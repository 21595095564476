import React, { FC } from "react";
import get from "lodash/get";

import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import Typography from "components/Typography";
import { SvgIcon } from "components/Icon";

import { useTranslation } from "react-i18next";
import { BillingType } from "types/Billing";
import { IcInfo, IcCircleCheck } from "components/SvgIcons";

import COLORS from "constants/Colors";
import { CertificateImage } from "./styled";

export type BillingInfoPropsType = {
  billing: BillingType;
  loading: boolean;
  showCertificate?: boolean;
};

const BillingDisplayInfo: FC<BillingInfoPropsType> = (props) => {
  const { t } = useTranslation();

  const { billing, loading, showCertificate = true } = props;
  const certificateImage = get(billing, "documentVerificationCertificatePath") || "";

  if (loading) {
    return (
      <Grid container item justify="center">
        <CircularProgress className="m-4" />
      </Grid>
    );
  }

  return billing ? (
    <>
      {billing.documentVerificationCertificatePath && (
        <Grid item xs={12}>
          <Grid item xs={12} className="mb-3" container justify="center">
            <a href={billing.documentVerificationCertificatePath} target="_blank" rel="noopener noreferrer">
              <CertificateImage
                height={140}
                width={100}
                src={billing.documentVerificationCertificatePath}
                alt="certificate"
              />
            </a>
          </Grid>
        </Grid>
      )}

      {billing && billing.isVerified && showCertificate && (
        <Typography variant="title10" color="success" className="text-right">
          <SvgIcon
            className="ml-1 pb-0 pr-0 align-bottom"
            component={IcCircleCheck}
            fontSize="small"
            htmlColor={COLORS.Success}
          />
          {t("planBilling.verified")}
        </Typography>
      )}

      {billing && !billing.isVerified && certificateImage && showCertificate && (
        <Typography variant="title10" color="warning" className="text-right">
          <SvgIcon className="ml-1 pb-0" component={IcInfo} fontSize="small" htmlColor={COLORS.Warning} />
          {t("planBilling.inProgress")}
        </Typography>
      )}

      <Grid item xs={12} className={showCertificate ? "mt-3" : ""}>
        <Typography variant="title3" color="darkGray">
          {billing.name}
        </Typography>
      </Grid>
      <Grid item xs={12} className="pt-1">
        <Typography variant="body3" color="darkGray" className="text-wrap">
          {billing.address}&nbsp;{billing.subDistrict}&nbsp;
          {billing.district}&nbsp;{billing.province}&nbsp;{billing.postalCode}
        </Typography>
      </Grid>
      <Grid item xs={12} className="mt-3" />
      <Grid item xs={12} className="pt-1" container justify="flex-start" alignContent="center" alignItems="center">
        <Typography variant="title8" color="darkGray">
          {t("planBilling.info.label.taxId")}&nbsp;
        </Typography>
        <Typography variant="body3" color="darkGray">
          {billing.taxId || "-"}
        </Typography>
      </Grid>

      {billing.contactPerson && (
        <Grid item xs={12} className="pt-1" container justify="flex-start" alignContent="center" alignItems="center">
          <Typography variant="title8" color="darkGray">
            {t("planBilling.info.label.contactPerson")}&nbsp;
          </Typography>
          <Typography variant="body3" color="darkGray" className="hiddenOverFlowText">
            {billing.contactPerson || "-"}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12} className="pt-1" container justify="flex-start" alignContent="center" alignItems="center">
        <Typography variant="title8" color="darkGray">
          {t("planBilling.info.label.phoneNumber")}&nbsp;
        </Typography>
        <Typography variant="body3" color="darkGray">
          {billing.phoneNumber}
        </Typography>
      </Grid>
      <Grid item xs={12} className="pt-1" container justify="flex-start" alignContent="center" alignItems="center">
        <Typography variant="title8" color="darkGray">
          {t("planBilling.info.label.email")}&nbsp;
        </Typography>
        <Typography variant="body3" color="darkGray">
          {billing.email}
        </Typography>
      </Grid>
    </>
  ) : (
    <Grid item container xs={12} alignItems="center">
      <Typography variant="body3" color="darkGray">
        {t("planBilling.info.noBillingInfo")}
      </Typography>
    </Grid>
  );
};

export default BillingDisplayInfo;
