import * as React from "react";

const SvgIcDigitalProduct = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="33" height="24" viewBox="0 0 33 24" {...props}>
    <defs>
      <path id="2yfjx3knqa" d="M0.127 0.083L22.43 0.083 22.43 22.394 0.127 22.394z" />
      <path id="bqlg09kzec" d="M0.147 0.085L13.102 0.085 13.102 9.388 0.147 9.388z" />
      <path id="ynegvykk9e" d="M0 0.043L13.281 0.043 13.281 9.221 0 9.221z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g transform="translate(-99 -360) translate(99.6 360.8) translate(8.21 .085)">
              <mask id="5vi8innm3b" fill="#fff">
                <use xlinkHref="#2yfjx3knqa" />
              </mask>
              <path
                fill="currentColor"
                d="M6.482 10.14c.096-.095.262-.083.372.028.11.11.122.277.027.372l-.346.344c-.095.095-.262.083-.372-.027-.11-.11-.122-.277-.026-.373l.345-.344zM7.82 8.808c.095-.095.262-.083.372.028.11.11.122.277.026.372l-.345.344c-.096.095-.262.083-.373-.027-.11-.11-.121-.277-.026-.372l.346-.345zm1.336-1.332c.095-.095.262-.083.372.028.11.11.122.277.026.372l-.345.344c-.096.095-.262.083-.372-.027-.11-.11-.122-.277-.027-.373l.346-.344zm1.336-1.332c.096-.095.262-.083.372.028.11.11.122.277.027.372l-.346.344c-.095.095-.262.083-.372-.027-.11-.11-.122-.278-.026-.373l.345-.344zm4.167 4.927c.514.516.504 1.244.017 1.73-.491.49-1.244.464-1.73-.023-.49-.491-.522-1.225-.03-1.715.496-.494 1.234-.503 1.743.008zm2.27 2.384l-5.783 1.156-.436-.437 5.782-1.157.437.438zm-2.196 3.12c-.486.485-1.24.46-1.73-.032-.484-.487-.517-1.22-.026-1.71.497-.495 1.23-.498 1.74.012.514.516.508 1.24.016 1.73zM2.362 5.355c-.11-.091-.26-.097-.349-.01L.185 7.168c-.086.086-.075.237.024.337l3.968 3.98c.128.128.301.195.465.178l.793-.079-.082.792c-.017.164.049.338.176.466l9.285 9.265c.387.385 1.013.384 1.397-.003l5.932-5.974c.384-.386.382-1.01-.003-1.394l-9.25-9.233c-.127-.127-.3-.194-.464-.177l-.793.079.082-.793c.017-.163-.049-.337-.176-.465L7.571.166c-.1-.1-.25-.111-.336-.026L5.39 1.978c-.088.088-.082.238.01.349.8.968.84 2.305.051 3.09-.788.786-2.124.742-3.09-.061z"
                mask="url(#5vi8innm3b)"
              />
            </g>
            <path
              fill="currentColor"
              d="M21.73 15.453c-.259.257-.298.539-.09.748.209.209.496.185.758-.077.278-.276.293-.553.084-.762-.208-.21-.485-.176-.753.09M21.588 12.431c.204.205.486.185.753-.081.273-.272.288-.549.084-.753-.213-.214-.49-.18-.753.081-.263.262-.297.54-.084.753M12.541 13.469l.075-.728-.838-.038c-.176.018-.362-.053-.498-.19l-1.341-1.345-6.799.598c-.236.02-.416.222-.41.46l.196 7.572c.006.209.153.386.357.429l11.358 2.389c.278.058.56.087.842.086v-5.948l-2.752-2.786c-.137-.137-.208-.323-.19-.5"
              transform="translate(-99 -360) translate(99.6 360.8)"
            />
            <g transform="translate(-99 -360) translate(99.6 360.8) translate(18.764 1.593)">
              <mask id="8a6awg0nxd" fill="#fff">
                <use xlinkHref="#bqlg09kzec" />
              </mask>
              <path
                fill="currentColor"
                d="M1.985 2.153l-.088.85.702-.034c.319-.023.391.047.595.223l5.412 5.376 4 .818c.348.035.6-.322.453-.638L10.03 2.276c-.061-.131-.183-.225-.326-.252L.147.084l1.649 1.57c.137.137.207.323.19.5"
                mask="url(#8a6awg0nxd)"
              />
            </g>
            <path
              fill="currentColor"
              d="M16.383 20.457v2.246c.282 0 .564-.029.842-.087l3.39-.713-4.232-4.247v2.8z"
              transform="translate(-99 -360) translate(99.6 360.8)"
            />
            <g transform="translate(-99 -360) translate(99.6 360.8) translate(0 1.76)">
              <mask id="42pb7b0k7f" fill="#fff">
                <use xlinkHref="#ynegvykk9e" />
              </mask>
              <path
                fill="currentColor"
                d="M12.129.043L3.397 1.857c-.143.026-.265.12-.326.252L.043 8.58c-.148.316.105.673.453.638l8.417-.85L6.626 5.98c-.106-.107-.118-.268-.025-.36l3.346-3.162c.095-.094.272-.105.374.01.896.8 1.953 1.043 2.595.419.639-.67.475-1.645-.787-2.845"
                mask="url(#42pb7b0k7f)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgIcDigitalProduct;
