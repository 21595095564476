export const beeExpressDescription = {
  EN: null,
  TH: [
    {
      body:
        "เข้ารับสินค้าตั้งแต่ 1 ชิ้นขึ้นไป ในพื้นที่ กทม.และปริมณฑล (นนทบุรี, ปทุมธานี, สมุทรปราการ) และใช้ระยะเวลาในการจัดส่งประมาณ 1-2 วันทำการ",
    },
    { body: "อัตราค่าบริการ คิดตามน้ำหนักกล่อง และ ขนาดกล่อง" },
    {
      body: "ขนาดและน้ำหนักสินค้าที่สามารถเข้ารับได้",
      subBody: [
        "1. น้ำหนัก ไม่เกิน 30 กิโลกรัม",
        "2. สามารถเข้ารับสินค้าขนาดกล่อง ( กว้าง + ยาว + สูง ) ไม่เกิน 150 เซนติเมตร และ ด้านใดด้านหนึ่งต้อง ไม่เกิน 85 เซนติเมตร",
      ],
    },
    {
      body:
        "หากสร้างรายการและชำระเงินก่อน 09.30 น. เข้ารับและจัดส่งพัสดุในวันเดียวกัน หากสร้างรายการหลังจาก 09.31 - 13.00 น. เข้ารับพัสดุในวันเดียวกันแต่จะจัดส่งวันถัดไป หากสร้างรายการหลังจาก 13.00 น. จะเข้ารับในวันถัดไป",
    },
    { body: "กรณีพัสดุมีการตีคืน คิดค่าบริการตามจริง" },
    { body: "ค่าประกันสินค้ากรณีเสียหายและสูญหาย วงเงินประกันไม่เกิน 2,000 บาท/ชิ้น" },
  ],
};
