import React, { useState, FC } from "react";
import moment from "moment";
import { DateUtils } from "react-day-picker";
import { IcSubtract } from "components/SvgIcons";
import { SelectYearMonthComponent, SelectYearMonthPropsTypes } from "./DateTimeRangeHeader";
import Navbar from "./Navbar";
import { DayPicker, FooterWrapper, TimeInput, DateText, DaypickerWrapper, SelectTime } from "./index.styled";
import { SvgIcon } from "../Icon/index";

type SelectedDateTime = {
  from: Date;
  to: Date;
};

type DateTimeRangePickerPropsTypes = {
  selectedDateTime: SelectedDateTime;
  setStartTime: (date: string | null) => void;
  setEndTime: (date: string | null) => void;
  format?: string;
  fullWidth?: boolean;
  minDate?: Date;
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  numberOfMonths?: number;
  disabledFuture?: boolean;
  isNativeSize?: boolean;
};

const DateTimeRangePicker: FC<DateTimeRangePickerPropsTypes> = ({
  selectedDateTime,
  setStartTime,
  setEndTime,
  disabledFuture = false,
  minDate,
  isNativeSize = false,
}) => {
  const [yearMonthRange, setYearMonthRange] = useState({
    year: +moment().format("YYYY"),
    month: +moment().format("MM") - 1,
  });
  const { from, to } = selectedDateTime;
  const { year, month } = yearMonthRange;
  const modifiers = { start: from, end: to, startDate: { daysOfWeek: [0] } };
  const startTime = moment(from).format("HH:mm");
  const endTime = moment(to).format("HH:mm");
  const disabledDayProps = disabledFuture
    ? [
        {
          after: new Date(),
        },
      ]
    : [];
  const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const DATE_TEXT_FORMAT = isNativeSize ? "DD MMMM YY" : "DD MMM YY";

  const getMask = (value: string) => {
    const chars = value.split("");
    const hours = [/[0-2]/, chars[0] === "2" ? /[0-3]/ : /[0-9]/];
    const minutes = [/[0-5]/, /[0-9]/];

    return [...hours, ":", ...minutes];
  };

  const handleDayClick = (day: Date) => {
    if (disabledFuture && moment(day).diff(moment()) >= 1) {
      return;
    }

    if (minDate && moment(minDate).diff(moment()) <= 0) {
      return;
    }

    const range = DateUtils.addDayToRange(day, selectedDateTime);

    const { from, to } = range;
    const isSameDay = from && to && DateUtils.isSameDay(from, to);
    const isToday =
      from && to && moment(from).diff(Date.now(), "day") === 0 && moment(to).diff(Date.now(), "day") === 0;

    if (isSameDay) {
      const startDateTime = from && new Date(moment(from).format("MM/DD/YYYY"));
      const endDateTime = to && new Date(moment(to).format("MM/DD/YYYY 23:59"));

      setStartTime(startDateTime?.toISOString() || null);

      if (isToday) {
        setEndTime(new Date()?.toISOString() || null);
        return;
      }

      setEndTime(endDateTime?.toISOString() || null);
      return;
    }

    const isStartDay = from && DateUtils.isSameDay(from, day);
    const startDateTime = isStartDay && from ? new Date(moment(from).format("MM/DD/YYYY")) : from;
    const endDateTime = !isStartDay && to ? new Date(moment(to).format("MM/DD/YYYY 23:59")) : to;

    setStartTime(startDateTime?.toISOString() || null);
    setEndTime(endDateTime?.toISOString() || null);
  };

  const handleSetTime = (type: string, time: string) => {
    if (time.length >= 4) {
      const isStartType = type === "start";
      const date = isStartType ? from : to;
      const fullDateTime = moment(`${moment(date).format("MM/DD/YYYY")} ${time}`).toISOString();

      if (isStartType) {
        setStartTime(fullDateTime);
        return;
      }
      setEndTime(fullDateTime);
    }
  };

  const handleSelectYearMonth = (value: SelectYearMonthPropsTypes) => {
    const { year, month } = value;
    setYearMonthRange({ ...yearMonthRange, year, month });
  };

  return (
    <>
      <DaypickerWrapper container>
        <DayPicker
          month={new Date(year, month)}
          className="Selectable"
          numberOfMonths={isNativeSize ? 1 : 2}
          showOutsideDays
          modifiers={modifiers}
          selectedDays={[from, { from, to }]}
          onDayClick={handleDayClick}
          weekdaysShort={DAYS}
          disabledDays={disabledDayProps}
          captionElement={({ date, localeUtils }) => (
            <SelectYearMonthComponent
              year={year}
              month={month}
              monthList={moment.months()}
              date={date}
              localeUtils={localeUtils}
              locale={moment.locale()}
              handleSelect={handleSelectYearMonth}
            />
          )}
          navbarElement={() => (
            <Navbar isNativeSize={isNativeSize} year={year} month={month} handleSelect={handleSelectYearMonth} />
          )}
        />
      </DaypickerWrapper>

      {from && to ? (
        <DaypickerWrapper container>
          <FooterWrapper>
            <SelectTime className={isNativeSize ? "native" : ""}>
              <DateText>{moment(from).format(DATE_TEXT_FORMAT)}</DateText>
              <TimeInput
                mask={getMask}
                value={startTime}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleSetTime("start", e.target.value)}
              />
            </SelectTime>
            <DateText>
              <SvgIcon className="ml-1 mt-1" component={IcSubtract} fontSize="small" />
            </DateText>
            <SelectTime className={isNativeSize ? "native" : ""}>
              <DateText>{moment(to).format(DATE_TEXT_FORMAT)}</DateText>
              <TimeInput
                mask={getMask}
                value={endTime}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleSetTime("end", e.target.value)}
              />
            </SelectTime>
          </FooterWrapper>
        </DaypickerWrapper>
      ) : null}
    </>
  );
};

export default DateTimeRangePicker;
