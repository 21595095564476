import React, { useState, FC } from "react";
import { FormControl, Grid, Button } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { SvgIcon } from "components/Icon";
import { IcArrowBack, IcArrowNext, IcArrowDown } from "components/SvgIcons";
import moment from "moment";
import { GridColumn, Select, SelectYearWrapper, SelectItem, MonthText, YearText, theme } from "./index.styled";

export enum RangeType {
  Start = "start",
  End = "end",
  Default = "default",
}

export type SelectYearMonthPropsTypes = {
  year: number;
  month: number;
};

type SelectYearMonthComponentPropsTypes = {
  month: number;
  year: number;
  minYear?: number;
  maxYear?: number;
  handleSelect: (value: SelectYearMonthPropsTypes) => void;
  date: Date;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  localeUtils: any;
  locale: string;
  monthList?: string[];
};

export const SelectYearMonthComponent: FC<SelectYearMonthComponentPropsTypes> = ({
  minYear,
  maxYear,
  year,
  month,
  monthList = [],
  handleSelect,
  date,
  localeUtils,
  locale,
}) => {
  const [selectedYear, setSelectedYear] = useState<number>(year);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isNextMonth = moment([year, month, 1]).diff(moment(localeUtils.formatDay(date, locale)), "months") === -1;
  const getMonthTitle = () => {
    if (isNextMonth) {
      if (month >= 11) return monthList[0];
      return monthList[month + 1];
    }

    return monthList[month];
  };

  const getYearTitle = () => {
    if (isNextMonth && month >= 11) {
      return year + 1;
    }

    return year;
  };

  const handleSetYearMonth = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target.value) {
      handleSelect({ year: selectedYear || year, month: Number(e.target.value) });
    }
  };

  const increaseYear = () => {
    if (maxYear && selectedYear >= maxYear) {
      return;
    }
    const updatedYear = selectedYear + 1;
    setSelectedYear(updatedYear);
    handleSelect({ year: updatedYear, month });
  };

  const decreaseYear = () => {
    if (minYear && selectedYear <= minYear) {
      return;
    }
    const updatedYear = selectedYear - 1;
    setSelectedYear(updatedYear);
    handleSelect({ year: updatedYear, month });
  };

  return (
    <MuiThemeProvider theme={theme}>
      <FormControl className="DayPicker-Caption">
        <Grid container>
          <GridColumn item xs={12}>
            <MonthText className="month" onClick={() => setIsOpen(!isOpen)}>
              {getMonthTitle()}
            </MonthText>
            <YearText className="year" onClick={() => setIsOpen(!isOpen)}>
              {getYearTitle()}
            </YearText>
            {!isNextMonth && (
              <Select
                className="SelectYearMonth"
                onChange={handleSetYearMonth}
                inputProps={{ display: "none" }}
                value={year}
                IconComponent={IcArrowDown}
              >
                <SelectYearWrapper>
                  <Button onClick={() => decreaseYear()}>
                    <SvgIcon className="mx-2" component={IcArrowBack} fontSize="small" />
                  </Button>
                  <span>{selectedYear}</span>
                  <Button onClick={() => increaseYear()}>
                    <SvgIcon className="mx-2" component={IcArrowNext} fontSize="small" />
                  </Button>
                </SelectYearWrapper>
                {monthList.map((value: string, i: number) => (
                  <SelectItem key={value} value={i}>
                    <span>{value}</span>
                  </SelectItem>
                ))}
              </Select>
            )}
          </GridColumn>
        </Grid>
      </FormControl>
    </MuiThemeProvider>
  );
};

export default SelectYearMonthComponent;
