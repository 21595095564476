import { useCallback, useEffect } from "react";
import { useGoogleLogin } from "react-google-login";
import get from "lodash/get";

import { APPLE_CLIENT_ID, GOOGLE_CLIENT_ID, ANDROID_GOOGLE_CLIENT_ID } from "config";
import { APPLE_SIGN_IN_SCOPE, APPLE_SIGN_IN_SCRIPT } from "constants/Apple";
import { AuthProvider, AuthSuccessProps } from "types/Auth";
import useScript from "./useScript";

const useAuthentication = (onCallback: (payload: AuthSuccessProps) => void) => {
  const [loaded] = useScript(APPLE_SIGN_IN_SCRIPT);

  useEffect(() => {
    if (loaded) {
      if(window?.AppleID){
        window.AppleID.auth.init({
          clientId: APPLE_CLIENT_ID,
          scope: APPLE_SIGN_IN_SCOPE || "",
          redirectURI: `${window.location.protocol}//${window.location.host}/auth/redirect`,
          usePopup: true,
        });
      }
      
    }
  }, [loaded]);

  const onFailure = useCallback((response) => {
    console.log(response);
  }, []);

  const { signIn: handleWebGoogleLogin } = useGoogleLogin({
    onFailure,
    clientId: GOOGLE_CLIENT_ID,
    onSuccess: (response) =>
      onCallback({
        token: get(response, "tokenId"),
        authProvider: AuthProvider.GOOGLE,
      }),
    isSignedIn: false,
  });

  const handleMobileGoogleSignIn = useCallback(() => {
    window.FirebasePlugin.authenticateUserWithGoogle(ANDROID_GOOGLE_CLIENT_ID, (data) => {
      onCallback({
        token: data.idToken,
        deviceProvider: window.cordova.platformId.toUpperCase(),
        authProvider: AuthProvider.GOOGLE,
      });
    });
  }, [onCallback]);

  const handleFacebookLogin = useCallback(() => {
    const { facebookConnectPlugin } = window;
    if (facebookConnectPlugin) {
      facebookConnectPlugin.login(
        ["email"],
        async (data) => {
          onCallback({ token: get(data, "authResponse.accessToken"), authProvider: AuthProvider.FACEBOOK });
        },
        (err: Error) => {
          console.error(err);
        },
      );
    } else {
      window.FB.login(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (response: any) => {
          onCallback({ token: get(response, "authResponse.accessToken"), authProvider: AuthProvider.FACEBOOK });
        },
        {
          scope: "email",
        },
      );
    }
  }, [onCallback]);

  const handleGoogleLogin = window.FirebasePlugin ? handleMobileGoogleSignIn : handleWebGoogleLogin;

  const handleAppleSignIn = useCallback(async () => {
    const payload = await window.AppleID.auth.signIn();
    const appleIdToken = get(payload, "authorization.id_token");

    const firstName = get(payload, "user.name.firstName");
    const lastName = get(payload, "user.name.lastName");
    const displayName = firstName && lastName ? `${firstName} ${lastName}` : undefined;

    onCallback({ token: appleIdToken, displayName, authProvider: AuthProvider.APPLE });
  }, [onCallback]);

  return {
    handleGoogleLogin,
    handleFacebookLogin,
    handleAppleSignIn,
  };
};

export default useAuthentication;
