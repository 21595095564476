import { useTranslation } from "react-i18next";
import { useMutation, ApolloError } from "@apollo/client";
import { toast } from "react-toastify";

import { CUSTOMER_NOTE } from "graphql/customer/query";
import { DELETE_CUSTOMER_NOTE } from "graphql/customer/mutation";
import { CustomerNoteVariablesType } from "types/Customer";

type UseDeleteCustomerNotePropsType = {
  variables: CustomerNoteVariablesType;
  projectId: string;
};

const useDeleteCustomerNote = (props: UseDeleteCustomerNotePropsType) => {
  const { variables, projectId } = props;
  const { t } = useTranslation();

  const [deleteNote] = useMutation(DELETE_CUSTOMER_NOTE, {
    refetchQueries: [{ query: CUSTOMER_NOTE, variables }],
    onError: (error: ApolloError) => {
      toast.error(`${t("Update failed!")}, ${error}`, {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 3000,
      });
    },
    onCompleted: () => {
      toast.success(t("Update successfully"), {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 1000,
        onClose: () => {},
      });
    },
  });

  const handleDeleteNote = (customerNoteId: string) => {
    deleteNote({
      variables: {
        projectId,
        customerNoteId,
      },
    });
  };

  return {
    handleDeleteNote,
  };
};

export default useDeleteCustomerNote;
