import styled from "styled-components";
import Grid from "components/Grid";
import Card from "components/Card";

export const OrderCardHeader = styled(Grid)`
  background-color: ${({ theme }) => theme.COLORS.Primary};
  border-radius: 8px 8px 0 0;
`;

export const CardDigitalCodeDetailModalWrapper = styled(Card)`
  .digital-code-detail:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  }
`;

export const CollapseButtonWrapper = styled(Grid)`
  display: flex;
  flex-grow: 0;
  align-items: center;
  transform: rotate(90deg);
`;

export const ItemListWrapper = styled(Grid)`
  .itemWrapper {
    :nth-child(odd) {
      background-color: ${({ theme }) => theme.COLORS.LightWhite};
    }
    :last-child {
      border-radius: 0 0 8px 8px;
    }
  }
`;
