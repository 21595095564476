import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import Typography from "components/Typography";
import { SummaryType } from "types/Product";
import Modal from "components/Modal";
import Grid from "components/Grid";
import Button from "components/Button";
import { SummaryBox } from "../styled";

type SummaryModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  summaries: SummaryType[];
  title: string;
  subTitle: string;
};

const SummaryModal: FC<SummaryModalProps> = ({ isOpen, onClose, onSubmit, summaries, title, subTitle }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-3">
        <Typography variant="title7" color="dark">
          {title}
        </Typography>
        <Typography variant="body3" className="pt-4">
          {subTitle}
        </Typography>
        <SummaryBox>
          {summaries.map(({ productCode, value }: SummaryType) => {
            const valueColor = value > 0 ? "success" : "error";

            return (
              <div key={productCode} className="d-flex flex-row">
                <Typography variant="body3">{productCode}:</Typography>
                <Typography variant="body3" color={valueColor} className="pl-2">
                  {value}
                </Typography>
              </div>
            );
          })}
        </SummaryBox>
        <Grid item container spacing={1} xs={12}>
          <Grid item xs={6}>
            <Button size="small" color="ghost" fullWidth onClick={onClose}>
              {t("Cancel")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button size="small" fullWidth onClick={onSubmit}>
              {t("Submit")}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default memo(SummaryModal);
