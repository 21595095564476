import React, { FC } from "react";
import { BoxProps } from "types/RichMenu";
import { Box, WidthBox, BaseBoxContainer, Badge } from "./styled";

const FiveBoxes: FC<BoxProps & { bgImageUrl?: string }> = ({ bgColor, bgImageUrl }) => {
  return (
    <BaseBoxContainer bgImageUrl={bgImageUrl}>
      <Box bgColor={bgColor}>
        <Badge>A</Badge>
      </Box>
      <WidthBox bgColor={bgColor}>
        <Badge>B</Badge>
      </WidthBox>
      <Box bgColor={bgColor}>
        <Badge>C</Badge>
      </Box>
      <Box bgColor={bgColor}>
        <Badge>D</Badge>
      </Box>
      <Box bgColor={bgColor}>
        <Badge>E</Badge>
      </Box>
    </BaseBoxContainer>
  );
};

export default FiveBoxes;
