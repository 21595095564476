import { gql } from "@apollo/client";

export const SHIPPOP_PURCHASE_ORDER_SUMMARY = gql`
  query shippopPurchaseOrderSummary($projectId: ID!, $orderIds: [ID]!) {
    shippopPurchaseOrderSummary(projectId: $projectId, orderIds: $orderIds) {
      totalNumberOfOrder
      carriers
      email
      hasApiKey
      senderInformation {
        name
        phoneNumber
        address
        district
        subDistrict
        province
        postalCode
      }
      estimatedShippingPrice
      remainingLogisticCredit
    }
  }
`;
