import { gql } from "@apollo/client";

export const UPSERT_BROADCAST_CAMPAIGN = gql`
  mutation upsertBroadcastCampaign($projectId: ID!, $broadcastCampaign: BroadcastCampaignInput!, $isPublish: Boolean!) {
    upsertBroadcastCampaign(projectId: $projectId, broadcastCampaign: $broadcastCampaign, isPublish: $isPublish) {
      id
      recurringNotificationOptInId
      projectId
      name
      channel
      numberOfAudience
      numberOfEngagement
      broadcastCriteria {
        id
        projectId
        systemCriteria
        customCriteria
        locationCriteria
        operation
      }
      status
      responseMessage {
        type
        value
      }
      broadcastDate
      failReason
      createdAt
      updatedAt
      broadcastDate
      type
    }
  }
`;

export const DELETE_BROADCAST_CAMPAIGN = gql`
  mutation deleteDraftBroadcastCampaign($projectId: ID!, $broadcastId: ID!) {
    deleteDraftBroadcastCampaign(projectId: $projectId, broadcastId: $broadcastId) {
      id
      projectId
      name
      channel
      numberOfAudience
      numberOfEngagement
      broadcastCriteria {
        id
        projectId
        systemCriteria
        customCriteria
        locationCriteria
        operation
      }
      status
      responseMessage {
        type
        value
      }
      broadcastDate
      failReason
      createdAt
      updatedAt
      broadcastDate
      type
    }
  }
`;

export const CANCEL_SCHEDULE_BROADCAST = gql`
  mutation cancelScheduledBroadcast($projectId: ID!, $broadcastId: ID!) {
    cancelScheduledBroadcast(projectId: $projectId, broadcastId: $broadcastId) {
      id
      projectId
      name
      channel
      numberOfAudience
      numberOfEngagement
      broadcastCriteria {
        id
        projectId
        systemCriteria
        customCriteria
        locationCriteria
        operation
      }
      status
      responseMessage {
        type
        value
      }
      broadcastDate
      failReason
      createdAt
      updatedAt
      broadcastDate
      type
    }
  }
`;

export const DELETE_RECURRING_NOTIFICATION_OPT_IN = gql`
  mutation deleteRecurringNotificationOptIn($projectId: ID!, $recurringNotificationOptInId: ID!) {
    deleteRecurringNotificationOptIn(
      projectId: $projectId
      recurringNotificationOptInId: $recurringNotificationOptInId
    ) {
      id
      projectId
      name
      deliveryContextType
      messageTitle
      messageImageUrl
      frequencyType
      buttonMessageType
      isAutoRenew
      isActive
      isDeleted
      createdAt
      updatedAt
      numberOfSubscribers
    }
  }
`;

export const CREATE_RECURRING_NOTIFICATION_OPT_IN = gql`
  mutation createRecurringNotificationOptIn(
    $projectId: ID!
    $recurringNotificationOptIn: RecurringNotificationOptInInput!
  ) {
    createRecurringNotificationOptIn(projectId: $projectId, recurringNotificationOptIn: $recurringNotificationOptIn) {
      id
      projectId
      name
      deliveryContextType
      messageTitle
      messageImageUrl
      frequencyType
      buttonMessageType
      isAutoRenew
      isActive
      isDeleted
      createdAt
      updatedAt
      numberOfSubscribers
      reactionMessage
      invitationMessage
    }
  }
`;

export const UPDATE_RECURRING_NOTIFICATION_OPT_IN = gql`
  mutation updateRecurringNotificationOptIn(
    $projectId: ID!
    $recurringNotificationOptInId: ID!
    $recurringNotificationOptIn: RecurringNotificationOptInInput!
  ) {
    updateRecurringNotificationOptIn(
      projectId: $projectId
      recurringNotificationOptInId: $recurringNotificationOptInId
      recurringNotificationOptIn: $recurringNotificationOptIn
    ) {
      id
      projectId
      name
      deliveryContextType
      messageTitle
      messageImageUrl
      frequencyType
      buttonMessageType
      isAutoRenew
      isActive
      isDeleted
      createdAt
      updatedAt
      numberOfSubscribers
      reactionMessage
      invitationMessage
    }
  }
`;
