import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";

import { RadioBox } from "components/Radio";
import { AddonType } from "types/Addon";
import { EntityLevel } from "types/Billing";
import useGetProject from "utils/hooks/useGetProject";

type ImageSearchItemListPropsType = {
  projectId: string;
  addons: AddonType[];
  handleChangeBroadcastAddon: (newBroadcastAddon?: AddonType) => void;
  selectedBroadcastAddon?: AddonType;
};

const BroadcastItemList: FC<ImageSearchItemListPropsType> = (props) => {
  const { t } = useTranslation();
  const { projectId, handleChangeBroadcastAddon, selectedBroadcastAddon, addons } = props;

  const { isAddonPackageEnabled } = useGetProject(projectId);
  const isEmptyAddons = addons.length === 0;
  const isPremiumPlan = isEmptyAddons ? false : addons[0].entityLevel === EntityLevel.PREMIUM;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RadioBox
          title={t("Disabled")}
          description={t("planAddon.broadcast.disabled.description")}
          isActive={!isAddonPackageEnabled}
          onClick={() => {}}
          rtl
          disabled={isAddonPackageEnabled}
        />
      </Grid>

      <Grid item xs={12}>
        <RadioBox
          title={t("planAddon.broadcast.defaultActive.title")}
          description={t("planAddon.broadcast.defaultActive.description")}
          isActive={!!isAddonPackageEnabled && !isEmptyAddons && !selectedBroadcastAddon}
          onClick={() => handleChangeBroadcastAddon()}
          rtl
          disabled={!isAddonPackageEnabled || !addons.length || isPremiumPlan}
        />
      </Grid>

      <Grid item xs={12}>
        <RadioBox
          title={t("planAddon.broadcast.unlimited.title")}
          description={t("planAddon.broadcast.unlimited.description")}
          isActive={!!isAddonPackageEnabled && (isEmptyAddons || !!selectedBroadcastAddon)}
          onClick={() => handleChangeBroadcastAddon(addons[0])}
          rtl
          disabled={!isAddonPackageEnabled}
        />
      </Grid>
    </Grid>
  );
};

export default BroadcastItemList;
