import { ActionType, Action, LinkValue, MenuAction, TextValue, DeepleAction, MenuActionType } from "types/RichMenu";

export const mapAction = (action: Action) => {
  switch (action.actionType) {
    case ActionType.TEXT:
      return {
        type: ActionType.TEXT,
        label: (action.value as TextValue).text,
      };

    case ActionType.URL:
      return {
        type: ActionType.URL,
        label: (action.value as LinkValue).description,
        url: (action.value as LinkValue).url,
      };

    default:
      return {
        type: action.value,
      };
  }
};

export const mapMenuAction = (menuAction: MenuAction) => {
  switch (menuAction.action.type) {
    case MenuActionType.TEXT:
      return { actionType: ActionType.TEXT, value: { text: menuAction.action.label } };

    case MenuActionType.URL:
      return {
        actionType: ActionType.URL,
        value: {
          url: menuAction.action.url,
          description: menuAction.action.label,
        },
      };

    default:
      return {
        actionType: ActionType.DEEPLE_ACTION,
        value: (menuAction.action.type as unknown) as DeepleAction,
      };
      break;
  }
};
