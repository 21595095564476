import * as React from "react";

const SvgIcAwaitingApproval = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24 17.778a6.222 6.222 0 110 12.444 6.222 6.222 0 010-12.444zM8.22 5.825c.12 3.028 2.547 5.475 5.576 5.59l.222.004h3.177c3.057 0 5.554-2.375 5.786-5.374l.013-.22h1.71a2.912 2.912 0 012.905 2.74l.005.17v8.126a8 8 0 00-9.036 13.022H9.377a5.82 5.82 0 01-5.816-5.597l-.004-.223V8.735A2.91 2.91 0 016.295 5.83l.17-.005h1.756zM24 19.555a4.444 4.444 0 100 8.89 4.444 4.444 0 000-8.89zm.02 1.334c.245 0 .444.199.444.444v2.823l1.498 1.151a.444.444 0 11-.542.705l-1.671-1.285-.174-.352v-3.042c0-.245.2-.444.445-.444zM18.652 1.778a2.914 2.914 0 012.91 2.91v.91c0 .077-.019.151-.023.227-.123 2.299-2.014 4.138-4.343 4.138h-3.178c-2.328 0-4.22-1.84-4.342-4.138-.004-.076-.023-.15-.023-.227v-.91a2.914 2.914 0 012.91-2.91zm0 2.91h-6.09l.001.91c0 .08.034.151.047.227.113.69.686 1.228 1.408 1.228h3.178c.722 0 1.295-.537 1.409-1.228.013-.076.047-.147.047-.227v-.91z"
    />
  </svg>
);

export default SvgIcAwaitingApproval;
