import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "components/Button";
import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import Modal from "components/Modal";
import Typography from "components/Typography";
import StickyPanel from "components/StickyPanel";

import { GuidelineContext } from "utils/context";

import { PAYMENTS } from "graphql/payment/query";

import { ProjectIdType } from "types/Project";
import { CODPaymentType, PaymentsQueryType, PaymentType, SavePaymentsMutation } from "types/Payment";
import { DefaultShippingMethodType } from "types/Shipping";

import useIsDesktop from "utils/hooks/useIsDesktop";
import { PaymentCardWrapper } from "domain/Payment/styled";

import { DEFAULT_SHIPPING_METHOD } from "graphql/shipping/query";
import { SAVE_PAYMENTS } from "graphql/payment/mutation";

import { removeTypenameField } from "utils/common";
import { PAYMENT_TYPES } from "constants/Payment";
import CODForm from "./CODForm";
import schema from "./schema";
import PaymentCard from "./PaymentCard";
import { getCodPayment } from "./utils";

export default function NewCashOnDelivery() {
  const { projectId } = useParams<ProjectIdType>();
  const { loading: isPaymentLoading, data: paymentData, called: paymentQueryCalled } = useQuery<
    PaymentsQueryType,
    ProjectIdType
  >(PAYMENTS, {
    variables: {
      projectId,
    },
  });

  const { loading: isDefaultShippingMethodLoading, data: defaultShippingMethodData } = useQuery<
    { defaultShippingMethod: DefaultShippingMethodType },
    ProjectIdType
  >(DEFAULT_SHIPPING_METHOD, {
    variables: {
      projectId,
    },
    skip: !paymentQueryCalled,
  });

  const [savePayments, { loading: isLoadingSavePayment }] = useMutation<
    SavePaymentsMutation,
    ProjectIdType & { paymentInputs: Record<string, unknown>[]; isUpdatePaymentToggle?: boolean }
  >(SAVE_PAYMENTS, {
    refetchQueries: [{ query: PAYMENTS, variables: { projectId } }, "payments"],
  });

  const loading = isPaymentLoading || isDefaultShippingMethodLoading;

  const payments = paymentData?.payments || [];
  const shippings = defaultShippingMethodData?.defaultShippingMethod?.shipping || [];

  const { t } = useTranslation();
  const { setGuidelineCompletedStepCount } = useContext(GuidelineContext);
  const isDesktop = useIsDesktop();
  const [isOpenWarningActivePaymentModal, setIsOpenWarningActivePaymentModal] = useState(false);
  const handleCloseWarningActivePaymentModal = () => setIsOpenWarningActivePaymentModal(false);

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { reset } = methods;

  useEffect(() => {
    const codPayment = getCodPayment(payments);
    if (codPayment) {
      reset(codPayment, { isDirty: false });
    }
  }, [reset, payments]);

  const handleSavePayment = async (formData: CODPaymentType) => {
    const codPayment = getCodPayment(payments);
    const sortedPayment = [...payments].sort((a, b) => +a.id - +b.id);
    const parsedPayments = removeTypenameField(sortedPayment) as PaymentType[];
    const id = codPayment?.id;

    const parsedFormData = {
      ...formData,
      id,
      projectId,
      type: PAYMENT_TYPES.CASH_ON_DELIVERY,
      depositAmount: +formData.depositAmount,
      depositThreshold: +formData.depositThreshold,
      managementFeeType: formData.managementFee.type,
      managementFee: +(formData.managementFee.percent || formData.managementFee.fee) || 0,
    };

    try {
      const { data } = await savePayments({
        variables: {
          projectId,
          paymentInputs: [
            ...parsedPayments.filter(({ type }) => type !== PAYMENT_TYPES.CASH_ON_DELIVERY),
            parsedFormData,
          ],
          isUpdatePaymentToggle: false,
        },
      });

      if (data?.savePayments) {
        reset(getCodPayment(data.savePayments));
      }

      toast.success(t("Update successfully"), {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 1000,
      });

      setGuidelineCompletedStepCount(projectId);
    } catch (error) {
      toast.error(`${t("Update failed!")}, ${error}`, {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 3000,
      });
    }
  };

  if (loading) {
    return (
      <Modal isOpen onClose={() => {}}>
        <CircularProgress className="m-4" />
      </Modal>
    );
  }

  const isDisabled = !!Object.keys(methods.errors).length || isLoadingSavePayment;

  return (
    <div className={isDesktop ? "p-5 w-50" : "w-100"}>
      {paymentData && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSavePayment)} className="w-100">
            <PaymentCardWrapper style={{ background: "none" }} className="w-100">
              <Typography variant="title2" color="dark" className="pb-3">
                {t("Cash on Delivery (COD)")}
              </Typography>
              <Controller
                name="isActive"
                defaultValue={false}
                control={methods.control}
                render={({ value, onChange }) => (
                  <PaymentCard isActive={value} onChangeIsActive={onChange} isLoading={isLoadingSavePayment}>
                    <CODForm defaultShippingMethods={shippings} disabled={!value || isLoadingSavePayment} />
                  </PaymentCard>
                )}
              />

              <div className="mt-4" />
              {isDesktop ? (
                <Button type="submit" fullWidth disabled={isDisabled}>
                  {t("Save")}
                </Button>
              ) : (
                <StickyPanel>
                  <Grid item xs={12} className="m-3">
                    <Button type="submit" fullWidth disabled={isDisabled}>
                      {t("Save")}
                    </Button>
                  </Grid>
                </StickyPanel>
              )}
            </PaymentCardWrapper>
          </form>
        </FormProvider>
      )}
      {/* Modal */}
      <Modal isOpen={isOpenWarningActivePaymentModal} onClose={handleCloseWarningActivePaymentModal}>
        <Grid container justify="center" className="p-3">
          <Grid item xs={8} className="text-center py-3">
            <Typography variant="title8" color="dark">
              {t("Please active at least 1 payment method except COD")}
            </Typography>
          </Grid>
          <Grid item xs={12} className="pt-1 text-center">
            <Button size="small" color="ghost" fullWidth onClick={handleCloseWarningActivePaymentModal}>
              {t("OK")}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}
