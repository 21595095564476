import styled, { css } from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import MuiDrawer, { DrawerProps } from "@material-ui/core/Drawer";

import Grid from "components/Grid";
import { ButtonWithIcon as DeepleButtonWithIcon } from "components/Button";

import { Z_INDEX } from "constants/ZIndex";
import { SUB_SIDE_NAV_WIDTH_DESKTOP } from "constants/Layout";
import { CHATROOM_HEADER_HEIGHT_DESKTOP } from "constants/Chat";

export const Drawer = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: (props: DrawerProps) => (props.open ? SUB_SIDE_NAV_WIDTH_DESKTOP : 16),
    transition: "width 0.3s ease",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "white",
  },
  paper: {
    width: SUB_SIDE_NAV_WIDTH_DESKTOP,
    position: "relative",
    zIndex: Z_INDEX.SUB_SIDE_NAV,
    boxSizing: "border-box",
    borderRight: "none",
  },
})(MuiDrawer);

export const TitleWrapper = styled.div<{ hasHeaderChildren?: boolean }>`
  padding: 0 20px 0 0;
  flex: 0;
  min-height: ${CHATROOM_HEADER_HEIGHT_DESKTOP - 1}px;
  height: ${CHATROOM_HEADER_HEIGHT_DESKTOP - 1}px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};

  ${({ hasHeaderChildren }) =>
    hasHeaderChildren &&
    css`
      border: none;
    `}
`;

export const SubSideWrapper = styled.div`
  position: relative;
  .tabHeader {
    background-color: transparent;
  }

  .MuiTabs-flexContainer {
    display: block;

    button {
      display: block;
      width: 100%;
      max-width: initial;
      padding-left: 20px;
      &:hover {
        background-color: ${({ theme }) => theme.COLORS.LightGray};
      }
    }
  }

  .Mui-selected {
    background-color: ${({ theme }) => theme.COLORS.LightGray};
    border-right: 2px solid ${({ theme }) => theme.COLORS.Primary};
  }

  .MuiTab-wrapper {
    align-items: flex-start;
    font-weight: bold;

    span {
      color: ${({ theme }) => theme.COLORS.Primary};
    }
  }

  .MuiTabs-indicator {
    background-color: initial !important;
  }

  .MuiTab-textColorInherit {
    opacity: 1;
  }
`;

export const ButtonWithIcon = styled(DeepleButtonWithIcon)`
  outline: none;
  position: absolute;
  right: -13px;
  top: 24px;
  z-index: ${Z_INDEX.TOGGLE_SWITCH_SUB_SIDE_NAV};
  border-radius: 50%;
`;

export const ChildrenWrapper = styled.div`
  display: flex;
  height: 100%;
  overflow-y: auto;
  flex: 1;
`;

export const DesktopGridContent = styled(Grid)`
  display: flex;
  height: 100%;
  overflow: auto;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 100%;
  }
`;

export const TabWrapper = styled.div`
  border-top: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
  overflow-y: auto;
  flex: 1;
`;
