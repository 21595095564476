import { ReactNode } from "react";

export type Warehouse = {
  id: string;
  name: string;
  code: string;
};

export enum InventoryIntegrationType {
  ZORT = "ZORT",
  X_COMMERCE = "X_COMMERCE",
}

export enum InventoryIntegrationSyncMethodType {
  MERGE = "MERGE",
  REPLACE = "REPLACE",
}

export enum IntegrationStatus {
  IN_PROGRESS = "IN_PROGRESS",
  FAILED = "FAILED",
  SUCCEEDED = "SUCCEEDED",
}

export type InventoryIntegrationCredential = {
  apiKey: string;
  apiSecret: string;
  storeName: string;
};

export type StepConfig = {
  name: string;
  content: ReactNode;
};

export type IntegrationQuery = {
  projectId: string;
  type: InventoryIntegrationType;
};

export type IntegrationData = {
  apiKey: string;
  apiSecret: string;
  storeName: string;
  warehouseName: string;
  warehouseCode: string;
};

export type Integration = {
  id: string;
  projectId: string;
  type: InventoryIntegrationType;
  data: IntegrationData;
  status: IntegrationStatus;
  isManual: boolean;
  updatedAt: string;
};

export type InventoryIntegrationPayload = {
  type: InventoryIntegrationType;
  data: IntegrationData;
};
