export enum PLATFORM {
  LINE = "LINE",
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
}

export enum MenuActionType {
  DEEPLE_CONTACT_ADMIN = "DEEPLE_CONTACT_ADMIN",
  DEEPLE_PRODUCT_CATALOG = "DEEPLE_PRODUCT_CATALOG",
  DEEPLE_TOP_SELLING = "DEEPLE_TOP_SELLING",
  DEEPLE_SHOPPING_CART = "DEEPLE_SHOPPING_CART",
  DEEPLE_ORDER_HISTORY = "DEEPLE_ORDER_HISTORY",
  TEXT = "TEXT",
  URL = "URL",
}

export enum LineMenuTemplate {
  DEFAULT_0 = "DEFAULT_0",
  LARGE_1 = "LARGE_1",
  LARGE_2 = "LARGE_2",
  LARGE_3 = "LARGE_3",
  LARGE_4 = "LARGE_4",
  LARGE_5 = "LARGE_5",
  LARGE_6 = "LARGE_6",
  LARGE_7 = "LARGE_7",
  DEFAULT_WITH_IMAGE = "DEFAULT_WITH_IMAGE",
}

export type MenuAction = {
  bounds: string;
  action: SubMenuAction;
};

export type SubMenuAction = {
  type: MenuActionType;
  label: string;
  url: string;
};

export enum DeepleAction {
  DEEPLE_CONTACT_ADMIN = "DEEPLE_CONTACT_ADMIN",
  DEEPLE_PRODUCT_CATALOG = "DEEPLE_PRODUCT_CATALOG",
  DEEPLE_TOP_SELLING = "DEEPLE_TOP_SELLING",
  DEEPLE_SHOPPING_CART = "DEEPLE_SHOPPING_CART",
  DEEPLE_ORDER_HISTORY = "DEEPLE_ORDER_HISTORY",
}

export enum Behavior {
  SHOWN = "SHOWN",
  COLLAPSED = "COLLAPSED",
}

export enum ActionType {
  DEEPLE_ACTION = "DEEPLE_ACTION",
  URL = "URL",
  TEXT = "TEXT",
}

export type TextValue = { text: string };
export type LinkValue = {
  url: string;
  description: string;
};

export type Action = {
  actionType: ActionType;
  value: TextValue | DeepleAction | LinkValue;
};

export type BoxProps = { bgColor?: string };

export type ActionInput = { type: ActionType | DeepleAction; label: string; text: string; url: string };

export type ChatMenu = {
  id: string;
  projectId: string;
  name: string;
  isActive: boolean;
  isDefault: boolean;
  platform: PLATFORM;
  menuActions: MenuAction[];
  menuUrl: string; // Menu image
  menuId?: string;
  menuLabel: string;
  isCollapsed: boolean;
  template: LineMenuTemplate;
  createdAt: string;
  updatedAt: string;
};

export type RichMenuInput = UpdateRichMenuInput & {
  platform: string;
};

export type UpdateRichMenuInput = {
  name: string;
  isActive?: boolean;
  isDefault?: boolean;
  menuActions: {
    bounds: string;
    action: ActionInput;
  }[];
  menuUrl?: string;
  menuId?: string;
  menuLabel?: string;
  isCollapsed?: boolean;
  template?: string;
};

export type AddRichMenuVariables = {
  projectId: string;
  chatMenu: RichMenuInput;
};

export type UpdateRichMenuVariables = {
  projectId: string;
  chatMenuId: string;
  chatMenu: RichMenuInput;
};

export type ActivateRichMenuVariables = {
  projectId: string;
  chatMenuId: string;
};

export type FacebookFormData = { title: string; action: Action[] };

export type InstagramFormData = { title: string; action: Action[] };
