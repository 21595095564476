import * as React from "react";

const SvgIcUserSetting = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.0455886,23.4882731 L15.9544114,23.4882731 L9.60027871,23.4882731 C9.60027871,18.8171355 13.6817509,18.81833 14.5871521,17.5963967 L14.6910702,17.039778 C13.4181732,16.3911859 12.5203369,14.8284331 12.5203369,13.0005101 C12.5203369,10.5924774 14.07871,8.63993231 16.0005972,8.63993231 C17.9220863,8.63993231 19.4804594,10.5924774 19.4804594,13.0005101 C19.4804594,14.8129052 18.5981511,16.3661022 17.3427729,17.0246482 L17.4610245,17.6573142 C18.4544176,18.8199226 22.4001194,18.8955718 22.4001194,23.4882731 L16.0455886,23.4882731 Z M16.0001991,-3.63087338e-12 C7.16318075,-3.63087338e-12 0,7.1635789 0,16.0001991 C0,24.8368193 7.16318075,32.0003982 16.0001991,32.0003982 C24.8368193,32.0003982 32,24.8368193 32,16.0001991 C32,7.1635789 24.8368193,-3.63087338e-12 16.0001991,-3.63087338e-12 L16.0001991,-3.63087338e-12 Z"
    />
  </svg>
);

export default SvgIcUserSetting;
