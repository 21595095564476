import { useQuery } from "@apollo/client";
import { RECURRING_NOTIFICATION_OPT_INS } from "graphql/broadcast/query";
import { RecurringNotificationOptInsResponseType } from "types/Broadcast";
import { ProjectIdType } from "types/Project";

export default function useGetRecurringNotiOptIns(projectId: string) {
  const { called, loading, data } = useQuery<RecurringNotificationOptInsResponseType, ProjectIdType>(
    RECURRING_NOTIFICATION_OPT_INS,
    {
      variables: { projectId },
      fetchPolicy: "network-only",
    },
  );

  const recurringNotiOptIns = data?.recurringNotificationOptIns ?? [];

  return {
    recurringNotiOptIns,
    isGetRecurringNotiOptInsCalled: called,
    isGetRecurringNotiOptInsLoading: loading,
  };
}
