import React from "react";
import NumberFormat from "react-number-format";
import { Controller, Control } from "react-hook-form";

type NumberFormatPropsType = {
  inputRef: (instance: NumberFormat<unknown> | null) => void;
  onChange: (event: { target: { name: string; value: string | number | null } }) => void;
  name: string;
  control?: Control;
};

export const NumberFormatCustom = (props: NumberFormatPropsType) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue || 0,
          },
        });
      }}
      allowNegative={false}
      thousandSeparator
      prefix="฿"
      decimalScale={2}
    />
  );
};

export const NumberFormatCustomWithoutPrefix = (props: NumberFormatPropsType) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue || 0,
          },
        });
      }}
      allowNegative={false}
      thousandSeparator
      decimalScale={2}
    />
  );
};

export const NumberFormatCustomNoDecimal = (props: NumberFormatPropsType) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue || 0,
          },
        });
      }}
      allowNegative={false}
      thousandSeparator
      decimalScale={0}
    />
  );
};

export const ControllerNumberFormatCustomWithoutPrefix = (props: NumberFormatPropsType) => {
  const { inputRef, name, control, ...other } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ onChange }: { onChange: (value: number) => void }): JSX.Element => (
        <NumberFormat
          {...other}
          getInputRef={inputRef}
          onValueChange={(values) => {
            onChange(values.floatValue || 0);
          }}
          allowNegative={false}
          thousandSeparator
          decimalScale={2}
        />
      )}
    />
  );
};

export const latLongFormat = (props: NumberFormatPropsType) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue == null ? null : values.floatValue,
          },
        });
      }}
      allowEmptyFormatting
      allowNegative
      thousandSeparator
      decimalScale={8}
      allowLeadingZeros={false}
    />
  );
};
