import gql from "graphql-tag";

export const TOTAL_ORDER_NUMBER_STATES_CHANGED = gql`
  subscription totalOrderNumberStatesChanged($projectId: ID!) {
    totalOrderNumberStatesChanged(projectId: $projectId) {
      totalOrderNumberStates {
        COD_PREPARE_TO_SHIP
        ORDER_PLACED
        PREPARE_TO_SHIPPING
        PENDING_FOR_SHIPPING_COST
        WAIT_FOR_PAYMENT_APPROVE
      }
    }
  }
`;
