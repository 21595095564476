import React, { FC, memo } from "react";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import Grid from "components/Grid";
import { ShowingResult, RowsSelectorPerPage } from "components/TablePagination";
import { Wrapper as PaginationWrapper } from "components/TablePagination/styled";
import { GridPagination, GridRowsPerPage, GridShowingResult, Typography } from "./styled";

type PaginationPropsType = {
  onChangeRowsPerPage: (rowsPerPage: number) => void;
  onClickPageNo: (selectedItem: { selected: number }) => void;
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  total: number;
};

const Pagination: FC<PaginationPropsType> = ({
  onChangeRowsPerPage,
  onClickPageNo,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  total,
}) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item className="px-2" container xs={12} alignItems="center" justify="center">
        <GridShowingResult item xs={6} md="auto" lg="auto">
          <ShowingResult page={page} rowsPerPage={rowsPerPage} total={total} />
        </GridShowingResult>
        <GridPagination item xs={12} md="auto" lg="auto" container>
          <PaginationWrapper>
            <ReactPaginate
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              forcePage={page - 1}
              pageCount={Math.ceil(total / rowsPerPage) || 0}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              onPageChange={onClickPageNo}
              activeClassName="active"
              containerClassName="d-flex flex-wrap p-0"
              pageClassName="page-no"
              breakClassName="break"
              previousClassName="page-no previous"
              nextClassName="page-no next"
            />
          </PaginationWrapper>
        </GridPagination>

        <GridRowsPerPage
          item
          xs={6}
          md="auto"
          lg="auto"
          className="flex-nowrap"
          container
          alignItems="center"
          justify="flex-end"
        >
          <Grid item>
            <Typography className="d-inline-block mr-2" color="darkGray">
              {t("salesReport.rowsSelector.show")}
            </Typography>
          </Grid>
          <Grid item>
            <RowsSelectorPerPage
              options={rowsPerPageOptions}
              onChange={onChangeRowsPerPage}
              rowsPerPage={rowsPerPage}
            />
          </Grid>
          <Grid item className="flex-grow-0">
            <Typography className="d-inline-block ml-2" color="darkGray">
              {t("salesReport.rowsSelector.perPage")}
            </Typography>
          </Grid>
        </GridRowsPerPage>
      </Grid>
    </Grid>
  );
};
export default memo(Pagination);
