import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Button from "components/Button";
import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import PageTitle from "components/PageTitle";
import Pagination from "components/Pagination";
import ScrollToTop from "components/ScrollToTop";
import Search from "components/Search";
import StickyPanel, { StickyButtonContainer } from "components/StickyPanel";

import { ProjectIdType } from "types/Project";
import { SynonymType, SynonymConfigKeyType } from "types/Synonym";
import { SYNONYM_CONFIGS, SYNONYM_LIMIT_PER_PAGE } from "constants/Synonym";

import SynonymCard from "./SynonymCard";
import useGetSynonyms from "./hooks/useGetSynnonyms";
import useUpdateSynonyms from "./hooks/useUpdateSynnonyms";

type SynonymContainerPropsType = {
  type: SynonymConfigKeyType;
};

const SynonymContainer: FC<SynonymContainerPropsType> = (props) => {
  const { type } = props;
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();

  const {
    isSynonymsLoading,
    pageNo,
    searchWord,
    handleChangePage,
    handleSearchChange,
    total,
    synonyms,
    queryVariable,
  } = useGetSynonyms(projectId, type);

  const { changedSynonyms, handleChangedSynonyms, handleSaveSynonyms, resetChangedSynonyms } = useUpdateSynonyms(
    projectId,
    type,
    queryVariable,
  );

  useEffect(() => {
    resetChangedSynonyms();
    handleSearchChange("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const isShowSaveButton = Boolean(changedSynonyms.length);

  const getSearchPlaceholder = () => {
    switch (type) {
      case "PRODUCT_NAME":
        return t("searchPlaceholder.aiSynonymProductName");
      case "PRODUCT_BRAND":
        return t("searchPlaceholder.aiSynonymProductBrand");
      case "CATEGORY":
        return t("searchPlaceholder.aiSynonymCategory");
      case "SKU_TYPE":
        return t("searchPlaceholder.aiSynonymSkuType");
      case "PROPERTY_TYPE":
        return t("searchPlaceholder.aiSynonymPropertyType");
      default:
        return "";
    }
  };

  return (
    <>
      <Grid container className={isShowSaveButton ? "pb-5" : ""}>
        <Grid item container xs={12}>
          <PageTitle title={t(SYNONYM_CONFIGS[type].TITLE)} />
        </Grid>
        <Grid item xs={12} className="pl-3">
          <Search
            defaultValue={searchWord}
            onChange={handleSearchChange}
            className="mr-3"
            placeholder={getSearchPlaceholder()}
          />
        </Grid>
        <Grid
          item
          container
          justify="center"
          xs={12}
          className={`px-3 ${isShowSaveButton ? "pt-2 pb-3" : "px-3 py-2"}`}
        >
          {isSynonymsLoading ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : (
            synonyms && (
              <Grid container>
                {synonyms
                  .filter(({ word }: SynonymType) => {
                    if (!searchWord) {
                      return !!word;
                    }

                    return new RegExp(searchWord, "i").test(word);
                  })
                  .map(({ word, synonyms, image, children }: SynonymType) => (
                    <Grid key={word} item xs={12} className="pb-3">
                      <SynonymCard word={word} synonyms={synonyms} image={image} onChange={handleChangedSynonyms}>
                        {children && (
                          <>
                            {children.map((child: SynonymType) => (
                              <Grid key={child.word} item xs={12} className="pt-3">
                                <SynonymCard
                                  word={child.word}
                                  synonyms={child.synonyms}
                                  onChange={(childWord: string, childSynonyms: string[]) =>
                                    handleChangedSynonyms(childWord, childSynonyms, word)
                                  }
                                />
                              </Grid>
                            ))}
                          </>
                        )}
                      </SynonymCard>
                    </Grid>
                  ))}
                <Grid item xs={12} className="pb-3">
                  {!!total && (
                    <Pagination
                      rowsPerPage={SYNONYM_LIMIT_PER_PAGE}
                      page={pageNo}
                      totalItem={total}
                      handlePageChange={handleChangePage}
                      isMobile
                    />
                  )}
                </Grid>
              </Grid>
            )
          )}
        </Grid>
      </Grid>
      <StickyPanel>
        <StickyButtonContainer className={isShowSaveButton ? `p-2 show-save-button` : `px-2 py-3`}>
          <ScrollToTop buttonColor="darkGray" />
        </StickyButtonContainer>
        {isShowSaveButton && (
          <Grid item xs={12} className="m-3">
            <Button fullWidth onClick={handleSaveSynonyms}>
              {t("Save")}
            </Button>
          </Grid>
        )}
      </StickyPanel>
    </>
  );
};

export default SynonymContainer;
