import React from "react";
import styled from "styled-components";
import { Z_INDEX } from "constants/ZIndex";

type StyledCoverLabelPropsType = {
  isPrimary?: boolean;
};

export const AddImageBox = styled(({ width: _width, height: _height, ...rest }) => <label {...rest} />).attrs(
  ({ width, height }) => {
    return { width, height };
  },
)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  width: ${({ width }) => (width ? `${width}px` : `100px`)};
  height: ${({ height }) => (height ? `${height}px` : `100px`)};
  border: dashed 1px ${({ theme }) => theme.COLORS.LightBlue};
  border-radius: 8px;
  margin: 16px 0;
`;

export const ImageContainer = styled(({ width: _width, height: _height, ...rest }) => <div {...rest} />).attrs(
  ({ width, height }) => {
    return { width, height };
  },
)`
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : `100px`)};
  height: ${({ height }) => (height ? `${height}px` : `100px`)};
  margin: 16px 0;
`;

export const ImageItem = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: contain;
  text-align: center;
  max-width: 100%;
  max-height: 100%;
  background-color: ${({ theme }) => theme.COLORS.PrimaryTransparent};
`;

export const CircleRemoveIconContainer = styled.div`
  position: absolute;
  font-size: 18px;
  z-index: ${Z_INDEX.REMOVE_IMAGE_ICON};
  top: -6px;
  right: -6px;
`;

export const CoverLabel = styled.div<StyledCoverLabelPropsType>`
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  background: ${({ theme, isPrimary }) => (isPrimary ? theme.COLORS.Primary : theme.COLORS.Dark)};
  opacity: ${({ isPrimary }) => (isPrimary ? 1 : 0.3)};
  color: ${({ theme }) => theme.COLORS.White};
  border-radius: 0 0 8px 8px;
  font-size: 8px;
  line-height: 20px;
  height: 20px;
  display: flex;
  flex-flow: column;
`;

export const CircleEditIconContainer = styled.div`
  position: absolute;
  z-index: ${Z_INDEX.REMOVE_IMAGE_ICON};
  top: -6px;
  right: -6px;
  &:hover {
    cursor: pointer;
    transform: scale(1.2, 1.2);
  }
  background: ${({ theme }) => theme.COLORS.White};
  border: 1px solid #ccc;
  border-radius: 100%;
  font-size: 16px;
  width: 32px;
  height: 32px;
`;
