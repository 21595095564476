import * as React from "react";

const SvgIcSubtract = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.632 14.5c.756 0 1.368.672 1.368 1.5s-.612 1.5-1.368 1.5H9.368C8.612 17.5 8 16.828 8 16s.612-1.5 1.368-1.5h13.264z"
    />
  </svg>
);

export default SvgIcSubtract;
