import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

import { CAMPAIGNS } from "graphql/campaign/query";
import { ADD_CAMPAIGN, UPDATE_CAMPAIGN } from "graphql/campaign/mutation";
import {
  CampaignWithoutPromotionType,
  CampaignConnectionType,
  CampaignFormType,
  CampaignQueryVariableType,
} from "types/Campaign";
import { ProjectIdType } from "types/Project";

type useSubmitFormPropsType = {
  onClose: () => void;
  focusedCampaign?: CampaignWithoutPromotionType;
  queryVariable: CampaignQueryVariableType;
};

const useSubmitForm = (props: useSubmitFormPropsType) => {
  const { projectId } = useParams<ProjectIdType>();
  const { queryVariable, focusedCampaign, onClose } = props;

  const [addCampaign] = useMutation(ADD_CAMPAIGN, {
    refetchQueries: [{ query: CAMPAIGNS, variables: queryVariable }],
  });
  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN, {
    update(cache, { data: { updateCampaign: updatedCampaign } }) {
      const cacheCampaigns = cache.readQuery<CampaignConnectionType>({
        query: CAMPAIGNS,
        variables: queryVariable,
      });

      if (cacheCampaigns) {
        const newCampaignResults = cacheCampaigns.campaigns.results.map(
          (cacheCampaign: CampaignWithoutPromotionType) => {
            return updatedCampaign.id === cacheCampaign.id ? updatedCampaign : cacheCampaign;
          },
        );

        cache.writeQuery({
          query: CAMPAIGNS,
          variables: queryVariable,
          data: {
            campaigns: {
              results: newCampaignResults,
              total: newCampaignResults.length,
              // eslint-disable-next-line no-underscore-dangle
              __typename: cacheCampaigns.campaigns.__typename,
            },
          },
        });
      }
    },
  });

  const handleSubmitForm = (formData: CampaignFormType) => {
    const campaign = {
      name: formData.campaignName,
      description: formData.campaignDescription,
      startDate: formData.startDate,
      endDate: formData.endDate,
    };

    if (focusedCampaign) {
      updateCampaign({
        variables: {
          campaignId: focusedCampaign.id,
          projectId,
          campaign,
        },
      });
    } else {
      addCampaign({
        variables: {
          projectId,
          campaign,
        },
      });
    }
    onClose();
  };

  return {
    handleSubmitForm,
  };
};

export default useSubmitForm;
