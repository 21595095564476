import { useState } from "react";
import { useQuery } from "@apollo/client";

import { DIGITAL_PRODUCT_CODE_LIMIT } from "config";
import { DIGITAL_PRODUCT_CODES } from "graphql/digitalContent/query";
import { DigitalProductCodeConnectionQueryType, DigitalContentStatus } from "types/DigitalContent";

const useDigitalProductCodes = (projectId: string, productSKUId: string, status: DigitalContentStatus) => {
  const [pageNo, setPageNo] = useState(1);

  const variables = {
    projectId,
    productSKUId,
    limit: DIGITAL_PRODUCT_CODE_LIMIT,
    offset: pageNo - 1,
    status,
  };

  const { data, loading: isLoading } = useQuery<DigitalProductCodeConnectionQueryType>(DIGITAL_PRODUCT_CODES, {
    variables,
  });

  const handlePageChange = (newPage: number) => {
    setPageNo(newPage);
  };

  return {
    isLoading,
    digitalProductCodes: data?.digitalProductCodes?.results || [],
    totalDigitalProductCodes: data?.digitalProductCodes?.total || 0,
    pageNo,
    handlePageChange,
    digitalProductCodesQueryVariables: variables,
  };
};

export default useDigitalProductCodes;
