import React, { FC, useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { PageConnect, ModalConnectGuieline, ModalConnectSuccessfully } from "@1convo/deeple-ui";

import { GUIDELINE_VIDEO_LINK } from "constants/Video";
import { PROJECT, START_PAGE } from "constants/Router";
import { GUIDELINE } from "graphql/guideline/query";

import { GuidelineQueryType } from "types/Guideline";
import { ProjectIdType } from "types/Project";

import { GuidelineContext } from "utils/context";
import { VideoPopupContext } from "utils/context/VideoPopupContext";

const NewStoreConnect: FC = () => {
  // General hooks
  const history = useHistory();
  const { projectId } = useParams<ProjectIdType>();
  const [isShowGuideline, setIsShowGuideline] = useState(false);
  const [isOpenModalCountdown, setIsOpenModalCountdown] = useState(false);

  // Context hooks
  const { videoProps, setVideoProps } = useContext(VideoPopupContext);
  const {
    isClosedConnectModalCountdown,
    isShowBroadcastQuickStart,
    handleFetchGuideline,
    setGuidelineCompletedStepCount,
    setIsClosedConnectModalCountdown,
    showBroadcastBar,
  } = useContext(GuidelineContext);

  // Async hooks
  const { data: guidelineData } = useQuery<GuidelineQueryType>(GUIDELINE, {
    variables: {
      projectId,
    },
  });

  // Define variables
  const hasConnect = Boolean(guidelineData?.guideline.hasConnect);
  const hasProduct = Boolean(guidelineData?.guideline.hasProducts);

  // Event handlers
  const handleClickWatchVideo = (variant: "facebook" | "instagram" | "line") => {
    setVideoProps({
      ...videoProps,
      isShowVideo: true,
      videoLink: GUIDELINE_VIDEO_LINK[variant],
    });
  };

  const handleConnected = (isConnected: boolean) => {
    if (isShowBroadcastQuickStart && !isClosedConnectModalCountdown && isConnected) {
      setIsOpenModalCountdown(true);
    }
  };

  const handleCloseModalCountdown = () => {
    setIsOpenModalCountdown(false);
    setIsClosedConnectModalCountdown(true);

    if (hasProduct) {
      history.push(`/${PROJECT}/${projectId}/${START_PAGE}#storeSetupSuccessfully`);
    } else {
      history.push(`/${PROJECT}/${projectId}/${START_PAGE}`);
    }
  };

  // Effect hooks
  useEffect(() => {
    if (!hasConnect) {
      setIsClosedConnectModalCountdown(false);
      setIsShowGuideline(true);
    }
  }, [hasConnect, setIsClosedConnectModalCountdown]);

  useEffect(() => {
    if (isOpenModalCountdown) {
      showBroadcastBar();
    }
  }, [isOpenModalCountdown, showBroadcastBar]);

  return (
    <>
      <PageConnect
        className="dp-min-h-fit"
        projectId={projectId}
        setGuidelineCompletedStepCount={setGuidelineCompletedStepCount}
        onFetchGuideline={handleFetchGuideline}
        onClickWatchVideo={handleClickWatchVideo}
        onConnected={handleConnected}
      />
      <ModalConnectGuieline
        isOpen={isShowGuideline}
        onClose={() => setIsShowGuideline(false)}
        onClickTutorial={() => {
          setVideoProps({
            ...videoProps,
            isShowVideo: true,
            videoLink: GUIDELINE_VIDEO_LINK.connect,
          });
          setIsShowGuideline(false);
        }}
      />
      {isOpenModalCountdown && (
        <ModalConnectSuccessfully isOpen={isOpenModalCountdown} onClose={handleCloseModalCountdown} />
      )}
    </>
  );
};

export default NewStoreConnect;
