import { makeStyles } from "@material-ui/core/styles";
import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { OptionType, ValueType } from "react-select";
import COLORS from "constants/Colors";
import CircularProgress from "components/CircularProgress";
import Typography from "components/Typography";
import Select from "components/Select";
import Modal from "components/Modal";
import Grid from "components/Grid";
import Button from "components/Button";
import { SHIPPOP_INVOICE_SIZE } from "types/Shipping";

type PrintInvoiceModalProps = {
  isOpen?: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onChange: (option: ValueType<OptionType>) => void;
  onSubmit: () => void;
  invoiceSize: ValueType<OptionType>;
};

const useStyles = makeStyles(() => ({
  paper: {
    overflow: "unset",
  },
}));

const PrintInvoiceModal: FC<PrintInvoiceModalProps> = ({
  isOpen = false,
  isLoading = false,
  invoiceSize,
  onSubmit,
  onClose,
  onChange,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const SHIPPOP_INVOICE_SIZE_OPTIONS = [
    { label: t("RECEIPT"), value: SHIPPOP_INVOICE_SIZE.RECEIPT },
    { label: SHIPPOP_INVOICE_SIZE.A4, value: SHIPPOP_INVOICE_SIZE.A4 },
    { label: SHIPPOP_INVOICE_SIZE.A5, value: SHIPPOP_INVOICE_SIZE.A5 },
    { label: SHIPPOP_INVOICE_SIZE.A6, value: SHIPPOP_INVOICE_SIZE.A6 },
    { label: t("LETTER"), value: SHIPPOP_INVOICE_SIZE.LETTER },
    { label: t("LETTER4x6"), value: SHIPPOP_INVOICE_SIZE.LETTER4x6 },
    { label: t("STICKER4x6"), value: SHIPPOP_INVOICE_SIZE.STICKER4x6 },
  ];

  const handleChangeSelector = (option: ValueType<OptionType>) => {
    onChange(option);
  };

  return (
    <Modal classes={classes} isDisabledOverFlow isOpen={isOpen} onClose={onClose} closeIconColor={COLORS.DarkMed}>
      <div style={{ minWidth: 320 }} className="p-4">
        <Typography variant="title2" className="mb-4">
          {t("shippopReport.printInvoiceButton")}
        </Typography>
        <Typography color="darkMed" variant="body3" className="mb-2">
          {t("SIZE")}
        </Typography>
        <Select
          value={invoiceSize}
          options={SHIPPOP_INVOICE_SIZE_OPTIONS}
          onChange={handleChangeSelector}
          className="mb-4"
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button size="medium" type="button" color="secondary" onClick={onClose} fullWidth>
              {t("Cancel")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button size="medium" type="button" fullWidth onClick={onSubmit} disabled={isLoading}>
              {t("Submit")}
              {isLoading && <CircularProgress size={20} className="ml-2" />}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default memo(PrintInvoiceModal);
