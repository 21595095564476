import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import Modal from "components/Modal";
import PageTitle from "components/PageTitle";
import Typography from "components/Typography";
import PlanAddonChangedBroadcast from "components/PlanAddonChangedBroadcast";

import { ProjectIdType } from "types/Project";

import UsageQuotaGroup from "./UsageQuotaGroup";
import TeamMember from "./TeamMember";
import Channel from "./Channel";
import useUsageData from "./hooks/useUsageData";
import useHasChangeOnNextCycle from "./hooks/useHasChangeOnNextCycle";

const PlanUsageContainer: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();

  const { currentUsagePlans, usagePeriod, isLoading, teamMember, channel } = useUsageData(projectId);
  const { isAddonChangeOnNextCycle, isPackageChangeOnNextCycle } = useHasChangeOnNextCycle(projectId);

  if (isLoading) {
    return (
      <Modal isOpen onClose={() => {}}>
        <CircularProgress className="m-4" />
      </Modal>
    );
  }

  const isBothPackageAndAddonChanged = isAddonChangeOnNextCycle && isPackageChangeOnNextCycle;
  const label =
    !isBothPackageAndAddonChanged && isPackageChangeOnNextCycle
      ? t("planUsage.broadcast.label.package")
      : t("planUsage.broadcast.label.addon");

  const broadcastChangeTitle = isBothPackageAndAddonChanged
    ? t("planUsage.broadcast.title.bothChange")
    : t("planUsage.broadcast.title.singleChange", { label });

  return (
    <Grid container alignContent="flex-start" alignItems="flex-start">
      <PageTitle title={t("planUsage.header")} />
      {(isAddonChangeOnNextCycle || isPackageChangeOnNextCycle) && (
        <Grid item xs={12} className="px-3 pb-3">
          <PlanAddonChangedBroadcast title={broadcastChangeTitle} />
        </Grid>
      )}
      <Grid item xs={12} className="px-3 pb-3">
        <Typography variant="body3" color="darkGray" className="pl-1">
          {t("planUsage.label.usagePeriod")} {usagePeriod}
        </Typography>
      </Grid>
      {currentUsagePlans && (
        <Grid item xs={12} lg={5} className="px-3 pb-4">
          <UsageQuotaGroup usageData={currentUsagePlans} />
        </Grid>
      )}
      <Grid item className="pb-4" xs={12} lg={7}>
        <Grid item xs={12} className="pb-4">
          <TeamMember data={teamMember.details} limit={teamMember.quota} projectId={projectId} />
        </Grid>
        <Grid item xs={12} className="pb-4">
          <Channel data={channel.details} limit={channel.quota} projectId={projectId} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PlanUsageContainer;
