import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";

import COLORS from "constants/Colors";
import Input from "components/Form/Input";
import { ControllerNumberFormatCustomWithoutPrefix } from "components/NumberFormatCustom";
import Typography from "components/Typography";
import Card, { CardPropsType } from "components/Card";

import { ThaiBahtAdornment } from "../../DefaultShippingMethod/style";

type FlatRateProps = {
  fixedPrice: number;
  cardProps?: CardPropsType;
};

const FlatRate: FC<FlatRateProps> = ({ fixedPrice, cardProps }) => {
  const { t } = useTranslation();

  return (
    <Card fullWidth padding="24px" className="mt-4" {...cardProps}>
      <Typography variant="body2" color={COLORS.DarkMed}>
        {t("Flat rate")}
      </Typography>
      <Input
        name="fixedPrice"
        className="mt-3"
        defaultValue={fixedPrice}
        variant="outlined"
        fullWidth
        placeholder={t("Price")}
        InputProps={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          inputComponent: ControllerNumberFormatCustomWithoutPrefix as any,
          startAdornment: <ThaiBahtAdornment />,
        }}
      />
    </Card>
  );
};

export default memo(FlatRate);
