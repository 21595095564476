const facebookAutoEngagement = {
  en: {
    "facebookAutoEngagement.add": "Add keyword set",
    "facebookAutoEngagement.addKeywords": "Add keywords",
    "facebookAutoEngagement.all": "Reply all",
    "facebookAutoEngagement.confirmDelete": "Are you sure to delete",
    "facebookAutoEngagement.createNew": "Add new attached post",
    "facebookAutoEngagement.delete": "Delete",
    "facebookAutoEngagement.header": "Comment to Chat",
    "facebookAutoEngagement.keywordBased": "Reply by keyword Based",
    "facebookAutoEngagement.keywordTag.title": "If a client makes comments including these keywords:",
    "facebookAutoEngagement.keywords": "If a client makes comments with these keywords:",
    "facebookAutoEngagement.messageReply": "Messenger reply",
    "facebookAutoEngagement.platform": "Platform",
    "facebookAutoEngagement.platformType": "Type",
    "facebookAutoEngagement.post": "Post :",
    "facebookAutoEngagement.postId": "Post ID:",
    "facebookAutoEngagement.reply": "Custom comment reply:",
    "facebookAutoEngagement.save": "Save",
    "facebookAutoEngagement.table.active": "Active",
    "facebookAutoEngagement.table.engagement": "Engagement",
    "facebookAutoEngagement.table.facebookConnectIsRequired": "Please connect facebook page",
    "facebookAutoEngagement.table.inactive": "Inactive",
    "facebookAutoEngagement.table.newOrderConversionRate": "Conversion rate",
    "facebookAutoEngagement.table.orderPaidConversionRate": "Order paid",
    "facebookAutoEngagement.table.orderPaidConversionTotal": "Total spending",
    "facebookAutoEngagement.table.placeholder": "No post",
    "facebookAutoEngagement.table.postId": "Attached Post ID",
    "facebookAutoEngagement.table.reach": "Reach",
    "facebookAutoEngagement.table.title": "Post title",
    "facebookAutoEngagement.targetedFacebookComments": "Targeted comments",
    "facebookAutoEngagement.title": "Title",
    "facebookAutoEngagement.titleLabel.placeholder": "name the keyword set",
    "facebookAutoEngagement.toast.complete": "Success",
    "facebookAutoEngagement.toast.fail": "Fail",
    "facebookAutoEngagement.channel": "Reply Channel",
  },
  th: {
    "facebookAutoEngagement.add": "เพิ่มกลุ่มคำ",
    "facebookAutoEngagement.addKeywords": "เพิ่มคำ",
    "facebookAutoEngagement.all": "ตอบกลับทั้งหมด",
    "facebookAutoEngagement.confirmDelete": "ยืนยันการลบ",
    "facebookAutoEngagement.createNew": "เพิ่มการเชื่อมต่อโพสต์ใหม่",
    "facebookAutoEngagement.delete": "ลบ",
    "facebookAutoEngagement.header": "ตอบกลับโพสต์",
    "facebookAutoEngagement.keywordBased": "ตอบกลับเฉพาะ                คำ/ประโยค",
    "facebookAutoEngagement.keywordTag.title": "ถ้าลูกค้าแสดงความคิดเห็นด้วย คำ/ประโยค เหล่านี้:",
    "facebookAutoEngagement.keywords": "ถ้าลูกค้าแสดงความคิดเห็นด้วย คำ/ประโยค เหล่านี้:",
    "facebookAutoEngagement.messageReply": "ข้อความตอบกลับ",
    "facebookAutoEngagement.platform": "Platform",
    "facebookAutoEngagement.platformType": "ประเภท",
    "facebookAutoEngagement.post": "โพสต์:",
    "facebookAutoEngagement.postId": "โพสต์ไอดี:",
    "facebookAutoEngagement.reply": "กำหนดข้อความตอบกลับความคิดเห็น:",
    "facebookAutoEngagement.save": "บันทึก",
    "facebookAutoEngagement.table.placeholder": "ไม่มีรายการการตอบกลับโพสต์อัตโนมัติ",
    "facebookAutoEngagement.toast.complete": "สำเร็จ",
    "facebookAutoEngagement.toast.fail": "ไม่สำเร็จ",
    "facebookAutoEngagement.table.title": "ชื่อโพสต์",
    "facebookAutoEngagement.table.postId": "โพสต์ไอดี",
    "facebookAutoEngagement.table.reach": "การเข้าถึง",
    "facebookAutoEngagement.table.engagement": "การตอบกลับ",
    "facebookAutoEngagement.table.active": "เปิดการใช้งาน",
    "facebookAutoEngagement.table.facebookConnectIsRequired": "กรุุณาตั้งค่าการเชื่อมต่อ Facebook",
    "facebookAutoEngagement.table.inactive": "ปิดการใช้งาน",
    "facebookAutoEngagement.table.newOrderConversionRate": "ผู้เข้ามาซื้อ",
    "facebookAutoEngagement.table.orderPaidConversionRate": "ออเดอร์ที่สำเร็จ",
    "facebookAutoEngagement.table.orderPaidConversionTotal": "ยอดขาย",
    "facebookAutoEngagement.targetedFacebookComments": "กลุ่มคำ/ประโยค ที่สนใจ",
    "facebookAutoEngagement.title": "หัวข้อ",
    "facebookAutoEngagement.titleLabel.placeholder": "เพิ่มคำ",
    "facebookAutoEngagement.channel": "ช่องทางตอบกลับโพสต์",
  },
};

export default facebookAutoEngagement;
