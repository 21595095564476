import * as React from "react";

import { IconStartPage } from "@1convo/deeple-ui";
import {
  ACCOUNT_SETTINGS,
  AI_SETTINGS,
  CHAT,
  DASHBOARD,
  ENTERPRISE_SETTINGS,
  INTEGRATION,
  MARKETING_TOOLS,
  ORDERS,
  PLAN,
  PRODUCTS_INVENTORY,
  QUICK_START,
  SHIPPING,
  STORE_SETTINGS,
  USER_SETTING,
  PAYMENTS,
  START_PAGE,
} from "constants/Router";
import { HeaderType } from "types/Tab";

import {
  TABS_OWNER_CONFIG as STORE_SETTING_TABS_OWNER_CONFIG,
  TABS_ADMIN_CONFIG as STORE_SETTING_TABS_ADMIN_CONFIG,
} from "pages/StoreSetting/tabsConfig";
import {
  OWNER_TAB_CONFIGS as SHIPPING_OWNER_TAB_CONFIGS,
  OWNER_TAB_CONFIGS_WITHOUT_SHIPPOP as SHIPPING_OWNER_TAB_CONFIGS_WITHOUT_SHIPPOP,
  ADMIN_TAB_CONFIGS as SHIPPING_ADMIN_TAB_CONFIGS,
  ADMIN_TAB_CONFIGS_WITHOUT_SHIPPOP as SHIPPING_ADMIN_TAB_CONFIGS_WITHOUT_SHIPPOP,
} from "pages/Shipping/config";
import { TAB_CONFIGS_WITH_HIGHLIGHT_PRODUCT as PRODUCT_TAB_CONFIGS } from "pages/Products/config";
import { TAB_CONFIGS_DEFAULT } from "pages/PlanUsage/tabsConfig";
import { TAB_CONFIGS as MARKETING_TOOLS_TAB_CONFIGS } from "pages/MarketingTools/tabsConfig";
import { TAB_CONFIGS as DASHBOARD_TAB_CONFIGS } from "pages/Dashboard/tabsConfig";
import { TAB_CONFIGS as AI_SETTING_TAB_CONFIGS } from "pages/AISetting/tabsConfig";
import { TAB_CONFIGS as ACCOUNT_SETTING_TAB_CONFIGS } from "pages/AccountSetting/tabsConfig";
import { TAB_CONFIGS as PAYMENTS_TAB_CONFIGS } from "pages/Payments/tabsConfig";

import {
  IcCampaign,
  IcChat,
  IcChatbot,
  IcHelp,
  IcOrders,
  IcPlanUsage,
  IcPreference,
  IcProduct,
  IcReport,
  IcShipping,
  IcStoreSettings,
  IcIntegration,
  IcDeeplePay,
} from "../components/SvgIcons";

export type MenuListConfigType = {
  title: string;
  page: string;
  link: string;
  icon: React.FunctionComponent;
  badgeNo?: number;
};

export type RouterConfigType = MenuListConfigType & {
  tabConfig?: {
    header: ReadonlyArray<HeaderType>;
  };
};

const filteredAIsettingTabConfigs = AI_SETTING_TAB_CONFIGS;

const IcStartPage = () => (
  <div style={{ padding: "0 20px 0 20px" }} className="d-flex items-center">
    <IconStartPage />
  </div>
);

export const START_PAGE_ROUTE_CONFIG = {
  title: "startPage",
  page: START_PAGE,
  link: `/${START_PAGE}`,
  icon: IcStartPage,
};

// Don't sort key of OWNER_ROUTE_CONFIG that effect to order of lists in the side nav.
export const OWNER_ROUTE_CONFIG: Record<string, RouterConfigType> = {
  START_PAGE_ROUTE_CONFIG,
  dashboard: {
    title: "dashboard.menu",
    page: DASHBOARD,
    link: `/${DASHBOARD}`,
    icon: IcReport,
    tabConfig: {
      header: DASHBOARD_TAB_CONFIGS,
    },
  },
  orders: {
    title: "Orders",
    page: ORDERS,
    link: `/${ORDERS}`,
    icon: IcOrders,
  },
  chat: {
    title: "Chat",
    page: CHAT,
    link: `/${CHAT}`,
    icon: IcChat,
  },
  "ai-settings": {
    title: "AI Settings",
    page: AI_SETTINGS,
    link: `/${AI_SETTINGS}`,
    icon: IcChatbot,
    tabConfig: {
      header: filteredAIsettingTabConfigs,
    },
  },
  "products-inventory": {
    title: "Category & Products Inventory",
    page: PRODUCTS_INVENTORY,
    link: `/${PRODUCTS_INVENTORY}`,
    icon: IcProduct,
    tabConfig: {
      header: PRODUCT_TAB_CONFIGS,
    },
  },
  "marketing-tools": {
    title: "marketingTools.title",
    page: MARKETING_TOOLS,
    link: `/${MARKETING_TOOLS}`,
    icon: IcCampaign,
    tabConfig: {
      header: MARKETING_TOOLS_TAB_CONFIGS,
    },
  },
  shipping: {
    title: "Shipping method",
    page: SHIPPING,
    link: `/${SHIPPING}`,
    icon: IcShipping,
    tabConfig: {
      header: SHIPPING_OWNER_TAB_CONFIGS,
    },
  },
  "store-settings": {
    title: "Store Settings",
    page: STORE_SETTINGS,
    link: `/${STORE_SETTINGS}`,
    icon: IcStoreSettings,
    tabConfig: {
      header: STORE_SETTING_TABS_OWNER_CONFIG,
    },
  },
  integration: {
    title: "integration.title",
    page: INTEGRATION,
    link: `/${INTEGRATION}`,
    icon: IcIntegration,
  },
  [PAYMENTS]: {
    title: "payments.title",
    page: PAYMENTS,
    link: `/${PAYMENTS}`,
    icon: IcDeeplePay,
    tabConfig: {
      header: PAYMENTS_TAB_CONFIGS,
    },
  },
};

export const OWNER_ROUTE_CONFIG_WITHOUT_SHIPPOP: Record<string, RouterConfigType> = {
  startPage: START_PAGE_ROUTE_CONFIG,
  dashboard: {
    title: "dashboard.menu",
    page: DASHBOARD,
    link: `/${DASHBOARD}`,
    icon: IcReport,
    tabConfig: {
      header: DASHBOARD_TAB_CONFIGS,
    },
  },
  orders: {
    title: "Orders",
    page: ORDERS,
    link: `/${ORDERS}`,
    icon: IcOrders,
  },
  chat: {
    title: "Chat",
    page: CHAT,
    link: `/${CHAT}`,
    icon: IcChat,
  },
  "ai-settings": {
    title: "AI Settings",
    page: AI_SETTINGS,
    link: `/${AI_SETTINGS}`,
    icon: IcChatbot,
    tabConfig: {
      header: filteredAIsettingTabConfigs,
    },
  },
  "products-inventory": {
    title: "Category & Products Inventory",
    page: PRODUCTS_INVENTORY,
    link: `/${PRODUCTS_INVENTORY}`,
    icon: IcProduct,
    tabConfig: {
      header: PRODUCT_TAB_CONFIGS,
    },
  },
  "marketing-tools": {
    title: "marketingTools.title",
    page: MARKETING_TOOLS,
    link: `/${MARKETING_TOOLS}`,
    icon: IcCampaign,
    tabConfig: {
      header: MARKETING_TOOLS_TAB_CONFIGS,
    },
  },
  shipping: {
    title: "Shipping method",
    page: SHIPPING,
    link: `/${SHIPPING}`,
    icon: IcShipping,
    tabConfig: {
      header: SHIPPING_OWNER_TAB_CONFIGS_WITHOUT_SHIPPOP,
    },
  },
  "store-settings": {
    title: "Store Settings",
    page: STORE_SETTINGS,
    link: `/${STORE_SETTINGS}`,
    icon: IcStoreSettings,
    tabConfig: {
      header: STORE_SETTING_TABS_OWNER_CONFIG,
    },
  },
  integration: {
    title: "integration.title",
    page: INTEGRATION,
    link: `/${INTEGRATION}`,
    icon: IcIntegration,
  },
  [PAYMENTS]: {
    title: "payments.title",
    page: PAYMENTS,
    link: `/${PAYMENTS}`,
    icon: IcDeeplePay,
    tabConfig: {
      header: PAYMENTS_TAB_CONFIGS,
    },
  },
};

const {
  "marketing-tools": marketingTools,
  "products-inventory": productsInventory,
  "store-settings": storeSettings,
  chat,
  dashboard,
  orders,
  shipping,
  integration,
} = OWNER_ROUTE_CONFIG;

// Don't sort key of ADMIN_ROUTE_CONFIG that effect to order of lists in the side nav.
export const ADMIN_ROUTE_CONFIG = {
  START_PAGE_ROUTE_CONFIG,
  dashboard,
  orders,
  chat,
  "products-inventory": productsInventory,
  shipping: {
    ...shipping,
    tabConfig: {
      header: SHIPPING_ADMIN_TAB_CONFIGS,
    },
  },
  "store-settings": {
    ...storeSettings,
    tabConfig: {
      header: STORE_SETTING_TABS_ADMIN_CONFIG,
    },
  },
  "marketing-tools": marketingTools,
  integration,
};

export const ADMIN_ROUTE_CONFIG_WITHOUT_SHIPPOP = {
  START_PAGE_ROUTE_CONFIG,
  dashboard,
  orders,
  chat,
  "products-inventory": productsInventory,
  shipping: {
    ...shipping,
    tabConfig: {
      header: SHIPPING_ADMIN_TAB_CONFIGS_WITHOUT_SHIPPOP,
    },
  },
  "store-settings": {
    ...storeSettings,
    tabConfig: {
      header: STORE_SETTING_TABS_ADMIN_CONFIG,
    },
  },
  "marketing-tools": marketingTools,
  integration,
};

export const PLAN_USAGE_ROUTE_CONFIG = {
  title: "planUsage.menu",
  page: PLAN,
  link: `/${PLAN}`,
  icon: IcPlanUsage,
  tabConfig: {
    header: TAB_CONFIGS_DEFAULT,
  },
};

export const QUICK_START_ROUTE_CONFIG = {
  title: "BroadcastBar.button",
  page: QUICK_START,
  link: `/${QUICK_START}`,
  icon: IcHelp,
};

export const ACCOUNT_SETTINGS_ROUTE_CONFIG = {
  title: "Account settings",
  page: ACCOUNT_SETTINGS,
  link: `/${USER_SETTING}/${ACCOUNT_SETTINGS}`,
  icon: IcPreference,
  tabConfig: {
    header: ACCOUNT_SETTING_TAB_CONFIGS,
  },
};

export const ENTERPRISE_ROUTE_CONFIG = {
  title: "Enterprise settings",
  page: ENTERPRISE_SETTINGS,
  link: `/${ENTERPRISE_SETTINGS}`,
  icon: IcPreference,
};

export const OWNER_MENU_LIST_CONFIG: RouterConfigType[] = Object.values(OWNER_ROUTE_CONFIG);
export const OWNER_MENU_LIST_CONFIG_WITHOUT_SHIPPOP: RouterConfigType[] = Object.values(
  OWNER_ROUTE_CONFIG_WITHOUT_SHIPPOP,
);
export const ADMIN_MENU_LIST_CONFIG: RouterConfigType[] = Object.values(ADMIN_ROUTE_CONFIG);
export const ADMIN_MENU_LIST_CONFIG_WITHOUT_SHIPPOP: RouterConfigType[] = Object.values(
  ADMIN_ROUTE_CONFIG_WITHOUT_SHIPPOP,
);
