import { DAYS, DEFAULT_WORKING_TIME } from "domain/Setting/AISetting/config";
import { WorkingHour, WorkingTime, WorkingTimeWithId } from "types/Setting";
import nanoid from "utils/nanoid";
import { addMinutes, compareAsc, format } from "date-fns";

export const sortWorkingHour = ({ day: aDay }: WorkingHour, { day: bDay }: WorkingHour) => {
  const aIndex = DAYS.findIndex((day) => day === aDay);
  const bIndex = DAYS.findIndex((day) => day === bDay);

  return aIndex - bIndex;
};

export const workingTimeStringToDate = (workingTimeString: string) => {
  if (!workingTimeString) {
    return null;
  }

  const now = new Date();
  const [hour, minute] = workingTimeString.split(":").map(Number);

  return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour, minute);
};

export const dateToWorkingTimeString = (date: Date) => {
  return format(date, "HH:mm");
};

export const generateWorkingTime = (recentWorkingTime?: WorkingTimeWithId) => {
  const id = nanoid();

  if (!recentWorkingTime) {
    return { id, ...DEFAULT_WORKING_TIME };
  }

  const endDate = workingTimeStringToDate(recentWorkingTime.end);

  if (!endDate) {
    return { id, ...DEFAULT_WORKING_TIME };
  }

  const start = format(addMinutes(endDate, 1), "HH:mm");
  const end = format(addMinutes(endDate, 2), "HH:mm");

  return { id, start, end } as WorkingTimeWithId;
};

export const sortWorkingTime = (a: WorkingTime, b: WorkingTime) => {
  const startDate = workingTimeStringToDate(a.start) || new Date();
  const endDate = workingTimeStringToDate(b.start) || new Date();

  return compareAsc(startDate, endDate);
};
