import React, { ReactNode } from "react";
import styled from "styled-components";
import { toast, ToastOptions } from "react-toastify";
import { VARIANTS } from "constants/Typography";

export const Message = styled.p`
  white-space: pre-wrap;
  line-height: 1.5;
  margin: 0;
  padding: 0 8px;
  font-size: ${VARIANTS.title8.size};
  font-family: ${VARIANTS.title8.fontFamily};
  color: ${({ theme }) => theme.COLORS.Dark};
`;

export type Notify = (message: ReactNode, option?: ToastOptions) => void;

export const notifyDefault: Notify = (message, option = {}) => {
  toast(<Message>{message}</Message>, {
    hideProgressBar: true,
    ...option,
  });
};

export const notifySuccess: Notify = (message, option = {}) => {
  toast.success(<Message>{message}</Message>, {
    ...option,
  });
};

export const notifyError: Notify = (message, option = {}) => {
  toast.error(<Message>{message}</Message>, {
    ...option,
  });
};

export const notifyWarn: Notify = (message, option = {}) => {
  toast.warn(<Message>{message}</Message>, {
    ...option,
  });
};

export const notifyInfo: Notify = (message, option = {}) => {
  toast.info(<Message>{message}</Message>, {
    ...option,
  });
};

window.notifySuccess = notifySuccess;
window.notifyError = notifyError;
