import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Stepper } from "@material-ui/core";
import { useMutation } from "@apollo/client";

import Modal from "components/Modal";
import Grid from "components/Grid";
import Button from "components/Button";
import Typography from "components/Typography";
import AccordionStepper from "components/AccordionStepper";
import COLORS from "constants/Colors";
import { REQUEST_WITHDRAW } from "graphql/deeplePay/mutation";
import { BankInfo } from "types/DeeplePay";
import { Device } from "types/Device";
import useDevice from "utils/hooks/useDevice";
import { ProjectIdType } from "types/Project";
import WithdrawalCardContent from "./WithdrawalCardContent";
import WithdrawalStatusCard from "./WithdrawalStatusCard";
import { MobileFooter, MobileDetail, MobileWrapper } from "../KYCPopup/styled";
import { StepItem, StepperItem, WithdrawButton } from "./styled";
import { MAXIMUM_DEEPLE_PAY_WITHDRAW_AMOUNT, MINIMUM_DEEPLE_PAY_WITHDRAW_AMOUNT } from "./config";

type WithdrawalModalProps = {
  isOpen: boolean;
  onClose: () => void;
  balance: number;
  bankInfo: BankInfo;
  feeRate: number;
  refetchDeeplePayWallet: () => void;
};

const WithdrawalModal: FC<WithdrawalModalProps> = (props) => {
  const { isOpen, onClose, bankInfo, balance, feeRate, refetchDeeplePayWallet } = props;
  const { projectId } = useParams<ProjectIdType>();
  const { bank, bankAccountName, bankAccountNo } = bankInfo;
  const device = useDevice();
  const isMobile = device === Device.MOBILE;
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);

  const [requestWithdraw, { loading }] = useMutation(REQUEST_WITHDRAW, {
    onCompleted: () => {
      setStepIndex(stepIndex + 1);
      setIsError(false);
    },
    onError: () => {
      setIsError(true);
      setStepIndex(stepIndex + 1);
    },
  });
  const [stepIndex, setStepIndex] = useState(0);
  const [withdrawAmount, setWithdrawAmount] = useState<number | null>(null);

  const handleClose = () => {
    setStepIndex(0);
    setWithdrawAmount(null);
    onClose();
  };

  const handleClickNext = () => {
    setStepIndex(stepIndex + 1);
  };

  const handleSubmit = async () => {
    await requestWithdraw({ variables: { projectId, amount: withdrawAmount } });
    await refetchDeeplePayWallet();
  };

  const handleClickBack = () => {
    setStepIndex(stepIndex - 1);
  };

  const handleChangeWithdrawAmount = (inputAmount: number) => {
    const newWithdrawAmount = inputAmount || null;

    setWithdrawAmount(newWithdrawAmount);
  };

  const steps = ["withdrawalModal.step1.title", "withdrawalModal.step2.title"];
  const isWithdrawAmountExceedBalance = Number(withdrawAmount) > balance;
  const isWithdrawAmountExceedLimit = Number(withdrawAmount) > MAXIMUM_DEEPLE_PAY_WITHDRAW_AMOUNT;
  const isWithdrawAmountLessThanThreshold =
    Boolean(withdrawAmount) && Number(withdrawAmount) < MINIMUM_DEEPLE_PAY_WITHDRAW_AMOUNT;

  const isNextButtonDisabled =
    !withdrawAmount ||
    isWithdrawAmountExceedBalance ||
    isWithdrawAmountExceedLimit ||
    isWithdrawAmountLessThanThreshold;

  return (
    <Modal
      fullScreen={isMobile}
      isOpen={isOpen}
      onClose={handleClose}
      backgroundColor={COLORS.Light}
      title={t("withdrawalModal.title")}
    >
      {!isMobile && (
        <Grid container className="p-4">
          <Grid item container justify="center">
            <Stepper className="p-0 mb-2 w-100" activeStep={stepIndex} alternativeLabel>
              {steps.map((label) => (
                <StepItem key={label}>
                  <StepperItem>
                    <Typography variant="title8">{t(label)}</Typography>
                  </StepperItem>
                </StepItem>
              ))}
            </Stepper>
          </Grid>
          <Grid item className="pt-2" xs={12}>
            {stepIndex < 2 && (
              <WithdrawalCardContent
                accountName={bankAccountName}
                accountNumber={bankAccountNo}
                balance={balance}
                bankName={bank}
                feeRate={feeRate}
                isConfirm={stepIndex === 1}
                isWithdrawAmountExceedBalance={isWithdrawAmountExceedBalance}
                isWithdrawAmountExceedLimit={isWithdrawAmountExceedLimit}
                isWithdrawAmountLessThanThreshold={isWithdrawAmountLessThanThreshold}
                onChangeWithdrawAmount={handleChangeWithdrawAmount}
                requestWithdrawLoading={loading}
                withdrawAmount={withdrawAmount}
              />
            )}
            {stepIndex >= 2 && <WithdrawalStatusCard isMobile={isMobile} isError={isError} />}
          </Grid>
          <Grid item container justify="flex-end" className="pt-4">
            {stepIndex < 2 && (
              <Grid item xs={3}>
                <Button fullWidth onClick={stepIndex === 0 ? handleClose : handleClickBack} color="secondary">
                  {t(stepIndex === 0 ? "Cancel" : "Back")}
                </Button>
              </Grid>
            )}
            <Grid item xs={3} className="pl-2">
              {stepIndex < 2 ? (
                <Button
                  fullWidth
                  onClick={stepIndex === 0 ? handleClickNext : handleSubmit}
                  disabled={isNextButtonDisabled || loading}
                >
                  {t(stepIndex === 0 ? "Next" : "Submit")}
                </Button>
              ) : (
                <Button fullWidth onClick={handleClose}>
                  {t("Done")}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      {isMobile && (
        <MobileDetail container>
          <MobileWrapper>
            <Grid item xs={12}>
              <AccordionStepper stepNumber={1} isShowIcon={false} completedChildren={stepIndex > 0}>
                <Typography variant="title8">{t(steps[0])}</Typography>
              </AccordionStepper>
            </Grid>
            <Grid item>
              {stepIndex === 0 && (
                <WithdrawalCardContent
                  requestWithdrawLoading={loading}
                  feeRate={feeRate}
                  accountName={bankAccountName}
                  accountNumber={bankAccountNo}
                  bankName={bank}
                  isConfirm={false}
                  balance={balance}
                  onChangeWithdrawAmount={handleChangeWithdrawAmount}
                  withdrawAmount={withdrawAmount}
                  isWithdrawAmountExceedLimit={isWithdrawAmountExceedLimit}
                  isWithdrawAmountExceedBalance={isWithdrawAmountExceedBalance}
                  isWithdrawAmountLessThanThreshold={isWithdrawAmountLessThanThreshold}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <AccordionStepper stepNumber={2} isShowIcon={false} completedChildren={stepIndex > 1}>
                <Typography variant="title8">{t(steps[1])}</Typography>
              </AccordionStepper>
            </Grid>
            {stepIndex === 1 && (
              <WithdrawalCardContent
                requestWithdrawLoading={loading}
                feeRate={feeRate}
                accountName={bankAccountName}
                accountNumber={bankAccountNo}
                bankName={bank}
                isConfirm
                balance={balance}
                onChangeWithdrawAmount={handleChangeWithdrawAmount}
                withdrawAmount={withdrawAmount}
                isWithdrawAmountExceedLimit={isWithdrawAmountExceedLimit}
                isWithdrawAmountExceedBalance={isWithdrawAmountExceedBalance}
                isWithdrawAmountLessThanThreshold={isWithdrawAmountLessThanThreshold}
              />
            )}
            {stepIndex === 2 && <WithdrawalStatusCard isMobile={isMobile} isError={isError} />}
          </MobileWrapper>
          <MobileFooter>
            <Grid container justify="center">
              <Grid item className="mt-3 mb-3">
                {stepIndex < 2 && (
                  <WithdrawButton
                    className="cancel"
                    fullWidth
                    minWidth={130}
                    onClick={stepIndex === 0 ? handleClose : handleClickBack}
                    color="secondary"
                  >
                    {t(stepIndex === 0 ? "Cancel" : "Back")}
                  </WithdrawButton>
                )}
                {stepIndex < 2 ? (
                  <WithdrawButton
                    className="ml-2"
                    fullWidth
                    minWidth={130}
                    onClick={stepIndex === 0 ? handleClickNext : handleSubmit}
                    disabled={isNextButtonDisabled || loading}
                  >
                    {t(stepIndex === 0 ? "Next" : "Submit")}
                  </WithdrawButton>
                ) : (
                  <WithdrawButton className="ml-2" fullWidth onClick={handleClose}>
                    {t("Done")}
                  </WithdrawButton>
                )}
              </Grid>
            </Grid>
          </MobileFooter>
        </MobileDetail>
      )}
    </Modal>
  );
};

export default WithdrawalModal;
