import React, { FC, useState, useEffect, useRef } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";

import { AreaChart } from "components/Chart";
import Card from "components/Card";
import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import Typography from "components/Typography";
import { CircleIcon } from "components/Icon";
import Divider from "components/Divider";

import COLORS from "constants/Colors";
import { GRAPH_STAT_CONFIGS, GRAPH_COLORS, STAT_EVENT_NAME, TIME_UNIT } from "constants/Stat";

import { DASHBOARD_DATE_TIME_FORMAT } from "constants/DateTimeFormat";
import { GRAPH_STAT_COUNT, GRAPH_STAT_SUM } from "graphql/stat/query";

import { DataPointType, GraphStatKeyType } from "types/Stat";
import { tickFormatterToTime, tickFormatterToDate, tickFormatterToMonth } from "utils/chart";
import { GraphLegendButton } from "../index.styled";
import { getStatStartTime, getStatEndTime, getDateTimeDiff } from "../utils";

type GraphStatPropsType = {
  duration: number;
  projectId: string;
  startTime: string;
  endTime: string;
  platformFilter: Record<string, string | string[]>;
  periodOption: string;
};

const GraphStat: FC<GraphStatPropsType> = ({
  duration,
  projectId,
  startTime,
  endTime,
  platformFilter,
  periodOption,
}) => {
  const { t } = useTranslation();
  const [graphStatQuery, setGraphStatQuery] = useState(GRAPH_STAT_SUM);
  const [selectedGraphName, setSelectedGraphName] = useState(GRAPH_STAT_CONFIGS[0]);
  const timeoutIdRef = useRef<number>();
  const [getStat, { data, loading }] = useLazyQuery(graphStatQuery);

  const handleChangeGraphName = (name: GraphStatKeyType) => {
    switch (name) {
      case "totalSales":
        setGraphStatQuery(GRAPH_STAT_SUM);
        break;
      case "orders":
        setGraphStatQuery(GRAPH_STAT_COUNT);
        break;
      case "customers":
        setGraphStatQuery(GRAPH_STAT_COUNT);
        break;
      default:
        setGraphStatQuery(GRAPH_STAT_SUM);
    }
    setSelectedGraphName(name);
  };

  let isShowHour = duration === 0 || duration === 1;
  let tickFormatter = isShowHour ? tickFormatterToTime : tickFormatterToDate;

  if (duration === -1) {
    const timeDiff = getDateTimeDiff(startTime, endTime);

    isShowHour = timeDiff <= 1;
    if (timeDiff > 180) {
      tickFormatter = tickFormatterToMonth;
    } else if (timeDiff > 1) {
      tickFormatter = tickFormatterToDate;
    } else {
      tickFormatter = tickFormatterToTime;
    }
  }

  useEffect(() => {
    const DEFAULT_TIMEOUT = 300;

    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = undefined;
    }

    const timeoutId = setTimeout(() => {
      const eventName =
        selectedGraphName === GRAPH_STAT_CONFIGS[2] ? STAT_EVENT_NAME.NEW_CUSTOMER : STAT_EVENT_NAME.ORDER_PAID;
      let timeUnit;
      let start;
      let end;

      if (duration === -1) {
        const daysDiff = getDateTimeDiff(startTime, endTime);

        if (daysDiff > 1000) {
          timeUnit = TIME_UNIT.YEAR;
        } else if (daysDiff > 180) {
          timeUnit = TIME_UNIT.MONTH;
        } else if (daysDiff > 45) {
          timeUnit = TIME_UNIT.WEEK;
        } else if (daysDiff > 1) {
          timeUnit = TIME_UNIT.DAY;
        } else {
          timeUnit = TIME_UNIT.HOUR;

          const hoursDiff = getDateTimeDiff(startTime, endTime, "hours");
          if (hoursDiff < 1) {
            timeUnit = TIME_UNIT.MINUTE;
          }
        }

        start = moment(startTime, DASHBOARD_DATE_TIME_FORMAT).utc().toISOString();
        end = moment(endTime, DASHBOARD_DATE_TIME_FORMAT).utc().toISOString();
      } else {
        timeUnit = isShowHour ? TIME_UNIT.HOUR : TIME_UNIT.DAY;
        start = getStatStartTime(duration);
        end = getStatEndTime(duration);
      }

      getStat({
        variables: {
          name: eventName,
          projectId,
          start,
          end,
          label: platformFilter,
          timeUnit,
          option: { timeField: periodOption },
        },
      });

      // componentUnmounted
      return () => {
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
          timeoutIdRef.current = undefined;
        }
      };
    }, DEFAULT_TIMEOUT);

    timeoutIdRef.current = timeoutId;
  }, [duration, selectedGraphName, startTime, endTime, getStat, projectId, platformFilter, isShowHour, periodOption]);

  const formattedData =
    data &&
    data.graphStat.dataPoints.map(({ timestamp, value }: DataPointType) => ({
      xAxis: Number(moment(timestamp).format("x")),
      yAxis: value,
    }));

  return (
    <Card noShadow>
      <Grid container className="p-2">
        <Grid item container xs={12} spacing={1} justify="space-between" alignItems="center" className="pb-3">
          <Grid item xs={12} sm={3}>
            <Typography variant="title2">{t("statWidget.title.statistics")}</Typography>
          </Grid>
          <Grid item container xs={12} sm={9} spacing={1} justify="flex-end">
            {GRAPH_STAT_CONFIGS.map((name: GraphStatKeyType) => {
              const isSelected = selectedGraphName === name;
              const labelColor = isSelected ? "dark" : "grey";
              const iconColor = isSelected ? GRAPH_COLORS[name] : COLORS.LightBlue;

              return (
                <Grid key={name} item>
                  <GraphLegendButton isSelected={isSelected} onClick={() => handleChangeGraphName(name)}>
                    <Grid item container alignItems="center">
                      <Grid item>
                        <CircleIcon color={iconColor} size="s" />
                      </Grid>
                      <Grid item>
                        <Typography variant="title7" color={labelColor} className="d-flex align-items-center">
                          {t(`graphStat.legend.label.${name}`)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </GraphLegendButton>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider thickness="0.5px" className="m-0" />
        </Grid>
        <Grid item container xs={12} justify="center" className="pt-3">
          {loading ? (
            <CircularProgress className="m-4" />
          ) : (
            <AreaChart
              height={300}
              data={formattedData}
              color={GRAPH_COLORS[selectedGraphName]}
              scale="time"
              tickFormatter={tickFormatter}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default GraphStat;
