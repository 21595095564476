import * as React from "react";

const SvgIcClose = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.28 8.219l6.716 6.717 6.719-6.717a.75.75 0 0 1 1.06 1.06l-6.718 6.717 6.718 6.719a.75.75 0 0 1-1.06 1.06l-6.719-6.718-6.716 6.718a.75.75 0 0 1-1.061-1.06l6.717-6.719L8.22 9.28a.75.75 0 1 1 1.06-1.061z"
    />
  </svg>
);

export default SvgIcClose;
