import React, { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import Chip from "@material-ui/core/Chip";

import Grid from "components/Grid";
// import ButtonBase from "components/ButtonBase";
// import CopyText from "components/CopyText";
import Typography from "components/Typography";

import { DEFAULT_AVATAR_IMG } from "constants/Image";
import { COLORS } from "constants/Colors";
import { Member, MEMBER_ROLE, GenerateOTPType } from "types/Setting";
import { SvgIcon } from "components/Icon";
import {
  IcMore, // IcError
} from "components/SvgIcons";
import {
  MemberListItem,
  MemberAvatar, // LineNameTag, CloseIconWrapper
} from "../styled";

type MemberItemPropsType = {
  members: Member[];
  isOwner: boolean;
  generateOTP: GenerateOTPType;
  userId?: string;
  onClickMenuButton: (event: MouseEvent<HTMLElement>, memberId: string, role: string) => void;
  onClickRemoveNotification: (memberId: string) => void;
  onClickGenerateOTP: (memberId: string) => void;
};

const MemberItem: FC<MemberItemPropsType> = ({
  members,
  isOwner,
  onClickMenuButton,
  // generateOTP,
  // userId,
  // onClickRemoveNotification,
  // onClickGenerateOTP,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {members.map((member: Member) => {
        return (
          <MemberListItem key={member.id} container justify="space-between" id={member.user.displayName}>
            <Grid item container alignContent="center" alignItems="center" justify="center" className="p-1" xs={3}>
              <Grid item>
                <MemberAvatar alt={member.user.displayName} src={member.user.avatar || DEFAULT_AVATAR_IMG} />
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={8} className="pl-2">
              <Grid item>
                <Typography variant="title7" className="pt-1">
                  {member.user.displayName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" className="pt-1">
                  {member.email}
                  {!member.userId && (
                    <Chip className="ml-2" size="small" label={t("Pending")} variant="outlined" color="secondary" />
                  )}
                </Typography>
              </Grid>

              {/* TODO: when enable generateOTP for line account  */}
              {/* <Grid item>
                <Grid container className="pt-1">
                  {member.isEnableNotification && member.lineName ? (
                    <Grid item>
                      <LineNameTag>
                        <Grid container>
                          <Grid item>{member.lineName}</Grid>
                          {isOwner && (
                            <Grid item>
                              <CloseIconWrapper onClick={() => onClickRemoveNotification(member.id)}>
                                <SvgIcon component={IcError} className="p-0" fontSize="inherit" />
                              </CloseIconWrapper>
                            </Grid>
                          )}
                        </Grid>
                      </LineNameTag>
                    </Grid>
                  ) : (
                    <Grid item>
                      {generateOTP && generateOTP.userId === member.userId ? (
                        <Typography variant="body2" color={COLORS.Primary}>
                          {generateOTP.OTP}
                          <CopyText value={generateOTP.OTP} />
                        </Typography>
                      ) : (
                        <>
                          {member.userId && (isOwner || userId === member.userId) && (
                            <ButtonBase onClick={() => onClickGenerateOTP(member.userId)}>
                              <Typography variant="body2" color={COLORS.Primary}>
                                {t("Generate key")}
                              </Typography>
                            </ButtonBase>
                          )}
                        </>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid> */}
            </Grid>

            {isOwner ? (
              <Grid item container alignContent="flex-start" alignItems="flex-start" justify="flex-end" xs={1}>
                <div
                  onClick={(e: React.MouseEvent<HTMLElement>) => onClickMenuButton(e, member.id, member.role)}
                  className="px-2"
                >
                  <SvgIcon component={IcMore} fontSize="small" htmlColor={COLORS.DarkMed} />
                </div>
              </Grid>
            ) : (
              <>
                {member.role !== MEMBER_ROLE.OWNER ? (
                  <Grid item container alignContent="flex-start" alignItems="flex-start" justify="flex-end" xs={1}>
                    <div
                      onClick={(e: React.MouseEvent<HTMLElement>) => onClickMenuButton(e, member.id, member.role)}
                      className="px-2"
                    >
                      <SvgIcon component={IcMore} fontSize="small" htmlColor={COLORS.DarkMed} />
                    </div>
                  </Grid>
                ) : (
                  <Grid item container alignContent="flex-start" alignItems="flex-start" justify="flex-end" xs={1} />
                )}
              </>
            )}
          </MemberListItem>
        );
      })}
    </>
  );
};

export default MemberItem;
