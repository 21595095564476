import React from "react";
import styled from "styled-components";

export const Bullet = styled(({ color: _color, ...rest }) => <span {...rest} />).attrs(({ color }) => {
  return { color };
})`
  min-width: 6px;
  min-height: 6px;
  max-width: 6px;
  max-height: 6px;
  background-color: ${({ color }) => color};
  border-radius: 10px;
  display: inline-block;
  vertical-align: middle;
  margin: 6px;
`;

export const Title = styled.span`
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.COLORS.DarkMed};
  }
`;
