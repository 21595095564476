import { Grid } from "@material-ui/core";
import styled from "styled-components";

import { SIDE_NAV_WIDTH_DESKTOP } from "constants/Layout";
import { Z_INDEX } from "constants/ZIndex";

export const BankPaymentTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 0;
  color: ${({ theme }) => theme.COLORS.Dark};
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

export const BankPaymentWrapper = styled(Grid)`
  margin: 8px 0;
  border-style: solid;
  border-color: transparent;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.COLORS.White};
`;

export const DeeplePayChannelWrapper = styled.div`
  position: relative;
  border-radius: 8px;
`;

export const DeeplePayDetailWrapper = styled.div`
  display: flex;
  padding: 16px 0;
`;

export const DeeplePayLearnMoreLink = styled.a`
  color: ${({ theme }) => theme.COLORS.Primary};
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  cursor: pointer;
`;

export const DeeplePayWarning = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 8px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  min-height: 52px;
  font-size: 14px;
  line-height: 20px;
  background-color: ${({ theme }) => theme.COLORS.White};

  > svg {
    margin-right: 16px;
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.COLORS.DarkLight};
  }
`;

export const DetailContent = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: ${({ theme }) => theme.COLORS.Apple};
  font-size: 14px;
  line-height: 20px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(202, 210, 228, 0.5);
`;

export const ExampleLink = styled.a`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.COLORS.Primary};
  font-size: 14px;
  font-family: "Graphik Medium";
  line-height: 20px;
  cursor: pointer;
`;

export const FeeBox = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;

export const Item = styled(Grid)`
  border: 1px solid rgba(202, 210, 228, 0.5); // To instead 0.5px
  border-radius: 8px;
  max-width: 935px;
  height: fit-content;
  background-color: ${({ theme }) => theme.COLORS.White};

  &.mobile {
    border-radius: 0;
    padding: 0;
    max-width: 100%;
  }

  &.notification {
    display: inline-flex;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
  }
`;

export const Option = styled(Grid)`
  background-color: ${({ theme }) => theme.COLORS.LightWhite};

  img {
    width: 32px;
    height: 32px;
  }

  &.first {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  &.line {
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  }

  &.last {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

export const RadioBoxGroup = styled.div`
  padding-top: 8px;
  padding-bottom: 32px;
  height: 152px;

  @media screen and (min-width: 1280px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 558px;
    height: 68px;
  }
`;

export const StickyButton = styled.div`
  position: fixed;
  left: 0;
  bottom: 24px;
  z-index: ${Z_INDEX.FLOATING_BUTTON};
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    left: ${SIDE_NAV_WIDTH_DESKTOP}px;
    bottom: 48px;
    padding-left: 48px;
    padding-right: 48px;
    box-sizing: content-box;
    max-width: 935px;
  }
`;

export const TextItem = styled(Grid)`
  margin-top: 32px;
  max-width: 935px;
`;

export const Wrapper = styled(Grid)`
  padding-top: 48px;
  padding-right: 48px;
  padding-bottom: 120px;
  padding-left: 48px;
  width: 100%;

  &.mobile {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 96px;
    padding-left: 0;
  }
`;
