import styled from "styled-components";

const Badge = styled.div`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.COLORS.Badge};
  padding: 0 5px;
  color: ${({ theme }) => theme.COLORS.White};
  margin-left: 6px;
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  font-weight: normal;
`;

export default Badge;
