import styled from "styled-components";

export const StyledTitle = styled.h1`
  display: flex;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.COLORS.LightWhite};

  @media (max-width: 425px) {
    max-width: 124px;
  }
`;
