import { ColorsType } from "components/Theme/type";
import { COLORS } from "./Colors";

export interface ThemeType {
  default: ColorsType;
  darkMode: ColorsType;
}

export const THEME: ThemeType = {
  default: {
    Apple: COLORS.Apple,
    Badge: COLORS.Badge,
    BoxShadow: COLORS.BoxShadow,
    ChatBadgeWrapper: COLORS.ChatBadgeWrapper,
    Dark: COLORS.Dark,
    DarkGray: COLORS.DarkGray,
    DarkLight: COLORS.DarkLight,
    DarkMed: COLORS.DarkMed,
    DisabledSuccess: COLORS.DisabledSuccess,
    Error: COLORS.Error,
    Info: COLORS.Info,
    InfoYellowBackground: COLORS.InfoYellowBackground,
    InfoYellowBorder: COLORS.InfoYellowBorder,
    InfoYellow: COLORS.InfoYellow,
    IntentSolid: COLORS.Primary,
    IntentLiquid: COLORS.Blue,
    IntentGas: COLORS.LightBlueGray,
    IntentPlasma: COLORS.White,
    Light: COLORS.Light,
    LightBlue: COLORS.LightBlue,
    LightGray: COLORS.LightGray,
    LightWhite: COLORS.LightWhite,
    NewPrimary: COLORS.NewPrimary,
    NewPrimaryDark: COLORS.NewPrimaryDark,
    NewPrimaryLight: COLORS.NewPrimaryLight,
    Primary: COLORS.Primary,
    PrimaryDark: COLORS.PrimaryDark,
    PrimaryNude: COLORS.PrimaryNude,
    PrimaryTransparent: COLORS.PrimaryTransparent,
    Success: COLORS.Success,
    Warning: COLORS.Warning,
    White: COLORS.White,
    Accent: COLORS.Accent,
    TableShadow: COLORS.TableShadow,
    Facebook: COLORS.Facebook,
    Zircon: COLORS.Zircon,
    bgTrainingCard: COLORS.bgTrainingCard,
    borderTrainingCard: COLORS.borderTrainingCard,
    BrandeisBlue: COLORS.BrandeisBlue,
    DarkElectricBlue: COLORS.DarkElectricBlue,
    GhostWhite: COLORS.GhostWhite,
    Platinum: COLORS.Platinum,
    ChineseBlack: COLORS.ChineseBlack,
  },
  darkMode: {
    Apple: COLORS.Apple,
    Badge: COLORS.Badge,
    BoxShadow: COLORS.BoxShadow,
    ChatBadgeWrapper: COLORS.ChatBadgeWrapper,
    Dark: COLORS.Dark,
    DarkGray: COLORS.DarkGray,
    DarkLight: COLORS.DarkLight,
    DarkMed: COLORS.DarkMed,
    DisabledSuccess: COLORS.DisabledSuccess,
    Error: COLORS.Error,
    Info: COLORS.Info,
    InfoYellowBackground: COLORS.InfoYellowBackground,
    InfoYellowBorder: COLORS.InfoYellowBorder,
    InfoYellow: COLORS.InfoYellow,
    Light: COLORS.Light,
    LightBlue: COLORS.LightBlue,
    LightGray: COLORS.LightGray,
    LightWhite: COLORS.LightWhite,
    NewPrimary: COLORS.NewPrimary,
    NewPrimaryDark: COLORS.NewPrimaryDark,
    NewPrimaryLight: COLORS.NewPrimaryLight,
    Primary: COLORS.Dark,
    PrimaryDark: COLORS.Dark,
    PrimaryNude: COLORS.PrimaryNude,
    Success: COLORS.Success,
    Warning: COLORS.Warning,
    TableShadow: COLORS.TableShadow,
    Facebook: COLORS.Facebook,
    Zircon: COLORS.Zircon,
    BrandeisBlue: COLORS.BrandeisBlue,
    White: COLORS.White,
    DarkElectricBlue: COLORS.DarkElectricBlue,
    GhostWhite: COLORS.GhostWhite,
    Platinum: COLORS.Platinum,
    ChineseBlack: COLORS.ChineseBlack,
  },
};

export default THEME;
