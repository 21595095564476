import React, { FC } from "react";
import get from "lodash/get";
import moment from "moment";
import styled from "styled-components";
import COLORS from "constants/Colors";
import Grid from "components/Grid";
import Typography from "components/Typography";
import Divider from "components/Divider";
import { formatToReadablePrice } from "components/Message/utils/promotionUtil";
import { METHOD_TYPE } from "types/Shipping";
import { OrderState } from "types/Order";
import { HEADER_ORDER_DATE_FORMAT } from "constants/DateTimeFormat";
import { OrderCarouselType } from "./FlexOrderCarousel";
import { FlexMessageContent } from "./FlexMessage.style";
import FlexOrderHeader from "./FlexOrderHeader";
import FlexOrderProductSKU from "./FlexOrderProductSKU";

const MAXIMUM_PRODUCTS_DISPLAY = 2;
const BACKWARD_COMPATIBLE_PICKUPS = [METHOD_TYPE.PICK_UP, METHOD_TYPE.PICKUP].map(String);

type FlexOrderPropsType = {
  content: OrderCarouselType;
};

export const ReviewOrderWrapper = styled.div`
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  color: ${COLORS.LightBlue};
  background: ${COLORS.LightGray};
`;

const getShippingMethodAndCarrierFlex = (shippingMethod: string, shippingCarrierName: string) => {
  if (!shippingMethod) {
    return null;
  }
  let shippingMethodText;
  if (BACKWARD_COMPATIBLE_PICKUPS.includes(shippingMethod)) {
    // PICK_UP
    shippingMethodText = "รับสินค้าด้วยตัวเอง";
  } else {
    // STANDARD, EXPRESS
    if (!shippingCarrierName) {
      return null;
    }
    shippingMethodText = `สินค้าถูกจัดส่งโดย: ${shippingCarrierName}`;
  }
  return (
    <Grid item xs={12} className="px-2">
      <Typography variant="body4" color={COLORS.Dark} className="">
        {shippingMethodText}
      </Typography>
    </Grid>
  );
};

const getShippingPriceFlex = (
  shippingMethod: string,
  shippingPrice = 0,
  discountShippingPrice = 0,
  isPendingForShippingCost = false,
) => {
  if (!shippingMethod) {
    return null;
  }

  const shippingPriceAfterDiscount = Number(shippingPrice) - Number(discountShippingPrice);
  // if shipping price after discount  <= 0 then display 'ฟรี'
  let shippingPriceText = "ฟรี";
  if (shippingPriceAfterDiscount > 0) {
    shippingPriceText = `฿${formatToReadablePrice(shippingPriceAfterDiscount)}`;
  }

  if (isPendingForShippingCost) {
    shippingPriceText = `${OrderState.PENDING_FOR_SHIPPING_COST}`;
  }

  return (
    <Grid item xs={12} className="px-2">
      <Typography variant="title10" color={COLORS.DarkGray} className="">
        <Grid container justify="space-between" alignItems="center" alignContent="center">
          <div>ค่าขนส่ง</div>
          <div>{`${shippingPriceText}`}</div>
        </Grid>
      </Typography>
    </Grid>
  );
};

const FlexOrder: FC<FlexOrderPropsType> = (props) => {
  const { content } = props;

  const isPendingForShippingCost = content.state === OrderState.PENDING_FOR_SHIPPING_COST;
  const isShowShippingPriceFlex = content.shippingMethod !== METHOD_TYPE.PICK_UP;
  const shippedDetails = get(content.shippedDetail, "detail", []);
  const { carrierName, deliveryDate, otherCarrierName } = shippedDetails[0] || {};
  const shippingCarrierName = carrierName || otherCarrierName || "";

  let estimateDeliveryDate = "";
  // check shippedAt first
  const shippedDate = content.shippedAt || deliveryDate;
  if (shippedDate) {
    estimateDeliveryDate = moment(moment(new Date(shippedDate)).add(content.shippingDuration || 0, "days")).format(
      HEADER_ORDER_DATE_FORMAT,
    );
  }

  const hasOrderProducts = content.orderProducts && content.orderProducts.length;

  const hasMoreItems: boolean = hasOrderProducts
    ? content.orderProducts.length > MAXIMUM_PRODUCTS_DISPLAY
    : content.products.length > MAXIMUM_PRODUCTS_DISPLAY;
  const moreItemsCount: number = hasOrderProducts
    ? content.orderProducts.length - MAXIMUM_PRODUCTS_DISPLAY
    : content.products.length - MAXIMUM_PRODUCTS_DISPLAY;
  const updatedContent = hasOrderProducts
    ? { ...content, orderProducts: content.orderProducts.slice(0, MAXIMUM_PRODUCTS_DISPLAY) }
    : { ...content, products: content.products.slice(0, MAXIMUM_PRODUCTS_DISPLAY) };

  return (
    <FlexMessageContent>
      <Grid
        container
        justify="flex-start"
        alignItems="flex-start"
        alignContent="flex-start"
        className="d-flex"
        item
        xs={12}
      >
        <FlexOrderHeader content={content} />
        <FlexOrderProductSKU content={updatedContent} hasMoreItems={hasMoreItems} moreItemsCount={moreItemsCount} />
        <Grid item xs={12} className="px-2 pb-1">
          <Divider className="my-1" thickness="0.5px" />
        </Grid>
        <Grid item xs={12} className="px-2">
          <Typography variant="body4" color={COLORS.Error} className="">
            <Grid container justify="space-between" alignItems="center" alignContent="center">
              <div>ส่วนลด : </div>
              {Number(content.discountTotalPrice) > 0 ? (
                <div>{`-฿${formatToReadablePrice(content.discountTotalPrice)}`}</div>
              ) : (
                <div>{`฿${formatToReadablePrice(content.discountTotalPrice)}`}</div>
              )}
            </Grid>
          </Typography>
        </Grid>
        {isShowShippingPriceFlex
          ? getShippingPriceFlex(
              content.shippingMethod,
              content.shippingPrice,
              content.discountShippingPrice,
              isPendingForShippingCost,
            )
          : null}
        <Grid item xs={12} className="px-2">
          <Typography variant="title10" color={COLORS.Dark} className="">
            <Grid container justify="space-between" alignItems="center" alignContent="center">
              <div>ราคารวม : </div>
              <div>{`฿${formatToReadablePrice(content.grandTotal)}`}</div>
            </Grid>
          </Typography>
        </Grid>
        {getShippingMethodAndCarrierFlex(content.shippingMethod, shippingCarrierName)}

        {content.state === OrderState.COMPLETED && (
          <Grid item xs={12} className="px-2">
            <Typography variant="body4" color={COLORS.Dark} className="">
              {content.shippingMethod === METHOD_TYPE.PICK_UP ? `วันที่มารับสินค้า :` : `วันที่จัดส่ง :`}
              {moment(deliveryDate).format(HEADER_ORDER_DATE_FORMAT)}
            </Typography>
          </Grid>
        )}

        {content.shippingMethod !== METHOD_TYPE.PICK_UP && estimateDeliveryDate && (
          <Grid item xs={12} className="px-2">
            <Typography variant="body4" color={COLORS.Dark} className="">
              จะได้รับโดยประมาณ : {estimateDeliveryDate}
            </Typography>
          </Grid>
        )}
      </Grid>
    </FlexMessageContent>
  );
};

export default FlexOrder;
