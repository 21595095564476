import styled from "styled-components";

const List = styled.div`
  background-color: ${({ theme }) => theme.COLORS.White};
  > div {
    padding: 16px;
    height: 100%;
    border-bottom: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
    :first-child {
      border-top: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
    }
  }
`;

export default List;
