import styled from "styled-components";

const SelectorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: "5px 0px";
`;

export default SelectorWrapper;
