import React, { useState, MouseEvent, useEffect, useMemo } from "react";

import { Menu } from "components/Menu";
import Grid from "components/Grid";

import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { ALL_CATEGORY } from "graphql/category/query";
import { Project } from "types";
import { CategoryType } from "types/Category";
import get from "lodash/get";
import { ExportFileType } from "types/SalesReport";
import { FilterItemType, FILTER_ID } from "domain/Report/ReportFilter/types";
import { FilterCardWrapper, ButtonAddFilter } from "domain/Report/ReportFilter/Filter.styled";
import EditMenuOptionsItem from "domain/Report/ReportFilter/EditMenuOptionsItem";
import AddMenuOptionsItem from "domain/Report/ReportFilter/AddMenuOptionsItem";
import FilterListItems from "domain/Report/ReportFilter/FilterListItems";
import {
  getKeyName,
  getFilterValue,
  getSelectTypeFromFilterKey,
  getOptionsFromKey,
  displayFilterName,
} from "domain/Report/ReportFilter/FilterOrderStat.utils";
import { INITIAL_PRODUCT_FILTER_LISTS, MENU_OPTIONS } from "domain/Report/ProductFilter/config";
import { EXCLUDED_FILTER_ID_LISTS } from "domain/Report/ReportFilter/config";
import ExportCSVButton from "components/ExportCSVButton";
import { DisableFeature } from "pages/DisableFeature";
import useGetProject from "utils/hooks/useGetProject";

type CategoryConnectionType = {
  categories: {
    results: CategoryType[];
    total: number;
    __typename: string;
  };
};

const ProductFilter: React.FC<{
  setFilterValue: Function;
  isDisabledExportButton?: boolean;
  handleExportReports: (type: ExportFileType) => void;
  isLoadingExportReports: boolean;
  exportedNumber: string;
}> = (props) => {
  const { t } = useTranslation();
  const { projectId } = useParams<Project.ProjectIdType>();

  const { data } = useQuery<CategoryConnectionType>(ALL_CATEGORY, {
    skip: !projectId,
    variables: {
      projectId,
    },
  });

  const { setFilterValue, handleExportReports, isLoadingExportReports, isDisabledExportButton, exportedNumber } = props;
  const { isAdvancedSalesReportEnabled, isSupportLegacyPayment, isDeeplePayEnabled } = useGetProject(projectId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [filterList, setFilterList] = useState<FilterItemType[]>([]);

  const [categoriesOptionList, setCategoriesOptionList] = useState([]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetFilterList = (filterListData: FilterItemType[]) => {
    setFilterList(filterListData);
    handleClose();
  };

  const removeValue = (itemId: string) => {
    return setFilterList(filterList.filter((item) => item.id !== itemId));
  };

  const getFinalFilterResult = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any = {};
    filterList.forEach((filterItem) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(filterItem)) {
        if (key !== "id") {
          result[key] = value;
        }
      }
    });
    return result;
  };

  useEffect(() => {
    setFilterList(INITIAL_PRODUCT_FILTER_LISTS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilterValue(getFinalFilterResult());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterList]);

  useEffect(() => {
    const categoriesListResults = get(data, "categories.results", []);
    if (categoriesListResults) {
      // categoriesListResults.map((category: { id: number; name: string }) => category.id)
      setCategoriesOptionList(categoriesListResults);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const initialFilters = useMemo(
    () =>
      filterList.filter((filterItem) => {
        const keyName = getKeyName(filterItem);
        return EXCLUDED_FILTER_ID_LISTS.includes(keyName as FILTER_ID);
      }),
    [filterList],
  );

  const exportButton = (
    <ExportCSVButton
      className="mr-3"
      isDisabled={isDisabledExportButton}
      isLoading={isLoadingExportReports}
      subLabel={t("product.import.button.subLabel")}
      exportedNumber={exportedNumber || t("ALL")}
      onExport={handleExportReports}
    />
  );

  return (
    <Grid container alignItems="center" alignContent="flex-start" style={{ zIndex: 2 }} className="pb-2">
      {/* zIndex: 2 will be on top of table styled */}

      {initialFilters.map((filterItem: FilterItemType) => {
        const keyName = getKeyName(filterItem);
        return (
          <Grid item key={`${filterItem.id}-fixed`}>
            <FilterCardWrapper
              style={
                keyName === FILTER_ID.DATE_RANGE
                  ? { border: 0, minHeight: "46px", backgroundColor: "unset" }
                  : { minHeight: "46px" }
              }
              className="mr-2 my-1"
            >
              <Grid container justify="flex-start" alignContent="center" alignItems="center">
                <EditMenuOptionsItem
                  key={filterItem.id}
                  title={displayFilterName(filterItem)}
                  selectType={getSelectTypeFromFilterKey(filterItem)}
                  options={getOptionsFromKey(
                    filterItem,
                    filterList,
                    categoriesOptionList,
                    isSupportLegacyPayment,
                    isDeeplePayEnabled,
                  )}
                  categoriesOptionList={categoriesOptionList}
                  keyName={getKeyName(filterItem) || ""}
                  filterValue={getFilterValue(filterItem)}
                  filterList={filterList}
                  setValue={handleSetFilterList}
                  editId={filterItem.id}
                />
              </Grid>
            </FilterCardWrapper>
          </Grid>
        );
      })}

      <Grid item className="py-2">
        {isAdvancedSalesReportEnabled ? exportButton : <DisableFeature>{exportButton}</DisableFeature>}
      </Grid>

      <Grid container justify="flex-start" alignItems="center" alignContent="center">
        <FilterListItems
          filterList={filterList}
          projectId={projectId}
          removeValue={removeValue}
          setValue={handleSetFilterList}
        />
        <ButtonAddFilter onClick={handleClick} className="px-2">
          + {t("AddFilter")}
        </ButtonAddFilter>
      </Grid>
      <Grid item>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          {MENU_OPTIONS.map((menuOption) => (
            <AddMenuOptionsItem
              key={menuOption.key}
              title={menuOption.title}
              filterKey={menuOption.filterKey}
              selectType={menuOption.selectType}
              options={menuOption.options}
              setFilterList={handleSetFilterList}
              filterList={filterList}
              handleCloseAddMenu={handleClose}
            />
          ))}
        </Menu>
      </Grid>
    </Grid>
  );
};

export default ProductFilter;
