import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { FieldError } from "react-hook-form/dist/types";
import produce from "immer";
import get from "lodash/get";
import { PRODUCT_OPTION_LIMIT, PRODUCT_OPTION_CHOICE_LIMIT } from "config";
import { INITIAL_CHOICE, INITIAL_OPTION } from "constants/Product";
import Typography from "components/Typography";
import Card from "components/Card";
import Grid from "components/Grid";
import Button from "components/Button";
import ErrorText from "components/ErrorText";
import ConfirmationModal from "components/ConfirmationModal";

import { IcMore } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";
import COLORS from "constants/Colors";
import { ValueOf } from "types/Common";
import {
  ProductPropertiesType,
  ProductVariationsType,
  AddProductSKUType,
  ProductOptionType,
  ProductOptionChoiceType,
  OrderProductOption,
  ProductOptionKey,
} from "types/Product";
import useToggle from "utils/hooks/useToggle";
import { ProductSKUCardWrapper, ProductSKUCardPlaceholderWrapper, DashedCircle } from "./styled";
import VariationCard from "../VariationCard";

type AddProductSKUsPropsType = {
  handleSubmitForm: Function;
  productCode: string;
  productId?: string;
  productIsActive: boolean;
  productIsFree: boolean;
  productPrice: number;
  productSKUs: AddProductSKUType[];
  productSKUShadowId?: string | null;
  propertyListData: ProductPropertiesType[];
  selectedSKUFirstItem: string;
  selectedSKUSecondItem: string;
  setProductSKUs: Function;
  setSelectedSKUFirstItem: Function;
  setSelectedSKUSecondItem: Function;
  setValue: Function;
  setVariationListData: Function;
  variationListData: ProductVariationsType[];
};

export const ProductSKUsTab: React.FC<AddProductSKUsPropsType> = ({
  handleSubmitForm,
  productSKUs,
  setProductSKUs,
  variationListData,
}) => {
  const { errors } = useFormContext();
  const { t } = useTranslation();
  const indexOptionRef = useRef<number>();
  const {
    isOpen: isOpenRemoveOptionModal,
    handleToggle: handleToggleRemoveOptionModal,
    handleClose: handleCloseRemoveOptionModal,
  } = useToggle();

  const haveOptions = productSKUs && productSKUs[0] && productSKUs[0].options && productSKUs[0].options.length > 0;
  const handleChangeOption = (
    indexOption: number,
    fieldName: keyof ProductOptionType,
    value: ValueOf<ProductOptionType>,
  ) => {
    const newProductSKUs = produce(productSKUs, (draft) => {
      if (draft[0].options && draft[0].options[indexOption]) {
        (draft[0].options[indexOption][fieldName] as ValueOf<ProductOptionType>) = value;

        if (fieldName === ProductOptionKey.IS_MULTIPLE_CHOICES) {
          if (value) {
            draft[0].options[indexOption].isRequired = false;
          } else {
            draft[0].options[indexOption].isRequired = true;
          }
        }
      }
    });

    setProductSKUs(newProductSKUs);
  };

  const handleChangeChoice = (
    indexOption: number,
    choiceIndex: number,
    fieldName: keyof ProductOptionChoiceType,
    value: ValueOf<ProductOptionChoiceType>,
  ) => {
    const newProductSKUs = produce(productSKUs, (draft) => {
      if (
        draft[0].options &&
        draft[0].options[indexOption] &&
        draft[0].options[indexOption].choices[choiceIndex] &&
        draft[0].options[indexOption].choices[choiceIndex][fieldName] != null
      ) {
        (draft[0].options[indexOption].choices[choiceIndex][fieldName] as ValueOf<ProductOptionChoiceType>) = value;

        if (fieldName === ProductOptionKey.IS_ACTIVE && value === false) {
          const activeChoices = draft[0].options[indexOption].choices.filter(({ isActive }) => isActive);
          if (draft[0].options[indexOption].maximum > activeChoices.length && activeChoices.length !== 0) {
            draft[0].options[indexOption].maximum = activeChoices.length;
          }

          if (activeChoices.length === 0 && draft[0].options[indexOption].isActive === true) {
            draft[0].options[indexOption].isActive = false;
          }
        }
      }
    });

    setProductSKUs(newProductSKUs);
  };

  const areOptionsOverLimit = productSKUs[0].options && productSKUs[0].options.length >= PRODUCT_OPTION_LIMIT;
  const optionsNameError = get(errors, `productSKUs[0].options`);
  return (
    <>
      <ProductSKUCardWrapper>
        {!haveOptions && (
          <ProductSKUCardPlaceholderWrapper>
            <DashedCircle>
              <SvgIcon component={IcMore} className="mt-1 ml-2" htmlColor={COLORS.DarkLight} fontSize="small" />
            </DashedCircle>
            <Typography variant="title8" className="text-center mb-2" color={COLORS.DarkMed}>
              {t("product.addFoodForm.noVariationTitle")}
            </Typography>
            <Typography variant="body4" className="text-center" color={COLORS.DarkLight}>
              {t("product.addFoodForm.noVariationGuide")}
              <br />- {t("optional")} -
            </Typography>
          </ProductSKUCardPlaceholderWrapper>
        )}

        {haveOptions && (
          <Card borderRadius={0} noShadow>
            <Grid container className="my-4" justify="space-between">
              <Typography variant="title2">{t("VariationsTitle")}</Typography>
            </Grid>

            <Grid container spacing={2}>
              {haveOptions &&
                (productSKUs[0].options as ProductOptionType[]).map((option: ProductOptionType, indexOption) => {
                  const isTheLastOption = indexOption + 1 === (productSKUs[0].options as ProductOptionType[]).length;

                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Grid item xs={12} key={indexOption}>
                      <VariationCard
                        order={indexOption + 1}
                        isTheLast={isTheLastOption}
                        option={option}
                        onClickAddOption={() => {
                          if (
                            productSKUs[0].options &&
                            productSKUs[0].options[indexOption] &&
                            productSKUs[0].options[indexOption].choices &&
                            productSKUs[0].options[indexOption].choices.length >= PRODUCT_OPTION_CHOICE_LIMIT
                          ) {
                            return;
                          }

                          const newProductSKUs = produce(productSKUs, (draft) => {
                            if (draft[0].options && draft[0].options[indexOption]) {
                              draft[0].options[indexOption].choices.push(INITIAL_CHOICE);
                            }
                          });

                          setProductSKUs(newProductSKUs);
                        }}
                        onToggleRequiredSwitch={() => {
                          if (productSKUs[0].options && productSKUs[0].options[indexOption]) {
                            handleChangeOption(
                              indexOption,
                              ProductOptionKey.IS_REQUIRED,
                              !productSKUs[0].options[indexOption].isRequired,
                            );
                          }
                        }}
                        onToggleActiveSwitch={() => {
                          if (productSKUs[0].options && productSKUs[0].options[indexOption]) {
                            handleChangeOption(
                              indexOption,
                              ProductOptionKey.IS_ACTIVE,
                              !productSKUs[0].options[indexOption].isActive,
                            );
                          }
                        }}
                        onClickChoiceType={(isMultipleChoices: boolean) => {
                          handleChangeOption(indexOption, ProductOptionKey.IS_MULTIPLE_CHOICES, isMultipleChoices);
                        }}
                        onChangeName={(name: string) => {
                          handleChangeOption(indexOption, ProductOptionKey.NAME, name);
                        }}
                        onChangeMaximum={(maximum: number) => {
                          handleChangeOption(indexOption, ProductOptionKey.MAXIMUM, maximum);
                        }}
                        onChangeChoice={(
                          choiceIndex: number,
                          fieldName: keyof ProductOptionChoiceType,
                          value: ValueOf<ProductOptionChoiceType>,
                        ) => {
                          handleChangeChoice(indexOption, choiceIndex, fieldName, value);
                        }}
                        onClickRemoveChoice={(choiceIndex: number) => {
                          const newProductSKUs = produce(productSKUs, (draft) => {
                            if (draft[0].options && draft[0].options[indexOption]) {
                              draft[0].options[indexOption].choices.splice(choiceIndex, 1);

                              if (
                                draft[0].options[indexOption].maximum > draft[0].options[indexOption].choices.length &&
                                draft[0].options[indexOption].choices.length !== 0
                              ) {
                                draft[0].options[indexOption].maximum = draft[0].options[indexOption].choices.length;
                              }
                            }
                          });

                          setProductSKUs(newProductSKUs);
                        }}
                        onClickRemove={() => {
                          indexOptionRef.current = indexOption;
                          handleToggleRemoveOptionModal();
                        }}
                        onClickChangeOrder={(orderProductOption: OrderProductOption) => {
                          if (productSKUs[0].options && productSKUs[0].options[indexOption]) {
                            const newProductSKUs = produce(productSKUs, (draft) => {
                              const removedOption = (draft[0].options as ProductOptionType[]).splice(indexOption, 1);
                              if (orderProductOption === OrderProductOption.ORDER_UP) {
                                (draft[0].options as ProductOptionType[]).splice(indexOption - 1, 0, removedOption[0]);
                              } else {
                                (draft[0].options as ProductOptionType[]).splice(indexOption + 1, 0, removedOption[0]);
                              }
                            });

                            setProductSKUs(newProductSKUs);
                          }
                        }}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Card>
        )}

        <Grid container className="my-3">
          <Grid item xs={12} className="px-3 my-1">
            {optionsNameError && <ErrorText>{t((optionsNameError as FieldError).message || "")}</ErrorText>}
          </Grid>
          {variationListData && variationListData.length <= 50 && (
            <Grid item xs={12} className="px-3 my-1">
              <Button
                color="ghost"
                fullWidth
                disabled={areOptionsOverLimit}
                onClick={() => {
                  if (areOptionsOverLimit) {
                    return;
                  }

                  const newProductSKUs = produce(productSKUs, (draft) => {
                    if (!draft[0].options) {
                      draft[0].options = [];
                    }

                    draft[0].options.push(INITIAL_OPTION);
                  });

                  setProductSKUs(newProductSKUs);
                }}
              >
                + {t("Add new variation")}
              </Button>
            </Grid>
          )}

          <Grid item xs={12} className="px-3 my-1">
            <Button fullWidth onClick={handleSubmitForm}>
              {t("Save")}
            </Button>
          </Grid>
        </Grid>
      </ProductSKUCardWrapper>
      <ConfirmationModal
        title={t("Are you sure you want to remove ?")}
        isOpen={isOpenRemoveOptionModal}
        onClose={handleCloseRemoveOptionModal}
        onSubmit={() => {
          const indexOption = indexOptionRef.current;
          if (indexOption == null) {
            return;
          }

          const newProductSKUs = produce(productSKUs, (draft) => {
            if (draft[0].options && draft[0].options[indexOption]) {
              draft[0].options.splice(indexOption, 1);
            }
          });

          setProductSKUs(newProductSKUs);
          handleCloseRemoveOptionModal();
        }}
      />
    </>
  );
};
