import React, { FC } from "react";
import { OptionsType, OptionType, ActionMeta } from "@atlaskit/select";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import Card, { CardPropsType } from "components/Card";
import Typography from "components/Typography";
import MultiSelectWithSearchBox from "components/MultiSelectWithSearchBox";

import { SUPPORTED_LANGUAGE } from "constants/Language";
import { getFullNameFromProvinceCode, convertProvinceListToOptionFormat } from "../util";

type ShippingBoundaryPropsType = {
  excludeProvince: string[];
  optionsProvinceValue: OptionsType<OptionType>;
  handleChangeTagExcludeProvinceInput: Function;
  cardProps?: CardPropsType;
};

export const ShippingBoundary: FC<ShippingBoundaryPropsType> = (props) => {
  const { t, i18n } = useTranslation();

  const isThaiLanguage = i18n.language === SUPPORTED_LANGUAGE.TH;

  const { excludeProvince, optionsProvinceValue, handleChangeTagExcludeProvinceInput, cardProps } = props;

  return (
    <Card fullWidth className="px-4 mt-4" {...cardProps}>
      <Grid container item xs={12}>
        <Typography variant="body2" color="darkMed">
          {t("shipping.areasDisabledShipping")}
        </Typography>
      </Grid>
      <Grid container item xs={12} className="py-2">
        <Grid item xs={12} className="pb-2">
          <MultiSelectWithSearchBox
            isMulti
            isDisplayMinimumItem
            label={t("SelectProvinces")}
            placeholder={`${t("Select")} ${t("broadcast.location")}`}
            options={optionsProvinceValue}
            value={convertProvinceListToOptionFormat(excludeProvince || [], isThaiLanguage)}
            onChange={(event: OptionsType<OptionType>, action: ActionMeta<OptionType>) => {
              handleChangeTagExcludeProvinceInput(event || [], action);
            }}
          />
        </Grid>

        {Boolean(excludeProvince.length) && (
          <Grid item xs={12}>
            <Card fullWidth noShadow padding="10px 16px">
              <Typography variant="body3" color="primary">
                {t("shipping.excludedProvince")} ({excludeProvince.length})
              </Typography>
              <Grid item xs={12} className="py-1">
                <Typography variant="body3">
                  {excludeProvince.map((data) => `${getFullNameFromProvinceCode(data, isThaiLanguage)}`).join(" , ")}
                </Typography>
              </Grid>
            </Card>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};
