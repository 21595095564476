const chatTemplate = {
  en: {
    ADD_REPLY_SHORTCUT: "Add reply shortcut",
    ARE_YOU_SURE_TO_REMOVE: "Are you sure you want to remove ?",
    REPLY_SHORTCUT_HAS_REACHED_LIMIT: "The reply shortcut has reached the max limit.",
    REPLY_SHORTCUT: "Reply shortcut",
    CREATE_FAIL: "Create fail.",
    CREATE_SUCCESS: "Create successfully.",
    DELETE_FAIL: "Delete fail.",
    DELETE_SUCCESS: "Delete successfully.",
    DESCRIPTION: "Description",
    DUPLICATED_SHORTCUT_KEY: "Shortcut key is duplicated",
    EDIT_REPLY_SHORTCUT: "Edit reply shortcut",
    EXAMPLE_SHORTCUT: "E.g., /storeaddress, /arrival",
    LOADING: "Loading",
    NAME_IS_REQUIRED: "Name is a required field",
    NAME: "Name",
    NOT_ALLOW_SPECIAL_CHARACTER: "Not allow special character",
    REPLY: "Reply",
    RESPONSE_MESSAGE_IS_REQUIRED: "Response message is a required field",
    SAVE: "Save",
    SHORT_CUT_DOES_NOT_START_WITH_CHARACTER: "Short cut key should be start with English or Thai character with no space key",
    SHORTCUT_KEY_IS_REQUIRED: "Shortcut key is a required field",
    SHORTCUT_KEY: "Shortcut key",
    SHORTCUT_TITLE: "Give the shortcut a title",
    UPDATE_FAIL: "Update fail.",
    UPDATE_SUCCESS: "Update successfully.",
    WHAT_IS_REPLY_ABOUT: "What is the reply represented by this shortcut about?",
  },
  th: {
    ADD_REPLY_SHORTCUT: "เพิ่มคีย์ลัดการตอบ",
    ARE_YOU_SURE_TO_REMOVE: "คุณยืนยันที่จะลบหรือไม่ ?",
    REPLY_SHORTCUT_HAS_REACHED_LIMIT: "คีย์ลัดการตอบถึงจำนวนสูงสุดแล้ว",
    REPLY_SHORTCUT: "คีย์ลัดการตอบ",
    CREATE_FAIL: "การสร้าง ล้มเหลว",
    CREATE_SUCCESS: "การสร้าง สำเร็จ",
    DELETE_FAIL: "การลบ ล้มเหลว",
    DELETE_SUCCESS: "การลบ สำเร็จ",
    DESCRIPTION: "รายละเอียด",
    DUPLICATED_SHORTCUT_KEY: "คีย์ลัดนี้ถูกใช้ไปแล้ว",
    EDIT_REPLY_SHORTCUT: "แก้ไขคีย์ลัดการตอบ",
    EXAMPLE_SHORTCUT: "ตัวอย่างเช่น, /storeaddress, /arrival",
    LOADING: "กำลังโหลด",
    NAME_IS_REQUIRED: "โปรดกรอกชื่อ",
    NAME: "ชื่อ",
    NOT_ALLOW_SPECIAL_CHARACTER: "ไม่อนุญาตให้ใช้ตัวอักษรพิเศษ",
    REPLY: "คำตอบ",
    RESPONSE_MESSAGE_IS_REQUIRED: "โปรดกรอกข้อความอย่างน้อย 1 ข้อความ",
    SAVE: "บันทึก",
    SHORT_CUT_DOES_NOT_START_WITH_CHARACTER: "คีย์ลัดควรเริ่มด้วยตัวอักษรภาษาอังกฤษหรือภาษาไทยโดยไม่มีช่องว่าง",
    SHORTCUT_KEY_IS_REQUIRED: "โปรดกรอกคีย์ลัด",
    SHORTCUT_KEY: "คีย์ลัด",
    SHORTCUT_TITLE: "ชื่อคีย์ลัดการตอบ",
    UPDATE_FAIL: "การอัปเดต ล้มเหลว",
    UPDATE_SUCCESS: "การอัปเดต สำเร็จ",
    WHAT_IS_REPLY_ABOUT: "รูปแบบการตอบนี้เกี่ยวกับอะไร",
  },
};

export default chatTemplate;
