import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Modal from "components/Modal";
import CircularProgress from "components/CircularProgress";

import { PROJECT_SETTING } from "graphql/setting/query";
import { DEFAULT_SHIPPING_METHOD } from "graphql/shipping/query";
import { ProjectIdType } from "types/Project";
import { DefaultShippingMethodQueryType, CALCULATE } from "types/Shipping";

import { DeliveryPeriodCard } from "./DeliveryPeriodCard";

export const DeliveryPeriod: React.FC = () => {
  const { projectId } = useParams<ProjectIdType>();

  const { loading: loadingProjectData, data } = useQuery(PROJECT_SETTING, {
    skip: !projectId,
    variables: {
      projectId,
    },
  });

  const { loading: loadingShippingMethod, data: shippingData } = useQuery<
    DefaultShippingMethodQueryType,
    ProjectIdType
  >(DEFAULT_SHIPPING_METHOD, {
    skip: !projectId,
    variables: {
      projectId,
    },
  });

  const shippingMethods = shippingData?.defaultShippingMethod?.shipping;
  const hasLalamoveShipping = shippingMethods?.some(({ calculateType }) => calculateType === CALCULATE.LALAMOVE);

  if (loadingProjectData || loadingShippingMethod) {
    return (
      <Modal isOpen onClose={() => {}}>
        <CircularProgress className="m-4" />
      </Modal>
    );
  }

  return <>{data && <DeliveryPeriodCard projectData={data} hasLalamoveShipping={Boolean(hasLalamoveShipping)} />}</>;
};
