import { withStyles } from "@material-ui/core/styles";
import MuiBadge from "@material-ui/core/Badge";
import COLORS from "constants/Colors";

export const BadgeStatus = withStyles((theme) => ({
  badge: {
    backgroundColor: COLORS.Success,
    color: COLORS.Success,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,

    "&.inactive::after": {
      border: "none",
    },

    "&.inactive": {
      backgroundColor: COLORS.DarkLight,
      color: COLORS.DarkLight,
    },

    "&.error::after": {
      border: "none",
    },

    "&.error": {
      backgroundColor: COLORS.Error,
      color: COLORS.Error,
    },

    "&.pending": {
      backgroundColor: COLORS.ReadyToApprove,
      color: COLORS.ReadyToApprove,
    },
  },
}))(MuiBadge);

export default BadgeStatus;
