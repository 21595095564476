import { Link } from "react-router-dom";
import * as React from "react";
import styled from "styled-components";
import { SIDE_NAV_WIDTH_DESKTOP, TOP_NAV_HEIGHT_DESKTOP } from "constants/Layout";

type ListMenuItemPropsType = {
  isActive?: boolean;
};

type SideNavPropsType = {
  isOpen?: boolean;
};

export const LineSpacer = styled.div`
  display: block;
  height: 1px;
  background-color: ${({ theme }) => theme.COLORS.LightBlue};
  margin: 0 15px;
`;

export const ProjectImg = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 16px;
  margin-left: 4px;
`;

export const ListMenuWrapper = styled.div`
  overflow-y: auto;
`;

export const ListMenuIcon = styled.img`
  width: 24px;
  height: 24px;
  margin: 0px 20px 0px 10px;
`;

export const ProjectMenu = styled.div`
  position: relative;
  padding: 8px;
  min-height: 48px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.COLORS.LightWhite};
  font-weight: bold;
  color: ${({ theme }) => theme.COLORS.Primary};
  margin: 8px 16px;
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  }

  &.isActive {
    background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  }

  &.isBGWhite {
    background-color: ${({ theme }) => theme.COLORS.LightWhite};
    color: black;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
    }
  }

  &.isSideNav {
    padding: 0px;
    ${({ theme }) => theme.breakpoints.up("lg")} {
      box-sizing: border-box;
    }
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin: 8px 12px;
  }
`;

export const NavLink = styled(({ ...rest }) => <Link {...rest} />)<ListMenuItemPropsType>`
  position: relative;
  padding: 8px;
  min-height: 48px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.COLORS.LightWhite};
  font-weight: bold;
  color: ${({ theme }) => theme.COLORS.Primary};
  margin: 8px 16px;
  display: flex;
  align-items: center;
  text-decoration: none;

  &.disabled {
    pointer-events: none;
    user-select: none;
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  }

  &.isActive {
    background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  }

  &.isBGWhite {
    background-color: ${({ theme }) => theme.COLORS.LightWhite};
    color: black;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
    }
  }

  &.isSideNav {
    padding: 0px;
    ${({ theme }) => theme.breakpoints.up("lg")} {
      box-sizing: border-box;
    }
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin: 8px 12px;
  }
`;

export const Logout = styled.div`
  position: relative;
  height: 48px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.COLORS.LightWhite};
  font-weight: bold;
  color: ${({ theme }) => theme.COLORS.Primary};
  margin: 8px 16px;
  display: flex;
  align-self: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  }
  & > img {
    margin: 10px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin: 8px 12px;
  }
`;

export const SideNavContainer = styled.div<SideNavPropsType>`
  background: ${({ theme }) => theme.COLORS.LightWhite};
  height: 100vh;
  width: 260px;
  text-align: left;
  box-shadow: 6px 3px 20px 0 ${({ theme }) => theme.COLORS.BoxShadow};
  overflow-y: scroll;
  overflow-x: hidden;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: ${SIDE_NAV_WIDTH_DESKTOP}px;
    margin-top: ${TOP_NAV_HEIGHT_DESKTOP}px;
  }
`;

export const Wrapper = styled.div`
  ${({ theme }) => theme.breakpoints.up("lg")} {
    flex: 0 0 ${SIDE_NAV_WIDTH_DESKTOP}px;
    width: ${SIDE_NAV_WIDTH_DESKTOP}px;
  }
`;

export const List = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const BackButtonMenu = styled.div`
  padding: 12px 16px;
  margin: 12px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.COLORS.LightGray};
  }
`;
