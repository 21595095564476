import React from "react";
import { useTranslation } from "react-i18next";

import Card from "components/Card";
import Typography from "components/Typography";
import Grid from "components/Grid";

import ImageUpload from "components/ImageUpload";
import { Device } from "types";
import { ImageUrl } from "types/Image";
import useDevice from "utils/hooks/useDevice";

type ShippingInfoGraphicCardPropsType = {
  image: string;
  onChange: (imageUrls: ImageUrl[]) => void;
  onRemove?: () => void;
};

const ShippingInfoGraphicCard: React.FC<ShippingInfoGraphicCardPropsType> = (props) => {
  const { t } = useTranslation();
  const device = useDevice();
  const isMobile = device === Device.Device.MOBILE;

  const { image, onChange, onRemove } = props;

  return (
    <Card
      borderRadius={isMobile ? 0 : 8}
      className={isMobile ? "mb-4" : "mx-4 mb-4"}
      data-cy="shipping-method-info-graphic"
    >
      <Typography variant="title2" className="m-2 pt-1">
        {t("shipping.label.shippingInfographic")}
      </Typography>

      <Card noShadow className={`mt-4 ${isMobile ? "" : "px-4"}`}>
        <Grid container item className="p-2" alignItems="flex-start" justify="space-between" xs={12}>
          <Grid container item xs={12} sm={5}>
            <Grid item xs={12} className="pb-2">
              <Typography variant="title7" color="dark">
                {t("shipping.label.infoGraphic")}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body2">{t("shipping.description.infoGraphic")}</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={6}
            justify={isMobile ? "center" : "flex-end"}
            className={isMobile ? "mt-4" : ""}
          >
            <ImageUpload
              width={248}
              height={180}
              onChange={onChange}
              onRemove={onRemove}
              image={image}
              isConfirmRemove
            />
          </Grid>
        </Grid>
      </Card>
    </Card>
  );
};

export default ShippingInfoGraphicCard;
