import { AddonFilterType, AddonEntityType } from "types/Addon";

export const ADDON_FILTER: AddonFilterType[] = [
  AddonFilterType.MEMBER,
  AddonFilterType.CHANNEL,
  AddonFilterType.IMAGE_SEARCH,
  AddonFilterType.BROADCAST,
  AddonFilterType.ADVANCE_FEATURE,
  AddonFilterType.INTEGRATION,
];

export const ADVANCE_FEATURE_ADDONS: ReadonlyArray<AddonEntityType> = [
  AddonEntityType.OFFLINE_ORDER,
  AddonEntityType.PAYMENT_GATEWAY,
  AddonEntityType.RESPONSE_MESSAGE,
];

export const INTEGRATION_ADDONS: ReadonlyArray<AddonEntityType> = [AddonEntityType.ZORT_INTEGRATION];
