import React, { FC } from "react";
import { BoxProps } from "types/RichMenu";
import { Box, BigSquareBox, BaseBoxContainer, Badge } from "./styled";

const ThreeBoxes: FC<BoxProps> = ({ bgColor }) => {
  return (
    <BaseBoxContainer>
      <BigSquareBox bgColor={bgColor}>
        <Badge>A</Badge>
      </BigSquareBox>
      <Box bgColor={bgColor}>
        <Badge>B</Badge>
      </Box>
      <Box bgColor={bgColor}>
        <Badge>C</Badge>
      </Box>
    </BaseBoxContainer>
  );
};

export default ThreeBoxes;
