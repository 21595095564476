export const NAME_ERROR = "Name is a required field";
export const IMAGE_ERROR = "Image is a required field";
export const PHONE_NUMBER_ERROR = "Phone number should be 4, 9, 10 digits";
export const POSTCODE_ERROR = "Postcode should be number 5 digits";

export const INVALID_VALUE = "invalid value";
export const REQUIRED = "required";

export default {
  NAME_ERROR,
  IMAGE_ERROR,
  PHONE_NUMBER_ERROR,
  POSTCODE_ERROR,
  INVALID_VALUE,
  REQUIRED,
};
