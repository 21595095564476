import { IcInfo, IcVat, IcStoreMembers, IcConnect } from "components/SvgIcons";

export const TABS_OWNER_CONFIG = [
  {
    id: "information",
    title: "Shop Information",
    svgIcon: IcInfo,
  },
  {
    id: "offline-store",
    title: "Offline stores",
    svgIcon: IcInfo,
  },
  {
    id: "theme-config",
    title: "ThemeShopConfig.title",
    svgIcon: IcInfo,
  },
  {
    id: "order-setting",
    title: "Order setting",
    svgIcon: IcInfo,
  },
  {
    id: "vat",
    title: "VAT",
    svgIcon: IcVat,
  },
  {
    id: "members",
    title: "Store members",
    svgIcon: IcStoreMembers,
  },
  {
    id: "connect",
    title: "Connect",
    svgIcon: IcConnect,
  },
];

export const TABS_MOBILE_OWNER_CONFIG = [
  {
    id: "information",
    title: "Shop Information",
    svgIcon: IcInfo,
  },
  {
    id: "offline-store",
    title: "Offline stores",
    svgIcon: IcInfo,
  },
  {
    id: "theme-config",
    title: "ThemeShopConfig.title",
    svgIcon: IcInfo,
  },
  {
    id: "order-setting",
    title: "Order setting",
    svgIcon: IcInfo,
  },
  {
    id: "vat",
    title: "VAT",
    svgIcon: IcVat,
  },
  {
    id: "members",
    title: "Store members",
    svgIcon: IcStoreMembers,
  },
];

export const TABS_ADMIN_CONFIG = [
  {
    id: "information",
    title: "Shop Information",
    svgIcon: IcInfo,
  },
  {
    id: "offline-store",
    title: "Offline stores",
    svgIcon: IcInfo,
  },
  {
    id: "members",
    title: "Store members",
    svgIcon: IcStoreMembers,
  },
];
