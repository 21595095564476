import React, { FC } from "react";
import get from "lodash/get";
import styled from "styled-components";

import { ProductInOrderType, ProductType, ShippedDetail } from "types/Order";
import { PAYMENT_METHOD_TYPES } from "constants/Payment";
import { PromotionType } from "types/Promotion";
import Grid from "components/Grid";
import Typography from "components/Typography";
import { CarouselContainer } from "components/FlexMessage/FlexMessage.style";
import COLORS from "constants/Colors";
import FlexOrder from "./FlexOrder";
import { getActionButtonsFlex } from "./FlexOrderActionButton";
import { SingleButtonPrimary, SingleButtonSecondary } from "./FlexMessage.style";

export type OrderCarouselType = {
  orderNumber: string;
  state: string;
  createdAt: string;
  products: ProductType[];
  orderProducts: ProductInOrderType[];
  grandTotal: number;
  shippedDetail: ShippedDetail;
  shippedAt?: string;
  shippingDuration?: number;
  shippingPrice: number;
  shippingMethod: string;
  discountTotalPrice: number;
  discountShippingPrice: number;
  streetAddress: string;
  paymentMethodType: PAYMENT_METHOD_TYPES;
  promotionProduct?: PromotionType[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lastOrderWithShippingAddress?: any;
};

type FlexOrderCarouselPropsType = {
  content: OrderCarouselType[];
};

const CarouselContent = styled.div`
  color: ${({ theme }) => theme.COLORS.Primary};
  border-radius: 20px;
  display: flex;
  flex-shrink: 0;
  padding: 0px;
  width: 240px;
  background: ${({ theme }) => theme.COLORS.White};
`;

const FlexOrderCarousel: FC<FlexOrderCarouselPropsType> = (props: FlexOrderCarouselPropsType) => {
  const { content } = props;

  return (
    <CarouselContainer displayScrollbar>
      {content.map((data, index: number) => {
        const shippedDetails = get(data.shippedDetail, "detail", []);
        // hide button if shippedDetailMessage and notesMessage are empty
        const isShowTrackingNumberButton = Boolean(
          shippedDetails && shippedDetails[0] && shippedDetails[0].trackingNumber,
        );
        const actionButtonsFlex = getActionButtonsFlex(
          data.lastOrderWithShippingAddress,
          data.state,
          data.shippingMethod,
          data.streetAddress,
          isShowTrackingNumberButton,
        );

        return (
          <Grid
            container
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            alignContent="space-between"
            alignItems="flex-end"
            justify="space-between"
            className="m-1"
            style={{ background: COLORS.White, borderRadius: "10px", width: "240px" }}
          >
            <CarouselContent>
              <FlexOrder content={data} />
            </CarouselContent>

            <Grid item xs={12} className="mt-1">
              {actionButtonsFlex.map((button) => {
                return button.isPrimary ? (
                  <SingleButtonPrimary key={button.value}>
                    <Typography variant="body4" color="inherit">
                      [{button.value}]
                    </Typography>
                  </SingleButtonPrimary>
                ) : (
                  <SingleButtonSecondary key={button.value}>
                    <Typography variant="body4" color="inherit">
                      [{button.value}]
                    </Typography>
                  </SingleButtonSecondary>
                );
              })}
            </Grid>
          </Grid>
        );
      })}
    </CarouselContainer>
  );
};
export default FlexOrderCarousel;
