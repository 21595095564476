import styled from "styled-components";

export const ModalBodyWrapper = styled.div`
  width: 348px;
`;

export const ModalTextWrapper = styled.div`
  width: 248px;
  text-align: center;
  margin: auto;
`;
