import React, { FC } from "react";
import Grid from "components/Grid";
import { SvgIcon } from "components/Icon";
import { IcWarning } from "components/SvgIcons";

import { IconWrapper, GridDetail, GridContent, GridIcon, StatusBoxCard } from "./styled";

const StatusBox: FC = ({ children }) => {
  return (
    <StatusBoxCard>
      <Grid container alignItems="center">
        <GridIcon item className="mr-3">
          <IconWrapper>
            <SvgIcon viewBox="0 0 16 16" component={IcWarning} fontSize="small" className="p-0 m-0" />
          </IconWrapper>
        </GridIcon>
        <GridContent container item alignItems="center" justify="space-between" className="p-0">
          <GridDetail item sm="auto">
            {children}
          </GridDetail>
        </GridContent>
      </Grid>
    </StatusBoxCard>
  );
};

export default StatusBox;
