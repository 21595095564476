import { useState } from "react";
import { useMutation, ApolloError } from "@apollo/client";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import differenceBy from "lodash/differenceBy";

import {
  BANK_ID_PREFIX,
  COD_DEPOSIT_AMOUNT_KEY,
  COD_DEPOSIT_TYPE_KEY,
  COD_ID_KEY,
  COD_IS_ACTIVE_KEY,
  COD_MANAGEMENT_FEE_KEY,
  COD_MANAGEMENT_FEE_TYPE_KEY,
  COD_SHIPPING_METHOD_KEY,
  COD_THRESHOLD_KEY,
  PAYMENT_GATEWAY_API_KEY,
  PAYMENT_GATEWAY_ID_KEY,
  PAYMENT_GATEWAY_IS_ACTIVE_KEY,
  PAYMENT_GATEWAY_MD5_SECRET_KEY,
  PAYMENT_GATEWAY_MERCHANT_ID,
  PAYMENT_TYPES,
  PROMPT_PAY_ACCOUNT_NAME_KEY,
  PROMPT_PAY_ACCOUNT_NUMBER_KEY,
  PROMPT_PAY_CUSTOM_QR_IMAGE,
  PROMPT_PAY_TYPE_KEY,
  PROMPT_PAY_ID_KEY,
  PROMPT_PAY_IS_ACTIVE_KEY,
  PAYMENT_GATEWAY_TYPE_KEY,
  SECOND_PAYMENT_GATEWAY_ID_KEY,
  SECOND_PAYMENT_GATEWAY_IS_ACTIVE_KEY,
  PAYMENT_GATEWAY_DESCRIPTION_KEY,
  PAYMENT_GATEWAY_CUSTOM_BODY,
  PAYMENT_GATEWAY_CUSTOM_HEADER,
  PAYMENT_GATEWAY_CUSTOM_ICON_URL,
  PAYMENT_GATEWAY_CUSTOM_URL,
} from "constants/Payment";
import { SAVE_PAYMENTS } from "graphql/payment/mutation";
import { PAYMENTS } from "graphql/payment/query";
import {
  BankAccountPaymentType,
  NewBankAccountPaymentType,
  PaymentGatewayChillPayType,
  PaymentFormDataType,
  PaymentsQueryType,
  PaymentType,
  SavingPaymentGatewayFormDataType,
  PaymentGateway2C2PType,
  PaymentGatewayCustom,
} from "types/Payment";
import { removeTypenameField } from "utils/common";

type useSavePaymentPropsType = {
  projectId: string;
  onComplete: () => void;
  onChangeIsActive: (paymentId: string, isActive: boolean) => void;
  onChangeBankPayment: (id: string, fieldName: string, value: string) => void;
};

const useSavePayment = (props: useSavePaymentPropsType) => {
  const { t } = useTranslation();
  const { projectId, onComplete, onChangeIsActive, onChangeBankPayment } = props;
  const [isOpenWarningActivePaymentModal, setIsOpenWarningActivePaymentModal] = useState(false);
  const [savePayments] = useMutation(SAVE_PAYMENTS, {
    onError: (error: ApolloError) => {
      toast.error(`${t("Update failed!")}, ${error}`, {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 3000,
      });
    },
    onCompleted: () => {
      toast.success(t("Update successfully"), {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 1000,
      });

      onComplete();
    },
    update(cache, { data: { savePayments: savedPayments } }) {
      const cachePayments = cache.readQuery<PaymentsQueryType>({
        query: PAYMENTS,
        variables: {
          projectId,
        },
      });

      if (cachePayments) {
        const newPayments = differenceBy<PaymentType, PaymentType>(savedPayments, cachePayments.payments, "id");
        const updatedCachePayments = cachePayments.payments.map((cachePayment: PaymentType) => {
          const matchedUpdatePayment = savedPayments.find(
            (savedPayment: PaymentType) => savedPayment.id === cachePayment.id,
          );
          return matchedUpdatePayment || cachePayment;
        });

        const newCachePayments = [...updatedCachePayments, ...newPayments];

        cache.writeQuery({
          query: PAYMENTS,
          variables: {
            projectId,
          },
          data: {
            payments: newCachePayments,
          },
        });

        if (newPayments) {
          newPayments
            .filter(({ type }: PaymentType) => type === PAYMENT_TYPES.BANK_ACCOUNT)
            .forEach((bankPayment: PaymentType) => {
              const { id, isActive, bankName, bankAccountType } = bankPayment as BankAccountPaymentType;
              const bankIdWithPrefix = `${BANK_ID_PREFIX}${id}`;

              onChangeIsActive(bankIdWithPrefix, isActive);
              onChangeBankPayment(bankIdWithPrefix, "BankName", bankName);
              onChangeBankPayment(bankIdWithPrefix, "BankAccountType", bankAccountType);
            });
        }
      }
    },
  });

  const checkActivePayment = (formData: PaymentFormDataType) => {
    const validationKeys = Object.keys(formData);

    const regex = new RegExp(/.*IsActive$/);

    const paymentIsActiveKeysExceptCOD = validationKeys.filter((key: string) => {
      const isMatch = regex.test(key);

      return isMatch && key !== COD_IS_ACTIVE_KEY;
    });

    const isSomePaymentExceptCODActive = paymentIsActiveKeysExceptCOD.some((key: string) => formData[key]);

    return isSomePaymentExceptCODActive;
  };

  const handleSavePayment = (
    formData: PaymentFormDataType,
    paymentGateway: PaymentGatewayChillPayType | PaymentGateway2C2PType | PaymentGatewayCustom | null | false,
    secondPaymentGateway: PaymentGatewayChillPayType | PaymentGateway2C2PType | PaymentGatewayCustom | null | false,
    newBankPayments: NewBankAccountPaymentType[],
    editedBankPaymentIds: string[],
    newPaymentGateway?: PaymentFormDataType,
    newSecondPaymentGateway?: PaymentFormDataType,
    isDeeplePayEnabled?: boolean,
  ) => {
    const isSomePaymentExceptCODActive = checkActivePayment(formData);

    if (!isSomePaymentExceptCODActive || (!isSomePaymentExceptCODActive && !isDeeplePayEnabled)) {
      setIsOpenWarningActivePaymentModal(true);
    } else {
      const savingPayment = [];

      const savingCOD = {
        id: formData[COD_ID_KEY],
        projectId,
        isActive: formData[COD_IS_ACTIVE_KEY],
        shippingMethod: formData[COD_SHIPPING_METHOD_KEY],
        depositThreshold: Number(formData[COD_THRESHOLD_KEY] || 0),
        depositType: formData[COD_DEPOSIT_TYPE_KEY],
        depositAmount: Number(formData[COD_DEPOSIT_AMOUNT_KEY] || 0),
        type: PAYMENT_TYPES.CASH_ON_DELIVERY,
        managementFee: Number(formData[COD_MANAGEMENT_FEE_KEY] || 0),
        managementFeeType: formData[COD_MANAGEMENT_FEE_TYPE_KEY],
      };

      if (
        (!savingCOD.isActive &&
          (savingCOD.shippingMethod ||
            savingCOD.depositThreshold ||
            savingCOD.depositType ||
            savingCOD.depositAmount)) ||
        savingCOD.id ||
        savingCOD.isActive
      ) {
        savingPayment.push(savingCOD);
      }

      const savingPromptPay = {
        id: formData[PROMPT_PAY_ID_KEY],
        projectId,
        isActive: formData[PROMPT_PAY_IS_ACTIVE_KEY],
        accountName: formData[PROMPT_PAY_ACCOUNT_NAME_KEY],
        accountNumber: formData[PROMPT_PAY_ACCOUNT_NUMBER_KEY],
        promptpayType: formData[PROMPT_PAY_TYPE_KEY],
        image: formData[PROMPT_PAY_CUSTOM_QR_IMAGE],
        type: PAYMENT_TYPES.PROMPT_PAY,
      };
      console.log('savingPromptPay',savingPromptPay,formData)
      if (
        (!savingPromptPay.isActive && (savingPromptPay.accountName || savingPromptPay.accountNumber)) ||
        savingPromptPay.id ||
        savingPromptPay.isActive
      ) {
        savingPayment.push(savingPromptPay);
      }

      let savingPaymentGateway: SavingPaymentGatewayFormDataType | null = null;
      const initPaymentGatewayData = {
        id: formData[PAYMENT_GATEWAY_ID_KEY],
        projectId,
        isActive: formData[PAYMENT_GATEWAY_IS_ACTIVE_KEY],
      };
      if (paymentGateway) {
        const paymentGatewayWithoutTypename = removeTypenameField(paymentGateway) as
          | PaymentGateway2C2PType
          | PaymentGatewayChillPayType
          | PaymentGatewayCustom;

        savingPaymentGateway = {
          ...paymentGatewayWithoutTypename,
          ...initPaymentGatewayData,
        };
      }
      // do this when payment gateway data has changed
      // if payment gateway is exist in DB but nothing change in form should skip this part
      if (newPaymentGateway) {
        const paymentGatewayType = newPaymentGateway[PAYMENT_GATEWAY_TYPE_KEY] as PAYMENT_TYPES;

        savingPaymentGateway = {
          ...initPaymentGatewayData,
          type: paymentGatewayType,
          merchantId: newPaymentGateway[PAYMENT_GATEWAY_MERCHANT_ID],
          md5Secret: newPaymentGateway[PAYMENT_GATEWAY_MD5_SECRET_KEY],
          description: newPaymentGateway[PAYMENT_GATEWAY_DESCRIPTION_KEY],
        };

        const isPaymentGatewayChillPay = paymentGatewayType === PAYMENT_TYPES.PAYMENT_GATEWAY_CHILL_PAY;
        if (isPaymentGatewayChillPay) {
          // Chill pay need API key
          savingPaymentGateway = {
            ...savingPaymentGateway,
            apiKey: newPaymentGateway[PAYMENT_GATEWAY_API_KEY],
          };
        }

        if (paymentGatewayType === PAYMENT_TYPES.PAYMENT_GATEWAY_CUSTOM) {
          const bodyString = newPaymentGateway[PAYMENT_GATEWAY_CUSTOM_BODY] as string;
          const headerString = newPaymentGateway[PAYMENT_GATEWAY_CUSTOM_HEADER] as string;

          savingPaymentGateway = {
            ...initPaymentGatewayData,
            type: paymentGatewayType,
            header: JSON.parse(headerString) || null,
            body: JSON.parse(bodyString) || null,
            iconUrl: newPaymentGateway[PAYMENT_GATEWAY_CUSTOM_ICON_URL] || null,
            url: newPaymentGateway[PAYMENT_GATEWAY_CUSTOM_URL],
          };
        }
      }
      if (savingPaymentGateway) {
        savingPayment.push(savingPaymentGateway);
      }

      let savingSecondPaymentGateway: SavingPaymentGatewayFormDataType | null = null;
      const initSecondPaymentGatewayData = {
        id: formData[SECOND_PAYMENT_GATEWAY_ID_KEY],
        projectId,
        isActive: formData[SECOND_PAYMENT_GATEWAY_IS_ACTIVE_KEY],
      };
      if (secondPaymentGateway) {
        const secondPaymentGatewayWithoutTypename = removeTypenameField(secondPaymentGateway) as
          | PaymentGateway2C2PType
          | PaymentGatewayChillPayType
          | PaymentGatewayCustom;
        savingSecondPaymentGateway = {
          ...secondPaymentGatewayWithoutTypename,
          ...initSecondPaymentGatewayData,
        };
      }
      // do this when payment gateway data has changed
      // if payment gateway is exist in DB but nothing change in form should skip this part
      if (newSecondPaymentGateway) {
        const paymentGatewayType = newSecondPaymentGateway[PAYMENT_GATEWAY_TYPE_KEY] as PAYMENT_TYPES;

        savingSecondPaymentGateway = {
          ...initSecondPaymentGatewayData,
          type: paymentGatewayType,
          merchantId: newSecondPaymentGateway[PAYMENT_GATEWAY_MERCHANT_ID],
          md5Secret: newSecondPaymentGateway[PAYMENT_GATEWAY_MD5_SECRET_KEY],
          description: newSecondPaymentGateway[PAYMENT_GATEWAY_DESCRIPTION_KEY],
        };

        const isSecondPaymentGatewayChillPay = paymentGatewayType === PAYMENT_TYPES.PAYMENT_GATEWAY_CHILL_PAY;
        if (isSecondPaymentGatewayChillPay) {
          // Chill pay need API key
          savingSecondPaymentGateway = {
            ...savingSecondPaymentGateway,
            apiKey: newSecondPaymentGateway[PAYMENT_GATEWAY_API_KEY],
          };
        }

        if (paymentGatewayType === PAYMENT_TYPES.PAYMENT_GATEWAY_CUSTOM) {
          const bodyString = newSecondPaymentGateway[PAYMENT_GATEWAY_CUSTOM_BODY] as string;
          const headerString = newSecondPaymentGateway[PAYMENT_GATEWAY_CUSTOM_HEADER] as string;

          savingSecondPaymentGateway = {
            ...initPaymentGatewayData,
            type: paymentGatewayType,
            header: JSON.parse(headerString) || null,
            body: JSON.parse(bodyString) || null,
            iconUrl: newSecondPaymentGateway[PAYMENT_GATEWAY_CUSTOM_ICON_URL] || null,
            url: newSecondPaymentGateway[PAYMENT_GATEWAY_CUSTOM_URL],
          };
        }
      }
      if (savingSecondPaymentGateway) {
        savingPayment.push(savingSecondPaymentGateway);
      }

      const savingNewBanks = newBankPayments
        .map(({ id }: NewBankAccountPaymentType) => {
          const isActive = formData[`${id}IsActive`];
          const bankName = formData[`${id}BankName`];
          const accountName = formData[`${id}AccountName`];
          const accountNumber = formData[`${id}AccountNumber`];
          const bankAccountType = formData[`${id}BankAccountType`];
          const bankBranch = formData[`${id}Branch`];

          const hasSomeFieldOfBankData = bankName || accountName || accountNumber || bankAccountType || bankBranch;

          return hasSomeFieldOfBankData
            ? {
                projectId,
                type: PAYMENT_TYPES.BANK_ACCOUNT,
                isActive,
                accountName,
                accountNumber,
                bankAccountType,
                bankBranch,
                bankName,
              }
            : null;
        })
        .filter(Boolean);

      const savingExistingBank = editedBankPaymentIds.map((id: string) => {
        const bankId = id.replace(BANK_ID_PREFIX, "");
        const isActive = formData[`${id}IsActive`];
        const bankName = formData[`${id}BankName`];
        const accountName = formData[`${id}AccountName`];
        const accountNumber = formData[`${id}AccountNumber`];
        const bankAccountType = formData[`${id}BankAccountType`];
        const bankBranch = formData[`${id}Branch`];

        return {
          id: bankId,
          projectId,
          type: PAYMENT_TYPES.BANK_ACCOUNT,
          isActive,
          accountName,
          accountNumber,
          bankAccountType,
          bankBranch,
          bankName,
        };
      });

      const sumSavingPayment = [...savingPayment, ...savingNewBanks, ...savingExistingBank];

      savePayments({
        variables: {
          projectId,
          paymentInputs: sumSavingPayment,
          isUpdatePaymentToggle: true,
        },
      });
    }
  };

  const closeWarningActivePaymentModal = () => {
    setIsOpenWarningActivePaymentModal(false);
  };

  return {
    handleSavePayment,
    isOpenWarningActivePaymentModal,
    closeWarningActivePaymentModal,
  };
};

export default useSavePayment;
