import React from "react";
import get from "lodash/get";
import { CellProps } from "react-table";
import ButtonBase from "components/ButtonBase";
import Typography from "components/Typography";

const getCancelOrder = <T extends object>(t: Function, onClickCancelOrder: Function) => ({
  Header: "",
  accessor: "cancel",
  disableSortBy: true,
  width: 80,
  sticky: "right",
  // eslint-disable-next-line react/display-name
  Cell: (cell: CellProps<T>) => (
    <ButtonBase
      onClick={() => {
        onClickCancelOrder(get(cell, "row.original"));
      }}
      className="m-auto"
    >
      <Typography variant="body3" color="error" className="text-center">
        {t("Cancel")}
      </Typography>
    </ButtonBase>
  ),
});

export default getCancelOrder;
