import produce from "immer";
import { ResponseMessage } from "types/AISetting";
import { MessageType } from "types/Chat";

export const updateQuickReplyMessageToLastItem = (responses: ResponseMessage[]) => {
  // if found message type QUICK_REPLIES then move to the last list

  return produce(responses, (draft) => {
    const quickReplyIndex = draft.findIndex((obj) => obj.type === MessageType.QUICK_REPLIES);

    if (Number(quickReplyIndex) !== -1) {
      const quickReply = draft.splice(quickReplyIndex, 1)[0];
      draft.push(quickReply);
    }
  });
};
