import * as React from "react";

const SvgIcReply = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M27.273 15.696c-1.445-3.596-5.349-5.394-11.71-5.394h-2.998V6.876a.823.823 0 00-.254-.602.823.823 0 00-.602-.254.823.823 0 00-.603.254l-6.852 6.852a.824.824 0 00-.254.602c0 .232.085.433.254.603l6.852 6.852c.17.17.37.254.603.254a.824.824 0 00.602-.254c.17-.17.254-.37.254-.602v-3.427h2.998c.874 0 1.657.027 2.348.08.692.054 1.38.15 2.061.288a7.65 7.65 0 011.78.57 6.3 6.3 0 011.413.93c.437.379.794.829 1.07 1.351.277.522.493 1.14.65 1.853.156.714.234 1.522.234 2.423 0 .49-.023 1.04-.067 1.646 0 .053-.011.158-.034.314a2.755 2.755 0 00-.033.355.5.5 0 00.114.334c.076.09.18.134.314.134.143 0 .268-.076.375-.228.062-.08.12-.178.174-.294a23.58 23.58 0 00.321-.722c1.133-2.543 1.7-4.555 1.7-6.036 0-1.775-.237-3.26-.71-4.456z"
    />
  </svg>
);

export default SvgIcReply;
