import { IcCategories, IcProduct } from "components/SvgIcons";
import { HeaderType } from "types/Tab";
import { PRODUCT_NAME, PRODUCT_BRAND, PRODUCT_CATEGORY, PRODUCT_SKU, PRODUCT_PROPERTY } from "constants/Router";
import { SYNONYM_CONFIGS } from "constants/Synonym";

export const TAB_CONFIGS: HeaderType[] = [
  {
    id: PRODUCT_NAME,
    title: SYNONYM_CONFIGS.PRODUCT_NAME.TITLE,
    svgIcon: IcProduct,
  },
  {
    id: PRODUCT_BRAND,
    title: SYNONYM_CONFIGS.PRODUCT_BRAND.TITLE,
    svgIcon: IcProduct,
  },
  {
    id: PRODUCT_CATEGORY,
    title: SYNONYM_CONFIGS.CATEGORY.TITLE,
    svgIcon: IcCategories,
  },
  {
    id: PRODUCT_SKU,
    title: SYNONYM_CONFIGS.SKU_TYPE.TITLE,
    svgIcon: IcProduct,
  },
  {
    id: PRODUCT_PROPERTY,
    title: SYNONYM_CONFIGS.PROPERTY_TYPE.TITLE,
    svgIcon: IcProduct,
  },
];
