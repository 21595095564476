import React from "react";
import get from "lodash/get";
import { CellProps, Row } from "react-table";
import IndeterminateCheckbox from "components/Checkbox";

const CHECKBOX_COLUMN_WIDTH = 56;

export type OnSelectAllRowInPage = (ids: string[], isSelectedSomeRow: boolean) => void;
export type OnSelectRow = (id: string) => void;

const getSelection = <T extends object>(onSelectAllRowInPage?: OnSelectAllRowInPage, onSelectRow?: OnSelectRow) => ({
  id: "selection",
  width: CHECKBOX_COLUMN_WIDTH,
  sticky: "left",
  // The header can use the table's getToggleAllRowsSelectedProps method
  // to render a checkbox
  // eslint-disable-next-line react/display-name
  Header: (row: Row<T>) => {
    const allData: (T & { id: string })[] = get(row, "data") || [];
    const ids = allData.map((data) => data.id);
    const isSelectedAll: boolean = get(row, `state.isSelectedAll`) || false;
    const excludeIds: Record<string, boolean> = get(row, `state.excludeIds`) || {};
    const selectedIds: Record<string, boolean> = get(row, `state.selectedIds`) || {};
    const isSelectedSomeRow = isSelectedAll ? ids.some((id) => excludeIds[id]) : ids.some((id) => !selectedIds[id]);
    const handleClick = () => {
      if (onSelectAllRowInPage && ids.length) {
        onSelectAllRowInPage(ids, isSelectedSomeRow);
      }
    };

    return (
      <div className="m-auto">
        <IndeterminateCheckbox onClick={handleClick} checked={!isSelectedSomeRow} />
      </div>
    );
  },
  // The cell can use the individual row's getToggleRowSelectedProps method
  // to the render a checkbox
  // eslint-disable-next-line react/display-name
  Cell: (cell: CellProps<T>) => {
    const id = get(cell, "row.original.id");
    const isSelected = get(cell, `state.selectedIds[${id}]`, false);
    const isExcluded = get(cell, `state.excludeIds[${id}]`, false);
    const isSelectedAll = get(cell, `state.isSelectedAll`, false);
    let isChecked = false;

    if (isSelectedAll) {
      isChecked = !isExcluded;
    } else {
      isChecked = isSelected;
    }

    const handleClick = () => {
      if (onSelectRow && id) {
        onSelectRow(id);
      }
    };

    return (
      <div className="m-auto">
        <IndeterminateCheckbox onClick={handleClick} checked={isChecked} />
      </div>
    );
  },
});

export default getSelection;
