import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DragDropContext, DropResult } from "react-beautiful-dnd";

import Card from "components/Card";
import Grid from "components/Grid";
import Typography from "components/Typography";

import { IcNoData } from "components/SvgIcons";
import { COLORS } from "constants/Colors";

import { Product, ProductItemPropsType } from "types/Product";

import { reorder } from "utils/message";

import useGetProducts from "./hooks/useGetProducts";

import ProductLists from "./ProductList";
import SelectProduct from "./SelectProduct";

type ProductPreviewPropsType = {
  projectId: string;
  categoryId: string;
  categoryName: string;
  highlightProducts: Product[];
  onChangeHighlightProducts: Function;
};

const ProductPreview: FC<ProductPreviewPropsType> = ({
  projectId,
  categoryId,
  categoryName,
  highlightProducts,
  onChangeHighlightProducts,
}) => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedProducts, setSelectedProducts] = useState<any>([]);

  const {
    loading: loadingProducts,
    productOptions,
    productResults,
    convertProductValueToOptionFormat,
  } = useGetProducts({
    projectId,
    categoryId,
  });

  const handleDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    // dropped at the same position
    if (result.source.index === result.destination.index) {
      return;
    }

    const focusSelectedProducts = selectedProducts.map((productId: number) => {
      return productResults.find((product: ProductItemPropsType) => Number(product.id) === Number(productId));
    });

    const newPriorityProduct = reorder<Product>(focusSelectedProducts, result.source.index, result.destination.index);
    const newPriorityProductIds = newPriorityProduct.map((product) => product.id);

    setSelectedProducts(newPriorityProductIds);
    onChangeHighlightProducts(newPriorityProductIds);
  };

  const handleClickDelete = (deleteProductId: string) => {
    const updatedProductIds = selectedProducts.filter(
      (productId: number) => Number(deleteProductId) !== Number(productId),
    );
    setSelectedProducts(updatedProductIds);
    onChangeHighlightProducts(updatedProductIds);
  };

  const handleAddNewProduct = (product: ProductItemPropsType) => {
    setSelectedProducts(product);
    onChangeHighlightProducts(product);
  };

  useEffect(() => {
    setSelectedProducts(highlightProducts.map((product: Product) => product.id));
  }, [highlightProducts]);

  const renderSelectedProducts = selectedProducts.map((productId: number) => {
    return productResults.find((product: ProductItemPropsType) => Number(product.id) === Number(productId));
  });

  return (
    <Card borderRadius={0} noShadow className="py-2" noBorder>
      <Grid container item xs={12} justify="space-between" alignContent="center" alignItems="center">
        <Typography variant="title8" color={COLORS.DarkMed} className="pb-2">
          {t("displayHighlightProduct")}
          {`'${categoryName}'`}
        </Typography>

        <Typography variant="body3" color={COLORS.DarkMed} className="pb-2">
          {selectedProducts.length}/10
        </Typography>
      </Grid>

      <SelectProduct
        loading={loadingProducts}
        productOptions={productOptions}
        convertProductValueToOptionFormat={convertProductValueToOptionFormat}
        selectedProducts={selectedProducts}
        setSelectedProducts={handleAddNewProduct}
      />
      <div className="mb-3" />
      {renderSelectedProducts.length ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <ProductLists
            highlightProducts={renderSelectedProducts} // TODO: join data with "highlightProducts"
            promotions={[]}
            handleClickDelete={handleClickDelete}
            listId="dnd-product-lists"
          />
        </DragDropContext>
      ) : (
        <Grid container item xs={12} justify="center" alignContent="center" alignItems="center" style={{ height: 350 }}>
          <Grid container item xs={12} justify="center" alignContent="center" alignItems="center">
            <IcNoData color={COLORS.LightBlue} width={90} height={90} />
          </Grid>
          <Grid container item xs={12} justify="center" alignContent="center" alignItems="center">
            <Typography variant="title4" className="text-center" color={COLORS.LightBlue}>
              {t("noHighlightProduct")}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

export default ProductPreview;
