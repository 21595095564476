import { EntityLevel } from "types/Billing";
import { PackageTypes } from "types/PlanUsage";
import { VAT_OPTIONS_TYPE } from "types/Vat";

export enum AddonItemType {
  CURRENT = "current",
  NEW = "new",
}

export enum AddonFilterType {
  ADVANCE_FEATURE = "ADVANCE_FEATURE",
  BROADCAST = "BROADCAST",
  CHANNEL = "CHANNEL",
  IMAGE_SEARCH = "IMAGE_SEARCH",
  MEMBER = "MEMBER",
  INTEGRATION = "INTEGRATION",
}

export enum AddonEntityType {
  BROADCAST = "BROADCAST",
  CHANNEL = "CHANNEL",
  IMAGE_SEARCH = "IMAGE_SEARCH",
  MEMBER = "MEMBER",
  OFFLINE_ORDER = "OFFLINE_ORDER",
  PAYMENT_GATEWAY = "PAYMENT_GATEWAY",
  RESPONSE_MESSAGE = "RESPONSE_MESSAGE",
  ZORT_INTEGRATION = "ZORT_INTEGRATION",
}

export type AddonType = {
  id: string;
  name: string;
  type: PackageTypes;
  entityType: AddonEntityType;
  entityLevel: EntityLevel | null;
  pricePerMonth: number;
  extraChargeRate: number;
  memberQuota: number;
  imageSearchQuota: number;
  isSelected: boolean;
  amount: number;
  isSelectable?: boolean;
};

export type AddonsQueryType = {
  addons: AddonType[];
};

export type AddonCountType = Record<string, number>;

export enum AddonItemStatus {
  ADDITION = "ADDITION",
  NONE = "NONE",
  SUBTRACTION = "SUBTRACTION",
}

export type AddonChargedItem = {
  type: PackageTypes;
  entityType: AddonEntityType;
  entityLevel: EntityLevel;
  name: string;
  defaultPrice: number;
  price: number;
  amount: number;
  duration: number;
  daysInMonth: number;
  totalPrice: number;
};

export type AddonSummaryItem = {
  type: PackageTypes;
  entityType: AddonEntityType;
  entityLevel: EntityLevel;
  status: AddonItemStatus;
  name: string;
  price: number;
  amount: number;
  totalPrice: number;
};

export type AddonChargedItems = {
  items: AddonChargedItem[];
  subtotal: number;
  totalPrice: number;
  vatOptions: VAT_OPTIONS_TYPE;
  vatPrice: number;
};

export type AddonSummaryItems = {
  items: AddonSummaryItem[];
  previousSubtotal: number;
  changesSubtotal: number;
  subtotal: number;
  totalPrice: number;
  vatOptions: VAT_OPTIONS_TYPE;
  vatPrice: number;
};

export type AddonChangesSummary = {
  projectId: string;
  currentBillingDate: string;
  nextBillingDate: string;
  summaryItems: AddonSummaryItems;
  chargedItems: AddonChargedItems;
  isDuringFreeTrialPeriod: boolean;
};
