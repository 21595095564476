import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import Typography from "components/Typography";
import Card from "components/Card";
import COLORS from "constants/Colors";
import { convertPriceFormat } from "utils/common";

type FacebookLiveStat = {
  numberOfReach: number;
  numberOfEngagement: number;
  orderPaidConversionRate: number;
  orderPaidConversionTotal: number;
  createdAt: string;
  updatedAt: string;
};

const FacebookLiveStat: FC<FacebookLiveStat> = ({
  numberOfReach,
  numberOfEngagement,
  orderPaidConversionRate,
  orderPaidConversionTotal,
  createdAt,
  updatedAt,
}) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} className="mb-3">
      <Card noShadow>
        <Grid container spacing={2} justify="space-between" className="p-3">
          <Grid item xs={6} sm={3}>
            <Typography variant="title7" color="primary" className="mb-1">
              {t("facebookLive.table.reach")}
            </Typography>
            <Typography variant="body3" color="darkGray">
              {numberOfReach}
            </Typography>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Typography variant="title7" color="primary" className="mb-1">
              {t("facebookLive.table.engagement")}
            </Typography>
            <Typography variant="body3" color="darkGray">
              {numberOfEngagement}
            </Typography>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Typography variant="title7" color="primary" className="mb-1">
              {t("facebookLive.table.orderPaidConversionRate")}
            </Typography>
            <Typography variant="body3" color="darkGray">
              {orderPaidConversionRate}
            </Typography>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Typography variant="title7" color="primary" className="mb-1">
              {t("facebookLive.table.orderPaidConversionTotal")}
            </Typography>
            <Typography variant="body3" color="darkGray">
              {convertPriceFormat(orderPaidConversionTotal)}
            </Typography>
          </Grid>
        </Grid>

        <Card noShadow noBorder bgColor={COLORS.Light} fullWidth>
          <Grid container>
            <Grid item container alignItems="center" xs={12} lg={6} className="pt-2">
              <Typography variant="title10" color="darkLight" className="pr-2">
                {t("Created at").toUpperCase()}
              </Typography>
              <Typography variant="body3" color="darkGray">
                {createdAt}
              </Typography>
            </Grid>

            <Grid item container alignItems="center" xs={12} lg={6} className="pt-2">
              <Typography variant="title10" color="darkLight" className="pr-2">
                {t("last updated").toUpperCase()}
              </Typography>
              <Typography variant="body3" color="darkGray">
                {updatedAt}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Card>
    </Grid>
  );
};

export default FacebookLiveStat;
