import * as React from "react";

const SvgIcChatbot = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 3.59c5.82 0 10.704 4.006 12.045 9.41h.96a1 1 0 011 1v4a1 1 0 01-1 1h-.96c-1.342 5.404-6.226 9.41-12.045 9.41-5.82 0-10.703-4.006-12.045-9.41h-.96a1 1 0 01-1-1v-4a1 1 0 011-1h.96C5.296 7.597 10.18 3.59 16 3.59zm0 4.766a7.837 7.837 0 00-7.837 7.836c0 3.332 1.87 6.057 4.8 7.192.3.112-.869 3.088-.778 3.27 3.458-1.59 5.978-2.855 6.934-3.27A7.839 7.839 0 0016 8.356zm-3.697 5.673a1.971 1.971 0 110 3.942 1.971 1.971 0 010-3.942zm7.394 0a1.971 1.971 0 110 3.942 1.971 1.971 0 010-3.942z"
    />
  </svg>
);

export default SvgIcChatbot;
