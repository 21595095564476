import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useRef } from "react";
import XLSX from "xlsx";
import { ExportFileType } from "types/SalesReport";
import { OrderType } from "types/Order";
import { EXPORT_ORDERS } from "graphql/order/query/exportOrders";
import { Filter } from "../index";

type ExportOrdersQuery = {
  exportOrders: OrderType[];
};

const DEFAULT_FILE_TYPE = ExportFileType.CSV;
const HEADER_TABLE = [["orderNumber"], ["trackingNumber"], ["note"]];

const useExportOrders = (
  projectId: string,
  filter: Filter,
  option?: { onCompleted?: () => void; onError?: () => void },
) => {
  const [queryOrders, { data, loading }] = useLazyQuery<ExportOrdersQuery>(EXPORT_ORDERS, {
    fetchPolicy: "network-only",
    onError: option?.onError,
  });
  const fileType = useRef<ExportFileType>(DEFAULT_FILE_TYPE);

  const exportOrders = useCallback(
    (exportFileType: ExportFileType) => {
      fileType.current = exportFileType;
      queryOrders({
        variables: {
          projectId,
          filter: {
            startDate: filter?.dateRange?.start,
            endDate: filter?.dateRange?.end,
            orderStates: filter.orderState,
            shippingMethods: filter.shippingMethod,
            shippingCalculationTypes: filter?.shippingCalculationType,
            deliveryScheduleDateRange: filter?.deliveryScheduleDateRange,
            carrierNames: filter?.carrierNames,
          },
        },
      });
    },
    [filter, projectId, queryOrders],
  );

  useEffect(() => {
    if (loading || !data?.exportOrders) {
      return;
    }

    const orders = data.exportOrders;

    const tableData = orders.map((order) => [[order.orderNumber], [""], [""]]);

    /* convert from array of arrays to workbook */
    const worksheet = XLSX.utils.aoa_to_sheet([HEADER_TABLE, ...tableData]);
    const newWorkbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkbook, worksheet, "orders");
    XLSX.writeFile(newWorkbook, `export_orders.${fileType.current.toLowerCase()}`);
    fileType.current = DEFAULT_FILE_TYPE;
    if (option?.onCompleted) option?.onCompleted();
  }, [data, loading, option]);

  return {
    exportOrders,
  };
};

export default useExportOrders;
