import React, { forwardRef, Ref } from "react";
import { withStyles } from "@material-ui/core/styles";
import MenuMUI, { MenuProps } from "@material-ui/core/Menu";
import MenuItemMUI from "@material-ui/core/MenuItem";

import { COLORS } from "constants/Colors";

const DropDownMenu = (props: MenuProps, ref: Ref<HTMLButtonElement>) => (
  <MenuMUI
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
    innerRef={ref}
  />
);

const PopoverMenu = withStyles({
  paper: {
    border: `1px solid ${COLORS.LightBlue}`,
    borderRadius: "8px",
    overflow: "visible",
  },
})(forwardRef(DropDownMenu));

const CustomMenu = (props: MenuProps, ref: Ref<HTMLButtonElement>) => <MenuMUI {...props} innerRef={ref} />;
const Menu = withStyles({
  paper: {
    border: `1px solid ${COLORS.LightBlue} !important`,
  },
})(forwardRef(CustomMenu));

const MenuItem = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: COLORS.Primary,
      color: COLORS.White,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary, .menu-label": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItemMUI);

export { PopoverMenu, Menu, MenuItem };
