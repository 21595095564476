import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required(),
  phoneNumber: yup.string().matches(/^\d{10}$/, {
    excludeEmptyString: true,
    message: "PhoneNumberMatchError",
  }),
});

export default schema;
