import React, { FC } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import CircularProgress from "components/CircularProgress";

import useSetTitleOnMount from "utils/hooks/useSetTitleOnMount";

// TODO: Change all router to using Private Route as wrapper instance of using render component prop.
// And then remove all render component prop logic in this file.

type PrivateRoutePropsType = RouteProps & {
  redirectTo?: string;
  isAllowed?: boolean;
  loading: boolean;
  title?: string;
};

const PrivateRoute: FC<PrivateRoutePropsType> = ({
  isAllowed,
  component,
  redirectTo,
  title = "",
  children,
  loading,
  ...rest
}) => {
  const { t } = useTranslation();
  useSetTitleOnMount(t(title));

  // case 1 using PrivateRoute as wrapper
  // render child component if isAllowed is pass
  // - Example -
  //   <PrivateRoute
  //   loading={loading}
  //   path={`/${PROJECT}/:projectId/${SHIPPING}`}
  //   isAllowed={isAdmin}
  //   redirectTo="/"
  // >
  //   <Shipping>
  //     <ShippingSubRoute />
  //   </Shipping>
  // </PrivateRoute>

  if (!loading && isAllowed && children) {
    // render child component if isAllowed is pass
    return <Route {...rest}>{children}</Route>;
  }

  if (!loading && !isAllowed && children) {
    // redirect if isAllowed is not pass
    return <Redirect to={redirectTo || "/"} />;
  }

  // case 2 using render component prop
  // render component from prop if isAllowed is pass
  // - Example -
  // <PrivateRoute
  //   exact
  //   loading={loading}
  //   path={`/${PROJECT}/:projectId/${CHAT}`}
  //   isAllowed={isAdmin}
  //   component={Chat}
  // />

  const render = () => {
    if (loading)
      // render loading
      return (
        <Grid container className="flex-grow-1" justify="center" alignItems="center">
          <Grid item>
            <CircularProgress size={40} />
          </Grid>
        </Grid>
      );

    if (isAllowed && component) {
      // render component from component prop
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return React.createElement(component as any);
    }

    // redirect if isAllowed is not pass
    return <Redirect to={redirectTo || "/"} />;
  };

  return <Route {...rest} render={render} />;
};

export default PrivateRoute;
