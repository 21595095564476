import styled from "styled-components";
import { Z_INDEX } from "constants/ZIndex";

export const ProductImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  text-align: center;
  object-fit: cover;
`;

export const CircleRemoveIconContainer = styled.div`
  position: absolute;
  z-index: ${Z_INDEX.REMOVE_IMAGE_ICON};
  top: -9px;
  right: -12px;
`;
