import React, { useState } from "react";
import get from "lodash/get";
import moment from "moment";
import debounce from "lodash/debounce";

import { useMutation } from "@apollo/client";
import { NOTIFICATIONS } from "graphql/notification/query";
import { MARK_NOTIFICATION } from "graphql/notification/mutation";

import { useParams } from "react-router-dom";
import { ProjectIdType } from "types/Project";
import { useTranslation } from "react-i18next";
import { DEBOUNCE_TIME } from "constants/Order";

import ButtonBase from "components/ButtonBase";
import Card from "components/Card";
import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import Pagination from "components/Pagination";
import Search from "components/Search";
import Typography from "components/Typography";
import RefreshIcon from "@material-ui/icons/Refresh";
import { LIMIT_PREVIEW_NOTIFICATION_MESSAGES } from "constants/Notification";
import { NotificationInfo, NotificationNameOptions as NotificationNameEnum } from "types/Notification";
import { OptionType, ValueType, OptionsType } from "react-select";
import MultiSelectWithCheckBox from "components/MultiSelectWithCheckBox";
import useGetProject from "utils/hooks/useGetProject";
import NotificationListItem from "../NotificationListItem";
import { useNotifications } from "./hooks/useNotifications";

import DateFilterSelector from "./DateFilterSelector";

export type DateRangeType = {
  start: string;
  end: string;
};

export const AllNotification: React.FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();

  const [pageNo, setPageNo] = useState(1);
  const [customerName, setCustomerName] = useState("");
  const [startDate, setStartDateValue] = useState("");
  const [endDate, setEndDateValue] = useState("");
  const [selectedNotificationName, setSelectedNotificationName] = useState<string[] | []>([]);

  const { isDeeplePayEnabled } = useGetProject(projectId);

  const { notifications, loading, newNotificationCount, setNewNotificationCount, refetch } = useNotifications({
    projectId,
    pageNo,
    customerName,
    startDate,
    endDate,
    selectedNotificationName,
  });

  const NotificationNameOptions: OptionsType<OptionType> = Object.values(NotificationNameEnum)
    .filter(
      (notificationName: string) =>
        isDeeplePayEnabled || notificationName !== NotificationNameEnum.ORDER_PAID_WITH_DEEPLE_PAY,
    ) // filter ORDER_PAID_WITH_DEEPLE_PAY out if project does not use deeple pay
    .map((data: string) => ({
      label: t(`notification.configuration.name.${data}`),
      value: data,
    }));

  const handleRefresh = () => {
    setNewNotificationCount(0);
    setEndDateValue("");
    setStartDateValue("");
    setCustomerName("");
    setPageNo(1);
    setSelectedNotificationName([]);
    refetch();
  };

  const setStartDate = (value: string | null) => {
    if (value) {
      setStartDateValue(moment(value).toISOString());
    } else {
      setStartDateValue("");
    }
  };

  const setEndDate = (value: string | null) => {
    if (value) {
      setEndDateValue(moment(value).toISOString());
    } else {
      setEndDateValue("");
    }
  };

  const [markNotification, { loading: markNotificationLoading }] = useMutation(MARK_NOTIFICATION, {
    update(cache) {
      const queryVariable = {
        projectId,
        offset: pageNo - 1,
        limit: LIMIT_PREVIEW_NOTIFICATION_MESSAGES,
        filter: {
          customerNames: [customerName],
          dateRange: { start: startDate, end: endDate },
          notificationNames: selectedNotificationName,
        },
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const cacheNotifications = cache.readQuery<any>({
        query: NOTIFICATIONS,
        variables: queryVariable,
      });

      if (cacheNotifications) {
        cache.writeQuery({
          query: NOTIFICATIONS,
          variables: queryVariable,
          data: {
            notifications: {
              results: [...cacheNotifications.notifications.result],
              total: cacheNotifications.notifications.total,
              __typename: "NotificationConnection",
            },
          },
        });
      }
    },
  });

  const onClickMarkResponse = async (notificationId: string) => {
    if (!markNotificationLoading) {
      await markNotification({
        variables: {
          projectId,
          notificationId: Number(notificationId),
        },
      });
    }
  };

  const setPageValue = (value: number) => {
    if (value < 1) {
      return setPageNo(1);
    }
    return setPageNo(value);
  };

  const setFilterCustomerName = debounce((value: string) => {
    setPageNo(1);
    setCustomerName(value);
  }, DEBOUNCE_TIME);

  const notificationsTotal = Number(get(notifications, "total"));
  const notificationData = get(notifications, "results") || [];

  return (
    <Grid container className="p-3">
      <Grid item xs={12} className="py-2 pb-3 px-2">
        <Grid container alignContent="center" alignItems="center">
          <Typography variant="title2" color="dark">
            {t("ALL_NOTIFICATION")}
          </Typography>
          {Boolean(newNotificationCount) && (
            <ButtonBase className="ml-3" onClick={handleRefresh}>
              <Typography variant="title7" color="primary">
                {`${t("NEW_NOTIFICATION")} ${newNotificationCount} `}
                <RefreshIcon className="align-text-bottom" />
              </Typography>
            </ButtonBase>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} className="mb-2">
        <Search
          placeholder={t("searchPlaceholder.userSettingsNotifications")}
          defaultValue=""
          onChange={(value) => {
            setFilterCustomerName(value);
          }}
        />
      </Grid>

      <Grid item xs={12} className="mb-2">
        <MultiSelectWithCheckBox
          placeholder={`${t("Select")} ${t("Notifications")}`}
          options={NotificationNameOptions}
          onChange={(event: ValueType<OptionType>) => {
            const newTagsArray = (event as OptionType[]).map((tagInputValue) => {
              return tagInputValue.value;
            });
            setSelectedNotificationName(newTagsArray);
          }}
        />
      </Grid>

      <Grid item xs={12} className="mb-2">
        <DateFilterSelector endDate={endDate} startDate={startDate} onEndDate={setEndDate} onStartDate={setStartDate} />
      </Grid>

      <Grid item xs={12}>
        <Card>
          <Grid container>
            {notificationsTotal === 0 && (
              <Grid container justify="center" className="my-2">
                <Typography variant="body3">{t("NO_NOTIFICATION")}</Typography>
              </Grid>
            )}

            {loading ? (
              <Grid container justify="center" className="my-2">
                <CircularProgress />
              </Grid>
            ) : (
              (notificationData as NotificationInfo[]).map((data: NotificationInfo) => (
                <Grid item xs={12} key={data.id}>
                  <NotificationListItem
                    isDisplayDescription
                    notificationInfo={data}
                    onClickMarkResponse={onClickMarkResponse}
                  />
                </Grid>
              ))
            )}

            <Pagination
              rowsPerPage={LIMIT_PREVIEW_NOTIFICATION_MESSAGES}
              page={pageNo}
              totalItem={notificationsTotal}
              handlePageChange={setPageValue}
              isMobile
            />
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AllNotification;
