import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

type useRenderExportedNumberType = {
  isSelectedAll?: boolean;
  excludeIds?: Record<string, boolean>;
  selectedIds?: Record<string, boolean>;
  total?: number;
};

const useRenderExportedNumber = ({
  isSelectedAll = false,
  excludeIds = {},
  selectedIds = {},
  total = 0,
}: useRenderExportedNumberType) => {
  const { t } = useTranslation();

  const renderExportedNumber = useCallback(() => {
    const excludeIdsLength = Object.values(excludeIds).filter(Boolean).length;
    const selectedIdsLength = Object.values(selectedIds).filter(Boolean).length;

    if (isSelectedAll && excludeIdsLength) {
      return `(${total - excludeIdsLength})`;
    }

    if (selectedIdsLength) {
      return `${selectedIdsLength}`;
    }

    return `${t("salesReport.exportedNumber.all")}`;
  }, [excludeIds, isSelectedAll, selectedIds, t, total]);

  const exportedNumber = useMemo(() => renderExportedNumber(), [renderExportedNumber]);

  return exportedNumber;
};

export default useRenderExportedNumber;
