import React, { FC, Fragment, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FieldError } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import { PRODUCT_OPTION_CHOICE_LIMIT } from "config";
import get from "lodash/get";
import COLORS from "constants/Colors";

import Button, { ButtonWithIcon } from "components/Button";
import Card from "components/Card";
import ErrorText from "components/ErrorText";
import Grid from "components/Grid";
import Switch from "components/Switch";
import TextField from "components/TextField";
import Typography from "components/Typography";

import { IcDelete, IcLongArrowUp, IcLongArrowDown } from "components/SvgIcons";
import StepperNumberInput from "components/StepperNumberInput";
import { SvgIcon } from "components/Icon";
import { ValueOf } from "types/Common";
import { ProductOptionType, ProductOptionChoiceType, OrderProductOption } from "types/Product";
import { Divider } from "./styled";

type VariationCardPropsType = {
  isTheLast?: boolean;
  onChangeMaximum: (maximum: number) => void;
  onChangeName: (name: string) => void;
  onChangeChoice: (
    choiceIndex: number,
    fieldName: keyof ProductOptionChoiceType,
    value: ValueOf<ProductOptionChoiceType>,
  ) => void;
  onClickAddOption: () => void;
  onClickChangeOrder: (orderProductOption: OrderProductOption) => void;
  onClickChoiceType: (isMultipleChoices: boolean) => void;
  onClickRemove: () => void;
  onClickRemoveChoice: (indexChoice: number) => void;
  onToggleActiveSwitch: () => void;
  onToggleRequiredSwitch: () => void;
  option: ProductOptionType;
  order: number;
};

const VariationCard: FC<VariationCardPropsType> = ({
  isTheLast = false,
  onChangeChoice,
  onChangeMaximum,
  onChangeName,
  onClickAddOption,
  onClickChangeOrder,
  onClickChoiceType,
  onClickRemove,
  onClickRemoveChoice,
  onToggleActiveSwitch,
  onToggleRequiredSwitch,
  option,
  order,
}) => {
  const { errors } = useFormContext();
  const { t } = useTranslation();
  const optionNameError = get(errors, `productSKUs.0.options.${order - 1}.name`);
  const optionError = get(errors, `productSKUs.0.options.${order - 1}`);
  const maximum = useMemo(() => option.choices.filter(({ isActive }) => isActive).length, [option]);

  return (
    <Card>
      <Grid container>
        <Grid item xs={6}>
          <Grid container item spacing={1} alignItems="center">
            <Grid item>
              <Switch checked={option.isActive} onChange={onToggleActiveSwitch} />
            </Grid>
            <Grid item>
              <Typography variant="body4" color={COLORS.DarkMed}>
                {t("No.")}
                {order}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} container justify="flex-end">
          <Grid item className="pr-2">
            <ButtonWithIcon
              type="button"
              disabled={order === 1}
              onClick={() => onClickChangeOrder(OrderProductOption.ORDER_UP)}
            >
              <SvgIcon className="ml-2 mt-1" component={IcLongArrowUp} fontSize="large" />
            </ButtonWithIcon>
          </Grid>
          <Grid item className="pr-2">
            <ButtonWithIcon disabled={isTheLast} onClick={() => onClickChangeOrder(OrderProductOption.ORDER_DOWN)}>
              <SvgIcon className="ml-2 mt-1" component={IcLongArrowDown} fontSize="large" />
            </ButtonWithIcon>
          </Grid>
          <Grid item>
            <ButtonWithIcon onClick={onClickRemove}>
              <SvgIcon className="ml-1 mt-1" component={IcDelete} fontSize="small" />
            </ButtonWithIcon>
          </Grid>
        </Grid>
      </Grid>

      <div className="mt-3">
        <Button
          isSelected={!option.isMultipleChoices}
          onClick={() => {
            onClickChoiceType(false);
          }}
          color="secondary"
          borderRadius="8px"
          className="m-1"
        >
          {t("product.addFoodForm.variationCard.singleChoose")}
        </Button>
        <Button
          isSelected={option.isMultipleChoices}
          onClick={() => {
            onClickChoiceType(true);
          }}
          color="secondary"
          borderRadius="8px"
          className="m-1"
        >
          {t("product.addFoodForm.variationCard.multipleChoose")}
        </Button>
      </div>

      <div className="mt-3">
        <TextField
          className="mb-1"
          error={Boolean(optionNameError)}
          fullWidth
          label={t("product.addFoodForm.variationCard.name")}
          value={option.name}
          onChange={(event) => onChangeName(event.target.value)}
        />
        {optionNameError && <ErrorText>{t((optionNameError as FieldError).message || "")}</ErrorText>}
      </div>

      {option.choices.map((choice, indexChoice) => {
        const choiceNameError = get(errors, `productSKUs.0.options.${order - 1}.choices.${indexChoice}.name`);
        const isTheLastChoice = option.choices.length === indexChoice + 1;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={indexChoice}>
            <Grid container spacing={1} className="my-2">
              <Grid item xs={6} container alignItems="center">
                <Grid item>
                  <Switch
                    checked={choice.isActive}
                    onChange={() => {
                      onChangeChoice(indexChoice, "isActive", !choice.isActive);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} container alignItems="center" justify="flex-end">
                <Grid item>
                  <ButtonWithIcon
                    disabled={option.choices.length <= 1}
                    onClick={() => {
                      onClickRemoveChoice(indexChoice);
                    }}
                  >
                    <SvgIcon className="ml-1 mt-1" component={IcDelete} fontSize="small" />
                  </ButtonWithIcon>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1} className="mb-2">
              <Grid item xs={6}>
                <Typography variant="body4" color={COLORS.DarkMed}>
                  {t("product.addFoodForm.variationCard.options")}
                </Typography>
                <TextField
                  className="mb-1"
                  error={Boolean(choiceNameError)}
                  placeholder={t("Name")}
                  fullWidth
                  variant="outlined"
                  value={choice.name}
                  onChange={(event) => {
                    onChangeChoice(indexChoice, "name", event.target.value);
                  }}
                />
                {choiceNameError && <ErrorText>{t((choiceNameError as FieldError).message || "")}</ErrorText>}
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body4" color={COLORS.DarkMed}>
                  {t("product.addFoodForm.variationCard.choicePrice")}
                </Typography>
                <TextField
                  value={choice.price}
                  variant="outlined"
                  fullWidth
                  placeholder={t("Price")}
                  type="price"
                  onChange={(event) => {
                    onChangeChoice(indexChoice, "price", Number(event.target.value));
                  }}
                />
              </Grid>
            </Grid>
            {!isTheLastChoice && <Divider />}
          </Fragment>
        );
      })}

      <div className="mt-3">
        <Button
          color="secondary"
          fullWidth
          onClick={onClickAddOption}
          disabled={option.choices.length >= PRODUCT_OPTION_CHOICE_LIMIT}
        >
          + {t("product.addFoodForm.variationCard.addMoreOption")}
        </Button>
      </div>

      {option.isMultipleChoices && (
        <>
          <Grid container className="mt-3">
            <Grid item xs={6} container alignItems="center">
              <Grid item>
                <Typography variant="body4" color={COLORS.DarkMed}>
                  {t("product.addFoodForm.variationCard.maximum")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <Grid item>
                <StepperNumberInput
                  minValue={1}
                  maxValue={maximum}
                  defaultColor
                  value={option.maximum}
                  onChange={(value: number) => {
                    onChangeMaximum(value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className="mt-3">
            <Grid item xs={6} container alignItems="center">
              <Grid item>
                <Typography variant="body4" color={COLORS.DarkMed}>
                  {t("product.addFoodForm.variationCard.makeItOptional")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <Grid item>
                <Switch checked={!option.isRequired} onChange={onToggleRequiredSwitch} />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {optionError && (
        <div className="mt-3">
          <ErrorText>{t((optionError as FieldError).message || "")}</ErrorText>
        </div>
      )}
    </Card>
  );
};

export default VariationCard;
