import { useMutation } from "@apollo/client";
import { CREATE_SHIPPOP_ORDER } from "graphql/shipping/mutation";
import { SHIPPOP_REPORTS } from "graphql/salesReport/query";
import produce from "immer";
import { SortByType, OrderReportQueryType } from "types/Report";
import { Filter } from "./usePaginationState";

const useCreateShippopOrder = (
  orderIds: string[],
  projectId: string,
  rowsPerPage: number,
  page: number,
  sortBy: SortByType,
  filter: Filter,
) => {
  const [createShippopOrder, { loading }] = useMutation(CREATE_SHIPPOP_ORDER, {
    update(cache, { data }) {
      const variables = {
        projectId,
        pageSize: rowsPerPage,
        page: page - 1,
        filter: { ...sortBy, ...filter },
      };

      const cacheOrderReport = cache.readQuery<OrderReportQueryType>({
        query: SHIPPOP_REPORTS,
        variables,
      });

      if (!data) {
        return;
      }

      const orderReportResults = cacheOrderReport?.orderReports?.results || [];
      const total: number = data?.orderReports?.total || 0;
      const newOrderReportResults = orderReportResults.filter((orderReport) => {
        return !orderIds.includes(orderReport.id);
      });

      if (cacheOrderReport?.orderReports) {
        const newOrderReports = produce(cacheOrderReport.orderReports, (draft) => {
          draft.results = newOrderReportResults;
          draft.total = total - orderIds.length;
        });

        cache.writeQuery({
          query: SHIPPOP_REPORTS,
          variables,
          data: {
            orderReports: newOrderReports,
          },
        });
      }
    },
  });

  return { createShippopOrder, isLoading: loading };
};

export default useCreateShippopOrder;
