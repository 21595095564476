import React, { FC, MouseEvent, memo } from "react";
import Grid from "components/Grid";
import ImportProductButton from "../ImportProductButton";
import ImportProductButtonGrid from "../StyledComponent/ImportProductButtonGrid";
import DownloadSampleGrid from "../StyledComponent/DownloadSampleGrid";
import DownloadSampleLink from "../StyledComponent/DownloadSampleLink";

type ImportButtonProps = {
  isFileType?: boolean;
  label: string;
  subLabel: string;
  onChange?: (file: File) => void;
  isLoading?: boolean;
  onClick?: () => void;
  onClickLink?: (event: MouseEvent<HTMLAnchorElement>) => void;
  labelLink: string;
};

const ImportButton: FC<ImportButtonProps> = ({
  isFileType = false,
  label,
  subLabel,
  onChange,
  isLoading = false,
  onClick,
  onClickLink,
  labelLink,
}) => (
  <ImportProductButtonGrid item container justify="flex-end">
    <Grid item>
      <ImportProductButton
        color="secondary"
        size="small"
        isFileType={isFileType}
        label={label}
        subLabel={subLabel}
        onChange={onChange}
        onClick={onClick}
        isLoading={isLoading}
      />
    </Grid>
    <DownloadSampleGrid item className="pt-2 pr-1">
      <DownloadSampleLink onClick={onClickLink} rel="noopener noreferrer" target="_blank">
        {labelLink}
      </DownloadSampleLink>
    </DownloadSampleGrid>
  </ImportProductButtonGrid>
);

export default memo(ImportButton);
