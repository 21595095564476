import { useQuery } from "@apollo/client";

import { POSTS } from "graphql/facebookCrawler/query";
import { CrawlerType } from "types/FacebookCrawler";

export const useFetchFacebookPost = (projectId: string, type: CrawlerType | null, limit: number, after: string) => {
  const { data: postsData, loading: isPostsDataLoading } = useQuery(POSTS, {
    skip: !projectId || !type,
    variables: {
      after,
      limit,
      projectId,
      type,
    },
    fetchPolicy: "network-only",
  });

  return { postsData, isPostsDataLoading };
};
