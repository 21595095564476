import styled from "styled-components";

export const IconWrapper = styled.div`
  background-color: ${({ theme }) => theme.COLORS.Light};
  color: ${({ theme }) => theme.COLORS.DarkMed};
  height: 46px;
  width: 46px;
  min-width: 46px;
  text-align: center;
  font-size: 28px;
  border-right: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  border-radius: 7px 0 0 7px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DatePickerWrapper = styled.div`
  background-color: ${({ theme }) => theme.COLORS.White};
  color: ${({ theme }) => theme.COLORS.DarkMed};
  border-radius: 7px;
  border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  width: 100%;
  display: flex;
  overflow: hidden;
`;

export const DatePickerLabel = styled.div`
  background-color: ${({ theme }) => theme.COLORS.Light};
  color: ${({ theme }) => theme.COLORS.DarkMed};
  border-right: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  display: flex;
  padding: 14px;
  justify-content: center;
  width: 120px;
`;
