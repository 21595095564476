import { OptionType } from "react-select";
import { Gender } from "types/AISetting";

export const SEARCH_PRODUCT_ORDER_OPTIONS: OptionType[] = [
  {
    label: "First created",
    value: "FIRST_CREATED",
  },
  {
    label: "Last created",
    value: "LAST_CREATED",
  },
  {
    label: "Latest update",
    value: "LAST_UPDATED",
  },
];

export const ORDER_PREFIX = {
  ORDER_NUMBER: "ON",
  RECEIPT_NUMBER: "RN",
};

export const BOT_GENDERS = [Gender.MALE, Gender.FEMALE];
