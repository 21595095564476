import { useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NOTIFICATION_CREATE, NOTIFICATION_UPDATE } from "constants/Notification";
import { ChatMenu, AddRichMenuVariables, UpdateRichMenuVariables, ActivateRichMenuVariables } from "types/RichMenu";
import { RICH_MENU } from "graphql/richMenu/query";
import { ADD_RICH_MENU, UPDATE_RICH_MENU, ACTIVATE_RICH_MENU } from "graphql/richMenu/mutation";
import { notifySuccess, notifyError } from "utils/notify";

const useRichMenu = (projectId?: string, chatMenuId?: string) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery<{ chatMenu?: ChatMenu }>(RICH_MENU, {
    variables: { projectId, chatMenuId },
    skip: !projectId || !chatMenuId,
  });

  const [activate] = useMutation<{ addChatMenu: Partial<ChatMenu> }, ActivateRichMenuVariables>(ACTIVATE_RICH_MENU, {
    onCompleted: () => {
      notifySuccess(t(NOTIFICATION_UPDATE.SUCCESS));
    },
    onError: () => {
      notifyError(t(NOTIFICATION_UPDATE.FAIL));
    },
  });

  const [add] = useMutation<{ addChatMenu: Partial<ChatMenu> }, AddRichMenuVariables>(ADD_RICH_MENU, {
    onCompleted: () => {
      notifySuccess(t(NOTIFICATION_CREATE.SUCCESS));
    },
    onError: () => {
      notifyError(t(NOTIFICATION_CREATE.FAIL));
    },
  });

  const [update] = useMutation<{ updateChatMenu: Partial<ChatMenu> }, UpdateRichMenuVariables>(UPDATE_RICH_MENU, {
    onCompleted: () => {
      notifySuccess(t(NOTIFICATION_UPDATE.SUCCESS));
    },
    onError: () => {
      notifyError(t(NOTIFICATION_UPDATE.FAIL));
    },
  });

  const addRichMenu = useCallback(
    (variables: AddRichMenuVariables) => {
      return add({
        variables,
      });
    },
    [add],
  );

  const updateRichMenu = useCallback(
    (variables: UpdateRichMenuVariables) => {
      return update({
        variables,
      });
    },
    [update],
  );

  const activateRichMenu = useCallback(
    (variables: ActivateRichMenuVariables) => {
      return activate({
        variables,
      });
    },
    [activate],
  );

  return {
    activateRichMenu,
    addRichMenu,
    data: data?.chatMenu,
    isLoading: loading,
    updateRichMenu,
  };
};

export default useRichMenu;
