import { gql } from "@apollo/client";

export const IMPORT_ORDER_TRACKING_NUMBERS = gql`
  mutation importOrderTrackingNumbers($projectId: ID!, $file: Upload!, $isDisabledResponseMessage: Boolean) {
    importOrderTrackingNumbers(
      projectId: $projectId
      file: $file
      isDisabledResponseMessage: $isDisabledResponseMessage
    )
  }
`;
