import * as React from "react";

const SvgIcCircleRemove = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx={16} cy={16} r={16} fill="#EFF1F5" />
      <path
        fill="currentColor"
        d="M21.021 10.979a1.065 1.065 0 00-1.506 0l-3.508 3.508-3.509-3.508a1.065 1.065 0 00-1.506 1.506l3.508 3.508-3.508 3.509a1.065 1.065 0 101.506 1.506l3.509-3.508 3.508 3.508a1.065 1.065 0 001.506-1.506l-3.508-3.509 3.508-3.508a1.065 1.065 0 000-1.506z"
      />
    </g>
  </svg>
);

export default SvgIcCircleRemove;
