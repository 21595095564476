import { gql } from "@apollo/client";

export const GUIDELINE = gql`
  query guideline($projectId: ID!) {
    guideline(projectId: $projectId) {
      hasCategories
      hasProducts
      hasShippingMethod
      hasConnect
      hasFaqs
      hasPaymentMethod
    }
  }
`;
