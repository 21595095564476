import { useQuery } from "@apollo/client";
import { SystemTagType } from "types/Tag";
import { SEARCH_TAGS } from "graphql/tag/query";

type SearchTagData = { searchTags: { results: SystemTagType[]; total: number } };

const useSearchTags = () => {
  const { data, loading, refetch } = useQuery<SearchTagData>(SEARCH_TAGS, {
    skip: true,
  });

  const searchTags = data?.searchTags || [];

  return {
    refetch,
    searchTags,
    isLoadingFetchSearchTags: loading,
  };
};

export default useSearchTags;
