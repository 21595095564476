import { gql } from "@apollo/client";

export const ADDONS = gql`
  query addons($projectId: ID!) {
    addons(projectId: $projectId) {
      id
      name
      type
      entityType
      entityLevel
      pricePerMonth
      extraChargeRate
      imageSearchQuota
      memberQuota
      isSelected
      isSelectable
      amount
    }
  }
`;

export const ADDON_CHANGES_SUMMARY = gql`
  query addonChangesSummary($projectId: ID!, $newAddonIds: [ID]!, $removedAddonIds: [ID]!) {
    addonChangesSummary(projectId: $projectId, newAddonIds: $newAddonIds, removedAddonIds: $removedAddonIds) {
      projectId
      currentBillingDate
      nextBillingDate
      summaryItems {
        items {
          type
          entityType
          entityLevel
          status
          name
          price
          amount
          totalPrice
        }
        previousSubtotal
        changesSubtotal
        subtotal
        vatOptions
        vatPrice
        totalPrice
      } # Items for next cycle billing
      chargedItems {
        items {
          type
          entityType
          entityLevel
          name
          defaultPrice # original addon price
          price # prorate
          amount
          totalPrice # price * amount
          duration
          daysInMonth
        }
        subtotal
        vatOptions
        vatPrice
        totalPrice
      } # Items that will be charged right away
      isDuringFreeTrialPeriod
    }
  }
`;
