import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import Grid from "components/Grid";
import Typography from "components/Typography";

import { convertPriceFormat } from "utils/common";

export type AddonSummaryPropsType = {
  subTotalPrice: number;
  isAddonsChanged: boolean;
  handleCalculateAddon: () => void;
};

const AddonSummary: FC<AddonSummaryPropsType> = (props) => {
  const { t } = useTranslation();

  const { subTotalPrice, handleCalculateAddon, isAddonsChanged } = props;

  return (
    <Grid container>
      <Grid item container justify="space-between" alignItems="center" className="pb-4">
        <Grid item>
          <Typography variant="title7" color="dark" className="pt-1 text-center">
            {t("planAddon.subtotal.label")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="title7" color={subTotalPrice < 0 ? "error" : "primary"} className="pt-1 text-center">
            {t("THB")} {convertPriceFormat(subTotalPrice, 0)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button fullWidth onClick={handleCalculateAddon} disabled={!isAddonsChanged}>
          {t("Continue")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default AddonSummary;
