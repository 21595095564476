import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import numeral from "numeral";

import Card from "components/Card";
import Grid from "components/Grid";
import Typography from "components/Typography";
import Switch from "components/Switch";

import { AddonEntityType } from "types/Addon";
import { TitleLabel } from "./styled";

type AdvanceFeatureCardPropsType = {
  onChange: (isDisabled: boolean) => void;
  type: AddonEntityType;
  isDisabled: boolean;
  price: number;
  isSelectable: boolean;
};

const AdvanceFeatureCard: FC<AdvanceFeatureCardPropsType> = (props) => {
  const { t } = useTranslation();
  const { type, price, isDisabled: initIsDisabled, onChange, isSelectable } = props;
  const formatPrice = numeral(price).format("0,0");
  const [isDisabled, setIsDisabled] = useState(initIsDisabled);

  const handleToggle = () => {
    const newValue = !isDisabled;

    onChange(newValue);
    setIsDisabled(newValue);
  };

  return (
    <Card noShadow padding="24px 16px">
      <Grid container>
        <Grid item>
          <Switch checked={!isDisabled} onChange={handleToggle} disabled={!isSelectable} />
        </Grid>
        <Grid item container xs={10} sm={11} className="pl-3">
          <Grid item xs={12} className="pb-3">
            <TitleLabel variant="title7" color="dark">
              {t(`planAddon.addonItemCard.title.${type.toLowerCase()}`)}
            </TitleLabel>
          </Grid>
          <Grid item xs={12} className="pb-3">
            <Typography variant="body3" color="darkGray">
              {t(`planAddon.addonItemCard.description.${type.toLowerCase()}`)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body3" color="darkGray">
              {price ? t(`planAddon.addonItemCard.price`, { formatPrice }) : t(`planAddon.addonItemCard.defaultActive`)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default AdvanceFeatureCard;
