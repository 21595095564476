import React, { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import Typography from "components/Typography";

import { RectangleButton } from "../index.styled";

type FilterButtonPropsType = {
  label: string;
  isSelected: boolean;
  onClick: (event: MouseEvent) => void;
};

const FilterButton: FC<FilterButtonPropsType> = ({ label, isSelected, onClick }) => {
  const { t } = useTranslation();

  return (
    <RectangleButton isSelected={isSelected} onClick={onClick}>
      <Typography variant="title8" color="darkMed">
        {t(label).toUpperCase()}
      </Typography>
    </RectangleButton>
  );
};

export default FilterButton;
