import styled from "styled-components";

export const Snackbar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffeff2;
  border: 1px solid ${({ theme }) => theme.COLORS.Error};
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
`;

export const IconContainer = styled.div`
  flex: 0 0 auto;
  margin-right: 8px;
`;

export const TextContainer = styled.div`
  flex: 1 1 auto;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
`;

export const SuffixContainer = styled.div`
  flex: 0 0 auto;
  margin-left: 10px;
`;
