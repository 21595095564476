import { useQuery } from "@apollo/client";
import { GUIDELINE } from "graphql/guideline/query";

import type { GuidelineQueryType } from "types/Guideline";

export default function useGuideline(projectId: string) {
  const { data, loading: loadingGuideline, refetch: refetchGuideline } = useQuery<GuidelineQueryType>(GUIDELINE, {
    variables: {
      projectId,
    },
    fetchPolicy: "network-only",
  });

  const hasCategories = data?.guideline?.hasCategories ?? false;
  const hasConnect = data?.guideline?.hasConnect ?? false;
  const hasFaqs = data?.guideline?.hasFaqs ?? false;
  const hasPaymentMethod = data?.guideline?.hasPaymentMethod ?? false;
  const hasProducts = data?.guideline?.hasProducts ?? false;
  const hasShippingMethod = data?.guideline?.hasShippingMethod ?? false;
  const hasStoreSettings = data?.guideline?.hasStoreSettings ?? false;

  return {
    loadingGuideline,
    hasCategories,
    hasConnect,
    hasFaqs,
    hasPaymentMethod,
    hasProducts,
    hasShippingMethod,
    hasStoreSettings,
    refetchGuideline,
  };
}
