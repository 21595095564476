import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useRef } from "react";
import XLSX from "xlsx";
import { ExportFileType } from "types/SalesReport";

import { EXPORT_INVENTORIES } from "graphql/inventory/query/exportInventories";

type ExportInventoriesQuery = {
  exportInventories: { productSKUId: string; value: string; productCode: string }[];
};

const DEFAULT_FILE_TYPE = ExportFileType.CSV;
const HEADER_TABLE = [["productSKUCode"], ["inventory"]];

const useExportInventories = (projectId: string) => {
  const [queryExportInventories, { data, loading }] = useLazyQuery<ExportInventoriesQuery>(EXPORT_INVENTORIES, {
    fetchPolicy: "network-only",
  });
  const fileType = useRef<ExportFileType>(DEFAULT_FILE_TYPE);

  const exportInventories = useCallback(
    (exportFileType: ExportFileType) => {
      fileType.current = exportFileType;
      queryExportInventories({
        variables: {
          projectId,
        },
      });
    },
    [projectId, queryExportInventories],
  );

  useEffect(() => {
    if (loading || !data?.exportInventories) {
      return;
    }
    const { exportInventories } = data;

    const tableData = exportInventories.map((exportInventory) => [
      [exportInventory.productCode],
      [exportInventory.value],
    ]);

    /* convert from array of arrays to workbook */
    const worksheet = XLSX.utils.aoa_to_sheet([HEADER_TABLE, ...tableData]);
    const newWorkbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkbook, worksheet, "product-inventories");
    XLSX.writeFile(newWorkbook, `export_product_inventories.${fileType.current.toLowerCase()}`);
    fileType.current = DEFAULT_FILE_TYPE;
  }, [data, loading]);

  return {
    loading,
    exportInventories,
  };
};

export default useExportInventories;
