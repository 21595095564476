import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Grid from "components/Grid";
import ParcelInvoiceContainer from "domain/Report/ParcelInvoiceReport";
import PageTitle from "components/PageTitle";
import { DisableFeature } from "pages/DisableFeature";
import { ProjectIdType } from "types/Project";
import useGetProject from "utils/hooks/useGetProject";

export const ParcelInvoiceReport: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const { isConsignmentReportEnabled } = useGetProject(projectId);

  return (
    <Grid container alignItems="flex-start" alignContent="flex-start" justify="flex-start" className="px-3">
      <PageTitle title={t("Parcel invoice report")} className="pt-4" />
      {isConsignmentReportEnabled ? (
        <ParcelInvoiceContainer />
      ) : (
        <DisableFeature>
          <ParcelInvoiceContainer />
        </DisableFeature>
      )}
    </Grid>
  );
};
