import React, { FC, MouseEvent } from "react";
import Typography from "components/Typography";
import Grid from "components/Grid";
import Divider from "components/Divider";
import { IcArrowBack } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";
import BackButton from "components/BackButton";

type PageLayoutProps = {
  title: string;
  onClickBack: (event: MouseEvent) => void;
  backLabel: string;
};

const PageLayout: FC<PageLayoutProps> = ({ children, title, onClickBack, backLabel }) => {
  return (
    <div data-cy={`edit-shipping-method-${title.toLowerCase()}`}>
      <Grid item className="py-2 my-1">
        <BackButton onClick={onClickBack}>
          <SvgIcon component={IcArrowBack} fontSize="small" />
          <Typography variant="body2" className="ml-2">
            {backLabel}
          </Typography>
        </BackButton>
      </Grid>
      <Grid item className="p-3 m-1">
        <Divider thickness="1px" className="m-0" />
      </Grid>
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="title2" className="mx-4 mt-1">
            {title}
          </Typography>
        </Grid>
      </Grid>
      {children}
    </div>
  );
};

export default PageLayout;
