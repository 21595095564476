import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import Typography from "components/Typography";
import { CircleIcon, SvgIcon } from "components/Icon";
import { IcDigitalProduct } from "components/SvgIcons";
import COLORS from "constants/Colors";
import { NO_IMG_DEEPLE_LOGO } from "constants/Image";
import { Product } from "types";
import { getProductSKUsKeyValueFromProductSKUs, getProductPriceRangeFromProductSKUs } from "utils/common";

import {
  ProductImage,
  DigitalProductLabelBadge,
  ProductNameWrapper,
  ProductNameRowWrapper,
} from "../StyledComponent/product.styled";

export type ProductListItemPropsType = {
  productImage: string;
  productName: string;
  productSKUs: Array<Product.ProductSKUType>;
  defaultPrice: number;
  isActive: boolean;
  brand?: string;
  productImageMaxSize?: number;
  isShowFreeText?: boolean;
  isShowPrice?: boolean;
  isShowCardBadge?: boolean;
  isDigitalProduct?: boolean;
};

const ProductListItem: FC<ProductListItemPropsType> = (props) => {
  const {
    defaultPrice,
    isActive,
    isShowFreeText,
    isShowPrice = true,
    productImage,
    productImageMaxSize,
    productName,
    productSKUs,
    isShowCardBadge = false,
    isDigitalProduct = false,
  } = props;

  const { t } = useTranslation();
  const productSKUsGroupData = productSKUs ? getProductSKUsKeyValueFromProductSKUs(productSKUs) : [];
  const statusColor = isActive ? COLORS.Success : COLORS.DarkMed;
  const isFreeSKUs = productSKUs.map(({ isFree }) => isFree);
  const isAllFree = isFreeSKUs.every((isFreeSKU) => isFreeSKU === true);
  const isAllNotFree = isFreeSKUs.every((isFreeSKU) => isFreeSKU === false);

  let freeText = t("Free");
  if (isAllNotFree) {
    freeText = "";
  } else if (!isAllFree) {
    freeText = ` , ${freeText}`;
  }

  return (
    <Grid container>
      <Grid item style={{ flex: 0 }}>
        <ProductImage
          className="d-flex"
          alt={productImage ? productName : "no img"}
          src={productImage || NO_IMG_DEEPLE_LOGO}
          productImageMaxSize={productImageMaxSize}
        />
      </Grid>

      <Grid item container style={{ flex: 1 }} className="pl-3 ellipsis" direction="column">
        <ProductNameRowWrapper
          item
          container
          alignItems="center"
          className="mb-1 ellipsis"
          isShowCardBadge={isShowCardBadge}
        >
          <ProductNameWrapper item container isDigitalProduct={isDigitalProduct}>
            <Typography variant="title8" color="dark" className="mt-1 ellipsis">
              <CircleIcon color={statusColor} />
              {productName}
            </Typography>
          </ProductNameWrapper>
          {!isShowCardBadge && isDigitalProduct && (
            <DigitalProductLabelBadge item container alignItems="center" justify="center">
              <SvgIcon
                className="p-0 m-0"
                viewBox="0 0 28 28"
                component={IcDigitalProduct}
                fontSize="small"
                htmlColor={COLORS.White}
              />
              <Typography variant="body4" className="pl-2" color="white">
                Digital
              </Typography>
            </DigitalProductLabelBadge>
          )}
        </ProductNameRowWrapper>

        <Grid item container className="mb-1">
          {productSKUsGroupData.map((productSKU) => {
            return (
              <Typography variant="body3" className="mt-1 ellipsis" key={productSKU.key}>
                {`${productSKU.key} : ${productSKU.value.map((value: string) => ` ${value}`)}`}
              </Typography>
            );
          })}
        </Grid>

        {isShowPrice && productSKUs.length > 0 && (
          <Grid item className="mb-1">
            <Typography className="mt-1 ellipsis" variant="body3">
              {`${t("Price")} : `}
              {!(isShowFreeText && isAllFree) && getProductPriceRangeFromProductSKUs(productSKUs, defaultPrice, t)}
              {isShowFreeText && freeText}
            </Typography>
          </Grid>
        )}

        {productSKUs.length === 0 && (
          <Grid item className="mb-1">
            <Typography className="mt-1" variant="body3">
              {t("shoppingCart.manageShoppingCart.productCard.isDisabledText")}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ProductListItem;
