import { yupResolver } from "@hookform/resolvers/yup";
import React, { FC, useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RICH_MENU } from "constants/i18n";
import Colors from "constants/Colors";
import { STORE_SETTINGS, PROJECT, THEME_CONFIG } from "constants/Router";
import Button from "components/Button";
import ButtonBase from "components/ButtonBase";
import ConfirmationModal from "components/ConfirmationModal";
import Grid from "components/Grid";
import Typography from "components/Typography";
import IcArrowBack from "components/SvgIcons/IcArrowBack";
import Loading from "domain/Faq/FaqThEn/FaqContainer/Loading";
import { ProjectIdType } from "types/Project";
import { Behavior, Action, LineMenuTemplate } from "types/RichMenu";
import { Platform } from "types/Platform";
import useToggle from "utils/hooks/useToggle";
import ActionComponent from "./components/Action";
import RichMenuDetail from "./components/RichMenuDetail";
import Template from "./components/Template";
import useRichMenu from "./hooks/useRichMenu";
import { ACTION_SECTIONS, MAP_TEMPLATE_SIZE, DEFAULT_LINE_RICH_MENU } from "./config";
import { mapAction, mapMenuAction } from "./mapAction";
import getSchema from "./schema";

const LineRichMenu: FC = () => {
  const { t } = useTranslation(RICH_MENU);
  const [recentUpdatedId, setRecentUpdatedId] = useState<string>();
  const schema = useMemo(() => getSchema(t), [t]);
  const methods = useForm({ resolver: yupResolver(schema) });
  const { handleSubmit, watch } = methods;
  const { isOpen, handleToggle, handleClose } = useToggle();
  const { projectId, chatMenuId } = useParams<ProjectIdType & { chatMenuId?: string }>();
  const history = useHistory();

  const { addRichMenu, data, isLoading, updateRichMenu, activateRichMenu } = useRichMenu(projectId, chatMenuId);

  const handleCancelModal = () => {
    handleClose();
    history.push(`/${PROJECT}/${projectId}/${STORE_SETTINGS}/${THEME_CONFIG}`);
  };

  const handleSubmitModal = async () => {
    if (recentUpdatedId || chatMenuId) {
      await activateRichMenu({ projectId, chatMenuId: (recentUpdatedId || chatMenuId) as string });

      handleClose();
      history.push(`/${PROJECT}/${projectId}/${STORE_SETTINGS}/${THEME_CONFIG}`);
    }
  };

  const watchedTemplate: LineMenuTemplate | undefined = watch("template", data?.template);

  const size = watchedTemplate ? MAP_TEMPLATE_SIZE[watchedTemplate] : 0;

  const handleClickSubmitButton = () => {
    setTimeout(() => {
      const el = document.getElementsByClassName("action-error");

      if (el?.length) {
        Object.values(el).forEach((element) => {
          ((element as unknown) as HTMLDivElement)?.click?.();
        });
      }
    }, 500);
  };

  const handleSubmitRichMenu = handleSubmit(async (formData) => {
    const variables = {
      projectId,
      chatMenu: {
        name: formData?.title,
        platform: Platform.LINE,
        menuActions: formData?.action.map((action: Action, index: number) => ({
          bounds: ACTION_SECTIONS[index],
          action: mapAction(action),
        })),
        menuUrl: formData?.imageUrl,
        menuLabel: formData?.label,
        isCollapsed: formData?.behavior === Behavior.COLLAPSED,
        template:
          formData?.template === LineMenuTemplate.DEFAULT_WITH_IMAGE ? LineMenuTemplate.DEFAULT_0 : formData?.template,
      },
    };

    if (chatMenuId) {
      const result = await updateRichMenu({ ...variables, chatMenuId });
      const data = result?.data?.updateChatMenu;

      if (data) {
        setRecentUpdatedId(data.id);
        handleToggle();
      }
    } else {
      const result = await addRichMenu(variables);
      const data = result?.data?.addChatMenu;

      if (data) {
        setRecentUpdatedId(data.id);
        handleToggle();
      }
    }
  });

  const defaultAction = useMemo(
    () =>
      watchedTemplate === LineMenuTemplate.DEFAULT_WITH_IMAGE
        ? DEFAULT_LINE_RICH_MENU.action
        : data?.menuActions?.map(mapMenuAction),
    [data, watchedTemplate],
  );

  return (
    <div className="px-0 py-4 px-sm-4">
      <ButtonBase
        className="d-flex mb-4 ml-3 ml-md-0 mt-4 mt-md-0"
        onClick={() => {
          history.push(`/${PROJECT}/${projectId}/${STORE_SETTINGS}/${THEME_CONFIG}`);
        }}
      >
        <IcArrowBack fontSize="20px" color={Colors.DarkMed} />
        <Typography variant="title2" color="dark" className="ml-3">
          Line rich menu
        </Typography>
      </ButtonBase>

      {isLoading ? (
        <Loading loadingLabel={t("LOADING")} size={50} />
      ) : (
        <FormProvider {...methods}>
          <form noValidate autoComplete="off" onSubmit={handleSubmitRichMenu}>
            <div className="mb-4">
              <RichMenuDetail
                defaultTitle={data?.name}
                defaultLabel={data?.menuLabel}
                defaultBehavior={data?.isCollapsed ? Behavior.COLLAPSED : Behavior.SHOWN}
              />
            </div>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={5} md={3}>
                <Template defaultImageUrl={data?.menuUrl} defaultTemplate={data?.template} />
              </Grid>
              <Grid item xs={12} sm={7} md={9}>
                <ActionComponent key={watchedTemplate} size={size} defaultAction={defaultAction} />
              </Grid>
            </Grid>

            <div className="text-center text-sm-right my-3 px-0 px-sm-3">
              <Button type="submit" color="primary" onClick={handleClickSubmitButton} style={{ width: 200 }}>
                {t("SAVE")}
              </Button>
            </div>
          </form>
        </FormProvider>
      )}

      <ConfirmationModal
        title={`${t("ACTIVATE_CONFIRMATION")} ?`}
        isOpen={isOpen}
        onClose={handleCancelModal}
        onSubmit={handleSubmitModal}
        textSubmit={t("ACTIVATE_NOW")}
        textCancel={t("LATER")}
      />
    </div>
  );
};

export default LineRichMenu;
