import { gql } from "@apollo/client";

export const SAVE_PRODUCT_NAME = gql`
  mutation saveProductNameSynonyms($projectId: ID!, $synonyms: [SynonymsInput]!) {
    savedSynonyms: saveProductNameSynonyms(projectId: $projectId, synonyms: $synonyms) {
      word
      synonyms
    }
  }
`;

export const SAVE_PRODUCT_BRAND = gql`
  mutation saveProductBrandSynonyms($projectId: ID!, $synonyms: [SynonymsInput]!) {
    savedSynonyms: saveProductBrandSynonyms(projectId: $projectId, synonyms: $synonyms) {
      word
      synonyms
    }
  }
`;

export const SAVE_PRODUCT_CATEGORY = gql`
  mutation saveProductCategorySynonyms($projectId: ID!, $synonyms: [SynonymsInput]!) {
    savedSynonyms: saveProductCategorySynonyms(projectId: $projectId, synonyms: $synonyms) {
      word
      synonyms
    }
  }
`;

export const SAVE_PRODUCT_SKU = gql`
  mutation saveProductSKUSynonyms($projectId: ID!, $synonyms: [SynonymsInput]!) {
    savedSynonyms: saveProductSKUSynonyms(projectId: $projectId, synonyms: $synonyms) {
      word
      synonyms
      parentWord
    }
  }
`;

export const SAVE_PRODUCT_PROPERTY = gql`
  mutation saveProductPropertySynonyms($projectId: ID!, $synonyms: [SynonymsInput]!) {
    savedSynonyms: saveProductPropertySynonyms(projectId: $projectId, synonyms: $synonyms) {
      word
      synonyms
      parentWord
    }
  }
`;
