import { useMutation } from "@apollo/client";
import { UPDATE_LIVE_CRAWLER } from "graphql/facebookCrawler/mutation";
import i18n from "utils/i18n";
import { notifyError, notifySuccess } from "utils/notify";

export default (setIsSubmittingSaveDraft: Function, closeFrom: () => void) => {
  const [updateLiveCrawler, { loading: updateLiveCrawlerLoading }] = useMutation(UPDATE_LIVE_CRAWLER, {
    onError: () => {
      notifyError(i18n.t("facebookLive.toast.fail"));
      setIsSubmittingSaveDraft(false);
    },
    onCompleted: () => {
      notifySuccess(i18n.t("facebookLive.toast.complete"));
      closeFrom();
      setIsSubmittingSaveDraft(false);
    },
  });
  return { updateLiveCrawler, updateLiveCrawlerLoading };
};
