import React, { FC, useState } from "react";
import styled from "styled-components";

import Grid from "components/Grid";
import TextField from "components/TextField";

import { SvgIcon } from "components/Icon";
import { IcSearch } from "components/SvgIcons";
import { useTranslation } from "react-i18next";
import { fieldsEnum, resolveResultByField } from "./ThailandAddress/finder";

const MAXIMUM_DISPLAY_SUGGESTION_ITEMS = 20;

const SuggestionContainer = styled.div`
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.COLORS.LightBlue};
  background-color: ${({ theme }) => theme.COLORS.White};
  padding: 10px;
  margin: 10px 0px;
  color: ${({ theme }) => theme.COLORS.DarkGray};
  position: absolute;
  z-index: 1;
`;

type AutoCompleteAddressFormPropsType = {
  setAddressData: Function;
  type?: string;
  name?: string;
  defaultValue?: string;
  error?: boolean;
  validate?: () => void;
  isDefaultInput?: boolean;
  subDistrictFieldName?: string;
  districtFieldName?: string;
  provinceFieldName?: string;
  postalCodeFieldName?: string;
};

const AutoCompleteAddressForm: FC<AutoCompleteAddressFormPropsType> = (props) => {
  const {
    setAddressData,
    isDefaultInput = false,
    type,
    name,
    defaultValue,
    error,
    validate,
    subDistrictFieldName = "subDistrict",
    districtFieldName = "district",
    provinceFieldName = "province",
    postalCodeFieldName = "postalCode",
  } = props;
  const [searchAllResultData, setSearchAllResultData] = useState({});
  const [searchAll, setSearchAll] = useState("");
  const { t } = useTranslation();

  const searchAllAddress = (searchValue: string) => {
    if (searchValue) {
      const resultFromProvince = resolveResultByField(
        fieldsEnum.PROVINCE,
        searchValue,
        MAXIMUM_DISPLAY_SUGGESTION_ITEMS,
      );
      const resultFromAmphoe = resolveResultByField(fieldsEnum.AMPHOE, searchValue, MAXIMUM_DISPLAY_SUGGESTION_ITEMS);
      const resultFromDistrict = resolveResultByField(
        fieldsEnum.DISTRICT,
        searchValue,
        MAXIMUM_DISPLAY_SUGGESTION_ITEMS,
      );
      const resultFromZipcode = resolveResultByField(fieldsEnum.ZIPCODE, searchValue, MAXIMUM_DISPLAY_SUGGESTION_ITEMS);
      setSearchAllResultData({
        ...resultFromProvince,
        ...resultFromAmphoe,
        ...resultFromDistrict,
        ...resultFromZipcode,
      });
    } else {
      setSearchAllResultData({});
    }
  };

  const renderSuggestionAddressList = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addressResult: any,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parseSearchResultData: any = [];
    Object.keys(addressResult).forEach((key) => {
      parseSearchResultData.push(addressResult[key]);
    });
    const handleMouseDown = (addressSuggestion: { a: string; d: string; p: string; z: string }) => {
      setAddressData(subDistrictFieldName, addressSuggestion.d);
      setAddressData(districtFieldName, addressSuggestion.a);
      setAddressData(provinceFieldName, addressSuggestion.p);
      setAddressData(postalCodeFieldName, addressSuggestion.z);
      setSearchAll("");
      setSearchAllResultData({});
    };
    return (
      <>
        {Boolean(parseSearchResultData && parseSearchResultData.length) && (
          <SuggestionContainer>
            {parseSearchResultData.map((addressSuggest: { a: string; d: string; p: string; z: string }) => {
              return (
                <div
                  key={`SuggestionItem${addressSuggest.a}${addressSuggest.d}${addressSuggest.p}${addressSuggest.z}`}
                  className="p-2"
                  onMouseDown={() => {
                    handleMouseDown(addressSuggest);
                  }}
                >
                  {`${addressSuggest.d} » ${addressSuggest.a} » ${addressSuggest.p} » `}
                  {addressSuggest.z || <li>{t("autoSuggest.NoPostalCode")}</li>}
                </div>
              );
            })}
          </SuggestionContainer>
        )}
      </>
    );
  };

  return (
    <Grid item xs={12}>
      {isDefaultInput ? (
        <TextField
          defaultValue={defaultValue}
          error={error}
          type={type}
          fullWidth
          name={name}
          validate={validate}
          variant="outlined"
          rows={1}
          onChange={(e) => {
            searchAllAddress(e.target.value);
            setSearchAll(e.target.value);
          }}
        />
      ) : (
        <TextField
          margin="normal"
          InputProps={{
            startAdornment: <SvgIcon className="mx-2" component={IcSearch} fontSize="small" />,
          }}
          onChange={(e) => {
            searchAllAddress(e.target.value);
            setSearchAll(e.target.value);
          }}
          name="Search"
          value={searchAll}
          size="medium"
          placeholder={t("autoSuggest.SearchPlaceHolder")}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      )}
      {renderSuggestionAddressList(searchAllResultData)}
    </Grid>
  );
};

export default AutoCompleteAddressForm;
