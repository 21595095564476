import { useQuery } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
import React, { FC, useMemo, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { SortingRule } from "react-table";
import get from "lodash/get";

import { ORDER_REPORTS } from "graphql/salesReport/query";
import COLORS from "constants/Colors";
import { MOCK_ORDER_REPORT_DATA } from "constants/Report";

import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import ButtonToPlanUpgrade from "components/ButtonToPlanUpgrade";

import TableWrapper from "components/Table/TableWrapper";
import { ShowingResult, RowsSelectorPerPage } from "components/TablePagination";
import { Wrapper as PaginationWrapper } from "components/TablePagination/styled";
import Table from "components/Table";
import ReportFilter from "domain/Report/ReportFilter";
import {
  OrderByType,
  Data,
  OrderReportType,
  ProductSKUInOrderReportType,
  ExportFileType,
  ReportQueryVariableType,
} from "types/SalesReport";
import { OrderReportQueryType } from "types/Report";
import { ProjectIdType } from "types/Project";
import useIsDesktop from "utils/hooks/useIsDesktop";
import useGetProject from "utils/hooks/useGetProject";

import { useExportReports, useRenderExportedNumber, useDownloadPayslip } from "utils/hooks/SalesReport";
import filterSelectedIds from "utils/salesReport";

import TotalSummary from "../SalesReport/TotalSummary";
import { getColumns, tableHooks, getSubTableColumns } from "../SalesReport/customTable/config";
import {
  GridLoading,
  GridPagination,
  GridRowsPerPage,
  GridShowingResult,
  Hidden,
  Typography,
  Wrapper,
  DisabledReportWrapper,
  DisabledReportBox,
} from "../SalesReport/styled";
import { formattedOrderReports } from "../SalesReport/helper";
import { ROWS_PER_PAGE, ROWS_PER_PAGES, DEFAULT_PAGE_START } from "../SalesReport/config";

const OrderReport: FC = () => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const { projectId } = useParams<ProjectIdType>();
  const filterRef = useRef({});
  const subTableColumns = useMemo(() => getSubTableColumns<ProductSKUInOrderReportType>(isDesktop), [isDesktop]);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [page, setPage] = useState(DEFAULT_PAGE_START);
  const [selectedIds, setSelectedIds] = useState<Record<string, boolean>>({});
  const [excludeIds, setExcludeIds] = useState<Record<string, boolean>>({});
  const [isSelectedAll, setIsSelectedAll] = useState(false);

  const [filterValue, setFilterValue] = useState({});

  const exportReports = useExportReports();
  const { downloadPayslip, isLoading, downloadOrderId } = useDownloadPayslip();

  const tableColumns = useMemo(
    () =>
      getColumns<OrderReportType>(
        projectId,
        isDesktop,
        downloadPayslip,
        isLoading,
        downloadOrderId,
        (id) => {
          if (isSelectedAll) {
            const isExcludeId = Boolean(excludeIds[id]);

            setExcludeIds({ ...excludeIds, [`${id}`]: !isExcludeId });
          } else {
            const isSelectedId = Boolean(selectedIds[id]);
            setSelectedIds({ ...selectedIds, [`${id}`]: !isSelectedId });
          }
        },
        (ids, isSelectedSomeRow) => {
          if (!Array.isArray(ids)) {
            return;
          }

          const temp = ids.reduce((total, id) => {
            return { ...total, [`${id}`]: isSelectedAll ? !isSelectedSomeRow : isSelectedSomeRow };
          }, {});

          if (isSelectedAll) {
            setExcludeIds({ ...excludeIds, ...temp });
          } else {
            setSelectedIds({ ...selectedIds, ...temp });
          }
        },
      ),
    [excludeIds, downloadPayslip, isDesktop, isLoading, downloadOrderId, isSelectedAll, projectId, selectedIds],
  );

  const { isSalesReportEnabled, isAdvancedSalesReportEnabled, loading: projectInfoLoading } = useGetProject(projectId);

  const { data: orderReportsData, loading: orderReportsLoading, refetch: orderReportsRefetch } = useQuery<
    OrderReportQueryType,
    ReportQueryVariableType
  >(ORDER_REPORTS, {
    variables: {
      projectId,
      pageSize: rowsPerPage,
      page: page - 1,
      filter: { ...filterRef.current, ...filterValue },
    }, // filter Object
    skip: !projectId || isEmpty(filterValue) || !isSalesReportEnabled,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const totalOrdersLength: number = get(orderReportsData, "orderReports.total") || 0;
  const subTotal: number = get(orderReportsData, "orderReports.summary.subTotal") || 0;
  const shippingCost: number = get(orderReportsData, "orderReports.summary.shippingCost") || 0;
  const shippingDiscount: number = get(orderReportsData, "orderReports.summary.shippingDiscount") || 0;
  const productDiscount: number = get(orderReportsData, "orderReports.summary.productDiscount") || 0;
  const totalPrice: number = get(orderReportsData, "orderReports.summary.grandTotal") || 0;
  const total: number = get(orderReportsData, "orderReports.total") || 0;
  const additionalDiscount: number = get(orderReportsData, "orderReports.summary.additionalDiscount") || 0;

  const handlePageClick = (data: { selected: number }) => {
    const { selected } = data;
    // +1 because of ReactPaginate page start from 0 but my page start from 1
    setPage(selected + 1);
  };

  const newHandleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(DEFAULT_PAGE_START);
  };

  const handleChangeSortBy = (sortBys: SortingRule<{ id: string; desc: boolean }>[]) => {
    const sortBy = sortBys[0];
    if (sortBy && isSalesReportEnabled) {
      filterRef.current = { sortBy: sortBy.id, orderBy: sortBy.desc ? OrderByType.DESC : OrderByType.ASC };
      orderReportsRefetch({
        projectId,
        pageSize: rowsPerPage,
        page: page - 1,
        filter: { ...filterRef.current, ...filterValue },
      });
    }
  };

  const handleExportReports = (exportFileType: ExportFileType) => {
    if (!projectId || !isSalesReportEnabled) {
      return;
    }

    const filteredSelectedOrderIds = filterSelectedIds(selectedIds);
    const filteredExcludeOrderIds = filterSelectedIds(excludeIds);

    exportReports(
      projectId,
      isSelectedAll,
      filteredSelectedOrderIds,
      filteredExcludeOrderIds,
      {
        ...filterRef.current,
        ...filterValue,
      },
      exportFileType,
    );
  };

  const handleClickSelectedAll = () => {
    setIsSelectedAll(!isSelectedAll);

    if (!isSelectedAll) {
      setSelectedIds({});
    } else {
      setExcludeIds({});
    }
  };

  let orderReports = get(orderReportsData, "orderReports.results") || [];

  const isReportDisabled = !orderReportsLoading && !projectInfoLoading && !isSalesReportEnabled;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  orderReports = useMemo(
    () => (isReportDisabled ? MOCK_ORDER_REPORT_DATA : formattedOrderReports(orderReports, projectId)),
    [isReportDisabled, orderReports, projectId],
  );

  const exportedNumber = useRenderExportedNumber({ isSelectedAll, excludeIds, selectedIds, total });

  return (
    <Wrapper>
      <Grid container alignItems="center" className="mb-2">
        {/* Filter data and state */}

        <ReportFilter
          isDisabledExportButton={!total}
          isDisabledAdvanceButtonAndShowChangePackage={!isAdvancedSalesReportEnabled}
          setFilterValue={setFilterValue}
          handleClickSelectedAll={handleClickSelectedAll}
          handleExportReports={handleExportReports}
          exportedNumber={exportedNumber}
          isSelectedAll={isSelectedAll}
          projectId={projectId}
        />
      </Grid>

      <TableWrapper isBlurContent={isReportDisabled}>
        {
          // render when orderReports are loading
          (orderReportsLoading || projectInfoLoading) && (
            <GridLoading>
              <CircularProgress className="m-4" />
            </GridLoading>
          )
        }

        {isReportDisabled && (
          <DisabledReportWrapper>
            <DisabledReportBox>
              <Typography variant="title2" color={COLORS.Dark} className="text-center mb-3">
                {t("salesReport.orderReports.disabled")}
              </Typography>
              {projectId && <ButtonToPlanUpgrade label={t("DisableFeature.button")} projectId={projectId} />}
            </DisabledReportBox>
          </DisabledReportWrapper>
        )}

        {/* We have to render table and hide table when there are not reports
            to prevent table reset all filter and sortBy state in the table. */}
        <Hidden className="ml-auto mr-auto" isHidden={orderReports.length === 0}>
          <Table<Data, Record<string, Data>>
            data={orderReports}
            columns={tableColumns}
            subTableColumns={subTableColumns}
            manualSortBy
            hooks={tableHooks}
            onChangeSortBy={handleChangeSortBy}
            customState={{ selectedIds, excludeIds, isSelectedAll }}
            isBlurContent={isReportDisabled}
          />
        </Hidden>

        {
          // render when no orderReports
          !orderReportsLoading && !projectInfoLoading && orderReports.length === 0 && isSalesReportEnabled && (
            <Grid container justify="center" className="mt-4">
              <Grid item xs={12}>
                <Typography variant="title2" color={COLORS.DarkLight} className="text-center">
                  {
                    // No sales reports
                    t("salesReport.orderReports.placeholder")
                  }
                </Typography>
              </Grid>
            </Grid>
          )
        }
      </TableWrapper>
      <div className="mt-3">
        <TotalSummary
          additionalDiscount={additionalDiscount}
          summary={totalOrdersLength}
          subTotal={subTotal + productDiscount}
          shipping={shippingCost}
          discount={productDiscount}
          shippingDiscount={shippingDiscount}
          total={totalPrice}
          isBlurContent={isReportDisabled}
        />
      </div>

      {!isReportDisabled && (
        <Grid container>
          <Grid item className="px-2" container xs={12} alignItems="center" justify="center">
            <GridShowingResult item xs={6} md="auto" lg="auto">
              <ShowingResult page={page} rowsPerPage={rowsPerPage} total={total} />
            </GridShowingResult>
            <GridPagination item xs={12} md="auto" lg="auto" container>
              <PaginationWrapper>
                <ReactPaginate
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  // -1 because of ReactPaginate page start from 0 but my page start from 1
                  forcePage={page - 1}
                  pageCount={Math.ceil(total / rowsPerPage) || 0}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageClick}
                  activeClassName="active"
                  containerClassName="d-flex flex-wrap p-0"
                  pageClassName="page-no"
                  breakClassName="break"
                  previousClassName="page-no previous"
                  nextClassName="page-no next"
                />
              </PaginationWrapper>
            </GridPagination>

            <GridRowsPerPage item xs={6} md="auto" lg="auto" container alignItems="center" justify="flex-end">
              <Grid item>
                <Typography className="d-inline-block mr-2" color="darkGray">
                  {t("salesReport.rowsSelector.show")}
                </Typography>
              </Grid>
              <Grid item>
                <RowsSelectorPerPage
                  options={ROWS_PER_PAGES}
                  onChange={newHandleChangeRowsPerPage}
                  rowsPerPage={rowsPerPage}
                />
              </Grid>
              <Grid item>
                <Typography className="d-inline-block ml-2" color="darkGray">
                  {t("salesReport.rowsSelector.perPage")}
                </Typography>
              </Grid>
            </GridRowsPerPage>
          </Grid>
        </Grid>
      )}
    </Wrapper>
  );
};

export default OrderReport;
