import React from "react";
import moment from "moment";
import get from "lodash/get";
import { COLOR_BY_STATUS, BROADCAST_STATUS, CUSTOMER_RETENTION_STATUS } from "constants/Broadcast";
import { BROADCAST_DATE_TIME_FORMAT } from "constants/DateTimeFormat";
import { convertPriceFormat } from "utils/common";
import i18n from "utils/i18n";
import { CellProps, useSortBy, useBlockLayout } from "react-table";
import { useSticky } from "react-table-sticky";
import takeRight from "lodash/takeRight";
import { PROVINCE_TH } from "constants/Province";
import { Bullet, Title, Status } from "./styled";
import { DEFAULT_VALUE } from "../config";

export const tableHooks = [useSortBy, useBlockLayout, useSticky];

export const getColumns = <T extends object>(
  isDesktop?: boolean,
  locale?: string,
  onClickTitle?: (id: string) => void,
) => {
  return [
    {
      disableSortBy: true,
      Header: i18n.t("broadcast.table.title"),
      accessor: "name",
      sticky: isDesktop ? "left" : null,
      width: 150,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const id = get(cell, "row.original.id");
        return (
          <Title
            onClick={() => {
              if (onClickTitle) {
                onClickTitle(id);
              }
            }}
          >
            {cell.value}
          </Title>
        );
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("Status"),
      accessor: "status",
      sticky: isDesktop ? "left" : null,

      width: 100,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <>
            <Bullet color={COLOR_BY_STATUS[cell.value]} />
            <Status>{i18n.t(BROADCAST_STATUS[cell.value])}</Status>
          </>
        );
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("broadcast.table.targetAudience"),
      accessor: "broadcastCriteria[0].systemCriteria",
      width: 300,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const mappedSystemTags = Array.isArray(cell.value)
          ? cell.value.map((systemTag) => CUSTOMER_RETENTION_STATUS[systemTag])
          : [];
        // all system tags is apply then display text instead
        if (Number(mappedSystemTags.length) === Number(Object.keys(CUSTOMER_RETENTION_STATUS).length)) {
          return i18n.t("broadcast.targetAudience.all");
        }

        return mappedSystemTags.length ? mappedSystemTags.join(", ") : i18n.t("broadcast.targetAudience.all");
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("broadcast.location"),
      accessor: "broadcastCriteria[0].locationCriteria",
      width: 180,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const moreTags = Array.isArray(cell.value) && cell.value.length > 4 && `+${cell.value.length - 4}`;
        const moreTagsValue = takeRight((Array.isArray(cell.value) && cell.value) || [], 4).join(", ");
        // all location is apply then display text instead

        if (Number(Array.isArray(cell.value) && cell.value.length) === Number(PROVINCE_TH.length)) {
          return i18n.t("broadcast.location.all");
        }

        return Array.isArray(cell.value) && cell.value.length
          ? `${moreTagsValue} ${moreTags || ""}`
          : i18n.t("broadcast.location.all");
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("customerTag"),
      accessor: "broadcastCriteria[0].customCriteria",
      width: 300,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const moreTags = Array.isArray(cell.value) && cell.value.length > 4 && `+${cell.value.length - 4}`;
        const moreTagsValue = takeRight((Array.isArray(cell.value) && cell.value) || [], 4).join(", ");
        return Array.isArray(cell.value) && cell.value.length
          ? `${moreTagsValue} ${moreTags || ""}`
          : i18n.t("broadcast.customerTags.all");
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("Engagement"),
      accessor: "numberOfEngagement",
      width: 100,
      sticky: isDesktop ? "right" : null,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const numberOfAudience = get(cell, "row.original.numberOfAudience") || 0;
        const percentOfEngagement = numberOfAudience ? ((cell.value / numberOfAudience) * 100).toFixed(2) : "0.00";
        return cell.value != null ? `${percentOfEngagement}%` : DEFAULT_VALUE;
      },
    },

    {
      disableSortBy: true,
      Header: i18n.t("broadcast.conversionRate"),
      accessor: "newOrderConversionRate",
      width: 110,
      sticky: isDesktop ? "right" : null,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const numberOfAudience = get(cell, "row.original.numberOfAudience") || 0;
        const newOrderConversionRate = numberOfAudience ? ((cell.value / numberOfAudience) * 100).toFixed(2) : "0.00";
        return cell.value != null ? `${newOrderConversionRate}%` : DEFAULT_VALUE;
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("broadcast.orderPaid"),
      accessor: "orderPaidConversionRate",
      width: 100,
      sticky: isDesktop ? "right" : null,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const numberOfAudience = get(cell, "row.original.numberOfAudience") || 0;
        const orderPaidConversionRate = numberOfAudience ? ((cell.value / numberOfAudience) * 100).toFixed(2) : "0.00";
        return cell.value != null ? `${orderPaidConversionRate}%` : DEFAULT_VALUE;
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("broadcast.orderPaidConversionTotal"),
      accessor: "orderPaidConversionTotal",
      width: 100,
      sticky: isDesktop ? "right" : null,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const orderPaidConversionTotal = get(cell, "row.original.orderPaidConversionTotal") || 0;
        return cell.value != null ? convertPriceFormat(orderPaidConversionTotal) : DEFAULT_VALUE;
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("Audience"),
      accessor: "numberOfAudience",
      width: 85,
      sticky: isDesktop ? "right" : null,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return cell.value != null ? `${convertPriceFormat(cell.value, 0)}` : DEFAULT_VALUE;
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("broadcast.broadcastTime"),
      accessor: "broadcastDate",
      width: 135,
      sticky: isDesktop ? "right" : null,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return cell.value != null
          ? moment(cell.value)
              .add(locale === "en" ? 0 : 543, "year")
              .format(BROADCAST_DATE_TIME_FORMAT)
          : DEFAULT_VALUE;
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("Created at"),
      accessor: "createdAt",
      width: 135,
      sticky: isDesktop ? "right" : null,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return cell.value != null
          ? moment(cell.value)
              .add(locale === "en" ? 0 : 543, "year")
              .format(BROADCAST_DATE_TIME_FORMAT)
          : DEFAULT_VALUE;
      },
    },
  ];
};
