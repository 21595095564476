import * as React from "react";

const IcArrowLeft = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="21" height="14" viewBox="0 0 21 14" fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7682 5.77474H4.20076L7.97558 2.05814C8.45558 1.58554 8.45558 0.827047 7.97558 0.354449C7.49558 -0.11815 6.72521 -0.11815 6.24521 0.354449L0.360745 6.14816C0.360745 6.14816 0.360745 6.14816 0.354819 6.15399C-0.119256 6.62659 -0.119256 7.38508 0.360745 7.85184L6.24521 13.6456C6.72521 14.1181 7.49558 14.1181 7.97558 13.6456C8.45558 13.173 8.45558 12.4145 7.97558 11.9419L4.1652 8.19025H19.7682C20.4438 8.19025 20.9949 7.64764 20.9949 6.9825C20.9949 6.31736 20.4438 5.77474 19.7682 5.77474Z"
    />
  </svg>
);

export default IcArrowLeft;
