export const jwdExpressDescription = {
  EN: null,
  TH: [
    { body: "พิมพ์ใบปะหน้า และแปะลงบนกล่องพัสดุ" },
    { body: "เข้ารับสินค้าพัสดุถึงหน้าบ้าน (ขั้นต่ำ 5 ชิ้นขึ้นไป)" },
    {
      body:
        "เข้ารับสินค้า ในพื้นที่ กทม.และปริมณฑล (นนทบุรี, ปทุมธานี และสมุทรปราการ) ซึ่งจะเข้ารับและจัดส่งทุกวัน และมีระยะเวลาการจัดส่งประมาณ 1 - 2 วันทำการ",
    },
    {
      body:
        "หากลูกค้าสร้างรายการไม่เกิน 12.00 น. ทางขนส่งจะเข้ารับภายในวันนั้น หากลูกค้าสร้างรายการหลังจาก 12.00 น. ทางขนส่งจะทำการเข้ารับในวันถัดไป",
    },
    {
      body:
        "สําหรับการจัดส่งไปยังพื้นที่กรุงเทพฯ และปริมณฑล ขนาดของพัสดุรวม (กว้าง+ยาว+สูง) ต้องไม่เกิน 180 เซนติเมตร ด้านใดด้านหนึ่งไม่เกิน 100 เซนติเมตร และนํ้ำหนักไม่เกิน 25 กิโลกรัม",
    },
    {
      body:
        "สําหรับการจัดส่งไปยังพื้นที่ต่างจังหวัด ขนาดรวม (กว้าง+ยาว+สูง) ของพัสดุไม่เกิน 120 เซนติเมตร ด้านใดด้านหนึ่งไม่เกิน 30 เซนติเมตร และน้ำหนักไม่เกิน 25 กิโลกรัม",
    },
    { body: "หากสินค้ามีการตีคืนจะมีการเรียกเก็บเงินภายหลัง" },
  ],
};
