import * as React from "react";

const SvgIcShipping = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26.695 15.546h-5.133c-.212 0-.24-.207-.24-.207v-4.276s-.013-.163.26-.163h1.464c.884 0 1.938.64 2.343 1.424l1.356 2.745c.058.113.12.21.183.298.048.066-.032.179-.233.179M7.22 20.712a3.165 3.165 0 013.167 3.167 3.166 3.166 0 11-3.166-3.166zm16.7 0a3.165 3.165 0 013.166 3.167 3.166 3.166 0 11-3.166-3.166zm-16.7 1.79a1.376 1.376 0 10.003 2.753 1.376 1.376 0 00-.003-2.753zm16.7 0a1.376 1.376 0 10.001 2.753 1.376 1.376 0 00-.002-2.753zM0 6.782c0-1.005.467-1.827 1.825-1.827h17.721c1.208 0 1.775.822 1.775 1.826V9.37c0 .282.282.275.282.275h2.084c.992 0 2.176.722 2.633 1.601l1.525 3.087c.455.88 1.119 1.072 2.28 1.373.963.25 1.875.25 1.875 3.136v3.426c0 .991-.81 1.801-1.804 1.801h-2.05c-.101 0-.1-.126-.1-.189a4.132 4.132 0 00-4.125-4.128 4.131 4.131 0 00-4.127 4.128c0 .054.006.19-.155.19h-8.134c-.175 0-.157-.127-.157-.19a4.131 4.131 0 00-4.127-4.128 4.132 4.132 0 00-4.126 4.128c0 .063-.034.19-.262.19H1.825A1.83 1.83 0 010 22.245z"
    />
  </svg>
);

export default SvgIcShipping;
