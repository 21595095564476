import React from "react";
import { SvgIcon } from "components/Icon";
import { IcArrowDown } from "components/SvgIcons";
import styled from "styled-components";

const StyledSvgIcon = styled(SvgIcon)`
  vertical-align: "middle";
  z-index: 0;
  right: 0;
  position: absolute;
`;

const ArrowDownIcon = () => <StyledSvgIcon component={IcArrowDown} fontSize="small" />;

export default ArrowDownIcon;
