import { useMutation } from "@apollo/client";
import { DELETE_FAQ } from "graphql/ai-setting/mutation";
import { FAQS } from "graphql/ai-setting/query";
import { FaqQueryType, FaqType } from "types/AISetting";

const useRemoveFaq = (projectId: string) => {
  const [removeFaq] = useMutation(DELETE_FAQ, {
    update(cache, { data: { deleteFaq } }) {
      const cacheFaq = cache.readQuery<FaqQueryType>({
        query: FAQS,
        variables: {
          projectId,
        },
      });

      if (cacheFaq) {
        const updatedCacheFaq = cacheFaq.faqs.filter((faq: FaqType) => faq.id !== deleteFaq.id);

        cache.writeQuery({
          query: FAQS,
          variables: {
            projectId,
          },
          data: {
            faqs: updatedCacheFaq,
          },
        });
      }
    },
  });

  const handleRemoveFAQ = (faqId: string) => {
    return removeFaq({ variables: { projectId, faqId } });
  };

  return handleRemoveFAQ;
};

export default useRemoveFaq;
