import { gql } from "@apollo/client";

export const MAIN_CREDIT = gql`
  query credit($projectId: ID!) {
    credit(projectId: $projectId, type: MAIN)
  }
`;

export const HAS_CHANGES_ON_NEXT_CYCLE = gql`
  query hasChangesOnNextCycle($projectId: ID!) {
    hasChangesOnNextCycle(projectId: $projectId) {
      hasPackageChangesOnNextCycle
      hasAddonChangesOnNextCycle
    }
  }
`;

export const CHANGE_PACKAGE_SUMMARY = gql`
  query changePackageSummary($projectId: ID!, $packageIds: [ID!]!) {
    changePackageSummary(projectId: $projectId, packageIds: $packageIds) {
      currentPackages {
        subscriptionType
      }
      totalDiscount
      newPackages {
        subscriptionType
      }
      creditSummary {
        totalCredit
      }
      monthlyBillingSummary {
        newPackage {
          package
          addon
          imageSearchPackage
        }
      }
      nextBillingDate
      totalPrice
      isDuringFreeTrialPeriod
      invoiceDateInfo {
        startBillingDate
        endBillingDate
      }
      updateDateInfo {
        currentMonthlyBilling
        nextMonthlyBilling
        currentYearlyBilling
      }
    }
  }
`;

export const PACKAGES = gql`
  query packages($projectId: ID!, $subscriptionType: SubscriptionType) {
    packages(projectId: $projectId, subscriptionType: $subscriptionType) {
      starter_1 {
        id
        name
        type
        entityType
        entityLevel
        isTrial
        isSelected
        pricePerMonth
        subscriberQuota
        messageQuota
        orderQuota
        faqQuota
        productQuota
        memberQuota
        channelQuota
        imageSearchQuota
        isAddonEnabled
        extraChargeRate
        broadcastQuota
        pricePerYear
        subscriptionType
        deeplePayFeeRate {
          qrCash {
            fix
            min
            rate
          }
          creditCard {
            fix
            min
            rate
          }
          foreignCreditCard {
            fix
            min
            rate
          }
          mobileBanking {
            fix
            min
            rate
          }
          rabbitLinePay {
            fix
            min
            rate
          }
          trueMoneyWallet {
            fix
            min
            rate
          }
          withdrawal {
            fix
            min
            rate
          }
        }
      }
      starter_2 {
        id
        name
        type
        entityType
        entityLevel
        isTrial
        isSelected
        pricePerMonth
        subscriberQuota
        messageQuota
        orderQuota
        faqQuota
        productQuota
        memberQuota
        channelQuota
        imageSearchQuota
        isAddonEnabled
        extraChargeRate
        broadcastQuota
        pricePerYear
        subscriptionType
        deeplePayFeeRate {
          qrCash {
            fix
            min
            rate
          }
          creditCard {
            fix
            min
            rate
          }
          foreignCreditCard {
            fix
            min
            rate
          }
          mobileBanking {
            fix
            min
            rate
          }
          rabbitLinePay {
            fix
            min
            rate
          }
          trueMoneyWallet {
            fix
            min
            rate
          }
          withdrawal {
            fix
            min
            rate
          }
        }
      }
      starter_3 {
        id
        name
        type
        entityType
        entityLevel
        isTrial
        isSelected
        pricePerMonth
        subscriberQuota
        messageQuota
        orderQuota
        faqQuota
        productQuota
        memberQuota
        channelQuota
        imageSearchQuota
        isAddonEnabled
        extraChargeRate
        broadcastQuota
        pricePerYear
        subscriptionType
        deeplePayFeeRate {
          qrCash {
            fix
            min
            rate
          }
          creditCard {
            fix
            min
            rate
          }
          foreignCreditCard {
            fix
            min
            rate
          }
          mobileBanking {
            fix
            min
            rate
          }
          rabbitLinePay {
            fix
            min
            rate
          }
          trueMoneyWallet {
            fix
            min
            rate
          }
          withdrawal {
            fix
            min
            rate
          }
        }
      }
      free {
        id
        name
        type
        entityType
        entityLevel
        isTrial
        isSelected
        pricePerMonth
        subscriberQuota
        messageQuota
        orderQuota
        faqQuota
        productQuota
        memberQuota
        channelQuota
        imageSearchQuota
        isAddonEnabled
        extraChargeRate
        broadcastQuota
        pricePerYear
        subscriptionType
        deeplePayFeeRate {
          qrCash {
            fix
            min
            rate
          }
          creditCard {
            fix
            min
            rate
          }
          foreignCreditCard {
            fix
            min
            rate
          }
          mobileBanking {
            fix
            min
            rate
          }
          rabbitLinePay {
            fix
            min
            rate
          }
          trueMoneyWallet {
            fix
            min
            rate
          }
          withdrawal {
            fix
            min
            rate
          }
        }
      }
      standard {
        id
        name
        type
        entityType
        entityLevel
        isTrial
        isSelected
        pricePerMonth
        subscriberQuota
        messageQuota
        orderQuota
        faqQuota
        productQuota
        memberQuota
        channelQuota
        imageSearchQuota
        isAddonEnabled
        extraChargeRate
        broadcastQuota
        pricePerYear
        subscriptionType
        deeplePayFeeRate {
          qrCash {
            fix
            min
            rate
          }
          creditCard {
            fix
            min
            rate
          }
          foreignCreditCard {
            fix
            min
            rate
          }
          mobileBanking {
            fix
            min
            rate
          }
          rabbitLinePay {
            fix
            min
            rate
          }
          trueMoneyWallet {
            fix
            min
            rate
          }
          withdrawal {
            fix
            min
            rate
          }
        }
      }
      premium {
        id
        name
        type
        entityType
        entityLevel
        isTrial
        isSelected
        pricePerMonth
        subscriberQuota
        messageQuota
        orderQuota
        faqQuota
        productQuota
        memberQuota
        channelQuota
        imageSearchQuota
        isAddonEnabled
        extraChargeRate
        broadcastQuota
        pricePerYear
        subscriptionType
        deeplePayFeeRate {
          qrCash {
            fix
            min
            rate
          }
          creditCard {
            fix
            min
            rate
          }
          foreignCreditCard {
            fix
            min
            rate
          }
          mobileBanking {
            fix
            min
            rate
          }
          rabbitLinePay {
            fix
            min
            rate
          }
          trueMoneyWallet {
            fix
            min
            rate
          }
          withdrawal {
            fix
            min
            rate
          }
        }
      }
      professional {
        id
        name
        type
        entityType
        entityLevel
        isTrial
        isSelected
        pricePerMonth
        subscriberQuota
        messageQuota
        orderQuota
        faqQuota
        productQuota
        memberQuota
        channelQuota
        imageSearchQuota
        isAddonEnabled
        extraChargeRate
        broadcastQuota
        pricePerYear
        subscriptionType
        deeplePayFeeRate {
          qrCash {
            fix
            min
            rate
          }
          creditCard {
            fix
            min
            rate
          }
          foreignCreditCard {
            fix
            min
            rate
          }
          mobileBanking {
            fix
            min
            rate
          }
          rabbitLinePay {
            fix
            min
            rate
          }
          trueMoneyWallet {
            fix
            min
            rate
          }
          withdrawal {
            fix
            min
            rate
          }
        }
      }
    }
  }
`;

export const CURRENT_PACKAGES = gql`
  query currentPackages($projectId: ID!) {
    currentPackages(projectId: $projectId) {
      id
      type
      entityType
      entityLevel
      pricePerMonth
      memberQuota
      channelQuota
    }
  }
`;

export const CURRENT_USAGE = gql`
  query currentUsage($projectId: ID!) {
    currentUsage(projectId: $projectId) {
      projectId
      usagePeriod
      currentBillingDate
      nextBillingDate
      lineMessageUsage
      lineMessageQuota
      lineLastFetchMessageTimestamp
      manualApproveOrderUsage
      # billingDate
      ccommercePackage {
        id
        name
        type
        entityType
        entityLevel
        pricePerMonth
        orderQuota
        orderUsage
        productQuota
        productUsage
        subscriptionType
      }
      imageSearchPackage {
        id
        name
        type
        entityType
        isTrial
        pricePerMonth
        imageSearchQuota
        imageSearchUsage
      }
      mainPackage {
        id
        name
        type
        entityType
        entityLevel
        isAddonEnabled
        pricePerMonth
        subscriberQuota
        subscriberUsage
        messageQuota
        messageUsage
        orderQuota
        orderUsage
        productQuota
        productUsage
        faqQuota
        faqUsage
        broadcastQuota
        broadcastUsage
        memberQuota
        memberUsage
        members {
          id
          email
          user {
            id
            email
            displayName
          }
        }
        channelQuota
        channelUsage
        channels {
          platform
          name
        }
        subscriptionType
      }
      addons {
        id
        name
        type
        entityType
        pricePerMonth
        memberQuota
      }
    }
  }
`;
