import styled from "styled-components";
import ButtonBase from "components/ButtonBase";

export const CloseIconWrapper = styled.div`
  display: flex;
  width: 32px;
  height: 100%;
  font-size: 14px;
  border-radius: 8px 0px 0px 8px;
  background-color: ${({ theme }) => `${theme.COLORS.Light}`};
  border-right: solid 1px ${({ theme }) => `${theme.COLORS.LightBlue}`};
  align-items: center;
  align-content: center;
  justify-content: center;
`;

export const FilterCardWrapper = styled.div`
  display: inline-flex;
  min-height: 46px;
  height: auto;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => `${theme.COLORS.LightBlue}`};
  background-color: ${({ theme }) => `${theme.COLORS.White}`};
  color: ${({ theme }) => `${theme.COLORS.DarkGray}`};
`;

export const ButtonAddFilter = styled(ButtonBase)`
  display: flex;
  min-height: 46px;
  color: ${({ theme }) => `${theme.COLORS.Primary}`};
`;
