import React from "react";
import styled from "styled-components";

import Grid from "components/Grid";
import { ThemeType } from "components/Theme/type";

type RecommendCapsulePropsType = {
  isRecommend: boolean;
};

type IconWrapperPropsType = {
  color: "gray" | "secondary" | "primary";
  theme: ThemeType;
};

type TypographyPropsType = {
  color: "primary" | "secondary" | "disabled";
  theme: ThemeType;
  customFont: "Graphik" | "Graphik Medium";
  isDisabled: boolean;
  size?: "small";
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Typography = styled(({ customColor, customFont, isDisabled, size, ...rest }) => <span {...rest} />).attrs(
  (props: TypographyPropsType) => {
    const { theme, color, customFont, isDisabled } = props;

    let customColor;
    switch (color) {
      case "primary":
        customColor = theme.COLORS.Accent;
        break;
      case "secondary":
        customColor = theme.COLORS.DarkGray;
        break;
      case "disabled":
        customColor = theme.COLORS.LightBlue;
        break;
      default:
        customColor = theme.COLORS.DarkGray;
    }

    return { customColor, customFont, isDisabled };
  },
)`
  color: ${({ customColor }) => customColor};
  font-family: ${({ customFont }) => customFont};
  font-size: ${({ size }) => (size === "small" ? "0.7143rem" : "0.8571rem")};
  line-height: 18px;
  text-decoration: ${({ isDisabled }) => (isDisabled ? "line-through" : "none")};
`;

export const RecommendCapsule = styled.div<RecommendCapsulePropsType>`
  background-color: ${({ theme }) => theme.COLORS.Light};
  padding: 0px 16px;
  border-radius: 8px;
  height: 20px;
  display: flex;
  align-items: center;
  visibility: ${({ isRecommend }) => (isRecommend ? "visible" : "hidden")};
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const IconWrapper = styled(({ bgColor, ...rest }) => <div {...rest} />).attrs((props: IconWrapperPropsType) => {
  const { theme, color } = props;

  let bgColor;
  switch (color) {
    case "primary":
      bgColor = theme.COLORS.Primary;
      break;
    case "secondary":
      bgColor = theme.COLORS.Info;
      break;
    case "gray":
      bgColor = theme.COLORS.LightBlue;
      break;
    default:
      bgColor = theme.COLORS.Primary;
  }

  return { bgColor };
})`
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background-color: ${({ bgColor }) => bgColor};
`;

export const TooltipIconWrapper = styled.div`
  height: 18px;
`;

export const CardContentContainer = styled(Grid)`
  min-height: 290px;
`;

export const PriceDetailContainer = styled(Grid)`
  height: 50px;
`;

export const PackageSummaryModalSubTitle = styled.div`
  width: 350px;
  margin: auto;
  text-align: center;
`;
