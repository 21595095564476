import React, { FC } from "react";
import COLORS from "constants/Colors";
import Grid from "components/Grid";
import Typography from "components/Typography";
import {
  AmountWrapper,
  FlexMessageContent,
  SingleButtonPrimary,
  SingleButtonSecondary,
  ProductSKUImage,
} from "./FlexMessage.style";

type CartItem = {
  amount: string | number;
  discountPrice: string | null;
  image: string;
  isBundled: boolean;
  note?: string;
  options: string | null;
  price: string;
  productName: string;
  productType: string;
  promotionName: string;
};

type FlexCartSummaryContentDataType = {
  cartItems: Array<CartItem>;
  cartSummaryTitle: string;
  Discount: string;
  hasOnlyDigitalContentProducts: boolean;
  hasMoreThanMaximumProductSKU: boolean;
  promotionsBundledDescription: string;
  SubTotal: string;
  Total: string;
};

type FlexCartSummaryPropsType = {
  content: FlexCartSummaryContentDataType;
};

const FlexCartSummary: FC<FlexCartSummaryPropsType> = (props) => {
  const { content } = props;
  const {
    cartSummaryTitle,
    cartItems,
    Total,
    promotionsBundledDescription,
    hasOnlyDigitalContentProducts,
    hasMoreThanMaximumProductSKU,
  } = content;

  return (
    <FlexMessageContent>
      <Grid item xs={12} className="p-3">
        <Typography className="pb-2 text-left" variant="title8" color={COLORS.DarkLight}>
          {cartSummaryTitle}
        </Typography>

        {cartItems.map((cartItem: CartItem) => {
          return (
            <Grid
              key={cartItem.productName}
              container
              justify="flex-start"
              alignItems="flex-start"
              alignContent="flex-start"
              className="d-flex pb-2"
            >
              <Grid item>
                <ProductSKUImage src={cartItem.image} className="pr-3" />
              </Grid>
              <Grid item className="hiddenOverFlowText" style={{ flex: 1 }}>
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  alignContent="flex-start"
                  className="hiddenOverFlowText"
                >
                  <Typography
                    variant="title8"
                    color={COLORS.Dark}
                    className="ellipsis text-left py-1 hiddenOverFlowText"
                  >
                    {cartItem.productName}
                  </Typography>

                  <Grid item container justify="space-between" alignItems="flex-start" alignContent="flex-start">
                    <Grid item>
                      <Grid item container justify="space-between" alignItems="flex-start" alignContent="flex-start">
                        <AmountWrapper className="text-center">{`${cartItem.amount}x`}</AmountWrapper>
                        <Grid item className="pl-2" style={{ flex: 1 }}>
                          {cartItem.productType && (
                            <Typography
                              variant="body3"
                              color={COLORS.Dark}
                              className="text-left py-1 hiddenOverFlowText"
                            >
                              {cartItem.productType}
                            </Typography>
                          )}

                          {cartItem.options && (
                            <Typography
                              variant="body3"
                              color={COLORS.Dark}
                              className="text-left py-1 hiddenOverFlowText"
                            >
                              {cartItem.options}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant="title8" color={COLORS.Primary} className="text-right">
                        {cartItem.discountPrice && !cartItem.isBundled ? (
                          <>
                            <span style={{ color: COLORS.DarkLight, textDecoration: "line-through" }}>
                              {cartItem.price}
                            </span>
                            <br />
                            <span style={{ color: COLORS.Error }}>{cartItem.discountPrice}</span>
                          </>
                        ) : (
                          cartItem.price
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {cartItem.note && (
                  <Typography variant="body3" color={COLORS.Dark} className="text-left py-1 hiddenOverFlowText">
                    {cartItem.note}
                  </Typography>
                )}
                {cartItem.promotionName && !cartItem.isBundled && (
                  <Typography variant="body3" color={COLORS.Error} className="text-left hiddenOverFlowText">
                    {cartItem.promotionName}*
                  </Typography>
                )}
              </Grid>
            </Grid>
          );
        })}

        {Boolean(hasMoreThanMaximumProductSKU) && (
          <Typography className="py-2 text-center" variant="body3" color="dark">
            ดูรายการสินค้าเพิ่มเติม
          </Typography>
        )}

        <Grid container justify="space-between">
          <Grid item>
            <Typography className="pt-2" variant="body3" color="dark">
              ราคารวม{hasOnlyDigitalContentProducts ? "" : "(ไม่รวมค่าขนส่ง)"}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="title8">{Total}</Typography>
          </Grid>
        </Grid>

        {Boolean(promotionsBundledDescription) && (
          <Grid container justify="space-between" className="mt-2">
            <Typography variant="body3" color={COLORS.Error}>
              {/* TODO : Add translate */}
              โปรโมชั่น - {promotionsBundledDescription}
            </Typography>
          </Grid>
        )}
      </Grid>
      <SingleButtonSecondary>[แก้ไขรายการสั่งซื้อ]</SingleButtonSecondary>
      <SingleButtonPrimary>[ยืนยันการสั่งซื้อ]</SingleButtonPrimary>
    </FlexMessageContent>
  );
};

export default FlexCartSummary;
