import { ProductOptionType } from "types/Product";
// for product food
export const setRequiredAndMaximum = (options: ProductOptionType[]) => {
  return options.map((option) => {
    if (!option.isMultipleChoices) {
      return {
        ...option,
        isRequired: true,
        maximum: 1,
      };
    }

    return option;
  });
};
