import React, { ChangeEvent, FC, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";
import { ValueType, OptionType } from "react-select";
import get from "lodash/get";

import Input from "components/Form/Input";
import { ControllerSelect } from "components/Select";
import ControllerSwitch from "components/Switch/ControllerSwitch";
import StepperNumberInput from "components/StepperNumberInput";
import Typography from "components/Typography";
import Grid from "components/Grid";
import Card from "components/Card";

import COLORS from "constants/Colors";
import { CARRIER_OPTIONS_WITH_LOGO, ALL_CARRIERS, SHIPPOP_CARRIER_OPTIONS_WITH_LOGO } from "constants/Shipping";
import { CALCULATE, SHIPPOP_CALCULATE, CarrierOptionType } from "types/Shipping";

import { CustomOptionShippingCarrier, SingleValueShippingCarrier } from "../CustomSelectComponent";
import ShippingCondition from "../ShippingCondition";
import AdvanceSetting from "../AdvanceSetting";
import { PostalCodeBasedShippingProps } from "../../PostalCodeBasedShipping";

type CarrierCardProps = {
  carrierName: string;
  description: string;
  isAllowedOnlyCOD: boolean;
  isEnableAllowOnlyCODSwitch: boolean;
  isDisabledDuration: boolean;
  onChangeIsDisabledDuration: (value: boolean) => void;
  minDuration: number;
  maxDuration: number;
  onChangeMinDuration: (value: number) => void;
  onChangeMaxDuration: (value: number) => void;
  onChangeCarrierName: (value: ValueType<OptionType>) => void;
  control: Control;
  calculateType: CALCULATE;
  onMounted?: () => void;
  shippopCalculateType: SHIPPOP_CALCULATE;
  onChangeShippopCalculateType: (newShippopCalculateType: SHIPPOP_CALCULATE) => void;
} & PostalCodeBasedShippingProps;

const CarrierCard: FC<CarrierCardProps> = ({
  carrierName,
  description,
  isAllowedOnlyCOD,
  isEnableAllowOnlyCODSwitch,
  isDisabledDuration,
  onChangeIsDisabledDuration,
  minDuration,
  maxDuration,
  onChangeMinDuration,
  onChangeMaxDuration,
  onChangeCarrierName,
  control,
  calculateType,
  onMounted,
  fixedPrice,
  settingsPostalCode,
  setSettingsPostalCode,
  errors,
  shippopCalculateType,
  onChangeShippopCalculateType,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (onMounted) {
      onMounted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isShippopCalculateType = calculateType === CALCULATE.SHIPPOP;
  const carrierOptions: Array<CarrierOptionType> = isShippopCalculateType
    ? SHIPPOP_CARRIER_OPTIONS_WITH_LOGO
    : CARRIER_OPTIONS_WITH_LOGO;
  const defaultValueOption: CarrierOptionType =
    carrierOptions.find(({ value }) => value === (carrierName as string)) || carrierOptions[carrierOptions.length - 1];

  return (
    <Card fullWidth padding="24px" className="mt-4">
      <Typography variant="body2" color={COLORS.DarkMed}>
        {t("CARRIER_NAME")}
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <ControllerSelect<CarrierOptionType>
            name="carrierName"
            control={control}
            isSearchable={false}
            className="mt-3"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            defaultValue={carrierName as any}
            value={defaultValueOption}
            formatOptionLabel={(option: ValueType<OptionType>) => t(get(option, "label"))}
            options={carrierOptions}
            fullWidth
            onChange={onChangeCarrierName}
            isDisabled={calculateType === CALCULATE.DISTANCE_BASE_PRICE}
            components={{ SingleValue: SingleValueShippingCarrier, Option: CustomOptionShippingCarrier }}
          />

          {!isShippopCalculateType && !ALL_CARRIERS.includes(carrierName) && (
            <Input
              name="description"
              className="mt-3"
              placeholder={t("Other carrier name")}
              required
              fullWidth
              defaultValue={description}
            />
          )}
        </Grid>
        {isShippopCalculateType && (
          <>
            <Grid item xs={12} className="px-4 pt-4 pb-2">
              <ShippingCondition carrierName={carrierName} />
            </Grid>
            <Grid item xs={12} className="pt-3">
              <AdvanceSetting
                fixedPrice={fixedPrice}
                settingsPostalCode={settingsPostalCode}
                setSettingsPostalCode={setSettingsPostalCode}
                errors={errors}
                shippopCalculateType={shippopCalculateType}
                onChangeShippopSettingType={onChangeShippopCalculateType}
              />
            </Grid>
          </>
        )}

        <Grid item container xs={12} className="pt-4" spacing={2}>
          {(calculateType === CALCULATE.FIXED_PRICE || calculateType === CALCULATE.POSTAL_CODE_BASED_PRICE) &&
            isEnableAllowOnlyCODSwitch && (
              <Grid item container>
                <Grid item className="pb-3">
                  <ControllerSwitch name="isAllowedOnlyCOD" defaultValue={isAllowedOnlyCOD} />
                </Grid>
                <Grid item className="pb-3 pl-3">
                  <Typography variant="body2" color={COLORS.DarkMed}>
                    {t("shipping.isAllowedOnlyCOD")}
                  </Typography>
                </Grid>
              </Grid>
            )}

          <Grid item container>
            <Grid item className="pb-3">
              <ControllerSwitch
                name="disabledDuration"
                defaultValue={!isDisabledDuration}
                onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeIsDisabledDuration(event.target.checked)}
              />
            </Grid>
            <Grid item className="pb-3 pl-3">
              <Typography variant="body2" color={COLORS.DarkMed}>
                {t("Estimated delivery")}
              </Typography>
            </Grid>
          </Grid>
          {isDisabledDuration && (
            <Grid container item alignItems="center">
              <Grid item>
                <StepperNumberInput minValue={1} defaultColor value={minDuration} onChange={onChangeMinDuration} />
              </Grid>
              <Grid item className="mx-2">
                <Typography variant="body2" color={COLORS.DarkMed}>
                  {t("to")}
                </Typography>
              </Grid>
              <Grid item>
                <StepperNumberInput minValue={1} defaultColor value={maxDuration} onChange={onChangeMaxDuration} />
              </Grid>
              <Grid item className="mx-2">
                <Typography variant="body2" color={COLORS.DarkMed}>
                  {t("days")}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default memo(CarrierCard);
