import styled from "styled-components";
import Button, { ButtonWithIcon } from "components/Button";
import Typography from "components/Typography";

type CollapseButtonType = {
  isShow: boolean;
};

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.COLORS.White};
  border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  padding: 15px;
  margin-bottom: 24px;
  border-radius: 8px;
`;

export const DeleteButton = styled(Button)`
  border-color: ${({ theme }) => theme.COLORS.Error};
  background: ${({ theme }) => theme.COLORS.Error};
  color: ${({ theme }) => theme.COLORS.White};

  &:hover {
    border-color: ${({ theme }) => theme.COLORS.Error};
    background: ${({ theme }) => theme.COLORS.Error};
    color: ${({ theme }) => theme.COLORS.White};
  }
`;

export const CollapseButton = styled(ButtonWithIcon)<CollapseButtonType>`
  transition: transform 0.3s;
  transform-origin: center;
  transform: rotate(${({ isShow }) => (isShow ? 0 : 90)}deg);
  svg {
    margin-right: 0;
    padding-right: 4px;
    padding-bottom: 3px;
  }
  outline: none;
`;

export const Address = styled(Typography)`
  line-height: 1.7;
`;
