import React, { FC } from "react";
import get from "lodash/get";
import { OptionsType, OptionType, ActionMeta } from "@atlaskit/select";
import { FieldError, DeepMap } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { CardPropsType } from "components/Card";
import Grid from "components/Grid";
import { TOTAL_PROVINCE } from "config";
import { ProvinceType, SettingsPostalCodeType, PostalCodesWithCustomPriceType } from "types/Shipping";
import { notifyWarn } from "utils/notify";

import { provincesList, getExcludeProvinces } from "./util";
import { ShippingBoundary } from "./ShippingBoundary";
import { CustomRateProvince } from "./CustomRateProvince";
import { EditShippingMethodFormData } from "../EditShippingMethodForm";

export type PostalCodeBasedShippingProps = {
  fixedPrice: number;
  settingsPostalCode: SettingsPostalCodeType[];
  setSettingsPostalCode: Function;
  errors: DeepMap<EditShippingMethodFormData, FieldError>;
  cardProps?: CardPropsType;
};

export const PostalCodeBasedShipping: FC<PostalCodeBasedShippingProps> = (props) => {
  const { t } = useTranslation();

  const { fixedPrice, settingsPostalCode, setSettingsPostalCode, errors, cardProps } = props;

  const convertDataToOptionFormat = (provinces: ProvinceType[]) => {
    if (provinces && provinces.length) {
      return provinces.map((data: ProvinceType) => ({
        label: `${data.provinceTH}`,
        value: data.name,
      }));
    }
    return [];
  };

  const handleChangeBasePriceProvince = (provinceCode: string, price: number) => {
    const updatedSettingsPostalCode: SettingsPostalCodeType[] = settingsPostalCode.map(
      (customPostalCodeItem: SettingsPostalCodeType) => {
        if (customPostalCodeItem.name === provinceCode) {
          return {
            ...customPostalCodeItem,
            price,
          };
        }
        return customPostalCodeItem;
      },
    );
    setSettingsPostalCode(updatedSettingsPostalCode);
  };

  const handleChangeTagExcludeProvinceInput = (_option: OptionsType<OptionType>, action: ActionMeta<OptionType>) => {
    const actionValue = get(action, "option.value");
    if (action.action === "select-option") {
      const customRateProvincesExcludedOnly = (settingsPostalCode as SettingsPostalCodeType[]).filter(
        (customRateProvince: SettingsPostalCodeType) => customRateProvince.isActive === false,
      );
      if (customRateProvincesExcludedOnly.length !== TOTAL_PROVINCE - 1) {
        // not allow to exclude all province
        setSettingsPostalCode([
          ...settingsPostalCode.filter((setting) => setting.name !== actionValue),
          {
            name: actionValue,
            isActive: false,
          },
        ]);
      } else {
        notifyWarn(t("At least 1 province need to be include in shipping boundary"));
      }
    } else if (action.action === "deselect-option") {
      setSettingsPostalCode(settingsPostalCode.filter((setting) => setting.name !== actionValue));
    }
  };

  const handleChangeSelectProvince = (_option: OptionsType<OptionType>, action: ActionMeta<OptionType>) => {
    const actionValue = get(action, "option.value");
    if (action.action === "select-option") {
      setSettingsPostalCode([
        ...settingsPostalCode.filter((setting) => setting.name !== actionValue),
        {
          name: actionValue,
          price: fixedPrice,
          isActive: true,
        },
      ]);
    } else if (action.action === "deselect-option") {
      setSettingsPostalCode(settingsPostalCode.filter((setting) => setting.name !== actionValue));
    }
  };

  const handleAddCustomRatePostcode = (postalCode: string, provinceCode: string, price: number) => {
    const findSettingsPostalCode = settingsPostalCode.find((item) => item.name === provinceCode);
    if (findSettingsPostalCode) {
      const updatedSettingsPostalCode: SettingsPostalCodeType[] = settingsPostalCode.map(
        (customPostalCodeItem: SettingsPostalCodeType) => {
          if (customPostalCodeItem.name === provinceCode) {
            return {
              ...customPostalCodeItem,
              postalCodes: [
                ...((customPostalCodeItem.postalCodes as PostalCodesWithCustomPriceType[]) || []),
                { price, postalCode },
              ],
            };
          }
          return customPostalCodeItem;
        },
      );
      setSettingsPostalCode(updatedSettingsPostalCode);
    }
  };

  const handleEditCustomRatePostcode = (
    selectIndex: number,
    postalCode: string,
    provinceCode: string,
    price: number,
  ) => {
    const updatedSettingsPostalCode: SettingsPostalCodeType[] = settingsPostalCode.map(
      (customPostalCodeItem: SettingsPostalCodeType) => {
        if (customPostalCodeItem.name === provinceCode) {
          const updatedPostalCodes = ((customPostalCodeItem.postalCodes as PostalCodesWithCustomPriceType[]) || []).map(
            (prevPostalCode: PostalCodesWithCustomPriceType, index: number) => {
              if (selectIndex === index) {
                return { price, postalCode };
              }
              return prevPostalCode;
            },
          );

          return {
            ...customPostalCodeItem,
            postalCodes: updatedPostalCodes,
          };
        }
        return customPostalCodeItem;
      },
    );

    setSettingsPostalCode(updatedSettingsPostalCode);
  };

  const handleDeleteCustomRatePostcode = (selectIndex: number, provinceCode: string) => {
    const updatedSettingsPostalCode: SettingsPostalCodeType[] = settingsPostalCode.map(
      (customPostalCodeItem: SettingsPostalCodeType) => {
        if (customPostalCodeItem.name === provinceCode) {
          const updatedPostalCodes = (
            (customPostalCodeItem.postalCodes as PostalCodesWithCustomPriceType[]) || []
          ).filter((_prevPostalCode: PostalCodesWithCustomPriceType, index: number) => selectIndex !== index);

          return {
            ...customPostalCodeItem,
            postalCodes: updatedPostalCodes,
          };
        }
        return customPostalCodeItem;
      },
    );

    setSettingsPostalCode(updatedSettingsPostalCode);
  };

  const handleDeleteProvinceSetting = (provinceCode: string) => {
    setSettingsPostalCode(
      settingsPostalCode.filter((_prevPostalCode: SettingsPostalCodeType) => _prevPostalCode.name !== provinceCode),
    );
  };

  const optionsExcludeProvinceValue: OptionsType<OptionType> = convertDataToOptionFormat(provincesList);

  const provincesListForCustomActiveOnly = provincesList.filter(
    (province) => !getExcludeProvinces(settingsPostalCode).includes(province.name),
  );

  const optionsProvinceValue: OptionsType<OptionType> = convertDataToOptionFormat(provincesListForCustomActiveOnly);

  return (
    <Grid container item xs={12}>
      <CustomRateProvince
        customRateProvinces={settingsPostalCode}
        optionsProvinceValue={optionsProvinceValue}
        handleChangeBasePriceProvince={handleChangeBasePriceProvince}
        handleChangeSelectProvince={handleChangeSelectProvince}
        handleAddCustomRatePostcode={handleAddCustomRatePostcode}
        handleEditCustomRatePostcode={handleEditCustomRatePostcode}
        handleDeleteCustomRatePostcode={handleDeleteCustomRatePostcode}
        handleDeleteProvinceSetting={handleDeleteProvinceSetting}
        errors={errors}
        cardProps={cardProps}
      />
      <ShippingBoundary
        excludeProvince={getExcludeProvinces(settingsPostalCode)}
        optionsProvinceValue={optionsExcludeProvinceValue}
        handleChangeTagExcludeProvinceInput={handleChangeTagExcludeProvinceInput}
        cardProps={cardProps}
      />
    </Grid>
  );
};
