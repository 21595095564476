import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Grid from "components/Grid";
import Typography from "components/Typography";
// import Button from "components/Button";
import DateTimePicker from "components/DateTimePicker";
import TextField from "components/TextField";
import ConfirmModal from "components/Modal/ConfirmModal";

import { DATE_TIME_PICKER_FORMAT, CONTENT_MANAGEMENT_DATE_TIME_FORMAT } from "constants/DateTimeFormat";
import { Device } from "types/Device";
import { DigitalContentStatus, DigitalContent, DigitalContentAvailabilityType } from "types/DigitalContent";
import { ProductOutputType } from "types/Product";
import useDevice from "utils/hooks/useDevice";

type DigitalProductSKUItemPropsType = {
  isShowExpiredDateOnMobile?: boolean;
  status: DigitalContentStatus;
  outputType: ProductOutputType;
  digitalProductCode: DigitalContent;
  onClickMarkAsUsedButton?: () => void;
};

const DigitalProductSKUItem: FC<DigitalProductSKUItemPropsType> = (props) => {
  const { t } = useTranslation();
  const device = useDevice();
  const isMobile = Device.MOBILE === device;

  const {
    isShowExpiredDateOnMobile = false,
    status,
    // outputType,
    digitalProductCode,
    // onClickMarkAsUsedButton = () => {},
  } = props;
  const { refId, content, expiredAt, createdAt, note, usedAt, soldAt, availability } = digitalProductCode;

  const [isEditNote, setIsEditNote] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const handleSaveNote = () => {
    setIsEditNote(false);
  };

  const isNoExpired = availability?.type === DigitalContentAvailabilityType.NO_EXPIRY;
  const isExpireAfterPurchased = availability?.type === DigitalContentAvailabilityType.PERIOD_AFTER_PURCHASED;
  const unit = availability?.settings?.unit || "";
  const value = availability?.settings?.value || "";

  const isShowTextExpireAfterPurchased = status === DigitalContentStatus.AVAILABLE && isExpireAfterPurchased;

  const renderExpiredDateInfo = () => {
    if (
      (!isNoExpired && status === DigitalContentStatus.PURCHASED) ||
      (!isNoExpired && !isShowTextExpireAfterPurchased && status === DigitalContentStatus.AVAILABLE)
    ) {
      return (
        <Grid item xs={12} sm={status === DigitalContentStatus.PURCHASED ? 7 : 12}>
          <DateTimePicker
            selectedDateTime={moment(expiredAt, "x").toISOString()}
            format={DATE_TIME_PICKER_FORMAT}
            disablePast
            disableFuture={false}
            fullWidth
            // TODO: extend expired date (DP-2605)
            setDateTime={() => {}}
            disabled
          />
        </Grid>
      );
    }

    const renderTextExpiredDate = () => {
      if (isNoExpired && status !== DigitalContentStatus.REDEEMED) {
        return t("contentManagement.codeDetail.label.noExpiredTime");
      }
      if (isShowTextExpireAfterPurchased) {
        return t("contentManagement.codeDetail.label.expireAfterPurchased", {
          unit: t(`${unit}`, { value }),
          value,
        });
      }
      return moment(status === DigitalContentStatus.REDEEMED ? usedAt : expiredAt, "x").format(
        CONTENT_MANAGEMENT_DATE_TIME_FORMAT,
      );
    };

    return (
      <Grid item xs={isNoExpired || isShowTextExpireAfterPurchased ? 7 : 12}>
        <Typography variant="body2" color="darkGray" className={isMobile ? "" : "pt-2"}>
          {renderTextExpiredDate()}
        </Typography>
      </Grid>
    );
  };

  const renderPurchasedTime = () => {
    const time = status === DigitalContentStatus.AVAILABLE ? createdAt : soldAt;

    if (time) {
      return moment(time, "x").format(CONTENT_MANAGEMENT_DATE_TIME_FORMAT);
    }

    return "-";
  };

  return (
    <>
      <Grid item container>
        <Grid
          item
          container
          xs={isShowExpiredDateOnMobile ? 12 : 7}
          sm={3}
          alignItems={isMobile ? "center" : "flex-start"}
          className={isShowExpiredDateOnMobile ? "p-4 digital-code-detail" : ""}
        >
          <Grid item xs={isShowExpiredDateOnMobile ? 6 : 12}>
            <Typography variant="body3" color="darkLight">
              {t("contentManagement.codeDetail.label.refId", { refId })}
            </Typography>
          </Grid>
          <Grid item xs={isShowExpiredDateOnMobile ? 6 : 12}>
            <Typography
              variant="body2"
              color="darkGray"
              className={isMobile ? `${isShowExpiredDateOnMobile ? "" : "pt-3"}` : "pt-3"}
            >
              {content && content.substring(content.length - 20)}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={isShowExpiredDateOnMobile ? 12 : 5}
          sm={3}
          alignItems={isMobile ? "center" : "flex-start"}
          className={isShowExpiredDateOnMobile ? "p-4 digital-code-detail" : ""}
        >
          <Grid item xs={isShowExpiredDateOnMobile ? 4 : 12}>
            <Typography variant="body3" color="darkLight">
              {t(
                status === DigitalContentStatus.AVAILABLE
                  ? "contentManagement.codeDetail.label.importedTime"
                  : "contentManagement.codeDetail.label.purchasedTime",
              )}
            </Typography>
          </Grid>
          <Grid item xs={isShowExpiredDateOnMobile ? 8 : 12}>
            <Typography
              variant="body2"
              color="darkGray"
              className={isMobile ? `${isShowExpiredDateOnMobile ? "" : "pt-3"}` : "pt-3"}
            >
              {renderPurchasedTime()}
            </Typography>
          </Grid>
        </Grid>
        {(!isMobile ||
          (isShowExpiredDateOnMobile &&
            [DigitalContentStatus.REDEEMED, DigitalContentStatus.EXPIRED].includes(status))) && (
          <Grid
            item
            container
            xs={12}
            sm={status === DigitalContentStatus.REDEEMED ? 3 : 6}
            alignItems={isMobile ? "center" : "flex-start"}
            className={isMobile ? `${isShowExpiredDateOnMobile ? "p-4 digital-code-detail" : "pt-4"}` : ""}
          >
            <Grid
              item
              xs={isShowExpiredDateOnMobile ? 4 : 12}
              sm={12}
              className={isShowExpiredDateOnMobile ? "" : "pb-2"}
            >
              <Typography variant="body3" color="darkLight">
                {t(
                  status === DigitalContentStatus.REDEEMED
                    ? "contentManagement.codeDetail.label.redeemedTime"
                    : "contentManagement.codeDetail.label.expiredTime",
                )}
              </Typography>
            </Grid>
            <Grid item container xs={isShowExpiredDateOnMobile ? 8 : 12} sm={12}>
              {renderExpiredDateInfo()}
              {/* TODO: incomplete requirement mark as used */}
              {/* {!isMobile && status === DigitalContentStatus.PURCHASED && outputType !== ProductOutputType.STATIC && (
                <Grid item sm={5} className="pl-4">
                  <Button fullWidth onClick={onClickMarkAsUsedButton}>
                    {t("contentManagement.button.markAsUsed")}
                  </Button>
                </Grid>
              )} */}
            </Grid>
          </Grid>
        )}
        {isMobile &&
          isShowExpiredDateOnMobile &&
          (([DigitalContentStatus.AVAILABLE, DigitalContentStatus.PURCHASED].includes(status) && isNoExpired) ||
            isShowTextExpireAfterPurchased) && (
            <Grid item container xs={12} alignItems="center" className="p-4 digital-code-detail">
              <Grid item xs={4}>
                <Typography variant="body3" color="darkLight">
                  {t("contentManagement.codeDetail.label.expiredTime")}
                </Typography>
              </Grid>
              <Grid item container xs={8}>
                <Typography variant="body2" color="darkGray">
                  {t(
                    isShowTextExpireAfterPurchased
                      ? "contentManagement.codeDetail.label.expireAfterPurchased"
                      : "contentManagement.codeDetail.label.noExpiredTime",
                    { unit: t(`${unit}`, { value }), value },
                  )}
                </Typography>
              </Grid>
            </Grid>
          )}
        {!isMobile && status === DigitalContentStatus.REDEEMED && (
          <Grid item container xs={3}>
            <Grid item xs={12} className="pb-2">
              <Typography variant="body3" color="darkLight">
                {t("contentManagement.codeDetail.label.note")}
              </Typography>
            </Grid>
            <Grid item container xs={12} alignItems="center" className={isEditNote ? "" : "d-flex flex-nowrap pt-2"}>
              {isEditNote ? (
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder={t("contentManagement.codeDetail.placeholder.addNote")}
                  defaultValue=""
                  onChange={() => {}}
                  InputProps={{
                    endAdornment: (
                      <div className="mx-2" onClick={handleSaveNote}>
                        <Typography variant="title8" color="info">
                          {t("contentManagement.codeDetail.button.done")}
                        </Typography>
                      </div>
                    ),
                  }}
                />
              ) : (
                <Grid item className="pr-3">
                  <Typography variant="body2" color="darkGray">
                    {note || "-"}
                  </Typography>
                </Grid>
                // TODO: admin can edit note for redeemed digital code
                // <>
                //   <Grid item className="pr-3">
                //     <Typography variant="body2" color={note ? "darkGray" : "darkLight"}>
                //       {note || t("contentManagement.codeDetail.placeholder.addNote")}
                //     </Typography>
                //   </Grid>
                //   <Grid item className="d-flex">
                //     <SmallSquareButton
                //       onClick={() => {
                //         setIsEditNote(true);
                //       }}
                //     >
                //       <SvgIcon className="pl-1" component={IcEdit} fontSize="small" />
                //     </SmallSquareButton>
                //     {note && (
                //       <SmallSquareButton onClick={() => setIsConfirmModalOpen(true)}>
                //         <SvgIcon className="pl-1" component={IcDelete} fontSize="small" />
                //       </SmallSquareButton>
                //     )}
                //   </Grid>
                // </>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <ConfirmModal
        title="contentManagement.modal.title.deleteNote"
        confirmButtonText={t("Confirm")}
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onClickConfirmButton={() => setIsConfirmModalOpen(false)}
      />
    </>
  );
};

export default DigitalProductSKUItem;
