import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { OptionType, OptionsType, ActionMeta } from "@atlaskit/select";

import Grid from "components/Grid";
import MultiSelectWithSearchBox from "components/MultiSelectWithSearchBox";
import CircularProgress from "components/CircularProgress";

import FlexCategories from "components/FlexMessage/FlexCategories";

import { CategoryType } from "types/Category";
import { MAXIMUM_DISPLAY_CATEGORY_CAROUSEL } from "config";

export type SelectCategoriesPropsType = {
  id?: string;
  isCategoriesLoading: boolean;
  categoriesOptions: {
    label: string;
    img: string;
    value: string;
  }[];
  categoriesResults: CategoryType[];
  convertCategoriesValueToOptionFormat: Function;
  selectedCategories: number[];
  setSelectedCategories: Function;
};

const SelectCategories: FC<SelectCategoriesPropsType> = (props) => {
  const {
    id,
    selectedCategories,
    setSelectedCategories,
    isCategoriesLoading,
    categoriesOptions,
    categoriesResults,
    convertCategoriesValueToOptionFormat,
  } = props;
  const { t } = useTranslation();

  const handleChangeSelectCategory = (option: OptionsType<OptionType>, action: ActionMeta<OptionType>) => {
    if (action.action === "select-option" && selectedCategories.length < MAXIMUM_DISPLAY_CATEGORY_CAROUSEL) {
      setSelectedCategories(((option as OptionsType<OptionType>) || []).map((opt: OptionType) => opt.value));
    } else if (action.action === "deselect-option") {
      setSelectedCategories(((option as OptionsType<OptionType>) || []).map((opt: OptionType) => opt.value));
    }
  };

  const renderSelectedCategories = selectedCategories.map((categoryId: number) => {
    return categoriesResults.find((category: CategoryType) => Number(category.id) === Number(categoryId));
  });

  const handleClickDelete = (deleteCategoryId: string) => {
    setSelectedCategories(
      selectedCategories.filter((categoryId: number) => Number(deleteCategoryId) !== Number(categoryId)),
    );
  };

  return (
    <Grid container>
      {isCategoriesLoading ? (
        <Grid container item xs={12} justify="center">
          <CircularProgress className="p-4" />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <MultiSelectWithSearchBox
            id={id}
            isMulti
            isDisplayMinimumItem
            isDisplayImageOption
            value={convertCategoriesValueToOptionFormat(selectedCategories)}
            label={t("Click to select categories")}
            placeholder={t("Search categories")}
            options={categoriesOptions}
            onChange={(option: OptionsType<OptionType>, action: ActionMeta<OptionType>) => {
              handleChangeSelectCategory(option || [], action);
            }}
            maxMenuWidth={310}
          />
          {Boolean(renderSelectedCategories && renderSelectedCategories.length) && (
            <Grid container item xs={12} justify="flex-start" className="pt-1">
              <FlexCategories
                isEnableDelete
                onClickDelete={handleClickDelete}
                content={{ categories: (renderSelectedCategories as CategoryType[]) || [] }}
              />
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default SelectCategories;
