import { useQuery } from "@apollo/client";
import groupBy from "lodash/groupBy";

import { PACKAGES } from "graphql/plan/query";
import {
  PackageEntityType,
  PackageType,
  PackagesQueryType,
  PlanDetailType,
  PackageWithTitleType,
  PackagePricingType,
  FormattedPackageType,
  SubscriptionType,
} from "types/PlanUsage";
import { convertPriceFormat } from "utils/common";

import { IS_ALLOW_NEW_PACKAGE } from "config";
import { formatMainPackageDetail, formatCcommercePackageDetail, addOverPackage } from "../utils";

const formatPackage = (packages: PackageType[], title: PackagePricingType) =>
  packages.map((plan: PackageType) => {
    const {
      id,
      name,
      pricePerMonth,
      pricePerYear,
      isSelected,
      isTrial,
      entityType,
      orderQuota,
      subscriberQuota,
      extraChargeRate,
      deeplePayFeeRate,
      subscriptionType,
    } = plan;

    let planDetails: PlanDetailType[] = [];
    let markLabel = "";
    switch (entityType) {
      case PackageEntityType.C_COMMERCE:
        planDetails = formatCcommercePackageDetail(plan);
        markLabel = convertPriceFormat(orderQuota, 0);
        break;
      case PackageEntityType.MAIN:
        planDetails = formatMainPackageDetail(plan, title);
        markLabel = convertPriceFormat(subscriberQuota, 0);
        break;
      case PackageEntityType.BASIC:
        planDetails = [...formatMainPackageDetail(plan, title), ...formatCcommercePackageDetail(plan)];
        break;
      default:
        planDetails = [];
        break;
    }

    return {
      id,
      name,
      entityType,
      price: subscriptionType === SubscriptionType.MONTHLY ? pricePerMonth : pricePerYear,
      markLabel,
      isActive: isSelected,
      planDetails,
      isOneTimeTrial: isTrial,
      extraChargeRate,
      deeplePayFeeRate,
    };
  });

const usePlanData = (projectId: string, subscriptionType = SubscriptionType.MONTHLY) => {
  const { loading: isLoading, data: packagesData } = useQuery<
    PackagesQueryType,
    { projectId: string; subscriptionType: SubscriptionType }
  >(PACKAGES, {
    variables: {
      projectId,
      subscriptionType,
    },
  });

  if (packagesData && packagesData.packages) {
    const { free, professional, premium, standard } = packagesData.packages;

    const combinePackages = [
      { title: PackagePricingType.FREE, packages: free },
      ...(IS_ALLOW_NEW_PACKAGE ? [{ title: PackagePricingType.STANDARD, packages: standard }] : []),
      { title: PackagePricingType.PROFESSIONAL, packages: professional },
      { title: PackagePricingType.PREMIUM, packages: premium },
      { title: PackagePricingType.ENTERPRISE, packages: premium },
    ].filter(({ packages }: PackageWithTitleType) => packages.length);
    const formattedCombinePackages: FormattedPackageType[] = combinePackages.map(
      ({ title, packages }: PackageWithTitleType, index: number) => {
        const formattedPackages = formatPackage(packages, title);
        const groupPackages = groupBy(formattedPackages, "entityType");
        const isSelected = index === 0;
        const deeplePayFeeRate = groupPackages?.C_COMMERCE
          ? groupPackages?.C_COMMERCE[groupPackages.C_COMMERCE.length - 1]?.deeplePayFeeRate
          : groupPackages.BASIC[0]?.deeplePayFeeRate;

        if (title === PackagePricingType.FREE) {
          return {
            title,
            mainPackages: groupPackages.BASIC[0],
            cCommercePackages: groupPackages.BASIC[0],
            isSelected: IS_ALLOW_NEW_PACKAGE ? groupPackages.BASIC[0]?.isActive : isSelected,
            deeplePayFeeRate,
          };
        }

        if (title === PackagePricingType.ENTERPRISE) {
          const mainPackages = addOverPackage(groupPackages.MAIN);
          const cCommercePackages = addOverPackage(groupPackages.C_COMMERCE);

          const lastMainPackages = mainPackages[mainPackages.length - 1];
          const lastCCommercePackages = cCommercePackages[cCommercePackages.length - 1];

          return {
            title,
            mainPackages: lastMainPackages,
            cCommercePackages: lastCCommercePackages,
            isSelected: IS_ALLOW_NEW_PACKAGE ? lastCCommercePackages?.isActive : isSelected,
          };
        }

        return {
          title,
          mainPackages: groupPackages.MAIN[0],
          cCommercePackages: groupPackages.C_COMMERCE[0],
          isSelected: IS_ALLOW_NEW_PACKAGE ? groupPackages.C_COMMERCE[0]?.isActive : isSelected,
          deeplePayFeeRate,
        };
      },
    );

    const isSelectedBasicPackage = formattedCombinePackages.some(
      ({ title, isSelected }) => title === PackagePricingType.FREE && isSelected,
    );

    let packages = formattedCombinePackages;
    // This is hack for plan card slider in desktop when user current package is free
    if (formattedCombinePackages.length === 4) {
      packages = IS_ALLOW_NEW_PACKAGE
        ? formattedCombinePackages
        : [...formattedCombinePackages, formattedCombinePackages[3], formattedCombinePackages[3]];
    }

    return {
      packages,
      isLoading,
      isSelectedBasicPackage,
    };
  }

  return {
    packages: [],
    isLoading,
    isSelectedBasicPackage: false,
  };
};

export default usePlanData;
