import get from "lodash/get";
import { getProductTypes } from "utils/product/getProductTypes";
import { NO_IMG_AVAILABLE } from "constants/Image";
import { DEFAULT_VALUE } from "config/shippop";
import getDimensionText from "utils/getDimensionText";
import { formattedSubRows } from "./formattedSubRows";
import formattedWeight from "./formattedWeight";

export const formattedSubTable = (orderProducts: object[], t: Function) => {
  return orderProducts.map((orderProduct) => {
    const productType = get(orderProduct, "productSKU.productType") || [];
    const isFree = get(orderProduct, "productSKU.isFree") || false;

    let weight = get(orderProduct, "productSKU.weight");
    weight = formattedWeight(weight, t);

    let dimension = get(orderProduct, "productSKU.dimension");
    dimension = getDimensionText(dimension, t);

    return {
      productName: get(orderProduct, "product.name") || DEFAULT_VALUE,
      image: get(orderProduct, "productSKU.image.src") || get(orderProduct, "product.images.0.src") || NO_IMG_AVAILABLE,
      productCode:
        get(orderProduct, "productSKU.productCode") || get(orderProduct, "product.productCode") || DEFAULT_VALUE,
      productPrice: isFree ? "Free" : get(orderProduct, "productSKU.price") || 0,
      amount: get(orderProduct, "amount") || 0,
      weight,
      sku: getProductTypes(productType) || DEFAULT_VALUE,
      subRows: formattedSubRows(get(orderProduct, "options")),
      dimension,
    };
  });
};
