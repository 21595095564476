import React from "react";
import { CellProps, useSortBy, useFlexLayout, Row } from "react-table";
import { Link } from "react-router-dom";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import { MAX_TAG_CHAR_TO_DISPLAY } from "constants/Chat";
import COLORS from "constants/Colors";
import Typography from "components/Typography";
import IndeterminateCheckbox from "components/Checkbox";
import { FixWidthTooltip } from "components/Tooltip";
import { CustomerTag } from "domain/Chat/ContactsList/ContactDetailList/ContactDetail/styled";
import { SystemTagType } from "types/Tag";
import { PLATFORM } from "types/Customer";
import { convertPriceFormat } from "utils/common";
import { mapPotentialCustomerTag } from "utils/tag";
import { IcChat } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";

import Profile from "./Profile";

export const tableHooks = [useSortBy, useFlexLayout];

export const getColumns = <T extends object>(t: Function) => {
  return [
    {
      id: "selection",
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      // eslint-disable-next-line react/display-name
      Header: (row: Row<T> & { onSelectAllRowInPage?: Function }) => {
        const allData: (T & { id: string })[] = get(row, "data") || [];
        const ids = allData.map((data) => data.id);
        const isSelectedAll: boolean = get(row, `state.isSelectedAll`) || false;
        const excludeIds: Record<string, boolean> = get(row, `state.excludeIds`) || {};
        const selectedIds: Record<string, boolean> = get(row, `state.selectedIds`) || {};
        const isSelectedSomeRow = isSelectedAll ? ids.some((id) => excludeIds[id]) : ids.some((id) => !selectedIds[id]);

        return (
          <div className="m-auto">
            <IndeterminateCheckbox
              onClick={() => {
                if (row.onSelectAllRowInPage && ids.length) {
                  row.onSelectAllRowInPage(ids, isSelectedSomeRow);
                }
              }}
              checked={!isSelectedSomeRow}
            />
          </div>
        );
      },
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T> & { onSelectRow?: Function }) => {
        const id = get(cell, "row.original.id");
        const isSelected = get(cell, `state.selectedIds[${id}]`, false);
        const isExcluded = get(cell, `state.excludeIds[${id}]`, false);
        const isSelectedAll = get(cell, `state.isSelectedAll`, false);
        let isChecked = false;

        if (isSelectedAll) {
          isChecked = !isExcluded;
        } else {
          isChecked = isSelected;
        }

        return (
          <div className="m-auto">
            <IndeterminateCheckbox
              onClick={() => {
                if (cell.onSelectRow && id) {
                  cell.onSelectRow(id);
                }
              }}
              checked={isChecked}
            />
          </div>
        );
      },
    },
    {
      Header: t("customerName"),
      accessor: "profile",
      width: 150,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const id = get(cell, "row.original.id");

        return <Profile name={cell.value?.name} imgSrc={cell.value?.avatar} customerId={id} />;
      },
    },
    {
      Header: t("platform"),
      accessor: "platform",
      width: 120,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const platform = capitalize(cell.value);

        if (cell.value === PLATFORM.INSTAGRAM) {
          return (
            <>
              <img src="/assets/icon/ic-instagram.png" alt="instagram-icon" height="16" width="16" className="mr-2" />
              <Typography variant="body3" color="darkGray" className="ml-1">
                {platform}
              </Typography>
            </>
          );
        }

        if (cell.value === PLATFORM.FACEBOOK) {
          return (
            <>
              <img src="/assets/icon/ic-facebook.png" alt="facebook-icon" height="16" width="16" className="mr-2" />
              <Typography variant="body3" color="darkGray" className="ml-1">
                {platform}
              </Typography>
            </>
          );
        }

        if (cell.value === PLATFORM.LINE) {
          return (
            <>
              <img
                src="/assets/icon/ic-line-color-new@3x.png"
                alt="line-icon"
                height="16"
                width="16"
                className="mr-2"
              />
              <Typography variant="body3" color="darkGray" className="ml-1">
                {platform}
              </Typography>
            </>
          );
        }

        return (
          <Typography variant="body3" color="darkGray" className="ml-1">
            {platform}
          </Typography>
        );
      },
    },
    {
      disableSortBy: true,
      Header: "",
      accessor: "chatLink",
      width: 70,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <div>
            <Link to={cell.value} target="_blank">
              <SvgIcon className="ml-1 mt-1" component={IcChat} fontSize="small" htmlColor={COLORS.DarkLight} />
            </Link>
          </div>
        );
      },
    },
    {
      Header: t("totalSpend"),
      accessor: "totalSpend",
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return <div>{`${t("THB")} ${convertPriceFormat(cell.value)}`}</div>;
      },
    },
    {
      disableSortBy: true,
      Header: t("tags"),
      accessor: "tags",

      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <div className="d-flex align-items-center flex-wrap">
            {cell.value?.result.map((itemTag: SystemTagType) => {
              const { id, tag, displayTag, type } = itemTag;
              const tagContent = displayTag || tag;

              return (
                <FixWidthTooltip key={id} title={tagContent} placement="bottom" arrow>
                  <div className="mt-1">
                    <CustomerTag
                      type={type}
                      isFixWidth={tagContent.length > MAX_TAG_CHAR_TO_DISPLAY}
                      className="ellipsis"
                    >
                      {mapPotentialCustomerTag(tagContent)}
                    </CustomerTag>
                  </div>
                </FixWidthTooltip>
              );
            })}
            {Boolean(cell.value?.numberOfHiddenTags) && (
              <span className="mt-1">{`+${cell.value?.numberOfHiddenTags}`}</span>
            )}
          </div>
        );
      },
    },
    {
      Header: t("CREATED_AT"),
      accessor: "firstSeen",
      width: 150,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <Typography variant="body3" color="darkGray" className="ml-1">
            {cell.value}
          </Typography>
        );
      },
    },
    {
      Header: t("LAST_MESSAGE_AT"),
      accessor: "lastMessagedAt",
      width: 150,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <Typography variant="body3" color="darkGray" className="ml-1">
            {cell.value}
          </Typography>
        );
      },
    },
    {
      disableSortBy: true,
      Header: t("FIRST_NAME"),
      accessor: "firstName",
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <Typography variant="body3" color="darkGray" className="ml-1">
            {cell.value}
          </Typography>
        );
      },
    },
    {
      disableSortBy: true,
      Header: t("LAST_NAME"),
      accessor: "lastName",
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <Typography variant="body3" color="darkGray" className="ml-1">
            {cell.value}
          </Typography>
        );
      },
    },
    {
      disableSortBy: true,
      Header: t("Address"),
      accessor: "address",
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <Typography variant="body3" color="darkGray" className="ml-1">
            {cell.value}
          </Typography>
        );
      },
    },
    {
      disableSortBy: true,
      Header: t("PHONE_NUMBER"),
      accessor: "phoneNumber",
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <Typography variant="body3" color="darkGray" className="ml-1">
            {cell.value}
          </Typography>
        );
      },
    },
    {
      disableSortBy: true,
      Header: t("Email"),
      accessor: "email",
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <Typography variant="body3" color="darkGray" className="ml-1">
            {cell.value}
          </Typography>
        );
      },
    },
  ];
};
