import { gql } from "@apollo/client";

export const STAT = gql`
  query stat($projectId: ID!, $start: DateTime!, $end: DateTime!, $label: JSON, $option: GraphStatOptionInput) {
    averageOrderIncomeStat(projectId: $projectId, start: $start, end: $end, label: $label, option: $option) {
      averageOrderIncome
      changedPercentage
    }
    newCustomerStat(projectId: $projectId, start: $start, end: $end, label: $label) {
      totalNewCustomer
      changedNewCustomer
    }
    totalOrderStat(projectId: $projectId, start: $start, end: $end, label: $label, option: $option) {
      totalOrder
      changedPercentage
    }
    totalOrderIncomeStat(projectId: $projectId, start: $start, end: $end, label: $label, option: $option) {
      totalOrderIncome
      changedPercentage
    }
  }
`;

export const TOP_SELLING_PRODUCT_FROM_ORDER = gql`
  query topSellingProductsFromOrder($projectId: ID!, $timeSpanInDay: Int, $label: JSON) {
    topSellingProductsFromOrder(projectId: $projectId, timeSpanInDay: $timeSpanInDay, label: $label) {
      id
      projectId
      name
      productCode
      images {
        src
      }
    }
  }
`;

export const TOP_SELLING_PRODUCT_FROM_ORDER_WITH_PERIOD = gql`
  query topSellingProductsFromOrderWithPeriod($projectId: ID!, $start: DateTime!, $end: DateTime!, $label: JSON) {
    topSellingProductsFromOrderWithPeriod(projectId: $projectId, start: $start, end: $end, label: $label) {
      id
      projectId
      name
      productCode
      images {
        src
      }
    }
  }
`;

export const GRAPH_STAT_SUM = gql`
  query stat(
    $projectId: ID!
    $name: String!
    $start: DateTime!
    $end: DateTime!
    $timeUnit: TimeUnit
    $label: JSON
    $option: GraphStatOptionInput
  ) {
    graphStat: graphSumStat(
      projectId: $projectId
      name: $name
      start: $start
      end: $end
      timeUnit: $timeUnit
      label: $label
      option: $option
    ) {
      name
      dataPoints {
        timestamp
        value
      }
    }
  }
`;

export const GRAPH_STAT_COUNT = gql`
  query stat(
    $projectId: ID!
    $name: String!
    $start: DateTime!
    $end: DateTime!
    $timeUnit: TimeUnit
    $label: JSON
    $option: GraphStatOptionInput
  ) {
    graphStat: graphCountStat(
      projectId: $projectId
      name: $name
      start: $start
      end: $end
      timeUnit: $timeUnit
      label: $label
      option: $option
    ) {
      name
      dataPoints {
        timestamp
        value
      }
    }
  }
`;
