import * as React from "react";

const SvgIcIncome = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
    <circle cx="10" cy="10" r="10" fill="#F5F7FB" />
    <path
      d="M13.7764 6.29867C13.7429 6.26203 13.7065 6.22817 13.6676 6.19742C13.5525 6.10674 13.4126 6.05312 13.2664 6.04367H9.49386C9.31088 6.03935 9.13226 6.09982 8.98954 6.21441C8.84682 6.329 8.74918 6.49033 8.71386 6.66992C8.69573 6.77825 8.70161 6.88924 8.73109 6.99505C8.76057 7.10086 8.81293 7.1989 8.88447 7.28224C8.95601 7.36558 9.04499 7.4322 9.1451 7.47737C9.24522 7.52255 9.35404 7.54518 9.46386 7.54367L11.4101 7.54367L6.25011 12.6812C6.17925 12.751 6.12297 12.8341 6.08456 12.9259C6.04615 13.0176 6.02637 13.1161 6.02637 13.2155C6.02637 13.315 6.04615 13.4135 6.08456 13.5052C6.12297 13.597 6.17925 13.6801 6.25011 13.7499C6.31977 13.8197 6.40248 13.875 6.49353 13.9127C6.58458 13.9505 6.68218 13.9699 6.78074 13.9699C6.8793 13.9699 6.97689 13.9505 7.06794 13.9127C7.15899 13.875 7.24171 13.8197 7.31136 13.7499L12.4489 8.61242V10.5174C12.4444 10.6999 12.5043 10.8781 12.6182 11.0207C12.732 11.1634 12.8925 11.2613 13.0714 11.2974C13.18 11.3162 13.2914 11.3108 13.3976 11.2816C13.5039 11.2524 13.6025 11.2001 13.6862 11.1285C13.77 11.0569 13.837 10.9677 13.8824 10.8673C13.9278 10.7668 13.9505 10.6576 13.9489 10.5474V6.79742C13.9537 6.61577 13.8924 6.43854 13.7764 6.29867Z"
      fill="#2F4069"
    />
  </svg>
);

export default SvgIcIncome;
