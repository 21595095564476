import { COLORS } from "constants/Colors";

export default `.MuiPaper-root.MuiPaper-elevation8.MuiPopover-paper.MuiPaper-rounded {
    border-radius: 8px;
}

.MuiPickersDay-daySelected {
    background-color: ${COLORS.Primary};
}

.MuiPickersDay-current > span > p {
    font-weight: bold;
}

.MuiPickersDay-daySelected > .MuiPickersDay-current > span > p {
    color: ${COLORS.LightWhite};
    font-weight: bold;
}

.MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day:hover {
    background-color: ${COLORS.PrimaryNude};
    border: solid 1px ${COLORS.Primary};
    color: ${COLORS.Dark};
}

.MuiButtonBase-root.MuiIconButton-root.MuiPickersCalendarHeader-iconButton > .MuiIconButton-label {
    border: solid 0.5px ${COLORS.LightBlue};
    border-radius: 5px;
}`;
