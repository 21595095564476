import styled, { css } from "styled-components";
import Grid from "components/Grid";
import { VARIANTS } from "constants/Typography";

type GridWrapperPropsType = {
  isBlurContent?: boolean;
};

export const GridItem = styled(Grid)`
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
  padding: 0 8px;
`;

export const GridWrapper = styled(Grid)`
  font-size: ${VARIANTS.body4.size};
  justify-content: space-between;
  ${({ isBlurContent }: GridWrapperPropsType) =>
    isBlurContent &&
    css`
      filter: blur(4px);
    `}
`;
