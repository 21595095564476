import * as React from "react";

const IcNotificationSetting = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path fill="currentColor" fillRule="evenodd" d="M28,6.29a1,1,0,1,1,0,2H18.15a1,1,0,0,1,0-2Z" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.5,3A4.28,4.28,0,1,1,6.33,8.29H3.94a1,1,0,1,1,0-2H6.33A4.29,4.29,0,0,1,10.5,3Z"
    />
    <path fill="currentColor" fillRule="evenodd" d="M28,15a1,1,0,0,1,0,2H26.9a1,1,0,0,1,0-2Z" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.24,11.75A4.29,4.29,0,1,1,15.08,17H3.94a1,1,0,0,1,0-2H15.08A4.29,4.29,0,0,1,19.24,11.75Z"
    />
    <path fill="currentColor" fillRule="evenodd" d="M28,23.78a1,1,0,0,1,0,2H18.15a1,1,0,0,1,0-2Z" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.5,20.5a4.28,4.28,0,1,1-4.17,5.28H3.94a1,1,0,0,1,0-2H6.33A4.3,4.3,0,0,1,10.5,20.5Z"
    />
  </svg>
);

export default IcNotificationSetting;
