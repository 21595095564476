import { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";

import { UserProfileType } from "types/User";
import { FLAG_USER_WITH_IS_ENTERPRISE } from "graphql/auth/query";
import { UPDATE_RESTORE_ID } from "graphql/project/mutation";
import { FRESH_DESK_TOKEN, FRESH_DESK_HOST, IS_FRESH_DESK } from "config";
import { FRESH_DESK_DEFAULT_POSITION, FRESH_DESK_WIDGET_CONTENT_TRANSLATION } from "constants/FreshDesk";

const useAuthenticateFreshdesk = (projectId: string) => {
  const { data } = useQuery<UserProfileType>(FLAG_USER_WITH_IS_ENTERPRISE, {
    variables: { projectId },
  });

  const [updateRestoreId] = useMutation(UPDATE_RESTORE_ID);

  const currentUser = data && data.me;
  const permissions = currentUser && currentUser.permissions;
  const currentProject = permissions && permissions.find((permission) => permission.projectId === projectId);
  const freshDeskRestoreId = currentProject?.restoreId;

  const externalId = `PID:${projectId}:${currentUser?.email}`;
  const email = currentUser?.email;
  const firstName = currentUser?.firstname || currentUser?.displayName;
  const lastName = currentUser?.lastname;

  useEffect(() => {
    const registerFreshDesk = async () => {
      if (email && projectId) {
        if (!IS_FRESH_DESK) {
          return;
        }

        const freshDeskInfo = {
          token: FRESH_DESK_TOKEN,
          host: FRESH_DESK_HOST,
          externalId,
          restoreId: freshDeskRestoreId,
          email,
          firstName,
          lastName,
          config: {
            cssNames: {
              widget: `fc_frame ${FRESH_DESK_DEFAULT_POSITION}`,
            },
            content: FRESH_DESK_WIDGET_CONTENT_TRANSLATION,
          },
        };
        const handleWidgetDestroyed = () => {
          window.fcWidget?.off("widget:destroyed", handleWidgetDestroyed);
          window.fcWidget?.init(freshDeskInfo);
        };

        const handleWidgetLoaded = () => {
          window.fcWidget?.off("widget:loaded", handleWidgetLoaded);
          window.fcWidget?.on("widget:destroyed", handleWidgetDestroyed);
          window.fcWidget?.destroy();
        };

        if (window.fcWidget?.isInitialized()) {
          try {
            await window.fcWidget.user.clear();
          } catch (e) {
            console.log(e);
          }
        }

        if (!window.fcWidget?.isInitialized()) {
          window.fcWidget?.init(freshDeskInfo);
        } else if (window.fcWidget.isLoaded()) {
          window.fcWidget?.on("widget:destroyed", handleWidgetDestroyed);
          window.fcWidget?.destroy();
        } else {
          window.fcWidget?.on("widget:loaded", handleWidgetLoaded);
        }

        window.fcWidget?.on("user:created", async (response) => {
          if (response?.data?.restoreId && freshDeskRestoreId !== response?.data?.restoreId) {
            await updateRestoreId({
              variables: {
                projectId,
                restoreId: response?.data?.restoreId,
              },
            });
          }
        });
      }
    };

    registerFreshDesk();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, externalId, firstName, freshDeskRestoreId, lastName, projectId]);
};

export default useAuthenticateFreshdesk;
