import moment from "moment";
import Card from "components/Card";
import Grid from "components/Grid";
import { SvgIcon } from "components/Icon";
import { IcPlanUpgrade, IcInfo } from "components/SvgIcons";
import Typography from "components/Typography";
import Tooltip from "components/Tooltip";
import COLORS from "constants/Colors";
import { PLAN, PLAN_UPGRADE, PROJECT } from "constants/Router";
import { DATE_INVOICE_FORMAT } from "constants/DateTimeFormat";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PaymentSummaryItemType, PaymentSummaryType, PaymentSummaryTypes } from "types/Billing";
import { convertPriceFormat } from "utils/common";
import { SubscriptionType } from "types/PlanUsage";
import SummaryList from "./summaryList";
import { CardWithShadow, HorizontalScrollWrapper, PackageSummaryWrapper, PackageDetailWrapper } from "./styled";

export type PaymentSummaryDesktopPropsType = {
  billingDate: string;
  displayUpgradeButton: boolean;
  isDesktop: boolean;
  isFreePlan: boolean;
  itemsSummary: PaymentSummaryItemType[];
  packageLabel: string;
  packagePriceTotal: number;
  packageSummaryLabel: string;
  paymentSummary: PaymentSummaryType;
  projectId: string;
  isHiddenNextBilling: boolean;
};

const PaymentSummaryDesktop: FC<PaymentSummaryDesktopPropsType> = (props) => {
  const {
    billingDate,
    displayUpgradeButton,
    isDesktop,
    isFreePlan,
    itemsSummary,
    packageLabel,
    packagePriceTotal,
    packageSummaryLabel,
    paymentSummary,
    projectId,
    isHiddenNextBilling,
  } = props;

  const { t } = useTranslation();
  const endPackagePeriodDate = new Date(paymentSummary.mainPackagePeriod.endBillingDate);
  const startPackagePeriodDate = new Date(paymentSummary.mainPackagePeriod.startBillingDate);
  const endPackagePeriod = moment(endPackagePeriodDate).format(DATE_INVOICE_FORMAT);
  const startPackagePeriod = moment(startPackagePeriodDate).format(DATE_INVOICE_FORMAT);
  const subscriptionPeriod =
    paymentSummary.mainPackagePeriod.subscriptionType === SubscriptionType.ANNUALLY
      ? t("planUsage.subscriptionType.annually")
      : t("planUsage.subscriptionType.monthly");
  return (
    <Card noShadow padding="0px" borderRadius={isDesktop ? 8 : 0}>
      <Grid
        container
        style={{
          height: 150,
          maxHeight: 150,
        }}
      >
        <PackageSummaryWrapper>
          <Grid container justify="space-between" alignItems="center" className="px-4 py-3 my-1">
            <Grid item>
              <Typography variant="title10" color="darkLight">
                {t("planBilling.header")}
              </Typography>
            </Grid>
            {displayUpgradeButton && (
              <Grid item>
                <Link to={`/${PROJECT}/${projectId}/${PLAN}/${PLAN_UPGRADE}`}>
                  <Typography variant="body4" color="accent">
                    <SvgIcon
                      className="ml-1 pb-0 pr-1"
                      component={IcPlanUpgrade}
                      fontSize="small"
                      htmlColor="inherit"
                    />
                    {t("billing.link.upgradePlan")}
                  </Typography>
                </Link>
              </Grid>
            )}
          </Grid>

          <Grid container justify="space-between" alignItems="center" className="px-4 pt-1">
            <Grid item>
              <Typography variant="title2" color="dark">
                {isFreePlan ? t("Free") : `${t("THB")} ${convertPriceFormat(paymentSummary.totalPrice)}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="px-4 pb-3 pt-3 my-1" alignItems="baseline" justify="space-between">
            {!isHiddenNextBilling && (
              <Grid item>
                <Grid container alignItems="baseline">
                  <Grid item>
                    <Typography variant="body3" color="darkGray" className="pr-2">
                      {t("planBilling.label.summary.usagePeriod")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="title8" color="darkGray">
                      {isFreePlan ? " - " : billingDate}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>

          <CardWithShadow />
        </PackageSummaryWrapper>

        <HorizontalScrollWrapper className="px-2 my-1 show-scroll">
          <PackageDetailWrapper className="pt-3 px-3">
            {/* MAIN PACKAGE */}
            <Typography variant="title10" color="darkLight">
              {t(`planUsage.broadcast.label.package`)}
            </Typography>

            <Typography variant="body3" color="darkGray" className="mt-3">
              {packageLabel}{" "}
              {!isFreePlan && (
                <Tooltip
                  title={
                    <div>
                      <Typography variant="body5" color="white">
                        Subscription - {subscriptionPeriod}
                      </Typography>
                      <Typography variant="body5" color="white">
                        {startPackagePeriod} - {endPackagePeriod}
                      </Typography>
                    </div>
                  }
                  placement="top-start"
                  arrow
                >
                  <span>
                    <IcInfo color={COLORS.DarkMed} />
                  </span>
                </Tooltip>
              )}
            </Typography>

            <Typography variant="title3" color="darkGray" className="mt-2">
              {t("THB")}
              {convertPriceFormat(Math.floor(packagePriceTotal))}
            </Typography>
            <Typography variant="body4" color="darkLight" className="mt-3 package-summary-label">
              {packageSummaryLabel}
            </Typography>
          </PackageDetailWrapper>
          {/* ADD-ON */}
          {itemsSummary && !!itemsSummary.length && (
            <SummaryList
              type={PaymentSummaryTypes.ADDON}
              itemsSummary={itemsSummary}
              startPackagePeriod={startPackagePeriod}
              endPackagePeriod={endPackagePeriod}
            />
          )}
          {/* EXTRA */}
          {paymentSummary.extras && !!paymentSummary.extras.length && (
            <SummaryList
              type={PaymentSummaryTypes.EXTRA}
              itemsSummary={paymentSummary.extras}
              startPackagePeriod={startPackagePeriod}
              endPackagePeriod={endPackagePeriod}
            />
          )}
        </HorizontalScrollWrapper>
      </Grid>
    </Card>
  );
};

PaymentSummaryDesktop.displayName = "PaymentSummaryDesktop";

export default PaymentSummaryDesktop;
