import styled from "styled-components";
import Grid from "components/Grid";
import { COLORS } from "constants/Colors";

export const MemberListItem = styled(Grid)`
  width: 100%;
  border-bottom: solid 1px ${COLORS.LightBlue};
  background: ${COLORS.White};
  padding: 15px 0;
`;

export const MemberTypeTitle = styled(Grid)`
  width: 100%;
  border-bottom: solid 1px ${COLORS.LightBlue};
`;

export const MemberAvatar = styled.img`
  border-radius: 100%;
  height: 40px;
  width: 40px;
`;

export const LineNameTag = styled.div`
  border-radius: 8px;
  border: solid 0.5px ${COLORS.Primary};
  font-size: 10px;
  padding: 5px;
  color: ${COLORS.Primary};
  background-color: ${COLORS.PrimaryNude};
`;

export const CloseIconWrapper = styled.span`
  font-weight: bold;
  &:hover {
    color: ${COLORS.LightGray};
  }
`;
