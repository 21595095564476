import * as React from "react";

const SvgIcStoreSettings = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <defs>
      <path id="ic-store-settings_svg__a" d="M0 0h32v32H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="ic-store-settings_svg__b" fill="#fff">
        <use xlinkHref="#ic-store-settings_svg__a" />
      </mask>
      <path
        fill="currentColor"
        d="M10.256 11.778L11.428 4H5.333l-2.546 6.667c-.079.237-.12.483-.12.74 0 1.636 1.703 2.963 3.809 2.963 1.94 0 3.545-1.131 3.78-2.592zM16 14.37c2.104 0 3.809-1.327 3.809-2.963 0-.06-.005-.121-.008-.179L19.047 4h-6.094l-.756 7.222a3.729 3.729 0 00-.006.185c0 1.636 1.705 2.963 3.809 2.963zm7.407 1.55v5.858H8.593v-5.85a6.234 6.234 0 01-2.117.368c-.29 0-.57-.034-.846-.072v9.406c0 1.14.931 2.074 2.07 2.074h16.596a2.081 2.081 0 002.074-2.074v-9.405c-1.382.048-2.37-.054-2.963-.305zm5.808-5.253L26.665 4h-6.093l1.17 7.766c.228 1.467 1.833 2.604 3.782 2.604 2.104 0 3.81-1.327 3.81-2.963a2.35 2.35 0 00-.12-.74z"
        mask="url(#ic-store-settings_svg__b)"
      />
    </g>
  </svg>
);

export default SvgIcStoreSettings;
