import React, { FC, ChangeEventHandler } from "react";
import { useController, UseControllerOptions } from "react-hook-form";
import Switch from "./index";

const ControllerSwitch: FC<
  UseControllerOptions & { onChange?: ChangeEventHandler; isReadOnly?: boolean; className?: string; value?: boolean }
> = (props) => {
  const { className, onChange, isReadOnly, value } = props;
  const { field } = useController(props);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    field.onChange(event.target.checked);

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Switch
      className={className}
      checked={value == null ? field.value : value}
      onChange={handleChange}
      disabled={isReadOnly}
    />
  );
};

export default ControllerSwitch;
