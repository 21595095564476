import React, { FC, ChangeEventHandler, useState, useCallback } from "react";
import { DragDropContext, Droppable, Draggable, ResponderProvided, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { ValueType, OptionType, OptionsType } from "react-select";
import get from "lodash/get";

import { COLORS } from "constants/Colors";
import { formatProductDataToFlexFormat } from "components/Message/utils/formatProduct";
import { getRelatedPromotionByProducts } from "components/Message/utils/promotionUtil";
import { IcDrag, IcInfo } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";
import CircleRemoveIcon from "components/CircleRemoveIcon";
import CircularProgress from "components/CircularProgress";
import FlexCategories from "components/FlexMessage/FlexCategories";
import FlexProductCarousel from "components/FlexMessage/FlexProductCarousel";
import Grid from "components/Grid";
import ImageMessage from "components/FlexMessage/ImageMessage";
import InputMessage from "components/MessagesManager/InputMessage";
import MessageLayout from "components/FlexMessage/MessageLayout";
import Typography from "components/Typography";
import {
  MessageImageValue,
  ResponseMessage,
  MessageTextValue,
  CategoriesObjectType,
  ProductsObjectType,
  QuickReplyMessage,
} from "types/AISetting";
import { MessageType } from "types/Chat";
import { CategoryType } from "types/Category";
import { ProductItemPropsType } from "types/Product";
import { ImageUrl } from "types/Image";
import { VideoMessage } from "components/FlexMessage";
import { QUICK_REPLY_MAX_CHARACTER_LENGTH, QUICK_REPLY_MAX_TAG_SIZE, QUICK_REPLY_INPUT_HEIGHT } from "./constant";
import TagInputQuickReply from "./TagInputQuickReply";
import Placeholder from "./Placeholder";
import useGetCategories from "./hooks/useGetCategories";
import useGetProducts from "./hooks/useGetProducts";
import useGetPromotions from "./hooks/useGetPromotions";
import MessageInput from "./MessageInput";
import { ActionType } from "./MessageInput/ActionMenus";
import {
  AddMessageWrapper,
  SortIcon,
  MessagesManagerWrapper,
  MessagesContentWrapper,
  QuickReplyWrapper,
  QuickReplyInfoWrapper,
} from "./styled";
import { MessageInputVariant } from "./type";

export { MessageInputVariant } from "./type";

type MessagesManagerPropsType = {
  onClickAddMessage: (newMessage: string) => void;
  onDeleteMessage: (index: number) => void;
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
  onEditMessage: ChangeEventHandler<HTMLTextAreaElement & HTMLInputElement>;
  onUploadCallback: (url: string) => void;
  messages: ResponseMessage[];
  projectId: string;
  isShowLabel?: boolean;
  maxMessagesLength: number;
  disabled?: boolean;
  addNewMessages?: (
    type: MessageType,
    value: MessageTextValue | MessageImageValue | number[] | CategoriesObjectType | ProductsObjectType,
  ) => void;
  minHeight?: number;
  enableQuickReply?: boolean;
  updateQuickReplyMessage?: (newData: { text: string; label: string }[]) => void;
  messageInputVariant?: MessageInputVariant;
  editableMessageTypes?: MessageType[];
  isDisabledTextInput?: boolean;
};

const isEditable = (messageType: MessageType, editableMessageTypes: MessageType[]) =>
  editableMessageTypes.includes(messageType);

const DEFAULT_EDITABLE_MESSAGE_TYPES = Object.values(MessageType);

const MessagesManager: FC<MessagesManagerPropsType> = ({
  onClickAddMessage,
  onDeleteMessage,
  onDragEnd,
  onEditMessage,
  onUploadCallback,
  messages,
  projectId,
  isShowLabel = true,
  maxMessagesLength = 3,
  disabled,
  addNewMessages = () => {},
  minHeight = 400,
  enableQuickReply = false,
  updateQuickReplyMessage = () => {},
  messageInputVariant,
  editableMessageTypes = DEFAULT_EDITABLE_MESSAGE_TYPES,
  isDisabledTextInput = false,
}) => {
  const { t } = useTranslation();

  const maximumMessages = enableQuickReply ? maxMessagesLength + 1 : maxMessagesLength;

  const [previewTextMessage, setPreviewTextMessage] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
  const [actionType, setActionType] = useState<ActionType>(ActionType.TEXT);
  const { loading, categoriesOptions, categoriesResults, convertCategoriesValueToOptionFormat } = useGetCategories({
    projectId,
  });

  const {
    loading: loadingProducts,
    productOptions,
    productResults,
    convertProductValueToOptionFormat,
  } = useGetProducts({
    projectId,
  });

  const { loading: loadingPromotions, activePromotionsResults } = useGetPromotions({
    projectId,
  });

  const saveCategoriesMessage = selectedCategories.map((categoryId: number) => {
    return categoriesResults.find((category: CategoryType) => Number(category.id) === Number(categoryId));
  });

  const saveProductsMessage = selectedProducts.map((productId: number) => {
    return productResults.find((product: ProductItemPropsType) => Number(product.id) === Number(productId));
  });

  const isActiveSendButton =
    (selectedCategories && selectedCategories.length) ||
    (selectedProducts && selectedProducts.length) ||
    previewTextMessage;

  const handleClickSendDraftMessage = () => {
    if (actionType === ActionType.CATEGORY) {
      if (selectedCategories && selectedCategories.length) {
        addNewMessages(MessageType.CATEGORY_CAROUSEL, {
          categories: saveCategoriesMessage || [],
        } as CategoriesObjectType);
        setSelectedCategories([]);
      }
    } else if (actionType === ActionType.PRODUCT) {
      if (selectedProducts && selectedProducts.length) {
        addNewMessages(MessageType.PRODUCT_CAROUSEL, {
          products: saveProductsMessage || [],
        } as ProductsObjectType);
        setSelectedProducts([]);
      }
    } else if (previewTextMessage) {
      onClickAddMessage(previewTextMessage);
      setPreviewTextMessage("");
    }
  };

  const handleChangeActionType = useCallback((newActionType: ActionType) => {
    setActionType(newActionType);
  }, []);

  const handleChangeMessage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPreviewTextMessage(event.target.value);
  }, []);

  const handleChange = (imageUrl: ImageUrl[]) => {
    if (imageUrl.length) {
      const [{ publicUrl }] = imageUrl;
      onUploadCallback(publicUrl);
    }
  };

  const relatedPromotionByProducts =
    getRelatedPromotionByProducts((productResults as ProductItemPropsType[]) || [], activePromotionsResults || []) ||
    [];

  if (loading || loadingProducts || loadingPromotions) {
    return (
      <Grid container item xs={12} justify="center">
        <CircularProgress className="p-4" />
      </Grid>
    );
  }

  const handleChangeTagInput = (tagInputValues: ValueType<OptionType>) => {
    updateQuickReplyMessage(
      ((tagInputValues as OptionsType<OptionType>) || []).map((item: OptionType) => {
        return { text: item.value, label: item.value };
      }),
    );
  };

  const messageQuickReply: ResponseMessage | undefined = messages.find(
    (message) => message.type === MessageType.QUICK_REPLIES,
  );

  const messageQuickReplyIndex = messages.findIndex((message) => message.type === MessageType.QUICK_REPLIES);
  const isMessageBeforeQuickReplyIsText =
    messageQuickReplyIndex - 1 !== -1 ? messages[messageQuickReplyIndex - 1]?.type === MessageType.TEXT : false;
  const messageQuickReplyValue = messageQuickReply?.value;
  const messageQuickReplyOption = ((messageQuickReplyValue as QuickReplyMessage[]) || []).map(
    (item: { text: string; label: string }) => {
      return { value: item.text, label: item.label };
    },
  );

  return (
    <MessagesManagerWrapper minHeight={minHeight}>
      <MessagesContentWrapper minHeight={enableQuickReply ? minHeight - QUICK_REPLY_INPUT_HEIGHT : minHeight}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" isDropDisabled={Boolean(disabled)}>
            {(provided, { isDraggingOver }) => {
              return (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {!messages.length && <Placeholder />}
                  {messages.map((message, index) => {
                    const isEditableFlag = isEditable(message.type, editableMessageTypes);
                    const fileAttachmentLink = get(message, "value.url");
                    const thumbnailUrl = get(message, "value.thumbnailUrl");

                    return (
                      message.type !== MessageType.QUICK_REPLIES && ( // Hidden out of the list
                        // eslint-disable-next-line react/no-array-index-key
                        <Draggable key={index} draggableId={`${index}`} index={index}>
                          {(dragProvided) => (
                            <div
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                              style={{
                                ...dragProvided.draggableProps.style,
                                boxShadow: isDraggingOver ? "0 0 8px  rgba(0, 0, 0, 0.08)" : "none",
                              }}
                              className="py-2"
                            >
                              <Grid container justify="flex-end" alignItems="flex-end" alignContent="flex-end">
                                <Grid xs={12} sm={8} item>
                                  {isShowLabel && (
                                    <Typography
                                      color={COLORS.DarkLight}
                                      variant="title8"
                                      className="text-uppercase text-center mb-3"
                                    >
                                      {t("Message box")} {index + 1}
                                    </Typography>
                                  )}

                                  <div className="d-flex align-items-center position-relative">
                                    {!disabled && (
                                      <>
                                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                        <SortIcon {...(dragProvided.dragHandleProps as any)}>
                                          <IcDrag color={COLORS.DarkMed} />
                                        </SortIcon>
                                        {isEditableFlag && (
                                          <CircleRemoveIcon
                                            onClick={() => onDeleteMessage(index)}
                                            right={0}
                                            size={24}
                                            top={-8}
                                          />
                                        )}
                                      </>
                                    )}

                                    {message.type === MessageType.TEXT && (
                                      <InputMessage
                                        bgColor={COLORS.White}
                                        className="input-message"
                                        id={`${index}`}
                                        onChange={onEditMessage}
                                        value={message.value as string}
                                        placeholder={t("Message here")}
                                        disabled={disabled || !isEditableFlag}
                                      />
                                    )}

                                    {message.type === MessageType.IMAGE && (
                                      <ImageMessage
                                        bgColor={COLORS.White}
                                        fullWidth
                                        isCustomer={false}
                                        value={message.value as MessageImageValue}
                                      />
                                    )}

                                    {message.type === MessageType.MESSAGE_BUILDER && (
                                      <InputMessage
                                        bgColor={COLORS.White}
                                        className="input-message"
                                        id={`${index}`}
                                        value={t("MESSAGE_BUILDER")}
                                        disabled={disabled || !isEditableFlag}
                                      />
                                    )}

                                    {message.type === MessageType.CATEGORY_CAROUSEL && (
                                      <MessageLayout bgColor={COLORS.White} isCustomer={false}>
                                        <FlexCategories content={message.value as CategoriesObjectType} />
                                      </MessageLayout>
                                    )}

                                    {message.type === MessageType.VIDEO && (
                                      <VideoMessage
                                        bgColor={COLORS.White}
                                        thumbnailUrl={thumbnailUrl}
                                        videoUrl={fileAttachmentLink}
                                      />
                                    )}

                                    {message.type === MessageType.PRODUCT_CAROUSEL && (
                                      <MessageLayout bgColor={COLORS.White} isCustomer={false}>
                                        <FlexProductCarousel
                                          content={formatProductDataToFlexFormat({
                                            products: get(message.value, "products") || [],
                                            promotions: relatedPromotionByProducts,
                                          })}
                                        />
                                      </MessageLayout>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          )}
                        </Draggable>
                      )
                    );
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </MessagesContentWrapper>

      <AddMessageWrapper>
        {enableQuickReply && messageQuickReplyValue && (
          <>
            {isMessageBeforeQuickReplyIsText ? (
              <QuickReplyWrapper marginBottom={messages.length >= maximumMessages}>
                <TagInputQuickReply
                  value={messageQuickReplyOption}
                  limit={QUICK_REPLY_MAX_TAG_SIZE}
                  characterLimit={QUICK_REPLY_MAX_CHARACTER_LENGTH}
                  onChange={(options: ValueType<OptionType>) => {
                    handleChangeTagInput(options || []);
                  }}
                  dataCy="quick-reply-tags"
                  placeholder={t("Add quick reply message")}
                  notAllowSpecialCharacter
                  disabled={disabled || !isEditable(MessageType.QUICK_REPLIES, editableMessageTypes)}
                />
              </QuickReplyWrapper>
            ) : (
              <QuickReplyInfoWrapper className="wordBreak">
                <Typography color={COLORS.DarkGray} variant="body3" className="d-flex flex-row align-items-center">
                  <SvgIcon component={IcInfo} htmlColor={COLORS.DarkLight} fontSize="default" className="mx-3 pb-2" />
                  {t("Quick reply disabled")}
                </Typography>
              </QuickReplyInfoWrapper>
            )}
          </>
        )}
        {messages.length < maximumMessages && !disabled && (
          <MessageInput
            isDisabled={isDisabledTextInput}
            actionType={actionType}
            activePromotionsResults={activePromotionsResults}
            categoriesOptions={categoriesOptions}
            categoriesResults={categoriesResults}
            convertCategoriesValueToOptionFormat={convertCategoriesValueToOptionFormat}
            convertProductValueToOptionFormat={convertProductValueToOptionFormat}
            iconColorSubmit={isActiveSendButton ? COLORS.Primary : COLORS.DarkLight}
            isCategoriesLoading={loading}
            isProductsLoading={loadingProducts || loadingPromotions}
            message={previewTextMessage}
            messageInputVariant={messageInputVariant}
            onChangeActionType={handleChangeActionType}
            onChangeImage={handleChange}
            onChangeMessage={handleChangeMessage}
            onSubmit={handleClickSendDraftMessage}
            placeholder={t("Type your message here")}
            productOptions={productOptions}
            productResults={productResults}
            projectId={projectId}
            selectedCategories={selectedCategories}
            selectedProducts={selectedProducts}
            setSelectedCategories={setSelectedCategories}
            setSelectedProducts={setSelectedProducts}
          />
        )}
      </AddMessageWrapper>
    </MessagesManagerWrapper>
  );
};

export default MessagesManager;
