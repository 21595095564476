import React from "react";
import { CellProps } from "react-table";

const getShippingDescription = <T extends object>(t: Function) => ({
  Header: t("CARRIER_NAME"),
  accessor: "shipping",
  // eslint-disable-next-line react/display-name
  Cell: (cell: CellProps<T>) => <div>{t(cell.value)}</div>,
});

export default getShippingDescription;
