import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import Button from "components/Button";
import Typography from "components/Typography";
import TextField from "components/TextField";
import Card from "components/Card";
import { RICH_MENU } from "constants/i18n";
import { Behavior } from "types/RichMenu";

type RichMenuDetailProps = {
  defaultTitle?: string;
  defaultLabel?: string;
  defaultBehavior?: Behavior;
};

const RichMenuDetail: FC<RichMenuDetailProps> = ({ defaultTitle, defaultLabel, defaultBehavior }) => {
  const { t } = useTranslation(RICH_MENU);
  const { register, control, errors } = useFormContext();

  return (
    <Card noShadow padding="24px">
      <Typography variant="title3" className="mb-3">
        {t("RICH_MENU_DETAIL")}
      </Typography>

      <div className="mb-2">
        <Typography variant="body3" color="darkMed" className="mb-1">
          {t("TITLE")}
        </Typography>
        <TextField
          name="title"
          fullWidth
          variant="outlined"
          className="mb-2"
          inputRef={register}
          error={Boolean(errors?.title)}
          helperText={errors?.title?.message}
          defaultValue={defaultTitle}
        />
        <Typography variant="body4" color="darkMed">
          {t("TITLE_FOR_MANAGEMENT_PURPOSE")}
        </Typography>
      </div>

      <div className="mb-2">
        <Typography variant="body3" color="darkMed" className="mb-1">
          {t("MENU_BAR_LABEL")}
        </Typography>
        <TextField
          name="label"
          fullWidth
          variant="outlined"
          inputRef={register}
          error={Boolean(errors?.label)}
          helperText={errors?.label?.message}
          defaultValue={defaultLabel}
        />
      </div>

      <div className="mb-2">
        <Typography variant="body3" color="darkMed" className="mb-1">
          {t("DEFAULT_BEHAVIOR")}
        </Typography>
        <Controller
          control={control}
          name="behavior"
          defaultValue={defaultBehavior || Behavior.SHOWN}
          render={(field) => (
            <div>
              <Button
                isSelected={Behavior.SHOWN === field.value}
                type="button"
                color="secondary"
                className="mr-2"
                onClick={() => {
                  field.onChange(Behavior.SHOWN);
                }}
              >
                {t("SHOWN")}
              </Button>
              <Button
                isSelected={Behavior.COLLAPSED === field.value}
                type="button"
                color="secondary"
                onClick={() => {
                  field.onChange(Behavior.COLLAPSED);
                }}
              >
                {t("COLLAPSED")}
              </Button>
            </div>
          )}
        />
      </div>
    </Card>
  );
};

export default RichMenuDetail;
