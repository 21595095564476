import React, { FC, useState, ChangeEvent, ReactNode, useEffect } from "react";
import styled from "styled-components";
import times from "lodash/times";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";

import { useTranslation } from "react-i18next";
import Typography from "../Typography";
import Grid from "../Grid";
import { SvgIcon } from "../Icon";
import { SmallSquareButton } from "../Button";
import { IcArrowBack, IcArrowNext } from "../SvgIcons";

type themePropsType = {
  Colors: { [color: string]: string };
};

type PaginationPropsType = {
  rowsPerPage: number;
  page: number;
  totalItem: number;
  isMobile?: boolean;
  isShowResultText?: boolean;
  theme?: themePropsType;
  handlePageChange: Function;
};

type PageSelectionPropsType = {
  isMobile?: boolean;
  isShowResultText?: boolean;
  pageSelectOptions: Array<ReactNode>;
  value: string | number;
  onChange?: (event: ChangeEvent<{ name?: string | undefined; value: unknown }>, child: ReactNode) => void;
};

const StyledSelectInput = styled(InputBase)``;

const StyledSelect = styled(Select)`
  margin: 0 12px;
  .MuiInputBase-root,
  .MuiSelect-selectMenu {
    height: 28px;
    width: 50px;
    padding: 3px 12px 3px 0;
    border-radius: 8px;
    border: 0.5px solid ${({ theme }) => theme.COLORS.LightBlue};
    &:focus {
      border-color: ${({ theme }) => theme.COLORS.Primary};
    }
  }

  .MuiInputBase-input,
  .MuiInputBase-inputSelect {
    display: flex;
    align-items: center;
    width: auto;
    padding: 0 24px 0 16px;
  }
`;

const StyledNativeSelect = styled(NativeSelect)`
  .MuiNativeSelect-root,
  .MuiNativeSelect-select,
  .MuiInputBase-input,
  .MuiInputBase-inputSelect {
    height: 28px;
    width: 50px;
    padding: 3px 12px 3px 0;
    border-radius: 8px;
    border: 0.5px solid ${({ theme }) => theme.COLORS.LightBlue};
    &:focus {
      border-color: ${({ theme }) => theme.COLORS.Primary};
    }
  }

  .MuiInputBase-input,
  .MuiInputBase-inputSelect {
    display: flex;
    align-items: center;
    width: auto;
    padding: 0 24px 0 16px;
  }
`;

const PageSelection: FC<PageSelectionPropsType> = (props) => {
  const { pageSelectOptions, isMobile, ...otherProps } = props;
  return isMobile ? (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <StyledNativeSelect {...(otherProps as any)} input={<StyledSelectInput />}>
      {pageSelectOptions}
    </StyledNativeSelect>
  ) : (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <StyledSelect {...(otherProps as any)} input={<StyledSelectInput />}>
      {pageSelectOptions}
    </StyledSelect>
  );
};

const Pagination: FC<PaginationPropsType> = (props) => {
  const { isMobile, isShowResultText = true, rowsPerPage, page, totalItem, handlePageChange, ...otherProps } = props;
  const [currentPage, setCurrentPage] = useState(page);
  const { t } = useTranslation();

  const totalPage = Math.ceil(totalItem / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage + 1;
  const computeEndIndex = currentPage * rowsPerPage;
  const actualEndIndex = computeEndIndex > totalItem ? totalItem : computeEndIndex;
  const pageSelectOptions = times(totalPage, (i) => {
    const pageIndex = i + 1;
    return isMobile ? (
      <option key={pageIndex} value={pageIndex}>
        {pageIndex}
      </option>
    ) : (
      <MenuItem key={pageIndex} value={pageIndex}>
        {pageIndex}
      </MenuItem>
    );
  });

  const onPageChange = (newPage: number) => {
    setCurrentPage(Number(newPage));
    handlePageChange(Number(newPage));
  };

  const onClickPrev = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const onClickNext = () => {
    if (currentPage < totalPage) {
      onPageChange(currentPage + 1);
    }
  };

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  return (
    <Grid container className="px-2 mt-2" alignItems="center" justify="space-between">
      <Grid item>
        <Typography variant="body3" className="pr-2 py-2">
          <>{isShowResultText && `${t("Showing result")} : `}</> {startIndex} - {actualEndIndex} {t("of")} {totalItem}
        </Typography>
      </Grid>
      <Grid item className="d-flex">
        <PageSelection
          {...otherProps}
          isMobile={isMobile}
          value={currentPage}
          onChange={(e) => {
            onPageChange(Number(e.target.value));
          }}
          pageSelectOptions={pageSelectOptions}
        />
        {isMobile && (
          <Typography variant="body3" className="px-1 d-inline-flex align-items-center">
            {t("of")} {totalPage}
          </Typography>
        )}

        {isMobile && (
          <div className="d-flex">
            <SmallSquareButton onClick={onClickPrev} isDisabled={currentPage === 1}>
              <SvgIcon className="pl-1" component={IcArrowBack} fontSize="small" />
            </SmallSquareButton>
            <SmallSquareButton onClick={onClickNext} isDisabled={currentPage === totalPage}>
              <SvgIcon className="pl-1" component={IcArrowNext} fontSize="small" />
            </SmallSquareButton>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

Pagination.defaultProps = {
  rowsPerPage: 1,
  page: 1,
  totalItem: 0,
  isMobile: false,
  handlePageChange: () => {},
};

export default Pagination;
