import React, { FC } from "react";
import Typography from "components/Typography";
import Grid from "components/Grid/index";
import { useTranslation } from "react-i18next";

const InvoiceHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center" className="py-2">
      <Grid item xs={3}>
        <Typography variant="title10" color="darkLight">
          {t("billing.invoiceHistory.header.date")}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="title10" color="darkLight">
          {t("billing.invoiceHistory.header.paymentMethod")}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="title10" color="darkLight">
          {t("billing.invoiceHistory.header.amount")}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="title10" color="darkLight">
          {t("billing.invoiceHistory.header.file")}
        </Typography>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};

export default InvoiceHeader;
