import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { NOTIFICATION_DELETE } from "constants/Notification";
import { notifySuccess, notifyError } from "utils/notify";
import produce from "immer";

import { BRANCHES } from "graphql/branch/query";
import { DELETE_BRANCH } from "graphql/branch/mutation";
import { BranchesQueryType, BranchType } from "types/Branch";

type DeletedBranchMutationType = {
  deleteOfflineStore: BranchType;
};

type DeletedBranchVariablesType = {
  projectId: string;
  offlineStoreId: string;
};

const useDeleteBranch = (projectId: string) => {
  const { t } = useTranslation();

  const [deleteBranch, { loading: isLoading }] = useMutation<DeletedBranchMutationType, DeletedBranchVariablesType>(
    DELETE_BRANCH,
    {
      update(cache, { data }) {
        const cachedBranches = cache.readQuery({
          query: BRANCHES,
          variables: {
            projectId,
          },
        });

        if (cachedBranches && data) {
          const { deleteOfflineStore: deletedOfflineStore } = data;

          const updatedCacheBranches = produce((cachedBranches as BranchesQueryType).offlineStores, (draft) => {
            const index = draft.findIndex((branch) => branch.id === deletedOfflineStore.id);
            if (index !== -1) draft.splice(index, 1);
          });

          cache.writeQuery({
            query: BRANCHES,
            variables: {
              projectId,
            },
            data: {
              offlineStores: updatedCacheBranches,
            },
          });
        }
      },
      onError: () => {
        notifyError(t(NOTIFICATION_DELETE.FAIL));
      },
      onCompleted: () => {
        notifySuccess(t(NOTIFICATION_DELETE.SUCCESS));
      },
    },
  );

  return {
    deleteBranch,
    isLoading,
  };
};

export default useDeleteBranch;
