import moment from "moment";
import * as yup from "yup";

import { TIME_FORMAT } from "constants/DateTimeFormat";
import { PHONE_REG_EXP_REQUIRED, PHONE_REG_EXP_NOT_REQUIRED } from "constants/RegExp";
import { OpeningHoursType } from "types/Branch";
import { validateThPostCode } from "utils/address";

import * as ERROR_MESSAGE from "./errorMsg";

const branchSchema = yup.object().shape({
  isActive: yup.boolean(),
  isAvailableForPickUp: yup.boolean(),
  isAvailableForLalamove: yup.boolean(),
  name: yup.string().trim().required(ERROR_MESSAGE.NAME_ERROR),
  address: yup.string().when("isActive", (isActive: boolean) => {
    if (isActive) {
      return yup.string().trim().required(ERROR_MESSAGE.ADDRESS_ERROR);
    }
    return yup.string().trim();
  }),

  subDistrict: yup.string().when("isActive", (isActive: boolean) => {
    if (isActive) {
      return yup.string().trim().required("Please fill subDistrict");
    }
    return yup.string().trim();
  }),
  district: yup.string().when("isActive", (isActive: boolean) => {
    if (isActive) {
      return yup.string().trim().required("Please fill district");
    }
    return yup.string().trim();
  }),

  province: yup.string().when("isActive", (isActive: boolean) => {
    if (isActive) {
      return yup.string().trim().required("Please fill province");
    }
    return yup.string().trim();
  }),

  postalCode: yup.string().when("isActive", (isActive: boolean) => {
    if (isActive) {
      return yup
        .string()
        .trim()
        .test("len", "Postal Code Must be exactly 5 characters", (val) => val && val.toString().length === 5)
        .required("Please fill postalCode")
        .test("isThPostcode", "form.error.postcode", validateThPostCode);
    }
    return yup
      .string()
      .trim()
      .test("len", "Postal Code Must be exactly 5 characters", (val) => val && val.toString().length === 5)
      .test("isThPostcode", "form.error.postcode", validateThPostCode);
  }),

  latitude: yup
    .number()
    .nullable()
    // eslint-disable-next-line func-names
    .test("required", "offlineStore.latitude.isRequired", function (value) {
      if (value == null && this.parent.longitude != null) {
        return false;
      }

      return true;
    }),
  longitude: yup
    .number()
    .nullable()
    // eslint-disable-next-line func-names
    .test("required", "offlineStore.longitude.isRequired", function (value) {
      if (value == null && this.parent.latitude != null) {
        return false;
      }

      return true;
    }),
  phoneNumber: yup.string().when("isActive", (isActive: boolean) => {
    if (isActive) {
      return yup.string().matches(PHONE_REG_EXP_REQUIRED, ERROR_MESSAGE.PHONE_NUMBER_ERROR);
    }

    return yup.string().matches(PHONE_REG_EXP_NOT_REQUIRED, ERROR_MESSAGE.PHONE_NUMBER_ERROR);
  }),
  location: yup.string().when("isActive", (isActive: boolean) => {
    if (isActive) {
      return yup
        .string()
        .trim()
        .required(ERROR_MESSAGE.LOCATION_ERROR)
        .test("len", ERROR_MESSAGE.LOCATION_MUST_BE_SHORTEN_LINK, (value) => value.length <= 50);
    }

    return yup.string().trim();
  }),
  openingHours: yup
    .array()
    .of(
      yup.object().shape({
        isActive: yup.boolean(),
        day: yup.string(),
        open: yup.string().default("09:00"),
        close: yup.string().default("18:00"),
      }),
    )
    .when("isActive", (isOfflineStoreActive: boolean) => {
      return yup.array().of(
        yup
          .object()
          .shape({
            isActive: yup.boolean(),
            day: yup.string(),
            open: yup.string(),
            close: yup.string(),
          })
          .test(
            ERROR_MESSAGE.INVALID_VALUE,
            ERROR_MESSAGE.TIMEPICKER_OVER_MIDNIGHT,
            (openingHour: OpeningHoursType) => {
              const { close, isActive } = openingHour;
              const closeValue = moment(close, TIME_FORMAT).valueOf();

              const midNightStart = moment("00:00", TIME_FORMAT).valueOf();
              const midNightEnd = moment("00:59", TIME_FORMAT).valueOf();

              if (midNightStart < closeValue && closeValue <= midNightEnd && isActive && isOfflineStoreActive) {
                return false;
              }

              return true;
            },
          )
          .test(ERROR_MESSAGE.INVALID_VALUE, ERROR_MESSAGE.TIMEPICKER_ERROR, (openingHour: OpeningHoursType) => {
            const { open, close, isActive } = openingHour;
            const closeValue = moment(close, TIME_FORMAT).valueOf();
            const openValue = moment(open, TIME_FORMAT).valueOf();
            const midNightStart = moment("00:00", TIME_FORMAT).valueOf();

            if (closeValue === midNightStart && isActive && isOfflineStoreActive) {
              return true;
            }

            if (!isOfflineStoreActive && isActive) {
              return true;
            }

            if (openValue >= closeValue && isActive && isOfflineStoreActive) {
              return false;
            }

            return true;
          }),
      );
    }),
});

export default branchSchema;
