import { getYear, parseISO, format } from "date-fns";

export const getYearsFromYearRange = (endYear: number, startYear?: number): number[] => {
  const currentYear = startYear || getYear(new Date());
  let startYearValue = currentYear;
  let endYearvalue = endYear;

  if (currentYear > endYear) {
    startYearValue = endYear;
    endYearvalue = currentYear;
  }

  const numberOfYears = endYearvalue - startYearValue + 1;

  return new Array(numberOfYears).fill(startYearValue).map((value, index) => value + index);
};

export const formatDateFromISOString = (dateISOString: string, dateFormat: string) => {
  return format(parseISO(dateISOString), dateFormat);
};
