import { Member } from "types/Setting";
import { EntityLevel } from "types/Billing";
import { AddonType } from "types/Addon";

export enum PackageEntityType {
  BASIC = "BASIC",
  C_COMMERCE = "C_COMMERCE",
  IMAGE_SEARCH = "IMAGE_SEARCH",
  MAIN = "MAIN",
}

export enum PackagePricingType {
  FREE = "free",
  PROFESSIONAL = "professional",
  PREMIUM = "premium",
  ENTERPRISE = "enterprise",
  STANDARD = "standard",
}

export enum PlanUsagePackagesType {
  C_COMMERCE = "cCommercePackage",
  IMAGE_SEARCH = "imageSearchPackage",
  MAIN = "mainPackage",
}

export type FeeRate = {
  fix: number;
  min: number;
  rate: number;
};

export interface DeeplePayFeatureDetail {
  type: string;
  quota: string;
  unit: number;
  fee?: number;
}

export type DeeplePayFeeRate = {
  qrCash: FeeRate;
  creditCard: FeeRate;
  foreignCreditCard: FeeRate;
  mobileBanking: FeeRate;
  rabbitLinePay: FeeRate;
  trueMoneyWallet: FeeRate;
  withdrawal: FeeRate;
};

export type PlanUsageDetailType = {
  value: number;
  limit: number;
  label: string;
  lastUpdated?: string;
};

export type PlanUsageDataType = {
  name: string;
  details: PlanUsageDetailType[];
};

export enum PlanUsagePackageGroupType {
  MAIN = "mainPackage",
  IMAGE_SEARCH = "imageSearchAddon",
  LINE_MESSAGE = "lineMessage",
  MANUAL_APPROVE_ORDER = "manualApproveOrder",
}

export type UsagePackageGroupType = Record<PlanUsagePackageGroupType, PlanUsageDataType>;

export type HasChangesOnNextCycleType = {
  hasPackageChangesOnNextCycle: boolean;
  hasAddonChangesOnNextCycle: boolean;
};

export type HasChangesOnNextCycleQueryType = {
  hasChangesOnNextCycle: HasChangesOnNextCycleType;
};

export type InfoItemType = {
  title: string;
  description: string;
};

export type PlanDetailType = {
  quota: string;
  type: string;
  extraPrice?: number;
  isDisabled?: boolean;
  isAddon?: boolean;
  fee?: number;
  deeplePayFeeRate?: DeeplePayFeeRate;
  isOverCustomPackage?: boolean;
};

export type ChannelType = {
  platform: string;
  name: string;
};

type CcommercePackageType = {
  id: string;
  name: string;
  type: string;
  entityType: PackageEntityType;
  entityLevel: EntityLevel | null;
  pricePerMonth: number;
  orderQuota: number;
  orderUsage: number;
  productQuota: number;
  productUsage: number;
  subscriptionType: SubscriptionType;
};

type ImageSearchPackage = {
  id: string;
  name: string;
  type: string;
  entityType: PackageEntityType;
  isTrial: boolean;
  pricePerMonth: number;
  imageSearchQuota: number;
  imageSearchUsage: number;
};

export type MainPackageType = {
  id: string;
  name: string;
  type: string;
  entityType: PackageEntityType;
  entityLevel: EntityLevel | null;
  isAddonEnabled: boolean;
  pricePerMonth: number;
  subscriberQuota: number;
  subscriberUsage: number;
  messageQuota: number;
  messageUsage: number;
  orderQuota: number;
  orderUsage: number;
  productQuota: number;
  productUsage: number;
  faqQuota: number;
  faqUsage: number;
  broadcastQuota: number;
  broadcastUsage: number;
  memberQuota: number;
  memberUsage: number;
  members: Member[];
  channelQuota: number;
  channelUsage: number;
  channels: ChannelType[];
  deeplePayFeeRate: DeeplePayFeeRate;
  subscriptionType: SubscriptionType;
};

export type CurrentUsageType = {
  projectId: string;
  usagePeriod: string;
  currentBillingDate: string;
  nextBillingDate: string;
  ccommercePackage: CcommercePackageType;
  imageSearchPackage: ImageSearchPackage;
  mainPackage: MainPackageType;
  addons: AddonType[];
  lineMessageUsage: number;
  lineMessageQuota: number;
  lineLastFetchMessageTimestamp: string;
  manualApproveOrderUsage: number;
};

export type CurrentUsageQueryType = {
  currentUsage: CurrentUsageType;
};

export type PlanCardPropsType = {
  id: string;
  name: string;
  price: number;
  markLabel: string;
  planDetails: PlanDetailType[];
  isActive: boolean;
  entityType: PackageEntityType;
  isContactSale?: boolean;
  isOneTimeTrial?: boolean;
  extraChargeRate: number;
  deeplePayFeeRate?: DeeplePayFeeRate;
};

export enum PackageTypes {
  PACKAGE = "PACKAGE",
  ADDON = "ADDON",
}

export type PackageType = {
  id: string;
  name: string;
  type: PackageTypes;
  entityType: PackageEntityType;
  entityLevel: EntityLevel | null;
  isTrial: boolean;
  isSelected: boolean;
  pricePerMonth: number;
  pricePerYear: number;
  subscriberQuota: number;
  messageQuota: number;
  orderQuota: number;
  faqQuota: number;
  productQuota: number;
  memberQuota: number;
  channelQuota: number;
  imageSearchQuota: number;
  isAddonEnabled: boolean;
  extraChargeRate: number;
  deeplePayFeeRate?: DeeplePayFeeRate;
  broadcastQuota?: number;
  subscriptionType: SubscriptionType;
};

export type GroupPackageType = {
  free: PackageType[];
  standard: PackageType[];
  professional: PackageType[];
  premium: PackageType[];
};

export type PackagesQueryType = {
  packages: GroupPackageType;
};

export type CurrentPackagesQueryType = {
  currentPackages: PackageType[];
};

export type PackageDetailType = {
  type: string;
  planDetails: PlanDetailType[];
};

export type PackageWithActiveIndexType = {
  activeIndex: number;
  packages: PlanCardPropsType[];
};

export type FormattedPackageType = {
  title: PackagePricingType;
  mainPackages: PlanCardPropsType;
  cCommercePackages: PlanCardPropsType;
  isSelected: boolean;
  deeplePayFeeRate?: DeeplePayFeeRate;
};

export type PackageWithTitleType = {
  title: PackagePricingType;
  packages: PackageType[];
};

export type SelectedPackageIndexType = {
  mainPackage: number;
  cCommercePackage: number;
};

export type MainCreditQueryType = {
  credit: number;
};

type MonthlyBillingSummaryPackagePrice = {
  package: number;
  addon: number;
  imageSearchPackage: number;
};

type MonthlyBillingSummary = {
  newPackage: MonthlyBillingSummaryPackagePrice;
};

type CreditSummary = {
  totalCredit: number;
};

export type ChangePackageSummary = {
  creditSummary: CreditSummary;
  totalDiscount: number;
  monthlyBillingSummary: MonthlyBillingSummary;
  nextBillingDate: string;
  totalPrice: number;
  updateDateInfo: {
    currentMonthlyBilling: string | null;
    currentYearlyBilling: string | null;
    nextMonthlyBilling: string;
  };
  invoiceDateInfo: {
    endBillingDate: string;
    startBillingDate: string;
  };
  isDuringFreeTrialPeriod: Boolean;
  currentPackages: PackageType[];
  newPackages: PackageType[];
};

export interface CurrentSelectedPackage {
  id?: string;
  title: PackagePricingType;
  totalPrice: number;
  packages: PlanCardPropsType[];
  subscriptionType?: SubscriptionType;
  index?: number;
}

export enum SubscriptionType {
  MONTHLY = "MONTHLY",
  ANNUALLY = "ANNUALLY",
}
