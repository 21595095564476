import React, { FC } from "react";
import { useParams, Switch, Redirect, Route } from "react-router-dom";

import { PROJECT, USER_SETTING, ACCOUNT_SETTINGS, NOTIFICATIONS, NOTIFICATION_SETTING } from "constants/Router";
import AccountSetting from "domain/Authentication/ProfileSettingForm";
import NotificationSetting from "domain/Notification/NotificationSetting";
import AllNotification from "domain/Notification/AllNotification";

import PrivateRoute from "routers/PrivateRoute";
import { ProjectIdType } from "types/Project";
import { NotFound } from "pages/NotFound";
import { TAB_CONFIGS } from "pages/AccountSetting/tabsConfig";
import useGetUserWithPermission from "utils/hooks/useGetUserWithPermission";
import useTabWithLink from "utils/hooks/useTabWithLink";

const UserSettingSubRoute: FC = () => {
  const { projectId } = useParams<ProjectIdType>();
  const { loading, isAdmin } = useGetUserWithPermission(projectId);
  const { rootPath, defaultTabPath } = useTabWithLink(TAB_CONFIGS, USER_SETTING, projectId);

  return (
    <Switch>
      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${ACCOUNT_SETTINGS}`}
        isAllowed={isAdmin}
        redirectTo="/"
        component={AccountSetting}
      />

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${NOTIFICATION_SETTING}`}
        isAllowed={isAdmin}
        redirectTo="/"
        component={NotificationSetting}
      />

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${NOTIFICATIONS}`}
        isAllowed={isAdmin}
        redirectTo="/"
        component={AllNotification}
      />

      <PrivateRoute exact loading={loading} path={`${rootPath}`} isAllowed={isAdmin} redirectTo="/">
        <Redirect to={defaultTabPath ? `/${PROJECT}/${projectId}/${USER_SETTING}/${defaultTabPath}` : `/${PROJECT}`} />
      </PrivateRoute>
      <Route path={`${rootPath}/*`}>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default UserSettingSubRoute;
