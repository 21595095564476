const facebookAutoEngagement = {
  en: {
    "facebookLive.header": "Live to chat",
    "facebookLive.createNew": "Add new Facebook live",
    "facebookLive.table.title": "Facebook live title",
    "facebookLive.table.live.status": "Facebook live status",
    "facebookLive.table.postId": "Attached Facebook live",
    "facebookLive.table.reach": "Reach",
    "facebookLive.table.engagement": "Engagement",
    "facebookLive.table.orderPaidConversionRate": "Order paid",
    "facebookLive.table.orderPaidConversionTotal": "Total spending",
    "facebookLive.table.latestUpdate": "Latest update",
    "facebookLiveForm.header": "Add new Facebook live",
    "facebookLiveForm.header.edit": "Edit Facebook live",
    "facebookLiveForm.postId": "Post ID",
    "facebookLiveForm.title": "Facebook live title",
    "facebookLiveForm.post": "Attached Facebook live",
    "facebookLiveForm.cfCode.title": "Product CF code",
    "facebookLiveForm.cfCode.description": "Select products with their product CF codes.",
    "facebookLiveForm.productSelector.placeholder": "Search product name",
    "facebookLiveForm.productSelector.browse": "Browse",
    "facebookLive.toast.complete": "Success",
    "facebookLive.toast.fail": "Fail",
    "facebookLiveForm.confirmDelete": "Are you sure you want to delete",
    "facebookLive.table.placeholder": "No post",
    "facebookLive.live": "Live",
    "facebookLive.end": "End",
  },
  th: {
    "facebookLive.header": "ตอบกลับเฟซบุ๊กไลฟ์",
    "facebookLive.createNew": "เพิ่มเฟซบุ๊กไลฟ์",
    "facebookLive.table.title": "ชื่อเฟซบุ๊กไลฟ์",
    "facebookLive.table.live.status": "สถานะการถ่ายทอดสด",
    "facebookLive.table.postId": "เฟซบุ๊กไลฟ์ที่แนบ",
    "facebookLive.table.reach": "การเข้าถึง",
    "facebookLive.table.engagement": "การตอบกลับ",
    "facebookLive.table.orderPaidConversionRate": "ออเดอร์ที่สำเร็จ",
    "facebookLive.table.orderPaidConversionTotal": "ยอดขาย",
    "facebookLive.table.latestUpdate": "อัปเดตล่าสุด",
    "facebookLiveForm.header": "เพิ่มเฟซบุ๊กไลฟ์",
    "facebookLiveForm.header.edit": "แก้ไขเฟซบุ๊กไลฟ์",
    "facebookLiveForm.postId": "โพสต์ไอดี",
    "facebookLiveForm.title": "ชื่อเฟซบุ๊กไลฟ์",
    "facebookLiveForm.post": "เฟซบุ๊กไลฟ์ที่แนบ",
    "facebookLiveForm.cfCode.title": "CF code ของสินค้า",
    "facebookLiveForm.cfCode.description": "เลือกสินค้าพร้อมกับ CF code",
    "facebookLiveForm.productSelector.placeholder": "ค้นหาชื่อสินค้า",
    "facebookLiveForm.productSelector.browse": "ค้นหา",
    "facebookLive.toast.complete": "สำเร็จ",
    "facebookLive.toast.fail": "ไม่สำเร็จ",
    "facebookLiveForm.confirmDelete": "คุณยืนยันที่จะลบ",
    "facebookLive.table.placeholder": "ไม่มีรายการเฟซบุ๊กไลฟ์",
    "facebookLive.live": "ไลฟ์",
    "facebookLive.end": "ไลฟ์จบแล้ว",
  },
};

export default facebookAutoEngagement;
