import React, { FC } from "react";
import Typography from "components/Typography";
import { useTranslation } from "react-i18next";
import Modal from "components/Modal";
import Grid from "components/Grid";
import Button from "components/Button";
import { ModalBodyWrapper, ModalTextWrapper } from "./styled";

interface DetectChangeCategoryModalPropsType {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  prevCategoryName: string;
  selectCategoryName: string;
}

const DetectChangeCategoryModal: FC<DetectChangeCategoryModalPropsType> = ({
  isOpen,
  onCancel,
  onConfirm,
  prevCategoryName,
  selectCategoryName,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={() => {}}>
      <ModalBodyWrapper className="p-3">
        <ModalTextWrapper className="mt-3 mb-4">
          <Typography variant="title8" color="dark">
            {t("detectChangeCategory", { prevCategoryName, selectCategoryName })}
          </Typography>
        </ModalTextWrapper>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button color="ghost" fullWidth onClick={onCancel} size="medium">
              {t("Cancel")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth onClick={onConfirm} size="medium">
              {t("Confirm")}
            </Button>
          </Grid>
        </Grid>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default DetectChangeCategoryModal;
