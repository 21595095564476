import React, { FC } from "react";
import { useTranslation } from "react-i18next";
// import { Link, useParams } from "react-router-dom";

import Card from "components/Card";
import Grid from "components/Grid";
import Score from "components/Score";
import Typography from "components/Typography";

// import { PLAN, PROJECT, PLAN_UPGRADE } from "constants/Router";
import { PlanUsageDetailType } from "types/PlanUsage";
// import { ProjectIdType } from "types/Project";
import { ScoreComponentType } from "types/Score";
import { calculatePercentage, convertPriceFormat } from "utils/common";

import { UsageUpgradeTextWrapper } from "../styled";

type UsageQuotaCardPropsType = {
  details: PlanUsageDetailType[];
};

const UsageQuotaCard: FC<UsageQuotaCardPropsType> = (props) => {
  const { t } = useTranslation();
  const { details } = props;

  return (
    <Card noShadow className="position-relative">
      <UsageUpgradeTextWrapper>
        {/* <Tooltip title={t("planUsage.tooltip.contactSale")} placement="top-end" arrow>
          <div> */}
        {/* <Link to={`/${PROJECT}/${projectId}/${PLAN}/${PLAN_UPGRADE}`}>
          <Typography variant="body3" color="primary">
            {t("planUsage.label.upgrade")}
          </Typography>
        </Link> */}
        {/* </div>
        </Tooltip> */}
      </UsageUpgradeTextWrapper>
      {details.map(({ label, value, limit, lastUpdated }: PlanUsageDetailType, index: number) => {
        const percentage = calculatePercentage(value, limit);
        const score = Number(percentage) > 100 ? 100 : Number(percentage);

        if (limit === -1) {
          return null;
        }

        return (
          <Grid container key={label} className={index ? "pt-3" : ""}>
            <Grid item container justify="space-between" alignItems="center" xs={12} className="pb-1">
              <Grid item xs={12} sm="auto">
                <Typography variant="body3" color="darkGray">
                  {t(`planUsage.title.label.${label}`)}
                </Typography>
              </Grid>
              {Boolean(lastUpdated) && (
                <Grid item xs={12} sm="auto">
                  <Typography variant="body4" color="Gray">
                    {`${t("last updated")}: ${lastUpdated}`}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item container xs={12} alignItems="flex-end" className="pt-2">
              <Grid item container xs={7} alignItems="flex-end">
                <Typography variant="title4" color="darkGray">
                  {convertPriceFormat(value, 0)}
                </Typography>
                {Number(limit) >= 0 && (
                  <Typography variant="body4" color="darkGray" className="pl-2">
                    {percentage}
                    {t(`planUsage.suffix.percentage`)}
                  </Typography>
                )}
              </Grid>
              <Grid item container justify="flex-end" xs={5}>
                <Typography variant="body4" color="darkGray">
                  {Number(limit) >= 0
                    ? `${convertPriceFormat(limit, 0)} ${t(`planUsage.label.unit.${label}`)}`
                    : t("planUsage.label.unlimited")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} className="pt-2">
              <Score type={ScoreComponentType.USAGE} score={score} />
            </Grid>
          </Grid>
        );
      })}
    </Card>
  );
};

export default UsageQuotaCard;
