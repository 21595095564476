import React, { FC } from "react";
import Grid from "components/Grid";
import Typography from "components/Typography";
import COLORS from "constants/Colors";

type PlaceholderType = {
  message: string;
};

const Placeholder: FC<PlaceholderType> = ({ message }) => (
  <Grid container justify="center" className="mt-4">
    <Grid item xs={12}>
      <Typography variant="title2" color={COLORS.DarkLight} className="text-center">
        {message}
      </Typography>
    </Grid>
  </Grid>
);

export default Placeholder;
