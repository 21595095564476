import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { convertPriceFormat } from "utils/common";

import Hidden from "components/Hidden";
import { Typography } from "./styled";

type ShowingResultPropsType = {
  page: number;
  rowsPerPage: number;
  total: number;
};

const ShowingResult: FC<ShowingResultPropsType> = ({ page, rowsPerPage, total }) => {
  const { t } = useTranslation();
  const currentRow = useMemo(() => convertPriceFormat((page - 1) * rowsPerPage + 1, 0), [rowsPerPage, page]);
  const lastRow = rowsPerPage * page > total ? total : rowsPerPage * page;
  const lastRowText = useMemo(() => convertPriceFormat(rowsPerPage * page > total ? total : rowsPerPage * page, 0), [
    page,
    rowsPerPage,
    total,
  ]);
  const totalText = useMemo(() => convertPriceFormat(total, 0), [total]);

  return (
    <Typography color="darkGray">
      <Hidden only="xs">{t("Showing result")}: </Hidden>
      {total === 0 ? 0 : currentRow} - {`${total > lastRow ? lastRowText : totalText} `}
      {t("of")} {totalText}
    </Typography>
  );
};

export default ShowingResult;
