import * as React from "react";

const SvgIcAiResponse = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.007 1.778c6.652 0 12.234 4.578 13.767 10.755h1.24a1 1 0 011 1v4.857a1 1 0 01-1 1h-1.24c-1.533 6.177-7.115 10.755-13.767 10.755-6.651 0-12.233-4.578-13.766-10.756H1a1 1 0 01-1-1v-4.856a1 1 0 011-1h1.24C3.775 6.356 9.357 1.778 16.008 1.778zm4.115 8.752c.302.864.474 1.788.474 2.754a8.38 8.38 0 01-5.043 7.688c-.14.06-.34.154-.569.26a6.485 6.485 0 002.382 1.802c.796.347 2.894 1.399 5.773 2.725.077-.152-.897-2.63-.647-2.725 2.44-.943 3.997-3.213 3.997-5.987a6.521 6.521 0 00-6.367-6.517zm-7.905-4.308a7.06 7.06 0 00-7.061 7.062c0 3.002 1.683 5.458 4.325 6.48.27.1-.784 2.783-.701 2.948 3.116-1.435 5.388-2.574 6.248-2.948a7.062 7.062 0 004.25-6.48 7.06 7.06 0 00-7.06-7.062z"
    />
  </svg>
);

export default SvgIcAiResponse;
