import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { nanoid } from "nanoid";

import Button from "components/Button";
import CircularProgress from "components/CircularProgress";
import ConfirmationModal from "components/ConfirmationModal";
import Divider from "components/Divider";
import Grid from "components/Grid";
import { SvgIcon } from "components/Icon";
import { IcAdd } from "components/SvgIcons";
import { TableRecurringNotification } from "components/TableRecurringNotification";
import Typography from "components/Typography";

import COLORS from "constants/Colors";
import { BROADCAST, CAMPAIGNS, EDIT_RECURRING_REQUEST, FACEBOOK, MARKETING_TOOLS, PROJECT } from "constants/Router";

import { RECURRING_NOTIFICATION_OPT_INS } from "graphql/broadcast/query";
import { DELETE_RECURRING_NOTIFICATION_OPT_IN } from "graphql/broadcast/mutation";

import type { TableRecurringNotificationHeader } from "components/TableRecurringNotification";
import {
  RecurringNotificationOptInDeliveryContextType,
  RecurringNotificationOptInsResponseType,
  RecurringNotificationOptInsType,
} from "types/Broadcast";
import type { ProjectIdType } from "types/Project";
import { notifyWarn } from "utils/notify";

import type { BroadcastFacebookTableProps } from "./types";
import {
  mappingRecurringNotificationOptInsData,
  mappingRecurringNotificationOptInsSendingBroadcastIds,
} from "./helpers";
import { TableWrapper } from "./styled";

function BroadcastFacebookTable({ onClick }: BroadcastFacebookTableProps) {
  // Hooks
  const history = useHistory();
  const { projectId } = useParams<ProjectIdType>();
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);

  // Async hooks
  const [deleteRecurringNotificationOptIn] = useMutation<RecurringNotificationOptInsType>(
    DELETE_RECURRING_NOTIFICATION_OPT_IN,
  );

  const { data: broadcastData, loading: loadingBroadcastData, refetch: refetchBroadcastData } = useQuery<
    RecurringNotificationOptInsResponseType
  >(RECURRING_NOTIFICATION_OPT_INS, {
    variables: { projectId },
  });

  // Define variables
  const data = broadcastData?.recurringNotificationOptIns ?? [];
  const list = mappingRecurringNotificationOptInsData(data, t, i18n.language);
  const sendingIds = mappingRecurringNotificationOptInsSendingBroadcastIds(data);
  const isEmpty = list.length === 0;
  const headers: TableRecurringNotificationHeader[] | never[] = [
    t("broadcast.facebook.table.header.requestName"),
    t("broadcast.facebook.table.header.subscriber"),
    t("broadcast.facebook.table.header.requestDeliveryContext"),
    // t("broadcast.facebook.table.header.broadcastFrequency"),
    t("broadcast.facebook.table.header.createdAt"),
  ].map((header) => ({ id: nanoid(), header }));
  const totalRecurringNotificationOptIns = broadcastData?.recurringNotificationOptIns.length || 0;

  // Helper functions
  const closeConfirmModal = () => {
    setIsOpen(false);
    setDeleteId(null);
  };

  // Event handlers
  const handleClickSendBroadcast = (id: string) => {
    history.push(`/${PROJECT}/${projectId}/${MARKETING_TOOLS}/${BROADCAST}/${FACEBOOK}/${id}/${CAMPAIGNS}`);
  };

  const handleClickDelete = (id: string) => {
    setDeleteId(id);
    setIsOpen(true);
  };

  const handleCloseConfirmModal = () => {
    closeConfirmModal();
    setDeleteId(null);
  };

  const handleSubmitConfirmModal = async () => {
    closeConfirmModal();

    await deleteRecurringNotificationOptIn({
      variables: {
        projectId,
        recurringNotificationOptInId: deleteId,
      },
    });

    setDeleteId(null);
    await refetchBroadcastData();
  };

  const handleClickEdit = (id: string) => {
    history.push(
      `/${PROJECT}/${projectId}/${MARKETING_TOOLS}/${BROADCAST}/${FACEBOOK}/${id}/${EDIT_RECURRING_REQUEST}`,
    );
  };

  const handleClickCreate = () => {
    if (totalRecurringNotificationOptIns < Object.values(RecurringNotificationOptInDeliveryContextType).length) {
      onClick();
      return;
    }

    notifyWarn(
      <>
        <strong>{t("broadcast.rateLimitError.title")} </strong>
        <span>{t("broadcast.rateLimitError.subtitle")}</span>
      </>,
    );
  };

  // Conditional render
  if (loadingBroadcastData) {
    return (
      <Grid container item xs={12} justify="center">
        <CircularProgress className="p-4" />
      </Grid>
    );
  }

  return (
    <div className="h-100 d-flex flex-column">
      <div className="mx-4">
        <Divider margin="2px 0 0 0" className="my-4" />
        {!isEmpty && (
          <div className="d-flex flex-column flex-lg-row justify-content-between flex-wrap">
            <Typography variant="body1" className="mx-1 text-left">
              {t("broadcast.facebook.pageHeader")}
            </Typography>
            <div className="d-flex justify-content-end">
              <Button
                color="primary"
                className="mt-2 mt-lg-0 mx-lg-2 align-items-center"
                size="medium"
                onClick={handleClickCreate}
              >
                <SvgIcon component={IcAdd} fontSize="small" className="mr-2" />
                {t("broadcast.facebook.add")}
              </Button>
            </div>
          </div>
        )}
      </div>
      {isEmpty ? (
        <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
          <Button
            color="primary"
            className="mt-2 mt-lg-0 mx-lg-2 align-items-center"
            size="medium"
            onClick={handleClickCreate}
          >
            <SvgIcon component={IcAdd} fontSize="small" className="mr-2" />
            {t("broadcast.facebook.add")}
          </Button>
          <Typography variant="title2" className="mt-4" color={COLORS.DarkLight}>
            {t("broadcast.facebook.broadcastEmpty")}
          </Typography>
        </div>
      ) : (
        <div className="mx-4 my-4">
          <TableWrapper>
            <TableRecurringNotification
              headers={headers}
              list={list}
              sendingIds={sendingIds}
              onClickSendBroadcast={handleClickSendBroadcast}
              onClickEdit={handleClickEdit}
              onClickDelete={handleClickDelete}
            />
          </TableWrapper>
        </div>
      )}
      <ConfirmationModal
        isOpen={isOpen}
        title={t("broadcast.facebook.modal.delete.title")}
        subTitle={t("broadcast.facebook.modal.delete.subtitle")}
        textSubmit={t("Confirm")}
        onClose={handleCloseConfirmModal}
        onSubmit={handleSubmitConfirmModal}
        isHiddenCloseBtn
      />
    </div>
  );
}

export default BroadcastFacebookTable;
