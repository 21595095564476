import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { SvgIcon } from "components/Icon";
import { IcArrowBack, IcArrowNext } from "components/SvgIcons";
import { NavbarColumn, Button, PrevNextWrapper, NavbarWrapper } from "./index.styled";

export enum RangeType {
  Start = "start",
  End = "end",
  Default = "default",
}

export type HandleSelectEventTypes = {
  year: number;
  month: number;
};

type NavbarComponentPropsTypes = {
  month: number;
  year: number;
  minYear?: number;
  maxYear?: number;
  isNativeSize?: boolean;
  handleSelect: (value: HandleSelectEventTypes) => void;
};

export const Navbar: FC<NavbarComponentPropsTypes> = ({ year, month, handleSelect, isNativeSize = false }) => {
  const increaseMonth = () => {
    if (month >= 11) {
      handleSelect({ year: year + 1, month: 0 });
      return;
    }
    handleSelect({ year, month: month + 1 });
  };

  const decreaseMonth = () => {
    if (month <= 0) {
      handleSelect({ year: year - 1, month: 11 });
      return;
    }
    handleSelect({ year, month: month - 1 });
  };

  return (
    <NavbarWrapper container>
      <NavbarColumn item xs={6}>
        <PrevNextWrapper className="prev">
          <Button className={isNativeSize ? "" : "default"} onClick={() => decreaseMonth()}>
            <SvgIcon className="mx-2" component={IcArrowBack} fontSize="small" />
          </Button>
        </PrevNextWrapper>
      </NavbarColumn>
      <Grid item xs={6}>
        <PrevNextWrapper className="next">
          <Button className={isNativeSize ? "" : "default"} onClick={() => increaseMonth()}>
            <SvgIcon cassName="mx-2" component={IcArrowNext} fontSize="small" />
          </Button>
        </PrevNextWrapper>
      </Grid>
    </NavbarWrapper>
  );
};

export default Navbar;
