import React from "react";
import MuiButtonBase from "@material-ui/core/ButtonBase";
import styled from "styled-components";

const ButtonBase = styled(({ color: _color, ...rest }) => <MuiButtonBase {...rest} />).attrs(({ color }) => {
  return { color };
})`
  ${({ color }) => (color ? `color:${color};` : "")}
`;

export default ButtonBase;
