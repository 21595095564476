import React, { FC } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useFormContext, useFieldArray } from "react-hook-form";
import Colors from "constants/Colors";
import { RICH_MENU } from "constants/i18n";
import Button, { ButtonWithIcon } from "components/Button";
import Card from "components/Card";
import IcDrag from "components/SvgIcons/IcDrag";
import IcDelete from "components/SvgIcons/IcDelete";
import Typography from "components/Typography";
import IcAdd from "components/SvgIcons/IcAdd";
import { Action } from "types/RichMenu";
import Dropdown from "pages/LineRichMenu/components/Action/Dropdown";
import { DEFAULT_INSTAGRAM_ACTION } from "pages/LineRichMenu/config";
import { SortIcon } from "components/MessagesManager/styled";
import { Wrapper } from "./styled";

const MAX_LENGTH = 3;

const Action: FC = () => {
  const { t } = useTranslation(RICH_MENU);
  const { control } = useFormContext();
  const { fields, append, remove, move } = useFieldArray<Action>({
    control,
    name: "action",
  });

  const isDisabled = MAX_LENGTH <= fields?.length;

  const handleAddMenu = () => {
    if (!isDisabled) {
      append(DEFAULT_INSTAGRAM_ACTION[0]);
    }
  };

  const handleDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    // dropped at the same position
    if (result.source.index === result.destination.index) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  return (
    <Card noShadow padding="24px">
      <div className="d-flex justify-content-between mb-3">
        <Typography variant="title3">Action</Typography>
        <Typography variant="body3" color="darkMed">
          {fields?.length || 0}/{MAX_LENGTH}
        </Typography>
      </div>

      <div>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, { isDraggingOver }) => {
              return (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {fields?.map((action, index: number) => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <Draggable key={action?.id} draggableId={action?.id as string} index={index}>
                        {(dragProvided) => (
                          <Wrapper
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                            style={{
                              ...dragProvided.draggableProps.style,
                              boxShadow: isDraggingOver ? "0 0 8px  rgba(0, 0, 0, 0.08)" : "none",
                            }}
                          >
                            <SortIcon
                              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
                              {...(dragProvided.dragHandleProps as any)}
                              style={{ position: "relative", top: 38, left: 8 }}
                            >
                              <IcDrag color={Colors.DarkMed} />
                            </SortIcon>
                            <Dropdown name={`action[${index}]`} defaultValue={action as Action} />
                            <ButtonWithIcon
                              disabled={fields?.length === 1}
                              className="ml-2"
                              onClick={() => {
                                remove(index);
                              }}
                              style={{ position: "relative", top: 34 }}
                            >
                              <IcDelete />
                            </ButtonWithIcon>
                          </Wrapper>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </div>

      <div className="text-center">
        <Button type="button" color="secondary" onClick={handleAddMenu} disabled={isDisabled}>
          <IcAdd />
          {t("ADD_ACTION")}
        </Button>
      </div>
    </Card>
  );
};

export default Action;
