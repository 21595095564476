import * as React from "react";

const IcNewCustomer = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.359133,1.77777778 C23.2335187,1.77777778 28.8071548,7.35087284 28.8071548,14.2252585 C28.8071548,19.3395201 25.7235559,23.7330669 21.3137768,25.6484802 C19.7955142,26.3075122 15.7926251,28.3159907 10.3001506,30.8444444 C10.1546008,30.5544271 12.0115777,25.8264946 11.5348887,25.6484802 C6.87946058,23.8466931 3.91111111,19.5169935 3.91111111,14.2252585 C3.91111111,7.35087284 9.48420617,1.77777778 16.359133,1.77777778 Z M16.3592855,8.00165343 C14.757335,8.00165343 13.4583803,9.62071718 13.4583803,11.6175955 C13.4583803,13.1334836 14.2071477,14.4292141 15.2678395,14.9670006 L15.2678395,14.9670006 L15.1814464,15.4288934 C14.4264786,16.4418003 11.0243283,16.4407256 11.0243283,20.3144414 L11.0243283,20.3144414 L21.6938294,20.3146068 C21.6938294,16.5061198 18.4048582,16.4432884 17.5765596,15.4794892 L17.5765596,15.4794892 L17.4781789,14.9543517 C18.5247337,14.4084633 19.2601907,13.1204213 19.2601907,11.6175955 C19.2601907,9.62071718 17.961236,8.00165343 16.3592855,8.00165343 Z"
    />
  </svg>
);

export default IcNewCustomer;
