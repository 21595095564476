import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { NOTIFICATION_UPDATE } from "constants/Notification";
import { NOTIFICATION_CONFIGS } from "graphql/notification/query";
import { UPDATE_NOTIFICATION_CONFIG } from "graphql/notification/mutation";
import { NotificationConfigurationQueryType, NotificationConfigurationType } from "types/Notification";
import { notifyError } from "utils/notify";

type UseUpdateNotificationConfigurationType = {
  projectId: string;
  userId: string;
};

export const useUpdateNotificationConfiguration = (props: UseUpdateNotificationConfigurationType) => {
  const { projectId, userId } = props;
  const { t } = useTranslation();

  const [updateNotificationConfiguration] = useMutation(UPDATE_NOTIFICATION_CONFIG, {
    onError: () => notifyError(t(NOTIFICATION_UPDATE.FAIL)),
    update(cache, { data: { updateNotificationConfigurations: updatedNotificationConfigurations } }) {
      const cacheNotificationConfigs = cache.readQuery<NotificationConfigurationQueryType>({
        query: NOTIFICATION_CONFIGS,
        variables: {
          projectId,
          userId,
        },
      });

      if (cacheNotificationConfigs) {
        cache.writeQuery({
          query: NOTIFICATION_CONFIGS,
          variables: {
            projectId,
            userId,
          },
          data: {
            notificationConfigurations: updatedNotificationConfigurations,
          },
        });
      }
    },
  });

  const handleToggleNotificationConfig = (notificationConfigs: NotificationConfigurationType[]) => {
    const formattedNotificationConfigs = notificationConfigs.map(
      ({ name, type, isActive }: NotificationConfigurationType) => {
        return {
          name,
          type,
          isActive,
        };
      },
    );

    updateNotificationConfiguration({
      variables: {
        projectId,
        userId,
        notificationConfigurations: formattedNotificationConfigs,
      },
      optimisticResponse: {
        updateNotificationConfigurations: notificationConfigs,
      },
    });
  };

  return { toggleNotificationConfig: handleToggleNotificationConfig };
};
