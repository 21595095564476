import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import Divider from "components/Divider";
import Typography from "components/Typography";
import { EntityType, PaymentSummaryItemType, PaymentSummaryTypes } from "types/Billing";
import { convertPriceFormat, convertStringWithCommasToNumber } from "utils/common";
import useDevice from "utils/hooks/useDevice";
import { Device } from "types/Device";
import { SubscriptionType } from "types/PlanUsage";

type SummaryListPropsType = {
  type: PaymentSummaryTypes;
  itemsSummary: PaymentSummaryItemType[];
  isInvoiceSummary?: boolean;
};

const SummaryList: FC<SummaryListPropsType> = (props) => {
  const { t } = useTranslation();
  const { type, itemsSummary, isInvoiceSummary = false } = props;
  const device = useDevice();
  const isMobile = device === Device.MOBILE;

  return (
    <Grid item container>
      <Grid item xs={12} className="pt-3">
        <Typography variant={isInvoiceSummary ? "title8" : "title10"} color="darkLight">
          {t(`planBilling.summary.${type}.label.title`)} ({itemsSummary.length})
        </Typography>
        {!isInvoiceSummary && <Divider thickness="0.5px" className="mx-0 mt-3 mb-2 w-100" />}
      </Grid>
      {itemsSummary.map((itemSummary: PaymentSummaryItemType) => {
        const { name, price, amount, totalPrice, entityType, subscriptionType, imageSearchQuota } = itemSummary;

        const subscriptionTypeLabel =
          subscriptionType === SubscriptionType.ANNUALLY ? t("planUsage.subscriptionType.annually") : "";

        const itemLabelTranslationKey = `planBilling.summary.${type}.label.${entityType.toLowerCase()}`;
        const itemUnitTranslationKey = `planBilling.summary.${type}.unit.${entityType.toLowerCase()}`;

        let label = `${t(itemLabelTranslationKey, {
          count: amount,
        })}`;

        if (entityType === EntityType.IMAGE_SEARCH) {
          label = `${t(itemLabelTranslationKey, {
            count: imageSearchQuota || convertStringWithCommasToNumber(name.split(" ")[0]),
          })}`;
        }

        const labelNameWithSubscriptionType = [label, subscriptionTypeLabel].filter(Boolean).join(" - ");

        const pricePerItemLabel = amount > 1 ? `${convertPriceFormat(price)} / ${t(itemUnitTranslationKey)}` : "";

        return (
          <Grid
            key={name}
            container
            justify={isInvoiceSummary && !isMobile ? "flex-start" : "space-between"}
            alignItems="flex-start"
            className="pt-2"
          >
            <Grid item xs={isInvoiceSummary ? 6 : 7}>
              <Typography variant="body3" color="darkGray">
                {labelNameWithSubscriptionType}
              </Typography>
              <Typography variant="body3" color="darkLight">
                {pricePerItemLabel}
              </Typography>
            </Grid>
            <Grid item xs={5} className={isInvoiceSummary && !isMobile ? "text-left" : "text-right"}>
              <Typography
                variant={isInvoiceSummary ? "body3" : "title8"}
                color={isInvoiceSummary ? "darkGray" : "dark"}
              >
                {t("THB")} {convertPriceFormat(totalPrice)}
              </Typography>
            </Grid>
            {isInvoiceSummary && <Grid item xs={1} />}
          </Grid>
        );
      })}
    </Grid>
  );
};
SummaryList.displayName = "SummaryListMobile";

export default SummaryList;
