import { useLazyQuery } from "@apollo/client";
import { useEffect, useRef } from "react";
import isEqual from "lodash/isEqual";
import print from "print-js";
import get from "lodash/get";
import { PARCEL_INVOICES_BY_ORDER_IDS } from "graphql/salesReport/query";
import { ParcelInvoiceTemplateType } from "types/SalesReport";

type UsePrintParcelInvoiceDataType = {
  data: {
    parcelInvoicesByOrderIds: string;
  };
};

const usePrintParcelInvoice = () => {
  const previousSelectedOrderId = useRef<string[]>();
  const previousSelectedConsignmentType = useRef<ParcelInvoiceTemplateType>();
  const [getPrintParcelInvoice, { data, loading }] = useLazyQuery<UsePrintParcelInvoiceDataType>(
    PARCEL_INVOICES_BY_ORDER_IDS,
  );

  const url: string = get(data, "parcelInvoicesByOrderIds") || "";
  const decodedUrl = decodeURIComponent(url);

  useEffect(() => {
    if (!loading && decodedUrl) {
      print(decodedUrl);
    }
  }, [loading, decodedUrl]);

  const printParcelInvoice = async (projectId: string, selectedOrderIds: string[], type: ParcelInvoiceTemplateType) => {
    if (!selectedOrderIds.length) {
      return;
    }

    if (
      previousSelectedOrderId.current &&
      previousSelectedConsignmentType.current &&
      isEqual(previousSelectedOrderId.current, selectedOrderIds) &&
      isEqual(previousSelectedConsignmentType.current, type)
    ) {
      print(decodedUrl);
      return;
    }

    previousSelectedOrderId.current = selectedOrderIds;
    previousSelectedConsignmentType.current = type;

    getPrintParcelInvoice({
      variables: {
        projectId,
        orderIds: selectedOrderIds,
        type,
      },
    });
  };

  return { printParcelInvoice, loading };
};

export default usePrintParcelInvoice;
