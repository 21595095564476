import { OrderState as ORDER_STATES } from "types/Order";
import { METHOD_TYPE } from "types/Shipping";
import { TEXT as TITLE } from "constants/Chat";

export const getActionButtonsFlex = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lastOrderWithShippingAddress: any,
  orderState: string,
  shippingMethod: string,
  streetAddress: string,
  isShowTrackingNumberButton = true,
) => {
  if (!orderState || !shippingMethod) {
    return [];
  }
  let actionButtons: { isPrimary: boolean; value: string }[] = [];

  switch (orderState) {
    case ORDER_STATES.ABANDONED:
    case ORDER_STATES.CANCELLED:
    case ORDER_STATES.COD_REJECTED:
      actionButtons = [
        { isPrimary: false, value: TITLE.ASK_FOR_REORDER },
        { isPrimary: false, value: TITLE.ASK_FOR_MORE_DETAIL },
      ];
      break;
    case ORDER_STATES.PENDING_FOR_SHIPPING_COST:
      actionButtons = [{ isPrimary: false, value: TITLE.ASK_FOR_MORE_DETAIL }];
      break;
    case ORDER_STATES.COD_PICKED_UP:
      actionButtons = [{ isPrimary: false, value: TITLE.ASK_FOR_MORE_DETAIL }];
      if (isShowTrackingNumberButton) {
        actionButtons.push({ isPrimary: true, value: TITLE.ASK_FOR_TRACKING_NUMBER });
      }
      break;

    case ORDER_STATES.COMPLETED:
    case ORDER_STATES.SHIPPED:
      actionButtons = [
        { isPrimary: false, value: TITLE.ASK_FOR_REORDER },
        { isPrimary: false, value: TITLE.ASK_FOR_RECEIPT },
      ];
      if (isShowTrackingNumberButton) {
        actionButtons.push({ isPrimary: true, value: TITLE.ASK_FOR_TRACKING_NUMBER });
      }
      break;

    case ORDER_STATES.ORDER_PLACED:
      actionButtons = [
        { isPrimary: false, value: TITLE.CANCEL_ORDER },
        { isPrimary: true, value: TITLE.ASK_FOR_PAYMENT_METHOD },
      ];
      break;

    case ORDER_STATES.COD_PREPARE_TO_SHIP:
    case ORDER_STATES.PREPARE_TO_SHIPPING:
      if (shippingMethod === METHOD_TYPE.PICK_UP) {
        actionButtons = [
          { isPrimary: false, value: TITLE.ASK_FOR_SHOP_LOCATION },
          { isPrimary: true, value: TITLE.ASK_FOR_RECEIPT },
        ];
      } else {
        actionButtons = [
          { isPrimary: false, value: TITLE.ASK_FOR_MORE_DETAIL },
          { isPrimary: true, value: TITLE.ASK_FOR_RECEIPT },
        ];
      }
      break;

    case ORDER_STATES.WAIT_FOR_PAYMENT_APPROVE:
      actionButtons.push({ isPrimary: false, value: TITLE.ASK_FOR_MORE_DETAIL });
      if (shippingMethod === METHOD_TYPE.PICK_UP) {
        actionButtons.push({ isPrimary: true, value: TITLE.ASK_FOR_SHOP_LOCATION });
      } else if (!streetAddress) {
        // address unfilled
        actionButtons.push({ isPrimary: true, value: TITLE.ENTER_ADDRESS });
        if (lastOrderWithShippingAddress) {
          actionButtons.push({ isPrimary: false, value: TITLE.USE_OLD_ADDRESS });
          actionButtons.push({ isPrimary: false, value: TITLE.OLD_ADDRESS });
        }
      } else {
        actionButtons.push({ isPrimary: true, value: TITLE.ASK_FOR_ADDRESS_INFORMATION });
      }
      break;

    case ORDER_STATES.VOIDED:
      actionButtons = [
        { isPrimary: false, value: TITLE.ASK_FOR_REORDER },
        { isPrimary: false, value: TITLE.ASK_FOR_MORE_DETAIL },
        { isPrimary: false, value: TITLE.ASK_FOR_RECEIPT },
      ];
      if (isShowTrackingNumberButton) {
        actionButtons.push({ isPrimary: true, value: TITLE.ASK_FOR_TRACKING_NUMBER });
      }
      break;

    default:
      break;
  }

  return actionButtons;
};
