import React, { FC } from "react";
import Title from "./Title";
import Switcher, { SwitcherProps } from "./Switcher";

export type HeaderProps = SwitcherProps;

const Header: FC<HeaderProps> = ({
  isDeeplePay,
  onClickBankPaymentExampleLink,
  onClickDeeplePayExampleLink,
  onClickLearnMoreLink,
  onClickSwitch,
}) => {
  return (
    <>
      <Title />
      <Switcher
        isDeeplePay={isDeeplePay}
        onClickBankPaymentExampleLink={onClickBankPaymentExampleLink}
        onClickDeeplePayExampleLink={onClickDeeplePayExampleLink}
        onClickLearnMoreLink={onClickLearnMoreLink}
        onClickSwitch={onClickSwitch}
      />
    </>
  );
};

export default Header;
