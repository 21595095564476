import React, { FC } from "react";
import { useParams, Switch, Redirect, Route } from "react-router-dom";

import {
  PROJECT,
  PRODUCT_BRAND,
  PRODUCT_CATEGORY,
  PRODUCT_NAME,
  PRODUCT_PROPERTY,
  PRODUCT_SKU,
  SYNONYM,
  AI_SETTINGS,
} from "constants/Router";

import useGetUserWithPermission from "utils/hooks/useGetUserWithPermission";
import useTabWithLink from "utils/hooks/useTabWithLink";

import PrivateRoute from "routers/PrivateRoute";
import { NotFound } from "pages/NotFound";

import { TAB_CONFIGS } from "pages/Synonym/config";
import SynonymContainer from "domain/Synonym";
import { ProjectIdType } from "types/Project";

const SynonymSubRoute: FC = () => {
  const { projectId = "" } = useParams<ProjectIdType>();
  const { loading, isOwner } = useGetUserWithPermission(projectId);
  const { defaultTabPath, rootPath } = useTabWithLink(TAB_CONFIGS, `${AI_SETTINGS}/${SYNONYM}`, projectId);

  return (
    <>
      <Switch>
        <PrivateRoute exact loading={loading} path={`${rootPath}/${PRODUCT_NAME}`} isAllowed={isOwner} redirectTo="/">
          <SynonymContainer type="PRODUCT_NAME" />
        </PrivateRoute>

        <PrivateRoute exact loading={loading} path={`${rootPath}/${PRODUCT_BRAND}`} isAllowed={isOwner} redirectTo="/">
          <SynonymContainer type="PRODUCT_BRAND" />
        </PrivateRoute>

        <PrivateRoute
          exact
          loading={loading}
          path={`${rootPath}/${PRODUCT_CATEGORY}`}
          isAllowed={isOwner}
          redirectTo="/"
        >
          <SynonymContainer type="CATEGORY" />
        </PrivateRoute>

        <PrivateRoute exact loading={loading} path={`${rootPath}/${PRODUCT_SKU}`} isAllowed={isOwner} redirectTo="/">
          <SynonymContainer type="SKU_TYPE" />
        </PrivateRoute>

        <PrivateRoute
          exact
          loading={loading}
          path={`${rootPath}/${PRODUCT_PROPERTY}`}
          isAllowed={isOwner}
          redirectTo="/"
        >
          <SynonymContainer type="PROPERTY_TYPE" />
        </PrivateRoute>

        <PrivateRoute exact loading={loading} path={`${rootPath}`} isAllowed={isOwner} redirectTo="/">
          <Redirect
            to={defaultTabPath ? `/${PROJECT}/${projectId}/${AI_SETTINGS}/${SYNONYM}/${defaultTabPath}` : `/${PROJECT}`}
          />
        </PrivateRoute>

        <Route path={`${rootPath}/*`}>
          <NotFound />
        </Route>
      </Switch>
    </>
  );
};

export default SynonymSubRoute;
