import { useQuery } from "@apollo/client";
import { COMMENT_CRAWLER_BY_ID } from "graphql/facebookCrawler/query";

export const useFetchFacebookCrawler = (projectId: string, facebookCrawlerFormId: string) => {
  const { data: commentCrawlerGQLData, loading: isFacebookCrawlerLoading } = useQuery(COMMENT_CRAWLER_BY_ID, {
    skip: !projectId || !facebookCrawlerFormId,
    variables: {
      projectId,
      commentCrawlerId: facebookCrawlerFormId,
    },
    fetchPolicy: "network-only",
  });

  return { commentCrawlerGQLData, isFacebookCrawlerLoading };
};
