import React, { FC, memo, forwardRef, Ref } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import clsx from "clsx";

import { IS_USE_NEW_FREEMIUM_PACKAGE } from "config";
import { ORDERS, PROJECT, PLAN_UPGRADE } from "constants/Router";
import Typography from "components/Typography";
import { NavLink, LineSpacer, ProjectImg, ProjectMenu } from "components/SideNav/SideNav.styled";
import { PermissionType } from "types/User";
import { ButtonColors } from "types/Button";
import { ProjectIdType } from "types/Project";
import useCurrentPage from "routers/useCurrentPage";
import useIsDesktop from "utils/hooks/useIsDesktop";
import CreateProjectButton from "./CreateProjectButton";
import { ProjectName, Wrapper } from "./styled";
import ImagePlaceholder from "../SelectedProject/ImagePlaceholder";

type ProjectListPropsType = {
  permissions?: PermissionType[];
  isAllowedToCreateProject?: boolean;
  handleClick: Function;
  isBGWhite?: boolean;
};

const ProjectList: FC<ProjectListPropsType> = (
  { permissions = [], isAllowedToCreateProject = false, handleClick, isBGWhite = false },
  ref: Ref<HTMLDivElement>,
) => {
  const { t } = useTranslation();

  const isDesktop = useIsDesktop();
  const { currentPage } = useCurrentPage();
  const { projectId } = useParams<ProjectIdType>();
  const projects = permissions ? permissions.filter((permission) => permission.projectId !== projectId) : [];

  const createProjectButtonColor = isBGWhite ? ButtonColors.SECONDARY : ButtonColors.GHOST;

  const handleClickRedirect = (selectedProjectId: string, isInitialProjectCompleted: boolean) => {
    if (isInitialProjectCompleted || IS_USE_NEW_FREEMIUM_PACKAGE) {
      // history.push is not working, we want to clear all project cache
      window.location.href = `/${PROJECT}/${selectedProjectId}/${currentPage === "/" ? ORDERS : currentPage}`;
    } else {
      window.location.href = `/${PROJECT}/${selectedProjectId}/${PLAN_UPGRADE}`;
    }
    handleClick(selectedProjectId);
  };

  if (projects && projects.length > 0) {
    return (
      <Wrapper isDesktop={isDesktop}>
        {projects.map((project, index) => {
          const isLast = index + 1 !== projects.length;

          return (
            <div key={project.projectId} ref={ref}>
              <ProjectMenu
                onClick={() => {
                  handleClickRedirect(project.projectId, project.isInitialProjectCompleted);
                }}
                className={clsx({ isBGWhite })}
              >
                {project.projectLogo ? <ProjectImg src={project.projectLogo} /> : <ImagePlaceholder />}
                <ProjectName>{project.projectName}</ProjectName>
              </ProjectMenu>
              {isLast && !isBGWhite && <LineSpacer />}
            </div>
          );
        })}
        {isAllowedToCreateProject && (
          <div className="d-flex justify-content-center">
            <NavLink to={`/${PROJECT}`} className={clsx("p-0 w-100", [{ isBGWhite }])} onClick={() => handleClick("")}>
              <CreateProjectButton color={createProjectButtonColor} label={t("Create project")} />
            </NavLink>
          </div>
        )}
      </Wrapper>
    );
  }
  return (
    <>
      <Typography color="gray" variant="body3" className="m-2 text-center">
        {t("projectList.noMoreProject")}
      </Typography>
      {isAllowedToCreateProject && (
        <div className="d-flex justify-content-center" ref={ref}>
          <NavLink to={`/${PROJECT}`} className={clsx("p-0 w-100", [{ isBGWhite }])} onClick={() => handleClick("")}>
            <CreateProjectButton color={createProjectButtonColor} label={t("Create project")} />
          </NavLink>
        </div>
      )}
    </>
  );
};

export default memo(forwardRef(ProjectList));
