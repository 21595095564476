import * as React from "react";

const SvgIcTax = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.45,7.62c.56,0,.91.42.91,1.1s-.33,1.1-.91,1.1-.91-.39-.91-1.09S17.87,7.62,18.45,7.62Zm0-1a2.1,2.1,0,0,0-2.06,2.14,2.06,2.06,0,1,0,4.11,0A2.06,2.06,0,0,0,18.45,6.6Zm-4.74,5.87H7.57a.62.62,0,0,0,0,1.21h6.14a.62.62,0,0,0,0-1.21Zm9.68-.25c.56,0,.91.42.91,1.1s-.33,1.1-.91,1.1-.91-.39-.91-1.09S22.81,12.22,23.39,12.22Zm.54-5.55H23a.13.13,0,0,0-.1.06l-5.14,8.42a.12.12,0,0,0,.1.19h.9a.13.13,0,0,0,.1-.06L24,6.86A.13.13,0,0,0,23.93,6.67Zm-.54,4.53a2.1,2.1,0,0,0-2.06,2.14,2.06,2.06,0,1,0,2.06-2.14Zm-1.25,6.62H7.57a.62.62,0,0,0,0,1.21H22.14a.62.62,0,0,0,0-1.21ZM22,22.62H7.68a.61.61,0,0,0-.6.61.6.6,0,0,0,.6.6H22a.6.6,0,0,0,.61-.6A.61.61,0,0,0,22,22.62ZM18.69.35a1.34,1.34,0,0,1,1.81,0L23.36,2.9a.15.15,0,0,0,.2,0L25.66,1a1.35,1.35,0,0,1,2.25,1V29.94a1.36,1.36,0,0,1-2.25,1l-2.1-1.86a.15.15,0,0,0-.2,0L20.5,31.65a1.36,1.36,0,0,1-1.81,0L15.83,29.1a.15.15,0,0,0-.2,0l-2.85,2.55a1.37,1.37,0,0,1-1.82,0L8.11,29.1a.15.15,0,0,0-.2,0L5.81,31a1.39,1.39,0,0,1-.91.34,1.37,1.37,0,0,1-1.35-1.36V2.06A1.36,1.36,0,0,1,5.8,1L7.9,2.9a.17.17,0,0,0,.21,0L11,.35a1.35,1.35,0,0,1,1.82,0L15.63,2.9a.15.15,0,0,0,.2,0Z"
    />
  </svg>
);

export default SvgIcTax;
