import { CategoryType } from "types/Category";
import { ProductPropertiesType } from "types/Product";

export const getSuggesionPropertyListFromSelectedCategory = (categoryData: CategoryType) => {
  const propertyLits: ProductPropertiesType[] = [];
  categoryData.properties.forEach((property: { name: string }) => {
    propertyLits.push({ name: property.name, value: "" });
  });
  return propertyLits;
};
