import * as React from "react";

const SvgIcFood = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.707 2.15c1.478-.5 3.082.29 3.584 1.767l.395 1.3c.332 1.097.402 2.256.204 3.385-.181.95-.784 1.765-1.637 2.217l.444 9.934c.051.54-.128 1.074-.492 1.474-.043.048-.089.093-.136.136-.876.802-2.236.74-3.037-.136-.372-.411-.552-.963-.492-1.515l.442-9.891c-.855-.452-1.458-1.269-1.64-2.219-.198-1.129-.128-2.288.204-3.385l.395-1.3c.283-.831.935-1.484 1.766-1.766zm-5.553-.147c.34 0 .616.276.616.616v5.539c0 .163-.065.32-.18.435l-1.654 1.653.525 10.499c.004.067.004.135 0 .202-.056 1.147-1.03 2.031-2.177 1.975-1.147-.055-2.031-1.03-1.976-2.177l.525-10.499L4.18 8.593C4.065 8.477 4 8.32 4 8.158v-5.54c0-.339.276-.615.615-.615.34 0 .616.276.616.616v5.284l1.666 1.666c.123.123.189.292.18.466l-.542 10.77c-.013.232.071.459.233.627l.005.005c.34.337.889.335 1.225-.005.162-.168.246-.395.233-.627l-.538-10.77c-.009-.174.057-.343.18-.466l1.666-1.666V2.619c0-.34.275-.616.615-.616zm7.016 1.33c-.827-.305-1.745.117-2.05.944l-.396 1.3c-.276.911-.334 1.874-.169 2.81.133.683.62 1.244 1.277 1.471.25.094.41.338.398.604l-.464 10.348c-.03.216.035.435.181.598.37.367.968.367 1.338 0 .139-.15.206-.354.185-.557l-.468-10.39c-.012-.265.149-.51.398-.603.657-.227 1.144-.788 1.277-1.47.164-.937.106-1.9-.17-2.81l-.394-1.301c-.162-.437-.506-.782-.943-.943zM6.462 2.004c.34 0 .615.276.615.616v4.923c0 .34-.275.616-.615.616-.34 0-.616-.276-.616-.616V2.62c0-.34.276-.616.616-.616zm1.846 0c.34 0 .615.276.615.616v4.923c0 .34-.275.616-.615.616-.34 0-.615-.276-.615-.616V2.62c0-.34.275-.616.615-.616z"
    />
  </svg>
);

export default SvgIcFood;
