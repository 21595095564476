import * as React from "react";

const SvgIcPrint = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M29.511 7.244h-3.394V1.071c0-.59-.477-1.067-1.067-1.067H6.95c-.59 0-1.067.478-1.067 1.067v6.173H2.489C1.117 7.244 0 8.361 0 9.734v10.73c0 1.373 1.117 2.49 2.489 2.49h3.394v5.725c0 .59.478 1.067 1.067 1.067h18.1c.59 0 1.067-.478 1.067-1.067v-5.726h3.394c1.373 0 2.489-1.116 2.489-2.488V9.733c0-1.372-1.116-2.489-2.489-2.489zM8.016 2.137h15.968v5.107H8.016V2.137zm15.968 25.476H8.016v-8.727h15.968v8.727zm-3.362-3.748c.59 0 1.067.477 1.067 1.066 0 .59-.478 1.067-1.067 1.067h-9.244c-.59 0-1.067-.478-1.067-1.067s.478-1.066 1.067-1.066zm0-3.365c.59 0 1.067.478 1.067 1.067 0 .59-.478 1.067-1.067 1.067h-9.244c-.59 0-1.067-.478-1.067-1.067 0-.59.478-1.067 1.067-1.067zm4.428-9.126c.59 0 1.067.478 1.067 1.067 0 .59-.477 1.067-1.067 1.067h-2.715c-.589 0-1.066-.478-1.066-1.067 0-.59.477-1.067 1.066-1.067h2.715z"
    />
  </svg>
);

export default SvgIcPrint;
