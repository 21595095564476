const richMenu = {
  en: {
    "RichMenu.title.line": "LINE rich menu",
    "RichMenu.title.facebook": "Facebook persistent menu",
    "RichMenu.title.instagram": "Instagram persistent menu",
    "RichMenu.action.setActive": "Set as active",
    "RichMenu.action.setInactive": "Set as inactive",
    "RichMenu.deleteModal.title": "Do you want to delete this menu?",
    FACEBOOK_REMARK: "The update of page level persistent menu can take up to 24 hours",
    NOTE: "Note",
    ACTION_TYPE: "Action type",
    ACTION: "Action",
    CANCEL: "Cancel",
    COLLAPSED: "Collapsed",
    CONTACT_ADMIN: "Contact admin",
    DEEPLE_ACTION: "1Convo present action",
    DEFAULT_BEHAVIOR: "Default behavior",
    DEFAULT_TEMPLATE: "Default Template",
    DESCRIPTION: "Description",
    ENTER_DESCRIPTION: "Enter description",
    ENTER_TEXT: "Enter text",
    ENTER_URL: "Enter URL",
    HEIGHT: "height",
    IMAGE_ASPECT_RATIO: "Image aspect ratio",
    IMAGE_FORMAT: "Image format",
    LINE_RICH_MENU: "Line rich menu",
    LINK: "Link",
    MAX_FILE_SIZE: "Max file size",
    MAX_IMAGE_HEIGHT_SIZE: "Image height size",
    MAX_IMAGE_WIDTH_SIZE: "Image width size",
    MENU_BAR_LABEL: "Menu bar label",
    MENU_BAR_LABEL_MAX_CHAR_EXCEEDED: "Menu bar label cannot be longer than 14 characters",
    MESSAGE: "Message",
    NO_SELECTED_TEMPLATE: "No selected template",
    OR: "or",
    ORDER_HISTORY: "Order history",
    PRODUCT_CATALOG: "Product catalog",
    RICH_MENU_DETAIL: "Rich menu detail",
    DETAIL: "Detail",
    SAVE: "Save",
    SELECT_TEMPLATE: "Select template",
    SELECT: "Select",
    SHOPPING_CART: "Shopping cart",
    SHOWN: "Shown",
    TEMPLATE: "Template",
    TEXT: "Text",
    TITLE_FOR_MANAGEMENT_PURPOSE: "Titles are only for management purposes. Users won't see them",
    TITLE: "Title",
    TOP_SELLING_PRODUCTS: "Top selling products",
    UPLOAD_BACKGROUND: "Upload background",
    URL: "URL",
    WIDTH: "width",
    FORM_IS_REQUIRED: "{{name}} is required",
    FORM_MAX_LENGTHS: "{{name}} must be at most {{length}} characters",
    FORM_VALID_URL_IS_REQUIRED: "{{name}} must be valid HTTP/HTTPS url",
    THIS_FIELD: "This field",
    PREVIEW: "Preview",
    ADD_ACTION: "Add action",
    PLEASE_SELECT_ACTION: "Please select action",
    IMAGE: "background image",
    ACTIVATE_CONFIRMATION: "Do you want to activate this rich menu",
    ACTIVATE_FACEBOOK_MENU_CONFIRMATION: "Do you want to activate this persistent menu",
    ACTIVATE_INSTAGRAM_MENU_CONFIRMATION: "Do you want to activate this persistent menu",
    LATER: "Later",
    ACTIVATE_NOW: "Activate now",
    Default: "Default",
    ADD_NEW: "Add",
    INSTAGRAM_PERSISTENT_MENU: "Instagram persistent menu",
    REMOVE_CHAT_MENU_INSTAGRAM_CREDENTIALS_NOT_CONFIGURED: "Cannot remove chat menu from IG since insufficient data",
    ACTIVATE_CHAT_MENU_INSTAGRAM_CREDENTIALS_NOT_CONFIGURED: "Cannot active chat menu from IG since insufficient data",
    ACTIVATE_CHAT_MENU_FACEBOOK_CREDENTIALS_NOT_CONFIGURED: "Cannot remove chat menu from FB since insufficient data",
    ACTIVATE_CHAT_MENU_LINE_CREDENTIALS_NOT_CONFIGURED: "Cannot remove chat menu from LINE since insufficient data",
    DEACTIVATE_CHAT_MENU_INSTAGRAM_CREDENTIALS_NOT_CONFIGURED:
      "Cannot deactive chat menu from IG since insufficient data",
    DEACTIVATE_CHAT_MENU_FACEBOOK_CREDENTIALS_NOT_CONFIGURED:
      "Cannot deactive chat menu from FB since insufficient data",
    DEACTIVATE_CHAT_MENU_LINE_CREDENTIALS_NOT_CONFIGURED: "Cannot deactive chat menu from LINE since insufficient data",
    REMOVE_CHAT_MENU_DEFAULT_CHAT_MENU_CANNOT_BE_DELETED: "Cannot remove default chat menu",
    REMOVE_CHAT_MENU_FACEBOOK_CREDENTIALS_NOT_CONFIGURED: "Cannot deactive chat menu from FB since insufficient data",
    REMOVE_CHAT_MENU_LINE_CREDENTIALS_NOT_CONFIGURED: "Cannot deactive chat menu from LINE since insufficient data",
    REMOVE_CHAT_MENU_FAIL_TO_DEACTIVATE_CHAT_MENU: "Canot remove chat menu from LINE",
    persistent_menu: "Default",
  },
  th: {
    "RichMenu.title.line": "LINE rich menu",
    "RichMenu.title.facebook": "Facebook persistent menu",
    "RichMenu.action.setActive": "เปิดการใช้งาน",
    "RichMenu.action.setInactive": "ปิดการใช้งาน",
    "RichMenu.deleteModal.title": "คุณต้องการลบเมนู?",
    FACEBOOK_REMARK: "การอัปเดตเมนูของเพจอาจใช้เวลาประมาณ 24 ชั่วโมง",
    NOTE: "หมายเหตุ",
    ACTION_TYPE: "ประเภทของ Action",
    ACTION: "Action",
    CANCEL: "ยกเลิก",
    COLLAPSED: "ซ่อน",
    CONTACT_ADMIN: "ติดต่อแอดมิน",
    DEEPLE_ACTION: "ชุดข้อความของ 1Convo",
    DEFAULT_BEHAVIOR: "ค่าเริ่มต้นของ rich menu",
    DEFAULT_TEMPLATE: "ค่าเริ่มต้นของรูปแบบ",
    DESCRIPTION: "รายละเอียด",
    ENTER_DESCRIPTION: "ใส่รายละเอียด",
    ENTER_TEXT: "ใส่ข้อความ",
    ENTER_URL: "ใส่ URL",
    HEIGHT: "ความสูง",
    IMAGE_ASPECT_RATIO: "อัตราส่วนของรูปภาพ",
    IMAGE_FORMAT: "ประเภทรูปที่รองรับ",
    LINE_RICH_MENU: "Line rich menu",
    LINK: "ลิงก์",
    MAX_FILE_SIZE: "ขนาดไฟล์ใหญ่สุดที่รองรับได้",
    MAX_IMAGE_HEIGHT_SIZE: "ความสูง",
    MAX_IMAGE_WIDTH_SIZE: "ความกว้าง",
    MENU_BAR_LABEL: "ชื่อแท็บเมนู",
    MENU_BAR_LABEL_MAX_CHAR_EXCEEDED: "ชื่อแท็บเมนูยาวได้ไม่เกิน 14 ตัวอักษร",
    MESSAGE: "ข้อความ",
    NO_SELECTED_TEMPLATE: "ยังไม่ได้เลือกรูปแบบ",
    OR: "หรือ",
    ORDER_HISTORY: "ประวัติการสั่งซื้อ",
    PRODUCT_CATALOG: "ดูเมนูสินค้า",
    RICH_MENU_DETAIL: "รายละเอียดของ rich menu",
    DETAIL: "รายละเอียด",
    SAVE: "บันทึก",
    SELECT_TEMPLATE: "เลือกรูปแบบ",
    SELECT: "เลือก",
    SHOPPING_CART: "รายการสั่งซื้อ",
    SHOWN: "แสดง",
    TEMPLATE: "รูปแบบ",
    TEXT: "ข้อความ",
    TITLE_FOR_MANAGEMENT_PURPOSE: "ชื่อที่ใช้สำหรับการจัดการเท่านั้น (ไม่แสดงให้ผู้ใช้เห็น)",
    TITLE: "ชื่อ",
    TOP_SELLING_PRODUCTS: "สินค้าขายดี",
    UPLOAD_BACKGROUND: "อัปโหลดพื้นหลัง",
    URL: "URL",
    WIDTH: "ความกว้าง",
    FORM_IS_REQUIRED: "กรุณาใส่ {{name}}",
    FORM_MAX_LENGTHS: "รองรับ {{name}} สูงสุด {{length}} ตัวอักษร",
    FORM_VALID_URL_IS_REQUIRED: "กรุณาใส่ {{name}} ให้ถูกฟอแมตลิงก์ (http/https url)",
    THIS_FIELD: "ข้อมูล",
    PREVIEW: "ตัวอย่าง",
    ADD_ACTION: "เพิ่ม action",
    PLEASE_SELECT_ACTION: "กรุณาเลือก Action",
    IMAGE: "รูปพื้นหลัง",
    ACTIVATE_CONFIRMATION: "คุณต้องการที่จะเปิดใช้ rich menu นี้ไหม",
    ACTIVATE_FACEBOOK_MENU_CONFIRMATION: "คุณต้องการที่จะเปิดใช้ persistent menu นี้ไหม",
    ACTIVATE_INSTAGRAM_MENU_CONFIRMATION: "คุณต้องการที่จะเปิดใช้ persistent menu นี้ไหม",
    LATER: "ไว้ทีหลัง",
    ACTIVATE_NOW: "เปิดใช้งานทันที",
    Default: "ค่าเริ่มต้น",
    ADD_NEW: "สร้างใหม่",
    INSTAGRAM_PERSISTENT_MENU: "Instagram persistent menu",
    REMOVE_CHAT_MENU_INSTAGRAM_CREDENTIALS_NOT_CONFIGURED: "ระบบไม่สามารถลบ chat menu จาก IG ได้เพราะว่าไม่มีข้อมูล",
    ACTIVATE_CHAT_MENU_INSTAGRAM_CREDENTIALS_NOT_CONFIGURED:
      "ระบบไม่สามารถเปิดใช้ chat menu จาก IG ได้เพราะว่าไม่มีข้อมูล",
    ACTIVATE_CHAT_MENU_FACEBOOK_CREDENTIALS_NOT_CONFIGURED:
      "ระบบไม่สามารถเปิดใช้ chat menu จาก FB ได้เพราะว่าไม่มีข้อมูล",
    ACTIVATE_CHAT_MENU_LINE_CREDENTIALS_NOT_CONFIGURED:
      "ระบบไม่สามารถเปิดใช้ chat menu จาก LINE ได้เพราะว่าไม่มีข้อมูล",
    DEACTIVATE_CHAT_MENU_INSTAGRAM_CREDENTIALS_NOT_CONFIGURED:
      "ระบบไม่สามารถปิดการใช้งาน chat menu จาก IG ได้เพราะว่าไม่มีข้อมูล",
    DEACTIVATE_CHAT_MENU_FACEBOOK_CREDENTIALS_NOT_CONFIGURED:
      "ระบบไม่สามารถปิดการใช้งาน chat menu จาก FB ได้เพราะว่าไม่มีข้อมูล",
    DEACTIVATE_CHAT_MENU_LINE_CREDENTIALS_NOT_CONFIGURED:
      "ระบบไม่สามารถปิดการใช้งาน chat menu จาก LINE ได้เพราะว่าไม่มีข้อมูล",
    REMOVE_CHAT_MENU_DEFAULT_CHAT_MENU_CANNOT_BE_DELETED: "ระบบไม่สามารถลบ chat menu เริ่มต้นได้",
    REMOVE_CHAT_MENU_FACEBOOK_CREDENTIALS_NOT_CONFIGURED: "ระบบไม่สามารถลบ chat menu จาก FB ได้เพราะว่าไม่มีข้อมูล",
    REMOVE_CHAT_MENU_LINE_CREDENTIALS_NOT_CONFIGURED: "ระบบไม่สามารถลบ chat menu จาก LINE ได้เพราะว่าไม่มีข้อมูล",
    REMOVE_CHAT_MENU_FAIL_TO_DEACTIVATE_CHAT_MENU: "ระบบไม่สามารถลบ chat menu จาก LINE ได้",
    persistent_menu: "ค่าเริ่มต้น",
  },
};

export default richMenu;
