import styled from "styled-components";

import Grid from "components/Grid";

export const SummaryBox = styled.div`
  border: solid 1px ${({ theme }) => theme.COLORS.LightBlue};
  border-radius: 8px;
  padding: 16px;
  max-height: 40vh;
  overflow: auto;
  margin: 8px 0 16px 0;
`;

export const Wrapper = styled(Grid)`
  flex-basis: content;
`;
