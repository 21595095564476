import React, { FC, useState, createContext } from "react";

type VideoPopupStateType = {
  isShowVideo: boolean;
  videoLink: string;
  width: number;
  height: number;
  x: number;
  y: number;
};

type VideoPopupStateContextType = {
  videoProps: VideoPopupStateType;
  setVideoProps: (videoProps: VideoPopupStateType) => void;
};

const defaultState: VideoPopupStateType = {
  isShowVideo: false,
  width: 430,
  height: 300,
  videoLink: "",
  x: window.innerWidth - 450,
  y: window.innerHeight - 320,
};

export const VideoPopupContext = createContext<VideoPopupStateContextType>({
  videoProps: defaultState,
  setVideoProps: () => {},
});

export const VideoPopupContextProvider: FC = ({ children }) => {
  const [videoProps, setVideoProps] = useState(defaultState);

  return (
    <VideoPopupContext.Provider
      value={{
        videoProps,
        setVideoProps,
      }}
    >
      {children}
    </VideoPopupContext.Provider>
  );
};
