const customer = {
  en: {
    "customer.ContentNotFound": "Content not found",
    "customer.LatestOrders": "Latest orders",
    "customer.LatestOrders.ShowAll": "Show all",
    "customer.Detail.title": "Customer detail",
    customerTag: "Customer tag",
    "customer.displayName.error": "Display name cannot contain special character",
    "customerReportDetail.orderTotal.title": "Total orders ( all statuses )",
    "customerReportDetail.totalSpend.title": "Total spend",
    "customerReportDetail.firstTimeChat.title": "First-time chat",
    "customerReportDetail.lastMessagedAt.title": "Last customer response",
    customers: "Customers",
    totalSpend: "Total spend",
    tags: "Tags",
    "customers.reachLimit": "Selected customers have reach {{limit}} limit",
    ALL_PLATFORM: "All platform",
    CUSTOMER_REPORT: "Customer report",
    SEARCH_BY_CUSTOMER_NAME: "Search by customer name",
    SEARCH_BY_LOCATION: "Search by province, postal code",
    EXPORT_CUSTOMER: "Export customer",
    EXPORT_CUSTOMER_SUCCESS: "Export customers are success",
    FIRST_NAME: "First name",
    LAST_NAME: "Last name",
    CREATED_AT: "Created at",
    DATA_NOT_FOUND: "Data not found",
    LAST_MESSAGE_AT: "Last message at",
    PHONE_NUMBER: "Phone number",

    "customer.title.mr": "Mr.",
    "customer.title.miss": "Miss",
    "customer.title.mrs": "Mrs.",
  },
  th: {
    "customer.ContentNotFound": "ไม่พบหน้าดังกล่าว",
    "customer.LatestOrders": "รายการสั่งซื้อล่าสุด",
    "customer.LatestOrders.ShowAll": "แสดงทั้งหมด",
    "customer.Detail.title": "ข้อมูลลูกค้า",
    customerTag: "แท็กลูกค้า",
    "customer.displayName.error": "ชื่อเล่นไม่สามารถมีอักขระพิเศษได้",
    "customerReportDetail.orderTotal.title": "รายการสั่งซื้อทั้งหมด ( ทุกสถานะ )",
    "customerReportDetail.totalSpend.title": "ยอดชำระทั้งหมด",
    "customerReportDetail.firstTimeChat.title": "สนทนาครั้งแรกเมื่อ",
    "customerReportDetail.lastMessagedAt.title": "ลูกค้าสนทนาครั้งล่าสุดเมื่อ",
    customers: "ลูกค้า",
    totalSpend: "ยอดชำระ",
    tags: "แท็ก",
    "customers.reachLimit": "เลือกลูกค้าได้ไม่เกิน {{limit}}",
    ALL_PLATFORM: "ทุกช่องทางแชท",
    CUSTOMER_REPORT: "รายงานลูกค้า",
    SEARCH_BY_CUSTOMER_NAME: "ค้นหาชื่อลูกค้า",
    SEARCH_BY_LOCATION: "ค้นหาจังหวัด, รหัสไปรษณีย์",
    EXPORT_CUSTOMER: "นำออกข้อมูลลูกค้า",
    EXPORT_CUSTOMER_SUCCESS: "นำออกข้อมูลลูกค้าสำเร็จ",
    FIRST_NAME: "ชื่อ",
    LAST_NAME: "นามสกุล",
    CREATED_AT: "ถูกสร้างเมื่อ",
    DATA_NOT_FOUND: "ไม่พบข้อมูล",
    LAST_MESSAGE_AT: "ข้อความล่าสุดเมื่อ",
    PHONE_NUMBER: "หมายเลขโทรศัพท์",

    "customer.title.mr": "นาย",
    "customer.title.miss": "น.ส.",
    "customer.title.mrs": "นาง",
  },
};

export default customer;
