import styled from "styled-components";
import Grid from "components/Grid";

export const ConfigWrapper = styled(Grid)`
  background-color: ${({ theme }) => theme.COLORS.White};
  border-style: solid;
  border-color: ${({ theme }) => theme.COLORS.LightBlue};
  border-width: 0.5px 0;
  padding: 24px;
  margin: 8px 0 32px;
`;
