import React, { useState, useContext } from "react";
import { Route, Switch, useParams, useHistory } from "react-router-dom";
import {
  PageGetStartedAccountSettings,
  PageGetStartedProductsIndex,
  PageGetStartedNewProducts,
  PageGetStartedConnect,
  LayoutGetStarted,
} from "@1convo/deeple-ui";
import { Pages } from "@1convo/web-console";

import { PROJECT, START_PAGE } from "constants/Router";
import { MultiStoreInventoryType, ProjectIdType } from "types/Project";
import useGetUserWithPermission from "utils/hooks/useGetUserWithPermission";
import PrivateRoute from "routers/PrivateRoute";
import NotFound from "pages/NotFound";
import ProfileSettingForm from "domain/Authentication/ProfileSettingForm/index";
import useGetProject from "utils/hooks/useGetProject";
import { notifySuccess } from "utils/notify";
import { VideoPopupContext } from "utils/context/VideoPopupContext";
import { GUIDELINE_VIDEO_LINK } from "constants/Video";
import { VideoPopupContextProvider } from "utils/context";
import VideoPopup from "components/VideoPopup";

export default function GetStartedSubRoute() {
  const { projectId = "" } = useParams<ProjectIdType>();
  const { loading, currentUser } = useGetUserWithPermission(projectId);
  const project = useGetProject(projectId);
  const { isGuidelineCompleted } = project;
  const history = useHistory();
  const { videoProps, setVideoProps } = useContext(VideoPopupContext);

  const { multiStoreInventoryType } = useGetProject(projectId);
  const isClientStore = multiStoreInventoryType === MultiStoreInventoryType.CHILD;
  const isMasterStore = multiStoreInventoryType === MultiStoreInventoryType.MASTER;
  const [isOpenModalSuccessfully, setIsOpenModalSuccessfully] = useState(false);

  const rootPath = `/${PROJECT}/:projectId/get-started`;

  const commonProps = {
    projectId,
    project,
    user: currentUser,
    permissions: currentUser?.permissions,
  };

  const handleAddProductSuccessfully = async () => {
    setIsOpenModalSuccessfully(true);
  };

  const handleClickWatchVideo = (variant: "facebook" | "instagram" | "line") => {
    setVideoProps({
      ...videoProps,
      isShowVideo: true,
      videoLink: GUIDELINE_VIDEO_LINK[variant],
    });
  };

  return (
    <>
      <LayoutGetStarted
        projectId={projectId}
        navigate={history.push}
        user={currentUser}
        permissions={currentUser?.permissions}
        project={project}
      >
        <VideoPopupContextProvider>
          <Switch>
            <PrivateRoute exact loading={loading} path={rootPath} redirectTo="/" isAllowed={!isGuidelineCompleted}>
              <PageGetStartedConnect
                projectId={projectId}
                history={history}
                onClickWatchVideo={handleClickWatchVideo}
              />
            </PrivateRoute>
            <PrivateRoute
              exact
              loading={loading}
              path={`${rootPath}/account-settings`}
              redirectTo="/"
              isAllowed={!isGuidelineCompleted}
            >
              <PageGetStartedAccountSettings
                projectId={projectId}
                project={project}
                user={currentUser}
                permissions={currentUser?.permissions}
                onClickBack={() => history.goBack()}
              >
                <ProfileSettingForm />
              </PageGetStartedAccountSettings>
            </PrivateRoute>
            <PrivateRoute
              exact
              loading={loading}
              path={`${rootPath}/account-settings`}
              redirectTo="/"
              isAllowed={!isGuidelineCompleted}
            >
              <PageGetStartedAccountSettings {...commonProps} onBack={() => history.goBack()}>
                <ProfileSettingForm />
              </PageGetStartedAccountSettings>
            </PrivateRoute>
            <PrivateRoute
              exact
              loading={loading}
              path={`${rootPath}/products`}
              redirectTo="/"
              isAllowed={!isGuidelineCompleted}
            >
              <PageGetStartedProductsIndex {...commonProps} history={history} />
            </PrivateRoute>
            <PrivateRoute
              exact
              loading={loading}
              path={`${rootPath}/products/new`}
              redirectTo="/"
              isAllowed={!isGuidelineCompleted}
            >
              <PageGetStartedNewProducts
                {...commonProps}
                isOpenModalSuccessfully={isOpenModalSuccessfully}
                handleCloseModalSuccessfully={() => {
                  setIsOpenModalSuccessfully(false);
                  history.replace(`/${PROJECT}/${projectId}/${START_PAGE}#storeSetupSuccessfully`);
                }}
                history={history}
              >
                <Pages.FormProduct
                  projectId={projectId}
                  refetchGuideline={handleAddProductSuccessfully}
                  refetchProducts={async () => {}}
                  closeProductForm={() => {}}
                  onClickClose={() => {
                    history.push(`/${PROJECT}/${projectId}/get-started/products`);
                  }}
                  notifySuccess={notifySuccess}
                  isClientStore={isClientStore}
                  isMasterStore={isMasterStore}
                  selectedProductId=""
                  isEditMode={false}
                />
              </PageGetStartedNewProducts>
            </PrivateRoute>

            <Route path={`${rootPath}/*`}>
              <NotFound />
            </Route>
          </Switch>
        </VideoPopupContextProvider>
      </LayoutGetStarted>
      <VideoPopup videoProps={videoProps} setVideoProps={setVideoProps} />
    </>
  );
}
