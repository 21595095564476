import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import moment from "moment";
import Button from "components/Button";
import Grid from "components/Grid";
import Typography from "components/Typography";
import Card from "components/Card";
import Divider from "components/Divider";
import { PROJECT, PLAN, BILLING } from "constants/Router";
import { DATE_PICKER_ORDER_FORMAT } from "constants/DateTimeFormat";
import { PlanCardPropsType, PackagePricingType, ChangePackageSummary, SubscriptionType } from "types/PlanUsage";
import { BillingError } from "types/Billing";
import { ProjectIdType } from "types/Project";
import { convertPriceFormat } from "utils/common";
import Snackbar from "components/Snackbar";
import { CardHeader } from "./styled";

type ConfirmModalPropsType = {
  changePackageSummary?: ChangePackageSummary;
  type: PackagePricingType;
  selectedPackage: PlanCardPropsType[];
  totalPrice: number;
  onCancel: () => void;
  onConfirm: () => void;
  error: BillingError | null;
  isShowCancelButton?: boolean;
  isDisableConfirm?: boolean;
  confirmText?: string;
  additionalDescription?: string;
  isShowConfirmButton?: boolean;
  isShowUpgradeText?: boolean;
  isLoadingChangePackage?: boolean;
};

const ConfirmModal: FC<ConfirmModalPropsType> = (props) => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const {
    type,
    onCancel,
    onConfirm,
    changePackageSummary,
    error,
    isShowCancelButton = true,
    isDisableConfirm: isDisableConfirmProps,
    confirmText,
    additionalDescription = "",
    isShowConfirmButton = true,
    isShowUpgradeText = true,
    isLoadingChangePackage,
  } = props;
  const totalPrice = changePackageSummary?.totalPrice || 0;
  const totalCredit = changePackageSummary?.totalDiscount || 0;
  const newPackagePrice = changePackageSummary?.monthlyBillingSummary?.newPackage?.package || 0;

  const nextBillingDate = moment(changePackageSummary?.invoiceDateInfo?.endBillingDate).format(
    DATE_PICKER_ORDER_FORMAT,
  );
  const newPackageSubscriptionType = changePackageSummary?.newPackages[0]?.subscriptionType;
  const isSubscriptionAnnually = newPackageSubscriptionType === SubscriptionType.ANNUALLY;
  const isDisableConfirm =
    error === BillingError["BILLING:REQUIRE_CREDIT_CARD_TO_UPGRADE_PLAN"] || isDisableConfirmProps;
  return (
    <Grid container justify="center">
      {error && (
        <Grid item xs={12}>
          <Snackbar
            description={t(error)}
            suffix={t("billing.planUpgrade.snackbar.suffix")}
            suffixLink={`/${PROJECT}/${projectId}/${PLAN}/${BILLING}`}
          />
        </Grid>
      )}
      <Grid item xs={12} className="py-3">
        <Card noShadow padding="0px" fullWidth>
          <Grid container>
            <CardHeader item xs={12} className="p-4">
              <Typography variant="title7" color="white">
                {t("planUpgrade.confirmModal.paymentSummary.title")}
              </Typography>
            </CardHeader>
          </Grid>
          <Grid item container xs={12} className="px-4 py-3">
            {isShowUpgradeText && (
              <Grid item xs={12} className="pb-3">
                <Typography variant="body4">{t("planUpgrade.confirmModal.upgradingTo")}</Typography>
              </Grid>
            )}
            <Grid item container xs={12} alignItems="center" justify="space-between">
              <Grid item>
                <Typography variant="title8" color="dark">
                  {t(`planUpgrade.card.title.plan.${type}`)}
                  {isSubscriptionAnnually ? ` - ${t("planUsage.subscriptionType.annually")}` : ""}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="title8" color="dark">
                  {t("THB")} {convertPriceFormat(newPackagePrice)}
                </Typography>
              </Grid>
            </Grid>

            {totalCredit !== 0 && (
              <>
                <Divider thickness="0.5px" line="dashed" className="w-100 mx-0 my-3 p-0" />
                <Grid item container xs={12} alignItems="center" justify="space-between">
                  <Grid item>
                    <Typography variant="body3">{t("planUpgrade.confirmModal.credit")}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body3">
                      - {t("THB")} {convertPriceFormat(totalCredit)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} className="pt-1">
                  <Typography variant="body4" color="darkLight">
                    {t("planUpgrade.confirmModal.creditDescription")}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Divider thickness="0.5px" className="w-100 m-0 p-0" />
          <Grid item container xs={12} alignItems="center" justify="space-between" className="px-4 py-3">
            <Grid item>
              <Typography variant="title8" color="dark">
                {t("planUpgrade.confirmModal.dueToday")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="title8" color="dark">
                {t("THB")} {convertPriceFormat(totalPrice)}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} className="text-center pb-1">
        <Typography variant="body3">
          {t("planUpgrade.confirmModal.nextBillingDate")} {nextBillingDate} {additionalDescription}
        </Typography>
      </Grid>
      {isShowConfirmButton && (
        <Grid item container xs={12} className="pt-3">
          {isShowCancelButton && (
            <Grid item xs={6} className="pr-2">
              <Button color="ghost" fullWidth onClick={onCancel}>
                {t("Cancel")}
              </Button>
            </Grid>
          )}

          <Grid item xs={isShowCancelButton ? 6 : 12} className={isShowCancelButton ? "pl-2" : ""}>
            <Button fullWidth onClick={onConfirm} disabled={isDisableConfirm || isLoadingChangePackage}>
              {t(confirmText || "Confirm")}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ConfirmModal;
