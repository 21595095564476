import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Container from "components/Container";
import Grid from "components/Grid";
import Typography from "components/Typography";
import { ProjectIdType } from "types/Project";

import ProfileSettingForm from "./form";

const Layout: FC = () => {
  const { projectId } = useParams<ProjectIdType>();
  const { t } = useTranslation();

  return (
    <Container>
      <Grid container justify="center">
        <Grid item>
          <Typography className="mt-5" variant="title1" color="dark">
            {t("Account settings")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className="mt-3">
        <Grid item xs={12}>
          <ProfileSettingForm projectId={projectId} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Layout;
