import { Grid, Step, StepLabel, MenuItem, Select as DefaultSelect } from "@material-ui/core";
import styled from "styled-components";

import Button from "components/Button";
import DefaultModal from "components/Modal";
import Typography from "components/Typography";
import COLORS from "constants/Colors";
import { SvgIcon } from "components/Icon";

export const Modal = styled(DefaultModal)`
  max-width: 640px;
  max-height: 676px;

  &.MuiDialog-paperScrollPaper {
    max-height: 650px !important;
  }
`;

export const StepperItem = styled(StepLabel)`
  span {
    margin-top: 4px !important;
  }
`;

export const ModalWrapper = styled(Grid)`
  width: 100%;
  height: 620px;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${COLORS.Light};

  .MuiPaper-root {
    background-color: transparent;
  }

  span {
    margin-top: 0;
  }

  .MuiStepConnector-alternativeLabel {
    top: 17px;
    left: calc(-50% + 15px);
    right: calc(50% + 15px);
    position: absolute;
  }
`;

export const StepItem = styled(Step)`
  .MuiStepIcon-root {
    color: ${COLORS.White};
    border: 1px solid ${COLORS.Primary};
    border-radius: 50%;
  }

  .MuiStepIcon-root.MuiStepIcon-active {
    color: ${COLORS.LightGray};
    border: 1px solid ${COLORS.Primary};
    border-radius: 50%;
  }

  .MuiStepIcon-root.MuiStepIcon-completed {
    color: ${COLORS.Primary};
    border: none;
  }

  .MuiStepIcon-text {
    fill: ${COLORS.Primary};
  }

  .MuiStepLabel-label {
    color: ${COLORS.Primary};
  }
`;

export const DetailWrapper = styled.div`
  width: 100%;
  height: 440px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${COLORS.White};
  border: 0.5px solid #cad2e4;
  box-sizing: border-box;
  border-radius: 8px;

  &.info {
    height: calc(440px - 106px);
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ConfirmationDetailWrapper = styled(Grid)`
  max-width: 95%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  border-top: 1px solid ${COLORS.LightBlue};

  &.desktop {
    height: 100%;
    width: 80%;
    max-width: auto;
  }

  img {
    max-width: 100px;
  }

  @media only screen and (max-width: 600px) {
    display: block;

    img {
      padding-bottom: 24px;
    }
  }
`;

export const TextWrapper = styled(Grid)`
  padding: 0 20px;
  text-align: left;

  &.mobile {
    padding: 0;
  }

  &.text {
    width: 100%;
    line-break: anywhere;
  }
`;

export const SuccessWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 48px);
`;

export const UploadBox = styled(Grid)`
  text-align: center;

  .title {
    display: inline-flex;
  }

  &.MuiGrid-item {
    width: 100%;
  }

  label {
    box-sizing: border-box;
    width: 100%;
    height: 250px;
  }

  span {
    color: ${COLORS.Accent};
  }
`;

export const ImageWrapper = styled(Grid)`
  background-color: ${COLORS.Light};
  border: 1px dashed ${COLORS.LightBlue};
  box-sizing: border-box;
  border-radius: 8px;
  max-height: 160px;

  .d-flex {
    margin: 10px 0;
  }

  img {
    max-height: 140px;
  }
`;

export const MobileWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  height: calc(100vh - 138px);
`;

export const MobileFooter = styled.div`
  position: fixed;
  bottom: 0;
  background-color: ${COLORS.Light};
  border-top: 1px solid ${COLORS.LightBlue};
  width: 100%;
`;

export const MobileDetail = styled(Grid)`
  overflow: hidden;
`;

export const KYCButton = styled(Button)`
  :hover {
    background-color: ${COLORS.Primary};
    color: ${COLORS.White};
  }

  &.cancel {
    background-color: transparent;
    border: 1px solid ${COLORS.Primary};
    color: ${COLORS.Primary};
  }

  @media only screen and (max-width: 400px) {
    height: 42px;
    width: 140px;
  }
`;

export const BrowseButton = styled(Button)`
  background-color: ${COLORS.Primary} !important ;
  height: 36px;
  width: 82px;

  :hover {
    background-color: ${COLORS.Primary};
    color: ${COLORS.White};
  }

  &.cancel {
    background-color: transparent;
    border: 1px solid ${COLORS.Primary};
    color: ${COLORS.Primary};
  }

  @media only screen and (max-width: 400px) {
    height: 42px;
    width: 140px;
  }
`;

export const SelectItem = styled(MenuItem)`
  img {
    width: 16px;
    height: 16px;
  }

  img + div {
    padding-left: 12px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Select = styled(DefaultSelect)`
  line-height: 26px !important;

  .MuiSelect-selectMenu {
    border-radius: 8px;
    border: 1px solid ${COLORS.LightBlue} !important;
    display: flex !important;
    padding: 10px !important;
    align-items: center;

    :focus {
      background-color: transparent;
    }
  }

  &.MuiInput-underline:before,
  &.MuiInput-underline:after,
  &.MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
  }

  img {
    width: 16px;
    height: 16px;
  }

  img + div {
    width: 100%;
    padding: 0 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Detail = styled.div`
  margin-left: 24px;
`;

export const Item = styled.div`
  width: 100%;
  display: inline-flex;

  @media only screen and (max-width: 600px) {
    width: auto;
  }

  img {
    width: 16px;
    height: 16px;
    padding-bottom: 0;
  }

  img + div {
    padding-left: 12px;
  }
`;

export const Label = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ExampleText = styled.span`
  cursor: pointer;
`;

export const ExampleSideBar = styled(Grid)`
  box-sizing: border-box;
  width: 20%;
  padding: 24px 8px;
  background-color: ${(props) => props.theme.COLORS.LightGray};
  justify-content: center;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
`;

export const ExampleImageBox = styled.div`
  width: 100%;
  height: auto;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 8px;

  img {
    width: 100%;
  }

  &.selected {
    border: 1px solid ${(props) => props.theme.COLORS.Primary};
  }
`;

export const ExampleDescription = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 80%;
  justify-content: center;
  text-align: left;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 16px 24px;
`;

export const CloseIcon = styled(SvgIcon)`
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
`;

export const ExampleImageWrapper = styled.div`
  width: 100%;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 350px;
  background-color: ${(props) => props.theme.COLORS.LightWhite};
  border: 1px solid #cad2e4;
  border-radius: 8px;

  img {
    width: inherit;
  }
`;

export const MobileImageCountBox = styled.div`
  text-align: center;
`;

export const PersonalInformationBox = styled.div`
  margin: 20px 0;
  border-radius: 5px;
  width: -webkit-fill-available;
  background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  height: 64px;
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.COLORS.Accent};
  color: 1px solid ${(props) => props.theme.COLORS.Accent};
  border-radius: 5px;
`;
