const sideNavMenu = {
  en: {
    Orders: "Orders",
    Chat: "Chat",
    "Category & Products Inventory": "Catalog & Product",
    "AI Settings": "AI Settings",
    "Store Settings": "Store Settings",
    "Help Center": "Help Center",
    Settings: "Settings",
    "Log out": "Logout",
    ModeManualReply: "Admin mode",
    ModeAutoReply: "AI Sales mode",
    BotSettingTooltip: "System Settings",
    BotTogglerOnTooltip: "‘Active’ auto reply for all customers",
    BotTogglerOffTooltip: "‘Inactive’ auto reply for all customers",
    startPage: "Start page",
  },
  th: {
    Orders: "รายการสั่งซื้อ",
    Chat: "บทสนทนา",
    "Category & Products Inventory": "เมนูสินค้า & คลังสินค้า",
    "AI Settings": "ตั้งค่าบอท",
    "Store Settings": "ตั้งค่าร้านค้า",
    "Help Center": "ความช่วยเหลือ",
    Settings: "ตั้งค่าทั่วไป",
    "Log out": "ออกจากระบบ",
    ModeManualReply: "Admin mode",
    ModeAutoReply: "AI Sales mode",
    BotSettingTooltip: "ตั้งค่าใช้งานระบบ",
    BotTogglerOnTooltip: "‘เปิด’ โหมดตอบแชทอัตโนมัติลูกค้าทุกคน",
    BotTogglerOffTooltip: "‘ปิด’ โหมดตอบแชทอัตโนมัติลูกค้าทุกคน",
    startPage: "หน้าเริ่มต้น",
  },
};

export default sideNavMenu;
