import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import Typography from "components/Typography";
import Switch from "components/Switch";
import { SvgIcon } from "components/Icon";

import COLORS from "constants/Colors";
import { NotificationName } from "types/Notification";
import { Device } from "types/Device";
import useDevice from "utils/hooks/useDevice";

import { mappedIconWithNotificationName } from "./utils";

type ConfigItemPropsType = {
  type: NotificationName;
  onToggleSwitch: () => void;
  isActive: boolean;
  isSelectable: boolean;
};

const ConfigItem: FC<ConfigItemPropsType> = (props) => {
  const { type, onToggleSwitch, isActive, isSelectable } = props;
  const { t } = useTranslation();
  const device = useDevice();
  const isDesktop = device === Device.DESKTOP;

  const icon = mappedIconWithNotificationName[type];

  const RenderSwitchToggle = () => (
    <Grid item xs={12} md={4} container alignItems="center" justify={isDesktop ? "flex-end" : "flex-start"}>
      <Grid item className="pr-1">
        <Switch checked={isActive} onClick={onToggleSwitch} disabled={!isSelectable} />
      </Grid>
      <Grid item className="pl-2" style={{ width: "100px" }}>
        <Typography variant="body3">{t(isActive ? "Active" : "Disabled")}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <Grid container direction="row" alignItems="center">
      {!isDesktop && <RenderSwitchToggle />}
      <Grid item xs={12} md={8} container alignItems="center" className={`flex-nowrap ${isDesktop ? "" : "mt-3"}`}>
        <Grid item className="pl-2 pr-3 mr-1">
          <SvgIcon component={icon} fontSize="inherit" htmlColor={COLORS.Primary} />
        </Grid>
        <Grid item>
          <Typography variant="title4" color="dark">
            {t(`notification.configuration.name.${type}`)}
          </Typography>
          <Typography variant="body3" className="pt-1">
            {t(`notification.configuration.description.${type}`)}
          </Typography>
        </Grid>
      </Grid>
      {isDesktop && <RenderSwitchToggle />}
    </Grid>
  );
};

export default ConfigItem;
