import React, { FC, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { PROJECT } from "constants/Router";

import { IMPERSONATE } from "graphql/impersonate/query";
import { ImpersonateType } from "types/Impersonate";
import { getParameterByName } from "utils/common";
import useLogout from "utils/hooks/useLogout";
import { setToken } from "utils/tokenUtil";

export const Impersonate: FC = ({ children }) => {
  const location = useLocation();
  const { push } = useHistory();
  const { logout } = useLogout();
  const email = getParameterByName("email", location.search);
  const { data } = useQuery<ImpersonateType>(IMPERSONATE, {
    variables: { email },
    skip: !email,
  });

  useEffect(() => {
    const impersonate = async () => {
      if (!data || !data.impersonate.token) {
        return;
      }

      await logout();
      setToken(data.impersonate.token);
      setTimeout(() => {
        push(`/${PROJECT}`);
        window.location.reload();
      }, 100);
    };

    impersonate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return <>{children}</>;
};
