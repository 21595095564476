import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";

import Typography from "components/Typography";
import Grid from "components/Grid";
import Card from "components/Card";
import { RadioBox } from "components/Radio";

import COLORS from "constants/Colors";
import { SHIPPOP_ADVANCE_SETTING_OPTIONS } from "constants/Shippop";
import { SHIPPOP_CALCULATE } from "types/Shipping";

import FlatRate from "../FlatRate";
import { PostalCodeBasedShipping, PostalCodeBasedShippingProps } from "../../PostalCodeBasedShipping";

type AdvanceSettingProps = PostalCodeBasedShippingProps & {
  shippopCalculateType: SHIPPOP_CALCULATE;
  onChangeShippopSettingType: (newType: SHIPPOP_CALCULATE) => void;
};

const CardStyledProps = { noBorder: true, noShadow: true, bgColor: COLORS.LightWhite };

const AdvanceSetting: FC<AdvanceSettingProps> = (props) => {
  const { t } = useTranslation();
  const {
    fixedPrice,
    settingsPostalCode,
    setSettingsPostalCode,
    errors,
    shippopCalculateType,
    onChangeShippopSettingType,
  } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card padding="24px">
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="title7" color="dark">
                {t("shipping.shippop.advancedSetting")}
              </Typography>
            </Grid>
            <Grid item container spacing={2} className="pt-3">
              {SHIPPOP_ADVANCE_SETTING_OPTIONS.map(({ title, description, value }) => {
                return (
                  <Grid item xs={12} sm={4} key={title}>
                    <RadioBox
                      isActive={value === shippopCalculateType}
                      title={t(title)}
                      description={t(description)}
                      onClick={() => onChangeShippopSettingType(value)}
                      className="h-100"
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {shippopCalculateType !== SHIPPOP_CALCULATE.SHIPPOP_BASE_PRICE && (
        <Grid item xs={12}>
          <FlatRate fixedPrice={fixedPrice} cardProps={CardStyledProps} />
        </Grid>
      )}
      {shippopCalculateType === SHIPPOP_CALCULATE.POSTAL_CODE_BASED_PRICE && (
        <PostalCodeBasedShipping
          fixedPrice={fixedPrice}
          settingsPostalCode={settingsPostalCode}
          setSettingsPostalCode={setSettingsPostalCode}
          errors={errors}
          cardProps={CardStyledProps}
        />
      )}
    </Grid>
  );
};

export default memo(AdvanceSetting);
