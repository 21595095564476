/* eslint-disable @typescript-eslint/no-explicit-any */
import produce from "immer";
import { OptionType } from "domain/Product/ProductListWithSearch/ProductSelector/Option/type";

const mergeSelectedOptions = (options: OptionType<any>[], selectedOptions: OptionType<any>[]): OptionType<any>[] => {
  return produce(options, (draft) => {
    const updateIsCheckedOptions = (options1: OptionType<any>[], options2: OptionType<any>[]) => {
      for (let index = 0; index < options1.length; index += 1) {
        const option = options1[index];
        if (option.value != null) {
          const foundIndex = options2.findIndex((selectedOption) => selectedOption.value.id === option.value.id);
          if (foundIndex >= 0) {
            // eslint-disable-next-line no-param-reassign
            option.isChecked = options2[foundIndex].isChecked;
            if (option?.options?.length && options2[foundIndex]?.options?.length) {
              updateIsCheckedOptions(
                option.options as OptionType<any>[],
                options2[foundIndex].options as OptionType<any>[],
              );
            }
          }
        }
      }
    };

    draft.forEach((option) => {
      if (option.options?.length) {
        updateIsCheckedOptions(option.options, selectedOptions);
      }
    });
  });
};

export default mergeSelectedOptions;
