import { useMutation } from "@apollo/client";
import { UPDATE_COMMENT_CRAWLER } from "graphql/facebookCrawler/mutation";
import { toast } from "react-toastify";

export const useUpdateFacebookCrawler = (t: Function, setIsSubmittingSaveDraft: Function, closeFrom: Function) => {
  const [updateCommentCrawler, { loading: updateCommentCrawlerLoading }] = useMutation(UPDATE_COMMENT_CRAWLER, {
    onError: () => {
      toast.error(`${t("facebookAutoEngagement.toast.fail")}`, {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 2000,
      });
      setIsSubmittingSaveDraft(false);
    },
    onCompleted: () => {
      toast.success(t("facebookAutoEngagement.toast.complete"), {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 3000,
      });
      closeFrom(false);
      setIsSubmittingSaveDraft(false);
    },
  });
  return { updateCommentCrawler, updateCommentCrawlerLoading };
};
