import { IcCategories, IcProduct as IcProducts, IcProductInventory } from "components/SvgIcons";
import { PRODUCTS, INVENTORY, CATEGORIES, CONTENT_MANAGEMENT, HIGHLIGHT_PRODUCT } from "constants/Router";
import { HeaderType } from "types/Tab";

export const TAB_CONFIGS_WITH_HIGHLIGHT_PRODUCT: ReadonlyArray<HeaderType> = [
  {
    id: PRODUCTS,
    title: "Products",
    svgIcon: IcProducts,
  },
  {
    id: CATEGORIES,
    title: "Categories",
    svgIcon: IcCategories,
  },
  {
    id: INVENTORY,
    title: "Inventory",
    svgIcon: IcProductInventory,
  },
  {
    id: HIGHLIGHT_PRODUCT,
    title: "HighlightProduct.title",
    svgIcon: IcProducts,
  },
];

export const TAB_CONFIGS_WITH_HIGHLIGHT_PRODUCT_AND_CONTENT_MANAGEMENT: ReadonlyArray<HeaderType> = [
  {
    id: PRODUCTS,
    title: "Products",
    svgIcon: IcProducts,
  },
  {
    id: CATEGORIES,
    title: "Categories",
    svgIcon: IcCategories,
  },
  {
    id: INVENTORY,
    title: "Inventory",
    svgIcon: IcProductInventory,
  },
  {
    id: CONTENT_MANAGEMENT,
    title: "ContentManagement",
    svgIcon: IcProducts,
  },
  {
    id: HIGHLIGHT_PRODUCT,
    title: "HighlightProduct.title",
    svgIcon: IcProducts,
  },
];
