import React, { FC, useState, createContext } from "react";

type CurrentPageContextType = {
  currentPage: string;
  setCurrentPage: (_currentPage: string) => void;
};

export const CurrentPageContext = createContext<CurrentPageContextType>({
  currentPage: "",
  setCurrentPage: () => {},
});

export const CurrentPageContextProvider: FC = ({ children }) => {
  const [currentPage, setCurrentPage] = useState<string>("");

  return (
    <CurrentPageContext.Provider
      value={{
        currentPage,
        setCurrentPage: (currentPageInput: string) => {
          setCurrentPage(currentPageInput);
        },
      }}
    >
      {children}
    </CurrentPageContext.Provider>
  );
};
