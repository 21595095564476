import styled from "styled-components";
import { Link } from "react-router-dom";
import Grid from "components/Grid";

export const UnreadItemBadge = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${({ theme }) => theme.COLORS.Primary};
  border: 1px solid ${({ theme }) => theme.COLORS.Primary};
  box-shadow: inset 0 0 0 2px ${({ theme }) => theme.COLORS.White};
  border-radius: 12px;
  &:hover {
    cursor: pointer;
  }
`;

export const LinkWrapper = styled(Link)`
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`;

export const GridItem = styled(Grid)`
  border-radius: 12px;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.COLORS.PrimaryNude};
  }
`;
