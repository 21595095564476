import React, { FC, memo } from "react";
import { Gender } from "types/AISetting";
import NewBotSettingForm from "./NewBotSettingForm";

type BotSettingFormPropsType = {
  botGender?: Gender;
  botTimeToResume: number;
  isBotTimerActive: boolean;
  searchProductsOrder?: string;
};

const BotSettingForm: FC<BotSettingFormPropsType> = (props) => {
  return <NewBotSettingForm {...props} />;
};

export default memo(BotSettingForm);
