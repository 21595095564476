import React from "react";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import get from "lodash/get";

import AutoCompleteAddress from "components/AutoCompleteAddress";
import Button from "components/Button";
import Grid from "components/Grid";
import Modal from "components/Modal";
import TextField from "components/TextField";
import Typography from "components/Typography";
import Select from "components/Select";

import { ShippopSenderInformation } from "types/Shipping";
import { updateDeliveryAddressSchema } from "../SenderInformation/validateSchema";
import useAddressInformation, { DEFAULT_STORE_SENDER_ADDRESS } from "./hooks/useAddressInformation";

type AddressFormPropsType = {
  data?: ShippopSenderInformation;
  projectId: string;
  isOpen: boolean;
  onClose: () => void;
  onSaveAddress: (addressInfo: ShippopSenderInformation) => void;
};

type AddressFormSchema = Omit<ShippopSenderInformation, "isActive">;

const AddressForm: React.FC<AddressFormPropsType> = (props) => {
  const { t } = useTranslation();

  const { projectId, isOpen, onClose, data, onSaveAddress } = props;

  const { register, errors, handleSubmit, setValue } = useForm<AddressFormSchema>({
    resolver: yupResolver(updateDeliveryAddressSchema),
  });

  const handleChangeAddress = (senderInformation: ShippopSenderInformation) => {
    const { name, phoneNumber, address, subDistrict, district, province, postalCode } = senderInformation;

    setValue("name", name);
    setValue("phoneNumber", phoneNumber);
    setValue("address", address);
    setValue("subDistrict", subDistrict);
    setValue("district", district);
    setValue("province", province);
    setValue("postalCode", postalCode);
  };

  const {
    addressFillingOptions,
    selectedAddressFillingOption,
    onSelectAddressFillingOption,
    senderAddress,
    handleClearSenderAddress,
  } = useAddressInformation({ projectId, onChangeAddress: handleChangeAddress });

  const handleClose = () => {
    handleClearSenderAddress();
    onClose();
  };

  const handleClickConfirm: SubmitHandler<AddressFormSchema> = (dataForm: AddressFormSchema, event) => {
    if (event) {
      event.preventDefault();
    }

    const addressInformation = {
      ...dataForm,
      isActive: false,
    };

    onSaveAddress(addressInformation);
    handleClose();
  };

  const defaultAddress = data || DEFAULT_STORE_SENDER_ADDRESS;

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <form name="updateDeliveryAddressForm" onSubmit={handleSubmit(handleClickConfirm)}>
        <Grid container className="p-4" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="title4" color="dark" className="pb-3">
              {t("shipping.shippop.address.title")}
            </Typography>
          </Grid>
          {/* Address filling selection */}
          <Grid item xs={12} className="mb-3">
            <Typography variant="body3" className="mb-2">
              {t("shipping.shippop.addressModal.select.label")}
            </Typography>
            <Select
              placeholder={t("shipping.shippop.addressModal.placeholder.addressFilling")}
              value={selectedAddressFillingOption}
              options={addressFillingOptions}
              onChange={onSelectAddressFillingOption}
              fullWidth
            />
          </Grid>

          {/* Address filling form */}
          <Grid container spacing={1}>
            <Grid container item xs={12}>
              <Typography variant="body3">{t("Name")}</Typography>
              <TextField
                className="mt-2"
                variant="outlined"
                placeholder={t("Name")}
                fullWidth
                defaultValue={senderAddress.name || defaultAddress.name}
                error={Boolean(get(errors, "name")) || false}
                helperText={t(`${get(errors, "name.message") || ""}`)}
                validate={register}
                name="name"
              />
            </Grid>

            <Grid container item xs={12}>
              <Typography variant="body3">{t("Street address")}</Typography>
              <TextField
                className="mt-2"
                variant="outlined"
                placeholder={t("Street address")}
                fullWidth
                defaultValue={senderAddress.address || defaultAddress.address}
                error={Boolean(get(errors, "address")) || false}
                helperText={t(`${get(errors, "address.message") || ""}`)}
                validate={register}
                name="address"
              />
            </Grid>

            <AutoCompleteAddress setAddressData={setValue} />

            <Grid container item xs={6}>
              <Typography variant="body3">{t("subDistrict")}</Typography>
              <TextField
                className="mt-2"
                variant="outlined"
                placeholder={t("subDistrict")}
                fullWidth
                defaultValue={senderAddress.subDistrict || defaultAddress.subDistrict}
                error={Boolean(get(errors, "subDistrict")) || false}
                helperText={t(`${get(errors, "subDistrict.message") || ""}`)}
                validate={register}
                name="subDistrict"
              />
            </Grid>
            <Grid container item xs={6}>
              <Typography variant="body3">{t("district")}</Typography>
              <TextField
                className="mt-2"
                variant="outlined"
                placeholder={t("district")}
                fullWidth
                defaultValue={senderAddress.district || defaultAddress.district}
                error={Boolean(get(errors, "district")) || false}
                helperText={t(`${get(errors, "district.message") || ""}`)}
                validate={register}
                name="district"
              />
            </Grid>
            <Grid container item xs={6}>
              <Typography variant="body3">{t("province")}</Typography>
              <TextField
                className="mt-2"
                variant="outlined"
                placeholder={t("province")}
                fullWidth
                defaultValue={senderAddress.province || defaultAddress.province}
                error={Boolean(get(errors, "province")) || false}
                helperText={t(`${get(errors, "province.message") || ""}`)}
                validate={register}
                name="province"
              />
            </Grid>
            <Grid container item xs={6}>
              <Typography variant="body3">{t("postalCode")}</Typography>
              <TextField
                className="mt-2"
                type="number"
                variant="outlined"
                placeholder={t("postalCode")}
                fullWidth
                defaultValue={senderAddress.postalCode || defaultAddress.postalCode}
                error={Boolean(get(errors, "postalCode")) || false}
                helperText={t(`${get(errors, "postalCode.message") || ""}`)}
                validate={register}
                name="postalCode"
              />
            </Grid>
            <Grid container item xs={12}>
              <Typography variant="body3">{t("phoneNumber")}</Typography>
              <TextField
                className="mt-2"
                type="tel"
                variant="outlined"
                placeholder={t("phoneNumber")}
                fullWidth
                defaultValue={senderAddress.phoneNumber || defaultAddress.phoneNumber}
                error={Boolean(get(errors, "phoneNumber")) || false}
                helperText={t(`${get(errors, "phoneNumber.message") || ""}`)}
                validate={register}
                name="phoneNumber"
              />
            </Grid>
          </Grid>
          <Grid item container className="pt-4">
            <Grid item xs={6} className="pr-2">
              <Button size="medium" fullWidth color="ghost" onClick={handleClose}>
                {t("Cancel")}
              </Button>
            </Grid>
            <Grid item xs={6} className="pl-2">
              <Button type="submit" size="medium" fullWidth>
                {t("Confirm")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

export default AddressForm;
