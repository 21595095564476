import {
  COD_DEPOSIT_TYPE_LIST,
  COD_MANAGEMENT_FEE_TYPES,
  COD_MANAGEMENT_FEE_TYPES_LIST,
  COD_SHIPPING_METHOD,
  PAYMENT_TYPES,
} from "constants/Payment";
import type { ShippingMethod } from "types/Shipping";
import type { PaymentType } from "types/Payment";

export const depositTypeOptions = COD_DEPOSIT_TYPE_LIST.map((value) => ({
  label: value,
  value,
}));

export const managementFeeTypeOptions = COD_MANAGEMENT_FEE_TYPES_LIST.map((value) => ({
  label: value,
  value,
}));

export function getCarrierOption(defaultShippingMethods: ShippingMethod[]) {
  const defaultShippingMethodOptions = defaultShippingMethods.map(({ description, method }) => ({
    label: description,
    value: method,
  }));

  const allShippingMethodOption = {
    label: "carrier.option.label.all",
    value: COD_SHIPPING_METHOD.ALL,
  };

  return [...defaultShippingMethodOptions, allShippingMethodOption];
}

export function getCodPayment(payments: PaymentType[]) {
  return payments.find(({ type }) => type === PAYMENT_TYPES.CASH_ON_DELIVERY);
}

export function getManagementFeeTypeName(managementFeeType: COD_MANAGEMENT_FEE_TYPES) {
  switch (managementFeeType) {
    case COD_MANAGEMENT_FEE_TYPES.FIXED_PRICE:
      return "managementFee.fee";
    case COD_MANAGEMENT_FEE_TYPES.PERCENTAGE:
      return "managementFee.percent";
    default:
      return "managementFee.fee";
  }
}
