import React from "react";
import { Link } from "react-router-dom";
import get from "lodash/get";
import { COLOR_BY_STATUS } from "constants/Order";
import { ORDER_STATUSES } from "constants/SalesReport";
import { convertPriceFormat } from "utils/common";
import i18n from "utils/i18n";
import moment from "moment";
import { CellProps, Row, useSortBy, useBlockLayout, useExpanded } from "react-table";
import { useSticky } from "react-table-sticky";
import IndeterminateCheckbox from "components/Checkbox";
import { SvgIcon } from "components/Icon";
import { IcDownload } from "components/SvgIcons";
import CircularProgress from "components/CircularProgress";
import { useSelection } from "./hooks";
import { Bullet, Thumbnail, ThumbnailWrapper, OrderNo, Status } from "./styled";
import { Typography } from "../styled";
import { DEFAULT_VALUE } from "../config";

export const tableHooks = [useSortBy, useExpanded, useBlockLayout, useSticky, useSelection];

const LEFT_STICKY_PANEL_MAX_WIDTH = 422;
const CHECKBOX_COLUMN_WIDTH = 56;

export const getColumns = <T extends object>(
  projectId?: string,
  isDesktop?: boolean,
  getReportUrl?: Function,
  isLoading?: boolean,
  downloadOrderId?: string,
  onSelectRow?: (id: string) => void,
  onSelectAllRowInPage?: (ids: string[], isSelectedSomeRow: boolean) => void,
) => {
  return [
    {
      id: "selection",
      width: CHECKBOX_COLUMN_WIDTH,
      sticky: "left",
      hello: "world",
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      // eslint-disable-next-line react/display-name
      Header: (row: Row<T>) => {
        const allData: (T & { id: string })[] = get(row, "data") || [];
        const ids = allData.map((data) => data.id);
        const isSelectedAll: boolean = get(row, `state.isSelectedAll`) || false;
        const excludeIds: Record<string, boolean> = get(row, `state.excludeIds`) || {};
        const selectedIds: Record<string, boolean> = get(row, `state.selectedIds`) || {};
        const isSelectedSomeRow = isSelectedAll ? ids.some((id) => excludeIds[id]) : ids.some((id) => !selectedIds[id]);

        return (
          <div className="m-auto">
            <IndeterminateCheckbox
              onClick={() => {
                if (onSelectAllRowInPage && ids.length) {
                  onSelectAllRowInPage(ids, isSelectedSomeRow);
                }
              }}
              checked={!isSelectedSomeRow}
            />
          </div>
        );
      },
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const id = get(cell, "row.original.id");
        const isSelected = get(cell, `state.selectedIds[${id}]`, false);
        const isExcluded = get(cell, `state.excludeIds[${id}]`, false);
        const isSelectedAll = get(cell, `state.isSelectedAll`, false);
        let isChecked = false;

        if (isSelectedAll) {
          isChecked = !isExcluded;
        } else {
          isChecked = isSelected;
        }

        return (
          <div className="m-auto">
            <IndeterminateCheckbox
              onClick={() => {
                if (onSelectRow && id) {
                  onSelectRow(id);
                }
              }}
              checked={isChecked}
            />
          </div>
        );
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("Order no."),
      accessor: "orderNumber",
      sticky: isDesktop ? "left" : null,
      width: (LEFT_STICKY_PANEL_MAX_WIDTH - CHECKBOX_COLUMN_WIDTH) / 3 + 10,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => (
        <OrderNo>
          {projectId ? <Link to={`/project/${projectId}/orders/${cell.value}`}>{cell.value}</Link> : cell.value}
        </OrderNo>
      ),
    },
    {
      disableSortBy: true,
      Header: i18n.t("Customer"),
      accessor: "customerName",
      sticky: isDesktop ? "left" : null,

      width: (LEFT_STICKY_PANEL_MAX_WIDTH - CHECKBOX_COLUMN_WIDTH) / 3 - 10,
    },
    {
      disableSortBy: true,
      Header: i18n.t("Status"),
      accessor: "state",
      sticky: isDesktop ? "left" : null,
      width: (LEFT_STICKY_PANEL_MAX_WIDTH - CHECKBOX_COLUMN_WIDTH) / 3,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <>
            <Bullet color={COLOR_BY_STATUS[cell.value]} />
            <Status color={COLOR_BY_STATUS[cell.value]}>
              {typeof cell.value === "string" ? i18n.t(ORDER_STATUSES[cell.value]) : cell.value}
            </Status>
          </>
        );
      },
    },
    {
      disableSortBy: true,
      Header: `${i18n.t("Weight")}(${i18n.t("gram")})`,
      accessor: "totalWeight",
      Cell: (cell: CellProps<T>) => {
        return cell.value ? `${convertPriceFormat(cell.value)} ${i18n.t("GRAM")}` : `0 ${i18n.t("GRAM")}`;
      },
    },
    {
      disableSortBy: true,
      Header: `${i18n.t("DIMENSION")} ${i18n.t("WxLxH")}`,
      accessor: "largestDimension",
      Cell: (cell: CellProps<T>) => {
        const dimensions = cell.value || [0, 0, 0];
        const textDimensions = dimensions.map((size: number) => convertPriceFormat(size));
        return `${textDimensions.join(" x ")} ${i18n.t("cm")}`;
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("Delivery schedule"),
      accessor: "deliverySchedule",
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const timeStamp = Number(cell.value);

        return timeStamp ? moment(timeStamp).format("L  LT") : DEFAULT_VALUE;
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("shippingDescription"),
      accessor: "shippingDescription",
      Cell: (cell: CellProps<T>) => {
        const shippingDescription = cell.value;

        return i18n.t(shippingDescription);
      },
    },
    { disableSortBy: true, Header: i18n.t("Address"), accessor: "streetAddress", width: 250 },
    { disableSortBy: true, Header: i18n.t("Note"), accessor: "note" },
    { disableSortBy: true, Header: i18n.t("PhoneNumber"), accessor: "phoneNumber" },
    { disableSortBy: true, Header: i18n.t("E-mail"), accessor: "email" },
    { disableSortBy: true, Header: i18n.t("Created by"), accessor: "createdBy" },
    { disableSortBy: true, Header: i18n.t("Tracking No."), accessor: "trackingNumber" },
    {
      disableSortBy: true,
      Header: i18n.t("Product Price"),
      accessor: "productPrice",
      Cell: (cell: CellProps<T>) => {
        return `${i18n.t("THB")} ${typeof cell.value === "number" ? convertPriceFormat(cell.value) : cell.value}`;
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("Product discount"),
      accessor: "discountTotalPrice",
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <Typography fontWeight={500} color="error">
            - {i18n.t("THB")} {typeof cell.value === "number" ? convertPriceFormat(cell.value) : cell.value}
          </Typography>
        );
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("Shipping price"),
      accessor: "shippingPrice",
      Cell: (cell: CellProps<T>) => {
        return `${i18n.t("THB")} ${typeof cell.value === "number" ? convertPriceFormat(cell.value) : cell.value}`;
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("Shipping discount"),
      accessor: "discountShippingPrice",
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <Typography fontWeight={500} color="error">
            - {i18n.t("THB")} {typeof cell.value === "number" ? convertPriceFormat(cell.value) : cell.value}
          </Typography>
        );
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("order.paySlip.additionalDiscount"),
      accessor: "additionalDiscount",
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <Typography fontWeight={500} color="error">
            - {i18n.t("THB")} {typeof cell.value === "number" ? convertPriceFormat(cell.value) : cell.value}
          </Typography>
        );
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("Total"),
      accessor: "grandTotal",
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <Typography fontWeight={500} color="darkGray">
            {i18n.t("THB")} {typeof cell.value === "number" ? convertPriceFormat(cell.value) : cell.value}
          </Typography>
        );
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("Payment at"),
      accessor: "paymentAt",
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const timeStamp = Number(cell.value);

        return timeStamp ? moment(timeStamp).format("L  LT") : DEFAULT_VALUE;
      },
    },
    { Header: i18n.t("salesReport.adminsNote"), accessor: "adminsNote", disableSortBy: true },
    { Header: i18n.t("salesReport.refCode"), accessor: "referenceCode", disableSortBy: true },
    {
      Header: i18n.t("PaySlip"),
      accessor: "billNumber",
      disableSortBy: true,
      sticky: "right",
      width: 100,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const orderId = get(cell.value, "orderId");
        const billNumber = get(cell.value, "billNumber");

        return (
          <>
            {billNumber && (
              <>
                {isLoading && downloadOrderId === orderId ? (
                  <CircularProgress size={14} />
                ) : (
                  <div
                    className="d-flex"
                    style={{ cursor: "pointer" }}
                    onClick={() => getReportUrl && getReportUrl(projectId, orderId)}
                  >
                    <SvgIcon component={IcDownload} fontSize="small" />
                    {billNumber}
                  </div>
                )}
              </>
            )}
          </>
        );
      },
    },
  ];
};

export const getSubTableColumns = <T extends object>(isDesktop?: boolean) => [
  {
    Header: "",
    accessor: "empty1",
    sticky: "left",
    width: isDesktop ? 34 : 56,
  },
  {
    Header: "#",
    accessor: "image",
    sticky: isDesktop ? "left" : null,
    width: 47,
    // eslint-disable-next-line react/display-name
    Cell: (cell: CellProps<T>) => {
      return cell.value ? (
        <ThumbnailWrapper>
          <Thumbnail alt="product" src={cell.value} />
        </ThumbnailWrapper>
      ) : (
        ""
      );
    },
  },
  {
    Header: i18n.t("SKU CODE"),
    accessor: "productCode",
    sticky: isDesktop ? "left" : null,
    width: 94,
  },
  {
    Header: i18n.t("PRODUCT NAME"),
    accessor: "productName",
    sticky: isDesktop ? "left" : null,
    width: 247,
  },
  { Header: i18n.t("Variation/Option"), accessor: "sku" },
  {
    Header: i18n.t("PRICE"),
    accessor: "productPrice",
    Cell: (cell: CellProps<T>) => {
      if (typeof cell.value === "string") {
        return i18n.t(cell.value);
      }

      if (typeof cell.value === "number") {
        return `${i18n.t("THB")} ${typeof cell.value === "number" ? convertPriceFormat(cell.value) : cell.value}`;
      }
      return cell.value;
    },
  },
  { Header: i18n.t("AMOUNT"), accessor: "amount" },
  {
    Header: i18n.t("WEIGHT (g.)"),
    accessor: "weight",
    Cell: (cell: CellProps<T>) => {
      const weight = cell.value;
      if (weight == null) {
        return DEFAULT_VALUE;
      }

      return `${typeof weight === "number" ? convertPriceFormat(weight) : weight}`;
    },
  },
  {
    disableSortBy: true,
    Header: `${i18n.t("DIMENSION")} ${i18n.t("WxLxH")}`,
    accessor: "dimension",
    Cell: (cell: CellProps<T>) => {
      const dimensions = cell.value || [0, 0, 0];
      const textDimensions = dimensions.map((size: number) => convertPriceFormat(size));
      return `${textDimensions.join(" x ")} ${i18n.t("cm")}`;
    },
  },
  {
    Header: "",
    accessor: "empty2",
    width: isDesktop ? 2200 : 2178,
  },
  {
    Header: "",
    accessor: "empty3",
    width: 160,
    sticky: "right",
  },
];
