import { ErrorImportOrder } from "types/Order";

const NETWORK_ERROR = "Network error: Failed to fetch";

const mapErrorMessage = (errMessage: string) => {
  switch (errMessage) {
    case NETWORK_ERROR:
      return "Network error please try again later";
    case ErrorImportOrder.FILE_TYPE_IS_NOT_PERMITTED:
      return "product.import.error.fileType";
    case ErrorImportOrder.ORDER_HAS_ONLY_DIGITAL_CONTENT_PRODUCT_CANNOT_UPDATE_SHIPPED_DETAIL:
      return "ORDER_HAS_ONLY_DIGITAL_CONTENT_PRODUCT_CANNOT_UPDATE_SHIPPED_DETAIL";
    case ErrorImportOrder.ORDER_NUMBER_IS_NOT_FOUND:
      return "ORDER_NUMBER_IS_NOT_FOUND";
    case ErrorImportOrder.ORDER_STATE_IS_NOT_IN_PREPARE_TO_SHIPPING:
      return "ORDER_STATE_IS_NOT_IN_PREPARE_TO_SHIPPING";
    case ErrorImportOrder.PICK_UP_OR_DIGITAL_ORDER_CANNOT_UPDATE_SHIPPED_DETAIL:
      return "PICK_UP_OR_DIGITAL_ORDER_CANNOT_UPDATE_SHIPPED_DETAIL";
    case ErrorImportOrder.NOT_SUPPORTED_SHIPPING_CALCULATION_TYPE:
      return "NOT_SUPPORTED_SHIPPING_CALCULATION_TYPE";
    default:
      return "Please try again later";
  }
};

export default mapErrorMessage;
