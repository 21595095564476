import React, { FC } from "react";

import Grid from "components/Grid";
import Divider from "components/Divider";

import { Device } from "types/Device";
import useDevice from "utils/hooks/useDevice";
import useGetDeeplePayFeeInvoiceSignedUrl from "utils/hooks/useGetDeeplePayFeeInvoiceSignedUrl";
import CircularProgress from "components/CircularProgress";
import Modal from "components/Modal/index";
import { DeeplePayFeeInvoice } from "types/DeeplePay";
import DeeplePayFeeInvoiceTableHeader from "./DeeplePayFeeInvoiceTableHeader";

import { InvoiceTable } from "./styled";
import DeeplePayFeeInvoiceItem from "./DeeplePayFeeInvoiceItem";

type DeeplePayFeeInvoiceTablePropsType = {
  projectId: string;
  deeplePayFeeInvoices: DeeplePayFeeInvoice[];
};

const DeeplePayFeeInvoiceTable: FC<DeeplePayFeeInvoiceTablePropsType> = (props) => {
  const { deeplePayFeeInvoices, projectId } = props;
  const device = useDevice();
  const isMobile = device === Device.MOBILE;
  const { onClickDeeplePayFeeInvoiceId, loading } = useGetDeeplePayFeeInvoiceSignedUrl(projectId);

  return (
    <>
      {loading && (
        <Modal isOpen onClose={() => {}}>
          <CircularProgress className="m-4" />
        </Modal>
      )}
      <Grid container className="px-4 mt-4">
        <DeeplePayFeeInvoiceTableHeader isMobile={isMobile} />
      </Grid>
      {!isMobile && <Divider thickness="0.5px" className="m-0" />}
      <InvoiceTable container isMobile={isMobile}>
        {deeplePayFeeInvoices &&
          deeplePayFeeInvoices.map((deeplePayFeeInvoice: DeeplePayFeeInvoice) => {
            return (
              <DeeplePayFeeInvoiceItem
                key={deeplePayFeeInvoice.id}
                handleDownloadPDF={() => onClickDeeplePayFeeInvoiceId(deeplePayFeeInvoice.id)}
                isMobile={isMobile}
                deeplePayFeeInvoice={deeplePayFeeInvoice}
              />
            );
          })}
      </InvoiceTable>
    </>
  );
};

export default DeeplePayFeeInvoiceTable;
