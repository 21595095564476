import React, { FC, useMemo, useRef, ReactNode } from "react";
import { SortingRule, useSortBy, useFlexLayout, Column } from "react-table";
import { useTranslation } from "react-i18next";
import Grid from "components/Grid";
import CircularProgress from "components/CircularProgress";
import COLORS from "constants/Colors";
import DeepleTable from "components/Table";
import { Data } from "types/SalesReport";
import { TableWrapper } from "./TableWrapper";
import { getColumns, getSearchResultColumns } from "./getColumns";
import { GridLoading, Hidden, Typography } from "./styled";

export type OnChangeSortBy = (sortBys: SortingRule<{ id: string; desc: boolean }>[]) => void;

type TableProps = {
  onChangeSortBy?: OnChangeSortBy;
  data: Data[];
  isLoading?: boolean;
  isSearchResults?: Boolean;
  renderFooter?: () => ReactNode;
  customCellProps?: object;
};

const Table: FC<TableProps> = ({ customCellProps, data, onChangeSortBy, isLoading, renderFooter, isSearchResults }) => {
  const { t } = useTranslation();

  const tableHooks = useRef([useSortBy, useFlexLayout]);
  const columns = useMemo(() => getColumns(t), [t]);
  const searchResultColumns = useMemo(() => getSearchResultColumns(t), [t]);
  const currentColumns = isSearchResults ? searchResultColumns : columns;

  return (
    <>
      <TableWrapper>
        {isLoading && (
          <GridLoading>
            <CircularProgress className="m-4" />
          </GridLoading>
        )}

        {/* We have to render table and hide table when there are not reports
            to prevent table reset all filter and sortBy state in the table. */}
        <Hidden className="ml-auto mr-auto" isHidden={data.length === 0}>
          <DeepleTable<Data, Record<string, Data>>
            data={data}
            customCellProps={customCellProps}
            columns={(currentColumns as unknown) as Column<Data>[]}
            manualSortBy
            hooks={tableHooks.current}
            onChangeSortBy={onChangeSortBy}
            renderFooter={renderFooter}
          />
        </Hidden>

        {!isLoading && data.length === 0 && (
          <Grid container justify="center" className="mt-4">
            <Grid item xs={12}>
              <Typography variant="title2" color={COLORS.DarkLight} className="text-center">
                {t("RESPONSE_MESSAGE_NOT_FOUND")}
              </Typography>
            </Grid>
          </Grid>
        )}
      </TableWrapper>
    </>
  );
};

export default Table;
