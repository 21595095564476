import * as yup from "yup";

const schema = yup.object().shape({
  code: yup.string(),
  note: yup.string(),
});

export const MarkAsUsedSchema = yup.object().shape({
  markAsUsedNote: yup.string(),
});

export default schema;
