import React from "react";
import { CellProps } from "react-table";
import styled from "styled-components";
import { HIGHLIGHT_CLASS_NAME } from "../../config";

const MessageWrapper = styled.div`
  span.${HIGHLIGHT_CLASS_NAME} {
    color: ${({ theme }) => theme.COLORS.Accent};
  }
`;

const Message = <T extends object>(t: Function) => {
  // eslint-disable-next-line react/no-danger
  const renderCell = (cell: CellProps<T>) => <MessageWrapper dangerouslySetInnerHTML={{ __html: cell.value }} />;

  return {
    Header: t("Message"),
    accessor: "message",
    disableSortBy: true,
    Cell: renderCell,
  };
};

export default Message;
