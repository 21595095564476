import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { OptionType } from "react-select";
import { useTranslation } from "react-i18next";

import { WAREHOUSES } from "graphql/integration/query";
import { Warehouse, InventoryIntegrationType, InventoryIntegrationCredential } from "types/Integration";
import { notifyError } from "utils/notify";

type UseWarehouseDataProps = {
  projectId: string;
  onCompleted: () => void;
};

const useWarehouseData = (props: UseWarehouseDataProps) => {
  const { t } = useTranslation();

  const { onCompleted, projectId } = props;

  const [getWarehouse, { data, loading }] = useLazyQuery(WAREHOUSES, {
    fetchPolicy: "network-only",
    onError: () => {
      notifyError(t("Please try again later"));
    },
  });

  const handleGetWarehouse = (credentialData: InventoryIntegrationCredential) => {
    getWarehouse({
      variables: {
        projectId,
        type: InventoryIntegrationType.ZORT,
        data: credentialData,
      },
    });
  };

  useEffect(() => {
    if (!loading && data) {
      onCompleted();
    }
  }, [data, loading, onCompleted]);

  const warehouseOptions: OptionType[] = (data?.integrationWarehouses || []).map((warehouse: Warehouse) => {
    const { code, name } = warehouse;

    return {
      label: name,
      value: code,
    };
  });

  return {
    handleGetWarehouse,
    isProcessingGetWarehouse: loading,
    warehouseOptions,
  };
};

export default useWarehouseData;
