import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Avatar from "components/Avatar";
import Grid from "components/Grid";
import Typography from "components/Typography";

import { CUSTOMER_NOTE_DATE_TIME_FORMAT } from "constants/DateTimeFormat";
import COLORS from "constants/Colors";
import { AgentType } from "types/Customer";

import { TextButton, TypoBreakWord, AdminNameWrapper, RightSideTextWrapper } from "../styled";

type DetailNotePropsType = {
  createdAt: string;
  note: string;
  agent: AgentType;
  isOwner: boolean;
  currentUserEmail: string;
  onDeletedNote: () => void;
  isDesktop?: boolean;
};

const DetailNote: FC<DetailNotePropsType> = (props) => {
  const { t } = useTranslation();
  const { note, createdAt, agent, isOwner, currentUserEmail, onDeletedNote, isDesktop } = props;
  const { avatar, displayName, email, isDeactivated } = agent;

  const hasDeletePermission = isOwner || currentUserEmail === email;

  const RenderAdminName = () => (
    <AdminNameWrapper item container alignItems="center">
      <Grid item className="pr-1">
        <Avatar size="20px" alt="userAvatar" className="mr-1" src={avatar} />
      </Grid>
      <Grid item>
        <Typography variant="body3" color={COLORS.DarkLight}>
          {displayName || email} {isDeactivated && t("customerDetail.customerNote.userDeactivated")}
        </Typography>
      </Grid>
    </AdminNameWrapper>
  );

  return (
    <Grid container className="pt-3">
      <Grid
        item
        container
        alignItems={isDesktop ? "center" : "flex-start"}
        justify={isDesktop ? "flex-start" : "space-between"}
        className="flex-nowrap"
      >
        <Grid item container alignItems="center" className="d-flex flex-grow-1">
          <Grid item xs={isDesktop ? "auto" : 12} className={isDesktop ? "pr-3" : ""}>
            <Typography variant="body3" color={COLORS.DarkLight}>
              {moment(createdAt).format(CUSTOMER_NOTE_DATE_TIME_FORMAT)}
            </Typography>
          </Grid>
          <Grid item xs={isDesktop ? "auto" : 12} className={isDesktop ? "" : "py-1"}>
            <TypoBreakWord variant="body3" color="dark">
              {note}
            </TypoBreakWord>
          </Grid>
        </Grid>
        <RightSideTextWrapper item container justify="flex-end" alignItems="center" className="d-flex flex-grow-0">
          {isDesktop && <RenderAdminName />}
          {hasDeletePermission && (
            <Grid item onClick={onDeletedNote} className="pl-3 pr-2">
              <TextButton variant="body3" color={COLORS.DarkLight}>
                {t("customerDetail.customerNote.delete")}
              </TextButton>
            </Grid>
          )}
        </RightSideTextWrapper>
      </Grid>
      {!isDesktop && <RenderAdminName />}
    </Grid>
  );
};

export default DetailNote;
