import { useQuery } from "@apollo/client";
import { HAS_CHANGES_ON_NEXT_CYCLE } from "graphql/plan/query";
import { ProjectIdType } from "types/Project";
import { HasChangesOnNextCycleQueryType } from "types/PlanUsage";

const useHasChangeOnNextCycle = (projectId: string) => {
  const { data } = useQuery<HasChangesOnNextCycleQueryType, ProjectIdType>(HAS_CHANGES_ON_NEXT_CYCLE, {
    variables: {
      projectId,
    },
  });

  if (data && data.hasChangesOnNextCycle) {
    const isAddonChangeOnNextCycle = data.hasChangesOnNextCycle.hasAddonChangesOnNextCycle;
    const isPackageChangeOnNextCycle = data.hasChangesOnNextCycle.hasPackageChangesOnNextCycle;

    return {
      isAddonChangeOnNextCycle,
      isPackageChangeOnNextCycle,
    };
  }

  return {
    isAddonChangeOnNextCycle: false,
    isPackageChangeOnNextCycle: false,
  };
};

export default useHasChangeOnNextCycle;
