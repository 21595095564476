import * as React from "react";

const SvgIcPercent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.413 12.676c-2.072 0-3.44-1.369-3.44-4.028 0-2.66 1.368-4.106 3.44-4.106 2.112 0 3.442 1.564 3.442 4.067 0 2.62-1.33 4.067-3.442 4.067zm0 2.542c3.364 0 6.414-2.738 6.414-6.61C14.827 4.817 11.894 2 8.413 2 5.011 2 2 4.816 2 8.687c0 3.793 3.09 6.53 6.413 6.53zM5.95 30.196h2.972l17.09-27.961h-2.973L5.95 30.195zM23.587 17.29c3.48 0 6.413 2.815 6.413 6.608 0 3.872-3.05 6.61-6.413 6.61-3.324 0-6.414-2.738-6.414-6.531 0-3.872 3.011-6.687 6.414-6.687zm0 2.541c-2.073 0-3.442 1.447-3.442 4.107 0 2.659 1.369 4.027 3.442 4.027 2.111 0 3.44-1.446 3.44-4.067 0-2.502-1.329-4.067-3.44-4.067z"
    />
  </svg>
);

export default SvgIcPercent;
