import { gql } from "@apollo/client";

export const BRANCHES = gql`
  query offlineStores($projectId: ID!) {
    offlineStores(projectId: $projectId) {
      id
      projectId
      name
      address
      subDistrict
      district
      province
      postalCode
      latitude
      longitude
      phoneNumber
      location
      isActive
      isAvailableForPickUp
      isAvailableForLalamove
      openingHours {
        day
        open
        close
        isActive
      }
    }
  }
`;
