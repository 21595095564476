import React from "react";

export const DeepleAppContext = {
  isLoggedin: false,
  isVerified: undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setIsLoggedin: (data: any) => data,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setIsVerified: (data: any) => data,
};

export const AuthContext = React.createContext(DeepleAppContext);
