import { AUDIENCE_NUMBER } from "graphql/broadcast/query";
import get from "lodash/get";
import { useLazyQuery } from "@apollo/client";

export const useFetchAudienceNumber = () => {
  const [fetchAudienceNumber, { data, loading }] = useLazyQuery(AUDIENCE_NUMBER, {
    fetchPolicy: "network-only",
  });

  const audienceNumber = get(data, "audienceNumber") || 0;

  return { fetchAudienceNumber, audienceNumber, loading };
};
