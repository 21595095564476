import { OptionType, LabelStyle } from "./type";

const getLabelStyle = (option: OptionType<unknown>): LabelStyle => {
  const headerStyle: LabelStyle = {
    variant: "title8",
    color: "darkLight",
    style: { minHeight: 44, boxSizing: "border-box" },
  };
  const subHeaderStyle: LabelStyle = { variant: "title7", color: "dark" };
  const defaultLabelStyle: LabelStyle = { variant: "body3", color: "dark" };

  const haveOptions = option?.options?.length;
  const isHeader = option?.value == null && haveOptions;
  const isSubHeader = Boolean(option?.value) && haveOptions;

  if (isHeader) {
    return headerStyle;
  }

  if (isSubHeader) {
    return subHeaderStyle;
  }

  return defaultLabelStyle;
};

export default getLabelStyle;
