import Box from "@material-ui/core/Box";
import React, { FC, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory, Prompt, useLocation } from "react-router-dom";
import { PROJECT, RESPONSE_MESSAGE, AI_SETTINGS } from "constants/Router";
import { MAP_LANGUAGE_DISPLAY_NAME, MAP_BOT_GENDER } from "constants/ResponseMessage";
import ButtonBase from "components/ButtonBase";
import Button from "components/Button";
import Card from "components/Card";
import ConfirmationModal from "components/ConfirmationModal";
import { SvgIcon } from "components/Icon";
import Grid from "components/Grid";
import { RadioBox, OnClickRadio } from "components/Radio";
import { IcArrowBack, IcAdd } from "components/SvgIcons";
import Typography from "components/Typography";
import MessagesManager, { MessageInputVariant } from "components/MessagesManager";
import Loading from "domain/Faq/FaqThEn/FaqContainer/Loading";
import { updateQuickReplyMessageToLastItem } from "domain/Faq/FaqThEn/util";
import { Gender, ResponseMessage } from "types/AISetting";
import { MessageType } from "types/Chat";
import { ProjectIdType } from "types/Project";
import { ResponseMessageData, RESPONSE_MESSAGE_TAB } from "types/ResponseMessage";
import { removeTypenameField } from "utils/common";
import useMessagesManager from "utils/hooks/useMessagesManager";
import { notifyError, notifySuccess } from "utils/notify";
import useShouldComponentUpdate from "utils/hooks/useLifeCycle/useShouldComponentUpdate";
import useGetProject from "utils/hooks/useGetProject";
import useToggle from "utils/hooks/useToggle";
import { NOTIFICATION_UPDATE } from "constants/Notification";
import { DEFAULT_TITLE, EDITABLE_MESSAGE_TYPES, MAX_MESSAGES_LENGTH } from "./config";
import useResponseMessages from "./hook/useResponseMessages";
import { ActionButton } from "./styled";

const EditResponseMessage: FC = () => {
  const { t, i18n } = useTranslation();
  const [customResponseMessageTabs, setCustomResponseMessageTabs] = useState([RESPONSE_MESSAGE_TAB.DEFAULT]);
  const [currentResponseMessageTab, setCurrentResponseMessageTab] = useState(RESPONSE_MESSAGE_TAB.DEFAULT);
  const { projectId, responseMessageName } = useParams<ProjectIdType & { responseMessageName: string }>();
  const { isOpen, handleClose, handleToggle } = useToggle();
  const {
    isOpen: isWarningUnsavedOpenModal,
    handleClose: handleUnsavedOpenCloseModal,
    handleToggle: handleWarningUnsavedOpenModalToggle,
  } = useToggle();
  const { updateCustomResponseMessages, data, loading: isResponseMessageLoading } = useResponseMessages(
    projectId,
    responseMessageName,
  );

  const { language } = i18n;
  const displayNameField = MAP_LANGUAGE_DISPLAY_NAME?.[language] as keyof ResponseMessageData;
  const { botGender, loading: isProjectLoading } = useGetProject(projectId);
  const history = useHistory();
  const { search } = useLocation();

  const {
    addNewMessages,
    deleteMessage,
    handleClickAddMessage,
    handleCloseConfirmModal,
    handleDeleteMessage,
    handleDragEnd,
    handleEditMessage,
    handleUploadCallback,
    isChanged,
    isOpenConfirmModal: isOpenConfirmDeleteModal,
    messages,
    messagesCount,
    selectedIndex,
    setIsChanged,
    setMessages,
    updateNewQuickReplyMessage,
  } = useMessagesManager();

  const handleUpdateCustomResponseMessages = useCallback(() => {
    const isCustom = currentResponseMessageTab === RESPONSE_MESSAGE_TAB.CUSTOM;
    const defaultCustomMessages = data?.responseMessage?.customMessages || [];
    const removedTypenameFieldMessages = removeTypenameField(messages);
    const messageLength = messages.filter((message) => message.type !== MessageType.QUICK_REPLIES).length;

    if (!messageLength) {
      notifyError(t("RESPONSE_MESSAGE_CAN_NOT_BE_EMPTY"));
      return;
    }

    const customMessages = isCustom
      ? updateQuickReplyMessageToLastItem(removedTypenameFieldMessages as ResponseMessage[])
      : removeTypenameField(defaultCustomMessages);

    try {
      updateCustomResponseMessages(customMessages as ResponseMessage[], isCustom);
      setIsChanged(false);
      notifySuccess(t(NOTIFICATION_UPDATE.SUCCESS));
    } catch (error) {
      console.error(error);
      notifyError(t(NOTIFICATION_UPDATE.FAIL));
    }
  }, [currentResponseMessageTab, data, messages, setIsChanged, t, updateCustomResponseMessages]);

  const handleAddCustomResponseMessageTab = useCallback(() => {
    setCustomResponseMessageTabs([RESPONSE_MESSAGE_TAB.DEFAULT, RESPONSE_MESSAGE_TAB.CUSTOM]);
  }, []);

  const handleDelete = useCallback(() => {
    deleteMessage(selectedIndex);
  }, [deleteMessage, selectedIndex]);

  const getDefaultResponseMessage = useCallback(() => {
    const responseMessage = data?.responseMessage;
    const botGenderField = MAP_BOT_GENDER[botGender as Gender] as keyof ResponseMessageData;

    return responseMessage?.[botGenderField] as ResponseMessage[];
  }, [botGender, data]);

  const handleSetMessages = useCallback(
    (newResponseMessages: ResponseMessage[]) => {
      setMessages(newResponseMessages);
    },
    [setMessages],
  );

  const switchResponseMessages = useCallback(
    (customResponseMessageTab: RESPONSE_MESSAGE_TAB) => {
      if (!customResponseMessageTab) return;
      setCurrentResponseMessageTab(customResponseMessageTab);
      const defaultResponseMessage = getDefaultResponseMessage();
      const customMessages = data?.responseMessage?.customMessages?.length
        ? data?.responseMessage?.customMessages
        : defaultResponseMessage;
      const newCustomMessages =
        customResponseMessageTab === RESPONSE_MESSAGE_TAB.CUSTOM ? customMessages : defaultResponseMessage;
      handleSetMessages(newCustomMessages);
      setIsChanged(false);
    },
    [data, getDefaultResponseMessage, handleSetMessages, setIsChanged],
  );

  const handleCustomResponseMessageTab: OnClickRadio = useCallback(
    (_event, customResponseMessageTab) => {
      if (isChanged) {
        handleWarningUnsavedOpenModalToggle();
      } else {
        switchResponseMessages(customResponseMessageTab as RESPONSE_MESSAGE_TAB);
      }
    },
    [handleWarningUnsavedOpenModalToggle, isChanged, switchResponseMessages],
  );

  const handleToGoNext = useCallback(() => {
    switchResponseMessages(RESPONSE_MESSAGE_TAB.DEFAULT);
    handleUnsavedOpenCloseModal();
  }, [handleUnsavedOpenCloseModal, switchResponseMessages]);

  const handleResetCustomResponseMessages = useCallback(() => {
    const defaultResponseMessage = getDefaultResponseMessage();
    handleSetMessages(defaultResponseMessage);
    handleClose();
    setIsChanged(true);
  }, [getDefaultResponseMessage, handleClose, handleSetMessages, setIsChanged]);

  const setInitial = useCallback(() => {
    const responseMessage = data?.responseMessage;
    const defaultResponseMessage = getDefaultResponseMessage();
    if (!isResponseMessageLoading && !isProjectLoading && responseMessage) {
      const removedTypenameFieldResponseMessages = removeTypenameField(defaultResponseMessage) as ResponseMessage[];
      if (responseMessage?.isCustom && responseMessage?.customMessages?.length) {
        const removedTypenameFieldCustomMessages = removeTypenameField(
          responseMessage?.customMessages,
        ) as ResponseMessage[];
        setCurrentResponseMessageTab(RESPONSE_MESSAGE_TAB.CUSTOM);
        setCustomResponseMessageTabs([RESPONSE_MESSAGE_TAB.DEFAULT, RESPONSE_MESSAGE_TAB.CUSTOM]);
        handleSetMessages(removedTypenameFieldCustomMessages);
      } else {
        handleSetMessages(removedTypenameFieldResponseMessages);
      }
    }
  }, [data, getDefaultResponseMessage, handleSetMessages, isProjectLoading, isResponseMessageLoading]);

  useShouldComponentUpdate(setInitial);

  const isLoading = isResponseMessageLoading || isProjectLoading;
  const isCustomResponseMessage = currentResponseMessageTab === RESPONSE_MESSAGE_TAB.CUSTOM;
  const currentResponseMessageTabFromData = data?.responseMessage?.isCustom
    ? RESPONSE_MESSAGE_TAB.CUSTOM
    : RESPONSE_MESSAGE_TAB.DEFAULT;
  const isSaveDisabled = !isChanged && currentResponseMessageTab === currentResponseMessageTabFromData;

  return (
    <div className=" mx-3 my-5 d-flex flex-column flex-1">
      <div className="d-flex align-items-center mb-4">
        <ButtonBase
          onClick={() => {
            history.push(`/${PROJECT}/${projectId}/${AI_SETTINGS}/${RESPONSE_MESSAGE}${search}`);
          }}
        >
          <SvgIcon component={IcArrowBack} />
          <Typography variant="title1">{data?.responseMessage?.[displayNameField] || t(DEFAULT_TITLE)}</Typography>
        </ButtonBase>
      </div>

      {isLoading && <Loading loadingLabel={t("LOADING")} />}
      {!isLoading && (
        <>
          <Grid container>
            <Grid item xs={12} md={5} className="px-md-2">
              <Card padding="20px">
                <Typography variant="title3" color="gray" className="mb-4">
                  {t("SELECTED_RESPONSE")}
                </Typography>

                {customResponseMessageTabs.map((customResponseMessageTab) => {
                  return (
                    <RadioBox
                      key={customResponseMessageTab}
                      className="p-4 mb-4"
                      title={t(customResponseMessageTab)}
                      isActive={currentResponseMessageTab === customResponseMessageTab}
                      onClick={handleCustomResponseMessageTab}
                      value={customResponseMessageTab}
                    />
                  );
                })}
                {customResponseMessageTabs.length < 2 && (
                  <Button color="secondary" fullWidth onClick={handleAddCustomResponseMessageTab}>
                    <IcAdd fontSize={25} className="mr-2" />
                    {t("ADD_CUSTOM_RESPONSE")}
                  </Button>
                )}
              </Card>
            </Grid>
            <Grid item xs={12} md={7} className="px-md-2 mt-4 mt-md-0">
              <Card padding="20px">
                <Box display="flex" justifyContent="space-between">
                  <Typography color="darkLight" variant="title3" className="mb-1">
                    {t(currentResponseMessageTab)}:
                  </Typography>
                  <Typography color="darkMed" variant="body4" className="mb-1">
                    {messagesCount || 0}/{MAX_MESSAGES_LENGTH}
                  </Typography>
                </Box>
                <MessagesManager
                  onClickAddMessage={handleClickAddMessage}
                  messageInputVariant={MessageInputVariant.OUTLINED}
                  onDeleteMessage={handleDeleteMessage}
                  onDragEnd={handleDragEnd}
                  onEditMessage={handleEditMessage}
                  onUploadCallback={handleUploadCallback}
                  messages={messages}
                  projectId={projectId}
                  maxMessagesLength={MAX_MESSAGES_LENGTH}
                  addNewMessages={addNewMessages}
                  updateQuickReplyMessage={updateNewQuickReplyMessage}
                  editableMessageTypes={EDITABLE_MESSAGE_TYPES}
                  enableQuickReply
                  disabled={!isCustomResponseMessage}
                />
              </Card>
            </Grid>
          </Grid>

          <div className="d-flex flex-column mt-4 px-md-2 flex-md-row justify-content-md-end">
            {isCustomResponseMessage && (
              <ActionButton color="secondary" className="mb-3 mb-md-0 mr-md-3" onClick={handleToggle}>
                {t("RESET_TO_DEFAULT")}
              </ActionButton>
            )}

            <ActionButton color="primary" disabled={isSaveDisabled} onClick={handleUpdateCustomResponseMessages}>
              {t("Save")}
            </ActionButton>
          </div>
          <ConfirmationModal
            title={t("ARE_YOU_SURE_TO_REMOVE")}
            isOpen={isOpenConfirmDeleteModal}
            onClose={handleCloseConfirmModal}
            onSubmit={handleDelete}
          />
          <ConfirmationModal
            title={t("ARE_YOU_SURE_TO_RESET_CUSTOM_RESPONSE_MESSAGES")}
            isOpen={isOpen}
            textSubmit={t("RESET_TO_DEFAULT")}
            onClose={handleClose}
            onSubmit={handleResetCustomResponseMessages}
          />
          <ConfirmationModal
            title={t("YOUR_CHANGES_COULD_NOT_BE_SAVED")}
            isOpen={isWarningUnsavedOpenModal}
            textSubmit={t("GO_NEXT")}
            onClose={handleUnsavedOpenCloseModal}
            onSubmit={handleToGoNext}
          />
          <Prompt when={isChanged} message={t("YOUR_CHANGES_COULD_NOT_BE_SAVED")} />
        </>
      )}
    </div>
  );
};

export default EditResponseMessage;
