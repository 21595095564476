import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import get from "lodash/get";
import { useMutation, ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { removeTypenameField } from "utils/common";
import { SAVE_PROJECT_SETTING } from "graphql/setting/mutation";
import {
  OrderSettingType,
  OrderSettingFormType,
  ProjectConfigurationType,
  ProjectConfigurationOrderTimerType,
} from "types/Setting";
import { calculateTimesToMinutes } from "utils/time";

type UseUpdateSettingPropsType = {
  projectId: string;
  configurationData: ProjectConfigurationType;
  remindTimerOrderConfig: ProjectConfigurationOrderTimerType;
  onError: () => void;
  onComplete: () => void;
};

const useUpdateSetting = (props: UseUpdateSettingPropsType) => {
  const { t } = useTranslation();
  const { projectId, configurationData, onComplete, onError, remindTimerOrderConfig } = props;

  const [isPaymentNotificationActive, setIsPaymentNotificationActive] = useState(true);

  const [saveGeneralSetting] = useMutation(SAVE_PROJECT_SETTING, {
    onError: (error: ApolloError) => {
      toast.error(`${t("Update failed!")}, ${error}`, {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 3000,
      });
    },
    onCompleted: () => {
      onComplete();
      toast.success(t("Update successfully"), {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 1000,
        onClose: () => {},
      });
    },
  });

  useEffect(() => {
    setIsPaymentNotificationActive(get(remindTimerOrderConfig, "isActive", true));
  }, [remindTimerOrderConfig]);

  const handleSubmitForm = (formData: OrderSettingFormType) => {
    const {
      isPaymentNotificationActive,
      isEmailRequired,
      isMultiCODOrderWithOutDepositEnabled,
      orderNumberPrefix,
      paymentNotiHr,
      paymentNotiMin,
      receiptNumberPrefix,
      reserveExpHr,
      reserveExpMin,
    } = formData;

    const paymentNotificationMinutes = calculateTimesToMinutes(paymentNotiHr, paymentNotiMin);
    const reserveExpOrderMinutes = calculateTimesToMinutes(reserveExpHr, reserveExpMin);

    if (isPaymentNotificationActive && reserveExpOrderMinutes <= paymentNotificationMinutes) {
      onError();
    } else {
      const configurationDataWithoutTypename = removeTypenameField(configurationData) as ProjectConfigurationType;
      const timer: ProjectConfigurationOrderTimerType[] = get(configurationDataWithoutTypename, "order.timer");

      const paymentNotificationOrderTimer = {
        type: OrderSettingType.REMIND,
        isActive: isPaymentNotificationActive,
        value:
          paymentNotificationMinutes == null || Number.isNaN(paymentNotificationMinutes)
            ? timer[0].value
            : paymentNotificationMinutes,
      };

      const reserveExpOrderTimer = {
        type: OrderSettingType.ABANDON,
        isActive: true,
        value:
          reserveExpOrderMinutes == null || Number.isNaN(reserveExpOrderMinutes)
            ? timer[1].value
            : reserveExpOrderMinutes,
      };

      const projectConfiguration = {
        ...configurationDataWithoutTypename,
        order: {
          isEmailRequired,
          isMultiCODOrderWithOutDepositEnabled,
          timer: [paymentNotificationOrderTimer, reserveExpOrderTimer],
          prefix: {
            orderNumber: orderNumberPrefix,
            receiptNumber: receiptNumberPrefix,
          },
        },
      };

      const variables = {
        projectId,
        projectInput: {
          configuration: projectConfiguration,
        },
      };

      saveGeneralSetting({ variables });
    }
  };

  return {
    handleSubmitForm,
    isPaymentNotificationActive,
  };
};

export default useUpdateSetting;
