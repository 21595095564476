import { TotalOrderNumberStates } from "types/Order";
import calculateAllTotalOrderNumberState from "utils/order/calculateAllTotalOrderNumberState";

const resolver = {
  TotalOrderNumberStates: {
    ALL(totalOrderNumberStates: TotalOrderNumberStates) {
      return calculateAllTotalOrderNumberState(totalOrderNumberStates);
    },
  },
};

export default resolver;
