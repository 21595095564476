import Burger from "./Burger";

import AppTitle from "./AppTitle";
import ProjectList from "./ProjectList";
import SelectedProject from "./SelectedProject";
import MenuList from "./MenuList";

const MobileLayout = { Burger, AppTitle, ProjectList, SelectedProject, MenuList };

export { Burger, AppTitle, ProjectList, SelectedProject, MenuList };
export default MobileLayout;
