import styled from "styled-components";
import Grid from "components/Grid";

export const AvatarWrapper = styled.div`
  height: fit-content;
`;

export const IconWrapper = styled(Grid)`
  width: max-content;
`;
