import React from "react";
import { components, ControlProps } from "react-select";
import COLORS from "constants/Colors";
import IcTag from "components/SvgIcons/IcTag";
import { TagOption } from "types/Chat";

const TagControl = (controlProps: ControlProps<TagOption>) => {
  return (
    <components.Control {...controlProps}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexBasis: 48,
        }}
      >
        <IcTag color={COLORS.DarkMed} viewBox="0 -5 25 25" fontSize={28} className="ml-2" />
      </div>
      <div className="flex-1 d-flex">{controlProps.children}</div>
    </components.Control>
  );
};

export default TagControl;
