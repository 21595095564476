import { gql } from "@apollo/client";

export const CREDITS = gql`
  query credit($projectId: ID!) {
    mainCredit: credit(projectId: $projectId, type: MAIN)
    logisticCredit: credit(projectId: $projectId, type: LOGISTIC)
  }
`;

export const TRANSACTIONS = gql`
  query creditTransactions($projectId: ID!, $offset: Int, $limit: Int, $start: DateTime, $end: DateTime) {
    transactions: creditTransactions(projectId: $projectId, offset: $offset, limit: $limit, start: $start, end: $end) {
      results {
        id
        projectId
        type
        transactionType
        transactionId
        value
        agent
        agentType
        refId
        createdAt
      }
      total
    }
  }
`;

export const FIRST_TRANSACTION = gql`
  query firstCreditTransaction($projectId: ID!) {
    firstCreditTransaction(projectId: $projectId) {
      id
      projectId
      type
      transactionType
      transactionId
      value
      agent
      agentType
      refId
      createdAt
    }
  }
`;
