import * as React from "react";

const SvgIcAdd = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.19922 23.9363L24.9084 29.4327C26.5146 29.8393 28.1222 28.8611 28.4991 27.2477L31.6358 13.8218C32.0127 12.2084 31.0162 10.5708 29.41 10.1641L27.0001 9.55396V19.0001C27.0001 21.7615 24.7615 24.0001 22.0001 24.0001H4.00007C3.72748 24.0001 3.45997 23.9782 3.19922 23.9363Z"
      fill="#2F4069"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 15.6667H6.25C5.91848 15.6667 5.60054 15.5332 5.36612 15.2957C5.1317 15.0581 5 14.7359 5 14.4C5 14.0641 5.1317 13.7419 5.36612 13.5043C5.60054 13.2668 5.91848 13.1333 6.25 13.1333H10C10.3315 13.1333 10.6495 13.2668 10.8839 13.5043C11.1183 13.7419 11.25 14.0641 11.25 14.4C11.25 14.7359 11.1183 15.0581 10.8839 15.2957C10.6495 15.5332 10.3315 15.6667 10 15.6667ZM3.75 3H21.25C22.2446 3 23.1984 3.40036 23.9017 4.11299C24.6049 4.82563 25 5.79218 25 6.8V18.2C25 19.2078 24.6049 20.1744 23.9017 20.887C23.1984 21.5996 22.2446 22 21.25 22H3.75C2.75544 22 1.80161 21.5996 1.09835 20.887C0.395088 20.1744 0 19.2078 0 18.2V6.8C0 5.79218 0.395088 4.82563 1.09835 4.11299C1.80161 3.40036 2.75544 3 3.75 3ZM22.1339 19.0957C22.3683 18.8581 22.5 18.5359 22.5 18.2V10.6H2.5V18.2C2.5 18.5359 2.6317 18.8581 2.86612 19.0957C3.10054 19.3332 3.41848 19.4667 3.75 19.4667H21.25C21.5815 19.4667 21.8995 19.3332 22.1339 19.0957Z"
      fill="#2F4069"
    />
  </svg>
);

export default SvgIcAdd;
