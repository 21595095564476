import React, { FC, useMemo } from "react";
import { SortingRule } from "react-table";
import { useTranslation } from "react-i18next";
import Grid from "components/Grid";
import CircularProgress from "components/CircularProgress";
import ButtonToPlanUpgrade from "components/ButtonToPlanUpgrade";
import useIsDesktop from "utils/hooks/useIsDesktop";
import COLORS from "constants/Colors";
import Table from "components/Table";
import { OrderReportType, ProductSKUInOrderReportType, Data } from "types/SalesReport";
import { OrderReportsDataType } from "./helper/formattedOrderReports";
import { TableWrapper } from "./customTable/TableWrapper";
import { getColumns, tableHooks, getSubTableColumns } from "./customTable/config";
import { GridLoading, Hidden, Typography, DisabledReportWrapper, DisabledReportBox } from "./styled";

type ReportTablePropsType = {
  downloadOrderId: string;
  downloadPayslipLoading?: boolean;
  isBlurContent?: boolean;
  onChangeSortBy?: (sortBys: SortingRule<{ id: string; desc: boolean }>[]) => void;
  onClickDownloadPayslip?: (projectId: string, orderId: string) => void;
  onSelectAllRowInPage?: (ids: string[], isSelectedSomeRow: boolean) => void;
  onSelectRow?: (id: string) => void;
  orderReports: OrderReportsDataType[];
  orderReportsLoading?: boolean;
  projectId?: string;
  projectInfoLoading?: boolean;
  selectedIds: Record<string, boolean>;
};

const ReportTable: FC<ReportTablePropsType> = ({
  downloadOrderId,
  downloadPayslipLoading,
  isBlurContent,
  onChangeSortBy,
  onClickDownloadPayslip,
  onSelectAllRowInPage,
  onSelectRow,
  orderReports,
  orderReportsLoading,
  projectId,
  projectInfoLoading,
  selectedIds,
}) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  const tableColumns = useMemo(
    () =>
      getColumns<OrderReportType>(
        projectId,
        onClickDownloadPayslip,
        downloadPayslipLoading,
        downloadOrderId,
        onSelectRow,
        onSelectAllRowInPage,
      ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIds],
  );

  const subTableColumns = useMemo(() => getSubTableColumns<ProductSKUInOrderReportType>(isDesktop), [isDesktop]);
  const isLoading = orderReportsLoading || projectInfoLoading;
  return (
    <TableWrapper isBlurContent={isBlurContent}>
      {
        // render when orderReports are loading
        isLoading && (
          <GridLoading>
            <CircularProgress className="m-4" />
          </GridLoading>
        )
      }

      {isBlurContent && !isLoading && (
        <DisabledReportWrapper>
          <DisabledReportBox>
            <Typography variant="title2" color={COLORS.Dark} className="text-center mb-3">
              {t("salesReport.orderReports.disabled")}
            </Typography>
            {projectId && <ButtonToPlanUpgrade label={t("DisableFeature.button")} projectId={projectId} />}
          </DisabledReportBox>
        </DisabledReportWrapper>
      )}

      {/* We have to render table and hide table when there are not reports
            to prevent table reset all filter and sortBy state in the table. */}
      <Hidden className="ml-auto mr-auto" isHidden={orderReports.length === 0}>
        <Table<Data, Record<string, Data>>
          data={orderReports}
          columns={tableColumns}
          subTableColumns={subTableColumns}
          manualSortBy
          hooks={tableHooks}
          onChangeSortBy={onChangeSortBy}
          customState={{ selectedIds }}
          isBlurContent={isBlurContent}
        />
      </Hidden>

      {
        // render when no orderReports
        !orderReportsLoading && !projectInfoLoading && orderReports.length === 0 && isBlurContent && (
          <Grid container justify="center" className="mt-4">
            <Grid item xs={12}>
              <Typography variant="title2" color={COLORS.DarkLight} className="text-center">
                {
                  // No sales reports
                  t("salesReport.orderReports.placeholder")
                }
              </Typography>
            </Grid>
          </Grid>
        )
      }
    </TableWrapper>
  );
};

export default ReportTable;
