export const ninjavanDescription = {
  EN: null,
  TH: [
    { body: "พิมพ์ใบปะหน้า และแปะลงบนกล่องพัสดุ" },
    { body: "ชำระเงินก่อน 11.59 น. รับสินค้าวันเดียวกัน หากชำระหลัง 12.00 น. เข้ารับสินค้าวันถัดไป" },
    { body: "เข้ารับและจัดส่งพัสดุทุกวันจันทร์ - เสาร์ (ยกเว้นวันอาทิตย์และวันหยุด)" },
    { body: "เข้ารับพัสดุถึงบ้านเฉพาะพื้นที่กรุงเทพฯ และปริมณฑลเท่านั้น" },
    { body: "ต้องมีขั้นต่ำ 1 ชิ้น" },
    {
      body: "ปลายทางกรุงเทพฯ ปริมณฑล และต่างจังหวัด",
      subBody: [
        "1. ขนาดพัสดุ (กว้าง + ยาว + สูง) ไม่เกิน 130 เซนติเมตร และ ด้านใดด้านหนึ่งต้องไม่เกิน 100 เซนติเมตร",
        "2. น้ำหนักไม่เกิน 20 กิโลกรัม กรณีน้ำหนักเกิน 20 กก. ขนส่งจะปฏิเสธการรับพัสดุชิ้นนั้น",
      ],
    },
    {
      body: "ระยะเวลาในการจัดส่งพัสดุ",
      subBody: [
        "1. พื้นที่กรุงเทพฯ และปริมณฑล ใช้เวลาการจัดส่งประมาณ 1-2 วันทำการ",
        "2. พื้นที่ต่างจังหวัด ใช้เวลาการจัดส่งประมาณ 2-3 วันทำการ",
        "3. พื้นที่ห่างไกล ใช้เวลาประมาณ 3-6 วันทำการ ไม่รวมวันที่รับของและวันหยุดนักขัตฤกษ์ (ยกเว้นกรณีที่เกิดปัญหาต่างๆ เช่น ไม่สามารถติดต่อปลายทางได้, ภัยธรรมชาติ, อุบัติเหตุต่างๆ เป็นต้น อาจทำให้เกิดความล่าช้า)",
      ],
    },
    {
      body:
        "หากพัสดุมีขนาดหรือน้ำหนักเกินกำหนด อาจจะทำให้เกิดความล่าช้าในการจัดส่ง และจะมีการเรียกเก็บเงินค่าขนส่งเพิ่มเติมภายหลัง โดยคำนวณตามค่าน้ำหนักที่แท้จริงจากขนส่ง",
    },
    { body: "หากสินค้ามีการตีคืนจะมีการเรียกเก็บเงินภายหลัง" },
  ],
};
