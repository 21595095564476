import React from "react";

const SvgIcWarningOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4.23077C7.58172 4.23077 4 7.8125 4 12.2308C4 16.6491 7.58172 20.2308 12 20.2308C16.4183 20.2308 20 16.6491 20 12.2308C20 7.8125 16.4183 4.23077 12 4.23077ZM2 12.2308C2 6.70793 6.47715 2.23077 12 2.23077C17.5228 2.23077 22 6.70793 22 12.2308C22 17.7536 17.5228 22.2308 12 22.2308C6.47715 22.2308 2 17.7536 2 12.2308ZM12 7.23077C12.5523 7.23077 13 7.67849 13 8.23077V12.2308C13 12.7831 12.5523 13.2308 12 13.2308C11.4477 13.2308 11 12.7831 11 12.2308V8.23077C11 7.67849 11.4477 7.23077 12 7.23077ZM11 16.2308C11 15.6785 11.4477 15.2308 12 15.2308H12.01C12.5623 15.2308 13.01 15.6785 13.01 16.2308C13.01 16.7831 12.5623 17.2308 12.01 17.2308H12C11.4477 17.2308 11 16.7831 11 16.2308Z"
      fill="#FF9525"
    />
  </svg>
);

export default SvgIcWarningOutline;
