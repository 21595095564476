import { gql } from "@apollo/client";

export const ADD_PRODUCT = gql`
  mutation addProduct($projectId: ID!, $categoryId: ID!, $product: InputProduct!) {
    addProduct(projectId: $projectId, categoryId: $categoryId, product: $product) {
      id
      cfCode
      projectId
      outputType
      name
      brand
      productCode
      description
      additionalDescription
      weight
      dimension
      properties {
        id
        name
        value
      }
      categories {
        id
        projectId
        name
        description
        image
        isFree
        isActive
        isDeleted
        properties {
          name
        }
        variations {
          name
        }
      }
      images {
        src
      }
      deleted
      isCustomSKU
      defaultPrice
      productSKUs {
        id
        cfCode
        productId
        productCode
        price
        image {
          src
        }
        selected
        deleted
        isFree
        productType {
          value
          key {
            key
          }
        }
        inventory
        reserved
        createdAt
        updatedAt
        productName
        productImages {
          src
        }
        options {
          name
          isActive
          isMultipleChoices
          isRequired
          maximum
          choices {
            name
            price
            isActive
            isDefault
            isSelected
          }
        }
        weight
        dimension
        #region For digital content product
        digitalContentOptions {
          outputType
          settings {
            availability {
              type
              settings {
                # PERIOD_AFTER_PURCHASED
                # days
                unit
                value
                # DATE_RANGE
                availableAt
                # EXPIRY_DATE
                expiredAt
              }
            }
            content
          }
        }
        #endregion
      }
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($projectId: ID!, $id: ID!) {
    deleteProduct(projectId: $projectId, id: $id) {
      id
      projectId
      name
      brand
      productCode
      description
    }
  }
`;

export const EDIT_PRODUCT = gql`
  mutation editProduct($projectId: ID!, $categoryId: ID!, $productId: ID!, $product: InputProduct!) {
    editProduct(projectId: $projectId, categoryId: $categoryId, productId: $productId, product: $product) {
      id
      cfCode
      projectId
      outputType
      name
      brand
      productCode
      description
      additionalDescription
      weight
      dimension
      properties {
        id
        name
        value
      }
      categories {
        id
        projectId
        name
        description
        image
        isFree
        isActive
        isDeleted
        properties {
          name
        }
        variations {
          name
        }
      }
      images {
        src
      }
      deleted
      isCustomSKU
      defaultPrice
      productSKUs {
        id
        cfCode
        productId
        productCode
        price
        image {
          src
        }
        selected
        deleted
        isFree
        productType {
          value
          key {
            key
          }
        }
        inventory
        reserved
        createdAt
        updatedAt
        productName
        productImages {
          src
        }
        options {
          name
          isActive
          isMultipleChoices
          isRequired
          maximum
          choices {
            name
            price
            isActive
            isDefault
            isSelected
          }
        }
        weight
        dimension
        #region For digital content product
        digitalContentOptions {
          outputType
          settings {
            availability {
              type
              settings {
                # PERIOD_AFTER_PURCHASED
                # days
                unit
                value
                # DATE_RANGE
                availableAt
                # EXPIRY_DATE
                expiredAt
              }
            }
            content
          }
        }
        #endregion
      }
    }
  }
`;

export const IMPORT_PRODUCTS = gql`
  mutation importProducts($projectId: ID!, $file: Upload!) {
    importProducts(projectId: $projectId, file: $file)
  }
`;

export const IMPORT_CODES = gql`
  mutation importDigitalContents($projectId: ID!, $file: Upload!) {
    importDigitalContents(projectId: $projectId, file: $file)
  }
`;
