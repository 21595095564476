import React, { FC, useState, useCallback, useMemo, useEffect } from "react";
import { SortingRule } from "react-table";
import { useTranslation } from "react-i18next";
import { OptionType } from "react-select";
import debounce from "lodash/debounce";
import moment from "moment";
import DateFilterSelector from "domain/Order/OrderFilterSelector/dateFilter";
import CircularProgress from "components/CircularProgress";
import Button from "components/Button";
import ErrorText from "components/ErrorText";
import Grid from "components/Grid";
import Typography from "components/Typography";
import Search from "components/Search";
import ConfirmationModal from "components/ConfirmationModal";
import { IcPrint, IcBilling } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";
import {
  DEFAULT_PAGE_START,
  ROWS_PER_PAGES,
  TAB_CONFIG,
  MAXIMUM_SELECTED_ORDER_ID,
  SHIPPOP_ERROR_CODE_TYPES,
} from "config/shippop";
import { NOTIFICATION_UPDATE } from "constants/Notification";
import { MOCK_SHIPPOP_REPORTS_DATA } from "constants/Report";
import { ShipmentStatusFilter, InvoiceStatue } from "types/Report";
import { ShippopPurchaseOrderSummaryError, SHIPPOP_INVOICE_SIZE } from "types/Shipping";
import { OrderByType } from "types/SalesReport";
import { OrderState } from "types/Order";
import UpdateDimensionAndWeight from "domain/Order/OrderDetail/UpdateDimensionAndWeight";
import useToggle from "utils/hooks/useToggle";
import filterSelectedIds from "utils/salesReport";
import useGetProject from "utils/hooks/useGetProject";
import { notifySuccess, notifyError } from "utils/notify";
import Tab from "./Tab";
import ReportTable from "./Table";
import { OnSelectAllRowInPage, OnSelectRow } from "./Table/Column/getSelection";
import { formattedOrderReports } from "./helper/formattedOrderReports";
import useCreateShippopOrder from "./hooks/useCreateShippopOrder";
import usePaginationState from "./hooks/usePaginationState";
import useQueryOrderReports from "./hooks/useQueryOrderReports";
import useCreateShippopConsignmentNote from "./hooks/useCreateShippopConsignmentNote";
import useInvoiceSize from "./hooks/useInvoiceSize";
import ReportPagination from "./ReportPagination";
import Placeholder from "./Placeholder";
import PrintInvoiceModal from "./PrintInvoiceModal";
import SettleInvoiceModal from "./SettleInvoiceModal";
import { Wrapper } from "./styled";
import mapErrorMessage from "./mapErrorMessage";

type ShippopProps = {
  projectId: string;
};

const Shippop: FC<ShippopProps> = ({ projectId }) => {
  const { t } = useTranslation();
  const [startDate, setStartDateValue] = useState("");
  const [endDate, setEndDateValue] = useState("");
  const [shippopPurchaseOrderSummaryErrors, setShippopPurchaseOrderSummaryErrors] = useState<
    ShippopPurchaseOrderSummaryError[]
  >([]);
  const { invoiceSize, handleChangeInvoiceSize, resetInvoiceSize } = useInvoiceSize(t);

  const { filter, setFilter, page, rowsPerPage, setPage, setRowsPerPage, sortBy, setSortBy } = usePaginationState();
  const { printInvoice, isLoading: isLoadingCreateShippopConsignmentNote } = useCreateShippopConsignmentNote(
    projectId,
    (invoiceSize as OptionType)?.value as SHIPPOP_INVOICE_SIZE,
    t,
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [focusSelectOrder, setFocusSelectOrder] = useState<Record<string, any>>();

  useEffect(() => {
    setFilter({
      ...filter,
      dateRange: {
        start: startDate,
        end: endDate,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const handleChangeStartDate = useCallback((value: string | null) => {
    let newStartDate = "";
    if (value) {
      newStartDate = moment(value).toISOString();
    }

    setStartDateValue(newStartDate);
  }, []);

  const handleChangeEndDate = useCallback((value: string | null) => {
    let newEndDate = "";

    if (value) {
      newEndDate = moment(value).toISOString();
    }

    setEndDateValue(newEndDate);
  }, []);

  const { isSalesReportEnabled, loading: projectInfoLoading } = useGetProject(projectId);
  const { loading: orderReportsLoading, orderReports, total, cancelOrder } = useQueryOrderReports({
    projectId,
    isSkip: !isSalesReportEnabled,
    filter,
    sortBy,
    page,
    rowsPerPage,
  });

  const isReportDisabled = !orderReportsLoading && !projectInfoLoading && !isSalesReportEnabled;

  const {
    isOpen: isOpenPrintInvoiceModal,
    handleToggle: handleTogglePrintInvoiceModal,
    handleClose: closePrintInvoiceModal,
  } = useToggle();
  const [tab, setTab] = useState<string>(InvoiceStatue.PENDING);
  const [selectedIds, setSelectedIds] = useState<Record<string, boolean>>({});
  const orderIds = filterSelectedIds(selectedIds);

  const { isOpen, handleToggle, handleClose } = useToggle();

  const {
    isOpen: isOpenCancelOrderModal,
    handleToggle: handleToggleCancelOrderModal,
    handleClose: handleCloseCancelOrderModal,
  } = useToggle();
  const {
    isOpen: isShowUpdateDimensionAndWeight,
    handleToggle: handleToggleUpdateDimensionAndWeight,
    handleClose: handleCloseUpdateDimensionAndWeight,
  } = useToggle();

  const newFormattedOrderReports = useMemo(
    () =>
      isReportDisabled
        ? MOCK_SHIPPOP_REPORTS_DATA
        : formattedOrderReports(t, orderReports, projectId, shippopPurchaseOrderSummaryErrors),
    [isReportDisabled, t, orderReports, projectId, shippopPurchaseOrderSummaryErrors],
  );

  const { createShippopOrder, isLoading: isLoadingCreateShippopOrder } = useCreateShippopOrder(
    orderIds,
    projectId,
    rowsPerPage,
    page,
    sortBy,
    filter,
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickEditDimensionAndWeight = (order: any) => {
    setFocusSelectOrder(order);
    handleToggleUpdateDimensionAndWeight();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickCancelOrder = (order: any) => {
    setFocusSelectOrder(order);
    handleToggleCancelOrderModal();
  };

  const handleSelectRow: OnSelectRow = useCallback(
    (id) => {
      const isSelectedId = Boolean(selectedIds[id]);
      setSelectedIds({ ...selectedIds, [`${id}`]: !isSelectedId });
    },
    [selectedIds],
  );

  const handleSelectAllRowInPage: OnSelectAllRowInPage = useCallback(
    (ids, isSelectedSomeRow) => {
      if (!Array.isArray(ids)) {
        return;
      }

      const temp = ids.reduce((total, id) => {
        return { ...total, [`${id}`]: isSelectedSomeRow };
      }, {});

      setSelectedIds({ ...selectedIds, ...temp });
    },
    [selectedIds],
  );

  const handleChangeSortBy = useCallback(
    (sortBys: SortingRule<{ id: string; desc: boolean }>[]) => {
      const sortBy = sortBys[0];
      if (sortBy) {
        setSortBy({ sortBy: sortBy.id, orderBy: sortBy.desc ? OrderByType.DESC : OrderByType.ASC });
      }
    },
    [setSortBy],
  );

  const handleChangeRowsPerPage = useCallback(
    (newRowsPerPage: number) => {
      setRowsPerPage(newRowsPerPage);
      setPage(DEFAULT_PAGE_START);
    },
    [setPage, setRowsPerPage],
  );

  const handleClickPageNo = useCallback(
    (data: { selected: number }) => {
      const { selected } = data;

      setPage(selected + 1);
    },
    [setPage],
  );

  const handleClickArrowTab = useCallback(
    (value: string) => {
      setTab(value);
      setSelectedIds({});
      setPage(DEFAULT_PAGE_START);

      switch (value) {
        case InvoiceStatue.PENDING:
          setFilter({
            ...filter,
            orderState: [OrderState.COD_PREPARE_TO_SHIP, OrderState.PREPARE_TO_SHIPPING],
            shipmentStatus: [
              ShipmentStatusFilter.CANCEL,
              ShipmentStatusFilter.CANCELED,
              ShipmentStatusFilter.NO_SHIPMENT,
            ],
          });
          break;

        case InvoiceStatue.SETTLED:
          setFilter({
            ...filter,
            orderState: [OrderState.COD_PREPARE_TO_SHIP, OrderState.PREPARE_TO_SHIPPING, OrderState.SHIPPED],
            shipmentStatus: [ShipmentStatusFilter.BOOKING],
          });
          break;

        case InvoiceStatue.SHIPPING:
          setFilter({
            ...filter,
            orderState: [OrderState.COD_PICKED_UP, OrderState.PREPARE_TO_SHIPPING, OrderState.SHIPPED],
            shipmentStatus: [ShipmentStatusFilter.SHIPPING],
          });
          break;

        case InvoiceStatue.COMPLETED:
          setFilter({
            ...filter,
            orderState: [OrderState.COMPLETED],
            shipmentStatus: [ShipmentStatusFilter.COMPLETE],
          });
          break;

        default:
          break;
      }
    },
    [filter, setFilter, setPage],
  );

  const handleChangeSearch = useCallback(
    (newTextSearch: string) => {
      setFilter({
        ...filter,
        orderNumber: newTextSearch,
      });
    },
    [filter, setFilter],
  );

  const handleClosePrintInvoiceModal = useCallback(() => {
    closePrintInvoiceModal();
    resetInvoiceSize();
  }, [closePrintInvoiceModal, resetInvoiceSize]);

  const handleClickPrintInvoice = useCallback(async () => {
    await printInvoice(orderIds);
    handleClosePrintInvoiceModal();
  }, [handleClosePrintInvoiceModal, orderIds, printInvoice]);

  const handleSubmitCreateInVoice = useCallback(() => {
    createShippopOrder({ variables: { projectId, orderIds } })
      .then(() => {
        handleClose();
        setSelectedIds({});
        notifySuccess(t(NOTIFICATION_UPDATE.SUCCESS));
      })
      .catch((err) => {
        handleClose();
        notifyError(t(mapErrorMessage(err.message)));
      });
  }, [createShippopOrder, handleClose, orderIds, projectId, t]);

  const handleClickCreateInVoice = useCallback(() => {
    handleToggle();
  }, [handleToggle]);

  const handleError = useCallback((errors: ShippopPurchaseOrderSummaryError[]) => {
    const filteredErrors = errors.filter((error) => {
      return SHIPPOP_ERROR_CODE_TYPES.includes(error.errorCode);
    });

    setShippopPurchaseOrderSummaryErrors(filteredErrors);
  }, []);

  const selectedIdsLength = Object.values(selectedIds).filter(Boolean).length;

  const isReachLimit = selectedIdsLength > MAXIMUM_SELECTED_ORDER_ID;

  return (
    <Wrapper>
      <Typography variant="title1" className="mb-4">
        Shippop
      </Typography>

      <div className="mb-3">
        <Tab configs={TAB_CONFIG.header} value={tab} onClick={handleClickArrowTab} />
      </div>

      <Grid container spacing={2} className="mb-3">
        <Grid item xs={12} sm={6} md="auto" style={{ minWidth: 300 }}>
          <Search
            defaultValue=""
            variant="outlined"
            onChange={debounce(handleChangeSearch, 400)}
            placeholder={t("SEARCH_ORDER_NO")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md="auto" style={{ minWidth: 300 }}>
          <DateFilterSelector
            endDate={endDate}
            startDate={startDate}
            onEndDate={handleChangeEndDate}
            onStartDate={handleChangeStartDate}
          />
        </Grid>
        {tab === InvoiceStatue.SETTLED && (
          <Grid item xs={12} sm={6} md="auto">
            <Button type="button" color="primary" disabled={!selectedIdsLength} onClick={handleTogglePrintInvoiceModal}>
              <SvgIcon className="align-middle" component={IcPrint} fontSize="small" />
              {t("shippopReport.printInvoiceButton")}
              {isLoadingCreateShippopConsignmentNote && <CircularProgress size={20} className="ml-2" />}
            </Button>
          </Grid>
        )}

        {tab === InvoiceStatue.PENDING && (
          <Grid item xs={12} sm={6} md="auto">
            <Button
              type="button"
              color="primary"
              disabled={!selectedIdsLength || isReachLimit}
              onClick={handleClickCreateInVoice}
            >
              <SvgIcon className="align-middle" component={IcBilling} fontSize="small" />
              {t("shippopReport.modal.title")}
            </Button>
            {isReachLimit && (
              <ErrorText className="mt-2 d-block text-center">
                {t("parcelInvoiceReport.reachLimit", { limit: MAXIMUM_SELECTED_ORDER_ID })}
              </ErrorText>
            )}
          </Grid>
        )}
      </Grid>

      {
        // render when no orderReports
        !orderReportsLoading && !projectInfoLoading && orderReports.length === 0 && isSalesReportEnabled ? (
          <Placeholder message={t("salesReport.orderReports.placeholder")} />
        ) : (
          <ReportTable
            isBlurContent={isReportDisabled}
            onChangeSortBy={handleChangeSortBy}
            onSelectAllRowInPage={handleSelectAllRowInPage}
            onClickCancelOrder={handleClickCancelOrder}
            onSelectRow={handleSelectRow}
            orderReports={newFormattedOrderReports}
            orderReportsLoading={orderReportsLoading}
            projectId={projectId}
            projectInfoLoading={projectInfoLoading}
            selectedIds={selectedIds}
            onClickEditDimensionAndWeight={handleClickEditDimensionAndWeight}
            state={tab}
          />
        )
      }

      {!isReportDisabled && (
        <ReportPagination
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onClickPageNo={handleClickPageNo}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGES}
          total={total}
        />
      )}

      <PrintInvoiceModal
        isOpen={isOpenPrintInvoiceModal}
        isLoading={isLoadingCreateShippopConsignmentNote}
        invoiceSize={invoiceSize}
        onSubmit={handleClickPrintInvoice}
        onClose={handleClosePrintInvoiceModal}
        onChange={handleChangeInvoiceSize}
      />

      <UpdateDimensionAndWeight
        carrierName={focusSelectOrder?.shipping}
        shippopError={focusSelectOrder?.shippopError}
        projectId={projectId}
        orderId={focusSelectOrder?.id}
        totalWeight={focusSelectOrder?.totalWeight}
        largestDimension={focusSelectOrder?.largestDimension}
        isOpen={isShowUpdateDimensionAndWeight}
        onClose={handleCloseUpdateDimensionAndWeight}
        closeModal={() => {
          handleCloseUpdateDimensionAndWeight();
          const newShippopPurchaseOrderSummaryErrors = shippopPurchaseOrderSummaryErrors.filter(
            (shippopPurchaseOrderSummaryError) => {
              return shippopPurchaseOrderSummaryError.orderId.toString() !== focusSelectOrder?.id;
            },
          );
          setShippopPurchaseOrderSummaryErrors(newShippopPurchaseOrderSummaryErrors);
        }}
        variables={{
          projectId,
          pageSize: rowsPerPage,
          page: page - 1,
          filter: { ...sortBy, ...filter },
        }}
      />

      <ConfirmationModal
        title={t("CONFIRM_CANCEL_SHIPPOP_ORDER", { orderNo: focusSelectOrder?.orderNumber })}
        isOpen={isOpenCancelOrderModal}
        onClose={() => handleCloseCancelOrderModal()}
        textSubmit={t("Submit")}
        onSubmit={async () => {
          if (focusSelectOrder?.id) {
            try {
              await cancelOrder({ variables: { projectId, orderId: focusSelectOrder?.id } });
              notifySuccess(t(NOTIFICATION_UPDATE.SUCCESS));
              handleCloseCancelOrderModal();
            } catch (error) {
              console.error("error", error);
              notifyError(t(NOTIFICATION_UPDATE.FAIL));
              handleCloseCancelOrderModal();
            }
          }
        }}
      />

      {isOpen && (
        <SettleInvoiceModal
          isOpen
          projectId={projectId}
          onClickConfirm={handleSubmitCreateInVoice}
          onClose={handleClose}
          orderIds={orderIds}
          isLoading={isLoadingCreateShippopOrder}
          onError={handleError}
        />
      )}
    </Wrapper>
  );
};

export default Shippop;
