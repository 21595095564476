import styled, { css } from "styled-components";

import Card from "components/Card";
import Grid from "components/Grid";

type RichMenuCardWrapperPropsType = {
  isSelected: boolean;
};

type PreviewItemWrapperPropsType = {
  isDefault: boolean;
};

export const RichMenuCardWrapper = styled(Card)<RichMenuCardWrapperPropsType>`
  max-height: 210px;
  max-width: 260px;

  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
      border-color: ${({ theme }) => theme.COLORS.Primary};
    `}
`;

export const MenuButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
`;

export const PreviewItemWrapper = styled.div<PreviewItemWrapperPropsType>`
  width: 225px;
  height: 150px;

  ${(props) =>
    !props.isDefault &&
    css`
      cursor: pointer;
    `}
`;

export const PreviewImageContainer = styled.img`
  object-fit: cover;
  box-shadow: ${({ theme }) => `0 5px 10px 0 ${theme.COLORS.BoxShadow}`};
`;

export const AddNewRichMenuCard = styled(Grid)`
  cursor: pointer;
  height: 214px;
  width: 260px;
  min-width: 260px;
  background: ${({ theme }) => theme.COLORS.LightWhite};
  border: 1.5px dashed ${({ theme }) => theme.COLORS.LightBlue};
  border-radius: 8px;
`;

export const RichMenuCardContainer = styled(Grid)`
  display: flex;
  overflow: scroll;
`;
