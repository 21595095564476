import { Days, OpeningHoursType } from "types/Branch";

export const DEFAULT_WORKING_HOURS = {
  OPEN: "09:00",
  CLOSE: "18:00",
};

export const OPENING_HOURS: OpeningHoursType[] = [
  {
    day: Days.SUNDAY,
    open: DEFAULT_WORKING_HOURS.OPEN,
    close: DEFAULT_WORKING_HOURS.CLOSE,
    isActive: true,
  },
  {
    day: Days.MONDAY,
    open: DEFAULT_WORKING_HOURS.OPEN,
    close: DEFAULT_WORKING_HOURS.CLOSE,
    isActive: true,
  },
  {
    day: Days.TUESDAY,
    open: DEFAULT_WORKING_HOURS.OPEN,
    close: DEFAULT_WORKING_HOURS.CLOSE,
    isActive: true,
  },
  {
    day: Days.WEDNESDAY,
    open: DEFAULT_WORKING_HOURS.OPEN,
    close: DEFAULT_WORKING_HOURS.CLOSE,
    isActive: true,
  },
  {
    day: Days.THURSDAY,
    open: DEFAULT_WORKING_HOURS.OPEN,
    close: DEFAULT_WORKING_HOURS.CLOSE,
    isActive: true,
  },
  {
    day: Days.FRIDAY,
    open: DEFAULT_WORKING_HOURS.OPEN,
    close: DEFAULT_WORKING_HOURS.CLOSE,
    isActive: true,
  },
  {
    day: Days.SATURDAY,
    open: DEFAULT_WORKING_HOURS.OPEN,
    close: DEFAULT_WORKING_HOURS.CLOSE,
    isActive: true,
  },
];
export default OPENING_HOURS;
