// GraphQL libs
import {
  ApolloClient,
  from,
  split,
  InMemoryCache,
  PossibleTypesMap,
  NormalizedCacheObject,
  ApolloLink,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

import { API_BASE_URL, IS_UNIVERSAL_ERROR_ENABLE } from "config";

import { getToken, checkTokenExpired, removeToken } from "utils/tokenUtil";
import possibleTypesData from "utils/possibleTypes.json";
import { UNIVERSAL_ERROR_CODES } from "constants/ErrorMessages";
import resolvers from "./resolvers";
import typePolicies from "./typePolicies";

let apolloClient: ApolloClient<NormalizedCacheObject>;

window.getApolloClient = getApolloClient;

function getApolloClient(setError: (errorCode: number) => void) {
  if (apolloClient) {
    return apolloClient;
  }
  // Create an http link:
  const rawHttpLink = createUploadLink({
    uri: `${API_BASE_URL}/graphql`,
    headers: {
      "apollo-require-preflight": "true",
    },
  });

  const authLink = setContext(async () => {
    const token = getToken();

    // TODO: This is temporary solution to handle token expired problem.
    // Better way is to interpret from error metadata and decide whether to reload or not
    if (token) {
      const isTokenExpired = checkTokenExpired(token);
      if (isTokenExpired) {
        removeToken();
        window.location.reload();
      }
    }
    return {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors && IS_UNIVERSAL_ERROR_ENABLE) {
      const errorCode: number = graphQLErrors[0]?.extensions?.exception?.statusCode;
      if (UNIVERSAL_ERROR_CODES.includes(errorCode)) {
        setError(errorCode);
      }
    }

    if (networkError && IS_UNIVERSAL_ERROR_ENABLE) {
      setError(500);
    }
  });

  // Create a WebSocket link:
  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${API_BASE_URL.replace("http", "ws")}/graphql`,
      lazy: true,
      keepAlive: 0,
      retryAttempts: 20,
      connectionParams: async () => {
        const userToken = getToken();
        return {
          token: userToken,
        };
      },
    }),
  );

  // rawHttpLink need to be last
  const combineHttpLink = from([authLink, errorLink, (rawHttpLink as unknown) as ApolloLink]);

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    combineHttpLink,
  );

  // Create possible types
  const possibleTypes = (possibleTypesData as unknown) as PossibleTypesMap | undefined;
  const cache = new InMemoryCache({
    possibleTypes,
    typePolicies,
  });

  // Finally, create your ApolloClient instance with the modified network interface
  const newApolloClient = new ApolloClient({
    link,
    cache,
    resolvers,
  });

  apolloClient = newApolloClient;

  return apolloClient;
}

export { getApolloClient };
