import React, { useState } from "react";
import Grid from "components/Grid";
import Typography from "components/Typography";
import Card from "components/Card";
import CollapseButton from "components/CollapseButton";
import { convertPriceFormat } from "utils/common";
import { Shipping } from "types";
import { Device } from "types/Device";
import { IcArrowDown } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";
import useDevice from "utils/hooks/useDevice";
import { useTranslation } from "react-i18next";
import COLORS from "constants/Colors";
import { SUPPORTED_LANGUAGE } from "constants/Language";

import groupBy from "lodash/groupBy";

import {
  PostalCodeTextWrapper,
  TableHeader,
  TableRowWrapper,
  PriceTextWrapper,
  PostalCodeGroupTextWrapper,
} from "../styled";
import { getFullNameFromProvinceCode, getPostcodeLabelFormat } from "../PostalCodeBasedShipping/util";

type ShippingMethodCardPropsType = {
  locationSettings: Shipping.ProvinceSetting[];
};

export const LocationSettingCard: React.FC<ShippingMethodCardPropsType> = (props) => {
  const { t, i18n } = useTranslation();
  const isThaiLanguage = i18n.language === SUPPORTED_LANGUAGE.TH;
  const device = useDevice();
  const isMobile = device === Device.MOBILE;

  const { locationSettings } = props;
  const [isInfoExpanded, setIsInfoExpanded] = useState(!isMobile);

  const handleToggle = () => {
    setIsInfoExpanded(!isInfoExpanded);
  };

  return (
    <Card noShadow fullWidth className={isMobile ? "mt-3 pb-1" : "px-4 mt-4 pb-2"}>
      <Grid container className="pb-3 pt-1" alignItems="flex-end" justify="space-between">
        <Grid item container xs={8} alignItems="center">
          <Typography variant="title8" className="mt-2">
            {t("shipping.label.locationSetting")}
          </Typography>
        </Grid>
        {/* COLLAPSE ICON BUTTON */}
        <Grid item className="ml-2">
          <CollapseButton degree={90} isShow={isInfoExpanded} onClick={handleToggle}>
            <SvgIcon component={IcArrowDown} fontSize="small" />
          </CollapseButton>
        </Grid>
      </Grid>

      <Grid container className={isMobile ? "pt-2" : "pt-2"}>
        <TableHeader item container className={`${isMobile ? "px-3" : "px-4"} py-3 mb-1`}>
          <Grid item xs={9} sm={10}>
            <Typography variant="title8" color={COLORS.Primary}>
              {t("shipping.table.header.province")}
            </Typography>
          </Grid>

          <Grid item xs={3} sm={2}>
            <Typography variant="title8" color={COLORS.Primary}>
              {t("shipping.table.header.shippingRates")}
            </Typography>
          </Grid>
        </TableHeader>
        {locationSettings
          .filter((province) => province.isActive)
          .map((province) => {
            const postalCodeGroupedByPrice =
              province.postalCodes && province.postalCodes.length ? groupBy(province.postalCodes, "price") : null;

            return (
              <TableRowWrapper item container key={province.name} className="pb-3">
                <Grid item container className={`${isMobile ? "px-1" : "px-4"} py-3`} key={province.name}>
                  <Grid item xs={9} sm={10}>
                    <Typography variant="body3" color={COLORS.Primary}>
                      {getFullNameFromProvinceCode(province.name, isThaiLanguage)}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <Typography variant="body3" color={COLORS.Primary}>
                      {t("shipping.table.columnValue.defaultRate", {
                        price: convertPriceFormat((province && province.price) || 0),
                      })}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container className={`${isMobile ? "px-1" : "px-3"}`}>
                  {isInfoExpanded &&
                    postalCodeGroupedByPrice &&
                    Object.values(postalCodeGroupedByPrice).map(
                      (postalCodeList: Shipping.PostalCodeSetting[], postalCodeListIndex: number) => {
                        const postalCodePrice = postalCodeList[0].price;
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <PostalCodeTextWrapper item key={province.name + postalCodeListIndex + postalCodeListIndex}>
                            <Typography variant="body3" color={COLORS.Primary}>
                              <Grid container alignContent="center" alignItems="center">
                                <PostalCodeGroupTextWrapper item className="hiddenOverFlowText">
                                  {`${postalCodeList.map(({ postalCode }: Shipping.PostalCodeSetting) =>
                                    getPostcodeLabelFormat(postalCode, province.name, isThaiLanguage),
                                  )}`}
                                </PostalCodeGroupTextWrapper>
                                <PriceTextWrapper item>{`${t("THB")} ${convertPriceFormat(
                                  postalCodePrice,
                                )}`}</PriceTextWrapper>
                              </Grid>
                            </Typography>
                          </PostalCodeTextWrapper>
                        );
                      },
                    )}
                </Grid>
              </TableRowWrapper>
            );
          })}
      </Grid>
    </Card>
  );
};
