import * as React from "react";

const SvgIcHelp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 2c7.732 0 14 6.268 14 14s-6.268 14-14 14S2 23.732 2 16 8.268 2 16 2zm-.443 20.951c-.505 0-.934.182-1.289.546a1.802 1.802 0 00-.532 1.303c0 .504.177.933.532 1.288.355.355.784.532 1.289.532a1.8 1.8 0 001.302-.532c.364-.355.546-.784.546-1.288s-.182-.939-.546-1.303a1.778 1.778 0 00-1.302-.546zm.476-16.75c-1.083 0-2.012.178-2.787.533-.775.354-1.415.812-1.919 1.372a5.549 5.549 0 00-1.12 1.863 5.873 5.873 0 00-.364 1.974c0 .505.056.953.168 1.345l2.717.336a3.053 3.053 0 01-.14-.616 5.35 5.35 0 01-.056-.757c0-.448.07-.886.21-1.316.14-.43.35-.812.63-1.148a3.09 3.09 0 011.078-.813c.44-.205.967-.308 1.583-.308.56 0 1.05.089 1.47.266.42.178.766.416 1.037.715.27.298.476.64.616 1.022.14.383.21.78.21 1.19 0 .542-.126 1.055-.378 1.54a3.166 3.166 0 01-1.107 1.205l-1.232.84c-.84.56-1.438 1.196-1.793 1.905-.354.71-.532 1.531-.532 2.465v.504c0 .15.01.308.028.476h2.465a2.96 2.96 0 01-.028-.322v-.21c0-.541.098-1.05.294-1.526.196-.476.509-.864.938-1.163l1.289-.868a7.33 7.33 0 002.045-2.003c.541-.793.812-1.75.812-2.87a5.62 5.62 0 00-.406-2.087 5.295 5.295 0 00-1.19-1.807c-.524-.523-1.168-.943-1.933-1.26-.766-.318-1.634-.477-2.605-.477z"
    />
  </svg>
);

export default SvgIcHelp;
