import * as React from "react";

const IcLongArrowDown = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.595 6.211c.104-.133.26-.211.426-.211.165 0 .32.078.425.211l2.47 3.165c.193.247.159.61-.076.81-.235.202-.582.166-.774-.08l-1.45-1.869v9.672h-1.168V8.237l-1.473 1.868c-.109.14-.266.212-.425.212-.123 0-.247-.043-.349-.13-.234-.201-.27-.564-.077-.81l2.47-3.166z"
    />
  </svg>
);

export default IcLongArrowDown;
