import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import { CONNECT_ACCOUNT_SOCIAL, DISCONNECT_ACCOUNT_SOCIAL } from "graphql/auth/mutation";

export type ConnectResponse = {};

export type ConnectAccountVariables = {
  authProvider?: string;
  token?: string;
  deviceProvider?: string;
};

const useConnectAccount = () => {
  const [connectAccount, { loading: isConnectAccountLoading, error: connectAccountError }] = useMutation<
    ConnectResponse,
    ConnectAccountVariables
  >(CONNECT_ACCOUNT_SOCIAL);
  const [disconnectAccount, { loading: isDisconnectAccountLoading, error: disconnectAccountError }] = useMutation<
    ConnectResponse,
    ConnectAccountVariables
  >(DISCONNECT_ACCOUNT_SOCIAL);

  const connectSocialAccount = useCallback(
    async (token: string, authProvider: string, deviceProvider?: string) => {
      const result = await connectAccount({
        variables: {
          token,
          authProvider,
          deviceProvider,
        },
      });

      if (!result?.data) {
        throw connectAccountError;
      }
    },
    [connectAccount, connectAccountError],
  );

  const disconnectAccountSocial = useCallback(
    async (authProvider: string) => {
      const result = await disconnectAccount({
        variables: {
          authProvider,
        },
      });

      if (!result?.data) {
        throw disconnectAccountError;
      }
    },
    [disconnectAccount, disconnectAccountError],
  );

  return {
    connectSocialAccount,
    disconnectAccountSocial,
    isConnectAccountLoading,
    isDisconnectAccountLoading,
  };
};

export default useConnectAccount;
