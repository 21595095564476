import React, { FC } from "react";
import get from "lodash/get";

import { CarouselContainer } from "components/FlexMessage/FlexMessage.style";
import { CategoryType } from "types/Category";
import { CategoriesObjectType } from "types/AISetting";
import FlexCategoryCard from "./FlexCategoryCard";

type FlexCategoriesPropsType = {
  content: CategoriesObjectType;
  isEnableDelete?: boolean;
  onClickDelete?: (categoryId: string) => void;
};

const FlexCategories: FC<FlexCategoriesPropsType> = (props: FlexCategoriesPropsType) => {
  const { content, isEnableDelete, onClickDelete } = props;
  const categories = get(content, "categories") || [];

  return (
    <CarouselContainer displayScrollbar>
      {categories.map((category: CategoryType) => (
        <FlexCategoryCard
          key={category.id}
          category={category}
          isEnableDelete={isEnableDelete}
          onClickDelete={onClickDelete}
        />
      ))}
    </CarouselContainer>
  );
};
export default FlexCategories;
