import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import Grid from "components/Grid";
import Modal from "components/Modal";
import Typography from "components/Typography";

type RemoveCategoryModalPropsType = {
  isOpen: boolean;
  onClose: () => void;
  onRemove: () => void;
};

const RemoveCategoryModal: FC<RemoveCategoryModalPropsType> = (props) => {
  const { t } = useTranslation();
  const { isOpen, onClose, onRemove } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Grid container justify="center" className="p-3">
        <Grid item xs={8} className="text-center py-3">
          <Typography variant="title8" color="dark">
            {t("Are you sure you want to remove this category?")}
          </Typography>
        </Grid>
        <Grid item container spacing={1} xs={12} className="pt-1">
          <Grid item xs={6}>
            <Button size="small" color="ghost" fullWidth onClick={onClose}>
              {t("Cancel")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button size="small" fullWidth onClick={onRemove}>
              {t("Remove")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default RemoveCategoryModal;
