import { useQuery } from "@apollo/client";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FAQ_BY_ID } from "graphql/ai-setting/query";
import { FaqQueryByIdType, FaqType, ChangedFaqType } from "types/AISetting";
import Button from "components/Button";
import FaqForm from "domain/Faq/FaqThEn/FaqForm";
import mapIdAndValue from "utils/faq/mapIdAndValue";
import mapColorIntents from "utils/faq/mapColorIntents";
import { CheckDuplicatedQuestionType } from "../hooks/useCheckDuplicatedQuestionFromAll";
import Loading from "./Loading";

type FaqContainerProps = {
  projectId: string;
  faqId: string;
  isLoadingSubmit: boolean;
  isEnableEN: boolean;
  onSubmit: (faqData: FaqType) => void;
  onCancel: () => void;
  checkDuplicatedQuestionFromAll?: CheckDuplicatedQuestionType;
};

const FaqContainer: FC<FaqContainerProps> = ({
  projectId,
  faqId,
  isLoadingSubmit,
  isEnableEN,
  onSubmit,
  onCancel,
  checkDuplicatedQuestionFromAll,
}) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery<FaqQueryByIdType>(FAQ_BY_ID, { variables: { projectId, faqId } });
  const faq = data?.faqById;

  const faqWithId: ChangedFaqType = useMemo(
    () => ({
      id: faq?.id || "",
      responses: faq?.responses || [],
      enResponses: faq?.enResponses || [],
      isActive: faq?.isActive || false,
      isEnabledNotification: faq?.isEnabledNotification || false,
      questions: mapIdAndValue(faq?.questions || []),
      enQuestions: mapIdAndValue(faq?.enQuestions || []),
      colorIntents: mapColorIntents(faq?.colorIntents || []),
    }),
    [faq],
  );

  if (loading && !faq) {
    return (
      <Loading loadingLabel={t("LOADING")}>
        <Button color="ghost" onClick={onCancel}>
          {t("Cancel")}
        </Button>
      </Loading>
    );
  }

  return (
    <FaqForm
      isLoadingSubmit={isLoadingSubmit}
      projectId={projectId}
      faq={faqWithId}
      onSubmit={onSubmit}
      onCancel={onCancel}
      checkDuplicatedQuestionFromAll={checkDuplicatedQuestionFromAll}
      isEnableEN={isEnableEN}
    />
  );
};

export default FaqContainer;
