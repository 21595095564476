import produce from "immer";
import { ChangedFaqQuestionType, FaqType, ChangedFaqType } from "types/AISetting";
import { ValueOf } from "types/Common";
import nanoid from "utils/nanoid";

export type AddQuestionType = () => {
  id: string;
  value: string;
  index: number;
};

const useManageQuestions = (
  currentTabLanguage = 0,
  setFAQ: (fieldName: keyof FaqType, value: ValueOf<ChangedFaqType>) => void,
  getQuestions: () => ChangedFaqQuestionType[],
) => {
  const getQuestionField = () => {
    return currentTabLanguage === 0 ? "questions" : "enQuestions";
  };

  const addQuestion: AddQuestionType = () => {
    const questionsField = getQuestionField();
    const questions = getQuestions();
    const newId = nanoid();
    const newQuestion = { id: newId, value: "" };

    const newQuestions = produce(questions, (draft) => {
      draft.push(newQuestion);
    });

    setFAQ(questionsField, newQuestions);

    return { ...newQuestion, index: questions.length };
  };

  const updateQuestion = (newQuestion: string, id: string) => {
    const trimmedQuestion = newQuestion.trim();
    const questionsField = getQuestionField();
    const questions = getQuestions();
    const index = questions.findIndex((question) => question.id === id);

    const newQuestions = produce(questions, (draft) => {
      draft[index] = { ...questions[index], value: trimmedQuestion };
    });

    setFAQ(questionsField, newQuestions);
  };

  const removeQuestion = (id: string) => {
    const questionsField = getQuestionField();
    const questions = getQuestions();
    const newQuestions = produce(questions, (draft) => {
      return draft.filter((question) => question.id !== id);
    });

    setFAQ(questionsField, newQuestions);

    return id;
  };

  return {
    getQuestionField,
    getQuestions,
    addQuestion,
    updateQuestion,
    removeQuestion,
  };
};

export default useManageQuestions;
