import flatMap from "lodash/flatMap";
import { ProductOptionType } from "types/Product";

export const formattedOptions = (options?: ProductOptionType[]) => {
  if (!options) {
    return [];
  }

  const activeOptions = options.filter((option) => option.isActive);
  const choices = flatMap(activeOptions, (filterOption) => {
    const selectedChoices = filterOption.choices.filter((choice) => choice.isSelected);
    return selectedChoices;
  });

  return choices.map((choice) => choice.name).join(", ");
};
