import React, { FC } from "react";

import Grid from "components/Grid";

import { ChangedDataValueType, CategoryType } from "types/Category";

import CategoryCard from "../CategoryCard";
import { useAddCategory } from "../hooks";

type NewCategoryCardPropsType = {
  projectId: string;
  category: CategoryType;
  handleClearNewCategory: () => void;
  onChange: (id: string, fieldName: keyof CategoryType, value: ChangedDataValueType) => void;
};

const NewCategoryCard: FC<NewCategoryCardPropsType> = (props) => {
  const { projectId, category, handleClearNewCategory, onChange } = props;

  const { handleAddCategory } = useAddCategory(projectId, handleClearNewCategory);

  const handleClickUpdate = () => {
    handleAddCategory(category);
  };

  const handleChangeData = (fieldName: keyof CategoryType, value: ChangedDataValueType) => {
    onChange(category.id, fieldName, value);
  };

  return (
    <Grid container item xs={12} md={6} className="p-2">
      <Grid item xs={12}>
        <CategoryCard
          dataCy="newCategoryCard"
          category={category}
          isEditMode
          isHiddenCloseButton
          onClickDelete={handleClearNewCategory}
          onChangeData={handleChangeData}
          onClickUpdate={handleClickUpdate}
        />
      </Grid>
    </Grid>
  );
};

export default NewCategoryCard;
