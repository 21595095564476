import { CrawlerType } from "types/FacebookCrawler";

export const MAX_PAGE_SIZE = 20;
export const MAX_TAG_CHARACTER_LENGTH = 50;

export const CHANNELS = () =>
  ({
    [CrawlerType.POST]: "Facebook post",
    [CrawlerType.MEDIA]: "Instagram post",
    [CrawlerType.STORY]: "Instagram story",
  } as Record<CrawlerType, string>);

export const CHANNEL_OPTIONS = () =>
  Object.keys(CHANNELS()).map((key) => ({
    value: key,
    label: CHANNELS()[key as CrawlerType],
  }));
