import * as yup from "yup";

const schema = yup.object().shape({
  paymentNotiHr: yup
    .number()
    .typeError("Payment notification time (hours) required")
    .integer("Should be positive number and integer")
    .min(0, "Should be positive number and integer"),
  paymentNotiMin: yup.number().when("paymentNotiHr", (paymentNotiHr: number) => {
    if (paymentNotiHr === 0) {
      return yup
        .number()
        .typeError("Payment notification time (mins) is required")
        .integer("Should be positive number and integer")
        .min(0, "Should be positive number and integer")
        .max(59, "Minute cannot be above 59")
        .test("time is 0", "Time cannot be 0", (val) => Number(val) > 0);
    }
    return yup
      .number()
      .typeError("Payment notification time (mins) is required")
      .integer("Should be positive number and integer")
      .min(0, "Should be positive number and integer")
      .max(59, "Minute cannot be above 59");
  }),
  reserveExpHr: yup
    .number()
    .typeError("Reserved product expiration time (hours) is required")
    .integer("Should be positive number and integer")
    .min(0, "Should be positive number and integer"),
  reserveExpMin: yup.number().when("reserveExpHr", (reserveExpHr: number) => {
    if (reserveExpHr === 0) {
      return yup
        .number()
        .typeError("Reserved product expiration time (mins) is required")
        .integer("Should be positive number and integer")
        .min(0, "Should be positive number and integer")
        .max(59, "Minute cannot be above 59")
        .test("time is 0", "Time cannot be 0", (val) => Number(val) > 0);
    }
    return yup
      .number()
      .typeError("Reserved product expiration time (mins) is required")
      .integer("Should be positive number and integer")
      .min(0, "Should be positive number and integer")
      .max(59, "Minute cannot be above 59");
  }),
  orderNumberPrefix: yup
    .string()
    .required("orderSetting.error.orderNumberPrefix.required")
    .matches(/^[A-Z]{2,4}$/, "orderSetting.error.customPrefix.matches"),
  receiptNumberPrefix: yup
    .string()
    .required("orderSetting.error.receiptNumberPrefix.required")
    .matches(/^[A-Z]{2,4}$/, "orderSetting.error.customPrefix.matches"),
});

export default schema;
