import React, { useState } from "react";
import styled from "styled-components";
import ScrollToTop from "components/ScrollToTop";
import get from "lodash/get";

import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import Typography from "components/Typography";
import Modal from "components/Modal";
import CircularProgress from "components/CircularProgress";
import Search from "components/Search";

import PageTitle from "components/PageTitle";
import Button, { FloatingButton } from "components/Button";
import { useQuery, useMutation } from "@apollo/client";
import { MEMBERS, INVITED_MEMBERS } from "graphql/setting/query";

import { FLAG_USER_WITH_IS_ENTERPRISE } from "graphql/auth/query";
import { UserProfileType } from "types/User";
import { useParams } from "react-router-dom";
import { ProjectIdType } from "types/Project";
import { Member, MEMBER_ROLE } from "types/Setting";
import { SvgIcon } from "components/Icon";
import { IcAdd, IcArrowToTop } from "components/SvgIcons";
import { COLORS } from "constants/Colors";
import {
  CANCEL_INVITED_MEMBER,
  DELETE_MEMBER,
  EDIT_MEMBER,
  GENERATE_NOTIFICATION_KEY,
  REMOVE_LINE_NOTIFICATION,
} from "graphql/setting/mutation";
import { Device } from "types/Device";
import { notifySuccess, notifyError } from "utils/notify";
import useToggle from "utils/hooks/useToggle";
import useDevice from "utils/hooks/useDevice";
import ConfirmModal from "components/ConfirmModal";
import { StickyButtonContainer } from "components/StickyPanel";
import useScrollToTopElement from "utils/hooks/useScrollToTopElement";
import { DEFAULT_SCROLL } from "constants/Scroll";
import MemberItem from "./MemberItem";
import MenuList from "./MenuList";
import { AddMemberModal } from "./AddMemberModal";

import { MemberTypeTitle } from "./styled";

const MemberWrapper = styled(Grid)`
  padding-bottom: 150px;
`;

export const StoreMembers: React.FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const device = useDevice();
  const isDesktop = device === Device.DESKTOP;

  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [filterUserName, setFilterUserName] = useState("");
  const [selectMemberId, setSelectMemberId] = useState("");
  const [selectMemberRole, setSelectMemberRole] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(false);
  const {
    isOpen: isCancelInviteModalOpen,
    handleToggle: handleToggleInviteModal,
    handleClose: handleCloseInviteModal,
  } = useToggle();

  const { loading: loadingMembersData, data: membersDataFromQuery, refetch: refetchMembers } = useQuery(MEMBERS, {
    skip: !projectId,
    variables: {
      projectId,
    },
  });

  const { loading: loadingInvitedMembersData, data: invitedMembersData } = useQuery(INVITED_MEMBERS, {
    skip: !projectId,
    variables: {
      projectId,
    },
  });

  // using this to update cache graphql on user permission data
  const { data: dataUser, refetch: refetchUser } = useQuery<UserProfileType>(FLAG_USER_WITH_IS_ENTERPRISE, {
    variables: { projectId },
  });

  const { scrollTopId, handleScrollToTop, isScroll } = useScrollToTopElement(
    DEFAULT_SCROLL.STORE_MEMBER_SCROLL_TOP,
    isDesktop,
  );

  const currentUser = dataUser && dataUser.me;
  const permissions = currentUser && currentUser.permissions;
  const currentProject = permissions && permissions.find((permission) => permission.projectId === projectId);
  const isOwner = (currentProject && currentProject.role) === MEMBER_ROLE.OWNER;
  const userId = currentUser && currentUser.id;

  const [deleteMember] = useMutation(DELETE_MEMBER, {
    onError: () => {
      notifyError(t("Member delete failed!"));
    },
    onCompleted: () => {
      notifySuccess(t("Member deleted"));
      setAnchorEl(null);
      refetchMembers();
      setIsOpenConfirmDeleteModal(false);
    },
  });

  const [cancelInvitedMember] = useMutation(CANCEL_INVITED_MEMBER, {
    onError: () => {
      notifyError(t("setting.members.cancelInvitedMember.error"));
    },
    onCompleted: () => {
      notifySuccess(t("setting.members.cancelInvitedMember.success"));
      setAnchorEl(null);
      refetchMembers();
      setIsOpenConfirmDeleteModal(false);
    },
    update(cache, { data: { cancelInvitedMember: cacheCancelInvitedMember } }) {
      const cacheInvitedMembers = cache.readQuery<{ invitedMembers: Member[] }>({
        query: INVITED_MEMBERS,
        variables: {
          projectId,
        },
      });

      if (
        cacheInvitedMembers &&
        cacheInvitedMembers.invitedMembers &&
        cacheInvitedMembers.invitedMembers.length &&
        cacheCancelInvitedMember.id
      ) {
        const newInvitedMembers = cacheInvitedMembers.invitedMembers.filter(
          (invitedMember) => invitedMember.id !== cacheCancelInvitedMember.id,
        );

        cache.writeQuery({
          query: INVITED_MEMBERS,
          variables: {
            projectId,
          },
          data: {
            invitedMembers: [...newInvitedMembers],
          },
        });
      }
    },
  });

  const [editMemberRole] = useMutation(EDIT_MEMBER, {
    onError: () => {
      notifyError(t("Change member role failed!"));
    },
    onCompleted: () => {
      notifySuccess(t("Member role changed"));
      setAnchorEl(null);
      refetchMembers();
      refetchUser();
    },
  });

  const [generateKey, { data: generateKeyData }] = useMutation(GENERATE_NOTIFICATION_KEY, {
    onError: () => {
      notifyError(t("Generate OTP failed!"));
    },
    onCompleted: () => {
      notifySuccess(t("OTP Generated"));
    },
  });

  const [removeLineNotification] = useMutation(REMOVE_LINE_NOTIFICATION, {
    onError: () => {
      notifyError(t("Remove notification failed!"));
    },
    onCompleted: () => {
      notifySuccess(t("Notification removed"));
      refetchMembers();
    },
  });

  const handleEditMemberRole = (id: string, role: string) => {
    editMemberRole({
      variables: {
        projectId,
        id,
        role,
      },
    });
  };

  const handleDeleteMember = (id: string) => {
    deleteMember({
      variables: {
        projectId,
        id,
      },
    });
  };

  const handleGenerateOTP = (id: string) => {
    generateKey({
      variables: {
        projectId,
        userId: id,
      },
    });
  };

  const handleCancelInvitation = (id: string, invitedMembers: Member[]) => {
    const selectedMember = invitedMembers.find((invitedMember) => invitedMember.id === id);
    if (!selectedMember) {
      return;
    }

    cancelInvitedMember({
      variables: {
        projectId,
        email: selectedMember.email,
      },
    });
  };

  const handleRemoveNotification = (id: string) => {
    removeLineNotification({
      variables: {
        projectId,
        permissionId: id,
      },
    });
  };

  const openAddMemberModal = () => {
    setIsAddMemberModalOpen(true);
  };

  const onCloseAddMemberModal = () => {
    setIsAddMemberModalOpen(false);
  };

  const handleClickMenuButton = (event: React.MouseEvent<HTMLElement>, id: string, role: string) => {
    setSelectMemberId(id);
    setSelectMemberRole(role);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenuList = () => {
    setAnchorEl(null);
  };

  // using to filter all member by display name or email from search text field
  const filterUser = (members: Member[], role: string) => {
    return members.filter((member: Member) => {
      if (member.role === role) {
        if (filterUserName === "") {
          return member;
        }
        const displayName = get(member, "user.displayName");
        return (
          member.role === role &&
          ((displayName && displayName.toLowerCase().includes(filterUserName.toLowerCase())) ||
            member.email.includes(filterUserName.toLowerCase()))
        );
      }
      return null;
    });
  };

  const invitedMembers: Member[] = get(invitedMembersData, "members", []);
  const invitedAdmins = filterUser(invitedMembers, MEMBER_ROLE.ADMIN);
  const invitedOwners = filterUser(invitedMembers, MEMBER_ROLE.OWNER);
  const allMembers = get(membersDataFromQuery, "members", []);
  const userAdmins = filterUser(allMembers, MEMBER_ROLE.ADMIN);
  const userOwners = filterUser(allMembers, MEMBER_ROLE.OWNER);
  const userAdminsAndInvitedAdmins = [...userAdmins, ...invitedAdmins];
  const userOwnersAndInvitedOwners = [...userOwners, ...invitedOwners];
  const totalOwner = allMembers.filter((member: Member) => member.role === MEMBER_ROLE.OWNER).length;
  const isInvitedMember = invitedMembers.some((invitedMember) => invitedMember.id === selectMemberId);

  const getOppositeMemberRole = (currentRole: string) => {
    if (currentRole === MEMBER_ROLE.ADMIN) {
      return MEMBER_ROLE.OWNER;
    }
    return MEMBER_ROLE.ADMIN;
  };

  return (
    <>
      {(loadingMembersData || loadingInvitedMembersData) && (
        <Modal isOpen onClose={() => {}}>
          <CircularProgress className="m-4" />
        </Modal>
      )}
      <Grid item container className="pt-3" id={scrollTopId}>
        <Grid item xs={7}>
          <PageTitle title={t("Store members")} className="pr-0" />
        </Grid>
        <Grid item xs={5} container justify="flex-end" alignItems="center" alignContent="center" className="px-3">
          <Button color="primary" className="mx-2" size="medium" onClick={openAddMemberModal}>
            <SvgIcon className="pt-1 pr-2 pb-2" component={IcAdd} fontSize="small" />
            {isDesktop ? t("Add new member") : t("Add")}
          </Button>
        </Grid>
      </Grid>
      <MemberWrapper>
        <Grid item xs={12} className="px-4 py-1">
          <Search
            defaultValue=""
            onChange={(searchInput: string) => setFilterUserName(searchInput)}
            className="mr-3"
            placeholder={t("searchPlaceholder.storeSettingsMembers")}
          />
        </Grid>

        {Boolean(userAdminsAndInvitedAdmins.length) && (
          <>
            <MemberTypeTitle>
              <Typography variant="title2" className="px-4 py-4">
                {t("Admin")}
              </Typography>
            </MemberTypeTitle>
            <div data-cy="adminMembers">
              <MemberItem
                members={userAdminsAndInvitedAdmins}
                isOwner={isOwner}
                generateOTP={get(generateKeyData, "generateOTP")}
                userId={userId}
                onClickMenuButton={handleClickMenuButton}
                onClickRemoveNotification={handleRemoveNotification}
                onClickGenerateOTP={handleGenerateOTP}
              />
            </div>
          </>
        )}

        {Boolean(userOwnersAndInvitedOwners.length) && (
          <>
            <MemberTypeTitle>
              <Typography variant="title2" className="px-4 py-4">
                {t("Owner")}
              </Typography>
            </MemberTypeTitle>
            <div data-cy="OwnerMembers">
              <MemberItem
                members={userOwnersAndInvitedOwners}
                isOwner={isOwner}
                generateOTP={get(generateKeyData, "generateOTP")}
                userId={userId}
                onClickMenuButton={handleClickMenuButton}
                onClickRemoveNotification={handleRemoveNotification}
                onClickGenerateOTP={handleGenerateOTP}
              />
            </div>
          </>
        )}
      </MemberWrapper>

      <MenuList
        isOwner={isOwner}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        selectMemberRole={selectMemberRole}
        onClickDelete={() => setIsOpenConfirmDeleteModal(true)}
        onClickSetMemberRole={() => handleEditMemberRole(selectMemberId, getOppositeMemberRole(selectMemberRole))}
        oppositeMemberRole={getOppositeMemberRole(selectMemberRole)}
        onCancelInvite={handleToggleInviteModal}
        isEnableCancelInvite={isInvitedMember}
        isEnableDelete={isInvitedMember ? false : totalOwner !== 1 || selectMemberRole !== MEMBER_ROLE.OWNER}
        isEnableSetRole={isInvitedMember ? false : totalOwner !== 1 || selectMemberRole !== MEMBER_ROLE.OWNER}
        onClose={handleCloseMenuList}
      />

      <AddMemberModal
        isOwner={isOwner}
        isOpen={isAddMemberModalOpen}
        onClose={onCloseAddMemberModal}
        closeModal={() => setIsAddMemberModalOpen(false)}
        refetchMembers={refetchMembers}
        members={allMembers}
      />

      {isOpenConfirmDeleteModal && (
        <ConfirmModal
          isOpen={isOpenConfirmDeleteModal}
          isLoading={false}
          title="Confirm Delete"
          description={t("Do you want to delete this member")}
          onClose={() => {
            setIsOpenConfirmDeleteModal(false);
          }}
          closeModal={() => {
            setIsOpenConfirmDeleteModal(false);
          }}
          handleConfirm={() => handleDeleteMember(selectMemberId)}
        />
      )}

      {isCancelInviteModalOpen && (
        <ConfirmModal
          isOpen={isCancelInviteModalOpen}
          isLoading={false}
          title="Confirm Delete"
          description={t("Do you want to delete this member")}
          onClose={handleCloseInviteModal}
          closeModal={handleCloseInviteModal}
          handleConfirm={() => handleCancelInvitation(selectMemberId, invitedMembers)}
        />
      )}

      {!isDesktop && isScroll && (
        <StickyButtonContainer className="px-2 py-3">
          <FloatingButton onClick={handleScrollToTop} data-cy="addButton">
            <SvgIcon
              className="mb-1 ml-3 mt-3"
              component={IcArrowToTop}
              fontSize="default"
              htmlColor={COLORS.DarkMed}
            />
          </FloatingButton>
          <ScrollToTop buttonColor="darkGray" />
        </StickyButtonContainer>
      )}
    </>
  );
};
