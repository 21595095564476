import styled from "styled-components";
import Button from "components/Button";
import Grid from "components/Grid";

export const CertificateImage = styled.img`
  border-radius: 8px;
  object-fit: contain;
  text-align: center;
  background-color: ${({ theme }) => theme.COLORS.PrimaryTransparent};
`;

export const InformationTypeButton = styled(Button)`
  font-weight: 500;
  font-size: 14px;
  font-family: "Graphik";
  font-style: normal;
  margin-right: 8px;
  padding: 4px 16px;
  color: ${({ theme }) => theme.COLORS.DarkGray};
  background-color: ${({ theme }) => theme.COLORS.White};
  border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  border-radius: 8px;

  &.selected {
    color: ${({ theme }) => theme.COLORS.Primary};
    background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
    border: 1px solid ${({ theme }) => theme.COLORS.Primary};
  }
`;

export const FieldWrapper = styled(Grid)`
  .MuiGrid-grid-xs-6 {
    &.first {
      padding-right: 8px;
    }

    &.second {
      padding-left: 8px;
    }
  }
`;
