import { TagType } from "types/Tag";
import { Platform } from "./Platform";

export type ChatListMessage = {
  [name: string]: string;
};

export type ConsoleDetailType = {
  __typename: string;
  email: string;
  firstname?: string;
  id: string;
  lastname?: string;
};

export type ChatLogPropsType = {
  customerImg?: string;
  messages: Message[];
};

export type LatestMessage = { createdAt: string; data: Array<MessageData> };

export interface Message {
  consoleDetail?: ConsoleDetailType;
  createdAt: string;
  data: MessageData[];
  id: string;
  isDeleted: boolean;
  messageUUID: string;
  slug: string;
  source: MessageSource;
  referenceId: string;
  customerId: string;
  customerPlatformUUID: string | null;
  messageType: MessageType;
  platform: Platform;
  sentiment: string;
}

export enum MessageSource {
  BOT = "BOT",
  CONSOLE = "CONSOLE",
  CUSTOMER = "CUSTOMER",
}

export enum MessageType {
  AUDIO = "AUDIO",
  CAROUSEL = "CAROUSEL",
  CART_SUMMARY = "CART_SUMMARY",
  CATEGORY_CAROUSEL = "CATEGORY_CAROUSEL",
  DELIVERY_SCHEDULE = "DELIVERY_SCHEDULE",
  FILE = "FILE",
  FLEX = "FLEX",
  IMAGE = "IMAGE",
  IMAGE_CAROUSEL = "IMAGE_CAROUSEL",
  LOCATION = "LOCATION",
  MESSAGE_BUILDER = "MESSAGE_BUILDER",
  MESSAGE_REPLY = "MESSAGE_REPLY",
  ORDER_CAROUSEL = "ORDER_CAROUSEL",
  PAYMENT_METHOD = "PAYMENT_METHOD",
  PRODUCT_CAROUSEL = "PRODUCT_CAROUSEL",
  QUICK_REPLIES = "QUICK_REPLIES",
  RECURRING_NOTIFICATION_OPT_IN = "RECURRING_NOTIFICATION_OPT_IN",
  SHIPPING_ADDRESS = "SHIPPING_ADDRESS",
  SHIPPING_METHOD = "SHIPPING_METHOD",
  SHIPPING_METHOD_TYPE = "SHIPPING_METHOD_TYPE",
  SPACING = "SPACING",
  STICKER = "STICKER",
  STORY_MENTION = "STORY_MENTION",
  STORY_REPLY = "STORY_REPLY",
  TEXT = "TEXT",
  VIDEO = "VIDEO",
}

export type LocationData = {
  readonly title: string;
  readonly address?: string;
  readonly latitude: number;
  readonly longitude: number;
};

export type MessageData = {
  type: string;
  // FIXME: please specific type for value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
};

export type LatestMessageType = { value: string; type: string };

export type MessageLog = {
  messages: Message[];
  hasNextPage: boolean;
};

export type MessageFetched = { messageLog: MessageLog };
export type AddedMessage = { data: { messageAdded: Message } };
export type MessageFetchedVariables = { projectId: string; customerId: string; offset: number };

export type TagOption = { label: string; value: string; type: TagType };

export type TotalUnseenChatMessagesChangedSubscriptionData = {
  subscriptionData: {
    data: {
      totalUnseenChatMessagesChanged: {
        totalUnseenChatMessages: number;
        totalUnseenAdminChatMessages: number;
        totalUnseenDisconnectedChatMessages: number;
      };
    };
  };
};

export type TotalUnseenChatMessagesData = {
  totalUnseenMessages: {
    totalUnseenAdminChatMessages: number;
    totalUnseenChatMessages: number;
    totalUnseenDisconnectedChatMessages: number;
  };
};
