import React, { FC, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { ValueType, OptionType } from "react-select";
import { useFormContext } from "react-hook-form";
import get from "lodash/get";

import Card from "components/Card";
import { CircleIcon } from "components/Icon";
import Select from "components/Select";
import Grid from "components/Grid";
import Typography from "components/Typography";
import TextField from "components/TextField";
import Switch from "components/Switch";
import ImageUpload from "components/ImageUpload";
import { NumberFormatCustom } from "components/NumberFormatCustom";

import { CONSOLE_GENERATED_FORM } from "constants/i18n";
import { COLORS } from "constants/Colors";
import { ImageUrl } from "types/Image";

import { convertProductTypeToText, convertPriceFormat } from "utils/common";
import {
  ProductSKUType,
  DigitalContentAvailability,
  ProductOutputType,
  AddProductSKUType,
  ProductType,
  ProductSKUInputDataType,
} from "types/Product";
import ExpiryDateInput from "./ExpiryDateInput";

type ProductSKUCardPropsType = {
  productSKU: ProductSKUType;
  onChangeDigitalContentAvailabilityType: (
    SKUId: string,
    digitalContentAvailabilityType: DigitalContentAvailability,
  ) => void;
  onChangeExpiryDate: (SKUId: string, expiryDate: string | number) => void;
  index: number;
  isCodeGenerateFromOther: boolean;
  isEditMode: boolean;
  isShadowSKU: boolean;
  nanoid: string;
  productSKUs: AddProductSKUType[];
  productType: ProductType;
  setProductSKUs: Function;
};

const ProductSKUCard: FC<ProductSKUCardPropsType> = ({
  index,
  isCodeGenerateFromOther,
  isEditMode,
  isShadowSKU,
  nanoid,
  onChangeDigitalContentAvailabilityType,
  onChangeExpiryDate,
  productSKU,
  productSKUs,
  setProductSKUs,
}) => {
  const { t } = useTranslation(CONSOLE_GENERATED_FORM);
  const { errors } = useFormContext();

  const DIGITAL_CONTENT_AVAILABILITY_TYPE_OPTIONS = [
    { label: t("noExpiry"), value: DigitalContentAvailability.NO_EXPIRY },
    { label: t("validityPeriod"), value: DigitalContentAvailability.PERIOD_AFTER_PURCHASED },
    { label: t("expiryDate"), value: DigitalContentAvailability.EXPIRY_DATE },
    // { label: t("date&time"), value: DigitalContentAvailability.DATE_RANGE, isDisabled: true }, // Not supported yet
  ];

  const availabilityType: DigitalContentAvailability = get(
    productSKU,
    "digitalContentOptions.settings.availability.type",
  );
  const defaultDigitalContentAvailabilityType =
    DIGITAL_CONTENT_AVAILABILITY_TYPE_OPTIONS.find((option) => option.value === availabilityType) ||
    DIGITAL_CONTENT_AVAILABILITY_TYPE_OPTIONS[0];
  let expiredAt: string = get(productSKU, "digitalContentOptions.settings.availability.settings.expiredAt");

  switch (availabilityType) {
    case DigitalContentAvailability.PERIOD_AFTER_PURCHASED:
      // settingsValue = { unit: "days", value: "30" };
      expiredAt = get(productSKU, "digitalContentOptions.settings.availability.settings.value");
      break;
    case DigitalContentAvailability.EXPIRY_DATE:
      // settingsValue = { expiredAt: "2021-06-30" };
      break;
    case DigitalContentAvailability.DATE_RANGE:
      // settingsValue = { availableAt: "2021-01-01", expiredAt: "2021-06-02" };
      break;
    case DigitalContentAvailability.NO_EXPIRY:
      break;
    default:
      break;
  }

  const statusColor = productSKU.selected ? COLORS.Success : COLORS.DarkMed;

  const handleChangeDigitalContentAvailabilityType = (option: ValueType<OptionType>) => {
    onChangeDigitalContentAvailabilityType(
      productSKU.nanoid || productSKU.id,
      (option as OptionType).value as DigitalContentAvailability,
    );
  };

  const handleDataChange = (dataType: ProductSKUInputDataType, value: number | boolean | string) => {
    const newProductSKUs = productSKUs.map((productSKU: AddProductSKUType) => {
      const returnProductSKUs = { ...productSKU };
      if (returnProductSKUs.nanoid === nanoid) {
        switch (dataType) {
          case ProductSKUInputDataType.PRICE:
            returnProductSKUs.price = Number(value);
            break;
          case ProductSKUInputDataType.SELECTED:
            returnProductSKUs.selected = Boolean(value);
            break;
          case ProductSKUInputDataType.IS_FREE:
            returnProductSKUs.isFree = Boolean(value);
            break;
          case ProductSKUInputDataType.PRODUCT_CODE:
            returnProductSKUs.productCode = value.toString();
            break;
          case ProductSKUInputDataType.PRODUCT_CF_CODE:
            returnProductSKUs.cfCode = value.toString() || null;
            break;
          case ProductSKUInputDataType.IMAGE:
            returnProductSKUs.image = value.toString();
            break;
          case ProductSKUInputDataType.WEIGHT:
            returnProductSKUs.weight = value ? Number(value) : 0;
            break;
          default:
            break;
        }
      }
      return returnProductSKUs;
    });

    setProductSKUs(newProductSKUs);
  };

  const handleChangeImage = (imageSources: ImageUrl[]) => {
    if (imageSources.length) {
      const [{ publicUrl }] = imageSources;
      handleDataChange(ProductSKUInputDataType.IMAGE, publicUrl);
    }
  };

  const errorProductCode = get(errors, `productSKUs[${index}].productCode.message`);
  const errorProductCfCode = get(errors, `productSKUs[${index}].cfCode.message`);

  const isNotAllowToEdit = productSKU.id ? isEditMode : false;

  return (
    <Grid container key={productSKU.nanoid || productSKU.id} className="sku-card">
      {!isShadowSKU && (
        <Grid container item className="mx-3">
          <Grid item xs={3} className="pt-3">
            <CircleIcon color={statusColor} />
            <Typography className="d-inline" variant="body3">
              {productSKU.productCode}
            </Typography>
          </Grid>
          <Grid item xs={3} className="pt-3">
            <Typography variant="body3">{convertProductTypeToText(productSKU.productType)}</Typography>
          </Grid>
          <Grid item xs={3} className="pt-3">
            <Typography variant="body3">฿{convertPriceFormat(productSKU.price, 0)}</Typography>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        <Card className="m-3" padding="0px 16px">
          {!isShadowSKU && (
            <>
              <Grid container justify="flex-start" alignItems="center" className="mt-4">
                <Switch
                  data-cy="toggleProductSKU"
                  checked={productSKU.selected}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleDataChange(ProductSKUInputDataType.SELECTED, event.target.checked);
                  }}
                />
                <Typography variant="body4" className="ml-2" color={COLORS.DarkMed}>
                  {t(productSKU.selected ? "Active" : "Disabled")}
                </Typography>
              </Grid>

              <Grid container item xs={12} alignContent="center" alignItems="center">
                <Grid container item className="pr-3 flex-0" justify="flex-start">
                  <Grid item xs={12}>
                    <ImageUpload
                      onChange={handleChangeImage}
                      onRemove={() => handleDataChange(ProductSKUInputDataType.IMAGE, "")}
                      image={productSKU.image}
                      width={120}
                      height={150}
                    />
                  </Grid>
                </Grid>

                <Grid container item className="flex-1">
                  <Grid item xs={12}>
                    <Typography className="d-flex" variant="body4">
                      {t("productSKUCode")}
                    </Typography>
                    <TextField
                      data-cy="productSKUCode"
                      className="my-2"
                      variant="outlined"
                      fullWidth
                      placeholder={t("productSKUCode")}
                      value={productSKU.productCode}
                      error={Boolean(errorProductCode)}
                      helperText={t(errorProductCode)}
                      onChange={(event) => {
                        handleDataChange(ProductSKUInputDataType.PRODUCT_CODE, event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="d-flex" variant="body4">
                      {t("Product SKU CF code")} - {t("product.cfCode.optional")}
                    </Typography>
                    <TextField
                      data-cy="productSKUCFCode"
                      className="my-2"
                      variant="outlined"
                      fullWidth
                      placeholder={t("Please put CF Code here")}
                      value={productSKU.cfCode}
                      error={Boolean(errorProductCfCode)}
                      helperText={t(errorProductCfCode)}
                      onChange={(event) => {
                        handleDataChange(ProductSKUInputDataType.PRODUCT_CF_CODE, event.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body4" color={COLORS.DarkMed}>
                      {t("price")}
                    </Typography>
                    <TextField
                      className="my-2"
                      data-cy="ProductSKUPrice"
                      fullWidth
                      placeholder={t("price")}
                      value={productSKU.price}
                      onChange={(event) => {
                        handleDataChange(
                          ProductSKUInputDataType.PRICE,
                          +parseFloat(event.target.value).toFixed(2) || 0.0,
                        );
                      }}
                      variant="outlined"
                      InputProps={{
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        inputComponent: NumberFormatCustom as any,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {!isCodeGenerateFromOther && (
            <Grid item xs={12} className="">
              <Card noShadow noBorder bgColor={COLORS.LightWhite} className="p-3">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Select
                      fullWidth
                      options={DIGITAL_CONTENT_AVAILABILITY_TYPE_OPTIONS}
                      onChange={handleChangeDigitalContentAvailabilityType}
                      defaultValue={defaultDigitalContentAvailabilityType}
                      isDisabled={isNotAllowToEdit}
                    />
                  </Grid>

                  {get(productSKU, "digitalContentOptions.outputType") !== ProductOutputType.CODE_IMPORTED && (
                    <ExpiryDateInput
                      key={productSKU.nanoid || productSKU.id}
                      SKUId={productSKU.nanoid || productSKU.id}
                      availabilityType={availabilityType}
                      defaultValue={expiredAt}
                      onChangeExpiryDate={onChangeExpiryDate}
                    />
                  )}
                </Grid>
              </Card>
            </Grid>
          )}

          {!isShadowSKU && (
            <Grid container justify="space-between" className="my-4">
              <Typography variant="body4" className="mt-2" color={COLORS.DarkMed}>
                {t("This product SKU is free")}
              </Typography>
              <Switch
                data-cy="toggleFreeProduct"
                checked={productSKU.isFree}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleDataChange(ProductSKUInputDataType.IS_FREE, event.target.checked);
                }}
              />
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProductSKUCard;
