import { useState } from "react";

import { OrderByType } from "types/SalesReport";
import { ROWS_PER_PAGE, DEFAULT_PAGE_START } from "../config";

export type SortByType = { sortBy?: string; orderBy?: OrderByType };

const usePaginationState = () => {
  const [sortBy, setSortBy] = useState<SortByType>({});
  const [page, setPage] = useState(DEFAULT_PAGE_START);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  return {
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    setSortBy,
    sortBy,
  };
};

export default usePaginationState;
