import React, { FC } from "react";
import styled from "styled-components";
import Grid from "components/Grid";

type MessagesManagerLabelPropsType = {
  limitNo: number;
  currentNo: number;
};

const Wrapper = styled.div`
  font-weight: 600;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  background-color: ${({ theme }) => theme.COLORS.White};
  padding: 24px 16px 16px 16px;
  color: ${({ theme }) => theme.COLORS.DarkGray};
`;

const MessagesManagerLabel: FC<MessagesManagerLabelPropsType> = ({ children, currentNo, limitNo }) => (
  <Wrapper>
    <Grid container justify="space-between">
      <Grid item>{children}</Grid>
      <Grid item>
        {currentNo}/{limitNo}
      </Grid>
    </Grid>
  </Wrapper>
);

export default MessagesManagerLabel;
