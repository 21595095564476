import React, { FC, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";
import get from "lodash/get";
import moment from "moment";

import ErrorText from "components/ErrorText";
import Grid from "components/Grid";
import TextField from "components/TextField";
import Typography from "components/Typography";
import { ORDER_PREFIX } from "constants/Setting";
import ControllerSwitch from "components/Switch/ControllerSwitch";
import {
  OrderSettingType,
  OrderSettingFormType,
  ProjectConfigurationOrderTimerType,
  PrefixOrderConfigType,
} from "types/Setting";
import { convertTimesToHourMinuteFormat } from "utils/time";

import { ContentWrapper } from "../styled";

type OrderSettingFormPropsType = {
  timerOrderConfig: ProjectConfigurationOrderTimerType[];
  prefixOrderConfig?: PrefixOrderConfigType;
  isMultiCODOrderWithOutDepositEnabled: boolean;
  isEmailRequired: boolean;
  handlePrefixChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: keyof OrderSettingFormType,
  ) => void;
};

const OrderSettingForm: FC<OrderSettingFormPropsType> = (props) => {
  const { t } = useTranslation();
  const { register, errors } = useFormContext();
  const {
    timerOrderConfig,
    prefixOrderConfig,
    isEmailRequired,
    isMultiCODOrderWithOutDepositEnabled,
    handlePrefixChange,
  } = props;

  const isPaymentNotificationActive = useWatch({ name: "isPaymentNotificationActive", defaultValue: false });

  const remindTimerOrderConfig =
    timerOrderConfig &&
    timerOrderConfig.find(({ type }: ProjectConfigurationOrderTimerType) => type === OrderSettingType.REMIND);
  const remindTimerOrderConfigValue = get(remindTimerOrderConfig, "value", 0);
  const remindTimerOrderConfigTimes = convertTimesToHourMinuteFormat(remindTimerOrderConfigValue);

  const abandonTimerOrderConfig =
    timerOrderConfig &&
    timerOrderConfig.find(({ type }: ProjectConfigurationOrderTimerType) => type === OrderSettingType.ABANDON);
  const abandonTimerOrderConfigValue = get(abandonTimerOrderConfig, "value", 0);
  const abandonTimerOrderConfigTimes = convertTimesToHourMinuteFormat(abandonTimerOrderConfigValue);

  const defaultOrderNumberPrefix = get(prefixOrderConfig, "orderNumber", ORDER_PREFIX.ORDER_NUMBER);
  const defaultReceiptNumberPrefix = get(prefixOrderConfig, "receiptNumber", ORDER_PREFIX.RECEIPT_NUMBER);
  const customPrefixLatestUpdated = get(prefixOrderConfig, "updatedAt");
  const isSameDate = !!(customPrefixLatestUpdated && moment(customPrefixLatestUpdated).isSame(moment(), "day"));

  return (
    <ContentWrapper item container xs={12} className="mb-5">
      <Grid item container>
        <Grid item xs={12}>
          <Typography variant="body3">{t("IS_MULTI_COD_ORDER_ENABLED")}</Typography>
        </Grid>
        <Grid item xs={12} className="py-3 px-1">
          <ControllerSwitch
            name="isMultiCODOrderWithOutDepositEnabled"
            defaultValue={isMultiCODOrderWithOutDepositEnabled}
          />
        </Grid>
      </Grid>

      <Grid item container>
        <Grid item xs={12}>
          <Typography variant="body3">{t("IS_EMAIL_REQUIRED")}</Typography>
        </Grid>
        <Grid item xs={12} className="py-3 px-1">
          <ControllerSwitch name="isEmailRequired" defaultValue={isEmailRequired} />
        </Grid>
      </Grid>

      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Typography variant="body3">{t("Payment notification")}</Typography>
        </Grid>
        <Grid item container xs={12} alignItems="center" className="pt-3 px-1">
          <Grid item xs={2}>
            <ControllerSwitch name="isPaymentNotificationActive" defaultValue={false} />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              defaultValue={remindTimerOrderConfigTimes.hours}
              type="number"
              validate={register}
              name="paymentNotiHr"
              disabled={!isPaymentNotificationActive}
              inputProps={{ readOnly: !isPaymentNotificationActive }}
            />
          </Grid>
          <Grid item xs={2} className="pl-3">
            <Typography variant="body3">{t("hours")}</Typography>
          </Grid>
          <Grid item xs={3} className="pl-3">
            <TextField
              fullWidth
              defaultValue={remindTimerOrderConfigTimes.mins}
              type="number"
              validate={register}
              name="paymentNotiMin"
              disabled={!isPaymentNotificationActive}
            />
          </Grid>
          <Grid item xs={2} className="pl-3">
            <Typography variant="body3">{t("mins")}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {errors.paymentNotiHr && (
        <Grid item xs={12} className="m-1">
          <ErrorText>{t(errors.paymentNotiHr.message as string)}</ErrorText>
        </Grid>
      )}
      {errors.paymentNotiMin && (
        <Grid item xs={12} className="m-1">
          <ErrorText>{t(errors.paymentNotiMin.message as string)}</ErrorText>
        </Grid>
      )}
      <Grid item container xs={12} className="pt-4 pb-2">
        <Grid item xs={12}>
          <Typography variant="body3">{t("Reserved product expiration time")}</Typography>
        </Grid>
        <Grid item container xs={12} alignItems="center" className="pt-3 px-1">
          <Grid item xs={4}>
            <TextField
              fullWidth
              defaultValue={abandonTimerOrderConfigTimes.hours}
              type="number"
              validate={register}
              name="reserveExpHr"
            />
          </Grid>
          <Grid item xs={2} className="pl-3">
            <Typography variant="body3">{t("hours")}</Typography>
          </Grid>
          <Grid item xs={4} className="pl-3">
            <TextField
              fullWidth
              defaultValue={abandonTimerOrderConfigTimes.mins}
              type="number"
              validate={register}
              name="reserveExpMin"
            />
          </Grid>
          <Grid item xs={2} className="pl-3">
            <Typography variant="body3">{t("mins")}</Typography>
          </Grid>
        </Grid>
        {errors.reserveExpHr && (
          <Grid item xs={12} className="m-1">
            <ErrorText>{t(errors.reserveExpHr.message as string)}</ErrorText>
          </Grid>
        )}
        {errors.reserveExpMin && (
          <Grid item xs={12} className="m-1">
            <ErrorText>{t(errors.reserveExpMin.message as string)}</ErrorText>
          </Grid>
        )}
      </Grid>
      <Grid item container className="pt-3 pb-2">
        <Grid item container alignItems="flex-end" xs={12}>
          <Typography variant="body3" className="mr-2">
            {t("orderSetting.label.orderNumberPrefix")}
          </Typography>
          <Typography variant="body4" color="gray">
            {t(`orderSetting.label.${isSameDate ? "prefixUpdateNextDay" : "prefixUpdateCondition"}`)}
          </Typography>
        </Grid>
        <Grid item container xs={12} className="pt-2">
          <TextField
            variant="outlined"
            placeholder={t("orderSetting.label.orderNumberPrefix")}
            required
            fullWidth
            name="orderNumberPrefix"
            validate={register}
            defaultValue={defaultOrderNumberPrefix}
            onChange={(event) => handlePrefixChange(event, "orderNumberPrefix")}
            InputProps={isSameDate ? { className: "Mui-disabled" } : {}}
            inputProps={{ readOnly: isSameDate }}
          />
        </Grid>
        {errors.orderNumberPrefix && (
          <Grid item xs={12} className="mx-1 mt-1">
            <ErrorText>{t(errors.orderNumberPrefix.message as string)}</ErrorText>
          </Grid>
        )}
      </Grid>
      <Grid item container className="pt-2 pb-2">
        <Grid item container alignItems="flex-end" xs={12}>
          <Typography variant="body3" className="mr-2">
            {t("orderSetting.label.receiptNumberPrefix")}
          </Typography>
          <Typography variant="body4" color="gray">
            {t(`orderSetting.label.${isSameDate ? "prefixUpdateNextDay" : "prefixUpdateCondition"}`)}
          </Typography>
        </Grid>
        <Grid item container xs={12} className="pt-2">
          <TextField
            variant="outlined"
            placeholder={t("orderSetting.label.receiptNumberPrefix")}
            required
            fullWidth
            name="receiptNumberPrefix"
            validate={register}
            defaultValue={defaultReceiptNumberPrefix}
            onChange={(event) => handlePrefixChange(event, "receiptNumberPrefix")}
            InputProps={isSameDate ? { className: "Mui-disabled" } : {}}
            inputProps={{ readOnly: isSameDate }}
          />
        </Grid>
        {errors.receiptNumberPrefix && (
          <Grid item xs={12} className="mx-1 mt-1">
            <ErrorText>{t(errors.receiptNumberPrefix.message as string)}</ErrorText>
          </Grid>
        )}
      </Grid>
    </ContentWrapper>
  );
};

export default OrderSettingForm;
