import { gql } from "@apollo/client";

export const DIGITAL_CONTENT_BY_CODE = gql`
  query soldDigitalContent($projectId: ID!, $code: String!) {
    digitalContentByCode: soldDigitalContent(projectId: $projectId, code: $code) {
      digitalContent {
        id
        content
        refId
        soldAt
        expiredAt
      }
      product {
        name
        images {
          src
        }
        defaultPrice
        productSKUs {
          id
          productCode
          price
          productType {
            value
            key {
              key
            }
          }
        }
      }
      order {
        id
        orderNumber
        state
        customerId
        customer {
          id
          name
          pictureUrl
          platform
          tags {
            results {
              id
              projectId
              customerId
              tag
            }
            total
          }
          systemTags {
            tag
            type
          }
        }
        createdAt
        updatedAt
        address
        firstName
        lastName
        phoneNumber
        email
      }
    }
  }
`;

export const DIGITAL_PRODUCTS = gql`
  query products($projectId: ID!, $limit: Int, $offset: Int, $filter: ProductFilterInput) {
    digitalProducts: products(projectId: $projectId, limit: $limit, offset: $offset, filter: $filter) {
      results {
        id
        projectId
        name
        type
        defaultPrice
        unit
        images {
          src
        }
        outputType
        productSKUs {
          id
          price
          productId
          productCode
          inventory
          reserved
          selected
          deleted
          isFree
          image {
            src
          }
          productType {
            value
            key {
              key
            }
          }
          digitalProductInventories {
            status
            inventory
          }
        }
      }
      total
    }
  }
`;

export const DIGITAL_PRODUCT_CODES = gql`
  query digitalProductCodes($projectId: ID!, $productSKUId: ID!, $status: String, $limit: Int, $offset: Int) {
    digitalProductCodes(
      projectId: $projectId
      productSKUId: $productSKUId
      status: $status
      limit: $limit
      offset: $offset
    ) {
      results {
        id
        outputType
        content
        refId
        isSold
        isUsed
        soldAt
        usedAt
        expiredAt
        createdAt
        note
        availability {
          type
          settings {
            unit
            value
            availableAt
            expiredAt
          }
        }
      }
      total
    }
  }
`;
