const vat = {
  en: {
    VAT: "VAT",
    None: "None",
    Included: "Included",
    Excluded: "Excluded",
    "VAT Settings": "VAT Settings",
    "Do not charge VAT": "Do not charge VAT",
    "Including VAT": "Including VAT",
    "Excluding VAT": "Excluding VAT",
    "VAT included in product price": "VAT included in product price",
    "VAT included in total price": "VAT included in total price",
    "VAT rate (%VAT)": "VAT rate (%VAT)",
    "Show Abbreviated tax invoice": "Show Abbreviated tax invoice",
    "Tax ID": "Taxpayer Identification No.",
    "Company name": "Company name",
    "Please enter taxpayer identification no.": "Please enter taxpayer identification no.",
    "Tax ID must be 13 digit characters": "Tax ID must be 13 digit characters",
    "Please enter company name": "Please enter company name",
    "vat.label.isShippingIncluded": "Charge excluded vat to shipping price",
    "Tax invoice language": "Tax invoice language",
    TaxInThai: "Tax invoices are sent to customers in Thai",
    TaxInEnglish: "Tax invoices are sent to customers in English",
  },
  th: {
    VAT: "ภาษีมูลค่าเพิ่ม",
    None: "ไม่มี",
    Included: "Included",
    Excluded: "Excluded",
    "VAT Settings": "ตั้งค่าการคำนวณภาษีมูลค่าเพิ่ม (VAT)",
    "Do not charge VAT": "ไม่คิดค่า VAT กับลูกค้า",
    "Including VAT": "คิดค่า VAT กับลูกค้า",
    "Excluding VAT": "คิดค่า VAT กับลูกค้า",
    "VAT included in product price": "ราคาสินค้าที่กำหนด ได้รวมกับภาษีมูลค่าเพิ่มแล้ว",
    "VAT included in total price": "ราคาสินค้าที่กำหนด ยังไม่รวมกับภาษีมูลค่าเพิ่ม",
    "VAT rate (%VAT)": "ภาษีมูลค่าเพิ่ม (%VAT)",
    "Show Abbreviated tax invoice": "แสดงใบกำกับภาษีอย่างย่อ",
    "Tax ID": "หมายเลขประจำตัวผู้เสียภาษี 13 หลัก",
    "Company name": "ชื่อบริษัท",
    "Please enter taxpayer identification no.": "กรุณากรอกหมายเลขประจำตัวผู้เสียภาษี",
    "Tax ID must be 13 digit characters": "หมายเลขประจำตัวผู้เสียภาษีต้องเป็นตัวเลข 13 หลัก",
    "Please enter company name": "กรุณากรอกชื่อริษัท",
    "vat.label.isShippingIncluded": "คิดภาษีมูลค่าเพิ่มกับค่าขนส่ง",
    "Tax invoice language": "ภาษาที่ใช้ในใบกำกับภาษีมูลค่าเพิ่ม",
    TaxInThai: "ส่งใบกำกับภาษีมูลค่าเพิ่มเป็นภาษาไทยให้ลูกค้า",
    TaxInEnglish: "ส่งใบกำกับภาษีมูลค่าเพิ่มเป็นภาษาอังกฤษให้ลูกค้า",
  },
};

export default vat;
