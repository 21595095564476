import styled from "styled-components";
import COLORS from "constants/Colors";

export const ModalHeader = styled.div`
  height: 57px;
  min-height: 57px;
  background: ${({ theme }) => `${theme.COLORS.Primary}`};
  color: ${({ theme }) => `${theme.COLORS.LightWhite}`};
  text-transform: uppercase;
  display: flex;
  align-content: center;
  & > div {
    align-self: center;
  }
`;

export const TitleWrapper = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
`;

export const ModalWrapper = styled.div<{ isFullScreen: boolean }>`
  background: ${COLORS.Light};
  position: relative;
  min-height: 100%;
  min-width: ${({ isFullScreen }) => (isFullScreen ? "0px" : "950px")};
  max-width: ${({ isFullScreen }) => (isFullScreen ? "100%" : "950px")};
  overflow-y: auto;
`;

export const ModalBodyWrapper = styled.div`
  background: ${COLORS.White};
  border: solid 0.5px ${COLORS.LightBlue};
  border-radius: 8px;
  min-height: 630px;
`;

export const ButtonWrapper = styled.div`
  bottom: 24px;
  right: 24px;
  display: flex;
  justify-content: flex-end;
  background: transparent;
`;
