import * as React from "react";

const IcNoData = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" fill="none" viewBox="0 0 33 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.819 20.495c.29-.32.29-.809 0-1.129L13.052 16.6l2.767-2.767c.329-.328.329-.86 0-1.19-.328-.327-.86-.327-1.188 0l-2.766 2.767-2.767-2.767c-.344-.31-.875-.285-1.187.06-.29.32-.29.808 0 1.129l2.766 2.766-2.766 2.767c-.345.312-.372.844-.06 1.188.312.345.844.372 1.19.06.02-.02.04-.038.057-.06l2.767-2.765 2.766 2.766c.344.312.876.286 1.188-.059zm11.933 5.017l.01.012.006.006c-.03.032-.066.06-.1.087l-.062.05-.035.032c-.036.032-.071.063-.109.09-.067.048-.136.09-.21.131-.034.019-.069.035-.104.05-.402.197-.863.304-1.269.309-.336.003-22.692 0-22.692 0-.638 0-1.18-.541-1.264-1.268L.011 8.602C-.09 7.73.5 6.882 1.267 6.878h12.56l2.145-2.44c.237-.269.557-.421.891-.423L21.38 4c.64-.003 1.182.54 1.266 1.267l.203 1.743h.004l1.858 16.008c0 .002.248 3.156 3.042 2.494zm-3.53-18.505L31.032 7c.662-.001 1.13.972.918 1.907l-3.45 15.13c-.019.08-.042.156-.066.232l-.016.055c-.618.256-1.358.183-1.76-.22-.481-.482-.6-1.39-.684-2.039l-.021-.166c-.13-.959-1.184-10.12-1.73-14.892z"
      clipRule="evenodd"
    />
  </svg>
);

export default IcNoData;
