import styled from "styled-components";
import Typography from "components/Typography";
import Grid from "components/Grid";

export const TextButton = styled(Typography)`
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.COLORS.Primary};
  }
`;

export const TypoBreakWord = styled(Typography)`
  word-break: break-word;
`;

export const AdminNameWrapper = styled(Grid)`
  background-color: ${({ theme }) => theme.COLORS.Light};
  width: max-content;
  padding: 4px;
  padding-right: 12px;
  border-radius: 100px;
  flex-wrap: nowrap;
`;

export const RightSideTextWrapper = styled(Grid)`
  width: fit-content;
  flex-wrap: nowrap;
`;
