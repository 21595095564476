import * as React from "react";

const SvgIcFacebookComment = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <defs>
      <path d="M0.029 0.043L23.484 0.043 23.484 17.909 0.029 17.909z" />
    </defs>
    <g fill="currentColor" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g transform="translate(-572 -375) translate(570 373) translate(2 2) translate(4.48)">
              <path
                fill="currentColors"
                d="M21.768.043H1.745C.797.043.029.82.029 1.782v.588h19.446c.947 0 1.715.778 1.715 1.739v13.8h.578c.948 0 1.716-.78 1.716-1.74V1.782c0-.96-.768-1.74-1.716-1.74"
                mask="url(#vxsha76x6b)"
              />
            </g>
            <path
              fill="currentColors"
              d="M16.559 14.984c-.92 0-1.665-.753-1.665-1.68 0-.929.746-1.68 1.665-1.68.92 0 1.665.751 1.665 1.68 0 .927-.746 1.68-1.665 1.68m-4.704 0c-.92 0-1.665-.753-1.665-1.68 0-.929.746-1.68 1.665-1.68.92 0 1.665.751 1.665 1.68 0 .927-.746 1.68-1.665 1.68m-4.704 0c-.92 0-1.665-.753-1.665-1.68 0-.929.746-1.68 1.665-1.68.92 0 1.665.751 1.665 1.68 0 .927-.745 1.68-1.665 1.68M21.8 4.48H1.72C.77 4.48 0 5.258 0 6.217V20.4c0 .959.77 1.736 1.72 1.736h7.645c.327 0 .645.11.904.31l7.098 5.51c.13.103.32.009.32-.157v-5.263c0-.22.177-.4.396-.4H21.8c.95 0 1.72-.777 1.72-1.736V6.217c0-.96-.77-1.737-1.72-1.737"
              transform="translate(-572 -375) translate(570 373) translate(2 2)"
            />
            <path
              stroke="currentColors"
              d="M16.559 14.984c-.92 0-1.665-.753-1.665-1.68 0-.929.746-1.68 1.665-1.68.92 0 1.665.751 1.665 1.68 0 .927-.746 1.68-1.665 1.68zm-4.704 0c-.92 0-1.665-.753-1.665-1.68 0-.929.746-1.68 1.665-1.68.92 0 1.665.751 1.665 1.68 0 .927-.746 1.68-1.665 1.68zm-4.704 0c-.92 0-1.665-.753-1.665-1.68 0-.929.746-1.68 1.665-1.68.92 0 1.665.751 1.665 1.68 0 .927-.745 1.68-1.665 1.68zM21.8 4.48H1.72C.77 4.48 0 5.258 0 6.217V20.4c0 .959.77 1.736 1.72 1.736h7.645c.327 0 .645.11.904.31l7.098 5.51c.13.103.32.009.32-.157v-5.263c0-.22.177-.4.396-.4H21.8c.95 0 1.72-.777 1.72-1.736V6.217c0-.96-.77-1.737-1.72-1.737z"
              transform="translate(-572 -375) translate(570 373) translate(2 2)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgIcFacebookComment;
