import address from "domain/Shipping/PostalCodeBasedShipping/provinces.json";
import { Province } from "types/Address";
import { DeliveryAddressType } from "types/Order";

export const validateThPostCode = (postcode: string | number) => {
  const thPostcodes = address.provinces.flatMap((province: Province) =>
    province.districts.map((district) => district.pC),
  );

  return thPostcodes.some((thPostcode) => thPostcode === postcode.toString());
};

export const getCustomerAddressText = (address: Partial<DeliveryAddressType>, exceptAddressKey: string[]) => {
  return Object.keys(address)
    .map((key) => !exceptAddressKey.includes(key) && address[key as keyof DeliveryAddressType])
    .filter(Boolean)
    .join(" ");
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAddress = (address: any) => {
  if (!address) {
    return "";
  }

  let streetAddress = address.address ? `${address.address}, ` : "";
  const street1 = address.streetAddress ? `${address.streetAddress}, ` : "";
  const street2 = address.streetAddress2 ? `${address.streetAddress2}, ` : "";

  if (!streetAddress) {
    streetAddress = `${street1}${street2}`;
  }

  const subDistrict = address.subDistrict || "";
  const district = address.district || "";
  const province = address.province || "";
  const postalCode = address.postalCode || "";

  return postalCode ? `${streetAddress}${subDistrict}, ${district}, ${province}, ${postalCode}` : "";
};
