import React, { FC, useState, memo } from "react";

import { ButtonWithIcon } from "components/Button";
import CollapseButton from "components/CollapseButton";
import Card from "components/Card";
import Grid from "components/Grid";
import Typography from "components/Typography";
import { SvgIcon } from "components/Icon";
import IcDelete from "components/SvgIcons/IcDelete";
import { NO_IMG_AVAILABLE } from "constants/Image";
import { NewProductItemProps } from "types/Product";
import { GridProductImage, GridProductName, ProductImage } from "./styled";
import ProductCard from "./productCard";

type ProductCategoryCardPropsType = {
  id: string;
  isDisabled?: boolean;
  products: NewProductItemProps[];
  categoryImage: string;
  categoryName: string;
  onDelete: (categoryId?: string, productId?: string, productSKUId?: string) => void;
};

const ProductCategoryCard: FC<ProductCategoryCardPropsType> = (props) => {
  const { id, products, categoryImage, categoryName, onDelete, isDisabled = false } = props;

  const [isShowProductList, setIsShowProductList] = useState(false);

  return (
    <Card fullWidth className="mt-3" padding="24px">
      <Grid container className="flex-nowrap" alignItems="center">
        <Grid item className="d-flex align-items-center ellipsis">
          <GridProductImage item>
            <ProductImage alt={categoryImage ? categoryName : "no img"} src={categoryImage} />
          </GridProductImage>
          <GridProductName item>
            <Typography variant="title7" color="dark" className="ellipsis">
              {categoryName}
            </Typography>
          </GridProductName>
        </Grid>
        <Grid item className="d-flex">
          {!isDisabled && (
            <ButtonWithIcon
              type="button"
              onClick={() => {
                onDelete(id);
              }}
            >
              <SvgIcon className="ml-1 mt-1" component={IcDelete} fontSize="small" />
            </ButtonWithIcon>
          )}
          <CollapseButton
            className="ml-3"
            isShow={isShowProductList}
            degree={0}
            onClick={() => setIsShowProductList(!isShowProductList)}
          />
        </Grid>
      </Grid>

      {isShowProductList &&
        Boolean(products.length) &&
        products.map((product) => {
          const { id, images, productSKUs, name = "", cfCode } = product;
          const productImage = images?.[0]?.src || NO_IMG_AVAILABLE;

          return (
            <ProductCard
              key={id}
              id={id}
              isDisabled={isDisabled}
              productSKUs={productSKUs}
              productImage={productImage}
              productName={name}
              onDelete={onDelete}
              cfCode={cfCode}
            />
          );
        })}
    </Card>
  );
};

export default memo(ProductCategoryCard);
