import { useQuery } from "@apollo/client";

import { CHAT_MENUS } from "graphql/richMenu/query";
import { PLATFORM, ChatMenu } from "types/RichMenu";

type ChatMenuQueryVariables = {
  projectId: string;
  platform: PLATFORM;
};

type ChatMenuQuery = {
  chatMenus: ChatMenu[];
};

const useGetMenuList = (projectId: string, platform: PLATFORM) => {
  const { data } = useQuery<ChatMenuQuery, ChatMenuQueryVariables>(CHAT_MENUS, {
    variables: {
      projectId,
      platform,
    },
    fetchPolicy: "cache-and-network",
  });

  return {
    menuList: data?.chatMenus || [],
  };
};

export default useGetMenuList;
