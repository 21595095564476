import styled from "styled-components";

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    display: initial;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(83, 83, 83, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  ::-webkit-scrollbar-corner {
    background-color: ${({ theme }) => theme.COLORS.Light};
  }
`;
