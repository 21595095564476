import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Card from "components/Card";
import Grid from "components/Grid";
import Typography from "components/Typography";
import DisplayNameWithAvatar from "components/DisplayNameWithAvatar";
import { CustomerType } from "types/Customer";
import displayDateTime from "utils/common/displayDateTime";
import useUpdateDisplayName from "./hooks/useUpdateDisplayName";

type CustomerInfoPropsType = {
  customerId: string;
  projectId: string;
  isDesktop: boolean;
  customer: CustomerType;
};

const CustomerInfo: FC<CustomerInfoPropsType> = ({ customerId, projectId, customer, isDesktop }) => {
  const { t } = useTranslation();
  const { firstSeen, lastSeen, displayName, name, pictureUrl, platform } = customer;

  const { onUpdateDisplayName } = useUpdateDisplayName({ projectId, customerId });

  return (
    <Card noShadow fullWidth padding="24px">
      <Grid container>
        <Grid item container alignItems="center">
          <Grid item className="pr-3">
            <DisplayNameWithAvatar
              displayName={displayName || name || ""}
              description={displayName ? name : ""}
              avatarImage={pictureUrl || ""}
              platform={platform || ""}
              isBig={isDesktop}
              isEditDisplayName
              onChangeDisplayName={onUpdateDisplayName}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className="pt-4">
          <Typography variant="title8" color="darkLight">
            {t("customerReportDetail.firstTimeChat.title")}
          </Typography>
          <Typography variant="body3">{displayDateTime(firstSeen)}</Typography>
        </Grid>
        <Grid item xs={12} className="pt-4">
          <Typography variant="title8" color="darkLight">
            {t("customerReportDetail.lastMessagedAt.title")}
          </Typography>
          <Typography variant="body3">{displayDateTime(lastSeen)}</Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CustomerInfo;
