import { ADD_CUSTOMER_TAG } from "graphql/customer/mutation";
import { ApolloError, useMutation } from "@apollo/client";
import { CUSTOMER_TAG } from "graphql/customer/query";
import { CustomerQueryType, CustomerTagVariablesType } from "types/Customer";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";

type UseAddCustomerTagPropsType = {
  variables: CustomerTagVariablesType;
  onComplete: () => void;
};

const useAddCustomerTag = (props: UseAddCustomerTagPropsType) => {
  const { variables } = props;
  const { t } = useTranslation();

  const [addTag] = useMutation(ADD_CUSTOMER_TAG, {
    update(cache, { data: customerTagData }) {
      const cachedCustomer = cache.readQuery<CustomerQueryType>({
        query: CUSTOMER_TAG,
        variables,
      });
      if (cachedCustomer && customerTagData) {
        const { addCustomerTag } = customerTagData;
        const cacheCustomerTags = cachedCustomer.customer.tags;
        const updatedCacheCustomerTagsResults = [...cacheCustomerTags.results, addCustomerTag];

        cache.writeQuery({
          query: CUSTOMER_TAG,
          variables,
          data: {
            customer: {
              ...cachedCustomer.customer,
              tags: {
                ...cacheCustomerTags,
                results: updatedCacheCustomerTagsResults,
                total: cacheCustomerTags.total + 1,
              },
            },
          },
        });
      }
    },
    onError: (error: ApolloError) => {
      toast.error(`${t("Update failed!")}, ${error}`, {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 3000,
      });
    },
    onCompleted: () => {},
  });

  return {
    addTag,
  };
};

export default useAddCustomerTag;
