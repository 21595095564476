/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback } from "react";
import produce from "immer";
import { OptionType } from "domain/Product/ProductListWithSearch/ProductSelector/Option/type";

const useManageSelectedOptions = () => {
  const [selectedOptions, setSelectedOptions] = useState<OptionType<any>[]>([]);

  const onClearAllTag = useCallback(() => {
    const clearSelectedOptions = produce(selectedOptions, (draft) => {
      draft.forEach((selectedOption) => {
        // eslint-disable-next-line no-param-reassign
        selectedOption.isChecked = false;
        selectedOption.options?.forEach((option) => {
          // eslint-disable-next-line no-param-reassign
          option.isChecked = false;
        });
      });
    });

    setSelectedOptions(clearSelectedOptions);
  }, [selectedOptions]);

  const onDeleteTag = useCallback(
    (option: OptionType<any>) => {
      const deletedSelectedOptions = produce(selectedOptions, (draft) => {
        const index = draft.findIndex((selectedOption) => selectedOption.value.id === option.value.id);
        if (index !== -1) {
          (draft[index] as OptionType<any>).isChecked = false;
          (draft[index] as OptionType<any>).options?.forEach((option) => {
            // eslint-disable-next-line no-param-reassign
            option.isChecked = false;
          });
        }
      });

      setSelectedOptions(deletedSelectedOptions);
    },
    [selectedOptions],
  );

  const deleteSelectedBySKUId = useCallback(
    (id: string) => {
      const deletedSelectedOptions = produce(selectedOptions, (draft) => {
        const index = draft.findIndex((selectedOption) => selectedOption.value.productSKUs[0].id === id);
        if (index !== -1) draft.splice(index, 1);
      });

      setSelectedOptions(deletedSelectedOptions);
    },
    [selectedOptions],
  );

  const onSelect = useCallback(
    (option: OptionType<any>) => {
      const newSelectedOptions = produce(selectedOptions, (draft) => {
        const updateSelectedOptions = () => {
          const index = selectedOptions.findIndex((selectedOption) => selectedOption.value.id === option.value.id);
          if (index !== -1) {
            draft[index] = option;
          } else {
            draft.push(option);
          }
        };

        if (option.parent) {
          const addNewOption = () => {
            let isChecked = true;
            if (!option.isChecked && option.parent?.value.productSKUs.length === 1) {
              isChecked = false;
            }

            draft.push({ ...(option.parent as OptionType<any>), isChecked, options: [option] });
          };

          const indexParent = selectedOptions.findIndex(
            (selectedOption) => selectedOption.value.id === option.parent?.value.id,
          );
          const parentOption = selectedOptions[indexParent];
          if (parentOption?.options) {
            const index = parentOption.options.findIndex(
              (selectedOption) => selectedOption.value.id === option.value.id,
            );
            if (index !== -1 && indexParent !== -1) {
              ((draft[indexParent] as OptionType<any>).options as OptionType<any>[])[index] = option;
              const isSelected = ((draft[indexParent] as OptionType<any>).options as OptionType<any>[]).some(
                (option) => option.isChecked,
              );

              if (isSelected && !(draft[indexParent] as OptionType<any>).isChecked) {
                (draft[indexParent] as OptionType<any>).isChecked = true;
              }

              if (!isSelected && (draft[indexParent] as OptionType<any>).isChecked) {
                (draft[indexParent] as OptionType<any>).isChecked = false;
              }
            } else if (indexParent !== -1) {
              ((draft[indexParent] as OptionType<any>).options as OptionType<any>[]).push(option);
            } else {
              addNewOption();
            }
          } else {
            const index = selectedOptions.findIndex((selectedOption) => selectedOption.value.id === option.value.id);
            if (index !== -1) {
              draft[index] = option;
            } else {
              addNewOption();
            }
          }
        } else {
          updateSelectedOptions();
        }
      });

      setSelectedOptions(newSelectedOptions);
    },
    [selectedOptions],
  );

  return {
    deleteSelectedById: deleteSelectedBySKUId,
    onClearAllTag,
    onDeleteTag,
    onSelect,
    selectedOptions,
    setSelectedOptions,
  };
};

export default useManageSelectedOptions;
