import { ProductItemPropsType, ProductOutputType, ProductType, Product } from "types/Product";
import { OrderType } from "types/Order";

export enum DigitalContentStatus {
  AVAILABLE = "AVAILABLE",
  PURCHASED = "PURCHASED",
  REDEEMED = "REDEEMED",
  EXPIRED = "EXPIRED",
}

export type DigitalProductConnectionType = {
  results: ProductItemPropsType[];
  total: number;
};

export type DigitalProductConnectionQueryType = {
  digitalProducts: DigitalProductConnectionType;
};

export type DigitalProductCodeConnectionType = {
  results: DigitalContent[];
  total: number;
};

export type DigitalProductCodeConnectionQueryType = {
  digitalProductCodes: DigitalProductCodeConnectionType;
};

export enum DigitalContentAvailabilityType {
  NO_EXPIRY = "NO_EXPIRY",
  DATE_RANGE = "DATE_RANGE",
  EXPIRY_DATE = "EXPIRY_DATE",
  PERIOD_AFTER_PURCHASED = "PERIOD_AFTER_PURCHASED",
}

export type DigitalContentAvailabilitySettings = {
  unit: string;
  value: number;
  availableAt: string;
  expiredAt: string;
};

export type AvailabilityType = {
  type: DigitalContentAvailabilityType;
  settings: DigitalContentAvailabilitySettings;
};

export type DigitalContent = {
  id: string;
  outputType: ProductOutputType;
  content: string;
  refId: string;
  isSold: boolean;
  isUsed: boolean;
  soldAt: string;
  usedAt: string;
  expiredAt: string;
  createdAt: string;
  note: string;
  availability: AvailabilityType;
};

export type RedeemDigitalContent = {
  digitalContent: DigitalContent;
  product: Product;
  order: OrderType;
};

export type RedeemDigitalContentQuery = {
  digitalContentByCode: RedeemDigitalContent;
};

export type DigitalProductsQueryVariablesType = {
  projectId: string;
  limit: number;
  offset: number;
  filter: {
    productName: string;
    productTypes: ProductType[];
  };
};

export type DigitalProductCodesQueryVariablesType = {
  projectId: string;
  productSKUId: string;
  limit: number;
  offset: number;
};

export enum ErrorRedeemCode {
  CODE_ALREADY_REDEEMED = "REDEEM_DIGITAL_CONTENT_BY_ID:DIGITAL_CONTENT_ALREADY_REDEEMED",
  NOT_FOUND = "REDEEM_DIGITAL_CONTENT_BY_ID:DIGITAL_CONTENT_NOT_FOUND",
  CODE_NOT_YET_SOLD = "REDEEM_DIGITAL_CONTENT_BY_ID:CANNOT_REDEEM_DIGITAL_CONTENT_NOT_YET_SOLD",
  CODE_EXPIRED = "REDEEM_DIGITAL_CONTENT_BY_ID:DIGITAL_CONTENT_EXPIRED",
  CODE_NOT_YET_AVAILABLE = "REDEEM_DIGITAL_CONTENT_BY_ID:DIGITAL_CONTENT_NOT_YET_AVAILABLE",
}
