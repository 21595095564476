import { ProductTypeType, ProductOptionType, ProductItemPropsType, ProductImagesDefaultType } from "types/Product";
import { OrderByType } from "types/SalesReport";
import { ShipmentDetail } from "types/Shipping";
import { OrderType } from "types/Order";

type OrderProductReportSummaryType = {
  totalSoldAmount: number;
  totalPrice: number;
};

type ProductReportSKUType = {
  id: string;
  isFree: boolean;
  productCode: string;
  price: number;
  weight: number;
  image: ProductImagesDefaultType;
  productType: ProductTypeType;
  options: ProductOptionType;
};

type OrderProductReportResultType = {
  productSKUId: string;
  product: ProductItemPropsType;
  productSKU: ProductReportSKUType;
  categoryName: string;
  price: number;
  productSKUCode: string;
  total: number;
  amount: number;
};

type OrderProductReportType = {
  result: OrderProductReportResultType;
  total: number;
  summary: OrderProductReportSummaryType;
};

export type OrderProductReportQueryType = {
  orderProductReports: OrderProductReportType;
};

type OrderReportSummaryType = {
  additionalDiscount: number;
  subTotal: number;
  shippingCost: number;
  shippingDiscount: number;
  productDiscount: number;
  grandTotal: number;
};

export type OrderReportResult = OrderType & { lastShipment: ShipmentDetail; trackingNumber: string };

type OrderReportType = {
  results: OrderReportResult[];
  total: number;
  summary: OrderReportSummaryType;
};

export type OrderReportQueryType = {
  orderReports: OrderReportType;
};

export enum ShipmentStatusFilter {
  ASSIGNING_DRIVER = "ASSIGNING_DRIVER",
  ON_GOING = "ON_GOING",
  CANCELED = "CANCELED",
  PICKED_UP = "PICKED_UP",
  REJECTED = "REJECTED",
  COMPLETED = "COMPLETED",
  EXPIRED = "EXPIRED",
  CANCEL = "CANCEL",
  BOOKING = "BOOKING",
  SHIPPING = "SHIPPING",
  COMPLETE = "COMPLETE",
  NO_SHIPMENT = "NO_SHIPMENT",
}

export enum InvoiceStatue {
  PENDING = "Pending",
  SETTLED = "Settled",
  SHIPPING = "Shipping",
  COMPLETED = "Completed",
}

export type SortByType = { sortBy?: string; orderBy?: OrderByType };
