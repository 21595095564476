import { gql } from "@apollo/client";

export const SUBMIT_KYC = gql`
  mutation requestKYCVerification($projectId: ID!, $kycVerificationInput: KYCVerificationInput!) {
    requestKYCVerification(projectId: $projectId, kycVerification: $kycVerificationInput) {
      id
      projectId
      status
      reason
      firstPageOfBankAccountPath
      bank
      bankAccountName
      bankAccountNo
      verifiedBy
      createdAt
      updatedAt
    }
  }
`;

export const REQUEST_WITHDRAW = gql`
  mutation withdrawDeeplePay($projectId: ID!, $amount: Float!) {
    withdrawDeeplePay(projectId: $projectId, amount: $amount) {
      amount
      status
    }
  }
`;

export const UPDATE_DEEPLE_PAY_PAYMENT_CHANNEL = gql`
  mutation updateDeeplePayPaymentChannelConfigurations(
    $projectId: ID!
    $deeplePayPaymentChannelConfigurationInput: [DeeplePayPaymentChannelConfigurationInput!]
  ) {
    updateDeeplePayPaymentChannelConfigurations(
      projectId: $projectId
      deeplePayPaymentChannelConfigurations: $deeplePayPaymentChannelConfigurationInput
    ) {
      type
      isActive
      minimum
      isSelectable
      channels {
        name
        fee {
          rate
          min
          fix
        }
      }
    }
  }
`;

export const DEEPLE_PAY_ORDER_PAID = gql`
  mutation deeplePayOrderPaid($projectId: ID!, $orderId: ID!) {
    deeplePayOrderPaid(projectId: $projectId, orderId: $orderId)
  }
`;
