import styled from "styled-components";
import { VARIANTS } from "constants/Typography";

export const Wrapper = styled.div`
  display: inline-block;
  width: 100%;

  .MuiFormControl-root {
    width: 100%;
    border-radius: 5px;
  }

  select {
    background-color: white;
    padding: 3px 17px 8px 5px;
    font-size: ${VARIANTS.body4.size};
  }

  svg {
    position: absolute;
    right: 0;
    font-size: 14px;
    position: absolute;
    right: 0;
    font-size: 20px;
    top: calc(50% - 10px);
  }
`;
