import styled from "styled-components";
import React from "react";
import Grid from "components/Grid";

type ItemListWrapperPropsType = {
  isFirstItem?: boolean;
};

type GridCardPropsType = {
  index: number;
  amountOfItems: number;
  isContainMoreCardAction: boolean;
  isDesktop: boolean;
};

type GridBottomCardPropsType = {
  isDesktop: boolean;
};

export const ItemListWrapper = styled.div<ItemListWrapperPropsType>`
  padding: 8px 16px 8px 20px;
`;

export const UsageUpgradeTextWrapper = styled.div`
  position: absolute;
  right: 16px;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const GridCard = styled(({ index, amountOfItems, isContainMoreCardAction, isDesktop, ...restProps }) => (
  <Grid {...restProps} />
)).attrs((props: GridCardPropsType) => {
  return props;
})`
  background: ${({ theme }) => theme.COLORS.White};
  padding-bottom: ${({ amountOfItems, index }) => (amountOfItems - 1 === index ? "2px" : "0px")};
  padding-top: ${({ index }) => (index === 0 ? "2px" : "0px")};
  border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  border-top-width: 0px;

  &:first-child {
    border-radius: ${({ isDesktop }) => (isDesktop ? "8px 8px 0px 0px" : "0px")};
    border-width: ${({ isDesktop }) => (isDesktop ? "1px" : "1px 0px 0px 0px")};
  }

  &:last-child {
    border-radius: ${({ isDesktop }) => (isDesktop ? "0px 0px 8px 8px" : "0px")};
    border-width: ${({ isDesktop }) => (isDesktop ? "0px 1px 1px 1px" : "0px 0px 1px 0px")};
  }

  &:first-child:last-child {
    border-radius: ${({ isDesktop }) => (isDesktop ? "8px" : "0px")};
    border-width: ${({ isDesktop }) => (isDesktop ? "1px" : "1px 0px")};
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const GridBottomCard = styled(({ isDesktop, ...restProps }) => <Grid {...restProps} />).attrs(
  (props: GridBottomCardPropsType) => {
    return props;
  },
)`
  background: ${({ theme }) => theme.COLORS.White};
  border-radius: ${({ isDesktop }) => (isDesktop ? "0px 0px 8px 8px" : "0px")};
  padding-bottom: 2px;
  border-bottom: ${({ theme }) => `solid 1px ${theme.COLORS.LightBlue}`};
  border-left: ${({ theme, isDesktop }) => (isDesktop ? `solid 1px ${theme.COLORS.LightBlue}` : "none")};
  border-right: ${({ theme, isDesktop }) => (isDesktop ? `solid 1px ${theme.COLORS.LightBlue}` : "none")};
`;
