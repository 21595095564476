import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import useIsDesktop from "utils/hooks/useIsDesktop";

import { CREDITS } from "graphql/wallet/query";
import { DEFAULT_SHIPPING_METHOD } from "graphql/shipping/query";
import { ProjectIdType } from "types/Project";
import { DefaultShippingMethodType, ShippingMethod, CALCULATE } from "types/Shipping";
import { CreditQueryType } from "types/Wallet";
import { InvoiceTypes } from "types/invoice";

type UseTotalCardPropsType = {
  projectId: string;
  type: InvoiceTypes;
};

const useTotalCredit = ({ projectId, type }: UseTotalCardPropsType) => {
  const isDesktop = useIsDesktop();

  const { loading: isLoading, data, refetch } = useQuery<CreditQueryType, UseTotalCardPropsType>(CREDITS, {
    variables: {
      projectId,
      type,
    },
  });

  const { data: defaultShippingData } = useQuery<{ defaultShippingMethod: DefaultShippingMethodType }, ProjectIdType>(
    DEFAULT_SHIPPING_METHOD,
    {
      variables: {
        projectId,
      },
    },
  );

  useEffect(() => {
    if (isDesktop) {
      const refetchVariables = {
        projectId,
        type,
      };

      refetch(refetchVariables);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop, projectId, type, refetch]);

  if (data) {
    const { mainCredit, logisticCredit } = data;

    const isLalamoveEnable = !!defaultShippingData?.defaultShippingMethod.shipping.some(
      ({ calculateType, disabled }: ShippingMethod) => calculateType === CALCULATE.LALAMOVE && !disabled,
    );
    const isShowLogisticWallet = isLalamoveEnable || !!logisticCredit;

    if (!isDesktop) {
      const credits = [
        {
          type: InvoiceTypes.MAIN,
          credit: mainCredit,
        },
      ];

      if (isShowLogisticWallet) {
        credits.push({
          type: InvoiceTypes.LOGISTIC,
          credit: logisticCredit,
        });
      }

      return {
        credits,
        isLoading,
        isShowLogisticWallet,
      };
    }

    const isFocusMainWallet = type === InvoiceTypes.MAIN;
    const creditValue = isFocusMainWallet ? mainCredit : logisticCredit;

    return {
      credits: [
        {
          type,
          credit: creditValue,
        },
      ],
      isLoading,
      isShowLogisticWallet,
    };
  }

  return {
    credits: [
      {
        type,
        credit: 0,
      },
    ],
    isLoading,
    isShowLogisticWallet: false,
  };
};

export default useTotalCredit;
