import styled from "styled-components";

export const AddImageBox = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  height: 80px;
  width: 80px;
  border: dashed 1px ${({ theme }) => theme.COLORS.LightBlue};
  border-radius: 8px;
  box-sizing: border-box;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
`;

export const CategoryImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: contain;
  text-align: center;
`;
