const inventory = {
  en: {
    "SKU Code": "SKU Code",
    "SKU(s)": "SKU(s)",
    "In stock": "In stock",
    Reserved: "Reserved",
    "Update stock": "Update stock",
    "Update stock record": "Update stock record",
    Summary: "Summary",
    IMPORT: "Import",
    IMPORT_INVENTORY: "Import inventory",
    IMPORTING_METHOD: "Importing method",
    ADD_INVENTORY: "Add",
    ADD_INVENTORY_DETAIL: "Add more products with the existing products in the inventory",
    REPLACE_INVENTORY: "Replace",
    REPLACE_INVENTORY_DETAIL: "Replace the entire inventory with recently added products",
    INVENTORY_IMPORT_ERROR_DUPLICATED: "'{{productCode}}' SKU is duplicated",
    INVENTORY_IMPORT_ERROR_EXCEED_LIMIT: "Import inventory cannot exceed 1,000 rows",
    INVENTORY_IMPORT_ERROR_LESS_THAN_RESERVED: "'{{productCode}}' remaining inventory is lower than the reserved",
    INVENTORY_IMPORT_ERROR_SKU_CODES_NOT_EXISTED: "SKU {{productCode}} is not existed",
    EXPORT_INVENTORY: "Export Inventory",
    EXPORT_INVENTORIES_SUCCESS: "Export inventories are successful",
    IMPORT_INVENTORIES_SUCCESS: "Import inventories are successful",
  },
  th: {
    "SKU Code": "รหัส SKU",
    "SKU(s)": "SKU(s)",
    "In stock": "คงเหลือ ",
    Reserved: "จอง",
    "Update stock": "ปรับปรุงคลังสินค้า",
    "Update stock record": "บันทึกการปรับปรุงคลังสินค้า",
    Summary: "สรุปการปรับปรุงคลังสินค้า",
    IMPORT: "นำเข้า",
    IMPORT_INVENTORY: "นำเข้าคลังสินค้า",
    IMPORTING_METHOD: "วิธีนำเข้าสินค้า",
    ADD_INVENTORY: "เพิ่ม",
    ADD_INVENTORY_DETAIL: "เพิ่มจำนวนสินค้าจากสินค้าที่มีอยู่แล้ว",
    REPLACE_INVENTORY: "แทนที่",
    REPLACE_INVENTORY_DETAIL: "แทนที่จำนวนสินค้าจากสินค้าทั้งหมด",
    INVENTORY_IMPORT_ERROR_DUPLICATED: "SKU '{{productCode}}' ซ้ำ",
    INVENTORY_IMPORT_ERROR_EXCEED_LIMIT: "ข้อมูลคลังสินค้าไม่ควรเกิน 1,000 รายการ",
    INVENTORY_IMPORT_ERROR_LESS_THAN_RESERVED: "ข้อมูลคลังสินค้าคงเหลือของ SKU '{{productCode}}' มีน้อยกว่ายอดจอง",
    INVENTORY_IMPORT_ERROR_SKU_CODES_NOT_EXISTED: "SKU '{{productCode}}' ไม่มีในระบบ",
    EXPORT_INVENTORY: "นำออกคลังสินค้า ",
    EXPORT_INVENTORIES_SUCCESS: "นำออกคลังสินค้าสำเร็จ",
    IMPORT_INVENTORIES_SUCCESS: "นำเข้าคลังสินค้าสำเร็จ",
  },
};

export default inventory;
