const orders = {
  en: {
    order: "order",
    All: "All",
    "Doing Payment": "New order",
    "Ready to Ship": "Ready to Ship",
    Shipped: "Shipped",
    Abandoned: "Abandoned",
    Voided: "Voided",
    Refunded: "Refunded",
    Cancelled: "Cancelled",
    Search: "Search",
    Filters: "Filters",
    "New order": "New order",
    "order.state.pending": "Pending for shipping cost",
    Name: "Name",
    Total: "Total",
    "Ready to approve": "Ready to approve",
    "Ready to ship": "Ready to ship",
    "Show result": "Show result",
    "order no.": "order no.",
    THB: "฿",
    "created on": "created on",
    "Latest created": "Latest created",
    "Oldest created": "Oldest created",
    "Order has been created. wait for payment": "Order has been created, waiting for payment.",
    "Delivery information": "Delivery information",
    "Sender information": "Sender information",
    "Payment method": "Payment method",
    "Payment channel": "Payment channel",
    "Payment status": "Payment status",
    "Transferred to": "Transferred to",
    "Bank transfer": "Bank transfer",
    PromptPay: "PromptPay",
    Express: "Express",
    Standard: "Standard",
    "Receipt no": "Receipt no",
    "Total weight": "Total weight",
    WeightUnit: "{{weightInKgUnit}} Kilogram",
    QTY: "QTY",
    "Order details": "Order details",
    "Sub total": "Sub total",
    "order.paySlip.discount": "Discount",
    "order.paySlip.additionalDiscount": "Additional discount",
    Vat: "Vat",
    Shipping: "Shipping",
    "Discount shipping": "Discount shipping",
    GrandTotal: "GrandTotal",
    "Cancel order": "Cancel order",
    "Add carier/Add tracking": "Add carrier/Add tracking",
    "Expected delivery date": "Expected delivery date",
    "Expected receive date": "Expected receive date",
    "Delivery cost/price": "Delivery cost/price",
    "Tracking number": "Tracking number",
    Submit: "Submit",
    "ORDER SUMMARY": "ORDER SUMMARY",
    "Order Summary": "Order Summary",
    "Payment reciept": "Payment receipt",
    "Payment amount": "Payment amount",
    "Proof of payment": "Payment",
    "Transfer amount": "Transfer amount",
    "Transfer method": "Transfer method",
    "Transferred at": "Transferred at",
    "Order is ready! Prepare your parcel, the list of ordered items show below Please deliver the parcel on Expected Delivery Date":
      "Order is ready! Prepare your parcel, the list of ordered items show below Please deliver the parcel on Expected Delivery Date",
    "Please approve ‘Proof of Payment’ by fill-in payment details":
      "Please approve ‘Proof of Payment’ by fill-in payment details",
    "This order has been shipped. Order details are shown below for reference":
      "This order has been shipped. Order details are shown below for reference.",
    "Order is ready! Please fill shiping details in Shipping confirmation":
      "Order is ready! Please fill shipping details in ‘Shipping confirmation‘.",
    "Order has been paid, Please aprove ‘Proof of Payment": "Order has been paid, Please approve ‘Proof of Payment‘.",
    "Customer details": "Customer details",
    "last updated": "Last updated",
    "This order has been cancelled": "This order has been cancelled.",
    "This order has been abandoned": "This order has been abandoned.",
    "Please select bank": "Please select bank",
    Time: "Time",
    Reject: "Reject",
    Approve: "Approve",
    Date: "Date",
    "Shipping confirmation": "Shipping confirmation",
    Items: "Items",
    "Tracking detail": "Tracking detail",
    "Please choose carrier": "Please choose carrier",
    Carrier: "Carrier",
    "No customer address information": "No customer address information",
    Done: "Done",
    "Customer made wrong order, and request to cancel order.":
      "Customer made wrong order, and request to cancel order.",
    "Customer changed his/her mind, and request to cancel order.":
      "Customer changed his/her mind, and request to cancel order.",
    "Customer is NOT ready to do a payment, and request to cancel order.":
      "Customer is NOT ready to do a payment, and request to cancel order.",
    "This is a spam.": "This is a spam.",
    Other: "Other",
    "Note: There will be message send to your customer to notify that this order is cancelled. The order cancelled could NOT be recovered.":
      "Note: There will be message send to your customer to notify that this order is cancelled. The order cancelled could NOT be recovered.",
    "Type your reason here...": "Type your reason here...",
    "Please give reason why you would like to cancel": "Please give reason why you would like to cancel",
    "Reject This receipt?": "Reject This receipt?",
    "Please specify Tracking number": "Please specify Tracking number",
    "Please select date": "Please select date",
    "Please fill payment amount": "Please fill payment amount",
    "Please select Bank Name": "Please select Bank Name",
    "First name": "First name",
    "Last name": "Last name",
    "Street address": "Street address",
    "Street address2": "Street address2",
    subDistrict: "Sub-District",
    district: "District",
    province: "Province",
    postalCode: "Postal code",
    phoneNumber: "Phone number",
    email: "email",
    deliveryNote: "Delivery note",
    "Delivery Information": "Delivery Information",
    "Please fill firstName": "Please fill firstname",
    "Please fill lastName": "Please fill lastname",
    "Please fill streetAddress": "Please fill street address",
    "Please fill subDistrict": "Please fill sub district",
    "Please fill district": "Please fill district",
    "Please fill province": "Please fill province",
    "Postal Code Must be exactly 5 characters": "Postal Code Must be exactly 5 characters",
    "Explanation can not more than 255 characters": "Explanation can not more than 255 characters",
    "Please fill postalCode": "Please fill postal code",
    "Please fill phoneNumber": "Please fill phone number",
    note: "note",
    Copied: "Copied",
    Export: "Export",
    Promotion: "Promotion",
    "Payment gateway": "Payment gateway",
    Completed: "Completed",
    "COD Approve": "COD Approve",
    "COD Ready to ship": "COD Ready to ship",
    "COD Cancelled": "COD Cancelled",
    "This order has been shipped. Waiting to approve.": "This order has been shipped. Waiting to approve.",
    "Cash on delivery": "Cash on delivery",
    COD: "Cash on delivery",
    "Proof of deposit payment": "Deposit payment",
    "Consignment note": "COD Payment",
    "Payment amount can not be below 0": "Payment amount can not be below 0",
    "Customer rejects the shipment, and request to cancel order.":
      "Customer rejects the shipment, and request to cancel order.",
    "Cancel this COD order?": "Cancel this COD order?",
    "Remaining payment": "Remaining payment",
    "Order.DeliveryTime": "Delivery time",
    "Order.SortingOption.SortingByDisplayDeliveryTime": "Display Delivery time first",
    "Order.SortingOption.NormalSorting": "Display normal order",
    "Order.DeliveryInformation": "Delivery Information",
    "Order.VoidExplanation.VOIDED.title": "Void explanation",
    "Order.VoidExplanation.VOIDED.actionBy": "Voided by",
    "Order.VoidExplanation.REFUNDED.title": "Refunded explanation",
    "Order.VoidExplanation.REFUNDED.actionBy": "Refunded by",
    "Order.ShippingPriceConfirmation": "Shipping price confirmation",
    "Order.ShippingPrice": "Shipping price",
    "Order.ShippingPriceCODRemark":
      "(If you set COD fee, COD fee will be added after you fill the cost ex. shipping cost 100 b + fee (20b) = 120 b)",
    "Order.ShippingPriceNote": "Shipping price note",
    "Order.AdminNote": "Order note",
    "Order.AdminNote.OrderRefCode": "Ref code",
    "Order.AdminNote.Note": "Note",
    "Order.ShipmentStatus": "Shipment status",
    DELIVERY_SCHEDULE_CANNOT_BE_IN_THE_PAST: "Delivery schedule can not be in the past",
    ORDER_STILL_HAS_ACTIVE_SHIPMENT: "Order still has active shipment",
    "Order.PlaceOrderToLalamove": "Place order to Lalamove",
    "Order.LalamoveQuotationPrice": "Lalamove quotation price",
    "Finding driver": "Finding driver",
    "Driver accepted order": "Driver accepted order",
    "Order is cancelled before pick up": "Order is cancelled before pick up",
    "Order is picked up by driver": "Order is picked up by driver",
    "Order was rejected": "Order was rejected",
    "Successfully delivered and transaction has completed": "Successfully delivered and transaction has completed",
    "Order expired": "Order expired",
    CallShipping: "Call",
    CancelShipping: "Cancel",
    plateNumber: "Plate number",
    "Order.PickUpBranchInformation": "Pickup Information",
    "Order.PickUpBranchInformation.Name": "Branch",
    "Order.PickUpBranchInformation.DeliverySchedule": "Pickup time",
    "Submit and open order": "Submit and open order",
    "Submit to customer": "Submit to customer",
    "order.header": "Orders",
    "order.button.addNewOrder": "Add offline order",
    "order.list.result": "Result: {{totalOrder}} orders",
    "autoSuggest.NoPostalCode": "No postal code",
    "autoSuggest.SearchPlaceHolder": "Search postal code, province, district, sub-district",
    "Edit order": "Edit order",
    "order.confirmationText.cancelLalamove": "Are you sure you want to cancel Lalamove?",
    "voidOrder.title": "Void order",
    "voidOrder.description.cancel":
      "Are you sure you want to cancel this order? The order will be moved to Void / Refund status after you submit the cancellation.",
    "voidOrder.description.void":
      "Are you sure you want to void this order? The order will be moved to Void / Refund status after you submit voiding.",
    "voidOrder.description.refund":
      "Are you sure you want to refund this order? The order will be moved to Void / Refund status after you submit refunding.",
    "voidOrder.remark": "Please process the refund to customer or visit the payment gateway to void or refund",
    "voidOrder.label.explanation": "Explanation",
    "voidOrder.placeholder.explanation": "Leave your explanation here",
    "voidOrder.error.explanationRequired": "Please fill an explanation",
    "voidOrder.refund.title": "Refund order",
    "voidOrder.refund.orderTitle": "Order no.",
    "voidOrder.refund.refundTitle": "Total returned",
    "voidOrder.refund.errorMessage": "You balance remains insufficient for the refund.",
    "voidOrder.refund.noticeMessage":
      "A refund requires a total amount of paid order despite the 1convo pay fee already deducted from the original payment. The processing time takes 30-45 days in refunding your customers.",
    "voidOrder.refund.successfullyVoided": "You have successfully voided order no. {{orderNumber}}",
    "voidOrder.refund.successfullyRefunded": "You have successfully refunded for order no. {{orderNumber}}",
    "voidOrder.refund.insufficientError":
      "Your balance remains insufficient for the refund for order no. {{orderNumber}}",
    "order.default.error.notFound": "Order not found",
    "order.default.error.cannotVoidOrderAtThisState": "Cannot void the order at this status",
    "order.default.error.orderStillHasActiveShipment": "The order is in the process of active shipment",
    "order.default.error.cannotVoidOrderContainerDigitalProduct": "Cannot void the order containing digital products",
    "confirmEmail.label": "Email :",
    "confirmEmail.error.required": "Please fill email",
    "confirmEmail.title": "Resend code/content",
    "confirmEmail.label.customerInfo": "Customer's information",
    "resendDigitalContent.toastMsg.success": "Resend digital content successful",
    "resendDigitalContent.toastMsg.fail": "Resend digital content failed",
    "Pending for shipping": "Pending for shipping",
    "Shipping from SHIPPOP": "Shipping from SHIPPOP",
    "Shipment completed": "Shipment completed",
    OrderLargestDimension: "Largest dimension",
    OrderTotalWeight: "Weight (gram)",
    UpdateOrderLargestDimension: "Edit information",
    createdBy: "Created by",
    cancelledBy: "Cancelled by",
    voidedBy: "Voided by",
    refundedBy: "refunded by",
    "Are you sure you want to complete the order without a tracking number":
      "Are you sure you want to complete the order without a tracking number",
    "approvedOrder.orderReachLimit": "Manual payment approval already reached its limit, please upgrade your plan.",
  },
  th: {
    order: "รายการสั่งซื้อ",
    All: "รายการสั่งซื้อทั้งหมด",
    "Doing Payment": "รายการสั่งซื้อใหม่",
    "Ready to Ship": "รอการจัดส่ง",
    Shipped: "รายการที่ส่งแล้ว",
    Abandoned: "รายการที่ไม่มีการชำระเงิน",
    Voided: "ยกเลิกออเดอร์ (void)",
    Refunded: "คืนเงิน (refund)",
    Cancelled: "รายการที่ถูกยกเลิก",
    Search: "ค้นหา",
    Filters: "ตัวกรอง",
    "New order": "รายการสั่งซื้อใหม่",
    "order.state.pending": "รอคำนวณค่าส่ง",
    Name: "ชื่อ",
    Total: "ราคารวม",
    "Ready to approve": "รอการอนุมัติ",
    "Ready to ship": "รายการที่รอการส่ง",
    "Show result": "แสดงผลการค้นหา",
    "order no.": "รายการสั่งซื้อที่",
    THB: "฿",
    "created on": "สร้างเมื่อ",
    "Latest created": "รายการที่สร้างล่าสุด",
    "Oldest created": "รายการที่สร้างเก่าสุด",
    "Order has been created. wait for payment": "มีรายการสั่งซื้อใหม่รอการชำระเงิน",
    "Delivery information": "ข้อมูลการจัดส่ง",
    "Sender information": "ข้อมูลที่อยู่ผู้จัดส่ง",
    "Payment method": "วิธีการชำระเงิน",
    "Payment channel": "ช่องทางการชำระเงิน",
    "Payment status": "สถานะการชำระเงิน",
    "Transferred to": "โอนไปที่",
    "Bank transfer": "โอนเงินผ่านธนาคาร",
    PromptPay: "พร้อมเพย์",
    Express: "ด่วน",
    Standard: "ธรรมดา",
    "Receipt no": "ใบเสร็จที่",
    "Total weight": "น้ำหนักรวม",
    WeightUnit: "{{weightInKgUnit}} กิโลกรัม",
    QTY: "จำนวน",
    "Order details": "รายละเอียดการสั่งซื้อ",
    "Sub total": "ราคา",
    "order.paySlip.discount": "ส่วนลด",
    "order.paySlip.additionalDiscount": "ส่วนลดเพิ่มเติม",
    Vat: "Vat",
    Shipping: "ค่าจัดส่ง",
    "Discount shipping": "ส่วนลดค่าจัดส่ง",
    GrandTotal: "ราคารวมสุทธิ",
    "Cancel order": "ยกเลิกรายการสั่งซื้อ",
    "Add carier/Add tracking": "เพิ่มหมายเลขพัสดุ",
    "Expected delivery date": "วันที่ส่ง",
    "Expected receive date": "วันที่ได้รับ",
    "Delivery cost/price": "ค่าจัดส่ง",
    "Tracking number": "หมายเลขพัสดุ",
    Submit: "ยืนยัน",
    "ORDER SUMMARY": "สรุปข้อมูลรายการสั่งซื้อ",
    "Order Summary": "สรุปข้อมูลรายการสั่งซื้อ",
    "Payment reciept": "ข้อมูลการชำระเงิน",
    "Payment amount": "จำนวนเงินที่ต้องชำระ",
    "Proof of payment": "การชำระเงิน",
    "Transfer amount": "จำนวนเงินที่ชำระ",
    "Transfer method": "วิธีการที่ชำระเงิน",
    "Transferred at": "ชำระเงินเมื่อ",
    "Order is ready! Prepare your parcel, the list of ordered items show below Please deliver the parcel on 'Expected Delivery Date":
      "รายการสั่งซื้อได้รับการชำระเงินเรียบร้อยแล้ว กรุณาส่งสินค้าตามวันที่กำหนด",
    "Please approve ‘Proof of Payment’ by fill-in payment details.": "กรุณาตรวจสอบหลักฐานการชำระเงิน",
    "This order has been shipped. Order details are shown below for reference":
      "รายการสั่งซื้อถูกจัดส่งแล้ว รายละเอียดของการสั่งซื้อจะโชว์ด้านล่าง",
    "Order is ready! Please fill shiping details in Shipping confirmation":
      "รายการสั่งซื้อได้รับการชำระเงินเรียบร้อยแล้ว กรุณาใส่ข้อมูลการจัดส่ง",
    "Order has been paid, Please aprove ‘Proof of Payment": "ได้รับหลักฐานการชำระเงินแล้ว กรุณาตรวจสอบ",
    "Customer details": "รายละเอียดของลูกค้า",
    "last updated": "อัพเดทล่าสุดเมื่อ",
    "This order has been cancelled": "รายการสั่งซื้อถูกยกเลิก",
    "This order has been abandoned": "รายการสั่งซื้อถูกยกเลิก",
    "Please select bank": "โปรดเลือกธนาคาร",
    Time: "เวลา",
    Reject: "ปฏิเสธ",
    Approve: "ยืนยัน",
    Date: "วันที่",
    "Shipping confirmation": "ยืนยันการจัดส่ง",
    Items: "ชิ้น",
    "Tracking detail": "รายละเอียดพัสดุ",
    "Please choose carrier": "กรุณาเลือกบริษัทส่งพัสดุ",
    Carrier: "บริษัทส่งพัสดุ",
    "No customer address information": "ไม่มีรายละเอียดที่อยู่ของลูกค้า",
    Done: "เสร็จสิ้น",
    "Customer made wrong order, and request to cancel order.": "ลูกค้าสั่งซื้อผิดผลาด และต้องการยกเลิกคำสั่งซื้อ",
    "Customer changed his/her mind, and request to cancel order.": "ลูกค้าเปลี่ยนใจ และต้องการยกเลิกคำสั่งซื้อ",
    "Customer is NOT ready to do a payment, and request to cancel order.":
      "ลูกค้ายังไม่พร้อมที่จะชำระเงิน และต้องการยกเลิกคำสั่งซื้อ",
    "This is a spam.": "นี่คือสแปม",
    Other: "อื่นๆ",
    "Note. There will be message send to your customer to notify that this order is cancelled. The order cancelled could NOT be recovered.":
      "หมายเหตุ : จะมีข้อความส่งไปหาลูกค้าเพื่อแจ้งว่าคำสั่งซื้อนี้ได้ถูกยกเลิกแล้ว คำสั่งซื้อที่ถูกยกเลิกจะไม่สามารถกู้คืนได้",
    "Type your reason here...": "ใส่เหตุผลที่จะยกเลิกที่นี่...",
    "Please give reason why you would like to cancel": "โปรดให้เหตุผลที่ต้องการยกเลิกคำสั่งซื้อ",
    "Reject This receipt?": "ปฏิเสธใบเสร็จรับเงินนี้",
    "Please specify Tracking number": "กรุณาระบุหมายเลขพัสดุ",
    "Please select date": "กรุณาเลือกวันที่",
    "Please fill payment amount": "กรุณาใส่จำนวนเงิน",
    "Please select Bank Name": "กรุณาเลือกธนาคาร",
    "First name": "ชื่อ",
    "Last name": "นามสกุล",
    "Street address": "ที่อยู่ 1",
    "Street address2": "ที่อยู่ 2",
    subDistrict: "แขวง/ตำบล",
    district: "เขต/อำเภอ",
    province: "จังหวัด",
    postalCode: "รหัสไปรษณีย์",
    phoneNumber: "หมายเลขโทรศัพท์",
    email: "อีเมล",
    deliveryNote: "หมายเหตุ",
    "Delivery Information": "ข้อมูลที่อยู่การจัดส่ง",
    "Please fill firstName": "กรุณากรอกชื่อ",
    "Please fill lastName": "กรุณากรอกนามสกุล",
    "Please fill streetAddress": "กรุณากรอกที่อยู่",
    "Please fill subDistrict": "กรุณากรอกแขวง/ตำบล",
    "Please fill district": "กรุณากรอกเขต/อำเภอ",
    "Please fill province": "กรุณากรอกจังหวัด",
    "Postal Code Must be exactly 5 characters": "กรุณากรอกรหัสไปรษณีย์เป็นตัวเลข 5 หลัก",
    "Explanation can not more than 255 characters": "คำอธิบายการยกเลิกรายการสั่งซื้อต้องไม่เกิน 255 อักษร",
    "Please fill postalCode": "กรุณากรอกรหัสไปรษณีย์",
    "Please fill phoneNumber": "กรุณากรอกเบอร์โทรศัพท์",
    note: "หมายเหตุ",
    Copied: "คัดลอกแล้ว",
    Export: "ดาวน์โหลด",
    Promotion: "โปรโมชั่น",
    "Payment gateway": "บัตรเครดิต, อินเตอร์เน็ตแบงค์กิ้งและอื่นๆ",
    Completed: "สำเร็จ",
    "COD Approve": "COD รอการยืนยัน",
    "COD Ready to ship": "COD พร้อมจัดส่ง",
    "COD Cancelled": "COD ถูกยกเลิก",
    "This order has been shipped. Waiting to approve.": "รายการสั่งซื้อถูกจัดส่งแล้ว กำลังรอการยืนยัน",
    "Cash on delivery": "เก็บเงินปลายทาง",
    COD: "เก็บเงินปลายทาง",
    "Proof of deposit payment": "การชำระเงินมัดจำ",
    "Consignment note": "การชำระเงินปลายทาง",
    "Payment amount can not be below 0": "จำนวนเงินที่ต้องชำระไม่สามารถน้อยกว่า 0",
    "Customer rejects the shipment, and request to cancel order.":
      "ลูกค้าปฏิเสธการรับสินค้า และต้องการยกเลิกการสั่งซื้อ",
    "Cancel this COD order?": "ยกเลิกรายการสั่งซื้อ แบบเก็บเงินปลายทางนี้ ?",
    "Remaining payment": "ยอดเก็บเงินปลายทาง",
    "Order.DeliveryTime": "เวลาที่ต้องส่งของให้ลูกค้า",
    "Order.SortingOption.SortingByDisplayDeliveryTime": "แสดงเวลาที่ต้องส่งของให้ลูกค้าก่อน",
    "Order.SortingOption.NormalSorting": "แสดงเวลารายการสั่งซื้อแบบปกติ",
    "Order.DeliveryInformation": "ข้อมูลการจัดส่ง",
    "Order.VoidExplanation.VOIDED.title": "หมายเหตุการยกเลิกรายการสั่งซื้อ (VOID)",
    "Order.VoidExplanation.VOIDED.actionBy": "ถูกยกเลิกโดย",
    "Order.VoidExplanation.REFUNDED.title": "หมายเหตุการคืนเงิน (REFUNDED)",
    "Order.VoidExplanation.REFUNDED.actionBy": "คืนเงินโดย",
    "Order.ShippingPriceConfirmation": "ยืนยันค่าจัดส่ง",
    "Order.ShippingPrice": "ค่าจัดส่ง",
    "Order.ShippingPriceCODRemark":
      "(ถ้ามีการตั้งค่าธรรมเนียมเก็บเงินปลายทางไว้ ระบบจะบวกเพิ่มจากค่าส่งที่กรอกในช่องนี้เช่นกรอก 100 จะบวกค่าธรรมเนียม 20 เป็น 120)",
    "Order.ShippingPriceNote": "หมายเหตุค่าจัดส่ง",
    "Order.AdminNote": "หมายเหตุ",
    "Order.AdminNote.OrderRefCode": "Ref code",
    "Order.AdminNote.Note": "หมายเหตุ",
    "Order.ShipmentStatus": "สถานะการจัดส่ง",
    DELIVERY_SCHEDULE_CANNOT_BE_IN_THE_PAST: "Delivery schedule can not be in the past",
    ORDER_STILL_HAS_ACTIVE_SHIPMENT: "Order still has active shipment",
    "Order.PlaceOrderToLalamove": "ส่งคำสั่งซื้อไปที่ Lalamove",
    "Order.LalamoveQuotationPrice": "ค่าส่งโดย Lalamove",
    "Finding driver": "กำลังค้นหาคนขับ",
    "Driver accepted order": "คนขับรับคำสั่ง",
    "Order is cancelled before pick up": "คำสั่งถูกยกเลิกก่อนมารับสินค้า",
    "Order is picked up by driver": "คนขับรับสินค้าเรียบร้อยแล้ว",
    "Order was rejected": "คำสั่งถูกปฎิเสธ",
    "Successfully delivered and transaction has completed": "ส่งสินค้าเรียบร้อยแล้ว",
    "Order expired": "คำสั่งหมดอายุ (ไม่สามารถหาคนขับได้)",
    CallShipping: "เรียก",
    CancelShipping: "ยกเลิก",
    plateNumber: "เลขทะเบียนรถ",
    "Order.PickUpBranchInformation": "ข้อมูลการรับสินค้า",
    "Order.PickUpBranchInformation.Name": "สาขา",
    "Order.PickUpBranchInformation.DeliverySchedule": "เวลารับสินค้า",
    "Submit and open order": "ยืนยันค่าจัดส่งและเปิดการสั่งซื้อ",
    "Submit to customer": "ให้ลูกค้ายืนยันค่าจัดส่ง",
    "order.header": "รายการสั่งซื้อ",
    "order.button.addNewOrder": "เพิ่มออเดอร์ออฟไลน์",
    "order.list.result": "ผลการค้นหา: {{totalOrder}} รายการสั่งซื้อ",
    "autoSuggest.NoPostalCode": "ไม่มีรหัสไปรษณีย์",
    "autoSuggest.SearchPlaceHolder": "ค้นหารหัสไปรษณีย์, จังหวัด, อำเภอ, ตำบล",
    "Edit order": "แก้ไขรายการสั่งซื้อ",
    "order.confirmationText.cancelLalamove": "คุณต้องการที่จะยกเลิก Lalamove ใช่หรือไม่?",
    "voidOrder.title": "ยกเลิกรายการสั่งซื้อ",
    "voidOrder.description.cancel":
      "คุณแน่ใจที่จะยกเลิกรายการสั่งซื้อ? รายการสั่งซื้อจะถูกย้ายไปยังสถานะยกเลิก/คืนเงิน หลังจากที่คุณยืนยันการยกเลิก",
    "voidOrder.description.void":
      "แน่ใจหรือไม่ว่าต้องการยกเลิกคำสั่งซื้อนี้ หลังจากที่ลูกค้าส่งคำร้องเข้ามา คำสั่งซื้อจะถูกเปลี่ยนสถานะเป็น Void หรือยกเลิกรายการสั่งซื้อ",
    "voidOrder.description.refund":
      "แน่ใจหรือไม่ว่าต้องการขอคืนเงินสำหรับคำสั่งซื้อนี้ หลังจากที่ลูกค้าส่งคำร้องเข้ามา คำสั่งซื้อจะถูกเปลี่ยนสถานะเป็น Refund หรือขอคืนเงิน",
    "voidOrder.remark": "กรุณาดำเนินการคืนเงินให้ลูกค้าหรือ ไปที่เว็บไซต์ของ payment gateway เพื่อยกเลิกหรือคืนเงิน",
    "voidOrder.label.explanation": "คำอธิบาย",
    "voidOrder.placeholder.explanation": "เพิ่มคำอธิบาย",
    "voidOrder.error.explanationRequired": "กรุณาเพิ่มคำอธิบาย",
    "voidOrder.refund.title": "คืนเงินรายการสั่งซื้อ",
    "voidOrder.refund.orderTitle": "รายการสั่งซื้อที่",
    "voidOrder.refund.refundTitle": "คืนเงินทั้งหมด",
    "voidOrder.refund.errorMessage": "ยอดเงินคงเหลือของคุณไม่เพียงพอสำหรับการคืนเงิน",
    "voidOrder.refund.noticeMessage":
      "การคืนเงินลูกค้า ทางร้านค้าจะถูกหักค่าธรรมเนียม 1convo pay จากรายการคำสั่งซื้อที่ขายสินค้าสำเร็จ และ 1convo จะทำการโอนเงินเต็มจำนวนให้ลูกค้าภายในระยะเวลา 30-45 วัน",
    "voidOrder.refund.successfullyVoided": "คุณได้ยกเลิกรายการซื้อหมายเลข {{orderNumber}} สำเร็จ",
    "voidOrder.refund.successfullyRefunded": "คุณได้เงินคืนเรียบร้อยแล้ว รายการสั่งซื้อหมายเลข {{orderNumber}}",
    "voidOrder.refund.insufficientError": "ยอดเงินของคุณไม่เพียงพอสำหรับการคืนเงิน รายการซื้อเลขที่ {{orderNumber}}",
    "order.default.error.notFound": "ไม่พบรายการสั่งซื้อที่สามารถยกเลิก (VOID) ได้",
    "order.default.error.cannotVoidOrderAtThisState": "ไม่สามารถยกเลิกรายการสั่งซื้อ (VOID) ที่สถานะนี้",
    "order.default.error.orderStillHasActiveShipment": "รายการสั่งซื้อกำลังทำการจัดส่ง ไม่สามารถยกเลิก (VOID) ได้",
    "order.default.error.cannotVoidOrderContainerDigitalProduct":
      "ไม่สามารถยกเลิกรายการสินค้า (VOID) ที่มีสินค้าดิจิทัลได้",
    "confirmEmail.label": "อีเมล :",
    "confirmEmail.error.required": "กรุณากรอกอีเมล",
    "confirmEmail.title": "ส่งโค้ด/คอนเทนต์อีกครั้ง",
    "confirmEmail.label.customerInfo": "ข้อมูลลูกค้า",
    "resendDigitalContent.toastMsg.success": "ส่ง Digital content สำเร็จ",
    "resendDigitalContent.toastMsg.fail": "ส่ง Digital content ล้มเหลว",
    "Pending for shipping": "รอการจัดส่ง",
    "Shipping from SHIPPOP": "อยู่ระหว่างการฃนส่ง",
    "Shipment completed": "ส่งสินค้าเรียบร้อยแล้ว",
    OrderLargestDimension: "ขนาดพัสดุโดยประมาณ",
    OrderTotalWeight: "น้ำหนัก (กรัม)",
    UpdateOrderLargestDimension: "แก้ไขข้อมูล",
    createdBy: "สร้างโดย",
    cancelledBy: "ยกเลิกโดย",
    voidedBy: "ยกเลิกโดย (void)",
    refundedBy: "คืนเงินโดย (refunded)",
    "Are you sure you want to complete the order without a tracking number":
      "คุณต้องการที่จะเปลี่ยนสถานะของรายการสั่งซื้อเป็นสำเร็จโดยไม่มีเลขพัสดุหรือไม่",
    "approvedOrder.orderReachLimit": "การอนุมัติชำระเงินโดยแอดมินเต็มจำนวนการใช้งานแล้ว กรุณาอัปเกรดแพลน",
  },
};

export default orders;
