import times from "lodash/times";

const MOCK_DEFAULT_ORDER_REPORT_DATA = {
  customerName: "customer",
  state: "COMPLETED",
  shippingMethod: "STANDARD",
  shippingPrice: "100",
  streetAddress: "ที่อยู่ แขวงคลองตัน เขตคลองเตย กรุงเทพมหานคร 10110",
  phoneNumber: "0987654321",
  email: "email@1convo.ai",
  trackingNumber: "trackingNo",
  vatOptions: "NONE",
  productPrice: "1,200",
  discountTotalPrice: "200",
  discountShippingPrice: "100",
  grandTotal: "1,000",
  paymentAt: new Date(),
  referenceCode: "reference",
  subTable: [],
  billNumber: {
    projectId: "1",
    orderId: "1",
    billNumber: "billNo",
  },
  additionalDiscount: "0",
};

export const MOCK_ORDER_REPORT_DATA = times(7, (index: number) => {
  const mockOrderNumber = `ONS000000000${index}`;

  return {
    ...MOCK_DEFAULT_ORDER_REPORT_DATA,
    id: index,
    orderNumber: mockOrderNumber,
  };
});

const MOCK_DEFAULT_PRODUCT_REPORT_DATA = {
  productCode: "PD_00000",
  productName: "Product",
  categoryName: "Category",
  price: "100",
  amount: "1",
  sku: "-",
  total: "100",
};

export const MOCK_PRODUCT_REPORT_DATA = times(7, (index: number) => {
  return {
    ...MOCK_DEFAULT_PRODUCT_REPORT_DATA,
    id: index,
  };
});

export const MOCK_SHIPPOP_REPORT_DATA = {
  id: "156",
  orderNumber: "ON2102180002",
  customerName: "customerName",
  state: "CANCELLED",
  shippingMethod: "STANDARD",
  shippingPrice: "0.00",
  shipping: "-",
  streetAddress: "11\n \n  ปาเสมัส สุไหงโก-ลก นราธิวาส 96120",
  phoneNumber: "091779xxxx",
  email: "-",
  trackingNumber: "-",
  vatOptions: "NONE",
  productPrice: "100.00",
  discountTotalPrice: "0.00",
  discountShippingPrice: "0.00",
  grandTotal: "100.00",
  paymentAt: "-",
  referenceCode: "-",
  subTable: [
    {
      productName: "test001",
      image: "/assets/img/no-img-available.png",
      productCode: "BAG10",
      productPrice: 100,
      amount: 1,
      weight: 0,
      sku: "-",
      subRows: [],
    },
  ],
  billNumber: { projectId: "5", orderId: "156" },
  additionalDiscount: "0.00",
  totalWeight: "-",
  dimension: "-",
};

export const MOCK_SHIPPOP_REPORTS_DATA = times(10, (index: number) => {
  return {
    ...MOCK_SHIPPOP_REPORT_DATA,
    id: index,
  };
});
