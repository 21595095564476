import { gql } from "@apollo/client";

export const REGISTER_FCM_TOKEN = gql`
  mutation registerFCMToken($token: String!) {
    registerFCMToken(token: $token)
  }
`;

export const MARK_NOTIFICATION = gql`
  mutation markNotification($projectId: ID!, $notificationId: ID!) {
    markNotification(projectId: $projectId, notificationId: $notificationId) {
      # id
      # createdAt
      # readAt

      id
      userId
      type # NotificationType
      customer {
        # Customer
        id
        uuid
        projectId
        platform
        name
        pictureUrl
      }
      payload {
        # NotificationPayload
        title
        body
        action
      }
      createdAt
      readAt
    }
  }
`;

export const UPDATE_NOTIFICATION_CONFIG = gql`
  mutation updateNotificationConfigurations(
    $projectId: ID!
    $userId: ID!
    $notificationConfigurations: [UserNotificationConfigurationInput!]!
  ) {
    updateNotificationConfigurations(
      projectId: $projectId
      userId: $userId
      notificationConfigurations: $notificationConfigurations
    ) {
      type
      name
      isActive
      isSelectable
      priority
    }
  }
`;

export const SEEN_NOTIFICATION = gql`
  mutation seenNotification($projectId: ID!) {
    seenNotification(projectId: $projectId)
    # return Boolean
  }
`;
