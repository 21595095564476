import { gql } from "@apollo/client";

export const UPDATE_BRANCHES = gql`
  mutation updateOfflineStores($projectId: ID!, $offlineStoresInput: [OfflineStoreInput]!) {
    offlineStores: updateOfflineStores(projectId: $projectId, offlineStoresInput: $offlineStoresInput) {
      id
      projectId
      name
      address
      subDistrict
      district
      province
      postalCode
      latitude
      longitude
      isAvailableForLalamove
      phoneNumber
      location
      isActive
      isAvailableForPickUp
      openingHours {
        day
        open
        close
        isActive
      }
    }
  }
`;

export const UPDATE_BRANCH = gql`
  mutation upsertOfflineStore($projectId: ID!, $offlineStoreInput: OfflineStoreInput!) {
    offlineStore: upsertOfflineStore(projectId: $projectId, offlineStoreInput: $offlineStoreInput) {
      id
      projectId
      name
      address
      subDistrict
      district
      province
      postalCode
      latitude
      longitude
      isAvailableForLalamove
      phoneNumber
      location
      isActive
      isAvailableForPickUp
      openingHours {
        day
        open
        close
        isActive
      }
    }
  }
`;

export const DELETE_BRANCH = gql`
  mutation deleteOfflineStore($projectId: ID!, $offlineStoreId: ID!) {
    deleteOfflineStore(projectId: $projectId, offlineStoreId: $offlineStoreId) {
      id
      projectId
      name
      address
      subDistrict
      district
      province
      postalCode
      latitude
      longitude
      isAvailableForLalamove
      phoneNumber
      location
      isActive
      isAvailableForPickUp
      openingHours {
        day
        open
        close
        isActive
      }
    }
  }
`;
