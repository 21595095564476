import React, { FC } from "react";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Wrapper } from "./styled";

type RowsSelectorPerPagePropsType = {
  options: number[];
  onChange: (rowsPerPage: number) => void;
  rowsPerPage: number;
};

const RowsSelectorPerPage: FC<RowsSelectorPerPagePropsType> = ({ options, onChange, rowsPerPage }) => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(Number(event.target.value) as number);
  };

  return (
    <Wrapper>
      <FormControl variant="outlined">
        <Select native value={rowsPerPage} onChange={handleChange}>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      </FormControl>
    </Wrapper>
  );
};

export default RowsSelectorPerPage;
