import React, { FC } from "react";
import clsx from "clsx";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import Grid from "components/Grid";
import Typography from "components/Typography";
import ControllerTimePicker from "components/TimePicker/ControllerTimePicker";

type WorkingHoursPropsType = {
  defaultIsActiveOpeningHours?: boolean;
  branchIndex: number;
  onChangeOpening?: (time: string) => void;
  openingTime: string;
  closingTime: string;
  onChangeClosing?: (time: string) => void;
  disabled?: boolean;
  openingName: string;
  closingName: string;
};

const WorkingHours: FC<WorkingHoursPropsType> = ({
  defaultIsActiveOpeningHours = false,
  branchIndex,
  openingTime,
  closingTime,
  disabled = false,
  openingName,
  closingName,
}) => {
  const { control, watch, errors } = useFormContext();
  const { t } = useTranslation();
  const isActive = watch(`openingHours[${branchIndex}].isActive`, defaultIsActiveOpeningHours);
  const isError = Boolean(get(errors, `openingHours[${branchIndex}].message`));

  return (
    <>
      <Grid container alignItems="center" justify="center" className={clsx({ "d-none": isActive })}>
        <Grid item>
          <Typography variant="body3">{t("offlineStore.workingHours.close")}</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justify="space-between"
        className={clsx({ "d-none": !isActive }, "flex-nowrap")}
      >
        <Grid item>
          {openingTime && (
            <ControllerTimePicker
              name={openingName}
              defaultValue={openingTime}
              disabled={disabled}
              control={control}
              isError={isError}
            />
          )}
        </Grid>
        <Grid item className="px-1">
          {t("to")}
        </Grid>
        <Grid item>
          {closingTime && (
            <ControllerTimePicker
              name={closingName}
              defaultValue={closingTime}
              disabled={disabled}
              control={control}
              isError={isError}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default WorkingHours;
