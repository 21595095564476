import MuiAvatar from "@material-ui/core/Avatar";
import styled from "styled-components";

import { DEFAULT_AVATAR_IMG } from "constants/Image";
import { COLORS } from "constants/Colors";

type AvatarPropsType = {
  size?: string;
  color?: string;
};

const Avatar = styled(MuiAvatar)<AvatarPropsType>`
  width: ${({ size }) => size || "32px"};
  height: ${({ size }) => size || "32px"};
  background-color: ${({ color }) => color || COLORS.DarkLight};
`;

Avatar.defaultProps = {
  src: DEFAULT_AVATAR_IMG,
};

export default Avatar;
