import React, { FC, useState } from "react";
import { OptionType, ValueType } from "react-select";
import moment from "moment";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import DatePicker from "components/DatePicker";
import Select from "components/Select";

import { DATE_PICKER_DATE_FORMAT } from "constants/DateTimeFormat";

type DateFilterSelectorPropsType = {
  endDate: string;
  onEndDate: (date: string | null) => void;
  onStartDate: (date: string | null) => void;
  startDate: string;
};

const DateFilterSelector: FC<DateFilterSelectorPropsType> = ({ endDate, onEndDate, onStartDate, startDate }) => {
  const { t } = useTranslation();

  const [isShowDateRange, setIsShowDateRange] = useState(false);
  const [selectedSortDateVal, setSelectedSortDateVal] = useState<OptionType>({ value: "all", label: t("All time") });

  const dropdownSortDateOptions: OptionType[] = [
    { value: "all", label: t("All time") },
    { value: "yesterday", label: t("yesterday") },
    { value: "last7days", label: t("last7days") },
    { value: "last30days", label: t("last30days") },
    { value: "thisWeek", label: t("thisWeek") },
    { value: "thisMonth", label: t("thisMonth") },
    { value: "lastWeek", label: t("lastWeek") },
    { value: "lastMonth", label: t("lastMonth") },
    { value: "dateRange", label: t("Date range") },
  ];

  const handleSortDateChange = (val: ValueType<OptionType>) => {
    setSelectedSortDateVal(val as OptionType);

    if ((val as OptionType).value === "dateRange") {
      setIsShowDateRange(true);
      onStartDate(moment().subtract(30, "days").toISOString());
      onEndDate(moment().toISOString());
    } else if ((val as OptionType).value === "last30days") {
      setIsShowDateRange(false);
      onStartDate(moment().subtract(30, "days").toISOString());
      onEndDate(moment().toISOString());
    } else if ((val as OptionType).value === "last7days") {
      setIsShowDateRange(false);
      onStartDate(moment().subtract(7, "days").toISOString());
      onEndDate(moment().toISOString());
    } else if ((val as OptionType).value === "yesterday") {
      setIsShowDateRange(false);
      onStartDate(moment().subtract(1, "days").toISOString());
      onEndDate(moment().toISOString());
    } else if ((val as OptionType).value === "thisWeek") {
      // get last week start from first day of week
      const currentWeekFirstDay = moment().startOf("week");
      setIsShowDateRange(false);
      onStartDate(currentWeekFirstDay.toISOString());
      onEndDate(moment().toISOString());
    } else if ((val as OptionType).value === "thisMonth") {
      // get last month start from first day of month
      const currentMonthFirstDay = moment().startOf("month");
      setIsShowDateRange(false);
      onStartDate(currentMonthFirstDay.toISOString());
      onEndDate(moment().toISOString());
    } else if ((val as OptionType).value === "lastWeek") {
      // get last week start from sunday to end of week
      const lastWeekFirstDay = moment().weekday(-7);
      const lastWeekLastDay = moment(lastWeekFirstDay).add("d", 6);
      setIsShowDateRange(false);
      onStartDate(lastWeekFirstDay.toISOString());
      onEndDate(lastWeekLastDay.toISOString());
    } else if ((val as OptionType).value === "lastMonth") {
      // get last month start from 1st to end of month
      const prevMonthFirstDay = moment().subtract(1, "months").startOf("month");
      const prevMonthLastDay = moment(prevMonthFirstDay).endOf("month");

      setIsShowDateRange(false);
      onStartDate(prevMonthFirstDay.toISOString());
      onEndDate(prevMonthLastDay.toISOString());
    } else if ((val as OptionType).value === "all") {
      setIsShowDateRange(false);
      onStartDate("");
      onEndDate("");
    } else {
      onStartDate("");
      onEndDate("");
      setIsShowDateRange(false);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Select
          value={selectedSortDateVal}
          options={dropdownSortDateOptions}
          onChange={handleSortDateChange}
          fullWidth
        />
      </Grid>
      {isShowDateRange && (
        <Grid className="order-filter-date-range" item container>
          <Grid item xs={6} className="py-2 pr-1">
            <DatePicker selectedDate={startDate} setDate={onStartDate} fullWidth format={DATE_PICKER_DATE_FORMAT} />
          </Grid>
          <Grid item xs={6} className="py-2 pl-1">
            <DatePicker selectedDate={endDate} setDate={onEndDate} fullWidth format={DATE_PICKER_DATE_FORMAT} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default DateFilterSelector;
