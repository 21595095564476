import React from "react";
import get from "lodash/get";
import { convertPriceFormat } from "utils/common";
import i18n from "utils/i18n";
import { CellProps, useSortBy, useFlexLayout, useExpanded } from "react-table";
import moment from "moment";

import Typography from "components/Typography";
import { SvgIcon } from "components/Icon";
import { IcOutcome, IcInfo } from "components/SvgIcons";
import Tooltip from "components/Tooltip";

import COLORS from "constants/Colors";
import { Box } from "@material-ui/core";
import useSelection from "./hooks/useSelection";

import { DEFAULT_VALUE } from "../config";

export const tableHooks = [useSortBy, useExpanded, useFlexLayout, useSelection];

export const getColumns = <T extends object>() => {
  return [
    {
      id: "deeplePay.transaction.table.withdrawal.header.email",
      disableSortBy: true,
      Header: () => i18n.t("deeplePay.transaction.table.withdrawal.header.email"),
      accessor: "email",
      sticky: null,
      minWidth: 210,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <Box display="flex" alignItems="center">
            <Typography variant="body3" color={COLORS.DarkGray}>
              {get(cell, "row.original.email")}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: "deeplePay.transaction.table.withdrawal.header.paymentAmount",
      disableSortBy: true,
      Header: () => i18n.t("deeplePay.transaction.table.withdrawal.header.paymentAmount"),
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <Box display="flex" alignItems="center">
            <SvgIcon component={IcOutcome} className="p-0" viewBox="0 0 22 22" />
            <Typography variant="body3" color={COLORS.DarkGray}>
              ฿ {convertPriceFormat(get(cell, "row.original.amount"))}
            </Typography>
          </Box>
        );
      },
      sticky: null,
      minWidth: 210,
    },
    {
      id: "deeplePay.transaction.table.withdrawal.header.destination",
      disableSortBy: true,
      Header: () => i18n.t("deeplePay.transaction.table.withdrawal.header.destination"),
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <Box>
            <Typography variant="body3" color={COLORS.DarkGray}>
              {get(cell, "row.original.detail.bank")}
            </Typography>
            <Typography variant="body4" color={COLORS.DarkLight}>
              {get(cell, "row.original.detail.bankAccountNumber")}
            </Typography>
          </Box>
        );
      },
      sticky: null,
      minWidth: 210,
    },
    {
      id: "deeplePay.transaction.table.withdrawal.header.createdDate",
      disableSortBy: true,
      Header: () => i18n.t("deeplePay.transaction.table.withdrawal.header.createdDate"),
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const created = get(cell, "row.original.createdDate");
        return (
          <Typography variant="body3" color={COLORS.DarkGray}>
            {created ? moment(created).format("DD MMM YYYY HH:mm") : "-"}
          </Typography>
        );
      },
      sticky: null,
      minWidth: 210,
    },
    {
      id: "deeplePay.transaction.table.withdrawal.header.status",
      disableSortBy: true,
      Header: () => i18n.t("deeplePay.transaction.table.withdrawal.header.status"),
      accessor: "status",
      sticky: null,
      minWidth: 140,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const status = get(cell, "row.original.status")?.toLowerCase();
        return (
          <div className="status">
            <span className={`dot ${status}`} />
            {i18n.t(`deeplePay.transaction.table.${status}`)}
          </div>
        );
      },
    },
  ];
};

const MAPPING_STATUS_TO_COLUMNS = {
  PROCESSING: ["receivedAmount", "accountNo", "fee", "referenceNo"],
  COMPLETED: ["receivedAmount", "accountNo", "fee", "referenceNo"],
  VOIDED: ["receivedAmount", "accountNo", "fee", "referenceNo"],
  DECLINED: ["receivedAmount", "accountNo", "fee", "referenceNo"],
  DEFAULT: ["receivedAmount", "accountNo", "fee", "referenceNo"],
};

const SUB_TABLE_COLUMNS = [
  {
    Header: () => i18n.t("deeplePay.transaction.table.withdrawal.row.accountNo"),
    width: 210,
    id: "accountNo",
    disableSortBy: true,
    // eslint-disable-next-line react/display-name
    Cell: (cell: any) => {
      return (
        <Box>
          <Typography variant="body3" color={COLORS.DarkGray}>
            {get(cell, "row.original.detail.bank")} {get(cell, "row.original.detail.bankAccountNumber")}
          </Typography>
        </Box>
      );
    },
  },
  {
    // eslint-disable-next-line react/display-name
    Header: () => (
      <div style={{ display: "flex", alignItems: "center" }}>
        {i18n.t("deeplePay.transaction.table.income.row.receivedAmount")}
        <Tooltip title={i18n.t("deeplePay.transaction.table.tooltip.receivedAmount") as string} placement="right" arrow>
          <SvgIcon viewBox="-6 -6 24 24">
            <IcInfo />
          </SvgIcon>
        </Tooltip>
      </div>
    ),
    width: 210,
    accessor: "receivedAmount",
    id: "receivedAmount",
    disableSortBy: true,
    Cell: (cell: any) => {
      const fee = cell.value;
      if (fee == null) {
        return DEFAULT_VALUE;
      }
      return `${typeof fee === "number" ? `฿ ${convertPriceFormat(fee)}` : fee}`;
    },
  },
  {
    // eslint-disable-next-line react/display-name
    Header: (cell: any) => {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {i18n.t("deeplePay.transaction.table.income.row.fee")}
          <Tooltip
            title={i18n.t("deeplePay.transaction.table.tooltip.vatIncluded", { vat: cell?.data[0].feeVAT }) as string}
            placement="right"
            arrow
          >
            <SvgIcon viewBox="-6 -6 24 24">
              <IcInfo />
            </SvgIcon>
          </Tooltip>
        </div>
      );
    },
    width: 210,
    accessor: "fee",
    id: "fee",
    disableSortBy: true,
    Cell: (cell: any) => {
      const fee = cell.value;
      if (fee == null) {
        return DEFAULT_VALUE;
      }
      return `${typeof fee === "number" ? `฿ ${convertPriceFormat(fee)}` : fee}`;
    },
  },
  {
    Header: () => i18n.t("deeplePay.transaction.table.withdrawal.row.referenceNo"),
    width: 210,
    accessor: "referenceNo",
    id: "referenceNo",
    disableSortBy: true,
  },
  {
    Header: () => i18n.t("deeplePay.transaction.table.withdrawal.row.refundDate"),
    width: 210,
    accessor: "refundDate",
    id: "refundDate",
    disableSortBy: true,
  },
];

export const getSubTableColumns = (row: any) => {
  // eslint-disable-next-line
  // @ts-ignore
  const mapColumnsByStatus: string[] = MAPPING_STATUS_TO_COLUMNS[row.status] || MAPPING_STATUS_TO_COLUMNS.DEFAULT;
  return SUB_TABLE_COLUMNS.filter((column) => mapColumnsByStatus.includes(column.id));
};
