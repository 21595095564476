import * as React from "react";

const IcCircleCheck = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="-5 0 24 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="9.5" cy="9.5" r="9.5" fill="currentColor" />
      <path
        fill="#FBFCFE"
        d="M12.266 6.934c.273-.273.715-.273.988 0s.273.715 0 .988l-4.191 4.19c-.273.274-.716.274-.988 0L5.746 9.785c-.273-.272-.273-.715 0-.987.273-.273.715-.273.988 0L8.57 10.63l3.697-3.697z"
      />
    </g>
  </svg>
);

export default IcCircleCheck;
