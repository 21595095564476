import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import moment, { Moment } from "moment";

import { STAT, TOP_SELLING_PRODUCT_FROM_ORDER_WITH_PERIOD, TOP_SELLING_PRODUCT_FROM_ORDER } from "graphql/stat/query";
import {
  StatQueryType,
  StatVariablesType,
  TopSellingProductsFromOrderType,
  TopSellingProductsFromOrderVariablesType,
  TopSellingProductsFromOrderWithPeriodType,
  TopSellingProductsFromOrderWithPeriodVariablesType,
} from "types/Stat";
import { DASHBOARD_DATE_TIME_FORMAT, TIME_FORMAT } from "constants/DateTimeFormat";

import { getStatStartTime, getStatEndTime } from "../utils";

const useStatData = (projectId: string, platformFilter: Record<string, string | string[]>, periodOption: string) => {
  const initEndTime = moment().format("HH:mm");
  const [selectedDuration, setSelectedDuration] = useState(0);

  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState(initEndTime);
  const [getStat, { data, loading }] = useLazyQuery<StatQueryType, StatVariablesType>(STAT);

  const [
    getTopSellingWithPeriod,
    { data: dataTopSellingWithPeriod, loading: loadingTopSellingWithPeriod },
  ] = useLazyQuery<TopSellingProductsFromOrderWithPeriodType, TopSellingProductsFromOrderWithPeriodVariablesType>(
    TOP_SELLING_PRODUCT_FROM_ORDER_WITH_PERIOD,
  );

  const [getTopSelling, { data: dataTopSelling, loading: loadingTopSelling }] = useLazyQuery<
    TopSellingProductsFromOrderType,
    TopSellingProductsFromOrderVariablesType
  >(TOP_SELLING_PRODUCT_FROM_ORDER);

  const [toggleDateRange, setToggleDateRange] = useState(false);

  const [startCalendar, setStartCalendar] = useState<Moment>(moment());
  const [endCalendar, setEndCalendar] = useState<Moment>(moment());

  const handleChangeDateFilter = (day: number) => {
    setSelectedDuration(day);
  };

  const handleClickApplyCalendar = (startDateTime: Moment, endDateTime: Moment) => {
    setStartCalendar(startDateTime);
    setEndCalendar(endDateTime);
    if (selectedDuration === -1) {
      setToggleDateRange(!toggleDateRange);
      // detect when user want to change date filter form calendar more than 1 times continuously
    }

    setSelectedDuration(-1);
  };

  useEffect(() => {
    if (selectedDuration === -1) {
      const start = moment(startCalendar).utc().toISOString();
      const end = moment(endCalendar).utc().toISOString();

      getStat({
        variables: {
          projectId,
          start,
          end,
          label: platformFilter,
          option: {
            timeField: periodOption,
          },
        },
      });

      getTopSellingWithPeriod({
        variables: {
          projectId,
          start,
          end,
          label: platformFilter,
        },
      });

      const startTimeLabel = startCalendar.format(DASHBOARD_DATE_TIME_FORMAT);
      const endTimeLabel = endCalendar.format(DASHBOARD_DATE_TIME_FORMAT);

      setStartTime(startTimeLabel);
      setEndTime(endTimeLabel);
    } else {
      const start = getStatStartTime(selectedDuration);
      const end = getStatEndTime(selectedDuration);

      getStat({
        variables: {
          projectId,
          start,
          end,
          label: platformFilter,
          option: {
            timeField: periodOption,
          },
        },
      });

      getTopSelling({
        variables: {
          projectId,
          label: platformFilter,
          timeSpanInDay: selectedDuration,
        },
      });

      const startTimeLabel =
        selectedDuration === 0
          ? "00:00"
          : moment()
              .subtract(selectedDuration, "days")
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .format(DASHBOARD_DATE_TIME_FORMAT);
      const endTimeLabel =
        selectedDuration === 0
          ? moment().format(TIME_FORMAT)
          : moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format(DASHBOARD_DATE_TIME_FORMAT);

      setStartTime(startTimeLabel);
      setEndTime(endTimeLabel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDuration, projectId, toggleDateRange, platformFilter, periodOption]);

  return {
    dataTopSelling,
    dataTopSellingWithPeriod,
    endTime,
    handleChangeDateFilter,
    handleClickApplyCalendar,
    selectedDuration,
    startTime,
    isLoadingStat: loading && loadingTopSellingWithPeriod && loadingTopSelling,
    isLoadingWidget: loading,
    statData: data,
  };
};

export default useStatData;
