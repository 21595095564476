import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { FULL_MONTH_NAME_WITH_YEAR } from "constants/DateTimeFormat";
import { TRANSACTIONS, FIRST_TRANSACTION } from "graphql/wallet/query";
import {
  TransactionConnectionType,
  TransactionVariablesType,
  TransactionQueryType,
  FirstTransactionQueryType,
} from "types/Wallet";
import { ProjectIdType } from "types/Project";
import { WALLET_TRANSACTION_LIMIT } from "config/wallet";

type UseTransactionDataPropsType = {
  projectId: string;
};

const useTransactionData = ({ projectId }: UseTransactionDataPropsType) => {
  const { t } = useTranslation();
  const [transactionFilterList, setTransactionFilterList] = useState<string[]>([]);
  const [selectedFilterValue, setSelectedFilterValue] = useState<number>();

  const variables = {
    projectId,
    limit: WALLET_TRANSACTION_LIMIT,
    offset: 0,
  };
  const { loading: isLoading, data, fetchMore, refetch } = useQuery<TransactionQueryType, TransactionVariablesType>(
    TRANSACTIONS,
    {
      variables,
    },
  );
  const { data: firstTransactionData } = useQuery<FirstTransactionQueryType, ProjectIdType>(FIRST_TRANSACTION, {
    variables: {
      projectId,
    },
  });

  const handleFetchMore = () => {
    const transactionLength = data ? data.transactions.results.length : 0;
    const newOffset = data ? Math.floor(transactionLength / WALLET_TRANSACTION_LIMIT) : 0;

    fetchMore({
      variables: {
        ...variables,
        offset: newOffset,
      },
      updateQuery: (prev: { transactions: TransactionConnectionType }, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const updatedTransactionResults = [...prev.transactions.results, ...fetchMoreResult.transactions.results];

        return {
          ...prev,
          transactions: {
            ...fetchMoreResult.transactions,
            results: updatedTransactionResults,
          },
        };
      },
    });
  };

  const handleSelectFilter = (selectedValue: number) => {
    setSelectedFilterValue(selectedValue);
  };

  if (firstTransactionData && firstTransactionData.firstCreditTransaction && !transactionFilterList.length) {
    const firstTransactionCreatedAt = firstTransactionData.firstCreditTransaction.createdAt;

    const monthsDiffBetweenFirstTransactionAndLatest = moment().diff(moment(firstTransactionCreatedAt), "months");
    if (monthsDiffBetweenFirstTransactionAndLatest) {
      const filterList = Array.from({ length: monthsDiffBetweenFirstTransactionAndLatest + 1 }, (_, index) => {
        const thisMonth = t("wallet.history.filter.thisMonth");
        const monthName = moment().subtract(index, "months").format(FULL_MONTH_NAME_WITH_YEAR);

        return index ? monthName : thisMonth;
      });

      setTransactionFilterList(filterList);
    }
  }

  useEffect(() => {
    if (selectedFilterValue || selectedFilterValue === 0) {
      const setDateValue = {
        date: 1,
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      };

      const startTime = moment().subtract(selectedFilterValue, "months").set(setDateValue);
      const endTime =
        selectedFilterValue === 0
          ? moment()
          : moment()
              .subtract(selectedFilterValue - 1, "months")
              .set(setDateValue);

      const refetchVariables = {
        ...variables,
        offset: 0,
        start: moment(startTime).utc().toISOString(),
        end: moment(endTime).utc().toISOString(),
      };

      refetch(refetchVariables);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterValue]);

  if (data && data.transactions && firstTransactionData) {
    const { results, total } = data.transactions;
    const isLoadMore = total > results.length;

    return {
      transactions: results,
      isLoading,
      isLoadMore,
      handleFetchMore,
      transactionFilterList,
      handleSelectFilter,
    };
  }

  return {
    transactions: [],
    isLoading,
    isLoadMore: false,
    handleFetchMore,
    transactionFilterList,
    handleSelectFilter,
  };
};

export default useTransactionData;
