import * as yup from "yup";

const shippingSchema = yup.object().shape({
  fixedPrice: yup.number().required("Please fill shipping price"),
  description: yup.string().trim().required("Please specify carrier name"),
  minDuration: yup.number().required(),
  maxDuration: yup.number().required(),
  provinceSettings: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().trim(),
        isActive: yup.boolean(),
        postalCodes: yup
          .array()
          .of(
            yup.object().shape({
              postalCode: yup.string().required("Please select postal code"),
              price: yup.number().required(),
            }),
          )
          .nullable(),
      }),
    )
    .nullable(),
  storeManagementFee: yup.number(),
});

export default shippingSchema;
