const setting = {
  en: {
    "Cancel invitation": "Cancel invitation",
    Pending: "Pending",
    "General setting": "General setting",
    "Bot gender": "Bot Gender",
    Female: "Female",
    Male: "Male",
    "Admin - Bot auto resume chat": "Admin - Bot auto resume chat",
    mins: "mins",
    hours: "hours",
    "Search product order": "Search product order",
    "First created": "First created",
    "Last created": "Last created",
    "Latest update": "Latest update",
    "Bot timer is required": "Bot timer is required",
    "Should be positive number": "Should be positive number",
    "Store members": "Store members",
    "Change to admin": "Change to admin",
    "Change to owner": "Change to owner",
    "Delete member": "Delete member",
    "Add new member": "Add new member",
    Admin: "Admin",
    Owner: "Owner",
    "Generate key": "Generate key",
    Role: "Role",
    "Please select role": "Please select role",
    "Shop Information": "Shop Information",
    "Do you want to delete this member": "Do you want to delete this member?",
    "Member deleted": "Member deleted",
    "Member role changed": "Member role changed",
    "Member invited": "Member invited",
    "OTP Generated": "OTP Generated, Key will be expired in 5 minutes",
    Connect: "Connect",
    "email already exist": "email already exist",
    "Time cannot be 0": "Time cannot be 0",
    "Payment notification time (mins) is required": "Payment notification time (mins) is required",
    "Payment notification time (hours) required": "Payment notification time (hours) required",
    "Reserved product expiration time (hours) is required": "Reserved product expiration time (hours) is required",
    "Reserved product expiration time (mins) is required": "Reserved product expiration time (mins) is required",
    "Bot setting": "Set up response mode",
    "Order setting": "Order setting",
    "Payment notification": "Payment notification",
    "Notification setting": "Notification setting",
    "Fall back Notification":
      "Send a notification to admin when bot is unable to find a proper answer (Fallback message)",
    "Reserved product expiration time": "Reserved product expiration time",
    "Minute cannot be above 59": "Minute cannot be above 59",
    "Reserved product expiration time must be after Payment notification time":
      "Reserved product expiration time must be after Payment notification time",
    "setting.members.cancelInvitedMember.success": "Invited member had removed",
    "setting.members.cancelInvitedMember.error": "Cancel invited member failed!",
    "setting.members.invitedMember.duplicatedInvitation": "This email have been invited",
    "setting.members.invitedMember.duplicatedMember": "This email has been already exists in this project",
    "setting.members.invitedMember.maxQuota": "Your team member reaches maximum quota",
    "setting.members.invitedMember.permission": "Don't have a permission to invite new member",
    "orderSetting.label.orderNumberPrefix": "Order number prefix (2-4 characters)",
    "orderSetting.label.receiptNumberPrefix": "Receipt number prefix (2-4 characters)",
    "orderSetting.error.orderNumberPrefix.required": "Order number prefix is required",
    "orderSetting.error.receiptNumberPrefix.required": "Receipt number prefix is required",
    "orderSetting.error.customPrefix.matches": "Please use English alphabet (2-4 characters)",
    "orderSetting.label.prefixUpdateCondition": "Update once per day",
    "orderSetting.label.prefixUpdateNextDay": "Can update again in next day",
    "ThemeShopConfig.title": "Theme configuration",
    "ThemeShopConfig.Primary.label": "Primary color",
    "ThemeShopConfig.Secondary.label": "Secondary color",
    "botSetting.section.chatFlowSetting.title": "Shopping flow setting",
    "botSetting.section.chatFlowSetting.tooltip.title": "Chat flow",
    "botSetting.section.chatFlowSetting.tooltip.description":
      "Select what kind of shopping experience you would like to create for your customers.",
    "botSetting.section.chatFlowSetting.radio.chatbot.title": "Conversation-based",
    "botSetting.section.chatFlowSetting.radio.webview.title": "Web shopping-based",
    "botSetting.section.chatFlowSetting.radio.chatbot.description":
      "Bot replies customers with ‘quick reply’ as product SKUs of the selected products.",
    "botSetting.section.chatFlowSetting.radio.webview.description":
      "Customers navigate through ‘Product details’ of the selected products via web shopping.",
    "botSetting.admin.contact.title": "Bot shopping mode",
    "botSetting.admin.contact.description": "Customers can continue shopping while the bot is inactive.",
    IS_MULTI_COD_ORDER_ENABLED: "Multiple Cash on delivery order (No deposit)",
    IS_EMAIL_REQUIRED:
      "Require customers to enter their email In the process where the customer fills in the delivery address information",
    SCHEDULE_FORM_RADIO_SUBTITLE: "โหมดขายของอัตโนมัติ",
    SCHEDULE_FORM_OPEN_SUBTITLE: "Admin responds to chat",
    SCHEDULE_FORM_CLOSE_SUBTITLE: "AI automatically responds to chats",
    SCHEDULE_FORM_OPEN: "Admin mode",
    SCHEDULE_FORM_CLOSE: "AI Sales mode",
    SCHEDULE_FORM_TITLE: "Sales mode",
    SCHEDULE_FORM_SUBTITLE: "เพื่อช่วยแอดมินตอบแชทอัตโนมัติลูกค้าทุกคน และจะไม่มีผลกับลูกค้าที่ดูแลโดยแอดมิน",
    SCHEDULE_FORM_CHECKBOX_LABEL: "Enable Admin mode working hours",
    SCHEDULE_FORM_CHECKBOX_HELPER:
      "AI Sales mode will be turned off when admin work. and will opn back when admin finishes work",
    SCHEDULE_FORM_MONDAY_BUTTON: "Mon",
    SCHEDULE_FORM_TUESDAY_BUTTON: "Tue",
    SCHEDULE_FORM_WEDNESDAY_BUTTON: "Wed",
    SCHEDULE_FORM_THURSDAY_BUTTON: "Thu",
    SCHEDULE_FORM_FRIDAY_BUTTON: "Fri",
    SCHEDULE_FORM_SATURDAY_BUTTON: "Sat",
    SCHEDULE_FORM_SUNDAY_BUTTON: "Sun",
    SCHEDULE_FORM_LEARN_MORE: "learn more about",
    SCHEDULE_FORM_HOW_TO_LINK: "working hours.",
    SCHEDULE_FORM_MONDAY: "Monday",
    SCHEDULE_FORM_TUESDAY: "Tuesday",
    SCHEDULE_FORM_WEDNESDAY: "Wednesday",
    SCHEDULE_FORM_THURSDAY: "Thursday",
    SCHEDULE_FORM_FRIDAY: "Friday",
    SCHEDULE_FORM_SATURDAY: "Saturday",
    SCHEDULE_FORM_SUNDAY: "Sunday",
    SCHEDULE_FORM_FROM: "From",
    SCHEDULE_FORM_TO: "To",
    SCHEDULE_FORM_COPY_WORKING_HOURS: "Copy times to all",
    SCHEDULE_FORM_INFORMATION:
      "You can set the date time of day can be divided into periods and beyond the specified date and time It will be the work time, AI Sale. when the admin's work time, AI Sales will be disabled. and will turn on again After the admin has finished work",
    SCHEDULE_LEARN_MORE: "learn more about working hours.",
    BOT_GENDER_HELPER_TEXT: "The bot's gender determines the suffix when the bot talks to customers.",
    ADVANCE_SETTING_TITLE: "Admin - bot auto resume chat",
    BOT_TIMER_ACTIVE_LABEL: "The length of time the bot has stopped working.",
    BOT_TIME_TO_RESUME_LABEL: "The bot will resume in",
    BOT_TIME_TO_RESUME_HELPER_TEXT: "after the customer presses the button. 'Contact admin'",
    "botSetting.admin.contact.tooltip": "Set the bot's behavior while the customer presses it. 'Contact admin'",
  },
  th: {
    "Cancel invitation": "ยกเลิกคำขอเป็นสมาชิก",
    Pending: "รอดำเนินการ",
    "General setting": "ตั้งค่าทั่วไป",
    "Bot gender": "เพศของบอท",
    Female: "เพศหญิง",
    Male: "เพศชาย",
    "Admin - Bot auto resume chat": "กำหนดเวลาที่บอทจะกลับมาทำงาน",
    mins: "นาที",
    hours: "ชั่วโมง",
    "Search product order": "ตั้งค่าการแสดงสินค้า",
    "First created": "วันที่สร้าง เก่า-ใหม่",
    "Last created": "วันที่สร้าง ใหม่-เก่า",
    "Latest update": "แก้ไขล่าสุด",
    "Bot timer is required": "กรุณากรอกเวลาที่บอทจะกลับมาทำงาน",
    "Should be positive number and integer": "กรุณากรอกเวลาเป็นจำนวนบวก และเป็นจำนวนเต็ม",
    "Store members": "สมาชิก",
    "Change to admin": "เปลี่ยนหน้าที่เป็นแอดมิน",
    "Change to owner": "เปลี่ยนหน้าที่เป็นเจ้าของ",
    "Delete member": "ลบสมาชิก",
    "Add new member": "เพิ่มสมาชิก",
    Admin: "แอดมิน",
    Owner: "เจ้าของ",
    "Generate key": "สร้างรหัสยืนยัน",
    Role: "หน้าที่",
    "Please select role": "กรุณาระบุหน้าที่",
    "Shop Information": "ข้อมูลร้าน",
    "Do you want to delete this member": "คุณต้องการลบสมาชิกหรือไม่",
    "Member deleted": "ลบสมาชิกแล้ว",
    "Member role changed": "เปลี่ยนหน้าที่แล้ว",
    "Member invited": "เพิ่มสมาชิกแล้ว",
    "OTP Generated": "สร้างรหัสยืนยันแล้ว รหัสจะหมดอายุใน 5 นาที",
    Connect: "การเชื่อมต่อ",
    "email already exist": "มีอีเมล์นี้แล้ว",
    "Time cannot be 0": "ไม่สามารถตั้งเวลาเป็น 0 ได้",
    "Payment notification time (mins) is required": "กรุณากำหนดระยะเวลาแจ้งเตือนการชำระเงิน (นาที)",
    "Payment notification time (hours) required": "กรุณากำหนดระยะเวลาแจ้งเตือนการชำระเงิน (ชั่วโมง)",
    "Reserved product expiration time (hours) is required": "กรุณากำหนดระยะเวลาคงสถานะรายการสั่งซื้อ (ชั่วโมง)",
    "Reserved product expiration time (mins) is required": "กรุณากำหนดระยะเวลาคงสถานะรายการสั่งซื้อ (นาที)",
    "Bot setting": "ตั้งค่าโหมดการตอบแชท",
    "Order setting": "ตั้งค่ารายการสั่งซื้อ",
    "Payment notification": "ระยะเวลาแจ้งเตือนการชำระเงิน",
    "Notification setting": "ตั้งค่าการแจ้งเตือน",
    "Fall back Notification": "การแจ้งเตือนเมื่อบอทไม่สามารถตอบได้",
    "Reserved product expiration time": "ระยะเวลาคงสถานะรายการสั่งซื้อ",
    "Minute cannot be above 59": "ไม่สามารถกำหนดนาทีเกิน 59 ได้",
    "Reserved product expiration time must be after Payment notification time":
      "ระยะเวลาคงสถานะรายการสั่งซื้อต้องมากกว่าระยะเวลาแจ้งเตือนการชำระเงิน",
    "setting.members.cancelInvitedMember.success": "คำเชิญขอเป็นสมาชิกถูกลบออกแล้ว",
    "setting.members.cancelInvitedMember.error": "การลบคำเชิญขอเป็นสมาชิกล้มเหลว",
    "setting.members.invitedMember.duplicatedInvitation": "อีเมลนี้เคยถูกเชิญเป็นสมาชิกแล้ว",
    "setting.members.invitedMember.duplicatedMember": "อีเมลผู้ใช้มีอยู่ในระบบแล้ว",
    "setting.members.invitedMember.maxQuota": "จำนวนสมาชิกเต็ม",
    "setting.members.invitedMember.permission": "ไม่ได้รับอนุญาตในการส่งคำเชิญขอเป็นสมาชิก",
    "orderSetting.label.orderNumberPrefix": "ตัวอักษรนำหน้าใบสั่งซื้อ (2-4 ตัวอักษร)",
    "orderSetting.label.receiptNumberPrefix": "ตัวอักษรนำหน้าใบเสร็จ (2-4 ตัวอักษร)",
    "orderSetting.error.orderNumberPrefix.required": "กรุณาระบุตัวอักษรนำหน้าใบสั่งซื้อ",
    "orderSetting.error.receiptNumberPrefix.required": "กรุณาระบุตัวอักษรนำหน้าใบเสร็จ",
    "orderSetting.error.customPrefix.matches": "กรุณากรอกเป็นตัวอักษรภาษาอังกฤษ (2-4 ตัวอักษร)",
    "orderSetting.label.prefixUpdateCondition": "แก้ไขได้ 1 ครั้งต่อวัน",
    "orderSetting.label.prefixUpdateNextDay": "สามารถแก้ไขได้อีกครั้งในวันถัดไป",
    "ThemeShopConfig.title": "จัดการธีมร้านค้า",
    "ThemeShopConfig.Primary.label": "สีหลัก",
    "ThemeShopConfig.Secondary.label": "สีรอง",
    "botSetting.section.chatFlowSetting.title": "ตั้งค่ารูปแบบการเลือกซื้อสินค้า",
    "botSetting.section.chatFlowSetting.tooltip.title": "การตอบบทสนทนา",
    "botSetting.section.chatFlowSetting.tooltip.description":
      "เลือกประสบการณ์การเลือกซื้อสินค้าที่คุณต้องการสร้างให้ลูกค้า",
    "botSetting.section.chatFlowSetting.radio.chatbot.title": "รูปแบบบทสนทนา",
    "botSetting.section.chatFlowSetting.radio.webview.title": "รูปแบบเว็บช้อปปิ้ง",
    "botSetting.section.chatFlowSetting.radio.chatbot.description":
      "บอทเสนอตัวเลือกสินค้าด้วย 'quick reply' เมื่อลูกค้าเลือกสินค้า",
    "botSetting.section.chatFlowSetting.radio.webview.description":
      "ลูกค้าถูกนำไปยังหน้า 'รายละเอียดสินค้า' เพื่อเลือกตัวเลือกสินค้า(SKU) บนเว็บช้อปปิ้ง",
    "botSetting.admin.contact.title": "โหมดช้อปปิ้งต่อเนื่อง",
    "botSetting.admin.contact.description": "ลูกค้าสามารถเลือกซื้อสินค้าได้อย่างต่อเนื่อง ในขณะที่บอทหยุดทำงานชั่วคราว",
    IS_MULTI_COD_ORDER_ENABLED: "เปิดรายการสั่งซื้อแบบชำระเงินปลายทางได้หลายรายการ (ไม่มีมัดจำ)",
    IS_EMAIL_REQUIRED: "ต้องการให้ลูกค้ากรอกอีเมล ในขั้นตอนที่ลูกค้ากรอกข้อมูลที่อยู่จัดส่ง",
    SCHEDULE_FORM_RADIO_SUBTITLE: "โหมดขายของอัตโนมัติ",
    SCHEDULE_FORM_OPEN_SUBTITLE: "ตอบแชทด้วยตัวเอง",
    SCHEDULE_FORM_CLOSE_SUBTITLE: "ตอบแชทอัตโนมัติ",
    SCHEDULE_FORM_OPEN: "Admin mode",
    SCHEDULE_FORM_CLOSE: "AI Sales mode",
    SCHEDULE_FORM_TITLE: "โหมดการขายของ",
    SCHEDULE_FORM_SUBTITLE: "เพื่อช่วยแอดมินตอบแชทอัตโนมัติลูกค้าทุกคน และจะไม่มีผลกับลูกค้าที่ดูแลโดยแอดมิน",
    SCHEDULE_FORM_CHECKBOX_LABEL: "เปิดชั่วโมงทำงานของ Admin mode",
    SCHEDULE_FORM_CHECKBOX_HELPER: "AI Sales mode จะถูกปิดเมื่อถึงเวลาที่แอดมินทำงาน และจะเปิดกลับมาเมื่อแอดมินเลิกงาน",
    SCHEDULE_FORM_MONDAY_BUTTON: "จ",
    SCHEDULE_FORM_TUESDAY_BUTTON: "อ",
    SCHEDULE_FORM_WEDNESDAY_BUTTON: "พ",
    SCHEDULE_FORM_THURSDAY_BUTTON: "พฤ",
    SCHEDULE_FORM_FRIDAY_BUTTON: "ศ",
    SCHEDULE_FORM_SATURDAY_BUTTON: "ส",
    SCHEDULE_FORM_SUNDAY_BUTTON: "อา",
    SCHEDULE_FORM_LEARN_MORE: "เรียนรู้เพิ่มเติมเกี่ยวกับ",
    SCHEDULE_FORM_HOW_TO_LINK: "การตั้งวัน เวลาใช้งาน",
    SCHEDULE_FORM_MONDAY: "จันทร์",
    SCHEDULE_FORM_TUESDAY: "อังคาร",
    SCHEDULE_FORM_WEDNESDAY: "พุธ",
    SCHEDULE_FORM_THURSDAY: "พฤหัส",
    SCHEDULE_FORM_FRIDAY: "ศุกร์",
    SCHEDULE_FORM_SATURDAY: "เสาร์",
    SCHEDULE_FORM_SUNDAY: "อาทิตย์",
    SCHEDULE_FORM_FROM: "เริ่ม",
    SCHEDULE_FORM_TO: "ถึง",
    SCHEDULE_FORM_COPY_WORKING_HOURS: "คัดลอกเวลาใช้ทั้งหมด",
    SCHEDULE_FORM_INFORMATION:
      "คุณสามารถกำหนดวัน เวลาของแต่ละวัน แบ่งเป็นช่วงเวลาได้ และนอกเหนือจากวันเวลาที่กำหนด จะเป็นการทำงานของ AI Sales โดยเมื่อถึงเวลาการทำงานของแอดมิน AI Sales จะถูกปิดการทำงาน และจะเปิดทำงานอีกครั้ง หลังเวลาแอดมินเลิกงาน",
    SCHEDULE_LEARN_MORE: "เรียนรู้เพิ่มเติมเกี่ยวกับ การตั้งวัน เวลาใช้งาน",
    BOT_GENDER_HELPER_TEXT: "เพศของบอท เป็นตัวกำหนดคำลงท้ายเมื่อบอทสนทนากับลูกค้า เช่น คะ ค่ะ ครับ เป็นต้น",
    ADVANCE_SETTING_TITLE: "ตั้งค่าขั้นสูง",
    BOT_TIMER_ACTIVE_LABEL: "ระยะเวลาที่บอทหยุดทำงาน",
    BOT_TIME_TO_RESUME_HELPER_TEXT: "หลังจากที่ลูกค้ากดปุ่ม ‘ติดต่อแอดมิน’",
    BOT_TIME_TO_RESUME_LABEL: "บอทจะกลับมาทำงานภายใน",
    "botSetting.admin.contact.tooltip": "ตั้งค่าทำงานของบอทในระหว่างที่ลูกค้ากด ‘ติดต่อแอดมิน’",
  },
};

export default setting;
