import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import clsx from "clsx";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { RICH_MENU } from "constants/i18n";
import Colors from "constants/Colors";
import Typography from "components/Typography";
import Card from "components/Card";
import ExpansionPanel from "components/ExpansionPanel";
import ExpansionPanelDetails from "components/ExpansionPanelDetails";
import ExpansionPanelSummary from "components/ExpansionPanelSummary";
import ExpandMore from "components/Icon/ExpandMore";
import { Action } from "types/RichMenu";
import Dropdown from "./Dropdown";
import { ACTION_SECTIONS, DEFAULT_ACTION } from "../../config";

type ActionCardProps = {
  label: string;
  name: string;
  defaultValue: Action;
  isDisabled?: boolean;
};

type ActionProps = {
  size?: number;
  defaultAction?: Action[];
};

const useStylesIcon = makeStyles({
  expandIcon: {
    order: -1,
    transform: "rotate(270deg)",
  },
  expanded: {
    transform: "rotate(0deg) !important",
  },
});

const useStylesRoot = makeStyles({
  root: {
    backgroundColor: "initial",
  },
});

const ActionCard: FC<ActionCardProps> = ({ label, name, defaultValue, isDisabled }) => {
  const classesIcon = useStylesIcon();
  const classesRoot = useStylesRoot();

  const { errors } = useFormContext();
  const isError = Boolean(get(errors, name));

  return (
    <Card noShadow noBorder bgColor={Colors.PrimaryNude} className="mb-4">
      <ExpansionPanel classes={classesRoot} expanded={isDisabled}>
        <ExpansionPanelSummary
          className={clsx({ "action-error": isError })}
          classes={classesIcon}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
        >
          <Typography variant="title3" color="dark" className="pl-3">
            {label}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="p-3 w-100">
            <Dropdown name={name} defaultValue={defaultValue} isDisabled={isDisabled} />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Card>
  );
};

const Action: FC<ActionProps> = ({ size = 0, defaultAction }) => {
  const { t } = useTranslation(RICH_MENU);

  const dropdowns = [];

  for (let index = 0; index < size; index += 1) {
    const defaultValue = {
      actionType: defaultAction?.[index]?.actionType || DEFAULT_ACTION[0].actionType,
      value: defaultAction?.[index]?.value || DEFAULT_ACTION[0].value,
    };

    dropdowns.push(
      <ActionCard
        key={ACTION_SECTIONS[index]}
        label={ACTION_SECTIONS[index]}
        name={`action[${index}]`}
        defaultValue={defaultValue}
      />,
    );
  }

  return (
    <Card noShadow padding="24px" className="h-100 border-box">
      <Typography variant="title3" className="mb-3">
        {t("ACTION")}
      </Typography>

      {dropdowns.length ? (
        dropdowns
      ) : (
        <ActionCard isDisabled label={ACTION_SECTIONS[0]} name="action[0]" defaultValue={DEFAULT_ACTION[0]} />
      )}
    </Card>
  );
};

export default Action;
