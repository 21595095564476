import * as React from "react";

const IcTelGrey = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.8999 3.60002C2.8999 2.93728 3.43716 2.40002 4.0999 2.40002H6.68335C7.26996 2.40002 7.77058 2.82412 7.86702 3.40275L8.7542 8.72579C8.8408 9.24543 8.57837 9.76079 8.10718 9.99639L6.24941 10.9253C7.58897 14.254 10.2459 16.911 13.5747 18.2505L14.5035 16.3927C14.7391 15.9216 15.2545 15.6591 15.7741 15.7457L21.0972 16.6329C21.6758 16.7293 22.0999 17.23 22.0999 17.8166V20.4C22.0999 21.0628 21.5626 21.6 20.8999 21.6H18.4999C9.88426 21.6 2.8999 14.6157 2.8999 6.00002V3.60002Z"
      fill="#6F7794"
    />
  </svg>
);

export default IcTelGrey;
