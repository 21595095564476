import * as React from "react";

const SvgIcUpload = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.407 15.008c.78 0 1.408.628 1.408 1.407v6.514a2.46 2.46 0 002.455 2.456h15.46a2.46 2.46 0 002.455-2.456v-6.41a1.408 1.408 0 012.815 0v6.41a5.275 5.275 0 01-5.27 5.27H8.27A5.275 5.275 0 013 22.93v-6.514c0-.78.628-1.407 1.407-1.407zM16 4c.37 0 .73.153.993.415l4.478 4.478c.551.551.551 1.44.01 1.981a1.381 1.381 0 01-1.003.424c-.367 0-.727-.15-.992-.414l-2.079-2.079v12.6c0 .78-.627 1.408-1.407 1.408s-1.407-.628-1.407-1.408v-12.6l-2.079 2.079a1.405 1.405 0 11-1.985-1.99v-.001l4.469-4.469A1.385 1.385 0 0116 4z"
    />
  </svg>
);

export default SvgIcUpload;
