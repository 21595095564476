import * as React from "react";

const SvgIcMobilephone = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.735 2h-11.71C8.905 2 8 2.909 8 4.027v23.778c0 1.118.905 2.025 2.025 2.025h11.71a2.026 2.026 0 002.025-2.025V4.027A2.027 2.027 0 0021.735 2zm-8.571 2.019h5.78c.146 0 .265.218.265.488s-.119.488-.265.488h-5.78c-.147 0-.264-.219-.264-.488 0-.27.117-.488.264-.488zm2.925 24.227a1.501 1.501 0 110-3.002 1.501 1.501 0 010 3.002zM22 24H10V7h12v17z"
    />
  </svg>
);

export default SvgIcMobilephone;
