import React, { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { SvgIcon } from "components/Icon";
import { TabButton, Wrapper } from "./styled";

type TabProps = {
  configs: { id: string; title: string; icon?: ReactNode }[];
  value: string;
  onClick: (value: string) => void;
};

const Tab: FC<TabProps> = ({ configs, onClick, value }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {configs.map((config) => (
        <TabButton
          className={value === config.id ? "active" : ""}
          key={config.id}
          onClick={() => {
            onClick(config.id);
          }}
        >
          {config.icon && <SvgIcon className="align-middle" component={config.icon} fontSize="small" />}
          {t(config.title)}
        </TabButton>
      ))}
    </Wrapper>
  );
};

export default Tab;
