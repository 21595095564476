import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { get } from "lodash";

import { INVENTORY_INTEGRATION } from "graphql/integration/query";
import { SYNC_INTEGRATION } from "graphql/integration/mutation";
import { InventoryIntegrationSyncMethodType, IntegrationQuery, InventoryIntegrationPayload } from "types/Integration";

import { notifySuccess, notifyError } from "utils/notify";
import mapErrorMessage from "../mapErrorMessage";

type UseSyncIntegrationProps = {
  projectId: string;
  queryVariables: IntegrationQuery;
  onComplete: () => void;
};

const useSyncIntegration = ({ projectId, queryVariables, onComplete }: UseSyncIntegrationProps) => {
  const { t } = useTranslation();
  const [syncIntegration, { loading }] = useMutation(SYNC_INTEGRATION, {
    refetchQueries: [{ query: INVENTORY_INTEGRATION, variables: queryVariables }],
    onCompleted: () => {
      notifySuccess(t("notify.connectSuccess"));
      onComplete();
    },
    onError: (err) => {
      const gqlErrorMessage: string = get(err, "graphQLErrors.0.message");
      const gqlMeta = get(err, "graphQLErrors.0.extensions.exception.meta", {});
      const mappedErrMessage = mapErrorMessage(gqlErrorMessage);

      notifyError(t(mappedErrMessage, gqlMeta));
    },
  });

  const handleSyncIntegration = (
    syncMethodType: InventoryIntegrationSyncMethodType,
    payload: InventoryIntegrationPayload,
  ) => {
    syncIntegration({
      variables: {
        projectId,
        syncMethodType,
        payload,
      },
    });
  };

  return {
    syncIntegration: handleSyncIntegration,
    isProcessingSyncIntegration: loading,
  };
};

export default useSyncIntegration;
