import React, { Fragment } from "react";
import get from "lodash/get";
import { useTable, TableOptions, useFlexLayout, Cell, Row } from "react-table";

const SubTable = <SUB_TABLE extends object>(props: TableOptions<SUB_TABLE>) => {
  const { columns, data } = props;
  const { getTableProps, headerGroups, rows, prepareRow } = useTable<SUB_TABLE>(
    {
      columns,
      data,
    },
    useFlexLayout,
  );

  return (
    <div className="table sticky subTable" {...getTableProps()}>
      <div className="header">
        {headerGroups.map((headerGroup) => (
          // eslint-disable-next-line react/jsx-key
          <div
            // key={headerGroup.getHeaderGroupProps().key}
            className="tr"
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column) => (
              // eslint-disable-next-line react/jsx-key
              <div
                className="th d-flex align-items-center"
                // key={column.render("Header")}
                {...column.getHeaderProps()}
              >
                {column.render("Header")}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="body">
        {rows.map((row) => {
          prepareRow(row);
          const subRows = get(row, "subRows") || [];

          return (
            <Fragment key={row.getRowProps().key}>
              <div className="tr" {...row.getRowProps()}>
                {row.cells.map((cell: Cell<SUB_TABLE>) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <div
                      className="td d-flex align-items-center"
                      // key={cell.render("Header")}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </div>
                  );
                })}
              </div>
              {subRows.map((subRow: Row<SUB_TABLE>) => {
                prepareRow(subRow);

                return (
                  // eslint-disable-next-line react/jsx-key
                  <div // key={subRow.getRowProps().key}
                    className="tr"
                    {...subRow.getRowProps()}
                  >
                    {subRow.cells.map((cell: Cell<SUB_TABLE>) => {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <div
                          className="td d-flex align-items-center"
                          // key={cell.render("Header")}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default SubTable;
