import React, { ChangeEvent, FC, memo, ReactNode } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { SvgIcon } from "components/Icon";
import { IcUser } from "components/SvgIcons";
import { Z_INDEX } from "constants/ZIndex";
import { COLORS } from "constants/Colors";
import { Tab as TabType } from "types";
import { Theme } from "types/Theme";

interface TabPanelPropsType {
  index?: number;
  value?: number;
}

type TabPropsType = {
  onChange: (event: ChangeEvent<{}>, newValue: number) => void;
  tabConfig: TabType.TabConfig;
  value: number;
  theme: Theme;
  variant?: "standard" | "scrollable" | "fullWidth";
  isSubTab?: boolean;
  renderBadge?: (badgeNo: number) => ReactNode;
};

type TabMenuPropsType = {
  theme: Theme;
  isActive: boolean;
  isSubTab: boolean;
};

type TabHeaderPropsType = {
  theme: Theme;
  isSubTab: boolean;
};

const StyledTabIcon = styled.span`
  position: relative;
  top: 4px;
`;

export const NoticeBadge = styled.span<{ isHidden?: boolean }>`
  display: ${({ isHidden }) => (isHidden ? "none" : "flex")};
  align-items: center;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.COLORS.ChatBadgeWrapper} !important;
  padding: 0 4px;
  color: ${({ theme }) => theme.COLORS.Badge} !important;
`;

const TabHeaderContainer = styled.div`
  position: relative;
  z-index: ${Z_INDEX.TAB};
`;

const TabContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-transform: none;
  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 100vw;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TabMenu = styled(({ isActive, isSubTab, ...rest }) => <Tab {...rest} />).attrs((props: TabMenuPropsType) => {
  const { theme, isActive, isSubTab } = props;
  return { theme, isActive, isSubTab };
})`
  text-transform: none;
  min-width: fit-content;
  span {
    color: ${({ theme, isActive, isSubTab }) => (isActive && !isSubTab ? theme.COLORS.Primary : theme.COLORS.DarkGray)};
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TabHeader = styled(({ isSubTab, ...rest }) => <Tabs {...rest} />).attrs((props: TabHeaderPropsType) => {
  const { theme, isSubTab } = props;
  return { theme, isSubTab };
})`
  background-color: ${({ theme, isSubTab }) => (isSubTab ? theme.COLORS : theme.COLORS.White)};
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.03);
`;

const TabPanel: FC<TabPanelPropsType> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      hidden={value !== index}
      className="d-flex flex-grow-1 flex-column"
      role="tabpanel"
      {...other}
    >
      <div className="d-flex flex-grow-1 flex-column">{children}</div>
    </div>
  );
};

const MUITab = (props: TabPropsType) => {
  const { tabConfig, onChange, value, isSubTab = false, variant = "scrollable", renderBadge, theme } = props;
  const { t } = useTranslation();

  return (
    <TabContainer>
      <TabHeaderContainer>
        <TabHeader
          value={value}
          onChange={onChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: isSubTab ? theme.COLORS.DarkMed : theme.COLORS.Primary,
            },
          }}
          variant={variant || "scrollable"}
          scrollButtons="off"
          aria-label="scrollable auto tabs"
          isSubTab={isSubTab}
        >
          {tabConfig.header.map((element, idx) => {
            return (
              <TabMenu
                isActive={value === idx}
                isSubTab={isSubTab}
                disabled={element.disabled}
                key={element.id}
                label={
                  <div className="d-flex justify-content-between w-100">
                    <div>
                      <StyledTabIcon>
                        {element.svgIcon && <SvgIcon className="mr-2" component={element.svgIcon} fontSize="small" />}
                      </StyledTabIcon>
                      {t(element.title)}
                    </div>
                    <div className="d-flex">
                      {element.badges}
                      {Boolean(element.badgeNo) &&
                        (renderBadge ? (
                          renderBadge(element.badgeNo as number)
                        ) : (
                          <NoticeBadge>
                            <SvgIcon
                              className="m-0 pr-2 pb-1"
                              component={IcUser}
                              fontSize="small"
                              htmlColor={COLORS.Badge}
                              viewBox="0 0 28 28"
                            />
                            {element.badgeNo}
                          </NoticeBadge>
                        ))}
                    </div>
                  </div>
                }
              />
            );
          })}
        </TabHeader>
      </TabHeaderContainer>
      {tabConfig.content && (
        <TabPanel index={value} value={value}>
          {tabConfig.content}
        </TabPanel>
      )}
    </TabContainer>
  );
};

const DPTab = styled(MUITab).attrs((props: TabPropsType) => {
  const { theme } = props;
  return { theme };
})``;

export default memo(DPTab);
