import React from "react";

const SvgIcWarning = (props: React.SVGProps<SVGSVGElement>) => {
  const { color, ...rest } = props;

  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest} color={color || "#FF5472"}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 1.33268C6.10905 1.33268 1.33342 6.10831 1.33342 11.9993C1.33342 17.8904 6.10905 22.666 12.0001 22.666C17.8911 22.666 22.6667 17.8904 22.6667 11.9993C22.6667 6.10831 17.8911 1.33268 12.0001 1.33268ZM13.3539 16.9288C13.3539 17.3113 13.2224 17.634 12.9595 17.8969C12.6965 18.1598 12.3798 18.2913 12.0093 18.2913C11.6268 18.2913 11.3041 18.1598 11.0412 17.8969C10.7783 17.634 10.6468 17.3113 10.6468 16.9288C10.6468 16.5463 10.7783 16.2266 11.0412 15.9696C11.3041 15.7127 11.6268 15.5842 12.0093 15.5842C12.3798 15.5842 12.6965 15.7127 12.9595 15.9696C13.2224 16.2266 13.3539 16.5463 13.3539 16.9288ZM13.0311 5.70738L13.0311 14.4383L10.9694 14.4383L10.9694 5.70738L13.0311 5.70738Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgIcWarning;
