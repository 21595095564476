const accountSettings = {
  en: {
    "Bangkok Bank": "Bangkok Bank",
    "Bank of Ayudhya (Krungsri)": "Bank of Ayudhya (Krungsri)",
    "CIMB Thai Bank": "CIMB Thai Bank",
    "Government Savings Bank": "Government Savings Bank",
    "Kasikorn Bank": "Kasikorn Bank",
    "Krungthai Bank": "Krungthai Bank",
    "Land and Houses Bank": "Land and Houses bank",
    "Siam Commercial Bank": "Siam Commercial Bank",
    "Thanachart Bank": "Thanachart Bank",
    "TMB Bank": "TMB Bank",
    "United Overseas Bank": "United Overseas Bank",
    Citibank: "Citibank",

    // KYC
    ANZ_BANK: "ANZ Bank (Thai) Public Company Limited",
    BAAC: "Bank for Agriculture and Agricultural Cooperatives",
    BANGKOK_BANK: "Bangkok Bank Public Company Limited",
    BANK_OF_AMERICA: "Bank of America National Association",
    BANK_OF_CHINA: "Bank of China ( Thai ) Public Company Limited",
    BANK_OF_THAILAND: "Bank of Thailand",
    BAY: "Bank of Ayudhya Public Company Limited",
    BNP_PARIBAS: "BNP Paribas Bank",
    CIMB: "CIMB Thai Bank Public Company Limited",
    CITIBANK: "Citibank N.A.",
    DEUTSCHE_BANK: "Deutsche Bank Aktiengesellschaft",
    GHB: "Government Housing Bank",
    GSB: "Government Saving Bank",
    HONGKONG_AND_SHANGHAI: "Hong Kong & Shanghai Corporation Limited",
    ICBC: "ICBC Thai Commercial Bank",
    ISLAMIC_BANK: "Islamic Bank of Thailand",
    JP_MORGAN_CHASE: "JP Morgan Chase Bank",
    KBANK: "Kasikornbank Public CompanyLimited",
    KIATNAKIN: "Kiatnakin Bank Public Company Limited",
    KTB: "Krung Thai Bank Public Company Limited",
    LH_BANK: "Land and Houses Bank Public Company Limited",
    MEGA: "Mega International Commercial Bank PCL",
    MIZUHO: "Mizuho Corporate Bank Limited",
    RBS: "The Royal Bank of Scotland N.V.",
    SCB: "Siam Commercial Bank Public Company Limited",
    STANDARD_CHARTERED: "Standard Chartered Bank (Thai) Public Company Limited",
    SUMITOMO_MITSUI: "Sumitomo Mitsui Banking Corporation",
    THAI_CREDIT: "The Thai Credit Retail Bank Public Company Limited",
    TISCO: "Tisco Bank Public Company Limited",
    TTB: "TTB Bank",
    UOB: "United Overseas Bank (Thai) PCL",
  },
  th: {
    "Bangkok Bank": "ธนาคารกรุงเทพ",
    "Bank of Ayudhya (Krungsri)": "ธนาคารกรุงศรี",
    "CIMB Thai Bank": "ธนาคารซีไอเอ็มบีไทย",
    "Government Savings Bank": "ธนาคารออมสิน",
    "Kasikorn Bank": "ธนาคารกสิกรไทย",
    "Krungthai Bank": "ธนาคารกรุงไทย",
    "Land and Houses Bank": "ธนาคาร แลนด์ แอนด์ เฮ้าส์",
    "Siam Commercial Bank": "ธนาคารไทยพาณิชย์",
    "Thanachart Bank": "ธนาคารธนชาต",
    "TMB Bank": "ธนาคารทหารไทย",
    "United Overseas Bank": "ธนาคารยูโอบี",
    Citibank: "ธนาคารซิตี้แบงค์",

    // KYC
    ANZ_BANK: "ธนาคารเอเอ็นแซด (ไทย) จำกัด (มหาชน)",
    BAAC: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
    BANGKOK_BANK: "ธนาคารกรุงเทพจำกัด (มหาชน)",
    BANK_OF_AMERICA: "ธนาคารแห่งอเมริกา เนชั่นแนล แอสโซซิเอชั่น",
    BANK_OF_CHINA: "ธนาคารแห่งประเทศจีน (ไทย) จำกัด (มหาชน)",
    BANK_OF_THAILAND: "ธนาคารแห่งประเทศไทย",
    BAY: "ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)",
    BNP_PARIBAS: "ธนาคารบีเอ็นพี พารีบาส์",
    CIMB: "ธนาคารซีไอเอ็มบีไทย จำกัด (มหาชน)",
    CITIBANK: "ธนาคารซิตี้แบงค์",
    DEUTSCHE_BANK: "ธนาคารดอยซ์แบงก์",
    GHB: "ธนาคารอาคารสงเคราะห์",
    GSB: "ธนาคารออมสิน",
    HONGKONG_AND_SHANGHAI: "ธนาคารฮ่องกงและเซี่ยงไฮ้แบงกิ้งคอร์ปเปอเรชั่น จำกัด",
    ICBC: "ธนาคารไอซีบีซี (ไทย) จำกัด (มหาชน)",
    ISLAMIC_BANK: "ธนาคารอิสลามแห่งประเทศไทย",
    JP_MORGAN_CHASE: "ธนาคารเจพี มอร์แกน เชส",
    KBANK: "ธนาคารกสิกรไทย จำกัด (มหาชน)",
    KIATNAKIN: "ธนาคารเกียรตินาคิน จำกัด (มหาชน)",
    KTB: "ธนาคารกรุงไทย จำกัด (มหาชน)",
    LH_BANK: "ธนาคารแลนด์ แอนด์ เฮ้าส์ จำกัด (มหาชน)",
    MEGA: "ธนาคารเมกะ สากลพาณิชย์ จำกัด (มหาชน)",
    MIZUHO: "ธนาคารมิซูโฮ คอร์ปอเรต",
    RBS: "ธนาคารเดอะรอยัลแบงก์อ๊อฟสกอตแลนด์ เอ็น วี",
    SCB: "ธนาคารไทยพาณิชย์ จำกัด (มหาชน)",
    STANDARD_CHARTERED: "ธนาคารสแตนดาร์ด ชาร์เตอร์ด (ไทย) จำกัด",
    SUMITOMO_MITSUI: " ธนาคาร ซูมิโตโม มิตซุย แบงกิ้ง คอร์ปเปอเรชั่น",
    THAI_CREDIT: "ธนาคารไทยเครดิต เพื่อรายย่อย จำกัด (มหาชน)",
    TISCO: "ธนาคารทิสโก้ จำกัด (มหาชน)",
    TTB: "ธนาคารทหารไทยธนชาต จำกัด (มหาชน)",
    UOB: "ธนาคารยูโอบี จำกัด(มหาชน)",
  },
};

export default accountSettings;
