import React from "react";
import styled from "styled-components";
import ReactSelect, { Props as SelectPropsType, OptionType } from "react-select";
import makeAnimated from "react-select/animated";
import getStyle from "components/TagInputFilter/CustomerSelector/getStyle";
import { CustomStyleSelectType } from "types/ReactSelect";
import ArrowDownIcon from "../ArrowDownIcon";

const StyledReactSelect = styled(ReactSelect)`
  display: flex;
  max-width: 60vw;
  min-width: 60px;
`;

const animatedComponents = makeAnimated();

const DropdownIndicator = () => <ArrowDownIcon />;

const ClearIndicator = () => <div />;

const Selector = <T extends OptionType>(props: SelectPropsType<T> & CustomStyleSelectType) => {
  const { isDisabled, noOutline, styles, components, ...rest } = props;
  const customStyles = styles || getStyle(isDisabled, noOutline);

  const customComponents = noOutline
    ? { DropdownIndicator, ClearIndicator, ...components }
    : { ...animatedComponents, ...components };

  return (
    <StyledReactSelect
      {...rest}
      isDisabled={isDisabled}
      styles={customStyles}
      components={components || customComponents}
    />
  );
};

export default Selector;
