import React, { FC } from "react";
import COLORS from "constants/Colors";
import Grid from "components/Grid";
import Typography from "components/Typography";
import { COLOR_BY_STATUS, ORDER_STATUSES } from "constants/Order";
import { useTranslation } from "react-i18next";
import { HEADER_ORDER_DATE_FORMAT } from "constants/DateTimeFormat";
import moment from "moment";
import styled from "styled-components";
import { OrderCarouselType } from "./FlexOrderCarousel";

type FlexOrderHeaderPropsType = {
  content: OrderCarouselType;
};

type OrderStatusWrapperPropsType = {
  color: string;
};

export const LabelGreyWrapper = styled.div`
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  color: ${COLORS.LightBlue};
  background: ${COLORS.LightGray};
`;

export const OrderStatusWrapper = styled.div<OrderStatusWrapperPropsType>`
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  border-radius: 10px 0px 0px 10px;
  padding: 0px 5px;

  background: ${({ color }) => color || "none"};
  color: ${({ color }) => (color ? COLORS.White : COLORS.LightBlue)};
`;

const FlexOrderHeader: FC<FlexOrderHeaderPropsType> = (props) => {
  const { t } = useTranslation();
  const { content } = props;
  const color = COLOR_BY_STATUS[content.state];

  const totalProducts = content.orderProducts ? content.orderProducts.length : content.products.length;

  return (
    <>
      <Grid item xs={12} className="py-2 pl-2">
        <Typography className="" variant="body4" color={COLORS.DarkMed}>
          {t("Order no.")}
        </Typography>
        <Grid container justify="space-between" alignContent="flex-start" alignItems="center">
          <Grid item>
            <Typography className="" variant="title10" color={COLORS.Dark}>
              {content.orderNumber}
            </Typography>
          </Grid>
          <Grid item>
            <OrderStatusWrapper color={color}>{t(ORDER_STATUSES[content.state])}</OrderStatusWrapper>
          </Grid>
        </Grid>
        <Typography className="" variant="body4" color={COLORS.Dark}>
          {moment(content.createdAt).format(HEADER_ORDER_DATE_FORMAT)}
        </Typography>
      </Grid>

      <Grid item className="" xs={12}>
        <LabelGreyWrapper>
          <Grid container className="px-2" justify="space-between" alignContent="flex-start" alignItems="center">
            <Grid item>
              <Typography className="" variant="body4">
                {t("Review order")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="" variant="body4">
                {`${totalProducts} ${t("item(s)")}`}
              </Typography>
            </Grid>
          </Grid>
        </LabelGreyWrapper>
      </Grid>
    </>
  );
};

export default FlexOrderHeader;
