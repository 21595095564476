import { useQuery } from "@apollo/client";
import get from "lodash/get";

import { UserProfileType } from "types/User";
import { MEMBER_ROLE } from "types/Setting";
import { FLAG_USER_WITH_IS_ENTERPRISE } from "graphql/auth/query";

const useGetUserWithPermission = (projectId: string) => {
  const { data, loading, refetch } = useQuery<UserProfileType>(FLAG_USER_WITH_IS_ENTERPRISE, {
    variables: { projectId },
  });

  const currentUser = data && data.me;
  const permissions = currentUser && currentUser.permissions;
  const currentProject = permissions && permissions.find((permission) => permission.projectId === projectId);
  const isOwnerRole = (currentProject && currentProject.role) === MEMBER_ROLE.OWNER;
  const isAdminRole = (currentProject && currentProject.role) === MEMBER_ROLE.ADMIN;
  const isEmailVerified = get(currentUser, "isEmailVerified", false);
  const hasProjectPermission = Boolean(currentProject);
  const isOwner = isEmailVerified && isOwnerRole && hasProjectPermission;
  const isAdmin = isEmailVerified && hasProjectPermission;
  const isCurrentProjectEnterprise = Boolean(currentProject && currentProject.isEnterprise);
  const isDisabled = Boolean(currentProject && currentProject.isDisabled);
  const isAccepted = Boolean(get(data, "me.isAccepted"));
  const userEmail = currentUser && currentUser.email;

  return {
    currentUser,
    hasProjectPermission,
    isAdmin,
    isAdminRole,
    isCurrentProjectEnterprise,
    isEmailVerified,
    isOwner,
    isOwnerRole,
    loading,
    isDisabled,
    isAccepted,
    refetchGetUserWithPermission: refetch,
  };
};

export default useGetUserWithPermission;
