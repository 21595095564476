import React, { FC, useState, SVGProps } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Card from "components/Card";
import COLORS from "constants/Colors";
import { SvgIcon } from "components/Icon";
import Grid from "components/Grid";
import { PopoverMenu, MenuItem } from "components/Menu";
import PopConfirm from "components/Modal/PopConfirm";
import Typography from "components/Typography";
import Button from "components/Button";
import {
  IcOptions,
  IcDelete,
  IcCreditCardAmex,
  IcCreditCardJcb,
  IcCreditCardMaster,
  IcCreditCardVisa,
  IcRefresh,
} from "components/SvgIcons";
import { CreditCardBrandName, CreditCardBrand } from "types/Billing";
import { ButtonColors } from "types/Button";
import useIsDesktop from "utils/hooks/useIsDesktop";
import isEmpty from "lodash/isEmpty";
import useCreditCard from "../hooks/useCreditCard";

import { MenuButton, CreditCardNumber, ErrorCardText } from "./styled";

export const mockUseParams = useParams;

const mapIcon: Record<CreditCardBrand, FC<SVGProps<SVGSVGElement>>> = {
  AMERICAN_EXPRESS: IcCreditCardAmex,
  JCB: IcCreditCardJcb,
  MASTERCARD: IcCreditCardMaster,
  VISA: IcCreditCardVisa,
};

interface CreditCardListPropsType {
  hasProjectIssue: boolean;
  onClickRepayBilling: () => void;
}

const CreditCardList: FC<CreditCardListPropsType> = ({ hasProjectIssue, onClickRepayBilling }) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const { projectId } = useParams<{ projectId: string }>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpenConfirmRemoveCreditCardModal, setIsOpenConfirmRemoveCreditCardModal] = useState(false);
  const [isOpenConfirmRepayCreditCardModal, setIsOpenConfirmRepayCreditCardModal] = useState(false);
  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickRepay = () => {
    setIsOpenConfirmRepayCreditCardModal(true);
  };

  const { removeCreditCard, creditCard } = useCreditCard(projectId);
  const brand = creditCard?.brand || "VISA";
  const last4digitNumber = creditCard?.number.slice(-4) || "";
  const creditCardIssue = creditCard?.issue || "";
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRemove = () => {
    setIsOpenConfirmRemoveCreditCardModal(true);
    handleCloseMenu();
  };

  const handleConfirmRepay = () => {
    setIsOpenConfirmRepayCreditCardModal(false);
    onClickRepayBilling();
  };

  const handleConfirmRemove = async () => {
    if (!creditCard) {
      return null;
    }
    await removeCreditCard({ variables: { id: creditCard.id, projectId } });
    setIsOpenConfirmRemoveCreditCardModal(false);
    toast.info(t("billing.toast.remove", { brand: CreditCardBrandName[brand], cardNumber: last4digitNumber }), {
      position: toast.POSITION.TOP_CENTER,
      closeButton: false,
      autoClose: 3000,
    });
    return null;
  };

  return (
    <Card className="px-4 py-3" noShadow borderRadius={isDesktop ? 8 : 0}>
      <Grid container data-testid="container-testid">
        <Grid item>
          <Typography variant="title10" className="mb-3" color={COLORS.DarkLight}>
            {t("creditCard")}
          </Typography>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={2}>
            <SvgIcon
              component={mapIcon[brand]}
              fontSize="large"
              htmlColor={COLORS.DarkMed}
              className="p-0"
              style={{ width: 36, height: 28 }}
            />
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body2" color={COLORS.Dark} className="mb-1">
              {CreditCardBrandName[brand]} <CreditCardNumber>{last4digitNumber}</CreditCardNumber>
            </Typography>
            <Typography variant="title10" color={COLORS.DarkLight}>
              {t("billing.creditCardList.primary")}
              {!isEmpty(creditCardIssue) && <ErrorCardText>{t(creditCardIssue)}</ErrorCardText>}
            </Typography>
          </Grid>

          <Grid item xs={1}>
            <MenuButton type="button" onClick={handleClickMenu} data-testid="menu-testid">
              <SvgIcon component={IcOptions} fontSize="small" htmlColor={COLORS.DarkMed} />
            </MenuButton>
          </Grid>
        </Grid>

        {hasProjectIssue && (
          <Grid item xs={12} className="mt-2">
            <Button
              size="small"
              type="button"
              color="secondary"
              onClick={handleClickRepay}
              fullWidth
              data-testid="repay-button-testid"
            >
              <SvgIcon component={IcRefresh} viewBox="-4 -6 30 30" fontSize="small" htmlColor={COLORS.DarkGray} />
              {t("billing.repay.button.title")}
            </Button>
          </Grid>
        )}
      </Grid>
      <PopoverMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        data-testid="menu-popup-testid"
      >
        <MenuItem onClick={handleRemove} className="p-2 pr-5" data-testid="menuItem-remove-creditCard-testid">
          <SvgIcon className="mr-1" component={IcDelete} fontSize="small" />
          <span className="px-2">{t("billing.creditCardList.popup.removeCard")}</span>
        </MenuItem>
      </PopoverMenu>
      <PopConfirm
        isOpen={isOpenConfirmRemoveCreditCardModal}
        description={
          <div>
            {t("billing.creditCardList.confirmModal.removeTitle")}
            <div style={{ fontWeight: "bold" }}>
              {CreditCardBrandName[brand]} {last4digitNumber} ?
            </div>
          </div>
        }
        onClose={() => setIsOpenConfirmRemoveCreditCardModal(false)}
        onSubmit={handleConfirmRemove}
        confirmButtonColor={ButtonColors.ERROR}
        isShowCloseIcon={false}
        data-testid="popConfirm-remove-credit-card-testid"
      />
      <PopConfirm
        isOpen={isOpenConfirmRepayCreditCardModal}
        description={
          <div>
            {t("billing.popConfirm.repay")}
            <div style={{ fontWeight: "bold" }}>
              {CreditCardBrandName[brand]} {last4digitNumber} ?
            </div>
          </div>
        }
        onClose={() => setIsOpenConfirmRepayCreditCardModal(false)}
        onSubmit={handleConfirmRepay}
        isShowCloseIcon={false}
        data-testid="popConfirm-repay-billing-testid"
      />
    </Card>
  );
};

CreditCardList.displayName = "CreditCardList";

export default CreditCardList;
