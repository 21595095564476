import React, { FC } from "react";
import { Rnd } from "react-rnd";
import Modal from "components/Modal";
import { ButtonWithIcon } from "components/Button";
import { SvgIcon } from "components/Icon";
import { IcError } from "components/SvgIcons";
import { Device } from "types/Device";
import useDevice from "utils/hooks/useDevice";

const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "solid 1px #cad2e4",
  background: "#eff3fc",
  padding: "14px",
  borderRadius: "8px",
  zIndex: 10000,
  boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
};

const styleCloseButton = {
  display: "flex",
  top: 15,
  right: 15,
};

type VideoPopupPropsType = {
  videoProps: {
    isShowVideo: boolean;
    videoLink: string;
    width: number;
    height: number;
    x: number;
    y: number;
  };
  setVideoProps: Function;
};

const VideoPopup: FC<VideoPopupPropsType> = (props) => {
  const { videoProps, setVideoProps } = props;

  const device = useDevice();
  const isDesktop = device === Device.DESKTOP;
  return (
    <>
      {videoProps.isShowVideo && isDesktop ? (
        <Rnd
          style={{ ...style, position: "relative" }}
          minWidth={300}
          minHeight={200}
          size={{ width: videoProps.width, height: videoProps.height }}
          position={{ x: videoProps.x, y: videoProps.y }}
          onDragStop={(_e, d) => {
            setVideoProps({ ...videoProps, x: d.x, y: d.y });
          }}
          onResizeStop={(_e, _direction, _ref, delta, position) => {
            setVideoProps({
              ...videoProps,
              width: Number(videoProps.width + delta.width),
              height: Number(videoProps.width + delta.height),
              ...position,
            });
          }}
          lockAspectRatio
          bounds="#root"
        >
          <iframe
            title="tutorial"
            width="100%"
            height="100%"
            src={videoProps.videoLink}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          <ButtonWithIcon
            style={{ ...styleCloseButton, position: "absolute" }}
            onClick={() => {
              setVideoProps({
                ...videoProps,
                isShowVideo: false,
              });
            }}
          >
            <SvgIcon className="ml-1 mt-1" component={IcError} fontSize="small" />
          </ButtonWithIcon>
        </Rnd>
      ) : (
        <Modal
          fullScreen
          isOpen={videoProps.isShowVideo}
          onClose={() =>
            setVideoProps({
              ...videoProps,
              isShowVideo: false,
            })
          }
        >
          <iframe
            title="tutorial"
            width="100%"
            height="100%"
            src={videoProps.videoLink}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Modal>
      )}
    </>
  );
};

export default VideoPopup;
