import React from "react";
import styled from "styled-components";

import Grid from "components/Grid";
import Button from "components/Button";
import DeepleTypography from "components/Typography";

import { VARIANTS } from "constants/Typography";
import { Z_INDEX } from "constants/ZIndex";

export const ExportButton = styled(Button)`
  border-radius: 8px;
  padding-right: 16px;
  padding-left: 16px;
`;

export const TabButton = styled(Button)`
  border-radius: 8px;
  padding-right: 16px;
  padding-left: 16px;
  border: 0px;
`;

export const GridShowingResult = styled(Grid)`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    order: 2;
    padding-bottom: 8px;
  }
`;

export const GridPagination = styled(Grid)`
  justify-content: flex-end;
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex: 1;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    order: 1;
    justify-content: center;
  }
`;

export const GridRowsPerPage = styled(Grid)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex: 0;
    flex-basis: 180px;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    order: 3;
    padding-bottom: 8px;
  }
`;

export const GridLoading = styled.div`
  position: absolute;
  z-index: 10;
  top: calc(50% - 45px);
  left: calc(50% - 45px);
`;

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  font-size: ${VARIANTS.body4.size};
  overflow: auto;
  flex-direction: column;
  padding: 0;
`;

export const Typography = styled(({ fontWeight: _fontWeight, ...rest }) => <DeepleTypography {...rest} />).attrs(
  ({ fontWeight, variant }) => {
    return { fontWeight, variant };
  },
)`
  ${({ variant }) => (variant ? "" : "font-size: inherit;")};
  font-weight: ${({ fontWeight }) => fontWeight || "inherit"};
`;

export const PageTitleWrapper = styled.div`
  flex: 0;
`;

export const Hidden = styled(({ isHidden: _isHidden, ...rest }) => <div {...rest} />).attrs(({ isHidden }) => {
  return { isHidden };
})`
  display: ${({ isHidden }) => (isHidden ? "none" : "block")};
`;

export const DisabledReportWrapper = styled.div`
  z-index: ${Z_INDEX.DISABLED_REPORT_WRAPPER};
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const DisabledReportBox = styled.div`
  background-color: rgb(255 255 255 / 0.75);
  padding: 24px;
  margin-top: 64px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  text-align: center;
`;
