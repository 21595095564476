import React, { FC, useState, memo } from "react";

import { ButtonWithIcon } from "components/Button";
import CollapseButton from "components/CollapseButton";
import Card from "components/Card";
import Grid from "components/Grid";
import Typography from "components/Typography";
import { SvgIcon } from "components/Icon";
import IcDelete from "components/SvgIcons/IcDelete";
import COLORS from "constants/Colors";
import { NewProductSKU } from "types/Product";
import { GridProductImage, GridProductName, ProductImage } from "./styled";
import ProductSKUList from "./productSKUList";

type ProductCardPropsType = {
  id: string;
  productSKUs: NewProductSKU[];
  productImage: string;
  productName: string;
  onDelete: (categoryId?: string, productId?: string, productSKUId?: string) => void;
  isDisabled?: boolean;
  cfCode?: string;
};

const ProductCard: FC<ProductCardPropsType> = (props) => {
  const { productSKUs, productName, productImage, onDelete, id, isDisabled = false, cfCode } = props;

  const [isShowProductSKUList, setIsShowProductSKUList] = useState(false);

  return (
    <Card fullWidth className="mt-3 w-full" noBorder noShadow bgColor={COLORS.LightWhite}>
      <div className="d-flex justify-between w-full flex-nowrap  align-items-center">
        <div className="d-flex w-full flex-nowrap ellipsis align-items-center">
          <GridProductImage item>
            <ProductImage alt={productImage ? productName : "no img"} src={productImage} />
          </GridProductImage>
          <GridProductName item className="d-flex align-items-center w-full">
            <Typography variant="title7" color="dark" className="ellipsis flex-grow-1">
              {productName}
            </Typography>
            <div className="d-flex align-items-center">
              {Boolean(cfCode) && (
                <Typography variant="body3" color="darkGray" className="ellipsis">
                  {cfCode}
                </Typography>
              )}
            </div>
          </GridProductName>
        </div>
        <div className="d-flex w-full">
          {!isDisabled && (
            <ButtonWithIcon
              type="button"
              onClick={() => {
                onDelete(undefined, id);
              }}
            >
              <SvgIcon className="ml-1 mt-1" component={IcDelete} fontSize="small" />
            </ButtonWithIcon>
          )}
          <CollapseButton
            className="ml-3"
            isShow={isShowProductSKUList}
            degree={0}
            onClick={() => setIsShowProductSKUList(!isShowProductSKUList)}
          />
        </div>
      </div>
      {isShowProductSKUList && (
        <Grid container className="pt-2">
          <ProductSKUList
            productName={productName}
            productSKUs={productSKUs}
            productImage={productImage}
            onDelete={onDelete}
            isDisabled={isDisabled}
          />
        </Grid>
      )}
    </Card>
  );
};

export default memo(ProductCard);
