import { useState } from "react";
import { useMutation, ApolloError } from "@apollo/client";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { BillingError } from "types/Billing";
import { CURRENT_USAGE, PACKAGES, HAS_CHANGES_ON_NEXT_CYCLE } from "graphql/plan/query";
import { PAYMENT_SUMMARY } from "graphql/billing/query";
import { PROJECT_INFO } from "graphql/project/query";
import { CHANGE_PACKAGES, CHANGE_PACKAGES_FROM_BASIC } from "graphql/plan/mutation";

type UseChangePackagesType = {
  projectId: string;
  isSelectedBasicPackage: boolean;
  onErrorCallback?: (error: BillingError) => void;
};

const useChangePackages = ({ projectId, isSelectedBasicPackage, onErrorCallback }: UseChangePackagesType) => {
  const { t } = useTranslation();
  const [isRedirect, setIsRedirect] = useState(false);

  const defaultRefetchQueries = [
    { query: CURRENT_USAGE, variables: { projectId } },
    { query: PACKAGES, variables: { projectId } },
    { query: PAYMENT_SUMMARY, variables: { projectId } },
    { query: HAS_CHANGES_ON_NEXT_CYCLE, variables: { projectId } },
  ];
  const gqlMutation = isSelectedBasicPackage ? CHANGE_PACKAGES_FROM_BASIC : CHANGE_PACKAGES;
  const refetchQueries = isSelectedBasicPackage
    ? [...defaultRefetchQueries, { query: PROJECT_INFO, variables: { projectId } }]
    : defaultRefetchQueries;

  const [changePackages, { loading }] = useMutation(gqlMutation, {
    refetchQueries,
    onError: (error: ApolloError) => {
      const gqlErrorMessage = error?.graphQLErrors?.[0]?.message;
      if (onErrorCallback) {
        if (gqlErrorMessage in BillingError) {
          onErrorCallback(BillingError[gqlErrorMessage as keyof typeof BillingError]);
        } else {
          onErrorCallback(BillingError["BILLING:DEFAULT_ERROR"]);
        }
      } else {
        toast.error(t("upgradePlan.error.failed", { error }), {
          position: toast.POSITION.TOP_CENTER,
          closeButton: false,
          autoClose: 3000,
        });
      }
    },
    onCompleted: () => {
      toast.success(t("Update successfully"), {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 1000,
        onClose: () => {},
      });

      setIsRedirect(true);
    },
  });

  const handleChangePackages = async (packageIds: string[]) => {
    return changePackages({
      variables: {
        projectId,
        packageIds,
      },
    });
  };

  return {
    handleChangePackages,
    isRedirect,
    isLoadingChangePackage: loading,
  };
};

export default useChangePackages;
