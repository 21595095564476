import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

import { MARK_REDEEMED_BY_ID } from "graphql/digitalContent/mutation";
import { NOTIFICATION_SUBMIT } from "constants/Notification";

import { notifyError, notifyWarn, notifySuccess } from "utils/notify";
import { ErrorRedeemCode } from "types/DigitalContent";
import mapErrorMessage from "../mapErrorMessage";

type HookMarkCodeAsUsedPropsType = {
  projectId: string;
  onComplete: () => void;
};

const useRedeemByCode = ({ projectId, onComplete }: HookMarkCodeAsUsedPropsType) => {
  const { t } = useTranslation();

  const [redeemCode] = useMutation(MARK_REDEEMED_BY_ID, {
    onError: (err) => {
      const gqlErrorMessage: string = get(err, "graphQLErrors.0.message");
      const errorMessage = mapErrorMessage(gqlErrorMessage);

      if (gqlErrorMessage === ErrorRedeemCode.CODE_NOT_YET_AVAILABLE) {
        notifyWarn(t(errorMessage));
      } else {
        notifyError(t(errorMessage));
      }
    },
    onCompleted: () => {
      notifySuccess(t(NOTIFICATION_SUBMIT.SUCCESS));
      onComplete();
    },
  });

  const handleRedeemCode = useCallback(
    (digitalContentId: string, note: string) => {
      redeemCode({
        variables: {
          projectId,
          digitalContentId,
          note,
        },
      });
    },
    [projectId, redeemCode],
  );

  return {
    handleRedeemCode,
  };
};

export default useRedeemByCode;
