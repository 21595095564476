import React, { FC } from "react";
import styled from "styled-components";
import { BoxProps } from "types/RichMenu";
import { Box, BaseBoxContainer, Badge } from "./styled";

const BoxContainer = styled(BaseBoxContainer)`
  grid-template-columns: auto;
`;

const TwoBoxesOneColumn: FC<BoxProps> = ({ bgColor }) => {
  return (
    <BoxContainer>
      <Box bgColor={bgColor}>
        <Badge>A</Badge>
      </Box>
      <Box bgColor={bgColor}>
        <Badge>B</Badge>
      </Box>
    </BoxContainer>
  );
};

export default TwoBoxesOneColumn;
