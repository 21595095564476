import moment from "moment";
import { DATE_TIME_PICKER_FORMAT } from "constants/DateTimeFormat";

const displayDateTime = (dateTime?: number | string | null, fallbackValue = "-") => {
  if (dateTime == null) return fallbackValue;
  const isValidNumber = moment(Number(dateTime)).isValid();
  const isValid = moment(dateTime).isValid();

  // eslint-disable-next-line no-nested-ternary
  return isValidNumber
    ? moment(Number(dateTime)).format(DATE_TIME_PICKER_FORMAT)
    : isValid
    ? moment(dateTime).format(DATE_TIME_PICKER_FORMAT)
    : fallbackValue;
};

export default displayDateTime;
