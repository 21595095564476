import * as React from "react";

const SvgIcEdit = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.006 13.934l-4.02-4.022 2.925-3 4.095 4.097-3 2.925zm-10.38 10.408l-4.5.422.405-4.5 8.475-8.37 4.05 4.05-8.43 8.398zm15.48-15.418l-4.11-4.11a3 3 0 00-3.99-.105l-13.5 13.5a3.005 3.005 0 00-.855 1.815l-.645 6.253a1.5 1.5 0 001.5 1.635h.135l6.255-.57a2.987 2.987 0 001.815-.853l13.5-13.5a2.88 2.88 0 00-.105-4.065z"
    />
  </svg>
);

export default SvgIcEdit;
