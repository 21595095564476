import React, { FC, useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import Button from "components/Button";
import CircularProgress from "components/CircularProgress";
import Card from "components/Card";
import ErrorText from "components/ErrorText";
import Grid from "components/Grid";
import Typography from "components/Typography";
import TextField from "components/TextField";

import { CUSTOMER_NOTE_DEFAULT_PAGE_SIZE, CUSTOMER_NOTE_ALL_PAGE_SIZE } from "config/customer";
import { CUSTOMER_NOTE } from "graphql/customer/query";
import { CustomerQueryType, CustomerNoteDetailType, CustomerNoteVariablesType } from "types/Customer";
import useGetUserWithPermission from "utils/hooks/useGetUserWithPermission";

import NoteDetails from "./NoteDetail";
import useAddCustomerNote from "./hooks/useAddCustomerNote";
import useDeleteCustomerNote from "./hooks/useDeleteCustomerNote";
import validationSchema from "./validationSchema";
import { TextButton } from "./styled";

type CustomerNotePropsType = {
  projectId: string;
  customerId: string;
  isDesktop?: boolean;
};

type CustomerNoteValidationSchemaType = {
  customerNote: string;
};

const CustomerNote: FC<CustomerNotePropsType> = ({ projectId, customerId, isDesktop = false }) => {
  const { t } = useTranslation();
  const [isShowAll, setIsShowAll] = useState(false);
  const [isDisableSubmit, setIsDisableSubmit] = useState(true);

  const { register, errors, handleSubmit, setValue } = useForm<CustomerNoteValidationSchemaType>({
    resolver: yupResolver(validationSchema),
  });

  const variables = {
    projectId,
    customerId,
    pageSize: isShowAll ? CUSTOMER_NOTE_ALL_PAGE_SIZE : CUSTOMER_NOTE_DEFAULT_PAGE_SIZE,
  };

  const { data: customerData, refetch, loading } = useQuery<CustomerQueryType, CustomerNoteVariablesType>(
    CUSTOMER_NOTE,
    {
      variables,
    },
  );

  const resetCustomerNote = () => {
    setValue("customerNote", "");
    setIsDisableSubmit(true);
  };

  const { currentUser, isOwner } = useGetUserWithPermission(projectId);
  const { addNote } = useAddCustomerNote({
    variables,
    onComplete: resetCustomerNote,
  });
  const { handleDeleteNote } = useDeleteCustomerNote({
    variables,
    projectId,
  });

  const handleSubmitNote = ({ customerNote }: CustomerNoteValidationSchemaType) => {
    addNote({
      variables: {
        projectId,
        customerId,
        note: customerNote,
      },
    });
  };

  const handleChangeNote = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value && event.target.value.length === 0) {
      setIsDisableSubmit(true);
    } else {
      setIsDisableSubmit(false);
    }
  };

  useEffect(() => {
    setIsShowAll(false);
    resetCustomerNote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, customerId]);

  useEffect(() => {
    refetch(variables);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowAll]);

  const totalNotes = (customerData && customerData.customer && customerData.customer.notes.total) || 0;
  const noteList = (customerData && customerData.customer && customerData.customer.notes.results) || [];
  const isShowSeeAll = totalNotes > noteList.length;
  const currentUserEmail = currentUser ? currentUser.email : "";

  return (
    <Card noShadow padding={isDesktop ? "24px" : "16px"}>
      <Grid container>
        <Grid item container justify="space-between" alignItems="center" className="py-2">
          <Grid item>
            <Typography variant="body2" color="dark">
              {t("customerDetail.customerNote.title")}
            </Typography>
          </Grid>
          {isShowSeeAll && (
            <Grid item onClick={() => setIsShowAll(true)}>
              <TextButton variant="body4" color="primary">
                {t("customerDetail.customerNote.seeAll")}
              </TextButton>
            </Grid>
          )}
        </Grid>
        {loading ? (
          <Grid container justify="center">
            <CircularProgress className="m-4" />
          </Grid>
        ) : (
          noteList.map(({ createdAt, note, agent, id }: CustomerNoteDetailType) => (
            <NoteDetails
              key={id}
              note={note}
              createdAt={createdAt}
              agent={agent}
              isOwner={isOwner}
              currentUserEmail={currentUserEmail}
              onDeletedNote={() => handleDeleteNote(id)}
              isDesktop={isDesktop}
            />
          ))
        )}
        <Grid item xs={12} className={isDesktop ? "pt-4" : "pt-3"}>
          <TextField
            className="mb-2"
            variant="outlined"
            placeholder={t("customerDetail.customerNote.placeholder")}
            fullWidth
            multiline
            rows="3"
            name="customerNote"
            validate={register}
            onChange={handleChangeNote}
          />
        </Grid>
        {errors && errors.customerNote && (
          <Grid item xs={12} className="px-1">
            <ErrorText>{t(`${errors.customerNote.message}`)}</ErrorText>
          </Grid>
        )}
        <Grid item container justify="flex-end" className={isDesktop ? "pt-2" : ""}>
          <Grid item xs={4}>
            <Button size="small" fullWidth onClick={handleSubmit(handleSubmitNote)} disabled={isDisableSubmit}>
              {t("customerDetail.customerNote.submit")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CustomerNote;
