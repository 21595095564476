import { useMutation } from "@apollo/client";
import { UPDATE_FAQ } from "graphql/ai-setting/mutation";
import { EditedFaqType } from "types/AISetting";
import { cleanCategoriesMessage } from "utils/message";

const useUpdateFaq = (projectId: string) => {
  const [updateFaq, { loading: isLoadingUpdateFaq }] = useMutation(UPDATE_FAQ);

  const handleUpdateFaq = (faqId: string, faqData: EditedFaqType) => {
    let responses = {};

    if (faqData.responses) {
      responses = {
        ...responses,
        responses: cleanCategoriesMessage(faqData.responses),
      };
    }

    if (faqData.enResponses) {
      responses = {
        ...responses,
        enResponses: cleanCategoriesMessage(faqData.enResponses),
      };
    }
    return updateFaq({
      variables: { projectId, faqId, faqData: { ...faqData, ...responses } },
    });
  };

  return { updateFaq: handleUpdateFaq, isLoadingUpdateFaq };
};

export default useUpdateFaq;
