import * as React from "react";

const SvgIcSearch = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.554 22.523l5.025 5.026a1.436 1.436 0 01-2.03 2.03l-5.026-5.025a1.436 1.436 0 012.03-2.03zM13.487 2c6.344 0 11.487 5.143 11.487 11.487s-5.143 11.487-11.487 11.487S2 19.831 2 13.487 7.143 2 13.487 2zm0 2.872a8.615 8.615 0 100 17.23 8.615 8.615 0 000-17.23z"
    />
  </svg>
);

export default SvgIcSearch;
