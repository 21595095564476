import Button from "components/Button";
import styled from "styled-components";

import { Grid } from "@material-ui/core";
import COLORS from "constants/Colors";

export const ErrorBillingButton = styled(Button)`
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

export const Wrapper = styled(Grid)`
  max-height: 300px !important;
  box-sizing: border-box !important;
`;

export const TransactionWrapper = styled(Wrapper)`
  @media screen and (max-width: 992px) and (min-width: 960px) {
    .MuiGrid-grid-md-8 {
      margin-top: 0 !important;
    }
  }
`;

export const TotalBalanceWrapper = styled(Grid)`
  height: 180px;
  width: 100%;
  background-color: ${COLORS.White};
  border: 0.5px solid ${COLORS.LightBlue};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px 24px;

  @media screen and (max-width: 600px) {
    height: 100%;
    border-radius: 0;

    button {
      width: 100%;
    }
  }

  .price {
    font-family: Graphik;
    font-style: normal;
    font-weight: 500;
    font-size: 32px !important;
    line-height: 40px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 600px) {
      padding: 16px 0;
    }
  }
`;

export const AccountVerification = styled(Grid)`
  width: 100%;
  border-right: 1px solid ${COLORS.LightBlue};
  padding-right: 24px;
  height: 100%;

  @media screen and (max-width: 600px) {
    padding-right: 0;
    border-right: none;
    height: auto;
    border-bottom: 1px solid ${COLORS.LightBlue};
    border-radius: 0;
    padding-bottom: 16px;

    button {
      width: 100%;
    }
  }
`;

export const KYCStatusBox = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    height: 120px;
    width: auto;
    margin-right: 16px;

    @media screen and (max-width: 600px) {
      margin-top: 24px;
    }
  }

  p {
    color: ${COLORS.DarkLight};
    font-family: Graphik;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    /* display: flex;
    align-items: center; */
    margin: 16px 0;
  }

  svg {
    width: 15px;
    height: 15px;
  }

  .verified {
    color: ${COLORS.Success};
  }

  .reject {
    color: ${COLORS.Error};
    margin: 0;

    @media screen and (max-width: 600px) {
      margin-top: 24px;
    }
  }

  .pending {
    color: ${COLORS.Warning};
  }

  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ReasonBox = styled.div`
  height: 35px;
  padding: 0;
  overflow-y: auto;

  @media screen and (max-width: 600px) {
    height: 100%;
    margin-bottom: 24px;
  }
`;

export const BankDetailWrapper = styled(Grid)`
  background-color: ${COLORS.PrimaryNude};
  display: flex;
  align-items: center;
  padding: 28px 24px;
  height: 110px;
  border-radius: 8px;

  img {
    width: 52px;
    height: 52px;
  }

  .detail {
    height: 100%;
    overflow-y: auto;
  }

  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .error {
    display: flex;
    height: 100%;
    overflow-y: auto;
    align-items: center;
  }
`;
