import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { downloadFile } from "utils/common";
import { EXPORT_CUSTOMERS_URL } from "graphql/customer/query/exportCustomers";
import { ExportFileType } from "types/SalesReport";
import { Filter } from "./usePaginationState";

type UseExportCustomersUrlDataType = {
  exportCustomers: string;
};

type useExportCustomersProps = { onCompleted?: () => void; onError?: () => void };

const useExportCustomers = ({ onCompleted, onError }: useExportCustomersProps) => {
  const [exportCustomersUrl, { data, loading }] = useLazyQuery<UseExportCustomersUrlDataType>(EXPORT_CUSTOMERS_URL, {
    onError,
  });

  useEffect(() => {
    const url: string = data?.exportCustomers || "";

    if (url) {
      const fileName = url.split("?")[0].split("/").pop();

      if (fileName) {
        downloadFile(url, fileName).then(() => {
          if (onCompleted) onCompleted();
        });
      }
    }
  }, [data, onCompleted]);

  const exportCustomers = async (
    projectId: string,
    selectedIds: string[],
    filter: Filter,
    exportFileType: ExportFileType,
    createdDateRange: { startDate: string; endDate: string },
  ) => {
    // export all data when no selected any data.
    exportCustomersUrl({
      variables: {
        projectId,
        fileType: exportFileType || ExportFileType.XLSX,
        option: {
          order: {
            column: filter.orderByField,
            direction: filter.orderBy,
          },
          filter: {
            name: filter?.customerNames[0] || "",
            location: filter.customerLatestLocation,
            tags: filter.customerTags,
            customerIds: selectedIds,
            platforms: filter.customerPlatforms,
            startDate: createdDateRange.startDate,
            endDate: createdDateRange.endDate,
          },
        },
      },
    });
  };

  return { exportCustomers, loading };
};

export default useExportCustomers;
