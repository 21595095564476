import produce, { Draft } from "immer";
import get from "lodash/get";

import { ResponseMessage, QuickReplyMessage } from "types/AISetting";
import { CategoryType } from "types/Category";
import { MessageType } from "types/Chat";
import { ProductItemPropsType } from "types/Product";

export const reorder = <T,>(items: T[], startIndex: number, endIndex: number) => {
  return produce(items, (draftItems) => {
    const [removed] = draftItems.splice(startIndex, 1);
    draftItems.splice(endIndex, 0, removed);
  });
};

export const reorderQuickReply = (items: ResponseMessage[]) => {
  return produce(items, (draftItems: ResponseMessage[]) => {
    const quickReplyIndex = draftItems.findIndex((item: ResponseMessage) => item.type === MessageType.QUICK_REPLIES);
    if (quickReplyIndex !== -1) {
      const [quickReply] = draftItems.splice(quickReplyIndex, 1);
      draftItems.splice(draftItems.length, 0, quickReply);
    }
  });
};

export const updateQuickReplyMessage = (newData: QuickReplyMessage[], messages: ResponseMessage[]) => {
  const newDataTrim = newData.map((data) => {
    return { label: data.label.trim(), text: data.text.trim() };
  });
  const newMessages = produce(messages, (draftMessages: Draft<ResponseMessage[]>) => {
    const objIndex = draftMessages.findIndex((message: ResponseMessage) => message.type === MessageType.QUICK_REPLIES);
    if (objIndex !== -1 && draftMessages[objIndex]) {
      // eslint-disable-next-line no-param-reassign
      draftMessages[objIndex].value = newDataTrim;
    }
  });

  return newMessages;
};

export const clearEmptyMessage = (messages: ResponseMessage[]) => {
  return messages.filter((message) => {
    if (typeof message.value === "string") {
      const trimmedMessage = message.value.trim();

      return Boolean(trimmedMessage);
    }
    if (typeof message.value === "object") {
      return true;
    }

    return false;
  });
};

export const cleanCategoriesMessage = (responseMessage: ResponseMessage[]) => {
  return responseMessage.map((message: ResponseMessage) => {
    if (message.type === MessageType.CATEGORY_CAROUSEL) {
      const categories = get(message.value, "categories");
      if (categories) {
        const categoriesIdList: number[] = categories.map((category: CategoryType) => category.id);
        if (categoriesIdList) {
          return { type: message.type, value: categoriesIdList };
        }
      }
    }
    if (message.type === MessageType.PRODUCT_CAROUSEL) {
      const products = get(message.value, "products");
      if (products) {
        const productsIdList: number[] = products.map((product: ProductItemPropsType) => product.id);
        if (productsIdList) {
          return { type: message.type, value: productsIdList };
        }
      }
    }
    return message;
  });
};
