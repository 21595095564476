import * as yup from "yup";

import { CATEGORY_NAME_LENGTH_LIMIT } from "config/category";

const validateSchema = (i18n: Function) =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .max(
        CATEGORY_NAME_LENGTH_LIMIT,
        i18n("form.error.max", { name: i18n("Category Name"), length: CATEGORY_NAME_LENGTH_LIMIT }),
      )
      .required(i18n("form.error.isRequired", { name: i18n("Category Name") })),
  });

export default validateSchema;
