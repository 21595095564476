import React, { FC, useState } from "react";
import { OptionType, ValueType } from "react-select";
import moment from "moment";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import DatePicker from "components/DatePicker";
import Select from "components/Select";

import { DATE_PICKER_DATE_FORMAT } from "constants/DateTimeFormat";

type DateFilterSelectorPropsType = {
  endDate: string;
  onEndDate: (date: string | null) => void;
  onStartDate: (date: string | null) => void;
  startDate: string;
};

const DateFilterSelector: FC<DateFilterSelectorPropsType> = ({ endDate, onEndDate, onStartDate, startDate }) => {
  const { t } = useTranslation();

  // #region state mgt
  const [isShowDateRange, setIsShowDateRange] = useState(false);
  const [selectedSortDateVal, setSelectedSortDateVal] = useState<OptionType>({
    value: "last7days",
    label: t("last7days"),
  });
  // #endregion

  // #region Supported date options
  const dropdownSortDateOptions: OptionType[] = [
    { value: "last7days", label: t("last7days") },
    { value: "last30days", label: t("last30days") },
    { value: "dateRange", label: t("Date range") },
  ];
  // #endregion

  // #region Handle date filter change func
  const handleSortDateChange = (val: ValueType<OptionType>) => {
    setSelectedSortDateVal(val as OptionType);

    if ((val as OptionType).value === "dateRange") {
      setIsShowDateRange(true);
      onStartDate(moment().subtract(30, "days").toISOString());
      onEndDate(moment().toISOString());
    } else if ((val as OptionType).value === "last30days") {
      setIsShowDateRange(false);
      onStartDate(moment().subtract(30, "days").toISOString());
      onEndDate(moment().toISOString());
    } else {
      // Default to 7 days
      setIsShowDateRange(false);
      onStartDate(moment().subtract(7, "days").toISOString());
      onEndDate(moment().toISOString());
    }
  };
  // #endregion

  // #region Handle on set and validate start date and end date
  const onSetAndValidateStartDate = (startDate: string) => {
    if (endDate) {
      const isAfterEndDate = moment(startDate).isAfter(moment(endDate));
      if (isAfterEndDate) {
        onEndDate(startDate);
      }
    }

    onStartDate(startDate);
  };

  const onSetAndValidateEndDate = (endDate: string) => {
    if (startDate) {
      const isAfterEndDate = moment(startDate).isAfter(moment(endDate));
      if (isAfterEndDate) {
        // Update start date to match end date
        onStartDate(endDate);
      }
    }

    onEndDate(endDate);
  };
  // #endregion

  return (
    <Grid container>
      <Grid item xs={12}>
        <Select
          value={selectedSortDateVal}
          options={dropdownSortDateOptions}
          onChange={handleSortDateChange}
          fullWidth
        />
      </Grid>
      {isShowDateRange && (
        <Grid className="order-filter-date-range" item container>
          <Grid item xs={6} className="py-2 pr-1">
            <DatePicker
              selectedDate={startDate}
              setDate={onSetAndValidateStartDate}
              fullWidth
              format={DATE_PICKER_DATE_FORMAT}
            />
          </Grid>
          <Grid item xs={6} className="py-2 pl-1">
            <DatePicker
              selectedDate={endDate}
              setDate={onSetAndValidateEndDate}
              fullWidth
              format={DATE_PICKER_DATE_FORMAT}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default DateFilterSelector;
