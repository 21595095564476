import styled, { css } from "styled-components";
import { types as CardType } from "credit-card-type";

import Grid from "components/Grid";
import Card from "components/Card";

type CreditCardIconsWrapperPropsType = {
  creditCardType: string;
};

export const StyledCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.COLORS.Error};
  background-color: #ff54720d;
  padding: 12px 16px;
`;

export const CreditCardIconsWrapper = styled(Grid)<CreditCardIconsWrapperPropsType>`
  .credit-card-visa-type,
  .credit-card-master-type,
  .credit-card-jcb-type {
    filter: grayscale(1);
  }

  ${(props) => {
    switch (props.creditCardType) {
      case CardType.VISA:
        return css`
          .credit-card-visa-type {
            filter: none;
          }
        `;
      case CardType.MASTERCARD:
        return css`
          .credit-card-master-type {
            filter: none;
          }
        `;
      case CardType.JCB:
        return css`
          .credit-card-jcb-type {
            filter: none;
          }
        `;
      default:
        return css`
          .credit-card-visa-type,
          .credit-card-master-type,
          .credit-card-jcb-type {
            filter: none;
          }
        `;
    }
  }}
`;
