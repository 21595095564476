import React, { FC, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import Card from "components/Card";
import { CategoryType } from "types/Category";
import { reorder } from "utils/message";
import { notifyWarn } from "utils/notify";

import CategoryLists from "./CategoryLists";

type ProductCatalogPreviewPropsType = {
  categories: CategoryType[];
  onChange: (categoryIds: string[]) => void;
};

const ProductCatalogPreview: FC<ProductCatalogPreviewPropsType> = ({ categories, onChange }) => {
  const { t } = useTranslation();

  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnd = (result: DropResult) => {
    setIsDragging(false);
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    // dropped at the same position
    if (result.source.index === result.destination.index) {
      return;
    }

    // check index of all category card
    const indexOfAllCategoryCard = categories.findIndex((category) => {
      const { isHomePageCategory } = category;

      return isHomePageCategory;
    });

    if (indexOfAllCategoryCard === result.source.index && result.destination.index > 9) {
      notifyWarn(t("category.allCategory.priorityError"));
      return;
    }

    const newPriorityCategories = reorder<CategoryType>(categories, result.source.index, result.destination.index);
    const newPriorityCategoryIds = newPriorityCategories.map((newPriorityCategory) => newPriorityCategory.id);
    onChange(newPriorityCategoryIds);
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  return (
    <Card borderRadius={0} noShadow className="py-4">
      <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
        <CategoryLists
          categories={categories}
          listId="dnd-category-lists"
          className={isDragging ? "item-dragging" : ""}
        />
      </DragDropContext>
    </Card>
  );
};

export default ProductCatalogPreview;
