import React, { FC, ChangeEventHandler } from "react";
import { useController, UseControllerOptions } from "react-hook-form";
import { NumberFormatCustomWithoutPrefix } from "components/NumberFormatCustom";
import TextField from "components/TextField";
import { UnitPriceAdornment } from "components/TextField/PriceUnitAdornment";

const TextFieldPrice: FC<
  UseControllerOptions & {
    onChange?: ChangeEventHandler;
    isReadOnly?: boolean;
    hasError?: boolean;
    isPercent?: boolean;
  }
> = (props) => {
  const { onChange, hasError = false, isPercent, ...rest } = props;
  const { field } = useController(props);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    field.onChange(event.target.value);

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <TextField
      inputRef={field.ref}
      onChange={handleChange}
      InputProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: NumberFormatCustomWithoutPrefix as any,
        startAdornment: <UnitPriceAdornment unit={isPercent ? "%" : "฿"} />,
      }}
      error={hasError}
      fullWidth
      variant="outlined"
      {...rest}
    />
  );
};

export default TextFieldPrice;
