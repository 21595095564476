import styled from "styled-components";

import Grid from "components/Grid";

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 80px;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin: 0;
  }
`;

export const GridButtonWrapper = styled(Grid)`
  display: flex;
  flex: 0;
  flex-wrap: nowrap;
`;
