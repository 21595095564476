import styled from "styled-components";

export const MenuButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
`;

export const CreditCardNumber = styled.span`
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
`;

export const ErrorCardText = styled.span`
  padding-left: 6px;
  align-self: flex-end;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.COLORS.Error};
`;
