import styled from "styled-components";
import { VARIANTS } from "constants/Typography";

const TableWrapper = styled.div<{ isBlurContent?: boolean; disableMaxHeight?: boolean }>`
  position: relative;
  width: 100%;
  min-height: 450px;
  max-height: ${({ disableMaxHeight }) => (disableMaxHeight ? "none" : "600px")};
  overflow: ${({ isBlurContent }) => (isBlurContent ? "hidden" : "auto")};
  overflow-x: scroll;
  line-height: 1rem;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    display: initial;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(83, 83, 83, 0.5);
  }
  ::-webkit-scrollbar-corner {
    background-color: ${({ theme }) => theme.COLORS.Light};
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
    width: 25px;
    height: 25px;
    margin: auto;
    transform: rotate(0);
    transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .circle.active {
    transform: rotate(-180deg);
  }

  .table {
    border-top: 1px solid ${({ theme }) => theme.COLORS.LightBlue} !important;
    font-size: ${VARIANTS.body4.size};
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    input[type="checkbox"] {
      width: 24px;
      height: 24px;
    }

    .th {
      padding: 12px 10px 8px;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      background-color: white;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue} !important;
      color: ${({ theme }) => theme.COLORS.Primary};
      font-weight: 500;
      word-break: break-word;
      line-height: 20px;

      svg {
        font-size: 20px;
      }
    }

    .tr {
      .td:first-child,
      .th:first-child {
        padding-left: 24px;
      }

      :last-child {
        .td {
          :last-child {
            background-color: white;
          }
        }
      }

      .circle {
        border: none !important;
      }

      .status {
        text-transform: capitalize;
        font-size: 14px;
      }

      .dot {
        width: 6px;
        height: 6px;
        display: inline-block;
        vertical-align: middle;
        border-radius: 100px;
        margin-right: 8px;

        &.voided {
          background-color: ${({ theme }) => theme.COLORS.Error} !important;
        }
        &.declined {
          background-color: ${({ theme }) => theme.COLORS.DarkMed} !important;
        }
        &.processing {
          background-color: ${({ theme }) => theme.COLORS.Warning} !important;
        }
        &.completed {
          background-color: ${({ theme }) => theme.COLORS.Success} !important;
        }
        &.withdrawn {
          background-color: ${({ theme }) => theme.COLORS.Success} !important;
        }
        &.refuned {
          background-color: ${({ theme }) => theme.COLORS.Accent} !important;
        }
      }
    }
    .tr.expand-row {
      padding-top: 4px;
      background-color: ${({ theme }) => theme.COLORS.PrimaryNude};

      .td {
        border-bottom: none !important;
      }

      * {
        background-color: transparent !important;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    .td {
      padding: 10px;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      background-color: white;
      color: ${({ theme }) => theme.COLORS.DarkGray};
      word-break: break-word;

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .table.subTable {
      background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
      border: none !important;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue} !important;

      * {
        background-color: transparent !important;
      }

      .header,
      .body {
        .tr {
          .th,
          .td {
            // 5 is header row
            max-width: calc(100% / 5);
          }
        }
      }

      .th {
        border: none !important;
        padding: 10px;
        border-color: ${({ theme }) => theme.COLORS.LightBlue};
        background-color: ${({ theme }) => theme.COLORS.LightWhite};
        font-weight: 400;
        color: ${({ theme }) => theme.COLORS.DarkLight};
        font-size: 14px;
        text-transform: capitalize;
        padding-bottom: 0;
      }

      .th {
        :first-child {
          border-bottom: 0;
          border-radius: unset;
          ${({ theme }) => theme.breakpoints.down("lg")} {
            background-color: white;
          }
        }

        :last-child {
          border-bottom: 0;
          border-radius: unset;
          background-color: white;
        }
      }

      .tr {
        :last-child {
          .td {
            :first-child {
              border-radius: unset;
            }

            :last-child {
              border-radius: unset;
            }
          }
        }
      }

      .body .td {
        font-size: 14px;
        color: ${({ theme }) => theme.COLORS.DarkGray};
      }

      .td {
        border: none !important;
        padding: 10px;
        color: ${({ theme }) => theme.COLORS.DarkMed};

        :first-child {
          ${({ theme }) => theme.breakpoints.down("lg")} {
            background-color: white;
          }

          border-bottom: 0;
        }

        :last-child {
          background-color: white;
        }
      }

      .td:first-child,
      .th:first-child {
        padding-left: 24px;
      }

      &.sticky {
        .header {
          position: sticky;
          overflow: unset;
          left: 0;
          z-index: 1;
        }

        .body {
          position: relative;
          z-index: 0;
        }

        [data-sticky-td] {
          position: sticky;
        }
      }
    }
  }
`;

export default TableWrapper;
