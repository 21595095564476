import produce from "immer";
import { NewProductItemProps } from "types/Product";

const filterSelectedProducts = (
  newSelectedProducts: NewProductItemProps[],
  oldSelectedProducts: NewProductItemProps[],
) => {
  return produce(oldSelectedProducts, (draft) => {
    const removedIndex: number[] = [];
    newSelectedProducts.forEach((newSelectedProduct) => {
      const index = oldSelectedProducts.findIndex(
        (selectedSelectedProduct) => selectedSelectedProduct.id === newSelectedProduct.id,
      );

      const filteredNewSelectedProducts = newSelectedProduct?.productSKUs?.filter((productSKU) => {
        return productSKU.isChecked;
      });

      if (filteredNewSelectedProducts.length === 0) {
        removedIndex.push(index);
        return;
      }

      if (index >= 0) {
        draft[index].productSKUs = filteredNewSelectedProducts;
      } else {
        draft.push({ ...newSelectedProduct, productSKUs: filteredNewSelectedProducts });
      }
    });

    removedIndex
      .sort((a, b) => b - a)
      .forEach((index) => {
        if (index >= 0) draft.splice(index, 1);
      });
  });
};

export default filterSelectedProducts;
