import { useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { OptionType, ValueType } from "react-select";

import { BRANCHES } from "graphql/branch/query";
import { BranchesQueryType, BranchType } from "types/Branch";
import { ProjectIdType } from "types/Project";
import { ShippopSenderInformation } from "types/Shipping";

export const DEFAULT_STORE_SENDER_ADDRESS: ShippopSenderInformation = {
  name: "",
  phoneNumber: "",
  address: "",
  subDistrict: "",
  district: "",
  province: "",
  postalCode: "",
  isActive: false,
};

type AddressFillingOption = OptionType & { value: string };

type UseAddressInformationPropsType = {
  projectId: string;
  onChangeAddress: (senderAddress: ShippopSenderInformation) => void;
};

const useAddressInformation = ({ projectId, onChangeAddress }: UseAddressInformationPropsType) => {
  const { t } = useTranslation();

  const { data } = useQuery<BranchesQueryType, ProjectIdType>(BRANCHES, {
    variables: {
      projectId,
    },
  });

  const defaultAddressFillingOptions: AddressFillingOption = {
    label: t("shipping.shippop.label.fillInYourAddress"),
    value: String(0),
  };
  const [selectedAddressFillingOption, setSelectedAddressFillingOption] = useState(defaultAddressFillingOptions);

  const branches = useMemo(() => data?.offlineStores || [], [data]);
  const branchOptions = branches.map((offlineStore: BranchType, index: number) => {
    return {
      label: `${t("Branch")} - ${offlineStore.name}`,
      value: String(index + 1),
    };
  });

  const addressFillingOptions: AddressFillingOption[] = [defaultAddressFillingOptions, ...branchOptions];

  const handleSelectAddressFillingOption = (selectedValue: AddressFillingOption) => {
    if (selectedValue) {
      setSelectedAddressFillingOption(selectedValue);
    }
  };

  let senderAddress = DEFAULT_STORE_SENDER_ADDRESS;

  const selectedAddressFillingOptionValue = Number(selectedAddressFillingOption.value);
  if (selectedAddressFillingOptionValue) {
    const branchIndex = selectedAddressFillingOptionValue - 1;
    const { name, address, subDistrict, district, province, postalCode, phoneNumber } = branches[branchIndex];

    senderAddress = {
      ...senderAddress,
      name,
      phoneNumber,
      address,
      subDistrict,
      district,
      province,
      postalCode,
    };

    onChangeAddress(senderAddress);
  }

  const handleClearSenderAddress = () => {
    onChangeAddress(DEFAULT_STORE_SENDER_ADDRESS);
  };

  return {
    addressFillingOptions,
    selectedAddressFillingOption,
    onSelectAddressFillingOption: (selectedValue: ValueType<AddressFillingOption>) =>
      handleSelectAddressFillingOption(selectedValue as AddressFillingOption),
    senderAddress,
    handleClearSenderAddress,
  };
};

export default useAddressInformation;
