import JQL from "jqljs";
import thailandAddressData from "./thailand_address_data.json";
// TODO : finish this provinceType Later
// type districtType = [string, number[]];
// type amphurType = [string, districtType[]];
// type provinceType = [string, amphurType[]];
// type AddressType = (string | (string | ((string | number[])[] | (string | string[])[])[])[][])[][];
// const districtData : districtType = ["district", [81120]];
// const amphurData : amphurType = ["amphur",[["district",[81120]]]]
// const provinceData : provinceType = [["province",[["amphur",[["district",[81120]]]]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const preprocess = (data: any[]) => {
  if (!data[0].length) {
    // non-compacted database
    return data;
  }
  // compacted database in hierarchical form of:
  // [["province",[["amphur",[["district",["zip"...]]...]]...]]...]
  const expanded: {
    d: string;
    a: string;
    p: string;
    z: string;
  }[] = [];
  data.forEach((provinceEntry) => {
    const province = provinceEntry[0];
    const amphurList = provinceEntry[1];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    amphurList.forEach((amphurEntry: any) => {
      const amphur = amphurEntry[0];
      const districtList = amphurEntry[1];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      districtList.forEach((districtEntry: any) => {
        const district = districtEntry[0];
        const zipCodeList = districtEntry[1];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        zipCodeList.forEach((zipCode: any) => {
          expanded.push({
            d: district,
            a: amphur,
            p: province,
            z: zipCode,
          });
        });
      });
    });
  });
  return expanded;
};

const DB = new JQL(preprocess(thailandAddressData));

export const fieldsEnum = {
  DISTRICT: "d",
  AMPHOE: "a",
  PROVINCE: "p",
  ZIPCODE: "z",
};

export const resolveResultByField = (type: string, searchStr: string, limit: number) => {
  let possibles = [];
  try {
    possibles = DB.select("*").where(type).match(`^${searchStr}`).limit(limit).orderBy(type).fetch();
  } catch (e) {
    return [];
  }
  return possibles;
};
