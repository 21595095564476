import React from "react";
import ReactDOM from "react-dom";
import TagManager, { TagManagerArgs } from "react-gtm-module";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const tagManagerArgs: TagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || "__GTM_ID__",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
