import { useQuery } from "@apollo/client";
import { LIVE_CRAWLER_BY_ID } from "graphql/facebookCrawler/query";

export default (projectId: string, facebookCrawlerFormId: string) => {
  const isFormCreate = facebookCrawlerFormId === "new";

  const { data: liveCrawlerGQLData, loading: isLiveCrawlerLoading } = useQuery(LIVE_CRAWLER_BY_ID, {
    skip: !projectId || isFormCreate,
    variables: {
      projectId,
      liveCrawlerId: facebookCrawlerFormId,
    },
    fetchPolicy: "network-only",
  });

  return { liveCrawlerGQLData, isLiveCrawlerLoading };
};
