import { useQuery } from "@apollo/client";

import { PAYMENT_TYPES } from "constants/Payment";
import { PAYMENTS } from "graphql/payment/query";
import { PaymentType } from "types/Payment";

const usePayment = (projectId: string) => {
  const { data, loading: isLoadingPayments } = useQuery(PAYMENTS, {
    variables: {
      projectId,
    },
  });

  const codPayment = (data?.payments || []).find(({ type }: PaymentType) => type === PAYMENT_TYPES.CASH_ON_DELIVERY);

  return {
    isCODPaymentActive: codPayment?.isActive || false,
    shippingMethodApplyCOD: codPayment?.shippingMethod,
    isLoadingPayments,
  };
};

export default usePayment;
