const forgotPassword = {
  en: {
    "Forgot your password?": "Forgot your password?",
    "We’ll help you reset your password.": "We’ll help you reset your password.",
    "Go back to": "Go back to",
    "Please check your email.": "Please Check your email.",
    "Submit fail!": "Submit fail!",
  },
  th: {
    "Forgot your password?": "ลืมรหัสผ่าน?",
    "We’ll help you reset your password.": "เราจะช่วยคุณตั้งรหัสผ่านใหม่",
    "Go back to": "กลับไปยัง",
    "Please check your email.": "กรุณาตรวจสอบอีเมลของคุณ",
    "Submit fail!": "การส่งข้อมูลล้มเหลว",
  },
};

export default forgotPassword;
