import { gql } from "@apollo/client";

export const UPDATE_ORDER_ADDRESS = gql`
  mutation updateOrderAddress($projectId: ID!, $customerId: ID!, $orderId: ID!, $addressForm: AddressForm) {
    updateOrderAddress(projectId: $projectId, customerId: $customerId, orderId: $orderId, addressForm: $addressForm) {
      id
      orderNumber
      state
      customerId
      address
      firstName
      lastName
      streetAddress
      streetAddress2
      district
      subDistrict
      province
      postalCode
      phoneNumber
      email
      note
      email
      updatedAt
    }
  }
`;
