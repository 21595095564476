import React, { FC, useState } from "react";
import { useQuery, useLazyQuery, useMutation, ApolloError } from "@apollo/client";
import { OptionType, ValueType } from "react-select";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

import { IcCircleRemove } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";
import Button from "components/Button";
import Card from "components/Card";
import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import Modal from "components/Modal";
import PageTitle from "components/PageTitle";
import Select from "components/Select";
import StickyPanel from "components/StickyPanel";
import Typography from "components/Typography";

import { NO_IMG_DEEPLE_LOGO } from "constants/Image";
import COLORS from "constants/Colors";
import SearchProductContainer from "domain/Product/SearchProduct";

import { TOP_SELLING_PRODUCT_FROM_ORDER, TOP_SELLING_CONFIGS } from "graphql/topSelling/query";
import { SAVE_TOP_SELLING_CONFIG } from "graphql/topSelling/mutation";
import { ProductItemPropsType } from "types/Product";
import { ProjectIdType } from "types/Project";
import {
  TopSellingProductsFromOrderQueryType,
  TopSellingProductsFromOrderVariablesType,
  TopSellingProductsConfigQueryType,
  TopSellingProductsConfigType,
} from "types/TopSellingProduct";

import { ProductImage, CircleRemoveIconContainer } from "./styled";
import ProductItem from "./ProductItem";

const SELECTED_TOP_SELLING_LIMIT = 5;

const TopSellingProductContainer: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();

  const TIME_SPAN_OPTIONS: OptionType[] = [
    {
      label: `1 ${t("week", { count: 1 })}`,
      value: "7",
    },
    {
      label: `2 ${t("week", { count: 2 })}`,
      value: "14",
    },
    {
      label: `1 ${t("month", { count: 1 })}`,
      value: "30",
    },
    {
      label: `3 ${t("month", { count: 3 })}`,
      value: "90",
    },
  ];

  const [isShowSaveButton, setIsShowSaveButton] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<ProductItemPropsType[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedTimeSpan, setSelectedTimeSpan] = useState<any>();

  const [getTopSellingFromOrder, { data: topSellingData, loading: isLoadingTopSellingFromOrder }] = useLazyQuery<
    TopSellingProductsFromOrderQueryType,
    TopSellingProductsFromOrderVariablesType
  >(TOP_SELLING_PRODUCT_FROM_ORDER);

  const { data: topSellingConfigData, loading: isLoadingTopSellingConfig } = useQuery<
    TopSellingProductsConfigQueryType,
    ProjectIdType
  >(TOP_SELLING_CONFIGS, {
    variables: {
      projectId,
    },
  });

  const [saveTopSellingProductsConfig] = useMutation(SAVE_TOP_SELLING_CONFIG, {
    onError: (error: ApolloError) => {
      toast.error(`${t("Update failed!")}, ${error}`, {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 3000,
      });
    },
    onCompleted: () => {
      toast.success(t("Update successfully"), {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 1000,
      });

      setIsShowSaveButton(false);
    },
    update(cache, { data: updatedTopSellingConfigs }) {
      const cacheTopSellingConfigs = cache.readQuery<TopSellingProductsConfigQueryType>({
        query: TOP_SELLING_CONFIGS,
        variables: {
          projectId,
        },
      });

      if (cacheTopSellingConfigs) {
        const updatedTopSellingProducts = updatedTopSellingConfigs.topSellingProductConfigs;

        cache.writeQuery({
          query: TOP_SELLING_CONFIGS,
          variables: {
            projectId,
          },
          data: {
            ...updatedTopSellingConfigs,
            topSellingProductConfigs: updatedTopSellingProducts,
          },
        });
      }
    },
  });

  const showSaveButton = () => {
    if (!isShowSaveButton) {
      setIsShowSaveButton(true);
    }
  };

  const handleClickSave = () => {
    const topSellingProductConfigs = selectedProducts.map(({ id }: ProductItemPropsType, sequence: number) => ({
      productId: id,
      sequence,
    }));

    const topSellingProductPeriodInDays = Number(selectedTimeSpan && selectedTimeSpan.value);

    saveTopSellingProductsConfig({
      variables: {
        projectId,
        projectInput: {
          topSellingProductPeriodInDays,
        },
        topSellingProductConfigs,
      },
    });
  };

  const handleClickRemoveProduct = (productId: string) => {
    const filteredSelectedProduct = selectedProducts.filter(
      (selectedProduct: ProductItemPropsType) => selectedProduct.id !== productId,
    );

    setSelectedProducts(filteredSelectedProduct);
    showSaveButton();
  };

  const isDisableAddTopSellingProduct = selectedProducts.length >= SELECTED_TOP_SELLING_LIMIT;

  const handleChangeTimeSpan = (timeSpanOption: ValueType<OptionType>) => {
    if (timeSpanOption) {
      getTopSellingFromOrder({
        variables: {
          projectId,
          timeSpanInDay: Number((timeSpanOption as OptionType).value),
        },
      });
    }

    setSelectedTimeSpan(timeSpanOption);
    showSaveButton();
  };

  if (
    !selectedTimeSpan &&
    topSellingConfigData &&
    topSellingConfigData.project &&
    topSellingConfigData.project.topSellingProductPeriodInDays
  ) {
    const { topSellingProductPeriodInDays } = topSellingConfigData.project;
    const initSelectedTimeSpan = TIME_SPAN_OPTIONS.find(
      (option: OptionType) => Number(option.value) === topSellingProductPeriodInDays,
    );

    getTopSellingFromOrder({
      variables: {
        projectId,
        timeSpanInDay: topSellingProductPeriodInDays,
      },
    });
    setSelectedTimeSpan(initSelectedTimeSpan);
  }

  if (
    !isShowSaveButton &&
    !selectedProducts.length &&
    topSellingConfigData &&
    topSellingConfigData.topSellingProductConfigs &&
    topSellingConfigData.topSellingProductConfigs.length
  ) {
    const selectedTopSellingProductConfigs = topSellingConfigData.topSellingProductConfigs.map(
      ({ product }: TopSellingProductsConfigType) => product,
    );

    setSelectedProducts(selectedTopSellingProductConfigs);
  }

  if (isLoadingTopSellingConfig) {
    return (
      <Modal isOpen onClose={() => {}}>
        <CircularProgress className="m-4" />
      </Modal>
    );
  }

  const topSellingProductsFromOrder = topSellingData && topSellingData.topSellingProductsFromOrder;
  const combineTopSellingProduct = topSellingProductsFromOrder
    ? [...selectedProducts, ...topSellingProductsFromOrder]
    : [...selectedProducts];

  return (
    <>
      <Grid
        container
        item
        xs={12}
        alignContent="flex-start"
        alignItems="flex-start"
        className={isShowSaveButton ? "mb-5" : ""}
      >
        <Grid item>
          <PageTitle title={t("topSellingProduct.header")} />
        </Grid>
        <Grid item xs={12} className="p-3">
          <Card noShadow>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="title7" color="dark">
                  {t("topSellingProduct.add.title")}
                </Typography>
              </Grid>
              <Grid item xs={12} className="pt-3">
                <SearchProductContainer
                  onSelect={(product: ProductItemPropsType) => {
                    setSelectedProducts([...selectedProducts, product]);
                    showSaveButton();
                  }}
                  selectedProducts={combineTopSellingProduct}
                  isDisabled={isDisableAddTopSellingProduct}
                />
              </Grid>
              {Boolean(selectedProducts.length) && (
                <>
                  <Grid item xs={12} className="pt-3">
                    <Typography variant="title7" color="dark">
                      {t("topSellingProduct.config.list.title")}
                    </Typography>
                  </Grid>

                  {selectedProducts.map((selectedProduct: ProductItemPropsType) => {
                    const { id, images, name } = selectedProduct;
                    const productImage = get(images, "0.src") || NO_IMG_DEEPLE_LOGO;

                    return (
                      <Grid key={id} item xs={12} className="mt-3 position-relative">
                        <CircleRemoveIconContainer onClick={() => handleClickRemoveProduct(id)}>
                          <SvgIcon component={IcCircleRemove} fontSize="large" htmlColor={COLORS.DarkMed} />
                        </CircleRemoveIconContainer>
                        <Card noShadow>
                          <Grid container alignItems="center">
                            <ProductImage src={productImage} />
                            <Grid item container xs={9} className="pl-3">
                              <Grid item xs={12} className="ellipsis">
                                {name}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    );
                  })}
                </>
              )}
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} className="px-3 pb-4">
          <Card noShadow>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="title7" color="dark">
                  {t("topSellingProduct.timeSpan.title")}
                </Typography>
              </Grid>
              <Grid item xs={12} className="pt-3">
                <Select
                  value={selectedTimeSpan}
                  options={TIME_SPAN_OPTIONS}
                  isSearchable={false}
                  onChange={handleChangeTimeSpan}
                />
              </Grid>
              <Grid item xs={12} className="pt-3">
                <Typography variant="title7" color="dark">
                  {t("topSellingProduct.fromOrder.list.title")}
                </Typography>
              </Grid>
              {isLoadingTopSellingFromOrder && (
                <Grid container item xs={12} justify="center">
                  <Grid item>
                    <CircularProgress className="m-4" />
                  </Grid>
                </Grid>
              )}
              {topSellingProductsFromOrder &&
                (topSellingProductsFromOrder.length
                  ? topSellingProductsFromOrder.map(({ id, images, name }: ProductItemPropsType) => (
                      <ProductItem key={id} name={name} images={images} />
                    ))
                  : "-")}
            </Grid>
          </Card>
        </Grid>
      </Grid>
      {isShowSaveButton && (
        <StickyPanel>
          <Grid item xs={12} className="m-3">
            <Button fullWidth onClick={handleClickSave}>
              {t("Save")}
            </Button>
          </Grid>
        </StickyPanel>
      )}
    </>
  );
};

export default TopSellingProductContainer;
