import React, { FC, useState, ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import produce from "immer";
import { DropResult } from "react-beautiful-dnd";

import { MAXIMUM_FACEBOOK_CRAW_MESSAGES_TOTAL } from "config";
import ConfirmationModal from "components/ConfirmationModal";
import MessagesManager from "components/MessagesManager";

import { reorder, reorderQuickReply, updateQuickReplyMessage } from "utils/message";

import { MessageType } from "types/Chat";
import { AddCommentCrawlerInput } from "types/FacebookCrawler";

import {
  ResponseMessage,
  MessageTextValue,
  MessageImageValue,
  CategoriesObjectType,
  ProductsObjectType,
} from "types/AISetting";

import Layout from "./Layout";

type MessagePreviewPropsType = {
  projectId: string;
  FacebookCrawlerData: AddCommentCrawlerInput;
  setFacebookCrawlerData: Function;
  disabledForm: boolean;
};

const MessagePreview: FC<MessagePreviewPropsType> = ({
  projectId,
  FacebookCrawlerData,
  setFacebookCrawlerData,
  disabledForm,
}) => {
  const { t } = useTranslation();

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(0);
  const [messages, setMessages] = useState<ResponseMessage[]>(FacebookCrawlerData.responseMessage);

  const handleSetMessage = (newMessage: ResponseMessage[]) => {
    setMessages(newMessage);
    setFacebookCrawlerData({
      ...FacebookCrawlerData,
      responseMessage: newMessage,
    });
  };

  const deleteMessage = (index: number | null) => {
    if (index === null) {
      return;
    }

    const newMessage = messages.filter((_message, indexMessage) => indexMessage !== index);

    handleSetMessage(newMessage);
    setIsOpenConfirmModal(false);
    setSelectedIndex(null);
  };

  const addNewMessages = (
    type: MessageType,
    value: MessageTextValue | MessageImageValue | number[] | CategoriesObjectType | ProductsObjectType,
  ) => {
    const newWelcomeMessages: ResponseMessage[] = [...messages, { type, value }];
    const newOrderQuickReply = reorderQuickReply(newWelcomeMessages);
    handleSetMessage(newOrderQuickReply);
  };

  const handleClickAddMessage = (text: string) => {
    addNewMessages(MessageType.TEXT, text || "");
  };

  const handleDeleteMessage = (index: number) => {
    setIsOpenConfirmModal(true);
    setSelectedIndex(index);
  };

  const handleDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newMessages = reorder<ResponseMessage>(messages, result.source.index, result.destination.index);
    const newOrderQuickReply = reorderQuickReply(newMessages);
    handleSetMessage(newOrderQuickReply);
  };

  const handleUploadCallback = (url: string) => {
    addNewMessages(MessageType.IMAGE, { url });
  };

  const handleCloseConfirmModal = () => {
    setIsOpenConfirmModal(false);
  };

  const handleEditMessage = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;

    const newMessages = produce(messages, (draftMessages) => {
      // eslint-disable-next-line no-param-reassign
      draftMessages[Number(id)].value = value;
    });

    handleSetMessage(newMessages);
  };

  useEffect(() => {
    setMessages(FacebookCrawlerData.responseMessage);
  }, [FacebookCrawlerData]);

  return (
    <>
      <Layout currentNumber={messages.length - 1} limit={MAXIMUM_FACEBOOK_CRAW_MESSAGES_TOTAL}>
        <MessagesManager
          disabled={disabledForm}
          onClickAddMessage={handleClickAddMessage}
          onDeleteMessage={handleDeleteMessage}
          onDragEnd={handleDragEnd}
          onEditMessage={handleEditMessage}
          onUploadCallback={handleUploadCallback}
          messages={messages}
          projectId={projectId}
          maxMessagesLength={MAXIMUM_FACEBOOK_CRAW_MESSAGES_TOTAL}
          addNewMessages={addNewMessages}
          enableQuickReply
          updateQuickReplyMessage={(newData) => {
            handleSetMessage(updateQuickReplyMessage(newData, messages));
          }}
        />
      </Layout>
      <ConfirmationModal
        title={`${t("Are you sure you want to remove message no.")} ${(selectedIndex || 0) + 1} ?`}
        isOpen={isOpenConfirmModal}
        onClose={handleCloseConfirmModal}
        onSubmit={() => {
          deleteMessage(selectedIndex);
        }}
      />
    </>
  );
};

export default MessagePreview;
