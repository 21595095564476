import { gql } from "@apollo/client";

export const ALL_CATEGORY = gql`
  query allCategory($projectId: ID!) {
    categories(projectId: $projectId) {
      results {
        id
        projectId
        name
        image
        isActive
        properties {
          name
        }
        variations {
          name
        }
        isHomePageCategory
        updatedAt
      }
      total
    }
  }
`;

export const ALL_CATEGORY_WITH_HIGHLIGHT_PRODUCTS = gql`
  query allCategory($projectId: ID!) {
    categories(projectId: $projectId) {
      results {
        id
        projectId
        name
        image
        isActive
        properties {
          name
        }
        variations {
          name
        }
        highlightProducts {
          id
          name
          images {
            src
          }
          isCustomSKU
          defaultPrice
          productSKUs {
            price
            variableTypes {
              key
              value
            }
            inventory
            reserved
            digitalContentOptions {
              outputType
              settings {
                availability {
                  type
                  settings {
                    unit
                    value
                    availableAt
                    expiredAt
                  }
                }
                content
              }
            }
            digitalProductInventories {
              status
              inventory
            }
          }
        }
        isHomePageCategory
        updatedAt
      }
      total
    }
  }
`;

export const CATEGORY_WITH_HIGHLIGHT_PRODUCTS = gql`
  query categoryWithHighlightProducts($projectId: ID!, $id: ID!) {
    category(projectId: $projectId, id: $id) {
      id
      name
      image
      totalProductSKU
      highlightProducts {
        id
        name
        images {
          src
        }
        isCustomSKU
        defaultPrice
        productSKUs {
          price
          variableTypes {
            key
            value
          }
          inventory
          reserved
          digitalContentOptions {
            outputType
            settings {
              availability {
                type
                settings {
                  unit
                  value
                  availableAt
                  expiredAt
                }
              }
              content
            }
          }
          digitalProductInventories {
            status
            inventory
          }
        }
      }
    }
  }
`;
