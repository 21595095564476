import { useQuery } from "@apollo/client";
import get from "lodash/get";
import { OrderReportQueryType } from "types/Report";
import { ReportQueryVariableType } from "types/SalesReport";
import { ORDER_REPORTS } from "graphql/salesReport/query";
import { SortByType } from "./usePaginationState";

type UseQueryOrderReportsType = {
  projectId: string;
  isSkip?: boolean;
  page: number;
  rowsPerPage: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter: Record<string, any>;
  sortBy: SortByType;
};

const useQueryOrderReports = ({ projectId, isSkip, filter, sortBy, page, rowsPerPage }: UseQueryOrderReportsType) => {
  const { data, loading, refetch } = useQuery<OrderReportQueryType, ReportQueryVariableType>(ORDER_REPORTS, {
    variables: {
      projectId,
      pageSize: rowsPerPage,
      page: page - 1,
      filter: { ...sortBy, ...filter },
    },
    skip: !projectId || isSkip,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const orderReports = get(data, "orderReports.results") || [];
  const total: number = get(data, "orderReports.total") || 0;

  return {
    loading,
    orderReports,
    refetch,
    total,
  };
};

export default useQueryOrderReports;
