import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Card from "components/Card";
import Typography from "components/Typography";
import Grid from "components/Grid";
import Button, { ButtonWithIcon } from "components/Button";
import Switch from "components/Switch";
import TextField from "components/TextField";

import { IcEdit, IcStoreSettings } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";

import { CARRIER_ICONS } from "constants/Image";
import COLORS from "constants/Colors";
import { Shipping, Device } from "types";
import { CARRIERS } from "types/Shipping";
import useDevice from "utils/hooks/useDevice";

import { ThaiBahtAdornment, CODBadge } from "../style";
import { ErrorMessageLink } from "../../ErrorMessageLink";
import PostalCodeBasePriceInfo from "./PostalCodeBasePriceInfo";
import ShippopPriceInfo from "./ShippopPriceInfo";

type ShippingMethodCardPropsType = {
  calculateType: Shipping.CALCULATE;
  disabled: boolean;
  disabledDuration: boolean;
  errorFields?: string[];
  fixedPrice: number;
  handleSelectMethod: (value: string) => void;
  isDisabledSchedulePickUp: boolean;
  isAllowedOnlyCOD: boolean;
  locationSettings?: Shipping.ProvinceSetting[];
  maxDuration: number;
  minDuration: number;
  onToggleEnableShippingMethod: (value: boolean) => void;
  onToggleSchedulePickUp: (value: boolean) => void;
  openEditModal: (value: boolean) => void;
  projectId: string;
  shippingData: Shipping.ShippingMethod[];
  shippingMethod: string;
  shippingMethodType: Shipping.METHOD_TYPE;
  shippingName: string;
  shippopData: Shipping.ShippopStoreSetting;
  shippopSetting?: Shipping.ShippopSetting;
};

export const ShippingMethodCard: React.FC<ShippingMethodCardPropsType> = (props) => {
  const { t } = useTranslation();
  const device = useDevice();
  const isMobile = device === Device.Device.MOBILE;

  const {
    calculateType,
    disabled,
    disabledDuration,
    isAllowedOnlyCOD,
    fixedPrice,
    maxDuration,
    minDuration,
    shippingData,
    shippingMethod,
    shippingMethodType,
    shippingName,
    handleSelectMethod,
    openEditModal,
    onToggleEnableShippingMethod,
    onToggleSchedulePickUp,
    errorFields,
    projectId,
    locationSettings,
    shippopData,
    shippopSetting,
    isDisabledSchedulePickUp,
  } = props;

  const carrierNameForLogo = shippingName && shippingName.includes(CARRIERS.KERRY) ? CARRIERS.KERRY : shippingName;
  const carrierIconUrl = CARRIER_ICONS[carrierNameForLogo] || CARRIER_ICONS.DEFAULT_CARRIER;

  const handleClickOpenEditModal = () => {
    handleSelectMethod(shippingMethod);
    openEditModal(true);
  };

  const disabledShippingList = shippingData.filter(
    (shippingItem) => shippingItem.method !== shippingMethod && shippingItem.disabled !== false,
  );
  const isAllShippingDisabled = shippingData.every(
    (shippingItem) => shippingItem.disabled || shippingItem.disabled === null,
  );
  const disableUserToTurnOffSwitch = disabledShippingList.length === 2 && !isAllShippingDisabled;
  const isShippingMethodActive = disabled === false;

  const isShippingMethodPickup = shippingMethodType === Shipping.METHOD_TYPE.PICK_UP;

  const EstimatedDeliveryTime = () => {
    return (
      <>
        {!disabledDuration && (
          <Grid container item alignItems="center" xs={isMobile ? 12 : 8}>
            <Grid item>
              <Typography variant="title7" className="m-2">
                {t("Estimated delivery")} :
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body2" color={COLORS.DarkMed}>
                {maxDuration > minDuration && `${minDuration} - `} {`${maxDuration} ${t("days")}`}
              </Typography>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const handleToggleSchedulePickUp = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (isShippingMethodActive) {
      onToggleSchedulePickUp(checked);
    }
  };

  const handleToggleEnableShippingMethod = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onToggleEnableShippingMethod(!checked);
  };

  return (
    <Card
      borderRadius={isMobile ? 0 : 8}
      className={isMobile ? "my-4" : "m-4"}
      data-cy={`shipping-method-${shippingMethod ? shippingMethod.toLowerCase() : "pickUp"}`}
    >
      <Grid
        container
        item
        className="my-2 pt-1"
        alignItems={isMobile || isShippingMethodPickup ? "center" : "flex-start"}
        justify="space-between"
        xs={12}
      >
        <Grid item container xs={8} sm={11} alignItems={isShippingMethodPickup ? "center" : "flex-start"}>
          <Grid item className={isMobile ? "ml-2" : "mx-4"}>
            <Switch
              className={!isMobile && !isShippingMethodPickup ? "mt-3" : ""}
              checked={isShippingMethodActive}
              disabled={disableUserToTurnOffSwitch}
              onChange={handleToggleEnableShippingMethod}
            />
          </Grid>
          {!isMobile && (
            <Grid item container xs={isShippingMethodPickup ? 8 : 10}>
              <Grid container item alignItems="center">
                {shippingName && carrierIconUrl && (
                  <img height="40" width="40" className="ml-2" src={carrierIconUrl} alt={carrierIconUrl} />
                )}
                <Typography variant="title2" className="mx-2">
                  {t(isShippingMethodPickup ? "shipping.label.pickUpAtShop" : shippingName)}
                </Typography>
                {!isShippingMethodPickup && (
                  <Typography variant="body2" className="mx-4" color={COLORS.DarkMed}>
                    {t(shippingMethod)}
                  </Typography>
                )}
                {isAllowedOnlyCOD && <CODBadge />}

                <EstimatedDeliveryTime />
              </Grid>
            </Grid>
          )}
        </Grid>
        {/* EDIT ICON BUTTON */}
        {!isShippingMethodPickup && (
          <Grid item className="ml-2">
            <ButtonWithIcon className="ml-1" onClick={handleClickOpenEditModal}>
              <SvgIcon className="ml-1 mt-1" component={IcEdit} fontSize="small" htmlColor={COLORS.DarkLight} />
            </ButtonWithIcon>
          </Grid>
        )}
      </Grid>

      {isMobile && (
        <>
          {!isShippingMethodPickup && (
            <Grid item>
              <Typography variant="body2" className="m-2 mt-4" color={COLORS.DarkMed}>
                {t(shippingMethod)}
              </Typography>
            </Grid>
          )}
          <Grid container item alignItems="center" xs={12}>
            {shippingName && carrierIconUrl && (
              <img height="40" width="40" className="ml-2" src={carrierIconUrl} alt={carrierIconUrl} />
            )}
            <Typography variant="title2" className="m-2">
              {t(isShippingMethodPickup ? "shipping.label.pickUpAtShop" : shippingName)}
            </Typography>
            {isAllowedOnlyCOD && <CODBadge />}
          </Grid>
          <EstimatedDeliveryTime />
        </>
      )}

      <Card noShadow className={`mt-4 ${isMobile ? "" : "px-4"}`}>
        <Grid container item className="p-2" alignItems="flex-start" justify="space-between" xs={12}>
          <Grid container item xs={12} sm={6}>
            <Grid item xs={12} className="pb-2">
              <Typography variant="title7" color="dark">
                {t(
                  isShippingMethodPickup
                    ? "shipping.label.calculateType.PICK_UP"
                    : `shipping.label.calculateType.${calculateType}`,
                )}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body2">
                {t(
                  isShippingMethodPickup
                    ? "shipping.description.calculateType.PICK_UP"
                    : `shipping.description.calculateType.${calculateType}`,
                )}
              </Typography>
            </Grid>
          </Grid>

          {isShippingMethodPickup && (
            <Grid item className={isMobile ? "pt-3 w-100" : ""}>
              <Link to={`/project/${projectId}/store-settings/offline-store`}>
                <Button color="secondary" fullWidth borderRadius="8px" className="px-2">
                  <SvgIcon component={IcStoreSettings} fontSize="small" />
                  <span className="ml-1">{t("Manage available stores")}</span>
                </Button>
              </Link>
            </Grid>
          )}
          {!isShippingMethodPickup && calculateType === Shipping.CALCULATE.FIXED_PRICE && (
            <Grid item container xs={12} sm={6} className={isMobile ? "pt-3" : "pl-3"}>
              <Grid item xs={12}>
                <Typography variant="title8">{t("Rate amount")}</Typography>
              </Grid>
              <Grid item xs={12} className="my-2">
                <TextField
                  value={fixedPrice}
                  disabled
                  normalColor
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: <ThaiBahtAdornment />,
                  }}
                />
              </Grid>
            </Grid>
          )}
          {!isShippingMethodPickup && calculateType === Shipping.CALCULATE.SHIPPOP && shippopSetting && (
            <Grid item container xs={12} sm={6} className={isMobile ? "pt-3" : "pl-3"}>
              <ShippopPriceInfo
                shippopData={shippopData}
                shippopCalculateType={shippopSetting.type}
                storeManagementFee={shippopSetting.managementFee}
              />
            </Grid>
          )}
          {!isShippingMethodPickup && calculateType === Shipping.CALCULATE.POSTAL_CODE_BASED_PRICE && (
            <PostalCodeBasePriceInfo fixedPrice={fixedPrice} locationSettings={locationSettings} />
          )}

          {errorFields &&
            calculateType === Shipping.CALCULATE.LALAMOVE &&
            errorFields.map((field: string) => {
              return (
                <Grid item container alignContent="center" xs={12} className="pt-2" key={field}>
                  <ErrorMessageLink projectId={projectId} field={field} />
                </Grid>
              );
            })}
        </Grid>

        {isShippingMethodPickup && (
          <Card className="mt-3" noShadow noBorder bgColor={COLORS.LightGray}>
            <Grid container className={isMobile ? "p-1" : "p-2 flex-nowrap"} alignItems="center">
              <Grid item xs={isMobile ? 12 : 6} className="d-flex flex-grow-0">
                <Typography variant="body2" color="dark">
                  {t("shipping.label.isDisabledSchedulePickUp")}
                </Typography>
              </Grid>

              <Grid
                container
                item
                alignItems="center"
                justify={isMobile ? "flex-start" : "flex-end"}
                className={`d-flex ${isMobile ? "flex-grow-0 pt-2" : "flex-grow-1"}`}
              >
                <Switch
                  className="mr-3 d-inline-flex"
                  checked={isDisabledSchedulePickUp}
                  disabled={!isShippingMethodActive}
                  onChange={handleToggleSchedulePickUp}
                />
                <Typography className="mr-3 d-inline-flex" variant="body3" color={COLORS.DarkMed}>
                  {t("Active")}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        )}
      </Card>
    </Card>
  );
};
