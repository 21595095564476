import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { SvgIcon } from "components/Icon";
import Typography from "components/Typography";
import CircleRemoveIcon from "components/CircleRemoveIcon";
import { IcAddphoto } from "components/SvgIcons";
import Dropzone from "components/DropzoneUpload";

import { COLORS } from "constants/Colors";
import { NO_IMG_AVAILABLE } from "constants/Image";
import { ProjectIdType } from "types/Project";
import { ImageUrl } from "types/Image";

import { AddImageBox, ImageContainer, CategoryImage } from "./styled";

type CategoryImageContainerPropsType = {
  image?: string;
  onChange: (imageUrl: string) => void;
  canEditable?: boolean;
  setIsRemoveAllCategoryImage?: () => void;
  isHomePageCategory?: boolean;
};

const CategoryImageContainer: FC<CategoryImageContainerPropsType> = (props) => {
  const {
    image,
    onChange,
    canEditable = false,
    isHomePageCategory = false,
    setIsRemoveAllCategoryImage = () => {},
  } = props;
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();

  const handleRemoveImage = () => {
    if (isHomePageCategory) {
      setIsRemoveAllCategoryImage();
    }
    onChange("");
  };

  const handleChange = (imageUrl: ImageUrl[]) => {
    if (imageUrl.length) {
      const [{ publicUrl }] = imageUrl;
      onChange(publicUrl);
    }
  };

  return image || !canEditable ? (
    <ImageContainer>
      {canEditable && <CircleRemoveIcon onClick={handleRemoveImage} />}
      <CategoryImage alt="category-image" src={image || NO_IMG_AVAILABLE} />
    </ImageContainer>
  ) : (
    <Dropzone projectId={projectId} onChange={handleChange}>
      <AddImageBox>
        <SvgIcon className="ml-2" component={IcAddphoto} fontSize="large" htmlColor={COLORS.DarkMed} />
        <Typography className="mt-2" variant="body4" color="gray">
          <div>+ {t("Add Image")}</div>
        </Typography>
      </AddImageBox>
    </Dropzone>
  );
};

export default CategoryImageContainer;
