import React, { FC, HTMLAttributes } from "react";
import { NO_IMG_AVAILABLE } from "constants/Image";

type ProductImageProps = {
  label: string;
  url?: string;
};

const ProductImage: FC<ProductImageProps & HTMLAttributes<HTMLImageElement>> = ({ url, label, className }) => (
  <img
    className={className}
    height="40"
    width="40"
    src={url || NO_IMG_AVAILABLE}
    alt={label}
    style={{ borderRadius: 8 }}
  />
);

export default ProductImage;
