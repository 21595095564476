import React, { FC, MouseEvent } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import Typography from "components/Typography";
import Button from "components/Button";
import Modal from "components/Modal";

type ConfirmModalPropsType = {
  isOpen: boolean;
  isLoading: boolean;
  title: string;
  action?: string;
  description: string;
  onClose: (event: MouseEvent) => void;
  closeModal: () => void;
  handleConfirm: Function;
};

const ConfirmModal: FC<ConfirmModalPropsType> = (props) => {
  const { isOpen, onClose, isLoading, title, description, handleConfirm, action, closeModal } = props;
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {isLoading && <LinearProgress />}
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="title7" className="m-4">
            {t(title)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className="px-4 pb-2">
        <Typography variant="body2">{description || ""}</Typography>
        <Grid container item>
          <Grid item xs={6} className="py-4 px-2">
            <Button
              fullWidth
              disabled={isLoading}
              onClick={() => {
                handleConfirm();
                closeModal();
              }}
            >
              {action || t("Confirm")}
            </Button>
          </Grid>
          <Grid item xs={6} className="py-4 px-2">
            <Button fullWidth color="secondary" onClick={() => closeModal()}>
              {t("Cancel")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ConfirmModal;
