import i18n from "utils/i18n";
import { convertPriceFormat } from "utils/common";
import {
  PackageType,
  PlanCardPropsType,
  PlanDetailType,
  PackageEntityType,
  PackagePricingType,
  DeeplePayFeeRate,
  DeeplePayFeatureDetail,
} from "types/PlanUsage";
import { EntityLevel } from "types/Billing";
import { IS_ALLOW_NEW_PACKAGE } from "config";

export const formatImageSearchPackageDetail = ({ imageSearchQuota }: PackageType) => [
  {
    type: "imageSearch",
    quota: convertPriceFormat(imageSearchQuota, 0),
  },
];

export const addOverPackage = (plans: PlanCardPropsType[]) => {
  if (!plans || !plans.length) {
    return [];
  }

  const lastPlan = plans[plans.length - 1];
  const overPackageDetail = lastPlan.planDetails.map((detail: PlanDetailType) => {
    const { type, quota } = detail;
    if (["subscriber", "member", "order", "sku"].includes(type)) {
      const isFreePackage = lastPlan.entityType === PackageEntityType.BASIC;

      return {
        type,
        quota:
          quota === "-1" ? i18n.t("planUsage.label.unlimited") : `${i18n.t("planUpgrade.label.prefix.over")} ${quota}`,
        isAddon: type === "member" && !isFreePackage,
      };
    }

    return detail;
  });

  const overPackage = {
    id: "0",
    entityType: lastPlan.entityType,
    name: `${i18n.t("planUpgrade.label.prefix.over")} ${lastPlan.name}`,
    price: -1,
    markLabel: `${lastPlan.markLabel}+`,
    isActive: false,
    planDetails: overPackageDetail,
    isContactSale: true,
    extraChargeRate: -1,
  };

  return [...plans, overPackage];
};

export const formatCcommercePackageDetail = ({ orderQuota, productQuota }: PackageType) => [
  {
    type: "order",
    quota: convertPriceFormat(orderQuota, 0),
  },
  {
    type: "sku",
    quota: convertPriceFormat(productQuota, 0),
  },
];

export const formatMainPackageDetail = (
  {
    subscriberQuota,
    faqQuota,
    memberQuota,
    messageQuota,
    channelQuota,
    entityType,
    entityLevel,
    broadcastQuota,
  }: PackageType,
  title?: PackagePricingType,
) => {
  const isFreePackage = entityType === PackageEntityType.BASIC;
  const incomingMsgQuotaText =
    messageQuota === -1 ? `${i18n.t("planUpgrade.quota.unlimited")}` : convertPriceFormat(messageQuota || 0, 0);
  const broadcastQuotaText = `${i18n.t("planUpgrade.quota.unlimited")}`;

  return [
    {
      type: "subscriber",
      quota: convertPriceFormat(subscriberQuota, 0),
    },
    {
      type: "incomingMsg",
      quota: incomingMsgQuotaText,
    },
    {
      type: "intent",
      isOverCustomPackage: title === PackagePricingType.ENTERPRISE,
      quota: faqQuota.toString(),
    },
    {
      type: "member",
      isOverCustomPackage: title === PackagePricingType.ENTERPRISE,
      quota: memberQuota.toString(),
      isAddon: !isFreePackage,
    },
    {
      type: "channel",
      quota: channelQuota.toString(),
      isAddon: !IS_ALLOW_NEW_PACKAGE && !isFreePackage,
    },
    {
      type: "imageSearch",
      quota: "100",
      isDisabled: isFreePackage,
      isAddon: !isFreePackage,
    },
    {
      type: "broadcast",
      isOverCustomPackage: title === PackagePricingType.ENTERPRISE,
      quota: IS_ALLOW_NEW_PACKAGE ? convertPriceFormat(broadcastQuota || 0, 0) : broadcastQuotaText,
      isAddon: entityLevel === EntityLevel.PROFESSIONAL,
    },
  ];
};

export const getPowerfulFeatureDetails = (title: string) => [
  {
    type: "liveChatAgent",
    quota: "",
  },
  {
    type: "orderManagement",
    quota: "",
  },
  ...(IS_ALLOW_NEW_PACKAGE
    ? []
    : [
        {
          type: "inventoryManagement",
          quota: "",
        },
      ]),
  {
    type: "sameDayFoodDelivery",
    quota: "",
  },
  {
    type: "monthlyAddon",
    quota: "",
    isDisabled: title === PackagePricingType.FREE,
  },
  {
    type: IS_ALLOW_NEW_PACKAGE ? "newCustomAi" : "customAi",
    quota: "",
    isDisabled: title === PackagePricingType.FREE,
    isAddon: title === EntityLevel.FREE,
  },
  {
    type: "customTheme",
    quota: "",
  },
  {
    type: "facebookCommentToChat",
    quota: "",
    isDisabled: title === PackagePricingType.FREE,
  },
  ...(IS_ALLOW_NEW_PACKAGE
    ? []
    : [
        {
          type: "openApi",
          quota: "",
          isDisabled: title !== PackagePricingType.PREMIUM && title !== PackagePricingType.ENTERPRISE,
        },
      ]),
];

export const getGrowthFeatureDetails = (title: string) => [
  {
    type: "marketingTools",
    quota: "",
  },
  {
    type: "salesReport",
    quota: "",
  },
  {
    type: "intelligenceAnalytics",
    quota: "",
    isDisabled: title === PackagePricingType.FREE,
  },
];

export const getDeeplePayFeatureDetails = (deeplePayFeeRate?: DeeplePayFeeRate): DeeplePayFeatureDetail[] => [
  {
    type: "qrCode",
    quota: "",
    unit: 5,
    fee: deeplePayFeeRate?.qrCash?.rate,
  },
  {
    type: "mobileBanking",
    quota: "",
    unit: 5,
    fee: deeplePayFeeRate?.mobileBanking?.rate,
  },
  {
    type: "creditCard",
    quota: "",
    unit: 5,
    fee: deeplePayFeeRate?.creditCard?.rate,
  },
  {
    type: "trueMoneyWallet",
    quota: "",
    unit: 5,
    fee: deeplePayFeeRate?.trueMoneyWallet?.rate,
  },
  // Todo: add more payment supported
];

export const associateAddonKey = (addonTypes: string[], value: boolean, planDetails: PlanDetailType[]) =>
  planDetails.map(({ type, ...props }) => {
    const isIncludedAddonType = addonTypes.includes(type);
    if (isIncludedAddonType) {
      return {
        type,
        ...props,
        isAddon: value,
      };
    }

    return {
      type,
      ...props,
    };
  });

export const moveArrayIndex = (arr: unknown[], fromIndex: number, toIndex: number) => {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);

  return arr;
};

export const switchKeyFeatureRowDetails = (planDetails: PlanDetailType[], enableDeeplePay = true, title?: string) => {
  const coreDetails = planDetails.splice(3, 4);
  const deeplePayDetails = [
    {
      type: "deeplePay",
      quota: "",
      isDisabled: !enableDeeplePay,
    },
  ];
  const openAPIDetails = [
    {
      type: "openApi",
      quota: "",
      isDisabled: title !== PackagePricingType.PREMIUM,
    },
  ];

  const newCore = associateAddonKey(["broadcast"], false, coreDetails);
  const newData = moveArrayIndex([...planDetails, ...deeplePayDetails, ...newCore, ...openAPIDetails], 5, 2);

  const newPlanDetails = IS_ALLOW_NEW_PACKAGE ? moveArrayIndex(newData, 4, 2) : [...planDetails, ...coreDetails];

  return newPlanDetails as PlanDetailType[];
};
