import React, { FC } from "react";
import { types as CardType } from "credit-card-type";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import Typography from "components/Typography";
import COLORS from "constants/Colors";

import { prettyCardNumber } from "utils/creditCard";
import { AMERICAN_EXPRESS_LOGO, JCB_LOGO, MASTERCARD_LOGO, VISA_LOGO } from "constants/Image";
import { CreditCardBrand } from "types/Billing";
import { InformationCardWrapper, LogoImg } from "./styled";

interface InformationCardPropsType {
  cardNumber: string;
  name: string;
  expirationMonth: string;
  expirationYear: string;
  brand: CreditCardBrand;
}

const creditCardImageUrl: Record<CreditCardBrand, string> = {
  AMERICAN_EXPRESS: AMERICAN_EXPRESS_LOGO,
  JCB: JCB_LOGO,
  MASTERCARD: MASTERCARD_LOGO,
  VISA: VISA_LOGO,
};

const InformationCard: FC<InformationCardPropsType> = (props) => {
  const { t } = useTranslation();

  const { cardNumber, brand, name, expirationMonth, expirationYear } = props;
  const cardNumberFormat = prettyCardNumber(cardNumber.replace(/\D/g, "●"), CardType[brand]).split(" ");

  return (
    <InformationCardWrapper>
      <Grid container justify="space-between" alignItems="center" className="px-4">
        <Grid item>
          <Typography variant="title3" className="text-center font-weight-bold" color={COLORS.Light}>
            {t("PrimaryCard")}
          </Typography>
        </Grid>
        <Grid item>
          <LogoImg src={creditCardImageUrl[brand]} />
        </Grid>
      </Grid>
      <Grid container justify="center">
        {cardNumberFormat.map((splitNumber, index) => {
          const isDot = splitNumber.includes("●");
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item key={index} className="mx-2">
              <Typography variant={isDot ? "title2" : "title1"} className="text-center" color={COLORS.Light}>
                {isDot ? splitNumber.replace(/\d/g, "●") : splitNumber}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
      <Grid container justify="space-between" className="px-4">
        <Grid item xs={8} className="ellipsis">
          <Typography
            variant="title3"
            className="font-weight-bold hiddenOverFlowText ellipsis w-100"
            color={COLORS.Light}
          >
            {name}
          </Typography>
        </Grid>
        <Grid item xs={4} container justify="flex-end">
          <Typography variant="title3" className="text-center font-weight-bold" color={COLORS.Light}>
            {expirationMonth}/{expirationYear}
          </Typography>
        </Grid>
      </Grid>
    </InformationCardWrapper>
  );
};

InformationCard.displayName = "InformationCard";
export default InformationCard;
