import styled from "styled-components";

export const CrossLine = styled.div`
  box-shadow: 0px 0px 0px 1.5px ${({ theme }) => theme.COLORS.White};
  width: 100%;
  height: 0px;
  border: 1px solid ${({ theme }) => theme.COLORS.DarkMed};
  background-color: ${({ theme }) => theme.COLORS.DarkMed};
  border-radius: 5px;
  transform: rotate(-45deg);
  position: absolute;
  top: 40%;
  left: -12%;
`;

export const NotificationIconWrapper = styled.div`
  display: flex;
  align-self: center;
  margin-right: 16px;
  position: relative;
  cursor: pointer;
`;
