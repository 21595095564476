import { useState, useCallback } from "react";
import { notifyWarn } from "utils/notify";
import { LIMIT_ID_SELECTED } from "../config";

type SelectedIdsType = Record<string, boolean>;

const useSelectedIds = ({ warringMessage = "" }: { warringMessage?: string }) => {
  const [selectedIds, setSelectedIds] = useState<SelectedIdsType>({});

  const getIsOverLimit = (newSelectIds: SelectedIdsType) => {
    const values = Object.values(newSelectIds);
    const newSelectedIdsLength = values.filter((value) => value).length;
    if (newSelectedIdsLength > LIMIT_ID_SELECTED) {
      return true;
    }

    return false;
  };

  const selectRowId = useCallback(
    (id: string) => {
      const isSelectedId = Boolean(selectedIds[id]);
      const newSelectIds = { ...selectedIds, [`${id}`]: !isSelectedId };
      const isOverLimit = getIsOverLimit(newSelectIds);
      if (isOverLimit) {
        notifyWarn(warringMessage);
        return;
      }

      setSelectedIds(newSelectIds);
    },
    [selectedIds, warringMessage],
  );

  const selectAllRowIdsInPage = useCallback(
    (ids: string[], isSelectedSomeRow: boolean) => {
      if (!Array.isArray(ids)) {
        return;
      }

      const temp = ids.reduce((total, id) => {
        return { ...total, [`${id}`]: isSelectedSomeRow };
      }, {});
      const newSelectIds = { ...selectedIds, ...temp };
      const isOverLimit = getIsOverLimit(newSelectIds);
      if (isOverLimit) {
        notifyWarn(warringMessage);
        return;
      }

      setSelectedIds(newSelectIds);
    },
    [selectedIds, warringMessage],
  );

  return {
    selectedIds,
    setSelectedIds,
    selectRowId,
    selectAllRowIdsInPage,
  };
};

export default useSelectedIds;
