import * as React from "react";

const SvgIcAwaitingShipping = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26.695 15.546h-5.133c-.212 0-.24-.207-.24-.207v-4.276s-.013-.163.26-.163h1.464c.884 0 1.938.64 2.343 1.424l1.356 2.745c.058.113.12.21.183.298.048.066-.032.179-.233.179M7.22 22.49a3.165 3.165 0 013.167 3.167 3.166 3.166 0 11-3.166-3.166zm16.7 0a3.165 3.165 0 013.166 3.167 3.166 3.166 0 11-3.166-3.166zm-16.7 1.79a1.376 1.376 0 10.003 2.753 1.376 1.376 0 00-.003-2.753zm16.7 0a1.376 1.376 0 10.001 2.753 1.376 1.376 0 00-.002-2.753zM1.777 9.778A8 8 0 1016.16 4.955h3.385c1.208 0 1.775.822 1.775 1.826V9.37c0 .282.282.275.282.275h2.084c.992 0 2.176.722 2.633 1.601l1.525 3.087c.455.88 1.119 1.072 2.28 1.373.963.25 1.875 2.028 1.875 4.914v3.425c0 .992-.81 1.802-1.804 1.802h-2.05c-.101 0-.1-.127-.1-.189a4.132 4.132 0 00-4.125-4.128 4.131 4.131 0 00-4.127 4.128c0 .054.006.19-.155.19h-8.134c-.175 0-.157-.128-.157-.19A4.131 4.131 0 007.22 21.53a4.132 4.132 0 00-4.126 4.128c0 .062-.034.19-.262.19H1.825A1.83 1.83 0 010 24.023V6.78c0-1.004.467-1.826 1.825-1.826h1.57a7.965 7.965 0 00-1.617 4.823zm8-6.222a6.222 6.222 0 110 12.444 6.222 6.222 0 010-12.444zm0 1.777a4.444 4.444 0 100 8.89 4.444 4.444 0 000-8.89zm.02 1.334c.245 0 .444.199.444.444v2.823l1.497 1.151a.444.444 0 11-.541.705l-1.671-1.285-.174-.352V7.11c0-.245.199-.444.444-.444z"
    />
  </svg>
);

export default SvgIcAwaitingShipping;
