import React from "react";
import { StyledBurger } from "./Burger.styled";

interface BurgerProps {
  id?: string;
  isActive: boolean;
  onToggle: (isToggled: boolean) => void;
}

const Burger: React.FC<BurgerProps> = ({ id, onToggle, isActive }) => {
  const isExpanded = !!isActive;

  return (
    <StyledBurger
      id={id}
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      isActive={isActive}
      onClick={() => onToggle(!isActive)}
    >
      <span />
      <span />
      <span />
    </StyledBurger>
  );
};

export default Burger;
