import * as React from "react";

const SvgIcStoreChannel = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M102,66.73c9.19-9.18,25.06-2.66,25.06,10.38a14.55,14.55,0,0,1-4.3,10.38l-7,7a14.67,14.67,0,0,1-20.76,0,4.13,4.13,0,0,1,5.85-5.84,6.42,6.42,0,0,0,9.07,0l7-7a6.41,6.41,0,1,0-9.07-9.07A4.14,4.14,0,0,1,102,66.73ZM84.2,78.12a14.67,14.67,0,0,1,20.76,0A4.14,4.14,0,0,1,99.11,84,6.4,6.4,0,0,0,90,84l-7,7A6.41,6.41,0,1,0,92.15,100,4.14,4.14,0,1,1,98,105.84a14.65,14.65,0,0,1-10.38,4.29c-13.06,0-19.58-15.86-10.38-25Zm52.28-36.66h-73a6.44,6.44,0,0,0-6.44,6.43v74.23a6.45,6.45,0,0,0,6.44,6.44h73a6.45,6.45,0,0,0,6.44-6.44V47.89A6.44,6.44,0,0,0,136.48,41.46Zm0-8.27a14.73,14.73,0,0,1,14.71,14.7v74.23a14.73,14.73,0,0,1-14.71,14.71H103.85v6.85h3.85a11.55,11.55,0,0,1,10.86,7.71h36.07a3.86,3.86,0,1,1,0,7.71H118.57a11.55,11.55,0,0,1-10.86,7.71H92.29a11.55,11.55,0,0,1-10.86-7.71H45.36a3.86,3.86,0,1,1,0-7.71H81.43a11.55,11.55,0,0,1,10.86-7.71h3.85v-6.85H63.52a14.73,14.73,0,0,1-14.67-13.61l0-1.1V47.89a14.73,14.73,0,0,1,14.71-14.7Z"
    />
  </svg>
);

export default SvgIcStoreChannel;
