import React from "react";
import { CellProps } from "react-table";
import Typography from "components/Typography";

const MessageUsed = <T extends object>(t: Function) => {
  const renderCell = (cell: CellProps<T>) => {
    return (
      <Typography variant="body3" color="DarkMed">
        {cell.value || t("DEFAULT_MESSAGE")}
      </Typography>
    );
  };

  return {
    disableSortBy: true,
    Header: t("MESSAGE_USED"),
    accessor: "messageUsed",
    Cell: renderCell,
  };
};

export default MessageUsed;
