import * as React from "react";

const SvgIcCreditCard = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <g>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M24 7.56283V5.56132C24 4.56057 23.1894 3.75 22.1887 3.75H1.81132C0.810566 3.75 0 4.56057 0 5.56132V7.56283H24ZM19.4987 13.7508H18.498C18.2217 13.7508 17.9976 13.9749 17.9976 14.2512V15.2519C17.9976 15.5282 18.2217 15.7523 18.498 15.7523H19.4987C19.775 15.7523 19.9991 15.5282 19.9991 15.2519V14.2512C19.9991 13.9749 19.775 13.7508 19.4987 13.7508ZM0 17.937V9.93551H24V17.937C24 18.9378 23.1894 19.7483 22.1887 19.7483H1.81132C0.810566 19.7483 0 18.9378 0 17.937ZM3.50038 16.7506H9.50038C9.7766 16.7506 10.0008 16.5265 10.0008 16.2502C10.0008 15.974 9.7766 15.7499 9.50038 15.7499H3.50038C3.22415 15.7499 3 15.974 3 16.2502C3 16.5265 3.22415 16.7506 3.50038 16.7506ZM3.50038 14.7491H9.50038C9.7766 14.7491 10.0008 14.5249 10.0008 14.2487C10.0008 13.9725 9.7766 13.7483 9.50038 13.7483H3.50038C3.22415 13.7483 3 13.9725 3 14.2487C3 14.5249 3.22415 14.7491 3.50038 14.7491ZM19.5011 16.7483C20.3275 16.7483 21 16.0759 21 15.2495V14.2487C21 13.4223 20.3275 12.7499 19.5011 12.7499H18.5004C17.674 12.7499 17.0015 13.4223 17.0015 14.2487V15.2495C17.0015 16.0759 17.674 16.7483 18.5004 16.7483H19.5011Z"
      />
    </g>
  </svg>
);

export default SvgIcCreditCard;
