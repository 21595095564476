import styled from "styled-components";
import MUIInputAdornment from "@material-ui/core/InputAdornment";

import { Step, StepLabel } from "@material-ui/core";
import COLORS from "constants/Colors";
import Button from "components/Button";
import TextField from "components/TextField";
import Card from "components/Card";

export const InputAdornment = styled(MUIInputAdornment)`
  margin-left: 0;
  margin-right: 8px;
`;

export const InputEndAdornmentWrapper = styled.div`
  cursor: default;
`;

export const StepperItem = styled(StepLabel)`
  span {
    margin-top: 4px !important;
  }
`;

export const StepItem = styled(Step)`
  .MuiStepIcon-root {
    color: ${COLORS.White};
    border: 1px solid ${COLORS.Primary};
    border-radius: 50%;
  }

  .MuiStepIcon-root.MuiStepIcon-active {
    color: ${COLORS.LightGray};
    border: 1px solid ${COLORS.Primary};
    border-radius: 50%;
  }

  .MuiStepIcon-root.MuiStepIcon-completed {
    color: ${COLORS.Primary};
    border: none;
    width: 26px;
    height: 26px;
  }

  .MuiStepIcon-text {
    fill: ${COLORS.Primary};
  }

  .MuiStepLabel-label {
    color: ${COLORS.Primary};
  }

  .MuiStepConnector-alternativeLabel {
    top: 17px;
    left: calc(-50% + 15px);
    right: calc(50% + 15px);
    position: absolute;
  }
`;

export const WithdrawButton = styled(Button)`
  :hover {
    background-color: ${COLORS.Primary};
    color: ${COLORS.White};
  }

  &.cancel {
    background-color: transparent;
    border: 1px solid ${COLORS.Primary};
    color: ${COLORS.Primary};
  }

  @media only screen and (max-width: 400px) {
    height: 42px;
    width: 140px;
  }
`;

export const MaxWrapper = styled.div`
  cursor: pointer;
`;

export const WithdrawTextInput = styled(TextField)`
  .MuiOutlinedInput-input {
    padding: 14px 12px 14px 2px;
  }
`;

export const WithdrawCard = styled(Card)`
  width: 100%;
  height: 100%;
  min-height: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  &.desktop {
    min-width: 555px;
  }
`;

export const ErrorWithdrawWrapper = styled.div`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.COLORS.LightGray};
  padding: 16px;
`;
