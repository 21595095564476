import * as React from "react";

const SvgIcStop = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 2c7.732 0 14 6.268 14 14s-6.268 14-14 14S2 23.732 2 16 8.268 2 16 2zm10.092 9.617L7.16 22.547A10.984 10.984 0 0016 27c6.075 0 11-4.925 11-11 0-1.558-.324-3.04-.908-4.383zM16 5C9.925 5 5 9.925 5 16c0 1.379.254 2.699.717 3.915L24.53 9.054A10.978 10.978 0 0016 5z"
    />
  </svg>
);

export default SvgIcStop;
