/* eslint-disable jsx-a11y/media-has-caption */

import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { IcDownload } from "components/SvgIcons";

import { TextMessage } from "./styled";
import MessageLayout from "./MessageLayout";

type VideoMessagePropsType = {
  bgColor?: string;
  chatBubbleTextColor?: string;
  from?: string;
  handleClickDate?: () => void;
  isCustomer?: boolean;
  thumbnailUrl?: string;
  timestamp?: string;
  videoUrl: string;
};

const VideoMessage: FC<VideoMessagePropsType> = ({
  bgColor,
  chatBubbleTextColor,
  from,
  handleClickDate,
  thumbnailUrl,
  timestamp,
  videoUrl,
  isCustomer = false,
}) => {
  const { t } = useTranslation();

  if (isCustomer) {
    return (
      <MessageLayout
        from={from}
        timeStamp={timestamp}
        isCustomer={isCustomer}
        bgColor={bgColor}
        onClickDate={handleClickDate}
      >
        <TextMessage variant="body3" color={chatBubbleTextColor}>
          <div className="d-flex flex-wrap align-items-center">
            <div className="mr-1">
              {t("Customer sent video")} {!videoUrl && t("REMARK_DOWNLOAD_FROM_PROVIDER")}
            </div>
            {videoUrl && (
              <div>
                <a className="mt-1" href={videoUrl} rel="noreferrer" target="_blank">
                  (<IcDownload fontSize="14" className="px-1" viewBox="0 -2 32 32" />
                  {t("Download")} )
                </a>
              </div>
            )}
          </div>
        </TextMessage>
      </MessageLayout>
    );
  }

  return (
    <>
      {thumbnailUrl && videoUrl && (
        <MessageLayout
          bgColor={bgColor}
          isCustomer={isCustomer}
          timeStamp={timestamp}
          from={from}
          onClickDate={handleClickDate}
        >
          <video
            controls
            width="100%"
            height="auto"
            poster={thumbnailUrl}
            src={videoUrl}
            autoPlay={false}
            controlsList="nodownload"
          />
        </MessageLayout>
      )}
    </>
  );
};

export default VideoMessage;
