import styled from "styled-components";
import COLORS from "constants/Colors";
import { Z_INDEX } from "constants/ZIndex";

export type ContentDataType = { type: string; bold?: boolean; value?: string; color?: string };

type FlexMessageContentPropsType = {
  isCategory?: boolean;
};

type CategoryFlexImagePropsType = {
  src?: string;
};
export const PromotionBadge = styled.div`
  background: ${({ theme }) => theme.COLORS.Primary};
  color: ${({ theme }) => theme.COLORS.White};
  border-radius: 10px 0 10px 0;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  line-height: 12px;
  padding: 6px;
  z-index: 1;
  position: absolute;
  max-width: 114px;
  height: fit-content;
  width: fit-content;
`;

export const FlexImageUnavailable = styled.div<CategoryFlexImagePropsType>`
  display: flex;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  text-align: center;
  width: 194px;
  height: 188px;
  background-image: linear-gradient(to top, rgba(20, 25, 49, 0), rgba(20, 25, 49, 0.7) 100%), url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  border-radius: 10px 10px 0px 0px;
`;

export const FlexImage = styled.img<FlexMessageContentPropsType>`
  display: flex;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  text-align: center;
  width: 194px;
  height: 188px;
  border-radius: ${({ isCategory }) => (isCategory ? "10px" : "10px 10px 0px 0px")};
`;

export const CategoryFlexImage = styled.div<CategoryFlexImagePropsType>`
  display: flex;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  text-align: center;
  width: 194px;
  height: 188px;
  background-image: linear-gradient(to bottom, rgba(20, 25, 49, 0) 30%, rgba(20, 25, 49, 0.7)), url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
`;

export const CategoryFlexImageUnavailable = styled(CategoryFlexImage)`
  background-image: linear-gradient(to bottom, rgba(20, 25, 49, 0) 50%, rgba(20, 25, 49, 0.7)),
    linear-gradient(to top, rgba(20, 25, 49, 0), rgba(20, 25, 49, 0.7) 100%), url(${({ src }) => src});
`;

export const CategoryFlexName = styled.div`
  margin-top: -50px;
  height: 50px;
  width: 125px;
  white-space: pre-wrap;
  overflow: visible;
  text-overflow: ellipsis;
  word-break: break-word;
  align-items: flex-end;
  align-content: flex-end;
  display: flex;
`;

export const FlexMessageContent = styled.div<FlexMessageContentPropsType>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 340px;
  border-radius: 10px;
  background: ${({ isCategory }) => (isCategory ? "none" : COLORS.LightWhite)};
  justify-content: space-between;
  height: 100%;
`;

export const ButtonPrimary = styled.div`
  display: flex;
  height: 37px;
  border-radius: 0px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  color: ${COLORS.LightWhite};
  background: ${COLORS.Primary};
`;

export const ButtonError = styled.div`
  display: flex;
  height: 37px;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  color: ${COLORS.Error};
`;

export const ButtonSecondary = styled.div`
  display: flex;
  height: 37px;
  border-radius: 0px 0px 0px 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  color: ${COLORS.DarkGray};
  background: ${COLORS.LightGray};
`;

export const SingleButtonPrimary = styled.div`
  display: flex;
  height: 37px;
  border-radius: 0px 0px 10px 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  color: ${COLORS.LightWhite};
  background: ${COLORS.Primary};
`;

export const SelectButtonPrimary = styled.span`
  display: flex;
  padding: 0px 10px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  color: ${COLORS.LightWhite};
  background: ${COLORS.Primary};
`;

export const SingleButtonSecondary = styled.div`
  display: flex;
  height: 37px;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  color: ${COLORS.DarkGray};
  background: ${COLORS.LightGray};
`;

export const ProductSKUImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  text-align: center;
  width: 60px;
  height: 60px;
`;

export const ProductSKUImageSmall = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  text-align: center;
  width: 40px;
  height: 40px;
`;

export const AmountWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${COLORS.LightGray};
  color: ${COLORS.Primary};
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
`;

export const CarrierImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  text-align: center;
  width: 40px;
  height: 40px;
`;

export const SingleButtonPrimaryNoBorder = styled.div`
  display: flex;
  height: 37px;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  color: ${COLORS.LightWhite};
  background: ${COLORS.Primary};
`;

export const ShippingTitleWrapper = styled.div`
  display: flex;
  height: 37px;
  border-radius: 10px 10px 0px 0px;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  color: ${COLORS.LightWhite};
  background: ${COLORS.Primary};
`;

export const AddressTextWrapper = styled.div`
  border-radius: 10px;
  color: ${COLORS.DarkGray};
  background: ${COLORS.LightGray};
`;

type CarouselContainerPropsType = {
  displayScrollbar?: boolean;
};

export const CarouselContainer = styled.div<CarouselContainerPropsType>`
  overflow-x: scroll;
  display: flex;
  width: 100%;

  ::-webkit-scrollbar {
    height: 6px;
    display: ${({ displayScrollbar }) => (displayScrollbar ? "initial !important" : "none")};
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.COLORS.DarkLight};
  }

  ::-webkit-scrollbar:vertical {
    display: none;
  }
`;

export const CarouselContent = styled.div`
  position: relative;
  color: ${({ theme }) => theme.COLORS.Primary};
  border-radius: 20px;
  display: flex;
  flex-shrink: 0;
  margin: 8px 4px;
  padding: 0px;
  width: 125px;
`;

export const CarouselProductContent = styled(CarouselContent)`
  width: 194px;
  background-color: ${({ theme }) => theme.COLORS.LightWhite};
`;

export const CircleRemoveIconContainer = styled.div`
  position: absolute;
  z-index: ${Z_INDEX.REMOVE_IMAGE_ICON + 1};
  top: -6px;
  right: -6px;
  font-size: 25px;
  &:hover {
    cursor: pointer;
    transform: scale(1.1, 1.1);
  }
`;

export const UnavailableContainer = styled.div`
  position: absolute;
  z-index: ${Z_INDEX.REMOVE_IMAGE_ICON};
  top: 10px;
  right: 10px;
  height: 20px;
  border-radius: 20px;
  color: ${({ theme }) => theme.COLORS.White};
  line-height: 20px;
  font-size: 12px;
  padding: 4px;
  background-color: ${({ theme }) => theme.COLORS.Error};
`;
