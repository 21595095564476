import MuiTooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: 14,
  },
}));

const StyledMark = styled.mark`
  padding: 5px;
  border-radius: 3px;
`;

type ToolTipProps = {
  tooltip?: string;
};

const ToolTip: FC<HTMLAttributes<HTMLDivElement> & ToolTipProps> = ({ children, className, tooltip }) => {
  const classes = useStyles();

  if (!tooltip) return <StyledMark className={className}>{children}</StyledMark>;

  return (
    <MuiTooltip classes={classes} arrow title={tooltip} placement="top">
      <StyledMark className={className}>{children}</StyledMark>
    </MuiTooltip>
  );
};

export default ToolTip;
