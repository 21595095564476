import * as React from "react";

const SvgIcIncome = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
    <circle cx="8" cy="8" r="8" fill="#F5F7FB" />
    <path
      d="M10.9609 11.0203C10.9902 10.9936 11.0173 10.9645 11.0419 10.9333C11.1144 10.8412 11.1573 10.7293 11.1649 10.6123L11.1649 7.59431C11.1683 7.44793 11.1199 7.30503 11.0283 7.19085C10.9366 7.07667 10.8075 6.99856 10.6639 6.97031C10.5772 6.9558 10.4884 6.96051 10.4038 6.98409C10.3191 7.00768 10.2407 7.04957 10.174 7.1068C10.1073 7.16403 10.054 7.23521 10.0179 7.3153C9.98177 7.39539 9.96366 7.48245 9.96487 7.57031L9.96487 9.12731L5.85487 4.99931C5.79904 4.94262 5.73249 4.8976 5.6591 4.86687C5.5857 4.83614 5.50693 4.82031 5.42737 4.82031C5.3478 4.82031 5.26903 4.83614 5.19564 4.86687C5.12224 4.8976 5.0557 4.94262 4.99987 4.99931V4.99931C4.94408 5.05503 4.89982 5.12121 4.86963 5.19404C4.83944 5.26688 4.82389 5.34496 4.82389 5.42381C4.82389 5.50266 4.83944 5.58073 4.86963 5.65357C4.89982 5.72641 4.94408 5.79259 4.99987 5.84831L9.10987 9.95831L7.58587 9.95831C7.43991 9.95472 7.29735 10.0027 7.18323 10.0937C7.06912 10.1848 6.99074 10.3132 6.96187 10.4563C6.94687 10.5432 6.9512 10.6323 6.97456 10.7173C6.99792 10.8024 7.03973 10.8812 7.09702 10.9482C7.1543 11.0152 7.22566 11.0688 7.306 11.1051C7.38634 11.1414 7.47371 11.1596 7.56187 11.1583L10.5619 11.1583C10.7072 11.1622 10.849 11.1131 10.9609 11.0203V11.0203Z"
      fill="#00C566"
    />
  </svg>
);

export default SvgIcIncome;
