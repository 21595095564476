import { useQuery } from "@apollo/client";
import get from "lodash/get";
import { GET_INCOME_TRANSACTION } from "graphql/transaction/query";

type Variables = {
  page: number;
  perPage: number;
  projectId: string;
};

const useIncomeTransactions = (variables: Variables) => {
  const { data, loading: isLoading } = useQuery(GET_INCOME_TRANSACTION, {
    variables: { projectId: variables.projectId, offset: variables.page, limit: variables.perPage },
  });

  return {
    incomeTransactions: get(data, "queryIncomeDeeplePayWalletTransactionsByProjectId.results") || [],
    isLoading,
    total: get(data, "queryIncomeDeeplePayWalletTransactionsByProjectId.total") || 0,
  };
};

export default useIncomeTransactions;
