import React, { useContext, useState } from "react";
import "styles/index.css";
import { StylesProvider, createMuiTheme } from "@material-ui/core/styles";

import { ThemeProvider } from "styled-components";
import { ThemeContext } from "../../utils/context";
import "bootstrap-utilities/bootstrap-utilities.css";
import "react-toastify/dist/ReactToastify.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

document.documentElement.style.setProperty("--toastify-toast-width", "auto");

const DeepleTheme: React.FC = ({ children }) => {
  const { theme } = useContext(ThemeContext);
  const [currentTheme, setCurrentTheme] = useState(theme);
  const muiTheme = createMuiTheme();

  return (
    <ThemeContext.Provider value={{ theme: currentTheme, setTheme: setCurrentTheme }}>
      <ThemeProvider theme={{ COLORS: currentTheme, breakpoints: muiTheme.breakpoints }}>
        <StylesProvider injectFirst>{children}</StylesProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default DeepleTheme;
