const { PUBLIC_URL } = process.env;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";
const AVAILABLE_SCROLL_REACH_BOTTOM_DELTA = 10;
const DEFAULT_SORT_PRODUCT_BY = "desc";
const DIGITAL_PRODUCT_LIMIT = 10;
const DIGITAL_PRODUCT_CODE_LIMIT = 10;
const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
const FACEBOOK_HUMAN_AGENT_IDS_ENABLE = (process.env.REACT_APP_FACEBOOK_HUMAN_AGENT_IDS_ENABLE || "")
  .split(",")
  .filter(Boolean);
const FACEBOOK_OAUTH_REQUEST_URL = "https://graph.facebook.com/oauth/access_token";
const FACEBOOK_REQUEST_URL = "https://graph.facebook.com/v16.0";
const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const FIREBASE_PUBLIC_VAPID_KEY = process.env.REACT_APP_FIREBASE_PUBLIC_VAPID_KEY;
// IS_CALCULATOR_ENABLED flag will be removed after enterprise feature is enabled
// By default we use calculator in order and cart but for enterprise wil be disabled.
// For testing we will set IS_CALCULATOR_ENABLED = true if IS_ENTERPRISE_PAGE_ENABLED = true also (temporary)
// Because now we don't have getCartSummary() from enterprise.
// if IS_CALCULATOR_ENABLED = true and IS_ENTERPRISE_PAGE_ENABLED = true, shopping cart will calculate as SME project type
const IS_CALCULATOR_ENABLED = process.env.REACT_APP_IS_CALCULATOR_ENABLED === "true";
// if IS_CALCULATOR_ENABLED = true,
// Enable query isEnterprise in user permission from graphql
// Enable to show page and route when project is enterprise
// Disable calculator in shopping cart if IS_CALCULATOR_ENABLED = false
const IS_ENTERPRISE_PAGE_ENABLED = process.env.REACT_APP_ENTERPRISE_PAGE_ENABLE === "true";
const IS_USING_NEW_SHIPPING_SERVICE = process.env.REACT_APP_IS_USING_NEW_SHIPPING_SERVICE === "true";
const MAXIMUM_FOOD_PRODUCT_IMAGE_UPLOAD = 1;
const MAX_PROJECTS = 500;
const MAXIMUM_BROADCAST_MESSAGES_TOTAL = 4;
const MAXIMUM_BROADCAST_NAME_STRING_LENGTH = 100;
const MAXIMUM_FACEBOOK_BROADCAST_MESSAGES_TOTAL = 1;
const MAXIMUM_FACEBOOK_CRAW_MESSAGES_TOTAL = 1;
const MAXIMUM_PRODUCT_IMAGE_UPLOAD = 10;
const MAXIMUM_PRODUCT_NAME_STRING_LENGTH = 100;
const MAXIMUM_PRODUCT_DESCRIPTION_STRING_LENGTH = 2500;
const MAXIMUM_VARIATION_NAME_STRING_LENGTH = 25;
const MAXIMUM_WELCOME_MESSAGES_LENGTH = 4;
const MAXIMUM_ADDITIONAL_SLIP_UPLOAD = 5;
const MINIMUM_FAQ_QUESTIONS_LENGTH = 3;
const MOBILE_DISPLAY_LIST_LIMIT = 15;
const OMISE_PUBLIC_KEY = process.env.REACT_APP_OMISE_PUBLIC_KEY;
const PRODUCT_OPTION_CHOICE_LIMIT = 50;
const PRODUCT_OPTION_LIMIT = 20;

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || "development";
const TOKEN_LOCAL_STORAGE_KEY = "deepleid";
const COMPLETE_GUIDELINE_STEP_LOCAL_STORAGE_KEY = "completedGuidelineStep";
const MODAL_FACEBOK_RECURRING_NOTIFICATION_STORAGE_KEY = "modalFacebookRecurringNotification";
const IS_ADD_FOOD_PRODUCT_ENABLED = process.env.REACT_APP_IS_ADD_FOOD_PRODUCT_ENABLED;
const IS_UNIVERSAL_ERROR_ENABLE = process.env.REACT_APP_IS_ENABLE_UNIVERSAL_ERROR === "true";
const ENV = process.env.REACT_APP_ENV || "development";
// maximum number of project that useR has permission as OWNER that allow user to create project
const MAXIMUM_PROJECT_AS_OWNER_ALLOWED_TO_CREATE = 1;
const TOTAL_PROVINCE = 77;
const CUSTOM_CAROUSEL_ENABLE = process.env.REACT_APP_CUSTOM_CAROUSEL_ENABLE === "true";

const MAXIMUM_ADD_PRODUCT_CAROUSEL = 10;
const MAXIMUM_DISPLAY_CATEGORY_CAROUSEL = 10;

const CHANNEL_LIMIT = 2;

const NEW_FAQ_IDS_ENABLE = (process.env.REACT_APP_NEW_FAQ_IDS_ENABLE || "").split(",").filter(Boolean);
const WEBHOOK_BASE_URL = process.env.REACT_APP_WEBHOOK_URL;
const WEBVIEW_BASE_URL = process.env.REACT_APP_WEBVIEW_BASE_URL;

const FACEBOOK_ADS_IDS_ENABLE = (process.env.REACT_APP_FACEBOOK_ADS_IDS_ENABLE || "").split(",").filter(Boolean);

const GB_PRIME_PUBLIC_KEY = process.env.REACT_APP_GB_PRIME_PUBLIC_KEY;
const GB_PRIME_URL = process.env.REACT_APP_GB_PRIME_URL || "https://api.globalprimepay.com";

const IS_ALLOW_NEW_PACKAGE = process.env.REACT_APP_IS_ALLOW_NEW_PACKAGE === "true";
const IS_USE_NEW_FREEMIUM_PACKAGE = process.env.REACT_APP_IS_USE_NEW_FREEMIUM_PACKAGE === "true";

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";
const ANDROID_GOOGLE_CLIENT_ID = process.env.REACT_APP_ANDROID_GOOGLE_CLIENT_ID || "";

const FRESH_DESK_TOKEN = process.env.REACT_APP_FRESH_DESK_TOKEN || "5a60012d-632c-4f42-88e2-773bc615c916";
const FRESH_DESK_HOST = process.env.REACT_APP_FRESH_DESK_HOST || "https://wchat.in.freshchat.com";
const IS_FRESH_DESK = process.env.REACT_APP_IS_FRESH_DESK === "true";
const IS_UPLOAD_VIDEO_ENABLED = process.env.REACT_APP_IS_UPLOAD_VIDEO_ENABLED === "true";

const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID || "__CLIENT_ID__";
const APPLE_SIGN_IN_REDIRECT_URI =
  process.env.REACT_APP_APPLE_SIGN_IN_REDIRECT_URI || "https://console.dev.1convo.ai/auth/redirect";

const NEW_PRODUCT_LIST_ENABLE = process.env.REACT_APP_NEW_PRODUCT_LIST_ENABLE === "true";

export {
  ANDROID_GOOGLE_CLIENT_ID,
  API_BASE_URL,
  APPLE_CLIENT_ID,
  APPLE_SIGN_IN_REDIRECT_URI,
  AVAILABLE_SCROLL_REACH_BOTTOM_DELTA,
  CHANNEL_LIMIT,
  COMPLETE_GUIDELINE_STEP_LOCAL_STORAGE_KEY,
  CUSTOM_CAROUSEL_ENABLE,
  DEFAULT_SORT_PRODUCT_BY,
  DIGITAL_PRODUCT_CODE_LIMIT,
  DIGITAL_PRODUCT_LIMIT,
  ENV,
  FACEBOOK_ADS_IDS_ENABLE,
  FACEBOOK_APP_ID,
  FACEBOOK_HUMAN_AGENT_IDS_ENABLE,
  FACEBOOK_OAUTH_REQUEST_URL,
  FACEBOOK_REQUEST_URL,
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_PUBLIC_VAPID_KEY,
  FRESH_DESK_HOST,
  FRESH_DESK_TOKEN,
  GB_PRIME_PUBLIC_KEY,
  GB_PRIME_URL,
  GOOGLE_CLIENT_ID,
  IS_ADD_FOOD_PRODUCT_ENABLED,
  IS_ALLOW_NEW_PACKAGE,
  IS_CALCULATOR_ENABLED,
  IS_ENTERPRISE_PAGE_ENABLED,
  IS_FRESH_DESK,
  IS_UNIVERSAL_ERROR_ENABLE,
  IS_UPLOAD_VIDEO_ENABLED,
  IS_USE_NEW_FREEMIUM_PACKAGE,
  IS_USING_NEW_SHIPPING_SERVICE,
  MAX_PROJECTS,
  MAXIMUM_ADD_PRODUCT_CAROUSEL,
  MAXIMUM_ADDITIONAL_SLIP_UPLOAD,
  MAXIMUM_BROADCAST_MESSAGES_TOTAL,
  MAXIMUM_BROADCAST_NAME_STRING_LENGTH,
  MAXIMUM_DISPLAY_CATEGORY_CAROUSEL,
  MAXIMUM_FACEBOOK_BROADCAST_MESSAGES_TOTAL,
  MAXIMUM_FACEBOOK_CRAW_MESSAGES_TOTAL,
  MAXIMUM_FOOD_PRODUCT_IMAGE_UPLOAD,
  MAXIMUM_PRODUCT_DESCRIPTION_STRING_LENGTH,
  MAXIMUM_PRODUCT_IMAGE_UPLOAD,
  MAXIMUM_PRODUCT_NAME_STRING_LENGTH,
  MAXIMUM_PROJECT_AS_OWNER_ALLOWED_TO_CREATE,
  MAXIMUM_VARIATION_NAME_STRING_LENGTH,
  MAXIMUM_WELCOME_MESSAGES_LENGTH,
  MINIMUM_FAQ_QUESTIONS_LENGTH,
  MOBILE_DISPLAY_LIST_LIMIT,
  MODAL_FACEBOK_RECURRING_NOTIFICATION_STORAGE_KEY,
  NEW_FAQ_IDS_ENABLE,
  NEW_PRODUCT_LIST_ENABLE,
  OMISE_PUBLIC_KEY,
  PRODUCT_OPTION_CHOICE_LIMIT,
  PRODUCT_OPTION_LIMIT,
  PUBLIC_URL,
  SENTRY_DSN,
  SENTRY_ENV,
  TOKEN_LOCAL_STORAGE_KEY,
  TOTAL_PROVINCE,
  WEBHOOK_BASE_URL,
  WEBVIEW_BASE_URL,
};
