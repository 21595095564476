import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ButtonBase from "components/ButtonBase";
import COLORS from "constants/Colors";
import { PROJECT, SHIPPING, SHIPPING_DELIVERY_PERIOD, STORE_SETTINGS, OFFLINE_STORE } from "constants/Router";
import mapErrorMessage, { ERRORS } from "./errorMessage";

export const ErrorMessageLink: React.FC<{ projectId: string; field: string }> = (props) => {
  const { projectId, field } = props;
  const { t } = useTranslation();

  return (
    <>
      <span style={{ color: COLORS.Error }}>{`${t(mapErrorMessage(field))} `}</span>
      <ButtonBase className="px-2">
        {field === ERRORS.NO_DELIVERY_SETTING && (
          <Link
            to={`/${PROJECT}/${projectId}/${SHIPPING}/${SHIPPING_DELIVERY_PERIOD}`}
            style={{ color: COLORS.Primary }}
          >
            {t("Click here to go to delivery setting")}
          </Link>
        )}
        {field === ERRORS.NO_STORE_ADDRESS && (
          <Link to={`/${PROJECT}/${projectId}/${STORE_SETTINGS}/${OFFLINE_STORE}`} style={{ color: COLORS.Primary }}>
            {t("Click here to go to store setting")}
          </Link>
        )}
      </ButtonBase>
    </>
  );
};
