import React, { ChangeEvent, FC, memo, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { OptionType, ValueType } from "react-select";

import Card from "components/Card";
import Grid from "components/Grid";
import ControllerSwitch from "components/Switch/ControllerSwitch";
import Typography from "components/Typography";
import Divider from "components/Divider";
import { TextField } from "@material-ui/core";
import ErrorText from "components/ErrorText";
import InputAdornment from "components/TextField/InputAdornment.styled";
import Tooltip from "components/Tooltip";
import { IcTooltip } from "components/SvgIcons";
import { SEARCH_PRODUCT_ORDER_OPTIONS } from "constants/Setting";
import Select from "components/Select";

type ToggleFormPropsType = {
  isSelected: boolean;
  name: string;
  i18nDescription: string;
  isBotTimerActive: boolean;
  botTimeToResume: number;
  searchProductsOrder?: string;
};

const ToggleForm: FC<ToggleFormPropsType> = (props) => {
  const { t } = useTranslation();
  const { control, errors } = useFormContext();
  const { i18nDescription, isSelected, name, isBotTimerActive, botTimeToResume, searchProductsOrder = "" } = props;

  const [, setActive] = useState(isSelected);

  const renderBotTimer = useCallback(
    ({ onChange, value, defaultValue }) => (
      <TextField
        value={value}
        defaultValue={defaultValue}
        type="formatNumber"
        name="botTimer"
        InputProps={{
          endAdornment: <InputAdornment position="start">{t(`mins`)}</InputAdornment>,
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.value || 1);
        }}
      />
    ),
    [t],
  );

  const renderSearchProductOrder = useCallback(
    ({ onChange, value }) => {
      return (
        <Select
          value={SEARCH_PRODUCT_ORDER_OPTIONS.find((option) => option.value === value)}
          options={SEARCH_PRODUCT_ORDER_OPTIONS}
          onChange={(option) => {
            onChange((option as OptionType).value);
          }}
          formatOptionLabel={(option: ValueType<OptionType>) => t((option as OptionType).label)}
        />
      );
    },
    [t],
  );

  return (
    <Card className="w-100 mx-3">
      <Typography variant="title2">{t("ADVANCE_SETTING_TITLE")}</Typography>
      <Grid container xs={12} className="mt-4">
        <Grid item container alignItems="center" xs={12} zeroMinWidth>
          <ControllerSwitch defaultValue={isBotTimerActive} name="isBotTimerActive" control={control} />
          <Typography variant="body2" className="ml-2" fontWeight="500">
            {t("BOT_TIMER_ACTIVE_LABEL")}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} className="p-0">
        <Grid item xs={12} alignItems="center" className="pt-3 px-1">
          <Grid container alignItems="center">
            <Grid item xs={12} md="auto">
              <Typography variant="body3">{t("BOT_TIME_TO_RESUME_LABEL")}</Typography>
            </Grid>

            <Grid item xs={6} md={1} className="pl-md-3">
              <Controller name="botTimer" control={control} defaultValue={botTimeToResume} render={renderBotTimer} />
            </Grid>
          </Grid>

          <Grid item className="mt-2">
            <Typography variant="body3">{t("BOT_TIME_TO_RESUME_HELPER_TEXT")}</Typography>
          </Grid>
        </Grid>
        {errors.botTimer && (
          <Grid item xs={12} className="m-1">
            <ErrorText>{t(errors.botTimer.message as string)}</ErrorText>
          </Grid>
        )}
      </Grid>
      <Divider />
      <Grid container xs={12} alignItems="center">
        <Grid item container alignItems="center" xs={12} zeroMinWidth className="mt-2 mb-2">
          <ControllerSwitch
            name={name}
            control={control}
            defaultValue={isSelected}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setActive(event.target.checked)}
          />

          <Typography variant="body2" className="ml-2" fontWeight="500">
            {t("botSetting.admin.contact.title")}
          </Typography>
          <Tooltip className="ml-1" title={t("botSetting.admin.contact.tooltip") || ""}>
            <IcTooltip />
          </Tooltip>
        </Grid>

        <Grid item xs={12} zeroMinWidth className="mt-2">
          <Typography variant="body3">{t(i18nDescription)}</Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} className="pt-4 pb-2">
        <Grid item xs={12}>
          <Typography variant="body2" fontWeight="500">
            {t("Search product order")}
          </Typography>
        </Grid>
        <Grid item xs={12} className="pt-3">
          <Controller
            control={control}
            name="searchProductsOrder"
            defaultValue={searchProductsOrder}
            render={renderSearchProductOrder}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default memo(ToggleForm);
