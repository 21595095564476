const upSelling = {
  en: {
    "upSelling.title": "AI Up-selling",
    "upSelling.promotionType.product": "Product promotions",
    "upSelling.promotionType.shipping": "Shipping promotions",
    "upSelling.promotionType.product.FREE_GIVEAWAY": "Free gift",
    "upSelling.promotionType.product.FIXED_PRICE_DISCOUNT": "Discount price",
    "upSelling.promotionType.product.BUY_X_GET_Y": "Buy...get...",
    "upSelling.promotionType.product.FREE_LOWEST_PRICE": "Free cheapest product",
    "upSelling.promotionType.product.PERCENTAGE_DISCOUNT": "Discount price (%)",
    "upSelling.promotionType.product.FIXED_AMOUNT_DISCOUNT": "Fixed price",
    "upSelling.promotionType.shipping.FIXED_AMOUNT_DISCOUNT": "Discount shipping",
    "upSelling.promotionType.shipping.PERCENTAGE_DISCOUNT": "Discount shipping (%)",
    "upSelling.promotionType.shipping.FREE_SHIPPING": "Free shipping",
  },
  th: {
    "upSelling.title": "AI Up-selling",
    "upSelling.promotionType.product": "โปรโมชั่นสินค้า",
    "upSelling.promotionType.shipping": "โปรโมชั่นค่าส่ง",
    "upSelling.promotionType.product.FREE_GIVEAWAY": "ของแถม",
    "upSelling.promotionType.product.FIXED_PRICE_DISCOUNT": "ลดราคาสินค้า",
    "upSelling.promotionType.product.BUY_X_GET_Y": "ซื้อ...แถม...",
    "upSelling.promotionType.product.FREE_LOWEST_PRICE": "ฟรีสินค้าที่ราคาต่ำที่สุด",
    "upSelling.promotionType.product.PERCENTAGE_DISCOUNT": "ลดราคาสินค้าเป็น %",
    "upSelling.promotionType.product.FIXED_AMOUNT_DISCOUNT": "ตั้งสินค้าราคาพิเศษ",
    "upSelling.promotionType.shipping.FIXED_AMOUNT_DISCOUNT": "ลดค่าส่งสินค้า",
    "upSelling.promotionType.shipping.PERCENTAGE_DISCOUNT": "ลดค่าส่งสินค้าเป็น %",
    "upSelling.promotionType.shipping.FREE_SHIPPING": "ส่งฟรี",
  },
};

export default upSelling;
