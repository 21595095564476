import { gql } from "@apollo/client";

export const ACTIVE_PROMOTIONS = gql`
  query($projectId: ID!) {
    activePromotions(projectId: $projectId) {
      id
      isIncludedAllProducts
      name
      type
      shortName
      totalDiscount
      description
      settings {
        type
        condition
        criteria {
          operator
          thresholds {
            amount
            price
          }
          discount {
            amount
            fixed
            percent
          }
          freeProducts {
            id
            projectId
            productId
            productCode
            image
            selected
            deleted
            isFree
            product {
              id
              name
              productCode
              images {
                src
              }
            }
            productType {
              value
              key {
                key
              }
            }
            amount
            stock
          }
        }
        isBundled
        awardTotalPrice
        awardFromLowToHigh
        threshold {
          amount
          price
        }
        discount {
          amount
          fixed
          percent
        }
        shippingMethod
        isRepeatable
      }
      selectedProducts {
        id
        price
        product {
          id
          name
          type
        }
        productType {
          value
          key {
            key
          }
        }
        digitalContentOptions {
          outputType
          settings {
            availability {
              type
              settings {
                # PERIOD_AFTER_PURCHASED
                # days
                unit
                value
                # DATE_RANGE
                availableAt
                # EXPIRY_DATE
                expiredAt
              }
            }
            content
          }
        }
      }
      freeProducts {
        id
        isFree
        product {
          id
          name
          type
        }
        productType {
          value
          key {
            key
          }
        }
        digitalContentOptions {
          outputType
          settings {
            availability {
              type
              settings {
                # PERIOD_AFTER_PURCHASED
                # days
                unit
                value
                # DATE_RANGE
                availableAt
                # EXPIRY_DATE
                expiredAt
              }
            }
            content
          }
        }
        amount
        stock
      }
    }
  }
`;

export const PROMOTION = gql`
  query promotion($projectId: ID!, $campaignId: ID!, $promotionId: ID!) {
    promotion(projectId: $projectId, campaignId: $campaignId, promotionId: $promotionId) {
      id
      isIncludedAllProducts
      projectId
      campaignId
      name
      shortName
      description
      isActive
      type
      settings {
        type
        condition
        criteria {
          operator
          thresholds {
            amount
            price
          }
          discount {
            amount
            fixed
            percent
          }
          freeProducts {
            id
            projectId
            productId
            productCode
            image
            selected
            deleted
            isFree
            product {
              id
              name
              productCode
              images {
                src
              }
            }
            productType {
              value
              key {
                key
              }
            }
            amount
          }
        }
        isBundled
        threshold {
          amount
          price
        }
        discount {
          amount
          fixed
          percent
        }
        awardTotalPrice
        awardFromLowToHigh
        shippingMethod
        isRepeatable
      }
      selectedProducts {
        id
        projectId
        productId
        productCode
        image
        selected
        deleted
        isFree
        stock
        product {
          id
          name

          productCode
          images {
            src
          }
          categories {
            id
            projectId
            name
            description
            image
            isFree
            isActive
            isDeleted
            properties {
              name
            }
            variations {
              name
            }
          }
          type
        }
        productType {
          value
          key {
            key
          }
        }
      }
      freeProducts {
        id
        projectId
        productId
        productCode
        stock
        image
        selected
        deleted
        isFree
        product {
          id
          name
          productCode
          images {
            src
          }
          categories {
            id
            name
          }
        }
        productType {
          value
          key {
            key
          }
        }
        amount
      }
    }
  }
`;

export const PROMOTION_WITH_NEW_SHIPPING = gql`
  query promotion($projectId: ID!, $campaignId: ID!, $promotionId: ID!) {
    promotion(projectId: $projectId, campaignId: $campaignId, promotionId: $promotionId) {
      id
      isIncludedAllProducts
      projectId
      campaignId
      name
      shortName
      description
      isActive
      type
      settings {
        shippingIds
        type
        condition
        criteria {
          operator
          thresholds {
            amount
            price
          }
          discount {
            amount
            fixed
            percent
          }
          freeProducts {
            id
            projectId
            productId
            productCode
            image
            selected
            deleted
            isFree
            product {
              id
              name
              productCode
              images {
                src
              }
            }
            productType {
              value
              key {
                key
              }
            }
            amount
          }
        }
        isBundled
        threshold {
          amount
          price
        }
        discount {
          amount
          fixed
          percent
        }
        awardTotalPrice
        awardFromLowToHigh
        shippingMethod
        isRepeatable
      }
      selectedProducts {
        id
        projectId
        productId
        productCode
        image
        selected
        deleted
        isFree
        stock
        product {
          id
          name

          productCode
          images {
            src
          }
          categories {
            id
            projectId
            name
            description
            image
            isFree
            isActive
            isDeleted
            properties {
              name
            }
            variations {
              name
            }
          }
          type
        }
        productType {
          value
          key {
            key
          }
        }
      }
      freeProducts {
        id
        projectId
        productId
        productCode
        stock
        image
        selected
        deleted
        isFree
        product {
          id
          name
          productCode
          images {
            src
          }
          categories {
            id
            name
          }
        }
        productType {
          value
          key {
            key
          }
        }
        amount
      }
    }
  }
`;
