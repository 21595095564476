import React, { FC, memo } from "react";
import get from "lodash/get";
import { COLORS } from "constants/Colors";
import { ProjectImg } from "components/SideNav/SideNav.styled";
import Typography from "components/Typography";
import { PermissionType } from "types/User";
import ImagePlaceholder from "./ImagePlaceholder";

type SelectedProjectPropsType = { permission?: PermissionType };

const SelectedProject: FC<SelectedProjectPropsType> = ({ permission = {} }) => {
  const projectLogo = get(permission, "projectLogo", "");

  return (
    <>
      {projectLogo ? <ProjectImg src={projectLogo} /> : <ImagePlaceholder />}
      <Typography variant="title8" color={COLORS.Primary} className="ellipsis">
        {permission ? permission.projectName : "Project name"}
      </Typography>
    </>
  );
};

export default memo(SelectedProject);
