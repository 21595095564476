import * as yup from "yup";
import isBefore from "date-fns/isBefore";
import getYear from "date-fns/getYear";
import creditCardType from "credit-card-type";
import { AllowCardType } from "types/Billing";
import { getExpiredDate } from "utils/creditCard";

const schema = yup.object({
  cardType: yup.string().required(),
  cardNumber: yup
    .string()
    .test("validate card type", "billing.addCreditCardForm.error.cardNumberNotValid", (cardNumber: string) => {
      const possibleCardType = creditCardType(cardNumber);
      const allowCardType = Object.values(AllowCardType);
      return allowCardType.some((type) => possibleCardType.find((possible) => possible.type === type));
    })
    .test("validate card number", "billing.addCreditCardForm.error.cardNumberNotValid", (cardNumber) => {
      const cardNumberReplaceSpace = cardNumber.replace(/[^0-9]+/g, "");
      if (cardNumberReplaceSpace.length !== 16) {
        return false;
      }
      return true;
    }),
  validDate: yup.string().test("validate date", "billing.addCreditCardForm.error.invalidDate", (date: string) => {
    const { month, year } = getExpiredDate(date || "");

    if (year?.length) {
      const currentDate = new Date();
      const currentYear = getYear(currentDate).toString();

      if (currentYear[0] > year[0]) {
        return false;
      }

      const cardExpirationDate = new Date(`20${year}/${month}/1`);
      const isValidDate = isBefore(currentDate, cardExpirationDate);
      return isValidDate; // if error (test fail) should return false
    }
    return false;
  }),
  cvv: yup.string().required("billing.addCreditCardForm.error.cvvRequired").length(3),
  cardHolderName: yup.string().required("billing.addCreditCardForm.error.required.cardHolder"),
});

export default schema;
