import styled from "styled-components";

export type ChatBubblePropsType = {
  isCustomer?: boolean;
  bgColor?: string;
  isReply?: boolean;
};

const ChatBubble = styled.div<ChatBubblePropsType>`
  display: flex;
  max-width: 100%;
  margin: 10px;
  padding: 10px 15px;
  box-sizing: border-box;
  border-radius: ${({ isCustomer, isReply }) => {
    if (isReply) {
      return "10px";
    }
    return isCustomer ? "20px 20px 20px 0" : "20px 20px 0 20px";
  }};
  box-shadow: ${({ isReply }) => {
    if (isReply) {
      return "0px 1px 8px rgba(215, 217, 225, 0.5)";
    }

    return "0 5px 10px 0 rgba(0, 0, 0, 0.03)";
  }};
  border: solid 1px #efefef;
  background-color: ${({ theme, isCustomer, bgColor }) => {
    if (bgColor) {
      return bgColor;
    }
    if (isCustomer) {
      return theme.COLORS.White;
    }
    return theme.COLORS.DarkGray;
  }};
`;

export default ChatBubble;
