import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { Z_INDEX } from "constants/ZIndex";
import { TOP_NAV_HEIGHT_MOBILE, TOP_NAV_HEIGHT_DESKTOP } from "constants/Layout";

type HelpItemWrapperPropsType = {
  isSelected: boolean;
};
type HelpIconWrapperPropsType = {
  isOpen: boolean;
};

export const StyledTopNav = styled.header`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: ${Z_INDEX.TOP_NAV};
  background: ${({ theme }) => theme.COLORS.Dark};
  height: ${TOP_NAV_HEIGHT_MOBILE}px;
  text-align: center;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.5s ease;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    height: ${TOP_NAV_HEIGHT_DESKTOP}px;
    background: ${({ theme }) => theme.COLORS.White};
    border-bottom: solid 1px ${({ theme }) => theme.COLORS.LightBlue};
  }
`;

export const TopNavShadow = styled.div`
  min-height: ${TOP_NAV_HEIGHT_MOBILE}px;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    min-height: ${TOP_NAV_HEIGHT_DESKTOP + 1}px;
  }
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.COLORS.DarkGray};
`;

const menuLink = css`
  background-color: ${({ theme }) => theme.COLORS.LightWhite};
  color: ${({ theme }) => theme.COLORS.DarkGray};
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: 8px;
  padding: 12px 16px;
  margin: 2px 8px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.COLORS.LightGray};
    color: ${({ theme }) => theme.COLORS.Dark};
  }
`;

export const UserMenuLink = styled(Link)`
  ${menuLink}
`;

export const AnchorMenuLink = styled.span`
  ${menuLink}
`;

export const IconWrapper = styled.div`
  width: 20px;
  display: flex;
  justify-content: center;
`;

export const HelpItemWrapper = styled.div<HelpItemWrapperPropsType>`
  width: 100%;
  height: 64px;
  cursor: pointer;
  border-bottom: solid 0.5px #cad1e3;
  display: flex;
  align-items: center;
  padding-left: 30px;
  &:hover {
    background-color: #eff3fc;
    color: ${({ theme }) => theme.COLORS.Primary};
  }
  background-color: ${({ isSelected, theme }) => (isSelected ? theme.COLORS.Primary : theme.COLORS.White)};
  color: ${({ isSelected, theme }) => (isSelected ? theme.COLORS.White : theme.COLORS.Primary)};
`;

export const HelpTitleWrapper = styled.div`
  border-bottom: solid 0.5px #cad1e3;
  width: 100%;
  display: flex;
  padding: 30px 28px;
`;

export const HelpIconWrapper = styled.div<HelpIconWrapperPropsType>`
  cursor: pointer;
  width: 20px;
  display: flex;
  justify-content: center;
  color: ${({ isOpen, theme }) => (isOpen ? theme.COLORS.Primary : theme.COLORS.DarkMed)};
`;

export const GapSpacing = styled.div`
  display: flex;
  width: 97px;
`;
