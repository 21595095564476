import styled from "styled-components";

export const IframeWrapper = styled.div`
  justify-content: center;
  display: flex;
  background: ${({ theme }) => theme.COLORS.Light};
  border: solid 1px ${({ theme }) => theme.COLORS.LightBlue};
  border-radius: 8px;
  padding: 10px 0px;
`;
