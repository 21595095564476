import * as React from "react";

const SvgIcAbandoned = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24 17.778c3.422 0 6.222 2.8 6.222 6.222 0 3.422-2.8 6.222-6.222 6.222-3.422 0-6.222-2.8-6.222-6.222 0-3.422 2.8-6.222 6.222-6.222zM8.22 5.825c.123 3.102 2.667 5.594 5.798 5.594h3.177c3.132 0 5.675-2.492 5.799-5.594h1.71a2.911 2.911 0 012.91 2.91v8.126a8 8 0 00-9.036 13.022H9.377a5.821 5.821 0 01-5.82-5.82V8.735a2.91 2.91 0 012.91-2.91zm19.652 15.271l-6.776 6.776c.83.587 1.832.968 2.904.968A4.854 4.854 0 0028.84 24c0-1.072-.38-2.074-.968-2.904zM24 19.16A4.854 4.854 0 0019.16 24c0 1.072.38 2.074.968 2.904l6.776-6.776c-.83-.587-1.832-.968-2.904-.968zM18.652 1.778a2.914 2.914 0 012.91 2.91v.91c0 .077-.019.151-.023.227-.123 2.299-2.014 4.138-4.343 4.138h-3.178c-2.328 0-4.22-1.84-4.342-4.138-.004-.076-.023-.15-.023-.227v-.91a2.914 2.914 0 012.91-2.91zm0 2.91h-6.09l.001.91c0 .08.034.151.047.227.113.69.686 1.228 1.408 1.228h3.178c.722 0 1.295-.537 1.409-1.228.013-.076.047-.147.047-.227v-.91z"
    />
  </svg>
);

export default SvgIcAbandoned;
