import React, { FC, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import capitalize from "lodash/capitalize";
import { RadioBox } from "components/Radio";
import Grid from "components/Grid";
import Typography from "components/Typography";
import { BOT_GENDERS } from "constants/Setting";
import { Gender } from "types/AISetting";
import Card from "components/Card";

type NewBotSettingFormPropsType = {
  botGender?: Gender;
  botTimeToResume: number;
  isBotTimerActive: boolean;
  searchProductsOrder?: string;
};

const NewBotSettingForm: FC<NewBotSettingFormPropsType> = (props) => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const { botGender = Gender.MALE } = props;

  const renderBotGender = useCallback(
    ({ onChange, value }) => (
      <>
        {BOT_GENDERS.map((gender, index) => {
          const className = index === 0 ? "pr-2" : "pl-2";
          return (
            <Grid item xs={6} md="auto" key={gender} className={`pb-3 ${className}`}>
              <RadioBox title={t(capitalize(gender))} isActive={value === gender} onClick={() => onChange(gender)} />
            </Grid>
          );
        })}
      </>
    ),
    [t],
  );

  return (
    <Card className="w-100 mx-3">
      <Grid container xs={12} className="p-0">
        <Grid item xs={12}>
          <Typography variant="title2" className="mb-2">
            {t("Bot gender")}
          </Typography>
          <Typography variant="body3" className="mt-2">
            {t("BOT_GENDER_HELPER_TEXT")}
          </Typography>
        </Grid>
        <Grid item container xs={12} className="pt-3">
          <Controller control={control} name="botGender" defaultValue={botGender} render={renderBotGender} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default memo(NewBotSettingForm);
