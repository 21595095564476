import * as yup from "yup";
import { PHONE_REG_EXP_REQUIRED, TAX_ID_REG_EXP } from "constants/RegExp";

const defaultBillingShape = {
  image: yup.string().trim(),
  name: yup.string().trim().required("planBilling.info.error.name.required"),
  address: yup.string().trim().required("planBilling.info.error.address.required"),
  postalCode: yup
    .string()
    .trim()
    .required("planBilling.info.error.postalCode.required")
    .matches(/^[1-9][0-9]{4}$/, "planBilling.info.error.postalCode.invalid"),
  subDistrict: yup.string().trim().required("planBilling.info.error.subDistrict.required"),
  district: yup.string().trim().required("planBilling.info.error.district.required"),
  province: yup.string().trim().required("planBilling.info.error.province.required"),
  email: yup
    .string()
    .trim()
    .required("planBilling.info.error.email.required") // please fill ...
    .email("planBilling.info.error.email.format"), // "Please enter your email address in format yourname@work.com"
  taxId: yup
    .string()
    .matches(TAX_ID_REG_EXP, "planBilling.info.error.taxId.format")
    .required("planBilling.info.error.taxId.required"),
  phoneNumber: yup.string().matches(PHONE_REG_EXP_REQUIRED, "planBilling.info.error.phoneNumber.format"),
};

// this validation is for new billing page
const PersonalBillingInfoSchema = yup.object().shape({
  titleName: yup.string().trim().required("planBilling.info.error.titleName.required"),
  ...defaultBillingShape,
});

const CompanyBillingInfoSchema = yup.object().shape(defaultBillingShape);

const updateBillingInfoSchema = yup.object().shape({
  name: yup.string().trim().required("planBilling.info.error.companyName.required"),
  address: yup.string().trim().required("planBilling.info.error.address.required"),
  email: yup
    .string()
    .trim()
    .required("planBilling.info.error.email.required") // please fill ...
    .email("planBilling.info.error.email.format"), // "Please enter your email address in format yourname@work.com"
  taxId: yup.string().matches(TAX_ID_REG_EXP, "planBilling.info.error.taxId.format"),
  phoneNumber: yup.string().matches(PHONE_REG_EXP_REQUIRED, "planBilling.info.error.phoneNumber.format"),
});

export { updateBillingInfoSchema, PersonalBillingInfoSchema, CompanyBillingInfoSchema };
