import React from "react";
import { MultiValueProps } from "react-select";
import { MultiCustomerTag } from "domain/Chat/ContactsList/ContactDetailList/ContactDetail/styled";
import { TagOption } from "types/Chat";

export const TagMultiValue = (props: MultiValueProps<TagOption>) => {
  const { data } = props;

  return (
    <MultiCustomerTag {...props} type={data.type}>
      {data.label}
    </MultiCustomerTag>
  );
};

export default TagMultiValue;
