import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { UPDATE_CATEGORY } from "graphql/category/mutation";
import { CategoryType } from "types/Category";
import { notifyError, notifySuccess } from "utils/notify";
import mapErrorMessage from "../mapErrorMessage";

const useUpdateCategory = (projectId: string, handleClearEditedCategory: (id: string) => void) => {
  const { t } = useTranslation();
  const [updateCategory] = useMutation(UPDATE_CATEGORY, {
    onError: (err) => {
      const gqlErrorMessage: string = err?.graphQLErrors?.[0]?.message || "";
      const errorMessage = mapErrorMessage(gqlErrorMessage);

      notifyError(t(errorMessage));
    },
  });

  const handleUpdateCategory = async (id: string, editedCategory?: Partial<CategoryType>, callback?: Function) => {
    if (editedCategory) {
      const updatedCategory = await updateCategory({
        variables: {
          projectId,
          id,
          category: editedCategory,
        },
      });

      const updatedCategoryName = updatedCategory?.data?.updateCategory?.name;
      handleClearEditedCategory(id);

      if (callback) {
        callback();
      }

      // toast
      if (updatedCategoryName) {
        if (editedCategory.isActive != null) {
          notifySuccess(
            t("category.toggle", {
              name: updatedCategoryName,
              isActive: editedCategory.isActive ? t("Active") : t("Disabled"),
            }),
          );
        } else {
          notifySuccess(t("category.save", { name: updatedCategoryName }));
        }
      }
    } else if (callback) {
      callback();
    }
  };

  return { handleUpdateCategory };
};

export default useUpdateCategory;
