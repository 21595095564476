import { useTranslation } from "react-i18next";
import { useMutation, ApolloError } from "@apollo/client";

import { toast } from "react-toastify";

import { CUSTOMER_TAG } from "graphql/customer/query";
import { DELETE_CUSTOMER_TAG } from "graphql/customer/mutation";
import { CustomerTagVariablesType } from "types/Customer";

type UseDeleteCustomerTagPropsType = {
  variables: CustomerTagVariablesType;
  projectId: string;
  onComplete: () => void;
};

const useDeleteCustomerTag = (props: UseDeleteCustomerTagPropsType) => {
  const { variables, projectId } = props;
  const { t } = useTranslation();

  const [deleteTag] = useMutation(DELETE_CUSTOMER_TAG, {
    refetchQueries: [{ query: CUSTOMER_TAG, variables }],
    onError: (error: ApolloError) => {
      toast.error(`${t("Update failed!")}, ${error}`, {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 3000,
      });
    },
    onCompleted: () => {},
  });

  const handleDeleteTag = (customerTagId: string) => {
    deleteTag({
      variables: {
        projectId,
        customerTagId,
      },
    });
  };

  return {
    handleDeleteTag,
  };
};

export default useDeleteCustomerTag;
