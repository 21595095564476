import React, { FC } from "react";
import { OptionType } from "react-select";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import get from "lodash/get";
import { RICH_MENU } from "constants/i18n";
import Typography from "components/Typography";
import Select from "components/Select";
import { ActionType, LinkValue, Action, TextValue, DeepleAction } from "types/RichMenu";
import Option from "./Option";
import SingleValue from "./SingleValue";
import { ACTION_OPTIONS, DEEPLE_ACTION_OPTIONS } from "./config";
import { StyledTextField } from "./styled";

type DropdownProps = {
  defaultValue?: Action;
  name: string;
  isDisabled?: boolean;
  options?: OptionType[];
};

const Dropdown: FC<DropdownProps> = ({ defaultValue, name, isDisabled, options }) => {
  const { t } = useTranslation(RICH_MENU);
  const { control, watch, setValue, errors } = useFormContext();
  const watchedActionType = watch(`${name}.actionType`, defaultValue?.actionType);
  const errorData = get(errors, name);
  const defaultOptions = options || ACTION_OPTIONS;

  return (
    <div className="w-100">
      <Controller
        control={control}
        name={`${name}.actionType`}
        defaultValue={defaultValue?.actionType}
        render={(field) => (
          <div className="mb-3">
            <Typography variant="body3" color="darkMed" className="mb-2">
              {t("ACTION_TYPE")}
            </Typography>
            <Select
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              components={{ Option: Option as any, SingleValue: SingleValue as any }}
              placeholder={t("SELECT")}
              isDisabled={isDisabled}
              options={defaultOptions}
              defaultValue={defaultOptions.find((option) => option.value === field.value)}
              onChange={(option) => {
                const value = (option as OptionType).value as ActionType;
                switch (value) {
                  case ActionType.TEXT:
                    setValue(`${name}.value`, { text: "" });
                    break;

                  case ActionType.URL:
                    setValue(`${name}.value`, {
                      url: "",
                      description: "",
                    });
                    break;

                  case ActionType.DEEPLE_ACTION:
                    setValue(`${name}.value`, DeepleAction.DEEPLE_PRODUCT_CATALOG);
                    break;

                  default:
                    setValue(`${name}.value`, "");
                    break;
                }

                field.onChange((option as OptionType).value);
              }}
            />
          </div>
        )}
      />

      {watchedActionType === ActionType.TEXT && (
        <Controller
          control={control}
          name={`${name}.value.text`}
          defaultValue={(defaultValue?.value as TextValue)?.text || ""}
          render={({ onChange }) => (
            <StyledTextField
              disabled={isDisabled}
              multiline
              rows={4}
              fullWidth
              onChange={(event) => onChange(event.target.value)}
              defaultValue={(defaultValue?.value as TextValue)?.text || ""}
              variant="outlined"
              placeholder={t("ENTER_TEXT")}
              error={Boolean(errorData?.value?.text)}
              helperText={t(errorData?.value?.text?.message)}
              className="mb-3"
              label={t("MESSAGE")}
            />
          )}
        />
      )}

      {watchedActionType === ActionType.URL && (
        <>
          <Controller
            control={control}
            name={`${name}.value.url`}
            defaultValue={(defaultValue?.value as LinkValue).url || ""}
            render={({ onChange }) => (
              <StyledTextField
                fullWidth
                disabled={isDisabled}
                defaultValue={(defaultValue?.value as LinkValue).url || ""}
                variant="outlined"
                placeholder={t("ENTER_URL")}
                label={t("URL")}
                className="mb-3"
                onChange={(event) => onChange(event.target.value)}
                error={Boolean(errorData?.value?.url)}
                helperText={t(errorData?.value?.url?.message)}
              />
            )}
          />
          <Controller
            control={control}
            name={`${name}.value.description`}
            defaultValue={(defaultValue?.value as LinkValue).description || ""}
            render={({ onChange }) => (
              <StyledTextField
                multiline
                disabled={isDisabled}
                rows={4}
                fullWidth
                defaultValue={(defaultValue?.value as LinkValue).description || ""}
                onChange={(event) => onChange(event.target.value)}
                variant="outlined"
                placeholder={t("ENTER_DESCRIPTION")}
                label={t("DESCRIPTION")}
                className="mb-3"
                error={Boolean(errorData?.value?.description)}
                helperText={t(errorData?.value?.description?.message)}
              />
            )}
          />
        </>
      )}

      {watchedActionType === ActionType.DEEPLE_ACTION && (
        <Controller
          control={control}
          name={`${name}.value`}
          defaultValue={(defaultValue?.value as DeepleAction) || DeepleAction.DEEPLE_PRODUCT_CATALOG}
          render={(field) => {
            return (
              <>
                <Select
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  components={{ Option: Option as any, SingleValue: SingleValue as any }}
                  placeholder={t("SELECT")}
                  isDisabled={isDisabled}
                  options={DEEPLE_ACTION_OPTIONS}
                  defaultValue={DEEPLE_ACTION_OPTIONS.find((option) => option.value === defaultValue?.value)}
                  onChange={(option) => {
                    field.onChange((option as OptionType).value);
                  }}
                />

                {Boolean(errorData?.value) && (
                  <Typography variant="body3" color="error" className="mt-2 ml-2">
                    {errorData?.value?.message}
                  </Typography>
                )}
              </>
            );
          }}
        />
      )}
    </div>
  );
};

export default Dropdown;
