import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { OptionType, ValueType } from "react-select";
import { useFormContext } from "react-hook-form";

import Select from "components/Select";
import Grid from "components/Grid";
import Card from "components/Card";
import Typography from "components/Typography";
import ErrorText from "components/ErrorText";
import { RadioBox } from "components/Radio";
import { InventoryIntegrationSyncMethodType } from "types/Integration";

type SyncingMethodFormProps = {
  selectedSyncingMethod: InventoryIntegrationSyncMethodType;
  selectedWarehouse?: OptionType;
  warehouseOptions: OptionType[];
  onSelectWarehouse: (newWarehouse: OptionType) => void;
  onSelectSyncingMethod: (newSyncingMethod: InventoryIntegrationSyncMethodType) => void;
};

const SyncingMethodForm: FC<SyncingMethodFormProps> = (props) => {
  const { t } = useTranslation();
  const { errors, control } = useFormContext();

  const {
    selectedWarehouse,
    warehouseOptions,
    onSelectWarehouse,
    selectedSyncingMethod,
    onSelectSyncingMethod,
  } = props;

  const handleSelectWarehouse = (warehouse: ValueType<OptionType>) => {
    if (warehouse) {
      onSelectWarehouse(warehouse as OptionType);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card noShadow padding="24px" fullWidth>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="title2" color="dark">
                {t("inventoryIntegration.syncingMethodForm.title")}
              </Typography>
            </Grid>
            <Grid item xs={12} className="pt-4">
              <Typography variant="body3" color="darkMed" className="pb-2">
                {t("inventoryIntegration.syncingMethodForm.warehouse.label")}
              </Typography>
              <Select
                control={control}
                name="warehouses"
                defaultValue={selectedWarehouse}
                className="mt-2"
                placeholder={t("inventoryIntegration.syncingMethodForm.warehouse.label")}
                options={warehouseOptions}
                onChange={handleSelectWarehouse}
                fullWidth
              />
            </Grid>
            {errors.warehouse && (
              <Grid item xs={12} className="m-1">
                <ErrorText>{t(errors.warehouse.message as string)}</ErrorText>
              </Grid>
            )}
            <Grid item xs={12} className="pt-4">
              <Typography variant="body3" color="darkMed" className="pb-2">
                {t("inventoryIntegration.syncingMethodForm.title")}
              </Typography>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <RadioBox
                    className="h-100"
                    title={t("inventoryIntegration.syncingMethodForm.replace.title")}
                    description={t("inventoryIntegration.syncingMethodForm.replace.description")}
                    isActive={selectedSyncingMethod === InventoryIntegrationSyncMethodType.REPLACE}
                    onClick={() => onSelectSyncingMethod(InventoryIntegrationSyncMethodType.REPLACE)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RadioBox
                    className="h-100"
                    title={t("inventoryIntegration.syncingMethodForm.merge.title")}
                    description={t("inventoryIntegration.syncingMethodForm.merge.description")}
                    isActive={selectedSyncingMethod === InventoryIntegrationSyncMethodType.MERGE}
                    onClick={() => onSelectSyncingMethod(InventoryIntegrationSyncMethodType.REPLACE)}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SyncingMethodForm;
