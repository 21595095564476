import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";

import CircularProgress from "components/CircularProgress";
import Typography from "components/Typography";
import { ModalConfig } from "../config";
import { useStyles } from "../styled";

type LoadingModalPropsType = {
  isOpen: boolean;
  description?: React.ReactNode;
  className?: string;
};

const Loading: FC<LoadingModalPropsType> = (props) => {
  const defaultClass = useStyles();
  const { isOpen, description, ...rest } = props;

  return (
    <Modal {...ModalConfig} className={`${defaultClass.modal}`} open={isOpen} onClose={() => {}} {...rest}>
      <Fade in={isOpen}>
        <Box className={defaultClass.paper}>
          <Box className="text-center">
            <CircularProgress className="m-2" />
            {description && (
              <Typography variant="body3" className="text-center px-4 py-2">
                {description}
              </Typography>
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

Loading.displayName = "InformationModal";

export default Loading;
