import React, { FC, ChangeEventHandler } from "react";
import { toast } from "react-toastify";
import { ImageUrl } from "types/Image";
import { useTranslation } from "react-i18next";
import ActionMenus, { ActionType } from "./ActionMenus";
import SendMessageButton from "./SendMessageButton";
import { Wrapper, ActionWrapper, TextInput } from "./styled";
import { MessageInputVariant } from "../type";
import SelectProduct, { SelectProductPropsType } from "../SelectProduct";
import SelectCategories, { SelectCategoriesPropsType } from "../SelectCategories";

type MessageInputProps = {
  actionType: ActionType;
  isDisabled?: boolean;
  iconColorSubmit?: string;
  message?: string;
  messageInputVariant?: MessageInputVariant;
  onChangeActionType: (actionType: ActionType) => void;
  onChangeImage?: (imageUrl: ImageUrl[]) => void;
  onChangeMessage?: ChangeEventHandler<HTMLInputElement>;
  onSubmit?: () => void;
  placeholder?: string;
  projectId?: string;
} & SelectProductPropsType &
  SelectCategoriesPropsType;

const PRODUCT_SELECTOR_ID = "productSelector";
const CATEGORY_SELECTOR_ID = "categoriesSelector";
const MAX_CHARACTER_LENGTH_OF_ANSWER = 2000;

const MessageInput: FC<MessageInputProps> = ({
  actionType,
  activePromotionsResults,
  categoriesOptions,
  categoriesResults,
  convertCategoriesValueToOptionFormat,
  convertProductValueToOptionFormat,
  isDisabled = false,
  iconColorSubmit,
  isCategoriesLoading,
  isProductsLoading,
  message = "",
  messageInputVariant,
  onChangeActionType,
  onChangeImage,
  onChangeMessage = () => {},
  onSubmit,
  placeholder = "",
  productOptions,
  productResults,
  projectId,
  selectedCategories,
  selectedProducts,
  setSelectedCategories,
  setSelectedProducts,
}) => {
  const { t } = useTranslation();
  const clickElementById = (id: string) => {
    const categoriesSelectorDOM = document.getElementById(id);

    if (categoriesSelectorDOM) {
      categoriesSelectorDOM.click();
    }
  };

  const handleOnChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > MAX_CHARACTER_LENGTH_OF_ANSWER) {
      toast.error(t("Exceed character limit"), {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 2000,
        onClose: () => {},
      });
      return;
    }
    onChangeMessage(event);
  };

  return (
    <Wrapper variant={messageInputVariant}>
      {(actionType === ActionType.TEXT || actionType === ActionType.IMAGE) && (
        <TextInput
          fullWidth
          disabled={isDisabled || actionType === ActionType.IMAGE}
          multiline
          value={message}
          placeholder={placeholder}
          onChange={handleOnChangeMessage}
        />
      )}

      {actionType === ActionType.PRODUCT && (
        <div className="p-3">
          <SelectProduct
            id={PRODUCT_SELECTOR_ID}
            isProductsLoading={isProductsLoading}
            productOptions={productOptions}
            productResults={productResults}
            convertProductValueToOptionFormat={convertProductValueToOptionFormat}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            activePromotionsResults={activePromotionsResults}
          />
        </div>
      )}

      {actionType === ActionType.CATEGORY && (
        <div className="p-3">
          <SelectCategories
            id={CATEGORY_SELECTOR_ID}
            isCategoriesLoading={isCategoriesLoading}
            categoriesOptions={categoriesOptions}
            categoriesResults={categoriesResults}
            convertCategoriesValueToOptionFormat={convertCategoriesValueToOptionFormat}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
          />
        </div>
      )}

      <ActionWrapper>
        <ActionMenus
          projectId={projectId}
          type={actionType}
          isDisabled={isDisabled}
          onChangeImage={(urlImage) => {
            if (isDisabled) return;
            if (onChangeImage) onChangeImage(urlImage);
            onChangeActionType(ActionType.TEXT);
          }}
          onClickImage={() => {
            if (isDisabled) return;
            onChangeActionType(ActionType.IMAGE);
          }}
          onClickCategory={() => {
            if (isDisabled) return;
            onChangeActionType(ActionType.CATEGORY);
            setTimeout(() => {
              clickElementById(CATEGORY_SELECTOR_ID);
            }, 200);
          }}
          onClickProduct={() => {
            if (isDisabled) return;
            onChangeActionType(ActionType.PRODUCT);
            setTimeout(() => {
              clickElementById(PRODUCT_SELECTOR_ID);
            }, 200);
          }}
          onClickTyping={() => {
            if (isDisabled) return;
            onChangeActionType(ActionType.TEXT);
          }}
        />
        <SendMessageButton
          iconColor={iconColorSubmit}
          onClick={() => {
            if (isDisabled) return;
            if (onSubmit) onSubmit();
          }}
        />
      </ActionWrapper>
    </Wrapper>
  );
};

export default MessageInput;
