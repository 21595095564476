export enum NotificationType {
  CHAT = "CHAT",
  CHECKOUT = "CHECKOUT",
  INVENTORY = "INVENTORY",
  ORDER = "ORDER",
  SYSTEM = "SYSTEM",
}

export enum NotificationName {
  ABANDONED_ORDER = "ABANDONED_ORDER",
  ADMIN_CONTACTING_REQUEST = "ADMIN_CONTACTING_REQUEST",
  ADMIN_ENGLISH_SPEAKER_REQUEST = "ADMIN_ENGLISH_SPEAKER_REQUEST",
  AWAITING_PAYMENT_APPROVAL = "AWAITING_PAYMENT_APPROVAL",
  BANK_SLIP_WITHOUT_ORDER = "BANK_SLIP_WITHOUT_ORDER",
  BOT_CANNOT_RESPOND = "BOT_CANNOT_RESPOND",
  CHECKOUT_ON_WEBVIEW = "CHECKOUT_ON_WEBVIEW",
  COD_AWAITING_PAYMENT_APPROVAL = "COD_AWAITING_PAYMENT_APPROVAL",
  COD_NEW_ORDER = "COD_NEW_ORDER",
  COD_READY_TO_SHIP = "COD_READY_TO_SHIP",
  ORDER_PAID_WITH_DEEPLE_PAY = "ORDER_PAID_WITH_DEEPLE_PAY",
  DELIVERY_METHOD_SELECTION = "DELIVERY_METHOD_SELECTION",
  DELIVERY_PERIOD_SELECTION = "DELIVERY_PERIOD_SELECTION", // Schedule
  DIRECT_MESSAGE = "DIRECT_MESSAGE",
  FILLING_ADDRESS = "FILLING_ADDRESS",
  FULL_TAX_INVOICE = "FULL_TAX_INVOICE",
  LALAMOVE_SHIPMENT_EXPIRED_STATUS = "LALAMOVE_SHIPMENT_EXPIRED_STATUS",
  LALAMOVE_SHIPMENT_REJECTED_STATUS = "LALAMOVE_SHIPMENT_REJECTED_STATUS",
  LOW_PRODUCT_QUANTITY = "LOW_PRODUCT_QUANTITY",
  NEW_CUSTOMER = "NEW_CUSTOMER",
  NEW_ORDER = "NEW_ORDER", // Order placed
  OUT_OF_STOCK = "OUT_OF_STOCK",
  PENDING_FOR_SHIPPING_COST = "PENDING_FOR_SHIPPING_COST",
  POTENTIAL_CUSTOMER = "POTENTIAL_CUSTOMER",
  PROCEEDING_TO_CHECKOUT = "PROCEEDING_TO_CHECKOUT",
  SHIPPING_METHOD_SELECTION = "SHIPPING_METHOD_SELECTION",
  SPECIAL_KNOWLEDGE = "SPECIAL_KNOWLEDGE",
}

export type NotificationConfigurationType = {
  name: NotificationName;
  type: NotificationType;
  isActive: boolean;
  isSelectable: boolean;
  priority: number;
};

export type GroupNotificationConfigurationType = {
  type: NotificationType;
  notification: NotificationConfigurationType[];
};

export type NotificationConfigurationQueryType = {
  notificationConfigurations: NotificationConfigurationType[];
};

export type NotificationInfo = {
  id: string;
  createdAt: string;
  customer: {
    id: string;
    name: string;
    pictureUrl: string;
    platform: string;
    projectId: string;
    uuid: string;
  };
  payload: {
    action: string;
    body: string;
    title: string;
  };
  readAt: string | null;
  type: NotificationType;
  userId: string;
};

export enum NotificationNameOptions {
  DELIVERY_METHOD_SELECTION = "DELIVERY_METHOD_SELECTION",
  FILLING_ADDRESS = "FILLING_ADDRESS",
  SHIPPING_METHOD_SELECTION = "SHIPPING_METHOD_SELECTION",
  DELIVERY_PERIOD_SELECTION = "DELIVERY_PERIOD_SELECTION", // Schedule
  // CHECKOUT_ON_WEBVIEW = "CHECKOUT_ON_WEBVIEW", // ยังไม่ทำ
  PENDING_FOR_SHIPPING_COST = "PENDING_FOR_SHIPPING_COST",
  NEW_ORDER = "NEW_ORDER", // Order placed
  AWAITING_PAYMENT_APPROVAL = "AWAITING_PAYMENT_APPROVAL",
  ABANDONED_ORDER = "ABANDONED_ORDER",
  COD_NEW_ORDER = "COD_NEW_ORDER",
  COD_AWAITING_PAYMENT_APPROVAL = "COD_AWAITING_PAYMENT_APPROVAL",
  COD_READY_TO_SHIP = "COD_READY_TO_SHIP",
  NEW_CUSTOMER = "NEW_CUSTOMER",
  SPECIAL_KNOWLEDGE = "SPECIAL_KNOWLEDGE",
  BOT_CANNOT_RESPOND = "BOT_CANNOT_RESPOND",
  ADMIN_CONTACTING_REQUEST = "ADMIN_CONTACTING_REQUEST",
  DIRECT_MESSAGE = "DIRECT_MESSAGE",
  FULL_TAX_INVOICE = "FULL_TAX_INVOICE",
  // LOW_PRODUCT_QUANTITY = "LOW_PRODUCT_QUANTITY", // ยังไม่ทำ
  // OUT_OF_STOCK = "OUT_OF_STOCK", // ยังไม่ทำ

  ADMIN_ENGLISH_SPEAKER_REQUEST = "ADMIN_ENGLISH_SPEAKER_REQUEST",
  BANK_SLIP_WITHOUT_ORDER = "BANK_SLIP_WITHOUT_ORDER",
  POTENTIAL_CUSTOMER = "POTENTIAL_CUSTOMER",
  ORDER_PAID_WITH_DEEPLE_PAY = "ORDER_PAID_WITH_DEEPLE_PAY",
  LALAMOVE_SHIPMENT_REJECTED_STATUS = "LALAMOVE_SHIPMENT_REJECTED_STATUS",
  LALAMOVE_SHIPMENT_EXPIRED_STATUS = "LALAMOVE_SHIPMENT_EXPIRED_STATUS",
}
