// This component that is imported from this package => https://github.com/bonafideduck/react-highlight-within-textarea
// It under beta test so I copy and fix their code to work with our project.
// I will convert to typescript later

export default function getType(instance) {
  const type = typeof instance;

  if (!instance) {
    return "falsey";
  }
  if (Array.isArray(instance)) {
    if (instance.length === 2 && typeof instance[0] === "number" && typeof instance[1] === "number") {
      return "range";
    }
    return "array";
  }
  if (type === "object") {
    if (instance instanceof RegExp) {
      return "regexp";
    }
    if (instance.hasOwnProperty("highlight")) {
      return "custom";
    }
  } else if (type === "function") {
    return "strategy";
  } else if (type === "string") {
    return type;
  }

  return "other";
}
