import { BANK_COLORS } from "./Colors";

export enum BANKS {
  BAY = "BAY",
  BBL = "BBL",
  CIMB = "CIMB",
  CITI = "CITI",
  GSB = "GSB",
  KBANK = "KBANK",
  KTB = "KTB",
  LH = "LH",
  SCB = "SCB",
  THANACHART = "THANACHART",
  TMB = "TMB",
  TTB = "TTB",
  UOB = "UOB",
}

type BankInfoType = {
  name: string;
  color: string;
  fullName: string;
};

type BankConfigType = {
  [name in BANKS]: BankInfoType;
};

export const BANKS_CONFIG: BankConfigType = {
  SCB: {
    name: BANKS.SCB,
    fullName: "Siam Commercial Bank",
    color: BANK_COLORS.SCB,
  },
  KBANK: {
    name: BANKS.KBANK,
    fullName: "Kasikorn Bank",
    color: BANK_COLORS.KBANK,
  },
  BBL: {
    name: BANKS.BBL,
    fullName: "Bangkok Bank",
    color: BANK_COLORS.BBL,
  },
  CIMB: {
    name: BANKS.CIMB,
    fullName: "CIMB Thai Bank",
    color: BANK_COLORS.CIMB,
  },
  CITI: {
    name: BANKS.CITI,
    fullName: "Citibank",
    color: BANK_COLORS.CITI,
  },
  GSB: {
    name: BANKS.GSB,
    fullName: "Government Savings Bank",
    color: BANK_COLORS.GSB,
  },
  KTB: {
    name: BANKS.KTB,
    fullName: "Krungthai Bank",
    color: BANK_COLORS.KTB,
  },
  TMB: {
    name: BANKS.TMB,
    fullName: "TMB Bank",
    color: BANK_COLORS.TMB,
  },
  UOB: {
    name: BANKS.UOB,
    fullName: "United Overseas Bank",
    color: BANK_COLORS.UOB,
  },
  BAY: {
    name: BANKS.BAY,
    fullName: "Bank of Ayudhya (Krungsri)",
    color: BANK_COLORS.BAY,
  },
  THANACHART: {
    name: BANKS.THANACHART,
    fullName: "Thanachart Bank",
    color: BANK_COLORS.THANACHART,
  },
  TTB: {
    name: BANKS.TTB,
    fullName: "TMBThanachart Bank",
    color: BANK_COLORS.TTB,
  },
  LH: {
    name: BANKS.LH,
    fullName: "Land and Houses Bank",
    color: BANK_COLORS.LH,
  },
};
