import * as React from "react";

const SvgIcReport = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m31.04435,16.992872c-0.470268,7.580719 -6.523557,13.634008 -14.104276,14.104276l0,-5.707172c4.444973,-0.451457 7.947528,-3.952132 8.397104,-8.397104l5.707172,0zm-24.389977,0c0.451457,4.444973 3.952132,7.947528 8.397104,8.397104l0,5.707172c-7.580719,-0.470268 -13.634008,-6.523557 -14.104276,-14.104276l5.707172,0zm5.673312,-0.927368c0.310377,0 0.564322,0.253945 0.564322,0.564322l0,3.893819c0,0.312258 -0.253945,0.564322 -0.564322,0.564322l-1.429615,0c-0.310377,0 -0.564322,-0.252064 -0.564322,-0.564322l0,-3.891937c0,-0.310377 0.253945,-0.564322 0.564322,-0.564322l1.429615,0l0,-0.001881zm4.717728,-1.674154c0.310377,0 0.564322,0.253945 0.564322,0.564322l0,5.567972c0,0.312258 -0.253945,0.564322 -0.564322,0.564322l-1.429615,0c-0.310377,0 -0.564322,-0.252064 -0.564322,-0.564322l0,-5.566091c0,-0.310377 0.253945,-0.564322 0.564322,-0.564322l1.429615,0l0,-0.001881zm4.715847,-3.348308c0.312258,0 0.564322,0.253945 0.564322,0.564322l0,8.91628c0,0.312258 -0.252064,0.564322 -0.564322,0.564322l-1.427733,0c-0.310377,0 -0.564322,-0.252064 -0.564322,-0.564322l0,-8.914399c0,-0.310377 0.253945,-0.564322 0.564322,-0.564322l1.429615,0l-0.001881,-0.001881zm-6.709783,-10.043042l0,5.707172c-4.444973,0.451457 -7.947528,3.952132 -8.397104,8.397104l-5.707172,0c0.470268,-7.580719 6.523557,-13.634008 14.104276,-14.104276zm1.888596,0c7.580719,0.470268 13.634008,6.523557 14.104276,14.104276l-5.707172,0c-0.451457,-4.444973 -3.952132,-7.947528 -8.397104,-8.397104l0,-5.707172z"
    />
  </svg>
);

export default SvgIcReport;
