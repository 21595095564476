import * as React from "react";

const SvgIcArrowNext = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.67 16l-5.646-5.645a1.38 1.38 0 111.95-1.951l6.621 6.62a1.38 1.38 0 010 1.951l-6.62 6.621a1.38 1.38 0 01-1.951-1.95L18.669 16z"
    />
  </svg>
);

export default SvgIcArrowNext;
