export const scgYamatoExpressDescription = {
  EN: null,
  TH: [
    { body: "พิมพ์ใบปะหน้า และแปะลงบนกล่องพัสดุ" },
    { body: "เข้ารับสินค้าตั้งแต่ 1 ชิ้นขึ้นไป ในพื้นที่ กทม.และปริมณฑล พื้นที่เข้ารับกับจัดส่งของ SCG" },
    { body: "ชำระค่าบริการก่อน 12.00 น. รับสินค้าวันเดียวกัน หลัง 12.01 น. เข้ารับสินค้าวันถัดไป" },
    { body: "สามารถเข้ารับสินค้าขนาดกล่อง ( กว้าง + ยาว + สูง ) ไม่เกิน 200 เซนติเมตร" },
    {
      body:
        "หากขนาดพัสดุมีขนาดเกินกำหนด หรือระบุมาไม่ถูกต้อง จะมีการเรียกเก็บเงินค่าขนส่งเพิ่มเติม แต่ไม่นำหนักเกิน 25 กิโลกรัม",
    },
    { body: "หากสินค้ามีการตีคืนจะมีการเรียกเก็บเงินภายหลัง" },
  ],
};
