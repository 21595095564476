const aiSetting = {
  en: {
    "Welcome message": "Welcome message",
    "Message box": "Message box",
    "Are you sure you want to remove message no.": "Are you sure you want to remove message no.",
    "Message here": "Message here",
  },
  th: {
    "Welcome message": "ข้อความต้อนรับ",
    "Message box": "กล่องข้อความ",
    "Are you sure you want to remove message no.": "คุณต้องการที่จะลบข้อความที่",
    "Message here": "ข้อความ...",
  },
};

export default aiSetting;
