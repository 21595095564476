import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import Typography from "components/Typography";
import Modal from "components/Modal";
import { RadioBox } from "components/Radio";
import ImportProductButton from "domain/Product/ImportProductButton";
import { UpdateInventoryType } from "types/Product";

type ImportMethodSelectorModalProps = {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSelect: (type: UpdateInventoryType) => void;
  onSubmit: (file: File) => void;
  subTitle: string;
  title: string;
  type: UpdateInventoryType;
};

const ImportTypeSelectorModal: FC<ImportMethodSelectorModalProps> = ({
  isLoading,
  isOpen,
  onClose,
  onSelect,
  onSubmit,
  subTitle,
  title,
  type,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-3">
        <Typography variant="title2" color="dark" className="mb-3">
          {title}
        </Typography>

        <Typography variant="body3" color="darkMed">
          {subTitle}
        </Typography>

        <RadioBox
          className="mb-3"
          type="button"
          title={t("ADD_INVENTORY")}
          description={t("ADD_INVENTORY_DETAIL")}
          isActive={type === UpdateInventoryType.ADD}
          onClick={() => {
            onSelect(UpdateInventoryType.ADD);
          }}
        />

        <RadioBox
          className="mb-3"
          type="button"
          title={t("REPLACE_INVENTORY")}
          description={t("REPLACE_INVENTORY_DETAIL")}
          isActive={type === UpdateInventoryType.REPLACE}
          onClick={() => {
            onSelect(UpdateInventoryType.REPLACE);
          }}
        />

        <ImportProductButton
          isFileType
          variantTitle="title4"
          fullWidth
          label={t("IMPORT")}
          onChange={onSubmit}
          isLoading={isLoading}
        />
      </div>
    </Modal>
  );
};

export default memo(ImportTypeSelectorModal);
