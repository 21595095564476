import React from "react";
import { useTranslation } from "react-i18next";
import { components, OptionProps, OptionType } from "react-select";
import { RICH_MENU } from "constants/i18n";

const Option = (optionProps: OptionProps<OptionType>) => {
  const { t } = useTranslation(RICH_MENU);
  const { data } = optionProps;

  return <components.Option {...optionProps}>{t(data.label)}</components.Option>;
};

export default Option;
