import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import moment from "moment";

import { get } from "lodash";
import i18n from "utils/i18n";
import { convertPriceFormat } from "utils/common";
import Typography from "components/Typography";
import COLORS from "constants/Colors";
import { SvgIcon } from "components/Icon";
import { IcIncome } from "components/SvgIcons";
import { DEFAULT_AVATAR_IMG } from "constants/Image";
import { DeeplePayPaymentChannel } from "types/Order";
import { PAYMENT_CHANNEL_DISPLAY_MAPPING } from "./config";
import MobileCollapse from "../../components/MobileCollapse";

interface MobileIncomeTransactionPropTypes {
  data: any;
}

const PaymentChannel = ({ transaction }: any) => {
  const subtitle = get(transaction, "paymentChannel.title");
  let title = "";

  switch (subtitle) {
    case DeeplePayPaymentChannel.MOBILE_BANKING: {
      title = get(transaction, "variable.bank");
      break;
    }
    case DeeplePayPaymentChannel.CREDIT_CARD: {
      title = get(transaction, "response.cardType");
      break;
    }
    default: {
      title = subtitle;
    }
  }

  const displaySubtitle = PAYMENT_CHANNEL_DISPLAY_MAPPING.subtitle[subtitle];
  const displayTitle = PAYMENT_CHANNEL_DISPLAY_MAPPING.title[title];

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body3" color={COLORS.DarkGray}>
        {i18n.t(displayTitle)}
      </Typography>
      &nbsp;
      <Typography variant="body3" color={COLORS.DarkLight}>
        ({i18n.t(displaySubtitle)})
      </Typography>
    </Box>
  );
};

const MobileIncomeTransaction: FC<MobileIncomeTransactionPropTypes> = ({ data }) => {
  return data.map((transaction: any) => {
    const refundStatusTranslationKey = `deeplePay.transaction.table.refund.status.${transaction.refundStatus?.toLowerCase()}`;

    return (
      <MobileCollapse
        key={transaction.deeplePayTransactionId}
        leftSideRender={
          <Box display="flex" alignItems="center">
            <Box width="20px" height="20px" borderRadius="50%" overflow="hidden" marginRight="12px">
              <img
                width={20}
                height={20}
                src={transaction.order?.pictureUrl || DEFAULT_AVATAR_IMG}
                alt="profile"
                onError={(e: any) => {
                  // eslint-disable-next-line
                  e.target.onerror = null;
                  // eslint-disable-next-line
                  e.target.src = DEFAULT_AVATAR_IMG;
                }}
              />
            </Box>
            <Box>
              <Typography variant="body3" color={COLORS.DarkGray}>
                {transaction.order.name}
              </Typography>
              <Typography variant="body4" color={COLORS.DarkLight}>
                {transaction.order.orderNumber}
              </Typography>
            </Box>
          </Box>
        }
        rightSideRender={
          <Box textAlign="right">
            <Typography variant="body3" color={COLORS.DarkGray}>
              <SvgIcon component={IcIncome} className="p-0" viewBox="0 -4 16 16" />฿ {transaction.amount}
            </Typography>
            <Typography variant="body4" color={COLORS.DarkGray}>
              {i18n.t(`deeplePay.transaction.table.${transaction.status?.toLowerCase()}`)}
            </Typography>
            {transaction.refundDate && (
              <Typography variant="body4" color={COLORS.DarkLight}>
                {`(${i18n.t(refundStatusTranslationKey)})`}
              </Typography>
            )}
          </Box>
        }
        expandRender={
          <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" className="my-2">
              <Typography variant="title8" color={COLORS.DarkGray}>
                {i18n.t("deeplePay.transaction.table.income.row.receivedAmount")}
              </Typography>
              <Typography variant="body3" color={COLORS.DarkGray}>
                ฿ {transaction?.receivedAmount}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" className="my-2">
              <Typography variant="title8" color={COLORS.DarkGray}>
                {i18n.t("deeplePay.transaction.table.income.row.fee")}
              </Typography>
              <Typography variant="body3" color={COLORS.DarkGray}>
                ฿ {convertPriceFormat(get(transaction, "fee") || 0)}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" className="my-2">
              <Typography variant="title8" color={COLORS.DarkGray}>
                {i18n.t("deeplePay.transaction.table.income.row.referenceNo")}
              </Typography>
              <Typography variant="body3" color={COLORS.DarkGray}>
                {transaction?.referenceNo}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" className="my-2">
              <Typography variant="title8" color={COLORS.DarkGray}>
                {i18n.t("deeplePay.transaction.table.income.row.paymentChannel")}
              </Typography>
              <PaymentChannel transaction={transaction} />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" className="my-2">
              <Typography variant="title8" color={COLORS.DarkGray}>
                {i18n.t("deeplePay.transaction.table.income.header.createdDate")}
              </Typography>
              <Typography variant="body3" color={COLORS.DarkGray}>
                {moment(transaction.createdDate).format("DD MMM YYYY HH:mm")}
              </Typography>
            </Box>

            {transaction.refundDate && (
              <Box display="flex" justifyContent="space-between" alignItems="center" className="my-2">
                <Typography variant="title8" color={COLORS.DarkGray}>
                  {i18n.t("deeplePay.transaction.table.income.row.refundDate")}
                </Typography>
                <Typography variant="body3" color={COLORS.DarkGray}>
                  {moment(transaction.refundDate).format("DD MMM YYYY HH:mm")}
                </Typography>
              </Box>
            )}
          </Box>
        }
      />
    );
  });
};

export default MobileIncomeTransaction;
