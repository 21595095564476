const page404 = {
  en: {
    "page404.content": "No page for ",
  },
  th: {
    "page404.content": "ไม่พบหน้าดังกล่าว",
  },
};

export default page404;
