import React, { FC } from "react";
import CircularProgress from "components/CircularProgress";
import Typography from "components/Typography";

type LoadingProps = {
  loadingLabel?: string;
  size?: number;
};

const Loading: FC<LoadingProps> = ({ loadingLabel, children, size = 30 }) => (
  <div className="d-flex align-items-center flex-column mx-auto my-4" style={{ width: 150 }}>
    <CircularProgress size={size} />
    {Boolean(loadingLabel) && (
      <Typography variant="body3" color="gray" className="text-center my-3">
        {loadingLabel}. . .
      </Typography>
    )}
    {children}
  </div>
);

export default Loading;
