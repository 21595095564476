import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { getAddress } from "utils/address";
import { notifyError } from "utils/notify";
import { SHIPPOP_PURCHASE_ORDER_SUMMARY } from "graphql/salesReport/query/shippopPurchaseOrderSummary";
import { ShippopPurchaseOrderSummaryError } from "types/Shipping";

const useQueryShippopOrderSummary = (
  projectId: string,
  orderIds: string[],
  t: Function,
  onError: (errors: ShippopPurchaseOrderSummaryError[]) => void,
) => {
  const { data, loading, error } = useQuery(SHIPPOP_PURCHASE_ORDER_SUMMARY, {
    fetchPolicy: "network-only",
    variables: { projectId, orderIds },
  });

  useEffect(() => {
    if (!loading && error) {
      if (error.graphQLErrors[0]?.extensions?.exception?.meta?.errors) {
        onError(error.graphQLErrors[0]?.extensions?.exception?.meta?.errors);
      }

      notifyError(t("checkout.error.default"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loading]);

  const address = data?.shippopPurchaseOrderSummary
    ? getAddress(data?.shippopPurchaseOrderSummary.senderInformation)
    : "";

  return {
    address,
    addressName: data?.shippopPurchaseOrderSummary.senderInformation.name,
    carriers: data?.shippopPurchaseOrderSummary.carriers,
    errors: error?.graphQLErrors[0]?.extensions?.exception?.meta?.errors as ShippopPurchaseOrderSummaryError[],
    estimatedShippingPrice: data?.shippopPurchaseOrderSummary.estimatedShippingPrice,
    hasApiKey: data?.shippopPurchaseOrderSummary.hasApiKey || false,
    isLoading: loading,
    remainingLogisticCredit: data?.shippopPurchaseOrderSummary.remainingLogisticCredit,
    totalNumberOfOrder: data?.shippopPurchaseOrderSummary.totalNumberOfOrder,
  };
};

export default useQueryShippopOrderSummary;
