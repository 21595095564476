import COLORS from "constants/Colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;

  &.detail {
    align-items: flex-start;
  }

  img {
    max-width: 815px;
    width: 60%;
  }

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: ${COLORS.DarkMed};
  }
`;
