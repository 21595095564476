import { gql } from "@apollo/client";

export const TOP_SELLING_PRODUCT_FROM_ORDER = gql`
  query topSellingProductsFromOrder($projectId: ID!, $timeSpanInDay: Int) {
    topSellingProductsFromOrder(projectId: $projectId, timeSpanInDay: $timeSpanInDay) {
      id
      projectId
      name
      productCode
      images {
        src
      }
    }
  }
`;

export const TOP_SELLING_CONFIGS = gql`
  query topSellingProductConfigs($projectId: ID!) {
    topSellingProductConfigs(projectId: $projectId) {
      id
      projectId
      productId
      sequence
      product {
        id
        name
        productCode
        images {
          src
        }
      }
    }
    project: getProject(projectId: $projectId) {
      id
      topSellingProductPeriodInDays
    }
  }
`;
