import gql from "graphql-tag";

export const NOTIFICATION_ADDED = gql`
  subscription notificationAdded($projectId: ID!) {
    notificationAdded(projectId: $projectId) {
      id
      userId
      type # NotificationType
      customer {
        # Customer
        id
        uuid
        projectId
        platform
        name
        pictureUrl
      }
      payload {
        # NotificationPayload
        title
        body
        action
      }
      createdAt
      readAt
    }
  }
`;
