import styled from "styled-components";
import Grid from "components/Grid";

export const PostalCodeTextWrapper = styled(Grid)`
  border-radius: 8px;
  color: ${({ theme }) => theme.COLORS.DarkMed};
  background-color: ${({ theme }) => theme.COLORS.White};
  border: 0.5px solid ${({ theme }) => theme.COLORS.Primary};
  padding: 0px 0px 0px 8px;
  margin: 4px;
  overflow: hidden;
`;

export const TableHeader = styled(Grid)`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.COLORS.LightGray};
`;

export const TableRowWrapper = styled(Grid)`
  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  }
`;

export const PriceTextWrapper = styled(Grid)`
  background-color: ${({ theme }) => theme.COLORS.LightGray};
  border-left: 1px solid ${({ theme }) => theme.COLORS.Primary};
  padding: 4px;
  margin: 0px 0px 0px 5px;
  border-radius: 0px 8px 8px 0px;
`;

export const PostalCodeGroupTextWrapper = styled(Grid)`
  flex: 1;
`;
