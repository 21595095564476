import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { OptionType } from "react-select";

import Grid from "components/Grid";
import Button from "components/Button";
import CircularProgress from "components/CircularProgress";
import Modal from "components/Modal";
import {
  InventoryIntegrationType,
  InventoryIntegrationSyncMethodType,
  InventoryIntegrationCredential,
  StepConfig,
  IntegrationQuery,
} from "types/Integration";
import { Device } from "types/Device";
import useDevice from "utils/hooks/useDevice";

import SyncingMethodForm from "./SyncingMethodForm";
import ConfirmationForm from "./ConfirmationForm";
import CredentialForm from "./CredentialForm";
import Stepper from "./stepper";
import schema from "./schema";
import useWarehouseData from "./hooks/useWarehouseData";
import useSyncIntegration from "./hooks/useSyncIntegration";

type CredentialFormSchemaProps = {
  apiKey: string;
  apiSecret: string;
  storeName: string;
  warehouse: string;
};

type InventoryIntegrationInputFormModalProps = {
  projectId: string;
  queryVariables: IntegrationQuery;
  isOpen: boolean;
  onClose: () => void;
};

const InventoryIntegrationInputFormModal: FC<InventoryIntegrationInputFormModalProps> = (props) => {
  const { t } = useTranslation();
  const { projectId, queryVariables, isOpen, onClose } = props;
  const device = useDevice();
  const isMobile = device === Device.MOBILE;

  const methods = useForm<CredentialFormSchemaProps>({
    resolver: yupResolver(schema),
  });

  const { reset, handleSubmit, setError } = methods;

  const [focusStepIndex, setFocusStepIndex] = useState(0);
  const [credentialData, setCredentialData] = useState<InventoryIntegrationCredential>();
  const [selectedWarehouse, setSelectedWarehouse] = useState<OptionType>();
  const [selectedSyncingMethod, setSelectedSyncingMethod] = useState(InventoryIntegrationSyncMethodType.REPLACE);

  const handleClose = () => {
    setFocusStepIndex(0);
    onClose();
  };

  const handleIncreaseStep = () => {
    setFocusStepIndex(focusStepIndex + 1);
  };

  const handleDecreaseStep = () => {
    setFocusStepIndex(focusStepIndex - 1);
  };

  const handleClickBack = () => {
    if (focusStepIndex === 1) {
      reset();
      setCredentialData(undefined);
      setSelectedWarehouse(undefined);
    }

    handleDecreaseStep();
  };

  const handleSaveSyncingMethod = () => {
    if (!selectedWarehouse) {
      setError("warehouse", { type: "required", message: "inventoryIntegration.error.required.warehouse" });

      return;
    }

    handleIncreaseStep();
  };

  const handleCheckCredential = handleSubmit(({ apiKey, apiSecret, storeName }: CredentialFormSchemaProps) => {
    const newCredentialData = { apiKey, apiSecret, storeName };

    setCredentialData(newCredentialData);
    handleGetWarehouse(newCredentialData);
  });

  const handleSelectWarehouse = (newWarehouse: OptionType) => {
    setSelectedWarehouse(newWarehouse);
  };

  const handleSelectSyncingMethod = (newSyncingMethod: InventoryIntegrationSyncMethodType) => {
    setSelectedSyncingMethod(newSyncingMethod);
  };

  const { handleGetWarehouse, warehouseOptions } = useWarehouseData({
    projectId,
    onCompleted: useCallback(() => {
      setFocusStepIndex(1);
    }, [setFocusStepIndex]),
  });

  const { syncIntegration, isProcessingSyncIntegration } = useSyncIntegration({
    projectId,
    queryVariables,
    onComplete: handleClose,
  });

  const handleClickConfirm = () => {
    if (!selectedWarehouse || !credentialData) {
      return;
    }

    const payload = {
      type: InventoryIntegrationType.ZORT,
      data: {
        ...credentialData,
        warehouseName: selectedWarehouse.label,
        warehouseCode: selectedWarehouse.value,
      },
    };

    syncIntegration(selectedSyncingMethod, payload);
  };

  const stepListConfig: StepConfig[] = [
    { name: t("inventoryIntegration.credentialForm.title"), content: <CredentialForm /> },
    {
      name: t("inventoryIntegration.syncingMethodForm.title"),
      content: (
        <SyncingMethodForm
          selectedSyncingMethod={selectedSyncingMethod}
          selectedWarehouse={selectedWarehouse}
          warehouseOptions={warehouseOptions}
          onSelectWarehouse={handleSelectWarehouse}
          onSelectSyncingMethod={handleSelectSyncingMethod}
        />
      ),
    },
    {
      name: t("inventoryIntegration.confirmationForm.title"),
      content: (
        <ConfirmationForm
          isMobile={isMobile}
          platform={InventoryIntegrationType.ZORT}
          credentialData={credentialData as InventoryIntegrationCredential}
          syncingMethod={selectedSyncingMethod}
          warehouseName={selectedWarehouse?.label || ""}
        />
      ),
    },
  ];

  const isLastStep = stepListConfig.length === focusStepIndex + 1;
  const onClickNextButton = focusStepIndex === 0 ? handleCheckCredential : handleSaveSyncingMethod;

  return (
    <>
      <Modal
        maxWidth="md"
        isOpen={isOpen}
        onClose={handleClose}
        title={t("inventoryIntegration.modal.addInventoryIntegration.title")}
        fullScreen={isMobile}
      >
        <FormProvider {...methods}>
          <Grid container className="p-4">
            <Grid item xs={12}>
              <Stepper isMobile={isMobile} stepList={stepListConfig} focusStepIndex={focusStepIndex} />
            </Grid>
            <Grid item xs={12} className="pt-4">
              {stepListConfig[focusStepIndex].content}
            </Grid>
            <Grid container spacing={2} className="pt-4">
              <Grid item xs={6}>
                {Boolean(focusStepIndex) && (
                  <Button size="medium" fullWidth color="secondary" onClick={handleClickBack}>
                    {t("Back")}
                  </Button>
                )}
              </Grid>
              <Grid item xs={6}>
                <Button
                  size="medium"
                  fullWidth
                  disabled={isProcessingSyncIntegration}
                  onClick={isLastStep ? handleClickConfirm : onClickNextButton}
                >
                  {t(isLastStep ? "Confirm" : "Next")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
        {isProcessingSyncIntegration && (
          <Modal isOpen onClose={() => ({})}>
            <CircularProgress className="m-4" />
          </Modal>
        )}
      </Modal>
    </>
  );
};

export default InventoryIntegrationInputFormModal;
