import styled from "styled-components";
import ButtonBase from "components/ButtonBase";

export const SocialIcon = styled.img`
  height: 16px;
  width: 16px;
  position: absolute;
  left: 0;
  bottom: 16px;
`;

export const RemovePhotoButton = styled(ButtonBase)`
  color: ${({ theme }) => theme.COLORS.Error};
  font-size: 12px;
`;
