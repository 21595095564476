import { ErrorAddProduct, ErrorDeleteProduct, ErrorEditProduct, ErrorImportProduct } from "types/Product";

export const NETWORK_ERROR = "Network error: Failed to fetch";

const mapErrorMessage = (errMessage: string) => {
  switch (errMessage) {
    case NETWORK_ERROR:
      return "Network error please try again later";
    case ErrorAddProduct.BASIC_PACK_CANNOT_EXCEED_PRODUCT_QUOTA:
      return "product.default.error.overQuota";
    case ErrorDeleteProduct.INVENTORY_NOT_EMPTY_CASE:
      return "product.delete.error.inventoryNotEmpty";
    case ErrorEditProduct.INVENTORY_NOT_EMPTY_CASE:
      return "product.edit.error.inventoryNotEmpty";
    case ErrorAddProduct.DUPLICATED_PRODUCT_CODE:
    case ErrorEditProduct.DUPLICATED_PRODUCT_CODE:
      return "product.default.error.duplicatedProductCode";

    case ErrorAddProduct.DUPLICATED_CF_CODE:
    case ErrorEditProduct.DUPLICATED_CF_CODE:
      return "product.default.error.duplicatedCFCode";

    case ErrorAddProduct.DUPLICATED_PRODUCT_NAME:
    case ErrorEditProduct.DUPLICATED_PRODUCT_NAME:
      return "product.default.error.duplicatedProductName";
    case ErrorAddProduct.DESCRIPTION_EXCEED_LIMIT:
    case ErrorEditProduct.DESCRIPTION_EXCEED_LIMIT:
      return "product.default.error.descriptionExceedLimit";
    case ErrorAddProduct.PRICE_REACH_LIMIT:
    case ErrorEditProduct.PRICE_REACH_LIMIT:
      return "product.default.error.priceReachLimit";

    // import product error
    case ErrorImportProduct.FILE_TYPE_IS_NOT_PERMITTED:
      return "importProduct.error.errorType.fileTypeIsNotPermitted";
    case ErrorImportProduct.BASIC_PACK_CANNOT_EXCEED_PRODUCT_QUOTA:
    case ErrorImportProduct.EXCEED_PRODUCT_QUOTA:
      return "importProduct.error.errorType.exceedProductQuota.prefix";
    case ErrorImportProduct.MISSING_C_COMMERCE_PACKAGE:
      return "importProduct.error.errorType.missingCCommercePackage";
    case ErrorImportProduct.INCORRECT_UPLOAD_FORMAT:
      return "importProduct.error.errorType.incorrectUploadFormat";
    case ErrorImportProduct.DUPLICATED_PRODUCT_TYPE_SKU:
      return "importProduct.error.errorType.duplicatedProductTypeSKU";
    case ErrorImportProduct.DUPLICATED_PRODUCT_NAME:
      return "importProduct.error.errorType.duplicatedProductName";
    case ErrorImportProduct.DUPLICATED_PRODUCT_CODE:
      return "importProduct.error.errorType.duplicatedProductCode";
    case ErrorImportProduct.CATEGORY_NOT_FOUND:
      return "importProduct.error.errorType.categoryNotFound";
    case ErrorImportProduct.DESCRIPTION_EXCEED_LIMIT:
      return "importProduct.error.errorType.descriptionExceedLimit";
    case ErrorImportProduct.PRICE_REACH_LIMIT:
      return "importProduct.error.errorType.priceReachLimit";
    case ErrorImportProduct.DUPLICATED_PRODUCT_CODE_IN_IMPORT_DATA:
      return "importProduct.error.errorType.duplicatedProductCodeInImportData";
    case ErrorImportProduct.DUPLICATED_PRODUCT_NAME_IN_IMPORT_DATA:
      return "importProduct.error.errorType.duplicatedProductNameInImportData";
    case ErrorImportProduct.PRODUCT_CODE_CANNOT_BE_EMPTY:
      return "importProduct.error.errorType.productCodeCannotBeEmpty";
    case ErrorImportProduct.PRODUCT_CF_CODE_EXISTED:
      return "importProduct.error.errorType.productCfCodeExisted";
    case ErrorImportProduct.DUPLICATED_PRODUCT_CF_CODE:
      return "importProduct.error.errorType.duplicatedProductCfCode";
    case ErrorImportProduct.PRICE_CANNOT_BE_LESSTHAN_OR_EQUAL_ZERO:
      return "importProduct.error.errorType.priceCannotBeLessThanOrEqualZero";
    case ErrorImportProduct.IMAGE_URL_IS_INVALID:
      return "importProduct.error.errorType.imageUrlIsInvalid";
    case ErrorImportProduct.EMPTY_PRODUCT_VARIABLE_TYPES:
      return "importProduct.error.errorType.emptyProductVariableTypes";
    case ErrorImportProduct.PRODUCT_CODE_NOT_SUPPORT_CHARACTER:
      return "importProduct.error.errorType.productCodeNotSupportCharacter";

    default:
      return "Please try again later";
  }
};

export default mapErrorMessage;
