import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import Typography from "components/Typography";
import Card from "components/Card";
import Divider from "components/Divider";

import { NotificationType, NotificationConfigurationType } from "types/Notification";

import ConfigItem from "./ConfigItem";

type ConfigCardPropsType = {
  type: NotificationType;
  configList: NotificationConfigurationType[];
  toggleNotificationConfig: (notification: NotificationConfigurationType) => void;
};

const ConfigCard: FC<ConfigCardPropsType> = (props) => {
  const { t } = useTranslation();
  const { type, configList = [], toggleNotificationConfig } = props;

  return (
    <Card padding="24px" noShadow fullWidth>
      <Grid container>
        <Grid item xs={12} className="pt-1">
          <Typography variant="title2" color="dark">
            {t(`notification.configuration.type.${type}`)}
          </Typography>
        </Grid>
        <Grid item xs={12} className="pt-4">
          <Card padding="8px 24px" noShadow fullWidth>
            <Grid container>
              {configList.map((notificationConfig: NotificationConfigurationType, index: number) => {
                const { name, isActive, isSelectable } = notificationConfig;

                return (
                  <Grid item container key={name}>
                    {Boolean(index) && (
                      <Grid item xs={12}>
                        <Divider thickness="0.5px" className="m-0" />
                      </Grid>
                    )}
                    <Grid item xs={12} className="py-3 my-1">
                      <ConfigItem
                        type={name}
                        isActive={isActive}
                        isSelectable={isSelectable}
                        onToggleSwitch={() => toggleNotificationConfig(notificationConfig)}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ConfigCard;
