import React, { useEffect } from "react";
import { OptionType, ValueType } from "react-select";
import { useTranslation } from "react-i18next";
import Grid from "components/Grid";
import TagInputFilter from "components/TagInputFilter";
import Typography from "components/Typography";

import { ORDER_STATUSES } from "constants/SalesReport";
import DateRangeFilter from "../DateRangeFilter";
import NumberRangeFilter from "../NumberRangeFilter";
import { FILTER_SELECT_TYPE, FilterItemType, FILTER_ID } from "../types";

export default function EditMenuOptionsItem(props: {
  title: string;
  setValue: Function;
  filterList: FilterItemType[];
  selectType: FILTER_SELECT_TYPE;
  editId: string;
  keyName: string;
  loadOptions?: () => void;
  onChangeTextInput?: (textInput: string) => void;
  options: string | string[] | { min: number; max: number } | { start: string; end: string };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterValue: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  categoriesOptionList?: any;
  isLoading?: boolean;
}) {
  const { t } = useTranslation();

  const {
    options,
    setValue,
    filterList,
    selectType,
    editId,
    keyName,
    filterValue,
    categoriesOptionList,
    loadOptions,
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getCategoryNameFromSelectedId = (selectedId: any) => {
    const categoryName = categoriesOptionList.find(
      (category: { id: number; name: string }) => Number(category.id) === Number(selectedId.id),
    );
    return categoryName && categoryName.name;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getCategoryIdFromSelectedId = (selectedId: any) => {
    const categoryName = categoriesOptionList.find(
      (category: { id: number; name: string }) => Number(category.id) === Number(selectedId.id),
    );
    return categoryName && categoryName.id;
  };

  const convertDataToSelectOptionFormat = (originalData: string[]) => {
    return originalData.map((data: string) => {
      const orderStatus = ORDER_STATUSES[data];
      const isCategory = keyName === FILTER_ID.CATEGORIES_ID;
      const orderStatusLabel = orderStatus ? t(orderStatus) : t(data);

      return {
        label: isCategory ? getCategoryNameFromSelectedId(data) : orderStatusLabel,
        value: isCategory ? getCategoryIdFromSelectedId(data) : data,
      };
    });
  };

  const handleFilterValueChange = (newValue: ValueType<OptionType>) => {
    const updateValue = filterList.map((item: { id: string }) => {
      if (item.id === editId) {
        return {
          ...item,
          [keyName]: (newValue as OptionType[]).map((tagInputValue) => {
            return tagInputValue.value;
          }),
        };
      }
      return item;
    });
    setValue(updateValue);
  };

  useEffect(() => {
    if (loadOptions) {
      loadOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSchedulePicker = keyName === FILTER_ID.DELIVERY_SCHEDULE_RANGE;

  return (
    <div className="d-flex flex-grow-1">
      {selectType === FILTER_SELECT_TYPE.CUSTOM_OPTIONS && (
        <TagInputFilter
          label={t(keyName)}
          value={convertDataToSelectOptionFormat(filterValue as string[])}
          onChange={(event) => {
            handleFilterValueChange(event || []);
          }}
          isCreateAble
        />
      )}

      {selectType === FILTER_SELECT_TYPE.STRICT_OPTIONS && (
        <TagInputFilter
          label={t(keyName)}
          value={convertDataToSelectOptionFormat(filterValue as string[])}
          options={convertDataToSelectOptionFormat(options as string[]) || []}
          onChange={(event) => {
            handleFilterValueChange(event || []);
          }}
        />
      )}

      {selectType === FILTER_SELECT_TYPE.NUMBER_RANGE && (
        <NumberRangeFilter
          setValue={setValue}
          filterList={filterList}
          editId={editId}
          keyName={keyName}
          options={options as { min: number; max: number }}
        />
      )}

      {selectType === FILTER_SELECT_TYPE.DATE_RANGE && (
        <Grid container alignContent="center" alignItems="center">
          {isSchedulePicker && (
            <Typography variant="body3" className="py-2 pl-2 pr-2">
              {`${t(keyName)} : `}
            </Typography>
          )}
          <DateRangeFilter
            setValue={setValue}
            filterList={filterList}
            editId={editId}
            keyName={keyName}
            options={options}
            isButtonBase={isSchedulePicker}
            disabledFuture={!isSchedulePicker}
          />
        </Grid>
      )}

      {selectType === FILTER_SELECT_TYPE.CUSTOMER_TAG && (
        <TagInputFilter
          label={t(keyName)}
          isCreateAble
          value={convertDataToSelectOptionFormat(filterValue as string[])}
          onChange={(event) => {
            handleFilterValueChange(event || []);
          }}
          options={convertDataToSelectOptionFormat(options as string[]) || []}
        />
      )}

      {selectType === FILTER_SELECT_TYPE.EMAIL && (
        <TagInputFilter
          label={t(keyName)}
          value={convertDataToSelectOptionFormat(filterValue as string[])}
          onChange={(event) => {
            handleFilterValueChange(event || []);
          }}
          isCreateAble
          isEmailAllowed
        />
      )}
    </div>
  );
}
