import React from "react";
import { CellProps } from "react-table";

const ResponseName = <T extends object>(t: Function) => {
  const renderCell = (cell: CellProps<T>) => <div>{cell.value}</div>;

  return {
    Header: t("Name"),
    accessor: "name",
    Cell: renderCell,
  };
};

export default ResponseName;
