import React, { useState, MouseEvent } from "react";
import nanoid from "utils/nanoid";

import { Menu, MenuItem } from "components/Menu";
import Grid from "components/Grid";

import { IcArrowNext } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { FILTER_SELECT_TYPE, FilterItemType } from "../types";

export default function AddMenuOptionsItem(props: {
  id?: string;
  title: string;
  setFilterList: Function;
  filterKey: string;
  filterList: FilterItemType[];
  selectType: FILTER_SELECT_TYPE;
  handleCloseAddMenu: Function;
  options: string[]; // for data/number range { min: number; max: number } | { start: string; end: string }
}) {
  const { t } = useTranslation();
  const { title, options, setFilterList, filterKey, filterList, selectType, handleCloseAddMenu, id } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isNewFilterKeyDuplicated = (checkFilterKey: string) => {
    return filterList.some((filterItem: FilterItemType) =>
      Object.keys(filterItem).some((key: string) => key === checkFilterKey),
    );
  };

  const constructValue = (
    newFilterKey: string,
    itemValue: string | string[] | { min: number; max: number } | { start: string; end: string },
  ) => {
    const isDuplicated = isNewFilterKeyDuplicated(newFilterKey);
    if (!isDuplicated) {
      const newItemValue = selectType === FILTER_SELECT_TYPE.STRICT_OPTIONS ? [itemValue] : itemValue;
      const newValue = {
        id: id || nanoid(),
        [newFilterKey]: newItemValue,
      };

      setFilterList([...filterList, { ...newValue }]);
      handleClose();
    }
  };

  const handleClick = (event: MouseEvent<HTMLLIElement>) => {
    if (selectType === FILTER_SELECT_TYPE.CUSTOM_OPTIONS || selectType === FILTER_SELECT_TYPE.EMAIL) {
      constructValue(filterKey, []);
      handleCloseAddMenu();
    } else if (selectType === FILTER_SELECT_TYPE.NUMBER_RANGE) {
      constructValue(filterKey, { min: 0, max: 1000 });
      handleCloseAddMenu();
    } else if (selectType === FILTER_SELECT_TYPE.DATE_RANGE) {
      constructValue(filterKey, {
        start: moment().subtract(6, "days").startOf("day").toISOString(),
        end: moment().endOf("day").toISOString(),
      });
      handleCloseAddMenu();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <>
      <MenuItem
        onClick={handleClick}
        disabled={isNewFilterKeyDuplicated(filterKey)}
        style={{ paddingRight: `${options && options.length ? 0 : ""}` }}
      >
        <Grid container justify="space-between" alignContent="center" alignItems="center">
          <span> {t(title)}</span>
          {Boolean(options && options.length) && <SvgIcon className="ml-1" component={IcArrowNext} fontSize="small" />}
        </Grid>
      </MenuItem>
      <Menu
        id={`child-menu-${filterKey}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
      >
        {selectType === FILTER_SELECT_TYPE.STRICT_OPTIONS &&
          options.map((item: string, index: number) => {
            return (
              <MenuItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClick={() => constructValue(filterKey, item)}
                disabled={isNewFilterKeyDuplicated(filterKey)}
              >
                {t(item)}
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
}
