export const bestExpressDescription = {
  EN: null,
  TH: [
    {
      body:
        "สามารถเข้ารับสินค้าหนักพัสดุสูงสุด 50 กิโลกรัม และขนาดกล่องไม่เกิน 250 เซนติเมตร (กว้างxยาวxสูง) (ด้านใดด้านหนึ่งต้องไม่เกิน 150 เซนติเมตร)",
    },
    {
      body:
        "ผู้ใช้ ต้องส่งข้อมูล ชื่อ ที่อยู่ เบอร์โทร ของต้นทาง/ผู้ส่ง และของปลายทาง/ผู้รับ ทุกครั้งที่ใช้งาน หากข้อมูลไม่ครบถ้วนอาจส่งผลให้การเข้ารับและจัดส่ง มีปัญหา ซึ่งทางผู้ใช้ต้องรับผิดชอบค่าใช้งานที่เกิดขึ้น",
    },
    { body: "วันทำการ จันทร์ - เสาร์ เวลาทำการ 8.00 - 18.00 น. (วันอาทิตย์ เปิดทำการบางสาขา)" },
    {
      body:
        "ผู้ขอใช้บริการจะต้องสร้างรายการล่วงหน้า 1 วัน และทีมขนส่งจะเข้ารับพัสดุในวันถัดไป ทั้งนี้ทีมขนส่งอาจเข้ารับภายในวันเดียวกับที่สร้างรายการได้ โดยขึ้นอยู่สาขาที่ให้บริการ",
    },
    {
      body:
        "ในกรณีที่ทีมขนส่งไม่ได้เข้ารับพัสดุเลยจนเลยกำหนดเวลา ลูกค้าสามารถกำหนดวันเวลานัดรับใหม่ได้ที่เบอร์โทรที่เจ้าหน้าที่โทรหาหรือลูกค้าสามารถส่งพัสดุได้ที่หน้าสาขา",
    },
    { body: "หากไม่สามารถกำหนดวันนัดรับพัสดุได้กรุณาติดต่อ Best Express Customer Service (02-108-8000)" },
    { body: "Best Express จะพยายามส่งพัสดุไปยังที่อยู่ผู้รับปลายทางตามที่ระบุไว้ 3 ครั้ง ภายใน 5 วันทำการ" },
  ],
};
