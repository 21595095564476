export const trueELogisticsDescription = {
  EN: null,
  TH: [
    { body: "พิมพ์ใบปะหน้าและแปะลงบนกล่องพัสดุ เพื่อให้ทางทีมขนส่งเข้ารับสินค้าพัสดุถึงหน้าบ้าน" },
    { body: "อัตราค่าบริการ คิดตามน้ำหนักกล่องและสินค้า" },
    {
      body:
        "พื้นที่ให้บริการ เขตกรุงเทพฯ ต้องสร้างรายการก่อนเวลา 12.00 น. และปริมณฑล (นนทบุรี, ปทุมธานี และสมุทรปราการ) ต้องสร้างรายการก่อนเวลา 12.00 น. หากสร้างรายการตามระยะเวลาที่กำหนด ทีมขนส่งจะเข้ารับในวันเดียวกัน แต่จะทำการ จัดส่งในวันถัดไป ซึ่งระยะเวลาในการจัดส่งประมาณ 1-3 วัน ทำการโดยเข้ารับทุกวันจันทร์ - วันเสาร์",
    },
    { body: "การเข้ารับสินค้า ต้องมียอดจัดส่งขั้นต่ำ 3 ชิ้น ขึ้นไป" },
    {
      body:
        "สามารถเข้ารับสินค้าหนักพัสดุสูงสุด 10 กิโลกรัม และขนาดกล่องไม่เกิน 90 เซนติเมตร (กว้าง+ยาว+สูง) และ ด้านใดด้านหนึ่งต้องไม่เกิน 38 เซนติเมตร",
    },
    { body: "กรณีพัสดุมีการตีคืน คิดค่าบริการตามจริงที่กำหนด" },
    { body: "กรณีพัสดุมีการตีคืน คิดค่าบริการตามจริง" },
    { body: "ค่าประกันสินค้ากรณีเสียหายและสูญหาย วงเงินประกันไม่เกิน 2,000 บาท/ชิ้น" },
  ],
};
