import React, { Component, ComponentType } from "react";
import { MenuListComponentProps, OptionType } from "react-select";

export const CHECK_TIMEOUT = 300;

export default function wrapMenuList(MenuList: ComponentType<MenuListComponentProps<OptionType>>) {
  class WrappedMenuList extends Component<MenuListComponentProps<OptionType>> {
    checkTimeout: number | undefined;

    menuListRef: HTMLDivElement | undefined;

    componentDidMount() {
      this.setCheckAndHandleTimeout();
    }

    componentWillUnmount() {
      if (this.checkTimeout) {
        clearTimeout(this.checkTimeout);
      }
    }

    innerRef = (ref: HTMLDivElement) => {
      if (ref === this.menuListRef) {
        return;
      }

      const { innerRef } = this.props;

      this.menuListRef = ref;

      (innerRef as (instance: HTMLDivElement) => void)(ref);
    };

    setCheckAndHandleTimeout = () => {
      this.checkAndHandle();

      this.checkTimeout = setTimeout(this.setCheckAndHandleTimeout, CHECK_TIMEOUT);
    };

    checkAndHandle() {
      if (this.shouldHandle()) {
        const {
          selectProps: { handleScrolledToBottom },
        } = this.props;

        if (handleScrolledToBottom) {
          handleScrolledToBottom();
        }
      }
    }

    shouldHandle() {
      const el = this.menuListRef;

      // menu not rendered
      if (!el) {
        return false;
      }

      const { scrollTop, scrollHeight, clientHeight } = el;

      // menu hasn't scroll
      if (scrollHeight <= clientHeight) {
        return true;
      }

      const {
        selectProps: { shouldLoadMore },
      } = this.props;

      return shouldLoadMore(scrollHeight, clientHeight, scrollTop);
    }

    render() {
      return <MenuList {...this.props} innerRef={this.innerRef} />;
    }
  }

  return WrappedMenuList;
}
