const productReport = {
  en: {
    "Category name": "Category name",
    TOTAL: "TOTAL",
  },
  th: {
    "Category name": "ชื่อหมวดหมู่",
    TOTAL: "ทั้งหมด",
  },
};

export default productReport;
