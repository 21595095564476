import React, { FC } from "react";
import { useParams, Switch, Redirect, Route } from "react-router-dom";
import { NEW_FAQ_IDS_ENABLE } from "config";
import {
  AI_SETTINGS,
  CHAT_TEMPLATE,
  GENERAL_SETTING,
  PROJECT,
  RESPONSE_MESSAGE,
  SPECIALIZED_KNOWLEDGE,
  SYNONYM,
  UP_SELLING,
  WELCOME_MESSAGE,
} from "constants/Router";
import DefaultSetting from "domain/Setting/AISetting";
import NewFaq from "domain/Faq/FaqThEn";
import UpSelling from "domain/MarketingTools/UpSelling";
import WelcomeMessage from "domain/Setting/WelcomeMessage";
import useGetUserWithPermission from "utils/hooks/useGetUserWithPermission";
import useTabWithLink from "utils/hooks/useTabWithLink";
import PrivateRoute from "routers/PrivateRoute";
import EditResponseMessage from "pages/EditResponseMessage";
import ReplyShortcutPage from "pages/ReplyShortcut";
import { Synonym as SynonymPageLayout } from "pages/Synonym";
import { NotFound } from "pages/NotFound";
import { TAB_CONFIGS } from "pages/AISetting/tabsConfig";
import ResponseMessage from "pages/ResponseMessage";
import { ProjectIdType } from "types/Project";
import SynonymSubRoute from "./SynonymSubRoute";

const AISettingSubRoute: FC = () => {
  const { projectId = "" } = useParams<ProjectIdType>();
  const { loading, isOwner } = useGetUserWithPermission(projectId);
  const pathIndex = 4;
  const filteredTabConfigs = TAB_CONFIGS;

  const { rootPath, defaultTabPath } = useTabWithLink(filteredTabConfigs, AI_SETTINGS, projectId, pathIndex);

  return (
    <>
      <Switch>
        <PrivateRoute
          exact
          loading={loading}
          path={`${rootPath}/${GENERAL_SETTING}`}
          isAllowed={isOwner}
          redirectTo="/"
          component={DefaultSetting}
        />

        <PrivateRoute
          exact
          loading={loading}
          path={`${rootPath}/${WELCOME_MESSAGE}`}
          isAllowed={isOwner}
          redirectTo="/"
        >
          <WelcomeMessage projectId={projectId} />
        </PrivateRoute>

        <PrivateRoute
          exact
          loading={loading}
          path={`${rootPath}/${SPECIALIZED_KNOWLEDGE}`}
          isAllowed={isOwner}
          redirectTo="/"
        >
          <NewFaq projectId={projectId} isEnableEN={Boolean(NEW_FAQ_IDS_ENABLE.includes(projectId))} />
        </PrivateRoute>

        <PrivateRoute
          exact
          loading={loading}
          path={`${rootPath}/${UP_SELLING}`}
          isAllowed={isOwner}
          redirectTo="/"
          component={UpSelling}
        />

        <PrivateRoute exact loading={loading} path={`${rootPath}/${CHAT_TEMPLATE}`} isAllowed={isOwner} redirectTo="/">
          <ReplyShortcutPage />
        </PrivateRoute>

        <PrivateRoute loading={loading} path={`${rootPath}/${SYNONYM}`} isAllowed={isOwner} redirectTo="/">
          <SynonymPageLayout>
            <SynonymSubRoute />
          </SynonymPageLayout>
        </PrivateRoute>

        <PrivateRoute
          exact
          loading={loading}
          path={`${rootPath}/${RESPONSE_MESSAGE}`}
          isAllowed={isOwner}
          redirectTo="/"
          title={RESPONSE_MESSAGE}
        >
          <ResponseMessage />
        </PrivateRoute>

        <PrivateRoute
          exact
          loading={loading}
          path={`${rootPath}/${RESPONSE_MESSAGE}/:responseMessageName`}
          isAllowed={isOwner}
          redirectTo="/"
          title={RESPONSE_MESSAGE}
        >
          <EditResponseMessage />
        </PrivateRoute>

        <PrivateRoute exact loading={loading} path={`${rootPath}`} isAllowed={isOwner} redirectTo="/">
          <Redirect to={defaultTabPath ? `/${PROJECT}/${projectId}/${AI_SETTINGS}/${defaultTabPath}` : `/${PROJECT}`} />
        </PrivateRoute>

        <Route path={`${rootPath}/*`}>
          <NotFound />
        </Route>
      </Switch>
    </>
  );
};

export default AISettingSubRoute;
