const broadcast = {
  en: {
    "broadcast.SelectALL": "All",
    broadcast: "Broadcast",
    "broadcast.title": "Broadcast title",
    "broadcast.Description": "Broadcast",
    "broadcast.audience": "Audience",
    "broadcast.targeting": "Targeting",
    "broadcast.sendNow": "Send now",
    "broadcast.cancel": "Cancel broadcast",
    "broadcast.previewMessage": "Preview message",
    "broadcast.broadcastTime": "Broadcast Time",
    "broadcast.now": "Now",
    "broadcast.scheduled": "Scheduled",
    "broadcast.location": "Location",
    "broadcast.customerTags": "Customer tags",
    "broadcast.delete": "Delete",
    "broadcast.saveDraft": "Save draft",
    "Are you sure to delete this broadcast draft": "Are you sure to delete this broadcast draft",
    "Are you sure to cancel this broadcast": "Are you sure to cancel this broadcast",
    "broadcast.table.placeholder": "No broadcast",
    "broadcast.table.title": "Title",
    "broadcast.table.targetAudience": "Target Audience",
    "broadcast.add": "Create new broadcast",
    "Created at": "Created at",
    "broadcast.description.inProgress": "Sending",
    Engagement: "Engagement",
    Draft: "Draft",
    Sent: "Sent",
    Send: "Send",
    Fail: "Fail",
    Audience: "Audience",
    "Broadcast campaign was saved failed": "Broadcast campaign was saved failed",
    "Broadcast campaign was saved successfully": "Broadcast campaign was saved successfully",
    "Broadcast draft was deleted failed": "Broadcast draft was deleted failed",
    "Broadcast draft was deleted successfully": "Broadcast draft was deleted successfully",
    "Broadcast canceled successfully": "Broadcast canceled successfully",
    "Broadcast canceled failed": "Broadcast canceled failed",
    "Are you sure to send a broadcast to the selected audiences":
      "Are you sure to send a broadcast to the selected audiences",
    "broadcast.description.sent": "This broadcast has successfully been sent. Editing is disabled now.",
    "broadcast.description.draft": "This broadcast has been saved. The draft can be edited only before sending.",
    "broadcast.description.fail": "This broadcast has been failed to send. Please resend it again.",
    "broadcast.description.sending": "The broadcast is being sent now.",
    "broadcast.description.scheduled": "The broadcast waiting for scheduled.",
    "broadcast.location.all": "all location",
    "broadcast.customerTags.all": "all customer tags",
    "broadcast.targetAudience.all": "all target audience",
    DRAFT: "Draft",
    SENT: "Sent",
    SEND: "Send",
    FAILED: "Fail",
    IN_PROGRESS: "Sending",
    SCHEDULED: "Scheduled",
    status: "status",
    "broadcast.orderPaid": "Order paid",
    "broadcast.orderPaidConversionTotal": "Total spending",
    "broadcast.conversionRate": "Conversion rate",
    "broadcast.date&time": "Date & time",
    Scheduled: "Scheduled",
    "broadcast.confirmSchedule": "Are you sure to scheduled a broadcast \n to the selected audiences at \n {{date}}",
    "broadcast.lineOa": "LINE OA BROADCAST",
    "broadcast.facebook": "FACEBOOK BROADCAST",
    "broadcast.facebook.add": "Create new request",
    "broadcast.facebook.pageHeader":
      "Broadcast permission requests are limited to 1 context per request. You will be unable to create requests once all contexts are selected.",
    "broadcast.facebook.table.header.requestName": "Request Name",
    "broadcast.facebook.table.header.subscriber": "Subscriber",
    "broadcast.facebook.table.header.subscriber.unit": "",
    "broadcast.facebook.table.header.requestDeliveryContext": "Request delivery context",
    "broadcast.facebook.table.header.broadcastFrequency": "Broadcast frequency",
    "broadcast.facebook.table.header.createdAt": "Created at",
    "broadcast.facebook.table.action.sendBroadcast": "Broadcast",
    "broadcast.facebook.table.action.sending": "Send...",
    "broadcast.facebook.table.requestDeliveryContext.welcomeMessageSent": "After welcome message",
    "broadcast.facebook.table.requestDeliveryContext.paymentVerified": "After payment verification",
    "broadcast.facebook.table.requestDeliveryContext.trackingNumberSent": "After tracking number",
    "broadcast.facebook.table.broadcastFrequency.daily": "Daily",
    "broadcast.facebook.table.broadcastFrequency.weekly": "Weekly",
    "broadcast.facebook.table.broadcastFrequency.monthly": "Monthly",
    "broadcast.facebook.modal.delete.title": "Do you want to delete this request?",
    "broadcast.facebook.modal.delete.subtitle": "All the information will not be restored.",
    "broadcast.facebook.broadcastEmpty": "No broadcast permission request",
    "broadcast.facebookRequestForm.title": "Facebook Broadcast (Recurring Notifications)",
    "broadcast.facebookRequestForm.description":
      "The Recurring Notifications allow your shops to connect with customers after 24 hours of the last chat. It is required to have prior permission from customers to send broadcast messages.",
    "broadcast.facebookRequestForm.form.title": "Broadcast permission request",
    "broadcast.facebookRequestForm.form.requestNameLabel": "Request name",
    "broadcast.facebookRequestForm.form.requestNamePlaceholder": "Ex. broadcast 1",
    "broadcast.facebookRequestForm.form.requestContextLabel": "Request delivery context",
    "broadcast.facebookRequestForm.form.requestContextPlaceholder": "Select context",
    "broadcast.facebookRequestForm.form.welcomeMessageSent": "After welcome message",
    "broadcast.facebookRequestForm.form.paymentVerifired": "After payment verification",
    "broadcast.facebookRequestForm.form.trackingNumberSent": "After tracking number",
    "broadcast.facebookRequestForm.form.submit": "Submit request",
    "broadcast.facebookRequestForm.form.titleMessageLabel": "Title message",
    "broadcast.facebookRequestForm.form.titleMessagePlaceholder": "Enter text up to {{ maxLength }} characters",
    "broadcast.facebookRequestForm.form.broadcastFrequencyLabel": "Broadcast frequency",
    "broadcast.facebookRequestForm.form.broadcastFrequencyPlaceholder": "Select frequency",
    "broadcast.facebookRequestForm.form.daily": "Daily",
    "broadcast.facebookRequestForm.form.weekly": "Weekly",
    "broadcast.facebookRequestForm.form.monthly": "Monthly",
    "broadcast.facebookRequestForm.form.buttonMessageLabel": "Button message",
    "broadcast.facebookRequestForm.form.buttonMessagePlaceholder": "Get {{ frequency }} messages",
    "broadcast.facebookRequestForm.form.allow": "Allow messages",
    "broadcast.facebookRequestForm.form.frequency": "Get {{ frequency }} messages",
    "broadcast.facebookRequestForm.form.get": "Get messages",
    "broadcast.facebookRequestForm.form.optIn": "Opt in to messages",
    "broadcast.facebookRequestForm.form.signUp": "Sign up for messages",
    "broadcast.facebookRequestForm.form.requestContextHelper":
      "Broadcast permission requests are limited to 1 context per request. You will be unable to create requests once all contexts are selected.",
    "broadcast.facebookRequestForm.form.renewPlaceholder": "Renew auto broadcast permission request",
    "broadcast.facebookRequestForm.form.autoRenewTitle": "Auto renew",
    "broadcast.facebookRequestForm.form.autoRenewSubtitle": "The request will be resent based on broadcast frequency",
    "broadcast.facebookRequestForm.form.noAutoRenewTitle": "No auto renew",
    "broadcast.facebookRequestForm.form.noAutoRenewSubtitle": "You will need to create a new request all over again.",
    "broadcast.facebookRequestForm.form.upload": "Upload",
    "broadcast.facebookRequestForm.form.uploadPlaceholder": "Upload or drag and drop an image",
    "broadcast.facebookRequestForm.form.tooltip.title": "Recurring Notifiation Frequency",
    "broadcast.facebookRequestForm.form.tooltip.description": "When a re-Opt In Request is Sent",
    "broadcast.facebookRequestForm.form.tooltip.daily": "Daily",
    "broadcast.facebookRequestForm.form.tooltip.dailyDescription": "6 months after first request",
    "broadcast.facebookRequestForm.form.tooltip.weekly": "Weekly",
    "broadcast.facebookRequestForm.form.tooltip.weeklyDescription": "9 months after first request",
    "broadcast.facebookRequestForm.form.tooltip.monthly": "Monthly",
    "broadcast.facebookRequestForm.form.tooltip.monthlyDescription": "12 months after first request",
    "broadcast.facebookRequestForm.form.validation.name.required": "Request Name is a required field",
    "broadcast.facebookRequestForm.form.validation.messageTitle.required": "Message Title is a required field",
    "broadcast.facebookRequestForm.form.validation.messageTitle.max": "Message Title is too long",
    "broadcast.facebook.audienceForm.broadcastHeader": "Broadcast audience",
    "broadcast.facebook.audienceForm.audienceHeader": "Audience",
    "broadcast.facebook.audienceForm.targetingLabel": "Targeting",
    "broadcast.facebook.audienceForm.locationLabel": "Location",
    "broadcast.facebook.audienceForm.customerTagLabel": "Customer tag",
    "broadcast.facebook.campaign.back.title": "Do you want to go back?",
    "broadcast.facebook.campaign.back.subTitle": "You will lose any changes you’ve made.",
    "broadcast.facebook.campaign.send.title": "Do you want to send this broadcast?",
    "broadcast.facebook.campaign.send.subTitle": "This action cannot be reversed.",
    "braodcast.messagePreview.previewButton": "Preview message",
    "broadcast.facebookRequestForm.form.save": "Save",
    "broadcast.facebookRequestForm.form.subtitle": "Recurring Notification",
    "broadcast.facebookRequestForm.form.exampleBtn": "see example",
    "broadcast.facebookRequestForm.form.previewSubtitle":
      "You may receive additional messages on this topic over the next {{ months }} months.",
    "broadcast.facebookRequestForm.previewModal.picture": "Image",
    "broadcast.facebookRequestForm.previewModal.topic": "Title message",
    "broadcast.facebookRequestForm.previewModal.buttonMessage": "Button message",
    "broadcast.facebookRequestForm.previewModal.description": "Facebook broadcast notifiction request example",
    "broadcast.facebookRequestForm.previewModal.closeButton": "Close",
    "broadcast.facebook.responseMessage.allow": "Allow messages",
    "broadcast.facebook.responseMessage.get": "Get messages",
    "broadcast.facebook.responseMessage.optIn": "Opt in to messages",
    "broadcast.facebook.responseMessage.signUp": "Sign up for messages",
    "broadcast.facebook.responseMessage.frequency": "Get {{ frequency }} messages",
    "broadcast.facebook.responseMessage.permission": "Broadcast permission request message sent",
    "broadcast.createFacebookRequestSuccess.title": "Success! Your broadcast permission request is sent.",
    "broadcast.createFacebookRequestSuccess.subtitle": "Continue to create your broadcast by clicking on “Broadcast”",
    "broadcast.createFacebookRequestError.title": "Something went wrong",
    "broadcast.createFacebookRequestError.subtitle": "Cannot create a new request",
    "broadcast.facebook.modal.create.title": "Do you want to send this broadcast?",
    "broadcast.facebook.modal.create.subtitle": "This action cannot be reversed.",
    "broadcast.facebook.modal.edit.title": "Do you want to save your changes?",
    "broadcast.facebook.modal.edit.subtitle":
      "The details will be changed and applied to your next request immediately.",
    "broadcast.editFacebookRequestSuccess.title": "Success! Your broadcast permission request is sent.",
    "broadcast.rateLimitError.title": "Request limit.",
    "broadcast.rateLimitError.subtitle": "Cannot create a new request",
    "broadcast.facebookRequestForm.form.additionalMessageSetting": "Additional message settings",
    "broadcast.facebookRequestForm.form.messageBeforeRequestLabel": "Message before request",
    "broadcast.facebookRequestForm.form.messageAfterRequestAccepted": "Message after request accepted",
    "broadcast.facebookRequestForm.form.defaultMessageBeforeRequest":
      "Didn’t get that promotion? 🧐 Don’t worry. Click on the button below to follow us and update our latest newsletters and promotions.",
    "broadcast.facebookRequestForm.form.defaultMessageAfterRequestAccepted":
      "Nicely done! 🎉🥰 You will never miss any of our updates or promotions again.",
    "broadcast.facebook.modal.additional.title": "Example of additional messages",
    "broadcast.facebookRequestForm.form.uploadDescription": "1:1 Aspect Ratio (Square)",
  },
  th: {
    "broadcast.SelectALL": "ทุก",
    broadcast: "บรอดแคสต์",
    "broadcast.title": "ชื่อบรอดแคสต์",
    "broadcast.Description": "บรอดแคสต์",
    "broadcast.audience": "กลุ่มลูกค้า",
    "broadcast.targeting": "กลุ่มเป้าหมาย",
    "broadcast.sendNow": "ส่งทันที",
    "broadcast.cancel": "ยกเลิกข้อความบรอดแคสต์",
    "broadcast.previewMessage": "ข้อความบรอดแคสต์",
    "broadcast.broadcastTime": "เวลาการบรอดแคสต์",
    "broadcast.now": "ทันที",
    "broadcast.scheduled": "ระบุเวลา",
    "broadcast.location": "จังหวัด",
    "broadcast.customerTags": "แท็กลูกค้า",
    "broadcast.delete": "ลบ",
    "broadcast.saveDraft": "บันทึกร่าง",
    "Are you sure to delete this broadcast draft": "คุณต้องการที่จะลบร่างบรอดแคสต์นี้ใช่หรือไม่",
    "Are you sure to cancel this broadcast": "คุณต้องการที่จะยกเลิกบรอดแคสต์นี้ใช่หรือไม่",
    "broadcast.table.placeholder": "ไม่มีรายการบรอดแคสต์",
    "broadcast.table.title": "ชื่อ",
    "broadcast.table.targetAudience": "กลุ่มเป้าหมาย",
    "broadcast.add": "เพิ่มบรอดแคสต์",
    "Created at": "ถูกสร้างเมื่อ",
    "broadcast.description.inProgress": "กำลังส่ง",
    Engagement: "เสียงตอบรับ",
    Draft: "ร่าง",
    Sent: "ส่งแล้ว",
    Send: "ส่ง",
    Fail: "ล้มเหลว",
    Audience: "กลุ่มเป้าหมาย",
    "Broadcast campaign was saved failed": "บรอดแคสต์บันทึกไม่สำเร็จ",
    "Broadcast campaign was saved successfully": "บรอดแคสต์บันทึกสำเร็จ",
    "Broadcast draft was deleted failed": "บรอดแคสต์ลบไม่สำเร็จ",
    "Broadcast draft was deleted successfully": "บรอดแคสต์ลบสำเร็จ",
    "Broadcast canceled successfully": "ยกเลิกบรอดแคสต์สำเร็จ",
    "Broadcast canceled failed": "ยกเลิกบรอดแคสต์ไม่สำเร็จ",
    "Are you sure to send a broadcast to the selected audiences":
      "คุณต้องการที่จะส่ง บรอดแคสต์ไปหากลุ่มลูกค้าที่ถูกเลือกใช่หรือไม่",
    "broadcast.description.sent": "บรอดแคสนี้ได้ถูกส่งเรียบร้อยแล้วและไม่สามารถแก้ไขได้",
    "broadcast.description.draft": "บรอดแคสนี้ได้ถูกบันทึกเรียบร้อยแล้ว สามารถแก้ไขแบบร่างได้ก่อนส่งเท่านั้น",
    "broadcast.description.fail": "การส่งบรอดแคสนี้ล้มเหลว กรุณาส่งใหม่อีกครั้ง",
    "broadcast.description.sending": "กำลังส่งบรอดแคส",
    "broadcast.description.scheduled": "กำลังรอเวลาส่งบรอดแคสต์",
    "broadcast.location.all": "ทุกจังหวัด",
    "broadcast.customerTags.all": "แท็กลูกค้าทั้งหมด",
    "broadcast.targetAudience.all": "กลุ่มเป้าหมายทั้งหมด",
    DRAFT: "ร่าง",
    SENT: "ส่งแล้ว",
    SEND: "ส่ง",
    FAILED: "ล้มเหลว",
    IN_PROGRESS: "กำลังส่ง",
    SCHEDULED: "ตั้งเวลาส่ง",
    status: "สถานะ",
    "broadcast.orderPaid": "ออเดอร์ที่สำเร็จ",
    "broadcast.orderPaidConversionTotal": "ยอดขาย",
    "broadcast.conversionRate": "ผู้เข้ามาซื้อ",
    "broadcast.date&time": "วันและเวลา",
    Scheduled: "ตั้งเวลาส่ง",
    "broadcast.confirmSchedule":
      "คุณต้องการที่จะตั้งเวลาส่งบรอดแคสต์ \nไปหากลุ่มลูกค้าที่ถูกเลือกในเวลา \n{{date}} ใช่หรือไม่",
    "broadcast.lineOa": "บรอดแคสต์ LINE OA",
    "broadcast.facebook": "บรอดแคสต์ Facebook",
    "broadcast.facebook.add": "เพิ่มการส่งคำขอ",
    "broadcast.facebook.pageHeader":
      "คำขออนุญาตส่งบรอดแคสต์จำกัด 1 บริบทต่อ 1 คำขอ เมื่อเลือกใช้ครบทุกบริบทแล้ว จะไม่สามารถเพิ่มการส่งคำขอได้อีก",
    "broadcast.facebook.table.header.requestName": "ชื่อคำขอ",
    "broadcast.facebook.table.header.subscriber": "ผู้ติดตาม",
    "broadcast.facebook.table.header.subscriber.unit": "คน",
    "broadcast.facebook.table.header.requestDeliveryContext": "บริบทในการส่งคำขอ",
    "broadcast.facebook.table.header.broadcastFrequency": "ความถี่ในการส่งบรอดแคสต์",
    "broadcast.facebook.table.header.createdAt": "ถูกสร้างเมื่อ",
    "broadcast.facebook.table.action.sendBroadcast": "สร้างบรอดแคสต์",
    "broadcast.facebook.table.action.sending": "กำลังส่ง...",
    "broadcast.facebook.table.requestDeliveryContext.welcomeMessageSent": "ส่งหลังข้อความต้อนรับ",
    "broadcast.facebook.table.requestDeliveryContext.paymentVerified": "ส่งหลังจากยืนยันการชำระเงิน",
    "broadcast.facebook.table.requestDeliveryContext.trackingNumberSent": "ส่งหลังการแจ้งเลขพัสดุ",
    "broadcast.facebook.table.broadcastFrequency.daily": "รายวัน",
    "broadcast.facebook.table.broadcastFrequency.weekly": "รายสัปดาห์",
    "broadcast.facebook.table.broadcastFrequency.monthly": "รายเดือน",
    "broadcast.facebook.modal.delete.title": "ยืนยันการลบคำขออนุญาตส่งบรอดแคสต์นี้?",
    "broadcast.facebook.modal.delete.subtitle": "ข้อมูลทั้งหมดจะไม่สามารถกู้คืนได้",
    "broadcast.facebook.broadcastEmpty": "ไม่พบคำขออนุญาตส่งบรอดแคสต์",
    "broadcast.facebookRequestForm.title": "บรอดแคสต์ Facebook (Recurring Notifications)",
    "broadcast.facebookRequestForm.description":
      "ระบบบรอดแคสต์แบบ Recurring Notifications ช่วยให้ร้านค้าติดต่อกับลูกค้าได้ แม้พ้นช่วงเวลา 24 ชั่วโมงของการแชทครั้งล่าสุด โดยร้านค้าจำเป็นต้องได้รับอนุญาตจากลูกค้าล่วงหน้าเพื่อส่งข้อความบรอดแคสต์",
    "broadcast.facebookRequestForm.form.title": "คำขออนุญาตส่งบรอดแคสต์",
    "broadcast.facebookRequestForm.form.requestNameLabel": "ชื่อคำขอ",
    "broadcast.facebookRequestForm.form.requestNamePlaceholder": "Ex. บรอดแคสต์ 1",
    "broadcast.facebookRequestForm.form.requestContextLabel": "บริบทในการส่งคำขอ",
    "broadcast.facebookRequestForm.form.requestContextPlaceholder": "เลือกบริบท",
    "broadcast.facebookRequestForm.form.welcomeMessageSent": "ส่งหลังข้อความต้อนรับ",
    "broadcast.facebookRequestForm.form.paymentVerifired": "ส่งหลังจากยืนยันการชำระเงิน",
    "broadcast.facebookRequestForm.form.trackingNumberSent": "ส่งหลังการแจ้งเลขพัสดุ",
    "broadcast.facebookRequestForm.form.submit": "ส่งคำขอ",
    "broadcast.facebookRequestForm.form.titleMessageLabel": "หัวข้อ",
    "broadcast.facebookRequestForm.form.titleMessagePlaceholder": "ใส่ข้อความได้สูงสุด {{ maxLength }} ตัวอักษร",
    "broadcast.facebookRequestForm.form.broadcastFrequencyLabel": "ความถี่ในการส่งบรอดแคสต์",
    "broadcast.facebookRequestForm.form.broadcastFrequencyPlaceholder": "เลือกความถี่",
    "broadcast.facebookRequestForm.form.daily": "รายวัน",
    "broadcast.facebookRequestForm.form.weekly": "รายสัปดาห์",
    "broadcast.facebookRequestForm.form.monthly": "รายเดือน",
    "broadcast.facebookRequestForm.form.buttonMessageLabel": "ข้อความที่แสดงบนปุ่ม",
    "broadcast.facebookRequestForm.form.buttonMessagePlaceholder": "รับข้อความ{{ frequency }}",
    "broadcast.facebookRequestForm.form.allow": "อนุญาตข้อความ",
    "broadcast.facebookRequestForm.form.frequency": "รับข้อความ{{ frequency }}",
    "broadcast.facebookRequestForm.form.get": "รับข้อความ",
    "broadcast.facebookRequestForm.form.optIn": "อนุญาตเพื่อรับข้อความ",
    "broadcast.facebookRequestForm.form.signUp": "สมัครรับข้อความ",
    "broadcast.facebookRequestForm.form.requestContextHelper":
      "คำขออนุญาตส่งบรอดแคสต์จำกัด 1 บริบทต่อ 1 คำขอ เมื่อเลือกใช้ครบทุกบริบทแล้ว จะไม่สามารถเพิ่มการส่งคำขอได้อีก",
    "broadcast.facebookRequestForm.form.renewPlaceholder": "ตั้งค่าการส่งคำขออนุญาตบรอดแคสต์ซ้ำอัตโนมัติ",
    "broadcast.facebookRequestForm.form.autoRenewTitle": "ส่งซ้ำอัตโนมัติ",
    "broadcast.facebookRequestForm.form.autoRenewSubtitle":
      "คำขอจะถูกส่งซ้ำเมื่อครบกำหนดเวลา ตามความถี่ในการส่งบรอดแคสต์",
    "broadcast.facebookRequestForm.form.noAutoRenewTitle": "ไม่ต้องส่งซ้ำอัตโนมัติ",
    "broadcast.facebookRequestForm.form.noAutoRenewSubtitle": "คำขอจะไม่ถูกส่งซ้ำ ต้องกลับไปสร้างคำขอใหม่อีกครั้ง",
    "broadcast.facebookRequestForm.form.upload": "อัปโหลด",
    "broadcast.facebookRequestForm.form.uploadPlaceholder": "อัปโหลดรูปภาพ หรือลากไฟล์มาวางเพื่ออัปโหลด",
    "broadcast.facebookRequestForm.form.tooltip.title": "ความถี่ในการส่งบรอดแคสต์",
    "broadcast.facebookRequestForm.form.tooltip.description": "กำหนดการส่งคำขออนุญาตบรอดแคสต์ซ้ำอัตโนมัติ",
    "broadcast.facebookRequestForm.form.tooltip.daily": "รายวัน",
    "broadcast.facebookRequestForm.form.tooltip.dailyDescription": "6 เดือน หลังลูกค้าตอบรับคำขอ",
    "broadcast.facebookRequestForm.form.tooltip.weekly": "รายสัปดาห์",
    "broadcast.facebookRequestForm.form.tooltip.weeklyDescription": "9 เดือน หลังลูกค้าตอบรับคำขอ",
    "broadcast.facebookRequestForm.form.tooltip.monthly": "รายเดือน",
    "broadcast.facebookRequestForm.form.tooltip.monthlyDescription": "12 เดือน หลังลูกค้าตอบรับคำขอ",
    "broadcast.facebookRequestForm.form.validation.name.required": "กรุณาใส่ชื่อคำขอ",
    "broadcast.facebookRequestForm.form.validation.messageTitle.required": "กรุณาใส่หัวข้อ",
    "broadcast.facebookRequestForm.form.validation.messageTitle.max": "หัวข้อมีความยาวมากกว่าที่กำหนด",
    "broadcast.facebook.audienceForm.broadcastHeader": "กลุ่มลูกค้าบรอดแคสต์",
    "broadcast.facebook.audienceForm.audienceHeader": "กลุ่มลูกค้า",
    "broadcast.facebook.audienceForm.targetingLabel": "กลุ่มเป้าหมาย",
    "broadcast.facebook.audienceForm.locationLabel": "สถานที่",
    "broadcast.facebook.audienceForm.customerTagLabel": "แท็กลูกค้า",
    "broadcast.facebook.campaign.back.title": "ยืนยันเพื่อออกจากหน้านี้?",
    "broadcast.facebook.campaign.back.subTitle": "ข้อมูลที่สร้างไว้จะไม่ถูกบันทึก",
    "broadcast.facebook.campaign.send.title": "ยืนยันการส่งบรอดแคสต์?",
    "broadcast.facebook.campaign.send.subTitle": "ข้อความจะไม่สามารถย้อนกลับมาแก้ไขได้",
    "braodcast.messagePreview.previewButton": "แสดงข้อความก่อนส่ง",
    "broadcast.facebookRequestForm.form.save": "บันทึก",
    "broadcast.facebookRequestForm.form.subtitle": "บรอดแคสต์ Facebook",
    "broadcast.facebookRequestForm.form.exampleBtn": "ดูตัวอย่าง",
    "broadcast.facebookRequestForm.form.previewSubtitle":
      "คุณจะได้รับข้อความโปรโมชั่นสุดพิเศษจากเราต่อเนื่องไปอีก {{ months }} เดือน",
    "broadcast.facebookRequestForm.previewModal.picture": "รูปภาพ",
    "broadcast.facebookRequestForm.previewModal.topic": "หัวข้อ",
    "broadcast.facebookRequestForm.previewModal.buttonMessage": "ข้อความที่แสดงบนปุ่ม",
    "broadcast.facebookRequestForm.previewModal.description": "ตัวอย่างคำขออนุญาตส่งบรอดแคสต์ผ่าน Facebook",
    "broadcast.facebookRequestForm.previewModal.closeButton": "ปิด",
    "broadcast.facebook.responseMessage.allow": "อนุญาตข้อความ",
    "broadcast.facebook.responseMessage.get": "รับข้อความ",
    "broadcast.facebook.responseMessage.optIn": "อนุญาตเพื่อรับข้อความ",
    "broadcast.facebook.responseMessage.signUp": "สมัครรับข้อความ",
    "broadcast.facebook.responseMessage.frequency": "รับข้อความ {{ frequency }}",
    "broadcast.facebook.responseMessage.permission": "ส่งคำขออนุญาตบรอดแคสต์แล้ว",
    "broadcast.createFacebookRequestSuccess.title": "คำขออนุญาตบรอดแคสต์ถูกส่งเรียบร้อยแล้ว",
    "broadcast.createFacebookRequestSuccess.subtitle": 'คลิกที่ "สร้างบรอดแคสต์" เพื่อสร้างข้อความบรอดแคสต์',
    "broadcast.createFacebookRequestError.title": "เกิดข้อผิดพลาด",
    "broadcast.createFacebookRequestError.subtitle": "กรุณาลองใหม่อีกครั้ง",
    "broadcast.facebook.modal.create.title": "ยืนยันการส่งคำขออนุญาตบรอดแคสต์นี้?",
    "broadcast.facebook.modal.create.subtitle": "คำขอจะไม่สามารถย้อนกลับมาแก้ไขได้",
    "broadcast.facebook.modal.edit.title": "ยืนยันเพื่อบันทึกการเปลี่ยนแปลงข้อมูล?",
    "broadcast.facebook.modal.edit.subtitle": "ข้อมูลจะถูกเปลี่ยนและนำไปใช้กับคำขอครั้งต่อไปทันที",
    "broadcast.editFacebookRequestSuccess.title": "คำขออนุญาตบรอดแคสต์ถูกบันทึกเรียบร้อยแล้ว",
    "broadcast.rateLimitError.title": "ครบจำนวนการสร้างคำขอแล้ว",
    "broadcast.rateLimitError.subtitle": "ไม่สามารถสร้างคำขอใหม่ได้อีก",
    "broadcast.facebookRequestForm.form.additionalMessageSetting": "ตั้งค่าข้อความเพิ่มเติม",
    "broadcast.facebookRequestForm.form.messageBeforeRequestLabel": "ข้อความก่อนส่งคำขอ",
    "broadcast.facebookRequestForm.form.messageAfterRequestAccepted": "ข้อความหลังลูกค้าตอบรับคำขอ",
    "broadcast.facebookRequestForm.form.defaultMessageBeforeRequest":
      "เพื่อไม่ให้พลาดสิทธิในการรับข่าวสารและโปรโมชั่นต่าง ๆ จากทางร้าน สามารถกดปุ่มด้านล่างเพื่อติดตามได้เลยน้า 💖",
    "broadcast.facebookRequestForm.form.defaultMessageAfterRequestAccepted":
      "เรียบร้อยแล้วจ้า 🎉🥰 คุณลูกค้าจะไม่พลาดข่าวสารและโปรโมชั่นดี ๆ จากทางร้านอีกแน่นอน",
    "broadcast.facebook.modal.additional.title": "ตัวอย่างข้อความเพิ่มเติม",
    "broadcast.facebook.modal.additional.beforeRequest": "ข้อความก่อนส่งคำขอ",
    "broadcast.facebook.modal.additional.afterAcceptRequest": "ข้อความหลังลูกค้าตอบรับคำขอ",
    "broadcast.facebookRequestForm.form.uploadDescription": "สัดส่วนภาพ 1:1 (สี่เหลี่ยมจัตุรัส)",
  },
};

export default broadcast;
