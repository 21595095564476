import React, { FC } from "react";
import { BoxProps } from "types/RichMenu";
import { Box, BaseBoxContainer, Badge } from "./styled";

const SixBoxes: FC<BoxProps> = ({ bgColor }) => {
  return (
    <BaseBoxContainer>
      <Box bgColor={bgColor}>
        <Badge>A</Badge>
      </Box>
      <Box bgColor={bgColor}>
        <Badge>B</Badge>
      </Box>
      <Box bgColor={bgColor}>
        <Badge>C</Badge>
      </Box>
      <Box bgColor={bgColor}>
        <Badge>D</Badge>
      </Box>
      <Box bgColor={bgColor}>
        <Badge>E</Badge>
      </Box>
      <Box bgColor={bgColor}>
        <Badge>F</Badge>
      </Box>
    </BaseBoxContainer>
  );
};

export default SixBoxes;
