import { OptionType } from "domain/Product/ProductListWithSearch/ProductSelector/Option/type";
import { NewProductItemProps, NewProductSKU } from "types/Product";
import produce from "immer";

const getValuesFromOptions = (options: OptionType<NewProductItemProps, NewProductSKU>[]) => {
  const chucks: NewProductItemProps[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pushValueToChuck = (selectedOptions: OptionType<any>[]) => {
    selectedOptions.forEach((selectedOption) => {
      if (selectedOption.value && selectedOption.options?.length && !selectedOption.parent) {
        const product = produce<NewProductItemProps>(selectedOption.value, (draft) => {
          const selectedSKUIds: string[] = selectedOption?.value.productSKUs
            .map((productSKU: NewProductSKU) => (productSKU.isChecked ? productSKU?.id : null))
            .filter(Boolean);

          const ids = (selectedOption.options as OptionType<NewProductSKU>[])
            .map((option) => {
              if (option.isChecked) {
                return option?.value?.id || "";
              }

              const index = selectedSKUIds.findIndex((selectedSKUId) => selectedSKUId === option?.value?.id);
              selectedSKUIds.splice(index, 1);

              return null;
            })
            .filter(Boolean);

          const allSelectedSKUIds = selectedSKUIds.concat(ids as string[]);

          (selectedOption.value.productSKUs as NewProductSKU[]).forEach(
            (productSKU: NewProductSKU, productSKUIndex) => {
              const isChecked = allSelectedSKUIds.some((id) => id === productSKU.id);
              draft.productSKUs[productSKUIndex].isChecked = isChecked;
            },
          );
        });

        chucks.push(product);
      }
    });
  };

  pushValueToChuck(options);

  return chucks;
};

export default getValuesFromOptions;
