import React, { useState } from "react";

import Button, { NewPrimaryButton } from "components/Button";
import Modal from "components/Modal";
import Typography from "components/Typography";
import useIsDesktop from "utils/hooks/useIsDesktop";
import styled from "styled-components";
import { ButtonColors } from "types/Button";

type FreeTrialWarningModalProps = { isShow: boolean; projectId: string };

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FreshChatIconWrapper = styled.div`
  cursor: pointer;
`;

const getLocalStorageKey = (projectId: string) => `dpd-${projectId}`;

// just need some number that running every day
const isSeenThisDay = (projectId: string) => {
  const currentDate = new Date();
  const currentDateNumber = currentDate.getDate();

  const localStorageKey = getLocalStorageKey(projectId);
  const savedDate = +(localStorage.getItem(localStorageKey) || 0);

  return currentDateNumber === savedDate;
};

const FreeTrialWarningModal = (props: FreeTrialWarningModalProps) => {
  const { isShow, projectId } = props;

  const [isOpen, setIsOpen] = useState(isShow);
  const [isSeen] = useState(isSeenThisDay(projectId));

  const isDesktop = useIsDesktop();

  const onClose = () => {
    const currentDate = new Date();
    const currentDateNumber = currentDate.getDate();

    const localStorageKey = getLocalStorageKey(projectId);
    localStorage.setItem(localStorageKey, currentDateNumber.toString());

    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen && !isSeen} onClose={() => {}} closeIconColor="white" fullScreen={!isDesktop} maxWidth="sm">
      <div className="text-center pt-5 pl-5 pr-5">
        <Typography variant="h3" className="mb-3">
          ขณะนี้คุณกำลังใช้งานในระบบทดลองใช้งานฟรี (จำกัดระยะเวลา)
        </Typography>
      </div>
      <div className="text-left p-5">
        <Typography variant="body3" color="darkMed" className="mb-4">
          ระบบจะยุติการให้บริการแพลนฟรี (Free Plan) ซึ่งมีผลตั้งแต่วันที่ 24 เมษายน 2566 เวลา 10.00 น. เป็นต้นไป
          โดยหากร้านค้าต้องการใช้งานต่อ สามารถอัปเกรดเป็นแพลนอื่น ๆ หรือแพลนใหม่ในราคาพิเศษ ดังนี้
        </Typography>
        <Typography variant="body3" color="darkMed" className="mb-4 pl-2">
          - ออเดอร์ทั้งร้านค้า สูงสุด 40 ออเดอร์/เดือน ราคา 190 บาท/เดือน
        </Typography>
        <Typography variant="body3" color="darkMed" className="mb-4 pl-2">
          - ออเดอร์ทั้งร้านค้า สูงสุด 100 ออเดอร์/เดือน ราคา 290 บาท/เดือน
        </Typography>
        <Typography variant="body3" color="darkMed" className="mb-4 pl-2">
          - ออเดอร์ทั้งร้านค้า สูงสุด 200 ออเดอร์/เดือน ราคา 590 บาท/เดือน
        </Typography>
        <FlexContainer>
          <Typography variant="body3" color="darkMed">
            หากสนใจ หรือสอบถามเพิ่มเติม สามารถติดต่อผ่านทาง{" "}
          </Typography>
          <FreshChatIconWrapper
            className="ml-2"
            onClick={() => {
              window.fcWidget.open();
            }}
          >
            <img src="/assets/logo/icon-freshchat.png" alt="support" width="50" height="50" />
          </FreshChatIconWrapper>
        </FlexContainer>
        <FlexContainer className="mt-5 justify-content-end">
          <Button size="medium" className="px-4" color={ButtonColors.SECONDARY} onClick={onClose}>
            รับทราบ
          </Button>
          <NewPrimaryButton
            type="button"
            className="px-4 ml-4"
            onClick={() => {
              window.fcWidget.open();
            }}
          >
            อัปเกรดแพลน
          </NewPrimaryButton>
        </FlexContainer>
      </div>
    </Modal>
  );
};

export default FreeTrialWarningModal;
