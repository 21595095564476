import React, { memo, FC } from "react";
import { useParams } from "react-router-dom";
import { LineSpacer } from "components/SideNav/SideNav.styled";
import SideNavList from "components/SideNav/SideNavList";
import { MenuListConfigType } from "routers/routerConfig";
import useCurrentPage from "routers/useCurrentPage";
import { ProjectIdType } from "types/Project";

type MenuListPropsType = {
  lists: MenuListConfigType[];
  handleClick: () => void;
};

const MenuList: FC<MenuListPropsType> = ({ lists, handleClick }) => {
  const { projectId } = useParams<ProjectIdType>();
  const { currentPage } = useCurrentPage();

  return (
    <>
      {lists.map((list) => {
        if (list.title === "separator") {
          return <LineSpacer key={list.page} />;
        }

        return (
          <SideNavList
            key={list.page}
            currentPage={currentPage}
            Icon={list.icon}
            link={projectId ? `/project/${projectId}${list.link}` : "#"}
            onClickMenuList={handleClick}
            page={list.page}
            title={list.title}
            badgeNo={list.badgeNo}
          />
        );
      })}
    </>
  );
};

export default memo(MenuList);
