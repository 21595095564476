export const ERROR_MESSAGES = {
  EMAIL_ALREADY_EXIST: "This email has already been taken",
  EMAIL_NOT_REGISTERED: "LOGIN:EMAIL_NOT_REGISTERED",
  EMAIL_NOT_REGISTERED_ERROR: "EMAIL_NOT_REGISTERED",
  EMAIL_NOT_FOUND: "FORGOT_PASSWORD:EMAIL_NOT_FOUND",
  INCORRECT_PASSWORD: "Incorrect password",
  NOT_FOUND: "Email is not found",
  INVALID_USER_OR_PASS: "LOGIN:INCORRECT_USER_OR_PASS",
  INVALID_TOKEN: "Invalid token",
  UNAUTHORIZED: "Unauthorized",
  VERIFIED: "Your account is already verified",
};

export const UNIVERSAL_ERROR_CODES = [408, 500, 502, 503];
