import React, { useMemo } from "react";
import Grid from "components/Grid";
import { IcError } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";
import COLORS from "constants/Colors";
import useSuggestCustomerTag from "utils/hooks/useSuggestCustomerTag";
import useGetProject from "utils/hooks/useGetProject";
import EditMenuOptionsItem from "../EditMenuOptionsItem";
import { FilterItemType, FILTER_ID } from "../types";
import { FilterCardWrapper, CloseIconWrapper } from "../Filter.styled";
import { EXCLUDED_FILTER_ID_LISTS } from "../config";

import {
  getKeyName,
  getFilterValue,
  getSelectTypeFromFilterKey,
  getOptionsFromKey,
  displayFilterName,
} from "../FilterOrderStat.utils";

export default function FilterListItems(props: {
  filterList: FilterItemType[];
  removeValue: Function;
  setValue: Function;
  projectId?: string;
}) {
  const { filterList, removeValue, setValue, projectId } = props;
  const { isSupportLegacyPayment, isDeeplePayEnabled } = useGetProject(projectId);
  const { suggestCustomerTags, fetchCustomerTags, isLoadingFetchCustomerTags } = useSuggestCustomerTag(projectId);

  const initialFilters = useMemo(
    () =>
      filterList.filter((filterItem) => {
        const keyName = getKeyName(filterItem);

        return !EXCLUDED_FILTER_ID_LISTS.includes(keyName as FILTER_ID);
      }),
    [filterList],
  );

  return (
    <>
      {initialFilters.map((filterItem: FilterItemType) => {
        const keyName = getKeyName(filterItem);
        const optionsValue = getFilterValue(filterItem);
        const options = getOptionsFromKey(
          filterItem,
          optionsValue,
          suggestCustomerTags,
          isSupportLegacyPayment,
          isDeeplePayEnabled,
        );

        return (
          <FilterCardWrapper
            style={keyName === FILTER_ID.DATE_RANGE ? { border: 0, backgroundColor: "unset" } : {}}
            className="mr-2 my-1"
            key={`${filterItem.id}-custom`}
          >
            <Grid container justify="space-between" alignContent="center" alignItems="center">
              <CloseIconWrapper>
                <SvgIcon
                  className="pl-1"
                  component={IcError}
                  fontSize="inherit"
                  htmlColor={COLORS.DarkMed}
                  onClick={() => {
                    removeValue(filterItem.id);
                  }}
                />
              </CloseIconWrapper>
              <EditMenuOptionsItem
                key={filterItem.id}
                title={displayFilterName(filterItem)}
                selectType={getSelectTypeFromFilterKey(filterItem)}
                options={options}
                keyName={getKeyName(filterItem) || ""}
                filterValue={getFilterValue(filterItem)}
                filterList={filterList}
                setValue={setValue}
                editId={filterItem.id}
                loadOptions={fetchCustomerTags}
                isLoading={isLoadingFetchCustomerTags}
              />
            </Grid>
          </FilterCardWrapper>
        );
      })}
    </>
  );
}
