import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MenuProps } from "@material-ui/core/Menu";
import { PopoverMenu, MenuItem } from "components/Menu";
import { SvgIcon } from "components/Icon";
import { IcPreference, IcDelete } from "components/SvgIcons";
import { COLORS } from "constants/Colors";

const MenuList: FC<
  MenuProps & {
    isEnableCancelInvite: boolean;
    isEnableDelete: boolean;
    isEnableSetRole: boolean;
    isOwner: boolean;
    onCancelInvite: () => void;
    onClickDelete: () => void;
    onClickSetMemberRole: () => void;
    oppositeMemberRole: string;
    selectMemberRole: string;
  }
> = ({
  anchorEl,
  isEnableCancelInvite,
  isEnableDelete,
  isEnableSetRole,
  isOwner,
  onCancelInvite,
  onClickDelete,
  onClickSetMemberRole,
  onClose,
  open,
  oppositeMemberRole,
}) => {
  const { t } = useTranslation();
  return (
    <PopoverMenu anchorEl={anchorEl} keepMounted open={open} onClose={onClose}>
      {isEnableSetRole && isOwner && (
        <MenuItem onClick={onClickSetMemberRole}>
          <SvgIcon component={IcPreference} fontSize="small" htmlColor={COLORS.DarkMed} />
          <span className="ml-2">{t(`Change to ${oppositeMemberRole}`)}</span>
        </MenuItem>
      )}

      {isEnableDelete && (
        <MenuItem onClick={onClickDelete}>
          <SvgIcon component={IcDelete} fontSize="small" htmlColor={COLORS.DarkMed} />
          <span className="ml-2">{t("Delete member")}</span>
        </MenuItem>
      )}

      {isEnableCancelInvite && (
        <MenuItem onClick={onCancelInvite}>
          <SvgIcon component={IcDelete} fontSize="small" htmlColor={COLORS.DarkMed} />
          <span className="ml-2">{t("Cancel invitation")}</span>
        </MenuItem>
      )}
    </PopoverMenu>
  );
};

export default MenuList;
