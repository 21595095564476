import React, { FC, useState, createContext } from "react";
import Helmet from "react-helmet";

import { DEFAULT_TITLE, UNSEEN_NOTIFICATION_COUNT_LIMIT } from "constants/Title";

type AppTitleContextType = {
  title: string;
  setTitle: (_title: string) => void;
  unseenNotification: number;
  setUnseenNotification: (_unseenNotification: number) => void;
};

export const AppTitleContext = createContext<AppTitleContextType>({
  title: DEFAULT_TITLE,
  setTitle: () => {},
  unseenNotification: 0,
  setUnseenNotification: () => {},
});

export const AppTitleContextProvider: FC = ({ children }) => {
  const [title, setTitle] = useState<string>("");
  const [unseenNotification, setUnseenNotification] = useState<number>(0);

  const notificationCount =
    unseenNotification > UNSEEN_NOTIFICATION_COUNT_LIMIT ? `${UNSEEN_NOTIFICATION_COUNT_LIMIT}+` : unseenNotification;
  const notificationCountText = notificationCount ? `(${notificationCount}) ` : "";
  const titleText = `${notificationCountText}${DEFAULT_TITLE} | ${title}`;

  return (
    <AppTitleContext.Provider
      value={{
        title,
        setTitle: (titleInput: string) => {
          if (title !== titleInput) {
            setTitle(titleInput);
          }
        },
        unseenNotification,
        setUnseenNotification: (unseenNotificationInput: number) => {
          if (unseenNotification !== unseenNotificationInput) {
            setUnseenNotification(unseenNotificationInput);
          }
        },
      }}
    >
      <Helmet>
        <title>{titleText}</title>
      </Helmet>
      {children}
    </AppTitleContext.Provider>
  );
};
