import styled, { css } from "styled-components";

type TableWrapperPropsType = {
  isBlurContent?: boolean;
};

export const TableRowWrapper = styled.div<TableWrapperPropsType>`
  ${({ isBlurContent }) =>
    isBlurContent &&
    css`
      filter: blur(4px);
    `}
`;
