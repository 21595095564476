import React from "react";
import styled from "styled-components";
import DeepleTypography from "components/Typography";

export const GridLoading = styled.div`
  position: absolute;
  z-index: 10;
  top: calc(50% - 45px);
  left: calc(50% - 45px);
`;

export const Typography = styled(({ fontWeight: _fontWeight, ...rest }) => <DeepleTypography {...rest} />).attrs(
  ({ fontWeight, variant }) => {
    return { fontWeight, variant };
  },
)`
  ${({ variant }) => (variant ? "" : "font-size: inherit;")};
  font-weight: ${({ fontWeight }) => fontWeight || "inherit"};
`;

export const Hidden = styled(({ isHidden: _isHidden, ...rest }) => <div {...rest} />).attrs(({ isHidden }) => {
  return { isHidden };
})`
  display: ${({ isHidden }) => (isHidden ? "none" : "block")};
`;

export const DisabledReportWrapper = styled.div`
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const DisabledReportBox = styled.div`
  background-color: rgb(255 255 255 / 0.75);
  padding: 24px;
  margin-top: 64px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  text-align: center;
`;
