import { Resolvers } from "@apollo/client";
import { FLAG_USER_WITH_IS_ENTERPRISE } from "graphql/auth/query";

const resolver: Resolvers = {
  Mutation: {
    logout: (_root, _variables, { cache }) => {
      cache.writeQuery({
        query: FLAG_USER_WITH_IS_ENTERPRISE,
        data: {
          me: null,
        },
      });
      return null;
    },
  },
};

export default resolver;
