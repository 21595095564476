import React, { FC } from "react";
import { TooltipProps } from "recharts";
import moment from "moment";
import numeral from "numeral";

import Card from "components/Card";
import Typography from "components/Typography";
import { BKK_TIMEZONE } from "constants/Time";

const CustomTooltip: FC<TooltipProps> = ({ active, payload, label }) => {
  if (active) {
    return (
      <Card>
        <Typography variant="title2">{payload && numeral(payload[0].value).format("0,0")}</Typography>
        <Typography variant="body3" color="darkMed">
          {moment(label).utc().utcOffset(BKK_TIMEZONE).format("HH:mm DD/MM/YYYY")}
        </Typography>
      </Card>
    );
  }

  return null;
};

export default CustomTooltip;
