import React, { FC } from "react";
import { NO_IMG_AVAILABLE } from "constants/Image";
import Typography from "components/Typography";
import Grid from "components/Grid";
import ProductDetail from "./ProductDetail";

type ProductProfile = {
  productImage: string;
  productName: string;
  price: string | number;
  variations: {
    key: string;
    value: string[];
  }[];
};

const ProductProfile: FC<ProductProfile> = ({ productImage, productName, price, variations }) => {
  return (
    <Grid container className="p-2 flex-nowrap">
      <Grid item className="flex-grow-0">
        <img
          src={productImage || NO_IMG_AVAILABLE}
          style={{
            objectFit: "contain",
            textAlign: "center",
          }}
          alt="product"
          width="60"
          height="60"
        />
      </Grid>
      <Grid item className="d-flex flex-column justify-content-center flex-grow-1 pl-3">
        <Grid container className="mb-1">
          <Grid item>
            <Typography variant="title8">{productName}</Typography>
          </Grid>
        </Grid>
        <ProductDetail variations={variations} price={price} />
      </Grid>
    </Grid>
  );
};

export default ProductProfile;
