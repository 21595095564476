import React, { FC } from "react";
import get from "lodash/get";
import Card from "components/Card";
import Grid from "components/Grid";
import { NO_IMG_DEEPLE_LOGO } from "constants/Image";
import { ProductImageType } from "types/Product";

import { ProductImage } from "../styled";

type ProductItemPropsType = {
  images: ProductImageType[];
  name: string;
};

const ProductItem: FC<ProductItemPropsType> = ({ images, name }) => {
  const productImage = get(images, "0.src") || NO_IMG_DEEPLE_LOGO;

  return (
    <Grid item xs={12} className="pt-3">
      <Card noShadow>
        <Grid container alignItems="center">
          <ProductImage src={productImage} />
          <Grid item container xs={9} className="pl-3">
            <Grid item xs={12} className="ellipsis">
              {name}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default ProductItem;
