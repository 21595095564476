import { gql } from "@apollo/client";

export const ADD_LIVE_CRAWLER = gql`
  mutation createLiveCrawler($projectId: ID!, $liveCrawler: LiveCrawlerInput!) {
    createLiveCrawler(projectId: $projectId, liveCrawler: $liveCrawler) {
      id
      title
      postId
      videoId
      platform
      isActive
      numberOfReach
      numberOfEngagement
      newOrderConversionRate
      orderPaidConversionRate
      orderPaidConversionTotal
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_LIVE_CRAWLER = gql`
  mutation updateLiveCrawler($projectId: ID!, $liveCrawlerId: ID!, $liveCrawler: LiveCrawlerInput!) {
    updateLiveCrawler(projectId: $projectId, liveCrawlerId: $liveCrawlerId, liveCrawler: $liveCrawler) {
      id
      title
      postId
      videoId
      platform
      isActive
      numberOfReach
      numberOfEngagement
      newOrderConversionRate
      orderPaidConversionRate
      orderPaidConversionTotal
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_LIVE_CRAWLER = gql`
  mutation deleteLiveCrawler($projectId: ID!, $liveCrawlerId: ID!) {
    deleteLiveCrawler(projectId: $projectId, liveCrawlerId: $liveCrawlerId) {
      id
    }
  }
`;

export const ADD_COMMENT_CRAWLER = gql`
  mutation addCommentCrawler($projectId: ID!, $commentCrawler: AddCommentCrawlerInput!) {
    addCommentCrawler(projectId: $projectId, commentCrawler: $commentCrawler) {
      createdAt
      id
      isActive
      isWatchedAllComments
      newOrderConversionRate
      numberOfEngagement
      numberOfReach
      orderPaidConversionRate
      platform
      postId
      projectId
      replyPostCommentMessage
      reservedWords
      title
      type
      updatedAt
      responseMessage {
        type
        value
      }
    }
  }
`;

export const UPDATE_COMMENT_CRAWLER = gql`
  mutation updateCommentCrawler($projectId: ID!, $commentCrawlerId: ID!, $commentCrawler: UpdateCommentCrawlerInput!) {
    updateCommentCrawler(projectId: $projectId, commentCrawlerId: $commentCrawlerId, commentCrawler: $commentCrawler) {
      createdAt
      id
      isActive
      isWatchedAllComments
      newOrderConversionRate
      numberOfEngagement
      numberOfReach
      orderPaidConversionRate
      platform
      postId
      projectId
      replyPostCommentMessage
      reservedWords
      title
      type
      updatedAt
      responseMessage {
        type
        value
      }
    }
  }
`;

export const DELETE_COMMENT_CRAWLER = gql`
  mutation deleteCommentCrawler($projectId: ID!, $commentCrawlerId: ID!) {
    deleteCommentCrawler(projectId: $projectId, commentCrawlerId: $commentCrawlerId) {
      id
    }
  }
`;
