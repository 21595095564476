import { useMediaQuery } from "react-responsive";
import { useTheme } from "@material-ui/core/styles";

const useIsDesktop = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.values.lg });
  return isDesktop;
};

export default useIsDesktop;
