import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { CONGRATULATIONS } from "constants/i18n";
import { NewPrimaryButton } from "components/Button";
import Typography from "components/Typography";
import Modal from "components/Modal";
import useIsDesktop from "utils/hooks/useIsDesktop";

type CongratulationsProps = { isOpen?: boolean; onSubmit?: () => void };

const Congratulations: FC<CongratulationsProps> = ({ isOpen = false, onSubmit }) => {
  const { t } = useTranslation(CONGRATULATIONS);
  const isDesktop = useIsDesktop();
  return (
    <Modal isOpen={isOpen} onClose={() => {}} closeIconColor="white" fullScreen={!isDesktop} maxWidth="xs">
      <div className="text-center p-5">
        <img src="assets/img/congratulation.png" alt="congratulation" width="300" height="250" className="mb-2" />
        <Typography variant="h3" className="mb-3">
          {t("CONGRATULATIONS")}
          <img src="assets/icon/party-popper.png" alt="congratulations logo" width={29} height={29} />
        </Typography>
        <Typography variant="body3" color="darkMed" className="mb-4">
          {t("YOUR_EMAIL_ALREADY_CONFIRMED")}
        </Typography>
        <NewPrimaryButton
          type="button"
          className="px-4"
          onClick={() => {
            onSubmit?.();
          }}
        >
          {t("CONTINUE")}
        </NewPrimaryButton>
      </div>
    </Modal>
  );
};

export default Congratulations;
