import React, { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { IcAdd } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";
import Typography from "components/Typography";
import Grid from "components/Grid";
import { PROJECT, PLAN, PLAN_ADDON } from "constants/Router";

import { InfoItemType } from "types/PlanUsage";
import { ProjectIdType } from "types/Project";
import useIsDesktop from "utils/hooks/useIsDesktop";

import InFoItem from "../InfoItem";
import { GridBottomCard, GridCard, ItemListWrapper } from "../styled";

type InfoItemListPropsType = {
  data: InfoItemType[];
  limit: number;
  title: string;
  isBuyMoreEnabled?: boolean;
  addNewText?: string;
  buyMoreDescription?: string;
  buyMoreLabel?: string;
  manageRoute: string;
};

const InfoItemList: FC<InfoItemListPropsType> = (props) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const { projectId } = useParams<ProjectIdType>();

  const {
    data = [],
    limit,
    title,
    isBuyMoreEnabled,
    addNewText,
    buyMoreDescription,
    buyMoreLabel,
    manageRoute,
  } = props;

  const dataLength = data.length;

  return (
    <Grid container>
      <Grid item container xs={12} justify="space-between" className="px-3">
        <Typography variant="body3" color="darkGray" className="pl-1">
          {t(title)}
        </Typography>
        <Typography variant="body3" color="darkGray">
          {dataLength} {t("planUsage.limit.conjunction")} {limit}
        </Typography>
      </Grid>
      <Grid item container xs={12} className={`pt-3 ${isDesktop ? "px-3" : ""}`}>
        {data.map(({ title: titleData, description }: InfoItemType, index: number) => {
          return (
            <GridCard
              item
              xs={12}
              key={titleData}
              index={index}
              amountOfItems={dataLength}
              isContainMoreCardAction={Boolean(isBuyMoreEnabled && buyMoreDescription && buyMoreLabel)}
              isDesktop={isDesktop}
            >
              <ItemListWrapper>
                <InFoItem title={titleData} description={description} manageLink={`${manageRoute}#${titleData}`} />
              </ItemListWrapper>
            </GridCard>
          );
        })}
        {addNewText && dataLength < limit && (
          <GridCard item xs={12} isDesktop={isDesktop}>
            <ItemListWrapper>
              <Link to={manageRoute}>
                <Grid container item xs={12} className="py-2">
                  <Typography variant="body3" color="primary">
                    <SvgIcon className="px-0 py-0 mr-2" component={IcAdd} fontSize="small" />
                    {t(addNewText)}
                  </Typography>
                </Grid>
              </Link>
            </ItemListWrapper>
          </GridCard>
        )}
        {isBuyMoreEnabled && buyMoreDescription && buyMoreLabel && (
          <GridBottomCard item xs={12} isDesktop={isDesktop}>
            <ItemListWrapper>
              <Grid container item xs={12} className="py-1">
                <Typography variant="body3" color="darkGray" className="pr-3">
                  {t(buyMoreDescription)}
                </Typography>
                <Link to={`/${PROJECT}/${projectId}/${PLAN}/${PLAN_ADDON}`}>
                  <Typography variant="body3" color="primary">
                    {t(buyMoreLabel)}
                  </Typography>
                </Link>
              </Grid>
            </ItemListWrapper>
          </GridBottomCard>
        )}
      </Grid>
    </Grid>
  );
};

export default InfoItemList;
