import React, { FC } from "react";
import moment from "moment";
import { BroadcastCampaignDataType } from "types/Broadcast";
import Grid from "components/Grid";
import Typography from "components/Typography";
import Card from "components/Card";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { COLOR_BY_STATUS, BROADCAST_STATUS, BROADCAST_STATUS_DESCRIPTION } from "constants/Broadcast";
import styled from "styled-components";
import { BROADCAST_DATE_TIME_FORMAT } from "constants/DateTimeFormat";
import { convertPriceFormat } from "utils/common";

const Bullet = styled(({ color: _color, ...rest }) => <span {...rest} />).attrs(({ color }) => {
  return { color };
})`
  min-width: 6px;
  min-height: 6px;
  max-width: 6px;
  max-height: 6px;
  background-color: ${({ color }) => color};
  border-radius: 10px;
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
`;

const Status = styled(({ color: _color, ...rest }) => <span {...rest} />).attrs(({ color }) => {
  return { color };
})`
  font-weight: 500;
  text-transform: capitalize;
  color: ${({ color }) => color};
`;

type BroadcastStatusLabelPropsType = {
  broadcastCampaignGQLData: BroadcastCampaignDataType;
  isDraft: boolean;
};

const BroadcastStatusLabel: FC<BroadcastStatusLabelPropsType> = ({ broadcastCampaignGQLData, isDraft }) => {
  const { t } = useTranslation();

  const broadcastStatus = get(broadcastCampaignGQLData, "broadcastCampaign.status");

  const numberOfAudience = get(broadcastCampaignGQLData, "broadcastCampaign.numberOfAudience");

  const numberOfEngagement = get(broadcastCampaignGQLData, "broadcastCampaign.numberOfEngagement");

  const newOrderConversionRate = get(broadcastCampaignGQLData, "broadcastCampaign.newOrderConversionRate");

  const orderPaidConversionRate = get(broadcastCampaignGQLData, "broadcastCampaign.orderPaidConversionRate");

  const orderPaidConversionTotal = get(broadcastCampaignGQLData, "broadcastCampaign.orderPaidConversionTotal");

  const percentOfEngagement = numberOfAudience ? (numberOfEngagement / numberOfAudience) * 100 : 0;
  const percentOfEngagementText = numberOfEngagement != null ? `${percentOfEngagement.toFixed(2)}%` : "-";

  const newOrderConversionRateValue = numberOfAudience ? (newOrderConversionRate / numberOfAudience) * 100 : 0;
  const newOrderConversionRateText =
    newOrderConversionRate != null ? `${newOrderConversionRateValue.toFixed(2)}%` : "-";

  const orderPaidConversionRateValue = numberOfAudience ? (orderPaidConversionRate / numberOfAudience) * 100 : 0;
  const orderPaidConversionRateText =
    orderPaidConversionRate != null ? `${orderPaidConversionRateValue.toFixed(2)}%` : "-";

  const orderPaidConversionTotalText =
    orderPaidConversionTotal != null ? convertPriceFormat(orderPaidConversionTotal) : "-";

  return (
    <>
      {broadcastStatus && (
        <Grid container item xs={12} className="pt-0 px-3 pb-3">
          <Card fullWidth>
            <Grid container item xs={12} className="my-1">
              <Grid container item xs={12} sm={5} alignContent="center" alignItems="center" className="pr-2 py-2">
                <Grid item xs={2}>
                  <Bullet color={COLOR_BY_STATUS[broadcastStatus]} />
                  <Status color={COLOR_BY_STATUS[broadcastStatus]}>{t(BROADCAST_STATUS[broadcastStatus])}</Status>
                </Grid>
                <Grid item xs={10} className="pl-3">
                  <Typography variant="body3" color="darkGray">
                    {t(BROADCAST_STATUS_DESCRIPTION[broadcastStatus])}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} sm={7} alignContent="center" alignItems="flex-start" className="pl-2">
                <Grid className="mt-2 pr-2" item xs={12} sm={6} container justify="space-between">
                  <Grid item xs={3}>
                    <Typography variant="title10" color="darkGray">
                      {t("Engagement")}
                    </Typography>
                    <Typography variant="body3" color="darkGray">
                      {percentOfEngagementText}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="title10" color="darkGray">
                      {t("broadcast.conversionRate")}
                    </Typography>
                    <Typography variant="body3" color="darkGray">
                      {newOrderConversionRateText}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="title10" color="darkGray">
                      {t("broadcast.orderPaid")}
                    </Typography>
                    <Typography variant="body3" color="darkGray">
                      {orderPaidConversionRateText}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="title10" color="darkGray">
                      {t("broadcast.orderPaidConversionTotal")}
                    </Typography>
                    <Typography variant="body3" color="darkGray">
                      {orderPaidConversionTotalText}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid className="mt-2" item xs={12} sm={6} container justify="space-between">
                  <Grid item xs={6}>
                    <Typography variant="title10" color="darkGray">
                      {t("broadcast.broadcastTime")}
                    </Typography>
                    <Typography variant="body3" color="darkGray">
                      {!isDraft
                        ? moment(get(broadcastCampaignGQLData, "broadcastCampaign.broadcastDate")).format(
                            BROADCAST_DATE_TIME_FORMAT,
                          )
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="title10" color="darkGray">
                      {t("Created at")}
                    </Typography>
                    <Typography variant="body3" color="darkGray">
                      {moment(get(broadcastCampaignGQLData, "broadcastCampaign.createdAt")).format(
                        BROADCAST_DATE_TIME_FORMAT,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
    </>
  );
};

export default BroadcastStatusLabel;
