import * as React from "react";

const IcHelpRequest = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.06,0A13.7,13.7,0,0,0,2.36,13.7a13.11,13.11,0,0,0,8.39,12.58c.53.2-1.52,5.4-1.36,5.72,6-2.78,10.46-5,12.13-5.72A13.71,13.71,0,0,0,16.06,0Zm.85,21.86a1.53,1.53,0,0,1-1.1.45,1.48,1.48,0,0,1-1.09-.45,1.56,1.56,0,0,1,0-2.19,1.45,1.45,0,0,1,1.09-.46,1.5,1.5,0,0,1,1.1.46,1.53,1.53,0,0,1,0,2.19Zm3.8-9.62A6.33,6.33,0,0,1,19,13.93l-1.09.73a2.16,2.16,0,0,0-.79,1,3.33,3.33,0,0,0-.25,1.29v.18c0,.06,0,.15,0,.27H14.79c0-.14,0-.27,0-.4v-.42a4.63,4.63,0,0,1,.45-2.09,4.31,4.31,0,0,1,1.51-1.61l1-.71a2.75,2.75,0,0,0,.94-1,2.85,2.85,0,0,0,.14-2.31A2.65,2.65,0,0,0,18.33,8a2.45,2.45,0,0,0-.88-.6,3.16,3.16,0,0,0-1.24-.22,3.05,3.05,0,0,0-1.34.26,2.62,2.62,0,0,0-.91.68,3.15,3.15,0,0,0-.53,1,3.59,3.59,0,0,0-.18,1.12,4.7,4.7,0,0,0,.05.63,2.23,2.23,0,0,0,.12.52L11.12,11A4.34,4.34,0,0,1,11,9.9a4.81,4.81,0,0,1,.31-1.66,4.6,4.6,0,0,1,1-1.58A4.81,4.81,0,0,1,13.86,5.5a5.6,5.6,0,0,1,2.35-.45,5.68,5.68,0,0,1,2.2.41A4.79,4.79,0,0,1,20,6.52a4.35,4.35,0,0,1,1,1.53,4.67,4.67,0,0,1,.34,1.76A4.26,4.26,0,0,1,20.71,12.24Z"
    />
  </svg>
);

export default IcHelpRequest;
