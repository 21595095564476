import moment from "moment";
import { DASHBOARD_DATE_TIME_FORMAT } from "constants/DateTimeFormat";

// Duration info
// 0 is today
// 1 is yesterday
// 7 is last week
// 30 is last month

export const getStatStartTime = (duration: number) => {
  return moment().subtract(duration, "days").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).utc().toISOString();
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getStatEndTime = (duration: number) => {
  return duration === 1
    ? moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).utc().toISOString()
    : moment().utc().toISOString();
};

export const getDateTimeDiff = (startTime: string, endTime: string, unit: "days" | "hours" | "minutes" = "days") => {
  const start = moment(startTime, DASHBOARD_DATE_TIME_FORMAT);
  const end = moment(endTime, DASHBOARD_DATE_TIME_FORMAT);
  const timeDiff = end.diff(start, unit);

  return timeDiff;
};
