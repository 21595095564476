import React, { useState, FC, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import isEqual from "lodash/isEqual";
import { CATEGORY_WITH_HIGHLIGHT_PRODUCTS } from "graphql/category/query";
import { UPDATE_HIGHLIGHT_PRODUCTS } from "graphql/category/mutation";
import { Product } from "types/Product";
import { CategoryType } from "types/Category";
import Modal from "components/Modal";
import Typography from "components/Typography";
import useDevice from "utils/hooks/useDevice";
import Button from "components/Button";
import { Device } from "types/Device";
import { NO_IMG_AVAILABLE } from "constants/Image";
import { useTranslation } from "react-i18next";
import { notifySuccess, notifyError } from "utils/notify";

import { ModalHeader, ModalWrapper, ModalBodyWrapper, ButtonWrapper, TitleWrapper } from "./styled";

import ProductPreview from "../ProductPreview";

interface ProductHighLightPropsType {
  projectId: string;
  categoryId: string;
  isOpen: boolean;
  onClose: () => void;
}

interface CategoryData {
  category: CategoryType;
}

interface CategoryVars {
  projectId: string;
  id: string;
}

interface UpdateHighlightProductsData {
  updateCategoryHighlightProducts: CategoryType;
}

interface UpdateHighlightProductsVars {
  projectId: string;
  id: string;
  productIds: ReadonlyArray<string>;
}

const ManageProductHighLight: FC<ProductHighLightPropsType> = ({ projectId, categoryId, isOpen, onClose }) => {
  const device = useDevice();
  const { t } = useTranslation();

  const isFullScreen = device !== Device.DESKTOP;
  const [initialHighlightProductIds, setInitialHighlightProductIds] = useState<ReadonlyArray<string | undefined>>([]);
  const [currentHighlightProductIds, setCurrentHighlightProductIds] = useState<ReadonlyArray<string | undefined>>([]);
  const { data: { category } = {} } = useQuery<CategoryData, CategoryVars>(CATEGORY_WITH_HIGHLIGHT_PRODUCTS, {
    variables: { projectId, id: categoryId },
    fetchPolicy: "cache-first",
    skip: !projectId || !categoryId,
  });

  const [updateHighlightProducts] = useMutation<UpdateHighlightProductsData, UpdateHighlightProductsVars>(
    UPDATE_HIGHLIGHT_PRODUCTS,
    {
      onCompleted: () => {
        notifySuccess(t("Save successfully"));
      },
      onError: () => {
        notifyError("Save failed!");
      },
    },
  );

  useEffect(() => {
    if (category?.highlightProducts) {
      const highlightProductIds = category?.highlightProducts.map((highlightProduct: Product) => highlightProduct.id);
      setInitialHighlightProductIds(highlightProductIds);
      setCurrentHighlightProductIds(highlightProductIds);
    }
  }, [category]);

  const onChangeHighlightProducts = (highlightProductIds: ReadonlyArray<string>) => {
    setCurrentHighlightProductIds(highlightProductIds);
  };

  const handleClose = () => {
    setCurrentHighlightProductIds([]);
    onClose();
  };

  const onSaveHighlightProducts = async () => {
    if (!isEqual(initialHighlightProductIds, currentHighlightProductIds)) {
      await updateHighlightProducts({
        variables: { projectId, id: categoryId, productIds: currentHighlightProductIds as ReadonlyArray<string> },
      });
      handleClose();
      return;
    }
    handleClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      fullScreen={isFullScreen}
      maxWidth="xl"
      onClose={handleClose}
      disableAutoFocus
      disableEnforceFocus
    >
      <ModalHeader>
        <Typography variant="title7" color="white" className="ml-4">
          {t("editHighlightProduct")}
        </Typography>
      </ModalHeader>
      <ModalWrapper className="p-3" isFullScreen={isFullScreen}>
        <ModalBodyWrapper className="mb-4 p-2">
          <TitleWrapper className="p-3">
            <img src={category?.image || NO_IMG_AVAILABLE} alt="category" height={80} width={80} />
            <Typography variant="title2" color="black" className="ml-4">
              {category?.name}
            </Typography>
          </TitleWrapper>

          <ProductPreview
            categoryId={categoryId}
            categoryName={category?.name || ""}
            projectId={projectId}
            highlightProducts={(category?.highlightProducts as Product[]) || []}
            onChangeHighlightProducts={onChangeHighlightProducts}
          />
        </ModalBodyWrapper>
        <ButtonWrapper className="px-2">
          <Button
            size="large"
            type="button"
            color="primary"
            onClick={onSaveHighlightProducts}
            minWidth="218"
            disabled={isEqual(initialHighlightProductIds, currentHighlightProductIds)}
          >
            {t("Save")}
          </Button>
        </ButtonWrapper>
      </ModalWrapper>
    </Modal>
  );
};

export default ManageProductHighLight;
