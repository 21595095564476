import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import moment from "moment";

import Typography from "components/Typography";
import COLORS from "constants/Colors";
import { SvgIcon } from "components/Icon";
import { IcOutcome } from "components/SvgIcons";
import { convertPriceFormat } from "utils/common";

import i18n from "utils/i18n";
import MobileCollapse from "../../components/MobileCollapse";

import { DEFAULT_VALUE } from "../config";

interface MobileIncomeTransactionPropTypes {
  data: any;
}

interface MobileIncomeTransactionPropTypes {
  data: any;
}

const MobileOutcomeTransaction: FC<MobileIncomeTransactionPropTypes> = ({ data }) => {
  return data.map((transaction: any) => {
    return (
      <MobileCollapse
        key={transaction.deeplePayTransactionId}
        leftSideRender={
          <Box display="flex" alignItems="center">
            <Typography variant="body3" color={COLORS.DarkGray}>
              {transaction.email}
            </Typography>
          </Box>
        }
        rightSideRender={
          <Box textAlign="right">
            <Typography variant="body3" color={COLORS.DarkGray}>
              <SvgIcon component={IcOutcome} className="p-0" viewBox="0 -6 20 20" />฿ {transaction.amount}
            </Typography>
            <Typography variant="body4" color={COLORS.DarkGray}>
              {i18n.t(`deeplePay.transaction.table.${transaction.status?.toLowerCase()}`)}
            </Typography>
          </Box>
        }
        expandRender={
          <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" className="my-2">
              <Typography variant="title8" color={COLORS.DarkGray}>
                {i18n.t("deeplePay.transaction.table.withdrawal.row.accountNo")}
              </Typography>
              <Typography variant="body3" color={COLORS.DarkGray}>
                {transaction?.detail?.bank} {transaction?.detail?.bankAccountNumber}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" className="my-2">
              <Typography variant="title8" color={COLORS.DarkGray}>
                {i18n.t("deeplePay.transaction.table.income.row.receivedAmount")}
              </Typography>
              <Typography variant="body3" color={COLORS.DarkGray}>
                ฿ {transaction?.receivedAmount}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" className="my-2">
              <Typography variant="title8" color={COLORS.DarkGray}>
                {i18n.t("deeplePay.transaction.table.withdrawal.row.fee")}
              </Typography>
              <Typography variant="body3" color={COLORS.DarkGray}>
                {`${typeof transaction.fee === "number" ? `฿ ${convertPriceFormat(transaction.fee)}` : DEFAULT_VALUE}`}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" className="my-2">
              <Typography variant="title8" color={COLORS.DarkGray}>
                {i18n.t("deeplePay.transaction.table.withdrawal.row.referenceNo")}
              </Typography>
              <Typography variant="body3" color={COLORS.DarkGray}>
                {transaction.referenceNo}
              </Typography>
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" className="my-2">
              <Typography variant="title8" color={COLORS.DarkGray}>
                {i18n.t("deeplePay.transaction.table.withdrawal.row.refundDate")}
              </Typography>
              <Typography variant="body3" color={COLORS.DarkGray}>
                {moment(transaction.refundDate).format("DD MMM YYYY HH:mm")}
              </Typography>
            </Box>
          </Box>
        }
      />
    );
  });
};

export default MobileOutcomeTransaction;
