import React, { FC } from "react";
import Typography from "components/Typography";
import Grid from "components/Grid/index";
import { useTranslation } from "react-i18next";

interface DeeplePayFeeInvoiceTableHeaderPropTypes {
  isMobile: boolean;
}

const DeeplePayFeeInvoiceTableHeader: FC<DeeplePayFeeInvoiceTableHeaderPropTypes> = (prop) => {
  const { t } = useTranslation();

  const { isMobile } = prop;

  return (
    <Grid container alignItems="center" className="py-2">
      <Grid item xs={4}>
        <Typography variant="title10" color="darkLight">
          {t("deeplePay.feeInvoice.table.header.month")}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="title10" color="darkLight" className={isMobile ? "text-right" : "text-left"}>
          {t("deeplePay.feeInvoice.table.header.amount")}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="title10" color="darkLight" className={isMobile ? "text-right" : "text-left"}>
          {t("deeplePay.feeInvoice.table.header.file")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DeeplePayFeeInvoiceTableHeader;
