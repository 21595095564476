import * as React from "react";

const SvgIcProduct = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <defs>
      <path id="ic-product_svg__a" d="M0 0h32v32H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="ic-product_svg__b" fill="#fff">
        <use xlinkHref="#ic-product_svg__a" />
      </mask>
      <path
        fill="currentColor"
        d="M15.114 10.023l-1.894 4.6a.452.452 0 01-.378.278l-9.688.851a.451.451 0 00-.412.462l.197 7.605a.45.45 0 00.358.43l11.406 2.399c.278.059.562.088.845.087V10.109c0-.628-.195-.666-.434-.086zm-1.326 14.182l-4.282-.69v-2.244h4.282v2.934zm-1.873-10.397L.498 14.96a.452.452 0 01-.455-.64l3.04-6.499a.452.452 0 01.328-.253l12.546-2.304-3.674 8.277a.452.452 0 01-.368.266zm16.931 1.944l-9.688-.851a.451.451 0 01-.378-.278l-1.894-4.6c-.239-.58-.434-.542-.434.086v16.626c.283 0 .567-.029.845-.087l11.406-2.4a.452.452 0 00.358-.43l.197-7.604a.452.452 0 00-.412-.462zm-3.987 4.062l-.535.015-.01 3.368c0 .148-.152.29-.339.317l-.877.126c-.2.029-.364-.074-.364-.23l.008-3.537-.612.017c-.297.008-.486-.23-.334-.428l1.456-1.897c.137-.18.476-.163.609.027l1.277 1.82c.121.173-.032.395-.279.402zm6.643-4.853l-11.417-1.153a.451.451 0 01-.368-.266l-3.674-8.277L28.59 7.57c.144.027.266.12.327.253l3.04 6.499a.452.452 0 01-.454.64z"
        mask="url(#ic-product_svg__b)"
      />
    </g>
  </svg>
);

export default SvgIcProduct;
