const validateFileSize = (file: File, maxSize: number) => {
  const maxSizeInByte = maxSize * 1024 * 1024;
  if (maxSizeInByte > file.size) {
    return false;
  }

  return true;
};

export default validateFileSize;
