/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

import { QR_CASH_LOGO, QR_CREDIT_LOGO } from "constants/Image";

import { IcInfo } from "components/SvgIcons";
import Switch from "components/Switch";
import Typography from "components/Typography";

import { DeeplePayPaymentChannelDetail } from "types/DeeplePay";

import useIsDesktop from "utils/hooks/useIsDesktop";

import { FeeBox, DeeplePayWarning, Item, Option } from "../styled";

export interface QRCodeProps {
  qrCashData: any;
  qrCreditData: any;
  onQRCashChange: (isActive: boolean) => void;
  onQRCreditChange: (isActive: boolean) => void;
}

const QRCode: FC<QRCodeProps> = ({ qrCashData, qrCreditData, onQRCashChange, onQRCreditChange }) => {
  // Hooks
  const isDesktop = useIsDesktop();
  const { t } = useTranslation();

  // Form hooks for QR Cash
  const [qrCashChannels] = useState<DeeplePayPaymentChannelDetail[]>(qrCashData?.channels || []);
  const [isActiveQRCash, setIsActiveQRCash] = useState<boolean>(qrCashData?.isActive || false);
  const [isDisableQRCashSwitch] = useState<boolean>(!qrCashData?.isSelectable || false);

  // Form hooks for QR Credit
  const [qrCreditChannels] = useState<DeeplePayPaymentChannelDetail[]>(qrCreditData?.channels || []);
  const [isActiveQRCredit, setIsActiveQRCredit] = useState<boolean>(qrCreditData?.isActive || false);
  const [isDisableQRCreditSwitch] = useState<boolean>(!qrCreditData?.isSelectable || false);

  // Event handler for QR Cash
  const handleChangeQRCash = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsActiveQRCash(checked);
    onQRCashChange(checked);
  };

  const handleChangeQRCredit = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsActiveQRCredit(checked);
    onQRCreditChange(checked);
  };

  // // Life-cycle hooks
  // useEffect(() => {
  //   if (qrCashData) {
  //     setQRCashChannels(qrCashData.channels || []);
  //     setIsActiveQRCash(qrCashData.isActive);
  //     setIsDisableQRCashSwitch(!qrCashData.isSelectable);
  //   }
  // }, [qrCashData]);

  // useEffect(() => {
  //   if (qrCreditData) {
  //     setQRCreditChannels(qrCreditData.channels || []);
  //     setIsActiveQRCredit(qrCreditData.isActive);
  //     setIsDisableQRCreditSwitch(!qrCreditData.isSelectable);
  //   }
  // }, [qrCreditData]);

  // Renderer
  if (!(qrCashData || qrCreditData)) {
    return null;
  }

  return (
    <Item container alignItems="center" className={isDesktop ? "mb-3 px-4 py-3" : "mobile mb-3 px-4 py-3"}>
      <Grid item xs={12} className="pb-3 mb-1">
        <Typography variant="title3">{t("deeplePay.paymentChannel.qrCode.label")}</Typography>
      </Grid>

      {/* QR Cash */}
      {qrCashData && (
        <Option
          container
          item
          xs={12}
          alignItems="center"
          className={qrCreditData ? "first line p-4" : "first last p-4"}
        >
          <Grid item xs={isDesktop ? 1 : 12}>
            <Switch checked={isActiveQRCash} disabled={isDisableQRCashSwitch} onChange={handleChangeQRCash} />
          </Grid>
          <Grid item className="mr-3" justify="flex-start">
            <img src={QR_CASH_LOGO} alt="qr-cash" />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 9} className={isDesktop ? "" : "mt-3"}>
            <Typography variant="title8">{t("deeplePay.paymentChannel.qrCash.title")}</Typography>
            <Typography variant="body4">{t("deeplePay.paymentChannel.qrCash.description")}</Typography>
            {!isDesktop && (
              <Typography variant="body3">
                {qrCashChannels[0]?.fee?.rate &&
                  t("deeplePay.paymentChannel.fee.percent.label", {
                    value: qrCashChannels[0]?.fee?.rate,
                  })}
              </Typography>
            )}
          </Grid>
          {isDesktop && (
            <FeeBox item xs={4} justify="flex-end">
              <Typography variant="body3">
                {qrCashChannels[0]?.fee?.rate &&
                  t("deeplePay.paymentChannel.fee.percent.label", {
                    value: qrCashChannels[0]?.fee?.rate,
                  })}
              </Typography>
            </FeeBox>
          )}
        </Option>
      )}

      {/* QR Credit */}
      {qrCreditData && (
        <Option container item xs={12} alignItems="center" className="last p-4">
          <Grid item xs={isDesktop ? 1 : 12}>
            <Switch checked={isActiveQRCredit} disabled={isDisableQRCreditSwitch} onChange={handleChangeQRCredit} />
          </Grid>
          <Grid item className="mr-3" justify="flex-start">
            <img src={QR_CREDIT_LOGO} alt="qr-credit" />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 9} className={isDesktop ? "" : "mt-3"}>
            <Typography variant="title8">{t("deeplePay.paymentChannel.qrCredit.title")}</Typography>
            <Typography variant="body4">{t("deeplePay.paymentChannel.qrCredit.description")}</Typography>
            {!isDesktop && (
              <Typography variant="body3">
                {qrCreditChannels[0]?.fee?.rate &&
                  t("deeplePay.paymentChannel.fee.percent.label", {
                    value: qrCreditChannels[0]?.fee?.rate,
                  })}
              </Typography>
            )}
          </Grid>
          {isDesktop && (
            <FeeBox item xs={4} justify="flex-end">
              <Typography variant="body3">
                {qrCreditChannels[0]?.fee?.rate &&
                  t("deeplePay.paymentChannel.fee.percent.label", {
                    value: qrCreditChannels[0]?.fee?.rate,
                  })}
              </Typography>
            </FeeBox>
          )}
        </Option>
      )}

      <div className="w-100">
        <DeeplePayWarning className="mt-3">
          <IcInfo />
          {t("deeplePay.paymentChannel.notification.label")}
        </DeeplePayWarning>
      </div>
    </Item>
  );
};

export default QRCode;
