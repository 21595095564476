import React, { FC } from "react";
import { NumberFormatCustom } from "components/NumberFormatCustom";
import TextField from "./TextField.styled";
import { TextFieldPropsType } from "./index";

const PriceField: FC<TextFieldPropsType> = (props) => {
  return (
    <TextField
      {...props}
      type="text"
      InputProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: NumberFormatCustom as any,
      }}
    />
  );
};

export default PriceField;
