import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import get from "lodash/get";
import { OptionType, ValueType } from "react-select";
import ErrorText from "components/ErrorText";
import Grid from "components/Grid";
import TextField from "components/TextField";
import Select from "components/Select";
import Typography from "components/Typography";
import ImageUpload from "components/ImageUpload";
import {
  PROMPT_PAY_ACCOUNT_NAME_KEY,
  PROMPT_PAY_ACCOUNT_NUMBER_KEY,
  PROMPT_PAY_CUSTOM_QR_IMAGE,
  PROMPT_PAY_TYPE_KEY,
} from "constants/Payment";
import { ImageUrl } from "types/Image";
import { DetailContent } from "domain/DeeplePay/PaymentChannel/styled";

type PromptPayFormType = {
  accountName?: string;
  accountNumber?: string;
  promptpayType?: string;
  image?: string;
  onChange: (fieldName: string, value: string) => void;
  setValue: (
    name: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    config?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
        }>
      | undefined,
  ) => void;
  showSaveButton: () => void;
};

const PromptPayForm: FC<PromptPayFormType> = (props) => {
  const { t } = useTranslation();
  const { register, errors } = useFormContext();
  const { accountName, accountNumber, promptpayType, image: imageProp, setValue, showSaveButton, onChange } = props;
  const [image, setImage] = useState<string | undefined>(imageProp);
  const [qrType, setQrType] = useState<string | undefined>(promptpayType);

  const dropdownTypeOptions: OptionType[] = [
    { value: "PROMPT_PAY", label: t("Promptpay QR (Phone/ThaiID)") },
    { value: "BILLER_ID", label: t("Promptpay Bill Payment") },
  ];
  const handleTypeChange = (val: ValueType<OptionType>) => {
    onChange("PromptpayType", (val as OptionType).value);
    console.log(PROMPT_PAY_TYPE_KEY, (val as OptionType).value);
    setQrType((val as OptionType).value);
    if ((val as OptionType).value === "BILLER_ID") {
      setValue(PROMPT_PAY_CUSTOM_QR_IMAGE, undefined);
    }
    showSaveButton();
  };

  const handleChangeImage = (imageUrls: ImageUrl[]) => {
    if (imageUrls.length) {
      const [{ publicUrl }] = imageUrls;

      setImage(publicUrl);
      setValue(PROMPT_PAY_CUSTOM_QR_IMAGE, publicUrl);
      showSaveButton();
    }
  };

  const handleRemoveImage = () => {
    setValue(PROMPT_PAY_CUSTOM_QR_IMAGE, undefined);
    showSaveButton();
  };

  return (
    <Grid container>
      <Grid item container xs={12} className="pt-2">
        <Grid item className="p-1">
          <Typography variant="body3" color="darkMed">
            {t("PromptPay Type")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Select
            variant="outlined"
            required
            fullWidth
            name={PROMPT_PAY_TYPE_KEY}
            validate={register}
            options={dropdownTypeOptions}
            defaultValue={
              promptpayType ? dropdownTypeOptions.filter((r) => r.value === promptpayType) : dropdownTypeOptions[0]
            }
            onChange={handleTypeChange}
          />
          {/* <TextField
            variant="outlined"
            placeholder={t("PromptPay name")}
            required
            fullWidth
            name={PROMPT_PAY_TYPE_KEY}
            validate={register}
            defaultValue={promptpayType}
            onChange={showSaveButton}
          /> */}
        </Grid>
        {errors[PROMPT_PAY_TYPE_KEY] && (
          <Grid item xs={12} className="p-1">
            <ErrorText>{t(get(errors[PROMPT_PAY_TYPE_KEY], "message", ""))}</ErrorText>
          </Grid>
        )}
      </Grid>
      <Grid item container xs={12} className="pt-2">
        <Grid item className="p-1">
          <Typography variant="body3" color="darkMed">
            {qrType === "BILLER_ID" ? t("Biller name") : t("PromptPay name")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            placeholder={qrType === "BILLER_ID" ? t("Biller name") : t("PromptPay name")}
            required
            fullWidth
            name={PROMPT_PAY_ACCOUNT_NAME_KEY}
            validate={register}
            defaultValue={accountName}
            onChange={showSaveButton}
          />
        </Grid>
        {errors[PROMPT_PAY_ACCOUNT_NAME_KEY] && (
          <Grid item xs={12} className="p-1">
            <ErrorText>{t(get(errors[PROMPT_PAY_ACCOUNT_NAME_KEY], "message", ""))}</ErrorText>
          </Grid>
        )}
      </Grid>
      <Grid item container xs={12} className="pt-2">
        <Grid item className="p-1">
          <Typography variant="body3" color="darkMed">
            {qrType === "BILLER_ID" ? t("Biller Id") : t("PromptPay number")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            type="tel"
            placeholder={t("PromptPay number")}
            required
            fullWidth
            name={PROMPT_PAY_ACCOUNT_NUMBER_KEY}
            validate={register}
            defaultValue={accountNumber}
            onChange={showSaveButton}
          />
        </Grid>
        {errors[PROMPT_PAY_ACCOUNT_NUMBER_KEY] && (
          <Grid item xs={12} className="p-1">
            <ErrorText>{t(get(errors[PROMPT_PAY_ACCOUNT_NUMBER_KEY], "message", ""))}</ErrorText>
          </Grid>
        )}
      </Grid>
      {qrType === "BILLER_ID" ? (
         <Grid item container xs={12} className="pt-2">
         <Grid item className="p-1">
           <Typography variant="body3" color="darkMed">
             สำหรับบิลเพย์เม้นต์
           </Typography>
         </Grid>
         <Grid item xs={12} className="p-1">
          <DetailContent className="mb-2">ระบบจะกำหนด Ref1 เป็นเลขที่ออเดอร์และกำหนดยอดชำระตามยอดที่ปรากฏในการสรุปยอดสั่งซื้อสุดท้าย</DetailContent>
         </Grid>
       </Grid>
      ) : (
        <Grid item container xs={12} className="pt-2">
          <Grid item className="p-1">
            <Typography variant="body3" color="darkMed">
              {t("PromptPay image")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ImageUpload
              onChange={handleChangeImage}
              onRemove={handleRemoveImage}
              image={image || ""}
              isConfirmRemove
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default PromptPayForm;
