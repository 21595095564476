import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { TRAIN_FAQ } from "graphql/ai-setting/mutation";
import { FAQ_TRAIN_JOB_OPERATION } from "graphql/ai-setting/query";

import { notifyError, notifySuccess } from "utils/notify";

const useTrainFaq = (projectId: string) => {
  const { t } = useTranslation();
  const [trainFaq] = useMutation(TRAIN_FAQ, {
    refetchQueries: [
      {
        query: FAQ_TRAIN_JOB_OPERATION,
        variables: {
          projectId,
        },
      },
    ],
    onError: () => {
      notifyError(t("AI training Fail. Please retry later"));
    },
    onCompleted: () => {
      notifySuccess(t("AI training is processing"));
    },
  });

  const handleClickTrain = () => {
    return trainFaq({
      variables: {
        projectId,
      },
    });
  };

  return handleClickTrain;
};

export default useTrainFaq;
