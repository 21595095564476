import React, { FC } from "react";

import Grid from "components/Grid";
import Typography from "components/Typography";
import ButtonBase from "components/ButtonBase";
import ChatBubble from "components/ChatBubble";

import { FlexAlign } from "./types";
import { ChatBubbleWrapper } from "./styled";

type MessageLayoutPropsType = {
  timeStamp?: string;
  from?: string;
  isCustomer?: boolean;
  bgColor?: string;
  displayChatBubble?: boolean;
  onClickDate?: () => void;
  isReply?: boolean;
};

const MessageLayout: FC<MessageLayoutPropsType> = ({
  children,
  timeStamp = "",
  from = "",
  isCustomer = false,
  bgColor = "",
  displayChatBubble = true,
  onClickDate = () => {},
  isReply = false,
  ...rest
}) => {
  const flexAlign = isCustomer ? FlexAlign.ALIGN_ITEM_START : FlexAlign.ALIGN_ITEM_END;
  return (
    <ChatBubbleWrapper {...rest} className={flexAlign}>
      {displayChatBubble ? (
        <ChatBubble isReply={isReply} isCustomer={isCustomer} bgColor={bgColor}>
          <Grid container justify="center">
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </ChatBubble>
      ) : (
        <Grid container justify="center" className="pb-1">
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      )}

      <div className={`mx-1 d-flex ${isCustomer ? "justify-content-start" : "justify-content-end"}`}>
        <ButtonBase onClick={onClickDate}>
          <Typography variant="body5">
            {timeStamp}
            {from}
          </Typography>
        </ButtonBase>
      </div>
    </ChatBubbleWrapper>
  );
};

export default MessageLayout;
