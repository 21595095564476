export const flashExpressDescription = {
  EN: null,
  TH: [
    { body: "พิมพ์ใบปะหน้า และแปะลงบนกล่องพัสดุ" },
    { body: "เข้ารับสินค้าพัสดุถึงหน้าบ้าน" },
    {
      body:
        "พื้นที่ กรุงเทพ ปริมณฑลและต่างจังหวัด ต้องสร้างรายการก่อนเวลา 12.00 น. จะเข้ารับในวันเดียวกัน แต่ไม่เกิน 18.00 น. สำหรับพื้นที่ห่างไกลจะต้องทำรายการล่วงหน้า 1 วันได้แก่ ภูเขา เกาะ และพื้นที่พิเศษ",
    },
    { body: "กรุงเทพ ปริมณฑล จัดส่งในวันถัดไป หรือ 1 - 2 วัน และต่างจังหวัด จัดส่งภายใน 1 - 3 วัน ไม่รวมวันเข้ารับ" },
    { body: "กรณีพัสดุมีการตีคืน ทางผู้ให้บริการจะคิดค่าบริการตีคืน 50% จากราคาค่าขนส่ง" },
    {
      body:
        "พัสดุหีบห่อไม่เหมาะสม หรือไม่เพียงพอ เช่น การแพ็คสินค้าแบบใส่ถุงพลาสติก ถุงกระดาษ ห่อด้วยกระดาษ กล่องประกอบการดัดแปลงกล่อง หรือ ซองใส่พัสดุ จะปฏิเสธการเคลมสินค้า",
    },
    {
      body:
        "ประกันค่าความเสียหายและการสูญหายที่เกิดจากขนส่ง สูงสุดไม่เกิน 2,000 บาท (สามารถซื้อประกันเพิ่มได้ *รายละเอียดประกันเช็ค API)",
    },
    {
      body: "ขนาดและน้ำหนักสินค้าที่สามารถเข้ารับได้",
      subBody: [
        "1. น้ำหนักไม่เกิน 50 กิโลกรัม",
        "2. สามารถเข้ารับสินค้าขนาดกล่อง ( กว้าง + ยาว + สูง ) ไม่เกิน 280 เซนติเมตร และ ด้านใดด้านหนึ่งต้อง ไม่เกิน 150 เซนติเมตร *เป็นไปตามมาตรฐานที่ขนส่งกำหนด",
      ],
    },

    {
      body:
        "หากขนาดพัสดุมีน้ำหนักเกินจากที่ระบุ จะมีการเรียกเก็บเงินค่าขนส่งเพิ่มเติมภายหลัง โดยคำนวณตามค่าน้ำหนักที่แท้จริงจากขนส่ง",
    },
    {
      body:
        "สินค้าที่ขนส่งไม่อาจยอมรับได้ ได้แก่ สัตว์ ทอง เงินสกุล อัญมณี อาวุธ วัตถุระเบิด กระสุน สื่อลามกอนาจาร ชิ้นส่วนมนุษย์ สิ่งของที่ผิดกฎหมาย เช่น ยาเสพติด หรือสินค้าจำพวกวัตถุอันตราย สิ่งของต้องห้าม เป็นต้น",
    },
    {
      body:
        "การส่งมอบพัสดุจะถููกส่งไปยังที่อยู่ปลายทางของผู้รับ แต่ไม่จำเป็นต้องส่งถึงตัวผู้รับที่ระบุชื่อไว้ ในกรณีที่ไม่สามารถจัดส่งได้จะเสนอทางเลือกให้ผู้รับ เช่น การส่งมอบในวันอื่น ไม่ต้องมีการลงนาม หรือให้ไปรับที่จุดบริการ",
    },
    {
      body:
        "กรณีพื้นที่ห่างไกล ภูเขา และเกาะ มีค่าบริการเพิ่ม 50 บาท ได้แก่ เชียงใหม่ ลำพูน ลำปาง น่าน พะเยา เชียงราย แม่ฮ่องสอน นครสวรรค์ ตาก เพชรบูรณ์ บึงกาฬ เลย ชลบุรี ตราด กาญจนบุรี กระบี่ พังงา สุราษฏร์ธานี นราธิวาส ปัตตานี และยะลา",
    },
  ],
};
