import * as React from "react";

const SvgIcAllorders = () => (
  <svg width="25px" height="30px" viewBox="0 0 20 20">
    <g fill="none" fillRule="nonzero">
      <path
        fill="#F5F5F5"
        d="M15.803 3.01H2.197C1.534 3.01 1 3.546 1 4.205v8.088c0 .662.537 1.197 1.197 1.197H15.8c.662 0 1.197-.538 1.197-1.197V4.206a1.192 1.192 0 0 0-1.194-1.197z"
      />
      <g fill="#FF4B55">
        <path d="M1 12.294c0 .662.537 1.197 1.197 1.197H15.8c.662 0 1.197-.538 1.197-1.197v-.457H1v.457zM15.803 3.01H2.197C1.534 3.01 1 3.546 1 4.205v.456h16v-.456c0-.662-.537-1.197-1.197-1.197z" />
      </g>
      <path fill="#41479B" d="M1 6.319h16v3.862H1z" />
    </g>
  </svg>
);

export default SvgIcAllorders;
