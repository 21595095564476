import * as React from "react";

const SvgIcBaht = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.727 2v4.123c1.48 0 2.701.223 3.664.668.634.284 1.148.813 1.542 1.586.372.729.558 1.487.558 2.275 0 1.735-.627 3.157-1.882 4.265-.466.416-1.05.82-1.75 1.214 1.539.409 2.742 1.218 3.61 2.428.525.744.787 1.714.787 2.91a5.936 5.936 0 01-.733 2.915 5.24 5.24 0 01-2.012 2.061c-.94.562-2.202.843-3.784.843v3.445h-1.805v-3.445H10V6.068h4.922V2h1.805zm0 15.498v7.657a5.37 5.37 0 001.706-.263 3.36 3.36 0 001.225-.755c.634-.663.951-1.48.951-2.45 0-.75-.19-1.443-.568-2.078-.358-.59-.923-1.082-1.696-1.476a7.393 7.393 0 00-1.618-.635zm-1.805-.098h-2.363v7.755h2.363V17.4zm0-9.177h-2.363v7.055h2.363V8.223zm1.805.022v6.89c.4-.05.896-.24 1.487-.568.474-.27.886-.773 1.236-1.51.335-.7.503-1.355.503-1.968 0-.81-.306-1.487-.919-2.034-.386-.423-1.155-.693-2.307-.81z"
    />
  </svg>
);

export default SvgIcBaht;
