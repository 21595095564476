import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import Divider from "components/Divider";
import Card from "components/Card";
import Typography from "components/Typography";

import { InvoiceType } from "types/Billing";

import { Device } from "types/Device";
import useDevice from "utils/hooks/useDevice";
import useGetInvoiceReceipt from "utils/hooks/useGetInvoiceReceipt";
import CircularProgress from "components/CircularProgress";
import Modal from "components/Modal/index";
import { GridWithPointerCursor, InvoiceTable } from "../styled";
import InvoiceItem from "./InvoiceItem";
import InvoiceHeader from "./InvoiceHeader";

type InvoiceHistoryPropsType = {
  projectId: string;
  invoices: InvoiceType[];
  isLoadMore: boolean;
  onFetchMore: () => void;
};

const InvoiceHistoryTable: FC<InvoiceHistoryPropsType> = (props) => {
  const { invoices, onFetchMore, isLoadMore, projectId } = props;
  const { t } = useTranslation();
  const device = useDevice();
  const [selectedInvoiceDetail, setSelectedInvoiceDetail] = useState<string>();
  const isMobile = device === Device.MOBILE;
  const { onClickInvoiceNumber, loading } = useGetInvoiceReceipt(projectId);

  return (
    <>
      {loading && (
        <Modal isOpen onClose={() => {}}>
          <CircularProgress className="m-4" />
        </Modal>
      )}
      <Card noShadow padding="0px">
        <Grid container className="px-4 mt-4">
          <Grid item xs={12} className="pb-2">
            <Typography variant="title3" color="dark">
              {t("billing.invoiceHistory.header.title")}
            </Typography>
          </Grid>
          {!isMobile && <InvoiceHeader />}
        </Grid>
        {!isMobile && <Divider thickness="0.5px" className="m-0" />}
        <InvoiceTable container isMobile={isMobile}>
          {invoices &&
            invoices.map((invoice: InvoiceType) => {
              return (
                <InvoiceItem
                  projectId={projectId}
                  key={invoice.id}
                  handleDownloadPDF={() => onClickInvoiceNumber(invoice.invoiceNumber)}
                  isMobile={isMobile}
                  selectedInvoiceDetail={selectedInvoiceDetail}
                  setSelectedInvoiceDetail={setSelectedInvoiceDetail}
                  {...invoice}
                />
              );
            })}
        </InvoiceTable>

        {isLoadMore && (
          <>
            <Divider thickness="0.5px" className="m-0" />
            <GridWithPointerCursor container item justify="center" className="px-4 py-2 my-1" onClick={onFetchMore}>
              <Typography variant="body3" color="darkGray">
                {t("billing.invoiceHistory.seeMore")}
              </Typography>
            </GridWithPointerCursor>
          </>
        )}
      </Card>
    </>
  );
};

export default InvoiceHistoryTable;
