/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";

import { FACEBOOK_REQUEST_URL } from "config";

type Permission = {
  permission: string;
  status: string;
};

// #region Facebook login status
const RE_AUTHORIZE_FACEBOOK_LOGIN = "reauthorize";
// #endregion

// #region Facebook permission validator
const GRANTED_PERMISSION = "granted";
// #endregion

const DEFAULT_FACEBOOK_PAGE_SCOPE =
  "pages_messaging,pages_manage_metadata,pages_manage_engagement,pages_read_engagement,pages_show_list,pages_manage_ads,ads_management";
const DEFAULT_INSTAGRAM_SCOPE = DEFAULT_FACEBOOK_PAGE_SCOPE.concat(
  ",instagram_basic,instagram_manage_messages,instagram_manage_insights,instagram_manage_comments",
);
const DEFAULT_SCOPES = DEFAULT_FACEBOOK_PAGE_SCOPE.split(",");

/**
 * Get Facebook page list that user has permissions
 * @param accessToken
 * @returns {Promise<{ data: ReadonlyArray<any> }>}
 */
export const getPageList = async (accessToken: string): Promise<{ data: ReadonlyArray<any> }> => {
  const { data } = await axios.get(`${FACEBOOK_REQUEST_URL}/me/accounts?access_token=${accessToken}`);

  return data;
};

/**
 * Get granted facebook permissions by user's access token
 * @param accessToken
 * @returns {Promise<ReadonlyArray<Permission>>}
 */
export const getGrantedFacebookPermissions = async (accessToken: string): Promise<ReadonlyArray<Permission>> => {
  const { data }: { data?: { data?: Permission[] } } = await axios.get(
    `${FACEBOOK_REQUEST_URL}/me/permissions?access_token=${accessToken}`,
  );

  const permissions = data?.data || [];

  return permissions.filter((permission: Permission) => permission.status === GRANTED_PERMISSION);
};

/**
 * Validate whether user has granted every required permissions for the system to use
 * @param grantedPermissions
 * @returns {boolean}
 */
export const isGrantedEveryRequiredPermissions = (
  grantedPermissions: ReadonlyArray<Permission>,
  requiredPermissions = DEFAULT_SCOPES,
): boolean => {
  return grantedPermissions.every((grantedPermission) => requiredPermissions.includes(grantedPermission.permission));
};

export const FacebookConnectLogin = (onGetAccessToken: (arg0: any) => void) => {
  // eslint-disable-next-line no-useless-catch
  try {
    // if we remove get login status from the code, it will throw error below.
    // message":"undefined passed as a property in argument #0 for 'where' operation. Call skipUndefined() method to ignore the undefined values.
    window.FB.getLoginStatus(
      async (_resp: { status: string; authResponse: { userID: string; accessToken: string } }) => {
        window.FB.login(
          (response: any) => {
            onGetAccessToken(response);
          },
          {
            auth_type: RE_AUTHORIZE_FACEBOOK_LOGIN,
            scope: DEFAULT_FACEBOOK_PAGE_SCOPE,
          },
        );
      },
    );
  } catch (error) {
    throw error;
  }
};

export const InstagramLogin = (onGetAccessToken: (arg0: any) => void) => {
  // eslint-disable-next-line no-useless-catch
  try {
    // if we remove get login status from the code, it will throw error below.
    // message":"undefined passed as a property in argument #0 for 'where' operation. Call skipUndefined() method to ignore the undefined values.
    window.FB.getLoginStatus(
      async (_resp: { status: string; authResponse: { userID: string; accessToken: string } }) => {
        window.FB.login(
          (response: any) => {
            onGetAccessToken(response);
          },
          {
            auth_type: RE_AUTHORIZE_FACEBOOK_LOGIN,
            scope: DEFAULT_INSTAGRAM_SCOPE,
          },
        );
      },
    );
  } catch (error) {
    throw error;
  }
};
