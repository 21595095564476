import React, { FC } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";

import Button from "components/Button";
import Grid from "components/Grid";
import Typography from "components/Typography";
import ErrorSnackbar from "components/Snackbar";

import { DATE_PICKER_ORDER_FORMAT } from "constants/DateTimeFormat";

import { AddonChangesSummary, AddonItemStatus } from "types/Addon";

import { BILLING } from "constants/Router";
import PaymentSummaryCard from "../PaymentSummaryCard";

type PaymentSummaryModalPropsType = {
  addonChangesSummary: AddonChangesSummary;
  onCancel: () => void;
  onConfirm: () => void;
  errorText: string;
  isLoadingBuyAddon: boolean;
};

const PaymentSummaryModal: FC<PaymentSummaryModalPropsType> = (props) => {
  const { t } = useTranslation();

  const { addonChangesSummary, onCancel, onConfirm, errorText, isLoadingBuyAddon } = props;

  if (!addonChangesSummary || isEmpty(addonChangesSummary)) {
    return null;
  }

  const addonChangesSummaryItems = addonChangesSummary.summaryItems.items || [];

  const thereIsSubtraction = addonChangesSummaryItems.some((item) => item.status === AddonItemStatus.SUBTRACTION);
  const nextBillingDate = moment(addonChangesSummary?.nextBillingDate).format(DATE_PICKER_ORDER_FORMAT);

  return (
    <Grid container className="p-3">
      <Grid item xs={12} className="pt-2 pb-3">
        <Typography variant="title3" color="dark" className="text-center">
          {t(thereIsSubtraction ? "planAddon.paymentSummary.title" : "planAddon.paymentSummary.AddOnPurchase")}
        </Typography>

        {addonChangesSummary.isDuringFreeTrialPeriod && (
          <Typography variant="body3" color="darkLight" className="mt-2 text-center">
            {t("planUpgrade.freeTrial.on14DayFreeTrial")}
          </Typography>
        )}
      </Grid>

      {errorText && (
        <Grid item xs={12} className="pb-3">
          <ErrorSnackbar
            description={t(errorText)}
            suffixLink={BILLING}
            suffix={t("BroadcastBarWarning.button.manageBilling")}
          />
        </Grid>
      )}

      <PaymentSummaryCard
        summaryType="summary"
        summaryData={addonChangesSummary.summaryItems}
        totalPrice={addonChangesSummary.chargedItems.totalPrice}
      />

      <Grid item container className="p-3" spacing={1}>
        <Grid item xs={12} className="pr-1">
          <Typography variant="body3" color="darkGray">
            {t("planAddon.paymentSummary.description")}
            {nextBillingDate}
          </Typography>
        </Grid>

        {thereIsSubtraction && (
          <Grid item xs={12}>
            <Typography variant="body3" color="darkGray">
              {t("planAddon.paymentSummary.removedAddOn.description")}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item container spacing={1} className="pt-2">
        <Grid item xs={6}>
          <Button color="ghost" fullWidth onClick={onCancel}>
            {t("Cancel")}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button fullWidth onClick={onConfirm} disabled={errorText || isLoadingBuyAddon}>
            {t("Submit")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentSummaryModal;
