import { useTranslation } from "react-i18next";
import { useMutation, ApolloError } from "@apollo/client";
import { toast } from "react-toastify";

import { CUSTOMER_NOTE } from "graphql/customer/query";
import { ADD_CUSTOMER_NOTE } from "graphql/customer/mutation";
import { CustomerQueryType, CustomerNoteVariablesType } from "types/Customer";

type UseAddCustomerNotePropsType = {
  variables: CustomerNoteVariablesType;
  onComplete: () => void;
};

const useAddCustomerNote = (props: UseAddCustomerNotePropsType) => {
  const { variables, onComplete } = props;
  const { t } = useTranslation();

  const [addNote] = useMutation(ADD_CUSTOMER_NOTE, {
    update(cache, { data: customerNoteData }) {
      const cachedCustomer = cache.readQuery<CustomerQueryType>({
        query: CUSTOMER_NOTE,
        variables,
      });
      if (cachedCustomer && customerNoteData) {
        const { addCustomerNote } = customerNoteData;
        const cacheCustomerNotes = cachedCustomer.customer.notes;
        const updatedCacheCustomerNotesResults = [...cacheCustomerNotes.results, addCustomerNote];

        cache.writeQuery({
          query: CUSTOMER_NOTE,
          variables,
          data: {
            customer: {
              ...cachedCustomer.customer,
              notes: {
                ...cacheCustomerNotes,
                results: updatedCacheCustomerNotesResults,
                total: cacheCustomerNotes.total + 1,
              },
            },
          },
        });
      }
    },
    onError: (error: ApolloError) => {
      toast.error(`${t("Update failed!")}, ${error}`, {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 3000,
      });
    },
    onCompleted: () => {
      toast.success(t("Update successfully"), {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 1000,
        onClose: () => {},
      });

      onComplete();
    },
  });

  return {
    addNote,
  };
};

export default useAddCustomerNote;
