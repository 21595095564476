import React, { FC, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Card from "components/Card";
import Grid from "components/Grid";
import Switch from "components/Switch";
import Typography from "components/Typography";
import { SvgIcon } from "components/Icon";
import { IcDelete, IcEdit } from "components/SvgIcons";
import { ButtonWithIcon } from "components/Button";

import COLORS from "constants/Colors";

type PaymentCardType = {
  isShowEditIcon?: boolean;
  isShowDeleteIcon?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
  isActive: boolean;
  onChangeIsActive: (isActive: boolean) => void;
};

const IconWrapper = styled(ButtonWithIcon)`
  position: absolute;
  margin: 16px;
  top: 0;
  right: 0;
`;

const PaymentCard: FC<PaymentCardType> = (props) => {
  const { t } = useTranslation();

  const {
    children,
    isShowEditIcon,
    onEdit,
    isShowDeleteIcon,
    onDelete,
    isActive: defaultIsActive,
    onChangeIsActive,
  } = props;
  const [isActive, setIsActive] = useState(defaultIsActive);

  const handleToggleSwitch = () => {
    setIsActive(!isActive);
    onChangeIsActive(!isActive);
  };

  return (
    <Card noShadow className="mt-3 p-4">
      {isShowDeleteIcon && (
        <IconWrapper onClick={onDelete}>
          <SvgIcon className="ml-1 mt-1" component={IcDelete} fontSize="small" htmlColor={COLORS.DarkMed} />
        </IconWrapper>
      )}
      {isShowEditIcon && (
        <IconWrapper onClick={onEdit}>
          <SvgIcon className="ml-1 mt-1" component={IcEdit} fontSize="small" htmlColor={COLORS.DarkMed} />
        </IconWrapper>
      )}
      <Grid container>
        <Grid item container xs={12}>
          <Grid item container xs={12} alignItems="center">
            <Grid item>
              <Switch checked={isActive} onChange={handleToggleSwitch} />
            </Grid>
            <Grid item>
              <Typography variant="body3" color="darkGray" className="pl-3">
                {t(isActive ? "Active" : "Disabled")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className="py-2">
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PaymentCard;
