import * as yup from "yup";
import i18n from "utils/i18n";
import { validateThPostCode } from "utils/address";
import { PHONE_REG_EXP_REQUIRED } from "constants/RegExp";

export const shippopEmailRequiredSchema = yup.object().shape({
  email: yup
    .string()
    .required(i18n.t("form.error.isRequired", { name: i18n.t("Email") }))
    .email("Invalid email address"),
});

export const shippopEmailSchema = yup.object().shape({
  email: yup.string().email("Invalid email address").notRequired(),
});

export const updateDeliveryAddressSchema = yup.object().shape({
  name: yup.string().required("Please fill name").nullable(),
  address: yup.string().required("Please fill streetAddress").nullable(),
  subDistrict: yup.string().required("Please fill subDistrict").nullable(),
  district: yup.string().required("Please fill district").nullable(),
  province: yup.string().required("Please fill province").nullable(),
  postalCode: yup
    .string()
    .test("len", "Postal Code Must be exactly 5 characters", (val) => val && val.toString().length === 5)
    .required("Please fill postalCode")
    .test("isThPostcode", "form.error.postcode", validateThPostCode)
    .nullable(),
  phoneNumber: yup.string().matches(PHONE_REG_EXP_REQUIRED, "Please fill phoneNumber").nullable(),
});
