import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";
import { ButtonWithIcon } from "components/Button";
import { SvgIcon } from "components/Icon";
import { IcArrowDown } from "components/SvgIcons";

type CollapseButtonPropsType = {
  degree: number;
  isShow: boolean;
  onClick?: () => void;
};

export const StyledCollapseButton = styled(ButtonWithIcon)<CollapseButtonPropsType>`
  transition: transform 0.3s;
  transform-origin: center;
  transform: rotate(${({ isShow, degree }) => (isShow ? degree || 0 : 90)}deg);
  svg {
    margin-right: 0;
    padding-right: 4px;
    padding-bottom: 3px;
  }
  outline: none;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.08);
`;

const CollapseButton: FC<CollapseButtonPropsType & HTMLAttributes<HTMLButtonElement>> = ({
  className = "",
  isShow,
  degree,
  onClick = () => {},
}) => {
  return (
    <StyledCollapseButton
      type="button"
      degree={degree}
      className={`rounded-circle ${className}`}
      isShow={isShow}
      onClick={onClick}
    >
      <SvgIcon viewBox="-4 0 24 24" component={IcArrowDown} fontSize="small" />
    </StyledCollapseButton>
  );
};

export default CollapseButton;
