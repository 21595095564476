import styled from "styled-components";
import ButtonBase from "components/ButtonBase";

export const Wrapper = styled.div`
  padding: 0 24px 12px 24px;
  border-bottom: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
`;

export const TagsWrapper = styled.div`
  border: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px 60px 12px 12px;
  display: flex;
  flex-wrap: wrap;
  max-height: 120px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    display: initial;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(83, 83, 83, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const OptionsWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 16px;
  flex: 1;

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    display: initial;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(83, 83, 83, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const ClearAllButton = styled(ButtonBase)`
  color: ${({ theme }) => theme.COLORS.Accent};
  position: absolute;
  right: 15px;
  top: 16px;

  &[disabled] {
    color: ${({ theme }) => theme.COLORS.LightBlue};
  }
`;
