export const scgYamatoExpressFrozenDescription = {
  EN: null,
  TH: [
    {
      body: "บริการรับ-ส่งสินค้าถึงบ้านหน้า (Door to Door) เป็นแบบ Next Day Delivery ราคาเริ่มต้นเพียง 140 บาทเท่านั้น",
    },
    { body: "ใช้ง่ายเพียง “ปริ้น แปะ ส่ง” โดยพิมพ์ใบปะหน้า และแปะลงบนกล่องพัสดุ" },
    { body: "“Deliver Your Happiness” ส่งพัสดุตรงตามเวลา ขนส่งรวดเร็ว และแม่นยำในการบริการ" },
    { body: "ครบครันด้วยเทคโนโลยี Cool TA-Q-BIN (Cool Parcel Delivery) Frozen อุณหภูมิประมาณ 0 °c ถึง -15 °c" },
    { body: "พัสดุไม่เสียหายระหว่างจัดส่ง" },
    { body: "เข้ารับสินค้าตั้งแต่ 1 ชิ้นขึ้นไป ในพื้นที่ กทม.และปริมณฑล พื้นที่เข้ารับและจัดส่ง" },
    { body: "สามารถเข้ารับสินค้าขนาดกล่อง ( กว้าง + ยาว + สูง ) ไม่เกิน 120 เซนติเมตร" },
    {
      body:
        "น้ำหนักเกิน 20 กิโลกรัม หากขนาดพัสดุมีขนาดเกินกำหนด หรือระบุมาไม่ถูกต้อง จะมีการเรียกเก็บเงินค่าขนส่งเพิ่มเติม",
    },
    {
      body:
        "สินค้าที่อยู่ภายใน ต้องถูกแช่อยู่ในความเย็นที่ต่ำกว่า -15 องศาเซลเซียส อย่างน้อยไม่ต่ำกว่า 12 ชั่วโมง ก่อนการจัดส่ง",
    },
    { body: "ชำระค่าบริการก่อน 15.00 น. รับสินค้าวันถัดไป จองล่วงหน้า 1 วัน (วันจันทร์-วันศุกร์)" },
    { body: "ทางขนส่ง จะดำเนินการจัดเก็บพัสดุของเย็น ไม่เกิน 3 วันทำการนับแต่วันที่เข้ารับพัสดุ" },
    { body: "หากสินค้ามีการตีคืนจะมีการเรียกเก็บเงินภายหลัง" },
  ],
};
