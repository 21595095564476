import React, { FC } from "react";

import { PROJECT, MEMBERS, STORE_SETTINGS } from "constants/Router";
import { InfoItemType } from "types/PlanUsage";

import InfoItemList from "../InfoItemList";

type TeamMemberPropsType = {
  data: InfoItemType[];
  limit: number;
  projectId: string;
};

const TeamMember: FC<TeamMemberPropsType> = (props) => {
  const { data = [], limit, projectId } = props;

  return (
    <InfoItemList
      data={data}
      limit={limit}
      title="planUsage.title.teamMember"
      addNewText="planUsage.addNew.teamMember"
      isBuyMoreEnabled={false}
      buyMoreDescription="planUsage.description.moreMember"
      buyMoreLabel="planUsage.label.buyMoreSeat"
      manageRoute={`/${PROJECT}/${projectId}/${STORE_SETTINGS}/${MEMBERS}`}
    />
  );
};

export default TeamMember;
