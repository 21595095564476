import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import isEqual from "lodash/isEqual";

import Button from "components/Button";
import Grid from "components/Grid";
import { SvgIcon } from "components/Icon";
import { IcOptions, IcEdit, IcDelete } from "components/SvgIcons";
import { RadioBox } from "components/Radio";
import MenuList from "components/Menu/MenuList";
import Typography from "components/Typography";

import COLORS from "constants/Colors";
import { ShippopSenderInformation } from "types/Shipping";
import { getAddress } from "utils/address";

import AddressForm from "./AddressForm";

type SenderInformationPropsType = {
  projectId: string;
  addressList: ShippopSenderInformation[];
  onChangeAddressList: (newAddressList: ShippopSenderInformation[]) => void;
};

const AddressListItem: React.FC<SenderInformationPropsType> = (props) => {
  const { t } = useTranslation();

  const { projectId, addressList, onChangeAddressList } = props;

  const [focusedAddress, setFocusedAddress] = useState<ShippopSenderInformation | undefined>(undefined);
  const [isOpenAddressModal, setIsOpenAddressModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | SVGSVGElement>(null);

  const handleClickMenuButton = (event: React.MouseEvent<SVGSVGElement>, address: ShippopSenderInformation) => {
    setFocusedAddress(address);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenuList = () => {
    setAnchorEl(null);
  };

  const handleAddNewAddress = () => {
    setFocusedAddress(undefined);
    setIsOpenAddressModal(true);
  };

  const handleClickDeleteAddress = () => {
    const newSenderAddressList = addressList.filter((editedAddress) => !isEqual(editedAddress, focusedAddress));

    onChangeAddressList(newSenderAddressList);
  };

  const popOverMenuList = () => {
    const selectedAddress = addressList.find(({ isActive }) => isActive);
    const isShowDeleteMenuItem = !isEqual(selectedAddress, focusedAddress);

    const defaultMenuList = [
      {
        icon: <SvgIcon className="ml-1" component={IcEdit} fontSize="small" />,
        label: "Edit",
        onClick: () => {
          handleCloseMenuList();

          if (focusedAddress) {
            setIsOpenAddressModal(true);
          }
        },
      },
    ];

    return isShowDeleteMenuItem
      ? [
          ...defaultMenuList,
          {
            icon: <SvgIcon className="ml-1" component={IcDelete} fontSize="small" />,
            label: "Delete",
            onClick: () => {
              handleCloseMenuList();
              handleClickDeleteAddress();
              setFocusedAddress(undefined);
            },
          },
        ]
      : defaultMenuList;
  };

  const handleChangeSenderAddressList = (senderAddress: ShippopSenderInformation) => {
    let newSenderAddressList;
    if (focusedAddress) {
      // Edit address in list
      newSenderAddressList = addressList.map((address: ShippopSenderInformation) => {
        if (isEqual(address, focusedAddress)) {
          // Update address
          return { ...senderAddress, isActive: address.isActive };
        }
        return address;
      });
    } else {
      // Add new address
      const isFirstAdded = addressList.length === 0;

      newSenderAddressList = [...addressList, { ...senderAddress, isActive: isFirstAdded }];
    }

    onChangeAddressList(newSenderAddressList);
  };

  const handleSelectAddress = (selectedIndex: number) => {
    const newSenderAddressList = addressList.map((senderAddress: ShippopSenderInformation, index: number) => ({
      ...senderAddress,
      isActive: index === selectedIndex,
    }));

    onChangeAddressList(newSenderAddressList);
  };

  return (
    <>
      <Grid container className="pt-4">
        <Grid item xs={12}>
          <Typography variant="body3" color="darkMed" className="pb-2">
            {t("shipping.shippop.address.title")}
          </Typography>
        </Grid>
        {addressList.map((addr: ShippopSenderInformation, index: number) => {
          const { name, address, phoneNumber, isActive } = addr;
          const addressText = getAddress(addr);

          const Subtitle = (
            <Grid container className="pb-2">
              <Grid item xs={12}>
                <Typography variant="body3">
                  {t("shipping.shippop.address.label.name")}
                  {name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body3">
                  {t("shipping.shippop.address.label.phone")}
                  {phoneNumber}
                </Typography>
              </Grid>
            </Grid>
          );

          return (
            <Grid
              item
              container
              alignItems="center"
              xs={12}
              className={`flex-nowrap ${index ? "pt-3" : ""}`}
              key={`${name}-${address}`}
            >
              <Grid item className="d-flex flex-grow-1">
                <RadioBox
                  title=""
                  SubTitle={Subtitle}
                  description={addressText}
                  isActive={isActive}
                  onClick={() => {
                    handleSelectAddress(index);
                  }}
                />
              </Grid>
              <Grid item className="d-flex flex-grow-0 pl-4">
                <SvgIcon
                  component={IcOptions}
                  size="small"
                  htmlColor={COLORS.DarkMed}
                  viewBox="0 0 32 32"
                  className="m-0 p-0"
                  onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => handleClickMenuButton(event, addr)}
                />
              </Grid>
            </Grid>
          );
        })}

        <Grid item xs={12} className="pt-3 mt-1">
          <Button fullWidth color="secondary" onClick={handleAddNewAddress}>
            {t("shipping.shippop.button.addSenderAddress")}
          </Button>
        </Grid>
      </Grid>
      <MenuList
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenuList}
        menuList={popOverMenuList()}
      />
      <AddressForm
        projectId={projectId}
        data={focusedAddress}
        isOpen={isOpenAddressModal}
        onClose={() => setIsOpenAddressModal(false)}
        onSaveAddress={handleChangeSenderAddressList}
      />
    </>
  );
};

export default AddressListItem;
