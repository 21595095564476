import React from "react";
import { CellProps } from "react-table";
import styled from "styled-components";

const Tag = styled.div`
  color: ${({ theme }) => theme.COLORS.Primary};
  border: 2px solid ${({ theme }) => theme.COLORS.Primary};
  font-family: "Graphik Medium";
  font-size: 14px;
  border-radius: 10px;
  padding: 6px;
`;

const Tags = <T extends object>(t: Function) => {
  const renderCell = (cell: CellProps<T>) => {
    return cell.value?.map((cellValue: { id: string; tag: string }) => (
      <Tag className="mr-2" key={cellValue.id}>
        {cellValue.tag}
      </Tag>
    ));
  };

  return {
    Header: t("tags"),
    accessor: "tags",
    disableSortBy: true,
    Cell: renderCell,
  };
};

export default Tags;
