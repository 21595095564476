import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link, useHistory } from "react-router-dom";

import Card from "components/Card";
import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import { SvgIcon } from "components/Icon";
import Typography from "components/Typography";
import { IcAdd } from "components/SvgIcons";

import COLORS from "constants/Colors";
import { PROJECT, STORE_SETTINGS, THEME_CONFIG } from "constants/Router";
import { PLATFORM, ChatMenu, MenuAction } from "types/RichMenu";
import { ProjectIdType } from "types/Project";
import { notifyError } from "utils/notify";
import { RichMenuTypography } from "pages/FacebookPersistentMenu/components/RichMenuDetail/RichMenuTypography";
import renderReview from "./renderReview";
import RichMenuPreviewCard from "./RichMenuPreviewCard";
import useGetMenuList from "./hooks/useGetMenuList";
import useActivateChatMenu from "./hooks/useActivateChatMenu";
import useRemoveChatMenu from "./hooks/useRemoveChatMenu";
import { PreviewImageContainer, AddNewRichMenuCard, RichMenuCardContainer } from "./style";
import InstagramPreviewCard from "./InstagramPreviewCard";
import { mapErrorMessage } from "./mapErrorMessage";

type RichMenuContainerPropsType = {
  title: string;
  limit: number;
  type: PLATFORM;
};

const platformPathMap = {
  [PLATFORM.LINE]: "line-rich-menu",
  [PLATFORM.FACEBOOK]: "facebook-persistent-menu",
  [PLATFORM.INSTAGRAM]: "instagram-persistent-menu",
};

const RichMenuContainer: FC<RichMenuContainerPropsType> = (props) => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const history = useHistory();

  const { title, limit, type } = props;

  const { menuList } = useGetMenuList(projectId, type);
  const {
    handleActivateChatMenu,
    handleDeactivateChatMenu,
    activatingChatMenu,
    activatingChatMenuError,
    deactivatingChatMenu,
    deactivatingChatMenuError,
  } = useActivateChatMenu(projectId, type);
  const { handleRemoveChatMenu, error: removeChatMenuError } = useRemoveChatMenu(projectId, type);

  const errors = [activatingChatMenuError, deactivatingChatMenuError, removeChatMenuError];
  errors.forEach((error) => {
    if (!error) {
      return;
    }
    const message = t(mapErrorMessage(error));
    notifyError(message);
  });

  const handleToggleActiveMenu = (id: string, isActive: boolean) => {
    if (isActive) {
      handleDeactivateChatMenu(id);
    } else {
      handleActivateChatMenu(id);
    }
  };

  const platformPath = platformPathMap[type];

  const handleClickEditRichMenu = (id: string) => {
    history.push(`/${PROJECT}/${projectId}/${STORE_SETTINGS}/${THEME_CONFIG}/${platformPath}/${id}`);
  };

  return (
    <Grid container>
      <Grid item container justify="space-between" className="pb-3">
        <Grid item>
          <Typography variant="body3" color="dark">
            {t(title)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body3">
            {menuList.length}/{limit}
          </Typography>
        </Grid>
      </Grid>
      {activatingChatMenu || deactivatingChatMenu ? (
        <CircularProgress className="m-4" />
      ) : (
        <>
          <RichMenuCardContainer item xs={12}>
            {menuList.map((menu: ChatMenu) => {
              const { id, name, menuActions, menuUrl, isActive, isDefault } = menu;

              return (
                <div key={id} className="pr-3">
                  <RichMenuPreviewCard
                    onToggleActiveMenu={() => handleToggleActiveMenu(id, isActive)}
                    onRemoveActiveMenu={() => handleRemoveChatMenu(id)}
                    onClickEditRichMenu={() => handleClickEditRichMenu(id)}
                    title={t(name)}
                    isSelected={isActive}
                    isDefault={isDefault}
                  >
                    {type === PLATFORM.LINE && <PreviewImageContainer alt="" src={menuUrl} className="w-100 h-100" />}
                    {type === PLATFORM.FACEBOOK && (
                      <Card width={225} height={150} padding="0px">
                        {menuActions &&
                          menuActions.map((menuAction: MenuAction, index) => {
                            const { action } = menuAction;

                            return (
                              // eslint-disable-next-line react/no-array-index-key
                              <RichMenuTypography key={index} variant="body3" className="p-3">
                                {t(renderReview(action))}
                              </RichMenuTypography>
                            );
                          })}
                      </Card>
                    )}
                    {type === PLATFORM.INSTAGRAM && <InstagramPreviewCard menuActions={menuActions} />}
                  </RichMenuPreviewCard>
                </div>
              );
            })}
            {menuList.length < 4 && (
              <Link to={`/${PROJECT}/${projectId}/${STORE_SETTINGS}/${THEME_CONFIG}/${platformPath}`}>
                <AddNewRichMenuCard item container justify="center" alignItems="center" direction="column">
                  <SvgIcon component={IcAdd} fontSize="default" htmlColor={COLORS.Primary} />
                  <Typography variant="title3" color="primary" className="p-1">
                    {t("ADD_NEW")}
                  </Typography>
                </AddNewRichMenuCard>
              </Link>
            )}
          </RichMenuCardContainer>
        </>
      )}
    </Grid>
  );
};

export default RichMenuContainer;
