import * as React from "react";

const SvgIcCustomerMessage = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.72,8.89H10.28a.5.5,0,0,0-.5.5v.42a.5.5,0,0,0,.5.5H21.72a.5.5,0,0,0,.5-.5V9.39A.5.5,0,0,0,21.72,8.89Zm0,4.27H10.28a.5.5,0,0,0-.5.5v.42a.5.5,0,0,0,.5.5H21.72a.5.5,0,0,0,.5-.5v-.42A.5.5,0,0,0,21.72,13.16Zm-5.33,4.62H10.28a.5.5,0,0,0-.5.5v.42a.5.5,0,0,0,.5.5h6.11a.5.5,0,0,0,.5-.5v-.42A.5.5,0,0,0,16.39,17.78ZM16,1.78A12.44,12.44,0,0,1,21,25.64c-1.51.66-5.52,2.67-11,5.2-.14-.29,1.71-5,1.24-5.2A11.89,11.89,0,0,1,3.56,14.22,12.44,12.44,0,0,1,16,1.78Z"
    />
  </svg>
);

export default SvgIcCustomerMessage;
