import styled from "styled-components";
import { COLORS } from "constants/Colors";
import Grid from "components/Grid";

interface CardImageRadioPropsType {
  isActive: boolean;
}

export const CardImageRadio = styled(Grid)<CardImageRadioPropsType>`
  background-color: ${(props) => (props.isActive ? COLORS.PrimaryNude : COLORS.White)};
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => (props.isActive ? COLORS.Primary : COLORS.LightBlue)};
  cursor: pointer;
  div {
    max-width: 314px;
    margin: auto;
  }
`;

export const CardTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
