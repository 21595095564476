const login = {
  en: {
    "Welcome back!": "Welcome back!",
    "Log in to 1Convo": "Log in to 1Convo",
    "Forgot your password?": "Forgot your password?",
    "Don’t have an account?": "Don’t have an account?",
    "Log in success!": "Log in success!",
    "Log in fail!": "Log in fail!",
    EMAIL_NOT_REGISTERED: "This email has not been registered yet.",
    ADVANCED_AI_CHATBOT: "Advanced AI Chatbot",
    AND_STORE_MANAGEMENT: "and Store Management",
  },
  th: {
    "Welcome back!": "ยินดีต้อนรับ",
    "Log in to 1Convo": "เข้าสู่ระบบเพื่อเข้าใช้ 1Convo",
    "Forgot your password?": "ลืมรหัสผ่าน",
    "Don’t have an account?": "คุณยังไม่มีบัญชีผู้ใช้?",
    "Log in success!": "เข้าสู่ระบบสำเร็จ",
    "Log in fail!": "เข้าสู่ระบบล้มเหลว",
    EMAIL_NOT_REGISTERED: "อีเมลยังไม่เคยสมัครสมาชิก",
    ADVANCED_AI_CHATBOT: "แชทบอท AI",
    AND_STORE_MANAGEMENT: "และเครื่องมือการจัดการร้านค้า",
  },
};

export default login;
