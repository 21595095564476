import React, { FC } from "react";
import { Link } from "react-router-dom";
import { SvgIcon } from "components/Icon";
import { IcWarning } from "components/SvgIcons";
import { COLORS } from "constants/Colors";
import Typography from "components/Typography";
import { Snackbar, IconContainer, DescriptionContainer, TextContainer, SuffixContainer } from "./styled";

interface ErrorSnackbarPropsType {
  description: React.ReactNode;
  suffix?: React.ReactNode;
  suffixLink?: string;
}

const ErrorSnackbar: FC<ErrorSnackbarPropsType> = ({ description, suffix, suffixLink }) => {
  return (
    <Snackbar>
      <DescriptionContainer>
        <IconContainer>
          <SvgIcon viewBox="0 0 24 24" component={IcWarning} fontSize="large" className="p-0" />
        </IconContainer>
        <TextContainer>
          <Typography variant="body4" color={COLORS.Error}>
            {description}
          </Typography>
        </TextContainer>
      </DescriptionContainer>
      {suffix && (
        <SuffixContainer>
          {suffixLink ? (
            <Link to={suffixLink}>
              <Typography variant="title10" color={COLORS.Error} style={{ textDecoration: "underline" }}>
                {suffix}
              </Typography>
            </Link>
          ) : (
            <Typography variant="title10" color={COLORS.Error}>
              {suffix}
            </Typography>
          )}
        </SuffixContainer>
      )}
    </Snackbar>
  );
};

export default ErrorSnackbar;
