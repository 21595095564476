import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { MARK_REDEEMED_BY_ID } from "graphql/digitalContent/mutation";
import { DIGITAL_PRODUCTS, DIGITAL_PRODUCT_CODES } from "graphql/digitalContent/query";
import { NOTIFICATION_UPDATE } from "constants/Notification";
import {
  DigitalProductConnectionQueryType,
  DigitalContentStatus,
  DigitalProductsQueryVariablesType,
  DigitalProductCodesQueryVariablesType,
} from "types/DigitalContent";
import { notifyError, notifySuccess } from "utils/notify";

type HookMarkCodeAsUsedPropsType = {
  projectId: string;
  productId: string;
  productSKUId: string;
  digitalProductsQueryVariables: DigitalProductsQueryVariablesType;
  digitalProductCodesQueryVariables: DigitalProductCodesQueryVariablesType;
  onComplete: () => void;
};

const useMarkCodeAsUsed = ({
  projectId,
  productId,
  productSKUId,
  digitalProductsQueryVariables,
  digitalProductCodesQueryVariables,
  onComplete,
}: HookMarkCodeAsUsedPropsType) => {
  const { t } = useTranslation();

  const [markAsUsed] = useMutation(MARK_REDEEMED_BY_ID, {
    update(cache) {
      const cacheDigitalProducts = cache.readQuery<DigitalProductConnectionQueryType>({
        query: DIGITAL_PRODUCTS,
        variables: digitalProductsQueryVariables,
      });

      if (cacheDigitalProducts) {
        const updatedResults = cacheDigitalProducts.digitalProducts.results.map((product) => {
          if (product.id === productId) {
            product.productSKUs.map((productSKU) => {
              if (productSKU.id === productSKUId) {
                const updatedDigitalProductInventories = productSKU.digitalProductInventories.map(
                  ({ status, inventory }) => {
                    let newInventory = inventory;
                    if (status === DigitalContentStatus.PURCHASED) {
                      newInventory = inventory - 1;
                    } else if (status === DigitalContentStatus.REDEEMED) {
                      newInventory = inventory + 1;
                    }

                    return {
                      status,
                      inventory: newInventory,
                    };
                  },
                );

                return updatedDigitalProductInventories;
              }

              return productSKU;
            });
          }

          return product;
        });

        cache.writeQuery({
          query: DIGITAL_PRODUCTS,
          variables: digitalProductsQueryVariables,
          data: {
            digitalProducts: {
              ...cacheDigitalProducts.digitalProducts,
              results: updatedResults,
            },
          },
        });
      }
    },
    refetchQueries: [{ query: DIGITAL_PRODUCT_CODES, variables: digitalProductCodesQueryVariables }],
    onError: () => {
      notifyError(t(NOTIFICATION_UPDATE.FAIL));
    },
    onCompleted: () => {
      notifySuccess(t(NOTIFICATION_UPDATE.SUCCESS));
      onComplete();
    },
  });

  const handleMarkCodeAsUsedById = (digitalContentId: string, note: string) => {
    markAsUsed({
      variables: {
        projectId,
        digitalContentId,
        note,
      },
    });
  };

  return {
    handleMarkCodeAsUsedById,
  };
};

export default useMarkCodeAsUsed;
