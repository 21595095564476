import React, { FC, memo } from "react";

import Card from "components/Card";
import Grid from "components/Grid";
import Typography from "components/Typography";
import { SvgIcon } from "components/Icon";
import { IcCircleRemove } from "components/SvgIcons";

import COLORS from "constants/Colors";
import { NO_IMG_AVAILABLE } from "constants/Image";
import { ProductTypeType, NewProductSKU, ProductImageType } from "types/Product";

import { GridProductImage, GridProductName, ProductImage, CircleRemoveIconContainer } from "./styled";

type ProductSKUListPropsType = {
  productName: string;
  productSKUs: NewProductSKU[];
  productImage: string;
  isDisabled?: boolean;
  onDelete: (categoryId?: string, productId?: string, productSKUId?: string) => void;
};

const ProductSKUList: FC<ProductSKUListPropsType> = (props) => {
  const { productSKUs, productImage, productName, onDelete, isDisabled = false } = props;

  return (
    <>
      {productSKUs.map((productSKU) => {
        const { id, image, productType, cfCode } = productSKU;
        const productSKUImage = (image as ProductImageType)?.src || productImage || NO_IMG_AVAILABLE;
        const productTypeText =
          productType &&
          productType
            .map((type: ProductTypeType) => {
              return type.value;
            })
            .join(", ");

        return (
          <Grid item xs={12} key={id} className="position-relative pt-1">
            {!isDisabled && (
              <CircleRemoveIconContainer
                onClick={() => {
                  onDelete(undefined, productSKU.productId, productSKU.id);
                }}
              >
                <SvgIcon component={IcCircleRemove} fontSize="inherit" htmlColor={COLORS.DarkMed} />
              </CircleRemoveIconContainer>
            )}

            <Card fullWidth className="mt-2" padding="12px" noBorder>
              <Grid container className="flex-nowrap" alignItems="center">
                <GridProductImage item>
                  <ProductImage alt={productSKUImage ? productTypeText : "no img"} src={productSKUImage} />
                </GridProductImage>
                <GridProductName item>
                  <Typography variant="body3" color="darkGray" className="ellipsis">
                    {productTypeText || productName}
                  </Typography>
                  {Boolean(cfCode) && (
                    <Typography variant="body3" color="darkLight" className="ellipsis">
                      {cfCode}
                    </Typography>
                  )}
                </GridProductName>
              </Grid>
            </Card>
          </Grid>
        );
      })}
    </>
  );
};

export default memo(ProductSKUList);
