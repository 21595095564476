const contentManagement = {
  en: {
    ContentManagement: "Content management",
    "contentManagement.modal.redeemCode.title": "Redeem code",
    "contentManagement.modal.redeemCode.label.code": "Code",
    "contentManagement.modal.redeemCode.placeholder.code": "Enter a code to redeem here",
    "contentManagement.modal.redeemCode.label.refId": "Ref ID: {{refId}}",
    "contentManagement.modal.redeemCode.label.productAndCustomer": "Product and customer details",
    "contentManagement.modal.redeemCode.label.note": "Note",
    "contentManagement.modal.redeemCode.button.continue": "Continue",
    "contentManagement.modal.redeemCode.button.redeemNow": "Redeem now",
    "contentManagement.codeDetail.label.refId": "Ref ID: {{refId}}",
    "contentManagement.codeDetail.label.importedTime": "Imported time",
    "contentManagement.codeDetail.label.purchasedTime": "Purchased time",
    "contentManagement.codeDetail.label.redeemedTime": "Redeemed time",
    "contentManagement.codeDetail.label.expiredTime": "Expired time",
    "contentManagement.button.markAsUsed": "Mark as used",
    "contentManagement.codeDetail.label.note": "Note",
    "contentManagement.codeDetail.placeholder.addNote": "Add your note",
    "contentManagement.codeDetail.button.done": "Done",
    "contentManagement.modal.title.deleteNote": "Are you confirm to remove note ?",
    "contentManagement.label.noDigitalProduct": "No product",
    "contentManagement.modal.title.editProductCode": "Product code and detail",
    "contentManagement.tab.available": "Available",
    "contentManagement.tab.purchased": "Purchased",
    "contentManagement.tab.redeemed": "Redeemed",
    "contentManagement.tab.expired": "Expired",
    "contentManagement.error.notFoundDigitalContent": "The code does not exist",
    "contentManagement.codeDetail.label.noExpiredTime": "No expired",
    "contentManagement.default.error.alreadyRedeemed": "The code is already been used",
    "contentManagement.default.error.notFound": "The code does not exist",
    "contentManagement.default.error.codeNotSold": "The code is not purchased",
    "contentManagement.default.error.codeExpired": "The code is expire",
    "contentManagement.default.error.codeNotAvailable": "The code is not available",
    "contentManagement.codeDetail.label.expireAfterPurchased": "{{value}} {{unit}} after purchased",
  },
  th: {
    ContentManagement: "การจัดการสินค้าดิจิทัล",
    "contentManagement.modal.redeemCode.title": "ใช้งานโค้ด",
    "contentManagement.modal.redeemCode.label.code": "โค้ด",
    "contentManagement.modal.redeemCode.placeholder.code": "ใส่โค้ดที่ต้องการใช้งานที่นี่",
    "contentManagement.modal.redeemCode.label.refId": "รหัสอ้างอิง: {{refId}}",
    "contentManagement.modal.redeemCode.label.productAndCustomer": "ข้อมูลของสินค้า และลูกค้า",
    "contentManagement.modal.redeemCode.label.note": "หมายเหตุ",
    "contentManagement.modal.redeemCode.button.continue": "ถัดไป",
    "contentManagement.modal.redeemCode.button.redeemNow": "ใช้งานโค้ด",
    "contentManagement.codeDetail.label.refId": "รหัสอ้างอิง: {{refId}}",
    "contentManagement.codeDetail.label.importedTime": "เวลานำเข้า",
    "contentManagement.codeDetail.label.purchasedTime": "เวลาที่ถูกซื้อไป",
    "contentManagement.codeDetail.label.redeemedTime": "เวลาที่ถูกใช้งาน",
    "contentManagement.codeDetail.label.expiredTime": "เวลาหมดอายุ",
    "contentManagement.button.markAsUsed": "ทำเครื่องหมายว่าใช้แล้ว",
    "contentManagement.codeDetail.label.note": "หมายเหตุ",
    "contentManagement.codeDetail.placeholder.addNote": "เพิ่มข้อความ",
    "contentManagement.codeDetail.button.done": "บันทึก",
    "contentManagement.modal.title.deleteNote": "คุณยืนยันที่จะลบข้อความหรือไม่ ?",
    "contentManagement.label.noDigitalProduct": "ไม่มีสินค้า",
    "contentManagement.modal.title.editProductCode": "ข้อมูลสินค้าและโค้ด",
    "contentManagement.tab.available": "สามารถใช้ได้",
    "contentManagement.tab.purchased": "ถูกซื้อไป",
    "contentManagement.tab.redeemed": "ใช้แล้ว",
    "contentManagement.tab.expired": "หมดอายุ",
    "contentManagement.error.notFoundDigitalContent": "ไม่มีโค้ดนี้อยู่ในระบบ",
    "contentManagement.codeDetail.label.noExpiredTime": "ไม่มีวันหมดอายุ",
    "contentManagement.default.error.alreadyRedeemed": "โค้ดนี้ถูกใช้ไปแล้ว",
    "contentManagement.default.error.notFound": "ไม่มีโค้ดนี้อยู่ในระบบ",
    "contentManagement.default.error.codeNotSold": "โค้ดนี้ยังไม่ถูกชำระเงิน",
    "contentManagement.default.error.codeExpired": "โค้ดนี้หมดอายุ",
    "contentManagement.default.error.codeNotAvailable": "โค้ดนี้ยังไม่สามารถใช้ได้",
    "contentManagement.codeDetail.label.expireAfterPurchased": "{{value}} {{unit}} หลังจากชำระเงิน",
  },
};

export default contentManagement;
