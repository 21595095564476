import { Grid } from "@material-ui/core";
import { debounce } from "lodash";
import React, { FC, useState, useContext, useEffect, ChangeEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";

import { Components } from "@1convo/web-console";
import { useMutation, useQuery } from "@apollo/client";

import { SvgIcon } from "components/Icon";
import { IcInfo } from "components/SvgIcons";
import TextField from "components/TextField";
import Typography from "components/Typography";
import COLORS from "constants/Colors";
import Switch from "components/Switch";
import Loading from "components/Modal/Loading";

import useIsDesktop from "utils/hooks/useIsDesktop";
import { GuidelineContext } from "utils/context";

import { UPDATE_PAYMENT_GUIDELINE } from "graphql/guideline/mutation";
import { GUIDELINE } from "graphql/guideline/query";

import {
  DeeplePayPaymentChannel,
  DeeplePayPaymentChannelConfiguration,
  DeeplePayPaymentChannelDetail,
} from "types/DeeplePay";
import { ProjectIdType } from "types/Project";
import { GuidelineQueryType } from "types/Guideline";

import { CREDIT_CARD_IMAGE, E_WALLET_IMAGE, MOBILE_BANKING_IMAGE, QR_CASH_LOGO, QR_CREDIT_LOGO } from "constants/Image";

import useDevice from "utils/hooks/useDevice";
import { Device } from "types/Device";
import usePaymentChannel from "./hooks/usePaymentChannel";

import { FeeBox, Item, Option, Wrapper, TextItem, DeeplePayDetailWrapper } from "./styled";

const PaymentChannel: FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { handleFetchGuideline } = useContext(GuidelineContext);
  const { projectId } = useParams<ProjectIdType>();
  const [isShowGuideline, setIsShowGuideline] = useState(false);
  const isDesktop = useIsDesktop();
  const device = useDevice();
  const isMobile = Device.MOBILE === device;

  const { paymentChannelData = [], updatePaymentChannel, updatePaymentChannelLoading } = usePaymentChannel(projectId);
  const [updatePaymentGuideline] = useMutation(UPDATE_PAYMENT_GUIDELINE);
  const { data: guidelineData } = useQuery<GuidelineQueryType>(GUIDELINE, {
    variables: {
      projectId,
    },
  });
  const isCompletePaymentSetting = guidelineData?.guideline?.hasPaymentMethod;
  const QRCashData = paymentChannelData.find(
    ({ type }: DeeplePayPaymentChannelConfiguration) => type === DeeplePayPaymentChannel.QR_CASH,
  );
  const QRCreditData = paymentChannelData.find(
    ({ type }: DeeplePayPaymentChannelConfiguration) => type === DeeplePayPaymentChannel.QR_CREDIT,
  );
  const mobileBankingData = paymentChannelData.find(
    ({ type }: DeeplePayPaymentChannelConfiguration) => type === DeeplePayPaymentChannel.MOBILE_BANKING,
  );
  const creditCardData = paymentChannelData.find(
    ({ type }: DeeplePayPaymentChannelConfiguration) => type === DeeplePayPaymentChannel.CREDIT_CARD,
  );
  const trueMoneyWalletData = paymentChannelData.find(
    ({ type }: DeeplePayPaymentChannelConfiguration) => type === DeeplePayPaymentChannel.TRUE_MONEY_WALLET,
  );

  const handleGotoSettingBillingInformation = async () => {
    await updatePaymentGuideline({ variables: { projectId } });
    setIsShowGuideline(false);
    handleFetchGuideline();
    history.push(`/project/${projectId}/deeple-pay/transaction?kyc=true`);
  };

  const handleConnectAccount = async () => {
    await updatePaymentGuideline({ variables: { projectId } });
    handleFetchGuideline();
    setIsShowGuideline(false);
  };

  const handleMinimumDeeplePayChange = async (type: DeeplePayPaymentChannel, isActive: boolean, minimum: number) => {
    await updatePaymentChannel(projectId, {
      type,
      isActive,
      minimum,
    });
  };

  const DEBOUNCE_HANDLE_DELAY = 1000;

  const debounceHandleMinimumDeeplePayChange = useCallback(
    debounce(handleMinimumDeeplePayChange, DEBOUNCE_HANDLE_DELAY),
    [handleMinimumDeeplePayChange],
  );

  useEffect(() => {
    if (isCompletePaymentSetting === false) {
      setIsShowGuideline(true);
    }
  }, [isCompletePaymentSetting]);

  return (
    <Wrapper container alignContent="flex-start" className={isDesktop ? "" : "mobile"}>
      <Grid item xs={12} className={!isDesktop ? "pl-3 py-4" : ""}>
        <Typography variant="title2">{t("deeplePay.header.paymentChannel")}</Typography>
        <DeeplePayDetailWrapper>
          <Typography variant="body2" color={COLORS.Accent}>
            <span onClick={() => setIsShowGuideline(true)} style={{ cursor: "pointer" }}>
              {t("deeplePay.paymentChannel.openGuideline")}
            </span>
          </Typography>
        </DeeplePayDetailWrapper>
      </Grid>
      {/* QR CASH */}
      {(QRCashData || QRCreditData) && (
        <Item container alignItems="center" className={isDesktop ? "px-4 py-3 mt-3" : "mobile px-4 py-3 mt-3"}>
          <Grid item xs={12} className="pb-3 mb-1">
            <Typography variant="title3">{t("deeplePay.paymentChannel.qrCode.label")}</Typography>
          </Grid>
          {QRCashData && (
            <Option
              container
              item
              xs={12}
              alignItems="center"
              className={QRCreditData ? "first line p-4" : "first last p-4"}
            >
              <Grid item xs={isDesktop ? 1 : 12}>
                <Switch
                  defaultChecked={QRCashData?.isActive}
                  disabled={!QRCashData?.isSelectable}
                  onChange={() =>
                    updatePaymentChannel(projectId, {
                      type: DeeplePayPaymentChannel.QR_CASH,
                      isActive: !QRCashData?.isActive,
                    })
                  }
                />
              </Grid>
              <Grid item className="mr-3" justify="flex-start">
                <img src={QR_CASH_LOGO} alt="qr-cash" />
              </Grid>
              <Grid item xs={isDesktop ? 6 : 9} className={isDesktop ? "" : "mt-3"}>
                <Typography variant="title8">{t("deeplePay.paymentChannel.qrCash.title")}</Typography>
                <Typography variant="body4">{t("deeplePay.paymentChannel.qrCash.description")}</Typography>
                {!isDesktop && (
                  <Typography variant="body3">
                    {QRCashData?.channels[0]?.fee?.rate &&
                      t("deeplePay.paymentChannel.fee.percent.label", {
                        value: QRCashData?.channels[0]?.fee?.rate,
                      })}
                  </Typography>
                )}
              </Grid>
              {isDesktop && (
                <FeeBox item xs={4} justify="flex-end">
                  <Typography variant="body3">
                    {QRCashData?.channels[0]?.fee?.rate &&
                      t("deeplePay.paymentChannel.fee.percent.label", {
                        value: QRCashData?.channels[0]?.fee?.rate,
                      })}
                  </Typography>
                </FeeBox>
              )}
            </Option>
          )}
          {QRCreditData && (
            <Option container item xs={12} alignItems="center" className="last p-4">
              <Grid item>
                <Switch
                  onChange={() =>
                    updatePaymentChannel(projectId, {
                      type: DeeplePayPaymentChannel.QR_CREDIT,
                      isActive: !QRCashData?.isActive,
                    })
                  }
                  defaultChecked={QRCreditData?.isActive}
                  disabled={!QRCreditData?.isSelectable}
                />
              </Grid>
              <Grid item className="mr-3" justify="flex-start">
                <img src={QR_CREDIT_LOGO} alt="qr-credit" />
              </Grid>
              <Grid item xs={isDesktop ? 6 : 9} className={isDesktop ? "" : "mt-3"}>
                <Typography variant="title8">{t("deeplePay.paymentChannel.qrCredit.title")}</Typography>
                <Typography variant="body4">{t("deeplePay.paymentChannel.qrCredit.description")}</Typography>
                {!isDesktop && (
                  <Typography variant="body3">
                    {QRCreditData?.channels[0]?.fee?.rate &&
                      t("deeplePay.paymentChannel.fee.percent.label", {
                        value: QRCreditData?.channels[0]?.fee?.rate,
                      })}
                  </Typography>
                )}
              </Grid>
              {isDesktop && (
                <FeeBox item xs={4} justify="flex-end">
                  <Typography variant="body3">
                    {QRCreditData?.channels[0]?.fee?.rate &&
                      t("deeplePay.paymentChannel.fee.percent.label", {
                        value: QRCreditData?.channels[0]?.fee?.rate,
                      })}
                  </Typography>
                </FeeBox>
              )}
            </Option>
          )}
          <Item
            container
            alignItems="center"
            className={isDesktop ? "notification p-4 mt-3" : "mobile notification p-4"}
          >
            <Grid item className="pr-3">
              <SvgIcon viewBox="0 0 20 20" htmlColor={COLORS.DarkLight} component={IcInfo} fontSize="small" />
            </Grid>
            <Grid item xs={isDesktop ? 11 : 9}>
              <Typography variant="body3">{t("deeplePay.paymentChannel.notification.label")}</Typography>
            </Grid>
          </Item>
        </Item>
      )}
      {/* MOBILE BANKING */}
      {mobileBankingData && (
        <Item
          container
          alignItems="center"
          alignContent="center"
          className={isDesktop ? "px-4 py-3 mt-3" : "mobile px-4 py-3 mt-3"}
        >
          <Grid container item xs={12} className="pb-3 mb-1">
            <Switch
              onChange={() =>
                updatePaymentChannel(projectId, {
                  type: DeeplePayPaymentChannel.MOBILE_BANKING,
                  isActive: !mobileBankingData?.isActive,
                })
              }
              className="mr-3"
              defaultChecked={mobileBankingData?.isActive}
              disabled={!mobileBankingData?.isSelectable}
            />
            <Typography variant="title3">{t("deeplePay.paymentChannel.mobileBanking.label")}</Typography>
          </Grid>
          <Grid container justify="flex-start" alignContent="center" alignItems="center" className="pb-3 mb-1">
            <Typography variant="body3" className={isMobile ? "mb-3" : "mr-1"}>
              {t("deeplePay.paymentChannel.mobileBanking.minimum")}
            </Typography>
            <TextField
              type="formatNumber"
              placeholder="฿ 0"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                debounceHandleMinimumDeeplePayChange(
                  DeeplePayPaymentChannel.MOBILE_BANKING,
                  mobileBankingData?.isActive,
                  parseInt(event.target.value, 10),
                );
              }}
              variant="outlined"
              defaultValue={mobileBankingData.minimum}
            />
            <Typography variant="body3" className="ml-1">
              {t("deeplePay.paymentChannel.minimum.unit")}
            </Typography>
          </Grid>

          {mobileBankingData?.channels?.map(({ name, fee }: DeeplePayPaymentChannelDetail, i: number) => (
            <Option
              key={name}
              container
              item
              xs={12}
              alignItems="center"
              className={i < mobileBankingData?.channels?.length - 1 ? "first line p-4" : "p-4"}
            >
              <Grid item className="mr-3" justify="flex-start">
                <img src={MOBILE_BANKING_IMAGE[name]} alt={name} />
              </Grid>
              <Grid item xs={isDesktop ? 7 : 9}>
                <Typography variant="title8">{t(`deeplePay.paymentChannel.mobileBanking.${name}.title`)}</Typography>
                <Typography variant="body4">{t(`deeplePay.paymentChannel.mobileBanking.description`)}</Typography>
                {!isDesktop && (
                  <Typography variant="body3">
                    {fee?.min &&
                      fee?.rate &&
                      t("deeplePay.paymentChannel.fee.min.label", {
                        value: fee?.rate,
                        min: fee?.min,
                      })}
                  </Typography>
                )}
              </Grid>
              {isDesktop && (
                <FeeBox item xs={4} justify="flex-end">
                  <Typography variant="body3">
                    {fee?.min &&
                      fee?.rate &&
                      t("deeplePay.paymentChannel.fee.min.label", {
                        value: fee?.rate,
                        min: fee?.min,
                      })}
                  </Typography>
                </FeeBox>
              )}
            </Option>
          ))}
        </Item>
      )}
      {/* CREDIT CARD */}
      {creditCardData && (
        <Item
          container
          alignItems="center"
          alignContent="center"
          className={isDesktop ? "px-4 py-3 mt-3" : "mobile px-4 py-3 mt-3"}
        >
          <Grid container item xs={12} className="pb-3 mb-1">
            <Switch
              onChange={() =>
                updatePaymentChannel(projectId, {
                  type: DeeplePayPaymentChannel.CREDIT_CARD,
                  isActive: !creditCardData?.isActive,
                })
              }
              className="mr-3"
              defaultChecked={creditCardData?.isActive}
              disabled={!creditCardData?.isSelectable}
            />
            <Typography variant="title3">{t("deeplePay.paymentChannel.creditCard.label")}</Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justify="flex-start"
            alignContent="center"
            alignItems="center"
            className="pb-3 mb-1"
          >
            <Typography variant="body3" className={isMobile ? "mb-3" : "mr-1"}>
              {t("deeplePay.paymentChannel.creditCard.minimum")}
            </Typography>
            <TextField
              type="formatNumber"
              placeholder="฿ 0"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                debounceHandleMinimumDeeplePayChange(
                  DeeplePayPaymentChannel.CREDIT_CARD,
                  creditCardData?.isActive,
                  parseInt(event.target.value, 10),
                );
              }}
              variant="outlined"
              defaultValue={creditCardData.minimum}
            />
            <Typography variant="body3" className="ml-1">
              {t("deeplePay.paymentChannel.minimum.unit")}
            </Typography>
          </Grid>
          {creditCardData?.channels?.map(({ name, fee }: DeeplePayPaymentChannelDetail, i: number) => (
            <Option
              key={name}
              container
              item
              xs={12}
              alignItems="center"
              className={i < creditCardData?.channels?.length - 1 ? "first line p-4" : "p-4"}
            >
              <Grid item className="mr-3" justify="flex-start">
                <img src={CREDIT_CARD_IMAGE[name]} alt={name} />
              </Grid>
              <Grid item xs={isDesktop ? 7 : 9}>
                <Typography variant="title8">{t(`deeplePay.paymentChannel.creditCard.${name}.title`)}</Typography>
                <Typography variant="body4">{t(`deeplePay.paymentChannel.creditCard.${name}.description`)}</Typography>
                {!isDesktop && (
                  <Typography variant="body3">
                    {fee?.rate &&
                      t("deeplePay.paymentChannel.fee.percent.label", {
                        value: fee?.rate,
                      })}
                  </Typography>
                )}
              </Grid>
              {isDesktop && (
                <FeeBox item xs={4} justify="flex-end">
                  <Typography variant="body3">
                    {fee?.rate &&
                      t("deeplePay.paymentChannel.fee.percent.label", {
                        value: fee?.rate,
                      })}
                  </Typography>
                </FeeBox>
              )}
            </Option>
          ))}
        </Item>
      )}
      {/* E-WALLET */}
      {trueMoneyWalletData && (
        <Item container alignItems="center" className={isDesktop ? "px-4 py-3 mt-3" : "mobile px-4 py-3 mt-3"}>
          <Grid item xs={12} className="pb-3 mb-1">
            <Typography variant="title3">{t("deeplePay.paymentChannel.eWallet.label")}</Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justify="flex-start"
            alignContent="center"
            alignItems="center"
            className="pb-3 mb-1"
          >
            <Typography variant="body3" className={isMobile ? "mb-3" : "mr-1"}>
              {t("deeplePay.paymentChannel.eWallet.minimum")}
            </Typography>
            <TextField
              type="formatNumber"
              placeholder="฿ 0"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                debounceHandleMinimumDeeplePayChange(
                  DeeplePayPaymentChannel.TRUE_MONEY_WALLET,
                  trueMoneyWalletData?.isActive,
                  parseInt(event.target.value, 10),
                );
              }}
              variant="outlined"
              defaultValue={trueMoneyWalletData.minimum}
            />
            <Typography variant="body3" className="ml-1">
              {t("deeplePay.paymentChannel.minimum.unit")}
            </Typography>
          </Grid>
          {trueMoneyWalletData && (
            <Option container item xs={12} alignItems="center" className="first last p-4">
              <Grid item xs={isDesktop ? 1 : 12}>
                <Switch
                  defaultChecked={trueMoneyWalletData?.isActive}
                  disabled={!trueMoneyWalletData?.isSelectable}
                  onChange={() =>
                    updatePaymentChannel(projectId, {
                      type: DeeplePayPaymentChannel.TRUE_MONEY_WALLET,
                      isActive: !trueMoneyWalletData?.isActive,
                    })
                  }
                />
              </Grid>
              <Grid item className="mr-3" justify="flex-start">
                <img src={E_WALLET_IMAGE.TRUE_MONEY_WALLET} alt="true-money-wallet" />
              </Grid>
              <Grid item xs={isDesktop ? 6 : 9} className={isDesktop ? "" : "mt-3"}>
                <Typography variant="title8">{t(`deeplePay.paymentChannel.trueMoneyWallet.title`)}</Typography>
                <Typography variant="body4">{t(`deeplePay.paymentChannel.trueMoneyWallet.description`)}</Typography>
                {!isDesktop && (
                  <Typography variant="body3">
                    {trueMoneyWalletData?.channels[0]?.fee?.rate &&
                      t("deeplePay.paymentChannel.fee.percent.label", {
                        value: trueMoneyWalletData?.channels[0]?.fee?.rate,
                      })}
                  </Typography>
                )}
              </Grid>
              {isDesktop && (
                <FeeBox item xs={4} justify="flex-end">
                  <Typography variant="body3">
                    {trueMoneyWalletData?.channels[0]?.fee?.rate &&
                      t("deeplePay.paymentChannel.fee.percent.label", {
                        value: trueMoneyWalletData?.channels[0]?.fee?.rate,
                      })}
                  </Typography>
                </FeeBox>
              )}
            </Option>
          )}
        </Item>
      )}
      <TextItem item xs={12}>
        <Typography variant="body3" className="text-center" color={COLORS.DarkMed}>
          {t("deeplePay.footer.explainVAT")}
        </Typography>
      </TextItem>
      <Loading isOpen={updatePaymentChannelLoading} />
      <Components.PaymentGuidelineModal
        isOpen={isShowGuideline}
        onClickDone={handleConnectAccount}
        onClickSetting={handleGotoSettingBillingInformation}
        lang={i18n.language}
        onClose={() => setIsShowGuideline(false)}
      />
    </Wrapper>
  );
};

export default PaymentChannel;
