import Collapse from "@material-ui/core/Collapse";
import React, { ChangeEvent } from "react";
import produce from "immer";
import BadgeStatus from "components/Badge/BadgeStatus";
import Checkbox from "components/Checkbox";
import CollapseButton from "components/CollapseButton";
import Typography from "components/Typography";
import useToggle from "utils/hooks/useToggle";
import ProductImage from "./ProductImage";
import getLabelStyle from "./getLabelStyle";
import { LabelStyle, OptionType } from "./type";
import { Label } from "./styled";

type OptionProps<T> = {
  data: OptionType<T>;
  onSelect?: (option: OptionType<T>) => void;
};

const Option = <T extends Object>({ data, onSelect = () => {} }: OptionProps<T>) => {
  const { isOpen, handleToggle } = useToggle(Boolean(data.isExpandedByDefault));
  const optionsLength = data?.options?.length;
  const haveOptions = Boolean(optionsLength);
  const checkedNo = data?.options?.reduce((total, option) => (option.isChecked ? total + 1 : total), 0);
  const isHeader = data?.value == null && haveOptions;
  const labelStyle: LabelStyle = getLabelStyle(data);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newOption = produce(data, (draft) => {
      draft.isChecked = event.target?.checked;
      if (draft.options?.length) {
        for (let index = 0; index < draft.options.length; index += 1) {
          draft.options[index].isChecked = event.target?.checked;
        }
      }
    });

    onSelect(newOption);
  };

  return (
    <div className="ml-2">
      <div className={`${haveOptions ? "p-2" : "py-2 pl-3 pr-2"} d-flex align-items-center`} style={labelStyle.style}>
        {!isHeader && (
          <>
            <Checkbox checked={Boolean(data?.isChecked)} className="align-middle mr-2" onChange={handleChange} />

            {data?.isActive != null ? (
              <BadgeStatus
                overlap="circle"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
                classes={{
                  badge: data?.isActive ? "" : "inactive",
                }}
              >
                <ProductImage className="align-middle mr-2" label={data.label} url={data?.imageUrl} />
              </BadgeStatus>
            ) : (
              <ProductImage className="align-middle mr-2" label={data.label} url={data?.imageUrl} />
            )}
          </>
        )}
        <div className="d-flex flex-1 align-items-center justify-content-between ">
          <Label>
            <Typography className="mr-2 " variant={labelStyle.variant} color={labelStyle.color}>
              {data.label}
            </Typography>
            <Typography className="mr-2 " variant="body4" color="darkLight">
              {data?.subLabel}
            </Typography>
          </Label>

          <div className="d-flex align-items-center">
            {data.rightLabel && (
              <Typography className="mr-2" variant="body4" color="darkMed">
                {data.rightLabel}
              </Typography>
            )}
            {haveOptions && (
              <>
                <Typography
                  className="mr-2"
                  variant="body4"
                  color="darkMed"
                >{`${checkedNo}/${optionsLength}`}</Typography>
                <CollapseButton isShow={isOpen} degree={0} onClick={handleToggle} />
              </>
            )}
          </div>
        </div>
      </div>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {data?.options?.map((option) => (
          <Option key={option.label} data={option} onSelect={onSelect} />
        ))}
      </Collapse>
    </div>
  );
};

export default Option;
