import { ActionType, DeepleAction } from "types/RichMenu";

export const ACTION_OPTIONS = [
  { label: "TEXT", value: ActionType.TEXT },
  { label: "LINK", value: ActionType.URL },
  { label: "DEEPLE_ACTION", value: ActionType.DEEPLE_ACTION },
];

export const ACTION_FACEBOOK_OPTIONS = [
  { label: "LINK", value: ActionType.URL },
  { label: "DEEPLE_ACTION", value: ActionType.DEEPLE_ACTION },
];

export const MAP_DEEPLE_ACTION = {
  [DeepleAction.DEEPLE_PRODUCT_CATALOG]: "PRODUCT_CATALOG",
  [DeepleAction.DEEPLE_TOP_SELLING]: "TOP_SELLING_PRODUCTS",
  [DeepleAction.DEEPLE_SHOPPING_CART]: "SHOPPING_CART",
  [DeepleAction.DEEPLE_ORDER_HISTORY]: "ORDER_HISTORY",
  [DeepleAction.DEEPLE_CONTACT_ADMIN]: "CONTACT_ADMIN",
};

export const DEEPLE_ACTION_OPTIONS = [
  { label: "PRODUCT_CATALOG", value: DeepleAction.DEEPLE_PRODUCT_CATALOG },
  { label: "TOP_SELLING_PRODUCTS", value: DeepleAction.DEEPLE_TOP_SELLING },
  { label: "SHOPPING_CART", value: DeepleAction.DEEPLE_SHOPPING_CART },
  { label: "ORDER_HISTORY", value: DeepleAction.DEEPLE_ORDER_HISTORY },
  { label: "CONTACT_ADMIN", value: DeepleAction.DEEPLE_CONTACT_ADMIN },
];
