import produce from "immer";
import get from "lodash/get";

import { OptionType } from "domain/Product/ProductListWithSearch/ProductSelector/Option/type";
import { NewProductItemProps, NewProductSKU } from "types/Product";
import { convertProductTypeToText, convertPriceFormat } from "utils/common";
import isProductSKUActive from "utils/product/isProductSKUActive";
import { NO_IMG_AVAILABLE } from "constants/Image";

const formatProductSKUToOption = (
  t: Function,
  productSKUs: NewProductSKU[],
  selectedIds: string[],
  parent?: OptionType<NewProductItemProps>,
  defaultImageUrl?: string,
  isFreeProductSelector = false,
): {
  selectedOptions: OptionType<NewProductSKU, NewProductItemProps>[];
  options: OptionType<NewProductSKU, NewProductItemProps>[];
} => {
  const filteredProductSKUs = productSKUs.filter((productSKU) => productSKU.isFree === isFreeProductSelector);
  const options = filteredProductSKUs.map((productSKU) => {
    return {
      parent,
      label: productSKU.productType?.length ? convertProductTypeToText(productSKU.productType) : parent?.label || "",
      subLabel: productSKU.cfCode || "",
      value: productSKU,
      imageUrl: get(productSKU, "image.src") || defaultImageUrl,
      isChecked: selectedIds.some((selectedId) => selectedId === productSKU.id),
      isActive: isProductSKUActive(productSKU.selected, productSKU.inventory),
      rightLabel: `${t("In stock")} ${convertPriceFormat(productSKU.inventory, 0)}`,
    };
  });

  return {
    options,
    selectedOptions: options.filter((option) => option.isChecked),
  };
};

const formatProductToOption = (
  t: Function,
  product: NewProductItemProps,
  selectedIds: string[],
  isFreeProductSelector = false,
): {
  option: OptionType<NewProductItemProps, NewProductSKU>;
  selectedOption: OptionType<NewProductItemProps, NewProductSKU>;
} => {
  const newProduct = produce(product, (draft) => {
    product.productSKUs.forEach((productSKU, index) => {
      draft.productSKUs[index].isChecked = selectedIds.some((selectedId) => selectedId === productSKU.id);
    });
  });

  const imageUrl = get(product, "images.0.src") || NO_IMG_AVAILABLE;

  const option = {
    imageUrl,
    isChecked: false,
    label: product?.name || "",
    subLabel: product.cfCode || "",
    value: newProduct,
  };

  const { options, selectedOptions } = formatProductSKUToOption(
    t,
    product.productSKUs,
    selectedIds,
    option,
    imageUrl,
    isFreeProductSelector,
  );

  const isChecked = options.some((option) => option.isChecked);

  return {
    selectedOption: {
      ...option,
      isChecked,
      options: selectedOptions,
    },
    option: {
      ...option,
      isChecked,
      options,
    },
  };
};

const groupProductByCategory = (
  t: Function,
  products?: NewProductItemProps[],
  selectedProducts?: NewProductItemProps[],
  isFreeProductSelector = false,
) => {
  if (!products || !products.length) {
    return {
      groupProducts: [],
      selectedOptions: [],
    };
  }

  const selectedProductSKUIds = selectedProducts?.flatMap((selectedProduct) =>
    selectedProduct.productSKUs.map((productSKU) => productSKU.id),
  ) as string[];

  const totalSelectedOptions: OptionType<NewProductItemProps, NewProductSKU>[] = [];

  const groupProducts = products.reduce<OptionType<NewProductItemProps, NewProductSKU>[]>((productGroups, product) => {
    const { option, selectedOption } = formatProductToOption(t, product, selectedProductSKUIds, isFreeProductSelector);
    const index = productGroups.findIndex((productGroup) => productGroup.label === get(product, "categories.0.name"));
    const isExist = index >= 0;

    const hasSomeChecked = selectedOption.options?.some((option) => option.isChecked);
    if (selectedOption.isChecked || hasSomeChecked) {
      totalSelectedOptions.push(selectedOption);
    }

    if (isExist) {
      const group = productGroups[index];
      group.options = group.options ? [...group.options, option] : [];
    } else {
      productGroups.push({
        isExpandedByDefault: true,
        label: get(product, "categories.0.name") || "",
        options: [option],
      });
    }

    return productGroups;
  }, []);

  return {
    groupProducts,
    newSelectedOptions: totalSelectedOptions,
  };
};

export default groupProductByCategory;
