import React, { useState, useEffect, MouseEvent } from "react";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { NOTIFICATIONS } from "graphql/notification/query";
import { MARK_NOTIFICATION } from "graphql/notification/mutation";
import { NOTIFICATIONS as NOTIFICATIONS_LINK, NOTIFICATION_SETTING, USER_SETTING, PROJECT } from "constants/Router";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import ButtonBase from "components/ButtonBase";
import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import Typography from "components/Typography";
import Popover from "@material-ui/core/Popover";
import { IcMore, IcNotificationSetting, IcOpenNotification } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";
import { MAXIMUM_PREVIEW_NOTIFICATION_MESSAGES, LIMIT_PREVIEW_NOTIFICATION_MESSAGES } from "constants/Notification";
import { NotificationInfo } from "types/Notification";
import COLORS from "constants/Colors";
import NotificationListItem from "../NotificationListItem";

type NotificationListPropsType = {
  projectId: string;
  unseenNumber: number;
  handleClose: () => void;
  handleSeen: () => void;
  setNotificationData: (data: NotificationInfo[]) => void;
  notificationData: NotificationInfo[] | [];
};

export const NotificationList: React.FC<NotificationListPropsType> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { projectId, handleClose, notificationData, setNotificationData, unseenNumber } = props;

  const [hasMore, setHasMore] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { data: notificationDataResponse } = useQuery(NOTIFICATIONS, {
    variables: {
      projectId,
      offset: pageNo - 1,
      limit: LIMIT_PREVIEW_NOTIFICATION_MESSAGES,
    },
    fetchPolicy: "network-only",
  });

  const [markNotification, { loading: markNotificationLoading }] = useMutation(MARK_NOTIFICATION);

  const onClickMarkResponse = async (notificationId: string) => {
    if (!markNotificationLoading) {
      const markResponse = await markNotification({
        variables: {
          projectId,
          notificationId: Number(notificationId),
        },
      });

      if (get(markResponse, "data.markNotification.id")) {
        setNotificationData([
          ...(notificationData as NotificationInfo[]).map((notificationItem: NotificationInfo) => {
            if (notificationItem.id === get(markResponse, "data.markNotification.id")) {
              return {
                ...notificationItem,
                readAt: get(markResponse, "data.markNotification.readAt"),
              };
            }
            return notificationItem;
          }),
        ]);
      }
    }
  };

  const fetchMoreData = () => {
    const notificationsTotal = Number(get(notificationDataResponse, "notifications.total"));
    if (
      notificationData.length >= MAXIMUM_PREVIEW_NOTIFICATION_MESSAGES ||
      notificationData.length >= notificationsTotal
    ) {
      setHasMore(false);
    } else {
      setTimeout(() => {
        setPageNo(pageNo + 1);
      }, 500);
    }
  };

  useEffect(() => {
    const notificationsTotal = Number(get(notificationDataResponse, "notifications.total"));

    if (notificationsTotal === 0 || notificationData.length === notificationsTotal) {
      setHasMore(false);
    }

    if (
      notificationData.length <= notificationsTotal &&
      notificationData.length <= MAXIMUM_PREVIEW_NOTIFICATION_MESSAGES
    ) {
      setNotificationData(
        uniqBy([...notificationData, ...get(notificationDataResponse, "notifications.results")], "id"),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationDataResponse]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    return () => {
      setNotificationData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notificationsTotal = Number(get(notificationDataResponse, "notifications.total"));
  const open = Boolean(anchorEl);
  const id = open ? "Notification-more-popover" : undefined;

  return (
    <>
      <Grid item xs={12} className="py-2 pb-3 px-2">
        <Grid container>
          <Grid item xs={6} container alignContent="center" alignItems="center">
            <Typography variant="title2" color="dark">
              {t("NOTIFICATION")}
            </Typography>
            {Boolean(unseenNumber) && (
              <Typography variant="title7" color="error">
                ({unseenNumber})
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} container justify="flex-end" alignContent="center" alignItems="center">
            <SvgIcon className="mt-2" onClick={handleClick}>
              <IcMore fontSize={20} />
            </SvgIcon>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleCloseMoreMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Grid container className="my-2" style={{ width: 220 }}>
                <ButtonBase
                  className="d-flex w-100 justify-content-start pl-3"
                  onClick={() => {
                    history.push(`/${PROJECT}/${projectId}/${USER_SETTING}/${NOTIFICATIONS_LINK}`);
                    handleCloseMoreMenu();
                    handleClose();
                  }}
                >
                  <SvgIcon className="mt-2">
                    <IcOpenNotification fontSize={24} color={COLORS.DarkGray} />
                  </SvgIcon>
                  <Typography variant="body3">{t("Notifications")}</Typography>
                </ButtonBase>

                <ButtonBase
                  className="d-flex w-100 justify-content-start pl-3"
                  onClick={() => {
                    history.push(`/${PROJECT}/${projectId}/${USER_SETTING}/${NOTIFICATION_SETTING}`);
                    handleCloseMoreMenu();
                    handleClose();
                  }}
                >
                  <SvgIcon className="mt-2">
                    <IcNotificationSetting fontSize={24} color={COLORS.DarkGray} />
                  </SvgIcon>
                  <Typography variant="body3">{t("Notification setting")}</Typography>
                </ButtonBase>
              </Grid>
            </Popover>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <InfiniteScroll
          dataLength={notificationData.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            <Grid container justify="center" className="my-2">
              <CircularProgress />
            </Grid>
          }
          height={480}
          endMessage={
            <>
              {notificationsTotal !== 0 && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  href="#"
                  className="text-center"
                  onClick={() => {
                    history.push(`/${PROJECT}/${projectId}/${USER_SETTING}/${NOTIFICATIONS_LINK}`);
                    handleClose();
                  }}
                >
                  <Typography variant="body4" color="accent" className="my-2">
                    {t("SEE_ALL")}
                  </Typography>
                </a>
              )}
            </>
          }
        >
          {notificationsTotal === 0 && (
            <Grid container justify="center" className="my-2">
              <Typography variant="body3">{t("NO_NOTIFICATION")}</Typography>
            </Grid>
          )}
          {(notificationData as NotificationInfo[]).map((data: NotificationInfo, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item xs={12} key={index}>
              <NotificationListItem
                notificationInfo={data}
                onClickMarkResponse={onClickMarkResponse}
                handleClose={handleClose}
              />
            </Grid>
          ))}
        </InfiniteScroll>
      </Grid>
    </>
  );
};

export default NotificationList;
