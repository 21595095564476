import React, { FC } from "react";
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableStateSnapshot,
  Draggable,
  DraggableProvided,
} from "react-beautiful-dnd";

import { IcDrag } from "components/SvgIcons";
import FlexCategoryCard from "components/FlexMessage/FlexCategories/FlexCategoryCard";
import COLORS from "constants/Colors";
import { CategoryType } from "types/Category";

import { ScrollContainer, DropZone, CategoryListWrapper } from "./styled";

type CategoryListsPropsType = {
  className?: string;
  listId: string;
  categories: CategoryType[];
};

const CategoryLists: FC<CategoryListsPropsType> = ({ listId, categories, className }) => {
  const hasAllCategoryCard = categories.some(({ isHomePageCategory }) => isHomePageCategory);

  const renderCategoryLists = (dropProvided: DroppableProvided) => {
    return (
      <DropZone ref={dropProvided.innerRef}>
        {categories.map((category: CategoryType, index: number) => (
          <Draggable key={category.id} draggableId={category.id} index={index}>
            {(dragProvided: DraggableProvided, _dragSnapshot: DraggableStateSnapshot) => (
              <CategoryListWrapper
                className={className}
                limitIndex={hasAllCategoryCard ? 10 : 9}
                ref={(ref) => dragProvided.innerRef(ref)}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                {...(dragProvided.draggableProps as any)}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                {...(dragProvided.dragHandleProps as any)}
              >
                <IcDrag color={COLORS.DarkMed} />
                <FlexCategoryCard category={category} />
              </CategoryListWrapper>
            )}
          </Draggable>
        ))}
        {dropProvided.placeholder}
      </DropZone>
    );
  };

  return (
    <Droppable droppableId={listId} direction="horizontal">
      {(dropProvided: DroppableProvided, _dropSnapshot: DroppableStateSnapshot) => (
        <div {...dropProvided.droppableProps}>
          <ScrollContainer>{renderCategoryLists(dropProvided)}</ScrollContainer>
        </div>
      )}
    </Droppable>
  );
};

export default CategoryLists;
