const products = {
  en: {
    "No.": "No.",
    Food: "Food",
    Product: "Product",
    Products: "Products",
    Catalogs: "Catalogs",
    Categories: "Product catalog",
    Inventory: "Inventory",
    Price: "Price",
    "Food details": "Food details",
    "Food image": "Food image",
    "Food name": "Food name",
    "Product details": "Product details",
    "Product Code": "Product Code",
    ProductWeight: "Weight per unit (gram)",
    ProductDimension: "Product dimension Width × Length × Height",
    productDimensionWidth: "Width",
    productDimensionLength: "Length",
    productDimensionHeight: "Height",
    Brand: "Brand",
    "This product is free": "This product is free",
    "This product SKU is free": "This product SKU is free",
    "Active product": "Active product",
    "Product images": "Product images",
    "Product name": "Product name",
    "Add new property": "Add new property",
    "Next Product SKU": "Next Product SKU",
    "Next Content": "Next Content",
    "Product SKU": "Product SKU",
    "products.variations": "Variations",
    "Please fill property value": "Please fill property value",
    "Please fill variation value": "Please fill variation value",
    "Add new variation": "Add new variation",
    "Product Description": "Product Description",
    "Product Conditions": "How to use / Conditions",
    "Add new product": "Add new product",
    "Digital content": "Digital content",
    "Add new food": "Add new food",
    "product.digitalContent.addTitle": "Add new digital content product",
    "product.digitalContent.editTitle": "Edit digital content product",
    "Product Unit": "Product Unit",
    Free: "Free",
    "Property name": "Property name",
    "property value": "property value",
    "Please fill product name": "Please fill product name",
    "Please select category": "Please select category",
    "Please fill product code": "Please fill product code (Product code must have at least 3  character)",
    "Please fill product price": "Please fill product price",
    "Please fill how to use / conditions": "Please fill how to use / conditions",
    "Please specify property name": "Please specify property name",
    "Please specify property value": "Please specify property value",
    "Edit product": "Edit product",
    "Edit food": "Edit food",
    "SKU name": "SKU name",
    "SKU value": "SKU value",
    "Product SKU code": "Product SKU code (Start with at least 1 English alphabet)",
    "Product SKU image": "Product SKU image",
    "Delete Product": "Delete Product",
    "Product Updated": "Product Updated",
    "Confirm Delete": "Confirm Delete",
    "Do you want to delete this product": "Do you want to delete this product?",
    "Product name can not contain plus sign": "Product code cannot contain +",
    "Product name must be below 100 characters": "Product name must be below 100 characters",
    "Product code can not contain white space": "Product code can not contain white space",
    "Product code must have 3 or more character": "Product code must have 3 or more characters",
    "Product code must have at least 1 alphabet character":
      "Product code must have at least 1 alphabet character ex. A123",
    "Product code must start with alphabet or number": "Product code must start with alphabet or number",
    "Product code must end with alphabet or number": "Product code must end with alphabet or number",
    "product.productTypeSelectorModal.title": "Choose product type",
    "product.addFoodForm.next": "Next: Variations",
    "product.addFoodForm.noVariationTitle": "No variation here",
    "product.addFoodForm.noVariationGuide": "Hit the button below to create new variation",
    "product.addFoodForm.variationCard.options": "Options",
    "product.addFoodForm.variationCard.singleChoose": "Single choose",
    "product.addFoodForm.variationCard.multipleChoose": "Multiple choose",
    "product.addFoodForm.variationCard.name": "Variation name",
    "product.addFoodForm.variationCard.choicePrice": "Add-on price",
    "product.addFoodForm.variationCard.addMoreOption": "Add more option",
    "product.addFoodForm.variationCard.maximum": "Maximum",
    "product.addFoodForm.variationCard.makeItOptional": "Make it optional",
    "product.addProduct.errorMessage": "Product name or product code is duplicated with other product",
    "product.addFoodForm.errorMessage.atLeastActive": "At least one option should be active",
    "product.addFoodForm.errorMessage.maximum": "Maximum should be less than number of options",
    "product.addFoodForm.errorMessage.variationName": "Please fill variation name",
    "product.addFoodForm.errorMessage.optionName": "Please fill option name",
    "product.addFoodForm.errorMessage.duplicateOption": `"{{duplicatedOption}}" name variation is duplicated`,
    "product.addFoodForm.errorMessage.duplicateChoice": `"{{duplicatedChoice}}" choice from "{{optionName}}" is duplicated`,
    "Please fill name": "Please fill name",
    "product.button.addNewProduct": "Add new product",
    "product.import.button.label": "Import product",
    "product.import.button.subLabel": "Format: CSV / XLSX",
    "product.import.error.fileType": "File type is not permitted",
    "product.import.error.format": "Imported products is incorrect format",
    "product.import.error.noCategory": "There is some category which is not in this store",
    "product.import.error.conflict": "Some import products have duplicated product code in store",
    "product.import.error.overSize": "Import file must be less than {{maxSize}} MB",
    "product.import.error.duplicatedProductName": "Some import products have duplicated product name in store",
    "product.import.error.descriptionExceedLimit": "Some import products have description exceed 2,500 characters",
    "product.import.error.priceReachLimit": "Import product prices must be less than 1 million baht",
    "product.download.sample": "Download a sample",
    "product.importCode.button.label": "Import codes",
    "product.importCode.button.subLabel": "Format: CSV / XLSX",
    "product.importCode.success": "Import codes success",
    "product.delete.error.inventoryNotEmpty":
      "Cannot delete product, Please make sure that SKU inventory of this product is 0",
    "product.edit.error.inventoryNotEmpty":
      "Cannot edit product, Please make sure that SKU inventory of this product is 0",
    "product.default.error.duplicatedProductName": "Product name is duplicated with other product",
    "product.default.error.duplicatedProductCode": "Product code is duplicated with other product",
    "product.default.error.duplicatedCFCode": "CF code is duplicated with other product",
    "product.default.error.descriptionExceedLimit": "Product description cannot exceed 2,500 characters",
    "product.default.error.priceReachLimit": "Product price must be less than 1 million baht",
    "product.default.error.overQuota": "Product quota reached limit. Please upgrade your plan",
    "product.default.error.productCodeNotFound": "Product code is not found",
    "product.default.error.incorrectDigitalContent": "Incorrect Digital content",
    "product.default.error.duplicatedDigitalContent": "Duplicated Digital content",
    "product.default.error.outputTypeIsNotCodeImport": "Output type is not code import",
    "product.default.error.contentAvailabilityIsNotMatch": "Content Availability is not match",
    "product.default.error.skuValueCannotContainKey": "SKU name and SKU value must not be the same",
    "product.default.error.skuKeyDuplicated": "SKU name must not be the same",
    TOP_UP_AND_VOUCHER: "Top up / Voucher",
    EVENT_TICKET: "Event ticket",
    ONLINE_COURSE: "Online course",
    "Select digital content type": "Select digital content type",
    STATIC: "Deep link / Link / Text",
    QR_CODE: "QR code",
    CODE_IMPORTED: "Code - Import a CSV / XLSX file",
    CODE_AUTO_GENERATED: "Code - Console generated",
    CODE_GENERATED_FROM_OTHER: "Code - Generated by other systems",
    "Digital content output": "Digital content output",
    "Please select content type": "Please select content type",
    "Please verify product data or Product SKU fields": "Please verify product data or Product SKU fields",
    "productDimensionWidth.required": "Parcel width is required",
    "productDimensionWidth.isMaximum": "Parcel width must be less than 10,000.00 cm",
    "productDimensionLength.required": "Parcel length is required",
    "productDimensionLength.isMaximum": "Parcel length must be less than 10,000.00 cm",
    "productDimensionHeight.required": "Parcel height is required",
    "productDimensionHeight.isMaximum": "Parcel height must be less than 10,000.00 cm",
    "productDimension.isMaximum": "Parcel size must be less than 10,000.00 cm",
    "Please fill value": "Please fill value",
    ProductDeleted: "Product Deleted",
    "product.default.error.incorrectProduct": "Product is in correct",
    "product.default.error.duplicateCFCode": "Product CF code is duplicated with other product",
    "Product CF Code": "Product CF Code",
    "Please put CF Code here": "Please put CF Code here",
    notSupportProductCode:
      "Product code must be at least 3 character but not more than 50 characters, allow EN/TH alphabet or number, special character allow only ( _ ) and ( - ), and cannot contain any space between the code",
    notSupportProductCodeLength: "Code must have 3 or more characters and less than 50 characters",
    notSupportCFCode:
      "CF code must be at least 3 character, must start with EN/TH alphabet, allow alphabet, number, ( _ ) and ( - ) and cannot contain any space between the code",
    SEARCH_PRODUCT: "Search product",
    PRODUCT_SELECTOR: "Product selector",
    CLEAR_ALL: "Clear all",
    SELECTED_PRODUCTS: "Selected products",
    NO_FOUND_PRODUCTS: "No found products",
    "HighlightProduct.title": "Highlight Product",
    "HighlightProduct.title.tooltip":
      "‘Product display’ feature enables custom arrangement of 10 highlighted products via Line Official Account.",
    editHighlightProduct: "Edit highlight product",
    noHighlightProduct: "No highlight product",
    displayHighlightProduct: "Display Highlight product in menu ",
    detectChangeCategory:
      "Are you sure you want to transfer this product to ‘{{selectCategoryName}}’? As it will be removed from being a highlighted product of ‘{{prevCategoryName}}’.",
    "product.cfCode.optional": "optional but it needs to fill if you want to use Live to chat feature",
  },
  th: {
    "No.": "ลำดับ ",
    Food: "อาหาร",
    Product: "สินค้า",
    Products: "สินค้า",
    Catalogs: "เมนูสินค้า",
    Categories: "เมนูสินค้า",
    Inventory: "คลังสินค้า",
    Price: "ราคา",
    "Food name": "ชื่ออาหาร",
    "Food image": "รูปอาหาร",
    "Food details": "รายละเอียดอาหาร",
    "Product details": "รายละเอียดสินค้า",
    "Product Code": "โค้ดสินค้า",
    ProductWeight: "น้ำหนักต่อหน่วย (กรัม)",
    ProductDimension: "ขนาดของพัสดุ (กว้าง × ยาว × สูง)",
    productDimensionWidth: "ความกว้างของพัสดุ",
    productDimensionLength: "ความยาวของพัสดุ",
    productDimensionHeight: "ความสูงของพัสดุ",
    Brand: "ยี่ห้อสินค้า",
    "This product is free": "ตั้งให้สินค้าชิ้นนี้ฟรี",
    "This product SKU is free": "ตั้งให้สินค้าชิ้นนี้ฟรี",
    "Active product": "เปิดใช้งาน",
    "Product images": "รูปภาพสินค้า",
    "Product name": "ชื่อสินค้า",
    "Add new property": "เพิ่มคุณสมบัติ",
    "Next Product SKU": "ขั้นตอนถัดไป สร้างตัวเลือกสินค้า",
    "Next Content": "ขั้นตอนถัดไป สร้างคอนเทนต์",
    "Product SKU": "ตัวเลือกสินค้า (SKU)",
    "products.variations": "ตัวเลือกของสินค้า (เช่น ขนาด, สี)",
    "Please fill property value": "กรุณาระบุค่าคุณสมบัติ",
    "Please fill variation value": "กรุณาระบุค่าตัวเลือกสินค้า",
    "Add new variation": "เพิ่มตัวเลือกสินค้า",
    "Product Description": "คำอธิบายสินค้า",
    "Product Conditions": "วิธีการใช้งาน / เงื่อนไข",
    "Add new product": "สร้างสินค้าใหม่",
    "Add new food": "สร้างรายการอาหารใหม่",
    "product.digitalContent.addTitle": "สร้างสินค้าดิจิทัลใหม่",
    "product.digitalContent.editTitle": "แก้ไขสินค้าดิจิทัล",
    "Digital content": "สินค้าดิจิทัล",
    "Product Unit": "หน่วยสินค้า",
    Free: "ฟรี",
    "Property name": "ชื่อคุณสมบัติ",
    "property value": "ค่าคุณสมบัติ",
    "Please fill product name": "กรุณาระบุชื่อสินค้า",
    "Please select category": "กรุณาเลือกเมนูสินค้า",
    "Please fill product code": "กรุณาระบุโค้ดสินค้า (รหัสสินค้าต้องมีอย่างน้อย 3 ตัว)",
    "Please fill product price": "กรุณาระบุราคาสินค้า",
    "Please fill how to use / conditions": "กรุณาระบุ วิธีการใช้งาน / เงื่อนไข",
    "Please specify property name": "กรุณาระบุชื่อคุณสมบัติ",
    "Please specify property value": "กรุณาระบุค่าคุณสมบัติ",
    "Edit product": "แก้ไขสินค้า",
    "Edit food": "แก้ไขรายการอาหาร",
    "SKU name": "ชื่อตัวเลือกสินค้า (SKU name) เช่น สี, ขนาด",
    "SKU value": "ค่าของตัวเลือกสินค้า (SKU value) เช่น สีแดง, ไซส์ 36, 9 us",
    "Product SKU code": "โค้ดสินค้า (SKU code ต้องมีตัวอักษรภาษาอังกฤษอย่างน้อย 1 ตัวอักษรเช่น A123)",
    "Product SKU image": "รูปภาพของสินค้า",
    "Delete Product": "ลบสินค้า",
    "Product Updated": "สินค้าถูกแก้ไขแล้ว",
    "Confirm Delete": "ยืนยันการลบ",
    "Do you want to delete this product": "คุณต้องการลบสินค้าชิ้นนี้หรือไม่",
    "Product name can not contain plus sign": "ชื่อสินค้าไม่สามารถมีเครื่องหมาย + ได้",
    "Product name must be below 100 characters": "ชื่อสินค้าต้องมีไม่เกิน 100 ตัวอักษร",
    "Product code can not contain white space": "รหัสสินค้าไม่สามารถเว้นวรรคได้",
    "Product code must have 3 or more character": "รหัสสินค้าต้องมี 3 ตัวอักษรขึ้นไป",
    "Product code must have at least 3 characters": "รหัสสินค้าต้องมีตัวอักษรภาษาอังกฤษอย่างน้อย 1 ตัวอักษร",
    "CF code must have at least 1 alphabet character": "รหัส CF สินค้าต้องมีตัวอักษรภาษาอังกฤษอย่างน้อย 1 ตัวอักษร",
    "Product code must start with alphabet or number": "รหัสสินค้าต้องขึ้นต้นด้วยตัวอักษรหรือตัวเลขเท่านั้น",
    "Product code must end with alphabet or number": "รหัสสินค้าต้องลงท้ายด้วยตัวอักษรหรือตัวเลขเท่านั้น",
    "product.productTypeSelectorModal.title": "โปรดเลือกประเภทสินค้า",
    "product.addFoodForm.next": "ขั้นต่อไป: เพิ่มตัวเลือก",
    "product.addFoodForm.noVariationTitle": "ยังไม่มีตัวเลือกสินค้า",
    "product.addFoodForm.noVariationGuide": "กดปุ่มข้างล่าง เพื่อเพิ่มตัวเลือกสินค้า",
    "product.addFoodForm.variationCard.options": "ตัวเลือกย่อย",
    "product.addFoodForm.variationCard.singleChoose": "ตัวเลือกเดียว",
    "product.addFoodForm.variationCard.multipleChoose": "หลายตัวเลือก",
    "product.addFoodForm.variationCard.name": "ชื่อตัวเลือก",
    "product.addFoodForm.variationCard.choicePrice": "ราคา",
    "product.addFoodForm.variationCard.addMoreOption": "เพิ่มตัวเลือกย่อย",
    "product.addFoodForm.variationCard.maximum": "จำนวนที่เลือกได้มากที่สุด",
    "product.addFoodForm.variationCard.makeItOptional": "ไม่จำเป็นต้องเลือก",
    "product.addProduct.errorMessage": "ชื่อสินค้า หรือ รหัสสินค้า ซ้ำกับสินค้าอื่น",
    "product.addFoodForm.errorMessage.atLeastActive": "ควรเปิดใช้งานตัวเลือกย่อยอย่างน้อย 1 ตัว",
    "product.addFoodForm.errorMessage.maximum": "จำนวนที่เลือกได้มากที่สุดต้องน้อยกว่าจำนวนตัวเลือกย่อย",
    "product.addFoodForm.errorMessage.variationName": "กรุณาใส่ชื่อตัวเลือก",
    "product.addFoodForm.errorMessage.optionName": "กรุณาใส่ชื่อตัวเลือกย่อย",
    "product.addFoodForm.errorMessage.duplicateOption": `ชื่อตัวเลือก "{{duplicatedOption}}" ซ้ำกับตัวเลือกอื่น`,
    "product.addFoodForm.errorMessage.duplicateChoice": `ชื่อตัวเลือกย่อย "{{duplicatedChoice}}" ของ "{{optionName}}" ซ้ำกัน`,
    "Please fill name": "กรุณาใส่ชื่อ",
    "product.button.addNewProduct": "เพิ่มสินค้า",
    "product.import.button.label": "นำเข้าสินค้า",
    "product.import.button.subLabel": "รูปแบบ: CSV / XLSX",
    "product.import.error.fileType": "รูปแบบไฟล์ต้องเป็น csv หรือ xlsx",
    "product.import.error.format": "ข้อมูลที่นำเข้ามามีบางจุดไม่ถูกต้อง",
    "product.import.error.noCategory": "มีเมนูสินค้าที่ไม่มีในระบบ",
    "product.import.error.conflict": "ข้อมูลที่นำเข้ามามีรหัสสินค้าซ้ำกับในระบบ",
    "product.import.error.overSize": "ไฟล์ที่นำเข้าควรมีขนาดน้อยกว่า {{maxSize}} MB",
    "product.import.error.duplicatedProductName": "ข้อมูลที่นำเข้ามามีชื่อสินค้าซ้ำกับในระบบ",
    "product.import.error.descriptionExceedLimit": "ข้อมูลที่นำเข้ามามีคำอธิบายสินค้าเกิน 2,500 ตัวอักษร",
    "product.import.error.priceReachLimit": "ข้อมูลที่นำเข้ามา ราคาสินค้าต้องมีค่าไม่เกินกว่า 1 ล้านบาท",
    "product.download.sample": "ดาวน์โหลดตัวอย่างไฟล์",
    "product.importCode.button.label": "นำเข้า Code",
    "product.importCode.button.subLabel": "รูปแบบ: CSV / XLSX",
    "product.importCode.success": "นำเข้า Code สำเร็จ",
    "product.delete.error.inventoryNotEmpty":
      "ไม่สามารถลบสินค้า ได้โปรดตรวจสอบให้แน่ใจว่า สินค้าในคลังสินค้าของสินค้านี้เหลือ 0 ชิ้น",
    "product.edit.error.inventoryNotEmpty":
      "ไม่สามารถแก้ไขสินค้า ได้โปรดตรวจสอบให้แน่ใจว่า สินค้าในคลังสินค้าของสินค้านี้เหลือ 0 ชิ้น",
    "product.default.error.duplicatedProductName": "ชื่อสินค้าซ้ำกับสินค้าอื่น",
    "product.default.error.duplicatedProductCode": "รหัสสินค้าซ้ำกับสินค้าอื่น",
    "product.default.error.duplicatedCFCode": "รหัส CF สินค้าซ้ำกับสินค้าอื่น",
    "product.default.error.descriptionExceedLimit": "คำอธิบายสินค้าไม่ควรเกิน 2,500 ตัวอักษร",
    "product.default.error.priceReachLimit": "ราคาสินค้าต้องมีค่าไม่เกินกว่า 1 ล้านบาท",
    "product.default.error.overQuota": "โค้วต้าการเพิ่มสินค้าเต็ม กรุณาอัพเกรดแพลนของคุณ",
    "product.default.error.productCodeNotFound": "ไม่พบรหัสสินค้า",
    "product.default.error.incorrectDigitalContent": "สินค้าดิจิทัลไม่ถูกต้อง",
    "product.default.error.duplicatedDigitalContent": "สินค้าดิจิทัลซ้ำ",
    "product.default.error.outputTypeIsNotCodeImport": "รูปแบบของสินค้าดิจิทัล ไม่ใช่ import",
    "product.default.error.contentAvailabilityIsNotMatch": "ประเภทระยะเวลาการใช้งานของสินค้าดิจิทัลไม่ถูกต้อง",
    "product.default.error.skuValueCannotContainKey": "SKU name และ SKU value ต้องไม่ซ้ำกัน",
    "product.default.error.skuKeyDuplicated": "SKU name ต้องไม่ซ้ำกัน",

    TOP_UP_AND_VOUCHER: "เติมเงิน / คูปอง",
    EVENT_TICKET: "งานอีเว้นท์",
    ONLINE_COURSE: "คอร์สออนไลน์",
    "Select digital content type": "เลือกรูปแบบของสินค้าดิจิทัล",
    STATIC: "Deeplink / ลิงก์ / ข้อความ",
    QR_CODE: "QR code",
    CODE_IMPORTED: "โค้ด - นำเข้าด้วยตนเอง CSV / XLSX",
    CODE_AUTO_GENERATED: "โค้ด - สร้างโดยระบบ",
    CODE_GENERATED_FROM_OTHER: "โค้ด - นำเข้าจากระบบอื่น",
    "Digital content output": "รูปแบบของสินค้าดิจิทัล",
    "Please select content type": "กรุณาเลือกรูปแบบของสินค้าดิจิทัล",
    "Please verify product data or Product SKU fields": "กรุณาตรวจสอบความถูกต้องของข้อมูลสินค้า",
    "productDimensionWidth.required": "กรุณาระบุความกว้างของพัสดุ",
    "productDimensionWidth.isMaximum": "ความกว้างของพัสดุต้องน้อยกว่า 10,000.00 เซนติเมตร",
    "productDimensionLength.required": "กรุณาระบุความยาวของพัสดุ",
    "productDimensionLength.isMaximum": "ความยาวของพัสดุต้องน้อยกว่า 10,000.00 เซนติเมตร",
    "productDimensionHeight.required": "กรุณาระบุความสูงของพัสดุ",
    "productDimensionHeight.isMaximum": "ความสูงต้องน้อยกว่า 10,000.00 เซนติเมตร",
    "productDimension.isMaximum": "ขนาดของพัสดุต้องน้อยกว่า 10,000.00 เซนติเมตร",
    "Please fill value": "กรุณากรอกข้อมูล",
    ProductDeleted: "ลบสินค้าสำเร็จ",
    "product.default.error.incorrectProduct": "ข้อมูลสินค้าไม่ถูกต้อง",
    "product.default.error.duplicateCFCode": "CF code ซ้ำกับสินค้าอื่น",
    "Product CF Code": "CF Code สินค้า",
    "Please put CF Code here": "กรุณาใส่ CF Code ที่นี่",
    notSupportProductCode:
      "โค้ดสินค้าต้องมีอย่างน้อย 3 ตัวอักษร แต่ไม่เกิน 50 ตัวอักษร สามารถใช้ตัวอักษร ภาษาไทย, อังกฤษ, หรือตัวเลข อักขระพิเศษอนุญาตเฉพาะ ( _ ) และ ( - ) และต้องไม่มีช่องว่างระหว่างโค้ด",
    notSupportProductCodeLength: "โค้ดสินค้าต้องมีอย่างน้อย 3 ตัวอักษร แต่ไม่เกิน 50 ตัวอักษร",
    notSupportCFCode:
      "CF code ต้องมีอย่างน้อย 3 ตัวอักษร ต้องขึ้นต้นด้วยตัวอักษรภาษาไทยหรืออังกฤษ สามารถใช้ ตัวอักษร, ตัวเลข, ( _ ) และ ( - ) และต้องไม่มีช่องว่างระหว่างโค้ด",
    SEARCH_PRODUCT: "ค้นหาสินค้า",
    PRODUCT_SELECTOR: "ตัวเลือกสินค้า",
    CLEAR_ALL: "ล้างทั้งหมด",
    SELECTED_PRODUCTS: "สินค้าที่เลือก",
    NO_FOUND_PRODUCTS: "ไม่พบสินค้า",
    "HighlightProduct.title": "สินค้าไฮไลท์",
    "HighlightProduct.title.tooltip":
      "ฟีเจอร์นี้มีไว้สำหรับการกำหนดสินค้าไฮไลท์ 10 ตัวแรกที่โชว์ผ่าน LINE Offical Account ด้วยตัวเอง",
    editHighlightProduct: "แก้ไขสินค้าไฮไลท์",
    noHighlightProduct: "ยังไม่มีรายการสินค้าไฮไลท์",
    displayHighlightProduct: "สินค้าไฮไลท์ที่แสดงในเมนู ",
    detectChangeCategory:
      "คุณต้องการจะย้ายเมนูของสินค้านี้ไปยัง ‘{{selectCategoryName}}’ หรือไม่? เนื่องจากสินค้าสินชิ้นนี้จะถูกลบออกจากการเป็นสินค้าไฮไลท์ในเมนู ‘{{prevCategoryName}}’ เช่นกัน",
    "product.cfCode.optional": "จำเป็นต้องใส่ ถ้าต้องการใช้การตอบกลับเฟซบุ๊กไลฟ์",
  },
};

export default products;
