import styled from "styled-components";
import Typography from "components/Typography";
import React from "react";

type ImageBubblePropsType = {
  borderRadius?: string | null;
  isLoading?: boolean;
};

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const EmptyMessage = styled.div`
  height: 16px;
  margin: 0;
`;

export const Message = styled.div`
  display: flex;
  padding-right: 16px;
  padding-left: 16px;
  flex-grow: 0;
  max-width: 75%;
  flex-basis: 75%;
  box-sizing: border-box;
`;

export const ChatBubbleWrapper = styled.div`
  flex: 1;
  overflow-x: scroll;
`;

export const TextMessage = styled((props) => <Typography {...props} />)`
  width: 100%;
  word-wrap: break-word;
`;

export const ImageBubble = styled.img<ImageBubblePropsType>`
  display: flex;
  max-width: 200px;
  max-height: 200px;
  min-width: ${({ isLoading }) => (isLoading ? "200px" : "0px")};
  min-height: ${({ isLoading }) => (isLoading ? "200px" : "0px")};
  visibility: ${({ isLoading }) => (isLoading ? "hidden" : "visible")};
  object-fit: contain;
  text-align: center;
  border-radius: ${({ borderRadius }) => borderRadius || "10px"};
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
`;
