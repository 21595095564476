import { useCallback, useState } from "react";
import { OptionType, ValueType } from "react-select";
import { SHIPPOP_INVOICE_SIZE } from "types/Shipping";

const useInvoiceSize = (t: Function) => {
  const DEFAULT_OPTION = {
    label: t("RECEIPT"),
    value: SHIPPOP_INVOICE_SIZE.RECEIPT,
  };
  const [invoiceSize, setInvoiceSize] = useState<ValueType<OptionType>>(DEFAULT_OPTION);
  const handleChangeInvoiceSize = useCallback((option: ValueType<OptionType>) => {
    setInvoiceSize(option);
  }, []);

  const resetInvoiceSize = useCallback(() => {
    setInvoiceSize(DEFAULT_OPTION);
  }, [DEFAULT_OPTION]);

  return {
    invoiceSize,
    resetInvoiceSize,
    handleChangeInvoiceSize,
  };
};

export default useInvoiceSize;
