import * as React from "react";

const SvgIcError = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.532 8.468a1.598 1.598 0 00-2.26 0L16.01 13.73l-5.262-5.262a1.598 1.598 0 00-2.26 2.26l5.262 5.262-5.262 5.262a1.598 1.598 0 102.26 2.26l5.262-5.262 5.262 5.262a1.598 1.598 0 002.26-2.26L18.27 15.99l5.262-5.262a1.598 1.598 0 000-2.26z"
    />
  </svg>
);

export default SvgIcError;
