import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { RadioBox } from "components/Radio";
import Button from "components/Button";
import Card from "components/Card";
import ErrorText from "components/ErrorText";
import Grid from "components/Grid";
import PageTitle from "components/PageTitle";
import StickyPanel from "components/StickyPanel";
import Switch from "components/Switch";
import TextField from "components/TextField";
import Typography from "components/Typography";

import { VAT_OPTIONS } from "constants/Vat";
import { ProjectIdType } from "types/Project";
import { VAT_OPTIONS_TYPE, VatOptionType, VatFormDataType, Language, Vat } from "types/Vat";

import validationSchema from "./validationSchema";
import { useVat, useUpdateVat } from "./hooks";

const VatContainer: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();

  const { register, errors, handleSubmit, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    vat,
    vatOption,
    isShowTaxInfo,
    isShowSaveButton,
    isShippingIncluded,
    selectedLanguage,
    handleSelectVatOption,
    handleToggleIsShowTaxInfo,
    handleToggleIsShippingIncluded,
    clearValue,
    showSaveButton,
    handleSelectLanguage,
  } = useVat({ projectId, setFormValue: setValue });

  const { handleUpdateVat, isUpdatingVat } = useUpdateVat({
    projectId,
    onCompleted: (updatedVat: Vat) => {
      clearValue();
      setValue("companyName", updatedVat.companyName);
      setValue("address", updatedVat.address);
      setValue("district", updatedVat.district);
      setValue("subDistrict", updatedVat.subDistrict);
      setValue("phoneNumber", updatedVat.phoneNumber);
      setValue("province", updatedVat.province);
      setValue("postcode", updatedVat.postcode);
    },
  });

  const handleSaveVatSetting = (vatFormData: VatFormDataType) => {
    const updatingVat = {
      ...vatFormData,
      option: vatOption as VAT_OPTIONS_TYPE,
      isShowTaxInfo,
      isShippingIncluded,
      lang: selectedLanguage,
    };
    if (vat) {
      handleUpdateVat(vat.id, updatingVat);
    }
  };

  useEffect(() => {
    register({ name: "option" });
    register({ name: "isShowTaxInfo" });
    register({ name: "lang" });

    register({ name: "address" });
    register({ name: "district" });
    register({ name: "subDistrict" });
    register({ name: "phoneNumber" });
    register({ name: "province" });
    register({ name: "postcode" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container className={isShowSaveButton ? "pb-5" : ""}>
      <Grid item container xs={12}>
        <PageTitle title={t("VAT Settings")} />
      </Grid>

      {vat && (
        <>
          <Card noShadow borderRadius={0} padding="32px" className="mt-1 mb-4 w-100">
            <Grid item className="pl-2">
              <Typography variant="title7">{t("Tax invoice language")}</Typography>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} sm={6} className="px-1 py-2">
                <RadioBox
                  title={t("TH")}
                  description={t("TaxInThai")}
                  isActive={selectedLanguage === Language.TH}
                  onClick={() => {
                    handleSelectLanguage(Language.TH);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="px-1 py-2">
                <RadioBox
                  title={t("EN")}
                  description={t("TaxInEnglish")}
                  isActive={selectedLanguage === Language.EN}
                  onClick={() => {
                    handleSelectLanguage(Language.EN);
                  }}
                />
              </Grid>
            </Grid>
          </Card>

          <Card noShadow borderRadius={0} padding="32px" className="mt-1 mb-4 w-100">
            <Grid item container xs={12}>
              {VAT_OPTIONS.map(({ title, value, description }: VatOptionType) => {
                return (
                  <Grid key={title} item xs={12} className="px-1 py-2">
                    <RadioBox
                      title={t(title)}
                      description={t(description || "")}
                      isActive={vatOption === value}
                      onClick={() => {
                        handleSelectVatOption(value);
                      }}
                    />
                  </Grid>
                );
              })}
              {vatOption !== VAT_OPTIONS_TYPE.NONE && (
                <Grid item container alignItems="center" xs={12} className="pt-3 mt-1">
                  <Grid item xs={2} sm="auto">
                    <Switch checked={isShippingIncluded} onChange={handleToggleIsShippingIncluded} />
                  </Grid>
                  <Grid item xs={10} sm="auto" className="pl-3">
                    <Typography variant="body3">{t("vat.label.isShippingIncluded")}</Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Card>
          {vatOption !== VAT_OPTIONS_TYPE.NONE && (
            <>
              <Card noShadow borderRadius={0} padding="32px" className="mb-4 w-100">
                <Grid container>
                  <Grid item container xs={12} className="pb-2">
                    <Grid item xs={12}>
                      <TextField label={t("VAT rate (%VAT)")} defaultValue={vat.rate} name="rate" fullWidth disabled />
                    </Grid>
                    {errors && errors.rate && (
                      <Grid item xs={12} className="m-1">
                        <ErrorText>{t(`${errors.rate.message}`)}</ErrorText>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item container alignItems="center" xs={12} className="pt-3">
                    <Grid item>
                      <Switch checked={isShowTaxInfo} onChange={handleToggleIsShowTaxInfo} />
                    </Grid>
                    <Grid item className="pl-3">
                      <Typography variant="body3">{t("Show Abbreviated tax invoice")}</Typography>
                    </Grid>
                  </Grid>
                  {isShowTaxInfo && (
                    <>
                      <Grid item container alignItems="center" xs={12} className="pt-3">
                        <Grid item xs={12}>
                          <Typography variant="body3" color="darkMed" className="my-1">
                            {t("Tax ID")}
                          </Typography>
                          <TextField
                            defaultValue={vat.taxId}
                            name="taxId"
                            onChange={showSaveButton}
                            fullWidth
                            validate={register}
                            variant="outlined"
                          />
                        </Grid>
                        {errors && errors.taxId && (
                          <Grid item xs={12} className="m-1">
                            <ErrorText>{t(`${errors.taxId.message}`)}</ErrorText>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item container alignItems="center" xs={12} className="pt-1">
                        <Grid item xs={12}>
                          <Typography variant="body3" color="darkMed" className="my-1">
                            {t("Company name")}
                          </Typography>
                          <TextField
                            defaultValue={vat.companyName}
                            name="companyName"
                            onChange={showSaveButton}
                            fullWidth
                            validate={register}
                            variant="outlined"
                          />
                        </Grid>
                        {errors && errors.companyName && (
                          <Grid item xs={12} className="m-1">
                            <ErrorText>{t(`${errors.companyName.message}`)}</ErrorText>
                          </Grid>
                        )}
                      </Grid>

                      <Grid item container alignItems="center" xs={12} className="pt-1">
                        <Grid item xs={12}>
                          <Typography variant="body3" color="darkMed" className="my-1">
                            {t("Address")}
                          </Typography>
                          <TextField
                            defaultValue={vat.address}
                            name="address"
                            onChange={showSaveButton}
                            fullWidth
                            validate={register}
                            variant="outlined"
                          />
                        </Grid>
                        {errors && errors.address && (
                          <Grid item xs={12} className="m-1">
                            <ErrorText>{t(`${errors.address.message}`)}</ErrorText>
                          </Grid>
                        )}
                      </Grid>

                      <Grid item container alignItems="center" xs={6} className="pt-1 pr-1">
                        <Grid item xs={12}>
                          <Typography variant="body3" color="darkMed" className="my-1">
                            {t("Sub-district")}
                          </Typography>
                          <TextField
                            defaultValue={vat.subDistrict}
                            name="subDistrict"
                            onChange={showSaveButton}
                            fullWidth
                            validate={register}
                            variant="outlined"
                          />
                        </Grid>
                        {errors && errors.subDistrict && (
                          <Grid item xs={12} className="m-1">
                            <ErrorText>{t(`${errors.subDistrict.message}`)}</ErrorText>
                          </Grid>
                        )}
                      </Grid>

                      <Grid item container alignItems="center" xs={6} className="pt-1 pl-1">
                        <Grid item xs={12}>
                          <Typography variant="body3" color="darkMed" className="my-1">
                            {t("District")}
                          </Typography>
                          <TextField
                            defaultValue={vat.district}
                            name="district"
                            onChange={showSaveButton}
                            fullWidth
                            validate={register}
                            variant="outlined"
                          />
                        </Grid>
                        {errors && errors.district && (
                          <Grid item xs={12} className="m-1">
                            <ErrorText>{t(`${errors.district.message}`)}</ErrorText>
                          </Grid>
                        )}
                      </Grid>

                      <Grid item container alignItems="center" xs={6} className="pt-1 pr-1">
                        <Grid item xs={12}>
                          <Typography variant="body3" color="darkMed" className="my-1">
                            {t("Province")}
                          </Typography>
                          <TextField
                            defaultValue={vat.province}
                            name="province"
                            onChange={showSaveButton}
                            fullWidth
                            validate={register}
                            variant="outlined"
                          />
                        </Grid>
                        {errors && errors.province && (
                          <Grid item xs={12} className="m-1">
                            <ErrorText>{t(`${errors.province.message}`)}</ErrorText>
                          </Grid>
                        )}
                      </Grid>

                      <Grid item container alignItems="center" xs={6} className="pt-1 pl-1">
                        <Grid item xs={12}>
                          <Typography variant="body3" color="darkMed" className="my-1">
                            {t("Postcode")}
                          </Typography>
                          <TextField
                            defaultValue={vat.postcode}
                            name="postcode"
                            onChange={showSaveButton}
                            fullWidth
                            validate={register}
                            variant="outlined"
                          />
                        </Grid>
                        {errors && errors.postcode && (
                          <Grid item xs={12} className="m-1">
                            <ErrorText>{t(`${errors.postcode.message}`)}</ErrorText>
                          </Grid>
                        )}
                      </Grid>

                      <Grid item container alignItems="center" xs={12} className="pt-1">
                        <Grid item xs={12}>
                          <Typography variant="body3" color="darkMed" className="my-1">
                            {t("phoneNumber")}
                          </Typography>
                          <TextField
                            defaultValue={vat.phoneNumber}
                            name="phoneNumber"
                            onChange={showSaveButton}
                            fullWidth
                            validate={register}
                            variant="outlined"
                          />
                        </Grid>
                        {errors && errors.phoneNumber && (
                          <Grid item xs={12} className="m-1">
                            <ErrorText>{t(`${errors.phoneNumber.message}`)}</ErrorText>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Card>
            </>
          )}
        </>
      )}
      {isShowSaveButton && (
        <StickyPanel>
          <Grid item xs={12} className="m-3">
            <Button fullWidth onClick={handleSubmit(handleSaveVatSetting)} disabled={isUpdatingVat}>
              {t("Save")}
            </Button>
          </Grid>
        </StickyPanel>
      )}
    </Grid>
  );
};

export default VatContainer;
