import React, { FC, useState, RefObject, useEffect } from "react";
import { KeyboardTimePicker as MUIKeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import styled from "styled-components";
import clsx from "clsx";
import { TIME_FORMAT } from "constants/DateTimeFormat";
import { Theme } from "types/Theme";

type TimePickerPropsType = {
  value: string; // HH:mm
  onChange?: (time: string) => void;
  disabled?: boolean;
  inputRef?: ((instance: HTMLInputElement) => void) | RefObject<HTMLInputElement> | null;
  isError?: boolean;
  name: string;
  theme?: Theme;
  sizeAuto?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const KeyboardTimePicker = styled(({ sizeAuto, ...rest }) => <MUIKeyboardTimePicker {...rest} />).attrs(
  (props: TimePickerPropsType) => {
    const { theme, sizeAuto } = props;
    return { theme, sizeAuto };
  },
)`
  border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  border-radius: 8px;
  padding: ${({ sizeAuto }) => (sizeAuto ? "8px" : "10px")};
  height: ${({ sizeAuto }) => (sizeAuto ? "auto" : "35px")};
  width: ${({ sizeAuto }) => (sizeAuto ? "auto" : "70px")};
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  input.Mui-focused,
  div.MuiInput-underline:hover:before,
  div.MuiInput-underline:before,
  div.MuiInput-underline:after {
    border-bottom: none;
  }
  background-color: ${({ theme }) => theme.COLORS.White};

  &.disabled {
    background-color: ${({ theme }) => theme.COLORS.Light};

    & > div > input {
      color: ${({ theme }) => theme.COLORS.LightBlue};
    }
  }

  &.error > div > input {
    color: ${({ theme }) => theme.COLORS.Error};
  }

  &.error {
    color: ${({ theme }) => theme.COLORS.Error};
  }

  &.disabled:focus-within {
    border-color: ${({ theme }) => theme.COLORS.LightBlue};
  }

  &:active,
  &:focus-within {
    border-color: ${({ theme }) => theme.COLORS.Primary};
  }

  .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
    position: absolute;
  }

  .MuiInputBase-input {
    display: inline-block;
    font: normal normal normal 14px/20px "Roboto", "Helvetica", "Arial", sans-serif;
    text-align: left;
    padding-left: 4px;
    line-height: 14px;
    color: ${({ theme }) => theme.COLORS.DarkGray};
    &.disabled {
      color: ${({ theme }) => theme.COLORS.LightBlue};
    }
  }

  .MuiSvgIcon-root {
    font-size: 16px;
    color: ${({ theme }) => theme.COLORS.Primary};
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: ${({ sizeAuto }) => (sizeAuto ? "8px" : "5px")};
    width: ${({ sizeAuto }) => (sizeAuto ? "auto" : "54px")};
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    padding: ${({ sizeAuto }) => (sizeAuto ? "8px" : "3px")};
    width: ${({ sizeAuto }) => (sizeAuto ? "auto" : "50px")};
  }
`;

const TimePicker: FC<TimePickerPropsType> = ({
  value,
  onChange = () => {},
  disabled = false,
  inputRef,
  isError = false,
  name,
  sizeAuto = false,
}) => {
  const [selectedDate, setSelectedDate] = useState(moment(value, TIME_FORMAT));
  const [isOpen, setIsOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDateChange = (_date: any, textValue?: string | null) => {
    if (textValue) {
      setSelectedDate(moment(textValue, TIME_FORMAT));
      onChange(textValue);
    }
  };

  const handleClick = () => {
    if (!disabled) {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (value) {
      setSelectedDate(moment(value, TIME_FORMAT));
    }
  }, [value]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardTimePicker
        size="small"
        name={name}
        ampm={false}
        sizeAuto={sizeAuto}
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change time",
        }}
        inputRef={inputRef}
        className={clsx({ disabled, error: isError })}
        open={isOpen}
        keyboardIcon={<> </>} // empty icon
        onClick={handleClick}
        onClose={handleClose}
      />
    </MuiPickersUtilsProvider>
  );
};

export default TimePicker;
