import styled from "styled-components";

export const ScrollContainer = styled.div`
  overflow: auto;
  ::-webkit-scrollbar {
    height: 6px;
    display: initial !important;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.COLORS.DarkLight};
  }

  ::-webkit-scrollbar:vertical {
    display: none;
  }
`;

export const DropZone = styled.div`
  display: flex;
  /*
    Needed to avoid growth in list due to lifting the first item
    Caused by display: inline-flex strangeness
  */
  align-items: start;
  /* stop the list collapsing when empty */
  min-width: 600px;
  /* stop the list collapsing when it has no items */
  min-height: 60px;
  width: fit-content;
`;

export const ProductListWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;
