import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

import Button from "components/Button";
import Card from "components/Card";
import Grid from "components/Grid";
import IcAppleBorderLogo from "components/SvgIcons/IcAppleBorderLogo";
import IcFacebookBorderLogo from "components/SvgIcons/IcFacebookBorderLogo";
import IcGoogleBorderLogo from "components/SvgIcons/IcGoogleBorderLogo";
import CircularProgress from "components/CircularProgress";

import useConnectAccount from "utils/hooks/useConnectAccount";

import { COLORS } from "constants/Colors";
import { AuthProviderLinkStatus, ConnectSocialAccountColor } from "constants/Auth";
import { UserProfileDetailType } from "types/User";
import useAuthentication from "utils/hooks/useAuthentication";
import { AuthProvider, AuthSuccessProps } from "types/Auth";
import Typography from "components/Typography";
import { notifyError, notifySuccess } from "utils/notify";

import { GridButtonWrapper } from "../ProfileSettingForm/styled";
import mapErrorMessage from "./mapErrorMessage";

type SocialAccountSettingPropsType = {
  user: UserProfileDetailType | undefined;
};

const SocialAccountSetting: FC<SocialAccountSettingPropsType> = (props) => {
  const { user } = props;

  const { t } = useTranslation();
  const {
    connectSocialAccount,
    disconnectAccountSocial,
    isConnectAccountLoading,
    isDisconnectAccountLoading,
  } = useConnectAccount();

  const isConnectedWithApple = Boolean(user?.isConnectedWithApple);
  const isConnectedWithFacebook = Boolean(user?.isConnectedWithFacebook);
  const isConnectedWithGoogle = Boolean(user?.isConnectedWithGoogle);
  const isDeepleAuthProvider = user?.authProvider !== AuthProvider.DEEPLE;
  const primaryProvider = user?.primaryProvider || AuthProvider.DEEPLE;

  const isAppleButtonDisabled = primaryProvider === AuthProvider.APPLE && isDeepleAuthProvider;
  const isFacebookButtonDisabled = primaryProvider === AuthProvider.FACEBOOK && isDeepleAuthProvider;
  const isGoogleButtonDisabled = primaryProvider === AuthProvider.GOOGLE && isDeepleAuthProvider;

  const selectConnectSocialAccountColor = (isLinkColor: boolean): string => {
    return isLinkColor ? ConnectSocialAccountColor.SECONDARY : ConnectSocialAccountColor.PRIMARY;
  };

  const selectLinkTextByIsConnectProvider = (isConnectProvider: boolean): string => {
    return isConnectProvider ? AuthProviderLinkStatus.UNLINK : AuthProviderLinkStatus.LINK;
  };

  const onConnectAccountSuccess = useCallback(
    async ({ token, authProvider }: AuthSuccessProps): Promise<void> => {
      try {
        await connectSocialAccount(token, authProvider);
        notifySuccess(t("CONNECTED_SUCCESS"));
      } catch (err) {
        const errorMsg = mapErrorMessage(get(err, "message"));

        notifyError(t(errorMsg));
      }
    },
    [connectSocialAccount, t],
  );

  const disconnectAccountSuccess = useCallback(
    async (provider: AuthProvider): Promise<void> => {
      try {
        await disconnectAccountSocial(provider);
        notifySuccess(t("DISCONNECTED_SUCCESS"));
      } catch (err) {
        const errorMsg = mapErrorMessage(get(err, "message"));

        notifyError(t(errorMsg));
      }
    },
    [disconnectAccountSocial, t],
  );

  const { handleAppleSignIn, handleFacebookLogin, handleGoogleLogin } = useAuthentication(onConnectAccountSuccess);

  const onConnectGoogleAccount = isConnectedWithGoogle
    ? () => disconnectAccountSuccess(AuthProvider.GOOGLE)
    : handleGoogleLogin;

  const onConnectFacebookAccount = isConnectedWithFacebook
    ? () => disconnectAccountSuccess(AuthProvider.FACEBOOK)
    : handleFacebookLogin;

  const onConnectAppleAccount = isConnectedWithApple
    ? () => disconnectAccountSuccess(AuthProvider.APPLE)
    : handleAppleSignIn;

  return (
    <Card className="mt-4">
      {(isConnectAccountLoading || isDisconnectAccountLoading) && (
        <Grid container justify="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      {!isConnectAccountLoading && !isDisconnectAccountLoading && (
        <>
          <Grid container className="my-3" style={{ minHeight: 60 }}>
            <Grid className="mx-3">
              <IcFacebookBorderLogo fontSize={48} color={COLORS.Facebook} />
            </Grid>
            <Grid item xs md sm className="ml-2 mt-1">
              <Typography variant="title7">Facebook</Typography>
            </Grid>
            <Grid
              item
              xs
              md={8}
              sm={8}
              justify="flex-end"
              alignItems="center"
              alignContent="center"
              className="d-flex pr-3 pb-3 wrap-xs-nowrap"
            >
              {!isFacebookButtonDisabled && (
                <GridButtonWrapper>
                  <Button
                    minWidth={130}
                    onClick={onConnectFacebookAccount}
                    color={selectConnectSocialAccountColor(isConnectedWithFacebook)}
                  >
                    {t(selectLinkTextByIsConnectProvider(isConnectedWithFacebook))}
                  </Button>
                </GridButtonWrapper>
              )}
            </Grid>
          </Grid>
          <Grid container className="mb-3" style={{ minHeight: 60 }}>
            <Grid className="mx-3">
              <IcGoogleBorderLogo fontSize={48} />
            </Grid>
            <Grid item xs md sm className="ml-2 mt-1">
              <Typography variant="title7">Google</Typography>
            </Grid>
            <Grid
              item
              xs
              md={8}
              sm={8}
              justify="flex-end"
              alignItems="center"
              alignContent="center"
              className="d-flex pr-3 pb-3 wrap-xs-nowrap"
            >
              {!isGoogleButtonDisabled && (
                <GridButtonWrapper>
                  <Button
                    minWidth={130}
                    onClick={onConnectGoogleAccount}
                    color={selectConnectSocialAccountColor(isConnectedWithGoogle)}
                  >
                    {t(selectLinkTextByIsConnectProvider(isConnectedWithGoogle))}
                  </Button>
                </GridButtonWrapper>
              )}
            </Grid>
          </Grid>
          <Grid container style={{ minHeight: 60 }}>
            <Grid className="mx-3">
              <IcAppleBorderLogo fontSize={48} />
            </Grid>
            <Grid item xs md sm className="ml-2 mt-1">
              <Typography variant="title7">Apple ID</Typography>
            </Grid>
            <Grid
              item
              xs
              md={8}
              sm={8}
              justify="flex-end"
              alignItems="center"
              alignContent="center"
              className="d-flex px-3 pb-3 wrap-xs-nowrap"
            >
              {!isAppleButtonDisabled && (
                <GridButtonWrapper>
                  <Button
                    minWidth={130}
                    onClick={onConnectAppleAccount}
                    color={selectConnectSocialAccountColor(isConnectedWithApple)}
                  >
                    {t(selectLinkTextByIsConnectProvider(isConnectedWithApple))}
                  </Button>
                </GridButtonWrapper>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Card>
  );
};

export default SocialAccountSetting;
