import { makeStyles } from "@material-ui/core/styles";
import React, { ChangeEvent, memo, forwardRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import TextField from "components/TextField";
import { SvgIcon } from "components/Icon";
import { IcSearch } from "components/SvgIcons";
import Colors from "constants/Colors";

type SearchPropsType = {
  defaultValue: string;
  disabled?: boolean;
  height?: string;
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  variant?: "standard" | "filled" | "outlined";
  InputProps?: Partial<{ startAdornment: JSX.Element; style: Record<string, string | number> }>;
};

const SearchWrapper = styled.div<{ height?: string }>`
  height: ${({ height }) => height || "48px"};
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles({
  adornedStart: {
    paddingLeft: 4,
    overflow: "hidden",
  },
  inputAdornedStart: {
    padding: "10px 10px 10px 0px !important",
  },
});

const Search = forwardRef<HTMLInputElement | HTMLTextAreaElement, SearchPropsType>((props, ref) => {
  const {
    defaultValue,
    disabled,
    onChange,
    placeholder,
    className,
    variant = "standard",
    InputProps,
    height = "48px",
    value,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const style =
    variant === "outlined"
      ? {
          backgroundColor: disabled ? Colors.Light : Colors.White,
          height,
          minHeight: height,
        }
      : { height, minHeight: height };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <SearchWrapper height={height} className={className}>
      <TextField
        variant={variant}
        placeholder={placeholder || t("Search")}
        defaultValue={defaultValue}
        value={value}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: InputProps?.startAdornment || (
            <SvgIcon className="mx-2" component={IcSearch} fontSize="small" htmlColor="#737d94" />
          ),
          style: InputProps?.style || style,
          // Can not defined classes type because we can not lock TextField to outlined type
          // just only outline type that has adornedStart key
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          classes: variant === "outlined" && (classes as any),
        }}
        fullWidth
        disabled={disabled}
        inputRef={ref}
      />
    </SearchWrapper>
  );
});

export default memo(Search);
