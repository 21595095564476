import styled from "styled-components";
import Grid from "components/Grid";
import Card from "components/Card";

export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.COLORS.Error};
  width: 16px;
  height: 16px;
`;

export const GridIcon = styled(Grid)`
  flex: 0;
`;

export const GridContent = styled(Grid)`
  flex: 1;
`;

export const GridDetail = styled(Grid)`
  display: flex;
  align-item: center;
  flex: 1;

  > :first-child {
    flex-grow: 1;
  }
`;

export const StatusBoxCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.COLORS.White};
  background-color: ${({ theme }) => theme.COLORS.White};
  padding: 12px 24px;
  flex: 0;
`;
