import { ErrorRedeemCode } from "types/DigitalContent";

const NETWORK_ERROR = "Network error: Failed to fetch";

const mapErrorMessage = (errMessage: string) => {
  switch (errMessage) {
    case NETWORK_ERROR:
      return "Network error please try again later";
    case ErrorRedeemCode.CODE_ALREADY_REDEEMED:
      return "contentManagement.default.error.alreadyRedeemed";
    case ErrorRedeemCode.NOT_FOUND:
      return "contentManagement.default.error.notFound";
    case ErrorRedeemCode.CODE_NOT_YET_SOLD:
      return "contentManagement.default.error.codeNotSold";
    case ErrorRedeemCode.CODE_EXPIRED:
      return "contentManagement.default.error.codeExpired";
    case ErrorRedeemCode.CODE_NOT_YET_AVAILABLE:
      return "contentManagement.default.error.codeNotYetAvailable";
    default:
      return "Please try again later";
  }
};

export default mapErrorMessage;
