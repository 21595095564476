import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { IcInfo, IcShow } from "components/SvgIcons";
import useIsDesktop from "utils/hooks/useIsDesktop";

import { DeeplePayLearnMoreLink, DeeplePayWarning, DetailContent, ExampleLink } from "../styled";

export interface DeeplePayDetailProps {
  onClickExampleLink: () => void;
  onClickLearnMoreLink: () => void;
}

const DeeplePayDetail: FC<DeeplePayDetailProps> = ({ onClickExampleLink, onClickLearnMoreLink }) => {
  // Hooks
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  // Event handlers
  const handleClickExampleLink = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onClickExampleLink();
  };

  const handleClickLearnMoreLink = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onClickLearnMoreLink();
  };

  return (
    <div className="pt-2 w-100">
      <div className="d-flex justify-content-between align-items-center mt-3 mb-4">
        <div className="d-inline-flex align-items-center">
          <img
            src="/assets/logo/deeple-logo-black.png"
            className={isDesktop ? "d-block mr-3" : "d-none"}
            height="22"
            alt="Logo"
          />
          <img src="/assets/images/payment-channels/qr-payment.png" className="mr-2" height="18" alt="QR Payment" />
          <img src="/assets/images/payment-channels/mastercard.png" className="mr-2" height="18" alt="Mastercard" />
          <img src="/assets/images/payment-channels/visa.png" className="mr-2" height="18" alt="Visa" />
          <img src="/assets/images/payment-channels/bank-scb.png" className="mr-2" height="18" alt="SCB Bank" />
          <img src="/assets/images/payment-channels/bank-kplus.png" className="mr-2" height="18" alt="KPlus Bank" />
          <img src="/assets/images/payment-channels/bank-kma.png" className="mr-2" height="18" alt="KMA Bank" />
          <img src="/assets/images/payment-channels/true-money.png" height="18" alt="True money" />
        </div>
        <ExampleLink onClick={handleClickExampleLink}>
          <IcShow className="mr-1" />
          <span>{t("deeplePay.details.seeExample")}</span>
        </ExampleLink>
      </div>
      <div>
        <DetailContent>
          {t("deeplePay.details.description")} {t("deeplePay.details.description2")}{" "}
          <DeeplePayLearnMoreLink onClick={handleClickLearnMoreLink}>
            {t("deeplePay.details.learnMore")}
          </DeeplePayLearnMoreLink>
        </DetailContent>
        <DeeplePayWarning>
          <IcInfo />
          {t("deeplePay.details.info")}
        </DeeplePayWarning>
      </div>
    </div>
  );
};

export default DeeplePayDetail;
