import * as React from "react";

const SvgIcArrowUp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 7.16497L5.17732 9.98766C4.90799 10.257 4.47132 10.257 4.202 9.98766C3.93267 9.71833 3.93267 9.28167 4.202 9.01234L7.51234 5.702C7.78167 5.43267 8.21833 5.43267 8.48766 5.702L11.798 9.01234C12.0673 9.28167 12.0673 9.71833 11.798 9.98766C11.5287 10.257 11.092 10.257 10.8227 9.98766L8 7.16497Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgIcArrowUp;
