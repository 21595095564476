import { useQuery } from "@apollo/client";
import { POST } from "graphql/facebookCrawler/query";

export default (projectId: string, postId: string) => {
  const { data: postPreviewData, loading: isPostPreviewDataLoading } = useQuery(POST, {
    skip: !projectId || !postId,
    variables: {
      projectId,
      postId,
    },
    fetchPolicy: "network-only",
  });

  return { postPreviewData, isPostPreviewDataLoading };
};
