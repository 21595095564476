import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Grid from "components/Grid";
import Typography from "components/Typography";
import Divider from "components/Divider";

import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 32px 0px 20px 0px;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(20, 25, 49, 0.06);
  border: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
  background-color: ${({ theme }) => theme.COLORS.LightWhite};
`;

type LayoutPropsType = {
  currentNumber?: number;
  limit?: number;
};

const Layout: FC<LayoutPropsType> = ({ children, currentNumber = 0, limit = 0 }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Grid container className="px-3">
        <Grid item xs={10}>
          <Typography variant="title7" color="dark">
            {t("facebookAutoEngagement.messageReply")}:
          </Typography>
        </Grid>
        <Grid container item xs={2} justify="flex-end" alignItems="center">
          <Grid item>
            {currentNumber} / {limit}
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid container className="px-3">
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default Layout;
