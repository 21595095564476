import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Typography from "components/Typography";

import { IcInfo } from "components/SvgIcons";
import Tooltip from "components/Tooltip";
import COLORS from "constants/Colors";
import { EntityType, PaymentSummaryItemType, PaymentSummaryTypes } from "types/Billing";
import { convertPriceFormat, convertStringWithCommasToNumber } from "utils/common";
import { SubscriptionType } from "types/PlanUsage";
import { SummaryWrapper, SummaryWrapperItem } from "./styled";

type SummaryListPropsType = {
  type: PaymentSummaryTypes;
  itemsSummary: PaymentSummaryItemType[];
  startPackagePeriod: string;
  endPackagePeriod: string;
};

const SummaryList: FC<SummaryListPropsType> = (props) => {
  const { t } = useTranslation();
  const { type, itemsSummary, startPackagePeriod, endPackagePeriod } = props;

  return (
    <SummaryWrapper className="pt-3 pl-3 d-flex">
      <Typography variant="title10" color="darkLight">
        {t(`planBilling.summary.${type}.label.title`)} ({itemsSummary.length})
      </Typography>
      <div className="pt-3 d-flex">
        {itemsSummary.map((itemSummary: PaymentSummaryItemType) => {
          const { name, price, amount, totalPrice, entityType, subscriptionType, imageSearchQuota } = itemSummary;
          const subscriptionTypeLabel =
            subscriptionType === SubscriptionType.ANNUALLY
              ? t("planUsage.subscriptionType.annually")
              : t("planUsage.subscriptionType.monthly");

          const itemLabelTranslationKey = `planBilling.summary.${type}.label.${entityType.toLowerCase()}`;
          const itemUnitTranslationKey = `planBilling.summary.${type}.unit.${entityType.toLowerCase()}`;

          let label = `${t(itemLabelTranslationKey, {
            count: amount,
          })}`;

          if (entityType === EntityType.IMAGE_SEARCH) {
            label = `${t(itemLabelTranslationKey, {
              count: imageSearchQuota || convertStringWithCommasToNumber(name.split(" ")[0]),
            })}`;
          }

          const pricePerItemLabel = amount > 1 ? `${convertPriceFormat(price)} / ${t(itemUnitTranslationKey)}` : "";

          return (
            <SummaryWrapperItem key={name}>
              <Typography variant="body3" color="darkGray">
                {label}{" "}
                {totalPrice > 0 && (
                  <Tooltip
                    title={
                      <div>
                        <Typography variant="body5" color="white">
                          Subscription - {subscriptionTypeLabel}
                        </Typography>
                        <Typography variant="body5" color="white">
                          {startPackagePeriod} - {endPackagePeriod}
                        </Typography>
                      </div>
                    }
                    placement="top-start"
                    arrow
                  >
                    <span>
                      <IcInfo color={COLORS.DarkMed} />
                    </span>
                  </Tooltip>
                )}
              </Typography>

              <Typography variant="title3" color="darkGray" className="mt-2">
                {t("THB")}
                {convertPriceFormat(totalPrice)}
              </Typography>

              <Typography variant="body4" color="darkLight" className="mt-3">
                {pricePerItemLabel}
              </Typography>
            </SummaryWrapperItem>
          );
        })}
      </div>
    </SummaryWrapper>
  );
};

SummaryList.displayName = "SummaryList";

export default SummaryList;
