import { IcCreditCardAmex, IcCreditCardJcb, IcCreditCardMaster, IcCreditCardVisa } from "components/SvgIcons";
import { CreditCardBrand, InvoiceDiscountType } from "types/Billing";

export const CreditCardType: Record<string, CreditCardBrand> = {
  visa: "VISA",
  "american-express": "AMERICAN_EXPRESS",
  mastercard: "MASTERCARD",
  jcb: "JCB",
};

export const CreditCardIcon: { [key: string]: unknown } = {
  AMERICAN_EXPRESS: IcCreditCardAmex,
  JCB: IcCreditCardJcb,
  MASTERCARD: IcCreditCardMaster,
  VISA: IcCreditCardVisa,
};

export const DiscountWording = {
  [InvoiceDiscountType.DEEPLE_CREDIT]: "billing.invoiceHistory.paymentMethod.wallet",
  [InvoiceDiscountType.UPGRADE_PACKAGE_COMPENSATION]: "billing.invoiceHistory.paymentMethod.upgradePackageCompensation",
  [InvoiceDiscountType.PROMOTION_CODE]: "billing.invoiceHistory.paymentMethod.promotion",
};

export const CreditCard: { [key: string]: string } = {
  VISA: "Visa",
  MASTERCARD: "Mastercard",
  AMERICAN_EXPRESS: "American Express",
  JCB: "JCB",
};
