import styled from "styled-components";
import Grid from "components/Grid";

type CardWrapperPropsType = {
  isDesktop: boolean;
};

export const CardWrapper = styled(Grid)<CardWrapperPropsType>`
  width: ${({ isDesktop }) => (isDesktop ? "362px" : "100%")};
  max-width: ${({ isDesktop }) => (isDesktop ? "362px" : "100%")};
`;
