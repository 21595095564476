import React, { FC, useState, useEffect, useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ValueType, OptionType } from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import { notifyError } from "utils/notify";

import Button from "components/Button";
import CircularProgress from "components/CircularProgress";
import { SHIPPOP_CARRIER_NAME } from "constants/Shippop";
import { ALL_CARRIERS } from "constants/Shipping";
import {
  ProvinceSetting,
  ShippingMethod,
  CALCULATE,
  SettingsPostalCodeType,
  CARRIERS,
  CALCULATE_OPTIONS,
  ShippopCredential,
  ShippopStoreSetting,
  SHIPPOP_CALCULATE,
  METHOD,
} from "types/Shipping";

import { useLazyQuery } from "@apollo/client";
import { VALIDATE_CREDENTIAL_FOR_SHIPPOP } from "graphql/shipping/query";
import Grid from "components/Grid";
import CarrierCard from "./CarrierCard";
import FlatRate from "./FlatRate";
import StoreManagementFee from "./StoreManagementFee";
import CredentialSetting from "./CredentialSetting";
import ShippingCalculateTypeCard from "./ShippingCalculateTypeCard";
import validationSchema from "../ValidationSchema";
import { PostalCodeBasedShipping } from "../PostalCodeBasedShipping";
import usePayment from "../hooks/usePayments";
import SenderInformation from "../Shippop/SenderInformation";

type EditShippingMethodFormProps = {
  focusedShippingData: ShippingMethod;
  errorFields?: string[];
  projectId: string;
  onSubmitForm: (editedData: ShippingMethod, editedShippopData: ShippopStoreSetting) => void;
  shippopData: ShippopStoreSetting;
};

export type EditShippingMethodFormData = {
  description: string;
  fixedPrice: number;
  carrierName: string;
  minDuration: number;
  maxDuration: number;
  provinceSettings: ProvinceSetting[];
  calculateType: CALCULATE;
  disabledDuration: boolean;
  isReceiveOrderOutsideDeliveryTime: boolean;
  storeManagementFee: number;
  isAllowedOnlyCOD: boolean;
};

const EditShippingMethodForm: FC<EditShippingMethodFormProps> = (props) => {
  const { focusedShippingData, errorFields, projectId, onSubmitForm, shippopData } = props;

  const {
    method: shippingMethodFromProps,
    description,
    calculateType,
    fixedPrice: fixedPriceFromProps,
    minDuration: minDurationFromProps,
    maxDuration: maxDurationFromProps,
    settings: settingsFromProps, // deprecate soon
    setting: settingFromProps,
    disabledDuration: disabledDurationFromProps,
    isReceiveOrderOutsideDeliveryTime: isReceiveOrderOutsideDeliveryTimeFormProps = false,
    isAllowedOnlyCOD: isAllowedOnlyCODFromProps,
  } = focusedShippingData;

  const { isCODPaymentActive, shippingMethodApplyCOD, isLoadingPayments } = usePayment(projectId);
  const isEnableAllowOnlyCODSwitch =
    isCODPaymentActive && (shippingMethodApplyCOD === METHOD.ALL || shippingMethodApplyCOD === shippingMethodFromProps);

  const initShippopInformation = {
    email: "",
    senderInformation: [],
  };

  const { t } = useTranslation();
  const [isShowSaveButton, setIsShowSaveButton] = useState(false);
  const [isOpenAddressModal, setIsOpenAddressModal] = useState(false);

  const showSaveButton = useCallback(() => {
    if (!isShowSaveButton) {
      setIsShowSaveButton(true);
    }
  }, [isShowSaveButton]);

  const [postalCodesSettings, setPostalCodesSettings] = useState<SettingsPostalCodeType[]>(
    settingFromProps?.provinceSettings || settingsFromProps || [],
  );
  const [isReceiveOrderOutsideDeliveryTime, setIsReceiveOrderOutsideDeliveryTime] = useState(
    isReceiveOrderOutsideDeliveryTimeFormProps,
  );
  const [shippopInformation, setShippopInformation] = useState<ShippopStoreSetting>(
    shippopData || initShippopInformation,
  );

  const [validateCredential, { loading: validateLoading }] = useLazyQuery(VALIDATE_CREDENTIAL_FOR_SHIPPOP, {
    fetchPolicy: "cache-and-network",
    onError: () => {
      const newShippopInformation = {
        email: shippopInformation?.email,
        senderInformation: shippopInformation?.senderInformation,
      };
      setShippopInformation(newShippopInformation);
      setIsValidCredential(false);
      setValue("shippopSenderInformation", newShippopInformation);
      notifyError(t("shipping.shippop.api.key.invalid"));
    },
    onCompleted: () => {
      setIsValidCredential(true);
      setValue("shippopSenderInformation", shippopInformation, { shouldDirty: true });
    },
  });

  const [isValidCredential, setIsValidCredential] = useState(!!shippopData?.credential?.apiKey || false);

  const [shippopCalculateType, setShippopCalculateType] = useState(
    settingFromProps?.shippopSetting?.type || SHIPPOP_CALCULATE.SHIPPOP_BASE_PRICE,
  );

  const method = useForm<EditShippingMethodFormData>({
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
    defaultValues: {
      fixedPrice: fixedPriceFromProps,
      calculateType,
      description,
      carrierName: description,
      minDuration: minDurationFromProps || 1,
      maxDuration: maxDurationFromProps || 1,
      provinceSettings: settingsFromProps,
      disabledDuration: !disabledDurationFromProps,
      storeManagementFee: settingFromProps?.shippopSetting?.managementFee || 0,
    },
  });

  const { register, errors, handleSubmit, setValue, watch, control, formState, getValues } = method;

  useEffect(() => {
    register({ name: "settings" });
    register({ name: "minDuration" });
    register({ name: "maxDuration" });
    register({ name: "calculateType" });
    register({ name: "description" });
    register({ name: "fixedPrice" });
    register({ name: "storeManagementFee" });
    register({ name: "shippopSenderInformation" });
    register({ name: "shippopCalculateType" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const watchFields = watch([
    "carrierName",
    "calculateType",
    "minDuration",
    "maxDuration",
    "storeManagementFee",
    "disabledDuration",
  ]);

  const onSubmit = (data: EditShippingMethodFormData) => {
    const isSelectShippopCalculateType = data.calculateType === CALCULATE.SHIPPOP;
    let newShippopData = shippopData;
    if (isSelectShippopCalculateType) {
      if ((shippopInformation?.senderInformation || []).length === 0 || (shippopInformation?.email || "") === "") {
        notifyError(t("shipping.shippop.error.senderInformationRequired"));
        return;
      }
      newShippopData = shippopInformation;
    }

    const newFocusedShippingData: ShippingMethod = {
      ...focusedShippingData,
      fixedPrice: data.fixedPrice,
      description: data.description,
      calculateType: data.calculateType as CALCULATE,
      minDuration: data.minDuration,
      maxDuration: data.maxDuration,
      disabledDuration: !data.disabledDuration,
      isAllowedOnlyCOD: data.isAllowedOnlyCOD,
      // settings: postalCodesSettings || [], // settings field need to deprecate soon
      setting: isSelectShippopCalculateType
        ? {
            shippopSetting: {
              type: shippopCalculateType,
              managementFee: data.storeManagementFee,
            },
            provinceSettings: postalCodesSettings,
          }
        : {
            ...settingFromProps,
            provinceSettings: postalCodesSettings,
          },
      isReceiveOrderOutsideDeliveryTime: data.isReceiveOrderOutsideDeliveryTime,
    };

    onSubmitForm(newFocusedShippingData, newShippopData);
  };

  const handleValidateForm = handleSubmit(onSubmit);

  const handlePostalCodesSettingsChange = (data: SettingsPostalCodeType[]) => {
    setPostalCodesSettings(data);
    setValue("provinceSettings", data, { shouldDirty: true });
    showSaveButton();
  };

  const handleChangeShippopCalculateType = (newShippopCalculateType: SHIPPOP_CALCULATE) => {
    setShippopCalculateType(newShippopCalculateType);
    setValue("shippopCalculateType", newShippopCalculateType, { shouldDirty: true });
  };

  const handleChangeIsDisabledDuration = useCallback(
    (value: boolean) => {
      setValue("disabledDuration", value, { shouldDirty: true });
    },
    [setValue],
  );

  const handleChangeMinDuration = useCallback(
    (value: number) => {
      if (value > watchFields.maxDuration) {
        setValue("minDuration", value, { shouldDirty: true });
        setValue("maxDuration", value, { shouldDirty: true });
      } else {
        setValue("minDuration", value, { shouldDirty: true });
      }
    },
    [setValue, watchFields.maxDuration],
  );

  const handleChangeMaxDuration = useCallback(
    (value: number) => {
      if (value < watchFields.minDuration) {
        setValue("maxDuration", value, { shouldDirty: true });
        setValue("minDuration", value, { shouldDirty: true });
      } else {
        setValue("maxDuration", value, { shouldDirty: true });
      }
    },
    [setValue, watchFields.minDuration],
  );

  const selectPayment = useCallback(
    (selectedValue: CALCULATE_OPTIONS) => {
      const { value } = selectedValue || null;
      const currentCarrierName = getValues("carrierName");

      if (value) {
        if (value === CALCULATE.DISTANCE_BASE_PRICE) {
          setValue("carrierName", CARRIERS.OTHER);
        } else if (value === CALCULATE.LALAMOVE) {
          setValue("carrierName", CALCULATE.LALAMOVE);
          setValue("description", CALCULATE.LALAMOVE);
        } else if (
          value === CALCULATE.SHIPPOP &&
          !Object.values(SHIPPOP_CARRIER_NAME).includes(currentCarrierName as SHIPPOP_CARRIER_NAME)
        ) {
          const firstCarrierName = Object.values(SHIPPOP_CARRIER_NAME)[0];
          setValue("carrierName", firstCarrierName);
          setValue("description", firstCarrierName);
        } else if (value !== CALCULATE.SHIPPOP && !ALL_CARRIERS.includes(currentCarrierName as CARRIERS)) {
          const firstCarrierName = ALL_CARRIERS[0];
          setValue("carrierName", firstCarrierName);
          setValue("description", firstCarrierName);
        }
      }
    },
    [getValues, setValue],
  );

  const handleMountedCarrierCard = useCallback(() => {
    if (watchFields.calculateType === CALCULATE.DISTANCE_BASE_PRICE) {
      setValue("carrierName", CARRIERS.OTHER);
    }
  }, [setValue, watchFields.calculateType]);

  const handleToggleIsReceiveOrderOutsideDeliveryTime = useCallback(() => {
    setIsReceiveOrderOutsideDeliveryTime(!isReceiveOrderOutsideDeliveryTime);
  }, [isReceiveOrderOutsideDeliveryTime]);

  const handleChangeCalculateType = useCallback(
    (selectedValue: ValueType<OptionType>) => {
      selectPayment(selectedValue as CALCULATE_OPTIONS);
    },
    [selectPayment],
  );

  const handleChangeCarrierName = useCallback(
    (value: ValueType<OptionType>) => {
      setValue("carrierName", (value as OptionType).value);
      setValue("description", (value as OptionType).value);
    },
    [setValue],
  );

  const handleChangeShippopInformation = (newShippopInformation: ShippopStoreSetting) => {
    const information = {
      email: newShippopInformation?.email,
      senderInformation: newShippopInformation?.senderInformation,
      credential: shippopInformation?.credential,
    };
    setValue("shippopSenderInformation", information, { shouldDirty: true });
    setShippopInformation(information);
  };

  const handleChangeCredential = useCallback(
    (newCredential: ShippopCredential) => {
      const isApplyNewCredential = !!newCredential?.apiKey;

      const newShippopInformation = {
        email: shippopInformation?.email,
        senderInformation: shippopInformation?.senderInformation,
        ...(isApplyNewCredential && {
          credential: newCredential,
        }),
      };

      if (newCredential?.apiKey) {
        setShippopInformation(newShippopInformation);
        setIsValidCredential(true);
        validateCredential({
          variables: {
            projectId,
            apiKey: newCredential?.apiKey,
          },
        });
        return;
      }

      setIsValidCredential(false);
      setValue("shippopSenderInformation", newShippopInformation, { shouldDirty: false });
    },
    [projectId, setValue, shippopInformation, validateCredential],
  );

  const hasCarrier =
    [
      CALCULATE.DISTANCE_BASE_PRICE,
      CALCULATE.FIXED_PRICE,
      CALCULATE.POSTAL_CODE_BASED_PRICE,
      CALCULATE.UNIT_PRICE,
    ].includes(watchFields.calculateType) ||
    (watchFields.calculateType === CALCULATE.SHIPPOP && isValidCredential && !validateLoading);

  const hasManagementFee = watchFields.calculateType === CALCULATE.SHIPPOP && isValidCredential && !validateLoading;

  return isLoadingPayments ? (
    <CircularProgress className="m-4" />
  ) : (
    <FormProvider {...method}>
      <div className="p-4">
        <ShippingCalculateTypeCard
          control={control}
          errorFields={errorFields}
          projectId={projectId}
          onChangeCalculateType={handleChangeCalculateType}
          calculateType={watchFields.calculateType}
          isReceiveOrderOutsideDeliveryTime={isReceiveOrderOutsideDeliveryTime}
          onToggleIsReceiveOrderOutsideDeliveryTime={handleToggleIsReceiveOrderOutsideDeliveryTime}
        />

        {watchFields.calculateType === CALCULATE.SHIPPOP && !validateLoading && (
          <CredentialSetting credential={shippopInformation?.credential} onChangeCredential={handleChangeCredential} />
        )}
        {watchFields.calculateType === CALCULATE.SHIPPOP && validateLoading && <CircularProgress className="m-4" />}
        {watchFields.calculateType === CALCULATE.SHIPPOP && isValidCredential && !validateLoading && (
          <Grid item xs={12} className="pt-4">
            <SenderInformation
              projectId={projectId}
              isOpen={isOpenAddressModal}
              onClose={() => setIsOpenAddressModal(false)}
              shippopInformation={shippopInformation}
              onChangeShippopInformation={handleChangeShippopInformation}
            />
          </Grid>
        )}
        {hasManagementFee && <StoreManagementFee storeManagementFee={watchFields.storeManagementFee} />}

        {hasCarrier && (
          <CarrierCard
            control={control}
            calculateType={watchFields.calculateType}
            carrierName={watchFields.carrierName}
            description={description}
            isEnableAllowOnlyCODSwitch={isEnableAllowOnlyCODSwitch}
            isAllowedOnlyCOD={isAllowedOnlyCODFromProps}
            isDisabledDuration={watchFields.disabledDuration}
            onChangeIsDisabledDuration={handleChangeIsDisabledDuration}
            minDuration={watchFields.minDuration}
            maxDuration={watchFields.maxDuration}
            onChangeMinDuration={handleChangeMinDuration}
            onChangeMaxDuration={handleChangeMaxDuration}
            onChangeCarrierName={handleChangeCarrierName}
            onMounted={handleMountedCarrierCard}
            fixedPrice={fixedPriceFromProps}
            settingsPostalCode={postalCodesSettings || []}
            setSettingsPostalCode={handlePostalCodesSettingsChange}
            errors={errors}
            shippopCalculateType={shippopCalculateType}
            onChangeShippopCalculateType={handleChangeShippopCalculateType}
          />
        )}

        {![CALCULATE.DISTANCE_BASE_PRICE, CALCULATE.LALAMOVE, CALCULATE.SHIPPOP].includes(
          watchFields.calculateType,
        ) && <FlatRate fixedPrice={fixedPriceFromProps} />}

        {watchFields.calculateType === CALCULATE.POSTAL_CODE_BASED_PRICE && (
          <PostalCodeBasedShipping
            fixedPrice={fixedPriceFromProps}
            settingsPostalCode={postalCodesSettings || []}
            setSettingsPostalCode={handlePostalCodesSettingsChange}
            errors={errors}
          />
        )}

        <div className="p-2">
          {(formState.isDirty || isShowSaveButton) && (
            <Button className="my-4 px-2" fullWidth onClick={handleValidateForm}>
              {t("Submit")}
            </Button>
          )}
        </div>
      </div>
    </FormProvider>
  );
};

export default EditShippingMethodForm;
