import * as React from "react";

const SvgIcDownload = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.407 15.008c.78 0 1.408.628 1.408 1.407v6.514a2.46 2.46 0 002.455 2.456h15.46a2.46 2.46 0 002.455-2.456v-6.41a1.408 1.408 0 012.815 0v6.41a5.275 5.275 0 01-5.27 5.27H8.27A5.275 5.275 0 013 22.93v-6.514c0-.78.628-1.407 1.407-1.407zM16 4c.78 0 1.407.628 1.407 1.407v12.6l2.079-2.078c.265-.265.625-.415.992-.415.377 0 .736.148 1.002.424.542.542.542 1.43-.009 1.982l-4.478 4.478a1.415 1.415 0 01-.993.415c-.379 0-.74-.151-1.002-.425l-4.47-4.468a1.405 1.405 0 111.986-1.991l2.079 2.078v-12.6C14.593 4.627 15.22 4 16 4z"
    />
  </svg>
);

export default SvgIcDownload;
