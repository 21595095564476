import * as React from "react";

const SvgIcShow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M.009 16.66c.008.043.032.082.047.123.024.075.04.153.084.222.241.385 6.033 9.435 14.984 9.897.236.011.471.018.707.018 5.485 0 10.854-3.303 15.966-9.829.023-.03.03-.066.05-.098.027-.045.048-.09.069-.138a.998.998 0 00.069-.213.963.963 0 00.012-.182.963.963 0 00-.012-.183 1.002 1.002 0 00-.069-.213 1.012 1.012 0 00-.07-.138c-.019-.031-.025-.068-.049-.098-5.332-6.806-10.944-10.098-16.672-9.81C6.173 6.478.382 15.527.139 15.913c-.044.07-.059.147-.083.221-.015.042-.039.08-.048.124-.013.067-.007.133-.006.2 0 .068-.007.134.007.2zm15.99-7.534a6.34 6.34 0 016.333 6.334 6.34 6.34 0 01-6.334 6.334 6.34 6.34 0 01-6.333-6.334 6.34 6.34 0 016.334-6.334zM10.25 9.4a8.333 8.333 0 00-2.616 6.06c0 4.612 3.752 8.365 8.364 8.365 4.611 0 8.364-3.753 8.364-8.364a8.32 8.32 0 00-2.279-5.72c2.558 1.393 5.104 3.636 7.613 6.72-4.74 5.824-9.607 8.665-14.457 8.414-6.744-.343-11.679-6.554-13.012-8.416.975-1.363 3.884-5.039 8.023-7.059z"
    />
  </svg>
);

export default SvgIcShow;
