import * as yup from "yup";
import { ActionType } from "types/RichMenu";

const MAX_CHAR_LIMIT_FOR_MENU_BAR_LABEL = 14;

const schema = (t?: Function) =>
  yup.object().shape({
    title: yup.string().required(t?.("FORM_IS_REQUIRED", { name: t("TITLE") })),
    label: yup
      .string()
      .required(t?.("FORM_IS_REQUIRED", { name: t("MENU_BAR_LABEL") }))
      .max(MAX_CHAR_LIMIT_FOR_MENU_BAR_LABEL, t?.("FORM_IS_REQUIRED", { name: t("MENU_BAR_LABEL_MAX_CHAR_EXCEEDED") })),
    behavior: yup.string().required(t?.("FORM_IS_REQUIRED", { name: t("DEFAULT_BEHAVIOR") })),
    imageUrl: yup.string().required(t?.("FORM_IS_REQUIRED", { name: t("IMAGE") })),
    template: yup.string().required(t?.("FORM_IS_REQUIRED", { name: t("TEMPLATE") })),
    action: yup.array().of(
      yup.object({
        actionType: yup.string().required(t?.("FORM_IS_REQUIRED", { name: t("ACTION_TYPE") })),
        value: yup.mixed().when(["actionType"], (actionType: ActionType) => {
          switch (actionType) {
            case ActionType.TEXT:
              return yup.object({
                text: yup.string().required(t?.("FORM_IS_REQUIRED", { name: t("THIS_FIELD") })),
              });
            case ActionType.URL:
              return yup.object({
                url: yup
                  .string()
                  .url(t?.("FORM_VALID_URL_IS_REQUIRED", { name: t("URL") }))
                  .required(t?.("FORM_IS_REQUIRED", { name: t("URL") })),
                description: yup.string().required(t?.("FORM_IS_REQUIRED", { name: t("DESCRIPTION") })),
              });

            default:
              return yup.string().required(t?.("FORM_IS_REQUIRED", { name: t("THIS_FIELD") }));
          }
        }),
      }),
    ),
  });

export default schema;
