import * as React from "react";

const SvgIcProductInventory = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.87 6.99a3.268 3.268 0 012.077-4.123A3.268 3.268 0 019.07 4.945l6.415 19.441a4.287 4.287 0 00-1.868-.07L7.407 5.494a1.514 1.514 0 00-1.91-.963 1.514 1.514 0 00-.963 1.91.876.876 0 01-1.664.55zm25.274 15.252l-10.51 3.467a4.3 4.3 0 01.856 1.496c.007.02.01.04.017.061l10.186-3.36a.876.876 0 00-.55-1.664zM17.658 27.48a3.441 3.441 0 11-6.535 2.156 3.441 3.441 0 016.535-2.156zm-1.634.539a1.72 1.72 0 10-3.267 1.078 1.72 1.72 0 003.267-1.078zm9.464-18.965l3.568 10.815a.438.438 0 01-.279.553l-11.23 3.705a.438.438 0 01-.554-.278l-3.568-10.815a.438.438 0 01.279-.553l11.23-3.706a.438.438 0 01.554.28zm-1.486 6.282l-3.824-2.104-1.82 3.967 1.205.554.787-1.716 1.421 4.308 1.36-.449-1.42-4.307 1.653.91.638-1.163zm-3.337-6.452L18.384 1.97a.28.28 0 00-.354-.178l-1.956.645-.01.018-.016-.009-1.032.34.85 2.576-1.164.384-.85-2.575-1.032.34-.008.017-.018-.008-1.944.641a.28.28 0 00-.178.354l2.281 6.914a.28.28 0 00.354.178l7.18-2.369a.28.28 0 00.178-.354z"
    />
  </svg>
);

export default SvgIcProductInventory;
