import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Card from "components/Card";
import Typography from "components/Typography";
import Grid from "components/Grid";
import { ShippopCredential } from "types/Shipping";
import ApiKeyForm from "./ApiKeyForm";

type CredentialSettingProps = {
  credential?: ShippopCredential;
  onChangeCredential: (credential: ShippopCredential) => void;
};

const CredentialSetting: FC<CredentialSettingProps> = (props) => {
  const { t } = useTranslation();
  const { credential, onChangeCredential } = props;

  const onChangeApiKey = (apiKey: string) => {
    const editedCredential: ShippopCredential = {
      apiKey,
    };
    onChangeCredential(editedCredential);
  };

  return (
    <Card fullWidth padding="24px" className="mt-4">
      <Grid item xs={12}>
        <Typography variant="title7" color="dark">
          {t("shipping.shippop.credential.title")}
        </Typography>
      </Grid>
      <Grid item container className="pt-4">
        <ApiKeyForm apiKey={credential?.apiKey || ""} onChangeApiKey={onChangeApiKey} />
      </Grid>
    </Card>
  );
};

export default CredentialSetting;
