import * as React from "react";

const IcOpenNotification = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M25.22.91a.46.46,0,0,0-.46.45v.48a3.44,3.44,0,0,1,.46,0,3.4,3.4,0,0,1,.45,0V1.36A.45.45,0,0,0,25.22.91ZM12.8,11H5.2a.88.88,0,1,0,0,1.76h7.6a.88.88,0,0,0,0-1.76ZM25.22,0a1.36,1.36,0,0,1,1.36,1.36V2A4.68,4.68,0,0,1,29.9,6.5v1a7.11,7.11,0,0,0,2,4.95.46.46,0,0,1,.09.5.45.45,0,0,1-.41.27H18.87a.45.45,0,0,1-.33-.77,7.11,7.11,0,0,0,2-4.95v-1A4.68,4.68,0,0,1,23.86,2V1.36A1.36,1.36,0,0,1,25.22,0Zm2.07,14.12a2.26,2.26,0,0,1-4.15,0ZM12.8,15.54H5.2a.88.88,0,0,0,0,1.76h7.6a.88.88,0,0,0,0-1.76ZM23,20.1H5.2a.88.88,0,0,0,0,1.76H23a.88.88,0,1,0,0-1.76ZM18.89,6.43c0,.28,0,1.36,0,1.36,0,1.55-1.11,3.29-2.85,5.34a1.33,1.33,0,0,0-.36.92.76.76,0,0,0,.7.73h4.91A4.25,4.25,0,0,0,25.22,17a3.5,3.5,0,0,0,3-1.4V23a3.38,3.38,0,0,1-3.38,3.38H10.6a1.28,1.28,0,0,0-1,.48s-4.85,4.63-5,4.8a1,1,0,0,1-.46.3c-.28.07-.57-.06-.5-.4l.83-4.53c.06-.46-.48-.65-.78-.65H3.36A3.38,3.38,0,0,1,0,23V9.82A3.39,3.39,0,0,1,3.36,6.43Z"
    />
  </svg>
);

export default IcOpenNotification;
