import { useQuery } from "@apollo/client";
import { POSTS } from "graphql/facebookCrawler/query";
import { FacebookPostType } from "types/FacebookLive";

export default (projectId: string, limit: number, after: string) => {
  const { data: postsData, loading: isPostsDataLoading } = useQuery(POSTS, {
    skip: !projectId,
    variables: {
      projectId,
      limit,
      after,
      type: FacebookPostType.LIVE,
    },
    fetchPolicy: "network-only",
  });

  return { postsData, isPostsDataLoading };
};
