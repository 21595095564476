import { useSubscription } from "@apollo/client";
import BotIndicatorMenuItem from "components/BotIndicatorMenuItem";
import { IS_CHATBOT_ENABLED_CHANGED } from "graphql/project/subscription";
import React, { FC } from "react";

export interface BotIndicatorProps {
  isChatbotEnabled: boolean;
  isAdmin?: boolean;
  projectId: string;
}

export const BotIndicator: FC<BotIndicatorProps> = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const { data, loading } = useSubscription(IS_CHATBOT_ENABLED_CHANGED, { variables: { projectId: props.projectId } });
  const { isAdmin, isChatbotEnabled } = props;

  function getIsChatbotEnabled() {
    if (!loading && data) {
      return data.projectInformationChanged.isChatbotEnabled;
    }
    return isChatbotEnabled;
  }

  return <BotIndicatorMenuItem isChatbotEnabled={getIsChatbotEnabled()} isAdmin={isAdmin} />;
};
