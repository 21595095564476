import axios, { AxiosResponse } from "axios";
import { GB_PRIME_PUBLIC_KEY, GB_PRIME_URL } from "config";

interface GBPrimeGetTokenBody {
  rememberCard: boolean;
  card: {
    number: string;
    expirationMonth: string;
    expirationYear: string;
    securityCode: string;
    name: string;
  };
}

interface GBPrimeGetTokenResponse {
  rememberCard: boolean;
  resultCode: string;
  card: {
    number: string;
    expirationMonth: string;
    expirationYear: string;
    securityCode: string;
    name: string;
    token: string;
  };
}

const GB_PRIME_HEADER = btoa(`${GB_PRIME_PUBLIC_KEY}:`);

const instance = axios.create({
  baseURL: GB_PRIME_URL,
  headers: {
    Authorization: `Basic ${GB_PRIME_HEADER}`,
  },
});

export const getGBPrimeToken = (body: GBPrimeGetTokenBody): Promise<AxiosResponse<GBPrimeGetTokenResponse>> => {
  return instance.post("/v2/tokens", body);
};
