import { gql } from "@apollo/client";

export const AUDIENCE_NUMBER = gql`
  query audienceNumber(
    $projectId: ID!
    $channel: BroadcastChannel!
    $criteria: [BroadcastCriteriaInput]
    $recurringNotificationOptInId: ID
  ) {
    audienceNumber(
      projectId: $projectId
      channel: $channel
      criteria: $criteria
      recurringNotificationOptInId: $recurringNotificationOptInId
    )
  }
`;

export const BROADCAST_CAMPAIGN = gql`
  query broadcastCampaign($projectId: ID!, $broadcastId: ID!) {
    broadcastCampaign(projectId: $projectId, broadcastId: $broadcastId) {
      id
      projectId
      name
      channel
      numberOfAudience
      numberOfEngagement
      broadcastCriteria {
        id
        projectId
        systemCriteria
        customCriteria
        locationCriteria
        operation
      }
      status
      responseMessage {
        type
        value
      }
      broadcastDate
      failReason
      createdAt
      updatedAt
      newOrderConversionRate
      orderPaidConversionRate
      orderPaidConversionTotal
      broadcastDate
      type
    }
  }
`;

export const BROADCAST_CAMPAIGNS = gql`
  query broadcastCampaigns($projectId: ID!, $page: Int, $pageSize: Int, $filter: BroadcastFilterInput) {
    broadcastCampaigns(projectId: $projectId, page: $page, pageSize: $pageSize, filter: $filter) {
      results {
        id
        projectId
        name
        channel
        numberOfAudience
        numberOfEngagement
        broadcastCriteria {
          id
          projectId
          systemCriteria
          customCriteria
          locationCriteria
          operation
        }
        status
        responseMessage {
          type
          value
        }
        broadcastDate
        failReason
        createdAt
        updatedAt
        newOrderConversionRate
        orderPaidConversionRate
        orderPaidConversionTotal
        broadcastDate
        type
      }
      total
    }
  }
`;

export const RECURRING_NOTIFICATION_OPT_INS = gql`
  query recurringNotificationOptIns($projectId: ID!) {
    recurringNotificationOptIns(projectId: $projectId) {
      id
      projectId
      name
      broadcastStatus
      deliveryContextType
      messageTitle
      messageImageUrl
      frequencyType
      buttonMessageType
      isAutoRenew
      isActive
      isDeleted
      createdAt
      updatedAt
      numberOfSubscribers
      invitationMessage
      reactionMessage
    }
  }
`;
