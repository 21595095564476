import * as React from "react";

const IcTyping = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M29.7141 24.9172H30.4694C30.7624 24.9172 30.9999 25.1596 30.9999 25.4586C30.9999 25.7576 30.7624 26 30.4694 26H27.055C26.7619 26 26.5244 25.7576 26.5244 25.4586C26.5244 25.1596 26.7619 24.9172 27.055 24.9172H28.0038V7.0828H27.0487C26.7557 7.0828 26.5182 6.84041 26.5182 6.5414C26.5182 6.24239 26.7557 6 27.0487 6H30.4631C30.7561 6 30.9937 6.24239 30.9937 6.5414C30.9937 6.84041 30.7561 7.0828 30.4631 7.0828H29.7141V24.9172ZM8.99688 7.28643C9.63621 7.28484 10.2036 7.70425 10.4013 8.32465L14.8269 22.0507C14.9775 22.5113 14.9018 23.0176 14.6235 23.4114C14.3452 23.8052 13.8983 24.0384 13.4225 24.038H13.1166C12.4709 24.0427 11.8982 23.616 11.7059 22.987L10.7509 19.9743H4.83346L3.89716 22.9807C3.70709 23.6121 3.13382 24.0418 2.48646 24.038C2.00675 24.0404 1.55645 23.8024 1.28175 23.4011C1.00181 23.0071 0.925126 22.4997 1.07577 22.038L5.57001 8.31828C5.77397 7.70064 6.34199 7.28517 6.98071 7.28643H8.99688ZM7.80405 10.4778L5.63184 17.4204H9.95131L7.80405 10.4778ZM16.8992 13.618C17.7169 12.2167 19.3586 11.446 21.5246 11.446C24.7018 11.446 26.3871 13.0192 26.3621 15.9938V22.6116C26.3621 23.396 25.7389 24.032 24.9702 24.032H24.7579C24.0815 24.0183 23.5127 23.5104 23.4097 22.8281C22.531 23.8221 21.2543 24.3503 19.9453 24.2613C17.3799 24.2613 15.8506 22.8727 15.8506 20.5415C15.8506 17.981 18.0478 16.567 22.0427 16.567H23.3535V16.1084C23.3535 14.4906 22.7979 13.8536 21.3685 13.8536C20.426 13.8536 19.8018 14.1785 19.4959 14.8473C19.2723 15.376 18.768 15.7241 18.2038 15.739H18.1476C17.6321 15.7504 17.1523 15.4713 16.8992 15.0129C16.6524 14.582 16.6524 14.0488 16.8992 13.618ZM20.6011 21.9425C22.2677 21.9425 23.3476 21.0508 23.3476 19.6686L23.3538 18.6304H22.1054C19.8958 18.6304 18.8658 19.2037 18.8658 20.4266C18.8658 21.4457 19.4338 21.9425 20.6011 21.9425Z"
    />
  </svg>
);

export default IcTyping;
