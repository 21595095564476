import { useQuery } from "@apollo/client";

import { CUSTOMER_ORDER_LIMIT } from "config/customer";
import { CUSTOMER } from "graphql/customer/query";
import { CustomerType } from "types/Customer";
import { OrderConnectionType, OrderType } from "types/Order";

type UseCustomerProps = {
  customerId: string;
  projectId: string;
};

const useCustomer = ({ projectId, customerId }: UseCustomerProps) => {
  const { loading: isLoading, data } = useQuery(CUSTOMER, {
    skip: !projectId || !customerId,
    variables: {
      projectId,
      customerId,
      pageSize: CUSTOMER_ORDER_LIMIT,
      page: 0,
    },
    fetchPolicy: "network-only",
  });

  const customer: CustomerType = data?.customer;
  const customerOrdersTotal: OrderConnectionType["total"] = customer?.orders?.total || 0;
  const customerOrders: OrderType[] = customer?.orders?.results || [];
  const customerTotalSpend: CustomerType["totalSpending"] = customer?.totalSpending || 0;

  return {
    isLoading,
    customer,
    customerOrdersTotal,
    customerOrders,
    customerTotalSpend,
  };
};

export default useCustomer;
