import { RequestHeader } from "types/Image";

const uploadFileToGCS = (file: File, requestHeaders: RequestHeader[], signedUrl: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (!requestHeaders.length || !signedUrl) {
      reject();
    }
    const xhr = new XMLHttpRequest();
    // must to be PUT method
    xhr.open("PUT", signedUrl, true);
    xhr.onload = () => {
      const { status } = xhr;

      if (status === 200) {
        resolve();
      } else {
        reject();
      }
    };

    xhr.onerror = () => {
      reject();
    };

    // loop to set required header
    requestHeaders.forEach((header) => {
      const { key, value } = header;
      if (key !== "filename") {
        xhr.setRequestHeader(key, value);
      }
    });

    xhr.send(file);
  });
};

export default uploadFileToGCS;
