import styled from "styled-components";
import { Theme } from "types/Theme";

import Divider from "components/Divider";

type StepperCircleProps = {
  theme: Theme;
  isActive: boolean;
  isCompleted: boolean;
};

export const StepperCircle = styled.div.attrs((props: StepperCircleProps) => {
  const { theme, isActive, isCompleted } = props;

  let bgColor = theme.COLORS.LightBlue;
  let borderColor = theme.COLORS.LightBlue;
  if (isCompleted) {
    bgColor = theme.COLORS.White;
  } else if (isActive) {
    bgColor = theme.COLORS.Primary;
    borderColor = theme.COLORS.Primary;
  }

  return { theme, bgColor, borderColor, isActive, isCompleted };
})`
  display: flex;
  border-radius: 13px;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border: ${({ borderColor }) => `solid 0.5px ${borderColor}`};
  background-color: ${({ bgColor }) => bgColor};
`;

type StepLineProps = {
  isLeft?: boolean;
  isRight?: boolean;
};

export const StepLine = styled(Divider)<StepLineProps>`
  position: absolute;
  margin: auto;
  left: ${({ isLeft }) => (isLeft ? "-20px" : "auto")};
  right: ${({ isRight }) => (isRight ? "-20px" : "auto")};
  width: 50%;
`;
