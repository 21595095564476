import * as React from "react";

const IcTurnOnBot = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 1c3.748 0 6.891 2.585 7.746 6.07.065-.016.133-.023.203-.023H17c.552 0 1 .448 1 1v1.855c0 .552-.448 1-1 1h-.05c-.07 0-.14-.007-.205-.02-.845 3.449-3.937 6.018-7.637 6.067H9c-3.748 0-6.891-2.585-7.746-6.07-.065.016-.133.023-.203.023H1c-.552 0-1-.448-1-1V8.047c0-.552.448-1 1-1h.05c.071 0 .14.007.205.021C2.11 3.585 5.252 1 9 1zm0 3.062c-2.781 0-5.036 2.255-5.036 5.036 0 2.141 1.2 3.893 3.084 4.621.193.073-.558 1.985-.5 2.103 2.223-1.023 3.842-1.836 4.457-2.103 1.784-.774 3.031-2.552 3.031-4.62 0-2.782-2.255-5.037-5.036-5.037zm1.836 2.328c.909.61 1.452 1.625 1.452 2.715 0 .906-.383 1.78-1.049 2.397-.611.567-1.39.872-2.216.872-.086 0-.171-.003-.257-.01-1.603-.121-2.896-1.422-3.008-3.027-.08-1.156.466-2.28 1.425-2.934.177-.12.405-.134.596-.033.192.102.311.299.311.515 0 .192-.097.373-.259.485-.575.394-.918 1.043-.918 1.735 0 .599.257 1.172.706 1.572.454.405 1.039.589 1.649.52.942-.108 1.716-.869 1.838-1.809.104-.795-.242-1.57-.903-2.021-.134-.091-.223-.23-.249-.384l-.008-.093v-.023c0-.212.117-.406.305-.506.186-.1.41-.088.585.03zm-1.817-.833c.258 0 .473.187.518.432l.008.094v.038l.001.009V8.72c0 .29-.237.526-.527.526-.258 0-.473-.187-.517-.432l-.009-.094V6.805l-.002-.027V6.13l.002-.028v-.019c0-.29.236-.526.526-.526z"
    />
  </svg>
);

export default IcTurnOnBot;
