import { ErrorAccountConnectCode } from "types/Auth";

const NETWORK_ERROR = "Network error: Failed to fetch";

const mapErrorMessage = (errMessage: string) => {
  switch (errMessage) {
    case NETWORK_ERROR:
      return "NETWORK_ERROR";
    case ErrorAccountConnectCode.SOCIAL_ID_ALREADY_EXISTED:
      return "SOCIAL_ID_ALREADY_EXISTED";
    case ErrorAccountConnectCode.PROVIDER_NOT_SUPPORTED:
      return "PROVIDER_NOT_SUPPORTED";
    default:
      return "PLEASE_TRY_AGAIN";
  }
};

export default mapErrorMessage;
