import moment from "moment";
import { CAMPAIGN_TIME_FORMAT_REGEX } from "constants/Campaign";

export const getDateTime = (time: string, date: string) => {
  const [hour, minute]: string[] = time.split(":");

  const newDate = moment(date).hour(Number(hour)).minute(Number(minute)).toDate().toISOString();

  return newDate;
};

export const validateTimeFormat = (time: string) => {
  const timeFormatValid = time && time.match(CAMPAIGN_TIME_FORMAT_REGEX);
  const isValidFormat = Boolean(timeFormatValid);

  if (!isValidFormat) {
    return false;
  }

  const [hour, minute]: string[] = time.split(":");
  if (Number(hour) > 23 || Number(minute) > 59) {
    return false;
  }

  return true;
};
