import { useQuery } from "@apollo/client";
import { OptionType, OptionsType } from "@atlaskit/select";

import { PRODUCTS } from "graphql/product/query";

import { GetProductsQueryType, ProductItemPropsType } from "types/Product";

type UseSaveProductPropsType = {
  projectId: string;
  categoryId: string;
};

const useGetProducts = (props: UseSaveProductPropsType) => {
  const { projectId, categoryId } = props;

  const { loading, data } = useQuery<GetProductsQueryType>(PRODUCTS, {
    variables: {
      projectId,
      filter: { categoryIds: [categoryId] },
    },
    skip: !projectId || !categoryId,
  });

  const productResults = (data && data.products && data.products.results) || [];

  const convertProductsToOptionFormat = (productData: ProductItemPropsType[]) => {
    if (productData && productData.length) {
      return productData.map((product: ProductItemPropsType) => ({
        label: product.name,
        img: product.images && product.images[0] && product.images[0].src,
        value: product.id,
      }));
    }
    return [];
  };

  const findProductsByProductId = (productId: number) =>
    productResults &&
    productResults.find((findProduct: ProductItemPropsType) => Number(findProduct.id) === Number(productId));

  const convertProductValueToOptionFormat = (productData: number[]) => {
    const newOptionsValue = productData.map((productId: number) => {
      const findProduct = findProductsByProductId(productId);
      return {
        label: findProduct && findProduct.name,
        img: findProduct && findProduct.images && findProduct.images[0] && findProduct.images[0].src,
        value: productId,
      };
    });

    return (newOptionsValue as OptionsType<OptionType>) || [];
  };

  return {
    loading,
    productOptions: convertProductsToOptionFormat(productResults || []),
    productResults: (data && data.products && data.products.results) || [],
    convertProductValueToOptionFormat,
  };
};

export default useGetProducts;
