import React, { FC, useState } from "react";
import { NO_IMG_AVAILABLE } from "constants/Image";
import { MessageImageValue } from "types/AISetting";
import { customerBorderRadius } from "utils/chat";
import MessageLayout from "./MessageLayout";
import { ImageBubble } from "./styled";

type ImageMessagePropsType = {
  value: MessageImageValue;
  isCustomer: boolean;
  timeStamp?: string;
  from?: string;
  bgColor?: string;
  fullWidth?: boolean;
  onClickDate?: () => void;
};

const ImageMessage: FC<ImageMessagePropsType> = ({
  bgColor = "",
  from = "",
  isCustomer,
  onClickDate,
  timeStamp = "",
  value,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const borderRadius = customerBorderRadius(isCustomer);

  return (
    <MessageLayout
      bgColor={bgColor}
      from={from}
      isCustomer={isCustomer}
      onClickDate={onClickDate}
      timeStamp={timeStamp}
    >
      <a href={value.url} key={value.url} target="_blank" rel="noopener noreferrer">
        <ImageBubble
          alt="image-content"
          src={value.url || NO_IMG_AVAILABLE}
          isLoading={isLoading}
          borderRadius={borderRadius}
          onLoad={() => {
            setIsLoading(false);
          }}
        />
      </a>
    </MessageLayout>
  );
};

export default ImageMessage;
