export const COLORS = {
  Accent: "#0048e5",
  Apple: "#121419",
  // #region new design colors
  NewPrimary: "#7665FF",
  NewPrimaryDark: "#6353EA",
  NewPrimaryLight: "#8778FF",
  // #endregion
  Primary: "#2f4069",
  PrimaryTransparent: "rgba(102, 62, 232, 0.06)",
  PrimaryNude: "#f0f3fc",
  PrimaryDark: "#1c2b50",
  PrimaryLight: "#828ca5",
  Dark: "#141931",
  DarkGray: "#42495a",
  DarkMed: "#737d94",
  DarkLight: "#a1a6b9",
  Blue: "#b5cff0",
  LightBlue: "#cad2e4",
  LightBlueGray: "#e6ebf3",
  LightGray: "#eff1f5",
  Light: "#f5f7fb",
  LightWhite: "#fbfcfe",
  Success: "#00c566",
  DisabledSuccess: "#0ab36d",
  Error: "#ff5472",
  Warning: "#fcc943",
  Info: "#4374fc",
  White: "#ffffff",
  BoxShadow: "rgba(0, 0, 0, 0.15)",
  COD: "#ef5a97",
  NewOrder: "#663ee8",
  ReadyToApprove: "#ff9526",
  ReadyToShip: "#7ea0ff",
  Badge: "#d04402",
  CustomerChatStatus: "#eebde8",
  OldBubbleChatBackground: "#e0e0e0",
  OldFontChatColor: "#424252",
  Salmon: "#e67676",
  MacaroniCheese: "#e1b540",
  CarolinaBlue: "#98ade6",
  GreyBlue: "#64c3c8",
  ReportDown: "#fe3f3b",
  GraphTotalSale: "#663ee8",
  GraphOrder: "#fcc943",
  GraphCustomer: "#7ea0ff",
  TimelineGreen: "#2ae371",
  DeepleHorizontal: "linear-gradient(90deg, rgba(236,0,140,1) 0%, rgba(255,78,0,1) 100%);",
  DeepleVertical: "linear-gradient(`180deg, rgba(236,0,140,1) 0%, rgba(255,78,0,1) 100%);",
  DeepleBG: "#221f20",
  ChatBadgeWrapper: "#fffaf9",
  InfoYellowBackground: "#fff3e1",
  InfoYellowBorder: "#f7b500",
  InfoYellow: "#ff9425",
  Voided: "#fe6767",
  Refunded: "#E34923",
  TableShadow: "#e4e9f4",
  Facebook: "#1877F2",
  Anakiwa: "linear-gradient(#99B4FF, #7EA0FF)",
  Zircon: "#edf2ff",
  bgTrainingCard: "#E6EDFD",
  borderTrainingCard: "#5686EE",
  DarkBlue500: "#5686EE",
  Gray100: "#F5F7FB",
  BrandeisBlue: "#0076FF",
  Gray200: "#EAEBEF",
  Gray400: "#B6BAC8",
  Gray500: "#6F7794",
  BrightGray: "#EAEBEF",
  Red100: "#FFE3E3",
  Red400: "#FE6969",
  DarkElectricBlue: "#626D78",
  GhostWhite: "#F8FBFF",
  Platinum: "#E5E5E5",
  ChineseBlack: "#0D1114",
  AntiFlashWhite: "#f1f1f1",
  Lotion: "#fbfbfb",
};

export const BANK_COLORS = {
  BAY: "#fec43b",
  BBL: "#1e4598",
  CIMB: "#7e2f36",
  CITI: "#1583c7",
  GSB: "#eb198d",
  KBANK: "#138f2d",
  KTB: "#1ba5e1",
  LH: "#6D6E72",
  SCB: "#4e2e7f",
  THANACHART: "#f46f22",
  TMB: "#1279be",
  TTB: "#ebebeb",
  UOB: "#0b3979",
};

export default COLORS;
