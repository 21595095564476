import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import React, { HTMLAttributes, FC, useState, useContext, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import { IcArrowDown, IcHelp, IcPlanUpgrade } from "components/SvgIcons";
import { PopoverMenu } from "components/Menu";
import { SvgIcon } from "components/Icon";
import AppLogo from "components/AppLogo";
import Avatar from "components/Avatar";
import Button from "components/Button";
import ButtonBase from "components/ButtonBase";
import DeepleLogoHeader from "components/DeepleLogoHeader";
import Grid from "components/Grid";
import Typography from "components/Typography";
import Tooltip from "components/Tooltip";
import { AppTitle, Burger, ProjectList } from "components/MobileLayout";
import { ButtonColors } from "types/Button";

import { PLAN_UPGRADE, PROJECT, QUICK_START, PLAN } from "constants/Router";
import { ACCOUNT_SETTINGS_ROUTE_CONFIG, QUICK_START_ROUTE_CONFIG } from "routers/routerConfig";
import { PermissionType } from "types/User";
import { ProjectIdType } from "types/Project";
import { AppTitleContext, SideNavMenuContext } from "utils/context";
import useIsDesktop from "utils/hooks/useIsDesktop";
import useGetUserWithPermission from "utils/hooks/useGetUserWithPermission";

import { QUICK_START_CARDS, QuickStartOptionType } from "domain/Setting/QuickStart/config";

import NotificationPopover from "domain/Notification";

import {
  StyledTopNav,
  StyledLink,
  UserMenuLink,
  AnchorMenuLink,
  TopNavShadow,
  IconWrapper,
  HelpItemWrapper,
  HelpTitleWrapper,
  HelpIconWrapper,
  GapSpacing,
} from "./styled";

const drawerWidth = 350;

const useStyles = makeStyles(() => ({
  root: {},
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    paddingTop: 50,
    width: drawerWidth,
    boxShadow: "0 5px 10px 0 rgba(0,0,0,0.15)",
  },
}));

type TopNavPropsType = {
  avatar: string;
  displayName: string;
  isActive: boolean;
  menuId: string;
  onToggle: (isToggled: boolean) => void;
  permissions?: PermissionType[];
  onClickProjectList: (projectId: string) => void;
  videoProps: {
    isShowVideo: boolean;
    width: number;
    height: number;
    x: number;
    y: number;
  };
  setVideoProps: Function;
  onLogout: () => void;
  displayUpgradeButton?: boolean;
};

const TopNav: FC<HTMLAttributes<HTMLHeadElement> & TopNavPropsType> = ({
  className,
  avatar,
  displayName,
  isActive,
  menuId,
  onToggle,
  permissions = [],
  videoProps,
  setVideoProps,
  onClickProjectList,
  onLogout,
  displayUpgradeButton,
}) => {
  const { title, setTitle } = useContext(AppTitleContext);
  const { projectId } = useParams<ProjectIdType>();

  const { handleShowSecondLevel } = useContext(SideNavMenuContext);
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [isSelectProject, setIsSelectProject] = useState(false);
  const { isOwner } = useGetUserWithPermission(projectId);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, isClickProject: boolean) => {
    setIsSelectProject(isClickProject);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickLogout = () => {
    onLogout();
    handleClose();
  };

  const handleClickProjectList = (projectIdInput: string) => {
    onClickProjectList(projectIdInput);
    handleClose();
  };

  const handleChangeRoute = (newTitle: string) => {
    setTitle(t(newTitle));
    handleClose();
  };

  const handleClickAccountSettingMenu = () => {
    handleChangeRoute(ACCOUNT_SETTINGS_ROUTE_CONFIG.title);
    handleShowSecondLevel(true);
  };

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [openVideoId, setOpenVideoId] = useState(-1);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  if (isDesktop) {
    if (projectId) {
      return (
        <>
          <StyledTopNav className={className}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item container xs={6} alignItems="center">
                <Link to={`/${PROJECT}`}>
                  <DeepleLogoHeader />
                </Link>
              </Grid>
              <Grid item container xs={6} justify="flex-end" alignItems="center" className="px-4">
                {displayUpgradeButton && (
                  <Link to={`/${PROJECT}/${projectId}/${PLAN}/${PLAN_UPGRADE}`}>
                    <Button minHeight={32} size="small" minWidth={118} color={ButtonColors.NEW_PRIMARY}>
                      <Typography variant="body4" color="inherit">
                        <SvgIcon
                          className="ml-1 pb-0 pr-1"
                          component={IcPlanUpgrade}
                          fontSize="small"
                          htmlColor="inherit"
                        />
                        {t("billing.link.upgradePlan")}
                      </Typography>
                    </Button>
                  </Link>
                )}

                <NotificationPopover projectId={projectId} />
                <Tooltip title={t("Help") as string} placement="top-end" arrow>
                  <HelpIconWrapper isOpen={open} className="mx-2">
                    <SvgIcon
                      className="ml-1"
                      component={IcHelp}
                      fontSize="large"
                      color="inherit"
                      onClick={handleDrawerToggle}
                    />
                  </HelpIconWrapper>
                </Tooltip>
                <StyledLink
                  to={`/${PROJECT}/${projectId}${ACCOUNT_SETTINGS_ROUTE_CONFIG.link}`}
                  onClick={handleClickAccountSettingMenu}
                >
                  <ButtonBase className="d-flex align-items-center">
                    <Avatar size="28px" alt="userAvatar" className="mx-2" src={avatar} />
                    <p className="m-0">{displayName}</p>
                  </ButtonBase>
                </StyledLink>
                <ButtonBase
                  className="pr-8 py-3"
                  onClick={(event: MouseEvent<HTMLButtonElement>) => handleClick(event, false)}
                >
                  <SvgIcon className="ml-2" component={IcArrowDown} fontSize="small" />
                </ButtonBase>
              </Grid>

              <PopoverMenu keepMounted anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {isSelectProject ? (
                  <ProjectList isBGWhite permissions={permissions} handleClick={handleClickProjectList} />
                ) : (
                  [
                    <UserMenuLink
                      key="1"
                      to={`/${PROJECT}/${projectId}${ACCOUNT_SETTINGS_ROUTE_CONFIG.link}`}
                      onClick={handleClickAccountSettingMenu}
                    >
                      {t("Account settings")}
                    </UserMenuLink>,
                    isOwner ? (
                      <UserMenuLink
                        key="2"
                        to={`/${PROJECT}/${projectId}/${QUICK_START}`}
                        onClick={() => handleChangeRoute(QUICK_START_ROUTE_CONFIG.title)}
                      >
                        {t("BroadcastBar.button")}
                      </UserMenuLink>
                    ) : null,
                    <UserMenuLink
                      key="3"
                      to={{ pathname: `mailto:issue@1convo.ai` }}
                      target="_blank"
                      onClick={handleClose}
                    >
                      {t("Report issue")}
                    </UserMenuLink>,
                    <AnchorMenuLink key="4" onClick={handleClickLogout}>
                      {t("Log out")}
                    </AnchorMenuLink>,
                  ]
                )}
              </PopoverMenu>
            </Grid>
          </StyledTopNav>
          <TopNavShadow />

          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Grid container justify="center">
              <HelpTitleWrapper>
                <Typography variant="title7" color="darkGray">
                  {t("HELP CENTER")}
                </Typography>
              </HelpTitleWrapper>
            </Grid>

            <Grid container>
              {QUICK_START_CARDS.map((item: QuickStartOptionType, index: number) => (
                <Grid
                  container
                  alignContent="center"
                  alignItems="center"
                  item
                  xs={12}
                  key={item.title}
                  onClick={() => {
                    setOpenVideoId(index);
                    setVideoProps({
                      ...videoProps,
                      isShowVideo: true,
                      videoLink: item.videoLink,
                    });
                  }}
                >
                  <HelpItemWrapper isSelected={openVideoId === index}>
                    <Grid container alignContent="center" alignItems="center">
                      <IconWrapper className="pr-3">
                        <SvgIcon component={item.Icon} fontSize="large" />
                      </IconWrapper>
                      <Typography variant="title7" color="inherit">
                        {t(item.title)}
                      </Typography>
                    </Grid>
                  </HelpItemWrapper>
                </Grid>
              ))}
            </Grid>
          </Drawer>
        </>
      );
    }

    return <span />;
  }

  return (
    <>
      <StyledTopNav className={className}>
        <Link to="/">
          <AppLogo height="25" className="pl-3 d-flex" />
        </Link>
        <AppTitle>{title}</AppTitle>

        {projectId && <Burger id="burger-menu" isActive={isActive} onToggle={onToggle} aria-controls={menuId} />}

        {projectId ? (
          <GapSpacing className="mr-2">
            <NotificationPopover projectId={projectId} />
          </GapSpacing>
        ) : (
          <GapSpacing />
        )}
      </StyledTopNav>
      <TopNavShadow />
    </>
  );
};

export default TopNav;
