import React, { FC, memo } from "react";
import { useLocation, Link } from "react-router-dom";

import Avatar from "components/Avatar";
import Typography from "components/Typography";

type ProfileProps = {
  name: string;
  imgSrc: string;
  customerId: string;
};

const Profile: FC<ProfileProps> = ({ name, imgSrc, customerId }) => {
  const { pathname } = useLocation();

  return (
    <>
      <Avatar size="24px" alt={name} src={imgSrc} />
      <Link to={`${pathname}/${customerId}`}>
        <Typography variant="body3" color="darkGray" className="ml-2">
          {name}
        </Typography>
      </Link>
    </>
  );
};

export default memo(Profile);
