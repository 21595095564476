import React, { FC, memo } from "react";
import Card from "components/Card";
import Grid from "components/Grid";
import { RadioBox } from "components/Radio";
import { useTranslation } from "react-i18next";
import Typography from "components/Typography";
import Checkbox from "components/Checkbox";
import { ButtonColors } from "types/Button";
import Divider from "components/Divider";
import { IcDocumentDuplicate, IcInformationCircle } from "components/SvgIcons";
import Button from "components/Button";
import { Controller } from "react-hook-form";
import useDevice from "utils/hooks/useDevice";
import { Device } from "types/Device";
import { WorkingTimeWithId } from "types/Setting";
import { AI_SETTING_RESPONSE_MODE_TUTORIAL_URL } from "constants/AISetting";
import { DayButton, InfoWrapper } from "../styled";
import WorkingHourItem from "./WorkingHourItem";
import { DAYS } from "../config";
import { useScheduleForm } from "./useScheduleForm";

export type ScheduleFormPropTypes = {};

export const ScheduleForm: FC<ScheduleFormPropTypes> = () => {
  const { t } = useTranslation("");
  const device = useDevice();
  const {
    control,
    isActive,
    isChatbotEnabled,
    activeDays,
    workingHours,
    handleAddWorkingHour,
    handleRemoveWorkingHour,
    handleClickCopyButton,
    shouldEnableWorkigDayAddButton,
    shouldEnableWorkingDayRemoveButton,
    handleClickDayButton,
    setValue,
    isDisableSchduleConfiguration,
    handleChangeWorkingTime,
  } = useScheduleForm();

  return (
    <Card className="w-100 mx-3">
      {/* <Typography variant="title2">{t("SCHEDULE_FORM_TITLE")}</Typography> */}
      <Controller
        name="isChatbotEnabled"
        control={control}
        defaultValue={isChatbotEnabled}
        render={(p) => (
          <Grid container className="mt-2">
            <Grid item xs={12} lg={4} className={device !== Device.DESKTOP ? "mb-2" : "mr-2"}>
              <RadioBox
                title={t("SCHEDULE_FORM_OPEN")}
                SubTitle={t("SCHEDULE_FORM_OPEN_SUBTITLE")}
                value=""
                isActive={!p.value}
                forceOnClick
                onClick={() => setValue("isChatbotEnabled", false)}
              />
            </Grid>
            <Grid item xs={12} lg={4} className={device !== Device.DESKTOP ? "mt-2" : "ml-2"}>
              <RadioBox
                title={t("SCHEDULE_FORM_CLOSE")}
                SubTitle={t("SCHEDULE_FORM_CLOSE_SUBTITLE")}
                value="checked"
                isActive={p.value}
                forceOnClick
                onClick={() => setValue("isChatbotEnabled", true)}
              />
            </Grid>
          </Grid>
        )}
      />
      <Divider />
      <Grid container alignItems="center" className="mb-2">
        <Controller
          name="adminWorkingHours.isActive"
          control={control}
          defaultValue={isActive}
          render={(p) => <Checkbox {...p} checked={p.value} onChange={() => p.onChange(!p.value)} />}
        />
        <Typography variant="body3" className="ml-2 mr-2" fontWeight="600">
          {t("SCHEDULE_FORM_CHECKBOX_LABEL")}
        </Typography>
        <Typography variant="body3" className="ml-2">
          {t("SCHEDULE_FORM_CHECKBOX_HELPER")}
        </Typography>
      </Grid>
      <InfoWrapper className="d-none d-md-flex mt-2">
        <div className="mr-2">
          <IcInformationCircle />
        </div>
        <Typography variant="body3" className="ml-2">
          {t("SCHEDULE_FORM_INFORMATION")}
        </Typography>
      </InfoWrapper>
      <Grid container alignItems="center" className="mt-4">
        {DAYS.map((day) => (
          <DayButton
            key={day}
            color={activeDays[day] ? ButtonColors.PRIMARY : ButtonColors.SECONDARY}
            value={day}
            disabled={isDisableSchduleConfiguration}
            onClick={handleClickDayButton}
            className="mr-md-2"
          >
            {t(`SCHEDULE_FORM_${day}_BUTTON`)}
          </DayButton>
        ))}
      </Grid>
      <Grid container className="mt-4">
        <Grid item xs={9} sm={12}>
          <Controller
            name="adminWorkingHours.workingHours"
            control={control}
            defaultValue={workingHours}
            render={() => {
              return (
                <>
                  {workingHours.map((workingHour, index) => (
                    <Grid container key={workingHour.day} alignItems="flex-start" className="mb-2 pb-2 w-100">
                      <Grid item xs={12} md={2} className="d-flex">
                        <Typography variant="body3" fontWeight="500">
                          {t(`SCHEDULE_FORM_${workingHour.day}`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={6}>
                        <Grid item container className="align-items-end w-100">
                          {workingHour.time.map((time, timeIndex, times) => (
                            <WorkingHourItem
                              key={(time as WorkingTimeWithId).id}
                              day={workingHour.day}
                              index={timeIndex}
                              start={time.start}
                              end={time.end}
                              disabled={isDisableSchduleConfiguration}
                              onAdd={handleAddWorkingHour}
                              onRemove={handleRemoveWorkingHour}
                              onChange={handleChangeWorkingTime}
                              isAddEnabled={shouldEnableWorkigDayAddButton(timeIndex, times.length)}
                              isRemoveEnabled={shouldEnableWorkingDayRemoveButton(timeIndex, times.length)}
                            />
                          ))}
                        </Grid>
                      </Grid>
                      <Grid xs={12} md={3}>
                        {index === 0 && workingHours.length > 1 && (
                          <Button
                            className="mt-1"
                            disabled={isDisableSchduleConfiguration}
                            color={ButtonColors.GHOST}
                            onClick={handleClickCopyButton}
                            startIcon={<IcDocumentDuplicate />}
                          >
                            {t("SCHEDULE_FORM_COPY_WORKING_HOURS")}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </>
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid container className="mt-4 align-items-center">
        <Typography variant="body3" className="mr-1">
          {t("SCHEDULE_FORM_LEARN_MORE")}
        </Typography>
        <a href={AI_SETTING_RESPONSE_MODE_TUTORIAL_URL} target="_blank" rel="noreferrer">
          <Typography variant="body3" className="mr-1" fontWeight="600">
            {t("SCHEDULE_FORM_HOW_TO_LINK")}
          </Typography>
        </a>
      </Grid>
    </Card>
  );
};

export default memo(ScheduleForm);
