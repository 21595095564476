export const thaipostRegisterDescription = {
  EN: null,
  TH: [
    { body: "พิมพ์ใบปะหน้าพัสดุที่ได้จากระบบ และแปะลงบนหน้ากล่องพัสดุ" },
    { body: "หลังจากชำระค่าขนส่งเรียบร้อยแล้ว จะต้องจัดส่งภายใน 12 วัน" },
    { body: "น้ำหนักของพัสดุจะต้องไม่เกิน 2 กิโลกรัม" },
    { body: "ขนาดของพัสดุ (กว้าง + ยาว + สูง) จะต้องไม่เกิน 120 เซนติเมตร" },
    { body: "รายการที่ชำระค่าขนส่งแล้ว จะไม่สามารถยกเลิก แก้ไข หรือขอคืนเงินได้ ไม่ว่าจะกรณีใดๆ" },
    { body: "หากจัดส่งพัสดุแล้ว ให้ดำเนินการตีกลับจะไม่สามารถนำหมายเลขติดตามพัสดุ(Tracking Number) กลับมาใช้ได้อีก" },
    { body: "การเลือกน้ำหนักพัสดุที่ไม่ตรงกับความเป็นจริง ทางบริษัทฯ จะดำเนินการเรียกเก็บค่าขนส่งเพิ่มเติม" },
  ],
};
