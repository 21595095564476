import React, { FC, useMemo, useRef } from "react";
import { SortingRule, useSortBy, useExpanded, useFlexLayout } from "react-table";
import { useTranslation } from "react-i18next";
import { useSticky } from "react-table-sticky";
import Grid from "components/Grid";
import CircularProgress from "components/CircularProgress";
import ButtonToPlanUpgrade from "components/ButtonToPlanUpgrade";
import COLORS from "constants/Colors";
import Table from "components/Table";
import { Data } from "types/SalesReport";
import { getSubColumns } from "./getSubColumns";
import { TableWrapper } from "./TableWrapper";
import getColumns from "./getColumns";
import useExpandButton from "../hooks/useExpandButton";
import { GridLoading, Hidden, Typography, DisabledReportWrapper, DisabledReportBox } from "./styled";

type ReportTablePropsType = {
  isBlurContent?: boolean;
  onChangeSortBy?: (sortBys: SortingRule<{ id: string; desc: boolean }>[]) => void;
  onSelectAllRowInPage?: (ids: string[], isSelectedSomeRow: boolean) => void;
  onSelectRow?: (id: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  orderReports: any[];
  orderReportsLoading?: boolean;
  projectId: string;
  projectInfoLoading?: boolean;
  selectedIds: Record<string, boolean>;
  onClickEditDimensionAndWeight: Function;
  onClickCancelOrder: Function;
  state: string;
};

const ReportTable: FC<ReportTablePropsType> = ({
  isBlurContent,
  onChangeSortBy,
  onSelectAllRowInPage,
  onSelectRow,
  orderReports,
  orderReportsLoading,
  projectId,
  projectInfoLoading,
  selectedIds,
  onClickEditDimensionAndWeight,
  onClickCancelOrder,
  state,
}) => {
  const { t } = useTranslation();

  const tableHooks = useRef([useSortBy, useExpanded, useFlexLayout, useExpandButton, useSticky]);
  const tableColumns = useMemo(
    () =>
      getColumns(
        t,
        onClickCancelOrder,
        onClickEditDimensionAndWeight,
        state,
        projectId,
        onSelectRow,
        onSelectAllRowInPage,
      ),
    [t, onClickCancelOrder, onClickEditDimensionAndWeight, state, projectId, onSelectRow, onSelectAllRowInPage],
  );

  const customState = useMemo(() => ({ selectedIds }), [selectedIds]);

  const subTableColumns = useMemo(() => getSubColumns(t), [t]);
  const isLoading = orderReportsLoading || projectInfoLoading;

  return (
    <>
      <TableWrapper isBlurContent={isBlurContent}>
        {
          // render when orderReports are loading
          isLoading && (
            <GridLoading>
              <CircularProgress className="m-4" />
            </GridLoading>
          )
        }

        {isBlurContent && !isLoading && (
          <DisabledReportWrapper>
            <DisabledReportBox>
              <Typography variant="title2" color={COLORS.Dark} className="text-center mb-3">
                {t("salesReport.orderReports.disabled")}
              </Typography>
              {projectId && <ButtonToPlanUpgrade label={t("DisableFeature.button")} projectId={projectId} />}
            </DisabledReportBox>
          </DisabledReportWrapper>
        )}

        {/* We have to render table and hide table when there are not reports
            to prevent table reset all filter and sortBy state in the table. */}
        <Hidden className="ml-auto mr-auto" isHidden={orderReports.length === 0}>
          <Table<Data, Record<string, Data>>
            data={orderReports}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            columns={tableColumns as any}
            subTableColumns={subTableColumns}
            manualSortBy
            hooks={tableHooks.current}
            onChangeSortBy={onChangeSortBy}
            customState={customState}
            isBlurContent={isBlurContent}
          />
        </Hidden>

        {
          // render when no orderReports
          !orderReportsLoading && !projectInfoLoading && orderReports.length === 0 && isBlurContent && (
            <Grid container justify="center" className="mt-4">
              <Grid item xs={12}>
                <Typography variant="title2" color={COLORS.DarkLight} className="text-center">
                  {
                    // No sales reports
                    t("salesReport.orderReports.placeholder")
                  }
                </Typography>
              </Grid>
            </Grid>
          )
        }
      </TableWrapper>
    </>
  );
};

export default ReportTable;
