import React, { FC, useState, useContext, useEffect, MouseEvent, memo, useRef } from "react";
import get from "lodash/get";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
// import useSound from 'use-sound';
import { IcNotification } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";
import Grid from "components/Grid";
import Tooltip from "components/Tooltip";
import COLORS from "constants/Colors";

import { NOTIFICATIONS as NOTIFICATIONS_LINK, USER_SETTING, PROJECT } from "constants/Router";
import { UNSEEN_NOTIFICATIONS } from "graphql/notification/query";
import { SEEN_NOTIFICATION } from "graphql/notification/mutation";
import { NOTIFICATION_ADDED } from "graphql/notification/subscription";
import { NotificationInfo } from "types/Notification";
import { AppTitleContext } from "utils/context";
import useIsDesktop from "utils/hooks/useIsDesktop";

import { Popover, NotificationIconWrapper, Badge } from "./styled";
import NotificationList from "./NotificationListWithInfiniteScroll";
import NotificationSound from "../../audio/notify.mp3";
 
type NotificationPopoverPropsType = {
  projectId: string;
};

export const NotificationPopover: FC<NotificationPopoverPropsType> = ({ projectId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isDesktop = useIsDesktop();
  
  const { setUnseenNotification } = useContext(AppTitleContext);

  const [notificationData, setNotificationData] = useState<NotificationInfo[]>([]);

  const [unseenNumber, setUnseenNumber] = useState(0);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { data: unseenNotificationDataResponse } = useQuery(UNSEEN_NOTIFICATIONS, {
    variables: {
      projectId,
    },
  });

  const [seenNotification, { loading: seenNotificationLoading }] = useMutation(SEEN_NOTIFICATION);

  // const [play, { stop, isPlaying }] = useSound(NotificationSound);

  const { data: dataSubscription } = useSubscription(NOTIFICATION_ADDED, {
    variables: { projectId },
  });
  const handleSeen = async () => {
    if (projectId && !seenNotificationLoading && unseenNumber !== 0) {
      const seenNotificationResponse = await seenNotification({
        variables: {
          projectId,
        },
      });

      if (get(seenNotificationResponse, "data.seenNotification") === true) {
        setUnseenNumber(0); // set to zero by assume that all messages is read
        setUnseenNotification(0);
      }
    }
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (isDesktop) {
      setAnchorEl(event.currentTarget);
      handleSeen();
    } else {
      handleSeen();
      history.push(`/${PROJECT}/${projectId}/${USER_SETTING}/${NOTIFICATIONS_LINK}`);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const totalUnseenNotification = Number(get(unseenNotificationDataResponse, "unseenNotification"));
    setUnseenNumber(totalUnseenNotification);
    setUnseenNotification(totalUnseenNotification);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unseenNotificationDataResponse]);
  
  useEffect(() => {
    const notificationAdded = get(dataSubscription, "notificationAdded");

    if (notificationAdded) {
      if (notificationData && notificationData?.[0]) {
        setNotificationData([{ ...(notificationAdded as NotificationInfo) }, ...notificationData]);
      }
      notifySound();
      // if(isPlaying){
      //   play();
      // }
      const newUnseenNumber = unseenNumber + 1;
      setUnseenNumber(newUnseenNumber);
      setUnseenNotification(newUnseenNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSubscription]);
  const audioPlayer = new Audio(NotificationSound);
  const notifySound = () => {
    try{
      // if(audioPlayer.readyState >= 2){
        audioPlayer.play();
      // }
    }catch(e : any){
      console.log(e.message);
    }
  }

  const open = Boolean(anchorEl);
  const id = open ? "Notification-popover" : undefined;

  let IcNotificationColor = open ? COLORS.Primary : COLORS.DarkMed;

  if (!isDesktop) {
    IcNotificationColor = COLORS.White;
  } 

  return (
    <>
      <NotificationIconWrapper className="mx-2">
        <Tooltip title={t("NOTIFICATION") as string} placement="top-end" arrow>
          <Badge
            badgeContent={unseenNumber || 0}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <SvgIcon onClick={handleClick}>
              <IcNotification color={IcNotificationColor} />
            </SvgIcon>
          </Badge>
        </Tooltip>
      </NotificationIconWrapper>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grid container className="p-3">
          <NotificationList
            projectId={projectId}
            unseenNumber={unseenNumber}
            handleClose={handleClose}
            handleSeen={handleSeen}
            notificationData={notificationData}
            setNotificationData={setNotificationData}
          />
        </Grid>
      </Popover>
    </>
  );
};

export default memo(NotificationPopover);
