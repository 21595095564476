import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";

import Card from "components/Card";
import Typography from "components/Typography";
import Grid from "components/Grid";
import Input from "components/Form/Input";
import { SvgIcon } from "components/Icon";
import { IcInfo } from "components/SvgIcons";
import { ControllerNumberFormatCustomWithoutPrefix } from "components/NumberFormatCustom";

import COLORS from "constants/Colors";
import { ThaiBahtAdornment } from "../../DefaultShippingMethod/style";

type StoreManagementFeeProps = {
  storeManagementFee: number;
};

const StoreManagementFee: FC<StoreManagementFeeProps> = ({ storeManagementFee }) => {
  const { t } = useTranslation();

  return (
    <Card fullWidth padding="24px" className="mt-4">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="title7" color="dark">
            {t("shipping.shippop.storeManagementFee.title")}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container className="pt-4">
        <Grid item xs={12}>
          <Typography variant="body2" color="darkMed">
            {t("shipping.shippop.storeManagementFee.label")}
          </Typography>
        </Grid>
        <Grid item xs={12} className="pt-3">
          <Input
            name="storeManagementFee"
            defaultValue={storeManagementFee}
            variant="outlined"
            fullWidth
            placeholder={t("Price")}
            InputProps={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              inputComponent: ControllerNumberFormatCustomWithoutPrefix as any,
              startAdornment: <ThaiBahtAdornment />,
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className="pt-3">
        <Card noShadow bgColor={COLORS.Light} noBorder>
          <Grid container className="flex-nowrap">
            <Grid item className="pl-2 pr-4 d-flex flex-grow-0">
              <SvgIcon component={IcInfo} fontSize="small" htmlColor={COLORS.DarkMed} className="m-0 p-0" />
            </Grid>
            <Grid item className="d-flex flex-grow-1">
              <Typography variant="body3">{t("shipping.shippop.storeManagementFee.remark")}</Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Card>
  );
};

export default memo(StoreManagementFee);
