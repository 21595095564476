import React, { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button, { FloatingButton } from "components/Button";
import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import { SvgIcon } from "components/Icon";
import PageTitle from "components/PageTitle";
import { IcAdd, IcArrowToTop } from "components/SvgIcons";
import ScrollToTop from "components/ScrollToTop";
import StickyPanel, { StickyButtonContainer } from "components/StickyPanel";
import Typography from "components/Typography";

import { CATEGORY_LIMIT } from "config/category";
import { COLORS } from "constants/Colors";
import { DEFAULT_SCROLL } from "constants/Scroll";
import { ChangedDataValueType, CategoryType } from "types/Category";
import { ProjectIdType, MultiStoreInventoryType } from "types/Project";
import { Device } from "types/Device";
import useDevice from "utils/hooks/useDevice";

import { DEFAULT_CATEGORY_IMAGE } from "constants/Category";
import isEmpty from "lodash/isEmpty";
import useScrollToTopElement from "utils/hooks/useScrollToTopElement";
import useGetProject from "utils/hooks/useGetProject";
import ProductCatalogPreview from "./CategoryCatalogPreview";
import CategoryCard from "./CategoryCard";
import NewCategoryCard from "./NewCategoryCard";
import RemoveCategoryModal from "./RemoveCategoryModal";
import { useChangeCategories, useDeleteCategories, useUpdateCategory, useUpdateCategoryPriority } from "./hooks";
import { Wrapper } from "./styled";

const CategoriesContainer: FC = () => {
  const { t } = useTranslation();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [focusedId, setFocusedId] = useState<string>();
  const { projectId } = useParams<ProjectIdType>();
  const { multiStoreInventoryType } = useGetProject(projectId);
  const isClientStore = multiStoreInventoryType === MultiStoreInventoryType.CHILD;

  const device = useDevice();
  const isDesktop = device === Device.DESKTOP;
  const isTablet = device === Device.TABLET;
  const isMobile = device === Device.MOBILE;

  const {
    categories,
    categoriesAmount,
    editedCategories,
    handleAddNewCategory,
    handleChange,
    handleClearEditedCategory,
    handleClearNewCategory,
    loading,
    newCategory,
  } = useChangeCategories(projectId);

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const { handleUpdateCategory } = useUpdateCategory(projectId, handleClearEditedCategory);
  const { handleRemoveCategory } = useDeleteCategories(projectId, handleCloseModal);
  const { handleUpdateCategoryPriority } = useUpdateCategoryPriority(projectId, categories);
  const [isRemoveAllCategoryImage, setIsRemoveAllCategoryImage] = useState(false);

  const { scrollTopId, handleScrollToTop, isScroll } = useScrollToTopElement(
    DEFAULT_SCROLL.CATEGORY_SCROLL_TOP,
    isDesktop,
  );

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress className="m-4" />
      </Grid>
    );
  }

  const addButtonClassName = categoriesAmount < CATEGORY_LIMIT ? "" : "d-none";

  const handleAskForRemoveCategory = (categoryId: string) => {
    setIsOpenModal(true);
    setFocusedId(categoryId);
  };

  return (
    <Wrapper id={scrollTopId}>
      <Grid item container xs={12} className="pt-4">
        <Grid item className="pt-1">
          <PageTitle title={t("Categories")} className="py-0" />
        </Grid>
        {!isMobile && (
          <Grid item className="pt-2" xs={2}>
            <Typography variant={isDesktop || isTablet ? "body2" : "body4"} color="dark">
              {`${categoriesAmount} / ${CATEGORY_LIMIT}`}
            </Typography>
          </Grid>
        )}
        {!isClientStore && (
          <Grid item xs justify="flex-end" alignItems="center" alignContent="center" className="d-flex px-3">
            <Button color="primary" size="medium" className="mx-2" onClick={handleAddNewCategory}>
              <SvgIcon component={IcAdd} fontSize="small" />
              {isDesktop || isTablet ? t("category.button.addNewCategory") : t("Add")}
            </Button>
          </Grid>
        )}
      </Grid>
      {isMobile && (
        <Grid container>
          <Grid item>
            <Typography className="px-3 pb-3 pt-1" variant={isDesktop || isTablet ? "body2" : "body4"} color="dark">
              {`${categoriesAmount} / ${CATEGORY_LIMIT}`}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Typography variant="body3" color="darkMed" className="px-3 py-3">
        {t("Product catalog preview")}
      </Typography>

      <ProductCatalogPreview categories={categories || []} onChange={handleUpdateCategoryPriority} />

      <Grid container className="pt-4 px-3">
        {categories &&
          categories.map((category, index) => {
            const { id, isActive, isHomePageCategory } = category;
            const editedCategory = editedCategories.find((tempCategory) => tempCategory.id === id);
            const patchedCategory = { ...category, ...editedCategory };
            const updatedCategoryData =
              isHomePageCategory && isRemoveAllCategoryImage && isEmpty(editedCategory?.image)
                ? { ...editedCategory, image: DEFAULT_CATEGORY_IMAGE }
                : editedCategory;

            return (
              <Grid container key={id} item xs={12} md={6} className="p-2">
                <CategoryCard
                  isClientStore={isClientStore}
                  dataCy={`categoryCard${index}`}
                  category={patchedCategory}
                  onClickDelete={() => handleAskForRemoveCategory(id)}
                  onChangeData={(fieldName: keyof CategoryType, value: ChangedDataValueType) =>
                    handleChange(id, fieldName, value)
                  }
                  onClickCancel={() => {
                    handleClearEditedCategory(id);
                  }}
                  onClickUpdate={(callback?: Function) => {
                    handleUpdateCategory(id, updatedCategoryData, callback);
                    setIsRemoveAllCategoryImage(false);
                  }}
                  onToggleIsActive={() => {
                    handleUpdateCategory(id, { isActive: !isActive });
                  }}
                  setIsRemoveAllCategoryImage={() => setIsRemoveAllCategoryImage(true)}
                />
              </Grid>
            );
          })}
        {newCategory && (
          <NewCategoryCard
            projectId={projectId}
            category={newCategory}
            handleClearNewCategory={handleClearNewCategory}
            onChange={handleChange}
          />
        )}
      </Grid>

      <StickyPanel>
        <Grid container direction="column" className="p-3">
          <StickyButtonContainer className="px-2 py-3">
            {!isDesktop && isScroll && (
              <Grid item>
                <FloatingButton className={addButtonClassName} onClick={handleScrollToTop} data-cy="addButton">
                  <SvgIcon
                    className="mb-1 mt-3 ml-3"
                    component={IcArrowToTop}
                    fontSize="default"
                    htmlColor={COLORS.DarkMed}
                  />
                </FloatingButton>
              </Grid>
            )}
            <Grid item>
              <ScrollToTop buttonColor="darkGray" />
            </Grid>
          </StickyButtonContainer>
        </Grid>
      </StickyPanel>

      <RemoveCategoryModal
        isOpen={isOpenModal}
        onClose={handleCloseModal}
        onRemove={() => handleRemoveCategory(focusedId || "")}
      />
    </Wrapper>
  );
};

export default CategoriesContainer;
