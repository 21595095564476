import sortBy from "lodash/sortBy";
import { useQuery } from "@apollo/client";
import { OptionType, OptionsType } from "@atlaskit/select";

import { ALL_CATEGORY } from "graphql/category/query";
import { CategoryType, CategoryConnectionType } from "types/Category";

type UseSaveCategoriesPropsType = {
  projectId: string;
};

const useGetCategories = (props: UseSaveCategoriesPropsType) => {
  const { projectId } = props;

  const { loading, data } = useQuery<CategoryConnectionType>(ALL_CATEGORY, {
    variables: {
      projectId,
    },
  });

  const categoriesResults = (data && sortBy(data.categories.results, ["name"])) || [];

  const convertCategoriesToOptionFormat = (categoriesData: CategoryType[]) => {
    if (categoriesData && categoriesData.length) {
      return categoriesData.map((category: CategoryType) => ({
        label: category.name,
        img: category.image,
        value: category.id,
      }));
    }
    return [];
  };

  const findCategoriesByCategoryId = (categoryId: number) =>
    categoriesResults &&
    categoriesResults.find((findCategory: CategoryType) => Number(findCategory.id) === Number(categoryId));

  const convertCategoriesValueToOptionFormat = (categoriesData: number[]) => {
    const newOptionsValue = categoriesData.map((categoryId: number) => {
      const findCategory = findCategoriesByCategoryId(categoryId);
      return {
        label: findCategory && findCategory.name,
        img: findCategory && findCategory.image,
        value: categoryId,
      };
    });

    return (newOptionsValue as OptionsType<OptionType>) || [];
  };

  return {
    loading,
    categoriesOptions: convertCategoriesToOptionFormat(categoriesResults || []),
    categoriesResults: (data && sortBy(data.categories.results, ["name"])) || [],
    convertCategoriesValueToOptionFormat,
  };
};

export default useGetCategories;
