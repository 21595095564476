import React, { FC, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT, EXPIRY_DATE_FORMAT } from "constants/DateTimeFormat";
import { COLORS } from "constants/Colors";
import { CONSOLE_GENERATED_FORM } from "constants/i18n";
import TextField from "components/TextField";
import DatePicker from "components/DatePicker";
import Grid from "components/Grid";
import { NumberFormatCustomWithoutPrefix } from "components/NumberFormatCustom";
import { DigitalContentAvailability } from "types/Product";
import moment from "moment";

type ExpiryDateInputPropsType = {
  availabilityType: DigitalContentAvailability;
  defaultValue: string;
  onChangeExpiryDate: (SKUId: string, date: string | number) => void;
  SKUId: string;
};

const ExpiryDateInput: FC<ExpiryDateInputPropsType> = ({
  availabilityType,
  defaultValue,
  SKUId,
  onChangeExpiryDate,
}) => {
  const { t } = useTranslation(CONSOLE_GENERATED_FORM);
  const handleChangeExpiryDay = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeExpiryDate(SKUId, Number(event.target.value));
  };

  const handleChangeExpiryDate = (date: string) => {
    onChangeExpiryDate(SKUId, moment(date).format(EXPIRY_DATE_FORMAT));
  };

  switch (availabilityType) {
    case DigitalContentAvailability.PERIOD_AFTER_PURCHASED:
      return (
        <Grid container alignItems="center" item xs={12} md={6}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={defaultValue}
              onChange={handleChangeExpiryDay}
              variant="outlined"
              placeholder={t("setLengthOfTime")}
              InputProps={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputComponent: NumberFormatCustomWithoutPrefix as any,
              }}
              style={{ backgroundColor: COLORS.White }}
            />
            <span style={{ position: "absolute", right: "30px", padding: "14px" }}>{t("days")}</span>
          </Grid>
        </Grid>
      );

    case DigitalContentAvailability.EXPIRY_DATE:
      return (
        <Grid container alignItems="center" item xs={12} md={6}>
          <Grid item xs={12}>
            <DatePicker
              disablePast
              fullWidth
              selectedDate={defaultValue}
              setDate={handleChangeExpiryDate}
              format={DATE_FORMAT}
            />
          </Grid>
        </Grid>
      );

    default:
      return null;
  }
};

export default ExpiryDateInput;
