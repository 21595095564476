import React, { FC } from "react";
import FlexMessage from "./FlexMessage";
import { CarouselContainer, CarouselProductContent } from "./FlexMessage.style";

type CarouselType = {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: string | any;
};

type FlexCarouselPropsType = {
  content: CarouselType[];
};

const FlexCarousel: FC<FlexCarouselPropsType> = (props: FlexCarouselPropsType) => {
  const { content } = props;
  return (
    <CarouselContainer displayScrollbar>
      {content.map(({ value: data }, index: number) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <CarouselProductContent key={index}>
            <FlexMessage content={data} />
          </CarouselProductContent>
        );
      })}
    </CarouselContainer>
  );
};
export default FlexCarousel;
