import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import Typography from "components/Typography";
import { PopoverMenu } from "components/Menu";
import { IcPreference } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";

import { DATE_TIME_WALLET_FORMAT } from "constants/DateTimeFormat";
import { COLORS } from "constants/Colors";
import { TRANSACTION_TYPES } from "constants/Wallet";
import { CreditTransaction } from "types/Wallet";
import { convertPriceFormat } from "utils/common";

import { HistoryItem, TextButton, FilterIconWrapper } from "../styled";
import useTransactionData from "../hooks/useTransactionData";

type WalletTransactionPropsType = {
  projectId: string;
};

const WalletTransaction: FC<WalletTransactionPropsType> = (props) => {
  const { t } = useTranslation();
  const { projectId } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const {
    transactions,
    isLoading,
    isLoadMore,
    handleFetchMore,
    transactionFilterList,
    handleSelectFilter: onSelectFilter,
  } = useTransactionData({ projectId });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectFilter = (index: number) => {
    onSelectFilter(index);
    handleClose();
  };

  const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Grid container className="pt-0 px-4 pb-4">
      <Grid item container justify="space-between" alignItems="baseline" className="py-2">
        <Grid item xs={6}>
          <Typography variant="title8" color={COLORS.DarkLight}>
            {t("wallet.history.title")}
          </Typography>
        </Grid>
        {!!transactionFilterList.length && (
          <>
            <Grid item>
              <FilterIconWrapper onClick={handleClickFilter}>
                <SvgIcon component={IcPreference} fontSize="small" htmlColor={COLORS.DarkMed} className="m-0 p-0" />
              </FilterIconWrapper>
            </Grid>
            <PopoverMenu keepMounted anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              <Grid container direction="column" className="pr-5">
                {transactionFilterList.map((monthFilter: string, index: number) => {
                  return (
                    <Grid item className="p-3" key={monthFilter} onClick={() => handleSelectFilter(index)}>
                      <TextButton variant="title8" color={COLORS.DarkGray}>
                        {monthFilter}
                      </TextButton>
                    </Grid>
                  );
                })}
              </Grid>
            </PopoverMenu>
          </>
        )}
      </Grid>
      {isLoading ? (
        <CircularProgress className="m-4" />
      ) : (
        transactions.map((transaction: CreditTransaction) => {
          const { id, transactionType, transactionId, refId, createdAt, value } = transaction;

          const valueWithoutSign = Math.abs(value);
          const isPositiveValue = value > 0;
          const sign = isPositiveValue ? "+" : "-";

          return (
            <HistoryItem item container justify="space-between" key={id}>
              <Grid item container xs={7}>
                <Grid item container alignItems="baseline">
                  <Grid item className="pr-1 pb-1">
                    <Typography variant="title8" color="dark">
                      {t(TRANSACTION_TYPES[transactionType])}
                    </Typography>
                  </Grid>
                  <Grid item className="pb-1">
                    <Typography variant="title8" color="primary">
                      {refId}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="body4" color={COLORS.DarkGray}>
                    {moment(createdAt).format(DATE_TIME_WALLET_FORMAT)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={5} alignItems="flex-end" direction="column">
                <Grid item>
                  <Typography variant="title8" color={isPositiveValue ? "primary" : "dark"}>
                    {sign} {t("THB")}
                    {convertPriceFormat(valueWithoutSign)}
                  </Typography>
                </Grid>
                <Grid item className="pt-1">
                  <Typography variant="body4" color={COLORS.DarkGray}>
                    {transactionId}
                  </Typography>
                </Grid>
              </Grid>
            </HistoryItem>
          );
        })
      )}
      {isLoadMore && (
        <Grid item container justify="center" className="pt-4">
          <Grid item onClick={handleFetchMore}>
            <TextButton variant="body4" color="primary">
              {t("wallet.history.loadMore")}
            </TextButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default WalletTransaction;
