import React, { FC } from "react";
import { SvgIcon } from "components/Icon";
import { IcLongArrowDown, IcLongArrowUp, IcSubtract, IcAdd } from "components/SvgIcons";

import { STAT_WIDGET_TYPE } from "constants/Stat";

type StatIconPropsType = {
  type: string;
  value: number;
  color: string;
};

const StatIcon: FC<StatIconPropsType> = ({ type, value, color }) => {
  if (value === 0) {
    return null;
  }
  if (type === STAT_WIDGET_TYPE.CUSTOMER && value > 0) {
    return <SvgIcon className="pb-0" component={IcAdd} fontSize="small" htmlColor={color} />;
  }
  if (value > 0) {
    return <SvgIcon className="p-0" component={IcLongArrowUp} htmlColor={color} />;
  }
  if (value < 0) {
    return <SvgIcon className="p-0" component={IcLongArrowDown} htmlColor={color} />;
  }
  return <SvgIcon className="pb-0" component={IcSubtract} fontSize="small" htmlColor={color} />;
};

export default StatIcon;
