import * as React from "react";

const IcTag = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="-5 -5 28 28" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.074 2.65c.58 0 1.05.47 1.05 1.05v4.397c0 .279-.11.546-.308.743l-5.253 5.252c-.41.41-1.075.41-1.485 0l-.09-.089 5.818-5.817c.172-.171.268-.404.268-.646zm-2.1-1.05c.541 0 .987.41 1.044.936l.006.114v4.398c0 .233-.077.457-.217.64l-.09.104-5.726 5.724c-.378.378-.974.407-1.386.087l-.1-.087-4.397-4.399C.729 8.738.7 8.143 1.02 7.731l.088-.1 5.725-5.723c.164-.164.377-.269.604-.299l.138-.009h4.398zM9.92 3.7c-.554 0-1.003.45-1.003 1.004s.45 1.003 1.003 1.003c.554 0 1.003-.45 1.003-1.003 0-.554-.45-1.003-1.003-1.003z"
      transform="translate(-273.000000, -300.000000) translate(258.000000, 284.000000) translate(15.000000, 16.000000)"
    />
  </svg>
);

export default IcTag;
