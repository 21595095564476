import React from "react";
import styled from "styled-components";

export const Bullet = styled(({ color: _color, ...rest }) => <span {...rest} />).attrs(({ color }) => {
  return { color };
})`
  min-width: 6px;
  min-height: 6px;
  max-width: 6px;
  max-height: 6px;
  background-color: ${({ color }) => color};
  border-radius: 10px;
  display: inline-block;
  vertical-align: middle;
  margin: 6px;
`;

export const OrderNo = styled.span`
  font-weight: 500;
  /* unvisited link */
  a:link {
    color: ${({ theme }) => theme.COLORS.Dark};
  }

  /* visited link */
  a:visited {
    color: ${({ theme }) => theme.COLORS.Dark};
  }

  /* mouse over link */
  a:hover {
    color: ${({ theme }) => theme.COLORS.DarkMed};
  }

  /* selected link */
  a:active {
    color: ${({ theme }) => theme.COLORS.Dark};
  }
`;

export const Status = styled(({ color: _color, ...rest }) => <span {...rest} />).attrs(({ color }) => {
  return { color };
})`
  font-weight: 500;
  text-transform: capitalize;
  color: ${({ color }) => color};
`;

export const Thumbnail = styled.img`
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
`;

export const ThumbnailWrapper = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
  margin: 3px 0%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
