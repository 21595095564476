import * as React from "react";

const SvgIcArrowGlyphUp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.415 20.35l9.826-11.464a1 1 0 011.518 0l9.826 11.463a1 1 0 01-.76 1.651H6.176a1 1 0 01-.76-1.65z"
    />
  </svg>
);

export default SvgIcArrowGlyphUp;
