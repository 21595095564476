import * as React from "react";

const SvgIcHide = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M.021 16.348c.02.215.106.414.257.578 1.07 1.892 5.216 6.952 10.8 8.908l-.575 1.03a1.006 1.006 0 101.758.982L24.181 6.5a1.006 1.006 0 10-1.759-.981l-.908 1.627c-5.069-2.208-10.311-1.49-15.216 2.11-3.812 2.798-6.05 6.318-6.144 6.466-.02.031-.021.067-.037.1a.98.98 0 00-.057.149.993.993 0 00-.044.22c-.003.027-.016.05-.016.078 0 .018.015.055.021.08zm15.872-7.362a6.24 6.24 0 013.852 1.324l-6.053 10.84c-2.413-.904-4.085-3.236-4.085-5.879a6.292 6.292 0 016.286-6.285zm8.843 1.177a1.01 1.01 0 011.417-.151c1.826 1.474 3.65 3.366 5.419 5.625.024.03.03.066.05.098a.997.997 0 01.067.135c.03.07.056.14.07.213a.94.94 0 01.012.18.971.971 0 01-.012.183.99.99 0 01-.069.21.837.837 0 01-.069.137c-.02.031-.026.068-.049.097-4.543 5.797-9.311 9.043-14.176 9.648a1.008 1.008 0 01-.249-1.999c4.146-.516 8.294-3.302 12.338-8.27-1.516-1.862-3.06-3.448-4.597-4.69-.433-.35-.5-.983-.152-1.416zm-14.583-.872a8.27 8.27 0 00-2.56 5.98c0 3.385 2.075 6.389 5.107 7.657l-.618 1.107c-5.11-1.63-8.747-6.22-9.857-7.773.97-1.352 3.834-4.973 7.928-6.971z"
    />
  </svg>
);

export default SvgIcHide;
