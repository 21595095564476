import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { SvgIcon } from "components/Icon";
import { Tab as TabType } from "types";
import { Theme } from "types/Theme";
import { ProjectIdType } from "types/Project";
import { ThemeContext } from "utils/context/ThemeContext";
import useTabWithLink from "utils/hooks/useTabWithLink";
import { TabMenu, TabIcon, NoticeBadge, TabHeaderContainer, TabContainer, TabHeader } from "./styled";

type TabPropsType = {
  tabConfig: TabType.TabConfig;
  theme?: Theme;
  isSubTab?: boolean;
  path: string;
  pathIndex?: number;
};

const TabWithLink: FC<TabPropsType> = ({ tabConfig, path, isSubTab = false, pathIndex, children }) => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const { theme } = useContext(ThemeContext);
  const { tabIndex, handleTabChange } = useTabWithLink(tabConfig.header, path, projectId, pathIndex);

  return (
    <TabContainer>
      <TabHeaderContainer>
        <TabHeader
          value={tabIndex}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: isSubTab ? theme.DarkMed : theme.Primary,
            },
          }}
          variant="scrollable"
          scrollButtons="off"
          aria-label="scrollable auto tabs"
          isSubTab={isSubTab}
          className="tabHeader"
          data-cy={`menu-${path}`}
        >
          {tabConfig.header.map((element, idx) => {
            return (
              <TabMenu
                key={element.id}
                data-cy={element.id}
                isActive={tabIndex === idx}
                isSubTab={isSubTab}
                disabled={element.disabled}
                label={
                  <div className="d-flex flex-row w-100 align-items-center">
                    <TabIcon>
                      {element.svgIcon && <SvgIcon className="mr-2" component={element.svgIcon} fontSize="small" />}
                    </TabIcon>
                    <span className="text-left">{t(element.title)}</span>
                    {Boolean(element.badgeNo) && <NoticeBadge>{element.badgeNo}</NoticeBadge>}
                  </div>
                }
              />
            );
          })}
        </TabHeader>
      </TabHeaderContainer>
      {children}
    </TabContainer>
  );
};

export default TabWithLink;
