import React, { FC } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, ScaleType, Tooltip, ResponsiveContainer } from "recharts";

import { ChartDataType } from "types/Chart";

import CustomTooltip from "../Tooltip";

type AreaChartComponentPropsType = {
  data: ChartDataType[];
  color: string;
  scale?: ScaleType;
  height?: number;
  width?: number;
  tickFormatter?: (value: number) => string;
};

const AreaChartComponent: FC<AreaChartComponentPropsType> = (props) => {
  const { color, data, scale, height, width, tickFormatter } = props;

  return (
    <ResponsiveContainer width={width || "100%"} height={height || 400}>
      <AreaChart
        data={data}
        margin={{
          top: 20,
          right: 10,
          bottom: 20,
        }}
      >
        <defs>
          <linearGradient id="colorArea" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={color} stopOpacity={0.8} />
            <stop offset="95%" stopColor={color} stopOpacity={0.05} />
          </linearGradient>
        </defs>
        <CartesianGrid vertical={false} />
        <XAxis
          axisLine={false}
          tickMargin={10}
          dataKey="xAxis"
          scale={scale || "auto"}
          padding={{ right: 15, left: 5 }}
          tickFormatter={tickFormatter}
          type="number"
          domain={["dataMin", "dataMax"]}
        />
        <YAxis axisLine={false} tickMargin={10} tickLine={false} />
        <Tooltip content={<CustomTooltip />} />
        <Area dataKey="yAxis" fill="url(#colorArea)" stroke={color} type="monotone" />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChartComponent;
