import React from "react";
import { CellProps } from "react-table";
import { Link } from "react-router-dom";
import styled from "styled-components";

const OrderNo = styled.span`
  font-weight: 500;
  /* unvisited link */
  a:link {
    color: ${({ theme }) => theme.COLORS.Dark};
  }

  /* visited link */
  a:visited {
    color: ${({ theme }) => theme.COLORS.Dark};
  }

  /* mouse over link */
  a:hover {
    color: ${({ theme }) => theme.COLORS.DarkMed};
  }

  /* selected link */
  a:active {
    color: ${({ theme }) => theme.COLORS.Dark};
  }
`;

const getOrderNo = <T extends object>(t: Function, projectId?: string) => ({
  Header: t("Order no."),
  accessor: "orderNumber",
  // eslint-disable-next-line react/display-name
  Cell: (cell: CellProps<T>) => (
    <OrderNo>
      {projectId ? <Link to={`/project/${projectId}/orders/${cell.value}`}>{cell.value}</Link> : cell.value}
    </OrderNo>
  ),
});

export default getOrderNo;
