import { gql } from "@apollo/client";

export const UPDATE_INVENTORY = gql`
  mutation updateInventory($projectId: ID!, $inventories: [InputInventory]!) {
    updateInventory(projectId: $projectId, inventories: $inventories) {
      productSKUId
      value
    }
  }
`;

export const IMPORT_PRODUCT_INVENTORIES = gql`
  mutation importProductInventories($projectId: ID!, $file: Upload!, $type: UpdateInventoryType!) {
    importProductInventories(projectId: $projectId, file: $file, type: $type)
  }
`;
