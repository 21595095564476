import { ApolloError, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";

import { ProjectConfigurationType } from "types/Setting";
import { SAVE_PROJECT_SETTING } from "graphql/setting/mutation";
import { removeTypenameField } from "utils/common";

type UseUpdateSettingPropsType = {
  projectId: string;
  configurationData: ProjectConfigurationType;
  primaryColor: string;
  secondaryColor: string;
  onUpdateComplete: () => void;
};

const useUpdateSetting = (props: UseUpdateSettingPropsType) => {
  const { t } = useTranslation();
  const { projectId, configurationData, primaryColor, secondaryColor, onUpdateComplete } = props;

  const [saveThemeConfig] = useMutation(SAVE_PROJECT_SETTING, {
    onError: (error: ApolloError) => {
      toast.error(`${t("Update failed!")}, ${error}`, {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 3000,
      });
    },
    onCompleted: () => {
      toast.success(t("Update successfully"), {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 1000,
        onClose: () => {},
      });
      onUpdateComplete();
    },
  });

  const handleSave = () => {
    const configurationDataWithoutTypename = removeTypenameField(configurationData) as ProjectConfigurationType;

    const configuration = {
      ...configurationDataWithoutTypename,
      theme: {
        color: {
          primary: primaryColor,
          secondary: secondaryColor,
        },
      },
    };

    const variables = {
      projectId,
      projectInput: {
        configuration,
      },
    };

    saveThemeConfig({ variables });
  };

  return {
    handleSave,
  };
};

export default useUpdateSetting;
