import { useEffect, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import get from "lodash/get";

import { INVOICE_RECEIPT } from "graphql/invoice/query";
import { notifyError } from "utils/notify";
import { useTranslation } from "react-i18next";

const useGetInvoiceReceipt = (projectId: string) => {
  const previousSelectedInvoiceNumber = useRef<string>();
  const { t } = useTranslation();
  const [getInvoiceReceipt, { data, loading }] = useLazyQuery(INVOICE_RECEIPT, {
    fetchPolicy: "network-only",
    onError(error) {
      notifyError(t("Please try again later"));
      console.error(error);
    },
  });

  const invoiceReceiptLink = get(data, "shopReceiptByInvoiceNumber");

  const handleClickInvoiceNumber = async (invoiceNumber: string) => {
    if (!invoiceNumber) {
      return;
    }

    if (previousSelectedInvoiceNumber.current === invoiceNumber) {
      window.open(invoiceReceiptLink);
      return;
    }

    previousSelectedInvoiceNumber.current = invoiceNumber;

    getInvoiceReceipt({
      variables: {
        projectId,
        invoiceNumber,
      },
    });
  };

  useEffect(() => {
    if (!loading && invoiceReceiptLink) {
      window.open(invoiceReceiptLink);
    }
  }, [loading, invoiceReceiptLink]);

  return {
    onClickInvoiceNumber: handleClickInvoiceNumber,
    loading,
  };
};

export default useGetInvoiceReceipt;
