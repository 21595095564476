import React, { FC, useState } from "react";
import get from "lodash/get";
import size from "lodash/size";
import { useTranslation } from "react-i18next";

import { OptionsType, OptionType, ActionMeta } from "@atlaskit/select";
import { FieldError, DeepMap } from "react-hook-form";

import COLORS from "constants/Colors";
import { SUPPORTED_LANGUAGE } from "constants/Language";
import { MAXIMUM_SHIPPING_COST } from "constants/Limit";

import Grid from "components/Grid";
import Card, { CardPropsType } from "components/Card";
import Typography from "components/Typography";
import MultiSelectWithSearchBox from "components/MultiSelectWithSearchBox";
import TextField from "components/TextField";
import Button from "components/Button";
import ConfirmationModal from "components/ConfirmationModal";
import { NumberFormatCustomWithoutPrefix } from "components/NumberFormatCustom";
import { IcDelete } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";

import { DistrictType, ProvinceType, SettingsPostalCodeType, PostalCodesWithCustomPriceType } from "types/Shipping";

import { ThaiBahtAdornment } from "../../DefaultShippingMethod/style";
import { EditShippingMethodFormData } from "../../EditShippingMethodForm";
import {
  getFullNameFromProvinceCode,
  getProvinceObjectByProvinceName,
  convertProvinceListToOptionFormat,
  convertDistrictObjectToOptionFormat,
} from "../util";
import { CustomRateDistrict } from "./CustomRateDistrict";

type CustomRateProvincePropsType = {
  customRateProvinces: SettingsPostalCodeType[];
  optionsProvinceValue: OptionsType<OptionType>;
  handleChangeBasePriceProvince: (provinceCode: string, price: number) => void;
  handleChangeSelectProvince: (_option: OptionsType<OptionType>, action: ActionMeta<OptionType>) => void;
  handleAddCustomRatePostcode: (postalCode: string, provinceCode: string, price: number) => void;
  handleEditCustomRatePostcode: (selectIndex: number, postalCode: string, provinceCode: string, price: number) => void;
  handleDeleteCustomRatePostcode: (selectIndex: number, provinceCode: string) => void;
  handleDeleteProvinceSetting: (provinceCode: string) => void;
  errors: DeepMap<EditShippingMethodFormData, FieldError>;
  cardProps?: CardPropsType;
};

export const CustomRateProvince: FC<CustomRateProvincePropsType> = (props) => {
  const { t, i18n } = useTranslation();
  const isThaiLanguage = i18n.language === SUPPORTED_LANGUAGE.TH;
  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(false);
  const [selectDeleteProvinceName, setSelectDeleteProvinceName] = useState("");

  const {
    customRateProvinces,
    optionsProvinceValue,
    handleChangeBasePriceProvince,
    handleChangeSelectProvince,
    handleAddCustomRatePostcode,
    handleEditCustomRatePostcode,
    handleDeleteCustomRatePostcode,
    handleDeleteProvinceSetting,
    errors,
    cardProps,
  } = props;

  const customRateProvincesForOptionFilterActive = (customRateProvinces as SettingsPostalCodeType[]).filter(
    (customRateProvince: SettingsPostalCodeType) => customRateProvince.isActive,
  );

  const customRateProvincesForOptionValue = (customRateProvincesForOptionFilterActive as SettingsPostalCodeType[]).map(
    (customRateProvince: SettingsPostalCodeType) => customRateProvince.name,
  );

  const handleFixedPriceChange = (event: React.ChangeEvent<HTMLInputElement>, provinceName: string) => {
    const value = event.target.value as string;
    const newValueNumber = +parseFloat(value).toFixed(2) || 0;

    if (newValueNumber > -1 && newValueNumber < MAXIMUM_SHIPPING_COST) {
      handleChangeBasePriceProvince(provinceName, newValueNumber);
    }
  };

  return (
    <>
      <Card fullWidth className="mt-4 px-4" {...cardProps}>
        <Grid item xs={12} className="my-3">
          <Typography variant="body2" color="darkMed">
            {t("shipping.locationSetting")}
          </Typography>
        </Grid>

        <Grid item xs={12} className="mb-2">
          <MultiSelectWithSearchBox
            isMulti
            label={t("SelectProvinces")}
            placeholder={`${t("Select")} ${t("broadcast.location")}`}
            options={optionsProvinceValue}
            value={convertProvinceListToOptionFormat(customRateProvincesForOptionValue, isThaiLanguage)}
            onChange={(option: OptionsType<OptionType>, action: ActionMeta<OptionType>) => {
              handleChangeSelectProvince(option || [], action);
            }}
          />
        </Grid>

        {customRateProvinces.map((customRateProvince: SettingsPostalCodeType, provinceIndex: number) => {
          const provinceList: ProvinceType | never[] = getProvinceObjectByProvinceName(customRateProvince.name) || [];
          const districtList: DistrictType[] | never[] = get(provinceList, "districts") || [];
          const optionsDistrict: OptionsType<OptionType> =
            convertDistrictObjectToOptionFormat(districtList, isThaiLanguage) || [];
          const postalCodesList =
            (customRateProvince.postalCodes &&
              customRateProvince.postalCodes.map(
                (postalCodeItem: PostalCodesWithCustomPriceType) => postalCodeItem.postalCode,
              )) ||
            [];

          const optionsDistrictNoDuplicatedSelect = optionsDistrict.filter(
            (district) => !postalCodesList.includes(district.value.toString()),
          );

          return (
            <>
              {customRateProvince.isActive && (
                <Card noShadow key={customRateProvince.name} fullWidth className="mt-3">
                  <Grid container className="mb-2">
                    <Grid
                      container
                      justify="space-between"
                      alignItems="center"
                      alignContent="center"
                      item
                      xs={12}
                      className="mb-2"
                    >
                      <Typography variant="title8" className="font-weight-bold" color="dark">
                        {getFullNameFromProvinceCode(customRateProvince.name, isThaiLanguage)}
                      </Typography>
                      <Button
                        color="secondary"
                        onClick={() => {
                          setIsOpenConfirmDeleteModal(true);
                          setSelectDeleteProvinceName(customRateProvince.name);
                        }}
                      >
                        <SvgIcon className="ml-1" component={IcDelete} fontSize="small" htmlColor={COLORS.DarkMed} />
                        <Typography variant="body3">{t("shipping.Delete")}</Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body4" className="pt-1">
                        {t("shipping.setAFlatRateFor")}{" "}
                        {getFullNameFromProvinceCode(customRateProvince.name, isThaiLanguage)}
                      </Typography>
                    </Grid>
                    <Grid container item xs={12} className="mt-2" alignItems="center" alignContent="center">
                      <Grid item xs={6} className="pr-2">
                        <Card noShadow fullWidth padding="14px">
                          <Typography variant="body3" className="">
                            {`${
                              optionsDistrict.length - size(customRateProvince && customRateProvince.postalCodes)
                            } ${t("PostalCode")}`}
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          InputProps={{
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            inputComponent: NumberFormatCustomWithoutPrefix as any,
                            startAdornment: <ThaiBahtAdornment />,
                          }}
                          value={customRateProvince.price}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            handleFixedPriceChange(event, customRateProvince.name)
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Card noShadow fullWidth className="mt-3">
                    <Typography variant="body4" className="pt-1">
                      {t("shipping.ChooseDistricts")}
                    </Typography>

                    {customRateProvince.postalCodes &&
                      customRateProvince.postalCodes.map(
                        (postalCodeItem: PostalCodesWithCustomPriceType, postalCodeIndex: number) => {
                          return (
                            <CustomRateDistrict
                              // eslint-disable-next-line react/no-array-index-key
                              key={`${postalCodeItem.postalCode}${postalCodeIndex}`}
                              postalCodeIndex={postalCodeIndex}
                              provinceIndex={provinceIndex}
                              provinceName={customRateProvince.name}
                              price={postalCodeItem.price}
                              postalCode={postalCodeItem.postalCode}
                              optionsDistrictValue={optionsDistrictNoDuplicatedSelect}
                              handleEditCustomRatePostcode={handleEditCustomRatePostcode}
                              handleDeleteCustomRatePostcode={handleDeleteCustomRatePostcode}
                              errors={errors}
                            />
                          );
                        },
                      )}

                    <Grid container item xs={12} className="mt-3">
                      <Button
                        disabled={size(customRateProvince.postalCodes) === optionsDistrict.length}
                        fullWidth
                        color="secondary"
                        onClick={() => handleAddCustomRatePostcode("", customRateProvince.name, 0)}
                      >
                        <Typography variant="body3" className="pt-1" color="inherit">
                          {t("shipping.addMoreRates")}
                        </Typography>
                      </Button>
                    </Grid>
                  </Card>
                </Card>
              )}
            </>
          );
        })}
      </Card>

      <ConfirmationModal
        title={`${t("Are you sure to delete this")} ?`}
        isOpen={isOpenConfirmDeleteModal}
        onClose={() => setIsOpenConfirmDeleteModal(false)}
        onSubmit={() => {
          if (selectDeleteProvinceName) {
            handleDeleteProvinceSetting(selectDeleteProvinceName);
            setIsOpenConfirmDeleteModal(false);
          }
        }}
        textSubmit={t("shipping.Delete")}
      />
    </>
  );
};
