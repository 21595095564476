import React, { FC, HTMLAttributes } from "react";
import CircularProgress from "components/CircularProgress";
import Button from "components/Button";
import Grid from "components/Grid";

type FormButtonPropsType = {
  cancelLabel: string;
  isDisabledSubmit?: boolean;
  isLoading?: boolean;
  onCancel: () => void;
  submitLabel: string;
};

const FormButton: FC<FormButtonPropsType & HTMLAttributes<HTMLDivElement>> = ({
  cancelLabel,
  className,
  isDisabledSubmit = false,
  isLoading = false,
  onCancel,
  submitLabel,
}) => (
  <Grid container item xs={12} alignItems="flex-end" justify="space-between" className={className}>
    <Grid item xs={6} md="auto" className="pr-2">
      <Button fullWidth color="ghost" onClick={onCancel}>
        {cancelLabel}
      </Button>
    </Grid>

    <Grid item xs={6} md="auto" className="pl-2">
      <Button fullWidth color="primary" type="submit" disabled={isDisabledSubmit || isLoading}>
        {submitLabel}
        {isLoading && <CircularProgress size={20} className="ml-2" />}
      </Button>
    </Grid>
  </Grid>
);

export default FormButton;
