import { useLazyQuery } from "@apollo/client";
import get from "lodash/get";
import { SuggestTagType } from "types/Tag";
import { SUGGEST_CUSTOMER_TAGS } from "graphql/customer/query";

const useSuggestCustomerTag = (projectId?: string, type?: SuggestTagType) => {
  const [fetchCustomerTags, { data, loading }] = useLazyQuery(SUGGEST_CUSTOMER_TAGS, {
    variables: { projectId, type: type || SuggestTagType.CUSTOMER },
  });

  const suggestCustomerTags: string[] = get(data, "suggestTags") || [];

  return {
    suggestCustomerTags,
    fetchCustomerTags,
    isLoadingFetchCustomerTags: loading,
  };
};

export default useSuggestCustomerTag;
