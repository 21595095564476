const Z_INDEX = {
  BURGER_MENU: 10,
  TOGGLE_SWITCH_SUB_SIDE_NAV: 1201,
  SUB_SIDE_NAV: 1100,
  TOP_NAV: 1300, // Due to default value of drawer of MUI is 1200
  MODAL: 10,
  TAB: 10,
  CHAT_HEADER: 8,
  HEADER_ORDER: 2,
  REMOVE_IMG_ICON: 1,
  FLOATING_BUTTON: 110,
  STICKY_FOOTER_BUTTON: 2,
  REMOVE_IMAGE_ICON: 1,
  SELECT_MENU: 10,
  DISABLED_REPORT_WRAPPER: 100,
};

export { Z_INDEX };
