import { useMediaQuery } from "react-responsive";
import { useTheme } from "@material-ui/core/styles";

import { Device } from "types/Device";

const useDevice = () => {
  const theme = useTheme();

  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.values.lg });
  const isTablet = useMediaQuery({ minWidth: theme.breakpoints.values.sm });

  if (isDesktop) {
    return Device.DESKTOP;
  }
  if (isTablet) {
    return Device.TABLET;
  }
  return Device.MOBILE;
};

export default useDevice;
