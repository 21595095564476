import React, { FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { components } from "react-select";

import pullAllBy from "lodash/pullAllBy";
import debounce from "lodash/debounce";
import uniqBy from "lodash/uniqBy";
import get from "lodash/get";

import Grid from "components/Grid";
import Select from "components/Select";
import wrapMenuList from "components/Select/wrapMenuList";
import { ProductImage } from "domain/MarketingTools/TopSellingProduct/styled";
import { DEFAULT_SORT_PRODUCT_BY, AVAILABLE_SCROLL_REACH_BOTTOM_DELTA } from "config";
import { NO_IMG_DEEPLE_LOGO } from "constants/Image";
import { ACTIVE_PRODUCTS } from "graphql/product/query";
import { ProjectIdType } from "types/Project";
import { ProductItemPropsType, GetActiveProductsQueryType, GetProductsVariablesType } from "types/Product";

type SearchProductPropsType = {
  isDisabled: boolean;
  onSelect: (product: ProductItemPropsType) => void;
  selectedProducts: ProductItemPropsType[];
};

const shouldLoadMore = (scrollHeight: number, clientHeight: number, scrollTop: number) => {
  const bottomBorder = scrollHeight - clientHeight - AVAILABLE_SCROLL_REACH_BOTTOM_DELTA;

  return bottomBorder < scrollTop;
};

export const MenuList = wrapMenuList(components.MenuList);

const SearchProduct: FC<SearchProductPropsType> = (props) => {
  const { onSelect, selectedProducts, isDisabled } = props;
  const { t } = useTranslation();

  const { projectId } = useParams<ProjectIdType>();

  const [searchQuery, setSearchQuery] = useState("");
  const [isLastData, setIsLastData] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [options, setOptions] = useState<any>();

  const { loading, data, fetchMore } = useQuery<GetActiveProductsQueryType, GetProductsVariablesType>(ACTIVE_PRODUCTS, {
    variables: {
      projectId,
      filter: {
        productName: searchQuery,
        productCode: searchQuery,
        sortBy: DEFAULT_SORT_PRODUCT_BY,
      },
    },
  });

  const loadMore = (offset: number) => {
    fetchMore({
      variables: {
        offset,
      },
      updateQuery: (prev: GetActiveProductsQueryType, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const isNodata = fetchMoreResult.activeProducts.results.length === 0;
        setIsLastData(isNodata);

        return {
          ...prev,
          activeProducts: {
            ...prev.activeProducts,
            results: [...prev.activeProducts.results, ...fetchMoreResult.activeProducts.results],
          },
        };
      },
    });
  };

  const handleSearch = debounce((input: string) => {
    setSearchQuery(input);
  }, 500);

  const handleScrollMenuToBottom = () => {
    if (!loading && data && data.activeProducts && data.activeProducts.results.length !== 0 && !isLastData) {
      const offset = data.activeProducts.results.length;
      loadMore(offset);
    }
  };

  const noOptionsMessage = (input: { inputValue: string }) => {
    if (!input) {
      return t("searchPlaceholder.marketingToolsTopSelling");
    }

    return t("Search not found");
  };

  // TODO: fix custom type for select option value
  // handleSelect = (value: ValueType<ProductSKUType>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelect = (value: any) => {
    onSelect(value);
  };

  // TODO: same as above
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatOptionLabel = (product: any) => {
    const { images, name } = product;

    const productImage = get(images, "0.src") || NO_IMG_DEEPLE_LOGO;

    return (
      <Grid container alignItems="center">
        <ProductImage src={productImage} />
        <Grid item container xs={9} className="pl-3">
          <Grid item xs={12} className="ellipsis">
            {name}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    if (data && data.activeProducts) {
      const searchProducts = data.activeProducts.results;
      const uniqueProduct = uniqBy(searchProducts, "id");
      const filteredOptions = pullAllBy(uniqueProduct, selectedProducts, "id");

      setOptions(filteredOptions);
    }
  }, [data, selectedProducts]);

  return (
    <Select
      placeholder={`${t("searchPlaceholder.marketingToolsTopSelling")}`}
      noOptionsMessage={noOptionsMessage}
      onChange={handleSelect}
      options={options}
      formatOptionLabel={formatOptionLabel}
      fullWidth
      onInputChange={handleSearch}
      shouldLoadMore={shouldLoadMore}
      onMenuScrollToBottom={handleScrollMenuToBottom}
      handleScrolledToBottom={handleScrollMenuToBottom}
      value={null}
      filterOption={() => true}
      isDisabled={isDisabled}
      components={{ MenuList }}
    />
  );
};

export default SearchProduct;
