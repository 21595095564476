import { gql } from "@apollo/client";

export const RESPONSE_MESSAGES = gql`
  query responseMessages($projectId: ID!, $offset: Int, $limit: Int, $filter: ResponseMessageFilter) {
    responseMessages(projectId: $projectId, offset: $offset, limit: $limit, filter: $filter) {
      results {
        id
        name
        thDisplayName
        enDisplayName
        slug
        isCustom
        tags {
          id
          tag
        }
        maleMessages
        femaleMessages
        customMessages {
          type
          value
        }
      }
      total
    }
  }
`;

export const RESPONSE_MESSAGE = gql`
  query responseMessage($projectId: ID!, $slug: String!) {
    responseMessage(projectId: $projectId, slug: $slug) {
      id
      name
      isCustom
      thDisplayName
      enDisplayName
      maleMessages
      femaleMessages
      customMessages {
        type
        value
      }
    }
  }
`;
