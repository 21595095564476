import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styled from "styled-components";

const RadioBox = styled(({ isActive: _isActive, isDisabled: _isDisabled, ...rest }) => (
  <FormControlLabel {...rest} />
)).attrs((props: { isActive: boolean; isDisabled: boolean }) => {
  const { isActive, isDisabled } = props;

  return { isActive, isDisabled };
})`
  border-radius: 5px;
  border: solid 0.5px ${({ isActive, theme }) => (isActive ? theme.COLORS.Primary : theme.COLORS.LightBlue)};
  padding: 12px 18px 12px 8px;
  margin-left: 0;
  background-color: ${({ isActive, theme }) => (isActive ? theme.COLORS.PrimaryNude : "initial")};
  ${({ isDisabled }) => (isDisabled ? "filter: grayscale(100%) opacity(.6);" : "")}

  ${({ theme }) => theme.breakpoints.down("xs")} {
    margin: 0 0 16px 0;
    width: 100%;
  }
`;

export const RadioIcon = styled.span`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.COLORS.LightBlue};
`;

export const CheckedRadioIcon = styled(RadioIcon)`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.COLORS.Primary};
  border: solid 4px ${({ theme }) => theme.COLORS.PrimaryNude};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.COLORS.Primary};
`;

export default RadioBox;
