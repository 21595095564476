import React from "react";
import styled from "styled-components";
import { CellProps } from "react-table";
import { IcEdit } from "components/SvgIcons";
import get from "lodash/get";
import { InvoiceStatue } from "types/Report";

const IconEditWrapper = styled.span`
  padding: 5px;
  margin-left: 4px;
  font-size: 12px;
  color: ${({ theme }) => theme.COLORS.DarkMed};
  border-radius: 6px;
  border: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
  background-color: ${({ theme }) => theme.COLORS.White};
  &:hover {
    color: ${({ theme }) => theme.COLORS.Primary};
    border: solid 0.5px ${({ theme }) => theme.COLORS.Primary};
    background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  }
`;

const OrderNo = styled.span`
  font-weight: 500;
  /* unvisited link */
  a:link {
    color: ${({ theme }) => theme.COLORS.Dark};
  }

  /* visited link */
  a:visited {
    color: ${({ theme }) => theme.COLORS.Dark};
  }

  /* mouse over link */
  a:hover {
    color: ${({ theme }) => theme.COLORS.DarkMed};
  }

  /* selected link */
  a:active {
    color: ${({ theme }) => theme.COLORS.Dark};
  }
`;

const getEditOrderNo = <T extends object>(onClickEditDimensionAndWeight: Function, state: string) => ({
  Header: "",
  accessor: "edit",
  disableSortBy: true,
  width: 50,
  // eslint-disable-next-line react/display-name
  Cell: (cell: CellProps<T>) => (
    <OrderNo>
      {state === InvoiceStatue.PENDING && (
        <IconEditWrapper
          onClick={() => {
            onClickEditDimensionAndWeight(get(cell, "row.original"));
          }}
        >
          <IcEdit />
        </IconEditWrapper>
      )}
    </OrderNo>
  ),
});

export default getEditOrderNo;
