import { useState, ChangeEvent, useCallback, useRef } from "react";
import { DropResult } from "react-beautiful-dnd";
import isEqual from "lodash/isEqual";
import produce from "immer";

import {
  ResponseMessage,
  MessageTextValue,
  MessageImageValue,
  CategoriesObjectType,
  ProductsObjectType,
  QuickReplyMessage,
} from "types/AISetting";
import { MessageType } from "types/Chat";
import { removeTypenameField } from "utils/common";
import {
  reorder,
  reorderQuickReply,
  clearEmptyMessage,
  cleanCategoriesMessage,
  updateQuickReplyMessage,
} from "utils/message";

const useMessagesManager = () => {
  const [isChanged, setIsChanged] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(0);
  const [messages, setMessages] = useState<ResponseMessage[]>([]);
  const previousMessages = useRef<ResponseMessage[]>();

  const handleSetMessages = useCallback((newMessages: ResponseMessage[]) => {
    setMessages(newMessages);
    previousMessages.current = newMessages;
  }, []);

  const addNewMessages = useCallback(
    (
      type: MessageType,
      value: MessageTextValue | MessageImageValue | number[] | CategoriesObjectType | ProductsObjectType,
    ) => {
      const newWelcomeMessages: ResponseMessage[] = [...messages, { type, value }];
      const newOrderQuickReply = reorderQuickReply(newWelcomeMessages);
      setMessages(newOrderQuickReply);
      setIsChanged(true);
    },
    [messages],
  );

  const handleClickAddMessage = useCallback(
    (text: string) => {
      addNewMessages(MessageType.TEXT, text || "");
      setIsChanged(true);
    },
    [addNewMessages],
  );

  const handleDeleteMessage = useCallback((index: number) => {
    setIsOpenConfirmModal(true);
    setSelectedIndex(index);
    setIsChanged(true);
  }, []);

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const newMessages = reorder<ResponseMessage>(messages, result.source.index, result.destination.index);
      const newOrderQuickReply = reorderQuickReply(newMessages);
      setMessages(newOrderQuickReply);
      setIsChanged(true);
    },
    [messages],
  );

  const handleUploadCallback = useCallback(
    (url: string) => {
      addNewMessages(MessageType.IMAGE, { url });
      setIsChanged(true);
    },
    [addNewMessages],
  );

  const handleCloseConfirmModal = useCallback(() => {
    setIsOpenConfirmModal(false);
  }, []);

  const handleEditMessage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { id, value } = event.target;

      const newMessages = produce(messages, (draftMessages) => {
        // eslint-disable-next-line no-param-reassign
        draftMessages[Number(id)].value = value;
      });

      setMessages(newMessages);
      setIsChanged(true);
    },
    [messages],
  );

  const deleteMessage = useCallback(
    (index: number | null) => {
      if (index == null) {
        return;
      }

      const newMessage = messages.filter((_message, indexMessage) => indexMessage !== index);

      setMessages(newMessage);
      setIsOpenConfirmModal(false);
      setSelectedIndex(null);
      setIsChanged(true);
    },
    [messages],
  );

  const handleClickSave = useCallback(
    (callback: (newMessages: ResponseMessage[]) => void) => {
      const clearedMessages = clearEmptyMessage(messages);

      const removedTypeNameMessage = removeTypenameField(clearedMessages) || [];

      const cleanObjectCategoriesMessage = cleanCategoriesMessage(removedTypeNameMessage as ResponseMessage[]);

      if (isEqual(cleanCategoriesMessage, previousMessages.current)) {
        setMessages(cleanCategoriesMessage);
        setIsChanged(true);
        return;
      }

      callback(cleanObjectCategoriesMessage);
    },
    [messages],
  );

  const updateNewQuickReplyMessage = useCallback(
    (newMessage: QuickReplyMessage[]) => {
      const updatedQuickReplyMessage = updateQuickReplyMessage(newMessage, messages);
      setMessages(updatedQuickReplyMessage);
      setIsChanged(true);
    },
    [messages],
  );
  const quickReplyIndex = messages.findIndex((message) => message.type === MessageType.QUICK_REPLIES);
  const hasQuickReply = quickReplyIndex >= 0;
  const messagesCount = hasQuickReply ? messages.length - 1 : messages.length;

  return {
    addNewMessages,
    deleteMessage,
    handleClickAddMessage,
    handleClickSave,
    handleCloseConfirmModal,
    handleDeleteMessage,
    handleDragEnd,
    handleEditMessage,
    handleSetMessages,
    handleUploadCallback,
    isChanged,
    isOpenConfirmModal,
    messages,
    messagesCount,
    selectedIndex,
    setIsChanged,
    setMessages,
    updateNewQuickReplyMessage,
  };
};

export default useMessagesManager;
