import React from "react";
import styled from "styled-components";
import { components } from "react-select";
import { COLORS } from "constants/Colors";
import { ThemeType } from "components/Theme/type";
import { VARIANTS } from "constants/Typography";
import { TagType } from "types/Tag";
import { getTagColor } from "utils/tag";

type CustomerTagPropsType = {
  theme: ThemeType;
  type?: TagType;
  isFixWidth?: boolean;
};

export const CustomerTag = styled(
  ({ color: _color, bgColor: _bgColor, borderColor: _borderColor, isFixWidth: _isFixWidth, ...rest }) => (
    <div {...rest} />
  ),
).attrs((props: CustomerTagPropsType) => {
  const { theme, type } = props;
  const { color, bgColor, borderColor } = getTagColor(theme.COLORS, type);
  return { color, bgColor, borderColor };
})`
  max-width: ${({ isFixWidth }) => (isFixWidth ? "62px" : "fit-content")};
  width: auto;
  padding: 10px;
  border-radius: 7px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  padding: 0px 5px;
  margin-right: 4px;
  font-size: 11px;
  line-height: 20px;
  font-weight: 500;
  border: ${({ borderColor }) => `solid 1px ${borderColor}`};
`;

export const MultiCustomerTag = styled(
  ({ color: _color, bgColor: _bgColor, borderColor: _borderColor, isFixWidth: _isFixWidth, ...rest }) => (
    <components.MultiValue {...rest} />
  ),
).attrs((props: CustomerTagPropsType) => {
  const { type } = props;
  const { color, bgColor, borderColor } = getTagColor(COLORS, type);
  return { color, bgColor, borderColor };
})`
  border-radius: 7px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  padding: 0 0 0 5px;
  margin-right: 4px;
  font-size: 11px;
  line-height: 20px;
  font-weight: 500;
  border: ${({ borderColor }) => `solid 1px ${borderColor}`};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-rows: 18px 18px auto;
  grid-template-columns: 48px 1fr auto 24px;
  max-height: 80px;
  gap: 4px 12px;
`;

export const Image = styled.div`
  grid-column-start: 1;
  grid-row: 1 / span 2;
`;

export const Name = styled.div`
  grid-column: 2;
  grid-row: 1;
  font-size: ${VARIANTS.title8.size};
  font-family: ${VARIANTS.title8.fontFamily};
  color: ${COLORS.Dark};
`;

export const LastMessageTime = styled.div`
  grid-column: 3 / span 2;
  grid-row: 1;
  text-align: right;
  font-size: ${VARIANTS.body4.size};
  font-family: ${VARIANTS.body4.fontFamily};
  color: ${COLORS.DarkGray};
`;

export const LastMessage = styled.div`
  grid-column: 2 / span 2;
  grid-row: 2;
  font-size: ${VARIANTS.body3.size};
  font-family: ${VARIANTS.body3.fontFamily};
  color: ${COLORS.DarkLight};
`;

export const Tag = styled.div`
  grid-column: 2 / span 3;
  grid-row: 3;
  display: flex;
  overflow: hidden;
`;

export const MoreTagWrapper = styled.div`
  font-size: 10px;
  line-height: 22px;
  color: ${COLORS.DarkGray};
  padding-left: 8px;
`;
