import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: "100%",
      margin: theme.spacing(2),
      maxWidth: "500px",
    },
    media: {
      height: 190,
    },
  }),
);

interface MediaProps {
  loading?: boolean;
  message: string;
  image: string;
  createdTime: string;
  pageName: string;
  pagePicture: string;
}

function FacebookMessageCard(props: MediaProps) {
  const { loading = false, message, image, createdTime, pageName, pagePicture } = props;
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          loading ? (
            <Skeleton animation="wave" variant="circle" width={40} height={40} />
          ) : (
            <Avatar alt="image" src={pagePicture} />
          )
        }
        title={loading ? <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} /> : pageName}
        subheader={loading ? <Skeleton animation="wave" height={10} width="40%" /> : createdTime}
      />
      <div>
        <div className="px-3 pb-2">
          {loading ? (
            <>
              <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={10} width="80%" />
            </>
          ) : (
            <Typography variant="body2" color="textSecondary" className="mb-2" style={{ whiteSpace: "pre-wrap" }}>
              {message}
            </Typography>
          )}
        </div>

        {loading ? (
          <Skeleton animation="wave" variant="rect" className={classes.media} />
        ) : (
          // eslint-disable-next-line jsx-a11y/img-redundant-alt
          <>{image && <img src={image} alt="image" className="d-flex width-fill-available" />}</>
        )}
      </div>
    </Card>
  );
}

export default FacebookMessageCard;
