import { gql } from "@apollo/client";

export const CHANGE_PACKAGES = gql`
  mutation changePackages($projectId: ID!, $packageIds: [ID!]!) {
    changePackages(projectId: $projectId, packageIds: $packageIds) {
      id
      name
      type
      entityType
      isTrial
      isSelected
      pricePerMonth
      subscriberQuota
      messageQuota
      orderQuota
      faqQuota
      productQuota
      memberQuota
      channelQuota
      imageSearchQuota
      isAddonEnabled
    }
  }
`;

export const CHANGE_PACKAGES_FROM_BASIC = gql`
  mutation changeFromBasicPackageToMainPackage($projectId: ID!, $packageIds: [ID!]!, $token: String) {
    changeFromBasicPackageToMainPackage(projectId: $projectId, packageIds: $packageIds, token: $token)
  }
`;
