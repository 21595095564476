import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core";

import { MenuAction } from "types/RichMenu";
import Card from "components/Card";
import InstagramMenuItem from "components/InstagramMenuItem";
import Grid from "components/Grid";

import renderReview from "./renderReview";

const CustomGrid = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "16px 0",
  },
})(Grid);

export type InstagramPreviewCardProps = {
  menuActions: MenuAction[];
};

const InstagramPreviewCard: FC<InstagramPreviewCardProps> = (props) => {
  const { menuActions = [] } = props;

  const { t } = useTranslation();

  return (
    <Card width={225} height={150} padding="0px">
      <CustomGrid>
        {menuActions.map(({ action }: MenuAction, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <InstagramMenuItem key={action.type + index} label={t(renderReview(action))} />
        ))}
      </CustomGrid>
    </Card>
  );
};

export default memo(InstagramPreviewCard);
