import { AuthProvider } from "./Auth";

export type UserDataType = {
  email: string;
  language: string;
  login?: string;
  name?: string;
  password: string;
  refCode?: string;
  register?: string;
  token?: string;
};

export type PermissionType = {
  id: string;
  projectId: string;
  projectName: string;
  projectLogo: string;
  role: string;
  isEnterprise?: boolean;
  isGuidelineCompleted: boolean;
  isInitialProjectCompleted: boolean;
  isDisabled: boolean;
  restoreId?: string;
};

export type UserProfileDetailType = {
  acceptedAt: boolean;
  authProvider: AuthProvider;
  avatar: string;
  displayName: string;
  email: string;
  firstname?: string;
  id: string;
  isAccepted: boolean;
  isAllowedToCreateProject: boolean;
  isConnectedWithApple: boolean;
  isConnectedWithFacebook: boolean;
  isConnectedWithGoogle: boolean;
  isEmailVerified: boolean;
  language: string;
  lastname?: string;
  permissions: PermissionType[];
  phoneNumber?: string;
  primaryProvider: AuthProvider;
};

export type UserProfileType = {
  me: UserProfileDetailType;
};

export enum UserRole {
  OWNER = "owner",
  ADMIN = "admin",
}
