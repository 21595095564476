import styled from "styled-components";

export const ExpandButton = styled.div`
  &.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
    width: 25px;
    height: 25px;
    margin: auto;
    transform: rotate(0);
    transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &.circle.active {
    transform: rotate(-90deg);
  }
`;
