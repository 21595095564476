import { useQuery } from "@apollo/client";
import { snakeCase } from "lodash";
import qs from "qs";
import React, { FC, useCallback, useMemo, useRef } from "react";
import { GroupType, OptionType } from "react-select";
import { useTranslation } from "react-i18next";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { PROJECT, RESPONSE_MESSAGE, AI_SETTINGS } from "constants/Router";
import { MAP_LANGUAGE_DISPLAY_NAME, MAP_BOT_GENDER } from "constants/ResponseMessage";
import PageTitle from "components/PageTitle";
import Search from "components/Search";
import Grid from "components/Grid";
import ReportPagination from "domain/Report/ParcelInvoiceReport/ReportPagination";
import TagOption from "domain/Chat/ContactsList/TagOption";
import TagMultiValue from "domain/Chat/ContactsList/TagMultiValue";
import useSearchText from "utils/hooks/useSearchText";
import useGetProject from "utils/hooks/useGetProject";
import { RESPONSE_MESSAGES } from "graphql/responseMessage/query";
import { Gender, ResponseMessage } from "types/AISetting";
import { OrderByType, Data } from "types/SalesReport";
import { MessageType } from "types/Chat";
import { TagType } from "types/Tag";
import { ResponseMessageData, RESPONSE_MESSAGE_TAB } from "types/ResponseMessage";
import { ProjectIdType } from "types/Project";
import useSearchTags from "utils/hooks/useSearchTags";
import usePaginationState from "./hook/usePaginationState";
import useWatchFilterAndUpdateQueryString from "./hook/useWatchFilterAndUpdateQueryString";
import Table, { OnChangeSortBy } from "./Table";
import highlight from "./highlight";
import { ROWS_PER_PAGES } from "./config";
import TagControl from "./TagControl";
import getTagInputStyles from "./getTagInputStyles";

type ResponseMessagesQuery = {
  responseMessages: {
    results: ResponseMessageData[];
    total: number;
  };
};

const ResponseMessagePage: FC = () => {
  const { projectId } = useParams<ProjectIdType>();
  const { search } = useLocation();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const displayNameField = MAP_LANGUAGE_DISPLAY_NAME?.[language] as keyof ResponseMessageData;
  const history = useHistory();
  const { refetch } = useSearchTags();
  const customTagSelector = useRef({ Option: TagOption, MultiValue: TagMultiValue, Control: TagControl });
  const tagInputStyles = useMemo(() => getTagInputStyles(false), []);
  const loadOptions = useCallback(
    async (search: string, options: GroupType<OptionType>, additional: { page: number }) => {
      const LIMIT = 25;

      try {
        const result = await refetch({
          projectId,
          type: TagType.RESPONSE_MESSAGE,
          tag: search,
          offset: additional.page,
          limit: LIMIT,
        });

        const tags = result?.data?.searchTags?.results || [];

        return {
          options: tags.map((tag) => ({
            label: tag.tag,
            value: tag.tag,
          })),
          hasMore: result?.data?.searchTags?.total > options?.[1]?.options?.length,
          additional: {
            page: additional.page + 1,
          },
        };
      } catch (error) {
        console.error("error:", error);
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [projectId, refetch],
  );

  const currentPathUrl = `/${PROJECT}/${projectId}/${AI_SETTINGS}/${RESPONSE_MESSAGE}`;

  const { filter: defaultFilterFromUrl, limit: defaultRowsPerPageFromUrl, offset: defaultPageFromUrl } = qs.parse(
    search,
    {
      ignoreQueryPrefix: true,
    },
  );

  const {
    page,
    rowsPerPage,
    filter,
    handleChangeRowsPerPage,
    handleClickPageNo,
    defaultPage,
    handleSetFilter,
  } = usePaginationState(defaultFilterFromUrl, defaultRowsPerPageFromUrl, defaultPageFromUrl);

  const handleChangeSearchText = useCallback(() => {
    handleClickPageNo({
      selected: defaultPage,
    });
  }, [defaultPage, handleClickPageNo]);

  const { searchText, debounceHandleChangeSearch } = useSearchText(
    defaultFilterFromUrl?.keyword,
    handleChangeSearchText,
  );

  useWatchFilterAndUpdateQueryString(filter, rowsPerPage, page, searchText, projectId, currentPathUrl);

  const { data, loading } = useQuery<ResponseMessagesQuery>(RESPONSE_MESSAGES, {
    variables: {
      projectId,
      offset: page,
      limit: rowsPerPage,
      filter: {
        ...filter,
        keyword: searchText,
        responseMessageTags: filter.responseMessageTags?.map((responseMessageTag) => responseMessageTag.value),
      },
    },
    skip: !projectId,
  });
  const { botGender, loading: isProjectLoading } = useGetProject(projectId);

  const botGenderField = MAP_BOT_GENDER[botGender as Gender] as keyof ResponseMessageData;

  const handleClickEdit = useCallback(
    (responseMessageData?: ResponseMessageData) => {
      if (!responseMessageData?.name) return;

      history.push(`${currentPathUrl}/${responseMessageData.slug}${search}`);
    },
    [currentPathUrl, history, search],
  );

  const handleSort: OnChangeSortBy = useCallback(
    (sort) => {
      const DEFAULT_SORT = "updated_at";
      const DEFAULT_SORT_ORDER = OrderByType.ASC;
      let field = DEFAULT_SORT;
      if (!sort?.length) {
        handleSetFilter({
          direction: DEFAULT_SORT_ORDER,
          orderBy: field,
        });
        return;
      }

      const key = sort?.[0].id;
      if (key === "name") {
        field = snakeCase(displayNameField);
      }

      handleSetFilter({
        direction: sort?.[0].desc ? OrderByType.DESC : OrderByType.ASC,
        orderBy: field,
      });
    },
    [displayNameField, handleSetFilter],
  );

  const handleChangeTags = useCallback(
    (options: OptionType[]) => {
      handleSetFilter({
        responseMessageTags: options || [],
      });
    },
    [handleSetFilter],
  );

  const tableData = useMemo(() => {
    if (isProjectLoading) return [];

    return (
      data?.responseMessages?.results?.map((responseMessage) => {
        const messages = (responseMessage?.[botGenderField] as ResponseMessage[]).concat(
          responseMessage?.customMessages,
        );
        const foundMessage = messages.find((message) => {
          if (message.type !== MessageType.TEXT && typeof message.value !== "string") return false;
          const index = (message.value as string).search(searchText);

          return index >= 0;
        });

        return {
          id: responseMessage.id,
          tags: responseMessage.tags,
          name: responseMessage?.[displayNameField] || responseMessage.name,
          messageUsed: responseMessage?.isCustom ? t(RESPONSE_MESSAGE_TAB.CUSTOM) : t(RESPONSE_MESSAGE_TAB.DEFAULT),
          slug: responseMessage.slug,
          message: foundMessage ? highlight(foundMessage.value as string, searchText) : "",
        };
      }) || []
    );
  }, [botGenderField, data, displayNameField, isProjectLoading, searchText, t]);

  const total = data?.responseMessages?.total || 0;

  return (
    <div className="mx-3 d-flex flex-column flex-1">
      <PageTitle className="p-0 py-4 flex-0" title={t("RESPONSE_MESSAGE")} />

      <Search
        defaultValue={defaultFilterFromUrl?.keyword || searchText}
        onChange={debounceHandleChangeSearch}
        placeholder={t("SEARCH_RESPONSE_MESSAGE")}
      />

      <Grid container className="mt-3">
        <Grid item xs={12} sm={6} md={4}>
          <AsyncPaginate
            isMulti
            noOptionsMessage={() => t("no option")}
            placeholder={t("SEARCH_BY_TAG")}
            loadingMessage={() => t("LOADING")}
            className="mb-2"
            styles={tagInputStyles}
            components={customTagSelector.current}
            onChange={handleChangeTags}
            debounceTimeout={400}
            additional={{ page: 0 }}
            loadOptions={loadOptions}
            value={filter.responseMessageTags}
          />
        </Grid>
      </Grid>

      <div className="flex-1 pb-4 pt-3 overflow-auto">
        <Table
          onChangeSortBy={handleSort}
          customCellProps={{ handleClickEdit }}
          data={tableData as Data[]}
          isSearchResults={Boolean(searchText)}
          isLoading={loading || isProjectLoading}
          renderFooter={() => (
            <ReportPagination
              onChangeRowsPerPage={handleChangeRowsPerPage}
              onClickPageNo={handleClickPageNo}
              page={page + 1}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={ROWS_PER_PAGES}
              total={total}
            />
          )}
        />
      </div>
    </div>
  );
};

export default ResponseMessagePage;
