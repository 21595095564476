const messageManager = {
  en: {
    "Please add new message": "Please add new message",
    "Click to select categories": "Click to select categories",
    "Click to select products": "Click to select products",
    "Search categories": "Search categories",
    "Search products": "Search products",
    "Type your message here": "Type your message here",
    "Exceed character limit": "Exceed 2,000 characters limit",
  },
  th: {
    "Please add new message": "กรุณาเพิ่มข้อความใหม่",
    "Click to select categories": "คลิกเพื่อเลือกเมนูสินค้า",
    "Click to select products": "คลิกเพื่อเลือกสินค้า",
    "Search categories": "ค้นหาเมนูสินค้า",
    "Search products": "ค้นหาสินค้า",
    "Type your message here": "กรอกข้อความที่นี่",
    "Exceed character limit": "ข้อความเกิน 2,000 ตัวอักษรที่กำหนด",
  },
};

export default messageManager;
