import * as React from "react";

const SvgIcCreditCardVisa = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <g>
      <path
        d="M22.5 3.75H1.5C0.671573 3.75 0 4.43558 0 5.28129V19.4687C0 20.3144 0.671573 21 1.5 21H22.5C23.3284 21 24 20.3144 24 19.4687V5.28129C24 4.43558 23.3284 3.75 22.5 3.75Z"
        fill="#0066B2"
      />
      <path d="M10.98 15.0818H9.72754L10.515 10.1816H11.76L10.98 15.0818Z" fill="white" />
      <path
        d="M8.67725 10.1814L7.49975 13.5502L7.36475 12.7846L6.92225 10.6178C6.89834 10.4807 6.82314 10.3586 6.71256 10.2771C6.60198 10.1957 6.46469 10.1613 6.32975 10.1814H4.36475V10.2656C4.81943 10.3899 5.25219 10.5862 5.64725 10.8475L6.74975 15.0815H8.04725L10.0422 10.1814H8.67725Z"
        fill="white"
      />
      <path
        d="M18.51 15.0814H19.6575L18.66 10.1812H17.655C17.533 10.1722 17.4113 10.2029 17.3076 10.2689C17.2038 10.335 17.1232 10.433 17.0775 10.5488L15.21 15.0814H16.5L16.7625 14.3157H18.3525L18.51 15.0814ZM17.1375 13.351L17.79 11.5135L18.165 13.351H17.1375Z"
        fill="white"
      />
      <path
        d="M15.3074 11.3608L15.4874 10.3042C15.1261 10.1716 14.7463 10.0992 14.3624 10.0898C13.7474 10.0898 12.2699 10.3731 12.2699 11.713C12.2699 13.0529 14.0024 12.9993 14.0024 13.6654C14.0024 14.3315 12.4499 14.209 11.9399 13.7879L11.7524 14.8904C12.1987 15.0809 12.6788 15.1748 13.1624 15.1661C14.0174 15.1661 15.2999 14.7143 15.2999 13.4893C15.2999 12.2643 13.5599 12.0958 13.5599 11.5446C13.5599 10.9933 14.7749 11.0546 15.3074 11.3608Z"
        fill="white"
      />
      <path
        d="M7.34975 12.8229L6.92225 10.6178C6.89834 10.4807 6.82314 10.3586 6.71256 10.2771C6.60198 10.1957 6.46469 10.1613 6.32975 10.1814H4.36475V10.2656C5.04236 10.4412 5.67446 10.7652 6.21725 11.215C6.72635 11.6403 7.11692 12.1948 7.34975 12.8229Z"
        fill="#F9A533"
      />
    </g>
  </svg>
);

export default SvgIcCreditCardVisa;
