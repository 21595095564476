import { gql } from "@apollo/client";

export const CHAT_MENUS = gql`
  query chatMenus($projectId: ID!, $platform: Platform!, $chatMenuOption: ChatMenuOption) {
    chatMenus(projectId: $projectId, platform: $platform, chatMenuOption: $chatMenuOption) {
      id
      projectId
      name
      isActive
      isDefault
      menuUrl
      platform
      menuActions {
        bounds
        action {
          type
          label
          url
        }
      }
    }
  }
`;

export const RICH_MENU = gql`
  query chatMenu($projectId: ID!, $chatMenuId: ID!) {
    chatMenu(projectId: $projectId, chatMenuId: $chatMenuId) {
      id
      projectId
      name
      isActive
      isDefault
      platform
      menuActions {
        bounds
        action {
          type
          label
          url
        }
      }
      menuUrl
      menuLabel
      isCollapsed
      template
      createdAt
      updatedAt
    }
  }
`;
