import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

import Typography from "components/Typography";
import useIsDesktop from "utils/hooks/useIsDesktop";

const Title: FC = () => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  return (
    <Grid item xs={12} className={!isDesktop ? "pl-3 py-4" : "mb-3"}>
      <Typography variant="title2">{t("deeplePay.header.paymentChannel")}</Typography>
    </Grid>
  );
};

export default Title;
