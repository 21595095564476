import React from "react";
import { Hooks, UseExpandedInstanceProps, UseExpandedRowProps, Row } from "react-table";
import { useTranslation } from "react-i18next";
import COLORS from "constants/Colors";
import ButtonBase from "components/ButtonBase";
import { SvgIcon } from "components/Icon";
import { IcArrowBack } from "components/SvgIcons";
import { EXPAND_CELL_WIDTH } from "domain/Report/ParcelInvoiceReport/config";
import { ExpandButton } from "./styled";

const useCheckboxSelector = <T extends object>(hooks: Hooks<T>) => {
  hooks.visibleColumns.push((newColumns) => [
    ...newColumns,
    {
      id: "expand",
      sticky: "right",
      width: EXPAND_CELL_WIDTH,
      // eslint-disable-next-line react/display-name
      Header: ({ toggleAllRowsExpanded }: UseExpandedInstanceProps<T>) => {
        const { t } = useTranslation();
        return (
          <ButtonBase
            onClick={() => {
              toggleAllRowsExpanded(false);
            }}
            className="d-block m-auto"
            style={{ color: COLORS.Primary, fontWeight: "bold" }}
          >
            {t("Close all")}
          </ButtonBase>
        );
      },
      // eslint-disable-next-line react/display-name
      Cell: ({ row }: { row: UseExpandedRowProps<Row> }) => (
        <ExpandButton className={`circle ${row.isExpanded ? "active" : ""}`} {...row.getToggleRowExpandedProps()}>
          <SvgIcon component={IcArrowBack} fontSize="small" className="m-0" />
        </ExpandButton>
      ),
    },
  ]);
};

export default useCheckboxSelector;
