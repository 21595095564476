import { useMutation, useQuery, ApolloError } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC, MouseEvent, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { OptionType, ValueType } from "react-select";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

import { SHIPPOP_CARRIER_OPTIONS_WITH_LOGO, MAPPED_SHIPPOP_ERROR } from "constants/Shipping";
import COLORS from "constants/Colors";
import ErrorText from "components/ErrorText";
import Button from "components/Button";
import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import { ControllerSelect } from "components/Select";
import Typography from "components/Typography";
import Modal from "components/Modal";
import {
  CustomOptionShippingCarrier,
  SingleValueShippingCarrier,
} from "domain/Shipping/EditShippingMethodForm/CustomSelectComponent";
import { OrderType } from "types/Order";
import { ShippopPurchaseOrderSummaryError, SHIPPOP_ERROR_CODE, CarrierOptionType } from "types/Shipping";
import { OrderReportQueryType } from "types/Report";
import { SHIPPOP_REPORTS } from "graphql/salesReport/query";
import SHIPPOP_ESTIMATED_SHIPPING_PRICE from "graphql/salesReport/query/shippopEstimatedShippingPrice";
import { UPDATE_DIMENSION_WEIGHT_AND_CARRIER } from "graphql/order/mutation";
import { convertPriceFormat } from "utils/common";
import { notifyWarn, notifySuccess } from "utils/notify";
import TextFieldWithUnit from "./TextFieldWithUnit";
import { updateDimensionAndWeightSchema } from "./validateSchema";

const useStyles = makeStyles(() => ({
  paper: {
    overflowY: "visible",
  },
}));

type UpdateDimensionAndWeightPropsType = {
  projectId: string;
  carrierName: string;
  shippopError?: ShippopPurchaseOrderSummaryError;
  orderId: string;
  isOpen: boolean;
  onClose: (event: MouseEvent) => void;
  closeModal: () => void;
  totalWeight: number | undefined;
  largestDimension?: number[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variables: any;
};

const UpdateDimensionAndWeight: FC<UpdateDimensionAndWeightPropsType> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    projectId,
    carrierName,
    orderId,
    shippopError,
    isOpen,
    onClose,
    closeModal,
    totalWeight: totalWeightProps,
    largestDimension: largestDimensionProps,
    variables,
  } = props;

  const methods = useForm({
    resolver: yupResolver(updateDimensionAndWeightSchema),
  });

  const { handleSubmit, errors, setError, clearErrors, watch, control, setValue } = methods;

  const watchFields = watch([
    "productDimensionWidth",
    "productDimensionLength",
    "productDimensionHeight",
    "totalWeight",
    "carrierName",
  ]);

  const handleChangeCarrierName = useCallback(
    (value: ValueType<OptionType>) => {
      setValue("carrierName", (value as OptionType).value);
    },
    [setValue],
  );

  const currentCarrierName = watchFields.carrierName || carrierName;

  const valueOption = SHIPPOP_CARRIER_OPTIONS_WITH_LOGO.find(({ value }) => value === currentCarrierName);

  const showError = (error: ApolloError) => {
    toast.error(`${t("Update failed!")}, ${error}`, {
      position: toast.POSITION.TOP_CENTER,
      closeButton: false,
      autoClose: 3000,
    });
  };

  const { loading: isLoadingEstimatedShippingPrice, data: estimatedShippingPriceData, error } = useQuery(
    SHIPPOP_ESTIMATED_SHIPPING_PRICE,
    {
      variables: { projectId, orderId, carrierName: watchFields.carrierName },
      fetchPolicy: "network-only",
      skip: orderId == null,
    },
  );

  const [updateDimensionWeightAndCarrier, { loading: updateOrderLoading }] = useMutation(
    UPDATE_DIMENSION_WEIGHT_AND_CARRIER,
    {
      variables: {
        projectId,
        orderId,
        dimension: [
          watchFields.productDimensionWidth,
          watchFields.productDimensionLength,
          watchFields.productDimensionHeight,
        ],
        weight: watchFields.totalWeight,
        carrierName: watchFields.carrierName,
      },
      onError: showError,
      onCompleted: () => {
        closeModal();
      },
      update(cache, { data }) {
        const cacheOrderReport = cache.readQuery<OrderReportQueryType>({
          query: SHIPPOP_REPORTS,
          variables,
        });

        if (!data) {
          return;
        }

        const orderReportResults: OrderType[] = get(cacheOrderReport, "orderReports.results") || [];

        const updatedOrder = orderReportResults.map((order: OrderType) => {
          if (order.id === orderId) {
            return {
              ...order,
              largestDimension: [
                watchFields.productDimensionWidth,
                watchFields.productDimensionLength,
                watchFields.productDimensionHeight,
              ],
              totalWeight: watchFields.totalWeight,
              shippingDescription: watchFields.carrierName,
            };
          }
          return order;
        });

        if (cacheOrderReport?.orderReports) {
          cache.writeQuery({
            query: SHIPPOP_REPORTS,
            variables,
            data: {
              orderReports: {
                results: updatedOrder,
                total: get(cacheOrderReport, "orderReports.total"),
                summary: get(cacheOrderReport, "orderReports.summary"),
                __typename: get(cacheOrderReport, "orderReports.__typename"),
              },
            },
          });
        }
      },
    },
  );

  const submitUpdateOrder = async () => {
    if (updateOrderLoading) {
      return;
    }

    try {
      await updateDimensionWeightAndCarrier();
      notifySuccess(t("Update successfully"));
    } catch (err) {
      const gqlErrorMessage: string = get(err, "graphQLErrors.0.message");
      notifyWarn(gqlErrorMessage, { autoClose: 8000 });
    }
  };

  const handleSubmitForm = handleSubmit(submitUpdateOrder);

  const errorMessage = shippopError ? shippopError?.errorCode : "";

  useEffect(() => {
    if (shippopError?.errorCode === SHIPPOP_ERROR_CODE.INVALID_WEIGHT && isOpen) {
      setError("totalWeight", {
        type: "manual",
        message: errorMessage,
      });
    }

    if (shippopError?.errorCode === SHIPPOP_ERROR_CODE.INVALID_SIZE && isOpen) {
      setError("productDimensionWidth", {
        type: "manual",
        message: errorMessage,
      });
      setError("productDimensionLength", {
        type: "manual",
        message: errorMessage,
      });
      setError("productDimensionHeight", {
        type: "manual",
        message: errorMessage,
      });
    }

    if (!isOpen) {
      clearErrors();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, orderId]);

  const clearDimensionError = () => {
    if (errors?.productDimensionWidth || errors?.productDimensionLength || errors?.productDimensionHeight) {
      clearErrors(["productDimensionWidth", "productDimensionLength", "productDimensionHeight"]);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeIconColor={COLORS.DarkMed} classes={classes}>
      <div className="p-3">
        <Typography variant="title2" className="py-2" color="dark">
          {t("UpdateOrderLargestDimension")}
        </Typography>

        {errors?.totalWeight && <ErrorText>{t(errors?.totalWeight?.message)}</ErrorText>}
        {errors?.productDimensionWidth && <ErrorText>{t(errors?.productDimensionWidth?.message)}</ErrorText>}

        <Typography variant="body3" className="mt-2" color={COLORS.DarkMed}>
          {t("ProductWeight")}
        </Typography>

        <div className="my-2 px-1">
          <TextFieldWithUnit
            control={control}
            name="totalWeight"
            defaultValue={totalWeightProps}
            hasError={Boolean(errors?.totalWeight)}
            onChange={() => {
              if (errors?.totalWeight) {
                clearErrors("totalWeight");
              }
            }}
            unit={t("gram")}
          />
        </div>

        <Typography variant="body3" className="mt-2" color={COLORS.DarkMed}>
          {t("ProductDimension")}
        </Typography>

        <Grid container>
          <Grid item sm={4} xs={12} className="my-2 px-1">
            <TextFieldWithUnit
              control={control}
              name="productDimensionWidth"
              defaultValue={largestDimensionProps?.[0]}
              hasError={Boolean(errors?.productDimensionWidth)}
              onChange={clearDimensionError}
              unit={t("cm")}
            />
          </Grid>

          <Grid item sm={4} xs={12} className="my-2 px-1">
            <TextFieldWithUnit
              control={control}
              name="productDimensionLength"
              defaultValue={largestDimensionProps?.[1]}
              hasError={Boolean(errors?.productDimensionLength)}
              onChange={clearDimensionError}
              unit={t("cm")}
            />
          </Grid>

          <Grid item sm={4} xs={12} className="my-2 px-1">
            <TextFieldWithUnit
              control={control}
              name="productDimensionHeight"
              defaultValue={largestDimensionProps?.[2]}
              hasError={Boolean(errors?.productDimensionHeight)}
              onChange={clearDimensionError}
              unit={t("cm")}
            />
          </Grid>
          <Grid item xs={12} className="my-2 px-1">
            <Typography variant="body3" color="darkMed">
              {t("CARRIER_NAME")}
            </Typography>
            <ControllerSelect<CarrierOptionType>
              name="carrierName"
              control={control}
              isSearchable={false}
              className="mt-2"
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              defaultValue={carrierName as any}
              value={valueOption}
              onChange={handleChangeCarrierName}
              formatOptionLabel={(option) => t(option?.label)}
              options={SHIPPOP_CARRIER_OPTIONS_WITH_LOGO}
              fullWidth
              components={{ SingleValue: SingleValueShippingCarrier, Option: CustomOptionShippingCarrier }}
            />
            <div className="mt-1">
              {isLoadingEstimatedShippingPrice ? (
                <>
                  <CircularProgress size={15} className="align-middle mr-2" />
                  <Typography variant="body4" color="darkMed" className="d-inline">
                    {t("ESTIMATED_SHIPPING_PRICE")}
                  </Typography>
                </>
              ) : (
                <Typography variant="body4" color="darkMed">
                  {error?.message
                    ? t(MAPPED_SHIPPOP_ERROR[(error as ApolloError).message])
                    : `${t("ESTIMATED_SHIPPING_PRICE")} = ${convertPriceFormat(
                        estimatedShippingPriceData?.shippopEstimatedShippingPrice || 0,
                        0,
                      )}${t("THB")}`}
                </Typography>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid container className="pt-3">
          <Grid item xs={6} className="pr-1">
            <Button size="small" color="secondary" fullWidth onClick={onClose}>
              {t("Cancel")}
            </Button>
          </Grid>
          <Grid item xs={6} className="pl-1">
            <Button size="small" fullWidth onClick={() => handleSubmitForm()} disabled={updateOrderLoading}>
              {t("Submit")} {updateOrderLoading && <CircularProgress size="small" />}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default UpdateDimensionAndWeight;
