import React, { FC, useState, useEffect } from "react";
import styled, { css } from "styled-components";

import { SvgIcon } from "components/Icon";
import { IcAdd, IcSubtract } from "components/SvgIcons";
import { MIN_QUANTITY } from "constants/ShoppingCart";
import { ThemeType } from "../Theme/type";

type StepperControlButtonPropsType = {
  side: "left" | "right";
};

type StepperNumberInputPropsType = {
  value: number;
  maxValue?: number;
  minValue?: number;
  onChange: (value: number) => void;
  defaultColor?: boolean;
  isDisabled?: boolean;
};

type NumberInputPropsType = Pick<StepperNumberInputPropsType, "value" | "defaultColor"> & {
  theme: ThemeType;
};

const StepperControlButton = styled.button<StepperControlButtonPropsType>`
  color: ${({ theme }) => theme.COLORS.DarkMed};
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border: none;
  background-color: ${({ theme }) => theme.COLORS.White};
  border-radius: ${({ side }) => (side === "left" ? "7px 0 0 7px" : "0 7px 7px 0")};

  svg {
    position: relative;
    top: -1px;
    margin-right: 5px;
  }

  :active {
    background-color: ${({ theme }) => theme.COLORS.Primary};
    border: solid 0.5px ${({ theme }) => theme.COLORS.Primary};
    img {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
    }
  }

  :focus {
    outline: none;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.COLORS.LightBlue};
      border: solid 0.5px ${({ theme }) => theme.COLORS.LightGray};
      background-color: ${({ theme }) => theme.COLORS.LightGray};
    `}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NumberInput = styled(({ isColoredInput, ...otherProps }) => <input {...otherProps} />).attrs(
  (props: NumberInputPropsType) => {
    const { defaultColor, value, theme } = props;
    let color = "inherit";
    if (!defaultColor && value > 0) {
      color = theme.COLORS.Success;
    } else if (!defaultColor && value < 0) {
      color = theme.COLORS.Error;
    }

    return {
      color,
      type: "text",
      inputMode: "numeric",
    };
  },
)`
  width: 44px;
  text-align: center;
  border-style: solid;
  border-width: 0 0.5px;
  border-color: ${({ theme }) => theme.COLORS.LightBlue};
  color: ${({ color }) => color};

  :focus {
    outline: none;
  }
`;

const StepperNumberInputWrapper = styled.div`
  display: flex;
  width: fit-content;
  border: solid 1px ${({ theme }) => theme.COLORS.LightBlue};
  border-radius: 8px;

  :focus-within {
    border: solid 1px ${({ theme }) => theme.COLORS.Primary};
  }
`;

const StepperNumberInput: FC<StepperNumberInputPropsType> = (props) => {
  const { value, minValue, maxValue, onChange, defaultColor, isDisabled = false } = props;

  const [inputValue, setInputValue] = useState<string>();

  useEffect(() => {
    const initInputValue = value.toString();
    setInputValue(initInputValue);
  }, [value]);

  const handleIncreaseValue = () => {
    const computedValue = Number(inputValue);
    if ((!maxValue && maxValue !== 0) || computedValue < maxValue) {
      onChange(computedValue + 1);
    }
  };

  const handleDecreaseValue = () => {
    const computedValue = Number(inputValue);
    if ((!minValue && minValue !== 0) || computedValue > minValue) {
      onChange(computedValue - 1);
    }
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputNumber = event.target.value;
    const numberPattern = /^-?\d*$/g;
    const isValid = numberPattern.test(inputNumber);
    if (isValid) {
      let computedInputNumber = Number(inputNumber) || 0;
      // Case: when user type zero twice times input will get '00' so we change it to '0' for display
      const zeroPattern = /^0+/g;
      const zeroWithPrefixPattern = /^(-?)(0*)/g;
      inputNumber = zeroPattern.test(inputNumber) ? "0" : inputNumber.replace(zeroWithPrefixPattern, "$1");

      if ((maxValue || maxValue === 0) && computedInputNumber > maxValue) {
        // Case: maxValue is assigned and zero is possible (maxValue = 0)
        computedInputNumber = maxValue;
        inputNumber = String(maxValue);
      } else if ((minValue || minValue === 0) && computedInputNumber < minValue) {
        // Case: minsValue is assigned and zero is possible (minValue = 0)
        computedInputNumber = minValue;
        inputNumber = String(minValue);
      }

      onChange(computedInputNumber);
      setInputValue(String(inputNumber));
    } else {
      onChange(Number(MIN_QUANTITY));
      setInputValue(String(MIN_QUANTITY));
    }
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputNumber = event.target.value;
    setInputValue(inputNumber.replace(/^(-?)(0?)$/g, "0"));
  };

  return (
    <StepperNumberInputWrapper>
      <StepperControlButton side="left" onClick={handleDecreaseValue} disabled={isDisabled}>
        <SvgIcon className="ml-1 pt-1 pr-1" component={IcSubtract} fontSize="small" />
      </StepperControlButton>
      <NumberInput
        defaultColor={defaultColor}
        max={maxValue}
        min={minValue}
        onChange={handleChangeInput}
        onBlur={handleBlur}
        value={inputValue}
      />
      <StepperControlButton side="right" onClick={handleIncreaseValue} disabled={isDisabled}>
        <SvgIcon className="ml-1 pt-1 pr-1" component={IcAdd} fontSize="small" />
      </StepperControlButton>
    </StepperNumberInputWrapper>
  );
};

export default StepperNumberInput;
