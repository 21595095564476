import React, { HTMLAttributes } from "react";
import { PROJECT } from "constants/Router";
import useCurrentPage from "routers/useCurrentPage";
import { StyledContainer } from "./Styled";

const AppContainer: React.FC<HTMLAttributes<HTMLTableSectionElement>> = ({ children }) => {
  const { currentPage } = useCurrentPage();

  return <StyledContainer className={currentPage === PROJECT ? "pt-0" : ""}>{children}</StyledContainer>;
};

export default AppContainer;
