import MaskedInput from "react-text-mask";
import styled from "styled-components";
import DayPickerComponent from "react-day-picker";
import { MenuItem, Select as DefaultSelect, Button as DefaultButton, Grid, createMuiTheme } from "@material-ui/core";
import { COLORS } from "constants/Colors";
import "react-day-picker/lib/style.css";

// to override MUI class
export const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        "&&&&:hover:before": {
          border: "none",
        },
      },
    },
  },
});

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  color: ${COLORS.DarkGray};
`;

export const DateText = styled.span`
  margin: 0 3px !important;
  font-size: 14px;
  color: ${COLORS.DarkGray};
`;

export const DaypickerWrapper = styled(Grid)`
  background-color: ${COLORS.White};
`;

export const DayPicker = styled(DayPickerComponent)`
  width: 100%;

  .DayPicker-NavButton {
    display: none;
  }

  .DayPicker-Day--outside {
    pointer-events: none;
  }

  .DayPicker-Day {
    display: flex;
    flex: 1;
    justify-content: center;
    border-radius: 0;
    height: 25px;
    text-align: center;
    align-items: center;
    padding: 3px;
  }

  .DayPicker-Body {
    font-size: 12px;
  }

  .DayPicker-Month {
    margin: 1em 0;
  }

  .DayPicker-wrapper {
    padding-bottom: 0.5em;
  }

  .DayPicker-Weekdays {
    display: flex;
    margin: 0;
    padding: 0 0.5em;
  }

  .DayPicker-Week {
    display: flex;
    margin: 5px 10px;
  }

  .DayPicker-Day {
    flex: 1;
    border-radius: 0;
    width: 32px;
    height: 32px;
  }

  .DayPicker-Week > .DayPicker-Day--selected:first-child {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    border-left: 1px solid #2f4069;
  }

  .DayPicker-Week > .DayPicker-Day--selected:last-child {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-right: 1px solid #2f4069;
  }

  .DayPicker-Day--start {
    background: radial-gradient(
      at center center,
      ${COLORS.Primary} 0%,
      ${COLORS.Primary} 71%,
      ${COLORS.Primary} 48%,
      ${COLORS.LightGray} 45%,
      ${COLORS.LightGray} 48%
    );
    border: 1px solid ${COLORS.Primary};
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    border-right: transparent;
  }

  .DayPicker-Day--end {
    background: radial-gradient(
      at center center,
      ${COLORS.Primary} 0%,
      ${COLORS.Primary} 71%,
      ${COLORS.Primary} 48%,
      ${COLORS.LightGray} 45%,
      ${COLORS.LightGray} 48%
    );
    border: 1px solid ${COLORS.Primary};
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-left: transparent;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
    background-color: ${COLORS.LightGray} !important;
    color: ${COLORS.DarkGray};
    border-top: 1px solid ${COLORS.Primary};
    border-bottom: 1px solid ${COLORS.Primary};
  }
`;

export const TimeInput = styled(MaskedInput)`
  margin: 0 5px !important;
  border-radius: 8px !important;
  padding: 10px 0;
  border: 1px solid ${COLORS.LightBlue};
  max-width: 6em;
  font-size: 14px !important;
  text-align: center;
  color: ${COLORS.DarkGray} !important;
  outline: none;

  input {
    text-align: center;
  }

  input:focus {
    border: 1px solid ${COLORS.LightBlue};
  }
`;

export const SelectYearWrapper = styled.div`
  font-size: 14px;
  color: ${COLORS.DarkGray};
  border-bottom: 1px solid ${COLORS.LightBlue};
`;

export const Button = styled(DefaultButton)`
  padding: 0;
  z-index: 1;

  &.default {
    min-width: 30px;
  }
`;

export const GridColumn = styled(Grid)`
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 19px;
`;

export const NavbarColumn = styled(Grid)`
  align-items: center;
  justify-content: flex-start;
  display: flex;
`;

export const NavbarWrapper = styled(Grid)`
  position: absolute;
  top: 6%;
`;

export const Select = styled(DefaultSelect)`
  position: fixed;
  display: flex;
  justify-content: center;
  padding-left: 8em;

  :before,
  :after {
    border: none;
  }

  .MuiSelect-select {
    padding: 0;
  }

  .MuiSelect-icon {
    top: calc(50% - 10px);
  }
`;

export const SelectItem = styled(MenuItem)`
  font-size: 12px;
  color: ${COLORS.DarkGray};

  :hover {
    color: ${COLORS.White};
    background-color: ${COLORS.Primary};
  }
`;

export const MonthText = styled.span`
  font-size: 14px;
  padding: 0 0 0 8px;
  color: ${COLORS.DarkGray} !important;
`;

export const YearText = styled.span`
  font-size: 14px;
  padding: 0 8px;
  color: ${COLORS.DarkLight} !important;
`;

export const PrevNextWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &.next {
    margin-right: 5%;
  }

  &.prev {
    margin-left: 5%;
  }
`;

export const SelectTime = styled.div`
  &.native {
    width: 100%;
    display: grid;
    margin: 0;
    justify-items: center;
    text-align: center;
  }
`;
