import React from "react";
import { CellProps } from "react-table";
import formattedWeight from "../../helper/formattedWeight";

const getWeight = <T extends object>(t: Function) => ({
  Header: `${t("Weight")}(${t("gram")})`,
  accessor: "totalWeight",
  // eslint-disable-next-line react/display-name
  Cell: (cell: CellProps<T>) => <div>{formattedWeight(cell.value, t)}</div>,
});

export default getWeight;
