import React, { FC, useState, ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Avatar from "components/Avatar";
import FloatBadge from "components/Badge/FloatBadge";
import Grid from "components/Grid";
import Typography from "components/Typography";
import TextField from "components/TextField";
import { ButtonWithIcon } from "components/Button";
import { SvgIcon } from "components/Icon";
import { IcError, IcCheck, IcEdit } from "components/SvgIcons";
import { CUSTOMER_DISPLAY_NAME_CHARACTER } from "config/customer";
import { SPECIAL_CHAR } from "constants/RegExp";
import { notifyWarn } from "utils/notify";
import { AvatarWrapper, IconWrapper } from "./styled";

type DisplayNameWithAvatarPropsType = {
  displayName: string;
  avatarImage: string;
  platform: string;
  description?: ReactNode;
  isBig?: boolean;
  isEditDisplayName?: boolean;
  onChangeDisplayName?: (displayName: string) => void;
  redirectLink?: string;
};

const DisplayNameWithAvatar: FC<DisplayNameWithAvatarPropsType> = ({
  displayName: initDisplayName,
  avatarImage,
  platform,
  description,
  isBig,
  isEditDisplayName,
  onChangeDisplayName = () => {},
  redirectLink,
}) => {
  const { t } = useTranslation();

  const [isEdit, setIsEdit] = useState(false);
  const [displayName, setDisplayName] = useState(initDisplayName);

  useEffect(() => {
    setDisplayName(initDisplayName);
  }, [initDisplayName]);

  const handleInputDisplayName = (newDisplayName: string) => {
    const trimmedDisplayName = newDisplayName.trim();

    if (trimmedDisplayName.length <= CUSTOMER_DISPLAY_NAME_CHARACTER) {
      setDisplayName(newDisplayName);
    }
  };

  const handleCancelEdit = () => {
    setDisplayName(initDisplayName);
    setIsEdit(false);
  };

  const handleChangeDisplayName = () => {
    const isContainSpecialCharacter = displayName.match(SPECIAL_CHAR);

    if (isContainSpecialCharacter) {
      notifyWarn(t("customer.displayName.error"));
    } else {
      setIsEdit(false);
      onChangeDisplayName(displayName);
    }
  };

  return (
    <Grid container className="border-0 py-2 flex-nowrap" alignItems="center">
      <Grid item className="d-flex flex-grow-0 pr-3">
        <AvatarWrapper>
          <FloatBadge
            overlap="circle"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              platform ? (
                <Avatar
                  size={isBig ? "24px" : "16px"}
                  alt={platform}
                  src={`/assets/icon/ic-${platform.toLowerCase()}-color@3x.png`}
                />
              ) : (
                <div />
              )
            }
          >
            <Avatar size={isBig ? "80px" : "40px"} src={avatarImage} />
          </FloatBadge>
        </AvatarWrapper>
      </Grid>
      {isEdit ? (
        <Grid item className="d-flex flex-grow-1">
          <TextField
            fullWidth
            value={displayName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputDisplayName(e.target.value)}
          />
        </Grid>
      ) : (
        <Grid item container className="d-flex flex-grow-1" alignItems="center">
          <Grid item xs={12} container alignItems="center">
            {redirectLink ? (
              <Link to={redirectLink}>
                <Typography variant="title4">{displayName}</Typography>
              </Link>
            ) : (
              <Typography variant="title4">{displayName}</Typography>
            )}
          </Grid>
          {description && (
            <Grid item xs={12}>
              <Typography variant="body4" color="darkMed">
                {description}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
      {isEditDisplayName && (
        <IconWrapper item className="d-flex flex-grow-0">
          {isEdit ? (
            <>
              <ButtonWithIcon className="ml-1" onClick={handleCancelEdit}>
                <SvgIcon className="ml-1 mt-1" component={IcError} fontSize="small" />
              </ButtonWithIcon>
              <ButtonWithIcon className="ml-1" onClick={handleChangeDisplayName}>
                <SvgIcon className="ml-1 mt-1" component={IcCheck} fontSize="small" />
              </ButtonWithIcon>
            </>
          ) : (
            <ButtonWithIcon className="ml-1" onClick={() => setIsEdit(true)}>
              <SvgIcon className="ml-1 mt-1" component={IcEdit} fontSize="small" />
            </ButtonWithIcon>
          )}
        </IconWrapper>
      )}
    </Grid>
  );
};

export default DisplayNameWithAvatar;
