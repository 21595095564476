import React from "react";
import styled from "styled-components";
import { CellProps } from "react-table";
import { IcEdit } from "components/SvgIcons";

const IconEditWrapper = styled.span`
  padding: 5px;
  margin-left: 4px;
  font-size: 12px;
  color: ${({ theme }) => theme.COLORS.DarkMed};
  border-radius: 6px;
  border: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
  background-color: ${({ theme }) => theme.COLORS.White};
  &:hover {
    color: ${({ theme }) => theme.COLORS.Primary};
    border: solid 0.5px ${({ theme }) => theme.COLORS.Primary};
    background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  }
`;

const Wrapper = styled.span`
  font-weight: 500;
  /* unvisited link */
  a:link {
    color: ${({ theme }) => theme.COLORS.Dark};
  }

  /* visited link */
  a:visited {
    color: ${({ theme }) => theme.COLORS.Dark};
  }

  /* mouse over link */
  a:hover {
    color: ${({ theme }) => theme.COLORS.DarkMed};
  }

  /* selected link */
  a:active {
    color: ${({ theme }) => theme.COLORS.Dark};
  }
`;

const Edit = <T extends object>() => {
  const renderCell = (cell: CellProps<T> & { handleClickEdit?: Function }) => (
    <Wrapper>
      <IconEditWrapper
        onClick={() => {
          if (cell?.handleClickEdit) {
            cell?.handleClickEdit(cell?.row?.original);
          }
        }}
      >
        <IcEdit />
      </IconEditWrapper>
    </Wrapper>
  );

  return {
    Header: "",
    accessor: "edit",
    disableSortBy: true,
    width: 70,
    Cell: renderCell,
  };
};

export default Edit;
