import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useController, useFormContext } from "react-hook-form";
import Card from "components/Card";
import Grid from "components/Grid";
import Typography from "components/Typography";

import { RadioButton, SelectedRadio } from "components/Radio";
import useDevice from "utils/hooks/useDevice";
import { Device } from "types/Device";
import { CHAT_FLOW, WEB_VIEW_FLOW } from "constants/Image";
import { COLORS } from "constants/Colors";
import { CardImageRadio, CardTitleWrapper } from "./styled";

type BotFlowSettingFormPropsType = {
  isOpenWebviewOnSelectProductEnabled: boolean;
};

const FLOWS = [
  {
    title: "botSetting.section.chatFlowSetting.radio.chatbot.title",
    value: false,
    image: CHAT_FLOW,
    wrapperMobileClass: "pb-3",
    wrapperDesktopClass: "pr-3",
  },
  {
    title: "botSetting.section.chatFlowSetting.radio.webview.title",
    value: true,
    image: WEB_VIEW_FLOW,
    wrapperMobileClass: "",
    wrapperDesktopClass: "pl-3",
  },
];

const BotFlowSettingForm: FC<BotFlowSettingFormPropsType> = (props) => {
  const { t } = useTranslation();
  const { isOpenWebviewOnSelectProductEnabled } = props;
  const { control } = useFormContext();
  const { field } = useController({
    name: "isOpenWebviewOnSelectProductEnabled",
    control,
    defaultValue: isOpenWebviewOnSelectProductEnabled,
  });
  const device = useDevice();
  const isMobile = device === Device.MOBILE;

  const handleClickFlow = (value: boolean) => {
    field.onChange(value);
    return null;
  };
  return (
    <Card className="w-100 mx-3 px-4 pb-4 pt-3">
      <Typography variant="title2">{t("botSetting.section.chatFlowSetting.title")}</Typography>
      <Grid container className="p-0 pb-3 pt-1" alignItems="center">
        <Typography color={COLORS.DarkGray} variant="body4">
          {t("botSetting.section.chatFlowSetting.tooltip.description")}
        </Typography>
      </Grid>
      <Grid container alignItems="center">
        {FLOWS.map((flow) => {
          const isActive = !!flow.value === !!field.value;
          return (
            <Grid
              item
              xs={isMobile ? 12 : 6}
              className={isMobile ? flow.wrapperMobileClass : flow.wrapperDesktopClass}
              key={flow.title}
            >
              <CardImageRadio
                isActive={isActive}
                className="p-4"
                container
                item
                onClick={() => handleClickFlow(flow.value)}
                direction="column"
              >
                <Grid item xs={12} container justify="center" alignItems="center" className="pb-4">
                  <CardTitleWrapper>
                    <RadioButton isActive={isActive}>{isActive && <SelectedRadio />}</RadioButton>
                    <Typography variant="title8" className="ml-2">
                      {t(flow.title)}
                    </Typography>
                  </CardTitleWrapper>
                </Grid>
                <Grid item xs={12} container justify="center" className="pb-4">
                  <img src={flow.image} alt="chat-flow" width="100%" />
                </Grid>
              </CardImageRadio>
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
};

export default BotFlowSettingForm;
