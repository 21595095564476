import * as React from "react";

export default function IcEye(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.49939 6C7.49939 6.82843 6.82782 7.5 5.99939 7.5C5.17096 7.5 4.49939 6.82843 4.49939 6C4.49939 5.17157 5.17096 4.5 5.99939 4.5C6.82782 4.5 7.49939 5.17157 7.49939 6Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.22852 5.99998C1.86565 3.97144 3.76079 2.5 5.99961 2.5C8.23844 2.5 10.1336 3.97146 10.7707 6.00002C10.1336 8.02856 8.23843 9.5 5.99962 9.5C3.76079 9.5 1.86564 8.02855 1.22852 5.99998Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
