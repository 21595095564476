import { useMutation } from "@apollo/client";

import { CHAT_MENUS } from "graphql/richMenu/query";
import { ACTIVATE_CHAT_MENU, DEACTIVATE_CHAT_MENU } from "graphql/richMenu/mutation";
import { PLATFORM } from "types/RichMenu";

type ChatMenuQueryVariables = {
  projectId: string;
  chatMenuId: string;
};

const useActivateChatMenu = (projectId: string, platform: PLATFORM) => {
  const refetchQueries = [
    {
      query: CHAT_MENUS,
      variables: { projectId, platform },
    },
  ];
  const [activateChatMenu, { loading: activatingChatMenu, error: activatingChatMenuError }] = useMutation<
    ChatMenuQueryVariables
  >(ACTIVATE_CHAT_MENU, {
    refetchQueries,
  });

  const [deactivateChatMenu, { loading: deactivatingChatMenu, error: deactivatingChatMenuError }] = useMutation<
    ChatMenuQueryVariables
  >(DEACTIVATE_CHAT_MENU, {
    refetchQueries,
  });

  const handleActivateChatMenu = (chatMenuId: string) => {
    activateChatMenu({
      variables: {
        projectId,
        chatMenuId,
      },
    });
  };

  const handleDeactivateChatMenu = (chatMenuId: string) => {
    deactivateChatMenu({
      variables: {
        projectId,
        chatMenuId,
      },
    });
  };

  return {
    activatingChatMenu,
    activatingChatMenuError,
    deactivatingChatMenu,
    deactivatingChatMenuError,
    handleActivateChatMenu,
    handleDeactivateChatMenu,
  };
};

export default useActivateChatMenu;
