import { useQuery, useMutation } from "@apollo/client";
import { OrderReportQueryType, SortByType } from "types/Report";
import { ReportQueryVariableType } from "types/SalesReport";
import { SHIPPOP_REPORTS } from "graphql/salesReport/query";
import { CANCEL_SHIPPOP_ORDER } from "graphql/order/mutation/cancelShippopOrder";

type UseQueryOrderReportsType = {
  projectId: string;
  isSkip?: boolean;
  page: number;
  rowsPerPage: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter: Record<string, any>;
  sortBy: SortByType;
};

const useQueryOrderReports = ({ projectId, isSkip, filter, sortBy, page, rowsPerPage }: UseQueryOrderReportsType) => {
  const composeFilter = { ...sortBy, ...filter };
  const variables = {
    projectId,
    pageSize: rowsPerPage,
    page: page - 1,
    filter: composeFilter,
  };
  const { data, loading, refetch } = useQuery<OrderReportQueryType, ReportQueryVariableType>(SHIPPOP_REPORTS, {
    variables,
    skip: !projectId || isSkip,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const [cancelOrder] = useMutation(CANCEL_SHIPPOP_ORDER, {
    update(cache, { data: { cancelShippopOrder } }) {
      const cachedShippopOrder = cache.readQuery<OrderReportQueryType>({
        query: SHIPPOP_REPORTS,
        variables,
      });

      if (cachedShippopOrder) {
        const updatedCachedShippopOrder = cachedShippopOrder.orderReports.results.filter(
          (orderReport) => orderReport.id !== cancelShippopOrder,
        );

        cache.writeQuery({
          query: SHIPPOP_REPORTS,
          variables,
          data: {
            orderReports: {
              results: updatedCachedShippopOrder,
              total: cachedShippopOrder.orderReports.total - 1,
              summary: cachedShippopOrder.orderReports.summary,
            },
          },
        });
      }
    },
  });

  const orderReports = data?.orderReports?.results || [];
  const total: number = data?.orderReports.total || 0;

  return {
    cancelOrder,
    loading,
    orderReports,
    refetch,
    total,
  };
};

export default useQueryOrderReports;
