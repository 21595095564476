import styled from "styled-components";
import Grid from "components/Grid";

const ImportProductButtonGrid = styled(Grid)`
  flex: 0;
  ${({ theme }) => theme.breakpoints.down("xs")} {
    min-width: initial;
  }
`;

export default ImportProductButtonGrid;
