import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import times from "lodash/times";

import Button from "components/Button";
import Grid from "components/Grid";
import Typography from "components/Typography";
import Card from "components/Card";
import CircleRemoveIcon from "components/CircleRemoveIcon";
import Modal from "components/Modal";

import { SvgIcon } from "components/Icon";
import { IcAdd } from "components/SvgIcons";
import { COLORS } from "constants/Colors";
import { AddonType, AddonItemType, AddonFilterType } from "types/Addon";

import { IconWrapper, NewItemWrapper, ItemWrapperWithCloseIcon } from "./styled";

type AddonItemCardPropsType = {
  addonType: AddonFilterType;
  defaultAmount: number;
  currentAmount: number;
  currentUsageAmount: number;
  selectedAmount: number;
  onAddNewAddon: (addonType: string) => void;
  onRemoveAddon: (addonType: string) => void;
  addonIcon: React.ElementType<React.SVGProps<SVGSVGElement>>;
  addonIconViewBox: string;
  redirectRoute: string;
  addonData?: AddonType;
  itemLimit?: number;
};

const AddonItemCard: FC<AddonItemCardPropsType> = (props) => {
  const { t } = useTranslation();
  const {
    addonType,
    defaultAmount,
    currentAmount,
    currentUsageAmount,
    onAddNewAddon,
    selectedAmount,
    onRemoveAddon,
    addonIcon,
    redirectRoute,
    addonData,
    itemLimit,
    addonIconViewBox,
  } = props;

  const [isOpenModal, setIsOpenModal] = useState(false);

  const currentUsageQuota = defaultAmount + currentAmount;

  const handleAddNewItem = () => {
    onAddNewAddon(addonType as string);
  };

  const handleRemoveNewItem = () => {
    if (currentUsageQuota + selectedAmount <= currentUsageAmount) {
      setIsOpenModal(true);
    } else if (currentAmount + selectedAmount > 0) {
      onRemoveAddon(addonType as string);
    }
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const renderItem = (amount: number, type?: AddonItemType) => {
    if (amount) {
      return times(amount, (index: number) => (
        <ItemWrapperWithCloseIcon item className="position-relative" key={index}>
          {/* close button (set default display to none and last nth display) */}
          {type && (
            <CircleRemoveIcon onClick={handleRemoveNewItem} right={-3} size={16} top={-2} className="closeButton" />
          )}
          <IconWrapper type={type}>
            <SvgIcon
              className="m-0 p-0"
              viewBox={addonIconViewBox}
              htmlColor={type === AddonItemType.NEW ? COLORS.Primary : COLORS.DarkMed}
              component={addonIcon}
              fontSize="default"
            />
          </IconWrapper>
        </ItemWrapperWithCloseIcon>
      ));
    }

    return null;
  };

  const displayedCurrentAmount = selectedAmount < 0 ? currentAmount + selectedAmount : currentAmount;
  const displayedChangedAmount = selectedAmount > 0 ? `+${selectedAmount}` : selectedAmount;
  const pricePerUnit = addonData ? addonData.pricePerMonth : 0;

  const itemAmount = defaultAmount + displayedCurrentAmount + selectedAmount;
  const displayItem = (itemLimit && itemAmount < itemLimit) || !itemLimit ? "" : "d-none";

  return (
    <>
      <Card noShadow padding="20px">
        <Grid container>
          <Grid item container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="title7" color="dark">
                {t(`planAddon.addonCard.title.${addonType.toLowerCase()}`)}
              </Typography>
            </Grid>
            {!!pricePerUnit && (
              <Grid item>
                <Typography variant="body3" color="darkGray">
                  {t("THB")}
                  {t("planAddon.addonCard.monthlyPricePerUnit", {
                    pricePerUnit,
                    unit: t(`planAddon.unit.${addonType.toLowerCase()}`),
                  })}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid item container alignContent="space-between" spacing={1} className="py-2 my-1">
            {renderItem(defaultAmount)}
            {renderItem(displayedCurrentAmount, AddonItemType.CURRENT)}
            {renderItem(selectedAmount, AddonItemType.NEW)}

            <Grid item className={displayItem}>
              <NewItemWrapper onClick={handleAddNewItem}>
                <SvgIcon htmlColor={COLORS.DarkMed} component={IcAdd} fontSize="default" />
              </NewItemWrapper>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item className="pr-1">
              <Typography variant="body4" color="darkGray">
                {t("planAddon.addonCard.currentAmount", { currentAmount: currentUsageQuota })}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body4" color={selectedAmount < 0 ? "error" : "primary"}>
                {!!selectedAmount && displayedChangedAmount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <Modal isOpen={isOpenModal} onClose={handleCloseModal}>
        <Grid container className="p-3">
          <Grid item container justify="center" className="m-4">
            <Grid item>
              <Typography variant="title7" className="text-center">
                {t(`planAddon.addonCard.modal.text.warningRemove.${addonType.toLowerCase()}`)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={6} className="px-1">
              <Button size="small" type="button" color="secondary" onClick={handleCloseModal} fullWidth>
                {t("Cancel")}
              </Button>
            </Grid>
            <Grid item xs={6} className="px-1">
              <Link to={redirectRoute}>
                <Button size="small" type="button" fullWidth>
                  {t(`planAddon.addonCard.modal.text.confirm.${addonType.toLowerCase()}`)}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default AddonItemCard;
