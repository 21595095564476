import React, { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import Card from "components/Card";
import Typography from "components/Typography";
import { ButtonWithIcon } from "components/Button";
import { IcEdit } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";
import COLORS from "constants/Colors";

import { BILLING } from "graphql/billing/query";
import { BillingType } from "types/Billing";
import { ProjectIdType } from "types/Project";

import useIsDesktop from "utils/hooks/useIsDesktop";
import BillingDisplayInfo from "./BillingDisplayInfo";
import BillingForm from "./BillingForm";
import { IconWrapper } from "../styled";

const BillingInfo: FC = () => {
  const { projectId } = useParams<ProjectIdType>();
  const isDesktop = useIsDesktop();

  const { t } = useTranslation();

  const { loading: loadingQuery, data } = useQuery(BILLING, {
    skip: !projectId,
    variables: {
      projectId,
    },
  });

  const billing: BillingType = data?.billing;

  const [isEdit, setIsEdit] = useState(false);

  const handleClickEdit = () => {
    setIsEdit(true);
  };

  return (
    <Card noShadow padding="0px" borderRadius={isDesktop ? 8 : 0}>
      <Grid container item className="px-4 py-3" alignContent="center" alignItems="center" style={{ height: 60 }}>
        <Grid item xs={6}>
          <Typography variant="title10" color="darkLight">
            {t("planBilling.title.billingInfo")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {!isEdit && (
            <Grid item>
              <IconWrapper>
                <ButtonWithIcon className="ml-1" onClick={handleClickEdit}>
                  <SvgIcon className="ml-1" component={IcEdit} fontSize="small" htmlColor={COLORS.DarkMed} />
                </ButtonWithIcon>
              </IconWrapper>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container className="px-4 pb-3 my-1 position-relative">
        <Grid container item xs={12} justify="center" alignItems="center" alignContent="center" />
        {isEdit ? (
          <BillingForm billing={billing} setIsEdit={setIsEdit} />
        ) : (
          <Grid container item xs={12} justify="center">
            <BillingDisplayInfo billing={billing} loading={loadingQuery} />
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default BillingInfo;
