const responseMessage = {
  en: {
    RESPONSE_MESSAGE: "Response message",
    SEARCH_RESPONSE_MESSAGE: "Search name, keyword in response message",
    MESSAGE_USED: "Message used",
    DEFAULT_MESSAGE: "Default message",
    RESPONSE_MESSAGE_NOT_FOUND: "Response message not found",
    DEFAULT: "Default",
    SELECTED_RESPONSE: "Selected response",
    MESSAGE_BUILDER: "This message is built by the system",
    ADD_CUSTOM_RESPONSE: "Add custom response",
    RESET_TO_DEFAULT: "Reset to default",
    ARE_YOU_SURE_TO_REMOVE: "Are you sure you want to remove ?",
    ARE_YOU_SURE_TO_RESET_CUSTOM_RESPONSE_MESSAGES: "Are you sure to reset your custom response messages to default ?",
    YOUR_CHANGES_COULD_NOT_BE_SAVED: `Your changes could not be saved. Are you sure to exit from this section ?`,
    GO_NEXT: "Confirm",
    RESPONSE_MESSAGE_CAN_NOT_BE_EMPTY: "Response message is required",
  },
  th: {
    RESPONSE_MESSAGE: "ข้อความตอบกลับ",
    SEARCH_RESPONSE_MESSAGE: "ค้นหาชื่อ, คีย์เวิร์ดในข้อความตอบกลับ",
    MESSAGE_USED: "ข้อความที่ถูกใช้งาน",
    DEFAULT_MESSAGE: "ข้อความเริ่มต้น",
    RESPONSE_MESSAGE_NOT_FOUND: "ไม่พบข้อความตอบกลับ",
    DEFAULT: "ค่าเริ่มต้น",
    SELECTED_RESPONSE: "ตัวเลือกข้อความตอบกลับ",
    MESSAGE_BUILDER: "ข้อความพิเศษที่ระบบสร้างขึ้น",
    ADD_CUSTOM_RESPONSE: " เพิ่มข้อความตอบกลับแบบกำหนดเอง",
    RESET_TO_DEFAULT: "คืนค่าเป็นค่าเริ่มต้น",
    ARE_YOU_SURE_TO_REMOVE: "คุณยืนยันที่จะลบหรือไม่ ?",
    ARE_YOU_SURE_TO_RESET_CUSTOM_RESPONSE_MESSAGES: "คุณยืนยันที่จะคืนค่าข้อความตอบกลับสู่ค่าเริ่มต้น ?",
    YOUR_CHANGES_COULD_NOT_BE_SAVED: `ข้อมูลที่แก้ไขของคุณยังไม่ถูกบันทึก คุณยังยืนยันที่จะออกจากส่วนนี้หรือไม่ ?`,
    GO_NEXT: "ยืนยัน",
    RESPONSE_MESSAGE_CAN_NOT_BE_EMPTY: "กรุณาใส่ข้อความตอบกลับ",
  },
};

export default responseMessage;
