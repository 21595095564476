import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";

import Grid from "components/Grid";
import Typography from "components/Typography";

import COLORS from "constants/Colors";
import { BILLING, PLAN, PROJECT } from "constants/Router";
import { ProjectIdType } from "types/Project";
import { Device } from "types/Device";
import useDevice from "utils/hooks/useDevice";

import { Wrapper, StyledButton } from "./styled";

export type PlanAddonChangedBroadcastPropsType = {
  title: string;
};

const PlanAddonChangedBroadcast: FC<PlanAddonChangedBroadcastPropsType> = ({ title }) => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const history = useHistory();
  const device = useDevice();
  const isDesktop = Device.DESKTOP === device;

  const handleClickButton = () => {
    history.push(`/${PROJECT}/${projectId}/${PLAN}/${BILLING}`);
  };

  return (
    <Wrapper container className={isDesktop ? "px-4 py-3" : "p-3"}>
      <Grid item container justify="space-between" alignItems="center" spacing={2}>
        <Grid item container xs={12} sm={8} lg={9}>
          <Grid item xs={12}>
            <Typography variant="title7" color={COLORS.InfoYellow}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} className="pt-2">
            <Typography variant="body3" color={COLORS.InfoYellow}>
              {t("planAddon.changedBroadcast.description")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3} lg={2}>
          <StyledButton size="small" fullWidth onClick={handleClickButton}>
            {t("planAddon.changedBroadcast.button")}
          </StyledButton>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default PlanAddonChangedBroadcast;
