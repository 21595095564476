export const FRESH_DESK_CHAT_PAGE_BOTTOM = "default-position-chat-page-fresh-desk";
export const FRESH_DESK_DEFAULT_POSITION = "default-position-fresh-desk";
export const FRESH_DESK_ELEMENT_ID = "fc_frame";
export const FRESH_DESK_WIDGET_CONTENT_TRANSLATION = {
  actions: {
    csat_yes: "ใช่",
    csat_no: "ไม่",
    push_notify_yes: "ใช่",
    push_notify_no: "ไม่",
    tab_faq: "คู่มือการใช้งาน",
    tab_chat: "แชท",
    csat_submit: "ยืนยัน",
  },
  headers: {
    chat: "แชทกับเจ้าหน้าที่ deeple Support",
    chat_help: "ติดต่อเจ้าหน้าที่ deeple Support",
    faq: "คู่มือการใช้งาน",
    faq_help: "ค้นหาคู่มือการใช้งาน",
    faq_not_available: "ไม่พบสิ่งที่ค้นหา ",
    faq_search_not_available: "ไม่พบบทความเกี่ยวกับ {{query}}",
    faq_useful: "บทความนี้มีประโยชน์หรือไม่",
    faq_thankyou: "ขอบคุณสำหรับความคิดเห็น",
    faq_message_us: "ส่งข้อความถึงเจ้าหน้าที่ deeple Support",
    push_notification: "อนุญาตการแจ้งเตือน เพื่อไม่ให้พลาดการตอบกลับ",
    csat_question: "คุณลูกค้าพึงพอใจในการให้บริการของเราหรือไม่",
    csat_yes_question: "รบกวนให้คะแนนความพึงพอใจการบริการ ",
    csat_no_question: "รบกวนให้ความคิดเห็นเพื่อให้เราบริการได้ดีขึ้น",
    csat_thankyou: "ขอบคุณสำหรับการให้คะแนน",
    csat_rate_here: "ยืนยันการให้คะแนน",
    channel_response: {
      offline: "ขณะนี้อยู่นอกเวลาทำการ กรุณาฝากข้อความไว้",
      online: {
        minutes: {
          one: "กำลังตอบกลับใน {!time!} นาที ",
          more: "ปกติแล้วจะตอบกลับภายใน {!time!} นาที",
        },
        hours: {
          one: "กำลังตอบกลับภายใน 1 ชั่วโมง ",
          more: "ปกติแล้วจะตอบกลับภายใน {!time!} ชั่วโมง",
        },
      },
    },
  },
};
