import React from "react";
import { components, OptionProps } from "react-select";

import Checkbox from "components/Checkbox";
import { TagOption as TagOptionType } from "types/Chat";

export const TagOption = (optionProps: OptionProps<TagOptionType>) => {
  const { data } = optionProps;

  return (
    <components.Option {...optionProps}>
      <span className="d-flex align-items-center">
        <Checkbox checked={optionProps.isSelected} />
        <span className="ml-2">{data.label}</span>
      </span>
    </components.Option>
  );
};

export default TagOption;
