import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import Typography from "components/Typography";
import TextField from "components/TextField";
import Card from "components/Card";
import { RICH_MENU } from "constants/i18n";
import { ActionType, LinkValue, Action, DeepleAction, TextValue } from "types/RichMenu";
import { MAP_DEEPLE_ACTION } from "pages/LineRichMenu/components/Action/Dropdown/config";
import { RichMenuTypography } from "./RichMenuTypography";

type RichMenuDetailProps = {
  defaultTitle?: string;
};

const RichMenuDetail: FC<RichMenuDetailProps> = ({ defaultTitle }) => {
  const { t } = useTranslation(RICH_MENU);
  const { register, errors, watch } = useFormContext();
  const watchedAction: Action[] = watch("action");

  const renderPreview = (action: Action) => {
    switch (action.actionType) {
      case ActionType.URL:
        return (action?.value as LinkValue)?.description;

      case ActionType.TEXT:
        return (action?.value as TextValue)?.text;

      case ActionType.DEEPLE_ACTION:
        return t(MAP_DEEPLE_ACTION[action?.value as DeepleAction]);
      default:
        return action?.value;
    }
  };

  return (
    <Card noShadow padding="24px">
      <Typography variant="title3" className="mb-3">
        {t("DETAIL")}
      </Typography>

      <div className="mb-2">
        <Typography variant="body3" color="darkMed" className="mb-1">
          {t("TITLE")}
        </Typography>
        <TextField
          name="title"
          fullWidth
          variant="outlined"
          className="mb-2"
          inputRef={register}
          error={Boolean(errors?.title)}
          helperText={errors?.title?.message}
          defaultValue={defaultTitle || ""}
        />
        <Typography variant="body4" color="darkMed">
          {t("TITLE_FOR_MANAGEMENT_PURPOSE")}
        </Typography>
      </div>

      <div className="mb-2">
        <Typography variant="title4" color="darkMed" className="mb-1">
          {t("PREVIEW")}
        </Typography>
        <Card>
          {watchedAction?.length ? (
            watchedAction?.map((action, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <RichMenuTypography variant="body3" className="p-3" key={index}>
                {renderPreview(action)}
              </RichMenuTypography>
            ))
          ) : (
            <Typography variant="body3" color="darkMed">
              {t("PLEASE_SELECT_ACTION")}
            </Typography>
          )}
        </Card>
      </div>
    </Card>
  );
};

export default RichMenuDetail;
