import { boolean, InferType, object, string } from "yup";

import {
  RecurringNotificationOptInButtonMessageType,
  RecurringNotificationOptInDeliveryContextType,
  RecurringNotificationOptInFrequencyType,
} from "types/Broadcast";

import { MAX_TITLE_MESSAGE } from "./constants";

const schema = object({
  name: string().required("broadcast.facebookRequestForm.form.validation.name.required"),
  deliveryContextType: string().required().oneOf(Object.values(RecurringNotificationOptInDeliveryContextType)),
  messageTitle: string()
    .required("broadcast.facebookRequestForm.form.validation.messageTitle.required")
    .max(MAX_TITLE_MESSAGE, "broadcast.facebookRequestForm.form.validation.messageTitle.max"),
  messageImageUrl: string().nullable(),
  frequencyType: string()
    .default(RecurringNotificationOptInFrequencyType.DAILY)
    .required()
    .oneOf(Object.values(RecurringNotificationOptInFrequencyType)),
  buttonMessageType: string()
    .default(RecurringNotificationOptInButtonMessageType.FREQUENCY)
    .required()
    .oneOf(Object.values(RecurringNotificationOptInButtonMessageType)),
  isAutoRenew: boolean().default(true).required(),
  reactionMessage: string().nullable(),
  invitationMessage: string().nullable(),
});

export type Schema = InferType<typeof schema>;

export const schemaNames = Object.keys(schema.describe().fields).reduce(
  (prev, curr) => ({ ...prev, [curr]: curr }),
  {} as Record<keyof Schema, keyof Schema>,
);

export default schema;
