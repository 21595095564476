import { CSSProperties } from "react";
import { StylesConfig } from "react-select";
import Colors from "constants/Colors";
import { transparentize } from "polished";
import { OptionStateType, ControlStateType, CustomStyleSelectType } from "types/ReactSelect";

const getStyle = (isDisabled = false, noOutline = false): StylesConfig & CustomStyleSelectType => {
  return {
    input: (styles: CSSProperties) => ({
      ...styles,
      input: {
        lineHeight: "1.5rem",
        textIndent: "2px",
      },
    }),
    valueContainer: (styles: CSSProperties) => ({
      ...styles,
      padding: "0px 24px 0px 0px",
    }),
    control: (styles: CSSProperties, state: ControlStateType) => ({
      ...styles,
      width: "100%",
      minHeight: "34px",
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: noOutline ? "transparent" : isDisabled ? Colors.Light : Colors.White,
      borderRadius: "8px",
      border: noOutline ? "none" : `solid 1px ${state.isFocused ? Colors.Primary : Colors.LightBlue}`,
      color: Colors.DarkGray,
      boxShadow: "0px",
      ":hover": {
        borderColor: state.isFocused ? Colors.Primary : Colors.LightBlue,
        color: Colors.DarkLight,
      },
      ":active": {
        borderColor: Colors.Primary,
        color: Colors.DarkGray,
      },
    }),
    indicatorSeparator: (styles: CSSProperties) => ({
      ...styles,
      width: 0,
    }),
    menu: (styles: CSSProperties) => ({
      ...styles,
      width: "100%",
      minWidth: "180px",
      borderRadius: "8px",
      overflow: "hidden",
      border: `solid 1px ${Colors.Primary}`,
      boxShadow: "0 5px 10px 0 Colors.BoxShadow",
    }),
    option: (styles: CSSProperties, state: OptionStateType) => {
      let backgroundColor = Colors.White;
      let color = Colors.DarkGray;

      if (isDisabled) {
        backgroundColor = Colors.Light;
        color = Colors.LightBlue;
      } else if (state.isFocused) {
        backgroundColor = transparentize(0.15, Colors.Primary);
        color = Colors.LightWhite;
      } else if (state.isSelected) {
        backgroundColor = Colors.Primary;
        color = Colors.LightWhite;
      }

      return {
        ...styles,
        width: "100%",
        display: "flex",
        alignItems: "center",
        backgroundColor,
        color,
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          backgroundColor: Colors.Primary,
          color: Colors.LightWhite,
        },
      };
    },
    multiValue: (styles: CSSProperties) => ({
      ...styles,
      minHeight: "24px",
      display: "flex",
      alignItems: "center",
      backgroundColor: transparentize(0.94, Colors.Primary),
      borderRadius: "8px",
      border: `solid 0.5px ${Colors.Primary}`,
    }),
    multiValueLabel: (styles: CSSProperties) => ({
      ...styles,
      color: Colors.Primary,
    }),
    multiValueRemove: (styles: CSSProperties) => ({
      ...styles,
      color: Colors.Primary,
      minHeight: "24px",
      ":hover": {
        backgroundColor: transparentize(0.4, Colors.Primary),
        borderRadius: "0px 6px 6px 0px",
        color: "white",
      },
    }),
    noOptionsMessage: (styles: CSSProperties) => ({
      ...styles,
      color: Colors.DarkLight,
    }),
    placeholder: (styles: CSSProperties): CSSProperties => ({
      ...styles,
      color: isDisabled ? Colors.LightBlue : Colors.DarkLight,
    }),
  };
};

export default getStyle;
