import { Gender } from "types/AISetting";

export const MAP_LANGUAGE_DISPLAY_NAME: Record<string, string> = {
  th: "thDisplayName",
  en: "enDisplayName",
};

export const MAP_BOT_GENDER = {
  [Gender.FEMALE]: "femaleMessages",
  [Gender.MALE]: "maleMessages",
};

export const SPECIAL_RESPONSE_MESSAGE = {
  CUSTOM: "custom-message",
  FAQ: "faq-message",
  GREETING: "greetings",
  WELCOME: "welcome-message",
};
