export interface TokenType {
  token: string;
}

export interface EmailType {
  email: string;
}

export type AuthSuccessProps = {
  token: string;
  deviceProvider?: string | undefined;
  displayName?: string;
  authProvider: AuthProvider;
};

export enum AuthProvider {
  APPLE = "APPLE",
  FACEBOOK = "FACEBOOK",
  GOOGLE = "GOOGLE",
  DEEPLE = "DEEPLE",
}

export enum ErrorAccountConnectCode {
  SOCIAL_ID_ALREADY_EXISTED = "SOCIAL_LOGIN:SOCIAL_ID_ALREADY_EXISTED",
  PROVIDER_NOT_SUPPORTED = "SOCIAL_LOGIN:PROVIDER_NOT_SUPPORTED",
}
