import { yupResolver } from "@hookform/resolvers/yup";
import React, { FC, useMemo, memo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RICH_MENU } from "constants/i18n";
import Button from "components/Button";
import Grid from "components/Grid";
import { Action, InstagramFormData } from "types/RichMenu";
import ActionComponent from "../../components/Action";
import RichMenuDetail from "../../components/RichMenuDetail";
import getSchema from "../../schema";

type FormProps = {
  defaultTitle: string;
  defaultAction: Action[];
  onSubmit: (formData: InstagramFormData) => void;
};

type FormData = {
  title: string;
  action: Action[];
};

const Form: FC<FormProps> = ({ onSubmit, defaultAction, defaultTitle }) => {
  const { t } = useTranslation(RICH_MENU);
  const schema = useMemo(() => getSchema(t), [t]);
  const methods = useForm<FormData>({
    defaultValues: { action: defaultAction, title: defaultTitle },
    resolver: yupResolver(schema),
  });
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} className="px-0 px-sm-3 mb-4 mb-sm-0">
            <RichMenuDetail />
          </Grid>
          <Grid item xs={12} sm={6} md={8} className="px-0 px-sm-3">
            <ActionComponent />
          </Grid>
        </Grid>

        <div className="text-center text-sm-right my-3 px-0 px-sm-3">
          <Button type="submit" color="primary" style={{ width: 200 }}>
            {t("SAVE")}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default memo(Form);
