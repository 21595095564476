import { AddQuestionType } from "./useManageQuestions";

type OptionalType = {
  timeoutId?: number;
  setTimeoutId?: (timeoutId?: number) => void;
};

const useHandleAddQuestions = (
  addQuestion: AddQuestionType,
  questionsWrapperDOM: HTMLDivElement | null,
  { timeoutId, setTimeoutId }: OptionalType,
) => {
  const handleAddQuestion = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const addedQuestion = addQuestion();

    const newTimeoutId = setTimeout(() => {
      if (!document) {
        return;
      }

      const element = document.getElementById(addedQuestion.id);

      if (element) {
        element.focus();
      }

      if (questionsWrapperDOM) {
        // eslint-disable-next-line no-param-reassign
        questionsWrapperDOM.scrollTop = questionsWrapperDOM.scrollHeight; // scroll to bottom of questions wrapper
      }

      if (setTimeoutId) {
        setTimeoutId(undefined);
      }
    }, 200);

    if (setTimeoutId) {
      setTimeoutId(newTimeoutId);
    }
  };

  return handleAddQuestion;
};

export default useHandleAddQuestions;
