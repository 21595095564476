export const CONSOLE_GENERATED_FORM = "CONSOLE_GENERATED_FORM";
export const CONGRATULATIONS = "CONGRATULATIONS";
export const PLACE_ORDER_TO_LALAMOVE = "PLACE_ORDER_TO_LALAMOVE";
export const REGISTER = "REGISTER";
export const REGISTER_WITH_EMAIL = "REGISTER_WITH_EMAIL";
export const REPLY_SHORTCUT = "REPLY_SHORTCUT";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const STORE_CONNECT = "STORE_CONNECT";
export const RICH_MENU = "RICH_MENU";
export const NEW_FORGOT_PASSWORD = "NEW_FORGOT_PASSWORD";
export const NEW_LOGIN = "NEW_LOGIN";
export const NEW_RESET_PASSWORD = "NEW_RESET_PASSWORD";
export const NEW_REGISTER = "NEW_REGISTER";
export const NEW_VERIFY_EMAIL = "NEW_VERIFY_EMAIL";
export const NEW_QUICK_START = "NEW_QUICK_START";
export const CONNECT_FACEBOOK_PAGE = "CONNECT_FACEBOOK_PAGE";
export const CONNECT_INSTAGRAM_BUSINESS = "CONNECT_INSTAGRAM_BUSINESS";
export const UPDATE_CONNECT_CREDENTIAL = "UPDATE_CONNECT_CREDENTIAL";
