export const DEFAULT_SCROLL = {
  CATEGORY_SCROLL_TOP: "new-categories-scroll-",
  FAQ_SCROLL_TOP: "new-faq-scroll-",
  PRODUCT_SCROLL_TOP: "new-product-scroll-",
  CAMPAIGN_SCROLL_TOP: "new-campaign-scroll-",
  BRANCH_SCROLL_TOP: "new-branch-scroll-",
  STORE_MEMBER_SCROLL_TOP: "new-store-scroll-",
  ORDER_SCROLL_TOP: "new-order-scroll-",
  REPLY_SHORTCUT_TOP: "new-reply-shortcut-",
  CAMPAIGN_PROMOTION_TOP: "new-campaign-promotion-",
};
