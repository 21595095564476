import styled from "styled-components";
import { VARIANTS } from "constants/Typography";
import { EXPAND_CELL_WIDTH } from "../config";

const CHECKBOX_COLUMN_WIDTH = 46;

type TableWrapperPropsType = {
  isBlurContent?: boolean;
};

export const TableWrapper = styled.div<TableWrapperPropsType>`
  flex: 1;
  width: 100%;
  min-height: 450px;
  position: relative;
  overflow: auto;
  overflow: ${({ isBlurContent }) => (isBlurContent ? "hidden" : "auto")};
  -ms-overflow-style: none;
  line-height: 1rem;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    display: initial;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(83, 83, 83, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .table {
    font-size: ${VARIANTS.body4.size};

    input[type="checkbox"] {
      width: 24px;
      height: 24px;
    }

    .header {
      .th {
        padding: 16px 10px;
        border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
        background-color: white;
        color: ${({ theme }) => theme.COLORS.DarkLight};
        font-weight: 600;
        border-top: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
        word-break: break-word;

        svg {
          font-size: 20px;
        }

        :first-child {
          min-width: ${EXPAND_CELL_WIDTH}px !important;
          border-left: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
          position: sticky;
          left: 0;
          z-index: 3;
          box-shadow: 2px 0px 3px ${({ theme }) => theme.COLORS.TableShadow};
          flex: ${CHECKBOX_COLUMN_WIDTH} 0 0 !important;
        }

        :last-child {
          border-right: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
        }
      }
    }

    .td {
      padding: 10px;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      background-color: white;
      color: ${({ theme }) => theme.COLORS.DarkGray};
      word-break: break-word;

      :first-child {
        min-width: ${EXPAND_CELL_WIDTH}px !important;
        position: sticky;
        left: 0;
        z-index: 3;
        box-shadow: 2px 0px 3px ${({ theme }) => theme.COLORS.TableShadow};
        border-left: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
        flex: ${CHECKBOX_COLUMN_WIDTH} 0 0 !important;
      }

      :last-child {
        border-right: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    &.sticky {
      .header {
        position: sticky;
        z-index: 2;
        top: 0;
        left: 0;
        box-shadow: 0px 1px 3px 0px ${({ theme }) => theme.COLORS.LightBlue};
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px ${({ theme }) => theme.COLORS.LightBlue};
      }
    }
  }
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 30%;
  right: 50%;
  z-index: 10;
`;
