import React, { FC } from "react";
import { Props as SelectPropsType, OptionType, MenuProps } from "react-select";
import makeAnimated from "react-select/animated";
import getStyle from "components/TagInputFilter/CustomerSelector/getStyle";
import { getIsDuplicatedWithOptions } from "components/TagInputFilter/helpers";
import { CustomStyleSelectType } from "types/ReactSelect";
import { validateSpecialCharacter, validateSpecialCharacterForEmail } from "utils/common";
import { StyledMenu, StyledReactSelectCreatable } from "./styled";
import ArrowDownIcon from "../ArrowDownIcon";

type CreateAbleSelectorPropsType = SelectPropsType & CustomStyleSelectType;

const Menu: FC<MenuProps<OptionType>> = ({ children, ...props }) => {
  return <StyledMenu {...props}>{children}</StyledMenu>;
};

const animatedComponents = makeAnimated();

const DropdownIndicator = () => <ArrowDownIcon />;

const ClearIndicator = () => <div />;

const CreateAbleSelector: FC<CreateAbleSelectorPropsType> = (props) => {
  const { isDisabled, noOutline, isEmailAllowed, ...rest } = props;
  const style = getStyle(isDisabled, noOutline);

  const component = noOutline ? { DropdownIndicator, ClearIndicator, Menu } : animatedComponents;

  const handleValidNewOption = (inputValue: string, _selectValue: OptionType[], selectOptions: OptionType[]) => {
    const hasSpecialCharacter = isEmailAllowed
      ? validateSpecialCharacterForEmail(inputValue)
      : validateSpecialCharacter(inputValue);
    const isDuplicatedWithOptions = getIsDuplicatedWithOptions(selectOptions, inputValue);

    return !hasSpecialCharacter && Boolean(inputValue.length) && !isDuplicatedWithOptions;
  };

  return (
    <StyledReactSelectCreatable
      {...rest}
      styles={style}
      components={component}
      isValidNewOption={handleValidNewOption}
    />
  );
};

export default CreateAbleSelector;
