/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

import Switch from "components/Switch";
import TextField from "components/TextField";
import Typography from "components/Typography";

import { E_WALLET_IMAGE } from "constants/Image";

import { DeeplePayPaymentChannelDetail } from "types/DeeplePay";
import { Device } from "types/Device";

import useDevice from "utils/hooks/useDevice";
import useIsDesktop from "utils/hooks/useIsDesktop";

import { FeeBox, Item, Option } from "../styled";

export interface TrueMoneyWalletProps {
  trueMoneyWalletData: any;
  onChange: (isActive: boolean, mimimum: number) => void;
}

const TrueMoneyWallet: FC<TrueMoneyWalletProps> = ({ trueMoneyWalletData, onChange }) => {
  // Hooks
  const device = useDevice();
  const isDesktop = useIsDesktop();
  const { t } = useTranslation();

  // Form hooks
  const [channels] = useState<DeeplePayPaymentChannelDetail[]>(trueMoneyWalletData?.channels || []);
  const [isActive, setIsActive] = useState<boolean>(trueMoneyWalletData?.isActive || false);
  const [isDisableTextField, setIsDisableTextField] = useState<boolean>(
    !trueMoneyWalletData?.isSelectable || !trueMoneyWalletData?.isActive || false,
  );
  const [isDisableSwitch] = useState<boolean>(!trueMoneyWalletData?.isSelectable || false);
  const [minimum, setMinimum] = useState<number>(trueMoneyWalletData?.minimum || 0);

  // Define variables
  const isMobile = Device.MOBILE === device;

  // Event handlers
  const handleChangeSwitch = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsActive(checked);
    setIsDisableTextField(!checked);
    onChange(checked, minimum);
  };

  const handleChangeTextField = (event: ChangeEvent<HTMLInputElement>) => {
    const minimumValue = parseInt(event.target.value, 10);

    setMinimum(minimumValue);
    onChange(isActive, minimumValue);
  };

  // Renderer
  if (!trueMoneyWalletData) {
    return null;
  }

  return (
    <Item container alignItems="center" className={isDesktop ? "px-4 py-3" : "mobile px-4 py-3"}>
      <Grid item xs={12} className="pb-3 mb-1">
        <Typography variant="title3">{t("deeplePay.paymentChannel.eWallet.label")}</Typography>
      </Grid>

      <Grid container item xs={12} justify="flex-start" alignContent="center" alignItems="center" className="pb-3 mb-1">
        <Typography variant="body3" className={isMobile ? "mb-3" : "mr-1"}>
          {t("deeplePay.paymentChannel.eWallet.minimum")}
        </Typography>
        <TextField
          type="formatNumber"
          variant="outlined"
          value={minimum}
          placeholder="฿ 0"
          disabled={isDisableTextField}
          onChange={handleChangeTextField}
        />
        <Typography variant="body3" className="ml-1">
          {t("deeplePay.paymentChannel.minimum.unit")}
        </Typography>
      </Grid>

      <Option container item xs={12} alignItems="center" className="first last p-4">
        <Grid item xs={isDesktop ? 1 : 12}>
          <Switch checked={isActive} disabled={isDisableSwitch} onChange={handleChangeSwitch} />
        </Grid>
        <Grid item className="mr-3" justify="flex-start">
          <img src={E_WALLET_IMAGE.TRUE_MONEY_WALLET} alt="true-money-wallet" />
        </Grid>
        <Grid item xs={isDesktop ? 6 : 9} className={isDesktop ? "" : "mt-3"}>
          <Typography variant="title8">{t(`deeplePay.paymentChannel.trueMoneyWallet.title`)}</Typography>
          <Typography variant="body4">{t(`deeplePay.paymentChannel.trueMoneyWallet.description`)}</Typography>
          {!isDesktop && (
            <Typography variant="body3">
              {channels[0]?.fee?.rate &&
                t("deeplePay.paymentChannel.fee.percent.label", {
                  value: channels[0]?.fee?.rate,
                })}
            </Typography>
          )}
        </Grid>
        {isDesktop && (
          <FeeBox item xs={4} justify="flex-end">
            <Typography variant="body3">
              {channels[0]?.fee?.rate &&
                t("deeplePay.paymentChannel.fee.percent.label", {
                  value: channels[0]?.fee?.rate,
                })}
            </Typography>
          </FeeBox>
        )}
      </Option>
    </Item>
  );
};

export default TrueMoneyWallet;
