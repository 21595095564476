import * as React from "react";

const IcEnglishChat = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <g>
      <path
        d="M3.00173 13.85C3.00173 6.75625 8.97048 1 16.333 1C23.6955 1 29.6642 6.75625 29.6642 13.85C29.6405 16.387 28.8655 18.86 27.4372 20.9568C26.0089 23.0537 23.9913 24.6803 21.6392 25.6313C20.0142 26.3188 15.7205 28.3937 9.84548 31C9.68298 30.7 11.6705 25.8188 11.1642 25.6313C8.74209 24.7684 6.65165 23.1675 5.18732 21.054C3.723 18.9405 2.95858 16.4209 3.00173 13.85Z"
        fill="#2F4069"
      />
      <path
        d="M11.82 17H13.15V13.96H16.36V17H17.7V9.85H16.36V12.89H13.15V9.85H11.82V17ZM19.2598 17H20.4698V11.77H19.2598V17ZM19.8498 10.91C20.2498 10.91 20.5698 10.61 20.5698 10.22C20.5698 9.82 20.2498 9.52 19.8498 9.52C19.4498 9.52 19.1298 9.82 19.1298 10.22C19.1298 10.61 19.4498 10.91 19.8498 10.91Z"
        fill="white"
      />
    </g>
  </svg>
);

export default IcEnglishChat;
