import { useState, useCallback } from "react";
import debounce from "lodash/debounce";
import { DEBOUNCE_DELAY } from "constants/Chat";

const useSearchText = (defaultSearchText?: string, onChange?: () => void) => {
  const [searchText, setSearchText] = useState(defaultSearchText || "");

  const handleChangeSearch = useCallback(
    (newSearchText: string) => {
      setSearchText(newSearchText);
      onChange?.();
    },
    [onChange],
  );

  const debounceHandleChangeSearch = useCallback(debounce(handleChangeSearch, DEBOUNCE_DELAY), [handleChangeSearch]);

  return {
    searchText,
    debounceHandleChangeSearch,
    handleChangeSearch,
  };
};

export default useSearchText;
