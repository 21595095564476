import React, { memo, FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Typography from "components/Typography";
import Search from "components/Search";
import { onSubmitProductSelector } from "domain/Product/ProductListWithSearch/ProductSelectorContainer";
import Loading from "domain/Faq/FaqThEn/FaqContainer/Loading";
import getValuesFromOptions from "domain/Product/ProductListWithSearch/ProductSelectorContainer/getValuesFromOptions";
import { Wrapper, TagsWrapper, OptionsWrapper, ClearAllButton } from "./styled";
import Option from "../Option";
import { OptionType } from "../Option/type";
import Tag from "../Tag";

type PopupProps<T, F = T> = {
  placeholder: string;
  options: OptionType<T, F>[];
  selectedOptions?: OptionType<T, F>[];
  onChangeSearch?: (searchText: string) => void;
  onSelect?: (option: OptionType<T, F>) => void;
  onDeleteTag?: (option: OptionType<T, F>) => void;
  onClearAllTag?: () => void;
  onSubmit: onSubmitProductSelector;
  isDisabledClearAll?: boolean;
  isLoading?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Popup: FC<PopupProps<any>> = ({
  placeholder,
  options,
  selectedOptions = [],
  onSelect,
  onChangeSearch = () => {},
  onDeleteTag,
  onClearAllTag = () => {},
  isDisabledClearAll = false,
  isLoading = false,
  onSubmit,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    return () => {
      onChangeSearch("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex flex-column flex-1 overflow-auto">
      <Search onChange={onChangeSearch} className="p-4" defaultValue="" placeholder={placeholder} />
      <Wrapper>
        <Typography className="mb-2" variant="title8" color="darkLight">
          {t("SELECTED_PRODUCTS")}
        </Typography>
        <div className="d-flex">
          <div className="flex-1 mr-3 position-relative">
            <TagsWrapper>
              {selectedOptions
                .filter((selectedOption) => selectedOption?.isChecked)
                .map((selectedOption) => {
                  return <Tag key={selectedOption.label} option={selectedOption} onDelete={onDeleteTag} />;
                })}
            </TagsWrapper>
            <ClearAllButton onClick={onClearAllTag} disabled={isDisabledClearAll}>
              {t("CLEAR_ALL")}
            </ClearAllButton>
          </div>
          <div>
            <Button
              color="primary"
              type="button"
              onClick={() => {
                const values = getValuesFromOptions(selectedOptions);
                onSubmit(values);
              }}
            >
              {t("Confirm")}
            </Button>
          </div>
        </div>
      </Wrapper>
      <OptionsWrapper>
        {isLoading && <Loading loadingLabel={t("LOADING")} />}
        {!isLoading && !options?.length && (
          <Typography variant="title2" color="darkLight" className="text-center mt-3">
            {t("NO_FOUND_PRODUCTS")}
          </Typography>
        )}
        {!isLoading &&
          options.map((option) => {
            return <Option key={option.label} data={option} onSelect={onSelect} />;
          })}
      </OptionsWrapper>
    </div>
  );
};

export default memo(Popup);
