const newResetPassword = {
  en: {
    BACK_TO_LOGIN: "Back to login",
    NEW_PASSWORD: "New password",
    PLEASE_ENTER_NEW_PASSWORD: "Please enter a new password",
    ENTER_NEW_PASSWORD: "Enter new password",
    CONFIRM_NEW_PASSWORD: "Confirm new password",
    CHANGE_YOUR_PASSWORD: "Change your password",
    PLEASE_CREATE_NEW_PASSWORD: "Please create a new password",
  },
  th: {
    BACK_TO_LOGIN: "ไปหน้าเข้าสู่ระบบ",
    NEW_PASSWORD: "รหัสผ่านใหม่",
    PLEASE_ENTER_NEW_PASSWORD: "กรุณาใส่รหัสผ่านใหม่",
    ENTER_NEW_PASSWORD: "ใส่รหัสผ่านใหม่",
    CONFIRM_NEW_PASSWORD: "ยืนยันรหัสผ่านใหม่",
    CHANGE_YOUR_PASSWORD: "เปลี่ยนรหัสผ่าน",
    PLEASE_CREATE_NEW_PASSWORD: "โปรดตั้งรหัสผ่านใหม่",
  },
};

export default newResetPassword;
