import styled from "styled-components";
import { SIDE_NAV_WIDTH_DESKTOP } from "constants/Layout";

export const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-top: 0;

    &.isShowSideNav {
      width: calc(100% - ${SIDE_NAV_WIDTH_DESKTOP}px);
    }
  }
`;

export const Wrapper = styled.div`
  flex: 1;
  overflow: auto;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
`;
