import * as React from "react";

const IcGoogleBorderLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
    <g clipPath="url(#clip0_245_17428)">
      <path
        d="M0.0273438 16.1C0.0273438 10.4051 0.0273438 7.55764 1.15321 5.39026C2.10196 3.56382 3.59117 2.07462 5.4176 1.12587C7.58498 0 10.4324 0 16.1273 0H31.9273C37.6222 0 40.4697 0 42.6371 1.12587C44.4635 2.07462 45.9527 3.56382 46.9015 5.39026C48.0273 7.55764 48.0273 10.4051 48.0273 16.1V31.9C48.0273 37.5949 48.0273 40.4424 46.9015 42.6097C45.9527 44.4362 44.4635 45.9254 42.6371 46.8741C40.4697 48 37.6222 48 31.9273 48H16.1273C10.4324 48 7.58498 48 5.4176 46.8741C3.59117 45.9254 2.10196 44.4362 1.15321 42.6097C0.0273438 40.4424 0.0273438 37.5949 0.0273438 31.9V16.1Z"
        fill="#F5F7FB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.824 24.3268C37.824 23.3057 37.7324 22.3239 37.5622 21.3813H24V26.9515H31.7498C31.416 28.7515 30.4015 30.2766 28.8764 31.2977V34.9108H33.5302C36.2531 32.4039 37.824 28.7123 37.824 24.3268Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9992 38.3994C27.8872 38.3994 31.1469 37.1099 33.5294 34.9106L28.8756 31.2975C27.5861 32.1615 25.9367 32.6721 23.9992 32.6721C20.2487 32.6721 17.0741 30.139 15.9418 26.7354H11.1309V30.4663C13.5003 35.1724 18.3701 38.3994 23.9992 38.3994Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9422 26.7361C15.6542 25.8721 15.4905 24.9492 15.4905 24.0001C15.4905 23.051 15.6542 22.1281 15.9422 21.2641V17.5332H11.1312C10.156 19.4772 9.59961 21.6765 9.59961 24.0001C9.59961 26.3237 10.156 28.523 11.1312 30.467L15.9422 26.7361Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9992 15.3269C26.1134 15.3269 28.0116 16.0534 29.504 17.4803L33.6341 13.3502C31.1403 11.0265 27.8807 9.59961 23.9992 9.59961C18.3701 9.59961 13.5003 12.8265 11.1309 17.5327L15.9418 21.2636C17.0741 17.86 20.2487 15.3269 23.9992 15.3269Z"
        fill="#EA4335"
      />
    </g>
    <defs>
      <clipPath id="clip0_245_17428">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IcGoogleBorderLogo;
