import * as React from "react";

const SvgIcBubble = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 12 13" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.627 11.625c-.057-.112.67-1.942.484-2.011-1.824-.697-2.986-2.373-2.986-4.421C1.125 2.532 3.308.375 6 .375c2.693 0 4.875 2.157 4.875 4.818 0 1.979-1.207 3.68-2.935 4.42-.594.256-2.162 1.033-4.313 2.012"
    />
  </svg>
);

export default SvgIcBubble;
