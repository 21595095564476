const mapErrorMessage = (errMessage: string) => {
  switch (errMessage) {
    case "ZORT_PRODUCT_HAS_DUPLICATED_PRODUCT_NAME":
      return "inventoryIntegration.error.duplicated.product.name";
    case "ZORT_VARIANT_DOES_NOT_HAVE_SAME_CATEGORY":
      return "inventoryIntegration.error.incorrect.variant";
    case "ZORT_PRODUCT_HAS_MORE_THAN_SUPPORTED_VARIANT_LIMIT":
      return "inventoryIntegration.error.exceeded.variant";
    case "BASIC_PACK_CANNOT_EXCEED_PRODUCT_QUOTA":
      return "product.default.error.overQuota";
    case "EXCEED_PRODUCT_QUOTA":
      return "inventoryIntegration.error.exceed.product";
    case "INTEGRATION_DOES_NOT_EXISTS":
      return "inventoryIntegration.error.not.exist";
    default:
      return "Please try again later";
  }
};

export default mapErrorMessage;
