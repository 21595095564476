import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Card from "components/Card";
import Grid from "components/Grid";
import Typography from "components/Typography";
import Modal from "components/Modal";

import { SvgIcon } from "components/Icon";
import { IcDigitalProduct, IcProduct, IcFood } from "components/SvgIcons";
import { IS_ADD_FOOD_PRODUCT_ENABLED } from "config";
import { COLORS } from "constants/Colors";

export const CardWrapper = styled.div`
  color: ${({ theme }) => theme.COLORS.DarkMed};
  &:hover {
    color: ${({ theme }) => theme.COLORS.Primary};
  }
`;

type ProductTypeSelectorModalPropsType = {
  isDigitalContentEnabled: boolean;
  isOpen?: boolean;
  onClickDigitalContentProduct?: () => void;
  onClickFood?: () => void;
  onClickProduct?: () => void;
  onClose?: () => void;
};

const ProductTypeSelectorModal: FC<ProductTypeSelectorModalPropsType> = ({
  isDigitalContentEnabled = false,
  isOpen = false,
  onClickDigitalContentProduct = () => {},
  onClickFood = () => {},
  onClickProduct = () => {},
  onClose = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeIconColor={COLORS.DarkGray}>
      <Grid container className="p-3">
        <Grid item container justify="center" className="m-4">
          <Grid item>
            <Typography variant="title7" className="text-center">
              {t("product.productTypeSelectorModal.title")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={isDigitalContentEnabled ? 6 : 12} className="px-1">
            <Card onClick={onClickProduct} noShadow height={60}>
              <CardWrapper>
                <div className="text-center">
                  <SvgIcon className="mb-1" htmlColor="inherit" component={IcProduct} fontSize="default" />
                </div>
                <Typography variant="title8" color="inherit" className="text-center">
                  {t("Product")}
                </Typography>
              </CardWrapper>
            </Card>
          </Grid>

          {isDigitalContentEnabled && (
            <Grid item xs={6} className="px-1">
              <Card onClick={onClickDigitalContentProduct} noShadow height={60}>
                <CardWrapper>
                  <div className="text-center">
                    <SvgIcon className="mb-1" htmlColor="inherit" component={IcDigitalProduct} fontSize="default" />
                  </div>
                  <Typography variant="title8" color="inherit" className="text-center">
                    {t("Digital content")}
                  </Typography>
                </CardWrapper>
              </Card>
            </Grid>
          )}

          {IS_ADD_FOOD_PRODUCT_ENABLED && (
            <Grid item xs={12} className="px-1 mt-2">
              <Card onClick={onClickFood} noShadow height={60}>
                <CardWrapper>
                  <div className="text-center">
                    <SvgIcon className="mb-1" htmlColor="inherit" component={IcFood} fontSize="default" />
                  </div>
                  <Typography variant="title8" color="inherit" className="text-center">
                    {t("Food")}
                  </Typography>
                </CardWrapper>
              </Card>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ProductTypeSelectorModal;
