import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { ValueType, OptionType, OptionsType } from "react-select";
import get from "lodash/get";
import last from "lodash/last";
import differenceBy from "lodash/differenceBy";

import Grid from "components/Grid";
import Typography from "components/Typography";
import Card from "components/Card";
import CircularProgress from "components/CircularProgress";
import TagInputWithLabel from "components/TagInputWithAutoSuggest";

import { CUSTOMER_TAG_MAX_PAGE_SIZE, CUSTOMER_TAG_MAX_CHARACTER_LENGTH } from "config/customer";
import { CUSTOMER_TAG } from "graphql/customer/query";
import { SUGGEST_TAGS } from "graphql/suggestTags/query";
import { CustomerType, CustomerTagVariablesType, SuggestTagVariablesType, SuggestTagsType } from "types/Customer";
import { SystemTagType } from "types/Tag";
import { mapPotentialCustomerTag } from "utils/tag";

import useAddCustomerTag from "./hooks/useAddCustomerTag";
import useDeleteCustomerTag from "./hooks/useDeleteCustomerTag";

export type TagOptionType = {
  id: string;
  tag: string;
};

export type OptionWithTagType = {
  id: string;
  label: string;
  value: string;
};

type CustomerTagPropsType = {
  projectId: string;
  customerId: string;
};

export const CustomerTag: FC<CustomerTagPropsType> = ({ projectId, customerId }) => {
  const { t } = useTranslation();
  const variables = {
    projectId,
    customerId,
    pageSize: CUSTOMER_TAG_MAX_PAGE_SIZE,
  };

  const { data: suggestTagsData } = useQuery<SuggestTagsType, SuggestTagVariablesType>(SUGGEST_TAGS, {
    variables: {
      projectId,
      type: "CUSTOMER",
    },
    fetchPolicy: "network-only",
  });

  const { data: customerTagsData, loading } = useQuery<CustomerType, CustomerTagVariablesType>(CUSTOMER_TAG, {
    variables,
    fetchPolicy: "network-only",
  });

  const tagsData = get(customerTagsData, "customer.tags.results") || [];

  const { addTag } = useAddCustomerTag({
    variables,
    onComplete: () => {},
  });

  const { handleDeleteTag } = useDeleteCustomerTag({
    variables,
    projectId,
    onComplete: () => {},
  });

  const convertDataToOptionFormat = (originalData: string[]) => {
    if (originalData && originalData.length) {
      return originalData.map((data: string) => ({
        label: data,
        value: data,
      }));
    }
    return [];
  };

  const convertDataToSelectOptionFormat = <T extends TagOptionType>(originalData: T[]) =>
    originalData.map((data: T) => ({
      id: data.id,
      label: mapPotentialCustomerTag(data.tag),
      value: data.tag,
    }));

  const handleChangeTagInput = (tagInputValues: ValueType<OptionType>) => {
    const newTagsArray = tagInputValues
      ? (tagInputValues as OptionWithTagType[]).map((tagInputValue) => {
          return { id: tagInputValue.id, tag: tagInputValue.value };
        })
      : [];

    const currentTags = tagsData.map((tag: SystemTagType) => {
      return { id: tag.id, tag: tag.tag };
    });

    const isDeletion = newTagsArray && newTagsArray.length < currentTags.length;
    const [deletedItem] = (isDeletion && differenceBy(currentTags, newTagsArray, "id")) || [];
    const newItem = last(tagInputValues as Array<OptionType>);

    if (isDeletion) {
      if (get(deletedItem, "id")) {
        handleDeleteTag(get(deletedItem, "id"));
      }
    } else if (
      !isDeletion &&
      newItem &&
      newItem.value &&
      newTagsArray &&
      newTagsArray.length <= CUSTOMER_TAG_MAX_PAGE_SIZE
    ) {
      addTag({
        variables: {
          projectId,
          customerId,
          tag: newItem.value,
        },
      });
    }
  };
  const optionsValue: OptionsType<OptionType> =
    convertDataToOptionFormat((suggestTagsData && suggestTagsData.suggestTags) || []) || [];

  return loading ? (
    <CircularProgress />
  ) : (
    <TagInputWithLabel
      options={optionsValue}
      limit={CUSTOMER_TAG_MAX_PAGE_SIZE}
      characterLimit={CUSTOMER_TAG_MAX_CHARACTER_LENGTH}
      value={convertDataToSelectOptionFormat(tagsData)}
      onChange={(event: ValueType<OptionType>) => {
        handleChangeTagInput(event || []);
      }}
      dataCy="tags"
      placeholder={t("chat.customerTag.placeholder")}
    />
  );
};

const CustomerTagCard: FC<CustomerTagPropsType> = ({ projectId, customerId }) => {
  const { t } = useTranslation();

  return (
    <Card noShadow padding="0px">
      <Grid container className="px-3 py-2" direction="column" alignItems="flex-start">
        <Grid item xs={12} className="w-100">
          <Typography variant="body2" color="dark" className="pt-3">
            {t("Tags")}
          </Typography>
          <CustomerTag projectId={projectId} customerId={customerId} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default CustomerTagCard;
