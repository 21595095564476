import styled from "styled-components";
import ReactSelectCreatable from "react-select/creatable";
import { components } from "react-select";

export const StyledReactSelectCreatable = styled(ReactSelectCreatable)`
  display: flex;
  max-width: 60vw;
  min-width: 100px;
`;

export const StyledMenu = styled(components.Menu)`
  z-index: 10 !important;
`;
