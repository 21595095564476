import { IcUserSetting, IcNotificationSetting, IcOpenNotification } from "components/SvgIcons";

import { ACCOUNT_SETTINGS, NOTIFICATION_SETTING, NOTIFICATIONS } from "constants/Router";

export const TAB_CONFIGS = [
  {
    id: ACCOUNT_SETTINGS,
    title: "Account settings",
    svgIcon: IcUserSetting,
  },
  {
    id: NOTIFICATIONS,
    title: "Notifications",
    svgIcon: IcOpenNotification,
  },
  {
    id: NOTIFICATION_SETTING,
    title: "NotificationSetting.header",
    svgIcon: IcNotificationSetting,
  },
];
