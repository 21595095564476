import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Grid from "components/Grid";
import PageTitle from "components/PageTitle";
import { DASHBOARD, SALES_REPORT, ORDER_REPORT, PRODUCT_REPORT } from "constants/Router";
import { DisableFeature } from "pages/DisableFeature";
import { ProjectIdType } from "types/Project";
import useTabWithLink from "utils/hooks/useTabWithLink";
import useGetProject from "utils/hooks/useGetProject";

import { ThemeContext } from "utils/context/ThemeContext";
import { TabMenu, TabHeader } from "./styled";

const SalesReport: FC<{ activeIndex: number }> = ({ children, activeIndex }) => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const { isSalesReportEnabled } = useGetProject(projectId);

  const tabConfig = {
    header: [
      {
        id: ORDER_REPORT,
        title: "ORDER CENTRIC",
      },
      {
        id: PRODUCT_REPORT,
        title: "PRODUCT CENTRIC",
      },
    ],
  };

  const { handleTabChange } = useTabWithLink(tabConfig.header, `${DASHBOARD}/${SALES_REPORT}`, projectId, activeIndex);

  return (
    <Grid container alignItems="flex-start" alignContent="flex-start" justify="flex-start" className="px-3">
      <PageTitle title={t("Sales report")} className="pt-4" />
      <Grid container direction="column" alignItems="flex-start" alignContent="flex-start">
        <Grid container>
          <div className="py-3">
            <TabHeader
              value={activeIndex}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: theme.Primary,
                  display: "none",
                },
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              {tabConfig.header.map((element: any, idx: number) => {
                return (
                  <TabMenu
                    key={element.id}
                    isActive={activeIndex === idx}
                    disabled={element.disabled}
                    label={<span>{t(element.title)}</span>}
                  />
                );
              })}
            </TabHeader>
          </div>
        </Grid>
      </Grid>
      {isSalesReportEnabled ? children : <DisableFeature>{children}</DisableFeature>}
    </Grid>
  );
};

export default SalesReport;
