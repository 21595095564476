import { gql } from "@apollo/client";

export const ADD_CUSTOMER_NOTE = gql`
  mutation addCustomerNote($projectId: ID!, $customerId: ID!, $note: String!) {
    addCustomerNote(projectId: $projectId, customerId: $customerId, note: $note) {
      createdAt
      id
      note
      agent {
        avatar
        email
        displayName
        isDeactivated
      }
    }
  }
`;

export const DELETE_CUSTOMER_NOTE = gql`
  mutation deleteCustomerNote($projectId: ID!, $customerNoteId: ID!) {
    deleteCustomerNote(projectId: $projectId, customerNoteId: $customerNoteId) {
      createdAt
      id
      note
      agent {
        avatar
        email
        displayName
        isDeactivated
      }
    }
  }
`;

export const ADD_CUSTOMER_TAG = gql`
  mutation addCustomerTag($projectId: ID!, $customerId: ID!, $tag: String!) {
    addCustomerTag(projectId: $projectId, customerId: $customerId, tag: $tag) {
      id
      projectId
      customerId
      tag
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_CUSTOMER_TAG = gql`
  mutation deleteCustomerTag($projectId: ID!, $customerTagId: ID!) {
    deleteCustomerTag(projectId: $projectId, customerTagId: $customerTagId) {
      id
      projectId
      customerId
      tag
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_CUSTOMER_DISPLAY_NAME = gql`
  mutation updateCustomerDisplayName($projectId: ID!, $customerId: ID!, $displayName: String!) {
    updateCustomerDisplayName(projectId: $projectId, customerId: $customerId, displayName: $displayName) {
      displayName
    }
  }
`;
