import React, { FC } from "react";
import { Link } from "react-router-dom";
import { PROJECT, PLAN, PLAN_UPGRADE } from "constants/Router";

import Button from "components/Button";

type ButtonToPlanUpgradePropsType = {
  projectId: string;
  label: string;
  fullWidth?: boolean;
};

const ButtonToPlanUpgrade: FC<ButtonToPlanUpgradePropsType> = ({ projectId, label, fullWidth }) => (
  <Link to={`/${PROJECT}/${projectId}/${PLAN}/${PLAN_UPGRADE}`} className={fullWidth ? "w-100" : ""}>
    <Button fullWidth={fullWidth} className="px-3 py-2">
      {label}
    </Button>
  </Link>
);

export default ButtonToPlanUpgrade;
