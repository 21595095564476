import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import get from "lodash/get";
import { downloadFile } from "utils/common";
import { EXPORT_PRODUCT_REPORTS } from "graphql/salesReport/query";
import { ExportFileType } from "types/SalesReport";

type UseExportReportsDataType = {
  data: {
    exportOrderProductReports: { url: string };
  };
};

const useExportReports = () => {
  const [exportOrderProductReports, { data, loading }] = useLazyQuery<UseExportReportsDataType>(EXPORT_PRODUCT_REPORTS);

  useEffect(() => {
    const url: string = get(data, "exportOrderProductReports.url") || "";

    if (url) {
      const fileName = url.split("?")[0].split("/").pop();

      if (fileName) {
        downloadFile(url, fileName);
      }
    }
  }, [data]);

  const exportReports = async (projectId: string, filter: object, exportFileType: ExportFileType) => {
    exportOrderProductReports({
      variables: {
        projectId,
        isSelectAll: true,
        filter,
        exportFileType: exportFileType || ExportFileType.XLSX,
      },
    });
  };

  return { exportReports, isLoadingExportReports: loading };
};

export default useExportReports;
