import { CONNECT, PRODUCTS, PRODUCTS_INVENTORY, PROJECT, STORE_SETTINGS } from "constants/Router";
import type { TFunction } from "i18next";

export const getProgressBarProps = (
  pathName: string,
  projectId: string,
  hasProducts: boolean,
  hasConnect: boolean,
  t: TFunction,
) => {
  const title = getProgressBarTitle(pathName, projectId, hasProducts, hasConnect, t);
  const subtitle = getProgressBarSubtitle(pathName, projectId, hasProducts, hasConnect, t);
  const isShowSubtitleProgress = getProgressBarIsShowSubtitleProgress(pathName, projectId, hasProducts, hasConnect, t);
  return {
    title,
    subtitle,
    isShowSubtitleProgress,
  };
};

const getProgressBarTitle = (
  pathName: string,
  projectId: string,
  hasProducts: boolean,
  hasConnect: boolean,
  t: TFunction,
) => {
  if (pathName === `/${PROJECT}/${projectId}/${STORE_SETTINGS}/${CONNECT}`) {
    if (hasProducts && hasConnect) {
      return t("Guideline.pageConnect.title.completedTwoSteps");
    }

    return t("Guideline.pageConnect.title.incompletedTwoSteps");
  }

  if (
    pathName === `/${PROJECT}/${projectId}/${PRODUCTS_INVENTORY}/${PRODUCTS}` ||
    pathName === `/${PROJECT}/${projectId}/${PRODUCTS_INVENTORY}/${PRODUCTS}/new`
  ) {
    if (hasProducts && hasConnect) {
      return t("Guideline.pageAddProduct.title.completedTwoSteps");
    }

    return t("Guideline.pageAddProduct.title.incompletedTwoSteps");
  }

  return t("Guideline.title");
};

const getProgressBarSubtitle = (
  pathName: string,
  projectId: string,
  hasProducts: boolean,
  hasConnect: boolean,
  t: TFunction,
) => {
  const isInProductPages =
    pathName === `/${PROJECT}/${projectId}/${PRODUCTS_INVENTORY}/${PRODUCTS}` ||
    pathName === `/${PROJECT}/${projectId}/${PRODUCTS_INVENTORY}/${PRODUCTS}/new`;

  const isInConnectPage = pathName === `/${PROJECT}/${projectId}/${STORE_SETTINGS}/${CONNECT}`;

  if (isInConnectPage || isInProductPages) {
    return t("Guideline.pageConnect.subtitle.remainingSteps");
  }

  return !hasProducts && !hasConnect
    ? t("Guideline.pageConnect.subtitle.requiredTwoSteps")
    : t("Guideline.pageConnect.subtitle.remainingSteps");
};

const getProgressBarIsShowSubtitleProgress = (
  pathName: string,
  projectId: string,
  hasProducts: boolean,
  hasConnect: boolean,
  _t: TFunction,
) => {
  const isInProductPages =
    pathName === `/${PROJECT}/${projectId}/${PRODUCTS_INVENTORY}/${PRODUCTS}` ||
    pathName === `/${PROJECT}/${projectId}/${PRODUCTS_INVENTORY}/${PRODUCTS}/new`;

  const isInConnectPage = pathName === `/${PROJECT}/${projectId}/${STORE_SETTINGS}/${CONNECT}`;

  if (!isInProductPages && !isInConnectPage && !hasProducts && !hasConnect) {
    return false;
  }

  return true;
};
