import { useState, useCallback } from "react";

const useToggle = (defaultValue?: boolean) => {
  const [isOpen, setIsOpen] = useState(Boolean(defaultValue));

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return { isOpen, setIsOpen, handleToggle, handleClose };
};

export default useToggle;
