import React, { FC, ChangeEvent, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { useForm, FormProvider } from "react-hook-form";

import Button from "components/Button";
import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import PageTitle from "components/PageTitle";
import StickyPanel from "components/StickyPanel";
import { PROJECT_SETTING } from "graphql/setting/query";
import { ProjectIdType } from "types/Project";
import {
  OrderSettingType,
  OrderSettingFormType,
  ProjectSettingQueryType,
  ProjectConfigurationOrderTimerType,
} from "types/Setting";

import OrderSettingForm from "./OrderSettingForm";
import useUpdateSetting from "./hooks/useUpdateSetting";
import validationSchema from "./validateSchema";

const DefaultSetting: FC = () => {
  const { t } = useTranslation();

  const { projectId } = useParams<ProjectIdType>();
  const methods = useForm<OrderSettingFormType>({
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, setError, setValue, formState, reset, getValues } = methods;

  const { loading, data } = useQuery<ProjectSettingQueryType>(PROJECT_SETTING, {
    variables: {
      projectId,
    },
  });

  const configurationData = get(data, "getProject.configuration");
  const timerOrderConfig = get(configurationData, "order.timer");
  const prefixOrderConfig = get(configurationData, "order.prefix");
  const isMultiCODOrderWithOutDepositEnabled =
    data?.getProject?.configuration?.order?.isMultiCODOrderWithOutDepositEnabled || false;
  const isEmailRequired = Boolean(get(configurationData, "order.isEmailRequired") || false);
  const remindTimerOrderConfig =
    timerOrderConfig &&
    timerOrderConfig.find(({ type }: ProjectConfigurationOrderTimerType) => type === OrderSettingType.REMIND);

  const { handleSubmitForm, isPaymentNotificationActive } = useUpdateSetting({
    projectId,
    remindTimerOrderConfig,
    configurationData,
    onError: () =>
      setError("reserveExpMin", {
        type: "invalid",
        message: "Reserved product expiration time must be after Payment notification time",
      }),

    onComplete: () => {
      reset(getValues(), { isDirty: false });
    },
  });

  const handlePrefixChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: keyof OrderSettingFormType,
  ) => {
    const inputText = event.target.value;
    if (inputText) {
      setValue(fieldName, inputText.toUpperCase());
    }
  };

  useEffect(() => {
    reset({ isPaymentNotificationActive });
  }, [isPaymentNotificationActive, reset]);

  return loading ? (
    <Grid container item justify="center">
      <CircularProgress />
    </Grid>
  ) : (
    <Grid container className={formState.isDirty ? "pb-5" : ""}>
      <Grid container item>
        <PageTitle title={t("Order setting")} />
      </Grid>
      <FormProvider {...methods}>
        <OrderSettingForm
          handlePrefixChange={handlePrefixChange}
          isMultiCODOrderWithOutDepositEnabled={isMultiCODOrderWithOutDepositEnabled}
          prefixOrderConfig={prefixOrderConfig}
          timerOrderConfig={timerOrderConfig}
          isEmailRequired={isEmailRequired}
        />
      </FormProvider>
      <StickyPanel>
        {formState.isDirty && (
          <Grid container item xs={12} className="p-3">
            <Button fullWidth onClick={handleSubmit(handleSubmitForm)}>
              {t("Save")}
            </Button>
          </Grid>
        )}
      </StickyPanel>
    </Grid>
  );
};

export default DefaultSetting;
