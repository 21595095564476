import { Grid } from "@material-ui/core";

import styled from "styled-components";

export const AvatarWrapper = styled.div`
  position: absolute;
  top: -60px;
  left: -8px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const IconEditWrapper = styled.span`
  padding: 2px 5px;
  font-size: 19px;
  color: ${({ theme }) => theme.COLORS.DarkMed};
  border-radius: 6px;
  border: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
  background-color: ${({ theme }) => theme.COLORS.White};
  position: absolute;
  top: 0;
  right: -15px;
`;

export const GridButtonWrapper = styled(Grid)`
  display: flex;
  flex-wrap: nowrap;
`;
