import Box from "@material-ui/core/Box";
import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { REPLY_SHORTCUT as REPLY_SHORTCUT_I18N } from "constants/i18n";
import COLORS from "constants/Colors";
import { SmallSquareButton } from "components/Button";
import Card from "components/Card";
import Typography from "components/Typography";
import { SvgIcon } from "components/Icon";
import { IcEdit, IcDelete } from "components/SvgIcons";

type ReplyShortcutCardProps = {
  description: string;
  id: string;
  name: string;
  onClickDelete: (id: string) => void;
  onClickEdit: (id: string) => void;
  shortcutKey: string;
};

const ReplyShortcutCard: FC<ReplyShortcutCardProps> = ({
  description,
  id,
  name,
  onClickDelete,
  onClickEdit,
  shortcutKey,
}) => {
  const { t } = useTranslation(REPLY_SHORTCUT_I18N);

  return (
    <Card fullWidth noShadow>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="title8" color="dark" className="mb-2">
          {`/${shortcutKey}`}
        </Typography>
        <Box display="flex">
          <SmallSquareButton
            className="border-box mr-2"
            onClick={() => {
              onClickEdit(id);
            }}
          >
            <SvgIcon
              component={IcEdit}
              size="small"
              htmlColor={COLORS.DarkMed}
              className="m-0 p-0"
              viewBox="-5 -4 40 40"
            />
          </SmallSquareButton>
          <SmallSquareButton
            className="border-box"
            onClick={() => {
              onClickDelete(id);
            }}
          >
            <SvgIcon
              component={IcDelete}
              size="small"
              htmlColor={COLORS.DarkMed}
              className="m-0 p-0"
              viewBox="-5 -4 40 40"
            />
          </SmallSquareButton>
        </Box>
      </Box>
      <Box display="flex" className="mb-1">
        <Typography variant="title8" color="darkLight" className="mr-1">
          {t("NAME")}:
        </Typography>
        <Typography variant="body3" color="darkGray">
          {name || "-"}
        </Typography>
      </Box>
      <Box display="flex" className="mb-1">
        <Typography variant="title8" color="darkLight" className="mr-1">
          {t("DESCRIPTION")}:
        </Typography>
        <Typography variant="body3" color="darkGray">
          {description || "-"}
        </Typography>
      </Box>
    </Card>
  );
};

export default memo(ReplyShortcutCard);
