import { useQuery, useMutation } from "@apollo/client";

import { CREDIT_CARD } from "graphql/billing/query";
import { ADD_CREDIT_CARD, REMOVE_CREDIT_CARD } from "graphql/billing/mutation";
import { CreditCard, CreditCardInput } from "types/Billing";
import { notifySuccess } from "utils/notify";
import i18n from "utils/i18n";

interface CreditCardVars {
  projectId: string;
}

export interface AddCreditCardVars {
  creditCard: CreditCardInput;
  projectId: string;
}

interface RemoveCreditCardVars {
  projectId: string;
  id: string;
}

export const useCreditCard = (projectId: string, onSuccessAddCreditCard?: () => void) => {
  const { data, loading: isLoadingCreditCard, refetch } = useQuery<{ creditCard: CreditCard }, CreditCardVars>(
    CREDIT_CARD,
    {
      variables: {
        projectId,
      },
      fetchPolicy: "cache-first",
    },
  );

  const [addCreditCard, { loading: isLoadingAddCreditCard }] = useMutation<
    { addCreditCard: CreditCard },
    AddCreditCardVars
  >(ADD_CREDIT_CARD, {
    onCompleted: () => {
      if (onSuccessAddCreditCard) {
        onSuccessAddCreditCard();
      } else {
        notifySuccess(i18n.t("billing.addCreditCardForm.success"));
      }
    },
    update(cache, { data }) {
      cache.writeQuery({
        query: CREDIT_CARD,
        variables: {
          projectId,
        },
        data: {
          creditCard: {
            ...data?.addCreditCard,
          },
        },
      });
    },
  });

  const [removeCreditCard, { loading: isLoadingRemoveCreditCard }] = useMutation<
    { removeCreditCard: CreditCard },
    RemoveCreditCardVars
  >(REMOVE_CREDIT_CARD, {
    update(cache) {
      const cacheCreditCard = cache.readQuery({
        query: CREDIT_CARD,
        variables: {
          projectId,
        },
      });
      if (cacheCreditCard) {
        cache.writeQuery({
          query: CREDIT_CARD,
          variables: {
            projectId,
          },
          data: {
            creditCard: null,
          },
        });
      }
    },
  });

  return {
    creditCard: data?.creditCard,
    isLoadingCreditCard,
    isLoadingAddCreditCard,
    isLoadingRemoveCreditCard,
    addCreditCard,
    removeCreditCard,
    refetchCreditCard: refetch,
  };
};

export default useCreditCard;
