import produce from "immer";
import { INTENT_COLOR } from "constants/Faq";
import ToolTip from "domain/Faq/FaqThEn/ToolTip";
import { MapColorIntent, FaqColorIntents } from "types/AISetting";

const mapColorIntents = (colorIntents: FaqColorIntents[]) =>
  produce<MapColorIntent>({}, (draft) => {
    colorIntents.forEach((colorIntent) => {
      const colorIntentValue = draft?.[colorIntent.originalText];
      const newColorIntentValue = {
        overlappedIntents: colorIntent.overlappedIntents,
        highlight: colorIntent.text,
        className: INTENT_COLOR[colorIntent.state],
        component: ToolTip,
        tooltip: colorIntent?.localSynonyms?.length ? `Synonyms: ${colorIntent.localSynonyms.join(", ")}` : "",
      };

      if (colorIntentValue && Array.isArray(colorIntentValue)) {
        draft[colorIntent.originalText].push(newColorIntentValue);
      } else {
        draft[colorIntent.originalText] = [newColorIntentValue];
      }
    });
  });

export default mapColorIntents;
