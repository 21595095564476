import React, { FC, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import TextField from "./TextField.styled";
import InputAdornment from "./InputAdornment.styled";
import { TextFieldPropsType } from "./index";
import { SvgIcon } from "../Icon";
import { IcHide, IcShow } from "../SvgIcons";

const PasswordField: FC<TextFieldPropsType> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      {...props}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              className="p-1"
              edge="end"
              aria-label="toggle password visibility"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              <SvgIcon viewBox="0 4 18 18" component={showPassword ? IcShow : IcHide} fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
