import styled from "styled-components";
import Typography from "components/Typography";

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;
export const ErrorWrapper = styled.div`
  width: 100%;
  max-width: 812px;
  padding: 24px;
  box-sizing: border-box;
`;

export const ErrorTextTitle = styled(Typography)`
  font-weight: bold;
  color: ${({ theme }) => theme.COLORS.Dark};
  width: 100%;
  max-width: 640px;
  margin: auto;
  box-sizing: border-box;
`;

export const ErrorTextDescription = styled(Typography)`
  color: ${({ theme }) => theme.COLORS.DarkGray};
  width: 100%;
  max-width: 640px;
  margin: auto;
  box-sizing: border-box;
`;
