import {
  SAVE_PRODUCT_NAME,
  SAVE_PRODUCT_BRAND,
  SAVE_PRODUCT_CATEGORY,
  SAVE_PRODUCT_PROPERTY,
  SAVE_PRODUCT_SKU,
} from "graphql/synonym/mutation";

import { SynonymConfigType } from "types/Synonym";

export const SYNONYM_LIMIT_PER_PAGE = 50;

export const SYNONYM_CONFIGS: SynonymConfigType = {
  PRODUCT_NAME: {
    MUTATION: SAVE_PRODUCT_NAME,
    TITLE: "Product name",
  },
  PRODUCT_BRAND: {
    MUTATION: SAVE_PRODUCT_BRAND,
    TITLE: "Product brand",
  },
  CATEGORY: {
    MUTATION: SAVE_PRODUCT_CATEGORY,
    TITLE: "Product category",
  },
  SKU_TYPE: {
    MUTATION: SAVE_PRODUCT_SKU,
    TITLE: "Product SKU",
  },
  PROPERTY_TYPE: {
    MUTATION: SAVE_PRODUCT_PROPERTY,
    TITLE: "Product property",
  },
};
