import React from "react";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import Button from "components/Button";
import Divider from "components/Divider";
import { SvgIcon } from "components/Icon";
import { IcAdd } from "components/SvgIcons";

import { Device } from "types/Device";
import useDevice from "utils/hooks/useDevice";

import BroadcastTable from "../BroadcastTable";

interface BroadcastLineTableProps {
  onClick: () => void;
  onClickTitle: (id: string) => void;
}

function BroadcastLineTable({ onClickTitle, onClick }: BroadcastLineTableProps): JSX.Element {
  const { t } = useTranslation();

  const device = useDevice();
  const isDesktop = device === Device.DESKTOP;

  return (
    <Grid id="BroadcastTablePageContainer" item container xs={12}>
      <Grid item xs={12} className="mx-4">
        <Divider margin="2px 0 0 0" className="my-4" />
      </Grid>
      <Grid item container xs={12} className="mx-4">
        <Grid item xs={12} container justify="flex-end" alignItems="center" alignContent="center">
          <Button color="primary" className="mx-2" size="medium" onClick={onClick}>
            <SvgIcon component={IcAdd} fontSize="small" />
            {isDesktop ? t("broadcast.add") : t("Add")}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} className="pt-3 px-3">
        <BroadcastTable onClickTitle={onClickTitle} />
      </Grid>
    </Grid>
  );
}

export default BroadcastLineTable;
