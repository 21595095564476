import { useQuery } from "@apollo/client";

import { ACTIVE_PROMOTIONS } from "graphql/promotion/query";
import { PromotionType } from "types/Promotion";

type UseGetPromotionsPropsType = {
  projectId: string;
};

const useGetPromotions = (props: UseGetPromotionsPropsType) => {
  const { projectId } = props;

  const { loading, data } = useQuery(ACTIVE_PROMOTIONS, {
    variables: {
      projectId,
    },
    skip: !projectId,
  });

  const activePromotionsResults: PromotionType[] = (data && data.activePromotions) || [];

  return {
    loading,
    activePromotionsResults,
  };
};

export default useGetPromotions;
