import React, { FC, ReactNode, AllHTMLAttributes } from "react";

import Divider from "components/Divider";
import Grid from "components/Grid";
import Typography from "components/Typography";
import { StyledGrid } from "./styled";

type PageTitlePropsType = {
  title: string;
  description?: string | ReactNode;
  topRightLabel?: ReactNode;
};

const PageTitle: FC<PageTitlePropsType & AllHTMLAttributes<HTMLDivElement>> = (props) => {
  const { title, description, topRightLabel, className } = props;
  return (
    <StyledGrid container item xs={12} className={className || ""}>
      <Grid item xs={12} container justify="space-between">
        <Typography variant="title2" color="dark">
          {title}
        </Typography>

        {topRightLabel}
      </Grid>
      {description && (
        <Grid item xs={12}>
          <Divider />
          {typeof description === "string" ? <Typography variant="body2">{description}</Typography> : description}
        </Grid>
      )}
    </StyledGrid>
  );
};

export default PageTitle;
