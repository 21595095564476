import React from "react";
import { FieldError, DeepMap } from "react-hook-form";
import { ValueType, OptionType } from "react-select";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

import { IcDelete } from "components/SvgIcons";
import { NumberFormatCustom } from "components/NumberFormatCustom";
import { SvgIcon } from "components/Icon";
import Button from "components/Button";
import Card from "components/Card";
import ErrorText from "components/ErrorText";
import Grid from "components/Grid";
import Select from "components/Select";
import Switch from "components/Switch";
import TextField from "components/TextField";
import Typography from "components/Typography";
import {
  MAXIMUM_PRODUCT_IMAGE_UPLOAD,
  MAXIMUM_PRODUCT_NAME_STRING_LENGTH,
  MAXIMUM_PRODUCT_DESCRIPTION_STRING_LENGTH,
} from "config";

import COLORS from "constants/Colors";
import ImageUpload from "components/ImageUpload";

import { Category } from "types";
import {
  ProductImagesType,
  ProductPropertiesType,
  AddProductSKUType,
  Product,
  DigitalContentProductType,
} from "types/Product";
import { ImageUrl } from "types/Image";

import { getSuggesionVariationListFromSelectedCategory } from "utils/product/getSuggesionVariationListFromSelectedCategory";
import { getSuggesionPropertyListFromSelectedCategory } from "utils/product/getSuggesionPropertyListFromSelectedCategory";

import nanoid from "utils/nanoid";

import { CategoryContentWrapper } from "../StyledComponent/product.styled";
import { BinIconContainer, ProductCardWrapper } from "../AddProductInTypeSKUForm/addProduct.styled";

type AddProductDetailPropsType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: DeepMap<Record<string, any>, FieldError>;
  categoryData: Category.CategoryType[];
  handleSubmitForm: Function;
  handleValidateForm: Function;
  isEditMode?: boolean;
  productData: Product;
  productIsActive: boolean;
  productIsFree: boolean;
  productSKUs: AddProductSKUType[];
  propertyListData: ProductPropertiesType[];
  selectedCategoryId: string;
  setIsAddPropertyModalOpen: Function;
  setProductData: Function;
  setProductIsActive: Function;
  setProductIsFree: Function;
  setProductSKUs: Function;
  setPropertyListData: Function;
  setSelectedCategoryId: Function;
  setValue: Function;
  setVariationListData: Function;
  isProductWithShadowSKU: boolean;
};

export const DigitalContentProductDetails: React.FC<AddProductDetailPropsType> = (props) => {
  const { t } = useTranslation();
  const {
    errors,
    categoryData,
    handleSubmitForm,
    handleValidateForm,
    isEditMode,
    productData,
    productIsActive,
    productIsFree,
    productSKUs,
    propertyListData,
    selectedCategoryId,
    setIsAddPropertyModalOpen,
    setProductData,
    setProductIsActive,
    setProductIsFree,
    setProductSKUs,
    setPropertyListData,
    setSelectedCategoryId,
    setValue,
    setVariationListData,
    isProductWithShadowSKU,
  } = props;

  const productDescription = get(productData, "description") || "";

  const handleSelectedCategory = (categoryId: string, category: Category.CategoryType) => {
    setValue("selectedCategoryId", categoryId);
    setSelectedCategoryId(categoryId);

    if (!isEditMode) {
      const newPropertiesData = [...getSuggesionPropertyListFromSelectedCategory(category)];
      setValue("properties", newPropertiesData);
      setPropertyListData(newPropertiesData);
      setVariationListData([...getSuggesionVariationListFromSelectedCategory(category)]);
    }
  };

  const handleRemoveProperty = (propertyName: string) => {
    const filteredNewProperty = propertyListData.filter(
      (property: ProductPropertiesType) => property.name !== propertyName,
    );
    setValue("properties", filteredNewProperty);
    setPropertyListData(filteredNewProperty);
  };

  const handlePropertyValueChange = (propertyName: string, propertyValue: string) => {
    const tempPropertyListData = propertyListData.map((propertyItem: ProductPropertiesType) => {
      const returnPropertyItem = { ...propertyItem };
      if (propertyItem.name === propertyName) {
        returnPropertyItem.value = propertyValue;
      }
      return returnPropertyItem;
    });
    setValue("properties", tempPropertyListData);
    setPropertyListData(tempPropertyListData);
  };

  const handleAddImage = (imageSources: ImageUrl[]) => {
    const tempImageList = productData.images.map((imageItem: ProductImagesType) => {
      const returnImageItem = { ...imageItem };
      return returnImageItem;
    });
    const newTempImageList = imageSources.map(({ publicUrl }: ImageUrl) => {
      return { nanoid: nanoid(), src: publicUrl };
    });
    const editedTempImageList = [...tempImageList, ...newTempImageList];
    setProductData({ ...productData, images: editedTempImageList });
  };

  const handleRemoveImage = (imagenanoid: string) => {
    const tempImageList = productData.images.filter((imageItem: ProductImagesType) => imageItem.nanoid !== imagenanoid);
    setProductData({ ...productData, images: tempImageList });
  };

  const handleSetPrimaryImage = (imageId: string) => {
    const secondaryImageList = productData.images.filter(
      (imageItem: ProductImagesType) => imageItem.nanoid !== imageId,
    );
    const primaryImageList = productData.images.filter((imageItem: ProductImagesType) => imageItem.nanoid === imageId);
    const tempImageList = [...primaryImageList, ...secondaryImageList];
    setProductData({ ...productData, images: tempImageList });
  };

  const setAllSKUsDisableTo = (value: boolean) => {
    const newProductSKUs = productSKUs.map((productSKU: AddProductSKUType) => {
      const returnProductSKUs = { ...productSKU };
      returnProductSKUs.selected = Boolean(value);
      return returnProductSKUs;
    });
    setProductSKUs(newProductSKUs);
  };

  const setAllSKUsIsFreeTo = (value: boolean) => {
    const newProductSKUs = productSKUs.map((productSKU: AddProductSKUType) => {
      const returnProductSKUs = { ...productSKU };
      returnProductSKUs.isFree = Boolean(value);
      return returnProductSKUs;
    });
    setProductSKUs(newProductSKUs);
  };

  const handleProductNameChange = (value: string) => {
    setValue("productName", value);
    setProductData({ ...productData, name: value });
  };

  const digitalContentProductOptions = [
    {
      label: t("TOP_UP_AND_VOUCHER"),
      value: DigitalContentProductType.TOP_UP_AND_VOUCHER,
      isDisabled: false,
    },
    { label: `${t("EVENT_TICKET")} *soon`, value: DigitalContentProductType.EVENT_TICKET, isDisabled: true },
    { label: `${t("ONLINE_COURSE")} *soon`, value: DigitalContentProductType.ONLINE_COURSE, isDisabled: true },
  ];

  return (
    <ProductCardWrapper>
      <Card borderRadius={0} noShadow>
        <Grid container justify="space-between" className="my-4">
          <Typography variant="title2">{t("Product details")}</Typography>
          <Grid item>
            <Grid container alignItems="center">
              <Typography variant="body4" className="px-2" color={COLORS.DarkMed}>
                {(productSKUs.length ? productSKUs.some((productSKU) => productSKU.selected === true) : productIsActive)
                  ? t("Active")
                  : t("Disabled")}
              </Typography>
              <Switch
                data-cy="toggleProduct"
                checked={
                  productSKUs && productSKUs.length ? productSKUs.some((o) => o.selected === true) : productIsActive
                }
                onChange={(event: { target: { checked: boolean } }) => {
                  setProductIsActive(event.target.checked);
                  setAllSKUsDisableTo(event.target.checked);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Select
              isDisabled={isEditMode}
              placeholder={t("Select digital content type")}
              isSearchable={false}
              className="mt-3"
              value={{
                label: t(productData.contentType || "Select digital content type"),
                value: productData.contentType || "",
                isDisabled: false,
              }}
              onChange={(selectedValue: ValueType<OptionType>) => {
                setValue("contentType", get(selectedValue, "value"));
                setProductData({ ...productData, contentType: get(selectedValue, "value") });
              }}
              options={digitalContentProductOptions}
              fullWidth
            />
          </Grid>
          {errors.contentType && <ErrorText className="mt-2">{t(get(errors, "contentType.message"))}</ErrorText>}
        </Grid>
        <Grid container justify="space-between" className="mt-2">
          <Typography variant="body4" color={COLORS.DarkMed}>
            {t("Product images")}
          </Typography>
          <Typography variant="body4" color={COLORS.DarkMed}>
            {productData.images.length} / {MAXIMUM_PRODUCT_IMAGE_UPLOAD}
          </Typography>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <CategoryContentWrapper className="d-flex">
              {productData.images.length < MAXIMUM_PRODUCT_IMAGE_UPLOAD && (
                <ImageUpload
                  isShowLabel
                  onChange={handleAddImage}
                  image=""
                  imageId="imageProductId0"
                  multiple
                  limitItem={MAXIMUM_PRODUCT_IMAGE_UPLOAD - productData.images.length}
                />
              )}

              {productData.images.map((image, index) => {
                return (
                  <div className="d-flex pl-2" key={image.src}>
                    <ImageUpload
                      isShowLabel
                      isPrimary={index === 0}
                      handleSetPrimaryImage={handleSetPrimaryImage}
                      onChange={(imageUrls: ImageUrl[]) => {
                        if (!imageUrls.length) {
                          handleRemoveImage(image.nanoid);
                        }
                      }}
                      image={image.src}
                      imageId={image.nanoid}
                    />
                  </div>
                );
              })}
            </CategoryContentWrapper>
          </Grid>
        </Grid>
        <Grid container justify="space-between">
          <Typography variant="body4" className="mt-2" color={COLORS.DarkMed}>
            {t("Product name")}
          </Typography>
          <Typography variant="body4" className="mt-2 mx-2" color={COLORS.DarkMed}>
            {productData.name.length} / {MAXIMUM_PRODUCT_NAME_STRING_LENGTH}
          </Typography>
        </Grid>
        <Grid container>
          <Grid container item xs={12}>
            <Grid item xs={12} className="my-2">
              <TextField
                data-cy="productName"
                fullWidth
                placeholder={t("Product name")}
                value={productData.name}
                error={Boolean(errors.productName)}
                helperText={t(get(errors, "productName.message"))}
                onChange={(event) => {
                  handleProductNameChange(event.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Typography variant="body4" className="py-2" color={COLORS.DarkMed}>
              {t("Categories")}
            </Typography>

            <Grid item container xs={12}>
              <CategoryContentWrapper>
                {categoryData &&
                  categoryData.map((category: Category.CategoryType) => {
                    return (
                      <Button
                        isSelected={selectedCategoryId === category.id}
                        color="secondary"
                        borderRadius="8px"
                        key={category.id}
                        className="m-1"
                        onClick={() => handleSelectedCategory(category.id, category)}
                      >
                        {category.name}
                      </Button>
                    );
                  })}
              </CategoryContentWrapper>
              {errors.selectedCategoryId && <ErrorText>{t(get(errors, "selectedCategoryId.message"))}</ErrorText>}
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={6} className="px-2 pb-1">
            <Grid item xs={12}>
              <Typography variant="body4" className="mt-2" color={COLORS.DarkMed}>
                {t("Brand")} - {t("optional")}
              </Typography>
            </Grid>

            <Grid item xs={12} className="mt-2">
              <TextField
                data-cy="brand"
                fullWidth
                variant="outlined"
                placeholder={t("Brand")}
                value={productData.brand}
                onChange={(event) => {
                  setProductData({ ...productData, brand: event.target.value });
                }}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} sm={6} className="px-2 pb-1">
            <Grid item xs={12}>
              <Typography variant="body4" className="mt-2" color={COLORS.DarkMed}>
                {t("Product Unit")} - {t("optional")}
              </Typography>
            </Grid>
            <Grid item xs={12} className="mt-2">
              <TextField
                data-cy="productUnit"
                fullWidth
                variant="outlined"
                placeholder={t("Product Unit")}
                value={productData.unit}
                onChange={(event) => {
                  setProductData({ ...productData, unit: event.target.value });
                }}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} className="px-2 pb-1">
            <Grid item xs={12}>
              <Typography variant="body4" className="mt-2" color={COLORS.DarkMed}>
                {t("Product Code")}
              </Typography>
            </Grid>
            <Grid item xs={12} className="mt-2">
              <TextField
                data-cy="productCode"
                fullWidth
                variant="outlined"
                placeholder={t("Product Code")}
                value={productData.productCode}
                error={Boolean(errors.productCode)}
                helperText={t(get(errors, "productCode.message"))}
                onChange={(event) => {
                  setValue("productCode", event.target.value);
                  if (isProductWithShadowSKU && productData.productSKUs.length === 1) {
                    // make sure this is shadow SKU
                    setProductData({
                      ...productData,
                      productCode: event.target.value,
                      productSKUs: [{ ...productData.productSKUs[0], productCode: event.target.value }],
                    });
                  } else {
                    setProductData({ ...productData, productCode: event.target.value });
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} sm={6} className="px-2 pb-1">
            <Grid item xs={12}>
              <Typography variant="body4" className="mt-2" color={COLORS.DarkMed}>
                {t("Product CF Code")} - {t("product.cfCode.optional")}
              </Typography>
            </Grid>
            <Grid item xs={12} className="mt-2">
              <TextField
                data-cy="cfCode"
                fullWidth
                variant="outlined"
                placeholder={t("Please put CF Code here")}
                value={productData.cfCode}
                error={Boolean(errors.cfCode)}
                helperText={t(get(errors, "cfCode.message"))}
                onChange={(event) => {
                  const cfCodeValue = event.target.value || null;
                  setValue("cfCode", cfCodeValue);
                  if (isProductWithShadowSKU && productData.productSKUs.length === 1) {
                    // make sure this is shadow SKU
                    setProductData({
                      ...productData,
                      cfCode: cfCodeValue,
                      productSKUs: [{ ...productData.productSKUs[0], cfCode: cfCodeValue }],
                    });
                  } else {
                    setProductData({ ...productData, cfCode: cfCodeValue });
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} sm={6} className="px-2">
            <Grid container justify="space-between">
              <Typography variant="body4" className="mt-2" color={COLORS.DarkMed}>
                {t("Product Description")} - {t("optional")}
              </Typography>
              <Typography variant="body4" className="mt-2 mr-2 ml-2" color={COLORS.DarkMed}>
                {productDescription.length} / {MAXIMUM_PRODUCT_DESCRIPTION_STRING_LENGTH}
              </Typography>
            </Grid>
            <Grid item xs={12} className="mt-2">
              <TextField
                data-cy="productDescription"
                variant="outlined"
                multiline
                rows={3}
                fullWidth
                placeholder={t("Product Description")}
                value={productData.description}
                onChange={(event) => {
                  setProductData({ ...productData, description: event.target.value });
                }}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} sm={6} className="px-2">
            <Grid item xs={12}>
              <Typography variant="body4" className="mt-2" color={COLORS.DarkMed}>
                {t("Product Conditions")}
              </Typography>
            </Grid>
            <Grid item xs={12} className="mt-2">
              <TextField
                data-cy="productAdditionalDescription"
                variant="outlined"
                multiline
                rows={3}
                fullWidth
                placeholder={t("Product Conditions")}
                value={productData.additionalDescription}
                error={Boolean(errors.additionalDescription)}
                helperText={t(get(errors, "additionalDescription.message"))}
                onChange={(event) => {
                  setValue("additionalDescription", event.target.value);
                  setProductData({ ...productData, additionalDescription: event.target.value });
                }}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} className="px-2">
            <Grid item xs={12}>
              <Typography variant="body4" className="mt-2" color={COLORS.DarkMed}>
                {t("Price")}
              </Typography>
            </Grid>
            <Grid item xs={12} className="my-2">
              <TextField
                variant="outlined"
                data-cy="productPrice"
                error={Boolean(errors.productPrice)}
                helperText={t(get(errors, "productPrice.message"))}
                value={productData.defaultPrice}
                onChange={(event) => {
                  setValue("productPrice", +parseFloat(event.target.value).toFixed(2) || 0.0);
                  setProductData({ ...productData, defaultPrice: +parseFloat(event.target.value).toFixed(2) || 0.0 });
                }}
                fullWidth
                placeholder={t("Price")}
                InputProps={{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  inputComponent: NumberFormatCustom as any,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container justify="space-between" className="my-4">
          <Typography variant="body4" className="mt-2" color={COLORS.DarkMed}>
            {t("This product is free")}
          </Typography>
          <Switch
            data-cy="freeProduct"
            checked={
              productSKUs && productSKUs.length
                ? productSKUs.every((productSKU) => productSKU.isFree === true)
                : productIsFree
            }
            onChange={(event: { target: { checked: boolean } }) => {
              setProductIsFree(event.target.checked);
              setAllSKUsIsFreeTo(event.target.checked);
            }}
          />
        </Grid>
      </Card>
      <Card className="mt-4" borderRadius={0} noShadow>
        <Grid container justify="space-between" className="my-4">
          <Typography variant="title2" className="mb-1">
            {t("Properties")}
          </Typography>
        </Grid>
        <>
          {propertyListData.map((property: ProductPropertiesType, index: number) => {
            const errorProperties = get(errors, `properties.${index}.value.message`);

            return (
              <div key={property.name}>
                <Grid container justify="space-between" alignItems="center">
                  <Typography className="d-flex" variant="body4">
                    {property.name}
                  </Typography>
                  <BinIconContainer className="d-flex" onClick={() => handleRemoveProperty(property.name)}>
                    <SvgIcon className="ml-1 mt-1" component={IcDelete} fontSize="small" htmlColor={COLORS.DarkMed} />
                  </BinIconContainer>
                </Grid>
                <Grid container justify="space-between" alignItems="center">
                  <TextField
                    error={Boolean(errorProperties)}
                    helperText={t(errorProperties)}
                    className="my-2 d-flex"
                    fullWidth
                    placeholder={t("property value")}
                    value={property.value}
                    onChange={(event) => {
                      handlePropertyValueChange(property.name, event.target.value);
                    }}
                    name={`properties[${index}].value`}
                  />
                </Grid>
              </div>
            );
          })}
        </>
        <Grid item xs={12} className="p-2">
          <Button color="secondary" fullWidth onClick={() => setIsAddPropertyModalOpen(true)}>
            + {t("Add new property")}
          </Button>
        </Grid>
      </Card>

      {!isEditMode && (
        <Grid item xs={12} className="p-4">
          <Button
            fullWidth
            onClick={() => {
              setValue("selectedCategoryId", selectedCategoryId);
              setValue("properties", propertyListData);
              setValue("productSKUs", productSKUs);
              setValue("productName", productData.name || "");
              setValue("productCode", productData.productCode || "");
              setValue("cfCode", productData.cfCode || "");
              setValue("productPrice", productData.defaultPrice);
              handleValidateForm();
            }}
          >
            {t("Next Product SKU")} &gt;
          </Button>
        </Grid>
      )}

      {isEditMode && Boolean(productSKUs && productSKUs.length !== 0) && (
        <Grid item xs={12} className="p-4">
          <Button
            fullWidth
            onClick={() => {
              setValue("selectedCategoryId", selectedCategoryId);
              setValue("properties", propertyListData);
              setValue("productSKUs", productSKUs);
              setValue("productName", productData.name || "");
              setValue("productCode", productData.productCode || "");
              setValue("cfCode", productData.cfCode || "");
              setValue("productPrice", productData.defaultPrice);
              handleSubmitForm();
            }}
          >
            {t("Save")}
          </Button>
        </Grid>
      )}
    </ProductCardWrapper>
  );
};
