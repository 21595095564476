import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { TextFieldProps } from "@material-ui/core/TextField";
import TextField from "components/TextField";
import ErrorText from "components/ErrorText";

type InputProps = {
  name: string;
};

const Input: FC<InputProps & TextFieldProps> = ({
  defaultValue = "",
  label = "",
  name,
  fullWidth = false,
  placeholder,
  className,
  variant = "outlined",
  required,
  ...rest
}) => {
  const { register, errors } = useFormContext();

  return (
    <>
      <TextField
        name={name}
        validate={register}
        defaultValue={defaultValue}
        fullWidth={fullWidth}
        label={label}
        variant={variant}
        placeholder={placeholder}
        error={Boolean(errors[name])}
        className={className}
        required={required}
        {...rest}
      />
      <ErrorText>{errors[name] && errors[name].message}</ErrorText>
    </>
  );
};

export default Input;
