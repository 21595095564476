import React, { FC, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { OptionType, ValueType } from "react-select";
import debounce from "lodash/debounce";
import get from "lodash/get";
import moment from "moment";

import { CircleIcon, SvgIcon } from "components/Icon";
import Grid from "components/Grid";
import Pagination from "components/Pagination";
import Search from "components/Search";
import CircularProgress from "components/CircularProgress";

import Button, { FloatingButton } from "components/Button";
import DatePicker from "components/DatePicker";
import Select from "components/Select";
import { StickyButtonContainer } from "components/StickyPanel";
import WhiteSpaceBottom from "components/WhiteSpaceBottom";
import PageTitle from "components/PageTitle";
import { IcAdd, IcDelete, IcEdit, IcMore, IcPreference, IcStop, IcArrowToTop } from "components/SvgIcons";
import Typography from "components/Typography";
import {
  CAMPAIGN_STATUS,
  CAMPAIGN_SORT_OPTIONS,
  CAMPAIGN_STATUS_LABELS,
  LIMIT,
  CONFIRM_MODAL_TYPE,
} from "constants/Campaign";
import { CAMPAIGN, PROJECT, MARKETING_TOOLS } from "constants/Router";
import { DATE_PICKER_DATE_FORMAT, CAMPAIGN_DATE_TIME_FORMAT } from "constants/DateTimeFormat";
import { CampaignWithoutPromotionType, CampaignConnectionType } from "types/Campaign";
import { ProjectIdType } from "types/Project";
import { CAMPAIGNS } from "graphql/campaign/query";
import { STOP_CAMPAIGN, REMOVE_CAMPAIGN } from "graphql/campaign/mutation";
import { INVENTORIES_QUERY_INTERVAL, DEBOUNCE_TIME } from "constants/Order";
import { COLORS } from "constants/Colors";
import useDevice from "utils/hooks/useDevice";
import { Device } from "types/Device";

import useScrollToTopElement from "utils/hooks/useScrollToTopElement";
import { DEFAULT_SCROLL } from "constants/Scroll";
import MenuList from "../../components/Menu/MenuList";
import AddEditCampaignModal from "./CampaignFormModal";
import ConfirmModal from "../../components/Modal/ConfirmModal";
import { CampaignCardWrapper, MenuButton, ColoredStopIconWrapper } from "./styled";

const CampaignListContainer: FC = () => {
  const { t } = useTranslation();

  const { projectId } = useParams<ProjectIdType>();
  const device = useDevice();
  const isDesktop = device === Device.DESKTOP;

  const [searchWord, setSearchWord] = useState("");
  const [isShowFilters, setIsShowFilters] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [selectedStartDate, setSelectedStartDate] = useState<string | undefined>();
  const [selectedEndDate, setSelectedEndDate] = useState<string | undefined>();

  const [selectedCampaignStatus, setSelectedCampaignStatus] = useState<ValueType<OptionType>>({
    value: CAMPAIGN_STATUS.LIVE_AND_UPCOMING,
    label: t("Live & Upcoming"),
  });

  const [selectedSortBy, setSelectedSortBy] = useState<ValueType<OptionType>>();

  const queryVariable = {
    projectId,
    limit: LIMIT,
    offset: pageNo - 1,
    filter: {
      search: searchWord,
      status: get(selectedCampaignStatus, "value"),
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      orderBy: get(selectedSortBy, "value"),
    },
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenAddEditCampaignModal, setIsOpenAddEditCampaignModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [focusedCampaign, setFocusCampaign] = useState<any>();
  const [confirmModalType, setConfirmModalType] = useState(CONFIRM_MODAL_TYPE.STOP);

  const { scrollTopId, handleScrollToTop, isScroll } = useScrollToTopElement(
    DEFAULT_SCROLL.CAMPAIGN_SCROLL_TOP,
    isDesktop,
  );

  const { data, loading } = useQuery(CAMPAIGNS, {
    variables: queryVariable,
    pollInterval: INVENTORIES_QUERY_INTERVAL,
  });

  const [stopCampaign] = useMutation(STOP_CAMPAIGN, {
    update(cache, { data: { disableCampaign: disabledCampaign } }) {
      const cacheCampaigns = cache.readQuery<CampaignConnectionType>({
        query: CAMPAIGNS,
        variables: queryVariable,
      });

      if (cacheCampaigns) {
        const newCampaignResults = cacheCampaigns.campaigns.results.filter(
          (cacheCampaign: CampaignWithoutPromotionType) => disabledCampaign.id !== cacheCampaign.id,
        );

        cache.writeQuery({
          query: CAMPAIGNS,
          variables: queryVariable,
          data: {
            campaigns: {
              results: newCampaignResults,
              total: newCampaignResults.length,
              // eslint-disable-next-line no-underscore-dangle
              __typename: cacheCampaigns.campaigns.__typename,
            },
          },
        });
      }
    },
  });

  const [removeCampaign] = useMutation(REMOVE_CAMPAIGN, {
    update(cache, { data: { deleteCampaign: deletedCampaign } }) {
      const cacheCampaigns = cache.readQuery<CampaignConnectionType>({
        query: CAMPAIGNS,
        variables: queryVariable,
      });

      if (cacheCampaigns) {
        const newCampaignResults = cacheCampaigns.campaigns.results.filter(
          (cacheCampaign: CampaignWithoutPromotionType) => deletedCampaign.id !== cacheCampaign.id,
        );

        cache.writeQuery({
          query: CAMPAIGNS,
          variables: queryVariable,
          data: {
            campaigns: {
              results: newCampaignResults,
              total: newCampaignResults.length,
              // eslint-disable-next-line no-underscore-dangle
              __typename: cacheCampaigns.campaigns.__typename,
            },
          },
        });
      }
    },
  });

  const handleChangePage = (page: number) => {
    if (page < 1) {
      return setPageNo(1);
    }
    return setPageNo(page);
  };

  const handleSearchChange = debounce((value: string) => {
    setPageNo(1);
    setSearchWord(value);
  }, DEBOUNCE_TIME);

  const handleSelectCampaignStatus = (value: ValueType<OptionType>) => {
    setSelectedCampaignStatus(value);
  };

  const handleSelectSortCampaign = (value: ValueType<OptionType>) => {
    setSelectedSortBy(value);
  };

  const handleSelectStartDate = (value: string | null) => {
    if (value) {
      const isFutureDate = moment(value).diff(moment(), "days") >= 0;
      setSelectedStartDate(moment(value).toISOString());
      if (isFutureDate) {
        setSelectedEndDate(moment(value).add(1, "months").toISOString());
      }
    }
  };

  const handleSelectEndDate = (value: string | null) => {
    if (value) {
      setSelectedEndDate(moment(value).toISOString());
    }
  };

  const handleClickMenuButton = (event: React.MouseEvent<HTMLElement>, campaign: CampaignWithoutPromotionType) => {
    setFocusCampaign(campaign);
    setAnchorEl(event.currentTarget);
  };

  const handleOnCloseAddEditCampaignModal = () => {
    setFocusCampaign(null);
    setIsOpenAddEditCampaignModal(false);
  };

  const handleClickAddButton = () => {
    setIsOpenAddEditCampaignModal(true);
  };

  const handleCloseMenuList = () => {
    setAnchorEl(null);
  };

  const handleClickModalConfirmButton = () => {
    if (focusedCampaign) {
      const isLiveCampaign = focusedCampaign.status === CAMPAIGN_STATUS.LIVE;
      const variables = {
        projectId,
        campaignId: focusedCampaign.id,
      };

      if (isLiveCampaign) {
        stopCampaign({ variables });
      } else {
        removeCampaign({ variables });
      }
    }

    setIsOpenConfirmModal(false);
  };

  const popOverMenuList = (status: string) => {
    const isDisabled = status === CAMPAIGN_STATUS.DISABLED || status === CAMPAIGN_STATUS.END;
    return [
      {
        icon: <SvgIcon className="ml-1" component={IcEdit} fontSize="small" />,
        label: "Edit campaign",
        onClick: () => {
          handleCloseMenuList();
          setIsOpenAddEditCampaignModal(true);
        },
        isDisabled,
      },
      {
        icon:
          status === CAMPAIGN_STATUS.LIVE ? (
            <ColoredStopIconWrapper>
              <SvgIcon className="ml-1" component={IcStop} fontSize="small" htmlColor={COLORS.Error} />
            </ColoredStopIconWrapper>
          ) : (
            <SvgIcon className="ml-1" component={IcDelete} fontSize="small" />
          ),
        label: `${status === CAMPAIGN_STATUS.LIVE ? CONFIRM_MODAL_TYPE.STOP : CONFIRM_MODAL_TYPE.REMOVE} campaign`,
        onClick: () => {
          setConfirmModalType(status === CAMPAIGN_STATUS.LIVE ? CONFIRM_MODAL_TYPE.STOP : CONFIRM_MODAL_TYPE.REMOVE);
          handleCloseMenuList();
          setIsOpenConfirmModal(true);
        },
        isDisabled,
      },
    ];
  };

  const campaignStatusOptions: OptionType[] = Object.keys(CAMPAIGN_STATUS_LABELS).map((key: string) => ({
    label: t(CAMPAIGN_STATUS_LABELS[key]),
    value: key,
  }));

  const campaignSortOptions: OptionType[] = [
    { value: CAMPAIGN_SORT_OPTIONS.START_DATE, label: t("Start date") },
    { value: CAMPAIGN_SORT_OPTIONS.END_DATE, label: t("End date") },
  ];

  return (
    <>
      {loading ? (
        <Grid container justify="center">
          <CircularProgress className="m-4" />
        </Grid>
      ) : (
        <Grid container item xs={12} alignContent="flex-start" alignItems="flex-start" id={scrollTopId}>
          <Grid container>
            <Grid item container xs={12}>
              <Grid item xs={6}>
                <PageTitle title={t("Campaign")} />
              </Grid>
              <Grid item xs={6} container justify="flex-end" alignItems="center" alignContent="center" className="px-3">
                <Button
                  color="primary"
                  size="medium"
                  onClick={handleClickAddButton}
                  data-cy="addButton"
                  className="mx-2"
                >
                  <SvgIcon component={IcAdd} fontSize="small" />
                  {isDesktop ? t("Add new campaign") : t("Add")}
                </Button>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              {/* search & filter zone */}
              <Grid item container alignItems="flex-end" className="px-3 pt-1 pb-2">
                <Grid item xs={8}>
                  <Search
                    defaultValue={searchWord}
                    onChange={handleSearchChange}
                    className="mr-3"
                    placeholder={t("searchPlaceholder.marketingToolsCampaigns")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    color="secondary"
                    fullWidth
                    onClick={() => setIsShowFilters(!isShowFilters)}
                    borderRadius="8px"
                  >
                    <SvgIcon component={IcPreference} fontSize="small" />
                    <Typography variant="body3" color="darkGray">
                      {t("Filters")}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {isShowFilters && (
              <>
                <Grid item xs={12} className="py-2 px-3">
                  <Select
                    placeholder="Select Status"
                    value={selectedCampaignStatus}
                    options={campaignStatusOptions}
                    onChange={handleSelectCampaignStatus}
                    fullWidth
                  />
                </Grid>

                <Grid className="py-2 px-3" item container>
                  <Grid item xs={6} className="pr-1">
                    <DatePicker
                      selectedDate={selectedStartDate}
                      setDate={handleSelectStartDate}
                      fullWidth
                      format={DATE_PICKER_DATE_FORMAT}
                    />
                  </Grid>
                  <Grid item xs={6} className="pl-1">
                    <DatePicker
                      selectedDate={selectedEndDate}
                      setDate={handleSelectEndDate}
                      fullWidth
                      format={DATE_PICKER_DATE_FORMAT}
                      minDate={selectedStartDate || moment(selectedStartDate).toDate()}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} className="py-2 px-3">
                  <Select
                    placeholder={t("Sort by")}
                    value={selectedSortBy}
                    options={campaignSortOptions}
                    onChange={handleSelectSortCampaign}
                    fullWidth
                  />
                </Grid>
              </>
            )}

            {/* campaign list zone */}
            {data && (
              <Grid item container>
                {data.campaigns.results.map((campaign: CampaignWithoutPromotionType) => {
                  const { id, name, startDate, endDate, status } = campaign;
                  let statusColor = COLORS.DarkMed;
                  if (status === CAMPAIGN_STATUS.LIVE) {
                    statusColor = COLORS.Success;
                  } else if (status === CAMPAIGN_STATUS.UPCOMING) {
                    statusColor = COLORS.Warning;
                  }

                  return (
                    <CampaignCardWrapper item container className="my-2" xs={12} key={id}>
                      <Link to={`/${PROJECT}/${projectId}/${MARKETING_TOOLS}/${CAMPAIGN}/${id}`} className="w-100">
                        <Grid item container xs={12}>
                          <Grid item>
                            <CircleIcon color={statusColor} size="m" />
                          </Grid>
                          <Grid item>
                            <Typography variant="body3" className="text-capitalize">
                              {t(CAMPAIGN_STATUS_LABELS[status])}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} className="py-3">
                          <Typography variant="title2" color="dark">
                            {name}
                          </Typography>
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item>
                            <Typography variant="title7" color="dark" className="mr-2">
                              {t("Date")}:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">
                              {`${moment(Number(startDate)).format(CAMPAIGN_DATE_TIME_FORMAT)} - ${moment(
                                Number(endDate),
                              ).format(CAMPAIGN_DATE_TIME_FORMAT)}`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Link>
                      <MenuButton type="button" onClick={(event) => handleClickMenuButton(event, campaign)}>
                        <SvgIcon component={IcMore} fontSize="small" htmlColor={COLORS.DarkMed} />
                      </MenuButton>
                    </CampaignCardWrapper>
                  );
                })}
                {data.campaigns.total ? (
                  <Grid item container>
                    <WhiteSpaceBottom>
                      <Pagination
                        rowsPerPage={LIMIT}
                        page={pageNo}
                        totalItem={data.campaigns.total}
                        handlePageChange={handleChangePage}
                        isMobile
                      />
                    </WhiteSpaceBottom>
                  </Grid>
                ) : (
                  <Typography variant="body2" className="px-3 mx-auto text-center">
                    {t("Search not found")}
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
          <MenuList
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenuList}
            menuList={popOverMenuList(get(focusedCampaign, "status"))}
          />
          {!isDesktop && isScroll && (
            <StickyButtonContainer className="px-2 py-3">
              <FloatingButton onClick={handleScrollToTop} data-cy="addButton">
                <SvgIcon
                  className="mb-1 mt-3 ml-3"
                  component={IcArrowToTop}
                  fontSize="default"
                  htmlColor={COLORS.DarkMed}
                />
              </FloatingButton>
            </StickyButtonContainer>
          )}
          <ConfirmModal
            title={
              confirmModalType === CONFIRM_MODAL_TYPE.STOP
                ? "Do you want to stop this campaign?"
                : "Are you sure to delete the campaign? All promotion in this campaign will be deleted with the campaign"
            }
            confirmButtonText={confirmModalType}
            isOpen={isOpenConfirmModal}
            onClose={() => setIsOpenConfirmModal(false)}
            onClickConfirmButton={handleClickModalConfirmButton}
          />
          <AddEditCampaignModal
            isOpen={isOpenAddEditCampaignModal}
            onClose={handleOnCloseAddEditCampaignModal}
            focusedCampaign={focusedCampaign}
            queryVariable={queryVariable}
          />
        </Grid>
      )}
    </>
  );
};

export default CampaignListContainer;
