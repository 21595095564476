import React from "react";

const SvgIcArrowToTop = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.71 4.29C8.6149 4.19896 8.50275 4.12759 8.38 4.08C8.13654 3.97998 7.86346 3.97998 7.62 4.08C7.49725 4.12759 7.3851 4.19896 7.29 4.29L3.29 8.29C3.19676 8.38324 3.1228 8.49393 3.07234 8.61575C3.02188 8.73757 2.99591 8.86814 2.99591 9C2.99591 9.13186 3.02188 9.26243 3.07234 9.38425C3.1228 9.50607 3.19676 9.61676 3.29 9.71C3.38324 9.80324 3.49393 9.8772 3.61575 9.92766C3.73757 9.97812 3.86814 10.0041 4 10.0041C4.13186 10.0041 4.26243 9.97812 4.38425 9.92766C4.50607 9.8772 4.61676 9.80324 4.71 9.71L7 7.41V19C7 19.2652 7.10536 19.5196 7.29289 19.7071C7.48043 19.8946 7.73478 20 8 20C8.26522 20 8.51957 19.8946 8.70711 19.7071C8.89464 19.5196 9 19.2652 9 19V7.41L11.29 9.71C11.383 9.80373 11.4936 9.87812 11.6154 9.92889C11.7373 9.97966 11.868 10.0058 12 10.0058C12.132 10.0058 12.2627 9.97966 12.3846 9.92889C12.5064 9.87812 12.617 9.80373 12.71 9.71C12.8037 9.61704 12.8781 9.50644 12.9289 9.38458C12.9797 9.26272 13.0058 9.13201 13.0058 9C13.0058 8.86799 12.9797 8.73728 12.9289 8.61542C12.8781 8.49356 12.8037 8.38296 12.71 8.29L8.71 4.29ZM15 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2H15C15.2652 2 15.5196 1.89464 15.7071 1.70711C15.8946 1.51957 16 1.26522 16 1C16 0.734784 15.8946 0.48043 15.7071 0.292893C15.5196 0.105357 15.2652 0 15 0Z"
      fill="#737D94"
    />
  </svg>
);

export default SvgIcArrowToTop;
