import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import { yupResolver } from "@hookform/resolvers/yup";
import intersection from "lodash/intersection";
import React, { FC, useState, useEffect, ChangeEvent, useRef, useMemo, useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

import ErrorText from "components/ErrorText";
import Button from "components/Button";
import Grid from "components/Grid";
import MessagesManager from "components/MessagesManager";
import Typography from "components/Typography";
import {
  NEW_FAQ_QUESTION_DUPLICATED_ERROR_SANITY,
  NEW_FAQ_QUESTION_ERROR,
  NEW_FAQ_QUESTION_HAS_SOME_MAX_LENGTH_ERROR,
} from "constants/Faq";
import { FAQ_MAX_ANSWER_LENGTH, FAQ_MAX_QUESTION_LENGTH } from "config/faq";
import { FaqQuestionType, ChangedFaqQuestionType, ChangedFaqType, FaqType, ResponseMessage } from "types/AISetting";
import { MessageType } from "types/Chat";
import { ValueOf } from "types/Common";
import { removeTypenameField } from "utils/common";
import { reorder } from "utils/message";

import FormButtons from "./FormButtons";
import MessagesManagerLabel from "./MessagesManagerLabel";
import { GridQuestions, MessagesManagerWrapper, QuestionsWrapper, AddQuestionButtonWrapper } from "./style";
import { getIsDuplicatedQuestion, clearNanoidQuestions, validateDataFaq } from "./helpers";
import { useTabsStyles, useTabStyles } from "../useStyles";
import useManageQuestions from "./hooks/useManageQuestions";
import useManageResponses from "./hooks/useManageResponses";
import useHandleAddQuestions from "./hooks/useHandleAddQuestions";
import QuestionInput from "../QuestionInput";
import useSanityCheck from "../hooks/useSanityCheck";
import { CheckDuplicatedQuestionType } from "../hooks/useCheckDuplicatedQuestionFromAll";
import useValidateDuplicatedQuestion from "./hooks/useValidateDuplicatedQuestion";
import { validationThEnSchema, validationThSchema } from "./validationSchema";
import { updateQuickReplyMessageToLastItem } from "../util";

type FaqFormPropsType = {
  projectId: string;
  faq: ChangedFaqType;
  onSubmit: (faqData: FaqType) => void;
  onCancel: () => void;
  checkDuplicatedQuestionFromAll?: CheckDuplicatedQuestionType;
  isLoadingSubmit?: boolean;
  isEnableEN: boolean;
};

const FaqForm: FC<FaqFormPropsType> = ({
  projectId,
  faq,
  onSubmit,
  onCancel,
  checkDuplicatedQuestionFromAll,
  isLoadingSubmit = false,
  isEnableEN,
}) => {
  const { t } = useTranslation();
  const timeoutId = useRef<number>();
  const setTimeoutId = (newTimeoutId?: number) => {
    timeoutId.current = newTimeoutId;
  };

  const questionsWrapperDOM = useRef<HTMLDivElement>(null);
  const tabsClasses = useTabsStyles();
  const tabClasses = useTabStyles();
  const [currentTabLanguage, setCurrentTabLanguage] = useState<number>(0);
  const validationSchema = useMemo(() => (isEnableEN ? validationThEnSchema(t) : validationThSchema(t)), [
    isEnableEN,
    t,
  ]);
  const methods = useForm<ChangedFaqType>({
    defaultValues: faq,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    register({ name: "questions" });
    register({ name: "enQuestions" });
    register({ name: "responses" });
    register({ name: "enResponses" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { handleSubmit, watch, setValue, register, setError, errors, clearErrors } = methods;

  const questions = watch("questions") as ChangedFaqQuestionType[];
  const enQuestions = watch("enQuestions") as ChangedFaqQuestionType[];

  const setFAQ = (fieldName: keyof FaqType, value: ValueOf<ChangedFaqType>) => {
    clearErrors();

    let newValue = value;
    if (["enResponses", "responses"].includes(fieldName)) {
      newValue = updateQuickReplyMessageToLastItem(value as ResponseMessage[]);
    }

    setValue(fieldName, newValue);
  };

  const getQuestions = useCallback(() => {
    return currentTabLanguage === 0
      ? (watch("questions") as ChangedFaqQuestionType[])
      : (watch("enQuestions") as ChangedFaqQuestionType[]);
  }, [currentTabLanguage, watch]);

  const getResponses = useCallback(() => {
    return currentTabLanguage === 0
      ? (watch("responses") as ResponseMessage[])
      : (watch("enResponses") as ResponseMessage[]);
  }, [currentTabLanguage, watch]);

  const { sanityCheck } = useSanityCheck();
  const { getQuestionField, addQuestion, removeQuestion, updateQuestion } = useManageQuestions(
    currentTabLanguage,
    setFAQ,
    getQuestions,
  );

  const { getResponseField, addResponse, updateResponse, removeResponse, updateQuickReplyMessage } = useManageResponses(
    currentTabLanguage,
    setFAQ,
    getResponses,
  );

  const validateDuplicatedQuestion = useValidateDuplicatedQuestion(
    faq.id,
    getIsDuplicatedQuestion,
    t,
    checkDuplicatedQuestionFromAll,
  );

  const handleAddQuestion = useHandleAddQuestions(addQuestion, questionsWrapperDOM.current, {
    timeoutId: timeoutId.current,
    setTimeoutId,
  });

  const handleRemoveQuestion = (id: string) => {
    removeQuestion(id);
  };

  const handleChangeTabLanguage = (_event: React.ChangeEvent<{}>, newIndex: number) => {
    setCurrentTabLanguage(newIndex);
  };

  const handleDeleteMessage = (index: number) => {
    removeResponse(index);
  };

  const handleClickAddMessage = (text: string) => {
    const newText = text.trim();

    if (newText) {
      addResponse(MessageType.TEXT, newText);
    }
  };

  const handleDragEnd = (result: DropResult) => {
    const responsesField = getResponseField();
    const responses = getResponses();
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newResponses = reorder<ResponseMessage>(responses, result.source.index, result.destination.index);
    setFAQ(responsesField, newResponses);
  };

  const handleEditMessage = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = event.target;
    updateResponse(value, id);
  };

  const handleUploadCallback = (url: string) => {
    addResponse(MessageType.IMAGE, { url });
  };

  const validateDuplicatedQuestionsWithBotFlow = async (newQuestions: string[]) => {
    // start search duplicated question in bot flow
    let sanityCheckResult;
    try {
      sanityCheckResult = await sanityCheck({ variables: { projectId, faqId: faq.id, questions: newQuestions } });
    } catch (error) {
      throw new Error(t(NEW_FAQ_QUESTION_ERROR));
    }

    const duplicateQuestionsFromSanity = get(sanityCheckResult, "data.validateFaq.duplicateQuestions") || [];

    if (duplicateQuestionsFromSanity.length) {
      const duplicatedQuestionsWithBotFlow = intersection(duplicateQuestionsFromSanity, newQuestions);
      const hasDuplicatedQuestionsWithBotFlow = Boolean(duplicatedQuestionsWithBotFlow.length);

      if (hasDuplicatedQuestionsWithBotFlow) {
        throw new Error(t(NEW_FAQ_QUESTION_DUPLICATED_ERROR_SANITY));
      }
    }
    // end search duplicated question in bot flow
  };

  const validateQuestion = async (newQuestion: string, newQuestionIndex: number, allQuestions: FaqQuestionType[]) => {
    const questionField = getQuestionField();
    // eslint-disable-next-line no-useless-catch
    try {
      validateDuplicatedQuestion(newQuestion, newQuestionIndex, allQuestions, questionField);
      await validateDuplicatedQuestionsWithBotFlow([newQuestion]);
    } catch (error) {
      throw error;
    }
  };

  const handleBlurQuestion = async (newQuestion: string, id: string) => {
    const trimmedQuestion = newQuestion.trim();
    const questions = getQuestions();
    const index = questions.findIndex((question) => question.id === id);

    if (trimmedQuestion) {
      const mappedQuestions = questions.map((question) => question.value);
      // eslint-disable-next-line no-useless-catch
      try {
        await validateQuestion(trimmedQuestion, index, mappedQuestions);
      } catch (error) {
        throw error;
      }
    }
  };

  const handleChangeQuestion = (newQuestion: string, id: string) => {
    updateQuestion(newQuestion, id);
  };

  const handleSubmitForm = handleSubmit(async (data) => {
    const questionField = getQuestionField();
    // Loop every questions to check duplicated
    const validateDuplicated = (questions: FaqQuestionType[]) => {
      questions.forEach((question, index) => {
        validateDuplicatedQuestion(question, index, questions, questionField);
      });
    };

    let result = {
      id: faq.id,
      questions: clearNanoidQuestions(data.questions),
      enQuestions: clearNanoidQuestions(data.enQuestions),
      responses: data.responses,
      enResponses: data.enResponses,
      isActive: faq.isActive,
      isEnabledNotification: faq.isEnabledNotification,
    };

    result = removeTypenameField(result) as FaqType;
    const { questions, enQuestions } = result;

    try {
      validateDuplicated(questions);
      validateDuplicated(enQuestions);

      await validateDuplicatedQuestionsWithBotFlow(questions);
      await validateDuplicatedQuestionsWithBotFlow(enQuestions);
    } catch (error) {
      setError("submit", { type: "manual", message: error.message });
      return;
    }

    try {
      validateDataFaq(result, t, isEnableEN);

      onSubmit(result);
    } catch (error) {
      setError("submit", { type: "manual", message: error.message });
    }
  });

  const responseMessages = useMemo(() => getResponses(), [getResponses]);

  const hasOverMaxLengthQuestionError = (fieldName: string) => {
    return Object.keys(errors).some((fieldNameError: string) => {
      const regExp = new RegExp(fieldName);

      return regExp.test(fieldNameError);
    });
  };

  const submitError = get(errors, "submit.message");

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmitForm}>
        <Grid container>
          {isEnableEN && (
            <Grid item xs={12}>
              <Tabs classes={tabsClasses} value={currentTabLanguage} onChange={handleChangeTabLanguage}>
                <Tab classes={tabClasses} label={t("Thai")} id="0" />
                <Tab classes={tabClasses} label={t("English")} id="1" />
              </Tabs>
            </Grid>
          )}

          <Grid container item xs={12} className={isEnableEN ? "p-4" : "pt-0 px-4 pb-4"}>
            <Grid item xs={12} lg={6} container className="pr-2">
              <GridQuestions item xs={12} className="mb-4">
                <Typography color="darkGray" variant="body3" className="pb-3">
                  {t("If client says something similar to")}:
                </Typography>
                <QuestionsWrapper ref={questionsWrapperDOM}>
                  <div className={currentTabLanguage === 0 ? "" : "d-none"}>
                    {questions.map((question: ChangedFaqQuestionType) => (
                      <QuestionInput
                        key={question.id}
                        id={question.id}
                        defaultValue={question.value}
                        isAddedQuestion={Boolean(!question.value)}
                        onBlur={handleBlurQuestion}
                        onChange={handleChangeQuestion}
                        onRemove={handleRemoveQuestion}
                        highlights={faq?.colorIntents?.[question.value]}
                      />
                    ))}
                  </div>

                  <div className={currentTabLanguage === 1 ? "" : "d-none"}>
                    {enQuestions.map((question: ChangedFaqQuestionType) => (
                      <QuestionInput
                        key={question.id}
                        id={question.id}
                        defaultValue={question.value}
                        isAddedQuestion={Boolean(!question.value)}
                        onBlur={handleBlurQuestion}
                        onChange={handleChangeQuestion}
                        onRemove={handleRemoveQuestion}
                      />
                    ))}
                  </div>
                </QuestionsWrapper>
                <AddQuestionButtonWrapper className="mt-3">
                  <Button color="secondary" onClick={handleAddQuestion}>
                    + {t("Add question")}
                  </Button>
                </AddQuestionButtonWrapper>
              </GridQuestions>
              <Hidden mdDown>
                {hasOverMaxLengthQuestionError("questions") && (
                  <ErrorText className="d-block">
                    {t(NEW_FAQ_QUESTION_HAS_SOME_MAX_LENGTH_ERROR, {
                      maxLength: FAQ_MAX_QUESTION_LENGTH,
                      language: t("Thai"),
                    })}
                  </ErrorText>
                )}
                {hasOverMaxLengthQuestionError("enQuestions") && (
                  <ErrorText className="d-block">
                    {t(NEW_FAQ_QUESTION_HAS_SOME_MAX_LENGTH_ERROR, {
                      maxLength: FAQ_MAX_QUESTION_LENGTH,
                      language: t("English"),
                    })}
                  </ErrorText>
                )}
                {submitError && <ErrorText className="d-block">{submitError}</ErrorText>}
              </Hidden>
            </Grid>

            <Grid item xs={12} lg={6}>
              <MessagesManagerWrapper>
                <MessagesManagerLabel currentNo={responseMessages.length - 1} limitNo={FAQ_MAX_ANSWER_LENGTH}>
                  {t("Answer")} :
                </MessagesManagerLabel>
                <MessagesManager
                  onClickAddMessage={handleClickAddMessage}
                  onDeleteMessage={handleDeleteMessage}
                  onDragEnd={handleDragEnd}
                  onEditMessage={handleEditMessage}
                  onUploadCallback={handleUploadCallback}
                  messages={responseMessages}
                  projectId={projectId}
                  maxMessagesLength={FAQ_MAX_ANSWER_LENGTH}
                  addNewMessages={addResponse}
                  enableQuickReply
                  updateQuickReplyMessage={updateQuickReplyMessage}
                />
              </MessagesManagerWrapper>
            </Grid>
            <Hidden lgUp>
              <div className="mt-3">
                {hasOverMaxLengthQuestionError("questions") && (
                  <ErrorText className="d-block">
                    {t(NEW_FAQ_QUESTION_HAS_SOME_MAX_LENGTH_ERROR, {
                      maxLength: FAQ_MAX_QUESTION_LENGTH,
                      language: t("Thai"),
                    })}
                  </ErrorText>
                )}
                {hasOverMaxLengthQuestionError("enQuestions") && (
                  <ErrorText className="d-block">
                    {t(NEW_FAQ_QUESTION_HAS_SOME_MAX_LENGTH_ERROR, {
                      maxLength: FAQ_MAX_QUESTION_LENGTH,
                      language: t("English"),
                    })}
                  </ErrorText>
                )}
                {submitError && <ErrorText className="d-block">{submitError}</ErrorText>}
              </div>
            </Hidden>
          </Grid>
        </Grid>
        <Box borderTop={{ md: "1px solid #CAD2E4" }} className="p-3">
          <FormButtons
            isLoading={isLoadingSubmit}
            onCancel={onCancel}
            cancelLabel={t("Cancel")}
            submitLabel={t("Save and train bot")}
            isDisabledSubmit={Boolean(Object.keys(errors).length)}
          />
        </Box>
      </form>
    </FormProvider>
  );
};

export default FaqForm;
