import { useQuery } from "@apollo/client";
import { ADDONS } from "graphql/addon/query";
import { CURRENT_USAGE, CURRENT_PACKAGES } from "graphql/plan/query";
import { ProjectIdType } from "types/Project";
import { PackageEntityType, PackageType, CurrentPackagesQueryType, CurrentUsageQueryType } from "types/PlanUsage";
import { AddonsQueryType, AddonType, AddonCountType, AddonEntityType } from "types/Addon";

// CURRENT ADDON + PACKAGE QUOTA
const useCurrentAddon = (projectId: string) => {
  const variables = {
    projectId,
  };

  const { data: currentPackageData, loading: isCurrentPackageLoading } = useQuery<
    CurrentPackagesQueryType,
    ProjectIdType
  >(CURRENT_PACKAGES, {
    variables,
  });

  const { data: currentUsageData, loading: isCurrentUsageLoading } = useQuery<CurrentUsageQueryType, ProjectIdType>(
    CURRENT_USAGE,
    {
      variables,
    },
  );

  const { data: addonData, loading: isAddonsLoading } = useQuery<AddonsQueryType, ProjectIdType>(ADDONS, {
    variables,
  });

  if (currentPackageData && addonData && currentUsageData) {
    const mainPackage = currentPackageData.currentPackages.find(
      ({ entityType }: PackageType) => entityType === PackageEntityType.BASIC || entityType === PackageEntityType.MAIN,
    ) as PackageType;

    const currentAddonsCount = addonData.addons.reduce((results: AddonCountType, addon: AddonType, index: number) => {
      const { entityType, amount } = addon;

      if (!index || !results[entityType]) {
        return {
          ...results,
          [entityType]: Number(amount),
        };
      }
      return {
        ...results,
        [entityType]: results[entityType] + Number(amount),
      };
    }, {});

    const currentMemberUsageCount = currentUsageData.currentUsage.mainPackage.members.length;
    const currentChannelUsageCount = currentUsageData.currentUsage.mainPackage.channels.length;

    const currentImageSearchPackage = currentUsageData.currentUsage.imageSearchPackage;
    const currentImageSearchAddon = currentImageSearchPackage
      ? addonData.addons.find((addon: AddonType) => currentImageSearchPackage.id === addon.id)
      : undefined;

    const currentBroadcastAddon = addonData.addons.find(
      (addon: AddonType) => addon.entityType === AddonEntityType.BROADCAST && addon.isSelected,
    );

    return {
      packageQuota: {
        MEMBER: mainPackage.memberQuota,
        CHANNEL: mainPackage.channelQuota,
      } as Record<string, number>,
      currentUsage: {
        MEMBER: currentMemberUsageCount,
        CHANNEL: currentChannelUsageCount,
      } as Record<string, number>,
      currentAddons: currentAddonsCount,
      addons: addonData.addons,
      currentImageSearchAddon,
      currentBroadcastAddon,
    };
  }

  return {
    packageQuota: null,
    currentUsage: null,
    currentAddons: null,
    addons: [],
    currentImageSearchAddon: undefined,
    currentBroadcastAddon: undefined,
    loading: isCurrentPackageLoading || isCurrentUsageLoading || isAddonsLoading,
  };
};

export default useCurrentAddon;
