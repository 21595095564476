import Typography from "components/Typography";
import COLORS from "constants/Colors";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Wrapper } from "./styled";

interface LockedAccountPropsType {
  title?: string;
  description?: string;
}

export const LockedAccount: FC<LockedAccountPropsType> = ({ title, description }) => {
  const { t } = useTranslation();

  return (
    <>
      <Wrapper>
        <img alt="locked-img" src="/assets/img/locked_account.svg" />
      </Wrapper>
      <Wrapper className="detail">
        <div>
          {title && (
            <Typography variant="title1" color={COLORS.Dark}>
              {t(title)}
            </Typography>
          )}
          {description && (
            <div className="pt-3">
              <p className="description">{t(description)}</p>
            </div>
          )}
        </div>
      </Wrapper>
    </>
  );
};

export default LockedAccount;
