import * as React from "react";

const SvgIcGoTo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M28.431 3.24299L28.6747 3.48598C28.9185 3.80997 28.9997 4.21495 28.9997 4.61993V12.7196V13.0436C28.8372 13.9345 28.0247 14.5015 27.131 14.3395C26.3185 14.1775 25.7497 13.4486 25.7497 12.6386V8.50777L14.6185 19.6043C13.9685 20.2523 12.9935 20.2523 12.3435 19.6043C11.6935 18.9563 11.6935 17.9844 12.3435 17.3364L23.4747 6.23987H19.2497H18.9247C18.1122 6.07787 17.4622 5.26791 17.6247 4.37694C17.7872 3.56698 18.5185 3 19.331 3H27.5372C27.8622 3 28.1872 3.081 28.431 3.24299ZM6.25 29.0003H25.75C27.5375 29.0003 29 27.5424 29 25.8414V18.5517C29 17.5798 28.1063 16.7698 27.1313 16.9318C26.3188 17.0128 25.75 17.8228 25.75 18.6327V25.0315C25.75 25.5175 25.3438 25.8414 24.9375 25.8414H7.0625C6.575 25.8414 6.25 25.4365 6.25 25.0315V7.13122C6.25 6.64524 6.65625 6.32125 7.0625 6.32125H13.4C14.375 6.32125 15.1875 5.43029 15.025 4.45833C14.9438 3.64836 14.1312 3.08139 13.3187 3.08139H6.25C4.4625 3.08139 3 4.53933 3 6.32125V25.6794C3 27.5424 4.4625 29.0003 6.25 29.0003Z"
    />
  </svg>
);

export default SvgIcGoTo;
