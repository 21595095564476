import { gql } from "@apollo/client";

export const NOTIFICATIONS = gql`
  query notifications($projectId: ID!, $offset: Int, $limit: Int, $filter: NotificationFilterInput) {
    notifications(projectId: $projectId, offset: $offset, limit: $limit, filter: $filter) {
      results {
        id
        userId
        type # NotificationType
        customer {
          # Customer
          id
          uuid
          projectId
          platform
          name
          pictureUrl
        }
        payload {
          # NotificationPayload
          title
          body
          action
        }
        createdAt
        readAt
      }
      total
    }
  }
`;

export const NOTIFICATION_CONFIGS = gql`
  query notificationConfigurations($projectId: ID!, $userId: ID!) {
    notificationConfigurations(projectId: $projectId, userId: $userId) {
      name
      type
      isActive
      isSelectable
      priority
    }
  }
`;

export const UNSEEN_NOTIFICATIONS = gql`
  query unseenNotification($projectId: ID!) {
    unseenNotification(projectId: $projectId)
    # return Int
  }
`;
