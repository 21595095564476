const category = {
  en: {
    Categories: "Product catalog",
    "Category Name": "Product catalog's name",
    Properties: "Properties",
    Variations: "Variations (ex. size, color)",
    VariationsTitle: "Variations",
    "Are you sure you want to remove this category?": "Are you sure you want to remove this product catalog?",
    "no option": "No options",
    "Please enter the Category name": "Please enter the product catalog's name",
    "category.error.limitLength": "Catalog's name cannot exceed {{limitLength}} characters",
    "Product catalog preview": "Product catalog preview",
    "category.button.addNewCategory": "Add new catalog",
    "category.save": "{{name}} is saved success",
    "category.toggle": "{{name}} is {{isActive}}",
    "categories.priority": "Catalogs 's priority are updated",
    "category.default.error.categoryNameCannotBeEmpty": "Catalog name cannot be empty",
    "category.default.error.duplicateCategoryName": "Catalog name cannot duplicated",
    "category.default.error.exceedMaximumCategoryLimit": "Catalog limit is exceeded",
    "category.default.error.unableToDeleteCategoryThatStillHasProduct":
      "Unable to delete category that still has product",
    "category.displayName": "Display name",
    "category.allCategory.description":
      "‘All product catalogs’ navigates your customers to the website view of your shop allowing them to browse all products more easily.",
    "category.allCategory.priorityError": "‘All products’ has to be in the order of first 10 items.",
  },
  th: {
    Categories: "เมนูสินค้า",
    "Category Name": "ชื่อเมนูสินค้า",
    Properties: "คุณสมบัติ",
    Variations: "ตัวเลือกของสินค้า (เช่น ขนาด, สี)",
    VariationsTitle: "ตัวเลือกของสินค้า",
    "Are you sure you want to remove this category?": "คุณต้องการที่จะลบเมนูสินค้านี้หรือไม่?",
    "no option": "ไม่มีตัวเลือก",
    "Please enter the Category name": "กรุณาใส่ชื่อเมนูสินค้า",
    "category.error.limitLength": "ชื่อเมนูสินค้ามีได้ไม่เกิน {{limitLength}} ตัวอักษร",
    "Product catalog preview": "ตัวอย่างเมนูสินค้า",
    "category.button.addNewCategory": "เพิ่มเมนูสินค้า",
    "category.save": "{{name}} บันทึกสำเร็จ",
    "category.toggle": "{{name}} ถูก {{isActive}}",
    "categories.priority": "เมนูสินค้าบันทึกสำเร็จ",
    "category.default.error.categoryNameCannotBeEmpty": "ชื่อเมนูสินค้าไม่สามารถเว้นว่างได้",
    "category.default.error.duplicateCategoryName": "ชื่อเมนูสินค้าไม่สามารถซ้ำได้",
    "category.default.error.exceedMaximumCategoryLimit": "จำนวนของเมนูสินค้าเกินขีดจำกัด",
    "category.default.error.unableToDeleteCategoryThatStillHasProduct": "ไม่สามารถลบเมนูสินค้าที่ยังมีสินค้าได้",
    "category.displayName": "ชื่อที่แสดง",
    "category.allCategory.description":
      "‘เมนูสินค้าทั้งหมด’ จะส่งลูกค้าไปยังหน้าเว็บไซต์ของร้าน เพื่อให้ลูกค้าได้ค้นหาสินค้าอย่างง่ายดายยิ่งขึ้น",
    "category.allCategory.priorityError": "‘สินค้าทั้งหมด’ต้องถูกจัดเรียงอยู่ใน 10 รายการแรกเท่านั้น",
  },
};

export default category;
