import { PRODUCT_PROMOTION_GROUP_TYPES, SHIPPING_PROMOTION_GROUP_TYPES } from "constants/Promotion";
import { PromotionType, PromotionCondition } from "types/Promotion";
import { ProductItemPropsType, ProductSKUType, ProductTypeType } from "types/Product";
import { ProductType as OrderProductSKUType } from "types/Order";

import get from "lodash/get";
import sumBy from "lodash/sumBy";

import moment from "moment";
import { ID } from "@1convo/shopping-cart-calculator";

const FREE_ABLE_PROMOTIONS = [
  PRODUCT_PROMOTION_GROUP_TYPES.BUY_X_GET_Y,
  PRODUCT_PROMOTION_GROUP_TYPES.FREE_GIVEAWAY,
  SHIPPING_PROMOTION_GROUP_TYPES.FREE_SHIPPING,
];

/**
 * Map promotion with promotion name for flex message
 * @param {Object} promotion
 * @returns {String}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getPromotionDisplayNameForFlexMessage(promotion: any) {
  if (!promotion) {
    return "";
  }

  const fullName = get(promotion, "fullName");
  // just display full name promotion for support enterprise promotion
  if (fullName) {
    return fullName;
  }

  const description = get(promotion, "description");
  if (description) {
    return description;
  }

  const promotionName = get(promotion, "name");
  if (promotionName) {
    return promotionName;
  }

  // Buy X get Y
  if (promotion.settings.type === PRODUCT_PROMOTION_GROUP_TYPES.BUY_X_GET_Y) {
    const totalAmountOfFreeProducts = sumBy(promotion.freeProducts, "amount");

    if (promotion.settings && promotion.settings.threshold && promotion.settings.threshold.amount) {
      return `ซื้อ ${promotion.settings.threshold.amount} ชิ้น แถม ${totalAmountOfFreeProducts} ชิ้น*`;
    }

    // eslint-disable-next-line no-irregular-whitespace
    return `ซื้อ 1 แถม​ ${totalAmountOfFreeProducts} ชิ้น*`;
  }

  // Fixed amount
  if (promotion.settings.type === PRODUCT_PROMOTION_GROUP_TYPES.FIXED_AMOUNT_DISCOUNT) {
    if (promotion.settings && promotion.settings.threshold && promotion.settings.threshold.amount) {
      return `ลด ${promotion.settings.discount.amount} บาท เมื่อซื้อ ${promotion.settings.threshold.amount} ชิ้น*`;
    }

    return `ลด ${promotion.settings.discount.amount} บาท เมื่อซื้อของทั้งหมดราคา ${promotion.settings.threshold.price} บาท*`;
  }

  // Fixed price
  if (promotion.settings.type === PRODUCT_PROMOTION_GROUP_TYPES.FIXED_PRICE_DISCOUNT) {
    return `ซื้อ ${promotion.settings.threshold.amount} ชิ้นในราคา ${promotion.settings.discount.fixed} บาท*`;
  }

  // Free giveaway
  if (promotion.settings.type === PRODUCT_PROMOTION_GROUP_TYPES.FREE_GIVEAWAY) {
    const totalAmountOfFreeProducts = sumBy(promotion.freeProducts, "amount");

    if (promotion.settings && promotion.settings.threshold && promotion.settings.threshold.price) {
      return `เมื่อซื้อของทั้งหมดราคา ${promotion.settings.threshold.price} บาท รับของแถม ${totalAmountOfFreeProducts} ชิ้น*`;
    }

    return `ฟรีของแถม ${totalAmountOfFreeProducts} ชิ้น*`;
  }

  // Free shipping
  if (promotion.settings.type === SHIPPING_PROMOTION_GROUP_TYPES.FREE_SHIPPING) {
    if (promotion.settings && promotion.settings.threshold && promotion.settings.threshold.price) {
      return `ส่งฟรี เมื่อซื้อของทั้งหมดราคา ${promotion.settings.threshold.price} บาท*`;
    }

    return "ส่งฟรี*";
  }

  // Percentage
  if (promotion.settings.type === SHIPPING_PROMOTION_GROUP_TYPES.PERCENTAGE_DISCOUNT) {
    return `ลด ${promotion.settings.discount.percent}% เมื่อซื้อ ${promotion.settings.threshold.amount} ชิ้น*`;
  }

  return "";
}

const compareCampaignCreateAt = (promotionA: PromotionType, promotionB: PromotionType) => {
  const campaignPromotionA = get(promotionA, "campaign");
  const campaignPromotionB = get(promotionB, "campaign");
  if (!campaignPromotionA || !campaignPromotionB || campaignPromotionA.id === campaignPromotionB.id) {
    return 0;
  }
  const campaignCreatedAtPromotionA = moment(get(campaignPromotionA, "createdAt"));
  const campaignCreatedAtPromotionB = moment(get(campaignPromotionB, "createdAt"));
  const campaignCreateAtPriority = campaignCreatedAtPromotionA < campaignCreatedAtPromotionB ? 1 : -1;

  return campaignCreateAtPriority;
};

const comparePromotionPriority = (promotionA: PromotionType, promotionB: PromotionType) => {
  const priorityPromotionA = get(promotionA, "priority");
  const priorityPromotionB = get(promotionB, "priority");
  const promotionPriority = priorityPromotionA < priorityPromotionB ? -1 : 1;

  return promotionPriority;
};
/**
 * sort promotions by priority
 *
 * campaign created date > promotion priority
 *
 * @param {object<Promotion>} promotionA
 * @param {object<Promotion>} promotionB
 */
export const sortPromotionByPriority = (promotionA: PromotionType, promotionB: PromotionType) => {
  // chain compare function with ||
  return compareCampaignCreateAt(promotionA, promotionB) || comparePromotionPriority(promotionA, promotionB);
};

/**
 * return first sorted promotions by sortPromotionByPriority
 * @param {object[]} productSKUs
 * @return {object} promotion
 */
export function getRelatedPromotionForProductHeaderBadge(promotions: PromotionType[]) {
  // sort latest updateAt first
  const sortedPromotions = promotions.sort(sortPromotionByPriority);

  if (!sortedPromotions.length) {
    return null;
  }

  return sortedPromotions[0];
}

/**
 * sort promotions by
 *
 * not in freeable promotions > updatedAt desc
 *
 * priority: 2  not in freeable promotions
 * priority: 1  updatedAt desc
 *
 * @param {object<Promotion>} promotionA
 * @param {object<Promotion>} promotionB
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortPromotionByTypeAndUpdatedAt = (promotionA: any, promotionB: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const nonFreeAblePromotionPriority: any =
    ((!FREE_ABLE_PROMOTIONS.includes(promotionB.settings.type) ? 1 : 0) -
      (!FREE_ABLE_PROMOTIONS.includes(promotionA.settings.type) ? 1 : 0)) *
    2;

  if (!get(promotionA, "settings.type") || !get(promotionB, "settings.type")) {
    return nonFreeAblePromotionPriority;
  }

  const updatedAtPromotionA = moment(promotionA.updatedAt);
  const updatedAtPromotionB = moment(promotionB.updatedAt);

  const updatedAtPriority = updatedAtPromotionA.isAfter(updatedAtPromotionB) ? -1 : 1;

  return nonFreeAblePromotionPriority || updatedAtPriority;
};

/**
 * group promotion by product SKU id
 * @param {[object]} promotions
 *
 * ex, promotions = [{id: 1, selectedProducts: [{id: 40}]}, {id: 2, selectedProducts: [{id: 43}]}]
 *     result = [
  {
    "40": [{ // product SKU id
      "id": 1, // promotion id
      "selectedProducts": [{
          "id": 40
        }]
    }]
  },
  {
    "43": [{
      "id": 2,
      "selectedProducts": [{
          "id": 43
        }]
    }]
  }
]
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupPromotionsByProductSKUId = (promotions: PromotionType[] = []): Record<ID, PromotionType[]> => {
  // TODO: Remove this function when all id is a string type
  const changePromotionIdType = (promotion: PromotionType): PromotionType => {
    return {
      ...promotion,
      id: `${promotion.id}`,
      campaignId: `${promotion.campaignId}`,
      selectedProducts: promotion.selectedProducts.map((selectedProduct) => ({
        ...selectedProduct,
        id: `${selectedProduct.id}`,
        productId: `${selectedProduct.productId}`,
        product: {
          ...selectedProduct.product,
          id: `${selectedProduct.product.id}`,
        },
      })),
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const groupedPromotions: Record<ID, PromotionType[]> = {};

  promotions.forEach((promotion: PromotionType) => {
    const selectedProducts = promotion.selectedProducts || [];

    selectedProducts.forEach((selectedProductSKU) => {
      const selectedProductSKUId = `${selectedProductSKU.id}`;

      if (!groupedPromotions[selectedProductSKUId]) {
        groupedPromotions[selectedProductSKUId] = [];
      }
      // don't add duplicated promotion that already added
      const findDuplicatedPromotion = groupedPromotions[selectedProductSKUId].find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (addedPromotion: PromotionType) => `${addedPromotion.id}` === `${promotion.id}`,
      );

      if (!findDuplicatedPromotion) {
        // TODO: Remove this function when all id is a string type
        const changedPromotion = changePromotionIdType(promotion);
        groupedPromotions[selectedProductSKUId].push(changedPromotion);
        groupedPromotions[selectedProductSKUId].sort(sortPromotionByTypeAndUpdatedAt);
      }
    });
  });

  return groupedPromotions;
};

export const formatToReadablePrice = (price = 0) =>
  Number(price).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

/**
 * Generate product price range
 * @param {Number} minPrice - min price of product
 * @param {Number?} priceAfterDiscount - price after discount
 * @param {Object?} options - options
 * @param {Number?} options - options
 * @returns {Object} - return LINE flex object
 */
export const getProductPriceLineFlex = (
  minPrice: number,
  maxPrice = minPrice,
  priceAfterDiscount = minPrice,
  priceForSubSKU = false,
) => {
  if (typeof minPrice !== "number" || typeof maxPrice !== "number") {
    return null;
  }

  const readableMinPrice = formatToReadablePrice(minPrice);
  const readableMaxPrice = formatToReadablePrice(maxPrice);
  // we need to separate number and decimal from string because we will have number size bigger than decimal
  const [numberMinPrice, decimalMinPrice] = readableMinPrice.split(".");
  const [numberMaxPrice, decimalMaxPrice] = readableMaxPrice.split(".");

  let price = ``;
  if (priceForSubSKU) {
    price = `฿${numberMinPrice}.${decimalMinPrice}`; // original price
  }

  if (minPrice > priceAfterDiscount) {
    if (priceAfterDiscount > 0) {
      price += `฿${formatToReadablePrice(priceAfterDiscount)}`;
    }
  }
  // display as "฿123.00 - ฿150.00"
  else if (minPrice !== maxPrice) {
    price += `฿${numberMaxPrice}.${decimalMaxPrice}`;
  }

  return price;
};

export const getPromotionTotalDiscountForProductSKU = (
  productSKU: ProductSKUType | OrderProductSKUType,
  promotions: PromotionType[],
) => {
  // free product
  if (productSKU.isFree) {
    return 0;
  }

  const totalDiscountForProductSKU = promotions.reduce((prev, currentPromotion) => {
    const { selectedProducts } = currentPromotion;

    const selectedProduct = selectedProducts.find(
      (product) => product.id === productSKU.id && product.amount === productSKU.amount,
    );

    // free product don't have promotion
    if (!selectedProduct) {
      return prev;
    }

    const relatePromotions = selectedProduct.promotions || [];
    const discount = relatePromotions.reduce((previous, relatePromotion) => {
      return previous + Number(get(relatePromotion, "totalDiscount") || 0);
    }, 0);

    return prev + discount;
  }, 0);

  return totalDiscountForProductSKU;
};

/**
 * get related promotion with the productSKUs (BUY_ANY_PRODUCT will alway included)
 * @param {ReadonlyArray<string>} productSKUIds
 * @param {ReadonlyArray<PromotionType>} promotions
 * @return {ReadonlyArray<PromotionType>} promotion
 */
export function getRelatedPromotionByProductSKUIds(productSKUIds: string[], promotions: PromotionType[]) {
  return promotions.filter((promotion) => {
    const {
      settings: { condition },
    } = promotion;

    /**
     * These promotion condition will always be include in the system
     * - BUY_ANY_PRODUCT
     * - BUY_X_PIECE_OF_ANY_PRODUCT
     * - BUY_X_PRICE_IN_TOTAL_OF_ANY_PRODUCT
     * or if promotion has is included all products
     */
    if (
      [
        PromotionCondition.BUY_ANY_PRODUCT,
        PromotionCondition.BUY_X_PIECE_OF_ANY_PRODUCT,
        PromotionCondition.BUY_X_PRICE_IN_TOTAL_OF_ANY_PRODUCT,
      ].includes(condition) ||
      promotion.isIncludedAllProducts
    ) {
      return true;
    }

    /**
     * If selected product list is empty meaning that this promotion
     * does not have any selected product that will fit its criteria
     * or it could be because list showing here does not have one that
     * meet its criteria (filtered by server already)
     */
    const selectedProducts = promotion.selectedProducts || [];
    if (!selectedProducts.length) {
      return false;
    }

    return selectedProducts.find((selectedProduct) =>
      productSKUIds.find((productSKUId: string) => selectedProduct?.id.toString() === productSKUId.toString()),
    );
  });
}

/**
 * get related promotiom with the products
 * @param {object[]} products
 * @param {object[]} promotions
 * @return {object[]} promotion
 */
export function getRelatedPromotionByProducts(products: ProductItemPropsType[], promotions: PromotionType[]) {
  const productSKUIds = products.flatMap((product: ProductItemPropsType) => {
    const productSKUs = product.productSKUs || [];

    return productSKUs.map((productSKU) => productSKU.id);
  });

  return getRelatedPromotionByProductSKUIds(productSKUIds, promotions);
}

export const getProductTypeList = (productType = []) => {
  return productType.map((productTypeValue: ProductTypeType) => productTypeValue.value).join(", ");
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const normalizeProductTypeFromSKU = (productSKUs: any) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  productSKUs.reduce((obj: any, sku: any) => {
    const result = { ...obj };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sku.productType.forEach((skuValue: any) => {
      const value = skuValue.value.toLowerCase();
      const type = skuValue.key.key.toLowerCase();
      if (!result[type]) result[type] = [value];
      else if (result[type] && !result[type].includes(value)) result[type].push(value);
    });

    return result;
  }, {});
