import styled from "styled-components";

type DividerPropsType = {
  line?: "solid" | "dashed";
  thickness?: string;
  margin?: string;
};

const Divider = styled.hr<DividerPropsType>`
  border-style: ${({ line }) => line || "solid"};
  border-color: ${({ theme }) => theme.COLORS.LightBlue};
  border-width: ${({ thickness }) => `${thickness || "1px"} 0 0 0`};
  margin: ${({ margin }) => `${margin || "30px 0 24px 0"}`};
`;

export default Divider;
