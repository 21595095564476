import { Gender } from "types/AISetting";
import { MessageType } from "types/Chat";

export const MAP_BOT_GENDER = {
  [Gender.FEMALE]: "femaleMessages",
  [Gender.MALE]: "maleMessages",
};
export const MAP_LANGUAGE_DISPLAY_NAME: Record<string, string> = {
  th: "thDisplayName",
  en: "enDisplayName",
};
export const MAX_MESSAGES_LENGTH = 5;
export const DEFAULT_TITLE = "RESPONSE_MESSAGE";
export const EDITABLE_MESSAGE_TYPES = [
  MessageType.TEXT,
  MessageType.IMAGE,
  MessageType.CATEGORY_CAROUSEL,
  MessageType.PRODUCT_CAROUSEL,
  MessageType.QUICK_REPLIES,
];
