const promotionForm = {
  en: {
    "%": "%",
    A_DISCOUNT: "a discount",
    A_SHIPPING_DISCOUNT: "a shipping discount",
    A_SPECIAL_PRICE: "a special price",
    ADD_MORE: "Add more",
    ADD_PROMOTION: "Add promotion",
    ANY_PRODUCTS: "any products",
    AT_LEAST: "at least",
    AT_SPECIFIC_AMOUNT: "at a specific amount",
    AT_SPECIFIC_PRICE: "at a specific price",
    BETWEEN: "between",
    BROWSE: "Browse",
    CONDITION_DETAIL: "Condition detail",
    CUSTOM: "Custom",
    CUSTOMERS_BUY: "customers buy",
    DISCOUNT: "discount",
    EDIT_PROMOTION: "Edit promotion",
    EQUAL: "equal to",
    FOR_EVERY_PURCHASE: "for every purchase",
    FREE_CHEAPEST_PRODUCT: "a free cheapest product",
    FREE_PRODUCT: "free product(s)",
    FREE_SHIPPING_PRICE: "a free shipping price",
    IN_PERCENT: "in percent",
    IN_THB: "in THB",
    OF: "of",
    PIECES: "pieces",
    PROMOTION_DETAIL: "Promotion detail",
    PROMOTION_RULE: "Promotion rule",
    SELECTED_PRODUCT: "selected product",
    SELECTED_PRODUCTS: "Selected products",
    SHIPPING_DISCOUNT: "shipping discount",
    SHIPPING_METHOD: "Shipping method",
    SPECIAL_DISCOUNT: "customer will buy products with special price",
    SPECIFY_PRODUCTS: "specific products",
    THB_UNIT: "THB",
    THEN: "Then",
    THEY_WILL_GET_A: "they will get a",
    THEY_WILL_GET_CHEAPEST_PRODUCT: "they will get 1 the cheapest product for free",
    THEY_WILL_GET_FREE_SHIPPING: "they will get free shipping.",
    THEY_WILL_GET: "they will get",
    TO: "to",
    WHEN_CUSTOMERS_BUY_ANY_PRODUCTS_FOR_EVERY_PURCHASE: "When customers buy any products for every purchase",
    WHEN_CUSTOMERS_BUY: "When customers buy",
    WHEN: "When",
    YOUR_CONDITION_IS_IN_INCORRECT_FORMAT: "Some conditions is overlap or invalid",
    ERROR_MIN_DISCOUNT: "Discount should be more than 0",
    ERROR_MIN_THRESHOLD: "Threshold should be more than 0",
    ERROR_MAX: "Text length has a reach limit",
    SHIPPING_IS_REQUIRED: "Shipping is required",
    NAME_IS_REQUIRED: "Name is required",
    SHORT_NAME_IS_REQUIRED: "Shortname is required",
    CUSTOM_PROMOTION_IS_NOT_SUPPORT: "Custom promotion is not support now",
    PLEASE_SELECT_FREE_PRODUCT: "Please select free product",
    SELECTED_PRODUCT_IS_REQUIRED: "Selected product is required",
    PROMOTION_BUNDLED: "Promotion bundle",
    PRODUCT_A_FOR_THRESHOLD: "{{product}} for {{threshold}}",
    PRODUCT_A_AND_B_WILL_GET_C: "When buying {{productA}} and {{productB}} will get {{reward}}",
    PRODUCT_A_AND_B_WILL_NOT_GET_C: "When buying {{productA}} and {{productB}} will not get {{reward}}",
    BUY_PRODUCT_A_FOR_B_WILL_GET_C: "When buying {{product}} for {{threshold}} will get {{reward}}",
    BUY_PRODUCT_A_WILL_GET_C: "When buying {{product}} will get {{reward}}",
    BUY_PRODUCT_A_FOR_B_NOT_WILL_GET_C: "When buying {{product}} for {{threshold}} will not get {{reward}}",
    PRODUCT_A: "product A",
    PRODUCT_B: "product B",
    TOTAL_OF_THB: "a total of {{price}} THB",
    DISCOUNT_SHIPPING_PRICE: "a discount shipping price",
    EXAMPLE: "Example",
  },
  th: {
    "%": "%",
    A_DISCOUNT: "ส่วนลด",
    A_SHIPPING_DISCOUNT: "ส่วนลดค่าจัดส่ง",
    A_SPECIAL_PRICE: "ราคาพิเศษ",
    ADD_MORE: "เพิ่ม",
    ADD_PROMOTION: "เพิ่มโปรโมชั่น",
    ANY_PRODUCTS: "สินค้าชิ้นใดก็ได้",
    AT_LEAST: "อย่างน้อย",
    AT_SPECIFIC_AMOUNT: "ตามจำนวนที่กำหนด",
    AT_SPECIFIC_PRICE: "ตามราคาที่กำหนด",
    BETWEEN: "ระหว่าง",
    BROWSE: "ค้นหา",
    CONDITION_DETAIL: "รายละเอียดเงื่อนไข",
    CUSTOM: "กำหนดเอง",
    CUSTOMERS_BUY: "ลูกค้าซื้อ",
    DISCOUNT: "ส่วนลด",
    EDIT_PROMOTION: "แก้ไขโปรโมชั่น",
    EQUAL: "เท่ากับ",
    FOR_EVERY_PURCHASE: "ในทุกการสั่งซื้อ",
    FREE_CHEAPEST_PRODUCT: "สินค้าที่ถูกที่สุดฟรี",
    FREE_PRODUCT: "ของแถม",
    FREE_SHIPPING_PRICE: "ค่าจัดส่งฟรี",
    IN_PERCENT: "ราคา (เปอร์เซ็นต์)",
    IN_THB: "ราคา (บาท)",
    OF: "",
    PIECES: "ชิ้น",
    PROMOTION_DETAIL: "รายละเอียดโปรโมชั่น",
    PROMOTION_RULE: "ประเภทของโปรโมชั่น",
    SELECTED_PRODUCT: "สินค้าที่เข้าร่วมโปรโมชั่น",
    SELECTED_PRODUCTS: "สินค้าที่เข้าร่วมโปรโมชั่น",
    SHIPPING_DISCOUNT: "ส่วนลดการจัดส่ง",
    SHIPPING_METHOD: "วิธีการจัดส่ง",
    SPECIAL_DISCOUNT: "ลูกค้าจะซื้อสินค้าได้ในราคา",
    SPECIFY_PRODUCTS: "สินค้าที่ร่วมโปรโมชั่น",
    THB_UNIT: "บาท",
    THEN: "แล้ว",
    THEY_WILL_GET_A: "ลูกค้าจะได้รับ",
    THEY_WILL_GET_CHEAPEST_PRODUCT: "ลูกค้าจะได้รับสินค้าที่ราคาถูกที่สุดฟรี 1 ชิ้น",
    THEY_WILL_GET_FREE_SHIPPING: "ลูกค้าจะได้รับการจัดส่งฟรี",
    THEY_WILL_GET: "ลูกค้าจะได้รับ",
    TO: "ถึง",
    WHEN_CUSTOMERS_BUY_ANY_PRODUCTS_FOR_EVERY_PURCHASE: "เมื่อลูกค้าซื้อสินค้า ทุกๆการสั่งซื้อ",
    WHEN_CUSTOMERS_BUY: "เมื่อลูกค้าซื้อ",
    WHEN: "เมื่อ",
    YOUR_CONDITION_IS_IN_INCORRECT_FORMAT: "เงื่อนไขโปรโมชั่นซ้อนทับกันหรือใส่ค่าผิด",
    ERROR_MIN_DISCOUNT: "ส่วนลดควรมากกว่า 0",
    ERROR_MIN_THRESHOLD: "เกณฑ์ขั้นต่ำควรมากกว่า 0",
    ERROR_MAX: "จำนวนตัวอักษรที่ใช้เกินที่กำหนด",
    SHIPPING_IS_REQUIRED: "กรุณาเลือกวิธีการจัดส่ง",
    NAME_IS_REQUIRED: "กรุณากรอกชื่อโปรโมชั่น",
    SHORT_NAME_IS_REQUIRED: "กรุณากรอกชื่อย่อโปรโมชั่น",
    CUSTOM_PROMOTION_IS_NOT_SUPPORT: "ตอนนี้ระบบยังไม่รองรับโปรโมชั่นแบบกำหนดเอง",
    PLEASE_SELECT_FREE_PRODUCT: "กรุณาเลือกสินค้าฟรี",
    SELECTED_PRODUCT_IS_REQUIRED: "กรุณาเลือกสินค้าที่ร่วมรายการ",
    PROMOTION_BUNDLED: " โปรโมชั่นมัดรวมสินค้าที่ร่วมรายการ",
    PRODUCT_A_FOR_THRESHOLD: "{{product}} จำนวน {{threshold}}",
    PRODUCT_A_AND_B_WILL_GET_C: "ซื้อ{{productA}}และ{{productB}}จะได้รับ{{reward}}",
    PRODUCT_A_AND_B_WILL_NOT_GET_C: "ซื้อ{{productA}}และ{{productB}}จะไม่ได้รับ{{reward}}",
    BUY_PRODUCT_A_FOR_B_WILL_GET_C: "ซื้อ{{product}} จำนวน {{threshold}}จะได้รับ{{reward}}",
    BUY_PRODUCT_A_FOR_B_NOT_WILL_GET_C: "ซื้อ{{product}} จำนวน {{threshold}}จะไม่ได้รับ{{reward}}",
    BUY_PRODUCT_A_WILL_GET_C: "ซื้อ{{product}}จะได้รับ{{reward}}",
    PRODUCT_A: "เสื้อยืด A",
    PRODUCT_B: "กางเกง B",
    TOTAL_OF_THB: "ครบ {{price}} บาท",
    DISCOUNT_SHIPPING_PRICE: "ส่วนลดค่าจัดส่ง",
    EXAMPLE: "ตัวอย่าง",
  },
};

export default promotionForm;
