import TableSortLabel from "@material-ui/core/TableSortLabel";
import React, { Fragment, useMemo, memo, ReactNode } from "react";
import { useTable, TableOptions, PluginHook, Column, SortingRule, TableSortByToggleProps } from "react-table";
import get from "lodash/get";
import { IcLongArrowDown } from "components/SvgIcons";
import SubTable from "./SubTable";
import { TableRowWrapper } from "./styled";

interface TablePropsType<TABLE extends object = {}, SUB_TABLE extends object = {}> extends TableOptions<TABLE> {
  hooks?: PluginHook<TABLE>[];
  subTableColumns?: Column<SUB_TABLE>[];
  onChangeSortBy?: (sortBy: SortingRule<TABLE>[]) => void;
  customState?: object;
  isBlurContent?: boolean;
  customCellProps?: object;
  customHeaderProps?: object;
  renderFooter?: () => ReactNode;
  customSubTable?: (row: any) => ReactNode;
}

const Table = <TABLE extends object, SUB_TABLE extends object>(props: TablePropsType<TABLE, SUB_TABLE>) => {
  const {
    columns,
    data,
    manualSortBy,
    hooks = [],
    subTableColumns = [],
    onChangeSortBy = () => {},
    customState = {},
    isBlurContent,
    customCellProps = {},
    customHeaderProps = {},
    customSubTable,
    renderFooter,
  } = props;
  const { getTableProps, headerGroups, footerGroups, rows, prepareRow, state } = useTable<TABLE>(
    {
      columns,
      data,
      manualSortBy,
      autoResetPage: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
      useControlledState: (tableState) => {
        return useMemo(
          () => ({
            ...tableState,
            ...customState,
          }),
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [tableState, customState],
        );
      },
    },
    ...hooks,
  );

  const { sortBy } = state;

  const tableProps = getTableProps();
  const hasFooter = useMemo(() => columns.some((column) => Boolean(column.Footer)), [columns]);

  return (
    <div className="table sticky" {...tableProps}>
      <div className="header">
        {headerGroups.map((headerGroup) => {
          const tableHeaderProps = headerGroup.getHeaderGroupProps();

          return (
            // eslint-disable-next-line react/jsx-key
            <div className="tr" {...tableHeaderProps}>
              {headerGroup.headers.map((column) => {
                let sortByToggleProps: Partial<TableSortByToggleProps> = {};
                if (typeof column.getSortByToggleProps === "function") {
                  sortByToggleProps = column.getSortByToggleProps();
                }

                return (
                  // eslint-disable-next-line react/jsx-key
                  <div className="th d-flex align-items-center" {...column.getHeaderProps(sortByToggleProps)}>
                    {column.canSort ? (
                      <TableSortLabel
                        IconComponent={IcLongArrowDown}
                        active={Boolean(column.isSorted)}
                        direction={column.isSortedDesc ? "desc" : "asc"}
                        {...sortByToggleProps}
                        onClick={(event) => {
                          if (manualSortBy) {
                            onChangeSortBy(sortBy);
                          }

                          (sortByToggleProps as Partial<{ onClick: Function }>)?.onClick?.(event);
                        }}
                      >
                        {column.render("Header", customHeaderProps)}
                      </TableSortLabel>
                    ) : (
                      <>{column.render("Header", customHeaderProps)}</>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <TableRowWrapper className="body" isBlurContent={isBlurContent}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <Fragment key={row.getRowProps().key}>
              <div className={`tr ${row.isExpanded ? "expand-row" : ""}`} {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const cellProps = cell.getCellProps();

                  return (
                    // eslint-disable-next-line react/jsx-key
                    <div className="td d-flex align-items-center" {...cellProps}>
                      {cell.render("Cell", customCellProps)}
                    </div>
                  );
                })}
              </div>
              {row.isExpanded && !!subTableColumns.length && (
                <SubTable<SUB_TABLE> columns={subTableColumns} data={get(row, "original.subTable") || []} />
              )}
              {row.isExpanded && customSubTable && customSubTable(get(row, "original"))}
            </Fragment>
          );
        })}
      </TableRowWrapper>

      {(hasFooter || Boolean(renderFooter)) && (
        <TableRowWrapper className="footer" isBlurContent={isBlurContent}>
          {renderFooter
            ? renderFooter()
            : footerGroups.map((footerGroup) => {
                const tableHeaderProps = footerGroup.getFooterGroupProps();

                return (
                  // eslint-disable-next-line react/jsx-key
                  <div // key={tableHeaderProps.key}
                    className="tr"
                    {...tableHeaderProps}
                  >
                    {footerGroup.headers.map((column, _index) => {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <div
                          className="th d-flex align-items-center" // key={index}
                          {...column.getFooterProps()}
                        >
                          {column.render("Footer")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
        </TableRowWrapper>
      )}
    </div>
  );
};

export default memo(Table) as typeof Table;
