import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import Typography from "components/Typography";
import CollapseButton from "components/CollapseButton";
import { convertPriceFormat } from "utils/common";

import { CollapseButtonWrapper } from "../styled";

type DigitalContentSKUProps = {
  id: string;
  productCode: string;
  variations: string;
  price: number;
  amount: number;
  isInfoExpanded?: boolean;
  onClickExpand?: () => void;
  isMobile?: boolean;
};

const DigitalContentSKU: FC<DigitalContentSKUProps> = (props) => {
  const { t } = useTranslation();
  const { productCode, variations, price, amount, isInfoExpanded = false, onClickExpand, isMobile = false } = props;

  return (
    <Grid container className="d-flex flex-nowrap">
      <Grid item className="d-flex flex-grow-1">
        {!isMobile && (
          <Grid item xs={3}>
            <Typography variant="title8" color="darkLight">
              {t("SKU Code")}
            </Typography>
            <Typography variant="body3" className="pt-8 mt-1">
              {productCode}
            </Typography>
          </Grid>
        )}
        <Grid item container xs={4} sm={3}>
          <Grid item container xs={12}>
            <Grid item>
              <Typography variant="title8" color="darkLight">
                {t("SKU(s)")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body3" className="pt-8 mt-1">
              {variations}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4} sm={3}>
          <Typography variant="title8" color="darkLight">
            {t("Price")}
          </Typography>
          <Typography variant="body3" className="pt-8 mt-1">
            {t("THB")} {convertPriceFormat(price, 0)}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={3}>
          <Typography variant="title8" color="darkLight">
            {t("QTY")}
          </Typography>
          <Typography variant="body3" className="pt-8 mt-1">
            {amount}
          </Typography>
        </Grid>
      </Grid>
      <CollapseButtonWrapper item className={`${amount ? "" : "invisible"}`}>
        <CollapseButton degree={-90} isShow={isInfoExpanded} onClick={onClickExpand} />
      </CollapseButtonWrapper>
    </Grid>
  );
};

export default DigitalContentSKU;
