import { yupResolver } from "@hookform/resolvers/yup";
import React, { FC, useCallback, ChangeEvent } from "react";
import { useParams, Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import Card from "components/Card";
import CircularProgress from "components/CircularProgress";
import ControllerSwitch from "components/Switch/ControllerSwitch";
import ErrorText from "components/ErrorText";
import Grid from "components/Grid";
import TextField from "components/TextField";
import TimePicker from "components/TimePicker";
import Typography from "components/Typography";
import { NumberFormatCustomNoDecimal } from "components/NumberFormatCustom";

import { PROJECT, STORE_SETTINGS, OFFLINE_STORE } from "constants/Router";
import { ProjectIdType } from "types/Project";
import { ProjectSettingType, ScheduleType } from "types/Setting";

import { useSaveGeneralSetting } from "./hook";
import { formatScheduleWithFormatDate } from "./helper";
import schema from "./schema";

type DeliveryPeriodCardProps = {
  projectData: ProjectSettingType;
  hasLalamoveShipping: boolean;
};

export const DeliveryPeriodCard: FC<DeliveryPeriodCardProps> = (props) => {
  const { projectData, hasLalamoveShipping } = props;
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const scheduleWithFormatDate = formatScheduleWithFormatDate(projectData);
  const { handleSubmit, formState, errors, control, watch, reset } = useForm<ScheduleType>({
    resolver: yupResolver(schema),
  });

  const { saveGeneralSetting, isLoadingSaveGeneralSetting } = useSaveGeneralSetting(projectId, projectData, () =>
    reset(),
  );

  const watchIsActive = watch("isActive", scheduleWithFormatDate.isActive);

  const renderNumberField = useCallback(({ onChange, ref, ...rest }) => {
    return (
      <TextField
        {...rest}
        inputRef={ref}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const number = +parseFloat(event.target.value).toFixed(2) || 0.0;
          onChange(number);
        }}
        InputProps={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          inputComponent: NumberFormatCustomNoDecimal as any,
        }}
        variant="outlined"
      />
    );
  }, []);

  const renderTimePicker = useCallback(({ onChange, ...rest }) => {
    return (
      <TimePicker
        {...rest}
        onChange={(time) => {
          onChange(time);
        }}
      />
    );
  }, []);

  return (
    <Grid container className="py-4">
      <Grid item xs={12} className="px-4 pb-4">
        <Typography variant="title2">{t("shipping.deliveryPeriod.title")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Card borderRadius={0}>
          <form name="deliveryPeriod" onSubmit={handleSubmit(saveGeneralSetting)}>
            <Grid container className="py-3 px-2">
              <Grid item container justify="flex-start" alignContent="center" alignItems="center" className="py-2">
                <ControllerSwitch
                  control={control}
                  name="isActive"
                  defaultValue={Boolean(scheduleWithFormatDate?.isActive)}
                />
                <Typography variant="body2" className="px-4">
                  {watchIsActive ? t("Active") : t("Disabled")}
                </Typography>
              </Grid>

              <Grid container justify="flex-start" alignContent="center" alignItems="center" className="py-2 mt-1">
                <Typography variant="title7" className="pr-2">
                  {t("shipping.deliveryPeriod.preparingTime")}
                </Typography>
                <Controller
                  render={renderNumberField}
                  control={control}
                  name="timeInAdvance"
                  defaultValue={Number(scheduleWithFormatDate?.timeInAdvance || 0)}
                />
              </Grid>
              {errors?.timeInAdvance?.message && (
                <Grid item xs={12}>
                  <ErrorText className="mt-2">{t(errors?.timeInAdvance?.message)}</ErrorText>
                </Grid>
              )}

              <Grid container justify="space-between" className="py-2 mt-2" alignContent="center" alignItems="center">
                <Grid item container justify="flex-start" alignContent="center" alignItems="center">
                  <Typography variant="title7" className="pr-4 py-2">
                    {t("shipping.deliveryPeriod.deliveryFrom")}
                  </Typography>
                  <Controller
                    render={renderTimePicker}
                    control={control}
                    name="start"
                    defaultValue={scheduleWithFormatDate?.start}
                  />
                  <Typography variant="title7" className="px-4 py-2">
                    {t("to")}
                  </Typography>
                  <Controller
                    render={renderTimePicker}
                    control={control}
                    name="end"
                    defaultValue={scheduleWithFormatDate?.end}
                  />
                </Grid>
              </Grid>
              {hasLalamoveShipping && (
                <Grid container className="py-2 mt-2" alignContent="center" alignItems="center">
                  <Grid item>
                    <Link to={`/${PROJECT}/${projectId}/${STORE_SETTINGS}/${OFFLINE_STORE}`}>
                      <Button fullWidth color="secondary" size="medium">
                        {t("shipping.deliveryPeriod.button.goToBranch")}
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Typography variant="body3" className="pl-4">
                      {t("shipping.deliveryPeriod.goToBranchDescription")}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              <Grid item className="mt-4" xs={12}>
                <Button fullWidth type="submit" disabled={Boolean(!formState.isDirty) || isLoadingSaveGeneralSetting}>
                  {t("Save")} {isLoadingSaveGeneralSetting && <CircularProgress size={20} className="ml-2" />}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};
