import * as Sentry from "@sentry/browser";
import { SENTRY_DSN, SENTRY_ENV } from "config";

function sentryService() {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
  });
}

function init() {
  return sentryService();
}

export { init, sentryService };
