import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import printJS from "print-js";
import { PRINT_FILE_TYPE } from "config/shippop";
import { SHIPPOP_INVOICE_SIZE } from "types/Shipping";
import { CREATE_SHIPPOP_CONSIGNMENT_NOTE } from "graphql/shipping/mutation";
import { notifyError } from "utils/notify";

const useCreateShippopConsignmentNote = (projectId: string, invoiceSize: SHIPPOP_INVOICE_SIZE, t: Function) => {
  const [createShippopConsignmentNote, { loading }] = useMutation(CREATE_SHIPPOP_CONSIGNMENT_NOTE);

  const printInvoice = useCallback(
    (orderIds: string[]) => {
      return createShippopConsignmentNote({
        variables: {
          projectId,
          size: invoiceSize,
          fileType: PRINT_FILE_TYPE,
          orderIds,
        },
      })
        .then((res) => {
          const base64 = res?.data?.createShippopConsignmentNote;

          if (base64) {
            printJS({ printable: base64, type: "pdf", base64: true });
          }
        })
        .catch(() => {
          notifyError(t("checkout.error.default"));
        });
    },
    [createShippopConsignmentNote, invoiceSize, projectId, t],
  );

  return {
    isLoading: loading,
    printInvoice,
  };
};

export default useCreateShippopConsignmentNote;
