import { convertPriceFormat } from "utils/common";
import { COD_DEPOSIT_TYPES } from "constants/Payment";

const PAYMENT_BANK_NAME_REGEX = /^([\w\s]*)(\(.*\))?\s?(:)?\s?(\d*)?/i;
const TRANSFER_METHOD_REGEX = /\(([^)]+)\)/i;

type PaymentInformation = {
  accountNumber: string;
  bankName: string;
  paymentBankName: string;
  transferMethod?: string;
};

export const formatPaymentGatewaySecretValue = (text: string | undefined) => {
  if (text) {
    const star25Char = "*************************";
    const isMaskText = text.match(/^([*]+)/);

    return isMaskText ? text.replace(/^([*]*)(.*)$/, `${star25Char}$2`) : text;
  }

  return "";
};

export const calculateDepositAmount = (
  depositType: COD_DEPOSIT_TYPES,
  depositAmount: number,
  totalPrice: number,
): number => {
  switch (depositType) {
    case COD_DEPOSIT_TYPES.FIXED_PRICE:
      return depositAmount;

    case COD_DEPOSIT_TYPES.PERCENTAGE:
      return totalPrice * (depositAmount / 100);

    default:
      return depositAmount;
  }
};

export const getCalculationDetail = (
  depositType: COD_DEPOSIT_TYPES,
  depositAmount: number,
  totalPrice: number,
): string => {
  switch (depositType) {
    case COD_DEPOSIT_TYPES.PERCENTAGE:
      return `${convertPriceFormat(totalPrice)} x ${convertPriceFormat(depositAmount)}% =`;

    default:
      return "";
  }
};

export const getPaymentInformationByPaymentBankName = (paymentBankName?: string): PaymentInformation => {
  if (!paymentBankName) {
    return {
      accountNumber: "",
      bankName: "",
      paymentBankName: "",
    };
  }

  const matchedRegexPaymentBankName = paymentBankName.match(PAYMENT_BANK_NAME_REGEX) || [];

  const [, extractedBankName, extractedTransferMethod, , accountNumber = "-"] = matchedRegexPaymentBankName;
  const bankName = (extractedBankName || "").trim();
  if (!extractedTransferMethod) {
    return {
      accountNumber,
      bankName,
      paymentBankName,
    };
  }

  const matchedRegexTransferMethod = extractedTransferMethod.match(TRANSFER_METHOD_REGEX) || [];
  const [, transferMethod = ""] = matchedRegexTransferMethod;

  return {
    accountNumber,
    bankName,
    paymentBankName,
    transferMethod,
  };
};
