import { createGlobalStyle } from "styled-components";
import { ThemeType } from "components/Theme/type";

const GlobalStyledComponent = createGlobalStyle`
mark.highlight-plasma {
  background-color: ${({ theme }: { theme: ThemeType }) => theme.COLORS.IntentPlasma};
}

mark.highlight-gas {
  background-color: ${({ theme }: { theme: ThemeType }) => theme.COLORS.IntentGas};
}

mark.highlight-liquid {
  background-color: ${({ theme }: { theme: ThemeType }) => theme.COLORS.IntentLiquid};
}

mark.highlight-solid {
  color: ${({ theme }) => theme.COLORS.White};
  background-color: ${({ theme }) => theme.COLORS.IntentSolid};;
}
`;

export default GlobalStyledComponent;
