import React, { FC, useState } from "react";

import { FloatingButton } from "components/Button";
import { SvgIcon } from "components/Icon";
import { IcUp } from "components/SvgIcons";
import COLORS from "constants/Colors";

type ScrollToTopPropsType = {
  buttonColor?: "darkGray" | "primary";
};

const PAGE_OFFSET_SHOW_GO_TO_TOP = 200;

const handleGoToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const ScrollToTop: FC<ScrollToTopPropsType> = ({ buttonColor }) => {
  const [goToTopBtnClassName, setGoToTopBtnClassName] = useState("d-none");

  window.onscroll = () => {
    let className = "mt-2";
    if (window.pageYOffset < PAGE_OFFSET_SHOW_GO_TO_TOP) {
      className = "d-none";
    }
    setGoToTopBtnClassName(className);
  };

  return (
    <FloatingButton className={goToTopBtnClassName} color={buttonColor} onClick={handleGoToTop}>
      <SvgIcon className="mb-1" component={IcUp} fontSize="default" htmlColor={COLORS.White} />
    </FloatingButton>
  );
};

export default ScrollToTop;
