import React, { FC } from "react";
import COLORS from "constants/Colors";
import { MESSAGE_TYPES } from "constants/Chat";

import Typography from "components/Typography";

import { SvgIcon } from "components/Icon";
import { IcCircleRemove } from "components/SvgIcons";

import FlexProductItem from "./FlexProductItem";

import {
  CarouselProductContent,
  CarouselContainer,
  CircleRemoveIconContainer,
  FlexImageUnavailable,
  UnavailableContainer,
} from "./FlexMessage.style";

type ContentDataType = { type: string; value?: string };

type CarouselType = {
  id: string;
  isUnavailable: boolean;
  isSingleProductSKUOutOfStock: boolean;
  type: string;
  value: ContentDataType[];
};

type FlexCarouselPropsType = {
  content: CarouselType[];
  isEnableDelete?: boolean;
  onClickDelete?: Function;
  displayScrollbar?: boolean;
};

const FlexCarousel: FC<FlexCarouselPropsType> = (props: FlexCarouselPropsType) => {
  const { content, isEnableDelete, onClickDelete = () => {}, displayScrollbar = true } = props;
  return (
    <CarouselContainer displayScrollbar={displayScrollbar}>
      {content.map(({ value: data, id: productId, isUnavailable, isSingleProductSKUOutOfStock }, index: number) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <CarouselProductContent key={index}>
            {isEnableDelete && (
              <CircleRemoveIconContainer
                onClick={() => {
                  onClickDelete(productId);
                }}
              >
                <SvgIcon component={IcCircleRemove} fontSize="inherit" htmlColor={COLORS.DarkMed} />
              </CircleRemoveIconContainer>
            )}
            {isUnavailable === true ? (
              <div>
                <UnavailableContainer>Unavailable</UnavailableContainer>
                {/* eslint-disable-next-line consistent-return , array-callback-return */}
                {data.map(({ value, type }) => {
                  if (type === MESSAGE_TYPES.PRODUCT_IMAGE) {
                    return (
                      <a href={value} key={value} target="_blank" rel="noopener noreferrer" className="d-flex">
                        <FlexImageUnavailable src={value} />
                      </a>
                    );
                  }
                  if (type === MESSAGE_TYPES.PRODUCT_NAME) {
                    return (
                      <Typography
                        key={value}
                        className="pl-2 py-2 wordBreakAll"
                        variant="title7"
                        color={COLORS.DarkMed}
                      >
                        {value}
                      </Typography>
                    );
                  }
                })}
              </div>
            ) : (
              <FlexProductItem content={data} isSingleProductSKUOutOfStock={isSingleProductSKUOutOfStock} />
            )}
          </CarouselProductContent>
        );
      })}
    </CarouselContainer>
  );
};
export default FlexCarousel;
