import React, { FC, useState, useEffect } from "react";
import moment from "moment";
import get from "lodash/get";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BROADCAST_DATE_TIME_FORMAT } from "constants/DateTimeFormat";
import { REPLY_POST_COMMENT_MESSAGE } from "constants/Facebook";

import Button from "components/Button";
import Grid from "components/Grid";
import Typography from "components/Typography";
import ConfirmationModal from "components/ConfirmationModal";
import CircularProgress from "components/CircularProgress";
import { IcSave, IcDelete } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";

import { ProjectIdType } from "types/Project";
import { MessageType } from "types/Chat";
import { AddCommentCrawlerInput, CommentCrawlerFromGQL } from "types/FacebookCrawler";
import { Device } from "types/Device";

import useDevice from "utils/hooks/useDevice";
import useGetProject from "utils/hooks/useGetProject";
import { removeTypenameField } from "utils/common";
import { cleanCategoriesMessage } from "utils/message";

import FacebookCampaignForm from "../FacebookCampaignForm";
import MessagePreview from "../MessagePreview/index";

import { useFetchFacebookCrawler } from "../hooks/useFetchFacebookCrawler";
import { useAddFacebookCrawler } from "../hooks/useAddFacebookCrawler";
import { useUpdateFacebookCrawler } from "../hooks/useUpdateFacebookCrawler";
import { useDeleteFacebookCrawler } from "../hooks/useDeleteFacebookCrawler";
import InfoCard from "./infoCard";

type FacebookFormContainerPropsType = {
  closeFrom: Function;
  facebookCrawlerFormId?: string | undefined | null;
};

const FacebookFormContainer: FC<FacebookFormContainerPropsType> = ({ facebookCrawlerFormId, closeFrom }) => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const { facebookPageId, instagramBusinessName } = useGetProject(projectId);
  const device = useDevice();
  const isMobile = Device.MOBILE === device;

  const [isSubmittingSaveDraft, setIsSubmittingSaveDraft] = useState(false);
  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(false);
  const [FacebookCrawlerData, setFacebookCrawlerData] = useState<AddCommentCrawlerInput>();

  const { commentCrawlerGQLData, isFacebookCrawlerLoading } = useFetchFacebookCrawler(
    projectId,
    facebookCrawlerFormId || "",
  );

  const { addCommentCrawler, addCommentCrawlerLoading } = useAddFacebookCrawler(t, setIsSubmittingSaveDraft, closeFrom);
  const { updateCommentCrawler, updateCommentCrawlerLoading } = useUpdateFacebookCrawler(
    t,
    setIsSubmittingSaveDraft,
    closeFrom,
  );

  const { deleteCommentCrawler, deleteCommentCrawlerLoading } = useDeleteFacebookCrawler(
    t,
    setIsOpenConfirmDeleteModal,
    closeFrom,
  );

  const submitDeleteFacebookCrawler = () => {
    if (deleteCommentCrawlerLoading) {
      return;
    }

    if (facebookCrawlerFormId) {
      deleteCommentCrawler({
        variables: {
          projectId,
          commentCrawlerId: facebookCrawlerFormId,
        },
      });
    } else {
      setIsOpenConfirmDeleteModal(false);
    }
  };

  const submitSaveDraft = () => {
    if (addCommentCrawlerLoading) {
      return;
    }

    if (updateCommentCrawlerLoading) {
      return;
    }

    const cleanObjectCategoriesMessage = cleanCategoriesMessage(
      (FacebookCrawlerData && FacebookCrawlerData.responseMessage) || [],
    );

    if (facebookCrawlerFormId !== "") {
      setIsSubmittingSaveDraft(true);

      updateCommentCrawler({
        variables: {
          projectId,
          commentCrawlerId: facebookCrawlerFormId,
          commentCrawler: {
            isActive: FacebookCrawlerData?.isActive,
            isWatchedAllComments: FacebookCrawlerData?.isWatchedAllComments,
            platform: FacebookCrawlerData?.platform,
            replyPostCommentMessage: FacebookCrawlerData?.replyPostCommentMessage,
            reservedWords: FacebookCrawlerData?.reservedWords,
            responseMessage: cleanObjectCategoriesMessage,
            title: FacebookCrawlerData?.title,
            type: FacebookCrawlerData?.type,
          },
        },
      });
    } else {
      setIsSubmittingSaveDraft(true);

      addCommentCrawler({
        variables: {
          projectId,
          commentCrawler: {
            isActive: FacebookCrawlerData?.isActive,
            isWatchedAllComments: FacebookCrawlerData?.isWatchedAllComments,
            platform: FacebookCrawlerData?.platform,
            postId: FacebookCrawlerData?.postId,
            replyPostCommentMessage: FacebookCrawlerData?.replyPostCommentMessage,
            reservedWords: FacebookCrawlerData?.reservedWords,
            responseMessage: cleanObjectCategoriesMessage,
            title: FacebookCrawlerData?.title,
            type: FacebookCrawlerData?.type,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (facebookCrawlerFormId === "") {
      // this is add new
      const initFacebookCrawlerData: AddCommentCrawlerInput = {
        title: "",
        isActive: true,
        postId: "",
        isWatchedAllComments: true,
        replyPostCommentMessage: REPLY_POST_COMMENT_MESSAGE, // default
        responseMessage: [
          {
            type: MessageType.QUICK_REPLIES,
            value: [],
          },
        ],
        reservedWords: [],
        platform: null,
        type: null,
      };
      setFacebookCrawlerData(initFacebookCrawlerData);
    } else if (commentCrawlerGQLData) {
      const newCommentCrawlerGQLData = removeTypenameField(
        commentCrawlerGQLData && commentCrawlerGQLData.commentCrawlerById,
      ) as CommentCrawlerFromGQL;

      setFacebookCrawlerData({
        isActive: newCommentCrawlerGQLData.isActive,
        isWatchedAllComments: newCommentCrawlerGQLData.isWatchedAllComments,
        platform: newCommentCrawlerGQLData.platform,
        postId: newCommentCrawlerGQLData.postId,
        replyPostCommentMessage: newCommentCrawlerGQLData.replyPostCommentMessage,
        reservedWords: newCommentCrawlerGQLData.reservedWords,
        responseMessage: newCommentCrawlerGQLData.responseMessage,
        title: newCommentCrawlerGQLData.title,
        type: newCommentCrawlerGQLData.type,
      });
    }
  }, [commentCrawlerGQLData, facebookCrawlerFormId]);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const isValidToSave =
    FacebookCrawlerData && FacebookCrawlerData.isWatchedAllComments
      ? Boolean(
          FacebookCrawlerData?.responseMessage?.length === 2 &&
            FacebookCrawlerData.responseMessage.every((message) => message.value),
        ) &&
        Boolean(FacebookCrawlerData.title) &&
        Boolean(FacebookCrawlerData.postId) &&
        Boolean(FacebookCrawlerData.replyPostCommentMessage)
      : FacebookCrawlerData &&
        Boolean(
          FacebookCrawlerData?.responseMessage?.length === 2 &&
            FacebookCrawlerData.responseMessage.every((message) => message.value),
        ) &&
        Boolean(FacebookCrawlerData.title) &&
        Boolean(FacebookCrawlerData.postId) &&
        Boolean(FacebookCrawlerData.reservedWords.length) &&
        Boolean(FacebookCrawlerData.replyPostCommentMessage) &&
        Boolean(FacebookCrawlerData.type);

  const isEdit = facebookCrawlerFormId !== "";

  const renderFacebookAutoEngagementInfo = () => (
    <InfoCard
      numberOfReach={get(commentCrawlerGQLData, "commentCrawlerById.numberOfReach") || 0}
      numberOfEngagement={get(commentCrawlerGQLData, "commentCrawlerById.numberOfEngagement") || 0}
      orderPaidConversionRate={get(commentCrawlerGQLData, "commentCrawlerById.orderPaidConversionRate") || 0}
      orderPaidConversionTotal={get(commentCrawlerGQLData, "commentCrawlerById.orderPaidConversionTotal") || 0}
      createdAt={moment(get(commentCrawlerGQLData, "commentCrawlerById.createdAt")).format(BROADCAST_DATE_TIME_FORMAT)}
      updatedAt={moment(get(commentCrawlerGQLData, "commentCrawlerById.updatedAt")).format(BROADCAST_DATE_TIME_FORMAT)}
    />
  );

  return (
    <Grid id="FacebookFormContainer" container alignContent="flex-start">
      {/* Title panel */}
      <Grid item container xs={12} alignItems="center" alignContent="center" className="py-3 px-3">
        <Grid item xs={12} md={5}>
          <Typography variant="title1" color="dark">
            {t("facebookAutoEngagement.header")}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={7}
          container
          justify="flex-end"
          alignItems="center"
          alignContent="center"
          className={isMobile ? "pt-2" : ""}
        >
          {facebookCrawlerFormId && (
            <Button
              color="secondary"
              className="mx-1"
              size="medium"
              onClick={() => {
                setIsOpenConfirmDeleteModal(true);
              }}
            >
              <Grid container alignItems="center" alignContent="center" className="mx-2">
                <SvgIcon component={IcDelete} fontSize="small" className="mr-2" />
                {t("facebookAutoEngagement.delete")}
              </Grid>
            </Button>
          )}
          <Button
            color="primary"
            className="mx-1"
            size="medium"
            onClick={submitSaveDraft}
            disabled={!isValidToSave || isSubmittingSaveDraft}
          >
            <Grid container alignItems="center" alignContent="center" className="mx-2">
              <SvgIcon component={IcSave} fontSize="small" className="mr-2" />
              {t("facebookAutoEngagement.save")}
            </Grid>
          </Button>
        </Grid>
      </Grid>
      {/* Title panel */}

      {isFacebookCrawlerLoading ? (
        <Grid container item xs={12} justify="center">
          <CircularProgress className="m-4" />
        </Grid>
      ) : (
        <>
          {FacebookCrawlerData && (
            <>
              {isMobile && isEdit && (
                <Grid container item xs={12} className="px-3">
                  {renderFacebookAutoEngagementInfo()}
                </Grid>
              )}
              <Grid container item xs={12} md={6} className="px-3 pb-3">
                <FacebookCampaignForm
                  isNewCreateForm={!facebookCrawlerFormId}
                  projectId={projectId}
                  FacebookCrawlerData={FacebookCrawlerData}
                  setFacebookCrawlerData={setFacebookCrawlerData}
                  disabledForm={isEdit}
                  instagramBusinessName={instagramBusinessName}
                  facebookPageId={facebookPageId || ""}
                />
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                className="px-3 pb-3"
                alignContent="flex-start"
                alignItems="flex-start"
              >
                {!isMobile && isEdit && renderFacebookAutoEngagementInfo()}

                <Grid item xs={12}>
                  <MessagePreview
                    projectId={projectId}
                    FacebookCrawlerData={FacebookCrawlerData}
                    setFacebookCrawlerData={setFacebookCrawlerData}
                    disabledForm={false}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}

      <ConfirmationModal
        title={`${t("facebookAutoEngagement.confirmDelete")} ?`}
        isOpen={isOpenConfirmDeleteModal}
        onClose={() => setIsOpenConfirmDeleteModal(false)}
        onSubmit={() => {
          submitDeleteFacebookCrawler();
        }}
      />
    </Grid>
  );
};

export default FacebookFormContainer;
