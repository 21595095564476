import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import COLORS from "constants/Colors";
import Button from "components/Button";
import ButtonBase from "components/ButtonBase";
import { SvgIcon } from "components/Icon";
import IcSend from "components/SvgIcons/IcSend";

import useDevice from "utils/hooks/useDevice";
import { Device } from "types/Device";

type SendMessageButtonProps = {
  iconColor?: string;
  onClick?: () => void;
};

const SendMessageButton: FC<SendMessageButtonProps> = ({ iconColor = COLORS.DarkLight, onClick }) => {
  const device = useDevice();
  const isDesktop = device === Device.DESKTOP;
  const { t } = useTranslation();

  return (
    <>
      {isDesktop ? (
        <Button color="primary" size="small" onClick={onClick}>
          <SvgIcon className="mr-1" component={IcSend} fontSize="small" />
          <span>{t("braodcast.messagePreview.previewButton")}</span>
        </Button>
      ) : (
        <ButtonBase onClick={onClick}>
          <SvgIcon className="mx-3" component={IcSend} fontSize="large" htmlColor={iconColor} />
        </ButtonBase>
      )}
    </>
  );
};

export default SendMessageButton;
