import React, { FC } from "react";
import { useParams } from "react-router-dom";

import Grid from "components/Grid";
import Card from "components/Card";
import Divider from "components/Divider";
import Pagination from "components/Pagination";
import CircularProgress from "components/CircularProgress";

import { DIGITAL_PRODUCT_CODE_LIMIT } from "config";
import { DigitalContentStatus, DigitalContent, DigitalProductsQueryVariablesType } from "types/DigitalContent";
import { ProductOutputType } from "types/Product";
import { ProjectIdType } from "types/Project";

import DigitalProductSKUItem from "../DigitalProductSKUItem";
import useDigitalProductCodes from "../hooks/useDigitalProductCodes";

type DigitalProductSKUItemListPropsType = {
  status: DigitalContentStatus;
  outputType: ProductOutputType;
  productId: string;
  productSKUId: string;
  productCode: string;
  variations: string;
  price: number;
  digitalProductsQueryVariables: DigitalProductsQueryVariablesType;
  renderProductListItem: () => JSX.Element;
};

const DigitalProductSKUItemList: FC<DigitalProductSKUItemListPropsType> = ({
  productCode,
  variations,
  price,
  status,
  outputType,
  productId,
  productSKUId,
  digitalProductsQueryVariables,
  renderProductListItem,
}) => {
  const { projectId = "" } = useParams<ProjectIdType>();

  const {
    isLoading,
    digitalProductCodes,
    totalDigitalProductCodes,
    pageNo,
    handlePageChange,
    digitalProductCodesQueryVariables,
  } = useDigitalProductCodes(projectId, productSKUId, status);

  return (
    <Card padding="0px">
      {isLoading ? (
        <Grid container justify="center">
          <CircularProgress className="m-4" />
        </Grid>
      ) : (
        <Grid container>
          {digitalProductCodes.map((digitalContent: DigitalContent, index: number) => {
            const key = `${digitalContent.id}-${index}`;

            return (
              <Grid container item key={key}>
                {Boolean(index) && (
                  <Grid item xs={12}>
                    <Divider className="m-0" thickness="0.5px" />
                  </Grid>
                )}
                <Grid item xs={12} className="p-4">
                  <DigitalProductSKUItem
                    projectId={projectId}
                    productId={productId}
                    productSKUId={productSKUId}
                    productCode={productCode}
                    variations={variations}
                    price={price}
                    digitalProductCode={digitalContent}
                    status={status}
                    outputType={outputType}
                    digitalProductsQueryVariables={digitalProductsQueryVariables}
                    digitalProductCodesQueryVariables={digitalProductCodesQueryVariables}
                    renderProductListItem={renderProductListItem}
                  />
                </Grid>
              </Grid>
            );
          })}
          <Grid item xs={12} className="px-3 pb-3">
            <Pagination
              rowsPerPage={DIGITAL_PRODUCT_CODE_LIMIT}
              page={pageNo}
              totalItem={totalDigitalProductCodes}
              handlePageChange={handlePageChange}
              isMobile
            />
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

export default DigitalProductSKUItemList;
