export enum VAT_OPTIONS_TYPE {
  NONE = "NONE",
  INCLUDED = "INCLUDED",
  EXCLUDED = "EXCLUDED",
}

export type VatOptionType = {
  title: string;
  value: VAT_OPTIONS_TYPE;
  description?: string;
};

export type VatFormDataType = {
  isShowTaxInfo?: boolean;
  rate?: number;
  taxId?: string;
  companyName?: string;
  lang?: Language;
};

export enum Language {
  TH = "TH",
  EN = "EN",
}

export type Vat = {
  id: string;
  projectId: string;
  taxId: string;
  option: VAT_OPTIONS_TYPE;
  isShowTaxInfo: boolean;
  rate: number;
  companyName: string;
  isShippingIncluded: boolean;

  isFullTaxInfo: boolean;
  address: string;
  district: string;
  phoneNumber: string;
  postcode: string;
  province: string;
  subDistrict: string;
  lang: Language;
};

export type UpdatingVatType = VatFormDataType & {
  option: VAT_OPTIONS_TYPE;
  isShippingIncluded: boolean;
};

export type VatQuery = {
  vat: Vat;
};
