import { useState } from "react";
import { useQuery } from "@apollo/client";
import { RedeemDigitalContentQuery } from "types/DigitalContent";
import { DIGITAL_CONTENT_BY_CODE } from "graphql/digitalContent/query";

const useQueryDigitalContentByCode = (projectId: string) => {
  const [code, setCode] = useState("");
  const { data, loading } = useQuery<RedeemDigitalContentQuery>(DIGITAL_CONTENT_BY_CODE, {
    variables: {
      projectId,
      code,
    },
    skip: !projectId || !code,
    fetchPolicy: "network-only",
  });

  return {
    code,
    setCode,
    data,
    loading,
  };
};

export default useQueryDigitalContentByCode;
