import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Card from "components/Card";
import Divider from "components/Divider";
import Grid from "components/Grid";
import Typography from "components/Typography";

import ProductListItem from "domain/Product/ProductListItem";
import CustomerAddress from "domain/Customer/CustomerAddress";
import COLORS from "constants/Colors";
import { CONTENT_MANAGEMENT_DATE_TIME_FORMAT } from "constants/DateTimeFormat";
import { RedeemDigitalContent } from "types/DigitalContent";
import { ProductSKUType } from "types/Product";
import { getProductSKUVariationList } from "utils/product/getProductSKUVariationList";
import { convertPriceFormat } from "utils/common";

import { OrderCardHeader } from "../styled";

type ProductAndCustomerDetailPropsType = {
  projectId: string;
  digitalContentByCode: RedeemDigitalContent;
  isMobile: boolean;
};

const ProductAndCustomerDetail: FC<ProductAndCustomerDetailPropsType> = (props) => {
  const {
    projectId,
    digitalContentByCode: {
      digitalContent: { soldAt, expiredAt },
      product: { name, images, defaultPrice, productSKUs },
      order: { orderNumber, customer, firstName, lastName, phoneNumber, email },
    },
    isMobile,
  } = props;
  const { productCode, price, productType } = productSKUs[0];
  const { t } = useTranslation();

  const productSKUVariationText = getProductSKUVariationList(productType) || [];
  const variations = productSKUVariationText.reduce((result, variationText, index) => {
    const lastIndex = productSKUVariationText.length - 1;
    return `${result}${variationText}${index !== lastIndex ? ", " : ""}`;
  }, "");

  const computedCustomer = {
    ...customer,
    address: { ...customer.address, firstName, lastName, phoneNumber, email },
  };

  return (
    <Card noShadow padding="0px">
      <Grid container>
        <OrderCardHeader item container className={isMobile ? "px-3 py-4" : "p-4"}>
          <Grid item xs={12}>
            <Typography variant="title8" color="darkLight">
              {t("Order no.")}
            </Typography>
          </Grid>
          <Grid item xs={12} className="pt-2">
            <Typography variant="title2" color="white">
              {orderNumber}
            </Typography>
          </Grid>
        </OrderCardHeader>
        <Grid item xs={12} className={isMobile ? "p-3" : "p-4"}>
          <ProductListItem
            productImage={images?.[0]?.src || ""}
            productName={name}
            productSKUs={productSKUs}
            defaultPrice={defaultPrice}
            isActive={productSKUs.some((productSKU: ProductSKUType) => productSKU.selected === true)}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider className={`my-0 ${isMobile ? "mx-3" : "mx-4"}`} thickness="0.5px" />
        </Grid>
        <Grid item container className={isMobile ? "p-3" : "p-4"}>
          <Grid item container justify="space-between" className="pb-2">
            <Grid item>
              <Typography variant="title8" color="gray">
                {t("SKU Code")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="title8" color="gray">
                {t("SKU(s)")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="title8" color="gray">
                {t("Price")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container justify="space-between" className="pt-1">
            <Grid item>
              <Typography variant="body3" color="darkGray">
                {productCode}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body3" color="darkGray">
                {variations}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body3" color="darkGray">
                {t("THB")} {convertPriceFormat(price, 0)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={isMobile ? "pt-3" : "pt-4"}>
            <Card padding="0px" noBorder noShadow bgColor={COLORS.LightWhite}>
              <Grid container>
                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  className={isMobile ? "border-bottom p-3" : "border-right px-4 py-3"}
                >
                  <Grid item xs={12} className="pl-2">
                    <Typography variant="title8" color="primary">
                      {t("contentManagement.codeDetail.label.purchasedTime")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className="pl-2">
                    <Typography variant="body3" color="darkGray">
                      {moment(soldAt, "x").format(CONTENT_MANAGEMENT_DATE_TIME_FORMAT)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6} className={isMobile ? "border-top p-3" : "border-left px-4 py-3"}>
                  <Grid item xs={12} className="pl-2">
                    <Typography variant="title8" color="primary">
                      {t("contentManagement.codeDetail.label.expiredTime")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className="pl-2">
                    <Typography variant="body3" color="darkGray">
                      {expiredAt
                        ? moment(expiredAt, "x").format(CONTENT_MANAGEMENT_DATE_TIME_FORMAT)
                        : t("contentManagement.codeDetail.label.noExpiredTime")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider className="m-0" thickness="0.5px" />
        </Grid>
        <Grid item xs={12} className={`${isMobile ? "" : "p-2"}`}>
          <CustomerAddress
            className="border-0"
            customer={computedCustomer}
            projectId={projectId}
            customerId={customer.id}
            isHiddenAddress
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProductAndCustomerDetail;
