import React, { FC } from "react";
import { useParams } from "react-router-dom";
import sortBy from "lodash/sortBy";

import Card from "components/Card";
import Grid from "components/Grid";
import StickyPanel from "components/StickyPanel";
import { IcUser, IcStoreChannel } from "components/SvgIcons";

import { STORE_SETTINGS, PROJECT, MEMBERS, CONNECT } from "constants/Router";
import { ADVANCE_FEATURE_ADDONS, INTEGRATION_ADDONS } from "constants/Addon";
import { CHANNEL_LIMIT } from "config";
import { AddonFilterType, AddonType, AddonCountType } from "types/Addon";
import { ProjectIdType } from "types/Project";

import { AddonSummaryWrapper, AddonCardWrapper } from "../styled";
import AddonCard from "../AddonCard";
import AddonImageSearchItemList from "../ImageSearchItemList";
import AddonSummary, { AddonSummaryPropsType } from "../AddonSummary";
import AdvanceFeatureItemList from "../AdvanceFeatureItemList";
import AddonBroadcastItemList from "../BroadcastItemList";

type AddonContentPropsType = {
  addons: AddonType[];
  currentAddons: AddonCountType;
  currentAmount: number;
  currentUsageAmount: number;
  defaultAmount: number;
  handleAddNewAddon: (type: string) => void;
  handleChangeImageSearchAddon: (newImageSearchAddon?: AddonType) => void;
  handleRemoveAddon: (type: string) => void;
  handleToggleAddon: (type: string, isDisabled: boolean) => void;
  isDesktop: boolean;
  selectedAddons: AddonCountType;
  selectedAddonType: AddonFilterType;
  selectedAmount: number;
  selectedImageAddon?: AddonType;
  handleChangeBroadcastAddon: (newBroadcastAddon?: AddonType) => void;
  selectedBroadcastAddon?: AddonType;
} & AddonSummaryPropsType;

const AddonContent: FC<AddonContentPropsType> = (props) => {
  const { projectId } = useParams<ProjectIdType>();

  const {
    addons,
    currentAddons,
    currentAmount,
    currentUsageAmount,
    defaultAmount,
    handleAddNewAddon,
    handleCalculateAddon,
    handleChangeImageSearchAddon,
    handleRemoveAddon,
    handleToggleAddon,
    isAddonsChanged,
    isDesktop,
    selectedAddons,
    selectedAddonType,
    selectedAmount,
    selectedImageAddon,
    subTotalPrice,
    handleChangeBroadcastAddon,
    selectedBroadcastAddon,
  } = props;

  let focusedAddonData: AddonType[] = [];
  if (selectedAddonType === AddonFilterType.ADVANCE_FEATURE) {
    focusedAddonData = addons.filter(({ entityType }) => ADVANCE_FEATURE_ADDONS.includes(entityType));
  } else if (selectedAddonType === AddonFilterType.INTEGRATION) {
    focusedAddonData = addons.filter(({ entityType }) => INTEGRATION_ADDONS.includes(entityType));
  } else {
    focusedAddonData = addons.filter(({ entityType }) => (entityType as string) === (selectedAddonType as string));
  }

  const renderAddonSummary = () => (
    <AddonSummary
      subTotalPrice={subTotalPrice}
      handleCalculateAddon={handleCalculateAddon}
      isAddonsChanged={isAddonsChanged}
    />
  );

  return (
    <Grid item container>
      <AddonCardWrapper item sm={12} lg={7} className="pt-3 flex-grow-1" isSpaceAdded={isAddonsChanged && !isDesktop}>
        {(selectedAddonType === AddonFilterType.MEMBER || selectedAddonType === AddonFilterType.CHANNEL) && (
          <AddonCard
            addonType={selectedAddonType}
            defaultAmount={defaultAmount}
            currentAmount={currentAmount}
            currentUsageAmount={currentUsageAmount}
            onAddNewAddon={handleAddNewAddon}
            selectedAmount={selectedAmount}
            onRemoveAddon={handleRemoveAddon}
            addonIcon={selectedAddonType === AddonFilterType.MEMBER ? IcUser : IcStoreChannel}
            addonIconViewBox={selectedAddonType === AddonFilterType.MEMBER ? "2 2 28 28" : "32 32 128 128"}
            redirectRoute={`/${PROJECT}/${projectId}/${STORE_SETTINGS}/${
              selectedAddonType === AddonFilterType.MEMBER ? MEMBERS : CONNECT
            }`}
            addonData={focusedAddonData[0]}
            itemLimit={selectedAddonType === AddonFilterType.CHANNEL ? CHANNEL_LIMIT : undefined}
          />
        )}
        {selectedAddonType === AddonFilterType.IMAGE_SEARCH && (
          <AddonImageSearchItemList
            addons={sortBy(focusedAddonData, "pricePerMonth")}
            selectedImageAddon={selectedImageAddon}
            handleChangeImageSearchAddon={handleChangeImageSearchAddon}
          />
        )}
        {selectedAddonType === AddonFilterType.BROADCAST && (
          <AddonBroadcastItemList
            projectId={projectId}
            addons={sortBy(focusedAddonData, "pricePerMonth")}
            handleChangeBroadcastAddon={handleChangeBroadcastAddon}
            selectedBroadcastAddon={selectedBroadcastAddon}
          />
        )}
        {(selectedAddonType === AddonFilterType.ADVANCE_FEATURE ||
          selectedAddonType === AddonFilterType.INTEGRATION) && (
          <AdvanceFeatureItemList
            addons={focusedAddonData}
            currentAddons={currentAddons}
            onToggleAddon={handleToggleAddon}
            selectedAddons={selectedAddons}
          />
        )}
      </AddonCardWrapper>
      {isDesktop ? (
        <Grid item className="p-3" lg={5}>
          <Card noShadow padding="20px">
            {renderAddonSummary()}
          </Card>
        </Grid>
      ) : (
        isAddonsChanged && (
          <StickyPanel>
            <AddonSummaryWrapper container className="py-4 px-3">
              {renderAddonSummary()}
            </AddonSummaryWrapper>
          </StickyPanel>
        )
      )}
    </Grid>
  );
};

export default AddonContent;
