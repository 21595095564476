import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import Error from "components/Error";

export const NotFound: FC = () => {
  const history = useHistory();
  const handleClick = () => history.push("/project");

  return <Error errorCode={404} buttonText="Back to project" handleClickButton={handleClick} />;
};

export default NotFound;
