import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import { SvgIcon } from "components/Icon";
import IcEdit from "components/SvgIcons/IcEdit";
import Button, { ButtonWithIcon } from "components/Button";
import Card from "components/Card";
import Typography from "components/Typography";

import { NO_IMG_AVAILABLE } from "constants/Image";
import { COLORS } from "constants/Colors";
import { CategoryType } from "types/Category";

import { ProductImage, EditButtonWrapper } from "./style";

type HighlightProductCardPropsType = {
  category: CategoryType;
  isMobile: boolean;
  onClickEdit: (categoryId: string) => void;
};

const HighlightProductCard: FC<HighlightProductCardPropsType> = (props) => {
  const { category, isMobile, onClickEdit } = props;
  const { id, image, name } = category;

  const { t } = useTranslation();

  const handleClickEdit = () => {
    onClickEdit(id);
  };

  return (
    <Card id={id} className="p-3 w-100 border-box position-relative" fullWidth>
      <Grid container className="align-items-center p-2">
        <Grid item className="flex-grow-0 pr-4">
          <ProductImage alt={image || "no img"} src={image || NO_IMG_AVAILABLE} />
        </Grid>

        <Grid item className="flex-grow-1">
          <Typography variant="title4" color="dark">
            {name}
          </Typography>
          <Typography variant="body2" className="pt-2">
            {name}
          </Typography>
        </Grid>
      </Grid>
      <EditButtonWrapper className="p-3">
        {isMobile ? (
          <ButtonWithIcon onClick={handleClickEdit}>
            <SvgIcon className="ml-1" component={IcEdit} fontSize="small" htmlColor={COLORS.DarkMed} />
          </ButtonWithIcon>
        ) : (
          <Button color="secondary" size="small" onClick={handleClickEdit}>
            <SvgIcon
              className="ml-1 mt-1"
              viewBox="8 4 24 24"
              component={IcEdit}
              fontSize="small"
              htmlColor={COLORS.DarkMed}
            />
            {t("Edit")}
          </Button>
        )}
      </EditButtonWrapper>
    </Card>
  );
};

export default HighlightProductCard;
