import { useQuery } from "@apollo/client";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { FC, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import debounce from "lodash/debounce";

import COLORS from "constants/Colors";
import { REPLY_SHORTCUT as REPLY_SHORTCUT_I18N } from "constants/i18n";
import { MAX_REPLY_SHORTCUT } from "config/replyShortcut";
import Button, { FloatingButton } from "components/Button";
import ConfirmationModal from "components/ConfirmationModal";
import Search from "components/Search";
import Card from "components/Card";
import ErrorText from "components/ErrorText";
import TextField from "components/TextField";
import Typography from "components/Typography";
import IcReplyShortcut from "components/SvgIcons/IcReplyShortcut";
import { SvgIcon } from "components/Icon";
import { IcArrowToTop } from "components/SvgIcons";
import IcAdd from "components/SvgIcons/IcAdd";
import Modal from "components/Modal";
import MessagesManager from "components/MessagesManager";
import { StickyButtonContainer } from "components/StickyPanel";
import Loading from "domain/Faq/FaqThEn/FaqContainer/Loading";
import { CHAT_TEMPLATE } from "graphql/chatTemplate/query";
import useSetTitleOnMount from "utils/hooks/useSetTitleOnMount";
import useToggle from "utils/hooks/useToggle";
import useMessagesManager from "utils/hooks/useMessagesManager";
import useChatTemplates from "utils/hooks/useChatTemplates";
import { notifyError, notifySuccess } from "utils/notify";
import { ReplyShortcut, ReplyShortcutQuery } from "types/ReplyShortcut";
import { ProjectIdType } from "types/Project";
import { Device } from "types/Device";
import useDevice from "utils/hooks/useDevice";
import PageTitle from "components/PageTitle";
import { DEFAULT_SCROLL } from "constants/Scroll";
import useScrollToTopElement from "utils/hooks/useScrollToTopElement";
import schema from "./schema";
import ReplyShortcutCard from "./ReplyShortcutCard";
import removeTabIndex from "./removeTabIndex";

const MODAL_ID = "replyShortcutModal";

const useStyles = makeStyles(() => ({
  paper: {
    height: "100%",
    width: "100%",
  },
}));

const ReplyShortcutPage: FC = () => {
  const classes = useStyles();
  const { projectId } = useParams<ProjectIdType>();
  const { t } = useTranslation(REPLY_SHORTCUT_I18N);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [isMessageError, setIsMessageError] = useState(false);
  const [selectId, setSelectId] = useState<string>("");
  const handleSearchTextChange = debounce((value: string) => {
    setPage(0);
    setSearchText(value);
  }, 400);

  const device = useDevice();
  const isDesktop = device === Device.DESKTOP;
  const isTablet = device === Device.TABLET;
  const isMobile = device === Device.MOBILE;

  const { register, handleSubmit, errors, reset, setError, clearErrors } = useForm({
    resolver: yupResolver(schema),
  });

  const { handleToggle, handleClose, isOpen } = useToggle();
  const { handleToggle: handleToggleModal, handleClose: handleCloseModal, isOpen: isOpenModal } = useToggle();
  useSetTitleOnMount(t("REPLY_SHORTCUT"));

  const {
    addChatTemplate,
    chatTemplates,
    deleteChatTemplate,
    fetchMore,
    isChatTemplatesLoading,
    total,
    updateChatTemplate,
  } = useChatTemplates(projectId, page, searchText);

  const {
    isOpenConfirmModal,
    selectedIndex,
    messages,
    deleteMessage,
    handleClickAddMessage,
    handleDeleteMessage,
    handleDragEnd,
    handleUploadCallback,
    addNewMessages,
    handleEditMessage,
    handleSetMessages,
    updateNewQuickReplyMessage,
    handleClickSave,
    handleCloseConfirmModal,
  } = useMessagesManager();

  const { data: chatTemplateData, loading: isChatTemplateDataLoading } = useQuery<{ chatTemplate: ReplyShortcut }>(
    CHAT_TEMPLATE,
    {
      skip: !selectId,
      variables: { projectId, chatTemplateId: selectId },
      onCompleted: (data) => {
        handleSetMessages(data?.chatTemplate?.messages || []);
      },
    },
  );

  const chatTemplatesLength = chatTemplates.length || 0;

  const loadMoreChatTemplate = () => {
    const newOffset = Math.floor(chatTemplatesLength / MAX_REPLY_SHORTCUT);
    fetchMore({
      variables: { projectId, offset: newOffset, limit: MAX_REPLY_SHORTCUT, filter: { keyword: searchText } },
      updateQuery: (prev: ReplyShortcutQuery, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const updatedCampaignPromotionResults = [
          ...prev.chatTemplates.results,
          ...fetchMoreResult.chatTemplates.results,
        ];

        return {
          ...prev,
          chatTemplates: {
            ...fetchMoreResult.chatTemplates,
            results: updatedCampaignPromotionResults,
          },
        };
      },
    });
  };

  const { scrollTopId, handleScrollToTop, isScroll } = useScrollToTopElement(
    DEFAULT_SCROLL.REPLY_SHORTCUT_TOP,
    isDesktop,
  );

  const handleClickAdd = useCallback(() => {
    handleToggle();

    setTimeout(() => {
      removeTabIndex(MODAL_ID);
    }, 300);
  }, [handleToggle]);

  const handleClickEdit = useCallback(
    (id: string) => {
      setSelectId(id);
      handleToggle();

      setTimeout(() => {
        removeTabIndex(MODAL_ID);
      }, 300);
    },
    [handleToggle],
  );

  const handleClickDelete = useCallback(
    (id: string) => {
      handleToggleModal();
      setSelectId(id);
    },
    [handleToggleModal],
  );

  const handleCloseModalAndClearForm = useCallback(() => {
    setIsMessageError(false);
    handleSetMessages([]);
    setSelectId("");
    reset();
    clearErrors();
    handleClose();
  }, [clearErrors, handleClose, handleSetMessages, reset]);

  const handleSubmitFormModal = handleSubmit((data) => {
    if (messages.length === 0) {
      setIsMessageError(true);
      return;
    }

    if (selectId) {
      handleClickSave(async (messages) => {
        try {
          await updateChatTemplate({
            variables: {
              projectId,
              chatTemplateId: selectId,
              chatTemplate: {
                name: data.name,
                description: data.description,
                shortcutKey: data.shortcutKey,
                messages,
              },
            },
          });
        } catch (error) {
          console.error("error: ", error.message);
          let errorMessage = "UPDATE_FAIL";
          if (error.message === "CHAT_TEMPLATE:DUPLICATED_SHORTCUT_KEY") {
            errorMessage = "DUPLICATED_SHORTCUT_KEY";
            setError("shortcutKey", { type: "duplicated", message: errorMessage });
          }

          if (error.message === "CHAT_TEMPLATE:SHORT_CUT_DOES_NOT_START_WITH_CHARACTER") {
            errorMessage = "SHORT_CUT_DOES_NOT_START_WITH_CHARACTER";
            setError("shortcutKey", { type: "pattern", message: errorMessage });
          }

          notifyError(t(errorMessage));
          return;
        }
        notifySuccess(t("UPDATE_SUCCESS"));

        handleCloseModalAndClearForm();
      });
    } else {
      handleClickSave(async (messages) => {
        if (chatTemplatesLength >= 50) {
          notifyError(t("REPLY_SHORTCUT_HAS_REACHED_LIMIT"));
          return;
        }
        try {
          await addChatTemplate({
            variables: {
              projectId,
              chatTemplate: {
                name: data.name,
                description: data.description,
                shortcutKey: data.shortcutKey,
                messages,
              },
            },
          });
        } catch (error) {
          console.error("error: ", error.message);
          let errorMessage = "CREATE_FAIL";
          if (error.message === "CHAT_TEMPLATE:DUPLICATED_SHORTCUT_KEY") {
            errorMessage = "DUPLICATED_SHORTCUT_KEY";
            setError("shortcutKey", { type: "duplicated", message: errorMessage });
          }

          if (error.message === "CHAT_TEMPLATE:SHORT_CUT_DOES_NOT_START_WITH_CHARACTER") {
            errorMessage = "SHORT_CUT_DOES_NOT_START_WITH_CHARACTER";
            setError("shortcutKey", { type: "pattern", message: errorMessage });
          }

          notifyError(t(errorMessage));
          return;
        }

        notifySuccess(t("CREATE_SUCCESS"));

        handleCloseModalAndClearForm();
      });
    }
  });

  const closeConfirmModal = useCallback(() => {
    handleCloseConfirmModal();
    handleCloseModal();
  }, [handleCloseConfirmModal, handleCloseModal]);

  const handleSubmitDelete = useCallback(async () => {
    if (isOpenModal) {
      try {
        await deleteChatTemplate({
          variables: {
            projectId,
            chatTemplateId: selectId,
          },
        });
      } catch (error) {
        console.error("error: ", error);
        notifyError(t("DELETE_FAIL"));
        return;
      }

      notifySuccess(t("DELETE_SUCCESS"));
      handleSetMessages([]);
      setSelectId("");
      handleCloseModal();
    }

    if (isOpenConfirmModal) {
      deleteMessage(selectedIndex);
      handleCloseConfirmModal();
    }
  }, [
    deleteChatTemplate,
    deleteMessage,
    handleCloseConfirmModal,
    handleCloseModal,
    handleSetMessages,
    isOpenConfirmModal,
    isOpenModal,
    projectId,
    selectId,
    selectedIndex,
    t,
  ]);

  return (
    <>
      <Box display="flex" flexDirection="column" height="100%">
        <Grid container id={scrollTopId}>
          <Grid item>
            <PageTitle title={t("REPLY_SHORTCUT")} className="pb-0" />
          </Grid>
          {!isMobile && (
            <Grid item className="pt-3" xs={1}>
              <Typography className="mt-3" variant={isDesktop || isTablet ? "body3" : "body4"} color="dark">
                {chatTemplatesLength}/{MAX_REPLY_SHORTCUT}
              </Typography>
            </Grid>
          )}
          {Boolean(chatTemplatesLength) && (
            <Grid item xs justify="flex-end" alignItems="center" alignContent="center" className="d-flex px-3 pt-2">
              <Button color="primary" className="px-3 mt-2" size="medium" onClick={handleClickAdd}>
                <SvgIcon component={IcAdd} fontSize="small" />
                {isDesktop || isTablet ? t("ADD_REPLY_SHORTCUT") : t("Add")}
              </Button>
            </Grid>
          )}
        </Grid>
        {isMobile && (
          <Box className="pt-2 px-3">
            <Typography variant={isDesktop || isTablet ? "body3" : "body4"} color="dark">
              {chatTemplatesLength}/{MAX_REPLY_SHORTCUT}
            </Typography>
          </Box>
        )}
        <Search
          defaultValue={searchText}
          onChange={handleSearchTextChange}
          className="m-4"
          placeholder={t("searchPlaceholder.aiReplyShortcut")}
        />
        {isChatTemplatesLoading && <Loading loadingLabel={t("LOADING")} size={50} />}
        {Boolean(chatTemplatesLength) && !isChatTemplatesLoading && (
          <InfiniteScroll
            className="w-100"
            loadMore={loadMoreChatTemplate}
            hasMore={chatTemplatesLength < total}
            useWindow={false}
          >
            <Grid container spacing={3} className="px-4">
              {chatTemplates.map((chatTemplate) => (
                <Grid item xs={12} sm={6} md={4} key={chatTemplate.id}>
                  <ReplyShortcutCard
                    description={chatTemplate.description}
                    id={chatTemplate.id}
                    name={chatTemplate.name}
                    onClickDelete={handleClickDelete}
                    onClickEdit={handleClickEdit}
                    shortcutKey={chatTemplate.shortcutKey}
                  />
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>
        )}
        {!chatTemplatesLength && !isChatTemplatesLoading && (
          <Box display="flex" alignItems="center" justifyContent="center" flex={1} flexDirection="column">
            <>
              <Box display="block" marginBottom="40px">
                <IcReplyShortcut color={COLORS.LightBlue} fontSize={100} />
              </Box>
              <Button className="d-block" onClick={handleClickAdd} color="secondary" style={{ width: 220 }}>
                <IcAdd style={{ verticalAlign: "middle", marginRight: 8 }} />
                {t("ADD_REPLY_SHORTCUT")}
              </Button>
            </>
          </Box>
        )}
      </Box>

      <Modal
        id={MODAL_ID}
        title={selectId ? t("EDIT_REPLY_SHORTCUT") : t("ADD_REPLY_SHORTCUT")}
        maxWidth="md"
        classes={classes}
        onClose={handleCloseModalAndClearForm}
        isOpen={isOpen}
      >
        {isChatTemplateDataLoading ? (
          <Loading loadingLabel={t("LOADING")} size={50} />
        ) : (
          <div className="p-4">
            <Card noShadow padding="24px">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography color="darkMed" variant="body3" className="mb-1">
                    {t("NAME")}
                  </Typography>
                  <TextField
                    inputRef={register}
                    fullWidth
                    name="name"
                    placeholder={t("SHORTCUT_TITLE")}
                    variant="outlined"
                    error={Boolean(errors?.name)}
                    helperText={t(errors?.name?.message)}
                    defaultValue={chatTemplateData?.chatTemplate?.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography color="darkMed" variant="body3" className="mb-1">
                    {t("SHORTCUT_KEY")}
                  </Typography>
                  <TextField
                    inputRef={register}
                    fullWidth
                    name="shortcutKey"
                    placeholder={t("EXAMPLE_SHORTCUT")}
                    variant="outlined"
                    error={Boolean(errors?.shortcutKey)}
                    helperText={t(errors?.shortcutKey?.message)}
                    defaultValue={chatTemplateData?.chatTemplate?.shortcutKey}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography color="darkMed" variant="body3" className="mb-1">
                    {t("DESCRIPTION")} - Optional
                  </Typography>
                  <TextField
                    inputRef={register}
                    fullWidth
                    name="description"
                    placeholder={t("WHAT_IS_REPLY_ABOUT")}
                    variant="outlined"
                    defaultValue={chatTemplateData?.chatTemplate?.description}
                  />
                </Grid>
              </Grid>

              <Card noShadow className="mt-5">
                <Box display="flex" justifyContent="space-between">
                  <Typography color="darkLight" variant="title8" className="mb-1">
                    {t("REPLY")}:
                  </Typography>
                  <Typography color="darkMed" variant="body4" className="mb-1">
                    {messages.length}/4
                  </Typography>
                </Box>
                <MessagesManager
                  onClickAddMessage={(newMessage: string) => {
                    setIsMessageError(false);
                    handleClickAddMessage(newMessage);
                  }}
                  onDeleteMessage={handleDeleteMessage}
                  onDragEnd={handleDragEnd}
                  onEditMessage={handleEditMessage}
                  onUploadCallback={handleUploadCallback}
                  messages={messages}
                  projectId={projectId}
                  maxMessagesLength={4}
                  addNewMessages={addNewMessages}
                  updateQuickReplyMessage={updateNewQuickReplyMessage}
                />
              </Card>

              {isMessageError && (
                <div className="mt-2">
                  <ErrorText>{t("RESPONSE_MESSAGE_IS_REQUIRED")}</ErrorText>
                </div>
              )}
            </Card>
            <Box display="flex" justifyContent="flex-end">
              <Button style={{ width: 190 }} type="button" className="mt-4" onClick={handleSubmitFormModal}>
                {t("SAVE")}
              </Button>
            </Box>
          </div>
        )}
      </Modal>
      <ConfirmationModal
        title={t("ARE_YOU_SURE_TO_REMOVE")}
        isOpen={isOpenModal || isOpenConfirmModal}
        onClose={closeConfirmModal}
        onSubmit={handleSubmitDelete}
      />
      <Hidden smUp>
        {isScroll && (
          <StickyButtonContainer className="px-3 py-4 mb-2">
            <FloatingButton onClick={handleScrollToTop} data-cy="addButton">
              <SvgIcon
                className="mb-1 mt-3 ml-3"
                component={IcArrowToTop}
                fontSize="default"
                htmlColor={COLORS.DarkMed}
              />
            </FloatingButton>
          </StickyButtonContainer>
        )}
      </Hidden>
    </>
  );
};

export default ReplyShortcutPage;
