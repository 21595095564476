import { PLATFORM } from "types";

export const DEBOUNCE_TIMEOUT = 500;
export const DEFAULT_IMG = "assets/no-img-available.png";
export const DEFAULT_PAGE_START = 1;
export const DEFAULT_VALUE = "-";
export const EXPAND_CELL_WIDTH = 60;
export const LIMIT_ID_SELECTED = 20000;
export const MAX_TAGS_SEARCH = 50;
export const ROWS_PER_PAGE = 10;
export const ROWS_PER_PAGES = [ROWS_PER_PAGE, 25, 50];
export const ALL_CUSTOMER_PLATFORMS_MULTI_OPTIONS = [
  { label: "Facebook", value: PLATFORM.FACEBOOK },
  { label: "Instagram", value: PLATFORM.INSTAGRAM },
  { label: "Line", value: PLATFORM.LINE },
  { label: "Offline", value: PLATFORM.OFFLINE },
];
