import { useMutation, ApolloError } from "@apollo/client";
import { REPAY_BILLING } from "graphql/billing/mutation";
import { BillingError } from "types/Billing";

interface RepayBillingVars {
  projectId: string;
}

interface UseBillingPackageType {
  projectId: string;
  onCompletedRepay: () => void;
  onErrorRepay: (errorMessage: BillingError) => void;
}

export const useBillingPackage = ({ projectId, onCompletedRepay, onErrorRepay }: UseBillingPackageType) => {
  const [repayBilling, { loading }] = useMutation<boolean, RepayBillingVars>(REPAY_BILLING, {
    variables: {
      projectId,
    },
    onCompleted: () => {
      onCompletedRepay();
    },
    onError: (error: ApolloError) => {
      const gqlErrorMessage = error?.graphQLErrors?.[0]?.message;
      if (gqlErrorMessage in BillingError) {
        onErrorRepay(BillingError[gqlErrorMessage as keyof typeof BillingError]);
      } else {
        onErrorRepay(BillingError["BILLING:DEFAULT_ERROR"]);
      }
    },
  });

  return {
    repayBilling,
    isLoadingRepay: loading,
  };
};

export default useBillingPackage;
