import { GQLProductSKUType, ProductOptionType } from "types/Product";
import { removeTypenameField } from "utils/common";

export const getInitSKUsConvertImageObject = (productId: string, productSKUs: GQLProductSKUType[]) => {
  return productSKUs.map((productSKU) => {
    let tempOptions: ProductOptionType[] = [];
    if (productSKU.options && productSKU.options.length > 0) {
      tempOptions = removeTypenameField(productSKU.options) as ProductOptionType[];
    }

    return {
      ...productSKU,
      productId,
      nanoid: productSKU.id,
      image: productSKU.image && productSKU.image.src,
      options: tempOptions,
    };
  });
};
