import { gql } from "@apollo/client";

export const PROJECT_SETTING = gql`
  query projectSetting($projectId: ID!) {
    getProject(projectId: $projectId) {
      id
      botGender
      botTimer {
        isOpen
        timeToResume
      }
      searchProductsOrder
      configuration {
        notification {
          isActive
        }
        order {
          isMultiCODOrderWithOutDepositEnabled
          isEmailRequired
          timer {
            type
            isActive
            value
          }
          prefix {
            orderNumber
            receiptNumber
            updatedAt
          }
        }
        delivery {
          schedule {
            isActive
            start
            end
            timeInAdvance
          }
        }
        theme {
          color {
            primary
            secondary
          }
        }
        promotion {
          upselling {
            isActive
            promotionType
            settingsType
          }
        }
        botFlowBehavior {
          category {
            isHomePageWebviewCardEnabled
            isOpenWebviewWhenOverThresholdEnabled
          }
          product {
            isOpenWebviewOnSelectProductEnabled
          }
          postback {
            isProcessingPostbackEnabled
          }
        }
      }
      adminWorkingHours {
        isActive
        workingHours {
          day
          isActive
          time {
            start
            end
          }
        }
      }
      isChatbotEnabled
    }
  }
`;

export const MEMBERS = gql`
  query members($projectId: ID!) {
    members(projectId: $projectId) {
      id
      projectId
      email
      isEnableNotification
      lineName
      userId
      role
      user {
        id
        avatar
        displayName
      }
    }
  }
`;

export const INVITED_MEMBERS = gql`
  query invitedMembers($projectId: ID!) {
    members: invitedMembers(projectId: $projectId) {
      id
      projectId
      email
      isEnableNotification
      lineName
      userId
      role
      user {
        id
        avatar
        displayName
      }
    }
  }
`;
