import styled from "styled-components";

type InputSelectorWrapperPropsType = {
  showUnderline?: boolean;
};

const InputSelectorWrapper = styled.div<InputSelectorWrapperPropsType>`
  margin-left: 4px;
  border-bottom: ${({ theme, showUnderline }) => (showUnderline ? `solid 1px ${theme.COLORS.LightBlue}` : "none")};
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:focus-within {
    border-bottom: ${({ theme, showUnderline }) => (showUnderline ? `solid 1px ${theme.COLORS.Primary}` : "none")};
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
`;

export default InputSelectorWrapper;
