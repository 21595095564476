import { CSSProperties } from "react";
import { StylesConfig } from "react-select";
import { ControlStateType, CustomStyleSelectType } from "types/ReactSelect";

import Colors from "constants/Colors";

const getTagInputStyles = (isDisabled: boolean): StylesConfig & CustomStyleSelectType => {
  return {
    input: (styles: CSSProperties) => ({
      ...styles,
      display: "inline-flex",
      input: {
        whiteSpace: "pre",
        lineHeight: "1.5rem",
        textIndent: "2px",
      },
    }),
    singleValue: () => ({
      color: isDisabled ? Colors.LightBlue : "initial",
    }),
    valueContainer: (styles: CSSProperties) => ({
      ...styles,
      boxSizing: "border-box",
      minHeight: "48px",
      borderLeft: `1px solid ${Colors.LightBlue}`,
    }),
    control: (styles: CSSProperties, state: ControlStateType) => ({
      ...styles,
      width: "100%",
      backgroundColor: isDisabled ? Colors.Light : Colors.White,
      borderRadius: 5,
      border: `solid 1px ${state.isFocused ? Colors.Primary : Colors.LightBlue}`,
      color: Colors.DarkGray,
      boxShadow: "0px",
      ":hover": {
        borderColor: state.isFocused ? Colors.Primary : Colors.LightBlue,
        color: Colors.DarkLight,
      },
      ":active": {
        borderColor: Colors.Primary,
        color: Colors.DarkGray,
      },
    }),
    indicatorSeparator: (styles: CSSProperties) => ({
      ...styles,
      width: 0,
    }),
    menu: (styles: CSSProperties) => ({
      ...styles,
      width: "100%",
      borderRadius: "8px",
      border: `solid 1px ${Colors.Primary}`,
      boxShadow: "0 5px 10px 0 Colors.BoxShadow",
      zIndex: 20,
    }),
    option: (styles: CSSProperties) => {
      let backgroundColor = Colors.White;
      let color = Colors.DarkGray;

      if (isDisabled) {
        backgroundColor = Colors.Light;
        color = Colors.LightBlue;
      }

      return {
        ...styles,
        whiteSpace: "pre",
        width: "100%",
        height: "48px",
        display: "flex",
        alignItems: "center",
        backgroundColor,
        cursor: isDisabled ? "not-allowed" : "pointer",
        color,
        ":hover": {
          backgroundColor: Colors.Primary,
          color: Colors.LightBlue,
        },
      };
    },
    menuList: (styles: CSSProperties) => ({
      ...styles,
      borderRadius: "8px",
    }),
    multiValue: () => ({
      display: "flex",
      margin: "2px 2px 0 0",
    }),
    multiValueLabel: () => ({
      display: "flex",
      border: 0,
    }),
    multiValueRemove: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: 0,
      ":hover": {
        cursor: "pointer",
      },
    }),
    noOptionsMessage: (styles: CSSProperties) => ({
      ...styles,
      color: Colors.DarkLight,
    }),
    placeholder: (styles: CSSProperties): CSSProperties => ({
      ...styles,
      color: isDisabled ? Colors.LightBlue : Colors.DarkLight,
    }),
  };
};

export default getTagInputStyles;
