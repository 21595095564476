import React, { FC, useState, MouseEvent, memo } from "react";
import CircularProgress from "components/CircularProgress";
import { SvgIcon } from "components/Icon";
import { IcDownload } from "components/SvgIcons";
import { Menu, MenuItem } from "components/Menu";
import Typography, { VariantPropsType } from "components/Typography";
import { ExportFileType } from "types/SalesReport";
import { ExportButton } from "./styled";

type ExportCSVButtonProps = {
  className?: string;
  color?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  label?: string;
  subLabel?: string;
  exportedNumber?: string;
  onExport: (type: ExportFileType) => void;
  variantTitle?: VariantPropsType["variant"];
};

const ExportCSVButton: FC<ExportCSVButtonProps> = ({
  className,
  color = "darkGray",
  exportedNumber,
  isDisabled = false,
  isLoading = false,
  label,
  onExport,
  subLabel,
  variantTitle = "body4",
}) => {
  const [anchorExport, setAnchorExport] = useState<null | HTMLElement>(null);

  const handleClickExport = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorExport(event.currentTarget);
  };

  const handleCloseExport = () => {
    setAnchorExport(null);
  };

  return (
    <span className={className}>
      <ExportButton color="secondary" disabled={isDisabled} onClick={handleClickExport}>
        {isLoading ? (
          <CircularProgress size={12} className="mr-3" />
        ) : (
          <SvgIcon className="pt-1 pr-2 pb-2" component={IcDownload} fontSize="small" />
        )}
        <div className="text-left">
          <Typography color={color} variant={variantTitle}>
            {label || "Export"} {exportedNumber ? `(${exportedNumber})` : ""}
          </Typography>
          {subLabel && (
            <Typography color="darkLight" variant="body5">
              {subLabel}
            </Typography>
          )}
        </div>
      </ExportButton>

      <Menu
        getContentAnchorEl={null}
        id="export-order-menu"
        anchorEl={anchorExport}
        keepMounted
        open={Boolean(anchorExport)}
        onClose={handleCloseExport}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <MenuItem onClick={() => onExport(ExportFileType.XLSX)}>.xlsx</MenuItem>
        <MenuItem onClick={() => onExport(ExportFileType.CSV)}>.csv</MenuItem>
      </Menu>
    </span>
  );
};

export default memo(ExportCSVButton);
