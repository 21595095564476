import { MessageType } from "types/Chat";
import { QuickReplyMessage } from "types/AISetting";
import { ProjectIdType } from "./Project";

export type MessageTextValue = string;

export type MessageImageValue = {
  url: string;
};

export type ResponseMessageType = {
  type: MessageType;
  value: MessageTextValue | MessageImageValue | QuickReplyMessage[];
};

export enum BroadcastChannel {
  LINE = "LINE",
  FACEBOOK = "FACEBOOK",
}

export enum BroadcastStatus {
  DRAFT = "DRAFT",
  FAILED = "FAILED",
  SENT = "SENT",
  IN_PROGRESS = "IN_PROGRESS",
  SCHEDULED = "SCHEDULED",
}

export enum BroadcastType {
  IMMEDIATE = "IMMEDIATE",
  SCHEDULE = "SCHEDULE",
}

export enum BroadcastCriteriaOperation {
  INCLUDE = "INCLUDE",
  EXCLUDE = "EXCLUDE",
  BASE = "BASE",
}

export type BroadcastCriteriaType = {
  id?: string;
  projectId: string;
  systemCriteria?: string[];
  customCriteria?: string[];
  locationCriteria?: string[];
  operation: BroadcastCriteriaOperation;
};

export type BroadcastCampaignType = {
  id: string;
  projectId: string;
  name: string;
  channel: BroadcastChannel;
  numberOfAudience: number;
  numberOfEngagement: number;
  systemCriteria: string[];
  customCriteria: string[];
  locationCriteria: string[];
  broadcastCriteria: BroadcastCriteriaType[];
  status: BroadcastStatus;
  responseMessage: ResponseMessageType;
  broadcastDate: string;
  failReason: string;
  createdDate: string;
  updatedDate: string;
};

export type BroadcastCampaignInputType = {
  id?: string;
  projectId: string;
  name: string;
  channel: BroadcastChannel;
  responseMessage: ResponseMessageType;
  broadcastCriteria: BroadcastCriteriaType[];
  broadcastDate: string;
  type: BroadcastType;
};

export type BroadcastCampaignDataType = {
  id?: string;
  projectId: string;
  name: string;
  status?: BroadcastStatus;
  channel: BroadcastChannel;
  responseMessage: ResponseMessageType[];
  broadcastCriteria: BroadcastCriteriaType[];
  orderPaidConversionTotal?: number;
  broadcastDate: string;
  type: BroadcastType;
};

export type BroadcastCampaignFromQueryType = {
  id: string;
  projectId: string;
  name: string;
  status: BroadcastStatus;
  channel: BroadcastChannel;
  responseMessage: ResponseMessageType[];
  broadcastCriteria: BroadcastCriteriaType[];
  numberOfAudience: string | object | string[] | object[]; // not number type, because in table component is require type
  broadcastDate: string;
  failReason: string;
  createdAt: string;
  updatedAt: string;
  numberOfEngagement: string | object | string[] | object[]; // not number type, because in table component is require type
};

export enum CriteriaType {
  SYSTEM_TAGS = "SYSTEM_TAGS",
  CUSTOM_TAGS = "CUSTOM_TAGS",
  LOCATION = "LOCATION",
}

export enum RecurringNotificationOptInDeliveryContextType {
  WELCOME_MESSAGE_SENT = "WELCOME_MESSAGE_SENT",
  PAYMENT_VERIFIED = "PAYMENT_VERIFIED",
  TRACKING_NUMBER_SENT = "TRACKING_NUMBER_SENT",
}

export enum RecurringNotificationOptInFrequencyType {
  DAILY = "DAILY",
  // Facebook api not support (9-Feb-2023)
  // WEEKLY = "WEEKLY",
  // MONTHLY = "MONTHLY",
}

export enum RecurringNotificationOptInButtonMessageType {
  ALLOW = "ALLOW",
  GET = "GET",
  FREQUENCY = "FREQUENCY",
  OPT_IN = "OPT_IN",
  SIGN_UP = "SIGN_UP",
}

export type RecurringNotificationOptInsType = {
  id: string;
  projectId: string;
  name: string;
  broadcastStatus: BroadcastStatus;
  deliveryContextType: RecurringNotificationOptInDeliveryContextType;
  messageTitle: string;
  messageImageUrl: string;
  frequencyType: RecurringNotificationOptInFrequencyType;
  buttonMessageType: RecurringNotificationOptInButtonMessageType;
  isAutoRenew: boolean;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  numberOfSubscribers: number;
  reactionMessage?: string | null;
  invitationMessage?: string | null;
};

export type RecurringNotificationOptInsResponseType = {
  recurringNotificationOptIns: RecurringNotificationOptInsType[];
};

export type RecurringNotificationOptInInput = ProjectIdType & {
  projectId: string;
  name: string;
  deliveryContextType: RecurringNotificationOptInDeliveryContextType;
  messageTitle: string;
  messageImageUrl?: string | null;
  frequencyType: RecurringNotificationOptInFrequencyType;
  buttonMessageType: RecurringNotificationOptInButtonMessageType;
  isAutoRenew: boolean;
  isActive?: boolean | null;
  reactionMessage?: string | null;
  invitationMessage?: string | null;
};

export type CreateRecurringNotificationOptInInputVariables = ProjectIdType & {
  recurringNotificationOptIn: RecurringNotificationOptInInput;
};

export type UpdateRecurringNotificationOptInInputVariables = CreateRecurringNotificationOptInInputVariables & {
  recurringNotificationOptInId: string;
};

export type RecurringNotificationOptInsCampaignParamsType = {
  projectId: string;
  recurringNotificationOptInId: string;
};
