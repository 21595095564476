import moment from "moment";

import { CUSTOMER_RETENTION_STATUS } from "constants/Broadcast";
import { MessageType } from "types/Chat";
import {
  BroadcastCampaignDataType,
  BroadcastChannel,
  BroadcastCriteriaOperation,
  BroadcastType,
} from "types/Broadcast";
import type { RecurringNotificationOptInsType } from "types/Broadcast";

export const convertDataToOptionFormat = (originalData: string[]) => {
  if (originalData && originalData.length) {
    return originalData.map((data: string) => ({
      label: data,
      value: data,
    }));
  }

  return [];
};

export const convertDataSystemTagsToOptionFormat = (originalData: string[]) => {
  if (originalData && originalData.length) {
    return originalData.map((data: string) => ({
      label: CUSTOMER_RETENTION_STATUS[data],
      value: data,
    }));
  }
  return [];
};

export const getInitialBroadcastCampaignData = (projectId: string): BroadcastCampaignDataType => {
  return {
    projectId,
    name: "",
    channel: BroadcastChannel.FACEBOOK,
    responseMessage: [
      {
        type: MessageType.QUICK_REPLIES,
        value: [],
      },
    ],
    broadcastCriteria: [
      {
        projectId,
        systemCriteria: [],
        customCriteria: [],
        locationCriteria: [],
        operation: BroadcastCriteriaOperation.BASE,
      },
    ],
    broadcastDate: moment().toISOString(),
    type: BroadcastType.IMMEDIATE,
  };
};

export const getSubscriberNumber = (
  data: RecurringNotificationOptInsType[],
  recurringNotificationOptInId: string,
): number => {
  return data.find(({ id }) => id === recurringNotificationOptInId)?.numberOfSubscribers ?? 0;
};

export const getBroadcastName = (
  data: RecurringNotificationOptInsType[],
  recurringNotificationOptInId: string,
): string => {
  return data.find(({ id }) => id === recurringNotificationOptInId)?.name ?? "";
};
