import React, { FC } from "react";
import Typography from "components/Typography";
import styled from "styled-components";

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 60px;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.COLORS.Primary};
  padding: 16px;
  box-sizing: border-box;
`;

const Title: FC = ({ children }) => {
  return (
    <TitleWrapper>
      <Typography variant="title7" color="white">
        {children}
      </Typography>
    </TitleWrapper>
  );
};

export default Title;
