import { useCallback, useState, useEffect } from "react";

import scrollToElementId from "utils/common/scrollToElementId";
import nanoid from "utils/nanoid";

const DEFAULT_SIZE_SCROLL = 250;

const useScrollToTopElement = (prevId: string, isDesktop?: boolean) => {
  const [scrollTopId, setScrollTopId] = useState<string>();
  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    const newId = prevId + nanoid();
    setScrollTopId(newId);
  }, [prevId, setScrollTopId]);

  const handleScrollHight = useCallback((scrollPage?: Element) => {
    if (!scrollPage) return;

    const { scrollTop } = scrollPage;

    if (scrollTop > DEFAULT_SIZE_SCROLL) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  }, []);

  useEffect(() => {
    const scrollPage = document.querySelector("#pageMainContainer > div");

    if (!isDesktop) {
      scrollPage?.addEventListener(
        "scroll",
        () => {
          handleScrollHight(scrollPage);
        },
        false,
      );
    }

    return () => {
      scrollPage?.removeEventListener(
        "scroll",
        () => {
          handleScrollHight(scrollPage);
        },
        false,
      );
    };
  }, [handleScrollHight, isDesktop, isScroll]);

  const handleScrollToTop = useCallback(() => {
    if (scrollTopId) {
      scrollToElementId(scrollTopId);

      return;
    }

    const newId = prevId + nanoid();

    setScrollTopId(newId);

    setTimeout(() => {
      scrollToElementId(newId);
    }, 200);
  }, [prevId, scrollTopId]);

  return {
    scrollTopId,
    handleScrollToTop,
    isScroll,
  };
};

export default useScrollToTopElement;
