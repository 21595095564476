import * as React from "react";

const SvgIcAddphoto = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.57 3.556a4.03 4.03 0 012.884 1.182 4.156 4.156 0 011.182 2.884v10.554a6.394 6.394 0 013.576 1.702A6.1 6.1 0 0132 24.204a6.1 6.1 0 01-6.114 6.113c-1.47 0-2.797-.548-3.864-1.413H5.844a4.03 4.03 0 01-2.884-1.182 4.152 4.152 0 01-1.182-2.884V7.622A4.03 4.03 0 012.96 4.738a4.152 4.152 0 012.884-1.182zm3.288 16.12c-1.27 0-2.394.49-3.23 1.327-.808.836-1.327 1.96-1.327 3.23 0 .317.029.634.087.922.028.116.086.26.115.375.029.173.086.346.144.49.058.116.116.202.173.318.087.173.173.346.289.49l.26.288c.114.116.23.26.345.375a4.549 4.549 0 003.144 1.269c1.268 0 2.393-.49 3.23-1.327.807-.836 1.326-1.96 1.326-3.2 0-1.27-.49-2.423-1.327-3.23-.836-.808-1.96-1.327-3.23-1.327zm-.03 1.211c.433 0 .78.346.78.779v1.759h1.758c.433 0 .779.346.779.779a.775.775 0 01-.779.778h-1.759v1.76a.775.775 0 01-.778.778.775.775 0 01-.779-.779v-1.759h-1.76a.775.775 0 01-.778-.778c0-.433.346-.78.779-.78h1.759v-1.758c0-.433.346-.779.779-.779zm-5.507-6.892l-6.431 6.46c-.317.288-.808.288-1.096 0L8.439 16.1l-5.133 5.133v3.605c0 .692.289 1.327.721 1.788a2.53 2.53 0 001.788.75v.029h14.823c-.144-.202-.26-.462-.375-.693v-.028a10.094 10.094 0 01-.288-.866c-.03-.115-.058-.201-.087-.317a6.34 6.34 0 01-.144-1.269 6.1 6.1 0 011.788-4.325 3.48 3.48 0 01.692-.606l.144-.086c.087-.058.144-.087.23-.145l.433-.26c.087-.057.173-.086.289-.143a.863.863 0 01.23-.116c.116-.029.231-.086.347-.115a2.56 2.56 0 01.374-.115l.26-.087-4.21-4.24zm2.249-8.911H5.844a2.53 2.53 0 00-1.788.75 2.53 2.53 0 00-.75 1.788V19.07l4.557-4.614a.774.774 0 011.095 0l4.355 4.384 6.43-6.46a.774.774 0 011.097 0l4.268 4.24V7.621a2.53 2.53 0 00-.75-1.788 2.53 2.53 0 00-1.788-.75zm-9.401 5.075a2.076 2.076 0 110 4.153 2.076 2.076 0 010-4.153z"
    />
  </svg>
);

export default SvgIcAddphoto;
