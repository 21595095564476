import { useState } from "react";
import { useQuery } from "@apollo/client";
import debounce from "lodash/debounce";
import pick from "lodash/pick";

import { ALL_CATEGORY } from "graphql/category/query";
import { CategoryType, ChangedDataValueType, CategoryConnectionType, EditedCategoriesType } from "types/Category";
import nanoid from "utils/nanoid";
import scrollToElementId from "utils/common/scrollToElementId";

const NEW_CATEGORY_ID_PREFIX = "new-";

const useChangeCategories = (projectId: string) => {
  const [editedCategories, setEditedCategories] = useState<EditedCategoriesType[]>([]);
  const [newCategory, setNewCategory] = useState<CategoryType>();

  const { loading, data } = useQuery<CategoryConnectionType>(ALL_CATEGORY, {
    variables: {
      projectId,
    },
  });

  const handleChange = debounce((id: string, fieldName: keyof CategoryType, value: ChangedDataValueType) => {
    const isNewCategory = id.includes(NEW_CATEGORY_ID_PREFIX);
    const computedValue = typeof value === "string" ? value.trim() : value;

    if (isNewCategory && newCategory) {
      const editedNewCategory: CategoryType = {
        ...newCategory,
        [fieldName]: computedValue,
      };

      setNewCategory(editedNewCategory);
    } else {
      let editedCategory = editedCategories.find((category) => category.id === id);
      if (!editedCategory && data) {
        const focusedCategory = data.categories.results.find((category) => category.id === id);
        editedCategory = pick(focusedCategory, ["id", "name"]) as EditedCategoriesType;
      }

      const filteredChangedCategories = editedCategories.filter((category) => category.id !== id);
      const computedEditedCategory = {
        ...editedCategory,
        [fieldName]: computedValue,
      } as EditedCategoriesType;
      const newTempCategories = [...filteredChangedCategories, computedEditedCategory];

      setEditedCategories(newTempCategories);
    }
  }, 500);

  const handleClearEditedCategory = (id: string) => {
    const newEditedCategories = editedCategories.filter((editedCategory) => editedCategory.id !== id);
    setEditedCategories(newEditedCategories);
  };

  const handleAddNewCategory = () => {
    if (Array.isArray(newCategory) && newCategory.length) {
      scrollToElementId(newCategory[0].id);

      return;
    }

    const newId = NEW_CATEGORY_ID_PREFIX + nanoid();
    const initCategory = {
      id: newId,
      image: "",
      name: "",
      isActive: true,
      projectId,
      properties: [],
      variations: [],
    };

    setNewCategory(initCategory);

    // This is the easiest way for UX when create new item don't forget to fix it later
    setTimeout(() => {
      scrollToElementId(newId);
    }, 200);
  };

  const handleClearNewCategory = () => {
    setNewCategory(undefined);
  };

  let categoriesAmount = 0;
  if (data) {
    const {
      categories: { total },
    } = data;
    categoriesAmount = total + (newCategory ? 1 : 0);
  }

  const categories: CategoryType[] = data?.categories?.results || [];
  const hasAllCategoryCard = categories.some(({ isHomePageCategory }) => isHomePageCategory);

  return {
    categories,
    categoriesAmount,
    editedCategories,
    handleAddNewCategory,
    handleChange,
    handleClearEditedCategory,
    handleClearNewCategory,
    loading,
    newCategory,
    hasAllCategoryCard,
  };
};

export default useChangeCategories;
