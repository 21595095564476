import * as React from "react";

const SvgIcDoingPayment = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24 17.778a6.222 6.222 0 110 12.444 6.222 6.222 0 010-12.444zm0 1.778a4.444 4.444 0 100 8.888 4.444 4.444 0 000-8.888zm3.556-14.223c1.41 0 2.574 1.11 2.66 2.499l.006.168v10.972a8.044 8.044 0 00-1.777-1.624V16H3.554v8.389a.5.5 0 00.5.5H16.05c.068.617.206 1.212.406 1.778H4.445a2.675 2.675 0 01-2.662-2.499L1.778 24V8c0-1.41 1.11-2.574 2.498-2.661l.168-.006h23.112zm0 1.778H4.444A.9.9 0 003.556 8v2.667h24.888V8a.9.9 0 00-.888-.889zm-3.092 14.222a.444.444 0 00-.889 0v3.042l.174.352 1.67 1.285a.444.444 0 10.543-.705l-1.498-1.15v-2.824z"
    />
  </svg>
);

export default SvgIcDoingPayment;
