const termAndCondition = {
  en: {
    "termAndCondition.title": "Terms of service",
    "scopeOfService.title": "Scope of Services",
  },
  th: {
    "termAndCondition.title": "Terms of service",
    "scopeOfService.title": "Scope of Services",
  },
};

export default termAndCondition;
