import { gql } from "@apollo/client";

export const SAVE_PROJECT_SETTING = gql`
  mutation projectSetting($projectId: ID!, $projectInput: ProjectInput!) {
    updateProject(projectId: $projectId, projectInput: $projectInput) {
      id
      botGender
      botTimer {
        isOpen
        timeToResume
      }
      searchProductsOrder
      configuration {
        notification {
          isActive
        }
        order {
          isMultiCODOrderWithOutDepositEnabled
          timer {
            type
            isActive
            value
          }
          prefix {
            orderNumber
            receiptNumber
            updatedAt
          }
        }
        delivery {
          schedule {
            isActive
            start
            end
            timeInAdvance
          }
        }
        theme {
          color {
            primary
            secondary
          }
        }
        promotion {
          upselling {
            isActive
            promotionType
            settingsType
          }
        }
        botFlowBehavior {
          category {
            isHomePageWebviewCardEnabled
            isOpenWebviewWhenOverThresholdEnabled
          }
          product {
            isOpenWebviewOnSelectProductEnabled
          }
          postback {
            isProcessingPostbackEnabled
          }
        }
      }
      adminWorkingHours {
        isActive
        workingHours {
          day
          isActive
          time {
            start
            end
          }
        }
      }
      isChatbotEnabled
    }
  }
`;

export const INVITE_MEMBER = gql`
  mutation inviteMember($projectId: ID!, $email: String!, $role: MemberRole!) {
    inviteMember(projectId: $projectId, email: $email, role: $role) {
      id
      projectId
      email
      isEnableNotification
      lineName
      userId
      role
      user {
        id
        avatar
        displayName
      }
    }
  }
`;

export const CANCEL_INVITED_MEMBER = gql`
  mutation cancelInvitedMember($projectId: ID!, $email: String!) {
    cancelInvitedMember(projectId: $projectId, email: $email) {
      id
      projectId
      email
      isEnableNotification
      lineName
      userId
      role
      user {
        id
        avatar
        displayName
      }
    }
  }
`;

export const EDIT_MEMBER = gql`
  mutation editMemberRole($projectId: ID!, $id: ID!, $role: MemberRole!) {
    editMemberRole(id: $id, projectId: $projectId, role: $role) {
      id
      email
      role
    }
  }
`;

export const DELETE_MEMBER = gql`
  mutation deleteMember($projectId: ID!, $id: ID!) {
    deleteMember(id: $id, projectId: $projectId) {
      id
      projectId
      email
      role
    }
  }
`;

export const REMOVE_LINE_NOTIFICATION = gql`
  mutation removeLineNotification($projectId: ID!, $permissionId: ID!) {
    removeLineNotification(projectId: $projectId, permissionId: $permissionId) {
      isEnableNotification
    }
  }
`;

export const GENERATE_NOTIFICATION_KEY = gql`
  mutation generateOTP($projectId: ID!, $userId: ID!) {
    generateOTP(projectId: $projectId, userId: $userId) {
      OTP
      projectId
      userId
    }
  }
`;
