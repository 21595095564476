import InputAdornment from "@material-ui/core/InputAdornment";
import React, { FC, ChangeEventHandler } from "react";
import { useController, UseControllerOptions } from "react-hook-form";
import { NumberFormatCustomWithoutPrefix } from "components/NumberFormatCustom";
import TextField from "components/TextField";
import { StringDecoder } from "string_decoder";

const TextFieldWithUnit: FC<
  UseControllerOptions & {
    onChange?: ChangeEventHandler;
    isReadOnly?: boolean;
    unit: StringDecoder;
    hasError?: boolean;
  }
> = (props) => {
  const { onChange, hasError = false, unit, ...rest } = props;
  const { field } = useController(props);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    field.onChange(event.target.value);

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <TextField
      inputRef={field.ref}
      onChange={handleChange}
      InputProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: NumberFormatCustomWithoutPrefix as any,
        endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
      }}
      error={hasError}
      fullWidth
      variant="outlined"
      {...rest}
    />
  );
};

export default TextFieldWithUnit;
