import styled from "styled-components";

import Grid from "components/Grid";
import Button from "components/Button";

export const ContentWrapper = styled(Grid)`
  margin-top: 8px;
  padding: 0 12px;
`;

export const DayButton = styled(Button)`
  width: 45px;
  height: 36px;
  .MuiButton-label {
    font-size: 14px;
    font-weight: 600;
  }
  margin: 1px;
`;

export const InfoWrapper = styled.div`
  padding: 16px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.COLORS.Light};
  display: flex;
`;
