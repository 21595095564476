import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";
import upperFirst from "lodash/upperFirst";

const Span = styled.span<HTMLAttributes<HTMLSpanElement>>`
  color: ${({ theme }) => theme.COLORS.Error};
  white-space: pre-wrap;
  line-height: 1.5;
`;

const ErrorText: FC<HTMLAttributes<HTMLSpanElement>> = (props) => {
  const { children, className } = props;
  return <Span className={className}>{typeof children === "string" && upperFirst(children)}</Span>;
};

export default ErrorText;
