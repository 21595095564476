import { ProductOptionChoiceType, ProductOptionType, STATE_TAB } from "types/Product";

export const INITIAL_CHOICE: ProductOptionChoiceType = {
  name: "",
  price: 0,
  isActive: true,
  isDefault: false,
  isSelected: false,
};

export const INITIAL_OPTION: ProductOptionType = {
  name: "",
  isMultipleChoices: false,
  isRequired: true,
  maximum: 1,
  isActive: true,
  choices: [INITIAL_CHOICE],
};

export const MAXIMUM_DIMENSION = 9999.99;
export const PRODUCT_TABS = [STATE_TAB.PRODUCT_DETAILS, STATE_TAB.CONTENT, STATE_TAB.INVENTORY];
export const FOOD_PRODUCT_TABS = [STATE_TAB.FOOD_PRODUCT_DETAILS, STATE_TAB.VARIATIONS];
export const SKU_PRODUCT_TABS = [STATE_TAB.PRODUCT_DETAILS, STATE_TAB.SKU_PRODUCT_DETAILS, STATE_TAB.VARIATIONS];
export const INIT_TAB_VALUE = 0;

export const REGEX_PRODUCT_CODE_SUPPORT_CHARACTER = /^([a-zA-Z\u0E01-\u0E7F0-9][a-zA-Z\u0E01-\u0E7F0-9_-]+[a-zA-Z\u0E01-\u0E7F0-9])$/;
export const REGEX_CF_CODE_SUPPORT_CHARACTER = /^([a-zA-Z\u0E01-\u0E2E][a-zA-Z\u0E01-\u0E2E0-9_-]+[a-zA-Z\u0E01-\u0E2E0-9])$/;
