import { gql } from "@apollo/client";

export const SEARCH_OFFLINE_CUSTOMER = gql`
  query searchOfflineCustomers($projectId: ID!, $query: String!) {
    searchOfflineCustomers(projectId: $projectId, query: $query) {
      customerId
      uuid
      projectId
      platform
      name
      address {
        firstName
        lastName
        streetAddress
        streetAddress2
        district
        subDistrict
        province
        postalCode
        phoneNumber
        email
      }
    }
  }
`;
