import React, { useState, useContext, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import { useForm, FormProvider } from "react-hook-form";

import Button from "components/Button";
import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import Modal from "components/Modal";
import Typography from "components/Typography";

import ConfirmModal from "components/Modal/ConfirmModal";
import nanoid from "utils/nanoid";
import { GuidelineContext } from "utils/context";
import StickyPanel from "components/StickyPanel";

import {
  BANK_ID_PREFIX,
  COD_ID_KEY,
  COD_PREFIX,
  COD_THRESHOLD_KEY,
  NEW_BANK_ID_PREFIX,
  PAYMENT_GATEWAY_ID_KEY,
  PAYMENT_GATEWAY_PREFIX,
  PAYMENT_TYPES,
  PROMPT_PAY_CUSTOM_QR_IMAGE,
  PROMPT_PAY_ID_KEY,
  PROMPT_PAY_PREFIX,
  SECOND_PAYMENT_GATEWAY_PREFIX,
  SECOND_PAYMENT_GATEWAY_ID_KEY,
} from "constants/Payment";

import { PAYMENTS } from "graphql/payment/query";
import { ProjectIdType } from "types/Project";
import {
  BankAccountPaymentType,
  NewBankAccountPaymentType,
  PaymentGatewayChillPayType,
  PaymentGateway2C2PType,
  PaymentFormDataType,
  PaymentType,
  PaymentGatewayCustom,
} from "types/Payment";
import useGetProject from "utils/hooks/useGetProject";

import useIsDesktop from "utils/hooks/useIsDesktop";
import PaymentCard from "domain/Payment/PaymentCard";
import CODForm from "domain/Payment/InputForm/CODForm";
import getPaymentSchema from "domain/Payment/validateSchema";
import useDeleteBankPayment from "domain/Payment/hooks/useDeleteBankPayment";
import useSavePayment from "domain/Payment/hooks/useSavePayment";
import { PaymentCardWrapper } from "domain/Payment/styled";

function CashOnDelivery() {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const { setGuidelineCompletedStepCount } = useContext(GuidelineContext);
  const { isBankTransferEnabled = true, isAllowApproveBySlip, isDeeplePayEnabled } = useGetProject(projectId);
  const isDesktop = useIsDesktop();

  const [isShowSaveButton, setIsShowSaveButton] = useState(false);
  const [isFirstTimeGetQueryData, setIsFirstTimeGetQueryData] = useState(false);
  const [editedBankPaymentIds, setEditedBankPaymentIds] = useState<string[]>([]);
  const [newBankPayments, setNewBankPayments] = useState<NewBankAccountPaymentType[]>([]);
  const [focusedBankPayment] = useState<string>();
  const [editingPaymentGatewayIndex] = useState<0 | 1 | 2>(0);
  const [newPaymentGateway, setNewPaymentGateway] = useState<PaymentFormDataType | undefined>();
  const [newSecondPaymentGateway, setNewSecondPaymentGateway] = useState<PaymentFormDataType | undefined>();

  const methods = useForm({
    resolver: yupResolver(
      getPaymentSchema(newBankPayments, editedBankPaymentIds, isBankTransferEnabled || isAllowApproveBySlip),
    ),
  });

  const { register, handleSubmit, setValue, clearErrors } = methods;

  const { loading, data } = useQuery(PAYMENTS, {
    variables: {
      projectId,
    },
  });

  const { handleConfirmDelete, isOpenConfirmDeleteModal, closeConfirmModal } = useDeleteBankPayment(projectId);

  const showSaveButton = useCallback(() => {
    if (!isShowSaveButton) {
      setIsShowSaveButton(true);
    }
  }, [isShowSaveButton]);

  const handleChangeBankPayment = (id: string, fieldName: string, value: string) => {
    const validateKey = `${id}${fieldName}`;

    const regex = new RegExp(`^${BANK_ID_PREFIX}.*`, "i");
    const isExistingBankPayment = regex.test(id);

    if (isExistingBankPayment) {
      const newEditedBankPaymentIds = Array.from(new Set([...editedBankPaymentIds, id]));

      setEditedBankPaymentIds(newEditedBankPaymentIds);
    }

    if (fieldName && value) {
      register({ name: validateKey });
      setValue(validateKey, value);

      if (["BankName", "BankAccountType"].includes(fieldName)) {
        clearErrors(validateKey);
      }
    }
  };

  const handleChangeCOD = (fieldName: string, value: string) => {
    const validateKey = `${COD_PREFIX}${fieldName}`;

    register({ name: validateKey });
    setValue(validateKey, value);
  };

  const handleChangeIsActive = (paymentId: string, isActive: boolean) => {
    register({ name: `${paymentId}IsActive` });
    setValue(`${paymentId}IsActive`, isActive);

    const regex = new RegExp(`^${BANK_ID_PREFIX}.*`, "i");
    const isExistingBankPayment = regex.test(paymentId);

    if (isExistingBankPayment) {
      const newEditedBankPaymentIds = Array.from(new Set([...editedBankPaymentIds, paymentId]));

      setEditedBankPaymentIds(newEditedBankPaymentIds);
    }

    showSaveButton();
  };

  useEffect(() => {
    register({ name: PROMPT_PAY_CUSTOM_QR_IMAGE });
  }, [register]);

  useEffect(() => {
    if (editingPaymentGatewayIndex === 0 && newSecondPaymentGateway && newSecondPaymentGateway.merchantId === "") {
      setNewSecondPaymentGateway(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingPaymentGatewayIndex]);

  useEffect(() => {
    if (data) {
      const [paymentGateway1, paymentGateway2] = data.payments.filter(
        ({ type }: PaymentType) =>
          type !== PAYMENT_TYPES.BANK_ACCOUNT &&
          type !== PAYMENT_TYPES.PROMPT_PAY &&
          type !== PAYMENT_TYPES.CASH_ON_DELIVERY,
      );

      const isPaymentGatewayActive = get(paymentGateway1, "isActive", false);
      handleChangeIsActive(PAYMENT_GATEWAY_PREFIX, isPaymentGatewayActive);

      const paymentGatewayId = get(paymentGateway, "id");
      if (paymentGatewayId) {
        register({ name: PAYMENT_GATEWAY_ID_KEY });
        setValue(PAYMENT_GATEWAY_ID_KEY, paymentGatewayId);
      }

      // Second payment gateway
      const isSecondPaymentGatewayActive = get(paymentGateway2, "isActive", false);
      handleChangeIsActive(SECOND_PAYMENT_GATEWAY_PREFIX, isSecondPaymentGatewayActive);

      const secondPaymentGatewayId = get(secondPaymentGateway, "id");
      if (secondPaymentGatewayId) {
        register({ name: SECOND_PAYMENT_GATEWAY_ID_KEY });
        setValue(SECOND_PAYMENT_GATEWAY_ID_KEY, secondPaymentGatewayId);
      }
    }
    setIsShowSaveButton(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const {
    handleSavePayment: onSavePayment,
    isOpenWarningActivePaymentModal,
    closeWarningActivePaymentModal,
  } = useSavePayment({
    projectId,
    onComplete: () => {
      setIsFirstTimeGetQueryData(false);
      setIsShowSaveButton(false);
      setGuidelineCompletedStepCount(projectId);
      setNewPaymentGateway(undefined);
      setNewSecondPaymentGateway(undefined);
      setEditedBankPaymentIds([]);
      setNewBankPayments([]);
    },
    onChangeIsActive: handleChangeIsActive,
    onChangeBankPayment: handleChangeBankPayment,
  });

  if (loading) {
    return (
      <Modal isOpen onClose={() => {}}>
        <CircularProgress className="m-4" />
      </Modal>
    );
  }

  let bankAccountPayments = [];
  let promptPayPayment = null;
  let codPayment = null;
  let paymentGateway: PaymentGatewayChillPayType | PaymentGateway2C2PType | PaymentGatewayCustom | null | false = null;
  let secondPaymentGateway:
    | PaymentGatewayChillPayType
    | PaymentGateway2C2PType
    | PaymentGatewayCustom
    | null
    | false = null;
  if (data && data.payments) {
    const groupPayments = groupBy(data.payments, "type");

    bankAccountPayments = groupPayments[PAYMENT_TYPES.BANK_ACCOUNT] || [];
    promptPayPayment = groupPayments[PAYMENT_TYPES.PROMPT_PAY] && groupPayments[PAYMENT_TYPES.PROMPT_PAY][0];
    codPayment = groupPayments[PAYMENT_TYPES.CASH_ON_DELIVERY] && groupPayments[PAYMENT_TYPES.CASH_ON_DELIVERY][0];
    const [paymentGateway1, paymentGateway2] = data.payments.filter(
      ({ type }: PaymentType) =>
        type !== PAYMENT_TYPES.BANK_ACCOUNT &&
        type !== PAYMENT_TYPES.PROMPT_PAY &&
        type !== PAYMENT_TYPES.CASH_ON_DELIVERY,
    );
    paymentGateway = paymentGateway1;
    secondPaymentGateway = paymentGateway2;
  }

  const bankAccountPaymentAmount = bankAccountPayments.length + newBankPayments.length;

  const handleAddNewBankPayment = () => {
    const isNewBankActive = !!bankAccountPaymentAmount;

    const initBankPayment = {
      id: NEW_BANK_ID_PREFIX + nanoid(),
      isActive: isNewBankActive,
      type: PAYMENT_TYPES.BANK_ACCOUNT,
    };
    const combinedBankPayments = [...newBankPayments, initBankPayment];

    handleChangeIsActive(initBankPayment.id, isNewBankActive);
    setNewBankPayments(combinedBankPayments);
  };

  const handleSavePayment = (formData: PaymentFormDataType) => {
    onSavePayment(
      formData,
      paymentGateway,
      secondPaymentGateway,
      newBankPayments,
      editedBankPaymentIds,
      newPaymentGateway,
      newSecondPaymentGateway,
      isDeeplePayEnabled,
    );

    // eslint-disable-next-line
    if ((formData[COD_THRESHOLD_KEY] as string) == "-0") {
      setValue(COD_THRESHOLD_KEY, 0);
    }
  };

  if (!isFirstTimeGetQueryData && data) {
    if (bankAccountPayments && !bankAccountPayments.length) {
      handleAddNewBankPayment();
    } else if (bankAccountPayments) {
      bankAccountPayments.forEach(({ id, isActive, bankName, bankAccountType }: BankAccountPaymentType) => {
        const bankIdWithPrefix = `${BANK_ID_PREFIX}${id}`;

        handleChangeIsActive(bankIdWithPrefix, isActive);
        handleChangeBankPayment(bankIdWithPrefix, "BankName", bankName);
        handleChangeBankPayment(bankIdWithPrefix, "BankAccountType", bankAccountType);
      });
    }

    // Promptpay
    const isPromptPayActive = get(promptPayPayment, "isActive", false);
    handleChangeIsActive(PROMPT_PAY_PREFIX, isPromptPayActive);

    const promptpayId = get(promptPayPayment, "id");
    if (promptpayId) {
      register({ name: PROMPT_PAY_ID_KEY });
      setValue(PROMPT_PAY_ID_KEY, promptpayId);
    }

    // COD
    const isCODActive = get(codPayment, "isActive", false);
    handleChangeIsActive(COD_PREFIX, isCODActive);

    if (codPayment) {
      const { id, shippingMethod, depositType, managementFee } = codPayment;
      const managementFeeType = managementFee?.type;

      register({ name: COD_ID_KEY });
      setValue(COD_ID_KEY, id);

      handleChangeCOD("ShippingMethod", shippingMethod);
      handleChangeCOD("DepositType", depositType);
      handleChangeCOD("ManagementFeeType", managementFeeType);
    }

    // Payment gateway
    const isPaymentGatewayActive = get(paymentGateway, "isActive", false);
    handleChangeIsActive(PAYMENT_GATEWAY_PREFIX, isPaymentGatewayActive);

    const paymentGatewayId = get(paymentGateway, "id");
    if (paymentGatewayId) {
      register({ name: PAYMENT_GATEWAY_ID_KEY });
      setValue(PAYMENT_GATEWAY_ID_KEY, paymentGatewayId);
    }

    // Second payment gateway
    const isSecondPaymentGatewayActive = get(secondPaymentGateway, "isActive", false);
    handleChangeIsActive(SECOND_PAYMENT_GATEWAY_PREFIX, isSecondPaymentGatewayActive);

    const secondPaymentGatewayId = get(secondPaymentGateway, "id");
    if (secondPaymentGatewayId) {
      register({ name: SECOND_PAYMENT_GATEWAY_ID_KEY });
      setValue(SECOND_PAYMENT_GATEWAY_ID_KEY, secondPaymentGatewayId);
    }

    setIsFirstTimeGetQueryData(true);
    setIsShowSaveButton(false);
    setEditedBankPaymentIds([]);
  }

  const getWrapperClassName = () => {
    if (!isDesktop) {
      return isShowSaveButton ? "pb-5" : "";
    }

    return "p-5";
  };

  return (
    <Grid container className={getWrapperClassName()}>
      {data && (
        <FormProvider {...methods}>
          {/* CASH_ON_DELIVERY */}
          <PaymentCardWrapper style={{ background: "none" }} item xs={12}>
            <Typography variant="title2" color="dark" className="pb-3">
              {t("Cash on Delivery (COD)")}
            </Typography>
            <PaymentCard
              isActive={get(codPayment, "isActive", false)}
              onChangeIsActive={(value: boolean) => handleChangeIsActive(COD_PREFIX, value)}
            >
              <CODForm
                shippingMethod={get(codPayment, "shippingMethod")}
                depositThreshold={get(codPayment, "depositThreshold")}
                depositType={get(codPayment, "depositType")}
                depositAmount={get(codPayment, "depositAmount")}
                managementFee={get(codPayment, "managementFee")}
                onChange={(fieldName: string, value: string) => handleChangeCOD(fieldName, value)}
                showSaveButton={showSaveButton}
              />
            </PaymentCard>
            <div className="mt-4" />
            <div className="d-none d-lg-block">
              <Button fullWidth disabled={isShowSaveButton} onClick={handleSubmit(handleSavePayment)}>
                {t("Save")}
              </Button>
            </div>
            <div className="d-block d-lg-none pt-4">
              <StickyPanel>
                <Grid item xs={12} className="m-3">
                  <Button fullWidth onClick={handleSubmit(handleSavePayment)}>
                    {t("Save")}
                  </Button>
                </Grid>
              </StickyPanel>
            </div>
          </PaymentCardWrapper>
        </FormProvider>
      )}
      <Modal isOpen={isOpenWarningActivePaymentModal} onClose={closeWarningActivePaymentModal}>
        <Grid container justify="center" className="p-3">
          <Grid item xs={8} className="text-center py-3">
            <Typography variant="title8" color="dark">
              {t("Please active at least 1 payment method except COD")}
            </Typography>
          </Grid>
          <Grid item xs={12} className="pt-1 text-center">
            <Button size="small" color="ghost" fullWidth onClick={closeWarningActivePaymentModal}>
              {t("OK")}
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <ConfirmModal
        title={t("Are you sure you want to remove this payment?")}
        confirmButtonText={t("Confirm")}
        isOpen={isOpenConfirmDeleteModal}
        onClose={closeConfirmModal}
        onClickConfirmButton={() => handleConfirmDelete(focusedBankPayment as string)}
      />
    </Grid>
  );
}

export default CashOnDelivery;
