import React, { FC, useCallback, useState } from "react";
import get from "lodash/get";
import moment from "moment";

import { useTranslation } from "react-i18next";
import { OptionType, ValueType, components, OptionProps, SingleValueProps, GroupType } from "react-select";

import Grid from "components/Grid";
import Typography from "components/Typography";
import TextField from "components/TextField";
import Switch from "components/Switch";

import SelectWithAsyncPaginate from "components/SelectWithAsyncPaginate";

import TagInputWithLabel from "components/TagInputWithAutoSuggest";
import Card from "components/Card";
import { RadioBox } from "components/Radio";

import { MAXIMUM_BROADCAST_NAME_STRING_LENGTH } from "config";
import { AddCommentCrawlerInput, CrawlerPlatform, CrawlerType } from "types/FacebookCrawler";
import { NO_IMG_AVAILABLE } from "constants/Image";
import { DATE_FORMAT } from "constants/DateTimeFormat";
import CircularProgress from "components/CircularProgress";

import { getCommentCrawlerContentEmbedLink } from "utils/common";
import { useFetchFacebookPost } from "../hooks/useFetchFacebookPost";
import { useFetchFacebookScheduledPost } from "../hooks/useFetchFacebookScheduledPost";
import { useFetchFacebookAdsPost } from "../hooks/useFetchFacebookAdsPost";
import { useFetchFacebookPostByPostId } from "../hooks/useFetchFacebookPostByPostId";
import { useFetchFacebookPage } from "../hooks/useFetchFacebookPage";

import FacebookMessageCard from "../FacebookMessageCard";
import { IframeWrapper } from "../styled";
import { CHANNELS, CHANNEL_OPTIONS, MAX_PAGE_SIZE, MAX_TAG_CHARACTER_LENGTH } from "./config";
import InstagramMessageCard from "../InstagramMessageCard";

type FacebookPostType = { id: string; message: string; createdTime: string };

type FacebookCampaignFormPropsType = {
  projectId: string;
  FacebookCrawlerData: AddCommentCrawlerInput;
  setFacebookCrawlerData: Function;
  disabledForm: boolean;
  isNewCreateForm: boolean;
  facebookPageId: string;
  instagramBusinessName: string;
};

const FacebookCampaignForm: FC<FacebookCampaignFormPropsType> = ({
  projectId,
  FacebookCrawlerData,
  setFacebookCrawlerData,
  disabledForm,
  facebookPageId,
  instagramBusinessName,
}) => {
  const { t } = useTranslation();

  const [inputValue, onInputChangeRaw] = useState("");
  const { postsData, isPostsDataLoading } = useFetchFacebookPost(projectId, FacebookCrawlerData.type, 100, "");
  const { scheduledPostsData, isScheduledPostsDataLoading } = useFetchFacebookScheduledPost(
    projectId,
    FacebookCrawlerData.type,
    5,
    "",
  );
  const { adsPostsData, isAdsPostsDataLoading } = useFetchFacebookAdsPost(projectId, FacebookCrawlerData.type, 5, "");
  const { postPreviewData, isPostPreviewDataLoading } = useFetchFacebookPostByPostId(
    projectId,
    FacebookCrawlerData.postId,
    FacebookCrawlerData.type,
  );
  const { facebookPageData, isFacebookPageDataLoading } = useFetchFacebookPage(projectId);

  const onInputChange = (newInputValue: string) => onInputChangeRaw(newInputValue);

  const onSelectChange = (newInputValue: ValueType<OptionType>) => {
    setFacebookCrawlerData({
      ...FacebookCrawlerData,
      postId: get(newInputValue, "value"),
    });
  };

  const handleChangeTitle = (newTitle: string) => {
    if (newTitle.length <= MAXIMUM_BROADCAST_NAME_STRING_LENGTH) {
      setFacebookCrawlerData({
        ...FacebookCrawlerData,
        title: newTitle,
      });
    }
  };

  const handleChangeReplyPostCommentMessage = (newMessage: string) => {
    setFacebookCrawlerData({
      ...FacebookCrawlerData,
      replyPostCommentMessage: newMessage,
    });
  };

  const convertDataToSelectOptionFormat = (originalData: string[]) =>
    originalData.map((data: string) => ({
      label: data,
      value: data,
    }));

  const handleChangeTagInput = (tagInputValues: ValueType<OptionType>) => {
    setFacebookCrawlerData({
      ...FacebookCrawlerData,
      reservedWords: (tagInputValues as Array<OptionType>).map((tag: OptionType) => {
        return tag.value;
      }),
    });
  };

  const handleToggleSwitch = () => {
    setFacebookCrawlerData({
      ...FacebookCrawlerData,
      isActive: !FacebookCrawlerData.isActive,
    });
  };

  const handleSetWatchedAllComments = (newValue: boolean) => {
    setFacebookCrawlerData({
      ...FacebookCrawlerData,
      isWatchedAllComments: newValue,
    });
  };

  const getCrawlerType = useCallback(() => {
    if (!FacebookCrawlerData.type) {
      return undefined;
    }

    return CHANNELS()[FacebookCrawlerData.type];
  }, [FacebookCrawlerData.type]);

  const onInputChannelChange = (newInputValue: ValueType<OptionType>) => {
    const value: string = get(newInputValue, "value");
    const commonNewState = {
      ...FacebookCrawlerData,
      postId: null,
      type: value,
    };

    const conditions: Record<string, Function> = {
      [CrawlerType.POST]: () => {
        setFacebookCrawlerData({
          ...commonNewState,
          platform: CrawlerPlatform.FACEBOOK,
        });
      },

      [CrawlerType.MEDIA]: () =>
        setFacebookCrawlerData({
          ...commonNewState,

          platform: CrawlerPlatform.INSTAGRAM,
        }),
      [CrawlerType.STORY]: () =>
        setFacebookCrawlerData({
          ...commonNewState,
          platform: CrawlerPlatform.INSTAGRAM,
        }),
    };

    const handler: Function | undefined = conditions[value];
    if (typeof handler === "function") {
      handler();
    }
    onInputChangeRaw("");
  };

  const SingleValue = (
    optionProps: SingleValueProps<{ label: string; image: string; value: string; createdTime: string }>,
  ) => {
    const { data } = optionProps;
    return (
      <components.SingleValue {...optionProps}>
        <Grid container alignContent="center" alignItems="center">
          <Grid item className="flex-0">
            <Grid container alignContent="center" alignItems="center">
              <img src={data.image} width="32" height="32" alt={data.image} className="mr-2" />
            </Grid>
          </Grid>
          <Grid item className="hiddenOverFlowText flex-1">
            <Grid container alignContent="center" alignItems="center">
              <Typography variant="title8" color="inherit" className="hiddenOverFlowText">
                {data.label}
              </Typography>
            </Grid>

            <Grid container alignContent="center" alignItems="center" justify="space-between">
              <Typography variant="body4" color="inherit">
                {`${t("facebookAutoEngagement.postId")}`} {data.value}
              </Typography>

              <Typography variant="body4" color="inherit" className="pr-2">
                {data.createdTime}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </components.SingleValue>
    );
  };

  const Option = (optionProps: OptionProps<{ label: string; image: string; value: string; createdTime: string }>) => {
    const { data } = optionProps;
    return (
      <components.Option {...optionProps}>
        <Grid container alignContent="center" alignItems="center">
          <Grid item className="flex-0">
            <Grid container alignContent="center" alignItems="center">
              <img src={data.image} width="32" height="32" alt={data.image} className="mr-2" />
            </Grid>
          </Grid>
          <Grid item className="hiddenOverFlowText flex-1">
            <Grid container alignContent="center" alignItems="center">
              <Typography variant="title8" color="inherit" className="hiddenOverFlowText">
                {data.label}
              </Typography>
            </Grid>

            <Grid container alignContent="center" alignItems="center" justify="space-between">
              <Typography variant="body4" color="inherit">
                {`${t("facebookAutoEngagement.postId")}`} {data.value}
              </Typography>

              <Typography variant="body4" color="inherit" className="pr-2">
                {data.createdTime}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </components.Option>
    );
  };

  const formatFacebookPostOption = (facebookPostList: FacebookPostType[]) => {
    return facebookPostList.map((post: FacebookPostType) => {
      return {
        image: get(post, "attachments.data[0].media.image.src") || NO_IMG_AVAILABLE,
        label: post.message ? `${post.message}` : "No caption",
        createdTime: post.createdTime && moment(post.createdTime).format(DATE_FORMAT),
        value: post.id,
      };
    });
  };

  const loadOptions = async (search: string) => {
    const postsDataList: FacebookPostType[] = get(postsData, "posts.data") || [];
    const postsDataScheduledList: FacebookPostType[] = get(scheduledPostsData, "scheduledPosts.data") || [];
    const postsDataAdsList: FacebookPostType[] = get(adsPostsData, "adsPosts.data") || [];

    const optionsAds = formatFacebookPostOption(postsDataAdsList);
    const optionsScheduled = formatFacebookPostOption(postsDataScheduledList);
    const optionsPublic = formatFacebookPostOption(postsDataList);

    let filteredOptions;
    if (!search) {
      filteredOptions = optionsPublic;
    } else {
      const searchLower = search.toLowerCase();
      filteredOptions = optionsPublic.filter(
        (option: OptionType) =>
          option.value.toLowerCase().includes(searchLower) || option.label.toLowerCase().includes(searchLower),
      );
    }

    const postsDataGroup: GroupType<OptionType>[] = [
      {
        label: "Ads",
        options: optionsAds,
      },
      {
        label: "Scheduled",
        options: optionsScheduled,
      },
      {
        label: "Public",
        options: filteredOptions,
      },
    ];

    const hasMore = false;
    // TODO: need to fix this to support fetch more data pagination
    // const afterValue = get(postsData, "posts.paging.next");
    // if (afterValue) {
    //   hasMore = true;
    // }

    return {
      options: postsDataGroup,
      hasMore,
    };
  };

  const loadChannelOptions = () => {
    return {
      options: CHANNEL_OPTIONS(),
      hasMore: false,
    };
  };

  return (
    <>
      {FacebookCrawlerData && (
        <Grid item xs={12}>
          <Card className="mb-3" noShadow>
            <Grid container>
              <Grid container item xs={12} justify="flex-end" alignItems="center">
                <Switch checked={FacebookCrawlerData.isActive} onChange={handleToggleSwitch} />{" "}
                <Typography variant="body1" className="ml-2">
                  {FacebookCrawlerData.isActive
                    ? t("facebookAutoEngagement.table.active")
                    : t("facebookAutoEngagement.table.inactive")}
                </Typography>
              </Grid>
              <Grid item xs={12} className="py-1">
                <Grid container justify="space-between" alignContent="center" alignItems="center" className="pb-2">
                  <Grid item xs={6} alignItems="center">
                    <Typography variant="body3" color="darkMed">
                      {`${t("facebookAutoEngagement.channel")}`}
                    </Typography>
                  </Grid>
                </Grid>

                <SelectWithAsyncPaginate
                  className="mb-2"
                  onChange={onInputChannelChange}
                  isDisabled={disabledForm}
                  placeholder={`${t("facebookAutoEngagement.channel")}`}
                  loadOptions={loadChannelOptions}
                  inputValue={disabledForm ? getCrawlerType() : undefined}
                />
              </Grid>

              <Grid item xs={12} className="py-1">
                <Grid container justify="space-between" alignContent="center" alignItems="center" className="pb-2">
                  <Grid container item xs={6} alignItems="center">
                    <Typography variant="body3" color="darkMed">
                      {`${t("facebookAutoEngagement.title")}`}
                    </Typography>
                  </Grid>
                </Grid>

                <TextField
                  variant="outlined"
                  className="py-1"
                  placeholder={`${t("facebookAutoEngagement.title")}`}
                  fullWidth
                  value={FacebookCrawlerData.title}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeTitle(e.target.value)}
                />
              </Grid>

              <Grid item xs={6} className="py-1 pr-1">
                <Typography variant="body3" color="darkMed">
                  {t("facebookAutoEngagement.targetedFacebookComments")}
                </Typography>
              </Grid>

              <Grid container>
                <Grid item xs={6} className="py-1 pr-1">
                  <RadioBox
                    className="h-100"
                    title={t("facebookAutoEngagement.all")}
                    isActive={FacebookCrawlerData && FacebookCrawlerData.isWatchedAllComments}
                    onClick={() => {
                      handleSetWatchedAllComments(true);
                    }}
                  />
                </Grid>
                <Grid item xs={6} className="py-1 pr-1">
                  <RadioBox
                    className="h-100"
                    prewrap
                    title={t("facebookAutoEngagement.keywordBased")}
                    isActive={!(FacebookCrawlerData && FacebookCrawlerData.isWatchedAllComments)}
                    onClick={() => {
                      handleSetWatchedAllComments(false);
                    }}
                  />
                </Grid>
              </Grid>

              {!(FacebookCrawlerData && FacebookCrawlerData.isWatchedAllComments) && (
                <>
                  <Grid item xs={12} className="py-1">
                    <Typography variant="body3" color="darkMed">
                      {`${t("facebookAutoEngagement.keywords")}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TagInputWithLabel
                      options={[]}
                      limit={MAX_PAGE_SIZE}
                      characterLimit={MAX_TAG_CHARACTER_LENGTH}
                      value={convertDataToSelectOptionFormat(
                        (FacebookCrawlerData && FacebookCrawlerData.reservedWords) || [],
                      )}
                      onChange={(event: ValueType<OptionType>) => {
                        handleChangeTagInput(event || []);
                      }}
                      dataCy="reservedWordsTags"
                      placeholder={t("facebookAutoEngagement.addKeywords")}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <div className="mb-3">
              <Grid container>
                <Grid item xs={12} className="py-1">
                  <Typography variant="title8" color="dark">
                    {`${t("facebookAutoEngagement.post")} `}
                    {/*  eslint-disable-next-line react/jsx-no-target-blank */}
                    <a
                      href={getCommentCrawlerContentEmbedLink(FacebookCrawlerData.postId, facebookPageId)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {FacebookCrawlerData.postId}
                    </a>
                  </Typography>
                </Grid>

                <Grid item xs={12} className="py-1">
                  {!disabledForm && (
                    <SelectWithAsyncPaginate
                      key={FacebookCrawlerData.type || "undefined"}
                      inputValue={inputValue}
                      onInputChange={onInputChange}
                      className="mb-2"
                      isDisabled={isPostsDataLoading || isScheduledPostsDataLoading || isAdsPostsDataLoading}
                      loadOptions={loadOptions}
                      onChange={onSelectChange}
                      components={{ SingleValue, Option, LoadingIndicator: CircularProgress }}
                      placeholder={`${t("facebookAutoEngagement.postId")}`}
                    />
                  )}
                  {isPostPreviewDataLoading || isFacebookPageDataLoading ? undefined : (
                    <>
                      {postPreviewData && (
                        <IframeWrapper>
                          {FacebookCrawlerData.platform === CrawlerPlatform.FACEBOOK && (
                            <FacebookMessageCard
                              pageName={get(facebookPageData, "page.name")}
                              pagePicture={get(facebookPageData, "page.picture")}
                              loading={isPostPreviewDataLoading}
                              message={get(postPreviewData, "post.message")}
                              image={get(postPreviewData, "post.attachments.data[0].media.image.src")}
                              createdTime={
                                postPreviewData &&
                                postPreviewData.post &&
                                postPreviewData.post.createdTime &&
                                moment(postPreviewData.post.createdTime).format(DATE_FORMAT)
                              }
                            />
                          )}
                          {FacebookCrawlerData.platform === CrawlerPlatform.INSTAGRAM && (
                            <InstagramMessageCard
                              pageName={instagramBusinessName}
                              pagePicture={get(facebookPageData, "page.picture")}
                              loading={isPostPreviewDataLoading}
                              message={get(postPreviewData, "post.message")}
                              image={get(postPreviewData, "post.attachments.data[0].media.image.src")}
                            />
                          )}
                        </IframeWrapper>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </div>

            {FacebookCrawlerData.type && FacebookCrawlerData.type !== CrawlerType.STORY ? (
              <Grid container>
                <Grid item xs={12} className="py-1">
                  <Typography variant="body3" color="darkMed">
                    {`${t("facebookAutoEngagement.reply")}`}
                  </Typography>

                  <TextField
                    multiline
                    variant="outlined"
                    className="py-1"
                    placeholder={`${t("facebookAutoEngagement.reply")}`}
                    fullWidth
                    rows={3}
                    value={FacebookCrawlerData.replyPostCommentMessage}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeReplyPostCommentMessage(e.target.value)
                    }
                  />
                </Grid>
              </Grid>
            ) : undefined}
          </Card>
        </Grid>
      )}
    </>
  );
};

export default FacebookCampaignForm;
