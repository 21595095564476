import { Grid } from "@material-ui/core";
import Button from "components/Button";
import { SvgIcon } from "components/Icon";
import { IcCircleCheck, IcInfo, IcWarning } from "components/SvgIcons";
import Typography from "components/Typography";
import COLORS from "constants/Colors";
import { get } from "lodash";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { KYCStatus, KYCVerification } from "types/DeeplePay";
import { BANK_LOGO_SQUARE, LOCK } from "constants/Image";
import { AccountVerification, BankDetailWrapper, KYCStatusBox, ReasonBox, TotalBalanceWrapper } from "./styled";

interface BankAccountInfoPropsType {
  handleVerify: () => void;
  kycData?: KYCVerification;
  isMobile: boolean;
}

const BankAccountInfo: FC<BankAccountInfoPropsType> = ({ kycData, isMobile, handleVerify }) => {
  const { t } = useTranslation();
  const requestStatus = get(kycData, "status");
  const reason = get(kycData, "reason");
  const bankAccount = {
    bank: get(kycData, "bank"),
    bankAccountName: get(kycData, "bankAccountName"),
    bankAccountNo: get(kycData, "bankAccountNo"),
  };

  const isVerifiedAccount = requestStatus === KYCStatus.VERIFIED;
  const inUnverified = !requestStatus || requestStatus === KYCStatus.UNVERIFIED;

  const getVerifyClassName = () => {
    switch (requestStatus) {
      case KYCStatus.UNVERIFIED:
        return "unverified";
      case KYCStatus.PENDING:
        return "pending";
      case KYCStatus.REJECTED:
        return "reject";
      case KYCStatus.VERIFIED:
        return "verified";
      default:
        return "unverified";
    }
  };

  const getIcon = () => {
    switch (requestStatus) {
      case KYCStatus.VERIFIED:
        return IcCircleCheck;
      case KYCStatus.REJECTED:
        return IcWarning;
      default:
        return IcInfo;
    }
  };

  return (
    <TotalBalanceWrapper container>
      <AccountVerification item xs={isMobile ? 12 : 6}>
        <Grid item xs={12}>
          <Typography variant="title10" color={COLORS.DarkLight}>
            {t("deeplePay.accountVerification.title")}
          </Typography>
        </Grid>
        <Grid item xs={12} className={isMobile ? "" : "pt-2"}>
          <KYCStatusBox>
            <p className={`${getVerifyClassName()} text-center`}>
              <SvgIcon
                component={getIcon()}
                fontSize="small"
                viewBox={
                  requestStatus === KYCStatus.REJECTED || requestStatus === KYCStatus.VERIFIED
                    ? "0 -7 19 19"
                    : "0 -5 24 24"
                }
              />
              {t(`deeplePay.verifyStatus.${requestStatus ? requestStatus.toLowerCase() : "unverified"}.label`)}
            </p>
          </KYCStatusBox>
          {requestStatus === KYCStatus.REJECTED && (
            <ReasonBox>
              <Typography variant="body5" color={COLORS.Error}>
                {reason}
              </Typography>
            </ReasonBox>
          )}
        </Grid>
        {(inUnverified || requestStatus !== KYCStatus.PENDING) && (
          <Grid item xs={12} className={isMobile ? "" : "pt-3"}>
            <Button size="small" onClick={handleVerify}>
              {inUnverified
                ? t("deeplePay.verifyAccount.verify.button.label")
                : t("deeplePay.verifyAccount.reVerify.button.label")}
            </Button>
          </Grid>
        )}
      </AccountVerification>
      <Grid item xs={isMobile ? 12 : 6} className={isMobile ? "pt-3" : "pl-4"}>
        <Grid item xs={12}>
          <Typography variant="title10" color={COLORS.DarkLight}>
            {t("deeplePay.bankAccount.title")}
          </Typography>
        </Grid>
        <BankDetailWrapper item xs={12} className="mt-3">
          <div>
            <img
              src={isVerifiedAccount && bankAccount?.bank ? BANK_LOGO_SQUARE[bankAccount?.bank || ""] : LOCK}
              alt="verified-store"
            />
          </div>
          <div className="ml-4 detail">
            {isVerifiedAccount && bankAccount?.bank ? (
              <>
                <Typography className="text" variant="title8" color={COLORS.Dark}>
                  {bankAccount?.bankAccountName}
                </Typography>
                <Typography className="text" variant="body3" color={COLORS.DarkGray}>
                  {bankAccount?.bankAccountNo}
                </Typography>
                <Typography className="text" variant="body4" color={COLORS.DarkGray}>
                  {t(bankAccount?.bank || "")}
                </Typography>
              </>
            ) : (
              <div className="error">
                <Typography variant="body4" color={COLORS.DarkGray}>
                  {t("deeplePay.verifyAccount.status.notVerify.label")}
                </Typography>
              </div>
            )}
          </div>
        </BankDetailWrapper>
      </Grid>
    </TotalBalanceWrapper>
  );
};

export default BankAccountInfo;
