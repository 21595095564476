import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation login(
    $appleIdToken: String
    $authProvider: AuthProvider
    $deviceProvider: DeviceProvider
    $displayName: String
    $email: String
    $facebookIdToken: String
    $googleIdToken: String
    $language: Language
    $password: String
  ) {
    login(
      appleIdToken: $appleIdToken
      authProvider: $authProvider
      deviceProvider: $deviceProvider
      displayName: $displayName
      email: $email
      facebookIdToken: $facebookIdToken
      googleIdToken: $googleIdToken
      language: $language
      password: $password
    )
  }
`;

export const REGISTER = gql`
  mutation register(
    $appleIdToken: String
    $authProvider: AuthProvider
    $deviceProvider: DeviceProvider
    $displayName: String
    $email: String
    $facebookIdToken: String
    $googleIdToken: String
    $invitationToken: String
    $language: Language
    $password: String
    $phoneNumber: String
    $refCode: String
    $utm: UtmInput
  ) {
    register(
      appleIdToken: $appleIdToken
      authProvider: $authProvider
      deviceProvider: $deviceProvider
      displayName: $displayName
      email: $email
      facebookIdToken: $facebookIdToken
      googleIdToken: $googleIdToken
      invitationToken: $invitationToken
      isMobile: true
      language: $language
      password: $password
      phoneNumber: $phoneNumber
      refCode: $refCode
      utm: $utm
    )
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation($email: String!, $oldPassword: String!, $newPassword: String!) {
    changePassword(email: $email, oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation($email: String!) {
    forgotPassword(email: $email, isMobile: true)
  }
`;

export const RESET_PASSWORD = gql`
  mutation($email: String!, $token: String!, $password: String!) {
    resetPassword(email: $email, token: $token, password: $password)
  }
`;

export const RESEND_EMAIL_VERIFICATION = gql`
  mutation {
    resendVerifyEmail(isMobile: true)
  }
`;

export const UPDATE_USER = gql`
  mutation($avatar: String, $displayName: String, $language: Language, $phoneNumber: String) {
    updateUser(avatar: $avatar, displayName: $displayName, language: $language, phoneNumber: $phoneNumber) {
      avatar
      displayName
      id
      language
      phoneNumber
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(token: $token) {
      id
      isEmailVerified
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout @client {
      me
    }
  }
`;

export const ACCEPT_TERM_AND_SERVICE = gql`
  mutation {
    acceptTermAndService {
      id
      isAccepted
      acceptedAt
    }
  }
`;

export const CONNECT_ACCOUNT_SOCIAL = gql`
  mutation($token: String!, $authProvider: AuthProvider!, $deviceProvider: DeviceProvider) {
    connectUserSocialLogin(token: $token, authProvider: $authProvider, deviceProvider: $deviceProvider) {
      id
      email
      isConnectedWithApple
      isConnectedWithFacebook
      isConnectedWithGoogle
    }
  }
`;

export const DISCONNECT_ACCOUNT_SOCIAL = gql`
  mutation($authProvider: AuthProvider!) {
    disconnectUserSocialLogin(authProvider: $authProvider) {
      id
      email
      isConnectedWithApple
      isConnectedWithFacebook
      isConnectedWithGoogle
    }
  }
`;
