import React, { FC, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { RICH_MENU } from "constants/i18n";
import Colors from "constants/Colors";
import { STORE_SETTINGS, PROJECT, THEME_CONFIG } from "constants/Router";
import ButtonBase from "components/ButtonBase";
import Typography from "components/Typography";
import ConfirmationModal from "components/ConfirmationModal";
import IcArrowBack from "components/SvgIcons/IcArrowBack";
import Loading from "domain/Faq/FaqThEn/FaqContainer/Loading";
import { ProjectIdType } from "types/Project";
import { Action, AddRichMenuVariables, UpdateRichMenuVariables, InstagramFormData } from "types/RichMenu";
import { Platform } from "types/Platform";
import useToggle from "utils/hooks/useToggle";
import { mapAction, mapMenuAction } from "pages/LineRichMenu/mapAction";
import { ACTION_SECTIONS, DEFAULT_INSTAGRAM_ACTION } from "pages/LineRichMenu/config";
import useRichMenu from "pages/LineRichMenu/hooks/useRichMenu";
import Form from "./components/Form";

const InstagramPersistentMenu: FC = () => {
  const { t } = useTranslation(RICH_MENU);
  const [recentUpdatedId, setRecentUpdatedId] = useState<string>();
  const { isOpen, handleToggle, handleClose } = useToggle();
  const history = useHistory();
  const { projectId, chatMenuId } = useParams<ProjectIdType & { chatMenuId?: string }>();
  const { addRichMenu, data, isLoading, updateRichMenu, activateRichMenu } = useRichMenu(projectId, chatMenuId);

  const defaultAction = useMemo(
    () => (data?.menuActions ? data?.menuActions?.map(mapMenuAction) : DEFAULT_INSTAGRAM_ACTION),
    [data],
  );

  const defaultTitle = data?.name || "";

  const handleCancelModal = () => {
    handleClose();
    history.push(`/${PROJECT}/${projectId}/${STORE_SETTINGS}/${THEME_CONFIG}`);
  };

  const handleSubmitModal = async () => {
    if (recentUpdatedId || chatMenuId) {
      await activateRichMenu({ projectId, chatMenuId: (recentUpdatedId || chatMenuId) as string });

      handleClose();
      history.push(`/${PROJECT}/${projectId}/${STORE_SETTINGS}/${THEME_CONFIG}`);
    }
  };

  const handleSubmitRichMenu = useCallback(
    async (formData: InstagramFormData) => {
      const variables = {
        projectId,
        chatMenu: {
          name: formData?.title,
          platform: Platform.INSTAGRAM,
          menuActions: formData?.action.map((action: Action, index: number) => ({
            bounds: ACTION_SECTIONS[index],
            action: mapAction(action),
          })),
        },
      };

      if (chatMenuId) {
        const result = await updateRichMenu({ ...variables, chatMenuId } as UpdateRichMenuVariables);
        const data = result?.data?.updateChatMenu;

        if (data) {
          setRecentUpdatedId(data.id);
          handleToggle();
        }
      } else {
        const result = await addRichMenu(variables as AddRichMenuVariables);
        const data = result?.data?.addChatMenu;

        if (data) {
          setRecentUpdatedId(data.id);
          handleToggle();
        }
      }
    },
    [addRichMenu, chatMenuId, handleToggle, projectId, updateRichMenu],
  );

  return (
    <div className="px-0 py-4 px-sm-4">
      <ButtonBase
        className="d-flex mb-4 ml-3 ml-md-0 mt-4 mt-md-0"
        onClick={() => {
          history.push(`/${PROJECT}/${projectId}/${STORE_SETTINGS}/${THEME_CONFIG}`);
        }}
      >
        <IcArrowBack fontSize="20px" color={Colors.DarkMed} />
        <Typography variant="title2" color="dark" className="ml-3">
          {t("INSTAGRAM_PERSISTENT_MENU")}
        </Typography>
      </ButtonBase>

      {isLoading ? (
        <Loading loadingLabel={t("LOADING")} size={50} />
      ) : (
        <Form defaultAction={defaultAction} defaultTitle={defaultTitle} onSubmit={handleSubmitRichMenu} />
      )}
      <ConfirmationModal
        title={`${t("ACTIVATE_INSTAGRAM_MENU_CONFIRMATION")} ?`}
        isOpen={isOpen}
        onClose={handleCancelModal}
        onSubmit={handleSubmitModal}
        textSubmit={t("ACTIVATE_NOW")}
        textCancel={t("LATER")}
      />
    </div>
  );
};

export default InstagramPersistentMenu;
