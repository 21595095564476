import * as yup from "yup";
import moment from "moment";
import { CAMPAIGN_NAME_LENGTH_LIMIT } from "config/campaign";
import { getDateTime, validateTimeFormat } from "./utils";

const TIME_FORMAT_ERROR = "campaign.error.timeFormat";
const START_TIME_ERROR = "campaign.error.startTime";
const END_TIME_ERROR = "campaign.error.endTime";

const schema = yup.object().shape({
  isLiveStatus: yup.boolean(),
  campaignName: yup
    .string()
    .trim()
    .required("Please enter campaign name")
    .max(CAMPAIGN_NAME_LENGTH_LIMIT, `Campaign name is limit ${CAMPAIGN_NAME_LENGTH_LIMIT} characters.`),
  campaignDescription: yup.string(),
  endDate: yup.string(),
  startDate: yup.string(),
  endTime: yup.string().when(["startDate", "endDate"], (startDate: string, endDate: string) => {
    return yup
      .string()
      .test("Time format", TIME_FORMAT_ERROR, validateTimeFormat)
      .test("End time", END_TIME_ERROR, (time: string) => {
        const newDate = getDateTime(time, endDate);

        // Error case
        const isEndTimeBeforeStartTime = moment(newDate).isSameOrBefore(moment(startDate));
        const isEndTimeBeforeNow = moment(newDate).isSameOrBefore(moment());

        return !isEndTimeBeforeStartTime && !isEndTimeBeforeNow; // if error (test fail) should return false
      });
  }),
  startTime: yup.string().when(["startDate", "isLiveStatus"], (startDate: string, isLiveStatus: boolean) => {
    return yup
      .string()
      .test("Time format", TIME_FORMAT_ERROR, validateTimeFormat)
      .test("Start time", START_TIME_ERROR, (time: string) => {
        if (isLiveStatus) {
          // when campaign is live no need to validate startDate because this field cannot edit
          return true;
        }

        const newDate = getDateTime(time, startDate);

        // Error case
        const isStartTimeBeforeNow = moment(newDate).isBefore(moment());

        return !isStartTimeBeforeNow; // if error (test fail) should return false
      });
  }),
});

export default schema;
