import * as React from "react";

const IcFacebookBorderLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
    <path
      d="M0 16.1C0 10.4051 0 7.55764 1.12587 5.39026C2.07462 3.56382 3.56382 2.07462 5.39026 1.12587C7.55764 0 10.4051 0 16.1 0H31.9C37.5949 0 40.4424 0 42.6097 1.12587C44.4362 2.07462 45.9254 3.56382 46.8741 5.39026C48 7.55764 48 10.4051 48 16.1V31.9C48 37.5949 48 40.4424 46.8741 42.6097C45.9254 44.4362 44.4362 45.9254 42.6097 46.8741C40.4424 48 37.5949 48 31.9 48H16.1C10.4051 48 7.55764 48 5.39026 46.8741C3.56382 45.9254 2.07462 44.4362 1.12587 42.6097C0 40.4424 0 37.5949 0 31.9V16.1Z"
      fill="#3E7BFA"
    />
    <path
      d="M33.3333 30.6663L34.4 23.9997H28V19.333C28 17.4663 28.6667 15.9997 31.6 15.9997H34.6667V9.86634C32.9333 9.59967 31.0667 9.33301 29.3333 9.33301C23.8667 9.33301 20 12.6663 20 18.6663V23.9997H14V30.6663H20V47.9997C22.6667 47.9997 25.3333 47.9997 28 47.9997V30.6663H33.3333Z"
      fill="white"
    />
  </svg>
);

export default IcFacebookBorderLogo;
