import { gql } from "@apollo/client";

export const STORE_DETAIL = gql`
  query storeDetail($projectId: ID!) {
    storeDetail(projectId: $projectId) {
      id
      projectId
      image
      name
      address
      subDistrict
      district
      city
      postcode
      phoneNumber
      facebookLink
      instagramLink
      twitterLink
      websiteLink
      offlineStore {
        id
        projectId
        name
        address
        phoneNumber
        location
        isActive
        openingHours {
          day
          open
          close
          isActive
        }
      }
    }
  }
`;
