// TODO: Remove this when new packages is done!
// type FeeRate = {
//   fix: number | null;
//   min: number | null;
//   rate: number | null;
// };
// type CreatePackageMockArgs = {
//   entityLevel: string;
//   deeplePayFeeRate: {
//     qrCash: FeeRate | null;
//     creditCard: FeeRate | null;
//     foreignCreditCard: FeeRate | null;
//     mobileBanking: FeeRate | null;
//     rabbitLinePay: FeeRate | null;
//     trueMoneyWallet: FeeRate | null;
//     withdrawal: FeeRate | null;
//   };
// };

// function createPackageMock({ entityLevel, deeplePayFeeRate }: CreatePackageMockArgs) {
//   return {
//     read() {
//       return [
//         {
//           id: "82",
//           name: "20,000 subscribers",
//           type: "PACKAGE",
//           entityType: "MAIN",
//           entityLevel,
//           isTrial: false,
//           isSelected: false,
//           pricePerMonth: 600,
//           subscriberQuota: 20000,
//           messageQuota: -1,
//           orderQuota: 0,
//           faqQuota: 50,
//           productQuota: 0,
//           memberQuota: 5,
//           channelQuota: 3,
//           imageSearchQuota: 0,
//           isAddonEnabled: null,
//           extraChargeRate: null,
//           broadcastQuota: 50000,
//           pricePerYear: 0,
//           subscriptionType: "MONTHLY",
//           deeplePayFeeRate: {
//             ...deeplePayFeeRate,
//             __typename: "DeeplePayFeeRate",
//           },
//           __typename: "Package",
//         },
//         {
//           id: "85",
//           name: "1,000 products",
//           type: "PACKAGE",
//           entityType: "C_COMMERCE",
//           entityLevel,
//           isTrial: false,
//           isSelected: false,
//           pricePerMonth: 190,
//           subscriberQuota: 0,
//           messageQuota: 0,
//           orderQuota: 300,
//           faqQuota: 0,
//           productQuota: 1000,
//           memberQuota: 0,
//           channelQuota: 0,
//           imageSearchQuota: 0,
//           isAddonEnabled: null,
//           extraChargeRate: null,
//           broadcastQuota: 0,
//           pricePerYear: 0,
//           subscriptionType: "MONTHLY",
//           deeplePayFeeRate: {
//             ...deeplePayFeeRate,
//             __typename: "DeeplePayFeeRate",
//           },
//           __typename: "Package",
//         },
//       ];
//     },
//   };
// }

const typePolicies = {
  FreeProduct: {
    keyFields: ["id", "amount"],
  },
  DeeplePayFeeInvoice: {
    keyFields: ["id", "createdAt"],
  },
  // Packages: {
  //   fields: {
  //     starter_1: createPackageMock({
  //       entityLevel: "STARTER_1",
  //       deeplePayFeeRate: {
  //         qrCash: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //         creditCard: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //         foreignCreditCard: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //         mobileBanking: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //         rabbitLinePay: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //         trueMoneyWallet: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //         withdrawal: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //       },
  //     }),
  //     starter_2: createPackageMock({
  //       entityLevel: "STARTER_2",
  //       deeplePayFeeRate: {
  //         qrCash: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //         creditCard: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //         foreignCreditCard: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //         mobileBanking: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //         rabbitLinePay: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //         trueMoneyWallet: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //         withdrawal: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //       },
  //     }),
  //     starter_3: createPackageMock({
  //       entityLevel: "STARTER_3",
  //       deeplePayFeeRate: {
  //         qrCash: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //         creditCard: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //         foreignCreditCard: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //         mobileBanking: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //         rabbitLinePay: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //         trueMoneyWallet: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //         withdrawal: {
  //           min: null,
  //           fix: null,
  //           rate: null,
  //         },
  //       },
  //     }),
  //   },
  // },
};

export default typePolicies;
