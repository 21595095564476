import React from "react";
import styled from "styled-components";

import { Z_INDEX } from "constants/ZIndex";
import Button from "components/Button";
import Card from "components/Card";
import Grid from "components/Grid";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const FloatingButtonContainer = styled(({ isShowSaveButton, ...rest }) => <div {...rest} />)`
  position: fixed;
  margin: 24px;
  bottom: ${({ isShowSaveButton }) => (isShowSaveButton ? "55px" : "80px")};
  right: 0;
  z-index: ${Z_INDEX.FLOATING_BUTTON};
`;

export const CategoryContentWrapper = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  min-height: 65px;
`;

type ProductImagePropsType = {
  productImageMaxSize?: number;
};

export const ProductImage = styled.img<ProductImagePropsType>`
  width: 100%;
  min-width: 60px;
  min-height: 60px;
  max-width: ${({ productImageMaxSize }) => (productImageMaxSize ? `${productImageMaxSize}px` : "none")};
  max-height: ${({ productImageMaxSize }) => (productImageMaxSize ? `${productImageMaxSize}px` : "none")};
  border-radius: 8px;
  margin: auto;
`;

export const DeleteButton = styled(Button)`
  border: solid 1px ${({ theme }) => theme.COLORS.Error};
  background: ${({ theme }) => theme.COLORS.Error};
  color: ${({ theme }) => theme.COLORS.White};
  position: relative;
  padding: 12px;
`;

export const AddButton = styled(Button)`
  min-width: 150px;
  min-height: 47px;
`;

export const GridButtonWrapper = styled(Grid)`
  display: flex;
  flex: 0;
  flex-wrap: nowrap;
`;

export const DigitalProductLabelBadge = styled(Grid)`
  margin-left: 6px;
  background-color: ${({ theme }) => theme.COLORS.Primary};
  border-radius: 10px;
  width: 70px;
  height: 18px;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ProductNameWrapper = styled(({ isDigitalProduct, ...rest }) => <Grid {...rest} />).attrs(
  (props: { isDigitalProduct: boolean }) => {
    const { isDigitalProduct } = props;
    return { isDigitalProduct };
  },
)`
  max-width: ${({ isDigitalProduct }) => (isDigitalProduct ? "calc(100% - 76px)" : "100%")};
  width: fit-content;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ProductNameRowWrapper = styled(({ isShowCardBadge, ...rest }) => <Grid {...rest} />).attrs(
  (props: { isShowCardBadge: boolean }) => {
    const { isShowCardBadge } = props;
    return { isShowCardBadge };
  },
)`
  width: ${({ isShowCardBadge }) => (isShowCardBadge ? "calc(100% - 50px)" : "100%")};
`;

export const DigitalProductCardBadge = styled(Grid)`
  right: 0;
  top: 0;
  border-radius: 0 7px 0 7px;
  background-color: ${({ theme }) => theme.COLORS.Primary};
`;

export const ProductItemCardWrapper = styled(Card)`
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
`;
