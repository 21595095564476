import * as React from "react";

const IcLocation2 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="-4 -4 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.534 13.088c1.42.138 2.414.453 2.414.82 0 .492-1.799.892-4.018.892-2.22 0-4.019-.4-4.019-.893 0-.366.993-.68 2.414-.818.501.492.958.898 1.297 1.186.177.151.437.151.614 0 .34-.288.796-.694 1.298-1.187zM7.93 1.2c2.829 0 5.13 2.301 5.13 5.13 0 2.995-3.46 6.238-4.84 7.413-.168.142-.412.142-.58 0C6.26 12.568 2.8 9.325 2.8 6.33c0-2.829 2.301-5.13 5.13-5.13zm.03 2.4c-1.213 0-2.2.987-2.2 2.2S6.747 8 7.96 8s2.2-.987 2.2-2.2-.987-2.2-2.2-2.2z"
      transform="translate(-847.000000, -236.000000) translate(832.000000, 220.000000) translate(15.000000, 16.000000)"
    />
  </svg>
);

export default IcLocation2;
