import React, { FC } from "react";

import TabWithLink from "components/TabWithLink";
import { SYNONYM, AI_SETTINGS } from "constants/Router";

import { TAB_CONFIGS } from "./config";

export const Synonym: FC = ({ children }) => {
  const tabConfig = {
    header: TAB_CONFIGS,
  };

  return (
    <TabWithLink tabConfig={tabConfig} path={`${AI_SETTINGS}/${SYNONYM}`} isSubTab>
      {children}
    </TabWithLink>
  );
};
