import React, { FC } from "react";
import Avatar from "components/Avatar";
import FloatBadge from "components/Badge/FloatBadge";

type CustomerImageProps = {
  chatPlatform: string;
  src: string;
  size: string; // example: 40px
};

const CustomerImage: FC<CustomerImageProps> = ({ src, chatPlatform, size }) => {
  return (
    <FloatBadge
      overlap="circle"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      badgeContent={
        <Avatar size="16px" alt={chatPlatform} src={`/assets/icon/ic-${chatPlatform.toLowerCase()}-color@3x.png`} />
      }
    >
      <Avatar size={size} src={src} />
    </FloatBadge>
  );
};

export default CustomerImage;
