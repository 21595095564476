const scoreStatus = {
  en: {
    average: "average",
    bad: "bad",
    good: "good",
    great: "great",
    none: "none",
    poor: "poor",
  },
  th: {
    average: "ปานกลาง",
    bad: "แย่",
    good: "ดี",
    great: "เยี่ยม",
    none: "ไม่มี",
    poor: "พอใช้",
  },
};

export default scoreStatus;
