import COLORS from "constants/Colors";

export const COLOR_BY_STATUS: Record<string, string> = {
  ORDER_PLACED: COLORS.NewOrder,
  PENDING_FOR_SHIPPING_COST: COLORS.Warning,
  WAIT_FOR_PAYMENT_APPROVE: COLORS.ReadyToApprove,
  PREPARE_TO_SHIPPING: COLORS.ReadyToShip,
  SHIPPED: COLORS.Success,
  ABANDONED: COLORS.DarkMed,
  CANCELLED: COLORS.DarkMed,
  ALL: COLORS.DarkMed,
  COD_PICKED_UP: COLORS.COD,
  COD_PREPARE_TO_SHIP: COLORS.ReadyToShip,
  COD_REJECTED: COLORS.DarkMed,
  COMPLETED: COLORS.Success,
  VOIDED: COLORS.Voided,
  REFUNDED: COLORS.Refunded,
};

export const DEEPLE_PAY_COLOR_BY_STATUS: Record<string, string> = {
  PROCESSING: COLORS.Warning,
  COMPLETED: COLORS.Success,
  VOIDED: COLORS.Voided,
  REFUNDED: COLORS.DarkMed,
  SUCCEEDED: COLORS.Success,
  UNPAID: COLORS.Warning,
};

export const DEEPLE_PAY_PAYMENT_CHANNEL_TYPES: Record<string, string> = {
  QR_CASH: "QR Cash",
  QR_CREDIT: "QR Credit",
  CREDIT_CARD: "Credit card",
  MOBILE_BANKING: "Mobile banking",
  TRUE_MONEY_WALLET: "True Money Wallet",
};

export const SHIPMENT_DESCRIPTION_BY_STATUS: Record<string, string> = {
  ASSIGNING_DRIVER: "Finding driver",
  ON_GOING: "Driver accepted order",
  CANCELED: "Order is cancelled before pick up",
  PICKED_UP: "Order is picked up by driver",
  REJECTED: "Order was rejected",
  COMPLETED: "Successfully delivered and transaction has completed",
  EXPIRED: "Order expired",
  // for Shippop
  CANCEL: "Order is cancelled before pick up",
  BOOKING: "Pending for shipping",
  SHIPPING: "Shipping from SHIPPOP",
  COMPLETE: "Shipment completed",
};

export const ORDER_STATUSES: Record<string, string> = {
  ORDER_PLACED: "New order",
  PENDING_FOR_SHIPPING_COST: "order.state.pending",
  WAIT_FOR_PAYMENT_APPROVE: "Ready to approve",
  PREPARE_TO_SHIPPING: "Ready to ship",
  SHIPPED: "Completed",
  ABANDONED: "Abandoned",
  CANCELLED: "Cancelled",
  ALL: "All",
  COD_PICKED_UP: "COD Approve",
  COD_PREPARE_TO_SHIP: "COD Ready to ship",
  COD_REJECTED: "COD Cancelled",
  COMPLETED: "Completed",
  VOIDED: "Voided",
  REFUNDED: "Refunded",
};

export const DEEPLE_PAY_STATUS: Record<string, string> = {
  PROCESSING: "Processing",
  COMPLETED: "Completed",
  VOIDED: "Voided",
  REFUNDED: "Refunded",
  SUCCEEDED: "Completed",
  UNPAID: "Unpaid",
};

export const ORDERS_QUERY_INTERVAL = 15000;

export const ORDER_QUERY_INTERVAL = 10000;

export const DEBOUNCE_TIME = 500;

export const INVENTORIES_QUERY_INTERVAL = 60000;
export const PRODUCTS_QUERY_INTERVAL = 60000;
