import { useQuery, WatchQueryFetchPolicy } from "@apollo/client";

import { TotalUnseenChatMessagesData } from "types/Chat";
import { TOTAL_UNSEEN_CHAT_MESSAGES } from "graphql/chat/query";

const useTotalUnseenChatMessages = (projectId: string, fetchPolicy?: WatchQueryFetchPolicy) => {
  const { data, loading, subscribeToMore } = useQuery<TotalUnseenChatMessagesData>(TOTAL_UNSEEN_CHAT_MESSAGES, {
    skip: !projectId,
    variables: { projectId },
    fetchPolicy,
  });

  return {
    totalUnseenChatMessages: {
      totalUnseenAdminChatMessages: data?.totalUnseenMessages?.totalUnseenAdminChatMessages || 0,
      totalUnseenChatMessages: data?.totalUnseenMessages?.totalUnseenChatMessages || 0,
      totalUnseenDisconnectedChatMessages: data?.totalUnseenMessages?.totalUnseenDisconnectedChatMessages || 0,
    },
    loading,
    subscribeToMore,
  };
};

export default useTotalUnseenChatMessages;
