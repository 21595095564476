import React, { FC, ReactChild } from "react";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";

import { SvgIcon } from "components/Icon";
import { IcCircleCheck, IcArrowUp, IcEdit } from "components/SvgIcons";
import Typography from "components/Typography";
import COLORS from "constants/Colors";

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    border: `solid 0.5px ${COLORS.LightBlue}`,
    borderRadius: "8px !important",
  },
  expanded: {},
})(MuiAccordion);

const Title = styled(Typography)`
  display: flex;
  flex: 1;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Summary = styled(AccordionSummary)`
  align-items: flex-start;
  padding: 8px 0;

  &.Mui-expanded {
    min-height: unset;
  }

  > .MuiAccordionSummary-expandIcon {
    display: none;
  }

  > .MuiAccordionSummary-content {
    width: 100%;
    flex-direction: column;
    padding: 0 24px;
  }

  > .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
`;

const CircleCheckMarkIconWrapper = styled.div`
  color: ${({ theme }) => theme.COLORS.Primary};
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

const CircleCheckMarkIcon = styled(SvgIcon)`
  font-size: 2rem;
  padding: 0;
`;

const CircleNumberWrapperIcon = styled.div`
  border-radius: 100%;
  border: solid 1px ${({ theme }) => theme.COLORS.LightBlue};
  width: 24px;
  height: 24px;
  margin-right: 12px;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 24px;

  > div {
    justify-content: center;
    line-height: 24px;
  }
`;

const ArrowIconWrapper = styled.div`
  width: 28px;
  height: 28px;
  color: ${({ theme }) => theme.COLORS.DarkMed};

  > div {
    padding-top: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
  }
`;

const PencilIconWrapper = styled.div`
  border-radius: 5px;
  border: solid 1px ${({ theme }) => theme.COLORS.LightBlue};
  width: 26px;
  height: 26px;
  color: ${({ theme }) => theme.COLORS.DarkMed};

  > div {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
  }
`;

const PencilIcon = styled(SvgIcon)`
  width: 0.5em;
  height: 0.5em;
  padding: 0;
`;

const CompletedContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 4px;
`;

const IncompleteContainer = styled.div`
  margin: 0 24px 24px;
`;

const ActionOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export interface AccordionStepperType {
  stepNumber: number;
  isExpanded?: boolean;
  onExpand?: () => void;
  isShowIcon?: boolean;
  completedChildren?: ReactChild | null | boolean;
  incompleteChildren?: ReactChild | null;
}

const AccordionStepper: FC<AccordionStepperType> = ({
  stepNumber,
  isExpanded,
  onExpand = () => {},
  children,
  completedChildren,
  incompleteChildren,
  isShowIcon = true,
}) => {
  const onClick = (event: React.ChangeEvent<{}>) => {
    event.stopPropagation();
    onExpand();
  };
  return (
    <Accordion className="position-relative" expanded={isExpanded}>
      <Summary>
        <ActionOverlay className="action-overlay" onClick={onClick} />
        <TitleContainer>
          {completedChildren ? (
            <CircleCheckMarkIconWrapper>
              <CircleCheckMarkIcon component={IcCircleCheck} />
            </CircleCheckMarkIconWrapper>
          ) : (
            <CircleNumberWrapperIcon>
              <Title variant="title8" color="primary">
                {stepNumber}
              </Title>
            </CircleNumberWrapperIcon>
          )}
          <Title variant="title4" color="primary">
            {children}
          </Title>
          {isShowIcon && isExpanded ? (
            <ArrowIconWrapper>
              <div>
                <SvgIcon viewBox="-6 0 20 20" component={IcArrowUp} />
              </div>
            </ArrowIconWrapper>
          ) : (
            isShowIcon && (
              <PencilIconWrapper>
                <div>
                  <PencilIcon viewBox="0 4 24 24" component={IcEdit} />
                </div>
              </PencilIconWrapper>
            )
          )}
        </TitleContainer>
        {completedChildren && typeof completedChildren !== "boolean" && (
          <CompletedContainer>{completedChildren}</CompletedContainer>
        )}
      </Summary>
      {incompleteChildren && <IncompleteContainer>{incompleteChildren}</IncompleteContainer>}
    </Accordion>
  );
};

export default AccordionStepper;
