import * as React from "react";

const SvgIcSave = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <g>
          <g>
            <g>
              <path
                d="M21.656 23.275H6.064c-.798 0-1.444-.65-1.444-1.451V13.04c0-.801.646-1.45 1.444-1.45h15.592c.797 0 1.443.649 1.443 1.45v8.783c0 .801-.646 1.45-1.443 1.45zm2.458-22.197C23.464.426 22.584.06 21.664.06h-.874v5.514c0 .801-.647 1.45-1.444 1.45H6.064c-.798 0-1.444-.649-1.444-1.45V.059H2.887C1.293.06 0 1.36 0 2.961v19.226c0 3.165 2.553 5.73 5.703 5.73h16.314c3.15 0 5.702-2.565 5.702-5.73V6.505c0-1.154-.456-2.261-1.268-3.078l-2.337-2.349z"
                transform="translate(-335 -373) translate(335 373) translate(2.065 2)"
              />
            </g>
            <path
              d="M14.452 0h4.129v3.125C18.58 4.161 17.656 5 16.516 5s-2.064-.84-2.064-1.875V0zM8.43 14h12.043c.665 0 1.204.448 1.204 1s-.539 1-1.204 1H8.43c-.665 0-1.204-.448-1.204-1s.54-1 1.204-1M8.43 19h12.043c.665 0 1.204.448 1.204 1s-.539 1-1.204 1H8.43c-.665 0-1.204-.448-1.204-1s.54-1 1.204-1"
              transform="translate(-335 -373) translate(335 373) translate(2.065 2)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgIcSave;
