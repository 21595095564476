import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Modal from "components/Modal";
import TextField from "components/TextField";
import Typography from "components/Typography";
import Button from "components/Button";
import Grid from "components/Grid";
import ErrorText from "components/ErrorText";

import COLORS from "constants/Colors";
import validationSchema from "../validationSchema";
import useDigitalContentForRedeem from "../hooks/useDigitalContentForRedeem";
import useRedeemByCode from "../hooks/useRedeemByCode";
import RedeemModalDigitalContentDetail from "../RedeemModalDigitalContentDetail";
import useQueryDigitalContentByCode from "../hooks/useQueryDigitalContentByCode";

type RedeemModalPropsType = {
  projectId: string;
  isOpen: boolean;
  onClose: () => void;
  isMobile: boolean;
  onRedeemComplete: () => void;
};

export type RedeemModalFormDataType = {
  code: string;
  note: string;
  id: string;
};

const RedeemModal: FC<RedeemModalPropsType> = (props) => {
  const { t } = useTranslation();

  const { isOpen, onClose, projectId, isMobile, onRedeemComplete } = props;

  const method = useForm<RedeemModalFormDataType>({
    resolver: yupResolver(validationSchema),
  });

  const { register, errors, handleSubmit, reset } = method;

  const {
    isShowDigitalContentDetail,
    handleHideDigitalContentDetail,
    handleShowDigitalContentDetail,
  } = useDigitalContentForRedeem(projectId);
  const { setCode, data: digitalContentData, loading } = useQueryDigitalContentByCode(projectId);

  const { handleRedeemCode } = useRedeemByCode({
    projectId,
    onComplete: () => {
      onRedeemComplete();
      handleHideDigitalContentDetail();
      reset();
      onClose();
      setCode("");
    },
  });

  const digitalContent = digitalContentData?.digitalContentByCode;

  const refId = digitalContentData?.digitalContentByCode?.digitalContent?.refId || "";
  const InputProps = useMemo(
    () =>
      refId
        ? {
            endAdornment: (
              <Typography variant="body3" className="text-nowrap" color={COLORS.Primary}>
                {t("contentManagement.modal.redeemCode.label.refId", { refId })}
              </Typography>
            ),
          }
        : {},
    [refId, t],
  );

  const handleClickRedeemNow = useCallback(
    ({ note, id }: RedeemModalFormDataType) => {
      if (id) {
        handleRedeemCode(id, note);
      }
    },
    [handleRedeemCode],
  );

  const handleCloseContentDetail = useCallback(() => {
    setCode("");
    reset({ code: "" });
    handleHideDigitalContentDetail();
  }, [handleHideDigitalContentDetail, reset, setCode]);

  return (
    <Modal
      fullScreen={isMobile}
      isOpen={isOpen}
      onClose={() => {
        handleHideDigitalContentDetail();
        reset();
        setCode("");
        onClose();
      }}
      closeIconColor={COLORS.DarkGray}
    >
      <FormProvider {...method}>
        <Grid container className={`${isMobile ? "p-3" : "p-4"} position-relative`}>
          <Grid item xs={12}>
            <Typography variant="title2" color="dark">
              {t("contentManagement.modal.redeemCode.title")}
            </Typography>
          </Grid>
          <Grid container className="pt-4">
            <Grid item xs={12}>
              <Typography variant="body3" color="darkMed" className="pl-1">
                {t("contentManagement.modal.redeemCode.label.code")}
              </Typography>
            </Grid>
            <Grid item xs={12} className="pt-2">
              <TextField
                name="code"
                fullWidth
                variant="outlined"
                placeholder={t("contentManagement.modal.redeemCode.placeholder.code")}
                validate={register}
                error={Boolean(errors.code)}
                defaultValue={refId}
                disabled={Boolean(refId)}
                InputProps={InputProps}
              />
              {errors && errors.code && (
                <Grid item xs={12} className="m-1">
                  <ErrorText>{t(`${errors.code.message}`)}</ErrorText>
                </Grid>
              )}
            </Grid>
          </Grid>

          {isShowDigitalContentDetail && (
            <RedeemModalDigitalContentDetail
              projectId={projectId}
              isLoading={loading}
              data={digitalContent}
              isMobile={isMobile}
              onClose={handleCloseContentDetail}
              onSubmit={handleClickRedeemNow}
            />
          )}

          <Grid container spacing={2} className="pt-4">
            {!refId && (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  onClick={handleSubmit((data) => {
                    setCode(data.code);
                    handleShowDigitalContentDetail();
                  })}
                >
                  {t("contentManagement.modal.redeemCode.button.continue")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </FormProvider>
    </Modal>
  );
};

export default RedeemModal;
