import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { IcCreditCard } from "components/SvgIcons";
import Typography from "components/Typography";

import COLORS from "constants/Colors";

import { DefaultCardWrapper } from "./styled";

interface DefaultCardPropsType {
  onClick?: () => void;
}

const DefaultCard: FC<DefaultCardPropsType> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <DefaultCardWrapper onClick={onClick}>
      <IcCreditCard width="65px" height="65px" color={COLORS.LightBlue} />
      <Typography variant="title3" className="text-center m-3" color={COLORS.DarkMed}>
        + {t("AddPaymentMethod")}
      </Typography>
    </DefaultCardWrapper>
  );
};

DefaultCard.displayName = "DefaultCard";
export default DefaultCard;
