import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { useForm, FormProvider } from "react-hook-form";
import Button from "components/Button";
import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import PageTitle from "components/PageTitle";
import StickyPanel from "components/StickyPanel";

import { PROJECT_SETTING } from "graphql/setting/query";
import useDevice from "utils/hooks/useDevice";
import { Device } from "types/Device";
import { ProjectIdType } from "types/Project";
import { GeneralSettingFormType, ProjectSettingQueryType } from "types/Setting";
import produce from "immer";
import nanoid from "utils/nanoid";
import BotFlowSettingForm from "./BotFlowSettingForm";

import BotSettingForm from "./BotSettingForm";
import ToggleForm from "./ToggleForm";
import useUpdateSetting from "./hooks/useUpdateSetting";
import validationSchema from "./validateSchema";
import ScheduleForm from "./ScheduleForm";

const DefaultSetting: FC = () => {
  const { t } = useTranslation();
  const device = useDevice();
  const isMobile = device === Device.MOBILE;

  const { projectId } = useParams<ProjectIdType>();

  const methods = useForm<GeneralSettingFormType>({
    resolver: yupResolver(validationSchema),
  });

  const { loading, data } = useQuery<ProjectSettingQueryType>(PROJECT_SETTING, {
    variables: {
      projectId,
    },
    onCompleted(data) {
      const oldValue = methods.getValues();

      const newValue = produce(oldValue, (draft) => {
        const { adminWorkingHours, isChatbotEnabled } = data.getProject;
        const { workingHours } = adminWorkingHours;
        const newWorkingHours = workingHours.map((workingHour) => {
          const newTime = workingHour.time.map((time) => ({ ...time, id: nanoid() }));
          return {
            ...workingHour,
            time: newTime,
          };
        });

        draft.adminWorkingHours = { ...adminWorkingHours, workingHours: newWorkingHours };
        draft.isChatbotEnabled = isChatbotEnabled;
      });

      methods.reset(newValue, { isDirty: false });
    },
  });

  const configurationData = get(data, "getProject.configuration");
  const timeToResume = get(data, "getProject.botTimer.timeToResume", 15);

  const { handleSubmit, formState, reset } = methods;
  const { handleSubmitForm } = useUpdateSetting({
    projectId,
    configurationData,
    onUpdateComplete: (data) => {
      const oldValues = methods.getValues();

      if (!data) {
        return;
      }

      const newValues = produce(oldValues, (draft) => {
        draft.adminWorkingHours = data.adminWorkingHours;
        draft.isChatbotEnabled = data.isChatbotEnabled;
      });

      reset(newValues, { isDirty: false });
    },
  });

  const isBotTimerActive = Boolean(data?.getProject?.botTimer?.isOpen);

  return loading ? (
    <Grid container item justify="center">
      <CircularProgress />
    </Grid>
  ) : (
    <Grid container className={formState.isDirty ? "pb-5" : ""}>
      <Grid container item>
        <PageTitle title={t("Bot setting")} />
      </Grid>
      <FormProvider {...methods}>
        <ScheduleForm />
        <div className="mt-3" />
        <BotSettingForm
          botGender={data?.getProject?.botGender}
          botTimeToResume={timeToResume}
          isBotTimerActive={Boolean(data?.getProject?.botTimer?.isOpen)}
          searchProductsOrder={data?.getProject?.searchProductsOrder}
        />
        <div className="mt-3" />
        <ToggleForm
          i18nDescription="botSetting.admin.contact.description"
          isSelected={Boolean(data?.getProject?.configuration?.botFlowBehavior?.postback?.isProcessingPostbackEnabled)}
          name="isProcessingPostbackEnabled"
          isBotTimerActive={isBotTimerActive}
          botTimeToResume={timeToResume}
          searchProductsOrder={data?.getProject?.searchProductsOrder}
        />
        <div className="mt-3" />
        {/* TODO integrate config data to this component */}
        <BotFlowSettingForm
          isOpenWebviewOnSelectProductEnabled={
            configurationData?.botFlowBehavior?.product?.isOpenWebviewOnSelectProductEnabled
          }
        />
      </FormProvider>
      {isMobile && (
        <StickyPanel>
          <Grid item xs={12} className="p-3">
            <Button fullWidth onClick={handleSubmit(handleSubmitForm)}>
              {t("Save")}
            </Button>
          </Grid>
        </StickyPanel>
      )}

      {!isMobile && (
        <Grid item xs={12} className="px-3 py-4 text-right">
          <Button minWidth={220} onClick={handleSubmit(handleSubmitForm)}>
            {t("Save")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default DefaultSetting;
