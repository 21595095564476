export const kerryDescription = {
  EN: null,
  TH: [
    { body: "พิมพ์ใบปะหน้าและแปะลงบนกล่องพัสดุ" },
    {
      body:
        "ผู้ใช้บริการจะต้องนำสินค้าไปส่งที่สาขา Kerry Express (Parcel Shop) เท่านั้น ตรวจสอบสาขา Kerry Express ที่สามารถจัดส่ง",
    },
    {
      body:
        "ขนาดพัสดุที่จัดส่ง (กว้าง+ยาว+สูง) ต้องไม่เกิน 180 เซนติเมตร ด้านใดด้านหนึ่งไม่เกิน 100 เซนติเมตร และน้ำหนักไม่เกิน 20 กิโลกรัม",
    },
    { body: "การเลือกน้ำหนักพัสดุที่ไม่ตรงกับความเป็นจริง ทางบริษัทฯ จะดำเนินการเรียกเก็บค่าขนส่งเพิ่มเติม" },
    {
      body:
        "มาตรฐานการนำส่ง กรุงเทพฯ และปริมณฑล หรือภายในภูมิภาคเดียวกันจัดส่งภายในวันถัดไป หากเป็นการจัดส่งต่างภูมิภาคจัดส่งภายใน 1-2 วัน (เมื่อผู้ใช้บริการนำพัสดุไปส่งภายในเวลา Cut Off Time ของแต่ละสาขา)",
      subBody: [
        "ภูมิภาค : กรุงเทพฯและปริมณฑล",
        "ได้แก่ กรุงเทพฯ, นนทบุรี, ปทุมธานี, สมุทรปราการ",
        "ภูมิภาค : ภาคกลาง",
        "ได้แก่ เพชรบุรี, กาญจนบุรี, นครนายก, นครปฐม, ประจวบคีรีขันธ์, พระนครศรีอยุธยา, ราชบุรี, ลพบุรี, สมุทรสงคราม, สมุทรสาคร, สระบุรี, สิงห์บุรี,สุพรรณบุรี, อ่างทอง",
        "ภูมิภาค : ภาคตะวันออก",
        "ได้เเก่ จันทบุรี, ฉะเชิงเทรา, ชลบุรี, ตราด, ปราจีนบุรี, ระยอง, สระเเก้ว",
        "ภูมิภาค : ภาคเหนือ",
        "ได้แก่ เชียงใหม่, เชียงราย, เพชรบูรณ์, แพร่, แม่ฮ่องสอน, กำแพงเพชร, ชัยนาท, ตาก, นครสวรรค์, น่าน, พะเยา, พิจิตร, พิษณุโลก, ลำปาง, ลำพูน, สุโขทัย, อุตรดิตถ์, อุทัยธานี",
        "ภูมิภาค : ภาคตะวันออกเฉียงเหนือ",
        "ได้เเก่ เลย, กาฬสินธุ์, ขอนเเก่น, ชัยภูมิ, นครพนม, นครราชสีมา, บึงกาฬ, บุรีรัมย์, มหาสารคาม, มุกดาหาร, ยโสธร,ร้อยเอ็ด, ศรีสะเกษ, สกลนคร, สุรินทร์, หนองคาย, หนองบัวลำภู, อำนาจเจริญ, อุดรธานี, อุบลราชธานี",
        "ภูมิภาค : ภาคใต้",
        "ได้เเก่ กระบี่, ชุมพร, ตรัง, นครศรีธรรมราช, นราธิวาส, ปัตตานี, พังงา, พัทลุง, ภูเก็ต, ยะลา, ระนอง, สงขลา, สุราษฎร์ธานี, สตูล",
      ],
    },

    {
      body:
        "ทั้งนี้หากเป็นพื้นที่พิเศษ จะมีค่าบริการเพิ่มเติม 50 บาท และระยะเวลาในการจัดส่งจะเพิ่มจากมาตรฐานการนำส่งทั่วไป 1-2 วัน ตรวจสอบรหัสไปรษณีย์ของพื้นที่พิเศษ",
    },

    { body: "กรณีพัสดุมีการตีคืนทางผู้ให้บริการจะคิดค่าบริการตามค่าขนส่ง" },
    {
      body:
        "ประกันคุ้มครองสินค้า กรณีความเสียหายและสูญหายที่เกิดขึ้นจากบริษัทที่เข้าข่ายความรับผิดชอบชดใช้ให้ตามจริง สูงสุดไม่เกิน 2,000 บาท",
    },
    { body: "หากไม่สามารถติดต่อเพื่อคืนสินค้าได้ภายใน 3 วัน จะดำเนินการตามมาตรการที่ทางขนส่งกำหนด" },
    { body: "รายการที่ชำระค่าขนส่งแล้ว จะไม่สามารถยกเลิก แก้ไข หรือขอคืนเงินได้ ไม่ว่าจะกรณีใดๆ" },
  ],
};
