import * as React from "react";

const SvgIcStoreMembers = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0 23.587c0-6.862 6.026-6.86 7.363-8.654l.153-.818c-1.879-.953-3.205-3.248-3.205-5.933 0-3.537 2.3-6.404 5.138-6.404 2.234 0 4.13 1.78 4.838 4.263-2.842.904-4.945 3.985-4.945 7.649 0 2.522.994 4.84 2.627 6.314-.262.134-.554.271-.805.389-1.316.617-3.14 1.476-4.457 3.194H0zm16.066 5.507H6.55c0-6.862 6.026-6.86 7.363-8.654l.153-.818c-1.878-.952-3.204-3.247-3.204-5.932 0-3.537 2.3-6.405 5.138-6.405 2.837 0 5.138 2.868 5.138 6.405 0 2.662-1.303 4.943-3.157 5.91l.175.93c1.467 1.707 7.292 1.818 7.292 8.564h-9.382zm9.225-5.507c-1.316-1.718-3.141-2.576-4.457-3.194a25.87 25.87 0 01-.803-.388c1.63-1.475 2.626-3.799 2.626-6.315 0-3.663-2.102-6.744-4.943-7.648.707-2.483 2.603-4.264 4.838-4.264 2.837 0 5.138 2.868 5.138 6.405 0 2.661-1.303 4.942-3.157 5.91l.175.93C26.175 16.729 32 16.84 32 23.587h-6.709z"
    />
  </svg>
);

export default SvgIcStoreMembers;
