const salesReport = {
  en: {
    "Close all": "Close",
    "Order no.": "Order no.",
    "pagination.of": "of",
    "pagination.showingResult": "Showing result",
    "Payment at": "Payment at",
    "PRODUCT NAME": "PRODUCT NAME",
    "Sales report": "Sales report",
    "salesReport.rowsSelector.perPage": "per page",
    "salesReport.rowsSelector.show": "Shows",
    "salesReport.totalSummary.shipping": "shipping",
    "salesReport.totalSummary.subTotal": "subTotal",
    "salesReport.totalSummary.total": "total",
    "Shipping price": "Shipping price",
    "SKU CODE": "SKU CODE",
    "Variation/Option": "Variation/Option",
    "Shipping discount": "Shipping discount",
    "Product discount": "Product discount",
    "WEIGHT (g.)": "WEIGHT (g.)",
    AMOUNT: "AMOUNT",
    Customer: "Customer",
    discount: "discount",
    PRICE: "PRICE",
    Shipping: "Shipping",
    Status: "Status",
    summary: "summary",
    Total: "Total",
    PhoneNumber: "PhoneNumber",
    "E-mail": "E-mail",
    "Created by": "Created by",
    "Tracking No.": "Tracking No.",
    "Product Price": "Product Price",
    "salesReport.orderReports.disabled": "Please upgrade your package to use this feature",
    "salesReport.orderReports.placeholder": "No sales reports",
    "salesReport.refCode": "Ref. code",
    "salesReport.state.newOrder": "New order",
    "salesReport.state.pending": "Pending",
    "salesReport.state.readyToApprove": "Ready to approve",
    "salesReport.state.readyToShip": "Ready to ship",
    "salesReport.state.completed": "Completed",
    "salesReport.state.abandoned": "Abandoned",
    "salesReport.state.cancelled": "Cancelled",
    "salesReport.state.all": "All",
    "salesReport.state.CODApprove": "COD approve",
    "salesReport.state.CODReadyToShip": "COD ready to ship",
    "salesReport.state.CODCancelled": "COD cancelled",
    "salesReport.state.voided": "Voided",
    "salesReport.state.refunded": "Refunded",
    "salesReport.exportedNumber.all": "All",
    "salesReport.printInvoice": "Print invoice",
    "ORDER CENTRIC": "ORDER CENTRIC",
    "PRODUCT CENTRIC": "PRODUCT CENTRIC",
    PaySlip: "Pay slip",
    Settled: "Settled",
    Weight: "Weight",
    DIMENSION: "Dimension",
    WxLxH: "(W x L x H)",
    SHIPPOP_REF: "Shippop ref",
    SEARCH_ORDER_NO: "Search by order no.",
    SETTLE_INVOICE: "Settle {{count}}invoice(s) with Shippop",
    GRAM: "g.",
    "salesReport.adminsNote": "Admin's note",
    "Delivery schedule": "Delivery schedule",
    "salesReport.modalSelectConsignmentType.title": "Choose consignment note type",
    "salesReport.modalSelectConsignmentType.full.title": "A4-sized consignment note",
    "salesReport.modalSelectConsignmentType.brief.title": "A6-sized consignment note",
  },
  th: {
    "Close all": "ปิด",
    "Order no.": "รายการสั่งซื้อที่",
    "pagination.of": "จาก",
    "pagination.showingResult": "แสดงผลรับ",
    "Payment at": "ชำระเงินเมื่อ",
    "PRODUCT NAME": "ชื่อสินค้า",
    "Sales report": "รายงานการขาย",
    "salesReport.rowsSelector.perPage": "ต่อหน้า",
    "salesReport.rowsSelector.show": "แสดง",
    "salesReport.totalSummary.shipping": "ค่าจัดส่งรวม",
    "salesReport.totalSummary.subTotal": "ราคาสินค้า",
    "salesReport.totalSummary.total": "ราคารวมทั้งหมด",
    "Shipping price": "ค่าจัดส่ง",
    "SKU CODE": "SKU CODE",
    "Variation/Option": "ความหลากหลาย/ตัวเลือก",
    "Shipping discount": "ส่วนลดค่าจัดส่ง",
    "Product discount": "ส่วนลดสินค้า",
    "WEIGHT (g.)": "น้ำหนัก (กรัม)",
    AMOUNT: "จำนวน",
    Customer: "ลูกค้า",
    discount: "ส่วนลดรวม",
    PRICE: "ราคา",
    Shipping: "การจัดส่ง",
    Status: "สถานะ",
    summary: "ผลรวม",
    Total: "ราคารวม",
    PhoneNumber: "เบอร์โทรศัพท์",
    "E-mail": "อีเมล",
    "Created by": "สร้างโดย",
    "Tracking No.": "เลขพัสดุ",
    "Product Price": "ราคาสินค้า",
    "salesReport.orderReports.disabled": "กรุณาอัพเกรดแพ็กเกจ เพื่อใช้ระบบนี้",
    "salesReport.orderReports.placeholder": "ไม่มีรายการสั่งซื้อ",
    "salesReport.refCode": "รหัสอ้างอิง",
    "salesReport.state.newOrder": "สั่งซื้อใหม่",
    "salesReport.state.pending": "รอคำนวณค่าส่ง",
    "salesReport.state.readyToApprove": "รอการอนุมัติ",
    "salesReport.state.readyToShip": "รอการส่ง",
    "salesReport.state.completed": "สำเร็จ",
    "salesReport.state.abandoned": "ไม่มีการชำระเงิน",
    "salesReport.state.cancelled": "ถูกยกเลิก",
    "salesReport.state.all": "ทั้งหมด",
    "salesReport.state.CODApprove": "รอการยืนยัน",
    "salesReport.state.CODReadyToShip": "COD พร้อมจัดส่ง",
    "salesReport.state.CODCancelled": "COD ถูกยกเลิก",
    "salesReport.state.voided": "ยกเลิก (void)",
    "salesReport.state.refunded": "คืนเงิน (refund)",
    "salesReport.exportedNumber.all": "ทั้งหมด",
    "salesReport.printInvoice": "พิมพ์ใบนำส่ง",
    "ORDER CENTRIC": "รายงานการสั่งซื้อ",
    "PRODUCT CENTRIC": "รายงานการขายรายสินค้า",
    PaySlip: "ใบเสร็จรับเงิน",
    Settled: "ยืนยันคำสั่งซื้อแล้ว",
    Weight: "น้ำหนัก",
    DIMENSION: "ขนาด",
    WxLxH: "(ก x ย x ส)",
    SHIPPOP_REF: "รหัสอ้างอิง Shippop",
    SEARCH_ORDER_NO: "ค้นหาโดย หมายเลขออเดอร์",
    SETTLE_INVOICE: "ยืนยัน {{count}}คำสั่งซื้อกับ Shippop",
    GRAM: "กรัม",
    "salesReport.adminsNote": "หมายเหตุแอดมิน",
    "Delivery schedule": "เวลาที่ต้องจัดส่ง",
    "salesReport.modalSelectConsignmentType.title": "เลือกประเภทใบปะหน้า",
    "salesReport.modalSelectConsignmentType.full.title": "ใบปะหน้าขนาด A4",
    "salesReport.modalSelectConsignmentType.brief.title": "ใบปะหน้าขนาด A6",
  },
};

export default salesReport;
