import styled from "styled-components";
import { VARIANTS } from "constants/Typography";

const TableWrapper = styled.div<{ isBlurContent?: boolean }>`
  display: flex;
  position: relative;
  min-height: 450px;
  max-height: 600px;
  overflow: ${({ isBlurContent }) => (isBlurContent ? "hidden" : "auto")};
  line-height: 1rem;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    display: initial;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(83, 83, 83, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  ::-webkit-scrollbar-corner {
    background-color: ${({ theme }) => theme.COLORS.Light};
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
    width: 25px;
    height: 25px;
    margin: auto;
    transform: rotate(0);
    transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .circle.active {
    transform: rotate(-90deg);
  }

  .table {
    font-size: ${VARIANTS.body4.size};
    display: inline-block;

    input[type="checkbox"] {
      width: 24px;
      height: 24px;
    }

    .th {
      padding: 16px 10px;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      background-color: white;
      color: ${({ theme }) => theme.COLORS.DarkLight};
      font-weight: 600;
      border-top: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      word-break: break-word;

      svg {
        font-size: 20px;
      }

      :first-child {
        border-left: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      }

      :last-child {
        border-right: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      }
    }

    .tr {
      :last-child {
        .td {
          :first-child {
            border-left: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
            border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue} !important;
          }

          :last-child {
            border-right: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
            border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue} !important;
            background-color: white;
          }
        }
      }
    }

    .td {
      padding: 10px;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      background-color: white;
      color: ${({ theme }) => theme.COLORS.DarkGray};
      word-break: break-word;

      :first-child {
        border-left: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      }

      :last-child {
        border-right: 0;
        border-right: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    &.sticky {
      .header {
        position: sticky;
        z-index: 1;
        width: fit-content;
        top: 0;
        left: 0;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px ${({ theme }) => theme.COLORS.TableShadow};
        border-right: 1px solid #ddd;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px ${({ theme }) => theme.COLORS.TableShadow};
      }
    }

    .table.subTable {
      &.sticky {
        .header {
          top: 61px;
        }
      }

      .th {
        padding: 10px;
        border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
        border-right: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
        border-top: 0;
        background-color: ${({ theme }) => theme.COLORS.LightWhite};
        font-weight: 500;
        color: ${({ theme }) => theme.COLORS.DarkMed};

        :nth-child(2) {
          justify-content: center;
        }
      }

      .th {
        :first-child {
          border-bottom: 0;
          border-radius: unset;
          ${({ theme }) => theme.breakpoints.down("lg")} {
            background-color: white;
          }
        }

        :last-child {
          border-bottom: 0;
          border-radius: unset;
          background-color: white;
        }
      }

      .tr {
        :last-child {
          .td {
            :first-child {
              border-radius: unset;
            }

            :last-child {
              border-radius: unset;
            }
          }
        }
      }

      .td {
        padding: 10px;
        border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
        border-right: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
        background-color: ${({ theme }) => theme.COLORS.LightWhite};
        color: ${({ theme }) => theme.COLORS.DarkMed};

        :first-child {
          ${({ theme }) => theme.breakpoints.down("lg")} {
            background-color: white;
          }

          border-bottom: 0;
        }

        :nth-child(2) {
          justify-content: center;
          padding: 0;
        }

        :last-child {
          border-bottom: 0;
          background-color: white;
        }
      }

      &.sticky {
        .header {
          position: sticky;
          overflow: unset;
          left: 0;
          top: 61px;
          z-index: 1;
          width: fit-content;
        }

        .body {
          position: relative;
          z-index: 0;
        }

        [data-sticky-td] {
          position: sticky;
        }

        [data-sticky-last-left-td] {
          box-shadow: 2px 0px 3px ${({ theme }) => theme.COLORS.TableShadow};
          border-right: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
        }

        [data-sticky-first-right-td] {
          box-shadow: -2px 0px 3px ${({ theme }) => theme.COLORS.TableShadow};
        }
      }
    }
  }
`;

export default TableWrapper;
