import { useEffect, useContext } from "react";
import { AppTitleContext } from "utils/context";

const useSetTitleOnMount = (title: string) => {
  const { setTitle } = useContext(AppTitleContext);

  useEffect(() => {
    setTitle(title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useSetTitleOnMount;
