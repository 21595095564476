import React, { FC, useEffect, memo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import CircularProgress from "components/CircularProgress";
import Button from "components/Button";
import Grid from "components/Grid";
import TextField from "components/TextField";
import Typography from "components/Typography";
import { RedeemDigitalContent } from "types/DigitalContent";
import ProductAndCustomerDetail from "../ProductAndCustomerDetail";
import { RedeemModalFormDataType } from "../RedeemModal";

type DigitalContentDetailProps = {
  projectId: string;
  isMobile?: boolean;
  onClose: () => void;
  data?: RedeemDigitalContent;
  isLoading: boolean;
  onSubmit: (data: RedeemModalFormDataType) => void;
};

const DigitalContentDetail: FC<DigitalContentDetailProps> = ({
  projectId,
  isMobile = false,
  onSubmit,
  onClose,
  data,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, setValue } = useFormContext();

  const digitalContentId = data?.digitalContent?.id;

  useEffect(() => {
    register("id");
  }, [register]);

  useEffect(() => {
    if (digitalContentId) {
      setValue("id", digitalContentId);
    }

    return () => {
      setValue("id", undefined);
    };
  }, [digitalContentId, setValue]);

  if (isLoading) {
    return (
      <Grid container item xs={12} justify="center">
        <CircularProgress className="p-4" />
      </Grid>
    );
  }

  return digitalContentId ? (
    <>
      <Grid item xs={12} className="pt-4">
        <Grid item xs={12} className="pb-2">
          <Typography variant="body3" color="darkMed" className="pl-1">
            {t("contentManagement.modal.redeemCode.label.productAndCustomer")}
          </Typography>
        </Grid>
        <ProductAndCustomerDetail
          projectId={projectId}
          digitalContentByCode={data as RedeemDigitalContent}
          isMobile={isMobile}
        />
      </Grid>
      <Grid container className="pt-4">
        <Grid item xs={12}>
          <Typography variant="body3" color="darkMed" className="pl-1">
            {t("contentManagement.modal.redeemCode.label.note")}
          </Typography>
        </Grid>
        <Grid item xs={12} className="pt-2">
          <TextField
            name="note"
            fullWidth
            variant="outlined"
            multiline
            rows="3"
            placeholder={t("contentManagement.modal.redeemCode.label.note")}
            validate={register}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className="pt-4">
        <Grid item xs={5}>
          <Button fullWidth onClick={onClose} color="secondary">
            {t("Cancel")}
          </Button>
        </Grid>
        <Grid item xs={7}>
          <Button fullWidth onClick={handleSubmit(onSubmit)}>
            {t("contentManagement.modal.redeemCode.button.redeemNow")}
          </Button>
        </Grid>
      </Grid>
    </>
  ) : (
    <Typography variant="title7" color="error" className="pt-2">
      {t("contentManagement.default.error.notFound")}
    </Typography>
  );
};

export default memo(DigitalContentDetail);
