import { VAT_OPTIONS_TYPE } from "types/Vat";
import { SubscriptionType } from "types/PlanUsage";

export enum EntityType {
  BASIC = "BASIC",
  C_COMMERCE = "C_COMMERCE",
  IMAGE_SEARCH = "IMAGE_SEARCH",
  MAIN = "MAIN",
  MEMBER = "MEMBER",
}

export enum EntityLevel {
  FREE = "FREE",
  STANDARD = "STANDARD",
  PROFESSIONAL = "PROFESSIONAL",
  PREMIUM = "PREMIUM",
}

export enum PackageType {
  PACKAGE = "PACKAGE",
  ADDON = "ADDON",
}

export enum InvoicePaymentType {
  WALLET = "WALLET",
  CREDIT_CARD = "CREDIT_CARD",
  QR_CASH = "QR_CASH",
}

export enum InvoiceDiscountType {
  DEEPLE_CREDIT = "DEEPLE_CREDIT",
  UPGRADE_PACKAGE_COMPENSATION = "UPGRADE_PACKAGE_COMPENSATION",
  PROMOTION_CODE = "PROMOTION_CODE",
}

export enum AllowCardType {
  visa,
  mastercard,
  jcb,
}

export enum BillingInformationType {
  PERSONAL = "PERSONAL",
  COMPANY = "COMPANY",
}

export enum BuyAddonError {
  "BUY_ADDONS:DEFAULT_ERROR" = "planAddon.error.default",
  "BUY_ADDONS:BASIC_PACK_CANNOT_BUY_ADDON" = "BUY_ADDONS.BASIC_PACK_CANNOT_BUY_ADDON",
  "BUY_ADDONS:BILLING_INFO_IS_REQUIRED" = "BUY_ADDONS.BILLING_INFO_IS_REQUIRED",
  "BUY_ADDONS:EMPTY_SELECTED_ADDONS" = "BUY_ADDONS.EMPTY_SELECTED_ADDONS",
  "BUY_ADDONS:INSUFFICIENT_CREDIT_AND_NO_CREDIT_CARD" = "BUY_ADDONS.INSUFFICIENT_CREDIT_AND_NO_CREDIT_CARD",
  "BUY_ADDONS:INVALID_DEDUCT_VALUE" = "BUY_ADDONS.INVALID_DEDUCT_VALUE",
  "BUY_ADDONS:INVALID_REMAIN_CREDIT" = "BUY_ADDONS.INVALID_REMAIN_CREDIT",
  "BUY_ADDONS:NECESSARY_INFO_IS_INSUFFICIENT" = "BUY_ADDONS.NECESSARY_INFO_IS_INSUFFICIENT",
  "BUY_ADDONS:SELECTED_ADDONS_ARE_NOT_IN_THE_SAME_LEVEL_AS_PACKAGES" = "BUY_ADDONS.SELECTED_ADDONS_ARE_NOT_IN_THE_SAME_LEVEL_AS_PACKAGES",
  "BUY_ADDONS:APPROVED" = "APPROVED",
  "BUY_ADDONS:CUSTOMER_CANCELLATION" = "CUSTOMER_CANCELLATION",
  "BUY_ADDONS:DO_NOT_HONOUR" = "DO_NOT_HONOUR",
  "BUY_ADDONS:DUPLICATE_TRANSMISSION" = "DUPLICATE_TRANSMISSION",
  "BUY_ADDONS:EXPIRED_CARD" = "EXPIRED_CARD",
  "BUY_ADDONS:FORMAT_ERROR" = "FORMAT_ERROR",
  "BUY_ADDONS:INSUFFICIENT_FUNDS" = "INSUFFICIENT_FUNDS",
  "BUY_ADDONS:INVALID_AMOUNT" = "INVALID_AMOUNT",
  "BUY_ADDONS:INVALID_CARD_NUMBER" = "INVALID_CARD_NUMBER",
  "BUY_ADDONS:INVALID_MERCHANT_ID" = "INVALID_MERCHANT_ID",
  "BUY_ADDONS:INVALID_PAYMENT_CONDITION" = "INVALID_PAYMENT_CONDITION",
  "BUY_ADDONS:INVALID_TRANSACTION" = "INVALID_TRANSACTION",
  "BUY_ADDONS:ISSUER_OR_SWITCH_IS_INOPERATIVE" = "ISSUER_OR_SWITCH_IS_INOPERATIVE",
  "BUY_ADDONS:LOST_CARD_PICK_UP" = "LOST_CARD_PICK_UP",
  "BUY_ADDONS:RE_ENTER_TRANSACTION" = "RE_ENTER_TRANSACTION",
  "BUY_ADDONS:REFER_TO_CARD_ISSUER" = "REFER_TO_CARD_ISSUER",
  "BUY_ADDONS:REQUIRE_CREDIT_CARD_TO_BUY_ADD_ON" = "REQUIRE_CREDIT_CARD_TO_BUY_ADD_ON",
  "BUY_ADDONS:REQUIRE_CREDIT_CARD_TO_UPGRADE_PLAN" = "REQUIRE_CREDIT_CARD_TO_UPGRADE_PLAN",
  "BUY_ADDONS:STOLEN_CARD_PICK_UP" = "STOLEN_CARD_PICK_UP",
  "BUY_ADDONS:SYSTEM_MALFUNCTION" = "SYSTEM_MALFUNCTION",
  "BUY_ADDONS:TRANSACTION_NOT_PERMITTED_TO_TERMINAL" = "TRANSACTION_NOT_PERMITTED_TO_TERMINAL",
  "BUY_ADDONS:TRANSACTION_TIMEOUT" = "TRANSACTION_TIMEOUT",
  "BUY_ADDONS:WRONG_CVV" = "WRONG_CVV",
}

export enum BillingError {
  "BILLING:BILLING_INFO_IS_REQUIRED" = "BILLING.BILLING_INFO_IS_REQUIRED",
  "BILLING:DEFAULT_ERROR" = "BILLING.DEFAULT_ERROR",
  "BILLING:INSUFFICIENT_CREDIT_AND_NO_CREDIT_CARD" = "BILLING.INSUFFICIENT_CREDIT_AND_NO_CREDIT_CARD",
  "BILLING:INVALID_DEDUCT_VALUE" = "BILLING.INVALID_DEDUCT_VALUE",
  "BILLING:INVALID_REMAIN_CREDIT" = "BILLING.INVALID_REMAIN_CREDIT",
  "BILLING:APPROVED" = "APPROVED",
  "BILLING:CUSTOMER_CANCELLATION" = "CUSTOMER_CANCELLATION",
  "BILLING:DO_NOT_HONOUR" = "DO_NOT_HONOUR",
  "BILLING:DUPLICATE_TRANSMISSION" = "DUPLICATE_TRANSMISSION",
  "BILLING:EXPIRED_CARD" = "EXPIRED_CARD",
  "BILLING:FORMAT_ERROR" = "FORMAT_ERROR",
  "BILLING:INSUFFICIENT_FUNDS" = "INSUFFICIENT_FUNDS",
  "BILLING:INVALID_AMOUNT" = "INVALID_AMOUNT",
  "BILLING:INVALID_CARD_NUMBER" = "INVALID_CARD_NUMBER",
  "BILLING:INVALID_MERCHANT_ID" = "INVALID_MERCHANT_ID",
  "BILLING:INVALID_PAYMENT_CONDITION" = "INVALID_PAYMENT_CONDITION",
  "BILLING:INVALID_TRANSACTION" = "INVALID_TRANSACTION",
  "BILLING:ISSUER_OR_SWITCH_IS_INOPERATIVE" = "ISSUER_OR_SWITCH_IS_INOPERATIVE",
  "BILLING:LOST_CARD_PICK_UP" = "LOST_CARD_PICK_UP",
  "BILLING:RE_ENTER_TRANSACTION" = "RE_ENTER_TRANSACTION",
  "BILLING:REFER_TO_CARD_ISSUER" = "REFER_TO_CARD_ISSUER",
  "BILLING:REQUIRE_CREDIT_CARD_TO_BUY_ADD_ON" = "REQUIRE_CREDIT_CARD_TO_BUY_ADD_ON",
  "BILLING:REQUIRE_CREDIT_CARD_TO_UPGRADE_PLAN" = "REQUIRE_CREDIT_CARD_TO_UPGRADE_PLAN",
  "BILLING:STOLEN_CARD_PICK_UP" = "STOLEN_CARD_PICK_UP",
  "BILLING:SYSTEM_MALFUNCTION" = "SYSTEM_MALFUNCTION",
  "BILLING:TRANSACTION_NOT_PERMITTED_TO_TERMINAL" = "TRANSACTION_NOT_PERMITTED_TO_TERMINAL",
  "BILLING:TRANSACTION_TIMEOUT" = "TRANSACTION_TIMEOUT",
  "BILLING:WRONG_CVV" = "WRONG_CVV",
  "BILLING:NON_UNPAID_INVOICE" = "BILLING.NON_UNPAID_INVOICE",
  "BILLING:INSUFFICIENT_CREDIT" = "BILLING.INSUFFICIENT_CREDIT",
  "BILLING:UNABLE_TO_REPAY_WITH_CREDIT_CARD" = "BILLING.UNABLE_TO_REPAY_WITH_CREDIT_CARD",
}

export enum TitleName {
  MR = "MR",
  MISS = "MISS",
  MRS = "MRS",
}

export enum CreditCardError {
  APPROVED = "APPROVED",
  CUSTOMER_CANCELLATION = "CUSTOMER_CANCELLATION",
  DO_NOT_HONOUR = "DO_NOT_HONOUR",
  DUPLICATE_TRANSMISSION = "DUPLICATE_TRANSMISSION",
  EXPIRED_CARD = "EXPIRED_CARD",
  FORMAT_ERROR = "FORMAT_ERROR",
  INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
  INVALID_AMOUNT = "INVALID_AMOUNT",
  INVALID_CARD_NUMBER = "INVALID_CARD_NUMBER",
  INVALID_MERCHANT_ID = "INVALID_MERCHANT_ID",
  INVALID_PAYMENT_CONDITION = "INVALID_PAYMENT_CONDITION",
  INVALID_TRANSACTION = "INVALID_TRANSACTION",
  ISSUER_OR_SWITCH_IS_INOPERATIVE = "ISSUER_OR_SWITCH_IS_INOPERATIVE",
  LOST_CARD_PICK_UP = "LOST_CARD_PICK_UP",
  RE_ENTER_TRANSACTION = "RE_ENTER_TRANSACTION",
  REFER_TO_CARD_ISSUER = "REFER_TO_CARD_ISSUER",
  REQUIRE_CREDIT_CARD_TO_BUY_ADD_ON = "REQUIRE_CREDIT_CARD_TO_BUY_ADD_ON",
  REQUIRE_CREDIT_CARD_TO_UPGRADE_PLAN = "REQUIRE_CREDIT_CARD_TO_UPGRADE_PLAN",
  STOLEN_CARD_PICK_UP = "STOLEN_CARD_PICK_UP",
  SYSTEM_MALFUNCTION = "SYSTEM_MALFUNCTION",
  TRANSACTION_NOT_PERMITTED_TO_TERMINAL = "TRANSACTION_NOT_PERMITTED_TO_TERMINAL",
  TRANSACTION_TIMEOUT = "TRANSACTION_TIMEOUT",
  WRONG_CVV = "WRONG_CVV",
}

export type PaymentSummaryItemType = {
  type: PackageType;
  entityType: EntityType;
  entityLevel: EntityLevel | null;
  name: string;
  price: number;
  amount: number;
  totalPrice: number;
  productQuota: number;
  subscriberQuota: number;
  imageSearchQuota: number;
  orderQuota: number;
  subscriptionType: SubscriptionType;
};

export type PackagePeriodType = {
  endBillingDate: string;
  startBillingDate: string;
  subscriptionType: SubscriptionType;
};

export type PaymentSummaryType = {
  projectId: string;
  nextBillingDate: string;
  usagePeriod: string;
  items: PaymentSummaryItemType[];
  extras: PaymentSummaryItemType[];
  totalPrice: number;
  subtotal: number;
  vatOptions: VAT_OPTIONS_TYPE;
  vatPrice: number;
  mainPackagePeriod: PackagePeriodType;
};

export type CompanyBilling = {
  id: string;
  projectId: string;
  name: string;
  address: string;
  email: string;
  taxId: string;
  phoneNumber: string;
  documentVerificationCertificatePath: string;
  isVerified: Boolean;
  contactPerson: string;
  type: BillingInformationType;
  subDistrict: string;
  district: string;
  province: string;
  postalCode: string;
};

export type PersonalBilling = {
  id: string;
  projectId: string;
  name: string;
  address: string;
  email: string;
  taxId: string;
  phoneNumber: string;
  documentVerificationCertificatePath: string;
  isVerified: Boolean;
  type: BillingInformationType;
  contactPerson: string;
  subDistrict: string;
  district: string;
  province: string;
  postalCode: string;
  titleName: string;
};

export type BillingInfoType = {
  id: string;
  name: string; // rename from companyName
  address: string;
  email: string;
  taxId: string;
  phoneNumber: string;
  documentVerificationCertificatePath: string;
  isVerified: boolean;
  contactPerson: string;
};

// this type is for new billing page
export type BillingType = CompanyBilling | PersonalBilling;

export type InvoiceType = {
  id: string;
  startBillingDate: string;
  endBillingDate: string;
  invoiceNumber: string;
  totalPrice: number;
  vatPrice: number;
  paymentType: InvoicePaymentType;
  cardBrand: CreditCardBrand;
  lastFourDigitCardNumber: string;
};

export enum PaymentSummaryTypes {
  ADDON = "addon",
  EXTRA = "extra",
}

export type PaymentSummaryResultType = {
  packagePriceTotal: number;
  packageSummaryLabel: string;
  packageLabel: string;
};

export type InvoiceConnectionType = {
  results: InvoiceType[];
  total: number;
};

export type InvoiceQueryType = {
  invoices: InvoiceConnectionType;
};

export type InvoiceQueryVariablesType = {
  projectId: string;
  limit: number;
  offset: number;
};

export type CreditCardBrand = "VISA" | "AMERICAN_EXPRESS" | "MASTERCARD" | "JCB";

export enum CreditCardBrandName {
  VISA = "Visa",
  AMERICAN_EXPRESS = "American express",
  MASTERCARD = "Mastercard",
  JCB = "JCB",
}

export interface CreditCardInput {
  name: string;
  number: string;
  expirationMonth: string;
  expirationYear: string;
  token: string;
  brand: CreditCardBrand;
}

export interface CreditCard {
  id: string;
  name: string;
  number: string;
  expirationMonth: string;
  expirationYear: string;
  brand: CreditCardBrand;
  issue: CreditCardError;
  issuedAt: string;
}

export enum PackagePromotionDiscountType {
  PERCENTAGE_DISCOUNT = "PERCENTAGE_DISCOUNT",
  AMOUNT_DISCOUNT = "AMOUNT_DISCOUNT",
  UNRECOGNIZED = "UNRECOGNIZED",
}
export enum PackagePromotionConditionType {
  FROM_FREEMIUM = "FROM_FREEMIUM",
  FROM_MONTHLY = "FROM_MONTHLY",
  FROM_ANNUALLY = "FROM_ANNUALLY",
  TO_MONTHLY = "TO_MONTHLY",
  TO_ANNUALLY = "TO_ANNUALLY",
  ONLY_ADDONS = "ONLY_ADDONS",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export interface PromotionCode {
  id: number;
  name: string;
  description: string;
  code: string;
  isActive: boolean;
  discountType: PackagePromotionDiscountType;
  conditionType: PackagePromotionConditionType[];
  discount: number;
  quota: number;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
}

export interface InvoiceDiscountItem {
  type: InvoiceDiscountType;
  total: number;
  promotionCode?: PromotionCode;
}

export interface BillingOverdue {
  isOverdue: boolean;
  billingDate: string;
  amount: number;
}
