const customerDetail = {
  en: {
    "customerDetail.customerNote.title": "Note",
    "customerDetail.customerNote.seeAll": "See all",
    "customerDetail.customerNote.placeholder": "Note",
    "customerDetail.customerNote.submit": "Submit",
    "customerDetail.customerNote.delete": "Delete",
    "customerDetail.customerNote.userDeactivated": "(Deactivated)",
    "customerDetail.customerNote.error.max": "Note cannot be more than 255 characters",
    RECONNECT: "Turn on bot",
    DISCONNECT_BOT: "Turn off bot",
    ARE_YOU_SURE_TO_TURN_OFF_BOT: "Are you sure to turn off permanently the bot for this customer?",
    ARE_YOU_SURE_TO_TURN_ON_BOT: "Are you sure to turn on permanently the bot for this customer?",
    THE_CUSTOMER_CHAT_WITH_BOT:
      "The customer will only be able to chat with admin. Some processes and features will not be functional.",
    THE_CUSTOMER_ONLY_CHAT_WITH_ADMIN: "The customer will be able to chat with bot normally.",
    DISCONNECT_BOT_TOOLTIP: "turn off permanently the bot for this customer",
    RECONNECT_BOT_TOOLTIP: "turn on permanently the bot for this customer",
  },
  th: {
    "customerDetail.customerNote.title": "บันทึกเพิ่มเติม",
    "customerDetail.customerNote.seeAll": "ดูทั้งหมด",
    "customerDetail.customerNote.placeholder": "ข้อความ",
    "customerDetail.customerNote.submit": "บันทึก",
    "customerDetail.customerNote.delete": "ลบ",
    "customerDetail.customerNote.userDeactivated": "(ไม่อยู่ในระบบ)",
    "customerDetail.customerNote.error.max": "ข้อความในบันทึกเพิ่มเติมไม่สามารถมีความยาวเกิน 255 ตัวอักษร",
    RECONNECT: "ดูแลโดยระบบอัตโนมัติ",
    DISCONNECT_BOT: "ดูแลโดยแอดมิน",
    ARE_YOU_SURE_TO_TURN_OFF_BOT: "ลูกค้ารายนี้ ดูแลโดยแอดมินเท่านั้น?",
    ARE_YOU_SURE_TO_TURN_ON_BOT: "ลูกค้ารายนี้ ดูแลโดยระบบอัตโนมัติ?",
    THE_CUSTOMER_ONLY_CHAT_WITH_ADMIN:
      "ลูกค้าจะคุยได้แค่แอดมินเท่านั้น ระบบการทำงานโหมดขายของอัตโนมัติจะไม่มีผลกับการเปลี่ยนแปลงนี้",
    THE_CUSTOMER_CHAT_WITH_BOT: "ลูกค้าจะคุยกับบอทได้ตามปกติ",
    DISCONNECT_BOT_TOOLTIP:
      "ปิดการขายของอัตโนมัติเฉพาะลูกค้ารายนี้เท่านั้น ไม่ว่าจะเปิด ปิดโหมดขายของอัตโนมัติทั้งหมด จะไม่มีผลส่วนนี้ แอดมินสามารถกลับมาดูแลโดยระบบได้อีกครั้ง",
    RECONNECT_BOT_TOOLTIP:
      "เปิด - ปิดการขายของอัตโนมัติเฉพาะลูกค้ารายนี้เท่านั้น โหมดขายของอัตโนมัติทั้งหมด จะไม่มีผลส่วนนี้",
  },
};

export default customerDetail;
