// This component that is imported from this package => https://github.com/bonafideduck/react-highlight-within-textarea
// It under beta test so I copy and fix their code to work with our project.
// I will convert to typescript later

import React from "react";
import { CompositeDecorator } from "draft-js";
import highlightToStrategyAndComponents from "./highlightToStrategyAndComponents.js";

function _extends() {
  _extends =
    Object.assign ||
    function (target) {
      for (let i = 1; i < arguments.length; i++) {
        const source = arguments[i];
        for (const key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
      return target;
    };
  return _extends.apply(this, arguments);
}

function _createForOfIteratorHelperLoose(o, allowArrayLike) {
  let it = (typeof Symbol !== "undefined" && o[Symbol.iterator]) || o["@@iterator"];
  if (it) return (it = it.call(o)).next.bind(it);
  if (
    Array.isArray(o) ||
    (it = _unsupportedIterableToArray(o)) ||
    (allowArrayLike && o && typeof o.length === "number")
  ) {
    if (it) o = it;
    let i = 0;
    return function () {
      if (i >= o.length) return { done: true };
      return { done: false, value: o[i++] };
    };
  }
  throw new TypeError(
    "Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.",
  );
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  let n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }
  return arr2;
}

const getMatches = function getMatches(text, strategyAndComponents) {
  const finds = [];

  const _loop = function _loop() {
    const sc = _step.value;
    sc.strategy(text, function (start, end) {
      if (start <= end && start >= 0 && end <= text.length) {
        finds.push({
          Component: sc.component,
          matchStart: start,
          matchEnd: end,
          matchText: text.slice(start, end),
        });
      }
    });
  };

  for (var _iterator = _createForOfIteratorHelperLoose(strategyAndComponents), _step; !(_step = _iterator()).done; ) {
    _loop();
  }

  const maps = []; // Eliminate overlapping finds.

  loop: for (let _i = 0, _finds = finds; _i < _finds.length; _i++) {
    const find = _finds[_i];

    for (let i = find.matchStart; i < find.matchEnd; i++) {
      if (maps[i]) {
        continue loop;
      }
    }

    for (let _i2 = find.matchStart; _i2 < find.matchEnd; _i2++) {
      maps[_i2] = find;
    }
  }

  const matches = Array.from(new Set(Object.values(maps))).sort(function (a, b) {
    return a.matchStart - b.matchStart;
  });
  return matches;
};

const extractBlockData = function extractBlockData(contentState, text) {
  const blocks = contentState.getBlocksAsArray();
  const blockData = [];
  let blockEnd = 0;
  const blockNumber = 0;

  for (var _iterator2 = _createForOfIteratorHelperLoose(blocks), _step2; !(_step2 = _iterator2()).done; ) {
    const block = _step2.value;
    const blockLength = block.getLength();

    if (blockLength == 0) {
      continue;
    }

    const blockText = block.getText();
    const blockStart = text.indexOf(blockText[0], blockEnd);
    blockEnd = blockStart + blockLength;
    blockData.push({
      blockStart,
      blockEnd,
      blockText: text.slice(blockStart, blockEnd),
      block,
    });
  }

  return blockData;
};

const breakSpansByBlocks = function breakSpansByBlocks(contentState, matches, text) {
  const blockData = extractBlockData(contentState, text);
  const newSpans = [];

  loop: for (var _iterator3 = _createForOfIteratorHelperLoose(matches), _step3; !(_step3 = _iterator3()).done; ) {
    const match = _step3.value;

    for (var _iterator4 = _createForOfIteratorHelperLoose(blockData), _step4; !(_step4 = _iterator4()).done; ) {
      const block = _step4.value;

      if (block.blockStart >= match.matchEnd) {
        continue loop;
      }

      if (block.blockEnd < match.matchStart) {
        continue;
      }

      const spanStart = Math.max(match.matchStart, block.blockStart);
      const spanEnd = Math.min(match.matchEnd, block.blockEnd);
      const spanText = text.slice(spanStart, spanEnd);
      newSpans.push({
        text,
        ...match,
        ...block,
        spanStart,
        spanEnd,
        spanText,
      });
    }
  }

  return newSpans;
};

const blockSpansToDecorators = function blockSpansToDecorators(blockSpans) {
  const decorators = [];

  const _loop2 = function _loop2() {
    const blockSpan = _step5.value;
    const { block } = blockSpan;
    const { spanStart } = blockSpan;
    const { spanEnd } = blockSpan;
    const { blockStart } = blockSpan;
    const { Component } = blockSpan;

    const strategy = function strategy(contentBlock, callback, contentState) {
      if (contentBlock === block) {
        callback(spanStart - blockStart, spanEnd - blockStart);
      }
    };

    delete blockSpan.component;
    delete blockSpan.block;

    const component = function component(props) {
      return /* #__PURE__ */ React.createElement(Component, { ...blockSpan, children: props.children });
    };

    decorators.push({
      strategy,
      component,
    });
  };

  for (var _iterator5 = _createForOfIteratorHelperLoose(blockSpans), _step5; !(_step5 = _iterator5()).done; ) {
    _loop2();
  }

  return decorators;
};

const createDecorator = function createDecorator(contentState, highlight, text) {
  const sc = highlightToStrategyAndComponents(highlight);
  const matches = getMatches(text, sc);
  const blockSpans = breakSpansByBlocks(contentState, matches, text);
  const decorators = blockSpansToDecorators(blockSpans);
  return new CompositeDecorator(decorators);
};

export default createDecorator;
