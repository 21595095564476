const newForgotPassword = {
  en: {
    WE_WILL_SEND_YOU_RESET_INSTRUCTIONS: "No worries, we'll send you to reset instructions",
    FORGOT_PASSWORD: "Forgot password",
    ENTER_YOUR_EMAIL: "Enter your email",
    EMAIL: "Email",
    RESET_PASSWORD: "Reset password",
    EMAIL_IS_NOT_FOUND: "Email is not found",
    BACK_TO_LOGIN: "Back to login",
  },
  th: {
    WE_WILL_SEND_YOU_RESET_INSTRUCTIONS: "ไม่ต้องกังวล เราจะส่งคำแนะนำในการรีเซ็ตให้คุณ",
    FORGOT_PASSWORD: "ลืมรหัสผ่าน",
    ENTER_YOUR_EMAIL: "กรอกอีเมลของคุณ",
    EMAIL: "อีเมล",
    RESET_PASSWORD: "รีเซ็ตรหัสผ่าน",
    EMAIL_IS_NOT_FOUND: "ไม่พบอีเมลนี้ในระบบ",
    BACK_TO_LOGIN: "ไปหน้าเข้าสู่ระบบ",
  },
};

export default newForgotPassword;
