import React, { FC } from "react";
import { useParams, Switch, Redirect, Route } from "react-router-dom";
import { OVERVIEW, PROJECT, DASHBOARD, SALES_REPORT, CUSTOMERS_REPORT } from "constants/Router";
import CustomersReport from "domain/Report/CustomersReportTable";
import CustomerReportDetail from "domain/Customer/CustomerReportDetail";
import Dashboard from "domain/Dashboard";
import useTabWithLink from "utils/hooks/useTabWithLink";
import useGetUserWithPermission from "utils/hooks/useGetUserWithPermission";

import PrivateRoute from "routers/PrivateRoute";
import { NotFound } from "pages/NotFound";

import { TAB_CONFIGS } from "pages/Dashboard/tabsConfig";
import { ProjectIdType } from "types/Project";
import SalesReportsSubRoute from "./SalesReportsSubRoute";

const DashboardSubRoute: FC = () => {
  const { projectId = "" } = useParams<ProjectIdType>();
  const { loading, isAdmin } = useGetUserWithPermission(projectId);
  const pathIndex = 4;
  const { rootPath, defaultTabPath } = useTabWithLink(TAB_CONFIGS, DASHBOARD, projectId, pathIndex);

  return (
    <Switch>
      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${OVERVIEW}`}
        isAllowed={isAdmin}
        redirectTo="/"
        component={Dashboard}
      />

      <PrivateRoute loading={loading} path={`${rootPath}/${SALES_REPORT}`} isAllowed={isAdmin} redirectTo="/">
        <SalesReportsSubRoute />
      </PrivateRoute>

      <PrivateRoute exact loading={loading} path={`${rootPath}/${CUSTOMERS_REPORT}`} isAllowed={isAdmin} redirectTo="/">
        <CustomersReport />
      </PrivateRoute>

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${CUSTOMERS_REPORT}/:customerId`}
        isAllowed={isAdmin}
        redirectTo="/"
      >
        <CustomerReportDetail />
      </PrivateRoute>

      <PrivateRoute exact loading={loading} path={`${rootPath}`} isAllowed={isAdmin} redirectTo="/">
        <Redirect to={defaultTabPath ? `/${PROJECT}/${projectId}/${DASHBOARD}/${defaultTabPath}` : `/${PROJECT}`} />
      </PrivateRoute>

      <Route path={`${rootPath}/*`}>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default DashboardSubRoute;
