import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import Typography from "components/Typography";
import { SvgIcon } from "components/Icon";
import { IcCheck } from "components/SvgIcons";

import COLORS from "constants/Colors";
import { StepConfig } from "types/Integration";

import { StepperCircle, StepLine } from "./styled";

type InventoryIntegrationInputFormModalProps = {
  focusStepIndex: number;
  stepList: StepConfig[];
  isMobile: boolean;
};

const InventoryIntegrationInputFormModal: FC<InventoryIntegrationInputFormModalProps> = (props) => {
  const { t } = useTranslation();

  const { focusStepIndex, stepList, isMobile } = props;

  return (
    <Grid container>
      {stepList.map(({ name }: StepConfig, index: number) => {
        const isActive = focusStepIndex === index;
        const isCompleted = focusStepIndex > index;

        return (
          <Grid item container key={name} xs={4} justify="center" alignItems="center">
            <Grid item container justify="center" alignItems="center" className="position-relative">
              <StepperCircle isActive={isActive} isCompleted={isCompleted}>
                {isCompleted ? (
                  <SvgIcon component={IcCheck} fontSize="small" htmlColor={COLORS.Primary} viewBox="0 0 20 20" />
                ) : (
                  <Typography variant="title7" color="white">
                    {index + 1}
                  </Typography>
                )}
              </StepperCircle>
              {Boolean(index) && <StepLine thickness="1px" isLeft />}
              {index < stepList.length - 1 && <StepLine thickness="1px" isRight />}
            </Grid>
            {!isMobile && (
              <Grid item className="pt-2">
                <Typography variant="body2">{t(name)}</Typography>
              </Grid>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default InventoryIntegrationInputFormModal;
