import styled from "styled-components";
import Grid from "components/Grid";

export const GridCategoryName = styled(Grid)`
  flex-grow: 1;
  padding-left: 24px;
  ${({ theme }) => theme.breakpoints.down("xs")} {
    padding-left: 0;
  }
`;
