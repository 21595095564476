import React, { FC, useRef, ChangeEvent } from "react";
import get from "lodash/get";
import Button from "components/Button";
import { SvgIcon } from "components/Icon";
import Typography, { VariantPropsType } from "components/Typography";
import { IcUpload } from "components/SvgIcons";
import CircularProgress from "components/CircularProgress";

type ImportProductButtonPropsType = {
  color?: string;
  size?: string;
  onChange?: (file: File) => void;
  onClick?: () => void;
  label: string;
  subLabel?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isFileType?: boolean;
  fullWidth?: boolean;
  variantTitle?: VariantPropsType["variant"];
};

const ImportProductButton: FC<ImportProductButtonPropsType> = ({
  color,
  onChange,
  onClick,
  label,
  subLabel,
  size,
  isDisabled = false,
  isLoading = false,
  isFileType = false,
  fullWidth = false,
  variantTitle = "body4",
}) => {
  const inputFileDOM = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (onClick) onClick();

    if (inputFileDOM.current) {
      inputFileDOM.current.click();
    }
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = get(event.target.files, 0);
    if (file && onChange) {
      onChange(file);
      // eslint-disable-next-line no-param-reassign
      event.target.value = "";
    }
  };

  const fontColor = color === "primary" || !color ? "white" : "darkGray";

  return (
    <Button
      color={color}
      size={size}
      className="p-2"
      disabled={isLoading || isDisabled}
      onClick={handleClick}
      minHeight={47}
      minWidth={138}
      fullWidth={fullWidth}
    >
      {isFileType && (
        <input
          className="d-none"
          accept=".csv, .xlsx"
          name="import-products"
          type="file"
          onChange={handleChange}
          ref={inputFileDOM}
        />
      )}

      {isLoading ? (
        <CircularProgress size={12} className="mr-3" />
      ) : (
        <SvgIcon className="pt-1 pr-2 pb-2" component={IcUpload} fontSize="inherit" />
      )}

      <div className="text-left">
        <Typography color={fontColor} variant={variantTitle}>
          {label}
        </Typography>
        {subLabel && (
          <Typography color="darkLight" variant="body5">
            {subLabel}
          </Typography>
        )}
      </div>
    </Button>
  );
};

export default ImportProductButton;
