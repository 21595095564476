import React, { FC } from "react";
import styled from "styled-components";

import { IcStoreSettings } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";

const Wrapper = styled.div`
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  color: ${({ theme }) => theme.COLORS.DarkLight};
  img {
    max-height: 100%;
    max-width: 100%;
  }
`;

const ImagePlaceholder: FC<{ src: string }> = ({ src }) => (
  <Wrapper>
    {src ? <img src={src} alt="store-logo" /> : <SvgIcon color="inherit" component={IcStoreSettings} />}
  </Wrapper>
);

export default ImagePlaceholder;
