import get from "lodash/get";
import { getProductTypes } from "utils/product/getProductTypes";
import { NO_IMG_AVAILABLE } from "constants/Image";
import { formattedSubRows } from "./formattedSubRows";
import { DEFAULT_VALUE } from "../config";

export const formattedSubTable = (orderProducts: object[]) => {
  return orderProducts.map((orderProduct) => {
    const productType = get(orderProduct, "productSKU.productType") || [];
    const isFree = get(orderProduct, "productSKU.isFree") || false;

    return {
      productName: get(orderProduct, "product.name") || DEFAULT_VALUE,
      image: get(orderProduct, "productSKU.image.src") || get(orderProduct, "product.images.0.src") || NO_IMG_AVAILABLE,
      productCode:
        get(orderProduct, "productSKU.productCode") || get(orderProduct, "product.productCode") || DEFAULT_VALUE,
      productPrice: isFree ? "Free" : get(orderProduct, "productSKU.price") || 0,
      amount: get(orderProduct, "amount") || 0,
      weight: get(orderProduct, "productSKU.weight") || 0,
      dimension: get(orderProduct, "productSKU.dimension") || [0, 0, 0],
      sku: getProductTypes(productType) || DEFAULT_VALUE,
      subRows: formattedSubRows(get(orderProduct, "options")),
    };
  });
};
