import React, { FC, useMemo, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { NewProductItemProps } from "types/Product";
import { CategoryType } from "types/Category";
import { NO_IMG_AVAILABLE } from "constants/Image";
import ConfirmationModal from "components/ConfirmationModal";
import CategoriesAndProductsTotal from "domain/Promotion/CategoriesAndProductsTotal";
import useToggle from "utils/hooks/useToggle";
import ProductCategoryCard from "./productCategoryCard";

type SelectedProductManagement = {
  products: NewProductItemProps[];
  onDelete: (categoryId?: string, productId?: string, productSKUId?: string) => void;
  isDisabled?: boolean;
  isHideCategoriesAndProductsTotal?: boolean;
};

type ProductsByCategory = {
  products: NewProductItemProps[];
} & CategoryType;

type FocusedProductId = {
  categoryId?: string;
  productId?: string;
  productSKUId?: string;
};

const groupProductsByCategory = (products: NewProductItemProps[]) => {
  const productsByCategory: ProductsByCategory[] = [];
  let productSKULength = 0;
  products.forEach((product) => {
    const index = productsByCategory.findIndex((category) => category.id === product.categories[0]?.id);

    if (index === -1 && product.categories[0]) {
      productsByCategory.push({ ...(product.categories[0] as CategoryType), products: [product] });
    } else {
      productsByCategory[index].products.push(product);
    }
    productSKULength += product.productSKUs.length;
  });
  const productLength = products.length;
  const categoryLength = productsByCategory.length;

  return { productsByCategory, productSKULength, productLength, categoryLength };
};

const SelectedProductManagement: FC<SelectedProductManagement> = ({
  products,
  onDelete,
  isDisabled = false,
  isHideCategoriesAndProductsTotal = false,
}) => {
  const { productsByCategory, productSKULength, productLength, categoryLength } = useMemo(
    () => groupProductsByCategory(products),
    [products],
  );

  const { t } = useTranslation();
  const { isOpen, handleToggle, handleClose } = useToggle();
  const [focusedProductId, setFocusedProductId] = useState<FocusedProductId>();

  const handleDeleteSelectedProduct = useCallback(
    (categoryId?: string, productId?: string, productSKUId?: string) => {
      setFocusedProductId({
        categoryId,
        productId,
        productSKUId,
      });
      handleToggle();
    },
    [handleToggle],
  );

  const handleCloseModal = useCallback(() => {
    setFocusedProductId(undefined);
    handleClose();
  }, [handleClose]);

  const handleSubmitModal = useCallback(() => {
    onDelete(focusedProductId?.categoryId, focusedProductId?.productId, focusedProductId?.productSKUId);
    setFocusedProductId(undefined);
    handleClose();
  }, [focusedProductId, handleClose, onDelete]);

  return (
    <>
      <div className={isHideCategoriesAndProductsTotal ? "" : "mt-4"}>
        {!isHideCategoriesAndProductsTotal && (
          <CategoriesAndProductsTotal
            categoriesTotal={categoryLength}
            productsTotal={productLength}
            productSKUsTotal={productSKULength}
          />
        )}
        {productsByCategory.map((category) => {
          const categoryImage = category.image || category.products?.[0]?.images?.[0]?.src || NO_IMG_AVAILABLE;

          return (
            <ProductCategoryCard
              id={category.id}
              key={category.name}
              products={category.products}
              categoryImage={categoryImage}
              categoryName={category.name}
              onDelete={handleDeleteSelectedProduct}
              isDisabled={isDisabled}
            />
          );
        })}
      </div>
      <ConfirmationModal
        title={t("Are you sure you want to remove ?")}
        isOpen={isOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
      />
    </>
  );
};

export default memo(SelectedProductManagement);
