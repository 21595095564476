import moment from "moment";
import get from "lodash/get";
import produce from "immer";
import { ProjectSettingType, ScheduleType } from "types/Setting";

export const formatScheduleWithFormatDate = (projectData: ProjectSettingType) => {
  const scheduleData = get(projectData, "getProject.configuration.delivery.schedule");
  return {
    isActive: (scheduleData && scheduleData.isActive) || false,
    start:
      scheduleData && scheduleData.start
        ? moment(moment(scheduleData && scheduleData.start, "HH:mm:ssZ").toDate()).format("HH:mm")
        : "08:00",
    end:
      scheduleData && scheduleData.end
        ? moment(moment(scheduleData && scheduleData.end, "HH:mm:ssZ").toDate()).format("HH:mm")
        : "08:00",
    timeInAdvance: (scheduleData && scheduleData.timeInAdvance) || 10,
  };
};

export const formatDateBeforeUpdate = (scheduleValue: Partial<ScheduleType>) => {
  return produce(scheduleValue, (draft) => {
    if (scheduleValue.start) {
      draft.start = moment(moment(scheduleValue.start, "HH:mm").toDate()).utc().format("HH:mm:ssZ");
    }

    if (scheduleValue.end) {
      draft.end = moment(moment(scheduleValue.end, "HH:mm").toDate()).utc().format("HH:mm:ssZ");
    }
  });
};
