import React, { FC, ChangeEvent, ChangeEventHandler, ReactNode } from "react";
import styled from "styled-components";
import { useController, UseControllerOptions } from "react-hook-form";
import MUICheckbox, { CheckboxProps as MUICheckboxProps } from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const StyledCheckbox = styled(MUICheckbox)`
  width: 24px;
  height: 20px;
  .MuiIconButton-label {
    width: 20px;
    height: 20px;
    border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
    border-radius: 5px;
  }

  &:hover {
    background-color: transparent;
    div {
      filter: invert(20%);
    }
    .MuiIconButton-label {
      border: 1px solid ${({ theme }) => theme.COLORS.LightGray};
    }
  }

  &.Mui-checked {
    .MuiIconButton-label {
      border: 1px solid ${({ theme }) => theme.COLORS.Primary};
      background-color: ${({ theme }) => theme.COLORS.Primary};
    }
  }

  &.Mui-checked.Mui-disabled {
    .MuiIconButton-label {
      border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      background-color: ${({ theme }) => theme.COLORS.LightBlue};
    }
  }
  &.MuiCheckbox-root {
    padding: 2px;
  }
`;

const StyledIconCheck = styled.div`
  background-image: url("/assets/icon/ic-check.svg");
  width: 16px;
  height: 16px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CheckboxPropsType = MUICheckboxProps | any;

export const ControllerCheckbox: FC<
  UseControllerOptions & {
    onChange?: ChangeEventHandler;
    label?: ReactNode;
    isDisabled?: boolean;
    className?: string;
  }
> = (props) => {
  const { onChange, label = "", isDisabled, className = "ml-0" } = props;
  const { field } = useController(props);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    field.onChange(event.target.checked);

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <FormControlLabel
      className={className}
      disabled={isDisabled}
      control={
        <StyledCheckbox
          {...field}
          checked={Boolean(field.value)}
          onChange={handleChange}
          color="default"
          icon={<StyledIconCheck />}
          checkedIcon={<StyledIconCheck />}
        />
      }
      label={label}
    />
  );
};

const Checkbox: FC<CheckboxPropsType> = (props) => {
  return <StyledCheckbox {...props} color="default" icon={<StyledIconCheck />} checkedIcon={<StyledIconCheck />} />;
};

export default Checkbox;
