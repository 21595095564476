import React, { FC } from "react";
import { LineMenuTemplate } from "types/RichMenu";
import FiveBoxes from "../TemplateSelector/options/FiveBoxes";
import SixBoxes from "../TemplateSelector/options/SixBoxes";
import FourBoxes from "../TemplateSelector/options/FourBoxes";
import FourBoxesFirstRowBigOne from "../TemplateSelector/options/FourBoxesFirstRowBigOne";
import ThreeBoxes from "../TemplateSelector/options/ThreeBoxes";
import TwoBoxesOneColumn from "../TemplateSelector/options/TwoBoxesOneColumn";
import TwoBoxesTwoColumn from "../TemplateSelector/options/TwoBoxesTwoColumn";
import OneBox from "../TemplateSelector/options/OneBox";

type GridTemplateProps = {
  type: LineMenuTemplate;
  bgColor?: string;
};

const GridTemplate: FC<GridTemplateProps> = ({ type, bgColor }) => {
  switch (type) {
    case LineMenuTemplate.LARGE_1:
      return <SixBoxes bgColor={bgColor} />;
      break;

    case LineMenuTemplate.LARGE_2:
      return <FourBoxes bgColor={bgColor} />;
      break;

    case LineMenuTemplate.LARGE_3:
      return <FourBoxesFirstRowBigOne bgColor={bgColor} />;
      break;

    case LineMenuTemplate.LARGE_4:
      return <ThreeBoxes bgColor={bgColor} />;
      break;

    case LineMenuTemplate.LARGE_5:
      return <TwoBoxesOneColumn bgColor={bgColor} />;
      break;

    case LineMenuTemplate.LARGE_6:
      return <TwoBoxesTwoColumn bgColor={bgColor} />;
      break;

    case LineMenuTemplate.LARGE_7:
      return <OneBox bgColor={bgColor} />;
      break;

    default:
      return <FiveBoxes bgColor={bgColor} />;
      break;
  }
};

export default GridTemplate;
