import { gql } from "@apollo/client";

export const PRODUCT = gql`
  query product($projectId: ID!, $id: ID!) {
    product(projectId: $projectId, id: $id) {
      id
      cfCode
      #region For digital content product
      outputType
      contentType
      #endregion
      type
      projectId
      name
      brand
      defaultPrice
      isCustomSKU
      productCode
      description
      additionalDescription
      unit
      weight
      dimension
      defaultCostPrice
      properties {
        id
        name
        value
      }
      images {
        src
      }
      categories {
        id
        name
      }
      productSKUs {
        id
        cfCode
        price
        productId
        productCode
        costPrice
        inventory
        reserved
        selected
        deleted
        isFree
        image {
          src
        }
        productType {
          value
          key {
            key
          }
        }
        options {
          name
          isActive
          isMultipleChoices
          isRequired
          maximum
          choices {
            name
            price
            isActive
            isDefault
            isSelected
          }
        }
        weight
        dimension
        #region For digital content product
        digitalContentOptions {
          outputType
          settings {
            availability {
              type
              settings {
                # PERIOD_AFTER_PURCHASED
                # days
                unit
                value
                # DATE_RANGE
                availableAt
                # EXPIRY_DATE
                expiredAt
              }
            }
            content
          }
        }
        #endregion
      }
    }
  }
`;

export const ACTIVE_AND_FREE_PRODUCTS = gql`
  query activeAndFreeProducts($projectId: ID!, $limit: Int, $offset: Int, $filter: ProductFilterInput) {
    activeAndFreeProducts(projectId: $projectId, limit: $limit, offset: $offset, filter: $filter) {
      results {
        id
        projectId
        name
        type
        brand
        weight
        dimension
        defaultPrice
        defaultCostPrice
        images {
          src
        }
        categories {
          id
          name
        }
        productSKUs {
          id
          price
          productId
          productCode
          inventory
          reserved
          selected
          deleted
          costPrice
          isFree
          image {
            src
          }
          productType {
            value
            key {
              key
            }
          }
          options {
            name
            isActive
            isMultipleChoices
            isRequired
            maximum
            choices {
              name
              price
              isActive
              isDefault
              isSelected
            }
          }
          weight
          dimension
          shippingRules {
            id
            projectId
            method
            type
            settings {
              amountThreshold
              basePrice
              fixedPrice
              pricePerUnit
            }
            selectedProducts {
              id
            }
          }
        }
      }
      total
    }
  }
`;

export const PRODUCTS = gql`
  query products($projectId: ID!, $limit: Int, $offset: Int, $filter: ProductFilterInput) {
    products(projectId: $projectId, limit: $limit, offset: $offset, filter: $filter) {
      results {
        id
        cfCode
        #region For digital content product
        outputType
        contentType
        #endregion
        projectId
        name
        type
        brand
        defaultPrice
        unit
        weight
        dimension
        defaultCostPrice
        images {
          src
        }
        categories {
          id
          name
        }
        productSKUs {
          id
          cfCode
          price
          productId
          productCode
          inventory
          reserved
          selected
          costPrice
          deleted
          isFree
          image {
            src
          }
          productType {
            value
            key {
              key
            }
          }
          weight
          dimension
          #region For digital content product
          digitalContentOptions {
            outputType
            settings {
              availability {
                type
                settings {
                  # PERIOD_AFTER_PURCHASED
                  # days
                  unit
                  value
                  # DATE_RANGE
                  availableAt
                  # EXPIRY_DATE
                  expiredAt
                }
              }
              content
            }
          }
          #endregion
        }
      }
      total
    }
  }
`;

export const ACTIVE_PRODUCT_BY_ID = gql`
  query activeProduct($projectId: ID!, $productId: ID!) {
    activeProduct(projectId: $projectId, productId: $productId) {
      id
      name
      type
      brand
      unit
      weight
      dimension
      defaultCostPrice
      categories {
        id
        name
        image
      }
      properties {
        id
        name
        value
      }
      images {
        src
      }
      description
      additionalDescription
      defaultPrice
      isCustomSKU
      productCode
      type
      productSKUs {
        id
        price
        inventory
        reserved
        productCode
        costPrice
        productType {
          value
          key {
            key
          }
        }
        deleted
        selected
        isFree
        image {
          src
        }
        weight
        dimension
        shippingRules {
          id
          projectId
          method
          type
          settings {
            amountThreshold
            basePrice
            fixedPrice
            pricePerUnit
          }
          selectedProducts {
            id
          }
        }
        digitalContentOptions {
          outputType
          settings {
            availability {
              type
              settings {
                # PERIOD_AFTER_PURCHASED
                # days
                unit
                value
                # DATE_RANGE
                availableAt
                # EXPIRY_DATE
                expiredAt
              }
            }
            content
          }
        }
      }
    }
  }
`;

export const SEARCH_PRODUCT_SKUS = gql`
  query searchSKUs($projectId: ID!, $searchQuery: String, $offset: Int, $limit: Int, $isFree: Boolean) {
    searchSKUs(projectId: $projectId, searchQuery: $searchQuery, offset: $offset, limit: $limit, isFree: $isFree) {
      id
      productCode
      price
      image {
        src
      }
      selected
      deleted
      costPrice
      productType {
        value
        key {
          key
        }
      }
      createdAt
      updatedAt
      productName
      productId
      productImages {
        src
      }
    }
  }
`;

export const ACTIVE_PRODUCTS = gql`
  query activeProducts($projectId: ID!, $limit: Int, $offset: Int, $filter: ProductFilterInput) {
    activeProducts(projectId: $projectId, limit: $limit, offset: $offset, filter: $filter) {
      results {
        id
        projectId
        type
        name
        brand
        defaultPrice
        defaultCostPrice
        unit
        weight
        dimension
        images {
          src
        }
        categories {
          id
          name
          image
        }
        productSKUs {
          id
          price
          productId
          productCode
          inventory
          reserved
          selected
          deleted
          isFree
          costPrice
          image {
            src
          }
          productType {
            value
            key {
              key
            }
          }
          weight
          dimension
        }
      }
      total
    }
  }
`;

export const TOTAL_WAREHOUSE = gql`
  query totalWareHouse($projectId: ID!) {
    totalWareHouse(projectId: $projectId) {
      totalCategory
      totalProduct
      totalProductSKU
    }
  }
`;
