import { MessageType } from "types/Chat";

/**
 * Indicate dropdown in comment crawler that
 * admin can choose
 */
export enum CrawlerChannel {
  /**
   * For Facebook post type
   */
  FacebookPost = "FACEBOOK_POST",
  /**
   * For Instagram post type
   */
  InstagramPost = "INSTAGRAM_POST",
  /**
   * For instagram story type
   */
  InstagramStory = "INSTAGRAM_STORY",
}

/**
 * Crawler platform
 */
export enum CrawlerPlatform {
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
}

/**
 * Crawler content type that crawler supported
 */
export enum CrawlerType {
  /**
   * Instagram post
   */
  MEDIA = "MEDIA",
  /**
   * Facebook post
   */
  POST = "POST",
  /**
   * Instagram story
   */
  STORY = "STORY",
}

export type MessageTextValue = string;

export type MessageImageValue = {
  url: string;
};

export type ResponseMessageType = {
  type: MessageType;
  value: MessageTextValue | MessageImageValue | [];
};

export type CommentCrawler = {
  createdAt: string;
  id: string;
  isActive: boolean;
  isWatchedAllComments: boolean;
  newOrderConversionRate: number;
  numberOfEngagement: number;
  numberOfReach: number;
  orderPaidConversionRate: number;
  platform: CrawlerPlatform;
  postId: string;
  projectId: string;
  replyPostCommentMessage: string;
  reservedWords: string[];
  responseMessage: ResponseMessageType[];
  title: string;
  updatedAt: string;
  type: CrawlerType;
};

export type AddCommentCrawlerInput = {
  isActive: boolean;
  isWatchedAllComments: boolean;
  platform: CrawlerPlatform | null;
  postId: string;
  replyPostCommentMessage: string;
  reservedWords: string[];
  responseMessage: ResponseMessageType[];
  title: string;
  type: CrawlerType | null;
};

export type CommentCrawlerFromGQL = {
  isActive: boolean;
  isWatchedAllComments: boolean;
  platform: CrawlerPlatform;
  postId: string;
  replyPostCommentMessage: string;
  reservedWords: string[];
  responseMessage: ResponseMessageType[];
  title: string;
  type: CrawlerType;
};

export type UpdateCommentCrawlerInput = {
  isActive: boolean;
  isWatchedAllComments: boolean;
  platform: CrawlerPlatform;
  replyPostCommentMessage: string;
  reservedWords: string[];
  responseMessage: ResponseMessageType[];
  title: string;
  type: CrawlerType;
};

type FacebookImage = {
  src: string;
  width: number;
  height: number;
};

type FacebookMedia = {
  image: FacebookImage;
  source: string;
};

enum FacebookMediaType {
  "animated_image_autoplay" = "animated_image_autoplay",
  "cover_photo" = "cover_photo",
  "profile_media" = "profile_media",
  "video_autoplay" = "video_autoplay",
  album = "album",
  checkin = "checkin",
  event = "event",
  link = "link",
  multiple = "multiple",
  music = "music",
  note = "note",
  offer = "offer",
  photo = "photo",
  status = "status",
  video = "video",
}

type FacebookAttachmentData = {
  media: FacebookMedia;
  mediaType: FacebookMediaType;
};

type FacebookAttachment = {
  data: FacebookAttachmentData[];
};

export type FacebookPostData = {
  attachments: FacebookAttachment;
  createdTime: string;
  id: string;
  isPublished: boolean;
  message: string;
  status: string;
  videoId: string;
};
