import { SystemTagStatusType } from "types/Tag";

export const SystemTagStatus: SystemTagStatusType = {
  // Deprecated
  NEW_CUSTOMER: "New",
  FIRST_TIME_BUYER: "First purchase",
  // New tags
  NEW: "New",
  FIRST_PURCHASE: "First purchase",
  REPEAT_BUYER: "Repeat buyer",
};
