import { useQuery } from "@apollo/client";
import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import { IS_ALLOW_NEW_PACKAGE } from "config";
import { DATE_INVOICE_FORMAT } from "constants/DateTimeFormat";
import { PAYMENT_SUMMARY } from "graphql/billing/query";
import groupBy from "lodash/groupBy";
import moment from "moment";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  EntityLevel,
  EntityType,
  PackageType,
  PaymentSummaryItemType,
  PaymentSummaryResultType,
  PaymentSummaryType,
} from "types/Billing";
import { PackageEntityLevel, ProjectIdType } from "types/Project";
import useGetProject from "utils/hooks/useGetProject";
import useIsDesktop from "utils/hooks/useIsDesktop";
import PaymentSummaryDesktop from "./PaymentSummaryDesktop";
import PaymentSummaryMobile from "./PaymentSummaryMobile";

interface PaymentSummaryProps {
  isDisabled?: boolean;
}

const PaymentSummary: FC<PaymentSummaryProps> = ({ isDisabled = false }) => {
  const { projectId } = useParams<ProjectIdType>();
  const isDesktop = useIsDesktop();

  const [isSeeMore, setIsSeeMore] = useState(false);

  const { t } = useTranslation();

  const { isUsingCustomPackage, packageEntityLevel } = useGetProject(projectId);
  const { loading, data } = useQuery(PAYMENT_SUMMARY, {
    skip: !projectId,
    variables: {
      projectId,
    },
  });

  if (loading) {
    return (
      <Grid container item justify="center">
        <CircularProgress className="m-4" />
      </Grid>
    );
  }

  const paymentSummary: PaymentSummaryType = data && data.paymentSummary;

  const billingDate = moment(paymentSummary.nextBillingDate).format(DATE_INVOICE_FORMAT);
  const groupPaymentSummaryItems = groupBy(paymentSummary.items, "type");

  const packagePaymentSummaryItems = groupPaymentSummaryItems[PackageType.PACKAGE];
  const { packagePriceTotal, packageSummaryLabel, packageLabel } = packagePaymentSummaryItems
    .filter((paymentDetail) => Boolean(paymentDetail.entityLevel))
    .reduce(
      (results: PaymentSummaryResultType, paymentDetail: PaymentSummaryItemType, index: number) => {
        const { name, totalPrice, entityLevel, entityType, subscriberQuota, productQuota } = paymentDetail;

        if (isUsingCustomPackage) {
          return {
            packagePriceTotal: results.packagePriceTotal + totalPrice,
            packageLabel: t("CUSTOM_PACKAGE"),
            packageSummaryLabel: "",
          };
        }

        if (!entityLevel) {
          return results;
        }

        if (entityLevel === EntityLevel.FREE) {
          return {
            packagePriceTotal: totalPrice,
            packageLabel: t("planBilling.packageSummary.label.free"),
            packageSummaryLabel: t("planBilling.packageSummary.newSummary", {
              subscriberQuota: new Intl.NumberFormat().format(subscriberQuota),
              productQuota: new Intl.NumberFormat().format(productQuota),
            }),
          };
        }

        const itemValue = name.split(" ")[0].replace(/,/g, "");
        const itemFormat = new Intl.NumberFormat().format(+itemValue);

        const summaryLabel = `${itemFormat} ${t(
          `planBilling.packageSummary.itemValueSuffix.${
            IS_ALLOW_NEW_PACKAGE ? `newPlan.${entityType.toLowerCase()}` : entityType.toLowerCase()
          }`,
        )}`;

        return {
          packageLabel: t(`planBilling.packageSummary.label.${entityLevel && entityLevel.toLowerCase()}`),
          packagePriceTotal: results.packagePriceTotal + totalPrice,
          packageSummaryLabel: `${results.packageSummaryLabel}${index ? ", " : ""}${summaryLabel}`,
        };
      },
      { packagePriceTotal: 0, packageSummaryLabel: "", packageLabel: "" },
    );

  const foundImageSearchItem = paymentSummary.items.find(
    (item: PaymentSummaryItemType) => item.entityType === EntityType.IMAGE_SEARCH,
  );

  const addonItems = groupPaymentSummaryItems[PackageType.ADDON] || [];
  const itemsSummary = foundImageSearchItem ? addonItems.concat([foundImageSearchItem]) : addonItems;

  const isFreePlan = packageEntityLevel === PackageEntityLevel.FREE;

  return (
    <>
      {isDesktop ? (
        <PaymentSummaryDesktop
          isHiddenNextBilling={isDisabled}
          displayUpgradeButton
          itemsSummary={itemsSummary}
          projectId={projectId}
          isDesktop={isDesktop}
          billingDate={billingDate}
          paymentSummary={paymentSummary}
          packageLabel={packageLabel}
          packagePriceTotal={packagePriceTotal}
          packageSummaryLabel={packageSummaryLabel}
          isFreePlan={isFreePlan}
        />
      ) : (
        <PaymentSummaryMobile
          isHiddenNextBilling={isDisabled}
          displayUpgradeButton
          itemsSummary={itemsSummary}
          projectId={projectId}
          isDesktop={isDesktop}
          isSeeMore={isSeeMore}
          setIsSeeMore={setIsSeeMore}
          billingDate={billingDate}
          paymentSummary={paymentSummary}
          packageLabel={packageLabel}
          packagePriceTotal={packagePriceTotal}
          packageSummaryLabel={packageSummaryLabel}
          isFreePlan={isFreePlan}
        />
      )}
    </>
  );
};

export default PaymentSummary;
