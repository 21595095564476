import React from "react";
import { CellProps } from "react-table";
import COLORS from "constants/Colors";
import { IcInfo } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";

const getShippopError = <T extends object>() => ({
  Header: "",
  accessor: "shippopError",
  width: 40,
  // eslint-disable-next-line react/display-name
  Cell: (cell: CellProps<T>) => {
    return cell.value ? (
      <SvgIcon component={IcInfo} fontSize="small" htmlColor={COLORS.Error} className="m-auto" />
    ) : (
      <div />
    );
  },
});

export default getShippopError;
