import moment from "moment";

export const calculateTimesToMinutes = (hours: number, mins: number) => hours * 60 + mins;

export const convertTimesToHourMinuteFormat = (minute: number) => {
  const hours = Math.floor(minute / 60);
  const mins = minute % 60;

  return { hours, mins };
};

export const convertTimeToDateText = (dateData: moment.Moment) => {
  const timeNow = moment().endOf("day");
  const tomorrow = moment().add(1, "day").endOf("day");

  const yesterday = moment().subtract(1, "day").endOf("day");

  if (dateData < yesterday) return dateData.format("DD/MM/YYYY");
  if (dateData < timeNow) return "Today";
  if (dateData < tomorrow) return "Tomorrow";
  return dateData.format("DD/MM/YYYY");
};
