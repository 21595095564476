import React, { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import Grid from "components/Grid";
import Typography from "components/Typography";
import Button from "components/Button";
import CircularProgress from "components/CircularProgress";
import Modal from "components/Modal";
import COLORS from "constants/Colors";

type ConfirmationModalPropsType = {
  isOpen: boolean;
  isLoading?: boolean;
  header?: string;
  title: string;
  subTitle?: string;
  onClose: (event: MouseEvent) => void;
  onSubmit: (event: MouseEvent) => void;
  textSubmit?: string;
  textCancel?: string;
  isHiddenCloseBtn?: boolean;
  classes?: Record<string, string | number>;
};

const ConfirmationModal: FC<ConfirmationModalPropsType> = ({
  children,
  header,
  title,
  isLoading = false,
  subTitle,
  isOpen,
  onClose,
  onSubmit,
  textSubmit = "Remove",
  textCancel = "Cancel",
  isHiddenCloseBtn = false,
  classes,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={header}
      isOpen={isOpen}
      onClose={onClose}
      closeIconColor={COLORS.DarkMed}
      isHiddenCloseBtn={isHiddenCloseBtn}
      classes={classes}
    >
      <Grid container className="p-3">
        <Grid item container justify="center" className="my-4">
          <Grid item xs={12}>
            <Typography variant="title7" className="text-center">
              <span style={{ whiteSpace: "pre-line" }}>{title}</span>
            </Typography>
            {subTitle && (
              <Typography variant="body3" className="text-center mt-2" color="darkMed">
                {subTitle}
              </Typography>
            )}
          </Grid>
        </Grid>
        {Boolean(children) && (
          <Grid item xs={12}>
            {isLoading ? <CircularProgress size={30} className="mx-auto d-block my-4" /> : children}{" "}
          </Grid>
        )}
        <Grid item container>
          <Grid item xs={6} className="px-1">
            <Button size="small" type="button" color="secondary" onClick={onClose} fullWidth>
              {t(textCancel)}
            </Button>
          </Grid>
          <Grid item xs={6} className="px-1">
            <Button size="small" type="button" disabled={isLoading} fullWidth onClick={onSubmit}>
              {isLoading && <CircularProgress size={8} />}
              {t(textSubmit)}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ConfirmationModal;
