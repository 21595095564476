import styled, { css } from "styled-components";
import Popper from "@material-ui/core/Popper";
import Select from "components/Select";
import Grid from "components/Grid";
import Button from "components/Button";
import { Modal } from "@material-ui/core";

type RectangleButtonPropsType = {
  isSelected: boolean;
  isActive?: boolean;
};

type GraphLegendButtonType = {
  isSelected?: boolean;
};

export const PopperBox = styled(Popper)`
  border-radius: 8px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.03);
  border: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
  background-color: ${({ theme }) => theme.COLORS.White};
  padding: 13px;
  margin: 8px 12px 0 20px;
`;

export const CardListItemWrapper = styled(Grid)`
  border-top: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
  padding: 20px 20px 12px 20px;
  min-height: 72.5px;
`;

export const Capsule = styled.div`
  background-color: ${({ theme }) => theme.COLORS.LightGray};
  border-radius: 8px;
  padding: 4px 6px;
  margin-right: 4px;
`;

export const ApplyButtonWrapper = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 70px;
  padding: 15px 0;

  &.native {
    background-color: ${({ theme }) => theme.COLORS.Light};
  }
`;

export const ApplyButton = styled(Button)`
  font-family: "Graphik";
  font-size: 16px;
  width: 100%;

  &.native {
    margin: 0 20px;
    min-height: 42px;
  }
`;

export const RectangleButton = styled.div<RectangleButtonPropsType>`
  border: solid 0.5px ${({ theme, isSelected }) => (isSelected ? theme.COLORS.LightBlue : "transparent")};
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.COLORS.White : "transparent")};
  border-radius: 8px;
  padding: 10px 12px 12px;
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
      border-color: ${({ theme }) => theme.COLORS.Primary};
    `}
`;

export const PercentageChangedRow = styled(Grid)`
  height: 20px;
`;

export const GraphLegendButton = styled.button<GraphLegendButtonType>`
  outline: none;
  padding: 8px 12px;
  border-radius: 8px;
  border: solid 1px ${({ theme, isSelected }) => (isSelected ? theme.COLORS.Primary : theme.COLORS.LightBlue)};
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.COLORS.PrimaryNude : theme.COLORS.White)};
`;

export const PlatformFilterButton = styled.button`
  outline: none;
  cursor: pointer;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.COLORS.LightBlue};
  background-color: ${({ theme }) => theme.COLORS.White};
`;

export const PopupBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
`;

export const DateRagePickerModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  background-color: rgb(20, 25, 49, 0.5); // fix opacity from COLORS.Dark
`;

export const DateRangePickerHeader = styled.div`
  width: 100%;
  max-width: 320px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.COLORS.Primary};
  color: ${({ theme }) => theme.COLORS.White};
`;

export const DateRangeContainer = styled(Grid)`
  height: calc(570px - 60px - 70px);
  background-color: ${({ theme }) => theme.COLORS.White}; ;
`;

export const MobileDatePickerWrapper = styled.div`
  width: "100%";
  width: 320px;
  height: 570px;
`;

export const HeaderText = styled(Grid)`
  padding: 0 15px;

  &.close {
    cursor: pointer;
  }
`;

export const StyledSelect = styled(Select)`
  margin-left: 8px;
`;
