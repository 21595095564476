import { Gender } from "types/AISetting";
import { InvoicePaymentType } from "types/Billing";

export type ProjectIdType = {
  projectId: string;
};

export type CustomerIdType = {
  customerId: string;
};

export type FacebookCredentialParams = {
  appId: string;
  appSecret: string;
  accessToken: string;
};

export type ProjectConfiguration = {
  shipping: {
    shippop: {
      isActive: boolean;
    };
  };
  product: {
    digitalContent: {
      isActive: boolean;
    };
  };
};

export enum PackageEntityLevel {
  FREE = "FREE", // 0
  STANDARD = "STANDARD", // 1
  PROFESSIONAL = "PROFESSIONAL", // 2
  PREMIUM = "PREMIUM", // 3
}

export enum MultiStoreInventoryType {
  MASTER = "MASTER",
  CHILD = "CHILD",
  NONE = "NONE",
}

export type ProjectType = {
  botGender: Gender;
  configuration: ProjectConfiguration;
  facebookPageId: string;
  id: string;
  isAddonPackageEnabled: boolean;
  isAllowingConnectMoreChannel: boolean;
  isBroadcastMessageEnabled: boolean;
  isCurrentProjectEnterprise: boolean;
  isCustomThemeConfigurationEnabled: boolean;
  isDisabled: boolean;
  isEnterprise: boolean;
  isFacebookAutoEngagementEnabled: boolean;
  isIntegrationEnabled: boolean;
  isOfflineOrderEnabled: boolean;
  isOpenApiConfigurationEnabled: boolean;
  isPaymentGatewayAddonEnabled: boolean;
  isSalesReportEnabled: boolean;
  isUsingCustomPackage: boolean;
  refId: string;
  packageEntityLevel: PackageEntityLevel;
  monthlyBillingPaymentIssueType?: InvoicePaymentType;
  multiStoreInventoryType: MultiStoreInventoryType;
  isBankTransferEnabled: boolean;
  isDeeplePayEnabled: boolean;
  isAllowApproveBySlip: boolean;
  isGuidelineCompleted: boolean;
  instagramBusinessId: string;
  isFacebookConnected: boolean;
  isInstagramConnected: boolean;
  isLineConnected: boolean;
  isChatbotEnabled: boolean;
  orderLimitReachedStatus: "NORMAL" | "ALMOST_REACH_QUOTA" | "REACHED_QUOTA";
  lineChannelAccount: string;
};
