import styled from "styled-components";
import Grid from "components/Grid";
import Select from "components/Select";
import Typography from "components/Typography";
import { ButtonWithIcon } from "components/Button";

export const Wrapper = styled(Grid)`
  padding-top: 48px;
  padding-right: 48px;
  padding-bottom: 120px;
  padding-left: 48px;
  width: 100%;

  &.mobile {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 96px;
    padding-left: 0;
  }
`;

export const CardWrapper = styled.div`
  padding: 32px 20px 20px 20px;
  border-radius: 8px;
`;

export const StyledSelect = styled(Select)`
  margin-left: 8px;
`;

export const InvoiceTable = styled(Grid)<{ isMobile: boolean }>`
  div {
    box-sizing: border-box;
  }

  svg {
    vertical-align: middle;
    font-size: 12px;
  }

  .table-item {
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  }

  .table-item:last-child {
    border-bottom: none;
  }

  .item:not(:first-child) {
    display: ${({ isMobile }) => (isMobile ? "inline-grid" : "flex")};
    text-align: ${({ isMobile }) => (isMobile ? "right" : "left")};
  }

  .item:last-child {
    cursor: pointer;
  }

  .text-left {
    display: flex;
    text-align: left;
  }

  .item-right {
    display: flex;
    justify-content: flex-end;
  }
`;

export const InvoiceItemCard = styled(Grid)`
  &.active {
    background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  }

  svg {
    margin-right: 6px !important;
  }
`;

export const InvoiceNumberText = styled(Typography)`
  &.native {
    padding-top: 6px;
  }
`;

export const DownloadPDFButton = styled(ButtonWithIcon)`
  width: 100%;
  max-width: 68px;
  height: 100%;
  padding: 8px;

  &.disabled {
    border: none;
    background-color: transparent;
    pointer-events: none;
  }
`;
