import React, { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { OptionType, ValueType } from "react-select";

import Card from "components/Card";
import Grid from "components/Grid";
import TagInputWithLabel from "components/TagInputWithAutoSuggest";
import Typography from "components/Typography";

import { NO_IMG_DEEPLE_LOGO } from "constants/Image";
import { ImageWrapper } from "./styled";

type SynonymCardPropsType = {
  word: string;
  synonyms: string[];
  children?: ReactNode;
  image?: string;
  onChange: (word: string, synonyms: string[]) => void;
};

const convertDataToSelectOptionFormat = (originalData: string[]) =>
  originalData.map((data: string) => ({
    label: data,
    value: data,
  }));

const SynonymCard: FC<SynonymCardPropsType> = (props) => {
  const { t } = useTranslation();
  const { word, synonyms, children, image, onChange } = props;

  const isShowImage = typeof image === "string";

  const handleChangeSynonyms = (event: ValueType<OptionType>) => {
    let changedSynonyms: string[] = [];
    if (event) {
      changedSynonyms = (event as OptionType[]).map(({ value }) => value);
    }
    onChange(word, changedSynonyms);
  };

  return (
    <Card noShadow>
      <Grid container>
        <Grid item container xs={12} alignItems="center">
          {isShowImage && (
            <Grid item container xs={2}>
              <ImageWrapper alt="no img" src={image || NO_IMG_DEEPLE_LOGO} />
            </Grid>
          )}
          <Grid item xs={isShowImage ? 10 : 12} className={isShowImage ? "pl-3" : "pt-2 pl-1"}>
            <Typography variant="title7" color="dark">
              {word}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TagInputWithLabel
            className="m-0"
            placeholder={t("Add new key")}
            value={convertDataToSelectOptionFormat(synonyms)}
            onChange={handleChangeSynonyms}
          />
        </Grid>
        {children && (
          <Grid item xs={12} className="pt-1">
            {children}
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default SynonymCard;
