import React, { FC, useRef, useState } from "react";
import { useDropzone, FileRejection } from "react-dropzone";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import isEqual from "lodash/isEqual";

import Modal from "components/Modal";
import CircularProgress from "components/CircularProgress";
import { NOTIFICATION_UPLOAD } from "constants/Notification";
import { UploadDirectoryType, ImageUrl } from "types/Image";
import { MessageType } from "types/Chat";
import { notifyError } from "utils/notify";

import { compressImage } from "utils/compressImage";
import useUploadImage from "./useUploadImage";
import { Wrapper } from "./styled";

type DropZoneUploadPropsType = {
  projectId: string;
  onChange?: (urlImage: ImageUrl[]) => void;
  onCancel?: () => void;
  limitItem?: number;
  disabled?: boolean;
  multiple?: boolean;
  noDrag?: boolean;
  noClick?: boolean;
  UploadDirectory?: UploadDirectoryType;
  customerId?: string;
  maxWidthOrHeight?: number;
  maxSizeMB?: number;
  width?: number;
  height?: number;
};

const DropZoneUpload: FC<DropZoneUploadPropsType> = (props) => {
  const {
    children,
    projectId,
    onChange = () => {},
    limitItem = 0,
    disabled = false,
    multiple = false,
    noDrag = false,
    noClick = false,
    UploadDirectory = UploadDirectoryType.PROJECT_PUBLIC,
    customerId,
    maxWidthOrHeight,
    maxSizeMB,
    width,
    height,
    onCancel,
  } = props;
  const { t } = useTranslation();
  const urlImageRef = useRef<ImageUrl[]>([]);
  const acceptedFilesRef = useRef<File[]>([]);
  const fileRejectionsRef = useRef<FileRejection[]>([]);
  const uploadImage = useUploadImage();

  const initDropzoneProps = {
    accept: "image/jpg, image/jpeg, image/png",
    multiple: multiple && limitItem !== 1,
    noDrag,
    noClick,
    disabled,
    onFileDialogCancel: onCancel,
  };

  const dropzoneProps =
    limitItem != null && limitItem > 0 ? { ...initDropzoneProps, maxFiles: limitItem } : initDropzoneProps;

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone(dropzoneProps);

  const [isUploadInProgress, setIsUploadInProgress] = useState(false);

  const handleUpload = async (file: File, fileCount: number) => {
    try {
      const newUrlImage = await uploadImage(projectId, file, UploadDirectory, customerId, {
        maxSizeMB,
        maxWidthOrHeight,
      });
      const indexOtherType = urlImageRef.current.findIndex((image) => image.type !== MessageType.IMAGE);
      if (indexOtherType > -1) {
        urlImageRef.current = [{ ...newUrlImage, type: MessageType.IMAGE }];
      } else {
        urlImageRef.current = [...urlImageRef.current, { ...newUrlImage, type: MessageType.IMAGE }];
      }

      const currentUrls = urlImageRef.current;
      if (fileCount === currentUrls.length) {
        onChange(currentUrls);
        setIsUploadInProgress(false);
      }
    } catch (error) {
      console.error(error);
      notifyError(t(NOTIFICATION_UPLOAD.FAIL));
    }
  };

  if (!isEqual(acceptedFilesRef.current, acceptedFiles) || !isEqual(fileRejectionsRef.current, fileRejections)) {
    acceptedFilesRef.current = acceptedFiles;
    fileRejectionsRef.current = fileRejections;

    if (fileRejections.length) {
      let errorText = "Please try again later";

      if (fileRejections?.[0]?.errors?.[0]?.code === "file-invalid-type") {
        errorText = "uploadImage.type.error";
      }

      if (fileRejections?.[0]?.errors?.[0]?.code === "too-many-files") {
        errorText = "uploadImage.limit.error";
      }

      notifyError(t(errorText, { limitItem }));
    }

    if (acceptedFiles.length) {
      setIsUploadInProgress(true);
      urlImageRef.current = [];

      acceptedFiles.forEach((acceptedFile) => {
        const reader = new FileReader();

        reader.onload = async (input) => {
          const base64Url = get(input, "target.result");

          const image = new Image();
          image.addEventListener("load", async () => {
            // only select images within width/height limits
            if (width && image.width !== width) {
              notifyError(t("uploadImage.width.error"));
              setIsUploadInProgress(false);
              return;
            }

            if (height && image.height !== height) {
              notifyError(t("uploadImage.width.error"));
              setIsUploadInProgress(false);
              return;
            }

            const compressedAcceptedFile: File | null = await compressImage(acceptedFile, maxWidthOrHeight, maxSizeMB);

            if (compressedAcceptedFile) {
              handleUpload(compressedAcceptedFile, acceptedFiles.length);
            } else {
              notifyError(t("uploadImage.largeSize.error"));
              setIsUploadInProgress(false);
            }
          });

          image.src = base64Url;
        };

        reader.readAsDataURL(acceptedFile);
      });
    }
  }

  return (
    <Wrapper {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
      <Modal isOpen={isUploadInProgress} onClose={() => {}}>
        <CircularProgress className="m-4" />
      </Modal>
    </Wrapper>
  );
};

export default DropZoneUpload;
