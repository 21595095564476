/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import IcClose from "components/SvgIcons/IcClose";
import { OptionType } from "./Option/type";
import { StyledTag, DeleteTagButton, Label } from "./styled";

type TagProps = {
  option: OptionType<any>;
  onDelete?: (option: OptionType<any>) => void;
};

const Tag: FC<TagProps> = ({ option, onDelete = () => {} }) => {
  const handleDelete = () => {
    onDelete(option);
  };
  const selectedNo = option?.options?.reduce((total, current) => (current.isChecked ? total + 1 : total), 0) || 0;

  return (
    <StyledTag>
      <Label className="ellipsis p-1">{option.label}</Label>
      <div className="py-1 pr-1">{`(${selectedNo}/${option.value.productSKUs.length})`}</div>
      <DeleteTagButton onClick={handleDelete}>
        <IcClose />
      </DeleteTagButton>
    </StyledTag>
  );
};

export default Tag;
