export const cjLogisticsDescription = {
  EN: null,
  TH: [
    { body: "1. ยืนยันตัวตนด้วยบัตรประชาชนและบัญชีธนาคาร ที่ หน้ายืนยันตัวตน" },

    {
      body:
        "2. สร้างรายการเก็บของโดยกรอกข้อมูลที่ช่องเก็บเงินปลายทาง ซึ่งจะปรากฏขึ้นหลังจากที่ได้ยืนยันตัวตน ตามข้อที่ 1",
    },

    { body: "3. พิมพ์ใบปะหน้า และแปะลงบนหน้ากล่อง" },

    {
      body: "4. เข้ารับสินค้าในพื้นที่ต้นทางเดียวกันตามรายละเอียดดังนี้",

      subBody: [
        "พื้นที่ต้นทางเข้ารับ กรุงเทพ จะต้องมี ขั้นต่ำ 20 ชิ้นขึ้นไป ในการเข้ารับต่อจุด",
        "พื้นที่ต้นทางเข้ารับ ปริมณฑล และต่างจังหวัด จะต้องมี ขั้นต่ำ 1 ชิ้นขึ้นไป ในการเข้ารับต่อจุด",
      ],
    },

    { body: "5. ขนาดของพัสดุ (กว้าง+ยาว+สูง) ไม่เกิน 200 ซม. โดยแต่ละด้านขนาดไม่เกิน 100 เซนติเมตร" },

    { body: "6. สินค้าต้องมีน้ำหนักไม่เกิน 30 กิโลกรัม" },

    {
      body:
        "7. ให้บริการทุกวันจันทร์ - เสาร์ โดยชำระเงินก่อน 11.30 น. เข้ารับสินค้าวันเดียวกัน หากชำระเงินหลัง 11.31 น. จะเข้ารับสินค้าวันถัดไป",
    },

    { body: "8. Cj logistics จะคิดค่าธรรมเนียมเก็บเงินปลายทาง คิดจากยอดเรียกเก็บ 3% ไม่มีขั้นต่ำ" },

    { body: "9. บริษัทจะทําการโอนเงินที่ได้รับชําระมาแล้ว ทุกวันศุกร์ หากเป็นวันหยุดจะทําการโอนคืนในวันทําการถัดไป" },

    {
      body:
        "10. ยอดโอนคืนขั้นตํ่า 500 บาท หลังหักค่าธรรมเนียม COD (3%) แล้ว ส่วนค่าธรรมเนียมในการโอนขึ้นอยู่กับธนาคารของผู้ใช้งาน",
    },

    { body: "11. กําหนดการใช้บริการ COD ยอดขั้นตํ่าของสินค้า คือ 100 บาท สูงสุดไม่เกิน 50,000 บาท" },

    { body: "12. การรับประกันสินค้าตามราคาจริง และไม่เกิน 2,000 บาท" },

    { body: "13. หากระบุข้อมูลปลายทางผิดพลาด ทางบริษัทขอสงวนสิทธิ์ ในการแก้ไข ยกเลิกและคืนเงิน" },

    { body: "14. หากสินค้ามีการตีคืนจะมีการเรียกเก็บเงินภายหลัง" },
  ],
};
