import MuiBadge from "@material-ui/core/Badge";
import styled from "styled-components";
import Colors from "constants/Colors";

const FloatBadge = styled(MuiBadge)`
  .MuiBadge-anchorOriginTopRightRectangle {
    color: ${Colors.White};
    background-color: ${Colors.Badge};
    font-size: 10px;
    font-weight: 500;
    height: 16px;
    padding: 0 4px;
  }
`;

export default FloatBadge;
