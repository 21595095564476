import React, { FC, ReactNode } from "react";
import styled from "styled-components";

import { Z_INDEX } from "constants/ZIndex";
import { SIDE_NAV_WIDTH_DESKTOP } from "constants/Layout";

type StickyPanelPropsType = {
  children: ReactNode;
  className?: string;
};

const Wrapper = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: ${Z_INDEX.FLOATING_BUTTON};
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: calc(100% - ${SIDE_NAV_WIDTH_DESKTOP}px);
  }
`;

const StickyPanel: FC<StickyPanelPropsType> = (props) => {
  const { children, className } = props;
  return <Wrapper className={className}>{children}</Wrapper>;
};

export const StickyButtonContainer = styled.div`
  position: fixed;
  bottom: 80px;
  right: 8px;
  &.show-save-button {
    bottom: 64px;
  }
`;

export default StickyPanel;
