import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ValueType, OptionType } from "react-select";
import get from "lodash/get";

import Select from "components/Select";
import Grid from "components/Grid";
import Card from "components/Card";
import Button from "components/Button";
import Typography from "components/Typography";
import { SvgIcon } from "components/Icon";
import { IcDelete } from "components/SvgIcons";
import TagInputWithLabel from "components/TagInputWithAutoSuggest";
import TextField from "components/TextField";

import { MAXIMUM_VARIATION_NAME_STRING_LENGTH } from "config";
import COLORS from "constants/Colors";
import { EXPIRY_DATE_FORMAT } from "constants/DateTimeFormat";
import {
  DigitalContentAvailability,
  Product,
  ProductType,
  ProductPropertiesType,
  ProductVariationsType,
  AddProductTypeType,
  AddProductSKUType,
  ProductOutputType,
} from "types/Product";
import nanoid from "utils/nanoid";
import { constructSKUsData } from "utils/product/constructSKUsData";

import ConsoleGeneratedForm from "./ConsoleGeneratedForm";
import DeepLinkForm from "./DeepLinkForm";
import { BinIconContainer, ProductSKUCardWrapper } from "../AddProductInTypeSKUForm/addProduct.styled";

type DigitalProductContentPropsType = {
  productData: Product;
  setProductData: Function;
  handleSubmitForm: Function;
  setValue: Function;
  productId?: string;
  productIsActive: boolean;
  productIsFree: boolean;
  productSKUs: AddProductSKUType[];
  productSKUShadowId?: string | null;
  propertyListData: ProductPropertiesType[];
  setProductSKUs: Function;
  setVariationListData: Function;
  variationListData: ProductVariationsType[];
  isDisplaySave?: boolean;
  productType: ProductType;
  outputType?: ProductOutputType;
  isEditMode: boolean;
};

const DigitalProductContentTab: React.FC<DigitalProductContentPropsType> = (props) => {
  const { t } = useTranslation();

  const digitalContentProductOutputTypeOptions = [
    {
      label: t("CODE_AUTO_GENERATED"),
      value: ProductOutputType.CODE_AUTO_GENERATED,
    },
    { label: t("STATIC"), value: ProductOutputType.STATIC },
    { label: t("CODE_GENERATED_FROM_OTHER"), value: ProductOutputType.CODE_GENERATED_FROM_OTHER },
    { label: t("CODE_IMPORTED"), value: ProductOutputType.CODE_IMPORTED },
  ];

  const {
    productData,
    setProductData,
    setValue,
    handleSubmitForm,
    productId,
    productIsActive,
    productIsFree,
    productSKUs,
    productSKUShadowId,
    setProductSKUs,
    setVariationListData,
    variationListData,
    productType,
    outputType,
    isEditMode,
  } = props;

  const convertDataToSelectOptionFormat = (originalData: string[]) => {
    return originalData.map((data: string) => ({
      label: data,
      value: data,
    }));
  };

  const handleRemoveVariation = (variationNanoId: string) => {
    const filteredNewVariation = variationListData.filter(
      (variation: ProductVariationsType) => variation.nanoid !== variationNanoId,
    );
    setVariationListData(filteredNewVariation);
    setProductSKUs(
      constructSKUsData(
        productData,
        productSKUs,
        productId || "",
        productIsActive,
        productIsFree,
        filteredNewVariation,
        productSKUShadowId || "",
        outputType,
      ),
    );
  };

  const handleAddNewVariation = () => {
    const newVariations = [...variationListData, { nanoid: nanoid(), key: "SKU name", value: [] }];
    setVariationListData(newVariations);
    setProductSKUs(
      constructSKUsData(
        productData,
        productSKUs,
        productId || "",
        productIsActive,
        productIsFree,
        newVariations,
        productSKUShadowId || "",
        outputType,
      ),
    );
  };

  const handleVariationNameChange = (
    variationNanoId: string,
    newVariationName: string,
    variation: ProductVariationsType,
  ) => {
    if (newVariationName.length <= MAXIMUM_VARIATION_NAME_STRING_LENGTH) {
      const tempVariationListData = variationListData.map((VariationItem: ProductVariationsType) => {
        const returnVariationItem = { ...VariationItem };
        if (VariationItem.nanoid === variationNanoId) {
          returnVariationItem.key = newVariationName;
        }
        return returnVariationItem;
      });

      setVariationListData(tempVariationListData);

      const newProductSKUs = productSKUs.map((productSKU: AddProductSKUType) => {
        const returnProductSKUs = { ...productSKU };
        returnProductSKUs.productType.map((productType: AddProductTypeType) => {
          const returnProductType = { ...productType };
          if (returnProductType.key.key === variation.key) {
            returnProductType.key.key = newVariationName;
          }
          return returnProductType;
        });

        return returnProductSKUs;
      });
      setProductSKUs(newProductSKUs);
    }
  };

  const handleVariationValueChange = (variationNanoId: string, newVariationValue: ValueType<OptionType>) => {
    const tempVariationListData = variationListData.map((VariationItem: ProductVariationsType) => {
      const returnVariationItem = { ...VariationItem };
      if (VariationItem.nanoid === variationNanoId) {
        returnVariationItem.value = (newVariationValue as OptionType[]).map((tagInputValue) => {
          return tagInputValue.value;
        });
      }

      return returnVariationItem;
    });
    setVariationListData(tempVariationListData);
    setProductSKUs(
      constructSKUsData(
        productData,
        productSKUs,
        productId || "",
        productIsActive,
        productIsFree,
        tempVariationListData,
        productSKUShadowId || "",
        outputType,
      ),
    );
  };

  const handleChangeProductSKUsOutputType = (outputType: ProductOutputType) => {
    switch (outputType) {
      case ProductOutputType.CODE_AUTO_GENERATED:
        {
          const newProductSKUsDataWithOutputType = {
            ...productData,
            outputType, // set both Product and SKU
            productSKUs: productData.productSKUs.map((productSKU) => {
              const newData = {
                ...productSKU,
                digitalContentOptions: {
                  outputType, // set both Product and SKU
                  settings: {
                    availability: { type: DigitalContentAvailability.NO_EXPIRY, settings: {} },
                  },
                },
              };
              return newData;
            }),
          };
          setValue("productSKUs", newProductSKUsDataWithOutputType.productSKUs);
          setProductData(newProductSKUsDataWithOutputType);
        }
        break;

      case ProductOutputType.STATIC:
        {
          const newProductSKUsDataWithOutputType = {
            ...productData,
            outputType, // set both Product and SKU
            productSKUs: productData.productSKUs.map((productSKU) => {
              const newData = {
                ...productSKU,
                digitalContentOptions: {
                  outputType, // set both Product and SKU
                  settings: {
                    content: "",
                  },
                },
              };
              return newData;
            }),
          };
          setValue("productSKUs", newProductSKUsDataWithOutputType.productSKUs);
          setProductData(newProductSKUsDataWithOutputType);
        }
        break;

      case ProductOutputType.CODE_IMPORTED:
        {
          const newProductSKUsDataWithOutputType = {
            ...productData,
            outputType, // set both Product and SKU
            productSKUs: productData.productSKUs.map((productSKU) => {
              const newData = {
                ...productSKU,
                digitalContentOptions: {
                  outputType, // set both Product and SKU
                  settings: {
                    availability: { type: DigitalContentAvailability.NO_EXPIRY },
                  },
                },
              };
              return newData;
            }),
          };
          setValue("productSKUs", newProductSKUsDataWithOutputType.productSKUs);
          setProductData(newProductSKUsDataWithOutputType);
        }
        break;

      case ProductOutputType.CODE_GENERATED_FROM_OTHER:
        {
          const newProductSKUsDataWithOutputType = {
            ...productData,
            outputType, // set both Product and SKU
            productSKUs: productData.productSKUs.map((productSKU) => {
              const newData = {
                ...productSKU,
                digitalContentOptions: {
                  outputType, // set both Product and SKU
                },
              };
              return newData;
            }),
          };
          setValue("productSKUs", newProductSKUsDataWithOutputType.productSKUs);
          setProductData(newProductSKUsDataWithOutputType);
        }
        break;

      default:
        break;
    }
  };

  const handleChangeDeepLink = (nanoid: string, link: string) => {
    const newProductSKUsDataWithOutputType = {
      ...productData,
      productSKUs: productData.productSKUs.map((productSKU) => {
        if (get(productSKU, "nanoid") === nanoid) {
          const newData = {
            ...productSKU,
            digitalContentOptions: {
              ...productSKU.digitalContentOptions,
              settings: {
                content: link,
              },
            },
          };
          return newData;
        }
        return productSKU;
      }),
    };
    setValue("productSKUs", newProductSKUsDataWithOutputType.productSKUs);
    setProductData(newProductSKUsDataWithOutputType);
  };

  const getSettingsFromOptionType = (
    newAvailabilityOptionType: DigitalContentAvailability,
    productDataOutputType: ProductOutputType | undefined,
  ) => {
    if (productDataOutputType === ProductOutputType.CODE_IMPORTED) {
      return {}; // no need settings in import customer have to import later
    }
    let settingsValue = {};
    switch (newAvailabilityOptionType) {
      case DigitalContentAvailability.PERIOD_AFTER_PURCHASED:
        settingsValue = { unit: "days", value: 30 };
        break;
      case DigitalContentAvailability.EXPIRY_DATE:
        settingsValue = { expiredAt: moment().add(7, "days").format(EXPIRY_DATE_FORMAT) };
        break;
      // case DigitalContentAvailability.DATE_RANGE: // not support yet
      //   settingsValue = {
      //     availableAt: moment().format(EXPIRY_DATE_FORMAT),
      //     expiredAt: moment().add(7, "days").format(EXPIRY_DATE_FORMAT),
      //   };
      //   break;
      case DigitalContentAvailability.NO_EXPIRY:
        break;
      default:
        break;
    }
    return settingsValue;
  };

  const setSettingsFromOptionType = (
    newAvailabilityOptionType: DigitalContentAvailability,
    newValue: string | number,
  ) => {
    let settingsValue = {};
    switch (newAvailabilityOptionType) {
      case DigitalContentAvailability.PERIOD_AFTER_PURCHASED:
        settingsValue = { unit: "days", value: newValue };
        break;
      case DigitalContentAvailability.EXPIRY_DATE:
        settingsValue = { expiredAt: newValue };
        break;
      // case DigitalContentAvailability.DATE_RANGE: // not support yet
      //   settingsValue = {
      //     availableAt: moment().format(EXPIRY_DATE_FORMAT),
      //     expiredAt: moment().add(7, "days").format(EXPIRY_DATE_FORMAT),
      //   };
      //   break;
      case DigitalContentAvailability.NO_EXPIRY:
        break;
      default:
        break;
    }
    return settingsValue;
  };

  const handleChangeDigitalContentAvailabilityType = (
    nanoid: string,
    newAvailabilityOptionType: DigitalContentAvailability,
  ) => {
    const newProductSKUsDataWithOutputType = {
      ...productData,
      productSKUs: productData.productSKUs.map((productSKU) => {
        // nanoid for first time add , id for edit
        if (get(productSKU, "nanoid") === nanoid) {
          const newData = {
            ...productSKU,
            digitalContentOptions: {
              ...productSKU.digitalContentOptions,
              settings: {
                ...get(productSKU, "digitalContentOptions.settings"),
                availability: {
                  ...get(productSKU, "digitalContentOptions.settings.availability"),
                  type: newAvailabilityOptionType,
                  settings: getSettingsFromOptionType(newAvailabilityOptionType, get(productData, "outputType")),
                },
              },
            },
          };
          return newData;
        }
        return productSKU;
      }),
    };
    setValue("productSKUs", newProductSKUsDataWithOutputType.productSKUs);
    setProductData(newProductSKUsDataWithOutputType);
  };

  const handleChangeExpiryDate = (nanoid: string, newValue: string | number) => {
    const newProductSKUsDataWithOutputType = {
      ...productData,
      productSKUs: productData.productSKUs.map((productSKU) => {
        if (get(productSKU, "nanoid") === nanoid) {
          const newData = {
            ...productSKU,
            digitalContentOptions: {
              ...productSKU.digitalContentOptions,
              settings: {
                ...get(productSKU, "digitalContentOptions.settings"),
                availability: {
                  ...get(productSKU, "digitalContentOptions.settings.availability"),
                  settings: setSettingsFromOptionType(
                    get(productSKU, "digitalContentOptions.settings.availability.type"),
                    newValue,
                  ),
                },
              },
            },
          };
          return newData;
        }
        return productSKU;
      }),
    };

    setValue("productSKUs", newProductSKUsDataWithOutputType.productSKUs);

    setProductData(newProductSKUsDataWithOutputType);
  };

  const isValidToSave = !productData.productSKUs.length || !productData.outputType;

  const isShadowSKU = Boolean(variationListData.length === 0);

  return (
    <>
      <ProductSKUCardWrapper className="pb-3">
        <Card borderRadius={0} noShadow>
          <Grid container className="my-4" justify="space-between">
            <Typography variant="title2">{t("VariationsTitle")}</Typography>
            <Typography variant="body4" className="ml-2" color={COLORS.DarkMed}>
              {variationListData.length} / 2
            </Typography>
          </Grid>

          {variationListData.map((variation: ProductVariationsType, index: number) => {
            return (
              <div key={variation.nanoid}>
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography className="d-flex" variant="body4">
                      {t("SKU name")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container justify="space-between" alignItems="center" alignContent="flex-end">
                      <Typography variant="body4" className="mr-2 ml-2 d-flex " color={COLORS.DarkMed}>
                        {variation.key.length} / {MAXIMUM_VARIATION_NAME_STRING_LENGTH}
                      </Typography>
                      <BinIconContainer className="d-flex" onClick={() => handleRemoveVariation(variation.nanoid)}>
                        <SvgIcon
                          className="ml-1 mt-1"
                          component={IcDelete}
                          fontSize="small"
                          htmlColor={COLORS.DarkMed}
                        />
                      </BinIconContainer>
                    </Grid>
                  </Grid>
                </Grid>
                <TextField
                  data-cy={`skuKey${index + 1}`}
                  className="my-3"
                  fullWidth
                  placeholder={t("Please fill variation value")}
                  value={variation.key}
                  error={variation.key.length === 0}
                  onChange={(event) => {
                    handleVariationNameChange(variation.nanoid, event.target.value, variation);
                  }}
                />
                <TagInputWithLabel
                  dataCy={`skuValue${index + 1}`}
                  label={t("SKU value")}
                  value={convertDataToSelectOptionFormat(variation.value)}
                  onChange={(event) => {
                    handleVariationValueChange(variation.nanoid, event || []);
                  }}
                />
              </div>
            );
          })}

          {variationListData && variationListData.length < 2 && (
            <Grid item>
              <Button color="secondary" className="p-2" fullWidth onClick={() => handleAddNewVariation()}>
                + {t("Add new variation")}
              </Button>
            </Grid>
          )}
        </Card>
      </ProductSKUCardWrapper>

      <Card borderRadius={0} noShadow>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="title2">Content</Typography>
            <Typography variant="body4" color="darkMed" className="mt-2">
              {t("Digital content output")}
            </Typography>
            <Select
              isDisabled={isEditMode}
              placeholder={t("Select digital content type")}
              isSearchable={false}
              className="mt-2"
              value={{
                label: t(productData.outputType || "Select digital content type"),
                value: productData.outputType || "",
              }}
              onChange={(selectedValue: ValueType<OptionType>) => {
                handleChangeProductSKUsOutputType(get(selectedValue, "value"));
              }}
              options={digitalContentProductOutputTypeOptions}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            {(get(productData, "outputType") === ProductOutputType.CODE_AUTO_GENERATED ||
              get(productData, "outputType") === ProductOutputType.CODE_IMPORTED ||
              get(productData, "outputType") === ProductOutputType.CODE_GENERATED_FROM_OTHER) && (
              <ConsoleGeneratedForm
                productName={productData.name}
                productImage={get(productData, "images[0].src")}
                productSKUs={productData.productSKUs}
                onChangeDigitalContentAvailabilityType={handleChangeDigitalContentAvailabilityType}
                onChangeExpiryDate={handleChangeExpiryDate}
                setProductSKUs={setProductSKUs}
                productType={productType}
                isEditMode={isEditMode}
                isShadowSKU={isShadowSKU}
                isCodeGenerateFromOther={get(productData, "outputType") === ProductOutputType.CODE_GENERATED_FROM_OTHER}
              />
            )}

            {get(productData, "outputType") === ProductOutputType.STATIC && (
              <DeepLinkForm
                productName={productData.name}
                productImage={get(productData, "images[0].src")}
                productSKUs={productData.productSKUs}
                onChangeDeepLink={handleChangeDeepLink}
                setProductSKUs={setProductSKUs}
                productType={productType}
                isEditMode={isEditMode}
                isShadowSKU={isShadowSKU}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className="py-4">
          <Button
            fullWidth
            disabled={isValidToSave}
            onClick={() => {
              handleSubmitForm();
            }}
          >
            {t("Save")}
          </Button>
        </Grid>
      </Card>
    </>
  );
};

export default DigitalProductContentTab;
