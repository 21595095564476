/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import get from "lodash/get";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import Button from "components/Button";
import Card from "components/Card";
import TextField from "components/TextField";
import ErrorText from "components/ErrorText";
import Dropzone from "components/DropzoneUpload";
import Typography from "components/Typography";
import AutoCompleteAddress from "components/AutoCompleteAddress";

import { STORE_DETAIL_FORMS } from "constants/Store";
import { StoreDetail } from "types/Store";
import { ImageUrl } from "types/Image";

import { RemovePhotoButton, SocialIcon } from "../style";
import ImagePlaceholder from "../ImagePlaceholder";

const MAXIMUM_DIMENSION_SIZE_STORE_IMAGE = 512;
type StoreDetailFormPropsType = {
  projectId: string;
  defaultValueImageSrc?: string;
  imageSrc: string;
  onChangeImage?: (imageUrls: ImageUrl[]) => void;
  onRemoveImage?: () => void;
  storeDetail?: StoreDetail;
};

const StoreDetailForm: FC<StoreDetailFormPropsType> = ({
  projectId,
  defaultValueImageSrc = "",
  imageSrc,
  onChangeImage = () => {},
  onRemoveImage = () => {},
  storeDetail,
}) => {
  const { t } = useTranslation();
  const { errors, control, setValue } = useFormContext();

  return (
    <Card noShadow padding="24px" className="mb-4">
      <Grid container item>
        <Grid item>
          <Controller
            render={() => <ImagePlaceholder src={imageSrc} />}
            name="image"
            defaultValue={defaultValueImageSrc}
            control={control}
          />
        </Grid>

        <Grid container item xs={6} alignItems="flex-end">
          <Grid item xs={12} className="text-center">
            <Dropzone
              projectId={projectId}
              noDrag
              onChange={onChangeImage}
              maxWidthOrHeight={MAXIMUM_DIMENSION_SIZE_STORE_IMAGE}
            >
              <Button color="ghost" size="small" className="px-4 py-1">
                {t("Upload")} {t("Image")} <br />
                512 × 512 px
              </Button>
            </Dropzone>
          </Grid>

          <Grid item xs={12} className="text-center">
            <RemovePhotoButton onClick={onRemoveImage}>{t("Remove photo")}</RemovePhotoButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ErrorText>{errors.image && t(errors.image.message as string)}</ErrorText>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item className="px-1 pt-2" xs={12}>
          <Typography variant="body3" className="pb-1">
            {t("First name")}
          </Typography>
          <Controller
            as={<TextField error={Boolean(errors.name)} fullWidth variant="outlined" />}
            control={control}
            name="name"
            defaultValue={get(storeDetail, "name", "")}
          />
          <ErrorText>{errors.name && errors.name.message}</ErrorText>
        </Grid>
        <Grid item className="px-1 pt-2" xs={12}>
          <Typography variant="body3" className="pb-1">
            {t("Address")}
          </Typography>
          <Controller
            as={<TextField error={Boolean(errors.address)} fullWidth variant="outlined" />}
            control={control}
            name="address"
            defaultValue={get(storeDetail, "address", "")}
          />
          <ErrorText>{errors.address && errors.address.message}</ErrorText>
        </Grid>

        <Grid item className="px-1 pt-2" xs={12}>
          <AutoCompleteAddress setAddressData={setValue} provinceFieldName="city" postalCodeFieldName="postcode" />
        </Grid>

        {STORE_DETAIL_FORMS.map(({ name, label, placeholder, icon }) => (
          <Grid item xs={12} key={name} className="px-1 pt-2">
            <Typography variant="body3" className="pb-1">
              {t(label)}
            </Typography>
            <div className="position-relative">
              {icon && <SocialIcon src={icon} alt="icon" />}
              <Controller
                as={
                  <TextField
                    error={Boolean(errors[name])}
                    fullWidth
                    placeholder={t(placeholder)}
                    className={clsx({ "pl-4": icon })}
                    variant="outlined"
                  />
                }
                control={control}
                name={name}
                defaultValue={get(storeDetail, name, "")}
              />
              <ErrorText>{errors[name] && t(errors[name]!.message as string)}</ErrorText>
            </div>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default StoreDetailForm;
