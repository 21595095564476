const wallet = {
  en: {
    "wallet.header": "Wallet",
    "wallet.invoice.title.main": "Credit cost invoice",
    "wallet.invoice.title.logistic": "Shipping cost invoice",
    "wallet.invoice.loadMore": "See all invoice",
    "wallet.history.title": "History",
    "wallet.history.loadMore": "Load more",
    "wallet.rightLabel.showAll": "See all invoice",
    "wallet.card.label.main": "CREDIT",
    "wallet.card.label.logistic": "SHIPPING",
    "wallet.button.main": "CREDIT",
    "wallet.button.logistic": "SHIPPING",
    "wallet.invoiceModal.header": "INVOICE",
    "wallet.history.filter.thisMonth": "This month",
    "wallet.invoice.title": "Invoice",
    "transactionType.creditCard": "Top up",
    "transactionType.freeCredit": "Bonus credit",
    "transactionType.lalamove": "Lalamove",
    "transactionType.package": "Monthly Package",
    "transactionType.packageAndAddon": "Monthly Package",
    "transactionType.addOn": "Activated Add On",
    "transactionType.promoCode": "Promotion code",
    "transactionType.topUp": "Top up",
    "transactionType.shippop": "Shippop",
  },
  th: {
    "wallet.header": "วอลเล็ท",
    "wallet.invoice.title.main": "ใบแจ้งยอดชำระบัตรเครดิต",
    "wallet.invoice.title.logistic": "ใบแจ้งยอดชำระค่าบริการการส่งสินค้า",
    "wallet.invoice.loadMore": "ดูใบแจ้งค่าบริการทั้งหมด",
    "wallet.history.title": "ประวัติการทำรายการ",
    "wallet.history.loadMore": "ดูเพิ่มเติม",
    "wallet.rightLabel.showAll": "ดูใบแจ้งค่าบริการทั้งหมด",
    "wallet.card.label.main": "เครดิตคงเหลือ",
    "wallet.card.label.logistic": "ค่าจัดส่งคงเหลือ",
    "wallet.button.main": "เครดิต",
    "wallet.button.logistic": "การจัดส่งสินค้า",
    "wallet.invoiceModal.header": "ใบแจ้งค่าบริการ",
    "wallet.history.filter.thisMonth": "เดือนนี้",
    "wallet.invoice.title": "ใบแจ้งค่าบริการ",
    "transactionType.creditCard": "เติมเงินเข้าระบบ",
    "transactionType.freeCredit": "โบนัสเครดิต",
    "transactionType.lalamove": "ลาล่ามูฟ",
    "transactionType.package": "แพ็กเกจรายเดือน",
    "transactionType.packageAndAddon": "แพ็กเกจรายเดือน",
    "transactionType.addOn": "เปิดใช้งานฟีเจอร์เสริม",
    "transactionType.promoCode": "โปรโมชั่นโค้ด",
    "transactionType.topUp": "เติมเงินเข้าระบบ",
    "transactionType.shippop": "Shippop",
  },
};

export default wallet;
