import { IcAiWelcomemessage, IcAiGeneral } from "components/SvgIcons";
import IcChartCustomer from "components/SvgIcons/IcChartCustomer";
import { SALES_REPORT, OVERVIEW, CUSTOMERS_REPORT } from "constants/Router";

export const TAB_CONFIGS = [
  {
    id: OVERVIEW,
    title: "dashboard.header", // Overview
    svgIcon: IcAiGeneral,
  },
  {
    id: SALES_REPORT,
    title: "Sales report",
    svgIcon: IcAiWelcomemessage,
  },
  {
    id: CUSTOMERS_REPORT,
    title: "CUSTOMER_REPORT",
    svgIcon: IcChartCustomer,
  },
];
