import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Card from "components/Card";
import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import Typography from "components/Typography";

import COLORS from "constants/Colors";
import { STAT_WIDGET_TYPE } from "constants/Stat";
import { StatWidgetType } from "types/Stat";

import StatIcon from "../StatIcon";
import { PercentageChangedRow } from "../index.styled";

type StatWidgetProps = StatWidgetType & {
  isLoading: boolean;
};

const StatWidget: FC<StatWidgetProps> = ({ label, value, changedValue, type, isLoading }) => {
  const { t } = useTranslation();

  let textColor = COLORS.DarkLight;
  if (changedValue) {
    textColor = changedValue > 0 ? COLORS.Success : COLORS.Error;
  }

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card padding="24px" noShadow>
        <Grid container>
          <Grid item xs={12} className="pb-2">
            <Typography variant="title8" color="darkMed">
              {t(label)}
            </Typography>
          </Grid>
          {isLoading ? (
            <Grid container justify="center">
              <CircularProgress className="m-4" />
            </Grid>
          ) : (
            <>
              {value && (
                <Grid item container xs={12} className="pb-3" alignItems="center">
                  {type === STAT_WIDGET_TYPE.PRICE && (
                    <Grid item className="pr-2">
                      <Typography variant="title7" color="dark">
                        {t("THB")}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item>
                    <Typography variant="title1" color="dark">
                      {value}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <PercentageChangedRow item container xs={12} justify="flex-end" alignItems="center">
                <Grid item>
                  <StatIcon type={type} value={changedValue} color={textColor} />
                </Grid>
                <Grid item>
                  <Typography variant="title7" color={textColor}>
                    {changedValue}
                    {type !== STAT_WIDGET_TYPE.CUSTOMER && !!changedValue && "%"}
                  </Typography>
                </Grid>
              </PercentageChangedRow>
            </>
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

export default StatWidget;
