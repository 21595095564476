import styled from "styled-components";
import MuiButtonBase from "@material-ui/core/ButtonBase";

export const TabButton = styled(MuiButtonBase)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px;
  min-width: 120px;
  border-color: ${({ theme }) => theme.COLORS.LightBlue};
  color: ${({ theme }) => theme.COLORS.DarkLight};

  &.active {
    border-color: ${({ theme }) => theme.COLORS.Primary} !important;
    color: ${({ theme }) => theme.COLORS.Primary};
  }

  &.active:first-child {
    background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  }

  &.active:last-child {
    background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;

  button {
    position: relative;
    background-color: ${({ theme }) => theme.COLORS.LightWhite};
    border-top: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
    border-bottom: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
    border-left: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
    border-right: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
  }

  button:first-child {
    border-radius: 8px 0 0 8px;
  }

  button:last-child {
    border-radius: 0 8px 8px 0;
  }
`;
