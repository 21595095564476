const addon = {
  en: {
    "planAddon.header": "Add-on",
    "planAddon.heading.title": "Add-on",
    "planAddon.error.title": "Add-on purchase is not allowed",
    "planAddon.error.description":
      "Please contact our customer success team for updating to our latest package version.",
    "planAddon.heading.subTitle": "Enhance your package with additional features",
    "planAddon.filter.label.member": "Seat",
    "planAddon.filter.label.image_search": "AI Visual product search",
    "planAddon.filter.label.advance_feature": "Advanced features",
    "planAddon.filter.label.broadcast": "AI Broadcast",
    "planAddon.filter.label.channel": "Channel",
    "planAddon.filter.label.integration": "Integration",
    "planAddon.addonCard.title.member": "Seat",
    "planAddon.addonCard.title.channel": "Channel",
    "planAddon.addonCard.title.image_search": "AI Visual product search",
    "planAddon.addonCard.monthlyPricePerUnit": " {{pricePerUnit}}/{{unit}} monthly",
    "planAddon.unit.member": "account",
    "planAddon.unit.channel": "channel",
    "planAddon.addonCard.currentAmount": "Current: {{currentAmount}}",
    "planAddon.subtotal.label": "Add-on change sub total",
    "planAddon.addonCard.modal.text.warningRemove.member": "Please remove a user before removing seat slot.",
    "planAddon.addonCard.modal.text.confirm.member": "Go to store members",
    "planAddon.addonCard.modal.text.warningRemove.channel":
      "Please Disconnect from LINE or Facebook before delete channel.",
    "planAddon.addonCard.modal.text.confirm.channel": "Go to connect",
    "planAddon.paymentSummary.AddOnPurchase": "Add-on purchase",
    "planAddon.paymentSummary.title": "Confirm your changes",
    "planAddon.paymentSummary.title.summary": "Monthly bill summary",
    "planAddon.paymentSummary.title.charged": "Charged summary",
    "planAddon.summaryItem.label.member": "Add-on Seat account",
    "planAddon.summaryItem.label.image_search": "Add-on AI Visual product search ({{itemQuota}} image searches)",
    "planAddon.summaryItem.label.offline_order": "Add-on Offline order",
    "planAddon.summaryItem.label.response_message": "Add-on Custom AI response message",
    "planAddon.summaryItem.label.broadcast": "Add-on Unlimited broadcast message",
    "planAddon.summaryItem.label.channel": "Add-on Channel",
    "planAddon.summaryItem.label.payment_gateway": "Add-on Payment gateway",
    "planAddon.summaryItem.label.inventory": "Add-on Inventory integration",
    "planAddon.summaryItem.label.zort_integration": "Add-on Zort integration",
    "planAddon.paymentSummary.label.previousSubtotal": "Your previous monthly add-on",
    "planAddon.paymentSummary.label.changesSubtotal": "Add-on change sub total",
    "planAddon.paymentSummary.label.subtotal": "Sub total",
    "planAddon.paymentSummary.label.vat": "Vat ({{vatOptions}})",
    "planAddon.paymentSummary.label.total": "Total",
    "planAddon.paymentSummary.description": "Add-on purchase will be billed as part of your monthly billing on ",
    "planAddon.paymentSummary.remark":
      "This {{chargedAddon}} add-on will be charged prorated amount ฿{{chargedPrice}} immediately.",
    "planAddon.paymentSummary.remarkDescription": "This {{chargedAddon}} add-on will be charged prorated",
    "planAddon.type.member": "member",
    "planAddon.type.offline_order": "offline order",
    "planAddon.type.response_message": "Custom AI response message",
    "planAddon.type.payment_gateway": "Payment gateway",
    "planAddon.type.inventory": "Inventory integration",
    "planAddon.type.zort_integration": "Zort integration",
    "planAddon.type.image_search": "AI Visual product search",
    "planAddon.type.channel": "Channel",
    "planAddon.type.broadcast": "Broadcast",
    "planAddon.paymentSummary.confirmModal.removeAddon":
      "Are you sure to continue delete an {{removedAddonType}} add-on? This change will be effects in your next billing.",
    "planAddon.addonItemCard.title.offline_order": "Offline order",
    "planAddon.addonItemCard.description.offline_order":
      "Add new orders to the shopping cart directly from the console.",
    "planAddon.addonItemCard.title.response_message": "Custom AI response message",
    "planAddon.addonItemCard.description.response_message":
      "Customize your bot's response messages in your own way and make it match your store characteristic.",
    "planAddon.addonItemCard.title.payment_gateway": "Payment gateway",
    "planAddon.addonItemCard.description.payment_gateway":
      "Add Payment gateways to allow payment with credit card, internet banking, and other.",
    "planAddon.addonItemCard.title.inventory": "Inventory integration",
    "planAddon.addonItemCard.description.inventory":
      "Enable Inventory integration to sync its orders, products and inventory with the console.",
    "planAddon.addonItemCard.title.zort_integration": "Zort integration",
    "planAddon.addonItemCard.description.zort_integration":
      "Enable Zort to sync its orders, products and inventory with the console.",
    "planAddon.addonItemCard.price": "฿ {{formatPrice}} monthly",
    "planAddon.addonItemCard.defaultActive": "Active by default",
    "planAddon.error.default": "Please try again later",
    "addon.imageSearch.disabled.description": "Cancel AI Visual Product Search",
    "addon.imageSearch.freeTrial": "Free Trial - ",
    "addon.imageSearch.item.title": "{{quota}} image searches",
    "addon.imageSearch.item.description": "฿ {{price}}",
    "addon.imageSearch.monthly": "monthly",
    "addon.imageSearch.contactUs.description": "Contact us for pricing evaluation",
    "addon.imageSearch.contactUs.button": "Contact us",
    "addon.imageSearch.extraPrice": "Extra search will be charged ฿{{price}}/search",
    "planAddon.changedBroadcast.title": "Your billing summary has updated",
    "planAddon.changedBroadcast.description": "Your last change will be effective in the next billing cycle.",
    "planAddon.changedBroadcast.button": "Go to your Billing",
    "planAddon.broadcast.disabled.description": "Broadcast message is not available",
    "planAddon.broadcast.defaultActive.title": "Active Broadcast Messages",
    "planAddon.broadcast.defaultActive.description": "Broadcast messages to advertise your products real-time",
    "planAddon.broadcast.unlimited.title": "Unlimited Broadcast Messages",
    "planAddon.broadcast.unlimited.description": "Broadcast unlimited number of messages monthly",
    "planAddon.paymentSummary.removedAddOn.description": "Removed Add-ons will be affected in your next billing cycle.",
    "planAddon.confirmModal.description1": "Add-on AI Visual product search will be fully charged, ",
    "planAddon.confirmModal.description2": "while the others will still remain in prorated charges.",
  },
  th: {
    "planAddon.header": "ฟีเจอร์เสริม",
    "planAddon.heading.title": "ฟีเจอร์เสริม",
    "planAddon.error.title": "ไม่สามารถซื้อแพ็คเกจเสริมได้",
    "planAddon.error.description": "กรุณาติดต่อฝ่ายบริการลูกค้าเพื่อเปลี่ยนเป็นแพ็คเกจแบบใหม่",
    "planAddon.heading.subTitle": "ปรับเปลี่ยนฟีเจอร์เสริมของคุณ",
    "planAddon.filter.label.member": "สมาชิก",
    "planAddon.filter.label.image_search": "การค้นหารูปภาพด้วย AI",
    "planAddon.filter.label.broadcast": "บรอดแคสต์",
    "planAddon.filter.label.advance_feature": "ฟีเจอร์ขั้นสูง",
    "planAddon.filter.label.channel": "ช่องทางการเชื่อมต่อ",
    "planAddon.addonCard.title.member": "สมาชิก",
    "planAddon.addonCard.title.channel": "ช่องทางการเชื่อมต่อ",
    "planAddon.addonCard.title.image_search": "การค้นหารูปภาพด้วย AI",
    "planAddon.addonCard.monthlyPricePerUnit": " {{pricePerUnit}}/{{unit}} ต่อเดือน",
    "planAddon.unit.member": "บัญชี",
    "planAddon.unit.channel": "ช่องทาง",
    "planAddon.addonCard.currentAmount": "ปัจจุบัน: {{currentAmount}}",
    "planAddon.subtotal.label": "ราคารวมฟีเจอร์เสริม ที่มีการเปลี่ยนแปลง",
    "planAddon.addonCard.modal.text.warningRemove.member": "กรุณาลบผู้ใช้งานก่อนลดจำนวนสมาชิก",
    "planAddon.addonCard.modal.text.confirm.member": "ไปยังสมาชิก",
    "planAddon.addonCard.modal.text.warningRemove.channel":
      "กรุณายกเลิกการเชื่อมต่อกับ LINE หรือ Facebook ก่อนที่จะลบ ช่องทางการเชื่อมต่อ",
    "planAddon.addonCard.modal.text.confirm.channel": "ไปยังการเชื่อมต่อ",
    "planAddon.paymentSummary.AddOnPurchase": "ซื้อฟีเจอร์เสริม",
    "planAddon.paymentSummary.title": "ยืนยันการเปลี่ยนแปลง",
    "planAddon.paymentSummary.title.summary": "สรุปค่าใช้จ่ายรายเดือน",
    "planAddon.paymentSummary.title.charged": "สรุปค่าใช้จ่ายเพิ่มเติม",
    "planAddon.summaryItem.label.member": "ฟีเจอร์เสริม สมาชิก",
    "planAddon.summaryItem.label.image_search": "ฟีเจอร์เสริม การค้นหารูปภาพด้วย AI ({{itemQuota}} รูป)",
    "planAddon.summaryItem.label.offline_order": "ฟีเจอร์เสริม รายการสั่งซื้อออฟไลน์",
    "planAddon.summaryItem.label.response_message": "ฟีเจอร์เสริม ระบบการจัดการถามตอบของแชทบอท",
    "planAddon.summaryItem.label.broadcast": "ฟีเจอร์เสริม ข้อความบรอดแคสต์ไม่จำกัดจำนวน",
    "planAddon.summaryItem.label.channel": "ฟีเจอร์เสริม ช่องทางการเชื่อมต่อ",
    "planAddon.summaryItem.label.payment_gateway": "ฟีเจอร์เสริม Payment gateway",
    "planAddon.summaryItem.label.inventory": "ฟีเจอร์เสริม Inventory integration",
    "planAddon.summaryItem.label.zort_integration": "ฟีเจอร์เสริม Zort integration",
    "planAddon.paymentSummary.label.previousSubtotal": "ราคารวมฟีเจอร์เสริมในรอบบิลก่อนหน้า",
    "planAddon.paymentSummary.label.changesSubtotal": "ราคารวมฟีเจอร์เสริมที่มีการเปลี่ยนแปลง",
    "planAddon.paymentSummary.label.subtotal": "ราคารวม",
    "planAddon.paymentSummary.label.vat": "ภาษีมูลค่าเพิ่ม ({{vatOptions}})",
    "planAddon.paymentSummary.label.total": "ราคารวมสุทธิ",
    "planAddon.paymentSummary.description": "การเปลี่ยนแปลงค่าบริการรายเดือนจะมีผลในรอบบิลถัดไป ในวันที่ ",
    "planAddon.paymentSummary.remark":
      "ฟีเจอร์เสริม ({{chargedAddon}}) จะถูกเรียกเก็บเงินตามอัตราส่วนการใช้งาน {{chargedPrice}} บาท ในทันที",

    "planAddon.paymentSummary.remarkDescription":
      "ฟีเจอร์เสริม ({{chargedAddon}}) จะถูกเรียกเก็บเงินตามอัตราส่วนการใช้งาน",
    "planAddon.type.member": "สมาชิก",
    "planAddon.type.offline_order": "รายการสั่งซื้อออฟไลน์",
    "planAddon.type.response_message": "ระบบการจัดการถามตอบของแชทบอท",
    "planAddon.type.payment_gateway": "Payment gateway",
    "planAddon.type.inventory": "Inventory integration",
    "planAddon.type.zort_integration": "Zort integration",
    "planAddon.type.image_search": "การค้นหารูปภาพด้วย AI",
    "planAddon.type.channel": "ช่องทางการเชื่อมต่อ",
    "planAddon.type.broadcast": "ข้อความบรอดแคสต์",
    "planAddon.paymentSummary.confirmModal.removeAddon":
      "คุณยืนยันที่จะลบฟีเจอร์เสริม{{removedAddonType}}? การเปลี่ยนแปลงจะมีผลในรอบบิลถัดไป",
    "planAddon.addonItemCard.title.offline_order": "รายการสั่งซื้อออฟไลน์",
    "planAddon.addonItemCard.description.offline_order": "เพิ่มรายการสั่งซื้อใหม่ได้จากระบบโดยตรง",
    "planAddon.addonItemCard.title.response_message": "ระบบการจัดการถามตอบของแชทบอท",
    "planAddon.addonItemCard.description.response_message":
      "เปลี่ยนการตอบคำถามของบอทได้ในแบบที่คุณต้องการ ให้เหมาะสมกับร้านของคุณ",
    "planAddon.addonItemCard.title.payment_gateway": "Payment gateway",
    "planAddon.addonItemCard.description.payment_gateway":
      "เพิ่มวิธีการชำระเงินด้วย บัตรเครดิต, อินเตอร์เน็ตแบงค์กิ้งและอื่นๆ",
    "planAddon.addonItemCard.title.inventory": "Inventory integration",
    "planAddon.addonItemCard.description.inventory":
      "เปิดใช้งาน Inventory integration เพื่อเชื่อมต่อรายการสั่งซื้อ สินค้า และ คลังสินค้ากับคอนโซล",
    "planAddon.addonItemCard.title.zort_integration": "Zort integration",
    "planAddon.addonItemCard.description.zort_integration":
      "เปิดใช้งาน Zort เพื่อเชื่อมต่อรายการสั่งซื้อ สินค้า และ คลังสินค้ากับคอนโซล",
    "planAddon.addonItemCard.price": "฿ {{formatPrice}} ต่อเดือน",
    "planAddon.addonItemCard.defaultActive": "ค่าตั้งต้นจากแพ็กเกจ",
    "planAddon.error.default": "กรุณาลองใหม่อีกครั้ง",
    "addon.imageSearch.disabled.description": "ปิดการค้นหารูปภาพด้วย AI",
    "addon.imageSearch.freeTrial": "ทดลองใช้งานฟรี - ",
    "addon.imageSearch.item.title": "การค้นหารูปภาพด้วย AI {{quota}} รูป",
    "addon.imageSearch.item.description": "฿ {{price}}",
    "addon.imageSearch.monthly": "ต่อเดือน",
    "addon.imageSearch.contactUs.description": "ติดต่อเราเพื่อประเมินราคาในการใช้งาน",
    "addon.imageSearch.contactUs.button": "ติดต่อเรา",
    "addon.imageSearch.extraPrice": "การใช้งานส่วนเกินจะถูกเรียกเก็บเงินเพิ่ม ฿{{price}}/การค้นหา",
    "planAddon.changedBroadcast.title": "มีการเปลี่ยงแปลงในแพ็กเกจของคุณ",
    "planAddon.changedBroadcast.description": "การเปลี่ยนแปลงครั้งล่าสุดของคุณจะมีผลในรอบบิลถัดไป",
    "planAddon.changedBroadcast.button": "ไปยังสรุปการเรียกเก็บเงิน",
    "planAddon.broadcast.disabled.description": "ไม่สามารถใช้งานข้อความบอรดแคสต์ได้",
    "planAddon.broadcast.defaultActive.title": "เปิดใช้งานข้อความบรอดแคสต์",
    "planAddon.broadcast.defaultActive.description": "ส่งข้อความบรอดแคสต์เพื่อโฆษณาสินค้าแบบเรียลไทม์",
    "planAddon.broadcast.unlimited.title": "ข้อความบรอดแคสต์ไม่จำกัดจำนวน",
    "planAddon.broadcast.unlimited.description": "สามารถส่งข้อความบรอดแคสต์ได้ไม่จำกัดจำนวนต่อเดือน",
    "planAddon.paymentSummary.removedAddOn.description": "ฟีเจอร์เสริมที่ถูกลบจะมีผลในรอบบิลถัดไปของคุณ",
    "planAddon.confirmModal.description1": "ฟีเจอร์เสริม การค้นหารูปภาพด้วย AI จะถูกคิดค่าบริการเต็มจำนวน",
    "planAddon.confirmModal.description2": "ในขณะที่ฟีเจอร์เสริมอื่นๆจะถูกคำนวณค่าบริการตามสัดส่วนวันที่ใช้",
  },
};

export default addon;
