import * as React from "react";

const SvgIcCategories = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M29.387 19.621c.46 0 .833.373.833.834v8.934c0 .46-.373.833-.833.833h-3.592v-10.6zm-5.259 0v10.601h-9.25a.833.833 0 01-.833-.833v-8.934c0-.46.373-.834.834-.834h9.25zm-12.583 0c.46 0 .834.373.834.834v8.934c0 .46-.373.833-.834.833H2.611a.833.833 0 01-.833-.833v-8.934c0-.46.373-.834.833-.834zm-3.51 1.901H6.37a.833.833 0 100 1.667h1.666a.833.833 0 100-1.667zm11.885-.08h-1.666a.833.833 0 100 1.666h1.666a.834.834 0 000-1.667zm4.701-13.576v9.255c0 .46-.373.834-.833.834h-3.59V7.865h4.423zm-6.09 0v10.089H9.32a.834.834 0 01-.834-.834V7.866h10.046zm-4.189 1.666h-1.666a.833.833 0 100 1.667h1.666a.833.833 0 000-1.667zm-4.179-7.754c.333 0 .633.197.765.502l1.696 3.92h-4.57l-1.41-3.257a.834.834 0 01.765-1.164zm15.536 0a.833.833 0 01.765 1.164L25.054 6.2h-4.57l1.696-3.92a.833.833 0 01.765-.502z"
    />
  </svg>
);

export default SvgIcCategories;
