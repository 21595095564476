export const CAMPAIGN_STATUS = {
  LIVE: "LIVE",
  LIVE_AND_UPCOMING: "LIVE_AND_UPCOMING",
  UPCOMING: "UPCOMING",
  END: "END",
  DISABLED: "DISABLED",
};

export const CAMPAIGN_SORT_OPTIONS = {
  START_DATE: "START_DATE",
  END_DATE: "END_DATE",
};

export const CAMPAIGN_STATUS_LABELS: { [key: string]: string } = {
  LIVE_AND_UPCOMING: "Live & Upcoming",
  LIVE: "Live",
  UPCOMING: "Upcoming",
  END: "End",
  DISABLED: "Disabled Campaign",
};

export const CAMPAIGN_TIME_FORMAT_REGEX = new RegExp(/^[0-9]{2}:[0-9]{2}$/, "g");

export const LIMIT = 15;

export const CONFIRM_MODAL_TYPE = {
  STOP: "Stop",
  REMOVE: "Remove",
};
