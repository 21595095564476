import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";

import Grid from "components/Grid";
import PageTitle from "components/PageTitle";
import Button from "components/Button";
import { SvgIcon } from "components/Icon";
import { IcAdd } from "components/SvgIcons";

import { MARKETING_TOOLS, FACEBOOK_LIVE, PROJECT } from "constants/Router";
import { ProjectIdType } from "types/Project";
import { Device } from "types/Device";
import useDevice from "utils/hooks/useDevice";

import FacebookLiveTable from "./FacebookLiveTable";

const FacebookLive: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId } = useParams<ProjectIdType>();
  const device = useDevice();
  const isMobile = device === Device.MOBILE;

  const handleClickAdd = () => {
    history.push(`/${PROJECT}/${projectId}/${MARKETING_TOOLS}/${FACEBOOK_LIVE}/new`);
  };

  const handleClickTableRowItem = (id?: string) => {
    if (id) {
      history.push(`/${PROJECT}/${projectId}/${MARKETING_TOOLS}/${FACEBOOK_LIVE}/${id}`);
    }
  };

  return (
    <Grid container alignContent="flex-start" alignItems="flex-start" className="pb-3">
      <Grid id="FacebookCrawlerTablePageContainer" item container xs={12}>
        <Grid item container xs={12}>
          <Grid item sm={6} xs={8}>
            <PageTitle title={t("facebookLive.header")} />
          </Grid>
          <Grid
            item
            sm={6}
            xs={4}
            container
            justify="flex-end"
            alignItems="center"
            alignContent="center"
            className="px-3"
          >
            <Button color="primary" className="mx-2" size="medium" onClick={handleClickAdd}>
              <SvgIcon component={IcAdd} fontSize="small" />
              {isMobile ? t("Add") : t("facebookLive.createNew")}
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} className="pt-3 px-3">
          <FacebookLiveTable onClickTitle={handleClickTableRowItem} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FacebookLive;
