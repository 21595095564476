const uploadImage = {
  en: {
    "uploadImage.type.error": "Image file should be .png, .jpg, .jpeg",
    "Upload image fail": "Upload fail",
    "uploadImage.limit.error": `Number of upload files limit at {{limitItem}} items`,
    "uploadImage.largeSize.error": "Size is too large",
    "uploadImage.width.error": "Image's width is wrong",
    "uploadImage.height.error": "Image's height is wrong",
    "uploadVideo.largeSize.error": "Please upload the video size lower than {{size}} MB",
    "uploadVideo.type.error": "Video file should be .mp4",
  },
  th: {
    "uploadImage.type.error": "ควรใช้รูปภาพ .png, .jpg, .jpeg",
    "Upload image fail": "อัปโหลดล้มเหลว",
    "uploadImage.limit.error": `จำนวนรูปที่อัปโหลดเกิน {{limitItem}} รูป`,
    "uploadImage.largeSize.error": "รูปภาพขนาดใหญ่เกินไป",
    "uploadImage.width.error": "รูปภาพมีความกว้างไม่เท่ากับที่กำหนด",
    "uploadImage.height.error": "รูปภาพมีความยาวไม่เท่ากับที่กำหนด",
    "uploadVideo.largeSize.error": "กรุณาอัปโหลดวิดีโอขนาดไม่เกิน {{size}} MB",
    "uploadVideo.type.error": "วีดีโอควรเป็นไฟล์ .mp4",
  },
};

export default uploadImage;
