import React, { FC } from "react";
import COLORS from "constants/Colors";
import Grid from "components/Grid";
import Typography from "components/Typography";

import { MESSAGE_TYPES } from "constants/Chat";

import {
  FlexImage,
  FlexMessageContent,
  ButtonPrimary,
  ButtonSecondary,
  ButtonError,
  SingleButtonPrimary,
  SelectButtonPrimary,
  PromotionBadge,
  UnavailableContainer,
} from "./FlexMessage.style";

type ContentDataType = { type: string; value?: string };

type FlexProductItemPropsType = {
  isSingleProductSKUOutOfStock: boolean;
  content: ContentDataType[];
};

/**
 *  TODO: refactor
 * flex content contain text data line by line without ID
 * So it can't be form unique key to this component
 * Using index as key as workaround for now.
 */

const FlexProductItem: FC<FlexProductItemPropsType> = (props) => {
  const { content, isSingleProductSKUOutOfStock } = props;
  let ButtonItems = null;
  let promotionBadgeText = null;
  let displayPrice = null;
  const discountPrice = content.find((data) => data.type === MESSAGE_TYPES.PRODUCT_DISCOUNT_PRICE);
  const discountPriceText = discountPrice && discountPrice.value;

  const flexMessageContent = content
    .filter(Boolean)
    .map(({ value, type }, index) => {
      if (!value) {
        return <></>;
      }

      if (type === MESSAGE_TYPES.PRODUCT_IMAGE) {
        return (
          <a href={value} key={value} target="_blank" rel="noopener noreferrer" className="d-flex">
            <FlexImage alt="flex-image" src={value} />
          </a>
        );
      }
      if (type === MESSAGE_TYPES.PRODUCT_NAME) {
        return (
          <Typography
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="pl-2 py-2 wordBreakAll"
            variant="title7"
            color={COLORS.Dark}
          >
            {value}
          </Typography>
        );
      }
      if (type === MESSAGE_TYPES.PRODUCT_SKU_PRICE) {
        const displaySKUPrice = value && value.split("-", 2);
        return displaySKUPrice && displaySKUPrice.length > 1 ? (
          <Typography
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="pl-2 pb-2 wordBreakAll"
            variant="title8"
          >
            <span style={{ color: COLORS.DarkLight, textDecoration: "line-through" }}>
              {displaySKUPrice && displaySKUPrice[0]}
            </span>
            &nbsp;
            <span style={{ color: COLORS.Error }}>{displaySKUPrice && displaySKUPrice[1]}</span>
          </Typography>
        ) : (
          <Typography
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="pl-2 pb-2"
            variant="title8"
            color={COLORS.Primary}
          >
            {displaySKUPrice && displaySKUPrice[0]}
          </Typography>
        );
      }

      if (type === MESSAGE_TYPES.PRODUCT_PRICE) {
        displayPrice = value;

        return discountPriceText ? (
          <Typography
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="pl-2 pb-2 wordBreakAll"
            variant="title7"
          >
            <span style={{ color: COLORS.DarkLight, textDecoration: "line-through" }}>{displayPrice}</span>
            &nbsp;
            <span style={{ color: COLORS.Error }}>{discountPriceText}</span>
          </Typography>
        ) : (
          <Typography
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="pl-2 pb-2"
            variant="title7"
            color={COLORS.Primary}
          >
            {displayPrice}
          </Typography>
        );
      }

      if (type === MESSAGE_TYPES.PRODUCT_SKU_WITH_ACTION) {
        const displaySKUName = value && value.split("action", 2);
        return displaySKUName && displaySKUName.length > 1 ? (
          <Typography
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="pl-2 pb-2 wordBreakAll"
            variant="body3"
          >
            <Grid container justify="space-between">
              <Grid item className="hiddenOverFlowText" xs={8}>
                <span>{displaySKUName && displaySKUName[0]}</span>
              </Grid>
              <Grid item xs={4}>
                {displaySKUName && displaySKUName[1] === "[สินค้าหมด]" ? (
                  <span
                    style={{
                      color: COLORS.Error,
                    }}
                  >
                    สินค้าหมด
                  </span>
                ) : (
                  <SelectButtonPrimary>{displaySKUName && displaySKUName[1]}</SelectButtonPrimary>
                )}
              </Grid>
            </Grid>
          </Typography>
        ) : (
          <Typography
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="pl-2 pb-2"
            variant="title7"
            color={COLORS.Primary}
          >
            {displaySKUName && displaySKUName[0]}
          </Typography>
        );
      }

      if (type === MESSAGE_TYPES.PRODUCT_SKU) {
        return (
          <Typography
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="pl-2 pb-2"
            variant="body3"
            color={COLORS.Dark}
            style={{ whiteSpace: "pre-line" }}
          >
            {value}
          </Typography>
        );
      }

      if (type === MESSAGE_TYPES.PRODUCT_SKU_PROMOTION) {
        return (
          <Typography
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            variant="title10"
            className="pl-2 pb-2"
            color={COLORS.Primary}
            style={{ whiteSpace: "pre-line" }}
          >
            {value}
          </Typography>
        );
      }

      if (type === MESSAGE_TYPES.PRODUCT_MENU) {
        const actionList = value && value.split(",", 2);
        ButtonItems = (
          <Grid
            container
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            {actionList && actionList.length > 1 ? (
              <>
                <Grid item xs={6}>
                  <ButtonSecondary>{actionList && actionList[0]}</ButtonSecondary>
                </Grid>
                <Grid item xs={6}>
                  {actionList && actionList[1] === "[สินค้าหมด]" ? (
                    <ButtonError>{actionList && actionList[1]}</ButtonError>
                  ) : (
                    <ButtonPrimary>{actionList && actionList[1]}</ButtonPrimary>
                  )}
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <SingleButtonPrimary>{actionList && actionList[0]}</SingleButtonPrimary>
              </Grid>
            )}
          </Grid>
        );
      }

      if (type === MESSAGE_TYPES.PRODUCT_PROMOTION_BADGE) {
        promotionBadgeText = (
          <Typography // eslint-disable-next-line react/no-array-index-key
            key={index}
            variant="body3"
            color={COLORS.White}
          >
            {value || ""}
          </Typography>
        );
      }

      if (type === MESSAGE_TYPES.PRODUCT_PROMOTION_NAME && !isSingleProductSKUOutOfStock) {
        return (
          <Typography // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="pl-2 pb-2 wordBreakAll"
            variant="title8"
            color={COLORS.Primary}
          >
            {value || ""}
          </Typography>
        );
      }

      return null;
    })
    .filter((contentData): contentData is JSX.Element => !!contentData);

  return (
    <>
      {isSingleProductSKUOutOfStock && <UnavailableContainer>Out of stock</UnavailableContainer>}
      <FlexMessageContent>
        {!isSingleProductSKUOutOfStock && promotionBadgeText && <PromotionBadge>{promotionBadgeText}</PromotionBadge>}
        <Grid item xs={12}>
          {flexMessageContent}
        </Grid>
        {ButtonItems}
      </FlexMessageContent>
    </>
  );
};

export default FlexProductItem;
