import React, { FC, useEffect } from "react";
import moment from "moment";

import Grid from "components/Grid";
import Card from "components/Card";
import DateTimePicker from "components/DateTimePicker";
import TextField from "components/TextField";
import Typography from "components/Typography";
import MultiSelectWithCheckBox from "components/MultiSelectWithCheckBox";
import { RadioBox } from "components/Radio";
import { IcUser } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";

import { MAXIMUM_BROADCAST_NAME_STRING_LENGTH } from "config";
import { OptionType, OptionsType, ValueType } from "react-select";
import { PROVINCE_TH } from "constants/Province";
import { SUGGEST_TAGS } from "graphql/suggestTags/query";
import { SuggestTagVariablesType, SuggestTagsType } from "types/Customer";
import {
  BroadcastCampaignDataType,
  BroadcastChannel,
  BroadcastCriteriaType,
  CriteriaType,
  BroadcastType,
} from "types/Broadcast";
import { systemTagsOptions, CUSTOMER_RETENTION_STATUS } from "constants/Broadcast";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { DATE_TIME_PICKER_FORMAT } from "constants/DateTimeFormat";

type BroadcastCampaignFormPropsType = {
  projectId: string;
  broadcastCampaignData: BroadcastCampaignDataType;
  setBroadcastCampaignData: Function;
  disabledForm: boolean;
  audienceNumber?: number;
  fetchAudienceNumber?: Function;
  isNewCreateForm: boolean;
};

const BroadcastCampaignForm: FC<BroadcastCampaignFormPropsType> = ({
  projectId,
  broadcastCampaignData,
  setBroadcastCampaignData,
  disabledForm,
  audienceNumber = 0,
  fetchAudienceNumber = () => {},
  isNewCreateForm,
}) => {
  const { t } = useTranslation();

  const { data: suggestTagsData } = useQuery<SuggestTagsType, SuggestTagVariablesType>(SUGGEST_TAGS, {
    variables: {
      projectId,
      type: "CUSTOMER",
    },
    fetchPolicy: "network-only",
  });

  const handleBroadcastCriteriaChange = (broadcastCriteria: BroadcastCriteriaType) => {
    const fetchAudienceNumberVariables = {
      projectId,
      channel: BroadcastChannel.LINE,
      criteria: [broadcastCriteria],
    };

    fetchAudienceNumber({ variables: fetchAudienceNumberVariables });
  };

  const convertDataToOptionFormat = (originalData: string[]) => {
    if (originalData && originalData.length) {
      return originalData.map((data: string) => ({
        label: data,
        value: data,
      }));
    }
    return [];
  };

  const convertDataSystemTagsToOptionFormat = (originalData: string[]) => {
    if (originalData && originalData.length) {
      return originalData.map((data: string) => ({
        label: CUSTOMER_RETENTION_STATUS[data],
        value: data,
      }));
    }
    return [];
  };

  const optionsProvinceValue: OptionsType<OptionType> = convertDataToOptionFormat(PROVINCE_TH || []) || [];
  const optionsSuggestTagsValue: OptionsType<OptionType> =
    convertDataToOptionFormat((suggestTagsData && suggestTagsData.suggestTags) || []) || [];

  const handleChangeTagInput = (tagInputValues: ValueType<OptionType>, criteriaType: CriteriaType) => {
    const newTagsArray = tagInputValues
      ? (tagInputValues as OptionType[]).map((tagInputValue) => {
          return tagInputValue.value;
        })
      : [];

    let newData = null;
    switch (criteriaType) {
      case CriteriaType.SYSTEM_TAGS:
        newData = { systemCriteria: newTagsArray };
        break;
      case CriteriaType.CUSTOM_TAGS:
        newData = { customCriteria: newTagsArray };
        break;
      case CriteriaType.LOCATION:
        newData = { locationCriteria: newTagsArray };
        break;
      default:
        break;
    }
    const newBroadcastCampaignData = {
      ...broadcastCampaignData.broadcastCriteria[0],
      projectId,
      ...newData,
    };

    setBroadcastCampaignData({
      ...broadcastCampaignData,
      broadcastCriteria: [newBroadcastCampaignData],
    });

    handleBroadcastCriteriaChange(newBroadcastCampaignData);
  };

  const handleChangeBroadcastCampaignName = (newName: string) => {
    if (newName.length <= MAXIMUM_BROADCAST_NAME_STRING_LENGTH) {
      setBroadcastCampaignData({
        ...broadcastCampaignData,
        name: newName,
      });
    }
  };

  useEffect(() => {
    if (isNewCreateForm) {
      const fetchAudienceNumberVariables = {
        projectId,
        channel: BroadcastChannel.LINE,
        criteria: [broadcastCampaignData && broadcastCampaignData.broadcastCriteria[0]],
      };
      fetchAudienceNumber({ variables: fetchAudienceNumberVariables }); // first time get AudienceNumber
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewCreateForm]);

  const handleSetDateTime = (selectedDate: string | null) => {
    if (selectedDate) {
      setBroadcastCampaignData({
        ...broadcastCampaignData,
        broadcastDate: selectedDate,
      });
    }
  };

  return (
    <>
      {broadcastCampaignData && (
        <Grid item xs={12}>
          <Card>
            <Grid container>
              <Grid item xs={12} className="py-1">
                <Grid container justify="space-between">
                  <Typography variant="body3" color="darkMed">
                    {`${t("broadcast.title")}`}
                  </Typography>
                </Grid>
                <TextField
                  variant="outlined"
                  className="py-1"
                  placeholder={`${t("broadcast.title")}`}
                  fullWidth
                  value={broadcastCampaignData.name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeBroadcastCampaignName(e.target.value)
                  }
                  InputProps={disabledForm ? { className: "Mui-disabled" } : {}}
                  inputProps={{ readOnly: disabledForm }}
                />
              </Grid>

              <Grid item xs={12} className="py-1">
                <Typography variant="body3" color="darkMed">
                  {`${t("broadcast.broadcastTime")}`}
                </Typography>

                <Grid container>
                  <Grid item xs={6} className="py-1 pr-1">
                    <RadioBox
                      title={t("broadcast.now")}
                      isActive={broadcastCampaignData.type === BroadcastType.IMMEDIATE}
                      onClick={() => {
                        setBroadcastCampaignData({
                          ...broadcastCampaignData,
                          broadcastDate: moment().toISOString(),
                          type: BroadcastType.IMMEDIATE,
                        });
                      }}
                      disabled={disabledForm}
                    />
                  </Grid>
                  <Grid item xs={6} className="py-1 pl-1">
                    <RadioBox
                      title={t("broadcast.scheduled")}
                      isActive={broadcastCampaignData.type === BroadcastType.SCHEDULE}
                      disabled={disabledForm}
                      onClick={() => {
                        setBroadcastCampaignData({
                          ...broadcastCampaignData,
                          broadcastDate: moment().add(15, "minutes").toISOString(),
                          type: BroadcastType.SCHEDULE,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
                {broadcastCampaignData.type === BroadcastType.SCHEDULE && (
                  <Grid container className="mt-2">
                    <Typography variant="body3" color="darkMed" className="mb-1">
                      {`${t("broadcast.date&time")}`}
                    </Typography>
                    <DateTimePicker
                      disabled={disabledForm}
                      fullWidth
                      selectedDateTime={broadcastCampaignData.broadcastDate}
                      setDateTime={handleSetDateTime}
                      format={DATE_TIME_PICKER_FORMAT}
                      disableFuture={false}
                      disablePast={!disabledForm} // should allow to display when time is the past
                    />
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12} className="py-1">
                <Typography variant="title8" className="py-2">
                  {t("broadcast.audience")}
                </Typography>
                <Card noShadow>
                  <Grid container>
                    <Grid item xs={6} className="py-1">
                      <Typography variant="body3" color="darkMed" className="py-1">
                        {`${t("broadcast.targeting")}`}
                      </Typography>
                    </Grid>
                    <Grid container item xs={6} justify="flex-end" className="py-1">
                      <Typography variant="body3" color="darkMed" className="py-1">
                        <Grid container alignContent="center" alignItems="center">
                          <SvgIcon component={IcUser} fontSize="small" color="inherit" />
                          {audienceNumber}
                        </Grid>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className="py-1">
                      <MultiSelectWithCheckBox
                        disabled={disabledForm}
                        placeholder={`${t("broadcast.SelectALL")} ${t("broadcast.targeting")}`}
                        options={systemTagsOptions}
                        value={convertDataSystemTagsToOptionFormat(
                          get(broadcastCampaignData, "broadcastCriteria[0].systemCriteria") || [],
                        )}
                        onChange={(event: ValueType<OptionType>) => {
                          handleChangeTagInput(event || [], CriteriaType.SYSTEM_TAGS);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className="py-1 pr-1">
                      <Typography variant="body3" color="darkMed" className="py-2">
                        {`${t("broadcast.location")}`}
                      </Typography>
                      <MultiSelectWithCheckBox
                        disabled={disabledForm}
                        placeholder={`${t("broadcast.SelectALL")} ${t("broadcast.location")}`}
                        options={optionsProvinceValue}
                        value={convertDataToOptionFormat(
                          get(broadcastCampaignData, "broadcastCriteria[0].locationCriteria") || [],
                        )}
                        onChange={(event: ValueType<OptionType>) => {
                          handleChangeTagInput(event || [], CriteriaType.LOCATION);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className="py-1 pl-1">
                      <Typography variant="body3" color="darkMed" className="py-2">
                        {`${t("broadcast.customerTags")}`}
                      </Typography>
                      <MultiSelectWithCheckBox
                        disabled={disabledForm}
                        placeholder={`${t("broadcast.SelectALL")} ${t("broadcast.customerTags")}`}
                        options={optionsSuggestTagsValue}
                        value={convertDataToOptionFormat(
                          get(broadcastCampaignData, "broadcastCriteria[0].customCriteria") || [],
                        )}
                        onChange={(event: ValueType<OptionType>) => {
                          handleChangeTagInput(event || [], CriteriaType.CUSTOM_TAGS);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
    </>
  );
};

export default BroadcastCampaignForm;
