import styled from "styled-components";
import Grid from "components/Grid";

export const GridQuestions = styled(Grid)`
  ${({ theme }) => theme.breakpoints.down("xs")} {
    height: auto;
  }
`;

export const AddQuestionButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.COLORS.White};
  text-align: center;
`;

export const MessagesManagerWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.COLORS.LightWhite};
  overflow: hidden;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-left: 8px;
  }
`;

export const QuestionsWrapper = styled.div`
  max-height: 370px;
  overflow-y: scroll;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    display: initial;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(83, 83, 83, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
