import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { NO_IMG_AVAILABLE } from "constants/Image";
import { CONSOLE_GENERATED_FORM } from "constants/i18n";
import Card from "components/Card";
import Typography from "components/Typography";
import { getProductSKUsKeyValueFromProductSKUs, getProductPriceRangeFromProductSKUs } from "utils/common";
import { ProductSKUType, DigitalContentAvailability, ProductType } from "types/Product";
import nanoid from "utils/nanoid";
import SKUTableHeader from "./SKUTableHeader";
import SKUsWrapper from "./SKUsWrapper.styled";
import ProductSKUCard from "./ProductSKUCard";
import ProductProfile from "./ProductProfile";

type ConsoleGeneratedFormPropsType = {
  productName: string;
  productImage?: string;
  productSKUs: ProductSKUType[];
  comment?: string;
  onChangeDigitalContentAvailabilityType: (
    SKUId: string,
    digitalContentAvailabilityType: DigitalContentAvailability,
  ) => void;
  onChangeExpiryDate: (SKUId: string, expiryDate: string | number) => void;
  setProductSKUs: Function;
  productType: ProductType;
  isEditMode: boolean;
  isShadowSKU: boolean;
  isCodeGenerateFromOther: boolean;
};

const ConsoleGeneratedForm: FC<ConsoleGeneratedFormPropsType> = ({
  productName,
  productImage,
  productSKUs,
  onChangeDigitalContentAvailabilityType,
  onChangeExpiryDate,
  setProductSKUs,
  productType,
  isEditMode,
  isShadowSKU,
  isCodeGenerateFromOther,
}) => {
  const { t } = useTranslation(CONSOLE_GENERATED_FORM);
  const variations = getProductSKUsKeyValueFromProductSKUs(productSKUs);

  const productPrice = getProductPriceRangeFromProductSKUs(productSKUs, 0, t);

  const isCodeGenerateFromOtherAndIsShadowSKU = isCodeGenerateFromOther && isShadowSKU;

  return !isCodeGenerateFromOtherAndIsShadowSKU ? (
    <Card borderRadius={0} noBorder noShadow padding="24px 0px">
      <Typography variant="title4" className="mb-3">
        {t("deliverable")}
      </Typography>
      <Card padding="0" noShadow>
        {!isShadowSKU && (
          <>
            <ProductProfile
              productName={productName}
              productImage={productImage || NO_IMG_AVAILABLE}
              variations={variations}
              price={productPrice}
            />
            <SKUTableHeader />
          </>
        )}
        <SKUsWrapper>
          {productSKUs.map((productSKU, index) => {
            return (
              <ProductSKUCard
                index={index}
                key={productSKU.nanoid || productSKU.id}
                productSKU={productSKU}
                onChangeExpiryDate={onChangeExpiryDate}
                onChangeDigitalContentAvailabilityType={onChangeDigitalContentAvailabilityType}
                nanoid={productSKU.nanoid || nanoid()}
                productSKUs={productSKUs}
                setProductSKUs={setProductSKUs}
                productType={productType}
                isEditMode={isEditMode}
                isShadowSKU={isShadowSKU}
                isCodeGenerateFromOther={isCodeGenerateFromOther}
              />
            );
          })}
        </SKUsWrapper>
      </Card>
    </Card>
  ) : (
    <></>
  );
};

export default ConsoleGeneratedForm;
