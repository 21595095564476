import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import COLORS from "constants/Colors";
import { FixWidthTooltip } from "components/Tooltip";

import TextField from "./TextField.styled";
import InputAdornment from "./InputAdornment.styled";
import { TextFieldPropsType } from "./index";
import { SvgIcon } from "../Icon";
import { IcInfo } from "../SvgIcons";

const CreditCardCvvField: FC<TextFieldPropsType> = (props) => {
  const { onChange = () => {}, maxInputLength = 3, value, ...otherProps } = props;

  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState(value || "");

  return (
    <TextField
      {...otherProps}
      value={inputValue}
      type="password"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const inputLength = value.toString().length;
        const formattedValue = value.replace(/\D+/g, "");

        if (inputLength <= maxInputLength) {
          onChange(event);
          setInputValue(formattedValue);
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <FixWidthTooltip title={t("billing.addCreditCardForm.cvv.info") as string} placement="bottom-end" arrow>
              <div>
                <SvgIcon
                  component={IcInfo}
                  fontSize="small"
                  htmlColor={COLORS.DarkLight}
                  viewBox="0 4 24 24"
                  className="p-0 mr-0"
                />
              </div>
            </FixWidthTooltip>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CreditCardCvvField;
