import * as React from "react";

const IcChartCustomer = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" {...props} viewBox="0 0 14 14">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.556 5.333c.118 0 .23.047.314.131l.574.574.575-.575c.083-.083.196-.13.314-.13h.487c.458 0 .839.344.884.801l.404 4.044c.014.125-.027.25-.112.343-.084.092-.203.146-.33.146h-.486l-.365 3.644c-.045.455-.425.8-.883.8h-.975c-.457 0-.838-.344-.883-.8l-.365-3.644h-.487c-.126 0-.245-.054-.33-.146-.084-.094-.125-.218-.112-.343l.405-4.045c.045-.456.425-.8.883-.8zM3.11 9.778c.245 0 .445.199.445.444v4.445c0 .245-.2.444-.445.444H1.333c-.245 0-.444-.199-.444-.444v-4.445c0-.245.199-.444.444-.444zm4.445-4.445c.245 0 .444.2.444.445v8.889c0 .245-.2.444-.444.444H5.778c-.246 0-.445-.199-.445-.444v-8.89c0-.245.2-.444.445-.444zM12.444.89c.982 0 1.778.796 1.778 1.778s-.796 1.777-1.778 1.777c-.981 0-1.777-.795-1.777-1.777 0-.982.796-1.778 1.777-1.778z"
      transform="translate(-24 -292) translate(0 50) translate(0 230) translate(24 12)"
    />
  </svg>
);

export default IcChartCustomer;
