import React, { FC } from "react";
import { useParams, Switch, Redirect, Route } from "react-router-dom";

import { PROJECT, DASHBOARD, SALES_REPORT, PRODUCT_REPORT, ORDER_REPORT } from "constants/Router";
import ProductReport from "domain/Report/ProductReport";
import OrderReport from "domain/Report/OrderReport";
import SalesReport from "domain/Report/SalesReport";

import useGetUserWithPermission from "utils/hooks/useGetUserWithPermission";
import PrivateRoute from "routers/PrivateRoute";
import { NotFound } from "pages/NotFound";
import { ProjectIdType } from "types/Project";

const SalesReportsSubRoute: FC = () => {
  const { projectId = "" } = useParams<ProjectIdType>();
  const { loading, isAdmin } = useGetUserWithPermission(projectId);
  const rootPath = `/${PROJECT}/:projectId/${DASHBOARD}/${SALES_REPORT}`;

  return (
    <Switch>
      <PrivateRoute exact loading={loading} path={`${rootPath}/${ORDER_REPORT}`} isAllowed={isAdmin} redirectTo="/">
        <SalesReport activeIndex={0}>
          <OrderReport />
        </SalesReport>
      </PrivateRoute>

      <PrivateRoute exact loading={loading} path={`${rootPath}/${PRODUCT_REPORT}`} isAllowed={isAdmin} redirectTo="/">
        <SalesReport activeIndex={1}>
          <ProductReport />
        </SalesReport>
      </PrivateRoute>

      <PrivateRoute exact loading={loading} path={`${rootPath}`} isAllowed={isAdmin} redirectTo="/">
        <Redirect to={`/${PROJECT}/${projectId}/${DASHBOARD}/${SALES_REPORT}/${ORDER_REPORT}`} />
      </PrivateRoute>

      <Route path={`${rootPath}/*`}>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default SalesReportsSubRoute;
