// This component that is imported from this package => https://github.com/bonafideduck/react-highlight-within-textarea
// It under beta test so I copy and fix their code to work with our project.
// I will convert to typescript later

import React from "react";
import getType from "./getType.js";

function _extends() {
  _extends =
    Object.assign ||
    function (target) {
      for (let i = 1; i < arguments.length; i++) {
        const source = arguments[i];
        for (const key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
      return target;
    };
  return _extends.apply(this, arguments);
}

const highlightToStrategyAndComponents = function highlightToStrategyAndComponents(
  highlight,
  className,
  component,
  tooltip,
) {
  const type = getType(highlight);

  switch (type) {
    case "array":
      return arrayToDecorator(highlight, className, component, tooltip);

    case "strategy":
      return strategyToDecorator(highlight, className, component, tooltip);

    case "regexp":
      return regExpToDecorator(highlight, className, component, tooltip);

    case "string":
      return stringToDecorator(highlight, className, component, tooltip);

    case "range":
      return rangeToDecorator(highlight, className, component, tooltip);

    case "custom":
      return customToDecorator(highlight);

    default:
      if (!highlight) {
        // do nothing for falsey values
      } else {
        console.error("unrecognized highlight type");
      }

      return [];
  }
};

function arrayToDecorator(highlight, className, component, tooltip) {
  const decorators = highlight.map(function (h) {
    return highlightToStrategyAndComponents(h, className, component, tooltip);
  });
  return Array.prototype.concat.apply([], decorators);
}

function strategyToDecorator(highlight, className, component, tooltip) {
  return [
    {
      strategy: highlight,
      component: hwtComponent(className, component, tooltip),
    },
  ];
}

function regExpToDecorator(highlight, className, component, tooltip) {
  const regExpStrategy = function regExpStrategy(text, callback) {
    let matchArr;
    let start;

    while ((matchArr = highlight.exec(text)) !== null) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
  };

  return [
    {
      strategy: regExpStrategy,
      component: hwtComponent(className, component, tooltip),
    },
  ];
}

function stringToDecorator(highlight, className, component, tooltip) {
  const stringStrategy = function stringStrategy(text, callback) {
    const textLower = text.toLowerCase();
    const strLower = highlight.toLowerCase();
    let index = 0;

    while (((index = textLower.indexOf(strLower, index)), index !== -1)) {
      callback(index, index + strLower.length);
      index += strLower.length;
    }
  };

  return [
    {
      strategy: stringStrategy,
      component: hwtComponent(className, component, tooltip),
    },
  ];
}

function rangeToDecorator(highlight, className, component, tooltip) {
  const rangeStrategy = function rangeStrategy(text, callback) {
    const low = highlight[0];
    const high = highlight[1];

    if (low < high && low >= 0 && high < text.length) {
      callback(low, high);
    }
  };

  return [
    {
      strategy: rangeStrategy,
      component: hwtComponent(className, component, tooltip),
    },
  ];
}

function customToDecorator(highlight) {
  const { className, component, tooltip } = highlight;
  const hl = highlight.highlight;
  return highlightToStrategyAndComponents(hl, className, component, tooltip);
}

var hwtComponent = function hwtComponent(className, Component, tooltip) {
  if (Component) {
    return function (props) {
      return /* #__PURE__ */ React.createElement(Component, {
        className,
        tooltip,
        ...props,
      });
    };
  }
  return function (props) {
    return /* #__PURE__ */ React.createElement(
      "mark",
      {
        className,
      },
      props.children,
    );
  };
};

export default highlightToStrategyAndComponents;
