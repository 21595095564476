import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { UPDATE_VAT } from "graphql/vat/mutation";
import { NOTIFICATION_UPDATE } from "constants/Notification";
import { UpdatingVatType, Vat } from "types/Vat";
import { notifyError, notifySuccess } from "utils/notify";

type UseUpdateVatPropsType = {
  projectId: string;
  onCompleted: (updatedVat: Vat) => void;
};

const useUpdateVat = (props: UseUpdateVatPropsType) => {
  const { projectId, onCompleted } = props;
  const { t } = useTranslation();

  const [updateVat, { loading: isUpdatingVat }] = useMutation(UPDATE_VAT, {
    onError: () => notifyError(t(NOTIFICATION_UPDATE.FAIL)),
    onCompleted: ({ updatedVat }) => {
      notifySuccess(t(NOTIFICATION_UPDATE.SUCCESS));
      onCompleted(updatedVat);
    },
  });

  const handleUpdateVat = (vatId: string, newVatData: UpdatingVatType) => {
    updateVat({
      variables: {
        projectId,
        vatId,
        vat: newVatData,
      },
    });
  };

  return {
    handleUpdateVat,
    isUpdatingVat,
  };
};

export default useUpdateVat;
