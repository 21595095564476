import React, { FC, useRef, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import get from "lodash/get";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";

import Button from "components/Button";
import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import PageTitle from "components/PageTitle";

import { NOTIFICATION_UPDATE } from "constants/Notification";
import { UPDATE_STORE_DETAIL } from "graphql/storeDetail/mutation";
import { STORE_DETAIL } from "graphql/storeDetail/query";
import { FLAG_USER_WITH_IS_ENTERPRISE } from "graphql/auth/query";
import { UserProfileType } from "types/User";
import { ImageUrl } from "types/Image";
import { ProjectIdType } from "types/Project";
import { StoreDetail, FormDataInputOnSubmit } from "types/Store";

import { notifySuccess, notifyError } from "utils/notify";
import { GuidelineContext } from "utils/context";

import StoreDetailForm from "./StoreDetailForm";
import schema from "./schema";

const Information: FC<ProjectIdType> = ({ projectId }) => {
  const { t } = useTranslation();
  const { setGuidelineCompletedStepCount } = useContext(GuidelineContext);

  const [updateStoreDetail] = useMutation(UPDATE_STORE_DETAIL, {
    update(cache, { data: { updateStoreDetail: updatedStoreDetail } }) {
      const user = cache.readQuery<UserProfileType>({
        query: FLAG_USER_WITH_IS_ENTERPRISE,
        variables: { projectId },
      });

      if (user) {
        cache.writeQuery({
          query: FLAG_USER_WITH_IS_ENTERPRISE,
          variables: { projectId },
          data: {
            me: {
              ...user.me,
              permissions: user.me.permissions.map((permission) => {
                if (permission.projectId === projectId) {
                  return {
                    ...permission,
                    projectLogo: updatedStoreDetail.image,
                    projectName: updatedStoreDetail.name,
                  };
                }

                return permission;
              }),
            },
          },
        });
      }

      setGuidelineCompletedStepCount(projectId);
    },
  });

  const { data, loading } = useQuery<{ storeDetail: StoreDetail }, { projectId: string }>(STORE_DETAIL, {
    variables: { projectId },
  });

  const inputFile = useRef<File>();
  const [isEmptyImage, setIsEmptyImage] = useState(false);

  const method = useForm({
    resolver: yupResolver(schema),
  });

  const { clearErrors, errors, handleSubmit, setValue, watch } = method;

  const handleChangeImage = (imageUrls: ImageUrl[]) => {
    if (imageUrls.length) {
      const [{ publicUrl }] = imageUrls;

      setValue("image", publicUrl);
      setIsEmptyImage(false);
    }

    if (errors.image) {
      clearErrors("image");
    }
  };

  const handleRemoveImage = () => {
    setValue("image", "");
    inputFile.current = undefined;
    setIsEmptyImage(true);
  };

  const handleUpdateStoreDetail: SubmitHandler<FormDataInputOnSubmit> = async (formDataInputOnSubmit, event) => {
    if (event) {
      event.preventDefault();
    }

    const storeDetail = data?.storeDetail;

    const formDataInputOnSubmitWithId = {
      ...formDataInputOnSubmit,
      id: storeDetail?.id,
    };

    try {
      await updateStoreDetail({
        variables: {
          projectId,
          storeDetail: formDataInputOnSubmitWithId,
        },
      });

      notifySuccess(t(NOTIFICATION_UPDATE.SUCCESS));
    } catch (error) {
      notifyError(t(NOTIFICATION_UPDATE.FAIL));
    }
  };

  const storeImage = watch("image");

  if (loading || !data) {
    return (
      <Grid container className="my-5" justify="center">
        <Grid item>
          <CircularProgress size={40} />
        </Grid>
      </Grid>
    );
  }

  return (
    <FormProvider {...method}>
      <form name="information" onSubmit={handleSubmit(handleUpdateStoreDetail)} className="mb-4">
        <Grid container>
          <Grid item>
            <PageTitle title={t("Information")} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <StoreDetailForm
              projectId={projectId}
              defaultValueImageSrc={storeImage || get(data, ["storeDetail", "image"], "")}
              imageSrc={isEmptyImage ? "" : storeImage || get(data, ["storeDetail", "image"], "")}
              onChangeImage={handleChangeImage}
              onRemoveImage={handleRemoveImage}
              storeDetail={data?.storeDetail}
            />
          </Grid>

          <Grid container>
            <Grid item xs={12} className="px-4">
              <Button fullWidth type="submit" disabled={Boolean(Object.keys(errors).length)}>
                {t("Save")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Information;
