import { gql } from "@apollo/client";

export const CREATE_PROJECT = gql`
  mutation($projectName: String!) {
    createProject(projectName: $projectName) {
      projectId
    }
  }
`;

export const CONNECT_TO_FACEBOOK_PAGE = gql`
  mutation connectFacebookPage($projectId: ID!, $pageId: ID!, $userId: String!, $accessToken: String!) {
    connectFacebookPage(projectId: $projectId, pageId: $pageId, userId: $userId, accessToken: $accessToken)
  }
`;

export const DISCONNECT_TO_FACEBOOK_PAGE = gql`
  mutation disconnectFacebookPage($projectId: ID!) {
    disconnectFacebookPage(projectId: $projectId)
  }
`;

export const CONNECT_TO_INSTAGRAM_BUSINESS = gql`
  mutation connectInstagramBusiness($projectId: ID!, $pageId: ID!, $userId: String!, $accessToken: String!) {
    connectInstagramBusiness(projectId: $projectId, pageId: $pageId, userId: $userId, accessToken: $accessToken)
  }
`;

export const DISCONNECT_TO_INSTAGRAM_BUSINESS = gql`
  mutation disconnectInstagramBusiness($projectId: ID!) {
    disconnectInstagramBusiness(projectId: $projectId)
  }
`;

export const UPDATE_CONNECT_CREDENTIAL = gql`
  mutation updateConnectCredential(
    $projectId: ID!
    $channelId: String!
    $channelSecret: String!
    $channelAccessToken: String!
  ) {
    updateConnectCredential(
      projectId: $projectId
      channelId: $channelId
      channelSecret: $channelSecret
      channelAccessToken: $channelAccessToken
    ) {
      id
      refId
      lineChannelAccessToken
      lineChannelId
      lineChannelSecret
    }
  }
`;

export const DELETE_CONNECT_CREDENTIAL = gql`
  mutation deleteConnectCredential($projectId: ID!) {
    deleteConnectCredential(projectId: $projectId) {
      id
      refId
      lineChannelAccessToken
      lineChannelId
      lineChannelSecret
    }
  }
`;

export const UPDATE_RESTORE_ID = gql`
  mutation addRestoreId($projectId: ID!, $restoreId: String!) {
    addRestoreId(projectId: $projectId, restoreId: $restoreId) {
      id
      email
      displayName
      avatar
      firstname
      lastname
      language
      isEmailVerified
      permissions {
        id
        projectId
        projectName
        projectLogo
        role
        isEnterprise
        isGuidelineCompleted
        isDisabled
        isInitialProjectCompleted
        restoreId
      }
      isAllowedToCreateProject
      isAccepted
      acceptedAt
      authProvider
    }
  }
`;
