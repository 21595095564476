import { useEffect } from "react";

import { FRESH_DESK_HOST, FRESH_DESK_TOKEN, IS_FRESH_DESK } from "config";
import { PROJECT } from "constants/Router";
import { FRESH_DESK_WIDGET_CONTENT_TRANSLATION } from "constants/FreshDesk";

const initializeFreshDesk = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!IS_FRESH_DESK) {
      return;
    }

    if (!window.location.pathname.match(new RegExp(`${PROJECT}/+`, "g"))) {
      const freshDeskInfo = {
        token: FRESH_DESK_TOKEN,
        host: FRESH_DESK_HOST,
        config: {
          content: FRESH_DESK_WIDGET_CONTENT_TRANSLATION,
        },
      };

      window.fcWidget?.init(freshDeskInfo);
    }
  }, []);

  return {};
};

export default initializeFreshDesk;
