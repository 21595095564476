import { useQuery, WatchQueryFetchPolicy } from "@apollo/client";
import { TotalOrderNumberStatesData } from "types/Order";
import { TOTAL_ORDER_NUMBER_STATES } from "graphql/order/query";

const useQueryTotalOrderNumberStates = (projectId: string, fetchPolicy?: WatchQueryFetchPolicy) => {
  const { data, loading, subscribeToMore } = useQuery<TotalOrderNumberStatesData>(TOTAL_ORDER_NUMBER_STATES, {
    skip: !projectId,
    variables: { projectId },
    fetchPolicy,
  });

  return {
    data,
    loading,
    subscribeToMore,
  };
};

export default useQueryTotalOrderNumberStates;
