import { useState } from "react";
import { useQuery } from "@apollo/client";
import debounce from "lodash/debounce";

import { DEBOUNCE_TIME } from "constants/Order";
import { SYNONYM_LIMIT_PER_PAGE } from "constants/Synonym";
import { SYNONYMS } from "graphql/synonym/query";
import { SynonymConfigKeyType } from "types/Synonym";

export default (projectId: string, type: SynonymConfigKeyType) => {
  const [pageNo, setPageNo] = useState(1);
  const [searchWord, setSearchWord] = useState("");

  const queryVariable = {
    projectId,
    limit: SYNONYM_LIMIT_PER_PAGE,
    offset: pageNo - 1,
    filter: {
      keyword: searchWord,
    },
    type,
  };

  const { loading, data } = useQuery(SYNONYMS, {
    variables: queryVariable,
  });

  const handleChangePage = (page: number) => {
    if (page < 1) {
      return setPageNo(1);
    }
    return setPageNo(page);
  };

  const handleSearchChange = debounce((value: string) => {
    setSearchWord(value);
    setPageNo(1);
  }, DEBOUNCE_TIME);

  return {
    isSynonymsLoading: loading,
    pageNo,
    searchWord,
    handleChangePage,
    handleSearchChange,
    total: data?.synonyms?.total || 0,
    synonyms: data?.synonyms?.results || [],
    queryVariable,
  };
};
