import * as React from "react";

const SvgIcPlanUpgrade = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M 4.679688 2.542969 C 5.226562 1.996094 6.105469 1.996094 6.648438 2.542969 C 7.191406 3.082031 7.191406 3.964844 6.648438 4.507812 C 4.15625 7 2.785156 10.324219 2.785156 13.859375 C 2.785156 17.398438 4.15625 20.71875 6.648438 23.210938 C 9.144531 25.699219 12.464844 27.078125 16 27.078125 C 19.535156 27.078125 22.855469 25.703125 25.347656 23.210938 C 27.84375 20.71875 29.214844 17.398438 29.214844 13.859375 C 29.214844 10.324219 27.84375 7.003906 25.351562 4.507812 C 24.808594 3.964844 24.808594 3.082031 25.351562 2.542969 C 25.894531 1.996094 26.773438 1.996094 27.316406 2.542969 C 30.339844 5.558594 32 9.578125 32 13.859375 C 32 18.140625 30.339844 22.15625 27.320312 25.175781 C 24.300781 28.195312 20.277344 29.859375 16 29.859375 C 11.722656 29.859375 7.699219 28.195312 4.679688 25.175781 C 1.664062 22.160156 0 18.140625 0 13.859375 C 0 9.578125 1.660156 5.558594 4.679688 2.542969 Z M 16 4.132812 C 21.523438 4.132812 26 8.613281 26 14.132812 C 26 19.65625 21.523438 24.132812 16 24.132812 C 10.476562 24.132812 6 19.65625 6 14.132812 C 6 8.609375 10.476562 4.132812 16 4.132812 Z M 15.886719 8.132812 C 15.632812 8.132812 15.398438 8.234375 15.222656 8.414062 L 12.273438 11.359375 C 11.910156 11.726562 11.910156 12.3125 12.273438 12.675781 C 12.632812 13.035156 13.222656 13.035156 13.585938 12.675781 L 14.957031 11.304688 L 14.957031 19.621094 C 14.957031 20.136719 15.371094 20.550781 15.886719 20.550781 C 16.398438 20.550781 16.8125 20.136719 16.8125 19.621094 L 16.8125 11.304688 L 18.183594 12.675781 C 18.359375 12.851562 18.597656 12.949219 18.839844 12.949219 C 19.089844 12.949219 19.324219 12.855469 19.503906 12.671875 C 19.859375 12.3125 19.859375 11.726562 19.496094 11.363281 L 16.539062 8.40625 C 16.367188 8.234375 16.128906 8.132812 15.886719 8.132812 Z M 15.886719 8.132812 "
    />
  </svg>
);

export default SvgIcPlanUpgrade;
