import React, { FC } from "react";
import { useQuery } from "@apollo/client";

import Card from "components/Card";
import Divider from "components/Divider";
import Grid from "components/Grid";
import Typography from "components/Typography";
import CircularProgress from "components/CircularProgress";

import { SystemTagStatus } from "constants/Tag";
import { SYSTEM_TAGS } from "graphql/tag/query";
import {
  SystemTagsQueryVariablesType,
  SystemTagsQueryType,
  SystemTagType,
  CustomerRetentionStatusKeyType,
  TagType,
} from "types/Tag";
import { mapPotentialCustomerTag } from "utils/tag";

import { CustomerTag as CustomerTagInput } from "../CustomerTag";
import { CustomerTag as CustomerSystemTag } from "../CustomerAddress/styled";

type CustomerTagPropsType = {
  projectId: string;
  customerId: string;
};

const CustomerTag: FC<CustomerTagPropsType> = ({ projectId, customerId }) => {
  const { data, loading } = useQuery<SystemTagsQueryType, SystemTagsQueryVariablesType>(SYSTEM_TAGS, {
    variables: {
      projectId,
      customerId,
    },
    fetchPolicy: "network-only",
  });

  return (
    <Card noShadow padding="0px">
      <Grid container>
        <Grid item xs={12} className="p-4">
          <Typography variant="title8" color="darkLight">
            Tags
          </Typography>
          {/* SYSTEM TAG */}
          {loading && <CircularProgress />}
          <Grid container item className="mt-2">
            {data &&
              data.systemTags.map((systemTag: SystemTagType) => {
                let displayTag;
                if (systemTag.type === TagType.SYSTEM_BROADCAST) {
                  displayTag = `Broadcast: ${systemTag.tag}`;
                } else {
                  displayTag = SystemTagStatus[systemTag.tag as CustomerRetentionStatusKeyType] || systemTag.tag;
                }

                return (
                  <CustomerSystemTag key={systemTag.tag} item type={systemTag.type} className="my-1">
                    {mapPotentialCustomerTag(displayTag)}
                  </CustomerSystemTag>
                );
              })}
          </Grid>
        </Grid>
        <Divider className="w-100 my-2" />
        <Grid item xs={12} className="px-4 pb-4">
          <CustomerTagInput projectId={projectId} customerId={customerId} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default CustomerTag;
