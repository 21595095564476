import React, { ReactNode } from "react";
import { components, OptionType, SingleValueProps } from "react-select";
import { useTranslation } from "react-i18next";
import { RICH_MENU } from "constants/i18n";

const SingleValueShippingCarrier = (singleValueProps: SingleValueProps<OptionType & { Logo?: ReactNode }>) => {
  const { t } = useTranslation(RICH_MENU);
  const { data } = singleValueProps;

  return <components.SingleValue {...singleValueProps}>{t(data.label)}</components.SingleValue>;
};

export default SingleValueShippingCarrier;
