import { ORDER_REPORT, PRODUCT_REPORT } from "constants/Router";

export const DEFAULT_VALUE = "-";
export const DEFAULT_PAGE_START = 1;
export const DEFAULT_ROWS_PER_PAGE = 10;
export const ROWS_PER_PAGES = [DEFAULT_ROWS_PER_PAGE, 25, 50, 100];
export const DEFAULT_IMG = "assets/no-img-available.png";
export const TAB_CONFIGS = [
  { name: "Order Centric", path: ORDER_REPORT },
  { name: "Product Centric", path: PRODUCT_REPORT },
];
