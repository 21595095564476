const en = {
  "importProduct.error.title": "Product import error",
  "importProduct.error.row.title": "Row",
  "importProduct.error.row.description": "Error",
  "importProduct.error.errorType.fileTypeIsNotPermitted":
    "The file type is not supported, please make sure it’s .csv or .xlsx file.",
  "importProduct.error.errorType.exceedProductQuota.prefix":
    "You have exceeded the maximum number of products. Please ",
  "importProduct.error.errorType.exceedProductQuota.link": "upgrade your plan",
  "importProduct.error.errorType.exceedProductQuota.suffix": " to continue importing.",
  "importProduct.error.errorType.missingCCommercePackage": "Missing C Commerce Package",
  "importProduct.error.errorType.incorrectUploadFormat":
    "Something went wrong in importing the products. Please check your file and correct all the errors.",
  "importProduct.error.errorType.duplicatedProductTypeSKU": "Product SKU '{{value}}' is duplicated.",
  "importProduct.error.errorType.duplicatedProductName":
    "Product name '{{value}}' is duplicated with existing product.",
  "importProduct.error.errorType.duplicatedProductCode":
    "Product code '{{value}}' is duplicated with existing product.",
  "importProduct.error.errorType.categoryNotFound": "Product catalog is missing.",
  "importProduct.error.errorType.descriptionExceedLimit":
    "Product description has exceeded the character limit. ({{value}}/{{limit}})",
  "importProduct.error.errorType.priceReachLimit": "Product price has reached the limit. ({{value}}/{{limit}})",
  "importProduct.error.errorType.duplicatedProductCodeInImportData": "Product code '{{value}}' is duplicated.",
  "importProduct.error.errorType.duplicatedProductNameInImportData": "Product name '{{value}}' is duplicated.",
  "importProduct.error.errorType.productCodeCannotBeEmpty": "Product code is missing.",
  "importProduct.error.errorType.productCfCodeExisted": "Duplicated CF code '{{value}}' with one in the system.",
  "importProduct.error.errorType.duplicatedProductCfCode": "Duplicated CF code '{{value}}' in the file itself.",
  "importProduct.error.errorType.priceCannotBeLessThanOrEqualZero":
    "Product price cannot be less than or equal to zero.",
  "importProduct.error.errorType.imageUrlIsInvalid": "Image URL is invalid.",
  "importProduct.error.errorType.emptyProductVariableTypes": "Product type '{{value}}' invalid",
  "importProduct.error.errorType.productCodeNotSupportCharacter": "Product code is invalid",

  // to support object type when refactor
  error: {
    title: "Product import error",
    row: {
      title: "Row",
      description: "Error",
    },
    errorType: {
      fileTypeIsNotPermitted: "The file type is not supported, please make sure it’s .csv or .xlsx file.",
      exceedProductQuota: {
        prefix: "You have exceeded the maximum number of products. Please",
        link: "upgrade your plan",
        suffix: "to continue importing.",
      },
      missingCCommercePackage: "Missing C Commerce Package",
      incorrectUploadFormat:
        "Something went wrong in importing the products. Please check your file and correct all the errors.",
      duplicatedProductTypeSKU: "Product SKU '{{value}}' is duplicated.",
      duplicatedProductName: "Product name '{{value}}' is duplicated with existing product.",
      duplicatedProductCode: "Product code '{{value}}' is duplicated with existing product.",
      categoryNotFound: "Product catalog is missing.",
      descriptionExceedLimit: "Product description has exceeded the character limit. ({{value}}/{{limit}})",
      priceReachLimit: "Product price has reached the limit. ({{value}}/{{limit}})",
      duplicatedProductCodeInImportData: "Product code '{{value}}' is duplicated.",
      duplicatedProductNameInImportData: "Product name '{{value}}' is duplicated.",
    },
  },
};

const th = {
  "importProduct.error.title": "ข้อผิดพลาดในการนำเข้าสินค้า",
  "importProduct.error.row.title": "แถว",
  "importProduct.error.row.description": "ข้อผิดพลาด",
  "importProduct.error.errorType.fileTypeIsNotPermitted":
    "ประเภทของไฟล์ไม่ถูกต้อง โปรดตรวจสอบว่ามันเป็นไฟล์ประเภท .csv หรือ .xlsx",
  "importProduct.error.errorType.exceedProductQuota.prefix": "คุณมีรายการสินค้ามากเกินกว่าที่กำหนด โปรด ",
  "importProduct.error.errorType.exceedProductQuota.link": "อัพเกรดแพ็กเกจการใช้งาน",
  "importProduct.error.errorType.exceedProductQuota.suffix": " เพื่อนำเข้าสินค้าเพิ่ม",
  "importProduct.error.errorType.missingCCommercePackage": "Missing C Commerce Package",
  "importProduct.error.errorType.incorrectUploadFormat":
    "มีบางอย่างผิดพลาดในการนำเข้าสินค้า กรุณาตรวจสอบไฟล์ของคุณและแก้ไขรายการดังกล่าว",
  "importProduct.error.errorType.duplicatedProductTypeSKU": "ตัวเลือกสินค้า '{{value}}' ซ้ำ",
  "importProduct.error.errorType.duplicatedProductName": "ชื่อสินค้า '{{value}}' ซ้ำกับสินค้าที่มีอยู่แล้ว",
  "importProduct.error.errorType.duplicatedProductCode": "รหัสสินค้า '{{value}}' ซ้ำกับสินค้าที่มีอยู่แล้ว",
  "importProduct.error.errorType.categoryNotFound": "ไม่พบเมนูสินค้า",
  "importProduct.error.errorType.descriptionExceedLimit": "คำอธิบายสินค้ายาวเกินกว่าที่กำหนด ({{value}}/{{limit}})",
  "importProduct.error.errorType.priceReachLimit": "ราคาสินค้ามากเกินกว่าที่กำหนด ({{value}}/{{limit}})",
  "importProduct.error.errorType.duplicatedProductCodeInImportData": "รหัสสินค้า '{{value}}' ซ้ำ",
  "importProduct.error.errorType.duplicatedProductNameInImportData": "ชื่อสินค้า '{{value}}' ซ้ำ",
  "importProduct.error.errorType.productCodeCannotBeEmpty": "ไม่พบรหัสสินค้า",
  "importProduct.error.errorType.productCfCodeExisted": "รหัส CF สินค้่า '{{value}}' ซ้ำกับสินค้าที่มีอยู่แล้ว",
  "importProduct.error.errorType.duplicatedProductCfCode": "รหัส CF สินค้า '{{value}}' ซ้ำ",
  "importProduct.error.errorType.priceCannotBeLessThanOrEqualZero": "ราคาสินค้าไม่สามารถเป็น 0 หรือน้อยกว่า",
  "importProduct.error.errorType.imageUrlIsInvalid": "ลิ้งค์รูปภาพไม่สามารถใช้ได้",
  "importProduct.error.errorType.emptyProductVariableTypes": "ตัวเลือกสินค้า '{{value}}' ไม่สามารถใช้ได้",
  "importProduct.error.errorType.productCodeNotSupportCharacter": "รหัสสินค้าไม่สามารถใช้ได้",

  // to support object type when refactor
  error: {
    title: "ข้อผิดพลาดในการนำเข้าสินค้า",
    row: {
      title: "แถว",
      description: "ข้อผิดพลาด",
    },
    errorType: {
      fileTypeIsNotPermitted: "ประเภทของไฟล์ไม่ถูกต้อง โปรดตรวจสอบว่ามันเป็นไฟล์ประเภท .csv หรือ .xlsx",
      exceedProductQuota: {
        prefix: "คุณมีรายการสินค้ามากเกินกว่าที่กำหนด โปรด",
        link: "อัพเกรดแพ็กเกจการใช้งาน",
        suffix: "เพื่อนำเข้าสินค้าเพิ่ม",
      },
      missingCCommercePackage: "Missing C Commerce Package",
      incorrectUploadFormat: "มีบางอย่างผิดพลาดในการนำเข้าสินค้า กรุณาตรวจสอบไฟล์ของคุณและแก้ไขรายการดังกล่าว",
      duplicatedProductTypeSKU: "ตัวเลือกสินค้า '{{value}}' ซ้ำ",
      duplicatedProductName: "ชื่อสินค้า '{{value}}' ซ้ำกับสินค้าที่มีอยู่แล้ว",
      duplicatedProductCode: "รหัสสินค้า '{{value}}' ซ้ำกับสินค้าที่มีอยู่แล้ว",
      categoryNotFound: "ไม่พบเมนูสินค้า",
      descriptionExceedLimit: "คำอธิบายสินค้ายาวเกินกว่าที่กำหนด ({{value}}/{{limit}})",
      priceReachLimit: "ราคาสินค้ามากเกินกว่าที่กำหนด ({{value}}/{{limit}})",
      duplicatedProductCodeInImportData: "รหัสสินค้า '{{value}}' ซ้ำ",
      duplicatedProductNameInImportData: "ชื่อสินค้า '{{value}}' ซ้ำ",
    },
  },
};

export default {
  th,
  en,
};
