import * as React from "react";

const SvgIcInfo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 1.778c7.855 0 14.222 6.367 14.222 14.222 0 7.855-6.367 14.222-14.222 14.222-7.855 0-14.222-6.367-14.222-14.222C1.778 8.145 8.145 1.778 16 1.778zm1.374 10.97h-2.748V24.39h2.748V12.75zm-1.386-5.137c-.494 0-.916.175-1.267.526-.35.35-.526.78-.526 1.29s.176.937.526 1.28c.35.342.773.513 1.267.513.51 0 .94-.171 1.29-.514.351-.342.527-.769.527-1.279 0-.51-.176-.94-.526-1.29a1.753 1.753 0 00-1.291-.526z"
    />
  </svg>
);

export default SvgIcInfo;
