const chat = {
  en: {
    ADMIN_ONLY: "Admin only",
    HELP_REQUEST: "Help request",
    "All Chat": "All Chat",
    "Customer not found": "Customer not found",
    "1Convo Bot is active": "1Convo Bot is active",
    "Switch off the bot to chat with your customer": "Switch off the bot to chat with your customer",
    // #region Attachment related message type
    "Customer sent audio": "Customer sent audio",
    "Customer sent file": "Customer sent file",
    "Customer sent sticker": "Customer sent sticker",
    "Customer sent video": "Customer sent video",
    CUSTOMER_MENTIONED_YOU_IN_THEIR_STORY: "Customer mentioned you in story",
    CUSTOMER_REPLIED_TO_YOUR_STORY: "Customer replied to your story",
    // #endregion
    REMARK_DOWNLOAD_FROM_PROVIDER: "(Please check this file in your LINE OA for temporary)",
    "chat.topNav.newClientLabel": "New customer",
    "chat.toggleSwitch.bot.active": "Active bot",
    "chat.toggleSwitch.bot.inactive": "Inactive bot",
    Delivery: "Delivery",
    "Delivery scheduled": "Delivery scheduled",
    "Pick up": "Pick up",
    Next: "Next",
    "Shipping address": "Shipping address",
    Available: "Available",
    "Delivery shipping method": "Delivery shipping method",
    "checkout.error.pickUpTime": "Time to pick up should between opening and closing time",
    "Pick up scheduled": "Pick up scheduled",
    "Select branch": "Select branch",
    "Please select branch or delivery schedule": "Please select branch or delivery schedule",
    "Customer can only has one active order": "Customer can only has one active order",
    "chat.customerDetails": "Customer details",
    "checkout.error.deliveryScheduled": "Scheduled time that include preparing time is not available",
    "checkout.error.shippingPrice": "Shipping price is required for distance base shipping",
    "checkout.error.offlineCustomerAddon": "Please buy offline order addon",
    "checkout.error.noCustomerName": "Please fill your customer name",
    "checkout.error.noShippingAddress": "Please fill your shipping address",
    "checkout.error.outOfShippingServiceArea": "Cannot call Lalamove, Please adjust shipping schedule",
    "checkout.error.selectedProductsAreDisabled": "Selected product are disabled or expired",
    "checkout.error.default": "Something went wrong, Please try again",
    "chat.customerTag.placeholder": "Add new tag",
    "Cannot render message": "Cannot render message",
    "line.error.message.attachment": "Customer sent a file. (You can view via LINE OA)",
    "No more message": "No more message",
    SEARCH_BY_NAME: "Search by name",
    SEARCH_BY_TAG: "Search tag",
    SYSTEM_TAG: "System tag",
    CUSTOMER_TAG: "Customer tag",
    "Google map link": "Google map link",
    DISCONNECTED_BOT_CHAT: "No bot chat",
    "chat.replyMessageInput.placeholder1": "message",
    "chat.replyMessageInput.placeholder2": "(bot will be temporary disconnected when sending message)",
    SEND_TO_MESSAGE_INPUT: "Send to message input",
    SEARCH_SHORTCUTS: "Search shortcuts",
    NO_SHORTCUTS_YET: "Type to search",
    REPLY_SHORTCUT: "Reply shortcut",
    SHORTCUT_KEY_NOT_FOUND: "Shortcut key is not found",
    UNSEEN_MESSAGES: "Unseen messages",
    NUMBER_PERSONS: "Number of persons",
    SEND_MESSAGE_BAD_REQUEST: "Cannot reply the message: chat template or message is incorrect format",
    FACEBOOK_UNAUTHORIZED: "Token is invalidated, Please reconnect Facebook Page to be able to send a message",
    FACEBOOK_FORBIDDEN:
      "Cannot reply the message: outside of allowed window or person isn't available right now (blocked)",
    FACEBOOK_NO_CREDENTIAL: "Cannot reply the message: does not connect with Facebook Page or incorrect credential",
    LINE_NO_CREDENTIAL: "Cannot reply the message: does not connect with LINE OA or incorrect credential",
    LINE_TOO_MANY_REQUEST: "Cannot reply the message: push message exceeded your monthly message limit",
    VIDEO_FORBIDDEN: "Your video might not be able to send right now. Please try again later",
    MESSAGE_HAS_BEEN_DELETED_BY_CUSTOMER: "Message has been deleted by customer",
    VIEW_REPLY: "View reply",
    CUSTOMER_REPLY_MESSAGE: "Customer reply message",
    VIEW_REPLY_MESSAGE_ERROR: "Cannot show reply message",

    // #region message type on chat list
    // #region BOT TYPE
    BOT_DISPLAY_MESSAGE_AUDIO: "System sent audio",
    BOT_DISPLAY_MESSAGE_CAROUSEL: "Carousel message",
    BOT_DISPLAY_MESSAGE_CART_SUMMARY: "Cart summary message",
    BOT_DISPLAY_MESSAGE_CATEGORY_CAROUSEL: "Category carousel message",
    BOT_DISPLAY_MESSAGE_DELIVERY_SCHEDULE: "Delivery schedule message",
    BOT_DISPLAY_MESSAGE_FILE: "System sent file",
    BOT_DISPLAY_MESSAGE_FLEX: "Flex message",
    BOT_DISPLAY_MESSAGE_IMAGE: "Image message",
    BOT_DISPLAY_MESSAGE_ORDER_CAROUSEL: "Order carousel message",
    BOT_DISPLAY_MESSAGE_PAYMENT_METHOD: "Payment method message",
    BOT_DISPLAY_MESSAGE_PRODUCT_CAROUSEL: "Product carousel message",
    BOT_DISPLAY_MESSAGE_SHIPPING_ADDRESS: "Shipping address message",
    BOT_DISPLAY_MESSAGE_SHIPPING_METHOD_TYPE: "Shipping method type message",
    BOT_DISPLAY_MESSAGE_SHIPPING_METHOD: "Shipping method message",
    BOT_DISPLAY_MESSAGE_STICKER: "System sent sticker",
    BOT_DISPLAY_MESSAGE_VIDEO: "System sent video",
    // #endregion
    // #region CUSTOMER TYPE
    CUSTOMER_DISPLAY_MESSAGE_AUDIO: "Customer sent audio",
    CUSTOMER_DISPLAY_MESSAGE_FILE: "Customer sent file",
    CUSTOMER_DISPLAY_MESSAGE_IMAGE: "Customer sent image",
    CUSTOMER_DISPLAY_MESSAGE_STICKER: "Customer sent sticker",
    CUSTOMER_DISPLAY_MESSAGE_STORY_MENTION: "Customer mentioned you in story",
    CUSTOMER_DISPLAY_MESSAGE_STORY_REPLY: "Customer replied your story",
    CUSTOMER_DISPLAY_MESSAGE_VIDEO: "Customer sent video",
    // #endregion
    // #region CONSOLE TYPE aka ADMIN
    CONSOLE_DISPLAY_MESSAGE_CATEGORY_CAROUSEL: "Admin sent category carousel message",
    CONSOLE_DISPLAY_MESSAGE_FILE: "Sent a file",
    CONSOLE_DISPLAY_MESSAGE_IMAGE: "Admin sent image",
    CONSOLE_DISPLAY_MESSAGE_PRODUCT_CAROUSEL: "Admin sent product carousel message",
    // #endregion
    // #endregion
  },
  th: {
    ADMIN_ONLY: "ดูแลโดยแอดมิน",
    HELP_REQUEST: "ต้องการความช่วยเหลือ",
    "All Chat": "ทั้งหมด",
    "Customer not found": "ไม่พบลูกค้า",
    "1Convo Bot is active": "1Convo บอทเปิดใช้งานแล้ว",
    "Switch off the bot to chat with your customer": "ปิดการใช้งานบอทเพื่อพูดคุยกับลูกค้าของคุณด้วยตัวเอง",
    // #region Attachment related message type
    "Customer sent audio": "ลูกค้าส่งไฟล์เสียง",
    "Customer sent file": "ลูกค้าส่งไฟล์",
    "Customer sent sticker": "ลูกค้าส่งสติ๊กเกอร์",
    "Customer sent video": "ลูกค้าส่งวิดีโอ",
    CUSTOMER_MENTIONED_YOU_IN_THEIR_STORY: "ลูกค้าพูดถึงคุณในสตอรี่",
    CUSTOMER_REPLIED_TO_YOUR_STORY: "ลูกค้าตอบกลับสตอรี่ของคุณ",
    // #endregion
    REMARK_DOWNLOAD_FROM_PROVIDER: "(กรุณาดาวน์โหลดไฟล์ผ่าน LINE OA ชั่วคราว)",
    "chat.topNav.newClientLabel": "ลูกค้าใหม่",
    "chat.toggleSwitch.bot.active": "บอททำงาน",
    "chat.toggleSwitch.bot.inactive": "หยุดบอทชั่วคราว",
    Delivery: "จัดส่ง",
    "Delivery scheduled": "กำหนดการจัดส่ง",
    "Pick up": "รับด้วยตัวเอง",
    Next: "ถัดไป",
    "Shipping address": "ที่อยู่จัดส่ง",
    Available: "เปิดทำการ",
    "Delivery shipping method": "ประเภทการจัดส่ง",
    "checkout.error.pickUpTime": " เวลารับสินค้าควรอยู่ในช่วงเวลาทำการ",
    "Pick up scheduled": "เวลาที่มารับสินค้า",
    "Select branch": "เลือกสาขา",
    "Please select branch or delivery schedule": "โปรดระบุสาขาหรือเวลารับสินค้า",
    "Customer can only has one active order": "ลูกค้ามีคำสั่งซื้อเก่าค้างอยู่",
    "chat.customerDetails": "ข้อมูลลูกค้า",
    "checkout.error.deliveryScheduled": "เวลาในการจัดส่งและเตรียมสินค้าเกินเวลาทำการ",
    "checkout.error.shippingPrice": "โปรดกรอกราคาค่าจัดส่งตามระยะทาง",
    "checkout.error.offlineCustomerAddon": "กรุณาซื้อแพ็กเกจเสริมรายการสั่งซื้อออฟไลน์",
    "checkout.error.noCustomerName": "กรุณาเพิ่มชื่อลูกค้า",
    "checkout.error.noShippingAddress": "กรุณาเพิ่มที่อยู่จัดส่ง",
    "checkout.error.outOfShippingServiceArea": "ไม่สามารถเรียก Lalamove ได้, กรุณาแก้ไขเวลารับสินค้า",
    "checkout.error.selectedProductsAreDisabled": "มีสินค้าหมด หรือ หมดอายุ",
    "checkout.error.default": "กรุณาลองใหม่อีกครั้ง",
    "chat.customerTag.placeholder": "เพิ่ม tag",
    "Cannot render message": "ไม่สามารถแสดงข้อความได้",
    "line.error.message.attachment": "ลูกค้าส่งไฟล์แนบ (ร้านค้าสามารถดูได้ผ่าน LINE OA)",
    "No more message": "ไม่มีข้อความเก่ากว่านี้แล้ว",
    SEARCH_BY_NAME: "ค้นหาด้วยชื่อ",
    SEARCH_BY_TAG: "ค้นหาแท็ก",
    SYSTEM_TAG: "แท็กจากระบบ",
    CUSTOMER_TAG: "แท็กจากลูกค้า",
    "Google map link": "กูเกิ้ลแมปลิงก์",
    DISCONNECTED_BOT_CHAT: "ลูกค้าที่ไม่ต้องการคุยกับบอท",
    "chat.replyMessageInput.placeholder1": "ข้อความ",
    "chat.replyMessageInput.placeholder2": "(บอทจะหยุดชั่วคราวเมื่อส่งข้อความ)",
    SEND_TO_MESSAGE_INPUT: "ส่งไปที่กล่องข้อความ",
    SEARCH_SHORTCUTS: "ค้นหาคีย์ลัด",
    NO_SHORTCUTS_YET: "พิมพ์เพื่อค้นหา",
    REPLY_SHORTCUT: "คีย์ลัดการตอบ",
    SHORTCUT_KEY_NOT_FOUND: "ไม่พบคีย์ลัด",
    UNSEEN_MESSAGES: "ข้อความที่ยังไม่ได้อ่าน",
    NUMBER_PERSONS: "จำนวนคน",
    SEND_MESSAGE_BAD_REQUEST: "ไม่สามารถส่งข้อความได้เนื่องจาก คีย์ลัดการตอบ หรือ ข้อความผิด",
    FACEBOOK_UNAUTHORIZED: "กรุณาเชื่อมต่อเพจเข้ากับ 1Convo อีกครั้งเพื่อส่งข้อความเนื่องจาก token มีปัญหา",
    FACEBOOK_FORBIDDEN: "ไม่สามารถส่งข้อความได้เนื่องจากเกินระยะเวลาที่ระบบอนุญาตหรือลูกค้าไม่อนุญาต (blocked)",
    FACEBOOK_NO_CREDENTIAL:
      "ไม่สามารถส่งข้อความได้เนื่องจากไม่ได้เชื่อมต่อ Facebook Page หรือมีความผิดพลาดในการเชื่อมต่อ",
    LINE_NO_CREDENTIAL: "ไม่สามารถส่งข้อความได้เนื่องจากไม่ได้เชื่อมต่อกับ LINE OA หรือมีความผิดพลาดในการเชื่อมต่อ",
    LINE_TOO_MANY_REQUEST: "ไม่สามารถส่งข้อความได้เนื่องจากเกินโควต้าของการส่งข้อความ (push message)",
    VIDEO_FORBIDDEN: "วิดีโอในข้อความตอบกลับของคุณไม่สามารถส่งได้ ณ ตอนนี้ กรุณารอสักครู่แล้วส่งอีกครั้ง",
    MESSAGE_HAS_BEEN_DELETED_BY_CUSTOMER: "ลูกค้ายกเลิกข้อความ",
    VIEW_REPLY: "ดูการตอบกลับ",
    CUSTOMER_REPLY_MESSAGE: "ลูกค้าตอบกลับข้อความ",
    VIEW_REPLY_MESSAGE_ERROR: "ยังไม่สามารถแสดงข้อความตอบกลับได้",
    // #region message type on chat list
    // #region BOT type
    BOT_DISPLAY_MESSAGE_AUDIO: "ระบบส่งข้อความเสียง",
    BOT_DISPLAY_MESSAGE_CAROUSEL: "ข้อความเลื่อนได้",
    BOT_DISPLAY_MESSAGE_CART_SUMMARY: "ข้อความสำหรับตะกร้าสินค้า",
    BOT_DISPLAY_MESSAGE_CATEGORY_CAROUSEL: "ข้อความสำหรับเลือกแคตตาล็อก",
    BOT_DISPLAY_MESSAGE_DELIVERY_SCHEDULE: "ข้อความสำหรับการเลือกเวลาจัดส่ง",
    BOT_DISPLAY_MESSAGE_FILE: "ระบบส่งไฟล์",
    BOT_DISPLAY_MESSAGE_FLEX: "ข้อความพิเศษ",
    BOT_DISPLAY_MESSAGE_IMAGE: "ระบบส่งรูปภาพ",
    BOT_DISPLAY_MESSAGE_ORDER_CAROUSEL: "ข้อความเกี่ยวกับประวัติการสั่งซื้อ",
    BOT_DISPLAY_MESSAGE_PAYMENT_METHOD: "ข้อความเกี่ยวกับช่องทางการชำระเงิน",
    BOT_DISPLAY_MESSAGE_PRODUCT_CAROUSEL: "ข้อความสำหรับเลือกสินค้า",
    BOT_DISPLAY_MESSAGE_SHIPPING_ADDRESS: "ข้อความสำหรับกรอกข้อมูลเพื่อจัดส่งสินค้า",
    BOT_DISPLAY_MESSAGE_SHIPPING_METHOD_TYPE: "ข้อความสำหรับเลือกประเภทการจัดส่ง",
    BOT_DISPLAY_MESSAGE_SHIPPING_METHOD: "ข้อความสำหรับวิธีจัดส่งสินค้า",
    BOT_DISPLAY_MESSAGE_STICKER: "ระบบส่งสติกเกอร์",
    BOT_DISPLAY_MESSAGE_VIDEO: "ระบบส่งวิดีโอ",
    // #endregion
    // #region CUSTOMER TYPE
    CUSTOMER_DISPLAY_MESSAGE_AUDIO: "ลูกค้าส่งข้อความเสียง",
    CUSTOMER_DISPLAY_MESSAGE_FILE: "ลูกค้าส่งไฟล์",
    CUSTOMER_DISPLAY_MESSAGE_IMAGE: "ลูกค้าส่งรูปภาพ",
    CUSTOMER_DISPLAY_MESSAGE_STICKER: "ลูกค้าส่งสติกเกอร์",
    CUSTOMER_DISPLAY_MESSAGE_STORY_MENTION: "ลูกค้าพูดถึงคุณในสตอรี่",
    CUSTOMER_DISPLAY_MESSAGE_STORY_REPLY: "ลูกค้าตอบสตอรี่ของคุณ",
    CUSTOMER_DISPLAY_MESSAGE_VIDEO: "ลูกค้าส่งวิดีโอ",
    // #endregion
    // #region CONSOLE TYPE aka ADMIN
    CONSOLE_DISPLAY_MESSAGE_CATEGORY_CAROUSEL: "แอดมินส่งข้อความสำหรับเลือกแคตตาล็อก",
    CONSOLE_DISPLAY_MESSAGE_FILE: "ส่งไฟล์แนบ",
    CONSOLE_DISPLAY_MESSAGE_IMAGE: "แอดมินส่งรูปภาพ",
    CONSOLE_DISPLAY_MESSAGE_PRODUCT_CAROUSEL: "แอดมินส่งข้อความสำหรับเลือกสินค้า",
    // #endregion
    // #endregion
  },
};

export default chat;
