import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { NOTIFICATION_CREATE } from "constants/Notification";
import { ADD_CATEGORY } from "graphql/category/mutation";
import { ALL_CATEGORY } from "graphql/category/query";
import { CategoryConnectionType, CategoryType } from "types/Category";
import { GuidelineContext } from "utils/context";
import { notifyError, notifySuccess } from "utils/notify";
import { addGoogleTagEvent } from "services/GoogleTagEvent";
import { GTM_EVENT } from "constants/GTM";
import mapErrorMessage from "../mapErrorMessage";

const useAddCategory = (projectId: string, handleClearNewCategory: () => void) => {
  const { t } = useTranslation();
  const { setGuidelineCompletedStepCount } = useContext(GuidelineContext);

  const [addCategory] = useMutation(ADD_CATEGORY, {
    update(cache, { data: { addCategory: addedCategory } }) {
      const cachedCategories = cache.readQuery<CategoryConnectionType>({
        query: ALL_CATEGORY,
        variables: {
          projectId,
        },
      });
      if (cachedCategories) {
        const newCacheCategoriesResult = [...cachedCategories.categories.results, addedCategory];

        cache.writeQuery({
          query: ALL_CATEGORY,
          variables: {
            projectId,
          },
          data: {
            categories: {
              results: newCacheCategoriesResult,
              total: newCacheCategoriesResult.length,
              // eslint-disable-next-line no-underscore-dangle
              __typename: cachedCategories.categories.__typename,
            },
          },
        });
      }

      setGuidelineCompletedStepCount(projectId);
    },
    onCompleted: () => {
      addGoogleTagEvent(GTM_EVENT.ADD_CATALOG);
      notifySuccess(t(NOTIFICATION_CREATE.SUCCESS));
      handleClearNewCategory();
    },
    onError: (err) => {
      const gqlErrorMessage: string = err?.graphQLErrors?.[0]?.message || "";
      const errorMessage = mapErrorMessage(gqlErrorMessage);

      notifyError(t(errorMessage));
    },
  });

  const handleAddCategory = (newCategory: CategoryType) => {
    addCategory({
      variables: {
        category: newCategory,
        projectId,
      },
    });
  };

  return { handleAddCategory };
};

export default useAddCategory;
