import * as React from "react";

const SvgIcAdd = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 8c.828 0 1.5.612 1.5 1.368V14.5h5.132c.756 0 1.368.672 1.368 1.5s-.612 1.5-1.368 1.5H17.5v5.132c0 .756-.672 1.368-1.5 1.368s-1.5-.612-1.5-1.368V17.5H9.368C8.612 17.5 8 16.828 8 16s.612-1.5 1.368-1.5H14.5V9.368C14.5 8.612 15.172 8 16 8z"
    />
  </svg>
);

export default SvgIcAdd;
