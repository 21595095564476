import { useQuery } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
import React, { FC, useMemo, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { SortingRule } from "react-table";
import get from "lodash/get";

import { ORDER_PRODUCT_REPORTS } from "graphql/salesReport/query";
import COLORS from "constants/Colors";
import { MOCK_PRODUCT_REPORT_DATA } from "constants/Report";

import Grid from "components/Grid";
import CircularProgress from "components/CircularProgress";
import ButtonToPlanUpgrade from "components/ButtonToPlanUpgrade";

import { ShowingResult, RowsSelectorPerPage } from "components/TablePagination";
import { Wrapper as PaginationWrapper } from "components/TablePagination/styled";
import Table from "components/Table";
import {
  OrderByType,
  Data,
  OrderReportType,
  ExportFileType,
  OrderProductReportQueryType,
  ReportQueryVariableType,
} from "types/SalesReport";
import { ProjectIdType } from "types/Project";
import { useRenderExportedNumber } from "utils/hooks/SalesReport";
import useGetProject from "utils/hooks/useGetProject";

import ProductFilter from "domain/Report/ProductFilter";
import {
  GridLoading,
  GridPagination,
  GridRowsPerPage,
  GridShowingResult,
  Hidden,
  Typography,
  Wrapper,
  DisabledReportWrapper,
  DisabledReportBox,
} from "./styled";
import useExportReports from "./hooks/useExportReports";
import { getColumns, tableHooks } from "./customTable/config";
import { TableWrapper } from "./customTable/TableWrapper";
import { formattedProductReports } from "./helper";
import { DEFAULT_ROWS_PER_PAGE, ROWS_PER_PAGES, DEFAULT_PAGE_START } from "./config";

const ProductReport: FC = () => {
  const { projectId } = useParams<ProjectIdType>();
  const filterRef = useRef({});
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [page, setPage] = useState(DEFAULT_PAGE_START);
  const [filterValue, setFilterValue] = useState({});

  const { exportReports, isLoadingExportReports } = useExportReports();
  const tableColumns = useMemo(() => getColumns<OrderReportType>(), []);

  const { isSalesReportEnabled, loading: projectInfoLoading } = useGetProject(projectId);

  const { data: orderReportsData, loading: orderReportsLoading, refetch: orderReportsRefetch } = useQuery<
    OrderProductReportQueryType,
    ReportQueryVariableType
  >(ORDER_PRODUCT_REPORTS, {
    variables: { projectId, pageSize: rowsPerPage, page: page - 1, filter: { ...filterRef.current, ...filterValue } },
    skip: !projectId || isEmpty(filterValue) || !isSalesReportEnabled,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const total: number = get(orderReportsData, "orderProductReports.total") || 0;

  const handlePageClick = (data: { selected: number }) => {
    const { selected } = data;
    // +1 because of ReactPaginate page start from 0 but my page start from 1
    setPage(selected + 1);
  };

  const newHandleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(DEFAULT_PAGE_START);
  };

  const handleChangeSortBy = (sortBys: SortingRule<{ id: string; desc: boolean }>[]) => {
    const sortBy = sortBys[0];
    if (sortBy && isSalesReportEnabled) {
      filterRef.current = { sortBy: sortBy.id, orderBy: sortBy.desc ? OrderByType.DESC : OrderByType.ASC };
      orderReportsRefetch({
        projectId,
        pageSize: rowsPerPage,
        page: page - 1,
        filter: { ...filterRef.current, ...filterValue },
      });
    }
  };

  const handleExportReports = (exportFileType: ExportFileType) => {
    if (!projectId || !isSalesReportEnabled) {
      return;
    }
    exportReports(projectId, { ...filterRef.current, ...filterValue }, exportFileType);
  };

  let orderProductReports = get(orderReportsData, "orderProductReports.results") || [];

  const isReportDisabled = !orderReportsLoading && !projectInfoLoading && !isSalesReportEnabled;

  orderProductReports = useMemo(
    () => (isReportDisabled ? MOCK_PRODUCT_REPORT_DATA : formattedProductReports(orderProductReports)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderProductReports],
  );

  const exportedNumber = useRenderExportedNumber({ isSelectedAll: true, excludeIds: {}, selectedIds: {}, total });

  const totalPrice = get(orderReportsData, "orderProductReports.summary.totalPrice") || 0;
  const totalSoldAmount = get(orderReportsData, "orderProductReports.summary.totalSoldAmount") || 0;

  return (
    <Wrapper>
      <Grid container alignItems="center" className="mb-2">
        <ProductFilter
          isDisabledExportButton={!orderProductReports.length}
          isLoadingExportReports={isLoadingExportReports}
          setFilterValue={setFilterValue}
          handleExportReports={handleExportReports}
          exportedNumber={exportedNumber}
        />
      </Grid>

      <TableWrapper isBlurContent={isReportDisabled}>
        {
          // render when productReport are loading
          (orderReportsLoading || projectInfoLoading) && (
            <GridLoading>
              <CircularProgress className="m-4" />
            </GridLoading>
          )
        }

        {isReportDisabled && (
          <DisabledReportWrapper>
            <DisabledReportBox>
              <Typography variant="title2" color={COLORS.Dark} className="text-center mb-3">
                {t("salesReport.orderReports.disabled")}
              </Typography>
              {projectId && <ButtonToPlanUpgrade label={t("DisableFeature.button")} projectId={projectId} />}
            </DisabledReportBox>
          </DisabledReportWrapper>
        )}

        {/* We have to render table and hide table when there are not reports
            to prevent table reset all filter and sortBy state in the table. */}
        <Hidden isHidden={orderProductReports.length === 0}>
          <Table<Data, Record<string, Data>>
            data={orderProductReports}
            columns={tableColumns}
            manualSortBy
            hooks={tableHooks}
            onChangeSortBy={handleChangeSortBy}
            customState={{ totalPrice, totalSoldAmount, totalRows: total }}
            isBlurContent={isReportDisabled}
          />
        </Hidden>

        {
          // render when no productReport
          !orderReportsLoading && orderProductReports.length === 0 && isSalesReportEnabled && (
            <Grid container className="mt-4">
              <Grid item xs={12}>
                <Typography variant="title2" color={COLORS.DarkLight} className="text-center">
                  {
                    // No sales reports
                    t("salesReport.orderReports.placeholder")
                  }
                </Typography>
              </Grid>
            </Grid>
          )
        }
      </TableWrapper>

      {!isReportDisabled && (
        <Grid container>
          <Grid item className="px-2" container xs={12} alignItems="center" justify="center">
            <GridShowingResult item xs={6} md="auto" lg="auto">
              <ShowingResult page={page} rowsPerPage={rowsPerPage} total={total} />
            </GridShowingResult>
            <GridPagination item xs={12} md="auto" lg="auto" container>
              <PaginationWrapper>
                <ReactPaginate
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  // -1 because of ReactPaginate page start from 0 but my page start from 1
                  forcePage={page - 1}
                  pageCount={Math.ceil(total / rowsPerPage) || 0}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageClick}
                  activeClassName="active"
                  containerClassName="d-flex flex-wrap p-0"
                  pageClassName="page-no"
                  breakClassName="break"
                  previousClassName="page-no previous"
                  nextClassName="page-no next"
                />
              </PaginationWrapper>
            </GridPagination>

            <GridRowsPerPage item xs={6} md="auto" lg="auto" container alignItems="center" justify="flex-end">
              <Grid item>
                <Typography className="d-inline-block mr-2" color="darkGray">
                  {t("salesReport.rowsSelector.show")}
                </Typography>
              </Grid>
              <Grid item>
                <RowsSelectorPerPage
                  options={ROWS_PER_PAGES}
                  onChange={newHandleChangeRowsPerPage}
                  rowsPerPage={rowsPerPage}
                />
              </Grid>
              <Grid item>
                <Typography className="d-inline-block ml-2" color="darkGray">
                  {t("salesReport.rowsSelector.perPage")}
                </Typography>
              </Grid>
            </GridRowsPerPage>
          </Grid>
        </Grid>
      )}
    </Wrapper>
  );
};

export default ProductReport;
