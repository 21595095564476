import styled from "styled-components";
import DefaultModal from "components/Modal";
import COLORS from "constants/Colors";

export const Modal = styled(DefaultModal)`
  &.MuiSvgIcon {
    margin-top: 10px !important;
  }
`;

export const Wrapper = styled.div`
  width: 500px;
  max-height: 386px;
  padding: 24px;
  box-sizing: border-box;

  @media only screen and (max-width: 600px) {
    width: 100% !important;
    max-width: 500px;
  }
`;

export const Header = styled.div`
  width: 100%;
  max-height: 32px;
  display: inline-flex;
  justify-content: space-between;
  padding-bottom: 12px;
`;

export const OptionWrapper = styled.div`
  height: 250px;
  width: 100%;
  padding: 16px 16px 0 16px;
  box-sizing: border-box;
  background-color: ${COLORS.PrimaryNude};
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Detail = styled.div`
  margin-bottom: 8px;
  display: inline-flex;

  span {
    color: ${COLORS.Accent};
  }
`;

export const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  border: none;

  tbody {
    display: block;
    position: relative;
    width: 100%;
    overflow-x: auto;
    height: 190px;
    padding-bottom: 16px;

    ::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  tr {
    width: 100%;
    display: flex;
  }

  td,
  th {
    text-align: left;
    padding-bottom: 8px;
  }

  td:nth-child(1),
  th:nth-child(1) {
    width: 90px;
  }

  td:nth-child(2),
  th:nth-child(2) {
    width: 100%;
  }

  div {
    width: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
    box-sizing: border-box;
  }
`;
