import React, { FC } from "react";

import Grid from "components/Grid";
import { CircleIcon } from "components/Icon";
import Typography from "components/Typography";

import COLORS from "constants/Colors";
import { CarrierDescriptionText } from "constants/Shippop";
import { ListWrapper } from "./styled";

type ShippingConditionListPropsType = {
  conditions: CarrierDescriptionText[];
};

const ShippingConditionList: FC<ShippingConditionListPropsType> = ({ conditions }) => {
  return (
    <>
      {conditions.map(({ body: condition, subBody: descriptions }: CarrierDescriptionText, conditionIndex: number) => {
        return (
          <Grid item container key={condition} className={`d-flex flex-nowrap ${conditionIndex ? "pt-2" : "pt-3"}`}>
            <Grid item className="d-flex flex-grow-0 pr-1">
              <div>
                <CircleIcon color={COLORS.LightBlue} />
              </div>
            </Grid>
            <Grid item container className="d-flex flex-grow-1 text-wrap">
              <Grid item xs={12}>
                <Typography variant="body3">{condition}</Typography>
              </Grid>
              {descriptions && (
                <Grid item xs={12} className="pl-2 pt-1">
                  <ListWrapper>
                    {descriptions.map((description: string, descriptionIndex: number) => {
                      return (
                        <li key={description} className={descriptionIndex ? "pt-1" : ""}>
                          <Typography variant="body3">{description}</Typography>
                        </li>
                      );
                    })}
                  </ListWrapper>
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default ShippingConditionList;
