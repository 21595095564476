const placeOrderToLalamove = {
  en: {
    EXCEEDED_CREDIT: "Not Enough Shipping credits Please refill credit in wallet to continue",
  },
  th: {
    EXCEEDED_CREDIT: "กรุณเติมเงินค่าจัดส่งสินค้าในวอลเล็ท เพื่อดำเนินการต่อ",
  },
};

export default placeOrderToLalamove;
