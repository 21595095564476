import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 36px;
  min-width: 36px;
  margin-right: 16px;
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  color: ${({ theme }) => theme.COLORS.DarkLight};
  background-color: ${({ theme }) => theme.COLORS.Light};

  & > img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const ImagePlaceholder: FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => (
  <Wrapper className={className}>
    <img alt="logo_placeholder" src="/assets/img/store-logo-empty.png" />
  </Wrapper>
);

export default ImagePlaceholder;
