export const GTM_EVENT = {
  SIGNUP: "signup",
  LOGIN: "login",
  PASSWORD_RESET: "password_reset",
  CREATE_STORE: "create_store",
  ADD_CATALOG: "add_catalog",
  ADD_PRODUCT: "add_product",
  ADD_SHIPPING: "add_shipping",
  AI_SETTING: "ai_setting",
  SET_FIRST_MESSAGE: "set_first_message",
  ADD_KNOWLEDGE: "add_knowledge",
  SET_SYNONYM_PRODUCT_NAME: "set_synonym_product_name",
  COMPLETELY_CONNECTED_STORES: "completely_connected_stores",
};

export const GTM_VARIABLE_NAME = {
  CHANNEL: "channel",
  LOGIN_METHOD: "login_method",
  USER_ID: "user_id",
  TYPE_OF_BUSINESS: "type_of_business",
  STORES: "stores",
};

export const GTM_CHANNEL = {
  APPLE: "apple",
  EMAIL: "email",
  FACEBOOK: "facebook",
  GOOGLE: "google",
  INSTAGRAM: "instagram",
  LINE: "line",
};
