import imageCompression from "browser-image-compression";

const MAXIMUM_IMAGE_SIZE_UPLOAD = 2; // 2MB
const MAXIMUM_WIDTH_OR_HEIGHT = 2048; // 2048px

export const compressImage = async (
  imageFile: File,
  maxWidthOrHeight = MAXIMUM_WIDTH_OR_HEIGHT,
  maxSizeMB = MAXIMUM_IMAGE_SIZE_UPLOAD,
) => {
  // imageFile instanceof Blob
  const imageFileSize = Number(imageFile.size) / 1024 / 1024;
  // if file size already smaller than 2MB then skip compression / use as is
  if (imageFileSize <= maxSizeMB) {
    return imageFile;
  }

  try {
    const options = {
      maxSizeMB,
      maxWidthOrHeight,
      useWebWorker: true, // Compress in web-worker or Compress in main thread
    };
    const compressedFile = await imageCompression(imageFile, options);
    // compressedFile instanceof Blob

    const compressedFileSize = Number(compressedFile.size) / 1024 / 1024;
    // check compressedFileSize is not larger than maxSizeMB
    if (compressedFileSize <= maxSizeMB) {
      return compressedFile;
    }
    // size is larger than ${maxSizeMB}MB return null
    return null;
  } catch (error) {
    // (error);
    return null;
  }
};
