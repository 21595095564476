import * as React from "react";

const SvgIcVat = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26.384 16c0-5.724-4.659-10.383-10.384-10.383-5.725 0-10.384 4.659-10.384 10.384 0 5.725 4.659 10.384 10.384 10.384 5.725 0 10.384-4.659 10.384-10.384zm-14.16-6.607a2.835 2.835 0 012.832 2.832 2.835 2.835 0 01-2.832 2.832 2.835 2.835 0 01-2.832-2.832 2.835 2.835 0 012.832-2.832zm-1.22 12.94l-1.335-1.335L20.997 9.669l1.334 1.335-11.328 11.328zM8.025 25.31a12.202 12.202 0 007.03 2.915v3.776a15.962 15.962 0 01-9.7-4.023zm15.949-.001l2.669 2.669a15.958 15.958 0 01-9.7 4.022v-3.776a12.208 12.208 0 007.031-2.915zM3.776 16.945a12.208 12.208 0 002.915 7.03l-2.669 2.67A15.958 15.958 0 010 16.945zM32 16.944a15.962 15.962 0 01-4.022 9.7l-2.67-2.669a12.202 12.202 0 002.916-7.031zm-12.224.001a2.835 2.835 0 012.832 2.832 2.835 2.835 0 01-2.832 2.832 2.835 2.835 0 01-2.832-2.832 2.835 2.835 0 012.832-2.832zm0 1.888a.944.944 0 100 1.888.944.944 0 000-1.888zM4.022 5.358l2.67 2.669a12.202 12.202 0 00-2.916 7.03H0a15.948 15.948 0 014.022-9.699zm23.956 0A15.958 15.958 0 0132 15.056h-3.776a12.205 12.205 0 00-2.915-7.03zM12.224 11.28a.944.944 0 110 1.888.944.944 0 010-1.888zM15.056 0v3.776a12.208 12.208 0 00-7.031 2.915L5.356 4.023a15.958 15.958 0 019.7-4.022zm1.888 0a15.962 15.962 0 019.7 4.022l-2.67 2.67a12.202 12.202 0 00-7.03-2.916z"
    />
  </svg>
);

export default SvgIcVat;
