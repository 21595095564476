import styled from "styled-components";
import { Z_INDEX } from "constants/ZIndex";

type StyledBurgerProps = {
  isActive?: boolean;
};

export const StyledBurger = styled.button<StyledBurgerProps>`
  position: absolute;
  float: right;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 56px;
  height: 56px;
  background: transparent;
  border: none;
  padding: 16px;
  z-index: ${Z_INDEX.BURGER_MENU};
  background: ${({ isActive, theme }) => (isActive ? "none" : theme.COLORS.Primary)};
  cursor: pointer;

  &:focus {
    outline: none;
  }

  span {
    width: 24px;
    height: 2px;
    background: ${({ theme }) => theme.COLORS.LightWhite};
    border-radius: 10px;
    position: relative;
    transform-origin: 1px;
    transition: transform 0.5s ease;

    :first-child {
      transform: ${({ isActive }) => (isActive ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ isActive }) => (isActive ? "0" : "1")};
      transform: ${({ isActive }) => (isActive ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      width: ${({ isActive }) => (isActive ? "24px" : "15px")};
      transform: ${({ isActive }) => (isActive ? "rotate(-45deg)" : "rotate(0)")};
      left: ${({ isActive }) => (isActive ? "0px" : "9px")};
    }
  }
`;
