import React from "react";

const SvgIcDoingPayment = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.3998 1.19995C4.73706 1.19995 4.1998 1.73721 4.1998 2.39995V7.19995C4.1998 7.86269 4.73706 8.39995 5.3998 8.39995H8.9998C9.66255 8.39995 10.1998 7.86269 10.1998 7.19995V3.84848C10.1998 3.53022 10.0734 3.22499 9.84833 2.99995L8.3998 1.55142C8.17476 1.32638 7.86954 1.19995 7.55128 1.19995H5.3998Z"
      fill="currentColor"
    />
    <path
      d="M1.7998 4.79995C1.7998 4.13721 2.33706 3.59995 2.9998 3.59995V9.59995H7.7998C7.7998 10.2627 7.26255 10.8 6.5998 10.8H2.9998C2.33706 10.8 1.7998 10.2627 1.7998 9.59995V4.79995Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgIcDoingPayment;
