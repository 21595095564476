import React, { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm, useWatch } from "react-hook-form";
import type { OptionType } from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";

import Typography from "components/Typography";
import { IcArrowBack, IcDelete, IcEye, IcInfo, IcPicture } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";
import ButtonBase from "components/ButtonBase";
import Grid from "components/Grid";
import Divider from "components/Divider";
import Card from "components/Card";
import Button from "components/Button";
import TextField from "components/TextField";
import ErrorText from "components/ErrorText";
import Select from "components/Select";
// import { RadioBox } from "components/Radio";
import DropzoneUpload from "components/DropzoneUpload";
import COLORS from "constants/Colors";
import { ImageUrl } from "types/Image";
import ConfirmationModal from "components/ConfirmationModal";

import {
  RecurringNotificationOptInButtonMessageType,
  RecurringNotificationOptInDeliveryContextType,
  RecurringNotificationOptInFrequencyType,
  RecurringNotificationOptInsType,
} from "types/Broadcast";

import { FormContainer, InformationBoxContainer, MessageImageUploaderContainer } from "./styled";

import {
  BUTTON_MESSAGE_OPTIONS,
  DEFAULT_MESSAGE_AFTER_REQUEST_ACCEPTED,
  DEFAULT_MESSAGE_BEFORE_REQUEST,
  FREQUENCY_OPTIONS,
  MAX_TITLE_MESSAGE,
  MESSAGE_AFTER_REQUEST_ACCEPTED_MAX_LENGTH,
  MESSAGE_BEFORE_REQUEST_MAX_LENGTH,
} from "./constants";
import schema, { Schema, schemaNames } from "./schema";
import TooltipLabel from "./TooltipLabel";
import { ModalFacebookRecurringExample } from "../ModalFacebookRecurringExample";
import ModalAdditionalMessageExample from "./ModalAdditionalMessageExample";

interface FacebookRequestFormProps {
  onSubmit: (data: Schema) => Promise<void>;
  recurringNotiOptIns: RecurringNotificationOptInsType[];
  onClickBack: () => void;
  projectId: string;
  deliveryContextTypeOptions: {
    value: RecurringNotificationOptInDeliveryContextType;
    label: string;
  }[];

  isEditMode?: boolean;
  recurringNotificationOptInId?: string;
  isLoading?: boolean;
}

function FacebookRequestForm({
  onSubmit,
  recurringNotiOptIns,
  onClickBack,
  projectId,
  deliveryContextTypeOptions,
  isEditMode = false,
  isLoading = false,
  recurringNotificationOptInId,
}: FacebookRequestFormProps): JSX.Element {
  const { t } = useTranslation();
  const [isOpenExample, setIsOpenExample] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenAdditionExample, setIsOpenAdditionExample] = useState(false);

  const {
    errors,
    handleSubmit,
    control,
    reset,
    trigger,
    // formState: { isDirty },
  } = useForm<Schema>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const broadcastFrequency = useWatch<RecurringNotificationOptInFrequencyType>({
    name: schemaNames.frequencyType,
    control,
    defaultValue: FREQUENCY_OPTIONS[0].value,
  });

  const imgSrc = useWatch<string>({
    name: schemaNames.messageImageUrl,
    control,
  });

  const title = useWatch<string>({
    name: schemaNames.messageTitle,
    control,
  });

  const buttonMessageType = useWatch<RecurringNotificationOptInButtonMessageType>({
    name: schemaNames.buttonMessageType,
    control,
    defaultValue: RecurringNotificationOptInButtonMessageType.FREQUENCY,
  });

  const buttonMessage = BUTTON_MESSAGE_OPTIONS.find((option) => option.value === buttonMessageType)?.label || "";
  const parsedBroadcastFrequency = broadcastFrequency?.toLowerCase?.()?.trim?.() || "";

  const getDuration = () => {
    switch (broadcastFrequency) {
      case RecurringNotificationOptInFrequencyType.DAILY:
        return 6;
      // case RecurringNotificationOptInFrequencyType.WEEKLY:
      //   return 9;
      // case RecurringNotificationOptInFrequencyType.MONTHLY:
      //   return 12;
      default:
        return 6;
    }
  };

  const isDisabledSubmit = !!Object.keys(errors).length || isLoading;

  const handleCloseExample = () => {
    setIsOpenExample(false);
  };

  const handleBeforeSubmit: FormEventHandler = async (e) => {
    e.preventDefault();
    await trigger();

    if (Object.keys(errors).length) {
      const firstError = Object.keys(errors)[0] as keyof EventTarget;
      const target = (e.target[firstError] as unknown) as HTMLElement;
      target?.scrollIntoView?.({ behavior: `smooth`, block: "center" });
      return;
    }

    setIsOpenConfirmModal(true);
  };

  useEffect(() => {
    if (isEditMode && recurringNotiOptIns) {
      const recurringNotiOptIn = recurringNotiOptIns.find(({ id }) => id === recurringNotificationOptInId);
      if (!recurringNotiOptIn) {
        return;
      }

      reset(
        {
          name: recurringNotiOptIn.name,
          deliveryContextType: recurringNotiOptIn.deliveryContextType,
          messageTitle: recurringNotiOptIn.messageTitle,
          messageImageUrl: recurringNotiOptIn.messageImageUrl,
          frequencyType: recurringNotiOptIn.frequencyType,
          buttonMessageType: recurringNotiOptIn.buttonMessageType,
          isAutoRenew: recurringNotiOptIn.isAutoRenew,
          invitationMessage: recurringNotiOptIn.invitationMessage,
          reactionMessage: recurringNotiOptIn.reactionMessage,
        },
        { isDirty: false },
      );
    }
  }, [isEditMode, recurringNotiOptIns, reset, recurringNotificationOptInId, t]);

  return (
    <div className="w-100">
      <ModalAdditionalMessageExample
        isOpen={isOpenAdditionExample}
        onClose={() => {
          setIsOpenAdditionExample(false);
        }}
      />
      <ConfirmationModal
        isOpen={isOpenConfirmModal}
        title={isEditMode ? t("broadcast.facebook.modal.edit.title") : t("broadcast.facebook.modal.create.title")}
        subTitle={
          isEditMode ? t("broadcast.facebook.modal.edit.subtitle") : t("broadcast.facebook.modal.create.subtitle")
        }
        textSubmit={t("Confirm")}
        onClose={() => setIsOpenConfirmModal(false)}
        onSubmit={handleSubmit(onSubmit)}
        isHiddenCloseBtn
      />
      <ModalFacebookRecurringExample
        isOpen={isOpenExample}
        onClose={handleCloseExample}
        imgSrc={imgSrc}
        title={title}
        subtitle={t(`broadcast.facebookRequestForm.form.previewSubtitle`, { months: getDuration() })}
        buttonMessage={
          buttonMessageType === RecurringNotificationOptInButtonMessageType.FREQUENCY
            ? t(buttonMessage, {
                frequency: t(`broadcast.facebookRequestForm.form.${parsedBroadcastFrequency}`)?.toLowerCase?.(),
              })
            : t(buttonMessage)
        }
      />
      <ButtonBase color="secondary" className="ml-3 mt-4 p-2" onClick={onClickBack}>
        <SvgIcon component={IcArrowBack} fontSize="small" />
        {t("Back")}
      </ButtonBase>
      <Grid item xs={12} className="px-3">
        <Divider margin="22px 0 0 0" />
      </Grid>
      <FormContainer>
        <Grid item xs={12} className="pt-4">
          <Typography variant="title2">{t("broadcast.facebookRequestForm.title")}</Typography>
          <Typography variant="body2" className="mb-4 mt-4">
            {t("broadcast.facebookRequestForm.description")}
          </Typography>

          <form onSubmit={handleBeforeSubmit} className="my-4">
            <Card noShadow noBorder>
              <Typography variant="title4" className="mb-4">
                {t("broadcast.facebookRequestForm.form.title")}
              </Typography>

              <div className="mb-4">
                <Typography className="tw-mb-1" variant="body3">
                  {t("broadcast.facebookRequestForm.form.requestNameLabel")}
                </Typography>
                <Controller
                  name={schemaNames.name}
                  control={control}
                  defaultValue=""
                  render={({ value, onChange, ...props }) => (
                    <>
                      <TextField
                        {...props}
                        value={value}
                        error={!!errors?.name}
                        onChange={(e) => onChange(e.target.value)}
                        fullWidth
                        variant="outlined"
                        placeholder={t("broadcast.facebookRequestForm.form.requestNamePlaceholder")}
                      />
                      {errors.name?.message && (
                        <Grid item xs={12} className="p-1">
                          <ErrorText>{t(errors.name?.message || "")}</ErrorText>
                        </Grid>
                      )}
                    </>
                  )}
                />
              </div>

              <div className="mb-4">
                <Typography className="tw-mb-1" variant="body3" {...(isEditMode ? { color: COLORS.LightBlue } : {})}>
                  {t("broadcast.facebookRequestForm.form.requestContextLabel")}
                </Typography>
                <Controller
                  name={schemaNames.deliveryContextType}
                  control={control}
                  defaultValue={deliveryContextTypeOptions?.[0]?.value}
                  render={({ value, onChange, ...props }) => {
                    const selectedOption = deliveryContextTypeOptions.find((option) => option.value === value);

                    return (
                      <>
                        <Select
                          {...props}
                          options={deliveryContextTypeOptions}
                          value={selectedOption}
                          placeholder={t("broadcast.facebookRequestForm.form.requestContextPlaceholder") as string}
                          fullWidth
                          onChange={(option) => option && onChange((option as OptionType).value)}
                          isSearchable={false}
                          isDisabled={isEditMode}
                        />
                        {errors.deliveryContextType && (
                          <Grid item xs={12} className="p-1">
                            <ErrorText>{t(errors.deliveryContextType?.message || "")}</ErrorText>
                          </Grid>
                        )}
                      </>
                    );
                  }}
                />
                <InformationBoxContainer className="mt-2">
                  <SvgIcon component={IcInfo} fontSize="small" />
                  <Typography variant="body3" className="mr-3">
                    {t("broadcast.facebookRequestForm.form.requestContextHelper")}
                  </Typography>
                </InformationBoxContainer>
              </div>

              <Card noShadow>
                <div className="d-flex justify-content-between mb-4 align-items-center">
                  {/* <Typography variant="title4">{t("broadcast.facebookRequestForm.form.subtitle")}</Typography> */}
                  {/* <ButtonBase onClick={() => setIsOpenExample(true)}>
                    <div className="p-1">
                      <IcEye className="mr-1" />
                      {t("broadcast.facebookRequestForm.form.exampleBtn")}
                    </div>
                  </ButtonBase> */}
                </div>
                <div className="d-xl-flex">
                  <div className="mb-4">
                    <Controller
                      name={schemaNames.messageImageUrl}
                      control={control}
                      render={({ value, onChange, ...props }) => {
                        const handleChangeImage = (imageUrls: ImageUrl[]) => {
                          if (imageUrls.length) {
                            const [{ publicUrl }] = imageUrls;
                            onChange(publicUrl);
                          }
                        };

                        return (
                          <MessageImageUploaderContainer>
                            {value && (
                              <div className="image-container">
                                <button type="button" onClick={() => onChange(null)}>
                                  <IcDelete />
                                </button>
                                <img src={value ?? ""} alt="message" />
                              </div>
                            )}
                            <div className={classNames({ "d-none": value })}>
                              <DropzoneUpload {...props} projectId={projectId} noDrag onChange={handleChangeImage}>
                                <div className="d-flex flex-column justify-content-center w-100">
                                  <IcPicture style={{ color: COLORS.Primary, margin: "0 auto" }} />
                                  <Typography variant="title4" color={COLORS.Dark} className="mt-2 text-center">
                                    {t("broadcast.facebookRequestForm.form.uploadPlaceholder")}
                                  </Typography>
                                  <Typography variant="body4" color={COLORS.DarkMed} className="mt-2 text-center">
                                    {t("broadcast.facebookRequestForm.form.uploadDescription")}
                                  </Typography>
                                  <div className="mt-2 w-100 d-flex">
                                    <div className="mx-auto" style={{ width: "190px" }}>
                                      <Button fullWidth>{t("broadcast.facebookRequestForm.form.upload")}</Button>
                                    </div>
                                  </div>
                                </div>
                              </DropzoneUpload>
                            </div>
                          </MessageImageUploaderContainer>
                        );
                      }}
                    />
                  </div>

                  <div className="flex-1 d-flex h-full flex-column justify-content-center p-lg-4">
                    <div className="mb-3 text-right">
                      <ButtonBase onClick={() => setIsOpenExample(true)}>
                        <div className="p-1">
                          <IcEye className="mr-1" />
                          {t("broadcast.facebookRequestForm.form.exampleBtn")}
                        </div>
                      </ButtonBase>
                    </div>

                    <div className="mb-4">
                      <Controller
                        name={schemaNames.messageTitle}
                        control={control}
                        defaultValue=""
                        render={({ value, onChange, ...props }) => (
                          <div>
                            <div className="d-flex justify-content-between align-items-center tw-mb-1 tw-mt-4">
                              <Typography variant="body3">
                                {t("broadcast.facebookRequestForm.form.titleMessageLabel", {
                                  maxLength: MAX_TITLE_MESSAGE,
                                })}
                              </Typography>
                              <Typography variant="body3">
                                {value?.length ?? 0}/{MAX_TITLE_MESSAGE}
                              </Typography>
                            </div>
                            <TextField
                              {...props}
                              value={value}
                              onChange={(e) => {
                                const { value } = e.currentTarget;
                                const length = value?.length;
                                if (length <= MAX_TITLE_MESSAGE) {
                                  onChange(value);
                                }
                              }}
                              fullWidth
                              error={!!errors.messageTitle}
                              variant="outlined"
                              placeholder={t("broadcast.facebookRequestForm.form.titleMessagePlaceholder", {
                                maxLength: MAX_TITLE_MESSAGE,
                              })}
                            />
                            {errors.messageTitle?.message && (
                              <Grid item xs={12} className="p-1">
                                <ErrorText>{t(errors.messageTitle?.message || "")}</ErrorText>
                              </Grid>
                            )}
                          </div>
                        )}
                      />
                    </div>

                    <div className="mb-4 d-none">
                      <Typography
                        className="tw-mb-1"
                        variant="body3"
                        {...(isEditMode ? { color: COLORS.LightBlue } : {})}
                      >
                        {t("broadcast.facebookRequestForm.form.broadcastFrequencyLabel")}
                      </Typography>
                      <Controller
                        name={schemaNames.frequencyType}
                        control={control}
                        defaultValue={FREQUENCY_OPTIONS[0].value}
                        render={({ value, onChange, ...props }) => {
                          const options = FREQUENCY_OPTIONS.map(({ label, ...rest }) => ({
                            label: t(label),
                            ...rest,
                          }));

                          return (
                            <>
                              <Select
                                {...props}
                                options={options}
                                value={options.find((option) => option.value === value)}
                                placeholder={
                                  t("broadcast.facebookRequestForm.form.broadcastFrequencyPlaceholder") as string
                                }
                                fullWidth
                                onChange={(option) => {
                                  if (option) {
                                    onChange((option as OptionType).value);
                                  }
                                }}
                                isSearchable={false}
                                isDisabled
                              />
                              {errors.frequencyType && (
                                <Grid item xs={12} className="p-1">
                                  <ErrorText>{t(errors.frequencyType?.message || "")}</ErrorText>
                                </Grid>
                              )}
                            </>
                          );
                        }}
                      />
                    </div>

                    <div className="mb-4">
                      <Typography className="tw-mb-1" variant="body3">
                        {t("broadcast.facebookRequestForm.form.buttonMessageLabel")}
                      </Typography>
                      <Controller
                        name={schemaNames.buttonMessageType}
                        control={control}
                        defaultValue={BUTTON_MESSAGE_OPTIONS[0].value}
                        render={({ value, onChange, ...props }) => {
                          const options = BUTTON_MESSAGE_OPTIONS.map(({ label, ...rest }) => ({
                            label:
                              rest.value === RecurringNotificationOptInButtonMessageType.FREQUENCY
                                ? t(label, {
                                    frequency: t(
                                      `broadcast.facebookRequestForm.form.${parsedBroadcastFrequency}`,
                                    )?.toLowerCase?.(),
                                  })
                                : t(label),
                            ...rest,
                          }));
                          return (
                            <>
                              <Select
                                {...props}
                                options={options}
                                value={options.find((option) => option.value === value)}
                                placeholder={t("broadcast.facebookRequestForm.form.buttonMessagePlaceholder") as string}
                                fullWidth
                                onChange={(option) => option && onChange((option as OptionType).value)}
                                isSearchable={false}
                              />
                              {errors.buttonMessageType && (
                                <Grid item xs={12} className="p-1">
                                  <ErrorText>{t(errors?.buttonMessageType?.message || "")}</ErrorText>
                                </Grid>
                              )}
                            </>
                          );
                        }}
                      />
                    </div>

                    <div className="mb-4">
                      <TooltipLabel />
                      <Controller
                        name={schemaNames.isAutoRenew}
                        control={control}
                        defaultValue
                        render={({ value, onChange, ...props }) => {
                          const options = [
                            {
                              label: t("broadcast.facebookRequestForm.form.autoRenewTitle"),
                              value: "true",
                            },
                            {
                              label: t("broadcast.facebookRequestForm.form.noAutoRenewTitle"),
                              value: "false",
                            },
                          ];

                          return (
                            <>
                              <Select
                                {...props}
                                fullWidth
                                isSearchable={false}
                                isDisabled={isEditMode}
                                onChange={(option) => option && onChange((option as OptionType).value)}
                                options={options}
                                value={options.find((option) => {
                                  const isTrue = option.value === "true";
                                  return isTrue === value;
                                })}
                              />
                            </>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Card>
              {/* <TooltipLabel isDisable={isEditMode} /> */}
              {/* <Controller
                name={schemaNames.isAutoRenew}
                control={control}
                defaultValue
                render={({ value, onChange }) => {
                  return (
                    <div className="d-flex flex-column flex-lg-row">
                      <div className="w-100 py-1 pr-1 flex-grow-1">
                        <RadioBox
                          title={t("broadcast.facebookRequestForm.form.autoRenewTitle")}
                          SubTitle={t("broadcast.facebookRequestForm.form.autoRenewSubtitle")}
                          isActive={value}
                          onClick={(e) => {
                            e.preventDefault();
                            onChange(true);
                          }}
                          disabled={isEditMode}
                        />
                      </div>
                      <div className="w-100 py-1 pl-lg-1 flex-grow-1">
                        <RadioBox
                          title={t("broadcast.facebookRequestForm.form.noAutoRenewTitle")}
                          SubTitle={t("broadcast.facebookRequestForm.form.noAutoRenewSubtitle")}
                          isActive={!value}
                          disabled={isEditMode}
                          onClick={(e) => {
                            e.preventDefault();
                            onChange(false);
                          }}
                        />
                      </div>
                    </div>
                  );
                }}
              /> */}
            </Card>
            <Card noShadow noBorder className="mt-4">
              <div className="d-flex justify-content-between w-100">
                <Typography variant="title3">
                  {t("broadcast.facebookRequestForm.form.additionalMessageSetting")}
                </Typography>

                <ButtonBase onClick={() => setIsOpenAdditionExample(true)}>
                  <div className="p-1">
                    <IcEye className="mr-1" />
                    {t("broadcast.facebookRequestForm.form.exampleBtn")}
                  </div>
                </ButtonBase>
              </div>
              <div className="w-100">
                <div className="w-100">
                  <Controller
                    name={schemaNames.invitationMessage}
                    control={control}
                    defaultValue={isEditMode ? "" : t(DEFAULT_MESSAGE_BEFORE_REQUEST)}
                    render={({ value, onChange, ...props }) => (
                      <div>
                        <div className="d-flex justify-content-between align-items-center tw-mb-1 tw-mt-4">
                          <Typography variant="body3">
                            {t("broadcast.facebookRequestForm.form.messageBeforeRequestLabel")}
                          </Typography>
                          <Typography variant="body3">
                            {value?.length ?? 0}/{MESSAGE_BEFORE_REQUEST_MAX_LENGTH}
                          </Typography>
                        </div>
                        <TextField
                          {...props}
                          multiline
                          value={value}
                          onChange={(e) => {
                            const { value } = e.currentTarget;
                            const length = value?.length;
                            if (length <= MESSAGE_BEFORE_REQUEST_MAX_LENGTH) {
                              onChange(value);
                            }
                          }}
                          fullWidth
                          error={!!errors.invitationMessage}
                          variant="outlined"
                        />
                        {errors.invitationMessage?.message && (
                          <Grid item xs={12} className="p-1">
                            <ErrorText>{t(errors.invitationMessage?.message || "")}</ErrorText>
                          </Grid>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className="mb-4 w-100">
                  <Controller
                    name={schemaNames.reactionMessage}
                    control={control}
                    defaultValue={isEditMode ? "" : t(DEFAULT_MESSAGE_AFTER_REQUEST_ACCEPTED)}
                    render={({ value, onChange, ...props }) => (
                      <div>
                        <div className="d-flex justify-content-between align-items-center tw-mb-1 tw-mt-4">
                          <Typography variant="body3">
                            {t("broadcast.facebookRequestForm.form.messageAfterRequestAccepted")}
                          </Typography>
                          <Typography variant="body3">
                            {value?.length ?? 0}/{MESSAGE_AFTER_REQUEST_ACCEPTED_MAX_LENGTH}
                          </Typography>
                        </div>
                        <TextField
                          {...props}
                          multiline
                          value={value}
                          onChange={(e) => {
                            const { value } = e.currentTarget;
                            const length = value?.length ?? 0;
                            if (length <= MESSAGE_AFTER_REQUEST_ACCEPTED_MAX_LENGTH) {
                              onChange(value);
                            }
                          }}
                          fullWidth
                          error={!!errors.reactionMessage}
                          variant="outlined"
                        />
                        {errors.reactionMessage?.message && (
                          <Grid item xs={12} className="p-1">
                            <ErrorText>{t(errors.reactionMessage?.message || "")}</ErrorText>
                          </Grid>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>
            </Card>
            <div className="mt-4">
              <Button variant="primary" fullWidth type="submit" disabled={isDisabledSubmit}>
                {isEditMode
                  ? t("broadcast.facebookRequestForm.form.save")
                  : t("broadcast.facebookRequestForm.form.submit")}
              </Button>
            </div>
          </form>
        </Grid>
      </FormContainer>
    </div>
  );
}

export default FacebookRequestForm;
