import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { types as CardType } from "credit-card-type";
import { Link } from "react-router-dom";

import Grid from "components/Grid";
import Typography from "components/Typography";
import Button from "components/Button";
import TextField from "components/TextField";
import { SvgIcon } from "components/Icon";
import CircularProgress from "components/CircularProgress";
import { IcCreditCardVisa, IcCreditCardJcb, IcCreditCardMaster } from "components/SvgIcons";

import { getCreditCardInformation } from "utils/creditCard";

import { TERM_AND_CONDITION } from "constants/Router";
import schema from "./schema";
import { CreditCardIconsWrapper } from "./style";
import ErrorCard from "./cardError";

export interface CreditCardDataType {
  cardNumber: string;
  validDate: string;
  cvv: string;
  cardHolderName: string;
  cardType: string;
}

type AddCreditCardFormPropsType = {
  onSubmit: (creditCardData: CreditCardDataType) => void;
  error: string | boolean;
  showTitle?: boolean;
  noPadding?: boolean;
  isLoadingAddCreditCard: boolean;
};

const AddCreditCardForm: FC<AddCreditCardFormPropsType> = (props) => {
  const { onSubmit, error, isLoadingAddCreditCard, showTitle = true, noPadding = false } = props;
  const { t } = useTranslation();

  const [inputCardType, setInputCardType] = useState("");

  const { register, errors, handleSubmit, setValue, clearErrors, control } = useForm<CreditCardDataType>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    register({ name: "cardType" });
  }, [register]);
  // "credit-card-type" has no export type CreditCardTypeCardBrandId for each credit card type. so, i use any instead
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const onSubmitHandler = handleSubmit((value) => {
    const creditCardData = {
      ...value,
      inputCardType,
    };
    onSubmit(creditCardData);
  });

  const handleChangeCardNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const creditCardNumber = event.target.value;
    const { creditCardType } = getCreditCardInformation(creditCardNumber);
    setInputCardType(creditCardType);
    setValue("cardType", creditCardType);
  };

  const handleClearCreditCardNumber = () => {
    setInputCardType("");
    clearErrors("cardNumber");
  };

  return (
    <Grid container className={noPadding ? "" : "p-4"}>
      {showTitle && (
        <Grid item xs={12}>
          <Typography variant="title2" className="mb-4">
            {t("billing.addCreditCardForm.title")}
          </Typography>
        </Grid>
      )}

      {error && (
        <Grid item xs={12} className="pb-3">
          <ErrorCard />
        </Grid>
      )}
      <Grid item container justify="space-between" alignItems="center" className="pt-2">
        <Typography variant="body3">{t("billing.addCreditCardForm.cardNumber")}</Typography>
        <CreditCardIconsWrapper creditCardType={inputCardType} item>
          <SvgIcon className="p-0 credit-card-visa-type" component={IcCreditCardVisa} fontSize="default" />
          <SvgIcon className="p-0 credit-card-master-type" component={IcCreditCardMaster} fontSize="default" />
          <SvgIcon className="p-0 credit-card-jcb-type" component={IcCreditCardJcb} fontSize="default" />
        </CreditCardIconsWrapper>
      </Grid>
      <Grid item xs={12} className="pt-3">
        <Controller
          name="cardNumber"
          control={control}
          defaultValue=""
          render={(props) => {
            return (
              <TextField
                {...props}
                name="cardNumber"
                error={Boolean(errors.cardNumber)}
                helperText={t(errors?.cardNumber?.message || "")}
                onChangeCallBack={handleChangeCardNumber}
                onClearValue={handleClearCreditCardNumber}
                fullWidth
                type="creditCard"
                variant="outlined"
                isOutlined={false}
                placeholder={t("XXXX XXXX XXXX XXXX")}
              />
            );
          }}
        />
      </Grid>
      <Grid item container spacing={3} className="pt-4">
        <Grid item xs={6}>
          <Typography variant="body3" className="pb-2">
            {t("billing.addCreditCardForm.validTill")}
          </Typography>
          <Controller
            name="validDate"
            control={control}
            defaultValue=""
            render={(props) => {
              return (
                <TextField
                  {...props}
                  name="validDate"
                  fullWidth
                  variant="outlined"
                  placeholder={t("MM / YY")}
                  type="creditCardExpiredDate"
                  error={Boolean(errors.validDate)}
                  helperText={t(errors?.validDate?.message || "")}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body3" className="pb-2">
            {t("billing.addCreditCardForm.cvv.label")}
          </Typography>

          <Controller
            name="cvv"
            control={control}
            defaultValue=""
            render={(props) => {
              return (
                <TextField
                  {...props}
                  name="cvv"
                  fullWidth
                  variant="outlined"
                  type="creditCardCvv"
                  maxInputLength={inputCardType === CardType.AMERICAN_EXPRESS ? 4 : 3}
                  error={Boolean(errors.cvv)}
                  helperText={t(errors?.cvv?.message || "")}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body3" className="pb-2">
            {t("billing.addCreditCardForm.cardHolder.label")}
          </Typography>
          <TextField
            defaultValue=""
            validate={register}
            name="cardHolderName"
            fullWidth
            variant="outlined"
            placeholder={t("billing.addCreditCardForm.cardHolder.placeholder")}
            error={Boolean(errors.cardHolderName)}
            helperText={t(errors?.cardHolderName?.message || "")}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className="pt-4 pb-1">
        <Typography variant="body4" color="darkLight">
          {t("billing.addCreditCardForm.description1")}
        </Typography>
      </Grid>
      <Grid item className="d-inline-flex">
        <Typography variant="body4" color="darkLight" className="pr-1">
          {t("billing.addCreditCardForm.description2")}
        </Typography>
        <Link to={`/${TERM_AND_CONDITION}`} rel="noopener noreferrer" target="_blank">
          <Typography variant="title10" color="accent">
            {t("billing.addCreditCardForm.termAndCondition")}
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={12} className="pt-4">
        <Button fullWidth onClick={onSubmitHandler} disabled={isLoadingAddCreditCard}>
          {isLoadingAddCreditCard ? <CircularProgress size={24} /> : t("Save")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default AddCreditCardForm;
