import { SystemTagStatus } from "constants/Tag";
import { TagType, SystemTagType, CustomerRetentionStatusKeyType } from "types/Tag";
import { ColorsType } from "components/Theme/type";

export const getTagColor = (colors: ColorsType, type?: TagType) => {
  switch (type) {
    case TagType.CUSTOMER:
      return {
        color: colors.Primary,
        bgColor: colors.LightWhite,
        borderColor: colors.Primary,
      };
    case TagType.SYSTEM_CUSTOMER_RETENTION_STATUS:
      return {
        color: colors.LightWhite,
        bgColor: colors.Primary,
        borderColor: colors.Primary,
      };
    case TagType.SYSTEM_BROADCAST:
      return {
        color: colors.LightWhite,
        bgColor: colors.Success,
        borderColor: colors.Success,
      };
    case TagType.SYSTEM_COMMENT_CRAWLER:
      return {
        color: colors.LightWhite,
        bgColor: colors.Facebook,
        borderColor: colors.Facebook,
      };

    case TagType.POTENTIAL_CUSTOMER:
      return {
        color: colors.LightWhite,
        bgColor: colors.Facebook,
        borderColor: colors.Facebook,
      };

    default:
      return {
        color: colors.LightWhite,
        bgColor: colors.Primary,
        borderColor: colors.Primary,
      };
  }
};

export const getAllTags = (tags: SystemTagType[], systemTags: SystemTagType[]) => {
  const formattedSystemTags = systemTags.map((systemTag: SystemTagType) => ({
    ...systemTag,
    displayTag: SystemTagStatus[systemTag.tag as CustomerRetentionStatusKeyType],
  }));

  return [...tags, ...formattedSystemTags].sort((a, b) => {
    return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
  });
};

export const getNewestTags = (allTags: SystemTagType[], maxDisplayedTags: number) => {
  return allTags.slice(0, maxDisplayedTags);
};

// this util to check display tag is POTENTIAL_CUSTOMER or not
export const mapPotentialCustomerTag = (tag: string) => {
  /* tag: "POTENTIAL_CUSTOMER" type: "CUSTOMER" */
  return tag === TagType.POTENTIAL_CUSTOMER ? "Potential customer" : tag;
};
