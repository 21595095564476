import { gql } from "@apollo/client";

export const ADD_BILLING = gql`
  mutation addBilling($projectId: ID!, $billingInput: BillingInput!) {
    addBilling(projectId: $projectId, billingInput: $billingInput) {
      id
      projectId
      name
      address
      email
      taxId
      phoneNumber
      documentVerificationCertificatePath
      contactPerson
      isVerified
      type
      subDistrict
      district
      province
      postalCode
      titleName
    }
  }
`;

export const UPDATE_BILLING = gql`
  mutation updateBilling($projectId: ID!, $billingId: ID!, $billingInput: BillingInput!) {
    updateBilling(projectId: $projectId, billingId: $billingId, billingInput: $billingInput) {
      id
      projectId
      name
      address
      email
      taxId
      phoneNumber
      documentVerificationCertificatePath
      contactPerson
      isVerified
      type
      subDistrict
      district
      province
      postalCode
      titleName
    }
  }
`;

export const ADD_CREDIT_CARD = gql`
  mutation addCreditCard($projectId: ID!, $creditCard: CreditCardInput!) {
    addCreditCard(projectId: $projectId, creditCard: $creditCard) {
      id
      name
      number
      brand
      expirationYear
      expirationMonth
      issue
      issuedAt
    }
  }
`;

export const REMOVE_CREDIT_CARD = gql`
  mutation removeCreditCard($projectId: ID!, $id: ID!) {
    removeCreditCard(projectId: $projectId, id: $id) {
      id
    }
  }
`;

export const REPAY_BILLING = gql`
  mutation repayMonthlyBilling($projectId: ID!) {
    repayMonthlyBilling(projectId: $projectId)
  }
`;
