import produce from "immer";
import {
  CategoriesObjectType,
  ChangedFaqType,
  FaqType,
  MessageImageValue,
  MessageTextValue,
  ProductsObjectType,
  ResponseMessage,
  QuickReplyMessage,
} from "types/AISetting";
import { ValueOf } from "types/Common";
import { MessageType } from "types/Chat";

const useManageResponses = (
  currentTabLanguage = 0,
  setFAQ: (fieldName: keyof FaqType, value: ValueOf<ChangedFaqType>) => void,
  getResponses: () => ResponseMessage[],
) => {
  const getResponseField = () => {
    return currentTabLanguage === 0 ? "responses" : "enResponses";
  };

  const addResponse = (
    type: MessageType,
    value: MessageTextValue | MessageImageValue | number[] | CategoriesObjectType | ProductsObjectType,
  ) => {
    const responsesField = getResponseField();
    const responses = getResponses();

    const newResponses: ResponseMessage[] = [...responses, { type, value }];
    setFAQ(responsesField, newResponses);
  };

  const updateResponse = (newResponse: string, id: string) => {
    const responsesField = getResponseField();
    const responses = getResponses();
    const newResponses = produce(responses, (draft) => {
      draft[Number(id)].value = newResponse;
    });

    setFAQ(responsesField, newResponses);
  };

  const removeResponse = (index: number) => {
    const responsesField = getResponseField();
    const responses = getResponses();
    const filteredResponses = responses.filter((_response, responseIndex) => index !== responseIndex);

    setFAQ(responsesField, filteredResponses);
  };

  const updateQuickReplyMessage = (newData: QuickReplyMessage[]) => {
    const newDataTrim = newData.map((data) => {
      return { label: data.label.trim(), text: data.text.trim() };
    });
    const responsesField = getResponseField();
    const responses = getResponses();

    const objIndex = responses.findIndex((obj) => obj.type === MessageType.QUICK_REPLIES);
    if (objIndex !== -1 && responses[objIndex]) {
      responses[objIndex].value = newDataTrim;
    }

    setFAQ(responsesField, responses);
  };

  return {
    getResponseField,
    getResponses,
    addResponse,
    updateResponse,
    removeResponse,
    updateQuickReplyMessage,
  };
};

export default useManageResponses;
