import * as yup from "yup";

const schema = yup.object().shape({
  apiKey: yup.string().required(),
  apiSecret: yup.string().required(),
  storeName: yup.string().required(),
  warehouse: yup.string(),
});

export default schema;
