import React, { FC } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ScaleType, Tooltip } from "recharts";

import { ChartDataType } from "types/Chart";

import CustomTooltip from "../Tooltip";

type BarChartComponentPropsType = {
  data: ChartDataType[];
  color: string;
  scale?: ScaleType;
  height?: number;
  width?: number;
  tickFormatter?: (value: number) => string;
};

const BarChartComponent: FC<BarChartComponentPropsType> = (props) => {
  const viewportWidth = window.innerWidth;
  const { color, data, scale, height, width, tickFormatter } = props;

  return (
    <BarChart
      width={width || viewportWidth}
      height={height || 400}
      data={data}
      margin={{
        top: 20,
        right: 20,
        left: 20,
        bottom: 20,
      }}
    >
      <CartesianGrid vertical={false} />
      <XAxis
        axisLine={false}
        tickMargin={10}
        dataKey="xAxis"
        scale={scale || "auto"}
        padding={{ right: 15, left: 5 }}
        tickFormatter={tickFormatter}
        domain={["dataMin", "dataMax"]}
      />
      <YAxis axisLine={false} tickMargin={10} tickLine={false} />
      <Tooltip content={<CustomTooltip />} />
      <Bar dataKey="yAxis" fill={color} fillOpacity={0.75} />
    </BarChart>
  );
};

export default BarChartComponent;
