const consoleGeneratedForm = {
  en: {
    deliverable: "Deliverable",
    catalog: "Product catalog",
    price: "Price",
    SKUCode: "SKU Code",
    noExpiry: "No expiry",
    validityPeriod: "Validity period",
    expiryDate: "Expiry date",
    "date&time": "Date & time",
    THB: "฿",
    commentReply: "Customer comment reply",
    messageReplyToCustomer: "Customize what you want to reply along with the content here",
    setLengthOfTime: "Set length of time (days) available after the purchase",
    hide: "Hide",
    seeMore: "See more",
    insertLink: "Please insert deep link / link / text here",
    days: "days",
    productSKUCode: "Product SKU Code",
    "This product SKU is free": "This product SKU is free",
    Active: "Active",
    Inactive: "Inactive",
    Disabled: "Disabled",
    "Product CF Code": "Product CF Code",
    "Please put CF Code here": "Please put CF Code here",
    notSupportProductCode:
      "Product code must be at least 3 character but not more than 20 characters, allow EN/TH alphabet or number, special character allow only ( _ ) and ( - ), and cannot contain any space between the code",
    notSupportProductCodeLength: "Code must have 3 or more characters and less than 20 characters",
    notSupportCFCode:
      "CF code must be at least 3 character, must start with EN/TH alphabet, allow alphabet, number, ( _ ) and ( - ) and cannot contain any space between the code",
  },
  th: {
    deliverable: "รายละเอียดการส่งมอบ",
    catalog: "เมนูสินค้า",
    price: "ราคา",
    SKUCode: "รหัส SKU",
    noExpiry: "ไม่มีวันหมดอายุ",
    validityPeriod: "ระยะเวลาที่ใช้ได้",
    expiryDate: "วันหมดอายุ",
    "date&time": "วันและเวลาที่ใช้ได้",
    THB: "฿",
    commentReply: "ข้อความตอบกลับลูกค้า",
    messageReplyToCustomer: "ใส่ข้อความที่ต้องการตอบกลับลูกค้า",
    setLengthOfTime: "กำหนดระยะเวลาที่ใช้ได้หลังซื้อไปแล้ว ของตัวเลือกสินค้า",
    hide: "ซ่อน",
    seeMore: "เพิ่มเติม",
    insertLink: "กรุณาใส่เนื้อหาตอบกลับ เช่น Deep link / ลิงก์ / ข้อความ",
    days: "วัน",
    productSKUCode: "รหัสสินค้า",
    "This product SKU is free": "ตั้งให้สินค้าชิ้นนี้ฟรี",
    Active: "เปิดใช้งาน",
    Inactive: "ปิดใช้งาน",
    Disabled: "ปิดการใช้งาน",
    "Product CF Code": "CF Code สินค้า",
    "Please put CF Code here": "กรุณาใส่ CF Code ที่นี่",
    notSupportProductCode:
      "โค้ดสินค้าต้องมีอย่างน้อย 3 ตัวอักษร แต่ไม่เกิน 20 ตัวอักษร สามารถใช้ตัวอักษร ภาษาไทย, อังกฤษ, หรือตัวเลข อักขระพิเศษอนุญาตเฉพาะ ( _ ) และ ( - ) และต้องไม่มีช่องว่างระหว่างโค้ด",
    notSupportProductCodeLength: "โค้ดสินค้าต้องมีอย่างน้อย 3 ตัวอักษร แต่ไม่เกิน 20 ตัวอักษร",
    notSupportCFCode:
      "CF code ต้องมีอย่างน้อย 3 ตัวอักษร ต้องขึ้นต้นด้วยตัวอักษรภาษาไทยหรืออังกฤษ สามารถใช้ ตัวอักษร, ตัวเลข, ( _ ) และ ( - ) และต้องไม่มีช่องว่างระหว่างโค้ด",
  },
};

export default consoleGeneratedForm;
