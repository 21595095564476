import React, { FC } from "react";
import COLORS from "constants/Colors";
import Grid from "components/Grid";
import Typography from "components/Typography";
import { MESSAGE_TYPES } from "constants/Chat";

import { FlexImage, FlexMessageContent } from "./FlexMessage.style";

export type ContentDataType = { type: string; bold?: boolean; value?: string; color?: string };

type FlexMessagePropsType = {
  content: ContentDataType[];
};

const FlexMessage: FC<FlexMessagePropsType> = (props) => {
  const { content } = props;

  const flexMessageContent = content
    .map(({ value, bold, type, color }, index) => {
      const hideOutOfStock = `${color === COLORS.OldBubbleChatBackground && "d-none"}`;
      if (type === MESSAGE_TYPES.TEXT) {
        const typographyVariant = bold ? "title7" : "body3";
        const replaceColor = COLORS.Dark;
        return (
          <Typography
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={`${hideOutOfStock} pl-2 pb-2 ${bold && "py-2"}`}
            variant={typographyVariant}
            color={replaceColor}
          >
            {value}
          </Typography>
        );
      }
      if (type === MESSAGE_TYPES.SPACING) {
        // eslint-disable-next-line react/no-array-index-key
        return <div key={index} className="mt-2" />;
      }
      if (type === MESSAGE_TYPES.IMAGE) {
        return (
          <a href={value} key={value} target="_blank" rel="noopener noreferrer" className="d-flex">
            <FlexImage alt="flex-image" src={value} />
          </a>
        );
      }

      return null;
    })
    .filter((contentData): contentData is JSX.Element => !!contentData);

  return (
    <FlexMessageContent>
      <Grid item xs={12}>
        {flexMessageContent}
      </Grid>
    </FlexMessageContent>
  );
};

export default FlexMessage;
