import React from "react";
import { CellProps } from "react-table";
import styled from "styled-components";
import { convertPriceFormat } from "utils/common";
import { DEFAULT_VALUE } from "config/shippop";

export const Thumbnail = styled.img`
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
`;

export const ThumbnailWrapper = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
  margin: 3px 0%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const getSubColumns = <T extends object>(t: Function) => [
  {
    Header: "",
    accessor: "empty1",
    width: 56,
  },
  {
    Header: "#",
    accessor: "image",
    width: 47,
    // eslint-disable-next-line react/display-name
    Cell: (cell: CellProps<T>) => {
      return cell.value ? (
        <ThumbnailWrapper>
          <Thumbnail alt="product" src={cell.value} />
        </ThumbnailWrapper>
      ) : (
        ""
      );
    },
  },
  {
    Header: t("SKU CODE"),
    accessor: "productCode",
    width: 94,
  },
  {
    Header: t("PRODUCT NAME"),
    accessor: "productName",
    width: 247,
  },
  { Header: t("Variation/Option"), accessor: "sku" },
  {
    Header: t("PRICE"),
    accessor: "productPrice",
    Cell: (cell: CellProps<T>) => {
      if (typeof cell.value === "string") {
        return t(cell.value);
      }

      if (typeof cell.value === "number") {
        return `${t("THB")} ${typeof cell.value === "number" ? convertPriceFormat(cell.value) : cell.value}`;
      }
      return cell.value;
    },
  },
  { Header: t("AMOUNT"), accessor: "amount" },
  {
    Header: `${t("Weight")}(${t("gram")})`,
    accessor: "weight",
    Cell: (cell: CellProps<T>) => {
      const weight = cell.value;
      if (weight == null) {
        return DEFAULT_VALUE;
      }

      return `${typeof weight === "number" ? convertPriceFormat(weight) : weight}`;
    },
  },
  { Header: `${t("DIMENSION")} ${t("WxLxH")}`, accessor: "dimension" },
  {
    Header: "",
    accessor: "empty3",
    width: 60,
  },
];
