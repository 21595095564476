const addressForm = {
  en: {
    "form.error.isRequired": "{{name}} is required",
    "form.error.length": "{{name}} should be {{length}} characters",
    "form.error.min": "{{name}} should be more than {{length}} characters",
    "form.error.min.unit": "{{name}} should be more than {{length}} {{unit}}",
    "form.error.max": "{{name}} should be less than {{length}} characters",
    "form.error.number": "{{name}} should be number",
    "form.error.postcode": "Postcode is incorrect",
  },
  th: {
    "form.error.isRequired": "กรุณากรอก{{name}}",
    "form.error.length": "{{name}} ควรมี {{length}}ตัวอักษร",
    "form.error.min": "{{name}} ควรมากกว่า {{length}}ตัวอักษร",
    "form.error.min.unit": "{{name}} ควรมากกว่า {{length}} {{unit}}",
    "form.error.max": "{{name}} ควรน้อยกว่า {{length}}ตัวอักษร",
    "form.error.number": "{{name}}ควรเป็นตัวเลข",
    "form.error.postcode": "รหัสไปรษณีย์ไม่ถูกต้อง",
  },
};

export default addressForm;
