import { yupResolver } from "@hookform/resolvers/yup";
import React, { FC, useState, useCallback, memo, useEffect, useMemo } from "react";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider, Controller } from "react-hook-form";

import Button, { ButtonWithIcon } from "components/Button";
import CircularProgress from "components/CircularProgress";
import { latLongFormat } from "components/NumberFormatCustom";
import ErrorText from "components/ErrorText";
import ExpansionPanel from "components/ExpansionPanel";
import ExpansionPanelDetails from "components/ExpansionPanelDetails";
import ExpansionPanelSummary from "components/ExpansionPanelSummary";
import Grid from "components/Grid";
import Typography from "components/Typography";
import TextField from "components/TextField";
import Card from "components/Card";
import { ControllerCheckbox } from "components/Checkbox";
import { SvgIcon, CircleIcon } from "components/Icon";
import ExpandMore from "components/Icon/ExpandMore";
import { IcArrowDown, IcDelete } from "components/SvgIcons";
import ControllerSwitch from "components/Switch/ControllerSwitch";

import { COLORS } from "constants/Colors";
import { BRANCH_NAME_INPUT_ID } from "config/store";
import { DEFAULT_WORKING_HOURS, OPENING_HOURS } from "constants/Branch";
import { UpsertBranch, HandleClickUpdateBranch } from "types/Branch";

import WorkingHours from "../WorkingHours";
import branchSchema from "../validationSchema";
import { Wrapper, CollapseButton, Address } from "./styled";

type OfflineStoreFormPropsType = {
  data: UpsertBranch;
  id?: string;
  isExpanded?: boolean;
  isExpandOpeningHours?: boolean;
  isLoadingUpdate?: boolean;
  isNew?: boolean;
  onClickDelete: (branchId?: string) => void;
  onClickUpdate: HandleClickUpdateBranch;
  projectId: string;
};

const OfflineStoreForm: FC<OfflineStoreFormPropsType> = ({
  data,
  id,
  isExpanded = false,
  isExpandOpeningHours: isExpandOpeningHoursFromProps = true,
  isLoadingUpdate = false,
  isNew = false,
  onClickDelete,
  onClickUpdate,
  projectId,
}) => {
  const { t } = useTranslation();
  const [isExpandedOpeningHours, setIsExpandedOpeningHours] = useState<boolean>(isExpandOpeningHoursFromProps);
  const [isExpandedBranch, setIsExpandedBranch] = useState<boolean>(isExpanded);
  const method = useForm<UpsertBranch>({
    resolver: yupResolver(branchSchema),
    reValidateMode: "onChange",
  });

  const { handleSubmit, formState, errors, register, control, watch, setValue, setError, clearErrors } = method;

  const handleSubmitForm = handleSubmit((data) => {
    onClickUpdate(data, {
      onSuccess: () => {
        setIsExpandedBranch(false); // close after update success
      },
      onError: () => {
        setError("name", {
          type: "manual",
          message: "offlineStore.duplicated.branchName",
        });
      },
    });
  });

  // region custom register and set value
  useEffect(() => {
    if (data.id) {
      register(`id`);
    }

    register(`projectId`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.id) {
      setValue(`id`, data.id);
    }

    setValue(`projectId`, projectId);
  }, [data.id, projectId, setValue]);
  // end region custom register and set value

  // region watch changed value to re-render
  const isActiveBranch: boolean = watch(`isActive`, data.isActive);
  // end region watch changed value to re-render

  const handleToggleExpanded = useCallback(() => {
    setIsExpandedOpeningHours(!isExpandedOpeningHours);
  }, [isExpandedOpeningHours]);

  const handleCollapseForm = useCallback(() => {
    setIsExpandedBranch(false);
  }, []);

  const handleExpandForm = useCallback(() => {
    setIsExpandedBranch(true);
  }, []);

  const handleClickDeleteBranch = useCallback(() => {
    onClickDelete(data.id);
  }, [data.id, onClickDelete]);

  const handleChangeName = useCallback(() => {
    if (errors.name) {
      clearErrors("name");
    }
  }, [clearErrors, errors.name]);

  const nameError = get(errors, `name.message`);
  const addressError = get(errors, `address.message`);
  const districtError = get(errors, `district.message`);
  const subDistrictError = get(errors, `subDistrict.message`);
  const phoneNumberError = get(errors, `phoneNumber.message`);
  const locationError = get(errors, `location.message`);
  const provinceError = get(errors, `province.message`);
  const postalCodeError = get(errors, `postalCode.message`);
  const latitudeError = get(errors, `latitude.message`);
  const longitudeError = get(errors, `longitude.message`);

  const renderLabelLalamoveCheckbox = useCallback(
    () => (
      <Typography variant="body3" color={COLORS.DarkMed}>
        {t("offlineStore.availableForLalamove.label")}
      </Typography>
    ),
    [t],
  );

  const renderLabelPickupCheckbox = useCallback(
    () => (
      <Typography variant="body3" color={COLORS.DarkMed}>
        {t("offlineStore.availableForPickUp.label")}
      </Typography>
    ),
    [t],
  );

  const renderTextFieldNumber = useCallback(
    ({ onChange, ref, ...rest }, { invalid }) => (
      <TextField
        className="my-2"
        inputProps={{ readOnly: !isActiveBranch }}
        error={Boolean(invalid)}
        variant="outlined"
        inputRef={ref}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        InputProps={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          inputComponent: latLongFormat as any,
          className: !isActiveBranch ? "Mui-disabled" : "",
        }}
        fullWidth
        {...rest}
      />
    ),
    [isActiveBranch],
  );

  const district = useMemo(() => [data.subDistrict, data.district].filter(Boolean).join(", "), [
    data.district,
    data.subDistrict,
  ]);
  const province = useMemo(() => [data.province, data.postalCode].filter(Boolean).join(", "), [
    data.postalCode,
    data.province,
  ]);

  const renderAddress = [data.address, district, province].map((address) => (
    <>
      {address}
      <br />
    </>
  ));

  return (
    <Wrapper id={id}>
      {isExpandedBranch && (
        <form onSubmit={handleSubmitForm}>
          <FormProvider {...method}>
            <Grid container>
              <Grid item container alignItems="center" xs={6}>
                <Grid item className="pr-2">
                  <ControllerSwitch control={control} name="isActive" defaultValue={data.isActive} />
                </Grid>
                <Grid item>
                  <Typography variant="body3" color={COLORS.DarkMed}>
                    {isActiveBranch ? t("Active") : t("Disabled")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={6} className="d-flex justify-content-end">
                {isExpandedBranch && (
                  <ButtonWithIcon type="button" onClick={handleClickDeleteBranch}>
                    <SvgIcon className="ml-1 mt-1" component={IcDelete} fontSize="small" />
                  </ButtonWithIcon>
                )}
                {!isNew && (
                  <CollapseButton
                    type="button"
                    className="rounded-circle ml-3"
                    isShow={isExpandedBranch}
                    onClick={handleCollapseForm}
                  >
                    <SvgIcon component={IcArrowDown} fontSize="small" />
                  </CollapseButton>
                )}
              </Grid>
            </Grid>

            <Card className="mt-3 p-3" noShadow>
              <Grid container>
                <Grid item xs={12} className="px-1">
                  <Typography variant="body3" color="darkMed" className="d-inline-block">
                    {t("Name")}
                  </Typography>

                  <TextField
                    id={BRANCH_NAME_INPUT_ID} // For auto-focus
                    className="my-2"
                    defaultValue={data.name}
                    error={Boolean(nameError)}
                    fullWidth
                    name="name"
                    validate={register}
                    variant="outlined"
                    onChange={handleChangeName}
                  />
                  {nameError && <ErrorText>{t(nameError)}</ErrorText>}
                </Grid>

                <Grid item xs={12} id="address" className="px-1">
                  <Typography variant="body3" color="darkMed" className="d-inline-block">
                    {t("Address or detail")}
                  </Typography>

                  <TextField
                    className="my-2"
                    defaultValue={data.address}
                    error={Boolean(addressError)}
                    fullWidth
                    name="address"
                    validate={register}
                    variant="outlined"
                    InputProps={!isActiveBranch ? { className: "Mui-disabled" } : {}}
                    inputProps={{ readOnly: !isActiveBranch }}
                  />
                  {addressError && <ErrorText>{t(addressError)}</ErrorText>}
                </Grid>

                <Grid item xs={12} lg={6} className="px-1">
                  <Typography variant="body3" color="darkMed" className="d-inline-block">
                    {t("subDistrict")}
                  </Typography>

                  <TextField
                    className="my-2"
                    defaultValue={data.subDistrict}
                    InputProps={!isActiveBranch ? { className: "Mui-disabled" } : {}}
                    inputProps={{ readOnly: !isActiveBranch }}
                    error={Boolean(subDistrictError)}
                    fullWidth
                    name="subDistrict"
                    validate={register}
                    variant="outlined"
                  />
                  {subDistrictError && <ErrorText>{t(subDistrictError)}</ErrorText>}
                </Grid>

                <Grid item xs={12} lg={6} className="px-1">
                  <Typography variant="body3" color="darkMed" className="d-inline-block">
                    {t("district")}
                  </Typography>

                  <TextField
                    className="my-2"
                    defaultValue={data.district}
                    InputProps={!isActiveBranch ? { className: "Mui-disabled" } : {}}
                    inputProps={{ readOnly: !isActiveBranch }}
                    error={Boolean(districtError)}
                    fullWidth
                    name="district"
                    validate={register}
                    variant="outlined"
                  />
                  {districtError && <ErrorText>{t(districtError)}</ErrorText>}
                </Grid>

                <Grid item xs={12} lg={6} className="px-1">
                  <Typography variant="body3" color="darkMed" className="d-inline-block">
                    {t("province")}
                  </Typography>

                  <TextField
                    className="my-2"
                    defaultValue={data.province}
                    InputProps={!isActiveBranch ? { className: "Mui-disabled" } : {}}
                    inputProps={{ readOnly: !isActiveBranch }}
                    error={Boolean(provinceError)}
                    fullWidth
                    name="province"
                    validate={register}
                    variant="outlined"
                  />
                  {provinceError && <ErrorText>{t(provinceError)}</ErrorText>}
                </Grid>

                <Grid item xs={12} lg={6} className="px-1">
                  <Typography variant="body3" color="darkMed" className="d-inline-block">
                    {t("postalCode")}
                  </Typography>

                  <TextField
                    className="my-2"
                    defaultValue={data.postalCode}
                    InputProps={!isActiveBranch ? { className: "Mui-disabled" } : {}}
                    inputProps={{ readOnly: !isActiveBranch }}
                    error={Boolean(postalCodeError)}
                    fullWidth
                    name="postalCode"
                    validate={register}
                    variant="outlined"
                  />
                  {postalCodeError && <ErrorText>{t(postalCodeError)}</ErrorText>}
                </Grid>

                <Grid item xs={12} lg={6} className="px-1">
                  <Typography variant="body3" color="darkMed" className="d-inline-block">
                    {t("Latitude")}
                  </Typography>

                  <Controller
                    render={renderTextFieldNumber}
                    name="latitude"
                    control={control}
                    defaultValue={data.latitude}
                  />

                  {latitudeError && <ErrorText>{t(latitudeError)}</ErrorText>}
                </Grid>

                <Grid item xs={12} lg={6} className="px-1">
                  <Typography variant="body3" color="darkMed" className="d-inline-block">
                    {t("Longitude")}
                  </Typography>

                  <Controller
                    render={renderTextFieldNumber}
                    name="longitude"
                    control={control}
                    defaultValue={data.longitude}
                  />

                  {longitudeError && <ErrorText>{t(longitudeError)}</ErrorText>}
                </Grid>

                <Grid item xs={12} className="px-1">
                  <Typography variant="body3" color="darkMed" className="d-inline-block">
                    {t("Phone number")}
                  </Typography>

                  <TextField
                    className="my-2"
                    defaultValue={data.phoneNumber}
                    error={Boolean(phoneNumberError)}
                    fullWidth
                    name="phoneNumber"
                    validate={register}
                    variant="outlined"
                    InputProps={!isActiveBranch ? { className: "Mui-disabled" } : {}}
                    inputProps={{ readOnly: !isActiveBranch }}
                  />
                  {phoneNumberError && <ErrorText>{t(phoneNumberError)}</ErrorText>}
                </Grid>

                <Grid item xs={12} className="px-1">
                  <Typography variant="body3" color="darkMed" className="d-inline-block">
                    {t("Location (Google maps link)")}
                  </Typography>

                  <TextField
                    className="my-2"
                    defaultValue={data.location}
                    error={Boolean(locationError)}
                    fullWidth
                    name="location"
                    validate={register}
                    variant="outlined"
                    InputProps={!isActiveBranch ? { className: "Mui-disabled" } : {}}
                    inputProps={{ readOnly: !isActiveBranch }}
                  />
                  <ErrorText>{locationError && t(locationError)}</ErrorText>
                </Grid>

                <Grid item container alignItems="center">
                  <Grid item className="mt-1 px-1">
                    <ControllerCheckbox
                      control={control}
                      name="isAvailableForPickUp"
                      defaultValue={data.isAvailableForPickUp}
                      label={renderLabelPickupCheckbox()}
                    />
                  </Grid>
                  <Grid item className="mt-1 mx-1">
                    <ControllerCheckbox
                      control={control}
                      name="isAvailableForLalamove"
                      defaultValue={data.isAvailableForLalamove}
                      label={renderLabelLalamoveCheckbox()}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container className="mt-2">
                <Grid item xs={12}>
                  <ExpansionPanel expanded={isExpandedOpeningHours}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      onClick={handleToggleExpanded}
                    >
                      <Typography variant="body3" color="darkMed">
                        {t("Opening hours")}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container className="px-1">
                        {OPENING_HOURS.map(({ day, isActive }, index) => {
                          const openingHoursError = get(errors, `openingHours[${index}].message`);
                          const defaultIsActiveOpeningHours = get(data, `openingHours[${index}].isActive`, isActive);
                          return (
                            <Grid container item xs={12} key={day}>
                              <Grid container item xs={2} sm={2} alignItems="center">
                                <Grid item className="py-1">
                                  <ControllerSwitch
                                    control={control}
                                    name={`openingHours[${index}].isActive`}
                                    defaultValue={defaultIsActiveOpeningHours}
                                    isReadOnly={!isActiveBranch}
                                  />
                                </Grid>
                              </Grid>

                              <Grid container item xs={4} sm={3} alignItems="center">
                                <Grid item className="py-1 pl-1">
                                  <Controller
                                    render={() => <Typography variant="body3">{t(capitalize(day))}</Typography>}
                                    name={`openingHours[${index}].day`}
                                    control={control}
                                    defaultValue={get(data, `openingHours[${index}].day`, "") as string}
                                  />
                                </Grid>
                              </Grid>

                              <Grid container item xs={6} sm={7} className="py-1">
                                <WorkingHours
                                  defaultIsActiveOpeningHours={defaultIsActiveOpeningHours}
                                  branchIndex={index}
                                  disabled={!isActiveBranch}
                                  openingTime={get(data, `openingHours[${index}].open`) || DEFAULT_WORKING_HOURS.OPEN}
                                  closingTime={get(data, `openingHours[${index}].close`) || DEFAULT_WORKING_HOURS.CLOSE}
                                  openingName={`openingHours[${index}].open`}
                                  closingName={`openingHours[${index}].close`}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="body3" color="error" className="text-center mt-2">
                                  {openingHoursError && t(openingHoursError)}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              </Grid>
            </Card>
            <div className="mt-3">
              <Button fullWidth type="submit" color="primary" disabled={!formState.isDirty || isLoadingUpdate}>
                {t("Save")} {isLoadingUpdate && <CircularProgress size={18} className="ml-2" />}
              </Button>
            </div>
          </FormProvider>
        </form>
      )}

      {!isExpandedBranch && (
        <Grid container justify="space-between" alignItems="center" className="flex-nowrap">
          <Grid item className="flex-grown-1">
            <Typography variant="title7" color="dark" className="ml-2 mb-2">
              <CircleIcon color={isActiveBranch ? COLORS.Success : COLORS.DarkMed} size="m" />
              {isNew ? t("offlineStore.newBranch") : data.name}
            </Typography>
            {!isNew && data.address && (
              <Address variant="body3" color="darkGray">
                {t("Address")}: {renderAddress}
                {!isNew && data.phoneNumber && `${t("Phone number")}: ${data.phoneNumber}`}
              </Address>
            )}
          </Grid>
          <Grid item className="flex-grown-0">
            <CollapseButton className="rounded-circle" isShow={isExpandedBranch} onClick={handleExpandForm}>
              <SvgIcon component={IcArrowDown} fontSize="small" />
            </CollapseButton>
          </Grid>
        </Grid>
      )}
    </Wrapper>
  );
};

export default memo(OfflineStoreForm);
