import { LineMenuTemplate, ActionType, DeepleAction } from "types/RichMenu";

export const MAP_TEMPLATE_SIZE: Record<LineMenuTemplate, number> = {
  [LineMenuTemplate.LARGE_1]: 6,
  [LineMenuTemplate.LARGE_2]: 4,
  [LineMenuTemplate.LARGE_3]: 4,
  [LineMenuTemplate.LARGE_4]: 3,
  [LineMenuTemplate.LARGE_5]: 2,
  [LineMenuTemplate.LARGE_6]: 2,
  [LineMenuTemplate.LARGE_7]: 1,
  [LineMenuTemplate.DEFAULT_0]: 5,
  [LineMenuTemplate.DEFAULT_WITH_IMAGE]: 5,
};

export const ACTION_SECTIONS = ["A", "B", "C", "D", "E", "F"];

export const TEMPLATE_OPTIONS = [
  LineMenuTemplate.LARGE_1,
  LineMenuTemplate.DEFAULT_0,
  LineMenuTemplate.LARGE_2,
  LineMenuTemplate.LARGE_3,
  LineMenuTemplate.LARGE_4,
  LineMenuTemplate.LARGE_5,
  LineMenuTemplate.LARGE_6,
  LineMenuTemplate.LARGE_7,
];

export const DEFAULT_ACTION = [{ actionType: ActionType.TEXT, value: { text: "" } }];
export const DEFAULT_FACEBOOK_ACTION = [
  { actionType: ActionType.DEEPLE_ACTION, value: DeepleAction.DEEPLE_PRODUCT_CATALOG },
];

export const DEFAULT_LINE_RICH_MENU = {
  menuUrl: "https://storage.googleapis.com/kanda-ai-core/static/deeple-rich-menu.jpg",
  template: LineMenuTemplate.DEFAULT_0,
  action: [
    { actionType: ActionType.DEEPLE_ACTION, value: DeepleAction.DEEPLE_TOP_SELLING },
    { actionType: ActionType.DEEPLE_ACTION, value: DeepleAction.DEEPLE_PRODUCT_CATALOG },
    { actionType: ActionType.DEEPLE_ACTION, value: DeepleAction.DEEPLE_SHOPPING_CART },
    { actionType: ActionType.DEEPLE_ACTION, value: DeepleAction.DEEPLE_ORDER_HISTORY },
    { actionType: ActionType.DEEPLE_ACTION, value: DeepleAction.DEEPLE_CONTACT_ADMIN },
  ],
};

export const DEFAULT_INSTAGRAM_ACTION = [
  { actionType: ActionType.DEEPLE_ACTION, value: DeepleAction.DEEPLE_PRODUCT_CATALOG },
];
