import { SubMenuAction, MenuActionType } from "types/RichMenu";

const MAP_LABEL = {
  [MenuActionType.DEEPLE_PRODUCT_CATALOG]: "PRODUCT_CATALOG",
  [MenuActionType.DEEPLE_TOP_SELLING]: "TOP_SELLING_PRODUCTS",
  [MenuActionType.DEEPLE_SHOPPING_CART]: "SHOPPING_CART",
  [MenuActionType.DEEPLE_ORDER_HISTORY]: "ORDER_HISTORY",
  [MenuActionType.DEEPLE_CONTACT_ADMIN]: "CONTACT_ADMIN",
};

const renderReview = (action: SubMenuAction) => {
  switch (action.type) {
    case MenuActionType.TEXT:
      return action.label;
    case MenuActionType.URL:
      return action.label;

    default:
      return MAP_LABEL[action.type];
  }
};

export default renderReview;
