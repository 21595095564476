import React, { FC, ChangeEvent, useMemo } from "react";
import { OptionType, ValueType } from "react-select";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Grid from "components/Grid";
import { SvgIcon } from "components/Icon";
import IcEdit from "components/SvgIcons/IcEdit";
import IcClose from "components/SvgIcons/IcClose";
import Button, { ButtonWithIcon } from "components/Button";
import Card from "components/Card";
import ErrorText from "components/ErrorText";
import Switch from "components/Switch";
import TagInputWithLabel from "components/TagInputWithAutoSuggest";
import TextField from "components/TextField";
import Typography from "components/Typography";

import { CATEGORY_PROPERTIES_MAX_LENGTH } from "config/category";
import { CATEGORY_CARD_FIELD } from "constants/Category";
import { COLORS } from "constants/Colors";
import { ChangedDataValueType, CategoryType, PropertyType, VariationType } from "types/Category";
import useToggle from "utils/hooks/useToggle";

import CategoryImage from "../CategoryImage";
import validateSchema from "../validateSchema";
import { GridCategoryName } from "./styled";

type CategoryCardPropsType = {
  category: CategoryType;
  dataCy?: string;
  isEditMode?: boolean;
  isHiddenCloseButton?: boolean;
  isClientStore?: boolean;
  onChangeData: (fieldName: keyof CategoryType, value: ChangedDataValueType) => void;
  onClickCancel?: () => void;
  onClickDelete: () => void;
  onClickUpdate: (callback?: Function) => void;
  onToggleIsActive?: () => void;
  setIsRemoveAllCategoryImage?: () => void;
};

const convertDataToSelectOptionFormat = <T extends PropertyType | VariationType>(originalData: T[]) =>
  originalData.map((data: T) => ({
    label: data.name,
    value: data.name,
  }));

const CategoryCard: FC<CategoryCardPropsType> = (props) => {
  const {
    category,
    onClickDelete: onDeleteCard,
    onClickCancel = () => {},
    onChangeData,
    onClickUpdate,
    onToggleIsActive = () => {},
    isEditMode: defaultIsEditMode,
    isHiddenCloseButton = false,
    isClientStore = false,
    dataCy,
    setIsRemoveAllCategoryImage = () => {},
  } = props;
  const { id, image, name, properties, isActive, isHomePageCategory } = category;
  const { isOpen: isEditMode, handleToggle: handleToggleEditMode, handleClose: handleCloseEditMode } = useToggle(
    Boolean(defaultIsEditMode),
  );
  const { t } = useTranslation();

  const validationSchema = useMemo(() => validateSchema(t), [t]);
  const { register, errors, handleSubmit } = useForm({ resolver: yupResolver(validationSchema) });

  const handleChangeCategoryName = (event: ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    onChangeData(CATEGORY_CARD_FIELD.NAME, newName);
  };

  const handleChangeTagInput = (fieldName: keyof CategoryType, tagInputValues: ValueType<OptionType>) => {
    const computedValue = tagInputValues
      ? (tagInputValues as OptionType[]).map((tagInputValue) => {
          return { name: tagInputValue.value };
        })
      : null;

    onChangeData(fieldName, computedValue);
  };

  const handleCancel = () => {
    onClickCancel();
    handleCloseEditMode();
  };

  const handleSave = () => {
    onClickUpdate(handleCloseEditMode);
  };

  return (
    <Card data-cy={dataCy} id={id} className="p-3 w-100 border-box">
      <Grid container direction="column">
        <Grid item container direction="row" justify="space-between" alignItems="center" className="pb-2">
          <Grid item className="d-flex flex-grow-1 align-items-center">
            {!isHomePageCategory && (
              <Switch className="mr-2" checked={isActive} onChange={onToggleIsActive} data-cy="toggleCategory" />
            )}
            <Typography variant="body3" color={COLORS.DarkMed}>
              {isActive ? t("Active") : t("Disabled")}
            </Typography>
          </Grid>
          {!isClientStore && (
            <Grid item className="d-flex flex-grow-0">
              {isEditMode && !isHiddenCloseButton && (
                <ButtonWithIcon onClick={handleCancel} data-cy="closeButton">
                  <SvgIcon className="ml-1 mt-1" component={IcClose} fontSize="small" htmlColor={COLORS.DarkMed} />
                </ButtonWithIcon>
              )}
              {!isEditMode && (
                <ButtonWithIcon onClick={handleToggleEditMode} data-cy="editButton">
                  <SvgIcon className="ml-1 mt-1" component={IcEdit} fontSize="small" htmlColor={COLORS.DarkMed} />
                </ButtonWithIcon>
              )}
            </Grid>
          )}
        </Grid>

        <Grid container className="mt-2 flex-nowrap">
          <Grid item className="flex-grow-0" xs={12} sm="auto">
            <CategoryImage
              onChange={(imageUrl: string) => onChangeData(CATEGORY_CARD_FIELD.IMAGE, imageUrl)}
              image={image}
              canEditable={isEditMode}
              isHomePageCategory={isHomePageCategory}
              setIsRemoveAllCategoryImage={setIsRemoveAllCategoryImage}
            />
          </Grid>

          <GridCategoryName item className="flex-grow-1 mt-3">
            {isEditMode ? (
              <>
                <TextField
                  className="mb-1 w-100"
                  label={t(isHomePageCategory ? "category.displayName" : "Category Name")}
                  name="name"
                  validate={register}
                  defaultValue={name}
                  onChange={handleChangeCategoryName}
                  data-cy="categoryName"
                  multiline
                  InputProps={!isEditMode ? { className: "Mui-disabled" } : {}}
                  inputProps={{ readOnly: !isEditMode }}
                  error={Boolean(errors?.name)}
                />

                {errors?.name && <ErrorText className="d-block">{errors?.name?.message || ""}</ErrorText>}
              </>
            ) : (
              <Typography variant="title4">{name}</Typography>
            )}
            {isHomePageCategory && (
              <Typography variant="body4" color="darkMed" className="pt-1">
                {t("category.allCategory.description")}
              </Typography>
            )}
          </GridCategoryName>
        </Grid>

        {isEditMode && (
          <>
            {!isHomePageCategory && (
              <TagInputWithLabel
                label={t("Properties")}
                isOptional
                limit={CATEGORY_PROPERTIES_MAX_LENGTH}
                value={convertDataToSelectOptionFormat(properties)}
                onChange={(event: ValueType<OptionType>) => {
                  handleChangeTagInput(CATEGORY_CARD_FIELD.PROPERTIES, event || []);
                }}
                dataCy="properties"
              />
            )}

            <Grid container className="mt-2" spacing={2}>
              {!isHomePageCategory && (
                <Grid item xs={6}>
                  <Button fullWidth minHeight={32} color="secondary" onClick={onDeleteCard}>
                    {t("Remove")}
                  </Button>
                </Grid>
              )}
              <Grid item xs={isHomePageCategory ? 12 : 6}>
                <Button fullWidth minHeight={32} color="primary" onClick={handleSubmit(handleSave)}>
                  {t("Save")}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  );
};

export default CategoryCard;
