import React, { FC } from "react";
import styled from "styled-components";
import SelectProject from "domain/Project/SelectProject";
import COLORS from "constants/Colors";

export const BackgroundImg = styled.div`
  background-color: ${COLORS.DeepleBG};
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  background-image: url("/assets/img/new-bg-welcome-mobile.png");
  ${({ theme }) => theme.breakpoints.up("sm")} {
    background-image: url("/assets/img/new-bg-welcome.png");
  }
`;

export interface ProjectProps {
  isShowModalCongratulations: boolean;
  isShowModalPrivacyPolicy: boolean;
}

export const Project: FC<ProjectProps> = ({ isShowModalCongratulations, isShowModalPrivacyPolicy }) => {
  return (
    <BackgroundImg>
      <SelectProject
        isShowModalCongratulations={isShowModalCongratulations}
        isShowModalPrivacyPolicy={isShowModalPrivacyPolicy}
      />
    </BackgroundImg>
  );
};
