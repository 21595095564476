import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import { NOTIFICATION_UPDATE } from "constants/Notification";
import { CUSTOMER_ORDER_LIMIT } from "config/customer";
import { CUSTOMER } from "graphql/customer/query";
import { UPDATE_CUSTOMER_DISPLAY_NAME } from "graphql/customer/mutation";
import { CustomerQueryType } from "types/Customer";
import { notifyError, notifySuccess } from "utils/notify";

type UseUpdateDisplayNameProps = {
  customerId: string;
  projectId: string;
};

const useUpdateDisplayName = ({ projectId, customerId }: UseUpdateDisplayNameProps) => {
  const { t } = useTranslation();

  const [updateDisplayName] = useMutation(UPDATE_CUSTOMER_DISPLAY_NAME, {
    update(cache, { data: { updateCustomerDisplayName } }) {
      const cachedCustomer = cache.readQuery<CustomerQueryType>({
        query: CUSTOMER,
        variables: {
          projectId,
          customerId,
          pageSize: CUSTOMER_ORDER_LIMIT,
          page: 0,
        },
      });

      if (cachedCustomer && updateCustomerDisplayName) {
        cache.writeQuery({
          query: CUSTOMER,
          variables: {
            projectId,
            customerId,
            pageSize: CUSTOMER_ORDER_LIMIT,
            page: 0,
          },
          data: {
            customer: {
              ...cachedCustomer.customer,
              displayName: updateCustomerDisplayName.displayName,
            },
          },
        });
      }
    },
    onCompleted: () => {
      notifySuccess(t(NOTIFICATION_UPDATE.SUCCESS));
    },
    onError: () => notifyError(t(NOTIFICATION_UPDATE.FAIL)),
  });

  const onUpdateDisplayName = (newDisplayName: string) => {
    updateDisplayName({
      variables: {
        projectId,
        customerId,
        displayName: newDisplayName,
      },
    });
  };

  return {
    onUpdateDisplayName,
  };
};

export default useUpdateDisplayName;
