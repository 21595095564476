import { TFunction } from "i18next";
import moment from "moment";
import lowerCase from "lodash/lowerCase";
import camelCase from "lodash/camelCase";

import { BROADCAST_DATE_TIME_FORMAT } from "constants/DateTimeFormat";
import { BroadcastStatus } from "types/Broadcast";

import type { TableRecurringNotificationList } from "components/TableRecurringNotification";
import type { RecurringNotificationOptInsType } from "types/Broadcast";

export function mappingRecurringNotificationOptInsData(
  data: RecurringNotificationOptInsType[],
  t: TFunction,
  locale: string,
): TableRecurringNotificationList[] {
  return data.map(
    ({
      id,
      name,
      numberOfSubscribers,
      deliveryContextType,
      frequencyType,
      createdAt,
    }: RecurringNotificationOptInsType) => ({
      id,
      requestName: name,
      subscriber: new Intl.NumberFormat("en-US").format(numberOfSubscribers),
      requestDeliveryContext: t(`broadcast.facebook.table.requestDeliveryContext.${camelCase(deliveryContextType)}`),
      broadcastFrequency: t(`broadcast.facebook.table.broadcastFrequency.${lowerCase(frequencyType)}`),
      createdAt: moment(createdAt)
        .add(locale === "en" ? 0 : 543, "year")
        .format(BROADCAST_DATE_TIME_FORMAT),
    }),
  );
}

export function mappingRecurringNotificationOptInsSendingBroadcastIds(
  data: RecurringNotificationOptInsType[],
): string[] {
  return data.filter(({ broadcastStatus }) => broadcastStatus === BroadcastStatus.IN_PROGRESS).map(({ id }) => id);
}
