import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import TextField from "components/TextField";
import Button from "components/Button";
import ErrorText from "components/ErrorText";

import { CHANGE_PASSWORD } from "graphql/auth/mutation";
import { NOTIFICATION_UPDATE } from "constants/Notification";
import { notifySuccess, notifyError } from "utils/notify";
import schema from "./schema";
import Title from "./Title";

type UserDataType = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

type ChangePasswordPropsType = {
  email: string;
  callback?: () => void;
};

const ChangePassword: FC<ChangePasswordPropsType> = ({ email, callback = () => {} }) => {
  const { t } = useTranslation();
  const { register, errors, handleSubmit } = useForm<UserDataType>({
    resolver: yupResolver(schema),
  });
  const [changePassword] = useMutation(CHANGE_PASSWORD);
  const onSubmitHandler = handleSubmit(async (formData) => {
    const { oldPassword, newPassword } = formData;
    if (oldPassword && newPassword) {
      try {
        await changePassword({ variables: { email, oldPassword, newPassword } });
        notifySuccess(t(NOTIFICATION_UPDATE.SUCCESS));
        callback();
      } catch (error) {
        notifyError(t("Incorrect old password"));
      }
    }
  });

  return (
    <>
      <Title>{t("Change password")}</Title>
      <div className="p-3">
        <form noValidate autoComplete="off" onSubmit={onSubmitHandler}>
          <TextField
            label={t("Old password")}
            name="oldPassword"
            type="password"
            margin="normal"
            fullWidth
            validate={register}
            error={Boolean(errors.oldPassword)}
          />
          <ErrorText>{errors.oldPassword && t(errors.oldPassword.message as string)}</ErrorText>
          <TextField
            label={t("New password")}
            name="newPassword"
            type="password"
            margin="normal"
            fullWidth
            validate={register}
            error={Boolean(errors.newPassword)}
          />
          <ErrorText>{errors.newPassword && t(errors.newPassword.message as string)}</ErrorText>
          <TextField
            label={t("Confirm password")}
            name="confirmPassword"
            type="password"
            margin="normal"
            fullWidth
            validate={register}
            error={Boolean(errors.confirmPassword)}
          />
          <ErrorText>{errors.confirmPassword && t(errors.confirmPassword.message as string)}</ErrorText>
          <Button type="submit" fullWidth className="my-3" data-cy="changePasswordSubmit">
            {t("Submit")}
          </Button>
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
