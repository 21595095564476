import * as React from "react";

const SvgIcFacebookLive = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.8182 5C22.2039 5 22.5739 5.14487 22.8467 5.40273C23.1195 5.66059 23.2727 6.01033 23.2727 6.375V12.15L30.8553 7.13125C30.9643 7.059 31.0922 7.01643 31.2251 7.00819C31.358 6.99994 31.4907 7.02634 31.6087 7.08449C31.7268 7.14265 31.8258 7.23033 31.8948 7.33799C31.9637 7.44564 32.0002 7.56913 32 7.695V24.305C32.0002 24.4309 31.9637 24.5544 31.8948 24.662C31.8258 24.7697 31.7268 24.8574 31.6087 24.9155C31.4907 24.9737 31.358 25.0001 31.2251 24.9918C31.0922 24.9836 30.9643 24.941 30.8553 24.8688L23.2727 19.85V25.625C23.2727 25.9897 23.1195 26.3394 22.8467 26.5973C22.5739 26.8551 22.2039 27 21.8182 27H1.45455C1.06878 27 0.698807 26.8551 0.426026 26.5973C0.153246 26.3394 0 25.9897 0 25.625V6.375C0 6.01033 0.153246 5.66059 0.426026 5.40273C0.698807 5.14487 1.06878 5 1.45455 5H21.8182ZM9.30909 11.6399C9.17496 11.6398 9.04495 11.6836 8.94104 11.7638C8.83714 11.844 8.76573 11.9556 8.73891 12.0799L8.72727 12.1885V19.8088C8.72726 19.8978 8.75013 19.9856 8.79392 20.0644C8.83771 20.1433 8.9011 20.2109 8.97865 20.2615C9.0562 20.3121 9.14559 20.3441 9.23912 20.3548C9.33266 20.3655 9.42755 20.3546 9.51564 20.323L9.62182 20.2735L15.9564 16.462C16.0303 16.4173 16.0925 16.3574 16.1385 16.2867C16.1844 16.216 16.2129 16.1365 16.2217 16.0539C16.2305 15.9714 16.2195 15.8881 16.1894 15.8102C16.1593 15.7322 16.111 15.6618 16.048 15.604L15.9564 15.5353L9.62182 11.7238C9.52797 11.6688 9.4195 11.6388 9.30909 11.6399Z"
    />
  </svg>
);

export default SvgIcFacebookLive;
