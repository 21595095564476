import styled from "styled-components";

import Grid from "components/Grid";

export const CardHeader = styled(Grid)`
  background-color: ${({ theme }) => theme.COLORS.Primary};
  border-radius: 8px 8px 0 0;
`;

export const Snackbar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffeff2;
  border: 1px solid ${({ theme }) => theme.COLORS.Error};
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
`;

export const FixFlex = styled.div`
  flex: 0 0 auto;
`;

export const DynamicFlex = styled.div`
  flex: 1 1 auto;
`;
