import * as yup from "yup";
import { ProductOutputType } from "types/Product";
import {
  MAXIMUM_DIMENSION,
  REGEX_CF_CODE_SUPPORT_CHARACTER,
  REGEX_PRODUCT_CODE_SUPPORT_CHARACTER,
} from "constants/Product";
import { MAXIMUM_PRODUCT_NAME_STRING_LENGTH } from "config";

export const addNewProductSchema = yup.object().shape({
  productName: yup
    .string()
    .trim()
    .required("Please fill product name")
    .matches(/^(?!.*\+).*$/, "Product name can not contain plus sign")
    .max(MAXIMUM_PRODUCT_NAME_STRING_LENGTH, "Product name must be below 100 characters"),
  selectedCategoryId: yup.string().required("Please select category"),
  productCode: yup
    .string()
    .trim()
    .required("Please fill product code")
    .min(3, "notSupportProductCodeLength")
    .max(50, "notSupportProductCodeLength")
    .test("isSupportCharacter", "notSupportProductCode", (value) => {
      const isSupportCharacter = value && value.match(REGEX_PRODUCT_CODE_SUPPORT_CHARACTER);
      if (isSupportCharacter) {
        return true;
      }
      return false;
    }),
  cfCode: yup
    .string()
    .nullable()
    .trim()
    .test("length", "notSupportProductCodeLength", (value) => {
      if (value && value.length) {
        if (value.length > 2 && value.length < 21) {
          return true;
        }
        return false;
      }
      return true; // ignore if no value
    })
    .test("isSupportCharacter", "notSupportCFCode", (value) => {
      const isSupportCharacter = value && value.match(REGEX_CF_CODE_SUPPORT_CHARACTER);
      if (value && value.length) {
        if (value.length > 2 && value.length < 21 && isSupportCharacter) {
          return true;
        }
        return false;
      }
      return true; // ignore if no value
    }),
  productPrice: yup.number().required("Please fill product price"),
  properties: yup.array().of(
    yup.object({
      name: yup.string().required("Please specify property name"),
      value: yup.string().required("Please specify property value"),
    }),
  ),
  categories: yup.array().of(
    yup.object({
      id: yup.number().required("Please select category"),
    }),
  ),
  productSKUs: yup.array().of(
    yup.object({
      productCode: yup
        .string()
        .trim()
        .required("Please fill product code")
        .min(3, "notSupportProductCodeLength")
        .max(50, "notSupportProductCodeLength")
        .test("isSupportCharacter", "notSupportProductCode", (value) => {
          const isSupportCharacter = value && value.match(REGEX_PRODUCT_CODE_SUPPORT_CHARACTER);
          if (isSupportCharacter) {
            return true;
          }
          return false;
        }),
      cfCode: yup
        .string()
        .nullable()
        .trim()
        .test("length", "notSupportProductCodeLength", (value) => {
          if (value && value.length) {
            if (value.length > 2 && value.length < 21) {
              return true;
            }
            return false;
          }
          return true; // ignore if no value
        })
        .test("isSupportCharacter", "notSupportCFCode", (value) => {
          const isSupportCharacter = value && value.match(REGEX_CF_CODE_SUPPORT_CHARACTER);
          if (value && value.length) {
            if (value.length > 2 && value.length < 21 && isSupportCharacter) {
              return true;
            }
            return false;
          }
          return true; // ignore if no value
        }),
      dimension: yup
        .array()
        .of(
          yup
            .number()
            // eslint-disable-next-line func-names
            .test("dimension", "Please fill value", function (value) {
              const { parent: dimensions = [] } = this;
              const canNull = dimensions.every((dimension: number | null) => !dimension);
              if (canNull) {
                return true;
              }
              return !!value;
            })
            .max(MAXIMUM_DIMENSION, "productDimension.isMaximum")
            .nullable(),
        )
        .nullable(),
    }),
  ),
  productDimensionWidth: yup
    .number()
    .nullable()
    // eslint-disable-next-line func-names
    .test("required", "productDimensionWidth.required", function (value) {
      if (value == null && (this.parent.productDimensionLength != null || this.parent.productDimensionHeight != null)) {
        return false;
      }
      return true;
    })
    .max(MAXIMUM_DIMENSION, "productDimensionWidth.isMaximum"),
  productDimensionLength: yup
    .number()
    .nullable()
    // eslint-disable-next-line func-names
    .test("required", "productDimensionLength.required", function (value) {
      if (value == null && (this.parent.productDimensionWidth != null || this.parent.productDimensionHeight != null)) {
        return false;
      }
      return true;
    })
    .max(MAXIMUM_DIMENSION, "productDimensionLength.isMaximum"),
  productDimensionHeight: yup
    .number()
    .nullable()
    // eslint-disable-next-line func-names
    .test("required", "productDimensionHeight.required", function (value) {
      if (value == null && (this.parent.productDimensionLength != null || this.parent.productDimensionWidth != null)) {
        return false;
      }
      return true;
    })
    .max(MAXIMUM_DIMENSION, "productDimensionHeight.isMaximum"),
});

export const productSKUsSchema = yup.object().shape({
  variations: yup.array().of(
    yup.object({
      key: yup.string().required("Please specify variation name"),
      value: yup.string().required("Please specify property value"),
    }),
  ),
});

export const addNewDigitalProductSchema = yup.object().shape({
  productName: yup
    .string()
    .trim()
    .required("Please fill product name")
    .matches(/^(?!.*\+).*$/, "Product name can not contain plus sign")
    .max(100, "Product name must be below 100 characters"),
  contentType: yup.string().required("Please select content type"),
  selectedCategoryId: yup.string().required("Please select category"),
  additionalDescription: yup.string().required("Please fill how to use / conditions"),
  productCode: yup
    .string()
    .trim()
    .required("Please fill product code")
    .min(3, "notSupportProductCodeLength")
    .max(50, "notSupportProductCodeLength")
    .test("isSupportCharacter", "notSupportProductCode", (value) => {
      const isSupportCharacter = value && value.match(REGEX_PRODUCT_CODE_SUPPORT_CHARACTER);
      if (isSupportCharacter) {
        return true;
      }
      return false;
    }),
  cfCode: yup
    .string()
    .nullable()
    .trim()
    .test("length", "notSupportProductCodeLength", (value) => {
      if (value && value.length) {
        if (value.length > 2 && value.length < 21) {
          return true;
        }
        return false;
      }
      return true; // ignore if no value
    })
    .test("isSupportCharacter", "notSupportCFCode", (value) => {
      const isSupportCharacter = value && value.match(REGEX_CF_CODE_SUPPORT_CHARACTER);
      if (value && value.length) {
        if (value.length > 2 && value.length < 21 && isSupportCharacter) {
          return true;
        }
        return false;
      }
      return true; // ignore if no value
    }),
  productPrice: yup.number().required("Please fill product price"),
  properties: yup.array().of(
    yup.object({
      name: yup.string().required("Please specify property name"),
      value: yup.string().required("Please specify property value"),
    }),
  ),
  categories: yup.array().of(
    yup.object({
      id: yup.number().required("Please select category"),
    }),
  ),
  outputType: yup.string(),
  productSKUs: yup.array().of(
    yup.object({
      productCode: yup
        .string()
        .trim()
        .required("Please fill product code")
        .min(3, "notSupportProductCodeLength")
        .max(50, "notSupportProductCodeLength")
        .test("isSupportCharacter", "notSupportProductCode", (value) => {
          const isSupportCharacter = value && value.match(REGEX_PRODUCT_CODE_SUPPORT_CHARACTER);
          if (isSupportCharacter) {
            return true;
          }
          return false;
        }),
      cfCode: yup
        .string()
        .nullable()
        .trim()
        .test("length", "notSupportProductCodeLength", (value) => {
          if (value && value.length) {
            if (value.length > 2 && value.length < 21) {
              return true;
            }
            return false;
          }
          return true; // ignore if no value
        })
        .test("isSupportCharacter", "notSupportCFCode", (value) => {
          const isSupportCharacter = value && value.match(REGEX_CF_CODE_SUPPORT_CHARACTER);
          if (value && value.length) {
            if (value.length > 2 && value.length < 21 && isSupportCharacter) {
              return true;
            }
            return false;
          }
          return true; // ignore if no value
        }),
      digitalContentOptions: yup.object({
        outputType: yup.string(),
        settings: yup
          .object({
            availability: yup
              .object({
                type: yup.string(),
                settings: yup
                  .object({
                    unit: yup.string().nullable(), // "days" by default
                    value: yup.number().nullable(), // alway cast Number()
                    expiredAt: yup.string().nullable(), // alway set default date
                    availableAt: yup.string().nullable(), // .required("Please select start date"),
                  })
                  .nullable(),
              })
              .nullable(),
            content: yup
              .string()
              .nullable()
              // eslint-disable-next-line func-names
              .test("required", "insertLink", function (value) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if ((this.options as any).context.outputType === ProductOutputType.STATIC) {
                  if (value === "" || value === undefined) {
                    return false;
                  }
                  return true;
                }
                // IF QR_CODE, CODE_IMPORTED ,CODE_AUTO_GENERATED, CODE_GENERATED_FROM_OTHER,
                return true;
              }),
          })
          .nullable(),
      }),
    }),
  ),
});
