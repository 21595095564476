import * as React from "react";

const SvgIcUser = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.076 29.064H5.334c0-7.746 6.802-7.744 8.311-9.769l.173-.924c-2.12-1.075-3.618-3.666-3.618-6.697 0-3.992 2.597-7.23 5.8-7.23s5.8 3.238 5.8 7.23c0 3.005-1.47 5.58-3.562 6.672l.196 1.05c1.656 1.927 8.233 2.053 8.233 9.668H16.076z"
    />
  </svg>
);

export default SvgIcUser;
