import { DuplicatedQuestionType, FaqType, FaqQuestionType, ExcludedQuestionType } from "types/AISetting";
import escapeRegExp from "lodash/escapeRegExp";

export const searchFaq = (faqs: FaqType[], wordSearch?: string) => {
  const result = faqs.filter(({ questions, enQuestions }: FaqType) => {
    if (!wordSearch) {
      return true;
    }

    const safeWordSearch = escapeRegExp(wordSearch);

    const isQuestionMatchWithSearchWord = questions.some((questionText: FaqQuestionType) => {
      return new RegExp(safeWordSearch, "i").test(questionText);
    });

    const isEnQuestionMatchWithSearchWord = enQuestions.some((questionText: FaqQuestionType) => {
      return new RegExp(safeWordSearch, "i").test(questionText);
    });

    return isQuestionMatchWithSearchWord || isEnQuestionMatchWithSearchWord;
  });

  return result;
};

export const getDuplicatedQuestionFromIndex = (
  faqIds: string[],
  questions: string[],
  questionsLengths: number[],
  duplicatedQuestionIndex: number,
): DuplicatedQuestionType | null => {
  if (!Array.isArray(duplicatedQuestionIndex) && duplicatedQuestionIndex >= 0) {
    const duplicatedPosition = duplicatedQuestionIndex + 1;
    let currentPosition = 0;

    for (let index = 0; index < questionsLengths.length; index += 1) {
      const length = questionsLengths[index];
      currentPosition += length;

      if (currentPosition >= duplicatedPosition) {
        const order = duplicatedPosition - (currentPosition - length);

        return {
          faqId: faqIds[index],
          firstQuestion: questions[currentPosition - length],
          index: order - 1,
        };
      }
    }
  }

  return null;
};

export const getMappedQuestions = (
  faqsInput: Partial<FaqType>[],
  questionsLengths: number[],
  faqIds: string[],
  questionFieldName: keyof FaqType,
  excludedQuestion?: ExcludedQuestionType,
) => {
  const questions = faqsInput.reduce<string[]>((total, faq) => {
    const focusedQuestions = faq[questionFieldName] as string[];
    if (!focusedQuestions) {
      return total;
    }

    questionsLengths.push(focusedQuestions.length);
    faqIds.push(faq.id as string);

    // set default
    let clearExcludedQuestion = focusedQuestions;

    if (excludedQuestion && excludedQuestion.faqId && excludedQuestion.faqId === faq.id) {
      // map and clear excluded question to empty string
      clearExcludedQuestion = focusedQuestions.map((question, index) => {
        if (excludedQuestion.index == null || (excludedQuestion.index != null && index === excludedQuestion.index)) {
          return "";
        }

        return question;
      });
    }

    return [...total, ...clearExcludedQuestion];
  }, []);

  return { questions, questionsLengths, faqIds };
};
