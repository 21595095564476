import moment from "moment";
import { FILTER_ID } from "domain/Report/ReportFilter/types";

export const INITIAL_FILTER_ID_LISTS = [FILTER_ID.BROADCAST_STATUS, FILTER_ID.DATE_RANGE];
export const INITIAL_BROADCAST_FILTER_LISTS = [
  { id: FILTER_ID.BROADCAST_STATUS, status: [] },
  {
    id: FILTER_ID.DATE_RANGE,
    dateRange: {
      start: moment().subtract(6, "days").startOf("day").toISOString(),
      end: moment().endOf("day").toISOString(),
    },
  },
];
