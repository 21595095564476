import React, { FC, useState, useEffect, createContext } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { GUIDELINE } from "graphql/guideline/query";
import useGetProject from "utils/hooks/useGetProject";
import { GuidelineQueryType } from "types/Guideline";
import { ProjectIdType } from "types/Project";
import { START_PAGE } from "constants/Router";
import useCurrentPage from "routers/useCurrentPage";

type GuidelineContextType = {
  guidelineCompletedStepCount: number;
  isShowBroadcastQuickStart: boolean;
  isClosedConnectModalCountdown: boolean;
  setIsClosedConnectModalCountdown: React.Dispatch<React.SetStateAction<boolean>>;
  closeBroadcastBar: () => void;
  showBroadcastBar: () => void;
  setGuidelineCompletedStepCount: (projectId: string) => void;
  resetGuidelineCompletedStepCount: () => void;
  handleFetchGuideline: () => void;
  isStartPage: boolean;
};

export const GuidelineContext = createContext<GuidelineContextType>({
  guidelineCompletedStepCount: 0,
  isShowBroadcastQuickStart: false,
  isClosedConnectModalCountdown: false,
  setIsClosedConnectModalCountdown: () => {},
  closeBroadcastBar: () => {},
  showBroadcastBar: () => {},
  resetGuidelineCompletedStepCount: () => {},
  setGuidelineCompletedStepCount: () => {},
  handleFetchGuideline: () => {},
  isStartPage: false,
});

export const GuidelineContextProvider: FC = ({ children }) => {
  const { projectId } = useParams<ProjectIdType>();

  const [guidelineCompletedStepCount, setGuidelineCompletedStepCount] = useState<number>(0);
  const [isShowBroadcastQuickStart, setIsShowBroadcastQuickStart] = useState(false);

  const { currentPage } = useCurrentPage();
  const [isClosedConnectModalCountdown, setIsClosedConnectModalCountdown] = useState<boolean>(true);

  const isStartPage = currentPage === START_PAGE;

  const { data: guidelineData, loading: loadingGuideline, refetch: refetchGuideline } = useQuery<GuidelineQueryType>(
    GUIDELINE,
    {
      fetchPolicy: "network-only",
      variables: {
        projectId,
      },
    },
  );

  const hasConnect = guidelineData?.guideline?.hasConnect;
  const hasProducts = guidelineData?.guideline?.hasProducts;
  const { loading: isLoadingProject, isGuidelineCompleted, refetchProject } = useGetProject(projectId);

  const handleFetchGuideline = async () => {
    await refetchGuideline();
    await refetchProject();
  };

  const syncGuidelineCompletedStepCount = () => {
    const activeStep = [hasConnect, hasProducts].filter(Boolean).length;
    setGuidelineCompletedStepCount(activeStep);
  };

  useEffect(syncGuidelineCompletedStepCount, [hasConnect, hasProducts]);

  useEffect(() => {
    if (isGuidelineCompleted) {
      setIsShowBroadcastQuickStart(false);
      return;
    }

    setIsShowBroadcastQuickStart(true);
  }, [isGuidelineCompleted]);

  // Conditional Rendering
  if (isLoadingProject || loadingGuideline) {
    return null;
  }

  return (
    <GuidelineContext.Provider
      value={{
        guidelineCompletedStepCount,
        isShowBroadcastQuickStart,
        isClosedConnectModalCountdown,
        setIsClosedConnectModalCountdown,
        setGuidelineCompletedStepCount: handleFetchGuideline, // TODO: confusing code, remove this method and replace method call (everywhere) with `handleFetchGuideline`
        resetGuidelineCompletedStepCount: () => {
          setGuidelineCompletedStepCount(0);
        },
        showBroadcastBar: () => {
          setIsShowBroadcastQuickStart(true);
        },
        closeBroadcastBar: () => {
          setIsShowBroadcastQuickStart(false);
        },
        isStartPage,
        handleFetchGuideline,
      }}
    >
      {children}
    </GuidelineContext.Provider>
  );
};
