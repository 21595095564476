import { useContext, useEffect, useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PROJECT } from "constants/Router";
import { AppTitleContext } from "utils/context";
import { HeaderType } from "types/Tab";

// path parameter is next to projectId
// example: localhost:3002/project/5/products-inventory/categories
// path = "products-inventory" or "products-inventory/categories"
// if path = "products-inventory" so pathIndex = 3
// if path = "products-inventory/categories" so pathIndex = 4 or undefined
// because default of pathIndex is the last index of url.split("/")

const useTabWithLink = (
  tabHeaderConfigs: ReadonlyArray<HeaderType>,
  path: string,
  projectId: string,
  pathIndex?: number,
) => {
  const history = useHistory();
  const { setTitle, title } = useContext(AppTitleContext);
  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation();

  const currentPaths = window.location.pathname.split("/");
  const tempPathIndex = pathIndex == null ? currentPaths.length - 1 : pathIndex;
  const currentPath = currentPaths[tempPathIndex] || tabHeaderConfigs[0].id;

  const handleTabChange = (_event: ChangeEvent, val: number) => {
    const newTabName = tabHeaderConfigs[val].id;
    history.push(`/${PROJECT}/${projectId}/${path}/${newTabName}`);

    setTitle(t(newTabName));
    setTabIndex(val);
  };

  useEffect(() => {
    // set current tabIndex to display active style
    const tabConfig = tabHeaderConfigs.find((tabHeaderConfig, index) => {
      if (tabHeaderConfig.id === currentPath) {
        setTabIndex(index);

        return true;
      }

      return false;
    });

    // if currentPathIndex is not the last path of url. It should not set new title.
    if (pathIndex != null && pathIndex !== currentPaths.length - 1) {
      return;
    }

    // set new title
    if (tabConfig && tabConfig.title) {
      const newTitle = t(tabConfig.title);

      if (newTitle !== title) {
        setTitle(newTitle);
      }
    }
  }, [currentPath, currentPaths.length, pathIndex, setTitle, t, tabHeaderConfigs, title]);

  const defaultTabPath = tabHeaderConfigs[0] ? tabHeaderConfigs[0].id : "";
  const rootPath = `/${PROJECT}/:projectId/${path}`;

  return { tabIndex, handleTabChange, rootPath, defaultTabPath };
};

export default useTabWithLink;
