import { gql } from "@apollo/client";

export const ADD_CAMPAIGN = gql`
  mutation($projectId: ID!, $campaign: AddCampaignInput) {
    addCampaign(projectId: $projectId, campaign: $campaign) {
      id
      projectId
      name
      description
      startDate
      endDate
      status
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation($projectId: ID!, $campaignId: ID!, $campaign: UpdateCampaignInput!) {
    updateCampaign(projectId: $projectId, campaignId: $campaignId, campaign: $campaign) {
      id
      projectId
      name
      description
      startDate
      endDate
      status
    }
  }
`;

export const STOP_CAMPAIGN = gql`
  mutation($projectId: ID!, $campaignId: ID!) {
    disableCampaign(projectId: $projectId, campaignId: $campaignId) {
      id
    }
  }
`;

export const REMOVE_CAMPAIGN = gql`
  mutation($projectId: ID!, $campaignId: ID!) {
    deleteCampaign(projectId: $projectId, campaignId: $campaignId) {
      id
    }
  }
`;
