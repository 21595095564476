const campaign = {
  en: {
    Campaign: "Campaign",
    Campaigns: "Campaigns",
    Stop: "Stop",
    Live: "Live",
    Upcoming: "Upcoming",
    End: "End",
    "Edit campaign": "Edit campaign",
    "Stop campaign": "Stop campaign",
    "Do you want to stop this campaign?": "Do you want to stop this campaign?",
    "Are you sure to delete the campaign? All promotion in this campaign will be deleted with the campaign":
      "Are you sure to delete the campaign? All promotion in this campaign will be deleted with the campaign.",
    "Start date": "Start date",
    "End date": "End date",
    "Live & Upcoming": "Live & Upcoming",
    "Disabled Campaign": "Disabled",
    "Add new campaign": "Add new campaign",
    "Remove campaign": "Remove campaign",
    "Campaign name": "Campaign name",
    Description: "Description",
    "Start time": "Start time",
    "End time": "End time",
    "Please enter campaign name": "Please enter campaign name",
    "campaign.error.endTime": "End time cannot be in the past or before start time",
    "campaign.error.startTime": "Start time cannot be in the past",
    "campaign.error.timeFormat": "Please set time as HH:mm format (Example 08:35)",
    "Sort by": "Sort by",
    COPY_PROMOTION_SETTING: "Copy promotion",
    PLEASE_SELECT_CAMPAIGN_TO_PASTE: "Please select your target campaign to paste this promotion to",
    PLEASE_SELECT_CAMPAIGN: "Please select your target campaign",
  },
  th: {
    Campaign: "แคมเปญ",
    Campaigns: "แคมเปญ",
    Stop: "หยุดแคมเปญ",
    Live: "แคมเปญที่เปิดอยู่",
    Upcoming: "แคมเปญที่กำลังจะมาถึง",
    End: "แคมเปญที่จบแล้ว",
    "Edit campaign": "แก้ไขแคมเปญ",
    "Stop campaign": "หยุดแคมเปญ",
    "Do you want to stop this campaign?": "คุณต้องการจะหยุดแคมเปญนี้?",
    "Are you sure to delete the campaign? All promotion in this campaign will be deleted with the campaign":
      "คุณแน่ใจที่จะลบแคมเปญนี้? โปรโมชั่นทั้งหมดในแคมเปญนี้จะถูกลบไปด้วย",
    "Start date": "วันที่เริ่มต้น",
    "End date": "วันที่สิ้นสุด",
    "Live & Upcoming": "แคมเปญที่เปิดอยู่และกำลังจะมาถึง",
    "Disabled Campaign": "แคมเปญที่ถูกปิดการใช้งาน",
    "Add new campaign": "เพิ่มแคมเปญใหม่",
    "Remove campaign": "ลบแคมเปญ",
    "Campaign name": "ชื่อแคมเปญ",
    Description: "รายละเอียด",
    "Start time": "เวลาเริ่มต้น",
    "End time": "เวลาสิ้นสุด",
    "Please enter campaign name": "กรุณาใส่ชื่อแคมเปญ",
    "campaign.error.endTime": "เวลาสิ้นสุดไม่สามารถเป็นเวลาในอดีตหรือก่อนเวลาเริ่มต้น",
    "campaign.error.startTime": "เวลาเริ่มต้นไม่สามารถเป็นเวลาในอดีตได้",
    "campaign.error.timeFormat": "กรุณาตั้งเวลาเป็นรูปแบบ HH:mm (ตัวอย่าง 08:25)",
    "Sort by": "เรียงลำดับโดย",
    COPY_PROMOTION_SETTING: "คัดลอกโปรโมชั่น",
    PLEASE_SELECT_CAMPAIGN_TO_PASTE: "กรุณาเลือกแคมเปญปลายทาง",
    PLEASE_SELECT_CAMPAIGN: "กรุณาเลือกแคมเปญ",
  },
};

export default campaign;
