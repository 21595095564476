import { FacebookLiveProductSKUType } from "types/Product";
import { CrawlerPlatform } from "./FacebookCrawler";

export type FacebookPost = { id: string; message: string; createdTime: string; videoId: string };

export enum FacebookPostType {
  LIVE = "LIVE",
  MEDIA = "MEDIA",
  POST = "POST",
  STORY = "STORY",
}

export enum LiveStatusType {
  LIVE = "LIVE",
  END = "END",
}

export type FacebookLive = {
  createdAt: Date;
  finishedAt: Date;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  isIncludedAllProducts: boolean;
  liveStatus: LiveStatusType;
  newOrderConversionRate: number;
  numberOfEngagement: number;
  numberOfReach: number;
  orderPaidConversionRate: number;
  platform: CrawlerPlatform;
  postId: string;
  productSKUs: FacebookLiveProductSKUType[];
  projectId: string;
  title: string;
  updatedAt: Date;
  videoId: string;
};

export type FacebookLiveInput = {
  isActive: boolean;
  isIncludedAllProducts: boolean;
  liveStatus?: LiveStatusType;
  postId: string;
  productSKUIds: string[];
  title: string;
  videoId: string;
};
