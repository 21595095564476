import React, { FC, MouseEvent } from "react";

import { COLORS } from "constants/Colors";
import { SvgIcon } from "components/Icon";
import { IcClose } from "components/SvgIcons";
import Title from "./Title";
import { FullScreenCloseIconWrapper, StyledDialog } from "./styled";

type ModalPropsType = {
  fullScreen?: boolean;
  isDisabledOverFlow?: boolean;
  isOpen: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  title?: string;
  onClose: (event: MouseEvent, type?: string) => void;
  closeIconColor?: string;
  classes?: Record<string, string | number>;
  backgroundColor?: string;
  disableAutoFocus?: boolean;
  disableEnforceFocus?: boolean;
  id?: string;
  keepMounted?: boolean;
  isHiddenCloseBtn?: boolean;
};

const Modal: FC<ModalPropsType> = (props) => {
  const {
    id,
    children,
    fullScreen,
    isOpen,
    onClose,
    maxWidth,
    title,
    classes,
    closeIconColor = COLORS.White,
    backgroundColor,
    disableAutoFocus = false,
    disableEnforceFocus = false,
    keepMounted = false,
    isHiddenCloseBtn = false,
  } = props;
  const open = isOpen;
  const dialogProps = {
    children,
    fullScreen,
    open,
    onClose,
    maxWidth,
  };

  return (
    <StyledDialog
      backgroundColor={backgroundColor}
      {...dialogProps}
      classes={classes}
      disableAutoFocus={disableAutoFocus}
      disableEnforceFocus={disableEnforceFocus}
      id={id}
      keepMounted={keepMounted}
    >
      {title ? (
        <Title>
          <div style={{ flex: 1 }}>{title}</div>
          {!isHiddenCloseBtn && (
            <div style={{ flex: 0 }}>
              <SvgIcon
                onClick={onClose}
                style={{ cursor: "pointer" }}
                className="ml-1 mt-1"
                component={IcClose}
                fontSize="large"
                htmlColor={closeIconColor}
              />
            </div>
          )}
        </Title>
      ) : (
        !isHiddenCloseBtn && (
          <FullScreenCloseIconWrapper onClick={onClose}>
            <SvgIcon className="ml-1 mt-1" component={IcClose} fontSize="large" htmlColor={closeIconColor} />
          </FullScreenCloseIconWrapper>
        )
      )}

      {children}
    </StyledDialog>
  );
};

export default Modal;
