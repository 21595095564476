import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { NOTIFICATION_DELETE } from "constants/Notification";
import { ALL_CATEGORY } from "graphql/category/query";
import { DELETE_CATEGORY } from "graphql/category/mutation";
import { CategoryType, CategoryConnectionType } from "types/Category";
import { notifyError, notifySuccess } from "utils/notify";
import mapErrorMessage from "../mapErrorMessage";

const useDeleteCategories = (projectId: string, handleCloseModal: () => void) => {
  const { t } = useTranslation();

  const [deleteCategory] = useMutation(DELETE_CATEGORY, {
    update(cache, { data: { deleteCategory: deletedCategory } }) {
      const cachedCategories = cache.readQuery<CategoryConnectionType>({
        query: ALL_CATEGORY,
        variables: {
          projectId,
        },
      });
      if (cachedCategories) {
        const updatedCacheCategoriesResults = cachedCategories.categories.results.filter(
          (cachedCategory: CategoryType) => cachedCategory.id !== deletedCategory.id,
        );

        cache.writeQuery({
          query: ALL_CATEGORY,
          variables: {
            projectId,
          },
          data: {
            categories: {
              results: updatedCacheCategoriesResults,
              total: updatedCacheCategoriesResults.length,
              // eslint-disable-next-line no-underscore-dangle
              __typename: cachedCategories.categories.__typename,
            },
          },
        });
      }
    },
    onCompleted: () => {
      handleCloseModal();
      notifySuccess(t(NOTIFICATION_DELETE.SUCCESS));
    },
    onError: (err) => {
      const gqlErrorMessage: string = err?.graphQLErrors?.[0]?.message || "";
      const errorMessage = mapErrorMessage(gqlErrorMessage);

      notifyError(t(errorMessage));
    },
  });

  const handleRemoveCategory = (focusedId: string) => {
    deleteCategory({
      variables: {
        id: focusedId,
        projectId,
      },
    });
  };

  return {
    handleRemoveCategory,
  };
};

export default useDeleteCategories;
