import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import COLORS from "constants/Colors";
import { convertPriceFormat } from "utils/common";
import { GridItem, GridWrapper } from "./styled";
import { Typography } from "../styled";

type TotalSummaryPropsType = {
  additionalDiscount?: number;
  summary?: number;
  subTotal?: number;
  shipping?: number;
  discount?: number;
  shippingDiscount?: number;
  total?: number;
  isBlurContent?: boolean;
};

const TotalSummary: FC<TotalSummaryPropsType> = ({
  additionalDiscount = 0,
  summary = 0,
  subTotal = 0,
  shipping = 0,
  discount = 0,
  shippingDiscount = 0,
  total = 0,
  isBlurContent,
}) => {
  const { t } = useTranslation();

  return (
    <GridWrapper container isBlurContent={isBlurContent}>
      <GridItem>
        <Typography className="text-uppercase mr-2" fontWeight={500} color={COLORS.DarkLight}>
          {t("summary")}
        </Typography>
        <Typography color="dark">
          {convertPriceFormat(summary, 0)} {t("Orders")}
        </Typography>
      </GridItem>

      <GridItem>
        <Typography className="text-uppercase mr-2" fontWeight={500} color={COLORS.DarkLight}>
          {t("salesReport.totalSummary.subTotal")}
        </Typography>
        <Typography color="dark">
          {t("THB")} {convertPriceFormat(subTotal)}
        </Typography>
      </GridItem>

      <GridItem>
        <Typography className="text-uppercase mr-2" fontWeight={500} color={COLORS.DarkLight}>
          {t("Product discount")}
        </Typography>
        <Typography color="error">
          - {t("THB")} {convertPriceFormat(discount)}
        </Typography>
      </GridItem>

      <GridItem>
        <Typography className="text-uppercase mr-2" fontWeight={500} color={COLORS.DarkLight}>
          {t("salesReport.totalSummary.shipping")}
        </Typography>
        <Typography color="dark">
          {t("THB")} {convertPriceFormat(shipping)}
        </Typography>
      </GridItem>

      <GridItem>
        <Typography className="text-uppercase mr-2" fontWeight={500} color={COLORS.DarkLight}>
          {t("Shipping discount")}
        </Typography>

        <Typography className="" color="error">
          - {t("THB")} {convertPriceFormat(shippingDiscount)}
        </Typography>
      </GridItem>

      <GridItem>
        <Typography className="text-uppercase mr-2" fontWeight={500} color={COLORS.DarkLight}>
          {t("order.paySlip.additionalDiscount")}
        </Typography>

        <Typography className="" color="error">
          - {t("THB")} {convertPriceFormat(additionalDiscount)}
        </Typography>
      </GridItem>

      <GridItem>
        <Typography className="text-uppercase mr-2" fontWeight={500} color={COLORS.DarkLight}>
          {t("salesReport.totalSummary.total")}
        </Typography>
        <Typography color="dark">
          {t("THB")} {convertPriceFormat(total)}
        </Typography>
      </GridItem>
    </GridWrapper>
  );
};

export default TotalSummary;
