import React, { FC } from "react";
import { CreditCardBrand } from "types/Billing";

import DefaultCard from "./DefaultCard";
import InformationCard from "./InformationCard";
import LoadingCreditCard from "./LoadingCreditCard";
import { Wrapper } from "./styled";

export interface CreditCardPropsType {
  isViewInfo?: boolean;
  loading?: boolean;
  number?: string;
  name?: string;
  expirationMonth?: string;
  expirationYear?: string;
  onClick?: () => void;
  brand?: CreditCardBrand;
}

const CreditCard: FC<CreditCardPropsType> = (props) => {
  const {
    isViewInfo,
    number: cardNumber = "",
    expirationMonth = "",
    expirationYear = "",
    name = "",
    loading = false,
    brand,
    onClick,
  } = props;
  if (loading) {
    return (
      <Wrapper>
        <LoadingCreditCard />
      </Wrapper>
    );
  }
  if (isViewInfo) {
    return (
      <Wrapper>
        <InformationCard
          cardNumber={cardNumber}
          brand={brand || "VISA"}
          expirationMonth={expirationMonth}
          expirationYear={expirationYear}
          name={name}
        />
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <DefaultCard onClick={onClick} />
    </Wrapper>
  );
};

CreditCard.displayName = "CreditCard";
export default CreditCard;
