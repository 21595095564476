import React from "react";
import moment from "moment";
import get from "lodash/get";
import { CellProps, useSortBy, useFlexLayout } from "react-table";

import COLORS from "constants/Colors";
import { DATE_TIME_WITHOUT_SEPARATOR_FORMAT } from "constants/DateTimeFormat";
import i18n from "utils/i18n";
import { convertPriceFormat } from "utils/common";
import { Bullet, Title } from "./styled";
import { DEFAULT_VALUE } from "../config";

export const tableHooks = [useSortBy, useFlexLayout];

export const getColumns = <T extends object>(_isDesktop?: boolean, onClickTitle?: (id: string) => void) => {
  return [
    {
      disableSortBy: true,
      Header: i18n.t("facebookLive.table.title"),
      accessor: "title",
      width: 150,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const id = get(cell, "row.original.id");
        return (
          <Title
            onClick={() => {
              if (onClickTitle) {
                onClickTitle(id);
              }
            }}
          >
            {cell.value}
          </Title>
        );
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("Status"),
      accessor: "isActive",
      width: 100,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <>
            <Bullet color={cell.value === true ? COLORS.Success : COLORS.DarkMed} />
            {i18n.t(cell.value === true ? "Active" : "Inactive")}
          </>
        );
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("facebookLive.table.postId"),
      accessor: "postId",
      width: 150,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return cell.value != null ? cell.value : DEFAULT_VALUE;
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("facebookLive.table.live.status"),
      accessor: "liveStatus",
      width: 100,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return cell.value != null ? i18n.t(`facebookLive.${cell.value?.toLowerCase()}`) : DEFAULT_VALUE;
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("facebookLive.table.reach"),
      accessor: "numberOfReach",
      width: 120,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return cell.value != null ? cell.value : DEFAULT_VALUE;
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("facebookLive.table.engagement"),
      accessor: "numberOfEngagement",
      width: 120,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return cell.value != null ? cell.value : DEFAULT_VALUE;
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("facebookLive.table.orderPaidConversionRate"),
      accessor: "orderPaidConversionRate",
      width: 120,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return cell.value != null ? cell.value : DEFAULT_VALUE;
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("facebookLive.table.orderPaidConversionTotal"),
      accessor: "orderPaidConversionTotal",
      width: 120,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return cell.value != null ? convertPriceFormat(cell.value) : DEFAULT_VALUE;
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("facebookLive.table.latestUpdate"),
      accessor: "updatedAt",
      width: 150,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return cell.value != null ? moment(cell.value).format(DATE_TIME_WITHOUT_SEPARATOR_FORMAT) : DEFAULT_VALUE;
      },
    },
  ];
};
