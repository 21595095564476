import { MouseEventHandler } from "react";
import { WorkingHour, Day } from "types/Setting";
import { useFormContext } from "react-hook-form";
import produce from "immer";
import { ButtonProps } from "@material-ui/core";
import { generateWorkingTime, sortWorkingHour, sortWorkingTime } from "utils/adminWorkingHours";
import { MAXIMUM_WORKING_HOURS } from "../config";

export const useScheduleForm = () => {
  const { control, setValue, watch } = useFormContext();

  const workingHours = (watch("adminWorkingHours.workingHours", []) as WorkingHour[]).filter(
    ({ isActive }) => isActive,
  );
  const isActive = watch("adminWorkingHours.isActive", false) as boolean;
  const isChatbotEnabled = watch("isChatbotEnabled", false) as boolean;

  const findWorkingHourIndex = (day: Day) => workingHours.findIndex((workingHour) => workingHour.day === day);

  const activeDays = workingHours.reduce(
    (prev, { day, isActive }) => ({ ...prev, [day]: isActive }),
    {} as Record<Day, boolean>,
  );

  const shouldEnableWorkigDayAddButton = (timesIndex: number, timesLength: number) =>
    timesIndex === timesLength - 1 && timesLength < MAXIMUM_WORKING_HOURS;

  // const shouldEnableWorkingDayRemoveButton = (timesIndex: number, timesLength: number) =>
  //   timesLength > 1 && timesLength - 1 === timesIndex;

  const shouldEnableWorkingDayRemoveButton = (_timesIndex: number, _timesLength: number) => true;

  const isDisableSchduleConfiguration = !isActive;

  const handleClickDayButton: MouseEventHandler<ButtonProps> = (e) => {
    const day = e.currentTarget.value as Day;

    const newWorkingHours = produce(workingHours, (draft) => {
      const index = findWorkingHourIndex(day);

      if (index > -1) {
        draft[index].isActive = !draft[index].isActive;
      } else {
        const time = generateWorkingTime();
        draft.push({ day, isActive: true, time: [time] });
      }

      draft.sort(sortWorkingHour);
    });

    setValue("adminWorkingHours.workingHours", newWorkingHours);
  };

  const handleAddWorkingHour = (day: Day) => {
    const newWorkingHours = produce(workingHours, (draft) => {
      const index = findWorkingHourIndex(day);

      if (draft[index].time.length) {
        const lastWorkingTime = draft[index].time[draft[index].time.length - 1];

        const workingTime = generateWorkingTime(lastWorkingTime);
        draft[index].time.push(workingTime);
      } else {
        const workingTime = generateWorkingTime();
        draft[index].time.push(workingTime);
      }

      draft[index]?.time?.sort?.(sortWorkingTime);
    });

    setValue("adminWorkingHours.workingHours", newWorkingHours);
  };

  const handleRemoveWorkingHour = (day: Day, i: number) => {
    const newWorkingHours = produce(workingHours, (draft) => {
      const index = findWorkingHourIndex(day);
      draft[index].time.splice(i, 1);

      if (draft[index].time.length === 0) {
        draft.splice(index, 1);
      }

      draft[index]?.time?.sort?.(sortWorkingTime);
    });

    setValue("adminWorkingHours.workingHours", newWorkingHours);
  };

  const handleChangeWorkingTime = (day: Day, index: number, values: [string, string]) => {
    const newWorkingHours = produce(workingHours, (draft) => {
      const dayIndex = findWorkingHourIndex(day);
      const { 0: start, 1: end } = values;

      draft[dayIndex].time[index] = { ...draft[dayIndex].time[index], start, end };
      draft[dayIndex].time.sort(sortWorkingTime);
    });

    setValue("adminWorkingHours.workingHours", newWorkingHours);
  };

  const handleClickCopyButton: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (isDisableSchduleConfiguration) {
      return;
    }
    const newWorkingHours = produce(workingHours, (draft) => {
      const { time } = draft[0];

      const newWorkingHours = workingHours.map((workingHour) => ({ ...workingHour, time }));
      draft.splice(0);
      draft.push(...newWorkingHours);
    });

    setValue("adminWorkingHours.workingHours", newWorkingHours);
    e.preventDefault();
  };

  return {
    control,
    isActive,
    isChatbotEnabled,
    activeDays,
    handleAddWorkingHour,
    handleRemoveWorkingHour,
    handleClickCopyButton,
    shouldEnableWorkigDayAddButton,
    shouldEnableWorkingDayRemoveButton,
    handleClickDayButton,
    setValue,
    workingHours,
    isDisableSchduleConfiguration,
    handleChangeWorkingTime,
  };
};

export default useScheduleForm;
