import * as React from "react";

const SvgIcMore = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 13a3 3 0 1 1 0 6 3 3 0 0 1 0-6zM3 13a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm26 0a3 3 0 1 1 0 6 3 3 0 0 1 0-6z"
    />
  </svg>
);

export default SvgIcMore;
