import moment from "moment";
import ButtonBase from "components/ButtonBase";
import Card from "components/Card";
import Divider from "components/Divider";
import Grid from "components/Grid";
import { SvgIcon } from "components/Icon";
import { IcPlanUpgrade } from "components/SvgIcons";
import Typography from "components/Typography";
import { PLAN, PLAN_UPGRADE, PROJECT } from "constants/Router";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PaymentSummaryItemType, PaymentSummaryType, PaymentSummaryTypes } from "types/Billing";
import { convertPriceFormat } from "utils/common";
import { DATE_INVOICE_FORMAT } from "constants/DateTimeFormat";
import { SubscriptionType } from "types/PlanUsage";
import { IncreasedLineHeightTypography } from "../../styled";
import { AnimatedArrowSeeMoreIcon } from "../AnimatedArrowSeeMoreIcon";
import SummaryList from "./summaryList";

export type PaymentSummaryMobilePropsType = {
  billingDate: string;
  displayUpgradeButton: boolean;
  isDesktop: boolean;
  isFreePlan: boolean;
  isSeeMore: boolean;
  itemsSummary: PaymentSummaryItemType[];
  packageLabel: string;
  packagePriceTotal: number;
  packageSummaryLabel: string;
  paymentSummary: PaymentSummaryType;
  projectId: string;
  setIsSeeMore: React.Dispatch<React.SetStateAction<boolean>>;
  isHiddenNextBilling: boolean;
};

const PaymentSummaryMobile: FC<PaymentSummaryMobilePropsType> = (props) => {
  const {
    billingDate,
    displayUpgradeButton,
    isDesktop,
    isFreePlan,
    isSeeMore,
    itemsSummary,
    packageLabel,
    packagePriceTotal,
    packageSummaryLabel,
    paymentSummary,
    projectId,
    setIsSeeMore,
    isHiddenNextBilling,
  } = props;

  const { t } = useTranslation();
  const endPackagePeriodDate = new Date(paymentSummary.mainPackagePeriod.endBillingDate);
  const startPackagePeriodDate = new Date(paymentSummary.mainPackagePeriod.startBillingDate);
  const endPackagePeriod = moment(endPackagePeriodDate).format(DATE_INVOICE_FORMAT);
  const startPackagePeriod = moment(startPackagePeriodDate).format(DATE_INVOICE_FORMAT);
  const subscriptionPeriod =
    paymentSummary.mainPackagePeriod.subscriptionType === SubscriptionType.ANNUALLY
      ? t("planUsage.subscriptionType.annually")
      : t("planUsage.subscriptionType.monthly");

  return (
    <Card noShadow padding="0px" borderRadius={isDesktop ? 8 : 0}>
      <Grid container justify="space-between" alignItems="center" className="px-4 py-3 my-1">
        <Grid item>
          <Typography variant="title10" color="darkLight">
            {t("planBilling.header")}
          </Typography>
        </Grid>
        {displayUpgradeButton && (
          <Grid item>
            <Link to={`/${PROJECT}/${projectId}/${PLAN}/${PLAN_UPGRADE}`}>
              <Typography variant="body4" color="accent">
                <SvgIcon className="ml-1 pb-0 pr-1" component={IcPlanUpgrade} fontSize="small" htmlColor="inherit" />
                {t("billing.link.upgradePlan")}
              </Typography>
            </Link>
          </Grid>
        )}
      </Grid>

      <Grid container justify="space-between" alignItems="center" className="px-4">
        <Grid item>
          <Typography variant="title2" color="dark">
            {isFreePlan ? t("Free") : `${t("THB")} ${convertPriceFormat(paymentSummary.totalPrice)}`}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className="px-4 pb-3 pt-1 my-1" alignItems="baseline" justify="space-between">
        {!isHiddenNextBilling && (
          <Grid item>
            <Grid container alignItems="baseline">
              <Grid item>
                <Typography variant="body4" color="darkGray" className="pr-2 pt-2">
                  {t("planBilling.label.summary.usagePeriod")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="title10" color="darkGray" className="pt-2">
                  {isFreePlan ? " - " : billingDate}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item>
          <ButtonBase onClick={() => setIsSeeMore(!isSeeMore)}>
            <Typography variant="body4" color="primary">
              {t(isSeeMore ? "seeLess" : "seeMore")}
            </Typography>
            <AnimatedArrowSeeMoreIcon isShow={isSeeMore} viewBox="0 -6 32 40" />
          </ButtonBase>
        </Grid>
      </Grid>
      {isSeeMore && (
        <Grid container className="px-4 pb-3 my-1">
          {/* MAIN PACKAGE */}
          <Typography variant="title10" color="darkLight">
            {t(`planUsage.broadcast.label.package`)}
          </Typography>
          <Divider thickness="0.5px" className="mx-0 my-3 w-100" />
          <Grid container justify="space-between" alignItems="baseline">
            <Grid item xs={8}>
              <IncreasedLineHeightTypography variant="body3" color="darkGray">
                {packageLabel}
              </IncreasedLineHeightTypography>
              <Typography variant="body3" color="darkLight">
                {packageSummaryLabel}
              </Typography>
              <Typography variant="body4" color="darkLight">
                Subscription - {subscriptionPeriod}
              </Typography>
              <Typography variant="body4" color="darkLight">
                {startPackagePeriod} - {endPackagePeriod}
              </Typography>
            </Grid>
            <Grid item xs={4} className="text-right">
              <Typography variant="title8" color="darkGray">
                {t("THB")}
                {convertPriceFormat(Math.floor(packagePriceTotal))}
              </Typography>
            </Grid>
          </Grid>
          {/* ADD-ON */}
          {itemsSummary && !!itemsSummary.length && (
            <SummaryList type={PaymentSummaryTypes.ADDON} itemsSummary={itemsSummary} />
          )}
          {/* EXTRA */}
          {paymentSummary.extras && !!paymentSummary.extras.length && (
            <SummaryList type={PaymentSummaryTypes.EXTRA} itemsSummary={paymentSummary.extras} />
          )}
        </Grid>
      )}
    </Card>
  );
};
PaymentSummaryMobile.displayName = "PaymentSummaryMobile";

export default PaymentSummaryMobile;
