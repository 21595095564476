import React, { FC, MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import Modal from "components/Modal";
import TextField from "components/TextField";
import Typography from "components/Typography";
import Grid from "components/Grid";
import { ProductPropertiesType } from "types/Product";
import { notifyError } from "utils/notify";

type AddPropertyModalPropsType = {
  isOpen: boolean;
  onClose: (event: MouseEvent) => void;
  closeModal: () => void;
  addNewProperty: (newProperty: string) => void;
  propertyListData: ProductPropertiesType[];
};

const AddPropertyModal: FC<AddPropertyModalPropsType> = (props) => {
  const { isOpen, onClose, addNewProperty, closeModal, propertyListData } = props;
  const { t } = useTranslation();
  const [propertyName, setPropertyValue] = useState("");

  const handleSubmitNewProperty = () => {
    const trimmedProperty = propertyName.trim();
    const isDuplicatedProperty = propertyListData.some(({ name }: ProductPropertiesType) => name === trimmedProperty);

    if (!trimmedProperty) {
      notifyError(t("Product.createProperty.error.emptyText"));
    } else if (isDuplicatedProperty) {
      notifyError(t("Product.createProperty.fail"));
    } else {
      addNewProperty(trimmedProperty);
      closeModal();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Grid container className="p-4" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="title7" className="p-2">
            {t("Add new property")}
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                className="mt-3"
                variant="outlined"
                placeholder={t("Property name")}
                required
                fullWidth
                value={propertyName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPropertyValue(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} className="px-2">
          <Button className="mt-4" fullWidth onClick={handleSubmitNewProperty} disabled={!propertyName.length}>
            {t("Submit")}
          </Button>
        </Grid>
        <Grid item xs={6} className="text-right px-2">
          <Button color="secondary" className="mt-4" fullWidth onClick={onClose}>
            {t("Close")}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AddPropertyModal;
