import React, { HTMLAttributes, FC } from "react";

type AppLogoProps = {
  height?: string;
};

const AppLogo: FC<HTMLAttributes<HTMLHeadElement> & AppLogoProps> = ({ className, height }) => {
  return <img className={className} src="/assets/logo/deeple-logo-white@2x.png" alt="Deeple logo" height={height} />;
};

export default AppLogo;
