import { gql } from "@apollo/client";

export const VOID_ORDER = gql`
  mutation voidOrder($projectId: ID!, $orderId: ID!, $reason: String!) {
    voidOrder(projectId: $projectId, orderId: $orderId, reason: $reason) {
      id
      orderNumber
      state
      updatedAt
      voidedBy
      voidedAt
      voidReason
    }
  }
`;
