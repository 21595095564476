import React, { FC, useState } from "react";

import COLORS from "constants/Colors";
import { prettyCardNumber, getCreditCardInformation } from "utils/creditCard";

import TextField from "./TextField.styled";
import InputAdornment from "./InputAdornment.styled";
import { TextFieldPropsType } from "./index";
import { SvgIcon } from "../Icon";
import { IcCircleRemove, IcCircleCheck, IcCreditCard } from "../SvgIcons";

type CreditCardField = TextFieldPropsType & {
  onClearValue?: () => void;
  onChangeCallBack?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const CreditCardField: FC<CreditCardField> = (props) => {
  const { onChange = () => {}, onChangeCallBack, onClearValue = () => {}, ...otherProps } = props;
  const handleChangeCardNumber = (creditCardNumber: string) => {
    const { creditCardType, isValid, cardNumberLengths, cardNumberGaps } = getCreditCardInformation(creditCardNumber);

    const cardNumberWithoutSpace = creditCardNumber.replace(/\D+/g, "");
    const formattedCardNumber = prettyCardNumber(cardNumberWithoutSpace, creditCardType);
    const maxLength = cardNumberLengths.pop() || 16;
    const gapCount = cardNumberGaps.length || 3;

    return { formattedCardNumber, maxLength: maxLength > 16 ? 16 : maxLength, gapCount, isValid };
  };
  const { formattedCardNumber: initialValue } = handleChangeCardNumber("");
  const [isShowEndIcon, setIsShowEndIcon] = useState(false);
  const [inputValue, setInputValue] = useState(initialValue);
  const [isVerified, setIsVerified] = useState(false);

  return (
    <TextField
      {...otherProps}
      isBoldText
      isOutlined={false}
      value={inputValue}
      type="tel"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const inputLength = value.toString().length;

        const { formattedCardNumber, maxLength, gapCount, isValid } = handleChangeCardNumber(value);

        if (inputLength <= maxLength + gapCount) {
          onChange(event);
          if (onChangeCallBack) {
            onChangeCallBack(event);
          }
          setInputValue(formattedCardNumber);
          setIsVerified(isValid);
        }

        setIsShowEndIcon(inputLength !== 0);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SvgIcon component={IcCreditCard} fontSize="default" htmlColor={COLORS.DarkMed} className="p-0 m-0" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {isShowEndIcon && (
              <SvgIcon
                onClick={() => {
                  if (!isVerified) {
                    onClearValue();
                    setInputValue("");
                    setIsShowEndIcon(false);
                  }
                }}
                component={isVerified ? IcCircleCheck : IcCircleRemove}
                fontSize={isVerified ? "default" : "large"}
                viewBox={isVerified ? "-8 -4 24 24" : "0 2 24 24"}
                htmlColor={isVerified ? COLORS.Success : COLORS.Dark}
                className="m-0 p-0"
              />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CreditCardField;
