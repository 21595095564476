import { gql } from "@apollo/client";

export const PAYMENTS = gql`
  query payments($projectId: ID!) {
    payments(projectId: $projectId) {
      ... on BankAccount {
        id
        projectId
        type
        isActive
        accountName
        accountNumber
        bankAccountType
        bankBranch
        bankName
      }
      ... on PromptPay {
        id
        projectId
        type
        isActive
        accountName
        accountNumber
        promptpayType
        image
      }
      ... on COD {
        id
        projectId
        type
        isActive
        shippingMethod
        depositType
        depositThreshold
        depositAmount
        managementFee {
          fee
          percent
          type
          isAppliedPromotion
        }
      }
      ... on PaymentGatewayChillPay {
        id
        projectId
        type
        isActive
        apiKey
        md5Secret
        merchantId
        description
      }
      ... on PaymentGateway2C2P {
        id
        projectId
        type
        isActive
        md5Secret
        merchantId
        description
      }
      ... on PaymentGatewayCustom {
        id
        projectId
        type
        isActive
        description
        header
        body
        iconUrl
        url
      }
    }
  }
`;
