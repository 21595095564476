import React, { FC } from "react";
import styled from "styled-components";

import { SIDE_NAV_WIDTH_DESKTOP } from "constants/Layout";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 ${SIDE_NAV_WIDTH_DESKTOP}px;
`;

const Logo = styled.img`
  padding: 0 32px;
`;

const DeepleLogoHeader: FC = () => {
  return (
    <Wrapper>
      <Logo alt="deeple-logo" src="/assets/logo/deeple_logo_colored.svg" width="70" />
    </Wrapper>
  );
};

export default DeepleLogoHeader;
