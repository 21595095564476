import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ActionMeta, OptionType, OptionsType } from "@atlaskit/select";

import Grid from "components/Grid";
import MultiSelectWithSearchBox from "components/MultiSelectWithSearchBox";
import CircularProgress from "components/CircularProgress";

import { MAXIMUM_ADD_PRODUCT_CAROUSEL } from "config";

type SelectProductPropsType = {
  loading: boolean;
  productOptions: {
    label: string;
    img: string;
    value: string;
  }[];
  convertProductValueToOptionFormat: Function;
  selectedProducts: number[];
  setSelectedProducts: Function;
};

const SelectProduct: FC<SelectProductPropsType> = (props) => {
  const { selectedProducts, setSelectedProducts, loading, productOptions, convertProductValueToOptionFormat } = props;
  const { t } = useTranslation();

  const handleChangeSelectProduct = (option: OptionsType<OptionType>, action: ActionMeta<OptionType>) => {
    if (action.action === "select-option" && selectedProducts.length < MAXIMUM_ADD_PRODUCT_CAROUSEL) {
      setSelectedProducts(((option as OptionsType<OptionType>) || []).map((opt: OptionType) => opt.value));
    } else if (action.action === "deselect-option") {
      setSelectedProducts(((option as OptionsType<OptionType>) || []).map((opt: OptionType) => opt.value));
    }
  };

  return (
    <Grid container>
      {loading ? (
        <Grid container item xs={12} justify="center">
          <CircularProgress className="p-4" />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <MultiSelectWithSearchBox
            isMulti
            isDisplayMinimumItem
            isDisplayImageOption
            value={convertProductValueToOptionFormat(selectedProducts)}
            label={t("Products")}
            placeholder={t("Products")}
            options={productOptions}
            onChange={(option: OptionsType<OptionType>, action: ActionMeta<OptionType>) => {
              handleChangeSelectProduct(option || [], action);
            }}
            maxMenuWidth={310}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SelectProduct;
