import { BranchType } from "types/Branch";

const checkDuplicatedBranchName = (branches: BranchType[], branchName: string) => {
  const trimmedBranchName = branchName.trim();

  const duplicatedBranchName = branches.filter((branch) => branch.name === trimmedBranchName).length;

  return duplicatedBranchName > 1;
};

export default checkDuplicatedBranchName;
