import React from "react";
import { components, OptionProps, SingleValueProps } from "react-select";
import { useTranslation } from "react-i18next";

import { Shipping } from "types";

export const CustomOptionShippingCarrier = (
  optionProps: OptionProps<Shipping.CARRIER_OPTIONS | Shipping.SHIPPOP_CARRIER_OPTIONS>,
) => {
  const { t } = useTranslation();
  const { data } = optionProps;

  return (
    <components.Option {...optionProps}>
      <span className="d-flex align-items-center">
        {data.logo && <img height="45" width="45" src={data.logo} alt={t(data.value)} />}
        <span className="ml-2">{t(data.value)}</span>
      </span>
    </components.Option>
  );
};

export const SingleValueShippingCarrier = (
  singleValueProps: SingleValueProps<Shipping.CARRIER_OPTIONS | Shipping.SHIPPOP_CARRIER_OPTIONS>,
) => {
  const { t } = useTranslation();
  const { data } = singleValueProps;

  return (
    <components.SingleValue {...singleValueProps}>
      <span className="d-flex align-items-center">
        {data.logo && <img height="45" width="45" src={data.logo} alt={t(data.value)} />}
        <span className="ml-2">{t(data.value)}</span>
      </span>
    </components.SingleValue>
  );
};
