import moment from "moment";

const updateLocale = () => {
  moment.updateLocale("en", {
    relativeTime: {
      future: "%s",
      past: "%s",
      s: "Just now",
      ss: "Just now",
      m: "Just now",
      mm: "Just now",
      h: "%d hour",
      hh: "%d hours",
      d: "a day",
      dd: "%d days",
    },
    calendar: {},
  });

  moment.updateLocale("th", {
    relativeTime: {
      future: "%s",
      past: "%s",
      s: "เมื่อสักครู่",
      ss: "เมื่อสักครู่",
      m: "เมื่อสักครู่",
      mm: "เมื่อสักครู่",
      h: "%d ชั่วโมง",
      hh: "%d ชั่วโมง",
      d: "1 วัน",
      dd: "%d วัน",
    },
    calendar: {},
  });
};

export { updateLocale };
