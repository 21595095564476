const congratulations = {
  en: {
    CONGRATULATIONS: "Congratulations",
    YOUR_EMAIL_ALREADY_CONFIRMED:
      "Your email has already been confirmed. You can now log in and enjoy our dashboard and all features.",
    CONTINUE: "Continue",
  },
  th: {
    CONGRATULATIONS: "ยินดีด้วย",
    YOUR_EMAIL_ALREADY_CONFIRMED:
      "อีเมลของคุณได้รับการยืนยันแล้ว คุณสามารถเข้าสู่ระบบและเพลิดเพลินไปกับระบบของเราได้แล้ว",
    CONTINUE: "ดำเนินการต่อ",
  },
};

export default congratulations;
