import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Grid from "components/Grid";
import Typography from "components/Typography";

const Wrapper = styled(Grid)`
  border-top: 0.5px solid ${({ theme }) => theme.COLORS.LightBlue};
  border-bottom: 0.5px solid ${({ theme }) => theme.COLORS.LightBlue};
`;

const SKUTableHeader: FC = () => {
  const { t } = useTranslation("CONSOLE_GENERATED_FORM");

  return (
    <Wrapper container alignContent="center" alignItems="center" className="px-3 py-2">
      <Grid item xs={3}>
        <Typography variant="title10" color="darkLight">
          {t("SKUCode")}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="title10" color="darkLight">
          SKU(s)
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="title10" color="darkLight">
          {t("price")}
        </Typography>
      </Grid>
    </Wrapper>
  );
};

export default SKUTableHeader;
