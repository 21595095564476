import React from "react";
import { CellProps } from "react-table";
import getDimensionText from "utils/getDimensionText";

const getDimension = <T extends object>(t: Function) => ({
  disableSortBy: true,
  Header: `${t("DIMENSION")} ${t("WxLxH")}`,
  accessor: "largestDimension",
  width: 250,
  // eslint-disable-next-line react/display-name
  Cell: (cell: CellProps<T>) => <div>{getDimensionText(cell.value, t)}</div>,
});

export default getDimension;
