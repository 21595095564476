import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

import CircularProgress from "components/CircularProgress";
import Typography from "components/Typography";
import Modal from "components/Modal";

import { DEFAULT_SHIPPING_METHOD } from "graphql/shipping/query";
import { ShippingMethod, METHOD, DefaultShippingMethodQueryType, ShippopStoreSetting } from "types/Shipping";
import { ProjectIdType } from "types/Project";
import Grid from "components/Grid";
import PageLayout from "./PageLayout";
import { ShippingMethodCard } from "./ShippingMethodCard";
import EditShippingMethodForm from "../EditShippingMethodForm";
import ShippingInfoGraphicCard from "../ShippingInfoGraphic";
import useUpdateShippingMethod from "../hooks/useUpdateShippingMethod";

export const DefaultShippingMethod: React.FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();

  const [isEdit, setIsEdit] = useState(false);
  const [focusedMethodIndex, setFocusedMethodIndex] = useState(0);
  const [errorFields, setErrorFields] = useState<string[]>([]);

  const { loading: loadingShippingMethod, data } = useQuery<DefaultShippingMethodQueryType, ProjectIdType>(
    DEFAULT_SHIPPING_METHOD,
    {
      skip: !projectId,
      variables: {
        projectId,
      },
    },
  );

  const {
    handleChangeImage,
    handleEditShippingMethod,
    handleToggleEnableShippingMethod,
    handleToggleSchedulePickUp,
    handleUpdateDefaultShippingMethod,
    isUpdating,
  } = useUpdateShippingMethod({
    projectId,
    onUpdateCompleted: () => {
      setIsEdit(false);
    },
    onUpdateFailed: (error: ApolloError) => {
      setErrorFields(get(error, "graphQLErrors[0].extensions.exception.meta.fields") || []);
    },
    defaultShippingData: data?.defaultShippingMethod?.shipping || [],
    defaultImage: data?.defaultShippingMethod?.image || "",
    defaultShippopData: data?.defaultShippingMethod?.shippop || null,
  });

  const handleSelectMethod = (value: string) => {
    if (value === METHOD.STANDARD) {
      return setFocusedMethodIndex(0);
    }
    return setFocusedMethodIndex(1);
  };

  const handleClickBack = useCallback(() => setIsEdit(false), []);

  const handleSubmitForm = useCallback(
    (editedData: ShippingMethod, editedShippopData: ShippopStoreSetting) => {
      handleEditShippingMethod(editedData, editedShippopData, focusedMethodIndex);
    },
    [focusedMethodIndex, handleEditShippingMethod],
  );

  if (loadingShippingMethod) {
    return (
      <Modal isOpen onClose={() => {}}>
        <CircularProgress className="m-4" />
      </Modal>
    );
  }

  const imageUrl: string = get(data, "defaultShippingMethod.image") || "";

  const handleRemoveImage = () => {
    const shippingData = data?.defaultShippingMethod?.shipping || [];
    const shippopData = data?.defaultShippingMethod?.shippop || null;
    handleUpdateDefaultShippingMethod(shippingData, "", shippopData);
  };

  return isEdit && data && data.defaultShippingMethod && data.defaultShippingMethod.shipping[focusedMethodIndex] ? (
    <PageLayout
      title={t(data.defaultShippingMethod.shipping[focusedMethodIndex].method)}
      onClickBack={handleClickBack}
      backLabel={t("Back")}
    >
      {isUpdating && (
        <Modal isOpen={isUpdating} onClose={() => {}}>
          <CircularProgress className="m-4" />
        </Modal>
      )}
      <EditShippingMethodForm
        projectId={projectId}
        focusedShippingData={data.defaultShippingMethod.shipping[focusedMethodIndex]}
        errorFields={errorFields}
        onSubmitForm={handleSubmitForm}
        shippopData={data.defaultShippingMethod.shippop}
      />
    </PageLayout>
  ) : (
    <>
      <Grid className="px-3 pt-3">
        <Typography variant="title2" className="p-4">
          {t("Default shipping method")}
        </Typography>
      </Grid>
      {data &&
        data.defaultShippingMethod &&
        data.defaultShippingMethod.shipping.map((shippingMethod: ShippingMethod, index: number) => {
          const {
            calculateType,
            description,
            disabled,
            isAllowedOnlyCOD,
            disabledDuration,
            fixedPrice,
            isDisabledSchedulePickUp,
            maxDuration,
            method,
            methodType,
            minDuration,
            setting,
          } = shippingMethod;

          return (
            <ShippingMethodCard
              projectId={projectId}
              key={method}
              shippingName={description}
              shippingMethod={method}
              shippingMethodType={methodType}
              calculateType={calculateType}
              fixedPrice={fixedPrice}
              maxDuration={maxDuration || 1}
              minDuration={minDuration || 1}
              disabled={disabled}
              disabledDuration={disabledDuration}
              isAllowedOnlyCOD={isAllowedOnlyCOD}
              openEditModal={setIsEdit}
              handleSelectMethod={handleSelectMethod}
              shippingData={data.defaultShippingMethod.shipping}
              onToggleEnableShippingMethod={(isDisabled: boolean) => {
                handleToggleEnableShippingMethod(isDisabled, index);
              }}
              onToggleSchedulePickUp={(isDisabled: boolean) => {
                handleToggleSchedulePickUp(isDisabled, index);
              }}
              isDisabledSchedulePickUp={isDisabledSchedulePickUp}
              errorFields={errorFields}
              locationSettings={setting?.provinceSettings}
              shippopData={data?.defaultShippingMethod?.shippop || null}
              shippopSetting={setting?.shippopSetting}
            />
          );
        })}
      <ShippingInfoGraphicCard image={imageUrl} onChange={handleChangeImage} onRemove={handleRemoveImage} />
    </>
  );
};
