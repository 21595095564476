import get from "lodash/get";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { downloadFile } from "utils/common";
import { RECEIPT_URL } from "graphql/salesReport/query";

type UseDownloadPayslipDataType = {
  data?: { receiptURL: string };
};

const useDownloadPayslip = () => {
  const [downloadOrderId, setDownloadOrderId] = useState("");
  const [receiptURL, { data, loading }] = useLazyQuery<UseDownloadPayslipDataType>(RECEIPT_URL, {});

  useEffect(() => {
    const url: string = get(data, "receiptURL") || "";
    if (url) {
      const fileName = url.split("?")[0].split("/").pop();

      if (fileName) {
        downloadFile(url, fileName);
      }
    }
  }, [data]);

  const downloadPayslip = async (projectId: string, orderId: string) => {
    setDownloadOrderId(orderId);

    receiptURL({
      variables: {
        projectId,
        orderId,
      },
    });
  };

  return { downloadPayslip, isLoading: loading, downloadOrderId };
};

export default useDownloadPayslip;
