import { SHIPPOP_CALCULATE } from "types/Shipping";

import { aramexDescription } from "./Carrier/aramex";
import { beeExpressDescription } from "./Carrier/beeExpress";
import { bestExpressDescription } from "./Carrier/bestExpress";
import { cjLogisticsDescription } from "./Carrier/cjLogistics";
import { flashExpressDescription } from "./Carrier/flashExpress";
// import { jandtExpressDropOffDescription } from "./Carrier/jandtExpressDropOff";
// import { jandtExpressPickupDescription } from "./Carrier/jandtExpressPickup";
import { jwdExpressDescription } from "./Carrier/jwdExpress";
import { kerryDescription } from "./Carrier/kerry";
import { ninjavanDescription } from "./Carrier/ninjavan";
import { scgYamatoExpressDescription } from "./Carrier/scgYamatoExpress";
import { scgYamatoExpressChilledDescription } from "./Carrier/scgYamatoExpressChilled";
import { scgYamatoExpressFrozenDescription } from "./Carrier/scgYamatoExpressFrozen";
import { thaipostEMSDescription } from "./Carrier/thaipostEMS";
import { thaipostRegisterDescription } from "./Carrier/thaipostRegister";
import { trueELogisticsDescription } from "./Carrier/trueELogistics";

export const SHIPPOP_FAQ_LINK = "https://www.shippop.com/faq/#";

export type CarrierDescriptionText = { body: string; subBody?: string[] };

export type ShippopCarrierDescription = {
  [key: string]: {
    EN: [] | null;
    TH: CarrierDescriptionText[] | null;
  };
};

export enum SHIPPOP_CARRIER_CODE {
  ARAMEX = "ARM",
  BEE_EXPRESS = "BEE",
  BEST_EXPRESS = "BEST",
  CJ_LOGISTICS = "CJE",
  FLASH_EXPRESS = "FLE",
  // J_AND_T_EXPRESS_DROPOFF = "JNTD",
  // J_AND_T_EXPRESS_PICKUP = "JNTP",
  JWD_EXPRESS_CHILLED = "JWDC",
  JWD_EXPRESS_FROZEN = "JWDF",
  KERRY_DROPOFF = "KRYD",
  KERRY_PICKUP = "KRYP",
  NINJAVAN = "NJV",
  SCG_YAMATO_EXPRESS = "SCG",
  SCG_YAMATO_EXPRESS_CHILLED = "SCGC",
  SCG_YAMATO_EXPRESS_FROZEN = "SCGF",
  THAIPOST_EMS = "THP",
  THAIPOST_REGISTER = "TP2",
  TRUE_E_LOGISTICS = "TRUE",
}

export enum SHIPPOP_CARRIER_NAME { // no EN description yet
  ARAMEX = "Aramex",
  BEE_EXPRESS = "BEE express",
  BEST_EXPRESS = "BEST express",
  CJ_LOGISTICS = "CJ logistics",
  FLASH_EXPRESS = "Flash express",
  // J_AND_T_EXPRESS_DROPOFF = "J&T drop off",
  // J_AND_T_EXPRESS_PICKUP = "J&T pickup",
  JWD_EXPRESS_CHILLED = "JWD express chilled",
  JWD_EXPRESS_FROZEN = "JWD express frozen",
  KERRY_DROPOFF = "Kerry drop off",
  KERRY_PICKUP = "Kerry pickup",
  NINJAVAN = "Ninjavan",
  SCG_YAMATO_EXPRESS = "SCG yamato express",
  SCG_YAMATO_EXPRESS_CHILLED = "SCG yamato express chilled",
  SCG_YAMATO_EXPRESS_FROZEN = "SCG yamato express frozen",
  THAIPOST_EMS = "Thaipost-EMS",
  THAIPOST_REGISTER = "Thaipost-Register",
  TRUE_E_LOGISTICS = "True E logistics",
}

export const CARRIER_DESCRIPTION: ShippopCarrierDescription = {
  [`${SHIPPOP_CARRIER_NAME.BEE_EXPRESS}`]: beeExpressDescription,
  [`${SHIPPOP_CARRIER_NAME.BEST_EXPRESS}`]: bestExpressDescription,
  [`${SHIPPOP_CARRIER_NAME.CJ_LOGISTICS}`]: cjLogisticsDescription,
  [`${SHIPPOP_CARRIER_NAME.FLASH_EXPRESS}`]: flashExpressDescription,
  // [`${SHIPPOP_CARRIER_NAME.J_AND_T_EXPRESS_DROPOFF}`]: jandtExpressDropOffDescription,
  // [`${SHIPPOP_CARRIER_NAME.J_AND_T_EXPRESS_PICKUP}`]: jandtExpressPickupDescription,
  [`${SHIPPOP_CARRIER_NAME.KERRY_DROPOFF}`]: kerryDescription,
  [`${SHIPPOP_CARRIER_NAME.KERRY_PICKUP}`]: kerryDescription,
  [`${SHIPPOP_CARRIER_NAME.NINJAVAN}`]: ninjavanDescription,
  [`${SHIPPOP_CARRIER_NAME.SCG_YAMATO_EXPRESS}`]: scgYamatoExpressDescription,
  [`${SHIPPOP_CARRIER_NAME.SCG_YAMATO_EXPRESS_CHILLED}`]: scgYamatoExpressChilledDescription,
  [`${SHIPPOP_CARRIER_NAME.SCG_YAMATO_EXPRESS_FROZEN}`]: scgYamatoExpressFrozenDescription,
  [`${SHIPPOP_CARRIER_NAME.THAIPOST_EMS}`]: thaipostEMSDescription,
  [`${SHIPPOP_CARRIER_NAME.THAIPOST_REGISTER}`]: thaipostRegisterDescription,
  [`${SHIPPOP_CARRIER_NAME.JWD_EXPRESS_CHILLED}`]: jwdExpressDescription,
  [`${SHIPPOP_CARRIER_NAME.JWD_EXPRESS_FROZEN}`]: jwdExpressDescription,
  [`${SHIPPOP_CARRIER_NAME.TRUE_E_LOGISTICS}`]: trueELogisticsDescription,
  [`${SHIPPOP_CARRIER_NAME.ARAMEX}`]: aramexDescription,
};

export const SHIPPOP_ADVANCE_SETTING_OPTIONS = [
  {
    value: SHIPPOP_CALCULATE.SHIPPOP_BASE_PRICE,
    title: "shippop.advanceSetting.shippopBased.title",
    description: "shippop.advanceSetting.shippopBased.description",
  },
  {
    value: SHIPPOP_CALCULATE.POSTAL_CODE_BASED_PRICE,
    title: "shippop.advanceSetting.postCodeBased.title",
    description: "shippop.advanceSetting.postCodeBased.description",
  },
  {
    value: SHIPPOP_CALCULATE.FLAT_RATE_PRICE,
    title: "shippop.advanceSetting.flatRate.title",
    description: "shippop.advanceSetting.flatRate.description",
  },
];
