import React, { FC, ChangeEventHandler, HTMLAttributes } from "react";

import MessageLayout from "components/FlexMessage/MessageLayout";
import { TextInput } from "./styled";

type InputMessagePropsType = {
  value: string;
  onChange?: ChangeEventHandler;
  bgColor?: string;
  disabled?: boolean;
} & Omit<HTMLAttributes<HTMLInputElement>, "color">;

const InputMessage: FC<InputMessagePropsType> = ({ value, onChange = () => {}, bgColor, disabled, ...rest }) => {
  return (
    <MessageLayout bgColor={bgColor}>
      <TextInput fullWidth disabled={disabled} multiline value={value} onChange={onChange} {...rest} />
    </MessageLayout>
  );
};

export default InputMessage;
