// new support just only png,jpg,jpeg
export const GUIDELINE_VIDEO_LINK = {
  productAndCategory: "https://www.youtube.com/embed/rqssnhvyH70?list=PLpQ7q9xUQjKfDL2uWrwXDpjmqaCNe1sBl",
  shipping: "https://www.youtube.com/embed/BZngTYqvs_U?list=PLpQ7q9xUQjKfDL2uWrwXDpjmqaCNe1sBl",
  connect: "https://www.youtube.com/embed/oMFl3V5vY00",
  deeplePay: "https://www.youtube.com/embed/XEAsVDTDviQ",
  trainBot: "https://www.youtube.com/embed/kb_PsQ5S7HE?list=PLpQ7q9xUQjKfDL2uWrwXDpjmqaCNe1sBl",
  facebook: "https://www.youtube.com/embed/C5kiHmG5OjI",
  instagram: "https://www.youtube.com/embed/11pzQ_HTgLg",
  line: "https://www.youtube.com/embed/hWFVoU50Tok",
};
