import { yupResolver } from "@hookform/resolvers/yup";
import React, { FC, useState, useEffect, ChangeEvent } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import moment from "moment";
import get from "lodash/get";

import Button from "components/Button";
import DatePicker from "components/DatePicker";
import ErrorText from "components/ErrorText";

import Grid from "components/Grid";
import Modal from "components/Modal";
import TextField from "components/TextField";

import Typography from "components/Typography";

import { CAMPAIGN_NAME_LENGTH_LIMIT } from "config/campaign";
import { CAMPAIGN_STATUS, CAMPAIGN_TIME_FORMAT_REGEX } from "constants/Campaign";
import { DATE_FORMAT, TIME_FORMAT } from "constants/DateTimeFormat";
import { CampaignWithoutPromotionType, CampaignQueryVariableType, CampaignFormType } from "types/Campaign";

import useSubmitForm from "../hooks/useSubmitForm";
import validationSchema from "../validateSchema";

type AddEditCampaignModalPropsType = {
  isOpen: boolean;
  onClose: () => void;
  focusedCampaign?: CampaignWithoutPromotionType;
  queryVariable: CampaignQueryVariableType;
};

// Fix bug on Ios safari not render
const TextArea = styled(TextField)`
  transform: translate3d(0, 0, 0);
`;

const AddEditCampaignModal: FC<AddEditCampaignModalPropsType> = (props) => {
  const { t } = useTranslation();
  const { isOpen, onClose, focusedCampaign, queryVariable } = props;

  const initStartDate = moment().toISOString();
  const initEndDate = moment().add(1, "hours").toISOString();
  const isLiveStatus = get(focusedCampaign, "status") === CAMPAIGN_STATUS.LIVE;

  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);
  const [campaignName, setCampaignName] = useState("");

  const { register, handleSubmit, errors, setValue, getValues, trigger, reset } = useForm<CampaignFormType>({
    resolver: yupResolver(validationSchema),
  });

  const handleCloseModal = () => {
    reset();
    onClose();
  };

  const { handleSubmitForm } = useSubmitForm({ queryVariable, focusedCampaign, onClose: handleCloseModal });

  const handleTimeValidation = () => {
    trigger(["startTime", "endTime"]);
  };

  const handleSetStartDate = (selectedDate: string | null) => {
    if (selectedDate) {
      const isSelectedStartDateAfterCurrentEndDate = moment(selectedDate).isAfter(moment(endDate));
      if (isSelectedStartDateAfterCurrentEndDate) {
        const newEndDate = moment(selectedDate).add(1, "days").toISOString();
        setValue("endDate", newEndDate);
        setEndDate(newEndDate);
      }
      setValue("startDate", selectedDate);
      setStartDate(selectedDate);
    }
  };

  const handleSetEndDate = (selectedDate: string | null) => {
    if (selectedDate) {
      setValue("endDate", selectedDate);
      setEndDate(selectedDate);
      handleTimeValidation();
    }
  };

  const handleTimeInputBlur = (event: React.FocusEvent<HTMLInputElement>, isEndTime = false) => {
    const timeString: string = event.target.value;
    const isTimeFormatValid = timeString.match(CAMPAIGN_TIME_FORMAT_REGEX);

    if (isTimeFormatValid) {
      const [hour, minute]: string[] = timeString.split(":");
      const focusedKey = isEndTime ? "endDate" : "startDate";
      const formValues = getValues();
      const date = formValues[focusedKey];

      const newDate = moment(date).hour(Number(hour)).minute(Number(minute)).toDate().toISOString();

      setValue(focusedKey, newDate);
    }

    handleTimeValidation();
  };

  useEffect(() => {
    const focusedCampaignName = get(focusedCampaign, "name", "");
    const focusedStartDate = get(focusedCampaign, "startDate");
    const focusedEndDate = get(focusedCampaign, "endDate");
    const focusedDescription = get(focusedCampaign, "description", "");

    setCampaignName(focusedCampaignName);
    setStartDate(focusedStartDate ? moment(Number(focusedStartDate)).toISOString() : initStartDate);
    setEndDate(focusedEndDate ? moment(Number(focusedEndDate)).toISOString() : initEndDate);

    register("endDate");
    register("startDate");
    register("isLiveStatus");
    setValue("campaignName", focusedCampaignName);
    setValue("campaignDescription", focusedDescription);
    setValue("endDate", focusedEndDate ? moment(Number(focusedEndDate)).toISOString() : initEndDate);
    setValue("startDate", focusedStartDate ? moment(Number(focusedStartDate)).toISOString() : initStartDate);
    setValue("isLiveStatus", isLiveStatus);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedCampaign]);

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal}>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container className="p-3">
          <Grid item xs={12}>
            <Typography variant="title2" color="dark" className="py-2">
              {t(focusedCampaign ? "Edit campaign" : "Add new campaign")}
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <Grid className="px-1 mb-2" item container xs={12} justify="space-between">
              <Grid item>
                <Typography className="mt-3" variant="body3" color="darkMed">
                  {t("Campaign name")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className="mt-3" variant="body3" color="darkMed">
                  {campaignName.length} / {CAMPAIGN_NAME_LENGTH_LIMIT}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  placeholder={t("Campaign name")}
                  required
                  name="campaignName"
                  validate={register}
                  fullWidth
                  defaultValue={campaignName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setCampaignName(e.target.value);
                  }}
                  InputProps={isLiveStatus ? { className: "Mui-disabled" } : {}}
                  inputProps={{ readOnly: isLiveStatus }}
                />
              </Grid>
              {errors.campaignName && (
                <Grid item xs={12} className="m-1">
                  <ErrorText>{t(errors.campaignName.message as string)}</ErrorText>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid className="px-1 mb-2" item xs={12}>
              <Typography className="mt-3" variant="body3" color="darkMed">
                {t("Start date")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                fullWidth
                format={DATE_FORMAT}
                selectedDate={startDate}
                setDate={handleSetStartDate}
                minDate={isLiveStatus ? undefined : moment().toDate()}
                disabled={isLiveStatus}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid className="px-1 mb-2" item xs={12}>
              <Typography className="mt-3" variant="body3" color="darkMed">
                {t("Start time")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                defaultValue={moment(startDate).format(TIME_FORMAT)}
                variant="outlined"
                name="startTime"
                validate={register}
                placeholder={t("Time")}
                type="time"
                fullWidth
                onChange={handleTimeValidation}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => handleTimeInputBlur(event)}
                InputProps={isLiveStatus ? { className: "Mui-disabled" } : {}}
                inputProps={{ readOnly: isLiveStatus }}
              />
            </Grid>
            {errors.startTime && (
              <Grid item xs={12} className="m-1">
                <ErrorText>{t(errors.startTime.message as string)}</ErrorText>
              </Grid>
            )}
          </Grid>
          <Grid item container xs={12}>
            <Grid className="px-1 mb-2" item xs={12}>
              <Typography className="mt-3" variant="body3" color="darkMed">
                {t("End date")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                fullWidth
                format={DATE_FORMAT}
                selectedDate={endDate}
                setDate={handleSetEndDate}
                minDate={isLiveStatus ? moment().toDate() : moment(startDate).toDate()}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid className="px-1 mb-2" item xs={12}>
              <Typography className="mt-3" variant="body3" color="darkMed">
                {t("End time")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                defaultValue={moment(endDate).format(TIME_FORMAT)}
                variant="outlined"
                name="endTime"
                validate={register}
                placeholder={t("Time")}
                type="time"
                fullWidth
                onChange={handleTimeValidation}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => handleTimeInputBlur(event, true)}
              />
            </Grid>
            {errors.endTime && (
              <Grid item xs={12} className="m-1">
                <ErrorText>{t(errors.endTime.message as string)}</ErrorText>
              </Grid>
            )}
          </Grid>
          <Grid item container xs={12}>
            <Grid className="px-1 mb-2" item xs={12}>
              <Typography className="mt-3" variant="body3" color="darkMed">
                {t("Description")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextArea
                variant="outlined"
                placeholder={t("Description")}
                fullWidth
                name="campaignDescription"
                validate={register}
                defaultValue={get(focusedCampaign, "description", "")}
                multiline
                rows="3"
              />
            </Grid>
          </Grid>
          <Grid container className="mt-3 mb-1 ml-0" spacing={1}>
            <Grid item xs={6} className="text-right">
              <Button color="ghost" fullWidth onClick={handleCloseModal}>
                {t("Close")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button type="submit" fullWidth>
                {t("Submit")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

export default AddEditCampaignModal;
