import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { RICH_MENU } from "constants/i18n";
import Button from "components/Button";
import Typography from "components/Typography";
import Card from "components/Card";
import Colors from "constants/Colors";
import { LineMenuTemplate } from "types/RichMenu";
import FiveBoxes from "./options/FiveBoxes";
import Option from "./Option";
import { TemplateOptionsWrapper, RatioBox, RatioContent } from "./styled";
import GridTemplate from "./GridTemplate";
import { TEMPLATE_OPTIONS, DEFAULT_LINE_RICH_MENU } from "../../config";

type TemplateSelectorProps = {
  value?: LineMenuTemplate;
  onCancel: () => void;
  onSubmit: (value?: LineMenuTemplate) => void;
};

const TemplateSelector: FC<TemplateSelectorProps> = ({ onCancel, onSubmit, value }) => {
  const { t } = useTranslation(RICH_MENU);
  const [selectedTemplate, setSelectedTemplate] = useState<LineMenuTemplate | undefined>(value);
  const handleClickOption = (value?: LineMenuTemplate) => {
    if (!value) return;
    setSelectedTemplate(value);
  };

  return (
    <Card noShadow padding="24px">
      <Typography variant="title3" className="mb-2">
        {t("SELECT_TEMPLATE")}
      </Typography>

      <TemplateOptionsWrapper>
        {TEMPLATE_OPTIONS.map((option) => (
          <Option key={option} value={option} onClick={handleClickOption} isActive={option === selectedTemplate}>
            <RatioBox>
              <RatioContent>
                <GridTemplate type={option} bgColor={Colors.LightGray} />
              </RatioContent>
            </RatioBox>
          </Option>
        ))}
      </TemplateOptionsWrapper>

      <Typography variant="title3" className="mt-4 mb-2">
        {t("DEFAULT_TEMPLATE")}
      </Typography>
      <TemplateOptionsWrapper>
        <Option
          value={LineMenuTemplate.DEFAULT_WITH_IMAGE}
          onClick={handleClickOption}
          isActive={LineMenuTemplate.DEFAULT_WITH_IMAGE === selectedTemplate}
        >
          <FiveBoxes bgImageUrl={DEFAULT_LINE_RICH_MENU.menuUrl} />
        </Option>
      </TemplateOptionsWrapper>

      <div className="d-flex justify-content-between mt-4">
        <Button
          type="button"
          color="secondary"
          onClick={() => {
            setSelectedTemplate(value);
            onCancel();
          }}
        >
          {t("CANCEL")}
        </Button>
        <Button
          disabled={!selectedTemplate}
          type="button"
          onClick={() => {
            onSubmit(selectedTemplate);
            onCancel();
          }}
        >
          {t("SELECT")}
        </Button>
      </div>
    </Card>
  );
};

export default TemplateSelector;
