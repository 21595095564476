import * as React from "react";

const IcSlipWarning = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <g>
      <path
        fill="#2f4069"
        d="M22.9,17.6A6.18,6.18,0,1,1,28.45,7.42V2.11a1.35,1.35,0,0,0-2.25-1L24.11,3a.15.15,0,0,1-.2,0L21.06.4a1.34,1.34,0,0,0-1.81,0L16.4,2.94a.15.15,0,0,1-.2,0L13.35.4a1.34,1.34,0,0,0-1.81,0L8.69,3a.15.15,0,0,1-.2,0L6.39,1.09a1.35,1.35,0,0,0-1.9.11,1.34,1.34,0,0,0-.34.91V29.92a1.35,1.35,0,0,0,2.24,1l2.1-1.86a.15.15,0,0,1,.2,0l2.85,2.55a1.36,1.36,0,0,0,1.81,0l2.85-2.54a.13.13,0,0,1,.2,0l2.85,2.54a1.36,1.36,0,0,0,1.81,0l2.85-2.55a.15.15,0,0,1,.2,0l2.1,1.86a1.35,1.35,0,0,0,2.24-1V20.84L26,17.27A6.1,6.1,0,0,1,22.9,17.6ZM8.23,8.13H15.3a.61.61,0,0,1,0,1.21H8.23a.61.61,0,0,1,0-1.21ZM8.17,13h6.28a.62.62,0,0,1,0,1.21H8.17a.61.61,0,0,1,0-1.21Zm.13,4.83h7.93a.61.61,0,1,1,0,1.21H8.3a.61.61,0,1,1,0-1.21Zm15,6H8.3a.62.62,0,0,1-.64-.6.63.63,0,0,1,.64-.61h15a.62.62,0,0,1,.63.61A.62.62,0,0,1,23.28,23.83Z"
      />
      <path fill="#2f4069" d="M31,20.21,28,16a.63.63,0,0,0-.87-.13.62.62,0,0,0-.12.87L30,21a.62.62,0,1,0,1-.74Z" />
      <path
        fill="#2f4069"
        d="M24.55,6.55a4.95,4.95,0,1,0,4.16,5.62A5,5,0,0,0,24.55,6.55ZM27.49,12a3.71,3.71,0,1,1-3.12-4.22A3.71,3.71,0,0,1,27.49,12Z"
      />
      <path
        fill="#2f4069"
        d="M23.64,11.88h.45a.2.2,0,0,0,.22-.19V9.31a.21.21,0,0,0-.22-.2h-.45a.21.21,0,0,0-.22.2v2.38A.2.2,0,0,0,23.64,11.88Z"
      />
      <path fill="#2f4069" d="M23.91,12.46a.6.6,0,0,0-.6.6.6.6,0,0,0,1.19,0A.6.6,0,0,0,23.91,12.46Z" />
    </g>
  </svg>
);

export default IcSlipWarning;
