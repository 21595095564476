import React, { FC } from "react";
import { NumberFormatCustomWithoutPrefix } from "components/NumberFormatCustom";
import TextField from "./TextField.styled";
import { TextFieldPropsType } from "./index";

const NumberField: FC<TextFieldPropsType> = (props) => {
  return (
    <TextField
      {...props}
      type="text"
      InputProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: NumberFormatCustomWithoutPrefix as any,
        // eslint-disable-next-line react/destructuring-assignment
        ...props?.InputProps,
      }}
    />
  );
};

export default NumberField;
