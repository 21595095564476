import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { Pages } from "@1convo/web-console";

import useComponentDidMount from "utils/hooks/useComponentDidMount";
import { getToken } from "utils/tokenUtil";

export interface SelectProjectProps {
  isShowModalCongratulations: boolean;
  isShowModalPrivacyPolicy: boolean;
}

const SelectProject: FC<SelectProjectProps> = ({ isShowModalCongratulations, isShowModalPrivacyPolicy }) => {
  const history = useHistory();

  useComponentDidMount(() => {
    const token = getToken();

    if (!token) {
      history.push("/");
    }
  });

  return (
    <Pages.CreateStore
      isShowModalCongratulations={isShowModalCongratulations}
      isShowModalPrivacyPolicy={isShowModalPrivacyPolicy}
    />
  );
};

export default SelectProject;
