import styled, { css } from "styled-components";
import Grid from "components/Grid";
import Typography from "components/Typography";

type RectangleButtonPropsType = {
  isSelected: boolean;
  isActive?: boolean;
};

export const RectangleButton = styled.div<RectangleButtonPropsType>`
  border: solid 0.5px ${({ theme, isSelected }) => (isSelected ? theme.COLORS.LightBlue : "transparent")};
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.COLORS.White : "transparent")};
  border-radius: 8px;
  padding: 12px 10px;
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
      border-color: ${({ theme }) => theme.COLORS.Primary};
    `}
`;

export const HistoryItem = styled(Grid)`
  padding: 16px 0;
  border-bottom: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
`;

export const InvoiceModalHeader = styled.div`
  height: 57px;
  background: ${({ theme }) => `${theme.COLORS.Primary}`};
  color: ${({ theme }) => `${theme.COLORS.LightWhite}`};
  text-transform: uppercase;
  display: flex;
  align-content: center;
  & > div {
    align-self: center;
  }
`;

export const TextButton = styled(Typography)`
  cursor: pointer;
  :hover {
    color: ${({ theme }) => `${theme.COLORS.Primary}`};
  }
`;

export const TextInvoiceNumber = styled(Typography)`
  // TODO: [DP-2793]: Disable billing section
  /* cursor: pointer;
  :hover {
    color: ${({ theme }) => `${theme.COLORS.Primary}`};
    text-decoration: underline;
  } */
`;

export const FilterIconWrapper = styled.button`
  border: none;
  outline: none;
  background: none;
  width: 18px;
  height: 18px;
  padding: 0 !important;
  cursor: pointer;
`;
