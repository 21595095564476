import { ProductOptionChoiceType, ProductOptionType } from "types/Product";

const getProductOptions = (productOptions?: ProductOptionType[]) => {
  if (!productOptions || !productOptions.length) {
    return null;
  }

  const activeOptions = productOptions.filter((option: { isActive: boolean }) => option.isActive);
  const productOptionsText = activeOptions
    .map((option: { choices: ProductOptionChoiceType[] }) => {
      const selectedChoices = option.choices.filter(
        (choice: ProductOptionChoiceType) => choice.isSelected && choice.isActive,
      );
      const selectedChoicesText = selectedChoices.map((choice: { name: string }) => choice.name);

      return selectedChoicesText.filter(Boolean).join(", ");
    })
    .filter(Boolean)
    .join(", ");
  // there is no selected choice
  if (!productOptionsText) {
    return null;
  }

  return productOptionsText;
};

export default getProductOptions;
