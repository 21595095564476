import { FaqQuestionType, DuplicatedQuestionType, FaqType } from "types/AISetting";
import { NEW_FAQ_QUESTION_DUPLICATED_ERROR, NEW_FAQ_QUESTION_DUPLICATED } from "constants/Faq";

import { GetIsDuplicatedQuestionType } from "../helpers";
import { CheckDuplicatedQuestionType } from "../../hooks/useCheckDuplicatedQuestionFromAll";

type UseValidateDuplicatedQuestionType = (
  faqId: string,
  getIsDuplicatedQuestion: GetIsDuplicatedQuestionType,
  i18n: Function,
  checkDuplicatedQuestionFromAll?: CheckDuplicatedQuestionType,
) => ValidateDuplicatedQuestionType;

type ValidateDuplicatedQuestionType = (
  newQuestion: string,
  newQuestionIndex: number,
  allQuestions: FaqQuestionType[],
  questionFieldName: keyof FaqType,
) => void;

const useValidateDuplicatedQuestion: UseValidateDuplicatedQuestionType = (
  faqId,
  getIsDuplicatedQuestion,
  i18n,
  checkDuplicatedQuestionFromAll,
) => {
  const validateDuplicatedQuestion: ValidateDuplicatedQuestionType = (
    newQuestion,
    newQuestionIndex,
    allQuestions,
    questionFieldName,
  ) => {
    // start search duplicated question in this faq
    const isDuplicatedInThisFaq = getIsDuplicatedQuestion(allQuestions, newQuestion, newQuestionIndex);

    if (isDuplicatedInThisFaq) {
      throw new Error(i18n(NEW_FAQ_QUESTION_DUPLICATED, { question: newQuestion }));
    }
    // end search duplicated question in this faq

    // start search duplicated question in every faq
    const duplicatedQuestion =
      checkDuplicatedQuestionFromAll && checkDuplicatedQuestionFromAll(newQuestion, questionFieldName, { faqId });

    if (duplicatedQuestion) {
      const { firstQuestion, index } = duplicatedQuestion as DuplicatedQuestionType;

      throw new Error(
        i18n(NEW_FAQ_QUESTION_DUPLICATED_ERROR, {
          order: `${index + 1}`,
          firstQuestion,
          question: newQuestion,
        }),
      );
    }
    // end search duplicated question in every faq
  };

  return validateDuplicatedQuestion;
};

export default useValidateDuplicatedQuestion;
