import { gql } from "@apollo/client";

export const SYNONYMS = gql`
  query synonyms($projectId: ID!, $offset: Int, $limit: Int, $type: SynonymsType, $filter: SynonymsFilterInput) {
    synonyms(projectId: $projectId, offset: $offset, limit: $limit, type: $type, filter: $filter) {
      total
      results {
        word
        synonyms
        image
        children {
          word
          synonyms
        }
      }
    }
  }
`;
