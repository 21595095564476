import React, { useState, MouseEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import Typography from "components/Typography";
import Modal from "components/Modal";
import TextField from "components/TextField";
import Button from "components/Button";
import Select from "components/Select";
import ErrorText from "components/ErrorText";

import { INVITE_MEMBER } from "graphql/setting/mutation";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { COLORS } from "constants/Colors";
import { ProjectIdType } from "types/Project";
import { ValueType, OptionType } from "react-select";
import get from "lodash/get";
import { notifySuccess, notifyError } from "utils/notify";
import { INVITED_MEMBERS, MEMBERS } from "graphql/setting/query";
import { MEMBER_ROLE, Member } from "types/Setting";
import mapErrorMessage from "./mapErrorMessage";
import schema from "./schema";

type AddMemberModalPropsType = {
  isOpen: boolean;
  isOwner: boolean;
  onClose: (event: MouseEvent) => void;
  closeModal: () => void;
  refetchMembers: () => void;
  members: Member[];
};

export const AddMemberModal: React.FC<AddMemberModalPropsType> = (props) => {
  const { t } = useTranslation();
  const { onClose, closeModal, isOpen, refetchMembers, members, isOwner } = props;
  const { projectId } = useParams<ProjectIdType>();

  const [memberEmail, setMemberEmailValue] = useState("");
  const [role, setRole] = useState<MEMBER_ROLE>(MEMBER_ROLE.ADMIN);

  useEffect(() => {
    if (!isOpen) {
      // clear all state to initial when modal is closed
      setMemberEmailValue("");
      setRole(MEMBER_ROLE.ADMIN);
    }
  }, [isOpen]);

  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const isEmailExist = (email: string) => {
    return members.some((member: Member) => member.email === email);
  };

  const [inviteMember] = useMutation(INVITE_MEMBER, {
    onError: (error) => {
      const errorMessage = mapErrorMessage(error);
      notifyError(t(errorMessage));
    },
    onCompleted: () => {
      setMemberEmailValue("");
      refetchMembers();
      notifySuccess(t("Member invited"));
      closeModal();
    },
    update(cache, { data: { inviteMember: invitedMember } }) {
      const isInvitedMemberExistInSystem = Boolean(invitedMember.id);
      const cacheQuery = isInvitedMemberExistInSystem ? MEMBERS : INVITED_MEMBERS;

      const cacheInvitedMembers = cache.readQuery<{ members: Member[] }>({
        query: cacheQuery,
        variables: {
          projectId,
        },
      });

      if (cacheInvitedMembers && cacheInvitedMembers.members) {
        const updatedInvitedMembers = [...cacheInvitedMembers.members, invitedMember];

        cache.writeQuery({
          query: cacheQuery,
          variables: {
            projectId,
          },
          data: {
            members: updatedInvitedMembers,
          },
        });
      }
    },
  });

  const handleInviteMember = async () => {
    try {
      await schema.validate({ email: memberEmail && memberEmail.toLowerCase(), role });
      if (emailErrorMessage) {
        setEmailErrorMessage("");
      }

      inviteMember({
        variables: {
          projectId,
          email: memberEmail && memberEmail.toLowerCase(),
          role,
        },
      });
    } catch (error) {
      setEmailErrorMessage(error.message);
    }
  };

  const handleSelectRoleChange = (selectRoleValue: ValueType<OptionType>) => {
    setRole(get(selectRoleValue, "value", MEMBER_ROLE.ADMIN));
  };

  const optionRoleSelect = isOwner
    ? [
        {
          value: MEMBER_ROLE.ADMIN,
          label: t("Admin"),
        },
        {
          value: MEMBER_ROLE.OWNER,
          label: t("Owner"),
        },
      ]
    : [
        {
          value: MEMBER_ROLE.ADMIN,
          label: t("Admin"),
        },
      ];

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography variant="title2" className="px-4 pt-4">
        {t("Add new member")}
      </Typography>
      <Grid container className="p-4 mb-4" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="title10" color={COLORS.DarkMed}>
            {t("Email")}
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                className="mt-2"
                variant="outlined"
                placeholder={t("Email")}
                required
                fullWidth
                error={isEmailExist(memberEmail)}
                helperText={isEmailExist(memberEmail) && t("email already exist")}
                value={memberEmail}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setMemberEmailValue(e.target.value);
                }}
              />
              <div className="mt-2">
                <ErrorText>{t(emailErrorMessage)}</ErrorText>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mt-4">
          <Typography variant="title10" color={COLORS.DarkMed}>
            {t("Role")}
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <Select
                isSearchable={false}
                className="mt-4"
                defaultValue={[optionRoleSelect[0]]}
                onChange={handleSelectRoleChange}
                placeholder={t("Please select role")}
                options={optionRoleSelect}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} className="text-right px-1">
          <Button color="secondary" className="mt-4" fullWidth onClick={closeModal}>
            {t("Cancel")}
          </Button>
        </Grid>
        <Grid item xs={6} className="px-1">
          <Button
            className="mt-4"
            fullWidth
            onClick={handleInviteMember}
            disabled={!memberEmail.length || isEmailExist(memberEmail)}
          >
            {t("Submit")}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
