import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import get from "lodash/get";
import {
  MAXIMUM_FOOD_PRODUCT_IMAGE_UPLOAD,
  MAXIMUM_PRODUCT_NAME_STRING_LENGTH,
  MAXIMUM_PRODUCT_DESCRIPTION_STRING_LENGTH,
} from "config";
import COLORS from "constants/Colors";
import ImageUpload from "components/ImageUpload";

import Typography from "components/Typography";
import Card from "components/Card";
import ConfirmationModal from "components/ConfirmationModal";
import Grid from "components/Grid";
import TextField from "components/TextField";
import Switch from "components/Switch";
import Button from "components/Button";
import ErrorText from "components/ErrorText";

import { Category } from "types";
import { ProductImagesType, ProductPropertiesType, AddProductSKUType } from "types/Product";
import { ImageUrl } from "types/Image";
import nanoid from "utils/nanoid";
import useToggle from "utils/hooks/useToggle";
import { CategoryContentWrapper, ProductCardWrapper } from "./styled";
import { DeleteButton } from "../../StyledComponent/product.styled";

type AddProductDetailPropsType = {
  onDelete: () => void;
  propertyListData: ProductPropertiesType[];
  categoryData: Category.CategoryType[];
  handleValidateForm: Function;
  handleSubmitForm: Function;
  isEditMode?: boolean;
  productBrand: string;
  productCode: string;
  productDescription: string;
  productImage: ProductImagesType[];
  productIsActive: boolean;
  productIsFree: boolean;
  productName: string;
  productPrice: number;
  productUnit: string;
  selectedCategoryId: string;
  productSKUs: AddProductSKUType[];
  setProductSKUs: Function;
  setProductBrand: Function;
  setProductCode: Function;
  setProductDescription: Function;
  setProductImage: Function;
  setProductIsActive: Function;
  setProductIsFree: Function;
  setProductName: Function;
  setProductPrice: Function;
  setProductUnit: Function;
  setPropertyListData: Function;
  setSelectedCategoryId: Function;
  setValue: Function;
  setVariationListData: Function;
};

export const ProductDetailsTab: React.FC<AddProductDetailPropsType> = (props) => {
  const { t } = useTranslation();
  const {
    categoryData,
    onDelete,
    handleValidateForm,
    productCode,
    productDescription,
    productImage,
    productIsActive,
    productIsFree,
    productName,
    productPrice,
    propertyListData,
    selectedCategoryId,
    setProductCode,
    setProductDescription,
    setProductImage,
    setProductIsActive,
    setProductIsFree,
    setProductName,
    setProductPrice,
    setSelectedCategoryId,
    setValue,
    isEditMode,
    productSKUs,
    setProductSKUs,
    handleSubmitForm,
  } = props;

  const { errors } = useFormContext();
  const {
    isOpen: isDeleteModalOpen,
    handleToggle: handleToggleDeleteModal,
    handleClose: handleCloseDeleteModal,
  } = useToggle();

  const handleSelectedCategory = (categoryId: string) => {
    setValue("selectedCategoryId", categoryId);
    setSelectedCategoryId(categoryId);
  };

  const handleAddImage = (imageSources: ImageUrl[]) => {
    imageSources.forEach(({ publicUrl }: ImageUrl) => {
      const tempImageList = productImage.map((imageItem: ProductImagesType) => {
        const returnImageItem = { ...imageItem };
        return returnImageItem;
      });
      tempImageList.push({ nanoid: nanoid(), src: publicUrl });
      setProductImage(tempImageList);
    });
  };

  const handleRemoveImage = (imagenanoid: string) => {
    const tempImageList = productImage.filter((imageItem: ProductImagesType) => imageItem.nanoid !== imagenanoid);
    setProductImage(tempImageList);
  };

  const handleSetPrimaryImage = (imageId: string) => {
    const secondaryImageList = productImage.filter((imageItem: ProductImagesType) => imageItem.nanoid !== imageId);
    const primaryImageList = productImage.filter((imageItem: ProductImagesType) => imageItem.nanoid === imageId);
    const tempImageList = [...primaryImageList, ...secondaryImageList];
    setProductImage(tempImageList);
  };

  const setAllSKUsDisableTo = (value: boolean) => {
    const newProductSKUs = productSKUs.map((productSKU: AddProductSKUType) => {
      const returnProductSKUs = { ...productSKU };
      returnProductSKUs.selected = Boolean(value);
      return returnProductSKUs;
    });
    setProductSKUs(newProductSKUs);
  };

  const setAllSKUsIsFreeTo = (value: boolean) => {
    const newProductSKUs = productSKUs.map((productSKU: AddProductSKUType) => {
      const returnProductSKUs = { ...productSKU };
      returnProductSKUs.isFree = Boolean(value);
      return returnProductSKUs;
    });
    setProductSKUs(newProductSKUs);
  };

  const handleProductNameChange = (value: string) => {
    setValue("productName", value);
    setProductName(value);
  };

  return (
    <>
      <ProductCardWrapper>
        <Card borderRadius={0} noShadow>
          <Grid container justify="space-between" className="my-4">
            <Typography variant="title2">{t("Food details")}</Typography>
            <Grid item>
              <Grid container alignItems="center">
                <Typography variant="body4" className="px-2" color={COLORS.DarkMed}>
                  {(
                    productSKUs.length
                      ? productSKUs.some((productSKU) => productSKU.selected === true)
                      : productIsActive
                  )
                    ? t("Active")
                    : t("Disabled")}
                </Typography>
                <Switch
                  data-cy="toggleProduct"
                  checked={
                    productSKUs && productSKUs.length
                      ? productSKUs.some((productSKU) => productSKU.selected === true)
                      : productIsActive
                  }
                  onChange={(event: { target: { checked: boolean } }) => {
                    setProductIsActive(event.target.checked);
                    setAllSKUsDisableTo(event.target.checked);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container justify="space-between" className="mt-2">
            <Typography variant="body4" color={COLORS.DarkMed}>
              {t("Food image")}
            </Typography>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <CategoryContentWrapper className="d-flex">
                {productImage.length < MAXIMUM_FOOD_PRODUCT_IMAGE_UPLOAD && (
                  <ImageUpload
                    isShowLabel
                    onChange={handleAddImage}
                    image=""
                    multiple
                    limitItem={MAXIMUM_FOOD_PRODUCT_IMAGE_UPLOAD - productImage.length}
                  />
                )}

                {productImage.map((image, index) => {
                  return (
                    <div className="d-flex pl-2" key={image.src}>
                      <ImageUpload
                        isShowLabel
                        isPrimary={index === 0}
                        handleSetPrimaryImage={handleSetPrimaryImage}
                        onChange={(imageUrls: ImageUrl[]) => {
                          if (!imageUrls.length) {
                            handleRemoveImage(image.nanoid);
                          }
                        }}
                        image={image.src}
                        imageId={image.nanoid}
                      />
                    </div>
                  );
                })}
              </CategoryContentWrapper>
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            <Typography variant="body4" className="mt-2" color={COLORS.DarkMed}>
              {t("Food name")}
            </Typography>
            <Typography variant="body4" className="mt-2 mr-2 ml-2" color={COLORS.DarkMed}>
              {productName.length} / {MAXIMUM_PRODUCT_NAME_STRING_LENGTH}
            </Typography>
          </Grid>
          <Grid container>
            <Grid container item xs={12}>
              <Grid item xs={12} className="my-2">
                <TextField
                  data-cy="productName"
                  fullWidth
                  placeholder={t("Product name")}
                  value={productName}
                  error={Boolean(errors.productName)}
                  helperText={t(get(errors, "productName.message"))}
                  onChange={(event) => {
                    handleProductNameChange(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Typography variant="body4" className="py-2" color={COLORS.DarkMed}>
                {t("Categories")}
              </Typography>

              <Grid item container xs={12}>
                <CategoryContentWrapper>
                  {categoryData &&
                    categoryData.map((category: Category.CategoryType) => {
                      return (
                        <Button
                          isSelected={selectedCategoryId === category.id}
                          color="secondary"
                          borderRadius="8px"
                          key={category.id}
                          className="m-1"
                          onClick={() => handleSelectedCategory(category.id)}
                        >
                          {category.name}
                        </Button>
                      );
                    })}
                </CategoryContentWrapper>
                {errors.selectedCategoryId && <ErrorText>{t(get(errors, "selectedCategoryId.message"))}</ErrorText>}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Typography variant="body4" className="mt-2" color={COLORS.DarkMed}>
                  {t("Product Code")}
                </Typography>
              </Grid>
              <Grid item xs={12} className="my-2">
                <TextField
                  data-cy="productCode"
                  fullWidth
                  placeholder={t("Product Code")}
                  value={productCode}
                  error={Boolean(errors.productCode)}
                  helperText={t(get(errors, "productCode.message"))}
                  onChange={(event) => {
                    setValue("productCode", event.target.value);
                    setProductCode(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid container justify="space-between">
                <Typography variant="body4" className="mt-2" color={COLORS.DarkMed}>
                  {t("Product Description")} - {t("optional")}
                </Typography>
                <Typography variant="body4" className="mt-2 mx-2" color={COLORS.DarkMed}>
                  {productDescription ? productDescription.length : 0} / {MAXIMUM_PRODUCT_DESCRIPTION_STRING_LENGTH}
                </Typography>
              </Grid>
              <Grid item xs={12} className="my-2">
                <TextField
                  data-cy="productDescription"
                  multiline
                  fullWidth
                  placeholder={t("Product Description")}
                  value={productDescription}
                  onChange={(event) => {
                    setProductDescription(event.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Typography variant="body4" className="mt-2" color={COLORS.DarkMed}>
                  {t("Price")}
                </Typography>
              </Grid>
              <Grid item xs={12} className="my-2">
                <TextField
                  data-cy="productPrice"
                  error={Boolean(errors.productPrice)}
                  helperText={t(get(errors, "productPrice.message"))}
                  onChange={(event) => {
                    setValue("productPrice", +parseFloat(event.target.value).toFixed(2) || 0.0);
                    setProductPrice(+parseFloat(event.target.value).toFixed(2) || 0.0);
                  }}
                  value={productPrice}
                  fullWidth
                  placeholder={t("Price")}
                  type="price"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container justify="space-between" className="my-4">
            <Typography variant="body4" className="mt-2" color={COLORS.DarkMed}>
              {t("This product is free")}
            </Typography>
            <Switch
              data-cy="freeProduct"
              checked={
                productSKUs && productSKUs.length
                  ? productSKUs.every((productSKU) => productSKU.isFree === true)
                  : productIsFree
              }
              onChange={(event: { target: { checked: boolean } }) => {
                setProductIsFree(event.target.checked);
                setAllSKUsIsFreeTo(event.target.checked);
              }}
            />
          </Grid>
        </Card>

        {!isEditMode && (
          <Grid item xs={12}>
            <Button
              className="m-4"
              fullWidth
              onClick={() => {
                setValue("selectedCategoryId", selectedCategoryId);
                setValue("properties", propertyListData);
                setValue("productSKUs", productSKUs);
                setValue("productName", productName || "");
                setValue("productCode", productCode || "");
                setValue("productPrice", productPrice);
                handleValidateForm();
              }}
            >
              {t("product.addFoodForm.next")} &gt;
            </Button>
          </Grid>
        )}

        {isEditMode && (
          <Grid item xs={12} className="mt-4 px-3">
            <DeleteButton fullWidth onClick={handleToggleDeleteModal}>
              {t("Delete Product")}
            </DeleteButton>
          </Grid>
        )}

        {isEditMode && Boolean(productSKUs && productSKUs.length !== 0) && (
          <Grid item xs={12} className="my-4 px-3">
            <Button
              fullWidth
              onClick={() => {
                setValue("selectedCategoryId", selectedCategoryId);
                setValue("properties", propertyListData);
                setValue("productSKUs", productSKUs);
                setValue("productName", productName || "");
                setValue("productCode", productCode || "");
                setValue("productPrice", productPrice);
                handleSubmitForm();
              }}
            >
              {t("Save")}
            </Button>
          </Grid>
        )}
      </ProductCardWrapper>

      <ConfirmationModal
        title={t("Are you sure you want to remove ?")}
        isOpen={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onSubmit={onDelete}
      />
    </>
  );
};
