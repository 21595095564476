import React, { FC, useState, useEffect } from "react";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";

import { IcCalendar, IcClose } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";
import ErrorText from "components/ErrorText";
import Grid from "components/Grid";
import DateTimeRangePicker from "components/DateTimeRangePicker";

import { DATE_TIME_PICKER_FORMAT, DASHBOARD_DATE_TIME_FORMAT, TIME_FORMAT } from "constants/DateTimeFormat";
import COLORS from "constants/Colors";

import useOrientation, { OrientationType } from "hooks/useOrientation";
import {
  HeaderText,
  MobileDatePickerWrapper,
  DateRangeContainer,
  DateRangePickerHeader,
  RectangleButton,
  PopperBox,
  ApplyButtonWrapper,
  ApplyButton,
  PopupBackground,
  DateRagePickerModal,
} from "../index.styled";

type CalendarPickerType = {
  startTime: string;
  endTime: string;
  onApply: (start: Moment, end: Moment) => void;
};

const CalendarPicker: FC<CalendarPickerType> = (props) => {
  const { startTime, endTime, onApply } = props;
  const { t } = useTranslation();
  const { orientation, screenSize } = useOrientation();
  const [isNativeSize, setIsNativeSize] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedStart, setSelectedStart] = useState<Moment>(moment());
  const [selectedEnd, setSelectedEnd] = useState<Moment>(moment());
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (screenSize.width < 768 || screenSize.height < 400 || orientation === OrientationType.Portrait) {
      setIsNativeSize(true);
      return;
    }
    setIsNativeSize(false);
  }, [orientation, screenSize, screenSize.height, screenSize.width]);

  useEffect(() => {
    const checkTimeRegex = new RegExp(/^\d{2}:\d{2}$/);
    const isStartWithOnlyTime = checkTimeRegex.test(startTime);
    const isEndWithOnlyTime = checkTimeRegex.test(endTime);

    const startDateTime = isStartWithOnlyTime
      ? moment(startTime, TIME_FORMAT)
      : moment(startTime, DASHBOARD_DATE_TIME_FORMAT);
    const endDateTime = isEndWithOnlyTime ? moment(endTime, TIME_FORMAT) : moment(endTime, DASHBOARD_DATE_TIME_FORMAT);

    setSelectedStart(startDateTime);
    setSelectedEnd(endDateTime);
  }, [endTime, startTime]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickApply = () => {
    const timesDiff = selectedEnd.diff(selectedStart);

    // end must be different more than 1 minute from start
    if (timesDiff < 60000) {
      setIsError(true);
    } else {
      onApply(selectedStart, selectedEnd);
      setAnchorEl(null);
    }
  };

  const handleSetStartDateTime = (timeString: string | null) => {
    if (timeString) {
      setSelectedStart(moment(timeString));
      setIsError(false);
    }
  };

  const handleSetEndDateTime = (timeString: string | null) => {
    if (timeString) {
      setSelectedEnd(moment(timeString));
      setIsError(false);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "spring-popper" : undefined;

  return (
    <>
      <Grid item>
        <RectangleButton isSelected isActive={open} aria-describedby={id} onClick={handleClick}>
          <SvgIcon component={IcCalendar} className="p-0" fontSize="small" htmlColor={COLORS.DarkMed} />
        </RectangleButton>
      </Grid>
      {isNativeSize ? (
        <DateRagePickerModal open={open} onClose={() => setAnchorEl(null)}>
          <MobileDatePickerWrapper>
            <DateRangePickerHeader>
              <Grid container alignItems="center">
                <HeaderText item xs={10}>
                  {t("dashboard.calendar.header")}
                </HeaderText>
                <HeaderText className="close" item xs={2}>
                  <SvgIcon
                    onClick={() => setAnchorEl(null)}
                    className="ml-1 mt-1"
                    component={IcClose}
                    fontSize="large"
                  />
                </HeaderText>
              </Grid>
            </DateRangePickerHeader>
            {isError && (
              <Grid item xs={12} className="px-1 pb-2">
                <ErrorText>{t("dashboard.calendar.error.limit")}</ErrorText>
              </Grid>
            )}
            <DateRangeContainer container alignItems="center">
              <Grid item xs={12}>
                <DateTimeRangePicker
                  disabledFuture
                  format={DATE_TIME_PICKER_FORMAT}
                  selectedDateTime={{ from: moment(selectedStart).toDate(), to: moment(selectedEnd).toDate() }}
                  setEndTime={handleSetEndDateTime}
                  setStartTime={handleSetStartDateTime}
                  isNativeSize={isNativeSize}
                />
              </Grid>
            </DateRangeContainer>
            <Grid container alignItems="center">
              <ApplyButtonWrapper className="native" item>
                <ApplyButton size="small" className="native" onClick={handleClickApply}>
                  {t("dashboard.calendar.apply")}
                </ApplyButton>
              </ApplyButtonWrapper>
            </Grid>
          </MobileDatePickerWrapper>
        </DateRagePickerModal>
      ) : (
        <>
          {open && <PopupBackground onClick={() => setAnchorEl(null)} />}
          <PopperBox id={id} open={open} anchorEl={anchorEl}>
            {isError && (
              <Grid item xs={12} className="px-1 pb-2">
                <ErrorText>{t("dashboard.calendar.error.limit")}</ErrorText>
              </Grid>
            )}
            <Grid container alignItems="center" justify="center">
              <Grid item>
                <DateTimeRangePicker
                  disabledFuture
                  format={DATE_TIME_PICKER_FORMAT}
                  selectedDateTime={{ from: moment(selectedStart).toDate(), to: moment(selectedEnd).toDate() }}
                  setEndTime={handleSetEndDateTime}
                  setStartTime={handleSetStartDateTime}
                  isNativeSize={isNativeSize}
                />
              </Grid>
            </Grid>{" "}
            <Grid container alignItems="center">
              <ApplyButtonWrapper item>
                <ApplyButton size="small" onClick={handleClickApply}>
                  {t("dashboard.calendar.apply")}
                </ApplyButton>
              </ApplyButtonWrapper>
            </Grid>
          </PopperBox>
        </>
      )}
    </>
  );
};

export default CalendarPicker;
