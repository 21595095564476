const payments = {
  en: {
    "payments.title": "Payment",
  },
  th: {
    "payments.title": "ช่องทางการจ่ายเงิน",
  },
};

export default payments;
