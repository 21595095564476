import { IcAiWelcomemessage, IcAiFaq, IcAiProductname as IcAiSynonym, IcAiGeneral } from "components/SvgIcons";
import IcReplyShortcut from "components/SvgIcons/IcReplyShortcut";
import {
  CHAT_TEMPLATE,
  GENERAL_SETTING,
  SPECIALIZED_KNOWLEDGE,
  SYNONYM,
  UP_SELLING,
  WELCOME_MESSAGE,
  RESPONSE_MESSAGE,
} from "constants/Router";

export const TAB_CONFIGS = [
  {
    id: GENERAL_SETTING,
    title: "Bot setting",
    svgIcon: IcAiGeneral,
  },
  {
    id: WELCOME_MESSAGE,
    title: "Welcome message",
    svgIcon: IcAiWelcomemessage,
  },
  {
    id: SPECIALIZED_KNOWLEDGE,
    title: "Specialized knowledge",
    svgIcon: IcAiFaq,
  },
  {
    id: SYNONYM,
    title: "Synonym",
    svgIcon: IcAiSynonym,
  },
  {
    id: UP_SELLING,
    title: "upSelling.title",
    svgIcon: IcAiSynonym,
  },
  {
    id: CHAT_TEMPLATE,
    title: "REPLY_SHORTCUT",
    svgIcon: IcReplyShortcut,
  },
  {
    id: RESPONSE_MESSAGE,
    title: "RESPONSE_MESSAGE",
    svgIcon: IcReplyShortcut,
  },
];
