import { gql } from "@apollo/client";

export const NEW_OFFLINE_ORDER = gql`
  mutation newOfflineOrder(
    $additionalDiscount: Float
    $deliverySchedule: String
    $isCODPayment: Boolean
    $isDigitalOrder: Boolean
    $isPickupOrder: Boolean
    $offlineCustomer: OfflineCustomerInput!
    $offlineStoreId: ID
    $orderProducts: [ItemInput]!
    $projectId: ID!
    $shippingId: ID
    $shippingMethod: ShippingMethod
    $shippingPrice: Float
  ) {
    newOfflineOrder(
      additionalDiscount: $additionalDiscount
      deliverySchedule: $deliverySchedule
      isCODPayment: $isCODPayment
      isDigitalOrder: $isDigitalOrder
      isPickupOrder: $isPickupOrder
      offlineCustomer: $offlineCustomer
      offlineStoreId: $offlineStoreId
      orderProducts: $orderProducts
      projectId: $projectId
      shippingId: $shippingId
      shippingMethod: $shippingMethod
      shippingPrice: $shippingPrice
    ) {
      id
      orderNumber
      updatedAt
    }
  }
`;
