import { useState, useCallback } from "react";
import produce from "immer";
import { NewProductItemProps } from "types/Product";
import { PromotionProductSKUType } from "types/Promotion";
import filterSelectedProducts from "./filterSelectedProducts";

export type SelectProducts = (newSelectedProducts: NewProductItemProps[]) => void;
export type DeleteSelectedProduct = (categoryId?: string, productId?: string, SKUId?: string) => void;

const useSelectedProducts = () => {
  const [selectedSelectedProducts, setSelectedSelectedProducts] = useState<NewProductItemProps[]>([]);

  const deleteSelectedProductByCategoryId = useCallback(
    (categoryId: string) => {
      const filteredSelectedSelectedProducts = selectedSelectedProducts.filter(
        (selectedSelectedProduct) => selectedSelectedProduct.categories[0]?.id !== categoryId,
      );

      setSelectedSelectedProducts(filteredSelectedSelectedProducts);
    },
    [selectedSelectedProducts],
  );

  const deleteSelectedProductByProductId = useCallback(
    (productId: string) => {
      const newSelectedSelectedProducts = produce(selectedSelectedProducts, (draft) => {
        const index = selectedSelectedProducts.findIndex(
          (selectedSelectedProduct) => selectedSelectedProduct.id === productId,
        );

        draft.splice(index, 1);
      });

      setSelectedSelectedProducts(newSelectedSelectedProducts);
    },
    [selectedSelectedProducts],
  );

  const deleteSelectedProductBySKUId = useCallback(
    (productId: string, SKUId: string) => {
      const newSelectedSelectedProducts = produce(selectedSelectedProducts, (draft) => {
        const productIndex = selectedSelectedProducts.findIndex(
          (selectedSelectedProduct) => selectedSelectedProduct.id === productId,
        );

        const SKUIndex = selectedSelectedProducts[productIndex].productSKUs.findIndex(
          (productSKU) => productSKU.id === SKUId,
        );

        if (selectedSelectedProducts[productIndex].productSKUs.length === 1) {
          draft.splice(productIndex, 1);
        } else {
          draft[productIndex].productSKUs.splice(SKUIndex, 1);
        }
      });
      setSelectedSelectedProducts(newSelectedSelectedProducts);
    },
    [selectedSelectedProducts],
  );

  const deleteSelectedProduct = useCallback(
    (categoryId?: string, productId?: string, SKUId?: string) => {
      if (productId && SKUId) {
        deleteSelectedProductBySKUId(productId, SKUId);
        return;
      }

      if (productId) {
        deleteSelectedProductByProductId(productId);
        return;
      }

      if (categoryId) {
        deleteSelectedProductByCategoryId(categoryId);
      }
    },
    [deleteSelectedProductByCategoryId, deleteSelectedProductByProductId, deleteSelectedProductBySKUId],
  );

  const selectProducts = useCallback(
    (newSelectedProducts: NewProductItemProps[]) => {
      const newSelectedSelectedProducts = filterSelectedProducts(newSelectedProducts, selectedSelectedProducts);
      setSelectedSelectedProducts(newSelectedSelectedProducts);
    },
    [selectedSelectedProducts],
  );

  const convertSelectedProducts = useCallback((selectedProducts: PromotionProductSKUType[]) => {
    const newSelectedProducts: NewProductItemProps[] = [];
    selectedProducts.forEach((selectedProduct) => {
      if (!selectedProduct) return;
      const newSKU = {
        id: selectedProduct.id,
        productCode: selectedProduct.productCode,
        cfCode: selectedProduct?.cfCode || "",
        price: 0,
        image: { src: selectedProduct.image },
        selected: selectedProduct.selected,
        deleted: false,
        isFree: selectedProduct.isFree,
        isChecked: true,
        productType: selectedProduct.productType,
        inventory: 0,
        reserved: 0,
        productId: selectedProduct.product.id,
        weight: 0,
      };

      const index = newSelectedProducts.findIndex(
        (newSelectedProduct) => newSelectedProduct.id === selectedProduct.product.id,
      );

      if (index >= 0) {
        newSelectedProducts[index].productSKUs.push(newSKU);
      } else {
        newSelectedProducts.push({
          id: selectedProduct.product.id,
          cfCode: selectedProduct.product?.cfCode || "",
          type: selectedProduct.product.type,
          defaultPrice: 0,
          name: selectedProduct.product.name,
          images: selectedProduct.product.images,
          productSKUs: [newSKU],
          categories: selectedProduct.product.categories,
        });
      }
    });

    setSelectedSelectedProducts(newSelectedProducts);
  }, []);

  return {
    convertSelectedProducts,
    deleteSelectedProduct,
    filterSelectedProducts,
    selectedSelectedProducts,
    selectProducts,
    setSelectedSelectedProducts,
  };
};

export default useSelectedProducts;
