import styled from "styled-components";

const BackButton = styled.button`
  display: flex;
  align-items: center;
  margin: 16px 0 0 16px;
  outline: none;
  border: none;
  background-color: transparent;
`;

export default BackButton;
