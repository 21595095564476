import { FaqType, EditedFaqType } from "types/AISetting";

export const getFaqStatus = (score: number) => {
  // status: great - good - average - poor - bad
  let status;
  if (score >= 80) {
    status = "great";
  } else if (score < 80 && score >= 60) {
    status = "good";
  } else if (score < 60 && score >= 40) {
    status = "average";
  } else if (score < 40 && score >= 20) {
    status = "poor";
  } else if (score < 20 && score >= 1) {
    status = "bad";
  } else {
    status = "none";
  }

  return status;
};

export const getUsageStatus = (score: number) => {
  // status:  normal - nearlyLimit - exceedLimit
  let status;
  if (score >= 100) {
    status = "exceedLimit";
  } else if (score < 100 && score >= 85) {
    status = "nearlyLimit";
  } else if (score < 85 && score > 0) {
    status = "normal";
  } else {
    status = "none";
  }

  return status;
};

export const mergeEditFaqs = (faqDatas: FaqType[], editFaqDatas: EditedFaqType[]): FaqType[] => {
  return faqDatas.map((faq) => {
    const matchedFaq = editFaqDatas.find(({ id }) => id === faq.id);

    if (!matchedFaq) {
      return faq;
    }

    return {
      ...faq,
      ...matchedFaq,
    };
  });
};
