import React from "react";
import moment from "moment";
import get from "lodash/get";
import { CellProps, useSortBy, useFlexLayout } from "react-table";

import COLORS from "constants/Colors";
import { BROADCAST_DATE_TIME_FORMAT } from "constants/DateTimeFormat";
import i18n from "utils/i18n";
import { convertPriceFormat } from "utils/common";
import Typography from "components/Typography";
import { IcEdit } from "components/SvgIcons";
import { CrawlerPlatform, CrawlerType } from "types/FacebookCrawler";
import { Bullet, EditButton, Status, Title } from "./styled";
import { DEFAULT_VALUE } from "../config";

export const tableHooks = [useSortBy, useFlexLayout];

const getTitleRowProps = (type: CrawlerType) => {
  if (type === CrawlerType.POST) {
    return {
      src: "/assets/icon/ic-facebook-color@3x.png",
      alt: "facebook-post-icon",
    };
  }

  if (type === CrawlerType.MEDIA) {
    return {
      src: "/assets/icon/ic-instagram.png",
      alt: "instagram-post-icon",
    };
  }
  if (type === CrawlerType.STORY) {
    return {
      src: "/assets/icon/ic-instagram-story@3x.png",
      alt: "instagram-story-icon",
    };
  }

  return null;
};

export const getColumns = <T extends object>(_isDesktop?: boolean, onClickTitle?: (id: string) => void) => {
  return [
    {
      disableSortBy: true,
      Header: i18n.t("facebookAutoEngagement.table.title"),
      accessor: "title",
      width: 150,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const id = get(cell, "row.original.id");
        const { type } = cell.row.original as { platform: CrawlerPlatform; type: CrawlerType };
        const titleRowProps = getTitleRowProps(type);
        const onClick = () => onClickTitle && onClickTitle(id);

        return (
          <Title>
            <div className="th d-flex align-items-center">
              {titleRowProps && (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img {...titleRowProps} height="16" width="16" className="mr-2" />
              )}
              <Typography
                variant="body1"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "100%",
                }}
              >
                {cell.value}
              </Typography>
              <EditButton onClick={onClick}>
                <IcEdit />
              </EditButton>
            </div>
          </Title>
        );
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("Status"),
      accessor: "isActive",
      width: 100,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <>
            <Bullet color={cell.value === true ? COLORS.Success : COLORS.DarkMed} />
            <Status>
              {i18n.t(
                cell.value === true ? "facebookAutoEngagement.table.active" : "facebookAutoEngagement.table.inactive",
              )}
            </Status>
          </>
        );
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("facebookAutoEngagement.table.postId"),
      accessor: "postId",
      width: 150,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return cell.value != null ? cell.value : DEFAULT_VALUE;
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("facebookAutoEngagement.table.reach"),
      accessor: "numberOfReach",
      width: 120,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return cell.value != null ? cell.value : DEFAULT_VALUE;
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("facebookAutoEngagement.table.engagement"),
      accessor: "numberOfEngagement",
      width: 120,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return cell.value != null ? cell.value : DEFAULT_VALUE;
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("facebookAutoEngagement.table.orderPaidConversionRate"),
      accessor: "orderPaidConversionRate",
      width: 120,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return cell.value != null ? cell.value : DEFAULT_VALUE;
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("facebookAutoEngagement.table.orderPaidConversionTotal"),
      accessor: "orderPaidConversionTotal",
      width: 120,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return cell.value != null ? convertPriceFormat(cell.value) : DEFAULT_VALUE;
      },
    },
    {
      disableSortBy: true,
      Header: i18n.t("Created at"),
      accessor: "createdAt",
      width: 150,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return cell.value != null ? moment(cell.value).format(BROADCAST_DATE_TIME_FORMAT) : DEFAULT_VALUE;
      },
    },
  ];
};
