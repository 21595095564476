// remove the first and last double quote
export const removeFirstAndLastDoubleQuote = (text: string) => {
  const regex = new RegExp('^"|"$', "g");
  return text.replace(regex, "");
};

export const stringify = (message: string | object) => {
  const text = JSON.stringify(message).replace(/\\"/g, '"');
  return removeFirstAndLastDoubleQuote(text);
};
