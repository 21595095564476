import { useQuery } from "@apollo/client";
import React, { FC, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import get from "lodash/get";

import Button, { FloatingButton } from "components/Button";
import CircularProgress from "components/CircularProgress";
import ConfirmationModal from "components/ConfirmationModal";
import Divider from "components/Divider";
import Grid from "components/Grid";
import ScrollToTop from "components/ScrollToTop";
import { IcAdd, IcArrowToTop } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";
import { StickyButtonContainer } from "components/StickyPanel";
import COLORS from "constants/Colors";
import { NEW_BRANCH_ID, MIN_LENGTH_BRANCH, BRANCH_NAME_INPUT_ID, BRANCH_LIMIT } from "config/store";

import { BranchesQueryType, HandleClickUpdateBranch } from "types/Branch";
import { Device } from "types/Device";
import { ProjectIdType } from "types/Project";
import { BRANCHES } from "graphql/branch/query";
import useDevice from "utils/hooks/useDevice";
import useToggle from "utils/hooks/useToggle";
import checkDuplicatedBranchName from "utils/branches/checkDuplicatedBranchName";
import autoFocus from "utils/branches/autoFocus";
import { notifyError } from "utils/notify";

import useScrollToTopElement from "utils/hooks/useScrollToTopElement";
import { DEFAULT_SCROLL } from "constants/Scroll";
import PageTitle from "components/PageTitle";
import BranchForm from "./BranchForm";
import useDeleteBranch from "./hooks/useDeleteBranch";
import useUpdateBranch from "./hooks/useUpdateBranch";
import useAddBranch from "./hooks/useAddBranch";
import { Wrapper, BranchCardList } from "./styled";

const Branches: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const selectedBranchId = useRef<string>();
  const device = useDevice();
  const isDesktop = device === Device.DESKTOP;

  const {
    isOpen: isOpenConfirmDeleteModal,
    handleClose: handleCloseModal,
    handleToggle: handleToggleModal,
  } = useToggle();

  const { loading, data } = useQuery<BranchesQueryType, ProjectIdType>(BRANCHES, {
    variables: {
      projectId,
    },
  });

  const { newBranch, addNewBranch, removeNewBranch } = useAddBranch(projectId);
  const { updateBranch, isLoading: isUpdateBranchLoading } = useUpdateBranch(projectId);
  const { scrollTopId, handleScrollToTop, isScroll } = useScrollToTopElement(
    DEFAULT_SCROLL.BRANCH_SCROLL_TOP,
    isDesktop,
  );

  const {
    deleteBranch,
    // isLoading: isDeleteBranchLoading
  } = useDeleteBranch(projectId);

  const branches = get(data, "offlineStores") || [];
  const isReachLimitBranch = branches.length >= BRANCH_LIMIT;
  const handleClickAddBranch = useCallback(() => {
    addNewBranch(() => {
      autoFocus(BRANCH_NAME_INPUT_ID);
    });
  }, [addNewBranch]);

  const handleClickUpdateBranch: HandleClickUpdateBranch = useCallback(
    async (branch, { onSuccess, onError }) => {
      const isDuplicatedBranchName = checkDuplicatedBranchName(branches, branch.name);
      const isNewBranch = !branch.id;

      if (isDuplicatedBranchName) {
        if (onError) {
          onError();
        }

        return;
      }

      if (isNewBranch && isReachLimitBranch) {
        return;
      }

      await updateBranch({
        variables: {
          projectId,
          offlineStoreInput: branch,
        },
      });

      if (onSuccess) {
        onSuccess();
      }

      if (isNewBranch) {
        removeNewBranch();
      }
    },
    [branches, isReachLimitBranch, projectId, removeNewBranch, updateBranch],
  );

  const handleClickDeleteBranch = useCallback(
    (branchId?: string) => {
      selectedBranchId.current = branchId;

      if (branches.length <= MIN_LENGTH_BRANCH) {
        notifyError(t("offlineStore.deleteBranch.warning"), { autoClose: 5000 });
        return;
      }

      handleToggleModal();
    },
    [branches.length, handleToggleModal, t],
  );
  const handleCloseConfirmationModal = useCallback(() => {
    selectedBranchId.current = undefined;
    handleCloseModal();
  }, [handleCloseModal]);

  const handleClickConfirmDeleteBranch = useCallback(async () => {
    if (!selectedBranchId.current) {
      if (newBranch) {
        // new branch don't have id
        removeNewBranch();
        handleCloseConfirmationModal();
      }

      return;
    }

    await deleteBranch({ variables: { projectId, offlineStoreId: selectedBranchId.current } });
    handleCloseConfirmationModal();
  }, [deleteBranch, handleCloseConfirmationModal, newBranch, projectId, removeNewBranch]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <CircularProgress size={40} />
      </div>
    );
  }

  return (
    <Wrapper>
      <Grid item container xs={12} id={scrollTopId}>
        <Grid item xs={6}>
          <PageTitle title={t("offlineStore.title")} />
        </Grid>
        <Grid item xs={6} container justify="flex-end" alignItems="center" alignContent="center" className="px-3">
          <Button color="primary" className="mx-2" size="medium" onClick={handleClickAddBranch}>
            <SvgIcon component={IcAdd} fontSize="small" />
            {isDesktop ? t("offlineStore.addBranch") : t("Add")}
          </Button>
        </Grid>
      </Grid>
      <Divider margin={isDesktop ? "16px 0 36px 0" : "0 20px 36px 20px"} />

      <BranchCardList>
        <Grid container>
          {newBranch && (
            <Grid item lg={6} sm={12} md={12} xs={12} className={isDesktop ? "px-2" : ""}>
              <BranchForm
                id={NEW_BRANCH_ID}
                data={newBranch}
                projectId={projectId}
                onClickDelete={handleClickDeleteBranch}
                onClickUpdate={handleClickUpdateBranch}
                isLoadingUpdate={isUpdateBranchLoading}
                isExpanded
                isNew
              />
            </Grid>
          )}
          {branches.map((branch) => (
            <Grid
              item
              lg={branches.length > 1 || Boolean(newBranch) ? 6 : 12}
              sm={12}
              md={12}
              xs={12}
              key={branch.id}
              className={isDesktop ? "px-2" : ""}
            >
              <BranchForm
                data={branch}
                projectId={projectId}
                onClickDelete={handleClickDeleteBranch}
                onClickUpdate={handleClickUpdateBranch}
                isLoadingUpdate={isUpdateBranchLoading}
              />
            </Grid>
          ))}
        </Grid>
      </BranchCardList>

      <ConfirmationModal
        title={`${t("offlineStore.remove.confirm")}`}
        isOpen={isOpenConfirmDeleteModal}
        onClose={handleCloseConfirmationModal}
        onSubmit={handleClickConfirmDeleteBranch}
      />

      <StickyButtonContainer className="px-2 py-3">
        {!isDesktop && isScroll && (
          <Grid item>
            <FloatingButton onClick={handleScrollToTop} data-cy="addButton" disabled={isReachLimitBranch}>
              <SvgIcon
                className="mb-1 ml-3 mt-3"
                component={IcArrowToTop}
                fontSize="default"
                htmlColor={COLORS.DarkMed}
              />
            </FloatingButton>
          </Grid>
        )}
        <Grid item>
          <ScrollToTop buttonColor="darkGray" />
        </Grid>
      </StickyButtonContainer>
    </Wrapper>
  );
};

export default Branches;
