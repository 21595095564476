import COLORS from "constants/Colors";
import { PackageEntityType } from "types/PlanUsage";

export const USAGE_STATUS_COLOR = {
  none: COLORS.LightBlue,
  exceedLimit: COLORS.ReportDown,
  nearlyLimit: COLORS.Warning,
  normal: COLORS.Primary,
};

export const FREE_PRICE = "Free";

export const FORMATTED_ENTITY_TYPE: Record<PackageEntityType, string> = {
  BASIC: "basic",
  C_COMMERCE: "cCommerce",
  IMAGE_SEARCH: "imageSearch",
  MAIN: "main",
};

export const ERRORS = {
  unSuccessfulDownGradeWithLowerQuota:
    "CHANGE_PACKAGE_FOR_NEXT_CYCLE:CANNOT_CHANGE_TO_MAIN_PACKAGE_WITH_LOWER_QUOTA_THAN_USAGE",
};
