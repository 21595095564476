import { gql } from "@apollo/client";

export const ACTIVATE_CHAT_MENU = gql`
  mutation activateChatMenu($projectId: ID!, $chatMenuId: ID!) {
    activateChatMenu(projectId: $projectId, chatMenuId: $chatMenuId) {
      id
    }
  }
`;

export const DEACTIVATE_CHAT_MENU = gql`
  mutation deactivateChatMenu($projectId: ID!, $chatMenuId: ID!) {
    deactivateChatMenu(projectId: $projectId, chatMenuId: $chatMenuId) {
      id
    }
  }
`;
export const ADD_RICH_MENU = gql`
  mutation addChatMenu($projectId: ID!, $chatMenu: ChatMenuInput!) {
    addChatMenu(projectId: $projectId, chatMenu: $chatMenu) {
      id
      projectId
      name
      isActive
      isDefault
      platform
      menuActions {
        bounds
        action {
          type
          label
          url
        }
      }
      menuUrl
      menuLabel
      isCollapsed
      template
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_RICH_MENU = gql`
  mutation updateChatMenu($projectId: ID!, $chatMenuId: ID!, $chatMenu: ChatMenuInput!) {
    updateChatMenu(projectId: $projectId, chatMenuId: $chatMenuId, chatMenu: $chatMenu) {
      id
      projectId
      name
      isActive
      isDefault
      platform
      menuActions {
        bounds
        action {
          type
          label
          url
        }
      }
      menuUrl
      menuLabel
      isCollapsed
      template
      createdAt
      updatedAt
    }
  }
`;

export const REMOVE_CHAT_MENU = gql`
  mutation removeChatMenu($projectId: ID!, $chatMenuId: ID!) {
    removeChatMenu(projectId: $projectId, chatMenuId: $chatMenuId) {
      id
    }
  }
`;

export const ACTIVATE_RICH_MENU = gql`
  mutation activateChatMenu($projectId: ID!, $chatMenuId: ID!) {
    activateChatMenu(projectId: $projectId, chatMenuId: $chatMenuId) {
      id
      isActive
      isDefault
    }
  }
`;
