import { ErrorAddCategory, ErrorUpdateCategory, ErrorDeleteCategory } from "constants/Category";

const NETWORK_ERROR = "Network error: Failed to fetch";

const mapErrorMessage = (errMessage: string) => {
  switch (errMessage) {
    case NETWORK_ERROR:
      return "Network error please try again later";
    case ErrorAddCategory.CATEGORY_NAME_CANNOT_BE_EMPTY:
    case ErrorUpdateCategory.CATEGORY_NAME_CANNOT_BE_EMPTY:
      return "category.default.error.categoryNameCannotBeEmpty";
    case ErrorAddCategory.DUPLICATED_CATEGORY_NAME:
    case ErrorUpdateCategory.DUPLICATED_CATEGORY_NAME:
      return "category.default.error.duplicateCategoryName";
    case ErrorAddCategory.EXCEED_MAXIMUM_CATEGORIES_LIMIT:
      return "category.default.error.exceedMaximumCategoryLimit";
    case ErrorDeleteCategory.UNABLE_TO_DELETE_CATEGORY_THAT_STILL_HAS_PRODUCT:
      return "category.default.error.unableToDeleteCategoryThatStillHasProduct";

    default:
      return "Please try again later";
  }
};

export default mapErrorMessage;
