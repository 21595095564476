import React, { FC } from "react";
import { components } from "react-select";
import { IcFlagThai, IcFlagEnglish } from "components/SvgIcons";
import { useTranslation } from "react-i18next";

import { ReactSelect } from "types";

export const LANGUAGE_OPTIONS: ReactSelect.OptionWithLogoType[] = [
  {
    value: "TH",
    label: "Thai",
    logo: <IcFlagThai />,
  },
  {
    value: "EN",
    label: "English (US)",
    logo: <IcFlagEnglish />,
  },
];

const DisplayOption: FC<{ data: ReactSelect.OptionWithLogoType }> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex align-items-center">
      {data.logo}
      <span className="ml-2">{t(data.label)}</span>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Option = (optionProps: any) => {
  const { data } = optionProps;
  return (
    <components.Option {...optionProps}>
      <DisplayOption data={data} />
    </components.Option>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SingleValue = (singleValueProps: any) => {
  const { data } = singleValueProps;
  return (
    <components.SingleValue {...singleValueProps}>
      <DisplayOption data={data} />
    </components.SingleValue>
  );
};
