import { useQuery } from "@apollo/client";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Loading from "domain/Faq/FaqThEn/FaqContainer/Loading";
import { UserProfileType } from "types/User";
import { FLAG_USER_WITH_IS_ENTERPRISE } from "graphql/auth/query";

const UserAuthentication: FC = ({ children }) => {
  const { t } = useTranslation();

  const { loading } = useQuery<UserProfileType>(FLAG_USER_WITH_IS_ENTERPRISE);

  if (loading) {
    return <Loading loadingLabel={t("LOADING")} size={50} />;
  }

  return <>{children}</>;
};

export default UserAuthentication;
