import { HIGHLIGHT_CLASS_NAME } from "./config";

const highlight = (text: string, highlight: string) => {
  const index = text.search(highlight);
  return `${text.substring(0, index)}<span class='${HIGHLIGHT_CLASS_NAME}'>${text.substring(
    index,
    index + highlight.length,
  )}</span>${text.substring(index + highlight.length)}`;
};

export default highlight;
