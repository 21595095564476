import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { SmallSquareButton } from "components/Button";
import Card from "components/Card";
import Grid from "components/Grid";
import { SvgIcon } from "components/Icon";
import { IcCheck, IcError, IcEdit, IcInfo } from "components/SvgIcons";
import TextField from "components/TextField";
import Typography from "components/Typography";
import COLORS from "constants/Colors";
import { SHIPPOP_API_KEY_DOC } from "constants/Shipping";
import { shippopApiKeySchema } from "./validateSchema";

type ShippopApiKeySchema = {
  apiKey: string;
};

type ApiKeyFormPropsType = {
  apiKey: string;
  onChangeApiKey: (apiKey: string) => void;
};

const ApiKeyForm: React.FC<ApiKeyFormPropsType> = (props) => {
  const { t } = useTranslation();

  const { apiKey, onChangeApiKey } = props;
  const [defaultApiKey, setDefaultApiKey] = useState("");
  const [isEditApiKey, setIsEditApiKey] = useState(!apiKey);

  useEffect(() => {
    setDefaultApiKey(apiKey);
  }, [apiKey]);

  const { register, errors, handleSubmit } = useForm<ShippopApiKeySchema>({
    resolver: yupResolver(shippopApiKeySchema),
  });

  const handleToggleEditApiKey = () => {
    if (isEditApiKey && !apiKey) return;
    setDefaultApiKey(isEditApiKey ? apiKey : "");
    setIsEditApiKey(!isEditApiKey);
  };

  const handleApiKey = (editedApiKey: string) => {
    setDefaultApiKey(editedApiKey);
  };

  const handleSaveApiKey = ({ apiKey: newApiKey }: ShippopApiKeySchema) => {
    onChangeApiKey(newApiKey);
    setIsEditApiKey(false);
  };

  const maskingCredential = (credentialString: string) => {
    if (!credentialString || !credentialString.length) {
      return "";
    }

    return credentialString.replace(/.(?=.{4,}$)/g, "*");
  };

  return (
    <Grid container>
      <Grid item xs={12} className="mt-2">
        <Typography variant="body3" color="darkMed" className="pb-2">
          {t("shipping.shippop.apikey.title")}
        </Typography>
      </Grid>
      <Grid item container alignItems="center" xs={12} className="flex-nowrap">
        <Grid item className="d-flex flex-grow-1">
          <TextField
            name="apiKey"
            defaultValue={defaultApiKey || ""}
            value={isEditApiKey ? defaultApiKey || maskingCredential(defaultApiKey) : defaultApiKey}
            fullWidth
            variant="outlined"
            disabled={!isEditApiKey}
            validate={register}
            onChange={(event) => handleApiKey(event.target.value)}
            error={Boolean(errors?.apiKey)}
            helperText={t(`${errors?.apiKey?.message || ""}`)}
          />
        </Grid>
        <Grid item className="d-flex flex-grow-0 flex-row pl-3">
          {isEditApiKey && (
            <Grid item className="pr-2">
              <SmallSquareButton onClick={handleSubmit(handleSaveApiKey)}>
                <SvgIcon
                  component={IcCheck}
                  size="small"
                  htmlColor={COLORS.DarkMed}
                  className="m-0 p-0"
                  viewBox="0 0 32 32"
                />
              </SmallSquareButton>
            </Grid>
          )}
          <Grid>
            <SmallSquareButton onClick={handleToggleEditApiKey} isDisabled={isEditApiKey && !apiKey}>
              <SvgIcon
                component={isEditApiKey ? IcError : IcEdit}
                size="small"
                htmlColor={COLORS.DarkMed}
                className="m-0 p-0"
                viewBox={isEditApiKey ? "0 0 32 32" : "-2 0 34 34"}
              />
            </SmallSquareButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="pt-3">
        <Card noShadow bgColor={COLORS.Light} noBorder>
          <Grid container className="flex-nowrap">
            <Grid item className="pl-2 pr-4 d-flex flex-grow-0">
              <SvgIcon component={IcInfo} fontSize="small" htmlColor={COLORS.DarkMed} className="m-0 p-0" />
            </Grid>
            <Grid item className="d-flex flex-grow-1">
              <Typography variant="body3">
                <Trans i18nKey="shipping.shippop.apikey.remark">
                  <a href={SHIPPOP_API_KEY_DOC} key={SHIPPOP_API_KEY_DOC} target="_blank" rel="noopener noreferrer">
                    link
                  </a>
                </Trans>
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ApiKeyForm;
