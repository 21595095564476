import { InvoiceStatue } from "types/Report";
import getSelection, { OnSelectAllRowInPage, OnSelectRow } from "./Column/getSelection";
import getOrderNo from "./Column/getOrderNo";
import getEditOrderNo from "./Column/getEditOrderNo";
import getShippopError from "./Column/getShippopError";
import getCustomer from "./Column/getCustomer";
import getStatus from "./Column/getStatus";
import getDimension from "./Column/getDimension";
import getWeight from "./Column/getWeight";
import getShippingDescription from "./Column/getShippingDescription";
import getCancelOrder from "./Column/getCancelOrder";

const getColumns = (
  t: Function,
  onClickCancelOrder: Function,
  onClickEditDimensionAndWeight: Function,
  state: string,
  projectId?: string,
  onSelectRow?: OnSelectRow,
  onSelectAllRowInPage?: OnSelectAllRowInPage,
) => {
  const cancelOrder = state === InvoiceStatue.SETTLED && getCancelOrder(t, onClickCancelOrder);
  const selection = getSelection(onSelectAllRowInPage, onSelectRow);
  const orderNo = getOrderNo(t, projectId);
  const shippopError = getShippopError();
  const editOrderNo = getEditOrderNo(onClickEditDimensionAndWeight, state);
  const dimension = getDimension(t);
  const customer = getCustomer(t);
  const status = getStatus(t);
  const weight = getWeight(t);
  const shippingDescription = getShippingDescription(t);

  return [
    selection,
    shippopError,
    orderNo,
    editOrderNo,
    customer,
    status,
    weight,
    dimension,
    shippingDescription,
    { disableSortBy: true, Header: t("Address"), accessor: "streetAddress", width: 250 },
    { disableSortBy: true, Header: t("Note"), accessor: "note" },
    { disableSortBy: true, Header: t("Phone number"), accessor: "phoneNumber" },
    { Header: t("E-mail"), accessor: "email" },
    { Header: t("Tracking No."), accessor: "trackingNumber" },
    { Header: t("SHIPPOP_REF"), accessor: "shippopRef" },
    { Header: t("salesReport.adminsNote"), accessor: "adminsNote", disableSortBy: true },
    { Header: t("salesReport.refCode"), accessor: "referenceCode", disableSortBy: true },
    cancelOrder,
  ].filter(Boolean);
};

export default getColumns;
