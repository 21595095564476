import * as React from "react";

const SvgIcIntegration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.584 17.227l-1.649 1.649c-1.36 1.36-3.56 1.359-4.918 0-.383-.383-.383-1.003 0-1.385.382-.383 1.002-.383 1.385 0 .594.593 1.555.593 2.148 0l1.65-1.65c.286-.286.444-.668.444-1.073 0-1.34-1.631-2.036-2.593-1.074-.382.382-1.003.382-1.385 0-.383-.383-.383-1.003 0-1.386 2.176-2.176 5.937-.631 5.937 2.46 0 .929-.362 1.802-1.019 2.459m-5.867 4.346c-.678.678-1.569 1.017-2.46 1.017-3.092 0-4.637-3.757-2.459-5.935l1.65-1.649c1.358-1.36 3.558-1.36 4.918 0 .382.382.382 1.003 0 1.385-.383.383-1.003.383-1.385 0-.594-.594-1.555-.594-2.149 0l-1.648 1.649c-.288.287-.445.668-.445 1.074 0 1.35 1.642 2.025 2.593 1.074.382-.382 1.002-.382 1.385 0 .382.383.382 1.003 0 1.385m.246-17.383c.565 0 1.034.47 1.034 1.034 0 .566-.47 1.034-1.034 1.034-.565 0-1.034-.468-1.034-1.034 0-.565.469-1.034 1.034-1.034m9.65.345H19.85c-.579-1.6-2.095-2.757-3.887-2.757-1.792 0-3.309 1.158-3.888 2.757H6.313c-.193 0-.373.014-.552.055-.537.11-1.02.386-1.392.758-.248.248-.455.552-.593.883-.138.317-.22.675-.22 1.061v19.3c0 .373.082.745.22 1.076.138.33.345.62.593.882.372.372.855.648 1.392.758.18.028.359.042.552.042h19.3c1.516 0 2.757-1.241 2.757-2.757v-19.3c0-1.517-1.24-2.758-2.757-2.758"
      transform="translate(-3 -1)"
    />
  </svg>
);

export default SvgIcIntegration;
