import React, { FC, useState, useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";

import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

import {
  CANCEL_SCHEDULE_BROADCAST,
  DELETE_BROADCAST_CAMPAIGN,
  UPSERT_BROADCAST_CAMPAIGN,
} from "graphql/broadcast/mutation";
import { BROADCAST_CAMPAIGN } from "graphql/broadcast/query";

import Button from "components/Button";
import Grid from "components/Grid";
import Typography from "components/Typography";

import ConfirmationModal from "components/ConfirmationModal";
import CircularProgress from "components/CircularProgress";

import { IcDownload, IcDelete } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";

import { removeTypenameField, removeFieldByName } from "utils/common";
import { cleanCategoriesMessage } from "utils/message";
import { MessageType } from "types/Chat";
import { ProjectIdType } from "types/Project";
import {
  BroadcastCriteriaOperation,
  BroadcastChannel,
  BroadcastCampaignDataType,
  BroadcastStatus,
  BroadcastType,
} from "types/Broadcast";

import { DATE_TIME_PICKER_FORMAT } from "constants/DateTimeFormat";
import { COLORS } from "constants/Colors";

import BroadcastCampaignForm from "../BroadcastCampaignForm";
import MessagePreview from "../MessagePreview/index";
import BroadcastStatusLabel from "../BroadcastStatusLabel";
import { useFetchAudienceNumber } from "../hooks/useFetchAudienceNumber";

const TOAST_CONFIG = {
  position: toast.POSITION.TOP_CENTER,
  closeButton: false,
  autoClose: 3000,
};

type BroadcastContainerPropsType = {
  closeFrom: Function;
  broadcastFormId?: string | undefined | null;
};

const BroadcastContainer: FC<BroadcastContainerPropsType> = ({ broadcastFormId, closeFrom }) => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const { fetchAudienceNumber, audienceNumber, loading: isAudienceNumberLoading } = useFetchAudienceNumber();
  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(false);
  const [isOpenConfirmSendModal, setIsOpenConfirmSendModal] = useState(false);
  const [isOpenConfirmCancelModal, setIsOpenConfirmCancelModal] = useState(false);

  const [isSubmittingSaveDraft, setIsSubmittingSaveDraft] = useState(false);
  const [audienceNumberValue, setAudienceNumber] = useState(audienceNumber);

  const initBroadcastCampaignData: BroadcastCampaignDataType = {
    projectId,
    name: "",
    channel: BroadcastChannel.LINE,
    responseMessage: [
      {
        type: MessageType.QUICK_REPLIES,
        value: [],
      },
    ],
    broadcastCriteria: [
      {
        projectId,
        systemCriteria: [],
        customCriteria: [],
        locationCriteria: [],
        operation: BroadcastCriteriaOperation.BASE,
      },
    ],
    broadcastDate: moment().toISOString(),
    type: BroadcastType.IMMEDIATE,
  };

  const { data: broadcastCampaignGQLData, loading: isBroadcastCampaignLoading } = useQuery(BROADCAST_CAMPAIGN, {
    skip: !projectId || !broadcastFormId,
    variables: {
      projectId,
      broadcastId: broadcastFormId,
    },
    fetchPolicy: "network-only",
  });

  const [broadcastCampaignData, setBroadcastCampaignData] = useState<BroadcastCampaignDataType>(
    initBroadcastCampaignData,
  );

  const [upsertBroadcastCampaign, { loading: upsertBroadcastLoading }] = useMutation(UPSERT_BROADCAST_CAMPAIGN, {
    onError: () => {
      toast.error(`${t("Broadcast campaign was saved failed")}`, TOAST_CONFIG);
      if (!broadcastFormId) {
        // Note! if sent fail will close the menu
        closeFrom(false);
      }
      setIsOpenConfirmSendModal(false);
      setIsSubmittingSaveDraft(false);
    },
    onCompleted: () => {
      toast.success(t("Broadcast campaign was saved successfully"), TOAST_CONFIG);
      closeFrom(false); // Note! this effect to setIsOpenBroadcastForm(false)
      setIsOpenConfirmSendModal(false);
      setIsSubmittingSaveDraft(false);
    },
  });

  const [deleteDraftBroadcastCampaign, { loading: deleteDraftBroadcastLoading }] = useMutation(
    DELETE_BROADCAST_CAMPAIGN,
    {
      onError: () => {
        toast.error(`${t("Broadcast draft was deleted failed")}`, {
          position: toast.POSITION.TOP_CENTER,
          closeButton: false,
          autoClose: 2000,
        });
        setIsOpenConfirmDeleteModal(false);
      },
      onCompleted: () => {
        toast.success(t("Broadcast draft was deleted successfully"), TOAST_CONFIG);
        setIsOpenConfirmDeleteModal(false);
        closeFrom(false); // Note! this effect to setIsOpenBroadcastForm(false)
      },
    },
  );

  const [cancelScheduledBroadcast, { loading: cancelScheduledBroadcastLoading }] = useMutation(
    CANCEL_SCHEDULE_BROADCAST,
    {
      onError: () => {
        toast.error(`${t("Broadcast canceled failed")}`, {
          position: toast.POSITION.TOP_CENTER,
          closeButton: false,
          autoClose: 2000,
        });
        setIsOpenConfirmCancelModal(false);
      },
      onCompleted: () => {
        toast.success(t("Broadcast canceled successfully"), TOAST_CONFIG);
        setIsOpenConfirmCancelModal(false);
      },
    },
  );

  const submitSaveDraft = () => {
    if (upsertBroadcastLoading) {
      return;
    }

    const cleanObjectCategoriesMessage = cleanCategoriesMessage(broadcastCampaignData.responseMessage);

    const broadcastCampaign = { ...broadcastCampaignData, responseMessage: cleanObjectCategoriesMessage };
    if (broadcastCampaign.orderPaidConversionTotal !== undefined) {
      delete broadcastCampaign.orderPaidConversionTotal;
    }
    setIsSubmittingSaveDraft(true);
    upsertBroadcastCampaign({
      variables: {
        projectId,
        broadcastCampaign,
        isPublish: false,
      },
    });
  };

  const submitSendBroadcast = () => {
    if (upsertBroadcastLoading) {
      return;
    }
    const cleanObjectCategoriesMessage = cleanCategoriesMessage(broadcastCampaignData.responseMessage);

    const broadcastCampaign = { ...broadcastCampaignData };
    delete broadcastCampaign.orderPaidConversionTotal;

    upsertBroadcastCampaign({
      variables: {
        projectId,
        broadcastCampaign: { ...broadcastCampaign, responseMessage: cleanObjectCategoriesMessage },
        isPublish: true,
      },
    });
  };

  const submitDeleteBroadcast = () => {
    if (deleteDraftBroadcastLoading) {
      return;
    }

    if (broadcastFormId) {
      deleteDraftBroadcastCampaign({
        variables: {
          projectId,
          broadcastId: broadcastFormId,
        },
      });
    } else {
      setIsOpenConfirmDeleteModal(false);
    }
  };

  const submitCancelBroadcast = () => {
    if (cancelScheduledBroadcastLoading) {
      return;
    }

    if (broadcastFormId) {
      cancelScheduledBroadcast({
        variables: {
          projectId,
          broadcastId: broadcastFormId,
        },
      });
    } else {
      setIsOpenConfirmCancelModal(false);
    }
  };

  useEffect(() => {
    if (broadcastCampaignGQLData && broadcastFormId) {
      const newBroadcastCampaignGQLData = removeTypenameField(
        broadcastCampaignGQLData && broadcastCampaignGQLData.broadcastCampaign,
      ) as BroadcastCampaignDataType;

      const newBroadcastRemovedUnUseKey = removeFieldByName(newBroadcastCampaignGQLData, [
        "numberOfAudience",
        "status",
        "failReason",
        "createdAt",
        "updatedAt",
        "numberOfEngagement",
        "newOrderConversionRate",
        "orderPaidConversionRate",
      ]) as BroadcastCampaignDataType;

      setBroadcastCampaignData(newBroadcastRemovedUnUseKey);
      setAudienceNumber(get(broadcastCampaignGQLData, "broadcastCampaign.numberOfAudience")); // numberOfAudience from query
    }
  }, [broadcastCampaignGQLData, broadcastFormId]);

  const isValidToSave =
    Boolean(broadcastCampaignData.responseMessage.length >= 2) && // length 2 is included quick-reply
    Boolean(broadcastCampaignData.responseMessage.every((message) => message.value)) &&
    Boolean(broadcastCampaignData.name);

  //  to check disable sand except first draft
  const isAllowToSendSaveDraftAndDelete =
    get(broadcastCampaignGQLData, "broadcastCampaign.status") === BroadcastStatus.DRAFT || !broadcastFormId;

  useEffect(() => {
    setAudienceNumber(audienceNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audienceNumber]);

  const isSelectedScheduled = get(broadcastCampaignGQLData, "broadcastCampaign.status") === BroadcastStatus.SCHEDULED;

  return (
    <Grid id="BroadcastContainer" container alignContent="flex-start">
      {/* Title panel */}
      <Grid item container xs={12} alignItems="center" alignContent="center" className="py-3 px-3">
        <Grid item xs={12} md={5}>
          <Typography variant="title1" color="dark">
            {t("broadcast")}
          </Typography>
        </Grid>

        {isAllowToSendSaveDraftAndDelete && (
          <Grid item xs={12} md={7} container justify="flex-end" alignItems="center" alignContent="center">
            <Button
              color="secondary"
              className="mx-1"
              size="medium"
              onClick={() => {
                setIsOpenConfirmDeleteModal(true);
              }}
              disabled={!broadcastFormId}
            >
              <Grid container alignItems="center" alignContent="center" className="mx-2">
                <SvgIcon component={IcDelete} fontSize="small" className="mr-2" />
                {t("broadcast.delete")}
              </Grid>
            </Button>
            <Button
              color="secondary"
              className="mx-1"
              size="medium"
              onClick={submitSaveDraft}
              disabled={!isValidToSave || isSubmittingSaveDraft}
            >
              <Grid container alignItems="center" alignContent="center" className="mx-2">
                <SvgIcon component={IcDownload} fontSize="small" className="mr-2" />
                {t("broadcast.saveDraft")}
              </Grid>
            </Button>
          </Grid>
        )}
      </Grid>
      {/* Title panel */}

      <BroadcastStatusLabel
        isDraft={isAllowToSendSaveDraftAndDelete}
        broadcastCampaignGQLData={broadcastCampaignGQLData}
      />

      {isBroadcastCampaignLoading ? (
        <Grid container item xs={12} justify="center">
          <CircularProgress className="m-4" />
        </Grid>
      ) : (
        <>
          <Grid container item xs={12} md={6} className="px-3 pb-3">
            <BroadcastCampaignForm
              isNewCreateForm={!broadcastFormId}
              projectId={projectId}
              broadcastCampaignData={broadcastCampaignData}
              setBroadcastCampaignData={setBroadcastCampaignData}
              disabledForm={!isAllowToSendSaveDraftAndDelete}
              fetchAudienceNumber={fetchAudienceNumber}
              audienceNumber={audienceNumberValue}
            />
          </Grid>

          <Grid container item xs={12} md={6} className="px-3 pb-3">
            <Grid item xs={12}>
              <MessagePreview
                projectId={projectId}
                broadcastCampaignData={broadcastCampaignData}
                setBroadcastCampaignData={setBroadcastCampaignData}
                disabledForm={!isAllowToSendSaveDraftAndDelete}
              />
            </Grid>
          </Grid>
        </>
      )}

      {isAllowToSendSaveDraftAndDelete && (
        <Grid container item xs={12} className="px-3" justify="flex-end">
          <Button
            size="medium"
            style={{ width: 220 }}
            onClick={() => {
              setIsOpenConfirmSendModal(true);
            }}
            disabled={!isValidToSave || isAudienceNumberLoading || Number(audienceNumberValue) === 0}
          >
            {broadcastCampaignData.type === BroadcastType.IMMEDIATE ? t("broadcast.sendNow") : t("Scheduled")}
          </Button>
        </Grid>
      )}

      {isSelectedScheduled && (
        <Grid container item xs={12} className="px-3" justify="flex-end">
          <Button
            size="medium"
            style={{ width: 220 }}
            onClick={() => {
              setIsOpenConfirmCancelModal(true);
            }}
            disabled={!isValidToSave || isAudienceNumberLoading || Number(audienceNumberValue) === 0}
            bgColor={COLORS.Error}
            color={COLORS.White}
          >
            {t("broadcast.cancel")}
          </Button>
        </Grid>
      )}

      <ConfirmationModal
        title={`${t("Are you sure to delete this broadcast draft")} ?`}
        isOpen={isOpenConfirmDeleteModal}
        onClose={() => setIsOpenConfirmDeleteModal(false)}
        onSubmit={() => {
          submitDeleteBroadcast();
        }}
      />

      <ConfirmationModal
        title={`${t("broadcast.confirmSchedule", {
          date: moment(broadcastCampaignData.broadcastDate).format(DATE_TIME_PICKER_FORMAT),
        })} ?`}
        isOpen={isOpenConfirmSendModal}
        onClose={() => setIsOpenConfirmSendModal(false)}
        onSubmit={submitSendBroadcast}
        textSubmit="Yes"
        textCancel="No"
      />

      <ConfirmationModal
        title={`${t("Are you sure to cancel this broadcast")} ?`}
        isOpen={isOpenConfirmCancelModal}
        onClose={() => setIsOpenConfirmCancelModal(false)}
        onSubmit={() => {
          submitCancelBroadcast();
        }}
        textSubmit="Yes"
        textCancel="No"
      />
    </Grid>
  );
};

export default BroadcastContainer;
