import React, { FC } from "react";

import { IcAdd } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";
import { ButtonColors } from "types/Button";
import { Button } from "./styled";

type CreateProjectButtonPropsType = {
  label: string;
  color?: ButtonColors;
};

const CreateProjectButton: FC<CreateProjectButtonPropsType> = ({ label, color = ButtonColors.PRIMARY }) => (
  <Button color={color} fullWidth>
    <SvgIcon component={IcAdd} fontSize="large" />
    {label}
  </Button>
);

export default CreateProjectButton;
