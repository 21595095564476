import React, { FC } from "react";
import COLORS from "constants/Colors";
import { NO_IMG_AVAILABLE } from "constants/Image";

import Grid from "components/Grid";
import Typography from "components/Typography";

import { SvgIcon } from "components/Icon";
import { IcCircleRemove } from "components/SvgIcons";
import { CategoryType } from "types/Category";
import {
  CategoryFlexImage,
  CategoryFlexImageUnavailable,
  CategoryFlexName,
  FlexMessageContent,
  CarouselContent,
  CircleRemoveIconContainer,
  UnavailableContainer,
} from "../FlexMessage.style";

type FlexCategoryCardPropsType = {
  category: CategoryType;
  isEnableDelete?: boolean;
  onClickDelete?: (categoryId: string) => void;
};

const FlexCategoryCard: FC<FlexCategoryCardPropsType> = (props) => {
  const { category, isEnableDelete, onClickDelete } = props;

  return (
    <CarouselContent>
      <FlexMessageContent isCategory>
        {isEnableDelete && (
          <CircleRemoveIconContainer
            onClick={() => {
              if (onClickDelete) {
                onClickDelete(category.id);
              }
            }}
          >
            <SvgIcon component={IcCircleRemove} fontSize="inherit" htmlColor={COLORS.DarkMed} />
          </CircleRemoveIconContainer>
        )}
        {category.isActive === false && <UnavailableContainer>Unavailable</UnavailableContainer>}
        <Grid item xs={12}>
          {category.isActive === false ? (
            <CategoryFlexImageUnavailable src={category.image || NO_IMG_AVAILABLE} />
          ) : (
            <CategoryFlexImage src={category.image || NO_IMG_AVAILABLE} />
          )}
          <CategoryFlexName>
            <Typography className="pl-2 pb-2" variant="title7" color={COLORS.White}>
              {category.name}
            </Typography>
          </CategoryFlexName>
        </Grid>
      </FlexMessageContent>
    </CarouselContent>
  );
};
export default FlexCategoryCard;
