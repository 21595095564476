import React from "react";
import styled from "styled-components";
import Grid from "components/Grid";
import DeepleTypography from "components/Typography";

import { VARIANTS } from "constants/Typography";

export const GridShowingResult = styled(Grid)`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    order: 2;
    padding-bottom: 8px;
  }
`;

export const GridPagination = styled(Grid)`
  justify-content: flex-end;
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex: 1;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    order: 1;
    justify-content: center;
  }
`;

export const GridRowsPerPage = styled(Grid)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex: 0;
    flex-basis: 180px;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    order: 3;
    padding-bottom: 8px;
  }
`;

export const GridLoading = styled.div`
  position: absolute;
  z-index: 10;
  top: calc(50% - 45px);
  left: calc(50% - 45px);
`;

export const Wrapper = styled.div`
  position: relative;
  font-size: ${VARIANTS.body4.size};
  overflow: auto;
  flex-direction: column;
  padding: 0;
  width: 100%;
`;

export const Typography = styled(({ fontWeight: _fontWeight, ...rest }) => <DeepleTypography {...rest} />).attrs(
  ({ fontWeight, variant }) => {
    return { fontWeight, variant };
  },
)`
  ${({ variant }) => (variant ? "" : "font-size: inherit;")};
  font-weight: ${({ fontWeight }) => fontWeight || "inherit"};
`;

export const Hidden = styled(({ isHidden: _isHidden, ...rest }) => <div {...rest} />).attrs(({ isHidden }) => {
  return { isHidden };
})`
  display: ${({ isHidden }) => (isHidden ? "none" : "block")};
`;
