import * as React from "react";

const IcAiGeneral = () => (
  <svg width="18" height="18" viewBox="0 0 16 16" className="mr-2">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7 .778a6.205 6.205 0 0 1 6.025 4.724.798.798 0 0 1 .975.776v1.404a.798.798 0 0 1-.976.777 6.202 6.202 0 0 1-12.05 0A.798.798 0 0 1 0 7.682l.001-1.403A.798.798 0 0 1 .976 5.5 6.202 6.202 0 0 1 7 .778zM7 3.16a3.917 3.917 0 0 0-3.917 3.916c0 1.665.934 3.028 2.4 3.595.15.056-.435 1.543-.39 1.635 1.729-.796 2.988-1.428 3.466-1.635A3.918 3.918 0 0 0 7 3.16zM5.152 5.995a.985.985 0 1 1 0 1.97.985.985 0 0 1 0-1.97zm3.696 0a.985.985 0 1 1 0 1.97.985.985 0 0 1 0-1.97z"
    />
  </svg>
);

export default IcAiGeneral;
