import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useMutation } from "@apollo/client";

import { UploadDirectoryType, SignedUrl, RequestHeader } from "types/Image";
import { SIGNED_URL_UPLOAD_IMAGE } from "graphql/image/mutation";
import { compressImage } from "utils/compressImage";

import uploadFileToGCS from "./uploadFileToGCS";

export interface CompressImageOptions {
  maxWidthOrHeight?: number;
  maxSizeMB?: number;
}

const useUploadImage = () => {
  const [signUrlForImage] = useMutation<{ signedUrlForImageUpload: SignedUrl }>(SIGNED_URL_UPLOAD_IMAGE);

  const uploadImage = async (
    projectId: string,
    file: File,
    type: UploadDirectoryType,
    customerId?: string,
    compressOption?: CompressImageOptions,
  ) => {
    const targetFile: File = file;
    if (!isEmpty(compressOption)) {
      const targetFile = await compressImage(file, compressOption?.maxSizeMB, compressOption?.maxWidthOrHeight);

      if (!targetFile) {
        throw new Error("unable to compress file");
      }
    }

    const result = await signUrlForImage({
      variables: {
        projectId,
        imageFile: {
          contentType: targetFile.type,
          sizeInByte: targetFile.size,
        },
        type,
        customerId,
      },
    });

    const requestHeaders: RequestHeader[] = get(result, ["data", "signedUrlForImageUpload", "requestHeaders"], []);
    const signedUrl: string = get(result, ["data", "signedUrlForImageUpload", "signedUrl"], "");
    const publicUrl: string = get(result, ["data", "signedUrlForImageUpload", "publicUrl"], "");
    const filePath: string = get(result, ["data", "signedUrlForImageUpload", "filePath"], "");

    await uploadFileToGCS(targetFile, requestHeaders, signedUrl);

    return { publicUrl, filePath };
  };

  return uploadImage;
};

export default useUploadImage;
