import { gql } from "@apollo/client";

export const UPDATE_VAT = gql`
  mutation updateVatV2($projectId: ID!, $vatId: ID!, $vat: VatInput) {
    updatedVat: updateVatV2(projectId: $projectId, vatId: $vatId, vat: $vat) {
      id
      projectId
      address
      companyName
      district
      isFullTaxInfo
      isShowTaxInfo
      option
      phoneNumber
      postcode
      province
      rate
      subDistrict
      taxId
      isShippingIncluded
      lang
    }
  }
`;
