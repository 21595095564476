const mapErrorMessage = (errMessage: string) => {
  switch (errMessage) {
    case "CREATE_SHIPPOP_ORDER:SUBCEEDDED_SHIPPOP_MINIMUM_PICKUP_ORDER":
      return "SUBCEEDDED_SHIPPOP_MINIMUM_PICKUP_ORDER";
    default:
      return "Update fail.";
  }
};

export default mapErrorMessage;
