import styled from "styled-components";

import COLORS from "constants/Colors";

export const StyledOrderList = styled.ol`
  margin-top: 36px !important;
  color: ${COLORS.Gray500};
  list-style-type: decimal !important;
  list-style-position: inside !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
`;

export const StyledModalContent = styled.div`
  @media (min-width: 1024px) {
    padding: 24px;
  }
`;

export const StyledPreviewCard = styled.div`
  width: 273px;
  height: 312px;
  margin: 0 auto;

  background: #f1f1f1;
  border-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: column;

  img,
  .image-placeholder {
    width: 100%;
    height: 140px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .image-placeholder {
    /* background: linear-gradient(140.26deg, #6d15ff -2.09%, #ff3086 42.72%, #de0149 69.53%, #ffb30d 111.5%); */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    flex: 1;
    padding: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .content h4 {
    padding: 0;
    margin: 0;
    height: 60px;
    color: ${COLORS.Dark};
    line-break: anywhere;
  }

  .content p {
    font-size: 12px;
    color: ${COLORS.DarkLight};
    height: 32px;
  }

  .content button {
    width: 100%;
    padding: 8px 0;
    border: none;
    border-radius: 10px;
    background: #fbfbfb;
    font-weight: 600;
  }
`;

export const NumberBadge = styled.div`
  height: 24px;
  width: 24px;
  position: absolute;
  color: ${COLORS.White};
  background: linear-gradient(90deg, #ec008c 0%, #ec008c 0%, #ec008c 0%, #ff4e00 100%, #ff4e00 100%);
  border-radius: 100%;
  left: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
