import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@material-ui/core";
import React, { useState, useEffect, useContext, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import get from "lodash/get";

import { PRODUCT_TABS, INIT_TAB_VALUE } from "constants/Product";
import { NOTIFICATION_CREATE, NOTIFICATION_UPDATE } from "constants/Notification";
import CircularProgress from "components/CircularProgress";
import Modal from "components/Modal";
import Tab from "components/Tab";
import Typography from "components/Typography";
import { ADD_PRODUCT, EDIT_PRODUCT } from "graphql/product/mutation";
import { Category } from "types";
import { ProjectIdType } from "types/Project";
import {
  ProductType,
  ProductPropertiesType,
  ProductVariationsType,
  AddProductSKUType,
  Product,
  ProductItemPropsType,
  STATE_TAB,
} from "types/Product";
import { removeTypenameField, removeFieldByName, removeEmptyObjects } from "utils/common";
import { GuidelineContext } from "utils/context";
import { notifyWarn, notifySuccess } from "utils/notify";
import { getInitSKUsConvertImageObject } from "utils/product/getInitSKUsConvertImageObject";
import { getInitAllTypesFromProductSKUs } from "utils/product/getInitAllTypesFromProductSKUs";
import { getInitImages } from "utils/product/getInitImages";
import { GTM_EVENT } from "constants/GTM";
import { addGoogleTagEvent } from "services/GoogleTagEvent";
import DetectChangeCategoryModal from "../DetectChangeCategoryModal";
import { addNewDigitalProductSchema } from "../validateSchema";
import { DigitalContentProductDetails } from "./DigitalContentProductDetails";
import { ProductModalHeader, Wrapper } from "../AddProductInTypeSKUForm/addProduct.styled";

import DigitalProductContentTab from "./DigitalProductContentTab";
import ProductInventoriesContainer from "../ProductInventory";
import mapErrorMessage from "../mapErrorMessage";
import AddPropertyModal from "../AddPropertyModal";

type AddDigitalContentProductFormPropsType = {
  categoryData: Category.CategoryType[];
  closeProductModal: Function;
  isEditMode: boolean;
  productData: ProductItemPropsType;
};

const AddDigitalContentProductForm: React.FC<AddDigitalContentProductFormPropsType> = (props) => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const { categoryData, closeProductModal, isEditMode = false, productData: productDataProps } = props;
  const [stateTab, setStateTab] = useState(PRODUCT_TABS[INIT_TAB_VALUE]);
  const [isOpenModalDetectHighlightProducts, setIsOpenModalDetectHighlightProducts] = useState(false);
  const [isAddPropertyModalOpen, setIsAddPropertyModalOpen] = useState(false);

  const initProductSKUs = isEditMode ? get(productDataProps, ["product", "productSKUs"], []) : [];
  const initProperties = isEditMode ? removeTypenameField(get(productDataProps, ["product", "properties"], [])) : [];
  const images = isEditMode ? get(productDataProps, ["product", "images"], []) : [];
  const initImages = isEditMode ? getInitImages(images) : [];
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    isEditMode ? get(productDataProps, ["product", "categories", "0", "id"], "") : "",
  );

  const prevCategoryId = get(productDataProps, ["product", "categories", "0", "id"], "");
  const prevCategory = categoryData.find((category) => category.id === prevCategoryId);
  const selectCategory = categoryData.find((category) => category.id === selectedCategoryId);
  const [productIsActive, setProductIsActive] = useState(true);
  const [productIsFree, setProductIsFree] = useState(false);
  const [propertyListData, setPropertyListData] = useState<ProductPropertiesType[]>(
    initProperties as ProductPropertiesType[],
  );
  const [variationListData, setVariationListData] = useState<ProductVariationsType[]>(
    getInitAllTypesFromProductSKUs(initProductSKUs),
  );
  const [productId, setProductId] = useState(isEditMode ? get(productDataProps, ["product", "id"], "") : "");

  const { setGuidelineCompletedStepCount } = useContext(GuidelineContext);

  const [productData, setProductData] = useState<Product>({
    type: ProductType.DIGITAL_CONTENT,
    contentType: isEditMode ? get(productDataProps, ["product", "contentType"], "") : "",
    outputType: isEditMode ? get(productDataProps, ["product", "outputType"], undefined) : undefined,
    name: isEditMode ? get(productDataProps, ["product", "name"], "") : "",
    brand: isEditMode ? get(productDataProps, ["product", "brand"], "") : "",
    productCode: isEditMode ? get(productDataProps, ["product", "productCode"], "") : "",
    cfCode: isEditMode ? get(productDataProps, ["product", "cfCode"], "") : "",
    description: isEditMode ? get(productDataProps, ["product", "description"], "") : "",
    additionalDescription: isEditMode ? get(productDataProps, ["product", "additionalDescription"], "") : "",
    unit: isEditMode ? get(productDataProps, ["product", "unit"], "") : "",
    properties: initProperties as ProductPropertiesType[],
    isCustomSKU: true, // all product must contain 1 sku
    images: initImages,
    defaultPrice: isEditMode ? get(productDataProps, ["product", "defaultPrice"], 0) : 0,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    productSKUs: (getInitSKUsConvertImageObject(productId, initProductSKUs) as any) || [],
  });

  const methods = useForm({
    resolver: yupResolver(addNewDigitalProductSchema),
    context: { outputType: productData.outputType },
  });

  const { register, errors, handleSubmit, setValue } = methods;

  const [disableTabAfterAddedProductSuccess, setDisableTabAfterAddedProductSuccess] = useState(false);

  const handleChangeTab = (_event: ChangeEvent<{}> | null, val: number) => {
    setStateTab(PRODUCT_TABS[val]);
  };

  useEffect(() => {
    // listen onchange data and validate required fields
    register({ name: "productName" });
    register({ name: "productCode" });
    register({ name: "cfCode" });

    register({ name: "additionalDescription" });
    register({ name: "productPrice" });
    register({ name: "selectedCategoryId" });
    register({ name: "properties" });
    register({ name: "productSKUs" });
    register({ name: "contentType" });

    setValue("contentType", productData.contentType);
    setValue("additionalDescription", productData.additionalDescription);
    setValue("productName", productData.name);
    setValue("productCode", productData.productCode);
    setValue("cfCode", productData.cfCode);
    setValue("productPrice", productData.defaultPrice);
    setValue("selectedCategoryId", selectedCategoryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeProductSKUs = (productSKUsData: AddProductSKUType[]) => {
    setValue("productSKUs", productSKUsData);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setProductData({ ...productData, productSKUs: productSKUsData as any });
  };

  const addProductDetailsPassedValidate = () => {
    // ("Passed validate goto step 2");
    if (!productData.productSKUs.length) {
      // if no productSKUs yet, then add one shadow SKU
      handleChangeProductSKUs([
        {
          productCode: productData.productCode,
          cfCode: productData.cfCode, // CF_CODE_ENABLE
          price: productData.defaultPrice,
          productType: [],
          selected: productIsActive,
          isFree: productIsFree,
          image: "",
          // digitalContentOptions is require
        },
      ]);
    }
    handleChangeTab(null, 1);
  };

  const isProductWithShadowSKU = variationListData.length === 0;

  const updateProductSKUsWithProductDetailSetting = (productSKUs: AddProductSKUType[]): AddProductSKUType[] => {
    // to replace price when its is shadow skus
    return productSKUs.map<AddProductSKUType>((productSKU) => {
      return {
        ...productSKU,
        price: isProductWithShadowSKU ? productData.defaultPrice : productSKU.price,
        productCode: isProductWithShadowSKU ? productData.productCode : productSKU.productCode,
        cfCode: isProductWithShadowSKU ? productData.cfCode : productSKU.cfCode,
      };
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cleanDataProductSKUs: any =
    removeTypenameField(
      removeFieldByName(productData.productSKUs, [
        "nanoid",
        "productId",
        "inventory",
        "reserved",
      ]) as AddProductSKUType[],
    ) || [];

  const cleanDataProductSKUWithDigitalContentOptions = cleanDataProductSKUs.map((productSKU: AddProductSKUType) => {
    return {
      ...productSKU,
      digitalContentOptions: removeEmptyObjects(productSKU.digitalContentOptions),
    };
  });

  const [addProduct, { data: dataAddProduct, loading: isAddingProduct }] = useMutation(ADD_PRODUCT, {
    variables: {
      projectId,
      categoryId: selectedCategoryId,
      product: {
        type: productData.type,
        contentType: productData.contentType,
        outputType: productData.outputType,
        additionalDescription: productData.additionalDescription,
        name: productData.name,
        brand: productData.brand,
        unit: productData.unit,
        productCode: productData.productCode,
        cfCode: productData.cfCode,
        description: productData.description,
        images: productData.images.map((image) => ({ src: image.src })), // Convert to input form
        defaultPrice: productData.defaultPrice,
        productSKUs: updateProductSKUsWithProductDetailSetting(cleanDataProductSKUWithDigitalContentOptions),
        isCustomSKU: !isProductWithShadowSKU, // all product must contain 1 sku this is shadow
        properties: propertyListData,
      },
    },
  });

  const [editProduct, { data: dataEditProduct, loading: isSubmitEditProduct }] = useMutation(EDIT_PRODUCT, {
    variables: {
      projectId,
      productId,
      categoryId: selectedCategoryId,
      product: {
        id: productId,
        type: productData.type,
        contentType: productData.contentType,
        outputType: productData.outputType,
        additionalDescription: productData.additionalDescription,
        name: productData.name,
        brand: productData.brand,
        unit: productData.unit,
        productCode: productData.productCode,
        cfCode: productData.cfCode,
        description: productData.description,
        images: productData.images.map((image) => ({ src: image.src })), // Convert to input form
        defaultPrice: productData.defaultPrice,
        productSKUs: updateProductSKUsWithProductDetailSetting(cleanDataProductSKUWithDigitalContentOptions),
        isCustomSKU: !isProductWithShadowSKU, // all product must contain 1 sku this is shadow
        properties: removeTypenameField(propertyListData),
      },
    },
  });

  const responseProductId = get(dataAddProduct, "addProduct.id");
  const responseEditProductId = get(dataEditProduct, "editProduct.id");
  const tabValue = PRODUCT_TABS.findIndex((productTab) => productTab === stateTab);

  if (responseEditProductId) {
    closeProductModal(closeProductModal);
  }

  if (responseProductId && responseProductId !== productId) {
    handleChangeTab(null, 2);
    setProductId(responseProductId);
    setDisableTabAfterAddedProductSuccess(true);
  }

  const editProductSKUs = async () => {
    try {
      await editProduct();
      notifySuccess(t(NOTIFICATION_UPDATE.SUCCESS));
    } catch (err) {
      const gqlErrorMessage: string = get(err, "graphQLErrors.0.message");
      const errorMessage = mapErrorMessage(gqlErrorMessage);
      notifyWarn(t(errorMessage), { autoClose: 8000 });
    }
  };

  const handleEditProduct = async () => {
    const isExistInHighlightProducts = !!prevCategory?.highlightProducts?.find((product) => product.id === productId);
    if (isExistInHighlightProducts && prevCategoryId !== selectedCategoryId) {
      setIsOpenModalDetectHighlightProducts(true);
      return;
    }
    editProductSKUs();
  };

  const submitProductPassedValidate = async () => {
    try {
      if (!isEditMode) {
        await addProduct();
        addGoogleTagEvent(GTM_EVENT.ADD_PRODUCT);
        notifySuccess(t(NOTIFICATION_CREATE.SUCCESS));
        setGuidelineCompletedStepCount(projectId);
      } else {
        handleEditProduct();
      }
    } catch (err) {
      const gqlErrorMessage: string = get(err, "graphQLErrors.0.message");
      const errorMessage = mapErrorMessage(gqlErrorMessage);
      notifyWarn(t(errorMessage), { autoClose: 8000 });
    }
  };

  const handleValidateForm = handleSubmit(addProductDetailsPassedValidate);
  const handleSubmitForm = handleSubmit(submitProductPassedValidate);

  const handleAddNewProperty = (newPropertyName: string) => {
    const newPropertiesData = [...propertyListData, { name: newPropertyName, value: "" }];
    setValue("properties", newPropertiesData);
    setPropertyListData(newPropertiesData);
  };

  const tabConfig = {
    header: [
      {
        id: STATE_TAB.PRODUCT_DETAILS,
        title: "Product details",
        disabled: disableTabAfterAddedProductSuccess && !isEditMode,
      },
      {
        id: STATE_TAB.CONTENT,
        title: productData.outputType || "Content",
        disabled: (disableTabAfterAddedProductSuccess || !productData.productSKUs.length) && !isEditMode,
      },
      {
        id: STATE_TAB.INVENTORY,
        title: "Inventory",
        disabled: isEditMode ? false : !responseProductId,
      },
    ],
    content:
      // eslint-disable-next-line no-nested-ternary
      stateTab === STATE_TAB.PRODUCT_DETAILS ? (
        <DigitalContentProductDetails
          errors={errors}
          categoryData={categoryData}
          handleValidateForm={handleValidateForm}
          handleSubmitForm={handleSubmitForm}
          productSKUs={productData.productSKUs}
          selectedCategoryId={selectedCategoryId}
          setIsAddPropertyModalOpen={setIsAddPropertyModalOpen}
          setProductSKUs={handleChangeProductSKUs}
          setSelectedCategoryId={setSelectedCategoryId}
          setValue={setValue}
          productData={productData}
          setProductData={setProductData}
          propertyListData={propertyListData}
          setPropertyListData={setPropertyListData}
          productIsActive={productIsActive}
          productIsFree={productIsFree}
          setProductIsActive={setProductIsActive}
          setProductIsFree={setProductIsFree}
          setVariationListData={setVariationListData}
          isEditMode={isEditMode}
          isProductWithShadowSKU={isProductWithShadowSKU}
        />
      ) : stateTab === STATE_TAB.CONTENT ? (
        <Grid container>
          <Grid item xs={12}>
            <DigitalProductContentTab
              productData={productData}
              setProductData={setProductData}
              handleSubmitForm={handleSubmitForm}
              setValue={setValue}
              productIsActive={productIsActive}
              productIsFree={productIsFree}
              productSKUs={productData.productSKUs}
              propertyListData={propertyListData}
              setProductSKUs={handleChangeProductSKUs}
              setVariationListData={setVariationListData}
              variationListData={variationListData}
              isDisplaySave={false}
              productType={productData.type}
              outputType={productData.outputType}
              isEditMode={isEditMode}
            />
          </Grid>
        </Grid>
      ) : (
        <ProductInventoriesContainer
          isEditMode={isEditMode}
          projectId={projectId}
          productId={productId}
          closeProductModal={closeProductModal}
          productType={ProductType.DIGITAL_CONTENT}
        />
      ),
  };

  if (isAddingProduct || isSubmitEditProduct) {
    return (
      <Modal isOpen onClose={() => {}}>
        <CircularProgress className="m-4" />
      </Modal>
    );
  }

  return (
    <Wrapper>
      <DetectChangeCategoryModal
        isOpen={isOpenModalDetectHighlightProducts}
        onCancel={() => setIsOpenModalDetectHighlightProducts(false)}
        onConfirm={editProductSKUs}
        prevCategoryName={prevCategory?.name as string}
        selectCategoryName={selectCategory?.name as string}
      />
      <ProductModalHeader>
        <Typography variant="title7" color="white" className="ml-4">
          {isEditMode ? t("product.digitalContent.editTitle") : t("product.digitalContent.addTitle")}
        </Typography>
      </ProductModalHeader>
      <FormProvider {...methods}>
        <Tab tabConfig={tabConfig} value={tabValue} onChange={handleChangeTab} />
      </FormProvider>
      {isAddPropertyModalOpen && (
        <AddPropertyModal
          isOpen={isAddPropertyModalOpen}
          onClose={() => setIsAddPropertyModalOpen(false)}
          closeModal={() => setIsAddPropertyModalOpen(false)}
          addNewProperty={handleAddNewProperty}
          propertyListData={propertyListData}
        />
      )}
    </Wrapper>
  );
};

export default AddDigitalContentProductForm;
