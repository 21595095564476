const LEFT_STICKY_PANEL_MAX_WIDTH = 422;
const CHECKBOX_COLUMN_WIDTH = 56;

const getCustomer = (t: Function) => ({
  Header: t("Customer"),
  accessor: "customerName",
  width: (LEFT_STICKY_PANEL_MAX_WIDTH - CHECKBOX_COLUMN_WIDTH) / 3 - 10,
});

export default getCustomer;
