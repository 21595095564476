import { gql } from "@apollo/client";

export const UPDATE_ADMIN_NOTE = gql`
  mutation updateOrderReferenceCodeAndAdminsNote(
    $projectId: ID!
    $orderId: ID!
    $referenceCode: String
    $adminsNote: String
  ) {
    updateOrderReferenceCodeAndAdminsNote(
      projectId: $projectId
      orderId: $orderId
      referenceCode: $referenceCode
      adminsNote: $adminsNote
    ) {
      id
      orderNumber
      state
      referenceCode
      adminsNote
      updatedAt
    }
  }
`;
