import React from "react";
import get from "lodash/get";
import { convertPriceFormat } from "utils/common";
import Box from "@material-ui/core/Box";
import moment from "moment";

import i18n from "utils/i18n";
import { CellProps, useSortBy, useFlexLayout, useExpanded } from "react-table";
import Typography from "components/Typography";
import { SvgIcon } from "components/Icon";
import { IcIncome, IcInfo } from "components/SvgIcons";
import Tooltip from "components/Tooltip";
import COLORS from "constants/Colors";
import { DEFAULT_AVATAR_IMG } from "constants/Image";
import { DeeplePayPaymentChannel } from "types/Order";

import useSelection from "./hooks/useSelection";

import { DEFAULT_VALUE } from "../config";

export const tableHooks = [useSortBy, useExpanded, useFlexLayout, useSelection];

export const PAYMENT_CHANNEL_DISPLAY_MAPPING: any = {
  title: {
    TRUE_MONEY_WALLET: "deeplePay.transaction.table.income.paymentChannel.title.trueMoneyWallet",
    KBANK: "deeplePay.transaction.table.income.paymentChannel.title.kplus",
    SCB: "deeplePay.transaction.table.income.paymentChannel.title.scb",
    KMA: "deeplePay.transaction.table.income.paymentChannel.title.kma",
    QR_CASH: "deeplePay.transaction.table.income.paymentChannel.title.thaiQRPayment",
    VIS: "deeplePay.transaction.table.income.paymentChannel.title.visa",
    MAS: "deeplePay.transaction.table.income.paymentChannel.title.mastercard",
  },
  subtitle: {
    TRUE_MONEY_WALLET: "deeplePay.transaction.table.income.paymentChannel.subtitle.eWallet",
    MOBILE_BANKING: "deeplePay.transaction.table.income.paymentChannel.subtitle.mobileBanking",
    QR_CASH: "deeplePay.transaction.table.income.paymentChannel.subtitle.qrCode",
    CREDIT_CARD: "deeplePay.transaction.table.income.paymentChannel.subtitle.creditCard",
  },
};

export const getColumns = <T extends object>() => {
  return [
    {
      id: "deeplePay.transaction.table.income.header.customerName",
      disableSortBy: true,
      Header: () => i18n.t("deeplePay.transaction.table.income.header.customerName"),
      width: 210,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <Box display="flex" alignItems="center">
            <Box width="24px" height="24px" className="mr-3" overflow="hidden">
              <img
                src={get(cell, "row.original.order.pictureUrl") || DEFAULT_AVATAR_IMG}
                alt="profile"
                style={{ width: "24px", height: "24px", borderRadius: "50%" }}
                onError={(e: any) => {
                  // eslint-disable-next-line
                  e.target.onerror = null;
                  // eslint-disable-next-line
                  e.target.src = DEFAULT_AVATAR_IMG;
                }}
              />
            </Box>
            <Box>
              <Typography variant="body3" color={COLORS.DarkGray}>
                {get(cell, "row.original.order.name")}
              </Typography>
              <Typography variant="body4" color={COLORS.DarkLight}>
                {get(cell, "row.original.order.orderNumber")}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      id: "deeplePay.transaction.table.income.header.paymentAmount",
      disableSortBy: true,
      Header: () => i18n.t("deeplePay.transaction.table.income.header.paymentAmount"),
      width: 210,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        return (
          <Box display="flex" alignItems="center">
            <SvgIcon component={IcIncome} className="p-0" viewBox="0 0 20 20" />
            <Typography variant="body3" color={COLORS.DarkGray}>
              ฿ {convertPriceFormat(get(cell, "row.original.amount"))}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: "deeplePay.transaction.table.income.header.paymentChannel",
      disableSortBy: true,
      Header: () => i18n.t("deeplePay.transaction.table.income.header.paymentChannel"),
      width: 210,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const subtitle = get(cell, "row.original.paymentChannel.title");
        let title = "";

        switch (subtitle) {
          case DeeplePayPaymentChannel.MOBILE_BANKING: {
            title = get(cell, "row.original.variable.bank");
            break;
          }
          case DeeplePayPaymentChannel.CREDIT_CARD: {
            title = get(cell, "row.original.response.cardType");
            break;
          }
          default: {
            title = subtitle;
          }
        }

        const displaySubtitle = PAYMENT_CHANNEL_DISPLAY_MAPPING.subtitle[subtitle];
        const displayTitle = PAYMENT_CHANNEL_DISPLAY_MAPPING.title[title];

        return (
          <Box display="flex" alignItems="center">
            <Typography variant="body3" className="d-block" color={COLORS.DarkGray}>
              {i18n.t(displayTitle)}

              <Typography variant="body4" className="d-block" color={COLORS.DarkLight}>
                {i18n.t(displaySubtitle)}
              </Typography>
            </Typography>
          </Box>
        );
      },
    },
    {
      id: "deeplePay.transaction.table.income.header.createdDate",
      disableSortBy: true,
      Header: () => i18n.t("deeplePay.transaction.table.income.header.createdDate"),
      width: 210,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const created = get(cell, "row.original.createdDate");
        return (
          <Typography variant="body3" color={COLORS.DarkGray}>
            {created ? moment(created).format("DD MMM YYYY HH:mm") : "-"}
          </Typography>
        );
      },
    },
    {
      id: "deeplePay.transaction.table.income.header.status",
      disableSortBy: true,
      Header: () => i18n.t("deeplePay.transaction.table.income.header.status"),
      width: 140,
      accessor: "status",
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const status = get(cell, "row.original.status")?.toLowerCase();
        const refundStatus = get(cell, "row.original.refundStatus");
        const refundStatusTranslationKey =
          refundStatus && `deeplePay.transaction.table.refund.status.${refundStatus.toLowerCase()}`;
        const classname = refundStatus ? `dot refuned` : `dot ${status}`;

        return (
          <div className="status">
            <span className={classname} />
            {i18n.t(`deeplePay.transaction.table.${status}`)}
            {refundStatus && (
              <Typography variant="body4" color={COLORS.DarkLight}>
                {`(${i18n.t(refundStatusTranslationKey)})`}
              </Typography>
            )}
          </div>
        );
      },
    },
  ];
};

const MAPPING_STATUS_TO_COLUMNS = {
  PROCESSING: ["receivedAmount", "fee", "referenceNo"],
  COMPLETED: ["receivedAmount", "fee", "referenceNo"],
  VOIDED: ["receivedAmount", "fee", "referenceNo"],
  DECLINED: ["receivedAmount", "fee", "referenceNo"],
  DEFAULT: ["receivedAmount", "fee", "referenceNo"],
};

const SUB_TABLE_COLUMNS = [
  {
    // eslint-disable-next-line react/display-name
    Header: () => (
      <div style={{ display: "flex", alignItems: "center" }}>
        {i18n.t("deeplePay.transaction.table.income.row.receivedAmount")}
        <Tooltip title={i18n.t("deeplePay.transaction.table.tooltip.receivedAmount") as string} placement="right" arrow>
          <SvgIcon viewBox="-6 -6 24 24">
            <IcInfo />
          </SvgIcon>
        </Tooltip>
      </div>
    ),
    width: 210,
    accessor: "receivedAmount",
    id: "receivedAmount",
    disableSortBy: true,
    Cell: (cell: any) => {
      const fee = cell.value;
      if (fee == null) {
        return DEFAULT_VALUE;
      }
      return `${typeof fee === "number" ? `฿ ${convertPriceFormat(fee)}` : fee}`;
    },
  },
  {
    // eslint-disable-next-line react/display-name
    Header: (cell: any) => {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {i18n.t("deeplePay.transaction.table.income.row.fee")}
          <Tooltip
            title={i18n.t("deeplePay.transaction.table.tooltip.vatIncluded", { vat: cell?.data[0].feeVAT }) as string}
            placement="right"
            arrow
          >
            <SvgIcon viewBox="-6 -6 24 24">
              <IcInfo />
            </SvgIcon>
          </Tooltip>
        </div>
      );
    },
    width: 210,
    accessor: "fee",
    id: "fee",
    disableSortBy: true,
    Cell: (cell: any) => {
      const fee = cell.value;
      if (fee == null) {
        return DEFAULT_VALUE;
      }
      return `${typeof fee === "number" ? `฿ ${convertPriceFormat(fee)}` : fee}`;
    },
  },
  {
    Header: () => i18n.t("deeplePay.transaction.table.income.row.referenceNo"),
    width: 210,
    accessor: "referenceNo",
    id: "referenceNo",
    disableSortBy: true,
  },
  {
    Header: () => i18n.t("deeplePay.transaction.table.income.row.refundDate"),
    width: 210,
    accessor: "refundDate",
    id: "refundDate",
    disableSortBy: true,
    Cell: (cell: any) => {
      const refundDate = cell.value;

      return moment(refundDate).format("DD MMM YYYY HH:mm");
    },
  },
  {
    Header: () => i18n.t("deeplePay.transaction.table.income.row.refundStatus"),
    width: 210,
    accessor: "refundStatus",
    id: "refundStatus",
    disableSortBy: true,
    Cell: (cell: any) => {
      const refundStatus = cell.value;

      return `(${i18n.t(`deeplePay.transaction.table.refund.status.${refundStatus.toLowerCase()}`)})`;
    },
  },
];
export const getSubTableColumns = (row: any) => {
  const isRefundTransaction = !!row.refundDate;
  // eslint-disable-next-line
  // @ts-ignore
  const mapColumnsByStatus: string[] = MAPPING_STATUS_TO_COLUMNS[row.status] || MAPPING_STATUS_TO_COLUMNS.DEFAULT;
  // eslint-disable-next-line
  // @ts-ignore
  const mapColumnsByStatusWithRefund: string[] = isRefundTransaction
    ? mapColumnsByStatus.concat(["refundDate"])
    : mapColumnsByStatus;
  return SUB_TABLE_COLUMNS.filter((column) => mapColumnsByStatusWithRefund.includes(column.id));
};
