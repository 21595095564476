import * as React from "react";

const SvgIcArrowDown = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <defs>
      <path id="ic-arrow-down_svg__a" d="M0 0h32v32H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="ic-arrow-down_svg__b" fill="#fff">
        <use xlinkHref="#ic-arrow-down_svg__a" />
      </mask>
      <path
        fill="currentColor"
        d="M14.64 17.31l5.646 5.645a1.38 1.38 0 11-1.951 1.95l-6.62-6.62a1.38 1.38 0 010-1.95l6.62-6.621a1.38 1.38 0 011.95 1.95L14.64 17.31z"
        mask="url(#ic-arrow-down_svg__b)"
        transform="matrix(0 -1 -1 0 33.31 33.31)"
      />
    </g>
  </svg>
);

export default SvgIcArrowDown;
