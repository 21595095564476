import React from "react";
import styled from "styled-components";
import { RadioGroup } from "@material-ui/core";

export const ProductCodeSearchContainer = styled(({ isDisabled: _isDisabled, ...rest }) => <div {...rest} />).attrs(
  ({ isDisabled }: { isDisabled: boolean }) => {
    return { isDisabled };
  },
)`
  border-radius: 5px;
  border: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
  background-color: ${({ theme, isDisabled }) => (isDisabled ? theme.COLORS.LightGray : theme.COLORS.White)};
  padding: 14px;
  cursor: pointer;
`;

export const RadioGroupCustom = styled((props) => <RadioGroup {...props} />)`
  flex-direction: column;

  label {
    margin-right: unset !important;
    width: 100%;
    padding-right: unset;
    padding-left: unset;

    :first-child {
      margin-bottom: 5px;
    }
  }
`;
