import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Grid from "components/Grid";
import Button from "components/Button";
import Typography from "components/Typography";
import Search from "components/Search";
import Pagination from "components/Pagination";
import CircularProgress from "components/CircularProgress";

import { DIGITAL_PRODUCT_LIMIT } from "config";
import { Device } from "types/Device";
import { ProductItemPropsType } from "types/Product";
import { ProjectIdType } from "types/Project";
import useDevice from "utils/hooks/useDevice";

import DigitalContent from "./DigitalContent";
import RedeemModal from "./RedeemModal";
import useDigitalProducts from "./hooks/useDigitalProducts";

const DigitalContentManagement: FC = () => {
  const { t } = useTranslation();
  const device = useDevice();
  const isMobile = Device.MOBILE === device;
  const { projectId = "" } = useParams<ProjectIdType>();

  const [isOpenRedeemModal, setIsOpenRedeemModal] = useState(false);

  const {
    isLoading,
    digitalProducts,
    totalDigitalProducts,
    pageNo,
    handlePageChange,
    handleChangeSearch,
    digitalProductsQueryVariables,
    refetchDigitalProduct,
  } = useDigitalProducts(projectId);

  const handleClickRedeemCodeButton = () => {
    setIsOpenRedeemModal(true);
  };

  const handleCloseRedeemCodeModal = () => {
    setIsOpenRedeemModal(false);
  };

  const handleRedeemComplete = () => {
    handleCloseRedeemCodeModal();
    refetchDigitalProduct();
  };

  const RedeemCodeButton = () => (
    <Grid item xs={isMobile ? 12 : "auto"} className={isMobile ? "pt-3 px-4" : "px-4"}>
      <Button color="primary" className="px-3" fullWidth={isMobile} onClick={handleClickRedeemCodeButton}>
        {t("contentManagement.modal.redeemCode.title")}
      </Button>
    </Grid>
  );

  return (
    <>
      <Grid container className="py-4">
        <Grid item container justify="space-between" alignItems="center">
          <Grid item className="px-4">
            <Typography variant="title1">{t("ContentManagement")}</Typography>
          </Grid>
          {!isMobile && <RedeemCodeButton />}
        </Grid>
        <Grid item xs={12} className="pt-4 px-4">
          <Search defaultValue="" onChange={handleChangeSearch} />
        </Grid>
        {isMobile && <RedeemCodeButton />}
        {isLoading ? (
          <Grid container justify="center">
            <CircularProgress className="m-4" />
          </Grid>
        ) : (
          <>
            {digitalProducts.map((product: ProductItemPropsType) => {
              const { id, name, images, productSKUs, defaultPrice, outputType } = product;
              const productImage = images?.[0]?.src || "";

              return (
                <Grid item xs={12} className={`pt-4 ${isMobile ? "" : "px-4"}`} key={id}>
                  <DigitalContent
                    productId={id}
                    productName={name}
                    productImage={productImage}
                    productSKUs={productSKUs}
                    defaultPrice={defaultPrice}
                    isActive
                    isMobile={isMobile}
                    outputType={outputType}
                    digitalProductsQueryVariables={digitalProductsQueryVariables}
                  />
                </Grid>
              );
            })}
            <Grid item xs={12} className="px-3 pt-3">
              <Pagination
                rowsPerPage={DIGITAL_PRODUCT_LIMIT}
                page={pageNo}
                totalItem={totalDigitalProducts}
                handlePageChange={handlePageChange}
                isMobile
              />
            </Grid>
          </>
        )}
      </Grid>
      <RedeemModal
        projectId={projectId}
        isOpen={isOpenRedeemModal}
        onClose={handleCloseRedeemCodeModal}
        isMobile={isMobile}
        onRedeemComplete={handleRedeemComplete}
      />
    </>
  );
};

export default DigitalContentManagement;
