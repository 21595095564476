import { useState } from "react";
import { useMutation, ApolloError } from "@apollo/client";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { DELETE_BANK_PAYMENT } from "graphql/payment/mutation";
import { PAYMENTS } from "graphql/payment/query";
import { PaymentsQueryType, PaymentType } from "types/Payment";

const useDeleteBankPayment = (projectId: string) => {
  const { t } = useTranslation();
  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(false);

  const [deleteBankPayment] = useMutation(DELETE_BANK_PAYMENT, {
    onError: (error: ApolloError) => {
      toast.error(`${t("Delete failed!")}, ${error}`, {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 3000,
      });
    },
    onCompleted: () => {
      toast.success(t("Delete successfully"), {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 1000,
      });
    },
    update(cache, { data: { deletePayment: deletedBankPayment } }) {
      const cachePayments = cache.readQuery<PaymentsQueryType>({
        query: PAYMENTS,
        variables: {
          projectId,
        },
      });

      if (cachePayments) {
        const updatedPayments = cachePayments.payments.filter(
          (payment: PaymentType) => payment.id !== deletedBankPayment.id,
        );

        cache.writeQuery({
          query: PAYMENTS,
          variables: {
            projectId,
          },
          data: {
            payments: updatedPayments,
          },
        });
      }
    },
  });

  const openConfirmModal = () => {
    setIsOpenConfirmDeleteModal(true);
  };

  const closeConfirmModal = () => {
    setIsOpenConfirmDeleteModal(false);
  };

  const handleConfirmDelete = (focusedBankPayment: string) => {
    deleteBankPayment({
      variables: {
        projectId,
        paymentId: focusedBankPayment,
      },
    });

    closeConfirmModal();
  };

  return {
    handleConfirmDelete,
    isOpenConfirmDeleteModal,
    openConfirmModal,
    closeConfirmModal,
  };
};

export default useDeleteBankPayment;
