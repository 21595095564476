import React, { FC } from "react";

import Grid from "components/Grid";
import Typography from "components/Typography";
import { SvgIcon } from "components/Icon";
import { IcSearch } from "components/SvgIcons";
import { COLORS } from "constants/Colors";
import RadioBox, { CheckedRadioIcon, RadioIcon } from "components/Radio/RadioBox";
import { Radio } from "@material-ui/core";
import { ProductSelectorType } from "types/Promotion";
import { useTranslation } from "react-i18next";
import { ProductCodeSearchContainer, RadioGroupCustom } from "./style";

type ProductSelectorProps = {
  isDisabled?: boolean;
  isIncludedAllProducts: boolean;
  placeholder: string;
  handleSetIsIncludedAllProducts: (isIncludedAllProducts: boolean) => void;
  onClickBrowse?: () => void;
};

const ProductCodeSelector: FC<ProductSelectorProps> = ({
  handleSetIsIncludedAllProducts,
  isIncludedAllProducts,
  onClickBrowse,
  placeholder,
  isDisabled = false,
}) => {
  const { t } = useTranslation();

  const handleRadioGroupOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isIncludedAllProducts = event.target.value !== ProductSelectorType.SPECIFY_PRODUCTS;
    handleSetIsIncludedAllProducts(isIncludedAllProducts);
  };

  const handleClickProductSelector = () => {
    if (!isDisabled && onClickBrowse) {
      onClickBrowse();
    }
  };

  return (
    <>
      <RadioGroupCustom
        row
        name="isIncludedAllProducts"
        onChange={handleRadioGroupOnChange}
        defaultValue={isIncludedAllProducts ? ProductSelectorType.ALL_PRODUCTS : ProductSelectorType.SPECIFY_PRODUCTS}
        className="mb-3"
      >
        <RadioBox
          isActive={!isIncludedAllProducts}
          isDisabled={isDisabled}
          value={ProductSelectorType.SPECIFY_PRODUCTS}
          control={
            <Radio
              color="primary"
              icon={<RadioIcon />}
              checkedIcon={<CheckedRadioIcon />}
              disabled={isDisabled}
              className="mr-2"
            />
          }
          label={
            <>
              <Typography variant="title7" color="dark">
                {t("promotion.productSelector.specifyProducts")}
              </Typography>
              <Typography className="mt-1" variant="body3" color="darkGray">
                {t("promotion.productSelector.specifyProducts.description")}
              </Typography>
            </>
          }
        />
        <RadioBox
          isActive={isIncludedAllProducts}
          isDisabled={isDisabled}
          value={ProductSelectorType.ALL_PRODUCTS}
          control={
            <Radio
              color="primary"
              icon={<RadioIcon />}
              disabled={isDisabled}
              checkedIcon={<CheckedRadioIcon />}
              className="mr-2"
            />
          }
          label={
            <>
              <Typography variant="title7" color="dark">
                {t("promotion.productSelector.allProduct")}
              </Typography>
              <Typography className="mt-1" variant="body3" color="darkGray">
                {t("promotion.productSelector.allProduct.description")}
              </Typography>
            </>
          }
        />
      </RadioGroupCustom>
      {!isIncludedAllProducts ? (
        <ProductCodeSearchContainer onClick={handleClickProductSelector} isDisabled={isDisabled}>
          <Grid container justify="space-between" className="flex-nowrap">
            <Grid item container className="flex-grow-1">
              <SvgIcon
                component={IcSearch}
                fontSize="small"
                className="m-0 p-0"
                htmlColor={isDisabled ? COLORS.LightBlue : COLORS.DarkMed}
              />
              <Typography className="d-inline pl-3" variant="body3" color={isDisabled ? "lightBlue" : "darkMed"}>
                {placeholder}
              </Typography>
            </Grid>
            <Grid item className="flex-grow-0">
              <Typography variant="body4" color={isDisabled ? "lightBlue" : "accent"} className="px-2">
                {t("facebookLiveForm.productSelector.browse")}
              </Typography>
            </Grid>
          </Grid>
        </ProductCodeSearchContainer>
      ) : undefined}
    </>
  );
};

export default ProductCodeSelector;
