const verifyEmail = {
  en: {
    "Verify Email": "Verify Email",
    "Your email have been verified": "Your email have been verified",
    "You can now sign in with your account.": "You can now sign in with your account.",
  },
  th: {
    "Verify Email": "ยืนยันอีเมล",
    "Your email have been verified": "อีเมลของคุณได้รับการยืนยัน",
    "You can now sign in with your account.": "คุณสามารถใช้บัญชีของคุณเข้าสู่ระบบได้แล้ว",
  },
};

export default verifyEmail;
