import { useState } from "react";
import { ShipmentStatusFilter, SortByType } from "types/Report";
import { OrderState, ShippingCalculationType } from "types/Order";
import { DEFAULT_ROWS_PER_PAGE, DEFAULT_PAGE_START } from "config/shippop";

export type Filter = {
  shippingCalculationType?: ShippingCalculationType[];
  shipmentStatus?: ShipmentStatusFilter[];
  orderState?: OrderState[];
  dateRange?: {
    start?: string;
    end?: string;
  };
  orderNumber?: string;
};

const usePaginationState = () => {
  const [filter, setFilter] = useState<Filter>({
    orderState: [OrderState.PREPARE_TO_SHIPPING, OrderState.COD_PREPARE_TO_SHIP],
    shippingCalculationType: [ShippingCalculationType.SHIPPOP],
    shipmentStatus: [ShipmentStatusFilter.CANCEL, ShipmentStatusFilter.CANCELED, ShipmentStatusFilter.NO_SHIPMENT],
    orderNumber: "",
  });
  const [sortBy, setSortBy] = useState<SortByType>({});
  const [page, setPage] = useState(DEFAULT_PAGE_START);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  return {
    filter,
    page,
    rowsPerPage,
    setFilter,
    setPage,
    setRowsPerPage,
    setSortBy,
    sortBy,
  };
};

export default usePaginationState;
