import React, { FC, SyntheticEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stepper } from "@material-ui/core";
import { useController, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@apollo/client";
import useDevice from "utils/hooks/useDevice";
import { Device } from "types/Device";
import Grid from "components/Grid";
import { BillingType } from "types/Billing";
import { ImageUrl } from "types/Image";
import { Bank } from "types/Bank";
import { SUBMIT_KYC } from "graphql/deeplePay/mutation";
import { notifyError } from "utils/notify";
import AccordionStepper from "components/AccordionStepper";
import Typography from "components/Typography";
import { get, isEmpty, omit } from "lodash";
import { KYCInformationType, KYCInfo, KYCVerificationInput } from "types/DeeplePay";
import COLORS from "constants/Colors";
import { SvgIcon } from "components/Icon";
import { IcInfo } from "components/SvgIcons";
import { TitleOptions } from "constants/information";
import { bankAccountSchema, CompanyInfoSchema, PersonalInfoSchema } from "./validateSchema";
import Success from "./Success";
import BankingDetail from "./BankingDetail";
import Confirmation from "./Confirmation";
import PersonalDetail from "./PersonalDetail";
import {
  ButtonWrapper,
  DetailWrapper,
  MobileFooter,
  MobileWrapper,
  Modal,
  ModalWrapper,
  StepItem,
  MobileDetail,
  KYCButton,
  StepperItem,
  PersonalInformationBox,
} from "./styled";
import AttachmentExample from "./AttachmentExample";

interface KYCPopupPropsType {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  refetchBilling: () => void;
  billing: BillingType;
}

export interface BankFormData {
  bank?: Bank;
  bookBankImage?: string;
  bankAccountName?: string;
  bankAccountNo?: string;
}

export type PersonalValidation = KYCInfo & {
  titleName?: string;
};

const KYCPopup: FC<KYCPopupPropsType> = ({ isOpen, onClose, projectId }) => {
  const { t } = useTranslation();
  const device = useDevice();
  const isMobile = device === Device.MOBILE;
  const steps = [
    "deeplePay.verifyBilling.step.header.attachBankAccount",
    "deeplePay.verifyBilling.step.header.personalDetail",
    "deeplePay.verifyBilling.step.header.confirmation",
  ];
  const initialPersonalDetail = {
    type: KYCInformationType.PERSONAL,
    documentVerificationCertificatePath: "",
    titleName: "",
    name: "",
    taxId: "",
    address: "",
    subDistrict: "",
    district: "",
    province: "",
    postalCode: "",
  };
  const [page, setPage] = useState(0);
  const [bookBankImage, setBookBankImage] = useState<string>();
  const [bank, setBank] = useState<string>();
  const [isDirty, setIsDirty] = useState(false);
  const [bankData, setBankData] = useState<BankFormData>();
  const [personalDetail, setPersonalDetail] = useState<PersonalValidation>(initialPersonalDetail);
  const [exampleType, setExampleType] = useState<"bankAccount" | "personal" | "business">();
  const imagePath = useRef<string>();
  const {
    register,
    unregister,
    errors,
    handleSubmit,
    reset,
    formState: { dirtyFields, isSubmitting },
    control,
    clearErrors,
  } = useForm<BankFormData>({
    resolver: yupResolver(bankAccountSchema),
    reValidateMode: "onChange",
  });
  const {
    register: personalRegister,
    handleSubmit: handleSubmitPersonal,
    errors: personalErrors,
    setValue,
    control: personalControl,
    reset: resetPersonalData,
    watch,
  } = useForm<PersonalValidation>({
    resolver: yupResolver(personalDetail.type === KYCInformationType.PERSONAL ? PersonalInfoSchema : CompanyInfoSchema),
  });

  const isBankingPage = page === 0;
  const isPersonalPage = page === 1;
  const isConfirmationPage = page === 2;
  const isSuccessPage = page === 3;
  const isValidatedBanking = (Object.keys(omit(dirtyFields, ["bank"])).length >= 3 && isEmpty(errors)) || isDirty;
  const [submitKYCData] = useMutation(SUBMIT_KYC, {
    onCompleted: () => setPage(3),
    onError: () => notifyError(t("BILLING.DEFAULT_ERROR")),
  });

  // tracking validation to image field
  const { field } = useController({
    name: "bookBankImage",
    control,
  });

  // tracking validation to image field
  const { field: bankField } = useController({
    name: "bank",
    control,
  });

  // tracking validation to personal attachmentimage field
  const { field: personalImageField } = useController({
    name: "documentVerificationCertificatePath",
    control: personalControl,
  });

  const handleSetBankDetail = (field: keyof BankFormData, value: string) => {
    setBankData((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleSetKYCDetail = (field: keyof PersonalValidation, value: string | KYCInformationType) => {
    setPersonalDetail((prevState) => ({ ...prevState, [field]: value }));
    setValue(field, value);
  };

  const handleChangeImage = (type: "personal" | "bankAccount", image: ImageUrl[]) => {
    if (image.length) {
      const [{ publicUrl, filePath }] = image;
      if (type === "bankAccount") {
        setBookBankImage(publicUrl);
        imagePath.current = publicUrl;
        field.onChange(filePath);
        field.onBlur();
      } else {
        setPersonalDetail({ ...personalDetail, documentVerificationCertificatePath: publicUrl });
        setValue("documentVerificationCertificatePath", publicUrl);
        imagePath.current = publicUrl;
        personalImageField.onChange(filePath);
        personalImageField.onBlur();
      }
    }
  };

  const handleSelectBank = (e: SyntheticEvent) => {
    const value = get(e, "target.value") || "";
    setBank(value);
    bankField.onChange(value);
    bankField.onBlur();
  };

  const handleRemoveImage = (type: "personal" | "bankAccount") => {
    if (type === "bankAccount") {
      setBookBankImage("");
      field.onChange("");
      field.onBlur();
    } else {
      setPersonalDetail({ ...personalDetail, documentVerificationCertificatePath: "" });
    }
  };

  const handleSubmitNextPage = (formData: BankFormData) => {
    setBankData(formData);
    setPage(1);
  };

  const handleSubmitPersonalData = () => {
    setPage(2);
  };

  const handleSubmitKYC = async () => {
    if (bankData && bankData?.bookBankImage && bankData?.bankAccountName && bankData?.bankAccountNo && bankData?.bank) {
      const input: KYCVerificationInput = {
        firstPageOfBankAccountPath: bankData?.bookBankImage,
        bankAccountName: bankData?.bankAccountName,
        bankAccountNo: bankData?.bankAccountNo,
        bank: bankData?.bank,
        ...personalDetail,
        postalCode: `${personalDetail.postalCode}`,
        titleName: personalDetail.type === KYCInformationType.PERSONAL ? personalDetail.titleName : undefined,
        documentVerificationCertificatePath: watch("documentVerificationCertificatePath"),
      };
      await submitKYCData({ variables: { projectId, kycVerificationInput: input } });
    }
  };

  const handleClose = () => {
    setPage(0);
    unregister(["bookBankImage", "bank", "bankAccountName", "bankAccountNo"]);
    clearErrors();
    reset();
    setBankData({});
    setBookBankImage("");
    onClose();
    setBank("");
    resetPersonalData();
    setExampleType(undefined);
    setPersonalDetail(initialPersonalDetail);
  };

  const handleBack = () => {
    if (exampleType) {
      setExampleType(undefined);
      return;
    }

    setPage(page - 1);
  };

  useEffect(() => {
    setBank(Bank.ANZ_BANK);
    handleSetKYCDetail("titleName", personalDetail?.titleName || TitleOptions[0].value);
    handleSetKYCDetail("type", personalDetail?.type || KYCInformationType.PERSONAL);
    bankField.onChange(Bank.ANZ_BANK);
    bankField.onBlur();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSubmitting) {
      setIsDirty(true);
    }
  }, [isSubmitting]);

  const bankDetailProps = {
    projectId,
    errors,
    register,
    bookBankImage,
    handleRemoveImage,
    handleChangeImage,
    control,
    bankData,
    isMobile,
    handleSelectBank,
    bank,
    setExampleType,
    handleSetBankDetail,
  };

  const personalProps = {
    handleChangeImage,
    handleRemoveImage,
    handleSetKYCDetail,
    personalDetail,
    isMobile,
    projectId,
    register: personalRegister,
    control: personalControl,
    errors: personalErrors,
    setExampleType,
  };

  const getPageDetail = () => {
    if (exampleType) {
      return (
        <AttachmentExample
          type={exampleType}
          isMobile={isMobile}
          setIsOpenAttachmentExample={() => setExampleType(undefined)}
        />
      );
    }

    if (isPersonalPage) {
      return <PersonalDetail {...personalProps} />;
    }

    if (isConfirmationPage) {
      return (
        <Confirmation banking={{ ...bankData, bookBankImage }} personalDetail={personalDetail} {...bankDetailProps} />
      );
    }

    if (isSuccessPage) {
      return <Success />;
    }

    return <BankingDetail {...bankDetailProps} />;
  };

  const getHandleSubmit = () => {
    if (isPersonalPage) {
      return handleSubmitPersonal(handleSubmitPersonalData);
    }

    if (isConfirmationPage) {
      return handleSubmitKYC;
    }

    return handleSubmit(handleSubmitNextPage);
  };

  return (
    <Modal fullScreen={isMobile} isOpen={isOpen} onClose={handleClose} title={t("deeplePay.verifyBilling.header")}>
      {!isMobile ? (
        <ModalWrapper container justify="center">
          <Grid item xs={12}>
            <Stepper className="p-0 mb-2" activeStep={page} alternativeLabel>
              {steps.map((label) => (
                <StepItem key={label}>
                  <StepperItem>
                    <Typography variant="title8">{t(label)}</Typography>
                  </StepperItem>
                </StepItem>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            {isPersonalPage && (
              <PersonalInformationBox className="wordBreak">
                <Typography color={COLORS.Accent} variant="body3" className="d-flex flex-row align-items-center">
                  <SvgIcon component={IcInfo} htmlColor={COLORS.Accent} fontSize="large" className="mx-3 pb-2" />
                  {t("deeplePay.verifyBilling.personalInfo.description")}
                </Typography>
              </PersonalInformationBox>
            )}
            <DetailWrapper className={isPersonalPage ? "info" : ""}>{getPageDetail()}</DetailWrapper>
          </Grid>
          <ButtonWrapper className="mt-3">
            {!isSuccessPage ? (
              <>
                <KYCButton
                  className="cancel"
                  minWidth={120}
                  size="small"
                  onClick={isBankingPage && !exampleType ? handleClose : handleBack}
                >
                  {isBankingPage && !exampleType
                    ? t("deeplePay.verifyBilling.button.cancel")
                    : t("deeplePay.verifyBilling.button.back")}
                </KYCButton>
                <KYCButton
                  className="ml-2"
                  minWidth={120}
                  size="small"
                  disabled={(!isValidatedBanking && isBankingPage) || exampleType}
                  onClick={getHandleSubmit()}
                >
                  {isConfirmationPage
                    ? t("deeplePay.verifyBilling.button.submit")
                    : t("deeplePay.verifyBilling.button.next")}
                </KYCButton>
              </>
            ) : (
              <KYCButton className="ml-2" minWidth={120} size="small" onClick={handleClose}>
                {t("deeplePay.verifyBilling.button.done")}
              </KYCButton>
            )}
          </ButtonWrapper>
        </ModalWrapper>
      ) : (
        <MobileDetail container>
          <MobileWrapper>
            <Grid item xs={12}>
              <AccordionStepper
                stepNumber={1}
                isShowIcon={false}
                completedChildren={isPersonalPage || isConfirmationPage || isSuccessPage}
              >
                <Typography variant={isBankingPage ? "title4" : "title8"}>{t(steps[0])}</Typography>
              </AccordionStepper>
            </Grid>
            {exampleType ? (
              <AttachmentExample
                type={exampleType}
                isMobile={isMobile}
                setIsOpenAttachmentExample={() => setExampleType(undefined)}
              />
            ) : (
              <>
                {isBankingPage && <BankingDetail {...bankDetailProps} />}
                <Grid item xs={12}>
                  <AccordionStepper
                    stepNumber={2}
                    isShowIcon={false}
                    completedChildren={isConfirmationPage || isSuccessPage}
                  >
                    <Typography variant={isPersonalPage ? "title4" : "title8"}>{t(steps[1])}</Typography>
                  </AccordionStepper>
                </Grid>
                {isPersonalPage && <PersonalDetail {...personalProps} />}
                <Grid item xs={12}>
                  <AccordionStepper stepNumber={3} isShowIcon={false} completedChildren={isSuccessPage}>
                    <Typography variant={isConfirmationPage ? "title4" : "title8"}>{t(steps[2])}</Typography>
                  </AccordionStepper>
                </Grid>
                {isConfirmationPage && (
                  <Confirmation
                    banking={{ ...bankData, bookBankImage }}
                    personalDetail={personalDetail}
                    {...bankDetailProps}
                  />
                )}
                {isSuccessPage && <Success />}
              </>
            )}
          </MobileWrapper>
          <MobileFooter>
            <Grid container justify="center">
              <Grid item className="mt-3 mb-3">
                {!isSuccessPage ? (
                  <>
                    <KYCButton
                      className="cancel"
                      minWidth={130}
                      size="small"
                      onClick={isBankingPage && !exampleType ? handleClose : handleBack}
                    >
                      {isBankingPage && !exampleType
                        ? t("deeplePay.verifyBilling.button.cancel")
                        : t("deeplePay.verifyBilling.button.back")}
                    </KYCButton>
                    <KYCButton
                      className="ml-2"
                      minWidth={130}
                      size="small"
                      disabled={(!isValidatedBanking && isBankingPage) || exampleType}
                      onClick={getHandleSubmit()}
                    >
                      {isConfirmationPage
                        ? t("deeplePay.verifyBilling.button.submit")
                        : t("deeplePay.verifyBilling.button.next")}
                    </KYCButton>
                  </>
                ) : (
                  <KYCButton className="ml-2" size="small" minWidth={120} onClick={handleClose}>
                    {t("deeplePay.verifyBilling.button.done")}
                  </KYCButton>
                )}
              </Grid>
            </Grid>
          </MobileFooter>
        </MobileDetail>
      )}
    </Modal>
  );
};

export default KYCPopup;
