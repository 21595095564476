import React from "react";
import styled from "styled-components";
import Grid from "components/Grid";

type AddonCardWrapperPropsType = {
  isSpaceAdded?: boolean;
};

export const AddonSummaryWrapper = styled(Grid)`
  background-color: ${({ theme }) => theme.COLORS.White};
  border: solid ${({ theme }) => theme.COLORS.LightBlue};
  border-width: 0.5px 0 0 0;
  box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.03);
`;

export const AddonCardWrapper = styled((props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isSpaceAdded, ...rest } = props;
  return <Grid {...rest} />;
})<AddonCardWrapperPropsType>`
  margin-bottom: ${({ isSpaceAdded }) => (isSpaceAdded ? "200px" : "16px")};
  padding: 0 16px;
`;

export const AddonFilterWrapper = styled(Grid)`
  display: -webkit-box;
  overflow: auto;
  padding: 16px;
`;

export const AddonSummaryDesktopWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
`;
