export const aramexDescription = {
  EN: null,
  TH: [
    { body: "พิมพ์ใบปะหน้าพัสดุที่ได้จากระบบ และแปะลงบนหน้ากล่องพัสดุ" },
    {
      body:
        "บริการรับพัสดุถึงบ้านลูกค้าและจัดส่งถึงปลายทางในวันถัดไป ( โดยการเข้ารับพัสดุขนส่งทำการ วันจันทร์ถึงวันเสาร์ หยุดวันอาทิตย์ )",
    },
    {
      body: "เบื้องต้น เปิดให้ใช้บริการเฉพาะพื้นที่โซน กรุงเทพมหานคร เท่านั้น",
    },
    {
      body:
        "ชำระค่าบริการก่อน 12.00 น. เข้ารับพัสดุในวันเดียวกัน หากสร้างรายการหลังจาก 12.01 น. เข้ารับพัสดุในวันถัดไป",
    },
    { body: "ประกันค่าความเสียหายและการสูญหายที่เกิดจากขนส่ง สูงสุดไม่เกิน 2,000 บาท" },
    {
      body:
        "ขนาดและน้ำหนักสินค้าที่สามารถเข้ารับได้ น้ำหนักไม่เกิน 10 กิโลกรัม สามารถเข้ารับสินค้าขนาดกล่อง ( กว้าง + ยาว + สูง ) ไม่เกิน 280 เซนติเมตร และ ด้านใดด้านหนึ่งต้อง ไม่เกิน 100 เซนติเมตร",
    },
    { body: "กรณี มีการเปลี่ยนแปลงที่อยู่จัดส่ง จะมีค่าบริการเพิ่มโดยค่าบริการราคาเดียวกับค่าขนส่ง" },
    { body: "กรณี มีการตีคืน/ส่งคืน จะมีค่าบริการเพิ่ม โดยค่าบริการราคาเดียวกับค่าขนส่ง" },
  ],
};
