import { PROMOTION_SHORT_NAME_LIMIT } from "config/promotion";

const promotion = {
  en: {
    "Campaign-promotion": "Campaign-promotion",
    "Add new promotion": "Add new promotion",
    "Edit promotion": "Edit promotion",
    "Do you want to remove this promotion?": "Do you want to remove this promotion?",
    "Main products": "Main products",
    "Free products": "Free products",
    "Promotion type": "Promotion type",
    "Select promotion type": "Select promotion type",
    "Promotion name": "Promotion name",
    "Promotion short name in badge": "Promotion short name in badge",
    "Promotion description": "Promotion description",
    "Add main product": "Add main product",
    "Add free product": "Add free product",
    "Search product by name": "Search product by name",
    "How much total prices for products bundle": "How much total prices for products bundle",
    "How many main products to bundle": "How many products do customer needs to buy to get promotion?",
    "Set fix price": "Set fix price",
    "Set fix discount": "Set fix discount",
    "Set % discount": "Set % discount",
    "Please enter Promotion name": "Please enter Promotion name",
    "Please enter Promotion short name": "Please enter Promotion short name",
    selectedMainProductError: "Please add at least {{requiredMainProductAmount}} main product",
    "Please add at least 1 free product": "Please add at least 1 free product",
    "Please set fixed price": "Please set fixed price",
    "Please set fixed discount": "Please set fixed discount",
    "Please set % discount": "Please set % discount",
    "Please set the product bundle amount. Product bundle value must not be empty or below 1":
      "Please set the product bundle amount. Product bundle value must not be empty or below 1",
    "Please set the free product bundle amount. Free product bundle value must not be empty or below 1":
      "Please set the free product bundle amount. Free product bundle value must not be empty or below 1",
    "Please set minimum cart price": "Please set minimum cart price",
    "Free gift": "Free gift",
    "Buy...Get...Free": "Buy...Get...Free",
    "Fixed price": "Fixed price",
    "Discount price": "Discount price",
    "% discount": "% discount",
    "Free cheapest product": "Free cheapest product",
    "Free shipping": "Free shipping",
    "Discount shipping price": "Discount shipping price",
    "% discount shipping": "% discount shipping",
    "For every purchase": "For every purchase",
    "When buy selected product": "When buy one of selected product at a specific quantity",
    "When buy selected product for every purchase": "When buy selected product for every purchase",
    "When cart reach specific price": "When buy at a specific price",
    "When buy any selected product": "When buy selected product at a specific quantity",
    "When buy any selected product reach specific price": "When buy selected product at a specific price",
    "Product amount must be greater than zero": "Product amount must be greater than zero",
    "Product price must be greater than zero": "Product price must be greater than zero",
    "Product amount must be integer number": "Product amount must be integer number",
    "Product price must be integer number": "Product price must be integer number",
    "% discount can not over 100": "% discount can not over 100",
    "minimum threshold amount at 2": "Product bundle value must be 2 or above",
    [`Promotion short name cannot exceed ${PROMOTION_SHORT_NAME_LIMIT} characters`]: `Promotion short name cannot exceed ${PROMOTION_SHORT_NAME_LIMIT} characters`,
    "Total price (include discount)": "Total price (include discount)",
    "Actual price": "Actual price",
    "Price for apply promotion": "Price for apply promotion",
    Amount: "Amount",
    or: "or",
    piece: "piece",
    piece_plural: "pieces",
    "when order above": "when order above",
    "when order specific above": "when order {{selectedProductListText}} above",
    baht: "baht",
    "when buy": "when buy",
    Discount: "Discount",
    "get free": "get free",
    Buy: "Buy",
    Shipping: "Shipping",
    "at price": "at price",
    free: "free",
    "total price": "total price",
    promotionTextFreeGiveaway: "Get {{freeProductList}}",
    promotionFreeProductText: "{{freeProduct}} free",
    "promotion.isRepeatable.label": "Apply promotion one time",
    "promotion.isRepeatable.repeat": "Repeat",
    "promotion.isRepeatable.oneTime": "One time",
    "promotion.addEditModal.noProduct": "No selected product",
    "promotion.addEditModal.allProduct": "This promotion is applied to all products in the store.",
    "promotion.productSelector.allProduct": "All product",
    "promotion.productSelector.allProduct.description": "Select all products in the store for the promotion.",
    "promotion.productSelector.specifyProducts": "Specific products",
    "promotion.productSelector.specifyProducts.description": "Select main products for the promotion.",
  },
  th: {
    "Campaign-promotion": "แคมเปญ-โปรโมชั่น",
    "Add new promotion": "เพิ่มโปรโมชั่น",
    "Edit promotion": "แก้ไขโปรโมชั่น",
    "Do you want to remove this promotion?": "คุณต้องการที่จะลบโปรโมชั่นนี้?",
    "Main products": "สินค้าหลัก",
    "Free products": "สินค้าฟรี",
    "Promotion type": "ประเภทโปรโมชั่น",
    "Select promotion type": "เลือกประเภทโปรโมชั่น",
    "Promotion name": "ชื่อโปรโมชั่น",
    "Promotion short name in badge": "ชื่อย่อโปรโมชั่น",
    "Promotion description": "รายละเอียดโปรโมชั่น",
    "Add main product": "เพิ่มสินค้าหลัก",
    "Add free product": "เพิ่มสินค้าฟรี",
    "Search product by name": "ค้นหาสินค้าจากชื่อ",
    "How much total prices for products bundle": "ราคารวมของสินค้า",
    "How many main products to bundle": "ซื้อสินค้ากี่ชิ้น เพื่อรับโปรโมชั่น",
    "Set fix price": "กำหนดราคาคงที่",
    "Set fix discount": "กำหนดส่วนลดคงที่",
    "Set % discount": "กำหนดเปอร์เซ็นต์ส่วนลด",
    "Please enter Promotion name": "กรุณาใส่ชื่อโปรโมชั่น",
    "Please enter Promotion short name": "กรุณาใส่ชื่อย่อโปรโมชั่น",
    selectedMainProductError: "กรุณาใส่สินค้าหลักอย่างน้อย {{requiredMainProductAmount}} ชิ้น",
    "Please add at least 1 free product": "กรุณาใส่สินค้าฟรีอย่างน้อย 1 ชิ้น",
    "Please set fixed price": "กรุณากำหนดราคาคงที่",
    "Please set fixed discount": "กรุณากำหนดส่วนลดคงที่",
    "Please set % discount": "กรุณากำหนดเปอร์เซ็นต์ส่วนลด",
    "Please set the free product bundle amount. Free product bundle value must not be empty or below 1":
      "กรุณากำหนดจำนวนสินค้าฟรี จำนวนสินค้าฟรี ต้องมีไม่ต่ำกว่า 1",
    "Please set the product bundle amount. Product bundle value must not be empty or below 1":
      "กรุณากำหนดจำนวนสินค้าหลัก จำนวนสินค้าหลัก ต้องมีไม่ต่ำกว่า 1",
    "Please set minimum cart price": "กรุณากำหนดราคารายการสั่งซื้อขั้นต่ำ",
    "Free gift": "ของแถม",
    "Buy...Get...Free": "ซื้อ...แถม...",
    "Fixed price": "ราคาพิเศษ",
    "Discount price": "ส่วนลดเป็นเงินบาท",
    "% discount": "ส่วนลดเป็นเปอร์เซ็นต์",
    "Free cheapest product": "สินค้าที่ถูกที่สุดฟรี",
    "Free shipping": "ฟรีค่าจัดส่ง",
    "Discount shipping price": "ส่วนลดค่าจัดส่งเป็นเงินบาท",
    "% discount shipping": "ส่วนลดค่าจัดส่งเป็นเปอร์เซ็นต์",
    "For every purchase": "ทุกการซื้อ",
    "When buy selected product": "เมื่อซื้อสินค้าที่กำหนดแบบเดียวกันในจำนวนที่กำหนด",
    "When buy selected product for every purchase": "เมื่อซื้อสินค้าที่กำหนดในทุกการสั่งซื้อ",
    "When cart reach specific price": "เมื่อซื้อถึงราคาที่กำหนด",
    "When buy any selected product": "เมื่อซื้อสินค้าที่กำหนดในจำนวนที่กำหนด",
    "When buy any selected product reach specific price": "เมื่อซื้อสินค้าที่กำหนดในราคาที่กำหนด",
    "Product amount must be greater than zero": "จำนวนสินค้าต้องมีค่ามากกว่า 0",
    "Product price must be greater than zero": "ราคาสินค้าต้องมีค่ามากกว่า 0",
    "Product amount must be integer number": "จำนวนสินค้าต้องเป็นจำนวนเต็ม",
    "Product price must be integer number": "ราคาสินค้าต้องเป็นจำนวนเต็ม",
    "% discount can not over 100": "การลดค่าส่งสินค้าเป็น % ไม่สามารถเกิน 100",
    "minimum threshold amount at 2": "จำนวนสินค้าหลัก ต้องมีไม่ต่ำกว่า 2",
    [`Promotion short name cannot exceed ${PROMOTION_SHORT_NAME_LIMIT} characters`]: `ชื่อย่อต้องมีไม่เกิน ${PROMOTION_SHORT_NAME_LIMIT} ตัวอักษร`,
    "Total price (include discount)": "ราคารวม (คิดส่วนลด)",
    "Actual price": "ราคาจริง",
    "Price for apply promotion": "ราคาที่ใช้คิดโปรโมชั่น",
    Amount: "จำนวน",
    or: "หรือ",
    piece: "ชิ้น",
    "when order above": "เมื่อซื้อสินค้าเกิน",
    "when order specific above": "เมื่อซื้อ {{selectedProductListText}} เกิน",
    baht: "บาท",
    "when buy": "เมื่อซื้อ",
    Discount: "ลดราคา",
    "get free": "แถม",
    Buy: "ซื้อ",
    Shipping: "ส่ง",
    "at price": "ในราคา",
    free: "ฟรี",
    "total price": "ทั้งหมดราคา",
    promotionTextFreeGiveaway: "ฟรี {{freeProductList}}",
    promotionFreeProductText: "{{freeProduct}}",
    "promotion.isRepeatable.label": "คิดโปรโมชั่นครั้งเดียว",
    "promotion.isRepeatable.repeat": "คิดซ้ำ",
    "promotion.isRepeatable.oneTime": "ครั้งเดียว",
    "promotion.addEditModal.noProduct": "ไม่มีสินค้าที่ร่วมรายการ",
    "promotion.addEditModal.allProduct": "สินค้าทั้งหมดในร้านค้าเข้าร่วมโปรโมชั่น",
    "promotion.productSelector.allProduct": "สินค้าทั้งหมด",
    "promotion.productSelector.allProduct.description": "เลือกให้สินค้าทั้งหมดในร้านเข้าร่วมโปรโมชั่น",
    "promotion.productSelector.specifyProducts": "ระบุสินค้า",
    "promotion.productSelector.specifyProducts.description": "เลือกสินค้าหลักที่เข้าร่วมโปรโมชั่น",
  },
};

export default promotion;
