import { IS_ENTERPRISE_PAGE_ENABLED } from "config";
import { gql } from "@apollo/client";

export const USER = gql`
  query user($projectId: ID) {
    me(projectId: $projectId) {
      id
      email
      displayName
      avatar
      firstname
      lastname
      language
      isEmailVerified
      phoneNumber
      permissions {
        id
        projectId
        projectName
        projectLogo
        role
        isEnterprise
        isGuidelineCompleted
        isDisabled
        isInitialProjectCompleted
        restoreId
      }
      isAllowedToCreateProject
      isAccepted
      acceptedAt
      authProvider
      isConnectedWithApple
      isConnectedWithFacebook
      isConnectedWithGoogle
      primaryProvider
    }
  }
`;

// // clear this after IS_ENTERPRISE_PAGE_ENABLED is removed
export const USER_WITHOUT_IS_ENTERPRISE = gql`
  query user($projectId: ID) {
    me(projectId: $projectId) {
      id
      email
      displayName
      avatar
      firstname
      lastname
      language
      isEmailVerified
      phoneNumber
      permissions {
        id
        projectId
        projectName
        projectLogo
        role
        isGuidelineCompleted
        isDisabled
        isInitialProjectCompleted
        restoreId
      }
      isAllowedToCreateProject
      isAccepted
      acceptedAt
      authProvider
      isConnectedWithApple
      isConnectedWithFacebook
      isConnectedWithGoogle
      primaryProvider
    }
  }
`;

export const FLAG_USER_WITH_IS_ENTERPRISE = IS_ENTERPRISE_PAGE_ENABLED ? USER : USER_WITHOUT_IS_ENTERPRISE;

export default USER;
