import { useMutation } from "@apollo/client";

import { VALIDATE_FAQ } from "graphql/ai-setting/mutation";

const useSanityCheck = () => {
  const [sanityCheck, { loading: isLoadingSanityCheck }] = useMutation(VALIDATE_FAQ);

  return {
    sanityCheck,
    isLoadingSanityCheck,
  };
};

export default useSanityCheck;
