import { useEffect, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import get from "lodash/get";

import { DEEPLE_PAY_FEE_SIGNED_URL } from "graphql/deeplePay/query";
import { notifyError } from "utils/notify";
import { useTranslation } from "react-i18next";

const useGetDeeplePayFeeInvoiceSignedUrl = (projectId: string) => {
  const previousSelectedInvoiceNumber = useRef<string>();
  const { t } = useTranslation();
  const [getInvoiceReceipt, { data, loading }] = useLazyQuery(DEEPLE_PAY_FEE_SIGNED_URL, {
    fetchPolicy: "network-only",
    onError(error) {
      notifyError(t("Please try again later"));
      console.error(error);
    },
  });

  const deeplePayFeeInvoiceSignedUrl = get(data, "deeplePayFeeInvoiceSignedURL");

  const handleClickDeeplePayFeeInvoiceId = async (deeplePayFeeInvoiceId?: string) => {
    if (!deeplePayFeeInvoiceId) {
      return;
    }

    if (previousSelectedInvoiceNumber.current === deeplePayFeeInvoiceId) {
      window.open(deeplePayFeeInvoiceSignedUrl);
      return;
    }

    previousSelectedInvoiceNumber.current = deeplePayFeeInvoiceId;

    getInvoiceReceipt({
      variables: {
        projectId,
        deeplePayFeeInvoiceId,
      },
    });
  };

  useEffect(() => {
    if (!loading && deeplePayFeeInvoiceSignedUrl) {
      window.open(deeplePayFeeInvoiceSignedUrl);
    }
  }, [loading, deeplePayFeeInvoiceSignedUrl]);

  return {
    onClickDeeplePayFeeInvoiceId: handleClickDeeplePayFeeInvoiceId,
    loading,
  };
};

export default useGetDeeplePayFeeInvoiceSignedUrl;
