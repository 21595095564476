import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import Grid from "components/Grid";
import TextField from "components/TextField";
import Typography from "components/Typography";
import Button from "components/Button";
import { DigitalProductsQueryVariablesType, DigitalProductCodesQueryVariablesType } from "types/DigitalContent";

import { MarkAsUsedSchema } from "../validationSchema";
import useMarkCodeAsUsed from "../hooks/useMarkCodeAsUsed";

type MarkAsUsedModalPropsType = {
  projectId: string;
  productId: string;
  productSKUId: string;
  digitalProductCodeId: string;
  onSubmit: () => void;
  digitalProductsQueryVariables: DigitalProductsQueryVariablesType;
  digitalProductCodesQueryVariables: DigitalProductCodesQueryVariablesType;
};

type MarkAsUsedFormDataType = {
  markAsUsedNote: string;
};

const MarkAsUsedModal: FC<MarkAsUsedModalPropsType> = ({
  projectId,
  productId,
  productSKUId,
  digitalProductCodeId,
  onSubmit,
  digitalProductsQueryVariables,
  digitalProductCodesQueryVariables,
}) => {
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm<MarkAsUsedFormDataType>({
    resolver: yupResolver(MarkAsUsedSchema),
  });

  const { handleMarkCodeAsUsedById } = useMarkCodeAsUsed({
    projectId,
    productId,
    productSKUId,
    digitalProductsQueryVariables,
    digitalProductCodesQueryVariables,
    onComplete: onSubmit,
  });

  const onClickSubmit = ({ markAsUsedNote }: MarkAsUsedFormDataType) => {
    handleMarkCodeAsUsedById(digitalProductCodeId, markAsUsedNote);
  };

  return (
    <Grid container className="p-4">
      <Grid item xs={12} className="pb-4">
        <Typography variant="title2" color="dark">
          {t("contentManagement.button.markAsUsed")}
        </Typography>
      </Grid>
      <Grid item xs={12} className="pb-2">
        <Typography variant="title7" color="darkMed">
          {t("contentManagement.codeDetail.label.note")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          validate={register}
          name="markAsUsedNote"
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          placeholder={t("contentManagement.codeDetail.placeholder.addNote")}
        />
      </Grid>
      <Grid item xs={12} className="pt-3">
        <Button fullWidth onClick={handleSubmit(onClickSubmit)}>
          {t("Submit")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default MarkAsUsedModal;
