import React from "react";
import styled from "styled-components";
import { CellProps } from "react-table";
import { COLOR_BY_STATUS, ORDER_STATUSES } from "constants/Order";

const LEFT_STICKY_PANEL_MAX_WIDTH = 422;
const CHECKBOX_COLUMN_WIDTH = 56;

const Bullet = styled(({ color: _color, ...rest }) => <span {...rest} />).attrs(({ color }) => {
  return { color };
})`
  min-width: 6px;
  min-height: 6px;
  max-width: 6px;
  max-height: 6px;
  background-color: ${({ color }) => color};
  border-radius: 10px;
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
`;

const Status = styled(({ color: _color, ...rest }) => <span {...rest} />).attrs(({ color }) => {
  return { color };
})`
  font-weight: 500;
  text-transform: capitalize;
  color: ${({ color }) => color};
`;

const getStatus = <T extends object>(t: Function) => ({
  disableSortBy: true,
  Header: t("Status"),
  accessor: "state",
  width: (LEFT_STICKY_PANEL_MAX_WIDTH - CHECKBOX_COLUMN_WIDTH) / 3,
  // eslint-disable-next-line react/display-name
  Cell: (cell: CellProps<T>) => {
    return (
      <>
        <Bullet color={COLOR_BY_STATUS[cell.value]} />
        <Status color={COLOR_BY_STATUS[cell.value]}>
          {typeof cell.value === "string" ? t(ORDER_STATUSES[cell.value]) : cell.value}
        </Status>
      </>
    );
  },
});

export default getStatus;
