import { DEFAULT_VALUE, MAP_SHIPPOP_ERRORS } from "config/shippop";
import { convertPriceFormat } from "utils/common";
import { getCustomerAddressText } from "utils/address";
import { ShippopPurchaseOrderSummaryError } from "types/Shipping";
import { OrderReportResult } from "types/Report";
import { getCourierNameByShippingMethod } from "utils/shipping";
import { formattedSubTable } from "./formattedSubTable";

export const formattedOrderReports = (
  t: Function,
  orderReportsInput: OrderReportResult[],
  projectId: string | number | undefined,
  shippopPurchaseOrderSummaryErrors: ShippopPurchaseOrderSummaryError[],
) => {
  return orderReportsInput.map((order) => {
    let shippopError = shippopPurchaseOrderSummaryErrors.find(
      (shippopPurchaseOrderSummaryError) => shippopPurchaseOrderSummaryError.orderId.toString() === order?.id,
    );

    if (shippopError) {
      shippopError = { ...shippopError, errorCode: MAP_SHIPPOP_ERRORS[shippopError.errorCode] };
    }
    const streetAddress = order?.streetAddress;
    const streetAddress2 = order?.streetAddress2;
    const subDistrict = order?.subDistrict;
    const district = order?.district;
    const province = order?.province;
    const postalCode = order?.postalCode;
    const discountProductPrice = order?.discountTotalPrice || 0;
    const productPrice = order?.totalPrice || 0;
    const shippopRef = order?.lastShipment?.referenceId || DEFAULT_VALUE;
    const note = order?.note || DEFAULT_VALUE;

    return {
      id: order?.id,
      orderNumber: order?.orderNumber || DEFAULT_VALUE,
      customerName: order?.customer.name || DEFAULT_VALUE,
      state: order?.state || DEFAULT_VALUE,
      shippingMethod: order?.shippingMethod || DEFAULT_VALUE,
      shippingPrice: convertPriceFormat(order?.shippingPrice || 0),
      shipping: getCourierNameByShippingMethod(order?.shippingDescription || DEFAULT_VALUE, order.shipping),
      streetAddress:
        getCustomerAddressText(
          {
            streetAddress,
            streetAddress2,
            district,
            subDistrict,
            province,
            postalCode,
          },
          [],
        ) || DEFAULT_VALUE,

      phoneNumber: order?.phoneNumber || DEFAULT_VALUE,
      email: order?.email || DEFAULT_VALUE,
      trackingNumber: order?.trackingNumber || DEFAULT_VALUE,
      vatOptions: order?.vatOptions || DEFAULT_VALUE,
      productPrice: convertPriceFormat(productPrice + discountProductPrice),
      discountTotalPrice: convertPriceFormat(discountProductPrice),
      discountShippingPrice: convertPriceFormat(order?.discountShippingPrice || 0),
      grandTotal: convertPriceFormat(order?.grandTotal || 0),
      paymentAt: order?.paymentAt || DEFAULT_VALUE,
      referenceCode: order?.referenceCode || DEFAULT_VALUE,
      subTable: formattedSubTable(order?.orderProducts || [], t),
      billNumber: {
        projectId,
        orderId: order?.id,
        billNumber: order?.bill?.billNumber,
      },
      additionalDiscount: convertPriceFormat(order?.additionalDiscount || 0),
      totalWeight: order?.totalWeight,
      shippopRef,
      largestDimension: order?.largestDimension,
      shippopError,
      note,
      adminsNote: order?.adminsNote || "",
    };
  });
};
