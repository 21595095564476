import styled from "styled-components";
import { VARIANTS } from "constants/Typography";

type TableWrapperPropsType = {
  isBlurContent?: boolean;
};

export const TableWrapper = styled.div<TableWrapperPropsType>`
  position: relative;

  -ms-overflow-style: none;
  line-height: 1rem;

  .table {
    width: 100%;
    .tr {
      background-color: white;
      &:hover {
        background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
      }
    }

    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 50%;
      border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      width: 25px;
      height: 25px;
      margin: auto;
      transform: rotate(0);
      transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .circle.active {
      transform: rotate(-90deg);
    }

    font-size: ${VARIANTS.body4.size};

    input[type="checkbox"] {
      width: 24px;
      height: 24px;
    }

    .header {
      .th {
        padding: 16px;
        border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
        background-color: white;
        color: ${({ theme }) => theme.COLORS.DarkLight};
        font-weight: 600;
        border-top: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
        word-break: break-word;

        svg {
          font-size: 20px;
        }

        :first-child {
          border-radius: 8px 0 0 0;
          overflow: hidden;
          border-left: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
        }

        :last-child {
          border-right: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
          z-index: 3;
          border-radius: 0 8px 0 0;
          flex: unset !important;
          overflow: hidden;
        }
      }
    }

    .footer {
      .th {
        padding: 16px 10px;
        border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
        background-color: white;
        color: ${({ theme }) => theme.COLORS.DarkLight};
        font-weight: 600;
        word-break: break-word;

        :first-child {
          border-left: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
        }

        :last-child {
          border-right: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
        }
      }
    }

    .td {
      padding: 16px;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      color: ${({ theme }) => theme.COLORS.DarkGray};
      word-break: break-word;

      :first-child {
        border-left: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      }

      :last-child {
        border-right: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
        flex: unset !important;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    &.sticky {
      .header {
        position: sticky;
        z-index: 2;
        top: 0;
        left: 0;
      }

      .footer {
        position: sticky;
        z-index: 0;
        bottom: 0;
        padding: 5px;
        border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
        border-top: 0;
        background-color: white;
        color: ${({ theme }) => theme.COLORS.DarkLight};
        border-radius: 0 0 8px 8px;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px ${({ theme }) => theme.COLORS.LightBlue};
        border-right: 1px solid #ddd;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px ${({ theme }) => theme.COLORS.LightBlue};
      }
    }
  }

  .table.subTable {
    &.sticky {
      .header {
        top: 61px;
      }
    }

    .th {
      padding: 10px;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      border-right: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      border-top: 0;
      background-color: ${({ theme }) => theme.COLORS.LightWhite};
      font-weight: 500;
      color: ${({ theme }) => theme.COLORS.DarkMed};

      :not(:nth-child(2)):not(:first-child):not(:last-child) {
        flex: 1;
        width: unset !important;
      }

      :nth-child(2) {
        justify-content: center;
      }

      :first-child {
        border-bottom: 0;
        border-radius: unset;
        ${({ theme }) => theme.breakpoints.down("lg")} {
          background-color: white;
        }
      }

      :last-child {
        border-bottom: 0;
        border-radius: unset;
        background-color: white;
      }
    }

    .tr {
      display: flex;
      width: unset !important;
      :last-child {
        .td {
          :last-child,
          :first-child {
            border-radius: unset;
            border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
          }
        }
      }
    }

    .td {
      padding: 10px;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      border-right: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      background-color: ${({ theme }) => theme.COLORS.LightWhite};
      color: ${({ theme }) => theme.COLORS.DarkMed};

      :first-child {
        ${({ theme }) => theme.breakpoints.down("lg")} {
          background-color: white;
        }
        border-bottom: 0;
      }

      :nth-child(2) {
        justify-content: center;
        padding: 0;
      }

      :last-child {
        border-bottom: 0;
        background-color: white;
        z-index: 3;
      }

      :not(:nth-child(2)):not(:first-child):not(:last-child) {
        flex: 1;
        width: unset !important;
      }
    }

    &.sticky {
      .header {
        z-index: 1;
      }

      .body {
        position: relative;
        z-index: 0;
      }
    }
  }
`;
