import { useQuery, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { ResponseMessage } from "types/AISetting";
import { ResponseMessageData } from "types/ResponseMessage";
import { RESPONSE_MESSAGE } from "graphql/responseMessage/query";
import { UPDATE_CUSTOM_RESPONSE_MESSAGES } from "graphql/responseMessage/mutation";
import { cleanCategoriesMessage } from "utils/message";

const useResponseMessages = (projectId: string, slug: string) => {
  const { data, loading } = useQuery<{ responseMessage: ResponseMessageData }>(RESPONSE_MESSAGE, {
    variables: { projectId, slug },
  });

  const [update] = useMutation(UPDATE_CUSTOM_RESPONSE_MESSAGES);

  const updateCustomResponseMessages = useCallback(
    (messages: ResponseMessage[], isCustom: Boolean) => {
      const customMessages = cleanCategoriesMessage(messages);
      update({
        variables: {
          projectId,
          slug,
          isCustom,
          customMessages,
        },
      });
    },
    [projectId, slug, update],
  );

  return {
    data,
    loading,
    updateCustomResponseMessages,
  };
};

export default useResponseMessages;
