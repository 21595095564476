import React, { FC } from "react";
import { ControlProps, OptionType, OptionProps } from "react-select";

import { StyledControl, StyledOption } from "./index.styled";

export const CustomControl: FC<ControlProps<OptionType>> = ({ children, ...props }) => {
  return <StyledControl {...props}>{children}</StyledControl>;
};

export const CustomOption: FC<OptionProps<OptionType>> = ({ children, ...props }) => {
  return <StyledOption {...props}>{children}</StyledOption>;
};
