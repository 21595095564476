import * as React from "react";

const SvgIcConnect = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.045 11.044a4.684 4.684 0 016.616 0l2.647 2.647c1.562 1.56 1.702 3.704.878 5.377l1.315 1.315c1.59-.88 3.633-.867 5.359.859l2.707 2.711a4.675 4.675 0 010 6.616 4.675 4.675 0 01-6.615 0l-2.709-2.71c-1.667-1.668-1.69-3.72-.836-5.335l-1.324-1.325a4.632 4.632 0 01-2.075.477 4.695 4.695 0 01-3.316-1.37l-2.647-2.646a4.686 4.686 0 010-6.616zM17.841 0c.355 0 .678.2.837.517l.92 1.904c1.182.316 2.316.786 3.382 1.403l1.957-.652a.942.942 0 01.957.226l2.646 2.647c.25.25.338.62.227.957l-.652 1.956a13.946 13.946 0 011.403 3.382l1.904.92a.936.936 0 01.517.837v3.743c0 .355-.2.678-.517.837l-1.904.921c-.045.167-.112.327-.164.493-.929-.93-1.91-1.57-3.33-1.901a10.256 10.256 0 00-2.776-9.5c-4.013-4.012-10.543-4.012-14.556 0-4.013 4.014-4.013 10.544 0 14.557a10.27 10.27 0 007.275 3.026c.741 0 1.481-.103 2.213-.266.325 1.416.968 2.402 1.912 3.346-.165.051-.325.12-.492.164l-.922 1.904a.936.936 0 01-.837.517H14.1a.936.936 0 01-.837-.517l-.92-1.904a13.945 13.945 0 01-3.382-1.404l-1.956.653a.936.936 0 01-.958-.227L3.4 25.893a.938.938 0 01-.227-.958l.653-1.956a13.946 13.946 0 01-1.404-3.382l-1.904-.92a.936.936 0 01-.517-.837v-3.743c0-.354.2-.678.517-.837l1.904-.92a13.945 13.945 0 011.403-3.38l-.652-1.957a.938.938 0 01.227-.957l2.646-2.647a.941.941 0 01.958-.226l1.956.652a13.946 13.946 0 013.382-1.403l.92-1.904A.936.936 0 0114.099 0zm6.782 22.685a1.5 1.5 0 01-.335 1.602c-.494.456-1.103.532-1.606.335.021.466.21.916.546 1.25l2.71 2.713c.732.733 1.91.734 2.646-.002a1.865 1.865 0 00-.002-2.645l-2.707-2.711a1.875 1.875 0 00-1.252-.542zm-8.946-9.657a1.875 1.875 0 00-2.646 0c-.73.73-.73 1.917-.001 2.647l2.647 2.647c.4.4.84.57 1.264.576a1.497 1.497 0 01.324-1.634 1.499 1.499 0 011.617-.33c-.004-.43-.17-.87-.56-1.258z"
    />
  </svg>
);

export default SvgIcConnect;
