import * as React from "react";

const SvgIcSort = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.028 4c.125 0 .227.04.307.12.08.08.12.183.12.308v18.415h2.57c.125 0 .228.04.308.12.08.081.12.184.12.309a.502.502 0 01-.133.32l-4.27 4.27a.448.448 0 01-.307.12.481.481 0 01-.308-.12L4.152 23.58c-.133-.142-.165-.298-.093-.468.071-.179.205-.268.401-.268h2.57V4.428a.42.42 0 01.12-.308c.08-.08.183-.12.308-.12zm17.13 20.556c.125 0 .227.04.308.121.08.08.12.183.12.308v2.57c0 .124-.04.227-.12.307a.417.417 0 01-.308.12H16.023a.417.417 0 01-.308-.12.417.417 0 01-.12-.308v-2.57c0-.124.04-.227.12-.307.08-.08.183-.12.308-.12zm-2.57-6.851c.125 0 .228.04.308.12.08.08.12.183.12.307v2.57c0 .125-.04.228-.12.308a.418.418 0 01-.308.12h-8.565a.417.417 0 01-.308-.12.416.416 0 01-.12-.308v-2.57c0-.124.04-.227.12-.307.08-.08.183-.12.308-.12zm-2.57-6.853c.126 0 .228.04.308.12.08.081.121.184.121.309v2.569c0 .125-.04.228-.12.308a.416.416 0 01-.308.12h-5.996a.417.417 0 01-.308-.12.417.417 0 01-.12-.308v-2.57c0-.124.04-.227.12-.307.08-.08.183-.12.308-.12zM19.45 4c.125 0 .228.04.308.12.08.08.12.183.12.308v2.57a.42.42 0 01-.12.308.416.416 0 01-.308.12h-3.426a.417.417 0 01-.308-.12.417.417 0 01-.12-.308v-2.57a.42.42 0 01.12-.308c.08-.08.183-.12.308-.12z"
    />
  </svg>
);

export default SvgIcSort;
