import React, { FC } from "react";
import get from "lodash/get";
import styled from "styled-components";
import COLORS from "constants/Colors";
import { groupPromotionsByProductSKUId } from "components/Message/utils/promotionUtil";
import Grid from "components/Grid";
import Typography from "components/Typography";
import { OrderCarouselType } from "../FlexOrderCarousel";
import FlexOrderProductSKUsWithOrderProducts from "./FlexOrderProductSKUsWithOrderProducts";
import FlexOrderProductSKUsWithProducts from "./FlexOrderProductSKUsWithProducts";

type FlexOrderProductSKUPropsType = {
  content: OrderCarouselType;
  hasMoreItems: boolean;
  moreItemsCount: number;
};

export const ReviewOrderWrapper = styled.div`
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  color: ${COLORS.LightBlue};
  background: ${COLORS.LightGray};
`;

const FlexOrderProductSKU: FC<FlexOrderProductSKUPropsType> = (props) => {
  const { content, hasMoreItems, moreItemsCount } = props;

  const promotionProducts = content.promotionProduct || [];
  const bundledPromotions = promotionProducts.filter((promotionProduct) => get(promotionProduct, "settings.isBundled"));
  const bundledPromotionDescriptions = bundledPromotions
    .map((promotion) => get(promotion, "description") || get(promotion, "name"))
    .join(", ");

  const promotionsWithoutBundle = promotionProducts.filter(
    (promotionProduct) => !get(promotionProduct, "settings.isBundled"),
  );
  const groupedPromotionBySKUId = groupPromotionsByProductSKUId(promotionsWithoutBundle);

  const productPromotions = get(content, "promotionProduct") || [];
  const hasOrderProducts = content.orderProducts && content.orderProducts.length;

  return (
    <Grid item className="p-2" xs={12}>
      {hasOrderProducts ? (
        <FlexOrderProductSKUsWithOrderProducts
          groupedPromotionBySKUId={groupedPromotionBySKUId}
          orderProducts={content.orderProducts}
          productPromotions={productPromotions}
        />
      ) : (
        <FlexOrderProductSKUsWithProducts
          groupedPromotionBySKUId={groupedPromotionBySKUId}
          products={content.products}
          productPromotions={productPromotions}
        />
      )}
      {hasMoreItems && (
        <Grid item xs={12} className="text-center font-italic">
          <Typography variant="body5" color={COLORS.Info} className="pt-1">
            <span style={{ textDecoration: "underline" }}>ดูรายการสินค้าเพิ่มเติม({moreItemsCount})</span>
          </Typography>
        </Grid>
      )}
      {bundledPromotionDescriptions && bundledPromotionDescriptions.length && (
        <Grid item xs={12}>
          <Typography variant="body4" color="error" className="pt-1">
            โปรโมชัน - {bundledPromotionDescriptions}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default FlexOrderProductSKU;
