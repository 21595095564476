import { ProductOutputType, DigitalContentAvailability } from "types/Product";

//  For Digital product
export const getProductSKUsOptionsFromDigitalContentOutputType = (outputType: ProductOutputType) => {
  let optionsData = {};
  switch (outputType) {
    case ProductOutputType.CODE_AUTO_GENERATED:
      optionsData = {
        digitalContentOptions: {
          outputType,
          settings: {
            availability: { type: DigitalContentAvailability.NO_EXPIRY, settings: {} },
          },
        },
      };
      break;

    case ProductOutputType.STATIC:
      optionsData = {
        digitalContentOptions: {
          outputType,
          settings: {
            content: "",
          },
        },
      };
      break;

    case ProductOutputType.CODE_IMPORTED:
      optionsData = {
        digitalContentOptions: {
          outputType,
          settings: {
            availability: { type: DigitalContentAvailability.NO_EXPIRY },
          },
        },
      };
      break;

    case ProductOutputType.CODE_GENERATED_FROM_OTHER:
      optionsData = {
        digitalContentOptions: {
          outputType,
        },
      };
      break;

    default:
      break;
  }
  return optionsData;
};
