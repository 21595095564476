import { gql } from "@apollo/client";

export const UPDATE_CUSTOM_RESPONSE_MESSAGES = gql`
  mutation($projectId: ID!, $slug: String!, $isCustom: Boolean!, $customMessages: [MessageInput]!) {
    updateCustomResponseMessage(
      projectId: $projectId
      slug: $slug
      isCustom: $isCustom
      customMessages: $customMessages
    ) {
      id
      isCustom
      customMessages {
        type
        value
      }
    }
  }
`;
