import React, { FC } from "react";
import get from "lodash/get";
import { FieldError, DeepMap } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OptionsType, OptionType, ActionMeta } from "@atlaskit/select";

import COLORS from "constants/Colors";
import { SUPPORTED_LANGUAGE } from "constants/Language";
import { MAXIMUM_SHIPPING_COST } from "constants/Limit";

import Grid from "components/Grid";
import Typography from "components/Typography";
import MultiSelectWithSearchBox from "components/MultiSelectWithSearchBox";
import TextField from "components/TextField";
import { NumberFormatCustomWithoutPrefix } from "components/NumberFormatCustom";

import { SvgIcon } from "components/Icon";
import { IcDelete } from "components/SvgIcons";
import { ButtonWithIcon } from "components/Button";

import { ThaiBahtAdornment } from "../../../DefaultShippingMethod/style";
import { EditShippingMethodFormData } from "../../../EditShippingMethodForm";
import { convertPostcodeToOptionFormat } from "../../util";

type CustomRateDistrictPropsType = {
  postalCodeIndex: number;
  provinceIndex: number;
  provinceName: string;
  price: number;
  postalCode: string;
  optionsDistrictValue: OptionsType<OptionType>;
  handleEditCustomRatePostcode: Function;
  handleDeleteCustomRatePostcode: Function;
  errors: DeepMap<EditShippingMethodFormData, FieldError>;
};

export const CustomRateDistrict: FC<CustomRateDistrictPropsType> = (props) => {
  const { t, i18n } = useTranslation();
  const isThaiLanguage = i18n.language === SUPPORTED_LANGUAGE.TH;
  const {
    postalCodeIndex,
    provinceIndex,
    provinceName,
    postalCode,
    price,
    optionsDistrictValue,
    handleEditCustomRatePostcode,
    handleDeleteCustomRatePostcode,
    errors,
  } = props;

  const errorMessage = get(errors, `settings[${provinceIndex}].postalCodes[${postalCodeIndex}].postalCode`);

  const handleSelectOption = (_option: OptionsType<OptionType>, action: ActionMeta<OptionType>) => {
    if (action.action === "select-option") {
      handleEditCustomRatePostcode(postalCodeIndex, get(action, "option.value") || "", provinceName, price);
    } else if (action.action === "deselect-option") {
      handleEditCustomRatePostcode(postalCodeIndex, "", provinceName, price);
    }
  };

  const handleFixedPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;
    const newValueNumber = +parseFloat(value).toFixed(2) || 0;

    if (newValueNumber > -1 && newValueNumber < MAXIMUM_SHIPPING_COST) {
      handleEditCustomRatePostcode(postalCodeIndex, postalCode, provinceName, newValueNumber || 0);
    }
  };

  return (
    <Grid container item xs={12} className="mt-1" justify="space-between" alignContent="center" alignItems="center">
      <Grid item xs={12} sm={6} className="pb-1">
        <Grid container>
          <Grid item xs={12} className="pr-2">
            <MultiSelectWithSearchBox
              isMulti
              label={t("PostalCode")}
              placeholder={`${t("Select")} ${t("PostalCode")}`}
              options={optionsDistrictValue}
              value={convertPostcodeToOptionFormat(postalCode, provinceName, isThaiLanguage)}
              onChange={(_option: OptionsType<OptionType>, action: ActionMeta<OptionType>) => {
                handleSelectOption(_option, action);
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} className="pb-1">
        <Grid container>
          <Grid item xs={10}>
            <TextField
              value={price}
              fullWidth
              variant="outlined"
              InputProps={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputComponent: NumberFormatCustomWithoutPrefix as any,
                startAdornment: <ThaiBahtAdornment />,
              }}
              onChange={handleFixedPriceChange}
            />
          </Grid>

          <Grid item xs={2} container alignItems="center" alignContent="center" justify="center">
            <ButtonWithIcon
              onClick={() => {
                handleDeleteCustomRatePostcode(postalCodeIndex, provinceName);
              }}
            >
              <SvgIcon className="ml-1" component={IcDelete} fontSize="small" htmlColor={COLORS.DarkMed} />
            </ButtonWithIcon>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="pb-1">
        {errorMessage && (
          <Typography variant="body3" color={COLORS.Error} className="p-1">
            {t(errorMessage && (errorMessage.message as string))}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
