import { useLocation } from "react-router-dom";
import { parsePage } from "utils/common";

const useCurrentPage = () => {
  const location = useLocation();
  const { pathname } = location;

  return parsePage(pathname);
};

export default useCurrentPage;
