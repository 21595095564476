import produce from "immer";
import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import get from "lodash/get";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { UPDATE_PROJECT_CONFIG } from "graphql/shipping/mutation";
import { PROJECT_SETTING } from "graphql/setting/query";
import { ProjectSettingType } from "types/Setting";
import { removeTypenameField } from "utils/common";
import { formatDateBeforeUpdate } from "../helper";

const useSaveGeneralSetting = (projectId: string, projectData: ProjectSettingType, onCompleted: () => void) => {
  const { t } = useTranslation();

  const [updateGeneralSetting, { loading }] = useMutation(UPDATE_PROJECT_CONFIG, {
    update(cache, { data: { updateProject } }) {
      const cacheProjectSetting = cache.readQuery<{ getProject: ProjectSettingType }>({
        query: PROJECT_SETTING,
        variables: {
          projectId,
        },
      });

      if (cacheProjectSetting) {
        const newProjectSetting = produce(cacheProjectSetting, (draft) => {
          draft.getProject.configuration.delivery = updateProject.configuration.delivery;
        });

        cache.writeQuery({
          query: PROJECT_SETTING,
          variables: {
            projectId,
          },
          data: newProjectSetting,
        });
      }
    },
    onCompleted: () => {
      toast.success(`${t("Update successfully")}`, {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 1000,
      });

      onCompleted();
    },
    onError: () => {
      toast.error(`${t("Update failed!")}`, {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 2000,
      });
    },
  });

  const saveGeneralSetting = useCallback(
    async (data) => {
      const variables = {
        projectId,
        projectInput: {
          configuration: {
            order: removeTypenameField(get(projectData, "getProject.configuration.order")),
            delivery: {
              schedule: formatDateBeforeUpdate({
                start: data.start,
                end: data.end,
                isActive: data.isActive,
                timeInAdvance: data.timeInAdvance,
              }),
            },
          },
        },
      };
      await updateGeneralSetting({ variables });
    },
    [projectData, projectId, updateGeneralSetting],
  );

  return { saveGeneralSetting, isLoadingSaveGeneralSetting: loading };
};

export default useSaveGeneralSetting;
