import React, { FC, useState, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getYear } from "date-fns";
import get from "lodash/get";
import { OptionType, ValueType } from "react-select";

import useIsDesktop from "utils/hooks/useIsDesktop";
import Grid from "components/Grid";
import Card from "components/Card";
import CircularProgress from "components/CircularProgress";
import Typography from "components/Typography";

import { DEEPLE_PAY_FEE_INVOICES } from "graphql/deeplePay/query";
import { DeeplePayFeeInvoicesQuery, DeeplePayFeeInvoicesQueryVariables } from "types/DeeplePay";
import { ProjectIdType } from "types/Project";
import { getYearsFromYearRange } from "utils/date";
import { CustomControl, CustomOption } from "domain/Dashboard/CustomReactSelect";
import { StyledSelect, CardWrapper, Wrapper } from "./styled";

import DeeplePayFeeInvoiceTable from "./DeeplePayFeeInvoiceTable";

const DEEPLE_PAY_FEE_SUMMARY_STARTING_YEAR = 2022; // year of starting deeple pay fee summary

const DeeplePayFeeInvoiceHistory: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const isDesktop = useIsDesktop();

  const yearOptions: number[] = getYearsFromYearRange(DEEPLE_PAY_FEE_SUMMARY_STARTING_YEAR);

  const currentYear = getYear(new Date());

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedYear, setSelectedYear] = useState<ValueType<OptionType>>({
    label: currentYear.toString(),
    value: currentYear.toString(),
  });

  const yearSelectOptions: OptionType[] = yearOptions.map((year: number) => ({
    label: year.toString(),
    value: year.toString(),
  }));

  const variables = useMemo(
    () => ({
      projectId,
      year: get(selectedYear, "value"),
    }),
    [projectId, selectedYear],
  );

  const { loading, data, refetch } = useQuery<DeeplePayFeeInvoicesQuery, DeeplePayFeeInvoicesQueryVariables>(
    DEEPLE_PAY_FEE_INVOICES,
    {
      skip: !projectId,
      variables,
    },
  );

  const handleOnYearChanged = (value: ValueType<OptionType>) => {
    setSelectedYear(value);
  };

  const formatOptionLabel = (option: OptionType) => {
    const { label } = option;
    return (
      <Typography variant="title8" color="darkGray">
        {label}
      </Typography>
    );
  };

  useEffect(() => {
    refetch(variables);
  }, [projectId, refetch, variables]);

  if (loading) {
    return (
      <Grid container item justify="center">
        <CircularProgress className="m-4" />
      </Grid>
    );
  }

  if (data) {
    const { deeplePayInvoices } = data;

    return (
      <Wrapper container alignContent="flex-start" className={isDesktop ? "" : "mobile"}>
        <Grid item xs={12} className={!isDesktop ? "pl-3 py-4" : "mb-4"}>
          <Typography variant="title2">{t("deeplePay.header.invoice")}</Typography>
        </Grid>
        <Card padding="0" className="d-flex flex-1 flex-column" maxWidth={783}>
          <CardWrapper className="d-flex flex-1 flex-column">
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={8}>
                <Typography variant="title3" color="dark">
                  {t("deeplePay.feeInvoice.table.title")}
                </Typography>
              </Grid>
              <Grid item xs={4} className="mt-2 mt-sm-0" style={{ maxWidth: "120px" }}>
                <StyledSelect
                  value={selectedYear}
                  options={yearSelectOptions}
                  onChange={handleOnYearChanged}
                  isSearchable={false}
                  formatOptionLabel={formatOptionLabel}
                  components={{ Control: CustomControl, Option: CustomOption }}
                />
              </Grid>
            </Grid>
          </CardWrapper>
          {deeplePayInvoices && (
            <DeeplePayFeeInvoiceTable projectId={projectId} deeplePayFeeInvoices={deeplePayInvoices} />
          )}
        </Card>
      </Wrapper>
    );
  }

  return null;
};

export default DeeplePayFeeInvoiceHistory;
