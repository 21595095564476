import React, { FC } from "react";

import Grid from "components/Grid";
import { AddonType, AddonCountType } from "types/Addon";
import AdvanceFeatureCard from "../AdvanceFeatureCard";

type ImageSearchItemListPropsType = {
  addons: AddonType[];
  currentAddons: AddonCountType;
  onToggleAddon: (type: string, isDisabled: boolean) => void;
  selectedAddons: AddonCountType;
};

const AdvanceFeatureItemList: FC<ImageSearchItemListPropsType> = (props) => {
  const { addons, currentAddons, onToggleAddon, selectedAddons } = props;

  return (
    <Grid container>
      {addons.map((addon: AddonType) => {
        const { entityType, pricePerMonth, isSelectable = true } = addon;
        const selectedAddonValue = selectedAddons[entityType];
        const currentStatusValue = currentAddons[entityType] || -1;

        return (
          <Grid item xs={12} className="pb-3" key={entityType}>
            <AdvanceFeatureCard
              type={entityType}
              price={pricePerMonth}
              isDisabled={(selectedAddonValue || currentStatusValue) < 0}
              onChange={(isDisabled: boolean) => {
                onToggleAddon(entityType, isDisabled);
              }}
              isSelectable={isSelectable}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AdvanceFeatureItemList;
