import { useQuery } from "@apollo/client";
import moment from "moment";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Typography from "components/Typography";
import Grid from "components/Grid/index";
import { IcDownload, IcArrowUp, IcArrowDown } from "components/SvgIcons";
import { SvgIcon } from "components/Icon/index";
import { DATE_INVOICE_FORMAT } from "constants/DateTimeFormat";
import { CreditCard } from "constants/Billing";
import {
  InvoicePaymentType,
  EntityLevel,
  PaymentSummaryItemType,
  PackageType,
  PaymentSummaryResultType,
  EntityType,
  PaymentSummaryTypes,
  CreditCardBrand,
  InvoiceDiscountItem,
} from "types/Billing";
import { INVOICE_USAGE } from "graphql/billing/query";

import { convertPriceFormat } from "utils/common/index";
import { SubscriptionType } from "types/PlanUsage";
import { IS_ALLOW_NEW_PACKAGE } from "config";
import {
  DownloadPDFButton,
  InvoiceItemCard,
  CreditCardText,
  ExpandColumn,
  InvoiceNumberText,
  PaymentWrapper,
} from "../styled";
import SummaryList from "../PaymentSummary/PaymentSummaryMobile/summaryList";
import { DiscountWording } from "../../../../../constants/Billing";

interface InvoiceItemPropTypes {
  invoiceNumber: string;
  totalPrice: number;
  handleDownloadPDF: () => void;
  isMobile: boolean;
  projectId: string;
  paymentType: InvoicePaymentType;
  setSelectedInvoiceDetail: (id: string) => void;
  selectedInvoiceDetail?: string;
  cardBrand?: CreditCardBrand;
  lastFourDigitCardNumber?: string | null;
  paidAt?: string;
}

const InvoiceItem: FC<InvoiceItemPropTypes> = (props) => {
  const { t } = useTranslation();
  const {
    projectId,
    paymentType = InvoicePaymentType.WALLET,
    invoiceNumber,
    totalPrice,
    handleDownloadPDF,
    isMobile,
    setSelectedInvoiceDetail,
    selectedInvoiceDetail,
    cardBrand = "",
    lastFourDigitCardNumber = "",
    paidAt,
  } = props;
  const { data, refetch } = useQuery(INVOICE_USAGE, {
    variables: {
      projectId,
      invoiceId: invoiceNumber,
    },
  });
  const isShowInvoiceDetail = selectedInvoiceDetail === invoiceNumber;
  const invoiceUsage = data && data.invoiceUsage;
  const creditCardNumber = lastFourDigitCardNumber ? lastFourDigitCardNumber.slice(-4).replace(/[xX-]/g, "") : "";
  const discount: InvoiceDiscountItem[] = invoiceUsage?.discount || [];
  const isPaidByWalletInvoice = paymentType === InvoicePaymentType.WALLET;
  const hasDiscountCredit = discount.length > 0;
  const subscriptionType = invoiceUsage?.items?.[0]?.subscriptionType || SubscriptionType.MONTHLY;
  const subscriptionLabel =
    subscriptionType === SubscriptionType.ANNUALLY ? ` - ${t("planBilling.subscriptionType.annually")} ` : "";
  const groupPaymentSummaryItems = invoiceUsage?.items ? groupBy(invoiceUsage.items, "type") : {};

  const invoiceUsageItems = groupPaymentSummaryItems[PackageType.PACKAGE] || [];
  const { packagePriceTotal, packageSummaryLabel, packageLabel } = invoiceUsageItems
    .filter((usageDetail) => Boolean(usageDetail.entityLevel))
    .reduce(
      (results: PaymentSummaryResultType, usageDetail: PaymentSummaryItemType, index: number) => {
        const { name, totalPrice, entityLevel, entityType } = usageDetail;

        if (!entityLevel) {
          return results;
        }

        if (entityLevel === EntityLevel.FREE) {
          return {
            packagePriceTotal: totalPrice,
            packageLabel: t("planBilling.packageSummary.label.free"),
            packageSummaryLabel: t("planBilling.packageSummary.freeSummary"),
          };
        }

        const itemValue = name.split(" ")[0].replace(/,/g, "");
        const itemFormat = new Intl.NumberFormat().format(+itemValue);

        const summaryLabel = `${itemFormat} ${t(
          `planBilling.packageSummary.itemValueSuffix.${
            IS_ALLOW_NEW_PACKAGE ? `newPlan.` : ""
          }${entityType.toLowerCase()}`,
        )}`;

        return {
          packageLabel: t(`planBilling.packageSummary.label.${entityLevel && entityLevel.toLowerCase()}`),
          packagePriceTotal: results.packagePriceTotal + totalPrice,
          packageSummaryLabel: `${results.packageSummaryLabel}${index ? ", " : ""}${summaryLabel}`,
        };
      },
      { packagePriceTotal: 0, packageSummaryLabel: "", packageLabel: "" },
    );

  const foundImageSearchItem = invoiceUsage?.items
    ? invoiceUsage.items.find((item: PaymentSummaryItemType) => item.entityType === EntityType.IMAGE_SEARCH)
    : "";

  const addonItems = groupPaymentSummaryItems[PackageType.ADDON] || [];
  const itemsSummary = foundImageSearchItem ? addonItems.concat([foundImageSearchItem]) : addonItems;
  const isShowPackage = !isEmpty(packageSummaryLabel) || !isEmpty(packageLabel);

  const handleClick = () => {
    if (!isShowInvoiceDetail) {
      setSelectedInvoiceDetail(invoiceNumber);
      return;
    }
    setSelectedInvoiceDetail("");
  };

  useEffect(() => {
    if (isShowInvoiceDetail) {
      refetch();
    }
  }, [isShowInvoiceDetail, refetch]);

  const PaymentIcon = {
    [InvoicePaymentType.WALLET]: {
      label: "billing.invoiceHistory.paymentMethod.wallet",
      suffix: "",
      viewBox: "-4 0 26 26",
    },
    [InvoicePaymentType.QR_CASH]: {
      label: "billing.invoiceHistory.paymentMethod.qrCode",
      suffix: "",
      viewBox: "-4 0 26 26",
    },
    [InvoicePaymentType.CREDIT_CARD]: {
      label: CreditCard[cardBrand],
      suffix: creditCardNumber,
      viewBox: isMobile ? "0 3 18 18" : "0 0 16 16",
    },
  };

  return (
    <InvoiceItemCard
      key={invoiceNumber}
      container
      alignItems="center"
      className={`${isShowInvoiceDetail ? "active" : ""} py-3 px-4 table-item`}
    >
      <Grid item xs={isMobile ? 5 : 3} className="item">
        <Typography variant="body3" color="darkGray">
          {moment(paidAt).format(DATE_INVOICE_FORMAT)}
        </Typography>
        {!isPaidByWalletInvoice && (
          <InvoiceNumberText className={isMobile ? "native" : ""} variant="body4" color="darkLight">
            {invoiceNumber}
          </InvoiceNumberText>
        )}
      </Grid>
      {!isMobile && (
        <Grid item xs={3} className="item">
          <Typography variant="body3" color="darkGray">
            {t(PaymentIcon[paymentType].label)}
            <CreditCardText>&nbsp;{t(PaymentIcon[paymentType].suffix)}</CreditCardText>
          </Typography>
        </Grid>
      )}
      <Grid item xs={isMobile ? 6 : 3} className="item">
        <Typography variant="body3" color="darkGray">
          {t("THB")} {convertPriceFormat(totalPrice)}
        </Typography>
        {isMobile && (
          <PaymentWrapper>
            <Typography variant="body4" color="darkLight">
              {t(PaymentIcon[paymentType].label)}
              <CreditCardText>&nbsp;{t(PaymentIcon[paymentType].suffix)}</CreditCardText>
            </Typography>
          </PaymentWrapper>
        )}
      </Grid>
      {!isMobile && (
        <Grid item xs={2} className="item">
          {!isPaidByWalletInvoice ? (
            <DownloadPDFButton onClick={handleDownloadPDF} className={!isPaidByWalletInvoice ? "" : "disabled"}>
              <Typography variant="body3" color="darkGray">
                <SvgIcon viewBox="-4 0 24 24" component={IcDownload} fontSize="small" />
                {t("billing.invoiceHistory.pdf")}
              </Typography>
            </DownloadPDFButton>
          ) : (
            <Typography variant="body4" color="darkLight" className="text-left">
              {t("billing.invoiceHistory.noAttachment")}
            </Typography>
          )}
        </Grid>
      )}
      <ExpandColumn item xs={1} onClick={handleClick} className="item">
        <SvgIcon
          viewBox={isShowInvoiceDetail ? "0 0 11 11" : "0 0 24 24"}
          component={isShowInvoiceDetail ? IcArrowUp : IcArrowDown}
          fontSize="small"
        />
      </ExpandColumn>

      {isShowInvoiceDetail && (
        <Grid container>
          {isShowPackage && (
            <Grid container justify="space-between" className="pt-3">
              <Grid item xs={6}>
                {isMobile ? (
                  <>
                    <Typography variant="title8" color="darkGray">
                      {packageLabel}
                      {subscriptionLabel}
                    </Typography>
                    <Typography variant="body4" color="darkLight">
                      {packageSummaryLabel}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="title8" color="darkGray">
                    {packageLabel}
                    {subscriptionLabel} ({packageSummaryLabel})
                  </Typography>
                )}
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body3" color="darkGray" className={isMobile ? "text-right" : ""}>
                  {t("THB")}&nbsp;{convertPriceFormat(packagePriceTotal)}
                </Typography>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          )}

          {/* ADDON */}
          {itemsSummary && !!itemsSummary.length && (
            <SummaryList isInvoiceSummary type={PaymentSummaryTypes.ADDON} itemsSummary={itemsSummary} />
          )}

          {/* EXTRA */}
          {invoiceUsage.extras && !!invoiceUsage.extras.length && (
            <SummaryList isInvoiceSummary type={PaymentSummaryTypes.EXTRA} itemsSummary={invoiceUsage.extras} />
          )}

          {hasDiscountCredit &&
            discount.map(({ type, total, promotionCode }: InvoiceDiscountItem) => (
              <Grid key={type} container justify="space-between" className="pt-3">
                <Grid item xs={6}>
                  <Typography variant="title8" color="darkGray">
                    {t(DiscountWording[type])}
                  </Typography>
                  {promotionCode && (
                    <Typography variant="body4" color="darkLight">
                      {promotionCode.name}
                    </Typography>
                  )}
                  {promotionCode && promotionCode.code && (
                    <Typography variant="body4" color="darkLight">
                      {t("billing.invoiceHistory.promotion.code", { code: promotionCode.code })}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body3" color="accent" className={isMobile ? "text-right" : ""}>
                    {t("-")}
                    {t("THB")}&nbsp;{convertPriceFormat(total)}
                  </Typography>
                </Grid>
                <Grid item xs={1} />
              </Grid>
            ))}

          {isMobile && !isPaidByWalletInvoice && (
            <Grid container alignItems="center" justify="flex-end" className="pt-3">
              <Grid item xs={11} className="item-right">
                <DownloadPDFButton onClick={handleDownloadPDF}>
                  <SvgIcon viewBox="-4 0 24 24" component={IcDownload} fontSize="small" />
                  <Typography variant="body3" color="darkGray">
                    {t("billing.invoiceHistory.pdf")}
                  </Typography>
                </DownloadPDFButton>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          )}
        </Grid>
      )}
    </InvoiceItemCard>
  );
};

export default InvoiceItem;
