import { gql } from "@apollo/client";

export const SYSTEM_TAGS = gql`
  query systemTags($projectId: ID!, $customerId: ID!) {
    systemTags(projectId: $projectId, customerId: $customerId) {
      id
      projectId
      customerId
      tag
      type
      metadata {
        broadcastId
        orderId
      }
    }
  }
`;

export const SEARCH_TAGS = gql`
  query searchTags($projectId: ID!, $type: TagType!, $tag: String, $offset: Int, $limit: Int) {
    searchTags(projectId: $projectId, type: $type, tag: $tag, offset: $offset, limit: $limit) {
      results {
        id
        tag
        type
      }
      total
    }
  }
`;
