import styled from "styled-components";
import { VARIANTS } from "constants/Typography";

const DownloadSampleLink = styled.a`
  font-size: ${VARIANTS.body4.size};
  color: ${({ theme }) => theme.COLORS.Accent};
  cursor: pointer;
`;

export default DownloadSampleLink;
