import React from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import COLORS from "constants/Colors";
import { VARIANTS } from "constants/Typography";
import { Z_INDEX } from "constants/ZIndex";

export const useStyles = makeStyles(() => {
  return createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "316px",
      backgroundColor: COLORS.LightWhite,
      padding: "32px 16px 16px 16px",
      border: "0.5px solid #E6EBF3",
      borderRadius: "8px",
      position: "relative",
    },
  });
});

export const FullScreenCloseIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 14px;
  z-index: ${Z_INDEX.MODAL};
`;

export const TitleWrapper = styled.div`
  background-color: ${({ theme }) => theme.COLORS.Primary};
  align-items: center;
  display: flex;
  min-height: 60px;
  max-height: 60px;
  text-transform: capitalize;
  color: white;
  font-size: ${VARIANTS.title7.size};
  font-family: ${VARIANTS.title7.fontFamily};
  position: sticky;
  top: 0;
  z-index: ${Z_INDEX.MODAL};
`;

type StyledDialogProps = {
  backgroundColor: string;
};

export const StyledDialog = styled(
  ({ backgroundColor: _backgroundColor, isDisabledOverFlow: _isDisabledOverFlow, ...rest }) => <Dialog {...rest} />,
).attrs((props: StyledDialogProps) => {
  const { backgroundColor } = props;

  return { backgroundColor };
})`
  .MuiPaper-root {
    background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.COLORS.White};
  }
`;
