import { useEffect, useCallback } from "react";
import produce from "immer";

import { TotalUnseenChatMessagesChangedSubscriptionData, TotalUnseenChatMessagesData } from "types/Chat";
import { TOTAL_UNSEEN_CHAT_MESSAGES_CHANGED } from "graphql/chat/subscription";
import useTotalUnseenChatMessages from "./useTotalUnseenChatMessages";

const useTotalUnseenChatMessagesChanged = (projectId: string) => {
  const { totalUnseenChatMessages, loading, subscribeToMore } = useTotalUnseenChatMessages(projectId);

  const subscribeTotalUnseenChatMessagesChanged = useCallback(
    () =>
      subscribeToMore({
        document: TOTAL_UNSEEN_CHAT_MESSAGES_CHANGED,
        variables: {
          projectId,
        },
        updateQuery: (
          prev: TotalUnseenChatMessagesData,
          { subscriptionData }: TotalUnseenChatMessagesChangedSubscriptionData,
        ) => {
          const totalUnseenChatMessagesChanged = subscriptionData?.data?.totalUnseenChatMessagesChanged;

          return produce(prev, (draft) => {
            draft.totalUnseenMessages = totalUnseenChatMessagesChanged;
          });
        },
      }),
    [projectId, subscribeToMore],
  );

  useEffect(() => {
    const unsubscribeTotalUnseenChatMessagesChanged = subscribeTotalUnseenChatMessagesChanged();

    return () => {
      if (unsubscribeTotalUnseenChatMessagesChanged) {
        unsubscribeTotalUnseenChatMessagesChanged();
      }
    };
  }, [subscribeTotalUnseenChatMessagesChanged]);

  return {
    totalUnseenChatMessages,
    loading,
  };
};

export default useTotalUnseenChatMessagesChanged;
