import * as React from "react";

const SvgIcAllorders = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.684 4.444H10.182s-1.439 0-1.439 1.445h14.38c0-1.445-1.44-1.445-1.44-1.445zm4.314 4.334c0-1.445-1.437-1.445-1.437-1.445H7.306s-1.437 0-1.437 1.445v1.444h20.13V8.778zm2.875 1.444l-.85-.855v2.3H3.844v-2.3l-.85.855c-.851.855-1.46 1.084-1.118 2.89.34 1.799 1.992 11.664 2.237 13 .269 1.464 1.758 1.444 1.758 1.444H26s1.488.02 1.758-1.445c.245-1.335 1.895-11.2 2.237-13 .34-1.805-.27-2.034-1.122-2.889zm-7.162 8.663c0 .8-.648 1.448-1.448 1.448h-8.658c-.8 0-1.45-.648-1.45-1.448V16H11.6v2.889h8.667V16h1.444v2.885z"
    />
  </svg>
);

export default SvgIcAllorders;
