import styled from "styled-components";

import Grid from "components/Grid";

export const ContentWrapper = styled(Grid)`
  margin-top: 8px;
  padding: 24px;
  background-color: ${({ theme }) => theme.COLORS.White};
  border-color: ${({ theme }) => theme.COLORS.LightBlue};
  border-style: solid;
  border-width: 0.5px 0;
`;
