import { gql } from "@apollo/client";

export const WAREHOUSES = gql`
  query integrationWarehouses($projectId: ID!, $type: IntegrationType!, $data: IntegrationCredentialInput!) {
    integrationWarehouses(projectId: $projectId, type: $type, data: $data) {
      id
      name
      code
    }
  }
`;

export const INVENTORY_INTEGRATION = gql`
  query integrationByType($projectId: ID!, $type: IntegrationType!) {
    integrationByType(projectId: $projectId, type: $type) {
      id
      projectId
      type
      status
      updatedAt
      data {
        apiKey
        apiSecret
        storeName
        warehouseName
        warehouseCode
      }
      isManual
    }
  }
`;
