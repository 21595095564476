import React from "react";
import { useTranslation } from "react-i18next";

import Typography from "components/Typography";

const Placeholder = () => {
  const { t } = useTranslation();
  return (
    <Typography variant="title2" color="gray" className="text-center my-5">
      {t("Please add new message")}
    </Typography>
  );
};

export default Placeholder;
