import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import isNil from "lodash/isNil";
import Typography from "components/Typography";
import Loading from "domain/Faq/FaqThEn/FaqContainer/Loading";
import { convertPriceFormat } from "utils/common";

type CategoriesAndProductsTotalProps = {
  isLoading?: boolean;
  categoriesTotal?: number;
  productsTotal?: number;
  productSKUsTotal?: number;
};

const CategoriesAndProductsTotal: FC<CategoriesAndProductsTotalProps> = ({
  isLoading = false,
  categoriesTotal,
  productsTotal,
  productSKUsTotal,
}) => {
  const { t } = useTranslation();

  return isLoading ? (
    <Loading loadingLabel={t("LOADING")} />
  ) : (
    <Typography variant="body3" color="darkMed">
      {!isNil(categoriesTotal) && `${convertPriceFormat(categoriesTotal || 0, 0)} ${t("Catalogs")} `}
      {!isNil(productsTotal) && `${convertPriceFormat(productsTotal || 0, 0)} ${t("Products")} `}
      {!isNil(productSKUsTotal) && `${convertPriceFormat(productSKUsTotal || 0, 0)} SKUs `}
    </Typography>
  );
};

export default CategoriesAndProductsTotal;
