import React, { FC, useState, createContext } from "react";

type SideNavMenuContextType = {
  isShowSecondLevel: boolean;
  handleShowSecondLevel: (_isShowSecondLevel: boolean) => void;
};

export const SideNavMenuContext = createContext<SideNavMenuContextType>({
  isShowSecondLevel: false,
  handleShowSecondLevel: () => {},
});

export const SideNavMenuContextProvider: FC = ({ children }) => {
  const [isShowSecondLevel, setIsShowSecondLevel] = useState(false);

  return (
    <SideNavMenuContext.Provider
      value={{
        isShowSecondLevel,
        handleShowSecondLevel: (isShowSecondLevelInput: boolean) => {
          setIsShowSecondLevel(isShowSecondLevelInput);
        },
      }}
    >
      {children}
    </SideNavMenuContext.Provider>
  );
};
