export const thaipostEMSDescription = {
  EN: null,
  TH: [
    { body: "พิมพ์ใบปะหน้าพัสดุที่ได้จากระบบ และแปะลงบนหน้ากล่องพัสดุ" },
    { body: "นำกล่องพัสดุไปส่งที่ไปรษณีย์ไทย สามารถตรวจสอบสาขาที่มี SHIPPOP DROP-OFF" },
    { body: "หลังจากชำระค่าขนส่งเรียบร้อยแล้ว จะต้องจัดส่งภายใน 12 วัน" },
    { body: "น้ำหนักของพัสดุจะต้องไม่เกิน 20 กิโลกรัม" },
    { body: "ขนาดของพัสดุ (กว้าง + ยาว + สูง) จะต้อง​ไม่เกิน 120 เซนติเมตร" },
    { body: "รายการที่ชำระค่าขนส่งแล้ว จะไม่สามารถยกเลิก แก้ไข หรือขอคืนเงินได้ ไม่ว่าจะกรณีใดๆ" },
    {
      body: "หากจัดส่งพัสดุแล้ว ให้ดำเนินการตีกลับจะไม่สามารถนำหมายเลขติดตามพัสดุ (Tracking Number) กลับมาใช้ได้อีก",
    },
    { body: "การเลือกน้ำหนักพัสดุที่ไม่ตรงกับความเป็นจริง ทางบริษัทฯ จะดำเนินการเรียกเก็บค่าขนส่งเพิ่มเติม" },
    { body: "หากสินค้ามีการตีคืนจะมีการเรียกเก็บเงินภายหลัง" },
    {
      body: "ผู้ใช้บริการจะต้องนำสินค้าไปส่ง ณ ที่ทำการไปรษณีย์ ตามที่กำหนดไว้เท่านั้น ในช่วงเวลาทำการ",
      subBody: [
        "วันจันทร์ ถึง วันศุกร์ เวลา 8.00 - 16.00 น. หรือตามเวลาทำการที่สาขากำหนด",
        "วันเสาร์ ถึง วันอาทิตย์ เวลา 8.00 - 12.00 น. หรือตามเวลาทำการที่สาขากำหนด",
      ],
    },
    {
      body: "มาตรฐานการนำส่ง เป็นไปตามเงื่อนไขของบริษัท ไปรษณีย์ไทย จำกัด",
      subBody: [
        "ปลายทางกรุงเทพและปริมณฑล ภายใน 1-2 วันทำการนับจากวันที่ฝากส่ง",
        "เวียงแหง จังหวัดเชียงใหม่, อำเภอเวียงแก่น จังหวัดเชียงราย ,พื้นที่พิเศษ และพื้นที่เกาะต่างๆ จะจัดส่งล่าช้ากว่าที่กำหนดไว้",
      ],
    },
  ],
};
