import { components, ControlProps, OptionType, OptionProps } from "react-select";
import styled, { StyledComponent } from "styled-components";

import { ComponentType } from "react";

export const StyledOption: StyledComponent<
  ComponentType<OptionProps<OptionType>>,
  Record<string, string>,
  {},
  never
> = styled(components.Option)`
  & > div {
    color: inherit;
  }
`;

export const StyledControl: StyledComponent<
  ComponentType<ControlProps<OptionType>>,
  Record<string, string>,
  {},
  never
> = styled(components.Control)`
  min-height: 0 !important;
  max-height: 40px;
  height: 40px;
`;
