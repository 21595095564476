import * as React from "react";

const SvgIcBigadd = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.747 15.247h9.5v-9.5a.75.75 0 011.5 0v9.5h9.5a.75.75 0 010 1.5h-9.5v9.5a.75.75 0 01-1.5 0v-9.5h-9.5a.75.75 0 110-1.5z"
    />
  </svg>
);

export default SvgIcBigadd;
