import { ReactNode } from "react";
import { MessageType } from "types/Chat";
import { CategoryType } from "types/Category";
import { ProductItemPropsType } from "types/Product";
import { AtLeast } from "./Common";

export type MessageTextValue = string;

export type MessageImageValue = {
  path?: string;
  url: string;
};

export type CategoriesObjectType = {
  categories: CategoryType[];
};

export type ProductsObjectType = {
  products: ProductItemPropsType[];
};

export type QuickReplyMessage = { text: string; label: string };

export type ResponseMessage = {
  type: MessageType;
  value:
    | MessageTextValue
    | MessageImageValue
    | number[]
    | CategoriesObjectType
    | ProductsObjectType
    | QuickReplyMessage[];
};

export type DuplicatedQuestionType = { firstQuestion: string; index: number; faqId: string };

export type FaqQuestionType = string;

export type FaqColorIntents = {
  localSynonyms: string[];
  text: string;
  state: StateColors;
  index: number;
  originalText: string;
  overlappedIntents: string[];
};

export type FaqType = {
  id: string;
  questions: FaqQuestionType[];
  responses: ResponseMessage[];
  enQuestions: FaqQuestionType[];
  enResponses: ResponseMessage[];
  isActive: boolean;
  isEnabledNotification: boolean;
  colorIntents?: FaqColorIntents[];
};

export type ChangedFaqQuestionType = { id: string; value: string };

export type ChangedFaqType = {
  id: string;
  questions: ChangedFaqQuestionType[];
  responses: ResponseMessage[];
  enQuestions: ChangedFaqQuestionType[];
  enResponses: ResponseMessage[];
  isActive: boolean;
  isEnabledNotification: boolean;
  colorIntents: MapColorIntent;
};

export type Highlight = {
  overlappedIntents?: string[];
  highlight: string;
  component?: ReactNode;
  className?: string;
  tooltip?: string;
};

export type MapColorIntent = Record<string, Highlight[]>;

export type ExcludedQuestionType = { faqId: string; index?: number };

export type FaqQueryType = {
  faqs: FaqType[];
};

export type FaqQueryByIdType = {
  faqById: FaqType;
};

export type FaqValidationType = {
  id: string;
  score: string;
  duplicateQuestions: string[];
};

export enum StateColors {
  GAS = "GAS",
  SOLID = "SOLID",
  LIQUID = "LIQUID",
  PLASMA = "PLASMA",
}

export enum QuestionCheckingEvent {
  NO_CHANGE = "NO_CHANGE",
  MIN_LENGTH_REQUIRED = "MIN_LENGTH_REQUIRED",
}

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export type ChangedFaqDataType = boolean | FaqQuestionType[] | ResponseMessage[];

export type EditedFaqType = AtLeast<FaqType, "id">;

export type BotFlowBehaviorType = {
  category: CategoryConfigurationType;
  postback: PostbackType;
  product: ProductType;
};

type CategoryConfigurationType = {
  isHomePageWebviewCardEnabled: boolean;
  isOpenWebviewWhenOverThresholdEnabled: boolean;
};

type PostbackType = {
  isProcessingPostbackEnabled: boolean;
};

type ProductType = {
  isOpenWebviewOnSelectProductEnabled: boolean;
};
