import { FaqType, ExcludedQuestionType, DuplicatedQuestionType } from "types/AISetting";
import { getDuplicatedQuestionFromIndex, getMappedQuestions } from "utils/faq";

export type CheckDuplicatedQuestionType = (
  questionInput: string,
  questionFieldName: keyof FaqType,
  exclude?: ExcludedQuestionType,
) => DuplicatedQuestionType | null;

const useCheckDuplicatedQuestionFromAll = (faqs: FaqType[]) => {
  const checkDuplicatedQuestion: CheckDuplicatedQuestionType = (questionInput, questionFieldName, exclude) => {
    let questionsLengths: number[] = [];
    let faqIds: string[] = [];

    const mappedQuestions = getMappedQuestions(faqs, questionsLengths, faqIds, questionFieldName, exclude);

    questionsLengths = mappedQuestions.questionsLengths;
    faqIds = mappedQuestions.faqIds;

    const questions = [...mappedQuestions.questions];
    const duplicatedQuestionIndex = questions.indexOf(questionInput);

    const duplicatedQuestion = getDuplicatedQuestionFromIndex(
      faqIds,
      questions,
      questionsLengths,
      duplicatedQuestionIndex,
    );

    return duplicatedQuestion;
  };

  return checkDuplicatedQuestion;
};

export default useCheckDuplicatedQuestionFromAll;
