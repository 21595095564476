import { gql } from "@apollo/client";

export const EXPORT_ORDERS = gql`
  query exportOrders($projectId: ID!, $filter: ExportOrdersInput) {
    exportOrders(projectId: $projectId, filter: $filter) {
      id
      orderNumber
      note
      trackingNumber
    }
  }
`;
