import * as React from "react";

const SvgIcSend = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.753 24.01l5.037 1.732-3.36 4.602a.926.926 0 01-1.677-.554v-5.78zM28.841.616a.896.896 0 01.985.073c.29.22.438.594.386.97l-3.462 24.85a1.018 1.018 0 01-.443.712.905.905 0 01-.796.093L12.31 22.5 25.29 5.82 8.515 21.119.638 18.247a1 1 0 01-.635-.877c-.028-.402.17-.783.505-.97z"
    />
  </svg>
);

export default SvgIcSend;
