/* eslint-disable func-names */
import React, { useEffect, useState, FC } from "react";
import get from "lodash/get";
import includes from "lodash/includes";

import { useHistory, Redirect } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { REGISTER_FCM_TOKEN } from "graphql/notification/mutation";
import { UserProfileDetailType } from "types/User";
import { getMessagingClient } from "./client";

const NOTIFICATION_TAPS = ["foreground", "background"];

export type FCMTokenHandlerPropsType = {
  loading: boolean;
  currentUser?: UserProfileDetailType;
};

export const FCMTokenHandler: FC<FCMTokenHandlerPropsType> = ({ loading, currentUser }) => {
  const [registerFCMToken] = useMutation(REGISTER_FCM_TOKEN);
  const [fcmToken, setFCMToken] = useState("");
  const [clickAction, setClickAction] = useState("");
  const history = useHistory();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { FirebasePlugin } = window as any;
    if (!loading) {
      const messagingClient = getMessagingClient();
      if (currentUser) {
        if (FirebasePlugin) {
          // handeling for mobile notification
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          FirebasePlugin.grantPermission((hasPermission: any) => {
            console.log("has notification Permission", hasPermission);
          });
          FirebasePlugin.getToken(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (token: any) => {
              // save this server-side and use it to push notifications to this device
              // this.props.mutate({ variables: { token } });
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (window as any).token = token;
              setFCMToken(token);
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            function (error: any) {
              console.error("getToken", error);
            },
          );
          FirebasePlugin.onTokenRefresh(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (token: any) => {
              // save this server-side and use it to push notifications to this device
              setFCMToken(token);
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            function (error: any) {
              console.error("onTokenRefresh", error);
            },
          );
          FirebasePlugin.onMessageReceived(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (notification: any) => {
              if (includes(NOTIFICATION_TAPS, notification.tap)) {
                const clickActionIOS = get(notification, "aps.alert.click_action");
                const clickActionAndroid = get(notification, "click_action");
                const clickActionUrl = clickActionIOS || clickActionAndroid;

                history.push(clickActionUrl);
                setClickAction(clickActionUrl);
              }
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (error: any) => {
              console.error(error);
            },
          );
        } else {
          const loadAndSaveFCMToken = async () => {
            if (messagingClient) {
              const token = await messagingClient.getToken();
              setFCMToken(token);
            }
          };
          loadAndSaveFCMToken();
        }
      } else if (messagingClient) {
        // deleteToken be able to called without specify token
        // But defined type force us to pass token in
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (messagingClient as any).deleteToken();
      }
    }
  }, [loading, currentUser, history, fcmToken]);

  useEffect(() => {
    if (fcmToken) {
      registerFCMToken({
        variables: {
          token: fcmToken,
        },
      });
    }
  }, [fcmToken, registerFCMToken]);

  if (clickAction) {
    return <Redirect to={clickAction} />;
  }

  return null;
};
