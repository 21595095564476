import React, { FC } from "react";

import Grid from "components/Grid";
import Typography from "components/Typography";

type PaymentSummaryItemPropsType = {
  label: string;
  price: string;
  color: string;
};

const PaymentSummaryItem: FC<PaymentSummaryItemPropsType> = (props) => {
  const { label, price, color } = props;

  return (
    <Grid container justify="space-between" className="py-1">
      <Grid item xs={8} sm={10}>
        <Typography variant="body3" color={color}>
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body3" color={color}>
          {price}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PaymentSummaryItem;
