import COLORS from "constants/Colors";
import { DashboardFilterType, GraphColorType, GraphStatKeyType } from "types/Stat";

export enum STAT_EVENT_NAME {
  NEW_CUSTOMER = "NEW_CUSTOMER",
  ORDER_PAID = "ORDER_PAID",
}

export enum TIME_UNIT {
  MINUTE = "MINUTE",
  HOUR = "HOUR",
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  YEAR = "YEAR",
}

export enum STAT_WIDGET_TYPE {
  ORDER = "ORDER",
  CUSTOMER = "CUSTOMER",
  PRICE = "PRICE",
}

export enum TIME_FIELD {
  DEFAULT = "DEFAULT",
  ORDER_CREATED_AT = "ORDER_CREATED_AT",
  ORDER_PAID_AT = "ORDER_PAID_AT",
}

export const DASHBOARD_FILTER: DashboardFilterType[] = [
  {
    label: "stat.dateFilter.label.day",
    duration: 0,
  },
  {
    label: "stat.dateFilter.label.yesterday",
    duration: 1,
  },
  {
    label: "stat.dateFilter.label.week",
    duration: 7,
  },
  {
    label: "stat.dateFilter.label.month",
    duration: 30,
  },
];

export const GRAPH_STAT_CONFIGS: GraphStatKeyType[] = ["totalSales", "orders", "customers"];

export const GRAPH_COLORS: GraphColorType = {
  totalSales: COLORS.GraphTotalSale,
  orders: COLORS.GraphOrder,
  customers: COLORS.GraphCustomer,
};
