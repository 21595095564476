import React, { FC, useState, useRef, useEffect, useCallback } from "react";
import { OptionType, ValueType } from "react-select";
import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Avatar from "components/Avatar";
import Button from "components/Button";
import Card from "components/Card";
import CircularProgress from "components/CircularProgress";
import ErrorText from "components/ErrorText";
import Grid from "components/Grid";
import Modal from "components/Modal";
import Select from "components/Select";
import TextField from "components/TextField";
import Typography from "components/Typography";

import { notifySuccess, notifyError } from "utils/notify";
import { DEFAULT_AVATAR_IMG } from "constants/Image";
import { NOTIFICATION_UPDATE } from "constants/Notification";

import Dropzone from "components/DropzoneUpload";
import { IcEdit } from "components/SvgIcons";
import { FLAG_USER_WITH_IS_ENTERPRISE } from "graphql/auth/query";
import { UserProfileType } from "types/User";
import { ImageUrl } from "types/Image";
import { UPDATE_USER } from "graphql/auth/mutation";
import { AuthProvider } from "types/Auth";
import { changeLanguage } from "utils/i18n";
import ChangePasswordForm from "../ChangePasswordForm";
import { LANGUAGE_OPTIONS, Option, SingleValue } from "./languageOptions";
import schema from "./schema";
import { AvatarWrapper, IconEditWrapper } from "./styled";
import SocialAccountSetting from "../SocialAccount";

type UserData = {
  name: string;
  language: string;
  avatar: string;
  phoneNumber?: string;
};

type ProfileSettingFormPropsType = {
  projectId: string;
};

const ProfileSettingForm: FC<ProfileSettingFormPropsType> = ({ projectId }) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery<UserProfileType>(FLAG_USER_WITH_IS_ENTERPRISE, { variables: { projectId } });
  const [updateUser] = useMutation(UPDATE_USER);
  const { register, errors, handleSubmit, getValues } = useForm<UserData>({
    resolver: yupResolver(schema),
  });
  const previousUserData = useRef<UserData>();

  const [avatar, setAvatar] = useState("");
  const [language, setLanguage] = useState("EN");
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitDisable, setIsSubmitDisable] = useState(true);

  const onSubmitHandler = handleSubmit(async (formData: UserData) => {
    const { name, phoneNumber } = formData;
    try {
      await updateUser({ variables: { displayName: name, language, avatar, phoneNumber } });
      setIsSubmitDisable(true);
      changeLanguage(language);
      notifySuccess(t(NOTIFICATION_UPDATE.SUCCESS));
    } catch (error) {
      notifyError(t(NOTIFICATION_UPDATE.FAIL));
    }
  });

  const handleChange = (imageUrl: ImageUrl[]) => {
    if (imageUrl.length) {
      const [{ publicUrl }] = imageUrl;
      setAvatar(publicUrl);
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  // #region Handle newly selected language
  const handleSelectLanguage = (selected: ValueType<OptionType>) => {
    const newLang = (selected as OptionType).value;
    // Setting language for updating user
    setLanguage(newLang);
  };
  // #endregion

  const isUserDataEqual = useCallback(() => {
    const formValue = getValues();
    const newUserData = {
      avatar,
      language,
      name: formValue.name,
      phoneNumber: formValue.phoneNumber,
    };

    if (isEqual(newUserData, previousUserData.current)) {
      setIsSubmitDisable(true);
    } else {
      setIsSubmitDisable(false);
    }
  }, [avatar, getValues, language]);

  useEffect(() => {
    const tempAvatar = get(data, "me.avatar");
    const tempLanguage = get(data, "me.language");
    const tempDisplayName = get(data, "me.displayName");
    const tempPhoneNumber = get(data, "me.phoneNumber");

    if (tempAvatar) {
      setAvatar(tempAvatar);
    }

    if (tempLanguage) {
      setLanguage(tempLanguage);
    }

    previousUserData.current = {
      avatar: tempAvatar,
      name: tempDisplayName,
      language: tempLanguage,
      phoneNumber: tempPhoneNumber,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    isUserDataEqual();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatar, language]);

  const defaultLanguage = LANGUAGE_OPTIONS.find((option) => option.value === get(data, "me.language"));
  const displayName = get(data, "me.displayName");
  const email = get(data, "me.email");
  const phoneNumber = get(data, "me.phoneNumber");
  const authProvider = get(data, "me.authProvider") || AuthProvider.DEEPLE;
  const isAuthProvider = ![AuthProvider.APPLE, AuthProvider.GOOGLE, AuthProvider.FACEBOOK].includes(authProvider);

  if (loading) {
    return (
      <Grid container justify="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <form noValidate autoComplete="off" onSubmit={onSubmitHandler} className="position-relative pt-5">
        <Card className="mt-5">
          <AvatarWrapper>
            <span className="position-relative">
              <Avatar alt="profile-image" src={avatar || DEFAULT_AVATAR_IMG} size="80px" />
              <Dropzone projectId={projectId} onChange={handleChange} noDrag>
                <IconEditWrapper>
                  <IcEdit />
                </IconEditWrapper>
              </Dropzone>
            </span>
          </AvatarWrapper>

          <Grid container>
            <Grid item xs={12}>
              <Typography variant="title8" color="darkMed">
                {t("Email")}
              </Typography>
              <Typography className="mt-2" variant="body3" color="dark">
                {email}
              </Typography>
              <TextField
                label={t("Name")}
                name="name"
                type="text"
                margin="normal"
                fullWidth
                validate={register}
                defaultValue={displayName}
                error={Boolean(errors.name)}
                onChange={isUserDataEqual}
              />
              <ErrorText>{errors.name && t(errors.name.message as string)}</ErrorText>
              <TextField
                label={t("PHONE_NUMBER")}
                type="text"
                name="phoneNumber"
                defaultValue={phoneNumber}
                validate={register}
                error={Boolean(errors.phoneNumber)}
                onChange={isUserDataEqual}
                margin="normal"
                fullWidth
              />
              <ErrorText>{errors.phoneNumber && t(errors.phoneNumber.message as string)}</ErrorText>
              <div className="mt-3 mb-2">
                <Select
                  isSearchable={false}
                  className="mt-4"
                  defaultValue={defaultLanguage}
                  components={{ SingleValue, Option }}
                  options={LANGUAGE_OPTIONS}
                  onChange={handleSelectLanguage}
                  fullWidth
                />
              </div>
            </Grid>
            {isAuthProvider && (
              <Grid item xs={12} className="mt-2 text-center">
                <Button type="button" className="mt-4" color="secondary" fullWidth onClick={handleOpenModal}>
                  {t("Change password")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Card>
        <SocialAccountSetting user={data?.me} />
        <div className="mt-5 mb-4">
          <Button fullWidth type="submit" disabled={isSubmitDisable} data-cy="accountSubmit">
            {t("Submit")}
          </Button>
        </div>
      </form>
      <Modal isOpen={isOpen} onClose={handleCloseModal} fullScreen>
        <ChangePasswordForm email={email} callback={handleCloseModal} />
      </Modal>
    </>
  );
};

export default ProfileSettingForm;
