import {
  RecurringNotificationOptInButtonMessageType,
  RecurringNotificationOptInDeliveryContextType,
  RecurringNotificationOptInFrequencyType,
} from "types/Broadcast";

export const MAX_TITLE_MESSAGE = 65;
export const MESSAGE_BEFORE_REQUEST_MAX_LENGTH = 300;
export const MESSAGE_AFTER_REQUEST_ACCEPTED_MAX_LENGTH = 300;

export const REQUEST_CONTEXT_OPTIONS: Readonly<
  { label: string; value: RecurringNotificationOptInDeliveryContextType }[]
> = [
  {
    label: "broadcast.facebookRequestForm.form.welcomeMessageSent",
    value: RecurringNotificationOptInDeliveryContextType.WELCOME_MESSAGE_SENT,
  },
  {
    label: "broadcast.facebookRequestForm.form.paymentVerifired",
    value: RecurringNotificationOptInDeliveryContextType.PAYMENT_VERIFIED,
  },
  {
    label: "broadcast.facebookRequestForm.form.trackingNumberSent",
    value: RecurringNotificationOptInDeliveryContextType.TRACKING_NUMBER_SENT,
  },
];

export const FREQUENCY_OPTIONS: Readonly<{ label: string; value: RecurringNotificationOptInFrequencyType }[]> = [
  {
    label: "broadcast.facebookRequestForm.form.daily",
    value: RecurringNotificationOptInFrequencyType.DAILY,
  },
  // {
  //   label: "broadcast.facebookRequestForm.form.weekly",
  //   value: RecurringNotificationOptInFrequencyType.WEEKLY,
  // },
  // {
  //   label: "broadcast.facebookRequestForm.form.monthly",
  //   value: RecurringNotificationOptInFrequencyType.MONTHLY,
  // },
];

export const BUTTON_MESSAGE_OPTIONS: Readonly<
  { label: string; value: RecurringNotificationOptInButtonMessageType }[]
> = [
  {
    label: "broadcast.facebookRequestForm.form.allow",
    value: RecurringNotificationOptInButtonMessageType.ALLOW,
  },
  // {
  //   label: "broadcast.facebookRequestForm.form.frequency",
  //   value: RecurringNotificationOptInButtonMessageType.FREQUENCY,
  // },
  {
    label: "broadcast.facebookRequestForm.form.get",
    value: RecurringNotificationOptInButtonMessageType.GET,
  },
  {
    label: "broadcast.facebookRequestForm.form.optIn",
    value: RecurringNotificationOptInButtonMessageType.OPT_IN,
  },
  {
    label: "broadcast.facebookRequestForm.form.signUp",
    value: RecurringNotificationOptInButtonMessageType.SIGN_UP,
  },
];

export const DEFAULT_MESSAGE_BEFORE_REQUEST = "broadcast.facebookRequestForm.form.defaultMessageBeforeRequest";

export const DEFAULT_MESSAGE_AFTER_REQUEST_ACCEPTED =
  "broadcast.facebookRequestForm.form.defaultMessageAfterRequestAccepted";
