import styled from "styled-components";
import { ButtonWithIcon } from "components/Button";

export const ProductModalHeader = styled.div`
  height: 57px;
  background: ${({ theme }) => `${theme.COLORS.Primary}`};
  color: ${({ theme }) => `${theme.COLORS.LightWhite}`};
  text-transform: uppercase;
  display: flex;
  align-content: center;
  & > div {
    align-self: center;
  }
`;

export const BinIconContainer = styled(ButtonWithIcon)`
  top: 0;
  right: 0;
`;

export const SkuSelectorWrapper = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  width: calc(100vw - 48px);
`;

export const ProductCardWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: ${({ theme }) => `${theme.COLORS.LightGray}`};
`;

export const ProductSKUCardWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: ${({ theme }) => `${theme.COLORS.LightGray}`};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
