import { useQuery } from "@apollo/client";

import { SCHEDULED_POSTS } from "graphql/facebookCrawler/query";
import { CrawlerType } from "types/FacebookCrawler";

export const useFetchFacebookScheduledPost = (
  projectId: string,
  type: CrawlerType | null,
  limit: number,
  after: string,
) => {
  const { data: scheduledPostsData, loading: isScheduledPostsDataLoading } = useQuery(SCHEDULED_POSTS, {
    skip: !projectId || !type,
    variables: {
      after,
      limit,
      projectId,
      type,
    },
    fetchPolicy: "network-only",
  });

  return { scheduledPostsData, isScheduledPostsDataLoading };
};
