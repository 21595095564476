import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { SvgIcon } from "components/Icon";
import { IcAddphoto, IcCircleRemove } from "components/SvgIcons";
import ConfirmationModal from "components/ConfirmationModal";
import Dropzone from "components/DropzoneUpload";
import Typography from "components/Typography";
import { COLORS } from "constants/Colors";
import { UploadDirectoryType, ImageUrl } from "types/Image";
import { ProjectIdType } from "types/Project";
import {
  ImageContainer,
  CircleRemoveIconContainer,
  ImageItem,
  CoverLabel,
  AddImageBox,
  CircleEditIconContainer,
} from "./styled";

type ImageUploadPropsType = {
  directoryType?: UploadDirectoryType;
  handleSetPrimaryImage?: Function;
  image?: string;
  imageId?: string; // This field required when user want to set label primary
  isConfirmRemove?: boolean;
  isHideRemoveButton?: boolean;
  isMultiUpload?: boolean;
  isPrimary?: boolean;
  isReplaceMode?: boolean;
  isShowLabel?: boolean;
  limitItem?: number;
  multiple?: boolean;
  onChange: (imageUrl: ImageUrl[]) => void;
  onRemove?: () => void;
  height?: number;
  width?: number;
  name?: string;
  validate?: () => void;
};

const ImageUpload: FC<ImageUploadPropsType> = (props) => {
  const {
    directoryType,
    handleSetPrimaryImage,
    image: imageProps,
    imageId,
    isConfirmRemove = false,
    isHideRemoveButton = false,
    isPrimary,
    isReplaceMode,
    isShowLabel,
    limitItem,
    multiple,
    onChange,
    onRemove = () => {},
    height,
    width,
  } = props;

  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const [image, setImage] = useState(imageProps);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  const handleOpenConfirmModal = () => {
    setIsOpenConfirmModal(true);
  };

  const handleRemoveImage = () => {
    onChange([]);
    setImage("");

    onRemove();
    setIsOpenConfirmModal(false);
  };

  const handleChangeImage = (imageUrls: ImageUrl[]) => {
    onChange(imageUrls);
    if (!multiple && imageUrls[0]) {
      setImage(imageUrls[0].publicUrl);
    }
  };

  useEffect(() => {
    setImage(imageProps);
  }, [imageProps]);

  return image ? (
    <>
      <ImageContainer className="d-flex" data-cy="product-image" height={height} width={width}>
        {isReplaceMode ? (
          <Dropzone
            projectId={projectId}
            UploadDirectory={directoryType}
            onChange={handleChangeImage}
            multiple={multiple}
            limitItem={limitItem}
          >
            <CircleEditIconContainer>
              <span className="p-1">
                <SvgIcon component={IcAddphoto} fontSize="inherit" htmlColor={COLORS.DarkMed} className="pr-1 pt-1" />
              </span>
            </CircleEditIconContainer>
          </Dropzone>
        ) : (
          <>
            {!isHideRemoveButton && (
              <CircleRemoveIconContainer onClick={isConfirmRemove ? handleOpenConfirmModal : handleRemoveImage}>
                <SvgIcon component={IcCircleRemove} fontSize="inherit" htmlColor={COLORS.DarkMed} />
              </CircleRemoveIconContainer>
            )}
          </>
        )}
        <a href={image} target="_blank" rel="noopener noreferrer">
          <ImageItem alt="product-image" src={image} />
        </a>
        {isShowLabel && (
          <CoverLabel
            isPrimary={isPrimary}
            onClick={() => {
              if (handleSetPrimaryImage && imageId) {
                handleSetPrimaryImage(imageId);
              }
            }}
          >
            Cover image
          </CoverLabel>
        )}
      </ImageContainer>
      <ConfirmationModal
        title={t("Are you sure you want to remove ?")}
        isOpen={isOpenConfirmModal}
        onClose={() => setIsOpenConfirmModal(false)}
        onSubmit={handleRemoveImage}
      />
    </>
  ) : (
    <Dropzone
      projectId={projectId}
      UploadDirectory={directoryType}
      onChange={handleChangeImage}
      multiple={multiple}
      limitItem={limitItem}
    >
      <AddImageBox height={height} width={width}>
        <SvgIcon component={IcAddphoto} fontSize="large" htmlColor={COLORS.DarkMed} />
        <Typography className="mt-2" variant="body4" color="gray">
          + {t("Add Image")}
        </Typography>
      </AddImageBox>
    </Dropzone>
  );
};

export default ImageUpload;
