import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";

import Button from "components/Button";
import Grid from "components/Grid";
import Typography from "components/Typography";

import { PROJECT, PLAN, PLAN_ADDON } from "constants/Router";
import { ProjectIdType } from "types/Project";

import { AddonWrapper } from "../styled";

const Addon: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const history = useHistory();

  const handleClickButton = () => {
    history.push(`/${PROJECT}/${projectId}/${PLAN}/${PLAN_ADDON}`);
  };

  return (
    <AddonWrapper>
      <Grid container justify="space-between" alignItems="center">
        <Grid item container xs={7} alignItems="baseline">
          <Grid item className="pr-1">
            <Typography variant="body3" color="darkGray">
              {t(`planBilling.label.addon.preText`)}
            </Typography>
          </Grid>
          <Grid item className="pr-1">
            <Typography variant="title8" color="darkGray">
              {t(`planBilling.label.addon`)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body3" color="darkGray">
              {t(`planBilling.label.addon.postText`)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Button size="small" fullWidth onClick={handleClickButton}>
            {t(`planBilling.button.addon`)}
          </Button>
        </Grid>
      </Grid>
    </AddonWrapper>
  );
};

export default Addon;
