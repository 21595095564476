import { useState, useCallback } from "react";
import { OptionType } from "react-select";
import produce from "immer";
import { OrderByType } from "types/SalesReport";

const DEFAULT_ROWS_PER_PAGE = 25;
const DEFAULT_PAGE = 0;
const DEFAULT_FILTER = {
  direction: OrderByType.ASC,
  orderBy: "updated_at",
  responseMessageTags: [],
};

export enum CustomerOrderByField {
  CUSTOMER_NAME = "CUSTOMER_NAME",
  CUSTOMER_PLATFORM = "CUSTOMER_PLATFORM",
  CUSTOMER_TOTAL_SPENDING = "CUSTOMER_TOTAL_SPENDING",
}

export const MAPPED_CUSTOMER_FIELD = {
  profile: CustomerOrderByField.CUSTOMER_NAME,
  platform: CustomerOrderByField.CUSTOMER_PLATFORM,
  totalSpend: CustomerOrderByField.CUSTOMER_TOTAL_SPENDING,
};

export type Filter = {
  keyword?: string;
  direction?: OrderByType;
  orderBy?: string;
  responseMessageTags?: OptionType[];
};

const usePaginationState = (defaultFilter?: Filter, defaultRowsPerPage?: number, defaultPage?: number) => {
  const [filter, setFilter] = useState<Filter>(defaultFilter || DEFAULT_FILTER);
  const [page, setPage] = useState(Number(defaultPage) || DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(Number(defaultRowsPerPage) || DEFAULT_ROWS_PER_PAGE);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(DEFAULT_PAGE);
  }, []);

  const handleClickPageNo = useCallback((selectedItem: { selected: number }) => {
    setPage(selectedItem.selected);
  }, []);

  const handleSetFilter = useCallback(
    (newFilter: Filter) => {
      const temp = produce(filter, (draft) => {
        Object.keys(newFilter).forEach((key) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          draft[key as keyof Filter] = newFilter[key as keyof Filter] as any;
        });
      });

      setPage(DEFAULT_PAGE);
      setFilter(temp);
    },
    [filter],
  );

  return {
    filter,
    page,
    rowsPerPage,
    setFilter,
    setPage,
    setRowsPerPage,
    handleChangeRowsPerPage,
    handleClickPageNo,
    handleSetFilter,
    defaultPage: DEFAULT_PAGE,
  };
};

export default usePaginationState;
