const optionSelector = {
  en: {
    "optionSelector.label.pick": "Pick",
    "optionSelector.label.max": "Max.",
    "optionSelector.label.note": "Additional note:",
    "optionSelector.input.note.placeholder": "Example: No garlic",
    "optionSelector.error.choiceRequired": "Option is required",
  },
  th: {
    "optionSelector.label.pick": "เลือก",
    "optionSelector.label.max": "เลือกได้สูงสุด",
    "optionSelector.label.note": "หมายเหตุ:",
    "optionSelector.input.note.placeholder": "ตัวอย่าง: ไม่ใส่กระเทียม",
    "optionSelector.error.choiceRequired": "โปรดเลือกตัวเลือกในรายการ",
  },
};

export default optionSelector;
