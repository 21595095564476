import { IcHelp } from "components/SvgIcons";
import { OptionType, ValueType, OptionsType } from "react-select";
import { SvgIcon } from "components/Icon";
import { useTranslation } from "react-i18next";
import CreateableSelect from "components/CreatableSelect";
import React, { FC, useState } from "react";
import size from "lodash/size";
import styled from "styled-components";
import Typography from "components/Typography";
import { COLORS } from "constants/Colors";
import { validateSpecialCharacter } from "utils/common";

type TagInputWithLabelPropsType = {
  label?: string;
  limit?: number;
  options?: OptionsType<OptionType>;
  value?: ValueType<OptionType>;
  onChange: (e: ValueType<OptionType>) => void;
  isOptional?: boolean;
  className?: string;
  placeholder?: string;
  dataCy?: string;
  characterLimit?: number;
  notAllowSpecialCharacter?: boolean;
  restrictWord?: string;
  restrictWordErrorMessage?: string;
};

const TagInputWithLabelContainer = styled.div`
  margin: 8px 0;
  border-bottom: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:focus-within {
    border-bottom: solid 2px ${({ theme }) => theme.COLORS.Primary};
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
`;

export const TagInputWithLabel: FC<TagInputWithLabelPropsType> = (props) => {
  const {
    label,
    isOptional,
    options = [],
    limit = 0,
    value = [],
    onChange,
    className,
    placeholder = "",
    dataCy,
    characterLimit,
    notAllowSpecialCharacter = false,
    restrictWord,
    restrictWordErrorMessage = "",
  } = props;
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState<ValueType<OptionType>>(value);

  const { t } = useTranslation();

  const isTagValueReachLimit = selectedValue && limit && (selectedValue as readonly OptionType[]).length >= limit;

  const handleCreateOption = (createdValue: string) => {
    const trimmedValue = createdValue.trim();
    const hasSpecialCharacter = notAllowSpecialCharacter ? validateSpecialCharacter(trimmedValue) : false;
    const trimmedRestrictWord = restrictWord && restrictWord.trim();

    if (trimmedValue !== "" && !hasSpecialCharacter && trimmedValue !== trimmedRestrictWord) {
      const isTrimmedValueExist = (selectedValue as OptionType[]).find(
        (selectedValueItem: OptionType) => selectedValueItem.value === trimmedValue,
      );

      if (!isTrimmedValueExist) {
        const newOption = {
          label: trimmedValue,
          value: trimmedValue,
        };
        const newSelectedValue = selectedValue ? (selectedValue as OptionType[]).concat([newOption]) : [newOption];
        setSelectedValue(newSelectedValue);
        onChange(newSelectedValue);
      }
    }
  };

  const generateNoOptionsMessage = (input: { inputValue: string }) => {
    let message = placeholder || t("Add tag");
    const textInput = input.inputValue;

    const hasSpecialCharacter = notAllowSpecialCharacter ? validateSpecialCharacter(textInput) : false;

    if (isTagValueReachLimit) {
      message = t("you have reach limit");
    } else if (hasSpecialCharacter) {
      message = t("No accept special character");
    } else if (inputValue) {
      message = t("already exist");
    }

    return message;
  };

  const formatCreateLabel = (val: string) => {
    const trimmedValue = val.trim();
    const isRestrictWord = restrictWord && restrictWord === inputValue;

    if (isRestrictWord) {
      const msg = restrictWordErrorMessage || t("This word is restrict");

      return `${trimmedValue}, ${msg}`;
    }

    return `${t("Add")} ${trimmedValue}`;
  };

  return (
    <TagInputWithLabelContainer className={className} data-cy={dataCy}>
      {label && (
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex flex-grow-1 align-items-center">
            <Typography variant="body4" color="gray" className="pr-2">
              {label}
            </Typography>
            {isOptional && (
              <>
                <SvgIcon className="ml-1" component={IcHelp} fontSize="small" htmlColor={COLORS.DarkLight} />
                <Typography variant="body5" color="gray" className="font-italic">
                  optional
                </Typography>
              </>
            )}
          </div>
          {Boolean(limit) && (
            <Typography variant="body5" color="gray">
              {size(value)} / {limit}
            </Typography>
          )}
        </div>
      )}

      <CreateableSelect
        className="pb-1 pt-2"
        isMulti
        isSearchable
        noOutline
        options={isTagValueReachLimit ? [] : options}
        value={selectedValue}
        placeholder={placeholder}
        onChange={(option: ValueType<OptionType>) => {
          setSelectedValue(option || []);
          onChange(option);
        }}
        onInputChange={(inputText) => {
          if (inputText.trim() === "") {
            setInputValue("");
          } else if (!isTagValueReachLimit) {
            if (characterLimit) {
              if (inputText.length <= characterLimit) {
                setInputValue(inputText);
              }
            } else {
              setInputValue(inputText);
            }
          }
        }}
        onCreateOption={handleCreateOption}
        noOptionsMessage={generateNoOptionsMessage}
        inputValue={inputValue}
        formatCreateLabel={formatCreateLabel}
      />
    </TagInputWithLabelContainer>
  );
};

export default TagInputWithLabel;
