import { IcAwaitingShipping, IcShipped, IcBilling, IcAwaitingApproval } from "components/SvgIcons";
import { InvoiceStatue } from "types/Report";
import { SHIPPOP_ERROR_CODE } from "types/Shipping";

export const DEFAULT_IMG = "assets/no-img-available.png";
export const DEFAULT_PAGE_START = 1;
export const DEFAULT_ROWS_PER_PAGE = 10;
export const DEFAULT_VALUE = "-";
export const MAXIMUM_SELECTED_ORDER_ID = 50;
export const PRINT_FILE_TYPE = "PDF";
export const ROWS_PER_PAGES = [DEFAULT_ROWS_PER_PAGE, 25, 50];

export const TAB_CONFIG = {
  header: [
    {
      id: InvoiceStatue.PENDING, // เป็นได้ทั้ง Shipment status: NO_SHIPMENT, CANCEL
      title: InvoiceStatue.PENDING,
      icon: IcAwaitingApproval,
    },
    {
      id: InvoiceStatue.SETTLED, // เป็นได้ทั้ง Shipment status: BOOKING
      title: InvoiceStatue.SETTLED,
      icon: IcBilling,
    },
    {
      id: InvoiceStatue.SHIPPING, // เป็นได้ทั้ง Shipment status: SHIPPING
      title: InvoiceStatue.SHIPPING,
      icon: IcAwaitingShipping,
    },
    {
      id: InvoiceStatue.COMPLETED, // เป็นได้ทั้ง Shipment status: COMPLETE
      title: InvoiceStatue.COMPLETED,
      icon: IcShipped,
    },
  ],
};

export const SHIPPOP_ERROR_CODE_TYPES = [
  "SHIPPOP_PURCHASE_ORDER_SUMMARY:INVALID_SIZE",
  "SHIPPOP_PURCHASE_ORDER_SUMMARY:INVALID_WEIGHT",
];

export const MAP_SHIPPOP_ERRORS: Record<string, SHIPPOP_ERROR_CODE> = {
  [SHIPPOP_ERROR_CODE_TYPES[0]]: SHIPPOP_ERROR_CODE.INVALID_SIZE,
  [SHIPPOP_ERROR_CODE_TYPES[1]]: SHIPPOP_ERROR_CODE.INVALID_WEIGHT,
};
