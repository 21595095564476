import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { CONSOLE_GENERATED_FORM } from "constants/i18n";
import Grid from "components/Grid";
import Typography from "components/Typography";

export type VariationsType = {
  key: string;
  value: string[];
};

type ProductDetailPropsType = {
  variations: VariationsType[];
  price: string | number;
};

const ProductDetail: FC<ProductDetailPropsType> = ({ variations, price }) => {
  const { t } = useTranslation(CONSOLE_GENERATED_FORM);

  return (
    <Grid container>
      {Boolean(variations.length) && (
        <Grid item className="pr-3">
          {variations.map((property) => (
            <Typography key={property.key} variant="body3">{`${property.key}: ${property.value.join(",")}`}</Typography>
          ))}
        </Grid>
      )}

      <Grid item>
        <Typography variant="body3">
          {t("price")} : {price}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProductDetail;
