import { useLazyQuery, ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

import { CHANGE_PACKAGE_SUMMARY } from "graphql/plan/query";
import { PlanCardPropsType, ChangePackageSummary } from "types/PlanUsage";
import { notifyError } from "utils/notify";

type UseChangePackageSummaryType = {
  projectId: string;
  onErrorNoBilling: () => void;
  onCompleted: () => void;
};

type ChangePackageSummaryQuery = {
  changePackageSummary: ChangePackageSummary;
};

const useChangePackageSummary = ({ projectId, onErrorNoBilling, onCompleted }: UseChangePackageSummaryType) => {
  const { t } = useTranslation();

  const [getChangePackageSummary, { data, loading }] = useLazyQuery<ChangePackageSummaryQuery>(CHANGE_PACKAGE_SUMMARY, {
    onError: (error: ApolloError) => {
      const gqlErrorMessage = get(error, "graphQLErrors.0.message");
      if (gqlErrorMessage === "BILLING:BILLING_INFO_IS_REQUIRED") {
        onErrorNoBilling();
      } else {
        notifyError(t("Please try again later"));
      }
    },
    onCompleted,
    fetchPolicy: "network-only",
  });

  const handleChangePackageSummary = (selectedPackages: PlanCardPropsType[]) => {
    const packageIds = selectedPackages.map(({ id }: PlanCardPropsType) => id);
    if (Array.isArray(packageIds) && packageIds.length > 0) {
      getChangePackageSummary({
        variables: {
          projectId,
          packageIds,
        },
      });
    }
  };

  return {
    handleChangePackageSummary,
    changePackageSummaryData: data?.changePackageSummary,
    loading,
  };
};

export default useChangePackageSummary;
