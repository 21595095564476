const synonym = {
  en: {
    "Product brand": "Product brand",
    "Product category": "Product category",
    "Product property": "Product property",
    "Add new key": "Add new key",
    Synonym: "Synonym",
  },
  th: {
    "Product brand": "ยี่ห้อสินค้า",
    "Product category": "เมนูสินค้า",
    "Product property": "คุณสมบัติของสินค้า",
    "Add new key": "เพิ่มคำ",
    Synonym: "ชื่อย่อ ชื่อเล่น",
  },
};

export default synonym;
