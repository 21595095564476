import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import { VAT } from "graphql/vat/query";
import { VAT_OPTIONS_TYPE, VatQuery, Language } from "types/Vat";
import { ProjectIdType } from "types/Project";

type UseVatPropsType = {
  projectId: string;
  setFormValue: (
    name: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    config?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
        }>
      | undefined,
  ) => void;
};

const useVat = (props: UseVatPropsType) => {
  const { projectId, setFormValue } = props;

  const [isFirstTimeGetQueryData, setIsFirstTimeGetQueryData] = useState(false);
  const [isShowSaveButton, setIsShowSaveButton] = useState(false);
  const [isShowTaxInfo, setIsShowTaxInfo] = useState<boolean>();
  const [isShippingIncluded, setIsShippingIncluded] = useState<boolean>();
  const [selectedOption, setSelectedOption] = useState<VAT_OPTIONS_TYPE>();
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(Language.TH);

  const { data } = useQuery<VatQuery, ProjectIdType>(VAT, {
    variables: {
      projectId,
    },
  });

  const vatOption = selectedOption || data?.vat?.option;
  const isShowTaxInfoValue = isShowTaxInfo === undefined ? data?.vat?.isShowTaxInfo : isShowTaxInfo;
  const isShippingIncludedValue = isShippingIncluded === undefined ? data?.vat?.isShippingIncluded : isShippingIncluded;

  const languageData = data?.vat?.lang;

  if (!isFirstTimeGetQueryData && data?.vat) {
    setFormValue("isShowTaxInfo", isShowTaxInfoValue);
    setFormValue("option", vatOption);
    setIsShowTaxInfo(isShowTaxInfoValue);
    setIsFirstTimeGetQueryData(true);
  }

  const showSaveButton = () => {
    setIsShowSaveButton(true);
  };

  const handleSelectVatOption = (option: VAT_OPTIONS_TYPE) => {
    setFormValue("option", option);
    setSelectedOption(option);
    showSaveButton();
  };

  const handleToggleIsShowTaxInfo = () => {
    setFormValue("isShowTaxInfo", !isShowTaxInfoValue);
    setIsShowTaxInfo(!isShowTaxInfoValue);
    showSaveButton();
  };

  const handleToggleIsShippingIncluded = () => {
    setIsShippingIncluded(!isShippingIncluded);
    showSaveButton();
  };

  const clearValue = () => {
    setSelectedOption(undefined);
    setIsShowTaxInfo(undefined);
    setIsShippingIncluded(undefined);
    setIsShowSaveButton(false);
  };

  const handleSelectLanguage = (language: Language) => {
    setFormValue("lang", language);
    setSelectedLanguage(language);
    showSaveButton();
  };

  useEffect(() => {
    if (isShippingIncluded === undefined) {
      setIsShippingIncluded(isShippingIncludedValue);
    }

    if (languageData) {
      setSelectedLanguage(languageData);
    }
  }, [isShippingIncluded, isShippingIncludedValue, languageData]);

  return {
    vat: data?.vat,
    vatOption,
    isShowTaxInfo: Boolean(isShowTaxInfoValue),
    isShippingIncluded: Boolean(isShippingIncludedValue),
    isShowSaveButton,
    selectedLanguage,
    handleSelectVatOption,
    handleToggleIsShowTaxInfo,
    handleToggleIsShippingIncluded,
    handleSelectLanguage,
    clearValue,
    showSaveButton,
  };
};

export default useVat;
