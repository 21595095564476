const scrollToElementId = (elementId: string) => {
  const element = document.getElementById(elementId);

  if (element) {
    element.scrollIntoView();
  }

  return element;
};

export default scrollToElementId;
