import * as React from "react";

const SvgIcOrders = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.182 18.748h-8.08a1.456 1.456 0 010-2.91h8.08a1.455 1.455 0 110 2.91m0 5.48h-8.08a1.456 1.456 0 010-2.91h8.08a1.455 1.455 0 110 2.91M8.754 5.826c.122 3.102 2.666 5.594 5.798 5.594h3.176c3.132 0 5.676-2.492 5.8-5.594h1.71a2.911 2.911 0 012.91 2.91v15.328a5.821 5.821 0 01-5.821 5.82H9.91a5.821 5.821 0 01-5.821-5.82V8.735a2.91 2.91 0 012.91-2.91zm10.43-4.047a2.914 2.914 0 012.911 2.91v.91c0 .077-.019.151-.023.227-.122 2.299-2.014 4.138-4.342 4.138h-3.178c-2.329 0-4.22-1.84-4.343-4.138-.004-.076-.023-.15-.023-.227v-.91a2.914 2.914 0 012.91-2.91zm0 2.91h-6.09l.002.91c0 .08.034.151.047.227.113.69.687 1.228 1.409 1.228h3.178c.721 0 1.295-.537 1.408-1.228.013-.076.047-.147.047-.227v-.91z"
    />
  </svg>
);

export default SvgIcOrders;
