const topSellingProduct = {
  en: {
    "marketingTools.title": "Marketing tools",
    "topSellingProduct.header": "Top selling products",
    "topSellingProduct.add.title": "Add top selling product",
    "topSellingProduct.config.list.title": "Selected top selling",
    "topSellingProduct.timeSpan.title": "Top selling time span",
    "topSellingProduct.fromOrder.list.title": "Top selling product from actual orders",
  },
  th: {
    "marketingTools.title": "เครื่องมือการตลาด",
    "topSellingProduct.header": "สินค้าขายดี",
    "topSellingProduct.add.title": "เพิ่มสินค้าขายดีของคุณ",
    "topSellingProduct.config.list.title": "สินค้าที่ถูกเลือก",
    "topSellingProduct.timeSpan.title": "ระยะเวลา",
    "topSellingProduct.fromOrder.list.title": "สินค้าขายดีตามยอดสั่งซื้อจริง",
  },
};

export default topSellingProduct;
