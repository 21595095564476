import styled from "styled-components";
import ButtonBase from "components/ButtonBase";

export const StyledTag = styled.div`
  display: flex;
  border: 0.5px solid ${({ theme }) => theme.COLORS.LightBlue};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.COLORS.Light};
  color: ${({ theme }) => theme.COLORS.DarkGray};
  margin-right: 6px;
  margin-bottom: 6px;
  align-items: center;
`;

export const DeleteTagButton = styled(ButtonBase)`
  display: block;
  height: 100%;
  border-left: 0.5px solid ${({ theme }) => theme.COLORS.LightBlue};
  padding: 4px;

  svg {
    vertical-align: middle;
  }
`;

export const Label = styled.div`
  max-width: 120px;
`;
