import React, { FC, memo } from "react";
import { EmptyMessage } from "./styled";
import { stringify } from "./helper";

type DisplayMessagePropsType = { message: string | Record<string, string | object> };

const DisplayMessage: FC<DisplayMessagePropsType> = ({ message }) => {
  if (typeof message === "object") {
    return <p className="m-0">{stringify(message)}</p>;
  }

  if (typeof message === "string") {
    return (
      <>
        {stringify(message)
          .split("\\n")
          .map((msg, index) => {
            if (msg) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <p className="m-0" key={index}>
                  {msg}
                </p>
              );
            }
            // eslint-disable-next-line react/no-array-index-key
            return <EmptyMessage key={index} />;
          })}
      </>
    );
  }
  return <p className="m-0" />;
};

export default memo(DisplayMessage);
