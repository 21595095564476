import React from "react";
import styled from "styled-components";
import { VARIANTS } from "constants/Typography";
import DeepleTypography from "components/Typography";

export const Wrapper = styled.div`
  font-size: ${VARIANTS.body4.size};
  color: ${({ theme }) => theme.COLORS.DarkGray};

  .page-no {
    list-style-type: none;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
    background-color: white;
    margin: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      cursor: pointer;
    }
    a {
      padding: 5px 10px;
      :focus {
        outline: none;
      }
    }
  }

  .active {
    color: white !important;
    background-color: ${({ theme }) => theme.COLORS.Primary};
  }

  .break {
    list-style-type: none;
    padding: 5px 10px;
  }

  .previous,
  .next {
    border: 1px solid #e6e6e6;
  }
`;

export const Typography = styled(({ fontWeight: _fontWeight, ...rest }) => <DeepleTypography {...rest} />).attrs(
  ({ fontWeight, variant }) => {
    return { fontWeight, variant };
  },
)`
  ${({ variant }) => (variant ? "" : "font-size: inherit;")};
  font-weight: ${({ fontWeight }) => fontWeight || "inherit"};
`;
