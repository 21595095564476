import { useMutation } from "@apollo/client";
import { DELETE_COMMENT_CRAWLER } from "graphql/facebookCrawler/mutation";
import { toast } from "react-toastify";

export const useDeleteFacebookCrawler = (t: Function, setIsOpenConfirmDeleteModal: Function, closeFrom: Function) => {
  const [deleteCommentCrawler, { loading: deleteCommentCrawlerLoading }] = useMutation(DELETE_COMMENT_CRAWLER, {
    onError: () => {
      toast.error(`${t("facebookAutoEngagement.toast.fail")}`, {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 2000,
      });
      setIsOpenConfirmDeleteModal(false);
    },
    onCompleted: () => {
      toast.success(t("facebookAutoEngagement.toast.complete"), {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 3000,
      });
      setIsOpenConfirmDeleteModal(false);
      closeFrom(false); // Note! this effect to setIsOpenBroadcastForm(false)
    },
  });
  return { deleteCommentCrawler, deleteCommentCrawlerLoading };
};
