import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  paper: {
    flex: 1,
    height: "100%",
  },
  paperWidthSm: {
    maxWidth: "800px",
  },
}));

export default useStyles;
