import { MessageType } from "types/Chat";

export enum UploadDirectoryType {
  PROJECT_CERTIFICATION = "PROJECT_CERTIFICATION",
  PROJECT_CHAT = "PROJECT_CHAT",
  PROJECT_MENU = "PROJECT_MENU",
  PROJECT_PUBLIC = "PROJECT_PUBLIC",
  PROJECT_RECEIPT = "PROJECT_RECEIPT",
  PROJECT_VIDEO = "PROJECT_VIDEO",
  PROJECT_VIDEO_THUMBNAIL = "PROJECT_VIDEO_THUMBNAIL",
  USER = "USER",
}

export type ImageFile = {
  filename: string;
  contentMd5: string;
  contentType: string;
  sizeInByte: number;
};

export type RequestHeader = {
  key: string;
  value: string;
};

export type SignedUrl = {
  signedUrl: string;
  publicUrl: string;
  requestHeaders: RequestHeader[];
};

export type ImageUrl = {
  publicUrl: string;
  filePath: string;
  thumbnailUrl?: string;
  type?: MessageType;
};
