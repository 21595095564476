import * as yup from "yup";

const schema = yup.object().shape({
  isActive: yup.boolean(),
  timeInAdvance: yup.number().min(0),
  start: yup.string(),
  end: yup.string(),
});

export default schema;
