import * as React from "react";

const SvgIcCopy = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.398 22.556H23.98a1.97 1.97 0 001.969-1.97V7.97A1.97 1.97 0 0023.979 6h-11.58a1.97 1.97 0 00-1.97 1.97v12.617a1.977 1.977 0 001.97 1.97zm.005-1.377a.592.592 0 01-.592-.592V7.974c0-.326.266-.591.592-.591h11.582c.326 0 .592.265.592.591v12.613a.592.592 0 01-.592.592H12.403zM7.97 26.913A1.97 1.97 0 016 24.943V12.328a1.97 1.97 0 011.97-1.97c.382 0 .688.306.688.689a.686.686 0 01-.689.689.592.592 0 00-.591.592v12.617c0 .326.265.592.591.592h11.582a.592.592 0 00.592-.592c0-.383.306-.689.689-.689.382 0 .688.306.688.689a1.97 1.97 0 01-1.969 1.97z"
    />
  </svg>
);

export default SvgIcCopy;
