import * as React from "react";

const SvgIcAiFaq = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.007 1.778c6.652 0 12.234 4.578 13.767 10.755h1.24a1 1 0 011 1v4.857a1 1 0 01-1 1h-1.24c-1.533 6.177-7.115 10.755-13.767 10.755-6.651 0-12.233-4.578-13.766-10.756H1a1 1 0 01-1-1v-4.856a1 1 0 011-1h1.24C3.775 6.356 9.357 1.778 16.008 1.778zm0 5.447a8.957 8.957 0 00-8.956 8.956c0 3.808 2.135 6.923 5.485 8.22.343.128-.993 3.53-.889 3.738 3.953-1.82 6.833-3.264 7.926-3.739a8.957 8.957 0 00-3.565-17.176zm-.26 14.647c.385 0 .717.14.995.418.278.278.418.61.418.996 0 .385-.14.713-.418.985-.278.271-.61.407-.996.407-.385 0-.714-.136-.985-.407a1.342 1.342 0 01-.407-.985c0-.386.136-.718.407-.996.271-.278.6-.418.985-.418zm.363-12.805c.743 0 1.406.121 1.992.364a4.493 4.493 0 011.477.963c.4.4.703.86.91 1.382a4.28 4.28 0 01.311 1.595c0 .856-.207 1.588-.621 2.195a5.604 5.604 0 01-1.563 1.531l-.985.664a1.983 1.983 0 00-.718.888c-.15.365-.225.754-.225 1.168v.16c0 .05.008.132.022.246h-1.885a3.304 3.304 0 01-.021-.364v-.385c0-.714.136-1.342.407-1.885.271-.542.728-1.027 1.37-1.456l.943-.642c.37-.243.653-.55.845-.92a2.52 2.52 0 00.29-1.179c0-.314-.054-.617-.161-.91a2.21 2.21 0 00-1.263-1.328 2.873 2.873 0 00-1.125-.203c-.47 0-.874.079-1.21.236a2.35 2.35 0 00-.824.62c-.214.258-.375.55-.482.879-.107.328-.16.663-.16 1.006 0 .186.014.378.042.578.029.2.065.357.107.471l-2.077-.257a3.748 3.748 0 01-.128-1.027c0-.486.093-.99.278-1.51.186-.521.471-.996.857-1.424a4.411 4.411 0 011.467-1.05c.592-.27 1.302-.406 2.13-.406z"
    />
  </svg>
);

export default SvgIcAiFaq;
