import * as React from "react";

const SvgIcPayment = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.515 21.707a5.146 5.146 0 110 10.293 5.147 5.147 0 010-10.293zm.114 1.629h-.285c-.208.006-.242.04-.249.25v.282c0 .274-.006.267-.268.364-.642.237-1.041.67-1.082 1.37-.038.622.282 1.042.794 1.344.308.188.655.299.988.443.132.054.248.12.36.21.322.264.263.7-.117.865a1.18 1.18 0 01-.634.085 2.82 2.82 0 01-.971-.288c-.183-.093-.235-.073-.294.124a8.694 8.694 0 00-.144.505c-.066.23-.04.282.176.385.275.132.571.203.872.25.235.039.248.052.248.295v.335c0 .136.066.221.21.221.163.007.327.007.492 0 .13 0 .201-.072.201-.208 0-.158.007-.309 0-.46-.006-.158.06-.235.21-.275.341-.093.637-.274.858-.55.623-.752.38-1.858-.485-2.339a7.37 7.37 0 00-.863-.392 2.064 2.064 0 01-.472-.267c-.282-.224-.223-.592.104-.736a.894.894 0 01.29-.059c.379-.02.738.047 1.086.217.17.079.23.052.288-.124.066-.183.118-.38.17-.57.04-.13-.007-.216-.131-.268a2.78 2.78 0 00-.687-.203c-.314-.047-.314-.047-.314-.36v-.095c-.002-.351-.034-.351-.446-.351zm9.808-13.307c1.106 0 2.003.897 2.003 2.005v14.012a2.006 2.006 0 01-2.003 2.007H26.77c.066-.395.1-.794.1-1.2 0-.46-.048-.902-.127-1.342H28.9v-6.707H10.13v6.713h2.148a7.311 7.311 0 00-.025 2.542H9.585c-1.107 0-2.003-.9-2.003-2.006v-14.02c0-1.107.896-2.004 2.003-2.004zM6.35 1.174A1.979 1.979 0 018.964.172L25.33 7.429c.42.19.289.818-.176.818v.006H9.593a3.788 3.788 0 00-3.786 3.787v5.456c0 .45-.472.752-.883.569l-3.301-1.43A1.976 1.976 0 01.62 14.024zM28.9 12.57H10.13v2.103H28.9V12.57z"
    />
  </svg>
);

export default SvgIcPayment;
