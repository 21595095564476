import { gql } from "@apollo/client";

export { NEW_OFFLINE_ORDER } from "./newOfflineOrder";
export { ADD_ORDER_SECONDARY_PAYSLIP, DELETE_ORDER_SECONDARY_PAYSLIP } from "./orderSecondaryPayslipImage";
export { UPDATE_ORDER } from "./updateOrder";
export { UPDATE_ADMIN_NOTE } from "./updateAdminNote";
export { UPDATE_ORDER_ADDRESS } from "./updateOrderAddress";
export {
  APPROVE_COD_ORDER,
  REJECT_COD_ORDER,
  SHIPPED_COD_ORDER,
  UPDATE_COD_ORDER_DEPOSIT_BILL_IMAGE,
} from "./codOrder";
export { VOID_ORDER } from "./voidOrder";
export { REFUND_ORDER } from "./refundOrder";

export const CANCEL_LALAMOVE = gql`
  mutation cancelLalamoveOrder($projectId: ID!, $orderId: ID!) {
    cancelLalamoveOrder(projectId: $projectId, orderId: $orderId)
  }
`;

export const REJECT_ORDER = gql`
  mutation rejectOrder($projectId: ID!, $orderId: ID!) {
    rejectOrder(projectId: $projectId, orderId: $orderId) {
      id
      orderNumber
      state
      paymentBillImageSrc
      depositBillImageSrc
      updatedAt
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation cancelOrder($projectId: ID!, $orderId: ID!, $cancelReason: String) {
    cancelOrder(projectId: $projectId, orderId: $orderId, cancelReason: $cancelReason) {
      id
      orderNumber
      state
      updatedAt
    }
  }
`;

export const APPROVE_ORDER = gql`
  mutation approveOrder($projectId: ID!, $orderId: ID!, $approveData: ApproveOrderInput) {
    approveOrder(projectId: $projectId, orderId: $orderId, approveData: $approveData) {
      id
      orderNumber
      state
      paymentAmount
      paymentBankName
      paymentAt
      paymentMethodType
      depositAmount
      depositBillImageSrc
      depositBankName
      depositedAt
      updatedAt
    }
  }
`;

export const SHIPPED_ORDER = gql`
  mutation shippedOrder($projectId: ID!, $orderId: ID!, $shippedData: ShippedOrderInput) {
    shippedOrder(projectId: $projectId, orderId: $orderId, shippedData: $shippedData) {
      id
      orderNumber
      state
      shippedDetail {
        detail {
          carrierName
          deliveryDate
          trackingNumber
          otherCarrierName
          note
        }
      }
      updatedAt
    }
  }
`;

export const UPDATE_DELIVERY_SCHEDULE = gql`
  mutation updateDeliverySchedule($projectId: ID!, $orderId: ID!, $deliverySchedule: String) {
    updateDeliverySchedule(projectId: $projectId, orderId: $orderId, deliverySchedule: $deliverySchedule) {
      id
      orderNumber
      state
      deliverySchedule
      updatedAt
    }
  }
`;

export const UPDATE_SHIPPING_PRICE = gql`
  mutation updateShippingPrice($projectId: ID!, $orderId: ID!, $updateShippingPrice: updateShippingPriceInput) {
    updateShippingPrice(projectId: $projectId, orderId: $orderId, updateShippingPrice: $updateShippingPrice) {
      id
      orderNumber
      state
      shippingPrice
      shippingPriceNote
      shippingMethod
      grandTotal
      note
      updatedAt
    }
  }
`;

export const UPDATE_ORDER_PAYMENT_BILL_IMAGE = gql`
  mutation updateOrderPaymentBillImage($projectId: ID!, $orderId: ID!, $imagePath: String!) {
    updateOrderPaymentBillImage(projectId: $projectId, orderId: $orderId, imagePath: $imagePath) {
      id
      orderNumber
      state
      paymentBillImageSrc
      updatedAt
    }
  }
`;

export const PLACE_ORDER_TO_LALAMOVE = gql`
  mutation placeOrderToLalamove($projectId: ID!, $orderId: ID!, $deliverySchedule: String!) {
    placeOrderToLalamove(projectId: $projectId, orderId: $orderId, deliverySchedule: $deliverySchedule) {
      id
      projectId
      orderId
      referenceId
      status
      assigningDriverAt
      onGoingAt
      cancelledAt
      pickedUpAt
      rejectedAt
      completedAt
      expiredAt
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_DIMENSION_WEIGHT_AND_CARRIER = gql`
  mutation updateDimensionWeightAndCarrierName(
    $projectId: ID!
    $orderId: ID!
    $dimension: [Float!]!
    $weight: Float!
    $carrierName: String
  ) {
    updateDimensionWeightAndCarrierName(
      projectId: $projectId
      orderId: $orderId
      dimension: $dimension
      weight: $weight
      carrierName: $carrierName
    ) {
      id
      orderNumber
      state
      largestDimension
      totalWeight
      updatedAt
    }
  }
`;
