import { ReactNode } from "react";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import { NotificationType as TYPE, NotificationName, NotificationConfigurationType } from "types/Notification";

import {
  IcAbandoned,
  IcAddress,
  IcAiFaq,
  IcAiInactive,
  IcAwaitingApproval,
  IcAwaitingShipping,
  IcCheckout,
  IcCustomerMessage,
  IcDeliveryOption,
  IcDeliveryPeriod,
  IcDoingPayment,
  IcEnglishChat,
  IcHelpRequest,
  IcLalamoveExpired,
  IcLalamoveRejected,
  IcLowProductNumber,
  IcNewCustomer,
  IcOrders,
  IcOutOfStock,
  IcShipping,
  IcSlipWarning,
  IcTax,
  IcWebview,
} from "components/SvgIcons";

type NotificationConfigList = {
  type: TYPE;
  notification: NotificationConfigurationType[];
};

export const groupNotificationConfig = (notificationConfigs: NotificationConfigurationType[]) => {
  if (notificationConfigs.length === 0) {
    return [];
  }

  const { CHAT, CHECKOUT, ORDER } = groupBy(notificationConfigs, "type");
  //    INVENTORY

  // Hide notification config Checkout on webview for now
  const checkoutNotificationSettingWithoutCheckoutWebview = CHECKOUT.filter(
    ({ name }) => name !== NotificationName.CHECKOUT_ON_WEBVIEW,
  );

  const notificationConfigList: NotificationConfigList[] = [
    {
      type: TYPE.CHECKOUT,
      notification: sortBy(checkoutNotificationSettingWithoutCheckoutWebview, "priority"),
    },
    {
      type: TYPE.ORDER,
      notification: sortBy(ORDER, "priority"),
    },
    {
      type: TYPE.CHAT,
      notification: sortBy(CHAT, "priority"),
    },
    // TODO: hide inventory notification for now
    // {
    //   type: TYPE.INVENTORY,
    //   notification: sortBy(INVENTORY, "priority"),
    // },
  ];

  return notificationConfigList;
};

export const mappedIconWithNotificationName: {
  [key in NotificationName]: ReactNode;
} = {
  // Customer checkout
  PROCEEDING_TO_CHECKOUT: IcCheckout,
  DELIVERY_METHOD_SELECTION: IcDeliveryOption,
  FILLING_ADDRESS: IcAddress,
  SHIPPING_METHOD_SELECTION: IcShipping,
  DELIVERY_PERIOD_SELECTION: IcDeliveryPeriod,
  CHECKOUT_ON_WEBVIEW: IcWebview,
  // Order state
  PENDING_FOR_SHIPPING_COST: IcDoingPayment,
  NEW_ORDER: IcOrders,
  AWAITING_PAYMENT_APPROVAL: IcAwaitingApproval,
  ABANDONED_ORDER: IcAbandoned,
  COD_NEW_ORDER: IcOrders,
  COD_AWAITING_PAYMENT_APPROVAL: IcAwaitingApproval,
  COD_READY_TO_SHIP: IcAwaitingShipping,
  ORDER_PAID_WITH_DEEPLE_PAY: IcAwaitingApproval,
  // Chat
  NEW_CUSTOMER: IcNewCustomer,
  POTENTIAL_CUSTOMER: IcNewCustomer,
  SPECIAL_KNOWLEDGE: IcAiFaq,
  BOT_CANNOT_RESPOND: IcAiInactive,
  ADMIN_CONTACTING_REQUEST: IcHelpRequest,
  ADMIN_ENGLISH_SPEAKER_REQUEST: IcEnglishChat,
  DIRECT_MESSAGE: IcCustomerMessage,
  FULL_TAX_INVOICE: IcTax,
  BANK_SLIP_WITHOUT_ORDER: IcSlipWarning,
  // Inventory
  LOW_PRODUCT_QUANTITY: IcLowProductNumber,
  OUT_OF_STOCK: IcOutOfStock,
  // Lalamove
  LALAMOVE_SHIPMENT_REJECTED_STATUS: IcLalamoveRejected,
  LALAMOVE_SHIPMENT_EXPIRED_STATUS: IcLalamoveExpired,
};
