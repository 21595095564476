import styled from "styled-components";

import TextField from "@material-ui/core/TextField";

export const TextInput = styled(TextField)`
  input,
  textarea {
    padding: 10px;
    box-sizing: border-box;
    &:focus {
      border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      border-radius: 30px;
    }
  }

  .MuiInput-underline:before,
  .MuiInput-underline:hover:not(.Mui-disabled):before,
  .Mui-focused:after {
    border-bottom: 0;
  }
`;
