import * as firebase from "firebase/app";
import "firebase/messaging";

import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_PUBLIC_VAPID_KEY,
} from "config";

let messagingClient: firebase.messaging.Messaging;

export function getMessagingClient() {
  if (
    FIREBASE_API_KEY &&
    FIREBASE_APP_ID &&
    FIREBASE_MESSAGING_SENDER_ID &&
    FIREBASE_PROJECT_ID &&
    FIREBASE_PUBLIC_VAPID_KEY
  ) {
    if (firebase.messaging.isSupported()) {
      if (!messagingClient) {
        firebase.initializeApp({
          apiKey: FIREBASE_API_KEY,
          projectId: FIREBASE_PROJECT_ID,
          messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
          appId: FIREBASE_APP_ID,
        });

        messagingClient = firebase.messaging();
        messagingClient.usePublicVapidKey(FIREBASE_PUBLIC_VAPID_KEY);
      }
    }

    return messagingClient;
  }

  return null;
}
