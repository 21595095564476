import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Button from "components/Button";
import Grid from "components/Grid";
import Typography from "components/Typography";

type InfoItemPropsType = {
  title: string;
  description: string;
  manageLink: string;
};

const InfoItem: FC<InfoItemPropsType> = (props) => {
  const { t } = useTranslation();
  const { title, description, manageLink } = props;

  return (
    <Grid container alignItems="center" className="py-3">
      <Grid item container xs={9}>
        <Grid item xs={12}>
          <Typography variant="title7" color="dark" className="ellipsis">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12} className="pt-1">
          <Typography variant="body2" color="darkGray" className="ellipsis">
            {description}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Link to={manageLink}>
          <Button color="ghost" size="small" fullWidth>
            {t("planUsage.button.manage")}
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export default InfoItem;
