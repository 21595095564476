import { gql } from "@apollo/client";

export { SEARCH_OFFLINE_CUSTOMER } from "./searchOfflineCustomers";
export { SUGGEST_CUSTOMER_TAGS } from "./suggestCustomerTags";

export const CUSTOMER_ADDRESS = gql`
  query customerAddress($projectId: ID!, $customerId: ID!) {
    customerAddress(projectId: $projectId, customerId: $customerId) {
      firstName
      lastName
      streetAddress
      streetAddress2
      district
      subDistrict
      province
      postalCode
      phoneNumber
      email
    }
  }
`;

export const CUSTOMER_NOTE = gql`
  query customer($projectId: ID!, $customerId: ID!, $page: Int, $pageSize: Int) {
    customer(projectId: $projectId, customerId: $customerId) {
      id
      projectId
      notes(page: $page, pageSize: $pageSize) {
        results {
          createdAt
          id
          note
          agent {
            avatar
            email
            displayName
            isDeactivated
          }
        }
        total
      }
    }
  }
`;

export const CUSTOMER = gql`
  query customer($projectId: ID!, $customerId: ID!, $page: Int, $pageSize: Int) {
    customer(projectId: $projectId, customerId: $customerId) {
      id
      uuid
      projectId
      platform
      name
      isHumanTakingOver
      isDisconnected
      pictureUrl
      unSeenMessageAfterTakingOver
      status
      # lastMessage: MessageLog
      isNewClient
      customerRefId
      address {
        firstName
        lastName
        streetAddress
        streetAddress2
        district
        subDistrict
        province
        postalCode
        phoneNumber
        email
      }
      orders(page: $page, pageSize: $pageSize) {
        results {
          id
          orderNumber
          state
          grandTotal
        }
        total
      }
      totalSpending
      firstSeen
      lastSeen
      displayName
    }
  }
`;

export const CUSTOMERS = gql`
  query customers($projectId: ID!, $offset: Int, $limit: Int, $filter: CustomerFilterInput) {
    customers(projectId: $projectId, limit: $limit, offset: $offset, filter: $filter) {
      results {
        id
        uuid
        projectId
        platform
        name
        displayName
        isHumanTakingOver
        isDisconnected
        pictureUrl
        firstSeen
        unSeenMessageAfterTakingOver
        status
        totalSpending
        isNewClient
        lastMessagedAt
        customerRefId
        address {
          firstName
          lastName
          streetAddress
          streetAddress2
          district
          subDistrict
          province
          postalCode
          phoneNumber
          email
        }
        tags(page: 0, pageSize: 50) {
          results {
            id
            projectId
            customerId
            tag
          }
          total
        }
        systemTags {
          id
          projectId
          customerId
          tag
          type
          metadata {
            broadcastId
            orderId
          }
        }
      }
      total
    }
  }
`;

export const CUSTOMER_TAG = gql`
  query customer($projectId: ID!, $customerId: ID!, $page: Int, $pageSize: Int) {
    customer(projectId: $projectId, customerId: $customerId) {
      id
      projectId
      tags(page: $page, pageSize: $pageSize) {
        results {
          id
          projectId
          customerId
          tag
        }
        total
      }
    }
  }
`;
