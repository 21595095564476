import { IcBilling, IcCOD, IcInfo, IcTransaction } from "components/SvgIcons";
import { TRANSACTION, PAYMENT_CHANNEL, COD, DEEPLE_PAY_FEE_INVOICE } from "constants/Router";

export const TAB_CONFIGS = [
  {
    id: PAYMENT_CHANNEL,
    title: "deeplePay.header.paymentChannel",
    svgIcon: IcInfo,
  },
  {
    id: TRANSACTION,
    title: "deeplePay.header.transaction",
    svgIcon: IcTransaction,
  },
  {
    id: COD,
    title: "deeplePay.header.cod",
    svgIcon: IcCOD,
  },
  {
    id: DEEPLE_PAY_FEE_INVOICE,
    title: "deeplePay.header.invoice",
    svgIcon: IcBilling,
  },
];
