import * as React from "react";

const SvgIcOptions = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 26a3 3 0 110 6 3 3 0 010-6zm0-13a3 3 0 110 6 3 3 0 010-6zm0-13a3 3 0 110 6 3 3 0 010-6z"
    />
  </svg>
);

export default SvgIcOptions;
