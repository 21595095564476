import React, { FC, useState, MouseEvent } from "react";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { OptionType, ValueType } from "react-select";

import { Menu, MenuItem } from "components/Menu";
import Grid from "components/Grid";
import Typography from "components/Typography";
import Card from "components/Card";
import CircularProgress from "components/CircularProgress";

import PageTitle from "components/PageTitle";

import { ORDERS_QUERY_INTERVAL } from "constants/Order";
import { DASHBOARD_FILTER, TIME_FIELD } from "constants/Stat";

import { LATEST_ORDER_LIMIT } from "config/dashboard";
import { ORDERS } from "graphql/order/query";

import { PLATFORM } from "types/Customer";
import { ProductItemPropsType } from "types/Product";
import { ProjectIdType } from "types/Project";
import { OrderType, OrderConnectionQueryType, OrderConnectionVariablesType } from "types/Order";
import { DashboardFilterType, StatQueryType } from "types/Stat";

import useGetProject from "utils/hooks/useGetProject";
import BlankItem from "./BlankItem";
import FilterButton from "./FilterButton";
import OrderListItem from "./OrderListItem";
import GroupStatWidget from "./GroupStatWidget";
import TopSellingProductListItem from "./topSellingProductListItem";
import useStatData from "./hooks/useStatData";
import GraphStat from "./GraphStat";
import Calendar from "./CalendarPicker";
import { CustomControl, CustomOption } from "./CustomReactSelect";
import { PlatformFilterButton, StyledSelect } from "./index.styled";

const DashboardContainer: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const { isOfflineOrderEnabled } = useGetProject(projectId);

  const [anchorExport, setAnchorExport] = useState<null | HTMLElement>(null);

  const [platformFilterLabel, setPlatformFilterLabel] = useState(t("platformLabelAll") as string);
  const [platformFilter, setPlatformFilter] = useState({});
  const [periodOption, setPeriodOption] = useState<ValueType<OptionType>>({
    value: TIME_FIELD.ORDER_CREATED_AT,
    label: t("graphStat.legend.label.orderCreatedTime"),
  });
  const periodOptions: ValueType<OptionType> = [
    { label: t("graphStat.legend.label.orderCreatedTime"), value: TIME_FIELD.ORDER_CREATED_AT },
    { label: t("graphStat.legend.label.orderPaidTime"), value: TIME_FIELD.ORDER_PAID_AT },
  ];

  const handleClickPlatformFilter = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorExport(event.currentTarget);
  };

  const handleClosePlatformFilter = () => {
    setAnchorExport(null);
  };

  const handlePlatformFilterChange = (label: string, value: Record<string, string | string[]>) => {
    setPlatformFilter(value);
    setPlatformFilterLabel(label);
    handleClosePlatformFilter();
  };

  const { data: latestOrders } = useQuery<OrderConnectionQueryType, OrderConnectionVariablesType>(ORDERS, {
    variables: {
      projectId,
      limit: LATEST_ORDER_LIMIT,
    },
    pollInterval: ORDERS_QUERY_INTERVAL,
  });

  const {
    statData,
    dataTopSelling,
    dataTopSellingWithPeriod,
    isLoadingWidget,
    isLoadingStat,
    selectedDuration,
    handleChangeDateFilter,
    handleClickApplyCalendar,
    startTime,
    endTime,
  } = useStatData(projectId, platformFilter, get(periodOption, "value"));

  const topSellingProductsWithPeriod = get(dataTopSellingWithPeriod, "topSellingProductsFromOrderWithPeriod");
  const topSellingProducts = get(dataTopSelling, "topSellingProductsFromOrder");

  const handleSelectPeriodOption = (value: ValueType<OptionType>) => {
    setPeriodOption(value);
  };

  const formatOptionLabel = (option: OptionType) => {
    const { label } = option;
    return (
      <Typography variant="title8" color="darkGray">
        {label}
      </Typography>
    );
  };

  return (
    <Grid container alignItems="flex-start" alignContent="flex-start">
      <Grid container item justify="space-between">
        <Grid item xs={6}>
          <PageTitle title={t("dashboard.header")} />
        </Grid>
        {isOfflineOrderEnabled && (
          <Grid item container xs={6} justify="flex-end" alignContent="center" alignItems="center">
            <Grid
              container
              item
              justify="flex-end"
              alignContent="center"
              alignItems="center"
              style={{ padding: "1.5rem 1rem 1rem 1rem" }}
            >
              {t("platformLabelPlatform")} :
              <PlatformFilterButton className="p-2 border ml-2" onClick={handleClickPlatformFilter}>
                {platformFilterLabel}
              </PlatformFilterButton>
            </Grid>
            <Menu
              id="filter-platform-menu"
              anchorEl={anchorExport}
              keepMounted
              open={Boolean(anchorExport)}
              onClose={handleClosePlatformFilter}
            >
              <MenuItem
                onClick={() => {
                  handlePlatformFilterChange(t("platformLabelAll"), {});
                }}
              >
                {t("platformLabelAll")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handlePlatformFilterChange(t("platformLabelOnline"), {
                    platform: [PLATFORM.FACEBOOK, PLATFORM.INSTAGRAM, PLATFORM.LINE],
                  });
                }}
              >
                {t("platformLabelOnline")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handlePlatformFilterChange(t("platformLabelOffline"), { platform: [PLATFORM.OFFLINE] });
                }}
              >
                {t("platformLabelOffline")}
              </MenuItem>
            </Menu>
          </Grid>
        )}
      </Grid>

      {isLoadingStat && (
        <Grid container justify="center">
          <CircularProgress className="m-4" />
        </Grid>
      )}
      <Grid container className="px-3">
        <Grid item container justify="space-between" alignItems="center">
          <Grid item container sm={6} xs={12}>
            {DASHBOARD_FILTER.map(({ label, duration }: DashboardFilterType) => {
              return (
                <FilterButton
                  key={label}
                  label={label}
                  isSelected={selectedDuration === duration}
                  onClick={() => handleChangeDateFilter(duration)}
                />
              );
            })}
          </Grid>
          <Grid item container xs={12} sm={6} alignItems="center" justify="flex-end">
            <Grid item xs={8} sm={6} className="text-right">
              <Typography variant="title8" color="darkGray" className="pr-3">
                {t(`dashboard.dateTime.label.${selectedDuration}`, { startTime, endTime })}
              </Typography>
            </Grid>
            <Calendar startTime={startTime} endTime={endTime} onApply={handleClickApplyCalendar} />
            <Grid item xs={8} sm={4} className="mt-2 mt-sm-0">
              <StyledSelect
                value={periodOption}
                options={periodOptions}
                onChange={handleSelectPeriodOption}
                isSearchable={false}
                formatOptionLabel={formatOptionLabel}
                components={{ Control: CustomControl, Option: CustomOption }}
              />
            </Grid>
          </Grid>
        </Grid>
        <GroupStatWidget isLoading={isLoadingWidget} statData={statData as StatQueryType} />
        <Grid item xs={12} className="pt-4">
          <GraphStat
            projectId={projectId}
            duration={selectedDuration}
            startTime={startTime}
            endTime={endTime}
            platformFilter={platformFilter}
            periodOption={get(periodOption, "value")}
          />
        </Grid>
        <Grid item container spacing={2} className="pt-4">
          <Grid item xs={12} md={8}>
            <Card noShadow padding="0px">
              <Grid container>
                <Grid item xs={12} className="m-1 p-3">
                  <Typography variant="title8" color="darkMed">
                    {t("statWidget.title.latestOrder")}
                  </Typography>
                </Grid>
                {latestOrders && latestOrders.orders.total ? (
                  latestOrders.orders.results.map((order: OrderType) => {
                    const { id, orderNumber, paymentMethodType, grandTotal, state, customer } = order;

                    const customerName = customer?.displayName || customer?.name;

                    return (
                      <OrderListItem
                        key={id}
                        orderNumber={orderNumber}
                        isCODStatus={paymentMethodType === "CASH_ON_DELIVERY"}
                        grandTotal={grandTotal}
                        state={state}
                        customerName={customerName}
                        customerImgSrc={customer.pictureUrl}
                      />
                    );
                  })
                ) : (
                  <BlankItem />
                )}
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card noShadow padding="0px">
              <Grid container>
                <Grid item xs={12} className="m-1 p-3">
                  <Typography variant="title8" color="darkMed">
                    {t("statWidget.title.topSelling")}
                  </Typography>
                </Grid>

                {topSellingProductsWithPeriod ? (
                  <>
                    {topSellingProductsWithPeriod && topSellingProductsWithPeriod.length ? (
                      topSellingProductsWithPeriod.map((product: ProductItemPropsType, index: number) => {
                        const { name, images } = product;
                        const productImage = images && images.length ? images?.[0]?.src : "";

                        return (
                          <TopSellingProductListItem
                            key={name}
                            sequence={index + 1}
                            productImage={productImage}
                            name={name}
                          />
                        );
                      })
                    ) : (
                      <BlankItem />
                    )}
                  </>
                ) : (
                  <>
                    {topSellingProducts && topSellingProducts.length ? (
                      topSellingProducts.map((product: ProductItemPropsType, index: number) => {
                        const { name, images } = product;
                        const productImage = images && images.length ? images?.[0]?.src : "";

                        return (
                          <TopSellingProductListItem
                            key={name}
                            sequence={index + 1}
                            productImage={productImage}
                            name={name}
                          />
                        );
                      })
                    ) : (
                      <BlankItem />
                    )}
                  </>
                )}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardContainer;
