import { useEffect, useState } from "react";
import { useLazyQuery, useMutation, ApolloError } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import i18n from "utils/i18n";
import isEqual from "lodash/isEqual";

import { BILLING, PLAN, PROJECT } from "constants/Router";
import { ADDON_CHANGES_SUMMARY, ADDONS } from "graphql/addon/query";
import { PAYMENT_SUMMARY } from "graphql/billing/query";
import { CURRENT_USAGE, CURRENT_PACKAGES, HAS_CHANGES_ON_NEXT_CYCLE } from "graphql/plan/query";
import { PROJECT_INFO } from "graphql/project/query";
import { BUY_ADDONS } from "graphql/addon/mutation";
import { AddonCountType, AddonType } from "types/Addon";
import { BuyAddonError } from "types/Billing";

const calculateTotalPrice = (selectedAddons: AddonCountType, addons: AddonType[]) => {
  return Object.keys(selectedAddons).reduce((totalPrice: number, key: string) => {
    const addonAmount = selectedAddons[key];
    const addon = addons.find(({ entityType }: AddonType) => entityType === key);

    if (addon) {
      const sumAddonPrice = addonAmount * addon.pricePerMonth;

      return totalPrice + sumAddonPrice;
    }
    return totalPrice;
  }, 0);
};

type UseCalculatedAddons = {
  projectId: string;
  currentAddons: AddonCountType;
  addons: AddonType[];
  currentImageSearchAddon?: AddonType;
  currentBroadcastAddon?: AddonType;
  onErrorCallback: (error: BuyAddonError | null) => void;
};

const useCalculatedAddons = (props: UseCalculatedAddons) => {
  const { currentAddons, addons, projectId, currentImageSearchAddon, currentBroadcastAddon, onErrorCallback } = props;
  const history = useHistory();

  const [selectedAddons, setSelectedAddons] = useState<AddonCountType>({});
  const [selectedImageAddon, setSelectedImageAddon] = useState<AddonType | undefined>();
  const [selectedBroadcastAddon, setSelectedBroadcastAddon] = useState<AddonType | undefined>();
  const [isOpenSummaryModal, setIsOpenSummaryModal] = useState(false);

  const handleCloseSummaryModal = () => {
    setIsOpenSummaryModal(false);
    onErrorCallback(null);
  };

  const [getAddonChangesSummary, { data, loading }] = useLazyQuery(ADDON_CHANGES_SUMMARY, {
    fetchPolicy: "network-only",
    onError: () => {
      handleCloseSummaryModal();
      toast.error(i18n.t("Please try again later"), {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 1000,
        onClose: () => {},
      });
    },
  });

  const [buyAddons, { loading: isLoadingBuyAddon }] = useMutation(BUY_ADDONS, {
    refetchQueries: [
      { query: PAYMENT_SUMMARY, variables: { projectId } },
      { query: ADDONS, variables: { projectId } },
      { query: CURRENT_USAGE, variables: { projectId } },
      { query: CURRENT_PACKAGES, variables: { projectId } },
      { query: HAS_CHANGES_ON_NEXT_CYCLE, variables: { projectId } },
      { query: PROJECT_INFO, variables: { projectId } },
    ],
    onError: (error: ApolloError) => {
      const gqlErrorMessage = error?.graphQLErrors?.[0]?.message;
      if (onErrorCallback) {
        if (gqlErrorMessage in BuyAddonError) {
          onErrorCallback(BuyAddonError[gqlErrorMessage as keyof typeof BuyAddonError]);
        } else {
          onErrorCallback(BuyAddonError["BUY_ADDONS:DEFAULT_ERROR"]);
        }
      } else {
        toast.error(`${i18n.t("Update failed!")} ${i18n.t("planAddon.error.default")}`, {
          position: toast.POSITION.TOP_CENTER,
          closeButton: false,
          autoClose: 3000,
        });
      }
    },
    onCompleted: () => {
      toast.success(i18n.t("Update successfully"), {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 1000,
        onClose: () => {},
      });

      setIsOpenSummaryModal(false);
      history.push(`/${PROJECT}/${projectId}/${PLAN}/${BILLING}`);
    },
  });

  const handleToggleAddon = (type: string, isDisabled: boolean) => {
    const statusValue = isDisabled ? -1 : 1;
    const currentStatusValue = currentAddons[type] || -1;
    const isStatusChanged = currentStatusValue !== statusValue;

    const newSelectedAddons = {
      ...selectedAddons,
      [type]: isStatusChanged ? statusValue : 0,
    };
    setSelectedAddons(newSelectedAddons);
  };

  const handleChangeAddon = (type: string, operationFunction: (value: number) => number) => {
    if (selectedAddons[type]) {
      const newSelectedAddons = {
        ...selectedAddons,
        [type]: operationFunction(selectedAddons[type]),
      };
      setSelectedAddons(newSelectedAddons);
    } else {
      const newSelectedAddons = {
        ...selectedAddons,
        [type]: operationFunction(0),
      };
      setSelectedAddons(newSelectedAddons);
    }
  };

  const handleAddNewAddon = (type: string) => {
    const incrementFunction = (value: number) => value + 1;
    handleChangeAddon(type, incrementFunction);
  };

  const handleRemoveAddon = (type: string) => {
    const decrementFunction = (value: number) => value - 1;
    handleChangeAddon(type, decrementFunction);
  };

  const handleChangeImageSearchAddon = (newImageSearchAddon?: AddonType) => {
    setSelectedImageAddon(newImageSearchAddon);
  };

  const handleChangeBroadcastAddon = (newBroadcastAddon?: AddonType) => {
    setSelectedBroadcastAddon(newBroadcastAddon);
  };

  const getChangedAddons = () => {
    let changedAddons = Object.keys(selectedAddons).reduce(
      ({ added, removed }: { added: string[]; removed: string[] }, key: string) => {
        const amount = selectedAddons[key];
        const addon = addons.find(({ entityType }: AddonType) => entityType === key);
        const addonIds = Array.from({ length: Math.abs(amount) }, () => addon && addon.id).filter(
          (val) => val,
        ) as string[];

        if (amount > 0) {
          const addedIds = added.concat(addonIds);
          return {
            added: addedIds,
            removed,
          };
        }

        const removedIds = removed.concat(addonIds);
        return {
          added,
          removed: removedIds,
        };
      },
      {
        added: [],
        removed: [],
      },
    );

    const isImageSearchAddonChanged = !isEqual(currentImageSearchAddon, selectedImageAddon);
    if (isImageSearchAddonChanged) {
      const removedList = currentImageSearchAddon
        ? [...changedAddons.removed, currentImageSearchAddon.id]
        : changedAddons.removed;
      const addedList = selectedImageAddon ? [...changedAddons.added, selectedImageAddon.id] : changedAddons.added;

      changedAddons = {
        added: addedList,
        removed: removedList,
      };
    }

    const isBroadcastAddonChanged = !isEqual(currentBroadcastAddon, selectedBroadcastAddon);
    if (isBroadcastAddonChanged) {
      const removedList = currentBroadcastAddon
        ? [...changedAddons.removed, currentBroadcastAddon.id]
        : changedAddons.removed;
      const addedList = selectedBroadcastAddon
        ? [...changedAddons.added, selectedBroadcastAddon.id]
        : changedAddons.added;

      changedAddons = {
        added: addedList,
        removed: removedList,
      };
    }

    return changedAddons;
  };

  const handleCalculateSummaryAddon = () => {
    const changedAddons = getChangedAddons();

    setIsOpenSummaryModal(true);
    getAddonChangesSummary({
      variables: {
        projectId,
        newAddonIds: changedAddons.added,
        removedAddonIds: changedAddons.removed,
      },
    });
  };

  const handleBuyAddons = () => {
    const changedAddons = getChangedAddons();

    buyAddons({
      variables: {
        projectId,
        newAddonIds: changedAddons.added,
        removedAddonIds: changedAddons.removed,
      },
    });
  };

  useEffect(() => {
    setSelectedImageAddon(currentImageSearchAddon);
  }, [currentImageSearchAddon]);

  useEffect(() => {
    setSelectedBroadcastAddon(currentBroadcastAddon);
  }, [currentBroadcastAddon]);

  const currentImageSearchAddonPrice = currentImageSearchAddon ? currentImageSearchAddon.pricePerMonth : 0;
  const selectedImageSearchAddonPrice = selectedImageAddon ? selectedImageAddon.pricePerMonth : 0;
  const imageSearchAddonChangedPrice = selectedImageSearchAddonPrice - currentImageSearchAddonPrice;

  const currentBroadcastAddonPrice = currentBroadcastAddon ? currentBroadcastAddon.pricePerMonth : 0;
  const selectedBroadcastAddonPrice = selectedBroadcastAddon ? selectedBroadcastAddon.pricePerMonth : 0;
  const broadcastAddonChangedPrice = selectedBroadcastAddonPrice - currentBroadcastAddonPrice;

  const currentPrice =
    calculateTotalPrice(currentAddons, addons) + currentImageSearchAddonPrice + currentBroadcastAddonPrice;
  const subTotalPrice =
    calculateTotalPrice(selectedAddons, addons) + imageSearchAddonChangedPrice + broadcastAddonChangedPrice;

  return {
    selectedAddons,
    currentPrice,
    subTotalPrice,
    handleAddNewAddon,
    handleRemoveAddon,
    handleToggleAddon,
    handleCalculateSummaryAddon,
    addonChangesSummary: data ? data.addonChangesSummary : {},
    isLoadingSummary: loading,
    isLoadingBuyAddon,
    handleBuyAddons,
    handleChangeImageSearchAddon,
    selectedImageAddon,
    handleChangeBroadcastAddon,
    selectedBroadcastAddon,
    isOpenSummaryModal,
    handleCloseSummaryModal,
  };
};

export default useCalculatedAddons;
