import { useState } from "react";
import { useMutation } from "@apollo/client";

import { SYNONYM_CONFIGS } from "constants/Synonym";
import { SYNONYMS } from "graphql/synonym/query";
import {
  SynonymType,
  UpdatedSynonymType,
  SynonymsQueryType,
  SynonymConfigKeyType,
  SynonymQueryVariablesType,
} from "types/Synonym";
import { notifyError, notifySuccess } from "utils/notify";
import i18n from "utils/i18n";
import { NOTIFICATION_SAVE } from "constants/Notification";
import { addGoogleTagEvent } from "services/GoogleTagEvent";
import { GTM_EVENT } from "constants/GTM";

export default (projectId: string, type: SynonymConfigKeyType, queryVariable: SynonymQueryVariablesType) => {
  const [changedSynonyms, setChangedSynonyms] = useState<UpdatedSynonymType[]>([]);

  const [saveSynonyms] = useMutation(SYNONYM_CONFIGS[type].MUTATION, {
    update(cache, { data: { savedSynonyms } }) {
      const cacheSynonyms = cache.readQuery<SynonymsQueryType>({
        query: SYNONYMS,
        variables: queryVariable,
      });

      if (cacheSynonyms) {
        const updatedSynonyms = cacheSynonyms.synonyms.results.map((synonym: SynonymType) => {
          if (savedSynonyms.parentWord && savedSynonyms.parentWord === synonym.word) {
            const updatedChildrenSynonyms = (synonym.children as SynonymType[]).map((childSynonym) => {
              const matchedUpdatedSynonym = savedSynonyms.find(
                ({ word }: UpdatedSynonymType) => word === childSynonym.word,
              );

              return matchedUpdatedSynonym
                ? {
                    ...childSynonym,
                    synonyms: matchedUpdatedSynonym.synonyms,
                  }
                : childSynonym;
            });

            return updatedChildrenSynonyms;
          }
          const matchedUpdatedSynonym = savedSynonyms.find(({ word }: UpdatedSynonymType) => word === synonym.word);
          return matchedUpdatedSynonym
            ? {
                ...synonym,
                synonyms: matchedUpdatedSynonym.synonyms,
              }
            : synonym;
        });

        cache.writeQuery({
          query: SYNONYMS,
          variables: queryVariable,
          data: {
            synonyms: {
              ...cacheSynonyms.synonyms,
              results: updatedSynonyms,
            },
          },
        });
      }
    },
    onCompleted: () => {
      setChangedSynonyms([]);
      if (type === "PRODUCT_NAME") {
        addGoogleTagEvent(GTM_EVENT.SET_SYNONYM_PRODUCT_NAME);
      }

      notifySuccess(i18n.t(NOTIFICATION_SAVE.SUCCESS));
    },
    onError: () => {
      notifyError(i18n.t(NOTIFICATION_SAVE.FAIL));
    },
  });

  const handleChangedSynonyms = (word: string, synonyms: string[], parentWord: string | undefined = undefined) => {
    const newChangedSynonyms = {
      word,
      synonyms,
      parentWord,
    };

    const filteredChangedSynonyms = changedSynonyms.filter((synonym: SynonymType) => {
      // Check condition when changed synonym have same word(key) with existing synonym but its not the same synonym
      // One is parent and one is child but have same word(key)
      // PS. parent synonym has undefined parentWord value(no parent)
      if (
        (parentWord === word && synonym.parentWord === undefined) ||
        (parentWord === undefined && synonym.parentWord === synonym.word)
      ) {
        return true;
      }

      return word !== synonym.word;
    });

    const updatedChangedSynonyms = [...filteredChangedSynonyms, newChangedSynonyms];

    setChangedSynonyms(updatedChangedSynonyms);
  };

  const handleSaveSynonyms = () => {
    saveSynonyms({
      variables: {
        projectId,
        synonyms: changedSynonyms,
      },
    });
  };

  const resetChangedSynonyms = () => {
    setChangedSynonyms([]);
  };

  return {
    changedSynonyms,
    handleChangedSynonyms,
    handleSaveSynonyms,
    resetChangedSynonyms,
  };
};
