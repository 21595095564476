import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { get } from "lodash";
import DropZoneUpload from "components/DropzoneUpload";
import ImageUpload from "components/ImageUpload";
import { AddImageBox } from "components/ImageUpload/styled";
import Typography from "components/Typography";
import TextField from "components/TextField";
import ErrorText from "components/ErrorText";
import COLORS from "constants/Colors";
import { BANK_LOGO_CIRCLE, UPLOAD_IMAGE } from "constants/Image";
import { ImageUrl, UploadDirectoryType } from "types/Image";
import { Bank } from "types/Bank";
import { ImageWrapper, SelectItem, UploadBox, Select, Label, ExampleText, BrowseButton } from "./styled";
import { BankFormData } from "./index";

export interface BankingDetailPropsType {
  projectId: string;
  errors?: any;
  register?: any;
  bank?: string;
  bookBankImage?: string;
  bankData?: BankFormData;
  isMobile: boolean;
  handleRemoveImage: (type: "personal" | "bankAccount") => void;
  handleChangeImage: (type: "personal" | "bankAccount", image: ImageUrl[]) => void;
  handleSetBankDetail: (type: keyof BankFormData, value: string) => void;
  handleSelectBank: (value: any) => void;
  setExampleType: (value: "personal" | "bankAccount") => void;
}

const DesktopBankingDetail: FC<BankingDetailPropsType> = ({
  bookBankImage,
  bankData,
  projectId,
  errors,
  register,
  bank,
  handleRemoveImage,
  handleChangeImage,
  handleSelectBank,
  isMobile,
  setExampleType,
  handleSetBankDetail,
}) => {
  const { t } = useTranslation();
  const bankOptions = Object.keys(Bank).map((value: string) => ({ key: value, value, label: t(value) }));

  return (
    <Grid container className={isMobile ? "p-4" : "px-5 py-3"}>
      <Grid style={{ textAlign: "center" }} item className="mt-2 w-100" justify="center">
        <Typography color={COLORS.Dark} variant="title2">
          {t("deeplePay.verifyBilling.bankingInfo.title")}
        </Typography>
      </Grid>
      <Grid container className="p-0 m-0">
        <UploadBox item>
          <Grid item className="mt-2 mb-0 title">
            <Typography color={COLORS.DarkLight} variant="body3">
              {t("deeplePay.verifyBilling.bankingInfo.description")}
              <ExampleText className="pl-1" onClick={() => setExampleType("bankAccount")}>
                {t("deeplePay.verifyBilling.bankingInfo.seeExample")}
              </ExampleText>
            </Typography>
          </Grid>
          {bookBankImage ? (
            <ImageWrapper container justify="center" className="mt-2 mb-3">
              <ImageUpload
                height={160}
                onChange={(img: ImageUrl[]) => handleChangeImage("bankAccount", img)}
                onRemove={() => handleRemoveImage("bankAccount")}
                image={bookBankImage}
                directoryType={UploadDirectoryType.PROJECT_CERTIFICATION}
              />
            </ImageWrapper>
          ) : (
            <DropZoneUpload
              projectId={projectId}
              UploadDirectory={UploadDirectoryType.PROJECT_CERTIFICATION}
              onChange={(img: ImageUrl[]) => handleChangeImage("bankAccount", img)}
              limitItem={1}
              maxSizeMB={2}
            >
              <AddImageBox height={150}>
                <img src={UPLOAD_IMAGE} alt="upload-bank" />
                <Typography className="mt-2" variant="title8" color="gray">
                  {t("Drag and drop")}
                </Typography>
                <Typography className="my-2" variant="body3" color="gray">
                  {t("OR")}
                </Typography>
                <BrowseButton disabled size="small">
                  <Typography variant="body3" className="py-2 px-4" color={COLORS.White}>
                    {t("Browse")}
                  </Typography>
                </BrowseButton>
              </AddImageBox>
            </DropZoneUpload>
          )}
        </UploadBox>
        <ErrorText className="pb-2">{errors.bookBankImage && t(errors.bookBankImage.message as string)}</ErrorText>
      </Grid>
      <Grid container justify="flex-start">
        <Grid item xs={12} className="mb-3">
          <Label color={COLORS.DarkLight} variant="body4">
            {t("deeplePay.verifyBilling.bankingInfo.bankName")}
          </Label>
          <Select
            value={bank || bankOptions[0]?.value}
            error={Boolean(errors.bank)}
            onChange={handleSelectBank}
            fullWidth
            name="bank"
          >
            {bankOptions.map(({ key, label, value }) => (
              <SelectItem style={{ zIndex: 1 }} value={value} key={key}>
                <img src={BANK_LOGO_CIRCLE[key]} alt={key} />
                <div>{t(label)}</div>
              </SelectItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={5}>
          <Label color={COLORS.DarkLight} variant="body4">
            {t("deeplePay.verifyBilling.bankingInfo.accountName")}
          </Label>
          <TextField
            defaultValue={get(bankData, "bankAccountName") || ""}
            error={Boolean(errors.bankAccountName)}
            fullWidth
            placeholder={t("deeplePay.verifyBilling.bankingInfo.accountName")}
            name="bankAccountName"
            validate={register}
            variant="outlined"
            onChange={(e) => handleSetBankDetail("bankAccountName", e.target.value)}
            value={bankData?.bankAccountName}
          />
          <ErrorText className="pt-1">
            {errors.bankAccountName && t(errors.bankAccountName.message as string)}
          </ErrorText>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <Label color={COLORS.DarkLight} variant="body4">
            {t("deeplePay.verifyBilling.bankingInfo.accountNo")}
          </Label>
          <TextField
            defaultValue={get(bankData, "bankAccountNo") || ""}
            error={Boolean(errors.bankAccountNo)}
            fullWidth
            placeholder="xxx-xxxxxx-x"
            name="bankAccountNo"
            validate={register}
            variant="outlined"
            onChange={(e) => handleSetBankDetail("bankAccountNo", e.target.value)}
            value={bankData?.bankAccountNo}
          />
          <ErrorText className="pt-1">{errors.bankAccountNo && t(errors.bankAccountNo.message as string)}</ErrorText>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DesktopBankingDetail;
