import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";
import MUILinearProgress from "@material-ui/core/LinearProgress";

import COLORS from "constants/Colors";
import { AI_STATUS_COLOR } from "constants/Faq";
import { USAGE_STATUS_COLOR } from "constants/PlanUsage";
import { ScoreComponentType } from "types/Score";
import { getFaqStatus, getUsageStatus } from "utils/score";

type ScorePropsType = {
  score: number;
  type: ScoreComponentType;
};

type ScoreBarPropsType = {
  colorStatus: string;
};

type colorGroupType = { [key: string]: string };

const ScoreBar = styled(MUILinearProgress)<ScoreBarPropsType>`
  background-color: ${COLORS.LightGray};
  height: 8px;
  border-radius: 20px;
  width: 100%;
  .MuiLinearProgress-bar {
    background-color: ${({ colorStatus }) => colorStatus || COLORS.LightGray};
  }
`;

const Score: FC<ScorePropsType & HTMLAttributes<HTMLDivElement>> = ({ score, type }) => {
  const colorGroup: colorGroupType = type === ScoreComponentType.AI ? AI_STATUS_COLOR : USAGE_STATUS_COLOR;
  const status = type === ScoreComponentType.AI ? getFaqStatus(score) : getUsageStatus(score);

  return <ScoreBar className="ma-1" variant="determinate" value={score} colorStatus={colorGroup[status]} />;
};

export default Score;
