import * as React from "react";

const SvgIcPreference = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.6 14.133H5.733V4.8H7.6v9.333zM5.733 27.2H7.6v-5.6H5.733v5.6zm9.334 0h1.866V16h-1.866v11.2zm9.333 0h1.867v-3.733H24.4V27.2zm1.867-22.4H24.4V16h1.867V4.8zm-9.334 0h-1.866v3.733h1.866V4.8zM9.467 16h-5.6C2.84 16 2 16.84 2 17.867c0 1.026.84 1.866 1.867 1.866h5.6c1.026 0 1.866-.84 1.866-1.866 0-1.027-.84-1.867-1.866-1.867zm9.333-5.6h-5.6c-1.027 0-1.867.84-1.867 1.867 0 1.026.84 1.866 1.867 1.866h5.6c1.027 0 1.867-.84 1.867-1.866 0-1.027-.84-1.867-1.867-1.867zm9.333 7.467h-5.6c-1.026 0-1.866.84-1.866 1.866 0 1.027.84 1.867 1.866 1.867h5.6c1.027 0 1.867-.84 1.867-1.867 0-1.026-.84-1.866-1.867-1.866z"
    />
  </svg>
);

export default SvgIcPreference;
