import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import Card from "components/Card";
import Typography from "components/Typography";

import COLORS from "constants/Colors";
import { InventoryIntegrationCredential } from "types/Integration";

type ConfirmationFormProps = {
  credentialData: InventoryIntegrationCredential;
  platform: string;
  syncingMethod: string;
  warehouseName: string;
  isMobile: boolean;
};

const ConfirmationForm: FC<ConfirmationFormProps> = (props) => {
  const { t } = useTranslation();

  const { platform, credentialData, syncingMethod, warehouseName, isMobile } = props;
  const { storeName, apiKey, apiSecret } = credentialData;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card noShadow padding="24px" fullWidth>
          <Grid container className="position-relative">
            <Grid item container alignItems="flex-start" xs={12} sm={6}>
              <Grid item xs={12}>
                <Typography variant="title2" color="dark">
                  {t("inventoryIntegration.confirmationForm.title")}
                </Typography>
              </Grid>
              <Grid item container className="pt-3">
                <Grid item container>
                  <Grid item className="pr-2">
                    <Typography variant="body3" color="darkMed">
                      {t("inventoryIntegration.confirmationForm.platform.label")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body3" color="dark">
                      {platform}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container className="pt-2">
                  <Grid item className="pr-2">
                    <Typography variant="body3" color="darkMed">
                      {t("inventoryIntegration.confirmationForm.storeName.label")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body3" color="dark">
                      {storeName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container className="pt-2">
                  <Grid item className="pr-2">
                    <Typography variant="body3" color="darkMed">
                      {t("inventoryIntegration.confirmationForm.warehouse.label")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body3" color="dark">
                      {warehouseName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container className="pt-2">
                  <Grid item className="pr-2">
                    <Typography variant="body3" color="darkMed">
                      {t("inventoryIntegration.confirmationForm.syncingMethod.label")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body3" color="dark">
                      {syncingMethod}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} className={isMobile ? "pt-3" : "pl-2"}>
              <Card noShadow fullWidth bgColor={COLORS.Light} padding="24px">
                <Grid container direction="column" justify="space-around" className="h-100">
                  <Grid item container direction="column" className="pb-2">
                    <Typography variant="title8" color="primary">
                      {t("inventoryIntegration.confirmationForm.apiKey.label")}
                    </Typography>
                    <Typography variant="body3" color="darkMed" className="mt-1 wordBreak">
                      {apiKey}
                    </Typography>
                  </Grid>
                  <Grid item container direction="column" className="pt-2">
                    <Typography variant="title8" color="primary">
                      {t("inventoryIntegration.confirmationForm.apiSecret.label")}
                    </Typography>
                    <Typography variant="body3" color="darkMed" className="mt-1 wordBreak">
                      {apiSecret}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ConfirmationForm;
