import * as React from "react";

const SvgIcLogout = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <defs>
      <path id="ic-logout_svg__a" d="M0 0h32v32H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="ic-logout_svg__b" fill="#fff">
        <use xlinkHref="#ic-logout_svg__a" />
      </mask>
      <path
        fill="currentColor"
        d="M17 4c1.654 0 3 1.346 3 3v3a1 1 0 11-2 0V7c0-.551-.448-1-1-1h-4.658l.296.099A2.015 2.015 0 0114 7.999v15h3a1 1 0 001-1v-4a1 1 0 112 0v4c0 1.655-1.346 3-3 3h-3v1c0 1.104-.897 2-2 2a2.07 2.07 0 01-.62-.092L5.362 25.9A2.015 2.015 0 014 24V6c0-1.103.897-2 2-2 .049 0 .095.018.143.022C6.18 4.017 6.212 4 6.25 4zm5.617 5.076a1 1 0 011.09.217l4 4a.999.999 0 010 1.414l-4 4A1 1 0 0122 18v-3h-4a1 1 0 010-2h4v-3a1 1 0 01.617-.924z"
        mask="url(#ic-logout_svg__b)"
      />
    </g>
  </svg>
);

export default SvgIcLogout;
