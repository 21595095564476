import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import Badge from "components/Badge";
import { SvgIcon } from "components/Icon";
import { List, NavLink } from "components/SideNav/SideNav.styled";
import { AppTitleContext } from "utils/context";

type SideNavList = {
  onClickMenuList: () => void;
  Icon?: FC;
  title: string;
  page: string;
  link: string;
  currentPage: string;
  badgeNo?: number;
  disabled?: boolean;
};

const SideNavList: FC<SideNavList> = ({
  onClickMenuList,
  Icon,
  title,
  page,
  link,
  currentPage,
  children,
  badgeNo,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const { setTitle } = useContext(AppTitleContext);

  return (
    <NavLink
      className={clsx(["menu-item isSideNav", { isActive: currentPage === page, disabled }])}
      to={link}
      onClick={() => {
        setTitle(t(title));
        onClickMenuList();
      }}
    >
      <List>
        {Icon && <SvgIcon className="ml-3 mr-3" component={Icon} fontSize="large" />}
        {children || t(title)}
        {Boolean(badgeNo) && <Badge>{badgeNo}</Badge>}
      </List>
    </NavLink>
  );
};

export default SideNavList;
