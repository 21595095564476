import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion, { AccordionProps } from "@material-ui/core/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails, { AccordionDetailsProps } from "@material-ui/core/AccordionDetails";

import Button from "components/Button";

import COLORS from "constants/Colors";
import { FC } from "react";

export const FormContainer = styled.div`
  padding: 16px;
  @media (min-width: 1024px) {
    max-width: 942px;
    margin: 0 auto;
    padding: 0 0 124px 0;
  }
`;

export const InformationBoxContainer = styled.div`
  display: flex;
  align-items: start;
  padding: 10px 16px;
  border-radius: 8px;
  background-color: ${COLORS.Light};
  color: #b4b6c3;
`;

export const MessageImageUploaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 268px;
  width: 100%;
  background: ${COLORS.Gray100};
  border: 1px dashed ${COLORS.Gray400};
  border-radius: 8px;
  flex: none;
  position: relative;
  margin: 0 auto;

  img {
    border-radius: 8px;
    height: 268px;
    width: 100%;
  }

  .image-container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .image-container button {
    padding: 2px 5px;
    font-size: 19px;
    color: ${({ theme }) => theme.COLORS.DarkMed};
    border-radius: 6px;
    border: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
    background-color: ${({ theme }) => theme.COLORS.White};
    position: absolute;
    top: -8px;
    right: -8px;
  }

  @media only screen and (min-width: 768px) {
    height: 390px;
    width: 390px;

    img {
      height: 390px;
      width: 390px;
    }
  }
`;

export const TooltipTable = styled.table`
  position: reletive;
  width: 276px;
  background: #141931;
  border-radius: 8px;
  color: ${COLORS.White};
  border-collapse: collapse;
  text-align: left;
  font-size: 12px;

  tr {
    border-bottom: 1px solid ${COLORS.DarkGray};
  }

  th {
    padding: 10px;
    font-weight: 700;
  }

  td {
    padding: 10px;
  }

  :before {
    position: absolute;
    content: "";
    width: 16px;
    height: 16px;
    background-color: inherit;
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
    left: 4px;
    top: -6px;
  }
`;

export const Accordion = (withStyles({
  root: {
    cursor: "none",
    border: "none",
    borderRadius: "8px",
    boxShadow: "none",
    "&::before": {
      display: "none",
    },
  },
})(MuiAccordion) as unknown) as FC<AccordionProps>;

export const AccordionSummary = (withStyles({
  root: {
    border: "none",
  },
})(MuiAccordionSummary) as unknown) as FC<AccordionSummaryProps>;

export const AccordionDetails = (withStyles({})(MuiAccordionDetails) as unknown) as FC<AccordionDetailsProps>;

export const ModalAdditionalMessageExampleContent = styled.div`
  @media only screen and (min-width: 1024px) {
    padding: 52px 48px 48px 48px;
  }
`;

export const ModalAdditionalMessageExampleImg = styled.img`
  width: 420px;
  @media only screen and (min-width: 1024px) {
    width: 420px;
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${COLORS.Primary} !important;
  color: ${COLORS.White} !important;

  &:hover {
    color: ${COLORS.Primary} !important;
    border: solid 0.5px ${COLORS.Primary} !important;
    background-color: ${COLORS.LightGray} !important;
  }
`;
