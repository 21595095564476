import React, { FC, useContext } from "react";
import { ApolloProvider } from "@apollo/client";
import DateFnsUtils from "@date-io/date-fns";

import {
  AppTitleContextProvider,
  CurrentPageContextProvider,
  SideNavMenuContextProvider,
  ErrorContext,
} from "utils/context";
import { I18nextProvider } from "react-i18next";
import UserAuthentication from "components/UserAuthentication";
import DeepleTheme from "components/Theme/DeepleTheme";
import { apolloClient } from "utils";
import i18n from "utils/i18n";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

const ContextProviders: FC = ({ children }) => {
  const { setError } = useContext(ErrorContext);
  return (
    <I18nextProvider i18n={i18n}>
      <DeepleTheme>
        <ApolloProvider client={apolloClient.getApolloClient(setError)}>
          <UserAuthentication>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <AppTitleContextProvider>
                <CurrentPageContextProvider>
                  <SideNavMenuContextProvider>{children}</SideNavMenuContextProvider>
                </CurrentPageContextProvider>
              </AppTitleContextProvider>
            </MuiPickersUtilsProvider>
          </UserAuthentication>
        </ApolloProvider>
      </DeepleTheme>
    </I18nextProvider>
  );
};

export default ContextProviders;
