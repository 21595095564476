import React, { FC, memo, MouseEventHandler } from "react";
import { IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { addMinutes } from "date-fns";

import { Day } from "types/Setting";
import { dateToWorkingTimeString, workingTimeStringToDate } from "utils/adminWorkingHours";
import TimePicker from "components/TimePicker";
import { IcPlusCircle, IcMinusCircle } from "components/SvgIcons";
import Typography from "components/Typography";
import Grid from "components/Grid";

type WorkingHourItemPropTypes = {
  index: number;
  day: Day;
  onAdd: (day: Day) => void;
  onRemove: (day: Day, index: number) => void;
  onChange: (day: Day, index: number, values: [string, string]) => void;
  start: string;
  end: string;
  isAddEnabled?: boolean;
  isRemoveEnabled?: boolean;
  disabled?: boolean;
};

const WorkingHourItem: FC<WorkingHourItemPropTypes> = ({
  onRemove,
  index,
  day,
  onAdd,
  onChange,
  start,
  end,
  isAddEnabled = true,
  isRemoveEnabled = true,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const handleAdd: MouseEventHandler<HTMLButtonElement> = (e) => {
    onAdd(day);
    e.preventDefault();
  };
  const handleRemove: MouseEventHandler<HTMLButtonElement> = (e) => {
    onRemove(day, index);
    e.preventDefault();
  };

  const startDate = workingTimeStringToDate(start);
  const endDate = workingTimeStringToDate(end);

  const handleChangeStart = (time: string) => {
    const start = time || "";

    const newStartDate = workingTimeStringToDate(time);

    if (newStartDate && endDate && newStartDate >= endDate) {
      const newEndDate = addMinutes(newStartDate, 1);
      onChange(day, index, [start, dateToWorkingTimeString(newEndDate)]);
    } else {
      onChange(day, index, [start, end]);
    }
  };

  const handleChangeEnd = (time: string) => {
    const end = time || "";

    const newEndDate = workingTimeStringToDate(time);

    if (newEndDate && startDate && newEndDate <= startDate) {
      const newStartDate = addMinutes(newEndDate, -1);
      onChange(day, index, [dateToWorkingTimeString(newStartDate), end]);
    } else {
      onChange(day, index, [start, end]);
    }
  };

  return (
    <Grid container className="align-items-center mb-3 w-100">
      <Grid item xs={8} sm={6} md="auto" className="d-flex align-items-center">
        <Typography variant="body3" className="mx-3 d-none d-md-block">
          {t(`SCHEDULE_FORM_FROM`)}
        </Typography>
        <TimePicker name="start" value={start} onChange={handleChangeStart} disabled={disabled} />
        <Typography variant="body3" className="mx-3">
          {t(`SCHEDULE_FORM_TO`)}
        </Typography>
        <TimePicker name="end" value={end} onChange={handleChangeEnd} disabled={disabled} />
      </Grid>

      <Grid item xs={4} sm={1} md="auto" className="d-flex ml-md-2 pl-md-2 align-items-center">
        {isAddEnabled && (
          <IconButton onClick={handleAdd} disabled={disabled}>
            <IcPlusCircle />
          </IconButton>
        )}
        {isRemoveEnabled && (
          <IconButton onClick={handleRemove} disabled={disabled}>
            <IcMinusCircle />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default memo(WorkingHourItem);
