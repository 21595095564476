import nanoid from "utils/nanoid";
import { ProductVariationsType } from "types/Product";
import { CategoryType } from "types/Category";

export const getSuggesionVariationListFromSelectedCategory = (categoryData: CategoryType) => {
  const VariationsLits: ProductVariationsType[] = [];
  categoryData.variations.forEach((property: { name: string }) => {
    VariationsLits.push({ nanoid: nanoid(), key: property.name, value: [] });
  });
  return VariationsLits;
};
