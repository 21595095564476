import flatMap from "lodash/flatMap";
import { ProductOptionType } from "types/Product";

export type SubRowDataType = {
  sku: string;
  productPrice: number;
  amount: number;
};

export const formattedSubRows = (options?: ProductOptionType[]): SubRowDataType[] => {
  if (!options) {
    return [];
  }

  const activeOptions = options.filter((option) => option.isActive);
  const choices = flatMap(activeOptions, (filterOption) => {
    const selectedChoices = filterOption.choices.filter((choice) => choice.isSelected);
    return selectedChoices;
  });

  return choices.map((choice) => {
    return {
      sku: choice.name,
      productPrice: choice.price,
      amount: 1,
    };
  });
};
