import React, { useState, useEffect, ChangeEvent } from "react";

import Tab from "components/Tab";
import Typography from "components/Typography";
import Modal from "components/Modal";
import CircularProgress from "components/CircularProgress";
import { INIT_TAB_VALUE, FOOD_PRODUCT_TABS } from "constants/Product";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { Category } from "types";
import {
  AddProductSKUType,
  ProductImagesType,
  ProductItemPropsType,
  ProductPropertiesType,
  ProductType,
  ProductVariationsType,
  STATE_TAB,
} from "types/Product";
import { useForm, FormProvider } from "react-hook-form";
import { ProjectIdType } from "types/Project";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { ADD_PRODUCT, EDIT_PRODUCT, DELETE_PRODUCT } from "graphql/product/mutation";
import { NOTIFICATION_SAVE, NOTIFICATION_DELETE, NOTIFICATION_UPDATE } from "constants/Notification";
import { notifyWarn, notifySuccess } from "utils/notify";
import { setRequiredAndMaximum } from "utils/product/setRequiredAndMaximum";
import { getInitSKUsConvertImageObject } from "utils/product/getInitSKUsConvertImageObject";

import { getInitImages } from "utils/product/getInitImages";
import { GTM_EVENT } from "constants/GTM";
import { addGoogleTagEvent } from "services/GoogleTagEvent";
import DetectChangeCategoryModal from "../DetectChangeCategoryModal";
import { ProductModalHeader, AddProductContainerWrapper } from "./styled";
import { addNewProductSchema } from "./validateSchema";
import { ProductDetailsTab } from "./ProductDetails";
import { ProductSKUsTab } from "./ProductSKUs";
import mapErrorMessage from "../mapErrorMessage";

type AddProductContainerPropsType = {
  categoryData: Category.CategoryType[];
  productData?: { product: ProductItemPropsType };
  closeProductModal: Function;
  isEditMode?: boolean;
};

const AddProductContainer: React.FC<AddProductContainerPropsType> = ({
  categoryData,
  closeProductModal,
  productData,
  isEditMode = false,
}) => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const [stateTab, setStateTab] = useState(FOOD_PRODUCT_TABS[INIT_TAB_VALUE]);
  const [isProductDetailsPassValidated, setProductDetailsPassValidated] = useState(true);

  // all product form data Product details tab 1
  const initName = isEditMode ? get(productData, ["product", "name"], "") : "";
  const initCategory = isEditMode ? get(productData, ["product", "categories", "0", "id"], "") : "";
  const initProductCode = isEditMode ? get(productData, ["product", "productCode"], "") : "";
  const initDescription = isEditMode ? get(productData, ["product", "description"], "") : "";
  const initPrice = isEditMode ? get(productData, ["product", "defaultPrice"], 0) : 0;
  const initProductId = isEditMode ? get(productData, ["product", "id"], "") : "";
  const images = isEditMode ? get(productData, ["product", "images"], []) : [];
  const initImages = isEditMode ? getInitImages(images) : [];

  const [productName, setProductName] = useState<string>(initName);
  const [isOpenModalDetectHighlightProducts, setIsOpenModalDetectHighlightProducts] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>(initCategory);

  const prevCategoryId = get(productData, ["product", "categories", "0", "id"], "");
  const prevCategory = categoryData.find((category) => category.id === prevCategoryId);
  const selectCategory = categoryData.find((category) => category.id === selectedCategoryId);
  const [productImage, setProductImage] = useState<ProductImagesType[]>(initImages);
  const [productIsActive, setProductIsActive] = useState(true);
  const [productBrand, setProductBrand] = useState("");
  const [productCode, setProductCode] = useState(initProductCode);
  const [productDescription, setProductDescription] = useState(initDescription);
  const [productUnit, setProductUnit] = useState("");
  const [productPrice, setProductPrice] = useState(initPrice);
  const [productIsFree, setProductIsFree] = useState(false);
  const [propertyListData, setPropertyListData] = useState<ProductPropertiesType[]>([]);
  const [variationListData, setVariationListData] = useState<ProductVariationsType[]>([]);
  const [selectedSKUFirstItem, setSelectedSKUFirstItem] = useState("");
  const [selectedSKUSecondItem, setSelectedSKUSecondItem] = useState("");
  const title = productData ? "Edit food" : "Add new food";
  const methods = useForm({
    resolver: yupResolver(addNewProductSchema),
  });

  const { register, errors, handleSubmit, setValue } = methods;

  const [productId, setProductId] = useState(initProductId);

  const [disableTabAfterAddedProductSuccess, setDisableTabAfterAddedProductSuccess] = useState(false);

  const handleChangeTab = (_event: ChangeEvent<{}> | null, val: number) => {
    setStateTab(FOOD_PRODUCT_TABS[val]);
  };

  useEffect(() => {
    // listen onchange data and validate mandatory fields
    register({ name: "productName" });
    register({ name: "productCode" });
    register({ name: "productPrice" });
    register({ name: "selectedCategoryId" });
    register({ name: "properties" });
    register({ name: "productSKUs" });
  }, [register]);

  useEffect(() => {
    setValue("selectedCategoryId", initCategory);
    setValue("properties", []);
    setValue(
      "productSKUs",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getInitSKUsConvertImageObject(productId, get(productData, ["product", "productSKUs"], []) as any),
    );
    setValue("productName", initName || "");
    setValue("productCode", initProductCode || "");
    setValue("productPrice", initPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const errorsData = Object.values(errors);
    if (errorsData.length) {
      const errorMessage = errorsData.map((error) => (error ? t(error.message || "") : "")).join("\n");
      notifyWarn(errorMessage, { autoClose: 8000 });
    }
  }, [errors, t]);

  // all product form data Product SKU tab 2
  const [productSKUs, setProductSKUs] = useState<AddProductSKUType[]>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getInitSKUsConvertImageObject(productId, get(productData, ["product", "productSKUs"], []) as any),
  );

  const handleChangeProductSKUs = (productSKUsData: AddProductSKUType[]) => {
    setValue("productSKUs", productSKUsData);
    setProductSKUs(productSKUsData);
  };

  const getProductImageWithoutnanoid = () => {
    return productImage.map((image) => ({ src: image.src }));
  };

  const isProductNoSKUs = variationListData.length === 0;

  const getProductSKUsWithoutnanoid = (): AddProductSKUType[] => {
    return productSKUs.map<AddProductSKUType>((productSKU) => {
      return {
        id: productSKU.id || undefined,
        price: isProductNoSKUs ? productPrice : productSKU.price,
        productCode: isProductNoSKUs ? productCode : productSKU.productCode,
        weight: 0, // Food product has no weight
        image: productSKU.image,
        selected: productSKU.selected,
        isFree: productSKU.isFree,
        productType: productSKU.productType,
        options: setRequiredAndMaximum(productSKU.options || []),
      };
    });
  };

  const addProductDetailsPassedValidate = () => {
    // ("Passed validate goto step 2");
    handleChangeProductSKUs([
      {
        productCode,
        price: productPrice,
        productType: [],
        options: [],
        selected: productIsActive,
        isFree: productIsFree,
        image: "",
      },
    ]);
    setProductDetailsPassValidated(false);
    handleChangeTab(null, 1);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addProduct, { data: dataAddProduct, loading }] = useMutation(isEditMode ? EDIT_PRODUCT : ADD_PRODUCT, {
    variables: {
      projectId,
      productId: productId || undefined,
      categoryId: selectedCategoryId,
      product: {
        type: ProductType.SINGLE_SKU_WITH_OPTIONS,
        id: productId || undefined,
        name: productName,
        brand: productBrand,
        productCode,
        description: productDescription,
        unit: productUnit,
        weight: 0, // Food product has no weight
        properties: propertyListData,
        isCustomSKU: !isProductNoSKUs, // all product must contain 1 sku
        images: getProductImageWithoutnanoid(),
        defaultPrice: productPrice,
        productSKUs: getProductSKUsWithoutnanoid(),
      },
    },
  });

  const [deleteProduct] = useMutation(DELETE_PRODUCT, {
    variables: {
      projectId,
      id: productId,
    },
  });

  const handleDelete = async () => {
    try {
      await deleteProduct();
      notifySuccess(t(NOTIFICATION_DELETE.SUCCESS));
      closeProductModal();
    } catch (error) {
      const errorMessage = mapErrorMessage(error);
      notifyWarn(t(errorMessage));
    }
  };

  const editProductSKUs = async () => {
    try {
      await addProduct();
      addGoogleTagEvent(GTM_EVENT.ADD_PRODUCT);
      notifySuccess(t(NOTIFICATION_UPDATE.SUCCESS));
      closeProductModal();
    } catch (err) {
      const gqlErrorMessage: string = get(err, "graphQLErrors.0.message");
      const errorMessage = mapErrorMessage(gqlErrorMessage);
      notifyWarn(t(errorMessage), { autoClose: 8000 });
    }
  };

  const handleEditProduct = async () => {
    const isExistInHighlightProducts = !!prevCategory?.highlightProducts?.find((product) => product.id === productId);
    if (isExistInHighlightProducts && prevCategoryId !== selectedCategoryId) {
      setIsOpenModalDetectHighlightProducts(true);
      return;
    }
    editProductSKUs();
  };

  const addProductSKUsPassedValidate = async () => {
    setValue("selectedCategoryId", selectedCategoryId);
    setValue("properties", propertyListData);
    setValue("productSKUs", productSKUs);
    setValue("productName", productName || "");
    setValue("productCode", productCode || "");
    setValue("productPrice", productPrice);
    try {
      if (isEditMode) {
        handleEditProduct();
      } else {
        await addProduct();
        addGoogleTagEvent(GTM_EVENT.ADD_PRODUCT);
        notifySuccess(t(NOTIFICATION_SAVE.SUCCESS));
        closeProductModal();
      }
    } catch (error) {
      const errorMessage = mapErrorMessage(error);
      notifyWarn(t(errorMessage));
    }
  };

  const tabValue = FOOD_PRODUCT_TABS.findIndex((productTab) => productTab === stateTab);

  const responseProductId = dataAddProduct && dataAddProduct.addProduct && dataAddProduct.addProduct.id;

  if (responseProductId && responseProductId !== productId) {
    handleChangeTab(null, 2);
    setProductId(responseProductId);
    setDisableTabAfterAddedProductSuccess(true);
  }

  const handleValidateForm = handleSubmit(addProductDetailsPassedValidate);
  const handleSubmitForm = handleSubmit(addProductSKUsPassedValidate);

  const isDisabledFoodDetails = productData ? false : disableTabAfterAddedProductSuccess;
  let isDisabledVariations = false;

  if (!productData) {
    isDisabledVariations = disableTabAfterAddedProductSuccess === false ? isProductDetailsPassValidated : true;
  }

  const tabConfig = {
    header: [
      {
        id: STATE_TAB.FOOD_PRODUCT_DETAILS,
        title: "Food details",
        disabled: isDisabledFoodDetails,
      },
      {
        id: STATE_TAB.VARIATIONS,
        title: "products.variations",
        disabled: isDisabledVariations,
      },
    ],
    content:
      stateTab === STATE_TAB.FOOD_PRODUCT_DETAILS ? (
        <ProductDetailsTab
          categoryData={categoryData}
          onDelete={handleDelete}
          handleValidateForm={handleValidateForm}
          handleSubmitForm={handleSubmitForm}
          productBrand={productBrand}
          productCode={productCode}
          productDescription={productDescription}
          productImage={productImage}
          productIsActive={productIsActive}
          productIsFree={productIsFree}
          productName={productName}
          productPrice={productPrice}
          productSKUs={productSKUs}
          productUnit={productUnit}
          propertyListData={propertyListData}
          selectedCategoryId={selectedCategoryId}
          setProductBrand={setProductBrand}
          setProductCode={setProductCode}
          setProductDescription={setProductDescription}
          setProductImage={setProductImage}
          setProductIsActive={setProductIsActive}
          setProductIsFree={setProductIsFree}
          setProductName={setProductName}
          setProductPrice={setProductPrice}
          setProductSKUs={handleChangeProductSKUs}
          setProductUnit={setProductUnit}
          setPropertyListData={setPropertyListData}
          setSelectedCategoryId={setSelectedCategoryId}
          setValue={setValue}
          setVariationListData={setVariationListData}
          isEditMode={isEditMode}
        />
      ) : (
        <ProductSKUsTab
          handleSubmitForm={handleSubmitForm}
          productCode={productCode}
          productIsActive={productIsActive}
          productIsFree={productIsFree}
          productPrice={productPrice}
          productSKUs={productSKUs}
          propertyListData={propertyListData}
          selectedSKUFirstItem={selectedSKUFirstItem}
          selectedSKUSecondItem={selectedSKUSecondItem}
          setProductSKUs={handleChangeProductSKUs}
          setSelectedSKUFirstItem={setSelectedSKUFirstItem}
          setSelectedSKUSecondItem={setSelectedSKUSecondItem}
          setValue={setValue}
          setVariationListData={setVariationListData}
          variationListData={variationListData}
        />
      ),
  };

  if (loading) {
    return (
      <Modal isOpen onClose={() => {}}>
        <CircularProgress className="m-4" />
      </Modal>
    );
  }

  return (
    <AddProductContainerWrapper>
      <DetectChangeCategoryModal
        isOpen={isOpenModalDetectHighlightProducts}
        onCancel={() => setIsOpenModalDetectHighlightProducts(false)}
        onConfirm={editProductSKUs}
        prevCategoryName={prevCategory?.name as string}
        selectCategoryName={selectCategory?.name as string}
      />
      <ProductModalHeader>
        <Typography variant="title7" color="white" className="ml-4">
          {t(title)}
        </Typography>
      </ProductModalHeader>
      <FormProvider {...methods}>
        <Tab tabConfig={tabConfig} onChange={handleChangeTab} value={tabValue} />
      </FormProvider>
    </AddProductContainerWrapper>
  );
};

export default AddProductContainer;
