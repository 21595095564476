import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import COLORS from "../../constants/Colors";
import Grid from "../Grid";
import Typography from "../Typography";
import { SvgIcon } from "../Icon";
import { IcInfo } from "../SvgIcons";

import { StyledCard } from "./style";

const BroadcastWarning: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledCard fullWidth noShadow>
      <Grid container alignItems="center" className="flex-nowrap">
        <Grid item className="pr-2">
          <SvgIcon component={IcInfo} fontSize="large" htmlColor={COLORS.Error} />
        </Grid>
        <Grid item>
          <Typography variant="body3" color={COLORS.Error}>
            {t("billing.addCreditCardForm.error.submitError")}
          </Typography>
        </Grid>
      </Grid>
    </StyledCard>
  );
};

export default BroadcastWarning;
