import { ApolloError } from "@apollo/client";

export const mapErrorMessage = (err?: ApolloError) => {
  if (!err || !err.message) {
    return "";
  }

  return err.message.replaceAll(":", "_");
};

export default mapErrorMessage;
