import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Grid from "components/Grid";
import PageTitle from "components/PageTitle";
import Button from "components/Button";
import ButtonBase from "components/ButtonBase";
import Divider from "components/Divider";

import { SvgIcon } from "components/Icon";
import { IcAdd, IcArrowBack, IcFacebookComment } from "components/SvgIcons";
import FacebookCrawlerTable from "domain/MarketingTools/FacebookAutoEngagement/FacebookAutoEngagementTable";
import useGetProject from "utils/hooks/useGetProject";
import { ProjectIdType } from "types/Project";
import { Device } from "types/Device";
import useDevice from "utils/hooks/useDevice";
import FacebookFormContainer from "./FacebookFormContainer";

const FacebookAutoEngagement: FC = () => {
  const { t } = useTranslation();
  const { projectId = "" } = useParams<ProjectIdType>();

  const device = useDevice();
  const isMobile = device === Device.MOBILE;

  const { isFacebookAutoEngagementEnabled } = useGetProject(projectId);

  const [isOpenFacebookReserveWordForm, setIsOpenFacebookReserveWordForm] = useState(false);
  const [facebookCrawlerFormId, setFacebookCrawlerFormId] = useState<string>("");

  const handleClickTableRowItem = (id: string) => {
    if (id) {
      setFacebookCrawlerFormId(id);
      setIsOpenFacebookReserveWordForm(true);
    }
  };

  return (
    <Grid container alignContent="flex-start" alignItems="flex-start" className="pb-3">
      {!isOpenFacebookReserveWordForm && (
        <Grid id="FacebookCrawlerTablePageContainer" item container xs={12}>
          <Grid item container xs={12}>
            <Grid item sm={6} xs={8}>
              <PageTitle title={t("facebookAutoEngagement.header")} />
            </Grid>
            <Grid
              item
              sm={6}
              xs={4}
              container
              justify="flex-end"
              alignItems="center"
              alignContent="center"
              className="px-3"
            >
              <Button
                color="primary"
                className="mx-2"
                size="medium"
                onClick={() => {
                  setFacebookCrawlerFormId("");
                  setIsOpenFacebookReserveWordForm(true);
                }}
              >
                <SvgIcon component={IcAdd} fontSize="small" />
                {isMobile ? t("Add") : t("facebookAutoEngagement.createNew")}
              </Button>
            </Grid>
          </Grid>

          {isFacebookAutoEngagementEnabled ? (
            <Grid item xs={12} className="pt-3 px-3">
              <FacebookCrawlerTable onClickTitle={handleClickTableRowItem} />
            </Grid>
          ) : (
            <Grid container justify="center" className="mt-5">
              <IcFacebookComment width="100" height="100" color="#cad2e4" />
            </Grid>
          )}
        </Grid>
      )}

      {isOpenFacebookReserveWordForm && (
        <Grid id="FacebookCrawFormPageContainer" item container xs={12}>
          <ButtonBase
            color="secondary"
            className="ml-3 mt-4 p-2"
            onClick={() => {
              setIsOpenFacebookReserveWordForm(false);
            }}
          >
            <SvgIcon component={IcArrowBack} fontSize="small" />
            {t("Back")}
          </ButtonBase>

          <Grid item xs={12} className="px-3">
            <Divider margin="22px 0 0 0" />
          </Grid>
          <Grid item xs={12} className="pt-1">
            <FacebookFormContainer
              closeFrom={setIsOpenFacebookReserveWordForm}
              facebookCrawlerFormId={facebookCrawlerFormId}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default FacebookAutoEngagement;
