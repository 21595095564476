import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Typography from "components/Typography";

import COLORS from "constants/Colors";

import { Link } from "react-router-dom";
import { Wrapper, Header, Modal, OptionWrapper, Detail, Table } from "./styled";

type Option = { rowRange: string; detail?: string; value?: string; limit?: string };
interface ErrorImportModalPropTypes {
  errorOptions?: Option[];
  isOpen?: boolean;
  handleCloseModal: () => void;
  title?: string;
  details?: string;
  linkWord?: string;
  link?: string;
  suffix?: string;
}

const ErrorImportModal: FC<ErrorImportModalPropTypes> = ({
  errorOptions = [],
  title = "",
  details,
  link,
  suffix,
  linkWord,
  isOpen = false,
  handleCloseModal,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} closeIconColor={COLORS.Dark}>
      <Wrapper>
        <Header>
          <Typography data-testid="title" variant="title3">
            {t(title)}
          </Typography>
        </Header>
        {details && (
          <Detail>
            <Typography variant="body1">
              {t(details)}
              {link && linkWord && (
                <Link to={link}>
                  <span>{t(linkWord)}</span>
                </Link>
              )}
              {suffix && t(suffix)}
            </Typography>
          </Detail>
        )}
        {errorOptions?.length > 0 && (
          <OptionWrapper>
            <Table className="fixed_header">
              <thead>
                <tr>
                  <th>
                    <Typography variant="body1">{t("importProduct.error.row.title")}</Typography>
                  </th>
                  <th>
                    <Typography variant="body1">{t("importProduct.error.row.description")}</Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                {errorOptions?.map(({ rowRange, detail = "", value, limit }) => (
                  <tr key={`key-${rowRange}`}>
                    <td>
                      <Typography variant="body1">{t(rowRange)}</Typography>
                    </td>
                    <td>
                      <Typography variant="body1">{t(detail, { value, limit })}</Typography>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </OptionWrapper>
        )}
      </Wrapper>
    </Modal>
  );
};

export default ErrorImportModal;
