import { useMutation } from "@apollo/client";

import { CREATE_RECURRING_NOTIFICATION_OPT_IN } from "graphql/broadcast/mutation";
import { RECURRING_NOTIFICATION_OPT_INS } from "graphql/broadcast/query";
import {
  CreateRecurringNotificationOptInInputVariables,
  RecurringNotificationOptInInput,
  RecurringNotificationOptInsType,
} from "types/Broadcast";

export default function useCreateRecurringNotiOptIn(projectId: string) {
  const [mutate, { data, loading, error }] = useMutation<
    RecurringNotificationOptInsType,
    CreateRecurringNotificationOptInInputVariables
  >(CREATE_RECURRING_NOTIFICATION_OPT_IN);

  const createRecurringNotiOptIn = (recurringNotificationOptIn: Omit<RecurringNotificationOptInInput, "projectId">) => {
    const variables = {
      projectId,
      recurringNotificationOptIn: {
        ...recurringNotificationOptIn,
        projectId,
      },
    };

    return mutate({
      variables,
      refetchQueries: [{ query: RECURRING_NOTIFICATION_OPT_INS, variables }],
    });
  };

  return {
    createRecurringNotiOptInError: error,
    isLoadingCreateRecurrigNotiOptIn: loading,
    createRecurringData: data,
    createRecurringNotiOptIn,
  };
}
