/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, useMutation } from "@apollo/client";

import CircularProgress from "components/CircularProgress";
import ConfirmationModal from "components/ConfirmationModal";
import Button from "components/Button";
import ButtonBase from "components/ButtonBase";
import Divider from "components/Divider";
import Grid from "components/Grid";
import MessagePreview from "domain/MarketingTools/Broadcast/MessagePreview";
import { SvgIcon } from "components/Icon";
import { IcArrowBack } from "components/SvgIcons";

import { BROADCAST, FACEBOOK, MARKETING_TOOLS, PROJECT } from "constants/Router";

import { RECURRING_NOTIFICATION_OPT_INS } from "graphql/broadcast/query";
import { UPSERT_BROADCAST_CAMPAIGN } from "graphql/broadcast/mutation";

import { BroadcastChannel } from "types/Broadcast";

import { cleanCategoriesMessage } from "utils/message";
import { MAXIMUM_FACEBOOK_BROADCAST_MESSAGES_TOTAL } from "config";

import type {
  BroadcastCampaignDataType,
  RecurringNotificationOptInsResponseType,
  RecurringNotificationOptInsCampaignParamsType,
} from "types/Broadcast";
import type { FetchAudienceVariables } from "./types";

import FormFacebookAudience from "./FormFacebookAudience";
import { getBroadcastName, getInitialBroadcastCampaignData, getSubscriberNumber } from "./helpers";
import { useFetchAudienceNumber } from "../hooks/useFetchAudienceNumber";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "1rem",
  },
  sendBroadcastButton: {
    marginTop: "2rem",
    marginBottom: "4rem",
    [theme.breakpoints.up("lg")]: {
      marginBottom: 0,
    },
  },
}));

export default function BroadcastFacebookCampaign(): JSX.Element {
  // Hooks
  const classes = useStyles();
  const history = useHistory();
  const { projectId, recurringNotificationOptInId } = useParams<RecurringNotificationOptInsCampaignParamsType>();
  const { t } = useTranslation();
  const [audienceNumberValue, setAudienceNumberValue] = useState<number>(0);
  const [isOpenBackConfirmationModal, setIsOpenBackConfirmationModal] = useState<boolean>(false);
  const [isOpenSendConfirmationModal, setIsOpenSendConfirmationModal] = useState<boolean>(false);
  const [broadcastCampaignData, setBroadcastCampaignData] = useState<BroadcastCampaignDataType>(
    getInitialBroadcastCampaignData(projectId),
  );

  // Async hooks
  const { fetchAudienceNumber, audienceNumber, loading: loadingAudienceNumber } = useFetchAudienceNumber();
  const { data: broadcastData, loading: loadingBroadcastData } = useQuery<RecurringNotificationOptInsResponseType>(
    RECURRING_NOTIFICATION_OPT_INS,
    {
      variables: { projectId },
    },
  );

  const [upsertBroadcastCampaign, { loading: loadingUpsertBroadcastCampaign }] = useMutation(
    UPSERT_BROADCAST_CAMPAIGN,
    {
      onError() {
        toast.error(t("Broadcast campaign was saved failed"));
      },
      onCompleted() {
        goToFacebookRecurringNotificationPage();
      },
    },
  );

  // Define variables
  const subscriberNumber = getSubscriberNumber(
    broadcastData?.recurringNotificationOptIns ?? [],
    recurringNotificationOptInId,
  );

  // Define functions
  const goToFacebookRecurringNotificationPage = () => {
    history.push(`/${PROJECT}/${projectId}/${MARKETING_TOOLS}/${BROADCAST}/${FACEBOOK}`);
  };

  const sendBroadcast = async () => {
    if (loadingUpsertBroadcastCampaign) {
      return;
    }

    const cleanObjectCategoriesMessage = cleanCategoriesMessage(broadcastCampaignData.responseMessage);
    const newBroadcastCampaign = { ...broadcastCampaignData };

    delete newBroadcastCampaign.orderPaidConversionTotal;

    console.log({
      ...newBroadcastCampaign,
      responseMessage: cleanObjectCategoriesMessage,
      recurringNotificationOptInId,
    });

    await upsertBroadcastCampaign({
      variables: {
        projectId,
        broadcastCampaign: {
          ...newBroadcastCampaign,
          responseMessage: cleanObjectCategoriesMessage,
          recurringNotificationOptInId,
        },
        isPublish: true,
      },
    });
  };

  // Event handlers
  const handleChangeFormFacebookAudience = (variables: FetchAudienceVariables) => {
    fetchAudienceNumber({ variables });
  };

  const handleClickBackButton = () => {
    setIsOpenBackConfirmationModal(true);
  };

  const handleClickSendBroadcast = () => {
    setIsOpenSendConfirmationModal(true);
  };

  const handleCloseBackConfirmationModal = () => {
    setIsOpenBackConfirmationModal(false);
  };

  const handleSubmitBackConfirmationModal = () => {
    goToFacebookRecurringNotificationPage();
  };

  const handleCloseSendConfirmationModal = () => {
    setIsOpenSendConfirmationModal(false);
  };

  const handleSubmitSendConfirmationModal = async () => {
    await sendBroadcast();
  };

  // Memo hooks
  const isEnableSendbroadcastButton = useMemo(() => {
    return (
      Boolean(broadcastCampaignData.responseMessage.length >= 2) &&
      Boolean(broadcastCampaignData.responseMessage.every((message) => message.value)) &&
      Boolean(broadcastCampaignData.name) &&
      !loadingAudienceNumber &&
      audienceNumberValue > 0
    );
  }, [broadcastCampaignData, audienceNumberValue, loadingAudienceNumber]);

  // Effect hooks
  useEffect(() => {
    fetchAudienceNumber({
      variables: {
        projectId,
        recurringNotificationOptInId,
        channel: BroadcastChannel.FACEBOOK,
        criteria: [
          {
            ...broadcastCampaignData.broadcastCriteria[0],
          },
        ],
      },
    });
  }, []);

  useEffect(() => {
    setAudienceNumberValue(audienceNumber);
  }, [audienceNumber]);

  useEffect(() => {
    if (broadcastData) {
      const broadcastName = getBroadcastName(
        broadcastData?.recurringNotificationOptIns ?? [],
        recurringNotificationOptInId,
      );

      setBroadcastCampaignData({
        ...broadcastCampaignData,
        name: broadcastName,
      });
    }
  }, [broadcastData]);

  // Conditional rendering
  if (loadingBroadcastData) {
    return (
      <Grid container item xs={12} justify="center">
        <CircularProgress className="p-4" />
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ButtonBase color="secondary" className="mt-2" onClick={handleClickBackButton}>
            <SvgIcon component={IcArrowBack} fontSize="small" />
            {t("Back")}
          </ButtonBase>
          <Divider margin="22px 0 0 0" />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormFacebookAudience
            audienceNumber={audienceNumberValue}
            broadcastCampaignData={broadcastCampaignData}
            setBroadcastCampaignData={setBroadcastCampaignData}
            subscriberNumber={subscriberNumber}
            onChange={handleChangeFormFacebookAudience}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <MessagePreview
            projectId={projectId}
            broadcastCampaignData={broadcastCampaignData}
            setBroadcastCampaignData={setBroadcastCampaignData}
            disabledForm={false}
            maxMessage={MAXIMUM_FACEBOOK_BROADCAST_MESSAGES_TOTAL}
          />
          <Button
            className={classes.sendBroadcastButton}
            color="primary"
            disabled={!isEnableSendbroadcastButton}
            fullWidth
            size="medium"
            onClick={handleClickSendBroadcast}
          >
            {t("broadcast.facebook.table.action.sendBroadcast")}
          </Button>
        </Grid>
      </Grid>
      <ConfirmationModal
        isOpen={isOpenBackConfirmationModal}
        title={t("broadcast.facebook.campaign.back.title")}
        subTitle={t("broadcast.facebook.campaign.back.subTitle")}
        textSubmit={t("Confirm")}
        onClose={handleCloseBackConfirmationModal}
        onSubmit={handleSubmitBackConfirmationModal}
        isHiddenCloseBtn
      />
      <ConfirmationModal
        isOpen={isOpenSendConfirmationModal}
        title={t("broadcast.facebook.campaign.send.title")}
        subTitle={t("broadcast.facebook.campaign.send.subTitle")}
        textSubmit={t("Confirm")}
        onClose={handleCloseSendConfirmationModal}
        onSubmit={handleSubmitSendConfirmationModal}
        isHiddenCloseBtn
      />
    </div>
  );
}
