import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";

export type CardPropsType = {
  fullWidth?: boolean;
  width?: number;
  maxWidth?: number;
  height?: number;
  noBorder?: boolean;
  noShadow?: boolean;
  borderRadius?: number | null;
  padding?: string;
  bgColor?: string;
  borderColor?: string;
};

const Wrapper = styled.div<CardPropsType>`
  position: relative;
  border-radius: ${({ borderRadius }) => (Number(borderRadius) >= 0 ? `${borderRadius}px` : "8px")};
  box-shadow: ${({ theme, noShadow }) => (noShadow ? "none" : `0 5px 10px 0 ${theme.COLORS.BoxShadow}`)};
  border: ${({ noBorder, theme, borderColor }) =>
    noBorder ? "none" : `solid 0.5px ${borderColor || theme.COLORS.LightBlue}`};
  background-color: ${({ theme, bgColor }) => bgColor || theme.COLORS.White};
  padding: ${({ padding }) => padding || "16px"};
  min-height: ${({ height }) => (height ? `${height}px` : "auto")};
  min-width: ${({ width }) => (width ? `${width}px` : "auto")};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : "auto")};
  width: ${({ fullWidth }) => (fullWidth ? "fill-available" : "auto")};
`;

const Card: FC<HTMLAttributes<HTMLDivElement> & CardPropsType> = (props) => {
  const { children, className, width, height, noShadow, fullWidth, borderRadius, padding } = props;
  return (
    <Wrapper
      {...props}
      width={width}
      height={height}
      borderRadius={borderRadius}
      className={className}
      noShadow={noShadow}
      fullWidth={fullWidth}
      padding={padding}
    >
      {children}
    </Wrapper>
  );
};

export default Card;
