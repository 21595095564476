import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { MAX_SIZE_IMPORTED_FILE } from "config/product";
import { IMPORT_ORDER_TRACKING_NUMBERS } from "graphql/order/mutation/importOrderTrackingNumbers";
import validateFileSize from "utils/common/validateFileSize";
import { notifyError } from "utils/notify";
import mapErrorMessage from "../mapErrorMessage";

const useImportTrackingNumber = (projectId: string, option: { onCompleted?: () => void }) => {
  const { t } = useTranslation();
  const [importOrderTrackingNumbers, { loading }] = useMutation(IMPORT_ORDER_TRACKING_NUMBERS, {
    onCompleted: option.onCompleted,
  });

  const importTrackingNumber = useCallback(
    async (file: File) => {
      if (file && projectId) {
        const isOverSize = validateFileSize(file, MAX_SIZE_IMPORTED_FILE);

        if (isOverSize) {
          notifyError(t("product.import.error.overSize", { maxSize: MAX_SIZE_IMPORTED_FILE }), { autoClose: 8000 });
          return;
        }

        try {
          await importOrderTrackingNumbers({ variables: { projectId, file } });
        } catch (err) {
          const gqlErrorMessage: string = get(err, "graphQLErrors.0.message");
          const errorMessage = mapErrorMessage(gqlErrorMessage);
          const orderNumbers: string[] | null = get(err, "graphQLErrors.0.extensions.exception.meta.orderNumbers");
          let orderNumbersText = "";

          if (orderNumbers) {
            orderNumbersText = orderNumbers.join("\n");
          }

          notifyError(t(errorMessage, { orderNumbers: orderNumbersText }), { autoClose: 20000 });
        }
      }
    },
    [importOrderTrackingNumbers, projectId, t],
  );

  return { loading, importTrackingNumber };
};

export default useImportTrackingNumber;
