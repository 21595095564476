import { FaqQuestionType, ChangedFaqQuestionType, FaqType } from "types/AISetting";
import { MessageType } from "types/Chat";
import { FAQ_MIN_QUESTION_LENGTH } from "config/faq";

export type GetIsDuplicatedQuestionType = (
  questions: FaqQuestionType[],
  value: string,
  excludedQuestionIndex: number,
) => Boolean;

export const getIsDuplicatedQuestion: GetIsDuplicatedQuestionType = (questions, value, excludedQuestionIndex) => {
  const allQuestions = questions.filter((_question, index) => index !== excludedQuestionIndex);

  return allQuestions.includes(value);
};

export const clearNanoidQuestions = (questions: ChangedFaqQuestionType[]) => {
  return questions.map((question) => question.value.trim()).filter((question) => Boolean(question));
};

export const validateDataFaq = (faqData: FaqType, i18n: Function, isEnableEN: boolean) => {
  const errorMessages: string[] = [];
  const responseWithoutQuickReply = faqData.responses.filter((response) => response.type !== MessageType.QUICK_REPLIES);
  const enResponseWithoutQuickReply = faqData.enResponses.filter(
    (response) => response.type !== MessageType.QUICK_REPLIES,
  );

  if (faqData.questions.length && faqData.questions.length < FAQ_MIN_QUESTION_LENGTH) {
    errorMessages.push(
      `${i18n("form.error.min.unit", {
        name: i18n("Thai questions"),
        length: FAQ_MIN_QUESTION_LENGTH,
        unit: i18n("question", { count: 3 }),
      })}`,
    );
  }

  if (faqData.questions.length && !responseWithoutQuickReply.length) {
    errorMessages.push(`${i18n("form.error.isRequired", { name: i18n("Thai responses") })}`);
  }

  if (!faqData.questions.length && responseWithoutQuickReply.length) {
    errorMessages.push(`${i18n("form.error.isRequired", { name: i18n("Thai questions") })}`);
  }

  if (isEnableEN) {
    if (!faqData.questions.length && !faqData.enQuestions.length) {
      errorMessages.push(
        `${i18n("form.error.isRequired", { name: i18n("questions") })} ${i18n("or")}\n${i18n("form.error.isRequired", {
          name: i18n("English questions"),
        })}`,
      );
    }

    if (faqData.enQuestions.length && faqData.enQuestions.length < FAQ_MIN_QUESTION_LENGTH) {
      errorMessages.push(
        `${i18n("form.error.min.unit", {
          name: i18n("English questions"),
          length: FAQ_MIN_QUESTION_LENGTH,
          unit: i18n("question", { count: 3 }),
        })}`,
      );
    }

    if (!faqData.enQuestions.length && enResponseWithoutQuickReply.length) {
      errorMessages.push(`${i18n("form.error.isRequired", { name: i18n("English questions") })}`);
    }

    if (faqData.enQuestions.length && !enResponseWithoutQuickReply.length) {
      errorMessages.push(`${i18n("form.error.isRequired", { name: i18n("English responses") })}`);
    }
  }

  if (errorMessages.length) {
    const allErrorMessages = errorMessages.join("\n");
    throw new Error(allErrorMessages);
  }
};
