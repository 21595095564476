import React, { FC, useState, useRef } from "react";
import { OptionType, ValueType } from "react-select";
import { useTranslation } from "react-i18next";
import RootRef from "@material-ui/core/RootRef";
import Typography from "components/Typography";
import CreateAbleSelector from "components/TagInputFilter/CustomerSelector/CreateAbleSelector";
import Selector from "components/TagInputFilter/CustomerSelector/Selector";
import { getIsDuplicatedWithOptions } from "components/TagInputFilter/helpers";
import { validateSpecialCharacter, validateSpecialCharacterForEmail } from "utils/common";
import SelectorWrapper from "./SelectorWrapper";
import InputSelectorWrapper from "./InputSelectorWrapper";

type TagInputFilterWithLabelPropsType = {
  label: string;
  limit?: number;
  options?: readonly OptionType[];
  value?: ValueType<OptionType>;
  onChange: (e: ValueType<OptionType>) => void;
  className?: string;
  placeholder?: string;
  isCreateAble?: boolean;
  isEmailAllowed?: boolean;
};

export const TagInputFilter: FC<TagInputFilterWithLabelPropsType> = (props) => {
  const {
    isCreateAble = false,
    isEmailAllowed = false,
    label,
    limit = 0,
    onChange,
    options = [],
    placeholder = "",
    value = [],
  } = props;
  const [selectedValues, setSelectedValues] = useState<ValueType<OptionType>>(value);
  const selectorRef = useRef();

  const { t } = useTranslation();

  const isTagValueReachLimit = selectedValues && limit && (selectedValues as readonly OptionType[]).length >= limit;

  const handleCreateOption = (createdValue: string) => {
    const trimmedValue = createdValue.trim();
    const isTrimmedValueExist = (selectedValues as OptionType[]).find(
      (selectedValueItem: OptionType) => selectedValueItem.value === trimmedValue,
    );
    if (!isTrimmedValueExist) {
      const newOption = {
        label: trimmedValue,
        value: trimmedValue,
      };
      const newSelectedValue = selectedValues ? (selectedValues as OptionType[]).concat([newOption]) : [newOption];
      setSelectedValues(newSelectedValue);
      onChange(newSelectedValue);
    }
  };

  const generateNoOptionsMessage = (input: { inputValue: string }) => {
    const textInput = input.inputValue;
    const hasSpecialCharacter = isEmailAllowed
      ? validateSpecialCharacterForEmail(textInput)
      : validateSpecialCharacter(textInput);
    const isDuplicated = getIsDuplicatedWithOptions(selectedValues as OptionType[], textInput);

    let message = placeholder || t("No option");

    if (isTagValueReachLimit) {
      message = t("you have reach limit");
    } else if (hasSpecialCharacter) {
      message = t("No accept special character");
    } else if (isDuplicated) {
      message = t("already exist");
    }

    return message;
  };

  const handleChangeSelected = (option: ValueType<OptionType>) => {
    setSelectedValues(option || []);
    onChange(option);
  };

  return (
    <RootRef rootRef={selectorRef}>
      <SelectorWrapper>
        <Typography variant="body3" className="py-2 pl-3">
          {label} :
        </Typography>
        <InputSelectorWrapper showUnderline={!options.length}>
          {isCreateAble ? (
            <CreateAbleSelector
              isMulti
              isEmailAllowed
              isSearchable
              noOutline
              options={isTagValueReachLimit ? [] : options}
              value={selectedValues}
              placeholder={placeholder}
              onChange={handleChangeSelected}
              inputRef
              onCreateOption={handleCreateOption}
              noOptionsMessage={generateNoOptionsMessage}
              formatCreateLabel={(val: string) => (isCreateAble ? `${t("Add")} ${val}` : "")}
            />
          ) : (
            <Selector
              isMulti
              isSearchable
              noOutline
              options={isTagValueReachLimit ? [] : options}
              value={selectedValues}
              placeholder={placeholder}
              onChange={handleChangeSelected}
              inputRef
              noOptionsMessage={generateNoOptionsMessage}
            />
          )}
        </InputSelectorWrapper>
      </SelectorWrapper>
    </RootRef>
  );
};

export default TagInputFilter;
