import React, { FC } from "react";
import { TextFieldProps } from "@material-ui/core/TextField";
import { Ref } from "react-hook-form/dist/types";
import { Theme } from "types/Theme";
import TextField from "./TextField.styled";
import PriceField from "./PriceField";
import PasswordField from "./PasswordField";
import NumberField from "./NumberField";
import CreditCardField from "./CreditCardField";
import CreditCardExpiredDateField from "./CreditCardExpiredDateField";
import CreditCardCvvField from "./CreditCardCvvField";

export type DeepleTextFieldProps = {
  isOutlined?: boolean;
  isBoldText?: boolean;
  isSuccess?: boolean;
  theme?: Theme;
  validate?: Ref;
  normalColor?: boolean;
  maxInputLength?: number;
  onClearValue?: () => void;
  inputRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
  onChangeCallBack?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export type TextFieldPropsType = TextFieldProps & DeepleTextFieldProps;

const DeepleTextField: FC<TextFieldPropsType> = (props) => {
  const { type, validate, inputRef, isOutlined } = props;

  if (type === "password") {
    return <PasswordField {...props} isOutlined inputRef={inputRef || (validate as React.Ref<HTMLInputElement>)} />;
  }

  if (type === "price") {
    return <PriceField {...props} isOutlined inputRef={inputRef || (validate as React.Ref<HTMLInputElement>)} />;
  }

  if (type === "formatNumber") {
    return <NumberField {...props} isOutlined inputRef={inputRef || (validate as React.Ref<HTMLInputElement>)} />;
  }

  if (type === "creditCard") {
    return <CreditCardField isOutlined={isOutlined} {...props} />;
  }

  if (type === "creditCardCvv") {
    return (
      <CreditCardCvvField {...props} isOutlined inputRef={inputRef || (validate as React.Ref<HTMLInputElement>)} />
    );
  }

  if (type === "creditCardExpiredDate") {
    return (
      <CreditCardExpiredDateField
        {...props}
        isOutlined
        inputRef={inputRef || (validate as React.Ref<HTMLInputElement>)}
      />
    );
  }

  return (
    <TextField
      {...props}
      isOutlined
      inputRef={inputRef || (validate as React.Ref<HTMLInputElement | HTMLTextAreaElement>)}
    />
  );
};

export default DeepleTextField;
