import React, { FC } from "react";

import { PROJECT, CONNECT, STORE_SETTINGS } from "constants/Router";
import { InfoItemType } from "types/PlanUsage";

import InfoItemList from "../InfoItemList";

type ChannelPropsType = {
  data: InfoItemType[];
  limit: number;
  projectId: string;
};

const Channel: FC<ChannelPropsType> = (props) => {
  const { data = [], limit, projectId } = props;

  return (
    <InfoItemList
      data={data}
      limit={limit}
      title="planUsage.title.channel"
      manageRoute={`/${PROJECT}/${projectId}/${STORE_SETTINGS}/${CONNECT}`}
    />
  );
};

export default Channel;
