import React, { FC, useState } from "react";
import { Grid } from "@material-ui/core";
import { IcClose } from "components/SvgIcons";
import Typography from "components/Typography";
import { BUSINESS_ATTACHMENT__EXAMPLE, ID_CARD_EXAMPLE, PASSBOOK__EXAMPLE } from "constants/Image";
import COLORS from "constants/Colors";
import { useTranslation } from "react-i18next";
import {
  CloseIcon,
  ExampleDescription,
  ExampleImageBox,
  ExampleImageWrapper,
  ExampleSideBar,
  MobileImageCountBox,
} from "./styled";

export interface AttachmentExamplePropsType {
  type: "bankAccount" | "personal" | "business";
  setIsOpenAttachmentExample: (value: boolean) => void;
  isMobile: boolean;
}

const AttachmentExample: FC<AttachmentExamplePropsType> = ({ setIsOpenAttachmentExample, isMobile, type }) => {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(0);
  const getItems = (type: "bankAccount" | "personal" | "business") => {
    switch (type) {
      case "bankAccount":
        return {
          title: "deeplePay.verifyBilling.bankingInfo.example.title",
          description: "deeplePay.verifyBilling.example.description",
          images: PASSBOOK__EXAMPLE,
        };
      case "personal":
        return {
          title: "deeplePay.verifyBilling.personalInfo.individual.example.title",
          description: "deeplePay.verifyBilling.example.description",
          images: ID_CARD_EXAMPLE,
        };
      case "business":
        return {
          title: "deeplePay.verifyBilling.personalInfo.corporate.example.title",
          description: "deeplePay.verifyBilling.example.description",
          images: BUSINESS_ATTACHMENT__EXAMPLE,
        };
      default:
        return {
          title: "deeplePay.verifyBilling.bankingInfo.example.title",
          description: "deeplePay.verifyBilling.example.description",
          images: PASSBOOK__EXAMPLE,
        };
    }
  };

  return isMobile ? (
    <Grid container justify="flex-start" className={isMobile ? "w-100 px-6" : "h-100 w-100"}>
      <ExampleDescription className={isMobile ? "w-100" : ""}>
        <CloseIcon onClick={() => setIsOpenAttachmentExample(false)} component={IcClose} fontSize="large" />
        <Grid className="w-100" justify="space-between">
          <Typography variant="title7">{t(getItems(type).title)}</Typography>
        </Grid>
        <Typography className="pt-2" variant="body2">
          {t(getItems(type).description)}
        </Typography>
        {getItems(type).images.map((value, i) => (
          <MobileImageCountBox key={value}>
            <ExampleImageWrapper key={value}>
              <img alt={value} src={value} />
            </ExampleImageWrapper>
            <Typography className="pt-2" color={COLORS.DarkGray} variant="body3">
              {i + 1}/{getItems(type).images.length}
            </Typography>
          </MobileImageCountBox>
        ))}
      </ExampleDescription>
    </Grid>
  ) : (
    <Grid container justify="flex-start" className={isMobile ? "h-100 w-100 py-4 px-6" : "h-100 w-100"}>
      <ExampleSideBar>
        <Grid justify="center">
          <Typography className="pb-4" color={COLORS.DarkGray} variant="body3">
            {selectedImage + 1}/{getItems(type).images.length}
          </Typography>
          {getItems(type).images.map((value, i) => (
            <ExampleImageBox
              className={selectedImage === i ? "selected" : ""}
              key={`img-${value}`}
              onClick={() => setSelectedImage(i)}
            >
              <img alt="img" src={value} />
            </ExampleImageBox>
          ))}
        </Grid>
      </ExampleSideBar>
      <ExampleDescription>
        <CloseIcon onClick={() => setIsOpenAttachmentExample(false)} component={IcClose} fontSize="large" />
        <Grid className="w-100" justify="space-between">
          <Typography variant="title7">{t(getItems(type).title)}</Typography>
        </Grid>
        <Typography className="pt-2" variant="body2">
          {t(getItems(type).description)}
        </Typography>
        <ExampleImageWrapper>
          <img alt={getItems(type).images[selectedImage]} src={getItems(type).images[selectedImage]} />
        </ExampleImageWrapper>
      </ExampleDescription>
    </Grid>
  );
};

export default AttachmentExample;
