import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { NOTIFICATION_UPDATE } from "constants/Notification";
import { ALL_CATEGORY } from "graphql/category/query";
import { UPDATE_CATEGORIES_PRIORITY } from "graphql/category/mutation";
import { CategoryConnectionType, CategoryType } from "types/Category";
import { notifyError, notifySuccess } from "utils/notify";

type UpdatedCategoryPriority = { updateCategoryPriority: CategoryType[] };

const useUpdateCategoryPriority = (projectId: string, categories: CategoryType[]) => {
  const { t } = useTranslation();
  const [updateCategoryPriority] = useMutation<UpdatedCategoryPriority, { projectId: string; categoryIds: string[] }>(
    UPDATE_CATEGORIES_PRIORITY,
    {
      update(cache, { data }) {
        const cacheCategory = cache.readQuery<CategoryConnectionType>({
          query: ALL_CATEGORY,
          variables: { projectId },
        });

        if (!data) {
          return;
        }

        const { updateCategoryPriority: updatedCategoryPriority } = data;

        if (cacheCategory) {
          cache.writeQuery({
            query: ALL_CATEGORY,
            variables: { projectId },
            data: {
              ...cacheCategory,
              categories: {
                ...cacheCategory.categories,
                total: cacheCategory.categories.total,
                results: [...updatedCategoryPriority],
              },
            },
          });
        }
      },
      onCompleted: () => notifySuccess(t("categories.priority")),
      onError: () => notifyError(t(NOTIFICATION_UPDATE.FAIL)),
    },
  );

  const sortCategoriesByIds = (categories: CategoryType[], ids: string[]) => {
    const results: CategoryType[] = [];
    categories.forEach((category) => {
      results[ids.indexOf(category.id)] = category;
    });

    return results;
  };

  const handleUpdateCategoryPriority = (newCategoryIds: string[]) => {
    const newCategoriesByIds = sortCategoriesByIds(categories, newCategoryIds);

    updateCategoryPriority({
      variables: { projectId, categoryIds: newCategoryIds },
      optimisticResponse: {
        updateCategoryPriority: newCategoriesByIds,
      },
    });
  };

  return { handleUpdateCategoryPriority };
};

export default useUpdateCategoryPriority;
