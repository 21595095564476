const search = {
  en: {
    "searchPlaceholder.orderSearchFilter": "Search order no., customer name",
    "searchPlaceholder.aiSpecialedKnowledge": "Search keyword in question set",
    "searchPlaceholder.aiReplyShortcut": "Search name, description",
    "searchPlaceholder.aiSynonymProductName": "Search name",
    "searchPlaceholder.aiSynonymProductBrand": "Search brand",
    "searchPlaceholder.aiSynonymCategory": "Search category",
    "searchPlaceholder.aiSynonymSkuType": "Search SKU",
    "searchPlaceholder.aiSynonymPropertyType": "Search property",
    "searchPlaceholder.productInventory": "Search product name",
    "searchPlaceholder.marketingToolsCampaigns": "Search campaign name",
    "searchPlaceholder.marketingToolsTopSelling": "Search product name",
    "searchPlaceholder.storeSettingsMembers": "Search name, email",
    "searchPlaceholder.userSettingsNotifications": "Search customer name",
    "searchPlaceholder.marketingToolsAutoEngagement": "Search post title",
    "searchPlaceholder.marketingToolsFacebookLive": "Search Facebook live title",
  },
  th: {
    "searchPlaceholder.orderSearchFilter": "ค้นหาเลขออเดอร์, ชื่อลูกค้า",
    "searchPlaceholder.aiSpecialedKnowledge": "ค้นหาคีย์เวิร์ดในชุดคำถาม",
    "searchPlaceholder.aiReplyShortcut": "ค้นหาชื่อ, รายละเอียด",
    "searchPlaceholder.aiSynonymProductName": "ค้นหาชื่อสินค้า",
    "searchPlaceholder.aiSynonymProductBrand": "ค้นหายี่ห้อสินค้า",
    "searchPlaceholder.aiSynonymCategory": "ค้นหาเมนูสินค้า",
    "searchPlaceholder.aiSynonymSkuType": "ค้นหาตัวเลือกสินค้า (SKU)",
    "searchPlaceholder.aiSynonymPropertyType": "ค้นหาคุณสมบัติของสินค้า",
    "searchPlaceholder.productInventory": "ค้นหาชื่อสินค้า",
    "searchPlaceholder.marketingToolsCampaigns": "ค้นหาชื่อแคมเปญ",
    "searchPlaceholder.marketingToolsTopSelling": "ค้นหาชื่อสินค้า",
    "searchPlaceholder.storeSettingsMembers": "ค้นหาชื่อ, อีเมล",
    "searchPlaceholder.userSettingsNotifications": "ค้นหาชื่อลูกค้า",
    "searchPlaceholder.marketingToolsAutoEngagement": "ค้นหาชื่อโพสต์",
    "searchPlaceholder.marketingToolsFacebookLive": "ค้นหาชื่อเฟซบุ๊กไลฟ์",
  },
};

export default search;
