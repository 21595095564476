import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import moment from "moment";
import get from "lodash/get";

import { DATE_INVOICE_FORMAT, DATE_TIME_PICKER_FORMAT } from "constants/DateTimeFormat";
import { CURRENT_USAGE } from "graphql/plan/query";
import { Member } from "types/Setting";
import { ProjectIdType } from "types/Project";
import {
  ChannelType,
  CurrentUsageQueryType,
  PackageEntityType,
  PlanUsageDetailType,
  SubscriptionType,
} from "types/PlanUsage";
import { convertPriceFormat } from "utils/common";
import useGetProject from "utils/hooks/useGetProject";
import { IS_ALLOW_NEW_PACKAGE } from "config";
import { EntityLevel } from "types/Billing";

const useUsageData = (projectId: string) => {
  const { t } = useTranslation();
  const { loading, data } = useQuery<CurrentUsageQueryType, ProjectIdType>(CURRENT_USAGE, {
    variables: {
      projectId,
    },
  });
  const { isUsingCustomPackage, isUsingDeeplePay } = useGetProject(projectId);

  if (data && data.currentUsage) {
    const {
      currentBillingDate,
      nextBillingDate,
      ccommercePackage,
      imageSearchPackage,
      mainPackage,
      lineMessageUsage,
      lineMessageQuota,
      lineLastFetchMessageTimestamp,
      manualApproveOrderUsage,
    } = data.currentUsage;
    const {
      members,
      memberQuota,
      channels,
      channelQuota,
      entityLevel,
      subscriberQuota,
      subscriberUsage,
      messageQuota,
      messageUsage,
      faqQuota,
      faqUsage,
      broadcastQuota,
      broadcastUsage,
      subscriptionType,
    } = mainPackage;

    const orderUsage = get(mainPackage, "orderUsage") || get(ccommercePackage, "orderUsage") || 0;
    const orderQuota = get(mainPackage, "orderQuota") || get(ccommercePackage, "orderQuota") || 0;
    const productUsage = get(mainPackage, "productUsage") || get(ccommercePackage, "productUsage") || 0;
    const productQuota = get(mainPackage, "productQuota") || get(ccommercePackage, "productQuota") || 0;

    let mainPackageUsageDetails: PlanUsageDetailType[] = [
      {
        label: "subscriber",
        value: subscriberUsage,
        limit: subscriberQuota,
      },
      {
        label: "intent",
        value: faqUsage,
        limit: faqQuota,
      },
      {
        label: "order",
        value: orderUsage,
        limit: orderQuota,
      },
      {
        label: "sku",
        value: productUsage,
        limit: productQuota,
      },
      {
        label: "message",
        value: messageUsage,
        limit: messageQuota,
      },
    ];

    // filter order usage section when using deeple pay package
    if (isUsingDeeplePay) {
      mainPackageUsageDetails = mainPackageUsageDetails.filter(
        (usageDetail: PlanUsageDetailType) => usageDetail.label !== "order",
      );
    }

    if (broadcastQuota) {
      const broadcastUsageDetail = {
        label: "broadcast",
        value: broadcastUsage,
        limit: broadcastQuota,
      };
      mainPackageUsageDetails = [...mainPackageUsageDetails, broadcastUsageDetail];
    }

    const mainPackageName = isUsingCustomPackage
      ? t("CUSTOM_PACKAGE")
      : t(IS_ALLOW_NEW_PACKAGE ? "planUsage.label.name.newPackage.mainPackage" : "planUsage.label.name.mainPackage", {
          level: t(`packageLevel.${entityLevel && entityLevel.toLowerCase()}`),
          subscriptionType:
            subscriptionType === SubscriptionType.ANNUALLY ? ` - ${t("planUsage.subscriptionType.annually")}` : "",
          orderQuota: productQuota === -1 ? t("planUsage.label.unlimited") : convertPriceFormat(productQuota, 0),
          subscriberQuota: convertPriceFormat(subscriberQuota, 0),
        });

    const usageData = {
      mainPackage: {
        name: mainPackageName,
        details: mainPackageUsageDetails,
      },
      manualApproveOrder: isUsingDeeplePay && {
        name: "",
        details: [
          {
            label: "manualApproveOrder",
            value: manualApproveOrderUsage,
            limit: orderQuota,
          },
        ],
      },
      imageSearchAddon: imageSearchPackage && {
        name: imageSearchPackage.name,
        details: [
          {
            label: "imageSearch",
            value: imageSearchPackage.imageSearchUsage,
            limit: imageSearchPackage.imageSearchQuota,
          },
        ],
      },
      lineMessage: {
        name: "",
        details: [
          {
            label: "lineMessage",
            value: lineMessageUsage,
            limit: lineMessageQuota,
            lastUpdated: lineLastFetchMessageTimestamp
              ? moment(lineLastFetchMessageTimestamp).format(DATE_TIME_PICKER_FORMAT)
              : "-",
          },
        ],
      },
    };

    const teamMemberData = members.map(({ user, email }: Member) => {
      const { displayName } = user;

      return {
        title: displayName,
        description: email,
      };
    });

    const channelData = channels.map(({ platform, name }: ChannelType) => ({
      title: platform,
      description: name,
    }));

    const isFreePackage =
      mainPackage.entityLevel === EntityLevel.FREE && mainPackage.entityType === PackageEntityType.BASIC;

    const usagePeriod = isFreePackage
      ? "-"
      : `${moment(currentBillingDate).format(DATE_INVOICE_FORMAT)} - ${moment(nextBillingDate).format(
          DATE_INVOICE_FORMAT,
        )}`;

    return {
      usagePeriod,
      currentUsagePlans: usageData,
      isLoading: loading,
      teamMember: {
        details: teamMemberData,
        quota: memberQuota,
      },
      channel: {
        details: channelData,
        quota: channelQuota,
      },
    };
  }

  const defaultData = {
    details: [],
    quota: 0,
  };

  return {
    usagePeriod: "",
    currentUsagePlans: null,
    isLoading: loading,
    teamMember: defaultData,
    channel: defaultData,
  };
};

export default useUsageData;
