export enum ButtonColors {
  SECONDARY = "secondary",
  GHOST = "ghost",
  GHOST_WHITE = "ghostWhite",
  DARK_GRAY = "darkGray",
  PRIMARY = "primary",
  ERROR = "error",
  NEW_PRIMARY = "newPrimary",
  TEXT_ERROR = "textError",
  LINK = "link",
}

export enum ButtonSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}
