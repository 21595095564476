import React, { FC } from "react";
import { useParams, Switch, Redirect, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  CONNECT,
  FACEBOOK_PERSISTENT_MENU,
  INFORMATION,
  INSTAGRAM_PERSISTENT_MENU,
  LINE_RICH_MENU,
  MEMBERS,
  OFFLINE_STORE,
  ORDER_SETTING,
  PAYMENT,
  PROJECT,
  STORE_SETTINGS,
  THEME_CONFIG,
  VAT,
} from "constants/Router";
import PrivateRoute from "routers/PrivateRoute";
import Payment from "domain/Payment";
import Vat from "domain/Vat";
import { StoreMembers } from "domain/Member";
import NewStoreConnect from "domain/Store/StoreConnect/NewStoreConnect";
import OrderSetting from "domain/Setting/OrderSetting";
import ThemeShopConfigContainer from "domain/Store/ThemeShopConfig";
import Branch from "domain/Store/Branch";
import { DisableFeature } from "pages/DisableFeature";
import FacebookPersistentMenu from "pages/FacebookPersistentMenu";
import InstagramPersistentMenu from "pages/InstagramPersistentMenu";
import Information from "domain/Store/Information";
import { NotFound } from "pages/NotFound";
import { TABS_OWNER_CONFIG, TABS_ADMIN_CONFIG } from "pages/StoreSetting/tabsConfig";
import LineRichMenu from "pages/LineRichMenu";
import useGetUserWithPermission from "utils/hooks/useGetUserWithPermission";
import useGetProject from "utils/hooks/useGetProject";
import useTabWithLink from "utils/hooks/useTabWithLink";
import { isRunningOnMobileApp } from "utils/mobileUtil";
import { ProjectIdType } from "types/Project";

const StoreSettingSubRoute: FC = () => {
  const { projectId = "" } = useParams<ProjectIdType>();
  const { isCustomThemeConfigurationEnabled } = useGetProject(projectId);
  const { t } = useTranslation();
  const { loading, isOwnerRole, isOwner, isAdmin } = useGetUserWithPermission(projectId);
  const allowedTabConfigs = isOwnerRole ? TABS_OWNER_CONFIG : TABS_ADMIN_CONFIG;

  const { rootPath, defaultTabPath } = useTabWithLink(allowedTabConfigs, STORE_SETTINGS, projectId);
  const isMobileApp = isRunningOnMobileApp();

  return (
    <Switch>
      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${INFORMATION}`}
        isAllowed={isOwner || isAdmin}
        redirectTo="/"
      >
        <Information projectId={projectId} />
      </PrivateRoute>

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${OFFLINE_STORE}`}
        isAllowed={isOwner || isAdmin}
        redirectTo="/"
        component={Branch}
      />

      {isCustomThemeConfigurationEnabled ? (
        <PrivateRoute
          exact
          loading={loading}
          path={`${rootPath}/${THEME_CONFIG}`}
          isAllowed={isOwner}
          redirectTo="/"
          component={ThemeShopConfigContainer}
        />
      ) : (
        <PrivateRoute exact loading={loading} path={`${rootPath}/${THEME_CONFIG}`} isAllowed={isOwner} redirectTo="/">
          <DisableFeature>
            <ThemeShopConfigContainer />
          </DisableFeature>
        </PrivateRoute>
      )}

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${THEME_CONFIG}`}
        isAllowed={isOwner}
        redirectTo="/"
        component={ThemeShopConfigContainer}
      />

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${THEME_CONFIG}/${LINE_RICH_MENU}`}
        isAllowed={isOwner}
        redirectTo="/"
        title={`${t("Add")} Line rich menu`}
      >
        <LineRichMenu />
      </PrivateRoute>

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${THEME_CONFIG}/${FACEBOOK_PERSISTENT_MENU}`}
        isAllowed={isOwner}
        redirectTo="/"
        title={`${t("Add")} facebook persistent menu`}
      >
        <FacebookPersistentMenu />
      </PrivateRoute>

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${THEME_CONFIG}/${FACEBOOK_PERSISTENT_MENU}/:chatMenuId`}
        isAllowed={isOwner}
        redirectTo="/"
        title={`${t("Edit")} facebook persistent menu`}
      >
        <FacebookPersistentMenu />
      </PrivateRoute>

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${THEME_CONFIG}/${LINE_RICH_MENU}/:chatMenuId`}
        isAllowed={isOwner}
        redirectTo="/"
        title={`${t("Edit")} Line rich menu`}
      >
        <LineRichMenu />
      </PrivateRoute>

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${THEME_CONFIG}/${INSTAGRAM_PERSISTENT_MENU}`}
        isAllowed={isOwner}
        redirectTo="/"
        title={`${t("Add")} instagram persistent menu`}
      >
        <InstagramPersistentMenu />
      </PrivateRoute>

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${THEME_CONFIG}/${INSTAGRAM_PERSISTENT_MENU}/:chatMenuId`}
        isAllowed={isOwner}
        redirectTo="/"
        title={`${t("Edit")} instagram persistent menu`}
      >
        <InstagramPersistentMenu />
      </PrivateRoute>

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${ORDER_SETTING}`}
        isAllowed={isOwner}
        redirectTo="/"
        component={OrderSetting}
      />

      {!isMobileApp && (
        <PrivateRoute
          exact
          loading={loading}
          path={`${rootPath}/${PAYMENT}`}
          isAllowed={isOwner}
          redirectTo="/"
          component={Payment}
        />
      )}

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${VAT}`}
        isAllowed={isOwner}
        redirectTo="/"
        component={Vat}
      />

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${MEMBERS}`}
        isAllowed={isOwner || isAdmin}
        redirectTo="/"
        component={StoreMembers}
      />

      {!isMobileApp && (
        <PrivateRoute
          exact
          loading={loading}
          path={`${rootPath}/${CONNECT}`}
          isAllowed={isOwner}
          redirectTo="/"
          component={NewStoreConnect}
        />
      )}

      <PrivateRoute exact loading={loading} path={`${rootPath}`} isAllowed={isAdmin} redirectTo="/">
        <Redirect
          to={defaultTabPath ? `/${PROJECT}/${projectId}/${STORE_SETTINGS}/${defaultTabPath}` : `/${PROJECT}`}
        />
      </PrivateRoute>

      <Route path={`${rootPath}/*`}>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default StoreSettingSubRoute;
