const replyMessageInput = {
  en: {
    "upload.document.popover": "Please upgrade plan to use this feature",
  },
  th: {
    "upload.document.popover": "กรุณาอัปเกรดแพลน<br>เพื่อเปิดใช้งานฟีเจอร์นี้",
  },
};

export default replyMessageInput;
