const errorMessage = {
  en: {
    "This email has already been taken": "This email has already been taken",
    "Incorrect password": "Incorrect password",
    "Email is not found": "Email is not found",
    "Invalid username or password": "Invalid username or password",
    "Invalid token": "Invalid token",
    Unauthorized: "Unauthorized",
    "Your account is already verified": "Your account is already verified",
    "not found": "not found",
    "Network error please try again later": "Network error please try again later",
    "Please try again later": "Please try again later",
    "This field is required": "This field is required",
    ERROR_WAIT_AND_TRY_AGAIN: "Please wait for a few minutes and try again",
    CANNOT_CANCEL_LALAMOVE: "Cannot cancel LALAMOVE if you want to cancel please contact LALAMOVE support team",
    "Product.createProperty.fail": "Cannot create existing property",
    "Product.createProperty.error.emptyText": "Property cannot be empty",
    PLEASE_TRY_AGAIN: "Please try again later",
    NETWORK_ERROR: "Network error please try again later",
  },
  th: {
    "This email has already been taken": "อีเมลนี้ถูกใช้ไปแล้ว",
    "Incorrect password": "รหัสผ่านของคุณผิด",
    "Email is not found": "ไม่พบอีเมลนี้",
    "Invalid username or password": "บัญชีผู้ใช้ หรือ รหัสผ่านอาจจะผิด",
    "Invalid token": "คำขอถูกปฎิเสธ",
    Unauthorized: "ต้องเข้าสู่ระบบก่อน",
    "Your account is already verified": "บัญชีของคุณได้รับการยืนยันแล้ว",
    "not found": "ไม่พบ",
    "Network error please try again later": "เครือข่ายล้มเหลว กรุณาลองใหม่อีกครั้ง",
    "Please try again later": "กรุณาลองใหม่อีกครั้ง",
    "This field is required": "กรุณใส่ข้อมูลให้ครบ",
    ERROR_WAIT_AND_TRY_AGAIN: "กรุณาลองใหม่อีกครั้งในภายหลัง",
    CANNOT_CANCEL_LALAMOVE:
      "ไม่สามารถยกเลิก Lalamove ได้ ถ้าต้องการที่จะยกเลิกกรุณาติดต่อ ฝ่ายบริการลูกค้าของ Lalamove",
    "Product.createProperty.fail": "ไม่สามารถสร้างคุณสมบัติซ้ำได้",
    "Product.createProperty.error.emptyText": "กรุณาเพิ่มคุณสมบัติ",
    PLEASE_TRY_AGAIN: "กรุณาลองใหม่อีกครั้ง",
    NETWORK_ERROR: "เครือข่ายล้มเหลว กรุณาลองใหม่อีกครั้ง",
  },
};

export default errorMessage;
