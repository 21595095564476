import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import Grid from "components/Grid";
import Card from "components/Card";
import Typography from "components/Typography";
import TextField from "components/TextField";

const CredentialForm: FC = () => {
  const { t } = useTranslation();
  const { errors, register } = useFormContext();

  return (
    <Card noShadow padding="24px" fullWidth>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="title2" color="dark">
            {t("inventoryIntegration.credentialForm.title")}
          </Typography>
        </Grid>
        <Grid item xs={12} className="pt-4">
          <Typography variant="body3" color="darkMed" className="pb-2">
            {t("inventoryIntegration.credentialForm.storeName.label")}
          </Typography>
          <TextField
            name="storeName"
            validate={register}
            fullWidth
            variant="outlined"
            placeholder={t("inventoryIntegration.credentialForm.storeName.placeholder")}
            error={Boolean(errors.storeName)}
            helperText={t(errors?.storeName?.message || "")}
          />
        </Grid>
        <Grid item xs={12} className="pt-4">
          <Typography variant="body3" color="darkMed" className="pb-2">
            {t("inventoryIntegration.credentialForm.apiKey.label")}
          </Typography>
          <TextField
            name="apiKey"
            validate={register}
            fullWidth
            variant="outlined"
            placeholder={t("inventoryIntegration.credentialForm.apiKey.placeholder")}
            error={Boolean(errors.apiKey)}
            helperText={t(errors?.apiKey?.message || "")}
          />
        </Grid>
        <Grid item xs={12} className="pt-4">
          <Typography variant="body3" color="darkMed" className="pb-2">
            {t("inventoryIntegration.credentialForm.apiSecret.label")}
          </Typography>
          <TextField
            name="apiSecret"
            validate={register}
            fullWidth
            variant="outlined"
            placeholder={t("inventoryIntegration.credentialForm.apiSecret.placeholder")}
            error={Boolean(errors.apiSecret)}
            helperText={t(errors?.apiSecret?.message || "")}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default CredentialForm;
