import { useQuery } from "@apollo/client";

import { NOTIFICATION_CONFIGS } from "graphql/notification/query";
import { NotificationConfigurationQueryType } from "types/Notification";

type UseNotificationConfigurationType = {
  projectId: string;
  userId: string;
};

export const useNotificationConfiguration = (props: UseNotificationConfigurationType) => {
  const { projectId, userId } = props;

  const { data, loading } = useQuery<NotificationConfigurationQueryType, UseNotificationConfigurationType>(
    NOTIFICATION_CONFIGS,
    {
      variables: {
        projectId,
        userId,
      },
    },
  );

  const notificationConfigurations = data?.notificationConfigurations || [];

  return { isLoading: loading, notificationConfigurations };
};
