import { gql } from "@apollo/client";

export const UPDATE_WELCOME_MESSAGE = gql`
  mutation($projectId: ID!, $messages: [InputGreetingMessage]!) {
    updateGreetingMessage(projectId: $projectId, messages: $messages) {
      type
      value
    }
  }
`;

export const DELETE_FAQ = gql`
  mutation deleteFaq($projectId: ID!, $faqId: ID!) {
    deleteFaq(projectId: $projectId, faqId: $faqId) {
      id
    }
  }
`;

export const CREATE_FAQ = gql`
  mutation createFaq($projectId: ID!, $faqData: FaqDataInput!) {
    createFaq(projectId: $projectId, faqData: $faqData) {
      id
      questions
      enQuestions
      responses {
        type
        value
      }
      enResponses {
        type
        value
      }
      isActive
      isEnabledNotification
    }
  }
`;

export const UPDATE_FAQ = gql`
  mutation updateFaq($projectId: ID!, $faqId: ID!, $faqData: FaqDataInput!) {
    updateFaq(projectId: $projectId, faqId: $faqId, faqData: $faqData) {
      id
      questions
      enQuestions
      responses {
        type
        value
      }
      enResponses {
        type
        value
      }
      isActive
      isEnabledNotification
    }
  }
`;

export const TRAIN_FAQ = gql`
  mutation($projectId: ID!) {
    createTrainJob(projectId: $projectId) {
      id
      status
      timestamp
    }
  }
`;

export const VALIDATE_FAQ = gql`
  mutation validateFaq($projectId: ID!, $faqId: ID!, $questions: [String]!) {
    validateFaq(projectId: $projectId, faqId: $faqId, questions: $questions) {
      id
      score
      duplicateQuestions
    }
  }
`;
