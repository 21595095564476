import styled from "styled-components";

export const ProjectName = styled.div`
  max-width: 130px;
  word-break: break-all;
`;

export const Wrapper = styled.div<{
  isDesktop: boolean;
}>`
  max-height: ${({ isDesktop }) => (isDesktop ? "calc(100vh - 260px)" : "250px")};
  overflow-y: auto;
`;
