import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { parseISO, isBefore } from "date-fns";

import Grid from "components/Grid";
import Typography from "components/Typography";
import { SvgIcon } from "components/Icon/index";
import { IcDownload } from "components/SvgIcons";
import { DeeplePayFeeInvoice } from "types/DeeplePay";
import { DEEPLE_PAY_FEE_INVOICE_DATE_FORMAT } from "constants/DateTimeFormat";
import { formatDateFromISOString } from "utils/date";
import { convertPriceFormat } from "utils/common";
import { DownloadPDFButton, InvoiceItemCard, InvoiceNumberText } from "./styled";

interface InvoiceItemPropTypes {
  deeplePayFeeInvoice: DeeplePayFeeInvoice;
  handleDownloadPDF: () => void;
  isMobile: boolean;
}

const DeeplePayFeeInvoiceItem: FC<InvoiceItemPropTypes> = (props) => {
  const { t } = useTranslation();
  const { deeplePayFeeInvoice, handleDownloadPDF, isMobile } = props;

  const { invoiceNumber, amount, path, createdAt } = deeplePayFeeInvoice;

  const isFutureInvoice = isBefore(new Date(), parseISO(createdAt));

  return (
    <InvoiceItemCard
      // some invoice does not have id, so we use combination of month and year as key
      key={`invoice-item-${formatDateFromISOString(createdAt, "MM-yyyy")}`}
      container
      alignItems="center"
      className="py-3 px-4 table-item"
    >
      <Grid item xs={4} className="item">
        <Typography variant="body3" color="darkGray">
          {formatDateFromISOString(createdAt, DEEPLE_PAY_FEE_INVOICE_DATE_FORMAT)}
        </Typography>
        {invoiceNumber && (
          <InvoiceNumberText className={isMobile ? "native" : ""} variant="body4" color="darkLight">
            {invoiceNumber}
          </InvoiceNumberText>
        )}
      </Grid>
      <Grid item xs={4} className="item">
        <Typography variant="body3" color="darkGray" align="left">
          {isFutureInvoice && <>-</>}
          {!isFutureInvoice && (
            <>
              {t("THB")} {convertPriceFormat(amount || 0)}
            </>
          )}
        </Typography>
      </Grid>
      <Grid item xs={4} className="item">
        {path ? (
          <Grid container alignContent="flex-end" alignItems="center" justify={isMobile ? "flex-end" : "flex-start"}>
            <DownloadPDFButton onClick={handleDownloadPDF} className={path ? "" : "disabled"}>
              <Typography variant="body3" color="darkGray">
                <SvgIcon viewBox="-4 0 24 24" component={IcDownload} fontSize="small" />
                {t("deeplePay.feeInvoice.pdf")}
              </Typography>
            </DownloadPDFButton>
          </Grid>
        ) : (
          <Typography variant="body4" color="darkLight" className={isMobile ? "" : "text-left"}>
            {t("deeplePay.feeInvoice.noAttachment")}
          </Typography>
        )}
      </Grid>
    </InvoiceItemCard>
  );
};

export default DeeplePayFeeInvoiceItem;
