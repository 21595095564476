import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Grid from "components/Grid";
import CustomerImage from "components/CustomerImage";
import Typography from "components/Typography";
import Tooltip from "components/Tooltip";
import { NotificationInfo } from "types/Notification";
import { UnreadItemBadge, LinkWrapper, GridItem } from "./styled";

type NotificationListItemPropsType = {
  notificationInfo: NotificationInfo;
  onClickMarkResponse: (id: string) => void;
  handleClose?: () => void;
  isDisplayDescription?: boolean;
};

const NotificationListItem: React.FC<NotificationListItemPropsType> = (props) => {
  const { t } = useTranslation();

  const { notificationInfo, onClickMarkResponse, handleClose, isDisplayDescription = false } = props;
  const currentMessageTime = moment(Number(notificationInfo?.createdAt));
  const today = moment();
  const isSameDay = moment(currentMessageTime).isSame(today, "day");
  const relativeDate = isSameDay ? moment(currentMessageTime).fromNow() : currentMessageTime.calendar();

  return (
    <GridItem container alignItems="center" className="flex-nowrap py-2 px-2">
      <Grid item className="flex-grow-0 mr-3">
        <CustomerImage
          size="48px"
          src={notificationInfo?.customer?.pictureUrl}
          chatPlatform={notificationInfo?.customer?.platform}
        />
      </Grid>

      <Grid item container className="flex-grow-1 h-100" alignContent="center" alignItems="center">
        <LinkWrapper
          to={notificationInfo?.payload?.action || "#"}
          onClick={() => {
            onClickMarkResponse(notificationInfo?.id);
            if (handleClose) {
              handleClose();
            }
          }}
        >
          <Grid item container xs={12} alignContent="center" alignItems="center">
            <Grid item>
              <Typography className="d-inline" variant="title8" color="dark">
                {`${notificationInfo?.customer?.name || ""} `}
              </Typography>
              <Typography className="d-inline text-lowercase" variant="body3" color="darkGray">
                {notificationInfo?.payload?.title}
              </Typography>
            </Grid>
          </Grid>
          {isDisplayDescription && (
            <Grid item xs={12} className="mt-1">
              <Typography variant="body3">{notificationInfo?.payload?.body || ""}</Typography>
            </Grid>
          )}
          <Grid item xs={12} className="mt-1">
            <Typography variant="body4" color="darkLight">
              {relativeDate}
            </Typography>
          </Grid>
        </LinkWrapper>
      </Grid>
      <Grid item className="flex-grow-0" style={{ width: 20 }}>
        {!notificationInfo?.readAt && (
          <Tooltip title={t("MARK_AS_READ") as string} placement="top-end" arrow>
            <UnreadItemBadge onClick={() => onClickMarkResponse(notificationInfo?.id)} />
          </Tooltip>
        )}
      </Grid>
    </GridItem>
  );
};

export default NotificationListItem;
