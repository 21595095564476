import React, { CSSProperties } from "react";
import styled from "styled-components";
import ReactSelect, { Props as SelectPropsType, StylesConfig, OptionType, ValueType } from "react-select";
import { useController, UseControllerOptions } from "react-hook-form";
import { transparentize } from "polished";
import { OptionStateType, ControlStateType } from "types/ReactSelect";
import Colors from "constants/Colors";
import { Z_INDEX } from "constants/ZIndex";

const StyledSelect = styled(ReactSelect)`
  &.error {
    div {
      border-color: ${Colors.Error};
    }
  }
`;

// FIXME: After refactoring theme context

// TODO: We need to get color from theme object or using styled-components;
const getStyle = (isDisabled = false, isReadOnly = false): StylesConfig => {
  return {
    singleValue: (styles: CSSProperties) => ({
      ...styles,
      ...(isReadOnly ? { color: Colors.Dark } : {}),
    }),
    indicatorsContainer: (styles: CSSProperties) => ({
      ...styles,
      ...(isReadOnly ? { display: "none" } : {}),
    }),
    groupHeading: (styles: CSSProperties) => ({
      ...styles,
      fontSize: 14,
      padding: "8px 10px",
      fontWeight: "bolder",
      color: Colors.Dark,
      backgroundColor: Colors.LightBlueGray,
    }),
    control: (styles: CSSProperties, state: ControlStateType) => ({
      ...styles,
      width: "100%",
      minHeight: "48px",
      lineHeight: 2,
      backgroundColor: isDisabled ? Colors.Light : Colors.White,
      borderRadius: "8px",
      border: `solid 1px ${state.isFocused ? Colors.Primary : Colors.LightBlue}`,
      color: Colors.DarkGray,
      boxShadow: "0px",
      ":hover": {
        borderColor: state.isFocused ? Colors.Primary : Colors.LightBlue,
        color: Colors.DarkLight,
      },
      ":active": {
        borderColor: Colors.Primary,
        color: Colors.DarkGray,
      },
      input: {
        lineHeight: 1.5,
      },
    }),
    indicatorSeparator: (styles: CSSProperties) => ({
      ...styles,
      width: 0,
    }),
    menu: (styles: CSSProperties) => ({
      ...styles,
      width: "100%",
      borderRadius: "8px",
      border: `solid 1px ${Colors.Primary}`,
      boxShadow: "0 5px 10px 0 Colors.BoxShadow",
      zIndex: Z_INDEX.SELECT_MENU,
    }),
    option: (styles: CSSProperties, state) => {
      let backgroundColor = Colors.White;
      let color = Colors.DarkGray;

      if (state.isDisabled) {
        backgroundColor = Colors.Light;
        color = Colors.LightBlue;
      } else if (state.isSelected) {
        backgroundColor = Colors.Primary;
        color = Colors.LightWhite;
      }

      return {
        ...styles,
        width: "100%",
        height: "48px",
        display: "flex",
        alignItems: "center",
        backgroundColor,
        color,
        cursor: isDisabled ? "not-allowed" : "default",
        ":hover": {
          backgroundColor: Colors.Primary,
          color: Colors.LightWhite,
        },
        ":active": {
          backgroundColor: Colors.Primary,
          color: Colors.LightWhite,
        },
      };
    },
    menuList: (styles: CSSProperties) => ({
      ...styles,
      borderRadius: "8px",
    }),
    multiValue: (styles: CSSProperties) => ({
      ...styles,
      minHeight: "24px",
      display: "flex",
      alignItems: "center",
      backgroundColor: transparentize(0.94, Colors.Primary),
      borderRadius: "8px",
      border: `solid 0.5px ${Colors.Primary}`,
    }),
    multiValueLabel: (styles: CSSProperties) => ({
      ...styles,
      color: Colors.Primary,
    }),
    multiValueRemove: (styles: CSSProperties) => ({
      ...styles,
      color: Colors.Primary,
      minHeight: "30px",
      borderLeft: `solid 1px ${Colors.LightBlue}`,
      borderRadius: "none",
      ":hover": {
        backgroundColor: transparentize(0.4, Colors.Primary),
        borderRadius: "1px 8px 8px 1px",
        color: "white",
      },
    }),
    noOptionsMessage: (styles: CSSProperties) => ({
      ...styles,
      color: Colors.DarkLight,
    }),
    placeholder: (styles: CSSProperties): CSSProperties => ({
      ...styles,
      color: isDisabled ? Colors.LightBlue : Colors.DarkLight,
    }),
  };
};

const getStyleWithCheckBox = (isDisabled = false): StylesConfig => {
  return {
    control: (styles: CSSProperties, state: ControlStateType) => ({
      ...styles,
      width: "100%",
      minHeight: "48px",
      lineHeight: 2,
      backgroundColor: isDisabled ? Colors.Light : Colors.White,
      borderRadius: "8px",
      border: `solid 1px ${state.isFocused ? Colors.Primary : Colors.LightBlue}`,
      color: Colors.DarkGray,
      boxShadow: "0px",
      ":hover": {
        borderColor: state.isFocused ? Colors.Primary : Colors.LightBlue,
        color: Colors.DarkLight,
      },
      ":active": {
        borderColor: Colors.Primary,
        color: Colors.DarkGray,
      },
      input: {
        lineHeight: 1.5,
      },
    }),
    indicatorSeparator: (styles: CSSProperties) => ({
      ...styles,
      width: 0,
    }),
    menu: (styles: CSSProperties) => ({
      ...styles,
      width: "100%",
      borderRadius: "8px",
      border: `solid 1px ${Colors.Primary}`,
      boxShadow: "0 5px 10px 0 Colors.BoxShadow",
      zIndex: Z_INDEX.SELECT_MENU,
    }),
    option: (styles: CSSProperties, state: OptionStateType) => {
      let backgroundColor = Colors.White;
      let color = Colors.DarkGray;

      if (isDisabled) {
        backgroundColor = Colors.Light;
        color = Colors.LightBlue;
      } else if (state.isSelected) {
        backgroundColor = Colors.White;
        color = Colors.DarkGray;
      }

      return {
        ...styles,
        width: "100%",
        height: "48px",
        display: "flex",
        alignItems: "center",
        backgroundColor,
        color,
        cursor: isDisabled ? "not-allowed" : "pointer",
        ":active": {},
      };
    },
    multiValue: (styles: CSSProperties) => ({
      ...styles,
      minHeight: "24px",
      display: "flex",
      alignItems: "center",
      backgroundColor: "none",
      border: "none",
    }),
    multiValueLabel: (styles: CSSProperties) => ({
      ...styles,
      color: Colors.Primary,
      fontSize: "100%",
      padding: "0px",
    }),
    multiValueRemove: (styles: CSSProperties) => ({
      ...styles,
      display: "none",
    }),
    noOptionsMessage: (styles: CSSProperties) => ({
      ...styles,
      color: Colors.DarkLight,
    }),
    placeholder: (styles: CSSProperties): CSSProperties => ({
      ...styles,
      color: isDisabled ? Colors.LightBlue : Colors.DarkLight,
    }),
  };
};

const Select = <T extends OptionType>(props: SelectPropsType<T>) => {
  const { isDisabled, withCheckBoxStyle, error } = props;
  const style = withCheckBoxStyle ? getStyleWithCheckBox(isDisabled) : getStyle(isDisabled);

  return <StyledSelect {...props} className={error ? "error" : ""} styles={style} />;
};

// TODO: Deprecated. Replace with a new one
export const ControllerSelect = <T extends OptionType>(
  props: SelectPropsType<T> & { value: ValueType<T> } & UseControllerOptions,
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange, value: defaultValueOption, options, isDisabled, ...rest } = props;

  const style = getStyle(isDisabled);
  const { field } = useController(props);

  const handleChange = (option: ValueType<T>) => {
    field.onChange((option as OptionType).value);

    if (onChange) {
      onChange(option, { action: "select-option" });
    }
  };

  return (
    <StyledSelect
      {...rest}
      options={options}
      value={defaultValueOption}
      onChange={handleChange}
      styles={style}
      isDisabled={isDisabled}
    />
  );
};

export const NewControllerSelect = <T extends OptionType>(
  props: SelectPropsType<T> & UseControllerOptions & { isReadOnly?: boolean },
) => {
  const { name, onChange, value, defaultValue, options, isDisabled, isReadOnly, control, error, ...rest } = props;

  const style = getStyle(isDisabled, isReadOnly);
  const { field } = useController({
    name,
    control,
    defaultValue: rest?.defaultInputValue || (defaultValue as T)?.value || null,
  });

  const handleChange = (option: ValueType<T>) => {
    field.onChange((option as OptionType).value);

    if (onChange) {
      onChange(option, { action: "select-option" });
    }
  };

  return (
    <StyledSelect
      {...rest}
      className={error ? "error" : ""}
      defaultValue={defaultValue}
      options={options}
      value={value}
      onChange={handleChange}
      styles={style}
      isDisabled={isReadOnly || isDisabled}
    />
  );
};

export default Select;
