import React from "react";
import styled from "styled-components";
import DeepleButton from "components/Button";
import Grid from "components/Grid";
import Card from "components/Card";

export const IconWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 53px;
  }

  width: 20px;
  color: ${({ theme }) => theme.COLORS.Error};
  display: flex;
  justify-content: center;
  padding-right: 16px;

  &.info {
    color: ${({ theme }) => theme.COLORS.Primary};
  }
`;

export const Button = styled(({ isSuccess: _isSuccess, ...rest }) => <DeepleButton {...rest} />)`
  color: ${({ theme }) => theme.COLORS.White};
  border: ${({ theme }) => `solid 0.5px ${theme.COLORS.Error}`};
  background-color: ${({ theme }) => theme.COLORS.Error};
  font-size: 12px;

  &:hover {
    color: ${({ theme }) => theme.COLORS.Error};
    border: solid 0.5px ${({ theme }) => theme.COLORS.Error};
    background-color: ${({ theme }) => theme.COLORS.White};
  }

  &.info {
    border: ${({ theme }) => `solid 0.5px ${theme.COLORS.Primary}`};
    background-color: ${({ theme }) => theme.COLORS.Primary};
  }
`;

export const IconCheckWrapper = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.COLORS.Success};
  margin-right: 8px;
`;

export const GridIcon = styled(Grid)`
  flex: 0;
`;

export const GridContent = styled(Grid)`
  flex: 1;
`;

export const GridDetail = styled(Grid)`
  padding-bottom: 14px;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding: 0;
    flex: 4;
  }
`;

export const GridButton = styled(Grid)`
  ${({ theme }) => theme.breakpoints.up("sm")} {
    flex: 1;
    padding-left: 8px;
  }
`;

export const BroadcastBarCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.COLORS.Error};
  background-color: #ff54720d;
  margin: 16px;
  flex: 0;

  &.info {
    border: 1px solid ${({ theme }) => theme.COLORS.Primary};
    background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  }
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin: 8px;
`;
