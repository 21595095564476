import React, { FC } from "react";
import { IcAddphoto, IcCategories, IcProduct, IcTyping } from "components/SvgIcons";
import Dropzone from "components/DropzoneUpload";
import { ImageUrl } from "types/Image";
import { ButtonBase } from "./styled";

export enum ActionType {
  TEXT = "TEXT",
  IMAGE = "IMAGE",
  CATEGORY = "CATEGORY",
  PRODUCT = "PRODUCT",
}

type ActionMenusProps = {
  onChangeImage?: (urlImage: ImageUrl[]) => void;
  onClickCategory?: () => void;
  onClickProduct?: () => void;
  onClickTyping?: () => void;
  onClickImage?: () => void;
  projectId?: string;
  type?: ActionType;
  isDisabled?: boolean;
};

const ActionMenus: FC<ActionMenusProps> = ({
  onChangeImage,
  onClickCategory,
  onClickProduct,
  onClickTyping,
  onClickImage,
  projectId = "",
  isDisabled = false,
  type = ActionType.TEXT,
}) => {
  return (
    <div className="d-flex">
      <ButtonBase
        isActive={type === ActionType.TEXT}
        disabled={isDisabled}
        className="mx-1 mx-sm-2"
        onClick={onClickTyping}
      >
        <IcTyping color="inherit" />
      </ButtonBase>

      <Dropzone projectId={projectId} disabled={isDisabled} onChange={onChangeImage} onCancel={onClickTyping} noDrag>
        <ButtonBase
          isActive={type === ActionType.IMAGE}
          disabled={isDisabled}
          onClick={onClickImage}
          className="mx-1 mx-sm-2"
        >
          <IcAddphoto color="inherit" />
        </ButtonBase>
      </Dropzone>

      <ButtonBase
        disabled={isDisabled}
        isActive={type === ActionType.CATEGORY}
        className="mx-1 mx-sm-2"
        onClick={onClickCategory}
      >
        <IcCategories color="inherit" />
      </ButtonBase>

      <ButtonBase
        disabled={isDisabled}
        isActive={type === ActionType.PRODUCT}
        className="mx-1 mx-sm-2"
        onClick={onClickProduct}
      >
        <IcProduct color="inherit" />
      </ButtonBase>
    </div>
  );
};

export default ActionMenus;
