import React, { FC, CSSProperties } from "react";
import styled from "styled-components";
import { Props as SelectPropsType, StylesConfig } from "react-select";
import ReactSelectCreatable from "react-select/creatable";
import makeAnimated from "react-select/animated";
import { transparentize } from "polished";
import { OptionStateType, ControlStateType, CustomStyleSelectType } from "types/ReactSelect";
import Colors from "constants/Colors";

const StyledSelect = styled(ReactSelectCreatable)``;

const animatedComponents = makeAnimated();

type CreateableSelectPropsType = SelectPropsType & CustomStyleSelectType;

// FIXME: After refactoring theme context

// TODO: We need to get color from themm object or using styled-components;
const getStyle = (isDisabled = false, noOutline = false): StylesConfig & CustomStyleSelectType => {
  return {
    input: (styles: CSSProperties) => ({
      ...styles,
      input: {
        whiteSpace: "pre",
        lineHeight: "1.5rem",
        textIndent: "2px",
      },
    }),
    valueContainer: (styles: CSSProperties) => ({
      ...styles,
      padding: "2px",
    }),
    control: (styles: CSSProperties, state: ControlStateType) => ({
      ...styles,
      width: "100%",
      minHeight: "48px",
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: noOutline ? "transparent" : isDisabled ? Colors.Light : Colors.White,
      borderRadius: "8px",
      border: noOutline ? "none" : `solid 1px ${state.isFocused ? Colors.Primary : Colors.LightBlue}`,
      color: Colors.DarkGray,
      boxShadow: "0px",
      ":hover": {
        borderColor: state.isFocused ? Colors.Primary : Colors.LightBlue,
        color: Colors.DarkLight,
      },
      ":active": {
        borderColor: Colors.Primary,
        color: Colors.DarkGray,
      },
    }),
    indicatorSeparator: (styles: CSSProperties) => ({
      ...styles,
      width: 0,
    }),
    menu: (styles: CSSProperties) => ({
      ...styles,
      width: "100%",
      borderRadius: "8px",
      border: `solid 1px ${Colors.Primary}`,
      boxShadow: "0 5px 10px 0 Colors.BoxShadow",
    }),
    option: (styles: CSSProperties, state: OptionStateType) => {
      let backgroundColor = Colors.White;
      let color = Colors.DarkGray;

      if (isDisabled) {
        backgroundColor = Colors.Light;
        color = Colors.LightBlue;
      } else if (state.isFocused) {
        backgroundColor = transparentize(0.15, Colors.Primary);
        color = Colors.LightWhite;
      } else if (state.isSelected) {
        backgroundColor = Colors.Primary;
        color = Colors.LightWhite;
      }

      return {
        ...styles,
        whiteSpace: "pre",
        width: "100%",
        height: "48px",
        display: "flex",
        alignItems: "center",
        backgroundColor,
        color,
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          backgroundColor: Colors.Primary,
          color: Colors.LightWhite,
        },
      };
    },
    multiValue: (styles: CSSProperties) => ({
      ...styles,
      minHeight: "21px",
      display: "flex",
      alignItems: "center",
      backgroundColor: transparentize(0.94, Colors.Primary),
      borderRadius: "8px",
      border: `solid 0.5px ${Colors.Primary}`,
      fontSize: "11px",
      lineHeight: "11px",
      fontWeight: 500,
    }),
    multiValueLabel: (styles: CSSProperties) => ({
      ...styles,
      fontSize: "100%",
      whiteSpace: "pre",
      color: Colors.Primary,
    }),
    multiValueRemove: (styles: CSSProperties) => ({
      ...styles,
      color: Colors.Primary,
      minHeight: "21px",
      ":hover": {
        backgroundColor: transparentize(0.4, Colors.Primary),
        borderRadius: "1px 8px 8px 1px",
        color: "white",
      },
    }),
    noOptionsMessage: (styles: CSSProperties) => ({
      ...styles,
      color: Colors.DarkLight,
    }),
    placeholder: (styles: CSSProperties): CSSProperties => ({
      ...styles,
      color: isDisabled ? Colors.LightBlue : Colors.DarkLight,
    }),
  };
};

const CreatableSelect: FC<CreateableSelectPropsType & SelectPropsType> = (props) => {
  const { isDisabled, noOutline, styles, components } = props;

  const customStyle = styles || getStyle(isDisabled, noOutline);

  const customComponents = noOutline ? { ...components } : { ...animatedComponents, ...components };

  return <StyledSelect {...props} styles={customStyle} components={customComponents} />;
};

export default CreatableSelect;
