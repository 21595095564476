import * as React from "react";

const IcAddBubble = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="1 1 15 15" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" stroke="#A1A6B9" d="M17.5 16.588v-6.5a8.5 8.5 0 0 0-8.5-8.5H8a7.5 7.5 0 0 0 0 15h9.5z" />
      <path
        fill="#737D94"
        d="M9 6.088c.31 0 .563.23.563.513l-.001 1.924h1.925c.283 0 .513.252.513.563 0 .31-.23.562-.513.562H9.562v1.925c0 .283-.251.513-.562.513-.31 0-.563-.23-.563-.513V9.65H6.513C6.23 9.65 6 9.4 6 9.088c0-.31.23-.563.513-.563h1.924V6.601c0-.283.252-.513.563-.513z"
      />
    </g>
  </svg>
);

export default IcAddBubble;
