import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";

import { STAT_WIDGET_TYPE } from "constants/Stat";
import { StatQueryType, StatWidgetType } from "types/Stat";
import { convertPriceFormat } from "utils/common";

import StatWidget from "../StatWidget";

type GroupStatWidgetPropsType = {
  isLoading: boolean;
  statData: Omit<StatQueryType, "graphStat">;
};

const GroupStatWidget: FC<GroupStatWidgetPropsType> = ({ statData, isLoading }) => {
  const { t } = useTranslation();

  const statWidgets = [
    {
      label: t("statWidget.title.income"),
      value: !statData ? "" : `${convertPriceFormat(statData.totalOrderIncomeStat.totalOrderIncome)}`,
      changedValue: !statData ? 0 : statData.totalOrderIncomeStat.changedPercentage,
      type: STAT_WIDGET_TYPE.PRICE,
    },
    {
      label: t("statWidget.title.orders"),
      value: !statData ? "" : `${convertPriceFormat(statData.totalOrderStat.totalOrder, 0)}`,
      changedValue: !statData ? 0 : statData.totalOrderStat.changedPercentage,
      type: STAT_WIDGET_TYPE.ORDER,
    },
    {
      label: t("statWidget.title.customers"),
      value: !statData ? "" : `${convertPriceFormat(statData.newCustomerStat.totalNewCustomer, 0)}`,
      changedValue: !statData ? 0 : statData.newCustomerStat.changedNewCustomer,
      type: STAT_WIDGET_TYPE.CUSTOMER,
    },
    {
      label: t("statWidget.title.orderValue"),
      value: !statData ? "" : `${convertPriceFormat(statData.averageOrderIncomeStat.averageOrderIncome)}`,
      changedValue: !statData ? 0 : statData.averageOrderIncomeStat.changedPercentage,
      type: STAT_WIDGET_TYPE.PRICE,
    },
  ];

  return (
    <Grid item container spacing={2} className="pt-4">
      {statWidgets.map(({ label, value, changedValue, type }: StatWidgetType) => (
        <StatWidget
          key={label}
          label={label}
          value={value}
          changedValue={changedValue}
          type={type}
          isLoading={isLoading}
        />
      ))}
    </Grid>
  );
};

export default GroupStatWidget;
