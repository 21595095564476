import qs from "qs";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Filter } from "./usePaginationState";

const useWatchFilterAndUpdateQueryString = (
  filter: Filter,
  rowsPerPage: number,
  page: number,
  searchText: string,
  projectId: string,
  currentPathUrl: string,
) => {
  const history = useHistory();

  useEffect(() => {
    const variables = {
      projectId,
      offset: page,
      limit: rowsPerPage,
      filter: {
        ...filter,
        keyword: searchText,
      },
    };
    const queryString = qs.stringify(variables);
    history.push(`${currentPathUrl}?${queryString}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, rowsPerPage, page, searchText, projectId]);
};

export default useWatchFilterAndUpdateQueryString;
