import { ResponseMessage } from "types/AISetting";

export type ResponseMessageData = {
  id: string;
  name: string;
  slug: string;
  thDisplayName: string;
  enDisplayName: string;
  tags: {
    id: string;
    tag: string;
  };
  isCustom: boolean;
  maleMessages: ResponseMessage[];
  femaleMessages: ResponseMessage[];
  customMessages: ResponseMessage[];
};

export enum RESPONSE_MESSAGE_TAB {
  DEFAULT = "DEFAULT",
  CUSTOM = "CUSTOM",
}
