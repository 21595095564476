import { Grid } from "@material-ui/core";
import Typography from "components/Typography";
import COLORS from "constants/Colors";
import { WAITING_IMAGE } from "constants/Image";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { SuccessWrapper } from "./styled";

const Sucess: FC = () => {
  const { t } = useTranslation();

  return (
    <SuccessWrapper className="pt-4 pb-4 pl-5 pr-5">
      <Grid container justify="center" className="p-2">
        <img src={WAITING_IMAGE} alt="waiting-img" />
        <Grid item className="mt-2 mb-4">
          <Typography color={COLORS.Dark} style={{ textAlign: "center" }} variant="title3">
            {t("deeplePay.verifyBilling.success.description")}
          </Typography>
        </Grid>
      </Grid>
    </SuccessWrapper>
  );
};

export default Sucess;
