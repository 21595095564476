import React from "react";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Grid from "components/Grid";
import List from "components/List";
import Typography from "components/Typography";
import StepperNumberInput from "components/StepperNumberInput";
import { ProductInventoryType, ProductSKUType } from "types/Product";
import { getProductSKUVariationList } from "utils/product/getProductSKUVariationList";

type InventoryCardPropsType = {
  productSKUs: ProductSKUType[];
  onChangeStock: (productSKUId: string, productCode: string, value: number) => void;
  tempInventories: ProductInventoryType[];
  isDisableUpdateStock: boolean;
  isClientStore?: boolean;
};

const InventoryCardWrapper = styled(Grid)`
  background-color: ${({ theme }) => theme.COLORS.White};
`;

const InventoryCard = (props: InventoryCardPropsType) => {
  const { productSKUs, onChangeStock, tempInventories, isDisableUpdateStock, isClientStore } = props;
  const { t } = useTranslation();

  return (
    <InventoryCardWrapper container>
      {/* inventory table */}
      <Grid item container xs={12} className="px-3 py-2">
        {/* table header */}
        <Grid item xs={3}>
          <Typography variant="title8" color="gray">
            {t("SKU Code")}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="title8" color="gray">
            {t("SKU(s)")}
          </Typography>
        </Grid>
        <Grid item container xs={2} alignItems="center">
          <Grid item>
            <Typography variant="title8" color="gray" className="mr-1">
              {t("In stock")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body4" color="gray">
              ({t("Reserved")})
            </Typography>
          </Grid>
        </Grid>
        {!isClientStore && (
          <Grid item xs={4} className="text-center">
            {!isDisableUpdateStock && (
              <Typography variant="title8" color="gray">
                {t("Update stock")}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        {/* table body */}
        <List>
          {productSKUs.map((productSKU: ProductSKUType) => {
            const { productType, productCode, inventory, reserved, id } = productSKU;
            const productSKUVariationText = getProductSKUVariationList(productType) || [];
            const tempUpdateStock = tempInventories.find((item) => item.productSKUId === id);

            const tempUpdateStockValue = get(tempUpdateStock, "value", 0);
            const minValue = -(inventory - reserved);

            return (
              <Grid container key={productCode} className="py-2" alignItems="center">
                <Grid item xs={3}>
                  <Typography variant="body3" className="ellipsis">
                    {productCode}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body3" className="wordBreakAll">
                    {productSKUVariationText.map((variationText, index) =>
                      index === 0 ? ` ${variationText},` : ` ${variationText}`,
                    )}
                  </Typography>
                </Grid>
                <Grid item container xs={2} alignItems="center">
                  <Grid item>
                    <Typography variant="body3">{inventory}</Typography>
                  </Grid>
                  <Grid>
                    <Typography className="ml-1" variant="body4">
                      ({reserved})
                    </Typography>
                  </Grid>
                </Grid>
                {!isClientStore && (
                  <Grid item container xs={4} justify="center">
                    {!isDisableUpdateStock && (
                      <Grid item>
                        <StepperNumberInput
                          minValue={minValue}
                          defaultColor={false}
                          value={tempUpdateStockValue}
                          onChange={(value: number) => onChangeStock(id, productCode, value)}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            );
          })}
        </List>
      </Grid>
    </InventoryCardWrapper>
  );
};

export default InventoryCard;
