import * as yup from "yup";

import { COD_DEPOSIT_TYPES, COD_MANAGEMENT_FEE_TYPES } from "constants/Payment";

const schema = yup.object().shape({
  isActive: yup.boolean().required(),
  shippingMethod: yup.string().when("isActive", (isActive: boolean) => {
    if (isActive) {
      return yup.string().typeError("Please select carrier name").required("Please select carrier name");
    }
    return yup.string().nullable();
  }),
  depositThreshold: yup.number().when("isActive", (isActive: boolean) => {
    if (isActive) {
      return yup
        .number()
        .typeError(
          "Minimum total price per order is require. Please fill in 0 if you not have require minimum total price per order",
        )
        .min(0, "Minimum total price per order cannot be below 0");
    }
    return yup.string();
  }),
  depositType: yup.string().when("isActive", (isActive: boolean) => {
    if (isActive) {
      return yup.string().typeError("Please select deposit type").required("Please select deposit type");
    }

    return yup.string().nullable();
  }),
  depositAmount: yup
    .string()
    .when(
      ["isActive", "depositType", "depositThreshold"],
      (isActive: boolean, depositType: string, depositThreshold: number) => {
        if (!isActive) {
          return yup.string().nullable();
        }

        switch (depositType) {
          case COD_DEPOSIT_TYPES.PERCENTAGE:
            return yup
              .number()
              .required("Please enter Deposit amount")
              .typeError("Deposit percent cannot be below 1")
              .min(1, "Deposit percent cannot be below 1")
              .max(100, "Deposit percent cannot be above 100");
          case COD_DEPOSIT_TYPES.FIXED_PRICE:
            return yup
              .number()
              .required("Please enter Deposit amount")
              .typeError("Fix deposit cannot be below 1")
              .min(1, "Fix deposit cannot be below 1")
              .max(depositThreshold, "Fix deposit value cannot exceed Minimum total price per order");
          default:
            return yup.string().nullable();
        }
      },
    ),
  "managementFee.type": yup.string().when("isActive", (isActive: Boolean) => {
    if (isActive) {
      return yup.string();
    }

    return yup.string().nullable();
  }),
  "managementFee.percent": yup.string().when(["isActive", "managementFee.type"], (isActive: Boolean, type: string) => {
    if (isActive && type === COD_MANAGEMENT_FEE_TYPES.PERCENTAGE) {
      return yup.string();
    }

    return yup.string().nullable();
  }),
  "managementFee.fee": yup.string().when(["isActive", "managementFee.type"], (isActive: Boolean, type: string) => {
    if (isActive && type === COD_MANAGEMENT_FEE_TYPES.FIXED_PRICE) {
      return yup.string();
    }

    return yup.string().nullable();
  }),
  managementFee: yup.object().shape({
    // type: yup.string().notOneOf(["NONE"], "Please select management fee type"),
    percent: yup.string().when(["type"], (type: string) => {
      if (type === COD_MANAGEMENT_FEE_TYPES.PERCENTAGE) {
        return yup
          .number()
          .typeError("Management fee percent cannot be below 1")
          .required("paymentCOD.error.required")
          .min(1, "Management fee percent cannot be below 1")
          .max(100, "Management fee percent cannot be above 100");
      }

      return yup.string().nullable();
    }),
    fee: yup.string().when(["type"], (type: string) => {
      if (type === COD_MANAGEMENT_FEE_TYPES.FIXED_PRICE) {
        return yup
          .number()
          .typeError("Fixed management fee cannot be below 1")

          .required("paymentCOD.error.required")
          .min(1, "Fixed management fee cannot be below 1");
      }

      return yup.string().nullable();
    }),
  }),
});

export default schema;
