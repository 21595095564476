import { useQuery } from "@apollo/client";
import { BILLING_OVERDUE } from "graphql/billing/query";
import { BillingOverdue } from "types/Billing";

const useBillingOverdue = (projectId?: string) => {
  const { data, loading, refetch } = useQuery<{ billingOverdue: BillingOverdue }>(BILLING_OVERDUE, {
    skip: !projectId,
    variables: {
      projectId,
    },
    errorPolicy: "ignore",
  });

  return {
    overdue: data?.billingOverdue,
    loading,
    refetchBillingOverdue: refetch,
  };
};

export default useBillingOverdue;
