import { gql } from "@apollo/client";

import { LastMessage } from "./query";

export interface CustomerStatusChangedType {
  id: string;
  uuid?: string;
  name?: string;
  platform?: string;
  isHumanTakingOver?: boolean;
  status?: string;
  pictureUrl?: string;
  lastMessage?: LastMessage;
  unSeenMessageAfterTakingOver?: string;
}

export const MESSAGE_ADDED = gql`
  subscription($projectId: ID!, $customerId: ID) {
    messageAdded(projectId: $projectId, customerId: $customerId) {
      id
      customerId
      data {
        type
        value
      }
      referenceId
      messageUUID
      platform
      source
      slug
      sentiment
      createdAt
      isDeleted
      consoleDetail {
        firstname
        lastname
        id
        email
      }
    }
  }
`;

export const CUSTOMER_STATUS_CHANGED = gql`
  subscription($projectId: ID!) {
    customerStatusChanged(projectId: $projectId) {
      tags(page: 0, pageSize: 50) {
        results {
          id
          projectId
          customerId
          tag
          createdAt
          updatedAt
        }
        total
      }
      systemTags {
        id
        createdAt
        projectId
        customerId
        tag
        type
        metadata {
          broadcastId
          orderId
        }
      }
      id
      displayName
      uuid
      name
      platform
      isHumanTakingOver
      isDisconnected
      status
      pictureUrl
      lastMessage {
        id
        isDeleted
        data {
          type
          value
        }
        source
        createdAt
        messageUUID
      }
      unSeenMessageAfterTakingOver
    }
  }
`;

export const TOTAL_UNSEEN_CHAT_MESSAGES_CHANGED = gql`
  subscription($projectId: ID!) {
    totalUnseenChatMessagesChanged(projectId: $projectId) {
      totalUnseenChatMessages
      totalUnseenAdminChatMessages
      totalUnseenDisconnectedChatMessages
    }
  }
`;
