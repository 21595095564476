import React from "react";
import ContentLoader from "react-content-loader";
import COLORS from "constants/Colors";
import { LoaderCardWrapper } from "./styled";

const LoadingCreditCard = () => {
  return (
    <LoaderCardWrapper>
      <ContentLoader viewBox="0 0 362 225" backgroundColor={COLORS.Light} foregroundColor={COLORS.LightGray}>
        <rect x="24" y="20" rx="5" ry="5" width="98px" height="24px" />
        <rect x="298" y="20" rx="5" ry="5" width="40px" height="24px" />
        <rect x="24" y="95" rx="5" ry="5" width="314px" height="36px" />
        <rect x="24" y="185" rx="5" ry="5" width="194px" height="20px" />
        <rect x="294" y="185" rx="5" ry="5" width="45px" height="20px" />
      </ContentLoader>
    </LoaderCardWrapper>
  );
};

LoadingCreditCard.displayName = "LoadingCreditCard";

export default LoadingCreditCard;
