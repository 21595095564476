import { useQuery } from "@apollo/client";
import moment from "moment";
import React, { FC, useMemo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { OptionsType, OptionType, ValueType } from "react-select";
import { SortingRule } from "react-table";
import debounce from "lodash/debounce";
import produce from "immer";
import ExportCSVButton from "components/ExportCSVButton";
import MultiSelectWithCheckBox from "components/MultiSelectWithCheckBox";
import Search from "components/Search";
import Table from "components/Table";
import Grid from "components/Grid";
import Typography from "components/Typography";
import IcTag from "components/SvgIcons/IcTag";
import IcSearch from "components/SvgIcons/IcSearch";
import IcLocation2 from "components/SvgIcons/IcLocation2";
import { SvgIcon } from "components/Icon";
import TagInputFilter from "components/TagInputFilter";
import ButtonToPlanUpgrade from "components/ButtonToPlanUpgrade";
import Colors, { COLORS } from "constants/Colors";
import { MOCK_CUSTOMER_REPORT_DATA } from "constants/Customer";
import DateRange from "domain/Report/ReportFilter/DateRangeFilter/DateRange";
import Loading from "domain/Faq/FaqThEn/FaqContainer/Loading";
import useSelectedIds from "domain/Report/ParcelInvoiceReport/hooks/useSelectedIds";
import ReportPagination from "domain/Report/ParcelInvoiceReport/ReportPagination";
import { FilterCardWrapper } from "domain/Report/ReportFilter/Filter.styled";
import { DisableFeature } from "pages/DisableFeature";
import { CustomerConnectionType, PLATFORM, SortByCustomerField } from "types/Customer";
import { Data, OrderByType, ExportFileType } from "types/SalesReport";
import { ProjectIdType } from "types/Project";
import { getAddress } from "utils/address";
import displayDateTime from "utils/common/displayDateTime";
import filterSelectedIds from "utils/salesReport";
import useRenderExportedNumber from "utils/hooks/SalesReport/useRenderExportedNumber";
import { notifySuccess, notifyError } from "utils/notify";
import useSuggestCustomerTag from "utils/hooks/useSuggestCustomerTag";
import { getAllTags, getNewestTags } from "utils/tag";
import useGetProject from "utils/hooks/useGetProject";
import { CUSTOMERS } from "graphql/customer/query";
import {
  ALL_CUSTOMER_PLATFORMS_MULTI_OPTIONS,
  DEBOUNCE_TIMEOUT,
  DEFAULT_PAGE_START,
  DEFAULT_VALUE,
  LIMIT_ID_SELECTED,
  MAX_TAGS_SEARCH,
  ROWS_PER_PAGES,
} from "./config";
import { getColumns, tableHooks } from "./Table/config";
import { TableWrapper, LoadingWrapper } from "./Table/styled";
import usePaginationState, { MAPPED_CUSTOMER_FIELD } from "./hooks/usePaginationState";
import useExportCustomers from "./hooks/useExportCustomers";
import { IconWrapper, DatePickerWrapper, DatePickerLabel } from "./styled";
import { DisabledReportBox, DisabledReportWrapper, Hidden } from "../SalesReport/styled";

const CustomerTable: FC = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [startDate, setStartDate] = useState(
    moment("00:00", "HH:mm").subtract(1, "months").startOf("day").toISOString(),
  );
  const [endDate, setEndDate] = useState(moment().endOf("day").toISOString());

  const handleSetStartDate = (selectedDate: string | null) => {
    if (selectedDate) {
      setStartDate(selectedDate);
    }
  };

  const handleSetEndDate = (selectedDate: string | null) => {
    if (selectedDate) {
      setEndDate(selectedDate);
    }
  };

  const { selectAllRowIdsInPage: onSelectAllRowInPage, selectRowId: onSelectRow, selectedIds } = useSelectedIds({
    warringMessage: t("customers.reachLimit", { limit: LIMIT_ID_SELECTED }),
  });

  const { page, rowsPerPage, setPage, setRowsPerPage, setFilter, filter } = usePaginationState();
  const exportedNumber = useRenderExportedNumber({
    isSelectedAll: false,
    excludeIds: {},
    selectedIds,
  });

  const handleExportCustomersCompleted = useCallback(() => {
    notifySuccess(t("EXPORT_CUSTOMER_SUCCESS"));
  }, [t]);

  const handleExportCustomersError = useCallback(() => {
    notifyError(t("Please try again later"));
  }, [t]);

  const { exportCustomers, loading: isLoadingExportCustomers } = useExportCustomers({
    onCompleted: handleExportCustomersCompleted,
    onError: handleExportCustomersError,
  });

  const handleExportCustomers = (type: ExportFileType) => {
    if (!isAdvancedCustomerReportEnabled) return;

    const selectedCustomerIds = filterSelectedIds(selectedIds);
    exportCustomers(projectId, selectedCustomerIds, filter, type, { startDate, endDate });
  };

  const handleChangeRowsPerPage = useCallback(
    (newRowsPerPage: number) => {
      setRowsPerPage(newRowsPerPage);
      setPage(DEFAULT_PAGE_START);
    },
    [setPage, setRowsPerPage],
  );

  const handleClickPageNo = useCallback(
    (data: { selected: number }) => {
      const { selected } = data;
      setPage(selected + 1);
    },
    [setPage],
  );

  const handleChangeSearchCustomer = useCallback(
    debounce((searchInput: string) => {
      const newFilter = produce(filter, (draft) => {
        draft.customerNames[0] = searchInput;
      });
      setFilter(newFilter);
    }, DEBOUNCE_TIMEOUT),
    [filter, setFilter],
  );

  const handleChangeSearchLocation = useCallback(
    debounce((searchInput: string) => {
      const newFilter = produce(filter, (draft) => {
        draft.customerLatestLocation = searchInput;
      });
      setFilter(newFilter);
    }, DEBOUNCE_TIMEOUT),
    [filter, setFilter],
  );

  const handleChangePlatformFilter = useCallback(
    debounce((options?: ValueType<OptionType>) => {
      const platforms = options ? (options as OptionsType<OptionType>).map((option) => option.value) : [];
      const newFilter = produce(filter, (draft) => {
        draft.customerPlatforms = platforms as PLATFORM[];
      });

      setFilter(newFilter);
    }, DEBOUNCE_TIMEOUT),
    [filter, setFilter],
  );

  const handleChangeTag = useCallback(
    debounce((options?: ValueType<OptionType>) => {
      const tags = options ? (options as OptionsType<OptionType>).map((option) => option.value) : [];
      const newFilter = produce(filter, (draft) => {
        draft.customerTags = tags;
      });

      setFilter(newFilter);
    }, DEBOUNCE_TIMEOUT),
    [filter, setFilter],
  );

  const { projectId } = useParams<ProjectIdType>();
  const { isAdvancedCustomerReportEnabled, isCustomerReportEnabled, loading: projectInfoLoading } = useGetProject(
    projectId,
  );
  const { loading, data } = useQuery<{ customers: CustomerConnectionType }>(CUSTOMERS, {
    variables: {
      projectId,
      offset: page - DEFAULT_PAGE_START,
      limit: rowsPerPage,
      filter: {
        ...filter,
        startDate,
        endDate,
      },
    },
    skip: !isCustomerReportEnabled,
  });

  const handleChangeSortBy = useCallback(
    (sortBys: SortingRule<{ id: string; desc: boolean }>[]) => {
      const sortBy = sortBys[0];
      if (sortBy && isCustomerReportEnabled) {
        const newFilter = produce(filter, (draft) => {
          if (sortBy) {
            draft.orderByField = MAPPED_CUSTOMER_FIELD[sortBy.id as SortByCustomerField];
            draft.orderBy = sortBy.desc ? OrderByType.DESC : OrderByType.ASC;
          } else {
            draft.orderByField = undefined;
            draft.orderBy = undefined;
          }
        });

        setFilter(newFilter);
      }
    },
    [filter, isCustomerReportEnabled, setFilter],
  );

  const isReportDisabled = !loading && !projectInfoLoading && !isCustomerReportEnabled;
  const customers = data?.customers?.results || [];
  const total = data?.customers?.total || 0;

  const { suggestCustomerTags, fetchCustomerTags } = useSuggestCustomerTag(projectId);

  const suggestCustomerTagOptions = useMemo(
    () => suggestCustomerTags.map((suggestCustomerTag) => ({ label: suggestCustomerTag, value: suggestCustomerTag })),
    [suggestCustomerTags],
  );

  useEffect(() => {
    fetchCustomerTags();
  }, [fetchCustomerTags]);

  const customersTableData = isCustomerReportEnabled
    ? customers.map((customer) => {
        const allTags = getAllTags(customer?.tags?.results, customer.systemTags);
        const twoNewestTags = getNewestTags(allTags, 3);

        return {
          id: customer.id,
          profile: { name: customer.displayName || customer.name, avatar: customer.pictureUrl },
          platform: customer.platform,
          chatLink: `/project/${projectId}/chat/${customer.id}`,
          email: customer?.address?.email || DEFAULT_VALUE,
          totalSpend: customer.totalSpending,
          tags: { result: twoNewestTags, numberOfHiddenTags: allTags.length - twoNewestTags.length },
          address: getAddress(customer?.address) || DEFAULT_VALUE,
          firstName: customer?.address?.firstName || DEFAULT_VALUE,
          lastName: customer?.address?.lastName || DEFAULT_VALUE,
          firstSeen: displayDateTime(customer?.firstSeen),
          lastMessagedAt: displayDateTime(customer?.lastMessagedAt),
          phoneNumber: customer?.address?.phoneNumber || DEFAULT_VALUE,
        };
      })
    : MOCK_CUSTOMER_REPORT_DATA;

  const columns = useMemo(() => getColumns(t), [t]);

  const exportButton = (
    <ExportCSVButton
      isLoading={isLoadingExportCustomers}
      label={t("EXPORT_CUSTOMER")}
      exportedNumber={exportedNumber || t("salesReport.exportedNumber.all")}
      subLabel={t("product.import.button.subLabel")}
      onExport={handleExportCustomers}
    />
  );

  const customerReportTable = (
    <>
      <div className="m-2 mb-4 m-md-0 mb-md-4">
        <Search defaultValue="" placeholder={t("SEARCH_BY_CUSTOMER_NAME")} onChange={handleChangeSearchCustomer} />

        <Grid container className="mt-3 mb-2" alignItems="center">
          <Grid item xs={12} md={3} xl={4} className="pr-sm-3 order-sm-0 order-1 mt-2 mb-2 my-sm-1">
            <MultiSelectWithCheckBox
              placeholder={t("ALL_PLATFORM")}
              options={ALL_CUSTOMER_PLATFORMS_MULTI_OPTIONS}
              onChange={handleChangePlatformFilter}
              isSearchable={false}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} xl={4} className="order-sm-1 order-0 my-sm-1 pr-sm-3">
            <Search
              variant="outlined"
              defaultValue=""
              placeholder={t("SEARCH_BY_LOCATION")}
              onChange={handleChangeSearchLocation}
              InputProps={{
                startAdornment: (
                  <>
                    <IconWrapper>
                      <IcLocation2 />
                    </IconWrapper>
                    <SvgIcon className="ml-3" component={IcSearch} fontSize="small" htmlColor={Colors.DarkMed} />
                  </>
                ),
                style: { backgroundColor: Colors.White, paddingLeft: 0 },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={5} xl={4} className="d-flex order-sm-2 order-2 mb-3 my-sm-1">
            <DatePickerWrapper>
              <DatePickerLabel>
                <Typography variant="body3" color="inherit" className="d-flex align-items-center">
                  {t("CREATED_AT")}
                </Typography>
              </DatePickerLabel>

              <DateRange
                isButtonBase
                isFullWidth
                anchorEl={anchorEl}
                endDate={endDate}
                isOpen={Boolean(anchorEl)}
                onChangeEnd={handleSetEndDate}
                onChangeStart={handleSetStartDate}
                onClick={handleClick}
                onClose={handleClose}
                startDate={startDate}
              />
            </DatePickerWrapper>
          </Grid>
        </Grid>
        <FilterCardWrapper className="mr-2 my-1 align-items-center justify-content-between">
          <IconWrapper>
            <IcTag />
          </IconWrapper>
          <TagInputFilter
            isCreateAble
            limit={MAX_TAGS_SEARCH}
            label={t("customerTag")}
            options={suggestCustomerTagOptions}
            onChange={handleChangeTag}
          />
        </FilterCardWrapper>
      </div>
      <TableWrapper isBlurContent={isReportDisabled}>
        {loading && (
          <LoadingWrapper>
            <Loading loadingLabel={t("LOADING")} />
          </LoadingWrapper>
        )}
        {isReportDisabled && (
          <DisabledReportWrapper>
            <DisabledReportBox>
              <Typography variant="title2" color={COLORS.Dark} className="text-center mb-3">
                {t("salesReport.orderReports.disabled")}
              </Typography>
              {projectId && <ButtonToPlanUpgrade label={t("DisableFeature.button")} projectId={projectId} />}
            </DisabledReportBox>
          </DisabledReportWrapper>
        )}
        <Hidden className="ml-auto mr-auto" isHidden={customersTableData.length === 0}>
          <Table<Data, Record<string, Data>>
            data={customersTableData}
            columns={columns}
            manualSortBy
            hooks={tableHooks}
            customState={{ selectedIds }}
            onChangeSortBy={handleChangeSortBy}
            customCellProps={{ onSelectRow }}
            customHeaderProps={{ onSelectAllRowInPage }}
            isBlurContent={isReportDisabled}
          />
        </Hidden>

        {
          // render when no customers
          !loading && customers.length === 0 && isCustomerReportEnabled && (
            <Grid container justify="center" alignItems="center" className="h-100">
              <Grid item>
                <Typography variant="title2" color={Colors.DarkLight}>
                  {t("DATA_NOT_FOUND")}
                </Typography>
              </Grid>
            </Grid>
          )
        }
      </TableWrapper>
      <div className="flex-0">
        <ReportPagination
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onClickPageNo={handleClickPageNo}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGES}
          total={total}
        />
      </div>
    </>
  );

  return (
    <div className="px-md-3 w-100 h-100 d-flex flex-column border-box">
      <Grid container className="mt-4 mb-2 px-2">
        <Grid item xs={6}>
          <Typography variant="title1" color="dark">
            {t("CUSTOMER_REPORT")}
          </Typography>
        </Grid>

        <Grid item xs={6} className="text-right">
          {isAdvancedCustomerReportEnabled ? exportButton : <DisableFeature>{exportButton}</DisableFeature>}
        </Grid>
      </Grid>

      {isCustomerReportEnabled ? customerReportTable : <DisableFeature>{customerReportTable}</DisableFeature>}
    </div>
  );
};

export default CustomerTable;
