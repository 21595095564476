import { MAX_REF_CODE } from "constants/Auth";

const register = {
  en: {
    "Create an account": "Create an account",
    "Register in a few second": "Register in a few second",
    "By register, you confirm that you’ve read and accepted": "By register, you confirm that you’ve read and accepted",
    our: "our ",
    and: " and ",
    "Terms of Service": "Terms of Service",
    "Privacy Policy.": "Privacy Policy.",
    "Register success!": "Register success!",
    "Register fail!": "Register fail!",
    "name is a required field": "name is a required field",
    "register.refCode": "Ref. code",
    "register.refCode.errorMaxLength": `Ref. code must be less than ${MAX_REF_CODE} characters.`,
    INVALID_REF_CODE: "Ref. code is invalid",
    EMAIL_ALREADY_EXIST: "This email has already been taken",
    "refCode is a required field": "Ref. code is a required field",
  },
  th: {
    "Create an account": "สร้างบัญชีผู้ใช้",
    "Register in a few second": "ใช้เวลาเพียงไม่นานในการสมัคร",
    "By register, you confirm that you’ve read and accepted": "โดยการสมัครครั้งนี้ถือว่าผู้สมัครยอมรับและได้รับทราบใน",
    our: "",
    "Terms of Service": "เงื่อนไขการให้บริการ",
    and: "และ",
    "Privacy Policy.": "นโยบายความเป็นส่วนตัว",
    "Register success!": "การสมัครเสร็จสมบรูณ์!",
    "Register fail!": "การสมัครไม่สำเร็จ!",
    "name is a required field": "กรุณากรอกชื่อ",
    "register.refCode": "รหัสอ้างอิง",
    "register.refCode.errorMaxLength": `รหัสอ้างอิงต้องน้อยกว่า ${MAX_REF_CODE} ตัว`,
    INVALID_REF_CODE: "รหัสอ้างอิงไม่ถูกต้อง",
    EMAIL_ALREADY_EXIST: "อีเมล์นี้ถูกใช้ไปแล้ว",
    "refCode is a required field": "กรุณากรอกรหัสอ้างอิง",
  },
};

export default register;
