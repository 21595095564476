import * as React from "react";

const IcLongArrowDown = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.595 17.698c.104.133.26.21.426.21.165 0 .32-.077.425-.21l2.47-3.165c.193-.247.159-.61-.076-.81-.235-.202-.582-.166-.774.08l-1.45 1.869V6h-1.168v9.672l-1.473-1.869c-.109-.139-.266-.212-.425-.212-.123 0-.247.044-.349.131-.234.201-.27.564-.077.81l2.47 3.166z"
    />
  </svg>
);

export default IcLongArrowDown;
