import * as yup from "yup";
import { ProductOptionType } from "types/Product";
import i18n from "utils/i18n";
import { findDuplicatedObjectInArrayByKey } from "utils/common";
import { REGEX_PRODUCT_CODE_SUPPORT_CHARACTER } from "constants/Product";
import { MAXIMUM_PRODUCT_NAME_STRING_LENGTH } from "config";

const addNewProductSchema = yup.object().shape({
  productName: yup
    .string()
    .required("Please fill product name")
    .matches(/^(?!.*\+).*$/, "Product name can not contain plus sign")
    .max(MAXIMUM_PRODUCT_NAME_STRING_LENGTH, "Product name must be below 100 characters"),
  selectedCategoryId: yup.string().required("Please select category"),
  productCode: yup
    .string()
    .trim()
    .required("Please fill product code")
    .min(3, "notSupportProductCodeLength")
    .max(50, "notSupportProductCodeLength")
    .test("isSupportCharacter", "notSupportProductCode", (value) => {
      const isSupportCharacter = value && value.match(REGEX_PRODUCT_CODE_SUPPORT_CHARACTER);
      if (isSupportCharacter) {
        return true;
      }
      return false;
    }),
  productPrice: yup.number().required("Please fill product price"),
  properties: yup.array().of(
    yup.object({
      name: yup.string().required("Please specify property name"),
      value: yup.string().required("Please specify property value"),
    }),
  ),
  categories: yup.array().of(
    yup.object({
      id: yup.number().required("Please select category"),
    }),
  ),
  productSKUs: yup.array().of(
    yup.object({
      productCode: yup
        .string()
        .trim()
        .required("Please fill product code")
        .min(3, "notSupportProductCodeLength")
        .max(50, "notSupportProductCodeLength")
        .test("isSupportCharacter", "notSupportProductCode", (value) => {
          const isSupportCharacter = value && value.match(REGEX_PRODUCT_CODE_SUPPORT_CHARACTER);
          if (isSupportCharacter) {
            return true;
          }
          return false;
        }),
      options: yup
        .array()
        .of(
          yup
            .object({
              name: yup.string().required("product.addFoodForm.errorMessage.variationName"),
              choices: yup.array().of(
                yup.object({
                  name: yup.string().required("product.addFoodForm.errorMessage.optionName"),
                }),
              ),
            })
            // eslint-disable-next-line func-names
            .test("isDuplicateChoice", "Choice is duplicated", function (option: ProductOptionType) {
              const duplicatedChoices = findDuplicatedObjectInArrayByKey(option.choices, "name");

              if (duplicatedChoices.length) {
                let errorMessage = "";
                duplicatedChoices.forEach((duplicatedChoice) => {
                  errorMessage += `${i18n.t("product.addFoodForm.errorMessage.duplicateChoice", {
                    duplicatedChoice,
                    optionName: option.name,
                  })}\n`;
                });

                return this.createError({ message: errorMessage });
              }

              return true;
            })
            .test("isActive", "product.addFoodForm.errorMessage.atLeastActive", (option: ProductOptionType) => {
              let result = true;

              if (option.isActive) {
                const hasActive = option.choices.some((choice) => choice.isActive);

                if (!hasActive) {
                  result = false;
                }
              }

              return result;
            })
            .test("maximum", "product.addFoodForm.errorMessage.maximum", (option: ProductOptionType) => {
              if (option.isMultipleChoices) {
                return option.maximum <= option.choices.length;
              }

              return true;
            }),
        )
        // eslint-disable-next-line func-names
        .test("isDuplicateOption", "option is duplicated", function (options: ProductOptionType[]) {
          const duplicatedOptions = findDuplicatedObjectInArrayByKey(options, "name");
          if (duplicatedOptions.length) {
            let errorMessage = "";
            duplicatedOptions.forEach((duplicatedOption) => {
              errorMessage += `${i18n.t("product.addFoodForm.errorMessage.duplicateOption", {
                duplicatedOption,
              })}\n`;
            });

            return this.createError({ message: errorMessage });
          }

          return true;
        }),
    }),
  ),
});

export { addNewProductSchema };
