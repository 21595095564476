import Backdrop from "@material-ui/core/Backdrop";

export const ModalConfig = {
  closeAfterTransition: true,
  BackdropComponent: Backdrop,
  BackdropProps: {
    timeout: 500,
  },
  disableAutoFocus: true,
  disableEnforceFocus: true,
  "data-testid": "test-modal-id",
};
