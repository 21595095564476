import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";

import COLORS from "constants/Colors";
import Grid from "components/Grid";
import Search from "components/Search";
import CircularProgress from "components/CircularProgress";
import { ShowingResult, RowsSelectorPerPage } from "components/TablePagination";
import { Wrapper as PaginationWrapper } from "components/TablePagination/styled";
import Table from "components/Table";

import { FacebookLive } from "types/FacebookLive";
import { ProjectIdType } from "types/Project";
import { Data, OrderReportType } from "types/SalesReport";
import useIsDesktop from "utils/hooks/useIsDesktop";

import { TableWrapper } from "./TableWrapper";
import { getColumns, tableHooks } from "./customTable/config";
import { GridLoading, GridPagination, GridRowsPerPage, GridShowingResult, Hidden, Typography, Wrapper } from "./styled";
import { ROWS_PER_PAGES } from "./config";
import useGetLiveCrawlers from "../hook/useGetLiveCrawlers";

type FacebookLiveTablePropType = {
  onClickTitle: (id: string) => void;
};

const FacebookLiveTable: FC<FacebookLiveTablePropType> = ({ onClickTitle }) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const { projectId } = useParams<ProjectIdType>();

  const {
    facebookLiveLoading,
    facebookLives,
    facebookLiveTotal,
    handleChangeRowsPerPage,
    handlePageClick,
    handleSearchChange,
    page,
    rowsPerPage,
  } = useGetLiveCrawlers({
    projectId,
  });

  const tableColumns = useMemo(() => getColumns<OrderReportType>(isDesktop, onClickTitle), [isDesktop, onClickTitle]);

  return (
    <Wrapper>
      <Grid container alignItems="center" className="pb-4">
        <Grid item xs={12}>
          <Search
            defaultValue=""
            onChange={handleSearchChange}
            className="mr-3"
            placeholder={t("searchPlaceholder.marketingToolsFacebookLive")}
          />
        </Grid>
      </Grid>
      <TableWrapper>
        {facebookLiveLoading && (
          <GridLoading>
            <CircularProgress className="m-4" />
          </GridLoading>
        )}

        <Hidden className="ml-auto mr-auto" isHidden={facebookLives.length === 0}>
          <Table<Data, FacebookLive[]>
            data={facebookLives}
            columns={tableColumns}
            manualSortBy={false}
            hooks={tableHooks}
          />
        </Hidden>

        {
          // No data
          !facebookLiveLoading && facebookLives.length === 0 && (
            <Grid container justify="center" alignItems="center" className="h-100">
              <Grid item>
                <Typography variant="title2" color={COLORS.DarkLight}>
                  {t("facebookLive.table.placeholder")}
                </Typography>
              </Grid>
            </Grid>
          )
        }
      </TableWrapper>

      {/* Pagination */}
      <Grid container>
        <Grid item className="px-2" container xs={12} alignItems="center" justify="center">
          <GridShowingResult item xs={6} md="auto" lg="auto">
            <ShowingResult page={page} rowsPerPage={rowsPerPage} total={facebookLiveTotal} />
          </GridShowingResult>
          <GridPagination item xs={12} md="auto" lg="auto" container>
            <PaginationWrapper>
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                // forcePage should -1 because of ReactPaginate page start from 0 but our page start from 1
                forcePage={page - 1}
                pageCount={Math.ceil(facebookLiveTotal / rowsPerPage) || 0}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                onPageChange={handlePageClick}
                activeClassName="active"
                containerClassName="d-flex flex-wrap p-0"
                pageClassName="page-no"
                breakClassName="break"
                previousClassName="page-no previous"
                nextClassName="page-no next"
              />
            </PaginationWrapper>
          </GridPagination>

          <GridRowsPerPage item xs={6} md="auto" lg="auto" container alignItems="center" justify="flex-end">
            <Grid item>
              <Typography className="d-inline-block mr-2" color="darkGray">
                {t("salesReport.rowsSelector.show")}
              </Typography>
            </Grid>
            <Grid item>
              <RowsSelectorPerPage
                options={ROWS_PER_PAGES}
                onChange={handleChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
              />
            </Grid>
            <Grid item>
              <Typography className="d-inline-block ml-2" color="darkGray">
                {t("salesReport.rowsSelector.perPage")}
              </Typography>
            </Grid>
          </GridRowsPerPage>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default FacebookLiveTable;
