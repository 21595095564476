import get from "lodash/get";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";

import {
  DISTANCE_BASED,
  POSTAL_CODE_BASED_PRICE,
  CALCULATE_FROM_WEIGHT_AND_SIZE,
  CASH_ON_DELIVERY,
  CASH_ON_DELIVERY_DEPOSIT,
  CASH_ON_DELIVERY_DEPOSIT_THRESHOLD,
} from "constants/Shipping";

import { ShippingMethod, CALCULATE, SHIPPOP_CALCULATE, ShippingQuotationType } from "types/Shipping";

import { COD_DEPOSIT_TYPES } from "constants/Payment";
import { getCurrentLanguage } from "utils/i18n";

export const getReadableTotalShippingPrice = (price: number) => {
  return price > 0 ? `(+฿${price})` : `(ฟรี)`;
};

export const getTotalShippingPrice = (shippingMethod: ShippingMethod): number => {
  const totalShippingPrice = get(shippingMethod, "totalShippingPrice");
  if (!isUndefined(totalShippingPrice)) {
    return totalShippingPrice;
  }

  return Number(shippingMethod.fixedPrice);
};

export function getDurationWithShippingPrice(shippingMethod: ShippingMethod, isShowSelectButton = true): string {
  if (!shippingMethod) {
    return "";
  }

  const totalShippingPrice = getTotalShippingPrice(shippingMethod);
  const calculateType = get(shippingMethod, "calculateType");
  let shippingDetail = "";
  if (!shippingMethod.disabledDuration && calculateType !== CALCULATE.LALAMOVE) {
    shippingDetail =
      shippingMethod.minDuration === shippingMethod.maxDuration
        ? `${shippingMethod.minDuration} วัน `
        : `${shippingMethod.minDuration} - ${shippingMethod.maxDuration} วัน `;
  }

  // COD
  if (shippingMethod.isCODPayment) {
    if (isShowSelectButton) {
      if (calculateType !== CALCULATE.DISTANCE_BASE_PRICE) {
        return `${shippingDetail}(${CASH_ON_DELIVERY}) ${getReadableTotalShippingPrice(totalShippingPrice)}`;
      }
      return `${shippingDetail}(${CASH_ON_DELIVERY} + ${DISTANCE_BASED}) ${getReadableTotalShippingPrice(
        totalShippingPrice,
      )}`;
    }

    const shippingTypeText = [CASH_ON_DELIVERY];
    const hasDepositThreshold = has(shippingMethod, "CODPayment.depositThreshold");
    if (hasDepositThreshold) {
      const depositThreshold = get(shippingMethod, "CODPayment.depositThreshold");

      if (depositThreshold > 0) {
        shippingTypeText.push(`${CASH_ON_DELIVERY_DEPOSIT_THRESHOLD} ฿${depositThreshold}`);
      }
    }

    const hasDeposit = has(shippingMethod, "CODPayment.depositType");
    const depositType = get(shippingMethod, "CODPayment.depositType");
    if (hasDeposit && depositType !== COD_DEPOSIT_TYPES.NONE) {
      shippingTypeText.push(`${CASH_ON_DELIVERY_DEPOSIT}`);
    }

    return `(${shippingTypeText.join(" ")}) ${shippingDetail} ${getReadableTotalShippingPrice(totalShippingPrice)}`;
  }

  // DISTANCE_BASE_PRICE
  if (
    calculateType === CALCULATE.DISTANCE_BASE_PRICE ||
    (calculateType === CALCULATE.LALAMOVE && !isShowSelectButton)
  ) {
    return `${shippingDetail}(${DISTANCE_BASED})`;
  }

  // POSTAL_CODE_BASED_PRICE
  if (calculateType === CALCULATE.POSTAL_CODE_BASED_PRICE) {
    if (isShowSelectButton) {
      return `${shippingDetail}(${POSTAL_CODE_BASED_PRICE})${getReadableTotalShippingPrice(totalShippingPrice)}`;
    }
    return `${shippingDetail}(${POSTAL_CODE_BASED_PRICE})`;
  }
  // SHIPPOP
  if (calculateType === CALCULATE.SHIPPOP) {
    const shippopShippingPrice = Number(totalShippingPrice);

    // SHIPPOP : FLAT_RATE_PRICE
    if (shippingMethod.setting?.shippopSetting?.type === SHIPPOP_CALCULATE.FLAT_RATE_PRICE) {
      return `${shippingDetail}${getReadableTotalShippingPrice(shippopShippingPrice)}`;
    }
    // SHIPPOP : SHIPPOP_BASE_PRICE
    if (shippingMethod.setting?.shippopSetting?.type === SHIPPOP_CALCULATE.SHIPPOP_BASE_PRICE) {
      if (isShowSelectButton) {
        return `(${CALCULATE_FROM_WEIGHT_AND_SIZE})${getReadableTotalShippingPrice(shippopShippingPrice)}`;
      }
      return `(${CALCULATE_FROM_WEIGHT_AND_SIZE})`;
    }
    // SHIPPOP : POSTAL_CODE_BASED_PRICE
    if (shippingMethod.setting?.shippopSetting?.type === SHIPPOP_CALCULATE.POSTAL_CODE_BASED_PRICE) {
      if (isShowSelectButton) {
        return `${POSTAL_CODE_BASED_PRICE}${getReadableTotalShippingPrice(shippopShippingPrice)}`;
      }
      return `${shippingDetail}(${POSTAL_CODE_BASED_PRICE})`;
    }
  }

  return `${shippingDetail}${getReadableTotalShippingPrice(totalShippingPrice)}`;
}

/**
 * Get courier name by shipping method
 * @param defaultShippingDescription
 * @param shipping
 * @returns {string} courierName
 */
export const getCourierNameByShippingMethod = (
  defaultShippingDescription: string,
  shipping?: ShippingQuotationType | ShippingMethod,
): string => {
  if (isEmpty(shipping)) {
    return defaultShippingDescription;
  }

  const lang = getCurrentLanguage();

  return get(shipping, `${lang}DisplayName`);
};
