import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Grid from "components/Grid";
import Typography from "components/Typography";
import { SvgIcon } from "components/Icon";
import { IcCircleCheck, IcWarningOutline, IcFacebookGrey, IcLineGrey, IcTelGrey } from "components/SvgIcons";

import COLORS from "constants/Colors";
import { DATE_TIME_WITHOUT_SEPARATOR_FORMAT } from "constants/DateTimeFormat";
import { ErrorWithdrawWrapper, WithdrawCard } from "./styled";

type WithdrawalStatusCardProps = {
  isError: boolean;
  isMobile: boolean;
};

const WithdrawalStatusCard: FC<WithdrawalStatusCardProps> = (props) => {
  const { isError, isMobile } = props;
  const { t } = useTranslation();

  return (
    <WithdrawCard
      className={isMobile ? "" : "desktop"}
      noShadow
      padding={isMobile ? "95px 25px" : "95px 72px"}
      fullWidth
    >
      <Grid container direction="column" alignItems="center" className="w-100">
        <Grid item>
          <SvgIcon
            component={isError ? IcWarningOutline : IcCircleCheck}
            htmlColor={isError ? COLORS.Error : COLORS.Success}
            viewBox="3.5 3.5 12 12"
            className="p-0 mr-0"
          />
        </Grid>
        <Grid item className="pt-4">
          {!isError && (
            <Typography variant="title3" color="dark">
              {t("withdrawalModal.success.description")}
            </Typography>
          )}
          {isError && (
            <Grid
              container
              justify="center"
              style={{
                textAlign: "center",
              }}
            >
              <Typography
                variant="title7"
                color="dark"
                style={{
                  fontWeight: "bold",
                }}
              >
                {t("withdrawalModal.decline.title")}
              </Typography>
              <Typography
                variant="title7"
                color="dark"
                style={{
                  fontWeight: "bold",
                }}
              >
                {t("withdrawalModal.decline.description")}
              </Typography>
              <ErrorWithdrawWrapper className="mt-3">
                <Typography variant="body3" color="dark">
                  {t("withdrawalModal.decline.contact")}
                </Typography>
                <Typography variant="body3" color="dark" className="pt-2">
                  <IcLineGrey /> {t("withdrawalModal.decline.contact.line")}
                </Typography>
                <Typography variant="body3" color="dark" className="pt-2">
                  <IcFacebookGrey /> {t("withdrawalModal.decline.contact.facebook")}
                </Typography>
                <Typography variant="body3" color="dark" className="pt-2">
                  <IcTelGrey /> {t("withdrawalModal.decline.contact.tel")}
                </Typography>
                <Typography variant="body3" color="dark" className="pt-2">
                  {t("withdrawalModal.decline.contact.other")}
                </Typography>
              </ErrorWithdrawWrapper>
            </Grid>
          )}
        </Grid>
        <Grid item className="pt-1">
          <Typography variant="body4" color="darkLight">
            {moment().format(DATE_TIME_WITHOUT_SEPARATOR_FORMAT)}
          </Typography>
        </Grid>
      </Grid>
    </WithdrawCard>
  );
};

export default WithdrawalStatusCard;
