import styled from "styled-components";

export const ProductSKUCardWrapper = styled.div`
  background: ${({ theme }) => `${theme.COLORS.LightGray}`};
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ProductSKUCardPlaceholderWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DashedCircle = styled.div`
  margin-bottom: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px dashed ${({ theme }) => `${theme.COLORS.DarkLight}`};
`;
