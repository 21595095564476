import React, { FC, useState } from "react";
import Popover from "@material-ui/core/Popover";
import { SketchPicker, ColorResult } from "react-color";

import Typography from "components/Typography";
import Grid from "components/Grid";

import { SwatchWrapper, ColorDisplay } from "./styled";

type ColorPickerPropsType = {
  onChange: (color: string) => void;
  color: string;
};

const ColorPicker: FC<ColorPickerPropsType> = (props) => {
  const { onChange = () => {}, color } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleChangeColor = (selectedColor: ColorResult) => {
    onChange(selectedColor.hex);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <SwatchWrapper onClick={handleClick}>
        <Grid container alignItems="center">
          <Grid item>
            <ColorDisplay swatchColor={color} />
          </Grid>
          <Grid item>
            <Typography variant="title8" color="darkGray">
              {color.toUpperCase()}
            </Typography>
          </Grid>
        </Grid>
      </SwatchWrapper>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className="mt-2"
      >
        <SketchPicker color={color} onChange={handleChangeColor} />
      </Popover>
    </>
  );
};

export default ColorPicker;
