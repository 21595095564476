import { gql } from "@apollo/client";

const UPLOAD_IMAGE = gql`
  mutation uploadImage($projectId: ID!, $file: Upload!) {
    uploadImage(projectId: $projectId, file: $file)
  }
`;

const SIGNED_URL_UPLOAD_IMAGE = gql`
  mutation signedUrlForImageUpload(
    $projectId: ID!
    $imageFile: FileMetadataForSignedUrlInput!
    $type: UploadDirectoryType!
    $customerId: ID
  ) {
    signedUrlForImageUpload(projectId: $projectId, imageFile: $imageFile, type: $type, customerId: $customerId) {
      signedUrl
      publicUrl
      requestHeaders {
        key
        value
      }
      filePath
    }
  }
`;

export { UPLOAD_IMAGE, SIGNED_URL_UPLOAD_IMAGE };
