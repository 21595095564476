import { gql } from "@apollo/client";

export const SHIPPED_COD_ORDER = gql`
  mutation updateCODOrderStatusAfterShipmentPickedUp(
    $projectId: ID!
    $orderId: ID!
    $shipmentPickedUpInput: ShipmentPickedUpInput
  ) {
    updateCODOrderStatusAfterShipmentPickedUp(
      projectId: $projectId
      orderId: $orderId
      shipmentPickedUpInput: $shipmentPickedUpInput
    ) {
      id
      orderNumber
      state
      shippedDetail {
        detail {
          carrierName
          deliveryDate
          trackingNumber
          otherCarrierName
        }
      }
      updatedAt
    }
  }
`;

export const APPROVE_COD_ORDER = gql`
  mutation approveCODOrder($projectId: ID!, $orderId: ID!, $approveCODInput: ApproveCODOrderInput) {
    approveCODOrder(projectId: $projectId, orderId: $orderId, approveCODInput: $approveCODInput) {
      id
      orderNumber
      state
      paymentAmount
      paymentBankName
      paymentAt
      remark
      updatedAt
    }
  }
`;

export const REJECT_COD_ORDER = gql`
  mutation rejectCODOrder($projectId: ID!, $orderId: ID!) {
    rejectCODOrder(projectId: $projectId, orderId: $orderId) {
      id
      orderNumber
      state
      updatedAt
    }
  }
`;

export const UPDATE_COD_ORDER_DEPOSIT_BILL_IMAGE = gql`
  mutation updateCODOrderDepositBillImage($projectId: ID!, $orderId: ID!, $imagePath: String!) {
    updateCODOrderDepositBillImage(projectId: $projectId, orderId: $orderId, imagePath: $imagePath) {
      id
      orderNumber
      state
      depositBillImageSrc
      updatedAt
    }
  }
`;
