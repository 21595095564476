import * as yup from "yup";
import { validateSpecialCharacter } from "utils/common";

const schema = yup.object().shape({
  name: yup
    .string()
    .required("NAME_IS_REQUIRED")
    .test("invalid pattern", "NOT_ALLOW_SPECIAL_CHARACTER", (name: string) => !validateSpecialCharacter(name)),
  shortcutKey: yup
    .string()
    .required("SHORTCUT_KEY_IS_REQUIRED")
    .test(
      "invalid pattern",
      "NOT_ALLOW_SPECIAL_CHARACTER",
      (shortcutKey: string) => !validateSpecialCharacter(shortcutKey),
    ),
});

export default schema;
