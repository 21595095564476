import { gql } from "@apollo/client";

export const MARK_REDEEMED_BY_ID = gql`
  mutation redeemDigitalContentById($projectId: ID!, $digitalContentId: ID!, $note: String) {
    redeemDigitalContentById(projectId: $projectId, digitalContentId: $digitalContentId, note: $note) {
      id
      outputType
      content
      refId
      isSold
      isUsed
      soldAt
      usedAt
      expiredAt
      createdAt
      note
    }
  }
`;

export const RESEND_DIGITAL_CONTENT = gql`
  mutation resendDigitalContents($projectId: ID!, $orderId: ID!, $email: String) {
    resendDigitalContents(projectId: $projectId, orderId: $orderId, email: $email)
  }
`;
