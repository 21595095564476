import React, { FC } from "react";

import { SvgIcon } from "components/Icon";
import { IcSubtract } from "components/SvgIcons";

import COLORS from "constants/Colors";
import { CardListItemWrapper } from "../index.styled";

const BlankItem: FC = () => (
  <CardListItemWrapper item container xs={12} alignItems="center">
    <SvgIcon component={IcSubtract} fontSize="small" htmlColor={COLORS.DarkLight} />
  </CardListItemWrapper>
);

export default BlankItem;
