import { useMutation } from "@apollo/client";

import { CHAT_MENUS } from "graphql/richMenu/query";
import { REMOVE_CHAT_MENU } from "graphql/richMenu/mutation";
import { PLATFORM } from "types/RichMenu";

type RemoveChatMenuQueryVariables = {
  projectId: string;
  chatMenuId: string;
};

const useRemoveChatMenu = (projectId: string, platform: PLATFORM) => {
  const [removeChatMenu, { loading, error }] = useMutation<RemoveChatMenuQueryVariables>(REMOVE_CHAT_MENU, {
    refetchQueries: [
      {
        query: CHAT_MENUS,
        variables: { projectId, platform },
      },
    ],
  });

  const handleRemoveChatMenu = (chatMenuId: string) =>
    removeChatMenu({
      variables: {
        projectId,
        chatMenuId,
      },
    });

  return {
    loading,
    handleRemoveChatMenu,
    error,
  };
};

export default useRemoveChatMenu;
