import React, { createContext, useState, Dispatch, SetStateAction, FC } from "react";

interface ErrorProviderPropsType {
  children: React.ReactNode;
}

export const ErrorContext = createContext<{
  errorCode: number | null;
  setError: Dispatch<SetStateAction<number | null>>;
}>({
  errorCode: null,
  setError: () => {},
});

export const ErrorProvider: FC<ErrorProviderPropsType> = ({ children }) => {
  const [errorCode, setError] = useState<number | null>(null);
  return <ErrorContext.Provider value={{ errorCode, setError }}>{children}</ErrorContext.Provider>;
};
