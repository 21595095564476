import React, { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { OptionType, ValueType } from "react-select";
import { useFormContext } from "react-hook-form";
import capitalize from "lodash/capitalize";
import get from "lodash/get";

import ErrorText from "components/ErrorText";
import Grid from "components/Grid";
import Select from "components/Select";
import TextField from "components/TextField";
import Typography from "components/Typography";
import { BankIcon } from "components/Icon";

import { BANKS_CONFIG, BANKS } from "constants/Banks";
import { BANK_ACCOUNT_TYPE_LIST } from "constants/Payment";

type BankAccountFormType = {
  id: string;
  accountName?: string;
  accountNumber?: string;
  bankName?: string;
  bankBranch?: string;
  bankAccountType?: string;
  onChange: (fieldName: string, value: string) => void;
  showSaveButton: () => void;
};

const BANK_KEYS = Object.values(BANKS);

const bankOptions: OptionType[] = BANK_KEYS.map((bankKey: BANKS) => {
  const { name } = BANKS_CONFIG[bankKey];

  return {
    label: name,
    value: name,
  };
});

const bankAccountTypeOptions: OptionType[] = BANK_ACCOUNT_TYPE_LIST.map((value: string) => ({
  label: value,
  value,
}));

const BankAccountForm: FC<BankAccountFormType> = (props) => {
  const { t } = useTranslation();

  const { accountName, accountNumber, bankName, bankBranch, bankAccountType, id, onChange, showSaveButton } = props;

  const { register, errors } = useFormContext();

  const handleChangeBankName = (option: ValueType<OptionType>) => {
    onChange("BankName", (option as OptionType).value);
    showSaveButton();
  };

  const handleChangeBankAccountType = (option: ValueType<OptionType>) => {
    onChange("BankAccountType", (option as OptionType).value);
    showSaveButton();
  };

  const handleChangeInput = () => {
    onChange("", "");
    showSaveButton();
  };

  const formatBankOptionLabel = ({ value }: OptionType): ReactNode => {
    const bankFullName = BANKS_CONFIG[value as BANKS].fullName;

    return (
      <div className="w-100 d-flex flex-row align-items-center">
        <div>
          <BankIcon bankName={value as BANKS} />
        </div>
        <div className="ellipsis">
          {t(bankFullName)} ({value})
        </div>
      </div>
    );
  };

  const formatAccountTypeOptionLabel = ({ value }: OptionType): ReactNode => <>{t(capitalize(value))}</>;

  const defaultBankName = bankName
    ? {
        label: bankName,
        value: bankName,
      }
    : null;

  const defaultBankAccountType = bankAccountType
    ? {
        label: bankAccountType,
        value: bankAccountType,
      }
    : null;

  return (
    <Grid container>
      <Grid item container xs={12} className="pt-2">
        <Grid item className="p-1">
          <Typography variant="body3" color="darkMed">
            {t("Bank name")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Select
            defaultValue={defaultBankName}
            options={bankOptions}
            formatOptionLabel={formatBankOptionLabel}
            placeholder={t("Please select bank")}
            fullWidth
            onChange={handleChangeBankName}
            isSearchable={false}
          />
        </Grid>
        {errors[`${id}BankName`] && (
          <Grid item xs={12} className="p-1">
            <ErrorText>{t(get(errors[`${id}BankName`], "message", ""))}</ErrorText>
          </Grid>
        )}
      </Grid>
      <Grid item container xs={12} className="pt-2">
        <Grid item className="p-1">
          <Typography variant="body3" color="darkMed">
            {t("Account name")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            placeholder={t("Account name")}
            required
            fullWidth
            name={`${id}AccountName`}
            validate={register}
            defaultValue={accountName}
            onChange={handleChangeInput}
          />
        </Grid>
        {errors[`${id}AccountName`] && (
          <Grid item xs={12} className="p-1">
            <ErrorText>{t(get(errors[`${id}AccountName`], "message", ""))}</ErrorText>
          </Grid>
        )}
      </Grid>
      <Grid item container xs={12} className="pt-2">
        <Grid item className="p-1">
          <Typography variant="body3" color="darkMed">
            {t("Account number")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            type="tel"
            placeholder={t("Account number")}
            required
            fullWidth
            name={`${id}AccountNumber`}
            validate={register}
            defaultValue={accountNumber}
            onChange={handleChangeInput}
          />
        </Grid>
        {errors[`${id}AccountNumber`] && (
          <Grid item xs={12} className="p-1">
            <ErrorText>{t(get(errors[`${id}AccountNumber`], "message", ""))}</ErrorText>
          </Grid>
        )}
      </Grid>
      <Grid item container xs={12} className="pt-2">
        <Grid item className="p-1">
          <Typography variant="body3" color="darkMed">
            {t("Bank account type")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Select
            defaultValue={defaultBankAccountType}
            options={bankAccountTypeOptions}
            formatOptionLabel={formatAccountTypeOptionLabel}
            placeholder={t("Please select account type")}
            fullWidth
            onChange={handleChangeBankAccountType}
            isSearchable={false}
          />
        </Grid>
        {errors[`${id}BankAccountType`] && (
          <Grid item xs={12} className="p-1">
            <ErrorText>{t(get(errors[`${id}BankAccountType`], "message", ""))}</ErrorText>
          </Grid>
        )}
      </Grid>
      <Grid item container xs={12} className="pt-2">
        <Grid item className="p-1">
          <Typography variant="body3" color="darkMed">
            {t("Branch")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            placeholder={t("Branch")}
            required
            fullWidth
            name={`${id}Branch`}
            validate={register}
            defaultValue={bankBranch}
            onChange={handleChangeInput}
          />
        </Grid>
        {errors[`${id}Branch`] && (
          <Grid item xs={12} className="p-1">
            <ErrorText>{t(get(errors[`${id}Branch`], "message", ""))}</ErrorText>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default BankAccountForm;
