import React, { FC } from "react";

import Grid from "components/Grid";
import Typography from "components/Typography";

const PrivacyLink = () => <a href="https://www.1convo.ai/privacy"> (https://www.1convo.ai/privacy) </a>;

const TermsOfService: FC = () => {
  return (
    <Grid item xs={12}>
      <Grid container justify="center" className="pt-3 pb-4">
        <Typography variant="title2" color="primary">
          ข้อตกลงการให้บริการ
        </Typography>
      </Grid>
      <Typography variant="body3" color="darkMed">
        หากท่านไม่ยอมรับหรือตกลงตามข้อกำหนดใดของข้อตกลงการให้บริการฉบับนี้หรือฉบับที่ได้เพิ่มเติม เปลี่ยนแปลงหรือแก้ไข
        ท่านต้องหยุดใช้บริการทันที ทั้งนี้ บริษัทฯ แนะนำให้ท่านพิจารณาข้อตกลงการให้บริการและนโยบายที่เกี่ยวข้องอื่น ๆ
        อย่างสม่ำเสมอเพื่อทำความเข้าใจข้อกำหนดและเงื่อนไขทั้งหมดที่บังคับใช้กับการใช้บริการของท่าน
      </Typography>
      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        1. บทนำ
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.1) ท่านตกลงและยืนยันว่าท่านได้อ่านและเข้าใจขอบเขตการให้บริการและข้อตกลงการให้บริการฉบับนี้
          รวมถึงฉบับที่ได้เพิ่มเติม เปลี่ยนแปลง หรือแก้ไข
          ซึ่งถือเป็นส่วนหนึ่งของข้อตกลงการให้บริการฉบับนี้และเป็นข้อตกลงที่มีผลผูกพันตามกฎหมายระหว่างท่านกับบริษัท
          ดีเปิ้ลจำกัด (“บริษัทฯ ”) และใช้กับการใช้บริการของบริษัทฯโดยท่าน (“บริการ”)
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.2) หากท่านใช้บริการในนามของบริษัท ห้างหุ้นส่วน สมาคม หรือองค์กร (“องค์กร”)
          ท่านตกลงและยืนยันต่อไปว่าท่านมีอำนาจกระทำการผูกพันองค์กรสำหรับขอบเขตการให้บริการและข้อตกลงการให้บริการฉบับนี้
          โดยในกรณีนี้ คำว่า “ท่าน” จะรวมถึงองค์กรด้วย
        </Typography>
      </div>
      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        2. คำรับรองและคำรับประกัน
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          โดยการใช้บริการนี้ ท่านรับรองและรับประกันว่า
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (2.1) ท่านมีความสามารถตามกฎหมายในการเข้าทำ ลงนามและปฏิบัติตามข้อผูกผันตามข้อตกลงการให้บริการฉบับนี้
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (2.2) ข้อตกลงการให้บริการฉบับนี้เป็นข้อผูกพันที่ชอบด้วยกฎหมาย
          สมบูรณ์และมีผลผูกพันต่อท่านได้ตามข้อกำหนดของข้อตกลงฉบับนี้
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (2.3) เอกสารและ/หรือข้อมูลที่บริษัทฯ ได้รับหรือจะได้รับจากท่านนั้นเชื่อถือได้ เป็นจริง ครบถ้วน ถูกต้อง
          และไม่บิดเบือน และ
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (2.4) บริการและ/หรือส่วนใดส่วนหนึ่งของบริการดังกล่าวจะต้องใช้เพื่อวัตถุประสงค์อันชอบด้วยกฎหมายเท่านั้น
        </Typography>
      </div>
      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        3. ข้อสงวนสิทธิการรับประกัน
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (3.1) ท่านตกลงและรับทราบว่า
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (3.1.1) บริการจะถูกจัดให้บนพื้นฐานของ “สภาพจริง” และ “ตามเท่าที่มีอยู่” โดยไม่มีการรับประกันใด ๆ ทั้งสิ้น
            (ไม่ว่าโดยชัดแจ้งหรือโดยนัย)
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (3.1.2) บริษัทฯ และกรรมการ เจ้าหน้าที่ ลูกจ้าง และตัวแทนของบริษัทฯ ขอสงวนสิทธิการรับประกันใด ๆ
            (ไม่ว่าโดยชัดแจ้งหรือโดยนัย) ซึ่งรวมถึงแต่ไม่จำกัดเพียง การรับประกันโดยนัยซึ่งความสามารถในทางการค้า
            ความเหมาะสมสำหรับวัตถุประสงค์เฉพาะ ประสิทธิภาพหรือความเหมาะสมตามความมุ่งหมายในการใช้งานของท่าน กรรมสิทธิ์
            และการไม่ถูกละเมิดการบริการรวมถึงข้อมูล เนื้อหา และสิ่งใดทั้งหมดที่อยู่ในบริการดังกล่าว
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (3.1.3)
            ข้อมูลใดหรือสิ่งใดที่ดาวน์โหลดหรือได้มาด้วยประการใดผ่านการใช้บริการถูกเข้าถึงตามดุลยพินิจและความเสี่ยงของท่าน
            และท่านจะรับผิดชอบต่อความเสียหายที่เกิดขึ้นกับอุปกรณ์ของท่านหรือการสูญหายของข้อมูลที่อาจเกิดจากการดาวน์โหลดข้อมูลหรือสิ่งดังกล่าวแต่เพียงผู้เดียว
            และ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (3.1.4) บริษัทฯ
            เชื่อมต่อบริการบางส่วนกับระบบและ/หรือข้อมูลของบุคคลภายนอกและเนื้อหาหรือข้อมูลที่ได้รับและได้มาจากบุคคลภายนอก
            ดังนั้น บริษัทฯ ไม่ประกัน รับรอง หรือรับประกันในความถูกต้อง และความครบถ้วน หรือประสิทธิภาพ คุณภาพ
            และประสิทธิผลของบริการของบุคคลภายนอกนั้นแต่อย่างใด
          </Typography>
        </div>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (3.2) บริษัทฯ ไม่รับรองหรือรับประกันว่า (ก) บริการหรือส่วนใดส่วนหนึ่งของบริการ เชื่อถือได้ พร้อมใช้งาน
          เป็นปัจจุบัน หรือปราศจากข้อผิดพลาด หรือใช้ได้อย่างต่อเนื่องหรือปลอดภัย (ข) ความผิดพลาดใด ๆ
          ในบริการจะได้รับการแก้ไข และ (ค)
          แต่ละแพลตฟอร์มหรือเซิร์ฟเวอร์ของแพลตฟอร์มนั้นปราศจากไวรัสหรือส่วนประกอบที่เป็นอันตราย ส่งผ่านได้อย่างปลอดภัย
          หรือทำงานได้อย่างเหมาะสม
        </Typography>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        4. สิทธิในทรัพย์สินทางปัญญา
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (4.1) สิทธิในทรัพย์สินทางปัญญาทั้งหมดอันเกิดจากหรือเกี่ยวเนื่องกับบริการ
          (นอกเหนือจากสิทธิในทรัพย์สินทางปัญญาในสิ่งใดที่ท่านจัดหา) เป็นของบริษัทฯ
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (4.2) ท่านจะไม่ (ก) อนุญาตช่วง โอนแก่บุคคลภายนอก หรือให้บุคคลภายนอกแสวงหาประโยชน์ในทางพาณิชย์ด้วยประการใด
          หรือทำให้บุคคลภายนอกเข้าถึงแพลตฟอร์มและ/หรือบริการไม่ว่าในทางใด (ข) ทำซ้ำ ทำสำเนา ดัดแปลง
          หรือลอกเลียนแพลตฟอร์มหรือบริการและ/หรือซอฟต์แวร์บนเซิร์ฟเวอร์ (Server) หรือไวร์เลส (Wireless)
          หรืออุปกรณ์ที่ใช้อินเทอร์เน็ตเป็นพื้นฐาน หรือ (ค) แยกส่วน แปลง ถอดแบบ ถอดรหัส หรือพยายามที่จะแยก Source Code
          ของหรือแบ่งแยกซอฟต์แวร์จากแพลตฟอร์มหรือระบบ
        </Typography>
      </div>
      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        5. การคุ้มครองข้อมูล
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          ท่านตกลงและรับทราบว่าบริษัทฯ จะประมวลผลข้อมูลส่วนบุคคลของท่านตามประกาศความเป็นส่วนตัว (Privacy
          Notice)ที่ระบุบนเว็บไซต์ของบริษัทฯ <PrivacyLink /> (“ประกาศความเป็นส่วนตัว”)
          และยืนยันว่าท่านได้อ่านและเข้าใจข้อกำหนดที่ระบุในประกาศความเป็นส่วนตัวครบถ้วนแล้วและรับทราบและตกลงต่อไปว่าบริษัทฯ
          อาจแก้ไขประกาศความเป็นส่วนตัวได้
        </Typography>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        6. การชดใช้ค่าเสียหาย
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          ท่านต้องชดใช้ค่าเสียหายและป้องกันความเสียหายแก่บริษัทฯ กรรมการ เจ้าหน้าที่ ลูกจ้าง ผู้รับมอบอำนาจ
          และตัวแทนของบริษัทฯ ภายใน 30 วันนับแต่วันที่ได้รับข้อเรียกร้องจากหรือต่อความสูญเสีย ความรับผิด การเรียกร้อง
          การกระทำ การทวงถาม ความเสียหาย ต้นทุน และค่าใช้จ่าย (รวมทั้งค่าฟ้องร้องดำเนินคดีและค่าทนายความ)
          ที่เกิดขึ้นโดยบริษัทฯ หรือบุคคลใดดังกล่าวของบริษัทฯ อันเป็นผลจากหรือเกี่ยวเนื่องกับ (ก)
          การใช้บริการหรือการใช้บริการที่ผิดโดยท่าน (ข) การผิดข้อกำหนดข้อใดของข้อตกลงการให้บริการฉบับนี้ (ค)
          การละเมิดสิทธิของบุคคลภายนอกโดยท่าน หรือ (ง) การให้บริการโดยใช้หรืออ้างถึงข้อมูลที่ผิด ไม่ถูกต้อง
          หรือบิดเบือนที่ท่านจัดหาเว้นแต่เกิดจากหรือเป็นผลจากความจงใจหรือประมาทเลินเล่ออย่างร้ายแรงของบริษัทฯ
        </Typography>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        7. การจำกัดความรับผิด
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (7.1) บริษัทฯ กรรมการ เจ้าหน้าที่ ลูกจ้าง ผู้รับมอบอำนาจ หรือตัวแทนของบริษัทฯ
          ไม่ต้องรับผิดสำหรับความเสียหายโดยตรง ความเสียหายโดยอ้อม หรือค่าเสียหายสืบเนื่อง อันเกิดจากหรือเกี่ยวเนื่องกับ
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (7.1.1) การขัดข้อง การระงับชั่วคราว
            หรือการยกเลิกของบริการหรือส่วนใดส่วนหนึ่งของบริการดังกล่าวตามข้อตกลงการให้บริการฉบับนี้
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (7.1.2) การใช้บริการผิดหรือการใช้บริการที่ไม่เหมาะสมโดยท่านและ/หรือลูกจ้าง เจ้าหน้าที่ ผู้ที่ท่านแต่งตั้ง
            หรือผู้แทนของท่าน
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (7.1.3) การสื่อสารหรือข้อตกลงใดในช่องทางการสนทนา (Chat Channel)
            ระหว่างลูกค้าและ/หรือสมาชิกและแชทบอทปัญญาประดิษฐ์ (AI Chatbot) และ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (7.1.4) การกระทำหรืองดเว้นกระทำการของท่าน ลูกจ้าง เจ้าหน้าที่ ผู้ที่ท่านแต่งตั้ง หรือผู้แทนของท่าน
            และบุคคลภายนอก
          </Typography>
        </div>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (7.2) ข้อเรียกร้องใด โดยท่านต่อบริษัทฯ กรรมการ เจ้าหน้าที่ ลูกจ้าง ผู้รับมอบอำนาจ ที่ปรึกษา ผู้แทน
          หรือตัวแทนของบริษัทฯ
          สำหรับความสูญหายหรือความเสียหายที่เกิดขึ้นจากหรือเกี่ยวเนื่องกับการใช้บริการหรือความไม่สามารถใช้บริการจำกัดไว้เพียงเท่าจำนวนเงินรวมของจำนวนค่าบริการที่ชำระโดยท่านสำหรับการใช้บริการก่อนยื่นข้อเรียกร้องดังกล่าว
        </Typography>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        8. การแก้ไขเพิ่มเติม
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (8.1) ท่านตกลงและรับทราบว่าบริษัทฯ อาจเพิ่มเติม
          เปลี่ยนแปลงหรือแก้ไขข้อกำหนดใดที่ระบุในข้อตกลงการให้บริการนี้ได้ทุกเมื่อตามที่บริษัทฯ
          เห็นสมควรและท่านตกลงที่จะผูกพันตามข้อตกลงที่เพิ่มเติม เปลี่ยนแปลงหรือแก้ไขนั้นทุกประการ
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (8.2) การเพิ่มเติม เปลี่ยนแปลง
          หรือแก้ไขข้อตกลงการให้บริการฉบับนี้จะประกาศและ/หรือแจ้งให้ท่านทราบเป็นลายลักษณ์อักษรภายใน 7
          วันก่อนมีผลบังคับใช้
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (8.3) หากมีการเพิ่มเติม การเปลี่ยนแปลง หรือการแก้ไขเป็นผล (ก) ก่อให้เกิดภาระหน้าที่เพิ่มขึ้น และ/หรือ (ข)
          ก่อให้เกิดภาระค่าใช้จ่ายหรือความรับผิดเพิ่มขึ้น และบริษัทฯ ไม่ได้รับคำคัดค้านจากท่านเป็นลายลักษณ์อักษรภายใน 15
          วันนับจากวันที่ระบุบนเว็บไซต์และ/หรือการแจ้งเป็นลายลักษณ์อักษรของบริษัทฯ
          ให้ถือว่าท่านตกลงและยอมรับการเพิ่มเติม การเปลี่ยนแปลงหรือการแก้ไขดังกล่าวแล้ว
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (8.4) การใช้บริการอย่างต่อเนื่องของท่านภายต่อไปหลังจากการประกาศหรือการแจ้งแก้ไข เปลี่ยนแปลง
          หรือเพิ่มเติมไม่ว่าท่านจะพิจารณาแล้วหรือไม่ถือว่าท่านยอมรับและตกลงที่จะผูกพันตามการเพิ่มเติม การเปลี่ยนแปลง
          หรือการแก้ไขนั้นแล้ว
        </Typography>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        9. การหยุด การระงับชั่วคราว และการยกเลิก
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (9.1) บริษัทฯ
          ขอสงวนสิทธิที่จะหยุดให้บริการได้ไม่ว่าทั้งหมดหรือแต่บางส่วนตามกำหนดระยะเวลาตามปกติหรือประการอื่นใดตามหนังสือบอกกล่าวล่วงหน้าหรือโดยปราศจากหนังสือบอกกล่าวล่วงหน้า
          รวมถึง (ก) การบำรุงรักษาและการซ่อมแซมเซิร์ฟเวอร์ (Server) เครือข่ายสัญญาณ (Network) ซอฟต์แวร์
          และ/หรือแพลตฟอร์มซึ่งจำเป็นสำหรับการจัดเตรียมการเข้าถึงบริการแก่ท่าน และ (ข) เหตุสุดวิสัย
          (ตามที่นิยามด้านล่างนี้)
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (9.2) บริษัทฯ
          ขอสงวนสิทธิในการระงับชั่วคราวหรือยกเลิกบัญชีของท่านและ/หรือการเข้าถึงบริการไม่ว่าทั้งหมดหรือบางส่วน
          เนื่องจากเหตุการณ์ดังต่อไปนี้
        </Typography>
        <div className="pt-1 pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (9.2.1) ท่านไม่ปฏิบัติตามหรือผิดข้อกำหนดในข้อตกลงการให้บริการนี้
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (9.2.2) ท่านไม่ชำระเงินซึ่งครบกำหนดชำระภายใต้ข้อตกลงการให้บริการนี้
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (9.2.3) ข้อมูลใด ๆ รวมถึงรายละเอียดบริการ สินค้า ผลิตภัณฑ์ หรือราคาที่ท่านให้แก่บริษัทฯ ผิดพลาด ไม่ถูกต้อง
            หรือบิดเบือน
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (9.2.4) เหตุสุดวิสัย หรือ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (9.2.5) การให้บริการของบริษัทฯ ผิดกฎหมายหรือขัดต่อความสงบเรียบร้อยหรือศีลธรรมอันดีของประชาชนหรือบริษัทฯ
            มีเหตุอันควรเชื่อได้ว่ามีการใช้บริการหรือการเข้าทำธุรกรรมใดด้วยระบบตอบรับอัตโนมัติหรือมีการใช้บริการในทางที่ผิดหรือในทางที่มิชอบ
          </Typography>
        </div>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (9.3)
          โดยไม่กระทบต่อสิทธิและหน้าที่ของคู่สัญญาแต่ละฝ่ายที่มีอยู่จนถึงเวลาที่ยกเลิกบริการและสิทธิและการเยียวยาอื่นใดที่มีอยู่ภายใต้ข้อตกลงการให้บริการ
          บริษัทฯ
          อาจยกเลิกการให้บริการโดยมีผลทันทีโดยส่งหนังสือบอกกล่าวแก่ท่านเมื่อเกิดเหตุการณ์อย่างใดอย่างหนึ่งดังต่อไปนี้
        </Typography>

        <div className="pt-1 pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (9.3.1)
            ท่านกระทำการผิดข้อกำหนดที่เป็นสาระสำคัญหรือไม่ปฏิบัติหน้าที่อย่างใดอย่างหนึ่งที่กำหนดในข้อตกลงการให้บริการและ
            (หากเยียวยาแก้ไขได้) ไม่เยียวยาแก้ไขความผิดนั้นภายในระยะเวลา 14 วัน
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (9.3.2) ท่านเลิกกิจการ หรือมีคำสั่งให้เลิกกิจการ หรือล้มละลาย
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (9.3.3)
            ท่านตกเป็นผู้ล้มละลายหรือถือจะตกเป็นผู้ล้มละลายรือไม่สามารถชำระหนี้เมื่อถึงกำหนดชำระได้ภายใต้กฎหมายที่ใช้บังคับ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (9.3.4) เหตุสุดวิสัย หรือ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (9.3.5)
            มีการเปลี่ยนแปลงกฎหมายหรือกฎเกณฑ์ของหน่วยงานราชการซึ่งทำให้การปฏิบัติหน้าที่ตามข้อตกลงการให้บริการนี้ผิดกฎหมาย
          </Typography>
        </div>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (9.4) ท่านอาจบอกเลิกหรือยกเลิกการใชบริการส่วนใดได้ทุกเมื่อและตกลงและรับทราบว่าบริษัทฯ
          มีสิทธิคิดค่าธรรมเนียมการให้บริการ ค่าธรรมเนียมเพิ่มเติม ค่าขนส่ง ค่าไปรษณีย์ ค่าธรรมเนียมและค่าใช้จ่ายอื่น ๆ
          หรือเงินหรือค่าตอบแทนที่ค้างชำระซึ่งเกิดขึ้นจากการใช้บริการของท่านได้ (“ค่าธรรมเนียม”)
          โดยหักจากบัตรเครดิตหรือบัตรเดบิตของท่านจนกว่าการบอกเลิกหรือการยกเลิกนั้นจะมีผลใช้บังคับ
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (9.5) เพื่อหลีกเลี่ยงข้อสงสัย ท่านตกลงและรับทราบว่าค่าธรรมเนียมซึ่งท่านชำระให้แก่บริษัทฯ
          เป็นค่าบริการไม่สามารถขอคืน แลกเปลี่ยน โอน หรือแลกคืนได้ไม่ว่าในกรณีใด
          ภายใต้ข้อจำกัดใดที่กำหนดโดยบทบัญญัติแห่งกฎหมาย
          นโยบายการไม่คืนค่าบริการนี้ให้มีผลบังคับใช้ได้ตลอดระยะเวลาไม่ว่าท่านหรือบริษัทฯ
          จะตัดสินใจบอกเลิกหรือยกเลิกการใช้บริการส่วนใดก็ตาม
        </Typography>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        10. เหตุสุดวิสัย
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (10.1) บริษัทฯ มิได้ผิดข้อตกลงการให้บริการหรือรับผิดสำหรับ ความล่าช้าในการดำเนินการ
          หรือไม่สามารถปฏิบัติหน้าที่ใดหากความล่าช้าหรือความไม่สามารถดำเนินการดังกล่าวเป็นผลจากเหตุการณ์ พฤติการณ์
          หรือเหตุอันใดที่นอกเหนือการควบคุมตามสมควรของบริษัทฯ รวมถึงความชำรุดเสียหายของคอมพิวเตอร์ เซิร์ฟเวอร์ (Server)
          หรืออุปกรณ์ หรือความล้มเหลวของบริการอินเทอร์เน็ต เว็บไซต์ แพลตฟอร์ม
          หรือแพลตฟอร์มของบุคคลภายนอกไม่ว่าด้วยเหตุใด รวมถึงกรณีการถูกโจมตีจากแฮ็คเกอร์ กรณีการถูกไวรัสคอมพิวเตอร์
          กรณีมีผลกระทบอย่างมีนัยสำคัญที่เกิดขึ้นโดยการเปลี่ยนแปลงของเทคโนโลยีด้านโทรคมนาคม
          หรือกรณีมีคำสั่งของหน่วยงานราชการหรือกฎหมาย (<strong>“เหตุสุดวิสัย”</strong>)
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (10.2) เมื่อเกิดเหตุสุดวิสัยขึ้น บริษัทฯ อาจระงับบริการหรือให้บริการล่าช้า
          ไม่ว่าบางส่วนหรือทั้งหมดแก่ท่านในระหว่างที่เหตุการณ์หรือพฤติการณ์ดังกล่าวยังคงอยู่
          และตกลงจะใช้ความพยายามที่เหมาะสมในทางพาณิชย์เพื่อดำเนินการลดผลกระทบจากการระงับหรือความล่าช้าดังกล่าวซึ่งในกรณีใดก็ตาม
          ท่านตกลงและรับทราบว่าบริษัทฯ ไม่ต้องรับผิดหรือมีหน้าที่ใด ๆ ต่อท่านสำหรับการระงับหรือความล่าช้าดังกล่าว
        </Typography>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        11. การรักษาความลับ
      </Typography>
      <Typography variant="body3" color="darkMed" className="pb-1 pl-4">
        ภายใต้เงื่อนไขของกฎหมายที่ใช้บังคับหรือหน่วยงานราชการหรือหน่วยงานกำกับดูแล
        คู่สัญญาแต่ละฝ่ายตกลงเก็บรักษาข้อมูลที่มีลักษณะเป็นความลับซึ่งเปิดเผยโดยคู่สัญญาอีกฝ่ายหนึ่งไว้เป็นความลับอย่างเคร่งครัด
        ซึ่งรวมถึงข้อมูลใด ๆ ที่เกี่ยวข้องกับหรือในเรื่องของกิจการ บริการ ผลิตภัณฑ์ ลูกค้า ผู้จัดหา (Suppliers)
        หรือแผนการส่งเสริมการขายและการตลาดของคู่สัญญาอีกฝ่ายหนึ่งนั้น
        และจะไม่เปิดเผยข้อมูลนั้นให้แก่บุคคลใดโดยปราศจากความยินยอมล่วงหน้าเป็นลายลักษณ์อักษรจากคู่สัญญาอีกฝ่าย (เว้นแต่
        เป็นการเปิดเผยแก่กรรมการ ลูกจ้าง หรือที่ปรึกษาเท่าที่จำเป็นต้องรู้ข้อมูลนั้น
        โดยที่บุคคลดังกล่าวผูกพันในการปฏิบัติหน้าที่ในการรักษาความลับตามที่ระบุไว้ในนี้ด้วย)
        หรือใช้ข้อมูลนั้นเพื่อวัตถุประสงค์อื่นใดนอกจากการปฏิบัติหน้าที่ตามบันทึกข้อตกลงฉบับนี้
      </Typography>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        12. คำบอกกล่าวและข้อมูลการติดต่อ
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (12.1)
          คำบอกกล่าวหรือการสื่อสารใดที่ให้หรือส่งให้ที่เกี่ยวเนื่องกับข้อตกลงการให้บริการฉบับนี้ให้ทำเป็นลายลักษณ์อักษรถึงคู่สัญญาที่เกี่ยวข้อง
          ณ ที่อยู่และ/หรืออีเมล์ที่ระบุไว้ในข้อตกลงการให้บริการนี้ (กรณีส่งโดยท่าน) หรือที่ระบุในเอกสารบันทึกของบริษัทฯ
          (กรณีบริษัทฯ เป็นผู้ส่ง) คำบอกกล่าวดังกล่าวจะถือว่าส่งโดยชอบแล้วหาก (ก) ส่งโดยพนักงานส่งเอกสาร เมื่อนำส่งแล้ว
          (ข) ส่งทางไปรษณีย์หรือไปรษณีย์แบบพิเศษ เมื่อนำส่งแล้ว และ (ค) ส่งทางอีเมล์ เมื่อส่งออกจากระบบแล้ว
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (12.2) หากท่านประสงค์จะสอบถามข้อสงสัยใดหรือรายงานปัญหาใดที่เกิดขึ้นจากบริการ ท่านสามารถติดต่อทีมงานของบริษัทฯ
          ได้ที่ contact@1convo.ai
        </Typography>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        13. ภาษี ต้นทุน และค่าใช้จ่าย
      </Typography>
      <Typography variant="body3" color="darkMed" className="pb-1 pl-4">
        ท่านจะรับผิดชอบภาษีใด ๆ ที่เกี่ยวข้อง (นอกเหนือจากภาษีหัก ณ ที่จ่ายในประเทศไทย ซึ่งหนังสือรับรองการหักภาษี ณ
        ที่จ่ายถูกนำส่งให้แก่บริษัทฯ ) ค่าอากร ต้นทุน และ/หรือ
        ค่าใช้จ่ายที่เกิดขึ้นจากหรือเกี่ยวเนื่องกับการเข้าทำหรือการปฏิบัติตามข้อตกลงการให้บริการฉบับนี้และต้องชดใช้แก่บริษัทฯ
        สำหรับจำนวนเงินที่เกี่ยวข้อง ซึ่งบริษัทฯ ได้จ่ายไป
      </Typography>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        14. การแบ่งแยกได้ของข้อกำหนด
      </Typography>
      <Typography variant="body3" color="darkMed" className="pb-1 pl-4">
        ข้อกำหนดใดไม่ชอบด้วยกฎหมาย ตกเป็นโมฆะ หรือมิอาจใช้บังคับได้
        ให้ข้อกำหนดข้อนั้นไม่มีผลบังคับเพียงเท่าที่ไม่สามารถบังคับใช้ได้โดยไม่กระทบความสมบูรณ์ของข้อกำหนดอื่นใดของข้อตกลงการให้บริการนี้
        นอกจากนี้ ความไม่ชอบด้วยกฎหมาย
        การตกเป็นโมฆะหรือการใช้บังคับมิได้ดังกล่าวไม่มีทำให้เป็นโมฆะซึ่งข้อกำหนดดังกล่าวหรือข้อกำหนดอื่น ๆ
        ในเขตอำนาจทางกฎหมายอื่น
      </Typography>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        15. การโอนสิทธิ
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (15.1) บริษัทฯ อาจโอนสิทธิหรือโอนโดยวิธีการใดซึ่งสิทธิหรือหน้าที่ตามข้อตกลงการให้บริการฉบับนี้ได้ทุกเมื่อ
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (15.2)
          ท่านจะไม่โอนสิทธิหรือโอนโดยวิธีการใดซึ่งสิทธิหรือหน้าที่ตามข้อตกลงการให้บริการฉบับนี้ได้โดยปราศจากความยินยอมล่วงหน้าเป็นลายลักษณ์อักษรจากบริษัทฯ
        </Typography>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        16. กฎหมายที่ใช้บังคับ
      </Typography>
      <Typography variant="body3" color="darkMed" className="pb-1 pl-4">
        ขอบเขตการให้บริการ ข้อตกลงการให้บริการฉบับนี้ และประเด็นที่เกี่ยวกับความสมบูรณ์ การตีความ
        และการบังคับใช้ใช้บังคับตามกฎหมายแห่งประเทศไทย
      </Typography>
    </Grid>
  );
};

export default TermsOfService;
