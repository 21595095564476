import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Card from "components/Card";
import Grid from "components/Grid";
import Typography from "components/Typography";
import Modal from "components/Modal";

import { SvgIcon } from "components/Icon";
import { IcConsignmentFull, IcConsignmentBrief } from "components/SvgIcons";
import { COLORS } from "constants/Colors";
import { CardWrapper } from "./style";

type ModalSelectConsignmentTypePropsType = {
  isOpen?: boolean;
  onClickFullType?: () => void;
  onClickBriefType?: () => void;
  onClose?: () => void;
};

const ModalSelectConsignmentType: FC<ModalSelectConsignmentTypePropsType> = ({
  isOpen = false,
  onClickFullType = () => {},
  onClickBriefType = () => {},
  onClose = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeIconColor={COLORS.DarkGray}>
      <Grid container className="p-4">
        <Grid item container justify="center" className="my-3">
          <Grid item>
            <Typography variant="title2" color="dark" className="text-center">
              {t("salesReport.modalSelectConsignmentType.title")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={3} justify="center" className="mt-2">
          <CardWrapper item>
            <Card onClick={onClickFullType} className="consignmentType" noShadow height={200} width={200}>
              <div className="text-center m-5">
                <SvgIcon
                  className="mb-1"
                  htmlColor="inherit"
                  component={IcConsignmentFull}
                  fontSize="default"
                  viewBox="6 6 24 24"
                />
              </div>
              <Typography variant="title8" color="inherit" className="text-center title">
                {t("salesReport.modalSelectConsignmentType.full.title")}
              </Typography>
            </Card>
          </CardWrapper>

          <CardWrapper item>
            <Card onClick={onClickBriefType} className="consignmentType" noShadow height={200} width={200}>
              <div className="text-center m-5">
                <SvgIcon
                  className="mb-1"
                  htmlColor="inherit"
                  component={IcConsignmentBrief}
                  fontSize="default"
                  viewBox="6 6 24 24"
                />
              </div>
              <Typography variant="title8" color="inherit" className="text-center title">
                {t("salesReport.modalSelectConsignmentType.brief.title")}
              </Typography>
            </Card>
          </CardWrapper>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ModalSelectConsignmentType;
