export const ORDER_STATUSES: Record<string, string> = {
  ORDER_PLACED: "salesReport.state.newOrder",
  PENDING_FOR_SHIPPING_COST: "salesReport.state.pending",
  WAIT_FOR_PAYMENT_APPROVE: "salesReport.state.readyToApprove",
  PREPARE_TO_SHIPPING: "salesReport.state.readyToShip",
  SHIPPED: "salesReport.state.completed",
  ABANDONED: "salesReport.state.abandoned",
  CANCELLED: "salesReport.state.cancelled",
  ALL: "salesReport.state.all",
  COD_PICKED_UP: "salesReport.state.CODApprove",
  COD_PREPARE_TO_SHIP: "salesReport.state.CODReadyToShip",
  COD_REJECTED: "salesReport.state.CODCancelled",
  COMPLETED: "salesReport.state.completed",
  VOIDED: "salesReport.state.voided",
  REFUNDED: "salesReport.state.refunded",
};
