import { gql } from "@apollo/client";

export const REFUND_ORDER = gql`
  mutation refundOrder($projectId: ID!, $orderId: ID!, $reason: String!) {
    refundOrder(projectId: $projectId, orderId: $orderId, reason: $reason) {
      id
      orderNumber
      state
      updatedAt
      voidedBy
      voidedAt
      voidReason
    }
  }
`;
