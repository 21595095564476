import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";

import COLORS from "constants/Colors";
import Typography from "components/Typography";
import Grid from "components/Grid";
import Button from "components/Button";
import { SvgIcon } from "components/Icon";
import { IcClose } from "components/SvgIcons";
import { ButtonColors } from "types/Button";
import { ModalConfig } from "../config";
import { useStyles } from "../styled";

type PopConfirmPropsType = {
  isOpen: boolean;
  description: React.ReactNode;
  onClose: () => void;
  onSubmit: (event: MouseEvent) => void;
  textSubmit?: React.ReactNode;
  textCancel?: React.ReactNode;
  className?: string;
  isShowCancel?: boolean;
  isShowCloseIcon?: boolean;
  confirmButtonColor?: ButtonColors;
};

const PopConfirm: FC<PopConfirmPropsType> = (props) => {
  const { t } = useTranslation();
  const defaultClass = useStyles();
  const {
    isOpen,
    onClose,
    description,
    onSubmit,
    textSubmit = t("Confirm"),
    textCancel = t("Cancel"),
    isShowCancel = true,
    confirmButtonColor = ButtonColors.PRIMARY,
    isShowCloseIcon = true,
    ...rest
  } = props;
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal {...ModalConfig} className={`${defaultClass.modal}`} open={isOpen} onClose={handleClose} {...rest}>
      <Fade in={isOpen}>
        <Box className={defaultClass.paper}>
          <Box className="mb-4 text-center">
            <Typography variant="body3" className="text-center px-4" style={{ fontSize: 14 }}>
              {description}
            </Typography>
          </Box>
          <Grid item container>
            {isShowCancel && (
              <Grid item xs={6} className="p-0 pr-2">
                <Button
                  data-testid="cancel-testid"
                  size="small"
                  type="button"
                  color="secondary"
                  onClick={onClose}
                  fullWidth
                >
                  {textCancel}
                </Button>
              </Grid>
            )}
            <Grid item xs={isShowCancel ? 6 : 12} className="p-0 pl-2">
              <Button
                size="small"
                type="button"
                fullWidth
                onClick={onSubmit}
                data-testid="confirm-testid"
                color={confirmButtonColor}
              >
                {textSubmit}
              </Button>
            </Grid>
          </Grid>
          {isShowCloseIcon && (
            <Box position="absolute" top="0" right="0" width={36} height={36} onClick={onClose}>
              <SvgIcon
                className="mt-1"
                component={IcClose}
                fontSize="large"
                htmlColor={COLORS.Dark}
                style={{ width: 24, height: 24 }}
              />
            </Box>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

PopConfirm.displayName = "PopConfirm";

export default PopConfirm;
