export enum InvoiceTypes {
  MAIN = "MAIN",
  LOGISTIC = "LOGISTIC",
}

export type InvoiceType = {
  id: string;
  type: InvoiceTypes;
  startBillingDate: string;
  invoiceNumber: string;
  totalPrice: number;
};

export type InvoiceConnectionType = {
  results: InvoiceType[];
  total: number;
};

export type InvoiceQueryType = {
  invoices: InvoiceConnectionType;
};

export type InvoiceQueryVariablesType = {
  projectId: string;
  invoiceType: InvoiceTypes;
  limit: number;
  offset: number;
};
