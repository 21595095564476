export const DATE_TIME_FORMAT = "dd MM,YYYY HH:mm";
export const DAY_WITH_SHORT_MONTH_FORMAT = "DD MMM";
export const DATE_PICKER_DATE_FORMAT = "DD.MM.YYYY";
export const DATE_TIME_PICKER_FORMAT = "DD/MM/YYYY HH:mm";
export const HEADER_ORDER_DATE_FORMAT = "MMM DD, YYYY | HH:mm";
export const TIME_FORMAT = "HH:mm";
export const DATE_FORMAT = "MMM DD, YYYY";
export const CAMPAIGN_DATE_TIME_FORMAT = "MMM DD, YYYY HH:mm";
export const DASHBOARD_DATE_TIME_FORMAT = "DD MMM YYYY, HH:mm";
export const BROADCAST_DATE_TIME_FORMAT = "DD MMM , YYYY | HH:mm";
export const DATE_TIME_WALLET_FORMAT = "DD MMM YYYY | HH:mm";
export const DATE_INVOICE_FORMAT = "DD MMM YYYY";
export const DEEPLE_PAY_FEE_INVOICE_DATE_FORMAT = "MMM yyyy"; // date-fn format
export const FULL_MONTH_NAME_WITH_YEAR = "MMMM YYYY";
export const CUSTOMER_NOTE_DATE_TIME_FORMAT = "DD MMM YYYY, HH:mm";
export const DATE_FORMAT_START_WITH_DATE = "DD MMM, YYYY";
export const DATE_PICKER_ORDER_FORMAT = "DD MMM YYYY";
export const DEFAULT_DATE_TIME_PICKER_FORMAT = "YYYY-MM-DDTHH:mm";
export const TIME_FORMAT_FROM_GQL = "HH:mm:ssZ";
export const CONTENT_MANAGEMENT_DATE_TIME_FORMAT = "DD MMM, YYYY | HH:mm";
export const EXPIRY_DATE_FORMAT = "YYYY-MM-DD";
export const DATE_DIGITAL_CONTENT_EXPIRY_DATE_FORMAT = "YYYY-MM-DD";
export const DATE_DIGITAL_CONTENT_DISPLAY_FORMAT = "DD MMMM YYYY";
export const DATE_TIME_WITHOUT_SEPARATOR_FORMAT = "DD MMM YYYY HH:mm";
