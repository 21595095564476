import React, { useCallback, useState, FC } from "react";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import COLORS from "constants/Colors";

import ButtonToPlanUpgrade from "components/ButtonToPlanUpgrade";
import ExportCSVButton from "components/ExportCSVButton";
import Grid from "components/Grid";

import { Device } from "types/Device";
import { DeeplePayTransactionType } from "types/DeeplePay";
import { ProjectIdType } from "types/Project";
import { ExportFileType } from "types/SalesReport";

import { notifySuccess, notifyError } from "utils/notify";
import useExportDeeplePayTransactions from "utils/hooks/DeeplePay/useExportDeeplePayTransactions";
import useDevice from "utils/hooks/useDevice";

import IncomeTransactionTable from "./TransactionReport/incomeTable";
import OutcomeTransactionTable from "./TransactionReport/OutcomeTable";
import TableWrapper from "./components/TableWrapper";
import DateFilterSelector from "./components/DateFilterSelector";

import { Typography, Wrapper, DisabledReportWrapper, DisabledReportBox } from "./styled";

const CustomTabs = withStyles({
  indicator: {
    backgroundColor: `${COLORS.Primary}`,
    height: "3px",
    borderRadius: "10px",
  },
})(Tabs);

const CustomTab = withStyles({
  root: {
    padding: "24px",
    borderLeft: "1px solid #CAD2E4",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Graphik",
    textTransform: "capitalize",
  },
})(Tab);

const CustomGrid = withStyles((theme) => ({
  root: {
    height: "100%",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      padding: [theme.spacing(1), theme.spacing(4)].join(" "),
    },
  },
}))(Grid);

const TransactionTitleBox = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(4),
    },
  },
}))(Box);

const TransactionTable: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  // #region state mgt
  // #region state mgt for tab (e.g. INCOME or WITHDRAW)
  const [tabState, setTabState] = useState<DeeplePayTransactionType>(DeeplePayTransactionType.INCOME);
  // #endregion
  // #region state mgt for date range filter (default to 7 days)
  const [startDate, setStartDateValue] = useState(moment().subtract(7, "days").toISOString());
  const [endDate, setEndDateValue] = useState(moment().toISOString());
  // #endregion
  // #endregion

  const isTransactionReportEnabled = true;

  // #region Update state for start date and end date filter
  const setStartDate = (value: string | null) => {
    if (value) {
      setStartDateValue(moment(value).toISOString());
    } else {
      setStartDateValue("");
    }
  };

  const setEndDate = (value: string | null) => {
    if (value) {
      setEndDateValue(moment(value).toISOString());
    } else {
      setEndDateValue("");
    }
  };
  // #endregion

  // #region Handle callback when client wants to export transactions
  const handleExportTransactionCompleted = useCallback(() => {
    notifySuccess(t("deeplePay.transaction.export.success"));
  }, [t]);

  const handleExportTransactionError = useCallback(() => {
    notifyError(t("Please try again later"));
  }, [t]);
  // #endregion

  const { exportDeeplePayTransactions } = useExportDeeplePayTransactions({
    onCompleted: handleExportTransactionCompleted,
    onError: handleExportTransactionError,
  });

  const handleExportTransaction = (fileType: ExportFileType) => {
    exportDeeplePayTransactions(projectId, fileType, startDate, endDate, tabState);
  };

  // #region Handle render table based on transaction type
  const renderTable = () => {
    switch (tabState) {
      case DeeplePayTransactionType.INCOME:
        return <IncomeTransactionTable projectId={projectId} />;
      case DeeplePayTransactionType.WITHDRAW:
        return <OutcomeTransactionTable projectId={projectId} />;
      default:
        return null;
    }
  };
  // #endregion

  const device = useDevice();

  return (
    <Wrapper>
      <Grid container direction="row">
        <Grid item xs={12} lg={6}>
          <Box display="flex">
            <TransactionTitleBox>
              <Typography variant="title4" color={COLORS.Dark} style={{ fontWeight: 500 }} className="text-center">
                {t("deeplePay.transaction.table.header.transaction")}
              </Typography>
            </TransactionTitleBox>
            <Box>
              <CustomTabs className="tabs" value={tabState} onChange={(_event, tabValue) => setTabState(tabValue)}>
                <CustomTab
                  label={t("deeplePay.paymentChannel.tab.title.income")}
                  value={DeeplePayTransactionType.INCOME}
                />
                <CustomTab
                  label={t("deeplePay.paymentChannel.tab.title.withdrawal")}
                  value={DeeplePayTransactionType.WITHDRAW}
                />
              </CustomTabs>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} lg={6} alignItems="center">
          <CustomGrid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <DateFilterSelector
                endDate={endDate}
                startDate={startDate}
                onEndDate={setEndDate}
                onStartDate={setStartDate}
              />
            </Grid>
            <Grid item xs={12} lg="auto">
              <Box display="flex" justifyContent="flex-end">
                {[Device.DESKTOP, Device.TABLET].includes(device) && (
                  <ExportCSVButton
                    label={t("deeplePay.transaction.export.label")}
                    subLabel={t("deeplePay.transaction.export.subLabel")}
                    onExport={handleExportTransaction}
                  />
                )}
              </Box>
            </Grid>
          </CustomGrid>
        </Grid>
      </Grid>
      <TableWrapper isBlurContent={isTransactionReportEnabled} disableMaxHeight>
        {!isTransactionReportEnabled && (
          <DisabledReportWrapper>
            <DisabledReportBox>
              <Typography variant="title2" color={COLORS.Dark} className="text-center mb-3">
                {t("salesReport.transactionReports.disabled")}
              </Typography>
              {projectId && <ButtonToPlanUpgrade label={t("DisableFeature.button")} projectId={projectId} />}
            </DisabledReportBox>
          </DisabledReportWrapper>
        )}

        {/* We have to render table and hide table when there are not reports
            to prevent table reset all filter and sortBy state in the table. */}
        {renderTable()}

        {!isTransactionReportEnabled && (
          <Grid container justify="center" className="mt-4">
            <Grid item xs={12}>
              <Typography variant="title2" color={COLORS.DarkLight} className="text-center">
                {
                  // No sales reports
                  t("salesReport.transactionReports.placeholder")
                }
              </Typography>
            </Grid>
          </Grid>
        )}
      </TableWrapper>
    </Wrapper>
  );
};

export default TransactionTable;
