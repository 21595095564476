import Colors from "./Colors";

export const TEXT_COLOR: { [key: string]: string } = {
  accent: Colors.Accent,
  dark: Colors.Dark,
  darkGray: Colors.DarkGray,
  darkLight: Colors.DarkLight,
  darkMed: Colors.DarkMed,
  gray: Colors.DarkLight,
  success: Colors.Success,
  error: Colors.Error,
  info: Colors.Info,
  warning: Colors.Warning,
  disabled: Colors.LightBlue,
  primary: Colors.Primary,
  white: Colors.White,
};

export const VARIANTS = {
  h1: {
    fontFamily: "Graphik Semibold",
    size: "3.571rem",
  },
  h2: {
    fontFamily: "Graphik Semibold",
    size: "2.286rem",
  },
  h3: {
    fontFamily: "Graphik Semibold",
    size: "2rem",
  },
  title1: {
    fontFamily: "Graphik Medium",
    size: "1.714rem",
  },
  title2: {
    fontFamily: "Graphik Medium",
    size: "1.429rem",
  },
  title3: {
    fontFamily: "Graphik Medium",
    size: "1rem",
    lineHeight: "24px",
    fontWeight: 500,
  },
  title4: {
    fontFamily: "Graphik",
    size: "1.142rem",
  },
  title7: {
    fontFamily: "Graphik Medium",
    size: "1rem",
    lineHeight: "20px",
  },
  title8: {
    fontFamily: "Graphik Medium",
    size: "0.8571rem",
    lineHeight: "18px",
  },
  title10: {
    fontFamily: "Graphik Medium",
    size: "0.7143rem",
    lineHeight: "16px",
  },
  body1: {
    fontFamily: "Graphik",
    size: "0.8571rem",
    lineHeight: "20px",
    fontWeight: 400,
  },
  body2: {
    fontFamily: "Graphik",
    size: "1rem",
    lineHeight: "20px",
  },
  body3: {
    fontFamily: "Graphik",
    size: "0.8571rem",
    lineHeight: "18px",
  },
  body4: {
    fontFamily: "Graphik",
    size: "0.7143rem",
    lineHeight: "16px",
  },
  body5: {
    fontFamily: "Graphik",
    size: "0.5714rem",
    lineHeight: "12px",
  },
  alertTitle: {
    fontFamily: "Graphik Semibold",
    size: "1rem",
  },
  alertBody: {
    fontFamily: "Graphik",
    size: "1rem",
  },
  iconText: {
    fontFamily: "Graphik",
    size: "0.571rem",
    lineHeight: "12px",
  },
};
