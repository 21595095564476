import { gql } from "@apollo/client";

export const SAVE_TOP_SELLING_CONFIG = gql`
  mutation topSellingProductConfigs(
    $projectId: ID!
    $projectInput: ProjectInput!
    $topSellingProductConfigs: [TopSellingProductInput]!
  ) {
    topSellingProductConfigs: saveTopSellingProductConfigs(
      projectId: $projectId
      topSellingProductConfigs: $topSellingProductConfigs
    ) {
      id
      projectId
      productId
      sequence
      product {
        id
        name
        productCode
        images {
          src
        }
      }
    }
    project: updateProject(projectId: $projectId, projectInput: $projectInput) {
      id
      topSellingProductPeriodInDays
    }
  }
`;
