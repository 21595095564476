import { ProductImagesDefaultType, ProductImagesType } from "types/Product";
import nanoid from "utils/nanoid";

export const getInitImages = (productImages: ProductImagesDefaultType[]) => {
  let tempImageLists: ProductImagesType[] = [];

  if (productImages) {
    tempImageLists = productImages.map((imageItem: ProductImagesDefaultType) => ({
      nanoid: nanoid(),
      src: imageItem.src,
    }));
  }
  return tempImageLists;
};
