import React, { useState, useEffect } from "react";
import moment from "moment";

import DateRange from "./DateRange";
import { FilterItemType } from "../types";

export default function DateRangeFilter(props: {
  setValue: Function;
  filterList: FilterItemType[];
  editId: string;
  keyName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any; // { start: string; end: string }
  isButtonBase?: boolean;
  disabledFuture?: boolean;
}) {
  const { setValue, filterList, editId, keyName, options, isButtonBase = false } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [startDate, setStartDate] = useState(
    options.start
      ? moment(options.start).toISOString()
      : moment("00:00", "HH:mm").subtract(6, "days").startOf("day").toISOString(),
  );
  const [endDate, setEndDate] = useState(
    options.end ? moment(options.end).toISOString() : moment().endOf("day").toISOString(),
  );

  const handleSetStartDate = (selectedDate: string | null) => {
    if (selectedDate) {
      setStartDate(selectedDate);
    }
  };

  const handleSetEndDate = (selectedDate: string | null) => {
    if (selectedDate) {
      setEndDate(selectedDate);
    }
  };

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? `date-picker-popover-${editId}` : undefined;

  useEffect(() => {
    const updateValue = filterList.map((item: { id: string }) => {
      if (item.id === editId) {
        return {
          ...item,
          [keyName]: { start: startDate, end: endDate },
        };
      }
      return item;
    });
    setValue(updateValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <DateRange
      anchorEl={anchorEl}
      endDate={endDate}
      id={id}
      isButtonBase={isButtonBase}
      isOpen={isOpen}
      onChangeEnd={handleSetEndDate}
      onChangeStart={handleSetStartDate}
      onClick={handleClick}
      onClose={handleClose}
      startDate={startDate}
    />
  );
}
