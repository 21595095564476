import { gql } from "@apollo/client";

export const WELCOME_MESSAGES = gql`
  query greetingMessage($projectId: ID!) {
    greetingMessage(projectId: $projectId) {
      type
      value
    }
  }
`;

export const FAQS = gql`
  query faqs($projectId: ID!) {
    faqs(projectId: $projectId) {
      id
      questions
      enQuestions
      isActive
      isEnabledNotification
    }
  }
`;

export const FAQ_BY_ID = gql`
  query faqById($projectId: ID!, $faqId: ID!) {
    faqById(projectId: $projectId, faqId: $faqId) {
      id
      questions
      enQuestions
      isActive
      isEnabledNotification
      responses {
        type
        value
      }
      enResponses {
        type
        value
      }
      colorIntents {
        localSynonyms
        text
        state
        index
        originalText
        overlappedIntents
      }
    }
  }
`;

export const FAQ_TRAIN_JOB_OPERATION = gql`
  query getFAQTrainJobOperation($projectId: ID!) {
    faqTrainJobOperation(projectId: $projectId) {
      id
      status
      timestamp
    }
  }
`;

export const FAQ_VALIDATION_RESULT = gql`
  query faqValidationResult($projectId: ID!, $faqId: ID!, $questions: [String]!) {
    faqValidationResult(projectId: $projectId, faqId: $faqId, questions: $questions) {
      id
      score
      duplicateQuestions
    }
  }
`;
