import React, { FC, useState, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import Grid from "components/Grid";
import CircularProgress from "components/CircularProgress";

import { INVOICES } from "graphql/billing/query";
import { InvoiceConnectionType, InvoiceQueryType, InvoiceQueryVariablesType } from "types/Billing";
import { ProjectIdType } from "types/Project";

import InvoiceHistoryTable from "./InvoiceHistoryTable";

const DISPLAY_LIMIT = 12;

const InvoiceHistory: FC = () => {
  const { projectId } = useParams<ProjectIdType>();
  const [offset, setOffset] = useState(0);

  const variables = useMemo(
    () => ({
      projectId,
      limit: DISPLAY_LIMIT,
      offset,
    }),
    [projectId, offset],
  );

  const { loading, data, fetchMore, refetch } = useQuery<InvoiceQueryType, InvoiceQueryVariablesType>(INVOICES, {
    skip: !projectId,
    variables,
  });

  const handleFetchMore = () => {
    const newOffset = offset + 1;

    fetchMore({
      variables: {
        ...variables,
        offset: newOffset,
      },
      updateQuery: (prev: { invoices: InvoiceConnectionType }, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const updatedInvoiceResults = [...prev.invoices.results, ...fetchMoreResult.invoices.results];

        return {
          ...prev,
          invoices: {
            ...fetchMoreResult.invoices,
            results: updatedInvoiceResults,
          },
        };
      },
    });

    setOffset(newOffset);
  };

  useEffect(() => {
    const refetchVariables = {
      ...variables,
      offset: 0,
    };
    refetch(refetchVariables);
  }, [projectId, refetch, variables]);

  if (loading) {
    return (
      <Grid container item justify="center">
        <CircularProgress className="m-4" />
      </Grid>
    );
  }

  if (data) {
    const invoices = data && data.invoices && data.invoices.results;
    const invoicesTotal = data && data.invoices && data.invoices.total;

    return (
      <>
        {Boolean(invoices && invoices.length) && (
          <InvoiceHistoryTable
            projectId={projectId}
            invoices={invoices}
            isLoadMore={invoices.length < invoicesTotal}
            onFetchMore={handleFetchMore}
          />
        )}
      </>
    );
  }

  return null;
};

export default InvoiceHistory;
