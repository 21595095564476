import styled from "styled-components";
import Grid from "components/Grid";

const DownloadSampleGrid = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
  display: flex;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    margin: auto;
    justify-content: center;
    padding-top: 20px;
    padding-left: 0;
  }
`;

export default DownloadSampleGrid;
