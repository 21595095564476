import { gql } from "@apollo/client";

const INVOICES = gql`
  query invoices($projectId: ID!, $invoiceType: InvoiceType, $offset: Int, $limit: Int) {
    invoices(projectId: $projectId, invoiceType: $invoiceType, offset: $offset, limit: $limit) {
      results {
        id
        type
        startBillingDate
        invoiceNumber
        totalPrice
      }
      total
    }
  }
`;

const ALL_INVOICE_COUNT = gql`
  query allInvoiceCount($projectId: ID!) {
    allInvoiceCount(projectId: $projectId)
  }
`;

const INVOICE_RECEIPT = gql`
  query shopReceiptByInvoiceNumber($projectId: ID!, $invoiceNumber: String!) {
    shopReceiptByInvoiceNumber(projectId: $projectId, invoiceNumber: $invoiceNumber)
  }
`;

export { INVOICES, ALL_INVOICE_COUNT, INVOICE_RECEIPT };
