import MUITooltip from "@material-ui/core/Tooltip";
import MUIIconButton from "@material-ui/core/IconButton";
import React, { FC, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import CopyToClipboard from "react-copy-to-clipboard";

import Typography from "components/Typography";
import { IcCopy } from "components/SvgIcons";
import useIsDesktop from "utils/hooks/useIsDesktop";
import { notifyDefault } from "utils/notify";

type CopyTextPropsType = {
  value: string;
  onCopy?: () => void;
  iconSize?: string;
  width?: string;
  height?: string;
};

const FADE_TIMEOUT = 300;

const CopyText: FC<CopyTextPropsType> = ({
  value,
  onCopy,
  children,
  iconSize = "14px",
  width = "auto",
  height = "auto",
}) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const [isCopied, setIsCopied] = useState(false);

  const handleTooltipClose = useCallback(() => {
    setIsCopied(false);
  }, []);

  const handleCopy = useCallback(() => {
    if (onCopy) onCopy();
    if (!isDesktop) notifyDefault(t("Copied"), { autoClose: 1000 });
    setIsCopied(!isCopied);
  }, [isCopied, isDesktop, onCopy, t]);

  return (
    <MUITooltip
      arrow
      style={{ width, height, padding: 0 }}
      onClose={handleTooltipClose}
      TransitionProps={{ timeout: FADE_TIMEOUT }}
      title={(isCopied ? t("Copied") : t("Copy")) as string}
      disableFocusListener
    >
      <CopyToClipboard text={value} onCopy={handleCopy}>
        <MUIIconButton aria-label="copy text" className="px-1 py-0" title={t("Copy") as string}>
          <IcCopy fontSize={iconSize} />
          {Boolean(children) && (
            <Typography variant="iconText" color="darkGray">
              {children}
            </Typography>
          )}
        </MUIIconButton>
      </CopyToClipboard>
    </MUITooltip>
  );
};

export default CopyText;
