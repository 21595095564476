import { makeStyles } from "@material-ui/core/styles";
import COLORS from "constants/Colors";

export const useTabsStyles = makeStyles(() => ({
  indicator: {
    backgroundColor: COLORS.Primary,
  },
  root: {
    marginBottom: 24,
    boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.03)",
  },
}));

export const useTabStyles = makeStyles(() => ({
  wrapper: {
    textTransform: "capitalize",
  },
}));
