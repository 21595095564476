import uniqBy from "lodash/uniqBy";
import produce from "immer";

import { CHAT_LIST_MESSAGE_TYPES, MESSAGE_TYPES } from "constants/Chat";
import { AI_SETTINGS, PROJECT, RESPONSE_MESSAGE, SPECIALIZED_KNOWLEDGE, WELCOME_MESSAGE } from "constants/Router";
import { SPECIAL_RESPONSE_MESSAGE } from "constants/ResponseMessage";
import { LatestMessageType, Message, MessageSource } from "types/Chat";

/**
 * Get display message
 * - IF message type is text and it's string, system will return its value out
 * - IF message type is in system message type, system will return its type with "DISPLAY_MESSAGE" as prefix
 * - If message type is not supported, it will simply return its type
 * @param latestMessage
 * @returns {string}
 */
export const getDisplayMessage = (
  lastMessageSource: MessageSource,
  latestMessage: LatestMessageType,
  isDeleted = false,
) => {
  if (isDeleted) {
    return "MESSAGE_HAS_BEEN_DELETED_BY_CUSTOMER";
  }

  if (latestMessage.type === MESSAGE_TYPES.TEXT && typeof latestMessage.value === "string") {
    return latestMessage.value;
  }

  const displayMessageType = CHAT_LIST_MESSAGE_TYPES[latestMessage.type];
  if (displayMessageType) {
    return `${lastMessageSource}_DISPLAY_MESSAGE_${latestMessage.type}`;
  }

  // If latest message type does not match, system should return its type
  return latestMessage.type;
};

export const replaceMyEmail = (myEmail: string, messages?: Message[]) => {
  if (!messages) {
    return [];
  }

  return messages.map(
    produce((draft: Message) => {
      if (!draft.consoleDetail) {
        return;
      }

      const email = draft.consoleDetail?.email;
      if (email === myEmail) {
        draft.consoleDetail.email = "You";
      }
    }),
  );
};

export const deduplicateMessages = (messages?: Message[]) => {
  if (!messages) {
    return [];
  }

  return uniqBy(messages, "id");
};

export const customerBorderRadius = (isCustomer: boolean) => (isCustomer ? "10px 10px 10px 0" : "10px 10px 0 10px");

export const getResponseMessageLink = (projectId: string, message: Message): string => {
  const slugMessage = message?.slug;
  // custom message slug message should be linked to nothing as this is normally coming from admin anyway
  if (!slugMessage || slugMessage === SPECIAL_RESPONSE_MESSAGE.CUSTOM) {
    return "";
  }

  switch (slugMessage) {
    // greetings and welcome message slug message should be linked to welcome message
    case SPECIAL_RESPONSE_MESSAGE.GREETING:
    case SPECIAL_RESPONSE_MESSAGE.WELCOME:
      return `/${PROJECT}/${projectId}/${AI_SETTINGS}/${WELCOME_MESSAGE}`;
    // faq message slug message should be linked to specialized knowledge
    case SPECIAL_RESPONSE_MESSAGE.FAQ:
      return `/${PROJECT}/${projectId}/${AI_SETTINGS}/${SPECIALIZED_KNOWLEDGE}`;
    default:
      return `/${PROJECT}/${projectId}/${AI_SETTINGS}/${RESPONSE_MESSAGE}/${slugMessage}`;
  }
};
