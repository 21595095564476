import React from "react";
import { useTranslation } from "react-i18next";

import Card from "components/Card";
import Typography from "components/Typography";
import Grid from "components/Grid";

import COLORS from "constants/Colors";
import { SHIPPOP_ADVANCE_SETTING_OPTIONS } from "constants/Shippop";
import { ShippopStoreSetting, SHIPPOP_CALCULATE, ShippopSenderInformation } from "types/Shipping";
import { getAddress } from "utils/address";

type ShippopInfoPropsType = {
  shippopData: ShippopStoreSetting;
  shippopCalculateType: SHIPPOP_CALCULATE;
  storeManagementFee: number;
};

const ShippopInfo: React.FC<ShippopInfoPropsType> = (props) => {
  const { t } = useTranslation();

  const { shippopData, shippopCalculateType, storeManagementFee } = props;

  const selectedAddress = (shippopData?.senderInformation || []).find(
    ({ isActive }: ShippopSenderInformation) => isActive,
  );

  const shippopAdvanceSettingData = SHIPPOP_ADVANCE_SETTING_OPTIONS.find(({ value }) => value === shippopCalculateType);

  const email = shippopData?.email || "";
  const name = (selectedAddress && selectedAddress.name) || "";
  const phone = (selectedAddress && selectedAddress.phoneNumber) || "";
  const address = getAddress(selectedAddress);

  return (
    <Card noShadow noBorder bgColor={COLORS.LightWhite} padding="24px">
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item container xs={12} sm={6}>
          <Grid item xs={12}>
            <Typography variant="title8">{t(shippopAdvanceSettingData?.title || "")}</Typography>
          </Grid>
          <Grid item xs={12} className="pt-2">
            <Typography variant="body3" color="darkMed">
              {t(shippopAdvanceSettingData?.description || "")}
            </Typography>
          </Grid>
          <Grid item xs={12} className="pt-2">
            <Typography variant="body3" color="darkMed">
              {t("shipping.shippop.storeManagementFee.title")}: {storeManagementFee} {t("THB")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6}>
          <Grid item xs={12}>
            <Typography variant="title8">{t("shipping.shippop.senderInformation.title")}</Typography>
          </Grid>
          <Grid item xs={12} className="pt-2">
            <Typography variant="body3" color="darkMed" className="pb-1">
              {t("shipping.shippop.address.label.email")}
              {email}
            </Typography>
            <Typography variant="body3" color="darkMed" className="pb-1">
              {t("shipping.shippop.address.label.name")}
              {name}
            </Typography>
            <Typography variant="body3" color="darkMed" className="pb-2">
              {t("shipping.shippop.address.label.phone")}
              {phone}
            </Typography>
            <Typography variant="body3" color="darkMed">
              {address}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ShippopInfo;
