import { gql } from "@apollo/client";

export const PAYMENT_SUMMARY = gql`
  query paymentSummary($projectId: ID!) {
    paymentSummary(projectId: $projectId) {
      projectId
      nextBillingDate
      usagePeriod
      items {
        type
        entityType
        entityLevel
        name
        price
        amount
        totalPrice
        productQuota
        subscriberQuota
        subscriptionType
        imageSearchQuota
        orderQuota
      }
      extras {
        type
        entityType
        entityLevel
        name
        price
        amount
        totalPrice
      }
      totalPrice
      subtotal
      vatOptions
      vatPrice
      mainPackagePeriod {
        startBillingDate
        endBillingDate
        subscriptionType
      }
    }
  }
`;

export const BILLING = gql`
  query billing($projectId: ID!) {
    billing(projectId: $projectId) {
      id
      projectId
      name
      address
      email
      taxId
      phoneNumber
      isVerified
      documentVerificationCertificatePath
      contactPerson
      type
      subDistrict
      district
      province
      postalCode
      titleName
    }
  }
`;

export const BILLING_OVERDUE = gql`
  query billingOverdue($projectId: ID!) {
    billingOverdue(projectId: $projectId) {
      isOverdue
      billingDate
      amount
    }
  }
`;

export const INVOICES = gql`
  query invoices($projectId: ID!, $offset: Int, $limit: Int) {
    invoices(projectId: $projectId, offset: $offset, limit: $limit) {
      results {
        id
        startBillingDate
        endBillingDate
        paidAt
        invoiceNumber
        totalPrice
        vatPrice
        paymentType
        cardBrand
        lastFourDigitCardNumber
      }
      total
    }
  }
`;

export const CREDIT_CARD = gql`
  query creditCard($projectId: ID!) {
    creditCard(projectId: $projectId) {
      id
      name
      number
      brand
      expirationYear
      expirationMonth
    }
  }
`;

export const INVOICE_USAGE = gql`
  query invoiceUsage($projectId: ID!, $invoiceId: ID!) {
    invoiceUsage(projectId: $projectId, invoiceId: $invoiceId) {
      projectId
      items {
        type
        entityType
        entityLevel
        name
        price
        amount
        totalPrice
        subscriptionType
      }
      extras {
        type
        entityType
        entityLevel
        name
        price
        amount
        totalPrice
      }
      totalPrice
      subtotal
      vatOptions
      vatPrice
      discount {
        type
        total
        promotionCode {
          id
          name
          description
          code
          isActive
          discountType
          conditionType
          discountAmount
          quota
          startDate
          endDate
          createdAt
          updatedAt
        }
      }
    }
  }
`;
