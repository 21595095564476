import React, { FC } from "react";
import { OptionType, ValueType, OptionsType, components, OptionProps, MultiValueProps } from "react-select";
import get from "lodash/get";

import Select from "components/Select";
import CheckBox from "components/Checkbox";

type MultiSelectWithCheckBoxPropsType = {
  id?: string;
  isSearchable?: boolean;
  label?: string;
  options?: OptionsType<OptionType>;
  value?: ValueType<OptionType>;
  onChange: (e: ValueType<OptionType>) => void;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: OptionsType<OptionType>;
};

export type SelectOption = {
  label: string;
  value: string;
} & OptionType;

export const MultiSelectWithCheckBox: FC<MultiSelectWithCheckBoxPropsType> = (props) => {
  const { id, options = [], value, onChange, placeholder, disabled, defaultValue, isSearchable = true } = props;

  const Option = (optionProps: OptionProps<SelectOption>) => {
    const { data } = optionProps;
    return (
      <components.Option {...optionProps}>
        <CheckBox checked={optionProps.isSelected} onChange={() => null} />
        <label className="ml-1">{data.label}</label>
      </components.Option>
    );
  };

  const MultiValue = (multiValueProps: MultiValueProps<SelectOption>) => {
    const allValues = get(multiValueProps, "selectProps.value") || [];
    const currentIndex = get(multiValueProps, "index") || 0;
    const isLastItem = Number(currentIndex + 1) === allValues.length;

    return (
      <components.MultiValue {...multiValueProps}>
        <span>
          {multiValueProps.data.label}
          {isLastItem ? "" : " , "}
        </span>
      </components.MultiValue>
    );
  };

  const ClearIndicator = () => <div />;

  return (
    <Select
      id={id}
      defaultValue={defaultValue}
      isDisabled={disabled}
      placeholder={placeholder}
      value={value}
      withCheckBoxStyle
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isMulti
      components={{ Option, MultiValue, ClearIndicator }}
      options={options}
      backspaceRemovesValue={false}
      onChange={(option: ValueType<OptionType>) => {
        onChange(option);
      }}
      isSearchable={isSearchable}
      noOutline
    />
  );
};

export default MultiSelectWithCheckBox;
