import * as React from "react";

const SvgIcCart = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.478 20.094a4.763 4.763 0 001.698-2.408l2.711-8.026a1 1 0 00-.947-1.32H9.038l-.224-2.483a3.49 3.49 0 00-1.122-2.272 3.49 3.49 0 00-2.362-.918H3.708a1.04 1.04 0 100 2.082H5.33c.74 0 1.346.555 1.41 1.293l1.63 18.065v.01a3.484 3.484 0 002.887 3.1 3.076 3.076 0 002.912 2.098 3.076 3.076 0 002.889-2.03h2.446a3.076 3.076 0 002.888 2.03 3.074 3.074 0 003.071-3.071 3.074 3.074 0 00-3.07-3.07 3.076 3.076 0 00-2.89 2.029h-2.445a3.076 3.076 0 00-2.889-2.03 3.075 3.075 0 00-2.843 1.913 1.41 1.41 0 01-.883-1.171l-.26-2.876h11.48a4.654 4.654 0 002.815-.945zm-2.086 5.161a.99.99 0 010 1.978.99.99 0 010-1.978zm-8.223 0a.99.99 0 010 1.978.99.99 0 010-1.978zm-4.173-6.298l-.77-8.535h17.207l-2.233 6.61-.01.028c-.343 1.117-1.382 1.897-2.526 1.897H9.996z"
    />
  </svg>
);

export default SvgIcCart;
