import React, { FC, ReactNode, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { OptionType, ValueType } from "react-select";
import capitalize from "lodash/capitalize";
import get from "lodash/get";

import CircularProgress from "components/CircularProgress";
import ErrorText from "components/ErrorText";
import Select, { NewControllerSelect } from "components/Select";
import Grid from "components/Grid";
import Typography from "components/Typography";

import { CARRIER_OPTIONS_WITH_LOGO } from "constants/Shipping";
import {
  COD_SHIPPING_METHOD_KEY,
  COD_THRESHOLD_KEY,
  COD_DEPOSIT_TYPE_KEY,
  COD_DEPOSIT_AMOUNT_KEY,
  COD_DEPOSIT_TYPE_LIST,
  COD_DEPOSIT_TYPES,
  COD_SHIPPING_METHOD,
  COD_MANAGEMENT_FEE_KEY,
  COD_MANAGEMENT_FEE_TYPES,
  COD_MANAGEMENT_FEE_TYPE_KEY,
  COD_MANAGEMENT_FEE_TYPES_LIST,
} from "constants/Payment";

import { DEFAULT_SHIPPING_METHOD } from "graphql/shipping/query";
import { ProjectIdType } from "types/Project";
import { ManagementFee } from "types/Payment";
import { METHOD_TYPE, ShippingMethod } from "types/Shipping";
import TextFieldPrice from "./TextFieldPrice";

type CODFormType = {
  shippingMethod?: string;
  depositAmount?: string;
  depositType?: string;
  depositThreshold?: string;
  onChange: (fieldName: string, value: string) => void;
  showSaveButton?: () => void;
  managementFee?: ManagementFee;
};

const CODForm: FC<CODFormType> = ({
  shippingMethod,
  depositAmount,
  depositType,
  depositThreshold,
  onChange,
  showSaveButton = () => {},
  managementFee,
}) => {
  const { t } = useTranslation();
  const { errors, control } = useFormContext();
  const { projectId } = useParams<ProjectIdType>();

  const { loading, data } = useQuery(DEFAULT_SHIPPING_METHOD, {
    skip: !projectId,
    variables: {
      projectId,
    },
  });

  const defaultShippingMethod: ShippingMethod[] = get(data, "defaultShippingMethod.shipping", []) || [];
  const filteredShippingMethod: ShippingMethod[] = defaultShippingMethod.filter(
    (shipping: ShippingMethod) => shipping.methodType !== METHOD_TYPE.PICK_UP,
  );

  const defaultDepositType = depositType || COD_DEPOSIT_TYPES.NONE;
  const defaultManagementFeeType = managementFee?.type || COD_MANAGEMENT_FEE_TYPES.NONE;
  const defaultManagementFee =
    defaultManagementFeeType === COD_MANAGEMENT_FEE_TYPES.PERCENTAGE ? managementFee?.percent : managementFee?.fee;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedDepositType, setSelectedDepositType] = useState<any>("");
  const [selectedManagementFeeType, setSelectedManagementFeeType] = useState<COD_MANAGEMENT_FEE_TYPES>(
    defaultManagementFeeType,
  );

  const handleChangeShippingMethod = (option: ValueType<OptionType>) => {
    onChange("ShippingMethod", (option as OptionType).value);
    showSaveButton();
  };

  const handleChangeDepositType = (option: ValueType<OptionType>) => {
    const selectedValue = (option as OptionType).value;
    onChange("DepositType", selectedValue);
    showSaveButton();

    setSelectedDepositType(selectedValue);
  };

  const handleChangeManagementFeeType = (option: ValueType<OptionType>) => {
    const selectedValue = (option as OptionType).value;
    onChange("ManagementFeeType", selectedValue);
    showSaveButton();

    setSelectedManagementFeeType(selectedValue as COD_MANAGEMENT_FEE_TYPES);
  };

  const getCarrierOption = (defaultShippingMethods: ShippingMethod[]) => {
    const defaultShippingMethodOptions = defaultShippingMethods.map(({ description, method }: ShippingMethod) => ({
      label: description,
      value: method,
    }));

    const allShippingMethodOption = {
      label: "carrier.option.label.all",
      value: COD_SHIPPING_METHOD.ALL,
    };

    return [...defaultShippingMethodOptions, allShippingMethodOption];
  };

  const depositTypeOptions = COD_DEPOSIT_TYPE_LIST.map((value: string) => ({
    label: value,
    value,
  }));

  const managementFeeTypeOptions = COD_MANAGEMENT_FEE_TYPES_LIST.map((value: string) => ({
    label: value,
    value,
  }));

  const formatCarrierOptionLabel = ({ label }: OptionType): ReactNode => {
    const carrierLogoFound = CARRIER_OPTIONS_WITH_LOGO.find(({ value: carrierName }) => carrierName === label);

    return (
      <div className="w-100 d-flex flex-row align-items-center">
        {carrierLogoFound && (
          <div>
            <img height="40" width="40" className="mr-2" src={carrierLogoFound.logo} alt={label} />
          </div>
        )}
        <div className="ellipsis">{t(label)}</div>
      </div>
    );
  };

  const formatDepositTypeOptionLabel = ({ value }: OptionType): ReactNode => <>{t(capitalize(value))}</>;

  useEffect(() => {
    setSelectedDepositType(defaultDepositType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress className="m-4" />
      </Grid>
    );
  }

  let defaultShippingMethodOption = null;
  if (filteredShippingMethod && shippingMethod) {
    const selectedShippingMethod = filteredShippingMethod.find(
      (shipping: ShippingMethod) => shipping.method === shippingMethod,
    );

    const description = get(selectedShippingMethod, "description", "carrier.option.label.all");
    const method = get(selectedShippingMethod, "method", COD_SHIPPING_METHOD.ALL);

    defaultShippingMethodOption = {
      label: description,
      value: method,
    };
  }

  const defaultDepositTypeOption = defaultDepositType
    ? {
        label: defaultDepositType,
        value: defaultDepositType,
      }
    : null;

  const defaultManagementFeeTypeOption = defaultManagementFeeType
    ? {
        label: defaultManagementFeeType,
        value: defaultManagementFeeType,
      }
    : null;

  const depositAmountLabel =
    selectedDepositType === COD_DEPOSIT_TYPES.PERCENTAGE ? "Deposit percent" : "Deposit amount";

  return (
    <Grid container>
      {filteredShippingMethod && (
        <Grid item container xs={12} className="pt-2">
          <Grid item className="p-1">
            <Typography variant="body3" color="darkMed">
              {t("CARRIER_NAME")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Select
              options={getCarrierOption(filteredShippingMethod)}
              formatOptionLabel={formatCarrierOptionLabel}
              placeholder={t("Please select carrier name")}
              fullWidth
              defaultValue={defaultShippingMethodOption}
              onChange={handleChangeShippingMethod}
              isSearchable={false}
            />
          </Grid>
          {errors[COD_SHIPPING_METHOD_KEY] && (
            <Grid item xs={12} className="p-1">
              <ErrorText>{t(get(errors[COD_SHIPPING_METHOD_KEY], "message", ""))}</ErrorText>
            </Grid>
          )}
        </Grid>
      )}
      <Grid item container xs={12} className="pt-2">
        <Grid item className="p-1">
          <Typography variant="body3" color="darkMed">
            {t("Minimum total price per order")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextFieldPrice
            control={control}
            name={COD_THRESHOLD_KEY}
            defaultValue={depositThreshold}
            onChange={() => {
              showSaveButton();
            }}
          />
        </Grid>
        {errors[COD_THRESHOLD_KEY] && (
          <Grid item xs={12} className="p-1">
            <ErrorText>{t(get(errors[COD_THRESHOLD_KEY], "message", ""))}</ErrorText>
          </Grid>
        )}
      </Grid>
      <Grid item container xs={12} className="pt-2">
        <Grid item className="p-1">
          <Typography variant="body3" color="darkMed">
            {t("Deposit type")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <NewControllerSelect
            name={COD_DEPOSIT_TYPE_KEY}
            control={control}
            options={depositTypeOptions}
            formatOptionLabel={formatDepositTypeOptionLabel}
            placeholder={t("Please select deposit type")}
            fullWidth
            defaultValue={defaultDepositTypeOption}
            onChange={handleChangeDepositType}
            isSearchable={false}
          />
        </Grid>
        {errors[COD_DEPOSIT_TYPE_KEY] && (
          <Grid item xs={12} className="p-1">
            <ErrorText>{t(get(errors[COD_DEPOSIT_TYPE_KEY], "message", ""))}</ErrorText>
          </Grid>
        )}
      </Grid>
      {selectedDepositType !== COD_DEPOSIT_TYPES.NONE && (
        <Grid item container xs={12} className="pt-2">
          <Grid item className="p-1">
            <Typography variant="body3" color="darkMed">
              {t(depositAmountLabel)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextFieldPrice
              isPercent={selectedDepositType === COD_DEPOSIT_TYPES.PERCENTAGE}
              control={control}
              name={COD_DEPOSIT_AMOUNT_KEY}
              defaultValue={depositAmount || 0}
              onChange={() => {
                showSaveButton();
              }}
            />
          </Grid>
          {errors[COD_DEPOSIT_AMOUNT_KEY] && (
            <Grid item xs={12} className="p-1">
              <ErrorText>{t(get(errors[COD_DEPOSIT_AMOUNT_KEY], "message", ""))}</ErrorText>
            </Grid>
          )}
        </Grid>
      )}
      <Grid item container xs={12} className="pt-2">
        <Grid item className="p-1">
          <Typography variant="body3" color="darkMed">
            {t("Management fee type")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <NewControllerSelect
            name={COD_MANAGEMENT_FEE_TYPE_KEY}
            control={control}
            options={managementFeeTypeOptions}
            formatOptionLabel={formatDepositTypeOptionLabel}
            placeholder={t("Please select management fee type")}
            fullWidth
            defaultValue={defaultManagementFeeTypeOption}
            onChange={handleChangeManagementFeeType}
            isSearchable={false}
          />
        </Grid>
        {errors[COD_MANAGEMENT_FEE_TYPE_KEY] && (
          <Grid item xs={12} className="p-1">
            <ErrorText>{t(get(errors[COD_MANAGEMENT_FEE_TYPE_KEY], "message", ""))}</ErrorText>
          </Grid>
        )}
      </Grid>
      {selectedManagementFeeType !== COD_MANAGEMENT_FEE_TYPES.NONE && (
        <Grid item container xs={12} className="pt-2">
          <Grid item className="p-1">
            <Typography variant="body3" color="darkMed">
              {t("Management fee")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextFieldPrice
              isPercent={selectedManagementFeeType === COD_MANAGEMENT_FEE_TYPES.PERCENTAGE}
              control={control}
              name={COD_MANAGEMENT_FEE_KEY}
              defaultValue={defaultManagementFee || 0}
              onChange={() => {
                showSaveButton();
              }}
            />
          </Grid>
          {errors[COD_MANAGEMENT_FEE_KEY] && (
            <Grid item xs={12} className="p-1">
              <ErrorText>{t(get(errors[COD_MANAGEMENT_FEE_KEY], "message", ""))}</ErrorText>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default CODForm;
