import React, { ElementType } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import type { OptionType } from "react-select";
import capitalize from "lodash/capitalize";
import get from "lodash/get";
import type { InputBaseComponentProps } from "@material-ui/core";

import ErrorText from "components/ErrorText";
import Select from "components/Select";
import Grid from "components/Grid";
import Typography from "components/Typography";
import { UnitPriceAdornment } from "components/TextField/PriceUnitAdornment";
import { NumberFormatCustomWithoutPrefix } from "components/NumberFormatCustom";
import TextField from "components/TextField";

import { CARRIER_OPTIONS_WITH_LOGO } from "constants/Shipping";
import { COD_DEPOSIT_TYPES, COD_SHIPPING_METHOD, COD_MANAGEMENT_FEE_TYPES } from "constants/Payment";

import { METHOD_TYPE, ShippingMethod } from "types/Shipping";

import { depositTypeOptions, getCarrierOption, getManagementFeeTypeName, managementFeeTypeOptions } from "./utils";

export interface CODFormProps {
  defaultShippingMethods: ShippingMethod[];
  disabled?: boolean;
}

export function CODForm({ defaultShippingMethods, disabled = false }: CODFormProps): JSX.Element {
  const { t } = useTranslation();
  const { errors, control, watch } = useFormContext();

  const filteredShippingMethod = defaultShippingMethods.filter(({ methodType }) => methodType !== METHOD_TYPE.PICK_UP);

  const formatCarrierOptionLabel = ({ label }: OptionType) => {
    const carrierLogoFound = CARRIER_OPTIONS_WITH_LOGO.find(({ value: carrierName }) => carrierName === label);

    return (
      <div className="w-100 d-flex flex-row align-items-center">
        {carrierLogoFound && (
          <div>
            <img height="40" width="40" className="mr-2" src={carrierLogoFound.logo} alt={label} />
          </div>
        )}
        <div className="ellipsis">{t(label)}</div>
      </div>
    );
  };

  const formatDepositTypeOptionLabel = ({ value }: OptionType) => t(capitalize(value));

  const managementFeeType = watch("managementFee.type") || COD_MANAGEMENT_FEE_TYPES.NONE;
  const selectedDepositType = watch("depositType") || COD_DEPOSIT_TYPES.NONE;

  return (
    <Grid container>
      <Grid item container xs={12} className="pt-2">
        <Grid item className="p-1">
          <Typography variant="body3" color="darkMed">
            {t("CARRIER_NAME")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="shippingMethod"
            control={control}
            defaultValue={COD_SHIPPING_METHOD.ALL}
            render={({ value, onChange, name }) => {
              const options = getCarrierOption(filteredShippingMethod);
              const selectedShippingMethod = filteredShippingMethod?.find?.(({ method }) => method === value);

              const defaultShippingMethodOption = {
                label: selectedShippingMethod?.description || "carrier.option.label.all",
                value: selectedShippingMethod?.method || COD_SHIPPING_METHOD.ALL,
              };

              return (
                <>
                  <Select
                    options={options}
                    value={defaultShippingMethodOption}
                    formatOptionLabel={formatCarrierOptionLabel}
                    placeholder={t("Please select carrier name")}
                    fullWidth
                    onChange={(option) => option && onChange((option as OptionType).value)}
                    isSearchable={false}
                    isDisabled={disabled}
                  />
                  {errors?.[name] && (
                    <Grid item xs={12} className="p-1">
                      <ErrorText>{t(errors?.[name]?.message)}</ErrorText>
                    </Grid>
                  )}
                </>
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} className="pt-2">
        <Grid item className="p-1">
          <Typography variant="body3" color="darkMed">
            {t("Minimum total price per order")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="depositThreshold"
            control={control}
            defaultValue={0}
            render={({ value, onChange, name }) => (
              <>
                <TextField
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  InputProps={{
                    inputComponent: (NumberFormatCustomWithoutPrefix as unknown) as ElementType<
                      InputBaseComponentProps
                    >,
                    startAdornment: <UnitPriceAdornment unit="฿" />,
                  }}
                  fullWidth
                  disabled={disabled}
                  variant="outlined"
                />
                {errors?.[name] && (
                  <Grid item xs={12} className="p-1">
                    <ErrorText>{t(errors?.[name]?.message)}</ErrorText>
                  </Grid>
                )}
              </>
            )}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} className="pt-2">
        <Grid item className="p-1">
          <Typography variant="body3" color="darkMed">
            {t("Deposit type")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="depositType"
            control={control}
            defaultValue={COD_DEPOSIT_TYPES.NONE}
            render={({ value, onChange, name }) => {
              return (
                <>
                  <Select
                    options={depositTypeOptions}
                    formatOptionLabel={formatDepositTypeOptionLabel}
                    fullWidth
                    onChange={(option) => option && onChange((option as OptionType).value)}
                    isSearchable={false}
                    placeholder={t("Please select deposit type")}
                    value={{ label: value, value }}
                    isDisabled={disabled}
                  />
                  {errors?.[name] && (
                    <Grid item xs={12} className="p-1">
                      <ErrorText>{t(errors?.[name]?.message)}</ErrorText>
                    </Grid>
                  )}
                </>
              );
            }}
          />
        </Grid>
      </Grid>
      {selectedDepositType !== COD_DEPOSIT_TYPES.NONE && (
        <Grid item container xs={12} className="pt-2">
          <Grid item className="p-1">
            <Typography variant="body3" color="darkMed">
              {selectedDepositType === COD_DEPOSIT_TYPES.PERCENTAGE ? t("Deposit percent") : t("Deposit amount")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="depositAmount"
              control={control}
              defaultValue={0}
              render={({ value, onChange, name }) => {
                return (
                  <>
                    <TextField
                      value={+value}
                      onChange={(e) => onChange(+e.target.value)}
                      InputProps={{
                        inputComponent: (NumberFormatCustomWithoutPrefix as unknown) as ElementType<
                          InputBaseComponentProps
                        >,
                        startAdornment: (
                          <UnitPriceAdornment unit={selectedDepositType === COD_DEPOSIT_TYPES.PERCENTAGE ? "%" : "฿"} />
                        ),
                      }}
                      disabled={disabled}
                      fullWidth
                      variant="outlined"
                    />

                    {errors?.[name] && (
                      <Grid item xs={12} className="p-1">
                        <ErrorText>{t(errors?.[name]?.message)}</ErrorText>
                      </Grid>
                    )}
                  </>
                );
              }}
            />
          </Grid>
        </Grid>
      )}
      <Grid item container xs={12} className="pt-2">
        <Grid item className="p-1">
          <Typography variant="body3" color="darkMed">
            {t("Management fee type")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="managementFee.type"
            control={control}
            defaultValue={COD_MANAGEMENT_FEE_TYPES.NONE}
            render={({ value, onChange, name }) => {
              const defaultValue =
                managementFeeTypeOptions.find((option) => option.value === value) || managementFeeTypeOptions[0];
              return (
                <>
                  <Select
                    options={managementFeeTypeOptions}
                    formatOptionLabel={formatDepositTypeOptionLabel}
                    placeholder={t("Please select management fee type")}
                    fullWidth
                    value={defaultValue}
                    onChange={(option) => option && onChange((option as OptionType).value)}
                    isSearchable={false}
                    isDisabled={disabled}
                  />
                  {get(errors, name) && (
                    <Grid item xs={12} className="p-1">
                      <ErrorText>{t(get(errors, [name, "message"].join(".")))}</ErrorText>
                    </Grid>
                  )}
                </>
              );
            }}
          />
        </Grid>
      </Grid>
      {managementFeeType !== COD_MANAGEMENT_FEE_TYPES.NONE && (
        <Grid item container xs={12} className="pt-2">
          <Grid item className="p-1">
            <Typography variant="body3" color="darkMed">
              {t("Management fee")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={getManagementFeeTypeName(managementFeeType)}
              control={control}
              defaultValue={0}
              render={({ value, onChange, name }) => {
                return (
                  <>
                    <TextField
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      InputProps={{
                        inputComponent: (NumberFormatCustomWithoutPrefix as unknown) as ElementType<
                          InputBaseComponentProps
                        >,
                        startAdornment: (
                          <UnitPriceAdornment unit={managementFeeType === COD_DEPOSIT_TYPES.PERCENTAGE ? "%" : "฿"} />
                        ),
                      }}
                      fullWidth
                      variant="outlined"
                      disabled={disabled}
                    />
                    {get(errors, name) && (
                      <Grid item xs={12} className="p-1">
                        <ErrorText>{t(get(errors, [name, "message"].join(".")))}</ErrorText>
                      </Grid>
                    )}
                  </>
                );
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default CODForm;
