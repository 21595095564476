import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Card from "components/Card";
import Typography from "components/Typography";

import { CARRIER_DESCRIPTION } from "constants/Shippop";
import ShippingCondition from "./ShippingConditionList";

type CarrierDetailPropsType = {
  carriers: string[];
};

const CarrierDetail: FC<CarrierDetailPropsType> = ({ carriers }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="title8" color="dark" className="pb-2">
        {t("shippop.modal.label.carrier", { count: carriers.length })}
      </Typography>

      <Card padding="24px 16px" fullWidth className="overflow-auto flex-grow-1 show-scroll">
        {carriers.map((carrier: string, index: number) => {
          const conditions = CARRIER_DESCRIPTION[carrier]?.TH || [];

          return (
            <div key={carrier} className={index ? "pt-4" : ""}>
              <Typography variant="title4" color="dark">
                {carrier}
              </Typography>

              <ShippingCondition conditions={conditions} />
            </div>
          );
        })}
      </Card>
    </>
  );
};

export default CarrierDetail;
