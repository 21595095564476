import React, { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { MenuProps } from "@material-ui/core/Menu";

import Typography from "components/Typography";
import { PopoverMenu, MenuItem } from "./index";

type MenuType = {
  icon: ReactNode;
  label: string;
  onClick: () => void;
  isDisabled?: boolean;
};

type MenuListPropsType = MenuProps & { menuList: MenuType[] };

const MenuList: FC<MenuListPropsType> = ({ anchorEl, open, onClose, menuList }) => {
  const { t } = useTranslation();

  return (
    <PopoverMenu anchorEl={anchorEl} keepMounted open={open} onClose={onClose}>
      {menuList.map(({ icon, label, onClick, isDisabled }: MenuType) => (
        <MenuItem key={label} onClick={onClick} disabled={isDisabled}>
          {icon}
          <Typography variant="body3" className="py-1 px-2  menu-label">
            {t(label)}
          </Typography>
        </MenuItem>
      ))}
    </PopoverMenu>
  );
};

export default MenuList;
