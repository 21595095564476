import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";
import MUISVGIcon from "@material-ui/core/SvgIcon";

import IcArrowDown from "components/SvgIcons/IcArrowDown";
import COLORS from "constants/Colors";

type IconArrowWrapperType = {
  isShow: boolean;
  viewBox?: string;
};

const RotatedIconWrapper = styled.div<IconArrowWrapperType>`
  position: relative;
  transition: transform 0.3s;
  transform-origin: center;
  transform: rotate(${({ isShow }) => (isShow ? 180 : 0)}deg);
`;

export const AnimatedArrowSeeMoreIcon: FC<HTMLAttributes<HTMLDivElement> & IconArrowWrapperType> = ({
  isShow,
  viewBox,
}) => (
  <RotatedIconWrapper isShow={isShow}>
    <MUISVGIcon component={IcArrowDown} fontSize="small" htmlColor={COLORS.Primary} viewBox={viewBox || "0 0 24 24"} />
  </RotatedIconWrapper>
);
