import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { IcShow } from "components/SvgIcons";
import { BankPaymentTitle, DetailContent, ExampleLink } from "../styled";

export interface BankPaymentDetailProps {
  onClickExampleLink: () => void;
}

const BankPaymentDetail: FC<BankPaymentDetailProps> = ({ onClickExampleLink }) => {
  // Hooks
  const { t } = useTranslation();

  // Event handlers
  const handleClickExampleLink = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onClickExampleLink();
  };

  return (
    <div className="pt-2 w-100">
      <div className="d-flex justify-content-between align-items-center mt-3 mb-4">
        <BankPaymentTitle>{t("bankPayment.radio.title")}</BankPaymentTitle>
        <ExampleLink onClick={handleClickExampleLink}>
          <IcShow className="mr-1" />
          <span>{t("deeplePay.details.seeExample")}</span>
        </ExampleLink>
      </div>
      <div>
        <DetailContent className="mb-2">{t("bankPayment.details.description")}</DetailContent>
      </div>
    </div>
  );
};

export default BankPaymentDetail;
