import styled from "styled-components";
import { Popover as MenuPopover } from "@material-ui/core";

import MuiBadge from "@material-ui/core/Badge";

export const Popover = styled(MenuPopover)`
  > .MuiPopover-paper {
    width: 460px;
    height: 570px;
    border: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }
`;

export const NotificationIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3px;
  padding-left: 14px;
  cursor: pointer;
  height: 20px;
  width: 20px;
`;

export const Badge = styled(MuiBadge)`
  .MuiBadge-anchorOriginTopRightRectangle {
    top: -2px;
    right: 14px;
    font-size: 11px;
    background-color: ${({ theme }) => theme.COLORS.Badge};
    color: ${({ theme }) => theme.COLORS.White};
  }
`;
