import * as React from "react";

const SvgIcCalendar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.346 17.288v-1.952c0-.292.235-.527.527-.527h1.952c.292 0 .527.235.527.527v1.952a.525.525 0 01-.527.527H6.873a.533.533 0 01-.527-.527zm4.61.521h1.952a.525.525 0 00.527-.526V15.33a.525.525 0 00-.527-.527h-1.953a.525.525 0 00-.526.527v1.953c.005.292.24.526.526.526zm4.082 0h1.953a.525.525 0 00.526-.526V15.33a.525.525 0 00-.526-.527h-1.953a.525.525 0 00-.527.527v1.953c.006.292.24.526.527.526zm-4.083 3.98h1.953a.525.525 0 00.527-.527V19.31a.525.525 0 00-.527-.527h-1.953a.525.525 0 00-.526.527v1.952c.005.292.24.527.526.527zm-4.082 0h1.952a.525.525 0 00.527-.527V19.31a.525.525 0 00-.527-.527H6.873a.525.525 0 00-.527.527v1.952c0 .292.235.527.527.527zm12.253-3.98h1.953a.525.525 0 00.527-.526V15.33a.525.525 0 00-.527-.527h-1.953a.525.525 0 00-.526.527v1.953c0 .292.234.526.526.526zM2 26.937V8.08a3.067 3.067 0 013.063-3.063H8.5v-1.9A1.113 1.113 0 019.616 2h1.746c.618 0 1.117.498 1.117 1.117v1.895h7.042V3.117c0-.619.499-1.117 1.117-1.117h1.746c.619 0 1.117.498 1.117 1.117v1.895h3.436A3.067 3.067 0 0130 8.075v18.862A3.067 3.067 0 0126.937 30H5.063A3.067 3.067 0 012 26.937zm2.37 0c0 .383.315.698.7.698h21.867a.701.701 0 00.698-.698V12.97H4.371v13.966z"
    />
  </svg>
);

export default SvgIcCalendar;
