import React from "react";
import { Hooks, UseExpandedInstanceProps, UseExpandedRowProps, Row } from "react-table";
import COLORS from "constants/Colors";
import ButtonBase from "components/ButtonBase";
import { SvgIcon } from "components/Icon";
import { IcArrowDown } from "components/SvgIcons";

const useSelection = <T extends object>(hooks: Hooks<T>) => {
  hooks.visibleColumns.push((newColumns) => [
    ...newColumns,
    {
      id: "expand",
      sticky: null,
      width: 70,
      // eslint-disable-next-line react/display-name
      Header: ({ toggleAllRowsExpanded }: UseExpandedInstanceProps<T>) => {
        return (
          <ButtonBase
            onClick={() => {
              toggleAllRowsExpanded(false);
            }}
            className="d-block m-auto"
            style={{ color: COLORS.Primary, fontWeight: "bold" }}
          />
        );
      },
      // eslint-disable-next-line react/display-name
      Cell: ({ row }: { row: UseExpandedRowProps<Row> }) => (
        <div className={`circle ${row.isExpanded ? "active" : ""}`} {...row.getToggleRowExpandedProps()}>
          <SvgIcon component={IcArrowDown} fontSize="small" viewBox="4 4 24 24" className="m-0 p-0" />
        </div>
      ),
    },
  ]);
};

export default useSelection;
