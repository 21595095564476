export const MIN_PASSWORD = 6;
export const MAX_REF_CODE = 15;

export const PHONE_NUMBER_LENGTH = 10;

export enum AuthProviderLinkStatus {
  LINK = "LINK_ACCOUNT",
  UNLINK = "UNLINK_ACCOUNT",
}

export enum ConnectSocialAccountColor {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}
