import styled from "styled-components";
import Grid from "components/Grid";
import { Z_INDEX } from "constants/ZIndex";

export const GridProductImage = styled(Grid)`
  flex: 0;
`;

export const GridProductName = styled(Grid)`
  flex: 1;
  min-width: 0;
  padding: 0 16px;
`;

export const GridProductAmount = styled(Grid)`
  flex: 0;
`;

export const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  min-width: 45px;
  min-height: 45px;
  border-radius: 8px;
  margin: auto;
  object-fit: cover;
`;

export const CircleRemoveIconContainer = styled.div`
  position: absolute;
  font-size: 18px;
  z-index: ${Z_INDEX.REMOVE_IMAGE_ICON};
  top: 0px;
  right: -10px;
`;
