import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { RadioBox, OnClickRadio } from "components/Radio";
import useIsDesktop from "utils/hooks/useIsDesktop";

import BankPaymentDetail from "./BankPaymentDetail";
import DeeplePayDetail from "./DeeplePayDetail";
import { Divider, Item, RadioBoxGroup } from "../styled";

export enum PaymentChannel {
  DeeplePay = "DeeplePay",
  BankPayment = "BankPayment",
}

export interface SwitcherProps {
  isDeeplePay: boolean;
  onClickBankPaymentExampleLink: () => void;
  onClickDeeplePayExampleLink: () => void;
  onClickLearnMoreLink: () => void;
  onClickSwitch: OnClickRadio;
}

const Switcher: FC<SwitcherProps> = ({
  isDeeplePay,
  onClickBankPaymentExampleLink,
  onClickDeeplePayExampleLink,
  onClickLearnMoreLink,
  onClickSwitch,
}) => {
  // Hooks
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  return (
    <Item container alignItems="center" className={isDesktop ? "mb-3 px-4 py-3" : "mobile mb-3 px-4 py-3"}>
      <RadioBoxGroup>
        <RadioBox
          title={t("deeplePay.title")}
          description={t("deeplePay.radio.description")}
          className={isDesktop ? "mr-3" : "mb-3"}
          isActive={isDeeplePay}
          value={PaymentChannel.DeeplePay}
          onClick={onClickSwitch}
        />
        <RadioBox
          title={t("bankPayment.radio.title")}
          description={t("bankPayment.radio.description")}
          isActive={!isDeeplePay}
          value={PaymentChannel.BankPayment}
          onClick={onClickSwitch}
        />
      </RadioBoxGroup>
      <Divider />
      {isDeeplePay ? (
        <DeeplePayDetail onClickExampleLink={onClickDeeplePayExampleLink} onClickLearnMoreLink={onClickLearnMoreLink} />
      ) : (
        <BankPaymentDetail onClickExampleLink={onClickBankPaymentExampleLink} />
      )}
    </Item>
  );
};

export default Switcher;
