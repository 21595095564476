import styled from "styled-components";
import Grid from "components/Grid";
import Typography from "components/Typography";
import { SvgIcon } from "components/Icon/index";
import { ButtonWithIcon } from "../../../../components/Button/index";

export const IconWrapper = styled.div`
  position: relative;
  top: 0;
  float: right;
  display: flex;
  flex-direction: row;
`;

export const IncreasedLineHeightTypography = styled(Typography)`
  line-height: 22px;
`;

export const AddonWrapper = styled.div`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.COLORS.LightGray};
  padding: 16px;
`;

export const GridWithPointerCursor = styled(Grid)`
  cursor: pointer;
`;

export const TextWithPointerCursor = styled(Typography)`
  // TODO: [DP-2793]: Disable billing section
  /* cursor: pointer;
  :hover {
    color: ${({ theme }) => `${theme.COLORS.Primary}`};
    text-decoration: underline;
  } */
`;

export const InvoiceTable = styled(Grid)<{ isMobile: boolean }>`
  div {
    box-sizing: border-box;
  }

  svg {
    vertical-align: middle;
    font-size: 12px;
  }

  .table-item {
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  }

  .table-item:last-child {
    border-bottom: none;
  }

  .item:nth-child(2) {
    display: ${({ isMobile }) => (isMobile ? "inline-grid" : "flex")};
    text-align: ${({ isMobile }) => (isMobile ? "right" : "left")};
  }

  .item:last-child {
    display: flex;
    cursor: pointer;
  }

  .text-left {
    display: flex;
    text-align: left;
  }

  .item-right {
    display: flex;
    justify-content: flex-end;
  }
`;

export const DownloadPDFButton = styled(ButtonWithIcon)`
  width: 100%;
  max-width: 68px;
  height: 100%;
  padding: 8px;

  &.disabled {
    border: none;
    background-color: transparent;
    pointer-events: none;
  }
`;

export const InvoiceItemCard = styled(Grid)`
  &.active {
    background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  }

  svg {
    margin-right: 6px !important;
  }
`;

export const CreditCardText = styled.span`
  font-weight: 500;
`;

export const ExpandColumn = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: ${({ theme }) => theme.COLORS.DarkMed};
  }
`;

export const PaymentWrapper = styled(Grid)`
  display: inline-flex;
  justify-content: flex-end;
  padding-top: 6px;
`;

export const InvoiceNumberText = styled(Typography)`
  &.native {
    padding-top: 6px;
  }
`;

export const ModalBody = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  text-align: center;
  position: relative;

  icon {
    width: 36px;
    height: 36px;
  }
`;

export const CloseIcon = styled(SvgIcon)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

export const ModalContainer = styled(Grid)`
  width: 100%;
  height: 100%;
  max-width: 316px;
  box-sizing: border-box;
`;
