import { gql } from "@apollo/client";

export const VAT = gql`
  query vatV2($projectId: ID!) {
    vat: vatV2(projectId: $projectId) {
      id
      projectId
      address
      companyName
      district
      isFullTaxInfo
      isShowTaxInfo
      option
      phoneNumber
      postcode
      province
      rate
      subDistrict
      taxId
      isShippingIncluded
      lang
    }
  }
`;
