import React, { FC, CSSProperties } from "react";
import CheckBox from "components/Checkbox";
import Grid from "components/Grid";

import { IcArrowDown } from "components/SvgIcons";
import { PopupSelect, OptionsType, OptionType, components, OptionProps, ActionMeta } from "@atlaskit/select";
import { SvgIcon } from "components/Icon";
import Colors from "constants/Colors";
import styled from "styled-components";
import size from "lodash/size";
import take from "lodash/take";
import { List } from "lodash";
import { NO_IMG_AVAILABLE } from "constants/Image";
import { MAXIMUM_DISPLAY_OPTION_ITEMS } from "constants/Limit";

const LabelButton = styled.button`
  height: 48px;
  width: 100%;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.COLORS.LightBlue};
  background-color: ${({ theme }) => theme.COLORS.White};
  color: ${({ theme }) => theme.COLORS.DarkGray};
  text-align: left;
  overflow: hidden;
`;

const OptionImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 8px;
`;

type MultiSelectToExcludeWithCheckBoxPropsType = {
  id?: string;
  label?: string;
  options?: OptionsType<OptionType>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (option: any, action: ActionMeta<OptionType>) => void;
  placeholder?: string;
  isDisplayUnselected?: boolean;
  isMulti?: boolean;
  isDisplayMinimumItem?: boolean;
  isDisplayImageOption?: boolean;
  maxMenuWidth?: number;
};

export const MultiSelectWithSearchBox: FC<MultiSelectToExcludeWithCheckBoxPropsType> = (props) => {
  const {
    id,
    label,
    options = [],
    placeholder,
    value,
    onChange,
    isDisplayUnselected,
    isMulti = true,
    isDisplayMinimumItem = false,
    isDisplayImageOption = false,
    maxMenuWidth,
  } = props;

  const Option = (optionProps: OptionProps<OptionType>) => {
    const { data } = optionProps;
    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <components.Option {...(optionProps as any)} key={data.value}>
        <CheckBox size="small" checked={optionProps.isSelected} />
        {isDisplayImageOption && <OptionImage className="mx-2" alt={data.label} src={data.img || NO_IMG_AVAILABLE} />}
        <label className="ml-1">{data.label}</label>
      </components.Option>
    );
  };

  const CustomStyle = {
    input: (styles: CSSProperties) => ({
      ...styles,
      padding: 0,
    }),
    control: (styles: CSSProperties, state: { isFocused: boolean }) => ({
      ...styles,
      width: "100%",
      minHeight: "48px",
      backgroundColor: Colors.White,
      borderRadius: "8px",
      border: `solid 1px ${state.isFocused ? Colors.Primary : Colors.LightBlue}`,
      color: Colors.DarkGray,
      boxShadow: "0px",
      ":hover": {
        borderColor: state.isFocused ? Colors.Primary : Colors.LightBlue,
        color: Colors.DarkLight,
      },
      ":active": {
        borderColor: Colors.Primary,
        color: Colors.DarkGray,
      },
      input: {},
    }),
    option: (styles: CSSProperties) => {
      return {
        ...styles,
        width: "100%",
        height: "48px",
        display: "flex",
        alignItems: "center",
        backgroundColor: Colors.White,
        color: Colors.DarkGray,
        cursor: "default",
        ":active": {},
      };
    },
  };

  let displayLabel = "";
  if (isDisplayUnselected) {
    displayLabel = `${Number(size(options)) - Number(size(value))} ${label}`;
  } else if (value && size(value)) {
    displayLabel = value.map(
      (data: OptionType, index: number) => `${data.label} ${index < size(value) - 1 ? ", " : ""}`,
    );
  } else {
    displayLabel = label || "";
  }

  if (isDisplayMinimumItem) {
    displayLabel = label || "";
    if (value && size(value)) {
      const itemsToDisplay = take(value as List<OptionType> | null | undefined, MAXIMUM_DISPLAY_OPTION_ITEMS);
      displayLabel = `${itemsToDisplay.map(
        (data: OptionType, index: number) => `${data.label} ${index < size(itemsToDisplay) - 1 ? "" : ""}`,
      )}${size(value) - size(itemsToDisplay) > 0 ? `+ ${size(value) - size(itemsToDisplay)} ${label}` : ""}`;
    }
  }

  return (
    <Grid container item xs={12}>
      <PopupSelect
        styles={CustomStyle}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        isMulti={isMulti as any}
        value={value}
        placeholder={placeholder}
        options={options}
        hideSelectedOptions={false}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(option: any, action: ActionMeta<OptionType>) => {
          onChange(option, action);
        }}
        closeMenuOnSelect={false}
        target={({ ref }) => (
          <LabelButton id={id} type="button" className="pl-3" ref={ref}>
            <Grid container item xs={12} justify="space-between" alignContent="center" alignItems="center">
              <Grid item xs={10} className="ellipsis">
                {displayLabel}
              </Grid>
              <Grid container item xs={2} justify="flex-end">
                <SvgIcon component={IcArrowDown} fontSize="inherit" color="inherit" className="mt-1" />
              </Grid>
            </Grid>
          </LabelButton>
        )}
        components={{
          Option,
        }}
        maxMenuWidth={maxMenuWidth}
      />
    </Grid>
  );
};

export default MultiSelectWithSearchBox;
