import { useEffect, useCallback } from "react";
import produce from "immer";

import { TotalOrderNumberStates, TotalOrderNumberStatesData } from "types/Order";
import { TOTAL_ORDER_NUMBER_STATES_CHANGED } from "graphql/order/subscription";
import { removeTypenameField } from "utils/common";
import calculateAllTotalOrderNumberState from "utils/order/calculateAllTotalOrderNumberState";
import useQueryTotalOrderNumberStates from "../useQueryTotalOrderNumberStates";

type SubscriptionData = {
  subscriptionData: { data: { totalOrderNumberStatesChanged: { totalOrderNumberStates: TotalOrderNumberStates } } };
};

const useSubscribeTotalOrderNumberStates = (projectId: string) => {
  const { data, loading, subscribeToMore } = useQueryTotalOrderNumberStates(projectId);

  const subscribeTotalOrderNumberStatesChanged = useCallback(
    () =>
      subscribeToMore({
        document: TOTAL_ORDER_NUMBER_STATES_CHANGED,
        variables: {
          projectId,
        },
        updateQuery: (prev: TotalOrderNumberStatesData, { subscriptionData }: SubscriptionData) => {
          let totalOrderNumberStates = subscriptionData?.data?.totalOrderNumberStatesChanged?.totalOrderNumberStates;

          if (!totalOrderNumberStates) {
            return prev;
          }

          totalOrderNumberStates = removeTypenameField(totalOrderNumberStates) as TotalOrderNumberStates;

          return produce(prev, (draft) => {
            Object.keys(totalOrderNumberStates).forEach((totalOrderNumberState) => {
              let badgeNo = totalOrderNumberStates[totalOrderNumberState as keyof TotalOrderNumberStates];

              if (badgeNo != null) {
                badgeNo = badgeNo > 0 ? badgeNo : 0;
                draft.totalOrderNumberStates[totalOrderNumberState as keyof TotalOrderNumberStates] = badgeNo;
              }
            });

            const allTotalOrderNumber = calculateAllTotalOrderNumberState(draft.totalOrderNumberStates);
            draft.totalOrderNumberStates.ALL = allTotalOrderNumber;
          });
        },
      }),
    [projectId, subscribeToMore],
  );

  useEffect(() => {
    const unsubscribeTotalOrderNumberStatesChanged = subscribeTotalOrderNumberStatesChanged();

    return () => {
      if (unsubscribeTotalOrderNumberStatesChanged) {
        unsubscribeTotalOrderNumberStatesChanged();
      }
    };
  }, [subscribeTotalOrderNumberStatesChanged]);

  return {
    data,
    loading,
  };
};

export default useSubscribeTotalOrderNumberStates;
