const accountSettings = {
  en: {
    "Change password": "Change password",
    Thai: "Thai",
    English: "English",
    "English (US)": "English (US)",
    "Old password": "Old password",
    "New password": "New password",
    "Confirm password": "Confirm password",
    "Confirm password must match with new password": "Confirm password must match with new password",
    "Incorrect old password": "Incorrect old password",
    "oldPassword is a required field": "Old password is a required field",
    "newPassword is a required field": "New password is a required field",
    "Email is required field": "Email is required field",
    "Name is required field": "Name is required field",
    "Password is required field": "Password is required field",
    "confirmPassword is a required field": "Confirm password is a required field",
    LINK_ACCOUNT: "Link",
    UNLINK_ACCOUNT: "Unlink",
    CONNECTED_SUCCESS: "Connect account has been completed",
    DISCONNECTED_SUCCESS: "Disconnect account has been completed",
    SOCIAL_ID_ALREADY_EXISTED: "Your user account had already been existed",
    PROVIDER_NOT_SUPPORTED: "Your service provider is unsupported",
  },
  th: {
    "Change password": "เปลี่ยนรหัสผ่าน",
    Thai: "ไทย",
    English: "อังกฤษ",
    "English (US)": "อังกฤษ",
    "Old password": "รหัสผ่านเก่า",
    "New password": "รหัสผ่านใหม่",
    "Confirm password": "ยืนยันรหัสผ่าน",
    "Confirm password must match with new password": "ยืนยันรหัสผ่านต้องตรงกับรหัสผ่านใหม่",
    "Incorrect old password": "รหัสผ่านเก่าผิด",
    "oldPassword is a required field": "ต้องการรหัสผ่านเก่า",
    "newPassword is a required field": "ต้องการรหัสผ่านใหม่",
    "Email is required field": "โปรดกรอกอีเมล",
    "Name is required field": "โปรดกรอกชื่อ",
    "Password is required field": "โปรดกรอกรหัสผ่าน",
    "confirmPassword is a required field": "ต้องการการยืนยันรหัสผ่านเก่า",
    CONNECTED_SUCCESS: "เชื่อมต่อสำเร็จแล้ว",
    DISCONNECTED_SUCCESS: "ยกเลิกการเชื่อมต่อสำเร็จ",
    SOCIAL_ID_ALREADY_EXISTED: "บัญชีที่เชื่อมต่อมีอยู่ในระบบแล้ว ไม่สามารถเชื่อมต่อได้",
    PROVIDER_NOT_SUPPORTED: "ประเภทของบัญชีไม่ถูกต้อง",
    LINK_ACCOUNT: "ผูกบัญชี",
    UNLINK_ACCOUNT: "ยกเลิกการผูก",
  },
};

export default accountSettings;
