import React, { FC } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import Grid from "components/Grid";
import CircularProgress from "components/CircularProgress";

type LoginRoutePropsType = { redirectTo: string; nextTo: string; isEmailVerified?: boolean; isLoggedin?: boolean };

const LoginRoute: FC<RouteProps & LoginRoutePropsType> = ({
  nextTo,
  component,
  isEmailVerified,
  isLoggedin,
  redirectTo,
  ...rest
}) => {
  const render = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!isLoggedin && component) return React.createElement(component as any);

    if (isLoggedin && isEmailVerified === false) return <Redirect to={redirectTo} />;
    if (isLoggedin && isEmailVerified) return <Redirect to={nextTo} />;

    return (
      <Grid container className="flex-grow-1" justify="center" alignItems="center">
        <Grid item>
          <CircularProgress size={40} />
        </Grid>
      </Grid>
    );
  };

  return <Route {...rest} render={render} />;
};

export default LoginRoute;
