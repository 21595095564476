const storeConnect = {
  en: {
    "Confirm Disconnect": "Confirm Disconnect",
    "Connect Facebook Pages to 1Convo": "Connect Facebook Pages to 1Convo",
    "Connect Instagram Business to 1Convo": "Connect Instagram Business to 1Convo",
    "Disconnect Facebook Pages": "Disconnect Facebook Pages",
    "Disconnect Instagram Business": "Disconnect Instagram Business",
    "Disconnect LINE OA": "Disconnect LINE OA",
    "Do you confirm to disconnect LINE OA from this store?": "Do you confirm to disconnect LINE OA from this store?",
    "Facebook page connect failed": "Facebook page connect failed",
    "Facebook page connect": "Facebook page connect",
    "Facebook page failed to disconnect": "Facebook page failed to disconnect",
    "Facebook page was connected": "Facebook page was connected",
    "Facebook page was disconnected": "Facebook page was disconnected",
    "Instagram Business connect": "Instagram Business connect",
    "Instagram Business failed to connect": "Instagram Business failed to connect",
    "Instagram Business failed to disconnect": "Instagram Business failed to disconnect",
    "Instagram Business was connected": "Instagram Business was connected",
    "Instagram Business was disconnected": "Instagram Business was disconnected",
    "LINE OA connect": "LINE OA connect",
    "LINE credential update failed": "LINE credential update failed",
    "LINE credential was deleted": "LINE credential was deleted",
    "connect.button": "Connect",
    "from Deeple": "from 1Convo",
    "storeConnect.connect": "Connect LINE OA",
    Cancel: "Cancel",
    Connect: "Connect",
    Pages: "Pages",
    Save: "Save",
    modalCountdown: {
      closeButtonText: "Next",
      countdownMessage: "Go to the next step in",
      countdownSecondsUnit: "seconds",
      title: "Store Connected Successfully",
    },
  },
  th: {
    "Confirm Disconnect": "ยืนยันยกเลิกการเชื่อมต่อ",
    "Connect Facebook Pages to 1Convo": "เชื่อมต่อเพจ Facebook กับ 1Convo",
    "Connect Instagram Business to 1Convo": "เชื่อมต่อ Instagram Business กับ 1Convo",
    "Disconnect Facebook Pages": "ยกเลิกการเชื่อมต่อกับ Facebook เพจ",
    "Disconnect Instagram Business": "ยกเลิกการเชื่อมต่อกับ Instagram Business",
    "Disconnect LINE OA": "ยกเลิกการเชื่อมต่อ LINE OA",
    "Do you confirm to disconnect LINE OA from this store?":
      "คุณยืนยันที่จะยกเลิกการเชื่อมต่อ LINE OA กับร้านนี้หรือไม่?",
    "Facebook page connect failed": "Facebook page เชื่อมต่อล้มเหลว",
    "Facebook page connect": "เชื่อมต่อกับเพจ Facebook",
    "Facebook page was connected": "Facebook page ถูกเชื่อมต่อ",
    "Facebook page was disconnected": "ยกเลิกการเชื่อมต่อกับ Facebook เพจ",
    "Facebook page failed to disconnect": "ยกเลิกการเชื่อมต่อกับ Facebook เพจ ล้มเหลว",
    "Instagram Business connect": "เชื่อมต่อกับ Instagram Business",
    "Instagram Business failed to connect": "Instagram Business เชื่อมต่อล้มเหลว",
    "Instagram Business failed to disconnect": "Instagram Business ยกเลิกการเชื่อมต่อล้มเหลว",
    "Instagram Business was connected": "Instagram Business ถูกเชื่อมต่อ",
    "Instagram Business was disconnected": "ยกเลิกการเชื่อมต่อกับ Instagram Business",
    "LINE OA connect": "เชื่อมต่อกับ LINE OA",
    "LINE credential update failed": "LINE credential อัพเดทล้มเหลว",
    "LINE credential was deleted": "LINE credential ถูกลบแล้ว",
    "connect.button": "เชื่อมต่อ",
    "from Deeple": "จาก 1Convo",
    "storeConnect.connect": "เชื่อมต่อ LINE OA",
    Cancel: "ยกเลิก",
    Connect: "การเชื่อมต่อ",
    Pages: "เพจ",
    Save: "บันทึก",
    modalCountdown: {
      closeButtonText: "ถัดไป",
      countdownMessage: "ไปยังขั้นตอนถัดไปใน",
      countdownSecondsUnit: "วินาที",
      title: "เชื่อมต่อร้านค้าสำเร็จ",
    },
  },
};

export default storeConnect;
