import { useState } from "react";
import { PLATFORM } from "types/Customer";
import { OrderByType } from "types/SalesReport";

import { ROWS_PER_PAGE, DEFAULT_PAGE_START } from "../config";

export enum CustomerOrderByField {
  CUSTOMER_NAME = "CUSTOMER_NAME",
  CUSTOMER_PLATFORM = "CUSTOMER_PLATFORM",
  CUSTOMER_TOTAL_SPENDING = "CUSTOMER_TOTAL_SPENDING",
}

export const MAPPED_CUSTOMER_FIELD = {
  profile: CustomerOrderByField.CUSTOMER_NAME,
  platform: CustomerOrderByField.CUSTOMER_PLATFORM,
  totalSpend: CustomerOrderByField.CUSTOMER_TOTAL_SPENDING,
};

export type Filter = {
  customerPlatforms: PLATFORM[];
  customerLatestLocation: string;
  customerTags: string[];
  customerNames: string[];
  orderByField?: CustomerOrderByField;
  orderBy?: OrderByType;
};

const usePaginationState = () => {
  const [filter, setFilter] = useState<Filter>({
    customerPlatforms: [],
    customerLatestLocation: "",
    customerTags: [],
    customerNames: [],
  });

  const [page, setPage] = useState(DEFAULT_PAGE_START);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  return {
    filter,
    page,
    rowsPerPage,
    setFilter,
    setPage,
    setRowsPerPage,
  };
};

export default usePaginationState;
