import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";
import get from "lodash/get";
import { VARIANTS, TEXT_COLOR } from "constants/Typography";

type FontWeight = "100" | "200" | "300" | "400" | "500" | "600";

export type VariantPropsType = {
  variant:
    | "h1"
    | "h2"
    | "h3"
    | "title1"
    | "title2"
    | "title3"
    | "title4"
    | "title7"
    | "title8"
    | "title10"
    | "body1"
    | "body2"
    | "body3"
    | "body4"
    | "body5"
    | "alertTitle"
    | "alertBody"
    | "iconText";
  color?:
    | "accent"
    | "dark"
    | "darkGray"
    | "darkMed"
    | "darkLight"
    | "gray"
    | "success"
    | "error"
    | "info"
    | "warning"
    | "disabled"
    | "white"
    | string;
  fontWeight?: FontWeight;
  align?: string;
};

type customType = {
  customColor?: string;
  customSize?: string;
  customFont?: string;
  customLineHeight?: string;
  customFontWeight?: FontWeight;
};

const StyledTypography = styled.div.attrs<VariantPropsType>((props) => {
  const customColor = props.color ? TEXT_COLOR[props.color] || props.color : TEXT_COLOR.darkGray;
  const customSize: string = get(VARIANTS[props.variant], "size") || "1.16rem";
  const customFont: string = get(VARIANTS[props.variant], "fontFamily") || "Graphik";
  const customLineHeight: string = get(VARIANTS[props.variant], "lineHeight") || "initial";
  const { align } = props;

  return { customColor, customSize, customFont, customLineHeight, align };
})<VariantPropsType & customType>`
  color: ${({ customColor }) => customColor};
  font-size: ${({ customSize }) => customSize};
  font-family: ${({ customFont }) => customFont};
  line-height: ${({ customLineHeight }) => customLineHeight};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

export type TypoGraphyPropsType = VariantPropsType & HTMLAttributes<HTMLDivElement>;

const Typography: FC<TypoGraphyPropsType> = (props) => {
  const { children, color, variant, className, style, fontWeight, align } = props;
  return (
    <StyledTypography
      variant={variant}
      color={color}
      style={style}
      className={className}
      fontWeight={fontWeight}
      align={align}
    >
      {children}
    </StyledTypography>
  );
};

export default Typography;
