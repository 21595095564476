import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import Card from "components/Card";
import Grid from "components/Grid";
import { SvgIcon } from "components/Icon";
import { IcEdit, IcClose } from "components/SvgIcons";
import ConfirmModal from "components/Modal/ConfirmModal";
import Typography from "components/Typography";

import COLORS from "constants/Colors";
import { ShippopSenderInformation, ShippopStoreSetting } from "types/Shipping";
import { Device } from "types/Device";
import useDevice from "utils/hooks/useDevice";

import AddressListItem from "./AddressListItem";
import EmailForm from "./EmailForm";
import { TopRightPositionWrapper } from "./styled";

type SenderInformationPropsType = {
  data?: ShippopSenderInformation;
  projectId: string;
  isOpen: boolean;
  onClose: () => void;
  shippopInformation: ShippopStoreSetting;
  onChangeShippopInformation: (newShippopInformation: ShippopStoreSetting) => void;
};

const SenderInformation: React.FC<SenderInformationPropsType> = (props) => {
  const { t } = useTranslation();
  const device = useDevice();
  const isMobile = device === Device.MOBILE;

  const { projectId, shippopInformation, onChangeShippopInformation } = props;

  const defaultSenderAddressList = shippopInformation?.senderInformation || [];
  const defaultEmail = shippopInformation?.email || "";
  const primaryAddress = defaultSenderAddressList.find(({ isActive }) => isActive);

  const [editedEmail, setEditEmail] = useState(defaultEmail);
  const [editedAddressList, setEditAddressList] = useState<ShippopSenderInformation[]>(defaultSenderAddressList);

  const [isShowEditSenderInfo, setIsShowEditSenderInfo] = useState(false);
  const [isShowConfirmCloseEditSenderInfo, setIsShowConfirmCloseEditSenderInfo] = useState(false);

  const handleChangeEmail = (newEmail: string) => {
    setEditEmail(newEmail);
  };

  const handleChangeAddressList = (newAddressList: ShippopSenderInformation[]) => {
    setEditAddressList(newAddressList);
  };

  const handleChangeShippopInformation = () => {
    const newShippopInformation: ShippopStoreSetting = {
      email: editedEmail,
      senderInformation: editedAddressList,
    };

    onChangeShippopInformation(newShippopInformation);
  };

  const handleToggleShowEditSenderInfo = () => {
    if (!isShowEditSenderInfo) {
      setIsShowEditSenderInfo(true);
      return;
    }

    setIsShowEditSenderInfo(false);
    handleChangeShippopInformation();
  };

  const handleConfirmSaveEditedSenderInfo = () => {
    setIsShowConfirmCloseEditSenderInfo(false);
    handleToggleShowEditSenderInfo();
  };

  const SenderInformationEditButton = () => (
    <Button
      color={isShowEditSenderInfo ? "primary" : "secondary"}
      onClick={handleToggleShowEditSenderInfo}
      fullWidth={isMobile}
    >
      {isShowEditSenderInfo ? (
        t("shipping.shippop.buttonText.doneEdit")
      ) : (
        <>
          <SvgIcon
            component={IcEdit}
            size="small"
            htmlColor={COLORS.DarkGray}
            viewBox="0 0 36 36"
            className="m-0 p-0"
          />
          <Typography variant="title7" color="darkGray" className="pl-2">
            {t("shipping.shippop.buttonText.editInfo")}
          </Typography>
        </>
      )}
    </Button>
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card padding="24px" fullWidth className="position-relative">
          {!isMobile && (
            <TopRightPositionWrapper className="m-3">{SenderInformationEditButton()}</TopRightPositionWrapper>
          )}
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="title7" color="dark" className="pb-3">
                {t("shipping.shippop.senderInformation.title")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body3" color="darkMed" className="pb-2">
                {t("shipping.shippop.email.title")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body3" className="pb-3">
                {defaultEmail || t("shipping.shippop.email.placeholder.required")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body3" color="darkMed" className="pb-2">
                {t("shipping.shippop.address.title")}
              </Typography>
            </Grid>
            {primaryAddress ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="body3" className="pb-2">
                    {primaryAddress.address}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body3">
                    {t("shipping.shippop.address.label.name")}
                    {primaryAddress.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body3">
                    {t("shipping.shippop.address.label.phone")}
                    {primaryAddress.phoneNumber}
                  </Typography>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Typography variant="body3">{t("shipping.shippop.address.placeholder.required")}</Typography>
              </Grid>
            )}
          </Grid>
          {isMobile && <div className="pt-3">{SenderInformationEditButton()}</div>}
        </Card>
      </Grid>
      {isShowEditSenderInfo && (
        <>
          <Grid item xs={12} className="pt-4">
            <Card padding="36px 24px 24px 24px" fullWidth className="position-relative">
              <TopRightPositionWrapper className="py-2 px-3">
                <SvgIcon
                  component={IcClose}
                  size="small"
                  htmlColor={COLORS.DarkMed}
                  className="m-0 p-0"
                  onClick={() => {
                    setIsShowConfirmCloseEditSenderInfo(true);
                  }}
                />
              </TopRightPositionWrapper>
              <EmailForm email={editedEmail} onChangeEmail={handleChangeEmail} isRequired />
              <AddressListItem
                projectId={projectId}
                addressList={editedAddressList}
                onChangeAddressList={handleChangeAddressList}
              />
            </Card>
          </Grid>
          <ConfirmModal
            isOpen={isShowConfirmCloseEditSenderInfo}
            onClose={() => setIsShowConfirmCloseEditSenderInfo(false)}
            onClickConfirmButton={handleConfirmSaveEditedSenderInfo}
            title={t("shipping.shippop.confirmModal.content")}
            confirmButtonText={t("Submit")}
          />
        </>
      )}
    </Grid>
  );
};

export default SenderInformation;
