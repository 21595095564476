import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Typography from "components/Typography";
import { Grid } from "@material-ui/core";
import COLORS from "constants/Colors";
import { BANK_LOGO_CIRCLE } from "constants/Image";
import { TitleOptions } from "constants/information";
import { KYCInformationType } from "types/DeeplePay";
import { ConfirmationDetailWrapper, Detail, Item, TextWrapper } from "./styled";
import { BankFormData, PersonalValidation } from ".";

interface ConfirmationPropsType {
  personalDetail: PersonalValidation;
  banking?: BankFormData;
  isMobile: boolean;
}

const Confirmation: FC<ConfirmationPropsType> = ({ personalDetail, banking, isMobile }) => {
  const { t } = useTranslation();
  const isCompanyType = personalDetail?.type === KYCInformationType.COMPANY;
  const title = TitleOptions.find(({ value }) => value === personalDetail?.titleName);

  return (
    <Grid container justify="center" className={isMobile ? "p-4" : "px-5 py-3"}>
      <Grid item className="mt-2">
        <Typography color={COLORS.Dark} variant="title2">
          {t("deeplePay.verifyBilling.confirmation.title")}
        </Typography>
      </Grid>
      <ConfirmationDetailWrapper className={isMobile ? "" : "desktop"}>
        {personalDetail?.documentVerificationCertificatePath && (
          <div>
            <img src={personalDetail?.documentVerificationCertificatePath} alt="personalDetail-info-img" />
          </div>
        )}

        <Detail>
          {personalDetail?.name && (
            <TextWrapper
              item
              xs={12}
              className={isMobile ? "pt-2 mobile" : "pt-2"}
              container
              justify="flex-start"
              alignContent="center"
              alignItems="center"
            >
              <Item>
                <Typography variant="title8">
                  {t(isCompanyType ? "billing.info.form.company.companyName" : "billing.info.form.personal.name")} :
                </Typography>
              </Item>
              <Item>
                <Typography variant="body3" className="text">
                  {title && !isCompanyType ? t(title?.label) : ""} {personalDetail?.name}
                </Typography>
              </Item>
            </TextWrapper>
          )}

          {personalDetail?.taxId && (
            <TextWrapper
              item
              xs={12}
              className={isMobile ? "pt-2 mobile" : "pt-2"}
              container
              justify="flex-start"
              alignContent="center"
              alignItems="center"
            >
              <Item>
                <Typography variant="title8">
                  {t(
                    isCompanyType
                      ? "deeplePay.verifyBilling.billingInfo.taxId.company"
                      : "deeplePay.verifyBilling.billingInfo.taxId.personal",
                  )}
                  :
                </Typography>
              </Item>
              <Item>
                <Typography variant="body3" className="text">
                  {personalDetail?.taxId}
                </Typography>
              </Item>
            </TextWrapper>
          )}

          {personalDetail?.address && (
            <TextWrapper
              item
              xs={12}
              className={isMobile ? "pt-2 mobile" : "pt-2"}
              container
              justify="flex-start"
              alignContent="center"
              alignItems="center"
            >
              <Item>
                <Typography variant="title8">{t("Address")} :</Typography>
              </Item>
              <Item>
                <Typography variant="body3" className="text">
                  {`${personalDetail?.address || ""} ${personalDetail?.subDistrict || ""} ${
                    personalDetail?.district || ""
                  } ${personalDetail?.province || ""} ${personalDetail?.postalCode || ""}`}
                </Typography>
              </Item>
            </TextWrapper>
          )}
        </Detail>
      </ConfirmationDetailWrapper>

      {banking && banking?.bankAccountName && (
        <ConfirmationDetailWrapper className={isMobile ? "" : "desktop"}>
          {banking?.bookBankImage && (
            <div>
              <img src={banking?.bookBankImage} alt="personalDetail-info-img" />
            </div>
          )}
          <Detail>
            {banking?.bank && (
              <TextWrapper
                item
                xs={12}
                className={isMobile ? "pt-2 mobile" : "pt-2"}
                container
                justify="flex-start"
                alignContent="center"
                alignItems="center"
              >
                <Item>
                  <Typography variant="title8">{t("deeplePay.verifyBilling.bankingInfo.bankName")} :</Typography>
                </Item>
                <Item>
                  <img src={BANK_LOGO_CIRCLE[banking.bank] || ""} alt="confirm-bank-logo" />
                  <Typography variant="body3" className="text">
                    {t(banking?.bank)}
                  </Typography>
                </Item>
              </TextWrapper>
            )}

            {banking?.bank && (
              <TextWrapper
                item
                xs={12}
                className={isMobile ? "pt-2 mobile" : "pt-2"}
                container
                justify="flex-start"
                alignContent="center"
                alignItems="center"
              >
                <Item>
                  <Typography variant="title8">{t("deeplePay.verifyBilling.bankingInfo.accountName")} :</Typography>
                </Item>
                <Item>
                  <Typography variant="body3" className="text">
                    {banking?.bankAccountName}
                  </Typography>
                </Item>
              </TextWrapper>
            )}

            {banking?.bank && (
              <TextWrapper
                item
                xs={12}
                className={isMobile ? "pt-2 mobile" : "pt-2"}
                container
                justify="flex-start"
                alignContent="center"
                alignItems="center"
              >
                <Item>
                  <Typography variant="title8">{t("deeplePay.verifyBilling.bankingInfo.accountNo")} :</Typography>
                </Item>
                <Item>
                  <Typography variant="body3" className="text">
                    {banking?.bankAccountNo}
                  </Typography>
                </Item>
              </TextWrapper>
            )}
          </Detail>
        </ConfirmationDetailWrapper>
      )}
    </Grid>
  );
};

export default Confirmation;
