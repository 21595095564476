import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";

import { RICH_MENU } from "constants/i18n";
import Button from "components/Button";
import Card from "components/Card";
import DropZoneUpload from "components/DropzoneUpload";
import Typography from "components/Typography";
import Modal from "components/Modal";
import { ProjectIdType } from "types/Project";
import { UploadDirectoryType } from "types/Image";
import { LineMenuTemplate } from "types/RichMenu";
import useToggle from "utils/hooks/useToggle";
import { ImagePlaceholder } from "./styled";
import TemplateSelector from "../../components/TemplateSelector";
import { RatioBox, RatioContent } from "../../components/TemplateSelector/styled";
import GridTemplate from "../../components/TemplateSelector/GridTemplate";
import { DEFAULT_LINE_RICH_MENU } from "../../config";

type TemplateProps = {
  defaultImageUrl?: string;
  defaultTemplate?: LineMenuTemplate;
};

const useStyles = makeStyles(() => ({
  paper: {
    width: "100%",
  },
}));

const Template: FC<TemplateProps> = ({ defaultTemplate, defaultImageUrl }) => {
  const { t } = useTranslation(RICH_MENU);
  const { projectId } = useParams<ProjectIdType>();
  const classes = useStyles();
  const { control, watch, setValue, errors } = useFormContext();

  const watchedTemplate = watch("template", defaultTemplate);
  const { isOpen, handleClose, handleToggle } = useToggle();

  return (
    <>
      <Card noShadow padding="24px">
        <Typography variant="title3" className="mb-3">
          {t("TEMPLATE")}
        </Typography>

        <div className="m-auto">
          <Controller
            control={control}
            name="imageUrl"
            defaultValue={defaultImageUrl || ""}
            render={(field) => {
              return watchedTemplate ? (
                <RatioBox>
                  <RatioContent backgroundUrl={field.value}>
                    <GridTemplate type={watchedTemplate} />
                  </RatioContent>
                </RatioBox>
              ) : (
                <RatioBox>
                  <RatioContent backgroundUrl={field.value}>
                    <ImagePlaceholder>{t("NO_SELECTED_TEMPLATE")}</ImagePlaceholder>
                  </RatioContent>
                </RatioBox>
              );
            }}
          />

          <Button type="button" color="secondary" className="mt-3" fullWidth onClick={handleToggle}>
            {t("SELECT_TEMPLATE")}
          </Button>

          {Boolean(watchedTemplate) && (
            <>
              <DropZoneUpload
                projectId={projectId}
                UploadDirectory={UploadDirectoryType.PROJECT_MENU}
                width={2500}
                height={1686}
                onChange={(images) => {
                  if (!images?.length) return;

                  setValue("imageUrl", images?.[0]?.publicUrl);
                }}
                limitItem={1}
                maxSizeMB={1}
              >
                <Button type="button" color="secondary" className="mt-3" fullWidth>
                  {t("UPLOAD_BACKGROUND")}
                </Button>
              </DropZoneUpload>

              <Typography variant="body3" className="mt-3" style={{ lineHeight: 1.5 }}>
                {`${t("IMAGE_FORMAT")}: JPG, JPEG, ${t("or")} PNG`}
                <br />
                {`${t("MAX_FILE_SIZE")}: 1 MB`}
                <br />
                {`${t("MAX_IMAGE_WIDTH_SIZE")}: 2500 pixels`}
                <br />
                {`${t("MAX_IMAGE_HEIGHT_SIZE")}: 1686 pixels`}
                <br />
                {`${t("IMAGE_ASPECT_RATIO")} (${t("WIDTH")}/${t("HEIGHT")}): 1.48`}
              </Typography>
            </>
          )}
          <div className={Boolean(errors?.imageUrl) || Boolean(errors?.template) ? "mt-3" : ""}>
            {Boolean(errors?.imageUrl) && (
              <Typography variant="body3" color="error">
                {errors?.imageUrl?.message}
              </Typography>
            )}
            {Boolean(errors?.template) && (
              <Typography variant="body3" color="error">
                {errors?.template?.message}
              </Typography>
            )}
          </div>
        </div>
      </Card>

      <Modal classes={classes} isOpen={isOpen} maxWidth="md" onClose={handleClose} keepMounted>
        <Controller
          control={control}
          name="template"
          defaultValue={defaultTemplate}
          render={(field) => (
            <TemplateSelector
              value={field.value}
              onCancel={handleClose}
              onSubmit={(value) => {
                if (value === LineMenuTemplate.DEFAULT_WITH_IMAGE) {
                  setValue("imageUrl", DEFAULT_LINE_RICH_MENU.menuUrl);
                }

                field.onChange(value);
              }}
            />
          )}
        />
      </Modal>
    </>
  );
};

export default Template;
