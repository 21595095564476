import * as React from "react";

const SvgIcCheck = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.987 9.458a1.765 1.765 0 012.496 2.496L14.895 22.542c-.69.69-1.807.69-2.496 0L6.517 16.66a1.765 1.765 0 112.496-2.496l4.634 4.634 9.34-9.34z"
    />
  </svg>
);

export default SvgIcCheck;
