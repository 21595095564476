import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";

import Typography from "components/Typography";
import { SvgIcon } from "components/Icon";
import { IcClose, IcWarning, IcCircleCheck, IcInfo } from "components/SvgIcons";
import COLORS from "constants/Colors";
import { ModalStatus } from "types/Common";
import { ModalConfig } from "../config";
import { useStyles } from "../styled";

type InformationModalPropsType = {
  isOpen: boolean;
  description: React.ReactNode;
  onClose: () => void;
  type: ModalStatus;
  className?: string;
};

const InformationModal: FC<InformationModalPropsType> = (props) => {
  const defaultClass = useStyles();
  const { isOpen, onClose, description, type, ...rest } = props;

  const handleClose = () => {
    onClose();
  };

  let informationIcon;
  let informationIconColor;
  let viewBox;
  switch (type) {
    case ModalStatus.SUCCESS:
      informationIcon = IcCircleCheck;
      informationIconColor = COLORS.Success;
      viewBox = "0 0 20 20";
      break;
    case ModalStatus.FAIL:
      informationIcon = IcWarning;
      informationIconColor = COLORS.Error;
      viewBox = "0 0 24 24";
      break;
    case ModalStatus.INFO:
      informationIcon = IcInfo;
      informationIconColor = COLORS.Primary;
      viewBox = "0 0 32 32";
      break;
    default:
  }

  return (
    <Modal {...ModalConfig} className={`${defaultClass.modal}`} open={isOpen} onClose={handleClose} {...rest}>
      <Fade in={isOpen}>
        <Box className={defaultClass.paper}>
          <Box className="mb-2 text-center">
            <SvgIcon
              className="p-0"
              component={informationIcon}
              fontSize="large"
              htmlColor={informationIconColor}
              viewBox={viewBox}
              style={{ width: 36, height: 36 }}
            />
            <Typography variant="body3" className="text-center px-4">
              {description}
            </Typography>
          </Box>
          <Box
            position="absolute"
            top="0"
            right="0"
            width={36}
            height={36}
            onClick={onClose}
            data-testid="close-testid"
          >
            <SvgIcon
              className="mt-1"
              component={IcClose}
              fontSize="large"
              htmlColor={COLORS.Dark}
              viewBox="0 0 24 24"
              style={{ width: 24, height: 24 }}
            />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

InformationModal.displayName = "InformationModal";

export default InformationModal;
