import React, { FC } from "react";
import { useParams, Switch, Redirect, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  BROADCAST,
  CAMPAIGNS,
  MARKETING_TOOLS,
  TOP_SELLING,
  PROJECT,
  FACEBOOK_LIVE,
  AUTO_ENGAGEMENT,
  LINE_OA,
  FACEBOOK,
  NEW_RECURRING_REQUEST,
  EDIT_RECURRING_REQUEST,
} from "constants/Router";
import Broadcast from "domain/MarketingTools/Broadcast";
import Campaigns from "domain/Campaign";
import TopSellingProductContainer from "domain/MarketingTools/TopSellingProduct";
import FacebookAutoEngagementContainer from "domain/MarketingTools/FacebookAutoEngagement";
import FacebookLive from "domain/MarketingTools/FacebookLive";
import FacebookLiveFormContainer from "domain/MarketingTools/FacebookLive/FacebookLiveFormContainer";
import { NewFacebookRecurringRequest } from "domain/MarketingTools/Broadcast/NewFacebookRecurringRequest";
import { BroadcastFacebookCampaign } from "domain/MarketingTools/Broadcast/BroadcastFacebookCampaign";

import useGetUserWithPermission from "utils/hooks/useGetUserWithPermission";
import useTabWithLink from "utils/hooks/useTabWithLink";

import useGetProject from "utils/hooks/useGetProject";
import { ProjectIdType } from "types/Project";

import PrivateRoute from "routers/PrivateRoute";
import { NotFound } from "pages/NotFound";
import { DisableFeature } from "pages/DisableFeature";
import { TAB_CONFIGS } from "pages/MarketingTools/tabsConfig";

const MarketingToolsSubRoute: FC = () => {
  const { t } = useTranslation()
  const { projectId = "" } = useParams<ProjectIdType>();
  const { loading, isAdmin } = useGetUserWithPermission(projectId);
  const { rootPath, defaultTabPath } = useTabWithLink(TAB_CONFIGS, MARKETING_TOOLS, projectId);
  const { isBroadcastMessageEnabled, isFacebookAutoEngagementEnabled } = useGetProject(projectId);

  return (
    <Switch>
      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${CAMPAIGNS}`}
        isAllowed={isAdmin}
        redirectTo="/"
        component={Campaigns}
      />
      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${BROADCAST}`}
        isAllowed={isAdmin}
        redirectTo="/"
        component={Broadcast}
      >
        {isBroadcastMessageEnabled ? (
          <Broadcast />
        ) : (
          <DisableFeature>
            <Broadcast />
          </DisableFeature>
        )}
      </PrivateRoute>
      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${BROADCAST}/${LINE_OA}`}
        isAllowed={isAdmin && isBroadcastMessageEnabled}
        redirectTo="/"
      >
        <Broadcast activeTab="line" />
      </PrivateRoute>
      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${BROADCAST}/${FACEBOOK}`}
        isAllowed={isAdmin && isBroadcastMessageEnabled}
        redirectTo="/"
      >
        <Broadcast activeTab="facebook" />
      </PrivateRoute>
      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${BROADCAST}/${FACEBOOK}/${NEW_RECURRING_REQUEST}`}
        isAllowed={isAdmin && isBroadcastMessageEnabled}
        redirectTo="/"
        title={t("broadcast.facebookRequestForm.title")}
      >
        <NewFacebookRecurringRequest />
      </PrivateRoute>
      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${BROADCAST}/${FACEBOOK}/:recurringNotificationOptInId/${EDIT_RECURRING_REQUEST}`}
        isAllowed={isAdmin && isBroadcastMessageEnabled}
        redirectTo="/"
      >
        <NewFacebookRecurringRequest />
      </PrivateRoute>
      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${BROADCAST}/${FACEBOOK}/:recurringNotificationOptInId/${CAMPAIGNS}`}
        isAllowed={isAdmin && isBroadcastMessageEnabled}
        redirectTo="/"
      >
        <BroadcastFacebookCampaign />
      </PrivateRoute>
      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${TOP_SELLING}`}
        isAllowed={isAdmin}
        redirectTo="/"
        component={TopSellingProductContainer}
      />

      <PrivateRoute exact loading={loading} path={`${rootPath}`} isAllowed={isAdmin} redirectTo="/">
        <Redirect
          to={defaultTabPath ? `/${PROJECT}/${projectId}/${MARKETING_TOOLS}/${defaultTabPath}` : `/${PROJECT}`}
        />
      </PrivateRoute>

      {isFacebookAutoEngagementEnabled ? (
        <PrivateRoute
          exact
          loading={loading}
          path={`${rootPath}/${AUTO_ENGAGEMENT}`}
          isAllowed={isAdmin}
          redirectTo="/"
          component={FacebookAutoEngagementContainer}
        />
      ) : (
        <PrivateRoute
          exact
          loading={loading}
          path={`${rootPath}/${AUTO_ENGAGEMENT}`}
          isAllowed={isAdmin}
          redirectTo="/"
          component={FacebookAutoEngagementContainer}
        >
          <DisableFeature>
            <FacebookAutoEngagementContainer />
          </DisableFeature>
        </PrivateRoute>
      )}

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${FACEBOOK_LIVE}`}
        isAllowed={isAdmin}
        redirectTo="/"
        component={FacebookLive}
      />

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${FACEBOOK_LIVE}/:postId`}
        isAllowed={isAdmin}
        redirectTo="/"
        component={FacebookLiveFormContainer}
      />

      <Route path={`${rootPath}/*`}>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default MarketingToolsSubRoute;
