const resetPassword = {
  en: {
    "Reset Password": "Reset Password",
    "Please fill your new password.": "Please fill your new password.",
    "New Password": "New Password",
    "Confirm New Password": "Confirm New Password",
    "Your password has been reset successfully, You can now use new password to log in":
      "Your password has been reset successfully, You can now use new password to log in",
    "Confirm new password must match with new password.": "Confirm new password must match with new password.",
  },
  th: {
    "Reset Password": "ตั้งค่ารหัสผ่าน",
    "Please fill your new password.": "กรุณากรอกรหัสผ่านใหม่ของคุณ",
    "New Password": "รหัสผ่านใหม่",
    "Confirm New Password": "ยืนยันรหัสผ่านใหม่",
    "Your password has been reset successfully, You can now use new password to log in":
      "รหัสผ่านของคุณถูกตั้งค่าใหม่แล้ว, คุณสามารถเข้าใช้โดยใช้รหัสผ่านใหม่ได้แล้ว",
    "Confirm new password must match with new password.": "ยืนยันรหัสผ่านใหม่ต้องเหมือนกับรหัสผ่านใหม่",
  },
};

export default resetPassword;
