import styled from "styled-components";
import Grid from "components/Grid";

export const CampaignCardWrapper = styled(Grid)`
  position: relative;
  background-color: ${({ theme }) => theme.COLORS.White};
  margin-top: 18px;
  padding: 24px 32px;
  border-style: solid;
  border-color: ${({ theme }) => theme.COLORS.LightBlue};
  border-width: 0.5px 0;
`;

export const MenuButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  position: absolute;
  right: 0;
  padding-right: 16px;
`;

export const ColoredStopIconWrapper = styled.div`
  display: flex;
  align-items: center;
  filter: invert(37%) sepia(85%) saturate(5998%) hue-rotate(19deg) brightness(97%) contrast(98%);
`;
