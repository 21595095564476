import * as React from "react";

const SvgIcTransfer = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.2146 1.6215L14.3365 1.74299C14.4583 1.90498 14.499 2.10747 14.499 2.30997V6.3598V6.52179C14.4177 6.96727 14.0115 7.25076 13.5646 7.16976C13.1583 7.08877 12.874 6.72428 12.874 6.3193V4.25389L7.30833 9.80215C6.98333 10.1261 6.49583 10.1261 6.17083 9.80215C5.84583 9.47817 5.84583 8.99219 6.17083 8.6682L11.7365 3.11993H9.62396H9.46146C9.05521 3.03894 8.73021 2.63395 8.81146 2.18847C8.89271 1.78349 9.25833 1.5 9.66458 1.5H13.7677C13.9302 1.5 14.0927 1.5405 14.2146 1.6215ZM3.125 14.4998H12.875C13.7688 14.4998 14.5 13.7709 14.5 12.9204V9.27554C14.5 8.78956 14.0531 8.38458 13.5656 8.46558C13.1594 8.50608 12.875 8.91106 12.875 9.31604V12.5154C12.875 12.7584 12.6719 12.9204 12.4688 12.9204H3.53125C3.2875 12.9204 3.125 12.7179 3.125 12.5154V3.56528C3.125 3.32229 3.32812 3.1603 3.53125 3.1603H6.7C7.1875 3.1603 7.59375 2.71482 7.5125 2.22884C7.47188 1.82385 7.06563 1.54036 6.65938 1.54036H3.125C2.23125 1.54036 1.5 2.26933 1.5 3.1603V12.8394C1.5 13.7709 2.23125 14.4998 3.125 14.4998Z"
    />
  </svg>
);

export default SvgIcTransfer;
