import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { IcInfo } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";
import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import Tooltip from "components/Tooltip";
import Typography from "components/Typography";

import COLORS from "constants/Colors";
import { Device } from "types/Device";
import { ProjectIdType } from "types/Project";
import { ALL_CATEGORY_WITH_HIGHLIGHT_PRODUCTS } from "graphql/category/query";

import useDevice from "utils/hooks/useDevice";

import { CategoryConnectionType, CategoryType } from "types/Category";
import { useQuery } from "@apollo/client";
import HighlightProductCard from "../HighlightProductCard";
import ManageProductHighLight from "./ManageHighlightProducts";

const HighlightProduct: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const device = useDevice();
  const isMobile = device === Device.MOBILE;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [focusedCategoryId, setFocusedCategoryId] = useState("");

  const { loading, data } = useQuery<CategoryConnectionType, ProjectIdType>(ALL_CATEGORY_WITH_HIGHLIGHT_PRODUCTS, {
    variables: {
      projectId,
    },
    skip: !projectId,
  });

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setFocusedCategoryId("");
  };

  const handleClickEdit = (categoryId: string) => {
    setFocusedCategoryId(categoryId);
  };

  useEffect(() => {
    if (focusedCategoryId) {
      setIsOpenModal(true);
    }
  }, [focusedCategoryId]);

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress className="m-4" />
      </Grid>
    );
  }

  const categoryResults = data?.categories?.results || [];

  return (
    <Grid container>
      <Grid container item className="pt-4 pl-4 pr-3 pb-3" alignItems="center">
        <Typography variant="title1" color="dark" className="pt-2">
          {t("HighlightProduct.title")}
        </Typography>
        <Tooltip
          title={t("HighlightProduct.title.tooltip") as string}
          placement={isMobile ? "bottom-end" : "right"}
          arrow
        >
          <div>
            <SvgIcon className="ml-4 mt-2" component={IcInfo} fontSize="large" htmlColor={COLORS.DarkLight} />
          </div>
        </Tooltip>
      </Grid>
      <Grid container item className="m-2 p-1" spacing={3}>
        {categoryResults.map((category: CategoryType) => {
          return (
            <Grid item xs={12} md={6} key={category.id}>
              <HighlightProductCard isMobile={isMobile} onClickEdit={handleClickEdit} category={category} />
            </Grid>
          );
        })}
      </Grid>

      <ManageProductHighLight
        projectId={projectId}
        categoryId={focusedCategoryId}
        isOpen={isOpenModal}
        onClose={handleCloseModal}
      />
    </Grid>
  );
};

export default HighlightProduct;
