import React, { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import Card from "components/Card";
import Divider from "components/Divider";
import Grid from "components/Grid";
import Typography from "components/Typography";

import { Wrapper } from "./styled";

type LayoutPropsType = {
  currentNumber?: number;
  limit?: number;
  handleClickSave: (event: MouseEvent) => void;
  isDisableSave?: boolean;
};

const Layout: FC<LayoutPropsType> = ({
  children,
  currentNumber = 0,
  handleClickSave,
  isDisableSave = false,
  limit = 0,
}) => {
  const { t } = useTranslation();
  return (
    <div className="m-3 d-flex flex-1 flex-column">
      <Card padding="0" className="d-flex flex-1 flex-column">
        <Wrapper className="d-flex flex-1 flex-column">
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="title1" color="dark">
                {t("Welcome message")}
              </Typography>
            </Grid>
            <Grid container item xs={2} justify="flex-end" alignItems="center">
              <Grid item>
                {currentNumber} / {limit}
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <div className="d-flex flex-1 flex-column">{children}</div>
          <Grid container>
            <Grid item xs={12}>
              <Button onClick={handleClickSave} fullWidth className="mt-4" disabled={isDisableSave}>
                {t("Save")}
              </Button>
            </Grid>
          </Grid>
        </Wrapper>
      </Card>
    </div>
  );
};

export default Layout;
