import { useCallback, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { DIGITAL_CONTENT_BY_CODE } from "graphql/digitalContent/query";
import { RedeemDigitalContentQuery } from "types/DigitalContent";
import { notifyError } from "utils/notify";

import { RedeemModalFormDataType } from "../RedeemModal";

const useDigitalContentByCode = (projectId: string) => {
  const { t } = useTranslation();

  const [isShowDigitalContentDetail, setIsShowDigitalContentDetail] = useState(false);

  const handleShowDigitalContentDetail = () => {
    setIsShowDigitalContentDetail(true);
  };

  const handleHideDigitalContentDetail = useCallback(() => {
    setIsShowDigitalContentDetail(false);
  }, []);

  const [searchCodeForRedeem, { data, loading: isLoading }] = useLazyQuery<RedeemDigitalContentQuery>(
    DIGITAL_CONTENT_BY_CODE,
    {
      onCompleted: (resultData) => {
        if (!resultData.digitalContentByCode) {
          notifyError(t("contentManagement.error.notFoundDigitalContent"));
        } else {
          handleShowDigitalContentDetail();
        }
      },
    },
  );

  const handleSearchCodeForRedeem = (formData: RedeemModalFormDataType) => {
    const { code } = formData;

    searchCodeForRedeem({
      variables: {
        projectId,
        code,
      },
    });
  };

  return {
    isLoading,
    digitalContentByCode: data?.digitalContentByCode,
    handleSearchCodeForRedeem,
    isShowDigitalContentDetail,
    handleShowDigitalContentDetail,
    handleHideDigitalContentDetail,
  };
};

export default useDigitalContentByCode;
