import styled, { css } from "styled-components";

export const ScrollContainer = styled.div`
  overflow: auto;
`;

export const DropZone = styled.div`
  display: flex;
  /*
    Needed to avoid growth in list due to lifting the first item
    Caused by display: inline-flex strangeness
  */
  align-items: start;
  /* stop the list collapsing when empty */
  min-width: 600px;
  /* stop the list collapsing when it has no items */
  min-height: 60px;
  width: fit-content;
`;

type CategoryListWrapperProps = {
  limitIndex: number;
};

export const CategoryListWrapper = styled.div<CategoryListWrapperProps>`
  display: flex;
  align-items: center;

  ${(props) =>
    props.limitIndex &&
    css`
      &:nth-child(${props.limitIndex}) {
        padding-right: 20px;
        margin-right: 15px;
        border-right: 2px solid #ccc;
      }
    `}

  &.item-dragging {
    padding-right: 0;
    margin-right: 0;
    border: none;
  }
`;
