import { gql } from "@apollo/client";

export const ADD_CHAT_TEMPLATE = gql`
  mutation addChatTemplate($projectId: ID!, $chatTemplate: ChatTemplateInput) {
    addChatTemplate(projectId: $projectId, chatTemplate: $chatTemplate) {
      id
      name
      description
      shortcutKey
      messages {
        type
        value
      }
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_CHAT_TEMPLATE = gql`
  mutation deleteChatTemplate($projectId: ID!, $chatTemplateId: ID!) {
    deleteChatTemplate(projectId: $projectId, chatTemplateId: $chatTemplateId) {
      id
    }
  }
`;

export const UPDATE_CHAT_TEMPLATE = gql`
  mutation updateChatTemplate($projectId: ID!, $chatTemplateId: ID!, $chatTemplate: ChatTemplateInput) {
    updateChatTemplate(projectId: $projectId, chatTemplateId: $chatTemplateId, chatTemplate: $chatTemplate) {
      id
      name
      description
      shortcutKey
      messages {
        type
        value
      }
      createdAt
      updatedAt
    }
  }
`;
