import styled from "styled-components";

export const ProductCardWrapper = styled.div`
  background: ${({ theme }) => `${theme.COLORS.LightGray}`};
`;

export const CategoryContentWrapper = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  min-height: 65px;
`;
