import { useMutation } from "@apollo/client";
import { FAQS } from "graphql/ai-setting/query";
import { CREATE_FAQ } from "graphql/ai-setting/mutation";
import { useContext } from "react";
import { FaqType, FaqQueryType } from "types/AISetting";
import { cleanCategoriesMessage } from "utils/message";
import { GuidelineContext } from "utils/context";

const useUpdateFaq = (projectId: string) => {
  const { setGuidelineCompletedStepCount } = useContext(GuidelineContext);

  const [createFaq, { loading: isLoadingCreateFaq }] = useMutation(CREATE_FAQ, {
    update(cache, { data: { createFaq: createdFaq } }) {
      const cacheFaq = cache.readQuery<FaqQueryType>({
        query: FAQS,
        variables: {
          projectId,
        },
      });

      if (cacheFaq) {
        cache.writeQuery({
          query: FAQS,
          variables: {
            projectId,
          },
          data: {
            faqs: [createdFaq, ...cacheFaq.faqs],
          },
        });
      }

      setGuidelineCompletedStepCount(projectId);
    },
  });

  const handleCreateFaq = (faqData: FaqType) => {
    return createFaq({
      variables: {
        projectId,
        faqData: {
          questions: faqData.questions,
          responses: cleanCategoriesMessage(faqData.responses),
          enQuestions: faqData.enQuestions,
          enResponses: cleanCategoriesMessage(faqData.enResponses),
          isActive: faqData.isActive,
        },
      },
    });
  };

  return { createFaq: handleCreateFaq, isLoadingCreateFaq };
};

export default useUpdateFaq;
