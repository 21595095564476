import styled from "styled-components";

type ColorDisplayType = {
  swatchColor: string;
};

export const SwatchWrapper = styled.button`
  padding: 12px 16px;
  border: 0.5px solid ${({ theme }) => theme.COLORS.LightBlue};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.COLORS.White};
  border-radius: 8px;
  outline: none;
  min-width: 150px;
`;

export const ColorDisplay = styled.div<ColorDisplayType>`
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background-color: ${({ swatchColor }) => swatchColor};
  border: 0.5px solid ${({ theme }) => theme.COLORS.LightBlue};
  margin-right: 12px;
`;
