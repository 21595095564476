const enterprise = {
  en: {
    "enterprise.APIKey.placeholder": `There isn't API key. Click "Generate" button below to generate new key.`,
    "enterprise.confirmation.firstGenerate": "This API KEY will show only once. Please copy or save this key",
    "enterprise.confirmation.generate":
      "Are you sure to generate new API key? the new generate API Key will make the old API Key unusable",
    "enterprise.OTP.active": "OTP is active",
    "enterprise.OTP.inactive": "OTP is inactive",
    Generate: "Generate",
    Regenerate: "Regenerate",
    "enterprise.accessKey.errorMessage.unexpectedCharacter": "* is unexpected character",
  },
  th: {
    "enterprise.APIKey.placeholder": `ยังไม่มี API key กดปุ่ม "สร้างใหม่" เพื่อสร้าง key ใหม่`,
    "enterprise.confirmation.firstGenerate": "API KEY นี้จะแสดงแค่ครั้งเดียว กรุณาบันทึกหรือคัดลอก API Key นี้ไว้",
    "enterprise.confirmation.generate":
      "คุณแน่ใจหรือว่าจะสร้าง API Key ใหม่? API Key ที่สร้างใหม่จะทำให้ API Key เดิมใช้ไม่ได้",
    "enterprise.OTP.active": "เปิดใช้ OTP",
    "enterprise.OTP.inactive": "ปิดการใช้ OTP",
    Generate: "สร้างใหม่",
    Regenerate: "สร้างใหม่อีกครั้ง",
    "enterprise.accessKey.errorMessage.unexpectedCharacter": "ห้ามใช้ตัวอักษร * ",
  },
};

export default enterprise;
