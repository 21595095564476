import COLORS from "constants/Colors";
import { OptionType, OptionsType } from "react-select";

export const COLOR_BY_STATUS: Record<string, string> = {
  DRAFT: COLORS.Warning,
  FAILED: COLORS.DarkMed,
  SENT: COLORS.Success,
  IN_PROGRESS: COLORS.ReadyToShip,
  SCHEDULED: COLORS.Accent,
};

export const BROADCAST_STATUS: Record<string, string> = {
  DRAFT: "Draft",
  SENT: "Sent",
  IN_PROGRESS: "broadcast.description.inProgress",
  FAILED: "Fail",
  SCHEDULED: "Scheduled",
};

export const BROADCAST_STATUS_DESCRIPTION: Record<string, string> = {
  DRAFT: "broadcast.description.draft",
  SENT: "broadcast.description.sent",
  IN_PROGRESS: "broadcast.description.inProgress",
  FAILED: "broadcast.description.fail",
  SENDING: "broadcast.description.sending",
  SCHEDULED: "broadcast.description.scheduled",
};

export const CUSTOMER_RETENTION_STATUS: Record<string, string> = {
  NEW_CUSTOMER: "New",
  FIRST_TIME_BUYER: "First purchase",
  REPEAT_BUYER: "Repeat buyer",
  UNSEEN: "Unseen",
  POTENTIAL_CUSTOMER: "Potential customer",
};

export const systemTagsOptions: OptionsType<OptionType> = [
  {
    label: CUSTOMER_RETENTION_STATUS.NEW_CUSTOMER,
    value: "NEW_CUSTOMER",
  },
  {
    label: CUSTOMER_RETENTION_STATUS.FIRST_TIME_BUYER,
    value: "FIRST_TIME_BUYER",
  },
  {
    label: CUSTOMER_RETENTION_STATUS.REPEAT_BUYER,
    value: "REPEAT_BUYER",
  },
  {
    label: CUSTOMER_RETENTION_STATUS.UNSEEN,
    value: "UNSEEN",
  },
  {
    label: CUSTOMER_RETENTION_STATUS.POTENTIAL_CUSTOMER,
    value: "POTENTIAL_CUSTOMER",
  },
];
