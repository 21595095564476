import * as React from "react";

const SvgIcCampaign = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.554 21.053l.805.806a2.389 2.389 0 010 3.38l-1.084 1.085.85.824a1.596 1.596 0 010 2.255 1.596 1.596 0 01-2.253 0L11.256 25.9l2.254-2.254 1.62 1.569 1.102-1.102a.795.795 0 000-1.126l-.962-.963c.414-.347.839-.672 1.284-.97zM7.312 16.32l5.635 5.635L9.003 25.9a2.39 2.39 0 01-3.382 0l-2.254-2.254a2.39 2.39 0 010-3.381l3.945-3.945zm1.69 3.945a.797.797 0 00-1.126 0l-1.127 1.127a.797.797 0 101.127 1.127l1.127-1.127a.797.797 0 000-1.127zM12.23 7.071l9.931 9.93-.401.08c-2.814.564-5.45 1.967-7.616 3.816L8.37 15.125c1.848-2.167 3.215-4.84 3.778-7.653l.08-.4zm.787-3.722a1.6 1.6 0 012.255.001l10.61 10.61a1.595 1.595 0 01-2.254 2.254L13.016 5.605a1.598 1.598 0 010-2.255zM25.87 9.042a.796.796 0 110 1.594h-1.594a.796.796 0 110-1.594zm-2.158-4.548a.797.797 0 111.127 1.127l-1.594 1.594a.797.797 0 11-1.127-1.127zm-4.218-1.827c.44 0 .797.356.797.797v1.593a.796.796 0 11-1.594 0V3.464c0-.44.357-.797.797-.797z"
    />
  </svg>
);

export default SvgIcCampaign;
