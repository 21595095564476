import { HeaderType } from "types/Tab";

import {
  TAB_CONFIGS_WITH_HIGHLIGHT_PRODUCT,
  TAB_CONFIGS_WITH_HIGHLIGHT_PRODUCT_AND_CONTENT_MANAGEMENT,
} from "pages/Products/config";

export const getProductTabConfig = (isDigitalContentEnabled: boolean): ReadonlyArray<HeaderType> => {
  if (isDigitalContentEnabled) {
    return TAB_CONFIGS_WITH_HIGHLIGHT_PRODUCT_AND_CONTENT_MANAGEMENT;
  }

  return TAB_CONFIGS_WITH_HIGHLIGHT_PRODUCT;
};
