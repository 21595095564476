import { gql } from "@apollo/client";

export const CAMPAIGNS = gql`
  query campaigns($projectId: ID!, $limit: Int, $offset: Int, $filter: CampaignFilterInput) {
    campaigns(projectId: $projectId, limit: $limit, offset: $offset, filter: $filter) {
      results {
        id
        projectId
        name
        startDate
        endDate
        description
        status
      }
      total
    }
  }
`;

export const CAMPAIGN = gql`
  query campaign($projectId: ID!, $campaignId: ID!, $offset: Int, $limit: Int) {
    campaign(projectId: $projectId, campaignId: $campaignId) {
      id
      projectId
      name
      startDate
      endDate
      description
      status
      promotions(offset: $offset, limit: $limit) {
        hasNext
        results {
          id
          campaignId
          name
          shortName
          isActive
          type
          isIncludedAllProducts
          settings {
            type
            condition
            isBundled
            awardTotalPrice
            awardFromLowToHigh
            threshold {
              amount
              price
            }
            discount {
              amount
              fixed
              percent
            }
            shippingMethod
            isRepeatable
          }
        }
      }
    }
  }
`;

export const CAMPAIGN_DETAIL = gql`
  query campaign($projectId: ID!, $campaignId: ID!) {
    campaign(projectId: $projectId, campaignId: $campaignId) {
      id
      projectId
      name
      startDate
      endDate
      description
      status
    }
  }
`;
