const chat = {
  en: {
    "Check out": "Check out",
    "Please turn off 1Convo Bot in order to edit cart for this customer.":
      "Please turn off 1Convo Bot in order to edit cart for this customer.",
    "1Convo Bot cannot response to customer&apos;s message until you turn it back on.":
      "1Convo Bot cannot response to customer’s message until you turn it back on.",
    Note: "Note",
    "Shopping cart": "Shopping cart",
    "Cart is empty.": "Cart is empty.",
    "Add more product": "Add more product",
    "Update cart": "Update cart",
    "Confirm to check out": "Confirm to check out",
    Close: "Close",
    "item(s)": "item(s)",
    "Review order": "Review order",
    "Manage shopping cart": "Manage shopping cart",
    No: "No",
    Quantity: "Quantity",
    Instock: "Instock",
    "This product is out of stock or disabled.": "This product is out of stock or disabled.",
    "This product is expire.": "This product is expire.",
    "shoppingCart.outOfStockMessage": "Please remove, reduce amount or change product SKU.",
    "Please add some product": "Please add some product",
    Change: "Change",
    Remove: "Remove",
    "Update successful": "Update successful",
    "Update failed": "Update fail",
    "shoppingCart.addProducts.error": "Add products fail. Please try again later.",
    "shoppingCart.button.label.addToCart": "Add to cart",
    "shoppingCart.button.label.addToCart.mobile": "Add",
    "shoppingCart.button.label.discountCondition": "(Discount will show on next page)",
    "shoppingCart.checkOptionError.optionRequired": "Please select choices in option",
    "shoppingCart.warningAlert.orderValueZero.title": "Placing an order is not supported",
    "shoppingCart.warningAlert.orderValueZero.subTitle": "when the order's total remains ฿0.",
    "shoppingCart.confirmationModal.removeProduct": "Are you sure you want to remove this item?",
    "shoppingCart.manageShoppingCart.productCard.isDisabledText": "This product is disabled.",
    "shoppingCart.productCard.freeBy": "Get free by",
    "No shipping methods": "No shipping methods",
    "Checkout is successful": "Checkout is successful",
    "Send order summary and payment method to the customer": "Send order summary and payment method to the customer",
    "Add new order": "Add new order",
    "Add offline order": "Add offline order",
    Continue: "Continue",
    FILL_RECIPIENT_INFO: "Fil recipient information",
    SHIPPING_SELECTION: "Select shipping method",
    BRANCH_SELECTION: "Select branch",
    ADDRESS: "Fill shipping address",
    DELIVERY_METHOD: "Select delivery method",
    "Shipping address": "Shipping address",
    "Search existing customer by name or phone number": "Search existing customer by name or phone number",
    "Customer is not found": "Customer is not found",
    OVER_BUSINESS_HOURS_REMARK:
      "Currently out of business hours, we will deliver as soon as possible according to business hours",
    "Recipient information": "Recipient information",
    "expired at": "Expired at",
    "after purchased": "Expired at: {{value}}{{unit}} after purchased",
  },
  th: {
    "Check out": "สั่งซื้อ",
    "Please turn off 1Convo Bot in order to edit cart for this customer.":
      "กรุณาปิด 1Convo บอทก่อนเพื่อแก้ไขรายการสินค้าของลูกค้า",
    "1Convo Bot cannot response to customer&apos;s message until you turn it back on.":
      "1Convo บอทจะไม่สามารถตอบลูกค้าได้จนกว่าแอดมินเปิดบอทกลับมา",
    Note: "หมายเหตุ",
    "Shopping cart": "รายการสั่งซื้อ",
    "Cart is empty.": "ไม่มีสินค้าในรายการสั่งซื้อ",
    "Add more product": "เพิ่มสินค้า",
    "Update cart": "อัปเดตรายการสั่งซื้อ",
    "Confirm to check out": "ยืนยันการสั่งซื้อ",
    Close: "ปิด",
    "item(s)": "รายการ",
    "Review order": "รายการสั่งซื้อ",
    "Manage shopping cart": "จัดการรายการสั่งซื้อ",
    No: "ไม่",
    Quantity: "จำนวน",
    Instock: "จำนวนสินค้าคงเหลือ",
    "This product is out of stock or disabled.": "สินค้าชิ้นนี้หมดหรือไม่เปิดใช้งาน",
    "This product is expire.": "สินค้าชิ้นนี้หมดอายุ",
    "shoppingCart.outOfStockMessage": "กรุณาลบสินค้าออก,ลดจำนวนหรือเปลี่ยนเป็นตัวเลือกอื่น",
    "Please add some product": "กรุณาเพิ่มสินค้า",
    Change: "เปลี่ยน",
    Remove: "ลบ",
    "Update successful": "อัปเดตสำเร็จ",
    "Update failed": "อัปเดตล้มเหลว",
    "shoppingCart.addProducts.error": "การเพิ่มสินค้าล้มเหลว กรุณาลองใหม่อีกครั้ง",
    "shoppingCart.button.label.addToCart.mobile": "เพิ่ม",
    "shoppingCart.button.label.addToCart": "เพิ่มรายการสั่งซื้อ",
    "shoppingCart.button.label.discountCondition": "(ส่วนลดจะแสดงในหน้าถัดไป)",
    "shoppingCart.checkOptionError.optionRequired": "โปรดเลือกตัวเลือกสินค้า",
    "shoppingCart.confirmationModal.removeProduct": "คุณต้องการจะลบสินค้าชิ้นนี้หรือไม่?",
    "shoppingCart.manageShoppingCart.productCard.isDisabledText": "สินค้านี้ถูกปิดการใช้งาน",
    "shoppingCart.productCard.freeBy": "ได้รับฟรีจาก",
    "shoppingCart.warningAlert.orderValueZero.subTitle": "เมื่อรายการสั่งซื้อ ราคารวมต่ำกว่า 0 บาท",
    "shoppingCart.warningAlert.orderValueZero.title": "ไม่รองรับการเปิดออเดอร์",
    "No shipping methods": "ไม่มีวิธีจัดส่ง",
    "Checkout is successful": "สั่งซื้อสำเร็จ",
    "Send order summary and payment method to the customer": "ส่งสรุปการสั่งซื้อและช่องทางการชำระเงินไปยังลูกค้า",
    "Add new order": "เพิ่มคำสั่งซื้อ",
    "Add offline order": "เพิ่มออเดอร์ออฟไลน์",
    Continue: "ต่อไป",
    FILL_RECIPIENT_INFO: "กรอกข้อมูลผู้รับ",
    SHIPPING_SELECTION: "เลือกวิธีการจัดส่ง",
    BRANCH_SELECTION: "เลือกสาขา",
    ADDRESS: "กรอกที่อยู่จัดส่ง",
    DELIVERY_METHOD: "เลือกประเภทการจัดส่ง",
    "Shipping address": "ที่อยู่จัดส่ง",
    "Search existing customer by name or phone number": "ค้นหาลูกค้าจากชื่อ หรือ เบอร์โทร",
    "Customer is not found": "ไม่พบลูกค้า",
    OVER_BUSINESS_HOURS_REMARK: "ขณะนี้อยู่นอกเวลาทำการ ทางร้านจะทำการจัดส่งให้เร็วที่สุดตามเวลาทำการนะคะ",
    "Recipient information": "ข้อมูลผู้รับ",
    "expired at": "ใช้ได้ถึง",
    "after purchased": "ใช้ได้ {{value}}{{unit}} หลังจากซื้อ",
  },
};

export default chat;
