import moment from "moment";
import { FILTER_ID, FILTER_SELECT_TYPE } from "domain/Report/ReportFilter/types";

export const INITIAL_PRODUCT_FILTER_LISTS = [
  { id: FILTER_ID.CATEGORIES_LIST, categoryId: [] },
  {
    id: FILTER_ID.DATE_RANGE,
    dateRange: {
      start: moment().subtract(6, "days").startOf("day").toISOString(),
      end: moment().endOf("day").toISOString(),
    },
  },
];

export const MENU_OPTIONS = [
  {
    key: FILTER_ID.PRODUCT_NAME,
    title: FILTER_ID.PRODUCT_NAME,
    filterKey: FILTER_ID.PRODUCT_NAME,
    selectType: FILTER_SELECT_TYPE.CUSTOM_OPTIONS,
    options: [],
  },
  {
    key: FILTER_ID.PRODUCT_SKU_CODE,
    title: FILTER_ID.PRODUCT_SKU_CODE,
    filterKey: FILTER_ID.PRODUCT_SKU_CODE,
    selectType: FILTER_SELECT_TYPE.CUSTOM_OPTIONS,
    options: [],
  },
  {
    key: FILTER_ID.PRICE,
    title: FILTER_ID.PRICE,
    filterKey: FILTER_ID.PRICE,
    selectType: FILTER_SELECT_TYPE.NUMBER_RANGE,
    options: [],
  },
  {
    key: FILTER_ID.TOTAL,
    title: FILTER_ID.TOTAL,
    filterKey: FILTER_ID.TOTAL,
    selectType: FILTER_SELECT_TYPE.NUMBER_RANGE,
    options: [],
  },
];
