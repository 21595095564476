import { gql } from "@apollo/client";

export const DEFAULT_SHIPPING_METHOD = gql`
  query defaultShippingMethod($projectId: ID!) {
    defaultShippingMethod(projectId: $projectId) {
      image
      shipping {
        methodType
        method
        calculateType
        pricePerUnit
        minimumPrice
        maximumPrice
        fixedPrice
        minDuration
        maxDuration
        description
        disabled
        disabledDuration
        isReceiveOrderOutsideDeliveryTime
        isDisabledSchedulePickUp
        isAllowedOnlyCOD
        setting {
          provinceSettings {
            name
            isActive
            price
            postalCodes {
              postalCode
              price
            }
          }
          shippopSetting {
            type
            managementFee
          }
        }
      }
      shippop {
        email
        credential {
          apiKey
        }
        senderInformation {
          name
          phoneNumber
          address
          district
          subDistrict
          province
          postalCode
          isActive
        }
      }
    }
  }
`;

export const CUSTOM_SHIPPING_METHOD = gql`
  query getShippingMethodByProjectId($projectId: ID!) {
    getShippingMethodByProjectId(projectId: $projectId) {
      id
      method
      calculateType
      pricePerUnit
      minimumPrice
      fixedPrice
      minDuration
      maxDuration
      description
      productSKUs {
        id
        product {
          name
        }
        productCode
        price
        image {
          src
        }
        selected
        deleted
        productType {
          value
          key {
            key
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SHIPPING_METHOD_WITH_PRICE = gql`
  query shippingMethodWithPrice($projectId: ID!, $shippingAddressInput: ShippingAddressInput, $itemInput: [ItemInput]) {
    shippingMethodWithPrice(projectId: $projectId, shippingAddressInput: $shippingAddressInput, itemInput: $itemInput) {
      methodType
      method
      calculateType
      pricePerUnit
      minimumPrice
      maximumPrice
      fixedPrice
      minDuration
      maxDuration
      description
      disabled
      totalShippingPrice
      shippingPrice
      isCODPayment
      offlineStore {
        id
        name
      }
      CODPayment {
        id
        type
        isActive
        depositAmount
        depositThreshold
        depositType
        shippingMethod
        managementFee {
          fee
          percent
          type
          isAppliedPromotion
        }
      }
    }
  }
`;

export const SHIPPING_METHOD_WITH_COD = gql`
  query shippingMethodsWithCOD($projectId: ID!) {
    shippingMethodsWithCOD(projectId: $projectId) {
      methodType
      method
      calculateType
      description
      disabled
      isCODPayment
      CODPayment {
        id
        type
        isActive
        shippingMethod
      }
    }
  }
`;

export const VALIDATE_CREDENTIAL_FOR_SHIPPOP = gql`
  query validateShippingCredential($projectId: ID!, $apiKey: String!) {
    validateShippingCredential(projectId: $projectId, apiKey: $apiKey)
  }
`;

export const SHIPPING_QUOTATIONS = gql`
  query shippingQuotations($projectId: ID!, $shippingAddressInput: ShippingAddressInput!, $itemInput: [ItemInput!]) {
    shippingQuotations(projectId: $projectId, shippingAddressInput: $shippingAddressInput, itemInput: $itemInput) {
      shippingId
      type
      name
      enDisplayName
      thDisplayName
      duration {
        min
        max
        isActive
      }
      managementFee {
        type
        fee
        percent
      }
      codInformation {
        minTotalPriceOrder
        managementFee {
          type
          fee
          percent
        }
        deposit {
          type
          fee
          percent
        }
        threshold {
          type
          fee
          percent
        }
        paymentType
      }
      price
      totalShippingPrice
      iconUrl
      isDisabled
      isCod
      disabledReason
      offlineStore {
        id
        projectId
        name
        address
        phoneNumber
        location
        isActive
        openingHours {
          day
          open
          close
          isActive
        }
      }
      trackingUrl
    }
  }
`;

export const SHIPPINGS = gql`
  query shippings($projectId: ID!, $filter: ShippingFilterInput) {
    shippings(projectId: $projectId, filter: $filter) {
      shippingInfographic {
        imageUrl
      }
      results {
        __typename
        id
        projectId
        type
        duration {
          max
          min
          isActive
        }
        name
        description
        isCod
        code
        isCod
        isActive
        isDeleted
        managementFee {
          fee
          type
        }
        codInformation {
          deposit {
            fee
            type
            percent
          }
          threshold {
            fee
            type
            percent
          }
          minTotalPriceOrder
          managementFee {
            fee
            type
            percent
          }
          paymentType
        }
        ... on ShippingShippop {
          setting {
            credential {
              apiKey
            }
            isDisabledSchedulePickUp
            isReceivedOrderOutsideDeliveryTime
            price
            provinces
          }
        }
        ... on ShippingLalamove {
          setting {
            credential {
              apiKey
              apiSecret
            }
            price
            isReceivedOrderOutsideDeliveryTime
          }
        }
        ... on ShippingFixedPrice {
          setting {
            price
          }
        }
        ... on ShippingPostalCode {
          setting {
            price
            provinces
          }
        }
      }
      total
    }
  }
`;

export const SHIPPING = gql`
  query shippings($projectId: ID!, $filter: ShippingFilterInput) {
    shippings(projectId: $projectId, filter: $filter) {
      results {
        id
        type
        enDisplayName
        thDisplayName
        iconUrl
      }
    }
  }
`;
