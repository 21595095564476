import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import get from "lodash/get";
import { downloadFile } from "utils/common";
import { EXPORT_ORDER_REPORTS } from "graphql/salesReport/query";
import { ExportFileType } from "types/SalesReport";

type UseExportReportsDataType = {
  data: {
    exportOrderReports: { url: string };
  };
};

const useExportReports = () => {
  const [exportOrderReports, { data }] = useLazyQuery<UseExportReportsDataType>(EXPORT_ORDER_REPORTS);

  useEffect(() => {
    const url: string = get(data, "exportOrderReports.url") || "";

    if (url) {
      const fileName = url.split("?")[0].split("/").pop();

      if (fileName) {
        downloadFile(url, fileName);
      }
    }
  }, [data]);

  const getReportUrl = async (
    projectId: string,
    isSelectAll: boolean,
    selectedOrderIds: string[],
    excludeOrderIds: string[],
    filter: object,
    exportFileType: ExportFileType,
  ) => {
    if (!isSelectAll && !selectedOrderIds.length && !excludeOrderIds.length) {
      // export all data when no selected any data.
      exportOrderReports({
        variables: {
          projectId,
          isSelectAll: true,
          selectedOrderIds,
          excludeOrderIds,
          filter,
          exportFileType: exportFileType || ExportFileType.XLSX,
        },
      });
    } else {
      exportOrderReports({
        variables: {
          projectId,
          isSelectAll,
          selectedOrderIds,
          excludeOrderIds,
          filter,
          exportFileType: exportFileType || ExportFileType.XLSX,
        },
      });
    }
  };

  return getReportUrl;
};

export default useExportReports;
