import styled from "styled-components";
import Grid from "components/Grid";

export const PaymentCardWrapper = styled(Grid)`
  background-color: ${({ theme }) => theme.COLORS.White};
  margin: 8px 0;
  border-style: solid;
  border-color: transparent;
  border-radius: 8px;
`;

export const CopiedTextContainer = styled.div`
  display: block;
  padding: 5px 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export const DeeplePayWrapper = styled(Grid)`
  img {
    max-width: 109px;
  }
`;

export const StatusBox = styled.div`
  width: 100%;
  display: inline-flex;
  padding-bottom: 20px;
  align-items: center;

  span {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.COLORS.Success};

    &.disabled {
      background-color: ${({ theme }) => theme.COLORS.DarkGray};
    }
  }

  span + div {
    padding-left: 8px;
  }
`;
