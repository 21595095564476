import styled from "styled-components";
import React, { FC, HTMLAttributes } from "react";
import { SvgIcon } from "components/Icon";
import { IcCircleRemove } from "components/SvgIcons";
import { Z_INDEX } from "constants/ZIndex";

export type CircleRemoveIconPropsType = {
  size?: number;
  top?: number;
  right?: number;
  left?: number;
  bottom?: number;
};

const Wrapper = styled.div<CircleRemoveIconPropsType>`
  position: absolute;
  z-index: ${Z_INDEX.REMOVE_IMAGE_ICON};
  color: ${({ theme }) => theme.COLORS.DarkMed};
  /* default top = -7px and right = -12px */
  top: ${({ top }) => (top != null ? `${top}px` : "-7px")};
  right: ${({ right }) => (right != null ? `${right}px` : "-12px")};
  left: ${({ left }) => (left != null ? `${left}px` : "initial")};
  bottom: ${({ bottom }) => (bottom != null ? `${bottom}px` : "initial")};
  svg {
    ${({ size }) => (size != null ? `font-size: ${size}px !important;` : "")}
  }
`;

const CircleRemoveIcon: FC<HTMLAttributes<HTMLDivElement> & CircleRemoveIconPropsType> = ({
  onClick,
  top,
  right,
  left,
  bottom,
  size,
  className,
}) => (
  <Wrapper className={className} onClick={onClick} top={top} right={right} left={left} bottom={bottom} size={size}>
    <SvgIcon className="ml-4" component={IcCircleRemove} fontSize="large" />
  </Wrapper>
);

export default CircleRemoveIcon;
