import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

import { EXPORT_DEEPLE_PAY_TRANSACTIONS } from "graphql/deeplePay/query";

import { DeeplePayTransactionType } from "types/DeeplePay";
import { ExportFileType } from "types/SalesReport";

import { downloadFile } from "utils/common";

type UseExportDeeplePayTransactionType = {
  /**
   * Url for deeple pay transaction file
   */
  readonly exportDeeplePayTransactions: string;
};

type useExportDeeplePayTransactionProps = { onCompleted?: () => void; onError?: () => void };

const useExportDeeplePayTransactions = ({ onCompleted, onError }: useExportDeeplePayTransactionProps) => {
  const [exportDeeplePayTransactionsUrl, { data, loading }] = useLazyQuery<UseExportDeeplePayTransactionType>(
    EXPORT_DEEPLE_PAY_TRANSACTIONS,
    {
      onError,
    },
  );

  useEffect(() => {
    const url: string = data?.exportDeeplePayTransactions || "";
    if (url) {
      const fileName = url.split("?")[0].split("/").pop();
      if (fileName) {
        downloadFile(url, fileName).then(() => {
          if (onCompleted) onCompleted();
        });
      }
    }
  }, [data, onCompleted]);

  const exportDeeplePayTransactions = async (
    projectId: string,
    exportFileType: ExportFileType,
    startDate: string,
    endDate: string,
    transactionType: DeeplePayTransactionType,
  ) => {
    exportDeeplePayTransactionsUrl({
      variables: {
        projectId,
        endDate,
        startDate,
        fileType: exportFileType || ExportFileType.XLSX,
        transactionType: transactionType || DeeplePayTransactionType.INCOME,
      },
    });
  };

  return { exportDeeplePayTransactions, loading };
};

export default useExportDeeplePayTransactions;
