import styled from "styled-components";

export const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  margin: auto;
  object-fit: cover;
`;

export const EditButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
