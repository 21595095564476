import get from "lodash/get";

import { AddProductSKUType, ProductVariationsType, ProductOutputType, Product } from "types/Product";
import nanoid from "utils/nanoid";

import { getProductSKUsOptionsFromDigitalContentOutputType } from "./getProductSKUsOptionsFromDigitalContentOutputType";

const findProductSKUItemMultiBySKUId = (
  productSKUs: AddProductSKUType[],
  firstItemKey: string,
  firstItemValue: string,
  secondItemKey: string,
  secondItemValue: string,
) => {
  return productSKUs.find(
    (productSKU: AddProductSKUType) =>
      get(productSKU, "productType[0].key.key") === firstItemKey &&
      get(productSKU, "productType[0].value") === firstItemValue &&
      get(productSKU, "productType[1].key.key") === secondItemKey &&
      get(productSKU, "productType[1].value") === secondItemValue,
  );
};

const findProductSKUItemSingleBySKUId = (
  productSKUs: AddProductSKUType[],
  firstItemKey: string,
  firstItemValue: string,
) => {
  return productSKUs.find(
    (productSKU: AddProductSKUType) =>
      get(productSKU, "productType[0].key.key") === firstItemKey &&
      get(productSKU, "productType[0].value") === firstItemValue,
  );
};

const getProductTypeIdBySKUValue = (productSKUs: AddProductSKUType[], firstItemValue: string, index: number) => {
  const productSKUItemValueID = productSKUs.find(
    (productSKU: AddProductSKUType) => get(productSKU, `productType[${index}].value`) === firstItemValue,
  );
  return get(productSKUItemValueID, `productType[${index}].id`);
};

const getProductTypeKeyIdBySKUKeyValue = (productSKUs: AddProductSKUType[], itemKey: string, index: number) => {
  const productSKUItemValueID = productSKUs.find(
    (productSKU: AddProductSKUType) => get(productSKU, `productType[${index}].key.key`) === itemKey,
  );
  return get(productSKUItemValueID, `productType[${index}].key.id`);
};

export const constructSKUsData = (
  productData: Product,
  productSKUs: AddProductSKUType[],
  productId: string | undefined = undefined,
  productIsActive: boolean,
  productIsFree: boolean,
  variationList: ProductVariationsType[],
  productSKUShadowId: string,
  outputType?: ProductOutputType,
) => {
  // priority first variation to be first loop of permutation
  const firstVariationValue: string[] = get(variationList, "[0].value", []);
  const secondVariationValue: string[] = get(variationList, "[1].value", []);

  const newProductSKUsData: AddProductSKUType[] = [];

  const defaultWeight = productData.weight ? { weight: Number(productData.weight) } : {};
  const defaultDimension =
    productData.dimension?.[0] === null && productData.dimension?.[1] === null && productData.dimension?.[2] === null
      ? {}
      : { dimension: productData.dimension };

  if (!variationList.length) {
    // Add shadow SKU instead if ProductSKU is empty
    // ("add shadows no Variant");
    let defaultDigitalContentOptions = {};
    if (outputType) {
      defaultDigitalContentOptions = getProductSKUsOptionsFromDigitalContentOutputType(outputType);
    }

    return [
      {
        id: productSKUShadowId || undefined,
        nanoid: nanoid(),
        productCode: productData.productCode,
        cfCode: productData.cfCode || null, // CF_CODE_ENABLE
        ...defaultWeight,
        ...defaultDimension,
        price: productData.defaultPrice,
        productType: [],
        selected: productIsActive,
        isFree: productIsFree,
        image: "",
        ...defaultDigitalContentOptions,
      },
    ];
  }

  firstVariationValue.forEach((firstItemValue) => {
    if (variationList.length === 2) {
      secondVariationValue.forEach((secondItemValue) => {
        const existProductSKU = findProductSKUItemMultiBySKUId(
          productSKUs,
          variationList[0].key,
          firstItemValue,
          variationList[1].key,
          secondItemValue,
        );

        if (existProductSKU) {
          // ("existProductSKU 2 Variant");
          newProductSKUsData.push({
            ...existProductSKU,
            nanoid: nanoid(),
            productId,
          });
        } else {
          // Add new SKU

          let defaultDigitalContentOptions = {};
          if (outputType) {
            defaultDigitalContentOptions = getProductSKUsOptionsFromDigitalContentOutputType(outputType);
          }
          newProductSKUsData.push({
            productId,
            price: productData.defaultPrice,
            nanoid: nanoid(),
            productCode: `${productData.productCode}-${nanoid()}`,
            ...defaultWeight,
            ...defaultDimension,
            image: "",
            selected: productIsActive,
            deleted: false,
            isFree: productIsFree,
            productType: [
              {
                id: getProductTypeIdBySKUValue(productSKUs, firstItemValue, 0),
                value: firstItemValue,
                key: {
                  id: getProductTypeKeyIdBySKUKeyValue(productSKUs, variationList[0].key, 0),
                  key: variationList[0].key,
                },
              },
              {
                id: getProductTypeIdBySKUValue(productSKUs, secondItemValue, 1),
                value: secondItemValue,
                key: {
                  id: getProductTypeKeyIdBySKUKeyValue(productSKUs, variationList[1].key, 1),
                  key: variationList[1].key,
                },
              },
            ],
            ...defaultDigitalContentOptions,
          });
        }
      });
    } else {
      const existProductSKU = findProductSKUItemSingleBySKUId(productSKUs, variationList[0].key, firstItemValue);
      if (existProductSKU) {
        // ("existProductSKU 1 Variant");

        newProductSKUsData.push({
          ...existProductSKU,
          nanoid: nanoid(),
          productId,
          productType: [
            {
              value: firstItemValue,
              key: {
                id: getProductTypeKeyIdBySKUKeyValue(productSKUs, variationList[0].key, 0),
                key: variationList[0].key,
              },
            },
          ],
        });
      } else {
        // ("Add new SKU 1 Variant");
        let defaultDigitalContentOptions = {};
        if (outputType) {
          defaultDigitalContentOptions = getProductSKUsOptionsFromDigitalContentOutputType(outputType);
        }

        newProductSKUsData.push({
          productId,
          price: productData.defaultPrice,
          nanoid: nanoid(),
          productCode: `${productData.productCode}-${nanoid()}`,
          ...defaultWeight,
          ...defaultDimension,
          image: "",
          selected: productIsActive,
          deleted: false,
          isFree: productIsFree,
          productType: [
            {
              value: firstItemValue,
              key: {
                id: getProductTypeKeyIdBySKUKeyValue(productSKUs, variationList[0].key, 0),
                key: variationList[0].key,
              },
            },
          ],
          ...defaultDigitalContentOptions,
        });
      }
    }
  });

  return newProductSKUsData;
};
