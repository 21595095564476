import * as yup from "yup";
import { ActionType } from "types/RichMenu";

const schema = (t?: Function) =>
  yup.object().shape({
    title: yup.string().required(t?.("FORM_IS_REQUIRED", { name: t("TITLE") })),
    action: yup.array().of(
      yup.object({
        actionType: yup.string().required(t?.("FORM_IS_REQUIRED", { name: t("ACTION_TYPE") })),
        value: yup.mixed().when(["actionType"], (actionType: ActionType) => {
          switch (actionType) {
            case ActionType.TEXT:
              return yup.object({
                text: yup
                  .string()
                  .required(t?.("FORM_IS_REQUIRED", { name: t("THIS_FIELD") }))
                  .max(30, t?.("FORM_MAX_LENGTHS", { name: t("THIS_FIELD"), length: 30 })),
              });
            case ActionType.URL:
              return yup.object({
                url: yup
                  .string()
                  .url(t?.("FORM_VALID_URL_IS_REQUIRED", { name: t("URL") }))
                  .required(t?.("FORM_IS_REQUIRED", { name: t("URL") })),
                description: yup
                  .string()
                  .required(t?.("FORM_IS_REQUIRED", { name: t("DESCRIPTION") }))
                  .max(30, t?.("FORM_MAX_LENGTHS", { name: t("THIS_FIELD"), length: 30 })),
              });

            default:
              return yup.string().required(t?.("FORM_IS_REQUIRED", { name: t("THIS_FIELD") }));
          }
        }),
      }),
    ),
  });

export default schema;
