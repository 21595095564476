import MuiTooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const Tooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "rgba(0, 0, 0, 0.84)",
    fontWeight: "bold",
    borderRadius: "6px",
  },
}))(MuiTooltip);

export default Tooltip;

export const FixWidthTooltip = withStyles(() => ({
  arrow: {
    color: "#141931",
  },
  tooltip: {
    backgroundColor: "#141931",
    fontWeight: "bold",
    borderRadius: "6px",
    maxWidth: "125px",
    width: "fit-content",
    padding: "6px 12px",
    textAlign: "center",
  },
}))(MuiTooltip);

export const PackageSliderTooltip = withStyles(() => ({
  arrow: {
    color: "#141931",
  },
  tooltip: {
    fontWeight: "bold",
    borderRadius: "8px",
    padding: "12px 24px",
    textAlign: "center",
    backgroundColor: "#141931",
    opacity: "0.9 !important",
    margin: "32px 0",
    transition: "none !important",
  },
  popper: {
    zIndex: 1201,
    willChange: "unset !important",
  },
}))(MuiTooltip);

export const UploadDocumentTooltip = withStyles(() => ({
  arrow: {
    color: "#4A4F63",
  },
  tooltip: {
    maxWidth: "135px",
    color: "#F5F7FB",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
    padding: "8px",
    borderRadius: "5px",
    backgroundColor: "#4A4F63",
  },
}))(MuiTooltip);
