import React, { FC, memo, RefObject } from "react";
import moment, { Moment } from "moment";
import styled from "styled-components";
import Event from "@material-ui/icons/Event";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

import { DateTimePicker as MUIDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { DATE_TIME_FORMAT } from "constants/DateTimeFormat";
import replaceStyle from "./replaceStyle";

// eslint-disable-next-line no-undef
const StyledDateTimePicker = styled(MUIDateTimePicker)`
  border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  padding: 12px 0 12px 16px;
  border-radius: 8px;
  height: 48px;

  width: ${({ fullWidth }) => (fullWidth ? "100%" : "170px")};
  box-sizing: border-box;
  input.Mui-focused,
  div.MuiInput-underline:hover:before,
  div.MuiInput-underline:before,
  div.MuiInput-underline:after {
    border-bottom: none;
  }
  background-color: ${({ theme, disabled }) => (disabled ? theme.COLORS.Light : theme.COLORS.White)};

  &:active,
  &:focus-within {
    border-color: ${({ theme, disabled }) => (disabled ? theme.COLORS.LightBlue : theme.COLORS.Primary)};
  }

  .MuiInputAdornment-positionEnd {
    position: absolute;
    right: 4px;
  }

  .MuiInput-root {
    height: 20px;
  }

  .MuiInputBase-input {
    min-width: 130px;
    color: ${({ theme, disabled }) => (disabled ? theme.COLORS.LightBlue : theme.COLORS.DarkGray)};
    font: normal normal normal 14px/20px "Roboto", "Helvetica", "Arial", sans-serif;
  }

  .MuiSvgIcon-root {
    font-size: 16px;
    color: ${({ theme, disabled }) => (disabled ? theme.COLORS.LightBlue : theme.COLORS.Primary)};
  }
`;

type DateTimePickerPropsType = {
  selectedDateTime: string;
  setDateTime: (date: string | null) => void;
  format?: string;
  fullWidth?: boolean;
  minDate?: Date;
  disabled?: boolean;
  inputRef?: ((instance: HTMLInputElement) => void) | RefObject<HTMLInputElement> | null;
  name?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
};

const DateTimePicker: FC<DateTimePickerPropsType> = ({
  fullWidth,
  selectedDateTime,
  setDateTime,
  format = DATE_TIME_FORMAT,
  minDate,
  disabled,
  inputRef,
  name = "",
  disableFuture = true,
  disablePast = false,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeDateTime = (inputDateTime: any) => {
    if (inputDateTime) {
      setDateTime(moment(inputDateTime as Moment).toISOString());
    }
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      {/* eslint-disable-next-line react/no-danger */}
      <style dangerouslySetInnerHTML={{ __html: replaceStyle }} />
      <StyledDateTimePicker
        name={name}
        fullWidth={fullWidth}
        variant="inline"
        format={format}
        value={moment(selectedDateTime)}
        onChange={handleChangeDateTime}
        disableFuture={disableFuture}
        disablePast={disablePast}
        autoOk
        ampm={false}
        minDate={minDate}
        disabled={disabled}
        allowKeyboardControl={false}
        inputRef={inputRef}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <Event />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default memo(DateTimePicker);
