import { useEffect, useCallback, useState } from "react";
import { useQuery } from "@apollo/client";
import { NOTIFICATIONS } from "graphql/notification/query";
import { NOTIFICATION_ADDED } from "graphql/notification/subscription";
import { NotificationInfo } from "types/Notification";

import { LIMIT_PREVIEW_NOTIFICATION_MESSAGES } from "constants/Notification";

type UseNotificationsType = {
  projectId: string;
  pageNo: number;
  customerName: string;
  startDate: string;
  endDate: string;
  selectedNotificationName: string[] | [];
};

type notificationsData = {
  notifications: { results: NotificationInfo[]; total: number; newResult: NotificationInfo[] };
};

type SubscriptionData = {
  subscriptionData: { data: { notificationAdded: NotificationInfo } };
};

export const useNotifications = (props: UseNotificationsType) => {
  const { projectId, pageNo, customerName, startDate, endDate, selectedNotificationName } = props;
  const [newNotificationCount, setNewNotificationCount] = useState(0);
  const customerNames = customerName === "" ? [] : [customerName];

  const { data, loading, subscribeToMore, refetch } = useQuery(NOTIFICATIONS, {
    variables: {
      projectId: Number(projectId),
      offset: pageNo - 1,
      limit: LIMIT_PREVIEW_NOTIFICATION_MESSAGES,
      filter: {
        customerNames,
        dateRange: { start: startDate, end: endDate },
        notificationNames: selectedNotificationName,
      },
    },
  });

  const subscribeNewNotificationAdded = useCallback(
    () =>
      subscribeToMore({
        document: NOTIFICATION_ADDED,
        variables: {
          projectId,
        },
        updateQuery: (prev: notificationsData, { subscriptionData }: SubscriptionData) => {
          if (subscriptionData.data) {
            setNewNotificationCount(newNotificationCount + 1);
          }
          return prev;
        },
      }),
    [newNotificationCount, projectId, subscribeToMore],
  );

  useEffect(() => {
    // un-subscribe when unmounted
    const unsubscribeNotification = subscribeNewNotificationAdded();

    return () => {
      if (unsubscribeNotification) {
        unsubscribeNotification();
      }
    };
  }, [subscribeNewNotificationAdded]);

  const notifications = data?.notifications || [];

  return { loading, notifications, newNotificationCount, setNewNotificationCount, refetch };
};
