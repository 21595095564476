const needVerifyEmail = {
  en: {
    "Need verify email": "Need verify email",
    "Verify link has been sent. Please check the email and follow the instruction":
      "Verify link has been sent. Please check the email and follow the instruction",
    "We’ve sent email verification to": "We’ve email verification email to",
    "Please verify your email before using console": "Please verify your email before using console",
    "or click here to resend": "or click here to resend",
  },
  th: {
    "Need verify email": "รอการยืนยันอีเมล",
    "Verify link has been sent. Please check the email and follow the instruction":
      "ลิ้งยืนยันอีเมลได้ถูกส่งไปแล้ว โปรดตรวจสอบอีเมลของคุณ",
    "We’ve email verification to": "เราได้ส่งอีเมลยืนยันตัวตนไปที่",
    "Please verify your email before using console": "โปรดยืนยันอีเมลของคุณก่อนเข้าใช้ระบบ",
    "or click here to resend": "หรือ คลิก เพื่อส่งซ้ำ",
  },
};

export default needVerifyEmail;
