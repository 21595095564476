export default {
  en: {
    DUPLICATED_PROJECT_CREDENTIAL:
      "Because this page is already connected to another store. To connect, please press 'Disconnect' to ‘{{name}}‘ store and reconnect again.",
  },
  th: {
    DUPLICATED_PROJECT_CREDENTIAL:
      "เนื่องจากเพจนี้มีการเชื่อมต่อกับร้านอื่นแล้ว หากต้องการเชื่อมต่อ กรุณากด ‘ยกเลิกการเชื่อมต่อ’ กับร้าน ‘{{name}}‘ ก่อน กลับมาเชื่อมต่อใหม่อีกครั้ง",
  },
};
