import React from "react";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  padding: 16px 20px 60px 20px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 16px 0 60px 0;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const BranchCardList = styled(({ isShowSaveButton, ...rest }) => <div {...rest} />)`
  ${({ isShowSaveButton }) =>
    isShowSaveButton &&
    css`
      margin-bottom: 80px;
    `}
`;
