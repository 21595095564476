import * as yup from "yup";

import {
  COD_DEPOSIT_AMOUNT_KEY,
  COD_DEPOSIT_TYPE_KEY,
  COD_DEPOSIT_TYPES,
  COD_ID_KEY,
  COD_IS_ACTIVE_KEY,
  COD_MANAGEMENT_FEE_TYPES,
  COD_MANAGEMENT_FEE_TYPE_KEY,
  COD_MANAGEMENT_FEE_KEY,
  COD_SHIPPING_METHOD_KEY,
  COD_THRESHOLD_KEY,
  PAYMENT_GATEWAY_API_KEY,
  PAYMENT_GATEWAY_ID_KEY,
  PAYMENT_GATEWAY_IS_ACTIVE_KEY,
  PAYMENT_GATEWAY_MD5_SECRET_KEY,
  PAYMENT_GATEWAY_MERCHANT_ID,
  PAYMENT_GATEWAY_DESCRIPTION_KEY,
  PAYMENT_GATEWAY_CUSTOM_URL,
  PAYMENT_GATEWAY_CUSTOM_HEADER,
  PAYMENT_GATEWAY_CUSTOM_BODY,
  PAYMENT_GATEWAY_CUSTOM_ICON_URL,
  PROMPT_PAY_ACCOUNT_NAME_KEY,
  PROMPT_PAY_ACCOUNT_NUMBER_KEY,
  PROMPT_PAY_CUSTOM_QR_IMAGE,
  PROMPT_PAY_ID_KEY,
  PROMPT_PAY_TYPE_KEY,
  PROMPT_PAY_IS_ACTIVE_KEY,
  PAYMENT_GATEWAY_TYPE_KEY,
  PAYMENT_TYPES,
  SECOND_PAYMENT_GATEWAY_ID_KEY,
  SECOND_PAYMENT_GATEWAY_IS_ACTIVE_KEY,
  PROMPT_PAY_PREFIX,
} from "constants/Payment";

import { NewBankAccountPaymentType } from "types/Payment";
import { omitBy } from "lodash";

export const generateBankPaymentSchema = (id: string) => {
  const bankPaymentIsActiveKey = `${id}IsActive`;

  return {
    [bankPaymentIsActiveKey]: yup.boolean().required(),
    [`${id}AccountName`]: yup.string().when(bankPaymentIsActiveKey, (isActive: boolean) => {
      if (isActive) {
        return yup
          .string()
          .trim()
          .required("Account name is required")
          .matches(/^[^0-9].*/gm, "First character can not be number");
      }
      return yup.string();
    }),
    [`${id}AccountNumber`]: yup.string().when(bankPaymentIsActiveKey, (isActive: boolean) => {
      if (isActive) {
        return yup.string().required("Account number is required").matches(/^\d*$/gm, "Please enter number only");
      }
      return yup.string();
    }),
    [`${id}BankName`]: yup.string().when(bankPaymentIsActiveKey, (isActive: boolean) => {
      if (isActive) {
        return yup.string().trim().required("Please select bank name");
      }
      return yup.string();
    }),
    [`${id}BankAccountType`]: yup.string().when(bankPaymentIsActiveKey, (isActive: boolean) => {
      if (isActive) {
        return yup.string().required("Please select account type");
      }
      return yup.string();
    }),
    [`${id}Branch`]: yup.string().when(bankPaymentIsActiveKey, (isActive: boolean) => {
      if (isActive) {
        return yup
          .string()
          .trim()
          .required("Branch is required")
          .matches(/^[^0-9].*/gm, "First character can not be number");
      }
      return yup.string();
    }),
  };
};

export const getPaymentSchema = (
  newBankPayments: NewBankAccountPaymentType[],
  editedBankPaymentIds: string[],
  isBankTransferEnabled?: boolean,
) => {
  let inputSchema = {
    // Promptpay validation
    [PROMPT_PAY_ID_KEY]: yup.string(),
    [PROMPT_PAY_IS_ACTIVE_KEY]: yup.boolean().required(),
    [PROMPT_PAY_ACCOUNT_NAME_KEY]: yup.string().when(PROMPT_PAY_IS_ACTIVE_KEY, (isActive: boolean) => {
      if (isActive) {
        return yup
          .string()
          .trim()
          .required("PromptPay account name is required")
          .matches(/^[^0-9].*/gm, "First character can not be number");
      }
      return yup.string();
    }),
    [PROMPT_PAY_ACCOUNT_NUMBER_KEY]: yup.string().when(PROMPT_PAY_IS_ACTIVE_KEY, (isActive: boolean) => {
      if (isActive) {
        return yup
          .string()
          .required("Please enter phone number 10 character or ID number 13 character")
          .matches(
            /^(\d{10}|\d{13}|\d{15})$/gm,
            "PromptPay number can be either phone number 10 character or ID number 13 character",
          );
      }
      return yup.string();
    }),
    [PROMPT_PAY_TYPE_KEY]: yup.string(),
    [PROMPT_PAY_CUSTOM_QR_IMAGE]: yup.string(),

    // COD validation
    [COD_ID_KEY]: yup.string(),
    [COD_IS_ACTIVE_KEY]: yup.boolean().required(),
    [COD_SHIPPING_METHOD_KEY]: yup.string().when(COD_IS_ACTIVE_KEY, (isActive: boolean) => {
      if (isActive) {
        return yup.string().typeError("Please select carrier name").required("Please select carrier name");
      }
      return yup.string().nullable();
    }),
    [COD_THRESHOLD_KEY]: yup.number().when(COD_IS_ACTIVE_KEY, (isActive: boolean) => {
      if (isActive) {
        return yup
          .number()
          .typeError(
            "Minimum total price per order is require. Please fill in 0 if you not have require minimum total price per order",
          )
          .min(0, "Minimum total price per order cannot be below 0");
      }
      return yup.string();
    }),
    [COD_DEPOSIT_TYPE_KEY]: yup.string().when(COD_IS_ACTIVE_KEY, (isActive: boolean) => {
      if (isActive) {
        return yup.string().typeError("Please select deposit type").required("Please select deposit type");
      }

      return yup.string().nullable();
    }),
    [COD_DEPOSIT_AMOUNT_KEY]: yup.string().when(COD_IS_ACTIVE_KEY, (isActive: boolean) => {
      if (!isActive) {
        return yup.string();
      }

      return yup.string().when([COD_DEPOSIT_TYPE_KEY, COD_THRESHOLD_KEY], (type: string, threshold: number) => {
        switch (type) {
          case COD_DEPOSIT_TYPES.PERCENTAGE:
            return yup
              .string()
              .required("Please enter Deposit amount")
              .test("below 1", "Deposit percent cannot be below 1", (val) => Number(val) > 0)
              .test("above 100", "Deposit percent cannot be above 100", (val) => Number(val) <= 100);
          case COD_DEPOSIT_TYPES.FIXED_PRICE:
            return yup
              .string()
              .required("Please enter Deposit amount")
              .test("below 1", "Fix deposit cannot be below 1", (val) => Number(val) > 0)
              .test(
                "above threshold",
                "Fix deposit value cannot exceed Minimum total price per order",
                (val) => Number(val) <= threshold,
              );
          default:
            return yup.string();
        }
      });
    }),
    [COD_MANAGEMENT_FEE_TYPE_KEY]: yup.string().when(COD_IS_ACTIVE_KEY, (isActive: boolean) => {
      if (isActive) {
        return yup
          .string()
          .typeError("Please select management fee type")
          .required("Please select management fee type");
      }

      return yup.string().nullable();
    }),
    [COD_MANAGEMENT_FEE_KEY]: yup.string().when(COD_IS_ACTIVE_KEY, (isActive: boolean) => {
      if (!isActive) {
        return yup.string();
      }

      return yup.string().when([COD_MANAGEMENT_FEE_TYPE_KEY], (type: string) => {
        switch (type) {
          case COD_MANAGEMENT_FEE_TYPES.PERCENTAGE:
            return yup
              .string()
              .required("paymentCOD.error.required")
              .test("below 1", "Management fee percent cannot be below 1", (val) => Number(val) > 0)
              .test("above 100", "Management fee percent cannot be above 100", (val) => Number(val) <= 100);
          case COD_MANAGEMENT_FEE_TYPES.FIXED_PRICE:
            return yup
              .string()
              .required("paymentCOD.error.required")
              .test("below 1", "Fixed management fee cannot be below 1", (val) => Number(val) > 0);
          default:
            return yup.string();
        }
      });
    }),

    // Payment gateway
    [PAYMENT_GATEWAY_ID_KEY]: yup.string(),
    [PAYMENT_GATEWAY_IS_ACTIVE_KEY]: yup.boolean().required(),

    // Second payment gateway
    [SECOND_PAYMENT_GATEWAY_ID_KEY]: yup.string(),
    [SECOND_PAYMENT_GATEWAY_IS_ACTIVE_KEY]: yup.boolean(),
  };

  editedBankPaymentIds.forEach((id: string) => {
    const bankPaymentSchema = generateBankPaymentSchema(id);

    inputSchema = {
      ...inputSchema,
      ...bankPaymentSchema,
    };
  });

  newBankPayments.forEach(({ id }: NewBankAccountPaymentType) => {
    const bankPaymentSchema = generateBankPaymentSchema(id);

    inputSchema = {
      ...inputSchema,
      ...bankPaymentSchema,
    };
  });

  // disabled prompt pay when bank transfer is not active
  if (!isBankTransferEnabled) {
    inputSchema = omitBy(inputSchema, (_value, key) => key.includes(PROMPT_PAY_PREFIX));
  }

  return yup.object().shape(inputSchema);
};

export const getCODSchema = () => {
  const inputSchema = {
    [COD_ID_KEY]: yup.string(),
    [COD_IS_ACTIVE_KEY]: yup.boolean().required(),
    [COD_SHIPPING_METHOD_KEY]: yup.string().when(COD_IS_ACTIVE_KEY, (isActive: boolean) => {
      if (isActive) {
        return yup.string().typeError("Please select carrier name").required("Please select carrier name");
      }
      return yup.string().nullable();
    }),
    [COD_THRESHOLD_KEY]: yup.number().when(COD_IS_ACTIVE_KEY, (isActive: boolean) => {
      if (isActive) {
        return yup
          .number()
          .typeError(
            "Minimum total price per order is require. Please fill in 0 if you not have require minimum total price per order",
          )
          .min(0, "Minimum total price per order cannot be below 0");
      }
      return yup.string();
    }),
    [COD_DEPOSIT_TYPE_KEY]: yup.string().when(COD_IS_ACTIVE_KEY, (isActive: boolean) => {
      if (isActive) {
        return yup.string().typeError("Please select deposit type").required("Please select deposit type");
      }

      return yup.string().nullable();
    }),
    [COD_DEPOSIT_AMOUNT_KEY]: yup.string().when(COD_IS_ACTIVE_KEY, (isActive: boolean) => {
      if (!isActive) {
        return yup.string();
      }

      return yup.string().when([COD_DEPOSIT_TYPE_KEY, COD_THRESHOLD_KEY], (type: string, threshold: number) => {
        switch (type) {
          case COD_DEPOSIT_TYPES.PERCENTAGE:
            return yup
              .string()
              .required("Please enter Deposit amount")
              .test("below 1", "Deposit percent cannot be below 1", (val) => Number(val) > 0)
              .test("above 100", "Deposit percent cannot be above 100", (val) => Number(val) <= 100);
          case COD_DEPOSIT_TYPES.FIXED_PRICE:
            return yup
              .string()
              .required("Please enter Deposit amount")
              .test("below 1", "Fix deposit cannot be below 1", (val) => Number(val) > 0)
              .test(
                "above threshold",
                "Fix deposit value cannot exceed Minimum total price per order",
                (val) => Number(val) <= threshold,
              );
          default:
            return yup.string();
        }
      });
    }),
    [COD_MANAGEMENT_FEE_TYPE_KEY]: yup.string().when(COD_IS_ACTIVE_KEY, (isActive: boolean) => {
      if (isActive) {
        return yup
          .string()
          .typeError("Please select management fee type")
          .required("Please select management fee type");
      }

      return yup.string().nullable();
    }),
    [COD_MANAGEMENT_FEE_KEY]: yup.string().when(COD_IS_ACTIVE_KEY, (isActive: boolean) => {
      if (!isActive) {
        return yup.string();
      }

      return yup.string().when([COD_MANAGEMENT_FEE_TYPE_KEY], (type: string) => {
        switch (type) {
          case COD_MANAGEMENT_FEE_TYPES.PERCENTAGE:
            return yup
              .string()
              .required("paymentCOD.error.required")
              .test("below 1", "Management fee percent cannot be below 1", (val) => Number(val) > 0)
              .test("above 100", "Management fee percent cannot be above 100", (val) => Number(val) <= 100);
          case COD_MANAGEMENT_FEE_TYPES.FIXED_PRICE:
            return yup
              .string()
              .required("paymentCOD.error.required")
              .test("below 1", "Fixed management fee cannot be below 1", (val) => Number(val) > 0);
          default:
            return yup.string();
        }
      });
    }),
  };

  return yup.object().shape(inputSchema);
};

export const paymentGatewaySchema = yup.object().shape({
  [PAYMENT_GATEWAY_TYPE_KEY]: yup.string().required("paymentGateway.error.paymentGatewayType.required"),
  [PAYMENT_GATEWAY_MERCHANT_ID]: yup.string().when(PAYMENT_GATEWAY_TYPE_KEY, (paymentGatewayType: string) => {
    if (paymentGatewayType !== PAYMENT_TYPES.PAYMENT_GATEWAY_CUSTOM) {
      return yup.string().required("Merchant ID is required");
    }
    return yup.string();
  }),
  [PAYMENT_GATEWAY_API_KEY]: yup.string().when(PAYMENT_GATEWAY_TYPE_KEY, (paymentGatewayType: string) => {
    if (paymentGatewayType === PAYMENT_TYPES.PAYMENT_GATEWAY_CHILL_PAY) {
      return yup.string().required("API Key is required");
    }
    return yup.string();
  }),
  [PAYMENT_GATEWAY_MD5_SECRET_KEY]: yup.string().when(PAYMENT_GATEWAY_TYPE_KEY, (paymentGatewayType: string) => {
    if (paymentGatewayType !== PAYMENT_TYPES.PAYMENT_GATEWAY_CUSTOM) {
      return yup.string().required("MD5 secret key is required");
    }
    return yup.string();
  }),
  [PAYMENT_GATEWAY_DESCRIPTION_KEY]: yup.string().trim(),

  [PAYMENT_GATEWAY_CUSTOM_URL]: yup.string().when(PAYMENT_GATEWAY_TYPE_KEY, (paymentGatewayType: string) => {
    if (paymentGatewayType === PAYMENT_TYPES.PAYMENT_GATEWAY_CUSTOM) {
      return yup.string().required("paymentGatewayCustom.error.paymentGatewayUrl.required");
    }
    return yup.string();
  }),
  [PAYMENT_GATEWAY_CUSTOM_HEADER]: yup.string().trim(),
  [PAYMENT_GATEWAY_CUSTOM_BODY]: yup.string().trim(),
  [PAYMENT_GATEWAY_CUSTOM_ICON_URL]: yup.string().trim(),
});

export default getPaymentSchema;
