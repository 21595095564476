import * as yup from "yup";

const schema = yup.object().shape({
  botTimer: yup
    .number()
    .typeError("Bot timer is required")
    .positive("Should be positive number and integer")
    .integer("Should be positive number and integer"),
});

export default schema;
