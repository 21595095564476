import { useQuery } from "@apollo/client";
import get from "lodash/get";
// import { CampaignQueryType } from "types/Campaign";
import { GET_OUTCOME_TRANSACTION } from "graphql/transaction/query";
// import { MOCK_TRANSACTION_REPORT_DATA } from "../../../mock";

type Variables = {
  page: number;
  perPage: number;
  projectId: string;
};

const useOutcomeTransactions = (variables: Variables) => {
  const { data, loading: isLoading } = useQuery(GET_OUTCOME_TRANSACTION, {
    variables: { projectId: variables.projectId, offset: variables.page, limit: variables.perPage },
  });
  return {
    outcomeTransactions: get(data, "queryOutcomeDeeplePayWalletTransactionsByProjectId.results") || [],
    isLoading,
    total: get(data, "queryOutcomeDeeplePayWalletTransactionsByProjectId.total") || 0,
  };
};

export default useOutcomeTransactions;
