import get from "lodash/get";

import { OrderProductReportResultType } from "types/SalesReport";
import { convertPriceFormat } from "utils/common";
import { getProductTypes } from "utils/product/getProductTypes";

import { formattedOptions } from "./formattedOptions";
import { DEFAULT_VALUE } from "../config";

export const formattedProductReports = (productReports: OrderProductReportResultType[]) => {
  const newFormattedProductReports = productReports.map((productReport: OrderProductReportResultType) => {
    const productSKU = get(productReport, "productSKU") || {};
    const product = get(productReport, "product") || {};
    const productType = get(productReport, "productSKU.productType") || [];

    return {
      id: get(productSKU, "id"),
      productCode: get(productSKU, "productCode") || DEFAULT_VALUE,
      productName: get(product, "name") || DEFAULT_VALUE,
      categoryName: get(productReport, "categoryName") || DEFAULT_VALUE,
      price: convertPriceFormat(get(productReport, "price") || 0),
      amount: get(productReport, "amount") || DEFAULT_VALUE,
      sku: getProductTypes(productType) || formattedOptions(get(productSKU, "options")) || DEFAULT_VALUE,
      total: get(productReport, "total") || 0,
    };
  });

  return newFormattedProductReports;
};
