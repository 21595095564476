import React, { useState, useEffect, useMemo } from "react";
import Grid from "components/Grid";
import { FilterItemType, FILTER_ID } from "domain/Report/ReportFilter/types";
import { FilterCardWrapper } from "domain/Report/ReportFilter/Filter.styled";
import EditMenuOptionsItem from "domain/Report/ReportFilter/EditMenuOptionsItem";
import {
  getKeyName,
  getFilterValue,
  getSelectTypeFromFilterKey,
  getOptionsFromKey,
  displayFilterName,
} from "domain/Report/ReportFilter/FilterOrderStat.utils";
import { INITIAL_BROADCAST_FILTER_LISTS, INITIAL_FILTER_ID_LISTS } from "./config";

const BroadcastFilter: React.FC<{
  setFilterValue: Function;
}> = (props) => {
  const { setFilterValue } = props;

  const [filterList, setFilterList] = useState<FilterItemType[]>(INITIAL_BROADCAST_FILTER_LISTS);

  const handleSetFilterList = (filterListData: FilterItemType[]) => {
    setFilterList(filterListData);
  };

  const getFinalFilterResult = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any = {};
    filterList.forEach((filterItem) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(filterItem)) {
        if (key !== "id") {
          result[key] = value;
        }
      }
    });
    return result;
  };

  useEffect(() => {
    setFilterValue(getFinalFilterResult());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterList]);

  const initialFilters = useMemo(
    () =>
      filterList.filter((filterItem) => {
        const keyName = getKeyName(filterItem);
        return INITIAL_FILTER_ID_LISTS.includes(keyName as FILTER_ID);
      }),
    [filterList],
  );

  return (
    <Grid container alignItems="center" alignContent="flex-start" style={{ zIndex: 2 }} className="pb-2">
      {/* zIndex: 2 will be on top of table styled */}

      {initialFilters.map((filterItem: FilterItemType) => {
        const keyName = getKeyName(filterItem);
        return (
          <Grid item key={`${filterItem.id}-fixed`}>
            <FilterCardWrapper
              style={
                keyName === FILTER_ID.DATE_RANGE
                  ? { border: 0, minHeight: "46px", backgroundColor: "unset" }
                  : { minHeight: "46px" }
              }
              className="mr-2 my-1"
            >
              <Grid container justify="flex-start" alignContent="center" alignItems="center">
                <EditMenuOptionsItem
                  key={filterItem.id}
                  title={displayFilterName(filterItem)}
                  selectType={getSelectTypeFromFilterKey(filterItem)}
                  options={getOptionsFromKey(filterItem, filterList, [])}
                  keyName={getKeyName(filterItem) || ""}
                  filterValue={getFilterValue(filterItem)}
                  filterList={filterList}
                  setValue={handleSetFilterList}
                  editId={filterItem.id}
                />
              </Grid>
            </FilterCardWrapper>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default BroadcastFilter;
