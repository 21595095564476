import { IcAwaitingShipping, IcShipping, IcAiWelcomemessage } from "components/SvgIcons";
import { SHIPPING_DELIVERY_PERIOD, SHIPPING_DEFAULT, SHIPPOP, PARCEL_INVOICE_REPORT } from "constants/Router";

export const OWNER_TAB_CONFIGS = [
  {
    id: SHIPPING_DEFAULT,
    title: "Default shipping",
    svgIcon: IcShipping,
  },
  {
    id: SHIPPING_DELIVERY_PERIOD,
    title: "shipping.deliveryPeriod.title",
    svgIcon: IcAwaitingShipping,
  },
  {
    id: PARCEL_INVOICE_REPORT,
    title: "Parcel invoice report",
    svgIcon: IcAiWelcomemessage,
  },
  {
    id: SHIPPOP,
    title: "Shippop",
    svgIcon: IcShipping,
  },
];

export const OWNER_TAB_CONFIGS_WITHOUT_SHIPPOP = [
  {
    id: SHIPPING_DEFAULT,
    title: "Default shipping",
    svgIcon: IcShipping,
  },
  {
    id: SHIPPING_DELIVERY_PERIOD,
    title: "shipping.deliveryPeriod.title",
    svgIcon: IcAwaitingShipping,
  },
  {
    id: PARCEL_INVOICE_REPORT,
    title: "Parcel invoice report",
    svgIcon: IcAiWelcomemessage,
  },
];

export const ADMIN_TAB_CONFIGS = [
  {
    id: SHIPPING_DEFAULT,
    title: "Default shipping",
    svgIcon: IcShipping,
  },
  {
    id: PARCEL_INVOICE_REPORT,
    title: "Parcel invoice report",
    svgIcon: IcAiWelcomemessage,
  },
  {
    id: SHIPPOP,
    title: "Shippop",
    svgIcon: IcShipping,
  },
];

export const ADMIN_TAB_CONFIGS_WITHOUT_SHIPPOP = [
  {
    id: SHIPPING_DEFAULT,
    title: "Default shipping",
    svgIcon: IcShipping,
  },
  {
    id: PARCEL_INVOICE_REPORT,
    title: "Parcel invoice report",
    svgIcon: IcAiWelcomemessage,
  },
];
