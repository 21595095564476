import * as React from "react";

const SvgIcShipped = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.22 5.825c.123 3.102 2.667 5.594 5.798 5.594h3.177c3.132 0 5.675-2.492 5.799-5.594h1.71a2.911 2.911 0 012.91 2.91v15.328a5.821 5.821 0 01-5.82 5.82H9.375a5.821 5.821 0 01-5.82-5.82V8.735a2.91 2.91 0 012.91-2.91zm14.524 8.765a1.255 1.255 0 00-1.775 0l-6.642 6.642-3.296-3.296a1.255 1.255 0 10-1.775 1.775l4.183 4.183c.49.49 1.285.49 1.775 0l7.53-7.53c.49-.49.49-1.284 0-1.774zM18.652 1.778a2.914 2.914 0 012.91 2.91v.91c0 .077-.019.151-.023.227-.123 2.299-2.014 4.138-4.343 4.138h-3.178c-2.328 0-4.22-1.84-4.342-4.138-.004-.076-.023-.15-.023-.227v-.91a2.914 2.914 0 012.91-2.91zm0 2.91h-6.09l.001.91c0 .08.034.151.047.227.113.69.686 1.228 1.408 1.228h3.178c.722 0 1.295-.537 1.409-1.228.013-.076.047-.147.047-.227v-.91z"
    />
  </svg>
);

export default SvgIcShipped;
