export enum TagType {
  CUSTOM = "CUSTOM",
  CUSTOMER = "CUSTOMER",
  SYSTEM_CUSTOMER_RETENTION_STATUS = "SYSTEM_CUSTOMER_RETENTION_STATUS",
  SYSTEM_BROADCAST = "SYSTEM_BROADCAST",
  SYSTEM_COMMENT_CRAWLER = "SYSTEM_COMMENT_CRAWLER",
  POTENTIAL_CUSTOMER = "POTENTIAL_CUSTOMER",
  RESPONSE_MESSAGE = "RESPONSE_MESSAGE",
}

export type CustomerRetentionStatusKeyType =
  // Deprecated
  | "NEW_CUSTOMER"
  | "FIRST_TIME_BUYER"
  // New system tag
  | "NEW"
  | "FIRST_PURCHASE"
  | "REPEAT_BUYER";

export type SystemTagStatusType = Record<CustomerRetentionStatusKeyType, string>;

type TagMetadataType = {
  broadcastId: string;
  orderId: string;
};

type TagNameType = string | CustomerRetentionStatusKeyType;

export type SystemTagType = {
  id: string;
  projectId: string;
  customerId: string;
  tag: TagNameType;
  type: TagType;
  metadata: TagMetadataType;
  displayTag?: string;
  createdAt: string;
};

export type SystemTagTypeWithFlag = SystemTagType & {
  isSecondary?: boolean;
};

export type SystemTagsQueryVariablesType = {
  projectId: string;
  customerId: string;
};

export type SystemTagsQueryType = {
  systemTags: SystemTagType[];
};
export enum SuggestTagType {
  SYSTEM = "SYSTEM",
  CUSTOM = "CUSTOM",
  CUSTOMER = "CUSTOMER",
  SYSTEM_COMMENT_CRAWLER = "SYSTEM_COMMENT_CRAWLER",
  SYSTEM_CUSTOMER_RETENTION_STATUS = "SYSTEM_CUSTOMER_RETENTION_STATUS",
  SYSTEM_BROADCAST = "SYSTEM_BROADCAST",
}
