import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";
import Grid from "components/Grid";
import Typography from "components/Typography";
import Button from "components/Button";
import { ButtonColors } from "types/Button";

type ConfirmModalPropsType = {
  isOpen: boolean;
  onClose: () => void;
  onClickConfirmButton: () => void;
  confirmButtonColor?: ButtonColors;
  title: string;
  titleParams?: Record<string, string>;
  confirmButtonText: string;
};

const ConfirmModal: FC<ConfirmModalPropsType> = (props) => {
  const { isOpen, onClose, onClickConfirmButton, title, titleParams, confirmButtonText, confirmButtonColor } = props;
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Grid container justify="center" className="p-3">
        <Grid item xs={8} className="text-center py-3">
          <Typography variant="title8" color="dark">
            {t(title, titleParams)}
          </Typography>
        </Grid>
        <Grid item container spacing={1} xs={12} className="pt-1">
          <Grid item xs={6}>
            <Button size="small" color="ghost" fullWidth onClick={onClose}>
              {t("Cancel")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button size="small" fullWidth onClick={onClickConfirmButton} color={confirmButtonColor}>
              {t(confirmButtonText)}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ConfirmModal;
