const shipping = {
  en: {
    "Shipping method": "Shipping",
    to: "to",
    STANDARD: "Standard",
    EXPRESS: "Express",
    "PICK UP": "Pick up",
    PICK_UP: "Pick up",
    PICKUP: "Pick up",
    "Estimated delivery": "Estimated delivery",
    "Rate amount": "Rate amount",
    days: "days",
    "Shipping rule": "Shipping rule",
    CARRIER_NAME: "Carrier name",
    Carrier: "Carrier",
    "Other carrier name": "Other carrier name",
    "Default shipping": "Shipping Method",
    "Custom shipping": "Custom shipping",
    "Default shipping method": "General shipping method",
    CJ: "CJ logistics",
    DHL: "DHL",
    "Inter express": "Inter express",
    "Flash express": "Flash express",
    "J&T": "J&T",
    Kerry: "Kerry",
    "NIM express": "NIM express",
    "NIM express chilled": "NIM express chilled",
    NINJAVAN: "Ninja Van",
    "Porlor express": "Porlor express",
    "SCG yamato express": "SCG yamato express",
    "Thaipost-EMS": "Thaipost-EMS",
    "Thaipost-Register": "Thaipost-Register",
    TNT: "TNT",
    SENDIT: "Sendit",
    X_PRESSO: "Xpresso",
    ZTO: "ZTO express",
    "TP Logistics": "TP Logistics",
    "Flat rate": "Flat rate",
    "The shipping price is the shipping price for the whole cart":
      "The shipping price is the shipping price for the whole cart",
    "Please specify carrier name": "Please specify carrier name",
    "Shipping Method Updated": "Shipping Method Updated",
    "Price per unit": "Price per unit",
    "Next item rate": "Next item rate",
    "shipping.deliveryPeriod.title": "Delivery Period",
    "shipping.deliveryPeriod.deliveryFrom": "Delivery from",
    "shipping.deliveryPeriod.preparingTime": "Preparing time (mins)",
    "shipping.deliveryPeriod.preparingTimePrefix": "Preparing time",
    "shipping.deliveryPeriod.minutes": "minutes",
    "shipping.deliveryPeriod.note": "note* customer can set delivery time (time from now + preparing time)",
    "shipping.deliveryPeriod.button.goToBranch": "Go to branch",
    "shipping.deliveryPeriod.goToBranchDescription":
      "please click the button to set up branch before using shipping with Lalamove",
    "shipping.label.calculateType.DISTANCE_BASE_PRICE": "Calculate shipping price manually",
    "shipping.label.calculateType.FIXED_PRICE": "Flat rate",
    "shipping.label.calculateType.LALAMOVE": "Connect with LALAMOVE",
    "shipping.label.calculateType.SHIPPOP": "Connect with SHIPPOP",
    "shipping.label.calculateType.PICK_UP": "Pick up order at store",
    "shipping.label.calculateType.POSTAL_CODE_BASED_PRICE": "Postcode-based",
    "shipping.label.shippingInfographic": "Shipping Infographic",
    "shipping.label.infoGraphic": "Infographic-based",
    "shipping.description.infoGraphic": "Upload the image to show the shipping information.",
    "shipping.description.calculateType.DISTANCE_BASE_PRICE":
      "This method calculates the shipping price manually by admin.",
    "shipping.description.calculateType.FIXED_PRICE": "This method calculates the shipping price based on flat rate.",
    "shipping.description.calculateType.LALAMOVE": "This method calculates the shipping price based on LALAMOVE.",
    "shipping.description.calculateType.SHIPPOP":
      "This method calculates the shipping price based on weights and dimensions.",
    "shipping.description.calculateType.PICK_UP": "This method requires customers to pick up their orders at store.",
    "shipping.description.calculateType.POSTAL_CODE_BASED_PRICE":
      "This method calculates the shipping price based on postal codes.",
    "Shipping calculate type": "Shipping calculate type",
    "shipping.label.pickUpAtShop": "Offline Pick-up",
    "Please set Address in store setting": "Please set Address in store setting.",
    "Click here to go to store setting": "Click here to go to store setting",
    "Please enable the delivery period": "Please enable the delivery period.",
    "Click here to go to delivery setting": "Click here to go to delivery setting",
    "Manage available stores": "Manage available stores",
    "Recipient first name": "Recipient first name",
    "Recipient last name": "Recipient last name",
    Select: "Select",
    Districts: "Districts",
    "shipping.shippingBoundary": "Shipping boundary",
    "shipping.areasDisabledShipping": "Areas disabled shipping",
    "shipping.excludedProvince": "Excluded province",
    "shipping.locationSetting": "Location setting",
    "shipping.setAFlatRateFor": "Set a flat rate for",
    "shipping.addMoreRates": "+ Add more rates",
    "shipping.ChooseDistricts": "Choose districts you want to set a specific rate for",
    "shipping.Delete": "Delete",
    "shipping.Save": "Save",
    SelectProvinces: "Select provinces",
    Provinces: "Provinces",
    "Are you sure to delete this": "Are you sure to delete this",
    "shipping.label.shippingBoundary": "Shipping boundary",
    "shipping.label.defaultShippingRate": "Default shipping rate",
    "shipping.label.locationSetting": "Location setting",
    "shipping.label.isDisabledSchedulePickUp": "Non-scheduled pick up",
    "shipping.table.header.province": "Province",
    "shipping.table.header.postalCode": "District-Postal Code",
    "shipping.table.header.shippingRates": "Shipping rates",
    "shipping.table.columnValue.defaultRate": "฿ {{price}} (Default rate)",
    "shipping.value.shippingBoundary": "{{count}} province",
    "shipping.value.shippingBoundary_plural": "{{count}} provinces",
    PostalCode: "Postal code",
    "Please select postal code": "Please select postal code",
    "At least 1 province need to be include in shipping boundary":
      "At least 1 province need to be include in shipping boundary",
    "Provinces enabled": "Provinces enabled",
    "shipping.label.isReceiveOrderOutsideDeliveryTime": "Enable to receive order outside delivery time",
    "shippingPriceDetail.label.shippingCondition": "See the condition",
    "shippingPriceDetail.label.seeFullInfo": "See full info:",
    "shipping.shippop.advancedSetting": "Advanced setting",
    "shippop.advanceSetting.shippopBased.title": "Shippop - based",
    "shippop.advanceSetting.shippopBased.description": "Shipping price calculates by weight and dimension",
    "shippop.advanceSetting.postCodeBased.title": "Postcode - based",
    "shippop.advanceSetting.postCodeBased.description":
      "Shipping prices vary depending on areas in which deliveries are made",
    "shippop.advanceSetting.flatRate.title": "Flat rate",
    "shippop.advanceSetting.flatRate.description": "An entire service is charged through a single fixed fee",
    Branch: "Branch",
    "shipping.badgeOnlyCOD": "Only COD",
    "shipping.isAllowedOnlyCOD": "For cash-on delivery only",
    "shipping.shippop.optionLabel.addressFilling": "Fill in your address",
    "shipping.shippop.formLabel.addressFilling": "Address-filling up",
    "shipping.shippop.label.fillingFromBranch": "Filling from branch",
    "shipping.shippop.storeManagementFee.title": "Store management fee",
    "shipping.shippop.storeManagementFee.label": "Fee amount",
    "shipping.shippop.storeManagementFee.remark":
      "Store management fee is the additional fee that can be set on top of the shipping price.",
    "shipping.shippop.remark":
      "Shipping prices of ’Shippop’ can vary from the first quotation based on accuracy of pinned addresses, weight of orders, parcel dimensions and etc.",
    "shipping.shippop.integration.remark":
      "Please contact our Customer Support for more information about SHIPPOP integration.",
    "shipping.shippop.api.key.invalid":
      "Your API key is not correct. Please recheck or contact to Shippop to get the correct key",
    "shipping.distance.base.remark": "Admin will need to fill in the shipping prices manually for every created order.",
    "shipping.shippop.button.addSenderAddress": "+ Add sender’s address",
    "shipping.shippop.address.label.email": "Email: ",
    "shipping.shippop.address.label.name": "Name: ",
    "shipping.shippop.address.label.phone": "Phone no: ",
    "shipping.shippop.address.title": "Sender’s address",
    "shipping.shippop.addressModal.placeholder.addressFilling": "Select filling-up format",
    "shipping.shippop.addressModal.select.label": "Address filling-up",
    "shipping.shippop.label.fillInYourAddress": "Fill in your address",
    "shipping.shippop.credential.title": "Connect with your Shippop account",
    "shipping.shippop.email.title": "Sender’s email",
    "shipping.shippop.credential.email.remark":
      "If you didn't provide an email, we will use your email from sender’s information to connect with shippop",
    "shipping.shippop.apikey.title": "Seller’s API key",
    "shipping.shippop.apikey.remark":
      "If you would like to apply to use Shippop, Please register here for a new user <0>Here</0>. If you already have a account, Please contact Shippop’s sale to get API token and email to use",
    "shipping.shippop.email.remark":
      "Filling in a  new email will replace  ‘{{email}}’ as there is only an e-mail available for connecting with ‘Shippop’",
    "shipping.shippop.confirmModal.content": "Do you want to save changes in Sender’s information ?",
    "shipping.shippop.address.placeholder.required": "Require an address",
    "shipping.shippop.email.placeholder.required": "Require an email",
    "shipping.shippop.senderInformation.title": "Sender’s information",
    "shipping.shippop.buttonText.editInfo": "Edit info",
    "shipping.shippop.buttonText.doneEdit": "Done editing",
    "shipping.shippop.error.senderInformationRequired": "Please fill in shippop sender's information",
    "shipping.lalamove.remark": "Please contact our Customer Support for more information about LALAMOVE integration.",
    "shipping.lalamove.price.rate": "View shipping price rate",
    RECEIPT: "Receipt (default)",
    LETTER: "Letter",
    LETTER4x6: "Letter 4x6",
    STICKER4x6: "Sticker 4x6",
    SIZE: "Size",
    "shippop.modal.label.carrier": "{{count}} carriers",
    "shippop.modal.label.senderInformation": "Sender Information",
    SERVICE_MAINTENANCE: "Service is under maintenance",
    ERR_MAINTENANCE: "Service is under maintenance",
    ERR_ORIGIN: "Origin is out of service area",
    ERR_DEST: "Destination is out of service area",
    ERR_REALTIME_CHECKPRICE: "Cannot calculate shipping distance",
    ERR_REVERSE_GEOCODE_FAILURE: "Reverse geocode failure",
    ERR_COD_AMOUNT_EXCEED: "COD price cannot exceed 50,000 baht",
    ERR_NOT_SUPPORT_COD: "Your account is not enable COD in Shippop",
    NOT_SUPPORT_COD: "COD is available with selected Courier",
    ERR_OUT_OF_AREA: "Out of service area",
    DAY_OFF: "Spacial holiday, does not allow to create order",
    ERR_POSTCODE: "Invalid postcode",
    ERR_LAT_LNG: "Cannot find latitude และ longitude",
    INVALID_WEIGHT: "Weight over limit",
    INVALID_SIZE: "Size over limit",
    SHIPPOP_ESTIMATED_SHIPPING_PRICE_ORDER_NOT_FOUND: "Order is not found from Shippop",
    SHIPPOP_ESTIMATED_SHIPPING_PRICE_ORDER_IS_NOT_SHIPPOP: "Order is not Shippop",
    SHIPPOP_ESTIMATED_SHIPPING_PRICE_NOT_SUPPORTED_CARRIER_NAME: "This carrier is not supported",
    SHIPPOP_ESTIMATED_SHIPPING_PRICE_FAIL_TO_QUOTE_SHIPPOP_SHIPPING_PRICE: "Cannot fetch data from Shippop",
    ESTIMATED_SHIPPING_PRICE: "Estimated shipping price",
    INVALID_SHIPPOP_STORE_SETTING: "Shippop setting invalid",
    SHIPPOP_ACCOUNT_NOT_FOUND:
      "Your Shippop account’s not found, Please register at this link or contact our support team for any further supports",
    NO_DELIVERY_SETTING: "Please enable the delivery period",
    NO_STORE_ADDRESS: "Please set Address in store setting",
    NO_REQUIRED_FIELDS: "Cannot Updated Shipping Method",
    CONFIRM_CANCEL_SHIPPOP_ORDER: "Are you sure you want to cancel order number {{orderNo}}?",
  },
  th: {
    "Shipping method": "การจัดส่ง",
    to: "ถึง",
    STANDARD: "ธรรมดา",
    EXPRESS: "ด่วน",
    "PICK UP": "รับสินค้าด้วยตัวเอง",
    PICK_UP: "รับสินค้าด้วยตัวเอง",
    PICKUP: "รับสินค้าด้วยตัวเอง",
    "Estimated delivery": "ระยะเวลาการจัดส่ง",
    "Rate amount": "ค่าจัดส่ง",
    days: "วัน",
    "Shipping rule": "วิธีการจัดส่งแบบพิเศษ",
    CARRIER_NAME: "ชื่อผู้ให้บริการจัดส่ง",
    Carrier: "ชื่อผู้ให้บริการจัดส่ง",
    "Other carrier name": "ชื่อผู้ให้บริการอื่นๆ",
    "Default shipping": "วิธีการจัดส่ง",
    "Custom shipping": "Custom shipping",
    "Default shipping method": "วิธีการจัดส่งสินค้า",
    CJ: "ซีเจ โลจิสติคส์",
    DHL: "ดีเอชแอล",
    "Flash express": "แฟลชเอ็กซ์แพรส",
    "Inter express": "อินเตอร์เอ็กซ์แพรส",
    "J&T": "เจแอนด์ที",
    Kerry: "เคอรี่",
    "NIM express": "นิมเอ็กซ์แพรส",
    "NIM express chilled": "นิมเอ็กซ์แพรส สินค้าแช่เย็น",
    NINJAVAN: "นินจาแวน",
    "Porlor express": "ปอลอ เอ็กซ์เพรส",
    "SCG yamato express": "เอสซีจี ยามาโตะ",
    "Thaipost-EMS": "ไปรษณีย์ไทย - ส่งด่วนพิเศษ",
    "Thaipost-Register": "ไปรษณีย์ไทย - ส่งลงทะเบียน",
    TNT: "ทีเอ็นที",
    SENDIT: "Sendit",
    X_PRESSO: "Xpresso",
    ZTO: "ZTO express",
    "TP Logistics": "TP Logistics",
    "Flat rate": "ราคาค่าจัดส่งคงที่",
    "The shipping price is the shipping price for the whole cart": "ราคาค่าส่งนี้เป็นราคาค่าส่งของทั้งตะกร้า",
    "Please specify carrier name": "กรุณาระบุชื่อผู้ให้บริการจัดส่ง",
    "Shipping Method Updated": "วิธีการจัดส่งถูกบันทึกแล้ว",
    "Price per unit": "ราคาค่าส่งต่อหน่วย",
    "Next item rate": "ราคาค่าส่งชิ้นถัดไป",
    "shipping.deliveryPeriod.title": "เวลาทำการจัดส่ง",
    "shipping.deliveryPeriod.deliveryFrom": "ส่งของตั้งแต่",
    "shipping.deliveryPeriod.preparingTime": "เวลาสำหรับเตรียมสินค้า (นาที)",
    "shipping.deliveryPeriod.preparingTimePrefix": "เวลาสำหรับเตรียมสินค้า",
    "shipping.deliveryPeriod.minutes": "นาที",
    "shipping.deliveryPeriod.note":
      "หมายเหตุ* ลูกค้าสามารถเลือกเวลาจัดส่งได้ตั้งแต่  (เวลาปัจจุบัน + เวลาเตรียมสินค้า)",
    "shipping.deliveryPeriod.button.goToBranch": "ไปหน้าสาขา",
    "shipping.deliveryPeriod.goToBranchDescription": "โปรดกดปุ่มเพื่อไปตั้งค่าสาขา สำหรับการใช้งานกับ Lalamove",
    "shipping.label.calculateType.DISTANCE_BASE_PRICE": "แอดมินคำนวณราคาค่าส่งด้วยตัวเอง",
    "shipping.label.calculateType.FIXED_PRICE": "ราคาค่าจัดส่งคงที่",
    "shipping.label.calculateType.LALAMOVE": "เชื่อมต่อกับ LALAMOVE",
    "shipping.label.calculateType.SHIPPOP": "เชื่อมต่อกับ SHIPPOP",
    "shipping.label.calculateType.PICK_UP": "รับสินค้าหน้าร้าน",
    "shipping.label.calculateType.POSTAL_CODE_BASED_PRICE": "ราคาค่าจัดส่งตามรหัสไปรษณีย์",
    "shipping.label.shippingInfographic": "รูปภาพประกอบการจัดส่ง",
    "shipping.label.infoGraphic": "รูปภาพแสดงข้อมูลการจัดส่ง",
    "shipping.description.infoGraphic": "อัปโหลดรูปภาพเพื่อแสดงข้อมูลการจัดส่ง",
    "shipping.description.calculateType.DISTANCE_BASE_PRICE":
      "วิธีนี้ให้แอดมินสามารถใส่ค่าจัดส่งที่แตกต่างกันในแต่ละออเดอร์ได้",
    "shipping.description.calculateType.FIXED_PRICE": "วิธีนี้ค่าจัดส่งคงที่ทุกการสั่งซื้อ",
    "shipping.description.calculateType.LALAMOVE": "วิธีนี้ค่าจัดส่งจะคิดตามราคาจากระบบ LALAMOVE",
    "shipping.description.calculateType.SHIPPOP": "วิธีนี้ค่าจัดส่งจะคำนวนตามน้ำหนัก และขนาดของพัสดุ",
    "shipping.description.calculateType.PICK_UP": "วิธีนี้ให้ลูกค้ามารับสินค้าที่ร้าน",
    "shipping.description.calculateType.POSTAL_CODE_BASED_PRICE":
      "วิธีนี้คำนวนค่าจัดส่งตาม เขต/อำเภอ หรือ รหัสไปรษณีย์",
    "shipping.distance.base.remark": "แอดมินจะต้องกรอกราคาค่าส่งให้กับออเดอร์ของลูกค้าด้วยตนเอง",
    "Shipping calculate type": "วิธีการคิดค่าจัดส่ง",
    "shipping.label.pickUpAtShop": "รับสินค้าด้วยตัวเอง",
    "Please set Address in store setting": "กรุณากรอกที่อยู่ของร้านค้า",
    "Click here to go to store setting": "กดที่นี่เพื่อไปตั้งค่า",
    "Please enable the delivery period": "กรุณาเปิดการใช้งาน เวลาทำการจัดส่ง",
    "Click here to go to delivery setting": "กดที่นี่เพื่อไปตั้งค่า",
    "Manage available stores": "จัดการสาขาที่ให้บริการ",
    "Recipient first name": "ชื่อผู้รับ",
    "Recipient last name": "นามสกุลผู้รับ",
    Select: "เลือก",
    Districts: "อำเภอ",
    "shipping.shippingBoundary": "ขอบเขตการส่ง",
    "shipping.areasDisabledShipping": "จังหวัดที่ไม่จัดส่ง",
    "shipping.excludedProvince": "จังหวัดที่ไม่จัดส่ง",
    "shipping.locationSetting": "การตั้งค่าพื้นที่การจัดส่ง",
    "shipping.setAFlatRateFor": "ตั้งราคาค่าส่งคงที่สำหรับ",
    "shipping.addMoreRates": " + เพิ่มพื้นที่ ที่ต้องการตั้งราคาค่าส่งเฉพาะ",
    "shipping.ChooseDistricts": "เลือก เขต/อำเภอ ที่ต้องการตั้งราคาค่าส่งเฉพาะ",
    "shipping.Delete": "ลบ",
    "shipping.Save": "บันทึก",
    SelectProvinces: "เลือกจังหวัด",
    Provinces: "จังหวัด",
    "Are you sure to delete this": "คุณแน่ใจที่จะลบหรือไม่",
    "shipping.label.shippingBoundary": "ขอบเขตการจัดส่ง",
    "shipping.label.defaultShippingRate": "ราคาค่าจัดส่งคงที่พื้นฐาน",
    "shipping.label.locationSetting": "การตั้งค่าพื้นที่การจัดส่ง",
    "shipping.table.header.province": "จังหวัด",
    "shipping.table.header.postalCode": "เขต/อำเภอ - รหัสไปรษณีย์",
    "shipping.table.header.shippingRates": "ค่าจัดส่ง",
    "shipping.table.columnValue.defaultRate": "฿ {{price}} (ค่าจัดส่งพื้นฐาน)",
    "shipping.value.shippingBoundary": "{{count}} จังหวัด",
    PostalCode: "รหัสไปรษณีย์",
    "Please select postal code": "กรุณาเลือกรหัสไปรษณีย์",
    "At least 1 province need to be include in shipping boundary":
      "ต้องมีจังหวัดอย่างน้อย 1 จังหวัดอยู่ในขอบเขตการจัดส่ง",
    "Provinces enabled": "จังหวัดที่จัดส่ง",
    "shipping.label.isReceiveOrderOutsideDeliveryTime": "รับออร์เดอร์นอกเวลาทำการของร้าน",
    "shipping.label.isDisabledSchedulePickUp": "รับสินค้าโดยไม่ระบุเวลา",
    "shippingPriceDetail.label.shippingCondition": "ดูเงื่อนไข",
    "shippingPriceDetail.label.seeFullInfo": "ดูเนื้อหาทั้งหมด:",
    "shipping.shippop.advancedSetting": "การตั้งค่าขั้นสูง",
    "shippop.advanceSetting.shippopBased.title": "ราคาค่าจัดส่งตาม Shippop",
    "shippop.advanceSetting.shippopBased.description": "คำนวนราคาค่าส่งโดย น้ำหนัก และขนาดของ พัสดุ",
    "shippop.advanceSetting.postCodeBased.title": "ราคาค่าจัดส่งตามรหัสไปรษณีย์",
    "shippop.advanceSetting.postCodeBased.description": "คำนวนราคาค่าส่งโดย สถานที่ที่ถูกจัดส่งตามรหัสไปรษณีย์",
    "shippop.advanceSetting.flatRate.title": "ราคาค่าจัดส่งคงที่",
    "shippop.advanceSetting.flatRate.description": "คำนวนราคาค่าส่งโดย ราคาค่าส่งคงที่",
    Branch: "สาขา",
    "shipping.badgeOnlyCOD": "เฉพาะเก็บเงินปลายทาง",
    "shipping.isAllowedOnlyCOD": "เฉพาะเก็บเงินปลายทาง",
    "shipping.shippop.optionlabel.addressFilling": "กรอกที่อยู่เอง",
    "shipping.shippop.formLabel.addressFilling": "เลือกการเติมที่อยู่",
    "shipping.shippop.label.fillingFromBranch": "ใช้ข้อมูลจากสาขา",
    "shipping.shippop.storeManagementFee.title": "ค่าธรรมเนียมของร้านค้า",
    "shipping.shippop.storeManagementFee.label": "ค่าธรรมเนียม",
    "shipping.shippop.storeManagementFee.remark": "ค่าธรรมเนียมของร้านค้า คือจำนวนเงินที่คิดเพิ่มเติมจากราคาค่าจัดส่ง",
    "shipping.shippop.remark":
      "ราคาค่าจัดส่งของ Shippop อาจแตกต่างกันไปโดยขี้นอยู่กับ ความถูกต้องของที่อยู่, น้ำหนักของสินค้า, และขนาดของสินค้า",
    "shipping.shippop.integration.remark":
      "กรุณาติดต่อฝ่ายบริการลูกค้า สำหรับข้อมูลเพิ่มเติมเกี่ยวกับการเชื่อมต่อกับ SHIPPOP",
    "shipping.shippop.api.key.invalid":
      "API key ของคุณไม่ถูกต้อง กรุณาเช็คอีกครั้งหรือติดต่อ Shippop เพื่อขอ API Key อีกครั้ง",
    "shipping.shippop.button.addSenderAddress": " + เพิ่มที่อยู่ผู้ส่งใหม่",
    "shipping.shippop.address.label.email": "อีเมล: ",
    "shipping.shippop.address.label.name": "ชื่อ: ",
    "shipping.shippop.address.label.phone": "หมายเลขโทรศัพท์: ",
    "shipping.shippop.address.title": "ที่อยู่ผู้ส่ง",
    "shipping.shippop.addressModal.placeholder.addressFilling": "เลือกวิธีการใส่ข้อมูลที่อยู่",
    "shipping.shippop.addressModal.select.label": "เลือกการเติมที่อยู่",
    "shipping.shippop.label.fillInYourAddress": "กรอกข้อมูลที่อยู่เอง",
    "shipping.shippop.credential.title": "เชื่อมต่อกับบัญชี Shippop ของคุณ",
    "shipping.shippop.email.title": "อีเมล์ผู้ส่ง",
    "shipping.shippop.credential.email.remark":
      "หากคุณไม่ได้ระบุอีเมล์ เราจะใช้อีเมล์จากข้อมูลผู้ส่งของคุณในการเชื่อมต่อกับ Shippop",
    "shipping.shippop.apikey.title": "API key ของผู้ขาย",
    "shipping.shippop.apikey.remark":
      "ลูกค้าใหม่สามารถสมัครใช้งาน Shippop ผ่าน 1Convo ได้ <0>ที่นี่</0>, ส่วนลูกค้าเก่าของ Shippop สามารถติดต่อเซลของ Shippop เพื่อขอ API token และ email เพื่อใช้งาน Shippop account ปัจจุบันได้เลย",
    "shipping.shippop.email.remark":
      "อีเมล์ที่แก้ใหม่จะแทนที่ '{{email}}' เนื่องจากสามารถมีเพียงอีเมล์เดียวที่เชื่อมต่อกับShippop",
    "shipping.shippop.confirmModal.content": "คุณต้องการบันทึกการแก้ไขข้อมูลผู้ส่งหรือไม่ ?",
    "shipping.shippop.address.placeholder.required": "กรุณาเพิ่มที่อยู่",
    "shipping.shippop.email.placeholder.required": "กรุณาเพิ่มอีเมล์",
    "shipping.shippop.senderInformation.title": "ข้อมูลผู้ส่ง",
    "shipping.shippop.buttonText.editInfo": "แก้ไขข้อมูล",
    "shipping.shippop.buttonText.doneEdit": "ยืนยันการแก้",
    "shipping.shippop.error.senderInformationRequired": "กรุณาเพิ่มข้อมูลผู้ส่งสำหรับ shippop",
    "shipping.lalamove.remark": "กรุณาติดต่อฝ่ายบริการลูกค้า สำหรับข้อมูลเพิ่มเติมเกี่ยวกับการเชื่อมต่อกับ LALAMOVE",
    "shipping.lalamove.price.rate": "ดูอัตราค่าจัดส่ง",
    RECEIPT: "ใบเสร็จรับเงิน (ค่าเริ่มต้น)",
    LETTER: "จดหมาย",
    LETTER4x6: "จดหมาย 4x6",
    STICKER4x6: "สติกเกอร์ 4x6",
    SIZE: "ขนาด",
    "shippop.modal.label.carrier": "{{count}} ผู้ให้บริการจัดส่ง",
    "shippop.modal.label.senderInformation": "ข้อมูลผู้ส่ง",
    SERVICE_MAINTENANCE: "ปิดปรับปรุงระบบ",
    ERR_MAINTENANCE: "ปิดปรับปรุงระบบ",
    ERR_ORIGIN: "ต้นทาง อยู่นอกพื้นที่ให้บริการ",
    ERR_DEST: "ปลายทาง อยู่นอกพื้นที่ให้บริการ",
    ERR_REALTIME_CHECKPRICE: "ขนส่งไม่สามารถคำนวณระยะทางได้",
    ERR_REVERSE_GEOCODE_FAILURE: "ไม่สามารถเปลี่ยนตำแหน่งที่อยู่ได้",
    ERR_COD_AMOUNT_EXCEED: "เรียกเก็บเงินปลายทางได้สูงสุด 50,000 บาท",
    ERR_NOT_SUPPORT_COD: "บัญชีของคุณยังไม่เปิดให้บริการเก็บเงินปลายทาง (COD)",
    NOT_SUPPORT_COD: "ขนส่งดังกล่าวยังไม่เปิดบริการเก็บเงินปลายทาง (COD)",
    ERR_OUT_OF_AREA: "อยู่นอกพื้นที่ให้บริการ",
    DAY_OFF: "วันหยุดทำการพิเศษ ไม่อนุญาตให้สร้างรายการได้",
    ERR_POSTCODE: "รหัสไปรษณีย์ไม่ถูกต้อง",
    ERR_LAT_LNG: "ไม่พบข้อมูล latitude และ longitude",
    INVALID_WEIGHT: "น้ำหนักเกินกำหนด",
    INVALID_SIZE: "ขนาดเกินกำหนด",
    SHIPPOP_ESTIMATED_SHIPPING_PRICE_ORDER_NOT_FOUND: "ไม่พบออร์เดอร์นี้จาก Shippop",
    SHIPPOP_ESTIMATED_SHIPPING_PRICE_ORDER_IS_NOT_SHIPPOP: "ออร์เดอร์ไม่ใช่ของ Shippop",
    SHIPPOP_ESTIMATED_SHIPPING_PRICE_NOT_SUPPORTED_CARRIER_NAME: "ผู้ให้บริการจัดส่งไม่พร้อมให้บริการ",
    SHIPPOP_ESTIMATED_SHIPPING_PRICE_FAIL_TO_QUOTE_SHIPPOP_SHIPPING_PRICE: "ไม่สามารถดึงข้อมูลจาก Shippop ได้",
    ESTIMATED_SHIPPING_PRICE: "ราคาจัดส่งโดยประมาณ",
    INVALID_SHIPPOP_STORE_SETTING: "ข้อมูลของ Shippop ไม่ถูกต้อง",
    SHIPPOP_ACCOUNT_NOT_FOUND: "ไม่พบบัญชีชิปป๊อปของคุณ กรุณาลงทะเบียนที่นี่ หรือติดต่อทีมงานเพื่อขอความช่วยเหลือ",
    NO_DELIVERY_SETTING: "กรุณาเปิดการใช้งาน เวลาทำการจัดส่ง",
    NO_STORE_ADDRESS: "กรุณากรอกที่อยู่ของร้านค้า",
    NO_REQUIRED_FIELDS: "กรุณาลองใหม่อีกครั้ง",
    CONFIRM_CANCEL_SHIPPOP_ORDER: "คุณต้องการยกเลิกออเดอร์ของ Shippop รายการสั่งซื้อที่ {{orderNo}} หรือไม่?",
  },
};

export default shipping;
