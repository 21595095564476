import * as yup from "yup";
import {
  IMAGE_TYPE_WHITE_LISTS_REGEX,
  IMAGE_TYPE_WHITE_LISTS_REGEX_BASE64,
  ERROR_MESSAGE_UPLOAD_IMAGE,
} from "constants/UploadImage";
import { PHONE_NUMBER_REGEX, POSTCODE_REGEX } from "constants/Address";
import ERROR_MESSAGE from "./errorMessage";

const schema = yup.object().shape({
  image: yup
    .string()
    .required(ERROR_MESSAGE.IMAGE_ERROR)
    .test(ERROR_MESSAGE.INVALID_VALUE, ERROR_MESSAGE_UPLOAD_IMAGE, (imageUrl: string) => {
      return IMAGE_TYPE_WHITE_LISTS_REGEX.test(imageUrl) || IMAGE_TYPE_WHITE_LISTS_REGEX_BASE64.test(imageUrl);
    }),
  name: yup.string().trim().required(ERROR_MESSAGE.NAME_ERROR),
  address: yup.string().trim(),
  subDistrict: yup.string().trim(),
  district: yup.string().trim(),
  city: yup.string().trim(),
  postcode: yup.string().matches(POSTCODE_REGEX, ERROR_MESSAGE.POSTCODE_ERROR),
  phoneNumber: yup.string().matches(PHONE_NUMBER_REGEX, ERROR_MESSAGE.PHONE_NUMBER_ERROR),
  facebookLink: yup.string().trim(),
  instagramLink: yup.string().trim(),
  twitterLink: yup.string().trim(),
  websiteLink: yup.string().trim(),
});

export default schema;
