import { useQuery } from "@apollo/client";
import { FACEBOOK_PAGE } from "graphql/facebookCrawler/query";

export const useFetchFacebookPage = (projectId: string) => {
  const { data: facebookPageData, loading: isFacebookPageDataLoading } = useQuery(FACEBOOK_PAGE, {
    skip: !projectId,
    variables: {
      projectId,
    },
    fetchPolicy: "network-only",
  });

  return { facebookPageData, isFacebookPageDataLoading };
};
