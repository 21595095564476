import React, { FC, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment, { Moment } from "moment";
import styled, { css } from "styled-components";
import { MuiPickersUtilsProvider, DatePicker as MUIDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { SvgIcon } from "components/Icon";
import { IcCalendar } from "components/SvgIcons";
import { DATE_TIME_FORMAT } from "constants/DateTimeFormat";
import COLORS from "constants/Colors";

import replaceStyle from "./replaceStyle";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomDatePicker: any = styled(MUIDatePicker)`
  border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  padding: 12px 0 12px 16px;
  border-radius: 8px;
  height: 44px;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "initial")};
  box-sizing: border-box;
  input.Mui-focused,
  div.MuiInput-underline:hover:before,
  div.MuiInput-underline:before,
  div.MuiInput-underline:after {
    border-bottom: none;
  }
  background-color: ${({ theme, disabled }) => (disabled ? theme.COLORS.Light : theme.COLORS.White)};

  &:active,
  &:focus-within {
    border-color: ${({ theme, disabled }) => (disabled ? theme.COLORS.LightBlue : theme.COLORS.Primary)};
  }

  .MuiInput-root {
    height: 20px;
  }

  .MuiInputBase-input {
    color: ${({ theme, disabled }) => (disabled ? theme.COLORS.LightBlue : theme.COLORS.DarkGray)};
    font: normal normal normal 14px/20px "Roboto", "Helvetica", "Arial", sans-serif;
  }

  .MuiSvgIcon-root {
    font-size: 16px;
    color: ${({ theme, disabled }) => (disabled ? theme.COLORS.LightBlue : theme.COLORS.Primary)};
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const IconWrapper = styled.div<{ isDisabled?: boolean }>`
  position: absolute;
  top: 14px;
  right: 12px;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      display: none;
    `}
`;

type DatePickerPropsType = {
  selectedDate: string | undefined;
  setDate: (date: string) => void;
  format?: string;
  fullWidth?: boolean;
  minDate?: Date | string | undefined;
  disabled?: boolean;
  disablePast?: boolean;
};

const DatePicker: FC<DatePickerPropsType> = ({
  fullWidth,
  selectedDate,
  setDate,
  format = DATE_TIME_FORMAT,
  minDate,
  disabled,
  disablePast,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeDate = (selectDate: any) => {
    setDate(moment(selectDate as Moment).toISOString());
    setIsOpen(false);
  };

  moment.locale(i18n.language);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      {/* eslint-disable-next-line react/no-danger */}
      <style dangerouslySetInnerHTML={{ __html: replaceStyle }} />
      <Wrapper
        onClick={() => {
          if (disabled) return;
          if (!isOpen) {
            setIsOpen(!isOpen);
          }
        }}
      >
        <CustomDatePicker
          disablePast={disablePast}
          disableToolbar
          fullWidth={fullWidth}
          variant="inline"
          format={format}
          value={moment(selectedDate)}
          onChange={handleChangeDate}
          autoOk
          minDate={minDate}
          disabled={disabled}
          open={isOpen}
          allowKeyboardControl={false}
          onClose={() => {
            setIsOpen(false);
          }}
        />
        <IconWrapper isDisabled={disabled}>
          <SvgIcon className="m-0" component={IcCalendar} fontSize="small" htmlColor={COLORS.DarkMed} />
        </IconWrapper>
      </Wrapper>
    </MuiPickersUtilsProvider>
  );
};

export default memo(DatePicker);
