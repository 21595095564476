import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { FixWidthTooltip } from "components/Tooltip";
import COLORS from "constants/Colors";
import { getCreditCardExpiry } from "utils/creditCard";

import { SvgIcon } from "../Icon";
import { IcInfo } from "../SvgIcons";
import TextField from "./TextField.styled";
import InputAdornment from "./InputAdornment.styled";
import { TextFieldPropsType } from "./index";

const CreditCardExpiredDateField: FC<TextFieldPropsType> = (props) => {
  const { t } = useTranslation();
  const { onChange } = props;
  return (
    <TextField
      {...props}
      type="text"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const formatValue = getCreditCardExpiry(e.target.value || "");
        const event = Object.assign(e, { target: { value: formatValue } });

        if (typeof onChange === "function") {
          onChange(event);
        }
      }}
      InputProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        endAdornment: (
          <InputAdornment position="end">
            <FixWidthTooltip
              title={t("billing.addCreditCardForm.validDate.info") as string}
              placement="bottom-end"
              arrow
            >
              <div>
                <SvgIcon
                  component={IcInfo}
                  fontSize="small"
                  htmlColor={COLORS.DarkLight}
                  viewBox="0 4 24 24"
                  className="p-0 mr-0"
                />
              </div>
            </FixWidthTooltip>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CreditCardExpiredDateField;
