export const NEW_BANK_ID_PREFIX = "newBank";
export const BANK_ID_PREFIX = "bank";
export const COD_PREFIX = "cashOnDelivery";
export const PROMPT_PAY_PREFIX = "promptPay";
export const PAYMENT_GATEWAY_PREFIX = "paymentGateway";
export const SECOND_PAYMENT_GATEWAY_PREFIX = "paymentGatewaySecond";
export const KBANK_GATWAY_PREFIX = "kbankGateway";

export const PROMPT_PAY_ID_KEY = `${PROMPT_PAY_PREFIX}Id`;
export const PROMPT_PAY_IS_ACTIVE_KEY = `${PROMPT_PAY_PREFIX}IsActive`;
export const PROMPT_PAY_ACCOUNT_NAME_KEY = `${PROMPT_PAY_PREFIX}AccountName`;
export const PROMPT_PAY_ACCOUNT_NUMBER_KEY = `${PROMPT_PAY_PREFIX}AccountNumber`;
export const PROMPT_PAY_CUSTOM_QR_IMAGE = `${PROMPT_PAY_PREFIX}CustomQRImage`;
export const PROMPT_PAY_TYPE_KEY = `${PROMPT_PAY_PREFIX}PromptpayType`;

export const COD_ID_KEY = `${COD_PREFIX}Id`;
export const COD_IS_ACTIVE_KEY = `${COD_PREFIX}IsActive`;
export const COD_SHIPPING_METHOD_KEY = `${COD_PREFIX}ShippingMethod`;
export const COD_THRESHOLD_KEY = `${COD_PREFIX}Threshold`;
export const COD_DEPOSIT_TYPE_KEY = `${COD_PREFIX}DepositType`;
export const COD_DEPOSIT_AMOUNT_KEY = `${COD_PREFIX}DepositAmount`;
export const COD_MANAGEMENT_FEE_TYPE_KEY = `${COD_PREFIX}ManagementFeeType`;
export const COD_MANAGEMENT_FEE_KEY = `${COD_PREFIX}ManagementFee`;

export const PAYMENT_GATEWAY_ID_KEY = `${PAYMENT_GATEWAY_PREFIX}Id`;
export const PAYMENT_GATEWAY_IS_ACTIVE_KEY = `${PAYMENT_GATEWAY_PREFIX}IsActive`;
export const PAYMENT_GATEWAY_MERCHANT_ID = `${PAYMENT_GATEWAY_PREFIX}MerchantId`;
export const PAYMENT_GATEWAY_API_KEY = `${PAYMENT_GATEWAY_PREFIX}ApiKey`;
export const PAYMENT_GATEWAY_MD5_SECRET_KEY = `${PAYMENT_GATEWAY_PREFIX}MD5SecretKey`;
export const PAYMENT_GATEWAY_TYPE_KEY = `${PAYMENT_GATEWAY_PREFIX}`;
export const PAYMENT_GATEWAY_DESCRIPTION_KEY = `${PAYMENT_GATEWAY_PREFIX}Description`;

export const PAYMENT_GATEWAY_CUSTOM_HEADER = `${PAYMENT_GATEWAY_PREFIX}Header`;
export const PAYMENT_GATEWAY_CUSTOM_BODY = `${PAYMENT_GATEWAY_PREFIX}Body`;
export const PAYMENT_GATEWAY_CUSTOM_ICON_URL = `${PAYMENT_GATEWAY_PREFIX}IconUrl`;
export const PAYMENT_GATEWAY_CUSTOM_URL = `${PAYMENT_GATEWAY_PREFIX}Url`;

export const SECOND_PAYMENT_GATEWAY_ID_KEY = `${SECOND_PAYMENT_GATEWAY_PREFIX}Id`;
export const SECOND_PAYMENT_GATEWAY_IS_ACTIVE_KEY = `${SECOND_PAYMENT_GATEWAY_PREFIX}IsActive`;

export enum PAYMENT_TYPES {
  BANK_ACCOUNT = "BANK_ACCOUNT",
  PROMPT_PAY = "PROMPT_PAY",
  CASH_ON_DELIVERY = "CASH_ON_DELIVERY",
  PAYMENT_GATEWAY_CHILL_PAY = "PAYMENT_GATEWAY_CHILL_PAY",
  PAYMENT_GATEWAY_2C2P = "PAYMENT_GATEWAY_2C2P",
  PAYMENT_GATEWAY_CUSTOM = "PAYMENT_GATEWAY_CUSTOM",
}

export enum COD_DEPOSIT_TYPES {
  NONE = "NONE",
  PERCENTAGE = "PERCENTAGE",
  FIXED_PRICE = "FIXED_PRICE",
}

export enum COD_MANAGEMENT_FEE_TYPES {
  NONE = "NONE",
  PERCENTAGE = "PERCENTAGE",
  FIXED_PRICE = "FIXED_PRICE",
}

export enum BANK_ACCOUNT_TYPES {
  SAVING = "SAVING",
  CURRENT = "CURRENT",
}

export const BANK_ACCOUNT_TYPE_LIST = Object.values(BANK_ACCOUNT_TYPES);
export const COD_DEPOSIT_TYPE_LIST = Object.values(COD_DEPOSIT_TYPES);
export const COD_MANAGEMENT_FEE_TYPES_LIST = Object.values(COD_MANAGEMENT_FEE_TYPES);

export enum PAYMENT_METHOD_TYPES {
  BANK_TRANSFER = "BANK_TRANSFER",
  PROMPT_PAY = "PROMPT_PAY",
  CASH_ON_DELIVERY = "CASH_ON_DELIVERY",
  DEEPLE_PAY = "DEEPLE_PAY",
  OTHER = "OTHER",
}

export const CONVERTED_PAYMENT_METHOD_TYPES = {
  BANK_TRANSFER: "Bank transfer",
  PROMPT_PAY: "PromptPay",
  CASH_ON_DELIVERY: "Cash on delivery",
  DEEPLE_PAY: "deeple pay",
  OTHER: "other",
};

export const COD_SHIPPING_METHOD = {
  STANDARD: "STANDARD",
  EXPRESS: "EXPRESS",
  ALL: "ALL",
  PICK_UP: "PICK UP",
};

export const PAYMENT_GATEWAY_TYPE_LIST = [
  PAYMENT_TYPES.PAYMENT_GATEWAY_CHILL_PAY,
  PAYMENT_TYPES.PAYMENT_GATEWAY_2C2P,
  PAYMENT_TYPES.PAYMENT_GATEWAY_CUSTOM,
];

export const CHILLPAY_WEBHOOK_PATH = "/payment/chill-pay/";
export const CHILLPAY_WEBVIEW_PATH = "/liff/payment-success";
