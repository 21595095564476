export enum GUIDELINE_CHECK {
  HAS_CATEGORIES = "hasCategories",
  HAS_CONNECT = "hasConnect",
  HAS_PAYMENT_METHOD = "hasPaymentMethod",
  HAS_PRODUCT = "hasProducts",
  HAS_SHIPPING_METHOD = "hasShippingMethod",
  HAS_STORE_SETTING = "hasStoreSettings",
  HAS_FAQ = "hasFaqs",
}

export type GuidelineType = {
  hasStoreSettings?: boolean;
  hasCategories?: boolean;
  hasProducts?: boolean;
  hasPaymentMethod?: boolean;
  hasShippingMethod?: boolean;
  hasConnect?: boolean;
  hasFaqs?: boolean;
};

export type GuidelineQueryType = {
  guideline: GuidelineType;
};
