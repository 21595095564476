import getEdit from "pages/ResponseMessage/Table/Column/Edit";
import getMessageUsed from "pages/ResponseMessage/Table/Column/MessageUsed";
import getMessage from "pages/ResponseMessage/Table/Column/Message";
import getResponseName from "pages/ResponseMessage/Table/Column/ResponseName";
import getTags from "pages/ResponseMessage/Table/Column/Tags";

export const getColumns = (t: Function) => {
  const Edit = getEdit();
  const MessageUsed = getMessageUsed(t);
  const ResponseName = getResponseName(t);
  const Tags = getTags(t);
  return [ResponseName, MessageUsed, Tags, Edit].filter(Boolean);
};

export const getSearchResultColumns = (t: Function) => {
  const Edit = getEdit();
  const Message = getMessage(t);
  const ResponseName = getResponseName(t);
  const Tags = getTags(t);
  return [ResponseName, Message, Tags, Edit].filter(Boolean);
};
