import { useQuery, useMutation } from "@apollo/client";
import produce from "immer";

import { MAX_REPLY_SHORTCUT } from "config/replyShortcut";
import { ReplyShortcut, ReplyShortcutQuery } from "types/ReplyShortcut";
import { ADD_CHAT_TEMPLATE, DELETE_CHAT_TEMPLATE, UPDATE_CHAT_TEMPLATE } from "graphql/chatTemplate/mutation";
import { CHAT_TEMPLATES } from "graphql/chatTemplate/query";

const useChatTemplates = (projectId: string, page: number, searchText: string) => {
  const { data, loading, fetchMore } = useQuery<ReplyShortcutQuery>(CHAT_TEMPLATES, {
    variables: { projectId, offset: page, limit: MAX_REPLY_SHORTCUT, filter: { keyword: searchText } },
  });

  const [updateChatTemplate] = useMutation(UPDATE_CHAT_TEMPLATE);
  const [addChatTemplate] = useMutation(ADD_CHAT_TEMPLATE, {
    update(cache, { data: { addChatTemplate: addedChatTemplate } }) {
      const result = cache.readQuery<ReplyShortcutQuery>({
        query: CHAT_TEMPLATES,
        variables: { projectId, offset: page, limit: MAX_REPLY_SHORTCUT, filter: { keyword: searchText } },
      });

      if (result) {
        const newResult = produce(result, (draft) => {
          draft.chatTemplates.results.unshift({
            id: addedChatTemplate.id,
            createdAt: addedChatTemplate.createdAt,
            updatedAt: addedChatTemplate.updatedAt,
            description: addedChatTemplate.description,
            name: addedChatTemplate.name,
            shortcutKey: addedChatTemplate.shortcutKey,
          });
          // eslint-disable-next-line operator-assignment
          draft.chatTemplates.total = draft.chatTemplates.total + 1;
        });

        cache.writeQuery<ReplyShortcutQuery>({
          query: CHAT_TEMPLATES,
          variables: { projectId, offset: page, limit: MAX_REPLY_SHORTCUT, filter: { keyword: searchText } },
          data: newResult,
        });
      }
    },
  });
  const [deleteChatTemplate] = useMutation(DELETE_CHAT_TEMPLATE, {
    update(cache, { data: { deleteChatTemplate: deletedChatTemplate } }) {
      const result = cache.readQuery<{ chatTemplates: { results: ReplyShortcut[]; total: number } }>({
        query: CHAT_TEMPLATES,
        variables: { projectId, offset: page, limit: MAX_REPLY_SHORTCUT, filter: { keyword: searchText } },
      });
      if (result) {
        const updatedCacheChatTemplateResults = result.chatTemplates.results.filter(
          (chatTemplate: ReplyShortcut) => chatTemplate.id !== deletedChatTemplate.id,
        );

        cache.writeQuery({
          query: CHAT_TEMPLATES,
          variables: { projectId, offset: page, limit: MAX_REPLY_SHORTCUT, filter: { keyword: searchText } },
          data: {
            chatTemplates: {
              ...result,
              results: updatedCacheChatTemplateResults,
              total: result.chatTemplates.total - 1,
            },
          },
        });
      }
    },
  });

  const chatTemplates = data?.chatTemplates?.results || [];
  const total = data?.chatTemplates?.total || 0;

  return {
    addChatTemplate,
    chatTemplates,
    deleteChatTemplate,
    fetchMore,
    isChatTemplatesLoading: loading,
    total,
    updateChatTemplate,
  };
};

export default useChatTemplates;
