import * as React from "react";

const IcNotification = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <path
          d="M1195.116 32.631c-.23 1.13-1.232 1.983-2.429 1.983-1.197 0-2.198-.853-2.428-1.983zm-2.457-18.017c.408 0 .74.326.75.732v.64c.053.006.105.013.157.022 2.975.428 5.272 2.988 5.272 6.08v2.45c0 1.738.761 3.38 2.09 4.502.35.3.545.725.545 1.175 0 .848-.69 1.538-1.537 1.538h-14.498c-.847 0-1.537-.69-1.537-1.538 0-.45.196-.875.538-1.168 1.336-1.13 2.098-2.77 2.098-4.51v-2.45c0-3.091 2.296-5.65 5.272-6.08l.099-.013v-.648c.011-.406.342-.732.75-.732zm6.607 1.455c1.509 1.238 2.327 3.36 2.244 5.82-.013.4-.342.717-.74.717h-.026c-.409-.014-.73-.357-.716-.767.067-1.995-.553-3.68-1.703-4.624-.316-.26-.362-.727-.103-1.043.26-.317.727-.363 1.044-.103zm-12.086.103c.26.316.214.783-.103 1.043-1.15.943-1.77 2.629-1.703 4.624.014.41-.307.753-.716.766h-.025c-.398 0-.727-.315-.74-.716-.083-2.46.735-4.582 2.244-5.82.316-.26.783-.214 1.043.103z"
          transform="translate(-1182 -14) rotate(12 1192.701 24.614)"
        />
      </g>
    </g>
  </svg>
);

export default IcNotification;
