import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Grid from "components/Grid";
import Typography from "components/Typography";
import Card from "components/Card";
import Divider from "components/Divider";
import { CircleIcon } from "components/Icon";

import { COLOR_BY_STATUS, ORDER_STATUSES } from "constants/Order";
import { PROJECT, ORDERS } from "constants/Router";
import { OrderType } from "types/Order";
import { convertPriceFormat } from "utils/common";

type CustomerOrdersPropsType = {
  customerOrders: OrderType[];
  projectId: string;
};

const CustomerOrders: FC<CustomerOrdersPropsType> = ({ customerOrders, projectId }) => {
  const { t } = useTranslation();

  return (
    <>
      {customerOrders.length > 0 && (
        <Card noShadow padding="0px">
          <Grid container direction="column" alignItems="flex-start">
            <Grid item xs={12} className="p-4">
              <Typography variant="title7" color="darkLight">
                {t("customer.LatestOrders")}
              </Typography>
            </Grid>

            {customerOrders.map((item: OrderType) => {
              const { orderNumber, state, grandTotal } = item;

              return (
                <>
                  <Divider thickness="0.5px" className="w-100 m-0 p-0" />
                  <Grid container className="p-4" key={orderNumber}>
                    <Grid item container>
                      <Grid item container xs={8} lg={9}>
                        <Grid item xs={12} lg={6}>
                          <Link to={`/${PROJECT}/${projectId}/${ORDERS}/${orderNumber}`}>
                            <Typography variant="title8">{orderNumber}</Typography>
                          </Link>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Typography variant="title8" color={COLOR_BY_STATUS[state]}>
                            <CircleIcon color={COLOR_BY_STATUS[state]} />
                            {t(ORDER_STATUSES[state])}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={4} lg={3}>
                        <Typography variant="body3" className="ellipsis">{`฿ ${convertPriceFormat(
                          Number(grandTotal),
                        )}`}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Card>
      )}
    </>
  );
};

export default CustomerOrders;
