import styled from "styled-components";

import Grid from "components/Grid";
import Button from "components/Button";

export const Wrapper = styled(Grid)`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.COLORS.InfoYellowBackground};
  border: 1px solid ${({ theme }) => theme.COLORS.InfoYellowBorder};
  padding: 16px;
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.COLORS.InfoYellow};
  border: 1px solid ${({ theme }) => theme.COLORS.InfoYellow};
  color: ${({ theme }) => theme.COLORS.White};

  :hover {
    background-color: ${({ theme }) => theme.COLORS.White};
    border: 1px solid ${({ theme }) => theme.COLORS.InfoYellowBorder};
    color: ${({ theme }) => theme.COLORS.InfoYellow};
  }
`;
