import { CategoryType } from "types/Category";

export const CATEGORY_CARD_FIELD: Record<string, keyof CategoryType> = {
  NAME: "name",
  IS_ACTIVE: "isActive",
  IMAGE: "image",
  PROPERTIES: "properties",
  VARIATIONS: "variations",
};

export enum ErrorAddCategory {
  CATEGORY_NAME_CANNOT_BE_EMPTY = "ADD_CATEGORY:CATEGORY_NAME_CANNOT_BE_EMPTY",
  DUPLICATED_CATEGORY_NAME = "ADD_CATEGORY:DUPLICATED_CATEGORY_NAME",
  EXCEED_MAXIMUM_CATEGORIES_LIMIT = "ADD_CATEGORY:EXCEED_MAXIMUM_CATEGORIES_LIMIT",
}

export enum ErrorUpdateCategory {
  CATEGORY_NAME_CANNOT_BE_EMPTY = "UPDATE_CATEGORY:CATEGORY_NAME_CANNOT_BE_EMPTY",
  DUPLICATED_CATEGORY_NAME = "UPDATE_CATEGORY:DUPLICATED_CATEGORY_NAME",
}

export enum ErrorDeleteCategory {
  UNABLE_TO_DELETE_CATEGORY_THAT_STILL_HAS_PRODUCT = "DELETE_CATEGORY:UNABLE_TO_DELETE_CATEGORY_THAT_STILL_HAS_PRODUCT",
}

export const DEFAULT_CATEGORY_IMAGE =
  "https://storage.googleapis.com/kanda-ai-core/static/message-builder/default-category-image.jpeg";
