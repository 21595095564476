import styled from "styled-components";
import Grid from "components/Grid";

export const PlanNameWrapper = styled(Grid)`
  @media only screen and (min-width: 400px) {
    margin-left: 8px;
  }
`;

export const NegativeMarginTextWrapper = styled(Grid)`
  margin-top: -4px;
`;

export const Wrapper = styled(Grid)`
  max-width: 380px;
`;

export const ChildrenWrapper = styled.div`
  pointer-events: none;
`;
