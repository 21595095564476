import { useRef } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import moment from "moment";
import { FAQ_TRAIN_JOB_OPERATION } from "graphql/ai-setting/query";
import { notifySuccess } from "utils/notify";
import { JOB_STATUS_PROCESSING, JOB_STATUS_COMPLETED } from "constants/Faq";

const useQueryFaqJobOperation = (projectId: string) => {
  const { t } = useTranslation();

  const previousJobStatus = useRef<string>();

  const { data: trainStatus, startPolling, stopPolling } = useQuery(FAQ_TRAIN_JOB_OPERATION, {
    variables: {
      projectId,
    },
  });

  const jobStatus = get(trainStatus, "faqTrainJobOperation.status", "");
  let timestamp = get(trainStatus, "faqTrainJobOperation.timestamp", "");
  timestamp = timestamp && moment(Number(timestamp)).format("DD/MM/YYYY HH:mm");
  const isJobProcessing = Boolean(jobStatus === JOB_STATUS_PROCESSING);

  if (jobStatus === JOB_STATUS_PROCESSING && previousJobStatus.current !== JOB_STATUS_PROCESSING) {
    startPolling(2500);
    previousJobStatus.current = JOB_STATUS_PROCESSING;
  } else if (jobStatus === JOB_STATUS_COMPLETED && previousJobStatus.current !== JOB_STATUS_COMPLETED) {
    if (previousJobStatus.current) {
      notifySuccess(t("AI training is completed"), { autoClose: 5000 });
      stopPolling();
    }

    previousJobStatus.current = JOB_STATUS_COMPLETED;
  }

  return { isJobProcessing, timestamp };
};

export default useQueryFaqJobOperation;
