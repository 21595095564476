import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "components/CircularProgress";
import Button from "components/Button";
import Divider from "components/Divider";
import Grid from "components/Grid";
import Modal from "components/Modal";
import Typography from "components/Typography";
import COLORS from "constants/Colors";
import { ShippopPurchaseOrderSummaryError } from "types/Shipping";
import { convertPriceFormat } from "utils/common";
import CarrierDetail from "./CarrierDetail";
import useQueryShippopOrderSummary from "../hooks/useQueryShippopOrderSummary";

type SettleInvoiceModalPropsType = {
  isOpen: boolean;
  onClickConfirm: () => void;
  onClose: () => void;
  projectId: string;
  orderIds: string[];
  isLoading?: boolean;
  onError: (errors: ShippopPurchaseOrderSummaryError[]) => void;
};

const SettleInvoiceModal: FC<SettleInvoiceModalPropsType> = (props) => {
  const { t } = useTranslation();

  const { onClickConfirm, onClose, isOpen, projectId, orderIds, isLoading = false, onError } = props;

  const {
    hasApiKey,
    totalNumberOfOrder,
    carriers,
    addressName,
    address,
    estimatedShippingPrice,
    isLoading: isLoadingShippopOrderSummary,
    errors,
    remainingLogisticCredit,
  } = useQueryShippopOrderSummary(projectId, orderIds, t, onError);

  const isInsufficientLogisticCredit = !hasApiKey ? estimatedShippingPrice > remainingLogisticCredit : false;

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeIconColor={isLoadingShippopOrderSummary ? undefined : COLORS.DarkMed}>
      {isLoadingShippopOrderSummary ? (
        <Grid container className="flex-grow-1" justify="center" alignItems="center">
          <Grid item>
            <CircularProgress size={40} />
          </Grid>
        </Grid>
      ) : (
        <div className="d-flex flex-column overflow-auto">
          {totalNumberOfOrder ? (
            <>
              <Typography variant="title2" color="dark" className="p-4 mt-2">
                {t("shippopReport.modal.title")}
              </Typography>

              <Divider thickness="0.5px" className="w-100 m-0 p-0" />
              <Grid container className="p-4 d-flex flex-grow-1 flex-nowrap flex-column overflow-auto">
                <div className="pb-3 d-flex flex-nowrap">
                  <Typography variant="body3">{t("shippopReport.modal.label.totalOrder")}</Typography>
                  <div className="flex-grow-1 d-flex justify-content-end align-items-center">
                    <Typography variant="title4" color="dark">
                      {convertPriceFormat(totalNumberOfOrder, 0, 0)}
                    </Typography>

                    <Typography className="pl-2" variant="body3">
                      {t("Orders")}
                    </Typography>
                  </div>
                </div>

                <Grid item container className="pb-4">
                  <Grid item xs={12} className="pb-2">
                    <Typography variant="title8" color="dark">
                      {t("shippop.modal.label.senderInformation")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body3">{addressName}</Typography>
                    <Typography variant="body3">{address}</Typography>
                  </Grid>
                </Grid>

                <Grid item container className="pb-4 flex-nowrap flex-column overflow-auto flex-grow-1">
                  <CarrierDetail carriers={carriers} />
                </Grid>

                <Grid item container className="pb-4" justify="space-between" alignItems="flex-end">
                  <Grid item>
                    <Typography variant="title8" color="dark">
                      {t("shippopReport.modal.label.estimateTotalShipping")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="title4" color="dark">
                      {t("THB")} {convertPriceFormat(estimatedShippingPrice)}
                    </Typography>
                  </Grid>
                </Grid>

                {isInsufficientLogisticCredit && (
                  <Grid item container alignItems="flex-end" justify="flex-end">
                    <Grid item>
                      <Typography variant="title8" color="error">
                        {t("shippopReport.remark.insufficientLogisticCredit", {
                          remainingLogisticCredit: convertPriceFormat(remainingLogisticCredit),
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <Grid container className="px-3 pb-4">
                <Grid item xs={6} className="pr-2">
                  <Button size="medium" color="ghost" fullWidth onClick={onClose}>
                    {t("Cancel")}
                  </Button>
                </Grid>
                <Grid item xs={6} className="pl-2">
                  <Button
                    size="medium"
                    fullWidth
                    onClick={onClickConfirm}
                    disabled={isLoading || isInsufficientLogisticCredit}
                  >
                    {t("Confirm")}
                    {isLoading && <CircularProgress size={20} className="ml-2" />}
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} className="p-5">
              <Typography variant="title8" color="dark">
                {t("shippopReport.modal.noneSelectedOrder")}
                <ul>
                  {Array.isArray(errors) && errors.map((error) => <li key={error.orderId}>{error.orderNumber}</li>)}
                </ul>
              </Typography>
            </Grid>
          )}
        </div>
      )}
    </Modal>
  );
};

export default SettleInvoiceModal;
