import { useState } from "react";
import { useQuery } from "@apollo/client";

import { DIGITAL_PRODUCT_LIMIT } from "config";
import { DIGITAL_PRODUCTS } from "graphql/digitalContent/query";
import { DigitalProductConnectionQueryType } from "types/DigitalContent";
import { ProductType } from "types/Product";

const useDigitalProducts = (projectId: string) => {
  const [pageNo, setPageNo] = useState(1);
  const [searchWord, setSearchWord] = useState("");

  const variables = {
    projectId,
    limit: DIGITAL_PRODUCT_LIMIT,
    offset: pageNo - 1,
    filter: {
      productName: searchWord,
      productTypes: [ProductType.DIGITAL_CONTENT],
    },
  };

  const { data, loading: isLoading, refetch } = useQuery<DigitalProductConnectionQueryType>(DIGITAL_PRODUCTS, {
    variables,
  });

  const handlePageChange = (newPage: number) => {
    setPageNo(newPage);
  };

  const handleChangeSearch = (newWord: string) => {
    setSearchWord(newWord);
  };

  return {
    isLoading,
    digitalProducts: data?.digitalProducts?.results || [],
    totalDigitalProducts: data?.digitalProducts?.total || 0,
    pageNo,
    searchWord,
    handlePageChange,
    handleChangeSearch,
    digitalProductsQueryVariables: variables,
    refetchDigitalProduct: refetch,
  };
};

export default useDigitalProducts;
