import MuiSwitch from "@material-ui/core/Switch";
import styled from "styled-components";

const Switch = styled(MuiSwitch)`
  width: 40px;
  height: 24px;
  padding: 0;
  display: flex;

  .MuiSwitch-switchBase {
    padding: 2px;
    bottom: 0px;
    &.Mui-checked {
      color: ${({ theme }) => theme.COLORS.White};
      transform: translateX(16px);
    }
    &.Mui-checked + .MuiSwitch-track {
      background-color: ${({ theme }) => theme.COLORS.Primary};
      opacity: 1;
    }
  }

  .MuiSwitch-switchBase.Mui-disabled {
    &.Mui-checked + .MuiSwitch-track {
      opacity: 0.6;
    }
  }

  .MuiSwitch-thumb {
    width: 20px;
    height: 20px;
  }

  .MuiSwitch-track {
    border-radius: 12px;
  }
`;

export default Switch;
