/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

import Switch from "components/Switch";
import TextField from "components/TextField";
import Typography from "components/Typography";

import { MOBILE_BANKING_IMAGE } from "constants/Image";

import { DeeplePayPaymentChannelDetail } from "types/DeeplePay";
import { Device } from "types/Device";

import useDevice from "utils/hooks/useDevice";
import useIsDesktop from "utils/hooks/useIsDesktop";

import { FeeBox, Item, Option } from "../styled";

export interface MobileBankingProps {
  mobileBankingData: any;
  onChange: (isActive: boolean, mimimum: number) => void;
}

const MobileBanking: FC<MobileBankingProps> = ({ mobileBankingData, onChange }) => {
  // Hooks
  const device = useDevice();
  const isDesktop = useIsDesktop();
  const { t } = useTranslation();

  // Form hooks
  const [channels] = useState<DeeplePayPaymentChannelDetail[]>(mobileBankingData?.channels || []);
  const [isActive, setIsActive] = useState<boolean>(mobileBankingData?.isActive || false);
  const [isDisableTextField, setIsDisableTextField] = useState<boolean>(
    !mobileBankingData?.isSelectable || !mobileBankingData?.isActive || false,
  );
  const [isDisableSwitch] = useState<boolean>(!mobileBankingData?.isSelectable || false);
  const [minimum, setMinimum] = useState<number>(mobileBankingData?.minimum || 0);

  // Define variables
  const isMobile = Device.MOBILE === device;

  // Event handlers
  const handleChangeSwitch = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsActive(checked);
    setIsDisableTextField(!checked);
    onChange(checked, minimum);
  };

  const handleChangeTextField = (event: ChangeEvent<HTMLInputElement>) => {
    const minimumValue = parseInt(event.target.value, 10);

    setMinimum(minimumValue);
    onChange(isActive, minimumValue);
  };

  // Renderer
  if (!mobileBankingData) {
    return null;
  }

  return (
    <Item
      container
      alignItems="center"
      alignContent="center"
      className={isDesktop ? "px-4 py-3 mb-3" : "mobile px-4 py-3 mb-3"}
    >
      <Grid container item xs={12} className="pb-3 mb-1">
        <Switch className="mr-3" checked={isActive} disabled={isDisableSwitch} onChange={handleChangeSwitch} />
        <Typography variant="title3">{t("deeplePay.paymentChannel.mobileBanking.label")}</Typography>
      </Grid>

      <Grid container justify="flex-start" alignContent="center" alignItems="center" className="pb-3 mb-1">
        <Typography variant="body3" className={isMobile ? "mb-3" : "mr-1"}>
          {t("deeplePay.paymentChannel.mobileBanking.minimum")}
        </Typography>
        <TextField
          type="formatNumber"
          variant="outlined"
          value={minimum}
          placeholder="฿ 0"
          disabled={isDisableTextField}
          onChange={handleChangeTextField}
        />
        <Typography variant="body3" className="ml-1">
          {t("deeplePay.paymentChannel.minimum.unit")}
        </Typography>
      </Grid>

      {channels.map(({ name, fee }: DeeplePayPaymentChannelDetail, i: number) => (
        <Option
          key={name}
          container
          item
          xs={12}
          alignItems="center"
          className={i < channels.length - 1 ? "first line p-4" : "p-4"}
        >
          <Grid item className="mr-3" justify="flex-start">
            <img src={MOBILE_BANKING_IMAGE[name]} alt={name} />
          </Grid>
          <Grid item xs={isDesktop ? 7 : 9}>
            <Typography variant="title8">{t(`deeplePay.paymentChannel.mobileBanking.${name}.title`)}</Typography>
            <Typography variant="body4">{t(`deeplePay.paymentChannel.mobileBanking.description`)}</Typography>
            {!isDesktop && (
              <Typography variant="body3">
                {fee?.min &&
                  fee?.rate &&
                  t("deeplePay.paymentChannel.fee.min.label", {
                    value: fee?.rate,
                    min: fee?.min,
                  })}
              </Typography>
            )}
          </Grid>
          {isDesktop && (
            <FeeBox item xs={4} justify="flex-end">
              <Typography variant="body3">
                {fee?.min &&
                  fee?.rate &&
                  t("deeplePay.paymentChannel.fee.min.label", {
                    value: fee?.rate,
                    min: fee?.min,
                  })}
              </Typography>
            </FeeBox>
          )}
        </Option>
      ))}
    </Item>
  );
};

export default MobileBanking;
