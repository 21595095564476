import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Avatar from "components/Avatar";
import Grid from "components/Grid";
import Typography from "components/Typography";
import { CircleIcon } from "components/Icon";

import { COLOR_BY_STATUS, ORDER_STATUSES } from "constants/Order";
import { convertPriceFormat } from "utils/common";

import { CardListItemWrapper, Capsule } from "../index.styled";

type OrderListItemPropsType = {
  orderNumber: string;
  isCODStatus: boolean;
  grandTotal: number;
  state: string;
  customerName: string;
  customerImgSrc: string;
};

const OrderListItem: FC<OrderListItemPropsType> = (props) => {
  const { t } = useTranslation();

  const { orderNumber, isCODStatus, grandTotal, state, customerName, customerImgSrc } = props;

  const statusColor = COLOR_BY_STATUS[state];

  return (
    <CardListItemWrapper container item xs={12} alignItems="center">
      <Grid item container xs={12} sm={6} className="pb-2" alignItems="center">
        <Grid item container xs={7} alignItems="center">
          <Typography variant="title8" color="dark" className="mr-2">
            {orderNumber}
          </Typography>
          {isCODStatus && (
            <Capsule>
              <Typography variant="title10" color="darkMed">
                COD
              </Typography>
            </Capsule>
          )}
        </Grid>
        <Grid item xs={5}>
          <Typography variant="title8" color={statusColor} className="ellipsis">
            <CircleIcon color={statusColor} />
            {t(ORDER_STATUSES[state])}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={6} className="pb-2" alignItems="center">
        <Grid item container xs={7} alignItems="center">
          <Avatar src={customerImgSrc} size="24px" />
          <Typography variant="body3" className="pl-2">
            {customerName}
          </Typography>
        </Grid>
        <Grid item container xs={5} justify="flex-end" alignItems="center">
          <Typography variant="title10" className="pr-1">
            {t("THB")}
          </Typography>
          <Typography variant="body3">{convertPriceFormat(grandTotal)}</Typography>
        </Grid>
      </Grid>
    </CardListItemWrapper>
  );
};

export default OrderListItem;
