import React, { FC, ReactNode } from "react";
import MUIRadio from "@material-ui/core/Radio";
import MUIRadioGroup from "@material-ui/core/RadioGroup";
import styled from "styled-components";

import Grid from "components/Grid";
import Typography from "components/Typography";
import { Device } from "types/Device";
import useDevice from "utils/hooks/useDevice";

export const RadioGroup = MUIRadioGroup;

export type OnClickRadio = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, value?: string) => void;

type RadioBoxPropsType = {
  isActive: boolean;
  title: string | ReactNode;
  SubTitle?: ReactNode;
  description?: string;
  className?: string;
  disabled?: boolean;
  type?: "button" | "submit";
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, value?: string) => void;
  rtl?: boolean;
  remark?: ReactNode;
  value?: string;
  prewrap?: boolean;
  forceOnClick?: boolean;
};

type RadioContainerPropsType = Pick<RadioBoxPropsType, "isActive"> & { prewrap: boolean };
type RadioButtonPropsType = Pick<RadioBoxPropsType, "isActive" | "disabled">;

const Radio = styled(MUIRadio)`
  &.MuiRadio-root {
    color: ${({ theme }) => theme.COLORS.LightBlue};
    margin-left: -10px;
  }

  &.Mui-checked {
    color: ${({ theme }) => theme.COLORS.Primary};
  }

  &.MuiIconButton-root:hover {
    background-color: rgba(130, 0, 245, 0.04);
  }
`;

const RadioContainer = styled.button<RadioContainerPropsType>`
  outline: none;
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  text-align: left;
  background-color: ${({ theme, isActive }) => (isActive ? theme.COLORS.PrimaryNude : theme.COLORS.White)};
  border: solid 1px ${({ theme, isActive }) => (isActive ? theme.COLORS.Primary : theme.COLORS.LightBlue)};
  cursor: pointer;
  &[disabled] {
    cursor: initial;
    color: ${({ theme }) => theme.COLORS.DarkLight};
    border: solid 1px ${({ theme }) => theme.COLORS.DarkLight};
  }
  white-space: normal;

  @media screen and (min-width: 1024px) {
    ${({ prewrap }) => (prewrap ? "white-space: pre-wrap" : "")}
  }

  @media screen and (min-width: 1531px) {
    white-space: normal;
  }
`;

export const RadioButton = styled.div<RadioButtonPropsType>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 12px;

  background-color: ${({ theme }) => theme.COLORS.White};
  outline: solid 1px
    ${({ theme, isActive, disabled }) => {
      if (disabled) {
        return theme.COLORS.LightBlue;
      }

      if (isActive) {
        return theme.COLORS.Primary;
      }

      return theme.COLORS.LightBlue;
    }};
`;

export const SelectedRadio = styled.div<{ disabled?: boolean }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ theme, disabled }) => (disabled ? theme.COLORS.LightBlue : theme.COLORS.Primary)};
`;

export const RadioBox: FC<RadioBoxPropsType> = (props) => {
  const {
    disabled = false,
    isActive = true,
    title,
    SubTitle,
    description,
    className,
    onClick,
    type = "submit",
    rtl,
    remark,
    value = "",
    prewrap = false,
    forceOnClick = false,
  } = props;

  const device = useDevice();
  const isMobile = device === Device.MOBILE;

  return (
    <RadioContainer
      type={type}
      className={className}
      disabled={disabled}
      isActive={isActive}
      onClick={(event) => {
        if (isActive && !forceOnClick) return;
        onClick(event, value);
      }}
      prewrap={prewrap}
    >
      <Grid container alignItems="center" className="position-relative flex-nowrap">
        {!rtl && (
          <Grid item className={`d-flex flex-grow-0 ${isMobile ? "" : "pl-3"}`}>
            <RadioButton isActive={isActive} disabled={disabled}>
              {isActive && <SelectedRadio disabled={disabled} />}
            </RadioButton>
          </Grid>
        )}
        <Grid item container alignItems="center" className={`d-flex flex-grow-1 ${isMobile ? "pl-1" : "pl-3"}`}>
          <Grid item xs={12}>
            <Typography variant="title7" color={disabled ? "inherit" : "primary"}>
              {typeof title === "function" ? title(props) : title}
            </Typography>
          </Grid>
          {SubTitle && (
            <Grid item xs={12} className="pt-1">
              <Typography color={disabled ? "inherit" : "darkGray"} variant="body4">
                {SubTitle}
              </Typography>
            </Grid>
          )}
          {description && (
            <Grid item xs={12} className="pt-1">
              <Typography variant="body4" color={disabled ? "inherit" : "darkGray"}>
                {description}
              </Typography>
            </Grid>
          )}
          {remark && (
            <Grid
              item
              xs={isMobile ? 12 : 5}
              style={{ margin: "auto", right: 0 }}
              className={isMobile ? "mt-2" : "position-absolute"}
            >
              <Typography color={disabled ? "inherit" : "darkGray"} variant="body4">
                {remark}
              </Typography>
            </Grid>
          )}
        </Grid>
        {rtl && (
          <Grid item className={`d-flex flex-grow-0 ${isMobile ? "pl-2" : "pl-3 pr-2"}`}>
            <RadioButton isActive={isActive}>{isActive && <SelectedRadio />}</RadioButton>
          </Grid>
        )}
      </Grid>
    </RadioContainer>
  );
};

export default Radio;
