import React, { FC } from "react";
import Grid from "components/Grid";
import Typography from "components/Typography";
import { SvgIcon } from "components/Icon";
import { IcInfo, IcWarning } from "components/SvgIcons";
import COLORS from "constants/Colors";
import { useTranslation } from "react-i18next";

import { getWarningConfig } from "utils/common";
import { UserRole } from "types/User";
import { InvoicePaymentType } from "types/Billing";
import { IS_ALLOW_NEW_PACKAGE } from "config";
import { IconWrapper, GridButton, GridDetail, GridContent, GridIcon, BroadcastBarCard, Button } from "./styled";
import { WarningType } from "../../types/Setting";

type BroadcastWarningType = {
  onClickRedirect?: () => void;
  isOwner?: boolean;
  isOnBillingPage?: boolean;
  monthlyBillingPaymentIssueType?: InvoicePaymentType;
  isDisabled?: boolean;
  isUsingNotSupportedPackage?: boolean;
};

const BroadcastWarning: FC<BroadcastWarningType> = ({
  onClickRedirect,
  isOwner = false,
  isOnBillingPage = false,
  monthlyBillingPaymentIssueType,
  isUsingNotSupportedPackage,
  isDisabled = false,
}) => {
  const { t } = useTranslation();
  const role = isOwner ? UserRole.OWNER : UserRole.ADMIN;
  const getErrorType = () => {
    if (isDisabled) return WarningType.BLOCKED_PROJECT;
    if (monthlyBillingPaymentIssueType === InvoicePaymentType.CREDIT_CARD) return WarningType.CREDIT_CARD_ERROR;
    if (monthlyBillingPaymentIssueType === InvoicePaymentType.WALLET) return WarningType.WALLET_ERROR;
    if (IS_ALLOW_NEW_PACKAGE && isUsingNotSupportedPackage) return WarningType.DISABLED_CHOOSE_PACKAGE;

    return WarningType.BLOCKED_PROJECT;
  };

  const WarningConfig = getWarningConfig(getErrorType(), role);
  const isInfo = getErrorType() === WarningType.DISABLED_CHOOSE_PACKAGE;

  return (
    <BroadcastBarCard noShadow className={isInfo ? "info" : ""}>
      <Grid container>
        <GridIcon item>
          <IconWrapper className={isInfo ? "info pt-2" : "pt-2"}>
            <SvgIcon
              width="24"
              height="24"
              viewBox={isInfo ? "0 5 21 21" : "0 0 16 16"}
              component={isInfo ? IcInfo : IcWarning}
              fontSize="large"
            />
          </IconWrapper>
        </GridIcon>
        <GridContent container item alignItems="center" justify="space-between" className={isInfo ? "info p-0" : "p-0"}>
          <GridDetail item sm="auto">
            <Typography variant="title8" color={isInfo ? COLORS.Primary : COLORS.Error}>
              {t(WarningConfig.title)}
            </Typography>
            <Typography variant="body4" color={isInfo ? COLORS.Primary : COLORS.Error}>
              {t(WarningConfig.description)}
            </Typography>
          </GridDetail>

          <GridButton item className="text-xs-right text-sm-right pr-4" sm="auto">
            {!isOnBillingPage && isOwner && !isUsingNotSupportedPackage && (
              <Button size="small" onClick={onClickRedirect} className={isInfo ? "info px-3 py-2" : "px-3 py-2"}>
                {t("BroadcastBarWarning.button.manageBilling")}
              </Button>
            )}
          </GridButton>
        </GridContent>
      </Grid>
    </BroadcastBarCard>
  );
};

export default BroadcastWarning;
