import { useQuery } from "@apollo/client";
import { useState, useRef } from "react";
import get from "lodash/get";
import { LIVE_CRAWLERS } from "graphql/facebookCrawler/query";

import { DEFAULT_PAGE_START, ROWS_PER_PAGE } from "../FacebookLiveTable/config";

type UseGetLiveCrawlersProps = {
  projectId: string;
};

const useGetLiveCrawlers = ({ projectId }: UseGetLiveCrawlersProps) => {
  const filterRef = useRef({});

  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [page, setPage] = useState(DEFAULT_PAGE_START);

  const [filterValue, setFilterValue] = useState({});

  const { data: facebookLiveData, loading: facebookLiveLoading } = useQuery(LIVE_CRAWLERS, {
    variables: {
      projectId,
      limit: rowsPerPage,
      offset: page - 1,
      filter: { ...filterRef.current, ...filterValue },
    }, // filter Object
    skip: !projectId,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const handleSearchChange = (searchInput: string) => setFilterValue({ keyword: searchInput });

  const handlePageClick = (data: { selected: number }) => {
    const { selected } = data;
    // +1 because of ReactPaginate page start from 0 but my page start from 1
    setPage(selected + 1);
  };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(DEFAULT_PAGE_START);
  };

  const facebookLives = get(facebookLiveData, "liveCrawlers.results") || [];
  const facebookLiveTotal: number = get(facebookLiveData, "liveCrawlers.total") || 0;

  return {
    facebookLiveLoading,
    facebookLives,
    facebookLiveTotal,
    handleChangeRowsPerPage,
    handlePageClick,
    handleSearchChange,
    rowsPerPage,
    page,
  };
};

export default useGetLiveCrawlers;
