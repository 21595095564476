import * as yup from "yup";

const schema = yup.object().shape({
  oldPassword: yup.string().required(),
  newPassword: yup.string().required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Confirm password must match with new password")
    .required(),
});

export default schema;
