import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ActionMeta, OptionType, OptionsType } from "@atlaskit/select";

import Grid from "components/Grid";
import MultiSelectWithSearchBox from "components/MultiSelectWithSearchBox";
import CircularProgress from "components/CircularProgress";

import { Product, ProductItemPropsType } from "types/Product";
import { PromotionType } from "types/Promotion";

import { getRelatedPromotionByProducts } from "components/Message/utils/promotionUtil";
import FlexProductCarousel from "components/FlexMessage/FlexProductCarousel";

import { formatProductDataToFlexFormat } from "components/Message/utils/formatProduct";
import { MAXIMUM_ADD_PRODUCT_CAROUSEL } from "config";

export type SelectProductPropsType = {
  id?: string;
  isProductsLoading: boolean;
  productOptions: {
    label: string;
    img: string;
    value: string;
  }[];
  productResults: ProductItemPropsType[];
  convertProductValueToOptionFormat: Function;
  selectedProducts: number[];
  setSelectedProducts: Function;
  activePromotionsResults: PromotionType[];
};

const SelectProduct: FC<SelectProductPropsType> = (props) => {
  const {
    id,
    selectedProducts,
    setSelectedProducts,
    isProductsLoading,
    productOptions,
    productResults,
    convertProductValueToOptionFormat,
    activePromotionsResults,
  } = props;
  const { t } = useTranslation();

  const handleChangeSelectProduct = (option: OptionsType<OptionType>, action: ActionMeta<OptionType>) => {
    if (action.action === "select-option" && selectedProducts.length < MAXIMUM_ADD_PRODUCT_CAROUSEL) {
      setSelectedProducts(((option as OptionsType<OptionType>) || []).map((opt: OptionType) => opt.value));
    } else if (action.action === "deselect-option") {
      setSelectedProducts(((option as OptionsType<OptionType>) || []).map((opt: OptionType) => opt.value));
    }
  };

  const renderSelectedProducts = selectedProducts.map((productId: number) => {
    return productResults.find((product: ProductItemPropsType) => Number(product.id) === Number(productId));
  });

  const handleClickDelete = (deleteProductId: string) => {
    setSelectedProducts(selectedProducts.filter((productId: number) => Number(deleteProductId) !== Number(productId)));
  };

  const relatedPromotionByProducts =
    getRelatedPromotionByProducts(
      (renderSelectedProducts as ProductItemPropsType[]) || [],
      activePromotionsResults || [],
    ) || [];

  return (
    <Grid container>
      {isProductsLoading ? (
        <Grid container item xs={12} justify="center">
          <CircularProgress className="p-4" />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <MultiSelectWithSearchBox
            id={id}
            isMulti
            isDisplayMinimumItem
            isDisplayImageOption
            value={convertProductValueToOptionFormat(selectedProducts)}
            label={t("Click to select products")}
            placeholder={t("Search products")}
            options={productOptions}
            onChange={(option: OptionsType<OptionType>, action: ActionMeta<OptionType>) => {
              handleChangeSelectProduct(option || [], action);
            }}
            maxMenuWidth={310}
          />
          {Boolean(renderSelectedProducts && renderSelectedProducts.length) && (
            <Grid container item xs={12} justify="flex-start" className="pt-1">
              <FlexProductCarousel
                isEnableDelete
                onClickDelete={handleClickDelete}
                content={formatProductDataToFlexFormat({
                  products: ((renderSelectedProducts as unknown) as Product[]) || [],
                  promotions: relatedPromotionByProducts,
                })}
              />
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default SelectProduct;
