import React, { useState, MouseEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Card from "components/Card";
import Grid from "components/Grid";
import Modal from "components/Modal";
import { Menu } from "components/Menu";
import CircularProgress from "components/CircularProgress";
import IndeterminateCheckbox from "components/Checkbox";
import { SvgIcon } from "components/Icon";
import { IcPrint } from "components/SvgIcons";
import ExportCSVButton from "components/ExportCSVButton";
import { ExportButton as PrintButton } from "domain/Report/SalesReport/styled";
import ImportProductButton from "domain/Product/ImportProductButton";
import { DisableFeature } from "pages/DisableFeature";
import { ExportFileType, ParcelInvoiceTemplateType } from "types/SalesReport";
import useGetProject from "utils/hooks/useGetProject";
import { FilterItemType, FILTER_ID } from "./types";
import { FilterCardWrapper, ButtonAddFilter } from "./Filter.styled";
import EditMenuOptionsItem from "./EditMenuOptionsItem";
import AddMenuOptionsItem from "./AddMenuOptionsItem";
import FilterListItems from "./FilterListItems";
import ModalSelectConsignmentType from "./ModalSelectConsignmentReportType";
import { INITIAL_FILTER_ID_LISTS, INITIAL_FILTER_LISTS, MENU_OPTIONS } from "./config";
import {
  getKeyName,
  getFilterValue,
  getSelectTypeFromFilterKey,
  getOptionsFromKey,
  displayFilterName,
} from "./FilterOrderStat.utils";

const ReportFilter: React.FC<{
  setFilterValue: Function;
  handleExportReports?: (type: ExportFileType) => void;
  isLoadingImport?: boolean;
  isDisabledImportButton?: boolean;
  isDisabledAdvanceButtonAndShowChangePackage?: boolean;
  isEnablePrintParcelInvoice?: boolean;
  handleImportReports?: (file: File) => void;
  handleClickSelectedAll?: (event: MouseEvent<HTMLButtonElement>) => void;
  printNumber?: string;
  exportedNumber: string;
  projectId: string;
  isSelectedAll?: boolean;
  isDisabledExportButton?: boolean;
  onClickPrintParcelInvoice?: Function;
  printParcelInvoiceLoading?: boolean;
  isHiddenAddMoreFilter?: boolean;
  defaultFilterList?: FilterItemType[];
  exportLabel?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menuOptions?: any;
}> = ({ projectId, ...props }) => {
  const { t } = useTranslation();
  const { isSupportLegacyPayment, isDeeplePayEnabled } = useGetProject(projectId);

  const {
    handleClickSelectedAll,
    isDisabledExportButton = false,
    isDisabledImportButton = false,
    isDisabledAdvanceButtonAndShowChangePackage = false,
    isEnablePrintParcelInvoice = true,
    isSelectedAll = false,
    isLoadingImport = false,
    setFilterValue,
    handleExportReports,
    handleImportReports,
    printNumber,
    exportedNumber,
    onClickPrintParcelInvoice,
    printParcelInvoiceLoading = false,
    isHiddenAddMoreFilter = false,
    defaultFilterList = INITIAL_FILTER_LISTS,
    exportLabel,
    menuOptions = MENU_OPTIONS(isSupportLegacyPayment, isDeeplePayEnabled),
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [filterList, setFilterList] = useState<FilterItemType[]>(defaultFilterList);
  const [isOpenSelectConsignmentTypeModal, setIsOpenSelectConsignmentTypeModal] = useState(false);

  const handleCloseModal = () => {
    setIsOpenSelectConsignmentTypeModal(false);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickPrintShipmentDetail = () => {
    setIsOpenSelectConsignmentTypeModal(true);
  };

  const handleClickButtonConsignmentType = (type: ParcelInvoiceTemplateType) => {
    if (onClickPrintParcelInvoice) {
      onClickPrintParcelInvoice(type);

      handleCloseModal();
    }
  };

  const handleSetFilterList = (filterListData: FilterItemType[]) => {
    setFilterList(filterListData);
    setFilterValue(getFinalFilterResult(filterListData));
    handleClose();
  };

  const removeValue = (itemId: string) => {
    const updateValue = filterList.filter((item) => item.id !== itemId);
    setFilterList(updateValue);
    setFilterValue(getFinalFilterResult(updateValue));
  };

  const getFinalFilterResult = (filterListData: FilterItemType[]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any = {};
    filterListData.forEach((filterItem: FilterItemType) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(filterItem)) {
        if (key !== "id") {
          result[key] = value;
        }
      }
    });
    return result;
  };

  const initialFilters = useMemo(
    () =>
      filterList.filter((filterItem) => {
        const keyName = getKeyName(filterItem);
        return INITIAL_FILTER_ID_LISTS.includes(keyName as FILTER_ID);
      }),
    [filterList],
  );

  const exportButton = handleExportReports && (
    <ExportCSVButton
      isDisabled={isDisabledExportButton}
      label={exportLabel}
      subLabel={t("product.import.button.subLabel")}
      exportedNumber={exportedNumber || t("salesReport.exportedNumber.all")}
      onExport={handleExportReports}
    />
  );

  const importButton = handleImportReports && (
    <ImportProductButton
      isDisabled={isDisabledImportButton}
      color="secondary"
      size="small"
      isFileType
      label={t("IMPORT_TRACKING_NO")}
      subLabel={t("product.import.button.subLabel")}
      onChange={handleImportReports}
      isLoading={isLoadingImport}
      variantTitle="body3"
    />
  );

  const printButton = (
    <PrintButton
      disabled={!Number(printNumber) || printParcelInvoiceLoading}
      color="secondary"
      onClick={handleClickPrintShipmentDetail}
    >
      <SvgIcon component={IcPrint} fontSize="small" />
      {t("salesReport.printInvoice")} {Number(printNumber) ? `(${printNumber})` : ""}
      {printParcelInvoiceLoading && <CircularProgress className="ml-3" size={16} />}
    </PrintButton>
  );
  return (
    <>
      <Modal isOpen={printParcelInvoiceLoading} onClose={() => {}}>
        <CircularProgress className="m-4" />
      </Modal>
      <ModalSelectConsignmentType
        isOpen={isOpenSelectConsignmentTypeModal}
        onClose={handleCloseModal}
        onClickFullType={() => handleClickButtonConsignmentType(ParcelInvoiceTemplateType.FULL)}
        onClickBriefType={() => handleClickButtonConsignmentType(ParcelInvoiceTemplateType.COMPACT)}
      />
      <Grid container alignItems="center" alignContent="flex-start" style={{ zIndex: 2 }}>
        {/* zIndex: 2 will be on top of table styled */}
        {handleClickSelectedAll && (
          <Grid item className="pr-2">
            <Card
              noShadow
              width={40}
              height={30}
              borderRadius={8}
              padding="8px 8px"
              className="d-flex justify-content-center align-items-center"
            >
              <IndeterminateCheckbox onClick={handleClickSelectedAll} checked={isSelectedAll} />
            </Card>
          </Grid>
        )}

        {initialFilters.map((initialFilter: FilterItemType) => {
          const keyName = getKeyName(initialFilter);
          return (
            <Grid item key={initialFilter.id}>
              <FilterCardWrapper
                style={
                  keyName === FILTER_ID.DATE_RANGE
                    ? { border: 0, minHeight: "46px", backgroundColor: "unset" }
                    : { minHeight: "46px" }
                }
                className="mr-2 my-1"
              >
                <Grid container justify="flex-start" alignContent="center" alignItems="center">
                  <EditMenuOptionsItem
                    key={initialFilter.id}
                    title={displayFilterName(initialFilter)}
                    selectType={getSelectTypeFromFilterKey(initialFilter)}
                    options={getOptionsFromKey(
                      initialFilter,
                      filterList,
                      [],
                      isSupportLegacyPayment,
                      isDeeplePayEnabled,
                    )}
                    keyName={getKeyName(initialFilter) || ""}
                    filterValue={getFilterValue(initialFilter)}
                    filterList={filterList}
                    setValue={handleSetFilterList}
                    editId={initialFilter.id}
                  />
                </Grid>
              </FilterCardWrapper>
            </Grid>
          );
        })}
        {handleExportReports && (
          <Grid item className="py-2 px-1">
            {isDisabledAdvanceButtonAndShowChangePackage ? (
              <DisableFeature>{exportButton}</DisableFeature>
            ) : (
              exportButton
            )}
          </Grid>
        )}

        {handleImportReports && (
          <Grid item className="py-2 px-1">
            {isDisabledAdvanceButtonAndShowChangePackage ? (
              <DisableFeature>{importButton}</DisableFeature>
            ) : (
              importButton
            )}
          </Grid>
        )}

        {onClickPrintParcelInvoice && (
          <Grid item className="py-2 px-1">
            {isEnablePrintParcelInvoice ? printButton : <DisableFeature>{printButton}</DisableFeature>}
          </Grid>
        )}

        <Grid container justify="flex-start" alignItems="center" alignContent="center">
          <FilterListItems
            removeValue={removeValue}
            filterList={filterList}
            setValue={handleSetFilterList}
            projectId={projectId}
          />
          {!isHiddenAddMoreFilter && (
            <ButtonAddFilter onClick={handleClick} className="px-2">
              + {t("AddFilter")}
            </ButtonAddFilter>
          )}
        </Grid>
        <Grid item>
          <Menu
            id="add-order-filter-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {menuOptions.map((menuOption: any) => (
              <AddMenuOptionsItem
                id={menuOption.id}
                key={menuOption.key}
                title={menuOption.title}
                filterKey={menuOption.filterKey}
                selectType={menuOption.selectType}
                options={menuOption.options}
                filterList={filterList}
                setFilterList={handleSetFilterList}
                handleCloseAddMenu={handleClose}
              />
            ))}
          </Menu>
        </Grid>
      </Grid>
    </>
  );
};

export default ReportFilter;
