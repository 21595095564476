import * as yup from "yup";
import { NEW_FAQ_QUESTION_MAX_LENGTH_ERROR } from "constants/Faq";
import { FAQ_MAX_QUESTION_LENGTH } from "config/faq";

export const validationThSchema = (i18n: Function) =>
  yup.object().shape({
    questions: yup.array().of(
      yup.object({
        id: yup.string(),
        value: yup
          .string()
          .trim()
          .max(
            FAQ_MAX_QUESTION_LENGTH,
            i18n(NEW_FAQ_QUESTION_MAX_LENGTH_ERROR, { maxLength: FAQ_MAX_QUESTION_LENGTH }),
          ),
      }),
    ),
  });

export const validationThEnSchema = (i18n: Function) =>
  yup.object().shape({
    questions: yup.array().of(
      yup.object({
        id: yup.string(),
        value: yup
          .string()
          .trim()
          .max(
            FAQ_MAX_QUESTION_LENGTH,
            i18n(NEW_FAQ_QUESTION_MAX_LENGTH_ERROR, { maxLength: FAQ_MAX_QUESTION_LENGTH }),
          ),
      }),
    ),
    enQuestions: yup.array().of(
      yup.object({
        id: yup.string(),
        value: yup
          .string()
          .trim()
          .max(
            FAQ_MAX_QUESTION_LENGTH,
            i18n(NEW_FAQ_QUESTION_MAX_LENGTH_ERROR, { maxLength: FAQ_MAX_QUESTION_LENGTH }),
          ),
      }),
    ),
  });
