export const TOAST_ID = "toastId";

export const NOTIFICATION_UPDATE = {
  SUCCESS: "Update successfully.",
  FAIL: "Update fail.",
};

export const NOTIFICATION_CREATE = {
  SUCCESS: "Create successfully.",
  FAIL: "Create fail.",
};

export const NOTIFICATION_DELETE = {
  SUCCESS: "Delete successfully.",
  FAIL: "Delete fail.",
};

export const NOTIFICATION_SUBMIT = {
  SUCCESS: "Submit successfully.",
  FAIL: "Submit fail.",
};

export const NOTIFICATION_UPLOAD = {
  SUCCESS: "Upload successfully",
  FAIL: "Upload fail",
};

export const NOTIFICATION_SAVE = {
  SUCCESS: "Save successfully",
  FAIL: "Save fail",
};

export const MAXIMUM_PREVIEW_NOTIFICATION_MESSAGES = 30;

export const LIMIT_PREVIEW_NOTIFICATION_MESSAGES = 10;
