import * as React from "react";

const SvgIcAiInactive = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.0073351,1.77777778 C22.6586961,1.77777778 28.2407237,6.35613799 29.7738912,12.5333316 L31.0146702,12.5332645 C31.566955,12.5332645 32.0146702,12.9809797 32.0146702,13.5332645 L32.0146702,18.3895164 C32.0146702,18.9418011 31.566955,19.3895164 31.0146702,19.3895164 L29.7738912,19.3894493 C28.2407237,25.5666429 22.6586961,30.1450031 16.0073351,30.1450031 C9.35597413,30.1450031 3.77394648,25.5666429 2.24077899,19.3894493 L1,19.3895164 C0.44771525,19.3895164 1.44125389e-11,18.9418011 1.44124712e-11,18.3895164 L1.44124712e-11,13.5332645 C1.44124036e-11,12.9809797 0.44771525,12.5332645 1,12.5332645 L2.24077899,12.5333316 C3.77394648,6.35613799 9.35597413,1.77777778 16.0073351,1.77777778 Z M16.0073351,7.22453925 C11.0606751,7.22453925 7.05061491,11.2345995 7.05061491,16.1812594 C7.05061491,19.9885889 9.18643664,23.1040535 12.5362051,24.400325 C12.8792158,24.5286126 11.5430299,27.9305009 11.6474183,28.139207 C15.5996797,26.3199065 18.4800142,24.8748774 19.5725255,24.400325 C22.7452111,23.0222106 24.9640553,19.8608544 24.9640553,16.1812594 C24.9640553,11.2345995 20.9539951,7.22453925 16.0073351,7.22453925 Z M16.1777778,19.5555556 C17.0612692,19.5555556 17.7777778,20.2720641 17.7777778,21.1555556 C17.7777778,22.039047 17.0612692,22.7552182 16.1777778,22.7552182 C15.2942863,22.7552182 14.5777778,22.039047 14.5777778,21.1555556 C14.5777778,20.2720641 15.2942863,19.5555556 16.1777778,19.5555556 Z M16.915876,10.3111111 C17.4050179,10.3111111 17.7916087,10.725701 17.7588868,11.2134936 L17.3109003,17.7990301 C17.2808771,18.2422939 16.9125026,18.5867173 16.4678895,18.5867173 L15.887666,18.5867173 C15.4430529,18.5867173 15.0746785,18.2422939 15.0446553,17.7990301 L14.5966688,11.2134936 C14.5639469,10.725701 14.9505376,10.3111111 15.4396795,10.3111111 L16.915876,10.3111111 Z"
    />
  </svg>
);

export default SvgIcAiInactive;
