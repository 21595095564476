import React, { FC } from "react";
import { useParams, Switch, Redirect, Route } from "react-router-dom";
import { Pages } from "@1convo/web-console";
import {
  PROJECT,
  SHIPPING,
  SHIPPING_DELIVERY_PERIOD,
  SHIPPING_DEFAULT,
  SHIPPOP,
  PARCEL_INVOICE_REPORT,
} from "constants/Router";
import { IS_USING_NEW_SHIPPING_SERVICE } from "config";
import { DeliveryPeriod, DefaultShippingMethod } from "domain/Shipping";
import Shippop from "domain/Shipping/Shippop";
import useGetUserWithPermission from "utils/hooks/useGetUserWithPermission";
import useTabWithLink from "utils/hooks/useTabWithLink";

import PrivateRoute from "routers/PrivateRoute";
import { NotFound } from "pages/NotFound";
import {
  OWNER_TAB_CONFIGS,
  OWNER_TAB_CONFIGS_WITHOUT_SHIPPOP,
  ADMIN_TAB_CONFIGS,
  ADMIN_TAB_CONFIGS_WITHOUT_SHIPPOP,
} from "pages/Shipping/config";
import { ParcelInvoiceReport } from "pages/ParcelInvoiceReport";
import { ProjectIdType } from "types/Project";

const ShippingSubRoute: FC<{ isShippopEnabled: boolean }> = ({ isShippopEnabled }) => {
  const { projectId = "" } = useParams<ProjectIdType>();
  const { loading, isAdmin, isOwner } = useGetUserWithPermission(projectId);
  const ownerTabConfigs = isShippopEnabled ? OWNER_TAB_CONFIGS : OWNER_TAB_CONFIGS_WITHOUT_SHIPPOP;
  const adminTabConfigs = isShippopEnabled ? ADMIN_TAB_CONFIGS : ADMIN_TAB_CONFIGS_WITHOUT_SHIPPOP;
  const { defaultTabPath, rootPath } = useTabWithLink(isOwner ? ownerTabConfigs : adminTabConfigs, SHIPPING, projectId);

  return (
    <Switch>
      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${SHIPPING_DEFAULT}`}
        isAllowed={isOwner || isAdmin}
        redirectTo="/"
      >
        {IS_USING_NEW_SHIPPING_SERVICE ? <Pages.Shipping projectId={projectId} /> : <DefaultShippingMethod />}
      </PrivateRoute>

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${SHIPPING_DELIVERY_PERIOD}`}
        isAllowed={isOwner}
        redirectTo="/"
        component={DeliveryPeriod}
      />

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${PARCEL_INVOICE_REPORT}`}
        isAllowed={isAdmin}
        redirectTo="/"
      >
        <ParcelInvoiceReport />
      </PrivateRoute>

      {isShippopEnabled && (
        <PrivateRoute exact loading={loading} path={`${rootPath}/${SHIPPOP}`} isAllowed={isAdmin} redirectTo="/">
          <Shippop projectId={projectId} />
        </PrivateRoute>
      )}

      <PrivateRoute exact loading={loading} path={`${rootPath}`} isAllowed={isOwner || isAdmin} redirectTo="/">
        <Redirect to={defaultTabPath ? `/${PROJECT}/${projectId}/${SHIPPING}/${defaultTabPath}` : `/${PROJECT}`} />
      </PrivateRoute>

      <Route path={`${rootPath}/*`}>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default ShippingSubRoute;
