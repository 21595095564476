import React, { FC } from "react";
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableStateSnapshot,
  Draggable,
  DraggableProvided,
} from "react-beautiful-dnd";

import { IcDrag } from "components/SvgIcons";
import { formatProductDataToFlexFormat } from "components/Message/utils/formatProduct";
import FlexProductCarousel from "components/FlexMessage/FlexProductCarousel";

import COLORS from "constants/Colors";
import { Product } from "types/Product";
import { PromotionType } from "types/Promotion";

import { ScrollContainer, DropZone, ProductListWrapper } from "../styled";

type ProductListsPropsType = {
  listId: string;
  highlightProducts: Product[];
  promotions: PromotionType[];
  handleClickDelete: Function;
};

const ProductLists: FC<ProductListsPropsType> = ({ listId, highlightProducts, promotions = [], handleClickDelete }) => {
  const renderProductLists = (dropProvided: DroppableProvided) => {
    return (
      <DropZone ref={dropProvided.innerRef}>
        {highlightProducts.map((product: Product, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Draggable key={index} draggableId={`${index}`} index={index}>
            {(dragProvided: DraggableProvided, _dragSnapshot: DraggableStateSnapshot) => (
              <ProductListWrapper
                ref={(ref: HTMLElement | null | undefined) => dragProvided.innerRef(ref)}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                {...(dragProvided.draggableProps as any)}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                {...(dragProvided.dragHandleProps as any)}
              >
                <IcDrag color={COLORS.DarkMed} className="mt-5" />
                <FlexProductCarousel
                  displayScrollbar={false}
                  isEnableDelete
                  onClickDelete={handleClickDelete}
                  content={formatProductDataToFlexFormat({
                    products: product ? [product] : [],
                    promotions: [...promotions],
                  })}
                />
              </ProductListWrapper>
            )}
          </Draggable>
        ))}
        {dropProvided.placeholder}
      </DropZone>
    );
  };

  return (
    <Droppable droppableId={listId} direction="horizontal">
      {(dropProvided: DroppableProvided, _dropSnapshot: DroppableStateSnapshot) => (
        <div {...dropProvided.droppableProps}>
          <ScrollContainer>{renderProductLists(dropProvided)}</ScrollContainer>
        </div>
      )}
    </Droppable>
  );
};

export default ProductLists;
