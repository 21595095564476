const faq = {
  en: {
    "Add question": "Add question",
    "AI training Fail. Please retry later": "AI training Fail. Please retry later",
    "AI training is completed": "AI training is completed",
    "AI training is processing": "AI training is processing",
    "Are you sure you want to remove ?": "Are you sure you want to remove ?",
    "Are you sure you want to remove this question": "Are you sure you want to remove this question",
    "FAQ.question.duplicated.error": "This question is duplicated by question no.{{order}} of '{{firstQuestion}}'",
    "newFAQ.question.duplicated.error": "{{question}} is duplicated by question no.{{order}} of '{{firstQuestion}}'",
    "FAQ.question.duplicated": "This question is duplicated",
    "newFAQ.question.duplicated": "{{question}} is duplicated",
    "FAQ.question.duplicated.sanity.error": "This question is duplicated with bot flow",
    "newFAQ.question.duplicated.sanity.error": "{{question}} is duplicated with bot flow",
    "newFAQ.question.sanity.error": "Validator system has problem. Please try again.",
    "newFAQ.question.maxLength.error": "Question No. should be less than {{maxLength}} letters",
    "newFAQ.question.hasSome.maxLength.error":
      "There is some question that is over {{maxLength}} letters in {{language}}",
    "FAQ.question.tryAgain.sanity.error": "Please try again",
    "FAQ.question.requireMinLength.error": "The question should have at least 3",
    "If client says something similar to": "If client says something similar to",
    "Next > Answer": "Next > Answer",
    "Please add a new answer": "Please add a new answer",
    "question is a required field": "question is a required field",
    "Specialized knowledge": "Specialized knowledge",
    "New Specialized Knowledge": "New Specialized Knowledge",
    "Train your AI": "Train your AI",
    Answer: "Answer",
    FAQ: "Specialized knowledge",
    Training: "Training",
    "faq.button.addNewFaq": "Add new specialized knowledge",
    questions: "Client says something",
    "Thai questions": "Client says something in Thai",
    "English questions": "Client says something in English",
    "English responses": "English answers",
    responses: "Answers",
    question: "questions",
    "Thai responses": "Thai responses",
    "Recently trained on": "Recently trained on",
    "No question": "No question",
    "No FAQ": "No specialized knowledge",
    "Search no found": "Search no found",
    "faq.notification.enable": "Enable notification",
    "faq.notification.disabled": "Disabled notification",
    "faq.question.overlappedIntents": "This sentence is overlapped with ",
    "Add specialized knowledges to train your bot to have store-customized and smarter responses when chatting with your customers":
      "Add specialized knowledges to train your bot to have store-customized and smarter responses when chatting with your customers",
    "Save and train bot": "Save and train bot",
    "Training bots to update bots with all the latest changes":
      "Training bots to update bots with all the latest changes",
  },
  th: {
    "Add question": "เพิ่มคำถาม",
    "AI training Fail. Please retry later": "การสอนบอทล้มเหลว กรุณาลองใหม่อีกครั้ง",
    "AI training is completed": "การสอนบอทสำเร็จ",
    "AI training is processing": "กำลังทำการสอนบอท",
    "Are you sure you want to remove ?": "คุณยืนยันที่จะลบหรือไม่ ?",
    "Are you sure you want to remove this question ?": "คุณยืนยันที่จะลบคำถามนี้หรือไม่ ?",
    "FAQ.question.duplicated.error": "คำถามนี้ซ้ำกับคำถามที่ {{order}} ของคำถาม '{{firstQuestion}}'",
    "newFAQ.question.duplicated.error": "{{question}} ซ้ำกับคำถามที่ no.{{order}} ของคำถาม '{{firstQuestion}}'",
    "newFAQ.question.sanity.error": "ระบบตรวจสอบเกิดปัญหา กรุณาลองใหม่อีกครั้ง",
    "newFAQ.question.maxLength.error": "คำถามควรมีความยาวไม่เกิน {{maxLength}}ตัวอักษร",
    "newFAQ.question.hasSome.maxLength.error": "มีบางคำถามที่เกิน {{maxLength}}ตัวอักษร ในภาษา{{language}}",
    "FAQ.question.duplicated": "คำถามนี้ซ้ำ",
    "newFAQ.question.duplicated": "{{question}} ซ้ำกับคำอื่นในนี้",
    "FAQ.question.duplicated.sanity.error": "คำถามนี้ซ้ำกับระบบของบอท",
    "newFAQ.question.duplicated.sanity.error": "{{question}}ซ้ำกับระบบของบอท",
    "FAQ.question.tryAgain.sanity.error": "กรุณาลองใส่อีกครั้ง",
    "FAQ.question.requireMinLength.error": "ควรมีคำถามอย่างน้อย 3 ข้อ",
    "If client says something similar to": "ข้อความที่ลูกค้าพิมพ์มา",
    "Next > Answer": "ถัดไป > คำตอบ",
    "Please add a new answer": "กรุณาเพิ่มคำตอบใหม่",
    "question is a required field": "กรุณาใส่คำถาม",
    "Specialized knowledge": "ความรู้เฉพาะทาง",
    "Train your AI": "สอนบอทของคุณ",
    "New Specialized Knowledge": "ความรู้เฉพาะทางใหม่",
    Answer: "คำตอบ",
    FAQ: "ความรู้เฉพาะทาง",
    Training: "กำลังสอนบอท",
    "faq.button.addNewFaq": "เพิ่มความรู้เฉพาะทาง",
    questions: "ข้อความที่ลูกค้าพิมพ์มา",
    "Thai questions": "ข้อความที่ลูกค้าพิมพ์มาในภาษาไทย",
    "English questions": "ข้อความที่ลูกค้าพิมพ์มาในภาษาอังกฤษ",
    "English responses": "คำตอบในภาษาอังกฤษ",
    responses: "คำตอบ",
    question: "คำถาม",
    "Thai responses": "คำตอบภาษาไทย",
    "Recently trained on": "สอนครั้งล่าสุดเมื่อ ",
    "No question": "ไม่มีคำถาม",
    "No FAQ": "ยังไม่มีความรู้เฉพาะทาง",
    "Search no found": "ไม่เจอการค้นหานี้",
    "faq.notification.enable": "เปิดการแจ้งเตือน",
    "faq.notification.disabled": "ปิดการแจ้งเตือน",
    "faq.question.overlappedIntents": "ประโยคนี้ซ้อนทับกับ Intent",
    "Add specialized knowledges to train your bot to have store-customized and smarter responses when chatting with your customers":
      "เพิ่มความรู้เฉพาะทางเพื่อฝึกบอทของคุณให้มีการตอบสนองที่ปรับแต่งจากร้านค้าและชาญฉลาดยิ่งขึ้นเมื่อพูดคุยกับลูกค้าของคุณ",
    "Save and train bot": "บันทึกและสอนบอท",
    "Training bots to update bots with all the latest changes": "ด้วยการเปลี่ยนแปลงล่าสุดทั้งหมด",
  },
};

export default faq;
