import React, { FC, useState, useEffect, ChangeEvent } from "react";
import xor from "lodash/xor";
import { useTranslation } from "react-i18next";

import Card from "components/Card";
import Divider from "components/Divider";
import Grid from "components/Grid";
import Tab from "components/Tab";
import Typography from "components/Typography";

import ProductListItem, { ProductListItemPropsType } from "domain/Product/ProductListItem";
import { DigitalProductsQueryVariablesType } from "types/DigitalContent";
import { ProductSKUType, ProductOutputType, DigitalProductInventory } from "types/Product";
import { getProductSKUVariationList } from "utils/product/getProductSKUVariationList";

import { tabHeaderConfigCodeImported, tabHeaderConfigDeepLink, tabHeaderConfigCodeGen } from "../tabHeaderConfig";
import DigitalContentSKU from "../DigitalContentSKU";
import DigitalProductSKUItemList from "../DigitalProductSKUItemList";
import { ItemListWrapper } from "../styled";

type DigitalContentProps = ProductListItemPropsType & {
  isMobile: boolean;
  productId: string;
  outputType: ProductOutputType;
  digitalProductsQueryVariables: DigitalProductsQueryVariablesType;
};

const DigitalContent: FC<DigitalContentProps> = (props) => {
  const { t } = useTranslation();
  const {
    productId,
    productName,
    productImage,
    productSKUs,
    defaultPrice,
    isActive,
    isMobile,
    outputType,
    digitalProductsQueryVariables,
  } = props;

  const [expandedIndexList, setExpandedIndexList] = useState<number[]>([]);
  const [focusedTabIndex, setFocusedTabIndex] = useState(0);

  const handleToggleExpandContent = (index: number) => {
    const newExpandedIndexList = xor(expandedIndexList, [index]);

    setExpandedIndexList(newExpandedIndexList);
  };

  const handleChangeTab = (_event: ChangeEvent<{}>, newTabIndex: number) => {
    setFocusedTabIndex(newTabIndex);
  };

  // if status is changed should collapse product code list
  useEffect(() => {
    setExpandedIndexList([]);
  }, [focusedTabIndex]);

  let tabHeaderConfig = tabHeaderConfigDeepLink;
  switch (outputType) {
    case ProductOutputType.CODE_IMPORTED:
      tabHeaderConfig = tabHeaderConfigCodeImported;
      break;
    case ProductOutputType.CODE_AUTO_GENERATED:
    case ProductOutputType.CODE_GENERATED_FROM_OTHER:
      tabHeaderConfig = tabHeaderConfigCodeGen;
      break;
    case ProductOutputType.STATIC:
    default:
      tabHeaderConfig = tabHeaderConfigDeepLink;
  }

  const renderProductListItem = () => (
    <ProductListItem
      productImage={productImage}
      productName={productName}
      productSKUs={productSKUs}
      defaultPrice={defaultPrice}
      isActive={isActive}
      isDigitalProduct
    />
  );

  return (
    <Card padding="0px" borderRadius={isMobile ? 0 : 8}>
      <Grid container>
        <Grid item xs={12} className="px-4 pt-4">
          {renderProductListItem()}
        </Grid>
        <Grid item xs={12} className="px-4 pt-3">
          <Tab tabConfig={tabHeaderConfig} onChange={handleChangeTab} value={focusedTabIndex} />
        </Grid>
        <Grid item xs={12}>
          <Divider className="m-0" thickness="0.5px" />
        </Grid>
        <ItemListWrapper item container justify="center">
          {productSKUs ? (
            productSKUs.map((productSKU: ProductSKUType, index: number) => {
              const { id, productCode, productType, price, digitalProductInventories } = productSKU;
              const isExpanded = expandedIndexList.some((expandedIndex: number) => expandedIndex === index);
              const productSKUVariationText = getProductSKUVariationList(productType) || [];
              const variations =
                productSKUVariationText.reduce((result, variationText, index) => {
                  const lastIndex = productSKUVariationText.length - 1;
                  return `${result}${variationText}${index !== lastIndex ? ", " : ""}`;
                }, "") || productName;

              const digitalProductInventory = digitalProductInventories.find(
                ({ status }: DigitalProductInventory) => status === tabHeaderConfig.header[focusedTabIndex].id,
              );

              return (
                <Grid key={id} item xs={12} className="itemWrapper p-4">
                  <DigitalContentSKU
                    id={id}
                    productCode={productCode}
                    variations={variations}
                    price={price}
                    amount={digitalProductInventory?.inventory || 0}
                    isInfoExpanded={isExpanded}
                    onClickExpand={() => {
                      handleToggleExpandContent(index);
                    }}
                    isMobile={isMobile}
                  />
                  {isExpanded && (
                    <>
                      <div className="pt-4 w-100" />
                      <DigitalProductSKUItemList
                        productId={productId}
                        productSKUId={id}
                        productCode={productCode}
                        variations={variations}
                        price={defaultPrice}
                        outputType={outputType}
                        status={tabHeaderConfig.header[focusedTabIndex].id}
                        digitalProductsQueryVariables={digitalProductsQueryVariables}
                        renderProductListItem={renderProductListItem}
                      />
                    </>
                  )}
                </Grid>
              );
            })
          ) : (
            <Typography variant="body3" className="p-4">
              {t("contentManagement.label.noDigitalProduct")}
            </Typography>
          )}
        </ItemListWrapper>
      </Grid>
    </Card>
  );
};

export default DigitalContent;
