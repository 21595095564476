import { gql } from "@apollo/client";

export const DELETE_CATEGORY = gql`
  mutation($id: ID!, $projectId: ID!) {
    deleteCategory(id: $id, projectId: $projectId) {
      id
      projectId
      name
      image
      isActive
      properties {
        name
      }
      variations {
        name
      }
      updatedAt
      isHomePageCategory
    }
  }
`;

export const UPDATE_CATEGORIES_PRIORITY = gql`
  mutation updateCategoryPriority($projectId: ID!, $categoryIds: [ID]!) {
    updateCategoryPriority(projectId: $projectId, categoryIds: $categoryIds) {
      id
      projectId
      name
      image
      isActive
      properties {
        name
      }
      variations {
        name
      }
      updatedAt
      isHomePageCategory
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation updateCategory($id: ID!, $projectId: ID!, $category: CategoryInput!) {
    updateCategory(id: $id, projectId: $projectId, category: $category) {
      id
      projectId
      name
      image
      isActive
      properties {
        name
      }
      variations {
        name
      }
      updatedAt
      isHomePageCategory
    }
  }
`;

export const ADD_CATEGORY = gql`
  mutation addCategory($projectId: ID!, $category: CategoryInput!) {
    addCategory(projectId: $projectId, category: $category) {
      id
      projectId
      name
      image
      isActive
      properties {
        name
      }
      variations {
        name
      }
      updatedAt
      isHomePageCategory
    }
  }
`;

export const UPDATE_HIGHLIGHT_PRODUCTS = gql`
  mutation updateCategoryHighlightProducts($projectId: ID!, $id: ID!, $productIds: [ID!]!) {
    updateCategoryHighlightProducts(projectId: $projectId, id: $id, productIds: $productIds) {
      id
      highlightProducts {
        id
        name
        images {
          src
        }
        isCustomSKU
        defaultPrice
        productSKUs {
          price
          variableTypes {
            key
            value
          }
          inventory
          reserved
          digitalContentOptions {
            outputType
            settings {
              availability {
                type
                settings {
                  unit
                  value
                  availableAt
                  expiredAt
                }
              }
              content
            }
          }
          digitalProductInventories {
            status
            inventory
          }
        }
      }
    }
  }
`;
