import React, { FC, useState, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import { SvgIcon } from "components/Icon";
import Typography from "components/Typography";
import { IcOptions, IcDelete, IcEdit, IcError, IcCheck } from "components/SvgIcons";
import MenuList from "components/Menu/MenuList";
import ConfirmModal from "components/Modal/ConfirmModal";

import COLORS from "constants/Colors";
import { MenuButton, PreviewItemWrapper, RichMenuCardWrapper } from "./style";

type RichMenuPreviewCardPropsType = {
  title: string;
  isSelected: boolean;
  isDefault: boolean;
  onToggleActiveMenu: () => void;
  onRemoveActiveMenu: () => void;
  onClickEditRichMenu: () => void;
  children: ReactNode;
};

const RichMenuPreviewCard: FC<RichMenuPreviewCardPropsType> = (props) => {
  const { t } = useTranslation();
  const { title, isSelected, isDefault, onToggleActiveMenu, onRemoveActiveMenu, onClickEditRichMenu, children } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  const handleClickMenuButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenuList = () => {
    setAnchorEl(null);
  };

  const handleClickModalConfirmButton = () => {
    onRemoveActiveMenu();
    setIsOpenConfirmModal(false);
  };

  let popOverMenuList = [
    {
      icon: (
        <SvgIcon
          className="ml-1"
          component={isSelected ? IcError : IcCheck}
          fontSize="small"
          htmlColor={COLORS.DarkMed}
        />
      ),
      label: isSelected ? "RichMenu.action.setInactive" : "RichMenu.action.setActive",
      onClick: () => {
        onToggleActiveMenu();
        handleCloseMenuList();
      },
    },
    {
      icon: <SvgIcon className="ml-1" component={IcEdit} fontSize="small" htmlColor={COLORS.DarkMed} />,
      label: "Edit",
      onClick: () => {
        onClickEditRichMenu();
        handleCloseMenuList();
      },
    },
    {
      icon: <SvgIcon className="ml-1" component={IcDelete} fontSize="small" htmlColor={COLORS.DarkMed} />,
      label: "Delete",
      onClick: () => {
        setIsOpenConfirmModal(true);
        handleCloseMenuList();
      },
    },
  ];

  if (isDefault) {
    popOverMenuList = [popOverMenuList[0]];
  }

  return (
    <>
      <RichMenuCardWrapper isSelected={isSelected} noShadow>
        <Grid item container className="pb-2 flex-nowrap">
          <Grid item className="flex-grow-1 ellipsis">
            <Typography variant="title8" color="primary">
              {t(title)}
            </Typography>
          </Grid>
          <Grid item className="flex-grow-0">
            <MenuButton
              type="button"
              onClick={(e: React.MouseEvent<HTMLElement>) => handleClickMenuButton(e)}
              className="p-0"
            >
              <SvgIcon component={IcOptions} fontSize="small" viewBox="-16 0 24 24" htmlColor={COLORS.Primary} />
            </MenuButton>
          </Grid>
        </Grid>
        <PreviewItemWrapper isDefault={isDefault} onClick={isDefault ? () => {} : onClickEditRichMenu}>
          {children}
        </PreviewItemWrapper>
        <MenuList
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenuList}
          menuList={popOverMenuList}
        />
      </RichMenuCardWrapper>
      <ConfirmModal
        title="RichMenu.deleteModal.title"
        confirmButtonText="Delete"
        isOpen={isOpenConfirmModal}
        onClose={() => setIsOpenConfirmModal(false)}
        onClickConfirmButton={handleClickModalConfirmButton}
      />
    </>
  );
};

export default RichMenuPreviewCard;
