import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

import { BROADCAST, FACEBOOK, MARKETING_TOOLS, PROJECT } from "constants/Router";
import {
  RecurringNotificationOptInButtonMessageType,
  RecurringNotificationOptInDeliveryContextType,
  RecurringNotificationOptInFrequencyType,
  RecurringNotificationOptInInput,
} from "types/Broadcast";
import { ProjectIdType } from "types/Project";
import { notifyError, notifySuccess } from "utils/notify";

import useCreateRecurringNotiOptIn from "../hooks/useCreateRecurringNotiOptIn";
import useGetRecurringNotiOptIns from "../hooks/useGetRecurringNotiOptIns";
import useUpdateRecurringNotiOptIn from "../hooks/useUpdateRecurringNotiOptIn";
import { FacebookRequestForm, FacebookRequestFormSchema } from "../FacebookRequestForm";
import { REQUEST_CONTEXT_OPTIONS } from "../FacebookRequestForm/constants";
import { GridLoading } from "../BroadcastTable/styled";

interface NewFacebookRecurringRequestParams extends ProjectIdType {
  recurringNotificationOptInId?: string;
}

export default function NewFacebookRecurringRequest(): JSX.Element {
  const { projectId, recurringNotificationOptInId } = useParams<NewFacebookRecurringRequestParams>();
  const { t } = useTranslation();
  const history = useHistory();

  const { recurringNotiOptIns, isGetRecurringNotiOptInsLoading } = useGetRecurringNotiOptIns(projectId);
  const { createRecurringNotiOptIn, isLoadingCreateRecurrigNotiOptIn } = useCreateRecurringNotiOptIn(projectId);
  const { updateRecurringNotiOptIn, isLoadingUpdateRecurrigNotiOptIn } = useUpdateRecurringNotiOptIn(projectId);

  const isEditMode = !!recurringNotificationOptInId?.length;

  const isLoadingMutationResult = isEditMode ? isLoadingUpdateRecurrigNotiOptIn : isLoadingCreateRecurrigNotiOptIn;
  const isLoading = isLoadingMutationResult || isGetRecurringNotiOptInsLoading;

  const persistedDeliveryContextTypes = recurringNotiOptIns.reduce(
    (prev, { deliveryContextType }) => ({ ...prev, [deliveryContextType]: deliveryContextType }),
    {} as Record<RecurringNotificationOptInDeliveryContextType, RecurringNotificationOptInDeliveryContextType>,
  );

  const deliveryContextTypeOptions = REQUEST_CONTEXT_OPTIONS.map(({ label, ...rest }) => ({
    label: t(label),
    ...rest,
  }));

  const filteredDeliveryContextTypeOptions = deliveryContextTypeOptions.filter(
    ({ value }) => !persistedDeliveryContextTypes[value],
  );

  const handleClickBack = () => {
    history.goBack();
  };

  const handleSubmit = async (data: FacebookRequestFormSchema) => {
    const formatData: Omit<RecurringNotificationOptInInput, "projectId"> = {
      name: data.name,
      deliveryContextType: data.deliveryContextType as RecurringNotificationOptInDeliveryContextType,
      messageTitle: data.messageTitle,
      messageImageUrl: data.messageImageUrl,
      frequencyType: data.frequencyType as RecurringNotificationOptInFrequencyType,
      buttonMessageType: data.buttonMessageType as RecurringNotificationOptInButtonMessageType,
      isAutoRenew: data.isAutoRenew,
      invitationMessage: data.invitationMessage,
      reactionMessage: data.reactionMessage,
    };

    try {
      if (isEditMode) {
        await updateRecurringNotiOptIn(recurringNotificationOptInId, formatData);
      } else {
        await createRecurringNotiOptIn(formatData);
      }

      history.replace(`/${PROJECT}/${projectId}/${MARKETING_TOOLS}/${BROADCAST}/${FACEBOOK}`);
      notifySuccess(
        <>
          <strong className="text-center">
            {isEditMode
              ? t("broadcast.editFacebookRequestSuccess.title")
              : t("broadcast.createFacebookRequestSuccess.title")}
          </strong>
          {!isEditMode && (
            <>
              <br />
              <span className="text-center">{t("broadcast.createFacebookRequestSuccess.subtitle")}</span>
            </>
          )}
        </>,
      );
    } catch (error) {
      notifyError(
        <>
          <strong className="text-center">{t("broadcast.createFacebookRequestError.title")}</strong>
          <br />
          <span className="text-center">{t("broadcast.createFacebookRequestError.subtitle")}</span>
        </>,
      );
    }
  };

  if (isGetRecurringNotiOptInsLoading) {
    return (
      <GridLoading>
        <CircularProgress className="m-4" />
      </GridLoading>
    );
  }

  return (
    <FacebookRequestForm
      onSubmit={handleSubmit}
      recurringNotiOptIns={recurringNotiOptIns}
      onClickBack={handleClickBack}
      isEditMode={isEditMode}
      recurringNotificationOptInId={recurringNotificationOptInId}
      isLoading={isLoading}
      projectId={projectId}
      deliveryContextTypeOptions={isEditMode ? deliveryContextTypeOptions : filteredDeliveryContextTypeOptions}
    />
  );
}
