import React from "react";
import get from "lodash/get";
import { convertPriceFormat } from "utils/common";
import i18n from "utils/i18n";
import { CellProps, Row, useSortBy, useFlexLayout, useExpanded } from "react-table";
import { Typography } from "../styled";

export const tableHooks = [useSortBy, useExpanded, useFlexLayout];

export const getColumns = <T extends object>() => [
  {
    disableSortBy: true,
    Header: "SKU code",
    accessor: "productCode",
    // eslint-disable-next-line react/display-name
    Footer: (row: Row<T>) => {
      const totalRows = get(row, "state.totalRows") || 0;

      return (
        <Typography color="darkGray">
          {i18n.t("TOTAL")} {typeof totalRows === "number" ? convertPriceFormat(totalRows, 0) : totalRows} SKUs
        </Typography>
      );
    },
  },
  {
    Header: i18n.t("Product name"),
    accessor: "productName",
    disableSortBy: true,
  },
  {
    disableSortBy: true,
    Header: i18n.t("Category name"),
    accessor: "categoryName",
  },
  {
    disableSortBy: true,
    Header: i18n.t("Variation/Option"),
    accessor: "sku",
  },
  {
    disableSortBy: true,
    Header: i18n.t("Price"),
    accessor: "price",
    // eslint-disable-next-line react/display-name
    Cell: (cell: CellProps<T>) => {
      return (
        <Typography color="darkGray">
          {i18n.t("THB")} {typeof cell.value === "number" ? convertPriceFormat(cell.value) : cell.value}
        </Typography>
      );
    },
  },
  {
    disableSortBy: true,
    Header: i18n.t("Amount"),
    // eslint-disable-next-line react/display-name
    Footer: (row: Row<T>) => {
      const amount = get(row, "state.totalSoldAmount") || 0;

      return (
        <Typography color="darkGray">{typeof amount === "number" ? convertPriceFormat(amount, 0) : amount}</Typography>
      );
    },
    accessor: "amount",
    // eslint-disable-next-line react/display-name
    Cell: (cell: CellProps<T>) => {
      return (
        <Typography color="darkGray">
          {typeof cell.value === "number" ? convertPriceFormat(cell.value, 0) : cell.value}
        </Typography>
      );
    },
  },
  {
    disableSortBy: true,
    Header: i18n.t("Total"),
    // eslint-disable-next-line react/display-name
    Footer: (row: Row<T>) => {
      const totalPrice = get(row, "state.totalPrice") || 0;

      return (
        <Typography color="darkGray">
          {i18n.t("THB")} {typeof totalPrice === "number" ? convertPriceFormat(totalPrice, 0) : totalPrice}
        </Typography>
      );
    },
    accessor: "total",
    // eslint-disable-next-line react/display-name
    Cell: (cell: CellProps<T>) => {
      return (
        <Typography color="darkGray">
          {i18n.t("THB")} {typeof cell.value === "number" ? convertPriceFormat(cell.value) : cell.value}
        </Typography>
      );
    },
  },
];
