import { gql } from "@apollo/client";

export const EXPORT_INVENTORIES = gql`
  query exportInventories($projectId: ID!) {
    exportInventories(projectId: $projectId) {
      productSKUId
      productCode
      value
    }
  }
`;
