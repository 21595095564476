import styled from "styled-components";
import { CREDIT_CARD_BG } from "constants/Image";

export const Wrapper = styled.div`
  width: 362px;
  height: 225px;
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  margin: auto;
  @media screen and (max-width: 375px) {
    width: 300px;
    height: 187px;
  }
`;

export const DefaultCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;

  border-width: 2px;
  border-style: dashed;
  border-color: ${({ theme }) => theme.COLORS.LightBlue};
  box-sizing: border-box;
`;

export const InformationCardWrapper = styled.div`
  background-image: url(${CREDIT_CARD_BG});
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-top: 12px;
  padding-bottom: 24px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.COLORS.Dark};
`;

export const LoaderCardWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background-color: ${({ theme }) => theme.COLORS.LightWhite};
`;

export const LogoImg = styled.img`
  width: 40px;
`;
