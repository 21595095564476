import * as yup from "yup";
import { MAXIMUM_DIMENSION } from "constants/Product";

export const updateDimensionAndWeightSchema = yup.object().shape({
  totalWeight: yup.number().required("Please fill value"),
  productDimensionWidth: yup
    .number()
    .required("productDimensionWidth.required")
    .max(MAXIMUM_DIMENSION, "productDimensionWidth.isMaximum"),
  productDimensionLength: yup
    .number()
    .required("productDimensionLength.required")
    .max(MAXIMUM_DIMENSION, "productDimensionLength.isMaximum"),
  productDimensionHeight: yup
    .number()
    .required("productDimensionHeight.required")
    .max(MAXIMUM_DIMENSION, "productDimensionHeight.isMaximum"),
});
