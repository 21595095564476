import { ProvinceType, DistrictType, SettingsPostalCodeType } from "types/Shipping";
import { OptionsType, OptionType } from "@atlaskit/select";
import provinces from "./provinces.json";

export const provincesList: ProvinceType[] = provinces.provinces;

export const getExcludeProvinces = (settings: SettingsPostalCodeType[]) => {
  const newExcludeProvinces: string[] = [];
  settings.forEach((setting: SettingsPostalCodeType) => {
    if (setting.isActive === false) {
      newExcludeProvinces.push(setting.name);
    }
  });

  return newExcludeProvinces;
};

// Use for Province
export const getFullNameFromProvinceCode = (provinceShortName: string, isThaiLanguage: boolean) => {
  const findName = provincesList.find(({ name }) => name === provinceShortName);
  return isThaiLanguage ? findName && findName.provinceTH : findName && findName.provinceEN;
};

export const getProvinceObjectByProvinceName = (provinceName: string) => {
  return provincesList.find(({ name }) => name === provinceName) || [];
};

export const convertProvinceListToOptionFormat = (provinceList: string[], isThaiLanguage: boolean) => {
  if (provinceList && provinceList.length) {
    const newTagsArray = provinceList.map((data: string) => ({
      label: getFullNameFromProvinceCode(data, isThaiLanguage) || data,
      value: data,
    }));
    return newTagsArray as OptionsType<OptionType>;
  }
  return [];
};

export const convertDistrictObjectToOptionFormat = (districtList: DistrictType[], isThaiLanguage: boolean) => {
  if (districtList && districtList.length) {
    const newTagsArray = districtList.map((data: DistrictType) => ({
      label: `${data.pC} ${isThaiLanguage ? data.dTH : data.dEN}`,
      value: data.pC,
    }));
    return newTagsArray as OptionsType<OptionType>;
  }
  return [];
};

export const getDistrictsFromProvinceShortName = (provinceShortName: string) => {
  const foundProvince = provincesList.find(({ name }) => name === provinceShortName);
  return foundProvince && foundProvince.districts;
};

export const convertPostcodeToOptionFormat = (postcode: string, provinceShortName: string, isThaiLanguage: boolean) => {
  const districts = getDistrictsFromProvinceShortName(provinceShortName);
  const newTagsArray: { label: string; value: string }[] = [];

  if (districts) {
    const districtLabel = districts.find((district: DistrictType) => district.pC === postcode);
    if (districtLabel) {
      newTagsArray.push({
        label: `${districtLabel && districtLabel.pC} ${
          isThaiLanguage ? districtLabel && districtLabel.dTH : districtLabel && districtLabel.dEN
        }`,
        value: postcode,
      });
    }
  }

  return newTagsArray;
};

export const getPostcodeLabelFormat = (postcode: string, provinceShortName: string, isThaiLanguage: boolean) => {
  const districts = getDistrictsFromProvinceShortName(provinceShortName);
  let PostcodeLabel = "";

  if (districts) {
    const districtLabel = districts.find((district: DistrictType) => district.pC === postcode);
    if (districtLabel) {
      PostcodeLabel = `${districtLabel && districtLabel.pC} ${
        isThaiLanguage ? districtLabel && districtLabel.dTH : districtLabel && districtLabel.dEN
      }`;
    }
  }
  return PostcodeLabel;
};
