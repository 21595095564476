import styled from "styled-components";

export const Label = styled.div`
  ${({ theme }) => theme.breakpoints.up("xs")} {
    max-width: 150px;
  }
  ${({ theme }) => theme.breakpoints.up("sm")} {
    max-width: 500px;
  }
`;
