import styled from "styled-components";
import { BoxProps } from "types/RichMenu";

export const BaseBoxContainer = styled.div<{ bgImageUrl?: string }>`
  display: inline-grid;
  grid-template-columns: auto auto auto;
  width: 100%;
  height: 100%;
  background-image: ${({ bgImageUrl }) => (bgImageUrl ? `url("${bgImageUrl}")` : "none")};
  background-size: contain;
`;

export const Badge = styled.span`
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 2px;
`;

export const Box = styled.div<BoxProps>`
  border: 1px solid ${({ theme }) => theme.COLORS.DarkLight};
  background-color: ${({ bgColor }) => bgColor || "initial"};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FullWidthBox = styled.div<BoxProps>`
  border: 1px solid ${({ theme }) => theme.COLORS.DarkLight};
  background-color: ${({ bgColor }) => bgColor || "initial"};
  grid-column: 1 / span 3;
  grid-row: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WidthBox = styled.div<BoxProps>`
  border: 1px solid ${({ theme }) => theme.COLORS.DarkLight};
  background-color: ${({ bgColor }) => bgColor || "initial"};
  /* background-color: ${({ theme }) => theme.COLORS.LightGray}; */
  grid-column: 2 / span 2;
  grid-row: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BigSquareBox = styled.div<BoxProps>`
  border: 1px solid ${({ theme }) => theme.COLORS.DarkLight};
  background-color: ${({ bgColor }) => bgColor || "initial"};
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
