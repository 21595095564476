import React from "react";
import styled from "styled-components";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { Z_INDEX } from "constants/ZIndex";
import { Theme } from "types/Theme";

type TabMenuPropsType = {
  theme: Theme;
  isActive: boolean;
  isSubTab: boolean;
};

type TabHeaderPropsType = {
  theme: Theme;
  isSubTab: boolean;
};

export const TabIcon = styled.span`
  position: relative;
  top: 4px;
`;

export const NoticeBadge = styled.span`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.COLORS.Badge};
  padding: 2px;
  color: ${({ theme }) => theme.COLORS.White};
  margin-left: 6px;
`;

export const TabHeaderContainer = styled.div`
  z-index: ${Z_INDEX.TAB};
  top: 0;
`;

export const TabContainer = styled.div`
  width: 100%;
  text-transform: none;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TabMenu = styled(({ isActive, isSubTab, ...rest }) => <Tab {...rest} />).attrs(
  (props: TabMenuPropsType) => {
    const { theme, isActive, isSubTab } = props;
    return { theme, isActive, isSubTab };
  },
)`
  text-transform: none;
  min-width: fit-content;
  span {
    color: ${({ theme, isActive, isSubTab }) => (isActive && !isSubTab ? theme.COLORS.Primary : theme.COLORS.DarkGray)};
    ${({ theme }) => theme.breakpoints.up("lg")} {
      color: ${({ theme, isActive }) => (isActive ? theme.COLORS.Primary : theme.COLORS.DarkGray)};
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TabHeader = styled(({ isSubTab, ...rest }) => <Tabs {...rest} />).attrs((props: TabHeaderPropsType) => {
  const { theme, isSubTab } = props;
  return { theme, isSubTab };
})`
  box-shadow: "0 5px 10px 0 rgba(0, 0, 0, 0.03)";
  background-color: ${({ theme, isSubTab }) => (isSubTab ? theme.COLORS.LightGray : theme.COLORS.White)};
  ${({ theme }) => theme.breakpoints.up("lg")} {
    background-color: ${({ theme }) => theme.COLORS.White};
  }
`;
