import React from "react";
import { useTranslation } from "react-i18next";

import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";

import Typography from "components/Typography";
import Button from "components/Button";
import { SvgIcon } from "components/Icon";
import { IcDelete, IcPencil, IcSend } from "components/SvgIcons";

import { COLORS } from "constants/Colors";

import type { TableRecurringNotificationProps } from "./types";

const useStyles = makeStyles({
  button: {
    minWidth: "137px",
  },
  table: {
    backgroundColor: COLORS.White,
    borderColor: COLORS.LightBlue,
    borderStyle: "solid",
    borderWidth: "1px",
    minWidth: "1194px",
    tableLayout: "fixed",
  },
  tableCell: {
    borderBottom: `1px solid ${COLORS.LightBlue}`,
    color: "#42495A",
    fontSize: "0.75rem",
    fontWeight: 500,
    lineHeight: "1.33",
    boxSizing: "border-box",
  },
  tableCellHeader: {
    color: COLORS.DarkLight,
  },
  tableCellFirstColumn: {
    borderRight: `1px solid ${COLORS.LightBlue}`,
  },
  tableCellFirstColumnContent: {
    maxWidth: "320px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  tableCellBroadcastButtonColumn: {
    width: "181px",
  },
  tableCellEditButtonColumn: {
    width: "75px",
  },
  tableCellDeleteButtonColumn: {
    width: "78px",
  },
  tableContainer: {
    overflowX: "unset",
  },
});

export default function TableRecurringNotification({
  headers,
  list,
  sendingIds,
  onClickDelete,
  onClickSendBroadcast,
  onClickEdit,
  ariaLabel = "recurring notification table",
}: TableRecurringNotificationProps): JSX.Element {
  // Hooks
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} aria-label={ariaLabel}>
        <TableHead>
          <TableRow>
            {headers.map(({ id, header }, index) => (
              <TableCell
                key={id}
                className={cn(classes.tableCell, classes.tableCellHeader, {
                  [classes.tableCellFirstColumn]: index === 0,
                })}
              >
                {header}
              </TableCell>
            ))}
            <TableCell
              className={cn(classes.tableCell, classes.tableCellHeader, classes.tableCellBroadcastButtonColumn)}
            />
            <TableCell className={cn(classes.tableCell, classes.tableCellHeader, classes.tableCellEditButtonColumn)} />
            <TableCell
              className={cn(classes.tableCell, classes.tableCellHeader, classes.tableCellDeleteButtonColumn)}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map(({ id, requestName, subscriber, requestDeliveryContext, createdAt }) => {
            const isSending = sendingIds.includes(id);
            const iconColor = isSending ? COLORS.LightBlue : COLORS.Primary;

            return (
              <TableRow key={id}>
                <TableCell
                  className={cn(classes.tableCell, classes.tableCellFirstColumn, classes.tableCellFirstColumnContent)}
                >
                  {requestName}
                </TableCell>
                <TableCell className={classes.tableCell}>{subscriber}</TableCell>
                <TableCell className={classes.tableCell}>{requestDeliveryContext}</TableCell>
                {/* <TableCell className={classes.tableCell}>{broadcastFrequency}</TableCell> */}
                <TableCell className={classes.tableCell}>{createdAt}</TableCell>
                <TableCell className={cn(classes.tableCell, classes.tableCellBroadcastButtonColumn)}>
                  <Button
                    className={classes.button}
                    color="primary"
                    disableElevation
                    disabled={isSending}
                    size="small"
                    startIcon={isSending ? <SvgIcon className="mr-0" component={IcSend} fontSize="small" /> : null}
                    variant="contained"
                    onClick={() => onClickSendBroadcast(id)}
                  >
                    {sendingIds.includes(id)
                      ? t("broadcast.facebook.table.action.sending")
                      : t("broadcast.facebook.table.action.sendBroadcast")}
                  </Button>
                </TableCell>
                <TableCell className={cn(classes.tableCell, classes.tableCellEditButtonColumn)}>
                  <IconButton
                    aria-label="edit"
                    className="p-1"
                    disabled={isSending}
                    size="small"
                    onClick={() => onClickEdit(id)}
                  >
                    <Typography variant="body1" color={iconColor}>
                      <IcPencil />
                    </Typography>
                  </IconButton>
                </TableCell>
                <TableCell className={cn(classes.tableCell, classes.tableCellDeleteButtonColumn)}>
                  <IconButton
                    aria-label="delete"
                    className="p-1"
                    disabled={isSending}
                    size="small"
                    onClick={() => onClickDelete(id)}
                  >
                    <Typography variant="body1" color={iconColor}>
                      <SvgIcon className="mr-0" component={IcDelete} fontSize="large" />
                    </Typography>
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
