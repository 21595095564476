import { ApolloError, useMutation, useQuery } from "@apollo/client";
import get from "lodash/get";

import { DEEPLE_PAY_PAYMENT_CHANNEL } from "graphql/deeplePay/query";
import { UPDATE_DEEPLE_PAY_PAYMENT_CHANNEL } from "graphql/deeplePay/mutation";
import { useTranslation } from "react-i18next";
import { DeeplePayPaymentChannelConfigurationInput } from "types/DeeplePay";
import { notifyError } from "utils/notify";

const usePaymentChannel = (projectId: string) => {
  const { t } = useTranslation();

  const { data, loading: deeplePayLoading, refetch } = useQuery(DEEPLE_PAY_PAYMENT_CHANNEL, {
    variables: { projectId },
  });
  const [updateChannel, { loading: updatePaymentChannelLoading }] = useMutation(UPDATE_DEEPLE_PAY_PAYMENT_CHANNEL, {
    onError: (error: ApolloError) => {
      const gqlErrorMessage = error?.graphQLErrors?.[0]?.message;
      notifyError(t(`deeplePay.updatePaymentConfig.error.${gqlErrorMessage}`));
    },
  });

  const updatePaymentChannel = async (projectId: string, input: DeeplePayPaymentChannelConfigurationInput) => {
    await updateChannel({
      variables: {
        projectId,
        deeplePayPaymentChannelConfigurationInput: input,
      },
    });

    await refetch();
  };

  return {
    paymentChannelData: get(data, "deeplePayPaymentChannelConfigurations"),
    deeplePayLoading,
    updatePaymentChannel,
    updatePaymentChannelLoading,
  };
};

export default usePaymentChannel;
