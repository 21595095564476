import { useState, useCallback } from "react";
import { OPENING_HOURS } from "constants/Branch";
import { UpsertBranch } from "types/Branch";
import scrollToElementId from "utils/common/scrollToElementId";
import { NEW_BRANCH_ID } from "../../../../config/store";

const useAddBranch = (projectId: string) => {
  const [newBranch, setNewBranch] = useState<UpsertBranch>();

  const addNewBranch = useCallback(
    (callback?: Function) => {
      if (newBranch) {
        scrollToElementId(NEW_BRANCH_ID);

        return;
      }

      setNewBranch({
        projectId,
        name: "",
        address: "",
        phoneNumber: "",
        location: "",
        district: "",
        subDistrict: "",
        province: "",
        postalCode: "",
        latitude: undefined,
        longitude: undefined,
        isActive: true,
        isAvailableForPickUp: false,
        isAvailableForLalamove: false,
        openingHours: OPENING_HOURS,
      });

      setTimeout(() => {
        scrollToElementId(NEW_BRANCH_ID);

        if (callback) {
          callback();
        }
      }, 200);
    },
    [newBranch, projectId],
  );

  const removeNewBranch = useCallback(() => {
    setNewBranch(undefined);
  }, []);

  return {
    newBranch,
    addNewBranch,
    removeNewBranch,
  };
};

export default useAddBranch;
