import { gql } from "@apollo/client";

export const LIVE_CRAWLERS = gql`
  query liveCrawlers($projectId: ID!, $offset: Int, $limit: Int, $filter: LiveCrawlerFilter) {
    liveCrawlers(projectId: $projectId, offset: $offset, limit: $limit, filter: $filter) {
      results {
        id
        title
        postId
        platform
        isActive
        numberOfReach
        numberOfEngagement
        newOrderConversionRate
        orderPaidConversionRate
        orderPaidConversionTotal
        updatedAt
        liveStatus
      }
      total
    }
  }
`;

export const COMMENT_CRAWLERS = gql`
  query commentCrawlers($projectId: ID!, $page: Int, $pageSize: Int, $filter: CommentCrawlerFilterInput) {
    commentCrawlers(projectId: $projectId, page: $page, pageSize: $pageSize, filter: $filter) {
      results {
        createdAt
        id
        isActive
        isWatchedAllComments
        newOrderConversionRate
        numberOfEngagement
        numberOfReach
        orderPaidConversionRate
        orderPaidConversionTotal
        platform
        postId
        projectId
        replyPostCommentMessage
        reservedWords
        title
        type
        updatedAt
        responseMessage {
          type
          value
        }
      }
      total
    }
  }
`;

export const COMMENT_CRAWLER_BY_ID = gql`
  query commentCrawlerById($projectId: ID!, $commentCrawlerId: ID!) {
    commentCrawlerById(projectId: $projectId, commentCrawlerId: $commentCrawlerId) {
      createdAt
      id
      isActive
      isWatchedAllComments
      newOrderConversionRate
      numberOfEngagement
      numberOfReach
      orderPaidConversionRate
      orderPaidConversionTotal
      platform
      postId
      projectId
      replyPostCommentMessage
      reservedWords
      title
      type
      updatedAt
      responseMessage {
        type
        value
      }
    }
  }
`;

export const LIVE_CRAWLER_BY_ID = gql`
  query liveCrawler($projectId: ID!, $liveCrawlerId: ID!) {
    liveCrawler(projectId: $projectId, liveCrawlerId: $liveCrawlerId) {
      createdAt
      id
      isActive
      isIncludedAllProducts
      liveStatus
      newOrderConversionRate
      numberOfEngagement
      numberOfReach
      orderPaidConversionRate
      orderPaidConversionTotal
      platform
      postId
      projectId
      title
      updatedAt
      videoId
      productSKUs {
        id
        cfCode
        price
        productId
        productCode
        inventory
        reserved
        selected
        deleted
        isFree
        product {
          id
          name
          type
          cfCode
          images {
            src
          }
          categories {
            id
            name
            image
          }
        }
        image {
          src
        }
        productType {
          value
          key {
            key
          }
        }
      }
    }
  }
`;

export const ADS_POSTS = gql`
  query adsPosts($projectId: ID!, $limit: Int, $after: String, $type: FacebookPostType) {
    adsPosts(projectId: $projectId, limit: $limit, after: $after, type: $type) {
      data {
        id
        message
        createdTime
        attachments {
          data {
            mediaType # enum { video photo }
            media {
              image {
                src
                width
                height
              }
            }
          }
        }
      }
      paging {
        cursors {
          before
          after
        }
        previous
        next
      }
    }
  }
`;

export const SCHEDULED_POSTS = gql`
  query scheduledPosts($projectId: ID!, $limit: Int, $after: String, $type: FacebookPostType) {
    scheduledPosts(projectId: $projectId, limit: $limit, after: $after, type: $type) {
      data {
        id
        message
        createdTime
        attachments {
          data {
            mediaType # enum { video photo }
            media {
              image {
                src
                width
                height
              }
            }
          }
        }
      }
      paging {
        cursors {
          before
          after
        }
        previous
        next
      }
    }
  }
`;

export const POSTS = gql`
  query posts($projectId: ID!, $limit: Int, $after: String, $type: FacebookPostType) {
    posts(projectId: $projectId, limit: $limit, after: $after, type: $type) {
      data {
        id
        message
        createdTime
        attachments {
          data {
            mediaType # enum { video photo }
            media {
              image {
                src
                width
                height
              }
            }
          }
        }
        status
        isPublished
        videoId
      }
      paging {
        cursors {
          before
          after
        }
        previous
        next
      }
    }
  }
`;

export const POST = gql`
  query post($projectId: ID!, $postId: String!, $type: FacebookPostType) {
    post(projectId: $projectId, postId: $postId, type: $type) {
      id
      message
      createdTime
      attachments {
        data {
          mediaType # enum { video photo }
          media {
            image {
              src
              width
              height
            }
          }
        }
      }
      status
      isPublished
      videoId
    }
  }
`;

export const FACEBOOK_PAGE = gql`
  query page($projectId: ID!) {
    page(projectId: $projectId) {
      id
      name
      picture
    }
  }
`;
