import * as yup from "yup";

import { VAT_OPTIONS_TYPE } from "types/Vat";
import { PHONE_NUMBER_REGEX, POSTCODE_REGEX } from "constants/Address";

const schema = yup.object().shape({
  option: yup.string(),
  isShowTaxInfo: yup.boolean(),
  taxId: yup.string().when("option", (option: string) => {
    if (option === VAT_OPTIONS_TYPE.NONE) {
      return yup.string();
    }
    return yup.string().when("isShowTaxInfo", (isShowTaxInfo: boolean) => {
      if (isShowTaxInfo) {
        return yup
          .string()
          .required("Please enter taxpayer identification no.")
          .matches(/^\d{13}$/gi, "Tax ID must be 13 digit characters");
      }

      return yup.string();
    });
  }),
  companyName: yup.string().when("option", (option: string) => {
    if (option === VAT_OPTIONS_TYPE.NONE) {
      return yup.string().trim();
    }
    return yup.string().when("isShowTaxInfo", (isShowTaxInfo: boolean) => {
      if (isShowTaxInfo) {
        return yup.string().trim().required("Please enter company name");
      }

      return yup.string().trim();
    });
  }),

  address: yup.string().when("option", (option: string) => {
    if (option === VAT_OPTIONS_TYPE.NONE) {
      return yup.string().trim();
    }
    return yup.string().when("isShowTaxInfo", (isShowTaxInfo: boolean) => {
      if (isShowTaxInfo) {
        return yup.string().trim().required("Please fill streetAddress");
      }

      return yup.string().trim();
    });
  }),

  district: yup.string().when("option", (option: string) => {
    if (option === VAT_OPTIONS_TYPE.NONE) {
      return yup.string().trim();
    }
    return yup.string().when("isShowTaxInfo", (isShowTaxInfo: boolean) => {
      if (isShowTaxInfo) {
        return yup.string().trim().required("Please fill district");
      }

      return yup.string().trim();
    });
  }),

  subDistrict: yup.string().when("option", (option: string) => {
    if (option === VAT_OPTIONS_TYPE.NONE) {
      return yup.string().trim();
    }
    return yup.string().when("isShowTaxInfo", (isShowTaxInfo: boolean) => {
      if (isShowTaxInfo) {
        return yup.string().trim().required("Please fill subDistrict");
      }

      return yup.string().trim();
    });
  }),

  phoneNumber: yup.string().when("option", (option: string) => {
    if (option === VAT_OPTIONS_TYPE.NONE) {
      return yup.string().trim();
    }
    return yup.string().when("isShowTaxInfo", (isShowTaxInfo: boolean) => {
      if (isShowTaxInfo) {
        return yup
          .string()
          .required("Please fill phoneNumber")
          .matches(PHONE_NUMBER_REGEX, "Phone number should be 4, 9, 10 digits");
      }

      return yup.string().trim();
    });
  }),

  province: yup.string().when("option", (option: string) => {
    if (option === VAT_OPTIONS_TYPE.NONE) {
      return yup.string().trim();
    }
    return yup.string().when("isShowTaxInfo", (isShowTaxInfo: boolean) => {
      if (isShowTaxInfo) {
        return yup.string().trim().required("Please fill province");
      }

      return yup.string().trim();
    });
  }),

  postcode: yup.string().when("option", (option: string) => {
    if (option === VAT_OPTIONS_TYPE.NONE) {
      return yup.string().trim();
    }
    return yup.string().when("isShowTaxInfo", (isShowTaxInfo: boolean) => {
      if (isShowTaxInfo) {
        return yup
          .string()
          .required("Please fill postalCode")
          .matches(POSTCODE_REGEX, "Postcode should be number 5 digits");
      }

      return yup.string().trim();
    });
  }),
});

export default schema;
