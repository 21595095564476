import { useContext } from "react";
import { useMutation, ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { DEFAULT_SHIPPING_METHOD } from "graphql/shipping/query";
import { UPDATE_SHIPPING_METHOD } from "graphql/shipping/mutation";
import { ShippingMethod, ShippopStoreSetting, CALCULATE, METHOD_TYPE } from "types/Shipping";
import { ImageUrl } from "types/Image";
import { GuidelineContext } from "utils/context";
import { notifyError, notifySuccess } from "utils/notify";
import { removeTypenameField } from "utils/common";
import { addGoogleTagEvent } from "services/GoogleTagEvent";
import { GTM_EVENT } from "constants/GTM";

type UseUpdateShippingMethodType = {
  projectId: string;
  onUpdateCompleted: () => void;
  onUpdateFailed: (error: ApolloError) => void;
  defaultShippingData: ShippingMethod[];
  defaultImage: string;
  defaultShippopData: ShippopStoreSetting;
};

const useUpdateShippingMethod = ({
  projectId,
  onUpdateCompleted,
  onUpdateFailed,
  defaultShippingData,
  defaultImage,
  defaultShippopData,
}: UseUpdateShippingMethodType) => {
  const { t } = useTranslation();
  const { setGuidelineCompletedStepCount } = useContext(GuidelineContext);

  const [updateDefaultShippingMethod, { loading }] = useMutation(UPDATE_SHIPPING_METHOD, {
    update(cache, { data: { updateDefaultShippingMethod: updatedDefaultShippingMethod } }) {
      const cacheDefaultShippingMethod = cache.readQuery({
        query: DEFAULT_SHIPPING_METHOD,
        variables: {
          projectId,
        },
      });

      if (cacheDefaultShippingMethod) {
        cache.writeQuery({
          query: DEFAULT_SHIPPING_METHOD,
          variables: {
            projectId,
          },
          data: {
            defaultShippingMethod: updatedDefaultShippingMethod,
          },
        });
      }

      setGuidelineCompletedStepCount(projectId);
    },
    onError: (error: ApolloError) => {
      notifyError(`${t(error.message || "Cannot Updated Shipping Method")}`, { autoClose: 3000 });
      onUpdateFailed(error);
    },
    onCompleted: () => {
      notifySuccess(t("Shipping Method Updated"));
      addGoogleTagEvent(GTM_EVENT.ADD_SHIPPING);
      onUpdateCompleted();
    },
  });

  const handleUpdateDefaultShippingMethod = (
    newShippingData: ShippingMethod[],
    imageUrl: string,
    newShippopData: ShippopStoreSetting,
  ) => {
    const shippingDataWithoutTypename = removeTypenameField(newShippingData);
    const shippopDataWithoutTypename = removeTypenameField(newShippopData);

    updateDefaultShippingMethod({
      variables: {
        projectId,
        shippingMethod: {
          image: imageUrl,
          shipping: shippingDataWithoutTypename,
          shippop: shippopDataWithoutTypename,
        },
      },
    });
  };

  const handleChangeImage = (imageUrls: ImageUrl[]) => {
    if (imageUrls.length) {
      const [{ publicUrl }] = imageUrls;

      handleUpdateDefaultShippingMethod(defaultShippingData, publicUrl, defaultShippopData);
    }
  };

  const handleToggleSchedulePickUp = (isDisabled: boolean, focusedIndex: number) => {
    const newShippingData = defaultShippingData.map((shippingItemData: ShippingMethod, index: number) => {
      if (index === focusedIndex) {
        const isDisabledSchedulePickUp = isDisabled;

        return {
          ...shippingItemData,
          isDisabledSchedulePickUp,
        };
      }
      return shippingItemData;
    });

    handleUpdateDefaultShippingMethod(newShippingData, defaultImage, defaultShippopData);
  };

  const handleToggleEnableShippingMethod = (isDisabled: boolean, focusedIndex: number) => {
    const newShippingData = defaultShippingData.map((shippingItemData: ShippingMethod, index: number) => {
      if (index === focusedIndex) {
        if (shippingItemData.methodType === METHOD_TYPE.PICK_UP) {
          const isDisabledSchedulePickUp = !isDisabled ? shippingItemData.isDisabledSchedulePickUp : false;
          return {
            ...shippingItemData,
            disabled: isDisabled,
            isDisabledSchedulePickUp,
          };
        }

        return {
          ...shippingItemData,
          disabled: isDisabled,
        };
      }
      return shippingItemData;
    });

    handleUpdateDefaultShippingMethod(newShippingData, defaultImage, defaultShippopData);
  };

  const handleEditShippingMethod = (
    newFocusedShippingData: ShippingMethod,
    newShippopData: ShippopStoreSetting,
    focusedIndex: number,
  ) => {
    const { calculateType } = newFocusedShippingData;

    const newShippingData = defaultShippingData.map((shippingItemData: ShippingMethod, index: number) =>
      index === focusedIndex ? newFocusedShippingData : shippingItemData,
    );

    const isShippopCalculateType = calculateType === CALCULATE.SHIPPOP;
    const updatedShippopData = isShippopCalculateType ? newShippopData : defaultShippopData;

    handleUpdateDefaultShippingMethod(newShippingData, defaultImage, updatedShippopData);
  };

  return {
    handleUpdateDefaultShippingMethod,
    handleChangeImage,
    handleToggleEnableShippingMethod,
    handleToggleSchedulePickUp,
    handleEditShippingMethod,
    isUpdating: loading,
  };
};

export default useUpdateShippingMethod;
