import { gql } from "@apollo/client";

export const SYNC_INTEGRATION = gql`
  mutation upsertIntegration(
    $projectId: ID!
    $syncMethodType: IntegrationSyncMethodType!
    $payload: IntegrationInput!
  ) {
    upsertIntegration(projectId: $projectId, syncMethodType: $syncMethodType, payload: $payload) {
      id
      projectId
      type
      data {
        apiKey
        apiSecret
        storeName
        warehouseName
        warehouseCode
      }
    }
  }
`;

export const DELETE_INTEGRATION = gql`
  mutation deleteIntegration($projectId: ID!, $integrationId: ID!) {
    deleteIntegration(projectId: $projectId, integrationId: $integrationId) {
      id
    }
  }
`;

export const RESYNC_INTEGRATION = gql`
  mutation reSyncIntegration($projectId: ID!, $syncMethodType: IntegrationSyncMethodType) {
    reSyncIntegration(projectId: $projectId, syncMethodType: $syncMethodType) {
      id
      projectId
      type
      data {
        apiKey
        apiSecret
        storeName
        warehouseName
        warehouseCode
      }
    }
  }
`;
