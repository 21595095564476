import styled from "styled-components";
import Grid from "components/Grid";

export const CardWrapper = styled(Grid)`
  color: ${({ theme }) => theme.COLORS.DarkMed};
  .title {
    color: ${({ theme }) => theme.COLORS.Dark};
  }
  .consignmentType {
    min-width: 200px;
    min-height: 200px;
    max-width: 220px;
    max-height: 220px;
  }

  &:hover {
    color: ${({ theme }) => theme.COLORS.Primary};
    .title {
      color: ${({ theme }) => theme.COLORS.Primary};
    }
    .consignmentType {
      border-color: ${({ theme }) => theme.COLORS.Primary} !important;
      background-color: ${({ theme }) => theme.COLORS.PrimaryNude} !important;
    }
  }
`;
