import React from "react";
import styled from "styled-components";

import COLORS from "constants/Colors";
import Typography from "components/Typography";
import { IcCOD } from "components/SvgIcons";
import { SvgIcon } from "components/Icon";
import i18n from "utils/i18n";

const ThaiBahtAdornmentWrapper = styled.div`
  background: ${({ theme }) => `${theme.COLORS.Light}`};
  padding: 15px 18px;
  margin: 0px 10px 0px -14px;
  border-radius: 8px 0px 0px 8px;
  border-right: ${({ theme }) => ` solid 1px  ${theme.COLORS.LightBlue}`};
`;

export const ThaiBahtAdornment = () => {
  return (
    <ThaiBahtAdornmentWrapper>
      <Typography variant="body3" color={COLORS.DarkMed}>
        ฿
      </Typography>
    </ThaiBahtAdornmentWrapper>
  );
};

const CODBadgeWrapper = styled.div`
  width: fit-content;
  background-image: linear-gradient(#99b4ff, #7ea0ff);
  border-radius: 5px;
  padding: 6px 12px;
  margin: 8px;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin: 0;
  }
`;

export const CODBadge = () => {
  return (
    <CODBadgeWrapper>
      <SvgIcon component={IcCOD} fontSize="small" htmlColor={COLORS.White} className="d-inline" />
      <Typography variant="title8" color="white" className="d-inline pl-2">
        {i18n.t("shipping.badgeOnlyCOD")}
      </Typography>
    </CODBadgeWrapper>
  );
};
