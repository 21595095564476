export enum ERRORS {
  NO_STORE_ADDRESS = "NO_STORE_ADDRESS",
  NO_DELIVERY_SETTING = "NO_DELIVERY_SETTING",
}

const mapErrorMessage = (error: string) => {
  switch (error) {
    case ERRORS.NO_STORE_ADDRESS:
      return "Please set Address in store setting";
    case ERRORS.NO_DELIVERY_SETTING:
      return "Please enable the delivery period";
    default:
      return "";
  }
};

export default mapErrorMessage;
