import { useMutation } from "@apollo/client";

import { UPDATE_RECURRING_NOTIFICATION_OPT_IN } from "graphql/broadcast/mutation";
import { RECURRING_NOTIFICATION_OPT_INS } from "graphql/broadcast/query";
import {
  RecurringNotificationOptInInput,
  RecurringNotificationOptInsType,
  UpdateRecurringNotificationOptInInputVariables,
} from "types/Broadcast";

export default function useUpdateRecurringNotiOptIn(projectId: string) {
  const [mutate, { data, loading, error }] = useMutation<
    RecurringNotificationOptInsType,
    UpdateRecurringNotificationOptInInputVariables
  >(UPDATE_RECURRING_NOTIFICATION_OPT_IN);

  const updateRecurringNotiOptIn = (
    recurringNotificationOptInId: string,
    recurringNotificationOptIn: Omit<RecurringNotificationOptInInput, "projectId">,
  ) => {
    const variables = {
      projectId,
      recurringNotificationOptInId,
      recurringNotificationOptIn: {
        ...recurringNotificationOptIn,
        projectId,
      },
    };

    return mutate({ variables, refetchQueries: [{ query: RECURRING_NOTIFICATION_OPT_INS, variables }] });
  };

  return {
    updateRecurringNotiOptInError: error,
    isLoadingUpdateRecurrigNotiOptIn: loading,
    updateRecurringData: data,
    updateRecurringNotiOptIn,
  };
}
