import { toast } from "react-toastify";
import { useMutation, ApolloError } from "@apollo/client";
import produce from "immer";
import { useTranslation } from "react-i18next";

import { removeTypenameField } from "utils/common";
import { SAVE_PROJECT_SETTING } from "graphql/setting/mutation";
import { GeneralSettingFormType, ProjectConfigurationType, ProjectSettingType, WorkingHour } from "types/Setting";
import { addGoogleTagEvent } from "services/GoogleTagEvent";
import { GTM_EVENT } from "constants/GTM";

type UseUpdateSettingPropsType = {
  projectId: string;
  configurationData: ProjectConfigurationType;
  onUpdateComplete: (data?: ProjectSettingType) => void;
};

const useUpdateSetting = (props: UseUpdateSettingPropsType) => {
  const { t } = useTranslation();
  const { projectId, configurationData, onUpdateComplete } = props;

  const [saveGeneralSetting] = useMutation(SAVE_PROJECT_SETTING, {
    onError: (error: ApolloError) => {
      toast.error(`${t("Update failed!")}, ${error}`, {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 3000,
      });
    },
    onCompleted: (data?: { updateProject: ProjectSettingType }) => {
      toast.success(t("Update successfully"), {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        autoClose: 1000,
        onClose: () => {},
      });
      addGoogleTagEvent(GTM_EVENT.AI_SETTING);
      onUpdateComplete(data?.updateProject);
    },
  });

  const handleSubmitForm = (formData: GeneralSettingFormType) => {
    const {
      botTimer,
      isBotTimerActive,
      botGender,
      searchProductsOrder,
      isOpenWebviewOnSelectProductEnabled,
      isProcessingPostbackEnabled,
      adminWorkingHours,
      isChatbotEnabled,
    } = formData;

    const projectConfiguration = removeTypenameField(configurationData) as ProjectConfigurationType;
    const newProjectConfiguration = produce(projectConfiguration, (draft) => {
      draft.botFlowBehavior = produce(draft.botFlowBehavior, (draft) => {
        // #region Setting both category and product when there is changes to web-shopping flag
        draft.category.isHomePageWebviewCardEnabled = isOpenWebviewOnSelectProductEnabled;
        draft.category.isOpenWebviewWhenOverThresholdEnabled = isOpenWebviewOnSelectProductEnabled;
        draft.product.isOpenWebviewOnSelectProductEnabled = isOpenWebviewOnSelectProductEnabled;
        // #endregion

        draft.postback.isProcessingPostbackEnabled = isProcessingPostbackEnabled;
      });
    });

    const variables = {
      projectId,
      projectInput: {
        botGender,
        botTimer: {
          isOpen: isBotTimerActive,
          timeToResume: botTimer,
        },
        searchProductsOrder,
        configuration: newProjectConfiguration,
        adminWorkingHours: {
          isActive: false,
          workingHours: [] as WorkingHour[],
        },
        isChatbotEnabled: true,
      },
    };

    variables.projectInput.isChatbotEnabled = isChatbotEnabled;

    variables.projectInput.adminWorkingHours = {
      ...adminWorkingHours,
      workingHours: [
        ...adminWorkingHours.workingHours.map(({ time, day, isActive }) => {
          const filteredTimes = time.map(({ start, end }) => ({ start, end }));

          return { day, isActive, time: filteredTimes };
        }),
      ],
    };

    saveGeneralSetting({ variables });
  };

  return {
    handleSubmitForm,
  };
};

export default useUpdateSetting;
