// This component that is imported from this package => https://github.com/bonafideduck/react-highlight-within-textarea
// It under beta test so I copy and fix their code to work with our project.
// I will convert to typescript later

import React, { useState, forwardRef, useRef, useMemo } from "react";

import PropTypes from "prop-types";
import { Editor, EditorState, ContentState } from "draft-js";
import createDecorator from "./createDecorator.js";

function _inheritsLoose(subClass, superClass) {
  subClass.prototype = Object.create(superClass.prototype);
  subClass.prototype.constructor = subClass;
  _setPrototypeOf(subClass, superClass);
}

function _setPrototypeOf(o, p) {
  _setPrototypeOf =
    Object.setPrototypeOf ||
    function _setPrototypeOf(o, p) {
      o.__proto__ = p;
      return o;
    };
  return _setPrototypeOf(o, p);
}

const HighlightWithinTextareaFunc = forwardRef(function (props, fwdRef) {
  const { placeholder, highlight, onChange, onFocus, onBlur, value } = props;

  const _React$useState = React.useState();
  const forceUpdate = _React$useState[1];

  const ref = useRef({}).current;

  if (!ref.current) {
    // First time
    ref.current = EditorState.createWithContent(ContentState.createFromText(value));
    ref.pending = null;
  } else if (ref.pending && value === ref.pending.getCurrentContent().getPlainText()) {
    // The pending value was accepted by parent.
    ref.current = ref.pending;
    ref.pending = null;
  } else if (ref.current && value === ref.current.getCurrentContent().getPlainText()) {
    // The parent blocked the onChange()
    ref.pending = null;
  } else {
    // The parent chose an entirely new value. Update previous.
    const contentState = ContentState.createFromText(value);

    ref.current = EditorState.push(ref.current, contentState, "insert-fragment");
    ref.pending = null;
  }

  const contentState = ref.current.getCurrentContent();
  const decorator = useMemo(
    function () {
      return createDecorator(contentState, highlight, value);
    },
    [contentState, highlight, value],
  );
  ref.current = EditorState.set(ref.current, {
    decorator,
  });

  const onDraftChange = function onDraftChange(nextEditorState) {
    const changeType = nextEditorState.getLastChangeType();

    if (changeType === null) {
      // This is a non-textual change.  Just save the new state.
      ref.current = nextEditorState;
      forceUpdate({});
      return;
    }

    const nextValue = nextEditorState.getCurrentContent().getPlainText();
    ref.pending = nextEditorState;
    onChange(nextValue, changeType);
  };

  return /* #__PURE__ */ React.createElement(Editor, {
    editorState: ref.current,
    onFocus,
    onBlur,
    onChange: onDraftChange,
    placeholder,
    ref: fwdRef,
  });
});
/*
 * For some reason, exporting a FunctionComponent
 * doesn't work when importing in codepen.io, so wrap
 * it in a class component.
 */

const HighlightWithinTextarea = /* #__PURE__ */ (function (_React$Component) {
  _inheritsLoose(HighlightWithinTextarea, _React$Component);

  function HighlightWithinTextarea(props) {
    let _this;

    _this = _React$Component.call(this, props) || this;
    _this.state = {
      date: new Date(),
    };
    return _this;
  }

  const _proto = HighlightWithinTextarea.prototype;

  _proto.render = function render() {
    return /* #__PURE__ */ React.createElement(HighlightWithinTextareaFunc, this.props);
  };

  return HighlightWithinTextarea;
})(React.Component);

HighlightWithinTextarea.propTypes =
  process.env.NODE_ENV !== "production"
    ? {
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        value: PropTypes.string.isRequired,
        highlight: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.array,
          PropTypes.instanceOf(RegExp),
          PropTypes.object,
          PropTypes.func,
        ]),
        placeholder: PropTypes.string,
      }
    : {};
HighlightWithinTextarea.defaultProps = {
  highlight: null,
  placeholder: "",
};
export default HighlightWithinTextarea;
