import React, { FC } from "react";
import styled from "styled-components";
import ButtonBase from "components/ButtonBase";
import { LineMenuTemplate } from "types/RichMenu";

type OptionProps = {
  onClick?: (value?: LineMenuTemplate) => void;
  value?: LineMenuTemplate;
  isActive?: boolean;
};

const StyledButton = styled(ButtonBase)<{ isActive?: boolean }>`
  padding: 16px;
  border: ${({ isActive, theme }) => (isActive ? `1px solid ${theme.COLORS.Primary}` : `1px solid white`)};
  border-radius: 8px;
  background-color: ${({ isActive, theme }) => (isActive ? theme.COLORS.PrimaryNude : "white")};
  width: 150px;
  height: 100px;
  box-sizing: content-box;

  &:hover {
    border: ${({ theme }) => `1px solid ${theme.COLORS.Primary}`};
    background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  }
`;

const Option: FC<OptionProps> = ({ onClick, children, isActive, value }) => {
  return (
    <StyledButton onClick={() => onClick?.(value)} isActive={isActive}>
      {children}
    </StyledButton>
  );
};

export default Option;
