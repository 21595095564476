import { PAYMENT_METHOD_TYPES } from "constants/Payment";
import { ProductOptionType, ProductType as ProductTypeValue, DigitalContentOptions } from "types/Product";
import { PromotionType } from "types/Promotion";
import { CustomerType } from "types/Customer";
import { BranchType } from "types/Branch";
import { METHOD_TYPE, ShippingQuotationType } from "types/Shipping";

export enum OrderState {
  ABANDONED = "ABANDONED",
  ALL = "ALL",
  CANCELLED = "CANCELLED",
  COD_PICKED_UP = "COD_PICKED_UP",
  COD_PREPARE_TO_SHIP = "COD_PREPARE_TO_SHIP",
  COD_REJECTED = "COD_REJECTED",
  COMPLETED = "COMPLETED",
  ORDER_PLACED = "ORDER_PLACED",
  PENDING_FOR_SHIPPING_COST = "PENDING_FOR_SHIPPING_COST",
  PREPARE_TO_SHIPPING = "PREPARE_TO_SHIPPING",
  SHIPPED = "SHIPPED",
  WAIT_FOR_PAYMENT_APPROVE = "WAIT_FOR_PAYMENT_APPROVE",
  VOIDED = "VOIDED",
  REFUNDED = "REFUNDED",
}

export type DeliveryAddressType = {
  firstName: string;
  lastName: string;
  streetAddress: string;
  streetAddress2?: string;
  subDistrict: string;
  district: string;
  province: string;
  postalCode: string;
  phoneNumber: string;
  email?: string;
  note?: string;
};

export type DeliveryAddressInputType = {
  firstName: string;
  lastName: string;
  streetAddress: string;
  streetAddress2?: string;
  subDistrict: string;
  district: string;
  province: string;
  postalCode: string;
  phoneNumber: string;
  email?: string;
  deliveryNote?: string;
};

export type ProductImageType = {
  src: string;
};

export type ProductTypeType = {
  value: string;
};

export type ProductSKUInOrderType = {
  id: string;
  isFree: boolean;
  image: ProductImageType;
  amount: number;
  options?: ProductOptionType[];
  productId?: string;
  productCode: string;
  productType: Array<ProductTypeType>;
  productPrice: number;
  digitalContentOptions?: DigitalContentOptions;
};

export type ProductInProductType = { id: string; name: string; images: ProductImageType[]; type: ProductTypeValue };

export type GroupProductType = {
  orderId: string;
  product: ProductInProductType;
  image: ProductImageType;
  productSkuList: ProductSKUInOrderType[];
  note?: string;
};

export type ProductType = {
  product: ProductInProductType;
  id: string;
  amount: number;
  productCode: string;
  productType: Array<ProductTypeType>;
  productPrice: number;
  image: ProductImageType | string;
  options?: ProductOptionType[];
  price?: string | number;
  isFree: boolean;
  note?: string;
};

export type ProductInOrderType = {
  type: ProductTypeValue;
  productSKU: ProductSKUInOrderType;
  product: ProductInProductType;
  id: string;
  isFree: boolean;
  amount: number;
  options?: ProductOptionType[];
  productCode: string;
  productType: Array<ProductTypeType>;
  productPrice: number;
  image: ProductImageType | string;
  note?: string;
  price?: string | number;
  isAddedByAdmin: boolean;
};

export type PaySlipDataType = {
  additionalDiscount?: number;
  discountShippingPrice?: number;
  discountTotalPrice?: number;
  grandTotal: number;
  productPromotions?: PromotionType[];
  shippingMethod?: string;
  shippingPrice?: number;
  shippingPromotions?: PromotionType[];
  totalPrice: number;
  vatOptions?: string;
  vatPrice?: number;
  vatValue?: number;
};

export type ShippedDetail = {
  detail: Array<{
    carrierName: string;
    deliveryDate?: string;
    trackingNumber: string;
    otherCarrierName?: string;
  }>;
};

export enum ShippingCalculationType {
  DISABLE = "DISABLE",
  DISTANCE_BASE_PRICE = "DISTANCE_BASE_PRICE",
  FIXED_PRICE = "FIXED_PRICE",
  LALAMOVE = "LALAMOVE",
  POSTAL_CODE_BASED_PRICE = "POSTAL_CODE_BASED_PRICE",
  SHIPPOP = "SHIPPOP",
  UNIT_PRICE = "UNIT_PRICE",
}

export enum ShipmentDetailStatus {
  ASSIGNING_DRIVER = "ASSIGNING_DRIVER", // Trying to match shipment with a driver
  ON_GOING = "ON_GOING", // Shipment is matched with a driver
  CANCELED = "CANCELED", // Shipment is cancelled before pick up
  PICKED_UP = "PICKED_UP", // Shipment is picked up by the driver
  REJECTED = "REJECTED", // Shipment was matched and rejected twice
  COMPLETED = "COMPLETED", // Successfully delivered and transaction has concluded
  EXPIRED = "EXPIRED", // Order expired because a match could not be found
}

export enum ShippopShipmentDetailStatus {
  CANCEL = "CANCEL", // Shipment has been cancelled by SHIPPOP
  BOOKING = "BOOKING", // Pending for shipping
  SHIPPING = "SHIPPING", // shipping from SHIPPOP
  COMPLETE = "COMPLETE", // Shipment completed
}

export enum DeeplePayPaymentChannel {
  QR_CASH = "QR_CASH",
  QR_CREDIT = "QR_CREDIT",
  CREDIT_CARD = "CREDIT_CARD",
  MOBILE_BANKING = "MOBILE_BANKING",
}

export enum DeeplePayPaymentStatus {
  COMPLETED = "COMPLETED",
  CREATED = "CREATED",
  DECLINED = "DECLINED",
  PROCESSING = "PROCESSING",
  VOIDED = "VOIDED",
  EXPIRED = "EXPIRED",
  REFUNDED = "REFUNDED",
}

export type DeeplePayPaymentInformation = {
  amount: number;
  channel: DeeplePayPaymentChannel;
  status: DeeplePayPaymentStatus;
  paymentAt: string;
};

export type LalamoveDriverInfoType = {
  name: string;
  phone: string;
  plateNumber: string;
  photo: string;
};

export type ShipmentDetailType = {
  id: string;
  projectId: string;
  orderId: string;
  referenceId: string;
  status: ShipmentDetailStatus;
  assigningDriverAt: string;
  onGoingAt: string;
  cancelledAt: string;
  pickedUpAt: string;
  rejectedAt: string;
  completedAt: string;
  expiredAt: string;
  createdAt: string;
  updatedAt: string;
  driver: LalamoveDriverInfoType;
};

export enum CancelOrderAction {
  CANCEL = "CANCEL",
  REFUND = "REFUND",
  REFUND_DEEPLE_PAY = "REFUND_DEEPLE_PAY",
  UNABLE = "UNABLE",
  VOID = "VOID",
  VOID_DEEPLE_PAY = "VOID_DEEPLE_PAY",
}

export type OrderType = {
  id: string;
  trackingNumber?: string;
  orderNumber: string;
  state: OrderState;
  grandTotal: number;
  customerName: string;
  paymentMethodType: PAYMENT_METHOD_TYPES;
  customer: CustomerType;
  customerId: string;
  createdAt: string;
  updatedAt: string;
  paymentBillImageSrc: string;
  secondaryPayslipImages: string[];
  firstName: string;
  lastName: string;
  streetAddress: string;
  streetAddress2: string;
  district: string;
  subDistrict: string;
  province: string;
  postalCode: string;
  phoneNumber: string;
  email: string;
  note: string;
  shippingMethod: METHOD_TYPE;
  products: ProductInOrderType[];
  orderProducts: ProductInOrderType[];
  discountTotalPrice: number;
  discountShippingPrice: number;
  shippingPrice: number;
  shippingPriceNote: string;
  totalPrice: number;
  totalWeight: number;
  vatOptions: string;
  vatPrice: number;
  vatValue: number;
  bill: { billNumber: string };
  paymentAmount: number;
  paymentBankName: string;
  paymentAt: string;
  deeplePayPaymentInformation: DeeplePayPaymentInformation;
  shippedDetail: ShippedDetail;
  productPromotions?: (PromotionType & { totalDiscount: number })[];
  shippingPromotions?: PromotionType[];
  depositAmount: number;
  depositBillImageSrc: string;
  depositBankName: string;
  depositedAt: string;
  remark: string;
  deliverySchedule: string;
  referenceCode: string;
  adminsNote: string;
  shipmentDetails: ShipmentDetailType[];
  shippingCalculationType: ShippingCalculationType;
  branchInformation: BranchType;
  promotionProduct?: PromotionType[];
  shippedAt?: string;
  shippingDuration?: number;
  additionalDiscount?: number;
  voidedBy: string;
  voidedAt: string;
  voidReason: string;
  shippingDescription: string;
  largestDimension?: number[];
  createdBy?: string;
  cancelledBy?: string;
  shipping?: ShippingQuotationType;
  cancelOrderAction?: CancelOrderAction;
  voidOrRefundAmount?: number;
  orderExternalId?: string;
};

export type OrderConnectionType = {
  results: OrderType[];
  total: number;
};

export type OrderConnectionQueryType = {
  orders: OrderConnectionType;
};

export type OrderConnectionVariablesType = {
  projectId: string;
  limit: number;
};

export enum ErrorVoidOrder {
  CANNOT_VOID_ORDER_AT_THIS_STATE = "VOID_ORDER:CANNOT_VOID_ORDER_AT_THIS_STATE",
  ORDER_NOT_FOUND = "VOID_ORDER:ORDER_NOT_FOUND",
  CANNOT_VOID_ORDER_CONTAIN_DIGITAL_PRODUCT = "VOID_ORDER:CANNOT_VOID_ORDER_CONTAIN_DIGITAL_PRODUCT",
  ORDER_STILL_HAS_ACTIVE_SHIPMENT = "VOID_ORDER:ORDER_STILL_HAS_ACTIVE_SHIPMENT",
  CANNOT_VOID_OR_REFUND_ORDER = "VOID_ORDER:CANNOT_VOID_OR_REFUND_ORDER",
  VOID_PAYMENT_ERROR = "VOID_ORDER:VOID_PAYMENT_ERROR",
  REFUND_PAYMENT_ERROR = "VOID_ORDER:REFUND_PAYMENT_ERROR",
}

export type TotalOrderNumberStates = {
  ALL: number;
  COD_PREPARE_TO_SHIP: number;
  ORDER_PLACED: number;
  COD_PICKED_UP: number;
  PREPARE_TO_SHIPPING: number;
  PENDING_FOR_SHIPPING_COST: number;
  WAIT_FOR_PAYMENT_APPROVE: number;
};

export type TotalOrderNumberStatesData = {
  totalOrderNumberStates: TotalOrderNumberStates;
};

export enum ErrorImportOrder {
  FILE_TYPE_IS_NOT_PERMITTED = "IMPORT_ORDER_TRACKING_NUMBERS:FILE_TYPE_IS_NOT_PERMITTED",
  ORDER_STATE_IS_NOT_IN_PREPARE_TO_SHIPPING = "IMPORT_ORDER_TRACKING_NUMBERS:ORDER_STATE_IS_NOT_IN_PREPARE_TO_SHIPPING",
  ORDER_NUMBER_IS_NOT_FOUND = "IMPORT_ORDER_TRACKING_NUMBERS:ORDER_NUMBER_IS_NOT_FOUND",
  PICK_UP_OR_DIGITAL_ORDER_CANNOT_UPDATE_SHIPPED_DETAIL = "IMPORT_ORDER_TRACKING_NUMBERS:PICK_UP_OR_DIGITAL_ORDER_CANNOT_UPDATE_SHIPPED_DETAIL",
  ORDER_HAS_ONLY_DIGITAL_CONTENT_PRODUCT_CANNOT_UPDATE_SHIPPED_DETAIL = "IMPORT_ORDER_TRACKING_NUMBERS:ORDER_HAS_ONLY_DIGITAL_CONTENT_PRODUCT_CANNOT_UPDATE_SHIPPED_DETAIL",
  NOT_SUPPORTED_SHIPPING_CALCULATION_TYPE = "IMPORT_ORDER_TRACKING_NUMBERS:NOT_SUPPORTED_SHIPPING_CALCULATION_TYPE",
}
