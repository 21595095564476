import { gql } from "@apollo/client";

export const GET_INCOME_TRANSACTION = gql`
  query getIncomeTransactions($projectId: ID!, $offset: Int, $limit: Int) {
    queryIncomeDeeplePayWalletTransactionsByProjectId(projectId: $projectId, offset: $offset, limit: $limit) {
      total
      results {
        feeVAT
        receivedAmount
        feeDetail {
          fix
          min
          rate
        }
        variable {
          bank
        }
        response {
          cardType
        }
        orderId
        projectId
        status
        amount
        fee
        referenceNo
        createdDate
        paymentChannel {
          title
        }
        order {
          orderNumber
          pictureUrl
          name
        }
        refundDate
        refundStatus
      }
    }
  }
`;

export const GET_OUTCOME_TRANSACTION = gql`
  query getOutcomeTransactions($projectId: ID!, $offset: Int, $limit: Int) {
    queryOutcomeDeeplePayWalletTransactionsByProjectId(projectId: $projectId, offset: $offset, limit: $limit) {
      total
      results {
        feeVAT
        receivedAmount
        feeDetail {
          fix
          min
          rate
        }
        email
        deeplePayTransactionId
        projectId
        status
        amount
        fee
        referenceNo
        createdDate
        detail {
          bank
          bankAccountName
          bankAccountNumber
        }
      }
    }
  }
`;
