const mapErrorMessage = (error: Error) => {
  switch (error.message) {
    case "Failed to fetch":
      return "Network error please try again later";

    case "This user have been invited":
      return "setting.members.invitedMember.duplicatedInvitation";

    case "INVITE_MEMBER:CANNOT_EXCEED_MEMBER_QUOTA":
      return "setting.members.invitedMember.maxQuota";

    case "This user exists in this project":
      return "setting.members.invitedMember.duplicatedMember";

    case "Don't have a permission to invite member":
      return "setting.members.invitedMember.permission";

    default:
      return "Please try again later";
  }
};

export default mapErrorMessage;
