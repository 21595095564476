import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Typography from "components/Typography";
import PopConfirm from "components/Modal/PopConfirm";

import { BILLING, PLAN, PROJECT } from "constants/Router";
import { ProjectIdType } from "types/Project";

type BillingInfoWarningModalPropsType = {
  isOpen: boolean;
  onCancel: () => void;
};

const BillingInfoWarningModal: FC<BillingInfoWarningModalPropsType> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId } = useParams<ProjectIdType>();
  const { isOpen, onCancel } = props;

  const handleClickConfirm = () => {
    onCancel();
    history.push(`/${PROJECT}/${projectId}/${PLAN}/${BILLING}`);
  };

  return (
    <PopConfirm
      isOpen={isOpen}
      description={
        <Typography variant="body3" color="dark" className="px-3">
          {t("billingInfoWarningModal.description")}
        </Typography>
      }
      onClose={onCancel}
      onSubmit={handleClickConfirm}
      isShowCancel={false}
      textSubmit={t("billingInfoWarningModal.button.confirm")}
    />
  );
};

export default BillingInfoWarningModal;
