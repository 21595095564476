import React, { FC, ChangeEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ButtonWithIcon } from "components/Button";
import Card from "components/Card";
import Grid from "components/Grid";
import Switch from "components/Switch";
import { SvgIcon } from "components/Icon";
import Typography from "components/Typography";
import { FixWidthTooltip } from "components/Tooltip";
import IcEdit from "components/SvgIcons/IcEdit";
import IcDelete from "components/SvgIcons/IcDelete";
import IcNotification from "components/SvgIcons/IcNotification";

import { COLORS } from "constants/Colors";
import { FaqType } from "types/AISetting";
import useToggle from "utils/hooks/useToggle";
import mapIdAndValue from "utils/faq/mapIdAndValue";
import mapColorIntents from "utils/faq/mapColorIntents";

import FaqContainer from "../FaqContainer";
import FaqForm from "../FaqForm";
import { CheckDuplicatedQuestionType } from "../hooks/useCheckDuplicatedQuestionFromAll";
import { CrossLine, NotificationIconWrapper } from "./styled";

type FaqCardPropsType = {
  faq: FaqType;
  isLoadingSubmit?: boolean;
  projectId: string;
  isNewFaq?: boolean;
  isEnableEN: boolean;
  onClickDelete?: (faqId: string) => void;
  onToggleSwitch?: (faqId: string, isActive: boolean) => void;
  onToggleNotification?: (faqId: string, isActive: boolean) => void;
  onSubmit?: (faqId: string, faqData: FaqType, callback?: () => void) => Promise<void>;
  checkDuplicatedQuestionFromAll?: CheckDuplicatedQuestionType;
};

const FaqCard: FC<FaqCardPropsType> = ({
  faq,
  projectId,
  isNewFaq = false,
  isEnableEN,
  onClickDelete = () => {},
  onSubmit,
  onToggleSwitch,
  onToggleNotification,
  checkDuplicatedQuestionFromAll,
  isLoadingSubmit = false,
}) => {
  const { t } = useTranslation();
  const { isOpen, handleToggle, handleClose } = useToggle();

  const handleSubmit = async (faqData: FaqType) => {
    if (onSubmit) {
      onSubmit(faq.id, faqData, handleClose);
    }
  };

  const handleToggleFaq = (event: ChangeEvent<HTMLInputElement>) => {
    if (onToggleSwitch) {
      onToggleSwitch(faq.id, event.target.checked);
    }
  };

  const handleToggleFaqNotification = () => {
    if (onToggleNotification) {
      onToggleNotification(faq.id, !faq.isEnabledNotification);
    }
  };

  const faqWithId = useMemo(
    () => ({
      ...faq,
      questions: mapIdAndValue(faq.questions),
      enQuestions: mapIdAndValue(faq.enQuestions),
      colorIntents: mapColorIntents(faq?.colorIntents || []),
    }),
    [faq],
  );

  return (
    <Card key={faq.id} id={faq.id} className="d-flex mb-3 flex-column" padding="0px">
      <Grid container className="p-4 flex-nowrap">
        <Grid item className="flex-grow-0">
          <Switch checked={faq.isActive} onChange={handleToggleFaq} />
        </Grid>
        <Grid item className="flex-grow-1 mx-3">
          {isNewFaq ? (
            <Typography variant="title7">{t("New Specialized Knowledge")}</Typography>
          ) : (
            <>
              <Typography variant="title7">{faq?.questions[0]}</Typography>
              <Typography variant="title7" color="darkMed">
                {faq?.enQuestions[0]}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item className="flex-grow-0 d-inline-flex align-self-center">
          <FixWidthTooltip
            title={t(`faq.notification.${faq.isEnabledNotification ? "enable" : "disabled"}`) as string}
            placement="top"
            arrow
          >
            <NotificationIconWrapper onClick={handleToggleFaqNotification}>
              {!faq.isEnabledNotification && <CrossLine />}
              <SvgIcon component={IcNotification} viewBox="0 0 26 26" className="m-0 p-0" htmlColor={COLORS.DarkMed} />
            </NotificationIconWrapper>
          </FixWidthTooltip>

          {isOpen || isNewFaq ? (
            <ButtonWithIcon onClick={() => onClickDelete(faq.id)}>
              <SvgIcon className="ml-1 mt-1" component={IcDelete} fontSize="small" />
            </ButtonWithIcon>
          ) : (
            <ButtonWithIcon onClick={handleToggle} data-cy="editButton">
              <SvgIcon className="ml-1 mt-1" component={IcEdit} fontSize="small" htmlColor={COLORS.DarkMed} />
            </ButtonWithIcon>
          )}
        </Grid>
      </Grid>
      <div>
        {isOpen && !isNewFaq && (
          <FaqContainer
            isLoadingSubmit={isLoadingSubmit}
            projectId={projectId}
            faqId={faq.id}
            onSubmit={handleSubmit}
            onCancel={isNewFaq ? () => onClickDelete(faq.id) : handleClose}
            checkDuplicatedQuestionFromAll={checkDuplicatedQuestionFromAll}
            isEnableEN={isEnableEN}
          />
        )}

        {isNewFaq && (
          <FaqForm
            isLoadingSubmit={isLoadingSubmit}
            projectId={projectId}
            faq={faqWithId}
            onSubmit={handleSubmit}
            onCancel={isNewFaq ? () => onClickDelete(faq.id) : handleClose}
            checkDuplicatedQuestionFromAll={checkDuplicatedQuestionFromAll}
            isEnableEN={isEnableEN}
          />
        )}
      </div>
    </Card>
  );
};

export default FaqCard;
