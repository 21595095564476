import { ChatListMessage } from "types/Chat";
import { HeaderType } from "types/Tab";
import { ContactListType } from "types/Customer";
import { IcChat, IcAdmin } from "components/SvgIcons";
import IcHelpRequest from "components/SvgIcons/IcHelpRequest";

export const SUGGEST_SYSTEM_TAGS = ["NEW_CUSTOMER", "POTENTIAL_CUSTOMER", "FIRST_TIME_BUYER", "REPEAT_BUYER", "UNSEEN"];

export enum MESSAGE_TYPES {
  CAROUSEL = "CAROUSEL",
  FLEX = "FLEX",
  IMAGE = "IMAGE",
  TEXT = "TEXT",
  SPACING = "SPACING",
  STICKER = "STICKER",
  PRODUCT_IMAGE = "PRODUCT_IMAGE",
  PRODUCT_NAME = "PRODUCT_NAME",
  PRODUCT_SKU = "PRODUCT_SKU",
  PRODUCT_SKU_WITH_ACTION = "PRODUCT_SKU_WITH_ACTION",

  PRODUCT_SKU_PRICE = "PRODUCT_SKU_PRICE",
  PRODUCT_SKU_DISCOUNT_PRICE = "PRODUCT_SKU_DISCOUNT_PRICE",
  PRODUCT_SKU_PROMOTION = "PRODUCT_SKU_PROMOTION",

  PRODUCT_PRICE = "PRODUCT_PRICE",
  PRODUCT_DISCOUNT_PRICE = "PRODUCT_DISCOUNT_PRICE",
  PRODUCT_MENU = "PRODUCT_MENU",
  PRODUCT_PROMOTION_NAME = "PRODUCT_PROMOTION_NAME",
  PRODUCT_PROMOTION_BADGE = "PRODUCT_PROMOTION_BADGE",

  CATEGORY_IMAGE = "CATEGORY_IMAGE",
  CATEGORY_NAME = "CATEGORY_NAME",
}

export const CHAT_LIST_MESSAGE_TYPES: ChatListMessage = {
  AUDIO: "AUDIO",
  CAROUSEL: "CAROUSEL",
  CART_SUMMARY: "CART_SUMMARY",
  CATEGORY_CAROUSEL: "CATEGORY_CAROUSEL",
  DELIVERY_SCHEDULE: "DELIVERY_SCHEDULE",
  FILE: "FILE",
  FLEX: "FLEX",
  IMAGE: "IMAGE",
  ORDER_CAROUSEL: "ORDER_CAROUSEL",
  PAYMENT_METHOD: "PAYMENT_METHOD",
  PRODUCT_CAROUSEL: "PRODUCT_CAROUSEL",
  SHIPPING_ADDRESS: "SHIPPING_ADDRESS",
  SHIPPING_METHOD_TYPE: "SHIPPING_METHOD_TYPE",
  SHIPPING_METHOD: "SHIPPING_METHOD",
  STICKER: "STICKER",
  STORY_MENTION: "STORY_MENTION",
  STORY_REPLY: "STORY_RELY",
  TEXT: "TEXT",
  VIDEO: "VIDEO",
};

export const CALENDAR_FORMAT = {
  lastDay: "[Yesterday]",
  lastWeek: "dddd",
  nextDay: "[Tomorrow]",
  nextWeek: "dddd",
  sameDay: "[Today]",
  sameElse: "DD/MM/YYYY",
};

export const CHATROOM_HEADER_HEIGHT_DESKTOP = 80;
export const CHATROOM_HEADER_HEIGHT_MOBILE = 62;
export const CHATROOM_REPLYBOX_HEIGHT = 80;
export const DEBOUNCE_DELAY = 300;
export const FETCH_POLICY = "network-only";
export const FIRST_LOAD_LIMIT = 15;
export const INFINITE_LOAD_LIMIT = 20;
export const INITIAL_OFFSET = 0;
export const MAX_DISPLAYED_TAGS = 2;
export const MAX_PAGE_SIZE = 50;
export const MAX_TAG_CHAR_TO_DISPLAY = 15;
export const MIN_THRESHOLD = 400;
export const NEXT_FETCH_POLICY = "cache-first";
export const POLL_INTERVAL = 5000;

export const TEXT: Record<string, string> = {
  ASK_FOR_ADDRESS_INFORMATION: "ดูที่อยู่จัดส่ง",
  ASK_FOR_CANCELED_ORDER: "การซื้อที่ถูกยกเลิก",
  ASK_FOR_MORE_DETAIL: "ดูรายละเอียด",
  ASK_FOR_PAYMENT_METHOD: "ช่องทางการชำระเงิน",
  ASK_FOR_RECEIPT: "ดูใบเสร็จ",
  ASK_FOR_REORDER: "สั่งอีกครั้ง",
  ASK_FOR_SHIPPED_ORDER: "การซื้อที่จัดส่งแล้ว",
  ASK_FOR_SHOP_LOCATION: "ดูที่อยู่ร้าน",
  ASK_FOR_TRACKING_NUMBER: "ดูหมายเลขพัสดุ",
  ASK_MORE_PRODUCT: "มีสินค้าอะไรอีกบ้าง",
  ASK_OTHER_PRODUCT: "ดูสินค้าอื่น",
  CANCEL_ORDER: "ยกเลิกการสั่งซื้อ",
  CANCEL: "ยกเลิก",
  CHECKOUT: "ชำระเงิน",
  CHOOSE_DELIVERY_TIME: "เลือกเวลาจัดส่ง",
  CONFIRM: "เอาเลย",
  CONFIRM_CHECKOUT: "ยืนยันการสั่งซื้อ",
  CONFIRM_PRICE: "ยืนยัน",
  CONTACT_ADMIN: "ติดต่อแอดมิน",
  DELIVERY: "จัดส่งถึงบ้าน",
  DELIVERY_TIME: "เวลาจัดส่ง",
  EDIT_CART: "แก้ไขรายการ",
  EDIT_CART_SUMMARY: "แก้ไขรายการสั่งซื้อ",
  EDIT_SHIPPING_ADDRESS: "แก้ไขที่อยู่",
  ENTER_ADDRESS: "กรอกที่อยู่จัดส่ง",
  FASTEST_DELIVERY_TIME: "จัดส่งเร็วที่สุด",
  FILL_INFO: "กรอกข้อมูล",
  FILL_NEW_ADDRESS: "กรอกข้อมูลจัดส่ง",
  INTERESTED_IN_PRODUCT: "สนใจสินค้านี้",
  MORE_INFO: "ดูข้อมูลเพิ่มเติม",
  OUT_OF_STOCK: "สินค้าหมด",
  PICK_UP_AT_STORE: "รับสินค้าที่ร้าน",
  REJECT: "ยังไม่เอา",
  SHOW_ALL_PRODUCT: "ดูสินค้าทั้งหมด",
  SHOW_CART: "ดูรถเข็น",
  SUGGEST_PRODUCT: "เมนูสินค้า",
  USE_THIS_ADDRESS: "ใช้ที่อยู่นี้",
  USE_OLD_ADDRESS: "ใช้ที่อยู่เดิม",
  OLD_ADDRESS: "ที่อยู่เดิม",
  FILL_SHIPPING_ADDRESS_INFO:
    "รบกวนขอรายละเอียด ชื่อ, ที่อยู่ และเบอร์ติดต่อของคุณลูกค้า เพื่อทางร้านจะได้จัดส่งสินค้าให้",
  PROVIDED_SHIPPING_ADDRESS: "รายละเอียด ชื่อ, ที่อยู่, และเบอร์ติดต่อของลูกค้า เพื่อทางร้านจะได้จัดส่งสินค้าให้",
  EXTRACTED_ADDRESS: "ที่อยู่ที่ได้รับ",
};

export const BANK_ACCOUNT = {
  TYPE: {
    NAME: {
      SAVING: "บัญชีออมทรัพย์",
      CURRENT: "บัญชีกระแสรายวัน",
    },
    VALUE: {
      SAVING: "SAVING",
      CURRENT: "CURRENT",
    },
  },
  ICON: {
    BAY: "https://storage.googleapis.com/kanda-ai-core/static/bank-icon/krungsri.png",
    BBL: "https://storage.googleapis.com/kanda-ai-core/static/bank-icon/bbl.png",
    CHILL_PAY: "https://storage.googleapis.com/kanda-ai-core/static/bank-icon/chill-pay.png",
    CIMB: "https://storage.googleapis.com/kanda-ai-core/static/bank-icon/cimb.png",
    CITI: "https://storage.googleapis.com/kanda-ai-core/static/bank-icon/citi.png",
    GSB: "https://storage.googleapis.com/kanda-ai-core/static/bank-icon/gsb.png",
    KBANK: "https://storage.googleapis.com/kanda-ai-core/static/bank-icon/kbank.png",
    KTB: "https://storage.googleapis.com/kanda-ai-core/static/bank-icon/ktb.png",
    LH: "https://storage.googleapis.com/kanda-ai-core/static/bank-icon/lh.png",
    PROMPTPAY: "https://storage.googleapis.com/kanda-ai-core/static/bank-icon/prompt-pay.png",
    SCB: "https://storage.googleapis.com/kanda-ai-core/static/bank-icon/scb.png",
    THANACHART: "https://storage.googleapis.com/kanda-ai-core/static/bank-icon/thanachartbank.png",
    TMB: "https://storage.googleapis.com/kanda-ai-core/static/bank-icon/tmb.png",
    TTB: "https://storage.googleapis.com/kanda-ai-core/static/bank-icon/ttb.png",
    TWO_C_TWO_P: "https://storage.googleapis.com/kanda-ai-core/static/bank-icon/2c2p.png",
    UOB: "https://storage.googleapis.com/kanda-ai-core/static/bank-icon/uob.png",
  },
  CASH_ON_DELIVERY: { EN: "CashOnDelivery", TH: "เก็บเงินปลายทาง" },
  PAYMENT_GATEWAY_CHILL_PAY: { EN: "PaymentGateway", TH: "บัตรเครดิต, อินเตอร์เน็ตแบงค์กิ้งและอื่นๆ" },
  NAME: {
    TH: {
      BAY: "กรุงศรีอยุธยา",
      BBL: "กรุงเทพ",
      CIMB: "ซีไอเอ็มบี",
      CITI: "ซิตี้",
      GSB: "ออมสิน",
      KBANK: "กสิกรไทย",
      KTB: "กรุงไทย",
      LH: "แลนด์ แอนด์ เฮ้าส์",
      SCB: "ไทยพาณิชย์",
      THANACHART: "ธนชาต",
      TMB: "ทหารไทย",
      UOB: "ยูโอบี",
    },
    EN: {
      BAY: "BAY",
      BBL: "BBL",
      CIMB: "CIMB",
      CITI: "CITI",
      GSB: "GSB",
      KBANK: "KBANK",
      KTB: "KTB",
      LH: "LH",
      SCB: "SCB",
      THANACHART: "THANACHART",
      TMB: "TMB",
      UOB: "UOB",
    },
  },
};

export const TAB_HEADER_CONFIG: HeaderType[] = [
  {
    id: "ALL_CHAT_TAB_HEADER",
    title: "All Chat",
    svgIcon: IcChat,
  },
  {
    id: "ADMIN_CHAT_TAB_HEADER",
    title: "HELP_REQUEST",
    svgIcon: IcHelpRequest,
  },
];

export const TAB_HEADER_CONFIG_WITH_DISCONNECTED_BOT: HeaderType[] = [
  ...TAB_HEADER_CONFIG,
  {
    id: "DISCONNECTED_BOT_TAB_HEADER",
    title: "ADMIN_ONLY",
    svgIcon: IcAdmin,
  },
];

export const MAP_TAB_CONFIGS = [ContactListType.ALL, ContactListType.ADMIN, ContactListType.BOT_DISCONNECTED];

export const DEEPLE_PAY_IMAGE_URL = "https://storage.googleapis.com/kanda-ai-core/static/deeple-pay";
