import * as React from "react";

const IcTurnOffBot = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.627.571c.27.15.366.491.215.76L4.705 17.203c-.102.184-.292.287-.488.287-.092 0-.186-.023-.272-.071-.27-.15-.366-.491-.215-.76l.665-1.155c-1.54-1.091-2.678-2.712-3.14-4.6-.066.015-.134.022-.204.022H1c-.552 0-1-.448-1-1V8.07c0-.552.448-1 1-1h.05c.071 0 .14.007.205.021C2.11 3.608 5.252 1.023 9 1.023c1.183 0 2.304.26 3.313.724l.553-.96c.15-.27.491-.367.76-.216zm.727 2.523c1.157 1.05 2.008 2.432 2.392 3.998.065-.015.133-.022.203-.022H17c.552 0 1 .448 1 1v1.855c0 .552-.448 1-1 1h-.05c-.07 0-.14-.007-.205-.021-.853 3.483-3.997 6.068-7.745 6.068-.762 0-1.497-.112-2.195-.312l.701-1.26c1.727-.81 2.977-1.43 3.499-1.658 1.784-.775 3.031-2.552 3.031-4.62 0-1.252-.46-2.392-1.215-3.272zM9 4.085c-2.781 0-5.036 2.255-5.036 5.036 0 1.623.691 3.022 1.846 3.921l4.969-8.63c-.553-.21-1.152-.327-1.779-.327z"
    />
  </svg>
);

export default IcTurnOffBot;
