import React, { MouseEventHandler, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Popper } from "@material-ui/core";

import { IcInfo } from "components/SvgIcons";
import Typography from "components/Typography";
import { SvgIcon } from "components/Icon";

import useOutsideClick from "utils/hooks/useOutsideClick";

import { TooltipTable } from "./styled";

function TooltipLabel(): JSX.Element {
  const { t } = useTranslation();
  const popperRef = useRef(null);
  const [popperState, setPopperState] = useState<{
    anchorEl?: HTMLLIElement | null;
    isOpen: boolean;
  } | null>({ anchorEl: null, isOpen: false });

  const handleClickTooltip: MouseEventHandler<HTMLLIElement> = (e) => {
    e.stopPropagation();
    setPopperState({ isOpen: true, anchorEl: e.currentTarget });
  };

  useOutsideClick(popperRef, () => {
    setPopperState({ ...popperState, isOpen: false });
  });

  return (
    <div>
      <Typography variant="body3" className="d-flex">
        {t("broadcast.facebookRequestForm.form.renewPlaceholder")}
        <i className="ml-1" style={{ cursor: "help" }} onClick={handleClickTooltip} ref={popperRef}>
          <SvgIcon component={IcInfo} fontSize="small" />
        </i>
      </Typography>
      <Popper open={popperState?.isOpen || false} anchorEl={popperState?.anchorEl} transition placement="bottom-start">
        <TooltipTable>
          <tr>
            <th>{t("broadcast.facebookRequestForm.form.tooltip.title")}</th>
            <th>{t("broadcast.facebookRequestForm.form.tooltip.description")}</th>
          </tr>
          <tr>
            <td>{t("broadcast.facebookRequestForm.form.tooltip.daily")}</td>
            <td>{t("broadcast.facebookRequestForm.form.tooltip.dailyDescription")}</td>
          </tr>
          {/* <tr>
            <td>{t("broadcast.facebookRequestForm.form.tooltip.weekly")}</td>
            <td>{t("broadcast.facebookRequestForm.form.tooltip.weeklyDescription")}</td>
          </tr>
          <tr>
            <td>{t("broadcast.facebookRequestForm.form.tooltip.monthly")}</td>
            <td>{t("broadcast.facebookRequestForm.form.tooltip.monthlyDescription")}</td>
          </tr> */}
        </TooltipTable>
      </Popper>
    </div>
  );
}

export default TooltipLabel;
