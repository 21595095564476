import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import get from "lodash/get";
import { MAX_SIZE_IMPORTED_FILE, DOWNLOAD_SAMPLE_CODE_URL } from "config/product";
import { IMPORT_CODES } from "graphql/product/mutation";
import { downloadFile } from "utils/common";
import validateFileSize from "utils/common/validateFileSize";
import { notifyError, notifySuccess } from "utils/notify";
import ImportButton from "../ImportButton";
import mapErrorMessage from "../mapErrorMessage";

type ImportCodeButtonPropsType = {
  projectId: string;
  onCompleted: () => void;
};

const ImportCodeButton: FC<ImportCodeButtonPropsType> = (props) => {
  const { t } = useTranslation();

  const { projectId, onCompleted } = props;

  const [importCodes, { loading: isImportCodesLoading }] = useMutation(IMPORT_CODES, {
    onCompleted: () => {
      notifySuccess(t("product.importCode.success"));
      onCompleted();
    },
  });

  const handleImportCode = async (file: File) => {
    if (file && projectId) {
      const isOverSize = validateFileSize(file, MAX_SIZE_IMPORTED_FILE);

      if (isOverSize) {
        notifyError(t("product.import.error.overSize", { maxSize: MAX_SIZE_IMPORTED_FILE }), { autoClose: 8000 });
        return;
      }

      try {
        await importCodes({ variables: { projectId, file } });
      } catch (err) {
        const gqlErrorMessage: string = get(err, "graphQLErrors.0.message");
        const errorMessage = mapErrorMessage(gqlErrorMessage);
        notifyError(t(errorMessage), { autoClose: 8000 });
      }
    }
  };

  return (
    <ImportButton
      isFileType
      label={t("product.importCode.button.label")}
      subLabel={t("product.importCode.button.subLabel")}
      onChange={handleImportCode}
      isLoading={isImportCodesLoading}
      labelLink={t("product.download.sample")}
      onClickLink={() => downloadFile(DOWNLOAD_SAMPLE_CODE_URL, "upload-codes-example.csv")}
    />
  );
};

export default ImportCodeButton;
