import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import Typography from "components/Typography";
import Grid from "components/Grid";
import { AnimatedArrowDownIcon } from "components/Icon";
import ShippingConditionList from "domain/Shipping/Shippop/SettleInvoiceModal/ShippingConditionList";

import { CARRIER_DESCRIPTION, SHIPPOP_FAQ_LINK } from "constants/Shippop";

type ShippingConditionPropsType = {
  carrierName: string;
};

const ShippingCondition: FC<ShippingConditionPropsType> = ({ carrierName }) => {
  const { t } = useTranslation();

  const [isShowInfo, setIsShowInfo] = useState(false);

  const conditions = CARRIER_DESCRIPTION[carrierName]?.TH || [];

  return conditions.length ? (
    <Grid container>
      <Grid item container alignItems="center">
        <Grid item>
          <Typography variant="body3">{t("shippingPriceDetail.label.shippingCondition")}</Typography>
        </Grid>
        <Grid item className="pl-2 pt-2">
          <AnimatedArrowDownIcon isShow={isShowInfo} onClick={() => setIsShowInfo(!isShowInfo)} viewBox="0 0 26 26" />
        </Grid>
      </Grid>
      {isShowInfo && (
        <>
          <Grid item container className="pb-4 pt-1">
            <ShippingConditionList conditions={conditions} />
          </Grid>
          <Grid item container>
            <Grid item className="pr-2">
              <Typography variant="body3">{t("shippingPriceDetail.label.seeFullInfo")}</Typography>
            </Grid>
            <Grid item>
              <a href={SHIPPOP_FAQ_LINK} rel="noopener noreferrer" target="_blank">
                <Typography variant="body3" color="info">
                  {SHIPPOP_FAQ_LINK}
                </Typography>
              </a>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  ) : null;
};

export default ShippingCondition;
