import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import Typography from "components/Typography";
import Card from "components/Card";
import { SvgIcon } from "components/Icon";
import { IcWallet, IcShipping } from "components/SvgIcons";

import useIsDesktop from "utils/hooks/useIsDesktop";
import { InvoiceTypes } from "types/invoice";

import { convertPriceFormat } from "utils/common";

type WalletCard = {
  type: string;
  total: number;
};

const WalletCard: FC<WalletCard> = (props) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  const { type, total } = props;

  return (
    <Card padding="0px" noShadow borderRadius={isDesktop ? 8 : 0}>
      <Grid container className="p-4" alignContent="center" alignItems="center">
        <Grid container item xs={8} alignContent="center" alignItems="center">
          <Grid item className="pr-1">
            <Typography variant="title3" color="primary">
              <SvgIcon
                className="mt-0 pb-0 mx-1"
                component={type === InvoiceTypes.MAIN ? IcWallet : IcShipping}
                fontSize="small"
                htmlColor="inherit"
              />
              {t("THB")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="title4" color="primary">
              {convertPriceFormat(total)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item xs={4} justify="flex-end">
          <Typography variant="title8" color="darkLight">
            {t(`billing.card.label.${type.toLowerCase()}`)}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default WalletCard;
