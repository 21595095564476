import React, { FC, MouseEvent } from "react";
import moment from "moment";
import clsx from "clsx";
import { DATE_TIME_PICKER_FORMAT } from "constants/DateTimeFormat";
import Button from "components/Button";
import ButtonBase from "components/ButtonBase";

import Grid from "components/Grid";
import Popover from "@material-ui/core/Popover";
import Typography from "components/Typography";
import DateTimeRangePicker from "components/DateTimeRangePicker";
import { SvgIcon } from "components/Icon";
import { IcCalendar } from "components/SvgIcons";
import COLORS from "constants/Colors";

type DateRangeProps = {
  anchorEl: HTMLButtonElement | null;
  endDate: string;
  id?: string;
  isButtonBase?: boolean;
  isFullWidth?: boolean;
  isOpen?: boolean;
  onChangeEnd: (selectedDate: string | null) => void;
  onChangeStart: (selectedDate: string | null) => void;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  onClose: () => void;
  startDate: string;
};

const DateRange: FC<DateRangeProps> = ({
  anchorEl,
  endDate,
  id,
  isButtonBase,
  isFullWidth = false,
  isOpen = false,
  onChangeEnd,
  onChangeStart,
  onClick,
  onClose,
  startDate,
}) => (
  <Grid
    item
    container
    justify="flex-start"
    alignContent="center"
    alignItems="center"
    style={{ width: isFullWidth ? "100%" : "fit-content" }}
  >
    {isButtonBase ? (
      <ButtonBase onClick={onClick} className={clsx("py-2", { "w-100": isFullWidth })}>
        <Typography variant="title8" color="inherit" className="pr-3">
          <SvgIcon component={IcCalendar} className="p-0" fontSize="small" htmlColor={COLORS.DarkMed} />{" "}
          {moment(startDate).format(DATE_TIME_PICKER_FORMAT)} - {moment(endDate).format(DATE_TIME_PICKER_FORMAT)}
        </Typography>
      </ButtonBase>
    ) : (
      <Button color="secondary" onClick={onClick} className={clsx({ "w-100": isFullWidth })}>
        <Typography variant="title8" color="inherit" className="pr-3">
          <SvgIcon component={IcCalendar} className="p-0" fontSize="small" htmlColor={COLORS.DarkMed} />{" "}
          {moment(startDate).format(DATE_TIME_PICKER_FORMAT)} - {moment(endDate).format(DATE_TIME_PICKER_FORMAT)}
        </Typography>
      </Button>
    )}

    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      className="mt-2"
    >
      <div className="py-3">
        <DateTimeRangePicker
          format={DATE_TIME_PICKER_FORMAT}
          selectedDateTime={{ from: moment(startDate).toDate(), to: moment(endDate).toDate() }}
          setStartTime={onChangeStart}
          setEndTime={onChangeEnd}
        />
      </div>
    </Popover>
  </Grid>
);

export default DateRange;
