import * as React from "react";

const SvgIcClock = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99961 14.3996C11.5342 14.3996 14.3996 11.5342 14.3996 7.99961C14.3996 4.46499 11.5342 1.59961 7.99961 1.59961C4.46499 1.59961 1.59961 4.46499 1.59961 7.99961C1.59961 11.5342 4.46499 14.3996 7.99961 14.3996ZM8.49963 4.7998C8.49963 4.52366 8.27578 4.2998 7.99963 4.2998C7.72349 4.2998 7.49963 4.52366 7.49963 4.7998V7.9998C7.49963 8.13241 7.55231 8.25959 7.64608 8.35336L9.90882 10.6161C10.1041 10.8114 10.4207 10.8114 10.6159 10.6161C10.8112 10.4208 10.8112 10.1043 10.6159 9.90899L8.49963 7.7927V4.7998Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgIcClock;
