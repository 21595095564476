import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import get from "lodash/get";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { makeStyles } from "@material-ui/core/styles";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Person from "@material-ui/icons/Person";

import MultiSelectWithCheckBox from "components/MultiSelectWithCheckBox";

import COLORS from "constants/Colors";
import { systemTagsOptions } from "constants/Broadcast";
import { PROVINCE_TH } from "constants/Province";

import { SUGGEST_TAGS } from "graphql/suggestTags/query";

import { BroadcastChannel, CriteriaType } from "types/Broadcast";

import type { OptionType, OptionsType, ValueType } from "react-select";
import type { RecurringNotificationOptInsCampaignParamsType } from "types/Broadcast";
import type { SuggestTagVariablesType, SuggestTagsType } from "types/Customer";
import type { FormFacebookAudienceProps } from "./types";

import { convertDataToOptionFormat, convertDataSystemTagsToOptionFormat } from "./helpers";

const useStyles = makeStyles((theme) => ({
  // Header
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  personIcon: {
    color: COLORS.Dark,
    fontSize: "1.5rem",
  },
  subscirberCount: {
    marginLeft: "0.5rem",
    color: COLORS.Dark,
    fontSize: "1rem",
    fontStyle: "normal",
    lineHeight: "1.125",
  },
  subscriberWrapper: {
    display: "inline-flex",
    alignItems: "center",
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: "1.5rem",
    fontWeight: 500,
    lineHeight: "2",
  },
  // Audience
  audienceWrapper: {
    marginTop: "1.5rem",
  },
  // Accordion Summary
  accordionSummaryTitle: {
    marginTop: 0,
    marginBottom: 0,
    color: COLORS.Apple,
    fontSize: "1.125rem",
    fontWeight: 500,
    lineHeight: 1.56,
  },
  // Accordion Details
  audienceDetails: {
    display: "flex",
    flexDirection: "column",
  },
  // Accordion Details Header
  accordionDetailsBroadcast: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
  },
  accordionDetailsBroadcastCount: {
    marginLeft: "5px",
    color: COLORS.DarkMed,
    fontStyle: "normal",
  },
  accordionDetailsBroadcastIcon: {
    color: COLORS.DarkMed,
    fontSize: "1.125rem",
  },
  accordionDetailsHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  accordionDetailsTitle: {
    marginTop: 0,
    marginBottom: 0,
    color: COLORS.DarkMed,
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: 1.43,
    textTransform: "capitalize",
  },
  audienceSubscriberCount: {
    marginLeft: "5px",
    color: COLORS.DarkMed,
    fontStyle: "normal",
  },
  // Audience Form
  audienceForm: {
    marginTop: "0.5rem",
    border: `1px solid ${COLORS.DarkGray}`,
    borderRadius: "5px",
    padding: "1rem",
  },
  audienceFormGroup: {
    marginTop: "1.5rem",
    [theme.breakpoints.up("lg")]: {
      flex: 1,
      marginTop: 0,
    },
  },
  audienceFormGroupInline: {
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "1.5rem",
    },
  },
  audienceFormInlineDivider: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "block",
      width: "1rem",
      height: "100%",
    },
  },
  audienceFormLabel: {
    display: "inline-block",
    marginBottom: "0.25rem",
    color: COLORS.DarkMed,
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: 1.43,
    textTransform: "capitalize",
  },
}));

export default function FormFacebookAudience({
  audienceNumber,
  broadcastCampaignData,
  setBroadcastCampaignData,
  subscriberNumber,
  onChange,
}: FormFacebookAudienceProps): JSX.Element {
  // Hooks
  const classes = useStyles();
  const { projectId, recurringNotificationOptInId } = useParams<RecurringNotificationOptInsCampaignParamsType>();
  const { t, i18n } = useTranslation();

  // Define variables
  const isThai = i18n.language === "th";

  // Async Hooks
  const { data: suggestTagsData } = useQuery<SuggestTagsType, SuggestTagVariablesType>(SUGGEST_TAGS, {
    variables: {
      projectId,
      type: "CUSTOMER",
    },
    fetchPolicy: "network-only",
  });

  // Variables
  const provinceOptions: OptionsType<OptionType> = convertDataToOptionFormat(PROVINCE_TH || []) || [];
  const optionsSuggestTagsValue: OptionsType<OptionType> =
    convertDataToOptionFormat((suggestTagsData && suggestTagsData.suggestTags) || []) || [];

  // Event handlers
  const handleChangeTagInput = (tagInputValues: ValueType<OptionType>, criteriaType: CriteriaType) => {
    const newTagsArray = tagInputValues
      ? (tagInputValues as OptionType[]).map((tagInputValue) => {
          return tagInputValue.value;
        })
      : [];

    let newData = null;
    switch (criteriaType) {
      case CriteriaType.SYSTEM_TAGS:
        newData = { systemCriteria: newTagsArray };
        break;
      case CriteriaType.CUSTOM_TAGS:
        newData = { customCriteria: newTagsArray };
        break;
      case CriteriaType.LOCATION:
        newData = { locationCriteria: newTagsArray };
        break;
      default:
        break;
    }

    const newBroadcastCriteriaData = {
      ...broadcastCampaignData.broadcastCriteria[0],
      projectId,
      ...newData,
    };

    setBroadcastCampaignData({
      ...broadcastCampaignData,
      broadcastCriteria: [newBroadcastCriteriaData],
    });

    const fetchAudienceNumberVariables = {
      projectId,
      recurringNotificationOptInId,
      channel: BroadcastChannel.FACEBOOK,
      criteria: [newBroadcastCriteriaData],
    };

    onChange(fetchAudienceNumberVariables);
  };

  return (
    <section>
      {/* Header */}
      <header className={classes.header}>
        <h1 className={classes.title}>{t("broadcast")}</h1>
        <div className={classes.subscriberWrapper}>
          <Person className={classes.personIcon} />
          <em className={classes.subscirberCount}>
            {isThai
              ? `${t("broadcast.facebook.table.header.subscriber")} ${subscriberNumber} ${t(
                  "broadcast.facebook.table.header.subscriber.unit",
                )}`
              : `${subscriberNumber} ${t("broadcast.facebook.table.header.subscriber")}`}
          </em>
        </div>
      </header>

      {/* Audience */}
      <div className={classes.audienceWrapper}>
        <Accordion defaultExpanded>
          {/* Accordion Summary */}
          <AccordionSummary
            aria-controls="facebook-broadcast-campaign-content"
            expandIcon={<ExpandMoreIcon />}
            id="facebook-broadcast-campaign-header"
          >
            <h2 className={classes.accordionSummaryTitle}>{t("broadcast.facebook.audienceForm.broadcastHeader")}</h2>
          </AccordionSummary>

          {/* Accordion Details */}
          <AccordionDetails className={classes.audienceDetails}>
            {/* Accordion Details Header */}
            <div className={classes.accordionDetailsHeader}>
              <h3 className={classes.accordionDetailsTitle}>{t("broadcast.facebook.audienceForm.audienceHeader")}</h3>
              <div className={classes.accordionDetailsBroadcast}>
                <Person className={classes.accordionDetailsBroadcastIcon} />
                <em className={classes.accordionDetailsBroadcastCount}>{audienceNumber}</em>
              </div>
            </div>

            {/* Audience Form */}
            <form className={classes.audienceForm}>
              <div>
                <label className={classes.audienceFormLabel} htmlFor="targeting">
                  {t("broadcast.facebook.audienceForm.targetingLabel")}
                </label>
                <MultiSelectWithCheckBox
                  id="targeting"
                  placeholder={`${t("broadcast.SelectALL")} ${t("broadcast.targeting")}`}
                  options={systemTagsOptions}
                  value={convertDataSystemTagsToOptionFormat(
                    get(broadcastCampaignData, "broadcastCriteria[0].systemCriteria") || [],
                  )}
                  onChange={(event: ValueType<OptionType>) => {
                    handleChangeTagInput(event || [], CriteriaType.SYSTEM_TAGS);
                  }}
                />
              </div>

              <div className={classes.audienceFormGroupInline}>
                {/* Audience Location */}
                <div className={classes.audienceFormGroup}>
                  <label className={classes.audienceFormLabel} htmlFor="location">
                    {t("broadcast.facebook.audienceForm.locationLabel")}
                  </label>
                  <MultiSelectWithCheckBox
                    id="location"
                    placeholder={`${t("broadcast.SelectALL")} ${t("broadcast.location")}`}
                    options={provinceOptions}
                    value={convertDataToOptionFormat(
                      get(broadcastCampaignData, "broadcastCriteria[0].locationCriteria") || [],
                    )}
                    onChange={(event: ValueType<OptionType>) => {
                      handleChangeTagInput(event || [], CriteriaType.LOCATION);
                    }}
                  />
                </div>

                <div className={classes.audienceFormInlineDivider} />

                {/* Audience Customer Tag */}
                <div className={classes.audienceFormGroup}>
                  <label className={classes.audienceFormLabel} htmlFor="customer-tag">
                    {t("broadcast.facebook.audienceForm.customerTagLabel")}
                  </label>
                  <MultiSelectWithCheckBox
                    id="customer-tag"
                    placeholder={`${t("broadcast.SelectALL")} ${t("broadcast.customerTags")}`}
                    options={optionsSuggestTagsValue}
                    value={convertDataToOptionFormat(
                      get(broadcastCampaignData, "broadcastCriteria[0].customCriteria") || [],
                    )}
                    onChange={(event: ValueType<OptionType>) => {
                      handleChangeTagInput(event || [], CriteriaType.CUSTOM_TAGS);
                    }}
                  />
                </div>
              </div>
            </form>
          </AccordionDetails>
        </Accordion>
      </div>
    </section>
  );
}
