const filterSelectedIds = (orderIdsObj: Record<string, boolean>): string[] => {
  const orderIds = Object.keys(orderIdsObj);
  const mappedOrderIds = orderIds.map((orderId: string) => {
    if (orderIdsObj[orderId]) {
      return orderId;
    }
    return null;
  });

  return mappedOrderIds.filter(Boolean) as string[];
};

export default filterSelectedIds;
