import React, { FC, useState, ChangeEvent, useEffect, useRef } from "react";
import isEqual from "lodash/isEqual";

import { useTranslation } from "react-i18next";
import produce from "immer";
import { DropResult } from "react-beautiful-dnd";
import { useQuery, useMutation } from "@apollo/client";

import { MAXIMUM_WELCOME_MESSAGES_LENGTH } from "config";

import ConfirmationModal from "components/ConfirmationModal";
import MessagesManager, { MessageInputVariant } from "components/MessagesManager";

import { notifySuccess, notifyError } from "utils/notify";
import { removeTypenameField } from "utils/common";
import {
  reorder,
  reorderQuickReply,
  clearEmptyMessage,
  cleanCategoriesMessage,
  updateQuickReplyMessage,
} from "utils/message";
import { WELCOME_MESSAGES } from "graphql/ai-setting/query";
import { UPDATE_WELCOME_MESSAGE } from "graphql/ai-setting/mutation";
import { NOTIFICATION_UPDATE } from "constants/Notification";
import {
  ResponseMessage,
  MessageTextValue,
  MessageImageValue,
  CategoriesObjectType,
  ProductsObjectType,
  QuickReplyMessage,
} from "types/AISetting";
import { MessageType } from "types/Chat";
import { addGoogleTagEvent } from "services/GoogleTagEvent";
import { GTM_EVENT } from "constants/GTM";
import Layout from "./Layout";

type WelcomeMessagePropsType = {
  projectId: string;
};

type GreetingMessage = {
  greetingMessage: ResponseMessage[];
};

const WelcomeMessageComponent: FC<WelcomeMessagePropsType> = ({ projectId }) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery<GreetingMessage>(WELCOME_MESSAGES, {
    variables: {
      projectId,
    },
    fetchPolicy: "network-only",
  });

  const [updateWelcomeMessage] = useMutation<ResponseMessage[]>(UPDATE_WELCOME_MESSAGE);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(0);
  const [isDisableSave, setIsDisableSave] = useState(true);
  const [messages, setMessages] = useState<ResponseMessage[]>([]);
  const previousMessages = useRef<ResponseMessage[]>();

  useEffect(() => {
    if (data && data.greetingMessage) {
      setMessages(data.greetingMessage);
      previousMessages.current = data.greetingMessage;
    }
  }, [data, loading]);

  useEffect(() => {
    const newIsDisableSave = !isEqual(previousMessages.current, messages) && Boolean(previousMessages.current);

    setIsDisableSave(!newIsDisableSave);
  }, [messages, previousMessages]);

  const handleClickSave = async () => {
    const clearedMessages = clearEmptyMessage(messages);

    const removedTypeNameMessage = removeTypenameField(clearedMessages) || [];

    const cleanObjectCategoriesMessage = cleanCategoriesMessage(removedTypeNameMessage as ResponseMessage[]);

    if (isEqual(cleanCategoriesMessage, previousMessages.current)) {
      setMessages(cleanCategoriesMessage);

      return;
    }

    const result = await updateWelcomeMessage({
      variables: {
        projectId,
        messages: cleanObjectCategoriesMessage,
      },
    });

    if (!result.data) {
      notifyError(t(NOTIFICATION_UPDATE.FAIL));
    } else {
      previousMessages.current = messages;

      addGoogleTagEvent(GTM_EVENT.SET_FIRST_MESSAGE);
      setIsDisableSave(true);
      notifySuccess(t(NOTIFICATION_UPDATE.SUCCESS));
    }
  };

  const deleteMessage = (index: number | null) => {
    if (index == null) {
      return;
    }

    const newMessage = messages.filter((_message, indexMessage) => indexMessage !== index);

    setMessages(newMessage);
    setIsOpenConfirmModal(false);
    setSelectedIndex(null);
  };

  const addNewMessages = (
    type: MessageType,
    value: MessageTextValue | MessageImageValue | number[] | CategoriesObjectType | ProductsObjectType,
  ) => {
    const newWelcomeMessages: ResponseMessage[] = [...messages, { type, value }];
    const newOrderQuickReply = reorderQuickReply(newWelcomeMessages);
    setMessages(newOrderQuickReply);
  };

  const handleClickAddMessage = (text: string) => {
    addNewMessages(MessageType.TEXT, text || "");
  };

  const handleDeleteMessage = (index: number) => {
    setIsOpenConfirmModal(true);
    setSelectedIndex(index);
  };

  const handleDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newMessages = reorder<ResponseMessage>(messages, result.source.index, result.destination.index);
    const newOrderQuickReply = reorderQuickReply(newMessages);
    setMessages(newOrderQuickReply);
  };

  const handleUploadCallback = (url: string) => {
    addNewMessages(MessageType.IMAGE, { url });
  };

  const handleCloseConfirmModal = () => {
    setIsOpenConfirmModal(false);
  };

  const handleEditMessage = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;

    const newMessages = produce(messages, (draftMessages) => {
      // eslint-disable-next-line no-param-reassign
      draftMessages[Number(id)].value = value;
    });

    setMessages(newMessages);
  };

  return (
    <>
      <Layout
        currentNumber={messages.length - 1}
        limit={MAXIMUM_WELCOME_MESSAGES_LENGTH}
        handleClickSave={handleClickSave}
        isDisableSave={isDisableSave}
      >
        <MessagesManager
          messageInputVariant={MessageInputVariant.OUTLINED}
          onClickAddMessage={handleClickAddMessage}
          onDeleteMessage={handleDeleteMessage}
          onDragEnd={handleDragEnd}
          onEditMessage={handleEditMessage}
          onUploadCallback={handleUploadCallback}
          messages={messages}
          projectId={projectId}
          maxMessagesLength={MAXIMUM_WELCOME_MESSAGES_LENGTH}
          addNewMessages={addNewMessages}
          enableQuickReply
          updateQuickReplyMessage={(newMessage: QuickReplyMessage[]) =>
            setMessages(updateQuickReplyMessage(newMessage, messages))
          }
        />
      </Layout>
      <ConfirmationModal
        title={`${t("Are you sure you want to remove message no.")} ${(selectedIndex || 0) + 1} ?`}
        isOpen={isOpenConfirmModal}
        onClose={handleCloseConfirmModal}
        onSubmit={() => {
          deleteMessage(selectedIndex);
        }}
      />
    </>
  );
};

export default WelcomeMessageComponent;
