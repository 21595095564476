import { useCallback, useState } from "react";
import { TFunction } from "i18next";
import { useMutation } from "@apollo/client";
import get from "lodash/get";
import { MAX_SIZE_IMPORTED_FILE } from "config/product";
import mapErrorMessage from "domain/Product/mapErrorMessage";
import validateFileSize from "utils/common/validateFileSize";
import { notifyError } from "utils/notify";
import { IMPORT_PRODUCT_INVENTORIES } from "graphql/inventory/mutation";
import { UpdateInventoryType, ErrorImportInventory } from "types/Product";

type ErrorReserved = {
  productCode: string;
  reserved: number;
  updateInventory: number;
};

const useImportInventories = (t: TFunction, projectId: string, onCompleted?: () => void) => {
  const [importInventoryType, setImportInventoryType] = useState<UpdateInventoryType>(UpdateInventoryType.ADD);
  const [importInventories, { loading }] = useMutation(IMPORT_PRODUCT_INVENTORIES);

  const handleImportInventories = useCallback(
    async (file: File) => {
      if (file && projectId) {
        const isOverSize = validateFileSize(file, MAX_SIZE_IMPORTED_FILE);

        if (isOverSize) {
          notifyError(t("product.import.error.overSize", { maxSize: MAX_SIZE_IMPORTED_FILE }), { autoClose: 8000 });
          return;
        }

        try {
          await importInventories({ variables: { projectId, file, type: importInventoryType } });
          if (onCompleted) onCompleted();
        } catch (err) {
          const gqlErrorMessage: string = get(err, "graphQLErrors.0.message");
          const errorProductCodes: string[] | ErrorReserved[] = get(
            err,
            "graphQLErrors.0.extensions.exception.meta.productCodes",
          );
          let productCodeText = "Some product SKUs";

          switch (gqlErrorMessage) {
            case ErrorImportInventory.INVENTORY_LESS_THAN_RESERVED: {
              const productCodes = (errorProductCodes as ErrorReserved[]).map(
                (errorProductCode) => errorProductCode.productCode,
              );
              productCodeText = productCodes.join(", ");
              break;
            }

            case ErrorImportInventory.DUPLICATED_PRODUCT_SKU_CODES:
            case ErrorImportInventory.PRODUCT_SKU_CODES_NOT_EXISTED:
              productCodeText = (errorProductCodes as string[]).join(", ");
              break;
            default:
              break;
          }

          const errorMessage = mapErrorMessage(gqlErrorMessage);
          notifyError(t(errorMessage, { productCode: productCodeText }), { autoClose: 20000 });
        }
      }
    },
    [importInventories, importInventoryType, onCompleted, projectId, t],
  );

  return {
    handleImportInventories,
    importInventoryType,
    loading,
    setImportInventoryType,
  };
};

export default useImportInventories;
