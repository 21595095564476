import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Card from "components/Card";
import Grid from "components/Grid";
import Typography from "components/Typography";

import { CustomerAddressType } from "types/Customer";

import { getAddress } from "utils/address";

type CustomerAddressPropsType = {
  customerAddress: CustomerAddressType;
};

const CustomerAddress: FC<CustomerAddressPropsType> = ({ customerAddress }) => {
  const { t } = useTranslation();

  const customerAddressText = getAddress(customerAddress);
  const customerFullName = `${customerAddress?.firstName || ""} ${customerAddress?.lastName || ""}`;

  return (
    <Card fullWidth noShadow padding="24px">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="title8" color="darkLight">
            {t("Name")}
          </Typography>
          <Typography variant="body2" className="pt-1">
            {customerFullName.trim() || "-"}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="title8" color="darkLight">
            {t("Phone")}
          </Typography>
          <Typography variant="body2" className="pt-1">
            {customerAddress?.phoneNumber || "-"}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="title8" color="darkLight">
            {t("Address")}
          </Typography>
          <Typography variant="body2" className="pt-1">
            {customerAddressText || "-"}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="title8" color="darkLight">
            {t("Email")}
          </Typography>
          <Typography variant="body2" className="ellipsis pt-1">
            {customerAddress?.email || "-"}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CustomerAddress;
