import React, { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { SvgIcon } from "components/Icon";
import { IcArrowBack, IcChat } from "components/SvgIcons";
import Typography from "components/Typography";
import BackButton from "components/BackButton";
import Grid from "components/Grid";
import Card from "components/Card";
import Button from "components/Button";
import Divider from "components/Divider";
import CircularProgress from "components/CircularProgress";

import { PROJECT, CHAT, CUSTOMERS_REPORT, DASHBOARD } from "constants/Router";
import { ProjectIdType, CustomerIdType } from "types/Project";
import { Device } from "types/Device";
import useDevice from "utils/hooks/useDevice";
import { convertPriceFormat } from "utils/common";

import CustomerAddress from "./CustomerAddress";
import CustomerOrderList from "./CustomerOrder";
import CustomerTag from "./CustomerTag";
import CustomerInfo from "./CustomerInfo";
import CustomerNote from "../CustomerNote";
import useCustomer from "./hooks/useCustomer";

const CustomerReportDetailContainer: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { projectId, customerId } = useParams<ProjectIdType & CustomerIdType>();
  const device = useDevice();
  const isDesktop = device === Device.DESKTOP;

  const { isLoading, customer, customerOrders, customerOrdersTotal, customerTotalSpend } = useCustomer({
    projectId,
    customerId,
  });

  return (
    <Grid container className={isDesktop ? "p-3 d-block" : "d-block"}>
      <Grid item xs={12} className={isDesktop ? "pb-2" : ""}>
        <BackButton onClick={() => history.push(`/${PROJECT}/${projectId}/${DASHBOARD}/${CUSTOMERS_REPORT}`)}>
          <SvgIcon component={IcArrowBack} fontSize="small" />
          <Typography variant="body2" className="ml-2">
            {t("Back")}
          </Typography>
        </BackButton>
      </Grid>

      <Grid item container direction="row" className="p-3" justify="center">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {/* Left */}
            <Grid item container xs={12} lg={4} alignContent="flex-start">
              <Grid item xs={12} className="pb-3">
                <CustomerInfo isDesktop={isDesktop} customer={customer} projectId={projectId} customerId={customerId} />
              </Grid>
              <Grid item xs={12} className="pb-3">
                <Button
                  fullWidth
                  size="large"
                  color="secondary"
                  onClick={() => history.push(`/${PROJECT}/${projectId}/${CHAT}/${customerId}`)}
                >
                  <SvgIcon className="ml-1 mr-2 mt-1" component={IcChat} fontSize="small" /> {t("Chat")}
                </Button>
              </Grid>
              <Grid item xs={12} className="pb-3">
                <CustomerAddress customerAddress={customer?.address} />
              </Grid>
              <Grid item xs={12}>
                <Card fullWidth noShadow padding="0px">
                  <Grid container>
                    <Grid item container justify="space-between" className="p-4">
                      <Typography variant="title8" color="darkLight">
                        {t("customerReportDetail.orderTotal.title")}
                      </Typography>
                      <Typography variant="body2">{customerOrdersTotal}</Typography>
                    </Grid>
                    <Divider thickness="0.5px" className="w-100 m-0 p-0" />
                    <Grid item container justify="space-between" className="p-4">
                      <Typography variant="title8" color="darkLight">
                        {t("customerReportDetail.totalSpend.title")}
                      </Typography>
                      <Typography variant="body2">
                        {t("THB")} {convertPriceFormat(customerTotalSpend)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            {/* Right */}
            <Grid item container xs={12} lg={8} alignContent="flex-start" className={isDesktop ? "pl-3" : "pt-3"}>
              <Grid item xs={12} className="pb-3">
                <CustomerTag projectId={projectId} customerId={customerId} />
              </Grid>
              <Grid item xs={12} className="pb-3">
                <CustomerNote isDesktop={isDesktop} projectId={projectId} customerId={customerId} />
              </Grid>
              <Grid item xs={12}>
                <CustomerOrderList projectId={projectId} customerOrders={customerOrders} />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default CustomerReportDetailContainer;
