import * as React from "react";

const SvgIcAiCategory = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M30.22 24.559v4.83c0 .46-.373.833-.833.833h-3.592v-1.238l4.425-4.425zm-15.398-4.934v.475c0 .524.213.996.557 1.336l7.67 7.627c.283.284.66.479 1.078.536l.001.623h-9.25a.833.833 0 01-.833-.833v-8.934c0-.46.373-.834.834-.834l-.057.004zm-3.277-.004c.46 0 .834.373.834.834v8.934c0 .46-.373.833-.834.833H2.611a.833.833 0 01-.833-.833v-8.934c0-.46.373-.834.833-.834zm11.25-6.843c.415 0 .792.17 1.068.441l6.79 6.791a1.51 1.51 0 01.005 2.14l-5.285 5.285a1.513 1.513 0 01-1.068.442c-.416 0-.793-.17-1.065-.442l-6.795-6.794A1.494 1.494 0 0116 19.572v-5.284c0-.835.676-1.51 1.51-1.51zm-14.76 8.744H6.37a.833.833 0 100 1.667h1.666a.833.833 0 100-1.667zM18.532 7.866V11.6h-1.82a1.888 1.888 0 00-1.89 1.889v4.465H9.32a.834.834 0 01-.827-.728l-.007-.105V7.866h10.046zm.11 6.422a1.13 1.13 0 00-1.132 1.132 1.13 1.13 0 001.132 1.133 1.13 1.13 0 001.133-1.133 1.13 1.13 0 00-1.133-1.132zm5.98-6.422l-.001 4.252-.082-.074a1.895 1.895 0 00-1.217-.444h-3.124V7.866h4.423zm-10.28 1.666h-1.666a.833.833 0 100 1.667h1.666a.833.833 0 000-1.667zm-4.179-7.754c.333 0 .633.197.765.502l1.696 3.92h-4.57l-1.41-3.257a.834.834 0 01.765-1.164zm15.536 0a.833.833 0 01.765 1.164L25.054 6.2h-4.57l1.696-3.92a.833.833 0 01.765-.502z"
    />
  </svg>
);

export default SvgIcAiCategory;
