import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { NO_IMG_AVAILABLE } from "constants/Image";
import { CONSOLE_GENERATED_FORM } from "constants/i18n";
import Card from "components/Card";
import Typography from "components/Typography";
import { getProductSKUsKeyValueFromProductSKUs, getProductPriceRangeFromProductSKUs } from "utils/common";
import { ProductSKUType, ProductType } from "types/Product";
import get from "lodash/get";
import nanoid from "utils/nanoid";
import SKUTableHeader from "../ConsoleGeneratedForm/SKUTableHeader";
import SKUsWrapper from "../ConsoleGeneratedForm/SKUsWrapper.styled";
import ProductProfile from "../ConsoleGeneratedForm/ProductProfile";
import ProductSKUCard from "./ProductSKUCard";

type ConsoleGeneratedFormPropsType = {
  comment?: string;
  productName: string;
  productImage?: string;
  productSKUs: ProductSKUType[];
  onChangeDeepLink: (nanoid: string, link: string) => void;
  setProductSKUs: Function;
  productType: ProductType;
  isEditMode: boolean;
  isShadowSKU: boolean;
};

const DeepLinkForm: FC<ConsoleGeneratedFormPropsType> = ({
  productName,
  productImage,
  productSKUs,
  onChangeDeepLink,
  setProductSKUs,
  productType,
  isEditMode,
  isShadowSKU,
}) => {
  const { t } = useTranslation(CONSOLE_GENERATED_FORM);
  const variations = getProductSKUsKeyValueFromProductSKUs(productSKUs);
  const productPrice = getProductPriceRangeFromProductSKUs(productSKUs, 0, t);

  return (
    <Card borderRadius={0} noBorder noShadow padding="24px 0px">
      <Typography variant="title4" className="mb-3">
        {t("deliverable")}
      </Typography>
      <Card padding="0" noShadow>
        {!isShadowSKU && (
          <>
            <ProductProfile
              productName={productName}
              productImage={productImage || NO_IMG_AVAILABLE}
              variations={variations}
              price={productPrice}
            />
            <SKUTableHeader />
          </>
        )}
        <SKUsWrapper>
          {productSKUs.map((productSKU, index) => {
            return (
              <ProductSKUCard
                index={index}
                key={productSKU.nanoid || productSKU.id}
                productSKU={productSKU}
                onChangeDeepLink={onChangeDeepLink}
                deepLink={get(productSKU, "digitalContentOptions.settings.content")}
                nanoid={productSKU.nanoid || nanoid()}
                productSKUs={productSKUs}
                setProductSKUs={setProductSKUs}
                productType={productType}
                isEditMode={isEditMode}
                isShadowSKU={isShadowSKU}
              />
            );
          })}
        </SKUsWrapper>
      </Card>
    </Card>
  );
};

export default DeepLinkForm;
