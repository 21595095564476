import { useEffect, useState } from "react";

export enum OrientationType {
  Landscape = "landscape",
  Portrait = "portrait",
}

type ScreenSize = {
  width: number;
  height: number;
};

const useOrientation = () => {
  const checkOrientation = () =>
    window.innerWidth > window.innerHeight ? OrientationType.Landscape : OrientationType.Portrait;
  const [orientation, setOrientation] = useState(checkOrientation());
  const [screenSize, setScreenSize] = useState<ScreenSize>({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    const handleOrientationchange = () => {
      setTimeout(() => {
        setScreenSize({ width: window.innerWidth, height: window.innerHeight });
        setOrientation(checkOrientation());
      }, 100);
    };
    window.addEventListener("resize", handleOrientationchange);
    return () => {
      window.removeEventListener("resize", handleOrientationchange);
    };
  }, [orientation]);

  return { orientation, screenSize };
};

export default useOrientation;
