import React, { FC, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { useFormContext } from "react-hook-form";

import { CONSOLE_GENERATED_FORM } from "constants/i18n";
import { COLORS } from "constants/Colors";
import { CircleIcon } from "components/Icon";
import Grid from "components/Grid";
import Typography from "components/Typography";
import TextField from "components/TextField";
import ErrorText from "components/ErrorText";
import Switch from "components/Switch";
import Card from "components/Card";
import { NumberFormatCustom } from "components/NumberFormatCustom";
import ImageUpload from "components/ImageUpload";

import { convertProductTypeToText, convertPriceFormat } from "utils/common";
import { ProductSKUType, AddProductSKUType, ProductType, ProductSKUInputDataType } from "types/Product";
import { ImageUrl } from "types/Image";

type ProductSKUCardPropsType = {
  deepLink?: string;
  index: number;
  isEditMode: boolean;
  isShadowSKU: boolean;
  nanoid: string;
  onChangeDeepLink: (nanoid: string, deepLink: string) => void;
  productSKU: ProductSKUType;
  productSKUs: AddProductSKUType[];
  productType: ProductType;
  setProductSKUs: Function;
};

const ProductSKUCard: FC<ProductSKUCardPropsType> = ({
  deepLink = "",
  index,
  isShadowSKU,
  nanoid,
  onChangeDeepLink,
  productSKU,
  productSKUs,
  setProductSKUs,
}) => {
  const { t } = useTranslation(CONSOLE_GENERATED_FORM);

  const statusColor = productSKU.selected ? COLORS.Success : COLORS.DarkMed;

  const { errors } = useFormContext();

  const handleChangeDeepLink = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeDeepLink(get(productSKU, "nanoid") || productSKU.id, event.target.value);
  };

  const handleDataChange = (dataType: ProductSKUInputDataType, value: number | boolean | string) => {
    const newProductSKUs = productSKUs.map((productSKU: AddProductSKUType) => {
      const returnProductSKUs = { ...productSKU };
      if (returnProductSKUs.nanoid === nanoid) {
        switch (dataType) {
          case ProductSKUInputDataType.PRICE:
            returnProductSKUs.price = Number(value);
            break;
          case ProductSKUInputDataType.SELECTED:
            returnProductSKUs.selected = Boolean(value);
            break;
          case ProductSKUInputDataType.IS_FREE:
            returnProductSKUs.isFree = Boolean(value);
            break;
          case ProductSKUInputDataType.PRODUCT_CODE:
            returnProductSKUs.productCode = value.toString();
            break;
          case ProductSKUInputDataType.PRODUCT_CF_CODE:
            returnProductSKUs.cfCode = value.toString() || null;
            break;
          case ProductSKUInputDataType.IMAGE:
            returnProductSKUs.image = value.toString();
            break;
          case ProductSKUInputDataType.WEIGHT:
            returnProductSKUs.weight = value ? Number(value) : 0;
            break;
          default:
            break;
        }
      }
      return returnProductSKUs;
    });

    setProductSKUs(newProductSKUs);
  };

  const handleChangeImage = (imageSources: ImageUrl[]) => {
    if (imageSources.length) {
      const [{ publicUrl }] = imageSources;
      handleDataChange(ProductSKUInputDataType.IMAGE, publicUrl);
    }
  };

  const errorProductCode = get(errors, `productSKUs[${index}].productCode.message`);
  const errorProductCfCode = get(errors, `productSKUs[${index}].cfCode.message`);
  const errorProductContent = get(errors, `productSKUs[${index}].digitalContentOptions.settings.content`);

  return (
    <Grid container key={productSKU.nanoid || productSKU.id} className="sku-card">
      {!isShadowSKU && (
        <Grid container item className="mx-3">
          <Grid item xs={3} className="pt-3">
            <CircleIcon color={statusColor} />
            <Typography className="d-inline" variant="body3">
              {productSKU.productCode}
            </Typography>
          </Grid>
          <Grid item xs={3} className="pt-3">
            <Typography variant="body3">{convertProductTypeToText(productSKU.productType)}</Typography>
          </Grid>
          <Grid item xs={3} className="pt-3">
            <Typography variant="body3">฿{convertPriceFormat(productSKU.price, 0)}</Typography>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        <Card className="m-3" padding="0px 16px">
          {!isShadowSKU && (
            <>
              <Grid container justify="flex-start" alignItems="center" className="mt-4">
                <Switch
                  data-cy="toggleProductSKU"
                  checked={productSKU.selected}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleDataChange(ProductSKUInputDataType.SELECTED, event.target.checked);
                  }}
                />
                <Typography variant="body4" className="ml-2" color={COLORS.DarkMed}>
                  {t(productSKU.selected ? "Active" : "Disabled")}
                </Typography>
              </Grid>
              <Grid container item xs={12} alignContent="center" alignItems="center">
                <Grid container item className="pr-3 flex-0" justify="flex-start">
                  <Grid item xs={12}>
                    <ImageUpload
                      onChange={handleChangeImage}
                      onRemove={() => handleDataChange(ProductSKUInputDataType.IMAGE, "")}
                      image={productSKU.image}
                      width={120}
                      height={150}
                    />
                  </Grid>
                </Grid>

                <Grid container item className="flex-1">
                  <Grid item xs={12}>
                    <Typography className="d-flex" variant="body4">
                      {t("productSKUCode")}
                    </Typography>
                    <TextField
                      data-cy="productSKUCode"
                      className="my-2"
                      variant="outlined"
                      fullWidth
                      placeholder={t("productSKUCode")}
                      value={productSKU.productCode}
                      error={Boolean(errorProductCode)}
                      helperText={t(errorProductCode)}
                      onChange={(event) => {
                        handleDataChange(ProductSKUInputDataType.PRODUCT_CODE, event.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography className="d-flex" variant="body4">
                      {t("Product SKU CF code")} - {t("product.cfCode.optional")}
                    </Typography>
                    <TextField
                      data-cy="productSKUCFCode"
                      className="my-2"
                      variant="outlined"
                      fullWidth
                      placeholder={t("Please put CF Code here")}
                      value={productSKU.cfCode}
                      error={Boolean(errorProductCfCode)}
                      helperText={t(errorProductCfCode)}
                      onChange={(event) => {
                        handleDataChange(ProductSKUInputDataType.PRODUCT_CF_CODE, event.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body4" color={COLORS.DarkMed}>
                      {t("price")}
                    </Typography>
                    <TextField
                      className="my-2"
                      data-cy="ProductSKUPrice"
                      fullWidth
                      placeholder={t("price")}
                      value={productSKU.price}
                      onChange={(event) => {
                        handleDataChange(
                          ProductSKUInputDataType.PRICE,
                          +parseFloat(event.target.value).toFixed(2) || 0.0,
                        );
                      }}
                      variant="outlined"
                      InputProps={{
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        inputComponent: NumberFormatCustom as any,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Card noShadow noBorder bgColor={COLORS.LightWhite} className="p-3">
              <TextField
                variant="outlined"
                fullWidth
                onChange={handleChangeDeepLink}
                placeholder={t("insertLink")}
                value={deepLink}
                style={{ background: COLORS.White }}
              />
              <ErrorText className="mt-1 d-flex">{t(errorProductContent && errorProductContent.message)}</ErrorText>
            </Card>
          </Grid>
          {!isShadowSKU && (
            <Grid container justify="space-between" className="my-4">
              <Typography variant="body4" className="mt-2" color={COLORS.DarkMed}>
                {t("This product SKU is free")}
              </Typography>
              <Switch
                data-cy="toggleFreeProduct"
                checked={productSKU.isFree}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleDataChange(ProductSKUInputDataType.IS_FREE, event.target.checked);
                }}
              />
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProductSKUCard;
