import React, { FC } from "react";
import { useController, UseControllerOptions } from "react-hook-form";
import TimePicker from "./index";

const ControllerTimePicker: FC<
  UseControllerOptions & {
    isError?: boolean;
    disabled?: boolean;
    onChange?: (time: string) => void;
    sizeAuto?: boolean;
  }
> = (props) => {
  const { onChange, disabled, isError, sizeAuto = false } = props;
  const { field } = useController(props);

  const handleChange = (time: string) => {
    field.onChange(time);

    if (onChange) {
      onChange(time);
    }
  };

  return (
    <TimePicker
      name={field.name}
      value={field.value}
      onChange={handleChange}
      disabled={Boolean(disabled)}
      inputRef={field.ref}
      isError={Boolean(isError)}
      sizeAuto={sizeAuto}
    />
  );
};

export default ControllerTimePicker;
