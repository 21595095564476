import {
  CONGRATULATIONS,
  CONSOLE_GENERATED_FORM,
  PLACE_ORDER_TO_LALAMOVE,
  REGISTER,
  REGISTER_WITH_EMAIL,
  REPLY_SHORTCUT,
  SEND_MESSAGE,
  STORE_CONNECT,
  RICH_MENU,
  NEW_FORGOT_PASSWORD,
  NEW_LOGIN,
  NEW_RESET_PASSWORD,
  NEW_REGISTER,
  NEW_VERIFY_EMAIL,
  NEW_QUICK_START,
  CONNECT_FACEBOOK_PAGE,
  CONNECT_INSTAGRAM_BUSINESS,
  UPDATE_CONNECT_CREDENTIAL,
} from "constants/i18n";
import accountSettings from "./accountSettings";
import addon from "./addon";
import addressForm from "./addressForm";
import bank from "./bank";
import broadcast from "./broadcast";
import congratulations from "./congratulations";
import campaign from "./campaign";
import category from "./category";
import chat from "./chat";
import replyShortcut from "./replyShortcut";
import common from "./common";
import contentManagement from "./contentManagement";
import customer from "./customer";
import customerDetail from "./customerDetail";
import enterprise from "./enterprise";
import errorMessage from "./errorMessage";
import facebookAutoEngagement from "./facebookAutoEngagement";
import facebookLive from "./facebookLive";
import faq from "./faq";
import freeTrial from "./freeTrial";
import forgotPassword from "./forgotPassword";
import guideline from "./guideline";
import information from "./information";
import inventory from "./inventory";
import integration from "./integration";
import login from "./login";
import messageManager from "./messageManager";
import needVerifyEmail from "./needVerifyEmail";
import notification from "./notification";
import optionSelector from "./optionSelector";
import orders from "./orders";
import page404 from "./404";
import parcelInvoiceReport from "./parcelInvoiceReport";
import payment from "./payment";
import planBilling from "./planBilling";
import responseMessage from "./responseMessage";
import planUsage from "./planUsage";
import placeOrderToLalamove from "./placeOrderToLalamove";
import productReport from "./productReport";
import products from "./products";
import project from "./project";
import promotion from "./promotion";
import register from "./register";
import registerWithEmail from "./registerWithEmail";
import resetPassword from "./resetPassword";
import richMenu from "./richMenu";
import salesReport from "./salesReport";
import scoreStatus from "./scoreStatus";
import setting from "./setting";
import shipping from "./shipping";
import storeConnect from "./storeConnect";
import shoppingCart from "./shoppingCart";
import sideNavMenu from "./sideNavMenu";
import stat from "./stat";
import synonym from "./synonym";
import topSellingProduct from "./topSellingProduct";
import uploadImage from "./uploadImage";
import upSelling from "./upSelling";
import vat from "./vat";
import verifyEmail from "./verifyEmail";
import wallet from "./wallet";
import welcomeMessage from "./welcomeMessage";
import consoleGeneratedForm from "./consoleGeneratedForm";
import importProduct from "./importProduct";
import billing from "./billing";
import promotionForm from "./promotionForm";
import termAndCondition from "./termAndCondition";
import deeplePay from "./deeplePay";
import newLogIn from "./newLogIn";
import newForgotPassword from "./newForgotPassword";
import newResetPassword from "./newResetPassword";
import newRegister from "./newRegister";
import newVerifyEmail from "./newVerifyEmail";
import newQuickStart from "./newQuickStart";
import connectFacebookPage from "./connectFacebookPage";
import connectInstagramBusiness from "./connectInstagramBusiness";
import updateConnectCredential from "./updateConnectCredential";
import payments from "./payments";
import replyMessageInput from "./replyMessageInput";
import search from "./search";

const translate = {
  en: {
    [CONGRATULATIONS]: congratulations.en,
    [RICH_MENU]: richMenu.en,
    [CONSOLE_GENERATED_FORM]: consoleGeneratedForm.en,
    [REGISTER]: register.en,
    [REGISTER_WITH_EMAIL]: registerWithEmail.en,
    [STORE_CONNECT]: storeConnect.en,
    [PLACE_ORDER_TO_LALAMOVE]: placeOrderToLalamove.en,
    [REPLY_SHORTCUT]: replyShortcut.en,
    [SEND_MESSAGE]: chat.en,
    [NEW_FORGOT_PASSWORD]: newForgotPassword.en,
    [NEW_LOGIN]: newLogIn.en,
    [NEW_RESET_PASSWORD]: newResetPassword.en,
    [NEW_REGISTER]: newRegister.en,
    [NEW_VERIFY_EMAIL]: newVerifyEmail.en,
    [NEW_QUICK_START]: newQuickStart.en,
    [CONNECT_FACEBOOK_PAGE]: connectFacebookPage.en,
    [CONNECT_INSTAGRAM_BUSINESS]: connectInstagramBusiness.en,
    [UPDATE_CONNECT_CREDENTIAL]: updateConnectCredential.en,
    translations: {
      ...accountSettings.en,
      ...addon.en,
      ...addressForm.en,
      ...bank.en,
      ...billing.en,
      ...broadcast.en,
      ...campaign.en,
      ...category.en,
      ...chat.en,
      ...common.en,
      ...contentManagement.en,
      ...customer.en,
      ...customerDetail.en,
      ...enterprise.en,
      ...errorMessage.en,
      ...facebookAutoEngagement.en,
      ...facebookLive.en,
      ...faq.en,
      ...forgotPassword.en,
      ...freeTrial.en,
      ...guideline.en,
      ...importProduct.en,
      ...information.en,
      ...integration.en,
      ...inventory.en,
      ...login.en,
      ...messageManager.en,
      ...needVerifyEmail.en,
      ...notification.en,
      ...optionSelector.en,
      ...orders.en,
      ...page404.en,
      ...parcelInvoiceReport.en,
      ...payment.en,
      ...planBilling.en,
      ...planUsage.en,
      ...productReport.en,
      ...products.en,
      ...project.en,
      ...promotion.en,
      ...promotionForm.en,
      ...register.en,
      ...resetPassword.en,
      ...responseMessage.en,
      ...richMenu.en,
      ...salesReport.en,
      ...scoreStatus.en,
      ...setting.en,
      ...shipping.en,
      ...shoppingCart.en,
      ...sideNavMenu.en,
      ...stat.en,
      ...synonym.en,
      ...termAndCondition.en,
      ...topSellingProduct.en,
      ...uploadImage.en,
      ...upSelling.en,
      ...vat.en,
      ...verifyEmail.en,
      ...wallet.en,
      ...welcomeMessage.en,
      ...importProduct.en,
      ...billing.en,
      ...termAndCondition.en,
      ...deeplePay.en,
      ...payments.en,
      ...replyMessageInput.en,
      ...search.en,
    },
  },
  th: {
    [CONGRATULATIONS]: congratulations.th,
    [RICH_MENU]: richMenu.th,
    [CONSOLE_GENERATED_FORM]: consoleGeneratedForm.th,
    [REGISTER]: register.th,
    [REGISTER_WITH_EMAIL]: registerWithEmail.th,
    [STORE_CONNECT]: storeConnect.th,
    [PLACE_ORDER_TO_LALAMOVE]: placeOrderToLalamove.th,
    [REPLY_SHORTCUT]: replyShortcut.th,
    [SEND_MESSAGE]: chat.th,
    [NEW_FORGOT_PASSWORD]: newForgotPassword.th,
    [NEW_LOGIN]: newLogIn.th,
    [NEW_RESET_PASSWORD]: newResetPassword.th,
    [NEW_REGISTER]: newRegister.th,
    [NEW_VERIFY_EMAIL]: newVerifyEmail.th,
    [NEW_QUICK_START]: newQuickStart.th,
    [CONNECT_FACEBOOK_PAGE]: connectFacebookPage.th,
    [CONNECT_INSTAGRAM_BUSINESS]: connectInstagramBusiness.th,
    [UPDATE_CONNECT_CREDENTIAL]: updateConnectCredential.th,
    translations: {
      ...accountSettings.th,
      ...addon.th,
      ...addressForm.th,
      ...bank.th,
      ...billing.th,
      ...broadcast.th,
      ...campaign.th,
      ...category.th,
      ...chat.th,
      ...common.th,
      ...contentManagement.th,
      ...customer.th,
      ...customerDetail.th,
      ...enterprise.th,
      ...errorMessage.th,
      ...facebookAutoEngagement.th,
      ...facebookLive.th,
      ...faq.th,
      ...forgotPassword.th,
      ...freeTrial.th,
      ...guideline.th,
      ...importProduct.th,
      ...information.th,
      ...integration.th,
      ...inventory.th,
      ...login.th,
      ...messageManager.th,
      ...needVerifyEmail.th,
      ...notification.th,
      ...optionSelector.th,
      ...orders.th,
      ...page404.th,
      ...parcelInvoiceReport.th,
      ...payment.th,
      ...planBilling.th,
      ...planUsage.th,
      ...productReport.th,
      ...products.th,
      ...project.th,
      ...promotion.th,
      ...promotionForm.th,
      ...register.th,
      ...resetPassword.th,
      ...responseMessage.th,
      ...richMenu.th,
      ...salesReport.th,
      ...scoreStatus.th,
      ...setting.th,
      ...shipping.th,
      ...shoppingCart.th,
      ...sideNavMenu.th,
      ...stat.th,
      ...synonym.th,
      ...termAndCondition.th,
      ...topSellingProduct.th,
      ...uploadImage.th,
      ...upSelling.th,
      ...vat.th,
      ...verifyEmail.th,
      ...wallet.th,
      ...welcomeMessage.th,
      ...importProduct.th,
      ...billing.th,
      ...termAndCondition.th,
      ...deeplePay.th,
      ...payments.th,
      ...replyMessageInput.th,
      ...search.th,
    },
  },
};

export { translate };
