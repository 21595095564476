import React, { FC } from "react";

import Avatar from "components/Avatar";
import Grid from "components/Grid";
import Typography from "components/Typography";

import { NO_IMG_DEEPLE_LOGO } from "constants/Image";
import { COLORS } from "constants/Colors";

import { ProductImage } from "domain/MarketingTools/TopSellingProduct/styled";
import { CardListItemWrapper } from "../index.styled";

type TopSellingProductListItemPropsType = {
  sequence: number;
  name: string;
  productImage: string;
};

const TopSellingProductListItem: FC<TopSellingProductListItemPropsType> = (props) => {
  const { sequence, name, productImage } = props;

  return (
    <CardListItemWrapper container item xs={12} alignItems="center" className="py-3">
      <Grid item container alignItems="center">
        <Avatar src="" size="30px" color={sequence <= 3 ? COLORS.PrimaryNude : COLORS.LightGray}>
          <Typography variant="title8" color={sequence <= 3 ? COLORS.Primary : COLORS.DarkMed}>
            {sequence}
          </Typography>
        </Avatar>
        <ProductImage src={productImage || NO_IMG_DEEPLE_LOGO} className="mx-3" />
        <Grid item container xs={7} justify="flex-end" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="title7">{name}</Typography>
          </Grid>
          {/* TODO: display order value when fix top selling API */}
          {/* <Grid item xs={12} className="pt-1">
            <Typography variant="body4" color="darkMed">
              xxx {t("Orders")}
            </Typography>
          </Grid> */}
        </Grid>
      </Grid>
    </CardListItemWrapper>
  );
};

export default TopSellingProductListItem;
