const newVerifyEmail = {
  en: {
    PLEASE_CONFIRM_YOUR_EMAIL_ADDRESS: "Please confirm your email address",
    WE_SENT_EMAIL_TO: "We sent an email to",
    SO_WE_CAN_CONFIRM: "So we can confirm you're you.",
    CLICK_BUTTON_IN_EMAIL: "Click the button in the email to continue on your way.",
    CAN_NOT_FIND_IT: "Can’t find it",
    RESEND_EMAIL: "Resend Email",
    EMAIL_ALREADY_VERIFIED: "This email is already verified",
    BACK_TO_LOGIN: "Back to login",
  },
  th: {
    PLEASE_CONFIRM_YOUR_EMAIL_ADDRESS: "กรุณายืนยันอีเมลของคุณ",
    WE_SENT_EMAIL_TO: "ระบบได้ส่งอีเมลไปยัง",
    SO_WE_CAN_CONFIRM: "อย่าลืมตรวจเช็กอีเมลของคุณ",
    CLICK_BUTTON_IN_EMAIL: "และกดปุ่มยืนยันตัวตน เพื่อเริ่มต้นใช้งานระบบของเรา",
    CAN_NOT_FIND_IT: "ไม่พบอีเมลดังกล่าว",
    RESEND_EMAIL: "ส่งอีเมลใหม่",
    EMAIL_ALREADY_VERIFIED: "อีเมลนี้เคยถูกยืนยันแล้ว",
    BACK_TO_LOGIN: "ไปหน้าเข้าสู่ระบบ",
  },
};

export default newVerifyEmail;
