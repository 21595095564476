import React, { CSSProperties } from "react";
import styled from "styled-components";

import { AsyncPaginate } from "react-select-async-paginate";

import { OptionType, StylesConfig, Props as SelectPropsType } from "react-select";
import { OptionStateType, ControlStateType } from "types/ReactSelect";

import Colors from "constants/Colors";
import { Z_INDEX } from "constants/ZIndex";

const getStyle = (isDisabled = false): StylesConfig => {
  return {
    control: (styles: CSSProperties, state: ControlStateType) => ({
      ...styles,
      width: "100%",
      minHeight: "48px",
      lineHeight: 2,
      backgroundColor: isDisabled ? Colors.Light : "unset",
      borderRadius: "8px",
      border: `solid 1px ${state.isFocused ? Colors.Primary : Colors.LightBlue}`,
      color: Colors.DarkGray,
      boxShadow: "0px",
      ":hover": {
        borderColor: state.isFocused ? Colors.Primary : Colors.LightBlue,
        color: Colors.DarkLight,
      },
      ":active": {
        borderColor: Colors.Primary,
        color: Colors.DarkGray,
      },
      input: {
        lineHeight: 1.5,
      },
    }),
    indicatorSeparator: (styles: CSSProperties) => ({
      ...styles,
      width: 0,
    }),
    menuList: (styles: CSSProperties) => ({
      ...styles,
      padding: 0,
      borderRadius: "8px",
    }),
    menu: (styles: CSSProperties) => ({
      ...styles,
      borderRadius: "8px",
      border: `solid 1px ${Colors.Primary}`,
      boxShadow: "0 5px 10px 0 Colors.BoxShadow",
      cursor: "pointer",
      width: "100%",
      zIndex: Z_INDEX.SELECT_MENU,
    }),
    groupHeading: (styles: CSSProperties) => ({
      ...styles,
      color: Colors.Primary,
      backgroundColor: Colors.PrimaryNude,
      padding: "12px 14px",
      fontWeight: 500,
      textTransform: "none",
    }),
    group: (styles: CSSProperties) => ({
      ...styles,
      padding: 0,
    }),
    option: (styles: CSSProperties, state: OptionStateType) => {
      let backgroundColor = "unset";
      let color = Colors.DarkGray;
      let cursor = "default";

      if (isDisabled) {
        backgroundColor = Colors.Light;
        color = Colors.LightBlue;
      } else if (state.isSelected) {
        backgroundColor = Colors.Primary;
        color = Colors.White;
      }

      if (isDisabled) {
        cursor = "not-allowed";
      }

      return {
        ...styles,
        width: "100%",
        height: "48px",
        display: "flex",
        alignItems: "center",
        backgroundColor,
        color,
        cursor,
        ":hover": {
          cursor: "pointer",
        },
        ":active": {},
      };
    },
    singleValue: (styles: CSSProperties) => ({
      ...styles,
      cursor: "pointer",
      width: "100%",
    }),
    multiValue: (styles: CSSProperties) => ({
      ...styles,
      minHeight: "24px",
      display: "flex",
      alignItems: "center",
      backgroundColor: "none",
      border: "none",
    }),
    multiValueLabel: (styles: CSSProperties) => ({
      ...styles,
      color: Colors.Primary,
      fontSize: "100%",
      padding: "0px",
    }),
    multiValueRemove: (styles: CSSProperties) => ({
      ...styles,
      display: "none",
    }),
    noOptionsMessage: (styles: CSSProperties) => ({
      ...styles,
      color: Colors.DarkLight,
    }),
    placeholder: (styles: CSSProperties): CSSProperties => ({
      ...styles,
      color: isDisabled ? Colors.LightBlue : Colors.DarkLight,
    }),
  };
};

const StyledSelect = styled(AsyncPaginate)``;

const SelectWithAsyncPaginate = <T extends OptionType>(props: SelectPropsType<T>) => {
  const { isDisabled } = props;
  const style = getStyle(isDisabled);

  return <StyledSelect {...props} styles={style} />;
};

export default SelectWithAsyncPaginate;
