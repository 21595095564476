import * as React from "react";

const IcFacebookGrey = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" width="24" height="24" rx="12" fill="#6F7794" />
    <path
      d="M13.3015 19.9999V12.7016H15.7513L16.118 9.85726H13.3015V8.04126C13.3015 7.21779 13.5302 6.65655 14.7111 6.65655L16.2173 6.65585V4.11195C15.9567 4.07742 15.0627 4 14.0226 4C11.851 4 10.3643 5.32545 10.3643 7.75971V9.85733H7.9082V12.7016H10.3642V20L13.3015 19.9999Z"
      fill="white"
    />
  </svg>
);
export default IcFacebookGrey;
