import * as React from "react";

const SvgIcChat = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.359 9C25.04 9.1 28 12.507 28 16.703c0 3.279-1.623 5.961-4.17 7.077-.26.112.756 3.041.676 3.22-3.003-1.567-5.192-2.81-6.022-3.22-.973-.477-1.812-1.222-2.484-2.13.238-.126.446-.236.593-.308 3.196-1.573 5.26-5.14 5.26-9.087 0-1.142-.18-2.234-.494-3.255zM12 5c4.419 0 8 3.45 8 7.708 0 3.167-1.98 5.888-4.816 7.074-.974.408-3.548 1.652-7.078 3.218-.094-.18 1.1-3.108.794-3.218C5.907 18.667 4 15.985 4 12.708 4 8.45 7.582 5 12 5z"
    />
  </svg>
);

export default SvgIcChat;
