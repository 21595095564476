import React from "react";
import styled from "styled-components";
import COLORS from "constants/Colors";
import Typography from "components/Typography";

const UnitPriceAdornmentWrapper = styled.div`
  background: ${({ theme }) => `${theme.COLORS.Light}`};
  padding: 15px 18px;
  margin: 0px 10px 0px -14px;
  border-radius: 8px 0px 0px 8px;
  border-right: ${({ theme }) => ` solid 1px  ${theme.COLORS.LightBlue}`};
`;

type UnitPriceAdornmentProps = {
  unit?: string;
};

export const UnitPriceAdornment = ({ unit = "฿" }: UnitPriceAdornmentProps) => {
  return (
    <UnitPriceAdornmentWrapper>
      <Typography variant="body3" color={COLORS.DarkMed}>
        {unit}
      </Typography>
    </UnitPriceAdornmentWrapper>
  );
};
