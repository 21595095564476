import { useQuery } from "@apollo/client";

import { POST } from "graphql/facebookCrawler/query";
import { CrawlerType } from "types/FacebookCrawler";

export const useFetchFacebookPostByPostId = (projectId: string, postId: string, type: CrawlerType | null) => {
  const { data: postPreviewData, loading: isPostPreviewDataLoading } = useQuery(POST, {
    skip: !projectId || !postId || !type,
    variables: {
      postId,
      projectId,
      type,
    },
    fetchPolicy: "network-only",
  });

  return { postPreviewData, isPostPreviewDataLoading };
};
