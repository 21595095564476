import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import Typography from "components/Typography";

import { PlanUsagePackageGroupType, UsagePackageGroupType } from "types/PlanUsage";

import UsageQuotaCard from "../UsageQuotaCard";

type UsageQuotaGroupPropsType = {
  usageData: UsagePackageGroupType;
};

const UsageQuotaGroup: FC<UsageQuotaGroupPropsType> = ({ usageData }) => {
  const { t } = useTranslation();

  const PLAN_USAGE_PACKAGES: PlanUsagePackageGroupType[] = [
    PlanUsagePackageGroupType.MAIN,
    PlanUsagePackageGroupType.IMAGE_SEARCH,
    PlanUsagePackageGroupType.LINE_MESSAGE,
    PlanUsagePackageGroupType.MANUAL_APPROVE_ORDER,
  ];

  return (
    <Grid container>
      {PLAN_USAGE_PACKAGES.map((planUsagePackage: PlanUsagePackageGroupType, index: number) => {
        if (usageData[planUsagePackage]) {
          const { name, details } = usageData[planUsagePackage];

          // don't show this section when quota is unlimited
          if (planUsagePackage === PlanUsagePackageGroupType.MANUAL_APPROVE_ORDER && details[0].limit === -1) {
            return null;
          }

          return (
            <Grid container key={planUsagePackage}>
              <Grid item container className={`${index ? "pt-3" : ""}`}>
                <Grid item className="pl-1">
                  <Typography variant="body3" color="darkGray">
                    {t(`planUsage.label.yourPlan.${planUsagePackage}`)}
                  </Typography>
                </Grid>
                <Grid item className="ml-1">
                  <Typography variant="body3" color="primary">
                    {name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} className="pt-3">
                <UsageQuotaCard details={details} />
              </Grid>
            </Grid>
          );
        }
        return null;
      })}
    </Grid>
  );
};

export default UsageQuotaGroup;
