import MuiButtonBase from "@material-ui/core/ButtonBase";
import TextField from "@material-ui/core/TextField";

import styled from "styled-components";

export const TextInput = styled(TextField)`
  input,
  textarea {
    padding: 12px;
    box-sizing: border-box;
  }

  .MuiInput-underline:before,
  .MuiInput-underline:hover:not(.Mui-disabled):before,
  .Mui-focused:after {
    border-bottom: 0;
  }
`;

enum MessageInputVariant {
  STANDARD = "standard",
  OUTLINED = "outlined",
}

export const Wrapper = styled.div<{ variant?: MessageInputVariant }>`
  ${({ variant, theme }) => {
    if (variant === MessageInputVariant.OUTLINED) {
      return `
      border-radius: 5px;
      border: 1px solid ${theme.COLORS.LightBlue};
      `;
    }

    return `
      border-top: 1px solid ${theme.COLORS.LightBlue};
      `;
  }}
  background-color: white;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  overflow: hidden;
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  padding: 10px;
`;

export const ButtonBase = styled(MuiButtonBase)<{ isActive?: boolean }>`
  background-color: ${({ isActive, theme }) => (isActive ? theme.COLORS.LightWhite : "initial")};
  width: 32px;
  height: 32px;
  border-radius: 4px;
  text-align: center;
  font-size: 20px;
  color: ${({ isActive, theme }) => (isActive ? theme.COLORS.Primary : theme.COLORS.DarkMed)};

  &:focus {
    background-color: ${({ theme }) => theme.COLORS.LightWhite};
  }
`;
