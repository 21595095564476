import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Grid from "components/Grid";
import Card from "components/Card";
import Typography from "components/Typography";
import Button from "components/Button";
import StickyPanel from "components/StickyPanel";
import TextField from "components/TextField";
import DateTimePicker from "components/DateTimePicker";
import Modal from "components/Modal";
import { SvgIcon } from "components/Icon";
import { IcArrowNext } from "components/SvgIcons";

import COLORS from "constants/Colors";
import { DATE_TIME_PICKER_FORMAT } from "constants/DateTimeFormat";
import { Device } from "types/Device";
import {
  DigitalContentStatus,
  DigitalContent,
  DigitalProductsQueryVariablesType,
  DigitalProductCodesQueryVariablesType,
} from "types/DigitalContent";
import { ProductOutputType } from "types/Product";
import useDevice from "utils/hooks/useDevice";
import { convertPriceFormat } from "utils/common";

import CodeDetail from "../CodeDetail";
import MarkAsUsedModal from "../MarkAsUsedModal";

import { CardDigitalCodeDetailModalWrapper } from "../styled";

type DigitalProductSKUItemPropsType = {
  projectId: string;
  productId: string;
  productSKUId: string;
  productCode: string;
  variations: string;
  price: number;
  status: DigitalContentStatus;
  outputType: ProductOutputType;
  digitalProductCode: DigitalContent;
  digitalProductsQueryVariables: DigitalProductsQueryVariablesType;
  digitalProductCodesQueryVariables: DigitalProductCodesQueryVariablesType;
  renderProductListItem: () => JSX.Element;
};

const DigitalProductSKUItem: FC<DigitalProductSKUItemPropsType> = ({
  projectId,
  productId,
  productSKUId,
  productCode,
  variations,
  price,
  status,
  outputType,
  digitalProductCode,
  digitalProductsQueryVariables,
  digitalProductCodesQueryVariables,
  renderProductListItem,
}) => {
  const { t } = useTranslation();
  const device = useDevice();
  const isMobile = Device.MOBILE === device;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isShowSaveButton, setIsShowSaveButton] = useState(false);
  const [isOpenMarkAsUsedModal, setIsOpenMarkAsUsedModal] = useState(false);

  const handleChangeData = () => {
    setIsShowSaveButton(true);
  };

  const handleSave = () => {
    setIsShowSaveButton(false);
  };

  const handleMarkAsUsed = () => {
    setIsOpenMarkAsUsedModal(false);
    setIsOpenModal(false);
  };

  return (
    <>
      <Grid container className="d-flex flex-nowrap">
        <Grid item container className="d-flex flex-grow-1">
          <CodeDetail
            status={status}
            digitalProductCode={digitalProductCode}
            onClickMarkAsUsedButton={() => setIsOpenMarkAsUsedModal(true)}
            outputType={outputType}
          />
        </Grid>
        {isMobile && (
          <Grid item className="d-flex flex-grow-0 align-items-center">
            <SvgIcon
              component={IcArrowNext}
              fontSize="large"
              htmlColor={COLORS.DarkMed}
              className="m-0 p-0"
              onClick={() => {
                setIsOpenModal(true);
              }}
            />
          </Grid>
        )}
      </Grid>
      <Modal
        fullScreen
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
        title={t("contentManagement.modal.title.editProductCode")}
        backgroundColor={COLORS.Light}
      >
        <Grid container className={isShowSaveButton ? "pb-5" : ""}>
          <Grid item xs={12} className="pb-3">
            <Card noShadow borderRadius={0}>
              <Grid container>
                <Grid item xs={12}>
                  {renderProductListItem()}
                </Grid>
                <Grid item xs={12} className="pt-4">
                  <Card noShadow bgColor={COLORS.Light} noBorder>
                    <Grid container>
                      <Grid item xs={5} alignItems="center">
                        <Typography variant="title8" color="darkLight">
                          {t("SKU Code")}
                        </Typography>
                        <Typography variant="body3" className="pt-8 mt-1">
                          {productCode}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} alignItems="center">
                        <Typography variant="title8" color="darkLight">
                          {t("SKU(s)")}
                        </Typography>
                        <Typography variant="body3" className="pt-8 mt-1">
                          {variations}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} alignItems="center">
                        <Typography variant="title8" color="darkLight">
                          {t("Price")}
                        </Typography>
                        <Typography variant="body3" className="pt-8 mt-1">
                          {t("THB")} {convertPriceFormat(price, 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} className="px-3 pb-3">
            <CardDigitalCodeDetailModalWrapper padding="0px">
              <CodeDetail
                isShowExpiredDateOnMobile
                status={status}
                digitalProductCode={digitalProductCode}
                outputType={outputType}
              />
            </CardDigitalCodeDetailModalWrapper>
          </Grid>
          {status === DigitalContentStatus.REDEEMED && (
            <Grid item xs={12} className="pb-3">
              <Card noShadow borderRadius={0} padding="24px 16px">
                <Typography variant="title7" color="dark" className="pb-3">
                  {t("contentManagement.codeDetail.label.note")}
                </Typography>
                <TextField
                  variant="outlined"
                  multiline
                  rows={3}
                  fullWidth
                  placeholder={t("contentManagement.codeDetail.placeholder.addNote")}
                  defaultValue=""
                  onChange={handleChangeData}
                />
              </Card>
            </Grid>
          )}
          {[DigitalContentStatus.AVAILABLE, DigitalContentStatus.PURCHASED].includes(status) &&
            digitalProductCode?.expiredAt && (
              <Grid item xs={12} className="pb-3">
                <Card noShadow borderRadius={0}>
                  <Grid container>
                    <Grid item xs={12} className="pb-2">
                      <Typography variant="body3" color="darkLight">
                        {t("contentManagement.codeDetail.label.expiredTime")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <DateTimePicker
                        selectedDateTime={moment(digitalProductCode.expiredAt, "x").toISOString()}
                        format={DATE_TIME_PICKER_FORMAT}
                        disablePast
                        disableFuture={false}
                        fullWidth
                        // TODO: extend expired date (DP-2605)
                        setDateTime={() => {}}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )}

          {/* TODO: incomplete requirement mark as used */}
          {/* {status === DigitalContentStatus.PURCHASED && outputType !== ProductOutputType.STATIC && (
            <Grid item xs={12} className="px-3">
              <Button fullWidth onClick={() => {}}>
                {t("contentManagement.button.markAsUsed")}
              </Button>
            </Grid>
          )} */}

          <StickyPanel>
            {isShowSaveButton && (
              <Button fullWidth className="m-3" onClick={handleSave}>
                {t("Save")}
              </Button>
            )}
          </StickyPanel>
        </Grid>
      </Modal>
      <Modal isOpen={isOpenMarkAsUsedModal} onClose={() => setIsOpenMarkAsUsedModal(false)}>
        <MarkAsUsedModal
          projectId={projectId}
          productId={productId}
          productSKUId={productSKUId}
          onSubmit={handleMarkAsUsed}
          digitalProductCodeId={digitalProductCode.id}
          digitalProductsQueryVariables={digitalProductsQueryVariables}
          digitalProductCodesQueryVariables={digitalProductCodesQueryVariables}
        />
      </Modal>
    </>
  );
};

export default DigitalProductSKUItem;
