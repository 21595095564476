import * as React from "react";

const SvgIcAiWelcomemessage = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.007 1.778c6.652 0 12.234 4.578 13.767 10.755h1.24a1 1 0 011 1v4.857a1 1 0 01-1 1h-1.24c-1.533 6.177-7.115 10.755-13.767 10.755-6.651 0-12.233-4.578-13.766-10.756H1a1 1 0 01-1-1v-4.856a1 1 0 011-1h1.24C3.775 6.356 9.357 1.778 16.008 1.778zm0 5.447a8.957 8.957 0 00-8.956 8.956c0 3.808 2.135 6.923 5.485 8.22.343.128-.993 3.53-.889 3.738 3.953-1.82 6.833-3.264 7.926-3.739a8.957 8.957 0 00-3.565-17.176zm-3.31 4.51v3.397h2.924v-3.397h2.551v8.898h-2.55v-3.51h-2.925v3.51h-2.564v-8.898h2.564zm9.283 2.39v6.508h-2.227v-6.509h2.227zM20.86 11.2c.697 0 1.232.498 1.232 1.157 0 .66-.535 1.145-1.232 1.145-.696 0-1.22-.485-1.22-1.145s.524-1.157 1.22-1.157z"
    />
  </svg>
);

export default SvgIcAiWelcomemessage;
