import { TotalOrderNumberStates, OrderState } from "types/Order";

const calculateAllTotalOrderNumberState = (totalOrderNumberStates: TotalOrderNumberStates) => {
  const orderStates = [
    OrderState.COD_PREPARE_TO_SHIP,
    OrderState.ORDER_PLACED,
    OrderState.PREPARE_TO_SHIPPING,
    OrderState.PENDING_FOR_SHIPPING_COST,
    OrderState.WAIT_FOR_PAYMENT_APPROVE,
  ];

  return orderStates.reduce(
    (total, orderState) => total + totalOrderNumberStates[orderState as keyof TotalOrderNumberStates],
    0,
  );
};

export default calculateAllTotalOrderNumberState;
