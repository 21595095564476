import { gql } from "@apollo/client";

export const ADD_ORDER_SECONDARY_PAYSLIP = gql`
  mutation addOrderSecondaryPayslipImage($projectId: ID!, $orderId: ID!, $secondaryPayslipImage: String!) {
    addOrderSecondaryPayslipImage(
      projectId: $projectId
      orderId: $orderId
      secondaryPayslipImage: $secondaryPayslipImage
    ) {
      id
      orderNumber
      secondaryPayslipImages
      updatedAt
    }
  }
`;

export const DELETE_ORDER_SECONDARY_PAYSLIP = gql`
  mutation deleteOrderSecondaryPayslipImageByIndex($projectId: ID!, $orderId: ID!, $index: Int!) {
    deleteOrderSecondaryPayslipImageByIndex(projectId: $projectId, orderId: $orderId, index: $index) {
      id
      orderNumber
      secondaryPayslipImages
      updatedAt
    }
  }
`;
