import React, { FC, useState, useCallback, useContext } from "react";
import get from "lodash/get";
import { useQuery, useMutation } from "@apollo/client";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import Grid from "components/Grid";

import { PRODUCT } from "graphql/product/query";
import { DELETE_PRODUCT } from "graphql/product/mutation";
import { UPDATE_INVENTORY } from "graphql/inventory/mutation";

import { notifySuccess, notifyError } from "utils/notify";
import { GuidelineContext } from "utils/context";
import { NOTIFICATION_SAVE } from "constants/Notification";
import ConfirmModal from "components/ConfirmModal";
import { ProductInventoryType, ProductType, ProductOutputType, SummaryType } from "types/Product";
import InventoryCard from "./InventoryCard";
import SummaryModal from "../../ProductInventory/SummaryModal";
import ProductListItem from "../ProductListItem";
import { DeleteButton } from "../StyledComponent/product.styled";
import mapErrorMessage from "../mapErrorMessage";
import ImportCodeButton from "../ImportCodeButton";

type ProductInventoriesContainerPropsType = {
  projectId: string;
  productId: string;
  isEditMode: boolean;
  closeProductModal: Function;
  productType: ProductType;
};

const ProductCardWrapper = styled(Grid)`
  background-color: ${({ theme }) => theme.COLORS.White};
  padding-top: 18px;
  border-top: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
`;

const ProductInventoriesContainer: FC<ProductInventoriesContainerPropsType> = (props) => {
  const { productId, projectId, closeProductModal, isEditMode, productType } = props;
  const [inventories, setInventories] = useState<ProductInventoryType[]>([]);
  const [summaries, setSummaries] = useState<SummaryType[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(false);
  const { handleFetchGuideline } = useContext(GuidelineContext);

  const { t } = useTranslation();

  const queryVariable = {
    projectId,
    id: productId,
  };

  const { data, refetch: refetchProduct } = useQuery(PRODUCT, {
    skip: !projectId || !productId,
    variables: queryVariable,
  });

  const [deleteProduct] = useMutation(DELETE_PRODUCT, {
    variables: {
      projectId,
      id: productId,
    },
  });

  const handleDelete = async () => {
    try {
      await deleteProduct();
      notifySuccess(t("ProductDeleted"));
      closeProductModal();
      handleFetchGuideline();
    } catch (err) {
      const gqlErrorMessage: string = get(err, "graphQLErrors.0.message");
      const errorMessage = mapErrorMessage(gqlErrorMessage);
      notifyError(t(errorMessage));
    }
  };

  const [updateInventory, { data: dataUpdateInventory }] = useMutation(UPDATE_INVENTORY, {
    onCompleted: () => {
      notifySuccess(t(NOTIFICATION_SAVE.SUCCESS));
    },
  });

  const handleUpdateInventory = useCallback(() => {
    updateInventory({
      variables: {
        projectId,
        inventories,
      },
    });
    setInventories([]);
    setSummaries([]);
    setIsOpenModal(false);
  }, [inventories, projectId, updateInventory]);

  const handleCloseSummaryModal = useCallback(() => {
    setIsOpenModal(false);
  }, []);

  if (dataUpdateInventory && dataUpdateInventory.updateInventory) {
    refetchProduct();
  }

  const handleChangeStock = (productSKUId: string, productCode: string, value: number) => {
    const newUpdateSKU = {
      productSKUId,
      value,
    };

    const newUpdateSummary = {
      productCode,
      value,
    };

    const nonUpdateInventories = inventories.filter(
      (inventory: ProductInventoryType) => inventory.productSKUId !== productSKUId,
    );
    const nonUpdateSummary = summaries.filter((summary: SummaryType) => summary.productCode !== productCode);

    const newInventories = value ? [...nonUpdateInventories, newUpdateSKU] : [...nonUpdateInventories];
    const newSummary = value ? [...nonUpdateSummary, newUpdateSummary].sort() : [...nonUpdateSummary];
    setInventories(newInventories);
    setSummaries(newSummary);
  };

  const product = get(data, "product");

  const isDigitalProduct = productType === ProductType.DIGITAL_CONTENT;
  const isDigitalProductAndCodeImport =
    isDigitalProduct && get(product, "outputType") === ProductOutputType.CODE_IMPORTED;

  return (
    <Grid container className="pb-4 mb-4">
      {product && (
        <ProductCardWrapper item xs={12} key={product.id}>
          <Grid container className="pl-2 mb-2 flex-0 d-flex" justify="space-between">
            <Grid item className="flex-1">
              <ProductListItem
                productImage={product.images && product.images[0] && product.images[0].src}
                productSKUs={product.productSKUs}
                defaultPrice={product.defaultPrice}
                productName={product.name}
                isActive={
                  !!product.productSKUs.find((productSKUs: { selected: boolean }) => productSKUs.selected === true)
                }
                productImageMaxSize={80}
                isDigitalProduct={isDigitalProduct}
              />
            </Grid>

            {isDigitalProductAndCodeImport && (
              <Grid item className="flex-0 pr-2">
                <ImportCodeButton projectId={projectId} onCompleted={() => refetchProduct()} />
              </Grid>
            )}
          </Grid>

          <div>
            <InventoryCard
              productSKUs={product.productSKUs}
              onChangeStock={handleChangeStock}
              tempInventories={inventories}
              disabled={isDigitalProductAndCodeImport}
            />
          </div>
        </ProductCardWrapper>
      )}
      {Boolean(summaries.length) && (
        <Grid item xs={12} className="mt-4 mx-2">
          <Button fullWidth onClick={() => setIsOpenModal(true)}>
            {t("Save")}
          </Button>
        </Grid>
      )}

      {isEditMode && (
        <Grid item xs={12} className="mt-4 mx-2">
          <DeleteButton fullWidth onClick={() => setIsOpenConfirmDeleteModal(true)}>
            {t("Delete Product")}
          </DeleteButton>
        </Grid>
      )}

      {isOpenConfirmDeleteModal && (
        <ConfirmModal
          isOpen={isOpenConfirmDeleteModal}
          isLoading={false}
          title="Confirm Delete"
          description={t("Do you want to delete this product")}
          onClose={() => {
            setIsOpenConfirmDeleteModal(false);
          }}
          closeModal={() => {
            setIsOpenConfirmDeleteModal(false);
          }}
          handleConfirm={handleDelete}
        />
      )}

      <SummaryModal
        summaries={summaries}
        isOpen={isOpenModal}
        onClose={handleCloseSummaryModal}
        onSubmit={handleUpdateInventory}
        title={t("Update stock record")}
        subTitle={t("Summary")}
      />
    </Grid>
  );
};

export default ProductInventoriesContainer;
