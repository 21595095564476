import * as React from "react";

const SvgIcUp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 12.544l6.758 6.758a.727.727 0 0 0 1.029-1.028L16.514 11a.727.727 0 0 0-1.028 0l-7.273 7.273a.727.727 0 0 0 1.029 1.028L16 12.544z"
    />
  </svg>
);

export default SvgIcUp;
