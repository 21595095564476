import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Grid from "components/Grid";
import PageTitle from "components/PageTitle";
import CircularProgress from "components/CircularProgress";
import Modal from "components/Modal";

import { GroupNotificationConfigurationType, NotificationConfigurationType } from "types/Notification";
import { ProjectIdType } from "types/Project";
import useGetUserWithPermission from "utils/hooks/useGetUserWithPermission";

import NotificationCard from "./ConfigCard";
import { useNotificationConfiguration } from "./hooks/useNotificationConfiguration";
import { useUpdateNotificationConfiguration } from "./hooks/useUpdateNotificationConfiguration";
import { groupNotificationConfig } from "./utils";

const NotificationSetting: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const { currentUser } = useGetUserWithPermission(projectId);
  const userId = currentUser?.id || "";

  const { toggleNotificationConfig } = useUpdateNotificationConfiguration({ projectId, userId });
  const { isLoading, notificationConfigurations } = useNotificationConfiguration({ projectId, userId });
  const notificationSettingData = groupNotificationConfig(notificationConfigurations);

  const handleToggleNotificationConfig = (changedNotificationConfig: NotificationConfigurationType) => {
    const newNotificationConfigurations = notificationConfigurations.map(
      (notificationConfig: NotificationConfigurationType) => {
        const { name } = notificationConfig;

        if (name === changedNotificationConfig.name) {
          return { ...changedNotificationConfig, isActive: !changedNotificationConfig.isActive };
        }

        return notificationConfig;
      },
    );

    toggleNotificationConfig(newNotificationConfigurations);
  };

  return (
    <Grid container className="pb-4">
      <Grid item xs={12}>
        <PageTitle title={t("NotificationSetting.header")} />
      </Grid>
      {isLoading && (
        <Modal isOpen onClose={() => {}}>
          <CircularProgress className="m-4" />
        </Modal>
      )}
      {notificationSettingData.map(({ type, notification }: GroupNotificationConfigurationType) => {
        return (
          <Grid key={type} item xs={12} className="p-3">
            <NotificationCard
              type={type}
              configList={notification}
              toggleNotificationConfig={handleToggleNotificationConfig}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default NotificationSetting;
