import React from "react";

import styled from "styled-components";
import COLORS from "constants/Colors";
import Grid from "components/Grid";
import { TagType } from "types/Tag";
import { ThemeType } from "components/Theme/type";
import { getTagColor } from "utils/tag";

type CustomerTagPropsType = {
  theme: ThemeType;
  type?: TagType;
  isFixWidth?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CustomerTag = styled(({ color, bgColor, borderColor, isFixWidth, ...rest }) => <Grid {...rest} />).attrs(
  (props: CustomerTagPropsType) => {
    const { theme, type } = props;
    const { color, bgColor, borderColor } = getTagColor(theme.COLORS, type);
    return { color, bgColor, borderColor };
  },
)`
  max-width: ${({ isFixWidth }) => (isFixWidth ? "62px" : "fit-content")};
  width: auto;
  height: 21px;
  border-radius: 7px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  padding: 0px 5px;
  margin-right: 4px;
  font-size: 11px;
  line-height: 20px;
  font-weight: 500;
  border: ${({ borderColor }) => `solid 1px ${borderColor}`};
`;

export const TagContentGrid = styled.div`
  display: flex;
  max-width: 90%;
  overflow: hidden;
`;

export const MoreTagWrapper = styled.div`
  font-size: 10px;
  line-height: 22px;
  color: ${COLORS.DarkGray};
  padding-left: 8px;
`;

export const TitleWrapper = styled.span`
  color: ${({ theme }) => theme.COLORS.DarkLight};
  font-weight: 500;
  line-height: 2rem;
`;

export const TextWrapper = styled.span`
  color: ${({ theme }) => theme.COLORS.DarkGray};
  line-height: 2rem;
`;

export const OverFlowTextWrapper = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
