import styled from "styled-components";

export const TemplateOptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > button {
    margin: 8px;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    justify-content: center;
  }
`;

export const RatioBox = styled.div`
  width: 100%;
  padding-bottom: 67.44%;
  position: relative;
`;

export const RatioContent = styled.div<{ backgroundUrl?: string }>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: ${({ backgroundUrl }) => `url(${backgroundUrl})` || "none"};
  background-repeat: no-repeat;
  background-size: contain;
`;
