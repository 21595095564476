import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import Button , {ButtonWithIcon} from "components/Button";
import Card from "components/Card";
import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import Typography from "components/Typography";
import DisplayNameWithAvatar from "components/DisplayNameWithAvatar";
import IcTurnOnBot from "components/SvgIcons/IcTurnOnBot";
import IcTurnOffBot from "components/SvgIcons/IcTurnOffBot";
import ConfirmationModal from "components/ConfirmationModal";
import { SystemTagStatus } from "constants/Tag";
import { SYSTEM_TAGS } from "graphql/tag/query";
import { CustomerType } from "types/Customer";
import Modal from "components/Modal";
import {
  SystemTagsQueryVariablesType,
  SystemTagsQueryType,
  SystemTagType,
  CustomerRetentionStatusKeyType,
  TagType,
} from "types/Tag";
import { IcEdit } from "components/SvgIcons";
import COLORS from "constants/Colors";
import styled from "styled-components";
import { SvgIcon } from "components/Icon";


import { mapPotentialCustomerTag } from "utils/tag";
import { getAddress } from "utils/address";
import useToggle from "utils/hooks/useToggle";
import useIsDesktop from "utils/hooks/useIsDesktop";
import { CUSTOMERS_REPORT, DASHBOARD, PROJECT } from "constants/Router";
import Tooltip from "components/Tooltip";
import { TitleWrapper, TextWrapper, OverFlowTextWrapper, CustomerTag } from "./styled";

const IconContainer = styled.div`
  display: inline-block;
`;

type CustomerAddressPropsType = {
  className?: string;
  customer: CustomerType;
  customerId: string;
  isDisconnected?: boolean;
  isHiddenAddress?: boolean;
  isShownDisconnectButton?: boolean;
  isDisabledChatProvider?: boolean;
  onClickDisconnectBot?: (isDisconnected: boolean, callback: Function) => void;
  projectId: string;
};

const CustomerAddress: FC<CustomerAddressPropsType> = ({
  className,
  customer,
  customerId,
  onClickDisconnectBot,
  projectId,
  isDisconnected = false,
  isDisabledChatProvider = false,
  isHiddenAddress = false,
  isShownDisconnectButton = false,
}) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const { handleToggle: handleToggleModal, isOpen: isOpenModal, handleClose: handleCloseModal } = useToggle(false);
  // const { handleToggle: handleCustomerRefToggleModal, isOpen: isCustomerRefOpenModal, handleClose: handleCustomerRefCloseModal } = useToggle(false);

  const handleSubmit = useCallback(() => {
    if (onClickDisconnectBot) {
      onClickDisconnectBot(isDisconnected, handleCloseModal);
    }
  }, [handleCloseModal, isDisconnected, onClickDisconnectBot]);

  const { data, loading } = useQuery<SystemTagsQueryType, SystemTagsQueryVariablesType>(SYSTEM_TAGS, {
    variables: {
      projectId,
      customerId,
    },
    fetchPolicy: "network-only",
    skip: isHiddenAddress,
  });

  let customerAddress = "";
  if (!isHiddenAddress) {
    customerAddress = getAddress(customer?.address);
  }

  const displayName = customer?.displayName || customer?.name || "";

  return (
    <>
      <Card noShadow padding="0px" className={className}>
        {loading ? (
          <Grid container justify="center">
            <CircularProgress className="m-4" />
          </Grid>
        ) : (
          <div className="px-3 py-2">
            <Grid container>
              <Grid item xs={isShownDisconnectButton ? 6 : 12}>
                <DisplayNameWithAvatar
                  displayName={displayName}
                  description={customer?.displayName ? customer?.name : ""}
                  avatarImage={customer?.pictureUrl || ""}
                  platform={customer?.platform || ""}
                  redirectLink={`/${PROJECT}/${projectId}/${DASHBOARD}/${CUSTOMERS_REPORT}/${customerId}`}
                />
              </Grid>
              {isShownDisconnectButton && (
                <Grid item xs={6} className="d-flex justify-content-end align-items-center">
                  <Tooltip
                    title={<div>{isDisconnected ? t("RECONNECT_BOT_TOOLTIP") : t("DISCONNECT_BOT_TOOLTIP")}</div>}
                    PopperProps={{
                      disablePortal: true,
                    }}
                  >
                    <Button
                      disabled={isDisabledChatProvider}
                      size={isDesktop ? "medium" : "small"}
                      color={isDisconnected ? "primary" : "secondary"}
                      onClick={handleToggleModal}
                      className={isDesktop ? "" : "py-2"}
                    >
                      {isDisconnected ? (
                        <IcTurnOnBot className="mr-1" fontSize="20px" viewBox="-3 -3 24 24" />
                      ) : (
                        <IcTurnOffBot className="mr-1" fontSize="20px" viewBox="-3 -3 24 24" />
                      )}

                      {isDisconnected ? t("RECONNECT") : t("DISCONNECT_BOT")}
                    </Button>
                  </Tooltip>
                </Grid>
              )}
            </Grid>

            <Typography variant="body3" className="pt-2">
              <TitleWrapper>{t("Name")} : </TitleWrapper>
              <TextWrapper>
                {customer?.address?.firstName}&nbsp;{customer?.address?.lastName}
              </TextWrapper>
            </Typography>

            <Typography variant="body3">
              <TitleWrapper>{t("Phone")} : </TitleWrapper>
              <TextWrapper>{customer?.address?.phoneNumber}</TextWrapper>
            </Typography>

            {!isHiddenAddress && (
              <Typography variant="body3">
                <TitleWrapper>{t("Address")} : </TitleWrapper>
                <TextWrapper>{customerAddress}</TextWrapper>
              </Typography>
            )}

            <OverFlowTextWrapper>
              <Typography variant="body3">
                <TitleWrapper>{t("Email")} : </TitleWrapper>
                <TextWrapper>{customer?.address?.email}</TextWrapper>
              </Typography>
            </OverFlowTextWrapper>
            {customer?.customerRefId ? (<Typography variant="body3">
              <TitleWrapper>{t("Customer No.")} : </TitleWrapper>
              <TextWrapper>{customer?.customerRefId}</TextWrapper>
            </Typography>):<></>}

            {/* SYSTEM TAG */}
            {!isHiddenAddress && (
              <Grid container item className="mb-2 mt-1">
                {data &&
                  data.systemTags.map((systemTag: SystemTagType) => {
                    // custom color by tag type (waiting for design)
                    let displayTag;
                    if (systemTag.type === TagType.SYSTEM_BROADCAST) {
                      displayTag = `Broadcast: ${systemTag.tag}`;
                    } else {
                      displayTag = SystemTagStatus[systemTag.tag as CustomerRetentionStatusKeyType] || systemTag.tag;
                    }

                    return (
                      <CustomerTag key={systemTag.tag} item type={systemTag.type} className="my-1">
                        {mapPotentialCustomerTag(displayTag)}
                      </CustomerTag>
                    );
                  })}
              </Grid>
            )}
          </div>
        )}
      </Card>
      <ConfirmationModal
        isOpen={isOpenModal}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
        textSubmit={t("Confirm")}
        title={isDisconnected ? t("ARE_YOU_SURE_TO_TURN_ON_BOT") : t("ARE_YOU_SURE_TO_TURN_OFF_BOT")}
        subTitle={isDisconnected ? t("THE_CUSTOMER_CHAT_WITH_BOT") : t("THE_CUSTOMER_ONLY_CHAT_WITH_ADMIN")}
      />
    </>
  );
};

export default CustomerAddress;
