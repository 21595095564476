import { withStyles, Chip } from "@material-ui/core";

import COLORS from "constants/Colors";

const CustomChip = withStyles({
  root: {
    fontFamily: "Graphik",
    backgroundColor: COLORS.Gray100,
    fontSize: "14px",
    color: COLORS.DarkBlue500,
    width: "fit-content",
    marginBottom: "8px",
    padding: "16px 8px",
    maxWidth: "100%",
  },
})(Chip);

export default CustomChip;
