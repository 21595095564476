import styled from "styled-components";

type MessagesManagerWrapperPropsType = {
  minHeight?: number;
};

type MessagesContentWrapperPropsType = {
  minHeight?: number;
};

type QuickReplyWrapperPropsType = {
  marginBottom?: boolean;
};
export const MessagesManagerWrapper = styled.div<MessagesManagerWrapperPropsType>`
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : "unset")};
  overflow-y: auto;
  width: 100%;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const MessagesContentWrapper = styled.div<MessagesContentWrapperPropsType>`
  width: 100%;
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : "unset")};
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

export const SortIcon = styled.div`
  width: 30px;
  height: 20px;
  font-size: 20px;
  line-height: 20px;
  margin-right: 10px;
`;

export const AddMessageWrapper = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 100;
`;

export const VerticalLine = styled.div`
  margin-right: 10px;
  border-left: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
`;

export const QuickReplyWrapper = styled.div<QuickReplyWrapperPropsType>`
  margin: ${({ marginBottom = false }) => (marginBottom ? "0px 0px 50px" : "0px 0px 8px")};
  padding: 0px 14px 6px 8px;
  border-radius: 5px;
  width: -webkit-fill-available;
  background-color: ${({ theme }) => theme.COLORS.Light};
`;

export const QuickReplyInfoWrapper = styled.div`
  margin: 0px 10px 8px;
  border-radius: 5px;
  width: -webkit-fill-available;
  background-color: ${({ theme }) => theme.COLORS.Light};
  height: 69px;
  display: flex;
  align-items: center;
`;
