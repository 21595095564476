import React from "react";
import { THEME } from "constants/Theme";

export const themes = {
  default: THEME.default,
  darkMode: THEME.darkMode,
};

const initTheme = {
  ...themes,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setTheme: (data: any) => {
    return data;
  },
};

export const ThemeContext = React.createContext({
  theme: initTheme.default,
  setTheme: initTheme.setTheme,
});
