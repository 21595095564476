import * as React from "react";

const SvgIcAiProperty = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.795 14.556c.415 0 .792.17 1.068.441l6.79 6.791a1.51 1.51 0 01.005 2.14l-5.285 5.285a1.513 1.513 0 01-1.068.442c-.416 0-.793-.17-1.065-.442l-6.795-6.794A1.494 1.494 0 0116 21.35v-5.285c0-.834.676-1.51 1.51-1.51zm4.798-2.822c.502 0 .895.433.847.932l-.343 4.682-3.438-3.418a1.9 1.9 0 00-1.337-.552h-6.61a1.888 1.888 0 00-1.89 1.889v6.61c0 .525.213.997.557 1.337l3.572 3.551-15.345.001a.851.851 0 01-.83-.663l-.018-.107-.976-13.33a.851.851 0 01.847-.932zm-8.95 4.331a1.13 1.13 0 00-1.133 1.133 1.13 1.13 0 001.132 1.132 1.13 1.13 0 001.133-1.132 1.13 1.13 0 00-1.133-1.133zM13.085 5.333c.47 0 .851.381.851.851v1.07h12.056c.47 0 .851.38.851.85v1.928H3.384V6.184c0-.47.38-.85.85-.85z"
    />
  </svg>
);

export default SvgIcAiProperty;
