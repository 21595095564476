import { StateColors } from "types/AISetting";
import COLORS from "./Colors";

export const NEW_FAQ_ID_PREFIX = "new-";
export const VALIDATE_FAQ_QUESTION_SUFFIX = "-questions";
export const VALIDATE_FAQ_RESPONSE_SUFFIX = "-responses";
export const JOB_STATUS_PROCESSING = "PROCESSING";
export const JOB_STATUS_COMPLETED = "COMPLETED";
export const FAQ_QUESTION_DUPLICATED_ERROR = "FAQ.question.duplicated.error";
export const FAQ_QUESTION_DUPLICATED_ERROR_SANITY = "FAQ.question.duplicated.sanity.error";
export const FAQ_QUESTION_DUPLICATED_ERROR_SANITY_NAME = "Duplicated with bot flow";
export const FAQ_QUESTION_DUPLICATED = "FAQ.question.duplicated";
export const NEW_FAQ_QUESTION_ERROR = "newFAQ.question.sanity.error";

export const NEW_FAQ_QUESTION_DUPLICATED_ERROR = "newFAQ.question.duplicated.error";
export const NEW_FAQ_QUESTION_DUPLICATED = "newFAQ.question.duplicated";
export const NEW_FAQ_QUESTION_DUPLICATED_ERROR_SANITY = "newFAQ.question.duplicated.sanity.error";
export const NEW_FAQ_QUESTION_MAX_LENGTH_ERROR = "newFAQ.question.maxLength.error";
export const NEW_FAQ_QUESTION_HAS_SOME_MAX_LENGTH_ERROR = "newFAQ.question.hasSome.maxLength.error";

export const AI_STATUS_COLOR = {
  none: COLORS.LightBlue,
  bad: COLORS.Salmon,
  poor: COLORS.MacaroniCheese,
  average: COLORS.CarolinaBlue,
  good: COLORS.GreyBlue,
  great: COLORS.Primary,
};

export const INTENT_COLOR = {
  [StateColors.PLASMA]: "highlight-plasma",
  [StateColors.GAS]: "highlight-gas",
  [StateColors.LIQUID]: "highlight-liquid",
  [StateColors.SOLID]: "highlight-solid",
};
