import * as yup from "yup";
import { NEW_FAQ_QUESTION_MAX_LENGTH_ERROR } from "constants/Faq";
import { FAQ_MAX_QUESTION_LENGTH } from "config/faq";

const validationSchema = (i18n: Function) =>
  yup.object().shape({
    questionInput: yup
      .string()
      .trim()
      .max(FAQ_MAX_QUESTION_LENGTH, i18n(NEW_FAQ_QUESTION_MAX_LENGTH_ERROR, { maxLength: FAQ_MAX_QUESTION_LENGTH })),
  });

export default validationSchema;
