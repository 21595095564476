import React, { FC, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import Card from "components/Card";
import Typography from "components/Typography";
import { NumberFormatCustomWithoutPrefix } from "components/NumberFormatCustom";
import ErrorText from "components/ErrorText";
import Divider from "components/Divider";

import COLORS from "constants/Colors";
import { convertPriceFormat } from "utils/common";
import { BANK_LOGO_SQUARE } from "constants/Image";
import { Bank } from "types/Bank";

import useDevice from "utils/hooks/useDevice";
import { Device } from "types/Device";
import { CircularProgress } from "@material-ui/core";
import { ThaiBahtAdornment } from "../../../Shipping/DefaultShippingMethod/style";
import { InputAdornment, InputEndAdornmentWrapper, MaxWrapper, WithdrawCard, WithdrawTextInput } from "./styled";
import { MAXIMUM_DEEPLE_PAY_WITHDRAW_AMOUNT, MINIMUM_DEEPLE_PAY_WITHDRAW_AMOUNT } from "./config";

type WithdrawalCardContentProps = {
  accountName: string;
  accountNumber: string;
  balance: number;
  bankName: Bank;
  feeRate: number;
  isConfirm: boolean;
  isWithdrawAmountExceedBalance: boolean;
  isWithdrawAmountExceedLimit: boolean;
  isWithdrawAmountLessThanThreshold: boolean;
  onChangeWithdrawAmount: (inputAmount: number) => void;
  requestWithdrawLoading: boolean;
  withdrawAmount: number | null;
};

const WithdrawalCardContent: FC<WithdrawalCardContentProps> = (props) => {
  const {
    accountName,
    accountNumber,
    balance,
    bankName,
    feeRate,
    isConfirm,
    isWithdrawAmountExceedBalance,
    isWithdrawAmountExceedLimit,
    isWithdrawAmountLessThanThreshold,
    onChangeWithdrawAmount,
    withdrawAmount,
    requestWithdrawLoading = false,
  } = props;
  const { t } = useTranslation();
  const device = useDevice();
  const isMobile = device === Device.MOBILE;

  const totalTransferred = Number(withdrawAmount) - feeRate;

  const handleClickMaxAmount = () => {
    if (MAXIMUM_DEEPLE_PAY_WITHDRAW_AMOUNT >= MINIMUM_DEEPLE_PAY_WITHDRAW_AMOUNT) {
      const maxAmount = balance > MAXIMUM_DEEPLE_PAY_WITHDRAW_AMOUNT ? MAXIMUM_DEEPLE_PAY_WITHDRAW_AMOUNT : balance;

      onChangeWithdrawAmount(maxAmount);
    }
  };

  const handleChangeWithdrawAmount = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const newWithdrawAmount = Number(value);

    onChangeWithdrawAmount(newWithdrawAmount);
  };

  return (
    <WithdrawCard
      noShadow
      className={isMobile ? "" : "desktop"}
      padding={isMobile ? "24px 12px" : "24px 72px 32px 72px"}
      fullWidth
    >
      <Grid container>
        {requestWithdrawLoading ? (
          <Grid container className="p-0 m-0" justify="center">
            <CircularProgress className="m-4" />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="title4" className="text-center" color="dark">
                {t(isConfirm ? "withdrawalModal.step2.contentTitle" : "withdrawalModal.step1.contentTitle")}
              </Typography>
            </Grid>
            <Grid item xs={12} className="pt-2">
              <Typography variant="body3" color="darkLight" className="text-center">
                {t("withdrawalModal.content.description")}
              </Typography>
            </Grid>
            <Grid item xs={12} className="pt-1">
              <Card noShadow noBorder bgColor={COLORS.PrimaryNude} padding="20px 24px">
                <Grid container className="flex-nowrap">
                  <Grid item className="pr-4 flex-grow-0">
                    <img width="52" height="52" src={BANK_LOGO_SQUARE[bankName]} alt="verified-store" />
                  </Grid>
                  <Grid item className="flex-grow-1">
                    <Typography variant="title8" color="dark">
                      {accountName}
                    </Typography>
                    <Typography variant="body3">{accountNumber}</Typography>
                    <Typography variant="body4">{t(bankName)}</Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            {!isConfirm && (
              <>
                <Grid item container justify="space-between" className="pt-3 pb-1">
                  <Grid item>
                    <Typography variant="body4">{t("withdrawalModal.Amount")}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body4">
                      {t("withdrawalModal.Balance")} {convertPriceFormat(balance)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <WithdrawTextInput
                    value={withdrawAmount}
                    fullWidth
                    placeholder={t("withdrawalModal.Placeholder")}
                    variant="outlined"
                    InputProps={{
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      inputComponent: NumberFormatCustomWithoutPrefix as any,
                      startAdornment: <ThaiBahtAdornment />,
                      endAdornment: (
                        <InputEndAdornmentWrapper>
                          <InputAdornment position="end">
                            {/* Todo: enable when have refund feature */}
                            {/* <FixWidthTooltip title={t("withdrawalModal.tooltip") as string} placement="bottom-end" arrow>
                          <div>
                            <SvgIcon
                              component={IcInfo}
                              fontSize="small"
                              htmlColor={COLORS.DarkLight}
                              viewBox="0 4 24 24"
                              className="p-0 mr-0"
                            />
                          </div>
                        </FixWidthTooltip> */}
                            <MaxWrapper onClick={handleClickMaxAmount}>
                              <Typography variant="title10" color="info" className="pl-3">
                                {t("withdrawalModal.max")}
                              </Typography>
                            </MaxWrapper>
                          </InputAdornment>
                        </InputEndAdornmentWrapper>
                      ),
                    }}
                    onChange={handleChangeWithdrawAmount}
                    error={
                      isWithdrawAmountExceedBalance || isWithdrawAmountExceedLimit || isWithdrawAmountLessThanThreshold
                    }
                  />
                </Grid>
                {isWithdrawAmountExceedBalance && (
                  <ErrorText className="mt-1">{t("withdrawalModal.error.withdrawAmountExceedBalance")}</ErrorText>
                )}
                {isWithdrawAmountExceedLimit && !isWithdrawAmountExceedBalance && (
                  <ErrorText className="mt-1">{t("withdrawalModal.error.withdrawAmountExceedLimit")}</ErrorText>
                )}
                {isWithdrawAmountLessThanThreshold && (
                  <ErrorText className="mt-1">{t("withdrawalModal.error.withdrawAmountLessThanThreshold")}</ErrorText>
                )}
              </>
            )}
            {isConfirm && (
              <>
                <Grid item container justify="space-between" className="pt-3">
                  <Grid item>
                    <Typography variant="body3">{t("withdrawalModal.Total")}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body3">
                      {t("THB")}
                      {convertPriceFormat(Number(withdrawAmount))}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container justify="space-between" className="pt-1">
                  <Grid item>
                    <Typography variant="body3">{t("withdrawalModal.Fee")}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body3" color="error">
                      -{t("THB")}
                      {convertPriceFormat(feeRate)}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className="w-100 my-3" />
                <Grid item container justify="space-between">
                  <Grid item>
                    <Typography variant="title3">{t("withdrawalModal.TotalTransferred")}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="title4">
                      {t("THB")}
                      {convertPriceFormat(Number(totalTransferred))}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </WithdrawCard>
  );
};

export default WithdrawalCardContent;
