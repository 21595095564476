import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import { SvgIcon } from "components/Icon";
import { IcArrowDown, IcArrowUp } from "components/SvgIcons";

import { CollapseWrapper } from "../styled";

interface MobileCollapsePropTypes {
  leftSideRender?: React.ReactNode;
  rightSideRender?: React.ReactNode;
  expandRender?: React.ReactNode;
}

const MobileCollapse: FC<MobileCollapsePropTypes> = ({ leftSideRender, rightSideRender, expandRender }) => {
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <>
      <CollapseWrapper isExpand={checked} className="p-3">
        <Box position="relative" display="flex" alignItems="center">
          <Box display="flex" justifyContent="space-between" alignItems="center" onClick={handleChange} width="100%">
            <Box>{leftSideRender}</Box>
            <Box paddingRight="30px">{rightSideRender}</Box>
          </Box>
          <Box position="absolute" right="0">
            <SvgIcon
              viewBox={checked ? "0 0 11 11" : "0 0 24 24"}
              component={checked ? IcArrowUp : IcArrowDown}
              fontSize="small"
              className="p-0"
            />
          </Box>
        </Box>
        <Collapse in={checked}>
          <Box className="pt-4 pb-2 px-2">{expandRender}</Box>
        </Collapse>
      </CollapseWrapper>
      <Divider />
    </>
  );
};

export default MobileCollapse;
