import * as React from "react";

const SvgIcAiProductname = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.795 12.778c.415 0 .792.17 1.068.441l6.79 6.791a1.51 1.51 0 01.005 2.14l-5.285 5.285a1.513 1.513 0 01-1.068.442c-.416 0-.793-.17-1.065-.442l-6.795-6.794A1.494 1.494 0 0116 19.572v-5.284c0-.835.676-1.51 1.51-1.51zm-7.632-1.173c.226-.548.41-.512.41.081v.295c-.456.345-.75.892-.75 1.508V20.1c0 .524.212.996.556 1.336l.194.194v5.758c-.178 0-.356-.011-.534-.036l-.264-.046L4.003 25.04a.427.427 0 01-.339-.406l-.186-7.182a.426.426 0 01.39-.436l9.15-.804a.426.426 0 00.356-.263zm1.263 10.878l4.123 4.123-3.324.7a3.84 3.84 0 01-.53.073l-.268.01-.001-4.906zm2.216-8.195a1.13 1.13 0 00-1.132 1.132 1.13 1.13 0 001.132 1.133 1.13 1.13 0 001.133-1.133 1.13 1.13 0 00-1.133-1.132zM15.96 7.11l-3.47 7.817a.426.426 0 01-.347.251L1.36 16.27a.426.426 0 01-.43-.605l2.872-6.138a.426.426 0 01.31-.239L15.96 7.112zm.082 0L27.89 9.287c.135.025.25.114.309.24l2.871 6.137c.14.3-.1.638-.429.605l-2.077-.21-3.905-3.907a1.9 1.9 0 00-1.337-.552h-5.29l-1.99-4.49zm.386 4.509c.014-.529.193-.542.41-.015l-.002-.006-.124.001-.148.006z"
    />
  </svg>
);

export default SvgIcAiProductname;
