import { DigitalContentStatus } from "types/DigitalContent";

const digitalProductStatusList = [
  {
    id: DigitalContentStatus.AVAILABLE,
    title: "contentManagement.tab.available",
  },
  {
    id: DigitalContentStatus.PURCHASED,
    title: "contentManagement.tab.purchased",
  },
  {
    id: DigitalContentStatus.REDEEMED,
    title: "contentManagement.tab.redeemed",
  },
  {
    id: DigitalContentStatus.EXPIRED,
    title: "contentManagement.tab.expired",
  },
];

export const tabHeaderConfigCodeImported = {
  header: digitalProductStatusList,
};

export const tabHeaderConfigCodeGen = {
  header: digitalProductStatusList.filter(({ id }) => id !== DigitalContentStatus.AVAILABLE),
};

export const tabHeaderConfigDeepLink = {
  header: digitalProductStatusList.filter(({ id }) => id === DigitalContentStatus.PURCHASED),
};
