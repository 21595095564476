export enum Days {
  SUNDAY = "SUNDAY",
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
}

export type OpeningHoursType = {
  day: Days;
  open: string;
  close: string;
  isActive: boolean;
};

export type BranchAddressType = {
  name: string;
  streetAddress: string;
  phoneNumber: string;
  location: string; // google maps link

  district: string;
  subDistrict: string;
  province: string;
  postalCode: string;
  latitude?: number;
  longitude?: number;
};

export type BranchType = BranchAddressType & {
  id: string;
  projectId: string;
  address: string;

  isActive: boolean;
  isAvailableForPickUp: boolean;
  isAvailableForLalamove: boolean;

  openingHours: OpeningHoursType[];
};

export type UpsertBranch = {
  id?: string;
  projectId: string;
  name: string;
  address: string;
  phoneNumber: string;
  location: string;

  district: string;
  subDistrict: string;
  province: string;
  postalCode: string;
  latitude?: number;
  longitude?: number;

  isActive: boolean;
  isAvailableForPickUp: boolean;
  isAvailableForLalamove: boolean;

  openingHours: OpeningHoursType[];
};

export type HandleClickUpdateBranch = (
  branch: UpsertBranch,
  { onSuccess, onError }: { onSuccess?: Function; onError?: Function },
) => void;

export type BranchesQueryType = {
  offlineStores: BranchType[];
};

export type FormDataBranchInput = {
  isActive: boolean;
  name: string;
  address?: string;
  phoneNumber?: string;
  location?: string;

  district: string;
  subDistrict: string;
  province: string;
  postalCode: string;
  latitude?: number | null;
  longitude?: number | null;

  isAvailableForLalamove: boolean;
  isAvailableForPickUp: boolean;

  openingHours: OpeningHoursType[];
};

export type FormDataInput = { [key: string]: FormDataBranchInput };
