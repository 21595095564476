import * as React from "react";

const SvgIcAdmin = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M27 25.167h-2.75a.88.88 0 01-.645-.272.88.88 0 01-.272-.645v-9.167a.88.88 0 01.272-.644.88.88 0 01.645-.272h.917c0-2.827-.788-5.061-2.364-6.703C21.228 5.82 18.96 5 16 5s-5.228.821-6.803 2.464C7.62 9.106 6.833 11.34 6.833 14.167h.917a.88.88 0 01.645.272.88.88 0 01.272.644v9.167a.88.88 0 01-.272.645.88.88 0 01-.645.272h-.917a3.53 3.53 0 001.075 2.592 3.533 3.533 0 002.592 1.074h1.833c0-.515.177-.95.53-1.303.354-.353.788-.53 1.304-.53H16c.516 0 .95.177 1.303.53.354.353.53.783.53 1.289a1.8 1.8 0 01-.53 1.303 1.748 1.748 0 01-1.303.545h-5.5c-1.528 0-2.826-.535-3.896-1.605C5.534 27.994 5 26.695 5 25.168c-1.012 0-1.876-.535-2.592-1.605-.717-1.069-1.075-2.368-1.075-3.895 0-.993.167-1.91.502-2.75.334-.84.778-1.5 1.332-1.977v-.773c0-1.738.339-3.4 1.017-4.985.678-1.585 1.59-2.95 2.735-4.096C8.065 3.94 9.431 3.028 11.016 2.35A12.545 12.545 0 0116 1.333c1.738 0 3.4.34 4.984 1.017 1.585.678 2.95 1.59 4.097 2.736 1.146 1.146 2.057 2.511 2.735 4.096a12.545 12.545 0 011.017 4.985v.773c.554.478.998 1.136 1.332 1.977.335.84.502 1.757.502 2.75 0 1.527-.358 2.826-1.075 3.895-.716 1.07-1.58 1.605-2.592 1.605z"
    />
  </svg>
);

export default SvgIcAdmin;
