import { useQuery } from "@apollo/client";

import { ADS_POSTS } from "graphql/facebookCrawler/query";
import { FACEBOOK_ADS_IDS_ENABLE } from "config";
import { CrawlerType } from "types/FacebookCrawler";

export const useFetchFacebookAdsPost = (projectId: string, type: CrawlerType | null, limit: number, after: string) => {
  const { data: adsPostsData, loading: isAdsPostsDataLoading } = useQuery(ADS_POSTS, {
    skip: !projectId && !type,
    variables: {
      after,
      limit,
      projectId,
      type,
    },
    fetchPolicy: "network-only",
  });

  if (FACEBOOK_ADS_IDS_ENABLE.includes(projectId)) {
    return { adsPostsData, isAdsPostsDataLoading };
  }
  return { adsPostsData: {}, isAdsPostsDataLoading: false };
};
