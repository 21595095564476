import { OptionType } from "react-select";
import { TitleName } from "types/Billing";
import THAILAND_ADDRESS from "components/AutoCompleteAddress/ThailandAddress/thailand_address_data.json";

export const TitleOptions = [
  {
    key: TitleName.MR,
    label: "customer.title.mr",
    value: TitleName.MR,
  },
  {
    key: TitleName.MRS,
    label: "customer.title.mrs",
    value: TitleName.MRS,
  },
  {
    key: TitleName.MISS,
    label: "customer.title.miss",
    value: TitleName.MISS,
  },
];

export const ProvinceOptions = THAILAND_ADDRESS.map(
  (address): OptionType => ({ label: address[0] as string, value: address[0] as string }),
);
