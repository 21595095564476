import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import produce from "immer";
import { NOTIFICATION_UPDATE } from "constants/Notification";
import { notifySuccess, notifyError } from "utils/notify";
import { UPDATE_BRANCH } from "graphql/branch/mutation";
import { BranchType, FormDataBranchInput, BranchesQueryType } from "types/Branch";
import { BRANCHES } from "graphql/branch/query";

type UpdateBranchesVariablesType = {
  projectId: string;
  offlineStoreInput: FormDataBranchInput;
};

type UpdatedBranch = {
  offlineStore: BranchType;
};

const useUpdateBranch = (projectId: string) => {
  const { t } = useTranslation();

  const [updateBranch, { loading: isLoading }] = useMutation<UpdatedBranch, UpdateBranchesVariablesType>(
    UPDATE_BRANCH,
    {
      update(cache, { data }) {
        const cachedBranches = cache.readQuery({
          query: BRANCHES,
          variables: {
            projectId,
          },
        });

        if (cachedBranches && data) {
          const { offlineStore: updatedOfflineStore } = data;
          const index = (cachedBranches as BranchesQueryType).offlineStores.findIndex(
            (branch) => branch.id === updatedOfflineStore.id,
          );

          if (index === -1) {
            const updatedCacheBranches = produce((cachedBranches as BranchesQueryType).offlineStores, (draft) => {
              draft.push(updatedOfflineStore);
            });

            cache.writeQuery({
              query: BRANCHES,
              variables: {
                projectId,
              },
              data: {
                offlineStores: updatedCacheBranches,
              },
            });
          }
        }
      },
      onError: () => {
        notifyError(t(NOTIFICATION_UPDATE.FAIL));
      },
      onCompleted: () => {
        notifySuccess(t(NOTIFICATION_UPDATE.SUCCESS));
      },
    },
  );

  return {
    updateBranch,
    isLoading,
  };
};

export default useUpdateBranch;
