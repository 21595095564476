import {
  PromotionLabelsType,
  PromotionSelectorType,
  PromotionTypeLabelsType,
  PromotionTypesType,
  SpecificAmountSelectorType,
  DiscountType,
} from "types/Promotion";
import { METHOD } from "types/Shipping";

export enum PROMOTION_TYPES {
  PRODUCT,
  SHIPPING,
}

export const ERRORS = {
  SELECTED_PRODUCT_IS_REQUIRED: "SELECTED_PRODUCT_IS_REQUIRED",
};

export const PRODUCT_PROMOTION_GROUP_TYPES = {
  FREE_GIVEAWAY: "FREE_GIVEAWAY",
  BUY_X_GET_Y: "BUY_X_GET_Y",
  FIXED_PRICE_DISCOUNT: "FIXED_PRICE_DISCOUNT",
  FIXED_AMOUNT_DISCOUNT: "FIXED_AMOUNT_DISCOUNT",
  PERCENTAGE_DISCOUNT: "PERCENTAGE_DISCOUNT",
  FREE_LOWEST_PRICE: "FREE_LOWEST_PRICE",
};

export const SHIPPING_PROMOTION_GROUP_TYPES = {
  FREE_SHIPPING: "FREE_SHIPPING",
  FIXED_AMOUNT_DISCOUNT: "FIXED_AMOUNT_DISCOUNT",
  PERCENTAGE_DISCOUNT: "PERCENTAGE_DISCOUNT",
};

export const PROMOTION_THRESHOLDS = {
  PRICE: "price",
  AMOUNT: "amount",
};

export const PROMOTION_DISCOUNT_TYPES = {
  FIXED: "fixed",
  AMOUNT: "amount",
  PERCENTAGE: "percent",
};

// TODO: SHIPPING_PROMOTION_TYPES is the old one promotion constants. We should remove this one when we remove this IS_NEW_PROMOTION_PAGE_ENABLED flag
export const SHIPPING_PROMOTION_TYPES: PromotionTypesType = {
  FREE_SHIPPING: {
    BUY_ANY_PRODUCT: {
      value: "BUY_ANY_PRODUCT_TO_GET_FREE_SHIPPING",
      label: "For every purchase",
      isBundled: false,
      awardFromLowToHigh: true,
      discount: PROMOTION_DISCOUNT_TYPES.FIXED,
    },
    BUY_ANY_SELECTED_PRODUCT: {
      value: "BUY_ANY_SELECTED_PRODUCT_TO_GET_FREE_SHIPPING",
      label: "When buy selected product",
      isBundled: false,
      awardFromLowToHigh: true,
      discount: PROMOTION_DISCOUNT_TYPES.FIXED,
    },
    BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE: {
      value: "BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE_TO_GET_FREE_SHIPPING",
      label: "When buy any selected product",
      isBundled: true,
      awardFromLowToHigh: true,
      discount: PROMOTION_DISCOUNT_TYPES.FIXED,
      threshold: PROMOTION_THRESHOLDS.AMOUNT,
    },
    BUY_X_PRICE_IN_TOTAL_OF_ANY_PRODUCT: {
      value: "BUY_X_PRICE_IN_TOTAL_OF_ANY_PRODUCT_TO_GET_FREE_SHIPPING",
      label: "When cart reach specific price",
      isBundled: false,
      awardFromLowToHigh: true,
      discount: PROMOTION_DISCOUNT_TYPES.FIXED,
      threshold: PROMOTION_THRESHOLDS.PRICE,
      awardTotalPrice: true,
    },
    BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDLE: {
      value: "BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDLE_TO_GET_FREE_SHIPPING",
      label: "When buy any selected product reach specific price",
      isBundled: true,
      threshold: PROMOTION_THRESHOLDS.PRICE,
      discount: PROMOTION_DISCOUNT_TYPES.AMOUNT,
      awardFromLowToHigh: true,
      awardTotalPrice: true,
    },
  },
  FIXED_AMOUNT_DISCOUNT: {
    BUY_ANY_PRODUCT: {
      value: "BUY_ANY_PRODUCT_TO_GET_FIXED_DISCOUNT",
      label: "For every purchase",
      isBundled: false,
      awardFromLowToHigh: true,
      discount: PROMOTION_DISCOUNT_TYPES.AMOUNT,
    },
    BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE: {
      value: "BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE_TO_GET_FIXED_DISCOUNT",
      label: "When buy any selected product",
      isBundled: true,
      awardFromLowToHigh: true,
      discount: PROMOTION_DISCOUNT_TYPES.AMOUNT,
      threshold: PROMOTION_THRESHOLDS.AMOUNT,
    },
    BUY_X_PRICE_IN_TOTAL_OF_ANY_PRODUCT: {
      value: "BUY_X_PRICE_IN_TOTAL_OF_ANY_PRODUCT_TO_GET_FIXED_DISCOUNT",
      label: "When cart reach specific price",
      isBundled: true,
      awardFromLowToHigh: true,
      awardTotalPrice: true,
      discount: PROMOTION_DISCOUNT_TYPES.AMOUNT,
      threshold: PROMOTION_THRESHOLDS.PRICE,
    },
    BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDLE: {
      value: "BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDL_TO_GET_FIXED_DISCOUNT",
      label: "When buy any selected product reach specific price",
      isBundled: true,
      awardFromLowToHigh: true,
      awardTotalPrice: true,
      discount: PROMOTION_DISCOUNT_TYPES.AMOUNT,
      threshold: PROMOTION_THRESHOLDS.PRICE,
    },
  },
  PERCENTAGE_DISCOUNT: {
    BUY_ANY_PRODUCT: {
      value: "BUY_ANY_PRODUCT_TO_GET_PERCENTAGE_DISCOUNT",
      label: "For every purchase",
      isBundled: false,
      awardFromLowToHigh: true,
      discount: PROMOTION_DISCOUNT_TYPES.PERCENTAGE,
    },
    BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE: {
      value: "BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE_TO_GET_PERCENTAGE_DISCOUNT",
      label: "When buy any selected product",
      isBundled: true,
      awardFromLowToHigh: true,
      discount: PROMOTION_DISCOUNT_TYPES.PERCENTAGE,
      threshold: PROMOTION_THRESHOLDS.AMOUNT,
    },
  },
};

export const PRODUCT_PROMOTION_TYPES: PromotionTypesType = {
  FREE_GIVEAWAY: {
    BUY_ANY_PRODUCT: {
      value: "BUY_ANY_PRODUCT_TO_GET_FREE_GIVE_AWAY",
      label: "For every purchase",
      isBundled: false,
      awardFromLowToHigh: true,
    },
    BUY_ANY_SELECTED_PRODUCT: {
      value: "BUY_ANY_SELECTED_PRODUCT_TO_GET_FREE_GIVE_AWAY",
      label: "When buy selected product",
      isBundled: false,
      awardFromLowToHigh: true,
    },
    BUY_X_PRICE_IN_TOTAL_OF_ANY_PRODUCT: {
      value: "BUY_X_PRICE_IN_TOTAL_OF_ANY_PRODUCT_TO_GET_FREE_GIVE_AWAY",
      label: "When cart reach specific price",
      isBundled: false,
      threshold: PROMOTION_THRESHOLDS.PRICE,
      awardFromLowToHigh: true,
      awardTotalPrice: true,
    },
  },
  BUY_X_GET_Y: {
    BUY_X_PIECE_OF_SELECTED_PRODUCT: {
      value: "BUY_X_PIECE_OF_SELECTED_PRODUCT_TO_GET_BUY_X_GET_Y",
      label: "When buy selected product",
      isBundled: false,
      threshold: PROMOTION_THRESHOLDS.AMOUNT,
      awardFromLowToHigh: false,
    },
    BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE: {
      value: "BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE_TO_GET_BUY_X_GET_Y",
      label: "When buy any selected product",
      isBundled: true,
      threshold: PROMOTION_THRESHOLDS.AMOUNT,
      awardFromLowToHigh: true,
    },
  },
  FIXED_PRICE_DISCOUNT: {
    BUY_X_PIECE_OF_SELECTED_PRODUCT: {
      value: "BUY_X_PIECE_OF_SELECTED_PRODUCT_TO_GET_FIXED_PRICE",
      label: "When buy selected product",
      isBundled: false,
      threshold: PROMOTION_THRESHOLDS.AMOUNT,
      discount: PROMOTION_DISCOUNT_TYPES.FIXED,
      awardFromLowToHigh: false,
    },
    BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE: {
      value: "BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE_TO_GET_FIXED_PRICE",
      label: "When buy any selected product",
      isBundled: true,
      threshold: PROMOTION_THRESHOLDS.AMOUNT,
      discount: PROMOTION_DISCOUNT_TYPES.FIXED,
      awardFromLowToHigh: true,
    },
  },
  FIXED_AMOUNT_DISCOUNT: {
    BUY_X_PIECE_OF_SELECTED_PRODUCT: {
      value: "BUY_X_PIECE_OF_SELECTED_PRODUCT_TO_GET_FIXED_DISCOUNT",
      label: "When buy selected product",
      isBundled: false,
      threshold: PROMOTION_THRESHOLDS.AMOUNT,
      discount: PROMOTION_DISCOUNT_TYPES.AMOUNT,
      awardFromLowToHigh: false,
    },
    BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE: {
      value: "BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE_TO_GET_FIXED_DISCOUNT",
      label: "When buy any selected product",
      isBundled: true,
      threshold: PROMOTION_THRESHOLDS.AMOUNT,
      discount: PROMOTION_DISCOUNT_TYPES.AMOUNT,
      awardFromLowToHigh: true,
    },
    BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDLE: {
      value: "BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDLE_TO_GET_FIXED_DISCOUNT",
      label: "When buy any selected product reach specific price",
      isBundled: true,
      threshold: PROMOTION_THRESHOLDS.PRICE,
      discount: PROMOTION_DISCOUNT_TYPES.AMOUNT,
      awardFromLowToHigh: true,
    },
  },
  PERCENTAGE_DISCOUNT: {
    BUY_X_PIECE_OF_SELECTED_PRODUCT: {
      value: "BUY_X_PIECE_OF_SELECTED_PRODUCT_TO_GET_PERCENTAGE_DISCOUNT",
      label: "When buy selected product",
      isBundled: false,
      threshold: PROMOTION_THRESHOLDS.AMOUNT,
      discount: PROMOTION_DISCOUNT_TYPES.PERCENTAGE,
      awardFromLowToHigh: false,
    },
    BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE: {
      value: "BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE_TO_GET_PERCENTAGE_DISCOUNT",
      label: "When buy any selected product",
      isBundled: true,
      threshold: PROMOTION_THRESHOLDS.AMOUNT,
      discount: PROMOTION_DISCOUNT_TYPES.PERCENTAGE,
      awardFromLowToHigh: true,
    },
    BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDLE: {
      value: "BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDLE_TO_GET_PERCENTAGE_DISCOUNT",
      label: "When buy any selected product reach specific price",
      isBundled: true,
      threshold: PROMOTION_THRESHOLDS.PRICE,
      discount: PROMOTION_DISCOUNT_TYPES.PERCENTAGE,
      awardFromLowToHigh: true,
    },
  },
  FREE_LOWEST_PRICE: {
    BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE: {
      value: "BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE_TO_GET_FREE_LOWEST_PRICE",
      label: "When buy any selected product",
      isBundled: true,
      threshold: PROMOTION_THRESHOLDS.AMOUNT,
      awardFromLowToHigh: true,
    },
    BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDLE: {
      value: "BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDLE_TO_GET_FREE_LOWEST_PRICE",
      label: "When buy any selected product reach specific price",
      isBundled: true,
      threshold: PROMOTION_THRESHOLDS.PRICE,
      awardFromLowToHigh: true,
    },
  },
};

export const PROMOTION_PRODUCT_TYPE_LABELS: PromotionLabelsType = {
  FREE_GIVEAWAY: "Free gift",
  BUY_X_GET_Y: "Buy...Get...Free",
  FIXED_PRICE_DISCOUNT: "Fixed price",
  FIXED_AMOUNT_DISCOUNT: "Discount price",
  PERCENTAGE_DISCOUNT: "% discount",
  FREE_LOWEST_PRICE: "Free cheapest product",
};

export const PROMOTION_SHIPPING_TYPE_LABELS: PromotionLabelsType = {
  FIXED_AMOUNT_DISCOUNT: "Discount shipping price",
  PERCENTAGE_DISCOUNT: "% discount shipping",
  FREE_SHIPPING: "Free shipping",
};

export const PROMOTION_TYPE_LABELS: PromotionTypeLabelsType = {
  PRODUCT: PROMOTION_PRODUCT_TYPE_LABELS,
  SHIPPING: PROMOTION_SHIPPING_TYPE_LABELS,
};

export const PROMOTION_DISCOUNT_LABELS: PromotionLabelsType = {
  fixed: "Set fix price",
  amount: "Set fix discount",
  percent: "Set % discount",
};

export const PROMOTION_THRESHOLD_LABELS: PromotionLabelsType = {
  price: "How much total prices for products bundle",
  amount: "How many main products to bundle",
};

export const FREE_PRODUCT_AMOUNT_LABEL = "freeProductAmount";

export const AWARD_TOTAL_PRICE_OPTIONS = [
  {
    label: "Total price (include discount)",
    value: "1",
  },
  {
    label: "Actual price",
    value: "0",
  },
];

export const SHIPPING_METHOD_OPTIONS = [
  {
    label: METHOD.STANDARD,
    value: METHOD.STANDARD,
  },
  {
    label: METHOD.EXPRESS,
    value: METHOD.EXPRESS,
  },
];

export const SHIPPING_PROMOTIONS_WITH_SELECTED_PRODUCTS = [
  SHIPPING_PROMOTION_TYPES.FREE_SHIPPING.BUY_ANY_SELECTED_PRODUCT.value,
  SHIPPING_PROMOTION_TYPES.FREE_SHIPPING.BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE.value,
  SHIPPING_PROMOTION_TYPES.FREE_SHIPPING.BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDLE.value,
  SHIPPING_PROMOTION_TYPES.FIXED_AMOUNT_DISCOUNT.BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE.value,
  SHIPPING_PROMOTION_TYPES.FIXED_AMOUNT_DISCOUNT.BUY_X_PRICE_IN_TOTAL_OF_ANY_PRODUCT.value,
  SHIPPING_PROMOTION_TYPES.FIXED_AMOUNT_DISCOUNT.BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDLE.value,
  SHIPPING_PROMOTION_TYPES.PERCENTAGE_DISCOUNT.BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE.value,
];

export const MAP_CONDITION: Record<string, SpecificAmountSelectorType> = {
  BUY_ANY_PRODUCT: SpecificAmountSelectorType.FOR_EVERY_PURCHASE,
  BUY_ANY_SELECTED_PRODUCT: SpecificAmountSelectorType.FOR_EVERY_PURCHASE,
  BUY_X_PIECE_OF_ANY_PRODUCT: SpecificAmountSelectorType.AT_A_SPECIFIC_AMOUNT,
  BUY_X_PIECE_OF_SELECTED_PRODUCT: SpecificAmountSelectorType.AT_A_SPECIFIC_AMOUNT,
  BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE: SpecificAmountSelectorType.AT_A_SPECIFIC_AMOUNT,
  BUY_X_PRICE_IN_TOTAL_OF_ANY_PRODUCT: SpecificAmountSelectorType.AT_A_SPECIFIC_PRICE,
  BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT: SpecificAmountSelectorType.AT_A_SPECIFIC_PRICE,
  BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDLE: SpecificAmountSelectorType.AT_A_SPECIFIC_PRICE,
};

export const MAP_PROMOTION: Record<string, Record<string, PromotionSelectorType>> = {
  [DiscountType.SHIPPING]: {
    FIXED_AMOUNT_DISCOUNT: PromotionSelectorType.SHIPPING_DISCOUNT,
    FREE_SHIPPING: PromotionSelectorType.FREE_SHIPPING_PRICE,
    PERCENTAGE_DISCOUNT: PromotionSelectorType.SHIPPING_DISCOUNT,
  },
  [DiscountType.PRODUCT]: {
    BUY_X_GET_Y: PromotionSelectorType.FREE_PRODUCT,
    FIXED_AMOUNT_DISCOUNT: PromotionSelectorType.DISCOUNT,
    FIXED_PRICE_DISCOUNT: PromotionSelectorType.SPECIAL_PRICE,
    FREE_GIVEAWAY: PromotionSelectorType.FREE_PRODUCT,
    FREE_LOWEST_PRICE: PromotionSelectorType.FREE_CHEAPEST_PRODUCT,
    PERCENTAGE_DISCOUNT: PromotionSelectorType.DISCOUNT,
  },
};
