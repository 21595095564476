import React from "react";
import { useTranslation } from "react-i18next";

import Typography from "components/Typography";
import Grid from "components/Grid";
import TextField from "components/TextField";

import { TOTAL_PROVINCE } from "config";
import { Shipping, Device } from "types";
import useDevice from "utils/hooks/useDevice";
import { convertPriceFormat } from "utils/common";

import { ThaiBahtAdornment } from "../style";
import { LocationSettingCard } from "../../LocationSetting";

type PostalCodeBasePriceInfoPropsType = {
  fixedPrice: number;
  locationSettings?: Shipping.ProvinceSetting[];
};

const PostalCodeBasePriceInfo: React.FC<PostalCodeBasePriceInfoPropsType> = (props) => {
  const { t } = useTranslation();
  const device = useDevice();
  const isDesktop = device === Device.Device.DESKTOP;

  const { fixedPrice, locationSettings } = props;

  const locationSettingTotalInActiveProvince = locationSettings
    ? locationSettings.filter(({ isActive }) => !isActive).length
    : 0;
  const locationSettingTotalActiveProvince = TOTAL_PROVINCE - locationSettingTotalInActiveProvince;

  return (
    <>
      <Grid
        item
        container
        xs={12}
        sm={6}
        justify="space-between"
        spacing={!isDesktop ? 0 : 6}
        className={!isDesktop ? "pt-3" : "pl-3"}
      >
        <Grid item container xs={12} lg={6}>
          <Grid item xs={12}>
            <Typography variant="title8">{t("shipping.label.shippingBoundary")}</Typography>
          </Grid>
          <Grid item xs={12} className="my-2">
            <TextField
              value={t("shipping.value.shippingBoundary", { count: locationSettingTotalActiveProvince })}
              disabled
              normalColor
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} lg={6} className={!isDesktop ? "mt-2 pt-1" : ""}>
          <Grid item xs={12}>
            <Typography variant="title8">{t("shipping.label.defaultShippingRate")}</Typography>
          </Grid>
          <Grid item xs={12} className="my-2">
            <TextField
              value={convertPriceFormat(fixedPrice)}
              disabled
              normalColor
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: <ThaiBahtAdornment />,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {locationSettings && <LocationSettingCard locationSettings={locationSettings} />}
    </>
  );
};

export default PostalCodeBasePriceInfo;
