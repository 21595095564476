import { convertPriceFormat } from "utils/common";

const getDimensionText = (largestDimension: number[] | null | undefined, t: Function) => {
  let dimensions = largestDimension;
  const defaultDimension = [0, 0, 0];

  if (!dimensions) {
    dimensions = defaultDimension;
  }

  const textDimensions = dimensions.map((size) => convertPriceFormat(size));
  return `${textDimensions.join(" x ")} ${t("cm")}`;
};

export default getDimensionText;
