import { gql } from "@apollo/client";

export type ResultsType = {
  id: string;
  orderNumber: string;
  state: string;
  grandTotal: string;
  customerName: string;
};

export type OrdersType = {
  results: ResultsType[];
  total: string;
};

export const ORDERS = gql`
  query orders($projectId: ID!, $limit: Int, $offset: Int, $filter: OrderFilterParams) {
    orders(projectId: $projectId, limit: $limit, offset: $offset, filter: $filter) {
      results {
        id
        cancelOrderAction
        orderNumber
        state
        shippingMethod
        grandTotal
        customerName
        paymentMethodType
        customerId
        customer {
          name
          displayName
          pictureUrl
          platform
        }
        deliverySchedule
        paymentBillImageSrc
        depositBillImageSrc
        shippingDescription
        shippingCalculationType
        orderExternalId
        updatedAt
        shipping {
          shippingId
          type
          name
          enDisplayName
          thDisplayName
          price
          iconUrl
          isCod
        }
      }
      total
    }
  }
`;

export const ORDER_BY_ORDER_NUMBER = gql`
  query orderByOrderNumber($projectId: ID!, $orderNumber: String!) {
    orderByOrderNumber(projectId: $projectId, orderNumber: $orderNumber) {
      id
      orderNumber
      state
      shippingMethod
      shippingPrice
      shippingPriceNote
      shippingDuration
      shippingDescription
      shippingCalculationType
      voidOrRefundAmount
      cancelOrderAction
      customerId
      customer {
        name
        displayName
        pictureUrl
        platform
      }
      paymentBillImageSrc
      secondaryPayslipImages
      depositBillImageSrc
      createdAt
      updatedAt
      address
      firstName
      lastName
      streetAddress
      streetAddress2
      district
      subDistrict
      province
      postalCode
      phoneNumber
      email
      deliveryNote
      note
      vatOptions
      vatValue
      vatPrice
      totalPrice
      totalWeight
      discountTotalPrice
      discountShippingPrice
      grandTotal
      paymentAmount
      paymentBankName
      paymentAt
      orderExternalId
      deeplePayPaymentInformation {
        amount
        channel
        status
        paymentAt
      }
      shippedDetail {
        detail {
          carrierName
          deliveryDate
          trackingNumber
          otherCarrierName
          note
        }
      }
      bill {
        billNumber
      }
      orderProducts {
        id
        productSKU {
          id
          image {
            src
          }
          productCode
          isFree
          productType {
            value
            key {
              key
            }
          }
          digitalContentOptions {
            outputType
            settings {
              availability {
                type
                settings {
                  unit
                  value
                  availableAt
                  expiredAt
                }
              }
              content
            }
          }
        }
        isFree
        isAddedByAdmin
        amount
        options {
          name
          isActive
          isMultipleChoices
          isRequired
          maximum
          choices {
            name
            price
            isActive
            isDefault
            isSelected
          }
        }
        note
        product {
          id
          type
          projectId
          name
          brand
          productCode
          description
          defaultPrice
          images {
            src
          }
        }
        productCode
        productPrice
        amount
        image {
          src
        }
      }
      productPromotions {
        id
        totalDiscount
        name
        type
        description
        settings {
          type
          condition
          isBundled
          awardTotalPrice
          awardFromLowToHigh
          threshold {
            amount
            price
          }
          discount {
            amount
            fixed
            percent
          }
          shippingMethod
          criteria {
            operator
            thresholds {
              amount
              price
            }
            discount {
              amount
              fixed
              percent
            }
            freeProducts {
              id
              projectId
              productId
              productCode
              image
              selected
              deleted
              isFree
              product {
                id
                name
                productCode
                images {
                  src
                }
              }
              productType {
                value
                key {
                  key
                }
              }
              amount
              stock
            }
          }
        }
        selectedProducts {
          id
          amount
          price
          product {
            id
            name
          }
          productType {
            value
            key {
              key
            }
          }
          # for now no need to use this one
          # Because Cache data may be lost when replacing the selectedProducts field of a Promotion object.
          # promotions {
          #   id
          #   name
          #   description
          #   type
          #   totalDiscount
          #   settings {
          #     type
          #     condition
          #     isBundled
          #     awardTotalPrice
          #     awardFromLowToHigh
          #     threshold {
          #       amount
          #       price
          #     }
          #     discount {
          #       amount
          #       fixed
          #       percent
          #     }
          #     shippingMethod
          #   }
          #   selectedProducts {
          #     id
          #     product {
          #       id
          #       name
          #     }
          #     productType {
          #       value
          #       key {
          #         key
          #       }
          #     }
          #   }
          #   freeProducts {
          #     id
          #     product {
          #       id
          #       name
          #     }
          #     productType {
          #       value
          #       key {
          #         key
          #       }
          #     }
          #     amount
          #   }
          # }
        }
        freeProducts {
          id
          product {
            id
            name
          }
          productType {
            value
            key {
              key
            }
          }
          amount
        }
      }
      shippingPromotions {
        id
        name
        description
        type
        settings {
          type
          condition
          isBundled
          threshold {
            amount
            price
          }
          discount {
            amount
            fixed
            percent
          }
          shippingMethod
        }
        selectedProducts {
          id
          product {
            id
            name
          }
          productType {
            value
            key {
              key
            }
          }
        }
      }
      paymentMethodType
      depositAmount
      depositBillImageSrc
      depositBankName
      depositedAt
      remark
      deliverySchedule
      referenceCode
      adminsNote
      shipmentDetails {
        id
        projectId
        orderId
        referenceId
        status
        assigningDriverAt
        onGoingAt
        cancelledAt
        pickedUpAt
        rejectedAt
        completedAt
        expiredAt
        createdAt
        updatedAt
        driver {
          name
          phone
          plateNumber
          photo
        }
      }
      shippingCalculationType
      branchInformation {
        id
        projectId
        name
        address
        phoneNumber
        location
        isActive
        isAvailableForPickUp
        openingHours {
          day
          open
          close
          isActive
        }
      }
      additionalDiscount

      voidedBy
      voidedAt
      voidReason

      largestDimension

      createdBy
      cancelledBy

      shipping {
        shippingId
        type
        name
        enDisplayName
        thDisplayName
        price
        iconUrl
        isCod
      }
    }
  }
`;

export const LALAMOVE_QUOTATION_PRICE = gql`
  query lalamoveQuotationPrice($projectId: ID!, $orderId: ID!, $deliverySchedule: String!) {
    lalamoveQuotationPrice(projectId: $projectId, orderId: $orderId, deliverySchedule: $deliverySchedule)
  }
`;

export const TOTAL_ORDER_NUMBER_STATES = gql`
  query totalOrderNumberStates($projectId: ID!) {
    totalOrderNumberStates(projectId: $projectId) {
      ALL @client
      COD_PREPARE_TO_SHIP
      ORDER_PLACED
      COD_PICKED_UP
      PREPARE_TO_SHIPPING
      PENDING_FOR_SHIPPING_COST
      WAIT_FOR_PAYMENT_APPROVE
    }
  }
`;
