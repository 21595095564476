import * as React from "react";

const SvgIcArrowBack = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.33 16l5.645 5.645a1.38 1.38 0 11-1.95 1.951l-6.621-6.62a1.38 1.38 0 010-1.951l6.62-6.621a1.38 1.38 0 011.951 1.95L13.33 16z"
    />
  </svg>
);

export default SvgIcArrowBack;
