import styled from "styled-components";

export const PackageDetailWrapper = styled.div`
  min-width: "180px";

  .package-summary-label {
    white-space: nowrap;
    overflow: visible;
  }
`;

export const CardWithShadow = styled.div`
  border-left: 0.5px solid ${({ theme }) => theme.COLORS.LightBlue};
  position: absolute;
  width: 15px;
  height: 150px;
  left: 290px;
  top: 0px;
  overflow-y: hidden;
  background: linear-gradient(90.03deg, rgba(202, 210, 228, 0.3) 0.03%, rgba(255, 255, 255, 0) 109.97%);
`;

export const HorizontalScrollWrapper = styled.div`
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;
  padding-bottom: 10px;
  flex: 1;
  height: 130px;
  width: 400px;
`;

export const SummaryWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const SummaryWrapperItem = styled.div`
  height: 82px;
  max-height: 82px;
  padding-right: 24px;
  min-width: max-content;
`;

export const PackageSummaryWrapper = styled.div`
  display: flex;
  flex: 0;
  flex-basis: 290px;
  flex-direction: column;
  width: 290px;
  height: 150px;
`;
