import { Grid } from "@material-ui/core";
import { get } from "lodash";
import React, { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import { OptionType } from "react-select";
import { Control, DeepMap, FieldError } from "react-hook-form";

import AutoCompleteAddressForm from "components/AutoCompleteAddress";
import DropZoneUpload from "components/DropzoneUpload";
import ErrorText from "components/ErrorText";
import ImageUpload from "components/ImageUpload";
import { AddImageBox } from "components/ImageUpload/styled";
import { NewControllerSelect } from "components/Select";
import TextField from "components/TextField";

import Typography from "components/Typography";
import COLORS from "constants/Colors";
import { UPLOAD_IMAGE } from "constants/Image";
import { TitleOptions } from "constants/information";
import { FieldWrapper, InformationTypeButton } from "domain/Billing/Plan/PlanBilling/BillingInfo/styled";
import { TitleName } from "types/Billing";
import { KYCInformationType } from "types/DeeplePay";
import { ImageUrl, UploadDirectoryType } from "types/Image";
import { PersonalValidation } from ".";
import { BrowseButton, ExampleText, ImageWrapper, UploadBox } from "./styled";

export interface PersonalDetailPropsType {
  projectId: string;
  isMobile: boolean;
  personalDetail: PersonalValidation;
  errors?: DeepMap<PersonalValidation, FieldError>;
  register: any;
  control: Control<PersonalValidation>;
  handleSetKYCDetail: (field: keyof PersonalValidation, value: string | KYCInformationType) => void;
  handleChangeImage: (type: "personal" | "bankAccount", image: ImageUrl[]) => void;
  handleRemoveImage: (type: "personal" | "bankAccount") => void;
  setExampleType: (value: "personal" | "bankAccount" | "business") => void;
}

const PersonalDetail: FC<PersonalDetailPropsType> = ({
  isMobile,
  personalDetail,
  handleChangeImage,
  handleSetKYCDetail,
  handleRemoveImage,
  projectId,
  errors,
  register,
  control,
  setExampleType,
}) => {
  const { t } = useTranslation();
  const isPersonalType = personalDetail.type === KYCInformationType.PERSONAL;
  const isCompanyType = personalDetail.type === KYCInformationType.COMPANY;
  const titleOptions = TitleOptions.map(({ label, ...props }) => ({ label: t(label), ...props }));
  const titleDefaultValue = titleOptions.find(({ value }) => value === TitleName.MR);
  const titleValue = titleOptions.find(({ value }) => value === personalDetail.titleName) || titleDefaultValue;

  return (
    <Grid container className={isMobile ? "p-4" : "px-5 py-3"}>
      <Grid style={{ textAlign: "center" }} item className="mt-2 w-100" justify="center">
        <Typography color={COLORS.Dark} variant="title2">
          {t("deeplePay.verifyBilling.personalInfo.title")}
        </Typography>
      </Grid>
      <Grid container className="py-4" justify="center" item xs={12}>
        <InformationTypeButton
          onClick={() => handleSetKYCDetail("type", KYCInformationType.PERSONAL)}
          className={isPersonalType ? "selected" : ""}
        >
          {t("billing.info.form.personal.button.label")}
        </InformationTypeButton>
        <InformationTypeButton
          onClick={() => handleSetKYCDetail("type", KYCInformationType.COMPANY)}
          className={isCompanyType ? "selected" : ""}
        >
          {t("billing.info.form.company.button.label")}
        </InformationTypeButton>
      </Grid>
      <Grid container className="p-0 m-0">
        <UploadBox item>
          <Grid item className="mt-2 mb-0 title">
            <Typography color={COLORS.DarkLight} variant="body3">
              {t(
                isPersonalType
                  ? "deeplePay.verifyBilling.personalInfo.individual.description"
                  : "deeplePay.verifyBilling.personalInfo.corporate.description",
              )}
              <ExampleText className="pl-1" onClick={() => setExampleType(isPersonalType ? "personal" : "business")}>
                {t("deeplePay.verifyBilling.bankingInfo.seeExample")}
              </ExampleText>
            </Typography>
          </Grid>
          {personalDetail.documentVerificationCertificatePath ? (
            <ImageWrapper container justify="center" className="mt-2 mb-3">
              <ImageUpload
                height={160}
                onChange={(img: ImageUrl[]) => handleChangeImage("personal", img)}
                onRemove={() => handleRemoveImage("personal")}
                image={personalDetail.documentVerificationCertificatePath}
                directoryType={UploadDirectoryType.PROJECT_CERTIFICATION}
              />
            </ImageWrapper>
          ) : (
            <DropZoneUpload
              projectId={projectId}
              UploadDirectory={UploadDirectoryType.PROJECT_CERTIFICATION}
              onChange={(img: ImageUrl[]) => handleChangeImage("personal", img)}
              limitItem={1}
              maxSizeMB={2}
              multiple={false}
            >
              <AddImageBox height={150}>
                <img src={UPLOAD_IMAGE} alt="upload-bank" />
                <Typography className="mt-2" variant="title8" color="gray">
                  {t("Drag and drop")}
                </Typography>
                <Typography className="my-2" variant="body3" color="gray">
                  {t("OR")}
                </Typography>
                <BrowseButton disabled size="small">
                  <Typography variant="body3" className="py-2 px-4" color={COLORS.White}>
                    {t("Browse")}
                  </Typography>
                </BrowseButton>
              </AddImageBox>
            </DropZoneUpload>
          )}
        </UploadBox>
        <ErrorText className="pb-2">
          {errors?.documentVerificationCertificatePath &&
            t(errors?.documentVerificationCertificatePath?.message as string)}
        </ErrorText>
        <Grid item xs={12} className="mb-1">
          <Typography variant="body4" color="darkMed" className="pb-1">
            {isCompanyType ? t("planBilling.info.form.label.companyName") : t("billing.info.form.personal.name")}
          </Typography>
          <Grid container justify="space-between">
            {isPersonalType && (
              <>
                <Grid item xs={3}>
                  <NewControllerSelect
                    control={control}
                    defaultValue={(titleValue || titleDefaultValue) as OptionType}
                    value={titleValue as OptionType}
                    error={Boolean(errors?.titleName)}
                    name="titleName"
                    type="text"
                    validate={register}
                    options={titleOptions}
                    fullWidth
                    onChange={(value: any) => handleSetKYCDetail("titleName", (value?.value as unknown) as string)}
                    placeholder=""
                  />
                  <Typography variant="body4" className="pt-1">
                    <ErrorText className="pt-1">
                      {errors?.titleName && t(errors?.titleName.message as string)}
                    </ErrorText>
                  </Typography>
                </Grid>
                <Grid item xs={1} />
              </>
            )}
            <Grid item xs={isPersonalType ? 8 : 12}>
              <TextField
                defaultValue={get(personalDetail, "name") || ""}
                error={Boolean(errors?.name)}
                fullWidth
                name="name"
                validate={register}
                variant="outlined"
                onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  handleSetKYCDetail("name", e.target.value)
                }
              />
              <Typography variant="body4" className="pt-1">
                <ErrorText className="pt-1">
                  {errors?.name &&
                    (isCompanyType
                      ? t("planBilling.info.error.companyName.required")
                      : t(errors?.name.message as string))}
                </ErrorText>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mb-1">
          <Typography variant="body4" color="darkMed" className="pb-1">
            {t("planBilling.info.form.label.taxId")}
          </Typography>
          <TextField
            defaultValue={get(personalDetail, "taxId") || ""}
            error={Boolean(errors?.taxId)}
            fullWidth
            name="taxId"
            validate={register}
            variant="outlined"
            onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              handleSetKYCDetail("taxId", e.target.value)
            }
          />
          <Typography variant="body4" className="pt-1">
            <ErrorText className="pt-1">{errors?.taxId && t(errors?.taxId.message as string)}</ErrorText>
          </Typography>
        </Grid>
        <Grid item xs={12} className="mb-1">
          <Typography variant="body4" color="darkMed" className="pb-1">
            {isCompanyType ? t("planBilling.info.form.label.address") : t("billing.info.form.personal.address")}
          </Typography>
          <TextField
            defaultValue={get(personalDetail, "address") || ""}
            error={Boolean(errors?.address)}
            fullWidth
            name="address"
            validate={register}
            variant="outlined"
            onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              handleSetKYCDetail("address", e.target.value)
            }
          />
          <Typography variant="body4" className="pt-1">
            <ErrorText className="pt-1">
              {errors?.address &&
                (isCompanyType
                  ? t("planBilling.info.error.companyAddress.required")
                  : t(errors?.address.message as string))}
            </ErrorText>
          </Typography>
        </Grid>
        <Grid style={{ position: "relative" }} item xs={12} className="mb-1">
          <Typography variant="body4" color="darkMed" className="pb-1">
            {t("billing.info.form.postalCode")}
          </Typography>
          <AutoCompleteAddressForm
            isDefaultInput
            type="text"
            name="postalCode"
            defaultValue={get(personalDetail, "postalCode") || ""}
            error={Boolean(errors?.postalCode)}
            validate={register}
            setAddressData={handleSetKYCDetail}
          />
          <Typography variant="body4" className="pt-1">
            <ErrorText className="pt-1">{errors?.postalCode && t(errors?.postalCode.message as string)}</ErrorText>
          </Typography>
        </Grid>
        <Grid item xs={12} className="mb-1">
          <FieldWrapper container>
            <Grid item className="first" xs={6}>
              <Typography variant="body4" color="darkMed" className="pb-1">
                {t("billing.info.form.subDistrict")}
              </Typography>
              <TextField
                defaultValue={get(personalDetail, "subDistrict") || ""}
                error={Boolean(errors?.subDistrict)}
                fullWidth
                name="subDistrict"
                validate={register}
                multiline
                variant="outlined"
                rows={1}
                onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  handleSetKYCDetail("subDistrict", e.target.value)
                }
              />
              <Typography variant="body4" className="pt-1">
                <ErrorText className="pt-1">
                  {errors?.subDistrict && t(errors?.subDistrict.message as string)}
                </ErrorText>
              </Typography>
            </Grid>
            <Grid item className="second" xs={6}>
              <Typography variant="body4" color="darkMed" className="pb-1">
                {t("billing.info.form.district")}
              </Typography>
              <TextField
                defaultValue={get(personalDetail, "district") || ""}
                error={Boolean(errors?.district)}
                fullWidth
                name="district"
                validate={register}
                multiline
                variant="outlined"
                rows={1}
                onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  handleSetKYCDetail("district", e.target.value)
                }
              />
              <Typography variant="body4" className="pt-1">
                <ErrorText className="pt-1">{errors?.district && t(errors?.district.message as string)}</ErrorText>
              </Typography>
            </Grid>
          </FieldWrapper>
        </Grid>
        <Grid item xs={12} className="mb-1">
          <Typography variant="body4" color="darkMed" className="pb-1">
            {t("billing.info.form.province")}
          </Typography>
          <TextField
            defaultValue={get(personalDetail, "province") || ""}
            error={Boolean(errors?.province)}
            fullWidth
            name="province"
            validate={register}
            multiline
            variant="outlined"
            rows={1}
            onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              handleSetKYCDetail("province", e.target.value)
            }
          />
          <Typography variant="body4" className="pt-1">
            <ErrorText className="pt-1">{errors?.province && t(errors?.province.message as string)}</ErrorText>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PersonalDetail;
