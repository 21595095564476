import { useQuery } from "@apollo/client";
import { TotalWareHouseQuery } from "types/Product";
import { ProjectIdType } from "types/Project";
import { TOTAL_WAREHOUSE } from "graphql/product/query";

const useTotalWarehouse = (projectId: string, skip?: boolean) => {
  const { data, loading } = useQuery<TotalWareHouseQuery, ProjectIdType>(TOTAL_WAREHOUSE, {
    variables: { projectId },
    skip,
  });

  return {
    isLoading: loading,
    totalCategory: data?.totalWareHouse?.totalCategory || 0,
    totalProduct: data?.totalWareHouse?.totalProduct || 0,
    totalProductSKU: data?.totalWareHouse?.totalProductSKU || 0,
  };
};

export default useTotalWarehouse;
