import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import groupBy from "lodash/groupBy";
import capitalize from "lodash/capitalize";

import Card from "components/Card";
import Grid from "components/Grid";
import Typography from "components/Typography";
import Divider from "components/Divider";

import { AddonSummaryItems, AddonChargedItems, AddonSummaryItem, AddonChargedItem, AddonEntityType } from "types/Addon";
import { convertPriceFormat } from "utils/common";
import PaymentSummaryItem from "../PaymentSummaryItem";

type PaymentSummaryCardPropsType = {
  summaryType: string;
  summaryData: AddonSummaryItems | AddonChargedItems;
  totalPrice: number;
};

const PaymentSummaryCard: FC<PaymentSummaryCardPropsType> = (props) => {
  const { t } = useTranslation();

  const { summaryType, summaryData, totalPrice: dueTodayTotalPrice } = props;
  const { subtotal, totalPrice, vatOptions, vatPrice, items } = summaryData;
  const { previousSubtotal, changesSubtotal } = summaryData as AddonSummaryItems;

  const renderSummaryItems = () => {
    if (summaryType === "charged") {
      return (items as AddonChargedItem[]).map((item: AddonChargedItem) => {
        const { entityType, name, amount, totalPrice: itemTotalPrice } = item;

        const labelText = `${amount} ${t(`planAddon.summaryItem.label.${entityType.toLowerCase()}`)}`;
        const priceText = `${t("THB")} ${convertPriceFormat(itemTotalPrice)}`;

        return <PaymentSummaryItem key={name} label={labelText} price={priceText} color="darkGray" />;
      });
    }

    const groupItems = groupBy(items as AddonSummaryItem[], "status");

    return Object.values((groupItems as unknown) as Array<AddonSummaryItem[]>)
      .map((groupItem: AddonSummaryItem[]) => {
        return (groupItem as AddonSummaryItem[]).map((item: AddonSummaryItem) => {
          const { entityType, status, name, amount, totalPrice: itemTotalPrice } = item;

          const isSubtractStatus = status === "SUBTRACTION";
          const isNoneStatus = status === "NONE";

          let textColor = "darkGray";
          if (status === "ADDITION") {
            textColor = "accent";
          } else if (isSubtractStatus) {
            textColor = "error";
          }

          const itemQuota = entityType === AddonEntityType.IMAGE_SEARCH ? name.split(" ")[0] : "";

          const sign = isSubtractStatus ? "- " : "+ ";
          const labelText = `${isNoneStatus ? "" : sign}${
            isNoneStatus ? "" : `${amount} `
          }${t(`planAddon.summaryItem.label.${entityType.toLowerCase()}`, { itemQuota })}`;
          const priceText = `${isSubtractStatus ? sign : ""}${t("THB")} ${convertPriceFormat(itemTotalPrice)}`;

          return <PaymentSummaryItem key={`${status}-${name}`} label={labelText} price={priceText} color={textColor} />;
        });
      })
      .flat();
  };

  return (
    <Card noShadow padding="0px" className="w-100">
      <Grid container>
        <Grid item className="px-4 py-3 my-1">
          <Typography variant="title3" color="dark">
            {t(`planAddon.paymentSummary.title.${summaryType}`)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider thickness="0.5px" className="m-0" />
        </Grid>
        <Grid item container className="px-4 py-3">
          {renderSummaryItems()}
        </Grid>
        {/* SUMMARY */}
        <Grid item xs={12}>
          <Divider thickness="0.5px" className="m-0" />
        </Grid>
        <Grid container className="px-4 py-3">
          {!!previousSubtotal && (
            <Grid container justify="space-between" alignItems="center" className="pt-2">
              <Grid item>
                <Typography variant="body3" color="darkGray">
                  {t("planAddon.paymentSummary.label.previousSubtotal")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body3" color="darkGray">
                  {t("THB")}&nbsp;{convertPriceFormat(previousSubtotal)}
                </Typography>
              </Grid>
            </Grid>
          )}
          {!!changesSubtotal && (
            <Grid container justify="space-between" alignItems="center" className="pt-2">
              <Grid item>
                <Typography variant="body3" color={changesSubtotal < 0 ? "error" : "primary"}>
                  {t("planAddon.paymentSummary.label.changesSubtotal")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body3" color={changesSubtotal < 0 ? "error" : "primary"}>
                  {t("THB")}&nbsp;{convertPriceFormat(changesSubtotal)}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container justify="space-between" alignItems="center" className="pt-2">
            <Grid item>
              <Typography variant="body3" color="darkGray">
                {t("planAddon.paymentSummary.label.subtotal")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body3" color="darkGray">
                {t("THB")}&nbsp;{convertPriceFormat(subtotal)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="space-between" alignItems="center" className="pt-2">
            <Grid item>
              <Typography variant="body3" color="darkGray">
                {t("planAddon.paymentSummary.label.vat", { vatOptions: t(capitalize(vatOptions)) })}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body3" color="darkGray">
                {t("THB")}&nbsp;{convertPriceFormat(vatPrice)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="space-between" alignItems="center" className="pt-3">
            <Grid item>
              <Typography variant="title8" color="dark">
                {t("planAddon.paymentSummary.label.total")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="title7" color="dark">
                {t("THB")}&nbsp;{convertPriceFormat(totalPrice)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider thickness="0.5px" className="m-0" />
      </Grid>
      <Grid item container xs={12} alignItems="center" justify="space-between" className="px-4 pt-3 pb-2">
        <Grid item>
          <Typography variant="title3" color="dark">
            {t("planUpgrade.confirmModal.dueToday")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="title3" color="dark">
            {t("THB")} {convertPriceFormat(dueTodayTotalPrice)}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container xs={12} alignItems="center" justify="space-between" className="px-4 pb-3">
        <Typography variant="body4" color="darkLight">
          {t("planAddon.confirmModal.description1")}
          <div className="mt-1" />
          {t("planAddon.confirmModal.description2")}
        </Typography>
      </Grid>
    </Card>
  );
};

export default PaymentSummaryCard;
