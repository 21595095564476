import React from "react";
import { useTranslation } from "react-i18next";

import Grid from "components/Grid";
import ButtonBase from "components/ButtonBase";
import Divider from "components/Divider";
import { SvgIcon } from "components/Icon";
import { IcArrowBack } from "components/SvgIcons";

import BroadcastContainer from "../BroadcastContainer";

interface BroadcastLineFormProps {
  onClick: () => void;
  broadcastFormId: string;
  onClose: () => void;
}

function BroadcastLineForm({ onClick, broadcastFormId, onClose }: BroadcastLineFormProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Grid id="BroadcastFormPageContainer" item container xs={12}>
      <ButtonBase color="secondary" className="ml-3 mt-4 p-2" onClick={onClick}>
        <SvgIcon component={IcArrowBack} fontSize="small" />
        {t("Back")}
      </ButtonBase>

      <Grid item xs={12} className="px-3">
        <Divider margin="22px 0 0 0" />
      </Grid>
      <Grid item xs={12} className="pt-1">
        <BroadcastContainer closeFrom={onClose} broadcastFormId={broadcastFormId} />
      </Grid>
    </Grid>
  );
}

export default BroadcastLineForm;
