import { yupResolver } from "@hookform/resolvers/yup";
import React, { FC, useState, useRef, useEffect, useMemo } from "react";
import { useForm, FieldError, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import clsx from "clsx";

import Typography from "components/Typography";
import { ButtonWithIcon } from "components/Button";
import Grid from "components/Grid";
import HighlightWithinTextarea from "components/HighlightWithinTextarea";
import IcError from "components/SvgIcons/IcError";
import IcDelete from "components/SvgIcons/IcDelete";
import IcHelp from "components/SvgIcons/IcHelp";
import { SvgIcon } from "components/Icon";
import ErrorText from "components/ErrorText";
import { Highlight } from "types/AISetting";
import getValidationSchema from "./validationSchema";

type QuestionFormType = { questionInput: string };

type QuestionInputPropsType = {
  defaultValue?: string;
  id: string;
  onBlur?: (newQuestion: string, id: string) => Promise<void>;
  onChange?: (newQuestion: string, id: string) => void;
  onRemove?: (id: string) => void;
  isAddedQuestion?: boolean;
  isDisabledDelete?: boolean;
  highlights?: Highlight[];
};

const QuestionInput: FC<QuestionInputPropsType> = ({
  defaultValue = "",
  onBlur,
  onChange,
  onRemove,
  id,
  isAddedQuestion = false,
  isDisabledDelete = false,
  highlights = [],
}) => {
  const { t } = useTranslation();
  const previousValue = useRef<{
    questionInput: string;
  }>();

  const previousQuestion: string = get(previousValue, "current.questionInput");
  const [isHover, setIsHover] = useState(false);
  const [isShowPanel, setIsShowPanel] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    previousValue.current = {
      questionInput: defaultValue,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isHover && !isChanged && !isFocus && !isAddedQuestion) {
      setIsShowPanel(false);
    } else if (isChanged || isFocus || (isFocus && !isHover)) {
      setIsShowPanel(true);
    }
  }, [isAddedQuestion, isChanged, isFocus, isHover]);

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  const { control, handleSubmit, errors, setError, clearErrors, setValue } = useForm<QuestionFormType>({
    defaultValues: {
      questionInput: defaultValue,
    },
    resolver: yupResolver(validationSchema),
  });

  const handleClickRemoveButton = () => {
    if (onRemove && id) {
      onRemove(id);
    }
  };

  const handleClickCancelButton = () => {
    if (isAddedQuestion) {
      setValue("questionInput", "");

      if (onChange) {
        onChange("", id);
      }
    } else if (previousValue.current) {
      setValue("questionInput", previousValue.current.questionInput);

      if (onChange) {
        onChange(previousValue.current.questionInput, id);
      }
    }

    setIsFocus(false);
  };

  const handleFocus = () => {
    if (!isFocus) {
      setIsFocus(true);
    }
  };

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const handleBlur = (event: React.BaseSyntheticEvent<object>) => {
    setIsFocus(false);

    handleSubmit(async (data) => {
      if (onBlur && id != null) {
        try {
          await onBlur(data.questionInput, id);
        } catch (error) {
          if (!errors.questionInput) {
            setError("questionInput", { type: "manual", message: error.message });
          }
        }
      }
    })(event);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={isShowPanel ? "" : "pb-2"}>
      <Grid container className="flex-nowrap">
        <Grid item className="flex-grow-0 d-flex align-items-center pt-2 pr-3">
          <IcHelp />
        </Grid>
        <Grid item className="flex-grow-1 d-flex flex-column">
          <div className="MuiFormControl-root ">
            <div className={clsx("MuiInput-underline", { "Mui-error": Boolean(errors.questionInput) })}>
              <div className=" MuiInputBase-input MuiInput-input">
                <Controller
                  control={control}
                  name="questionInput"
                  defaultValue={defaultValue}
                  render={({ onChange: onChangeField }) => (
                    <HighlightWithinTextarea
                      value={defaultValue}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      onChange={(value: string) => {
                        if ((errors?.questionInput as FieldError)?.type === "manual") {
                          clearErrors("questionInput");
                        }

                        if (value !== previousQuestion) {
                          setIsChanged(true);
                        } else {
                          setIsChanged(false);
                        }

                        if (onChange && id != null) {
                          onChange(value, id);
                          onChangeField(value);
                        }
                      }}
                      highlight={highlights}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          {errors?.questionInput && <ErrorText>{t((errors.questionInput as FieldError).message as string)}</ErrorText>}
        </Grid>
      </Grid>

      <Grid container className="pl-1">
        <Grid className="flex-1 px-4" item container>
          {Boolean(highlights?.[0]?.overlappedIntents?.length) && (
            <Typography className="my-2" color="orange" variant="body3">
              {t("faq.question.overlappedIntents")} {highlights?.[0]?.overlappedIntents?.join(", ")}
            </Typography>
          )}
        </Grid>

        {isShowPanel && (
          <Grid className="flex-0" item container justify="flex-end">
            <Grid className="d-flex" item>
              <ButtonWithIcon
                className="mx-1 mt-2"
                disabled={isDisabledDelete}
                type="button"
                onClick={handleClickRemoveButton}
              >
                <SvgIcon className="ml-1" component={IcDelete} fontSize="small" />
              </ButtonWithIcon>

              <ButtonWithIcon
                className="mx-1 mt-2"
                onClick={handleClickCancelButton}
                type="button"
                disabled={isAddedQuestion || !isChanged}
              >
                <SvgIcon className="ml-1" component={IcError} fontSize="small" />
              </ButtonWithIcon>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default QuestionInput;
