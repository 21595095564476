import styled, { css } from "styled-components";

interface TabProps {
  isSelected: boolean;
  isActive?: boolean;
}

const Tab = styled.button<TabProps>`
  border: solid 0.5px ${({ theme, isSelected }) => (isSelected ? theme.COLORS.LightBlue : "transparent")};
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.COLORS.White : "transparent")};
  border-radius: 8px;
  padding: 12px 10px;
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
      border-color: ${({ theme }) => theme.COLORS.Primary};
    `}
`;

export default Tab;
