import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";
import nanoid from "utils/nanoid";

import { GQLProductSKUType } from "types/Product";

export const getInitAllTypesFromProductSKUs = (productSKUsData: GQLProductSKUType[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let allTypes: any[] = [];
  productSKUsData.forEach((productSKU: GQLProductSKUType) => {
    productSKU.productType.forEach((type) => {
      const keyIndex = allTypes.findIndex(({ value: _a, ...newType }) => {
        const { key } = type;
        return JSON.stringify(key) === JSON.stringify(newType);
      });
      if (keyIndex !== -1) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { key, ...temp } = type;
        allTypes[keyIndex].value = [...allTypes[keyIndex].value, temp];
      } else {
        const { key, ...temp } = type;
        allTypes = [...allTypes, { ...key, value: [temp] }];
      }
    });
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const allUniqTypesValue: any[] = allTypes.map((type) => uniqWith(type.value, isEqual));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const finalStringArrayAllTypesValue: any[] = allUniqTypesValue.map((valueItem) =>
    valueItem.map((valueItemValue: { value: string }) => valueItemValue.value),
  );

  const results = allTypes.map((type, index) => ({
    ...type,
    nanoid: nanoid(),
    value: finalStringArrayAllTypesValue[index],
  }));

  return results;
};
