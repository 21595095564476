import { useQuery } from "@apollo/client";
import React, { FC, useMemo, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { SortingRule } from "react-table";
import get from "lodash/get";
import { COMMENT_CRAWLERS } from "graphql/facebookCrawler/query";
import COLORS from "constants/Colors";
import Grid from "components/Grid";
import Search from "components/Search";

import CircularProgress from "components/CircularProgress";

import { ShowingResult, RowsSelectorPerPage } from "components/TablePagination";
import { Wrapper as PaginationWrapper } from "components/TablePagination/styled";
import Table from "components/Table";
import { OrderByType, Data, OrderReportType } from "types/SalesReport";
import useIsDesktop from "utils/hooks/useIsDesktop";
import { ProjectIdType } from "types/Project";

import { AddCommentCrawlerInput } from "types/FacebookCrawler";
import { TableWrapper } from "./TableWrapper";

import { getColumns, tableHooks } from "./customTable/config";
import { GridLoading, GridPagination, GridRowsPerPage, GridShowingResult, Hidden, Typography, Wrapper } from "./styled";
import { DEFAULT_PAGE_START, ROWS_PER_PAGE, ROWS_PER_PAGES } from "./config";

type FacebookAutoEngagementPropType = {
  onClickTitle: (id: string) => void;
};

const FacebookAutoEngagement: FC<FacebookAutoEngagementPropType> = ({ onClickTitle }) => {
  const isDesktop = useIsDesktop();
  const { projectId } = useParams<ProjectIdType>();
  const filterRef = useRef({});
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [page, setPage] = useState(DEFAULT_PAGE_START);

  const [filterValue, setFilterValue] = useState({});

  const tableColumns = useMemo(() => getColumns<OrderReportType>(isDesktop, onClickTitle), [isDesktop, onClickTitle]);

  const { data: facebookCrawlerData, loading: facebookCrawlerLoading, refetch: facebookCrawlerRefetch } = useQuery(
    COMMENT_CRAWLERS,
    {
      variables: {
        projectId,
        pageSize: rowsPerPage,
        page: page - 1,
        filter: { ...filterRef.current, ...filterValue },
      }, // filter Object
      skip: !projectId,
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    },
  );

  const handleChangeSortBy = (sortBys: SortingRule<{ id: string; desc: boolean }>[]) => {
    const sortBy = sortBys[0];
    if (sortBy) {
      filterRef.current = { sortBy: sortBy.id, orderBy: sortBy.desc ? OrderByType.DESC : OrderByType.ASC };
      facebookCrawlerRefetch({
        projectId,
        pageSize: rowsPerPage,
        page: page - 1,
        filter: { ...filterRef.current, ...filterValue },
      });
    }
  };

  const handlePageClick = (data: { selected: number }) => {
    const { selected } = data;
    // +1 because of ReactPaginate page start from 0 but my page start from 1
    setPage(selected + 1);
  };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(DEFAULT_PAGE_START);
  };
  const facebookCrawler = get(facebookCrawlerData, "commentCrawlers.results") || [];
  const total: number = get(facebookCrawlerData, "commentCrawlers.total") || 0;

  return (
    <Wrapper>
      <Grid container alignItems="center" className="pb-4">
        <Grid item xs={12}>
          <Search
            defaultValue=""
            onChange={(searchInput: string) => setFilterValue({ title: searchInput })}
            className="mr-3"
            placeholder={t("searchPlaceholder.marketingToolsAutoEngagement")}
          />
        </Grid>
      </Grid>
      <TableWrapper>
        {
          // render when facebookCrawler are loading
          facebookCrawlerLoading && (
            <GridLoading>
              <CircularProgress className="m-4" />
            </GridLoading>
          )
        }

        {/* We have to render table and hide table when there are not reports
            to prevent table reset all filter and sortBy state in the table. */}
        <Hidden className="ml-auto mr-auto" isHidden={facebookCrawler.length === 0}>
          <Table<Data, AddCommentCrawlerInput[]>
            data={facebookCrawler}
            columns={tableColumns}
            manualSortBy
            hooks={tableHooks}
            onChangeSortBy={handleChangeSortBy}
          />
        </Hidden>

        {
          // render when no facebookCrawler
          !facebookCrawlerLoading && facebookCrawler.length === 0 && (
            <Grid container justify="center" alignItems="center" className="h-100">
              <Grid item>
                <Typography variant="title2" color={COLORS.DarkLight}>
                  {
                    // No facebookCrawler
                    t("facebookAutoEngagement.table.placeholder")
                  }
                </Typography>
              </Grid>
            </Grid>
          )
        }
      </TableWrapper>

      <Grid container>
        <Grid item className="px-2" container xs={12} alignItems="center" justify="center">
          <GridShowingResult item xs={6} md="auto" lg="auto">
            <ShowingResult page={page} rowsPerPage={rowsPerPage} total={total} />
          </GridShowingResult>
          <GridPagination item xs={12} md="auto" lg="auto" container>
            <PaginationWrapper>
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                // -1 because of ReactPaginate page start from 0 but my page start from 1
                forcePage={page - 1}
                pageCount={Math.ceil(total / rowsPerPage) || 0}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                onPageChange={handlePageClick}
                activeClassName="active"
                containerClassName="d-flex flex-wrap p-0"
                pageClassName="page-no"
                breakClassName="break"
                previousClassName="page-no previous"
                nextClassName="page-no next"
              />
            </PaginationWrapper>
          </GridPagination>

          <GridRowsPerPage item xs={6} md="auto" lg="auto" container alignItems="center" justify="flex-end">
            <Grid item>
              <Typography className="d-inline-block mr-2" color="darkGray">
                {t("salesReport.rowsSelector.show")}
              </Typography>
            </Grid>
            <Grid item>
              <RowsSelectorPerPage
                options={ROWS_PER_PAGES}
                onChange={handleChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
              />
            </Grid>
            <Grid item>
              <Typography className="d-inline-block ml-2" color="darkGray">
                {t("salesReport.rowsSelector.perPage")}
              </Typography>
            </Grid>
          </GridRowsPerPage>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default FacebookAutoEngagement;
