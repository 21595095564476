import React from "react";
import styled from "styled-components";
import Grid from "components/Grid";
import { AddonItemType } from "types/Addon";

type IconWrapperPropsType = {
  type?: AddonItemType;
  bgColor: string;
  borderColor: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const IconWrapper = styled(({ bgColor, borderColor, type, ...rest }) => <div {...rest} />).attrs<
  IconWrapperPropsType
>((props) => {
  const { theme, type } = props;

  switch (type) {
    case AddonItemType.CURRENT:
      return {
        bgColor: theme.COLORS.White,
        borderColor: theme.COLORS.LightBlue,
      };
    case AddonItemType.NEW:
      return {
        bgColor: theme.COLORS.PrimaryNude,
        borderColor: theme.COLORS.Primary,
      };
    default:
      return {
        bgColor: theme.COLORS.LightGray,
        borderColor: theme.COLORS.LightGray,
      };
  }
})<IconWrapperPropsType>`
  min-width: 48px;
  min-height: 48px;
  border-radius: 8px;
  background-color: ${({ bgColor }) => bgColor};
  border: solid 1px ${({ borderColor }) => borderColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NewItemWrapper = styled.div`
  min-width: 48px;
  min-height: 48px;
  border-radius: 8px;
  border: dashed 1px ${({ theme }) => theme.COLORS.LightBlue};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ItemWrapperWithCloseIcon = styled(Grid)`
  /* Hide close button except last element */
  :not(:nth-last-child(2)) {
    .closeButton {
      display: none;
    }
  }

  .closeButton {
    svg {
      margin: 0 !important;
    }
  }
`;
