import { gql } from "@apollo/client";

export const KYC_VERIFICATION = gql`
  query kycVerification($projectId: ID!) {
    kycVerification(projectId: $projectId) {
      id
      projectId
      status
      reason
      firstPageOfBankAccountPath
      bank
      bankAccountName
      bankAccountNo
      verifiedBy
      createdAt
      updatedAt
    }
  }
`;

export const DEEPLE_PAY_WALLET = gql`
  query deeplePayWallet($projectId: ID!) {
    deeplePayWallet(projectId: $projectId) {
      balance
      updatedAt
    }
  }
`;

export const DEEPLE_PAY_RATE = gql`
  query deeplePayFeeRate($projectId: ID!) {
    deeplePayFeeRate(projectId: $projectId) {
      qrCash {
        rate
        min
        fix
      }
      mobileBanking {
        rate
        min
        fix
      }
      withdrawal {
        rate
        min
        fix
      }
    }
  }
`;

export const DEEPLE_PAY_PAYMENT_CHANNEL = gql`
  query deeplePayPaymentChannelConfigurations($projectId: ID!) {
    deeplePayPaymentChannelConfigurations(projectId: $projectId) {
      type
      isActive
      isSelectable
      minimum
      channels {
        name
        fee {
          rate
          min
          fix
        }
      }
    }
  }
`;

export const EXPORT_DEEPLE_PAY_TRANSACTIONS = gql`
  query exportDeeplePayTransactions(
    $projectId: ID!
    $fileType: ExportFileType!
    $startDate: DateTime!
    $endDate: DateTime!
    $transactionType: TransactionTypeName!
  ) {
    exportDeeplePayTransactions(
      projectId: $projectId
      fileType: $fileType
      startDate: $startDate
      endDate: $endDate
      transactionType: $transactionType
    )
  }
`;

export const DEEPLE_PAY_FEE_INVOICES = gql`
  query deeplePayInvoices($projectId: ID!, $year: String!) {
    deeplePayInvoices(projectId: $projectId, year: $year) {
      id
      projectId
      path
      invoiceNumber
      amount
      createdAt
      updatedAt
    }
  }
`;

export const DEEPLE_PAY_FEE_SIGNED_URL = gql`
  query deeplePayFeeInvoiceSignedURL($projectId: ID!, $deeplePayFeeInvoiceId: ID!) {
    deeplePayFeeInvoiceSignedURL(projectId: $projectId, deeplePayFeeInvoiceId: $deeplePayFeeInvoiceId)
  }
`;
