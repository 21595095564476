import React, { useState } from "react";

import Grid from "components/Grid";
import Typography from "components/Typography";
import TextField from "components/TextField";

import { useTranslation } from "react-i18next";
import { FilterItemType } from "../types";

export default function NumberRangeFilter(props: {
  setValue: Function;
  filterList: FilterItemType[];
  editId: string;
  keyName: string;
  options: { min: number; max: number };
}) {
  const { setValue, filterList, editId, keyName, options } = props;
  const { t } = useTranslation();

  // .replace(/^0+/, '')

  const [optionMin, setOptionMin] = useState(Number(options.min));
  const [optionMax, setOptionMax] = useState(Number(options.max));

  const handleSetMin = (min: string) => {
    const minValue = min.replace(/^0+/, "");
    if (Number(minValue) <= Number(optionMax) && Number(minValue) >= 0) {
      setOptionMin(Number(minValue));
      const updateValue = filterList.map((item: { id: string }) => {
        if (item.id === editId) {
          return {
            ...item,
            [keyName]: { min: Number(minValue), max: Number(optionMax) },
          };
        }
        return item;
      });
      setValue(updateValue);
    }
  };

  const handleSetMax = (max: string) => {
    const maxValue = max.replace(/^0+/, "");
    if (Number(maxValue) >= Number(optionMin) && Number(maxValue) >= 0) {
      setOptionMax(Number(maxValue));
      const updateValue = filterList.map((item: { id: string }) => {
        if (item.id === editId) {
          return {
            ...item,
            [keyName]: { min: Number(optionMin), max: Number(maxValue) },
          };
        }
        return item;
      });
      setValue(updateValue);
    }
  };

  return (
    <Grid item className="px-2 py-1" xs={12} container justify="flex-start" alignContent="center" alignItems="center">
      <Typography variant="body3" className="pr-2">
        {t(keyName)} :
      </Typography>
      <TextField
        style={{ width: "85px" }}
        value={Number(optionMin)}
        type="number"
        onChange={(event) => {
          handleSetMin(event.target.value);
        }}
      />
      <Typography variant="body3" className="px-3">
        -
      </Typography>
      <TextField
        style={{ width: "85px" }}
        value={Number(optionMax)}
        type="number"
        onChange={(event) => {
          handleSetMax(event.target.value);
        }}
      />
    </Grid>
  );
}
