import { useQuery } from "@apollo/client";
import get from "lodash/get";
import { BILLING } from "graphql/billing/query";

const useHasBillingInfo = (projectId: string) => {
  const { data } = useQuery(BILLING, {
    variables: {
      projectId,
    },
  });

  const billingData = get(data, "billing");

  return Boolean(billingData);
};

export default useHasBillingInfo;
