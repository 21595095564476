import React from "react";

import IcStoreSettings from "components/SvgIcons/IcStoreSettings";
import IcCategories from "components/SvgIcons/IcCategories";
import IcProduct from "components/SvgIcons/IcProduct";
import IcChatbot from "components/SvgIcons/IcChatbot";
import IcConnect from "components/SvgIcons/IcConnect";

import { GUIDELINE_VIDEO_LINK } from "constants/Video";

import { GUIDELINE_CHECK } from "types/Guideline";

export type QuickStartOptionType = {
  title: string;
  description: string;
  textButton: string;
  redirectTo: string;
  Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  guidelineCheck: GUIDELINE_CHECK;
  isOptional?: boolean;
  videoLink: string;
};

export type NewQuickStartOptionType = {
  title: string;
  description: string;
  textButton: string;
  successText?: string;
  redirectTo: string;
  guidelineCheck: GUIDELINE_CHECK;
  isOptional?: boolean;
  videoLink: string;
  image?: string;
};

export const MAX_STEP = 4;
export const MAX_STEP_WITH_DEEPLE_PAY = 5;

export const NEW_QUICK_START_CARDS_WITH_DEEPLE_PAY: NewQuickStartOptionType[] = [
  {
    title: "ADD_YOUR_SHIPPING_METHODS",
    description: "SELECT_SHIPPING_METHOD",
    textButton: "ADD_SHIPPING",
    successText: "GO_TO_SHIPPING",
    redirectTo: "shipping/shipping-default",
    guidelineCheck: GUIDELINE_CHECK.HAS_SHIPPING_METHOD,
    videoLink: GUIDELINE_VIDEO_LINK.shipping,
    image: "/assets/img/quick_start_2.png",
  },
  {
    title: "PAYMENT_DEEPLE_PAY_TITLE",
    description: "PAYMENT_DEEPLE_PAY_DESCRIPTION",
    textButton: "PAYMENT_DEEPLE_PAY_BUTTON",
    successText: "PAYMENT_DEEPLE_PAY_SUCCESS_BUTTON",
    redirectTo: "payment-method/payment-channel",
    guidelineCheck: GUIDELINE_CHECK.HAS_PAYMENT_METHOD,
    videoLink: GUIDELINE_VIDEO_LINK.deeplePay,
    image: "/assets/img/quick_start_4.png",
  },
  {
    title: "TRAIN_YOUR_BOT",
    description: "ENHANCE_YOUR_BOT_TO_NEXT_LEVEL",
    textButton: "AI_SETTINGS",
    successText: "GO_TO_AI_SETTING",
    redirectTo: "ai-settings/specialized-knowledge",
    guidelineCheck: GUIDELINE_CHECK.HAS_FAQ,
    isOptional: true,
    videoLink: GUIDELINE_VIDEO_LINK.trainBot,
    image: "/assets/img/quick_start_5.png",
  },
  {
    title: "CONNECT_YOUR_FACEBOOK_OR_LINE",
    description: "CONNECT_DEEPLE_TO_YOUR_STORE",
    textButton: "CONNECT",
    successText: "GO_TO_CONNECT",
    redirectTo: "store-settings/connect",
    guidelineCheck: GUIDELINE_CHECK.HAS_CONNECT,
    videoLink: GUIDELINE_VIDEO_LINK.connect,
    image: "/assets/img/quick_start_3.png",
  },
  {
    title: "ADD_CATALOGS_AND_PRODUCT",
    description: "ADD_DETAILS_TO_YOUR_STORE",
    textButton: "ADD_PRODUCT",
    successText: "GO_TO_PRODUCT",
    redirectTo: "products-inventory/products",
    guidelineCheck: GUIDELINE_CHECK.HAS_PRODUCT,
    videoLink: GUIDELINE_VIDEO_LINK.productAndCategory,
    image: "/assets/img/quick_start_1.png",
  },
];

export const NEW_QUICK_START_CARDS: NewQuickStartOptionType[] = [
  {
    title: "ADD_YOUR_SHIPPING_METHODS",
    description: "SELECT_SHIPPING_METHOD",
    textButton: "ADD_SHIPPING",
    successText: "GO_TO_SHIPPING",
    redirectTo: "shipping/shipping-default",
    guidelineCheck: GUIDELINE_CHECK.HAS_SHIPPING_METHOD,
    videoLink: GUIDELINE_VIDEO_LINK.shipping,
    image: "/assets/img/quick_start_2.png",
  },
  {
    title: "TRAIN_YOUR_BOT",
    description: "ENHANCE_YOUR_BOT_TO_NEXT_LEVEL",
    textButton: "AI_SETTINGS",
    successText: "GO_TO_AI_SETTING",
    redirectTo: "ai-settings/specialized-knowledge",
    guidelineCheck: GUIDELINE_CHECK.HAS_FAQ,
    isOptional: true,
    videoLink: GUIDELINE_VIDEO_LINK.trainBot,
    image: "/assets/img/quick_start_5.png",
  },
  {
    title: "CONNECT_YOUR_FACEBOOK_OR_LINE",
    description: "CONNECT_DEEPLE_TO_YOUR_STORE",
    textButton: "CONNECT",
    successText: "GO_TO_CONNECT",
    redirectTo: "store-settings/connect",
    guidelineCheck: GUIDELINE_CHECK.HAS_CONNECT,
    videoLink: GUIDELINE_VIDEO_LINK.deeplePay,
    image: "/assets/img/quick_start_3.png",
  },
  {
    title: "ADD_CATALOGS_AND_PRODUCT",
    description: "ADD_DETAILS_TO_YOUR_STORE",
    textButton: "ADD_PRODUCT",
    successText: "GO_TO_PRODUCT",
    redirectTo: "products-inventory/products",
    guidelineCheck: GUIDELINE_CHECK.HAS_PRODUCT,
    videoLink: GUIDELINE_VIDEO_LINK.productAndCategory,
    image: "/assets/img/quick_start_1.png",
  },
];

export const QUICK_START_CARDS: QuickStartOptionType[] = [
  {
    title: "Guideline.categories.title",
    description: "Guideline.categories.description",
    textButton: "Guideline.categories.button",
    redirectTo: "products-inventory/categories",
    Icon: IcCategories,
    guidelineCheck: GUIDELINE_CHECK.HAS_CATEGORIES,
    videoLink: "https://www.youtube.com/embed/jwb-U3-oLvw?list=PLpQ7q9xUQjKfDL2uWrwXDpjmqaCNe1sBl",
  },
  {
    title: "Guideline.products.title",
    description: "Guideline.products.description",
    textButton: "Guideline.products.button",
    redirectTo: "products-inventory/products",
    Icon: IcProduct,
    guidelineCheck: GUIDELINE_CHECK.HAS_PRODUCT,
    videoLink: "https://www.youtube.com/embed/rqssnhvyH70?list=PLpQ7q9xUQjKfDL2uWrwXDpjmqaCNe1sBl",
  },
  {
    title: "Guideline.shippingMethod.title",
    description: "Guideline.shippingMethod.description",
    textButton: "Guideline.shippingMethod.button",
    redirectTo: "shipping/shipping-default",
    Icon: IcStoreSettings,
    guidelineCheck: GUIDELINE_CHECK.HAS_SHIPPING_METHOD,
    videoLink: "https://www.youtube.com/embed/BZngTYqvs_U?list=PLpQ7q9xUQjKfDL2uWrwXDpjmqaCNe1sBl",
  },
  {
    title: "Guideline.faqs.title",
    description: "Guideline.faqs.description",
    textButton: "Guideline.faqs.button",
    redirectTo: "ai-settings/general-setting",
    Icon: IcChatbot,
    guidelineCheck: GUIDELINE_CHECK.HAS_FAQ,
    isOptional: true,
    videoLink: "https://www.youtube.com/embed/kb_PsQ5S7HE?list=PLpQ7q9xUQjKfDL2uWrwXDpjmqaCNe1sBl",
  },
  {
    title: "Guideline.connect.title",
    description: "Guideline.connect.description",
    textButton: "Guideline.connect.button",
    redirectTo: "store-settings/connect",
    Icon: IcConnect,
    guidelineCheck: GUIDELINE_CHECK.HAS_CONNECT,
    videoLink: "https://www.youtube.com/embed/oMFl3V5vY00",
  },
];
