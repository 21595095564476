import { TOKEN_LOCAL_STORAGE_KEY } from "config";

const getToken = () => {
  return localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY);
};

const setToken = (token: string) => {
  localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, token);
};

const removeToken = () => {
  localStorage.removeItem(TOKEN_LOCAL_STORAGE_KEY);
};

const checkTokenExpired = (token: string) => {
  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    return Date.now() >= payload.exp * 1000;
  } catch (e) {
    return true; // treat token as expired if it's can't interpret
  }
};

const getPayloadFromJWTToken = (token: string) => {
  const payload = JSON.parse(atob(token.split(".")[1]));
  return payload;
};

export { getToken, setToken, removeToken, checkTokenExpired, getPayloadFromJWTToken };
